<template>
  <el-row class="detail-container">
    <div class="content">
      <!-- 订单管理 -->
      <OrderDetail v-if="leftCurrentText === '订单管理'" :detailId="detailId" />
      <!-- 采购备货 -->
      <PurchasesDetail
        v-if="leftCurrentText === '采购备货'"
        :detailId="detailId"
      />
      <!-- 采购订单 -->
      <PurchaseOrderDetail
        v-if="leftCurrentText === '采购订单'"
        :detailId="detailId"
      />
      <!-- 入库管理 -->
      <InboundSheetDetail
        v-if="leftCurrentText === '入库管理'"
        :detailId="detailId"
      />
      <!-- 出库管理 -->
      <OutboundSheetDetail
        v-if="leftCurrentText === '出库管理'"
        :detailId="detailId"
      />
      <!-- 产品管理 -->
      <ProductDetail
        v-if="leftCurrentText === '产品管理'"
        :detailId="detailId"
      />
      <!-- 付款管理 -->
      <PaymentDetailModel
        v-if="leftCurrentText === '付款管理'"
        :detailId="detailId"
      />
      <!-- 费用管理 -->
      <FetDetailModel
        v-if="leftCurrentText === '费用管理'"
        :detailId="detailId"
      />
    </div>
    <el-row class="operate" v-if="operation === '1'">
      <el-popover
        placement="bottom"
        title="审核"
        width="500"
        height="300"
        trigger="click"
        offset="-200"
        ref="cancel1"
      >
        <el-row>
          <el-form label-position="top">
            <el-form-item label="同意" prop="remark">
              <el-input
                type="textarea"
                placeholder="请输入审核意见"
                v-model="remark1"
                maxlength="500"
                show-word-limit
                :autosize="{ minRows: 6, maxRows: 8 }"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-row style="text-align: right">
            <el-button type="primary" @click="auditClickConfirm1">
              确认
            </el-button>
            <el-button @click="cancelClick1">取消</el-button>
          </el-row>
        </el-row>
        <el-button slot="reference" type="primary">同意</el-button>
      </el-popover>
      <el-popover
        placement="bottom"
        title="驳回"
        width="500"
        height="300"
        trigger="click"
        offset="-200"
        ref="cancel2"
      >
        <el-row>
          <el-form label-position="top">
            <el-form-item prop="remark">
              <template slot="label">
                <i style="color: #ff4d4f">*</i>
                驳回意见
              </template>
              <el-input
                type="textarea"
                placeholder="请输入驳回意见"
                v-model="remark2"
                maxlength="500"
                show-word-limit
                :autosize="{ minRows: 6, maxRows: 8 }"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-row style="text-align: right">
            <el-button type="primary" @click="auditClickConfirm2">
              确认
            </el-button>
            <el-button @click="cancelClick2">取消</el-button>
          </el-row>
        </el-row>
        <el-button slot="reference">驳回</el-button>
      </el-popover>
    </el-row>
    <el-row class="operate" v-else>
      <el-button @click="closeClick">取消</el-button>
    </el-row>
  </el-row>
</template>

<script>
import OrderDetail from './order-detail'
import PurchasesDetail from './purchases-detail'
import PurchaseOrderDetail from './purchases-order-detail'
import InboundSheetDetail from '@/views/stock-center/inbound-sheet/inbound-sheet-detail'
import OutboundSheetDetail from '@/views/stock-center/outbound-sheet/outbound-sheet-detail'
import PaymentDetailModel from './payment-detail'
import FetDetailModel from './fet-detail'
import ProductDetail from './product-detail'
import { audit } from '@/api/audit/auditObject'

export default {
  name: 'Test',
  props: {
    leftCurrentText: {
      type: String, //当前审核模块名称
      required: true,
    },
    detailId: {
      //传递给详情的id
      type: String,
      required: true,
    },
    operation: {
      //是否显示审核按钮
      type: String,
      required: false,
      default: '1',
    },
    passRow: {
      type: Object,
      required: true,
    },
  },
  components: {
    OrderDetail,
    PurchasesDetail,
    PurchaseOrderDetail,
    InboundSheetDetail,
    OutboundSheetDetail,
    FetDetailModel,
    PaymentDetailModel,
    ProductDetail,
  },
  data() {
    return {
      remark1: '',
      remark2: '',
    }
  },

  methods: {
    //同意的取消
    cancelClick1() {
      this.$refs.cancel1.doClose()
    },
    //驳回的取消
    cancelClick2() {
      this.$refs.cancel2.doClose()
    },
    //同意确认
    auditClickConfirm1() {
      this.audit(this.remark1, 2)
    },
    //驳回确认
    auditClickConfirm2() {
      if (this.remark2 === '') {
        return this.$baseMessage(
          '请输入您的审核驳回意见',
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      this.audit(this.remark2, 3)
    },
    //审核
    async audit(remark, num) {
      this.passRow.auditStatus = num
      this.passRow.remarks = remark
      let response = await audit(this.passRow)
      if (response.code === '000000') {
        this.$baseMessage(
          '操作成功',
          'success',
          false,
          'erp-hey-message-success'
        )
        this.closeClick()
      }
    },
    //关闭抽屉
    closeClick() {
      this.$emit('handleClose')
    },
  },
}
</script>

<style scoped lang="scss">
.detail-container {
  .content {
    margin-bottom: 100px;
  }
}
.operate {
  height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 40px;
  background: #fff;
  border-top: 1px solid #ededed;
}
</style>
