<template>
  <el-dialog
    :title="title"
    :append-to-body="true"
    :visible.sync="dialogFormVisible"
    width="650px"
    top="5vh"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-row>
      <el-col>
        <el-form
          ref="profit-from"
          :model="profitfromData"
          :rules="rules"
          label-width="100px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="模板名称" prop="nameCn">
                <el-input
                  v-model="profitfromData.nameCn"
                  placeholder="请输入"
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12" class="default-el-col">
              <el-form-item>
                <el-checkbox
                  v-model="profitfromData.isDefault"
                  :true-label="1"
                  :false-label="0"
                >
                  设为默认利润率
                </el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item prop="nameEn" label="英文名称">
                <el-input
                  v-model="profitfromData.nameEn"
                  placeholder="请输入"
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <el-col class="profit-cont-title">
        <h6 class="left-h6">价格利润率</h6>
        <el-tooltip effect="light" placement="right">
          <div slot="content">
            1、新增 / 编辑产品时，可引用价格利润率
            <br />
            2、可输入负数，不填写即表示利润率为0；
          </div>
          <i class="iconfont icon-wenhao" style="color: #909399"></i>
        </el-tooltip>
      </el-col>
      <el-col>
        <el-form :model="profitfromData" ref="profit_form" class="profit_form">
          <el-table
            :data="profitfromData.value"
            style="width: 100%"
            ref="table"
            border
          >
            <el-table-column align="center" prop="jt" label="阶梯">
              <template slot-scope="scope">
                <span>{{ scope.row.jt }}阶</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="lrl" label="印刷价格利润率">
              <template slot-scope="scope">
                <div class="step-panle">
                  <el-form-item
                    :prop="'value.' + scope.$index + '.lrl'"
                    label-width="0"
                  >
                    <el-input
                      type="number"
                      v-model="scope.row.lrl"
                      oninput="if(value > 99.99999){value=99.99999}if(value < -99.99999){value=-99.99999}if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+6)}"
                      @blur="profitMarginBlurFn($event, scope.row)"
                    ></el-input>
                  </el-form-item>
                  <span class="symbol">%</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/product/productSetting'
  const placeholderTableData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
    return {
      jt: item,
      lrl: 0,
    }
  })
  export default {
    name: 'PublicModel',
    data() {
      return {
        loading: false,
        title: '利润率模板',
        rules: {
          nameEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
          nameCn: [
            { required: true, trigger: 'blur', message: '请输入模板名称' },
          ],
        },
        profitfromData: {
          type: 10,
          productSettingId: '',
          nameCn: '',
          nameEn: '',
          isDefault: 0, //默认利润率
          value: this.utils.deepCopy(placeholderTableData),
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //展示弹框
      showAddEdit(row) {
        if (row) {
          const arr = JSON.parse(row.value)
          const resultArray = arr.map((item) => {
            const jt = Object.keys(item)[0]
            const lrl = item[jt]
            return {
              jt,
              lrl,
            }
          })

          this.profitfromData = Object.assign({}, row, { value: resultArray })
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.dialogFormVisible = false
        this.$refs['profit-from'].resetFields()
        this.profitfromData = {
          type: 10,
          productSettingId: '',
          nameCn: '',
          nameEn: '',
          isDefault: 0, //默认利润率
          value: this.utils.deepCopy(placeholderTableData),
        }
      },
      // 保存
      async save() {
        let profitfromData = this.utils.deepCopy(this.profitfromData)
        const arr = profitfromData.value.map((item) => ({
          [item.jt]: item.lrl,
        }))
        profitfromData.value = JSON.stringify(arr)
        this.$refs['profit-from'].validate(async (valid) => {
          if (valid) {
            let response
            this.loading = true
            if (profitfromData.productSettingId === '') {
              response = await insertPO(profitfromData)
              this.loading = false

              if ((response.code = '000000')) {
                this.$baseMessage(
                  '新增成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              response = await updatePO(profitfromData)
              this.loading = false

              if ((response.code = '000000')) {
                this.$baseMessage(
                  '编辑成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.$emit('public-model', 10)
            this.close()
          }
        })
      },
      // 利润率失去焦点
      profitMarginBlurFn(e, row) {
        if (!row.lrl) row.lrl = 0
      },
    },
  }
</script>
<style scoped lang="scss">
  .default-el-col {
    ::v-deep .el-form-item__content {
      margin-left: 10px !important;
    }
  }
  .profit-cont-title {
    display: flex;
    align-items: center;
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
      line-height: 30px;
      margin-right: 10px;
      &::before {
        content: '';
        display: block;
        height: 26px;
        width: 4px;
        background: rgba(2, 125, 180, 1);
        margin-right: 5px;
      }
    }
  }
  .step-panle {
    display: flex;
    align-items: center;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
    .symbol {
      margin-left: 10px;
    }
  }
</style>
