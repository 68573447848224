var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-aside-wrapper"},[_c('div',{ref:"leftAside",staticClass:"left-aside",style:({ width: _vm.width + 'px' })},[_c('el-collapse',{model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.leftData),function(item){return _c('el-collapse-item',{key:item.mailAccountId,staticStyle:{"margin-bottom":"18px"},attrs:{"title":item.emailAccount,"name":item.emailAccount}},[_c('div',{staticClass:"account-title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"flex1"},[_vm._v(_vm._s(item.emailAccount))]),(item.authStatus == 0)?_c('el-tag',{staticClass:"tag tag1"},[_vm._v(" "+_vm._s(_vm.$t('myMail.assist'))+" ")]):_vm._e(),(item.authStatus == 2)?_c('el-tag',{staticClass:"tag tag2"},[_vm._v(" "+_vm._s(_vm.$t('myMail.invalid'))+" ")]):_vm._e()],1),_c('el-tree',{ref:item.emailAccount,refInFor:true,attrs:{"data":item.folderConfigVOS,"node-key":"folderConfigId","expand-on-click-node":true,"props":_vm.defaultProps,"highlight-current":_vm.showCurrent,"current-node-key":_vm.currentemailId,"default-expanded-keys":_vm.expandTree,"auto-expand-parent":false},on:{"node-expand":_vm.nodeExpand,"node-collapse":_vm.nodeCollapse},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticClass:"custom-tree-node",attrs:{"id":data.folderConfigId}},[(!data.rename)?_c('div',{staticClass:"custom-tree-node-text",on:{"click":function($event){return _vm.getList(data, node, item)}}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":data.langKey ? _vm.$t(data.langKey) : data.CnName || data.name,"placement":"top-start","disabled":data.name.length < 9}},[_c('span',{staticClass:"folederName",style:({ maxWidth: _vm.width * 0.75 + 'px' })},[_c('i',{class:data.icon ? data.icon : 'iconfont icon-folder-fill',staticStyle:{"margin-right":"10px"}}),_vm._v(" "+_vm._s(data.userId && data.userId !== 'ALL_USER_ID' ? _vm.lang === 'en' ? data.userNameEn : data.userNameCn + '(' + data.userNameEn + ')' : data.fullName === '其他文件夹' ? _vm.$t('other.Otherfolders') : data.langKey ? _vm.$t(data.langKey) : data.CnName || data.name)+" ")])]),(
                  (data.CnName == '草稿箱' ||
                    data.CnName == '垃圾箱' ||
                    data.CnName == '垃圾邮件') &&
                  data.mailTotalCount
                )?_c('span',{staticClass:"unRead"},[_vm._v(" ["+_vm._s(data.mailTotalCount)+"] ")]):(
                  data.mailunReadTotalCount && data.mailunReadTotalCount > 0
                )?_c('span',{staticClass:"unRead"},[_vm._v(" ["+_vm._s(data.mailunReadTotalCount)+"] ")]):_vm._e(),(
                  item.sendFlag == 2 &&
                  ['已发邮件', '已发送', '已发邮件'].includes(data.name)
                )?_c('ErpMouseoverTip',{attrs:{"disabled":""}}):_vm._e()],1):_vm._e(),(data.rename)?_c('el-input',{ref:data.folderConfigId,refInFor:true,attrs:{"id":data.folderConfigId},on:{"focus":function($event){return _vm.focusLabel(data)},"blur":function($event){return _vm.blurLabel(data)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur()}},model:{value:(_vm.labelValue),callback:function ($$v) {_vm.labelValue=$$v},expression:"labelValue"}}):_vm._e(),(!data.unOper && item.authStatus === null)?_c('el-dropdown',{attrs:{"trigger":"hover","show-timeout":50}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[(
                      data.fullName == '其他文件夹' ||
                      data.emailAccount.indexOf('@qq.com') < 0
                    )?_c('div',{on:{"click":function($event){return _vm.append(data)}}},[_vm._v(" "+_vm._s(_vm.$t('other.newFolder'))+" ")]):_vm._e()]),_c('el-dropdown-item',[(
                      data.fullName !== '其他文件夹' &&
                      data.fullName !== '[Gmail]'
                    )?_c('div',{on:{"click":function($event){return _vm.reName(data)}}},[_vm._v(" "+_vm._s(_vm.$t('other.rename'))+" ")]):_vm._e()]),_c('el-dropdown-item',[(
                      data.fullName !== '其他文件夹' &&
                      data.fullName !== '[Gmail]'
                    )?_c('div',{on:{"click":function($event){return _vm.remove(node, data)}}},[_vm._v(" "+_vm._s(_vm.$t('other.removefolders'))+" ")]):_vm._e()])],1)],1):_vm._e()],1)}}],null,true)}),_c('leftAsideMaskLabel',{attrs:{"maskList":item.maskList,"currentLabelId":_vm.currentLabelId,"id":item.mailAccountId + 1,"mailAccountId":item.mailAccountId + 1,"emailAccount":item.emailAccount},on:{"getDetail":_vm.getDetail}})],1)}),1)],1),_c('addFolder',{ref:"addFolder",on:{"addNewFolder":_vm.addNewFolder}}),_c('erp-handle',{staticClass:"myxhandle",on:{"widthChange":_vm.widthChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }