<template>
  <el-dropdown
    v-allowed="['IMPORT:COST']"
    @command="(val) => $emit('command', val)"
  >
    <el-button>导入运费</el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="AUTO">自动分摊</el-dropdown-item>
      <p class="split-div"></p>
      <el-dropdown-item command="MANUAL">手动调整</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'ImportShipping',
}
</script>

<style lang="scss" scoped>
.split-div {
  height: 1px;
  background-color: #dcdfe6;
  margin: 5px 0;
}
.ml-15 {
  margin-left: 15px;
}
</style>
