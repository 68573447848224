<!-- 报价单内容 -->
<!-- 此页面涉及到复制html功能，样式必须写在行内 -->
<template>
  <div>
    <div
      class="offer-container"
      v-for="(item, i) in offerData.priceSheets"
      :key="i"
      :id="'inquiryprint' + i"
      style="
        width: 834px;
        /* padding: 0 10px; */
        overflow-x: hidden;
        height: auto;
        margin: 0 auto;
        box-sizing: border-box;
        overflow-y: auto;
      "
    >
      <div class="box" style="overflow-x: hidden">
        <div class="top" style="color: #606266" v-if="!isPrint">
          <div
            style="
              height: 40px;
              line-height: 40px;
              display: inline-block;
              margin-right: 20px;
            "
          >
            Quote No.:
            <span style="font-weight: 400">
              {{ offerData.priceSheetCode || emptyFlag }}
            </span>
          </div>
          <div
            style="
              height: 40px;
              line-height: 40px;
              display: inline-block;
              margin-right: 20px;
            "
          >
            Quote Date:
            <!-- changed -->
            <span style="font-weight: 400">
              {{ offerData.quoteDate || emptyFlag }}
            </span>
          </div>
          <div
            style="
              height: 40px;
              line-height: 40px;
              display: inline-block;
              margin-right: 20px;
            "
          >
            Prices valid for
            {{ offerData.validityDate ? offerData.validityDate : '_' }} days
          </div>
        </div>
        <div>
          <h1 style="text-align: center; margin: 10px 0 5px 0" v-if="isPrint">
            QUOTATION
          </h1>
          <div class="box" style="display: flex" v-if="isPrint">
            <div
              class="left"
              style="
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: end;
                padding-left: 5px;
                color: #606266;
              "
            >
              <div>
                Quote No.:
                <span>{{ offerData.priceSheetCode || '--' }}</span>
              </div>

              <div>
                Quote Date:
                <span>{{ offerData.quoteDate || '--' }}</span>
              </div>
              <div>
                Prices valid for
                {{ offerData.validityDate ? offerData.validityDate : '--' }}
                days
              </div>
            </div>
            <div class="right" style="flex: 1; text-align: right">
              <img v-if="rise == 'RG'" :src="RG" alt />
              <img v-else-if="rise == 'RP'" :src="RP" alt />
              <img v-else-if="rise == 'SW'" :src="SW" alt />
            </div>
          </div>
          <div
            class="pro-item"
            style="border: 1px solid #eee; margin-top: 10px; padding: 5px"
          >
            <div class="container" style="width: 822px; overflow-x: hidden">
              <div
                class="item-wrapper"
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  margin-top: 5px;
                "
              >
                <!-- 图片 -->
                <div
                  style="
                    height: 300px;
                    width: 300px;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  v-if="item.productUrl"
                >
                  <img
                    class="noObjectFit"
                    :src="item.productUrl"
                    alt=""
                    :style="
                      item.productStyle
                        ? item.productStyle
                        : { heght: '100%', width: '100%' }
                    "
                    @click="handlePreview(item.productUrl)"
                  />
                </div>

                <el-skeleton
                  v-else
                  style="width: 300px; height: 300px; margin-right: 10px"
                >
                  <template slot="template">
                    <el-skeleton-item variant="image" style="height: 300px" />
                  </template>
                </el-skeleton>

                <div
                  class="info-wrapper"
                  style="
                    width: 100%;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    min-height: 300px;
                  "
                >
                  <!-- 第一行 -->
                  <el-row style="display: flex; flex: 1 1 0%">
                    <el-col
                      class="label nobr nobb"
                      style="
                        font-weight: 650;
                        font-style: normal;
                        font-size: 14px;
                        color: #606266;
                        text-align: left;
                        word-break: break-word;
                        background-color: #f6f8f9;
                        width: 70px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        border-bottom: none;
                        border-right: none;
                      "
                    >
                      Sku number
                    </el-col>
                    <el-col
                      class="nobr nobb content"
                      style="
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        flex: 1;
                        border-bottom: none;
                        border-right: none;
                        color: #606266;
                      "
                    >
                      {{ getValue(item.productCode) }}
                    </el-col>
                    <el-col
                      class="label nobr nobb"
                      style="
                        font-weight: 650;
                        font-style: normal;
                        font-size: 14px;
                        color: #606266;
                        text-align: left;
                        word-break: break-word;
                        background-color: #f6f8f9;
                        width: 70px;
                        border: 1px solid #eee;
                        border-right: none;
                        border-bottom: none;
                        display: flex;
                        align-items: center;
                        padding: 2px;
                        word-break: break-all;
                      "
                    >
                      Item Name
                    </el-col>
                    <el-col
                      class="nobr nobb content"
                      style="
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        flex: 1;
                        border-bottom: none;
                        border-right: none;
                        word-break: break-word;
                        color: #606266;
                      "
                    >
                      {{ getValue(item.productEn) }}
                    </el-col>
                    <el-col
                      class="label nobr nobb"
                      style="
                        font-weight: 650;
                        font-style: normal;
                        font-size: 14px;
                        color: #606266;
                        text-align: left;
                        word-break: break-word;
                        background-color: #f6f8f9;
                        width: 70px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        border-bottom: none;
                        border-right: none;
                      "
                    >
                      Material
                    </el-col>
                    <el-col
                      class="nobb content"
                      style="
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        flex: 1;
                        border-bottom: none;
                        color: #606266;
                        word-break: break-word;
                      "
                    >
                      {{ getValue(item.material) }}
                    </el-col>
                  </el-row>

                  <!-- 第二行 -->
                  <el-row style="display: flex; flex: 1 1 0%">
                    <el-col
                      class="label nobr nobb"
                      :span="3"
                      style="
                        font-weight: 650;
                        font-style: normal;
                        font-size: 14px;
                        color: #606266;
                        text-align: left;
                        word-break: break-word;
                        background-color: #f6f8f9;
                        width: 70px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        border-bottom: none;
                        border-right: none;
                      "
                    >
                      Size
                    </el-col>
                    <el-col
                      class="nobr nobb content"
                      :span="5"
                      style="
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        flex: 1;
                        border-bottom: none;
                        border-right: none;
                        color: #606266;
                      "
                    >
                      {{ getchicun(item) }}
                    </el-col>
                    <el-col
                      class="label nobr nobb"
                      :span="3"
                      style="
                        font-weight: 650;
                        font-style: normal;
                        font-size: 14px;
                        color: #606266;
                        text-align: left;
                        word-break: break-word;
                        background-color: #f6f8f9;
                        width: 70px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        border-bottom: none;
                        border-right: none;
                      "
                    >
                      Imprint Area
                    </el-col>
                    <el-col
                      class="nobr nobb content"
                      :span="5"
                      style="
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        flex: 1;
                        border-bottom: none;
                        border-right: none;
                        color: #606266;
                      "
                    >
                      {{ getyinshuaquyuchicun(item) }}
                    </el-col>
                    <el-col
                      class="label nobr nobb"
                      :span="3"
                      style="
                        font-weight: 650;
                        font-style: normal;
                        font-size: 14px;
                        color: #606266;
                        text-align: left;
                        word-break: break-word;
                        background-color: #f6f8f9;
                        width: 70px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        border-bottom: none;
                        border-right: none;
                      "
                    >
                      Weight of Product
                    </el-col>
                    <el-col
                      class="nobb content"
                      :span="5"
                      style="
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        flex: 1;
                        border-bottom: none;
                        color: #606266;
                      "
                    >
                      {{ getLBS(item) }}
                    </el-col>
                  </el-row>

                  <!-- 第三行 -->
                  <el-row style="display: flex; flex: 1 1 0%">
                    <el-col
                      class="label nobr nobb"
                      :span="3"
                      style="
                        font-weight: 650;
                        font-style: normal;
                        font-size: 14px;
                        color: #606266;
                        text-align: left;
                        word-break: break-word;
                        background-color: #f6f8f9;
                        width: 70px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        border-bottom: none;
                        border-right: none;
                      "
                    >
                      Packing
                    </el-col>
                    <el-col
                      class="nobb content"
                      :span="21"
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        border: 1px solid #eee;
                        padding: 5px 2px;
                        flex: 1;
                        border-bottom: none;
                        color: #606266;
                      "
                    >
                      <p style="text-align: left; margin: 0">
                        {{ getnumberPerBox(item) }}
                      </p>

                      <p style="text-align: left; margin: 0">
                        {{ getnumberBoxIn(item) }}
                      </p>

                      <p style="text-align: left; margin: 0">
                        {{ getzhuangxiangbaozhong(item) }}
                      </p>
                    </el-col>
                  </el-row>

                  <!-- 第四行 -->
                  <el-row style="display: flex; flex: 1 1 0%">
                    <el-col
                      class="label nobr"
                      :span="3"
                      style="
                        font-weight: 650;
                        font-style: normal;
                        font-size: 14px;
                        color: #606266;
                        text-align: left;
                        word-break: break-word;
                        background-color: #f6f8f9;
                        width: 70px;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        border-right: none;
                      "
                    >
                      Item color
                    </el-col>
                    <el-col
                      class="nobr content"
                      :span="5"
                      style="
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        padding: 5px 2px;
                        flex: 1;
                        border-right: none;
                        color: #606266;
                      "
                    >
                      {{ getValue(item.color) }}
                    </el-col>
                    <template
                      v-if="
                        !['Blank', 'Sample'].includes(item.printingWay) ||
                        (item.manualTranslation == 1 &&
                          ['Blank', 'Sample'].includes(item.printingWay))
                      "
                    >
                      <el-col
                        class="label nobr"
                        :span="3"
                        style="
                          font-weight: 650;
                          font-style: normal;
                          font-size: 14px;
                          color: #606266;
                          text-align: left;
                          word-break: break-word;
                          background-color: #f6f8f9;
                          width: 70px;
                          border: 1px solid #eee;
                          display: flex;
                          align-items: center;
                          padding: 5px 2px;
                          border-right: none;
                        "
                      >
                        Print Color
                      </el-col>
                      <el-col
                        class="nobr content"
                        :span="5"
                        style="
                          border: 1px solid #eee;
                          display: flex;
                          align-items: center;
                          padding: 5px 2px;
                          flex: 1;
                          border-right: none;
                          color: #606266;
                        "
                      >
                        {{ getValue(item.waySelect) }}
                      </el-col>
                      <el-col
                        class="label nobr"
                        :span="3"
                        style="
                          font-weight: 650;
                          font-style: normal;
                          font-size: 14px;
                          color: #606266;
                          text-align: left;
                          word-break: break-word;
                          background-color: #f6f8f9;
                          width: 70px;
                          border: 1px solid #eee;
                          display: flex;
                          align-items: center;
                          padding: 5px 2px;
                          border-right: none;
                        "
                      >
                        Print Location
                      </el-col>
                      <el-col
                        :span="5"
                        class="content"
                        style="
                          border: 1px solid #eee;
                          display: flex;
                          align-items: center;
                          padding: 5px 2px;
                          flex: 1;
                          color: #606266;
                        "
                      >
                        <template v-if="item.manualTranslation != 1">
                          {{ getValue(item.digitSelect) }}
                          {{ item.digitSelect ? 'Side' : '' }}
                        </template>
                        <template v-else>
                          {{ getValue(item.printingPosition) }}
                        </template>
                      </el-col>
                    </template>
                    <template v-else>
                      <el-col
                        class="label nobr"
                        :span="3"
                        style="
                          font-weight: 650;
                          font-style: normal;
                          font-size: 14px;
                          color: #606266;
                          text-align: left;
                          word-break: break-word;
                          background-color: #f6f8f9;
                          width: 70px;
                          border: 1px solid #eee;
                          display: flex;
                          align-items: center;
                          padding: 5px 2px;
                          border-right: none;
                        "
                      >
                        Print Method
                      </el-col>
                      <el-col
                        class="content"
                        :span="13"
                        style="
                          border: 1px solid #eee;
                          display: flex;
                          align-items: center;
                          padding: 5px 2px;
                          flex: 1;
                        "
                      >
                        {{ item.printingWay }}
                      </el-col>
                    </template>
                  </el-row>
                </div>
              </div>

              <!-- 派送信息 -->
              <div
                v-if="item.deliveryToTheDoor != 0"
                style="padding: 10px 0; color: #606266"
              >
                <span>Ship To Address：</span>
                <span>
                  {{
                    `${item.shippingAddress ? item.shippingAddress : ''} ${
                      item.zipCode ? item.zipCode : emptyFlag
                    }`
                  }}
                </span>
                ,
                <span>Shipping Way：</span>
                <span>
                  {{ item.transportType | typeFilter }}
                </span>
              </div>
              <div v-else style="padding: 10px 0; color: #606266">
                <span>Ship To Address：</span>
                <span>FOB CN</span>
                ,
                <span>Shipping Way：</span>
                <span>
                  {{ item.transportType | typeFilter }}
                </span>
              </div>

              <div
                v-if="
                  item.printingWay !== 'Sample' || item.manualTranslation == 1
                "
                style="
                  page-break-inside: avoid !important;
                  border: 1px solid #ebeef5;
                  border-bottom: 0;
                  position: relative;
                  overflow: hidden;
                  flex: 1;
                  width: 822px;
                  max-width: 100%;
                  background-color: #ffffff;
                "
              >
                <div style="width: 822px; overflow: hidden">
                  <table cellspacing="0" cellpadding="0" style="width: 822px">
                    <thead>
                      <tr style="padding: 0; height: 38px">
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                            min-width: 80px;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              white-space: nowrap;
                              font-size: 14px;
                            "
                          >
                            Qty
                          </div>
                        </th>
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Net Price（pc）
                          </div>
                        </th>
                        <th
                          v-if="
                            item.printingWay !== 'Blank' &&
                            item.informationVOS &&
                            item.informationVOS.some(
                              (item) => item.additionalimprint
                            )
                          "
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Additional Imprint
                          </div>
                        </th>
                        <template
                          v-if="
                            item.addPriceProps &&
                            item.standardCustomized === '标准品'
                          "
                        >
                          <th
                            v-for="(s, index) in item.addPriceProps"
                            :key="index"
                            style="
                              border-bottom: 1px solid #ebeef5;
                              background: #f6f8f9;
                              border-right: 1px solid #ebeef5;
                            "
                          >
                            <div
                              style="
                                padding: 8px 10px;
                                font-weight: 650;
                                color: #606266;
                                text-align: left;
                                word-break: break-word;
                                font-size: 14px;
                              "
                            >
                              {{ s + ' Additional' }}
                            </div>
                          </th>
                        </template>
                        <th
                          v-if="
                            item.informationVOS &&
                            item.informationVOS.some((item) => item.fee)
                          "
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Setup Charge
                          </div>
                        </th>
                        <th
                          v-if="
                            item.informationVOS &&
                            item.informationVOS.some((item) => item.shippingFee)
                          "
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Shipping Fee
                          </div>
                        </th>
                        <template v-if="item.otherFeeProps.length > 0">
                          <th
                            v-for="(s, index) in item.otherFeeProps"
                            :key="index"
                            style="
                              border-bottom: 1px solid #ebeef5;
                              background: #f6f8f9;
                              border-right: 1px solid #ebeef5;
                            "
                          >
                            <div
                              style="
                                padding: 8px 10px;
                                font-weight: 650;
                                color: #606266;
                                text-align: left;
                                word-break: break-word;
                                font-size: 14px;
                              "
                            >
                              {{ s }}
                            </div>
                          </th>
                        </template>
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Production Time
                          </div>
                        </th>
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Shipping Time
                          </div>
                        </th>
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Total Amount
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="item.informationVOS.length > 0">
                      <tr v-for="(v, i) in item.informationVOS" :key="i">
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                            "
                          >
                            {{ v.num }}
                          </div>
                        </td>
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                            "
                          >
                            {{ v.netPrice ? '$' + v.netPrice : '--' }}
                          </div>
                        </td>
                        <template
                          v-if="
                            item.printingWay !== 'Blank' &&
                            item.informationVOS &&
                            item.informationVOS.some(
                              (item) => item.additionalimprint
                            )
                          "
                        >
                          <td
                            style="
                              height: 50px;
                              border-right: 1px solid #ebeef5;
                              color: #606266;
                              padding: 8px 10px;
                              border-bottom: 1px solid #ebeef5;
                            "
                          >
                            <div
                              style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                word-break: break-all;
                                line-height: 23px;
                                font-size: 14px;
                              "
                            >
                              {{
                                v.additionalimprint
                                  ? '$' + v.additionalimprint
                                  : '--'
                              }}
                            </div>
                          </td>
                        </template>
                        <template
                          v-if="
                            item.addPriceProps &&
                            item.standardCustomized === '标准品'
                          "
                        >
                          <td
                            v-for="(s, index) in item.addPriceProps"
                            :key="index"
                            style="
                              height: 50px;
                              border-right: 1px solid #ebeef5;
                              color: #606266;
                              padding: 8px 10px;
                              border-bottom: 1px solid #ebeef5;
                            "
                          >
                            <div
                              style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                word-break: break-all;
                                line-height: 23px;
                                font-size: 14px;
                              "
                            >
                              {{ v.s ? '$' + v.s : '--' }}
                            </div>
                          </td>
                        </template>
                        <!-- Setup Charge -->
                        <td
                          v-if="
                            item.informationVOS &&
                            item.informationVOS.some((item) => item.fee)
                          "
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                            "
                          >
                            {{ v.fee ? '$' + v.fee : '--' }}
                          </div>
                        </td>
                        <!-- Shipping Fee -->
                        <td
                          v-if="
                            item.informationVOS &&
                            item.informationVOS.some((item) => item.shippingFee)
                          "
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                            "
                          >
                            {{ v.shippingFee ? '$' + v.shippingFee : '--' }}
                          </div>
                        </td>
                        <!-- otherFeeProps -->
                        <template v-if="item.otherFeeProps.length > 0">
                          <td
                            v-for="(s, index) in item.otherFeeProps"
                            :key="index"
                            style="
                              height: 50px;
                              border-right: 1px solid #ebeef5;
                              color: #606266;
                              padding: 8px 10px;
                              border-bottom: 1px solid #ebeef5;
                            "
                          >
                            <div
                              style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                word-break: break-all;
                                line-height: 23px;
                                font-size: 14px;
                              "
                            >
                              {{ '$' + v.expenseNameList[index].amount }}
                            </div>
                          </td>
                        </template>
                        <!-- production Time -->
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                              color: #606266;
                            "
                          >
                            <span style="word-break: break-word">
                              {{ getValue(v.productionTime) }}
                            </span>
                            <span v-if="v.productionTime">
                              {{
                                v.productionType == 0
                                  ? 'Working Days'
                                  : 'Calendar Days'
                              }}
                            </span>
                          </div>
                        </td>
                        <!-- Shipping Time -->
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                              color: #606266;
                            "
                          >
                            <span style="word-break: break-word">
                              {{ getValue(v.shippingTime) }}
                            </span>
                            <span v-if="v.shippingTime">
                              {{
                                v.shippingType == 0
                                  ? 'Working Days'
                                  : 'Calendar Days'
                              }}
                            </span>
                          </div>
                        </td>
                        <!-- Total Amount -->
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                            "
                          >
                            <span style="color: #4b7902">
                              {{ `$${v.totalAmount}` }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr
                        style="
                          height: 60px;
                          line-height: 60px;
                          text-align: center;
                          color: #909399;
                          font-size: 12px;
                        "
                      >
                        <td
                          style="
                            border-bottom: 1px solid #ebeef5;
                            border-right: 1px solid #ebeef5;
                          "
                          colspan="5"
                        >
                          {{ lang === 'en' ? 'No Data.' : '暂无数据' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- sample时样品报价 -->
              <div
                v-else
                style="
                  page-break-inside: avoid !important;
                  border: 1px solid #ebeef5;
                  border-bottom: 0;
                  position: relative;
                  overflow: hidden;
                  flex: 1;
                  width: 822px;
                  max-width: 100%;
                  background-color: #ffffff;
                "
              >
                <div style="width: 822px; overflow: hidden">
                  <table cellspacing="0" cellpadding="0" style="width: 822px">
                    <thead>
                      <tr style="padding: 0; height: 38px">
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Sample Qty
                          </div>
                        </th>
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Sample Price（pc）
                          </div>
                        </th>
                        <th
                          v-if="
                            item.informationVOS &&
                            item.informationVOS.some((item) => item.shippingFee)
                          "
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Shipping Fee
                          </div>
                        </th>
                        <template v-if="item.otherFeeProps.length > 0">
                          <th
                            v-for="(s, index) in item.otherFeeProps"
                            :key="index"
                            style="
                              border-bottom: 1px solid #ebeef5;
                              background: #f6f8f9;
                              border-right: 1px solid #ebeef5;
                            "
                          >
                            <div
                              style="
                                padding: 8px 10px;
                                font-weight: 650;
                                color: #606266;
                                text-align: left;
                                word-break: break-word;
                                font-size: 14px;
                              "
                            >
                              {{ s }}
                            </div>
                          </th>
                        </template>
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Sample Time
                          </div>
                        </th>
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Shipping Time
                          </div>
                        </th>
                        <th
                          style="
                            border-bottom: 1px solid #ebeef5;
                            background: #f6f8f9;
                            border-right: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              padding: 8px 10px;
                              font-weight: 650;
                              color: #606266;
                              text-align: left;
                              word-break: break-word;
                              font-size: 14px;
                            "
                          >
                            Total Amount
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="item.informationVOS.length > 0">
                      <tr v-for="(v, i) in item.informationVOS" :key="i">
                        <!-- Sample Qty -->
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                            "
                          >
                            {{ v.num }}
                          </div>
                        </td>
                        <!-- Sample Price（pc） -->
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                            "
                          >
                            {{ v.netPrice ? '$' + v.netPrice : '--' }}
                          </div>
                        </td>
                        <!-- Shipping Fee -->
                        <template
                          v-if="
                            item.informationVOS &&
                            item.informationVOS.some((item) => item.shippingFee)
                          "
                        >
                          <td
                            style="
                              height: 50px;
                              border-right: 1px solid #ebeef5;
                              color: #606266;
                              padding: 8px 10px;
                              border-bottom: 1px solid #ebeef5;
                            "
                          >
                            <div
                              style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                word-break: break-all;
                                line-height: 23px;
                                font-size: 14px;
                              "
                            >
                              {{ v.shippingFee ? '$' + v.shippingFee : '--' }}
                            </div>
                          </td>
                        </template>
                        <template v-if="item.otherFeeProps.length > 0">
                          <td
                            v-for="(s, index) in item.otherFeeProps"
                            :key="index"
                            style="
                              height: 50px;
                              border-right: 1px solid #ebeef5;
                              color: #606266;
                              padding: 8px 10px;
                              border-bottom: 1px solid #ebeef5;
                            "
                          >
                            <div
                              style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                word-break: break-all;
                                line-height: 23px;
                                font-size: 14px;
                              "
                            >
                              {{ '$' + v.expenseNameList[index].amount }}
                            </div>
                          </td>
                        </template>
                        <!-- Sample Time -->
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                              color: #606266;
                            "
                          >
                            <span style="word-break: break-word">
                              {{ getValue(v.productionTime) }}
                            </span>
                            <span v-if="v.productionTime">
                              {{
                                v.productionType == 0
                                  ? 'Working Days'
                                  : 'Calendar Days'
                              }}
                            </span>
                          </div>
                        </td>
                        <!-- Shipping Time -->
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                              color: #606266;
                            "
                          >
                            <span style="word-break: break-word">
                              {{ getValue(v.shippingTime) }}
                            </span>
                            <span v-if="v.shippingTime">
                              {{
                                v.shippingType == 0
                                  ? 'Working Days'
                                  : 'Calendar Days'
                              }}909399
                            </span>
                          </div>
                        </td>
                        <!-- Total Amount -->
                        <td
                          style="
                            height: 50px;
                            border-right: 1px solid #ebeef5;
                            color: #606266;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ebeef5;
                          "
                        >
                          <div
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: normal;
                              word-break: break-all;
                              line-height: 23px;
                              font-size: 14px;
                            "
                          >
                            <span style="color: #4b7902">
                              {{ `$${v.totalAmount}` }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr
                        style="
                          height: 60px;
                          line-height: 60px;
                          text-align: center;
                          color: #909399;
                          font-size: 12px;
                        "
                      >
                        <td
                          style="
                            border-bottom: 1px solid #ebeef5;
                            border-right: 1px solid #ebeef5;
                          "
                          colspan="5"
                        >
                          {{ lang === 'en' ? 'No Data.' : '暂无数据' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-if="item.remark" style="padding: 5px 0">
                Remarks: {{ item.remark }}
              </div>
            </div>
          </div>
        </div>
        <!-- 图片预览 -->
        <el-image-viewer
          v-if="showViewer"
          :on-close="closeViewer"
          :url-list="[imgUrl]"
        />
      </div>

      <div
        style="
          height: 20px;
          margin-top: 10px;
          color: #606266;
          padding-left: 5px;
        "
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const emptyFlag = '--'
export default {
  name: 'OfferSheetContent',
  props: {
    offerData: {
      type: Object,
      required: true,
    },
    isPrint: {
      type: Boolean,
      default: false,
    },
    rise: {
      type: String,
      default: 'RG',
    },
  },
  data() {
    return {
      emptyFlag,
      showViewer: false,
      imgUrl: '',
      RG: require('@/assets/images/RG.png'),
      RP: require('@/assets/images/RP.png'),
      SW: require('@/assets/images/SW.png'),
    }
  },
  filters: {
    typeFilter(val) {
      switch (val) {
        case '1':
          return 'By Air'
        case '2':
          return 'By Sea'
        case '3':
          return 'By Express'
      }
    },
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  methods: {
    formatter(row, column, cellValue, index) {
      return cellValue ? `$${cellValue}` : '--'
    },
    // 大图预览
    handlePreview(item) {
      this.imgUrl = item
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },
    getValue(value) {
      return value ? value : emptyFlag
    },
    // 获取尺寸
    getchicun(item) {
      if (!item.specsVOS) return emptyFlag
      const spec = item.specsVOS[0]
      if (!spec) return emptyFlag
      if (spec.sizeUnit?.toUpperCase() == 'IN') {
        return this.getValue(
          `${spec.productLengthCm || 0}" x ${spec.productWidthCm || 0}" x ${
            spec.productHeightCm || 0
          }"`
        )
      } else {
        return this.getValue(
          `${spec.productLengthIn || 0}" x ${spec.productWidthIn || 0}" x ${
            spec.productHeightIn || 0
          }"`
        )
      }
    },
    // 印刷区域尺寸
    getyinshuaquyuchicun(item) {
      if (!item.specsVOS) return emptyFlag
      const spec = item.specsVOS[0]
      if (!spec) return emptyFlag
      if (!spec.printLengthCovert && !spec.printWidthCovert) {
        return emptyFlag
      }
      if (spec.printUnit?.toUpperCase() == 'IN') {
        if (!spec.printLength) {
          return `${spec.spec.printWidth}"`
        }
        if (!spec.printWidthCovert) {
          return `${spec.printLength}"`
        }
        if (spec.printLength && spec.printWidth) {
          return `${spec.printLength}" x ${spec.printWidth}"`
        }
      } else {
        if (!spec.printLengthCovert) {
          return `${spec.spec.printWidthCovert}"`
        }
        if (!spec.printWidthCovert) {
          return `${spec.printLengthCovert}"`
        }
        if (spec.printLengthCovert && spec.printWidthCovert) {
          return `${spec.printLengthCovert}" x ${spec.printWidthCovert}"`
        }
      }
    },
    // 获取LBS
    getLBS(item) {
      if (!item.specsVOS) return emptyFlag
      const spec = item.specsVOS[0]
      if (!spec) return emptyFlag
      return (spec?.productWeightKgs * 2.2046).toFixed(1) + 'lbs'
    },
    // 装箱数量
    getnumberPerBox(item) {
      if (!item.specsVOS) return emptyFlag
      const spec = item.specsVOS[0]
      if (!spec) return emptyFlag
      return `${spec.numberPerBox}pcs/carton`
    },
    // 获取装箱尺寸
    getnumberBoxIn(item) {
      if (!item.specsVOS) return emptyFlag
      const spec = item.specsVOS[0]
      if (!spec) return emptyFlag
      return this.getValue(
        `${spec.boxLengthCm}cm x ${spec.boxWidthCm}cm x ${spec.boxHeightCm}cm`
      )
    },
    // 获取装箱毛重
    getzhuangxiangbaozhong(item) {
      if (!item.specsVOS) return emptyFlag
      const spec = item.specsVOS[0]
      if (!spec) return emptyFlag
      return `GW:${Math.round(spec?.boxWeightKgs)}KGS`
    },
  },
}
</script>

