<template>
  <div class="custom-container" v-if="isAdd">
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="60px"
      class="mt10"
    >
      <el-row>
        <el-col :span="10">
          <el-form-item label="条件" prop="condition">
            <el-select
              v-model="form.condition"
              multiple
              @change="conditionChange"
              placeholder="请选择"
              clearable
            >
              <el-option-group
                v-for="group in options"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.options"
                  :key="item.code"
                  :label="item.message"
                  :value="item.code"
                  :disabled="item.disabled"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item label="结果">
            <el-select
              v-model="form.result"
              placeholder="请选择"
              clearable
              :disabled="true"
            >
              <el-option
                v-for="item in resOptions"
                :key="item.code"
                :label="item.message"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-button class="ml20" type="primary" @click="Save">
            暂存下方
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div class="condition-box">
      <h4 class="ml20">条件暂存区</h4>
      <div v-if="form.configConditionDTOs.length">
        <ul>
          <li v-for="(item, i) in form.configConditionDTOs" :key="i">
            <span class="index">{{ i + 1 }}</span>
            <span class="text ell">
              {{ getResultText(item) }}
            </span>
            <span class="red c_pointer" @click="Del(i)">删除</span>
          </li>
        </ul>
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
    </div>

    <div class="oper-btn text-center mt20">
      <el-button type="default" @click="Cancel">返回</el-button>
      <el-button type="primary" @click="Submit">保存</el-button>
    </div>
  </div>
</template>

<script>
import { FinanceConfigSetting } from '@/core/interactors/finance/config-manage'
export default {
  name: 'ConditionConfig',
  components: {},
  props: ['isAdd', 'areasCode', 'conditionOptions'],
  // 组件状态值
  data() {
    return {
      form: {
        condition: '', // 条件
        result: '', // 结果
        configConditionDTOs: [], // 暂存数据
      },
      options: [
        {
          label: '人员角色',
          options: [],
        },
        {
          label: '所属地区',
          options: [],
        },
      ],
      resOptions: [],
      rules: {
        condition: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
    }
  },
  computed: {},
  watch: {
    isAdd(val) {
      if (val) {
        this.getConditionOptions()
      }
    },
  },
  methods: {
    // 获取条件配置数据
    getConditionOptions() {
      this.options[0].options = this.conditionOptions.settlementRoleVO.map(
        (item) => {
          return {
            ...item,
            disabled: false,
          }
        }
      )
      this.options[1].options = this.conditionOptions.settlementAreaVO.map(
        (item) => {
          return {
            ...item,
            disabled: false,
          }
        }
      )
      this.resOptions = [
        ...this.conditionOptions.settlementAreaResultVO,
        ...this.conditionOptions.settlementRoleResultVO,
      ]
    },

    // 条件下拉框事件
    conditionChange(val) {
      const opt = this.options

      if (val.length === 0) {
        opt[0].options.map((item) => (item.disabled = false))
        opt[1].options.map((item) => (item.disabled = false))
      } else if (val.length === 1) {
        if (opt[0].options.find((item) => item.code === val[0])) {
          opt[1].options.map((item) => (item.disabled = true))
        } else {
          opt[0].options.map((item) => (item.disabled = true))
        }
      } else {
        // true:同一个地区， false:同一个人
        if (this.areasCode.includes(val[0])) {
          this.form.result = this.resOptions.find(
            (item) => item.code === 'SAME_AREA'
          ).code
        } else {
          this.form.result = this.resOptions.find(
            (item) => item.code === 'SAME_PERSON'
          ).code
        }
      }
    },

    // 取消
    Cancel() {
      if (this.form.configConditionDTOs.length > 0) {
        this.$confirm(`当前页数据未保存，是否返回?`, '提示', {
          type: 'warning',
        })
          .then(() => {
            this.$emit('Cancel')
          })
          .catch(() => {})
      } else {
        this.$emit('Cancel')
      }
      this.empty()
    },

    // 暂存下方
    Save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.form.condition.length < 2) {
            this.$message.warning('条件最少勾选2个')
            return false
          }

          let [condition, result, opt, arr, type] = [
            this.form.condition,
            this.form.result,
            this.options,
            [],
            0,
          ]
          /**
           * @desc 处理保存数据格式，根据code匹配下拉项
           */
          // true:匹配地区 false:匹配人员
          if (this.areasCode.includes(condition[0])) {
            arr = opt[1].options
            type = 2
          } else {
            for (let i = 0; i < condition.length; i++) {
              opt[0].options.find((item) =>
                item.code === condition[i] ? arr.push(item) : ''
              )
            }
            type = 1
          }

          this.form.configConditionDTOs.push({
            conditionResult: arr,
            result: [this.resOptions.find((item) => item.code === result)],
            type: type,
          })
          this.empty()
        }
      })
    },

    // 保存
    async Submit() {
      if (!this.form.configConditionDTOs.length) {
        this.$message.warning('至少添加一条数据')
        return false
      }

      let res = await FinanceConfigSetting.conditionAdds(
        this.form.configConditionDTOs
      )
      if (res.code === '000000') {
        this.$message.success('新增成功')
        this.form.configConditionDTOs = []
        this.empty()
        this.$emit('Add')
      }
    },

    // 条件内容格式化
    getResultText(item) {
      let [arr1, arr2, text1] = [item.conditionResult, item.result, '']
      for (let i = 0; i < arr1.length; i++) {
        text1 +=
          '【' + arr1[i].message + '】' + (arr1.length === i + 1 ? '' : '和')
      }
      return text1 + ' = ' + arr2[0].message
    },

    // 删除
    Del(i) {
      this.$confirm(`是否删除此条数据?`, '提示', { type: 'warning' })
        .then(() => {
          this.form.configConditionDTOs.splice(i, 1)
        })
        .catch(() => {})
    },

    // 清空数据
    empty() {
      this.form.condition = ''
      this.form.result = ''
      this.conditionChange([])
    },
  },
}
</script>

<style scoped lang="scss">
.condition-box {
  height: calc(100vh - 240px);
  > div {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
    ul {
      margin: 0 20px;
      li {
        width: 100%;
        height: 44px;
        line-height: 44px;
        border-bottom: 1px dashed #eee;
        list-style: none;
        display: flex;
        .index {
          width: 40px;
          font-size: 16px;
        }
        .text {
          width: calc(100% - 80px);
        }
      }
    }
  }
}
.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
}
::v-deep {
  .el-select {
    width: 100%;
  }
  .el-select-group__title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  .el-select-group li {
    text-indent: 10px;
  }
}
</style>
