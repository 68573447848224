
export const checkoutType = [{
  name: '先进先出',
  code: 1,
}, {
  name: '指定批次',
  code: 2,
}]

export const valuationType = [{
  name: '批次计价法',
  code: 1,
}, {
  name: '移动加权',
  code: 2,
}]
export const alter = [{
  name: '是',
  code: 1,
}, {
  name: '否',
  code: 0,
}]
