var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showTable)?_c('el-popover',{attrs:{"placement":"bottom","trigger":_vm.triggerType}},[_c('el-table',{attrs:{"data":_vm.tableData,"border":"","cell-style":{ 'text-align': 'center' },"header-row-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"width":"100","label":"产品图"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('erp-image',{attrs:{"imgs":scope.row.productImg}})]}}],null,false,3486331004)}),_c('el-table-column',{attrs:{"width":"100","property":"productCode","label":"产品编码"}}),_c('el-table-column',{attrs:{"width":"150","label":"中文品名/英文品名"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.nameCn || '--')+"/"+_vm._s(scope.row.nameEn || '--')+" ")])]}}],null,false,1007909410)}),_c('el-table-column',{attrs:{"width":"80","property":"productSpec","label":"规格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.productSpec || '--')+" ")]}}],null,false,4281837395)}),_c('el-table-column',{attrs:{"width":"100","property":"num","label":_vm.numName},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.num || '--')+" ")]}}],null,false,2510078379)})],1),_c('span',{staticClass:"popover-title",class:_vm.itemTitle === '主'
          ? 'green-box'
          : _vm.itemTitle === '子'
          ? 'origin-box'
          : '',attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.itemTitle)+" ")])],1):_c('span',{staticClass:"popover-title",class:_vm.itemTitle === '主'
        ? 'green-box'
        : _vm.itemTitle === '子'
        ? 'origin-box'
        : '',attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.itemTitle)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }