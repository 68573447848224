<template>
  <!-- title="移除分管人" -->
  <el-dialog
    :title="$t('cusDetail.RemoveAssignee')"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item
          prop="managerId"
          :rules="[
            {
              required: true,
              trigger: 'blur',
              message: $t('placeholder.plsSel'),
            },
          ]"
        >
          <el-select
            v-model="form.managerId"
            :placeholder="$t('placeholder.plsSel')"
            multiple
          >
            <el-option
              v-for="item in options"
              :key="item.managerId"
              :label="lang !== 'en' ? item.managerName : item.managerNameEn"
              :value="item.managerId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <!-- <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button> -->
        <el-button type="primary" @click="save">
          {{ $t('cusDetail.Confirm') }}
        </el-button>
        <el-button @click="close">
          {{ $t('cusDetail.Cancel') }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import {
  queryBranchedList,
  removeBranchedList,
} from '@/api/client-supplier/client-manage'
import { mapGetters } from 'vuex'
export default {
  name: 'RemoveAppraiseModel',
  data() {
    return {
      form: {
        managerId: '',
        email: '',
      },
      rules: {},
      options: [],
      dialogFormVisible: false,
      transferParams: {},
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  methods: {
    //新增
    showAddEdit(customerId, email, transferParams) {
      this.dialogFormVisible = true
      this.form.email = email
      this.transferParams = transferParams
      this.getGueryBranchedList(customerId)
    },

    //获取分管人下拉数据
    async getGueryBranchedList(customerId) {
      let response = await queryBranchedList({ infoId: customerId })
      if (response.code === '000000') {
        this.options = response.data
      }
    },

    //关闭
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },

    //保存
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let form = this.utils.deepCopy(this.form)
          let _arr = []
          this.options.forEach((item) => {
            if (form.managerId.includes(item.managerId)) {
              _arr.push(item.belongerId)
            }
          })
          this.transferParams.defaultAddressUserIds = _arr

          if (Array.isArray(form.managerId)) {
            form.managerId = form.managerId.join(',')
          }

          const msg =
            this.lang === 'en'
              ? 'Whether to remove the current pre-distribution human rights restriction'
              : '是否移除当前分管人权限！'
          this.$confirm(msg, this.lang === 'en' ? 'Info' : '提示', {
            confirmButtonText: this.lang === 'en' ? 'Confirm' : '确定',
            cancelButtonText: this.lang === 'en' ? 'Cancel' : '取消',
            type: 'warning',
          }).then(async () => {
            let response = await removeBranchedList({
              ...form,
              companyIds: this.transferParams.companyIds,
              defaultAddressUserIds: this.transferParams.defaultAddressUserIds,
            })
            if (response.code === '000000') {
              this.$baseMessage(
                // '操作成功',
                this.$t('reqmsg.$7'),
                'success',
                false,
                'erp-hey-message-success'
              )
            }
            this.$emit('fetch-data')
            this.close()
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>
