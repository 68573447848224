<!--
  功能：入库单详情基础 区分三种类型
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月28日 13:50:01
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="custom-detail">
    <!-- 其他入库基本信息 -->
    <template v-if="detail.type == '2' || detail.type == '10' || detail.type == '11'">
      <el-row>
        <el-col>
          <em style="width: 85px">入库方式：</em>
          <span style="width: calc(100% - 85px)">
            {{ detail.type | typeFilter }}
          </span>
        </el-col>
        <el-col>
          <em>入库仓库：</em>
          <span>
            {{ detail.warehouseName | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <em>创建人:</em>
          <span>{{ detail.creator | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>所属地区:</em>
          <span>{{ detail.area | emptyFilter }}</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <em>创建时间:</em>
          <span>{{ detail.createTime | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>操作人:</em>
          <span>{{ detail.operationName | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>入库时间:</em>
          <span>{{ detail.warehouseInDate | emptyFilter }}</span>
        </el-col>
        <el-col v-if="detail.type == 10">
          <em>调拨单号:</em>
          <span class="page-link-noline" @click="showDetail(detail.orderNumber)">
            {{ detail.orderNumber }}
          </span>
        </el-col>
        <el-col v-else-if="detail.type == 11">
          <em>盘点单号:</em>
          <span class="page-link-noline" @click="showDetail(detail.orderNumber)">
            {{ detail.orderNumber }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="width: 100%">
          <em style="vertical-align: top">备注:</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
      </el-row>
    </template>
    <!-- 采购入库基本信息 -->
    <template v-else-if="detail.type == '0'">
      <!-- 采购入库备货采购详情 -->
      <template v-if="!isBeihuoCaigou">
        <el-row>
          <el-col>
            <em style="width: 85px">入库方式：</em>
            <span style="width: calc(100% - 85px)">
              {{ detail.type | typeFilter }}
            </span>
          </el-col>
          <el-col>
            <em>入库仓库：</em>
            <span>
              {{ detail.warehouseName | emptyFilter }}
            </span>
          </el-col>
          <el-col>
            <em>采购单号:</em>
            <span class="el-link el-link--primary" v-if="detail.orderNumber" @click="showDetail(detail.orderNumber)">
              {{ detail.orderNumber | emptyFilter }}
            </span>
            <span v-else>-</span>
          </el-col>
          <el-col>
            <em style="width: 55px">供应商:</em>
            <span>{{ detail.supplierName | emptyFilter }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <em style="width: 85px">采购开发：</em>
            <span style="width: calc(100% - 85px)">
              {{ detail.buyer | emptyFilter }}
            </span>
          </el-col>
          <el-col>
            <em>创建人：</em>
            <span>
              {{ detail.creator | emptyFilter }}
            </span>
          </el-col>
          <el-col>
            <em>所属地区：</em>
            <span>{{ detail.area | emptyFilter }}</span>
          </el-col>
          <el-col>
            <em>创建时间：</em>
            <span>{{ detail.createTime | emptyFilter }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="width: 100%">
            <em style="vertical-align: top">备注：</em>
            <span>{{ detail.remark | emptyFilter }}</span>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <el-row>
          <el-col>
            <em style="width: 85px">入库方式：</em>
            <span style="width: calc(100% - 85px)">
              {{ detail.type | typeFilter }}
            </span>
          </el-col>
          <el-col>
            <em>入库仓库：</em>
            <span>
              {{ detail.warehouseName | emptyFilter }}
            </span>
          </el-col>
          <el-col>
            <em>采购单号:</em>
            <span class="el-link el-link--primary" v-if="detail.orderNumber" @click="showDetail(detail.orderNumber)">
              {{ detail.orderNumber | emptyFilter }}
            </span>
            <span v-else>-</span>
          </el-col>
          <el-col>
            <em style="width: 55px">供应商:</em>
            <span>{{ detail.supplierName | emptyFilter }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <em style="width: 85px">采购开发：</em>
            <span style="width: calc(100% - 85px)">
              {{ detail.buyer | emptyFilter }}
            </span>
          </el-col>

          <el-col>
            <em>所属地区：</em>
            <span>{{ detail.area | emptyFilter }}</span>
          </el-col>
          <el-col>
            <em>入库时间：</em>
            <span>{{ detail.warehouseInDate | emptyFilter }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="width: 100%">
            <em style="vertical-align: top">备注：</em>
            <span>{{ detail.remark | emptyFilter }}</span>
          </el-col>
        </el-row>
      </template>
    </template>
    <!-- 加工入库或退料入库基本信息 -->
    <template v-else-if="detail.type == '14' || detail.type == '15'">
      <el-row>
        <el-col>
          <em style="width: 85px">入库方式：</em>
          <span style="width: calc(100% - 85px)">
            {{ detail.type | typeFilter }}
          </span>
        </el-col>
        <el-col>
          <em>入库仓库：</em>
          <span>
            {{ detail.warehouseName | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <em>销售单号:</em>
          <span class="el-link el-link--primary" v-if="detail.orderNumber"
            @click="showDetail(detail.salesOrderNumber, 15)">
            {{ detail.salesOrderNumber | emptyFilter }}
          </span>

          <span v-else>-</span>
        </el-col>
        <el-col>
          <em>加工单号:</em>
          <span class="el-link el-link--primary" v-if="detail.orderNumber" @click="showDetail(detail.orderNumber, 16)">
            {{ detail.orderNumber | emptyFilter }}
          </span>

          <span v-else>-</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <em>创建人：</em>
          <span>{{ detail.creator | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>所属地区：</em>
          <span>{{ detail.area | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>操作人：</em>
          <span>{{ detail.operationName | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col style="width: 100%">
          <em style="vertical-align: top">备注：</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
      </el-row>
    </template>
    <!-- 退货入库基本信息 -->
    <template v-else>
      <el-row>
        <el-col>
          <em style="width: 85px">入库方式：</em>
          <span style="width: calc(100% - 85px)">
            {{ detail.type | typeFilter }}
          </span>
        </el-col>
        <el-col>
          <em>入库仓库：</em>
          <span>
            {{ detail.warehouseName | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <em>销售单号：</em>

          <span class="el-link el-link--primary" v-if="detail.orderNumber" @click="showDetail(detail.orderNumber)">
            {{ detail.orderNumber }}
          </span>
          <span v-else>-</span>
        </el-col>
        <el-col>
          <em>客户代表：</em>
          <span>{{ detail.businessName | emptyFilter }}</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <em>客户名称：</em>
          <span>{{ detail.customerName | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>创建人：</em>
          <span>{{ detail.creator | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>所属地区：</em>
          <span>{{ detail.area | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>创建时间：</em>
          <span>{{ detail.createTime | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <em>原因说明：</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  export default {
    name: 'BaseInboundDetail',
    props: {
      detail: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      isBeihuoCaigou() {
        return (
          this.detail.type == '0' &&
          this.detail.warehouseInProductVOList[0].orderProductId
        )
      },
    },
    methods: {
      showDetail(orderNumber, type) {
        type = type || this.detail.type
        const type2strat = {
          0: 'purchaseOrderDetail',
          1:'salesOrderDetail',
          3: 'salesOrderDetail',
          10: 'allotInquireDetail',
          11: 'stockCheckDetail',
          15: 'salesOrderDetail',
          16: 'outprocessingDetail',
        }
        return navigateTo(this, {
          orderNumber,
          showType: 'id',
          pageType: PAGE_TYPES[type2strat[type]],
        })
      },
    },
    filters: {
      emptyFilter(val) {
        return val ? val : '--'
      },
      typeFilter(val) {
        if (val == 1) {
          return '退货入库'
        } else if (val == 2) {
          return '其他入库'
        } else if (val == 10) {
          return '调拨入库'
        } else if (val == 11) {
          return '盘盈入库'
        } else if (val == 14) {
          return '退料入库'
        } else if (val == 15) {
          return '加工入库'
        } else {
          return '采购入库'
        }
      },
    },
  }
</script>

<style scoped></style>
