<template>
  <IndexCard title="收款确认" :tabs="tabs" @tab-click="handleTabClick" :loading="loading" :singleNum="singleNum"
    :teamNum="teamNum">
    <div v-if="list && list.length">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="collection-notice-item">
          <div class="content" @click="clientFollowClick(item)" :style="itemStyleObj">
            <div class="mails-item--top">
              订单
              <span class="mail-code" :style="boldStyle.bold">
                {{ item.orderCode }}
              </span>
              有一条金额为
              <span class="mail-code" :style="boldStyle.bold">
                {{ item.currency === '美元' ? '$' : '￥'
                }}{{ item.totlePrice ? item.totlePrice : '--' }}
              </span>
              的收款单
            </div>
            <div class="mails-item--bottom">
              <p>
                <span class="label">客户代表:</span>
                <span class="content">{{ item.businessName }}</span>
              </p>
              <p>
                <span>{{ item.createTime }}</span>
              </p>
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
    <CollectionConfirmDialog ref="collectionConfirmDialog" @refresh="getList"/>
  </IndexCard>
</template>

<script>
  import {
    HomeInteractor,
  } from '@/core'
  import mixins from '../mixins'

  import CollectionConfirmDialog from '@/components/CollectionConfirmDialog'
  export default {
    name: 'CollectionConfirm',
    components: { CollectionConfirmDialog },
    mixins: [mixins],
    data() {
      return {
        list: [],
        tabs: true,
      }
    },

    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.show = false
        this.loading = true
        const requestData = {
          queryType: this.active === 'ME' ? 1 : 2,
          pageLe: 99999,
          pageNo: 1,
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
        }
        HomeInteractor.collectionConfirmList(requestData).then((res) => {
          if (res && res.code === '000000') {
            const { singleTotal, teamTotal } = res.data || {}
            this.list = res.data.collectionConfirmList || {}
            this.singleNum = singleTotal || 0
            this.teamNum = teamTotal || 0
          } else {
            this.list = []
          }
          this.loading = false
        })
      },

      //确认单详情点击
      clientFollowClick(item) {
        if (this.active === 'GROUP') return

        const {financeOrderId, financeCode, financeId,remark} = item
        this.$refs.collectionConfirmDialog.showAddEdit({
          financeOrderId,
          financeCode,
          financeId,
          remark
        })

      },


    },
  }
</script>

<style scoped lang="scss">
  .collection-notice-item {
    font-size: 14px;

    .mails-item--top {
      margin-bottom: 6px;
      color: #000;

      .mail-code {
        font-family: Semibold;
      }

      .sender {
        font-family: Regular;
      }

      .topic {
        font-family: Semibold;
      }
    }

    .mails-item--bottom {
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      p {
        color: #5e5e5e;
        font-family: Regular;

        .label {
          margin-right: 5px;
        }
      }
    }
  }

  .erpdialog-detail-style {
    .el-row {
      label {
        color: #666;
      }

      p {
        color: #000;
        font-size: 16px;
        white-space: nowrap;
        margin-top: 5px;
      }
    }

    .link {
      color: #46a6ff;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .green-bold {
    color: green;
    font-weight: bold;
  }

  .no-text {
    color: #999 !important;
  }
</style>
