<template>
  <div class="search_form">
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item label="签约日期">
        <div class="to_input">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            placeholder="年/月/日"
          />
          <span>至</span>
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            placeholder="年/月/日"
          />
        </div>
      </el-form-item>
      <el-form-item label="产品编号">
        <el-input v-model="form.productCode" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="产品名称">
        <el-input v-model="form.productEn" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="产品类别">
        <el-select
          v-model="form.enquiryStatus"
          placeholder="请选择"
          class="w100"
        >
          <el-option label="待报价" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="效果图状态">
        <el-select
          v-model="form.enquiryStatus"
          placeholder="请选择"
          class="w100"
        >
          <el-option label="待报价" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="产品数量">
        <div class="to_input">
          <el-input
            v-model="form.enquiryBegin"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input v-model="form.enquiryEnd" placeholder="请输入" clearable />
        </div>
      </el-form-item>
      <el-form-item label="总金额">
        <div class="to_input">
          <el-input
            v-model="form.enquiryBegin"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input v-model="form.enquiryEnd" placeholder="请输入" clearable />
        </div>
      </el-form-item>
      <el-form-item label="客户代表">
        <el-input v-model="form.companyName" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="订单支持">
        <el-input v-model="form.customerPhone" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="公司名称">
        <el-input v-model="form.companyName" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="客户订单号">
        <el-input v-model="form.customerPhone" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="订单来源">
        <el-select
          v-model="form.enquiryStatus"
          placeholder="请选择"
          class="w100"
        >
          <el-option label="待报价" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="订单状态">
        <el-select
          v-model="form.enquiryStatus"
          placeholder="请选择"
          class="w100"
        >
          <el-option label="待报价" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="收款进度">
        <div class="to_input">
          <el-input
            v-model="form.enquiryBegin"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input v-model="form.enquiryEnd" placeholder="请输入" clearable />
        </div>
      </el-form-item>
      <el-form-item label="地区">
        <el-select
          v-model="form.enquiryStatus"
          placeholder="请选择"
          class="w100"
        >
          <el-option label="待报价" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="签收日期">
        <div class="to_input">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            placeholder="年/月/日"
          />
          <span>至</span>
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            placeholder="年/月/日"
          />
        </div>
      </el-form-item>
      <el-form-item label="创建人">
        <el-input v-model="form.companyName" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="创建时间">
        <div class="to_input">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            placeholder="年/月/日"
          />
          <span>至</span>
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            placeholder="年/月/日"
          />
        </div>
      </el-form-item>

      <div class="btn">
        <el-button type="primary" @click="searchClick">确定</el-button>
        <el-button @click="resetClick">重置</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ProductSearch',
  data() {
    return {
      props: { multiple: true, checkStrictly: true },
      categoryOptions: [], //产品类别
      optionProps: {
        value: 'id',
        label: 'nameCn',
        children: 'children',
        checkStrictly: true,
      },
      form: {},
      daterange: [],
    }
  },
  created() {},
  methods: {
    searchClick() {
      this.$emit('searchClick', this.form)
    },
    resetClick() {
      this.$refs.form.resetFields()
      this.daterange = ''
      this.form = this.$options.data().form
    },
  },
}
</script>

<style lang="scss" scoped>
.search_form {
  padding: 0 20px;
  .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  .btn {
    padding: 20px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
  }
}
::v-deep {
  .el-cascader {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 70px 0 28px 0 !important;
  }
}
</style>
