<template>
  <div>
    <el-dialog
      title="批量设置值班"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
      width="600px"
    >
      <el-form
        :model="formData"
        ref="formRef"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="每周重复" prop="weekDayList">
          <el-checkbox-group v-model="formData.weekDayList">
            <el-checkbox-button
              v-for="{ label, value } of weekDayOptions"
              :key="value"
              :label="value"
            >
              {{ label }}
            </el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="选择时段" prop="timeData">
          <el-select v-model="formData.timeData" placeholder="请选择" clearable>
            <el-option
              v-for="timeArr in timeOptions"
              :key="timeArr[0]"
              :label="`${timeArr[0]}-${timeArr[1]}`"
              :value="timeArr"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="值班人员" prop="userNameList">
          <el-select
            v-model="formData.userNameList"
            multiple
            collapse-tags
            clearable
            placeholder="请选择"
            class="select-user"
            popper-class="select-user-popper"
            @focus="handleUserFocus"
            @clear="handleUserClear"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSubmit">提 交</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 人员组织架构树 -->
    <UserChoose ref="userChooseRef" @choose-user="chooseUser" />
  </div>
</template>
<script>
  import UserChoose from '@/components/userChoose2'
  export default {
    components: { UserChoose },
    props: {
      value: { type: Boolean, default: false },
      timeOptions: { type: Array, default: () => [] },
    },
    data() {
      return {
        formData: {
          weekDayList: [1, 2, 3, 4, 5, 6, 7],
          timeData: '',
          userNameList: [],
          userIdList: [],
        },
        rules: {
          weekDayList: [
            { required: true, message: '请选择星期', trigger: 'change' },
          ],
          timeData: [
            { required: true, message: '请选择时段', trigger: 'change' },
          ],
          userNameList: [
            { required: true, message: '请选择值班人员', trigger: 'change' },
          ],
        },
        weekDayOptions: [
          { label: '一', value: 1 },
          { label: '二', value: 2 },
          { label: '三', value: 3 },
          { label: '四', value: 4 },
          { label: '五', value: 5 },
          { label: '六', value: 6 },
          { label: '日', value: 7 },
        ],
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },
    },
    methods: {
      // 点击打开选择人员弹窗
      handleUserFocus() {
        const { userIdList } = this.formData
        this.$refs.userChooseRef.showAddEdit('', userIdList)
      },
      // 选择人员
      chooseUser(userList) {
        const userNameList = userList.map((item) => item.name)
        const userIdList = userList.map((item) => item.id)
        Object.assign(this.formData, { userNameList, userIdList })
      },
      // 删除当前选框内人员
      handleUserClear() {
        this.formData.userNameList = []
        this.formData.userIdList = []
      },
      handleSubmit() {
        this.$refs.formRef.validate((valid) => {
          if (!valid) return
          this.$emit('setUsers', this.formData)
          this.handleClose()
        })
      },
      handleClose() {
        this.$refs.formRef.resetFields()
        this.formData = this.$options.data().formData
        this.handleUserClear()
        this.dialogVisible = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  ::v-deep {
    .el-checkbox-button {
      margin-right: 10px;
      .el-checkbox-button__inner {
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid #1890ff;
      }
    }
    .select-user {
      .el-tag {
        & + .el-tag {
          margin-left: 2px;
        }
        .el-select__tags-text {
          max-width: 28px;
        }
      }
      .el-tag__close {
        display: none;
      }
    }
  }
</style>
<style>
  /* 隐藏下拉框的popper */
  .select-user-popper {
    display: none;
  }
</style>
