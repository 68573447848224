<template>
  <div class="latest-aside">
    <div :style="{ width: width + 'px' }" class="my-mail-list">
      <div class="tab-cont" v-if="showSum">
        <!-- 账号文件夹和收件箱下显示 -->
        <MailTabs @tabItemCkickFn="tabItemClickFn" @currentActive="currentActive" :tabs="tabs"
          :show-search="showMailTabs" has-search-type @checkAllChange="handleCheckAllChange" :check-all-param="{
            isIndeterminate,
            checkAll
          }"></MailTabs>
      </div>
      <div v-if="isGlobalSearch">
        <!-- 全选 -->
        <el-checkbox :indeterminate="isIndeterminate" class="ml20" @change="handleCheckAllChange" v-model="checkAll">
        </el-checkbox>
      </div>
      <div class="search-container">
        <el-checkbox-group v-model="checkedContacts" @change="handleCheckedMailChange">
          <div :class="[
              'search-item',
              {
                active: currentMail == item.mailId,
                unread:
                  (listConfig.type === 0 || listConfig.type === 3) &&
                  item.readFlag !== 1,
                read:
                  (listConfig.type === 0 || listConfig.type === 3) &&
                  item.readFlag == 1,
              },
            ]" v-for="item in mailList" :key="item.mailId" :id="'mailItem' + item.mailId"
            @click="getDetail({ ...item, useNotify: false })">

            <div class="top-dl">
              <div class="top-dt">
                <el-checkbox v-if="!search" :label="item.mailId" @click.stop.native="">
                  {{ '' }}
                </el-checkbox>
                <!-- readFlag 为 1 是已读 不等于 1 是未读 -->
                <el-tooltip class="item" effect="dark" :content="item.nodeName_deal" placement="top" :open-delay="1000">
                  <span class="mail-item-title" :style="
                    item.readFlag == 1
                      ? 'font-weight:normal'
                      : 'font-weight:bold'
                  " v-html="item.nodeName_deal">
                  </span>
                </el-tooltip>
                <!-- 邮件发送失败状态 -->
                <ErpMouseoverTip v-if="item.sendFlag === 2" effect="dark"
                  style="position: relative;top: 4px;font-size: 14px;">
                  <div>
                    {{$t('myMail.sendFailed')}}
                  </div>
                </ErpMouseoverTip>

                <!-- 邮件发送超时状态-->
                <ErpMouseoverTip  effect="dark"
                  style="position: relative;top: 4px;font-size: 14px;" color="#F59A23" v-if="item.sendFlag === 3">
                  <div>
                    {{$t('myMail.sendTimeout')}}
                  </div>
                </ErpMouseoverTip>

                <!-- 邮件发送中状态-->
                <span v-if="item.sendFlag === 0" class="mx-10 green">{{$t('myMail.mailBeingDelivered')}}</span>

                <el-tooltip class="item" effect="dark" :content="`${item.emailAccount}/${item.boxFullName_edit}`"
                  placement="top" :open-delay="1000">

                  <span class="email-account" v-if="[0,1,2,3,4,5,6].includes(pageInfo.keyWordSearchType)">
                    {{ item.emailAccount }}/{{ item.boxFullName_edit }}
                  </span>
                </el-tooltip>




                <el-tooltip class="item" effect="dark" :content="item.emailMemo" placement="top">
                  <i v-show="item.emailMemo" class="iconfont icon-bianqian"   style="color:#F59A23"></i>
                </el-tooltip>
              </div>
              <!-- 心标 -->
              <i class="iconfont  mr10" v-if="heartEnabled" :class="[item.heartFlag ? 'icon-star' : 'icon-star1']"
                @click.stop="setHeartFlag(item)"></i>
              <!-- 分派和认领按钮 -->
              <span class="text-center pointer  text-primary mr-10" @click.stop="dispatch(item,true)"
                v-if="showDispatch(item) && dispatchEnabled">
                {{$t('myMail.dispatch')}}</span>
              <!-- 个人文件夹下无认领操作 -->
              <span class=" text-center pointer text-primary mr-10" @click.stop="claim(item,true)"
                v-if="showClaim(item) && dispatchEnabled">
                {{$t('myMail.claim')}}
              </span>

              <span style="white-space: nowrap" v-if="currentType == 2"
                :class="item.timingSwitch == 1 ? 'sendtime' : ''">
                <template v-if="item.timingSwitch == 1">
                  <template v-if="
                      new Date(item.timingSendTime).getTime() -
                        new Date().getTime() >
                      60000
                    ">
                    <span style="white-space: nowrap">
                      {{ utils.formatTime(item.timingSendTime, 'computed') }}
                    </span>
                  </template>
                  <CountDowm v-else :targetTime="item.timingSendTime" @countDowmEnd="countDowmEnd" />
                </template>
                <template v-else>
                  {{ utils.formatTime(item.updateTime, 'mail') }}
                </template>
              </span>
              <span v-else>
                {{ utils.formatTime(item.sendTime, 'mail') }}
              </span>
              <span>
                <el-tooltip class="item" effect="dark" :content="$t('myMail.remove')" placement="top">

                  <i class="el-icon-close"
                    v-if="$route.query.userId && $route.query.userId !== 'ALL_USER_ID'&&![0,1,2,3,4,5,6].includes(pageInfo.keyWordSearchType) && $route.query.authStatus !=2 "
                    @click.stop="removeAccountMail(item)"></i>
                </el-tooltip>
              </span>

            </div>
            <div class=" bottom-dl">

              <!-- 草稿箱没有这些标识 -->
              <div class="bottom-dt"
                v-if="listConfig.type != 2 || [0,1,2,3,4,5,6].includes(pageInfo.keyWordSearchType)">
                <!-- 未读 -->
                <el-tooltip :content="$t('myMail.UnreadMail')" placement="top" v-if="item.readFlag != 1"
                  :open-delay="1000">
                  <i class="iconfont icon-youxiang"></i>
                </el-tooltip>
                <!-- 已发邮件 -->
                <el-tooltip :content="$t('other.sendMail')" placement="top" v-else-if="item.issued == 1"
                  :open-delay="1000">
                  <i class="iconfont icon-qingqiuyifasong send"></i>
                </el-tooltip>
                <!-- 回复and转发 -->
                <el-tooltip :content="$t('other.Forwardedmessagereplied')" placement="top"
                  v-else-if="item.reply == 1 && item.isForward == 1" :open-delay="1000">
                  <i class="iconfont icon--huxiangguanzhu replyed"></i>
                </el-tooltip>
                <!-- 已转发 -->
                <el-tooltip placement="top" v-else-if="item.isForward == 1" :content="$t('other.Forwardedmessage')"
                  :open-delay="1000">
                  <i class="iconfont icon-zhuanfa1 replyed"></i>
                </el-tooltip>
                <!-- 已回复 -->
                <el-tooltip :content="$t('other.Messagereplied')" placement="top" v-else-if="item.reply == 1"
                  :open-delay="1000">
                  <i class="iconfont icon-huifu replyed"></i>
                </el-tooltip>
                <el-tooltip :content="$t('myMail.EmailsRead')" placement="top" v-else-if="item.readFlag == 1"
                  :open-delay="1000">
                  <i class="iconfont icon-youxiang2 looked"></i>
                </el-tooltip>
                <!-- 休假转交邮件 -->
                <el-tooltip :content="$t('myMail.Forwardtorecipient')" placement="top"
                  v-if="item.leaveTransferFlag == 1" :open-delay="1000">
                  <i class="iconfont icon-transfer"></i>
                </el-tooltip>
                <!-- 分配邮件 -->
                <el-tooltip :content="$t('mailConfig.Assigned')" placement="top" v-if="item.allocationFlag == 1"
                  :open-delay="1000">
                  <i class="iconfont icon-fenpei2 primary"></i>
                </el-tooltip>
                <!-- 分派邮件 -->

                <el-popover placement="right" width="350" trigger="hover" :visible-arrow="false"
                  @show="getRecords(item,1,'dispatchRecords')" :open-delay="900" popper-class="w-120"
                  v-if="item.dispatchFlag == 1">
                  <div style="color:#fff">
                    <div class="fb-500 mb-5">{{$t('myMail.dispatchrecord')}}</div>
                    <hr>
                    <div class="mt-5">
                      <p v-for="user in item.dispatchRecords">
                        <el-row>
                          <el-col :span="12">
                            {{$t('myMail.receiver')}}:&nbsp;{{lang !== 'en' ?
                            `${user.userNameCn}(${user.userNameEn})` :
                            user.userNameEn}}</el-col>
                          <el-col :span="12">
                            <p class="text-dot" :title="lang !==
                            'en' ?
                            `${user.operateUserNameCn}(${user.operateUserNameEn})` :
                            user.operateUserNameEn">&nbsp;&nbsp;
                              {{$t('myMail.assignee')}}:&nbsp;{{lang !==
                              'en' ?
                              `${user.operateUserNameCn}(${user.operateUserNameEn})` :
                              user.operateUserNameEn}}</p>

                          </el-col>
                        </el-row>

                      </p>
                    </div>
                  </div>

                  <i class="iconfont icon-n-fenpai" style="color:#1890ff;display: inline-block;margin-top: 4px;"
                    slot="reference" @click.stop></i>
                </el-popover>

                <!-- 认领邮件 -->

                <el-popover v-if="item.claimFlag == 1 " placement="right" width="200" trigger="hover"
                  :visible-arrow="false" @show="getRecords(item,0,'claimRecords')" :open-delay="900"
                  popper-class="w-70">
                  <div style="color:#fff">
                    <div class="fb-500  mb-5">{{$t('myMail.claimrecord')}}</div>
                    <hr>
                    <div class="mt-5">
                      <p v-for="user in item.claimRecords">
                        {{$t('myMail.claimant')}}:&nbsp;{{lang !== 'en' ?
                        `${user.operateUserNameCn}(${user.operateUserNameEn})` :
                        user.operateUserNameEn}}
                      </p>
                    </div>
                  </div>

                  <i class="iconfont icon-renling" style="color:#1890ff" slot="reference" @click.stop></i>

                </el-popover>

              </div>

              <div class="bottom-dt"
                v-if="listConfig.type == 2 && ![0,1,2,3,4,5,6].includes(pageInfo.keyWordSearchType)">
                <!-- content="延时发送邮件" -->
                <el-tooltip :content="$t('other.Delaysendingmail')" placement="top"
                  v-if="item.timingType == 1 && item.timingSwitch == 1" :open-delay="1000">
                  <i class="iconfont icon-sand-clock"></i>
                </el-tooltip>
                <!-- content="定时发送邮件" -->
                <el-tooltip :content="$t('other.Sendmailregularly')" placement="top"
                  v-else-if="item.timingType == 0 && item.timingSwitch == 1" :open-delay="1000">
                  <i class="el-icon-alarm-clock"></i>
                </el-tooltip>
              </div>


              <div class="bottom-dd">
                <p>
                  <i v-if="item.enclosure == 1" class="iconfont icon-biezhen file"></i>

                  <span v-if="item.title" class="mail-title" :style="
                      item.readFlag == 1
                        ? 'font-weight:normal;color:#666666'
                        : 'font-weight:bold;color:#222222'
                    " v-html="item.title_deal">

                  </span>
                  <span v-else>--</span>
                </p>
                <p class="mail-text" v-if="item.content" :style="
                    item.readFlag == 1
                      ? 'font-weight:normal;color:#999999'
                      : 'font-weight:bold;color:#666666'
                  " v-html="item.content_deal">

                </p>
                <p class="mail-text" v-else>--</p>
                <ul class="label-list" v-if="item.labelList && item.labelList.length">
                  <li v-for="label in item.labelList" :key="label.mailLabelConfigId"
                    :style="{ 'background-color': label.labelColor }">
                    <span class="label">{{ label.labelName }}</span>
                    <i class="el-icon-close remove-icon" @click.stop="removeLabel(label, item)"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div class="search-pagination">
        <el-select class="page-size" v-model="page.pageSize" @change="changePageSize" size="mini">

          <el-option :value="10" :label="$t('selOpt.$10')"></el-option>
          <el-option :value="20" :label="$t('selOpt.$20')"></el-option>
          <el-option :value="50" :label="$t('selOpt.$50')"></el-option>
          <el-option :value="100" :label="$t('selOpt.$100')"></el-option>
          <el-option :value="200" :label="$t('selOpt.$200')"></el-option>
        </el-select>
        <div class="page-current">
          <el-button type="text" icon="el-icon-arrow-left" @click="beforePage" :disabled="page.pageNo < 2">
            <!-- 上一页 -->
          </el-button>
          <span class="page-num">
            {{ page.total ? page.pageNo : 0 }} / {{ page.total }}
          </span>
          <el-button type="text" icon="el-icon-arrow-right" @click="nextPage" :disabled="page.pageNo == page.total">
            <!-- 下一页 -->
          </el-button>
        </div>
        <div class="jump">
          <!-- <span>前往</span> -->
          <span>{{ $t('other.goto') }}</span>
          <el-input v-model.number="page.goPage" size="mini" @blur="goPage" :disabled="page.total == 1"></el-input>
          <!-- <span>页</span> -->
          <span>{{ $t('other.Page') }}</span>
        </div>
      </div>
    </div>
    <erp-handle class="myxhandle" @widthChange="widthChange" />
    <!-- 分配 -->
    <AssignmentAndTransfer :assignmentAndTransferBol.sync="assignmentAndTransferBol" :mailIdArr="checkedContacts"
      :title="$t('mailConfig.assign')" @saveFn="saveFn" :mailServer="$route.query.serve" ref="assignmentAndTransfer">
    </AssignmentAndTransfer>
    <!-- 分派 -->
    <Dispatch ref="dispatch">
    </Dispatch>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import { highlightText } from 'kits'
  import { savePageWidth } from '../utils/tool.js'
  import {
    getMailPageList,
    getMailDetailById,
    removeMails,
    changeCatagory,
    changeBoxType,
    setReadFlag,
    mailLabelSave,
    deleteMailLabelConfig,
    cleanMailLabel,
    moveMail,
  } from '@/api/mail/mail.js'
  import { MailSettingInteractor, systemInteractor, MailInteractor } from '@/core'
  import Bus, { BusEmit, BusOn } from '@/utils/eventBus'
  import CountDowm from './countdown.vue'
  import AssignmentAndTransfer from '@/components/AssignmentAndTransfer/index'
  import Dispatch from './Dispatch'
  import MailTabs from '@/components/MailTabs'
  import mailMixin from '../utils/mixin'
  import { checksingleMarkDel, checkDistributeAndClaim, checkMarkAs, getMailDealField } from '../utils/tool'
  import { getEmailName, isInEmailName, sendBoxNames } from '../utils/mail-data'

  import _ from 'lodash'
  export default {
    name: 'MailList',
    components: {
      CountDowm,
      AssignmentAndTransfer,
      MailTabs,
      Dispatch
    },

    data() {
      return {
        searchFlag: false,
        checkedContacts: [],
        checkAll: false,
        page: {
          pageNo: 1,
          pageSize: 50,
          goPage: 1,
          total: 0,
        },
        mailList: [],
        searchValue: '',
        currentMail: '',
        currentMailDetail: null,
        second: 0,
        width: 500,
        pageInfo: {}, // 搜索查询条件
        mailTitle: '',
        assignmentAndTransferBol: false, //分盘转交弹窗
        tabs: [
          {
            nameCn: '全部',
            nameEn: 'All',
          },
          {
            nameCn: '未读',
            nameEn: 'Unread',
          },
          {
            nameCn: '已读未处理',
            nameEn: 'Pending',
            num: 0,
          },
          {
            nameCn: '已处理',
            nameEn: 'Solved',
          },
          {
            nameCn: '已认领/分派',
            nameEn: 'Claimed/Assigned',
          },
          {
            nameCn: '未认领/分派',
            nameEn: 'Unclaimed/Unassigned',
          },
        ],
        showSum: true,
        readProcessedFlag: 0,
        dispatchEnabled: false,//允许分派和认领
        sendBoxNames: sendBoxNames, //发件箱对应的boxName字段
        isIndeterminate: false, //全选isIndeterminate
        checkAll: false, //全选默认false
        heartEnabled: false, //是否展示心标
      }
    },
    mixins: [BusOn, mailMixin],
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      ...mapState({
        currentAccount: (state) => state.mail.currentAccount,
        userInfo: (state) => state.user.userInfo,
      }),
      receiveCatgoryList() {
        return this.$store.state.mail.receiveCatgoryList[
          this.$store.state.mail.currentAccount
        ]
      },
      currentType() {
        return this.listConfig.type
      },
      listenObj() {
        let obj = {
          currentAccount: this.$store.state.mail.currentAccount,
          query: {
            ...this.$route.query,
          },
        }
        return obj
      },

      showMailTabs() {
        let { userId, mailName, mailLabelId, boxFullName } = this.$route.query
        return !!userId || mailName === 'INBOX' || mailName === '所有邮件' || !!mailLabelId || boxFullName === 'erp_heart_folder'
      },

      allMailIds() {
        return this.mailList.map(s => s.mailId)
      },

      // 是否是全局搜索
      isGlobalSearch() {
        return [0, 1, 2, 4, 5, 6].includes(this.pageInfo.keyWordSearchType)
      }
    },
    props: {
      search: {
        type: Boolean,
        default: false,
      },
      listConfig: {
        // type 0 收件箱 1已发邮件 2草稿箱 3垃圾邮箱
        type: Object,
        require: true,
      },
    },
    filters: {
      lengthLimit(val, num) {
        if (val.length > num) {
          return val.substring(0, num) + '...'
        } else {
          return val
        }
      },
    },
    watch: {
      listenObj: {
        handler(val) {
          this.$emit('clearMail')
          setTimeout(() => {
            this.page.pageSize = 50
            let searchInput = document.querySelector('#mailActionBarSearchInput')
            if (searchInput && searchInput.value && searchInput.value.trim()) {
              return
            } else {
              this.page.pageNo = 1
              this.page.goPage = 1
            }
            this.searchValue =
              this.$route.query.searchValue !== undefined
                ? this.$route.query.searchValue
                : ''
            if (
              this.currentAccount &&
              this.$route.path != '/mail/my-mail/receive-all'
            ) {
              this.getPageList()
              this.getReadAndUnreadCounts()
            }
          }, 1400)

        },
        deep: true,
        immediate: true,
      },
      checkedContacts: {
        handler(val) {
          let emailAccount = this.$store.state.mail.currentAccount
          BusEmit('ActionBar', 'mailChecked', { val, mailList: this.mailList })
          let checkAccount = this.checkCheckedData()
          BusEmit('ActionBar', 'setCurrentSearchAccount', this.mailTreeAccount || checkAccount)
          this.mailTreeAccount = ''
        },
        deep: true,
      },
    },
    created() {
      this.width = this.$store.state.mail?.mailPageWidth?.centerWidth
      if (
        (this.$route.path == '/mail/my-mail/receive-all' ||
          this.$route.query.notify == 1) &&
        this.$route.query.mailId
      ) {
        this.getDetail({ mailId: this.$route.query.mailId })
      }

      this.getDispatchEnabled()

    },

    methods: {
      // 更新心标heartEnabled
      updateheartEnabled(heartEnabled) {
        this.heartEnabled = heartEnabled
      },
      // 全选
      handleCheckAllChange(val) {

        this.checkedContacts = val ? this.allMailIds : [];
        this.checkAll = val ? true : false
        this.isIndeterminate = false;
      },
      showClaim(item) {
        let checkResult = checkDistributeAndClaim(item, this.$store.state.mail.leftTableData)
        if (!checkResult?.isPass) {
          return false
        }
        if (this.$route.query.userId === this.userInfo.userId && ![0, 1, 2, 3, 4, 5, 6].includes(this.pageInfo.keyWordSearchType)) {
          return false
        }
        return true
      },
      showDispatch(item) {
        let checkResult = checkDistributeAndClaim(item, this.$store.state.mail.leftTableData)
        if (!checkResult?.isPass) {
          return false
        }
        return true
      },
      // 点击分配
      async distributeFn() {
        if (this.checkedContacts.length) {
          let privilege = await this.getPrivilege(
            this.checkedContacts,
            'allowAssign'
          )
          if (privilege.allowAssign) {
            this.assignmentAndTransferBol = true
          }
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('reqmsg.$28'),
          })
        }
      },
      // 分配保存
      saveFn(obj) {
        this.$refs.assignmentAndTransfer.closeLoadingFn()
        this.assignmentAndTransferBol = false
        this.getPageList()
        this.getReadAndUnreadCounts()
        BusEmit('MailLeftAside', 'refreshData')
      },
      // 设置当前账户
      setCurrentAccount(detail) {
        if (detail?.mailHeader?.emailAccount) {
          this.$store.commit('mail/setCurrentAccount', detail.mailHeader.emailAccount)
        } else if (detail?.baseInfo?.emailAccount) {
          this.$store.commit('mail/setCurrentAccount', detail.baseInfo.emailAccount)
        }
      },
      removeActive() {
        this.currentMail = ''
      },
      //获取详情
      async getDetail(item) {

        if (item) {
          this.currentMail = item.mailId
        } else if (!this.currentMail) {
          return
        }

        let res = await getMailDetailById(this.currentMail)
        if (res && res.code === '000000' && res.data) {
          if (!res?.data?.extendInfo?.content && item.content) {
            res.data.extendInfo.content = item.content
          }
          if (!res?.data?.baseInfo?.title_deal && item?.title && res.data) {
            res.data.baseInfo.title_deal = item.title
          }
          if (!item?.title && res.data) {
            res.data.baseInfo.title_deal = res?.data?.baseInfo?.title
          }
          this.currentMailDetail = res.data

          let mailSerer = this.getMailSerer(res.data?.mailHeader?.emailAccount || res.data?.baseInfo?.emailAccount)
          let obj = res.data
          if (obj && item) {
            obj.useNotify = item.useNotify
          }
          this.$emit('showInfo', obj)
          if (
            this.listConfig.type == 0 ||
            this.listConfig.type == 3 ||
            this.listConfig.type == 1 ||
            this.$route.query.notify == 1 ||
            [0, 1, 2, 3, 4, 5, 6].includes(this.pageInfo.keyWordSearchType)
          ) {
            // 处理搜索时权限查询信息
            let authStatus = ''
            let emailAccount = item?.emailAccount
            authStatus = this.$store.state.mail.leftTableData?.find(item => item.emailAccount == emailAccount)?.authStatus
            if (
              (item &&
                item.readFlag == 0 &&
                this.$route.query.authStatus != 2 && authStatus != 2) ||
              this.$route.query.notify == 1
            ) {

              if (!this.$route.query.serve) {
                return
              }
              let mailAccountUserIds = []
              let { currentAccount } = this.getCurrentAccountObj()
              if (![0, 1, 2, 3, 4, 5, 6].includes(!this.pageInfo.keyWordSearchType)) {
                mailAccountUserIds = this.getUserIds(this.$route.query.userId, currentAccount, this.$route.query.id)
              }
              let value = document.querySelector('#mailActionBarSearchInput')?.value
              BusEmit('MailLeftAside', 'refreshData')
              if (value?.trim()) {
                if ('readFlag' in item) {
                  let cMail = this.mailList.find(mail => mail.mailId === this.currentMail)
                  if (cMail) {
                    this.$set(cMail, 'readFlag', 1)
                  }
                }
              } else {
                this.getReadAndUnreadCounts()
                if ('readFlag' in item) {
                  let cMail = this.mailList.find(mail => mail.mailId === this.currentMail)
                  if (cMail) {
                    this.$set(cMail, 'readFlag', 1)
                  }

                } else {
                  if (this.$route.query.notify == 1) {
                    // 获取当前打开的邮件
                    let currentItem = this.mailList.find(
                      (s) => s.mailId === this.$route.query.mailId
                    )
                    if (currentItem) {
                      currentItem.readFlag = 1
                    }
                  }
                }
              }
              setReadFlag({
                mailIdList: [this.currentMail],
                readFlag: 1,
                mailServer: mailSerer || this.$route.query.serve,
                mailAccountUserIds,

              }).then((res) => {
                BusEmit('MailLeftAside', 'refreshData')
              })
            }
          }
        }

      },


      handleCheckedMailChange(value) {

        let checkedCount = value.length
        this.checkAll = checkedCount === this.allMailIds.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.allMailIds.length
        // console.log('this.isIndeterminate', this.isIndeterminate)
        // console.log('this.checkAll', this.checkAll)
      },

      //搜索筛选方法
      searchFilter() {
        this.page.pageNo = 1
        this.page.goPage = 1
        this.getPageList()
        this.searchFlag = false
      },

      //上一页
      beforePage() {
        if (this.page.pageNo > 1) {
          this.page.pageNo -= 1
          this.page.goPage = this.page.pageNo
        }
        this.getPageList()
      },

      //切换页码
      changePageSize(val) {
        this.page.pageSize = val
        this.page.pageNo = 1
        this.page.goPage = this.page.pageNo
        this.getPageList()
      },

      //下一页
      nextPage() {
        if (this.page.pageNo < this.page.total) {
          this.page.pageNo += 1
          this.page.goPage = this.page.pageNo
        }
        this.getPageList()
      },

      goPage() {
        if (this.page.goPage > this.page.total) {
          this.page.goPage = this.page.total
        }
        this.page.pageNo = this.page.goPage
        this.getPageList()
      },
      tabItemClickFn(index, params) {
        this.readProcessedFlag = index
        this.page.pageNo = 1
        this.page.goPage = 1
        this.getReadAndUnreadCounts()
        this.updatePage(params)
        this.$emit('clearMail')
      },
      // 处理授权账号已读未读问题
      handleAuthAccountReadStatus(item) {
        if (this.$route.query.userId) {  //账号文件夹不处理取主账号已读未读状态
          return item
        }
        let { emailAccount, authUsers } = item
        let leftTableData = this.$store.state.mail.leftTableData
        let userId = this.$store.state.user?.userInfo?.userId
        let obj = leftTableData.find(
          (item) => item.emailAccount === emailAccount
        )
        if (obj && obj.authStatus !== null) {
          //授权账号
          let authUserItem = authUsers.find(
            (item) => item.authUserId === userId
          )
          if (authUserItem) {
            item.readFlag = authUserItem.readFlag
            item.heartFlag = authUserItem.heartFlag
          }
        }
        return item
      },
      // 获取同事目录用户id
      getUserIds(userId, emailAccount, id) {
        if (!userId) return []
        if (userId !== 'ALL_USER_ID') {
          return [id]
        } else {
          let leftTableData = this.$store.state.mail.leftTableData
          let obj = leftTableData.find(
            (item) => item.emailAccount === emailAccount
          )
          if (obj) {
            return obj?.mailAccountUserVOS?.filter((item) => item.userId !== this.userInfo.userId).map(s => s.id)
          }
          return []
        }
      },
      hide(item) {
        this.$set(item, 'iconArrow', 'el-icon-caret-top')
      },
      setMailAccount(emailAccount) {
        this.mailTreeAccount = emailAccount
      },
      batchDeleteLabel(deleteMarks) {
        let labelNameList = deleteMarks.map(item => item.labelName)
        this.mailList.forEach(mail => {
          let mailLabelConfigVOS = mail.labelList
          labelNameList.forEach(labelName => {
            if (Array.isArray(mailLabelConfigVOS)) {
              let index = mailLabelConfigVOS.findIndex(s => s.labelName == labelName)
              if (index > -1) {
                mailLabelConfigVOS.splice(index, 1)
              }
            }
          })
        })


      },
      //获取分页数据
      getPageList(callback) {
        if (!this.listConfig.flag) {
          let { currentAccount, authStatus, businessId } =
            this.getCurrentAccountObj()
          let data = {}
          if (this.$route.path === '/mail/my-mail/receive-all') {
            data.flag = 1
          }
          if (this.$route.query.catagoryId !== undefined) {
            data.catagoryId = this.$route.query.catagoryId
            data.boxType = null
          }

          let dt = {
            ...this.pageInfo,
            boxName:
              this.listConfig.boxName || this.$route.query.mailName || null,
            emailAccount: this.currentAccount,
            labelId: this.$route.query.mailLabelId || null,
            pageNo: this.page.pageNo,
            pageSize: this.page.pageSize,
            tenantId: this.userInfo.tenantId,
            readProcessedFlag: this.readProcessedFlag,
            authFlag: authStatus === null ? 0 : 1,
            authUserId: businessId,
            boxFullName: this.$route.query.boxFullName,
            mailAccountUserIds: this.getUserIds(this.$route.query.userId, currentAccount, this.$route.query.id),
          }
          if (dt.keyWordSearchType === 0) {
            dt.boxName = ''
            dt.emailAccount = ''
          }
          const searchTerms = this.pageInfo.keyWord?.split(' ') || [];
          return getMailPageList(dt).then((res) => {
            if (res?.code == '000000') {
              // 重新查询列表数据时更改状态
              this.resetCheckBoxStatus()
              this.mailList = res.data.records?.map((item, index) => {
                item = this.handleAuthAccountReadStatus(item)
                let { content_deal, nodeName_deal, title_deal } = getMailDealField(this.pageInfo.keyWord, item, this.utils)
                return {
                  ...item,
                  content_deal,
                  nodeName_deal,
                  title_deal,
                  boxFullName_edit: isInEmailName(item.boxFullName)
                    ? this.$t(getEmailName(item.boxFullName).langKey)
                    : item.boxFullName,
                  iconArrow: 'el-icon-caret-top',
                  claimRecords: [],  //认领记录
                  dispatchRecords: [],  // 分派记录

                }
              })
              // 邮件列表为空时，不展示邮件详情
              if (this.mailList?.length === 0) {
                this.$emit('clearMail')
              }

              this.page.total = res.data.pages || 0
              if (!this.page.total) {
                this.page.pageNo = 1
              }
              if (this.clearChecked) {
                this.checkAll = false
                this.checkedContacts = []
              } else {
                this.clearChecked = true
              }

              // 标签转换
              this.mailList.forEach((item) => {
                if (item.label) {
                  let labelList = JSON.parse(item.label)
                  labelList = labelList.sort((a, b) => {
                    return b.mailLabelConfigId - a.mailLabelConfigId;
                  });
                  this.$set(item, 'labelList', labelList)
                } else {
                  this.$set(item, 'labelList', [])
                }
                return item
              })
              return true
            }
          })
        }
      },
      // 替换内容，无论大小写
      replaceContent(target, keys) {
        // let a = 'aAbB'
        // let aa = _.toLower(a)
        // let b = ''
        // let bb = _.toLower(b)
        // if (aa === bb) {
        //   aa.replace(bb)
        // }
      },
      // 取消垃圾邮件
      recall() {
        if (this.checkedContacts.length) {
          // changeBoxType({ mailIdList: this.checkedContacts, boxType: 0 }).then(
          moveMail({
            mailIds: this.checkedContacts,
            boxFullName: 'INBOX',
            mailServer: this.listenObj.query.serve,
          }).then((res) => {
            this.$message({
              type: 'success',
              // message: '取消垃圾邮件成功！',
              message: this.$t('reqmsg.$45'),
            })
            setTimeout(() => {
              this.getPageList() //刷新邮件列表
              // 重新获取数量
              this.getReadAndUnreadCounts()
              BusEmit('MailLeftAside', 'refreshData')
            }, 2500)
          })
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
        }
      },
      // 删除邮件
      removeMail() {
        if (this.checkedContacts.length) {
          // this.$confirm('是否确认删除？', '提示', { type: 'warning' })
          this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
            type: 'warning',
          }).then(() => {
            removeMails(this.checkedContacts).then((res) => {
              if (res.code === '000000') {
                this.$message({
                  type: 'success',
                  // message: '删除成功！',
                  message: this.$t('reqmsg.M2008'),
                })
                setTimeout(() => {
                  this.getPageList()
                  BusEmit('MailLeftAside', 'refreshData') // 触发更新邮件分类数量
                }, 2500)
              }
            })
          })
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
        }
      },
      // 处理搜索时的maiLSerer
      getMailSerer(emailAccount) {
        let currentAccount = emailAccount || this.$store.state.mail.currentAccount
        if (currentAccount) {
          let currentAccountObj = this.$store.state.mail.leftTableData.find(
            (item) => item.emailAccount === currentAccount
          )
          return currentAccountObj?.mailServer
        }
      },
      // 处理列表已读未读状态
      handleReadStatus(mailIdList, bool) {
        if (this.mailList?.length > 0) {
          mailIdList.forEach(mailId => {
            let cMail = this.mailList.find(m => m.mailId == mailId)
            if (cMail) {
              this.$set(cMail, 'readFlag', bool ? 1 : 0)
            }
          })

        }
      },
      // 设置已读和未读
      setReadFlag(bool = true) {
        if (this.checkedContacts.length) {
          if (!this.$route.query.serve) {
            return
          }
          // 判断能不能标记
          let checkResults = []

          for (let i = 0; i < this.checkedContacts.length; i++) {
            let curMail = this.mailList.find((item) => {
              return item.mailId == this.checkedContacts[i]
            })
            checkResults.push(checkMarkAs(curMail, this.$store.state.mail.leftTableData))
          }

          if (checkResults.some(s => !s?.isPass)) { // 有校验不通过的
            let messageKeys = checkResults.filter(s => s.messageKey).map(s => s.messageKey)
            this.$message.warning(this.$t(messageKeys[0]))
            return
          }
          let mailIdList = this.checkedContacts?.slice()
          let { currentAccount } = this.getCurrentAccountObj()
          this.handleReadStatus(this.checkedContacts, bool)
          setReadFlag({
            mailIdList,
            readFlag: bool ? 1 : 0,
            mailServer: this.getMailServerFromCheckedContacts(),
            mailAccountUserIds: this.getUserIds(this.$route.query.userId, currentAccount, this.$route.query.id),
          }).then((res) => {
            if (res.code == '000000') {
              setTimeout(() => {
                BusEmit('MailLeftAside', 'refreshData')
                this.getReadAndUnreadCounts()
              }, 2000)
              // 判断是否需要刷新
              this.doRefresh(bool ? 'SET_READ' : 'SET_UNREAD')
            }
          })
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
        }
      },

      // 加心和取消加心
      // 添加取消心标
      setHeartFlag(item) {
        let mailIdList = []
        let heartFlag = 0
        if (typeof item === 'boolean') { // 批量操作
          mailIdList = this.checkedContacts
          heartFlag = item ? 1 : 0
        } else {
          mailIdList = [item.mailId]
          heartFlag = item.heartFlag ? 0 : 1
        }
        if (!mailIdList.length) {
          this.$message({
            type: 'warning',
            message: this.$t('reqmsg.$28'),
          })
          return
        }
        // 获取勾选邮件对应账号
        let emailAccounts = this.mailList.filter(mail => mailIdList.includes(mail.mailId)).map(mail => mail.emailAccount)
        // 判断账号是否失效
        if (emailAccounts.some(account => this.$store.state.mail.leftTableData.find(s => s.emailAccount === account)?.authStatus === 2)) {
          return this.$message.warning(this.$t('myMail.invalidAccounts'))
        }

        MailInteractor.setHeartFlagApi(heartFlag, mailIdList).then((res) => {
          if (res.code == '000000') {
            // 更改状态
            this.updateHeadtFlag(mailIdList, heartFlag)
            //  操作提示
            this.$message.success(this.$t(heartFlag ? 'myMail.AddHeartMarkSus' : 'myMail.CancelHeartMarkFail'))

          }
        })

      },

      // 更改邮件心标状态
      updateHeadtFlag(mailIdList, heartFlag) {
        this.mailList.forEach(mail => {
          if (mailIdList.includes(mail.mailId)) {
            mail.heartFlag = heartFlag
          }
        })
        if (this.$route.query.boxFullName === 'erp_heart_folder' && !this.isGlobalSearch) {
          // 判断页数
          if (mailIdList.length === this.mailList.length) {
            if (this.page.pageNo > 1) {
              this.page.pageNo--
            }
          }

          setTimeout(() => {
            this.$emit('clearMail')
            this.getPageList()
          }, 1000)


        }
      },
      getMailServerFromCheckedContacts() {

        let mailId = this.checkedContacts[0]
        let curMail = this.mailList.find((item) => {
          return item.mailId == mailId
        })

        if (curMail) {
          let emailAccount = curMail.emailAccount
          return this.$store.state.mail.leftTableData.find(s => s.emailAccount === emailAccount)?.mailServer
        }
      },
      //当前tab变化
      currentActive(currentTab) {

        this.currentTab = currentTab
      },
      // 刷新处理控制
      async doRefresh(action) {
        //没有tab页搜索时不需要刷新
        if (!this.showMailTabs || [4, 5].includes(this.currentTab)) {
          return
        }
        if (['SET_READ', 'SET_PROCESSED'].includes(action) && this.currentTab == 1 ||
          // 已读未处理，标记为未读或者已处理
          ['SET_UNREAD', 'SET_PROCESSED'].includes(action) && this.currentTab == 2 ||
          // 已处理，标记为未读
          ['SET_UNREAD'].includes(action) && this.currentTab == 3
        ) {
          // 判断是否全选
          if (this.checkedContacts.length === this.mailList.length) {
            if (this.page.pageNo === this.page.total) {
              this.page.pageNo--
            }
            if (this.page.pageNo < 1) {
              this.page.pageNo = 1
            }
          }
          // 获取邮件列表中没有勾选的邮件id
          let uncheckMailIds = this.allMailIds.filter(s => !this.checkedContacts.includes(s))
          // 获取最后勾选的邮件所在索引
          let lastCheckedIndex = this.mailList.findIndex(s => s.mailId == this.checkedContacts[this.checkedContacts.length - 1])
          // 判断邮件列表最后一封邮件是否勾选
          let isLastChecked = lastCheckedIndex == this.mailList.length - 1

          // 如果没有全部勾选,获取刷新后展示的下一封邮件id
          let nextUncheckMailId = ''
          if (!isLastChecked) {
            nextUncheckMailId = this.mailList[lastCheckedIndex + 1].mailId
          }
          // 获取分页列表数据
          let res = await this.getPageList()

          if (res) {
            this.$nextTick(() => {
              this.doDetail(uncheckMailIds, isLastChecked, nextUncheckMailId)
            })

          }
        }
      },

      // 详情页处理
      doDetail(uncheckMailIds, isLastChecked, nextUncheckMailId) {

        // 没有打开详情时，不用处理详情
        if (!this.currentMail) {
          return
        } else {
          // 判断currentMail是否存在于allMailIds中并且邮件列表种有当前邮件
          if (uncheckMailIds.includes(this.currentMail) && this.mailList.find(s => s.mailId == this.currentMail)) {  //不做处理
            return
          }
          // 获取本次列表新添加的邮件
          let newMailList = this.mailList.filter(s => !uncheckMailIds.includes(s.mailId))

          if (this.mailList.length === 0) {  //清空邮件详情
            this.$emit('clearMail')
          } else {
            let mailId = ''
            if (uncheckMailIds.length === 0) { // 上次全部勾选了，直接展示当前邮件列表第一条
              mailId = this.mailList[0]?.mailId
            } else {
              // 上次没有勾选，
              if (!isLastChecked) {
                // 没有勾选最后一条,根据nextUncheckMailId
                if (this.mailList.find(mail => mail.mailId === nextUncheckMailId)) {
                  mailId = nextUncheckMailId
                } else {
                  // 不在邮件列表中展示第一封
                  mailId = this.mailList[0]?.mailId
                }

              } else {
                let newMailList = this.mailList.filter(s => !uncheckMailIds.includes(s.mailId))
                if (newMailList?.length) {
                  mailId = newMailList[0].mailId
                }
              }
            }
            if (!mailId) {
              mailId = this.mailList[0]?.mailId
            }
            document.querySelector(`#mailItem${mailId}`)?.click()

          }
        }
      },
      // 标记为以处理
      setSolvedFlag() {
        if (this.checkedContacts.length) {
          // 判断能不能标记
          let checkResults = []

          for (let i = 0; i < this.checkedContacts.length; i++) {
            let curMail = this.mailList.find((item) => {
              return item.mailId == this.checkedContacts[i]
            })
            checkResults.push(checkMarkAs(curMail, this.$store.state.mail.leftTableData))
          }

          if (checkResults.some(s => !s?.isPass)) { // 有校验不通过的
            let messageKeys = checkResults.filter(s => s.messageKey).map(s => s.messageKey)
            this.$message.warning(this.$t(messageKeys[0]))
            return
          }
          let { currentAccount } = this.getCurrentAccountObj()
          this.handleReadStatus(this.checkedContacts, true)
          MailSettingInteractor.processed({
            mailIdList: this.checkedContacts,
            mailServer: this.getMailServerFromCheckedContacts(),
            mailAccountUserIds: this.getUserIds(this.$route.query.userId, currentAccount, this.$route.query.id),
          }).then((res) => {
            if (res.code == '000000') {
              setTimeout(() => {
                BusEmit('MailLeftAside', 'refreshData')
                this.getReadAndUnreadCounts()
              }, 2500)
              this.doRefresh('SET_PROCESSED')
            }
          })
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
        }
      },

      // 变更分类
      moveMail(item) {
        if (this.checkedContacts.length) {
          if (!this.checkMailStatus(this.checkedContacts)) {
            return
          }
          moveMail({
            mailIds: this.checkedContacts,
            boxFullName: item.fullName,
            mailServer: this.listenObj.query.serve,
          }).then((res) => {
            if (res.code == '000000') {
              if (
                this.checkedContacts.includes(this.currentMail) &&
                item.catagoryId !== this.$route.query.catagoryId
              ) {
                this.currentMail = ''
                this.$emit('clearMail')
              }
              this.$message({
                type: 'success',
                // message: '变更分类成功！',
                message: this.$t('reqmsg.$47'),
              })
              this.checkedContacts = []
              setTimeout(() => {
                BusEmit('MailLeftAside', 'refreshData') // 触发更新邮件分类数量
                this.getPageList() // 重新获取list
                // 重新获取数量
                this.getReadAndUnreadCounts()
              }, 2500)
            }
          })
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
        }
      },
      // 转发
      async transformMail() {
        if (this.checkedContacts.length) {
          if (this.checkedContacts.length == 1) {
            let curMail = this.mailList.find((item) => {
              return item.mailId == this.checkedContacts[0]
            })
            // 校验邮件状态
            if (!this.checkMailStatus(this.checkedContacts)) {
              return
            }
            let privilege = await this.getPrivilege(
              this.checkedContacts,
              'allowForward',
              curMail?.emailAccount
            )
            if (privilege.allowForward) {
              let url = `${window.location.origin}/#/write?from=transform&mailId=${this.checkedContacts[0]}&type=2`
              this.utils.openWindow(url)
            }
          } else {
            this.$message({
              type: 'warning',

              message: this.$t('reqmsg.$48'),
            })
          }
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
        }
      },
      async getPrivilege(mailIdList, flag, emailAccount) {

        let res = await systemInteractor.myMailPrivilegeApi({
          emailAccount: emailAccount || this.$store.state.mail.currentAccount,
          mailIdList,
        })
        let privilege = {}

        if (res?.code === '000000') {
          privilege = res.data || {}
        } else {
          privilege = {}
        }
        if (!privilege[flag]) {
          this.$message.warning(this.$t('myMail.Nooperationpermission'))
        }
        return privilege
      },
      //回复
      async replyMail(data) {
        if (this.checkedContacts.length) {
          if (this.checkedContacts.length == 1) {
            let curMail = this.mailList.find((item) => {
              return item.mailId == this.checkedContacts[0]
            })
            if (!this.checkMailStatus(this.checkedContacts)) {
              return
            }
            let privilege = await this.getPrivilege(
              this.checkedContacts,
              'allowReply',
              curMail?.emailAccount
            )
            if (privilege.allowReply) {


              let url = `${window.location.origin}/#/write?from=reply&mailId=${this.checkedContacts[0]}&file=${data.file}&isReplyAll=${data.isReplyAll}&type=1`
              this.utils.openWindow(url)
            }
          } else {
            this.$message({
              type: 'warning',
              // message: '只能选择一封邮件进行回复',
              message: this.$t('reqmsg.$46'),
            })
          }
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
        }
      },
      checkMailStatus(mailIdList) {
        for (let i = 0; i < mailIdList.length; i++) {
          let mail = this.mailList.find(m => m.mailId == mailIdList[i])
          if ([0, 2].includes(mail.sendFlag)) {
            this.$message.warning(this.$t('myMail.noOperationPermission'))
            return false
          }
        }
        return true
      },
      // 操作栏标签校验
      checkCheckedData(isshowMessage) {
        // 校验选择的数据是否是相同账号
        let checkedContacts = this.checkedContacts
        if (!this.checkedContacts) {  //没有选中时返回系统当前账号
          return ''// 返回一个空账号
        }
        let mailAccounts = []
        checkedContacts.forEach(checked => {
          let checkedMail =
            this.mailList.find(mail => mail.mailId === checked)
          if (checkedMail?.emailAccount) {
            mailAccounts.push(checkedMail?.emailAccount)
          }
        })
        mailAccounts = Array.from(new Set(mailAccounts))
        if (mailAccounts.length > 1) {
          isshowMessage && this.$message({
            type: 'warning',
            message: this.$t('myMail.sameAccount'),
          })
          return false
        }
        // 校验选择的数据是否是失效
        for (let i = 0; i < mailAccounts.length; i++) {
          let account = mailAccounts[i]
          let accountList = this.$store.state.mail.leftTableData || []
          if (accountList.find(a => a.emailAccount === account)?.authStatus == 2) {
            isshowMessage && this.$message.warning(this.$t('myMail.invalidAccounts'))
            return false
          }
        }
        return mailAccounts[0]
      },
      // 添加标签回显
      resetMark(data) {
        let checkedContacts = this.checkedContacts
        checkedContacts.forEach(checked => {
          let mail = this.mailList.find(item => item.mailId === checked)
          if (mail) {
            let labelList = mail.labelList
            if (!labelList.find(label => label.labelName === data?.labelName)) { // 避免重复添加
              labelList.push({
                labelName: data.labelName,
                labelColor: data.labelColor,
                mailLabelConfigId: data?.mailLabelConfigMap && data?.mailLabelConfigMap[mail.mailId]
              })
              this.$set(mail, 'labelList', labelList)
            } else {
              labelList.forEach(label => {
                if (label.labelName === data.labelName) {
                  label.mailLabelConfigId = data?.mailLabelConfigMap && data?.mailLabelConfigMap[mail.mailId]
                }
              })
            }
          }
        })
      },
      // 清空checkedContacts
      clearCheckedContacts() {
        this.checkedContacts = []
        this.isIndeterminate = false
        this.checkAll = false
      },
      // 没有选中直接新建标签
      addNewMark(data) {
        mailLabelSave({
          ...data,
          emailAccount: this.currentAccount,
          businessId: this.userInfo.userId,
        }).then((res) => {
          if (res?.code === '000000') {
            //新增标签
            this.$message.success(this.$t('reqmsg.M2016'))
            // 重新获取标签数据
            this.$store.dispatch('mail/getMailMarkList')
            BusEmit('ActionBar', 'closeMarkDialog')
            // 刷新左侧标签数据
            BusEmit('MailLeftAside', 'refreshData')

          }
        })
      },
      // 编辑标签
      saveEditMark(data) {
        MailInteractor.mailLabelUpdateApi({
          ...data,
        }).then(res => {
          if (res?.code === '000000') {
            // 更新左侧标签树
            this.$store.dispatch('mail/getMailMarkList', { emailAccount: data.emailAccount })
            BusEmit('MailLeftAside', 'refreshData')
            BusEmit('ActionBar', 'closeMarkDialog')
            BusEmit('MarkManageModel', 'getList', data.emailAccount)
            BusEmit('MailInfo', 'rergetDetail')
            // 更新列表
            this.clearChecked = false
            this.getPageList()
            // 更新详情
          }
        })
      },
      //添加标签
      setMailMark(data) {
        // 直接新建标签
        if (!data.mailLabelId && !this.checkedContacts.length) {
          return this.addNewMark(data)

        }
        if (data.mailLabelId && this.checkedContacts.length == 0) {
          this.$message({
            type: 'warning',
            message: this.$t('reqmsg.$28'),
          })
          return false
        }
        let checkedAccount = this.checkCheckedData(true)
        if (!checkedAccount) {  //选中标签进行打标签时会进行相关校验
          return false
        }
        mailLabelSave({
          ...data,
          mailIds: this.checkedContacts.join(','),
          emailAccount: checkedAccount,
          businessId: this.userInfo.userId,
        }).then((res) => {
          if (res?.code === '000000') {

            if (!data.mailLabelId) {
              //新增标签
              this.$message.success(this.$t('reqmsg.M2016'))
              // 重新获取标签数据
              this.$store.dispatch('mail/getMailMarkList', { emailAccount: checkedAccount })
              BusEmit('ActionBar', 'closeMarkDialog')
            } else {
              this.$message.success(this.$t('reqmsg.$50'))
            }
            // 刷新左侧标签数据
            BusEmit('MailLeftAside', 'refreshData')

            if (this.checkedContacts.length) {  //重新设置标签
              // 重新设置列表标签
              this.resetMark(res?.data)
              // 详情页添加标签
              BusEmit('MailInfo', 'addMailInfoMark', res.data)
            }

          }
        })
      },
      // 更改标签
      afterRemoveLabel({ mailId, mailLabelConfigId }, isRemoveAll, mailIds) {
        // 清除所有标签
        if (isRemoveAll) {
          // 根据mailIds找到mailList中的mail，将mail的labelList设置为[]
          this.mailList.forEach(mail => {
            if (mailIds.includes(mail.mailId)) {
              mail.labelList = []
            }
          })
          return
        }
        // 单个删除标签
        let mail = this.mailList.find(m => m.mailId == mailId)
        if (mail) {
          mail.labelList = mail.labelList?.filter(l => l.mailLabelConfigId != mailLabelConfigId)
        }
      },
      //删除标签
      removeLabel(label, mail, mailIds) {
        if (!checksingleMarkDel(mail.emailAccount, this)) {
          return
        }
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {

          let [mailLabelConfigId, mailId] = [label.mailLabelConfigId, mail.mailId]
          deleteMailLabelConfig({
            mailLabelConfigId,
            mailId,
          }).then((res) => {
            if (res?.code == '000000') {
              this.$message.success(this.$t('reqmsg.M2008'))

              // 手动更改详情页标签
              BusEmit('MailInfo', 'delLabel', label.mailLabelConfigId)
              // 手动清除列表页对应标签
              this.afterRemoveLabel({
                mailLabelConfigId,
                mailId
              })
            }
          })
        })
      },
      //清除所有标签
      cleanLabel() {
        let mailIds = this.checkedContacts.slice()
        if (this.checkedContacts.length) {
          this.$confirm(this.$t('reqmsg.$51'), this.$t('reqmsg.M2005'), {
            type: 'warning',
          }).then(() => {
            cleanMailLabel({
              businessId: this.userInfo.userId,
              mailIds: mailIds.join(','),
            }).then((res) => {
              if (res.code == '000000') {
                this.$message.success(this.$t('reqmsg.$49'))
                this.afterRemoveLabel({}, true, mailIds)
                BusEmit('MailInfo', 'delLabelAll', mailIds)
              }
            })
          })
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
        }
      },
      // 列表数据更新时，清空勾选状态
      resetCheckBoxStatus() {
        this.isIndeterminate = false
        this.checkAll = false
      },
      updatePage(params = {}) {

        if (params.tenantId && !params.showSum) {
          this.showSum = false
        } else {
          this.showSum = true
        }
        this.page.pageNo = 1
        this.page.goPage = this.page.pageNo
        this.pageInfo = params
        this.getPageList(params)
      },
      // 倒计时结束执行
      countDowmEnd() {
        setTimeout(() => {
          this.getPageList()
          BusEmit('MailLeftAside', 'refreshData') // 触发更新邮件分类数量
        }, 2500)
      },
      widthChange(movement) {
        this.width -= movement
        if (this.width < 300) {
          this.width = 300
        }
        if (this.width > 900) {
          this.width = 900
        }
        this.$store.commit('mail/setMailListPageWidth', {
          centerWidth: this.width
        })
        savePageWidth()
      },
      getCurrentAccountObj() {
        let currentAccount = this.$store.state.mail.currentAccount

        let currentAccountObj = this.$store.state.mail.leftTableData?.find(
          (item) => item.emailAccount === currentAccount
        )
        let authStatus = '',
          businessId = ''
        if (currentAccountObj) {
          authStatus = currentAccountObj.authStatus
          businessId = currentAccountObj.businessId
        }
        return {
          currentAccount,
          authStatus,
          businessId,
        }
      },
      getReadAndUnreadCounts() {
        let { currentAccount, authStatus, businessId } =
          this.getCurrentAccountObj()
        let reqMethod =
          authStatus !== null
            ? MailSettingInteractor.getEmailAuthNumber
            : MailSettingInteractor.getEmailNumber


        let p2 = reqMethod({
          emailAccount: currentAccount,
          businessId,
          flag: 2, // 已读未处理
          boxName: !this.$route.query.userId ? this.$route.query.mailName : '',
          labelId: this.$route.query.mailLabelId,
          boxFullName: !this.$route.query.userId ? this.$route.query.boxFullName : '',
          mailAccountUserIds: this.getUserIds(this.$route.query.userId, currentAccount, this.$route.query.id),
        })

        Promise.all([p2]).then((res) => {
          this.tabs = [
            {
              nameCn: '全部',
              nameEn: 'All',
            },
            {
              nameCn: '未读',
              nameEn: 'Unread',
            },
            {
              nameCn: '已读未处理',
              nameEn: 'Pending',
              num: res[0]?.data?.num,
            },
            {
              nameCn: '已处理',
              nameEn: 'Solved',
            },
            {
              nameCn: '已认领/分派',
              nameEn: 'Claimed/Assigned',
            },
            {
              nameCn: '未认领/分派',
              nameEn: 'Unclaimed/Unassigned',
            },
          ]
        })
      },
      // 重设邮件内容
      resetMailItemContet({ content, mailId }) {
        const currentMail = this.mailList.find((item) => item.mailId === mailId)
        let { content_deal } = getMailDealField(this.pageInfo.keyWord, { ...currentMail, content }, this.utils)
        if (currentMail && !currentMail.content && content) {
          this.$set(currentMail, 'content', content)
          this.$set(
            currentMail,
            'content_deal',
            content_deal
          )
        }
      },
      // 获取分派认领设置
      getDispatchEnabled() {
        MailInteractor.getDispatchEnabledApi().then(res => {
          if (res?.code === '000000') {
            this.dispatchEnabled = res.data === 1
          }
        })
      },
      // 分派
      dispatch(item, isSingle) {
        // 分派校验
        if (Array.isArray(item)) { // 批量操作场景
          let checkResults = []
          for (let i = 0; i < item.length; i++) {
            checkResults.push(checkDistributeAndClaim(item[i], this.$store.state.mail.leftTableData))
          }

          if (checkResults.some(s => !s?.isPass)) { // 有校验不通过的
            let messageKeys = checkResults.filter(s => s.messageKey).map(s => s.messageKey)
            this.$message.warning(this.$t(messageKeys[0]))
            return
          }
        } else {
          item = [item]
        }
        this.$refs.dispatch.showAddEdit(item, isSingle)
      },

      // 认领
      claim(item, isSingle) {
        // 认领校验
        if (Array.isArray(item)) { // 批量操作场景
          let checkResults = []
          for (let i = 0; i < item.length; i++) {
            checkResults.push(checkDistributeAndClaim(item[i], this.$store.state.mail.leftTableData))
          }
          if (checkResults.some(s => !s?.isPass)) { // 有校验不通过的
            let messageKeys = checkResults.filter(s => s.messageKey).map(s => s.messageKey)
            this.$message.warning(this.$t(messageKeys[0]))
            return
          }
        } else {
          item = [item]
        }
        let reqMethod = isSingle ? MailInteractor.singleClaimApi : MailInteractor.claimApi
        reqMethod({
          mailIds: item.map(s => s.mailId)
        }).then(res => {
          if (res?.code === '000000') {
            this.$message.success(this.$t('myMail.claimedsuccessfully'))
            // 刷新左侧账号列表数据
            BusEmit('MailLeftAside', 'refreshData')
            this.getPageList()
          }
        })
      },

      // 移除当前账号下邮件
      removeAccountMail(item) {
        this.$confirm(this.$t('myMail.confirmToRemove'), this.$t('myMail.Tips'), {
          confirmButtonText: this.$t('myMail.Confirm'),
          cancelButtonText: this.$t('myMail.Cancel'),
          type: 'warning',
        })
          .then(() => {
            MailInteractor.removeMailApi({
              mailId: item.mailId,
              userId: this.$route.query.userId
            }).then(res => {
              if (res?.code === '000000') {
                this.$message.success(this.$t('myMail.removedsuccessfully'))
                // 刷新左侧账号列表数据
                BusEmit('MailLeftAside', 'refreshData')
                // 重新查询列表数据
                if (this.page.pageNo > 1 && this.mailList.length === 1) {
                  this.page.pageNo--
                }
                // 重新查询列表数据
                this.getPageList()
                // 清空邮件详情
                this.$emit('clearMail')

              }
            })
          })
          .catch(() => { })

      },

      // 获取认领分派记录
      getRecords(item, type, recordsField) {
        MailInteractor.listMailUserOperateApi({
          mailId: item.mailId,
          type
        }).then(res => {
          if (res?.code === '000000') {
            item[recordsField] = res.data || []

          }
        })
      },

      // 更改邮件列表发件状态
      changeMailSendFlag({ mailId, sendFlag }) {
        let cMail = this.mailList.find(mail => mail.mailId == mailId)
        if (cMail) {
          this.$set(cMail, 'sendFlag', sendFlag)
        }
      }


    },
  }
</script>

<style lang="scss" scoped>
  .my-mail-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .icon-star1 {
      cursor: pointer;
    }

    .icon-star {
      cursor: pointer;
      color: red;

    }
  }

  .tab-cont {
    flex-shrink: 0;
  }

  .mail-drop {
    max-height: 300px;
    overflow-y: auto;
  }

  .email-account {
    color: #999999;
    font-size: 12px;
    margin-left: 10px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .latest-aside {
    height: 100%;
    background: #fff;
    display: flex;

    .search-container {
      flex: 1;
      overflow-y: auto;

      .search-item-header {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        background: #f5f7fa;
        font-size: 12px;
        color: #303030;
        line-height: 36px;
        cursor: pointer;

        i {
          line-height: 36px;
        }
      }

      .search-item {
        width: 100%;
        padding: 7px 20px;
        background: #fff;
        font-size: 14px;
        color: #303030;
        line-height: 22px;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid #f5f7fa;

        &::before {
          content: '';
          width: 4px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: #0486fe;
          opacity: 0;
        }

        .sendtime {
          color: #f7b500;
        }

        .top-dl {
          width: 100%;
          display: flex;
          justify-content: space-between;
          white-space: nowrap;

          .top-dt {
            display: flex;
            width: 85%;

            .mail-item-title {
              margin-left: 10px;
              font-size: 14px;
              color: #222222;
              display: inline-block;
              /* max-width: 250px; */
              overflow: hidden;
              text-overflow: ellipsis;
            }

            i {
              font-size: 12px;
              color: #0486fe;
              margin-left: 5px;
            }
          }
        }

        .bottom-dl {
          display: flex;

          .bottom-dt {
            margin-right: 20px;
            width: 16px;
            display: flex;
            flex-direction: column;

            i {
              color: #f7b500;
              font-size: 14px;
              height: 16px;
              margin-bottom: 11px;

              &.mail-icon {
                display: block;
              }

              &.looked {
                color: #d9d9d9;
              }

              &.replyed {
                color: #2eb797;
              }

              &.send {
                color: #3abc38;
              }

              &.icon-sand-clock {
                color: #0486fe;
              }
            }
          }

          .bottom-dd {
            flex: 1;
            width: 0;

            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              i {
                font-size: 12px;
                margin-right: 5px;

                &.file {
                  color: #fa6400;
                }

                &.warning {
                  color: #e02020;
                }
              }
            }

            .mail-title {
              font-weight: bold;
              color: #666666;
            }
          }
        }

        &>span {
          margin-left: 15px;
        }

        &.active {
          background: #f6faff;

          &::before {
            opacity: 1;
            transition: 0.3s 0.1s;
          }

          transition: 0.3s 0.1s;
        }

        &:hover {
          background: #f6faff;

          &::before {
            opacity: 1;
            transition: 0.3s 0.1s;
          }

          transition: 0.3s 0.1s;
        }

        .mail-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.unread {
          .mail-item-title {
            font-weight: bold;
          }
        }

        &.read {
          .mail-text {
            color: #999;
          }
        }
      }

      .label-list {
        overflow: hidden;

        li {
          float: left;
          margin-right: 10px;
          margin-top: 4px;
          display: flex;
          align-items: center;
          height: 20px;
          font-size: 12px;
          color: #fff;
          padding: 0 4px;
          border-radius: 2px;
          max-width: 100%;

          .label {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .remove-icon {
            margin-left: 2px;
            cursor: pointer;
            opacity: 0;
          }

          &:hover {
            .remove-icon {
              opacity: 1;
            }
          }
        }
      }
    }

    .search-pagination {
      height: 40px;
      background: #fff;
      border-top: 1px solid #f5f7fa;
      display: flex;
      align-items: center;
      padding: 0 20px;

      ::v-deep .el-select {
        width: 60px;
        margin-left: 2px;

        .el-input__inner {
          padding: 0 4px;
        }

        .el-input__suffix {
          display: none;
        }
      }



      .page-current {
        display: flex;
        width: 60px;
        flex: 1;
        align-items: center;
        justify-content: center;

        .page-num {
          padding: 0 10px;
        }
      }

      .jump {
        ::v-deep .el-input {
          width: 32px;
          margin-left: 2px;
          margin-right: 2px;

          .el-input__inner {
            padding-left: 4px;
            padding-right: 4px;
            text-align: center;
          }
        }
      }
    }

    .ml-5 {
      margin-left: 5px;
    }

    i.primary {
      color: #d9d9d9 !important;
    }
  }

  .text-primary {
    color: #1890ff;
    opacity: .5;

    &:hover {
      opacity: 1;
      font-weight: 600;
    }
  }
</style>
<style>
  .w-100.el-popover {
    min-width: 100px !important;
  }

  .w-70.el-popover,
  .w-120.el-popover {
    min-width: 70px !important;
    background-color: rgba(0, 0, 0, 0.62);
    border: none !important;
  }

  .w-70.el-popover .popper__arrow::after,
  .w-120.el-popover .popper__arrow::after {
    border-right-color: rgba(0, 0, 0, 0.62) !important;
    border: none !important;
  }

  .fb-500 {
    font-weight: 700;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .text-dot {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mx-10 {
    margin: 0 10px;
  }
</style>
