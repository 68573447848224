<template>
  <div class="sage-platform-inventory">
    <el-form :model="inventoryForm" ref="inventoryForm" label-width="158px" class="inventory-form">
      <el-form-item
        label="Item Number："
        prop="itemNum"
        :rules="[
           { required: true, message: 'Item Number 必填'},
          {
            validator: (rule, value, callback) => {
              if(value || value == 0){
                const regexStart = /^0/;
                const regex = /^[A-Za-z0-9-]+$/;
                if(regexStart.test(value)){
                  callback('不能以0开头')
                }else if (!regex.test(value)){
                  callback('只能输入数字、英文字母和 -')
                }else{
                  callback()
                }
              }
                        
            },
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="inventoryForm.itemNum" clearable :maxlength="25"></el-input>
        <p class="tip"> <i class="iconfont icon-weixian"></i>{{ $t('Documentary.SAGETIP') }}</p>
      </el-form-item>
      <el-form-item
        label="Inventory On Hand："
        prop="stockNum"
        :rules="[
          { required: true, message: 'Inventory On Hand 必填'},
          { type: 'number', message: '只能输入纯数字'},
          {
          validator: (rule, value, callback) => {
            if (value < 0) {
              callback('只能输入0或大于0的整数'); // 检查负数
            } else if (value) {
              const regex = /^[1-9][0-9]*$/;
              if (!regex.test(value)) {
                callback('不能以0开头');
              } else {
                callback();
              }
            } else {
              callback(); // 如果没有值，调用 callback 继续验证
            }
          },
          trigger: 'blur',
        },

        ]"
      >
      <el-input v-model.number="inventoryForm.stockNum" clearable :maxlength="7"  min="0"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changeInventoryForm('inventoryForm')">{{ $t('Documentary.Sure') }}</el-button>
      </el-form-item>
    </el-form>
    <!-- 操作记录 -->
     <p class="title">{{ $t('Documentary.OPR') }}</p>
     <el-form :inline="true" :model="operationForm" class="operation-form" ref="operationForm" label-width="108px">
      <el-form-item
        label="Item Number："
        prop="businessId"
        :rules="[
          {
            validator: (rule, value, callback) => {
              if(value || value === '0'){
                const regexStart = /^0/;
                const regex = /^[A-Za-z0-9-]+$/;
                if(regexStart.test(value)){
                  callback('不能以0开头')
                }else if (!regex.test(value)){
                  callback('只能输入数字、英文字母和 -')
                }else{
                  callback()
                }
              }else{
                callback()
              }
                        
            },
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="operationForm.businessId" clearable :maxlength="25"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Documentary.Operator') + '：'" prop="creator">
        <el-input v-model="operationForm.creator" clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('Documentary.OPT') + '：'" prop="date">
        <el-date-picker
          v-model="operationForm.date"
          type="daterange"
          range-separator="～"
          start-placeholder="开始日期"
           value-format="yyyy-MM-dd"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('operationForm')">{{ $t('Documentary.query') }}</el-button>
        <el-button @click="resetForm('operationForm')">{{ $t('Documentary.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :total="total"
      :extra-height="186"
      @query="getList"
      @update:pageSize="page.pageNo = 1"
      @update:pageNumber="(val) => (page.pageNo = val)"
    >
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :label="item.tKey ? $t(item.tKey) : item.label"
        align="center"
        :prop="item.prop"
      > 
        <template slot-scope="scope">
          <p v-if="item.prop === 'successFlag'">
            {{ scope.row[item.prop] == '1' ? $t('other.succeed') : $t('other.M2009')}}
            <el-tooltip effect="light" :content="scope.row.remark" placement="bottom" v-if="scope.row[item.prop] != '1'">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </p>
          <p v-else-if="item.prop === 'businessContent'">
            {{ getRandomNumFixed(scope.row[item.prop],false,0) }}
          </p>
          <div v-else>
            {{ scope.row[item.prop]}}
          </div>
        </template>
      </el-table-column>
    </ErpTable>
    <hintCom :dialogBol.sync="dialogBol" :successFlag="successFlag" :desc="desc" @confirmFn="confirmFn"/>
  </div>
</template>
<script>
  import { addThousandSeparator } from 'kits'
  import { mapGetters } from 'vuex'
  import { PlatformProductsInteractor } from '@/core'
  import hintCom from "./com/hint.vue"
  export default {
    name:'sagePlatformInventory',
    data() {
      return {
        dialogBol:false,//更改库存弹窗
        successFlag:true,//是否成功
        desc:'',//弹窗提示
        inventoryForm: {
          itemNum: '',
          stockNum:null
        },
        operationForm:{
          businessId:'',
          creator:'',
          date:''
        },
        columns:[
         {
            label: 'Item Number',
            width: '120px',
            prop: 'businessId',
          },
          {
            label: 'Inventory On Hand',
            width: '120px',
            prop: 'businessContent',
          },
          {
            label: '操作人',
            width: '120px',
            prop: 'creator',
            tKey: 'Documentary.Operator',
          },
          {
            label: '操作结果',
            width: '120px',
            prop: 'successFlag',
            tKey: 'Documentary.result',
          },
          {
            label: '操作时间',
            width: '180px',
            prop: 'createTime',
            tKey: 'Documentary.OPT',
          }
        ],
        page: {
            //分页数据
            pageNo: 1,
            pageLe: 10,
          },
          total: 0, // 列表总条数
          tableData: [], //列表数据
      };
    },
    computed: { ...mapGetters({ lang: 'settings/language' }) },
    components:{
      hintCom
    },
    mounted() {
      this.getList()
    },
    methods: {
      // 处理千分位 
      getRandomNumFixed(amount, dealWithBol,fixed ){
        if(!amount && amount !== 0)return ''
        return addThousandSeparator(amount, dealWithBol,fixed)
      },
      // 更改库存
      changeInventoryForm(formName) {
        this.$refs[formName].validate( async (valid) => {
          if (valid) {
            const res = await PlatformProductsInteractor.stockUpdateApi(this.inventoryForm)
            if(res?.code === '400000'){
              this.dialogBol = true
              this.successFlag = false
              this.desc = res?.desc ?? ''
            }else if(res?.code === '000000'){
              let num = this.getRandomNumFixed(this.inventoryForm.stockNum)
              this.desc = `“${this.inventoryForm.itemNum}” 在 SAGE 平台库存已更新为：${num}`
              this.dialogBol = true
              this.successFlag = true
            }
          } else {
            return false;
          }
        });
      },
      // 库存弹窗点击确定
      confirmFn(){
        this.dialogBol = false
        this.getList()
      },
      // 查询操作记录
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
           this.getList()
          } else {
            return false;
          }
        });
      },
      // 重置查询
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.operationForm.date = null;
        this.getList()
      },
      // 操作记录查询
     async getList(){
        let searchParams = JSON.parse(JSON.stringify(this.operationForm))
        let {businessId, creator, date = []} = searchParams;
        console.log(date,'datedatedate')
        let startTime = null;
        let endTime = null;
        if(date && date.length){
          startTime = date[0]+' 00:00:00'
          endTime = date[1]+' 23:59:59'
        }
        const parasm = {
          businessId,
          creator,
          startTime,
          endTime,
          businessType:1,
          ...this.page
        }
        const res = await PlatformProductsInteractor.stockListPageApi(parasm)
        if(res.code === '000000'){
          this.tableData = res?.data?.data ?? []
          this.total = res?.data?.total ?? 0
        }else{
          this.tableData =  []
          this.total =  0
        }
      }
    }
  }
</script>
<style scope lang="scss">
.sage-platform-inventory{
  padding: 20px;
  .inventory-form{
    .el-form-item{
        .el-form-item__content{
          display: flex;
          .tip{
            margin-left: 10px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #F59A23;
          }
          .el-input{
            width: 185px;
          }
          .el-button{
            width: 140px;
          }
        }
      }
  }
  .title{
    font-size: 16px;
  }
  .operation-form{
    padding-top: 20px;
    .el-form-item{
      margin-right: 30px;
    }
  }
  .base-table{
    .table-custom{
      .el-table{
        tr{
          th:first-child {
            border-left: 1px solid #ebeef5;
          }
          td:first-child {
            border-left: 1px solid #ebeef5;
          }
        }

      }
    }
  }
}
</style>