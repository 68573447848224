<!--
  功能：询价需求
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年6月17日 20:00:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard
    title="询价需求"
    :tabs="tabs"
    @tab-click="handleTabClick"
    :loading="loading"
    :singleNum="singleNum"
    :teamNum="teamNum"
    @get-list="getList"
  >
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem class="procurement-demand-item" :key="index">
          <div
            class="content"
            @click="clientFollowClick(item)"
            :style="itemStyleObj"
          >
            <div class="procurement-demand-item--top">
              <span class="top-left">
                询价单号
                <span :style="boldStyle.bold">{{ item.number }}</span>
              </span>
              <span class="top-right">{{ item.createTime }}</span>
            </div>
            <div class="procurement-demand-item--bottom">
              <el-row>
                <el-col :span="8">
                  <p>
                    <span class="label">处理人:</span>
                    <span class="content">{{ item.handler }}</span>
                  </p>
                </el-col>
                <el-col :span="8">
                  <p>
                    <span class="label">需求数量:</span>
                    <span class="content">
                      {{ item.amount ? item.amount : '--' }}
                    </span>
                  </p>
                </el-col>
              </el-row>
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
    <!-- 询价 -->
    <InquiryModel ref="inquiryModel" @get-list="getList" />
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import InquiryModel from '@/views/purchase/inquiryDemand/components/inquiryModel.vue'
  import { HomeInteractor } from '@/core'
  export default {
    name: 'InquiryDemand',
    mixins: [mixins],
    props: {},

    // 组件状态值
    data() {
      return {
        list: [],
        tabs: true,
      }
    },
    components: {
      InquiryModel,
    },
    computed: {},

    methods: {
      getList() {
        this.loading = true
        const { tenantId, userId } = this.userInfo
        const requestData = {
          pageLe: 99999,
          pageNo: 1,
          authFlag: this.active === 'ME' ? 0 : 1,
          tenantId,
          userId,
        }

        HomeInteractor.homeInquiryOrderListPage(requestData).then((res) => {
          if (res && res.code === '000000') {
            const { ownTotal = 0, teamTotal = 0 } = res.data

            this.list = res.data.inquiryOrderListPageVOPageVO.data || []
            this.singleNum = ownTotal
            this.teamNum = teamTotal
          }
        })
        this.loading = false
      },

      clientFollowClick({ inquiryOrderId }) {
        if (this.beforeListItemClick()) {
          // 询价
          this.$refs.inquiryModel.initLoading(inquiryOrderId)
        }
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .procurement-demand-item {
    font-size: 14px;
    .procurement-demand-item--top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .top-left {
        font-size: 14px;
        font-family: Semibold;
        color: #000000;
      }
      .top-right {
        font-size: 12px;
        color: #5e5e5e;
      }
    }
    .procurement-demand-item--bottom {
      font-size: 12px;
      p {
        color: #5e5e5e;
        font-family: Regular;
        .label {
          margin-right: 5px;
        }
      }
    }
  }
</style>
