var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ladder-wrapper"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"100px","rules":_vm.rules,"size":"small"}},[_c('el-table',{attrs:{"data":_vm.form.tableData,"border":"","max-height":_vm.tableHeight}},[_c('el-table-column',{attrs:{"align":"center","prop":"num","label":"区间金额(开始)","label-class-name":"require-th","border":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"input-group"},[_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.beginAmount',"rules":[
                {
                  required: true,
                  validator: function (rule, value, cb) {
                    _vm.startNumValid(rule, value, cb, scope.$index)
                  },
                  trigger: 'blur',
                } ],"label-width":"0"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入"},on:{"change":function (val) { return _vm.padThree(val,scope.row,'beginAmount'); }},model:{value:(scope.row.beginAmount),callback:function ($$v) {_vm.$set(scope.row, "beginAmount", $$v)},expression:"scope.row.beginAmount"}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"num","label":"区间金额(结束)","label-class-name":"require-th","border":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"input-group"},[_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.endAmount',"rules":[
                {
                  required: true,
                  validator: function (rule, value, cb) {
                    _vm.endNumValid(rule, value, cb, scope.$index)
                  },
                  trigger: 'blur',
                } ],"label-width":"0"}},[_c('el-input',{attrs:{"size":"small","placeholder":"不填写则默认为+∞"},on:{"change":function (val) { return _vm.padThree(val,scope.row,'endAmount'); }},model:{value:(scope.row.endAmount),callback:function ($$v) {_vm.$set(scope.row, "endAmount", $$v)},expression:"scope.row.endAmount"}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"num","label":"提成点(%)"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label-width":"0","prop":'tableData.' + scope.$index + '.ratio',"rules":[
              
              {
              
                validator: function (rule, value, cb) {
                  _vm.percentValid(rule, value, cb, scope.$index)
                },
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入"},model:{value:(scope.row.ratio),callback:function ($$v) {_vm.$set(scope.row, "ratio", $$v)},expression:"scope.row.ratio"}})],1),(scope.row.ratio > 50)?_c('span',{staticClass:"info-p"},[_vm._v(" 当前录入的提成点较高，请核对，是否有误 ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"操作","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.$index == _vm.form.tableData.length - 1 &&
              _vm.form.tableData.length != 1
            )?_c('el-button',{staticClass:"red",attrs:{"disabled":_vm.form.tableData.length == 1,"icon":"el-icon-delete","type":"text","size":"mini"},on:{"click":function($event){return _vm.removeItem(scope.$index)}}}):_c('span',[_vm._v("-")])]}}])})],1),_c('div',{staticClass:"btn-wrapper"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.addNew}},[_vm._v("新增一行")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }