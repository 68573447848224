<!--
  功能：结算条件设置
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月30日 11:48:47
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="reject">
    <el-dialog
      title="结算条件设置"
      :visible.sync="dialogFormVisible"
      width="500px"
      @click="close"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form" label-position="left">
        <el-form-item prop="daysAfterReceivingComplete">
          <span slot="label"></span>
          销售订单收货后
          <el-input-number
            :controls="false"
            :min="0"
            :max="999"
            :precision="0"
            v-model="form.daysAfterReceivingComplete"
            clearable
            placeholder="请输入"
          ></el-input-number>
          天，收款完成
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  orderSettlementCondition,
  updateConditionPO,
} from '@/api/order-settlement'
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        daysAfterReceivingComplete: undefined,
        daysAfterReceivingNoComplete: undefined,
        exceptionFlag: 0,
        settlementSetupId: '',
      },
      rules: {
        daysAfterReceivingComplete: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        daysAfterReceivingNoComplete: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    show() {
      this.dialogFormVisible = true
    },

    close() {
      this.dialogFormVisible = false
      this.$refs.form.resetFields()
      this.form.exceptionFlag = 0
    },

    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          updateConditionPO({ ...this.form }).then((res) => {
            if (res?.code === '000000') {
              this.$message.success('结算条件保存成功！')
              this.dialogFormVisible = false
            }
          })
        }
      })
    },

    //获取结算条件
    _orderSettlementCondition() {
      orderSettlementCondition().then((res) => {
        if (res && res.code === '000000') {
          this.form = res.data || {}
        }
      })
    },
  },

  watch: {
    dialogFormVisible(val) {
      val && this._orderSettlementCondition()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
    .el-form-item__content {
      .el-form-item__error {
        padding-left: 123px;
      }
    }
  }
  .el-dialog__header {
    > .el-dialog__title {
      font-size: 14px;
    }
  }
  .el-dialog__footer {
    padding-top: 0;
    text-align: center;
  }
}
</style>

