<template>
  <el-dialog
    title="新建条件"
    :visible.sync="addConditionVisible"
    width="800px"
    :before-close="handleClose"
  >
    <div class="condition">
      <div class="condition-item">
        <span class="conditon-title">角色</span>
        <div class="target-box">
          <div class="target" v-for="item in 11" :key="item">
            <el-button type="primary" plain @click="setRole(item)">
              主要角色
            </el-button>
          </div>
        </div>
      </div>
      <div class="condition-item">
        <span class="conditon-title">符号/规则</span>
        <div class="target-box">
          <div class="target" v-for="item in 11" :key="item">
            <el-button type="primary" plain @click="setRules(item)">
              符号/规则
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="together">
      <div class="together-item">
        <span class="conditon-title">条件</span>
        <i class="el-icon-refresh-left" style="font-weight: bolder"></i>
      </div>
      <div class="target-box" style="width100%;height:100px">{{ result }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelModel">取 消</el-button>
      <el-button type="primary" @click="confirmModel">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // 弹窗显示
    addConditionVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      role: [],
      rules: [],
    }
  },
  computed: {
    result() {
      return ''
    },
  },
  methods: {
    handleClose() {},
    cancelModel() {
      this.$emit('update:addConditionVisible', false)
    },
    confirmModel() {
      this.$emit('update:addConditionVisible', false)
    },
    setRole(role) {
      this.role.push(role)
    },
    setRules(rule) {
      this.rules.push(rule)
    },
  },
}
</script>
<style lang="scss" scoped>
.condition {
  display: flex;
  justify-content: space-between;
  .condition-item {
    width: 49%;
  }
}
.target-box {
  border: 1px solid black;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  .target {
    margin: 4px;
  }
}
.conditon-title {
  color: red;
  border-bottom: 1px solid red;
  display: inline-block;
  padding: 5px 10px;
}
.together {
  margin-top: 15px;

  .together-item {
    display: flex;
    align-items: center;
  }
}
</style>
