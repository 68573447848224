<template>
  <div>
    <el-form inline :model="searchForm" ref="searchForm" label-width="100px">
      <!-- 操作类型 -->
      <el-form-item :label="$t('productDetail.OperationType')">
        <el-select
          v-model="searchForm.type"
          :placeholder="$t('placeholder.plsSel')"
          clearable
        >
          <!-- 上架 下架 -->
          <el-option
            :label="$t('productDetail.Activate')"
            :value="1"
          ></el-option>
          <el-option
            :label="$t('productDetail.Deactivate')"
            :value="2"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 操作平台 -->
      <el-form-item :label="$t('productDetail.OperationPlatform')">
        <el-select
          multiple
          v-model="searchForm.platform"
          :placeholder="$t('placeholder.plsSel')"
          clearable
        >
          <el-option
            v-for="(it, i) in platList"
            :key="i"
            :label="settings === 'zh' ? it.name : it.nameEn"
            :value="it.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 操作人 -->
      <el-form-item :label="$t('productDetail.Operator')" prop="name">
        <el-input
          v-model="creator"
          :placeholder="$t('placeholder.plsSel')"
          @focus="choosePeo"
          @clear="clearPerson"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 操作时间 -->
      <el-form-item :label="$t('productDetail.OperationTime')" prop="name">
        <!-- 开始日期 结束日期 至-->
        <el-date-picker
          v-model="searchForm.time"
          type="daterange"
          align="right"
          unlink-panels
          :range-separator="$t('other.To')"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <!-- 搜索 重置-->
        <el-button type="primary" @click="search">
          {{ $t('productDetail.Search') }}
        </el-button>
        <el-button @click="resetForm">{{ $t('other.reset') }}</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        :label="$t('productDetail.OperationType')"
        prop="type"
        align="center"
      >
        <template #default="{ row }">
          {{ typeFilter(row.type) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('productDetail.OperationPlatform')"
        prop="platformName"
        align="center"
      >
        <template #default="{ row }">
          {{ row[settings === 'en' ? 'platformEnName' : 'platformName'] }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('productDetail.DeactivateReason')"
        prop="removeReason"
        align="center"
      >
        <template #default="{ row }">
          {{ row.removeReason || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('productDetail.Operator')"
        prop="creator"
        align="center"
      >
        <template #default="{ row }">
          {{ row[settings === 'en' ? 'creatorEn' : 'creator'] }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('productDetail.OperationTime')"
        prop="createTime"
        align="center"
      ></el-table-column>
    </el-table>

    <Pagination
      v-show="total > 0"
      :limit.sync="pageLe"
      :page.sync="pageNo"
      :total="total"
      @pagination="query"
    />

    <!-- 选择人员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>
<script>
  import Pagination from '@/components/Pagination/Index'
  import UserChoose from '@/components/userChoose'
  import { ProductViewInteractor } from '@/core/interactors/product/productView'
  import { mapGetters } from 'vuex'
  export default {
    components: { UserChoose, Pagination },
    data() {
      return {
        searchForm: {
          type: '',
          platform: '',
          creatorId: '',
          time: [],
        },
        creator: '',
        platList: [],
        pickerOptions: {
          shortcuts: [
            {
              // 最近一周
              text: this.$t('placeholder.LastWeek'),
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              // 最近一个月
              text: this.$t('placeholder.LastMonth'),
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              // 最近三个月
              text: this.$t('placeholder.LastThreeMonths'),
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        pageNo: 1,
        pageLe: 10,
        total: 0,
        tableData: [],
        platList: [],
        that: this,
      }
    },
    props: {
      productId: {
        type: String,
        default: '',
      },
    },
    watch: {
      settings(val) {
        this.pickerOptions = {
          shortcuts: [
            {
              // 最近一周
              text: this.$t('placeholder.LastWeek'),
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              // 最近一个月
              text: this.$t('placeholder.LastMonth'),
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              // 最近三个月
              text: this.$t('placeholder.LastThreeMonths'),
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        }
      },
    },
    mounted() {
      this.query()
      this.initPaltList()
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
    },
    methods: {
      typeFilter(val) {
        let obj = {
          1: this.settings === 'zh' ? '上架' : 'Activate',
          2: this.settings === 'zh' ? '下架' : 'Deactivate',
        }
        return obj[val] || this.$t('productDetail.NotLaunched')
      },
      search() {
        this.pageNo = 1
        this.query()
      },
      resetForm() {
        this.searchForm = {}
        this.pageNo = 1
        this.creator = ''
        this.query()
      },
      async query(pageData) {
        if(pageData) {
          this.pageNo = pageData.page
          this.pageLe = pageData.limit
        }
        let res = await ProductViewInteractor.getProductPlatformList({
          ...this.searchForm,
          productId: this.productId,
          pageLe: this.pageLe,
          pageNo: this.pageNo,
        })
        this.tableData = res.data?.data || []
        this.total = res.data?.total
      },
      // 清空人员
      clearPerson() {
        this.creator = ''
        this.searchForm.creatorId = ''
      },
      // 选择人员
      choosePeo() {
        this.$refs['UserChoose'].showAddEdit('', '', true)
      },
      chooseUser(userIdArr) {
        this.searchForm.creatorId = userIdArr[0].id
        this.creator =
          this.settings === 'zh' ? userIdArr[0].name : userIdArr[0].englishName
      },
      // 平台列表
      async initPaltList() {
        let res = await ProductViewInteractor.getPlatList()
        this.platList = res?.data ?? []
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .table-custom {
      height: 100% !important;
      .el-table {
        height: 100% !important;
      }
    }
    .table-fixed {
      height: 580px !important;
    }
  }
</style>
