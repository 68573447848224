import Vue from 'vue'
import MailContent from '../components/mail-content'
import SignMailContent from '../components/sign-mail-content'

/**
 * @description 发货邮件
 * @param {*} config 
 * @param {*} header 
 * @returns 
 */
export default function getMailContentFromConfig(config, header) {
  ;['isRp', 'isRg', 'isSW'].forEach((item) => {
    config[item] =
      config.exportsRise.indexOf(item.split('s')[1].toUpperCase()) > -1
  })
  const Ctor = _extend(MailContent)
  const wrapper = document.createElement('div')
  const content = new Ctor({
    data() {
      return {
        config,
        header,
      }
    },
  }).$mount(wrapper).$el.outerHTML
  return content
}


export function getSignMialContent(config) {
  return _getContent(_extend(SignMailContent), {
    data() {
      return {
        config
      }
    }
  }, createWrapper())
}


const _extend = Comp => Vue.extend(Comp)

const _getContent = (Ctor, option, wrapper) => {
  return new Ctor({
    ...option,
  }).$mount(wrapper).$el.outerHTML
}

const createWrapper = () => document.createElement('div')