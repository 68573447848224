<!--
  功能：结算模板配置-固定
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月23日 09:18:39
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="fixed-wrapper">
    <div class="fixed-table">
      <h4>固定提成点暂存区</h4>
      <div class="list-wrapper">
        <!--
        <div v-for="(item, index) in list" :key="index" class="item">
          <span class="no">{{ index + 1 }}</span>
          <span class="sub">{{ item.name }}</span>
          <span class="sub">固定</span>
          <span class="sub">{{ item.fixedRatio }}</span>
          <el-button type="text" class="red">删除</el-button>
        </div>-->

        <el-table border stripe :data="list">
          <el-table-column
            label="序号"
            type="index"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            label="模板名称"
            prop="name"
            align="center"
          ></el-table-column>
          <el-table-column label="类型" align="center">
            <template slot-scope="scope">固定</template>
          </el-table-column>
          <el-table-column
            label="固定提成点"
            prop="fixedRatio"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button type="text" class="red" @click="del(scope.$index)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SettlementTplConfigInteractorFixed',
    props: {},
    components: {},
    // 组件状态值
    data() {
      return {
        list: [], //暂存数据
      }
    },
    methods: {
      // 暂存
      saveTemp(item) {
        this.list.push(item)
      },

      // 删除
      del(index) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.list.splice(index, 1)
          })
          .catch(() => {})
      },
    },
  }
</script>

<style scoped lang="scss">
  .fixed-wrapper {
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .fixed-table {
      width: 100%;
    }

    .list-wrapper {
      // border: 1px solid #eee;
      padding: 10px 10px 10px 0;
      .item {
        display: flex;
        border-bottom: 1px dashed #eee;
        padding-top: 10px;
        padding-bottom: 10px;
        align-items: center;
        .no {
          display: inline-block;
          height: 30px;
          width: 30px;
          line-height: 30px;
          border-radius: 50%;
          border: 1px solid #eee;
          text-align: center;
          margin-right: 10px;
        }
        .sub {
          flex: 1;
        }
      }
    }
  }
</style>
