<template>
  <!-- 规格图设置 -->
  <el-dialog
    :title="$t('other.setSpec')"
    :append-to-body="true"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <!-- 中文名称 -->
      <el-form-item :label="$t('productDetail.ChineseName')" prop="nameCn">
        <el-input
          v-model="form.nameCn"
          :placeholder="$t('placeholder.M2011')"
        />
      </el-form-item>
      <!-- 英文名称 -->
      <el-form-item :label="$t('productDetail.EnglishName')" prop="nameEn">
        <el-input
          v-model="form.nameEn"
          :placeholder="$t('placeholder.M2012')"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'ProductSpecPicModel',
  data() {
    return {
      // title: '规格图设置',
      // title: this.$t('other.setSpec'),
      form: {
        nameCn: '',
        nameEn: '',
        id: '',
        type: '',
        value: '',
      },
      rules: {},
      dialogFormVisible: false,
    }
  },
  created() {},
  methods: {
    //展示弹框
    publickModel(str, row) {
      this.$Bus.$on('bcglXiangXiList', (val) => {
        if (val) {
        }
      })
      this.dialogFormVisible = true
      this.form.nameEn = ''
    },
    // 关闭
    close() {
      this.form.nameEn = ''
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    // 保存
    async save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('fetch-data')
          this.close()
        }
      })
    },
  },
}
</script>
