import request from '@/core/services/request'

/**
 * 新建评价
 * @param {*} data
 * @returns
 */
export function getDetail() {
  return request({
    url: '/merchants/customerMessageConfigure/getDetail',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 新增客户评价设置
 * @returns
 */
export function insertCustomerMessageConfigurePO(data = []) {
  return request({
    url: '/merchants/customerMessageConfigure/insertCustomerMessageConfigurePO',
    method: 'post',
    data,
  })
}
