var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog-fixed",attrs:{"title":_vm.$t(_vm.title),"visible":_vm.dialogFormVisible,"width":"850px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('cusManage.Confirm'))+" ")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('cusManage.Cancel')))])],1)]},proxy:true}])},[_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"130px","label-position":"top"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.Contacts'),"prop":"linkmanId","rules":[
              {
                required: true,
                trigger: 'change',
                message: this.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsSel')},on:{"change":_vm.linkManChange},model:{value:(_vm.form.linkmanId),callback:function ($$v) {_vm.$set(_vm.form, "linkmanId", $$v)},expression:"form.linkmanId"}},_vm._l((_vm.linkManSelect),function(item){return _c('el-option',{key:item.linkmanId,attrs:{"label":item.linkman,"value":item.linkmanId}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.ContactDetails'),"prop":"linkType","rules":[
              {
                required: true,
                trigger: 'change',
                message: this.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsSel')},model:{value:(_vm.form.linkType),callback:function ($$v) {_vm.$set(_vm.form, "linkType", $$v)},expression:"form.linkType"}},_vm._l((_vm.linkTypeSelect),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.FollowUpTopic'),"prop":"theme"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsInput')},model:{value:(_vm.form.theme),callback:function ($$v) {_vm.$set(_vm.form, "theme", $$v)},expression:"form.theme"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.FollowUpDirection'),"prop":"direction","rules":[
              {
                required: true,
                trigger: 'change',
                message: this.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsSel')},model:{value:(_vm.form.direction),callback:function ($$v) {_vm.$set(_vm.form, "direction", $$v)},expression:"form.direction"}},_vm._l((_vm.directionOption),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.lang !== 'en' ? item.label : item.labelEn,"value":item.value}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.FollowUpPurpose'),"prop":"followGoal","rules":[
              {
                required: true,
                trigger: 'change',
                message: this.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsSel')},model:{value:(_vm.form.followGoal),callback:function ($$v) {_vm.$set(_vm.form, "followGoal", $$v)},expression:"form.followGoal"}},_vm._l((_vm.followGoalSelect),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.FollowUpTime'),"prop":"followTime"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","picker-options":_vm.pickerOptions,"value-format":"yyyy-MM-dd","placeholder":_vm.$t('placeholder.selDate')},model:{value:(_vm.form.followTime),callback:function ($$v) {_vm.$set(_vm.form, "followTime", $$v)},expression:"form.followTime"}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.AssociatedOrderType'),"prop":"orderType","rules":[
              {
                required: true,
                trigger: 'change',
                message: this.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsSel')},model:{value:(_vm.form.orderType),callback:function ($$v) {_vm.$set(_vm.form, "orderType", $$v)},expression:"form.orderType"}},_vm._l((_vm.customerSourceOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.OrderNumber'),"prop":"serial"}},[_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.form.serial),callback:function ($$v) {_vm.$set(_vm.form, "serial", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.serial"}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',[_c('el-form-item',{attrs:{"label":_vm.$t('cusManage.Remark'),"prop":"remark"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":_vm.$t('placeholder.rc'),"autosize":{ minRows: 2 },"maxlength":"500","show-word-limit":""},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }