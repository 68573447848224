<template>
  <div>
    <el-dialog title="BOM单库存扣减" :visible.sync="dialogTableVisible">
      <h4>BOM单库存扣减设置</h4>
      <el-table
        :data="gridData"
        border
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column property="skuId" label="产品编码" width="120">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false">
              {{ scope.row.skuId }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          property="productName"
          label="产品名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="parentSpecsValue"
          label="规格"
          width="100"
        ></el-table-column>
        <el-table-column
          property="productType"
          label="产品类别"
          width="80"
        ></el-table-column>
        <el-table-column property="date" label="申请出库数量/单位" width="150">
          <template slot-scope="scope">
            <span>
              {{ scope.row.expectOutNum || '--' }}/{{
                scope.row.productUnit || '--'
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column property="address" label="出库库位" width="120">
          <template slot-scope="scope">
            <!-- <el-link type="primary" @click="setStock(scope.row, scope.$index)">
              <span v-if="scope.row.outLoc.length == 0">请设置</span>
              <div v-else>
                <span v-for="(item, index) in scope.row.outLoc" :key="index">
                  {{ item.locationNumber }}（{{ item.outNum }}）；
                </span>
              </div>
            </el-link> -->
            <div v-if="ruleType !== '1'">
              {{ ruleType === '3' ? '从少到多自动扣减' : '从多到少自动扣减' }}
            </div>
            <div>
              <SelectedLocs
                :locs="scope.row.warehouseOutProductLocationDTOList"
                @add-loc="addLoc"
                :tableIndex="scope.$index"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-wrapper mt30 t_a_c" style="text-align: center">
        <el-button type="primary" class="ml30" @click="sumbit">确定</el-button>
        <el-button @click="closeDia">取 消</el-button>
      </div>
    </el-dialog>
    <Loc
      ref="loc"
      :params="locParams"
      :warehouseOutNum="currentRow.warehouseOutNum"
      :warehouseId="warehouseId"
      @set-loc="onSetLoc"
      @close="onClose('loc')"
    ></Loc>
  </div>
</template>
<script>
  import SelectedLocs from '../../inbound-sheet/components/selected-locs.vue'
  import { OutboundSheetInteractor } from '@/core'
  import Loc from './loc.vue'
  export default {
    components: {
      SelectedLocs,
      Loc,
    },
    data() {
      return {
        gridData: [],
        dialogTableVisible: false,
        locParams: {
          show: false,
          locs: [],
        },
        currentRow: {},
      }
    },
    props: {
      ruleType: {
        type: String,
      },
      warehouseId: {
        type: String,
      },
    },
    methods: {
      showDialog() {
        this.dialogTableVisible = true
      },
      setStock(val, index) {
        this.$emit('setStock', val, index)
      },
      closeDia() {
        this.dialogTableVisible = false
      },
      sumbit() {
        this.$emit('stockSubmit', this.gridData)
        this.dialogTableVisible = false
      },
      async addLoc(index) {
        let row = this.gridData[index]
        row.warehouseOutProductLocationDTOList =
          row.warehouseOutProductLocationDTOList
            ? row.warehouseOutProductLocationDTOList
            : []
        const { skuId, warehouseOutNum, giftNum, usable } = row
        // ,
        const warehouseId = this.warehouseId
        // if (!warehouseId) {
        //   return this.$message.warning('请选择仓库')
        // }

        if (!warehouseOutNum && warehouseOutNum !== 0) {
          return this.$message.warning('请输入实际出库数量')
        }
        // this.$refs.loc.giftCount = giftNum
        // this.$refs.loc.activeName = '1'

        let res = await OutboundSheetInteractor.listWarehouseLocationUsableNum({
          skuId,
          warehouseId,
          usable,
          flag: 1,
        })
        if (res && res.code === '000000') {
          res.data.forEach((item) => {
            item.outNum = 0
          })
          // 回显数据
          if (
            row.warehouseOutProductLocationDTOList &&
            row.warehouseOutProductLocationDTOList.length !== 0
          ) {
            res.data = res.data.map((item) => {
              let loc = row.warehouseOutProductLocationDTOList.find(
                (loc) => loc.locationNumber === item.locationNumber
              )
              if (loc) {
                item = { ...item, outNum: loc.num }
              }

              return item
            })
          } else {
            row.warehouseOutProductLocationDTOList = [...res.data]
          }

          this.locParams.locs = res.data
          this.locParams.index = index
          this.locParams.expectOutNum = row.expectOutNum
          this.locParams.isConfirm = true
          this.currentRow = row
        }

        this.locParams.show = true
      },

      onSetLoc(locs, totolCounts) {
        locs = locs.map((loc) => {
          let obj = {}
          obj.locationNumber = loc.locationNumber
          obj.num = loc.outNum
          obj.skuId = this.currentRow.skuId
          obj.warehouseId = this.warehouseId
          return obj
        })

        this.currentRow.warehouseOutProductLocationDTOList = locs
        // this.$set(this.currentRow, 'warehouseOutProductLocationDTOList', locs)
        this.currentRow.warehouseOutNum = totolCounts
        // this.currentRow.giftNum = giftCount

        this.onChangewarehouseOutNum(this.currentRow)
      },
      onChangewarehouseOutNum(row, e) {
        const { stockNum, warehouseOutNum, lockNum } = row
        if (stockNum - lockNum < warehouseOutNum) {
          this.$message.warning('库存不足')
          row.warehouseOutNum = 0
          event.target.value = ''
        }
        this.onChangePriceExcludingTax(row)
      },
      onChangePriceExcludingTax(row) {
        row.totalPrice = (row.price * row.warehouseOutNum).toFixed(3)
      },
      onClose(isLoc) {
        if (isLoc) {
          return (this.locParams.show = false)
        }
        this.locParams.show = false
      },
    },
  }
</script>
>
