<template>
  <div class="order-warning">
    <el-row>
      <el-col :span="2">
        <h4 class="warning-title">预警提醒</h4>
      </el-col>
    </el-row>
    <el-row>
      <el-form :model="form" ref="form">
        <el-col :span="22" :offset="1">
          <div>
            <el-radio v-model="form.deliveryTick" :label="1">
              <span>距离交期前</span>
              <el-form-item
                style="display: inline-block"
                prop="deliveryDays"
                :rules="{
                  required: form.deliveryTick === 1,
                  message: '时间不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="form.deliveryDays"
                  style="width: 120px; margin: 0 5px"
                  @change="numberFormat('delivery')"
                >
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>

              <span>预警</span>
            </el-radio>
            <el-radio v-model="form.deliveryTick" :label="2">
              <span>距离每个节点到日期前</span>
              <el-form-item
                style="display: inline-block"
                prop="everyNodeDays"
                :rules="{
                  required: form.deliveryTick === 2,
                  message: '时间不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="form.everyNodeDays"
                  style="width: 120px; margin: 0 5px"
                  @change="numberFormat('everyNode')"
                >
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>

              <span>预警</span>
            </el-radio>
            <small style="margin-left: -30px">（预计日期）</small>
          </div>

          <div>
            <div>
              <el-checkbox v-model="form.createTrackingTick">
                <span>生成跟踪单，状态=待更新超过</span>
                <el-form-item
                  style="display: inline-block"
                  prop="createTrackingTickTime"
                  :rules="{
                    required: form.createTrackingTick,
                    message: '时间不能为空',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="form.createTrackingTickTime"
                    style="width: 120px; margin: 0 5px"
                    @change="numberFormat('createTrackingTick')"
                  >
                    <template slot="append">小时</template>
                  </el-input>
                </el-form-item>

                <span>未更新预警</span>
              </el-checkbox>
              <small>（关闭勾选则不提醒）</small>
            </div>
            <div>
              <el-checkbox v-model="form.autoWarningTick">
                当系统日期等于或超过节点日期时，系统预警自动触发待办延期和消息提醒
              </el-checkbox>
              <!-- <small>（未处理每4个小时提醒一次）</small> -->
            </div>
            <!-- <div>
            <el-checkbox v-model="form.warningTimeSortTick">
              预警数据根据列表预警时间正序排序
            </el-checkbox>
          </div> -->
          </div>
          <div style="margin-top: 10px">
            <span>提醒方式</span>
            <div style="margin-left: 30px; margin-top: 5px">
              <el-checkbox-group v-model="form.tipList">
                <el-checkbox label="systemMessageTips">
                  系统消息提示
                </el-checkbox>
                <el-checkbox label="emailTips">邮件提醒</el-checkbox>
                <el-checkbox label="dingdingTips">手机钉钉</el-checkbox>
              </el-checkbox-group>
              <small>
                提示：提醒角色是根据单据上所有关联的人员核心(订单支持、采购、客户代表)
                一个人绑定多个邮件时，只会取第一个邮件作为通知，不会全部通知到。
              </small>
            </div>
          </div>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'order-warning',
  data() {
    return {
      form: {
        area: '', // 地区
        autoWarningTick: 0, //系统时间超过节点日期,自动触发预警和消息提醒的勾选框
        autoWarningUnit: 0, //自动触发预警和消息时间的单位: 1 小时 2 天
        trackingWarningSettingId: '', //业务id
        deliveryDays: '', //	距离交期前天数
        everyNodeDays: '', //	距离每个节点到期前天数
        deliveryTick: 1, // 	交期勾选按钮: 1距离交期 2距离每个节点到期
        createTrackingTickTime: '', //生成跟踪单后未更新预警时间
        createTrackingTickUnit: 1, //生成跟踪单后未更新预警时间的单位: 1 小时 2 天
        createTrackingTick: false, // 生成跟踪单勾选框
        // warningTimeSortTick: false, //预警数据根据列表预警时间正序排序的勾选框
        autoWarningTick: false, //系统时间超过节点日期,自动触发预警和消息提醒的勾选框
        tipList: [], //提醒方式
      },
    }
  },
  props: {
    reminderInfo: {
      default: () => {},
      type: Object,
    },
  },
  watch: {
    reminderInfo: {
      handler() {
        this.setReminderInfo(this.reminderInfo)
      },
    },
    form: {
      handler() {
        this.sendReminderData()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    numberFormat(type) {
      switch (type) {
        case 'delivery':
          this.form.deliveryDays = _.toInteger(this.form.deliveryDays)
          this.form.deliveryDays =
            this.form.deliveryDays > 0 ? this.form.deliveryDays : 0
          break
        case 'everyNode':
          this.form.everyNodeDays = _.toInteger(this.form.everyNodeDays)
          this.form.everyNodeDays =
            this.form.everyNodeDays > 0 ? this.form.everyNodeDays : 0
          break
        case 'createTrackingTick':
          this.form.createTrackingTickTime = _.toInteger(
            this.form.createTrackingTickTime
          )
          this.form.createTrackingTickTime =
            this.form.createTrackingTickTime > 0
              ? this.form.createTrackingTickTime
              : 0
          break
      }
    },
    sendReminderData() {
      let obj = { ...this.form }
      obj.createTrackingTick = this.form.createTrackingTick ? 1 : 0
      // obj.warningTimeSortTick = this.form.warningTimeSortTick ? 1 : 0
      obj.autoWarningTick = this.form.autoWarningTick ? 1 : 0

      obj.systemMessageTips =
        this.form.tipList?.indexOf('systemMessageTips') > -1 ? 1 : 0
      obj.emailTips = this.form.tipList?.indexOf('emailTips') > -1 ? 1 : 0
      obj.dingdingTips = this.form.tipList?.indexOf('dingdingTips') > -1 ? 1 : 0

      delete obj.tipList

      this.$emit('getReminderData', obj)
    },
    // 处理详情数据
    setReminderInfo(info) {
      this.form = { ...info, tipList: [] }
      info?.systemMessageTips === 1
        ? this.form.tipList.push('systemMessageTips')
        : ''
      info?.emailTips === 1 ? this.form.tipList.push('emailTips') : ''
      info?.dingdingTips === 1 ? this.form.tipList.push('dingdingTips') : ''
      this.form.createTrackingTick =
        this.form.createTrackingTick === 1 ? true : false
      // this.form.warningTimeSortTick =
      //   this.form.warningTimeSortTick === 1 ? true : false
      this.form.autoWarningTick = this.form.autoWarningTick === 1 ? true : false
    },
  },
}
</script>
<style lang="scss" scoped>
.warning-title {
  display: inline-block;
  border-bottom: 1px solid red;
  box-sizing: border-box;
  padding: 5px 8px;
}
</style>
