<template>
  <div class="aside">
    <div class="aside-head-wrapper">
      <div class="aside-head">
        <!-- <div class="label">选择人员</div> -->
        <div class="label">{{ $t('mailcheck.ChooseAPerson') }}</div>
        <el-dropdown @command="changeStatus">
          <span class="el-dropdown-link">
            <span>{{ $t(statusName) }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
          <el-dropdown-menu class="mail-drop" slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in states"
              :key="index"
              :command="item"
            >
              {{ $t(item.label) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!--  placeholder="请输入，按Enter搜索" -->
      <el-input
        :placeholder="$t('placeholder.EnterandpressEntertosearch')"
        v-model="keyword"
        clearable
        @keypress.native.enter="getMenberList"
        @clear="getMenberList"
      ></el-input>
    </div>
    <ul class="user-list">
      <li
        v-for="(item, index) in menberList"
        :key="index"
        :class="['item', { active: item.userId == currentMember }]"
        @click="memberChange(item)"
      >
        <span :class="['name', item.onTheJob !== 1 ? 'leave' : '']">
          {{ item.enName }}/{{ item.name }}
          {{ item.onTheJob !== 1 ? `（${$t('other.departure')}）` : '' }}
        </span>
        <i class="count" v-if="item.replyCount">{{ item.replyCount }}</i>
      </li>
    </ul>
  </div>
</template>
<script>
import { MailSettingInteractor } from '@/core'
import { mapState } from 'vuex'
import { BusEmit, BusOn } from '@/utils/eventBus'
import { mapGetters } from 'vuex'
export default {
  name: 'MailCenterAsides',
  mixins: [BusOn],
  data() {
    return {
      keyword: '',
      states: [
        { label: 'other.all', value: null, langKey: 'other.all' },
        { label: 'other.onjob', value: 1, langKey: 'other.onjob' },
        { label: 'other.departure', value: 0, langKey: 'other.departure' },
      ],
      statusName: 'other.all',
      status: null,
      menberList: [],
      currentMember: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  created() {
    this.getMenberList()
  },
  methods: {
    changeStatus(data) {
      this.status = data.value
      this.statusName = data.langKey
      this.getMenberList()
    },
    // 选择人员
    memberChange(item) {
      this.currentMember = item.userId 
      BusEmit('MailCenterMailLists', 'setUserId', this.currentMember)
      BusEmit('MailCenterToolBars', 'clearSearchEmai')
      let arr = item?.emailAccounts?.split(',') ?? []
      if(arr.length) arr =  arr.reduce((previousValue, currentValue) => {
        previousValue.push({
          emailAccount:currentValue
        })
        return previousValue
      },[])
      BusEmit('MailCenterToolBars', 'setAccountList', arr)
      
      
    },
    // 获取人员
    getMenberList() {  
      this.getCentralStation()
    },
    // 中心站获取人员
    async getCentralStation(){
      let params = {
        name: this.keyword,
        status: this.status,
      }
      let res = await MailSettingInteractor.centralUserListApi(params)
      if (res?.code == '000000') {
        if(res?.data?.length){
          this.menberList = res.data.map(item=>{
            item.enName = item.englishName
            item.name = item.userName
            return item
          })
          this.memberChange(this.menberList[0])
        }else{
          this.menberList =  []
          BusEmit('MailCenterToolBars', 'setAccountList', [])
          BusEmit('MailCenterMailLists', 'setUserId', null)
        }      
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.aside {
  display: flex;
  height: 100%;
  flex-direction: column;
  border-right: 1px solid #f5f7fa;
  .aside-head-wrapper {
    padding: 10px;
  }
  .aside-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .label {
      font-size: 14px;
      color: #666;
      flex: 1;
    }
  }
  .user-list {
    min-height: 300px;
    flex: 1;
    overflow-y: auto;
    .item {
      display: flex;
      align-items: center;
      height: 40px;
      margin-bottom: 6px;
      cursor: pointer;
      user-select: none;
      padding: 10px 10px;
      line-height: 20px;
      &:hover,
      &.active {
        background-color: #f8f8f8;
      }
    }
    .name {
      flex: 1;
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #333;
      &.leave {
        color: #f00;
      }
    }
    .count {
      min-width: 16px;
      height: 16px;
      text-align: center;
      padding: 0 4px;
      border-radius: 10px;
      line-height: 16px;
      background-color: #f33;
      color: #fff;
      font-size: 12px;
      font-style: normal;
    }
  }
}
</style>