<!-- 出库数量配置弹窗 -->
<template>
  <el-dialog
    title="出库数量配置"
    :visible.sync="params.showDialog"
    width="550px"
  >
    <p>是否允许出库数量大于订单数量</p>
    <p>
      <el-radio-group v-model="outNumSettingFlag">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
    </p>

    <div style="display: flex; justify-content: center">
      <el-button type="primary" @click="save">确 认</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import { OrderSettingInteractor } from '@/core/interactors/order-setting'

  export default {
    name: 'OutboundQuantityConfiguration',
    props: {
      params: {
        type: Object,
        default: () => ({
          showDialog: false,
          value: 0,
          amountId: '',
        }),
      },
    },

    data() {
      return {
        outNumSettingFlag: 0,
      }
    },

    methods: {
      //保存
      save() {
        console.log('outNumSettingFlag', this.outNumSettingFlag)
        OrderSettingInteractor.setOutboundQuantityConfig({
          value: this.outNumSettingFlag,
          amountId: this.params.amountId,
        }).then(() => {
          this.$message.success('保存成功')
          this.$emit('refresh')
        })
      },
      show(outNumSettingFlag) {
        this.outNumSettingFlag = outNumSettingFlag
      },
      cancel() {
        this.params.showDialog = false
      },
    },
  }
</script>

<style lang="scss" scoped></style>
