<template>
  <div class="custom-container track-container">
    <track-table
      @current-change="changeCurrent"
      class="top-wrapper"
    ></track-table>
    <track-data-detail
      ref="detail"
      class="bottom-wrapper"
      :cIndex="cIndex"
      @tabindex-change="changeTab"
    ></track-data-detail>
  </div>
</template>
<script>
import trackTable from './components/track-table.vue'
import trackDataDetail from './components/track-data-detail.vue'
export default {
  name: 'TrackList',
  components: { trackTable, trackDataDetail },
  data() {
    return {
      current: Object.create(null), //选中行
      cIndex: 1, //选中tabIndex
    }
  },

  methods: {
    // 选中变化
    changeCurrent(trackInfo) {
      // this._resetCIndex()
      this.current = trackInfo
      this.$refs.detail.initDetail(trackInfo)
    },

    // tab变化
    changeTab(index) {
      this.cIndex = index
    },

    // 重置
    _resetCIndex() {
      this.cIndex = 1
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-container.track-container {
  height: 100vh !important;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
}
.top-wrapper {
  flex: 3;
  overflow-y: scroll;
}

.bottom-wrapper {
  flex: 2;
  overflow: hidden;
}
</style>
