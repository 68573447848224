<template>
  <el-dialog
    top="10vh"
    title="IP黑名单"
    :visible.sync="modelVisible"
    width="1000px"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-row class="search">
      <el-col :span="20">
        <el-input
          v-model="searchIP"
          placeholder="请输入IP"
          maxlength="20"
          clearable
        ></el-input>
        <el-button type="primary" @click="search" class="ml10">搜索</el-button>
      </el-col>
      <el-col :span="4">
        <div class="text-right">
          <el-button @click="IPVisible = true">添加IP黑名单</el-button>
        </div>
      </el-col>
    </el-row>

    <el-table
      class="mt15"
      :data="tableData"
      border
      :height="572"
      :cell-style="{ 'text-align': 'center' }"
      :header-cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column label="IP" prop="ip"></el-table-column>
      <el-table-column label="拉黑时间" prop="createTime"></el-table-column>
      <el-table-column label="操作人" prop="creatorName"></el-table-column>
      <el-table-column label="操作" :width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click="remove(scope.row.blackId)"
          >
            移除黑名单
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="page.pageNo"
      :page-size="page.pageLe"
      :total="total"
      @current-change="currentChange"
    ></el-pagination>

    <div class="text-center mt15">
      <el-button @click="close">取消</el-button>
    </div>

    <!-- 添加ip黑名单 -->
    <el-dialog
      top="25vh"
      title="添加IP黑名单"
      :visible.sync="IPVisible"
      width="600px"
      @close="IPClose"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form
        class="mt20"
        :model="form"
        :rules="rules"
        ref="form"
        label-width="50px"
      >
        <el-form-item label="IP:" prop="ip">
          <el-input
            v-model="form.ip"
            placeholder="请输入"
            maxlength="20"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="text-right mt30">
        <el-button @click="IPClose">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { systemInteractor } from '@/core'
export default {
  data() {
    return {
      searchIP: '',
      form: {
        ip: '',
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageLe: 10,
      },
      total: 0,
      IPVisible: false,
      modelVisible: false,
      rules: {
        ip: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    }
  },
  methods: {
    // 初始化
    init() {
      this.search()
      this.modelVisible = true
    },

    // 获取列表
    async getList() {
      const params = {
        ...this.page,
        ip: this.searchIP,
      }
      const res = await systemInteractor.userBlackListPage(params)

      if (res && res.code === '000000') {
        this.tableData = res.data?.data || []
        this.total = res.data?.total || 0
      }
    },

    // 搜索
    search() {
      this.page.pageNo = 1
      this.getList()
    },

    // ip黑名单取消
    IPClose() {
      this.form.ip = ''
      this.IPVisible = false
      this.$refs['form'].resetFields()
    },

    // IP黑名单保存
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const res = await systemInteractor.addBlack(this.form.ip)

          if (res && res.code === '000000') {
            this.getList()
            this.$message.success('添加成功')
            this.IPClose()
          }
        }
      })
    },

    // 移除黑名单
    remove(id) {
      this.$confirm('确定移除黑名单？', '提示', {
        type: 'warning',
      }).then(async () => {
        const res = await systemInteractor.removeBlack(id)

        if (res && res.code === '000000') {
          this.getList()
          this.$message.success('移除成功')
        }
      })
    },

    // 切换分页
    currentChange(val) {
      this.page.pageNo = val
      this.getList()
    },

    // 关闭
    close() {
      this.modelVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.search .el-input {
  width: 300px;
}
::v-deep {
  .el-dialog__body {
    padding: 5px 20px 20px;
  }
}
</style>

