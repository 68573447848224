import { sendGoodsMail as sendGoodsMailRequest } from '../../helper/send-mail'
import { mapGetters } from 'vuex'

const mixin = {
  data() {
    return {
      shippingOrderId: '', //委托单id
      selPhotoOption: {
        show: false,
      }, // 选择大货照参数
      bigGoodsList: [], //大货照列表

    }

  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },

  methods: {

    sendGoodsMail({ shippingOrderId, shipTime }) {
      this.shippingOrderId = shippingOrderId
      sendGoodsMailRequest(
        shippingOrderId,
        { tenantId: this.userInfo.tenantId, shipTime },
        this
      ).then((res) => {
        if (!res[0]?.sendEmail) {
          return
        }
        this.bigGoodsList = res
        this.selPhotoOption.show = true
        this.selPhotoOption.content = res[0] //邮件信息
      })
    },
  }
}

export default mixin
