/*
 * @Author: xinlaiyang 
 * @Date: 2022-04-14 10:20:25 
 * @Last Modified by: xinlaiyang
 * @Last Modified time: 2022-04-28 18:10:29
 * @content: 主产品，子产品标签，鼠标悬浮显示弹窗
 */

<template>
  <div>
    <el-popover placement="bottom" :trigger="triggerType" v-if="showTable">
      <el-table
        :data="tableData"
        border
        :cell-style="{ 'text-align': 'center' }"
        :header-row-style="{ 'text-align': 'center' }"
      >
        <el-table-column width="100" label="产品图">
          <template slot-scope="scope">
            <erp-image :imgs="scope.row.productImg"></erp-image>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          property="productCode"
          label="产品编码"
        ></el-table-column>
        <el-table-column width="150" label="中文品名/英文品名">
          <template slot-scope="scope">
            <span>
              {{ scope.row.nameCn || '--' }}/{{ scope.row.nameEn || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="80" property="productSpec" label="规格">
          <template slot-scope="scope">
            {{ scope.row.productSpec || '--' }}
          </template>
        </el-table-column>
        <el-table-column width="100" property="num" :label="numName">
          <template slot-scope="scope">
            {{ scope.row.num || '--' }}
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="reference"
        class="popover-title"
        :class="
          itemTitle === '主'
            ? 'green-box'
            : itemTitle === '子'
            ? 'origin-box'
            : ''
        "
      >
        {{ itemTitle }}
      </span>
    </el-popover>
    <span
      v-else
      slot="reference"
      class="popover-title"
      :class="
        itemTitle === '主'
          ? 'green-box'
          : itemTitle === '子'
          ? 'origin-box'
          : ''
      "
    >
      {{ itemTitle }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'popover-ship',
  props: {
    itemTitle: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    showTable: {
      type: Boolean,
      default: true,
    },
    triggerType: {
      type: String,
      default: 'hover',
    },
    numName: {
      type: String,
      default: '订单数量',
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.popover-title {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 13px;
  margin-left: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.popover-title:hover {
  cursor: pointer;
}
.green-box {
  background: rgba(112, 182, 3, 0.2);
  color: #70b603;
}
.origin-box {
  background: rgba(245, 154, 35, 0.2);
  color: #f59a23;
}
</style>