<template>
  <div v-if="!editFlag" class="search-item-header">
    <div>
      <i class="el-icon-arrow-down mr-10"></i>
      <span>{{ config.name }}({{ config.num }})</span>
    </div>
    <div class="edit-btns" v-if="!search">
      <i
        class="iconfont icon-zhifeiji mr-5"
        v-if="config.num > 0"
        @click.stop="handleWrite(config)"
      ></i>
      <i
        class="iconfont icon-lajitong1 warning mr-5"
        @click.stop="handleRemoveType"
      ></i>
      <i class="iconfont icon-bi" @click.stop="handleEdit"></i>
    </div>

    <i
      class="iconfont icon-zhifeiji"
      v-if="search && config.num > 0"
      @click.stop="handleWrite(config)"
    ></i>
  </div>
  <div v-else class="book-edit" @click.stop="">
    <div>
      <el-input
        ref="currentInput"
        v-model="editValue"
        maxlength="20"
        show-word-limit
        @blur="beforeBlur"
      ></el-input>
    </div>

    <div>
      <div>
        <i class="el-icon-success" @mousedown="handleSave"></i>
      </div>
      <div>
        <i class="el-icon-error" @mousedown="handleClear"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {
  updateContactCatagory,
  deleteContactCatagory,
} from '@/api/mail/contacts.js'
export default {
  data() {
    return {
      editValue: '',
      editFlag: false,
      resolve: false,
    }
  },
  props: {
    search: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleEdit() {
      this.editValue = this.config.name
      this.editFlag = true
      this.$nextTick(function () {
        this.$refs.currentInput.focus()
      })
    },
    handleSave() {
      this.resolve = true
      if (!this.editValue) {
        this.$message({
          type: 'warning',
          // message: '请输入分组名称',
          message: this.$t('reqmsg.$30'),
        })
      } else {
        if (this.editValue.length > 20) {
          return this.$message({
            type: 'warning',
            // message: '分组名称限制20个字符',
            message: this.$t('reqmsg.$31'),
          })
        }
        let data = {
          name: this.editValue,
          emailAccount: this.$store.state.mail.currentAccount,
          contactCatagoryId: this.config.contactCatagoryId,
        }
        updateContactCatagory(data).then((res) => {
          if (res === undefined) {
            this.resolve = false
            this.$refs.currentInput.focus()
            return
          }
          if (res.code === '000000') {
            this.$emit('initTypeData')
            this.editFlag = false
            this.resolve = false
            this.$message({
              type: 'success',
              // message: '更新成功！',
              message: this.$t('reqmsg.$2'),
            })
          } else {
            this.resolve = false
          }
        })
      }
    },
    handleWrite(data) {
      this.$emit('handleWrite', data)
    },
    handleRemoveType(data) {
      if (this.config.num > 1) {
        this.$message({
          type: 'warning',
          // message: '该分组下有联系人，无法删除该分组',
          message: this.$t('reqmsg.$32'),
        })
      } else {
        deleteContactCatagory({
          contactCatagoryId: this.config.contactCatagoryId,
        }).then((res) => {
          if (res.code === '000000') {
            this.$emit('initTypeData')
            this.editFlag = false
            this.$message({
              type: 'success',
              // message: '删除成功！',
              message: this.$t('reqmsg.M2008'),
            })
          }
        })
      }
    },
    handleClear() {
      this.resolve = true
      this.editValue = ''
      this.editFlag = false
      this.$nextTick(function () {
        this.resolve = false
      })
    },
    beforeBlur() {
      if (!this.resolve) {
        // this.$confirm('此操作将放弃编辑, 是否继续?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // })
        this.$confirm(this.$t('reqmsg.$33'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('reqmsg.M2006'),
          cancelButtonText: this.$t('reqmsg.M2007'),
          type: 'warning',
        })
          .then(() => {
            this.editValue = ''
            this.editFlag = false
          })
          .catch(() => {
            this.$refs.currentInput.focus()
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.search-item-header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background: #f5f7fa;
  font-size: 14px;
  color: #303030;
  line-height: 36px;
  cursor: pointer;
  .mr-10 {
    margin-right: 10px;
  }
  i {
    line-height: 36px;
  }
  .edit-btns {
    display: none;
    line-height: 1;
    .mr-5 {
      margin-right: 10px;
    }
  }
  .icon-zhifeiji {
    display: none;
  }
  &:hover {
    .icon-zhifeiji {
      display: inline-block;
    }
    .edit-btns {
      display: block;
    }
  }
}
.mr-10 {
  margin-right: 10px;
}
.book-edit {
  display: flex;
  padding: 2px 14px;
  height: 36px;
  box-sizing: border-box;
  background: #f5f7fa;
  /deep/ .el-input__inner {
    line-height: 32px !important;
    height: 32px !important;
  }
  & > div:first-child {
    flex: 1;
  }
  & > div:last-child {
    width: 60px;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    & > div {
      padding: 0 5px;
      width: 30px;
      line-height: 30px;
      cursor: pointer;
      i {
        line-height: 30px;
      }
    }
  }
}
</style>
