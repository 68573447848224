<template>
  <el-dialog
    :visible.sync="params.show"
    title="出库库位"
    width="900px"
    append-to-body
    @close="onClose"
    modal-append-to-body
  >
    <el-table
      :data="locs"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      border
      striped
    >
      <el-table-column prop="locationNumber" label="库位号"></el-table-column>
      <el-table-column prop="num" label="当前数量"></el-table-column>
      <el-table-column align="cernter" prop="outNum" label="出库数量">
        <template slot-scope="scope">
          <el-input-number
            :precision="0"
            :min="0"
            :max="scope.row.num"
            :disabled="params.disabled"
            v-model.number="scope.row.outNum"
            placeholder="输入出库数量"
            @blur="outNumBlur(scope.row.outNum, scope.$index)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        v-if="type === 1"
        prop="locationNumber"
        label="入库库位号"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.warehouseLocationInId"
            placeholder="选择库位"
            @change="(val) => changeloc(val, scope.$index)"
          >
            <el-option
              v-for="loc in locList"
              :key="loc.warehouseLocationId"
              :label="loc.fullLocationNumber"
              :value="loc.warehouseLocationId"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column v-if="type === 1" prop="num" label="入库库位数">
        <template slot-scope="scope">
          <el-input-number
            :disabled="true"
            :precision="0"
            :min="0"
            v-model.number="scope.row.inNum"
            placeholder="输入入库数量"
          ></el-input-number>
        </template>
      </el-table-column>
    </el-table>
    <p class="mt20">总数量：{{ totolCounts }}</p>
    <div class="footer-wrapper mt30 t_a_c" v-if="params.disabled !== true">
      <el-button type="primary" class="ml30" @click="sureAction">
        保存
      </el-button>
      <el-button @click="cancelAction">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getWarehouseLocationNum } from '@/api/stock-center/inbound-sheet'
  export default {
    name: 'locForOut',
    props: {
      // 弹窗控制
      params: {
        type: Object,
        default: () => {},
      },
      type: {
        type: [Number, String],
        default: 0,
      },
      warehouseId: [String],
      warehouseOutNum: [Number],
      locList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        activeName: '1',
        giftCount: '',
      }
    },

    computed: {
      totolCounts() {
        const arr = this.locs.map((i) => i.outNum).filter((i) => i)

        if (arr.length === 0) {
          return 0
        } else {
          return arr.reduce((prev, next) => prev + next)
        }
      },

      totalNum() {
        const arr = this.locs.map((i) => i.num)
        if (arr.length === 0) {
          return 0
        } else {
          return arr.reduce((prev, next) => prev + next)
        }
      },
      locs() {
        if (this.params && this.params.locs) {
          return this.params.locs
        } else {
          return []
        }
      },
    },
    created() {},

    methods: {
      changeloc(val, index) {
        const loc = this.locList.find((i) => i.warehouseLocationId === val)
        if (loc) {
          this.locs[index].locationInNumber = loc.fullLocationNumber
        }
      },
      outNumBlur(val, i) {
        if (this.type === 1) {
          this.locs[i].inNum = val
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      // 获取产品列表
      getProducts() {},
      onSearch(row) {
        const { locationNumber } = row
        if (locationNumber || locationNumber === 0) {
          getWarehouseLocationNum({
            locationNumber,
            warehouseId: this.warehouseId,
          }).then((res) => {
            row.currentNum = res.data
          })
        } else {
          this.$message.warning('请输入库位号')
        }
      },
      onReset() {
        this.param = ''
        this.onSearch()
      },
      cancelAction() {
        this.onClose()
      },
      sureAction() {
        const { totolCounts } = this
        if (totolCounts === 0) {
          return this.$message.warning(`已选出库数量只能为正整数`)
        }
        if (this.params.isConfirm) {
          if (totolCounts !== this.params.lotNumber) {
            return this.$message.warning('出库数量不等于调拨出库数量')
          }
        }

        if (totolCounts !== this.params.lotNumber) {
          return this.$message.warning('出库数量不等于调拨出库数量')
        }
        const locs = this.params.locs.filter(
          (item) => item.outNum && item.outNum !== 0
        )
        this.$emit('set-loc', locs, this.totolCounts, this.giftCount)
        this.$emit('close')
      },
      onClose() {
        this.$emit('close')
      },
    },
  }
</script>

<style scoped lang="scss">
  .footer {
    .el-button + .el-button {
      margin-left: 30px;
    }
  }
  .loc-item {
    display: flex;
    align-items: center;
  }

  .error-p {
    position: absolute;
    left: 249px;
    top: 40px;
    color: #ff4d4f;
    font-size: 12px;
  }
  ::v-deep {
    .loc-wrapper .el-input-number--small {
      width: 100px !important;
    }

    .el-input-number--small .el-input__inner {
      padding: 0 10px !important;
    }
    .el-input-number--small .el-input-number__increase,
    .el-input-number--small .el-input-number__decrease {
      display: none !important;
    }
  }
</style>
