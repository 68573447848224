var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-con"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? '安全验证' : 'Safety Verification')+" ")]),_c('em',{on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.lang === 'zh' ? '< 返回' : '< Back'))])]),_c('el-form',{ref:"form",attrs:{"label-width":"0px","model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"phone"}},[_c('el-input',{attrs:{"type":"input","placeholder":_vm.lang === 'zh'
            ? '请输入手机号'
            : 'Please enter your mobile phone number',"disabled":true,"clearable":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}},[_c('i',{staticClass:"el-icon-phone",attrs:{"slot":"prefix"},slot:"prefix"})])],1),_c('div',{staticClass:"verify"},[_c('el-form-item',{attrs:{"prop":"smsCode","rules":[
          {
            required: true,
            message: _vm.lang === 'zh' ? '请输入' : 'please enter',
            trigger: ['blur', 'change'],
          } ]}},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.lang === 'zh'
              ? '请输入验证码'
              : 'Please enter the verification code',"maxlength":"10","clearable":""},model:{value:(_vm.form.smsCode),callback:function ($$v) {_vm.$set(_vm.form, "smsCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.smsCode"}},[_c('i',{staticClass:"iconfont icon-anquanyanzheng",attrs:{"slot":"prefix"},slot:"prefix"})])],1),_c('el-button',{attrs:{"disabled":_vm.disVerify},on:{"click":_vm.getCode}},[_vm._v(" "+_vm._s(_vm.disVerify ? (_vm.second + "s " + (_vm.lang === 'zh' ? '后可重新获取' : 'regain')) : _vm.lang === 'zh' ? '获取验证码' : 'get code')+" ")])],1)],1),_c('el-button',{staticClass:"login-btn",attrs:{"type":"primary"},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? '确认并登录' : 'Confirm and Sign in')+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }