<template>
  <!-- 打样费设置 -->
  <el-dialog
    :title="$t('other.setProof')"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      :label-position="settings === 'zh' ? 'right' : 'top'"
    >
      <!-- 打样费 -->
      <el-form-item :label="$t('productDetail.ProofingFee')" prop="proofingFee">
        <!-- 请输入 -->
        <el-input
          v-model.trim="form.proofingFee"
          :placeholder="$t('placeholder.plsInput')"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
        />
      </el-form-item>
      <!-- 打样周期 -->
      <el-form-item :label="$t('other.ProofingCycle')" prop="proofingDay">
        <!-- 工作日 -->
        <el-input
          v-model.trim="form.proofingDay"
          :placeholder="$t('other.workingDay')"
          oninput="value=value.replace(/[^0-9]/g,'')"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { deepCopy } from 'kits'
  import { mapGetters } from 'vuex'
  export default {
    name: 'FentModel',
    data() {
      return {
        // title: '打样费设置',
        // title: this.$t('other.setProof'),
        form: {
          id: '',
          proofingFee: null,
          proofingDay: null,
        },
        // rules: {
        //   proofingFee: [
        //     //
        //     { required: true, trigger: 'blur', message: this.$t('rules.M2012') },
        //   ],
        // },
        dialogFormVisible: false,
      }
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      rules() {
        return {
          proofingFee: [
            {
              required: true,
              trigger: 'blur',
              message: this.$t('rules.M2012'),
            },
          ],
        }
      },
    },
    created() {},
    methods: {
      //展示弹框
      fentModel(row) {
        if (!row) {
        } else {
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      // 保存
      save() {
        this.form.proofingFee = Number(this.form.proofingFee)
        this.form.proofingDay = Number(this.form.proofingDay)
        let form = deepCopy(this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.$emit('fent-data', form)
            this.close()
          }
        })
      },
    },
  }
</script>
