import { highlightText, debounce } from 'kits'
import store from '@/store'
import { MailInteractor } from '@/core'
import { sendBoxNames } from './mail-data'

// 单个标签删除时校验
export function checksingleMarkDel(emailAccount, ctx) {
  let leftTableData = ctx.$store.state.mail.leftTableData
  if (emailAccount && leftTableData.length > 0) {
    let currentAccountObj = leftTableData.find(
      (item) => item.emailAccount === emailAccount
    )
    if (currentAccountObj?.authStatus == 2) {
      //失效状态下不允许删除
      ctx.$message.warning(ctx.$t('myMail.invalidAccounts'))
      return false
    }
  }
  return true
}

export function checkDistributeAndClaim(
  { emailAccount, boxName },
  leftTableData
) {
  let isPass = true,
    messageKey = ''

  if (emailAccount && leftTableData.length > 0) {
    let currentAccountObj = leftTableData.find(
      (item) => item.emailAccount === emailAccount
    )
    if (currentAccountObj?.authStatus == 2) {
      // 判断账号是否失效
      messageKey = 'myMail.invalidAccounts'
      isPass = false
    }
    // 判断是否是Gmail
    if (currentAccountObj?.mailServer !== 'Gmail') {
      messageKey = 'myMail.onlyGmail'
      isPass = false
    }
    // 判断文件夹是否满足
    const notAllowedBoxNames = ['已发邮件', '草稿', '已删除邮件', '垃圾邮件']
    if (notAllowedBoxNames.includes(boxName)) {
      messageKey = 'myMail.notallowed'
      isPass = false
    }
  }
  return {
    isPass,
    messageKey,
  }
}

export function checkMarkAs({ emailAccount, boxName }, leftTableData) {
  let isPass = true,
    messageKey = ''

  if (emailAccount && leftTableData.length > 0) {
    let currentAccountObj = leftTableData.find(
      (item) => item.emailAccount === emailAccount
    )
    if (currentAccountObj?.authStatus == 2) {
      // 判断账号是否失效
      messageKey = 'myMail.invalidAccounts'
      isPass = false
    }

    // 判断文件夹是否满足
    const notAllowedBoxNames = [
      'Drafts',
      'Sent Messages',
      'Deleted Messages',
      'Junk',
      '收件箱',
      '草稿',
      '草稿箱',
      '已发送',
      '已发邮件',
      '已发送邮件',
      '已删除',
      '已删除邮件',
      '垃圾箱',
      '垃圾邮件',
      'qf_txl',
    ]
    if (notAllowedBoxNames.includes(boxName)) {
      messageKey = 'myMail.notallowed'
      isPass = false
    }
  }
  return {
    isPass,
    messageKey,
  }
}

// 邮件高亮处理
export function getMailDealField(keyword, mail, utils) {
  let { content, nodeName, title, boxName, receiveEmails } = mail
  if (sendBoxNames.includes(boxName)) {
    // 如果是发件,取发件人
    nodeName = receiveEmails
  }
  if (nodeName) {
    nodeName = nodeName.replace(/"/g, '')
  }
  let terms = keyword?.split(/\s+/) || []
  terms = terms.filter((term) => !!term)
  let dealed = {
    content_deal: '', //内容
    nodeName_deal: '', // 邮件人员
    title_deal: '', // 标题
  }
  if (terms.length === 0) {
    dealed.content_deal = content ? utils.removeTAG(content) : '--'
    dealed.nodeName_deal = nodeName || '邮件人员'
    dealed.title_deal = title ? utils.htmlToSetText(title) : '--'
  } else {
    dealed.content_deal = content
      ? highlightText(utils.removeTAG(content), terms)
      : '--'
    dealed.nodeName_deal = nodeName
      ? highlightText(nodeName, terms)
      : '邮件人员'

    dealed.title_deal = title
      ? highlightText(utils.htmlToSetText(title), terms)
      : '--'
  }

  return dealed
}

// 保存我的邮件页面左侧和右侧宽度
const _savePageWidth = () => {
  let mailPageWidth = store.state.mail.mailPageWidth
  MailInteractor.saveUserTripleConfigApi(mailPageWidth)
}

export const savePageWidth = debounce(_savePageWidth, 3000)

export const getPageWidth = async () => {
  let res = MailInteractor.getUserTripleConfigApi()
  if (res?.code === '000000') {
    if (res?.data?.centerWidth || res?.data?.leftWidth) {
      store.dispatch('mail/setMailListPageWidth', {
        centerWidth: res?.data?.centerWidth || 500,
        leftWidth: res?.data?.leftWidth || 300,
      })
    }
  }
}

// 获取base64地址
export function getUnhandledContentFiles(content, onlyBase64) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(content, 'text/html')
  // 需要处理的图片 非base64和已上传的图片
  const images = doc?.querySelectorAll('img')
  const filteredImages = [...images].filter(
    (img) =>
      !img.src.startsWith('data:image/') &&
      !img.src.startsWith('https://oss.eqstep.com') &&
      !img.src.startsWith('https://weiwo-erp.oss-accelerate.aliyuncs.com')
    )
  console.log([...filteredImages].map(i => i.src))
  // 进一步筛选出以 "http://" 或 "https://" 开头的图片， 对于file协议 blob:applewebdata等类型的数据不需要额外处理
  const imgElements = filteredImages.filter(
    (img) => img.src.startsWith('http://') || img.src.startsWith('https://')
  )
  const removedDuplicatesImgs =[],removedDuplicatesImgSrc = []

  imgElements.forEach((img) => {
    if(!removedDuplicatesImgSrc.includes(img.src)){
      removedDuplicatesImgSrc.push(img.src)
      removedDuplicatesImgs.push(img)
    }
  })
  // base64图片格式处理
  const base64Img =  Array.from(doc.querySelectorAll('img[src^="data:image/"]'))
  const removedDuplicatesBase64Imgs =[],removedDuplicatesBase64ImgSrc = []
  base64Img.forEach((img) => {
    if(!removedDuplicatesBase64ImgSrc.includes(img.src)){
      removedDuplicatesBase64ImgSrc.push(img.src)
      removedDuplicatesBase64Imgs.push(img)
    }
  })

  let arrImgs = []
  if(!onlyBase64){
    arrImgs = [...removedDuplicatesImgs, ...removedDuplicatesBase64Imgs].map((img) => img.src)
  } else {
    arrImgs = [...removedDuplicatesBase64Imgs].map((img) => img.src)
  }
  return arrImgs.map((base64) => {
    return {
      fileName: '',
      attachmentUrl: '',
      mailEnclosureId: '',
      mailId: '',
      id: '',
      attachmentId: '',
      type: 2,
      contentType: null,
      contentImgSrc: base64,
    }
  })
}

export function getMailInfoContent(selector) {
  const iframe = document.querySelector(selector)
  const iframeDoc = iframe?.contentDocument || iframe?.contentWindow?.document
  // 确保iframe已加载完成
  if (iframe && iframeDoc) {
    return iframeDoc.body.innerHTML
  } else {
    console.log('Iframe未加载完成或无法访问')
  }
}
