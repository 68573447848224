<template>
  <div class="index-wrapper index-container">
    <Weclome />
    <KanPan />
    <!-- / style="height: 323px; margin-bottom: 10px; overflow: hidden" -->
    <el-row class="card-wrapper" :gutter="10" style="min-width: 1160px">
      <el-col :span="6">
        <QuickEntrance />
      </el-col>
      <VueDraggable class="wrapper" v-model="list" handle=".handle">
        <transition-group>
          <el-col
            :span="6"
            v-for="(item, index) in list"
            :key="item.showType + index"
          >
            <CustomerCharge v-if="item.showType === '11'" :cardData="item" />
            <MyAudit v-else-if="item.showType === '12'" :cardData="item" />
            <InquiryQuotation
              v-else-if="item.showType === '13'"
              :cardData="item"
            />
            <UnreadMails v-else-if="item.showType === '14'" :cardData="item" />
            <CollectionNotice
              v-else-if="item.showType === '15'"
              :cardData="item"
            />
            <!-- 收款认领-待完善 -->
            <CollectionClaim
              v-else-if="item.showType === '16'"
              :cardData="item"
            />
            <!-- 收款确认 -->
            <CollectionConfirm
              v-else-if="item.showType === '17'"
              :cardData="item"
            />

            <CustomerFollowReminder
              v-else-if="item.showType === '18'"
              :cardData="item"
            />

            <ProcurementDemand
              v-else-if="item.showType === '19'"
              :cardData="item"
            />

            <PaymentOrder v-else-if="item.showType === '20'" :cardData="item" />

            <!-- 出运委托 -->
            <ShipmentEntrust
              v-else-if="item.showType === '21'"
              :cardData="item"
            />

            <!-- 入库申请 -->
            <InboundApply v-else-if="item.showType === '23'" :cardData="item" />

            <!-- 出库申请 -->
            <OutboundApply
              v-else-if="item.showType === '22'"
              :cardData="item"
            />

            <!-- 入库异常 -->
            <InboundUnusual
              v-else-if="item.showType === '24'"
              :cardData="item"
            />

            <InquiryDemand
              v-allowed="['INQUIRY:DEMAND']"
              v-else-if="item.showType === '25'"
              :cardData="item"
            />
            <OrderTracking
              v-else-if="item.showType === '26'"
              :cardData="item"
            />
            <!-- 客评中心 -->
            <CustomerReview
              v-else-if="item.showType === '27'"
              :cardData="item"
            ></CustomerReview>
            <!-- 物流状态通知 -->
            <LogisticsStatusNotification
              v-else-if="item.showType === '28'"
              :cardData="item"
            />
          </el-col>
        </transition-group>
      </VueDraggable>
    </el-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { HomeInteractor } from '@/core'

  import KanPan from './components/data-kanpan.vue' // ok
  import Weclome from './components/welcome-setting.vue' // ok
  import QuickEntrance from './components/quick-entrance.vue' //ok
  import CustomerCharge from './components/customer-charge.vue' //ok
  import MyAudit from './components/my-audit.vue' // ok
  import OrderTracking from './components/order-tracking.vue' // ok
  import InquiryQuotation from './components/inquiry-quotation.vue' // ok
  import UnreadMails from './components/unread-mails.vue' // ok
  import CollectionNotice from './components/collection-notice.vue' // ok
  import CollectionClaim from './components/collection-claim.vue'
  import CollectionConfirm from './components/collection-confirm.vue'
  import CustomerFollowReminder from './components/customer-follow-reminder.vue'
  import ProcurementDemand from './components/procurement-demand.vue'
  import PaymentOrder from './components/payment-order.vue'
  import ShipmentEntrust from './components/shipment-entrust.vue'
  import InboundApply from './components/inbound-apply.vue'
  import OutboundApply from './components/outbound-apply.vue'
  import InboundUnusual from './components/inbound-unusual.vue'
  import InquiryDemand from './components/inquiry-demand.vue'
  import CustomerReview from './components/customer-review.vue'
  import LogisticsStatusNotification from './components/logistics-status-notification'
  import VueDraggable from 'vuedraggable'

  export default {
    name: 'Index',
    data() {
      return {
        singleNum: 0,
        teamNum: 0,
      }
    },

    computed: {
      ...mapGetters({
        homepageConfig: 'kanpan/homepageConfig',
      }),
      todoList() {
        return this.homepageConfig.filter((i) => i.showType === '2')[0]
      },
      unCheckTodoList() {
        return this.todoList.voList.filter((j) => j.divStatus === '0')
      },
      list: {
        get() {
          if (!this.homepageConfig || this.homepageConfig.length === 0) {
            return []
          }
          return this.todoList.voList.filter((j) => j.divStatus === '1')
        },

        set(val) {
          const sortedList = val
            .map((card) => card.configId)
            .concat(this.unCheckTodoList.map((i) => i.configId))
          // 更改vuex数据
          const todoList = val.concat(this.unCheckTodoList)
          let homepageConfig = this.homepageConfig
          let todoVo = homepageConfig.find((item) => item.showType === '2')
          todoVo.voList = todoList
          this.$store.dispatch('kanpan/setHomepageConfig', homepageConfig)
          HomeInteractor.updateDivSort(sortedList)
        },
      },
    },
    components: {
      VueDraggable,
      KanPan, //数据预览
      Weclome, //欢迎
      QuickEntrance, // 快捷入口
      CustomerCharge, //客户分管
      MyAudit, //需我审核
      InquiryQuotation, //询盘报价
      UnreadMails, // 未读邮件
      CollectionNotice, //收款通知
      CollectionClaim, //收款认领
      CollectionConfirm, //收款确认
      CustomerFollowReminder, //客户跟进提醒
      ProcurementDemand, //采购需求
      PaymentOrder, //付款通知
      ShipmentEntrust, //出运委托
      InboundApply, //入库申请
      OutboundApply, //出库申请
      InboundUnusual, //入库异常
      InquiryDemand, //询价需求
      OrderTracking, //订单跟踪
      CustomerReview, //客评中心
      LogisticsStatusNotification, //物流状态通知
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.fetchData()
      })
    },
    async created() {
      HomeInteractor.getHomePageConfig().then((res) => {
        if (res?.code === '000000') {
          this.$store.dispatch('kanpan/setHomepageConfig', res.data)
        }
      })
    },

    methods: {
      async fetchData() {
        const userInfoStr = localStorage.getItem('setUserInfo')
        this.userInfo = userInfoStr ? JSON.parse(userInfoStr) : {}
        const { userId, tenantId } = this.userInfo
        const requestParams = {
          sourceSys: 'erp',
          tenantId,
          userId,
          readFlag: 1,
          messageType: 1,
          sysMessageType: '',
          startTime: '',
          endTime: '',
          pageNum: 1,
          pageSize: 10,
        }

        const res = await HomeInteractor.getUserMessagePage(requestParams)
        if (res?.code === '000000') {
          this.$store.commit('kanpan/setUnreadMessage', res?.data?.total > 0)
        }
      },
    },
    watch: {},
  }
</script>

<style lang="scss" scoped>
  .index-wrapper {
    overflow-x: auto;
  }
  .index-container.index-wrapper {
    height: 100%;
    overflow: auto !important;
    background-color: #f5f7fa !important;
    padding: 11px !important;
  }
  .card-wrapper {
    margin-top: 10px;
  }
</style>
