import QRCode from 'qrcodejs2'

/**
 * @param text 信息文本
 * @param width
 */

const defaultConfig = {
  selector: '#qrcode', //默认选择器
  width: 128, //默认宽度
  height: 128, //默认高度
  colorDark: '#000000', // 默认颜色
  colorLight: '#ffffff', // 默认颜色
  correctLevel: QRCode.CorrectLevel.H, //级别
}

/**
 * @description 生成单个二维码
 * @param {*} options 参考defaultConfig
 * @returns
 */
export function generateQRCode(options = {}) {
  options = {
    ...defaultConfig,
    ...options,
  }
  const { selector, text, colorDark, colorLight, size = 120 } = options
  const el = document.querySelector(selector)
  if (el) {
    return new QRCode(el, {
      text,
      width: size,
      height: size,
      colorDark,
      colorLight,
      correctLevel: QRCode.CorrectLevel.H,
    })
  }
}

// 示例
// generateQRCode({
//   text: '111',
// })

/**
 * @description 批量生成二维码
 * @param {*} optionsArray 单个option组成的数组
 */
export function batchGenerateQRCode(optionsArray) {
  optionsArray.forEach((option) => {
    generateQRCode(option)
  })
}

// 批量示例
// batchGenerateQRCode([
//   {
//     text: '111',
//   },
//   {
//     selector: '#qrcode2',
//     text: '222',
//   },
// ])
