<template>
  <!-- 批量导入 -->
  <el-dialog
    :title="$t('inquiryList.BatchImport')"
    class="import-dialog"
    :visible.sync="dialogVisible"
    width="520px"
  >
    <div class="import-inner">
      <dl class="row">
        <dt>1、</dt>
        <dd>
          <el-link type="primary" href="/order/enquiry.xlsx" download>
            <!-- 点击下载批量导入模板 -->
            {{ $t('inquiryList.DownloadTemplate') }}
          </el-link>
          <p class="desc">
            <!-- 下载Excel模板，按照表格内提示填写信息 -->
            {{ $t('inquiryList.DownloadTemplateTips') }}
          </p>
        </dd>
      </dl>
      <dl class="row">
        <dt>2、</dt>
        <dd>
          <el-upload
            action="/order/orderEnquiry/enquiryImport"
            class="upload-btn"
            :http-request="() => {}"
            :show-file-list="false"
            :before-upload="beforeUpload"
          >
            <el-button type="primary" size="medium">
              <!-- 选择xls/xlsx文件 -->
              {{ $t('inquiryList.SelectFile') }}
            </el-button>
          </el-upload>
          <div class="wait-upload" v-if="fileName">
            <span class="label">
              <!-- 待上传文件: -->
              {{ $t('inquiryList.FileToUpload') }}
            </span>
            <span class="name">{{ fileName }}</span>
            <i class="el-icon-close icon-close" @click="remove"></i>
          </div>
          <p class="desc">
            <!-- 按模板格式填写完信息后点击此处上传xls或xlsx文件 -->
            {{ $t('inquiryList.SelectFileTips') }}
          </p>
        </dd>
      </dl>
      <div class="tips">
        {{ $t('inquiryList.BatchImportTips') }}
        <!-- 注意：
        <br />
        文件后缀名必须为：xls或xlsx（即Excel格式），文件大小不得大于10M
        <br />
        必填项不允许为空，一旦为空将会导致导入失败
        <br />
        其他信息若不填写请复制模板第一行内的默认数据
        <br />
        模板中的表头名称不可更改，表头行不能删除
        <br /> -->
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <!-- 立即导入 -->
      <el-button type="primary" @click="upload" :loading="loading">
        {{ $t('inquiryList.BatchImport') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="dialogVisible = false">
        {{ $t('inquiryList.Cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { enquiryImport } from '@/api/order.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialogVisible: false,
      file: null,
      fileName: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.file = null
        this.fileName = ''
      }
    },
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    beforeUpload(file) {
      let suffix = file.name.substr(file.name.lastIndexOf('.'))
      const isExcel = ['.xls', '.xlsx'].includes(suffix)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isExcel) {
        const msg =
          this.lang === 'en'
            ? 'The file can be uploaded only in XLS or XLSX format'
            : '上传文件只支持xls或xlsx格式'
        this.$message.error(msg)
      }
      if (!isLt10M) {
        const msg =
          this.lang === 'en'
            ? 'The size of the uploaded file cannot exceed 10MB'
            : '上传文件大小不能超过 10MB'
        this.$message.error(msg)
      }
      if (isExcel && isLt10M) {
        this.file = file
        this.fileName = file.name
      }
      return isExcel && isLt10M
    },
    remove() {
      this.fileName = ''
      this.file = null
    },
    upload() {
      // 参数
      if (this.file) {
        let formData = new FormData()
        formData.append('file', this.file)
        this.loading = true
        enquiryImport(formData)
          .then((res) => {
            if (res && res.code == '000000') {
              this.$message.success(this.$t('reqmsg.G007'))
              this.$emit('success')
              this.dialogVisible = false
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$message.warning(this.$t('reqmsg.G006'))
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.import-dialog {
  .row {
    display: flex;
    margin-bottom: 20px;
    dt {
      font-size: 20px;
      color: #666;
    }
    dd {
      flex: 1;
    }
    .el-link {
      font-size: 16px;
      line-height: 32px;
    }
    .wait-upload {
      display: flex;
      max-width: 100%;
      align-items: center;
      margin-top: 10px;
      .label {
        margin-right: 10px;
      }
      .name {
        font-size: 14px;
        color: #333;
      }
      .icon-close {
        color: #ccc;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    .desc {
      font-size: 14px;
      color: #999;
      line-height: 24px;
      margin-top: 4px;
    }
  }
  .tips {
    font-size: 14px;
    color: #999;
    line-height: 24px;
  }
  .dialog-footer {
    text-align: center;
  }
}
::v-deep {
  .el-message {
    max-height: 600px;
    overflow-y: auto;
  }
}
</style>