<template>
  <div class="public-container">
    <el-row type="flex" justify="space-between">
      <el-page-header @back="goBack" v-if="!$route.query.noReturn">
        <template slot="content">
          <el-row type="flex" align="middle">
            <p>
              <label class="f_s_14">供应商编码：</label>
              <span class="f_s_16">{{ detailData.supplierCode }}</span>
            </p>
          </el-row>
        </template>
      </el-page-header>
      <el-row v-if="detailData.status !== 7" class="mr30">
        <el-button
          v-if="detailData.status != 1"
          type="primary"
          plain
          @click="editDetail('edit', supplierId)"
        >
          编辑
        </el-button>
        <!-- v-allowed="['Supplier:Add:Black']" -->
        <el-button
          v-allowed="['Supplier:Add:Black']"
          type="danger"
          plain
          @click="addBlacklist(detailData.supplierId)"
        >
          加入黑名单
        </el-button>
      </el-row>
    </el-row>
    <el-row class="mt10" style="position: relative; padding: 0 69px">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="供应商信息" name="first">
          <SupplierManageDetailMsg
            ref="SupplierManageDetailMsg"
            :dataPass="detailData"
          />
        </el-tab-pane>
        <el-tab-pane label="主营产品" name="second">
          <SupplierManageDetailProduct
            ref="SupplierManageDetailProduct"
            :data="detailData"
            @refreshDetail="refreshDetail"
          />
        </el-tab-pane>
        <el-tab-pane
          label="订单信息"
          name="third"
          v-if="permissionsTab('Order:List')"
        >
          <SupplierManageDetailOrder
            ref="SupplierManageDetailOrder "
            :data="detailData"
            :supplier="supplierId"
          />
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
  import SupplierManageDetailMsg from './components/supplier-manage-detail-msg'
  import SupplierManageDetailProduct from './components/supplier-manage-detail-product'
  import SupplierManageDetailOrder from './components/supplier-manage-detail-order'
  import { mapGetters } from 'vuex'
  import { SupplierManageInteractor, InboundSheet } from '@/core'

  export default {
    name: 'SupplierManageDetail',
    components: {
      SupplierManageDetailMsg,
      SupplierManageDetailProduct,
      SupplierManageDetailOrder,
    },
    data() {
      return {
        activeName: 'first',
        supplierId: '', // 当前供应商id
        detailData: {},
        dialogBlackVisible: false, // 黑名单弹窗
        blackForm: {
          blacklistReasons: '', // 作废理由
        },
        blackRules: {
          blacklistReasons: [
            { required: true, message: '请输入理由', trigger: 'blur' },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        permissions: 'user/permissions',
        userInfo: 'user/userInfo',
      }),
    },
    created() {
      this.supplierId = this.$route.query.supplierId
      this.getSupplierDetail(this.$route.query.supplierId)
    },
    mounted() {},
    methods: {
      // tabs权限控制
      permissionsTab(tab) {
        return this.permissions.some((item) => item == tab)
      },
      // 处理点击编辑
      editDetail(str, supplierId) {
        this.$router.push({
          path: '/client-supplier/supplier-manage-add',
          query: { str: str, supplierId: supplierId },
        })
      },
      // 获取详情数据
      async getSupplierDetail() {
        let response = await SupplierManageInteractor.supplierDetailNew({
          id: this.supplierId,
        })
        if(response?.code === '000000') {
          this.detailData = response.data
        }

      },

      //加入黑名单
      addBlacklist(supplierIds) {
        this.$prompt('是否确定将该供应商加入黑名单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          remarks: '请输入原因',
          inputErrorMessage: '请输入原因',
          inputValidator: (value) => {
            if (!value) {
              return '原因不能为空'
            }
          },
        }).then(async ({ value }) => {
          let params = {
            supplierIds: new Array(supplierIds),
            blacklistReasons: value,
          }
          let response = await SupplierManageInteractor.supplierAddBlacklist(
            params
          )
          if (response.code === '000000') {
            this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
            this.getSupplierDetail()
          }
        })
      },
      handleClick() {},
      goBack() {
        this.$router.push('/client-supplier/supplier-manage/index')
      },
      refreshDetail() {
        this.getSupplierDetail()
      },
    },
  }
</script>

<style scoped lang="scss">
  .public-container {
    overflow-y: auto;
    margin-bottom: 100px;
  }
  .right-fixed {
    position: absolute;
    top: 0;
    right: 69px;
  }
  ::v-deep {
    .el-tabs__header {
      margin: 0;
    }
  }
</style>
