<template>
  <div class="login-con">
    <div class="title">
      {{ lang === 'zh' ? '修改密码' : 'Update Your Login Password' }}
    </div>

    <div class="tips">
      <i class="el-icon-warning"></i>
      <div>
        <p class="top bold">
          {{
            lang === 'zh'
              ? '为了您的账号安全，请您及时修改登录密码'
              : 'For the security of your account, please promptly update your login password'
          }}
        </p>
        <p>
          {{
            lang === 'zh'
              ? '8~20位数字、大/小写字母、符号，密码强度需达到「强」'
              : 'Password should be 8-20 characters long, including a combination of numbers, letters, symbols until the password has a strength rating of "Strong".'
          }}
        </p>
      </div>
    </div>

    <el-form label-width="0px" :model="form" :rules="rules" ref="form">
      <el-form-item
        prop="password"
        :rules="[
          {
            required: true,
            message: this.$t('rules.$10'),
            min: 8,
            max: 20,
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <!-- 请输入密码 -->
        <el-input
          type="password"
          v-model.trim="form.password"
          :placeholder="
            lang === 'zh' ? '请输入新密码' : 'Please input a new password'
          "
          size="small"
          clearable
          tabindex="2"
          maxlength="20"
          show-password
          onkeyup="this.value = this.value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,'')"
        >
          <i slot="prefix" class="iconfont el-icon-s-goods"></i>
        </el-input>
      </el-form-item>

      <!-- 校验密码强度 -->
      <password-check
        :lang="lang"
        :password="form.password"
        @isPass="isPass"
      ></password-check>

      <el-form-item
        prop="confirmPassword"
        :rules="[
          {
            required: true,
            message: this.$t('rules.$10'),
            min: 8,
            max: 20,
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <!-- 再次输入密码 -->
        <el-input
          type="password"
          v-model.trim="form.confirmPassword"
          :placeholder="
            lang === 'zh'
              ? '请再次输入新密码'
              : 'Please enter a new password again'
          "
          size="small"
          clearable
          tabindex="2"
          maxlength="20"
          show-password
          onkeyup="this.value = this.value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,'')"
        >
          <i slot="prefix" class="iconfont el-icon-s-goods"></i>
        </el-input>
      </el-form-item>

      <p class="text-tips" v-if="isSame">
        {{
          lang === 'zh'
            ? '两次输入密码不一致'
            : 'The two passwords are inconsistent'
        }}
      </p>
    </el-form>

    <!-- 登 录 -->
    <el-button class="login-btn" @click="login" type="primary">
      {{ lang === 'zh' ? '确认并登录' : 'Confirm and Sign in' }}
    </el-button>
  </div>
</template>

<script>
  import passwordCheck from '@/components/password-check/index'
  export default {
    components: { passwordCheck },
    props: {
      lang: {
        type: String,
        default: 'zh',
      },
    },
    data() {
      return {
        form: {
          password: '',
          confirmPassword: '',
        },
        rules: {},
        isSame: false, // 密码是否一致
        disabled: false, // 检验密码强度
        dialogVisible: false,
      }
    },
    watch: {
      'form.password'(val) {
        const confirmPassword = this.form.confirmPassword
        if (confirmPassword && val && confirmPassword != val) {
          this.isSame = true
        } else {
          this.isSame = false
        }
      },
      'form.confirmPassword'(val) {
        const password = this.form.password
        if (password && val && password != val) {
          this.isSame = true
        } else {
          this.isSame = false
        }
      },
    },
    methods: {
      // 初始化
      init() {
        this.dialogVisible = true
      },

      // 密码是否通过
      isPass(bool) {
        this.disabled = bool
      },

      // 保存
      login() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (this.isSame) return false
            if (!this.disabled) {
              return this.$message.warning(
                this.lang === 'zh'
                  ? '密码强度不满足'
                  : 'Password strength is not satisfied'
              )
            }

            this.$emit('handleLogin', this.form)
          }
        })
      },

      // 关闭
      handleClose() {
        this.$refs['form'].resetFields()
        this.dialogVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  $bold: 'PingFang Bold';
  .login-content {
    width: 960px;
    height: 464px;
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 2px 11px 6px rgba(234, 234, 234, 0.5);
    border-radius: 4px;
    overflow: hidden;
    .tips {
      display: flex;
      background: #fdf6ec;
      border-radius: 4px;
      color: #e6a341;
      padding: 12px 10px;
      margin-bottom: 10px;
      i {
        font-size: 24px;
        display: flex;
        margin-right: 8px;
      }
      .top {
        font-size: 14px;
        margin-bottom: 5px;
      }
      p {
        font-size: 12px;
      }
    }
    .title {
      font-size: 21px;
      margin-bottom: 10px;
      font-family: $bold;
      color: #282c34;
      padding-bottom: 13px;
      border-bottom: 1px solid #282c34;
    }
    .login-btn {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      font-size: 14px;
      font-family: $bold;
      color: #ffffff;
      border: 0;
      margin-top: 5px;
    }
    .text-tips {
      color: #ff4d4f;
      margin: -5px 0 10px;
      font-family: $bold;
    }
  }
</style>
