<template>
  <div class="reject">
    <el-dialog
      title="驳回"
      :visible.sync="dialogFormVisible"
      width="400px"
      @close="close"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="驳回原因" label-width="25%" prop="rejectReason">
          <el-input
            style="width: 100%"
            v-model="form.rejectReason"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { reject } from '@/api/finance/finance-payment'
  export default {
    data() {
      return {
        amendRow: '',
        form: {
          rejectReason: '',
        },
        dialogFormVisible: false,
        rules: {
          rejectReason: [
            { required: true, message: '不能为空', trigger: 'blur' },
          ],
        },
      }
    },
    methods: {
      showReceiptDetail() {
        this.dialogFormVisible = true
      },
      close() {
        this.dialogFormVisible = false
        this.$refs.form.resetFields()
      },
      save() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            let response = await reject({
              payOrderId: this.amendRow,
              ...this.form,
            })
            if (response.code == '000000') {
              this.$message.success('驳回成功')
              this.close()
              this.$emit('getList')
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      padding-top: 10px;
    }
    .el-dialog__header {
      > .el-dialog__title {
        font-size: 14px;
      }
    }
    .el-dialog__footer {
      padding-top: 0;
      text-align: center;
    }
  }
</style>
