/**
 * 订单设置模块接口
 */
import request from '@/core/services/request'

/**
 * 批量更新排序
 */
export function batchUpdateSort(data) {
  return request({
    url: '/product/customExpenseSetting/batchUpdateSort',
    method: 'post',
    data,
  })
}

/**
 * 查询所有自定义费用
 */
export function listAll(data) {
  return request({
    url: '/product/customExpenseSetting/listAll',
    method: 'post',
    data,
  })
}

/**
 * 新增自定义费用
 */
export function savePO(data) {
  return request({
    url: '/product/customExpenseSetting/savePO',
    method: 'post',
    data,
  })
}

/**
 * 更新自定义费用
 */
export function customUpdatePO(data) {
  return request({
    url: '/product/customExpenseSetting/updatePO',
    method: 'post',
    data,
  })
}

/**
 * 删除自定义费用
 */
export function deletePO(customExpenseSettingId) {
  return request({
    url: '/product/customExpenseSetting/deletePO',
    method: 'post',
    params: {
      customExpenseSettingId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 查询运费报价设置
 */
export function queryOne() {
  return request({
    url: '/product/freightQuotationSetting/queryOne',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 保存运费报价设置
 */
export function freightUpdatePO(data) {
  return request({
    url: '/product/freightQuotationSetting/updatePO',
    method: 'post',
    data,
  })
}

/**
 * 运费配置-根据运输类型查询未停用的运费配置
 */
export function selectByTransportType(data) {
  return request({
    url: '/product/freightSetting/selectByTransportType',
    method: 'post',
    data,
  })
}

/**
 * 查询报价限制
 */
export function priceQueryOne(data) {
  return request({
    url: '/product/priceSheetSetting/queryOne',
    method: 'post',
    data,
  })
}

/**
 * 保存报价限制
 */
export function priceUpdatePO(data) {
  return request({
    url: '/product/priceSheetSetting/updatePO',
    method: 'post',
    data,
  })
}
