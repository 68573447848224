<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="名称" prop="sourceName">
          <el-input
            v-model.trim="form.sourceName"
            placeholder="请输入名称"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item label="英文名称" prop="sourceNameEn">
          <el-input
            v-model.trim="form.sourceNameEn"
            placeholder="请输入名称"
            maxlength="50"
          />
        </el-form-item>
        <!-- <el-form-item label="排序" prop="paixxu">
          <el-input v-model.trim="form.paixxu" placeholder="排序/数值" />
        </el-form-item> -->
      </el-form>
    </div>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    clientSourceUpdate,
    clientSourceAdd,
  } from '@/api/client-supplier/client-setting.js'

  export default {
    name: 'ClientSettingPublicModel',
    data() {
      return {
        loading: false,
        title: '客户来源',
        form: {
          sourceName: '',
          sourceId: '',
          sourceNameEn: '',
        },
        rules: {
          sourceName: [
            { required: true, message: '请输入名称', triggle: 'blur' },
          ],
          sourceNameEn: [
            { required: true, message: '请输入英文名称', triggle: 'blur' },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit(row) {
        if (!row) {
          this.title = '新增客户来源'
        } else {
          this.title = '编辑客户来源'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let Method
            if (this.form.sourceId) {
              Method = clientSourceUpdate
            } else {
              Method = clientSourceAdd
            }
            this.loading = true
            Method(this.form).then((res) => {
              this.loading = false
              if (res.code === '000000') {
                this.$message.success(
                  this.form.sourceId ? '修改成功！' : '新增成功！'
                )
                this.$emit('success')
                this.close()
              }
            })
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
