<!--
  功能：财务中心配置管理
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月19日 09:42:01
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="pro-container">
    <el-tabs
      tab-position="left"
      style="height: 100%"
      @tab-click="changeTab"
      v-model="activeTab"
    >
      <el-tab-pane
        v-for="item in configs"
        :key="item.key"
        :label="item.label"
        :name="item.name"
      >
        <component :is="item.comp" :ref="`configItem${item.name}`" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import ConditionConfig from './components/condition-config'
  import OperatingCostConfig from './components/operating-cost-config'
  import SettlementPersonnelConfig from './components/settlement-personnel-config'
  import SettlementTplConfigInteractor from './components/settlement-tpl-config'
  import ThemeConfig from './components/theme-config'

  export default {
    components: {
      ConditionConfig,
      OperatingCostConfig,
      SettlementPersonnelConfig,
      SettlementTplConfigInteractor,
      ThemeConfig,
    },

    data() {
      return {
        activeTab: '1',
        configs: [
          {
            label: '运营成本配置',
            name: '1',
            comp: OperatingCostConfig,
          },
          {
            label: '结算人员配置',
            name: '2',
            comp: SettlementPersonnelConfig,
          },
          {
            label: '结算模板配置',
            name: '3',
            comp: SettlementTplConfigInteractor,
          },
          {
            label: '主管配置',
            name: '4',
            comp: ThemeConfig,
          },
          {
            label: '条件配置',
            name: '5',
            comp: ConditionConfig,
          },
        ],
      }
    },
    methods: {
      changeTab({ name }) {
        const comp = this.$refs['configItem' + name][0]
        if (comp && typeof comp.init === 'function') {
          comp.init()
        }
      },

      initTab() {
        const { tab } = this.$route.query
        if (tab) {
          this.activeTab = tab
        }
      },
    },

    created() {
      this.initTab()
    },
  }
</script>

<style lang="scss" scoped>
  .pro-container {
    ::v-deep {
      .el-tabs__item {
        height: 60px !important;
        line-height: 60px !important;
        padding: 0 40px;
      }
      .is-active {
        background-color: #ebf5ff;
      }
    }
  }
</style>
