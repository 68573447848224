<template> 
  <div class="detail-msg" v-if="showPage" style="margin-top: -12px;">
    <!-- 基本信息 -->
    <el-row>
      <h4 class="f_s_15">基本信息</h4>
      <el-row :gutter="10" class="mt15 base-box">
        <el-col :span="6">
          <el-row type="flex" align="middle">
            <label>供应商标识：</label>
            <el-row type="flex" align="middle">
              <el-tag
                type="primary"
                class="ml5"
                v-if="dataPass.supplierIdentification === '0'"
              >
                线下
              </el-tag>
              <el-tag
                type="success"
                class="ml5"
                v-if="dataPass.supplierIdentification === '1'"
              >
                网拍
              </el-tag>
              <el-row
                v-if="
                  dataPass.supplierIdentification === '0,1' ||
                  dataPass.supplierIdentification === '1,0'
                "
              >
                <el-tag type="primary" size="mini" class="ml5">线下</el-tag>
                <el-tag type="success" size="mini" class="ml5">网拍</el-tag>
              </el-row>
              <el-tag
                class="ml5"
                type="danger"
                size="mini"
                v-if="dataPass.isAgreement === 1"
              >
                协议
              </el-tag>
            </el-row>
          </el-row>
        </el-col>
        <el-col :span="6">
          <label>供应商名称：</label>
          <span class="col-span">{{ dataPass.supplierName || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>地址：</label>
          <span class="col-span">{{ dataPass.address || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>纳税人资质：</label>
          <span class="col-span">{{ dataPass.ratepayerAptitudeType  | taxpayerQualifications}}</span>
        </el-col> 
        <el-col :span="6">
          <el-row type="flex" align="middle">
            <label>供应商等级：</label>
            <el-rate
              v-model="dataPass.supplierGrade"
              disabled
              text-color="#ff9900"
            ></el-rate>
          </el-row>
        </el-col>
        <el-col :span="6">
          <label>供应商类型：</label>
          <span class="col-span">{{ dataPass.supplierTypeName || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>供应商性质：</label>
          <span class="col-span">{{ dataPass.supplierNatureName || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>所属行业：</label>
          <span class="col-span">{{ dataPass.industry || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>网址：</label>
          <span class="col-span">{{ dataPass.website || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>邮编：</label>
          <span class="col-span">{{ dataPass.zipCode || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>成立年份：</label>
          <span class="col-span">{{ dataPass.establishmentDate || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>创建人：</label>
          <span class="col-span">{{ dataPass.creatorName || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>创建时间：</label>
          <span class="col-span">{{ dataPass.createTime || '--' }}</span>
        </el-col>
      </el-row>
    </el-row>
    <!-- 联系人信息 -->
    <el-row class="mt30" v-if="hasBeforeSupplierVOBol && !linkmanVOSBol">
      <h4 class="f_s_15">联系人信息——修改前</h4>
      <el-table class="mt20" border :data="beforeSupplierVO.linkmanVOS && beforeSupplierVO.linkmanVOS.length ?dataPass.linkmanVOS:[]">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column prop="lianxiren" align="center" label="姓名">
          <template slot-scope="scope">{{ scope.row.oldlinkman }}</template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="联系电话">
          <template slot-scope="scope">{{ scope.row.oldphone }}</template>
        </el-table-column>
        <el-table-column prop="email" align="center" label="电子邮箱">
          <template slot-scope="scope">
            <span
              v-if="scope.row.oldemail"
              class="c_pointer prod-code"
              @click="emailClick(scope.row.oldemail)"
            >
              {{ scope.row.oldemail }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="WhatsApp" align="center" label="部门">
          <template slot-scope="scope">
            {{ scope.row.olddepartment || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="Skype" align="center" label="职务">
          <template slot-scope="scope">
            {{ scope.row.oldduties || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="WeChat" align="center" label="备注">
          <template slot-scope="scope">
            {{ scope.row.oldremarks || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="zhuyao" align="center" label="是否主要">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.oldmajor === 1 ? true : false"
              disabled
            />
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="mt30">
      <h4 class="f_s_15">{{ hasBeforeSupplierVOBol && !linkmanVOSBol ?'联系人信息——修改后':'联系人信息'}}</h4>
      <el-table class="mt20" border :data="dataPass.linkmanVOS">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column prop="lianxiren" align="center" label="姓名">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.linkman !== scope.row.oldlinkman && hasBeforeSupplierVOBol && !linkmanVOSBol,
              }"
            >
              {{ scope.row.linkman }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="联系电话">
          <template slot-scope="scope">
            <span
              :class="{ 'change-text': scope.row.phone !== scope.row.oldphone && hasBeforeSupplierVOBol && !linkmanVOSBol}"
            >
              {{ scope.row.phone }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="email" align="center" label="电子邮箱">
          <template slot-scope="scope">
            <span
              v-if="scope.row.email"
              class="c_pointer prod-code"
              :class="{ 'change-text': scope.row.email !== scope.row.oldemail && hasBeforeSupplierVOBol && !linkmanVOSBol}"
              @click="emailClick(scope.row.email)"
            >
              {{ scope.row.email }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="WhatsApp" align="center" label="部门">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.department !== scope.row.olddepartment && hasBeforeSupplierVOBol && !linkmanVOSBol,
              }"
            >
              {{ scope.row.department || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="Skype" align="center" label="职务">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.duties !== scope.row.oldduties && hasBeforeSupplierVOBol && !linkmanVOSBol,
              }"
            >
              {{ scope.row.duties || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="WeChat" align="center" label="备注">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.remarks !== scope.row.oldremarks && hasBeforeSupplierVOBol && !linkmanVOSBol, 
              }"
            >
              {{ scope.row.remarks || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="zhuyao" align="center" label="是否主要">
          <template slot-scope="scope">
            <el-switch :value="scope.row.major === 1 ? true : false" disabled />
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 协议信息修改前 -->
    <el-row class="mt30" v-if="hasBeforeSupplierVOBol  && !supplierBol">
      <h4 class="f_s_15">协议信息修改前</h4>
      <el-table class="mt20 w90" border :data=" beforeSupplierVO.isAgreement === 1 ?oldsupplierData:[]">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column align="center" label="协议名称">
          <template slot-scope="scope">{{ beforeSupplierVO.agreementName }}</template>
        </el-table-column>
        <el-table-column align="center" label="协议文件" width="440">
          <template slot-scope="scope">
            <div v-show="oldfileList2.length">
              <div class="flie-list" v-for="(item, i) in oldfileList2" :key="i">
                <i class="el-icon-document"></i>
                <a class="page-link ml5">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="top-start"
                  >
                    <span class="ell">
                      <router-link
                        :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                        target="_blank"
                      >
                        {{ item.name }}
                      </router-link>
                    </span>
                  </el-tooltip>
                </a>
                <span class="size ml10">
                  {{ utils.calculateFileSize(item.size) }}
                </span>
                <span class="ml10">{{ item.time }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="协议有效期" width="240">
          <template slot-scope="scope">
            {{
              beforeSupplierVO.agreementStartDate + ' -- ' + beforeSupplierVO.agreementEndDate
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" width="240">
          <template slot-scope="scope">
            {{ beforeSupplierVO.agreementMemo || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="zhuangtai"
          align="center"
          label="状态"
          width="140"
        >
          <template slot-scope="scope">
            {{ beforeSupplierVO.agreementStatus || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
     <!-- 协议信息修改后 -->
     <el-row class="mt30" v-if="dataPass.isAgreement === 1">
      <h4 class="f_s_15">{{hasBeforeSupplierVOBol && !supplierBol?'协议信息修改后':'协议信息'}}</h4>
      <el-table class="mt20 w90" border :data="supplierData">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column align="center" label="协议名称">
          <template slot-scope="scope">
            <span :class="{
                'change-text': dataPass.agreementName !== beforeSupplierVO.agreementName && hasBeforeSupplierVOBol && !supplierBol,
              }">
              {{ dataPass.agreementName }}
            </span>
            </template>
        </el-table-column>
        <el-table-column align="center" label="协议文件" width="440">
          <template slot-scope="scope">
            <div v-show="fileList2.length">
              <div class="flie-list" v-for="(item, i) in fileList2" :key="i">
                <i class="el-icon-document"></i>
                <a class="page-link ml5">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="top-start"
                  >
                    <span class="ell">
                      <router-link
                        :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                        target="_blank"
                      >
                        {{ item.name }}
                      </router-link>
                    </span>
                  </el-tooltip>
                </a>
                <span class="size ml10">
                  {{ utils.calculateFileSize(item.size) }}
                </span>
                <span class="ml10">{{ item.time }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="协议有效期" width="240">
          <template slot-scope="scope">
            <span :class="{
                'change-text': ((dataPass.agreementStartDate !== beforeSupplierVO.agreementStartDate && hasBeforeSupplierVOBol && !supplierBol)||(dataPass.agreementEndDate !== beforeSupplierVO.agreementEndDate && hasBeforeSupplierVOBol&& !supplierBol)),
              }">
             {{
              dataPass.agreementStartDate + ' -- ' + dataPass.agreementEndDate
            }}
            </span>
            
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" width="240">
          <template slot-scope="scope">
            <span :class="{
                'change-text': dataPass.agreementMemo !== beforeSupplierVO.agreementMemo && hasBeforeSupplierVOBol&& !supplierBol,
              }">
                {{ dataPass.agreementMemo || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="zhuangtai"
          align="center"
          label="状态"
          width="140"
        >
          <template slot-scope="scope">
            {{ dataPass.agreementStatus || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 付款方式 -->
    <el-row class="mt30" v-if="hasBeforeSupplierVOBol && !payVOSBol">
      <h4 class="f_s_15">付款方式——修改前</h4>
      <div class="c-table-wrap">
        <table class="custom-table mt10 w90" border bordercolor="#ebeef5">
          <thead>
            <tr>
              <th width="60">序号</th>
              <th width="150">金额区间</th>
              <th>付款方式</th>
              <th width="150">占比(%)</th>
              <th width="160">付款环节</th>
              <th width="160">优先级</th>
              <th width="160">备注</th>
            </tr>
          </thead>
          <template v-if="dataPass.oldpayVOS && dataPass.oldpayVOS.length">
            <tbody v-for="(item, index) in dataPass.oldpayVOS" :key="index">
            <tr>
              <td rowspan="4">{{ index + 1 }}</td>
              <td rowspan="4">{{ item.intervalName }}</td>
              <td rowspan="4">{{ item.paymentMethodName || '--' }}</td>
              <td>{{ item.deposit || '--' }}</td>
              <td>
                <span>定金</span>
              </td>
              <td rowspan="4">{{ item.priority }}</td>
              <td rowspan="4">{{ item.remark }}</td>
            </tr>
            <tr v-if="item.beforeShipping">
              <td>{{ item.beforeShipping || '-' }}</td>
              <td>
                <span>发货前</span>
              </td>
            </tr>
            <tr v-if="item.uponDelivery">
              <td>{{ item.uponDelivery || '--' }}</td>
              <td>
                <span>发货后</span>
                <span v-if="item.uponDeliveryAfter">
                  {{ item.uponDeliveryAfter }}天
                </span>
              </td>
            </tr>
            <tr v-if="item.finalPayment">
              <td>{{ item.finalPayment || '--' }}</td>
              <td>
                <span>收货后</span>
                <span v-if="item.finalPaymentAfter">
                  {{ item.finalPaymentAfter }}天
                </span>
              </td>
            </tr>
          </tbody>
          </template>
          <template v-else>
            <tbody>
              <tr>
                <td colspan="6" style="font-size:12px;color: #999;">暂无数据</td>
              </tr>
            </tbody>
          </template>
         
        </table>
      </div>
    </el-row>
    <el-row class="mt30">
      <h4 class="f_s_15">{{ hasBeforeSupplierVOBol && !payVOSBol ?'付款方式——修改后':'付款方式'}}</h4>
      <div class="c-table-wrap">
        <table class="custom-table mt10 w90" border bordercolor="#ebeef5">
          <thead>
            <tr>
              <th width="60">序号</th>
              <th width="150">金额区间</th>
              <th>付款方式</th>
              <th width="150">占比(%)</th>
              <th width="160">付款环节</th>
              <th width="160">优先级</th>
              <th width="160">备注</th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in dataPass.payVOS" :key="index">
            <tr>
              <td rowspan="4">{{ index + 1 }}</td>
              <td rowspan="4">
                <span
                  :class="{
                    'change-text': item.intervalName !== item.oldintervalName && hasBeforeSupplierVOBol && !payVOSBol,
                  }"
                >
                  {{ item.intervalName }}
                </span>
              </td>
              <td rowspan="4">
                <span
                  :class="{
                    'change-text':  item.paymentMethodName !==  item.oldpaymentMethodName && hasBeforeSupplierVOBol && !payVOSBol, 
                  }"
                >
                {{ item.paymentMethodName || '--' }}
                </span>
              </td>
              <td>
                <span
                  :class="{
                    'change-text':  item.deposit !==  item.olddeposit && hasBeforeSupplierVOBol && !payVOSBol,
                  }"
                >
                {{ item.deposit || '--' }}
                </span>
              
              </td>
              <td :class="{
                    'change-text':  item.deposit !==  item.olddeposit && hasBeforeSupplierVOBol && !payVOSBol,
                  }">
                <span>定金</span>
              </td> 
              <td rowspan="4">
                <span
                  :class="{
                    'change-text':  item.priority !==  item.oldpriority && hasBeforeSupplierVOBol && !payVOSBol,
                  }"
                >
                {{ item.priority}}
                </span>
              </td>
              <td rowspan="4">
                <span
                  :class="{
                    'change-text':  item.remark !==  item.oldremark && hasBeforeSupplierVOBol && !payVOSBol,
                  }"
                >
                {{ item.remark}}
                </span>
              </td>
            </tr>
            <tr v-if="item.beforeShipping" :class="{
                    'change-text':  item.beforeShipping !==  item.oldbeforeShipping && hasBeforeSupplierVOBol && !payVOSBol,
                  }">
              <td>
                {{ item.beforeShipping || '-' }}
              </td>
              <td>
                <span>发货前</span>
              </td>
            </tr>
            <tr v-if="item.uponDelivery" :class="{
                    'change-text':  item.uponDelivery !==  item.olduponDelivery && hasBeforeSupplierVOBol && !payVOSBol,
                  }">
              <td>
                {{ item.uponDelivery || '-' }}
              </td>
              <td>
                <span>发货后</span>
                <span v-if="item.uponDeliveryAfter">
                  {{ item.uponDeliveryAfter}}天
                </span>
              </td>
            </tr>
            <tr v-if="item.finalPayment" :class="{
                    'change-text':  item.finalPayment !==  item.oldfinalPayment && hasBeforeSupplierVOBol && !payVOSBol,
                  }">
              <td>
                {{ item.finalPayment || '--' }}
              </td>
              <td :class="{
                    'change-text':  item.finalPaymentAfter !==  item.oldfinalPaymentAfter && hasBeforeSupplierVOBol && !payVOSBol,
                  }">
                <span>收货后</span>
                <span v-if="item.finalPaymentAfter">
                  {{ item.finalPaymentAfter }}天
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-row>

    <!-- 企业资料 -->
    <el-row class="mt30" v-if="hasBeforeSupplierVOBol && !supplierAttestationBol">
      <h4 class="f_s_15">企业资料——修改前</h4>
      <el-table :data="supplierAttestationDTO" border class="mt20">
        <el-table-column
          label="营业执照"
          align="center"
          prop="businessLicensePhoto"
        >
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              v-if="scope.row.oldbusinessLicensePhoto"
            >
              <el-image
                :src="scope.row.oldbusinessLicensePhoto"
                class="c_pointer"
                @click="handlePreview(scope.row.oldbusinessLicensePhoto)"
                style="width: 70px; height: 70px"
              ></el-image>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="社会统一信用代码" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.oldcreditCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效期" align="center">
          <template slot-scope="scope">
            {{ scope.row.oldbusinessLicensestard }}-{{
              scope.row.oldbusinessLicenseend
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="注册资金"
          align="center"
          prop="registeredCapital"
        >
          <template slot-scope="scope">
            {{ scope.row.oldregisteredCapital || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          label="企业真实照片"
          align="center"
          prop="enterprisePhoto"
        >
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              v-if="scope.row.oldenterprisePhoto"
            >
              <el-image
                :src="scope.row.oldenterprisePhoto.split(',')[0]"
                class="c_pointer"
                @click="handlePreview(scope.row.oldenterprisePhoto.split(','))"
                style="width: 70px; height: 70px"
              ></el-image>
              <span
                class="fileAnnex-span"
                v-if="scope.row.oldenterprisePhoto.split(',').length > 1"
              >
                {{ scope.row.oldenterprisePhoto.split(',').length }}
              </span>
            </el-row>
            <span v-else>/</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="supplierAttestationDTO" border class="mt15">
        <el-table-column label="法人身份证照片" align="center">
          <template slot-scope="scope">
            <el-row type="flex" justify="center">
              <el-row
                type="flex"
                align="middle"
                justify="center"
                v-if="scope.row.oldlegalPersonPhotoFront"
              >
                <el-image
                  :src="scope.row.oldlegalPersonPhotoFront"
                  class="c_pointer"
                  @click="handlePreview(scope.row.oldlegalPersonPhotoFront)"
                  style="width: 70px; height: 70px"
                ></el-image>
              </el-row>
              <el-row
                class="ml15"
                type="flex"
                align="middle"
                justify="center"
                v-if="scope.row.oldlegalPersonPhotoBack"
              >
                <el-image
                  :src="scope.row.oldlegalPersonPhotoBack"
                  class="c_pointer"
                  @click="handlePreview(scope.row.oldlegalPersonPhotoBack)"
                  style="width: 70px; height: 70px"
                ></el-image>
              </el-row>
              <span
                v-if="
                  !scope.row.oldlegalPersonPhotoFront &&
                  !scope.row.oldlegalPersonPhotoBack
                "
              >
                --
              </span>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="法人姓名" align="center" prop="legalPerson">
          <template slot-scope="scope">
            {{ scope.row.oldlegalPerson || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="法人身份证号" align="center" prop="cardNumber">
          <template slot-scope="scope">
            {{ scope.row.oldcardNumber || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="身份证有效期" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.oldcardStard">
              {{ scope.row.oldcardStard }}-{{ scope.row.oldcardEnd }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="mt30">
      <h4 class="f_s_15">{{hasBeforeSupplierVOBol && !supplierAttestationBol?'企业资料——修改后':'企业资料'}}</h4>
      <el-table :data="supplierAttestationDTO" border class="mt20">
        <el-table-column
          label="营业执照"
          align="center"
          prop="businessLicensePhoto"
        >
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              :class="{
                'change-img':
                  scope.row.businessLicensePhoto !==
                  scope.row.oldbusinessLicensePhoto && hasBeforeSupplierVOBol && !supplierAttestationBol,
              }"
              v-if="scope.row.businessLicensePhoto"
            >
              <el-image
                :src="scope.row.businessLicensePhoto"
                class="c_pointer"
                @click="handlePreview(scope.row.businessLicensePhoto)"
                style="width: 70px; height: 70px"
              ></el-image>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="社会统一信用代码" align="center">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.creditCode !== scope.row.oldcreditCode && hasBeforeSupplierVOBol && !supplierAttestationBol,
              }"
            >
              {{ scope.row.creditCode }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="有效期" align="center">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text':
                  (scope.row.businessLicensestard !==
                    scope.row.oldbusinessLicensestard && hasBeforeSupplierVOBol && !supplierAttestationBol &&
                    (scope.row.businessLicensestard || scope.row.oldbusinessLicensestard)) ||
                 ( scope.row.businessLicenseend !==
                    scope.row.oldbusinessLicenseend && hasBeforeSupplierVOBol && !supplierAttestationBol &&  (scope.row.businessLicenseend ||scope.row.oldbusinessLicenseend )),
              }"
            >
              {{ scope.row.businessLicensestard }}-{{
                scope.row.businessLicenseend
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="注册资金"
          align="center"
          prop="registeredCapital"
        >
          <template slot-scope="scope">
            <span
              :class="{
                'change-text':
                  scope.row.registeredCapital !==
                  scope.row.oldregisteredCapital && hasBeforeSupplierVOBol && !supplierAttestationBol,
              }"
            >
              {{ scope.row.registeredCapital || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="企业真实照片"
          align="center"
          prop="enterprisePhoto"
        >
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              v-if="scope.row.enterprisePhoto"
              :class="{
                'change-img':
                  scope.row.enterprisePhoto !== scope.row.oldenterprisePhoto && hasBeforeSupplierVOBol && !supplierAttestationBol,
              }"
            >
              <el-image
                :src="scope.row.enterprisePhoto.split(',')[0]"
                class="c_pointer"
                @click="handlePreview(scope.row.enterprisePhoto.split(','))"
                style="width: 70px; height: 70px"
              ></el-image>
              <span
                class="fileAnnex-span"
                v-if="scope.row.enterprisePhoto.split(',').length > 1"
              >
                {{ scope.row.enterprisePhoto.split(',').length }}
              </span>
            </el-row>
            <span v-else>/</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="supplierAttestationDTO" border class="mt15">
        <el-table-column label="法人身份证照片" align="center">
          <template slot-scope="scope">
            <el-row type="flex" justify="center">
              <el-row
                type="flex"
                align="middle"
                justify="center"
                v-if="scope.row.legalPersonPhotoFront"
                :class="{
                  'change-img':
                    scope.row.legalPersonPhotoFront !==
                    scope.row.oldlegalPersonPhotoFront && hasBeforeSupplierVOBol && !supplierAttestationBol,
                }"
              >
                <el-image
                  :src="scope.row.legalPersonPhotoFront"
                  class="c_pointer"
                  @click="handlePreview(scope.row.legalPersonPhotoFront)"
                  style="width: 70px; height: 70px"
                ></el-image>
              </el-row>
              <el-row
                class="ml15"
                type="flex"
                align="middle"
                justify="center"
                v-if="scope.row.legalPersonPhotoBack"
                :class="{
                  'change-img':
                    scope.row.legalPersonPhotoBack !==
                    scope.row.oldlegalPersonPhotoBack && hasBeforeSupplierVOBol && !supplierAttestationBol,
                }"
              >
                <el-image
                  :src="scope.row.legalPersonPhotoBack"
                  class="c_pointer"
                  @click="handlePreview(scope.row.legalPersonPhotoBack)"
                  style="width: 70px; height: 70px"
                ></el-image>
              </el-row>
              <span
                v-if="
                  !scope.row.legalPersonPhotoFront &&
                  !scope.row.legalPersonPhotoBack
                "
              >
                --
              </span>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="法人姓名" align="center" prop="legalPerson">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text':
                  scope.row.legalPerson !== scope.row.oldlegalPerson && hasBeforeSupplierVOBol && !supplierAttestationBol,
              }"
            >
              {{ scope.row.legalPerson || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="法人身份证号" align="center" prop="cardNumber">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.cardNumber !== scope.row.oldcardNumber && hasBeforeSupplierVOBol && !supplierAttestationBol,
              }"
            >
              {{ scope.row.cardNumber || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="身份证有效期" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.cardStard"
              :class="{
                'change-text':
                  (scope.row.cardStard !== scope.row.oldcardStard && hasBeforeSupplierVOBol && !supplierAttestationBol) ||
                 ( scope.row.cardEnd !== scope.row.oldcardEnd && hasBeforeSupplierVOBol && !supplierAttestationBol),
              }"
            >
              {{ scope.row.cardStard }}-{{ scope.row.cardEnd }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 账户信息 -->
    <el-row class="mt30" v-if="hasBeforeSupplierVOBol && !bankAccountBol">
      <h4 class="f_s_15">账户信息——修改前</h4>
      <el-table class="mt20 w90" border :data="dataPass.oldbankAccountVOS">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column align="center" label="账户类型">
          <template slot-scope="scope">
            {{ scope.row.accountType | accountType_filter }}
          </template>
        </el-table-column>
        <el-table-column prop="mudi" align="center" label="户名">
          <template slot-scope="scope">
            {{ scope.row.accountName || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="fangxiang" align="center" label="账号">
          <template slot-scope="scope">
            {{ scope.row.bankAccount || '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="开户行">
          <template slot-scope="scope">
            {{ scope.row.bankName || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="zhuangtai" align="center" label="税号">
          <template slot-scope="scope">
            {{ scope.row.dutyAccount || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="mt30">
      <h4 class="f_s_15">{{hasBeforeSupplierVOBol && !bankAccountBol ?'账户信息——修改后':'账户信息'}}</h4>
      <el-table class="mt20 w90" border :data="dataPass.bankAccountVOS">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column align="center" label="账户类型">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text':
                  scope.row.accountType !== scope.row.oldaccountType && hasBeforeSupplierVOBol && !bankAccountBol,
              }"
            >
              {{ scope.row.accountType | accountType_filter }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="mudi" align="center" label="户名">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text':
                  scope.row.accountName !== scope.row.oldaccountName && hasBeforeSupplierVOBol && !bankAccountBol,
              }"
            >
              {{ scope.row.accountName || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="fangxiang" align="center" label="账号">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text':
                  scope.row.bankAccount !== scope.row.oldbankAccount && hasBeforeSupplierVOBol && !bankAccountBol,
              }"
            >
              {{ scope.row.bankAccount || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="开户行">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.bankName !== scope.row.oldbankName && hasBeforeSupplierVOBol && !bankAccountBol,
              }"
            >
              {{ scope.row.bankName || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="zhuangtai" align="center" label="税号">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text':
                  scope.row.dutyAccount !== scope.row.olddutyAccount && hasBeforeSupplierVOBol && !bankAccountBol,
              }"
            >
              {{ scope.row.dutyAccount || '--' }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 认证情况 -->
    <el-row class="mt30" v-if="hasBeforeSupplierVOBol && !certificateAuthenticationcBol">
      <h4 class="f_s_15">认证情况——修改前</h4>
      <el-table
        class="mt20 w90"
        border
        :data="dataPass.oldcertificateAuthenticationcVOS"
      >
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column prop="lianxiren" align="center" label="证书名称">
          <template slot-scope="scope">{{ scope.row.cardName }}</template>
        </el-table-column>
        <el-table-column prop="way" align="center" label="证书照片">
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              v-if="scope.row.cardPhoto"
            >
              <el-image
                :src="scope.row.cardPhoto.split(',')[0]"
                class="c_pointer"
                @click="handlePreview(scope.row.cardPhoto.split(','))"
                style="width: 50px; height: 50px"
              ></el-image>
              <span
                class="fileAnnex-span"
                v-if="scope.row.cardPhoto.split(',').length > 1"
              >
                {{ scope.row.cardPhoto.split(',').length }}
              </span>
            </el-row>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" align="center" label="备注" width="480">
          <template slot-scope="scope">
            {{ scope.row.remarks || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="mt30">
      <h4 class="f_s_15">{{ hasBeforeSupplierVOBol && !certificateAuthenticationcBol ?'认证情况——修改后':'认证情况'}}</h4>
      <el-table
        class="mt20 w90"
        border
        :data="dataPass.certificateAuthenticationcVOS"
      >
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column prop="lianxiren" align="center" label="证书名称">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.cardName !== scope.row.oldcardName && hasBeforeSupplierVOBol && !certificateAuthenticationcBol,
              }"
            >
              {{ scope.row.cardName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="way" align="center" label="证书照片">
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              v-if="scope.row.cardPhoto"
              :class="{
                'change-img': scope.row.cardPhoto !== scope.row.oldcardPhoto && hasBeforeSupplierVOBol && !certificateAuthenticationcBol,
              }"
            >
              <el-image
                :src="scope.row.cardPhoto.split(',')[0]"
                class="c_pointer"
                @click="handlePreview(scope.row.cardPhoto.split(','))"
                style="width: 50px; height: 50px"
              ></el-image>
              <span
                class="fileAnnex-span"
                v-if="scope.row.cardPhoto.split(',').length > 1"
              >
                {{ scope.row.cardPhoto.split(',').length }}
              </span>
            </el-row>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" align="center" label="备注" width="480">
          <template slot-scope="scope">
            <span
              :class="{
                'change-text': scope.row.remarks !== scope.row.oldremarks && hasBeforeSupplierVOBol && !certificateAuthenticationcBol,
              }"
            >
              {{ scope.row.remarks || '--' }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 备注 -->
    <el-row class="mt30" v-if="hasBeforeSupplierVOBol && dataPass.oldremarks && dataPass.oldremarks != dataPass.remarks">
      <h4 class="f_s_15">备注——修改前</h4>
      <p style="margin-top: 20px">{{ dataPass.oldremarks || '未填写' }}</p>
    </el-row>
    <el-row class="mt30">
      <h4 class="f_s_15">{{ hasBeforeSupplierVOBol && dataPass.oldremarks && dataPass.oldremarks != dataPass.remarks ?'备注——修改后':'备注'}}</h4>
      <p style="margin-top: 20px" :class="{
                'change-text': hasBeforeSupplierVOBol && dataPass.oldremarks &&  dataPass.oldremarks != dataPass.remarks,
              }">{{ dataPass.remarks || '未填写' }}</p>
    </el-row>
    <!-- 附件 -->
    <el-row class="mt30" v-if="hasBeforeSupplierVOBol && beforeSupplierVO.enclosure && beforeSupplierVO.enclosure != dataPass.enclosure">
      <h4 class="f_s_15">附件——修改前</h4>
      <div v-show="oldfileList.length">
        <div class="flie-list" v-for="(item, i) in oldfileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <span class="ell">
                <router-link
                  :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                  target="_blank"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
      <p class="mt10" v-show="!oldfileList.length">未上传</p>
    </el-row>
    <el-row class="mt30">
      <h4 class="f_s_15">{{hasBeforeSupplierVOBol && beforeSupplierVO.enclosure && beforeSupplierVO.enclosure != dataPass.enclosure?'附件——修改后':'附件'}}</h4>
      <div v-show="fileList.length">
        <div class="flie-list" v-for="(item, i) in fileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <span class="ell">
                <router-link
                  :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                  target="_blank"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
      <p class="mt10" v-show="!fileList.length">未上传</p>
    </el-row>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
  import { SupplierManageInteractor } from '@/core'
  export default {
    name: 'ClientManageDetailMsg',

    props: {
      detailId: {
        type: String,
        required: true,
      },
      passRow: {
        type: [Object, Array],
        required: true,
      },
    },
    data() {
      return {
        showPage:false,
        supplierData: [{}],
        oldsupplierData:[{}],
        dialogImageUrl: '',
        dialogVisible: false,
        showViewer: false, //图片预览参数
        imgUrl: [], //图片预览url
        fileList: [], //协议文件
        oldfileList: [],
        fileList2: [], //协议信息的协议文件
        oldfileList2:[],
        supplierAttestationDTO: [], //企业资料
        dataPass: {},
        beforeSupplierVO: {
          linkmanVOS: [], //联系人信息
          payVOS: [], //付款方式
          bankAccountVOS: [], //账号信息
          certificateAuthenticationcVOS: [], //认证情况
          remarks: '', //备注
          enclosure:'',//附件
          isAgreement:0,
          agreementName:'',
          agreementStartDate:'',
          agreementEndDate:'',
          agreementFile:'',
          agreementMemo:'',
          agreementStatus:''
        }, //修改前信息
        hasBeforeSupplierVOBol:false,//是否有修改前数据
        linkmanVOSBol:false,//联系人是否有修改
        supplierBol:false,//协议信息是否有修改
        payVOSBol:false,//付款方式是否有修改
        supplierAttestationBol:false,//企业资料是否有修改
        bankAccountBol:false,//账号信息是否修改
        certificateAuthenticationcBol:false,//认证情况是否有修改
      }
    },

    filters: {
      accountType_filter(n) {
        const dicts = {
          1: '对公账户',
          2: '个人银行卡',
          3: '支付宝',
          4: '网拍',
        }
        return dicts[n] || '--'
      },
      taxpayerQualifications(n) {
        const dicts = {
          0: '一般纳税人',
          1: '小规模纳税人'
        }
        return dicts[n] || '--'
      },
    },
    created() {
      if(this.passRow?.auditContent?.beforeSupplierVO)this.hasBeforeSupplierVOBol = true
      if (
        this.passRow?.auditContent?.beforeSupplierVO &&
        Object.values(this.passRow?.auditContent?.beforeSupplierVO)
      ) {
        this.beforeSupplierVO = this.passRow?.auditContent?.beforeSupplierVO
        for (let k in this.beforeSupplierVO) {
          this.beforeSupplierVO[k] =
            this.passRow?.auditContent?.beforeSupplierVO?.[k] ??
            this.beforeSupplierVO[k]
        }
        this.oldfileList2 = this.beforeSupplierVO.agreementFile
          ? JSON.parse(this.beforeSupplierVO.agreementFile)
          : ''
      }
      if (this.detailId) {
        this.getSupplierDetail()
      }
    },
    methods: {
      // 获取详情数据
      async getSupplierDetail() {
        let response = await SupplierManageInteractor.supplierDetailNew({ id: this.detailId })
        this.dataPass = response.data
        const value = response.data
        this.$set(
          this.supplierAttestationDTO,
          0,
          value.supplierAttestationVO || []
        )
        if (value.payVOS && value.payVOS.length) {
          let payDTOS = []
          value.payVOS.forEach((item) => {
            let pushObj = {
              paymentId: item.paymentId,
              payType: item.payType,
              intervalName: item.intervalName,
              paymentMethodName: item.paymentMethodName,
              deposit: item.deposit,
              depositAfter: item.depositAfter,
              beforeShipping: item.beforeShipping,
              beforeShippingAfter: item.beforeShippingAfter,
              uponDelivery: item.uponDelivery,
              uponDeliveryAfter: item.uponDeliveryAfter,
              finalPayment: item.finalPayment,
              finalPaymentAfter: item.finalPaymentAfter,
              addTime:item.addTime??''
            }
            payDTOS.push(pushObj)
          })
          value.payDTOS = payDTOS
        }
        this.fileList = value.enclosure ? JSON.parse(value.enclosure) : ''

        this.fileList2 = value.agreementFile
          ? JSON.parse(value.agreementFile)
          : ''
        this.setInfoCompareFn()
      },
      // 判断信息是否有修改
      hasReviseFn(key, idKey,){
        let hasReviseBol = true;
        if(!this.dataPass[key] || !this.beforeSupplierVO[key]) return hasReviseBol
        for (let k in this.dataPass[key]) {
          const bol = this.areObjectsEqual(this.dataPass[key][k], this.beforeSupplierVO[key][k]);
          if (!bol) {
            hasReviseBol = false;
            break; 
          }
        }
       return hasReviseBol;
        
      },
      // 比较两个对象相同属性值是否相等
      areObjectsEqual(obj1={}, obj2={}) {
          const keys1 = Object.keys(obj1);
          const keys2 = Object.keys(obj2);
          const noChangeKeys = ['addTime','supplierLinkmanId','supplierPayId','supplierBankAccountId']
          for (const key of keys1) {
            const value1 = obj1[key];
            const value2 = obj2[key];
            if(noChangeKeys.includes(key))continue;
            // 检查是否为 null 或 undefined，将它们视为相等
            if ((value1 === null || value1 === undefined || value1 === '') && (value2 === null || value2 === undefined || value2 === '')) {
              continue;
            }
            if (value1 != value2) {
              return false;
            }
          }
          return true;
       },
      // 修改信息比较
      setInfoCompareFn() {
        // 处理联系人
         this.linkmanVOSBol = this.hasReviseFn('linkmanVOS', 'supplierLinkmanId')
         console.log(this.linkmanVOSBol)
        !this.linkmanVOSBol && this.setBeforeKey('linkmanVOS', 'supplierLinkmanId', 1)
        // 协议信息
        let supplierKeys = ['isAgreement','agreementName','agreementStartDate','agreementEndDate','agreementFile','agreementMemo','agreementStatus'];
        let obj1 = {}
        let obj2 = {}
        for(let k of supplierKeys ){
          obj1[k] = this.beforeSupplierVO[k]
          obj2[k] = this.dataPass[k] 
        }
         this.supplierBol =  this.areObjectsEqual(obj1,obj2)
        // 处理付款方式
        this.payVOSBol = this.hasReviseFn('payVOS', 'supplierPayId')
        if(!this.payVOSBol){
          this.$set(
            this.dataPass,
            'oldpayVOS',
            this.beforeSupplierVO.payVOS || []
          )
          this.setBeforeKey('payVOS', 'supplierPayId', 1)
        }
        // 处理企业资料
        this.supplierAttestationBol = this.areObjectsEqual(this.beforeSupplierVO?.supplierAttestationVO ?? {}, this.dataPass?.supplierAttestationVO??{})
        this.setBeforeKey('supplierAttestationVO', 'supplierAttestationId', 2)
        // 处理账户信息
        this.bankAccountBol = this.hasReviseFn('bankAccountVOS', 'supplierBankAccountId')
       !this.bankAccountBol &&  this.$set(
          this.dataPass,
          'oldbankAccountVOS',
          this.beforeSupplierVO.bankAccountVOS || []
        )
        this.setBeforeKey('bankAccountVOS', 'supplierBankAccountId', 1)
        // 处理认证情况
        this.certificateAuthenticationcBol =  this.hasReviseFn('certificateAuthenticationcVOS', 'supplierCertificateAuthenticationcId')
        !this.certificateAuthenticationcBol && this.$set(
          this.dataPass,
          'oldcertificateAuthenticationcVOS',
          this.beforeSupplierVO.certificateAuthenticationcVOS || []
        )
        this.setBeforeKey(
          'certificateAuthenticationcVOS',
          'supplierCertificateAuthenticationcId',
          1
        ) 
        // 处理备注
        this.$set(
          this.dataPass,
          'oldremarks',
          this.beforeSupplierVO.remarks || ''
        )
        // 处理附件
        this.oldfileList = this.beforeSupplierVO.enclosure
          ? JSON.parse(this.beforeSupplierVO.enclosure)
          : ''
        this.showPage = true  
      },
      // 添加修改前属性
      setBeforeKey(key, idKey, type) {
        const fn = {
          1: () => {
            if (!this.dataPass[key]) return
            if (
              this.beforeSupplierVO[key] &&
              this.beforeSupplierVO[key].length
            ) {
              this.dataPass[key].map((item) => {
                for (let k of this.beforeSupplierVO[key]) {
                  if (item[idKey] === k[idKey]) {
                    for (let key in item) {
                      item['old' + key] = k[key]
                    }
                  }
                }
                return item
              })
            } else {
              this.dataPass[key].map((item) => {
                for (let key in item) {
                  item['old' + key] = item[key] ? '' : item[key]
                }
                return item
              })
            }
          },
          2: () => {
            if (
              this.beforeSupplierVO[key] &&
              Object.values(this.beforeSupplierVO[key]).length
            ) {
              this.supplierAttestationDTO.map((item) => {
                for (let k of [this.beforeSupplierVO[key]]) {
                  if (item[idKey] === k[idKey]) {
                    for (let key in item) {
                      item['old' + key] = k[key]
                    }
                  }
                }
                return item
              })
            } else {
              this.supplierAttestationDTO.map((item) => {
                for (let key in item) {
                  item['old' + key] = item[key] ? '' : item[key]
                }

                return item
              })
            }
          },
        }
        fn[type]()
      },
      //邮件跳转
      emailClick(email) {
        let routeData = this.$router.resolve({
          name: 'MailReceiveAll',
          query: { searchValue: email },
        })
        window.open(routeData.href, '_blank')
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.getImgUrl({
            url: item,
            content: 'EQSTEP·ERP',
            cb: (base64Url) => {
              this.imgUrl = [base64Url]
            },
          })
        }
        this.showViewer = true
      },
      getImgUrl({
        url = '',
        textAlign = 'left',
        textBaseline = 'top',
        font = '18px Microsoft Yahei',
        fillStyle = 'rgba(255, 255, 255, 0.5)',
        content = '我是默认的水印参数',
        cb = null,
        textX = 100,
        textY = 30,
      } = {}) {
        // 创建所需要添加水印的img图片
        const img = new Image()
        img.src = url
        img.crossOrigin = 'anonymous'
        img.onload = function () {
          // 创建canvas，并将创建的img绘制成canvas
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')

          ctx.drawImage(img, 0, 0)
          ctx.textAlign = textAlign
          ctx.textBaseline = textBaseline
          ctx.font = font
          ctx.fillStyle = fillStyle
          ctx.rotate((Math.PI / 180) * 15)
          // 循环绘制水印
          //ctx.fillText(content, img.width - textX, img.height - textY)
          for (let i = 0; i < img.height / 120; i++) {
            for (let j = 0; j < img.width / 50; j++) {
              ctx.fillText(content, i * 200, j * 100, img.width)
            }
          }
          // 将绘制完成的canvas转换为base64的地址
          const base64Url = canvas.toDataURL()
          cb && cb(base64Url)
        }
      },
      closeViewer() {
        this.showViewer = false
      },
      //查看资料
      clientDatumClick(supplierId, status) {
        if (status === 1) {
          this.$refs.SupplierManageDatum.type = '查看'
        } else if (status === 0) {
          this.$refs.SupplierManageDatum.type = '上传'
        }
        this.$refs['SupplierManageDatum'].show(supplierId, status)
        this.$refs.SupplierManageDatum.supplier = supplierId
      },
    },
  }
</script>

<style scoped lang="scss">
  .detail-msg {
    padding: 20px;
    border-left: 1px solid #d7d7d7;
    min-height: 700px;
  }
  .base-box {
    ::v-deep.el-col{
      min-height:32px;
    }
    label {
      color: #666;
    }
    .col-span {
      color: #000;
    }
  }
  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }
  .w90 {
    width: 90%;
  }
  .table-pay {
    width: 80%;
  }
  .table-pay th {
    font-weight: normal;
  }
  .table-pay td {
    height: 36px;
  }
  // 邮箱链接
  .prod-code {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
  .input-span {
    display: inline-block;
    height: 48px;
    line-height: 48px;
  }
  ::v-deep {
    .change-text {
      color: #d9001b;
      font-weight: 700;
    }
    .cell {
      &:has(.change-img) {
        position: relative;
        &::before {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: #d9001b;
          content: '';
          position: absolute;
          top: 0px;
          right: 4px;
          z-index: 9999;
        }
      }
    }
  }
</style>
