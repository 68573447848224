
export const mixin = {
  props: {
    type: {
      //类型
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [], //表格数据
      total: 0, // 总条数
      pageSize: 10, //每页数量
      pageNumber: 1, // 当前页
      tableHeight: 300, //表格高度 
    }
  },
  computed: {

  },
  methods: {
    pageChange(val) { //切换分页
      this.pageNumber = val
      if (typeof this.getList === 'function') {
        this.getList()
      }

    },
    sizeChange(val) { // 分页数量变化
      this.pageSize = val
      if (typeof this.getList === 'function') {
        this.getList()
      }
    },
    // 编辑
    doEdit(row) {
      const type2id = {
        1: 'configOperateCostId',
        2: 'configOperatePersonnelId',
        3: 'configOperateCustomId'
      }
      const businessId = row[type2id[this.type]]
      this.$router.push({
        name: 'operatingCostConfigNew',
        query: {
          type: this.type,
          businessId,
        },
      })
    },

    // total赋值
    setTotal(total) {
      this.total = total
    },

    // 分页数据重置
    resetPage() {
      this.pageNo = 1
    },


    getSearchParams() {
      const form = {
        ...this.$parent.queryForm,
        pageLe: this.pageSize,
        pageNo: this.pageNumber
      }
      if (form.standardCustomized == '全部') {
        form.standardCustomized = null
      }
      return form
    },
    // 表格行启用或者冻结
    toggleFreeze(item) {
      const { status } = item
      if (status === 0) { //启用状态下冻结
        this.$confirm('冻结后，新单据则无法调用此模板！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$emit('template-freeze', {
              ...item,
              status: 1
            })
          })
          .catch(() => { })
      } else {
        this.$emit('template-freeze', {
          ...item,
          status: 0
        })
      }

    },
    // 获取表格高度
    getTableHeight() {
      const W_H = document.body.getBoundingClientRect().height
      this.tableHeight = W_H - 54 - 220
    },
    // resize监听
    onResize() {
      window.addEventListener('resize', this.getTableHeight, false)
    },

  },
  created() {
    this.getList()
    this.getTableHeight()
    this.onResize()
  }
}


/**
 * 新增编辑mixin
 */
export const newMixin = {
  methods: {
    // 新增一行
    addLine() {
      this.form.tableData.push({ ...this.baseTpl })
    },
    // 删除一行
    del(index) {
      this.delItems = this.delItems || [] //删除项
      if (this.form.tableData.length === 1) {
        return this.$message.warning('至少保留一行数据')
      } else {
        this.$confirm('是否删除数据', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const delItem = this.form.tableData.splice(index, 1)[0]
            if (
              delItem.configOperateCostId ||
              delItem.configOperatePersonnelId ||
              delItem.configOperateCustomId
            ) {
              // 删除处理
              delItem.deleted = 1
              this.delItems.push(delItem)
            }
          })
          .catch(() => { })
      }
    },

    // 初始化数据保存
    _saveInitTableData(tableData) {
      this.initTableDataStr = JSON.stringify(tableData)
    },

    // 判断表格数据是否发生变化
    hasTableDataChanged() {
      return this.initTableDataStr !== JSON.stringify(this.form.tableData)
    },

  },
  created() {
    this.saved = false
  }
}
