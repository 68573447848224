<template>
  <el-dialog
    title="请选择协助客代"
    :visible.sync="visible"
    width="400px"
    :before-close="cancel"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="人员" prop="userId">
        <UserSelect
          placeholder="请选择"
          @user-choose="(user) => chooseUser(user)"
          ref="userselect"
        />
      </el-form-item>
      <div style="text-align: center">
        <el-button type="primary" @click="submit" :loading="loading">
          确认
        </el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
  import { OrderSettingInteractor } from '@/core/interactors/order-setting'
  import UserSelect from '@/components/user-select'
  export default {
    data() {
      return {
        form: {
          userId: '',
        },
        rules: {
          userId: [
            {
              required: true,
              message: '请选择',
              trigger: 'change',
            },
          ],
        },
        loading: false,
        areaList: [],
        visible: false,
      }
    },
    components: {
      UserSelect,
    },
    methods: {
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true
            this.save()
            this.cancel()
          }
        })
      },
      cancel() {
        this.form = this.$options.data().form
        this.form.userId = ''
        this.$refs.userselect?.clearUser()
        this.$refs.form.resetFields()
        this.visible = false
      },
      // 编辑展示详情
      chooseUser(user) {
        this.form.userId = user ? user.userId : ''
      },
      showAddEdit() {
        this.visible = true
      },
      // 父组件 传值
      save() {
        console.log('this.form', this.form)
        this.$refs.form.validate((flag) => {
          if (flag) {
            OrderSettingInteractor.insertOrderAssistPOApi(
              this.form.userId
            ).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('添加成功')
                this.$emit('assist-refresh')
              }
            })
          }
        })

        this.loading = false
      },
    },
  }
</script>
