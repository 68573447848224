<template>
  <el-dialog
    :title="title"
    :visible.sync="orderRejectVisible"
    width="700px"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-row :gutter="20">
      <el-col :span="8" :offset="1">
        结算单号：{{ dialogObj.settlementOrder }}
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 10px">
      <el-col :span="8" :offset="1">
        订单编号：{{ dialogObj.salesOrder }}
      </el-col>
      <el-col :span="9">客户代表：{{ dialogObj.businessName }}</el-col>
      <el-col :span="6">地区：{{ dialogObj.businessArea }}</el-col>
    </el-row>
    <div class="dialog-content">
      <el-form
        :model="rejectForm"
        :rules="rejectRules"
        ref="rejectForm"
        label-width="80px"
      >
        <el-form-item label="原因说明" prop="statusRemark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="500"
            show-word-limit
            v-model.trim="rejectForm.statusRemark"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="附件" v-if="type === 'adjust'">
          <ErpUpload
            ref="ErpUpload"
            :uploadParams="uploadParams"
            @uploadList="uploadList"
            @initUpload="initUpload"
          ></ErpUpload>
        </el-form-item>
      </el-form>
      <AuditChoose
        ref="AuditChoose"
        @auditData="saveClick"
        @audit-close="onAuditClose"
      />
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="saveReject">提 交</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { OrderSettleInteractor } from '@/core/interactors/finance/order-settlement'
import AuditChoose from '@/components/AuditChoose'

export default {
  props: {
    orderRejectVisible: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
    type: {
      default: '',
      type: String,
    },
    salesOrderId: {
      default: '',
      type: String,
    },
    settlementSalesOrderId: {
      default: '',
      type: String,
    },
    settlementOrder: {
      type: String,
      default: '',
    },
    tenantId: {
      type: [String, Number],
      default: '',
    },
    dialogObj: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    AuditChoose,
  },
  data() {
    return {
      rejectForm: {
        financeIds: [],
        statusRemark: '', // 驳回原因
      },
      rejectRules: {
        statusRemark: [
          { required: true, message: '请填写驳回原因', trigger: 'blur' },
        ],
      },
      uploadParams: {
        size: 1024 * 1024 * 20, //单个文件上传大小
        amount: 1,
      },
      accessoryUrl: '',
    }
  },
  created() {},
  methods: {
    //关闭
    close() {
      this.$refs['rejectForm'].resetFields()
      this.$emit('update:orderRejectVisible', false)
    },

    // 点击确定驳回
    async saveReject() {
      this.$refs['rejectForm'].validate(async (valid) => {
        if (valid) {
          if (this.type == 'adjust') {
            return this.showAudit()
          }
          let data = {
            adjustmentReason: this.rejectForm.statusRemark,
            salesOrderId: this.salesOrderId,
            settlementSalesOrderId: this.settlementSalesOrderId,
            settlementOrder: this.settlementOrder,
            enclosure: this.accessoryUrl,
          }
          let res = await OrderSettleInteractor.setRejectOrder(data)
          if (res) {
            this.$message.success('操作成功')
            this.$emit('refreshList')
            this.close()
          }
        }
      })
    },

    showAudit() {
      let auditParams = {
        auditType: 14,
        status: 0,
        tenantId: this.tenantId,
      }
      this.pubApi.getAuditTemplates(auditParams).then((response) => {
        if (!response.data.length) {
          this.$baseMessage(
            '请联系管理员配置审核模板',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          this.$refs['AuditChoose'].showAddEdit(auditParams)
        }
      })
    },
    // 获取上传文件地址
    uploadList(fileList) {
      this.accessoryUrl = JSON.stringify(fileList)
    },
    // 回显附件
    initUpload(data) {
      this.$refs.ErpUpload.initUpload(data)
      this.tablekey = Math.random()
    },

    saveClick(data) {
      let requestData = {
        auditObjectDTO: data,
        adjustmentReason: this.rejectForm.statusRemark,
        salesOrderId: this.salesOrderId,
        settlementSalesOrderId: this.settlementSalesOrderId,
        settlementOrder: this.settlementOrder,
        enclosure: this.accessoryUrl,
        area: this.dialogObj.businessArea,
      }
      OrderSettleInteractor.adjustment(requestData).then((res) => {
        if (res) {
          this.$refs['rejectForm'].resetFields()
          this.$emit('update:orderRejectVisible', false)
          this.$message.success('操作成功')
          this.$emit('refreshList')
          this.accessoryUrl = ''
        }
      })
    },

    onAuditClose() {},
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  margin-top: 10px;
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
.text-hidden {
  width: 150px;
}
</style>
