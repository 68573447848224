<template>
  <div class="table-container" v-if="$route.meta.showParent">
    <div>
      <!-- 切换 -->
      <div class="mb10 top-wrapper">
        <el-radio-group v-model="showType" v-if="permissionsTab('Company:MsgNew')">
          <el-radio-button label="company" v-allowed="['Company:MsgNew']">
            {{ lang === 'en' ? 'Company Search' : '公司查询' }}
          </el-radio-button>
          <el-radio-button label="customer">
            {{ lang === 'en' ? 'Customer Search' : '客户查询' }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <!-- 查询表单开始 -->
      <div class="search-wrapper" v-if="showType == 'company'">
        <el-select v-model="searchFormCompany.queryType" class="w130 mr10 mb10">
          <el-option :value="1" :label="$t('cusCenter.myCustomer')"></el-option>
          <el-option :value="2" :label="$t('cusManage.AllCustomers')"></el-option>
        </el-select>
        <!-- 客户邮箱 -->
        <el-input class="w150 mr10" v-model.trim="searchFormCompany.email" @keyup.enter.native="search"
          :placeholder="$t('cusDetail.customerMail')"></el-input>

        <!-- 公司编码 -->
        <el-input class="w130 mr10" @keyup.enter.native="search" v-model="searchFormCompany.companyCode"
          :placeholder="$t('cusCenter.companycode')"></el-input>

        <!-- 公司名称 -->
        <el-input class="w150 mr10" v-model="searchFormCompany.companyName" :placeholder="$t('cusDetail.CompanyName')"
          @keyup.enter.native="search"></el-input>

        <!-- 类型 -->
        <el-select v-model="searchFormCompany.status" class="w130 mr10" :placeholder="$t('cusDetail.CompanyType')">
          <el-option value="" :label="lang === 'en' ? 'All' : '全部'"></el-option>
          <el-option :value="1" :label="$t('cusDetail.OfficialCompany')"></el-option>
          <el-option :value="0" :label="$t('cusDetail.PotentialCompany')"></el-option>
          <el-option :value="2" :label="$t('cusDetail.blacklistcompany')"></el-option>
        </el-select>

        <!-- 公司等级 -->
        <el-select class="w130 mr10" v-model="searchFormCompany.companyLevel"
          :placeholder="$t('cusCenter.companyLevel')">
          <el-option v-for="item in companyLevelList" :key="item.code" :value="item.code"
            :label="item.label"></el-option>
        </el-select>
        <!-- 搜索 -->
        <el-button type="primary" @click="search" class="mb10">
          {{ $t('cusCenter.Search') }}
        </el-button>
        <el-button @click="reset" class="mb10">
          {{ $t('cusCenter.Reset') }}
        </el-button>
      </div>
      <div class="search-wrapper" v-else>
        <el-select v-model="searchFormCustomer.type" class="w130 mr10 mb10">
          <el-option :value="1" :label="$t('cusCenter.myCustomer')"></el-option>
          <el-option :value="2" :label="$t('cusManage.AllCustomers')"></el-option>
        </el-select>
        <!-- 客户邮箱 -->
        <el-input class="w150 mr10" v-model="searchFormCustomer.email"
          :placeholder="$t('cusDetail.customerMail')"></el-input>

        <!-- 客户编码 -->
        <el-input v-model="searchFormCustomer.customerCode" class="w130 mr10 mb10"
          :placeholder="$t('companydetail.CustomerCode')"></el-input>

        <!-- 客户名称 -->
        <el-input class="w150 mr10" v-model="searchFormCustomer.linkman"
          :placeholder="$t('cusManage.CustomerName')"></el-input>

        <!-- 客户电话 -->
        <el-input v-if="showType !== 'company'" class="w150 mr10" v-model="searchFormCustomer.phone"
          :placeholder="$t('cusCenter.CustomerPhone')"></el-input>
        <el-select class="w160 mr10" v-model="searchFormCustomer.customerSourceId"
          :placeholder="$t('cusDetail.CustomerSource')">
          <el-option v-for="item in customerSource" :key="item.sourceId"
            :label="lang === 'en' ? item.sourceNameEn : item.sourceName" :value="item.sourceId"></el-option>
        </el-select>
        <!-- 负责人/分管人 -->
        <el-select v-model="searchFormCustomer.personType" class="w130 mr10" :placeholder="$t('placeholder.plsSel')">
          <el-option :value="1" :label="$t('cusDetail.ResponsiblePerson')"></el-option>
          <el-option :value="2" :label="$t('cusDetail.PersonInCharge')"></el-option>
        </el-select>
        <el-input class="w150 mr10" v-model="searchFormCustomer.personalValue" @focus="showPerson"
          :placeholder="$t('placeholder.plsSel')"></el-input>
        <!-- 回归公海剩余时间 -->
        <el-select v-model="searchFormCustomer.timeRemaining" :class="['mr10', lang !== 'en' ? 'w150' : 'w260']"
          :placeholder="$t('cusManage.ReturnToTheHighSeasRemainingTime')">
          <el-option v-for="item in timeRemainingList" :key="item.code" :value="item.code"
            :label="lang === 'en' ? item.labelEn : item.label"></el-option>
        </el-select>
        <!-- 类型 -->
        <el-select v-model="searchFormCustomer.customerType" class="w150 mr10"
          :placeholder="$t('cusDetail.CustomerType')">
          <el-option value="" :label="lang === 'en' ? 'All' : '全部'"></el-option>
          <el-option :value="1" :label="$t('cusDetail.OfficialCustomer')"></el-option>
          <el-option :value="0" :label="$t('cusDetail.PotentialCustomers')"></el-option>
          <el-option :value="2" :label="$t('selOpt.Blacklistcustomers')"></el-option>
        </el-select>

        <!-- 搜索 -->
        <el-button type="primary" @click="search" class="mb10">
          {{ $t('cusCenter.Search') }}
        </el-button>
        <el-button @click="reset" class="mb10">
          {{ $t('cusCenter.Reset') }}
        </el-button>
      </div>
      <!-- 查询表单结束 -->

      <!-- 操作按钮 -->
      <el-row class="page-header mb10" type="flex" align="middle" justify="space-between">
        <el-col :span="24">
          <el-row type="flex" align="middle" style="justify-content: space-between">
            <div>
              <!-- 新增 -->
              <el-button type="primary" icon="el-icon-plus" @click="addClick" v-show="!isCompany"
                v-allowed="['Add:ClientNew']">
                {{ $t('cusManage.Add') }}
              </el-button>
              <!-- 转让 -->
              <el-button v-if="!isCompany && buttonCommonControl" class="ml10" type="primary" plain
                @click="transferClick">
                {{ $t('cusManage.Transfer') }}
              </el-button>
              <!-- 转入公海 -->
              <el-button v-if="!isCompany && buttonCommonControl" class="ml10" type="primary" plain
                @click="throwPublicSeaClick">
                {{ $t('cusManage.ThrownBackIntoTheOpenSea') }}
              </el-button>
              <!-- 分管 -->
              <el-button v-if="!isCompany && buttonCommonControl" class="ml10" type="primary" plain
                @click="assignClick()">
                {{ $t('cusManage.InCharge') }}
              </el-button>
              <!-- 移除分管 -->
              <el-button v-if="
                  !isCompany && selectRows.length < 2 && buttonCommonControl
                " class="ml10" type="primary" plain @click="removeAppraise">
                {{ $t('cusDetail.removethebranch') }}
              </el-button>
              <!-- 加入黑名单 -->
              <el-button type="danger" plain @click="addBlacklist()"
                v-if="showAddBlacklist && buttonCommonControl && toblackRight">
                {{ $t('cusManage.AddToBlacklist') }}
              </el-button>
              <!-- 转出黑名单 -->
              <el-button class="ml10" type="primary" plain @click="removeBlacklist" v-if="
                  showRemoveBlacklist && buttonCommonControl && outblackRight
                ">
                {{ $t('cusDetail.RemovefromBlacklist') }}
              </el-button>
              <p v-if="selectRows.length" class="ml10">
                <!-- 已选择  -->
                {{ lang === 'en' ? 'Selected' : '已选择' }}
                <strong class="f_s_18 f_w_7">{{ selectRows.length }}</strong>
                <!-- 个客户 -->
                <template v-if="!isCompany">
                  {{ lang === 'en' ? 'Customers' : '个客户' }}
                </template>
                <template v-else>
                  {{ lang === 'en' ? 'Companys' : '个公司' }}
                </template>
              </p>
            </div>
            <el-popover ref="showOption" popper-class="custom-table-checkbox" trigger="hover">
              <erp-draggable v-bind="dragOptions" :list="columns">
                <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
                  :disabled="item.checkable === false" :label="item.label">
                  {{ $t(item.langKey) }}
                </el-checkbox>
              </erp-draggable>
              <!-- 保存自定义显示列 -->
              <div class="save-btn">
                <el-button type="primary" class="ml10" size="mini" plain @click="operateSave">
                  <!-- 保存 -->
                  {{ $t('cusManage.Save') }}
                </el-button>
              </div>
              <template #reference>
                <!-- content="列表可拖动排序" -->
                <el-tooltip effect="dark" :content="$t('iconbtn.Listdraggablesort')" placement="top-start">
                  <el-button plain type="primary" class="">
                    <!-- 自定义显示 -->
                    {{ $t('cusManage.CustomDisplay') }}
                  </el-button>
                </el-tooltip>
              </template>
            </el-popover>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <!-- 公司数据列表 -->
    <ErpTable :page-number.sync="pageNo" :page-size.sync="pageLe" :table-data="tableData" :total="total"
      @query="refreshData" class="table-fixed" @selection-change="setSelectRows" :key="tableKey"
      :extraHeight="extraHeight" ref="erpTable" v-if="isCompany">
      <el-table-column align="center" type="index" width="55" :label="lang === 'en' ? 'No.' : '序号'" fixed="left" />
      <el-table-column align="center" type="selection" width="55" fixed="left" />
      <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="$t(item.langKey)"
        show-overflow-tooltip :sortable="item.sortable" :resizable="false" align="center"
        :min-width="lang === 'en' ? item.enWidth : item.width" :prop="item.prop"
        :fixed="item.prop === 'companyCode' ? 'left' : false">
        <template #default="{ row }">
          <p class="page-link" v-if="item.prop === 'companyCode'" @click="showCompanyDetail(row.companyId)">
            {{ row.companyCode }}
          </p>
          <el-row v-else-if="item.prop === 'status'">
            <el-tooltip :content="'原因：' + row.blackRemark" placement="top" v-if="row.status === 3">
              <span class="c_pointer">
                {{ companyTypeStatusLang(row.status) }}
              </span>
            </el-tooltip>
            <span v-else>{{ companyTypeStatusLang(row.status) }}</span>
          </el-row>

          <!-- 公司等级 -->
          <el-rate v-else-if="item.prop === 'companyLevel'" v-model="row.companyLevel" disabled
            text-color="#ff9900"></el-rate>

          <el-row v-else-if="item.prop === 'companyName'">
            <pre class="precls">{{ row.companyName }}</pre>
          </el-row>

          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('cusManage.Operate')" width="150" fixed="right" :resizable="false">
        <template slot-scope="scope">
          <!-- 潜在客户与正式客户都可以编辑 -->
          <el-row v-if="scope.row.customerType !== 2">
            <!-- 详情" -->
            <el-tooltip :content="$t('cusManage.Detail')" placement="top">
              <el-button type="primary" plain circle size="mini" @click="showCompanyDetail(scope.row.companyId)">
                <i class="iconfont icon-chakan f_s_12"></i>
              </el-button>
            </el-tooltip>
            <!-- "编辑" -->
            <el-tooltip :content="$t('iconbtn.edit')" placement="top" v-if="scope.row.status != 2">
              <el-button type="primary" plain circle size="mini" v-if="scope.row.editOption"
                @click="editCompany(scope.row.companyId)">
                <i class="iconfont f_s_12">&#xe661;</i>
              </el-button>
            </el-tooltip>
            <!-- 备注 -->

            <el-tooltip :content="$t('iconbtn.remark')" placement="top" v-if="scope.row.status != 2">
              <el-button type="primary" plain circle size="mini" @click="setMark(scope.row)">
                <i class="iconfont icon-beizhu1 f_s_12"></i>
              </el-button>
            </el-tooltip>
          </el-row>
          <el-row v-else>--</el-row>
        </template>
      </el-table-column>
    </ErpTable>
    <!-- 客户数据列表 -->
    <ErpTable :page-number.sync="pageNo" :page-size.sync="pageLe" :table-data="tableData" :total="total"
      @query="refreshData" class="table-fixed" @selection-change="setSelectRows" :key="tableKey"
      :extraHeight="extraHeight" ref="erpTable" v-else>
      <el-table-column align="center" type="index" width="55" :label="lang === 'en' ? 'No.' : '序号'" fixed="left" />
      <el-table-column align="center" type="selection" width="55" fixed="left" />
      <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="$t(item.langKey)"
        show-overflow-tooltip :sortable="item.sortable" :resizable="false" align="center"
        :min-width="lang === 'en' ? item.enWidth : item.width" :prop="item.prop"
        :fixed="item.prop === 'customerCode' ? 'left' : false">
        <template #default="{ row }">
          <p :class="[1, 2, 3, 4].includes(row.status) ? 'page-link' : ''" v-if="item.prop === 'customerCode'"
            @click="showCustomerDetail(row)">
            {{ row.customerCode }}
          </p>
          <el-row v-else-if="item.prop === 'customerType'">
            <el-tooltip :content="'原因：' + row.blackRemark" placement="top" v-if="row.customerType === 2">
              <span class="c_pointer">
                {{ customerTypeStatus(row.customerType) }}
              </span>
            </el-tooltip>
            <span v-else>{{ customerTypeStatus(row.customerType) }}</span>
          </el-row>

          <el-row v-else-if="item.prop === 'status'">
            <span>{{ customerStatusLang(row.status) }}</span>
          </el-row>
          <el-row v-else-if="item.prop === 'linkman'">
            <pre class="precls">{{ row.linkman }}</pre>
          </el-row>
          <el-row v-else-if="item.prop === 'companyName'">
            <pre class="precls">{{ row.companyName }}</pre>
          </el-row>

          <p v-else-if="item.prop === 'allCharge'" style="width: 90%; overflow: hidden; text-overflow: ellipsis">
            <span>
              {{
              lang !== 'en'
              ? row.allChargeCn
              ? row.allChargeCn
              : '--'
              : row.allChargeEn
              ? row.allChargeEn
              : '--'
              }}
            </span>
          </p>

          <!-- 负责人 -->
          <el-row v-else-if="item.prop === 'belongerName'" class="text-ellipsis">
            <span>
              {{ getName(row, 'belongerName') }}
            </span>
          </el-row>
          <!-- 客户邮箱 -->

          <p v-else-if="item.prop === 'email'" class="page-link"
            style="width: 90%; overflow: hidden; text-overflow: ellipsis" @click="sendMail(row)">
            {{ row.email }}
          </p>

          <!-- 客户来源 -->
          <el-row v-else-if="item.prop === 'customerSourceName'" class="text-ellipsis">
            <span>{{ getName(row, 'customerSourceName') || '--' }}</span>
          </el-row>
          <!-- 回归公海剩余时间 -->
          <el-row v-else-if="item.prop === 'timeRemaining'">
            <span v-if="row.timeRemaining || row.timeRemaining === 0">
              {{
              `${row.timeRemaining}${
              lang === 'en'
              ? `${row.timeRemaining > 1 ? 'Days' : 'Day'}`
              : '天'
              }`
              }}
            </span>
            <span v-else>--</span>
          </el-row>
          <!-- 订单成交量 -->
          <el-row v-else-if="item.prop === 'totalOrderNum'">
            <span v-if="row.totalOrderNum || row.totalOrderNum === 0">
              {{ `${row.totalOrderNum}${lang === 'en' ? 'orders' : '单'}` }}
            </span>
            <span v-else>--</span>
          </el-row>

          <!-- 客户等级 -->
          <el-rate v-else-if="item.prop === 'customerRank'" v-model="row.customerRank" disabled
            text-color="#ff9900"></el-rate>

          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" :label="$t('cusManage.Operate')" width="280" fixed="right" :resizable="false">
        <template slot-scope="scope">
          <!-- 潜在客户与正式客户都可以编辑 -->
          <el-row v-if="scope.row.customerType !== 2">
            <!-- 详情" -->
            <el-tooltip :content="$t('cusManage.Detail')" placement="top"
              v-if="[1, 2, 3, 4].includes(scope.row.status)">
              <el-button type="primary" plain circle size="mini" @click="showCustomerDetail(scope.row)">
                <i class="iconfont icon-chakan f_s_12"></i>
              </el-button>
            </el-tooltip>
            <!-- "编辑" -->
            <el-tooltip :content="$t('iconbtn.edit')" placement="top" v-if="[1, 4].includes(scope.row.status)">
              <el-button type="primary" plain circle icon="el-icon-edit" size="mini"
                @click="editCustomer(scope.row)"></el-button>
            </el-tooltip>

            <!-- 跟进-->
            <el-tooltip :content="$t('cusManage.FollowUp')" placement="top" v-if="[1].includes(scope.row.status)">
              <el-button type="primary" plain circle size="mini" @click="clientFollowClick(scope.row)">
                <i class="iconfont f_s_12">&#xe661;</i>
              </el-button>
            </el-tooltip>
            <!-- 备注 -->

            <el-tooltip :content="$t('iconbtn.remark')" placement="top" v-if="[1].includes(scope.row.status)">
              <el-button type="primary" plain circle size="mini" @click="setMark(scope.row)">
                <i class="iconfont icon-beizhu1 f_s_12"></i>
              </el-button>
            </el-tooltip>
            <!-- MKT备注 -->

            <el-tooltip :content="$t('cusDetail.mktremarks')" placement="top" v-if="[1].includes(scope.row.status)">
              <el-button type="primary" plain circle size="mini" @click="showRemarkInput(scope.row, 2)">
                <i class="iconfont icon-beizhu2 f_s_12"></i>
              </el-button>
            </el-tooltip>
          </el-row>
          <!-- 分管 -->
        </template>
      </el-table-column>
    </ErpTable>

    <!-- 新增编辑客户弹框 -->
    <ClientManageAddModel ref="ClientManageAdd" @fetch-data="refreshData" />

    <!-- 加入黑名单 -->
    <!-- title="加入黑名单" -->
    <el-dialog :title="$t('cusManage.AddToBlacklist')" :visible="blackVisible" append-to-body width="500px"
      @close="onCancel" modal-append-to-body>
      <el-row type="flex">
        <el-form :model="blackForm" style="width: 100%" ref="blackForm">
          <!-- label="原因" -->
          <el-form-item :label="$t('cusManage.Reason')" prop="remarks" :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]">
            <el-input type="textarea" :placeholder="$t('placeholder.plsInput')" maxlength="500" show-word-limit
              v-model="blackForm.remarks"></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <div class="footer mt30 t_a_c">
        <el-button type="primary" @click="cancelSure">
          {{ $t('cusManage.Confirm') }}
        </el-button>
        <el-button @click="onCancel">{{ $t('cusManage.Cancel') }}</el-button>
      </div>
    </el-dialog>

    <!-- 客户跟进 -->
    <ClientManagefollowModel ref="ClientManagefollowModel" @fetch-data="refreshData" />

    <!-- 转让分管 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" :autoClose="autoClose" />
    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
    <!-- 备注弹框 -->
    <RemarkInput ref="remarkinput" :title="remarkTitle" @remark-confirm="confirmRemark"></RemarkInput>

    <RemarkDrawer :addRemark="addRemark" :infoId="infoId" :companyId="companyId"
      :remarkType="showType == 'company' ? 1 : 2" @closeDrawer="closeDrawer"></RemarkDrawer>
    <!-- 移除分管 -->
    <RemoveAppraiseModel ref="removeAppraiseModel" @fetch-data="refreshData" />
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import ErpDraggable from 'vuedraggable'
  import BaseTable from '@/components/baseTable'
  import ClientManageAddModel from './components/client-manage-add-model'
  import RemarkInput from './components/remark-input'
  import { companyLevelList } from './helper'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  import {
    clentListPage,
    listTakeCustomerPage,
    throwBackHighSeas,
    customerTransfer,
    customerBranched,
    customerBlackList,
  } from '@/api/client-supplier/client-manage'
  import CustomerManageInteractor from '@/core/interactors/new-customer-manage/index'
  import ClientManagefollowModel from './components/client-manage-follow-model'
  import UserChoose from '@/components/userChoose'
  import AuditChoose from '@/components/AuditChoose'
  import {
    customerTypeStatusLang,
    customerStatusLang,
    companyTypeStatusLang,
  } from '@/utils/en-match-zh'
  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  import nameMixin from '@/utils/name-mixin'
  import customTableMixin from '@/utils/custom-table-mixin'
  import { companyColumns, customerColumns } from './helper'
  import RemoveAppraiseModel from './components/remove-appraise-model'
  import RemarkDrawer from './components/remark-drawer'
  import { hasRight } from '@/utils/permissionBtn'
  import { getKeyIds } from '@/utils/pagelist-util'

  export default {
    name: 'CustomerCenter',
    mixins: [nameMixin, customTableMixin],
    components: {
      BaseTable, //表格
      ErpDraggable, //拖动
      ClientManageAddModel, // 新增客户
      ClientManagefollowModel, // 客户跟进
      UserChoose, // 用户选择
      AuditChoose, // 审核
      RemarkInput, //备注弹框
      RemoveAppraiseModel,
      RemarkDrawer, // 备注轨迹
    },
    data() {
      return {
        extraHeight: 130, //表格额外高度
        showType: 'customer', // 展示类型
        tabSpan: [{ name: '全部客户' }, { name: '我负责的' }],
        tabSpanCurrent: 1,
        tableData: [], // 表格数据
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数,
        tableKey: 0,
        userCustomizeColumnId: '', //自义定保存id
        selectRows: [], //表格勾选的数量
        stars: 5, // 星级
        size: '900px',
        auditManageTags: [],
        currentStr: '', //弹窗类型判断
        companyId: '', //公司id，用于调用公司详情接口
        assignStr: '', //公司
        infoId: '', // 客户id
        isThrow: true, //是否可以进行仍回公海操作
        isTransfer: true, //是否可以进行转让操作
        isCharge: true, //是否可以进行分管操作
        blackVisible: false,
        blackForm: {
          remarks: '', //黑名单理由
        },
        addRemark: false, //备注侧滑控制
        searchFormCompany: {
          //查询表单
          queryType: 1, //类型 1：我的客户，2：全部客户
          companyName: '', // 公司名称
          email: '', //客户邮箱
          status: '', //公司类型
          companyCode: '', // 公司编码
          cmpanyRank: '', // 公司等级
          code: '', // 输入的查询值
        },

        searchFormCustomer: {
          //查询表单
          type: 1, //类型 默认我的客户
          linkman: '', // 客户名称
          email: '', //客户邮箱
          customerSourceId: '', //客户来源id
          personType: '', // 查询人员类型
          personalValue: '', // 查询人员值
          customerType: '', // 客户类型
          phone: '', //电话号码
          customerCode: '', // 客户编码
          timeRemaining: '',
        },
        companyLevelList, // 公司等级数据
        customerSource: [], // 客户来源
        autoClose: false, // 自动关闭
        remarkType: 1, //1 备注 2业务备注 3 mkt备注
        infoId: '', //客户id
        companyId: '', // 公司id
        columns: companyColumns, // 自定义列
        companyColumns: companyColumns, // 公司自定义列
        customerColumns: customerColumns, // 客户自定义列
        transferParams: {
          companyIds: '',
          defaultAddressUserIds: '',
        }, // 转让、转入公海、移除分管入参
        transferParams: {
          companyIds: '',
          defaultAddressUserIds: '',
        }, // 转让、转入公海、移除分管入参
        timeRemainingList: [
          {
            label: '小于30天',
            labelEn: 'Less than 30 days',
            code: 30,
          },
          {
            label: '小于20天',
            labelEn: 'Less than 20 days',
            code: 20,
          },
          {
            label: '小于10天',
            labelEn: 'Less than 10 days',
            code: 10,
          },
          {
            label: '小于5天',
            labelEn: 'Less than 5 days',
            code: 5,
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
        lang: 'settings/language',
      }),

      isCompany() {
        // 公司tab
        return this.showType === 'company'
      },

      // drag配置项
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },

      finallyColumns() {
        let columns = []
        if (this.showType === 'company') {
          columns = this.companyColumns
        } else {
          columns = this.customerColumns
        }
        return columns.filter((item) => item.isShow === true)
      },
      // 黑名单按钮
      showAddBlacklist() {
        if (this.selectRows.length > 1 && this.isCompany) return false
        if (!this.isCompany) {
          return this.selectRows.every((item) => item.customerType != 2)
        } else {
          return this.selectRows.every((item) => item.status != 2)
        }
      },

      // 移除黑名单按钮
      showRemoveBlacklist() {
        if (!this.isCompany) {
          return this.selectRows.every((item) => item.customerType == 2)
        } else {
          return this.selectRows.every((item) => item.status == 2)
        }
      },

      // 备注标题
      remarkTitle() {
        switch (this.remarkType) {
          case 1:
            return 'cusDetail.Remark'
          case 2:
            return 'cusDetail.mktremarks'
          case 3:
            return 'cusDetail.BusinessRemraks'
        }
      },

      // 请求的参数
      infoLogDTOList() {
        return this.selectRows.map((item) => {
          return {
            customerCode: item.customerCode,
            customerName: item.linkman,
          }
        })
      },
      // 加入黑名单控制
      toblackRight() {
        if (this.isCompany) {
          if (hasRight('CompanyToBlackList')) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      },

      // 转出黑名单控制
      outblackRight() {
        if (this.isCompany) {
          if (hasRight('CompanyOutBlackList')) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      },

      // 按钮公共部分逻辑控制
      buttonCommonControl() {
        if (this.isCompany) {
          return true
        } else {
          if (this.selectRows.every((item) => item.status === 1)) {
            return true
          } else {
            return false
          }
        }
      },
    },

    created() {
      //获取自定义保存 公司23 客户24
      CustomCache.getColumns(23, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnIdCompany = res.userCustomizeColumnId
          this.companyColumns = res.columns
          if (this.showType === 'company') {
            this.columns = res.columns
          }
        }
      })

      CustomCache.getColumns(24, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnIdCustomer = res.userCustomizeColumnId
          this.customerColumns = res.columns
          if (this.showType === 'customer') {
            this.columns = res.columns
          }
        }
      })
      if (!this.permissionsTab('Company:MsgNew')) {
        this.showType = 'customer'
      }
      CustomerManageInteractor.customerSourceList().then((res) => {
        this.customerSource = res
      })
      const currentType = sessionStorage.getItem('CURRENT_TAB')
      if (currentType) {
        this.showType = currentType
      }
      if (this.$route.query.type) {
        this.searchFormCustomer.type = this.$route.query.type * 1
      }
    },
    activated() {
      this.refreshData()
    },
    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.refreshData()
        }
      },

      'searchFormCompany.queryType': function (val) {
        // 获取数据
        this.pageNo = 1
        this.refreshData()
      },

      'searchFormCustomer.type': function (val) {
        // 获取数据
        this.pageNo = 1
        this.refreshData()
      },

      showType: {
        handler: function (val) {
          if (val === 'company') {
            this.columns = this.companyColumns
          } else {
            this.columns = this.customerColumns
          }
          // 获取数据
          this.searchFormCompany.queryType = 1
          this.pageNo = 1
          this.refreshData()
          sessionStorage.setItem('CURRENT_TAB', val)
        },
      },
      finallyColumns: {
        deep: true,
        handler: function (val) {
          this.tableKey = Math.random()
          this.selectRows = []
        },
      },
    },

    methods: {
      // 发邮件
      sendMail({ email }) {
        let routeData = this.$router.resolve({
          path: '/write',
          query: { from: 'newcustomer', email },
        })
        this.utils.openWindow(routeData.href)
      },
      // 审核确定回调
      saveClick(data) {
        this._blackCustomer(0, data)
      },
      // 关闭
      closeDrawer() {
        this.addRemark = false
      },
      // 备注
      setMark({ infoId, companyId }) {
        this.addRemark = true
        this.infoId = infoId
        this.companyId = companyId
      },
      // tabs权限控制
      permissionsTab(tab) {
        return this.permissions.some((item) => item == tab)
      },
      // 客户跟进
      clientFollowClick(row) {
        this.$refs['ClientManagefollowModel'].showAddEdit(row.infoId)
      },

      // 移除分管
      removeAppraise() {
        if (!this._isSelected()) return
        if (!this.rightCheck('removeCharge')) return
        const { infoId, email } = this.selectRows[0]
        this.$refs.removeAppraiseModel.showAddEdit(
          infoId,
          email,
          this.transferParams,
        )
      },
      // 选择人员
      showPerson() {
        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId: '',
            canSelectZeroUser: false,
            singleSelect: true,
          })
          .then((users) => {
            if (Array.isArray(users) && users.length > 0) {
              const { id, name, englishName } = users[0]
              this.searchFormCustomer.personalValue =
                this.lang === 'en' ? englishName : name
              this.searchFormCustomer.personalId = id
            }
          })
          .catch((err) => { })
      },
      // 搜索
      search() {
        this.pageNo = 1
        this.refreshData()
      },
      // 重置
      reset() {
        this.searchFormCompany = this.$options.data().searchFormCompany
        this.searchFormCustomer = this.$options.data().searchFormCustomer
        this.search()
      },
      // tabs权限控制
      permissionsTab(tab) {
        return this.permissions.some((item) => item == tab)
      },

      // 获取客户全部列表数据
      async companyFetchData() {
        this.tableData = []

        let data = await CustomerManageInteractor.getCompanyList({
          ...this.searchFormCompany,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          keyIds: getKeyIds(this),
        })

        this.tableData = data?.data || []
        this.total = data?.total || 0
      },
      // 获取客户列表请求参数
      _getCustomerReqData() {
        const reqData = {
          ...this.searchFormCustomer,
          pageLe: this.pageLe,
          pageNo: this.pageNo,
          keyIds: getKeyIds(this),
        }
        let personType2User = {
          1: 'belongerId',
          2: 'chargeId',
        }
        if (reqData.personType == 1) {
          reqData.belongerId = reqData.personalId
          reqData.chargeId = ''
        } else {
          reqData.belongerId = ''
          reqData.chargeId = reqData.personalId
        }
        return reqData
      },

      //获取我负责的列表数据
      async customerFetchData() {
        this.tableData = []
        const reqData = this._getCustomerReqData()
        let methodType =
          reqData.type == 1 ? 'getCustomerList' : 'getCustomerListAll'
        let response = await CustomerManageInteractor[methodType](reqData)
        if (response && response.code === '000000') {
          this.tableData = response.data.data
          this.total = response.data.total
        }
      },

      //刷新列表
      refreshData() {
        if (this.isCompany) {
          this.companyFetchData()
        } else {
          this.customerFetchData()
        }
      },
      // 备注弹框
      showRemarkInput(row, remarkType) {
        this.currentRow = row
        this.remarkType = remarkType
        this.remarkType = remarkType
        this.currentRow = row
        this.$refs.remarkinput.showRemark(
          remarkType == 2 ? row.mktRemark : row.businessRemark,
        )
      },
      // 添加备注
      async confirmRemark(remark) {
        //获取请求参数
        const { companyId, infoId } = this.currentRow
        // 调用接口更新备注
        let response
        if (this.remarkType == 1) {
          response = await CustomerManageInteractor.addRemark({
            companyId,
            creator: this.userInfo.userName,
            creatorEn: this.userInfo.englishName,
            infoId: this.showType === 'company' ? '' : infoId,
            remark,
            remarkType: this.showType === 'company' ? 1 : 2,
          })
        } else {
          response = await CustomerManageInteractor.infoRemark({
            infoId: infoId,
            remark,
            remarkType: this.remarkType == 2 ? 1 : 2,
          })
        }

        if (response && response.code === '000000') {
          this.$message.success(this.$t('reqmsg.$7'))
          // this.refreshData()
          this.currentRow[
            this.remarkType == 2 ? 'mktRemark' : 'businessRemark'
          ] = remark
        }
      },
      // 权限校验
      rightCheck(rightFlag) {
        const rightFlag2Message = {
          transferCustomer: 'cusManage.Transfer', //转让
          seasCustomer: 'cusManage.ThrownBackIntoTheOpenSea', //转入公海
          customerCharge: 'cusManage.InCharge', //分管
          removeCharge: 'cusDetail.removethebranch', // 移除分管
          toBlackCustomer: 'cusDetail.AddToBlacklist', //加入黑名单
          outBlackCustomer: 'cusDetail.RemovefromBlacklist', // 转出黑名单
        }
        const noRightRow = this.selectRows.find(
          (item) => item[rightFlag] === '0',
        )

        if (noRightRow) {
          const isEn = this.lang === 'en',
            msg = isEn
              ? `You don\'t have customer ${noRightRow.customerCode} ${this.$t(
                rightFlag2Message[rightFlag],
              )} right`
              : `您没有客户${noRightRow.customerCode} ${this.$t(
                rightFlag2Message[rightFlag],
              )}权限`
          this.$message.warning(msg)
          return false
        }
        return true
      },
      //礽回公海
      async throwPublicSeaClick() {
        if (!this._isSelected()) return
        if (!this.rightCheck('seasCustomer')) return
        let selectRows = this.selectRows
        let paramsArr = selectRows.map((item) => {
          return item.infoId
        })

        this.$confirm(this.$t('reqmsg.$17'), this.$t('other.info'), {
          confirmButtonText: this.$t('other.confirm'),
          cancelButtonText: this.$t('other.cancel'),
          type: 'warning',
        }).then(async () => {
          let response = await throwBackHighSeas({
            infoId: paramsArr,
            companyIds: this.transferParams.companyIds,
            defaultAddressUserIds: this.transferParams.defaultAddressUserIds,
          })
          if (response?.code === '000000') {
            this.$baseMessage(
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success',
            )
            this.refreshData()
          }
        })
      },
      //转让弹框
      async transferClick() {
        this.autoClose = true
        this.auditManageTags = []
        if (!this._isSelected()) return
        if (!this.rightCheck('transferCustomer')) return
        this.currentStr = 'belongerUser'
        this.$refs['UserChoose'].showAddEdit(
          'belongerUser',
          this.auditManageTags,
        )
      },
      //转让接口
      async transfer(userArr) {
        let selectInfoId = this.selectRows.map((item) => {
          return item.infoId
        })

        let userId = [userArr[0].id]
        let params = {
          infoId: selectInfoId,
          userIds: userId,
          infoLogDTOList: this.infoLogDTOList,
          companyIds: this.transferParams.companyIds,
          defaultAddressUserIds: this.transferParams.defaultAddressUserIds,
        }
        let response = await customerTransfer(params)
        if (response.code === '000000' && response.data === 1) {
          this.$baseMessage(
            // '转让成功',
            this.$t('reqmsg.$18'),
            'success',
            false,
            'erp-hey-message-success',
          )
          this.refreshData()
        } else {
          this.$baseMessage(
            // '转让失败',
            this.$t('reqmsg.$19'),
            'error',
            false,
            'erp-hey-message-error',
          )
        }
        this.autoClose = false
      },
      //分管弹框
      async assignClick() {
        this.auditManageTags = []
        if (!this._isSelected()) return
        if (!this.rightCheck('customerCharge')) return
        this.currentStr = 'assign'
        this.autoClose = true
        this.$refs['UserChoose'].showAddEdit('assign', this.auditManageTags)
      },
      //分管返回
      async assign(userArr) {
        let selectInfoId = []
        if (this.assignStr === 'single') {
          selectInfoId.push(this.infoId)
        } else {
          selectInfoId = this.selectRows.map((item) => {
            return item.infoId
          })
        }

        let selectUserId = userArr.map((item) => {
          return item.id
        })
        let params = {
          infoId: selectInfoId,
          userIds: selectUserId,
          infoLogDTOList: this.infoLogDTOList,
        }

        let response = await customerBranched(params)
        if (response.code === '000000' && response.data >= 1) {
          this.$baseMessage(
            this.$t('reqmsg.$20'),
            'success',
            false,
            'erp-hey-message-success',
          )
          this.refreshData()
        }
      },
      //组织架构返回
      chooseUser(userArr) {
        if (this.currentStr === 'belongerUser') {
          this.transfer(userArr)
        }
        if (this.currentStr === 'assign') {
          this.assign(userArr)
        }
      },

      // 搜索
      searchClick() {
        this.pageNo = 1
        this.refreshData()
      },
      _isSelected() {
        if (!this.selectRows.length) {
          const { isCompany, lang } = this
          const msg = isCompany
            ? lang === 'en'
              ? 'Please Select Company'
              : '请选择公司'
            : lang === 'en'
              ? 'Please Select Customer'
              : '请选择客户'
          this.$baseMessage(msg, 'warning', false, 'erp-hey-message-warning')
          return false
        }
        return true
      },
      // 转出黑名单
      async removeBlacklist(row) {
        if (!this._isSelected()) return

        let selectRows = this.selectRows

        if (this.isCompany) {
          if (!hasRight('CompanyOutBlackList')) {
            return this.$message.warning(
              this.lang === 'en'
                ? 'You have no authority to transfer out of the blacklist'
                : '您没有公司转出黑名单权限',
            )
          }
        }

        if (!this.isCompany && !this.rightCheck('outBlackCustomer')) return

        const { isCompany, lang } = this
        const msg = isCompany
          ? lang === 'en'
            ? 'Whether to transfer the company out of the blacklist?'
            : '是否将公司转出黑名单?'
          : lang === 'en'
            ? 'Whether to transfer the customer out of the blacklist?'
            : '是否将客户转出黑名单?',
          title = lang === 'en' ? 'Info' : '提示'
        this.$confirm(msg, title, {
          confirmButtonText: lang === 'en' ? 'Confirm' : '确定',
          cancelButtonText: lang === 'en' ? 'cancel' : '取消',
          type: 'warning',
        })
          .then(async () => {
            let response = isCompany
              ? await CustomerManageInteractor.outBlackCompany(
                selectRows.map((i) => i.companyId).join(','),
              )
              : await this._blackCustomer(1)

            if (response && response.code === '000000') {
              this.$baseMessage(
                this.$t('reqmsg.$7'),
                'success',
                false,
                'erp-hey-message-success',
              )

              this.refreshData()
            }
          })
          .catch(() => { })
      },

      //加入黑名单
      addBlacklist() {
        if (this._isSelected()) {
          // 公司加入黑名单
          if (this.isCompany) {
            if (!hasRight('CompanyToBlackList')) {
              return this.$message.warning(
                this.lang === 'en'
                  ? 'You do not have permission to join the blacklist'
                  : '您没有公司加入黑名单权限',
              )
            }
          }
          // 客户加入黑名单
          if (!this.isCompany && !this.rightCheck('toBlackCustomer')) return
          this.blackVisible = true
        }
      },
      // 确定事件
      async cancelSure() {
        this.$refs['blackForm'].validate(async (valid) => {
          if (valid) {
            // 客户加入需要审核
            if (!this.isCompany) {
              // 判断是否需要审核
              const configs =
                await CustomerConfigInteractor.getCustomerCommonConfigure(1)
              let needAudit =
                configs.find((item) => item.operationType == '1')?.needTick ===
                1
              if (needAudit) {
                let auditParams = {
                  auditType: 19,
                  status: 0,
                  tenantId: this.userInfo.tenantId,
                }
                return this.pubApi
                  .getAuditTemplates(auditParams)
                  .then((response) => {
                    if (!response.data.length) {
                      this.$baseMessage(
                        '请联系管理员配置审核模板',
                        'warning',
                        false,
                        'erp-hey-message-warning',
                      )
                    } else {
                      this.$refs['AuditChoose'].showAddEdit(auditParams)
                    }
                  })
              }
            }
            let response = this.isCompany
              ? await this._blackCompany()
              : await this._blackCustomer(0)
            if (response?.code === '000000') {
              this.$baseMessage(
                // '操作成功',
                this.$t('reqmsg.$7'),
                'success',
                false,
                'erp-hey-message-success',
              )
              this.blackVisible = false
              this.refreshData()
            }
          }
        })
      },

      // 公司加入黑名单
      _blackCompany() {
        const row = this.selectRows[0]
        const {
          companyAddress,
          companyId,
          companyName,
          faxNumber,
          postcode,
          remark,
          website,
        } = row
        const reqData = {
          blackRemark: this.blackForm.remarks,
          companyAddress,
          companyId,
          companyName,
          faxNumber,
          postcode,
          remark,
          website,
        }
        return CustomerManageInteractor.toBlackCompany(reqData)
      },
      // 客户加入黑名单
      _blackCustomer(operationType, auditObjectDTO) {
        operationType = operationType || 0
        let infoId = []

        this.selectRows.forEach((ele) => {
          infoId.push(ele.infoId)
        })

        let params = {
          infoId: infoId.join(','),
          remarks: this.blackForm.remarks,
          operationType, // 0 转入 1转出
          infoLogDTOList: this.infoLogDTOList,
        }
        if (typeof auditObjectDTO === 'object') {
          // 接审核
          params.auditObjectDTO = auditObjectDTO
        }
        customerBlackList(params).then((res) => {
          if (res && res.code === '000000') {
            this.refreshData()
            this.singleBlackRow = ''
          }
        })
        this.blackVisible = false
      },
      // 取消黑名单
      onCancel() {
        this.$refs['blackForm'].resetFields()
        this.blackVisible = false
      },

      //新增编辑客户
      addClick() {
        this.$refs['ClientManageAdd'].showAddEdit()
      },
      //公司详情(进入详情页编辑)
      showCompanyDetail(companyId, str) {
        this.$router.push({
          path: '/customer-manage/index/company-detail',
          query: {
            companyId,
          },
        })
      },

      // 客户详情
      showCustomerDetail(row, str) {
        if (![1, 2, 3, 4].includes(row.status)) {
          return
        }
        const { companyId, infoId } = row
        this.$router.push({
          path: '/customer-manage/index/customer-detail',
          query: {
            companyId,
            infoId,
          },
        })
      },

      // 公司编辑
      editCompany(companyId) {
        this.$router.push({
          path: '/customer-manage/index/company-edit',
          query: {
            companyId,
          },
        })
      },

      // 客户编辑
      editCustomer(row) {
        const { companyId, infoId, editCustomer } = row
        if (editCustomer != 1) {
          return this.$message.warning(
            this.lang === 'en'
              ? 'You do not have permission to edit'
              : '您没有编辑权限',
          )
        }
        this.$router.push({
          path: '/customer-manage/index/customer-edit',
          query: {
            companyId,
            infoId,
          },
        })
      },

      //自定义显示保存
      operateSave() {
        let customizeData = JSON.stringify(
          this.showType == 'company'
            ? this.companyColumns
            : this.customerColumns,
        )
        let params = {
          userCustomizeColumnId:
            this.showType == 'company'
              ? this.userCustomizeColumnIdCompany
              : this.userCustomizeColumnIdCustomer,
          menuType: this.showType == 'company' ? 23 : 24,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.$baseMessage(
              // '操作成功',
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success',
            )
            if (this.showType == 'company') {
              this.userCustomizeColumnIdCompany = res.data.userCustomizeColumnId
            } else {
              this.userCustomizeColumnIdCustomer =
                res.data.userCustomizeColumnId
            }

            return
          }
        })
      },

      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
        //操作他人权限seasCustomer，自己的客户——仍回公海
        this.isThrow = this.selectRows.every(
          (item) => item.seasCustomer === '1',
        )
        //操作他人权限transferCustomer，自己的客户——转让
        this.isTransfer = this.selectRows.every(
          (item) => item.transferCustomer === '1',
        )
        //操作他人权限customerCharge,自己的客户-分管
        this.isCharge = this.selectRows.every(
          (item) => item.customerCharge === '1',
        )
        // 转让、转入公海、移除分管参数处理
        if (this.selectRows.length) {
          let [companyIds, userIds] = [[], []]
          this.selectRows.forEach((item) => {
            companyIds.push(item.companyId)
            item.allCharge && userIds.push(item.allCharge.split(','))
            item.belongerId && userIds.push(item.belongerId)
          })
          this.transferParams = {
            companyIds: companyIds.join(','),
            defaultAddressUserIds: userIds.flat(1),
          }
        }
      },
      //客户类型匹配
      customerTypeStatus(val) {
        return this.$t(customerTypeStatusLang(val))
      },
      companyTypeStatusLang(val) {
        return this.$t(companyTypeStatusLang(val))
      },

      customerStatusLang(val) {
        return this.$t(customerStatusLang(val))
      },
    },
  }
</script>

<style scoped lang="scss">
  .top-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .text-ellipsis {
    width: 100%;

    span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .width-200 {
    width: 200px;
  }

  .prod-code {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }

  .black-span {
    white-space: nowrap;
    margin-right: 10px;
  }

  .search-wrapper {
    margin-top: 15px;
  }

  .w150 {
    width: 150px;
  }

  .w160 {
    width: 160px;
  }

  .w260 {
    width: 310px;
  }

  .w130 {
    width: 130px;
  }

  .mr10 {
    margin-right: 10px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .precls {
    color: #303133;
    font-family: 'PingFang', Arial, 'Microsoft YaHei', sans-serif;
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
