<template>
  <IndexCard title="出运委托" :loading="loading">
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="my-audit-item">
          <div>
            <div
              class="content"
              @click="clientFollowClick(item.shippingOrderId)"
            >
              <div class="left">
                <div class="top" v-if="item.type == 0">
                  <span :style="boldStyle.bold" class="ml5">
                    {{ item.number }}
                  </span>
                  <span class="ml5">发起的出库申请单被驳回</span>
                </div>
                <div class="top" v-else>
                  <span :style="boldStyle.bold" class="ml5">
                    {{ item.number }}
                  </span>

                  <span class="ml5">的货物已准备好，请即时处理！</span>
                </div>
              </div>
              <div class="right index-card-time">
                {{ item.rejectTime | timeFilter }}
              </div>
            </div>
            <p v-if="item.type == 1" class="padl15">
              装运日期:&nbsp;{{ item.extProperty }}
            </p>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import { HomeInteractor } from '@/core'
  import Detail from '@/views/audit-process/audit-manage/components/detail.vue'

  export default {
    name: 'shipmentEntrust',
    props: {},
    mixins: [mixins],
    components: {
      Detail,
    },
    // 组件状态值
    data() {
      return {
        list: [],
      }
    },
    computed: {},
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.loading = true
        HomeInteractor.shipmentEnquiry({
          pageNo: 1,
          pageLe: 9999,
          userId: this.userInfo.userId,
        }).then((res) => {
          if (res?.code === '000000') {
            this.list = res.data
          }
        })
        this.loading = false
      },
      //跳转出运委托详情
      clientFollowClick(id) {
        let routeData = this.$router.resolve({
          path: '/order/shipment-sheet-detail',
          query: {
            shipingOrderId: id,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style scoped lang="scss">
  .my-audit-item {
    .content {
      display: flex;
      cursor: pointer;
      .left {
        display: flex;
        flex-direction: column;
        color: #000;
        flex: 1;
        .top {
          margin-bottom: 10px;
          color: #000;
          word-break: break-all;
        }
        .bottom {
          color: #5e5e5e;
          font-size: 12px;
          .user {
            margin-right: 7px;
          }
        }
      }
      .right {
        padding-left: 10px;
        display: flex;
        align-items: flex-start;
      }
      .padl15 {
        padding-left: 15px;
      }
    }
  }
</style>
