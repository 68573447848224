<template>
  <div class="login">
    <el-alert v-show="alertVisible" style="margin: 20px 30%; width: 40%" :title="
        lang === 'en'
          ? 'The password is changed successfully. Please use the new password to log in again!'
          : '修改成功，请使用新密码重新登录！'
      " type="success" center effect="dark" :closable="false"></el-alert>

    <div class="logo">
      <img :src="
          lang === 'en'
            ? require('@/assets/login_images/erp_logo_en.png')
            : require('@/assets/login_images/erp_logo.png')
        " alt="" />
    </div>
    <div class="login-content">
      <div class="left_bg" :class="{ en: lang === 'en' }"></div>

      <!-- 用户登录 -->
      <div class="login-con" v-if="loginType === 0">
        <div class="box-card">
          <div class="title">{{ lang === 'zh' ? '用户登录' : 'Sign in' }}</div>
          <div class="text item">
            <el-form label-width="0px" :model="form" :rules="rules" ref="form">
              <el-form-item prop="loginName">
                <!-- 请输入手机号码 -->
                <el-input v-model="form.loginName" :placeholder="
                    lang === 'zh'
                      ? '请输入手机号码'
                      : 'Please enter your mobile number'
                  " v-focus clearable tabindex="1">
                  <i slot="prefix" class="iconfont el-icon-phone"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="passWord" class="mt25">
                <!-- 请输入密码 -->
                <el-input type="password" v-model="form.passWord" :placeholder="
                    lang === 'zh' ? '请输入密码' : 'Please input a password'
                  " clearable tabindex="2" show-password
                  onkeyup="this.value = this.value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,'')">
                  <i slot="prefix" class="iconfont el-icon-s-goods"></i>
                </el-input>
              </el-form-item>
            </el-form>
          </div>

          <!-- 登 录 -->
          <div class="login-btn mt25" @click="handleLogin">
            {{ lang === 'zh' ? '登 录' : 'Sign in' }}
          </div>
        </div>
      </div>

      <!-- 修改密码 -->
      <change-password v-else-if="loginType === 1" :lang="lang" @handleLogin="redirectLogin"></change-password>

      <!-- 安全验证 -->
      <safety-verification v-else :lang="lang" :phone="phone" :phoneUuid="phoneUuid"
        @handleLogin="redirectLogin"></safety-verification>
    </div>

    <!-- 版权所有 -->
    <div class="copy-right">
      Copyright © 2020-{{ `${new Date().getFullYear()}` }} Slight Worlds INC
      {{ lang === 'zh' ? '版权所有' : '' }}
      <span @click="goLink()">
        {{ lang === 'zh' ? '皖ICP备19024433号-1' : 'Anhui ICP No. 19024433-1' }}
      </span>
    </div>
  </div>
</template>

<script>
  import { JSEncrypt } from "encryptlong";
  import { systemInteractor } from '@/core'
  import { mapActions, mapGetters } from 'vuex'
  import changePassword from './components/change-password.vue'
  import safetyVerification from './components/safety-verification.vue'
  import { encrypt } from 'kits'
  import { publicKey } from '@/config'
  import { getCache, setTaskList } from '@/utils/task'
  import Notification from '@/components/notification'
  import { getPageWidth } from '@/views/mail/my-mail/utils/tool.js'
  export default {
    name: 'Login',
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    components: { changePassword, safetyVerification },
    beforeRouteLeave(to, from, next) {
      clearInterval(this.timer)
      next()
    },
    data() {
      const validateUsername = (rule, value, callback) => {
        // 请输入手机号码
        let msg =
          this.lang === 'zh'
            ? '请输入手机号码'
            : 'Please enter your mobile number'
        if (!value) callback(new Error(msg))
        else callback()
      }
      const validatePassword = (rule, value, callback) => {
        // 请输入密码
        if (!value || value.lenth < 2) {
          let msg =
            this.lang === 'zh' ? '请输入密码' : 'Please input a password'
          callback(new Error(msg))
        } else callback()
      }
      return {
        userId: '',
        phone: '',
        phoneUuid: '',
        form: {
          loginName: '',
          passWord: '',
        },
        rules: {
          loginName: [
            {
              required: true,
              trigger: 'blur',
              validator: validateUsername,
            },
          ],
          passWord: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
        },
        loading: false,
        passwordType: 'password',
        redirect: undefined,
        loginType: 0, // type 0：正常登录 1:修改密码 2:发送验证码
        timer: 0,
        alertVisible: false,
      }
    },
    computed: {
      ...mapGetters({
        title: 'settings/title',
      }),
      // 语言  默认中文
      lang() {
        return process.env.VUE_APP_LANG ? process.env.VUE_APP_LANG : 'zh'
      },
    },
    watch: {
      $route: {
        handler(route) {
          this.redirect = (route.query && route.query.redirect) || '/'
          Notification.closeAll()
        },
        immediate: true,
      },
    },
    created() {
      document.onkeydown = (e) => {
        let key = window.event.keyCode
        if (key === 13 && location.hash === '#/login') {
          this.handleLogin && this.handleLogin()
        }
      }
    },
    mounted() { },
    methods: {
      ...mapActions({
        login: 'user/login',
        setNotifiyLength: 'task/setNotifiyLength',
      }),
      // 判断当前登录用户是否发生变更
      isLoginUserChange(data) {
        try {
          // 获取当前登录用户信息
          let userInfo = localStorage.getItem('setUserInfo')
          if (!userInfo) {
            return false
          }
          userInfo = JSON.parse(userInfo)
          if (userInfo.userId !== data?.userId && data?.userId) {
            return true
          }
          return false
        } catch (err) {
          console.log(err)
        }
      },
      showTaskCenterNotify() {
        setTimeout(() => {
          const processTasks = getCache()
          this.setNotifiyLength(processTasks.length)
          if (processTasks.length > 0) {
            setTaskList(processTasks, this.$store)
          }
        }, 3000)
      },
      handlePassword() {
        this.passwordType === 'password'
          ? (this.passwordType = '')
          : (this.passwordType = 'password')
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleRoute() {
        return this.redirect === '/404' || this.redirect === '/403'
          ? '/'
          : this.redirect
      },
      // 登录
      handleLogin() {
        this.$store.commit('settings/changeLanguage', this.lang)
        localStorage.setItem('tokenTime', new Date().getTime())

        this.$refs?.form?.validate(async (valid) => {
          if (valid) {
            this.redirectLogin()
          }
        })
      },
      /**
       * 登录并跳转
       * @param {from}
       */
      async redirectLogin(form) {
        const self = this
        let [requestUrl, params, type] = [null, null, '']
        switch (this.loginType) {
          case 0:
            params = { ...this.form }
            requestUrl = systemInteractor.systemLogin
            break
          case 1:
            params = {
              userId: this.userId,
              password: form.password,
              confirmPassword: form.confirmPassword,
            }
            params = JSON.stringify({
              paramKey: encrypt(JSEncrypt, params, publicKey),
            })
            requestUrl = systemInteractor.firstLoginUpdatePwdLogin
            type = 'change'
            break
          case 2:
            params = {
              ...this.form,
              phone: this.phone,
              smsCode: form.smsCode,
            }
            requestUrl = systemInteractor.anomalySendSmsLogin
            break
        }
        const res = await requestUrl(params)
        if (res?.data && this.isLoginUserChange(res?.data)) {
          // 登录用户发生变更
          const BC = new BroadcastChannel("LOGIN");
          BC.postMessage("login-user-change");
        }
        this.loginType = res?.data?.type || 0
        if (res?.code === '000000') {
          if (type === 'change') {
            this.alertVisible = true
            setTimeout(() => {
              this.alertVisible = false
            }, 3000)
          }

          if (this.loginType === 0) {
            // 正常登录
            try {
              await this.login({
                userInfo: this.form,
                ctx: this,
                fn: this.redirectRoute.bind(this),
                res: res.data,
              }).then((res) => {
                return Promise.resolve()
                self.showTaskCenterNotify()
              })
            } finally {
            }
          } else if (this.loginType === 1) {
            // 修改密码
            this.userId = res.data?.userId
          } else {
            // 安全验证
            this.phone = res.data?.phone
            this.phoneUuid = res.data?.phoneUuid
          }
          // 获取邮件配置
          getPageWidth()
        }
      },
      redirectRoute(route) {
        this.$router.push({
          path: route[0]?.children ? route[0].children[0].routePath : '',
        })
      },
      // 备案跳转
      goLink() {
        window.open('https://beian.miit.gov.cn', '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .login {
    width: 100%;
    min-width: 960px;
    min-height: 550px;
    height: 100% !important;
    background: #ffffff;
    position: relative;
    overflow: auto;

    .logo {
      height: 32px;
      margin-top: 20px;
      margin-left: 30px;

      img {
        width: 122px;
      }
    }

    .login-content {
      width: 960px;
      height: 464px;
      position: absolute;
      display: flex;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
      box-shadow: 0px 2px 11px 6px rgba(234, 234, 234, 0.5);
      border-radius: 4px;
      overflow: hidden;
    }

    .left_bg {
      width: 495px;
      height: 100%;
      position: relative;
      background-image: url('../../assets/login_images/login-left.png');
      background-size: cover;
      background-position: center;

      &.en {
        background-image: url('../../assets/login_images/login-left-en.png');
      }
    }

    &-con {
      width: 465px;
      height: 100%;
      position: relative;
      background: #fff;
      padding: 15px 40px 0;

      &-header {
        font-size: 16px;
        font-weight: 300;
        text-align: center;
      }

      .form-con {
        padding: 10px 0 0;
      }

      .login-tip {
        font-size: 10px;
        text-align: center;
        color: #c3c3c3;
      }

      .login-btn {
        width: 100%;
        height: 40px;
        background: #236ce9;
        border-radius: 4px;
        font-size: 14px;
        font-family: 'PingFang Bold';
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .title {
    font-size: 21px;
    margin-bottom: 30px;
    font-family: 'PingFang Bold';
    color: #282c34;
    padding-bottom: 13px;
    border-bottom: 1px solid #282c34;
  }

  .copy-right {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #a4b4d4;
    line-height: 34px;
    border-top: 1px solid #d0d7de;

    span {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  ::v-deep {
    .el-input__inner {
      height: 46px !important;
      background: #ffffff;
      border-radius: 2.5px;
      border: 1px solid #dcdfe6;
      line-height: 46px;

      &::-webkit-input-placeholder {
        color: #777;
      }

      &::-moz-input-placeholder {
        color: #777;
      }

      &::-ms-input-placeholder {
        color: #777;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
      }
    }

    .el-input__prefix {
      line-height: 46px;
      padding: 0 5px;

      i {
        font-size: 18px;
        line-height: inherit;
        color: #999;
      }
    }
  }
</style>
