<!--
  功能：空组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月26日 14:14:19
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="index-empty-wrapper">
    <!-- {{ text }} -->
    <DataKanpanEmpty />
  </div>
</template>

<script>
import DataKanpanEmpty from './data-kanpan-empty'
export default {
  name: 'IndexCardEmpty',
  props: {
    text: {
      type: String,
      default: '暂无数据',
    },
  },

  components: {
    DataKanpanEmpty,
  },
}
</script> 

<style scoped lang="scss">
.index-empty-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
