<template>
  <el-dialog
    title="科目设置"
    :visible.sync="dialogVisible"
    width="400px"
    @close="close"
    class="dialog-middle"
  >
    <el-form
      :model="form"
      label-width="80px"
      :rules="rules"
      size="small"
      ref="form"
    >
      <el-form-item label="科目名称" prop="financeName">
        <el-input v-model="form.financeName"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="close">取消</el-button>
      <el-button type="primary" size="mini" @click="submit" :loading="loading">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  FinanceSubjectAdd,
  FinanceSubjectUpdate,
} from '@/api/finance/finance-setting'
export default {
  name: 'subjectSetting',
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        financeName: '', // 中文名
        financeSetupId: null,
      },
      rules: {
        financeName: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    }
  },
  created() {},
  methods: {
    //打开弹框
    showDialog(row) {
      this.dialogVisible = true
      if (row) {
        this.form = Object.assign({}, row)
      } else {
        this.form.financeSetupId = null
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    //清空表单
    close() {
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let Method
          if (this.form.financeSetupId) {
            Method = FinanceSubjectUpdate
          } else {
            Method = FinanceSubjectAdd
          }
          this.loading = true
          Method(this.form)
            .then((res) => {
              this.loading = false
              if (res.code == '000000') {
                this.$message.success(
                  this.form.financeSetupId ? '修改成功！' : '新增成功！'
                )
                this.$emit('success')
                this.close()
              }
            })
            .catch((err) => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
