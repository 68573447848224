<template>
  <div :class="['p20', 'add-box', detailId ? 'audit-box' : '']">
    <div class="base-wrapper">
      <el-page-header @back="
        () => {
          $router.go(-1)
        }
      " :content="content" class="page-header-box" title="返回" v-if="!detailId"></el-page-header>
      <h4>基本信息</h4>

      <StockCheckEditBaseInfo class="mt10" v-if="detailId" :detail="addForm"></StockCheckEditBaseInfo>
      <el-form v-else :model="addForm" :rules="rules" ref="addForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="盘点仓库" prop="warehouseName " label-width="100px">
              <el-select v-model="addForm.warehouseName" placeholder="请选择" disabled>
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="盘点人" label-width="100px">
              <el-input v-model="addForm.takingUser" placeholder="请选择" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建人" label-width="100px">
              <el-input v-model="addForm.creator" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="6">
            <el-form-item label="盘点方式" prop="takingRange" label-width="100px">
              <el-select v-model="addForm.takingRange" placeholder="请选择" disabled>
                <el-option v-for="item in takingRangeList" :key="item.code" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="库存分类" prop="stockUsableType" v-if="addForm.takingRange === 1" label-width="100px">
              <el-select v-model="addForm.stockUsableType" placeholder="请选择" class="w100" disabled>
                <el-option v-for="item in stockUsableTypeList" :key="item.code" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="18">
            <el-form-item label="备注" label-width="100px">
              <el-input type="textarea" v-model="addForm.remark" :autosize="{ minRows: 2, maxRows: 4 }" maxlength="500"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-form :model="addForm" ref="addForm">
      <div class="mt10">
        <div class="pro-title">
          <h4 class="mt10 ">盘点产品</h4>
          <el-button @click="selPro" class="pro-button" v-if="!detailId">
            导入盘点结果
          </el-button>
        </div>


        <el-table :data="addForm.stockTakingProducts" border style="width: 100%" :key="tableKey"
          :span-method="objectSpanMethod" ref="proTable">
          <el-table-column align="center" label="产品图片" fixed="left">
            <template slot-scope="scope">
              <erp-image :imgs="scope.row.productImage"></erp-image>
            </template>
          </el-table-column>
          <el-table-column align="center" label="产品编码" prop="skuId" fixed="left"></el-table-column>
          <el-table-column align="center" label="产品名称" prop="productName" fixed="left"></el-table-column>
          <el-table-column align="center" label="规格" prop="productSpec" fixed="left"></el-table-column>
          <el-table-column align="center" label="产品类别" prop="productType"></el-table-column>
          <el-table-column align="center" label="是否可用库存" prop="usable" width="120">
            <template slot-scope="scope">
              {{ scope.row.usable == 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="库位号" prop="fullLocationNumber"></el-table-column>
          <el-table-column align="center" label="建单库位数" prop="num" width="100"></el-table-column>
          <el-table-column align="center" label="可用量" prop="enableNum" width="120">
            <template slot="header">
              <div>
                <span>可用库位数</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    包含该仓库所有销售出库待出库数量、锁定量及该库位可用库存。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="不可用量" prop="disableNum" width="140">

            <template slot="header">
              <div>
                <span>不可用库位数</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    该库位不可用库存。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="盘点数量" label-class-name="required-th" width="100">
            <template slot-scope="scope">
              <el-form-item v-if="!detailId" :prop="'stockTakingProducts.' + scope.$index + '.takingNum'" :rules="[
                  {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model="scope.row.takingNum" placeholder="请输入"
                  @change="takingNumInput(scope.$index, scope.row)"></el-input>
              </el-form-item>

              <span v-else>{{ scope.row.takingNum || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="差异数量" prop="takingDiff">
            <template slot-scope="scope">
              <span :style="
                  scope.row.takingDiff > 0
                    ? 'color:green'
                    : scope.row.takingDiff < 0
                    ? 'color:red'
                    : ''
                ">
                {{ scope.row.takingDiff }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="备注" show-overflow-tooltip width="150">
            <template slot-scope="scope">
              <el-form-item v-if="!detailId" :prop="'stockTakingProducts.' + scope.$index + '.remark'" :rules="[{
                required: scope.row.takingDiff != 0,
                message:'请填写备注'
              }]">
                <el-input placeholder="请输入" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" maxlength="500"
                  show-word-limit v-model="scope.row.remark" @change="changeRemark(scope.row)"></el-input>
              </el-form-item>
              <span v-else>{{ scope.row.remark || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="实际出库/入库数量" width="150">
            <template slot-scope="scope">
              {{abs(scope.row.takingDiff)}}
            </template>
          </el-table-column>
          <el-table-column align="center" width="200">
            <template slot="header">
              <div>
                <span>入库批次</span>
                <el-tooltip class="item" effect="dark" content="根据入库时间从小到大排列，最先入库批次在最上面。" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="lot-wrapper" v-if="scope.row.takingDiff < 0">
                <el-input disabled v-model="scope.row.stockLotNo"></el-input>
                <i class="el-icon-circle-plus-outline" @click="lotSel(scope.row)" v-if="!detailId"></i>
              </div>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="批次出库/入库数量" show-overflow-tooltip width="200">
            <template slot-scope="scope">
              {{scope.row.takingDiff > 0 ? scope.row.takingDiff : scope.row.lotNumber}}
            </template>
          </el-table-column>
          <el-table-column align="center" show-overflow-tooltip width="180" prop="price">
            <template slot="header">
              <div>
                <span>出库/入库单价</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">1. 若盘亏则带出对应批次的单价，不可修改；<br />2. 若盘盈且该产品在该仓库有库存，则取该产品在当前仓库最低单价；<br />
                    3. 若盘盈且该产品在该仓库无库存，则取该产品在当前仓库最近一次出库单价。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
              <el-input v-if="scope.row.takingDiff > 0" v-model="scope.row.price"
                @change="changePrice(scope.row)"></el-input>
              <span v-else> {{((scope.row.price || scope.row.price === 0 )&&
                scope.row.price.toFixed(3))|| '--'}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="出库/入库成本" show-overflow-tooltip width="130">
            <template slot-scope="scope">
              {{(scope.row.totalPrice && scope.row.totalPrice.toFixed(3))|| '--'}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="采购单号" show-overflow-tooltip width="150">
            <template slot-scope="scope">
              <span class="page-link-noline" v-if="scope.row.purchaseNumber"
                @click="goTopurchaseDetail(scope.row.purchaseNumber)">{{scope.row.purchaseNumber}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="采购开发" show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.buyer || '-'}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="出库/入库备注" show-overflow-tooltip width="120">
            <template slot-scope="scope">
              <el-input placeholder="请输入" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" maxlength="500"
                show-word-limit v-model="scope.row.amendRemark" :disabled="!!detailId"
                @change="changeAmendRemark(scope.row)"></el-input>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <div class="remark mt20 remark-wrapper">
        <p class="mb5" v-if="!detailId">盈亏说明</p>
        <el-form-item prop="takingResultDesc" v-if="!detailId">
          <el-input type="textarea" v-model="addForm.takingResultDesc" :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="500" show-word-limit></el-input>
        </el-form-item>

        <p v-else style="display: flex">
          <em style="width: 85px; display: block; white-space: nowrap">
            盈亏说明：
          </em>
          {{ addForm.takingResultDesc || '--' }}
        </p>
      </div>
    </el-form>
    <div class="oper-sticky">
      <el-button type="primary" class="mr20" @click="submit">确认</el-button>
      <el-button class="ml10" @click="cancel">取消</el-button>
    </div>
    <CheckResultImport ref="CheckResultImport" :stockTakingId="stockTakingId" @importSuccess="importSuccess" />
    <!-- 审核选择 -->
    <AuditChoose ref="AuditChoose" @auditData="saveAuditClick2" @auditData1="saveAuditClick2" />
    <!-- 批次选择 -->
    <LotBatchSel ref="lotBatchSel" @lotlist-set="setLocList" source="stockCheck"></LotBatchSel>
    <!-- 左右滑动 -->
    <span class="left"> <i class="el-icon-caret-left" @click="scroll('scrollLeft')"></i></span>
    <span class="right"> <i class="el-icon-caret-right" @click="scroll('scrollRight')"></i></span>

  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { deepCopy, getRemainingHeight } from 'kits'
  import CheckResultImport from './components/checkResultImport.vue'
  import AuditChoose from '@/components/AuditChoose'
  import { mixin } from './help/mixins'
  import { StoreCheck } from '@/core'
  import StockCheckEditBaseInfo from './components/stock-check-edit-base-info'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import LotBatchSel from '@/components/lot-batch-sel/index.vue'

  const mergeColumns = [
    '实际出库/入库数量',
    '产品图片',
    '产品编码',
    '产品名称',
    '规格',
    '产品类别',
    '是否可用库存',
    '库位号',
    '建单库位数',
    '可用量',
    '不可用量',
    '盘点数量',
    '差异数量',
    '备注'
  ]



  export default {
    components: { CheckResultImport, AuditChoose, StockCheckEditBaseInfo, LotBatchSel },
    mixins: [mixin],
    props: {
      detailId: {
        //业务id
        type: String,
        default: '',
      },
    },
    data() {
      return {
        addForm: {},
        rules: {},
        tableData: [],
        stockTakingId: '',
        tableKey: Math.random(),
        spanArr: [], //合并参数
        pos: 0, //合并参数
        takingRangeList: Object.freeze([{
          code: 1,
          name: '全部盘点'
        }, {
          code: 0,
          name: '部分盘点'
        }]),
        stockUsableTypeList: Object.freeze([{
          code: 1,
          name: '可用库存'
        }, {
          code: 0,
          name: '不可用库存'
        }, {
          code: 2,
          name: '全部库存'
        }]),

      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      content() {
        return this.$route.query.type == 'result' ? '盘点结果' : '盘点编辑'
      },
      resultDescescStatus() {
        let resultDescescStatus = this.addForm.stockTakingProducts?.every(
          (it) => {
            return it.takingDiff == 0
          }
        )
        return !resultDescescStatus && !this.addForm.takingResultDesc

      },
    },
    created() {
      this.amendRemarkCache = {}  //缓存备注和价格
      this.priceCache = {}//缓存备注和价格
      this.addForm.creater = this.userInfo.userName
      this.stockTakingId = this.$route.query.stockTakingId || this.detailId
      // if (this.$route.query.type == 'result' || this.detailId) {
      if (this.$route.query.type == 'result') {
        this.initResult()
      } else {
        if (this.detailId) {  // 审核
          this.initEdit()
        } else {
          this.initModify()
        }

      }
    },
    methods: {

      scroll(scrollDirection) {
        var tableContainer = document.querySelector('.el-table__body-wrapper');
        let tableEl = this.$refs.proTable.bodyWrapper
        if (tableEl) {
          if (scrollDirection == 'scrollLeft') {
            tableEl.scrollLeft -= 100; // 根据需要调整滚动的距离
          } else {
            tableEl.scrollLeft += 100; // 根据需要调整滚动的距离
          }
        }
      },
      changePrice(product) {
        let price = product.price * 1
        if (isNaN(price)) {
          product.totalPrice = 0
        } else {
          product.totalPrice = price * product.takingDiff
        }
        let { stockTakingProductId, stockLotNo } = product
        this.priceCache[stockTakingProductId + (stockLotNo || '')] = product.price
      },
      abs(val) {
        if (typeof val === 'number') {
          return Math.abs(val)
        }
        return '-'
      },
      setLocList(list) {
        // 加延时异步处理，确保侧滑页能及时关闭
        setTimeout(() => {
          list = list?.map(i => {

            i.stockLotNo = i.stockLotNo || i.lotNo
            return i
          })
          let initProductItem = this.initTakingProductVOList.find(s => s.stockTakingProductId === this.currentLotRow.stockTakingProductId)
          if (initProductItem) {
            initProductItem.amends = list
          }
          this.addForm.stockTakingProducts = this.formatTableData(deepCopy(this.initTakingProductVOList))
          this.resetCombineParams()
          this.getSpanArr(this.addForm.stockTakingProducts)
          this.tableKey = Math.random()
        }, 300)

      },
      // 选择批次
      lotSel(row) {

        let takingDiff = Math.abs(row.takingDiff)
        if (Number.isNaN(takingDiff)) {
          return this.$message.warning('请输入盘点数量')
        }
        this.currentLotRow = row //当前待设置的批次行
        if (!row.amends) {
          this.$set(row, 'amends', [])
        } else {
          row.amends = row.amends.map(i => {
            i.lotNo = i.stockLotNo
            i.lotNumber = i.lotNumber || i.num
            return i
          })
        }
        console.log(row.amends)
        // 添加orderProductUniqueId
        this.$refs.lotBatchSel.stockCheckshow({
          list: deepCopy(row.amends), //拷贝
          sku: row.skuId,
          orderProductUniqueId: row.orderProductUniqueId,
          productName: row.productName,
          warehouseId: row.warehouseId,
          warehouseLocationId: row.warehouseLocationId,
          usable: row.usable,
          applyNum: takingDiff,
        })
      },
      // 跳转采购订单详情
      goTopurchaseDetail(purchaseOrderNumber) {
        navigateTo(this, {
          pageType: PAGE_TYPES.purchaseOrderDetail,
          orderNumber: purchaseOrderNumber,
          roleJudge: false,
        })
      },
      /**
     * 合并单元格objectSpanMethod
     */
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (mergeColumns.includes(column.label)) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      formatTableData(list, field = 'amends') {
        let self = this
        list = deepCopy(list)
        let tableData = []
        for (let i = 0; i < list.length; i++) {

          let item = list[i]
          let key = `${list[i].stockTakingProductId}${list[i].stockLotNo || ''}`
          if (item[field] && item[field].length !== 0) {
            item[field].forEach((lotItem) => {
              let lotNumber = lotItem.lotNumber || lotItem.num
              let price = this.priceCache[key] || lotItem.price
              let totalPrice = lotNumber * price
              if (isNaN(totalPrice)) {
                totalPrice = 0
              }

              tableData.push({
                ...lotItem,  //放在前面避免num属性覆盖
                amendNum: lotItem.num,
                ...item,
                num: item.num,
                stockTakingId: item.stockTakingId,
                stockTakingProductId: item.stockTakingProductId,
                lotNumber,
                totalPrice,
                amendRemark: this.amendRemarkCache[key] || lotItem.remark || item.remark,  //重新执行表格合并时出库入库备注处理
                lotNo: lotItem.lotNo || lotItem.stockLotNo,  // 导入时选中批次回显
                price: this.priceCache[key] || lotItem.price
              })
            })
          } else {
            tableData.push({
              ...item,
              amendRemark: this.amendRemarkCache[key] || item.remark,
              price: this.priceCache[key] || item.price
            })
          }
        }
        return tableData
      },
      /**
       * 合并单元格getSpanArr
       */
      getSpanArr(list) {
        for (var i = 0; i < list.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            // 添加orderProductUniqueId
            if (list[i].stockTakingProductId && list[i - 1].stockTakingProductId &&
              `${list[i].stockTakingProductId}` ===
              `${list[i - 1].stockTakingProductId}`
            ) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },

      // 重置合并相关参数
      resetCombineParams() {
        this.spanArr = []
        this.pos = 0
      },


      // 初始化信息
      async initResult() {
        let res = await StoreCheck.toTaking(this.stockTakingId)
        // 记住初始产品数据initTakingProductVOList
        this.initTakingProductVOList = deepCopy(res?.data?.stockTakingProducts || [])  // 记录下初始的产品数据
        res.data.stockTakingProducts = this.formatTableData(res.data.stockTakingProducts)
        this.addForm = res.data
        this.getSpanArr(this.addForm.stockTakingProducts)
      },
      async initEdit() {
        let res = await StoreCheck.getDetailById(this.stockTakingId)
        this.addForm = res?.data
        this.addForm.stockTakingProducts = this.formatTableData(res?.data?.takingProductVOList)
        this.getSpanArr(this.addForm.stockTakingProducts)
        delete this.addForm.takingProductVOList
      },

      async initModify() {
        let res = await StoreCheck.toModifyApi(this.stockTakingId)
        this.addForm = res.data
        this.initTakingProductVOList = deepCopy(res?.data?.stockTakingProducts || [])
        this.addForm.stockTakingProducts = this.formatTableData(res.data.stockTakingProducts)
        this.getSpanArr(this.addForm.stockTakingProducts)
        delete this.addForm.takingProductVOList
      },


      // 选择产品
      selPro() {
        this.$refs.CheckResultImport.show()
      },
      // 选择人员
      choosePeople() { },

      // 保存

      submit() {

        this.$refs['addForm'].validate((valid) => {
          if (valid) {
            let data = deepCopy(this.addForm)
            let tableData = this.getSaveTableData(data.stockTakingProducts)
            if (this.checkSaveTableData(tableData)) {
              this.submitResult()
            }

          }
        })


      },
      submitResult() {
        // 显示审核
        let auditParams = {
          auditType: 21,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(auditParams).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(auditParams)
          }
        })
      },
      async submitEdit() {
        let res = await StoreCheck.updateStockTakingPO(this.addForm)
        if (res?.code === '000000') {
          this.$message.success('操作成功')
          this.$router.push('/stock-center/stock-check/index')
        }
      },
      // 取消
      cancel() {
        this.$router.push('/stock-center/stock-check/index')
      },
      // 审核
      async saveAuditClick2(auditData) {
        let data = deepCopy(this.addForm)
        data.stockTakingProducts = this.getSaveTableData(data.stockTakingProducts)
        let res = await StoreCheck.takingCommit(auditData, data)
        if (res?.code === '000000') {
          this.$message.success('操作成功')
          this.$router.push('/stock-center/stock-check/index')
        }
      },
      // 备注修改
      changeRemark(row) {
        let tableData = this.addForm.stockTakingProducts, { stockTakingProductId, stockLotNo } = row
        tableData.forEach(td => {
          if (td.stockTakingProductId == stockTakingProductId) {
            this.$set(td, 'amendRemark', row.remark)
          }
        })
        this.changeInitProductInfo(stockTakingProductId, 'remark', row.remark)
      },
      changeAmendRemark(row) {

        let { stockTakingProductId, stockLotNo } = row
        this.amendRemarkCache[stockTakingProductId + (stockLotNo || '')] = row.amendRemark
      },

      // 修改初始产品相关数据信息
      changeInitProductInfo(stockTakingProductId, field, val) {
        let initTakingProductVOList = this.initTakingProductVOList
        let index = initTakingProductVOList.findIndex(
          (it) => it.stockTakingProductId === stockTakingProductId
        )
        if (index > -1) {   //
          initTakingProductVOList[index][field] = val
        }
      },
      // 盘点数量计算差异数量
      takingNumInput(index, row) {
        this.$set(
          row,
          'takingNum',
          parseInt(row.takingNum > 0 ? row.takingNum : 0)
        )
        let takingDiff = Number(row.takingNum) - Number(row[row.usable ? 'enableNum' : 'disableNum'])
        this.$set(row, 'takingDiff', takingDiff)
        // 修改原始数据信息 盘点数量
        this.changeInitProductInfo(row.stockTakingProductId, 'takingNum', row.takingNum)
        this.changeInitProductInfo(row.stockTakingProductId, 'takingDiff', takingDiff)
        if (takingDiff > 0) {
          this.inbound(row, takingDiff)
        } else if (takingDiff == 0) {
          this.eql(row, takingDiff)
        } else {
          this.outbound(row, takingDiff)
        }
        if (takingDiff == 0) {
          this.$refs['addForm'].clearValidate([
            'takingResultDesc',
            `stockTakingProducts.${index}.remark`,
          ])
        }
      },

      checkSaveTableData(tableData) {
        let checkResult = true
        // 如果tableData中存在某项product的talkingDiff小于0，product.amends数组中stockLotNo不存在为或为空，将checkResult设置为false，跳出循环
        tableData.forEach((item) => {
          if (item.takingDiff < 0) {
            if (!item.amends || item.amends?.some(amend => !amend.stockLotNo)) {
              checkResult = false
            }
          }
        })
        if (!checkResult) {
          this.$message.warning('批次号不能为空')
        }
        return checkResult
      },

      // 获取确认时产品数据
      getSaveTableData() {
        let tableData = this.addForm.stockTakingProducts
        let saveTableData = []
        let amendsKey2ProKey = {
          'buyer': "buyer",
          'buyerId': "buyerId",
          'price': 'price',
          'purchaseNumber': 'purchaseNumber',
          'remark': 'amendRemark',
          'stockLotId': 'stockLotId',
          'stockLotNo': 'stockLotNo',
          'totalPrice': 'totalPrice'
        }

        tableData.forEach((item) => {
          let talkingDiff = item.takingDiff
          let stockTakingProductId = item.stockTakingProductId
          let stockTakingProductIdPro = saveTableData.find(s => s.stockTakingProductId === stockTakingProductId)
          if (stockTakingProductIdPro) {  // 已经添加了
            let obj = {}
            Reflect.ownKeys(amendsKey2ProKey).forEach(key => {
              obj[key] = item[amendsKey2ProKey[key]]
            })
            if (talkingDiff > 0) {
              obj.num = item.amendNum
            } else {
              obj.num = item.lotNumber
            }
            stockTakingProductIdPro.amends.push(obj)
          } else {

            item.amends = []
            let obj = {}
            Reflect.ownKeys(amendsKey2ProKey).forEach(key => {
              obj[key] = item[amendsKey2ProKey[key]]
            })
            if (talkingDiff > 0) {
              obj.num = item.amendNum
            } else if (talkingDiff === 0) {
              obj = {
                buyer: "",
                buyerId: "",
                num: "",
              }
            } else {
              obj.num = item.lotNumber
            }
            item.amends.push(obj)
            saveTableData.push(item)
          }
        })

        return saveTableData
      },
      // 重置数据
      resetProductAttributes(pro) {
        let attributes = [
          'price',
          'totalPrice',
          'purchaseNumber',
          'buyer'
        ]
        attributes.forEach(attr => {
          pro[attr] = ''
        })
      },
      eql(product, takingDiff) {
        const { stockTakingProductId } = product
        let initProductItem = this.initTakingProductVOList.find(s => s.stockTakingProductId === stockTakingProductId)
        initProductItem.amends = null
        this.addForm.stockTakingProducts = this.formatTableData(deepCopy(this.initTakingProductVOList))
        this.resetCombineParams()
        this.getSpanArr(this.addForm.stockTakingProducts)
        this.tableKey = Math.random()

      },
      // 盘盈入库
      inbound(product, takingDiff) {
        const { stockTakingProductId, skuId } = product
        StoreCheck.findPriceWhenProfitApi({
          num: takingDiff,
          sku: skuId,
          stockTakingProductId,
          warehouseId: this.addForm.warehouseId
        }).then(res => {
          if (res?.code === '000000') {
            let initProductItem = this.initTakingProductVOList.find(s => s.stockTakingProductId === stockTakingProductId)
            if (res?.data) {
              initProductItem.amends = [res.data]
              this.addForm.stockTakingProducts = this.formatTableData(deepCopy(this.initTakingProductVOList))
              this.resetCombineParams()
              this.getSpanArr(this.addForm.stockTakingProducts)
              this.tableKey = Math.random()
            }
          }
        })
      },
      // 盘亏出库
      async outbound(product, takingDiff) {
        let { stockTakingProductId, locationNumber, skuId: sku, usable, warehouseId, warehouseLocationId } = product
        StoreCheck.lotRecommendWhenLossApi({
          keyId: stockTakingProductId,
          locationNumber,
          num: Math.abs(takingDiff),
          sku,
          usable,
          warehouseId,
          warehouseLocationId
        }).then(res => {
          if (res?.code === '000000') {
            let initProductIndex = this.initTakingProductVOList.findIndex(s => s.stockTakingProductId === stockTakingProductId)
            if (initProductIndex > -1) {
              if (Array.isArray(res.data)) {
                res.data = res.data.map(s => {
                  s.lotNumber = s.num
                  s.lotNo = s.stockLotNo   // 单号处理
                  return s
                })
              }
              this.initTakingProductVOList[initProductIndex].amends = res.data || []
              // 重新进行表格数据合并
              this.addForm.stockTakingProducts = this.formatTableData(this.initTakingProductVOList)
              this.resetCombineParams()
              this.getSpanArr(this.addForm.stockTakingProducts)
              this.tableKey = Math.random()
            }
          }
        })
      },
      // 导入成功。替换产品列表
      importSuccess(list) {
        this.initTakingProductVOList = deepCopy(list || [])
        this.addForm.stockTakingProducts = this.formatTableData(this.handleImportAmendRemark(list))
        this.resetCombineParams()
        this.getSpanArr(this.addForm.stockTakingProducts)
        this.tableKey = Math.random()

      },

      // 处理导入时出库/入库备注
      handleImportAmendRemark(list) {
        if (Array.isArray(list)) {
          list = deepCopy(list)
          list = list.map(i => {
            if (i.amends) {
              i.amends = i.amends.map(s => {
                s.remark = i.remark
                return s
              })
            }
            return i
          })
        }

        return list
      },
    },
  }
</script>
<style lang="scss" scoped>
  .oper-sticky {
    width: calc(100% - 105px);
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #ccc;
    height: 80px;
    align-items: center;
    background-color: #fff;
  }

  .pro-button {
    border-color: rgb(40, 130, 255);
    color: rgb(40, 130, 255);
    margin: 15px 0;
  }

  .add-box {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 145px);
    margin-bottom: 100px !important;

    &.audit-box {
      height: auto !important;
    }
  }

  em {
    color: #000;
  }

  .el-form-item {
    margin-bottom: 0px !important;
  }

  .lot-wrapper {
    display: flex;
    align-items: center;

    .el-input {
      flex: 1;
    }

    .el-icon-circle-plus-outline {
      margin-left: 5px;
      cursor: pointer;
      font-size: 20px;
      color: rgb(40, 130, 255);
    }

  }

  .left,
  .right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: inline-block;
    display: inline-block;
    border: 1px solid #909399;
    z-index: 1000;
    border-radius: 50%;
  }

  .el-icon-caret-right,
  .el-icon-caret-left {

    font-size: 36px;
    color: rgb(40, 130, 255);


  }

  .right {
    right: 20px;
  }

  .left {
    left: 7px;
  }
</style>
