<template>
  <!-- <div class="print-box" id="PRINTBOX"> -->
  <printWrapper id="PRINTBOXPRINT" style="width: 854px; padding: 0 10px; margin: 0 auto">
    <div class="print" v-for="(detail, k) in offerList" :key="k">
      <OfferSheetContent isPrint :rise="rise" :offerData="detail" />
    </div>

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[imgUrl]" />
  </printWrapper>
  <!-- </div> -->
</template>

<script>
  import { getPriceSheetDetail, printOrderPriceSheet } from '@/api/order.js'

  // import { transpotwayList } from '@/api/shipment/shipment-plan'
  import OfferSheetContent from './components/offer-sheet-content'
  import printWrapper from '@/views/print/printWrapper'
  import handleOtherFee, { getproductStyle } from './helper/util'
  import { getNowDate } from '@/utils/index'
  import { OrderInteractor } from '@/core'
  export default {
    props: ['offerSheetInfo'],
    data() {
      return {
        isBatch: false,
        isCache: null, // 1:新增编辑预览入口, 0:列表预览入口
        offerSetString: '',
        priceSheetCode: '',
        enquiryIds: [],
        offerList: [],
        dialogImageUrl: '',
        dialogVisible: false,
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        rise: null,
        transpWayList: [], // 运输方式字典
        RG: require('@/assets/images/RG.png'),
        RP: require('@/assets/images/RP.png'),
        SW: require('@/assets/images/SW.png'),
      }
    },
    components: {
      OfferSheetContent,
      printWrapper,
    },

    mounted() {
      if (this.$route.query.d === '1') {
        setTimeout(() => {
          const pageNum = this.offerList.reduce((prev, next) => {
            return (prev += next?.priceSheets?.length)
          }, 0)
          this.getPdfMultiplePage(
            `${getNowDate()}-QUOTATION`,
            'inquiryprint',
            true,
            pageNum
          )
        }, 1000)
      }
    },
    created() {
      this.getDict()
      if (this.$route.path.indexOf('inquiryOffer') > 0) {
        return false
      }

      let self = this
      let query = self.$route.query
      // preview 预览
      if (query.preview) {
        self.isCache = query.isCache
        self.getPreviewInfo()
      } else {
        self.offerSetString = query.offerSetString || []
        self.enquiryIds =
          query.enquiryIds ||
          (self.offerSheetInfo && self.offerSheetInfo.enquiryIds)
        self.isBatch =
          query.isBatch || (self.offerSheetInfo && self.offerSheetInfo.isBatch)
            ? true
            : false
        if (!query?.isBatch) {
          self.priceSheetCode =
            query?.priceSheetCode || this.offerSheetInfo?.priceSheetCode || ''
        }
        self.getPrintInfo()
      }
    },
    methods: {
      // 获取运输方式字典
      getDict() {
        // transpotwayList().then((res) => {
        //   this.transpWayList = res.data.map((item) => {
        //     return {
        //       label: item.transportWayCn,
        //       value: item.id,
        //     }
        //   })
        // })
      },

      // 回显Shipping Method
      getShipMethod(id) {
        if (id) {
          let val = ''
          this.transpWayList.find((item) => {
            if (item.value == id) {
              val = item.label
            }
          })
          return val
        }
      },

      // 获取打印信息
      getPrintInfo() {
        let self = this
        if (!self.isBatch) {
          // 打印
          OrderInteractor.getPriceSheetDetailApi({
            enquiryId: self.enquiryIds,
            priceSheetCode: self.priceSheetCode,
          }).then(async (res) => {
            if (Array.isArray(res?.data?.priceSheets)) {
              for (let i = 0; i < res.data.priceSheets.length; i++) {
                let item = res.data.priceSheets[i]
                const { informationVOS, otherFeeProps, addPriceProps } =
                  handleOtherFee(item.informationVOS)
                item.productStyle = await getproductStyle(item)
                item.informationVOS = informationVOS
                item.otherFeeProps = otherFeeProps
                item.addPriceProps = addPriceProps
              }
            }
            if (res?.data) {
              self.offerList.push(res.data)
              self.rise = res.data.exportsRise
            }

          })
        } else {
          // 批量打印
          OrderInteractor.printOrderPriceSheetApi({
            enquiryId: self.enquiryIds.split(','),
          }).then(async (res) => {
            for (let j = 0; j < res.data?.length; j++) {
              let pItem = res.data[j]
              for (let i = 0; i < pItem.priceSheets.length; i++) {
                let item = pItem.priceSheets[i]
                const { informationVOS, otherFeeProps, addPriceProps } =
                  handleOtherFee(item.informationVOS)
                item.productStyle = await getproductStyle(item)
                item.informationVOS = informationVOS
                item.otherFeeProps = otherFeeProps
                item.addPriceProps = addPriceProps
              }
            }
            self.offerList = res.data
            self.rise = res.data[0].exportsRise
            this.$emit('priceSheetCodePass', res.data[0].priceSheetCode)
          })
        }
      },

      // 获取预览信息
      async getPreviewInfo() {
        const data = JSON.parse(sessionStorage.getItem('inquiryPrint'))
        let _obj = {
          validityDate: data.form.validityDate,
          priceSheets: data.form.list,
          businessEmail: data.base.businessEmail,
          businessName: data.base.businessName,
          companyAddress: data.base.companyAddress,
          companyName: data.base.companyName,
          customerEmail: data.base.customerEmail,
          customerName: data.base.customerName,
          customerPhone: data.base.customerPhone,
          demand: data.base.demand,
          quoteDate: data.isCopy ? '' : data.base.quoteDate,
          zipCode: data.base.zipCode,
          priceSheetCode: data.isCopy ? '' : data.base.priceSheetCode,
        }

        for (let i = 0; i < _obj.priceSheets.length; i++) {
          let item = _obj.priceSheets[i]
          const { informationVOS, otherFeeProps, addPriceProps } =
            handleOtherFee(item.informationDTOS)
          item.specsVOS = item.specsDTOS
          item.informationVOS = informationVOS
          item.productStyle = await getproductStyle(item)
          item.otherFeeProps = otherFeeProps
          item.addPriceProps = addPriceProps
        }
        this.rise = data.base.exportsRise
        this.offerList = []
        this.offerList.push(_obj)
      },
      // 生成报价单时，截图转成PDF
      getPreviewPDF(id, toQuationList) {
        this.offerList = []
        // 询盘列表预览入口，查询详情接口
        OrderInteractor.getPriceSheetDetailApi({
          priceSheetCode: id,
        }).then(async (res) => {
          if (Array.isArray(res.data.priceSheets)) {
            for (let i = 0; i < res.data.priceSheets.length; i++) {
              let item = res.data.priceSheets[i]
              const { informationVOS, otherFeeProps, addPriceProps } =
                handleOtherFee(item.informationVOS)
              item.productStyle = await getproductStyle(item)
              item.informationVOS = informationVOS
              item.otherFeeProps = otherFeeProps
              item.addPriceProps = addPriceProps
            }
          }

          const info = res.data
          this.rise = info.exportsRise
          this.offerList.push(info)
          this.$nextTick(() => {
            this.$emit(
              'rendered',
              info.customerEmail || '',
              toQuationList,
              res.data.priceSheets?.length
            )
          })
        })
      },

      getPrintPDF(id) {
        this.offerList = []
        // 询盘列表预览入口，查询详情接口
        OrderInteractor.getPriceSheetDetailApi({
          priceSheetCode: id,
        }).then(async (res) => {
          if (Array.isArray(res.data.priceSheets)) {
            for (let i = 0; i < res.data.priceSheets.length; i++) {
              let item = res.data.priceSheets[i]
              const { informationVOS, otherFeeProps, addPriceProps } =
                handleOtherFee(item.informationVOS)
              item.productStyle = await getproductStyle(item)
              item.informationVOS = informationVOS
              item.otherFeeProps = otherFeeProps
              item.addPriceProps = addPriceProps
            }
          }

          const info = res.data
          this.rise = info.exportsRise
          this.offerList.push(info)
          this.$nextTick(() => {
            this.$emit(
              'rendered-pdf',
              info.customerEmail || '',
              '',
              res.data.priceSheets?.length
            )
          })
        })
      },

      downloadPdf(id) {
        this.offerList = []
        // 询盘列表预览入口，查询详情接口
        OrderInteractor.getPriceSheetDetailApi({
          priceSheetCode: id,
        }).then(async (res) => {
          if (Array.isArray(res.data.priceSheets)) {
            for (let i = 0; i < res.data.priceSheets.length; i++) {
              let item = res.data.priceSheets[i]
              const { informationVOS, otherFeeProps, addPriceProps } =
                handleOtherFee(item.informationVOS)
              item.productStyle = await getproductStyle(item)
              item.informationVOS = informationVOS
              item.otherFeeProps = otherFeeProps
              item.addPriceProps = addPriceProps
            }
          }
          const info = res.data
          this.rise = info.exportsRise
          this.offerList.push(info)
          this.$nextTick(() => {
            const pageNum = this.offerList.reduce((prev, next) => {
              return (prev += next?.priceSheets?.length)
            }, 0)
            this.getPdfMultiplePage(
              `${getNowDate()}-QUOTATION`,
              'inquiryprint',
              true,
              pageNum
            )
          })
        })
      },

      // 格式化 rendingsUrl
      formatRendingsUrl(urls) {
        return urls ? urls.split(',') : []
      },

      // 大图预览
      handlePreview(item) {
        this.imgUrl = item
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  * {
    background: white;
  }

  .td-text {
    word-break: break-all;
    text-align: left;
  }

  .print-box {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 50px;

    .print+.print {
      margin-top: 50px;
    }
  }

  .print {
    height: 100%;
    /* width: 1280px; */
    margin: 0 auto;
    background: white;

    h1 {
      position: relative;
      margin: 0 auto;
      height: 90px;
      width: 1280px;
      line-height: 100px;
      text-align: center;
      font-size: 28px;

      img {
        height: 120px;
        position: absolute;
        top: 76px;
        right: 9px;
      }
    }

    .container {
      height: auto;
      padding: 0 5px 20px 0;
      overflow-y: auto;

      .box {

        /* margin-bottom: 10px; */
        .top {
          /* display: flex;
          > div {
            height: 40px;
            line-height: 40px;
            display: inline-block;
            margin-right: 20px;
            span {
              font-weight: 400;
            }
          } */
        }

        .base-info {
          display: flex;

          >div {
            border: 1px solid rgba(234, 235, 237, 1);
            width: calc(50% - 8px);
            padding: 10px;
            line-height: 26px;

            em {
              font-weight: 500;
              font-style: normal;
            }
          }

          >div:last-child {
            margin-left: 16px;
          }
        }
      }

      .wrapper-img {
        overflow: hidden;

        img {
          float: left;
          width: 120px;
          height: 120px;
          margin-right: 10px;
          margin-bottom: 10px;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }

    .custom-table thead th {
      min-width: 90px;
    }

    .custom-table p {
      text-align: left;
    }
  }
</style>