<template>
  <div class="search_form">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="需求数量">
        <div class="line_input">
          <el-form-item prop="amountMin">
            <el-input
              v-model="form.amountMin"
              maxlength="10"
              placeholder="请输入"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
          <span>至</span>
          <el-form-item prop="amountMax">
            <el-input
              v-model="form.amountMax"
              maxlength="10"
              placeholder="请输入"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="创建人" prop="creatorName">
        <el-input
          clearable
          v-model="creatorName"
          placeholder="请选择"
          @focus="selectUserClick('creatorName')"
          @change="creatorChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="处理人">
        <el-input
          clearable
          v-model="handlerName"
          placeholder="请选择"
          @focus="selectUserClick('business')"
          @change="handlerNameChange"
        ></el-input>
      </el-form-item>

      <div class="btn">
        <el-button type="primary" @click="searchClick">确定</el-button>
        <el-button @click="resetClick">重置</el-button>
      </div>
    </el-form>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
import UserChoose from '@/components/userChoose2'
export default {
  name: 'manageSearch',
  components: {
    UserChoose,
  },
  data() {
    return {
      handlerName: '', // 处理人名称
      handlerId: [], // 处理人id
      creatorName: '', //创建人
      creatorId: [], //创建人id
      isUserChoose: '', //当前组织架构是谁调用
      createTime: [], // 创建时间
      form: {},
      daterange: [],
      rules: {},
    }
  },
  watch: {
    // 创建时间
    createTime(dateArray) {
      if (dateArray) {
        this.form.createTimeMin = dateArray[0]
        this.form.createTimeMax = dateArray[1]
      } else {
        this.form.createTimeMin = ''
        this.form.createTimeMax = ''
      }
    },
  },
  created() {},
  methods: {
    // 处理人，创建人选择
    selectUserClick(str) {
      this.isUserChoose = str
      if (str === 'business') {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.handlerId)
      } else {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.creatorId)
      }
    },

    // 获取处理人
    chooseUser(checkedUser) {
      if (this.isUserChoose === 'business') {
        this.handlerNameChange()
      } else {
        this.creatorChange()
      }

      if (!checkedUser.length) {
        return
      }
      let userName = []
      checkedUser.forEach((item) => {
        userName.push(item.name)
        if (this.isUserChoose === 'business') {
          this.handlerName = userName.join(',')
          this.handlerId.push(item.id)
        } else {
          this.creatorName = userName.join(',')
          this.creatorId.push(item.id)
        }
      })
    },

    // 查询
    searchClick() {
      let self = this
      self.$refs.form.validate((valid) => {
        if (valid) {
          if (self.handlerId) {
            self.form.handlerId = self.handlerId.join(',')
          }
          if (self.creatorId) {
            self.form.creatorId = self.creatorId.join(',')
          }
          self.$emit('searchClick', self.form)
        }
      })
    },

    // 重置
    resetClick() {
      this.createTime = []
      this.handlerNameChange()
      this.creatorChange()
      this.$refs.form.resetFields()
      this.form = this.$options.data().form
    },

    // 删除处理人
    handlerNameChange() {
      this.handlerId = []
      this.handlerName = ''
      this.form.inspectorId = ''
    },

    // 删除创建人
    creatorChange() {
      this.creatorId = []
      this.creatorName = ''
      this.form.creatorId = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.search_form {
  padding: 0 20px 80px 20px;
  .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  .btn {
    padding: 20px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
  }
}
::v-deep {
  .el-input--suffix {
    padding-right: 5px !important;
  }
  .el-cascader {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 70px 0 28px 0 !important;
  }
}
</style>
