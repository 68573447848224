<template>
  <div class="detail-product">
    <!-- 主营产品 -->
    <el-row class="mt20">
      <el-button @click="setMainPro" type="primary">选择产品</el-button>
    </el-row>
    <el-row class="mt20">
      <el-table border :data="data.productVOS">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column prop="lianxiren" align="center" label="产品编码">
          <template slot-scope="scope">
            <span class="blue-text" @click="goToPagePro(scope.row.productId)">
              {{ scope.row.productCode }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="中文名称">
          <template slot-scope="scope">{{ scope.row.name || '--' }}</template>
        </el-table-column>
        <el-table-column prop="email" align="center" label="产品类别">
          <template slot-scope="scope">
            {{ scope.row.productCategoryName || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="WhatsApp" align="center" label="起订数量">
          <template slot-scope="scope">
            {{ scope.row.quantity || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="Skype" align="center" label="价格">
          <template slot-scope="scope">{{ scope.row.price || '--' }}</template>
        </el-table-column>
        <el-table-column prop="WeChat" align="center" label="供货周期(天)">
          <template slot-scope="scope">{{ scope.row.cycle || '--' }}</template>
        </el-table-column>
        <el-table-column
          prop=""
          align="center"
          label="备注"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.remarks || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="WeChat" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" class="ml10" @click="del(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <ChooseProduct
      :dialogSelectProductVisible.sync="dialogSelectProductVisible"
      ref="chooseProduct"
      @confirm="confirm"
    ></ChooseProduct>
  </div>
</template>

<script>
  import ChooseProduct from './chooseProduct'
  import ClientManageInteractor from '@/core/interactors/client-supplier/client-manage'
  export default {
    name: 'ClientManageDetailMsg',
    components: {
      ChooseProduct,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        supplierData: {},
        dialogSelectProductVisible: false,
      }
    },
    methods: {
      //跳往产品详情
      goToPagePro(productId) {
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId: productId,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
      // 增加主营产品
      setMainPro() {
        this.dialogSelectProductVisible = true
      },
      // 产品弹窗 确认
      async confirm(val) {
        let res = await ClientManageInteractor.setSupplierProduct(
          val,
          this.$route.query.supplierId
        )
        this.$refs.chooseProduct.loading = false
        if (res.data) {
          this.dialogSelectProductVisible = false
          this.$message.success('操作成功')
          this.getNewDetail()
        }
      },
      // 删除
      del({ supplierProductId, supplierId }) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await ClientManageInteractor.delSupplierProduct({
              supplierProductId,
              supplierId,
            })
            if (res.data) {
              this.$message.success('删除成功')
              this.getNewDetail()
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      // 获取最新详情数据
      getNewDetail() {
        this.$emit('refreshDetail')
      },
      // 编辑
      edit(row) {
        this.dialogSelectProductVisible = true
        this.$refs.chooseProduct.showMsg(row)
      },
    },
  }
</script>

<style scoped lang="scss">
  .detail-product {
    min-height: 700px;
    padding: 10px;
    border-left: 1px solid #d7d7d7;
  }
</style>
