<template>
  <el-dialog
    :title="$t(title)"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <!-- 转让人 -->
        <el-form-item :label="$t('productCenter.Transferor')" prop="userId">
          <!-- 请选择 -->
          <el-select
            v-model="form.userId"
            :placeholder="$t('placeholder.plsSel')"
            class="w100"
            @change="userIdChange"
          >
            <el-option
              v-for="item in belongData"
              :key="item.userId"
              :label="getName(item, 'userName')"
              :value="item.userId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <!-- 确 认 -->
        <el-button type="primary" :loading="loading" @click="save">
          {{ $t('productCenter.Confirm') }}
        </el-button>
        <!-- 取 消 -->
        <el-button @click="close">{{ $t('productCenter.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    listSameUserForProductionLine,
    transferBelongBatch,
  } from '@/api/product/productList'
  import nameMixin from '@/utils/name-mixin'
  export default {
    name: 'TransferModel',
    mixins: [nameMixin],
    data() {
      return {
        loading: false,
        // title: '转让归属权',
        title: 'productCenter.TransferOwnership',
        form: {
          productIds: '',
          userId: '',
          userName: '',
        },
        rules: {
          userId: [
            // 请选择转让人
            {
              required: true,
              trigger: 'change',
              message: this.$t('rules.M2001'),
            },
          ],
        },
        belongData: [],
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit(proLineIds, proIds) {
        this.form.productIds = proIds
        //产线id
        this.getListSameUserForProductionLine(proLineIds)
        this.dialogFormVisible = true
      },

      //产品运营下拉
      async getListSameUserForProductionLine(proLineIds) {
        let response = await listSameUserForProductionLine({
          productSettingIds: proLineIds,
        })
        if (response.code === '000000') {
          this.belongData = response.data
        }
      },

      userIdChange(val) {
        this.form.userName = this.belongData.filter(
          (item) => item.userId === val
        )[0].userName
      },

      //关闭
      close() {
        this.$refs['form'].resetFields()
        //this.form = this.$options.data().form
        this.dialogFormVisible = false
      },

      //保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let form = this.utils.deepCopy(this.form)
            this.loading = true
            let response = await transferBelongBatch(form)
            this.loading = false
            if (response.code === '000000' && response.data >= 1) {
              // 归属权转让成功
              this.$baseMessage(
                this.$t('reqmsg.M2013'),
                'success',
                false,
                'erp-hey-message-success'
              )
            } else {
              // 归属权转让失败
              return this.$baseMessage(
                this.$t('reqmsg.M2014'),
                'error',
                false,
                'erp-hey-message-error'
              )
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .text-hidden {
    width: 150px;
  }
</style>
