<template>
  <!-- 上传规格图片 -->
  <el-dialog
    :title="$t('other.uploadImg')"
    :visible.sync="visible"
    :before-close="handleClose"
    width="815px"
  >
    <erp-upload-images
      :default-file-list="defaultFileList"
      @change="changeUploadList"
      v-if="visible"
      :allowedType="allowedType"
      :limit="limit"
    ></erp-upload-images>
    <el-row slot="footer" class="dialog-footer text-center">
      <!-- 确认 -->
      <el-button
        type="primary"
        @click="confirmChoose"
        :disabled="imageList.length == 0"
      >
        {{ $t('productDetail.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="handleClose">
        {{ $t('productDetail.Cancel') }}
      </el-button>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  name: 'chooseImages',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
    // 默认回显图片
    defaultFileList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageList: [],
      allowedType: 'jpg、jpeg、png',
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.imageList = JSON.parse(JSON.stringify(this.defaultFileList))
      }
    },
  },
  methods: {
    // 关闭 dialog
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 上传印刷效果图
    changeUploadList(list) {
      this.imageList = list
    },
    // 格式化数据
    formatData() {
      let __urls = []
      const __imageList = this.imageList

      for (let i = 0; i < __imageList.length; i++) {
        __urls.push(__imageList[i].url)
      }
      return {
        urls: __urls.toString(),
      }
    },
    // 确认
    confirmChoose() {
      this.handleClose()
      this.$emit('confirm', this.formatData())
    },
  },
}
</script>
<style scoped lang="scss">
.wrapper-status {
  margin-top: 10px;
}
::v-deep {
  .el-icon-plus {
    position: relative;
  }
}
</style>
