<template>
  <div class="inspection-result-wrapper">
    <span class="label">QC质检结果:</span>
    <span class="count">{{ results.a || '--' }}</span>
    <span class="label">质检数量:</span>
    <span class="count">{{ results.a || '--' }}</span>
    <span class="label">不良率:</span>
    <span class="count">{{ results.a || '--' }}</span>
    <span class="label">良品数:</span>
    <span class="count">{{ results.a || '--' }}</span>
    <span class="label">不良品数:</span>
    <span class="count">{{ results.a || '--' }}</span>
  </div>
</template>

<script>
  export default {
    name: 'InspectionResult',
    props: {
      results: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style lang="scss" scoped>
  .inspection-result-wrapper {
    display: inline-block;
    text-align: right;
    font-size: 12px;
    font-weight: 600;
    .label {
      color: #606266;
      margin-right: 4px;
    }
    .count {
      color: #606266;
      margin-right: 10px;
    }
  }
</style>
