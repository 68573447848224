/**
 * 订单设置模块接口
 */
import request from '@/core/services/request'

/**
 * 根据业务ID删除
 */
export function deleteById(businessId) {
  return request({
    url: '/order/orderAmount/deleteById',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 根据id查找
 */

export function getDetail(businessId) {
  return request({
    url: '/order/orderAmount/getDetail',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 新增更新实体
 */

export function insertPO(data) {
  return request({
    url: '/order/orderAmount/insertPO',
    method: 'post',
    data,
  })
}

/**
 * 根据分页查找列表
 */

export function listPage(data = {}) {
  return request({
    url: '/order/orderAmount/listPage',
    method: 'post',
    data,
  })
}

/**
 * 出口抬头作废
 */

export function obsolete(businessId) {
  return request({
    url: '/order/orderAmount/obsolete',
    method: 'post',
    params: {
      businessId,
    },
  })
}

// 单证员查询
export function clerkSettingList() {
  return request({
    url: '/order/clerkSetting/query',
    method: 'post',
  })
}

// 单证员新增更新
export function clerkSettingUpdate(data = {}) {
  return request({
    url: '/order/clerkSetting/saveUpdate',
    method: 'post',
    data,
  })
}

// 成本调整查询
export function costAdjustList(data) {
  return request({
    url: '/order/orderAmount/getPurchaseCostSetPO',
    method: 'post',
    data,
  })
}

// 成本调整编辑审核
export function costAdjustSave(data) {
  return request({
    url: '/order/orderAmount/insertPurchaseCostSetPO',
    method: 'post',
    data,
  })
}
