<template>
  <el-dialog
    :title="$t(title)"
    width="800px"
    :visible.sync="isDialogVisible"
    class="mail-sign-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="120px"
      label-position="top"
    >
      <!-- label="执行邮箱：" -->
      <el-form-item
        prop="emailAccount"
        :label="$t('boxsetting.ExecuteMailbox')"
        :rules="[
          {
            required: true,
            message: $t('placeholder.plsSel'),
            trigger: 'change',
          },
        ]"
      >
        <el-select
          v-model="form.emailAccount"
          :placeholder="$t('placeholder.plsInput')"
          value-key="emailAccount"
          @change="emailAccountChange"
          style="width: 100%"
        >
          <el-option
            v-for="item in accountList"
            :key="item.mailAccountId"
            :label="item.emailAccount"
            :value="item.emailAccount"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- label="邮件到达时：" -->
      <el-form-item required :label="$t('reqmsg.$62')">
        <div class="inner">
          <span class="desc">
            <!-- 总把 -->
            {{ lang == 'en' ? 'Always move' : '总把' }}
          </span>
          <el-form-item
            prop="content"
            label-width="0"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                validator: $formValidation.isEmail,
                trigger: 'change',
              },
            ]"
          >
            <el-input
              v-model.trim="form.content"
              :placeholder="
                lang != 'en'
                  ? '请输入邮箱地址内容'
                  : 'please input mailAddress content'
              "
              maxlength="50"
              style="width: 240px; margin: 0 3px 10px"
            ></el-input>
          </el-form-item>
          <span class="desc">
            <!-- 的邮件，移动到此文件夹： -->
            {{
              lang == 'en'
                ? 'message to this folder：'
                : '的邮件，移动到此文件夹：'
            }}
          </span>
          <el-form-item
            prop="catagoryId"
            label-width="0"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsSel'),
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="form.catagoryId"
              :placeholder="$t('placeholder.plsInput')"
              value-key="catagoryId"
              @change="changeCatagory"
            >
              <el-option
                v-for="item in receiveCatgoryList[form.emailAccount]"
                :key="item.catagoryId"
                :label="item.name"
                :value="item.catagoryId"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button> -->
      <el-button @click="close">{{ $t('boxsetting.Cancel') }}</el-button>
      <el-button type="primary" @click="save">
        {{ $t('boxsetting.Confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { mailRulesSave } from '@/api/mail/mail-setting'
  import { mapState, mapGetters } from 'vuex'
  export default {
    data() {
      // let validContent = (value, rule, callback) => {
      //   if (!this.form.content) {
      //     callback(new Error('请输入邮箱地址内容'))
      //   } else if (!this.form.catagoryId) {
      //     callback(new Error('请选择移动文件夹'))
      //   } else {
      //     callback()
      //   }
      // }
      return {
        isDialogVisible: false,
        title: 'boxsetting.AddRule',
        form: {
          emailAccount: '',
          content: '',
          catagoryId: '',
          catagoryName: '',
        },
        rules: {
          // emailAccount: [
          //   { required: true, message: '请选择邮箱', trigger: 'change' },
          // ],
          // content: [
          //   { required: true, message: '请输入', trigger: 'blur' },
          //   {
          //     validator: this.$formValidation.isEmail,
          //     trigger: 'blur',
          //   },
          // ],
          // catagoryId: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        receiveList: [],
      }
    },
    computed: {
      ...mapState({
        accountList: (state) =>
          state.mail.accountList.filter((item) => item.authStatus === null),
        receiveCatgoryList: (state) => state.mail.receiveCatgoryList,
        userInfo: (state) => state.user.userInfo,
      }),
      ...mapGetters({
        lang: 'settings/language',
      }),
    },

    methods: {
      showDialog(data) {
        this.isDialogVisible = true
        this.title = data ? 'other.editRule' : 'boxsetting.AddRule'
        if (data) {
          this.form = Object.assign({}, data)
          this.form.catagoryId = data.catagoryId + ''
        }
        this.$forceUpdate()
      },
      close() {
        this.form = {
          emailAccount: '',
          content: '',
          catagoryId: '',
          catagoryName: '',
        }
        this.isDialogVisible = false
      },
      emailAccountChange() {
        this.form.catagoryId = ''
        this.form.catagoryName = ''

        // this.receiveList = []
        // for (const key in this.receiveCatgoryList) {
        //   if (
        //     this.receiveCatgoryList[key].emailAccount == this.form.emailAccount
        //   ) {
        //     this.receiveList.push(this.receiveCatgoryList[key])
        //   }
        // }
      },
      changeCatagory(val, data) {
        let list = this.receiveCatgoryList[this.form.emailAccount]
        list.forEach((item) => {
          if (item.catagoryId == this.form.catagoryId) {
            this.form.catagoryName = item.name
          }
        })
      },
      save() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            let params = {
              ...this.form,
              businessId: this.userInfo.userId,
            }
            if (this.form.mailReceivingRulesId) {
              //编辑
              mailRulesSave(params)
                .then((res) => {
                  if (res.code == '000000') {
                    this.$emit('success')
                    this.close()
                  } else if (res.code == '900000') {
                    this.$confirm(
                      `<div style="font-size:14px;color:#333"><i class="el-icon-warning" style="font-size:20px;color:#ffba00"></i> 该邮箱已有规则，无法重复设置。</div>
                    <p style="font-size:12px;color:#999;padding-left:20px;">可将已存在的规则禁用后重新编辑修改，或者删除后再新增</p>`,
                      '提示',
                      {
                        dangerouslyUseHTMLString: true,
                      }
                    ).then(() => {})
                  }
                })
                .catch((err) => {})
            } else {
              //新增
              this.$confirm(
                `<div style="font-size:14px;color:#333"><i class="el-icon-warning" style="font-size:20px;color:#ffba00"></i> 是否立即执行此规则？</div>
            <p style="font-size:12px;color:#999;padding-left:20px;">若选择“确定”，系统将收件箱内的所有历史邮件都移动到指定文件夹内；若选择“取消”，历史邮件不移动，收到的新邮件会自动分到指定文件夹内。</p>`,
                '提示',
                {
                  dangerouslyUseHTMLString: true,
                }
              )
                .then(() => {
                  params['implement'] = 1
                })
                .catch((err) => {
                  params['implement'] = 0
                })
                .finally(() => {
                  mailRulesSave(params)
                    .then((res) => {
                      if (res.code == '000000') {
                        this.$emit('success')
                        this.close()
                      } else if (res.code == '900000') {
                        this.$confirm(
                          `<div style="font-size:14px;color:#333"><i class="el-icon-warning" style="font-size:20px;color:#ffba00"></i> 该邮箱已有规则，无法重复设置。</div>
                    <p style="font-size:12px;color:#999;padding-left:20px;">可将已存在的规则禁用后重新编辑修改，或者删除后再新增</p>`,
                          '提示',
                          {
                            dangerouslyUseHTMLString: true,
                          }
                        ).then(() => {})
                      }
                    })
                    .catch((err) => {})
                })
            }
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .mail-sign-dialog {
    .editor {
      height: 400px;
    }
    .inner {
      display: flex;
    }
  }
</style>
