<template>
  <el-dialog title="重置密码" width="600px" :visible.sync="dialogVisible" :before-close="handleClose"
    :close-on-click-modal="false" append-to-body>
    <div class="reset-password">
      <el-form ref="form" :model="form" :rules="rules" label-width="0">
        <div class="box">
          <div class="text">重置密码：</div>

          <el-form-item prop="password">
            <el-input v-model="form.password" maxlength="20" show-word-limit placeholder="请输入" clearable
              onkeyup="this.value = this.value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,'')"></el-input>
          </el-form-item>


        </div>
      </el-form>

      <!-- 校验密码强度 -->
      <div class="grade">
        <password-check :password="form.password" @isPass="isPass"></password-check>
      </div>

      <p>8~20位数字、大/小写字母、符号，密码强度需达到「强」</p>

      <div slot="footer" class="text-right mt30">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :disabled="!disabled || String(form.password).length < 8" @click="save">
          确 定
        </el-button>
      </div>
    </div>

    <!-- 短信验证提示 -->
    <sms-hint ref="sms" :title="'密码重置成功'" :phone="userForm.telephone" :password="form.password"
      @close="handleClose"></sms-hint>
  </el-dialog>
</template>

<script>
  import { systemInteractor } from '@/core'
  import { generatePassword } from '@/utils/utils'
  import PasswordCheck from '@/components/password-check/index'
  import SmsHint from './sms-hint.vue'
  import { mapGetters } from 'vuex'
  export default {
    components: { PasswordCheck, SmsHint },
    props: {
      userForm: {
        type: Object,
        default: () => { },
      },
    },
    data() {
      return {
        userId: '',
        form: {
          password: '',
        },
        rules: {
          password: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        disabled: false, // 校验密码强度
        dialogVisible: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    methods: {
      // 初始化
      init(userId) {
        this.userId = userId
        this.dialogVisible = true
      },

      // 密码是否通过
      isPass(bool) {
        this.disabled = bool
      },

      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const res = await systemInteractor.resetUserPassWord(
              this.userId,
              this.form.password
            )
            if (res && res.code === '000000') {
              this.smsHintEvent()
            }
          }
        })
      },

      // 短信通知
      async smsHintEvent() {
        const params = {
          phone: this.userForm.telephone,
          userName: this.userForm.userName,
          password: this.form.password,
        }

        const res = await systemInteractor.resetSendSms(params)
        if (res && res.code === '000000') {
          this.$refs.sms.init(res?.data)
        }
      },

      // 关闭
      handleClose() {
        this.$refs['form'].resetFields()
        this.dialogVisible = false
      },


    },
  }
</script>

<style lang="scss" scoped>
  .reset-password {
    .box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      line-height: 32px;

      .text {
        width: 80px;
        text-align: right;
        font-family: 'PingFang Bold';
      }

      i {
        font-size: 16px;
        cursor: pointer;
        margin-left: 3px;
      }
    }

    p {
      text-indent: 90px;
      color: #e6a341;
      font-size: 14px;
    }

    .grade {
      margin-left: 90px;
      width: 382px;
    }
  }

  ::v-deep {
    .el-input {
      width: 380px;
    }

    .el-form-item__label {
      ::before {
        display: none;
      }
    }
  }
</style>