<template>
  <div class="custom-container">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="22" class="page-header-left">
        <el-row type="flex" class="flex_row_y flex_wrap">
          <div style="margin-top: -1px">
            <el-form
              ref="form"
              :inline="true"
              :model="queryForm"
              @submit.native.prevent
            >
              <el-form-item>
                <el-date-picker
                  v-model="loginDate"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  style="width: 100%"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="queryForm.loginPhone"
                  placeholder="登录手机号"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="queryForm.equipmentType"
                  placeholder="设备类型"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="queryForm.equipmentOs"
                  placeholder="设备系统"
                  clearable
                />
              </el-form-item>
            </el-form>
          </div>
          <el-button type="primary" @click="init">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-row>
      </el-col>
      <el-col :span="2">
        <el-row type="flex" justify="end">
          <el-button @click="mac">IP黑名单</el-button>
        </el-row>
      </el-col>
    </el-row>

    <ErpTable
      class="mt20"
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :total="total"
      @query="getList"
    >
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
      >
        <template #default="{ row }">
          <div v-if="item.prop === 'time'"></div>
          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
    </ErpTable>

    <!-- mac黑名单 -->
    <mac ref="mac"></mac>
  </div>
</template>

<script>
import { systemInteractor } from '@/core'
import Mac from './components/mac.vue'
import { mapGetters } from 'vuex'

export default {
  components: { Mac },
  data() {
    return {
      queryForm: {
        loginPhone: '',
        equipmentType: '',
        equipmentOs: '',
      },
      page: {
        pageNo: 1,
        pageLe: 10,
      },
      total: 0,
      loginDate: [],
      tableData: [],
      columns: [
        {
          label: '登录时间',
          prop: 'createTime',
        },
        {
          label: '登录手机号',
          prop: 'loginPhone',
        },
        {
          label: '姓名',
          prop: 'creatorName',
        },
        {
          label: '登录IP',
          prop: 'loginIp',
        },
        {
          label: 'IP地址（省）',
          prop: 'loginIpProvince',
        },
        {
          label: 'IP地址（市）',
          prop: 'loginIpCity',
        },
        {
          label: '设备类型',
          prop: 'equipmentType',
        },
        {
          label: '设备系统',
          prop: 'equipmentOs',
        },
        {
          label: '浏览器',
          prop: 'browser',
        },
        {
          label: '浏览器版本',
          prop: 'browserVersion',
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    this.init()
  },
  watch: {
    // 登录时间
    loginDate(dateArray) {
      if (dateArray) {
        this.queryForm.loginStartTime = dateArray[0]
        this.queryForm.loginEndTime = dateArray[1]
      } else {
        this.queryForm.loginStartTime = ''
        this.queryForm.loginEndTime = ''
      }
    },
  },
  methods: {
    // 初始化
    init() {
      this.page.pageNo = 1
      this.page.pageLe = 10
      this.getList()
    },

    // 获取列表
    async getList() {
      const params = {
        ...this.queryForm,
        ...this.page,
      }
      const res = await systemInteractor.loginRecordListPage(params)

      if (res && res.code == '000000') {
        this.tableData = res.data?.data || []
        this.total = res.data?.total || 0
      }
    },

    // mac黑名单
    mac() {
      this.$refs.mac.init()
    },

    // 重置
    reset() {
      this.loginDate = []
      this.queryForm = this.$options.data().queryForm
      this.init()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
