<template>
  <div class="mail-page">
    <div>
      <MailList ref="MailList" :listConfig="listConfig" @showInfo="showInfo" @clearMail="currentMail = null" />
    </div>
    <div>
      <MailInfo v-if="currentMail" ref="mailInfo" @updatePage="updatePage" />
      <NoMail v-else />
    </div>
  </div>
</template>

<script>
  import MailInfo from './components/MailInfo.vue'
  import MailList from './components/MailList.vue'
  import NoMail from './components/NoMail.vue'
  export default {
    components: {
      MailList,
      MailInfo,
      NoMail,
    },
    watch: {
      '$store.state.mail.currentAccount': {
        handler(n) {

          this.currentMail = null
        },
      },
    },
    data() {
      return {
        currentMail: null, // 当前邮件
        listConfig: {
          // 区分收件箱参数
          flag: false,
          type: 0,
        },
        width: 400,
      }
    },
    methods: {
      showInfo(data) {
        console.log('data===', data)
        setTimeout(() => {
          this.currentMail = data
        }, 10)
        if (data) {
          let self = this
          if (self.$refs.mailInfo) {
            self.$refs.mailInfo?.show(data)
          } else {
            setTimeout(() => {
              self.$refs.mailInfo?.show(data)
            }, 800)
          }
        }

      },
      updatePage() {
        this.$refs.MailList.updatePage()
      },

    },
  }
</script>

<style lang="scss" scoped>
  .mail-page {
    height: 100%;
    display: flex;

    &>div {
      height: 100%;
    }

    &>div:first-child {

      flex-shrink: 0;
    }

    &>div:last-child {
      flex: 1;
      width: 0;
    }
  }
</style>