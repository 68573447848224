<template>
  <el-dialog
    title="选择产品"
    :visible.sync="dialogSelectProductVisible"
    width="800px"
    :close-on-click-modal="false"
    @close="closeMainProductDialog"
  >
    <el-row class="search-row mb30" v-if="pageType !== 'edit'">
      <label style="display: inline-block; width: 110px; text-align: right">
        <i style="color: red">*</i>
        选择产品
      </label>
      <el-autocomplete
        style="width: 260px"
        v-model.trim="codeOrName"
        :fetch-suggestions="querySearchAsync"
        class="ml10"
        placeholder="请输入产品编码/英文名称搜索"
        :trigger-on-focus="triggerBol"
        clearable
        @select="productHandleSelect"
      ></el-autocomplete>
    </el-row>

    <el-row class="mb20" v-if="pageType !== 'edit' && productTags.length">
      <label
        style="
          width: 110px;
          text-align: right;
          margin-right: 10px;
          display: inline-block;
        "
      >
        已选产品
      </label>
      <el-tag
        v-for="(pro, index) in productTags"
        :key="pro + index"
        closable
        size="large"
        @close="handleClose(pro)"
      >
        {{ `${pro.productCode}&ensp;/&ensp;${pro.nameCn}（${pro.nameEn}）` }}
      </el-tag>
    </el-row>
    <el-form
      :model="productForm"
      :rules="productRules"
      ref="productForm"
      label-width="120px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item prop="quantity" label="起订数量">
            <el-input-number
              style="width: 260px"
              v-model="productForm.quantity"
              controls-position="right"
              :min="1"
              :precision="0"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="price" label="单价">
            <el-input-number
              style="width: 260px"
              v-model="productForm.price"
              controls-position="right"
              :min="0"
              :precision="3"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="cycle" label="供货周期">
            <el-input
              style="width: 260px"
              v-model="productForm.cycle"
              controls-position="right"
              :min="0"
              :precision="0"
              @blur="cycleBlur"
            >
              <span slot="append">天</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="备注">
          <el-input
            v-model="productForm.remarks"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer text-center">
      <el-button type="primary" :loading="loading" @click="saveMainProduct">
        确 认
      </el-button>
      <el-button @click="closeMainProductDialog">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { SupplierManageInteractor, ProductListInteractor } from '@/core'

  export default {
    data() {
      return {
        loading: false,
        codeOrName: '',
        triggerBol: false,
        productForm: {
          productCode: '', // 产品编码
          name: '', // 中文名
          quantity: '', // 起订数量
          price: '', // 单价
          cycle: '', // 供货周期
          remarks: '', // 备注
        },
        pageType: '',
        productTags: [],
        productRules: {
          productCode: [
            {
              required: true,
              message: '请输入产品编码',
              trigger: 'blur',
            },
          ],
          quantity: [
            {
              required: true,
              message: '请输入起订数量',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    props: {
      dialogSelectProductVisible: {
        default: false,
        type: Boolean,
      },
    },
    methods: {
      //选择产品-关闭弹框
      closeMainProductDialog() {
        this.$emit('update:dialogSelectProductVisible', false)
        this.handleClose()
        this.pageType = ''
      },
      //选择产品-保存
      saveMainProduct() {
        this.$refs['productForm'].validate((valid) => {
          this.loading = true
          this.$emit('confirm', this.productForm)
        })
      },
      //产品搜索
      async querySearchAsync(queryString, cb) {
        if (!this.codeOrName) return
        await ProductListInteractor.listProductByParam({
          param: this.codeOrName,
        }).then((res) => {
          let results = res?.data?.map((item) => {
            return {
              ...item,
              value: item.productCode + ' / ' + item.nameEn,
            }
          })
          cb(results)
        })
      },
      productHandleSelect(item) {
        if (item.productId) {
          this.getDetailByCodeOrName(item.productId)
        }
      },
      //选择产品-获取产品信息
      async getDetailByCodeOrName(productCode) {
        this.codeOrName = ''
        this.productTags = []
        this.productForm = {
          productCode: '', // 产品编码
          name: '', // 中文名
          quantity: '', // 起订数量
          price: '', // 单价
          cycle: '', // 供货周期
          remarks: '', // 备注
        }
        let response = await SupplierManageInteractor.detailByCodeOrName({
          param: productCode,
        })
        if (response.code === '000000') {
          this.productTags.push(response.data)
          this.$set(this.productForm, 'productCode', response.data.productCode)
          this.$set(this.productForm, 'name', response.data.nameCn)
          this.$set(this.productForm, 'supplierId', response.data.supplierIds)
          this.$set(this.productForm, 'productId', response.data.productId)
          this.$set(
            this.productForm,
            'productCategoryName',
            response.data.category
          )
          this.$set(
            this.productForm,
            'productCategoryId',
            response.data.categoryId
          )
        }
      },
      //删除产品
      handleClose() {
        this.codeOrName = ''
        this.productTags = []
        this.productForm = {
          productCode: '',
          name: '',
          quantity: '',
          price: '',
          cycle: '',
          remarks: '',
        }
      },
      // 产品供货周期的输入
      cycleBlur(e) {
        let val = e.target.value
        let reg = /^(([^0][0-9]+|0)$)|^(([1-9]+)$)/
        if (!reg.test(val)) {
          this.productForm.cycle = ' '
        }
      },
      // 信息回显
      showMsg(val) {
        this.pageType = 'edit'
        this.productForm = { ...val }
      },
    },
  }
</script>
