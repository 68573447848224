<template>
  <div>
    <el-dialog
      title="导出"
      :visible.sync="visible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-result icon="success">
          <div slot="subTitle">
            <p>导出任务创建成功</p>
            <p class="mt5">请在任务中心查看导出进度</p>
          </div>
        </el-result>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewTask">立即查看</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {}
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      ...mapActions({
        setViewTask: 'task/setViewTask',
      }),
      viewTask() {
        this.setViewTask(true)
        this.handleClose()
      },
      handleClose() {
        this.$emit('update:visible', false)
      },
    },
  }
</script>
