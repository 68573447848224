/**
 * 配置管理
 */




/**
 * 计算类型下拉
 */
export const calcTypes = [{
  name: '阶梯',
  code: 1
}, {
  name: '固定',
  code: 2
}]



export const areas = [{
  name: '全部',
  code: null
}, {
  name: '合肥',
  code: '合肥',
}, {
  name: '苏州',
  code: '苏州',
}, {
  name: '义乌',
  code: '义乌',
}]


export const configStatus = [{
  name: '全部',
  code: ''
}, {
  name: '启用',
  code: '1',
}, {
  name: '冻结',
  code: '0',
}]

export const productType = [
  {
    name: "订制品",
    code: '订制品',
  },
  {
    name: "标准品",
    code: "标准品",
  },
  {
    name: "辅料",
    code: "辅料",
  },
]



export const productTypes = [{
  name: '订制品',
  code: 0
}, {
  name: '标准品',
  code: 1,
}, {
  name: '辅料',
  code: 2,
}]

export const personConfigColumns = [
  {
    label: '序号',
    prop: 'NO',
    width: "80",
    isShow: true,
    checkable: false,
  }, {
    label: '地区',
    prop: 'NO',
    width: "80",
    isShow: true,
    checkable: false,
  }, {
    label: '员工姓名（英文）',
    prop: 'NO',
    width: "140",
    isShow: true,
    checkable: false,
  }, {
    label: '产品类型',
    prop: 'NO',
    width: "100",
    isShow: true,
    checkable: false,
  }, {
    label: '客户代表',
    prop: 'NO',
    width: "100",
    isShow: true,
    checkable: false,
  }, {
    label: '订单支持',
    prop: 'orderSuport',
    width: "100",
    isShow: true,
    checkable: false,
  }, {
    label: '产品运营',
    prop: 'NO',
    width: "100",
    isShow: true,
    checkable: false,
  }, {
    label: '采购支持',
    prop: 'NO',
    width: "100",
    isShow: true,
    checkable: false,
  }, {
    label: '客户代表管理',
    prop: 'NO',
    width: "120",
    isShow: true,
    checkable: false,
  }, {
    label: '订单支持管理',
    prop: 'NO',
    width: "120",
    isShow: true,
    checkable: false,
  }, {
    label: '产品运营管理',
    prop: 'NO',
    width: "120",
    isShow: true,
    checkable: false,
  }, {
    label: '采购支持管理',
    prop: 'NO',
    width: "120",
    isShow: true,
    checkable: false,
  }, {
    label: '模板生效时间',
    prop: 'NO',
    width: "140",
    isShow: true,
    checkable: false,
  },
  {
    label: '状态',
    width: "80",
    prop: 'NO',
    isShow: true,
    checkable: false,
  },]


export const templateConfigColumns = [
  {
    label: '序号',
    prop: 'NO',
    width: "80",
    isShow: true,
    checkable: false,
  },
  {
    label: '地区',
    prop: 'area',
    width: "80",
    isShow: true,
    checkable: false,
  },
  {
    label: '产品类型',
    prop: 'standardCustomized',
    isShow: true,
    checkable: false,
  },
]

export const templateConfigOperColumns = [
  {
    label: '地区',
    prop: 'area',
  },
  {
    label: '产品类型',
    prop: 'area',
  },
  {
    label: '客户代表',
    prop: 'area',
  },
  {
    label: '订单支持',
    prop: 'area',
  },
  {
    label: '产品运营',
    prop: 'area',
  },
  {
    label: '采购支持',
    prop: 'area',
  },
  {
    label: '客户代表管理',
    prop: 'area',
  },
  {
    label: '订单支持管理',
    prop: 'area',
  },
  {
    label: '产品运营管理',
    prop: 'area',
  },
  {
    label: '采购支持管理',
    prop: 'area',
  },
]

export const status = [
  {
    name: '全部',
    code: ''
  }, {
    name: '生效中',
    code: '1',
  }, {
    name: '待生效',
    code: '0',
  }
]

