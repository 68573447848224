import { render, staticRenderFns } from "./purchase-order.vue?vue&type=template&id=16d67eca&scoped=true&"
import script from "./purchase-order.vue?vue&type=script&lang=js&"
export * from "./purchase-order.vue?vue&type=script&lang=js&"
import style0 from "./purchase-order.vue?vue&type=style&index=0&id=16d67eca&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d67eca",
  null
  
)

export default component.exports