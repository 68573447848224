personSearchForm
<template>
  <div>
    <el-form ref="personSearchForm" :model="personSearchForm" inline>
      <el-form-item>
        <el-button type="primary" @click="goAdd">新增</el-button>
      </el-form-item>

      <el-form-item label="员工姓名" label-width="80px">
        <el-input
          v-model="personSearchForm.name"
          @focus="fromBusinessChange"
          clearable
          @clear="clearFromBusinessName"
          placeholder="请选择"
        ></el-input>
      </el-form-item>
      <el-form-item label="地区" label-width="60px">
        <el-select v-model="personSearchForm.area" style="width: 100px">
          <el-option
            v-for="(item, index) in areaList"
            :key="index"
            :label="item.areaName"
            :value="item.areaName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板状态" label-width="80px">
        <el-select
          v-model="personSearchForm.templateStatus"
          placeholder="模板状态"
          style="width: 100px"
        >
          <el-option
            v-for="item in statusOption"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" label-width="60px">
        <el-select
          v-model="personSearchForm.status"
          placeholder="状态"
          style="width: 100px"
        >
          <el-option
            v-for="item in personStatus"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <div class="config-table">
      <ErpTable
        :page-number.sync="page.pageNo"
        :page-size.sync="page.pageLe"
        :table-data="tableData"
        :total="total"
        :extraHeight="30"
        class="table-fixed"
        @query="getList"
        ref="trackTable"
      >
        <el-table-column
          align="center"
          width="80"
          label="序号"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          prop="area"
          align="center"
          width="80"
          label="地区"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="standardCustomized"
          align="center"
          width="80"
          label="产品类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          width="180"
          label="员工姓名（英文）"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.personnoelCn }}（{{ scope.row.personnoelEn }}）
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in roleList"
          :key="index"
          :label="item.message"
          show-overflow-tooltip
          min-width="120"
          :resizable="true"
          align="center"
          :prop="item.code"
          :class-name="item.code"
        >
          <template slot="header">
            <div>
              {{ item.message }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="tipContent"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row[getRoleCode(item.message)]">
              <span v-if="scope.row[getRoleCode(item.message)].ratioType == 1">
                {{ scope.row[getRoleCode(item.message)].mark }}
              </span>
              <el-link
                v-if="scope.row[getRoleCode(item.message)].ratioType == 2"
                type="primary"
                @click="showDetail(scope.row[getRoleCode(item.message)])"
              >
                {{ scope.row[getRoleCode(item.message)].mark }}
              </el-link>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="effectiveTime"
          align="center"
          width="180"
          label="模板生效时间"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="effectiveStatus"
          align="center"
          width="80"
          label="状态"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="templateStatus"
          align="center"
          width="100"
          label="模板状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.templateStatus == 1 ? '启用' : '冻结' }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="120" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="goDetail(scope.row)" size="medium">
              详情
            </el-button>
            <el-button type="text" @click="goEdit(scope.row)" size="medium">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </ErpTable>
    </div>
    <condition-add-model
      :addModelVisible.sync="addModelVisible"
      :roleList="roleList"
      :areaList="areaList"
    ></condition-add-model>
    <!-- 选择成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    <modelDetailDialog
      :modelDetailVisible.sync="modelDetailVisible"
      :tableData.sync="detailTable"
    ></modelDetailDialog>
  </div>
</template>
<script>
  import {
    configStatus,
    personConfigColumns,
    status,
  } from '@/views/finance-center/config-manage/helper'
  import conditionAddModel from './components/condition-add-model'
  import UserChoose from '@/components/userChoose'
  import { deepCopy } from 'kits'
  import { SettleConfigInteractor } from '@/core/interactors/finance/config-manage'
  import modelDetailDialog from './components/model-detali-dialog.vue'
  import mixins from './mixins'
  export default {
    mixins: [mixins],
    components: {
      conditionAddModel,
      UserChoose,
      modelDetailDialog,
    },
    data() {
      return {
        personSearchForm: {
          area: '',
          templateStatus: '',
          personnoelId: '',
          name: '',
          status: '',
        },
        statusOption: configStatus,
        columns: personConfigColumns,
        personStatus: status,
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        tableData: [],
        tipContent: '模板类型+提成点，阶梯用逗号区分开',
        addModelVisible: false, // 模板弹窗
        modelDetailVisible: false,
        detailTable: [],
      }
    },
    created() {
      this.getRoles()

      this.getArea()
      this.getList()
    },
    methods: {
      async getList() {
        let params = {
          ...this.page,
          ...this.personSearchForm,
        }
        delete params.name
        let res = await SettleConfigInteractor.getPersonnelListPage(params)
        this.tableData = res.data
        this.total = res.total
      },

      search() {
        this.page.pageNo = 1
        this.getList()
      },
      reset() {
        this.page.pageNo = 1
        for (const key in this.personSearchForm) {
          this.personSearchForm[key] = ''
        }
        this.getList()
      },

      showDetail(row) {
        this.detailTable = row.tieredVO
        this.modelDetailVisible = true
      },

      fromBusinessChange() {
        this.$refs['UserChoose'].showAddEdit(
          '1',
          deepCopy(this.businessArray),
          true,
          1
        )
      },
      // 删除客户代表
      clearFromBusinessName() {
        this.personSearchForm.personnoelId = ''
        this.personSearchForm.name = ''
      },

      chooseUser(userIdArr) {
        this.personSearchForm.personnoelId = userIdArr[0].id
        this.personSearchForm.name = userIdArr[0].name
      },
      //  添加
      goAdd() {
        this.addModelVisible = true
      },
      // 详情
      goDetail(val) {
        this.$router.push({
          path: '/finance-center/person-settlement-detail',
          query: {
            str: 'detail',
            id: val.configCommissionId,
            uid: val.personnoelId,
          },
        })
      },
      // 编辑
      goEdit(val) {
        this.$router.push({
          path: '/finance-center/person-settlement-detail',
          query: {
            str: 'edit',
            id: val.configCommissionId,
            uid: val.personnoelId,
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .config-table {
    margin-top: 20px;
    .table-fixed {
      max-height: 550px !important;
      ::v-deep {
        // .el-table {
        //   height: 600px !important;
        // }
        //   th {
        //     height: 35% !important;
        //     max-height: 35px !important;
        //     line-height: 35px !important;
        //   }
        //   td {
        //     height: 35% !important;
        //     max-height: 35px !important;
        //     line-height: 35px !important;
        //   }
        .table-custom {
          height: 100%;
          .el-table {
            height: 100% !important;
          }
        }
        .el-tooltip {
          width: auto !important;
        }
      }
    }
  }
</style>
