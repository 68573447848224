<template>
  <el-dialog
    :title="$t(params.title)"
    width="1200px"
    class="businessResponse"
    :visible.sync="params.show"
    :close-on-click-modal="false"
    :before-close="close"
    
  >
    <div class="detail-wrapper">
    <!-- 基础信息 -->
    <ErpSubTitle :title="$t('cusCenter.baseInfo')" /></ErpSubTitle>
    <el-row >
      <!-- 评价类型 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.Evaluationtype')}}</span>
        <span class="text">{{getEvaluationType(detail.evaluationType)}}</span>
      </el-col>
      <!-- 评价原因 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.evaluationReason')}}</span>
        <span class="text">{{  getshowReason(detail.reason) }}</span>
      </el-col>
      <!-- 原因说明 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.CauseDescription')}}</span>
        <span class="text">{{ getReasonExplain(detail.reasonExplain) }}</span>
      </el-col>
    </el-row>

    <el-row>
      <!-- 评价来源 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.EvaluationSource')}}</span>
        <span class="text">{{detail.evaluationSource}}</span>
      </el-col>
      <!-- 评价等级 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.EvaluationLevel')}}</span>
        <span class="text">{{getlevel(detail.level)}}</span>
      </el-col>
      <!-- 评价日期 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.EvaluationDate')}}</span>
        <span class="text">{{detail.evaluationDate}}</span>
      </el-col>
    </el-row>

    <el-row>
      <!-- 客户邮箱 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.CustomerMailbox')}}</span>
        <span class="text">{{detail.releatedCustomerEmail}}</span>
      </el-col>
      <!-- 客户名称 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.CustomerName')}}</span>
        <span class="text">{{detail.releatedCustomer}}</span>
      </el-col>
      <!-- 客户公司 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.CustomerCompany')}}</span>
        <span class="text">{{detail.companyName}}</span>
      </el-col>
      <!-- 客户电话 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.CustomerPhone')}}</span>
        <span class="text">{{detail.releatedCustomerPhone}}</span>
      </el-col>
    </el-row>

    <el-row>
      <!-- 销售订单 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.SalesOrder')}}</span>
        <span class="text">{{detail.orderNumber || '--'}}</span>
      </el-col>
        <!-- 产品名称 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.ProductName')}}</span>
        <span class="text"> {{lang === 'en' ? detail.productEn || '--' : detail.productCn || '--'}}</span>
      </el-col>
      <!-- 产品编码 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.ProductCode')}}</span>
        <span class="text">{{detail.sku || '--'}}</span>
      </el-col>
      <!-- 产品数量 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.Quantity')}}</span>
        <span class="text">{{detail.productNum || '--'}}</span>
      </el-col>
    </el-row>

    <el-row>
      <!-- 客户订单号 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.CustomerOrderNumber')}}</span>
        <span class="text">{{detail.customerOrderCode || '--'}}</span>
      </el-col>
        <!-- 公司抬头 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.ExportsRise')}}</span>
        <span class="text"> {{detail.exportsRise || '--'}}</span>
      </el-col>
      <!-- 客户代表 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.CustomerRepresentative')}}</span>
        <span class="text">{{detail[lang === 'en' ? 'salesmanEn' : 'salesman'] ?detail[lang === 'en' ? 'salesmanEn' : 'salesman'] :'--'}}</span>
      </el-col>
    </el-row>

    <el-row>
      <!-- 创建人 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.Creator')}}</span>
        <span class="text"> <span class="text">{{detail[lang === 'en' ? 'creatorEn' : 'creator']}}</span></span>
      </el-col>
        <!-- 创建时间 -->
      <el-col :span="6" :class="{'column': lang=='en' }">
        <span class="label">{{$t('cusCenter.creationtime')}}</span>
        <span class="text"> <span class="text">{{detail.createTime}}</span></span>
      </el-col>
    </el-row>

    <!-- 回复历史 -->
    <ErpSubTitle :title="$t('cusCenter.ReplyHistory')" />
    <div class="history-wrapper" v-if="detail.customerComplainRecordVOs">
      <div v-for="(item,index) in detail.customerComplainRecordVOs" :key="item.customerComplainRecordId" class="history-item-wrapper" :class="{'line-dashed':index!==detail.customerComplainRecordVOs.length -1}">
        <div class="history-item" >
          <div class="history-item--left">
            <!-- 类型 -->
            <span class="label" :title="getTransactionType(item.transactionType)">{{getTransactionType(item.transactionType) }}</span>
            <!-- 时间 -->
            <span :title="item.time">{{item.createTime}}</span>
          </div>
          <div class="history-item--right">
            {{item.concent}}
          </div>
        </div>
        <div class="file-wrapper" v-if="item.fileAnnex">
          <ErpUpload
            :ref="'upload' + item.customerComplainRecordId"
            
            :uploadParams="{uploadButton: false}"
            style="margin-bottom: 40px; background: #e4e7ed; padding: 15px;width:470px;"
        ></ErpUpload>
        </div>
      </div>
    </div>
    <!-- 回复 -->
    <ErpSubTitle :title="$t('cusCenter.Reply')" />
    <el-form :label-position="lang === 'en' ? 'top':'left'" label-width="140px"  :model="form" ref="ruleForm">
      <el-form-item :label="getTransactionType(params.transactionType)" :rules="[
      {
        required: true,
        trigger: 'blur',
        message: $t('placeholder.plsInput'),
      },
    ]"
    prop="concent"
    >
        <el-input type="textarea" maxlength="5000"  show-word-limit :rows="3" v-model="form.concent"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cusCenter.appendix')" prop="concent">
        <ErpUpload
          ref="ErpUpload"
          :uploadParams="uploadParams"
          @uploadList="uploadList"
          style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
        ></ErpUpload>
      </el-form-item>
    </el-form>

  </div>
  <div class="text-center">
    <!-- <el-button type="primary" @click="save">确认</el-button>
    <el-button @click="close()">取消</el-button> -->
    <el-button type="primary" :loading="loading" @click="save">
      {{ $t('cusCenter.Confirm') }}
    </el-button>
    <el-button @click="close()">{{ $t('cusCenter.Cancel') }}</el-button>
  </div>
  </el-dialog>
</template>

<script>
import { feedbackOrAppend } from '@/api/customer-manage/customer-center'
import mixin from '../../helper/mixins'
export default {
  name: 'BusinessReply',
  mixins: [mixin],
  props: {
    params: {
      //控制参数
      type: Object,
      default: () => ({
        show: false,
        title: 'cusCenter.Replytoreview',
        transactionType: 1,
      }),
    },
    detail: {
      //客诉详情
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading:false,
      // title: '新增收货信息',
      // title: 'cusCenter.Replytoreview',
      businessId: '',
      rules: {},

      uploadParams: {
        size: 1024 * 1024 * 20, //单个文件上传大小
        amount: 9,
      },
      form: {
        fileAnnex: '',
        concent: '',
      },
    }
  },

  methods: {
    // 文件初始化
    initFiles(id) {
      this.businessId = id

      const { customerComplainRecordVOs } = this.detail
      customerComplainRecordVOs.forEach((item) => {
        item.fileAnnex &&
          this.$refs['upload' + item.customerComplainRecordId][0].initUpload(
            item.fileAnnex,
            false
          )
      })
    },

    // 上传回调
    uploadList(fileList) {
      this.form.fileAnnex = JSON.stringify(fileList)
    },

    // 保存
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            concent: this.form.concent,
            fileAnnex: this.form.fileAnnex,
            customerComplainId: this.detail.customerComplainId || 1,
            customerComplainRecordId: this.detail.customerComplainRecordId,
            tenantId: this.userInfo.tenantId,
            transactionType: this.params.transactionType,
          }
            this.loading = true
          feedbackOrAppend(params).then((res) => {
            this.loading = false
            if (res?.code === '000000') {
              this.$emit('refresh')
              this.$message.success(
                this.lang == 'en' ? 'Operate success' : '操作成功'
              )
              if(this.$parent?.$parent?.getRecords) {
                this.$parent.$parent.getRecords(this.businessId)
              } else {
                this.$emit('refresh')
              }
               this.close()
            }
          })
        }
      })
    },

    // 关闭弹窗重置表单
    close() {
      this.form.concent = ''
      this.form.fileAnnex = ''
      this.params.show = false
      this.$refs.ErpUpload.clearFiles()
      this.$refs.ruleForm.clearValidate()
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  max-height: 500px;
  overflow: auto;
  padding-right: 10px;
}
.el-row {
  margin-bottom: 10px;
  .el-col {
    display: flex;
    &.column {
      flex-direction: column;
    }
    span {
      display: inline-block;
      // font-size: 16px;
      &.label {
        color: #555555;
        margin-right: 10px;
      }
      &.text {
        color: #000000;
      }
    }
  }
}
.history-item-wrapper {
  margin-bottom: 20px;
  padding-bottom: 5px;
  &.line-dashed {
    border-bottom: 1px dashed #c0c4cc;
  }
  .history-item {
    display: flex;

    .history-item--left {
      display: flex;
      flex-direction: column;
      width: 150px;
      span {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .history-item--right {
      flex: 1;
    }
  }
}
.file-wrapper {
  margin-left: 150px;
}

.w160 {
  width: 160px;
}
.w180 {
  width: 180px;
}
</style>
