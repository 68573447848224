<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form :model="rejectForm" :rules="rejectRules" ref="rejectForm">
        <el-form-item label="驳回原因" prop="statusRemark" label-width="80px">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="500"
            show-word-limit
            v-model.trim="rejectForm.statusRemark"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="saveReject">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { updateFinanceStatusReject } from '@/api/finance/credit-manage-list'
export default {
  name: 'RejectModel',
  data() {
    return {
      title: '驳回',
      rejectForm: {
        financeIds: [],
        statusRemark: '', // 驳回原因
      },
      rejectRules: {
        statusRemark: [
          { required: true, message: '请填写驳回原因', trigger: 'blur' },
        ],
      },
      dialogFormVisible: false,
    }
  },
  created() {},
  methods: {
    //显示
    showAddEdit() {
      this.dialogFormVisible = true
    },

    //关闭
    close() {
      this.$refs['rejectForm'].resetFields()
      this.dialogFormVisible = false
    },
    // 驳回和批量驳回
    async batchReject() {
      let params = {
        financeIds: this.rejectForm.financeIds,
        statusRemark: this.rejectForm.statusRemark,
      }
      let response = await updateFinanceStatusReject(params)
      if (response.code === '000000') {
        this.$message.success('驳回成功！')
        this.$emit('updateList')
        this.close()
      }
    },

    // 点击确定驳回
    saveReject() {
      this.$refs['rejectForm'].validate(async (valid) => {
        if (valid) {
          this.batchReject()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
.text-hidden {
  width: 150px;
}
</style>
