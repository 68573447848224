<template>
  <div class="table-container" v-if="$route.meta.showParent">
    <el-row class="page-header align_center" type="flex">
      <el-row type="flex" class="align_center">
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="onAdd"
          v-allowed="['ADD-WH']"
        >
          新增
        </el-button>
        <!-- 查询表单 -->
        <el-form
          ref="form"
          class="ml10"
          :inline="true"
          label-width="49px"
          :model="searchForm"
          @submit.native.prevent
        >
          <el-form-item prop="name">
            <el-input
              style="width: 280px"
              v-model="searchForm.name"
              placeholder="输入仓库名称/仓库编码"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="onSearchButton" class="pub-h-36">
          搜索
        </el-button>
        <el-button @click="onReset" class="pub-h-36">重置</el-button>
      </el-row>
    </el-row>
    <p class="divider"></p>

    <ErpTable :tableData="tableData" :pagination="false" :extraHeight="90">
      <!-- 仓库编码-->
      <el-table-column
        align="center"
        label="仓库编码"
        prop="warehouseNumber"
      ></el-table-column>
      <!-- 仓库名称-->
      <el-table-column
        align="center"
        label="仓库名称"
        show-overflow-tooltip
        prop="name"
      ></el-table-column>
      <!-- 仓库英文名称-->
      <el-table-column
        align="center"
        label="仓库英文名称"
        show-overflow-tooltip
        prop="nameEn"
      ></el-table-column>
      <!-- 仓库区域-->
      <!-- 1.4.2仓库区域改成所属区域 -->
      <el-table-column align="center" label="所属区域" prop="area">
        <template slot-scope="scope">
          <span v-if="scope.row.belong === 2">--</span>
          <span v-else>{{ scope.row.area ? scope.row.area : '--' }}</span>
        </template>
      </el-table-column>
      <!-- 仓库地址-->
      <el-table-column
        align="center"
        label="仓库地址"
        show-overflow-tooltip
        prop="address"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.belong === 2">--</span>
          <span v-else>
            {{ scope.row.address ? scope.row.address : '--' }}
          </span>
        </template>
      </el-table-column>
      <!-- 仓管电话-->
      <el-table-column align="center" label="仓库电话" prop="ownerPhone">
        <template slot-scope="scope">
          <span v-if="scope.row.belong === 2">-</span>
          <span v-else>
            {{ scope.row.ownerPhone ? scope.row.ownerPhone : '--' }}
          </span>
        </template>
      </el-table-column>
      <!-- 仓库归属-->
      <el-table-column align="center" label="仓库类型" prop="belong">
        <template slot-scope="scope">
          <!-- {{ scope.row.belong === 1 ? '第三方仓库' : '公司仓库' }} -->

          <span v-if="scope.row.belong === 2">--</span>
          <span v-else>
            {{ scope.row.belong === 1 ? '第三方' : '自营仓' }}
          </span>
        </template>
      </el-table-column>
      <!-- 仓管员-->
      <el-table-column
        align="center"
        label="仓管员"
        prop="ownerName"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.belong === 2">--</span>
          <span v-else>
            {{ scope.row.ownerName ? scope.row.ownerName : '--' }}
          </span>
        </template>
      </el-table-column>

      <!-- 状态-->
      <el-table-column
        align="center"
        label="状态"
        prop="ownerName"
        width="100px"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.deleted == '0' ? 'success' : 'danger'">
            {{ scope.row.deleted == '0' ? '启用' : '禁用' }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- 创建时间 -->
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="180px"
      ></el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.belong === 2">--</span>
          <p class="operation" v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top-start"
            >
              <el-button
                v-allowed="['EDIT-WH']"
                type="primary"
                plain
                icon="el-icon-edit"
                circle
                size="mini"
                @click="onEdit(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="库位设置"
              placement="top-start"
              v-show="scope.row.deleted !== 1"
            >
              <el-button
                v-allowed="['STOCK-LOCTION-SET']"
                type="primary"
                plain
                circle
                size="mini"
                @click="setLoc(scope.row)"
              >
                <i
                  class="iconfont icon-caidanlan-kucun-kuweiguanli"
                  v-if="scope.row.deleted == 0"
                ></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.deleted !== 0 ? '启用' : '禁用'"
              placement="top-start"
            >
              <el-button
                v-allowed="['DE-WH']"
                :type="scope.row.deleted == 0 ? 'danger' : 'primary'"
                plain
                circle
                size="mini"
                @click="
                  scope.row.deleted !== 0
                    ? enable(scope.row)
                    : disable(scope.row)
                "
              >
                <i
                  class="iconfont icon-lajiyoujian"
                  v-if="scope.row.deleted == 0"
                ></i>
                <i v-else class="iconfont icon-qiyong"></i>
              </el-button>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
      <!-- </el-table> -->
      <template v-slot:pagination>
        <Pagination
          v-if="page.total > 0"
          :limit.sync="page.pageLe"
          :page.sync="page.pageNo"
          :total="page.total"
          @pagination="onPagination"
        />
      </template>
    </ErpTable>

    <!-- 人员选择 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser"></UserChoose>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      width="500px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="dialog-content">
        <!-- 仓库名称 -->
        <el-form
          ref="dialogForm"
          :model="form"
          :rules="rules"
          label-width="110px"
        >
          <el-form-item label="仓库名称" prop="name">
            <el-input v-model.trim="form.name" placeholder="请输入仓库名称" />
          </el-form-item>
          <el-form-item label="仓库英文名称" prop="nameEn">
            <el-input
              v-model.trim="form.nameEn"
              placeholder="请输入仓库英文名称"
            />
          </el-form-item>
          <!-- 所属区域 -->
          <el-form-item label="所属区域" prop="area">
            <el-select
              v-model="form.area"
              clearable
              allow-create
              filterable
              placeholder="请输入仓库所在城市,如：苏州"
            >
              <el-option
                v-for="item in areas"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 仓库类型 -->
          <el-form-item label="仓库类型" prop="belong">
            <el-select v-model="form.belong" placeholder="请选择">
              <el-option
                v-for="item in warehouseBelongs"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- 仓库地址 -->
          <el-form-item label="仓库地址" prop="address">
            <el-input
              v-model.trim="form.address"
              placeholder="请输入仓库地址"
            />
          </el-form-item>

          <!-- 仓管员 -->
          <el-form-item label="仓管员" prop="ownerName">
            <el-input
              @focus="showUserDialog"
              v-model.trim="form.ownerName"
              placeholder="请添加仓管员"
              clearable
            />
          </el-form-item>
          <!-- 仓库电话 -->
          <el-form-item label="仓库电话" prop="ownerPhone">
            <el-input v-model="form.ownerPhone" placeholder="请输入仓库电话" />
          </el-form-item>
          <!-- 是否勾选 -->
          <el-form-item label="" prop="flag">
            <el-checkbox v-model="form.flag" :true-label="1" :false-label="0">
              <span style="color: #606266">一个库位允许放置多种SKU产品</span>
            </el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-row class="text-center">
          <el-button type="primary" @click="save" :loading="isLoading">
            确 认
          </el-button>
          <el-button @click="close">取 消</el-button>
        </el-row>
      </template>
    </el-dialog>
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import { WarehouseManageInteractor } from '@/core'
  import Pagination from '@/components/Pagination/Index'
  import { mapActions } from 'vuex'
  import UserChoose from '@/components/userChoose/index'
  let self
  let required = true
  let rules = {
    name: [
      {
        required: true,
        message: '请输入仓库名称',
        trigger: ['blur', 'change'],
      },
      {
        max: 50,
        message: '仓库名称不能超过50个字符',
        trigger: ['blur', 'change'],
      },
    ],
    nameEn: [
      {
        required: true,
        message: '请输入仓库英文名称',
        trigger: ['blur', 'change'],
      },
      {
        max: 50,
        message: '仓库英文名称不能超过50个字符',
        trigger: ['blur', 'change'],
      },
    ],
    area: [{ required: true, message: '请选择仓库区域', trigger: 'change' }],
    address: [
      {
        required: true,
        message: '请输入仓库地址',
        trigger: ['blur', 'change'],
      },
      {
        max: 100,
        message: '仓库地址不能超过100个字符',
        trigger: ['blur', 'change'],
      },
    ],
    ownerName: [{ required: true, message: '请选择仓管员', trigger: 'change' }],
    belong: [{ required: true, message: '请选择仓库归属', trigger: 'change' }],
    ownerPhone: [
      {
        max: 20,
        message: '仓库电话最大长度不能超过20',
        trigger: 'blur',
      },
      {
        validator: (rule, value, callback) => {
          if (!value) {
            return callback()
          }
          value = value.trim()
          if (!/^\d+$/.test(value)) {
            callback(new Error('仓库电话必须为数字'))
          } else {
            callback()
          }
        },
      },
    ],
  }
  export default {
    name: 'InboundSheet',
    components: { Pagination, UserChoose },
    data() {
      self = this
      return {
        dialogTitle: '新增仓库',
        searchForm: {
          warehouseId: '',
          name: '',
        },
        isLoading: false,
        form: {
          address: '',
          area: '',
          belong: '',
          name: '',
          nameEn: '',
          ownerId: '',
          ownerName: '',
          ownerPhone: '',
          warehouseId: '',
          flag: 0, //库位是否允许放置多种SKU产品(0不允许，1允许)
        },

        rules,
        showDialog: false,
        tableData: [],
        page: {
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        warehouses: [],
        areas: ['合肥', '苏州', '义乌', '美国'],
        warehouseBelongs: [
          //仓库归属
          {
            code: 0,
            label: '自营仓',
          },
          {
            code: 1,
            label: '第三方',
          },
        ],
      }
    },

    methods: {
      ...mapActions(['warehouseCenter/setLocations']),
      onSearchButton() {
        this.page.pageNo = 1
        const { pageLe, pageNo } = this.page
        WarehouseManageInteractor.getListPage({
          ...this.searchForm,
          pageLe,
          pageNo,
        }).then((res) => {
          this.tableData = res.data.data || []
          this.page.total = res.data.total
        })
      },
      onPagination({ page }) {
        this.page.pageNo = page
        this.onSearch()
      },
      onSearch() {
        const { pageLe, pageNo } = this.page
        WarehouseManageInteractor.getListPage({
          ...this.searchForm,
          pageLe,
          pageNo,
        }).then((res) => {
          this.tableData = res.data.data || []
          this.page.total = res.data.total
        })
      },
      onAdd() {
        this.showDialog = true
        this.dialogTitle = '新增仓库'
      },

      onReset() {
        this.page.pageNo = 1
        this.$refs.form.resetFields()
        this.onSearch()
      },
      showUserDialog() {
        let { ownerId, ownerName } = this.form,
          selectUsers = []
        if (ownerId && ownerName) {
          let ownerIds = ownerId.split(',')
          let ownerNames = ownerName.split(',')

          ownerIds.forEach((item, index) => {
            selectUsers.push({
              name: ownerNames[index],
              id: item,
            })
          })
        }
        this.$refs['UserChoose'].showAddEdit('1', selectUsers)
      },
      chooseUser(users) {
        this.form.ownerId = users.map((i) => i.id).join(',')
        this.form.ownerName = users.map((i) => i.name).join(',')
      },
      refresh() {
        this.close()
        this.page.pageNo = 1
        this.onSearch()
      },

      save() {
        this.$refs.dialogForm.validate((flag) => {
          if (flag) {
            this.isLoading = true
            this.warehouseId
              ? WarehouseManageInteractor.updateWarehouse({
                  ...this.form,
                  warehouseId: this.warehouseId,
                })
                  .then((res) => {
                    if (res && res.code === '000000') {
                      this.$message.success('编辑仓库成功')
                      this.isLoading = false
                      this.refresh()
                    } else {
                      this.isLoading = false
                    }
                  })
                  .catch(() => {
                    this.isLoading = false
                  })
              : WarehouseManageInteractor.insertWarehouse({ ...this.form })
                  .then((res) => {
                    if (res && res.code === '000000') {
                      this.$message.success('新增仓库成功')
                      this.refresh()
                      this.isLoading = false
                    } else {
                      this.isLoading = false
                    }
                  })
                  .catch(() => {
                    this.isLoading = false
                  })
          }
        })
      },
      close() {
        this.form.warehouseId = ''

        this.showDialog = false
        this.isLoading = false
        this.$_setWarehouseId()
        this.form = this.$options.data().form
        this.$refs.dialogForm.resetFields()
      },

      // 处理仓库id
      $_setWarehouseId(id) {
        this.warehouseId = id ? id : ''
      },

      // 编辑处理
      onEdit(row) {
        this.dialogTitle = '编辑'
        this.$_setWarehouseId(row.warehouseId)
        Reflect.ownKeys(this.form).forEach((key) => {
          this.form[key] = row[key]
        })
        this.showDialog = true
      },

      // 启用
      enable({ warehouseId }) {
        WarehouseManageInteractor.enable(warehouseId).then((res) => {
          if (res.code == '000000') {
            this.$message.success('启用成功')
            this.onSearch()
          }
        })
      },

      // 失效
      disable({ warehouseId }) {
        this.$confirm('入库/出库将不能再选择此仓库，请谨慎操作！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            WarehouseManageInteractor.disable(warehouseId).then((res) => {
              if (res && res.code == '000000') {
                this.$message.success('禁用成功')
                this.onSearch()
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消禁用',
            })
          })
      },

      setLoc({ warehouseId }) {
        this.$router.push({
          path: '/stock-center/warehouse-manage/warehouse-manage/location-setting',
          query: {
            warehouseId,
          },
        })
      },
    },
    created() {
      WarehouseManageInteractor.findWarehouseList({
        hasAuth: 0,
        hasCloudWarehouse: 0,
      }).then((res) => {
        this.warehouses = res.data || []
      })
      this.onSearch()
    },
    watch: {
      'form.belong': function (val) {
        if (val === 1) {
          rules['area'][0].required = false
          rules['ownerName'][0].required = false
          setTimeout(() => {
            this.$refs.dialogForm.clearValidate(['area'])
          }, 200)
        } else {
          rules['area'][0].required = true
          rules['ownerName'][0].required = true
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    height: 33px;
  }
  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }
  .calcu-p {
    font-size: 12px;
    color: #999;
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .el-dialog-div {
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
  }
  ::v-deep {
    .el-dialog-div {
      .el-form {
        width: 95% !important;
        margin: 0 auto !important;
      }
    }
  }
  .pointer {
    cursor: pointer;
  }
  .operation {
    display: flex;
    justify-content: space-evenly;
    i {
      font-size: 12px;
    }
  }
  .ml-10 {
    margin-left: 10px;
  }
</style>
<style>
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
