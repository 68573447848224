import { render, staticRenderFns } from "./subProductList.vue?vue&type=template&id=59462430&"
import script from "./subProductList.vue?vue&type=script&lang=js&"
export * from "./subProductList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports