<template>
  <el-dialog
    :title="$t(title)"
    :visible.sync="dialogFormVisible"
    width="850px"
    @close="close"
    :close-on-click-modal="false"
    class="dialog-fixed"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('cusManage.CustomerMailbox')"
              prop="email"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: this.$t('placeholder.plsInput'),
                },
                {
                  validator: this.$formValidation.isEmail,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model.trim="form.email"
                :placeholder="$t('cusManage.CustomerMailbox')"
                @blur="emailBlur(form.email)"
              />
              <p v-if="emailRepeat">
                <!-- 该客户已存在，你可以向 -->
                {{ $t('other.$1') }}
                {{ lang == 'en' ? belongerNameEn : belongerName }}
                <span @click="assignClick" class="blue-text f_s_12">
                  <!-- 申请分管 -->
                  {{ $t('other.$2') }}
                </span>
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('cusManage.CustomerName')"
              prop="customerName"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: this.$t('placeholder.plsInput'),
                },
              ]"
            >
              <el-input
                v-model="form.customerName"
                :placeholder="$t('cusManage.CustomerName')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('cusManage.CompanyName')"
              prop="companyName"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: this.$t('placeholder.plsInput'),
                },
              ]"
            >
              <el-autocomplete
                v-model="form.companyName"
                class="w100"
                :fetch-suggestions="querySearchAsync"
                :placeholder="$t('placeholder.plsSel')"
                clearable
                type="textarea"
                :rows="2"
                :maxlength="500"
                show-word-limit
                v-loadmore="loadMore"
                @select="productHandleSelect"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('cusManage.CompanyAddress')"
              prop="companyAddress"
            >
              <el-input
                v-model="form.companyAddress"
                :placeholder="$t('cusManage.CompanyAddress')"
                :maxlength="50"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('cusManage.PaymentMethod')"
              prop="paymentId"
            >
              <el-select
                v-model="form.paymentId"
                :placeholder="$t('placeholder.plsSel')"
                class="logMgCls"
                multiple
                @change="payChange"
                collapse-tags
              >
                <el-option
                  v-for="item in payWaySelect"
                  :key="item.paymentId"
                  :label="lang === 'en' ? item.paywayEn : item.payway"
                  :value="item.paymentId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('cusManage.ContactNumber')" prop="phone">
              <el-input
                v-model.trim="form.phone"
                :placeholder="$t('cusManage.ContactNumber')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              :label="$t('cusManage.CustomerSource')"
              prop="customerSourceId"
            >
              <el-select
                v-model="form.customerSourceId"
                :placeholder="$t('placeholder.plsSel')"
                @change="customerSourceChange"
              >
                <el-option
                  v-for="item in customerSource"
                  :key="item.sourceId"
                  :label="lang === 'en' ? item.sourceNameEn : item.sourceName"
                  :value="item.sourceId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('cusManage.CustomerLevel')"
              prop="customerRank"
            >
              <el-select
                v-model="form.customerRank"
                :placeholder="$t('placeholder.plsSel')"
              >
                <el-option
                  v-for="item in customerRankOptions"
                  :key="item.value"
                  :label="$t('selOpt.l' + item.value)"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('cusManage.WebAddress')" prop="website">
              <el-input
                v-model.trim="form.website"
                :placeholder="$t('cusManage.WebAddress')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('cusManage.FaxNumber')" prop="faxNumber">
              <el-input
                v-model.trim="form.faxNumber"
                :placeholder="$t('cusManage.FaxNumber')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col>
            <el-form-item :label="$t('cusManage.Remark')" prop="remark">
              <el-input
                type="textarea"
                :placeholder="$t('placeholder.rc')"
                v-model.trim="form.remark"
                :autosize="{ minRows: 2 }"
                :maxlength="500"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template #footer center>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          {{ $t('cusManage.Confirm') }}
        </el-button>
        <el-button @click="close">{{ $t('cusManage.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { doEdit } from '@/api/table'
  let companies = []

  import {
    clientInsertPO,
    checkEmail,
    listCompany,
    customerBranchedApply,
  } from '@/api/client-supplier/client-manage'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ClientManageAddModel',
    data() {
      return {
        loading: false,
        pageLe: 10,
        pageNo: 1,
        total: 0,
        form: {
          email: '',
          customerName: '',
          companyName: '',
          linkmanDTOS: [],
          customerRank: '1',
        },
        rules: {},
        title: 'dialogTitle.newcustomers',
        dialogFormVisible: false,
        customerRankOptions: [
          //客户等级
          {
            value: '1',
            label: '1级',
          },
          {
            value: '2',
            label: '2级',
          },
          {
            value: '3',
            label: '3级',
          },
          {
            value: '4',
            label: '4级',
          },
          {
            value: '5',
            label: '5级',
          },
        ],
        value: '',
        payWaySelect: [], //付款方式下拉
        customerSource: [], //客户来源下拉
        emailRepeat: false, //邮箱是否重复
        branchedId: '', //分管人id(当前登录人id)
        belongerName: '', //分管人姓名
        belongerNameEn: '',
        infoId: '', //infoId
        signPrice: '', // 价格区间标识
        payChangeArr: [], //选择的付款方式
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),

      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit(row) {
        if (!row) {
          this.title = 'dialogTitle.newcustomers'
        } else {
          this.title = 'dialogTitle.editcustomers'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
        //客户付款方式下拉
        this.pubApi.paymentList({ payee: 1 }).then((response) => {
          if (response.code === '000000') {
            if (response?.data) {
              response.data = response.data.map((i) => {
                i.paywayEn = i.payway.replace('全部', 'All')
                return i
              })
            }
            this.payWaySelect = response.data
          }
        })
        //客户来源下拉
        this.pubApi.customerSourceList({}).then((response) => {
          if (response.code === '000000') {
            this.customerSource = response.data
          }
        })
      },
      //客户邮箱校验是否重复
      async emailBlur(val) {
        let response = await checkEmail({ email: val })
        if (response.code === '000000' && response.data.repet) {
          this.emailRepeat = true
          this.branchedId = response.data.branchedId
          this.belongerName = response.data.belongerName
          this.belongerNameEn = response.data.belongerNameEn
          this.infoId = response.data.infoId[0]
        } else {
          this.emailRepeat = false
        }
      },
      //分管
      async assignClick() {
        let params = {
          infoId: this.infoId,
          branchedId: this.branchedId,
          email: this.form.email,
        }
        let response = await customerBranchedApply(params)
        if (response.code === '000000' && response.data !== 0) {
          this.$baseMessage(
            // '已申请分管该客户',
            this.$t('reqmsg.$5'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.form.email = ''
          this.emailRepeat = false
          this.branchedId = ''
          this.infoId = ''
        }
      },

      //付款方式选择
      payChange(val) {},
      //客户来源选择
      customerSourceChange(val) {
        let changeItem = this.customerSource.filter((item) => {
          if (item.sourceId === val) {
            return item.sourceName
          }
        })
        this.form.customerSourceName = changeItem[0].sourceName
      },

      //公司名称实时检索
      async querySearchAsync(queryString, cb) {
        this.cb = cb
        const requestData = {
          pageLe: this.pageLe,
          pageNo: this.pageNo,
          companyName: this.form.companyName,
        }
        await listCompany(requestData).then((res) => {
          let results = res.data.data.map((item) => {
            return {
              ...item,
              value: item.companyName,
            }
          })
          companies = companies.concat(results || [])
          this.total = res.data.total
          cb(companies)
        })
      },

      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this.querySearchAsync('', this.cb)
      },

      _resetPageParams() {
        this.pageNo = 1
        this.total = 0
        companies = []
      },
      productHandleSelect(item) {},
      close() {
        this.emailRepeat = false
        this.$refs['form'].resetFields()
        // this.form = this.$options.data().form
        this._resetPageParams()
        this.dialogFormVisible = false
      },

      //客户新增
      async save() {
        if (this.emailRepeat) {
          return this.$baseMessage(
            // '客户邮箱重复!',
            this.$t('reqmsg.$6'),
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        let form = this.utils.deepCopy(this.form)
        form.customerName = form.customerName.trim()
        if (!form.customerName) {
          return this.$baseMessage(
            // '客户邮箱重复!',
            this.$t('reqmsg.$aaa'),
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            // if (this.title === '新增客户') {
            if (this.title === 'dialogTitle.newcustomers') {
              this.loading = true
              let response = await clientInsertPO(form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  // '操作成功',
                  this.$t('reqmsg.$7'),
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },

    watch: {
      'form.companyName': function () {
        this._resetPageParams()
      },
    },
  }
</script>

<style scoped lang="scss">
  .center {
    text-align: center;
  }
  .dialog-content {
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }
    .logMgCls {
      .el-select__tags {
        > span {
          display: flex;
        }
      }
      .el-select__tags-text {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .el-select .el-tag__close.el-icon-close {
        top: -5px !important;
      }
    }
    .el-textarea__inner {
      padding: 5px 50px 5px 10px;
    }
  }
</style>
