<template>
  <el-dialog title="" width="400px" :visible.sync="isShowTimeVisible" :close-on-click-modal="false"
    :close-on-press-escape="false">
    <el-form :model="form" :rules="rules" ref="form" label-position="top">
      <!-- 选择定时发送的时间： -->
      <el-form-item prop="time" :label="$t('other.mail08')">
        <!-- 选择日期时间 -->
        <el-date-picker v-model="form.time" style="width: 100%" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
          :placeholder="$t('placeholder.selDate')"></el-date-picker>
      </el-form-item>
    </el-form>

    <div class="tip">
      <!-- 本邮件将在 -->
      {{ $t('other.mail06') }}
      {{ form.time ? utils.formatTime(form.time, 'mail-info') : '--' }}
      <!-- 投递到对方邮箱。 -->
      {{ $t('other.mail07') }}
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- 、取 消 -->
      <el-button @click="close">{{ $t('reqmsg.M2007') }}</el-button>
      <!-- 确 定 -->
      <el-button type="primary" @click="save">
        {{ $t('reqmsg.M2006') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        isShowTimeVisible: false,
        form: {
          time: '',
        },
        // rules: {
        //   time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        // },
      }
    },
    computed: {
      rules() {
        return {
          time: [
            {
              required: true,
              message: this.$t('reqmsg.mail06'),
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                if (new Date(value).getTime() < new Date().getTime()) {
                  callback(this.$t('myMail.checkTime'))
                } else {
                  callback()
                }

              },
              trigger: 'change',
            }
          ],
        }
      },
    },
    methods: {
      showDialog(time) {
        this.isShowTimeVisible = true
        this.form.time = time || ''
      },
      close() {
        this.form.time = ''
        this.isShowTimeVisible = false
      },
      save() {

        this.$refs.form.validate((valid) => {

          if (valid) {
            this.$emit('confirm', this.form.time)
            this.close()
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .tip {
    font-size: 14px;
    color: #999;
  }
</style>