const Dicts = {
  // 状态
  statusList: [
    {
      label: '全部',
      value: '',
    },
    {
      label: '审核中',
      value: 1,
    },
    {
      label: '待入库',
      value: 2,
    },
    {
      label: '已完成',
      value: 5,
    },
    {
      label: '已驳回',
      value: 3,
    },
    {
      label: '已撤销',
      value: 4,
    },
    {
      label: '已取消',
      value: 0,
    },
  ],

  // 出库方式
  typeNames: [
    {
      type: 0,
      typeName: '调拨出库',
    },
    {
      type: 1,
      typeName: '同仓调拨',
    },
  ],

  // 交货方式
  deliveryModes: [
    {
      label: '国际物流',
      value: 2,
    },
    {
      label: '国内物流',
      value: 3,
    },
    {
      label: '自送',
      value: 4,
    },
  ],

  // 分摊方式
  expenseMethods: [
    {
      label: '按金额比例',
      value: 6,
    },
    {
      label: '按数量',
      value: 5,
    },
    {
      label: '手动分摊',
      value: 7,
    },
  ],

  // 列表
  columns: [
    {
      label: '调拨单号',
      prop: 'transferOrderNumber',
      isShow: true,
      checkable: false,
      width: 150
    },
    {
      label: '出库方式',
      prop: 'warehouseOutType',
      isShow: true,
      checkable: false,
      width: 150
    },
    {
      label: '调出仓库',
      prop: 'warehouseOutName',
      isShow: true,
      checkable: false,
    },
    {
      label: '调入仓库',
      prop: 'warehouseInName',
      isShow: true,
      checkable: false,
    },
    {
      label: '预计到达时间',
      prop: 'arriveTime',
      isShow: true,
      checkable: true,
    },
    {
      label: '交货方式',
      prop: 'deliveryMode',
      isShow: true,
      checkable: true,
    },
    {
      label: '物流单号',
      prop: 'shipNo',
      isShow: true,
      checkable: true,
      width: 180
    },
    {
      label: '创建人',
      prop: 'creator',
      isShow: true,
      checkable: true,
    },
    {
      label: '所属区域',
      prop: 'area',
      isShow: true,
      checkable: true,
    },
    {
      label: '调拨数量',
      prop: 'num',
      isShow: true,
      checkable: true,
    },
    {
      label: '调拨出库成本',
      prop: 'cost',
      isShow: true,
      checkable: true,
    },
    {
      label: '创建日期',
      prop: 'createTime',
      isShow: true,
      checkable: true,
      width: 180
    },
    {
      label: '操作人',
      prop: 'updator',
      isShow: true,
      checkable: true,
    },
    {
      label: '状态',
      prop: 'transferOrderStatus',
      isShow: true,
      checkable: true,
    },
    {
      label: '备注',
      prop: 'transferOrderRemark',
      isShow: true,
      checkable: true,
    }
  ],

  // 交货方式转义
  deliveryModeName(n) {
    const dicts = {
      2: '国际物流',
      3: '国内物流',
      4: '自送',
    }

    return dicts[n]
  },

  // 状态转义
  transferOrderStatusName(n) {
    const dicts = {
      1: '审核中',
      2: '待入库',
      5: '已完成',
      3: '已驳回',
      4: '已撤销',
      0: '已取消',
    }

    return dicts[n]
  },

  // 出库方式转转义
  warehouseOutTypeName(n) {
    const dicts = {
      0: '调拨出库',
      1: '同仓调拨',
    }

    return dicts[n]
  }

}

export default Dicts