<template>
  <div class="mail-page">
    <div>
      <MailList ref="MailList" :listConfig="listConfig" @showInfo="showInfo" @clearMail="currentMail = null" />
    </div>
    <div>
      <MailInfo v-if="currentMail" ref="mailInfo" @updatePage="updatePage" />
      <NoMail v-else />
    </div>
  </div>
</template>

<script>
  import MailInfo from './components/MailInfo.vue'
  import MailList from './components/MailList.vue'
  import NoMail from './components/NoMail.vue'
  import { getEmailName, isInEmailName } from './utils/mail-data'
  export default {
    components: {
      MailList,
      MailInfo,
      NoMail,
    },
    data() {
      return {

        currentMail: null,
      }
    },
    watch: {
      '$store.state.mail.currentAccount': {
        handler(n) {

          this.currentMail = null
        },
      },
    },
    computed: {
      listConfig() {
        let obj = {}
        obj.flag = false
        obj.boxName = this.$route.query.mailName
        // 其他文件夹一律按收件箱逻辑
        obj.type = isInEmailName(this.$route.query.mailName)
          ? getEmailName(this.$route.query.mailName).type
          : 0
        return obj
      },
    },
    methods: {

      showInfo(data) {
        console.log('data===', data)

        setTimeout(() => {
          this.currentMail = data
        }, 10)
        if (data) {

          let self = this
          if (self.$refs.mailInfo) {
            self.$refs.mailInfo?.show(data)
          } else {
            setTimeout(() => {
              self.$refs.mailInfo?.show(data)
            }, 800)
          }
        }

      },

      updatePage() {
        this.$refs.MailList.updatePage()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mail-page {
    height: 100%;
    display: flex;

    &>div {
      height: 100%;
    }

    &>div:first-child {
      // width: 500px;
      flex-shrink: 0;
    }

    &>div:last-child {
      flex: 1;
      width: 0;
    }
  }
</style>