const NUMBER_LIST = [
  {
    id: 'number_1',
    name: '采购订单编号',
    nameEn: 'Purchase order number',
    value: 'purchaseOrderNumber',
  },
  {
    id: 'number_2',
    name: '订单跟踪编号',
    nameEn: 'order tracking number',
    value: 'orderTrackingNumber',
  },
  {
    id: 'number_3',
    name: '订单编号',
    nameEn: 'order number',
    value: 'salesOrderNumber',
  },
]
const OPER_LIST = [
  {
    id: 'OPER_1',
    name: '工厂交期',
    nameEn: 'Production time',
    value: '工厂交期',
  },
  {
    id: 'OPER_2',
    name: 'QC质检',
    nameEn: 'QC  Quality Control',
    value: 'QC质检',
  },
  {
    id: 'OPER_3',
    name: '物流货运',
    nameEn: 'In transit',
    value: '物流货运',
  },
  {
    id: 'OPER_4',
    name: '客户签收',
    nameEn: 'Signed',
    value: '客户签收',
  },
]
const AERA_LIST = [
  {
    id: 0,
    name: '全部',
    nameEn: 'all',
    value: '',
  },
  {
    id: 1,
    name: '合肥',
    nameEn: 'Hefei',
    value: '合肥',
  },
  {
    id: 2,
    name: '苏州',
    nameEn: 'Suzhou',
    value: '苏州',
  },
  {
    id: 3,
    name: '义乌',
    nameEn: 'Yiwu',
    value: '义乌',
  },
]
const STATUS_LIST = [
  {
    id: 'status_0',
    name: '全部',
    nameEn: 'all',
    value: '',
    active: true,
  },
  {
    id: 'status_5',
    name: '预警中',
    nameEn: 'Alert',
    value: '5',
    active: false,
  },
  {
    id: 'status_6',
    name: '延期',
    nameEn: 'Extended',
    value: '6',
    active: false,
  },
  {
    id: 'status_1',
    name: '待安排',
    nameEn: 'To be arranged',
    value: '1',
    active: false,
  },
  {
    id: 'status_2',
    name: '进行中',
    nameEn: 'In production',
    value: '2',
    active: false,
  },
  {
    id: 'status_3',
    name: '已完成',
    nameEn: 'completed',
    value: '3',
    active: false,
  },
  {
    id: 'status_7',
    name: '无需跟单',
    nameEn: 'No need to document',
    value: '7',
    active: false,
  },
  {
    id: 'status_4',
    name: '已作废',
    nameEn: 'Canceled',
    value: '4',
    active: false,
  }
]
const COLUMNS = [
  {
    label: '序号',
    width: '60',
    widthEn: '60',
    prop: 'NO',
    isShow: true,
    checkable: false,
    langKey: 'Documentary.SN',
  },
  {
    label: '地区',
    width: '80',
    prop: 'area',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.area',
  },
  {
    label: '订单跟踪编号',
    prop: 'orderTrackingNumber',
    isShow: true,
    checkable: false,
    langKey: 'Documentary.OTN',
    widthEn: '180',
  },
  {
    label: '单据创建日期',
    prop: 'createTime',
    isShow: true,
    checkable: false,
    langKey: 'Documentary.DCD',
    widthEn: '200',
  },
  {
    label: '采购订单编号',
    prop: 'purchaseOrderNumber',
    isShow: true,
    checkable: false,
    langKey: 'Documentary.PON',
    widthEn: '180',
  },
  {
    label: '订单编号',
    width: '120',
    prop: 'salesOrderNumber',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.ON',
    widthEn: '140',
  },
  {
    label: '采购开发',
    width: '120',
    prop: 'buyerName',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.PD',
    widthEn: '200',
  },
  {
    label: '订单支持',
    width: '120',
    prop: 'fromBusinessName',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.OS',
    widthEn: '140',
  },
  {
    label: '客户代表',
    width: '120',
    prop: 'businessName',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.CR',
    widthEn: '200',
  },
  {
    label: '供应商名称',
    width: '120',
    prop: 'supplierName',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.supplierName',
    widthEn: '160',
  },
  {
    label: '产品名称',
    width: '120',
    prop: 'productName',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.PN',
    widthEn: '180',
  },
  {
    label: '产品数量',
    width: '120',
    prop: 'quantity',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.Quantity',
  },
  {
    label: '产品总金额',
    width: '140',
    prop: 'amount',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.TPA',
    widthEn: '220',
  },
  {
    label: '采购单总金额',
    width: '140',
    prop: 'amountTotal',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.TPOA',
    widthEn: '220',
  },
  {
    label: '是否有样品',
    width: '100',
    prop: 'sampleDesc',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.ITAS',
    widthEn: '160',
  },
  {
    label: '当前节点',
    width: '120',
    prop: 'nodeNameCn',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.CN',
    widthEn: '180',
  },
  {
    label: '工厂交期（周期）',
    width: '140',
    prop: 'deliveryDays',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.FD',
    widthEn: '180',
  },
  {
    label: '距离到期',
    width: '120',
    prop: 'diffDays',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.expiry',
    widthEn: '160',
  },
  {
    label: '预警时间',
    width: '120',
    prop: 'warningTime',
    isShow: true,
    checkable: true,
    langKey: 'Documentary.WT',
    widthEn: '120',
  },
  {
    label: '状态',
    width: '100',
    prop: 'status',
    isShow: true,
    checkable: false,
    langKey: 'Documentary.state',
  },
]

const STATIC = {
  number_list: NUMBER_LIST, // 单据编号
  status_list: STATUS_LIST, // 状态
  columns: COLUMNS, // 列
  area_list: AERA_LIST, // 地区
  oper_list: OPER_LIST, // 流程
}

module.exports = STATIC
