<template>
  <el-dialog
    title="订单详情"
    :visible.sync="dialogReceiptDetailVisible"
    width="1100px"
    @close="close"
    :close-on-click-modal="false"
  >
    <!-- 销售订单 -->
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="8">
          <p>订单编号</p>
          <!-- 销售订单跳转 -->
          <router-link
            v-if="detail.orderCode"
            target="_blank"
            :to="`/order/orderList/orderDetail?orderCode=${
              detail.orderCode
            }&noReturn=${true}`"
          >
            <span class="page-link span-text">{{ detail.orderCode }}</span>
          </router-link>
        </el-col>
        <el-col :span="8">
          <p>客户代表</p>
          <span class="span-text">{{ detail.businessName }}</span>
        </el-col>
        <el-col :span="8">
          <p>订单状态</p>
          <el-tag class="c_f" :type="detail.orderStatus | orderStatusTagFilter">
            {{ orderStatus(detail.orderStatus) }}
          </el-tag>
        </el-col>
      </el-row>

      <el-row class="mt30">
        <p class="f_s_15">付款情况</p>
        <!-- 上table -->
        <table
          class="table-custom mt10"
          align="center"
          cellspacing="0"
          cellpadding="0"
          width="100%"
        >
          <tr height="50px">
            <td width="94px">订单金额</td>
            <td width="94px" class="f_w_6">
              <span v-if="detail.totlePrice">
                {{ utils.moneyFormat(detail.totlePrice) }}
              </span>
              <span v-else>0</span>
            </td>

            <td width="94px">实收金额</td>
            <td width="94px" class="c_danger f_w_6">
              <span v-if="detail.netReceipts">
                {{ utils.moneyFormat(detail.netReceipts) }}
              </span>
              <span v-else>0</span>
            </td>

            <td width="94px">已收金额</td>
            <td width="94px" class="c_success f_w_6">
              <span v-if="detail.receivablePrice">
                {{ utils.moneyFormat(detail.receivablePrice) }}
              </span>
              <span v-else>0</span>
            </td>

            <td width="94px">未收金额</td>
            <td width="94px" class="c_danger f_w_6">
              <!-- 实收-已收=未收 -->
              <span v-if="detail.netReceipts">
                {{
                  utils.moneyFormat(detail.netReceipts - detail.receivablePrice)
                }}
              </span>
              <span v-else>0</span>
            </td>

            <td width="94px">退款金额</td>
            <td width="94px" class="c_danger f_w_6">
              <span v-if="detail.refundAmount">
                {{ utils.moneyFormat(detail.refundAmount) }}
              </span>
              <span v-else>0</span>
            </td>
          </tr>
        </table>

        <!-- 下table -->
        <el-table
          border
          :data="detail.otherOrderVOS"
          :default-sort="{ prop: 'time', order: 'descending' }"
        >
          <el-table-column prop="reTransactionDate" align="center" label="时间">
            <template slot-scope="scope">
              {{ scope.row.reTransactionDate || '--' }}
            </template>
          </el-table-column>

          <el-table-column prop="typeMemo" align="center" label="类型">
            <template slot-scope="scope">
              {{ scope.row.typeMemo || '--' }}
            </template>
          </el-table-column>

          <el-table-column prop="money" align="center" label="金额">
            <template slot-scope="scope">
              <span v-if="scope.row.type === '1'" class="f_w_6 c_success">
                +{{ moneySign }}{{ scope.row.amount }}
              </span>
              <span v-if="scope.row.type === '2'" class="c_danger f_w_6">
                -{{ moneySign }}{{ scope.row.amount }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="danhao" align="center" label="单号">
            <template slot-scope="scope">
              <span
                class="blue-text"
                v-if="scope.row.transactionCode"
                @click="receiptNoClick(scope.row)"
              >
                {{ scope.row.transactionCode }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div style="margin-top: 40px" class="text-center">
            <el-button @click="close" class="pl20 pr20">关 闭</el-button>
          </div>
        </el-col>
      </el-row>

      <!-- 收款单的详情 (销售订单)-->
      <ReceiptDetailModel1 ref="ReceiptDetailModel1" />
      <!-- 收款单的详情 (采购退款)-->
      <ReceiptDetailModel2 ref="ReceiptDetailModel2" />

      <!-- 付款单详情 (采购订单1) -->
      <PaymentDetailModel ref="PaymentDetailModel" />
      <!-- 付款单详情弹窗（销售退款2） -->
      <PaymentDetailModel2 ref="PaymentDetailModel2" />
    </div>
  </el-dialog>
</template>

<script>
import { paymentStatusVO } from '@/api/finance/finance-payment'
import { orderStatus } from '@/utils/en-match-zh'

// 引入收款
import ReceiptDetailModel1 from '@/views/finance-center/credit-manage/components/receipt-detail-model1'
import ReceiptDetailModel2 from '@/views/finance-center/credit-manage/components/receipt-detail-model2'

//引入付款
import PaymentDetailModel from '@/views/finance-center/payment-manage/components/payment-detail-model'
import PaymentDetailModel2 from '@/views/finance-center/payment-manage/components/payment-detail-model2'

export default {
  name: 'OrderModel',
  components: {
    ReceiptDetailModel1,
    ReceiptDetailModel2,
    PaymentDetailModel,
    PaymentDetailModel2,
  },
  data() {
    return {
      detail: {},
      dialogReceiptDetailVisible: false, //订单详情弹窗
    }
  },
  // 状态过滤
  filters: {
    //销售订单状态
    orderStatusTagFilter(status) {
      const statusMap = {
        1: 'info', //录入中
        2: 'danger', //已驳回
        3: 'danger', //已撤销
        4: 'primary', //审核中
        5: 'success', //已通过
        6: 'primary', //待发货
        8: 'primary', //待收货
        9: 'success', //已收货
        10: 'success', //已完成
        11: 'danger', //已作废
      }
      return statusMap[status]
    },
    orderStatusTextFilter(status) {
      const createWay = {
        1: '录入中',
        2: '已驳回',
        3: '已撤销',
        4: '审核中',
        5: '已通过',
        6: '待发货',
        8: '待收货',
        9: '已收货',
        10: '已完成',
        11: '已作废',
      }
      return createWay[status]
    },
  },
  methods: {
    //这里一定是销售订单
    showDetail(code) {
      this.getpaymentStatusVO(code)
      this.dialogReceiptDetailVisible = true
    },

    //销售单详情
    async getpaymentStatusVO(code) {
      let response = await paymentStatusVO({
        orderCode: code,
      })
      if (response.code == '000000') {
        this.detail = response.data
      }
    },

    //单号click(Sk收款单1，FK付款单2)
    receiptNoClick(row) {
      if (row.transactionCode.includes('SK')) {
        //收款单（type2：销售收订单，type：1采购退款）
        let status = row.status === '9' ? 4 : 1
        if (row.type === '2') {
          this.$refs['ReceiptDetailModel1'].showReceiptDetail(
            row.transactionCode,
            status,
            false
          )
        } else {
          //采购退款收款单
          this.$refs['ReceiptDetailModel2'].showReceiptDetail(
            row.transactionCode,
            status
          )
        }
      } else {
        //付款单(销售退款2，采购付款1)
        if (row.type === '2') {
          this.$refs['PaymentDetailModel2'].amendRow = row.orderId
          this.$refs['PaymentDetailModel2'].showReceiptDetail()
        } else {
          this.$refs['PaymentDetailModel1'].amendRow = row.orderId
          this.$refs['PaymentDetailModel1'].showReceiptDetail()
        }
      }
    },

    close() {
      this.detail = {}
      this.dialogReceiptDetailVisible = false
    },
    orderStatus(val) {
      return orderStatus(val)
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px 0px;
  .detail-header {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      .span-text {
        font-size: 16px;
        color: #000;
      }
    }
  }
  .table-custom {
    border-collapse: collapse;
    text-align: left;
    td {
      border: #ebeef5 1px solid;
      text-align: center;
    }
    td:nth-child(odd) {
      background-color: #f5f7fa;
    }
  }
}
.page-link {
  color: #409eff !important;
}
</style>
