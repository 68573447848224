export const countdownMixin = {
  data() {
    return {
      second: 60,
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.second > 1) {
          this.second--
        } else {
          this.second = 60
          this.disVerify = false
          clearInterval(this.timer)
        }
      }, 1000)
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
