<template>
  <div class="search_form">
    <el-form
      ref="form"
      :model="searchData"
      label-width="100px"
      label-position="top"
    >
      <el-form-item label="地区">
        <el-select
          v-model="searchData.area"
          clearable
          placeholder="请选择"
          class="w100"
        >
          <el-option
            v-for="item in areaOption"
            :key="item.id"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色">
        <el-input
          @focus="fromBusinessChange"
          v-model="userName"
          readonly
          placeholder="请选择"
          @keyup.enter.native="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="searchData.status"
          placeholder="请选择"
          @keyup.enter.native="onSubmit"
          class="w100"
        >
          <el-option
            v-for="item in statusOption"
            :label="item.name"
            :value="item.value"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="btn">
        <!-- 确定 -->
        <el-button type="primary" @click="searchClick">确定</el-button>
        <!-- 重置 -->
        <el-button @click="cancelClick">取消</el-button>
      </div>
    </el-form>

    <!-- 选择成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>
<script>
  import STATIC from '../../utils/staticData.js'
  import { deepCopy } from 'kits'
  import UserChoose from '@/components/userChoose'
  export default {
    components: { UserChoose },
    props: {
      isReset: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        areaOption: STATIC.area_list, // 地区列表
        statusOption: _.cloneDeep(STATIC.status_list), // 状态
        searchData: {
          status: '',
        },
        userName: '',
      }
    },
    watch: {
      isReset() {
        if (this.isReset) {
          for (const key in this.searchData) {
            this.searchData[key] = ''
            this.userName = ''
          }
        }
      },
    },
    methods: {
      chooseUser(userIdArr) {
        this.searchData.userId = userIdArr[0].id
        this.userName = userIdArr[0].name
      },
      // 选择角色
      fromBusinessChange() {
        this.$refs['UserChoose'].showAddEdit(
          '1',
          deepCopy(this.formBusinessArray),
          true
        )
      },
      searchClick() {
        let obj = {
          ...this.searchData,
          userName: this.userName,
        }
        this.$emit('highSearch', obj)
      },
      cancelClick() {
        this.$emit('highSearchcCancel')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .search_form {
    padding: 0 20px 80px 20px;
    .el-date-editor--datetimerange.el-input__inner {
      width: 100%;
    }
    .btn {
      padding: 20px 0;
      width: 100%;
      position: fixed;
      bottom: 0;
      background: white;
    }
  }
  ::v-deep {
    .el-cascader {
      width: 100%;
    }
    .el-date-editor {
      width: 100%;
    }
    .el-divider {
      margin: 70px 0 28px 0 !important;
    }
  }
</style>
