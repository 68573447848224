import { render, staticRenderFns } from "./TransferModel.vue?vue&type=template&id=3301255c&scoped=true&"
import script from "./TransferModel.vue?vue&type=script&lang=js&"
export * from "./TransferModel.vue?vue&type=script&lang=js&"
import style0 from "./TransferModel.vue?vue&type=style&index=0&id=3301255c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3301255c",
  null
  
)

export default component.exports