<template>
  <el-dialog
    v-if="isEdit"
    title="编辑"
    :visible.sync="isEdit"
    :close-on-click-modal="false"
    :before-close="Cancel"
    width="800px"
  >
    <div class="offer-dialog">
      <div class="container">
        <el-form :model="form" ref="ruleForm" label-width="80px">
          <el-form-item label="地区">
            <el-select v-model="form.area">
              <el-option
                v-for="item in areaArray"
                :label="item.areaName"
                :value="item.areaName"
                :key="item.areaName"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="角色">
            <el-select v-model="form.role">
              <el-option
                v-for="item in roleArray"
                :label="item.message"
                :value="item.code"
                :key="item.code"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="主管">
            <el-input
              v-model="form.manager"
              @focus="businessChange"
              @change="clearBusinessName"
              placeholder="请选择"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="text-center mt20">
        <el-button @click="Cancel">返回</el-button>
        <el-button type="primary" @click="Save">保存</el-button>
      </div>
    </div>

    <!-- 人员组织树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </el-dialog>
</template>

<script>
  import UserChoose from '@/components/userChoose'
  import { FinanceThemeSetting } from '@/core/interactors/finance/config-manage'
  export default {
    props: ['isEdit', 'areaArray', 'roleArray', 'editRow'],
    components: { UserChoose },
    data() {
      return {
        form: {},
      }
    },
    watch: {
      isEdit(val) {
        if (val) {
          this.form = Object.assign({}, { ...this.editRow })
        }
      },
    },
    created() {},
    methods: {
      // 保存
      Save() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            let res = await FinanceThemeSetting.ThemeUpdatePO(this.form)
            if (res.code === '000000') {
              this.$message.success('保存成功')
              this.$emit('Edit')
            }
          }
        })
      },

      // 选择主管
      businessChange(i) {
        this.rowIndex = i
        this.$refs['UserChoose'].showAddEdit('', '', true, 1)
      },

      // 获取主管
      chooseUser(arr) {
        this.form.manager = arr[0].name + '(' + arr[0].englishName + ')'
        this.form.managerId = arr[0].id
        this.form.managerArea = arr[0].area
      },

      // 删除主管
      clearBusinessName() {
        this.form.manager = ''
        this.form.managerId = ''
        this.form.managerArea = ''
      },

      // 返回
      Cancel() {
        this.$emit('Cancel')
      },
    },
  }
</script>

<style scoped lang="scss">
  .container {
    width: 100%;
    overflow-x: auto;
    .el-form {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
</style>
