<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="角色名称" prop="name">
              <el-input v-model.trim="form.name" placeholder="请输入" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="所在分组" prop="parentId">
              <el-select v-model="form.parentId" placeholder="请选择">
                <el-option
                  v-for="item in treeData"
                  :key="item.roleId"
                  :label="item.name"
                  :value="item.roleId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, listPaged } from '@/api/power-group'
  export default {
    name: 'PowerGroupAddModel',
    data() {
      return {
        loading: false,
        title: '新增角色',
        form: {
          divideGroup: 0,
          name: '',
          parentId: '',
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: `请输入角色名称` },
          ],
          parentId: [
            { required: true, trigger: 'change', message: `请选择所在分组` },
          ],
        },
        dialogFormVisible: false,
        value: null,
        isUpdata: false,
        treeData: [],
      }
    },
    created() {},
    methods: {
      //新增角色
      showAddEdit() {
        this.getListPaged()
        this.dialogFormVisible = true
      },
      //权限列表
      async getListPaged() {
        let response = await listPaged(this.queryForm)
        if (response.code === '000000') {
          let treeData = response.data
          let i = treeData.length
          while (i--) {
            if (treeData[i].name === '默认' && treeData[i].roleType === 1) {
              treeData.splice(i, 1)
            }
          }

          this.treeData = treeData
        }
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async save() {
        let form = JSON.parse(JSON.stringify(this.form))
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            let response = await insertPO(form)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                '操作成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
