<template>
  <el-dialog title="验货" width="1200px" class="custom-dialog" :visible.sync="cargoManageVisible"
    :close-on-click-modal="false" :before-close="close">
    <el-form :model="form" ref="ruleForm">
      <div class="custom-detail">
        <el-row>
          <el-col>
            <em>验货单号：</em>
            <span>{{ detail.number || '--' }}</span>
          </el-col>
          <el-col class="w_90">
            <em>供应商名称：</em>
            <span>{{ detail.supplierName || '--' }}</span>
          </el-col>
          <el-col>
            <em style="display: flex">期望验货日期：</em>
            <p style="width: 100px; word-break: keep-all">
              {{ detail.inspectionDate || '--' }}
            </p>
          </el-col>
          <el-col>
            <em>工厂交期：</em>
            <span>{{ detail.expectDeliveryDate || '--' }}</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <em>客户代表：</em>
            <span>{{ detail.businessName || '--' }}</span>
          </el-col>
          <el-col class="w_90">
            <em>采购开发：</em>
            <span>{{ detail.buyerName || '--' }}</span>
          </el-col>
          <el-col>
            <em>验货员：</em>
            <span>{{ detail.inspector || '--' }}</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col class="w_130" style="width: 50%">
            <em>物流公司及运单号：</em>
            <span>{{ detail.logisticsCompanyNo || '--' }}</span>
          </el-col>
          <el-col style="width: 50%">
            <em>验货地址：</em>
            <span>{{ detail.inspectionAddress || '--' }}</span>
          </el-col>
        </el-row>
      </div>

      <div class="wrap" v-if="isShow">
        <div class="container mb15" v-for="(item, i) in form.inspectionProductEditDTOList" :key="i">
          <div class="top pointer" @click="isShowElement(item.isShow, i)">
            <div class="left">
              <div class="left-label"> 产品：</div>
              <div class="left-content"> <span v-show="item.sku">{{ item.sku }}/</span>
                <span v-show="item.sku">{{ item.nameCn }}/</span>
                <span v-show="item.specsValue1">{{ item.specsValue1 }}</span>
                <span v-show="item.specsValue2">;{{ item.specsValue2 }}/</span>
              </div>
            </div>
            <div class="center">
              <span class="ml20">验货数量：</span>
              <span class="green f_s_16">{{ item.numInspection || '--' }}</span>
              <span class="ml20">
                {{ item.provideSamples == 1 ? '已提供样品' : '未提供样品' }}
              </span>
              <span class="ml20">
                检验标准：{{ item.actualInspectionStandard || '--' }}
              </span>
            </div>
            <div class="right">
              <span class="red">待验货</span>
            </div>

          </div>
          <div v-show="item.isShow">
            <div class="intro">
              <div>
                <p>效果图：</p>
                <img :src="item.printingImg" />
              </div>
              <div>
                <p>验货要求：</p>
                <p>{{ item.inspectionRequirements || '--' }}</p>
                <p>装箱信息：</p>
                <p>{{ item.packingInformation || '--' }}</p>
              </div>
            </div>

            <div class="form">
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="验货结果" :prop="
                      'inspectionProductEditDTOList.' + i + '.actualResults'
                    " :rules="[
                      {
                        required: true,
                        message: '请选择',
                        trigger: 'change',
                      },
                    ]">
                    <el-select class="w100" v-model="
                        form.inspectionProductEditDTOList[i].actualResults
                      " clearable>
                      <el-option label="合格" value="合格"></el-option>
                      <el-option label="不合格" value="不合格"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="抽检数量" :prop="
                      'inspectionProductEditDTOList.' + i + '.samplingQuantity'
                    " :rules="[
                      {
                        required: true,
                        message: '抽检数量必填',
                        trigger: 'blur',
                      },
                      {
                        pattern: /^[1-9]\d*$/,
                        message: '请输入>0的整数',
                        trigger: 'blur',
                      },
                    ]">
                    <el-input maxlength="10" v-model="
                        form.inspectionProductEditDTOList[i].samplingQuantity
                      " @input="badnessChange(i, 'samplingQuantity')" placeholder="请输入" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="严重问题数量" :prop="
                      'inspectionProductEditDTOList.' + i + '.seriousQuantity'
                    " :rules="[
                      {
                        required: true,
                        message: '严重问题数量必填',
                        trigger: 'blur',
                      },
                      {
                        pattern: /^[0-9]+?$/,
                        message: '请输入>=0的整数',
                        trigger: 'blur',
                      },
                    ]">
                    <el-input maxlength="10" v-model="
                        form.inspectionProductEditDTOList[i].seriousQuantity
                      " @input="badnessChange(i, 'seriousQuantity')" placeholder="请输入" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="一般问题数量" :prop="
                      'inspectionProductEditDTOList.' + i + '.generalQuantity'
                    " :rules="[
                      {
                        required: true,
                        message: '一般数量必填',
                        trigger: 'change',
                      },
                      {
                        pattern: /^[0-9]+?$/,
                        message: '请输入>=0的整数',
                        trigger: 'blur',
                      },
                    ]">
                    <el-input maxlength="10" v-model="
                        form.inspectionProductEditDTOList[i].generalQuantity
                      " @input="badnessChange(i, 'generalQuantity')" placeholder="请输入" clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <div class="result">
                <span>
                  严重不良率：
                  <em class="green">
                    {{ item.seriousQuantityRate }}
                  </em>
                </span>
                <span class="ml20">
                  一般不良率：
                  <em class="green">
                    {{ item.generalQuantityRate }}
                  </em>
                </span>
                <span class="ml20">
                  整体不良率：
                  <em class="green">
                    {{ item.overallQuantityRate }}
                  </em>
                </span>
              </div>

              <p>箱规图（唛头，开箱图）</p>
              <erp-upload-images :limit="9" :tips-visible="false"
                @change="changeUploadList($event, i, 'boxGaugeUrl')"></erp-upload-images>

              <p>产品包装图</p>
              <erp-upload-images :limit="9" :tips-visible="false"
                @change="changeUploadList($event, i, 'productPackagingUrl')"></erp-upload-images>

              <p>产品图（Logo，正面，侧面，背面）</p>
              <erp-upload-images :limit="9" :tips-visible="false"
                @change="changeUploadList($event, i, 'productUrl')"></erp-upload-images>

              <el-row :gutter="20" class="mb20">
                <el-col :span="12">
                  <h4>验货结论说明</h4>
                  <el-input type="textarea" maxlength="500" show-word-limit :rows="3"
                    v-model="item.actualResultDescription" placeholder="请输入" clearable></el-input>
                </el-col>
                <el-col :span="12">
                  <h4>附件</h4>
                  <file-upload :ref="'ErpUploadLogoInfo' + i" :index="i" @uploadList="multiUploadList"></file-upload>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

      <p>备注</p>
      <el-input type="textarea" v-model="form.remark" maxlength="500" show-word-limit :rows="3"
        placeholder="请输入500个字符以内"></el-input>
      <p>凭证/附件</p>
      <div v-show="fileList.length">
        <div class="flie-list" v-for="(item, i) in fileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
              <span class="ell">
                <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
    </el-form>

    <div class="text-center mt20">
      <el-button @click="save()" type="primary" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import FileUpload from '@/components/FileUpload'
  import { inspectionGetDetailForEdit, inspectionUpdatePO } from '@/api/order'
  export default {
    components: {
      FileUpload,
    },
    data() {
      return {
        form: {
          buyerId: '',
          inspectionOrderId: '', // 业务Id
          purchaseOrderId: '', // 采购单Id
          purchaseOrderNumber: '', // 采购单单号
          number: '', // 验货单号
          supplierName: '', // 供应商名称
          inspectionDate: '', // 验货日期
          expectDeliveryDate: '', // 期望交期
          inspectionAddress: '', // 验货地址
          inspector: '', // 验货员
          inspectorId: '', // 验货员ID
          inspectionProductEditDTOList: [],
          fileAnnex: '', // 附件
          remark: '', // 备注
        },
        detail: {},
        fileList: [],
        cargoManageVisible: false,
        isShow: true,
        isLoading: false,
      }
    },
    methods: {
      // 初始化数据
      initLoading(id) {
        let self = this
        inspectionGetDetailForEdit({ businessId: id }).then((res) => {
          self.detail = res.data ? res.data : {}
          self.form.buyerId = res.data.buyerId
          self.form.purchaseOrderNumber = res.data.purchaseOrderNumber
          self.form.inspectionOrderId = res.data.inspectionOrderId
          self.form.purchaseOrderId = res.data.purchaseOrderId
          self.form.number = res.data.number
          self.form.supplierName = res.data.supplierName
          self.form.inspectionDate = res.data.inspectionDate
          self.form.expectDeliveryDate = res.data.expectDeliveryDate
          self.form.inspectionAddress = res.data.inspectionAddress
          self.form.inspector = res.data.inspector
          self.form.inspectorId = res.data.inspectorId
          self.form.remark = res.data.remark
          self.form.inspectionProductEditDTOList = []
          self.fileList = res.data.fileAnnex ? JSON.parse(res.data.fileAnnex) : ''
          if (
            res.data.inspectionProductEditVOList &&
            res.data.inspectionProductEditVOList.length
          ) {
            res.data.inspectionProductEditVOList.forEach((item) => {
              let obj = {}
              obj = Object.assign({}, { ...item }, { isShow: true })
              self.form.inspectionProductEditDTOList.push(obj)
            })
          }
          // 编辑不需要带出抽检数量等结果
          self.form.inspectionProductEditDTOList =
            self.form.inspectionProductEditDTOList.map((item) => {
              return {
                ...item,
                samplingQuantity: '',
                seriousQuantity: '',
                generalQuantity: '',
                seriousQuantityRate: '',
                generalQuantityRate: '',
                overallQuantityRate: '',
              }
            })
          self.cargoManageVisible = true
        })
      },

      // 保存
      save() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let params = { ...self.form }
            self.isLoading = true
            inspectionUpdatePO(params).then((res) => {
              if (res && res.code == '000000') {
                self.close()
                self.isLoading = false
                self.$parent.initLoading()
                self.$message.success('保存成功')
              }
            })
          }
        })
      },

      // 计算不良率
      badnessChange(i, type) {
        let list = this.form.inspectionProductEditDTOList[i]
        // 严重不良率
        list.seriousQuantityRate = (
          list.seriousQuantity / list.samplingQuantity
        ).toFixed(2)
        // 一般不良率
        list.generalQuantityRate = (
          list.generalQuantity / list.samplingQuantity
        ).toFixed(2)
        // 整体不良率
        list.overallQuantityRate = (
          Number(list.seriousQuantityRate) + Number(list.generalQuantityRate)
        ).toFixed(2)
      },

      // 图片文件上传
      changeUploadList(list, i, type) {
        let images = []
        list.forEach((item) => images.push(item.url))
        this.form.inspectionProductEditDTOList[i][type] = images.join(',')
      },

      // 附件上传
      multiUploadList(fileList, index) {
        this.form.inspectionProductEditDTOList[index].fileAnnex =
          JSON.stringify(fileList)
      },

      isShowElement(boo, i) {
        this.isShow = false
        this.$set(this.form.inspectionProductEditDTOList[i], 'isShow', !boo)
        this.isShow = true
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.cargoManageVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    .wrap {
      overflow-y: auto;
      max-height: 360px;

      .container {
        border: 1px solid #ebeef5;
        border-radius: 4px;

        .top {
          padding: 0 20px;
          /* line-height: 39px; */
          border-bottom: 1px solid #ebeef5;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: baseline;

          .left {
            flex-shrink: 1;
            /* 当空间不足时，.left可以缩小 */
            word-wrap: break-word;
            /* 文本超长时自动换行 */
            line-height: 20px;
            padding: 5px 0;
            display: flex;

            .left-content {
              flex-shrink: 1;
              /* 当空间不足时，.left可以缩小 */
              word-wrap: break-word;
            }

            .left-label {
              flex-shrink: 0;
            }
          }

          .center,
          .right {
            flex-grow: 1;
            /* 当空间有多余时，.right可以放大 */
            flex-shrink: 0;
            /* 当空间不足时，.right不缩小 */
            /* 可以设置min-width等样式来确保最小宽度 */

          }

          .right {
            margin-left: 10px;
            text-align: right;
          }
        }

        .result {
          text-align: right;

          em {
            font-style: normal;
          }
        }

        .intro {
          padding: 0 20px;
          display: flex;
          justify-content: space-between;

          // max-height: 120px;
          // overflow-y: auto;
          >div {
            width: 50%;

            img {
              width: 100%;
              padding: 0 120px 20px 0;
            }
          }
        }

        .custom-table {
          display: table;
        }

        .form {
          padding: 0 15px;
        }
      }
    }

    p {
      margin: 10px 0;
    }
  }

  ::v-deep {
    .upload-images {
      .el-upload {
        margin-bottom: 10px;
        width: 90px;
        height: 90px;

        i {
          display: block;
          line-height: 90px;
        }
      }

      .el-upload-list__item {
        width: 90px;
        height: 90px;
        object-fit: cover;
      }
    }
  }
</style>