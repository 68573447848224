<template>
  <el-drawer
    :visible.sync="setRemark"
    direction="rtl"
    :show-close="false"
    :size="400"
    :before-close="handleClose"
  >
    <template slot="title">
      <div class="drawer-title">
        <!-- 备注轨迹 -->
        <span>{{ $t('Documentary.NT') }}</span>
        <div>
          <!-- 保存 -->
          <el-button type="text" @click="save">
            {{ $t('Documentary.save') }}
          </el-button>
          <!-- 取消 -->
          <el-button type="text" @click="cancel">
            {{ $t('Documentary.Cancel') }}
          </el-button>
        </div>
      </div>
    </template>
    <div class="drawer-content">
      <div>
        <div class="nameAndTime">
          <span class="name">
            {{ lang === 'en' ? userInfo.englishName : userInfo.userName }}
          </span>
        </div>
        <div class="content">
          <el-input
            style="width: 300px"
            type="textarea"
            v-model="inputMark"
            :placeholder="$t('Documentary.PE')"
            show-word-limit
            maxlength="200"
          ></el-input>
        </div>
      </div>
      <div
        v-for="(item, index) in markList"
        :key="index"
        style="margin-top: 20px"
      >
        <div class="nameAndTime">
          <span class="name">
            {{ lang === 'en' ? item.creatorEn : item.creator }}
          </span>
          <span class="time">{{ item.createTime }}</span>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top-start"
          :disabled="item.remark.length < 60"
        >
          <template slot="content">
            <div class="popperStyle">{{ item.remark }}</div>
          </template>

          <div class="content">{{ item.remark }}</div>
        </el-tooltip>
      </div>
    </div>
  </el-drawer>
</template>
<script>
  import CustomerManageInteractor from '@/core/interactors/new-customer-manage/index'

  import { mapGetters } from 'vuex'
  export default {
    name: 'remark-drawer',
    data() {
      return {
        inputMark: '', //  备注
        setRemark: false, // 控制
        markList: [], // 备注列表
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
    },
    props: {
      addRemark: {
        // 是否新增备注
        default: false,
        type: Boolean,
      },
      companyId: {
        // 公司id
        default: '',
        type: String,
      },
      infoId: {
        // 客户id
        default: '',
        type: String,
      },
      remarkType: {
        //  备注类型
        default: 1,
        type: Number,
      },
    },
    watch: {
      addRemark: {
        handler() {
          if (this.addRemark) {
            this.getMarkList()
          }
          this.setRemark = this.addRemark
        },
      },
    },
    methods: {
      // 获取备注列表
      getMarkList() {
        CustomerManageInteractor.remarkListData({
          companyId: this.companyId,
          infoId: this.infoId,
          remarkType: this.remarkType,
        }).then((res) => {
          this.markList = res.data
        })
      },
      // 关闭
      handleClose() {
        if (this.inputMark != '') {
          this.$confirm(
            this.$t('Documentary.RANSD'), // 备注内容没有保存，是否关闭
            this.$t('Documentary.hint'), // 提示
            {
              type: 'warning',
            }
          ).then(() => {
            this.inputMark = ''
            this.$emit('closeDrawer', false)
          })
        } else {
          this.inputMark = ''
          this.$emit('closeDrawer', false)
        }
      },
      // 保存
      save() {
        if (this.inputMark === '') {
          this.$message.warning(this.$t('Documentary.RCBE')) // 备注不能为空
          return
        }
        let params = {
          companyId: this.companyId,
          infoId: this.infoId,
          remark: this.inputMark,
          remarkType: this.remarkType,
        }
        CustomerManageInteractor.insertPORemark(params).then((res) => {
          if (res.code === '000000') {
            this.$Bus.$emit('refresh-remarks')
            this.getMarkList()
            this.inputMark = ''
          }
        })
      },
      // 取消
      cancel() {
        this.handleClose()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .drawer-content {
    padding: 20px 0px 20px 40px;
    .nameAndTime {
      .time {
        margin-left: 10px;
      }
    }
    .content {
      margin-top: 5px;
      width: 300px;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; // 控制多行的行数
      -webkit-box-orient: vertical;
    }
  }
  .popperStyle {
    display: inline-block;
    max-width: 350px !important;
    line-height: 18px;
  }
</style>
