<template>
  <div class="pro_material">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        prop="supName"
        label="供应商信息"
        width="300"
        align="center"
      >
        <template slot-scope="scope">{{ scope.row.supName || '--' }}</template>
      </el-table-column>
      <el-table-column
        prop="supGrade"
        label="供应商等级"
        width="200"
        align="center"
      >
        <template slot-scope="scope">{{ scope.row.supGrade || '--' }}</template>
      </el-table-column>
      <el-table-column prop="fukuan" label="付款方式" align="center">
        <template slot-scope="scope">{{ scope.row.fukuan || '--' }}</template>
      </el-table-column>
      <el-table-column prop="main" label="是否主要" width="300" align="center">
        <template #default="{ row }">
          <el-switch
            :value="ids.indexOf(String(row.id)) != -1 ? true : false"
            disabled
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="lookClick(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'Supplier',
  props: {
    infoParames: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      ids: [],
    }
  },
  components: {},
  created() {
    if (this.infoParames.id) {
      if (!Array.isArray(this.infoParames.mainSupplierIds)) {
        this.ids = this.infoParames.mainSupplierIds.split(',')
      }

      this.tableData = this.infoParames.productSupplierVOList
    }
  },
  mounted() {},
  methods: {
    // 查看
    lookClick() {
      return this.$baseMessage(
        '待开发...',
        'error',
        false,
        'erp-hey-message-error'
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
