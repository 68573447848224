import { render, staticRenderFns } from "./BookEdit.vue?vue&type=template&id=55a6bcf3&scoped=true&"
import script from "./BookEdit.vue?vue&type=script&lang=js&"
export * from "./BookEdit.vue?vue&type=script&lang=js&"
import style0 from "./BookEdit.vue?vue&type=style&index=0&id=55a6bcf3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a6bcf3",
  null
  
)

export default component.exports