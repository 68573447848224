<template>
  <div class="custom-container">
    <el-row>
      <div class="flex justify_start">
        <!-- 新增/编辑报价 -->
        <el-page-header
          :title="$t('other.back')"
          @back="goBack()"
          :content="getTitle()"
        ></el-page-header>
      </div>
    </el-row>

    <div class="container">
      <div class="title flex">
        <div>
          <em class="dark">1</em>
          <!-- 填写信息 -->
          <span>{{ $t('inquiryDetails.FillInInformation') }}</span>
          <!-- 恢复默认 -->
          <span class="event" @click="recover">
            {{ $t('inquiryDetails.RestoreDefault') }}
          </span>
          <!-- 管理产品 -->
          <span class="event" @click="manageProduct">
            {{ $t('inquiryDetails.ManagementProducts') }}
          </span>
        </div>
        <div>
          <em class="dark">2</em>
          <!-- 完善报价 -->
          <span>{{ $t('inquiryDetails.PerfectQuote') }}</span>
        </div>
      </div>
      <div class="main-wrap">
        <div class="anchor">
          <ul>
            <li v-for="(item, i) in form.list" :key="i" @click="navSelect(i)">
              {{ $t('inquiryDetails.Product') }}{{ i + 1 }}:
              {{ item.productCode }}
            </li>
          </ul>
        </div>
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          :label-width="lang === 'en' ? '160px' : '100px'"
        >
          <div class="vaild">
            <!-- 报价有效期 -->
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('inquiryDetails.QuoteValid')"
                  prop="validityDate"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.validityDate"
                    :placeholder="$t('placeholder.plsInput')"
                    maxlength="4"
                    clearable
                    oninput="value=value.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')"
                  >
                    <template slot="append">
                      &nbsp;&nbsp;{{ $t('other.Day') }}&nbsp;&nbsp;
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="main" v-for="(item, i) in form.list" :key="i">
            <div class="line"></div>
            <div class="l-main">
              <!-- 标准品 -->
              <div v-if="item.standardCustomized === '标准品'">
                <div class="img-wrap flex">
                  <div class="img">
                    <img
                      :src="item.productUrl || emptyImg"
                      alt=""
                      @click="handlePreview(item.productUrl)"
                    />
                    <span>
                      {{ productTypeTransl(item.standardCustomized) }}
                    </span>
                  </div>
                  <div class="text">
                    <p>
                      <el-row>
                        <!-- 产品编码 -->
                        <el-col :span="10">
                          {{ $t('productCenter.ProductCode') }}:
                          {{ item.productCode }}
                        </el-col>
                        <!-- 英文名称 -->
                        <el-col :span="11">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.productEn"
                            placement="top-start"
                          >
                            <div class="ell">
                              {{ $t('productCenter.EnglishName') }}:
                              {{ item.productEn }}
                            </div>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                    </p>
                    <!-- 需求描述 -->
                    <p>
                      {{ $t('addInquiry.RequirementDescription') }}:
                      {{ item.demand }}
                    </p>
                    <!-- 更多信息 -->
                    <p class="more" @click="more(item)">
                      {{ $t('inquiryDetails.More') }}
                    </p>
                  </div>
                </div>

                <!-- 基本信息 -->
                <p class="dark">{{ $t('addInquiry.BaseInfo') }}</p>
                <div v-if="item.specsType">
                  <div
                    v-for="(specs, n) in specsValueInit(i)"
                    :key="n"
                    class="flex base-info"
                  >
                    <div
                      class="name"
                      :style="{ width: lang === 'en' ? '160px' : '100px' }"
                    >
                      <i class="red">*</i>
                      <!-- 规格 -->
                      {{ $t('inquiryDetails.Specification') }}
                    </div>
                    <div class="res">
                      <el-tag
                        :key="tag"
                        v-for="tag in specs.value"
                        :type="
                          tag == (n === 0 ? item.specsValue1 : item.specsValue2)
                            ? ''
                            : 'info'
                        "
                        @click="selectSpecsValue(tag, i, n)"
                      >
                        {{ tag }}
                      </el-tag>
                    </div>
                  </div>
                </div>
                <!-- 颜色 -->
                <el-row>
                  <el-col :span="11">
                    <el-form-item
                      :label="$t('addInquiry.Color')"
                      :prop="'list.' + i + '.color'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.list[i].color"
                        maxlength="50"
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- 印刷信息 -->
                <p class="dark">{{ $t('addInquiry.PrintingInformation') }}</p>
                <el-row>
                  <el-col :span="11">
                    <!-- 印刷方式 -->
                    <el-form-item
                      :label="$t('addInquiry.PrintingMethod')"
                      :prop="'list.' + i + '.printingWayId'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="form.list[i].printingWayId"
                        :placeholder="$t('placeholder.plsSel')"
                        @change="printingWayChange(i)"
                      >
                        <el-option
                          v-for="opt in item.printingWayOptions"
                          :key="opt.printingWayId"
                          :label="lang === 'en' ? opt.nameEn : opt.nameCn"
                          :value="opt.printingWayId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :span="11"
                    :offset="1"
                    v-if="
                      !['Blank', 'Sample'].includes(form.list[i].printingWayId)
                    "
                  >
                    <!-- 印刷细节 -->
                    <el-form-item
                      :label="$t('addInquiry.PrintingDetails')"
                      :prop="'list.' + i + '.waySelect'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="form.list[i].waySelect"
                        :placeholder="$t('placeholder.plsSel')"
                        @change="calcPrice(i)"
                      >
                        <el-option
                          v-for="opt in item.waySelectOptions"
                          :key="opt.value"
                          :label="opt.label"
                          :value="opt.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col
                    :span="11"
                    v-if="
                      !['Blank', 'Sample'].includes(form.list[i].printingWayId)
                    "
                  >
                    <!-- 印刷位置 -->
                    <el-form-item
                      :label="$t('addInquiry.PrintingPosition')"
                      :prop="'list.' + i + '.digitSelect'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="form.list[i].digitSelect"
                        :placeholder="$t('placeholder.plsSel')"
                        @change="calcPrice(i)"
                      >
                        <el-option
                          v-for="opt in item.digitSelectOptions"
                          :key="opt.value"
                          :label="opt.label"
                          :value="opt.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- 运输信息 -->
                <p class="dark">{{ $t('addInquiry.TransportInformation') }}</p>
                <el-row>
                  <el-col :span="12">
                    <!-- 运输类型 -->
                    <el-form-item
                      :label="$t('addInquiry.ShippingMethod')"
                      :label-width="lang === 'en' ? '135px' : '100px'"
                      :prop="'list.' + i + '.transportType'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <!-- 空运 -->
                      <el-radio
                        v-model="form.list[i].transportType"
                        label="1"
                        @change="calcPrice(i, null, null, 'ship')"
                      >
                        {{ $t('addInquiry.AirTransportation') }}
                      </el-radio>
                      <!-- 海运 -->
                      <el-radio
                        v-model="form.list[i].transportType"
                        label="2"
                        @change="calcPrice(i, null, null, 'ship')"
                      >
                        {{ $t('addInquiry.SeaTransportation') }}
                      </el-radio>
                      <!-- 快递 -->
                      <el-radio
                        v-model="form.list[i].transportType"
                        label="3"
                        @change="calcPrice(i, null, null, 'ship')"
                      >
                        {{ $t('addInquiry.Expressage') }}
                      </el-radio>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <!-- 收货国家 -->
                    <el-form-item
                      :label="$t('addInquiry.ReceivingCountries')"
                      :prop="'list.' + i + '.shippingCountry'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="form.list[i].shippingCountry"
                        :placeholder="$t('placeholder.plsSel')"
                        @change="calcPrice(i, null, null, 'ship')"
                        clearable
                      >
                        <el-option
                          v-for="item in countryOptions"
                          :key="item.code"
                          :label="lang === 'en' ? item.nameEn : item.areaName"
                          :value="item.code"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 派送到门 -->
                <el-form-item
                  :label="$t('addInquiry.DeliveryDoor')"
                  prop="deliveryToTheDoor"
                >
                  <el-switch
                    v-model="form.list[i].deliveryToTheDoor"
                    :active-value="1"
                    :inactive-value="0"
                  ></el-switch>
                </el-form-item>
                <!-- 邮编 -->
                <el-row>
                  <el-col :span="23">
                    <el-form-item
                      :label="$t('addInquiry.Postcode')"
                      :prop="'list.' + i + '.zipCode'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                      v-show="form.list[i].deliveryToTheDoor === 1"
                    >
                      <el-input
                        v-model="form.list[i].zipCode"
                        maxlength="50"
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 收货地址 -->
                <el-row>
                  <el-col :span="23">
                    <el-form-item
                      :label="$t('addInquiry.ShippingAddress')"
                      :prop="'list.' + i + '.shippingAddress'"
                      v-show="form.list[i].deliveryToTheDoor === 1"
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="200"
                        show-word-limit
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                        v-model="form.list[i].shippingAddress"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 报价数量 -->
                <el-row class="product-row">
                  <el-col :span="16">
                    <el-form-item
                      v-if="form.list[i].printingWayId === 'Sample'"
                      :label="$t('inquiryDetails.QuoteQuantity')"
                      :prop="'list.' + i + '.selectQuantity'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.list[i].selectQuantity"
                        type="number"
                        :min="1"
                        maxlength="10"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        :placeholder="$t('placeholder.plsInput')"
                        @blur="calcPrice(i)"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-else
                      :label="$t('inquiryDetails.QuoteQuantity')"
                      :prop="'list.' + i + '.selectQuantity'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <div class="flex">
                        <el-input
                          v-model="form.list[i].num"
                          type="number"
                          :min="1"
                          maxlength="10"
                          @keyup.enter.native="addNumbers(form.list[i].num, i)"
                          :placeholder="$t('placeholder.plsInput')"
                        ></el-input>
                        <!-- 添加 -->
                        <el-button
                          type="primary"
                          class="ml10"
                          @click="addNumbers(form.list[i].num, i)"
                          :disabled="!form.list[i].num"
                        >
                          {{ $t('addInquiry.Add') }}
                        </el-button>
                        <!-- 默认阶梯 -->
                        <el-button class="ml10" @click="defaultLadder(i)">
                          {{ $t('addInquiry.DefaultLadder') }}
                        </el-button>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 已选数量： -->
                <el-form-item
                  v-if="
                    item.selectQuantity &&
                    form.list[i].printingWayId != 'Sample'
                  "
                >
                  {{ $t('addInquiry.SelectedNumber') }}
                  <span>
                    <el-tag
                      :key="tag"
                      v-for="tag in item.selectQuantity.split(',')"
                      closable
                      @close="numbersClose(tag, i)"
                    >
                      {{ tag }}
                    </el-tag>
                  </span>
                </el-form-item>
                <div v-else class="mb15"></div>
                <!-- 报价备注 -->
                <el-row>
                  <el-col :span="23">
                    <el-form-item
                      :label="$t('inquiryDetails.QuoteRemark')"
                      :prop="'list.' + i + '.remark'"
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="500"
                        show-word-limit
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                        v-model="form.list[i].remark"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <!-- 订制品 -->
              <div v-else>
                <div class="img-wrap flex">
                  <div class="img">
                    <img
                      :src="item.productUrl || emptyImg"
                      alt="暂无数据"
                      @click="handlePreview(item.productUrl)"
                    />
                    <span>
                      {{ productTypeTransl(item.standardCustomized) }}
                    </span>
                    <div class="img-icon">
                      <i
                        class="el-icon-edit"
                        @click="choseRendingImages(i)"
                      ></i>
                    </div>
                  </div>
                  <div class="text">
                    <p>
                      <el-row>
                        <!-- 产品编码 -->
                        <el-col :span="10">
                          {{ $t('productCenter.ProductCode') }}:
                          {{ item.productCode }}
                        </el-col>
                        <!-- 英文名称 -->
                        <el-col :span="11">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.productEn"
                            placement="top-start"
                          >
                            <div class="ell">
                              {{ $t('productCenter.EnglishName') }}:
                              {{ item.productEn }}
                            </div>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                    </p>
                    <!-- 需求描述 -->
                    <p>
                      {{ $t('addInquiry.RequirementDescription') }}:
                      {{ item.demand }}
                    </p>
                    <!-- 更多信息 -->
                    <p class="more" @click="more(item)">
                      {{ $t('inquiryDetails.More') }}
                    </p>
                  </div>
                </div>

                <!-- 基本信息 -->
                <p class="dark mt10">{{ $t('addInquiry.BaseInfo') }}</p>
                <div v-if="item.specsType">
                  <div
                    v-for="(specs, n) in specsValueInit(i)"
                    :key="n"
                    class="flex base-info"
                  >
                    <div
                      class="name"
                      :style="{ width: lang === 'en' ? '160px' : '100px' }"
                    >
                      <i class="red">*</i>
                      <!-- 规格 -->
                      {{ $t('inquiryDetails.Specification') }}
                    </div>
                    <div class="res">
                      <el-tag
                        :key="tag"
                        v-for="tag in specs.value"
                        :type="
                          tag == (n === 0 ? item.specsValue1 : item.specsValue2)
                            ? ''
                            : 'info'
                        "
                        @click="selectSpecsValue(tag, i, n)"
                      >
                        {{ tag }}
                      </el-tag>
                    </div>
                  </div>
                </div>
                <!-- 颜色 -->
                <el-row>
                  <el-col :span="11">
                    <el-form-item
                      :label="$t('addInquiry.Color')"
                      :prop="'list.' + i + '.color'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.list[i].color"
                        maxlength="50"
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 英文名称 材质 -->
                <el-row>
                  <el-col :span="11">
                    <!-- 英文名称 -->
                    <el-form-item
                      :label="$t('productCenter.EnglishName')"
                      :prop="'list.' + i + '.productEn'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.list[i].productEn"
                        maxlength="100"
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11" :offset="1">
                    <!-- 材质 -->
                    <el-form-item
                      :label="$t('productDetail.Materials')"
                      :prop="'list.' + i + '.material'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.list[i].material"
                        maxlength="50"
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <section v-if="item.specsDTOS.length">
                  <!-- 产品重量 -->
                  <el-row class="product-row">
                    <el-col :span="11">
                      <el-form-item
                        :label="$t('inquiryDetails.ProductWeight')"
                        :prop="
                          'list.' + i + '.specsDTOS.' + 0 + '.productWeightKgs'
                        "
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          @blur="calcPrice(i, null, null, 'pro')"
                          v-model="form.list[i].specsDTOS[0].productWeightKgs"
                          type="number"
                          :placeholder="$t('placeholder.plsInput')"
                        >
                          <template slot="append">&nbsp;KGS&nbsp;</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <div
                    class="mb10"
                    :style="{ marginLeft: lang === 'en' ? '160px' : '100px' }"
                  >
                    <span>{{ calculateWeight(i) }} LBS</span>
                  </div>
                  <!-- 产品尺寸 -->
                  <el-row class="product-row">
                    <el-form-item>
                      <template slot="label">
                        <i class="red">*</i>
                        {{ $t('productDetail.ProductSize') }}
                      </template>
                      <el-row :gutter="10" type="flex">
                        <el-col :span="7">
                          <!-- 请输入>0的数值 -->
                          <el-form-item
                            :prop="
                              'list.' +
                              i +
                              '.specsDTOS.' +
                              0 +
                              '.productLengthCm'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                              {
                                pattern:
                                  /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                                message: $t('rules.M2014'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <!-- 长度 -->
                            <el-input
                              v-model="
                                form.list[i].specsDTOS[0].productLengthCm
                              "
                              type="number"
                              :placeholder="$t('placeholder.M2008')"
                              @blur="calculateSize(i)"
                            >
                              <template slot="append">
                                <select
                                  class="append-select"
                                  v-model="form.list[i].specsDTOS[0].sizeUnit"
                                  @change="proSizeUnitChange(i)"
                                >
                                  <option>IN</option>
                                  <option>FT</option>
                                  <option>CM</option>
                                </select>
                              </template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="1">x</el-col>
                        <el-col :span="7">
                          <!-- 请输入>0的数值 -->
                          <el-form-item
                            :prop="
                              'list.' +
                              i +
                              '.specsDTOS.' +
                              0 +
                              '.productWidthCm'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                              {
                                pattern:
                                  /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                                message: $t('rules.M2014'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <!-- 宽度 -->
                            <el-input
                              v-model="form.list[i].specsDTOS[0].productWidthCm"
                              type="number"
                              :placeholder="$t('placeholder.M2009')"
                              @blur="calculateSize(i)"
                            >
                              <template slot="append">
                                <select
                                  class="append-select"
                                  v-model="form.list[i].specsDTOS[0].sizeUnit"
                                  @change="proSizeUnitChange(i)"
                                >
                                  <option>IN</option>
                                  <option>FT</option>
                                  <option>CM</option>
                                </select>
                              </template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="1">x</el-col>
                        <el-col :span="7">
                          <el-form-item
                            :prop="
                              'list.' +
                              i +
                              '.specsDTOS.' +
                              0 +
                              '.productHeightCm'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                              {
                                pattern:
                                  /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                                message: $t('rules.M2014'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <!-- 高度 -->
                            <el-input
                              v-model="
                                form.list[i].specsDTOS[0].productHeightCm
                              "
                              type="number"
                              :placeholder="$t('placeholder.M2010')"
                              @blur="calculateSize(i)"
                            >
                              <template slot="append">
                                <select
                                  class="append-select"
                                  v-model="form.list[i].specsDTOS[0].sizeUnit"
                                  @change="proSizeUnitChange(i)"
                                >
                                  <option>IN</option>
                                  <option>FT</option>
                                  <option>CM</option>
                                </select>
                              </template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-row>
                  <div
                    class="mb10"
                    :style="{ marginLeft: lang === 'en' ? '160px' : '100px' }"
                  >
                    <span>{{ calculateSize(i) }}</span>
                  </div>
                  <!-- 印刷区域 -->
                  <el-row class="product-row">
                    <el-form-item
                      :prop="'list.' + i + '.specsDTOS.' + 0 + '.printSize'"
                    >
                      <template slot="label">
                        <i class="red">*</i>
                        {{ $t('inquiryDetails.PrintingArea') }}
                      </template>
                      <el-row :gutter="10" type="flex">
                        <el-col :span="7">
                          <!-- 请输入>0的数值 -->
                          <el-form-item
                            :prop="
                              'list.' + i + '.specsDTOS.' + 0 + '.printLength'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                              {
                                pattern:
                                  /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                                message: $t('rules.M2014'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <!-- 长度 -->
                            <el-input
                              v-model="form.list[i].specsDTOS[0].printLength"
                              type="number"
                              :placeholder="$t('placeholder.M2008')"
                            >
                              <template slot="append">
                                <select
                                  class="append-select"
                                  v-model="form.list[i].specsDTOS[0].printUnit"
                                  @change="proPrintChange(i)"
                                >
                                  <option>IN</option>
                                  <option>FT</option>
                                  <option>CM</option>
                                </select>
                              </template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="1">x</el-col>
                        <el-col :span="7">
                          <!-- 请输入>0的数值 -->
                          <el-form-item
                            :prop="
                              'list.' + i + '.specsDTOS.' + 0 + '.printWidth'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                              {
                                pattern:
                                  /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                                message: $t('rules.M2014'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <!-- 宽度 -->
                            <el-input
                              v-model="form.list[i].specsDTOS[0].printWidth"
                              type="number"
                              :placeholder="$t('placeholder.M2009')"
                            >
                              <template slot="append">
                                <select
                                  class="append-select"
                                  v-model="form.list[i].specsDTOS[0].printUnit"
                                  @change="proPrintChange(i)"
                                >
                                  <option>IN</option>
                                  <option>FT</option>
                                  <option>CM</option>
                                </select>
                              </template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-row>
                  <div
                    class="mb10"
                    :style="{ marginLeft: lang === 'en' ? '160px' : '100px' }"
                  >
                    <span>{{ calculatePrint(i) }}</span>
                  </div>
                  <!-- 装箱数量 装箱毛重 -->
                  <el-row>
                    <el-col :span="11">
                      <el-form-item
                        :label="$t('productDetail.PackingQuantity')"
                        :prop="
                          'list.' + i + '.specsDTOS.' + 0 + '.numberPerBox'
                        "
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="form.list[i].specsDTOS[0].numberPerBox"
                          @blur="calcPrice(i, null, null, 'pro')"
                          type="number"
                          :placeholder="$t('placeholder.plsInput')"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="1">
                      <el-row>
                        <el-col :span="18">
                          <el-form-item
                            :label="$t('productDetail.PackingGrossWeight')"
                            :prop="
                              'list.' + i + '.specsDTOS.' + 0 + '.boxWeightKgs'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input
                              v-model="form.list[i].specsDTOS[0].boxWeightKgs"
                              @blur="calcPrice(i, null, null, 'pro')"
                              type="number"
                              :placeholder="$t('placeholder.plsInput')"
                            >
                              <template slot="append">&nbsp;KGS&nbsp;</template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <div class="text-right" style="line-height: 36px">
                            {{ calculateGrossWeight(i) }} LBS
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>

                  <!-- 装箱尺寸 -->
                  <el-row class="product-row">
                    <el-form-item>
                      <template slot="label">
                        <i class="red">*</i>
                        {{ $t('productDetail.PackingSize') }}
                      </template>
                      <el-row :gutter="10" type="flex">
                        <el-col :span="7">
                          <!-- 请输入长度  请输入>0的数值 -->
                          <el-form-item
                            :prop="
                              'list.' + i + '.specsDTOS.' + 0 + '.boxLengthCm'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('rules.M2015'),
                                trigger: 'blur',
                              },
                              {
                                pattern:
                                  /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                                message: $t('rules.M2014'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <!-- 长度 -->
                            <el-input
                              v-model="form.list[i].specsDTOS[0].boxLengthCm"
                              type="number"
                              :placeholder="$t('placeholder.M2008')"
                              @blur="calculateBoxSize(i)"
                            >
                              <template slot="append">&nbsp;cm&nbsp;</template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="1">x</el-col>
                        <el-col :span="7">
                          <!-- 请输入宽度 请输入>0的数值-->
                          <el-form-item
                            :prop="
                              'list.' + i + '.specsDTOS.' + 0 + '.boxWidthCm'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('rules.M2016'),
                                trigger: 'blur',
                              },
                              {
                                pattern:
                                  /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                                message: $t('rules.M2014'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <!-- 宽度 -->
                            <el-input
                              v-model="form.list[i].specsDTOS[0].boxWidthCm"
                              type="number"
                              :placeholder="$t('placeholder.M2009')"
                              @blur="calculateBoxSize(i)"
                            >
                              <template slot="append">&nbsp;cm&nbsp;</template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="1">x</el-col>
                        <el-col :span="7">
                          <!-- 请输入高度 请输入>0的数值-->
                          <el-form-item
                            :prop="
                              'list.' + i + '.specsDTOS.' + 0 + '.boxHeightCm'
                            "
                            :rules="[
                              {
                                required: true,
                                message: $t('rules.M2016'),
                                trigger: 'blur',
                              },
                              {
                                pattern:
                                  /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                                message: $t('rules.M2014'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input
                              v-model="form.list[i].specsDTOS[0].boxHeightCm"
                              type="number"
                              :placeholder="$t('placeholder.M2010')"
                              @blur="calculateBoxSize(i)"
                            >
                              <template slot="append">&nbsp;cm&nbsp;</template>
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-row>
                  <div
                    class="mb10"
                    :style="{ marginLeft: lang === 'en' ? '160px' : '100px' }"
                  >
                    <span>
                      {{
                        form.list[i].specsDTOS[0].boxLengthIn +
                        '"' +
                        ' X ' +
                        form.list[i].specsDTOS[0].boxWidthIn +
                        '"' +
                        ' X ' +
                        form.list[i].specsDTOS[0].boxHeightIn +
                        '"'
                      }}
                    </span>
                  </div>
                </section>

                <!-- 印刷信息 -->
                <p class="dark">{{ $t('addInquiry.PrintingInformation') }}</p>
                <!-- 手动填写 -->
                <el-form-item :label="$t('addInquiry.FillInManually')">
                  <el-switch
                    v-model="form.list[i].manualTranslation"
                    @change="manualTranslationChange(i)"
                    :active-value="1"
                    :inactive-value="0"
                  ></el-switch>
                  <span class="ml10">
                    {{ $t('inquiryDetails.Tips23') }}
                  </span>
                </el-form-item>
                <section v-if="form.list[i].manualTranslation === 0">
                  <el-row>
                    <el-col :span="11">
                      <!-- 印刷方式 -->
                      <el-form-item
                        :label="$t('addInquiry.PrintingMethod')"
                        :prop="'list.' + i + '.printingWayId'"
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsSel'),
                            trigger: 'change',
                          },
                        ]"
                      >
                        <el-select
                          v-model="form.list[i].printingWayId"
                          :placeholder="$t('placeholder.plsSel')"
                          @change="printingWayChange(i)"
                        >
                          <el-option
                            v-for="opt in item.printingWayOptions"
                            :key="opt.printingWayId"
                            :label="lang === 'en' ? opt.nameEn : opt.nameCn"
                            :value="opt.printingWayId"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="11"
                      :offset="1"
                      v-if="
                        !['Blank', 'Sample'].includes(
                          form.list[i].printingWayId
                        )
                      "
                    >
                      <!-- 印刷细节 -->
                      <el-form-item
                        :label="$t('addInquiry.PrintingDetails')"
                        :prop="'list.' + i + '.waySelect'"
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsSel'),
                            trigger: 'change',
                          },
                        ]"
                      >
                        <el-select
                          v-model="form.list[i].waySelect"
                          :placeholder="$t('placeholder.plsSel')"
                          @change="calcPrice(i)"
                        >
                          <el-option
                            v-for="opt in item.waySelectOptions"
                            :key="opt.value"
                            :label="opt.label"
                            :value="opt.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col
                      :span="11"
                      v-if="
                        !['Blank', 'Sample'].includes(
                          form.list[i].printingWayId
                        )
                      "
                    >
                      <!-- 印刷位置 -->
                      <el-form-item
                        :label="$t('addInquiry.PrintingPosition')"
                        :prop="'list.' + i + '.digitSelect'"
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsSel'),
                            trigger: 'change',
                          },
                        ]"
                      >
                        <el-select
                          v-model="form.list[i].digitSelect"
                          :placeholder="$t('placeholder.plsSel')"
                          @change="calcPrice(i)"
                        >
                          <el-option
                            v-for="opt in item.digitSelectOptions"
                            :key="opt.value"
                            :label="opt.label"
                            :value="opt.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </section>
                <section v-else>
                  <el-row>
                    <el-col :span="11">
                      <!-- 印刷方式 -->
                      <el-form-item
                        :label="$t('addInquiry.PrintingMethod')"
                        :prop="'list.' + i + '.printingWay'"
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="form.list[i].printingWay"
                          @blur="
                            form.list[i].printingWayEn =
                              form.list[i].printingWay
                          "
                          :placeholder="$t('placeholder.plsInput')"
                          maxlength="50"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="1">
                      <!-- 印刷细节 -->
                      <el-form-item
                        :label="$t('addInquiry.PrintingDetails')"
                        :prop="'list.' + i + '.waySelect'"
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="form.list[i].waySelect"
                          :placeholder="$t('placeholder.plsInput')"
                          maxlength="50"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="11">
                      <!-- 印刷位置 -->
                      <el-form-item
                        :label="$t('addInquiry.PrintingPosition')"
                        :prop="'list.' + i + '.printingPosition'"
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="form.list[i].printingPosition"
                          :placeholder="$t('placeholder.plsInput')"
                          maxlength="50"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </section>

                <!-- 运输信息 -->
                <p class="dark">{{ $t('addInquiry.TransportInformation') }}</p>
                <el-row>
                  <el-col :span="12">
                    <!-- 运输类型 -->
                    <el-form-item
                      :label="$t('addInquiry.ShippingMethod')"
                      :label-width="lang === 'en' ? '135px' : '100px'"
                      :prop="'list.' + i + '.transportType'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <!-- 空运 -->
                      <el-radio
                        v-model="form.list[i].transportType"
                        label="1"
                        @change="calcPrice(i, null, null, 'ship')"
                      >
                        {{ $t('addInquiry.AirTransportation') }}
                      </el-radio>
                      <!-- 海运 -->
                      <el-radio
                        v-model="form.list[i].transportType"
                        label="2"
                        @change="calcPrice(i, null, null, 'ship')"
                      >
                        {{ $t('addInquiry.SeaTransportation') }}
                      </el-radio>
                      <!-- 快递 -->
                      <el-radio
                        v-model="form.list[i].transportType"
                        label="3"
                        @change="calcPrice(i, null, null, 'ship')"
                      >
                        {{ $t('addInquiry.Expressage') }}
                      </el-radio>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <!-- 收货国家 -->
                    <el-form-item
                      :label="$t('addInquiry.ReceivingCountries')"
                      :prop="'list.' + i + '.shippingCountry'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="form.list[i].shippingCountry"
                        @change="calcPrice(i, null, null, 'ship')"
                        :placeholder="$t('placeholder.plsSel')"
                        clearable
                      >
                        <el-option
                          v-for="item in countryOptions"
                          :key="item.code"
                          :label="lang === 'en' ? item.nameEn : item.areaName"
                          :value="item.code"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 派送到门 -->
                <el-form-item
                  :label="$t('addInquiry.DeliveryDoor')"
                  :prop="'list.' + i + '.deliveryToTheDoor'"
                >
                  <el-switch
                    v-model="form.list[i].deliveryToTheDoor"
                    :active-value="1"
                    :inactive-value="0"
                  ></el-switch>
                </el-form-item>
                <!-- 邮编 -->
                <el-row>
                  <el-col :span="23">
                    <el-form-item
                      :label="$t('addInquiry.Postcode')"
                      :prop="'list.' + i + '.zipCode'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                      v-show="form.list[i].deliveryToTheDoor === 1"
                    >
                      <el-input
                        v-model="form.list[i].zipCode"
                        maxlength="50"
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 收货地址 -->
                <el-row>
                  <el-col :span="23">
                    <el-form-item
                      :label="$t('addInquiry.ShippingAddress')"
                      :prop="'list.' + i + '.shippingAddress'"
                      v-show="form.list[i].deliveryToTheDoor === 1"
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="200"
                        show-word-limit
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                        v-model="form.list[i].shippingAddress"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 报价数量 -->
                <el-row class="product-row">
                  <el-col :span="16">
                    <el-form-item
                      v-if="form.list[i].printingWayId === 'Sample'"
                      :label="$t('inquiryDetails.QuoteQuantity')"
                      :prop="'list.' + i + '.selectQuantity'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.list[i].selectQuantity"
                        type="number"
                        :min="1"
                        maxlength="10"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        :placeholder="$t('placeholder.plsInput')"
                        @blur="calcPrice(i)"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-else
                      :label="$t('inquiryDetails.QuoteQuantity')"
                      :prop="'list.' + i + '.selectQuantity'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <div class="flex">
                        <el-input
                          v-model="form.list[i].num"
                          type="number"
                          :min="1"
                          maxlength="10"
                          @keyup.enter.native="addNumbers(form.list[i].num, i)"
                          onkeyup="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                        <!-- 添加 -->
                        <el-button
                          type="primary"
                          class="ml10"
                          @click="addNumbers(form.list[i].num, i)"
                          :disabled="!form.list[i].num"
                        >
                          {{ $t('addInquiry.Add') }}
                        </el-button>
                        <!-- 默认阶梯 -->
                        <el-button class="ml10" @click="defaultLadder(i)">
                          {{ $t('addInquiry.DefaultLadder') }}
                        </el-button>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 已选数量： -->
                <el-form-item v-if="item.selectQuantity">
                  {{ $t('addInquiry.SelectedNumber') }}
                  <span>
                    <el-tag
                      :key="tag"
                      v-for="tag in item.selectQuantity.split(',')"
                      closable
                      @close="numbersClose(tag, i)"
                    >
                      {{ tag }}
                    </el-tag>
                  </span>
                </el-form-item>
                <div v-else class="mb15"></div>
                <!-- 报价备注 -->
                <el-row>
                  <el-col :span="23">
                    <el-form-item
                      :label="$t('inquiryDetails.QuoteRemark')"
                      :prop="'list.' + i + '.remark'"
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="500"
                        show-word-limit
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                        v-model="form.list[i].remark"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="r-main">
              <!-- 产品编码 英文名称 产品类型 -->
              <p>
                {{ $t('productCenter.ProductCode') }}: {{ item.productCode }},
                {{ $t('productCenter.EnglishName') }}: {{ item.productEn }},
                {{ $t('productCenter.ProductType') }}:
                {{ productTypeTransl(item.standardCustomized) }}
              </p>
              <div
                v-if="
                  form.list[i].informationDTOS &&
                  form.list[i].informationDTOS.length &&
                  form.list[i].selectQuantity
                "
              >
                <div class="box">
                  <div class="title">
                    <ul
                      class="dark"
                      v-if="form.list[i].printingWayId === 'Sample'"
                    >
                      <li>Sample Qty</li>
                      <li>Sample Price</li>
                      <li>Shipping Fee</li>
                      <li
                        class="fees-tips"
                        v-for="(fees, m) in form.list[i].informationDTOS[0]
                          .expenseNameList"
                        :key="fees.label"
                      >
                        <span class="ell" :title="fees.label">
                          {{ fees.label }}
                        </span>
                        <em>
                          <el-tooltip
                            placement="top"
                            effect="light"
                            :content="getFeesRemark(fees.label)"
                          >
                            <i class="el-icon-warning-outline"></i>
                          </el-tooltip>
                        </em>
                        <i
                          class="el-icon-delete red del"
                          @click="customFeesDel(i, m)"
                        ></i>
                      </li>
                      <li>Sample Time</li>
                      <li>Shipping Time</li>
                    </ul>
                    <ul class="dark" v-else>
                      <li>Qty</li>
                      <li v-if="item.standardCustomized === '标准品'">
                        {{ $t('inquiryDetails.Trial') }} Net Price
                        <el-tooltip
                          placement="top"
                          effect="light"
                          :content="$t('inquiryDetails.Tips7')"
                        >
                          <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                      </li>
                      <li v-else>Net Price</li>
                      <section v-show="item.standardCustomized === '标准品'">
                        <li>
                          Sage Net price
                          <el-tooltip
                            placement="top"
                            effect="light"
                            :content="$t('inquiryDetails.Tips8')"
                          >
                            <i class="el-icon-warning-outline"></i>
                          </el-tooltip>
                        </li>
                        <li v-show="form.list[i].printingWayId != 'Blank'">
                          Net Price {{ $t('inquiryDetails.Spread') }}
                          <el-tooltip
                            placement="top"
                            effect="light"
                            :content="$t('inquiryDetails.Tips9')"
                          >
                            <i class="el-icon-warning-outline"></i>
                          </el-tooltip>
                        </li>
                        <li v-show="isShowAdditional(i)">
                          Additional Imprint
                          <el-tooltip
                            placement="top"
                            effect="light"
                            :content="$t('inquiryDetails.Tips10')"
                          >
                            <i class="el-icon-warning-outline"></i>
                          </el-tooltip>
                        </li>
                        <!-- 规格加价 -->
                        <li
                          class="ell"
                          v-for="(item, i) in specsProcess(
                            form.list[i].informationDTOS[0].addPricePOList || []
                          )"
                          :title="item.specsValue + ' Additional'"
                          :key="i"
                        >
                          {{ item.specsValue + ' Additional' }}
                        </li>
                      </section>
                      <li>Setup Charge</li>
                      <li>Shipping Fee</li>
                      <li
                        class="fees-tips"
                        v-for="(fees, m) in form.list[i].informationDTOS[0]
                          .expenseNameList"
                        :key="fees.label"
                      >
                        <span class="ell" :title="fees.label">
                          {{ fees.label }}
                        </span>
                        <em>
                          <el-tooltip
                            placement="top"
                            effect="light"
                            :content="getFeesRemark(fees.label)"
                          >
                            <i class="el-icon-warning-outline"></i>
                          </el-tooltip>
                        </em>
                        <i
                          class="el-icon-delete red del"
                          @click="customFeesDel(i, m)"
                        ></i>
                      </li>
                      <li>Total Amount</li>
                      <li>Production Time</li>
                      <li>Shipping Time</li>
                    </ul>
                  </div>
                  <div class="content">
                    <div
                      v-for="(lad, n) in form.list[i].informationDTOS"
                      :key="n"
                      class="price-box"
                    >
                      <ul>
                        <section v-if="form.list[i].printingWayId === 'Sample'">
                          <!-- Sample Qty -->
                          <li class="dark">{{ lad.num }}</li>
                          <!-- Sample Price -->
                          <li class="fees">
                            <el-form-item>
                              <el-input
                                v-model="form.list[i].proofingFee"
                                @blur="calculateTotal(lad, i, n)"
                                type="number"
                                :min="0"
                                :placeholder="$t('placeholder.plsInput')"
                              ></el-input>
                            </el-form-item>
                          </li>
                          <li class="fees">
                            <el-form-item>
                              <el-input
                                v-model="
                                  form.list[i].informationDTOS[n].shippingFee
                                "
                                @blur="calculateTotal(lad, i, n)"
                                type="number"
                                :min="0"
                                :placeholder="$t('placeholder.plsInput')"
                              ></el-input>
                            </el-form-item>
                          </li>
                          <!-- 自定义费用 -->
                          <li
                            v-for="(fees, m) in form.list[i].informationDTOS[n]
                              .expenseNameList"
                            :key="m"
                            class="fees"
                          >
                            <el-form-item>
                              <el-input
                                v-model="
                                  form.list[i].informationDTOS[n]
                                    .expenseNameList[m].amount
                                "
                                @blur="calculateTotal(lad, i, n)"
                                type="number"
                                :min="0"
                                :placeholder="$t('placeholder.plsSel')"
                              ></el-input>
                            </el-form-item>
                          </li>
                        </section>
                        <section v-else>
                          <li class="dark">{{ lad.num }}</li>
                          <li
                            v-if="item.standardCustomized === '标准品'"
                            class="net-price"
                          >
                            $ {{ toFixed3(lad.calcNetPrice) }}
                            <i
                              class="el-icon-edit-outline"
                              @click="netPrice(i, n)"
                            ></i>
                          </li>
                          <li v-else class="net-price">
                            $
                            {{ toFixed3(lad.netPrice) }}
                            <i
                              class="el-icon-edit-outline"
                              @click="netPrice(i, n)"
                            ></i>
                          </li>
                          <section
                            v-show="item.standardCustomized === '标准品'"
                          >
                            <!-- Sage Net Price -->
                            <li>$ {{ toFixed3(lad.netPrice) }}</li>
                            <!-- Net Price差价 -->
                            <li v-show="form.list[i].printingWayId != 'Blank'">
                              $
                              {{
                                lad.calcNetPrice
                                  ? toFixed3(lad.calcNetPrice - lad.netPrice)
                                  : 0
                              }}
                            </li>
                            <!-- Additional Imprint -->
                            <li
                              class="additional-fees"
                              v-show="isShowAdditional(i)"
                            >
                              <el-form-item>
                                <el-input
                                  v-model="
                                    form.list[i].informationDTOS[n]
                                      .additionalimprint
                                  "
                                  @blur="calculateTotal(lad, i, n)"
                                  type="number"
                                  :min="0"
                                  :placeholder="$t('placeholder.plsInput')"
                                ></el-input>
                                <i
                                  class="iconfont icon-jisuan"
                                  @click="additionalFees(i, n)"
                                ></i>
                              </el-form-item>
                            </li>
                            <!-- 规格加价 -->
                            <li
                              class="fees ell"
                              v-for="(specs, i) in specsProcess(
                                lad.addPricePOList || []
                              )"
                              :key="i"
                            >
                              ${{ toFixed3(specs.specsPrice * lad.num) }}
                            </li>
                          </section>
                          <!-- Setup Charge -->
                          <li class="fees">
                            <el-form-item>
                              <el-input
                                v-model="form.list[i].informationDTOS[n].fee"
                                @blur="calculateTotal(lad, i, n)"
                                type="number"
                                :min="0"
                                :placeholder="$t('placeholder.plsInput')"
                              ></el-input>
                            </el-form-item>
                          </li>
                          <!-- shippingFee -->
                          <li class="fees">
                            <el-form-item>
                              <el-input
                                v-model="
                                  form.list[i].informationDTOS[n].shippingFee
                                "
                                @blur="calculateTotal(lad, i, n)"
                                type="number"
                                :min="0"
                                :placeholder="$t('placeholder.plsInput')"
                              ></el-input>
                            </el-form-item>
                          </li>
                          <!-- 自定义费用 -->
                          <li
                            v-for="(fees, m) in form.list[i].informationDTOS[n]
                              .expenseNameList"
                            :key="m"
                            class="fees"
                          >
                            <el-form-item>
                              <el-input
                                v-model="
                                  form.list[i].informationDTOS[n]
                                    .expenseNameList[m].amount
                                "
                                @blur="calculateTotal(lad, i, n)"
                                type="number"
                                :min="0"
                                :placeholder="$t('placeholder.plsInput')"
                              ></el-input>
                            </el-form-item>
                          </li>
                          <!-- Total Amount -->
                          <li class="green">
                            ${{
                              form.list[i].informationDTOS[n].totalAmount || 0
                            }}
                          </li>
                        </section>
                        <!-- Production Time || Sample Time -->
                        <li>
                          <el-form-item
                            :prop="
                              'list.' +
                              i +
                              '.informationDTOS.' +
                              n +
                              '.productionTime'
                            "
                          >
                            <el-input
                              v-model="
                                form.list[i].informationDTOS[n].productionTime
                              "
                              type="number"
                            >
                              <template slot="append">
                                <select
                                  class="append-select"
                                  v-model="
                                    form.list[i].informationDTOS[n]
                                      .productionType
                                  "
                                >
                                  <!-- 工作日 -->
                                  <option :value="0">
                                    {{ $t('inquiryDetails.WorkingDay') }}
                                  </option>
                                  <!-- 自然日 -->
                                  <option :value="1">
                                    {{ $t('inquiryDetails.NaturalDay') }}
                                  </option>
                                </select>
                              </template>
                            </el-input>
                          </el-form-item>
                        </li>
                        <!-- Shipping Time -->
                        <li>
                          <el-form-item
                            :prop="
                              'list.' +
                              i +
                              '.informationDTOS.' +
                              n +
                              '.shippingTime'
                            "
                          >
                            <el-input
                              v-model="
                                form.list[i].informationDTOS[n].shippingTime
                              "
                              type="number"
                            >
                              <template slot="append">
                                <select
                                  class="append-select"
                                  v-model="
                                    form.list[i].informationDTOS[n].shippingType
                                  "
                                >
                                  <!-- 工作日 -->
                                  <option :value="0">
                                    {{ $t('inquiryDetails.WorkingDay') }}
                                  </option>
                                  <!-- 自然日 -->
                                  <option :value="1">
                                    {{ $t('inquiryDetails.NaturalDay') }}
                                  </option>
                                </select>
                              </template>
                            </el-input>
                          </el-form-item>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="custom mt10">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-select
                        :placeholder="$t('placeholder.plsSel')"
                        v-model="form.list[i].customFees"
                        clearable
                      >
                        <el-option
                          v-for="opt in customFeesOptions"
                          :key="opt.value"
                          :label="opt.label"
                          :value="opt.label"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="2">
                      <!-- 添加 -->
                      <el-button type="primary" @click="addCustomFees(i)">
                        {{ $t('addInquiry.Add') }}
                      </el-button>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div v-else>
                <div class="box">
                  <div class="title">
                    <ul
                      class="dark"
                      v-if="form.list[i].printingWayId === 'Sample'"
                    >
                      <li>Sample Qty</li>
                      <li>Sample Price</li>
                      <li>Shipping Fee</li>
                      <li>Sample Time</li>
                      <li>Shipping Time</li>
                    </ul>
                    <ul class="dark" v-else>
                      <li>Qty</li>
                      <li v-if="item.standardCustomized === '标准品'">
                        {{ $t('inquiryDetails.Trial') }} Net Price
                      </li>
                      <li v-else>Net Price</li>
                      <section v-show="item.standardCustomized === '标准品'">
                        <li>Sage Net price</li>
                      </section>
                      <li>Setup Charge</li>
                      <li>Shipping Fee</li>
                      <li>Total Amount</li>
                      <li>Production Time</li>
                      <li>Shipping Time</li>
                    </ul>
                  </div>
                  <div class="content">
                    <el-empty :image-size="100"></el-empty>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>

    <div class="oper-btn">
      <div class="text-center mt20">
        <!-- 预览报价单 -->
        <el-button type="info" @click="preview()">
          {{ $t('inquiryDetails.PreviewQuote') }}
        </el-button>
        <!-- 保存草稿 -->
        <el-button type="primary" @click="draft()" :loading="loading">
          {{ $t('inquiryDetails.SaveDraft') }}
        </el-button>
        <!-- 提交报价 -->
        <el-button type="primary" @click="save()" :loading="loading">
          {{ $t('inquiryDetails.SubmitQuote') }}
        </el-button>
        <div class="wrapper-mail">
          <!-- 发送邮件给客户 -->
          <el-checkbox v-model="sendMailToCustomer">
            {{ $t('inquiryDetails.SendMailToCustomers') }}
          </el-checkbox>
        </div>
      </div>
    </div>

    <!-- 选择印刷效果图 -->
    <specification-image
      :visible.sync="imagesModelVisible"
      @confirm="setRendingImages"
    ></specification-image>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
    <!-- 报价产品管理 -->
    <offer-product
      ref="offerProduct"
      @offerProductSave="offerProductSave"
    ></offer-product>
    <!-- 试算Net Price -->
    <net-price
      ref="netPrice"
      @adjustmentNetPrice="adjustmentNetPrice"
    ></net-price>
    <!-- additional Imprint -->
    <additional-imprint ref="additional"></additional-imprint>
    <!--  -->
    <div class="pdf-wrapper" v-show="isShowPrint">
      <InquiryPrint
        ref="InquiryPrint"
        @rendered="domToPdf"
        id="pdfEl"
        style="width: 914px"
      />
    </div>
    <!-- 报价单预览 -->
    <el-dialog :visible.sync="offerSheetVisible" width="980px">
      <div class="offer-container">
        <offerSheet ref="offerSheet" :preview="true"></offerSheet>
      </div>
      <div class="text-center mt20">
        <!-- 关闭 -->
        <el-button @click="offerSheetVisible = false">
          {{ $t('inquiryDetails.Close') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    orderPriceSheet,
    orderInsertPO,
    getPriceSheetDetail,
    getPriceSheetClone,
  } from '@/api/order.js'
  import { inquiryInteractor } from '@/core/interactors/inquiry/inquiry'
  import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'
  import { OrderOfferSetting } from '@/core/interactors/order/order-offer-setting'
  import offerProduct from './components/offerProduct.vue'
  import netPrice from './components/netPrice.vue'
  import additionalImprint from './components/additionalImprint.vue'
  import specificationImage from './components/specificationImage'
  import InquiryPrint from './inquiryPrint.vue'
  import offerSheet from './inquiryPrint.vue'
  import { getNowDate } from '@/utils/index'
  import { mapGetters } from 'vuex'
  import { uploadPdfFile } from '@/api/mail/mail.js'
  let P_LENGTH
  export default {
    components: {
      offerProduct,
      netPrice,
      additionalImprint,
      specificationImage,
      InquiryPrint,
      offerSheet,
    },
    data() {
      return {
        enquiryId: '',
        enquiryCode: '',
        title: '',
        form: {
          validityDate: '', // 报价有效期
          list: [],
        },
        exchange: 1, // 汇率
        countryOptions: [],
        customFeesOptions: [],
        rules: {},
        baseInfo: {},
        showViewer: false, // 图片预览参数
        imgUrl: [], // 图片预览url
        imageIndex: 0, // 列表图片索引
        imagesModelVisible: false, // 选择规格图片弹窗
        sendMailToCustomer: true, // 发送邮件
        isAdd: true,
        isEdit: false,
        isCopy: false,
        isFirst: true,
        loading: false,
        isShowPrint: false,
        offerSheetVisible: false,
        offerSetting: {}, // 报价设置
        // 印刷方式默认配置
        defaultPrint: [
          {
            printingWayId: 'Blank',
            nameCn: 'Blank',
            nameEn: 'Blank',
          },
          {
            printingWayId: 'Sample',
            nameCn: 'Sample',
            nameEn: 'Sample',
          },
        ],
        emptyImg: require('@/assets/empty_images/picture.png'),
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
    },
    mounted() {},
    created() {
      this.isEdit = this.$route.query?.isEdit
      this.isCopy = this.$route.query?.isCopy
      this.enquiryId = this.$route.query?.enquiryId
      this.priceSheetCode = this.$route.query?.priceSheetCode
      this.countryList()
      this.getExchangeRate()
      this.getOfferSetting()
      this.getCustomFeesOptions()
      if (this.isEdit || this.isCopy) {
        this.isAdd = false
        this.getDetailOrClone(this.priceSheetCode)
      } else {
        this.isAdd = true
        this.getOrderPriceSheet(this.enquiryId)
      }
    },
    methods: {
      // 锚点定位
      navSelect(i) {
        this.$nextTick(() => {
          let height =
            document.getElementsByClassName('main')[i].offsetTop - 160
          document.getElementsByClassName('main-wrap')[0].scrollTop = height
        })
      },

      // 获取标题
      getTitle() {
        if (this.isEdit) {
          return this.$t('inquiryDetails.EditQuote')
        } else if (this.isCopy) {
          return this.$t('inquiryDetails.CopyQuote')
        } else {
          return this.$t('inquiryDetails.AddQuote')
        }
      },

      // 获取国家列表
      async countryList() {
        this.countryOptions = await FreightSettingInteractor.getListCountry()
      },

      // 获取汇率
      async getExchangeRate() {
        this.exchange = await this.Dicts.getExchange('美元')
      },

      // 获取报价设置
      async getOfferSetting() {
        let res = await OrderOfferSetting.priceQueryOne()

        if (res && res.code === '000000') {
          this.offerSetting = res.data
        }
      },

      // 获取自定义费用列表
      async getCustomFeesOptions() {
        let res = await OrderOfferSetting.listAll({ param: '', showFlag: '' })

        if (res && res.code === '000000') {
          const _arr = res.data?.filter((item) => item.showFlag === 1)
          if (_arr.length) {
            this.customFeesOptions = _arr.map((item) => {
              return {
                label: item.nameEn,
                value: item.customExpenseSettingId,
                remark: item.remark,
              }
            })
          }
        }
      },

      // 产品类型转化
      productTypeTransl(str) {
        if (this.lang === 'en') {
          if (str === '标准品') {
            return 'Standard Product'
          } else {
            return 'Customized Product'
          }
        } else {
          return str || '订制品'
        }
      },

      // 新增获取报价信息
      getOrderPriceSheet(id) {
        orderPriceSheet({ enquiryId: id }).then((res) => {
          if (res && res.code == '000000') {
            this.baseInfo = res.data
            this.form.list = res.data.orderEnquiryProductVOS.map((item) => {
              return {
                ...item,
                enquiryId: this.enquiryId, // 询盘id
                num: '', // 报价数量
                exchange: item.exchange, // 产品汇率
                shippingCountry: item.shippingCountry || 'US', // 收货国家没值默认为美国
                deliveryToTheDoor: 1, // 派送到门默认开启
                standardCustomized: item.standardCustomized
                  ? item.standardCustomized
                  : '订制品', // 没有产品类型默认订制品
                specsValue1: this.getSpecsValue(item.specification, 0), // 规格1
                specsValue2: this.getSpecsValue(item.specification, 1), // 规格2
                printingWayOptions: [], // 印刷方式
                waySelectOptions: [], // 印刷细节
                digitSelectOptions: [], // 印刷位置
                specsDTOS: [{}], // 产品尺寸
                customFees: '', // 自定义费用
                informationDTOS: [], // 计价阶梯
              }
            })

            const _arr = []
            this.form.list.forEach((item) => _arr.push(item.productId))
            this.getPorductsInfo(_arr)
          }
        })
      },

      // 编辑or克隆获取报价详情
      getDetailOrClone(code) {
        let requestUrl = this.isEdit ? getPriceSheetDetail : getPriceSheetClone
        requestUrl({ priceSheetCode: code }).then((res) => {
          if (res && res.code == '000000') {
            const data = res.data
            this.baseInfo = data
            this.form.validityDate = data.validityDate
            this.form.list = data.priceSheets.map((item) => {
              return {
                ...item,
                enquiryId: this.enquiryId, // 询盘id
                num: '', // 报价数量
                specsValue1: this.getSpecsValue(item.specification, 0), // 规格1
                specsValue2: this.getSpecsValue(item.specification, 1), // 规格2
                printingWayOptions: [], // 印刷方式
                waySelectOptions: [], // 印刷细节
                digitSelectOptions: [], // 印刷位置
                specsDTOS: item.specsVOS, // 产品尺寸
                customFees: '', // 自定义费用
                informationDTOS: this.informationFormat(item.informationVOS), // 计价阶梯
                id: this.isCopy ? null : item.id, // 复制清除业务id
                priceSheetId: this.isCopy ? null : item.priceSheetId, // 复制清除业务id
                priceSheetCode: this.isCopy ? null : item.priceSheetCode, // 复制清除业务id
              }
            })

            const _arr = []
            this.form.list.forEach((item) => _arr.push(item.productId))
            this.getPorductsInfo(_arr)
          }
        })
      },

      // 计价阶梯数据处理
      informationFormat(_arr) {
        if (_arr?.length) {
          for (let i = 0; i < _arr.length; i++) {
            if (_arr[i].expenseName) {
              _arr[i].expenseNameList = JSON.parse(_arr[i].expenseName)
            }
            if (_arr[i].specsPriceJson) {
              _arr[i].addPricePOList = JSON.parse(_arr[i].specsPriceJson)
            }
          }
          return _arr
        } else {
          return []
        }
      },

      // 获取产品基本信息
      async getPorductsInfo(_arr) {
        let res = await inquiryInteractor.getEnquiryProductDetailList(
          _arr.join(',')
        )
        if (res.data.length) {
          let [list, _res] = [this.form.list, res.data]
          for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < _res.length; j++) {
              if (list[i].productId == _res[j].productId) {
                const _ways = _res[j]?.productPrintingWayVOList
                const printingWayId = list[i].printingWayId
                const type = list[i].standardCustomized === '标准品' ? 0 : 1
                const _specs = _res[j].productSpecsEditVOList?.map((item) => {
                  return {
                    ...item,
                    boxLengthCm: item.boxLengthCm || 0,
                    boxWidthCm: item.boxWidthCm || 0,
                    boxHeightCm: item.boxHeightCm || 0,
                  }
                })

                this.$set(
                  this.form.list,
                  i,
                  Object.assign(
                    {},
                    { ...list[i] },
                    {
                      specsType: _res[j].specsType,
                      specsValue: _res[j].specsValue,
                      proofingFee: _res[j].proofingFee,
                      material: this.isAdd
                        ? _res[j].material
                        : list[i].material, // 材质
                      exchange: _res[j].exchange, // 汇率
                      productUrl: this.checkProductInfo(type)
                        ? _res[j].imgMain
                        : list[i].productUrl, // 主图
                      productEn: this.checkProductInfo(type)
                        ? _res[j].nameEn
                        : list[i].productEn, // 英文名称
                      productPrintingWayVOList: _ways, // 印刷信息
                      printingWayOptions: this.getWayOptions(
                        _ways,
                        printingWayId,
                        1
                      ), // 印刷方式
                      waySelectOptions: this.getWayOptions(
                        _ways,
                        printingWayId,
                        2
                      ), // 印刷细节
                      digitSelectOptions: this.getWayOptions(
                        _ways,
                        printingWayId,
                        3
                      ), // 印刷位置
                      productPricingStepVOList:
                        _res[j].productPricingStepVOList, // 默认阶梯
                      productSpecsEditVOList: _specs, // 产品尺寸集合
                      specsDTOS: list[i]?.specsVOS
                        ? list[i]?.specsVOS
                        : [_specs[0]], // 没有产品尺寸默认显示第一条
                    }
                  )
                )
              }
            }

            // 新增满足条件计算价格阶梯
            if (!this.isEdit && !this.isCopy) this.calcPrice(i)
          }
        }
      },

      /**
       * 判断主图信息取值 新增取产品 编辑订制品取保存，标准品取产品
       * @param type 产品类型 0:标准品 1:订制品
       * @param bool true 取产品信息 false 取保存信息
       */
      checkProductInfo(type) {
        if (this.isAdd) {
          return true
        } else {
          if (type === 0) {
            return true
          } else {
            return false
          }
        }
      },

      /**
       * 印刷方式,印刷细节,印刷位置下拉项数据处理
       * @param _arr 数据源
       * @param printingWayId 选中的印刷方式
       * @param n 数据类型 1:印刷方式 2:印刷细节 3:印刷位置
       */
      getWayOptions(_arr, printingWayId, n) {
        let _res = []
        switch (n) {
          case 1:
            if (_arr?.length) {
              _res = _arr.map((item) => {
                return {
                  printingWayId: item.printingWaySelectId,
                  nameCn: item.printingWaySelect,
                  nameEn: item.printingWaySelectEn,
                }
              })
            }
            _res = _res.concat(this.defaultPrint)
            return _res
          case 2:
            if (_arr?.length && !['Blank', 'Sample'].includes(printingWayId)) {
              const waySelect = _arr.filter(
                (item) => item.printingWaySelectId === printingWayId
              )
              const wayArray = waySelect.length
                ? JSON.parse(waySelect[0].printingWay)
                : []

              if (wayArray.length) {
                _res = wayArray.map((item) => {
                  return {
                    label: item.way,
                    labelEn: item.pricingValueEn,
                    value: item.way,
                  }
                })
                return _res
              } else {
                _res = []
              }
              return _res
            }
          case 3:
            if (_arr?.length && !['Blank', 'Sample'].includes(printingWayId)) {
              const waySelect = _arr.filter(
                (item) => item.printingWaySelectId === printingWayId
              )
              const wayArray = waySelect.length
                ? waySelect[0].printingPositions.split(',')
                : []

              for (let i = 0; i < wayArray.length; i++) {
                _res.push({
                  label: i + 1,
                  value: i + 1,
                })
              }
              return _res
            }
        }
      },

      // 选择印刷方式
      printingWayChange(i) {
        const list = this.form.list[i]
        const _arr = list.productPrintingWayVOList

        list.waySelect = ''
        list.digitSelect = ''
        // 选择Sample清空报价数量
        if (list.printingWayId === 'Sample') {
          list.selectQuantity = ''
          list.printingWay = 'Sample'
          list.printingWayEn = 'Sample'
          list.informationDTOS = []
        }
        if (list.printingWayId === 'Blank') {
          list.printingWay = 'Blank'
          list.printingWayEn = 'Blank'
        }
        if (_arr?.length) {
          for (let n = 0; n < _arr.length; n++) {
            if (list.printingWayId == _arr[n].printingWaySelectId) {
              list.printingWay = _arr[n].printingWaySelect
              list.printingWayEn = _arr[n].printingWaySelectEn
              // 印刷细节下拉框赋值
              list.waySelectOptions = this.getWayOptions(
                [_arr[n]],
                list.printingWayId,
                2
              )
              // 印刷位置下拉框赋值
              list.digitSelectOptions = this.getWayOptions(
                [_arr[n]],
                list.printingWayId,
                3
              )
            } else {
              list.printingWay = list.printingWayId
              list.printingWayEn = list.printingWayId
            }
          }
        }
        this.calcPrice(i)
      },

      // 预览
      preview() {
        // 新增Sales和Sales Email取登录人信息
        if (this.isAdd) {
          this.baseInfo.businessName = this.userInfo.englishName
          this.baseInfo.businessEmail = this.userInfo.email
        }
        let info = {
          form: this.form,
          base: this.baseInfo,
          isCopy: this.isCopy,
        }
        sessionStorage.setItem('inquiryPrint', JSON.stringify(info))
        this.offerSheetVisible = true
        setTimeout(() => {
          this.$refs.offerSheet.getPreviewInfo()
        }, 100)
      },

      // 大图预览
      handlePreview(item) {
        if (!item) return false
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      // 保存草稿
      async draft() {
        let params = this.dataProcessing()

        this.loading = true
        let res = await inquiryInteractor.orderSavePO(params)
        if (res && res.code === '000000') {
          this.$message.success(this.$t('reqmsg.$9'))
          this.$router.push({
            path: '/order/quotations-list',
          })
          this.loading = false
        }
      },

      // 提交报价
      save(bool) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let flag = true
            if (this.offerSetting?.limitFlag === 1 && !bool) {
              flag = this.checkOfferSetting()
            }

            if (flag) {
              this.$confirm(
                this.$t('inquiryDetails.Tips20'),
                this.$t('reqmsg.M2005'),
                {
                  type: 'warning',
                }
              ).then(() => {
                let params = this.dataProcessing()

                this.loading = true
                orderInsertPO(params).then((res) => {
                  if (res && res.code == '000000') {
                    this.$message.success(this.$t('reqmsg.$65'))

                    if (this.sendMailToCustomer) {
                      this.generatePdf(res.data, true)
                    } else {
                      this.$router.push({
                        path: '/order/quotations-list',
                      })
                    }
                    this.loading = false
                  }
                })
              })
            }
          } else {
            this.$message.warning(this.$t('inquiryDetails.Tips19'))
          }
        })
      },

      // 校验报价限制
      checkOfferSetting() {
        let [bool, percent, limitMoney, handlingMethod] = [
          true,
          this.offerSetting.percent * 0.01, // 超出Total Amount百分比
          this.offerSetting.money, // 限制金额
          this.offerSetting.handlingMethod, // 0:提示 1:禁止提交
        ]

        let _list = this.form.list
        for (let i = 0; i < _list.length; i++) {
          if (_list[i]?.informationDTOS.length) {
            for (let n = 0; n < this.form.list[i].informationDTOS.length; n++) {
              const [shippingFee, totalAmount] = [
                _list[i].informationDTOS[n].shippingFee,
                _list[i].informationDTOS[n].totalAmount,
              ]
              // 报价条件设置校验
              if (shippingFee) {
                if (shippingFee > totalAmount * percent) {
                  if (handlingMethod === 1) {
                    this.$alert(
                      `${this.$t('inquiryDetails.Tips15')}${
                        this.offerSetting.percent
                      }%, ${this.$t('inquiryDetails.Tips18')}`,
                      this.$t('reqmsg.M2005'),
                      {
                        confirmButtonText: this.$t('reqmsg.M2006'),
                        callback: (action) => {},
                      }
                    )
                  } else {
                    this.$confirm(
                      `${this.$t('inquiryDetails.Tips15')}${
                        this.offerSetting.percent
                      }%, ${this.$t('inquiryDetails.Tips17')}`,
                      this.$t('reqmsg.M2005'),
                      {
                        type: 'warning',
                      }
                    ).then(() => {
                      this.save(true)
                    })
                  }
                  bool = false
                }
                if (shippingFee > limitMoney) {
                  if (handlingMethod === 1) {
                    this.$alert(
                      `${this.$t(
                        'inquiryDetails.Tips16'
                      )}${limitMoney}, ${this.$t('inquiryDetails.Tips18')}`,
                      this.$t('reqmsg.M2005'),
                      {
                        confirmButtonText: this.$t('reqmsg.M2006'),
                        callback: (action) => {},
                      }
                    )
                  } else {
                    this.$confirm(
                      `${this.$t(
                        'inquiryDetails.Tips16'
                      )}${limitMoney}, ${this.$t('inquiryDetails.Tips17')}`,
                      this.$t('reqmsg.M2005'),
                      {
                        type: 'warning',
                      }
                    ).then(() => {
                      this.save(true)
                    })
                  }
                  bool = false
                }
              }
            }
          }
        }
        return bool
      },

      // 提交报价参数处理
      dataProcessing() {
        const _list = this.form.list

        for (let i = 0; i < _list.length; i++) {
          // 编辑产品带入priceSheetCode
          if (this.isEdit) {
            this.form.list[i].priceSheetCode = this.priceSheetCode
          }
          if (_list[i]?.informationDTOS.length) {
            for (let n = 0; n < _list[i].informationDTOS.length; n++) {
              // 自定义费用类型转化
              if (_list[i].informationDTOS[n].expenseNameList) {
                this.form.list[i].informationDTOS[n].expenseName =
                  JSON.stringify(_list[i].informationDTOS[n].expenseNameList)
              }
              // 规格加价转为JSON存储
              if (_list[i].informationDTOS[n].addPricePOList?.length) {
                this.form.list[i].informationDTOS[n].specsPriceJson =
                  JSON.stringify(_list[i].informationDTOS[n].addPricePOList)
              }
            }
          }
          P_LENGTH = _list.length
        }
        this.form.orderPriceSheetDTO = this.form.list
        delete this.form.list
        return {
          enquiryId: this.enquiryId,
          ...this.form,
        }
      },

      // 恢复默认
      recover() {
        this.$confirm(this.$t('reqmsg.M2066'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(() => {
            this.form.validityDate = ''
            this.getOrderPriceSheet(this.enquiryId)
          })
          .catch(() => {})
      },

      // 更多信息
      more(item) {
        let routeData = this.$router.resolve({
          path: '../../../../product/ProductView',
          query: {
            proId: item.productId,
            productCode: item.productCode,
          },
        })

        window.open(routeData.href, '_blank')
      },

      // 产品重量单位转化
      calculateWeight(i) {
        let _list = this.form.list[i].specsDTOS[0]
        if (_list?.productWeightKgs) {
          return (_list.productWeightKgs * 2.2046).toFixed(1)
        }
      },

      // 毛重单位转化
      calculateGrossWeight(i) {
        let _list = this.form.list[i].specsDTOS[0]
        if (_list.boxWeightKgs) {
          return Math.round(_list.boxWeightKgs * 2.2046)
        }
      },

      // 产品尺寸单位转化
      calculateSize(i) {
        let _list = this.form.list[i].specsDTOS[0]
        let [L, W, H, U, unit] = [
          _list.productLengthCm || 0,
          _list.productWidthCm || 0,
          _list.productHeightCm || 0,
          _list.sUnit || ' IN',
          _list.sizeUnit,
        ]
        if (unit === 'FT') {
          // 英尺换算成英寸
          _list.productLengthIn = (L * 12).toFixed(2)
          _list.productWidthIn = (W * 12).toFixed(2)
          _list.productHeightIn = (H * 12).toFixed(2)
        } else if (unit === 'CM') {
          // 厘米换算成英寸
          _list.productLengthIn = (L * 0.3937).toFixed(2)
          _list.productWidthIn = (W * 0.3937).toFixed(2)
          _list.productHeightIn = (H * 0.3937).toFixed(2)
        } else {
          // 英寸换算成厘米
          // _list.productLengthIn = (L * 2.54).toFixed(2)
          // _list.productWidthIn = (W * 2.54).toFixed(2)
          // _list.productHeightIn = (H * 2.54).toFixed(2)
          _list.productLengthIn = L
          _list.productWidthIn = W
          _list.productHeightIn = H
        }
        return (
          _list.productLengthIn +
          '"' +
          ' X ' +
          _list.productWidthIn +
          '"' +
          ' X ' +
          _list.productHeightIn +
          '"'
        )
      },

      // 印刷区域单位转化
      calculatePrint(i) {
        let _list = this.form.list[i].specsDTOS[0]
        let [L, W, U, Unit] = [
          _list.printLength || 0,
          _list.printWidth || 0,
          _list.pUnit,
          _list.printUnit,
        ]
        if (Unit === 'FT') {
          // 英尺换算成英寸
          _list.printLengthCovert = (L * 12).toFixed(2)
          _list.printWidthCovert = (W * 12).toFixed(2)
        } else if (Unit === 'CM') {
          // 厘米换算成英寸
          _list.printLengthCovert = (L * 0.3937).toFixed(2)
          _list.printWidthCovert = (W * 0.3937).toFixed(2)
        } else {
          // 英寸
          _list.printLengthCovert = L
          _list.printWidthCovert = W
        }
        return (
          _list.printLengthCovert + '"' + ' X ' + _list.printWidthCovert + '"'
        )
      },

      // 装箱尺寸单位转化
      calculateBoxSize(i) {
        let _list = this.form.list[i].specsDTOS[0]
        let [L, W, H] = [_list.boxLengthCm, _list.boxWidthCm, _list.boxHeightCm]
        _list.boxLengthIn = (L * 0.3937).toFixed(2)
        _list.boxWidthIn = (W * 0.3937).toFixed(2)
        _list.boxHeightIn = (H * 0.3937).toFixed(2)
        this.calcPrice(i, null, null, 'pro')
      },

      // 产品尺寸选择
      proSizeUnitChange(i) {
        let _list = this.form.list[i].specsDTOS[0]
        if (_list.sizeUnit === 'FT') {
          // 英尺换算成英寸
          _list.sUnit = ' IN'
          if (_list.productLengthCm) {
            _list.productLengthIn = (
              Number(_list.productLengthCm) * 12
            ).toFixed(2)
          }
          if (_list.productWidthCm) {
            _list.productWidthIn = (Number(_list.productWidthCm) * 12).toFixed(
              2
            )
          }
          if (_list.productHeightCm) {
            _list.productHeightIn = (
              Number(_list.productHeightCm) * 12
            ).toFixed(2)
          }
        } else if (_list.sizeUnit === 'CM') {
          // 厘米换算成英寸
          _list.sUnit = ' IN'
          if (_list.productLengthCm) {
            _list.productLengthIn = (
              Number(_list.productLengthCm) * 0.3937
            ).toFixed(2)
          }
          if (_list.productWidthCm) {
            _list.productWidthIn = (
              Number(_list.productWidthCm) * 0.3937
            ).toFixed(2)
          }
          if (_list.productHeightCm) {
            _list.productHeightIn = (
              Number(_list.productHeightCm) * 0.3937
            ).toFixed(2)
          }
        }
      },

      // 印刷区域尺寸选择
      proPrintChange(i) {
        let _list = this.form.list[i].specsDTOS[0]
        if (_list.sizeUnit === 'FT') {
          // 英尺换算成英寸
          _list.pUnit = ' IN'
          if (_list.printLength) {
            _list.printLengthCovert = (Number(_list.printLength) * 12).toFixed(
              2
            )
          }
          if (_list.printWidth) {
            _list.printWidthCovert = (Number(_list.printWidth) * 12).toFixed(2)
          }
        } else if (_list.sizeUnit === 'CM') {
          // 厘米换算成英寸
          _list.pUnit = ' IN'
          if (_list.printLength) {
            _list.printLengthCovert = (
              Number(_list.printLength) * 0.3937
            ).toFixed(2)
          }
          if (_list.printWidth) {
            _list.printWidthCovert = (
              Number(_list.printWidth) * 0.3937
            ).toFixed(2)
          }
        } else {
          // 英寸换算成厘米
          _list.pUnit = ' CM'
          if (_list.printLength) {
            _list.printLengthCovert = (
              Number(_list.printLength) * 2.54
            ).toFixed(2)
          }
          if (_list.printWidth) {
            _list.printWidthCovert = (Number(_list.printWidth) * 2.54).toFixed(
              2
            )
          }
        }
      },

      /**
       * 计算价格阶梯
       * @param i 列表索引
       * @param bool true:获取返回值
       * @param num 添加数量响应结果
       * @param field 请求字段类型 ship:只更新试算Net Price 和 Shipping Time, pro:只更新计费信息
       */
      async calcPrice(i, bool, num, field) {
        if (!this.isShowPricesList(i)) return false

        // 规格处理
        let [specs, list, _obj] = ['', this.form.list[i], {}]
        let type = list.standardCustomized === '标准品' ? 0 : 1 // 0：标准品 1：订制品

        if (!list.specsValue2) {
          specs = list.specsValue1
        } else {
          specs = list.specsValue1 + ',' + list.specsValue2
        }

        // 订制品产品信息参数
        if (type) {
          const specs = list.specsDTOS[0]

          _obj = {
            type: type, // 订制品
            numberPerBox: specs.numberPerBox, // 每箱数量
            boxWeightKgs: specs.boxWeightKgs, // 装箱重量
            productWeightKgs: specs.productWeightKgs, // 产品重量
            boxVolumeM:
              (specs.boxLengthCm * specs.boxWidthCm * specs.boxHeightCm) /
              1000000, // 装箱体积
          }
        }

        let params = {
          specsSelect: specs, // 规格类型
          printingWaySelect: list.printingWayId, // 计价方式选择
          waySelect: list.waySelect, // 计价名称选择
          transportType: list.transportType, // 运输类型
          shippingCountry: list.shippingCountry, // 收货国家
          digitSelect: list.digitSelect, // 印刷位选择
          nums: num ? num : list.selectQuantity, // 数量阶梯
          productId: list.productId, // 产品ID
          ..._obj,
        }
        if (
          (params.printingWaySelect || type === 1) &&
          params.transportType &&
          params.nums
        ) {
          let res = await inquiryInteractor.productPricing(params)

          if (bool) {
            return res.data
          } else {
            if (res && res.code === '000000') {
              if (!res.data.length) return false
              let _arr = res.data.map((item) => {
                return {
                  ...item,
                  expenseNameList: [], // 自定义费用
                  productionType: item.productionType || 0, // 0:自然日 1:工作日
                  shippingType: item.shippingType || 0, // 0:自然日 1:工作日
                  calcNetPrice:
                    type === 0
                      ? this.calcTrialNetPrice(item, 0)
                      : item.calcNetPrice, // 标准品试算Net Price
                }
              })
              // 添加数量获取返回值
              if (num) {
                // Total Amount 和 自定义费用处理
                let expenseNameList = []
                if (list.informationDTOS[0]?.expenseNameList?.length) {
                  expenseNameList = list.informationDTOS[0].expenseNameList.map(
                    (item) => {
                      return {
                        ...item,
                        amount: 0,
                      }
                    }
                  )
                }
                for (let n = 0; n < _arr.length; n++) {
                  _arr[n].expenseNameList = JSON.parse(
                    JSON.stringify(expenseNameList)
                  )
                  _arr[n].totalAmount = this.calculateTotal(
                    _arr[n],
                    i,
                    null,
                    true
                  )
                }

                return _arr
              } else {
                if (field === 'ship') {
                  this.upDateShippingInfo(_arr, type, i)
                } else if (field === 'pro') {
                  this.upDateProductInfo(_arr, i)
                } else {
                  // 请求接口不清空Shipping Fee 和 other Fee
                  if (list.informationDTOS?.length) {
                    for (let n = 0; n < _arr.length; n++) {
                      _arr[n].shippingFee =
                        _arr[n].shippingFee ||
                        list.informationDTOS[n].shippingFee
                      _arr[n].expenseNameList =
                        list.informationDTOS[n].expenseNameList
                    }
                  }
                  this.$set(this.form.list[i], 'informationDTOS', _arr)
                }
                // 第一次加载Total Amount
                for (
                  let n = 0;
                  n < this.form.list[i].informationDTOS.length;
                  n++
                ) {
                  this.calculateTotal(
                    this.form.list[i].informationDTOS[n],
                    i,
                    n
                  )
                }
              }

              this.isFirst = false
            }
          }
        }
      },

      // 切换运输类型收货国家更新数据
      upDateShippingInfo(_arr, type, i) {
        let infos = this.form.list[i].informationDTOS

        // 列表有值更新，没值就直接赋值
        if (infos?.length) {
          for (let n = 0; n < _arr.length; n++) {
            if (type === 0) {
              infos[n].netPrice = _arr[n].netPrice
              infos[n].calcNetPrice = _arr[n].calcNetPrice
              infos[n].costPrice = _arr[n].costPrice
              infos[n].profitRate = _arr[n].profitRate
              infos[n].taxRate = _arr[n].taxRate
              infos[n].basicPrintingPrice = _arr[n].basicPrintingPrice
              infos[n].printingPrice = _arr[n].printingPrice
              infos[n].transportPrice = _arr[n].transportPrice
              infos[n].totalTransportPrice = _arr[n].totalTransportPrice
              infos[n].shippingTime = _arr[n].shippingTime
              infos[n].currency = _arr[n].currency
              infos[n].billingMethod = _arr[n].billingMethod
              infos[n].transportWayCn = _arr[n].transportWayCn
              infos[n].transportWayEn = _arr[n].transportWayEn
              infos[n].freightSettingId = _arr[n].freightSettingId
              infos[n].freightSettingLadderVO = _arr[n].freightSettingLadderVO
            } else {
              infos[n].shippingTime = _arr[n].shippingTime
              infos[n].currency = _arr[n].currency
              infos[n].billingMethod = _arr[n].billingMethod
              infos[n].transportWayCn = _arr[n].transportWayCn
              infos[n].transportWayEn = _arr[n].transportWayEn
              infos[n].freightSettingId = _arr[n].freightSettingId
              infos[n].transportPrice = _arr[n].transportPrice
              infos[n].freightSettingLadderVO = _arr[n].freightSettingLadderVO
            }
          }
          this.$set(this.form.list[i], 'informationDTOS', infos)
        } else {
          this.$set(this.form.list[i], 'informationDTOS', _arr)
        }
      },

      // 切换产品基本信息更新调整Net Price计费信息
      upDateProductInfo(_arr, i) {
        let infos = this.form.list[i].informationDTOS

        // 列表有值更新，没值就直接赋值
        if (infos?.length) {
          for (let n = 0; n < _arr.length; n++) {
            infos[n].currency = _arr[n].currency
            infos[n].billingMethod = _arr[n].billingMethod
            infos[n].transportWayCn = _arr[n].transportWayCn
            infos[n].transportWayEn = _arr[n].transportWayEn
            infos[n].transportPrice = _arr[n].transportPrice
            infos[n].freightSettingId = _arr[n].freightSettingId
            infos[n].freightSettingLadderVO = _arr[n].freightSettingLadderVO
          }
          this.$set(this.form.list[i], 'informationDTOS', infos)
        } else {
          this.$set(this.form.list[i], 'informationDTOS', _arr)
        }
      },

      // 判断是否显示价格阶梯
      isShowPricesList(i) {
        let [_list, bool] = [this.form.list[i], false]
        // 规格不选择，不出现价格阶梯(无规格除外)
        if (_list.specsType) {
          if (_list.specsType.indexOf(',') > 0) {
            // 双规格
            _list.specsValue1 && _list.specsValue2
              ? (bool = true)
              : (bool = false)
          } else {
            // 单规格
            _list.specsValue1 ? (bool = true) : (bool = false)
          }
        } else {
          bool = true
        }

        return bool
      },

      // 默认阶梯
      async defaultLadder(i) {
        let [_arr, _list] = [[], this.form.list[i]]
        if (_list?.selectQuantity) {
          _arr = _arr.concat(_list.selectQuantity.split(','))
        }
        if (_list?.productPricingStepVOList?.length) {
          _list.productPricingStepVOList.forEach(
            (item) => item.num > 0 && _arr.push(String(item.num))
          )
          _list.selectQuantity = Array.from(new Set(_arr))
            .splice(0, 5)
            .join(',')

          let data = await this.calcPrice(i, null, _list.selectQuantity)
          if (_list.informationDTOS.length === 0) {
            _list.informationDTOS = data || []
          } else {
            const _nums = []
            _list.informationDTOS.forEach((item) => _nums.push(item.num))
            for (let n = 0; n < data.length; n++) {
              if (!_nums.includes(data[n].num)) {
                _list.informationDTOS.push(data[n])
              }
            }
          }
        }
      },

      // 添加报价数量
      async addNumbers(num, i) {
        if (!num) return false
        let numbers = this.form.list[i].selectQuantity
          ? this.form.list[i].selectQuantity.split(',')
          : []

        // 询盘数量最多添加5条
        if (numbers.length > 4) {
          this.$message.warning(this.$t('inquiryDetails.Tips22'))
          return false
        }
        if (!numbers.includes(num)) {
          numbers.push(num)
          this.form.list[i].num = ''
          this.form.list[i].selectQuantity = numbers.join(',')

          let _arr = await this.calcPrice(i, null, num)
          if (_arr?.length) {
            this.form.list[i].informationDTOS =
              this.form.list[i].informationDTOS.concat(_arr)

            // this.calculateTotal()
          }
        }
      },

      // 删除报价数量
      numbersClose(tag, i) {
        let numbers = this.form.list[i].selectQuantity.split(',')
        numbers.splice(numbers.indexOf(tag), 1)

        if (numbers.length) {
          this.form.list[i].selectQuantity = numbers.join(',')
        } else {
          this.form.list[i].selectQuantity = ''
        }
        for (let n = 0; n < this.form.list[i].informationDTOS.length; n++) {
          if (this.form.list[i].informationDTOS[n].num == tag) {
            this.form.list[i].informationDTOS.splice(n, 1)
            break
          }
        }
      },

      // 计算Total Amount
      calculateTotal(item, i, n, bool) {
        let [_list, _fees, _specs, customFees, specsPrice] = [
          this.form.list[i],
          item?.expenseNameList || [],
          item?.addPricePOList || [],
          0,
          0,
        ]
        // 自定义费用
        if (_fees.length) {
          customFees = _fees.reduce((p, v) => p + Number(v.amount || 0), 0)
        }
        // 规格加价
        if (_specs.length && _list.standardCustomized === '标准品') {
          specsPrice = Number(item.specsPrice)
        }

        const _obj = {
          num: item.num,
          netPrice: Number(item.netPrice),
          fee: Number(item.fee) || 0,
          additionalimprint: Number(item.additionalimprint) || 0,
          shippingFee: Number(item.shippingFee) || 0,
        }
        let total = (
          _obj.num * _obj.netPrice +
          _obj.fee +
          _obj.shippingFee +
          _obj.additionalimprint +
          specsPrice +
          customFees
        ).toFixed(3)
        if (bool) {
          return total
        } else {
          this.$set(_list.informationDTOS[n], 'totalAmount', total)
        }
      },

      // 规格初始化加载
      specsValueInit(i) {
        let typearr = this.form.list[i].specsType.split(',')
        let svalue = this.form.list[i].specsValue
        let repalcevalue = svalue.replace(/\'/g, '"')
        let valarr = [[]]
        valarr = JSON.parse(repalcevalue)
        let arr = []
        for (let i = 0; i < typearr.length; i++) {
          let obj = {
            type: typearr[i],
            value: valarr[i],
          }
          arr.push(obj)
        }
        return arr
      },

      // 获取规格值
      getSpecsValue(specsValue, i) {
        let _arr = specsValue?.split(',') || []
        if (_arr.length) {
          return _arr[i] || ''
        } else {
          return ''
        }
      },

      // 选择规格
      selectSpecsValue(tag, i, n) {
        const list = this.form.list
        switch (n + 1) {
          case 1:
            list[i].specsValue1 = tag
            break
          case 2:
            list[i].specsValue2 = tag
            break
        }
        // 规格数据处理
        if (list[i].specsValue1 && !list[i].specsValue2) {
          list[i].specification = list[i].specsValue1
        } else if (!list[i].specsValue1 && list[i].specsValue2) {
          list[i].specification = list[i].specsValue2
        } else {
          list[i].specification =
            list[i].specsValue1 + ',' + list[i].specsValue2
        }
        this.calcPrice(i)
        this.switchProductInfo(
          list[i].specsType,
          list[i].specsValue1,
          list[i].specsValue2,
          i
        )
      },

      // 订制品选择规格切换产品信息
      switchProductInfo(spec, V1, V2, i) {
        if (spec.indexOf(',') > 0 && !V2) {
          return
        } else {
          let _specs = this.form.list[i].productSpecsEditVOList
          if (spec.indexOf(',') > 0) {
            // 多规格
            for (let n = 0; n < _specs.length; n++) {
              if (_specs[n].specsValue1 == V1 && _specs[n].specsValue2 == V2) {
                this.form.list[i].sku = _specs[n].sku
                this.$set(
                  this.form.list[i].specsDTOS,
                  0,
                  Object.assign(
                    {},
                    { ...this.form.list[i].productSpecsEditVOList[n] }
                  )
                )
              }
            }
          } else {
            // 单规格
            for (let n = 0; n < _specs.length; n++) {
              if (_specs[n].specsValue1 == V1) {
                this.form.list[i].sku = _specs[n].sku
                this.$set(
                  this.form.list[i].specsDTOS,
                  0,
                  Object.assign(
                    {},
                    { ...this.form.list[i].productSpecsEditVOList[n] }
                  )
                )
              }
            }
          }
        }
      },

      /**
       * 试算Net Price/调整Net Price弹窗
       * @param i 列表索引
       * @param n 计价阶梯索引
       */
      async netPrice(i, n) {
        let info = {
          base: this.form.list[i],
          info: this.form.list[i].informationDTOS[n],
          baseIndex: i,
          infoIndex: n,
        }

        // 编辑复制状态下第一次点击调用接口
        if ((this.isEdit || this.isCopy) && this.isFirst) {
          const data = await this.calcPrice(i, true)
          const _obj = {
            ...info.info,
            billingMethod: data[n]?.billingMethod || '',
            transportWayCn: data[n]?.transportWayCn || '',
            transportWayEn: data[n]?.transportWayEn || '',
            freightSettingId: data[n]?.freightSettingId || '',
            freightSettingLadderVO: data[n]?.freightSettingLadderVO || '',
          }
          info.info = _obj
        }
        if (info.base.standardCustomized != '标准品') {
          info.info.exchange = info.info.exchange || this.exchange
        } else {
          info.info.exchange = this.exchange
        }
        this.$refs.netPrice.netPriceInfo = info
        this.$refs.netPrice.modelVisible = true
      },

      // 查看 Additional Imprint
      async additionalFees(i, n) {
        let _list = this.form.list[i]

        // 编辑复制状态下第一次点击调用接口
        if ((this.isEdit || this.isCopy) && this.isFirst) {
          let data = await this.calcPrice(i, true)
          if (data.length) {
            this.$refs.additional.info = {
              num: _list.num,
              digitSelect: _list.digitSelect || 0,
              info: data[n],
            }
          }
        } else {
          this.$refs.additional.info = {
            num: _list.num,
            digitSelect: _list.digitSelect || 0,
            info: _list.informationDTOS[n],
          }
        }

        this.$refs.additional.modelVisible = true
      },

      /**
       * 调整Net Price
       *  @params _arr 0成本价 1利润率 2关税率 3运费单价 4印刷单价 5试算Net Price 6汇率 7规格加价
       */
      adjustmentNetPrice(_arr, i, n) {
        if (this.form.list[i].standardCustomized != '标准品') {
          this.$set(this.form.list[i].informationDTOS[n], 'costPrice', _arr[0])
          this.$set(
            this.form.list[i].informationDTOS[n],
            'totalTransportPrice',
            _arr[3]
          )
          this.$set(
            this.form.list[i].informationDTOS[n],
            'printingPrice',
            _arr[4]
          )
          this.$set(this.form.list[i].informationDTOS[n], 'netPrice', _arr[5])
          this.$set(this.form.list[i].informationDTOS[n], 'exchange', _arr[6])
          this.$set(this.form.list[i].informationDTOS[n], 'specsPrice', _arr[7])
        } else {
          this.$set(
            this.form.list[i].informationDTOS[n],
            'calcNetPrice',
            _arr[5]
          )
        }
        this.$set(this.form.list[i].informationDTOS[n], 'profitRate', _arr[1])
        this.$set(this.form.list[i].informationDTOS[n], 'taxRate', _arr[2])
        this.calculateTotal(this.form.list[i].informationDTOS[n], i, n)
      },

      // 试算NetPrice
      calcTrialNetPrice(item, type) {
        if (item.costPrice && item.profitRate) {
          // 标准品取最新汇率，订制品取产品汇率
          const exchange = type === 0 ? this.exchange : item.exchange
          const taxRate = Number(
            (
              (item.costPrice * item.taxRate) /
              exchange /
              item.profitRate /
              2
            ).toFixed(3)
          )
          const printingPrice =
            type === 0 ? item.basicPrintingPrice : item.printingPrice

          return (
            (item.costPrice / exchange + item.transportPrice) /
              item.profitRate +
            taxRate +
            printingPrice
          ).toFixed(3)
        } else {
          return 0
        }
      },

      // 添加自定义费用
      addCustomFees(i) {
        let fee = this.form.list[i].customFees

        if (fee) {
          let informationDTOS = this.form.list[i].informationDTOS
          let fees = []

          if (informationDTOS[0]?.expenseNameList?.length > 1) {
            this.$message.warning(this.$t('reqmsg.M2067'))
            return false
          }

          if (informationDTOS[0]?.expenseNameList) {
            informationDTOS[0].expenseNameList.forEach((item) =>
              fees.push(item.label)
            )
          }
          if (!fees.includes(fee)) {
            for (let n = 0; n < informationDTOS.length; n++) {
              this.$set(
                informationDTOS[n],
                'expenseNameList',
                informationDTOS[n].expenseNameList.concat([
                  {
                    label: fee,
                    amount: '',
                  },
                ])
              )
            }
            this.form.list[i].customFees = ''
          }
        }
      },

      // 删除自定义费用
      customFeesDel(i, m) {
        for (let n = 0; n < this.form.list[i].informationDTOS.length; n++) {
          this.form.list[i].informationDTOS[n].expenseNameList.splice(m, 1)
        }
      },

      // 获取自定义费用备注
      getFeesRemark(label) {
        let item = this.customFeesOptions.find((item) => item.label == label)
        if (item) return item.remark
      },

      // 是否显示additionalimprint
      isShowAdditional(i) {
        const _list = this.form.list[i]

        const boo = _list.informationDTOS.every(
          (item) => !item.additionalimprint
        )
        // 1.印刷方式选择为Blank或Sample 2.计算的Additional Imprint均为0
        if (boo || ['Blank', 'Sample'].includes(_list.printingWayId)) {
          return false
        } else {
          return true
        }
      },

      // 规格加价不显示价格为0的规格
      specsProcess(_arr) {
        return _arr.filter((item) => item.specsPrice > 0)
      },

      // 管理产品
      manageProduct() {
        this.$refs.offerProduct.tableData = JSON.parse(
          JSON.stringify(this.form.list)
        )
        this.$refs.offerProduct.modelVisible = true
      },

      // 报价管理产品
      offerProductSave(table) {
        if (this.isEdit) {
          this.form.list = table.map((item) => {
            return {
              ...item,
              priceSheetCode: this.priceSheetCode,
            }
          })
        } else {
          this.form.list = table
        }
      },

      // 订制品选择图片
      choseRendingImages(i) {
        this.imageIndex = i
        this.imagesModelVisible = true
      },

      // 订制品上传图片
      setRendingImages(img) {
        this.form.list[this.imageIndex].productUrl = img
      },

      // 手动填写切换事件
      manualTranslationChange(i) {
        let _list = this.form.list[i]
        _list.printingWay = ''
        _list.printingWayEn = ''
        _list.printingWayId = ''
        _list.waySelect = ''
        _list.digitSelect = ''
        _list.printingPosition = ''
        this.calcPrice(i)
      },

      // 获取隐藏的PDF弹框信息
      generatePdf(id, flag) {
        this.isShowPrint = true
        this.$refs.InquiryPrint.getPreviewPDF(id, flag)
      },

      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
      },

      // html 转成 PDF
      domToPdf(customerEmail, toQuationList) {
        const self = this
        let title = `quotation-${getNowDate()}`

        this.getPdfMultiplePage(title, 'inquiryprint', false, P_LENGTH).then(
          (res) => {
            //res拿到base64的pdf
            let pdfBase64Str = res
            let title = `quotation-${getNowDate()}`
            var myfile = this.dataURLtoFile(pdfBase64Str, title + '.pdf') //调用一下下面的转文件流函数
            var formdata = new FormData()
            formdata.append('file', myfile) // 文件对象
            formdata.append('flagSaveName', 1)
            formdata.append('source', 1)
            formdata.append('uploadType', 1)
            //该uploadMy为接口，直接以formdata格式传给后台

            uploadPdfFile(formdata)
              .then((res) => {
                let file = {
                  enclosureName: res.data.fileName,
                  fileId: res.data.fileId,
                  enclosureSize: res.data.size,
                  enclosureUrl: res.data.path,
                  suffix: res.data.suffix,
                  uploadType: 1,
                  mailEnclosureId: res.data.fileId,
                  source: 1,
                }
                let url = ''
                if (this.mailDetail) {
                  // 从邮件直接生成报价单
                  url = `${
                    window.location.origin
                  }/#/write?from=business&mailId=${
                    this.mailDetail.baseInfo.mailId
                  }&file=${JSON.stringify([file])}&email=${
                    this.mailDetail.baseInfo.sendEmail
                  }`
                } else {
                  url = `${
                    window.location.origin
                  }/#/write?from=business&file=${JSON.stringify(
                    file
                  )}&email=${customerEmail}`
                }
                this.utils.openWindow(url)
                if (toQuationList) {
                  this.$router.push({
                    path: '/order/quotations-list',
                  })
                }
              })
              .catch((err) => {})
              .finally(() => {
                this.isShowPrint = false
              })
          }
        )
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style scoped lang="scss">
  .container {
    .title {
      > div {
        width: 50%;
        em {
          font-size: 14px;
          border: 2px solid #333;
          border-radius: 50%;
          height: 22px;
          width: 22px;
          line-height: 18px;
          text-align: center;
          display: inline-block;
          margin-right: 5px;
        }
        span {
          display: inline-block;
          margin-right: 10px;
          line-height: 22px;
        }
        .event {
          color: #409eff;
          cursor: pointer;
        }
      }
    }
    .main-wrap {
      margin-top: 10px;
      height: calc(100vh - 220px);
      overflow-y: auto;
      border-radius: 8px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .anchor {
        position: fixed;
        top: 200px;
        right: 28px;
        border-radius: 4px;
        border: 1px solid #d1e9ff;
        z-index: 999;
        background: white;
        ul li {
          list-style: none;
          line-height: 24px;
          color: #409eff;
          border-top: 1px solid #d1e9ff;
          padding: 0 10px;
          cursor: pointer;
          margin-top: -1px;
        }
      }
      .vaild {
        width: 45%;
        padding-left: 10px;
        margin-top: 30px;
      }
      .main {
        padding: 0 10px;
        position: relative;
        margin-bottom: 50px;
        .l-main {
          width: 100%;
          padding-right: 55%;
          p {
            margin-bottom: 5px;
            text-indent: 20px;
          }
          .img-wrap {
            padding-left: 20px;
            margin-bottom: 10px;
            .img {
              width: 72px;
              height: 72px;
              position: relative;
              img {
                width: 72px;
                height: 72px;
                cursor: pointer;
              }
              span {
                font-size: 12px;
                position: absolute;
                top: 0;
                right: 0;
                background: #e8f4ff;
                border: 1px solid #d1e9ff;
                color: #409eff;
                line-height: 16px;
              }
              .img-icon {
                position: absolute;
                bottom: 0px;
                right: 0px;
                background: #000;
                opacity: 0.7;
                i {
                  font-size: 16px;
                  cursor: pointer;
                  color: #fff;
                  width: 24px;
                  height: 24px;
                  text-align: center;
                  line-height: 24px;
                }
              }
            }
            .text {
              padding-left: 10px;
              width: 100%;
              .more {
                color: #409eff;
                cursor: pointer;
                display: inline-block;
              }
              p {
                text-indent: 0;
              }
            }
          }
          .base-info {
            margin: 10px 0;
            .name {
              padding-right: 10px;
              text-align: right;
            }
            .res {
              width: calc(100% - 160px);
              .el-tag {
                cursor: pointer;
                margin-bottom: 5px;
              }
            }
          }
        }
        .r-main {
          top: 0;
          right: 0;
          width: 48%;
          height: 100%;
          padding: 0 20px 0 20px;
          position: absolute;
          .box {
            display: flex;
            margin-top: 20px;
            .title {
              width: 180px;
              background: #f6f8f9;
              margin-top: 1px;
            }
            .fees-tips {
              position: relative;
              display: flex;
              span {
                max-width: 140px;
              }
              i.del {
                font-size: 16px;
                position: absolute;
                top: 12px;
                left: -40px;
                cursor: pointer;
              }
            }
            .content {
              width: calc(100% - 190px);
              overflow-x: auto;
              display: flex;
              .price-box {
                width: 150px;
                margin: 1px -1px 0;
                .fees {
                  .el-input {
                    width: 130px;
                    left: -10px;
                  }
                }
                .additional-fees {
                  .el-input {
                    width: 110px;
                    left: -10px;
                  }
                }
                i.iconfont {
                  top: -8px;
                }
              }
            }
            ul li {
              text-indent: 10px;
              list-style: none;
              line-height: 40px;
              border: 1px solid #eceff5;
              margin-top: -1px;
              margin-right: -1px;
            }
            .net-price,
            .additional-fees {
              position: relative;
              i {
                font-size: 18px;
                color: #409eff;
                position: absolute;
                top: 3px;
                right: 5px;
                cursor: pointer;
                z-index: 2;
              }
            }
          }
          .el-empty {
            padding: 20px 0;
            width: 100%;
            border: 1px solid #eceff5;
          }
        }
        .line {
          width: 1px;
          height: 100%;
          border-right: 1px solid #dedede;
          position: absolute;
          top: 0;
          left: 50%;
        }
      }
    }
  }
  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    .wrapper-mail {
      display: inline-block;
      margin-left: 10px;
    }
  }
  ::v-deep {
    .el-input-group__prepend,
    .el-input-group__append {
      background: none;
      padding: 0;
    }
    .el-select {
      width: 100%;
    }
    .product-row {
      .el-form-item--small.el-form-item {
        margin-bottom: 5px;
      }
    }
    .product-row {
      .append-select {
        color: rgba(0, 0, 0, 0.65);
        height: 32px;
        border: none;
        background: #f5f7fa;
        cursor: pointer;
      }
    }
    .price-box {
      .append-select {
        color: rgba(0, 0, 0, 0.65);
        height: 32px;
        border: none;
        background: #f5f7fa;
        cursor: pointer;
        max-width: 60px;
      }
      .el-input {
        width: 140px;
      }
      .el-input-group__append {
        left: -10px;
      }
      .el-input__inner {
        padding: 0 0 0 10px;
      }
      .el-form-item--small.el-form-item {
        margin: 0;
      }
      .el-form-item__content {
        margin: 3px 0 3px 10px !important;
      }
    }
  }
</style>
