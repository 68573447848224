<template>
  <div class="content">
    <!-- 投诉数据 -->
    <h3>{{ $t('auditManage.DataOnComplaints') }}</h3>
    <CusTable
      :pagination="false"
      ref="cusTable"
      :tableData="tableData"
      :columns="columns"
      auditShow
    />
    <!-- 回复记录 -->
    <h3 class="reply">{{ $t('auditManage.RecordOfReply') }}</h3>
    <ReplayRecord ref="replayRecord" :tableData="history" class="record" />
  </div>
</template>

<script>
import { columns } from '@/views/customer-manage/helper/data'
import CusTable from '@/views/customer-manage/customer-center/components/cus-table.vue'
import ReplayRecord from '@/views/customer-manage/customer-center/components/replay-record.vue'
import { CustomerCenterInteractor } from '@/core'
import CustomCache from '@/utils/custom-cache' 
import { mapGetters } from 'vuex' 
export default {
  name: 'CustomerCenter',
  props: {
    detailId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      history: [],
      columns: [],
    }
  },
  components: {
    CusTable,
    ReplayRecord,
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    this.columns = columns
    this.getColumsData()
    CustomerCenterInteractor.getDetail(this.detailId).then((res) => {
      if (res?.code === '000000') {
        this.tableData = [res.data]
        this.history = res.data.customerComplainRecordVOs
      }
    })
  },

  methods: {
    // 获取自定义保存
    getColumsData() {
      CustomCache.getColumns(15, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px;
}
.reply {
  margin-top: 20px;
}
</style>
