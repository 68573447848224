<template>
  <div class="latest-aside">

    <div class="search-container" id="search-container">
      <shrink-bar ref="defaultContact" @getList="getListByType(defaultConfig)">
        <template v-slot:header>
          <div class="search-item-header">
            <div style="display: flex; flex-wrap: nowrap">
              <i class="el-icon-arrow-down mr-10"></i>
              <!-- <span>最近联系人({{ defaultConfig.num }})</span> -->
              <span style="display: inline-block; white-space: nowrap">
                {{ $t('other.recentcontacts') }} ({{ defaultConfig.num }})
              </span>
            </div>
            <!-- label="全选" -->
            <el-checkbox v-show="$route.path == '/write'" @change="
                (val) => {
                  handleCheckAllChange(val, defaultConfig)
                }
              " name="last" :isIndeterminate="defaultConfig.isIndeterminate" v-model="defaultConfig.isCheckAll"
              :label="$t('other.selectall')" @click.stop.native></el-checkbox>
          </div>
        </template>
        <template v-slot:main>
          <el-checkbox-group v-model="checkedContacts" @change="
              (val) => {
                handleCheckedContactsChange(val, defaultConfig)
              }
            ">
            <div :class="
                currentContactMail == v.contactEmail
                  ? 'search-item active'
                  : 'search-item'
              " v-for="v in defaultFilterList" :key="v.contactId" @click="getDetail(v)">
              <p class="wrapper-p">
                <el-checkbox v-if="!search" :label="v.contactId" @click.stop.native>
                  {{ '' }}
                </el-checkbox>
                <span v-if="!v.inputFlag">
                  {{ v.noteName ? v.noteName : v.contactEmail }}
                </span>
                <el-input type="text" size="mini" v-else v-model="v.noteName" @blur="updateNodeName(v)"></el-input>
              </p>

            </div>
          </el-checkbox-group>
        </template>
      </shrink-bar>
      <shrink-bar v-for="item in typeList" :key="item.contactCatagoryId" @getList="getListByType(item)">
        <template v-slot:header>
          <book-edit :config="item" :search="search" @handleWrite="handleWrite" @initTypeData="initTypeData"
            v-if="$route.path == '/mail/my-mail/book'"></book-edit>
          <div class="search-item-header" v-if="$route.path == '/write'">
            <div>
              <i class="el-icon-arrow-down mr-10"></i>
              <span>{{ item.name }}({{ item.num }})</span>
            </div>
            <el-checkbox v-if="$route.path == '/write' && item.filterList" @change="
                (val) => {
                  handleCheckAllChange(val, item)
                }
              " :name="item.name" v-model="item.isCheckAll" :label="$t('other.selectall')"
              @click.stop.native></el-checkbox>
          </div>
        </template>
        <template v-slot:main>
          <el-checkbox-group v-model="checkedContacts" @change="
              (val) => {
                handleCheckedContactsChange(val, item)
              }
            ">
            <div :class="
                currentContactMail == v.contactEmail
                  ? 'search-item active'
                  : 'search-item'
              " v-for="v in item.filterList" :key="v.contactId" @click="getDetail(v)">
              <el-checkbox v-if="!search" :label="v.contactId" @click.stop.native>
                {{ '' }}
              </el-checkbox>
              <span>{{ v.noteName ? v.noteName : v.contactEmail }}</span>
            </div>
          </el-checkbox-group>
        </template>
      </shrink-bar>
      <div class="contact-add" v-if="addFlag">
        <el-input ref="addTypeInput" v-model="addTypeValue" @blur="addTypeBlur" maxlength="20"
          show-word-limit></el-input>
        <i class="el-icon-success" @mousedown="handleAddSave"></i>
        <i class="el-icon-error" @mousedown="handleAddRemove"></i>
      </div>

    </div>
  </div>
</template>

<script>
  import {
    contactCatagoryList,
    getContactsByCatagoryId,
    addContact,
    listContact,
    addContactsCatagory,
    moveContact,
    getDetailForEmail,
    updateContact,
  } from '@/api/mail/contacts.js'
  import ShrinkBar from './ShrinkBar.vue'
  import BookEdit from './BookEdit.vue'
  import Bus, { BusOn, BusEmit } from '@/utils/eventBus'
  export default {
    name: 'LatestContacts',
    components: {
      ShrinkBar,
      BookEdit,
    },
    mixins: [BusOn],
    data() {
      return {
        addFlag: false,
        addTypeValue: '',
        searchFlag: false,
        checkedContacts: [],
        checkAll: false,
        isIndeterminate: false,
        contactOptions: [],
        typeList: [], //分类列表
        defaultConfig: {}, //默认分类config
        blackConfig: {}, //黑名单config
        defaultList: [], //默认列表
        defaultFilterList: [],
        blackList: [], // 白名单列表
        blackFilterList: [],
        contactKeywords: '',
        resolveFlag: false,
        currentContactMail: '', // 当前联系人id
      }
    },
    props: {
      search: {
        type: Boolean,
        default: false,
      },
      checkedData: {
        type: Array,
        default() {
          return []
        },
      },
      editNoteName: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      '$store.state.mail.currentAccount': {
        handler(n) {
          if (n) {
            this.getTypeList(() => {
              this.$refs.defaultContact.showExpend()
            })
          }
        },
        immediate: true,
      },
      checkedData: {
        handler(val) {
          if (val && Array.isArray(val)) {
            let list = this.defaultList
              .filter((item) => {
                return val.includes(item.contactEmail)
              })
              .map((item) => {
                return item.contactId
              })
            this.checkedContacts = [...list]
          }
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      // 更新备注
      updateNodeName(v) {
        const {
          contactCatagoryId,
          contactCatagoryName,
          contactEmail,
          emailAccount,
          flag,
          modifiable,
          noteName,
          phone,
          source,
        } = v
        v.inputFlag = false
        addContact({
          contactCatagoryId,
          contactCatagoryName,
          contactEmail,
          emailAccount,
          flag,
          modifiable,
          noteName,
          phone,
          source,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('操作成功')
          } else {
            this.$refs.defaultContact.$emit('getList')
          }
        })
      },
      //获取详情
      getDetail(v) {
        if (this.$route.path == '/write') {
          this.$emit('addContacts', v)
        } else {
          if (this.currentContactMail === v.contactEmail) {
            //当前联系人点击时不刷新
            return
          }
          this.currentContactMail = v.contactEmail
          getDetailForEmail({ email: v.contactEmail }).then((res) => {
            if (res.code === '000000') {
              this.$emit('getContactDetail', v, res.data)
              if (res.data) {
                let detail = res.data || null
                let linkmans =
                  res.data.linkmans && res.data.linkmans.length
                    ? res.data.linkmans.find(
                      (item) => item.email === v.contactEmail
                    )
                    : null
                if (
                  v.noteName &&
                  linkmans &&
                  linkmans.linkman &&
                  v.noteName !== linkmans.linkman
                ) {
                  updateContact({
                    contactId: v.contactId,
                    noteName: linkmans.linkman,
                  }).then((res) => {
                    if (res.code === '000000') {
                      v.noteName = linkmans.linkman
                    }
                  })
                }
              }
            }
          })
        }
      },

      setCurrent(mail) {
        this.currentContactMail = mail
      },

      //联系人全选功能
      handleCheckAllChange(val, obj) {
        // this.checkedContacts = val ? this.contactOptions : []
        // obj.isIndeterminate = false
        if (!val) {
          this.checkedContacts = []
          BusEmit('WriteMail', 'addContacts', [])
        } else {
          let list = []
          if (obj.contactCatagoryId) {
            list = obj.filterList
            this.typeList.forEach((item) => {
              if (item.contactCatagoryId != obj.contactCatagoryId) {
                item.isCheckAll = false
              }
            })
          } else {
            list = this.defaultFilterList
          }
          this.checkedContacts = list.map((item) => {
            return item.contactId
          })
          let mailList = list.map((item) => {
            return item.contactEmail
          })
          BusEmit('WriteMail', 'addContacts', mailList)
        }
        this.$nextTick(() => {
          this.$set(obj, 'isCheckAll', val)
        })
      },
      // 选择联系人change事件
      handleCheckedContactsChange(value, obj) {
        // 如果child全部被选中，则显示全选
        if (obj.contactCatagoryId) {
          if (
            obj.filterList.every((item) => {
              return value.includes(item.contactId)
            })
          ) {
            obj.isCheckAll = true
          } else {
            obj.isCheckAll = false
          }
        }
        if (this.$route.path == '/write') {
          // 如果是写邮件页面，则传参联系人邮箱列表
          let list = this.defaultFilterList
            .filter((item) => {
              return this.checkedContacts.includes(item.contactId)
            })
            .map((item) => {
              return item.contactEmail
            })
          if (list.length) {
            BusEmit('WriteMail', 'addContacts', list)
          } else {
            BusEmit('WriteMail', 'addContacts', [])
          }
        }
      },
      // handleSend() {
      //   if (this.checkedContacts.length) {
      //     let contactEmails = []
      //     this.checkedContacts.map((ele) => {
      //       let arr = this.defaultList.filter((item) => item.contactId == ele)
      //       if (arr.length > 0) {
      //         contactEmails.push(arr[0].contactEmail)
      //       }
      //     })
      //     this.$router.push({
      //       path: '/mail/my-mail/write',
      //       query: {
      //         from: 'book',
      //         receive: contactEmails.join(','),
      //       },
      //     })
      //   } else {
      //     this.$message({
      //       type: 'warning',
      //       message: '请选择联系人',
      //     })
      //   }
      // },
      // 移动分组
      moveContact(item) {
        if (this.checkedContacts.length) {
          let data = {
            contactIds: this.checkedContacts.join(','),
          }
          if (item.type == 99) {
            data.type = 1
          } else {
            data.contactCatagoryId = item.contactCatagoryId
          }
          moveContact(data).then((res) => {
            if (res.code == '000000') {
              this.$message({
                type: 'success',
                // message: '操作成功！',
                message: this.$t('reqmsg.$7'),
              })
              this.getTypeList(() => {
                let arr = [...this.typeList, this.defaultConfig, this.blackConfig]
                arr.forEach((atem) => {
                  this.getListByType(atem, '', 1)
                })
                this.checkedContacts = []
              })
              // contactCatagoryList({
              //   emailAccount: this.$store.state.mail.currentAccount,
              // }).then((res) => {
              //   let data = res.data
              //   this.typeList = data.filter(
              //     (item) => item.type != 0 && item.type != 99
              //   )
              //   this.moveOptions = data
              //     .filter((item) => item.type != 0)
              //     .sort((a, b) => a.type - b.type)
              //   this.$store.commit('mail/setContactGroupList', this.moveOptions)
              //   this.defaultConfig = data.find((item) => item.type == 0)
              //   this.blackConfig = data.find((item) => item.type == 99)

              //   let arr = [...this.typeList, this.defaultConfig, this.blackConfig]
              //   arr.forEach((atem) => {
              //     this.getListByType(atem, '', 1)
              //   })
              //   this.checkedContacts = []
              // })
            }
          })
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择联系人',
            message: this.$t('reqmsg.$34'),
          })
        }
      },
      //搜索筛选方法
      searchContact(name) {
        // if (name) {
        //   this.contactKeywords = name
        // }
        this.contactKeywords = name
        //默认列表
        this.defaultFilterList = this.defaultList.filter(
          (item) =>
            item.noteName.indexOf(this.contactKeywords) > -1 ||
            item.contactEmail.indexOf(this.contactKeywords) > -1
        )
        // 黑名单
        this.blackFilterList = this.blackList.filter(
          (item) =>
            item.noteName.indexOf(this.contactKeywords) > -1 ||
            item.contactEmail.indexOf(this.contactKeywords) > -1
        )

        // 其他分类列表
        this.typeList.map((ele) => {
          ele = {
            ...ele,
            filterList:
              ele.contactsList && ele.contactsList.length
                ? ele.contactsList.filter(
                  (item) =>
                    item.noteName.indexOf(this.contactKeywords) > -1 ||
                    item.contactEmail.indexOf(this.contactKeywords) > -1
                )
                : [],
          }
        })
        this.searchFlag = false
      },
      // 新建分组
      addContactGroup() {
        this.addFlag = true
        this.$nextTick(function () {
          this.$nextTick(() => {
            let container = this.$el.querySelector('#search-container')
            container.scrollTop = container.scrollHeight
          })
          this.$refs.addTypeInput.focus()
        })
      },

      addTypeBlur() {
        if (!this.resolveFlag) {
          // this.$confirm('此操作将放弃添加分组, 是否继续?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning',
          // })
          this.$confirm(this.$t('reqmsg.$35'), this.$t('reqmsg.M2005'), {
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            type: 'warning',
          })
            .then(() => {
              this.addTypeValue = ''
              this.addFlag = false
            })
            .catch(() => {
              this.$refs.addTypeInput.focus()
            })
        }
      },

      // 添加分类
      handleAddSave() {
        this.resolveFlag = true
        if (this.addTypeValue) {
          if (this.addTypeValue.length > 20) {
            return this.$message({
              type: 'warning',
              message: this.$t('reqmsg.$31'), //'分组名称限制20个字符',
            })
          }
          addContactsCatagory({
            name: this.addTypeValue,
            emailAccount: this.$store.state.mail.currentAccount,
          }).then((res) => {
            if (res.code === '000000') {
              this.resolveFlag = false
              this.addTypeValue = ''
              this.getTypeList((data) => {
                data.map((ele) => {
                  if (
                    ele.type != 0 &&
                    ele.type != 99 &&
                    this.typeList.filter(
                      (item) => item.contactCatagoryId == ele.contactCatagoryId
                    ).length == 0
                  ) {
                    this.typeList.push(ele)
                  }
                })
                this.$message({
                  type: 'success',
                  // message: '添加分组成功！',
                  message: this.$t('reqmsg.$36'),
                })
                this.addFlag = false
              })

            }
          })
        } else {
          this.$message({
            type: 'warning',
            // message: '请填写分组名称！',
            message: this.$t('reqmsg.$37'),
          })
        }
      },

      initTypeData() {
        this.getTypeList((data) => {
          data.map((ele) => {
            if (
              ele.type != 0 &&
              ele.type != 99 &&
              this.typeList.filter(
                (item) => item.contactCatagoryId == ele.contactCatagoryId
              ).length == 0
            ) {
              this.typeList.push(ele)
            }
          })
        })

      },

      // 撤销分类
      handleAddRemove() {
        this.resolveFlag = true
        this.addTypeValue = ''
        this.addFlag = false
        this.$nextTick(function () {
          this.resolveFlag = false
        })
      },

      updateLatest() {
        this.getListByType(this.defaultConfig)
      },
      // 根据分组id获取联系人列表
      getListByType(item, cb, flag) {
        if (item.num > 0 || flag === 1) {
          let data = {
            emailAccount: this.$store.state.mail.currentAccount,
            type: item.type == 99 ? 1 : 0,
          }
          if (item && item.contactCatagoryId) {
            data.contactCatagoryId = item.contactCatagoryId
          }
          listContact(data).then((res) => {
            // 给返回的参数添加inputFlag,点击编辑时，span切换为input
            if (Array.isArray(res.data)) {
              res.data = res.data.map((item) => {
                item.inputFlag = false
                item.modifiable = 0
                return item
              })
            }
            if (item.type == 0) {
              this.defaultList = res.data || []
              this.defaultFilterList = this.defaultList.length
                ? this.defaultList.filter(
                  (item) =>
                    item.noteName.indexOf(this.contactKeywords) > -1 ||
                    item.contactEmail.indexOf(this.contactKeywords) > -1
                )
                : []
              this.$store.commit('mail/setLatestContacts', this.defaultList)
            } else if (item.type == 99) {
              this.blackList = res.data || []
              this.blackFilterList = this.blackList
                ? this.blackList.filter(
                  (item) =>
                    item.noteName.indexOf(this.contactKeywords) > -1 ||
                    item.contactEmail.indexOf(this.contactKeywords) > -1
                )
                : []
            } else {
              this.typeList.map((ele, index) => {
                if (ele.contactCatagoryId === item.contactCatagoryId) {
                  let obj = {
                    ...ele,
                    contactsList: res.data || [],
                    filterList:
                      res.data && res.data.length
                        ? res.data.filter(
                          (item) =>
                            item.noteName.indexOf(this.contactKeywords) > -1 ||
                            item.contactEmail.indexOf(this.contactKeywords) > -1
                        )
                        : [],
                  }
                  this.typeList.splice(index, 1, obj)
                  if (cb) {
                    cb(obj.filterList)
                  }
                }
              })
            }
          })
        } else {
          if (item.type == 0) {
            this.defaultList = []
            this.defaultFilterList = []
            this.$store.commit('mail/setLatestContacts', this.defaultList)
          } else if (item.type == 99) {
            this.blackList = []
            this.blackFilterList = []
          } else {
            this.typeList.map((ele, index) => {
              if (ele.contactCatagoryId === item.contactCatagoryId) {
                let obj = {
                  ...ele,
                  contactsList: [],
                  filterList: [],
                }
                this.typeList.splice(index, 1, obj)
                if (cb) {
                  cb(obj.filterList)
                }
              }
            })
          }
        }
      },
      // 获取联系人分组
      getTypeList(callback) {
        contactCatagoryList({
          emailAccount: this.$store.state.mail.currentAccount,
        }).then((res) => {
          let data = res.data || []
          this.typeList = data.filter((item) => {
            item['isCheckAll'] = false
            return item.type != 0 && item.type != 99
          })
          let moveList = data
            .filter((item) => item.type != 0)
            .sort((a, b) => a.type - b.type)
          this.$store.commit('mail/setContactGroupList', moveList)
          this.defaultConfig = data.find((item) => {
            item['isCheckAll'] = false
            return item.type == 0
          })
          this.blackConfig = data.find((item) => item.type == 99)
          if (callback && typeof callback == 'function') {
            callback(data)
          }
        })
      },

      // 点击添加组内发件人
      handleWrite(data) {
        let self = this
        if (data.filterList !== undefined && data.filterList.length) {
          if (!self.search) {
            let contactEmails = data.filterList.map((item) => item.contactEmail)
            let url = `${window.location.origin
              }/#/write?from=book&receive=${contactEmails.join(',')}`
            self.utils.openWindow(url)
          } else {
            data.filterList.forEach((ele) => {
              self.$emit('addContacts', ele)
            })
          }
        } else {
          self.getListByType(data, function (arr) {
            if (!self.search) {
              let contactEmails = arr.map((item) => item.contactEmail)

              let url = `${window.location.origin
                }/#/write?from=book&receive=${contactEmails.join(',')}`
              self.utils.openWindow(url)
            } else {
              arr.forEach((ele) => {
                self.$emit('addContacts', ele)
              })
            }
          })
        }
      },
    },
    created() {
      if (this.$route.query.account) {
        this.$store.commit('mail/setCurrentAccount', this.$route.query.account)
      }
      this.$root.$on('updateNoteName', function (v, name) { })
    },
    mounted() { },
  }
</script>

<style lang="scss" scoped>
  .mail-drop {
    max-height: 300px;
    overflow-y: auto;
  }

  .latest-aside {
    height: 100%;
    background: #fff;

    .search-bar {
      width: 100%;
      padding: 12px 20px;
      background: #fff;
      height: 58px;
      box-sizing: border-box;

      .ml-5 {
        margin-left: 5px;
      }

      &>div:first-child {
        display: flex;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #303030;

        &>div {
          margin-right: 20px;
          line-height: 30px;
          cursor: pointer;
          display: flex;

          span {
            display: inline-block;
            margin-left: 5px;
            line-height: 30px;
          }

          .el-dropdown {
            font-size: 14px;

            .icon-yidong {
              font-size: 12px;
            }
          }
        }

        .el-input {
          flex: 1;
        }
      }
    }

    .search-container {
      height: calc(100% - 58px);
      overflow-y: auto;

      .search-item-header {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        background: #f5f7fa;
        font-size: 14px;
        color: #303030;
        line-height: 36px;
        cursor: pointer;

        .edit-btns {
          display: none;
          line-height: 1;

          i {
            margin-left: 10px;
          }
        }

        .icon-zhifeiji {
          display: none;
        }

        &:hover {
          .icon-zhifeiji {
            display: block;
          }

          .edit-btns {
            display: block;
          }
        }

        .mr-10 {
          margin-right: 10px;
        }

        i {
          line-height: 36px;
        }
      }

      .search-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
        background: #fff;
        font-size: 14px;
        color: #303030;
        line-height: 36px;
        position: relative;
        cursor: pointer;

        &::before {
          content: '';
          width: 4px;
          height: 36px;
          position: absolute;
          left: 0;
          top: 0;
          background: #0486fe;
          opacity: 0;
        }

        .wrapper-p {
          display: flex;

          .el-input,
          span {
            flex: 1;
          }
        }

        &>span {
          margin-left: 15px;
        }

        &.active {
          background: #f6faff;

          &::before {
            opacity: 1;
            transition: 0.3s 0.1s;
          }

          transition: 0.3s 0.1s;
        }

        i {
          display: none;
          color: #aaaaaa;
        }

        // .el-input {
        //   width: 120px;
        // }
        &:hover {
          i {
            display: block;

            &:hover {
              cursor: pointer;
              color: #409eff;
            }
          }

          background: #f6faff;

          &::before {
            opacity: 1;
            transition: 0.3s 0.1s;
          }

          transition: 0.3s 0.1s;
        }
      }

      .contact-add {
        display: flex;
        padding: 10px 20px;
        background: #fff;
        font-size: 14px;

        i {
          line-height: 30px;
          margin-left: 10px;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }
</style>