<template>
  <div class="platform-product-putaway">
    <el-page-header @back="handleBack" content="上架平台产品" />
    <div class="container">
      <PlatformProductDetails type="1" :detailInfo="detailInfo" />
    </div>
    <div class="btns">
      <el-button @click="handleBack">取消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="loading">
        确定
      </el-button>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="tip1">
        <i class="el-icon-warning" />
        当前产品没有维护 SAGE 平台信息
      </div>
      <div class="tip2">请先维护产品 SAGE 平台的信息</div>
      <span slot="footer">
        <el-button type="primary" @click="handleClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import PlatformProductDetails from '../components/PlatformProductDetails'
  import { PlatformProductListInteractor } from '@/core'
  export default {
    components: { PlatformProductDetails },
    data() {
      return { detailInfo: {}, dialogVisible: false, loading: false }
    },
    created() {
      this.getInfo()
    },
    methods: {
      // 返回
      handleBack() {
        this.$router.back()
      },
      // 确定
      async handleSubmit() {
        const { erpProductId, platformCode = 'SAGE' } = this.$route.query
        const params = {
          id: this.detailInfo.baseVO.id,
          erpProductId,
          platformCodes: [platformCode],
        }
        try {
          this.loading = true
          const { code } = await PlatformProductListInteractor.upShelfApi(
            params
          )
          if (code === '000000') {
            this.$message.success('上架成功！')
            this.handleBack()
          }
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      },
      // 获取详情
      async getInfo() {
        try {
          const {
            id,
            platformCode = 'SAGE',
            erpProductCode,
            type = '1',
            platformProductId,
          } = this.$route.query
          const { code, data } =
            await PlatformProductListInteractor.getPlatformProductShowDetailApi(
              {
                id,
                platformCode,
                erpProductCode,
                type,
                platformProductId,
              }
            )
          if (code === '000000') {
            this.detailInfo = data
            const codes = data?.baseVO?.draftPlatformCode?.split(',') || []
            if (!codes.includes('SAGE')) {
              this.dialogVisible = true
            }
          } else {
            this.dialogVisible = true
          }
        } catch (err) {
          console.log(err)
        }
      },
      // 关闭
      handleClose() {
        this.dialogVisible = false
        this.handleBack()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .platform-product-putaway {
    height: calc(100vh - 45px - 2 * 9px);
    padding: 9px;
    display: flex;
    flex-direction: column;
    .container {
      padding: 0 10px 60px;
      overflow: auto;
      flex: 1;
    }
    .btns {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }
    /deep/.el-dialog__body {
      padding: 0 20px;
      .tip1 {
        display: flex;
        align-items: center;
        font-size: 16px;
        > i {
          color: #faad14;
          margin-right: 10px;
          font-size: 30px;
        }
      }
      .tip2 {
        margin-top: 10px;
        padding-left: 40px;
      }
    }
  }
</style>
