<template>
  <el-dialog
    :title="isEdit ? $t('other.inquiryEdit') : $t('inquiryList.Inquiry')"
    width="1200px"
    class="custom-dialog"
    :visible.sync="inquiryDemandVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form ref="ruleForm" :model="form">
      <el-table
        :data="form.inquiryOrderDTOList"
        border
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-class-name="star"
      >
        <el-table-column type="index" label="#" width="55"></el-table-column>
        <!-- 产品名称 -->
        <el-table-column
          :label="$t('quoteDetails.ProductName')"
          prop="productEn"
          width="200"
        ></el-table-column>
        <!-- 产品图片 -->
        <el-table-column :label="$t('quoteDetails.ProductImage')" width="120">
          <template slot-scope="scope">
            <div
              class="wrapper-box"
              @click="
                choseRendingImages(
                  scope.$index,
                  scope.row.productImg,
                  'productImg'
                )
              "
            >
              <el-badge
                v-show="
                  scope.row.productImg &&
                  scope.row.productImg.split(',').length > 1
                "
                :value="
                  scope.row.productImg
                    ? scope.row.productImg.split(',').length
                    : ''
                "
              ></el-badge>
              <div class="wrapper-image" v-show="scope.row.productImg">
                <img :src="getFirstImgUrl(scope.row.productImg)" alt />
              </div>
              <div class="wrapper-add" v-show="!scope.row.productImg">
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 需求数量 -->
        <el-table-column
          :label="$t('quoteDetails.RequiredQuantity')"
          prop="amount"
          width="120"
        ></el-table-column>
        <!-- 产品类别 -->
        <el-table-column
          :label="$t('quoteDetails.ProductCategory')"
          width="200"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'inquiryOrderDTOList.' + scope.$index + '.categoryId'"
              :rules="[
                {
                  required: true,
                  message: $t('rules.M2006'),
                  trigger: 'change',
                },
              ]"
            >
              <el-cascader
                :options="categoryOptions"
                v-model="form.inquiryOrderDTOList[scope.$index].categoryId"
                @change="proCategoryChange(scope.$index)"
                :props="optionProps"
                :placeholder="$t('placeholder.plsSel')"
              ></el-cascader>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- 产品描述 -->
        <el-table-column
          :label="$t('quoteDetails.ProductDescription')"
          width="240"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'inquiryOrderDTOList.' + scope.$index + '.productDescribe'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                :rows="3"
                maxlength="500"
                show-word-limit
                v-model="form.inquiryOrderDTOList[scope.$index].productDescribe"
                :placeholder="$t('placeholder.plsInput')"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- 印刷描述 -->
        <el-table-column
          :label="$t('quoteDetails.PrintingDescription')"
          width="240"
        >
          <template slot-scope="scope">
            <el-form-item>
              <el-input
                type="textarea"
                :rows="3"
                maxlength="500"
                show-word-limit
                v-model="
                  form.inquiryOrderDTOList[scope.$index].printingDescribe
                "
                :placeholder="$t('placeholder.plsInput')"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- 印刷参考图 -->
        <el-table-column
          :label="$t('quoteDetails.PrintingReferenceDrawing')"
          width="120"
        >
          <template slot-scope="scope">
            <div
              class="wrapper-box"
              @click="
                choseRendingImages(
                  scope.$index,
                  scope.row.printingImg,
                  'printingImg'
                )
              "
            >
              <el-badge
                v-show="
                  scope.row.printingImg &&
                  scope.row.printingImg.split(',').length > 1
                "
                :value="
                  scope.row.printingImg
                    ? scope.row.printingImg.split(',').length
                    : ''
                "
              ></el-badge>
              <div class="wrapper-image" v-show="scope.row.printingImg">
                <img :src="getFirstImgUrl(scope.row.printingImg)" alt />
              </div>
              <div class="wrapper-add" v-show="!scope.row.printingImg">
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 备注说明 -->
        <el-table-column :label="$t('quoteDetails.Remark')" width="200">
          <template slot-scope="scope">
            <el-form-item>
              <el-input
                type="textarea"
                :rows="3"
                maxlength="500"
                show-word-limit
                v-model="form.inquiryOrderDTOList[scope.$index].remarks"
                :placeholder="$t('placeholder.plsInput')"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          fixed="right"
          :label="$t('inquiryList.Operate')"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              class="icon-r"
              @click="del(scope.$index)"
              size="mini"
              icon="el-icon-delete"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <div class="text-center mt20">
      <!-- 确定 -->
      <el-button @click="save()" type="primary">
        {{ $t('inquiryList.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="close()">
        {{ $t('addInquiry.Cancel') }}
      </el-button>
    </div>

    <!-- 选择图片 -->
    <choose-images
      :visible.sync="chooseImagesDialogVisible"
      :default-file-list="currentDefaultRendingList"
      @confirm="setRendingImages"
    ></choose-images>
  </el-dialog>
</template>

<script>
import ChooseImages from '../components/chooseImages'
import { toInquiry, insertBatch, updateInquiryOrder } from '@/api/order'
import { categoryListForSelect } from '@/api/product/productPrintWay'
import { mapGetters } from 'vuex'
function getCascaderObj(val, opt) {
  if (val && val.length > 0) {
    return val.map(function (value, index, array) {
      for (var itm of opt) {
        if (itm.productCategoryId === value) {
          opt = itm.children
          return itm
        }
      }
      return null
    })
  }
}
export default {
  components: {
    ChooseImages,
  },
  data() {
    return {
      isEdit: false,
      form: {
        inquiryOrderDTOList: [], // 询价列表
      },
      categoryOptions: [], //产品类别
      inquiryDemandVisible: false,
      chooseImagesDialogVisible: false, // 选择图片弹窗
      currentRendingIndex: -1, // 当前选择图片的产品，对应的 index
      currentRendingType: null, // 当前选择图片的产品，对应的 name
      currentDefaultRendingList: [], // 当前选中的图片
      categoryIdArray: [],
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),

    optionProps() {
      return {
        value: 'productCategoryId',
        // label: 'nameCn',
        children: 'children',
        checkStrictly: true,
        label: this.lang === 'en' ? 'nameEn' : 'nameCn',
      }
    },
  },
  methods: {
    // 初始化数据
    initLoading(data) {
      this.getCategoryListForSelect()
      if (typeof data == 'string') {
        toInquiry({ businessId: data }).then((res) => {
          if (res && res.code === '000000') {
            this.form.inquiryOrderDTOList = res.data.map((item) => {
              return {
                ...item,
                productDescribe: this.getProductDescribe(item), // 产品描述
                printingDescribe: '', // 印刷描述
                printingImg: '', // 印刷参考图
                remarks: '', // 备注说明
              }
            })

            this.inquiryDemandVisible = true
          }
        })
        this.isEdit = false
      } else {
        this.isEdit = true
        this.form.inquiryOrderDTOList = [data]
        this.inquiryDemandVisible = true
      }
    },

    // 获取产品类别
    async getCategoryListForSelect() {
      let response = await categoryListForSelect()
      if ((response.code = '000000')) {
        this.categoryOptions = response.data
      }
    },

    // 产品类别选择
    proCategoryChange(i) {
      let list = this.form.inquiryOrderDTOList
      let vals = getCascaderObj(list[i].categoryId, this.categoryOptions)
      let [arr, arrId] = [[], []]
      if (vals) {
        vals.forEach((item) => {
          arr.push(item.nameCn)
          arrId.push(item.productCategoryId)
        })
        // list[i].categoryId = arrId.slice(-1).join(',')

        list[i].category = arr.join('/')
        list[i].categoryId_new = arrId.slice(-1).join(',')
      }
    },

    // 获取产品描述
    getProductDescribe(item) {
      return `Material:${item.specification}\nDimensions:${item.demand}`
    },

    // 删除一行
    del(i) {
      if (this.form.inquiryOrderDTOList.length > 1) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'))
          .then(() => {
            this.form.inquiryOrderDTOList.splice(i, 1)
            this.$message.success(this.$t('reqmsg.M2008'))
          })
          .catch(() => {})
      } else {
        this.$message.warning(this.$t('other.keepData'))
      }
    },

    // 确定
    save() {
      let self = this
      self.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const list = self.form.inquiryOrderDTOList.map((item, i) => {
            return {
              ...item,
              categoryId: item.categoryId_new || item.categoryId,
            }
          })

          if (list.length < 1) {
            self.$message.warning(this.$t('other.keepData'))
            return false
          }
          // 校验产品图片是否必填
          if (list.some((item) => !item.productImg)) {
            self.$message.warning(this.$t('reqmsg.G008'))
            return false
          }
          if (self.isEdit) {
            updateInquiryOrder(list[0]).then((res) => {
              if (res && res.code == '000000') {
                self.$message.success(this.$t('reqmsg.$9'))
                self.$parent.getList()
                self.close()
              }
            })
          } else {
            insertBatch(list).then((res) => {
              if (res && res.code == '000000') {
                self.$message.success(this.$t('reqmsg.$9'))
                self.$parent.getList()
                self.close()
              }
            })
          }
        }
      })
    },

    // table添加必填样式
    star({ row, rowIndex, column, columnIndex }) {
      if (columnIndex == 2 || columnIndex == 4 || columnIndex == 5) {
        return 'star'
      }
    },

    // 获取第一张图片
    getFirstImgUrl(urls) {
      return urls ? urls.split(',')[0] : ''
    },

    // 显示 选择图片 弹窗
    choseRendingImages(index, urls, type) {
      this.chooseImagesDialogVisible = true
      this.currentRendingIndex = index
      this.currentRendingType = type
      this.currentDefaultRendingList = []
      if (urls) {
        let _urls = urls.split(',')
        for (let i = 0; i < _urls.length; i++) {
          this.currentDefaultRendingList.push({
            url: _urls[i],
            name: '',
          })
        }
      }
    },

    // 设置对应产品的 图片
    setRendingImages(url) {
      if (this.currentRendingType == 'productImg') {
        this.form.inquiryOrderDTOList[this.currentRendingIndex].productImg = url
      } else {
        this.form.inquiryOrderDTOList[this.currentRendingIndex].printingImg =
          url
      }
    },

    // 关闭弹窗重置表单
    close() {
      this.$refs.ruleForm.resetFields()
      this.inquiryDemandVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  .wrapper-box {
    text-align: center;
    position: relative;
    .wrapper-add {
      display: inline-block;
      width: 64px;
      height: 64px;
      line-height: 64px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 4px;
      cursor: pointer;
      i {
        font-size: 18px;
      }
    }
    .wrapper-image {
      img {
        width: 64px;
        height: 64px;
        margin-top: 8px;
        border-radius: 2px;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .el-badge {
      position: absolute;
      top: 4px;
      right: 8px;
    }
  }
}
::v-deep {
  .el-table td {
    padding: 0;
  }
  .el-form-item {
    margin: 0;
    padding: 14px 0;
  }
  .el-table table th.star div:before {
    content: '*';
    color: red;
  }
}
</style>
