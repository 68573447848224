<template>
  <el-dialog
    title="收款单详情"
    :visible.sync="dialogReceiptVisible"
    width="1200px"
    @close="close"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="content">
      <!-- floor1 -->
      <el-row type="flex" :gutter="80">
        <el-col>
          <label>收款单号</label>
          <p>
            {{ receiptData.financeCode }}
            <el-tag class="ml5" :type="status | statusFilter">
              {{ status | statusTextFilter }}
            </el-tag>
          </p>
        </el-col>
        <el-col>
          <label>收款来源</label>
          <p>{{ receiptData.financeType | financeTypeFilter }}</p>
        </el-col>
        <el-col>
          <label>付款方</label>
          <p>{{ receiptData.payee || '--' }}</p>
        </el-col>
        <!-- 销售订单-已完成（订单抵扣）（银行流水号） -->
        <el-col v-show="status === 4">
          <label>银行流水号</label>
          <p>{{ receiptData.serialNumber || '--' }}</p>
        </el-col>
        <!-- 销售订单-已作废（创建人，创建时间） -->
        <el-col v-show="status === 5">
          <label>创建人</label>
          <p>{{ receiptData.creatorName || '--' }}</p>
        </el-col>
        <el-col v-show="status === 5">
          <label>创建时间</label>
          <p>{{ receiptData.createTime || '--' }}</p>
        </el-col>
      </el-row>

      <!-- floor2 收款table-->
      <el-table border class="w100 mt30" :data="receiptTableData">
        <el-table-column
          label="收款方式"
          align="center"
          prop="financeMethod"
        ></el-table-column>
        <el-table-column
          label="出口抬头"
          align="center"
          prop="exportsRise"
        ></el-table-column>
        <el-table-column
          label="票据单号"
          align="center"
          prop="ticketNumber"
        ></el-table-column>
        <el-table-column
          label="币种"
          align="center"
          prop="currency"
        ></el-table-column>
        <el-table-column label="收款金额" align="center" prop="amountCollected">
          <template slot-scope="scope">
            <span class="green-bold">{{ scope.row.amountCollected }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="收款日期"
          align="center"
          prop="amountTime"
        ></el-table-column>
      </el-table>

      <!-- floor2 订单table（待确认，待处理-无需认领，已完成）-->

      <el-table
        border
        class="w100 mt10"
        :data="orderTableData"
        v-show="
          status === 7 ||
          (status === 1 && receiptData.salesConfirmTime) ||
          (status === 4 && receiptData.salesConfirmTime)
        "
      >
        <el-table-column
          type="index"
          align="center"
          label="#"
        ></el-table-column>
        <el-table-column
          label="订单编号"
          align="center"
          prop="orderCode"
          width="130"
        >
          <template slot-scope="scope">
            <span class="link" @click="orderCodeClick(scope.row.orderCode)">
              {{ scope.row.orderCode }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="客户姓名"
          align="center"
          prop="customerName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="公司名称"
          align="center"
          prop="companyName"
          show-overflow-tooltip
        ></el-table-column>
        <!-- 待确认，待处理-无需认领（客户代表） -->
        <el-table-column
          label="客户代表"
          align="center"
          prop="businessName"
          show-overflow-tooltip
          v-show="status === 7 || status === 1"
        ></el-table-column>
        <el-table-column
          label="所属地区"
          align="center"
          prop="area"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="币种"
          align="center"
          prop="currency"
        ></el-table-column>
        <el-table-column
          label="订单金额"
          align="center"
          prop="totlePrice"
        ></el-table-column>
        <el-table-column label="实收金额" align="center" prop="">
          <template slot-scope="scope">
            {{ toFixed3(scope.row.amountCollected || '--') }}
          </template>
        </el-table-column>
        <el-table-column
          label="手续费金额"
          align="center"
          prop="commissionAmount"
        ></el-table-column>
        <el-table-column
          label="应收金额"
          align="center"
          prop="amountReceivable"
        ></el-table-column>
      </el-table>

      <!-- floor3 备注等其他 -->
      <el-row :gutter="20" type="flex" class="mt30">
        <el-col :span="8">
          <label>备注</label>
          <tooltip-over
            v-if="receiptData.remark"
            class="c_pointer tooltipOver"
            :content="receiptData.remark"
            refName="tooltipOver5"
          >
            <p>{{ receiptData.remark }}</p>
          </tooltip-over>
          <p v-else class="no-text">未填写</p>
        </el-col>
        <el-col :span="6" v-show="!fileList.length">
          <label>附件</label>
          <div v-show="fileList.length">
            <div class="flie-list" v-for="(item, i) in fileList" :key="i">
              <i class="el-icon-document"></i>
              <a class="page-link ml5">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top-start"
                >
                  <span class="ell">
                    <router-link
                      :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                      target="_blank"
                    >
                      {{ item.name }}
                    </router-link>
                  </span>
                </el-tooltip>
              </a>
              <span class="size ml10">
                {{ utils.calculateFileSize(item.size) }}
              </span>
              <span class="ml10">{{ item.time }}</span>
            </div>
          </div>
          <p v-show="!fileList.length" class="no-text">无</p>
        </el-col>
        <!-- 已作废-没有这个两个（创建人，创建时间） -->
        <el-col :span="5" v-show="status !== 5">
          <label>创建人</label>
          <p>{{ receiptData.creatorName || '--' }}</p>
        </el-col>
        <el-col :span="5" v-show="status !== 5">
          <label>创建时间</label>
          <p>{{ receiptData.createTime || '--' }}</p>
        </el-col>
      </el-row>
      <el-row class="mt20" v-show="fileList.length">
        <label>附件</label>
        <div v-show="fileList.length">
          <div class="flie-list" v-for="(item, i) in fileList" :key="i">
            <i class="el-icon-document"></i>
            <a class="page-link ml5">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="top-start"
              >
                <span class="ell">
                  <router-link
                    :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                    target="_blank"
                  >
                    {{ item.name }}
                  </router-link>
                </span>
              </el-tooltip>
            </a>
            <span class="size ml10">
              {{ utils.calculateFileSize(item.size) }}
            </span>
            <span class="ml10">{{ item.time }}</span>
          </div>
        </div>
        <p v-show="!fileList.length" class="no-text">无</p>
      </el-row>

      <el-row class="row-line"></el-row>

      <!-- 待认领 floor4 -->
      <el-row class="mt20" v-show="status === 2">
        <label>推送客户代表</label>
        <p>{{ receiptData.claimedSalesName || '--' }}</p>
      </el-row>

      <!-- 待处理-无需认领,已完成-订单抵扣 floor4-->
      <el-row
        class="mt20"
        v-show="
          (status === 1 &&
            !receiptData.claimTime &&
            !receiptData.claimSalesName) ||
          (status === 4 && receiptData.salesConfirmTime)
        "
      >
        <label>业务确认时间</label>
        <p>{{ receiptData.salesConfirmTime || '--' }}</p>
      </el-row>

      <!-- 除待确认和待处理没有 floor4 -->
      <el-row class="mt20" v-show="status !== 5 || status !== 1">
        <el-row
          type="flex"
          v-if="
            receiptData.claimSalesName && receiptData.claimTime && status !== 2
          "
        >
          <el-col>
            <label>认领客户代表</label>
            <p>{{ receiptData.claimSalesName || '--' }}</p>
          </el-col>
          <el-col>
            <label>认领时间</label>
            <p>{{ receiptData.claimTime || '--' }}</p>
          </el-col>
        </el-row>
        <!-- table-->
        <el-table
          border
          class="w100 mt30"
          :data="receiptData.financeOrderVOS"
          v-if="
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 6) ||
            (receiptData.statusRemark &&
              receiptData.updateTime &&
              status === 3) ||
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 1) ||
            (receiptData.claimSalesName &&
              receiptData.claimTime &&
              status === 4)
          "
        >
          <el-table-column
            type="index"
            align="center"
            label="#"
          ></el-table-column>
          <el-table-column label="订单编号" align="center" prop="orderCode">
            <template slot-scope="scope">
              <span class="link" @click="orderCodeClick(scope.row.orderCode)">
                {{ scope.row.orderCode }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="客户名称"
            align="center"
            prop="customerName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="公司名称"
            align="center"
            prop="companyName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="所属地区"
            align="center"
            prop="area"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="币种"
            align="center"
            prop="currency"
          ></el-table-column>
          <el-table-column
            label="订单金额"
            align="center"
            prop="totlePrice"
          ></el-table-column>
          <el-table-column
            label="实收金额"
            align="center"
            prop="amountCollected"
          ></el-table-column>
          <el-table-column
            label="手续费金额"
            align="center"
            prop="commissionAmount"
          ></el-table-column>
          <el-table-column
            label="应收金额"
            align="center"
            prop="amountReceivable"
          ></el-table-column>
        </el-table>
      </el-row>

      <el-row class="row-line" style="display: none"></el-row>

      <!-- 已完成，已完成-订单抵扣  floor5-->
      <el-row :gutter="20" type="flex" class="mt30" v-show="status === 4">
        <el-col>
          <label>收款账户</label>
          <p>{{ receiptData.accountCredited || '--' }}</p>
        </el-col>
        <el-col>
          <label>银行入账日期</label>
          <p>{{ receiptData.bankAcceptanceTime || '--' }}</p>
        </el-col>
        <el-col>
          <label>确认人</label>
          <p>{{ receiptData.updateName || '--' }}</p>
        </el-col>
        <el-col>
          <label>确认时间</label>
          <p>{{ receiptData.updateTime || '--' }}</p>
        </el-col>
      </el-row>

      <!-- 已作废  floor5-->
      <el-row :gutter="20" type="flex" class="mt30" v-show="status === 5">
        <el-col>
          <label>作废人</label>
          <p>{{ receiptData.updateName || '--' }}</p>
        </el-col>
        <el-col>
          <label>作废原因</label>
          <p>{{ receiptData.statusRemark || '--' }}</p>
        </el-col>
        <el-col>
          <label>作废时间</label>
          <p>{{ receiptData.updateTime || '--' }}</p>
        </el-col>
      </el-row>
      <!-- 已驳回 floor5-->
      <el-row :gutter="20" type="flex" class="mt30" v-show="status === 3">
        <el-col>
          <label>驳回人</label>
          <p>{{ receiptData.updateName || '--' }}</p>
        </el-col>
        <el-col>
          <label>驳回原因</label>
          <p>{{ receiptData.statusRemark || '--' }}</p>
        </el-col>
        <el-col>
          <label>驳回时间</label>
          <p>{{ receiptData.updateTime || '--' }}</p>
        </el-col>
      </el-row>

      <!-- 待处理-财务二次确认 -->
      <el-row v-if="status === 6 && isOperate">
        <div style="margin-top: 30px" class="t_a_c">
          <el-button type="primary" @click="confirmClick">确认</el-button>
          <el-button @click="rejectClick">驳回</el-button>
        </div>
      </el-row>
      <el-row v-else>
        <div style="margin-top: 30px" class="t_a_c">
          <el-button @click="close">关 闭</el-button>
        </div>
      </el-row>

      <!-- 新增收款单弹窗 -->
      <!-- <CreditNoteAddModel ref="CreditNoteAdd" @updateListNote="close" /> -->
    </div>
  </el-dialog>
</template>

<script>
import {
  detailByFinanceOrder,
  updateClaimStatus,
} from '@/api/finance/credit-manage-list'
import tooltipOver from '@/components/base-tooltip'

export default {
  name: 'ReceiptDetailModel',
  components: {
    tooltipOver,
  },
  data() {
    return {
      dialogReceiptVisible: false,
      status: '', // 收款单状态
      receiptData: '', // 收款详情数据
      receiptTableData: [], //收款table
      orderTableData: [], // 底部订单表格
      fileList: [], //附件
      isOperate: true,
    }
  },
  filters: {
    //收款单状态-tag样式
    statusFilter(status) {
      const statusMap = {
        0: 'warning',
        1: 'warning',
        2: 'danger',
        3: 'danger',
        4: 'success',
        5: 'info',
        6: 'warning',
        7: 'danger',
        8: 'danger',
        9: 'info',
      }
      return statusMap[status]
    },
    //收款单状态
    statusTextFilter(status) {
      const statusTextMap = {
        1: '待处理',
        2: '待认领',
        3: '已驳回',
        4: '已完成',
        5: '已作废',
        6: '已认领',
        7: '待确认',
      }
      return statusTextMap[status]
    },
    //收款来源
    financeTypeFilter(status) {
      const statusTextMap = {
        1: '销售订单',
        2: '其他费用',
        3: '采购退款',
      }
      return statusTextMap[status]
    },
  },
  methods: {
    showReceiptDetail(financeCode, financeStatus, str) {
      this.isOperate = str
      this.status = Number(financeStatus)
      this.getDetailByFinanceOrder(financeCode)
      this.dialogReceiptVisible = true
    },

    //收款单详情
    async getDetailByFinanceOrder(financeCode) {
      let response = await detailByFinanceOrder({ financeCode: financeCode })
      if (response.code === '000000') {
        this.receiptData = response.data

        //收款单table  ，缺少手续费金额
        let receiptTableObj = {
          financeMethod: this.receiptData.financeMethod || '--', //收款方式
          exportsRise: this.receiptData.exportsRise || '--', //出口抬头
          ticketNumber: this.receiptData.ticketNumber || '--', //票据单号
          currency: this.receiptData.currency || '--', //币种
          amountCollected:
            this.toFixed3(this.receiptData.amountCollected) || '--', //收款金额
          amountTime: this.receiptData.amountTime || '--', //收款日期
        }
        this.receiptTableData.push(receiptTableObj)

        //销售单table
        this.orderTableData = response.data.financeOrderVOS

        //附件
        this.fileList = this.receiptData.financeUrl
          ? JSON.parse(this.receiptData.financeUrl)
          : ''
      }
    },

    //已认领，二次确认
    confirmClick() {
      updateClaimStatus({ financeId: this.receiptData.financeId }).then(
        (res) => {
          if (res.code == '000000') {
            this.$message.warning('操作成功')
            this.$emit('updateList')
            this.close()
          }
        }
      )
    },

    //驳回
    rejectClick() {
      this.$refs['CreditNoteAdd'].showCreditAdd(this.receiptData.financeId)
      this.close()
    },

    //订单编号跳转
    orderCodeClick(orderCode) {
      let routeData = this.$router.resolve({
        path: '/order/orderList/orderDetail',
        query: {
          orderCode: orderCode,
          noReturn: true,
        },
      })
      window.open(routeData.href, '_blank')
    },

    close() {
      this.isOperate = false
      this.status = ''
      this.receiptTableData = []
      this.orderTableData = []
      this.receiptData = {}
      this.fileList = []
      this.dialogReceiptVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px 0px;
  .el-row {
    label {
      color: #666;
    }
    p {
      color: #000;
      font-size: 16px;
      white-space: nowrap;
      margin-top: 5px;
    }
  }
  .row-line {
    border-bottom: 1px solid #ededed;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.link {
  color: #46a6ff;
  cursor: pointer;
  text-decoration: underline;
}
.green-bold {
  color: green;
  font-weight: bold;
}
.no-text {
  color: #999 !important;
}

::v-deep {
  .el-tooltip {
    span {
      color: #000;
      font-size: 16px;
      white-space: nowrap;
      margin-top: 5px;
    }
  }
}
</style>
