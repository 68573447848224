<template>
  <div class="template-config">
    <el-row>
      <el-page-header
        @back="goBack"
        content="结算人员配置模板编辑"
        title="返回"
      ></el-page-header>
    </el-row>
    <el-row>
      <el-col :span="2">
        <span class="title">角色模板配置</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20" :offset="2">
        <div class="template-table">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column align="center">
              <template slot="header">
                地区
                <span style="color: red">*</span>
              </template>
              <template slot-scope="scope">
                <el-select v-model="scope.row.area" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in areaList"
                    :key="index"
                    :label="item.areaName"
                    :value="item.areaName"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot="header">
                产品类型
                <span style="color: red">*</span>
              </template>
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.standardCustomized"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in productTypeOption"
                    :key="item.code"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column
              v-for="item in roleList"
              :key="item.code"
              :label="item.message"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  placeholder="请选择"
                  clearable
                  v-model="scope.row[item.code].mark"
                  @focus="showConfig(scope, scope.row)"
                  @clear="deleteOne(scope)"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="addTemplate" style="margin-top: 20px">
      <el-button @click="goBack">返回</el-button>
      <el-button type="primary" style="margin-left: 20px" @click="save">
        保存
      </el-button>
    </div>

    <!-- 条件提成点 -->
    <condition-appoint
      :appointVisible.sync="appointVisible"
      @setRow="setRow"
    ></condition-appoint>
  </div>
</template>
<script>
import conditionAppoint from './components/condition-appoint'
import { SettleConfigInteractor } from '@/core/interactors/finance/config-manage'
import { productType, areas } from '@/views/finance-center/config-manage/helper'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import mixins from './mixins'

export default {
  components: { conditionAppoint },
  mixins: [mixins],
  data() {
    return {
      areaOption: areas,
      productTypeOption: productType,
      tableData: [],
      appointVisible: false,
      roleList: [], // 角色清单
      currentIndex: 0,
      currentCode: '',
      operTable: [],
      configColumnRow: {},
      content: '结算人员配置模板编辑',
      businessIds: '', // 编辑用到的id
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    this.businessIds = this.$route.query.businessIds
      ? this.$route.query.businessIds
      : ''
    this.getRoles()
    this.getConfigDetail()
    this.getArea()
  },
  methods: {
    // 编辑查询
    async getConfigDetail() {
      let res = await SettleConfigInteractor.getConfigConditionBatchQuery(
        this.businessIds
      )
      let resObjectKeys = []
      res.forEach((item) => {
        resObjectKeys.push(Object.keys(item))
      })

      resObjectKeys.forEach((item, index) => {
        this.roleList.forEach((ele) => {
          if (!(item.indexOf(ele.code) > -1)) {
            item.push(ele.code)
            res[index][ele.code] = {}
          }
        })
      })
      this.tableData = res
    },
    showConfig(scope, row) {
      this.currentIndex = scope.$index
      this.currentCode = this.getRoleCode(scope.column.label)
      this.appointVisible = true
    },
    // 返回
    goBack() {
      this.$router.push({
        path: '/finance-center/config-manage',
        query: {
          tab: 2,
          configTab: 'template',
        },
      })
    },
    // 保存
    async save() {
      let paramsTable = []
      this.tableData.forEach((item) => {
        let obj = {
          area: item.area,
          configCommissionId: item.configCommissionId,
          configCommissionRoleDTOs: item.configCommissionRoleVO,
          standardCustomized: item.standardCustomized,
          status: item.status,
          tenantId: item.tenantId,
        }
        paramsTable.push(obj)
      })

      paramsTable.forEach((item) => {
        item.configCommissionRoleDTOs = item.configCommissionRoleDTOs.map(
          (ele) => {
            return {
              configCommissionId: ele.configCommissionId,
              configCommissionRoleId: ele.configCommissionRoleId,
              ratio: ele.ratio,
              ratioType: ele.ratioType,
              roleType: ele.roleType,
              tenantId: ele.tenantId,
              tieredDTOs: ele.tieredVOs || ele.tieredDTOs,
            }
          }
        )
      })
      paramsTable.forEach((item) => {
        item.configCommissionRoleDTOs.forEach((ele) => {
          delete ele.tieredVOs
        })
      })

      // 编辑
      let res = await SettleConfigInteractor.setConfigConditioninsertPOs(
        paramsTable
      )
      if (res) {
        this.$message.success('操作成功！')
        this.goBack()
      }
    },
    // 角色列表
    async getRoles() {
      this.roleList =
        await SettleConfigInteractor.getConfigConditionSettlementRoles()
    },
    // 获取角色code
    getRoleCode(msg) {
      let roleMap = new Map()
      this.roleList.forEach((ele) => {
        roleMap.set(ele.message, ele.code)
      })
      return roleMap.get(msg)
    },
    setRow(value) {
      this.configColumnRow = { ...value }
      // 如果不为空对象。修改
      if (
        JSON.stringify(this.tableData[this.currentIndex][this.currentCode]) !==
        '{}'
      ) {
        this.tableData[this.currentIndex].configCommissionRoleVO.forEach(
          (item) => {
            if (item.roleType === this.currentCode) {
              let stepDesc = value.tieredDTOS?.map((item) => item.ratio)
              item.ratio = value.fixedRatio
              item.ratioDesc =
                value.type == 1
                  ? `${value.typeDesc},${value.fixedRatio}`
                  : `${value.typeDesc},${stepDesc.join(',')}`
              item.ratioType = value.type
              item.ratioTypeDesc = value.typeDesc
              item.tieredVOs =
                value.type == 1 ? undefined : [...value.tieredDTOS]
            }
          }
        )
        // 固定，设置展示内容
        if (value.type === 1) {
          this.tableData[this.currentIndex][this.currentCode] = {
            mark: `${value.typeDesc},${value.fixedRatio}`,
            ratioType: value.type,
            tieredVO: null,
          }
        }
        // 阶梯 设置展示内容
        if (value.type === 2) {
          let stepDesc = value.tieredDTOS.map((item) => item.ratio)
          this.tableData[this.currentIndex][this.currentCode] = {
            mark: `${value.typeDesc},${stepDesc.join(',')}`,
            ratioType: value.type,
            tieredVO: value.tieredDTOS,
          }
        }
      } else {
        if (value.type === 1) {
          this.tableData[this.currentIndex][this.currentCode] = {
            mark: `${value.typeDesc},${value.fixedRatio}`,
            ratioType: value.type,
            tieredVO: null,
          }
        }
        if (value.type === 2) {
          let stepDesc = value.tieredDTOS.map((item) => item.ratio)
          this.tableData[this.currentIndex][this.currentCode] = {
            mark: `${value.typeDesc},${stepDesc.join(',')}`,
            ratioType: value.type,
            tieredVO: value.tieredDTOS,
          }
        }
        let obj = {
          ratio: value.fixedRatio,
          ratioType: value.type,
          roleType: this.currentCode,
          tenantId: value.tenantId,
          tieredDTOs: value.tieredDTOS,
        }
        this.tableData[this.currentIndex].configCommissionRoleVO.push(obj)
      }
    },
    // 删除某一项
    deleteOne(scope) {
      this.$set(scope.row, this.getRoleCode(scope.column.label), {})
      scope.row.configCommissionRoleVO =
        scope.row.configCommissionRoleVO.filter((item) => {
          return item.roleType !== this.getRoleCode(scope.column.label)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.template-config {
  box-sizing: border-box;
  padding: 10px 0px;
  width: 100%;
  height: calc(100vh - 55px);
  overflow-y: scroll;
  .title {
    display: inline-block;
    text-align: right;
  }
  .title::before {
    content: '';
    border-right: 7px solid blue;
    margin-right: 5px;
  }
  .template-table {
    margin-top: 15px;
  }
  .addTemplate {
    text-align: center;
    margin-top: 15px;
  }
}
</style>
