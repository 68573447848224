export const TabList = [
  {
    name: "订单记录",
    langKey: "",
    code: "1"
  },
  {
    name: "询盘记录",
    langKey: "",
    code: "2"
  },
  {
    name: "邮件记录",
    langKey: "",
    code: "3"
  },
  {
    name: "报价单",
    langKey: "",
    code: "4"
  },
  {
    name: "产品信息",
    langKey: "",
    code: "5"
  },
  {
    name: "出运信息",
    langKey: "",
    code: "6"
  },
  {
    name: "跟进记录",
    langKey: "",
    code: "7"
  },
  {
    name: "余额记录",
    langKey: "",
    code: "8"
  },
  {
    name: "客户等级成长记录",
    langKey: "",
    code: "9"
  },
  {
    name: "评价记录",
    code: "10",
    langKey: "cusDetail.EvaluationRecord",
  },
  {
    name: "备注",
    code: "11",
    langKey: "cusManage.Remark",
  },
]

