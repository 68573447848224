<template>
  <div>
    <el-row :gutter="40">
      <el-col :span="6" v-for="it in specTable" :key="it.positionType">
        <el-table :data="it.value" width="100%" border>
          <el-table-column
            :label="it.nameCn"
            prop="specsValue"
            align="center"
          ></el-table-column>
          <!-- 规格加价 -->
          <el-table-column
            prop="specsPrice"
            :label="$t('productDetail.SpecificationMarkup')"
            align="center"
          ></el-table-column>
          <!-- 操作 -->
          <el-table-column
            prop="basePricesSetting"
            :label="$t('productDetail.Operate')"
            align="center"
          >
            <template slot-scope="scope">
              <!-- 基准价 -->
              <span
                v-show="scope.row.basePricesSetting == 1"
                style="color: gray"
              >
                {{ $t('productDetail.BenchmarkPrice') }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row class="mt20" v-if="false">
      <el-col :span="23" v-if="specTable.length > 0">
        <el-table
          border
          :data="specListTable"
          width="100%"
          :span-method="spanMethod"
        >
          <!-- 数量 -->
          <el-table-column
            :label="$t('productDetail.Quantity')"
            align="center"
            prop="num"
          ></el-table-column>
          <!-- 产品编码 -->
          <el-table-column
            :label="$t('productDetail.ProductCode')"
            align="center"
            prop="sku"
          ></el-table-column>
          <el-table-column
            v-for="(it, i) in sepcTypes"
            :key="i"
            :label="it"
            align="center"
            :prop="'specsValue' + (i + 1)"
          ></el-table-column>
          <el-table-column
            label="Blank Net Price($)"
            align="center"
            prop="netPrice"
          >
            <template slot-scope="scope">
              {{ (scope.row.netPrice + scope.row.extraPrice).toFixed(3) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Blank List Price($)"
            align="center"
            prop="listPrice"
          >
            <template slot-scope="scope">
              {{ (scope.row.listPrice + scope.row.extraPrice).toFixed(3) }}
            </template>
          </el-table-column>
          <!-- Blank Net 总价($) -->
          <el-table-column
            :label="'Blank' + $t('productDetail.NetTotalPrice')"
            align="center"
            prop="totalNetPrice"
          >
            <template slot-scope="scope">
              {{
                (
                  scope.row.totalNetPrice +
                  scope.row.extraPrice * scope.row.num
                ).toFixed(3)
              }}
            </template>
          </el-table-column>
          <!-- "Blank List 总价($) -->
          <el-table-column
            :label="'Blank' + $t('productDetail.ListTotalPrice')"
            align="center"
            prop="totalListPrice"
          >
            <template slot-scope="scope">
              {{
                (
                  scope.row.totalListPrice +
                  scope.row.extraPrice * scope.row.num
                ).toFixed(3)
              }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      specTable: [],
      sepcTypes: [],
      specListTable: [],
      spanArr: [],
      priceRate: '',
    }
  },
  methods: {
    detailInfo(detail) {
      this.priceRate = detail.priceRate
      this.specTable = []
      this.specListTable = []

      this.setSpecTable(detail)

      this.setSpecType(detail)

      this.setSpecList(detail)
    },
    // 设置规格
    setSpecType(detail) {
      this.sepcTypes = detail.specsType.split(',')
    },
    // 设置规格加价
    setSpecTable(detail) {
      let arr = detail.productSpecsAddPriceVOList
      let arrSet = new Set(
        arr?.map((it) => {
          return it.positionType
        })
      )
      let list = Array.from(arrSet)
      let cArr = []
      list.forEach((it) => {
        cArr = arr.filter((ot) => {
          return ot.positionType == it
        })
        let obj = {
          positionType: it,
          nameCn: cArr[0].specsName,
          value: cArr,
        }
        this.specTable.push(obj)
      })
    },
    // 显示价格
    setSpecList(detail) {
      let steps = detail.productPricingStepVOList || []
      let productSpecs = detail.productSpecsShowVOList || []

      let priceMap = new Map()
      // 规格值对应的规格加价
      this.specTable.forEach((it) => {
        it.value.forEach((ot) => {
          priceMap.set(ot.specsValue, ot.specsPrice)
        })
      })

      steps?.forEach((it) => {
        productSpecs.forEach((ot) => {
          let obj = {
            num: Number(it.num),
            netPrice: Number(it.netPrice),
            listPrice: Number(it.listPrice),
            totalNetPrice: Number(it.totalNetPrice),
            totalListPrice: Number(it.totalListPrice),
            extraPrice1: priceMap.get(ot.specsValue1),
            extraPrice2: ot.specsValue2 ? priceMap.get(ot.specsValue2) : 0,
            ...ot,
          }
          // 设置总得额外规格加价
          this.$set(
            obj,
            'extraPrice',
            (Number(obj.extraPrice1) + Number(obj.extraPrice2)) / this.priceRate
          )
          this.specListTable.push(obj)
        })
      })
      this.getSpanArr(this.specListTable)
    },
    // 单元格合并
    getSpanArr(list) {
      this.spanArr = []
      let pos = 0
      for (let i = 0; i < list.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          pos = 0
        } else {
          if (list[i].num === list[i - 1].num) {
            this.spanArr[pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            pos = i
          }
        }
      }
    },
    // 单元格合并
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'num') {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },
  },
}
</script>
