<template>
  <div class="search_form">
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item label="付款方">
        <el-input
          v-model="form.shroffAccountId"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="form.financeStatus"
          placeholder="请选择"
          class="w100"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="客户代表">
        <el-input
          clearable
          v-model="yewuyuan"
          placeholder="请选择"
          @focus="selectUserClick('yewuyuan')"
          @change="yewuyuanChange"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="确认日期">
        <el-date-picker
          clearable
          v-model="confirmDateRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="confirmDateRangeChange"
        />
      </el-form-item>

      <el-form-item label="收款来源">
        <el-select
          v-model="form.financeTypeObj"
          placeholder="请选择"
          class="w100"
          clearable
          value-key="index"
        >
          <!-- entryType:0 正 -->
          <!-- <el-option label="销售订单" :value="1" />
          <el-option label="采购退款" :value="3" />
          <el-option label="其他费用" :value="2" /> -->
          <el-option
            v-for="item in financeTypes"
            :label="item.label"
            :value="item"
            :key="item.index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="币种">
        <el-select
          v-model="form.currency"
          placeholder="请选择"
          class="w100"
          clearable
        >
          <el-option
            v-for="item in currencyOptions"
            :key="item.financeSetupId"
            :label="item.financeName"
            :value="item.financeName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="待分配金额">
        <div class="line_input">
          <el-input
            v-model="form.startAmountDistributed"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            placeholder="请输入"
            clearable
            maxlength="12"
          />

          <span>至</span>
          <el-input
            v-model="form.endAmountDistributed"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            placeholder="请输入"
            clearable
            maxlength="12"
          />
        </div>
      </el-form-item>
      <el-form-item label="收款金额">
        <div class="line_input">
          <!-- <el-input
            v-model="form.startAmountCollected"
            onkeyup="this.value = this.value.replace(/[^-?\d]/g,'');"
            placeholder="请输入"
            clearable
            maxlength="12"
          /> -->
          <el-input-number
            placeholder="请输入"
            v-model="form.startAmountCollected"
            :controls="false"
            style="width: 50%"
          ></el-input-number>
          <span>至</span>
          <!-- <el-input
            v-model="form.endAmountCollected"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            placeholder="请输入"
            clearable
            maxlength="12"
          /> -->
          <el-input-number
            placeholder="请输入"
            v-model="form.endAmountCollected"
            :controls="false"
            style="width: 50%"
          ></el-input-number>
        </div>
      </el-form-item>
      <el-form-item label="收款日期">
        <el-date-picker
          v-model="collectDateRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="collectDateRangeChange"
        />
      </el-form-item>
      <el-form-item label="收款方式">
        <el-select
          v-model="form.financeMethodCode"
          placeholder="请选择"
          class="w100"
          clearable
        >
          <el-option
            v-for="item in paymentOptions"
            :key="item.financeMethodCode"
            :label="item.financeName"
            :value="item.financeSetupId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="银行入账日期">
        <el-date-picker
          v-model="bankCollectDateRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="bankCollectDateRangeChange"
        />
      </el-form-item>
      <el-form-item label="收款账户">
        <el-select
          v-model="form.bankAccount"
          placeholder="请选择"
          class="w100"
          clearable
        >
          <el-option
            v-for="item in accountList"
            :key="item.shroffAccountId"
            :label="item.payee + ' / ' + item.bankAccount"
            :value="item.bankAccount"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="票据单号">
        <el-input
          clearable
          v-model="form.ticketNumber"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建人">
        <el-input
          clearable
          v-model="createName"
          placeholder="请选择"
          @focus="selectUserClick('createName')"
          @change="createNameChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="createDateRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="createDateRangeChange"
        />
      </el-form-item>
      <div class="operate">
        <el-button type="primary" @click="searchClick">确定</el-button>
        <el-button @click="resetClick">重置</el-button>
      </div>
    </el-form>

    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import UserChoose from '@/components/userChoose2'
  import {
    selectOptions,
    getAllShroffAccount,
    listShorffAccountPay,
  } from '@/api/finance/credit-manage-list'
  export default {
    name: 'ProductSearch',
    components: { UserChoose },
    data() {
      return {
        form: {
          currency: '美元',
        },
        confirmDateRange: [], //确认日期
        collectDateRange: [], //收款日期
        bankCollectDateRange: [], // 银行入账日期
        createDateRange: [], //创建日期
        proTypeData: [], //产品类型7
        isUserChoose: '',
        yewuyuan: '', //客户代表
        yewuyuanId: [], //客户代表id
        createName: '', //创建人
        createNameId: [], //创建人id
        paymentOptions: [], // 收款方式列表
        currencyOptions: [], //货币下拉
        accountList: [], // 收款账号下拉
        statusList: [
          { value: '1', label: '待处理' },
          { value: '2', label: '待认领' },
          { value: '3', label: '已驳回' },
          { value: '4', label: '已完成' },
          { value: '5', label: '已作废' },
          { value: '6', label: '已认领' },
          { value: '7', label: '待确认' },
          { value: '8', label: '审核中' },
          { value: '9', label: '审核驳回' },
          { value: '10', label: '已撤销' },
        ],
        financeTypes: [
          {
            label: '销售订单',
            financeType: 1, //销售订单
            entryType: 0,
            index: 0,
          },
          {
            label: '销售退款',
            financeType: 1, // 销售退款
            entryType: 1,
            index: 1,
          },
          {
            label: '其他费用',
            financeType: 2, //其他费用
            index: 2,
          },
        ],
      }
    },
    created() {
      this.getSelectOptions(1)
      this.getSelectOptions(3)
      this.getAccountList()
    },
    methods: {
      // 获取所有下拉框数据
      async getSelectOptions(type) {
        // 1币种设置 2发票设置 3支付方式 4科目 5费用杂项
        let response = await selectOptions({
          financeType: Number(type),
        })
        if (response.code === '000000') {
          if (type === 1) {
            this.currencyOptions = response.data
          }
          if (type === 3) {
            this.paymentOptions = response.data
          }
        }
      },
      // 获取银行账号
      getAccountList() {
        listShorffAccountPay({
          shroffType: 3,
        }).then((res) => {
          if (res.code == '000000') {
            this.accountList = res.data || []
          }
        })
      },
      //确定
      searchClick() {
        //确认日期
        if (this.confirmDateRange && this.confirmDateRange.length) {
          this.form.startConfirmTime = this.confirmDateRange[0]
          this.form.endConfirmTime = this.confirmDateRange[1]
        }
        //收款日期
        if (this.collectDateRange && this.collectDateRange.length) {
          this.form.startAmountTime = this.collectDateRange[0]
          this.form.endAmountTime = this.collectDateRange[1]
        }
        //银行入账日期
        if (this.bankCollectDateRange && this.bankCollectDateRange.length) {
          this.form.startBankAcceptanceTime = this.bankCollectDateRange[0]
          this.form.endBankAcceptanceTime = this.bankCollectDateRange[1]
        }
        //收款日期
        if (this.createDateRange && this.createDateRange.length) {
          this.form.startCreateTime = this.createDateRange[0]
          this.form.endCreateTime = this.createDateRange[1]
        }
        //收款金额
        if (this.form.endAmountCollected) {
          this.form.endAmountCollected = Number(this.form.endAmountCollected)
        }
        //创建人
        if (this.createName) {
          this.form.creatorId = this.createNameId.join(',')
        }

        this.$emit('searchClick', this._handleEntryAndFinance(this.form))
      },

      // 处理entryType和financeType
      _handleEntryAndFinance(form) {
        form = JSON.parse(JSON.stringify(form))
        if (typeof form.financeTypeObj?.entryType !== 'undefined') {
          form.entryType = form.financeTypeObj.entryType
        }
        if (form.financeTypeObj?.financeType) {
          form.financeType = form.financeTypeObj.financeType
        }

        const noUseKeys = ['index', 'label', 'financeTypeObj']
        noUseKeys.forEach((key) => {
          delete form[key]
        })
        return form
      },

      //确认时间清空
      confirmDateRangeChange(val) {
        if (!val) {
          this.form.startConfirmTime = ''
          this.form.endConfirmTime = ''
        }
      },
      //收款时间清空
      collectDateRangeChange(val) {
        if (!val) {
          this.form.startAmountTime = ''
          this.form.endAmountTime = ''
        }
      },
      //创建时间清空
      createDateRangeChange(val) {
        if (!val) {
          this.form.startCreateTime = ''
          this.form.endCreateTime = ''
        }
      },
      //银行入账日期
      bankCollectDateRangeChange(val) {
        if (!val) {
          this.form.startBankAcceptanceTime = ''
          this.form.endBankAcceptanceTime = ''
        }
      },

      //创建人选择
      selectUserClick(str) {
        this.isUserChoose = str

        this.$refs['UserChoose'].showAddEdit('listSearch', this.createNameId)
      },
      //创建人返回
      chooseUser(checkedUser) {
        let userName = []

        this.createNameChange()
        if (!checkedUser.length) {
          return
        }

        checkedUser.forEach((item) => {
          userName.push(item.name)
          if (this.isUserChoose === 'createName') {
            this.createNameId.push(item.id)
            this.createName = userName.join(',')
          }
        })
      },

      // 删除创建人
      createNameChange() {
        this.createNameId = []
        this.createName = ''
        this.form.creatorId = ''
      },

      // cancelClick
      resetClick() {
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
        this.confirmDateRange = []
        this.collectDateRange = []
        this.createDateRange = []
        this.bankCollectDateRange = []

        this.createName = ''
        this.createNameId = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search_form {
    height: 90%;
    padding: 0 20px;
    overflow-y: auto;
    .operate {
      position: fixed;
      width: 100%;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #ededed;
      padding: 20px;
    }
  }
</style>
<style lang="scss" scoped>
  .search_form .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  ::v-deep {
    .el-cascader {
      width: 100%;
    }
    .el-date-editor {
      width: 100%;
    }
    .el-divider {
      margin: 70px 0 28px 0 !important;
    }
  }
</style>
