<template>
  <div>
    <el-dialog
      title="请选择模板"
      :visible.sync="addModelVisible"
      width="1300px"
      :before-close="cancelModel"
      center
    >
      <el-row :gutter="20">
        <el-col :span="5">
          <el-select v-model="area" placeholder="请选择" clearable>
            <el-option
              v-for="(item, index) in areaList"
              :key="index"
              :label="item.areaName"
              :value="item.areaName"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
      <el-table :data="tableData" style="width: 100%; margin-top: 20px" border>
        <el-table-column width="80" align="center" label="选择">
          <template slot-scope="scope">
            <el-radio
              style="transform: translateX(10px)"
              v-model="chooseRow"
              :value="scope.row.configCommissionId"
              :label="scope.row.configCommissionId"
            >
              &nbsp
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="area"
          align="center"
          label="地区"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="standardCustomized"
          align="center"
          width="80"
          label="产品类型"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          v-for="item in roleList"
          :key="item.code"
          :prop="item.code"
          align="center"
          width="125"
        >
          <template slot="header">
            <div>
              <span style="margin-right: 5px">{{ item.message }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="模板类型+提成点，阶梯用逗号区分开"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row[getRoleCode(item.message)]">
              <span v-if="scope.row[getRoleCode(item.message)].ratioType == 1">
                {{ scope.row[getRoleCode(item.message)].mark }}
              </span>
              <el-link
                v-if="scope.row[getRoleCode(item.message)].ratioType == 2"
                type="primary"
                @click="showDetail(scope.row[getRoleCode(item.message)])"
              >
                {{ scope.row[getRoleCode(item.message)].mark }}
              </el-link>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelModel">取 消</el-button>
        <el-button type="primary" @click="confirmModel">确 定</el-button>
      </span>
    </el-dialog>
    <modelDetailDialog
      :modelDetailVisible.sync="modelDetailVisible"
      :tableData.sync="detailTable"
    ></modelDetailDialog>
  </div>
</template>
<script>
import { templateConfigOperColumns } from '@/views/finance-center/config-manage/helper'
import { SettleConfigInteractor } from '@/core/interactors/finance/config-manage'
import modelDetailDialog from './model-detali-dialog'
// import mixins from '../mixins'
export default {
  components: { modelDetailDialog },
  // mixins: [mixins],
  props: {
    // 弹窗显示
    addModelVisible: {
      type: Boolean,
      default: false,
    },
    roleList: {
      type: Array,
      default: () => [],
    },
    areaList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      addColumns: templateConfigOperColumns,
      tableData: [],
      roleMap: null,
      chooseRow: '',
      modelDetailVisible: false,
      detailTable: [],
      area: '',
    }
  },
  watch: {
    addModelVisible(newval, oldval) {
      if (newval) {
        this.getList()
        this.setRoleCode()
      }
    },
  },
  methods: {
    search() {
      this.getList()
    },
    async getList() {
      this.tableData = await SettleConfigInteractor.getTemplateListDatas(
        this.area
      )
    },
    cancelModel() {
      this.$emit('update:addModelVisible', false)
      this.chooseRow = ''
    },
    confirmModel() {
      if (!this.chooseRow) {
        this.$message.warning('请选择一条数据！')
        return
      }
      this.$emit('update:addModelVisible', false)
      this.$router.push({
        path: '/finance-center/person-settlement-oper',
        query: {
          str: 'add',
          id: this.chooseRow,
        },
      })
      this.chooseRow = ''
    },
    setRoleCode() {
      this.roleMap = new Map()
      this.roleList?.forEach((ele) => {
        this.roleMap.set(ele.message, ele.code)
      })
    },
    getRoleCode(msg) {
      return this.roleMap.get(msg)
    },
    showDetail(row) {
      this.detailTable = row.tieredVO
      this.modelDetailVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
