<template>
  <div style="padding: 30px 0px 30px 90px">
    <table
      border="1px"
      cellspacing="0"
      style="
        border-collapse: collapse;
        font-size: 14px;
        line-height: 20px;
        color: rgb(102, 102, 102);
        width: 100%;
        max-width: 750px;
        border-color: #d7d7d7;
      "
    >
      <thead>
        <tr style="background-color: #f2f2f2">
          <th style="padding: 20px 3px">Item Number</th>
          <th style="padding: 20px 3px; min-width: 200px">Product Name</th>
          <th style="padding: 20px 3px; min-width: 180px">Specification</th>
          <th style="padding: 20px 3px; line-height: 18px">
            QUANTITY
            <br />
            (units)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="text-align: center; padding: 20px 3px">313465-01</td>
          <td style="text-align: center; padding: 20px 3px">Clear Backpack</td>
          <td style="text-align: center; padding: 20px 3px">
            <span>Color: Blue</span>
            <br />
            <span>Size: XL</span>
          </td>
          <td style="text-align: center; padding: 20px 3px">30</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  export default {
    data() {
      return {}
    },
  }
</script>
<style lang="scss" scoped></style>
