<template>
  <div>
    <el-row type="flex" justify="end">
      <!-- 自定义展示列 -->
      <el-popover popper-class="custom-table-checkbox" trigger="hover">
        <erp-draggable v-bind="dragOptions" :list="columns">
          <el-checkbox
            v-for="(item, index) in columns"
            :key="item + index"
            v-model="item.isShow"
            :disabled="item.checkable === false"
            :label="item.label"
          >
            {{ item.label }}
          </el-checkbox>
        </erp-draggable>
        <!-- 保存自定义显示列 -->
        <div class="save-btn">
          <el-button
            type="primary"
            class="ml10"
            size="mini"
            plain
            @click="operateSave()"
          >
            保存
          </el-button>
        </div>
        <template #reference>
          <el-tooltip
            effect="dark"
            content="列表可拖动排序"
            placement="top-start"
          >
            <el-button plain type="primary">自定义显示</el-button>
          </el-tooltip>
        </template>
      </el-popover>
    </el-row>
  </div>
</template>
<script>
import ErpDraggable from 'vuedraggable'
export default {
  name: 'custom-column',
  components: {
    ErpDraggable,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    dragOptions: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    operateSave() {
      this.$emit('operateSave')
    },
  },
}
</script>