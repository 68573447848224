<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="40%"
    @close="close"
    top="8vh"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <div class="dialog-div">
      <el-row class="w-el-row">
        <el-form ref="form">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item>
                <el-input
                  v-model="supplierName"
                  @input="searchInput"
                  clearable
                  @keyup.enter.native="searchClick"
                  placeholder="请输入供应商名称"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button @click="searchClick" class="pub-h-36">搜索</el-button>
            </el-col>
          </el-row>
        </el-form>

        <el-row class="container">
          <el-table
            :data="tableData"
            ref="dataTable"
            stripe
            border
            class="mt10 w100 table-height"
            @selection-change="setSelectRows"
            :row-key="
              (row) => {
                return row.supplierId
              }
            "
          >
            <el-table-column
              align="center"
              type="selection"
              width="55"
              label="序号"
              :reserve-selection="true"
            />
            <el-table-column
              align="center"
              label="供应商编码"
              width="150"
              prop="supplierCode"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.supplierCode || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="供应商名称"
              prop="supplierName"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.supplierName || '--' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-pagination
          v-if="total"
          :current-page="page.pageNo"
          :page-size="page.pageLe"
          :total="total"
          @current-change="currentChange"
        />
      </el-row>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确认</el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { listSupplierByProductCategoryId } from '@/api/product/productList'
export default {
  name: 'SupplierListModel',
  data() {
    return {
      title: '选择供应商',
      supplierName: '', //供应商名称查询
      dialogFormVisible: false,
      tableData: [],
      selectRows: [], //表格勾选
      selectIds: '', // 选中的供应商
      categoryId: '', //产品分类的id
      page: {
        pageLe: 10,
        pageNo: 1,
      },
      total: 0,
      selectSupplierId: '', //选中的id
    }
  },
  created() {},
  methods: {
    //展示弹框(id-产品类别id，supplierId-所有选中的供应商id)
    supplierListModel(supplierId) {
      this.listSupplierSelect()
      if (supplierId) {
        this.selectIds = supplierId.split(',')
      }
      this.dialogFormVisible = true
    },
    // 编辑回显勾选
    selectSupplier() {
      this.$nextTick(function () {
        this.tableData.forEach((row, index) => {
          if (this.selectIds.includes(row.supplierId)) {
            this.$refs.dataTable.toggleRowSelection(row, true)
          }
        })
      })
    },

    //供应商列表
    async listSupplierSelect(str) {
      let response = await listSupplierByProductCategoryId({
        params: this.supplierName,
        ...this.page,
      })
      if (response.code === '000000') {
        this.tableData = response.data.data
        this.total = response.data.total
        this.selectSupplier()
      }
    },
    // 表格勾选
    setSelectRows(val) {
      this.selectRows = val
    },

    currentChange(val) {
      this.page.pageNo = val
      this.listSupplierSelect('test')
    },

    searchClick(val) {
      this.page.pageNo = 1
      this.listSupplierSelect()
    },
    // 搜索框清空
    searchInput(val) {
      if (val === '') {
        this.listSupplierSelect()
      }
    },

    // 保存
    save() {
      //还要再判断有没有数据
      if (this.selectRows.length === 0) {
        return this.$baseMessage(
          '请勾选供应商',
          'warning',
          false,
          'erp-hey-message-warning'
        )
      } else {
        this.$emit('supplierSelect', this.selectRows)
        this.close()
      }
    },
    // 关闭
    close() {
      this.page.pageNo = 1
      this.total = 0
      this.supplierName = ''
      this.categoryId = ''
      this.mainIds = []
      this.tableData = []
      this.selectIds = ''
      this.dialogFormVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-div {
  max-height: 70vh;
  overflow: hidden;
}
.container {
  height: 55vh;
  overflow: hidden;
  overflow-y: auto;
}
::v-deep {
  .dialog-div {
    .w-el-row {
      width: 95% !important;
      margin: 0 auto !important;
    }
  }
}
</style>
