<template>
  <div class="container">
    <el-form ref="form" :model="form">
      <el-form-item label="运费报价限制" label-width="110px">
        <el-switch
          v-model="form.limitFlag"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>

      <div class="rule" v-show="form.limitFlag">
        <p class="dark mb10">限制条件</p>
        当报价单中的【Shipping Fee】超过【Total Amount】的
        <el-input
          placeholder="请输入"
          v-model="form.percent"
          maxlength="4"
          clearable
          oninput="value=value.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')"
        >
          <template slot="append">%</template>
        </el-input>
        或者 【Shipping Fee】超过
        <el-input
          placeholder="请输入"
          v-model="form.money"
          maxlength="10"
          clearable
          oninput="value=value.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')"
        >
          <template slot="prepend">$</template>
        </el-input>

        <p class="dark mt20">处理方式</p>
        <el-form-item>
          <el-radio-group v-model="form.handlingMethod">
            <el-radio :label="0">给出提示，但允许提交报价</el-radio>
            <el-radio :label="1">禁止提交报价</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>

    <div class="oper-btn text-center mt20">
      <el-button type="primary" @click="save" :loading="loading">
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import { OrderOfferSetting } from '@/core/interactors/order/order-offer-setting'
export default {
  data() {
    return {
      form: {
        currency: '美元', // 币种
        handlingMethod: 0, // 处理方式(0 给出提示,但允许报价 1禁止提交报价)
        limitFlag: '', // 运费报价限制(0不限制 1限制)
        money: '', // 金额
        percent: '', // 百分比
        priceSheetSettingId: '', // 业务id
      },
      loading: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let res = await OrderOfferSetting.priceQueryOne()

      if (res && res.code === '000000') {
        this.form = res.data        
      }
    },

    // 保存
    async save() {
      this.loading = true
      let res = await OrderOfferSetting.priceUpdatePO({ ...this.form })

      if (res && res.code === '000000') {
        this.init()
        this.$message.success('保存成功')
      }
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  padding: 10px 30px;
  .rule {
    margin-left: 15px;
    height: calc(100vh - 220px);
    .el-input {
      width: 150px;
      margin: 0 10px;
    }
  }
  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
  }
}
::v-deep {
  .el-input-group__prepend,
  .el-input-group__append {
    background: none;
    padding: 0 10px;
  }
}
</style>