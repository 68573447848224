<!--
  功能：付款通知组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月16日 20:00:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard
    title="付款通知"
    :tabs="tabs"
    @tab-click="handleTabClick"
    :loading="loading"
    :singleNum="singleNum"
    :teamNum="teamNum"
  >
    <div
      v-if="list.length > 0"
      ref="scrollContainer"
      class="infinite-scroll content"
      @scroll="onScroll"
    >
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="payment-order-item">
          <div
            class="content"
            @click="clientFollowClick(item)"
            :style="itemStyleObj"
          >
            <div class="payment-order-item--top">
              <span class="top-left">
                采购订单
                <span :style="boldStyle.bold">{{ item.number }}</span>
                已到达
              </span>
              <span :style="boldStyle.bold">
                {{ Math.round(item.percent * 100) }}%{{ item.paymentNodeName }}
              </span>
              付款节点
            </div>
            <div class="payment-order-item--bottom">
              <p>
                <span class="label">采购开发:</span>
                <span class="content">{{ item.buyerName }}</span>
              </p>
              <p>
                <span class="content">{{ item.estimatedTransactionDate }}</span>
              </p>
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
    <ApplyPament ref="ApplyPament" @success="getList" />
  </IndexCard>
</template>

<script>
  import { HomeInteractor } from '@/core'
  import mixins from '../mixins'
  import ApplyPament from '@/views/purchase/purchaseOrder/components/applyPayment.vue'
  import IndexCard from './index-card'

  export default {
    name: 'PaymentOrder',
    mixins: [mixins],
    props: {},
    components: {
      ApplyPament,
      IndexCard,
    },
    // 组件状态值
    data() {
      return {
        tabs: true,
        list: [],
        pageLe: 20,
        pageNo: 1,
      }
    },
    computed: {},
    methods: {
      onScroll(event) {
        const scrollContainer = event.target
        const scrollTop = scrollContainer.scrollTop
        const scrollHeight = scrollContainer.scrollHeight
        const clientHeight = scrollContainer.clientHeight
        if (scrollTop + clientHeight >= scrollHeight) {
          if (this.active === 'ME') {
            if (Math.ceil(this.singleNum / this.pageLe) > this.pageNo) {
              this.pageNo++
              this.getList(true)
            }
          } else {
            if (Math.ceil(this.teamNum / this.pageLe) > this.pageNo) {
              this.pageNo++
              this.getList(true)
            }
          }
        }
      },
      getList(bol = false) {
        this.loading = true
        if (!bol) this.pageNo = 1
        const { tenantId, userId } = this.userInfo
        const requestData = {
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          authFlag: this.active === 'ME' ? 0 : 1,
          tenantId,
          userId,
        }
        HomeInteractor.listHomePurchasePayment(requestData).then((res) => {
          if (res?.code === '000000' && res?.data?.dataList) {
            const { ownTotal = 0, teamTotal = 0 } = res.data
            this.singleNum = ownTotal
            this.teamNum = teamTotal
            if (bol) {
              this.list = [...this.list, ...res.data.dataList.data]
            } else {
              this.list = res.data.dataList.data
            }
          } else {
            this.list = []
          }
          this.loading = false
        })
      },

      clientFollowClick({ purchaseOrderId, number, state }) {
        let canClick = this.beforeListItemClick()
        if (canClick) {
          // this.$router.push({
          //   path: 'purchase/purchase/purchaseOrder/purchaseOrderDetail',
          //   query: {
          //     id: purchaseOrderId,
          //   },
          // })
          this.$refs.ApplyPament.show(purchaseOrderId)
        }
      },
    },
    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .infinite-scroll {
    height: 100%;
    overflow-y: auto;
  }
  .payment-order-item {
    font-size: 14px;
    .payment-order-item--top {
      margin-bottom: 10px;
      color: #000000;
    }
    .payment-order-item--bottom {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      p {
        color: #5e5e5e;
        font-family: Regular;
        .label {
          margin-right: 5px;
        }
      }
    }
  }
</style>
