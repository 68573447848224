<!-- 业务交接列表 -->
<template>
  <div
    class="custom-container"
    v-if="$route.meta.showParent"
    :key="$route.path"
  >
    <!-- 搜索 -->
    <ErpSearch
      ref="searchForm"
      :value="filterForm"
      :searchFormConfig="searchFormConfig"
      @enter-search="resetPageSearch"
    />
    <div>
      <el-button type="primary" @click="showDetail('')">新增交接</el-button>
    </div>
    <el-table
      class="mt15"
      border
      :tableData="tableData"
      :height="tableHeight"
      :data="tableData"
    >
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.tagType" v-if="item.prop === 'statusName'">
            {{ scope.row.statusName }}
          </el-tag>
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.errorNum + '条失败'"
            placement="top"
            v-if="item.prop === 'statusName'"
          >
            <i
              class="el-icon-warning-outline ml10"
              effect="light"
              v-if="scope.row.status == 3"
            ></i>
          </el-tooltip>
          <span v-else>{{ scope.row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span class="page-link-noline" @click="showDetail(scope.row)">
            查看详情
          </span>

          <span
            class="page-link-noline"
            @click="retry(scope.row)"
            v-if="scope.row.status == 3"
          >
            重新执行
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <Pagination
      class="mt-10"
      v-if="page.total > 0"
      :limit.sync="page.pageLe"
      :page.sync="page.pageNo"
      :total="page.total"
      @pagination="onPagination"
    />
  </div>
  <div v-else class="pro-container" :key="$route.path">
    <router-view />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { systemInteractor } from '@/core'
  import { getCommonPickOptions, tableHeightMixin } from '@/utils/element-util'
  import Pagination from '@/components/Pagination/Index'
  import Detail from './components/business-transfer-detail'
  export default {
    name: 'BusinessTransfer',
    components: { Pagination, Detail },
    mixins: [tableHeightMixin],
    data() {
      return {
        filterForm: {
          fromUserId: '', //交接人id
          fromUserName: '', //交接人中文名
          fromUserNameEn: '', //交接人英文名
          toUserId: '', // 接收人id
          toUserName: '', // 接收人中文名
          toUserNameEn: '', // 接收人英文名
          status: '', // 状态（1：处理中，2：已完成，3：转让异常）
          creatorId: '', // 创建人id
          creatorName: '', // 创建人名称
          creatorNameEn: '', // 创建人英文名称
          createTime: [], //创建时间
        },
        extraHeight: 152, //额外高度
        searchFormConfig: {
          //表单配置
          labelPosition: 'right',
          defaultExpand: false, //默认展开
          formItemList: [
            {
              type: 'userselectWithTab',
              name: 'fromUserId',
              prop: 'fromUserId',
              label: '交接人',
              placeholder: '请选择',
              chooseUser: (user) => this.chooseUser('fromUser', user),
              defaultUserKey: 'fromUser',
            },
            {
              type: 'userselectWithTab',
              name: 'toUserId',
              prop: 'toUserId',
              label: '接收人',
              placeholder: '请选择',
              chooseUser: (user) => this.chooseUser('toUser', user),
              defaultUserKey: 'toUser',
            },

            {
              type: 'select',
              name: 'status',
              prop: 'status',
              label: '状态',
              placeholder: '请选择',
              optList: [
                { label: '处理中', value: 1 },
                { label: '已完成', value: 2 },
                { label: '处理异常', value: 3 },
              ],
            },
            {
              type: 'userselect',
              name: 'creatorId',
              prop: 'creatorId',
              label: '创建人',
              placeholder: '请选择',
              chooseUser: (user) => this.chooseUser('creator', user),
              defaultUserKey: 'creator',
            },

            {
              type: 'daterange',
              name: 'createTime',
              prop: 'createTime',
              label: '创建日期',
              placeholder: '请选择',
            },
          ],
          operate: [
            {
              type: 'primary',
              name: '搜索',
              handleClick: this.resetPageSearch,
            },
            {
              type: 'plain',
              name: '重置',
              handleClick: this.onReset,
            },
          ],
        },

        tableData: [], // 列表数据
        page: {
          //分页数据
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        columns: [
          // 列表列
          {
            label: '交接人',
            prop: 'fromUserName',
            isShow: true,
            checkable: false,
          },

          {
            label: '接收人',
            prop: 'toUserName',
            isShow: true,
            checkable: true,
          },

          {
            label: '交接业务量',
            prop: 'handleNum',
            isShow: true,
            checkable: true,
          },
          {
            label: '状态',
            prop: 'statusName',
            isShow: true,
            checkable: true,
          },
          {
            label: '创建人',
            prop: 'creator',
            isShow: true,
            checkable: true,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: true,
            checkable: true,
          },
        ],
        detailVisible: false, //详情控制参数
        isDetail: true, //是否是新增
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    activated() {
      this.onSearch()
    },

    methods: {
      // 新增或详情
      showDetail(row) {
        this.$router.push({
          path: '/system-manage/business-transfer/transfer-new',
          query: this._handleQuery(row),
        })
      },

      // 处理路由query
      _handleQuery(row) {
        let query = {}
        if (row) {
          query.isDetail = true
          query.businessTransferId = row.businessTransferId
          query.title = '详情'
        } else {
          query.title = '新增'
        }
        return query
      },

      // 分页处理
      onPagination({ page, limit }) {
        this.page.pageNo = page
        this.page.pageLe = limit
        this.onSearch()
      },

      // 重置pageNo
      resetPageSearch() {
        this.page.pageNo = 1
        this.onSearch()
      },

      // 搜索
      async onSearch() {
        const data = await systemInteractor.getBusinessTransferList({
          ...this.page,
          ...this.filterForm,
          tenantId: this.userInfo.tenantId,
        })
        this.tableData = data.data || []
        this.page.total = data.total
      },

      // 搜索表单重置
      onReset() {
        this.filterForm = this.$options.data().filterForm
        this.$refs.searchForm.resetUserSelect()
        this.resetPageSearch()
      },

      // 人员选择
      chooseUser(userType, user) {
        const form = this.filterForm
        form[userType + 'Id'] = user ? user.userId : ''
        form[userType + 'Name'] = user ? user.userName : ''
        form[userType + 'NameEn'] = user ? user.userNameEn : ''
      },

      // 开始转交事件
      transferSubmit() {
        this.$refs.transferDetail.submitTransferData()
      },

      // 重新执行
      async retry({ businessTransferId }) {
        let res = await systemInteractor.retryTransfer(businessTransferId)
        if (res) {
          this.onSearch()
          return this.$message.success('重新执行成功')
        }
        this.$message.warning('重新执行失败')
      },
    },
  }
</script>

<style scoped lang="scss">
  .custom-container {
    overflow-y: hidden !important;
    position: relative;
  }
</style>
