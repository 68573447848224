<template>
  <div class="book-box">
    <div>
      <LatestContacts
        ref="LatestContacts"
        @getContactDetail="getContactDetail"
      />
    </div>
    <div>
      <BookDetail v-show="showDetail" ref="BookDetail" />
      <div v-show="!showDetail" class="no-book">
        <div>
          <img src="../../../assets/mail_images/person.png" alt="" />
          <!-- <p>未选择联系人</p> -->
          <p>{{ $t('other.nocontactselected') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LatestContacts from './components/LatestContacts.vue'
import BookDetail from './components/BookDetail.vue'
export default {
  components: {
    LatestContacts,
    BookDetail,
  },
  data() {
    return {
      showDetail: false,
    }
  },
  methods: {
    getContactDetail(v, data) {
      this.showDetail = true
      this.$nextTick(function () {
        this.$refs.BookDetail.initData(v, data)
      })
    },
  },
  mounted() {
    if (this.$route.query.mail !== undefined) {
      this.$refs.LatestContacts.setCurrent(this.$route.query.mail)
      this.getContactDetail({ contactEmail: this.$route.query.mail })
    }
  },
}
</script>

<style lang="scss" scoped>
.book-box {
  display: flex;
  height: 100%;
  & > div:first-child {
    width: 25%;
    min-width: 380px;
    height: 100%;
    flex-shrink: 0;
  }
  & > div:last-child {
    flex: 1;
    width: 0;
    height: 100%;
    .no-book {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background: #fff;
      border-left: 1px solid #ebeef5;
      position: relative;
      & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        img {
          width: 100px;
        }
        p {
          margin-top: 30px;
          color: #777777;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
