import request from '@/core/services/request'

// 公海客户页面

//分页列表查询公海客户
export function listHighSeasPage(data) {
  return request({
    url: '/merchants/info/listHighSeasPage',
    method: 'post',
    data,
  })
}

//认领设置新僧
export function claimSetting(data) {
  return request({
    url: '/merchants/claim/insertPO',
    method: 'post',
    data,
  })
}

//认领设置回显
export function claimSettingEdit(data) {
  return request({
    url: '/merchants/claim/getDetailForEdit',
    method: 'post',
    data,
  })
}

//认领
export function customerClaim(data) {
  return request({
    url: '/merchants/info/customerClaim',
    method: 'post',
    data,
  })
}
