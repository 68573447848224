var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container custom-padding"},[_c('el-page-header',{attrs:{"content":"库位设置"},on:{"back":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"loc-tree-wrapper"},[_c('el-tree',{ref:"tree",attrs:{"data":_vm.locs,"props":_vm.defaultProps,"default-expand-all":"","highlight-current":"","filter-node-method":_vm.filterNodeMethod,"expand-on-click-node":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('p',{staticClass:"node-content"},[_c('i',{class:[
              'mr5',
              data.locationLevel !== 0
                ? 'el-icon-location-outline'
                : 'el-icon-house' ]}),_c('el-tooltip',{attrs:{"content":node.label,"disabled":node.label.length < 60}},[_c('span',{staticClass:"node-label text-ellipsis"},[_vm._v(_vm._s(node.label))])])],1),_c('span',[_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function () { return _vm.append('SAME_LEVEL', node, data); }}},[_vm._v(" 平级 ")]),(_vm.showAddChild(node))?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function () { return _vm.append('CHILD_LEVEL', node, data); }}},[_vm._v(" 子级 ")]):_vm._e(),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function () { return _vm.edit(node, data); }}},[_vm._v(" 编辑 ")]),_c('el-button',{staticClass:"red",attrs:{"type":"text","size":"mini"},on:{"click":function () { return _vm.remove(node, data); }}},[_vm._v(" 删除 ")])],1)])}}])})],1),_c('el-row',{staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onConfirm}},[_vm._v("确认")]),_c('el-button',{on:{"click":_vm.onCancel}},[_vm._v("取消")])],1),_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.centerDialogVisible,"close-on-click-modal":false,"width":"400px","center":""},on:{"update:visible":function($event){_vm.centerDialogVisible=$event}}},[_c('div',[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"label-position":"left","prop":"locationNumber","rules":[
            {
              required: true,
              message: '请输入',
            } ]}},[_c('el-input',{attrs:{"maxlength":"50","placeholder":"请输入"},model:{value:(_vm.form.locationNumber),callback:function ($$v) {_vm.$set(_vm.form, "locationNumber", $$v)},expression:"form.locationNumber"}})],1)],1),_c('div',{staticClass:"btn-wrappper"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveLoc}},[_vm._v("确认")]),_c('el-button',{on:{"click":_vm.cancelLoc}},[_vm._v("取消")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }