<template>
  <div class="mt20">
    <ErpTable
      :page-number.sync="pageNumber"
      :page-size.sync="pageSize"
      :table-data="tableData"
      :total="total"
      :extraHeight="30"
      class="table-fixed"
      @query="getList"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
        :class-name="item.prop"
      >
        <template #default="{ row }">
          <div
            class="page-link"
            v-if="item.prop === 'accessoriesNumber'"
            @click="getDetail(row)"
          >
            <span>
              {{ row.accessoriesNumber }}
            </span>
          </div>
          <div v-if="item.prop === 'productImg'">
            <erp-image :imgs="row.productImg"></erp-image>
          </div>

          <div v-if="item.prop === 'nameCn'">
            {{ row.nameCn }}
          </div>
          <div v-if="item.prop === 'nameEn'">
            {{ row.nameEn | emptyFilter }}
          </div>
          <div v-if="item.prop === 'productUnit'">
            {{ row.productUnit | emptyFilter }}
          </div>
          <div v-if="item.prop === 'costPrice'">
            {{ row.costPrice | emptyFilter }}
          </div>
          <div v-if="item.prop === 'size'">
            <span
              v-if="
                row.productLengthCm == 0 &&
                row.productWidthCm == 0 &&
                row.productHeightCm == 0
              "
            >
              -
            </span>
            <span v-else>
              {{ row.productLengthCm | emptyFilter }} X
              {{ row.productWidthCm | emptyFilter }} X
              {{ row.productHeightCm | emptyFilter }}
            </span>
          </div>
          <div v-if="item.prop === 'productWeightKgs'">
            {{ row.productWeightKgs | emptyFilter }}
          </div>
          <div v-if="item.prop === 'numberOfRelatedProducts'">
            <el-popover placement="bottom" width="500" trigger="click">
              <el-table
                :data="row.relatedProductsList"
                border
                :cell-style="{ 'text-align': 'center' }"
                :header-row-style="{ 'text-align': 'center' }"
              >
                <el-table-column
                  width="100"
                  label="产品图"
                  prop="productSpecsImg"
                >
                  <template slot-scope="scope">
                    <erp-image :imgs="scope.row.productSpecsImg"></erp-image>
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="sku"
                  label="产品编码"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span class="page-link" @click="productDetail(scope.row)">
                      {{ scope.row.sku }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="中文品名/英文品名"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="80"
                  prop="numberOfCombinations"
                  label="组合数量"
                ></el-table-column>
              </el-table>
              <span
                class="page-link"
                style="text-decoration: none"
                slot="reference"
              >
                {{ row.numberOfRelatedProducts || 0 }}
              </span>
            </el-popover>
            <!-- <div v-else>0</div> -->
          </div>
          <div v-if="item.prop === 'stock'">
            <div
              v-if="row.warehouseStockList && row.warehouseStockList.length > 0"
            >
              <p v-for="(item, index) in row.warehouseStockList">
                {{ item.warehouseName }}:{{ item.warehouseNum }}
              </p>
            </div>
            <div v-else>-</div>
          </div>
          <div v-if="item.prop === 'description'">
            {{ row.description | emptyFilter }}
          </div>
          <div v-if="item.prop === 'creatorName'">
            {{ row.creatorName }}
          </div>
          <div v-if="item.prop === 'createTime'">
            {{ row.createTime }}
          </div>
          <div v-if="item.prop === 'updatorName'">
            {{ row.updatorName }}
          </div>
          <div v-if="item.prop === 'updateTime'">
            {{ row.updateTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        width="150"
        fixed="right"
        :resizable="false"
      >
        <template slot-scope="scope">
          <el-tooltip content="编辑" placement="top" v-if="showEdit">
            <el-button
              icon="el-icon-edit"
              type="primary"
              plain
              circle
              @click="edit(scope.row)"
            ></el-button>
          </el-tooltip>
          <div v-else>-</div>
        </template>
      </el-table-column>
    </ErpTable>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    finallyColumns: {
      default: () => [],
      type: Array,
    },
    tableData: {
      default: () => [],
      type: Array,
    },
    pageNo: {
      default: 1,
      type: Number,
      require: true,
    },
    pageLe: {
      default: 10,
      type: Number,
      require: true,
    },
    total: {
      default: 0,
      type: Number,
    },
  },
  filters: {
    emptyFilter(val) {
      if (!val) {
        return '-'
      } else {
        return val
      }
    },
  },
  watch: {
    pageLe() {},
    immediate: true,
    deep: true,
  },
  data() {
    return {
      pageNumber: this.$parent.page.pageNo,
      pageSize: this.$parent.page.pageLe,
    }
  },
  computed: {
    ...mapGetters({
      perssion: 'user/permissions',
    }),
    // 辅料编辑权限 MATERIAL:Edit
    showEdit() {
      return this.perssion?.indexOf('MATERIAL:Edit') > 0
    },
    // pageNumber() {
    //   return this.$parent.page.pageNo
    // },
    // pageSize() {
    //   return this.$parent.page.pageLe
    // },
  },
  watch: {
    pageNo: {
      handler(val) {
        this.pageNumber = val
      },
      deep: true,
    },
    pageLe: {
      handler(val) {
        this.pageSize = val
      },
      deep: true,
    },
  },
  methods: {
    getList() {
      this.$emit('getList', this.pageNumber, this.pageSize)
    },
    getDetail(val) {
      this.$emit('getDetail', val)
    },
    edit(val) {
      this.$emit('edit', val)
    },
    productDetail(val) {
      this.$emit('productDetail', val)
    },
  },
}
</script>
