<template>
  <div class="table-container" ref="category">
    <el-row class="page-header align_center pub-h-36" type="flex">
      <el-button icon="el-icon-plus" type="primary" @click="addClick('top')" class="pub-h-36">
        新增顶级类别
      </el-button>
      <!-- 查询表单 -->
      <el-form ref="form" class="ml10" :inline="true" :model="queryForm" label-width="49px" @submit.native.prevent>
        <el-form-item>
          <el-input v-model="queryForm.name" placeholder="请输入名称" @keyup.enter.native="fetchData" clearable
            @input="searchInput" class="pub-h-36" />
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="handleSearch" class="pub-h-36">
        搜索
      </el-button>
    </el-row>

    <!-- 表格 -->
    <div class="main-table mt20">
      <el-row :style="{ height: `${maxHeight}px` }" class="mt10">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" border row-key="productCategoryId"
          ref="testTable" class="test-table">
          <el-table-column prop="nameCn" label="中文名称" align="center"></el-table-column>
          <el-table-column prop="nameEn" label="英文名称" align="center"></el-table-column>
          <el-table-column prop="specsNames" label="可选规格" align="center"></el-table-column>
          <el-table-column prop="platformFlagsName" label="上架平台" align="center"></el-table-column>
          <el-table-column prop="sageCategoryName" label="SAGE类目" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template slot-scope="scope">
              <el-tooltip content="新增下级" placement="top">
                <el-button type="primary" plain icon="el-icon-plus" circle size="mini"
                  @click="addClick('addChild', scope.row)"
                  v-if="scope.row.level === 0 || scope.row.level === 1"></el-button>
              </el-tooltip>

              <el-tooltip content="编辑" placement="top">
                <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
                  @click="editClick('editChild', scope.row)"></el-button>
              </el-tooltip>

              <el-tooltip content="删除" placement="top">
                <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                  @click="delClick(scope.row)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <Pagination v-if="paginations" :limit.sync="queryForm.pageLe" :page.sync="queryForm.pageNo" :total="total"
        @pagination="fetchData" />
    </div>
    <!-- 新增编辑弹框 -->
    <CategoryModel ref="categoryModel" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { listPage, deletePO } from '@/api/product/catesManager'
  import Pagination from '@/components/Pagination/Index'
  import CategoryModel from './components/CategoryModel'
  import { platformDs } from '@/utils/dicts'
  export default {
    name: 'CatesManager',
    data() {
      const platformObj = platformDs.reduce((obj, item) => {
        if (item.value !== 'SAGE') obj[item.value] = item.label
        return obj
      }, {})
      return {
        tableData: [],
        // 总行数
        total: 0,
        queryForm: {
          name: '',
          pageLe: 10,
          pageNo: 1,
        },
        paginations: false,
        maxHeight: 0,

        showStyle: false,
        platformObj, // {RP: 'RP商城', RG: 'RG商城', SW: 'SW商城'}
      }
    },
    watch: {
      tableData: {
        immediate: true,
        deep: true,
        handler: function (val) {
          if (!val) return
          if (this.total > 0) {
            this.paginations = true
          } else {
            this.paginations = false
          }
          this.maxHeight = 0
          this.initTableStyle()
        },
      },
    },
    components: {
      Pagination,
      CategoryModel,
    },
    created() {
      this.fetchData()
    },
    methods: {
      initTableStyle() {
        this.$nextTick(() => {
          const baseTableEle = this.$refs['category']
          // 有分页则减去分页器的高度
          const maxHeight = this.paginations
            ? baseTableEle.offsetHeight - 134.5
            : baseTableEle.offsetHeight
          this.maxHeight = maxHeight
        })
      },
      // 搜索
      handleSearch() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      // 类别列表
      async fetchData(pageData) {
        if(pageData) {
          this.queryForm.pageNo = pageData.page
          this.queryForm.pageLe = pageData.limit
        }
        this.showStyle = false
        let response = await listPage(this.queryForm)
        if (response?.code === '000000') {
          let data = response.data
          // this.tableData = data.data
          const keys = Object.keys(this.platformObj).join()
          this.tableData = this.setParentPlatformFlags(data.data, keys)
          this.total = data.total

          if (this.queryForm.name !== '' && this.tableData.length) {
            this.contraction()
          }
        }
      },
      // 递归
      setParentPlatformFlags(tree, platformFlags) {
        tree = JSON.parse(JSON.stringify(tree))
        for (const item of tree) {
          item.parentPlatformFlags = platformFlags // 设置父级platformFlags
          item.platformFlagsName = this.getNameByCode(item.platformFlags) // 设置platformFlagsName属性，列表返回"RP,RG"，修改为"RP商城, RG商城"展示在列表上
          if (item.children && item.children.length) item.children = this.setParentPlatformFlags(item.children, item.platformFlags)
        }
        return tree
      },
      // 根据platformFlags和字典，获取platformFlagsName
      getNameByCode(platformFlags) {
        const list = platformFlags ? platformFlags.split(',') : []
        const nameList = list.map(n => this.platformObj[n])
        return nameList.join(', ')
      },
      // 新增
      addClick(str, row) {
        if (str === 'top') {
          // 新增一级类别
          const chooseList = Object.keys(this.platformObj)
          row = { chooseList }
        } else {
          // 新增二级/三级类别
          row.chooseList = row.platformFlags ? row.platformFlags.split(',') : []
        }
        this.$refs['categoryModel'].categoryModel(str, row, 'add')
      },
      // 编辑
      editClick(str, row = {}) {
        row.chooseList = row.parentPlatformFlags ? row.parentPlatformFlags.split(',') : []
        this.$refs['categoryModel'].categoryModel(str, row, 'edit')
      },

      //删除
      delClick(row) {
        if (row.productCategoryId) {
          this.$baseConfirm('确定要删除吗', null, async () => {
            // 删除接口
            let response = await deletePO({
              productCategoryId: row.productCategoryId,
            })
            if ((response.code = '000000')) {
              this.$baseMessage(
                '刪除成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
            this.fetchData()
          })
        }
      },
      //如果input为空
      searchInput(val) {
        if (val === '') {
          this.fetchData()
        }
      },
      //树形展开
      contraction(val) {
        if (this.showStyle) {
          this.showStyle = false
          if (this.tableData.length != 0) {
            const elsopen = this.$el.getElementsByClassName(
              'el-table__expand-icon--expanded'
            )
            if (
              this.$el.getElementsByClassName('el-table__expand-icon--expanded')
            ) {
              for (let i = 0; i < elsopen.length; i++) {
                elsopen[i].click()
              }
            }
          }
        } else {
          this.showStyle = true
          let els = document.getElementsByClassName('el-table__expand-icon')
          if (this.tableData.length != 0 && els.length != 0) {
            for (let j1 = 0; j1 < els.length; j1++) {
              els[j1].classList.add('dafult')
            }
            if (
              this.$el.getElementsByClassName('el-table__expand-icon--expanded')
            ) {
              const open = this.$el.getElementsByClassName(
                'el-table__expand-icon--expanded'
              )
              for (let j = 0; j < open.length; j++) {
                open[j].classList.remove('dafult')
              }
              const dafult = this.$el.getElementsByClassName('dafult')
              for (let a = 0; a < dafult.length; a++) {
                dafult[a].click()
              }
            }
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .btn-span {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 26px;
  }

  .main-table {
    >.el-row {
      overflow-y: auto;
    }
  }

  ::v-deep {
    .el-form--inline .el-form-item {
      margin-bottom: 0;
    }

    .el-table__row:not(.el_table__row--level-1) {
      >td:first-child {
        .cell {
          width: 200px;
          text-align: left;
          margin: 0 auto;
          white-space: nowrap;
          // .el-table__indent {
          //   padding-left: px !important;
          // }
        }
      }
    }

    .el-table__row.el-table__row--level-1 {
      .el-table__indent {
        padding-left: 32px !important;
      }
    }

    .el-table__row.el-table__row--level-2 {
      .el-table__indent {
        padding-left: 48px !important;
      }
    }

    .el-dropdown-menu__item {
      width: 80px;
      text-align: center;
    }

    .el-dropdown .el-button-group {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .el-icon-arrow-down {
      font-size: 14px;
    }

    .el-table__expand-icon {
      i {
        font-size: 16px;
        font-family: 'PingFang Bold';
      }
    }

    .el-dropdown-menu__item {
      line-height: 30px !important;

      a {
        width: 100%;
      }
    }

    .el-dropdown {
      .el-button-group {
        .el-button:first-child {
          width: 40px;
          height: 30px;
          padding: 2px;
        }
      }
    }

    .pagination-container {
      padding: 18.5px 10px 9.5px;
    }

    .el-pagination {
      margin-top: 0 !important;
    }
  }
</style>
