<template>
  <div class="youtube-video">
    <div id="youTubeVideoId"></div>
  </div>
</template>
<script>
  import YouTubePlayer from 'youtube-player'
  export default {
    props: {
      playerId: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        default: '450px',
      },
    },
    data() {
      return {
        player: '',
      }
    },
    mounted() {
      this.initPlayer()
    },
    methods: {
      initPlayer() {
        this.player = YouTubePlayer('youTubeVideoId', {
          width: this.width,
          height: this.height,
          videoId: this.playerId,
          autoplay: 1,
        })
        this.player.loadVideoById(this.playerId)
      },
      stopVideo() {
        this.player.stopVideo()
      },
    },
  }
</script>
<style scoped lang="scss"></style>
