var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tpl-new-wrapper"},[_vm._m(0),_c('el-table',{attrs:{"border":"","data":_vm.areaTplData}},[_c('el-table-column',{attrs:{"align":"center","label":"地区","prop":"area"}}),_c('el-table-column',{attrs:{"align":"center","label":"产品类型","prop":"standardCustomized"}}),_c('el-table-column',{attrs:{"align":"center","label":"毛利额","prop":"conditionType"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.conditionType == 1 ? '大于0' : '--')+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"运营成本(%)","prop":"ratio"}})],1),_vm._m(1),_c('el-form',{ref:"form",attrs:{"rules":_vm.rules,"model":_vm.form}},[_c('el-table',{staticClass:"form-rule-table",attrs:{"data":_vm.form.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"label":"员工姓名","prop":"area","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.personnoelCn',"rules":[
              {
                required: true,
                message: '请选择',
                trigger: 'change',
              } ]}},[_c('el-input',{attrs:{"clearable":""},on:{"focus":function($event){return _vm.selUser(scope.row)}},model:{value:(scope.row.personnoelCn),callback:function ($$v) {_vm.$set(scope.row, "personnoelCn", $$v)},expression:"scope.row.personnoelCn"}})],1)]}}])},[_c('template',{slot:"header"},[_c('i',{staticClass:"red"},[_vm._v("*")]),_vm._v(" 员工姓名 ")])],2),_c('el-table-column',{attrs:{"label":"员工条件运营成本(%)","prop":"standardCustomized","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.ratio',"rules":[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              } ]}},[_c('el-input-number',{staticStyle:{"width":"100%"},attrs:{"min":0,"max":100,"controls":false,"precision":2,"clearable":""},model:{value:(scope.row.ratio),callback:function ($$v) {_vm.$set(scope.row, "ratio", $$v)},expression:"scope.row.ratio"}})],1)]}}])},[_c('template',{slot:"header"},[_c('i',{staticClass:"red"},[_vm._v("*")]),_vm._v(" 员工条件运营成本(%) ")])],2),_c('el-table-column',{attrs:{"label":"操作","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(scope.$index)}}},[_vm._v("删除")])]}}])})],1)],1),(!_vm.$route.query.businessId)?_c('el-button',{staticClass:"new-btn",on:{"click":_vm.addLine}},[_vm._v(" 新增一行 ")]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',[_c('span',{staticClass:"vertical"}),_vm._v(" 地区模板配置 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"h3"},[_c('span',{staticClass:"vertical"}),_vm._v(" 人员条件配置 ")])}]

export { render, staticRenderFns }