<template>
  <div class="custom-container">
    <el-row
      class="page-header align_center"
      type="flex"
      justify="space-between"
    >
      <el-col :span="22" class="page-header-left">
        <el-form
          ref="form"
          :inline="true"
          :model="queryForm"
          label-width="49px"
          @submit.native.prevent
        >
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="mr10 pub-h-36"
            @click="addSub"
          >
            新增
          </el-button>
          <el-form-item>
            <el-input
              style="width: 230px"
              v-model="queryForm.queryString"
              placeholder="请输入辅料名称/辅料编码"
              clearable
              @keyup.enter.native="initLoading()"
            />
          </el-form-item>
          <el-button class="mr10" @click="initLoading()">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            搜索
          </el-button>
        </el-form>
      </el-col>
      <el-col :span="2">
        <!-- 自定义显示 -->
        <custom-column
          :columns="columns"
          :dragOptions="dragOptions"
          @operateSave="operateSave"
        ></custom-column>
      </el-col>
    </el-row>
    <sub-list
      :finallyColumns="finallyColumns"
      :tableData="tableData"
      :pageNo.sync="page.pageNo"
      :pageLe.sync="page.pageLe"
      :total="total"
      @getList="getList"
      @getDetail="getDetail"
      @edit="edit"
      :key="customTableKey"
      @productDetail="productDetail"
    ></sub-list>
    <add-sub-product
      ref="addSubProduct"
      @saveSubProduct="saveSubProduct"
      @updateSubProduct="editSubProduct"
    ></add-sub-product>
    <detail-sub-product ref="detailSubProduct"></detail-sub-product>
  </div>
</template>
<script>
  import AddSubProduct from './components/addSubProduct.vue'
  import DetailSubProduct from './components/detailSubProduct.vue'
  import SubList from './components/subList.vue'
  import customColumn from './components/customColumn.vue'
  // import { getColums, operateColums } from '@/utils/columsUtil'
  import CustomCache from '@/utils/custom-cache'

  import { mapGetters } from 'vuex'
  import getColumn from './util/column'
  import { productIdByCode } from '@/api/product/productList'
  import {
    subProductList,
    addSubProduct,
    updateSubProduct,
  } from '@/api/product/productList'
  import customTableMixin from '@/utils/custom-table-mixin'
  export default {
    name: 'subProductList',
    mixins: [customTableMixin],
    components: {
      AddSubProduct,
      SubList,
      customColumn,
      DetailSubProduct,
    },
    data() {
      return {
        queryForm: {
          queryString: '',
        },
        columns: [],
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        tableData: [],
        userCustomizeColumnId: '',
      }
    },
    created() {
      this.columns = [...getColumn]
      this.getList()
      CustomCache.getColumns(3, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })
    },
    computed: {
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    methods: {
      initLoading() {
        this.page.pageNo = 1
        this.page.pageLe = 10

        this.getList(1, 10)
      },
      getList(pageNo = 1, pageLe = 10) {
        this.page.pageNo = pageNo
        this.page.pageLe = pageLe

        let data = {
          ...this.queryForm,
          ...this.page,
          orderBy: 'create_time desc',
        }
        subProductList(data).then((res) => {
          this.tableData = res?.data?.list.map((item) => {
            return {
              ...item,
              numberOfRelatedProducts: item.relatedProductsList?.length,
            }
          })
          this.total = res?.data?.total
        })
      },
      // 新增
      addSub() {
        this.$refs.addSubProduct.dialogFormVisible = true
        this.$refs.addSubProduct.title = '新增辅料'
      },
      // 新增保存
      saveSubProduct(form) {
        this.$refs.addSubProduct.loading = true
        addSubProduct(form)
          .then((res) => {
            this.$refs.addSubProduct.loading = false
            if (res?.code === '000000') {
              this.page.pageNo = 1
              this.getList(this.page.pageNo, this.page.pageLe)
              this.$refs.addSubProduct.close()
            }
          })
          .catch((err) => {
            this.$refs.addSubProduct.loading = false
            this.$refs.addSubProduct.dialogFormVisible = false
          })
      },
      // 编辑
      edit(val) {
        this.$refs.addSubProduct.dialogFormVisible = true
        this.$refs.addSubProduct.title = '编辑辅料'
        this.$refs.addSubProduct.getDetail(val)
        // this.$refs.addSubProduct.productId = val.productAccessoriesId
      },
      // 编辑保存
      editSubProduct(form, id) {
        let data = {
          ...form,
          productAccessoriesId: id,
        }
        this.$refs.addSubProduct.loading = true
        updateSubProduct(data)
          .then((res) => {
            this.$refs.addSubProduct.loading = false
            if (res?.code === '000000') {
              this.getList(this.page.pageNo, this.page.pageLe)
              this.$refs.addSubProduct.close()
            }
          })
          .catch((err) => {
            this.$refs.addSubProduct.loading = false
            this.$refs.addSubProduct.dialogFormVisible = false
          })
      },
      //  查看详情
      getDetail(val) {
        this.$refs.detailSubProduct.dialogFormVisible = true
        this.$refs.detailSubProduct.getDetail(val)
      },
      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        let response = await productIdByCode({
          productCode: id,
        })
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },
      // 查看产品详情
      productDetail(val) {
        if (val.sku.indexOf('FL') > 0) return
        this.getProductIdByCode(val.sku && val.sku.split('-')[0])
      },
      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 3,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res?.data?.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
    },
  }
</script>
