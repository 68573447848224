<template>
  <div class="table-container" v-if="$route.meta.showParent">
    <el-row
      class="page-header pub-h-36"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <el-col :span="22">
        <el-row type="flex" align="middle" :gutter="20">
          <el-row class="tab-sapn ml10" type="flex" align="middle">
            <p v-allowed="['All:Clinet']">
              <span
                :class="[tabSpanCurrent === 0 ? 'current' : '']"
                @click="tabClick(0)"
              >
                <!-- 全部客户 -->
                {{ $t('cusManage.AllCustomers') }}
              </span>
            </p>
            <p>
              <span
                :class="[tabSpanCurrent === 1 ? 'current' : '']"
                @click="tabClick(1)"
                style="width: 150px"
              >
                <!-- 我负责的 -->
                {{ $t('cusManage.ImResponsible') }}
              </span>
            </p>
          </el-row>
          <el-row v-show="!selectRows.length" class="ml20">
            <el-button
              icon="el-icon-plus"
              type="primary"
              @click="addClick"
              class="pub-h-36"
              v-allowed="['Add:Client']"
            >
              <!-- 新增 -->
              {{ $t('cusManage.Add') }}
            </el-button>
            <!-- placeholder="请输入客户编码/邮箱/姓名" -->
            <el-input
              v-model="requireForm.customerInfo"
              type="text"
              :placeholder="$t('placeholder.cuscodemailname')"
              clearable
              class="width-200 ml10"
            ></el-input>
            <el-button class="ml10 pub-h-36" @click="searchClick">
              <!-- 搜索 -->
              {{ $t('cusManage.Search') }}
            </el-button>
            <el-button @click="productFilterClick" class="pub-h-36">
              <!-- 高级筛选 -->
              {{ $t('cusManage.AdvancedFilter') }}
            </el-button>

            <el-button
              class="ml10 pub-h-36"
              type="primary"
              plain
              @click="throwPublicSeaClick"
            >
              <!-- 转入公海 -->
              {{ $t('cusManage.ThrownBackIntoTheOpenSea') }}
            </el-button>
            <el-button
              class="ml10 pub-h-36"
              type="primary"
              plain
              @click="transferClick"
            >
              <!-- 转让 -->
              {{ $t('cusManage.Transfer') }}
            </el-button>
            <el-button
              class="ml10 pub-h-36"
              type="primary"
              plain
              @click="assignClick('top')"
            >
              <!-- 分管 -->
              {{ $t('cusManage.InCharge') }}
            </el-button>
            <el-button
              class="ml10 pub-h-36"
              type="primary"
              plain
              @click="addBlacklist()"
            >
              <!-- 加入黑名单 -->
              {{ $t('cusManage.AddToBlacklist') }}
            </el-button>
          </el-row>
          <el-row
            v-show="selectRows.length"
            type="flex"
            align="middle"
            class="ml20"
          >
            <p>
              <!-- 已选择  -->
              {{ lang === 'en' ? 'Selected' : '已选择' }}
              <strong class="f_s_18 f_w_7">{{ selectRows.length }}</strong>
              <!-- 个客户 -->
              {{ lang === 'en' ? 'Customers' : '个客户' }}
            </p>
            <el-button
              v-show="isThrow"
              class="ml10 pub-h-36"
              type="primary"
              plain
              @click="throwPublicSeaClick"
            >
              <!-- 转入公海 -->
              {{ $t('cusManage.ThrownBackIntoTheOpenSea') }}
            </el-button>
            <el-button
              v-show="isTransfer"
              class="ml10 pub-h-36"
              type="primary"
              plain
              @click="transferClick"
            >
              <!-- 转让 -->
              {{ $t('cusManage.Transfer') }}
            </el-button>
            <el-button
              v-show="isCharge"
              class="ml10 pub-h-36"
              type="primary"
              plain
              @click="assignClick('top')"
            >
              <!-- 分管 -->
              {{ $t('cusManage.InCharge') }}
            </el-button>
            <el-button
              class="ml10 pub-h-36"
              type="primary"
              plain
              @click="addBlacklist()"
            >
              <!-- 加入黑名单 -->
              {{ $t('cusManage.AddToBlacklist') }}
            </el-button>
          </el-row>
        </el-row>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-popover
          ref="showOption"
          popper-class="custom-table-checkbox"
          trigger="hover"
        >
          <erp-draggable v-bind="dragOptions" :list="columns">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="item + index"
              v-model="item.isShow"
              :disabled="item.checkable === false"
              :label="item.label"
            >
              {{ $t(item.langKey) }}
            </el-checkbox>
          </erp-draggable>
          <!-- 保存自定义显示列 -->
          <div class="save-btn">
            <el-button
              type="primary"
              class="ml10"
              size="mini"
              plain
              @click="operateSave"
            >
              <!-- 保存 -->
              {{ $t('cusManage.Save') }}
            </el-button>
          </div>
          <template #reference>
            <!-- content="列表可拖动排序" -->
            <el-tooltip
              effect="dark"
              :content="$t('iconbtn.Listdraggablesort')"
              placement="top-start"
            >
              <el-button plain type="primary" class="pub-h-36">
                <!-- 自定义显示 -->
                {{ $t('cusManage.CustomDisplay') }}
              </el-button>
            </el-tooltip>
          </template>
        </el-popover>
      </el-col>
    </el-row>

    <!-- table -->
    <el-row class="mt20 main-height">
      <el-row class="table-height-box">
        <ErpTable
          :page-number.sync="pageNo"
          :page-size.sync="pageLe"
          :table-data="tableData"
          :total="total"
          @query="refreshData"
          class="table-fixed"
          @selection-change="setSelectRows"
          :key="customTableKey"
        >
          <el-table-column align="center" type="selection" width="55" />
          <!-- :render-header="renderHeader" -->
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            :label="$t(item.langKey)"
            show-overflow-tooltip
            :sortable="item.sortable"
            :resizable="false"
            align="center"
            :min-width="lang === 'en' ? item.enWidth : item.width"
            :prop="item.prop"
          >
            <template #default="{ row }">
              <!-- 客户编码 -->
              <span
                v-if="item.prop === 'customerCode'"
                class="c_pointer prod-code"
                @click="customerCodeClick(row.infoId, 'show', row.editCustomer)"
              >
                {{ row.customerCode }}
              </span>
              <!-- 客户邮箱 -->

              <el-row v-else-if="item.prop === 'email'" class="text-ellipsis">
                <span
                  v-if="permissionsTab('Email:Record')"
                  class="c_pointer prod-code"
                  @click="emailClick(row.email)"
                >
                  {{ row.email }}
                </span>
                <span v-else>{{ row.email }}</span>
              </el-row>

              <!-- 公司名称 -->
              <el-row
                v-else-if="item.prop === 'companyName'"
                class="text-ellipsis"
              >
                <span
                  v-if="permissionsTab('Company:Msg')"
                  class="c_pointer prod-code"
                  @click="companyClick(row)"
                >
                  {{ row.companyName }}
                </span>
                <span v-else>{{ row.companyName }}</span>
              </el-row>
              <!-- 联系人，负责人 -->
              <el-row
                v-else-if="item.prop === 'belongerName'"
                class="text-ellipsis"
              >
                <span>{{ getName(row, 'belongerName') }}</span>
              </el-row>

              <el-row
                v-else-if="item.prop === 'customerSourceName'"
                class="text-ellipsis"
              >
                <span>{{ getName(row, 'customerSourceName') || '--' }}</span>
              </el-row>

              <!--创建人-->
              <el-row
                v-else-if="item.prop === 'creatorName'"
                class="text-ellipsis"
              >
                <span>
                  {{ getName(row, 'creatorName') }}
                </span>
              </el-row>

              <!-- 客户类型 -->
              <el-row v-else-if="item.prop === 'customerType'">
                <el-tooltip
                  :content="'原因：' + row.blackRemark"
                  placement="top"
                  v-if="row.customerType === 2"
                >
                  <span class="c_pointer">
                    {{ customerTypeStatus(row.customerType) }}
                  </span>
                </el-tooltip>
                <span v-else>{{ customerTypeStatus(row.customerType) }}</span>
              </el-row>
              <!-- 客户等级 -->
              <el-rate
                v-else-if="item.prop === 'customerRank'"
                v-model="row.customerRank"
                disabled
                text-color="#ff9900"
              ></el-rate>

              <!-- 其他 -->
              <span v-else>{{ row[item.prop] || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('cusManage.Operate')"
            width="150"
            fixed="right"
            :resizable="false"
          >
            <template slot-scope="scope">
              <!-- 潜在客户与正式客户都可以编辑 -->
              <el-row v-if="scope.row.customerType !== 2">
                <!-- 跟进 -->
                <!-- content="跟进" -->
                <el-tooltip :content="$t('cusManage.FollowUp')" placement="top">
                  <el-button
                    type="primary"
                    plain
                    circle
                    size="mini"
                    @click="clientFollowClick(scope.row)"
                  >
                    <i class="iconfont f_s_12">&#xe661;</i>
                  </el-button>
                </el-tooltip>
                <!-- 编辑 -->
                <!-- content="编辑" -->
                <el-tooltip :content="$t('cusManage.Edit')" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    v-if="
                      scope.row.editCustomer === '1' || tabSpanCurrent === 1
                    "
                    @click="editClick(scope.row.infoId, 'edit')"
                  ></el-button>
                </el-tooltip>
                <!-- content="分管" -->
                <el-tooltip
                  :content="$t('cusManage.InCharge')"
                  placement="top"
                  v-if="tabSpanCurrent === 1"
                >
                  <el-button
                    type="primary"
                    plain
                    circle
                    size="mini"
                    @click="assignClick('single', scope.row.infoId)"
                  >
                    <i class="iconfont icon-manage-circuit f_s_12"></i>
                  </el-button>
                </el-tooltip>
              </el-row>
              <el-row v-else>--</el-row>
              <!-- 分管 -->
            </template>
          </el-table-column>
        </ErpTable>
      </el-row>
    </el-row>

    <!-- 新增编辑客户弹框 -->
    <ClientManageAddModel ref="ClientManageAdd" @fetch-data="refreshData" />

    <!-- 高级筛选Drawer -->
    <!-- title="高级筛选" -->
    <el-drawer
      :visible.sync="drawer"
      :before-="seniorSearchClose"
      :title="$t('cusManage.AdvancedFilter')"
    >
      <ClientManageSearch
        ref="ClientManageSearch"
        @cancelClick="seniorSearchClose"
        @searchClick="searchClick2"
      />
    </el-drawer>

    <!-- 加入黑名单 -->
    <!-- title="加入黑名单" -->
    <el-dialog
      :title="$t('cusManage.AddToBlacklist')"
      :visible="blackVisible"
      append-to-body
      width="500px"
      @close="onCancel"
      modal-append-to-body
      class="dialog-fixed"
    >
      <el-row type="flex">
        <el-form :model="blackForm" style="width: 100%" ref="blackForm">
          <!-- label="原因" -->
          <el-form-item
            :label="$t('cusManage.Reason')"
            prop="remarks"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              type="textarea"
              :placeholder="$t('placeholder.plsInput')"
              maxlength="500"
              show-word-limit
              v-model="blackForm.remarks"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <div class="footer mt30 t_a_c">
        <!-- <el-button type="primary" @click="cancelSure">确 认</el-button>
        <el-button @click="onCancel">取 消</el-button> -->
        <el-button type="primary" :loading="loading" @click="cancelSure">
          {{ $t('cusManage.Confirm') }}
        </el-button>
        <el-button @click="onCancel">{{ $t('cusManage.Cancel') }}</el-button>
      </div>
    </el-dialog>

    <!-- 公司信息drawer -->
    <el-drawer :visible.sync="drawer2" :before-close="closeClick" :size="size">
      <template slot="title">
        <el-row type="flex" align="middle">
          <p class="c-666">
            <!-- 公司详情 -->
            {{ $t('companydetail.CompanyDetails') }}
          </p>
          <span class="ml10 f_s_12" style="margin-top: 2px">
            <!-- 公司名称： -->
            {{ $t('companydetail.CompanyName') }}:
            {{ drawer2Title }}
          </span>
        </el-row>
      </template>
      <ClientManageCompanyMsg
        ref="ClientManageCompanyMsg"
        :companyId="companyId"
        @closeClick="closeClick"
      />
    </el-drawer>

    <!-- 客户跟进 -->
    <ClientManagefollowModel
      ref="ClientManagefollowModel"
      @fetch-data="refreshData"
    />

    <!-- 转让分管 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import ErpDraggable from 'vuedraggable'
  import BaseTable from '@/components/baseTable'
  import ClientManageAddModel from './components/client-manage-add-model'
  import ClientManageSearch from './components/client-manage-search'
  import ClientManageCompanyMsg from './components/client-manage-company-msg'
  import {
    clentListPage,
    listTakeCustomerPage,
    throwBackHighSeas,
    customerTransfer,
    customerBranched,
    customerBlackList,
  } from '@/api/client-supplier/client-manage'
  import ClientManagefollowModel from './components/client-manage-follow-model'
  import UserChoose from '@/components/userChoose'
  import { customerTypeStatusLang } from '@/utils/en-match-zh'
  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  import nameMixin from '@/utils/name-mixin'
  import customTableMixin from '@/utils/custom-table-mixin'
  export default {
    name: 'ClientMange',
    mixins: [nameMixin, customTableMixin],
    components: {
      BaseTable,
      ErpDraggable,
      ClientManageAddModel,
      ClientManageSearch,
      ClientManageCompanyMsg,
      ClientManagefollowModel,
      UserChoose,
    },
    data() {
      return {
        loading: false,
        // tabSpan: [{ name: '全部客户' }, { name: '我负责的' }],
        tabSpan: [{ name: '全部客户' }, { name: '我负责的' }],
        tabSpanCurrent: 1,
        activeName: 'first',
        requireForm: {
          customerInfo: '',
        }, //请求参数
        tableData: [], // 表格数据
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数,
        tableKey: 0,
        columns: [
          //table列字段
          {
            label: '客户编码',
            prop: 'customerCode',
            isShow: true,
            checkable: false,
            width: 130,
            enWidth: 130,
            langKey: 'cusManage.CustomerCode',
          },
          {
            label: '客户邮箱',
            prop: 'email',
            isShow: true,
            checkable: false,
            width: 160,
            enWidth: 180,
            langKey: 'cusManage.CustomerMailbox',
          },
          {
            label: '公司名称',
            prop: 'companyName',
            isShow: true,
            checkable: false,
            width: 160,
            enWidth: 180,
            langKey: 'cusManage.CompanyName',
          },
          {
            label: '客户姓名',
            prop: 'linkman',
            isShow: true,
            checkable: false,
            width: 160,
            enWidth: 180,
            langKey: 'cusManage.CustomerName',
          },

          {
            label: '联系人',
            prop: 'linkman',
            isShow: false,
            width: 160,
            enWidth: 180,
            langKey: 'cusManage.Contacts',
          },
          {
            label: '联系电话',
            prop: 'phone',
            isShow: true,
            checkable: false,
            width: 160,
            enWidth: 180,
            langKey: 'cusManage.ContactNumber',
          },
          {
            label: '客户来源',
            prop: 'customerSourceName',
            isShow: true,
            width: 120,
            enWidth: 180,
            langKey: 'cusManage.CustomerSource',
          },
          {
            label: '客户类型',
            prop: 'customerType',
            isShow: true,
            width: 160,
            enWidth: 180,
            langKey: 'cusManage.CustomerType',
          },
          {
            label: '客户等级',
            prop: 'customerRank',
            isShow: true,
            width: 160,
            enWidth: 180,
            langKey: 'cusManage.CustomerLevel',
          },
          {
            label: '订单成交量',
            prop: 'tradingVolume',
            isShow: false,
            width: 160,
            enWidth: 200,
            langKey: 'cusManage.OrderVolume',
          },
          {
            label: '订单总金额',
            prop: 'tradingMoney',
            isShow: false,
            width: 160,
            enWidth: 200,
            langKey: 'cusManage.TotalOrderAmount',
          },
          {
            label: '负责人',
            prop: 'belongerName',
            isShow: true,
            width: 160,
            enWidth: 180,
            langKey: 'cusManage.ResponsiblePerson',
          },
          {
            label: '回归公海剩余时间',
            prop: 'timeRemaining',
            isShow: true,
            width: 150,
            enWidth: 300,
            langKey: 'cusManage.ReturnToTheHighSeasRemainingTime',
          },
          {
            label: '创建人',
            prop: 'creatorName',
            isShow: false,
            width: 150,
            enWidth: 180,
            langKey: 'cusManage.Creator',
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: false,
            width: 150,
            enWidth: 160,
            langKey: 'cusManage.CreationTime',
          },
        ],
        userCustomizeColumnId: '', //自义定保存id
        selectRows: [], //表格勾选的数量
        isRequesting: false,
        stars: 5,
        drawer: false, //高级筛选抽屉
        drawer2: false, //公司信息抽屉
        drawer2Title: '', //公司名称
        size: '900px',
        auditManageTags: [],
        currentStr: '',
        companyId: '', //公司id，用于调用公司详情接口
        assignStr: '', //公司
        infoId: '',
        isThrow: true, //是否可以进行仍回公海操作
        isTransfer: true, //是否可以进行转让操作
        isCharge: true, //是否可以进行分管操作
        blackVisible: false,
        blackForm: {
          remarks: '', //黑名单理由
        },
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
        lang: 'settings/language',
      }),

      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    created() {
      //获取自定义保存
      CustomCache.getColumns(5, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })
    },
    activated() {
      this.refreshData()
    },
    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.refreshData()
        }
      },
      finallyColumns: {
        deep: true,
        handler: function (val) {
          this.tableKey = Math.random()
        },
      },
    },

    methods: {
      // tabs权限控制
      permissionsTab(tab) {
        return this.permissions.some((item) => item == tab)
      },
      //tab切换
      tabClick(index) {
        this.tabSpanCurrent = index
        this.pageNo = 1
        this.requireForm = {}
        if (index === 1) {
          this.myfetchData()
          this.columns[11].isShow = false
          this.columns[11].checkable = false
        } else {
          this.fetchData()
          this.columns[11].checkable = true
          this.columns[11].isShow = true
        }
      },

      // 获取客户全部列表数据
      async fetchData() {
        this.tableData = []
        let response = await clentListPage({
          ...this.requireForm,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
        })
        this.tableData = response.data.data
        this.total = response.data.total
      },

      //获取我负责的列表数据
      async myfetchData() {
        this.tableData = []
        let response = await listTakeCustomerPage({
          ...this.requireForm,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
        })
        this.tableData = response.data.data
        this.total = response.data.total
      },

      //刷新列表
      refreshData() {
        if (this.tabSpanCurrent === 1) {
          this.myfetchData()
        } else {
          this.fetchData()
        }
      },

      //礽回公海
      async throwPublicSeaClick() {
        if (this.selectRows.length === 0) {
          return this.$baseMessage(
            // '请先选择客户',
            this.$t('reqmsg.pleaseselectacustomerfirst'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        let selectRows = this.selectRows
        let paramsArr = selectRows.map((item) => {
          return item.infoId
        })

        this.$confirm(
          // '是否确认将该客户转入公海？',
          this.$t('reqmsg.$17'),
          // '提示',
          this.$t('other.info'),
          {
            confirmButtonText: this.$t('other.confirm'),
            cancelButtonText: this.$t('other.cancel'),
            type: 'warning',
          }
        ).then(async () => {
          let response = await throwBackHighSeas({ infoId: paramsArr })
          if (response.code === '000000') {
            this.$baseMessage(
              // '操作成功',
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
            this.refreshData()
          }
        })
      },
      //转让弹框
      async transferClick() {
        this.auditManageTags = []
        if (this.selectRows.length === 0) {
          return this.$baseMessage(
            // '请先选择客户',
            this.$t('reqmsg.pleaseselectacustomerfirst'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.currentStr = 'belongerUser'
        this.$refs['UserChoose'].showAddEdit(
          'belongerUser',
          this.auditManageTags
        )
      },
      //转让接口
      async transfer(userArr) {
        let selectInfoId = this.selectRows.map((item) => {
          return item.infoId
        })
        let userId = [userArr[0].id]
        let params = {
          infoId: selectInfoId,
          userIds: userId,
        }
        let response = await customerTransfer(params)
        if (response.code === '000000' && response.data === 1) {
          this.$baseMessage(
            // '转让成功',
            this.$t('reqmsg.$18'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.refreshData()
        } else {
          this.$baseMessage(
            // '转让失败',
            this.$t('reqmsg.$19'),
            'error',
            false,
            'erp-hey-message-error'
          )
        }
      },
      //分管弹框
      async assignClick(str, id) {
        this.auditManageTags = []
        if (str === 'top' && !this.selectRows.length) {
          return this.$baseMessage(
            // '请先选择客户',
            this.$t('reqmsg.pleaseselectacustomerfirst'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.currentStr = 'assign'
        this.assignStr = str
        this.infoId = id
        this.$refs['UserChoose'].showAddEdit('assign', this.auditManageTags)
      },
      //分管返回
      async assign(userArr) {
        let selectInfoId = []
        if (this.assignStr === 'single') {
          selectInfoId.push(this.infoId)
        } else {
          selectInfoId = this.selectRows.map((item) => {
            return item.infoId
          })
        }

        let selectUserId = userArr.map((item) => {
          return item.id
        })
        let params = {
          infoId: selectInfoId,
          userIds: selectUserId,
        }
        let response = await customerBranched(params)
        if (response.code === '000000' && response.data >= 1) {
          this.$baseMessage(
            // '分管成功',
            this.$t('reqmsg.$20'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.refreshData()
        }
      },
      //组织架构返回
      chooseUser(userArr) {
        if (this.currentStr === 'belongerUser') {
          this.transfer(userArr)
        }
        if (this.currentStr === 'assign') {
          this.assign(userArr)
        }
      },

      // 搜索
      searchClick() {
        this.pageNo = 1
        this.refreshData()
      },

      //加入黑名单
      addBlacklist() {
        if (!this.selectRows.length) {
          return this.$baseMessage(
            // '请先选择要操作的客户',
            this.$t('reqmsg.pleaseselectacustomerfirst'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.blackVisible = true
      },
      async cancelSure() {
        let infoId = []
        this.selectRows.forEach((ele) => {
          infoId.push(ele.infoId)
        })
        let params = {
          infoId: infoId.join(','),
          remarks: this.blackForm.remarks,
        }

        this.$refs['blackForm'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            let response = await customerBlackList(params)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                // '操作成功',
                this.$t('reqmsg.$7'),
                'success',
                false,
                'erp-hey-message-success'
              )
              this.blackVisible = false
              this.refreshData()
            }
          }
        })
      },
      onCancel() {
        this.$refs['blackForm'].resetFields()
        this.blackVisible = false
      },

      //邮件跳转
      emailClick(email) {
        let routeData = this.$router.resolve({
          name: 'MailReceiveAll',
          query: { searchValue: email },
        })
        window.open(routeData.href, '_blank')
      },

      //如果input为空
      searchInput(val) {
        if (val === '') {
          this.fetchData()
        }
      },

      //新增编辑客户
      addClick() {
        this.$refs['ClientManageAdd'].showAddEdit()
      },
      //客户编辑(进入详情页编辑)
      editClick(infoId, str) {
        this.$router.push({
          path: '/client-supplier/client-manage-detail',
          query: { customerId: infoId, operate: str },
        })
      },

      // 客户跟进
      clientFollowClick(row) {
        this.$refs['ClientManagefollowModel'].showAddEdit(row.infoId)
      },

      //客户详情
      customerCodeClick(infoId, str, editCustomer) {
        this.$router.push({
          path: '/client-supplier/client-manage-detail',
          query: {
            customerId: infoId,
            operate: str,
            editCustomer: editCustomer,
            tabSpanCurrent: this.tabSpanCurrent,
          },
        })
      },

      //高级筛选
      seniorSearch() {
        this.drawer = true
      },
      // 高级筛选关闭
      seniorSearchClose() {
        this.drawer = false
      },

      //公司名称点击
      companyClick(row) {
        this.drawer2Title = row.companyName
        this.companyId = row.companyId
        if (this.companyId) {
          this.drawer2 = true
        }
      },
      // 公司名称关闭
      closeClick() {
        this.drawer2 = false
      },

      // 高级筛选
      productFilterClick() {
        this.drawer = true
      },
      // 高级筛选关闭
      productFilterClose() {
        this.drawer = false
      },
      // 高级筛选触发
      searchClick2(val) {
        this.pageNo = 1
        let obj = Object.assign(this.requireForm, val)
        this.requireForm = obj
        this.refreshData()
        this.productFilterClose()
      },

      //自定义显示保存
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 5,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              // '操作成功',
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
        //操作他人权限seasCustomer，自己的客户——仍回公海
        this.isThrow = this.selectRows.every(
          (item) => item.seasCustomer === '1'
        )
        //操作他人权限transferCustomer，自己的客户——转让
        this.isTransfer = this.selectRows.every(
          (item) => item.transferCustomer === '1'
        )
        //操作他人权限customerCharge,自己的客户-分管
        this.isCharge = this.selectRows.every(
          (item) => item.customerCharge === '1'
        )
      },
      //客户类型匹配
      customerTypeStatus(val) {
        return this.$t(customerTypeStatusLang(val))
      },
    },
  }
</script>

<style scoped lang="scss">
  .main-height {
    height: calc(100% - 56px);
  }
  .tab-sapn {
    p {
      flex: 1;
      text-align: center;
      span {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        padding: 0 10px;
        text-align: center;
        cursor: pointer;
      }
      .current {
        color: #409eff;
        border-bottom: 2px solid #409eff;
      }
    }
  }

  .text-ellipsis {
    width: 100%;
    span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  //自定义显示
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }
  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }
  .width-200 {
    width: 200px;
  }
  // 编码、公司名称
  .prod-code {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
  .black-span {
    white-space: nowrap;
    margin-right: 10px;
  }
</style>
