<!--
  功能：首页已签收邮件内容
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年05月12日 13:12:22
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div
    class="mail-content"
    style="padding-bottom: 100px"
    :style="styles.baseStyle"
  >
    <p>Hello,</p>
    <br />

    <p style="line-height: 24px">
      Our tracking
      {{
        config.shipNos && config.shipNos.length > 0
          ? config.shipNos.map((item) => item.shipNo).join('/')
          : '--'
      }}
      shows that your order {{ config.poNumber }}/{{ config.piNumber }}
      has been delivered. Please follow up with your customer and share their
      feedback with us, thank you.
    </p>
    <br />

    <p style="line-height: 24px">
      lf you happened to meet with an issue, please contact us for immediate
      assistance!
    </p>
    <br />

    <p style="line-height: 24px">
      {{ config.sendEmail }}-{{ config.receiveEmail }}
    </p>
    <p style="line-height: 24px">Tel: 323-282-3208</p>
    <br />

    <p>Thank you!</p>

    <p v-if="config.exportsRise === 'RP'">Rivers Promo Inc</p>
    <p v-else-if="config.exportsRise === 'RG'">Rivers Garment Inc</p>
    <p v-else="config.exportsRise === 'SW'">Slight World</p>
  </div>
</template>

<script>
  function createStyle() {
    return {
      baseStyle: {
        // color: 'rgb(51, 51, 51)',
        fontSize: '14px',
      },
    }
  }

  export default {
    name: 'MailContent',

    data() {
      return {
        styles: createStyle(),
      }
    },
  }
</script>
