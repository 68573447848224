<template>
  <!-- 报价 -->
  <el-dialog
    :title="$t('inquiryList.Quote')"
    width="1200px"
    :visible.sync="addOfferVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-row id="pdfDom">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <div class="offer-container">
          <!-- 基本信息 -->
          <h4>{{ $t('addInquiry.BaseInfo') }}</h4>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                class="label-left"
                label="Zip code"
                prop="zipCode"
                label-width="80px"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'change',
                  },
                ]"
              >
                <el-input
                  :placeholder="$t('placeholder.plsInput')"
                  v-model="form.zipCode"
                  clearable
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="Vaildity for"
                prop="validityDate"
                label-width="130px"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'change',
                  },
                  {
                    pattern: /^[0-9]+?$/,
                    message: $t('placeholder.integer'),
                    trigger: 'change',
                  },
                ]"
              >
                <el-input
                  maxlength="10"
                  :placeholder="$t('placeholder.plsInput')"
                  v-model="form.validityDate"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="product-container">
            <!-- 产品列表 -->
            <h4 class="mt5">{{ $t('addInquiry.ProductList') }}</h4>
            <div
              class="mb10"
              v-for="(item, i) in form.orderPriceSheetDTO"
              :key="i"
            >
              <el-table
                class="dyn-table"
                border
                :data="form.orderPriceSheetDTO[i]"
                :span-method="objectSpanMethod"
                :header-cell-style="{ textAlign: 'center' }"
                :header-cell-class-name="star"
              >
                <el-table-column label="Item" width="240" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.sku || scope.row.productCode">
                      <span
                        class="page-link"
                        @click="goProduct(scope.row.productId)"
                      >
                        {{ scope.row.sku || scope.row.productCode }}
                      </span>
                      <span v-show="scope.row.productEn || scope.row.productCn">
                        /{{ scope.row.productEn || scope.row.productCn }}
                      </span>
                      <span v-show="scope.row.specification">
                        /{{ scope.row.specification }}
                      </span>
                    </div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>

                <el-table-column label="Qty" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.amount'
                      "
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'change',
                        },
                        {
                          pattern: /^[0-9]+?$/,
                          message: $t('placeholder.integer'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="10"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].amount
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Net price($)" width="170">
                  <template slot-scope="scope">
                    <div class="net-price">
                      <el-button
                        class="icon-b"
                        v-show="scope.row.newFlag == 1"
                        @click="
                          calculatePrice(i, scope.$index, scope.row.amount)
                        "
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                      <el-form-item
                        :prop="
                          'orderPriceSheetDTO.' +
                          i +
                          '.' +
                          scope.$index +
                          '.netPrice'
                        "
                        :rules="[
                          {
                            required: true,
                            pattern: /^\d+(\.\d{1,3})?$/,
                            message: $t('placeholder.decimals'),
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          maxlength="20"
                          v-model="
                            form.orderPriceSheetDTO[i][scope.$index].netPrice
                          "
                          :class="scope.row.newFlag == 1 ? 'input-style' : ''"
                          :placeholder="$t('placeholder.plsInput')"
                          @input="calculateTotal(i, scope.$index)"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Set up Charge($)" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.setUpCharge'
                      "
                      :rules="[
                        {
                          required: false,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: $t('placeholder.decimals'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].setUpCharge
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        @input="calculateTotal(i, scope.$index)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Shiping Method"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="
                        scope.row.newFlag == 0 && scope.row.transportationType
                      "
                    >
                      {{ scope.row.transportationType }}
                    </div>
                    <div v-else>
                      <el-form-item>
                        <el-select
                          v-model="
                            form.orderPriceSheetDTO[i][scope.$index]
                              .shippingMethodId
                          "
                          @change="shippingMethodChange(i, scope.$index)"
                          :placeholder="$t('placeholder.plsSel')"
                          clearable
                        >
                          <!-- <el-option
                            v-for="item in transpWayList"
                            :key="item.value"
                            :label="lang === 'en' ? item.nameEn : item.nameCn"
                            :value="item.value"
                          /> -->
                          <el-option
                            v-for="item in transpWayList"
                            :key="item.value"
                            :label="item.nameCn"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Shipping Fee($)" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.shippingFee'
                      "
                      :rules="[
                        {
                          required: false,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: $t('placeholder.decimals'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].shippingFee
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        @input="calculateTotal(i, scope.$index)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Other Fee($)" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.otherFee'
                      "
                      :rules="[
                        {
                          required: false,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: $t('placeholder.decimals'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].otherFee
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        @input="calculateTotal(i, scope.$index)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Costs that" width="240">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.costsThat'
                      "
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="500"
                        show-word-limit
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].costsThat
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Production time" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.productionTime'
                      "
                      :rules="[
                        {
                          max: 50,
                          message: $t('placeholder.max50'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index]
                            .productionTime
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Sample time" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.sampleTime'
                      "
                      :rules="[
                        {
                          max: 50,
                          message: $t('placeholder.max50'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].sampleTime
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Shipping time" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.shippingTime'
                      "
                      :rules="[
                        {
                          max: 50,
                          message: $t('placeholder.max50'),
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].shippingTime
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Description" width="240">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.productDescription'
                      "
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="500"
                        show-word-limit
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index]
                            .productDescription
                        "
                        :placeholder="$t('placeholder.plsInput')"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Total Cost"
                  width="150"
                  fixed="right"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span class="green">
                      ${{
                        form.orderPriceSheetDTO[i][scope.$index].totalPrice || 0
                      }}
                    </span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                  :label="$t('addInquiry.Operate')"
                  width="80"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      class="icon-r"
                      icon="el-icon-delete"
                      circle
                      @click="del(i, scope.$index)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="add">
                <!-- 复制一条 -->
                <span @click="add(i)">{{ $t('inquiryDetails.CopyOne') }}</span>
              </div>

              <el-row :gutter="20">
                <el-col :span="12">
                  <h4>Virtual confirm</h4>
                  <erp-upload-images
                    :limit="9"
                    :tips-visible="false"
                    @change="changeUploadList($event, i)"
                  ></erp-upload-images>
                </el-col>
                <el-col :span="12">
                  <h4>Remarks</h4>
                  <el-form-item prop="remark" clearable v-if="!printBol">
                    <el-input
                      type="textarea"
                      :rows="4"
                      v-model="form.orderPriceSheetDTO[i][0].remark"
                      maxlength="500"
                      show-word-limit
                      :placeholder="$t('placeholder.plsInput')"
                    ></el-input>
                  </el-form-item>
                  <div v-if="printBol">
                    <div v-text="form.orderPriceSheetDTO[i][0].remark"></div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-form>
    </el-row>
    <div class="wrapper-mail mt10">
      <!-- 发送邮件给客户 -->
      <el-checkbox v-model="sendMailToCustomer">
        {{ $t('inquiryDetails.SendMailToCustomers') }}
      </el-checkbox>
    </div>

    <div class="text-center mt20">
      <!-- 预览 -->
      <el-button type="info" @click="preview()">
        {{ $t('inquiryDetails.Preview') }}
      </el-button>
      <!-- 确认 -->
      <el-button type="primary" @click="save()">
        {{ $t('inquiryDetails.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="close()">
        {{ $t('inquiryDetails.Cancel') }}
      </el-button>
    </div>

    <!-- 计算net Price弹窗 -->
    <calculate-price
      ref="calculatePrice"
      @calcNetPriche="calcNetPriche"
    ></calculate-price>
    <div class="pdf-wrapper" id="pdfEl" v-show="isShowPrint">
      <InquiryPrint ref="InquiryPrint" @rendered="domToPdf" />
    </div>
  </el-dialog>
</template>

<script>
  import { transpotwayList } from '@/api/shipment/shipment-plan'
  import {
    orderPriceSheet,
    orderInsertPO,
    orderEnquiryAdd,
  } from '@/api/order.js'
  import calculatePrice from './calculatePrice.vue'
  import { getNowDate } from '@/utils/index'
  import { uploadPdfFile, orderPriceSheetEmail } from '@/api/mail/mail.js'
  import InquiryPrint from '../inquiryPrint.vue'
  export default {
    components: { calculatePrice, InquiryPrint },
    data() {
      return {
        enquiryId: '',
        enquiryCode: '',
        addOfferVisible: false,
        isShowPrint: false,
        form: {
          zipCode: '',
          validityDate: '',
          customerEmail: '',
          orderPriceSheetDTO: [],
        },
        rules: {},
        sendMailToCustomer: true,
        printBol: false,
        transpWayList: [], // 运输方式字典
        isCache: null, // 预览是否走缓存
        previewForm: {},
        previewInfo: {},
        mailDetail: null,
        lang: 'zh',
      }
    },
    created() {
      this.getDict()
    },
    mounted() {
      this.getLang()
    },
    methods: {
      getLang() {
        let language = localStorage.getItem('language')

        if (JSON.parse(language)) {
          this.lang = JSON.parse(language).language
        }
      },

      // 获取运输方式字典
      getDict() {
        transpotwayList().then((res) => {
          this.transpWayList = res?.data?.map((item) => {
            return {
              nameCn: item.transportWayCn,
              nameEn: item.transportWayEn,
              value: item.freightSettingId,
            }
          })
        })
      },

      shippingMethodChange(i, n) {
        let list = this.form.orderPriceSheetDTO[i][n]
        let id = list.shippingMethodId
        this.transpWayList.find((item) => {
          if (item.value == id) {
            // list.transportationType =
            //   this.lang === 'en' ? item.nameEn : item.nameCn
            list.transportationType = item.nameCn
          }
        })
      },

      // 获取报价信息_来自邮件
      getOrderByMail(mail) {
        this.mailDetail = mail
        this.addOfferVisible = true
        let enquiryCode = mail.baseInfo.businessJson
        this.enquiryCode = enquiryCode
        orderPriceSheetEmail(enquiryCode)
          .then((res) => {
            if (res && res.code == '000000') {
              this.form.customerEmail = res.data ? res.data.customerEmail : ''
              this.form.orderPriceSheetDTO = res.data
                ? res.data.orderEnquiryProductVOS
                : []
              this.enquiryId = res.data ? res.data.enquiryId : ''
              this.offerInfoFormat()
              this.isCache = 0
            }
          })
          .catch(() => {
            this.enquiryId = ''
            this.form.customerEmail = ''
            this.form.orderPriceSheetDTO = []
          })
      },

      // 获取报价信息
      getOrderPriceSheet(row, data) {
        this.mailDetail = null
        this.enquiryId = row.enquiryId
        this.enquiryCode = row.enquiryCode
        if (data) {
          // 询盘新增编辑生成报价单入口
          let source = []
          data.products.forEach((item) => {
            source.push([item])
          })
          this.form.customerEmail = data.customerEmail
          this.form.orderPriceSheetDTO = source
          this.offerInfoFormat()
          // 赋值新增编辑询盘预览信息
          this.previewForm = data
          this.isCache = 1
        } else {
          // 询盘列表报价入口
          orderPriceSheet({ enquiryId: row.enquiryId }).then((res) => {
            if (res && res.code == '000000') {
              this.form.customerEmail = res.data ? res.data.customerEmail : ''
              this.form.orderPriceSheetDTO = res.data
                ? res.data.orderEnquiryProductVOS
                : []
              this.offerInfoFormat()
              this.isCache = 0
            }
          })
        }
      },

      // 报价添加保存字段
      offerInfoFormat() {
        if (Array.isArray(this.form.orderPriceSheetDTO)) {
          this.form.orderPriceSheetDTO = this.form.orderPriceSheetDTO.map(
            (list, i) => {
              if (Array.isArray(list)) {
                return list.map((item, k) => {
                  return {
                    ...item,
                    otherFee: '', // Other Fee
                    costsThat: '', // Costs that
                    productionTime: '', // Production time
                    sampleTime: '', // Sample time
                    shippingTime: '', // Shipping time
                    productDescription: item.demand || '', // Description
                    remark: '', // remark
                  }
                })
              } else {
                return list
              }
            }
          )
        }

        this.addOfferVisible = true
      },

      // 计算totalPrice = Qty*netPrice + setUpCharge + shippingFee + otherFee
      calculateTotal(i, n) {
        let list = this.form.orderPriceSheetDTO[i][n]
        if (list.amount && list.netPrice) {
          list.totalPrice = parseFloat(
            Number(list.amount * list.netPrice) +
              Number(list.setUpCharge) +
              Number(list.shippingFee) +
              Number(list.otherFee)
          ).toFixed(3)
        } else {
          list.totalPrice = 0
        }
      },

      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
      },
      //html 转成 PDF
      domToPdf() {
        let title = `quotation-${getNowDate()}`
        this.getPdf(title, 'pdfEl', false).then((res) => {
          //res拿到base64的pdf
          let pdfBase64Str = res
          let title = `quotation-${getNowDate()}`
          var myfile = this.dataURLtoFile(pdfBase64Str, title + '.pdf') //调用一下下面的转文件流函数
          var formdata = new FormData()
          formdata.append('file', myfile) // 文件对象
          formdata.append('flagSaveName', 1)
          formdata.append('source', 1)
          formdata.append('uploadType', 1)
          //该uploadMy为接口，直接以formdata格式传给后台

          uploadPdfFile(formdata)
            .then((res) => {
              let file = {
                enclosureName: res.data.fileName,
                fileId: res.data.fileId,
                enclosureSize: res.data.size,
                enclosureUrl: res.data.path,
                suffix: res.data.suffix,
                uploadType: 1,
                mailEnclosureId: res.data.fileId,
                source: 1,
              }
              let url = ''
              if (this.mailDetail) {
                // 从邮件直接生成报价单
                url = `${window.location.origin}/#/write?from=business&mailId=${
                  this.mailDetail.baseInfo.mailId
                }&file=${JSON.stringify([file])}&email=${
                  this.mailDetail.baseInfo.sendEmail
                }`
              } else {
                url = `${
                  window.location.origin
                }/#/write?from=business&file=${JSON.stringify(file)}&email=${
                  this.form.customerEmail
                }`
              }
              this.utils.openWindow(url)
            })
            .catch((err) => {})
            .finally(() => {
              this.isShowPrint = false
              this.close()
            })
        })
      },

      // 获取隐藏的PDF弹框信息
      generatePdf(id) {
        this.printBol = true
        this.isShowPrint = true
        let data = {
          ...this.form,
          enquiryId: this.enquiryId || id,
          priceSheetCode: this.enquiryCode,
        }
        this.$refs.InquiryPrint.getPreviewPDF(data)
        setTimeout(() => {
          if (id) {
            this.$router.push('/order/inquiryManage/inquiryList')
          } else {
            // this.close()
          }
        }, 1000)
      },

      // 计算单价
      calculatePrice(i, n, amount) {
        if (!Number(amount) > 0 || amount === 0) {
          this.$message.warning(this.$t('other.QtyTips'))
          return false
        }
        this.$refs.calculatePrice.initLoading([i, n, amount])
      },

      // 获取计算的net Price
      calcNetPriche(i, n, netPrice) {
        this.form.orderPriceSheetDTO[i][n].netPrice = netPrice
        this.calculateTotal(i, n)
        this.$refs.ruleForm.clearValidate()
      },

      // 生成订单
      save() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // 遍历赋值rendingsUrl, remark
            self.form.orderPriceSheetDTO.forEach((list) => {
              list.forEach((item) => {
                item.remark = list[0].remark
                item.rendingsUrl = list[0].rendingsUrl
              })
            })
            const flag = self.$route.path.includes('inquiryAdd') ? false : true
            if (flag) {
              // 询盘列表报价
              const params = {
                enquiryId: self.enquiryId,
                ...self.form,
              }
              orderInsertPO(params).then((res) => {
                if (res && res.code == '000000') {
                  self.$message({
                    type: 'success',
                    message: this.$t('reqmsg.G001'),
                  })

                  if (self.sendMailToCustomer) {
                    self.generatePdf()
                  } else {
                    // self.$parent.getList()
                    this.$emit('getList')
                    self.close()
                  }
                }
              })
            } else {
              // 询盘新增报价
              const params = {
                ...self.$parent.form,
                enquiryPriceDTO: self.form,
                buttonType: 1,
              }
              orderEnquiryAdd(params).then((res) => {
                if (res && res.code == '000000') {
                  self.$message({
                    type: 'success',
                    message: this.$t('reqmsg.G001'),
                  })

                  if (self.sendMailToCustomer) {
                    self.generatePdf(res.data.enquiryId)
                  } else {
                    self.$router.push('/order/inquiryManage/inquiryList')
                  }
                }
              })
            }
          } else {
            return false
          }
        })
      },

      // 复制一条
      add(i) {
        const source = this.form.orderPriceSheetDTO[i]
        source.push(JSON.parse(JSON.stringify(source[source.length - 1])))
      },

      // 删除一条
      del(i, n) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          const source = this.form.orderPriceSheetDTO
          if (source.length === 1 && n == 0) {
            this.$message.warning(this.$t('other.keepData'))
            return false
          }
          if (source[i].length === 1) {
            this.form.orderPriceSheetDTO.splice(i, 1)
          } else {
            source[i].splice(n, 1)
          }
        })
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (columnIndex == 1 || columnIndex == 2) {
          return 'star'
        }
      },

      // 合并单元格
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if (rowIndex === 0) {
            return {
              rowspan: 999, //合并的行数 默认给一个上限
              colspan: 1, //合并的列数，设为０则直接不显示
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },

      // 预览
      preview() {
        // 新增编辑预览入口
        if (this.isCache) {
          const form = this.previewForm
          this.previewInfo = {
            exportsRise: form.exportsRise,
            priceSheetCode: form.enquiryCode,
            priceSheetDate: form.quoteDate,
            customerName: form.customerName,
            companyName: form.companyName,
            customerEmail: form.customerEmail,
            customerPhone: form.customerPhone,
            companyAddress: form.companyAddress,
            businessName: form.businessName,
            salesName: form.salesName,
            salesEmail: form.salesEmail,
            zipCode: this.form.zipCode,
            validityDate: this.form.validityDate,
          }
        }
        const data = {
          ...this.form,
          ...this.previewInfo,
          enquiryId: this.enquiryId,
        }
        sessionStorage.setItem('inquiryPrint', JSON.stringify(data))
        window.open(
          `#/order/inquiryManage/inquiryPrint?preview=true&isCache=${this.isCache}`,
          '_blank'
        )
      },

      // 关闭弹窗
      close() {
        this.form = this.$options.data().form
        this.$refs.ruleForm.resetFields()
        this.addOfferVisible = false
      },

      // 上传 rendingsUrl
      changeUploadList(list, index) {
        let _urls = []
        let _len = list.length

        if (_len) {
          for (let i = 0; i < _len; i++) {
            _urls.push(list[i].url)
          }
        }
        this.form.orderPriceSheetDTO[index][0].rendingsUrl = _urls.toString()
      },

      // 跳转到产品
      goProduct(id) {
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId: id,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .offer-container {
    h4 {
      margin-top: 0px;
    }

    .product-container {
      max-height: 420px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 5px;

      .add {
        text-align: center;
        line-height: 30px;
        margin-bottom: 10px;

        span {
          color: #1890ff;
          cursor: pointer;
        }
      }
    }

    .net-price {
      position: relative;

      .el-button {
        position: absolute;
        top: 11px;
        right: -5px;
        z-index: 2;
        height: 28px;
        width: 28px;
        margin: 0;
      }
    }
  }

  ::v-deep {
    .net-price .el-icon-edit {
      margin-top: -2px;
      text-indent: -2px;
    }

    .el-upload--picture-card {
      width: 88px;
      height: 88px;

      i {
        line-height: 88px;
        display: block;
      }
    }

    .el-upload-list__item {
      width: 88px;
      height: 88px;
    }

    .input-style {
      width: 120px;
    }
  }

  .pdf-wrapper {
    position: fixed;
    // left: 50%;
    // top: 50%;
    // transform: translate3d(-50%, -50%, 0);
    left: 110vw;
    top: 110vh;
    width: 1400px;
    height: 100vh;
    z-index: 9999;
    background-color: #fff;
  }
</style>
