<template>
  <div>
    <el-table
      ref="table"
      :data="tabledata"
      align="center"
      border
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      @selection-change="tableSelect"
      @expand-change="changeTable"
    >
      <el-table-column type="selection" width="55">
        <template slot-scope="scoped">
          <div
            v-show="
              scoped.row.mainFlag == 1 && scoped.row.splitFlag == 1
                ? false
                : true
            "
          >
            <el-checkbox
              v-model="scoped.row.checked"
              :disabled="scoped.row.disabled"
              @change="changeParentSelect(scoped.row)"
            ></el-checkbox>
          </div>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="55">
        <template
          slot-scope="sub"
          v-if="sub.row.mainFlag == 1 && sub.row.splitFlag == 1"
        >
          <el-table
            :data="sub.row.childProduct"
            :ref="sub.row.id"
            align="center"
            :show-header="sub.row.mainFlag == 1 && sub.row.splitFlag == 1"
            border
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column width="54" header-align="center"></el-table-column>
            <el-table-column width="55">
              <template slot-scope="scoped">
                <el-checkbox
                  v-model="scoped.row.checked"
                  @change="changeChildStatus(sub.row)"
                  :disabled="scoped.row.disabled"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="productImg" label="效果图" width="120">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  class="el-tag-other"
                  v-if="scope.row.sampleFlag"
                >
                  {{ $t('AddOrder.PrenatalSample') }}
                </el-tag>
                <div v-if="scope.row.productImg">
                  <erp-image :imgs="scope.row.productImg"></erp-image>
                  <!--mainFlag 是否主产品 0 否 1.是 -->
                  <popover-ship
                    :showTable="false"
                    :itemTitle="
                      scope.row.mainFlag === 1 && scope.row.splitFlag == 1
                        ? '主'
                        : scope.row.mainFlag == 0
                        ? '子'
                        : ''
                    "
                  ></popover-ship>
                </div>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="sku" label="SKU ID" width="120">
              <template slot-scope="scope">
                <div class="page-link">
                  {{ scope.row.sku | emptyFilter }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="中文品名/英文品名"
              width="249px"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.productCn | emptyFilter }}/
                  {{ scope.row.productEn | emptyFilter }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="规格">
              <template slot-scope="scope">
                {{ scope.row.productSpecs || '--' }}
              </template>
            </el-table-column>
            <el-table-column label="产品材质">
              <template slot-scope="scope">
                {{ scope.row.productMaterial || '--' }}
              </template>
            </el-table-column>
            <el-table-column label="可用库存">
              <template slot-scope="scope">
                <div
                  v-for="(s, index) in scope.row
                    .shippingPlanOrderProductStockVOS"
                  :key="index"
                >
                  {{ s.storageName }}:{{ s.stockNum }}
                </div>
                <div v-if="scope.row.shippingPlanOrderProductStockVOS == 0">
                  --
                </div>
              </template>
            </el-table-column>
            <!-- totalNum -->
            <el-table-column prop="totalNum" label="订单数量"></el-table-column>
            <el-table-column
              prop="unShippedNum"
              label="待出运数量"
            ></el-table-column>
            <el-table-column prop="caseNum" label="总箱数"></el-table-column>
            <el-table-column
              prop="grossWeight"
              label="总重量"
            ></el-table-column>
            <el-table-column prop="volume" label="总体积"></el-table-column>
            <el-table-column
              prop="productOperation"
              width="140"
              label=" 产品运营"
            ></el-table-column>
            <el-table-column prop="address" label="唛头">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="showMarkDetail(scope.row)"
                >
                  点击查看
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="productImg" label="效果图" width="120">
        <template slot-scope="scope">
          <el-tag size="mini" class="el-tag-other" v-if="scope.row.sampleFlag">
            {{ $t('AddOrder.PrenatalSample') }}
          </el-tag>
          <div v-if="scope.row.productImg">
            <erp-image :imgs="scope.row.productImg"></erp-image>
            <!--mainFlag 是否主产品 0 否 1.是 -->
            <popover-ship
              :showTable="false"
              :itemTitle="
                scope.row.mainFlag === 1 && scope.row.splitFlag == 1
                  ? '主'
                  : scope.row.mainFlag == 0
                  ? '子'
                  : ''
              "
            ></popover-ship>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="sku" label="SKU ID" width="120">
        <template slot-scope="scope">
          <div class="page-link" @click="showProDetail(scope.row)">
            {{ scope.row.sku | emptyFilter }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="中文报关名/英文报关名"
        width="250px"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>
            {{ (scope.row.declareCn || scope.row.productCn) | emptyFilter }}/
            {{ (scope.row.declareEn || scope.row.productEn) | emptyFilter }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="productSpecs" label="规格">
        <template slot-scope="scope">
          <span>{{ scope.row.productSpecs | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productMaterial" label="产品材质">
        <template slot-scope="scope">
          <span>{{ scope.row.productMaterial | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="可用库存">
        <template slot-scope="scope">
          <div
            v-for="(s, index) in scope.row.shippingPlanOrderProductStockVOS"
            :key="index"
          >
            {{ s.storageName }}:{{ s.stockNum }}
          </div>
          <div v-if="scope.row.shippingPlanOrderProductStockVOS == 0">--</div>
        </template>
      </el-table-column>
      <el-table-column prop="totalNum" label="订单数量">
        <template slot-scope="scope">
          <span>{{ scope.row.totalNum | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="unShippedNum" label="待出运数量">
        <template slot-scope="scope">
          <span>{{ scope.row.unShippedNum | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="caseNum" label="总箱数">
        <template slot-scope="scope">
          <span>{{ scope.row.caseNum | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="grossWeight" label="总重量">
        <template slot-scope="scope">
          <span>{{ scope.row.grossWeight | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="volume" label="总体积">
        <template slot-scope="scope">
          <span>{{ scope.row.volume | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="productOperation"
        width="140"
        label=" 产品运营"
      ></el-table-column>
      <el-table-column prop="address" label="唛头">
        <template slot-scope="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="showMarkDetail(scope.row)"
          >
            点击查看
          </el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { val } from 'cheerio/lib/api/attributes'
  import popoverShip from './popoverShip.vue'
  export default {
    name: 'plan-detail-table',
    components: {
      popoverShip,
    },
    data() {
      return {
        tabledata: [],
        selectAll: [],
        parentProductSelect: [],
        childProductSelect: [],
      }
    },
    props: {
      tableList: {
        default: () => [],
        type: Array,
      },
    },
    filters: {
      emptyFilter(val) {
        if (!val && val !== 0) {
          return '--'
        } else {
          return val
        }
      },
    },
    created() {
      this.tabledata = [...this.tableList]
      this.tabledata = this.tabledata.map((item) => {
        // checkFlag  2：只能勾选子产品 1：只能勾选主产品；0：随便勾"
        if (item.checkFlag == 1) {
          item.childProduct = item.childProduct?.map((ele) => {
            return {
              ...ele,
              disabled: true,
            }
          })
        }

        return {
          ...item,
          checked: false,
          disabled: item.checkFlag == 2 ? true : false,
        }
      })
    },
    methods: {
      showMarkDetail(val) {
        this.$emit('showMarkDetail', val)
      },
      showProDetail(val) {
        if (val.sku.indexOf('FL') > -1) return
        this.$emit('showProDetail', val.productId)
      },
      /*
      要求：选中父产品，子产品也全部被选中
            子产品被全选，父产品不会被选中
            选中父产品，子产品也全部被选中，取消一个子产品时，父产品不被选中
    */
      tableSelect(val) {
        this.resetChecked()
        // 选中父产品，子产品也全部被选中
        if (val.length > 0) {
          this.selectAll = [...val]
          val?.forEach((item) => {
            this.$set(item, 'checked', true)
            // item.checked = true
            item?.childProduct?.forEach((ele) => {
              this.$set(ele, 'checked', true)
              // ele.checked = true
            })
          })
        }
      },
      // 父产品全部选中，子产品也全部选中
      changeParentSelect(val) {
        this.$refs.table.toggleRowExpansion(this.tabledata, true)
        val?.childProduct?.forEach((item) => {
          this.$set(item, 'checked', val.checked)
          // item.checked = val.checked
        })
      },
      changeChildStatus(val) {
        let state = val.childProduct.every((item) => {
          return item.checked
        })
        if (!state) {
          this.$set(val, 'checked', false)
          // val.checked = false
        }
      },
      //重置所有勾选
      resetChecked() {
        this.tabledata.forEach((item) => {
          this.$set(item, 'checked', false)
          // item.checked = false
          item?.childProduct?.forEach((ele) => {
            this.$set(ele, 'checked', false)
            // ele.checked = false
          })
        })
      },
      changeTable(row, expanded) {
        if (row.mainFlag == 1 && row.splitFlag == 1) {
          // this.$refs.table.toggleRowExpansion(1, expanded)
        } else {
          this.$refs.table.toggleRowExpansion(row, false)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-table__expanded-cell {
      padding: 0;
    }
    .el-table__body tr {
      min-height: 0px;
    }
    // .expanded + tr {
    //   display: none;
    // }
  }
  .jz {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-tag-other {
    position: absolute;
    right: 4px;
    z-index: 2;
    background-color: #fff7e8;
    border-color: #ffefd1;
    color: #ffae18;
  }
</style>
