<template>
  <div class="process-container" ref="category" v-if="$route.meta.showParent">
    <el-row class="page-header align_center" type="flex">
      <el-col :xs="14" :sm="16" :md="18" :lg="20" :xl="20" :span="20" class="page-header-left">
        <el-row type="flex" class="align_center flex_row_y flex_wrap">
          <el-button icon="el-icon-plus" type="primary" class="mr10" @click="goToPage('add')"
            v-allowed="['AUDIT:SET:ADD']">
            新增
          </el-button>
          <el-form ref="form" :inline="true" :model="queryForm" label-width="49px" @submit.native.prevent>
            <el-select v-model="queryForm.auditType" placeholder="请选择审核类型" class="mr10" clearable
              @change="changeAuditType">
              <el-option v-for="item in auditTypeList" :key="item.auditType" :label="item.auditTypeName"
                :value="item.auditType" />
            </el-select>
            <el-cascader ref="cascaderAddr" :options="organizationOptions" v-model="queryForm.organizationId"
              placeholder="请选择适用组织" :props="optionProps" @change="proCategoryChange()" collapse-tags
              clearable></el-cascader>
          </el-form>
          <el-button @click="changeAuditType" class="ml10 pub-h-36">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            搜索
          </el-button>
        </el-row>
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" class="mt30" border :height="maxHeight">
      <el-table-column prop="auditTypeName" label="审核类型" align="center"></el-table-column>
      <el-table-column prop="name" label="审核名称" align="center"></el-table-column>
      <el-table-column prop="organizationName" label="适用组织" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.organizationName || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="manageName" label="审核流程管理者" align="center"></el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          {{ scope.row.status === 0 ? '使用中' : '停用' }}
        </template>
      </el-table-column>
      <el-table-column prop="updatorName" label="最近更新人" align="center">
        <template slot-scope="scope">
          {{ scope.row.updatorName || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="最近更新时间" align="center"></el-table-column>
      <el-table-column align="center" label="操作" width="150" fixed="right" :resizable="false">
        <template slot-scope="scope">
          <!-- <el-button size="mini" @click="editAudit(scope.row)">编辑</el-button> -->

          <el-tooltip content="编辑" placement="top" v-if="scope.row.status === 0">
            <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
              @click="editAudit(scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip content="停用" placement="top" v-if="scope.row.status === 0">
            <el-button type="danger" plain icon="el-icon-discover" circle size="mini"
              @click="blockUpClick(scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip content="启用" placement="top" v-if="scope.row.status === 1">
            <el-button type="primary" plain icon="el-icon-video-play" circle size="mini"
              @click="startClick(scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip content="删除" placement="top" v-if="scope.row.status === 1">
            <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
              @click="delClick(scope.row)"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <Pagination v-if="paginations" :limit.sync="queryForm.pageLe" :page.sync="queryForm.pageNo" :total="total"
      @pagination="handlePagination" />
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import { listPage, deleteById, deactivate } from '@/api/audit/auditTemplate'
  import { listByTenantId } from '@/api/audit/auditMange'
  import { getListDepartmrnt } from '@/api/organization-manage'
  import { mapGetters } from 'vuex'
  function getCascaderObj(val, opt) {
    return val.map(function (value, index, array) {
      for (var itm of opt) {
        if (itm.id === value) {
          opt = itm.children
          return itm
        }
      }
      return null
    })
  }
  export default {
    name: 'AuditProcessSetting',
    components: { Pagination },
    data() {
      return {
        queryForm: {
          auditType: null,
          orderBy: 'id desc',
          organizationId: '',
          pageLe: 10,
          pageNo: 1,
          tenantId: '',
        },
        total: 0,
        paginations: false,
        maxHeight: 0,
        auditTypeList: [],
        tableData: [],
        categoryOptions: [],
        organizationOptions: [], //组织树
        optionProps: {
          value: 'id',
          label: 'name',
          children: 'children',
          checkStrictly: true,
        },
        loading: false,
      }
    },
    watch: {
      tableData: {
        immediate: true,
        deep: true,
        handler: function (val) {
          if (!val) return
          if (this.total > 0) {
            this.paginations = true
          } else {
            this.paginations = false
          }
          this.maxHeight = 0
          this.initTableStyle()
        },
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },

    created() {
      this.getListByTenantId()
      //获取组织
      this.getListDepartmrntUser()
      this.fetchData()
    },
    methods: {
      //审核下拉
      async getListByTenantId() {
        let response = await listByTenantId({
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
        })
        if (response.code === '000000') {
          this.auditTypeList = response.data
        }
      },
      // 改变审核类型
      changeAuditType() {
        this.queryForm.pageLe = 10
        this.queryForm.pageNo = 1
        this.total = 0
        this.fetchData()
      },
      handlePagination(val) {
        this.queryForm.pageNo = val.page
        this.queryForm.pageLe = val.limit
        this.fetchData()
      },
      // 审核列表
      async fetchData() {
        this.loading = true
        this.queryForm.tenantId = this.userInfo.tenantId
        let response = await listPage(this.queryForm)
        if ((response.code = '000000')) {
          this.tableData = response.data.data
          this.paginations = true
          this.total = response.data.total
        }
        this.loading = false
      },
      //组织树下拉
      async getListDepartmrntUser() {
        let response = await getListDepartmrnt()
        if (response.code === '000000') {
          this.organizationOptions = response.data
        }
      },
      proCategoryChange() {
        let test = getCascaderObj(
          this.queryForm.organizationId,
          this.organizationOptions
        )
        let arr = []
        let arrId = []
        test.forEach((item) => {
          arr.push(item.nameCn)
          arrId.push(item.id)
        })
        this.queryForm.organizationId = arrId.slice(-1).join(',')
      },
      //新增
      goToPage() {
        this.$router.push(
          '/audit-process/audit-process-setting/audit-process-setting-add'
        )
      },
      //编辑
      editAudit(row) {
        this.$router.push({
          path: '/audit-process/audit-process-setting-edit',
          query: { auditId: row.auditTemplateId },
        })
      },
      //启用
      startClick(row) {
        this.$confirm('是否确定启用？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await deactivate({
            auditTemplateId: row.auditTemplateId,
            status: 0,
          })
          if (response.code == '000000') {
            this.$baseMessage(
              '已启用',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
          this.changeAuditType()
        })
      },
      //停用
      blockUpClick(row) {
        this.$confirm('是否确定停用？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await deactivate({
            auditTemplateId: row.auditTemplateId,
            status: 1,
          })
          if (response.code == '000000') {
            this.$baseMessage(
              '已停用',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
          this.changeAuditType()
        })
      },
      //删除
      delClick(row) {
        this.$confirm('是否确定删除该模板？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await deleteById({ businessId: row.auditTemplateId })
          if (response.code == '000000') {
            this.$baseMessage(
              '删除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
          this.fetchData()
        })
      },
      initTableStyle() {
        this.$nextTick(() => {
          const baseTableEle = this.$refs['category']
          // 有分页则减去分页器的高度
          if (!baseTableEle) return
          const maxHeight = this.paginations
            ? baseTableEle.offsetHeight - 175
            : baseTableEle.offsetHeight
          this.maxHeight = maxHeight
        })
      },
    },
  }
</script>

<style scoped lang="scss"></style>