<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月19日 15:10:51
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div>
    <div class="top">
      <el-radio-group v-model="radio">
        <el-radio-button label="1">模板配置</el-radio-button>

        <el-radio-button label="2">人员查询</el-radio-button>
        <el-radio-button label="3">客户配置</el-radio-button>
      </el-radio-group>
    </div>
    <!-- 搜索表单 -->
    <div class="track-search">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item>
          <!-- 新增按钮 -->
          <el-button size="mini" type="primary" @click="addNew">新增</el-button>
        </el-form-item>
        <el-form-item label="产品类型" label-width="80">
          <el-select
            v-model="queryForm.standardCustomized"
            clearable
            placeholder="请选择"
            size="medium"
            style="width: 175px"
          >
            <el-option
              v-for="item in productTypes"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态" label-width="80">
          <el-select
            v-model="queryForm.status"
            clearable
            size="medium"
            placeholder="请选择"
            style="width: 175px"
          >
            <el-option
              v-for="item in statusList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="人员" v-if="radio == 2">
          <el-input
            v-model="queryForm.personnoelCn"
            clearable
            @change="onUserChange"
            @focus="selectUser"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 列表 -->
    <div class="table-wrapper">
      <component
        :is="component"
        @template-freeze="freezeTpl"
        :ref="'table' + radio"
        :type="radio"
      ></component>
    </div>
    <!-- 选择模板 -->
    <TplSel ref="tplsel" @tpl-sel="selTpl"></TplSel>
    <UserSel ref="usersel" @user-sel="selUser" />
  </div>
</template>

<script>
import CustomerTable from './operating-cost-config-customer/operating-cost-config-customer-table'
import TplTable from './operating-cost-config-tpl/operating-cost-config-tpl-table'
import TplSel from './operating-cost-config-tpl/operating-cost-config-tpl-sel'
import UserSel from './operating-cost-config-user/operating-cost-config-user-sel'

import UserTable from './operating-cost-config-user/operating-cost-config-user-table'

import { productType } from '@/views/finance-center/config-manage/helper'
import { OperatingCostConfigInteractor } from '@/core/interactors/finance/config-manage'
const configStatus = [
  {
    name: '全部',
    code: '',
  },
  {
    name: '启用',
    code: '0',
  },
  {
    name: '冻结',
    code: '1',
  },
]
productType.unshift({
  name: '全部',
  code: '',
})
export default {
  name: 'OperatingCostConfig',
  props: {},
  components: { TplTable, TplSel, CustomerTable, UserTable, UserSel },
  // 组件状态值
  data() {
    return {
      productTypes: Object.freeze(productType), //产品类型下拉
      statusList: Object.freeze(configStatus),
      queryForm: {
        // 查询表单
        standardCustomized: '', //产品类型
        status: '', //状态
        personnoelId: '',
        personnoelCn: '',
      },
      radio: '1', // 选中的
      showAddEditPage: false, //展示新增边界页面
    }
  },

  computed: {
    component() {
      switch (this.radio) {
        case '1':
          return TplTable
        case '2':
          return UserTable
        case '3':
          return CustomerTable
      }
    },

    tableRef() {
      return `table${this.radio}`
    },
  },
  watch: {
    radio() {
      this.queryForm = this.$options.data().queryForm
    },
  },
  methods: {
    // 获取表格高度
    getTableHeight() {
      const W_H = document.body.getBoundingClientRect().height
      this.tableHeight = W_H - 54 - 180
    },
    onUserChange(val) {
      if (!val) {
        this.queryForm.personnoelId = ''
      }
    },
    selectUser(row) {
      const { personnoelCn, personnoelId } = this.queryForm
      this.$userChoose(this)
        .showPromisedUserAddEdit({
          str: '',
          roleId:
            personnoelId && personnoelCn
              ? [
                  {
                    id: personnoelId,
                    name: personnoelCn,
                  },
                ]
              : '',
          userStatus: 1,
          canSelectZeroUser: false,
          singleSelect: true,
        })
        .then((users) => {
          if (Array.isArray(users) && users.length > 0) {
            const { id, name, englishName } = users[0]
            this.queryForm.personnoelCn = name
            // this.queryForm.personnoelEn = englishName
            this.queryForm.personnoelId = id
          }
        })
    },

    // 搜索
    onSearch() {
      this.$refs[this.tableRef].pageNumber = 1
      this.$refs[this.tableRef].getList()
    },
    // 重置
    reset() {
      this.queryForm = this.$options.data().queryForm
      this.$refs[this.tableRef].resetPage()
      this.onSearch()
    },
    // 新增
    async addNew() {
      switch (this.radio) {
        case '1':
          this.$router.push({
            name: 'operatingCostConfigNew',
            query: {
              type: this.radio,
            },
          })
          return
        case '2': //新增人员配置时，先选择模板
          const res = await OperatingCostConfigInteractor.getTemplates({
            pageNo: 1,
            status: 0,
            pageLe: 99999999,
          })
          if (!res.data || res.data.length === 0) {
            return this.$message.warning('请配置模板')
          }
          this.$refs.tplsel.showTplSel({ tableData: res.data })
          return
        case '3':
          const res2 = await OperatingCostConfigInteractor.getUserList({
            pageNo: 1,
            status: 0,
            pageLe: 99999999,
          })
          if (!res2?.data?.data || res2.data?.data?.length === 0) {
            return this.$message.warning('请配置模板')
          }
          this.$refs.usersel.showUserSel({ tableData: res2.data.data || [] })
          return
      }
    },
    // 模板选中回调事件
    selTpl(tpl) {
      localStorage.setItem('OPERATE_COST_CONFIG_TEMPLATE', JSON.stringify(tpl))
      this.$router.push({
        name: 'operatingCostConfigNew',
        query: {
          type: this.radio,
        },
      })
    },
    // 人员模板选
    selUser(user) {
      localStorage.setItem('OPERATE_COST_CONFIG_USER', JSON.stringify(user))
      this.$router.push({
        name: 'operatingCostConfigNew',
        query: {
          type: this.radio,
        },
      })
    },

    // 冻结模板
    async freezeTpl(item) {
      const type2businessId = {
        1: 'configOperateCostId',
        2: 'configOperatePersonnelId',
        3: 'configOperateCustomId',
      }
      const res = await OperatingCostConfigInteractor.freezeTpl({
        businessId: item[type2businessId[this.radio]],
        status: item.status,
        type: this.radio - 1,
      })
      if (res.code === '000000') {
        this.$message.success(item.status === 0 ? '启用成功' : '冻结成功')
        this.onSearch()
      }
    },

    //初始化radio
    initRadio() {
      const { radio } = this.$route.query
      if (radio) {
        this.radio = radio
      }
    },
  },

  created() {
    this.initRadio()
  },
}
</script>

<style scoped lang="scss">
.top {
  margin-bottom: 20px;
}
.table-wrapper {
  margin-top: 15px;
}
</style>
