<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="1380px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        label-position="top"
      >
        <el-table
          :data="form.tableData"
          border
          stripe
          :key="tableKey"
          max-height="450"
        >
          <el-table-column align="center" width="180" fixed="left">
            <template slot="header">
              <span style="color: red">*</span>
              运输方式
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.shippingMethodId'"
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  v-model="scope.row.shippingMethodId"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in transpotwayList"
                    :key="item.freightSettingId"
                    :label="item.transportWayCn"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="150px" fixed="left">
            <template slot="header">
              <span style="color: red">*</span>
              运单号
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.shipNo'"
                :rules="[
                  {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="scope.row.shipNo"
                  placeholder="请输入"
                  maxlength="50"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="" width="170px" align="center">
            <template slot="header">
              <span style="color: red" v-if="transportType != 'SEA'">*</span>
              预计到达时间
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.arriveTime'"
                :rules="[
                  {
                    required: transportType != 'SEA',
                    message: '请输入',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-date-picker
                  v-model="scope.row.arriveTime"
                  style="width: 150px"
                  type="date"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  placeholder="请选择"
                ></el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="100">
            <template slot="header">
              <span style="color: red" v-if="transportType != 'SEA'">*</span>
              总箱数
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.caseNum'"
                :rules="[
                  {
                    required: transportType != 'SEA',
                    message: '请输入',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input-number
                  v-model="scope.row.caseNum"
                  placeholder="请输入"
                  clearable
                  :controls="false"
                  :precision="0"
                  :min="1"
                  :max="9999999999"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column align="center" width="100" label="总重量">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.grossWeight'"
              >
                <el-input-number
                  v-model="scope.row.grossWeight"
                  placeholder="请输入"
                  clearable
                  :controls="false"
                  :precision="2"
                  :min="1"
                  :max="9999999999.99"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column align="center" width="100">
            <template slot="header">总体积</template>
            <template slot-scope="scope">
              <el-form-item :prop="'tableData.' + scope.$index + '.volume'">
                <el-input-number
                  v-model="scope.row.volume"
                  placeholder="请输入"
                  :controls="false"
                  :precision="2"
                  :max="9999999999.99"
                  clearable
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="装箱尺寸" align="center" width="280px">
            <template slot-scope="scope">
              <el-input
                clearable
                v-model="scope.row.packingSize"
                :maxlength="50"
                placeholder="装箱尺寸及箱数,例如:50x60x60/5箱;"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="发货备注" align="center" width="150px">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  :rows="2"
                  v-model="scope.row.remarks"
                  type="textarea"
                  maxlength="500"
                  placeholder="请输入内容,字数500字以内"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                class="icon-r"
                icon="el-icon-delete"
                circle
                @click="del(scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <el-button class="add-style" @click="addRow">新增一行</el-button>
    </div>

    <template #footer>
      <div class="text-center">
        <el-button type="primary" @click="save">确 定</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'

export default {
  props: {
    transpotwayList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '录入运单号',
    },
  },
  data() {
    return {
      form: {
        tableData: [{}],
      },
      rules: {
        shippingMethodId: [
          {
            required: true,
          },
        ],
      },
      dialogFormVisible: false,
      transportType: '', //SEA运输类型 :海运 EXPRESS:快递
      tableKey: Math.random(),
    }
  },

  methods: {
    getDefaultShippingMethodId(name) {
      const method = this.transpotwayList.find(
        (item) => item.transportWayCn === name
      )
      if (method) {
        this.shippingMethodId = method.id
      } else {
        this.shippingMethodId = ''
      }
    },
    save() {
      this.$refs.form.validate((res) => {
        if (res) {
          // 保存运单号
          const shipNos = this.form.tableData
            .concat(this.delShipNos)
            .map((item) => {
              const { shippingMethod, shippingMethodType } =
                this._getShippingMethod(item)
              item.shippingOrderId = this.shippingOrderId
              item.shippingMethod = shippingMethod
              item.shippingMethodType = shippingMethodType
              return item
            })
            .map((item) => {
              if (this.title === '录入运单号') {
                item.recordType = 1
              } else {
                item.recordType = 2
              }
              return item
            })
          if (!shipNos || shipNos.length === 0) {
            return this.$message.warning('运单号没有变化,无需保存')
          }
          ShipmentMangeInteractor.saveShipNo(shipNos).then((res) => {
            if (res && res.code === '000000') {
              this.$message.success('操作成功')
              this.dialogFormVisible = false
              this.$emit('refresh', this.form.tableData)
            }
          })
        }
      })
    },
    _getShippingMethod(item) {
      const shippingMethod = this.transpotwayList.find(
        (i) => i.id == item.shippingMethodId
      )
      return {
        shippingMethod: shippingMethod ? shippingMethod.transportWayCn : '',
        shippingMethodType: shippingMethod ? shippingMethod.transportType : '',
      }
    },

    showAddEdit(
      { shipNos = [], shippingOrderId, shippingMethod, totalCase },
      type
    ) {
      this.transportType = type
      this.tableKey = Math.random()
      this.delShipNos = []

      this.getDefaultShippingMethodId(shippingMethod)

      if (!shipNos || shipNos.length === 0) {
        shipNos = [
          {
            shippingMethodId: this.shippingMethodId,
            caseNum: totalCase || undefined,
            deleteMark: false,
          },
        ]
      }
      this.form.tableData = shipNos.map((i) => {
        if (i.volume === null) {
          i.volume = undefined
        }
        if (i.deleteMark !== false) {
          i.deleteMark = true
        }
        if (i.grossWeight === null) {
          i.grossWeight = undefined
        }
        if (i.caseNum === null) {
          i.caseNum = undefined
        }
        return i
      })

      this.shippingOrderId = shippingOrderId
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
      this.delShipNos = []
    },

    addRow() {
      this.form.tableData.push({
        shippingMethodId: this.shippingMethodId,
        deleteFlag: false,
      })
    },

    del(i) {
      this._beforeDel()
        .then(() => {
          this.$confirm('是否确认删除?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              let delShipNo = this.form.tableData[i]
              if (delShipNo.deleteMark) {
                this.delShipNos.push({ ...delShipNo, deleteFlag: true })
              }
              this.form.tableData.splice(i, 1)
            })
            .catch((err) => {})
        })
        .catch((msg) => {
          this.$message.warning(msg)
        })
    },
    _beforeDel() {
      if (this.form.tableData.length === 1 && this.title === '录入运单号') {
        return Promise.reject('至少保留一条数据')
      } else {
        return Promise.resolve('CAN_DEL')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px !important;
}
.add-style {
  border: 1px solid #eee;
  border-top: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
::v-deep .el-input-number {
  width: 80px !important;
}
</style>
