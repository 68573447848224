<template>
  <el-row class="freight-settings table-container">
    <el-row
      type="flex"
      class="page-header align_center pub-h-36"
      style="justify-content: space-between"
    >
      <el-form
        ref="form"
        class="ml10"
        :inline="true"
        :model="queryForm"
        label-width="49px"
        @submit.native.prevent
      >
        <el-form-item>
          <el-button
            @click="setFreight"
            icon="el-icon-plus"
            type="primary"
            size="mini"
          >
            新增
          </el-button>
          <el-button
            @click="setFreightPrice"
            v-allowed="['Freight:SetPrice']"
            size="small"
          >
            运费报价设置
          </el-button>
        </el-form-item>

        <el-form-item>
          <el-input
            v-model="queryForm.transportWayName"
            placeholder="请输入运输方式/英文名称"
            @keyup.enter.native="fetchData"
            clearable
            @input="searchInput"
            class="pub-h-36"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.transportType"
            placeholder="运输类型"
            clearable
          >
            <el-option
              v-for="item in transportTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.billingMethod"
            placeholder="计费方式"
            clearable
          >
            <el-option
              v-for="item in billingMethodOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.currency" placeholder="币种" clearable>
            <el-option
              v-for="item in currencyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="search" size="small">搜索</el-button>
        <el-button @click="reset" size="small">重置</el-button>
      </el-form>

      <el-popover
        ref="showOption"
        popper-class="custom-table-checkbox"
        trigger="hover"
      >
        <erp-draggable v-bind="dragOptions" :list="defaultColums">
          <el-checkbox
            v-for="(item, index) in defaultColums"
            :key="item + index"
            v-model="item.isShow"
            :disabled="item.checkable === false"
            :label="item.label"
          >
            {{ item.label }}
          </el-checkbox>
        </erp-draggable>
        <!-- 保存自定义显示列 -->
        <div class="save-btn">
          <el-button
            type="primary"
            class="ml10"
            size="mini"
            plain
            @click="operateSave"
          >
            保存
          </el-button>
        </div>
        <template #reference>
          <!-- 列表可拖动排序 -->
          <el-tooltip
            effect="dark"
            content="列表可拖动排序"
            placement="top-start"
          >
            <el-button plain type="primary" class="pub-h-36">
              自定义显示
            </el-button>
          </el-tooltip>
        </template>
      </el-popover>
    </el-row>
    <!-- table -->
    <div class="main-table mt20 main-height">
      <ErpTable
        :is-requesting="isRequesting"
        :page-number.sync="queryForm.pageNo"
        :page-size.sync="queryForm.pageLe"
        :table-data="tableData"
        :total="total"
        @query="fetchData"
      >
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :label="item.label"
          show-overflow-tooltip
          :sortable="item.sortable"
          :min-width="120"
          :resizable="false"
          align="center"
          :prop="item.prop"
          :width="item.width"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'transportType'">
              {{ scope.row.transportType | transportTypeFilter }}
            </div>
            <div v-else-if="item.prop === 'billingMethod'">
              {{ scope.row.billingMethod | billingMethodFilter }}
            </div>

            <div v-else-if="item.prop === 'status'">
              {{ scope.row.status | statusFilter }}
            </div>
            <div v-else>
              {{ scope.row[item.prop] || '--' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template #default="{ row }">
            <el-tooltip content="编辑" placement="top">
              <el-button
                type="primary"
                plain
                icon="el-icon-edit"
                circle
                size="mini"
                @click="editClick(row.freightSettingId)"
                v-if="row.status === 0"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="查看" placement="top">
              <el-button
                type="primary"
                plain
                icon="el-icon-search"
                circle
                size="mini"
                @click="detailClick(row.freightSettingId)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="停用" placement="top">
              <el-button
                type="danger"
                plain
                icon="el-icon-discover"
                circle
                size="mini"
                v-if="row.status === 0"
                @click="blockUpClick(row.freightSettingId)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </ErpTable>
    </div>

    <!-- 新增编辑 -->
    <FreightModel ref="FreightModel" @fetch-data="fetchData" />
    <!-- 详情 -->
    <FreightDetailModel ref="FreightDetailModel" />
    <!-- 运费报价设置 -->
    <freight-price-model
      :setPriceVisible.sync="setPriceVisible"
    ></freight-price-model>
  </el-row>
</template>

<script>
  import {
    freighListPage,
    freighDeactivate,
  } from '@/api/product/freightSettings'
  import FreightModel from './components/freight-model'
  import FreightDetailModel from './components/freight-detail-model'
  import freightPriceModel from './components/freight-price-model'
  import {
    columns,
    transportType,
    billingMethod,
    currency,
  } from './util/help.js'
  import { mapGetters } from 'vuex'
  import CustomCache from '@/utils/custom-cache'
  import ErpDraggable from 'vuedraggable'
  export default {
    name: 'FreightSettings',
    components: {
      FreightModel,
      FreightDetailModel,
      freightPriceModel,
      ErpDraggable,
    },
    data() {
      return {
        queryForm: {
          transportWayName: '',
          pageLe: 10,
          pageNo: 1,
          billingMethod: '',
          currency: '',
          transportType: '',
        },
        total: 0,
        tableData: [],
        isRequesting: false, // 正在查询
        setPriceVisible: false, // 运费报价设置
        transportTypeOptions: transportType,
        billingMethodOptions: billingMethod,
        currencyOptions: currency,
        defaultColums: columns,
        userCustomizeColumnId: '',
      }
    },
    filters: {
      //运输方式
      transportTypeFilter(status) {
        const transportType = {
          1: '空运',
          2: '海运',
          3: '快递',
        }
        return transportType[status]
      },
      //计费方式
      billingMethodFilter(status) {
        const billingMethod = {
          1: '体积',
          2: 'Max（重量，体积重：体积/6000）',
          3: 'Max（重量，体积重：体积/5000）',
          5: '重量',
        }
        return billingMethod[status]
      },
      //状态
      statusFilter(status) {
        const freightStatus = {
          0: '正常',
          1: '停用',
        }
        return freightStatus[status]
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        settings: 'settings/language',
      }),
      finallyColumns() {
        return this.defaultColums.filter((item) => item.isShow === true)
      },
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      // 运费列表
      async fetchData() {
        let response = await freighListPage(this.queryForm)
        if ((response.code = '000000')) {
          this.tableData = response.data.data
          this.total = response.data.total
        }
      },

      //如果input为空
      searchInput(val) {
        if (val === '') {
          this.fetchData()
        }
      },
      // 查询
      search() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      // 重置
      reset() {
        for (const key in this.queryForm) {
          this.queryForm[key] = ''
        }
        this.queryForm.pageLe = 10
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      //新增
      addClick() {
        this.$refs['FreightModel'].showAddEdit()
      },
      //编辑
      editClick(id) {
        this.$router.push({
          path: '/product/freightSettings/headFreight',
          query: {
            id,
            pageType: 'edit',
          },
        })
      },
      //详情
      detailClick(id) {
        this.$router.push({
          path: '/product/freightSettings/detailFreight',
          query: {
            id,
          },
        })
      },
      //停用
      blockUpClick(id) {
        this.$confirm('是否确认停用，停用后不可恢复？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await freighDeactivate({
            freightSettingId: id,
            status: 0,
          })
          if (response.code == '000000') {
            this.$baseMessage(
              '停用成功',
              'success',
              false,
              'erp-hey-message-success'
            )
            this.fetchData()
          }
        })
      },
      // 新增运费
      setFreight() {
        this.$router.push({
          path: '/product/freightSettings/headFreight',
          query: {
            pageType: 'add',
          },
        })
      },
      // 运费报价设置
      setFreightPrice() {
        this.setPriceVisible = true
      },
      // 获取自定义列
      initGetcolumn() {
        CustomCache.getColumns(15, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.defaultColums = res.columns
          }
        })
      },
      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 15,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            // 操作成功
            return this.$baseMessage(
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
</style>
