<template>
  <div>
    <!-- 查询 -->
    <CompanyDetailSearch :serchForm="serchForm" @serchForm="search" :code="code" v-if="[4, 6].includes(Number(code))">
    </CompanyDetailSearch>
    <ErpTable :page-number.sync="pageNo" :page-size.sync="pageLe" :table-data="tableData" :total="total" @query="query"
      @row-click="rowClick" class="table-fixed" :key="tableKey" ref="erpTable">
      <el-table-column align="center" type="index" width="55" :label="lang === 'en' ? 'No.' : '序号'" />
      <!-- :label="$t(item.langKey)" -->
      <el-table-column v-for="(item, index) in finallyColumn" :key="index + tableKey"
        :label="[10, 11].includes(Number(code)) ? $t(item.langKey) : item.label" show-overflow-tooltip
        :resizable="false" align="center" :min-width="lang === 'en' ? item.enWidth : item.width" :prop="item.prop">
        <template v-if="code == 1 && item.prop == 'totlePrice'" #default="{ row }">
          <span>{{ '$ ' + row.totlePrice || '--' }}</span>
        </template>
        <template #default="{ row }" v-else-if="item.prop == 'linkman'">
          <span style="white-space: pre">{{ row.linkman }}</span>
        </template>
        <template #default="{ row }" v-else-if="item.prop == 'customerName'">
          <span style="white-space: pre">{{ row.customerName }}</span>
        </template>
        <template v-else-if="code == 10 && item.prop == 'creator'" #default="{ row }">
          <span>
            {{ lang === 'zh' ? row.creator || '--' : row.creatorEn || '--' }}
          </span>
        </template>
        <template v-else-if="code == 11 && item.prop == 'creator'" #default="{ row }">
          <span>
            {{ lang === 'zh' ? row.creator || '--' : row.creatorEn || '--' }}
          </span>
        </template>
        <template #default="{ row }" v-else-if="item.prop == 'orderCode' && code == 1">
          <span class="page-link" @click="goOrderDetail(row.orderCode)">
            {{ row.orderCode }}
          </span>
        </template>
        <template #default="{ row }" v-else-if="item.prop == 'businessNumber' && code == 6">
          <span class="page-link" @click="goOrderDetail(row.businessNumber)">
            {{ row.businessNumber }}
          </span>
        </template>
        <template #default="{ row }" v-else-if="item.prop == 'salesOrder' && code == 8">
          <span class="page-link" @click="goOrderDetail(row.salesOrder)">
            {{ row.salesOrder }}
          </span>
        </template>
        <template v-else #default="{ row }">
          <span>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
    </ErpTable>
    <div class="mt20" v-if="[1, 2].includes(Number(code))">
      <detail-statistics :infoId="infoId" :code="code" type="customer"></detail-statistics>
    </div>
    <div class="mt20" v-if="code == 10">
      <ReplayRecord :tableData="currentHistory"></ReplayRecord>
    </div>
  </div>
</template>
<script>
  import {
    EvaluateColumns,
    orderColumns,
    inquiryColumns,
    quoteColumns,
    productColumns,
    shipmentColumns,
    followRecordColumns,
    surplusColumns,
    customerGradeColumns,
    remarksColumns,
    quoteSearch,
    customerShipmentSearch,
  } from '../helper/detailBaseCompany'
  import { mapGetters } from 'vuex'
  import CompanyDetailSearch from './company-detail-search.vue'
  import CompanyDetailListInteractor from '@/core/interactors/new-customer-manage/detail'
  import CustomerCenterInteractor from '@/core/interactors/customer-manage/customer-center'
  import DetailStatistics from './detail-statistics'
  import ReplayRecord from '@/views/customer-manage/customer-center/components/replay-record'
  import MailRecord from './mail-record'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

  export default {
    components: {
      CompanyDetailSearch,
      DetailStatistics,
      ReplayRecord,
    },
    data() {
      return {
        tableKey: Math.random(),
        tableData: [],
        pageLe: 10,
        pageNo: 1,
        total: 0,
        currentHistory: [],
        requestForm: {},
      }
    },
    props: {
      code: {
        type: [String, Number],
        default: '1',
      },
      infoId: {
        type: String,
        default: '',
      },
      companyId: {
        default: '',
        type: String,
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      finallyColumn() {
        this.tableKey = Math.random()
        let newProductColumns = productColumns.filter((it) => {
          return it.prop != 'customerName'
        })
        let obj = {
          1: orderColumns,
          2: inquiryColumns,
          4: quoteColumns,
          5: newProductColumns,
          6: shipmentColumns,
          7: followRecordColumns,
          8: surplusColumns,
          9: customerGradeColumns,
          10: EvaluateColumns,
          11: remarksColumns,
        }
        return obj[this.code]
      },
      serchForm() {
        let obj = {
          4: quoteSearch, // 报价
          6: customerShipmentSearch, // 出运
        }
        return obj[this.code]
      },
      interactors() {
        let obj = {
          1: 'getSalesOrder', //订单记录
          2: 'getEnquiry', // 询盘
          4: 'getOrderEnquiry', // 报价
          5: 'getOrderProduct', // 产品信息
          6: 'getShipping', // 出运
          7: 'getFollowRecords', // 跟进记录
          8: 'getCustomerAccountBalance', // 余额记录
          9: 'getCustomerScoreRecord', // 客户等级
          10: 'getCustomerComplain', // 评价李局
          11: 'getCustomerRemark', // 备注
        }
        return obj[this.code]
      },
    },
    mounted() {
      this.search()
    },
    methods: {
      query() {
        this.tableKey = Math.random()
        this.search(this.requestForm, this.pageLe, this.pageNo)
      },
      async search(val = {}, pageLe = 10, pageNo = 1) {
        this.pageLe = pageLe
        this.pageNo = pageNo
        let dt = {}
        this.requestForm = val
        if (['1', '2'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            infoIdList: this.infoId.split(','),
            // infoIdList: ['433043276548673536', '453249963247144960'],
          }
        }
        if (['4', '5', '6', '7'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            infoIds: this.infoId.split(','),
            // infoIds: ['433043276548673536', '453249963247144960'],
          }
        }
        if (['8'].indexOf(this.code) > -1) {
          dt = {
            customerId: this.infoId,
            pageLe: pageLe,
            pageNo: pageNo,
            // customerId: '306451584857804800',
          }
        }
        if (['11'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            infoId: this.infoId,
            // infoId: '433043276548673536, 453249963247144960',
            companyId: this.companyId,
            queryType: 2,
          }
        }
        if (['9'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            companyId: this.companyId,
            infoId: this.infoId,
            // infoId: '433043276548673536',
          }
        }
        if (['10'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            // companyId: this.companyId,
            customerIdList: this.infoId.split(','),
            sourceType: 1,
          }
        }

        if (this.code == 11 && val.createTime) {
          this.$set(val, 'startTime', val?.createTime[0])
          this.$set(val, 'endTime', val?.createTime[1])
          // delete val.createTime
        }
        if (!val.createTime || val.createTime.length == 0) {
          this.$set(val, 'startTime', '')
          this.$set(val, 'endTime', '')
        }

        let params = { ...val, ...dt }
        let res = await CompanyDetailListInteractor[this.interactors](params)

        this.tableData = res?.data?.data || []
        this.total = res?.data?.total
      },
      async rowClick(row) {
        if (this.code == 10) {
          let res = await CustomerCenterInteractor.getCustomerComplainRecords(
            row.customerComplainId
          )
          this.currentHistory = res.data
        }
      },
      // 跳转详情
      goOrderDetail(code) {
        navigateTo(this, {
          pageType: PAGE_TYPES.salesOrderDetail,
          roleJudge: false,
          orderNumber: code,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .table-fixed {
    height: 400px !important;
  }
</style>
