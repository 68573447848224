<template>
  <div class="table-container">
    <!-- 新版开始 -->
    <!-- 客户预警开始 -->
    <ErpSubTitle title="客户预警"></ErpSubTitle>
    <div class="upper-limit mb20 pl10">
      <div class="desc">
        <strong>客户年成交金额上限预警</strong>
        <span v-if="limitInfo" class="mr15">
          {{ getCurrencyCode(limitInfo.currency) }}
          {{ limitInfo.money | formatPrice }}
        </span>
        <el-button type="text" @click="setUpperLimit">修改</el-button>
      </div>
    </div>
    <!-- 客户预警结束 -->
    <!-- 客户来源开始 -->
    <ErpSubTitle title="客户来源"></ErpSubTitle>
    <div class="pl10">
      <el-button
        size="mini"
        type="primary"
        @click="setClientSource()"
        class="mb15"
        v-allowed="['CustomerSourceAdd']"
      >
        新增
      </el-button>
      <el-table :data="tableData" border height="400px">
        <el-table-column
          type="index"
          width="55"
          fixed="left"
          align="center"
          label="序号"
        ></el-table-column>
        <el-table-column
          prop="sourceName"
          align="center"
          label="中文名称"
        ></el-table-column>
        <el-table-column
          prop="sourceNameEn"
          align="center"
          label="英文名称"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="120"
          :resizable="false"
        >
          <template slot-scope="scope">
            <template
              v-if="
                scope.row.sourceName !== 'Email' ||
                scope.row.sourceName !== 'Email'
              "
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                plain
                size="mini"
                @click="setClientSource(scope.row)"
                v-allowed="['CustomerSourceEdit']"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                plain
                circle
                size="mini"
                @click="removeClientSource(scope.row)"
                v-allowed="['CustomerSourceDelete']"
              ></el-button>
            </template>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 客户来源结束 -->
    <!-- 收款方式开始 -->
    <ErpSubTitle title="收款方式"></ErpSubTitle>
    <div class="pl10">
      <el-button
        type="primary"
        size="mini"
        @click="clinetPayClick()"
        class="mb15"
        v-allowed="['SETTING:PaymentMethodAdd']"
      >
        新增
      </el-button>
      <!-- :span-method="objectSpanMethod2" -->
      <el-table :data="clientPayData" width="100%" border height="400px">
        <el-table-column
          type="index"
          width="55"
          fixed="left"
          align="center"
          label="序号"
        ></el-table-column>
        <el-table-column
          label="金额区间"
          align="center"
          prop="payType"
          fixed="left"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-row v-if="scope.row.payType === 1">全部</el-row>
            <el-row v-else class="pl5 pr5">
              <span
                v-if="
                  (scope.row.beginSection === 0 || scope.row.beginSection) &&
                  scope.row.endSection
                "
              >
                {{ scope.row.beginSection }}-{{ scope.row.endSection }}
              </span>
              <span v-else>&gt;{{ scope.row.beginSection }}</span>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          label="收款方式"
          align="center"
          prop="payway"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="创建人"
          align="center"
          width="120"
          prop="createName"
        ></el-table-column>
        <el-table-column
          label="更新时间"
          prop="updateTime"
          align="center"
          width="140"
        >
          <template slot-scope="scope">
            {{ scope.row.updateTime || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" align="center" width="100">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status !== '4'"
              effect="plain"
              :type="scope.row.status | statusFilter"
              class="c_pointer"
              @click="statusTypeClick(scope.row)"
            >
              {{ putPayStatus(scope.row.status) }}
            </el-tag>
            <el-row v-if="scope.row.status === '4'">
              <el-tooltip placement="top">
                <div slot="content" class="tip-content">
                  作废原因：{{ scope.row.deleteReason || '--' }}
                  <br />
                  作废人：{{ scope.row.deleteName || '--' }}
                  <br />
                  作废时间：{{ scope.row.deleteTime || '--' }}
                </div>
                <el-tag type="info" class="c_pointer">已作废</el-tag>
              </el-tooltip>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120" fixed="right">
          <template slot-scope="scope">
            <el-row v-if="scope.row.status === '2' || scope.row.status === '3'">
              <el-tooltip content="编辑" placement="top">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  circle
                  size="mini"
                  @click="clinetPayClick(scope.row.paymentId)"
                  v-allowed="['SETTING:PaymentMethodEdit']"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="作废" placement="top">
                <el-button
                  type="info"
                  plain
                  circle
                  size="mini"
                  @click="invoiceDeleteClick(scope.row.paymentId)"
                >
                  <i class="iconfont icon-void"></i>
                </el-button>
              </el-tooltip>
            </el-row>
            <el-tooltip
              content="删除"
              placement="top"
              v-if="scope.row.status === '4'"
            >
              <el-button
                type="danger"
                plain
                icon="el-icon-delete"
                circle
                size="mini"
                @click="clientPayDelClick(scope.row.paymentId)"
                v-allowed="['SETTING:PaymentMethodDelete']"
              ></el-button>
            </el-tooltip>
            <span v-if="scope.row.status === '1'">--</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 收款方式结束 -->
    <!-- 客户更进开始 -->
    <ErpSubTitle title="客户跟进"></ErpSubTitle>
    <!-- :span-method="objectSpanMethod" -->
    <div class="pl10">
      <el-table :data="tableData1" border height="400px">
        <el-table-column
          type="index"
          width="55"
          fixed="left"
          align="center"
          label="序号"
        ></el-table-column>
        <el-table-column prop="customerType" align="center" label="客户类型">
          <template slot-scope="scope">
            {{ scope.row.customerType == 1 ? '正式客户' : '潜在客户' }}
          </template>
        </el-table-column>
        <el-table-column prop="customerRank" align="center" label="客户级别">
          <template slot-scope="scope">
            <el-rate
              v-model="scope.row.customerRank"
              disabled
              text-color="#ff9900"
            ></el-rate>
          </template>
        </el-table-column>
        <el-table-column
          prop="followPeriod"
          align="center"
          label="回归公海前*天提前"
        ></el-table-column>
        <el-table-column
          prop="returnSeas"
          align="center"
          label="*天未跟进自动回归公海"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          fixed="right"
          :resizable="false"
          width="120px"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              plain
              size="mini"
              @click="setClientFollow(scope.row)"
              v-allowed="['SETTING:CustomerFollowEdit']"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 客户跟进结束 -->
    <!-- 消息通知开始 -->
    <ErpSubTitle title="消息通知"></ErpSubTitle>
    <!-- <div class="operate">
      <el-button type="primary" @click="save">保存</el-button>
    </div> -->
    <div class="pl10">
      <el-table :data="tableDataSetting" border>
        <el-table-column
          prop="operationName"
          align="center"
          width="160"
          label="类型"
        ></el-table-column>
        <el-table-column prop="customerRank" align="center" label="通知方式">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.emailNotice"
              :true-label="1"
              :false-label="0"
            >
              邮件通知
            </el-checkbox>
            <el-checkbox
              v-model="scope.row.messageNotice"
              :true-label="1"
              :false-label="0"
            >
              系统消息通知
            </el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 消息通知结束 -->
    <!-- 积分配置开始 -->
    <ErpSubTitle title="积分配置"></ErpSubTitle>
    <div class="pl10">
      <p>
        <span>积分成长</span>
        <el-tooltip
          class="item"
          effect="dark"
          content="正数分值填写正数即可，负数分值加上负号即可 例如-50"
          placement="top"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
      </p>
      <el-table border :data="integralTable" class="mb15">
        <el-table-column
          label="成长类型"
          align="center"
          prop="growthName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="增减积分" align="center">
          <template slot-scope="scope">
            <el-input
              v-model.number="scope.row.integral"
              placeholder="建议分值-100~100之间"
              type="number"
              @input="
                (arg) => {
                  limitInput(arg, scope.row)
                }
              "
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <el-row type="flex" justify="center" align="middle" class="mt10">
      <el-button type="primary" @click="saveIntegral">保存</el-button>
    </el-row> -->
    <div class="pl10">
      <p>阶段配置</p>
      <el-table border :data="levelTable" class="mb15">
        <el-table-column label="区间开始" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.startValue"
              placeholder="0"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="区间结束" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.endValue"
              placeholder="100"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="星级"
          align="center"
          prop="integralLevel"
        ></el-table-column>
      </el-table>
    </div>
    <!-- <el-row type="flex" justify="center" align="middle" class="mt10">
      <el-button type="primary" @click="saveLeval">保存</el-button>
    </el-row> -->
    <!-- 积分配置结束 -->
    <!-- 退款提醒开始-->
    <!-- <ErpSubTitle title="退款提醒"></ErpSubTitle>
    <div class="mb20 pl10">
      <el-checkbox>在创建销售订单时，给出提示用户退款次数</el-checkbox>
    </div> -->

    <!-- 退款提醒结束 -->
    <!-- 操作审批开始 -->
    <ErpSubTitle title="操作审批"></ErpSubTitle>
    <div class="pl10">
      <ul>
        <li
          class="mt10"
          v-for="it in common_list_1"
          :key="it.customerCommonConfigureId"
        >
          <el-checkbox v-model="it.needTickCheck">
            {{ it.operationName }}
          </el-checkbox>
        </li>
      </ul>
      <!-- <div class="mt10 mb20">
        <el-button type="primary" @click="saveCommon(1)">保存</el-button>
      </div> -->
    </div>
    <ErpSubTitle title="公海客户" class="mt20"></ErpSubTitle>
    <div class="pl10">
      <ul>
        <li
          class="mt10"
          v-for="it in common_list_2"
          :key="it.customerCommonConfigureId"
        >
          <el-checkbox v-model="it.needTickCheck"></el-checkbox>
          {{ it.operationName }}
          <el-input-number
            type="number"
            style="width: 50px"
            :controls="false"
            v-model="it.extendedValue"
          ></el-input-number>
          <span>天未跟进，回归公海；（不勾选默认走系统进入公海配置时长）</span>
        </li>
      </ul>
      <!-- <div class="mt10 mb20">
        <el-button type="primary" @click="saveCommon(2)">保存</el-button>
      </div> -->
    </div>
    <ErpSubTitle title="分管配置" class="mt20"></ErpSubTitle>
    <div class="pl10">
      <ul>
        <li
          class="mt10"
          v-for="it in common_list_3"
          :key="it.customerCommonConfigureId"
        >
          <el-checkbox v-model="it.needTickCheck">
            {{ it.operationName }}
          </el-checkbox>
        </li>
      </ul>
      <!-- <div class="mt10 mb20">
        <el-button type="primary" @click="saveCommon(3)">保存</el-button>
      </div> -->
    </div>

    <div style="text-align: center">
      <el-button type="primary" @click="saveAll">保存</el-button>
    </div>

    <!-- 操作审批结束 -->
    <!-- 新版结束 -->

    <!-- 金额设置 -->
    <ClientSettingLimit
      ref="ClientSettingLimit"
      @success="getClientLimit"
      :currency="currency"
    />
    <!-- 客户来源 -->
    <ClientSettingPublicModel
      ref="ClientSettingPublicModel"
      @success="getClientSourceList"
    />
    <!-- 客户跟进 -->
    <ClientSettingTypeModel
      ref="ClientSettingTypeModel"
      @success="getClientFollowList"
    />
    <!-- 收款方式 -->
    <ClientSettingPayModel
      ref="ClientSettingPayModel"
      @fetch-data="getPaymentListPage"
    />

    <!-- 客户分管申请-指定角色 -->
    <RoleModel
      ref="RoleModel"
      @role-close="roleClose"
      @fetch-data="getCustomerManagerSettingGetDetail"
    />

    <!-- 审核进度Drawer -->
    <el-drawer
      title="审核进度"
      :visible.sync="statusDrawer"
      :before-="statusClose"
    >
      <Audit
        ref="Audit"
        :auditObjectId="auditObjectId"
        :auditType="auditType"
        @cancelClick="statusClose"
      />
    </el-drawer>

    <!-- 作废 -->
    <el-dialog
      title="作废"
      :visible.sync="operatVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="offer-dialog">
        <div class="container">
          <el-form
            :model="operatForm"
            :rules="operatRules"
            ref="operatForm"
            label-width="60px"
          >
            <el-form-item label="原因" prop="deleteReason">
              <el-input v-model="operatForm.deleteReason"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="text-center">
          <el-button type="primary" @click="operatSave">确认</el-button>
          <el-button @click="operatCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { dataMethod } from '@/utils/table-merge.js'
  import ClientSettingPublicModel from './components/client-setting-public-model'
  import ClientSettingTypeModel from './components/client-setting-type-model'
  import ClientSettingPayModel from './components/client-setting-pay-model'
  import ClientSettingLimit from './components/client-setting-limit'
  import RoleModel from './components/role-model'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import {
    clientFollowList,
    clientSourceList,
    clientSourceDelete,
    customerWarningDetail,
  } from '@/api/client-supplier/client-setting.js'
  import { FinanceCurrencyList } from '@/api/finance/finance-setting'
  import {
    paymentListPage,
    paymentToVoid,
    paymentDeleteById,
    customerManagerSettingUpdatePO,
    customerManagerSettingGetDetail,
  } from '@/api/client-supplier/supplier-setting'
  import { mapState } from 'vuex'
  import { putPayStatus } from '@/utils/en-match-zh'
  import {
    getDetail,
    insertCustomerMessageConfigurePO,
  } from '@/api/customer-manage/customer-setting'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  export default {
    name: 'ClientSetting',
    components: {
      ClientSettingPublicModel,
      ClientSettingTypeModel,
      ClientSettingPayModel,
      ClientSettingLimit,
      Audit,
      RoleModel,
    },
    data() {
      return {
        tableDataSetting: [],
        currency: [], //货币下拉
        requireForm: {}, //请求参数
        tableData: [], // 来源列表
        tableData1: [], // 跟进列表
        clientPayData: [], // 收款列表
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数
        spanObj: {}, //客户来源合并
        spanObj2: {}, //客户收款方式合并
        limitInfo: null,
        statusDrawer: false, //审核进度,
        auditObjectId: '',
        auditType: 11,
        operatForm: {
          deleteReason: '', //作废理由
          paymentId: '',
          payee: 1, //客户
        },
        operatRules: {
          deleteReason: [
            { required: true, message: '请输入原因', trigger: 'blur' },
            {
              max: 50,
              message: '长度不能超过50个字符',
              trigger: 'blur',
            },
          ],
        },
        operatVisible: false,
        proposerParams: {
          handlerType: 0, //客户分管申请处理人
          roleName: '', //指定角色
          roleId: '', //指定角色id
          parentId: '', //指定角色父级d
          customerManagerSettingId: '', //业务唯一id
        },

        integralTable: [], // 节分配置
        levelTable: [], // 阶段配置
        common_list_1: [], // 操作审批
        common_list_2: [], // 公海客户
        common_list_3: [], //分管配置
      }
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
      }),
    },
    filters: {
      //客户收款审核状态
      statusFilter(status) {
        const statusMap = {
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'info',
        }
        return statusMap[status]
      },
    },
    created() {
      this.getClientLimit()
      this.getClientFollowList()
      this.getClientSourceList()
      this.getPaymentListPage()
      this.getCurrency()
      getDetail().then((res) => {
        if (res.code === '000000') {
          this.tableDataSetting = res.data
        }
      })
      //查询客户分管申请数据
      //this.getCustomerManagerSettingGetDetail()
      this.getCustomerIntegralConfigure()
      this.customerIntegralLevel()
      this.getCommonConfigure()
    },
    methods: {
      save() {
        insertCustomerMessageConfigurePO(
          this.tableDataSetting.map((item) => {
            let obj = {}
            obj.id = item.id
            obj.emailNotice = item.emailNotice
            obj.messageNotice = item.messageNotice
            return obj
          })
        ).then((res) => {
          if (res?.code === '000000') {
            // this.$message.success('操作成功')
          }
        })
      },
      getCustomerManagerSettingGetDetail() {
        customerManagerSettingGetDetail({
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.proposerParams.handlerType = res.data.handlerType
            this.proposerParams.customerManagerSettingId =
              res.data.customerManagerSettingId
            this.proposerParams.roleName = res.data.roleName
            this.proposerParams.roleId = res.data.roleId
            this.proposerParams.parentId = res.data.parentId
          }
        })
      },
      //客户分管申请处理人change
      radioChange(val) {
        if (val === 1 && this.proposerParams.roleId === '') {
          this.$refs['RoleModel'].showAddEdit(this.proposerParams)
        } else if (val === 0) {
          this.proposerParams.roleName = ''
          this.proposerParams.roleId = ''
          this.proposerParams.parentId = ''
          customerManagerSettingUpdatePO(this.proposerParams).then((res) => {
            if (res && res.data) {
              this.$baseMessage(
                '修改成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          })
        }
      },
      changeChange() {
        this.$refs['RoleModel'].showAddEdit(this.proposerParams)
      },
      roleClose() {
        if (!this.proposerParams.roleName) {
          this.proposerParams.handlerType = 0
        }
      },
      // 货币
      getCurrency() {
        FinanceCurrencyList().then((res) => {
          if (res && res.code === '000000') {
            this.currency = res.data || []
          }
        })
      },

      getCurrencyCode(name) {
        const curCurr = this.currency.find((c) => c.financeName === name)
        return curCurr ? curCurr.code : '-'
      },
      // 表格最大高度计算
      tableHeight(num = 0) {
        return window.innerHeight - num + 'px'
      },
      //客户收款方式
      async getPaymentListPage() {
        let response = await paymentListPage({ payee: 1 })
        if (response.code === '000000') {
          this.clientPayData = response.data
        }
      },
      flitterData(arr) {
        let spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.payType === 1) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return {
          one: spanOneArr,
        }
      },
      objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          const _row = this.flitterData(this.clientPayData).one[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },

      //收款方式新增编辑
      clinetPayClick(paymentId) {
        this.$refs['ClientSettingPayModel'].showAddEdit(paymentId)
      },
      //收款方式删除
      async clientPayDelClick(paymentId) {
        this.$confirm('确定将该收款方式删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await paymentDeleteById({
            paymentId: paymentId,
            payee: 1,
          })
          if (response.code === '000000') {
            this.$baseMessage(
              '删除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
            this.getPaymentListPage()
          }
        })
      },
      //收款作废
      invoiceDeleteClick(paymentId) {
        this.operatForm.paymentId = paymentId
        this.operatVisible = true
      },
      // 作废保存
      operatSave() {
        this.$refs['operatForm'].validate((valid) => {
          if (valid) {
            this.$confirm(`确定作废, 操作之后无法修改?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                paymentToVoid(this.operatForm).then((res) => {
                  if (res.data) {
                    this.$baseMessage(
                      '作废成功',
                      'success',
                      false,
                      'erp-hey-message-success'
                    )
                    this.operatCancel()
                    this.getPaymentListPage()
                  } else {
                    this.$baseMessage(
                      '作废失败',
                      'error',
                      false,
                      'erp-hey-message-success'
                    )
                    this.operatCancel()
                  }
                })
              })
              .catch(() => {})
          }
        })
      },
      //作废取消
      operatCancel() {
        this.$refs['operatForm'].resetFields()
        this.operatVisible = false
      },

      //审核状态匹配
      putPayStatus(val) {
        return putPayStatus(val)
      },
      //审核进度查看
      statusTypeClick(row) {
        if (row.status === '1' || row.status === '3') {
          this.auditObjectId = row.paymentId
          this.statusDrawer = true
        }
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },
      //获取客户预警额度
      getClientLimit() {
        customerWarningDetail({
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res.code === '000000') {
            this.limitInfo = res.data
          }
        })
      },
      // 客户来源列表
      getClientSourceList() {
        clientSourceList({
          sourceId: this.userInfo.userId,
        }).then((res) => {
          if (res.code === '000000') {
            this.tableData = res.data || []
          }
        })
      },
      // 客户跟进列表
      getClientFollowList() {
        clientFollowList().then((res) => {
          if (res.code === '000000') {
            this.tableData1 = res.data || []
            //客户跟进
            this.spanObj = dataMethod(this.tableData1, ['customerType'])
          }
        })
      },
      //新增编辑客户来源
      setClientSource(row) {
        this.$refs['ClientSettingPublicModel'].showAddEdit(row)
      },
      //删除客户来源
      removeClientSource(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          clientSourceDelete({ businessId: row.sourceId }).then((res) => {
            if (res.code === '000000') {
              this.$message.success('删除成功！')
              this.getClientSourceList()
            }
          })
        })
      },
      // 设置金额上限
      setUpperLimit() {
        this.$refs['ClientSettingLimit'].showDialog(this.limitInfo)
      },

      //客户跟进编辑
      setClientFollow(row) {
        this.$refs['ClientSettingTypeModel'].showAddEdit(row)
      },
      //客户跟进合并
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        let _row = this.spanObj[column.property]
          ? this.spanObj[column.property][rowIndex]
          : 1
        let _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      },
      //收款方式新增编辑
      clientPayClick(str) {
        this.$refs['ClientSettingPayModel'].showAddEdit()
      },

      // 获取积分配置
      async getCustomerIntegralConfigure() {
        this.integralTable = await CustomerConfigInteractor.customerConfig()
        console.log(this.integralTable, '00')
      },
      // 更新积分配置
      async saveIntegral() {
        let res = await CustomerConfigInteractor.updateCustomerIntegralConfig(
          this.integralTable
        )

        if (res.code === '000000') {
          // this.$message.success('更新成功')
          this.getCustomerIntegralConfigure()
        }
      },
      // 阶段配置
      async customerIntegralLevel() {
        this.levelTable =
          await CustomerConfigInteractor.getCustomerIntegralLevel()
        if (this.levelTable.length == 0) {
          let arr = ['一星', '二星', '三星', '四星', '五星']
          this.levelTable = arr.map((it) => {
            return {
              startValue: 0,
              endValue: 0,
              integralLevel: it,
            }
          })
        }
      },
      // 保存阶段配置 updateCustomerIntegralLevel
      async saveLeval() {
        let res = await CustomerConfigInteractor.updateCustomerIntegralLevel(
          this.levelTable
        )
        if (res.code === '000000') {
          this.$message.success('更新成功')
          this.customerIntegralLevel()
        }
      },

      // 查询客户公用配置 1操作审批 2公海客户 3分管配置
      async getCommonConfigure() {
        this.common_list_1 =
          await CustomerConfigInteractor.getCustomerCommonConfigure(1)
        this.common_list_2 =
          await CustomerConfigInteractor.getCustomerCommonConfigure(2)
        this.common_list_3 =
          await CustomerConfigInteractor.getCustomerCommonConfigure(3)
      },

      async saveCommon(type) {
        let res = await CustomerConfigInteractor.updatePOommonConfigure(
          this[`common_list_${type}`]
        )
        if (res.code === '000000') {
          // this.$message.success('更新成功')
          this.getCommonConfigure()
        }
      },
      // 限制输入-500到500
      limitInput(val, scope) {
        if (val < -500) {
          this.$message.warning('下限为-500，上限为500')
          scope.integral = -500
        }
        if (val > 500) {
          this.$message.warning('下限为-500，上限为500')
          scope.integral = 500
        }
      },
      // 总体保存
      saveAll() {
        this.save()
        this.saveIntegral()
        this.saveLeval()
        this.saveCommon(1)
        this.saveCommon(2)
        this.saveCommon(3)
      },
    },
  }
</script>

<style scoped lang="scss">
  .mr15 {
    margin-right: 15px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .el-table {
    width: 100%;
    margin-bottom: 20px;
  }
  .main-height {
    height: calc(100% - 52px);
  }
  .table-height-box {
    height: calc(100% - 4px);
    overflow: hidden;
    overflow-y: auto;
  }
  .col {
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
  }
  .upper-limit {
    /* border: 1px solid #ededed; */
    /* padding: 10px; */
    /* margin-bottom: 20px; */
    .desc {
      line-height: 32px;
      font-size: 14px;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      strong {
        margin-right: 10px;
      }
      /* > span {
        flex: 1;
      } */
    }
  }
  .public-col {
    border: 1px solid #ededed;
    padding: 10px;
    flex: 1;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: auto;

    p {
      line-height: 32px;
    }
  }
  .public-col2 {
    border: 1px solid #ededed;
    padding: 10px;
    p {
      line-height: 32px;
    }
  }
  ::v-deep {
    .el-tag {
      border: none;
    }
    .el-tag--plain {
      background: none;
    }
  }
  .pl10 {
    padding-left: 10px;
  }
  ul {
    list-style: none;
  }
  .mt20 {
    margin-top: 20px;
  }
</style>
