<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="币种" prop="currency">
          <el-select v-model="form.currency" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预警金额" prop="momey">
          <el-input v-model.trim="form.momey" placeholder="请输入" />
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <el-row class="t_a_c">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { doEdit } from '@/api/table'

export default {
  name: 'ClientSettingMoneyModel',
  data() {
    return {
      title: '金额设置',
      form: {
        currency: '',
        momey: '',
      },
      rules: {
        currency: [
          { required: true, message: '请选择币种', trigger: 'change' },
        ],
        momey: [
          {
            required: true,
            message: '请输入预警金额',
            trigger: 'blur',
          },
        ],
      },
      dialogFormVisible: false,
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          value: '选项2',
          label: '双皮奶',
        },
      ],
      value: '',
    }
  },
  created() {},
  methods: {
    //新增、编辑
    showAddEdit() {
      // this.form = Object.assign({}, row)
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('fetch-data')
          this.close()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>
