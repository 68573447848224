<!--
  功能：卡片缺省图
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月27日 11:15:44
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="kanpan-empty-wrapper">
    <i class="iconfont icon-meiyoushuju fs-42"></i>
    <span style="color: #999999">暂无数据</span>
  </div>
</template>

<script>
export default {
  name: 'DataKanpanEmpty',
  props: {},
  components: {},
  // 组件状态值
  data() {
    return {}
  },
}
</script> 

<style scoped lang="scss">
.kanpan-empty-wrapper {
  display: flex;
  align-items: center;
  .fs-42 {
    color: #999999;
    font-size: 14px;
    font-size: 23px;
  }
}
</style>
