<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="20%"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-row class="mt15">
        <el-col class="sort-box">
          <span>账期优先级</span>
          <el-select
            v-model="priority"
            placeholder="请选择"
            clearable
            v-if="showSelectBol"
          >
            <el-option
              v-for="item in sortOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <el-row class="t_a_c">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    paymentDetail,
  } from '@/api/client-supplier/supplier-setting' 
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  export default {
    name: 'SpplierPrioritySetting',
    data() {
      return {
        loading: false,
        paymentId:'',
        title: '设置账期优先级',
        percent: 0, //占比
        dialogFormVisible: false,
        priority: '', //账期优先级
        sortOptions: [],
        showSelectBol: false,
      }
    },


    created() {
  
    },
    methods: {
      //获取租户下所有优先级
      async _getPaymentPriorityList() {
        const numbers = Array.from({ length: 100 }, (_, index) => index + 1)
        let choosed = []
        let res = await CustomerConfigInteractor.getPaymentPriorityListApi()
        if (res.code === '000000') {
          choosed = res?.data || [] //已选数组
        }
        this.sortOptions = numbers.map((item) => {
          return {
            label: item,
            value: item,
            disabled: choosed.includes(item),
          }
        })
        this.showSelectBol = true
      },
      //新增、编辑
      showAddEdit(paymentId,priority) {
        if (!paymentId) {
        } else {
          this.priority = priority ?? '';
          this.paymentId = paymentId;
        }
        this.dialogFormVisible = true
      },
      close() {
        this.priority = ''
        this.paymentId =''
        this.dialogFormVisible = false
      },
     async save(){
        const data = {
          paymentId:this.paymentId,
          priority:this.priority
        }
        let res = await CustomerConfigInteractor.setPriorityApi(data)
        if(res.code === "000000"){
          this.$baseMessage(
            '设置成功',
            'success',
            false,
            'erp-hey-message-success'
          )
          this.dialogFormVisible = false
          this.$emit('fetch-data')
        }
      }
    },
    watch: {
      dialogFormVisible(val) {
        if (val) this._getPaymentPriorityList()
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
    .custom-item {
      .el-form-item__label:before {
        content: '' !important;
      }
    }
  }
  .tips-color {
    color: #999;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .row-box {
    span {
      white-space: nowrap;
    }
  }
  .sort-box {
    display: flex;
    align-items: center;
    span {
      margin-right: 20px;
    }
    ::v-deep.el-select {
      width: 240px;
    }
  }
</style>
