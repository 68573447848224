var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialogFormVisible)?_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogFormVisible,"width":"600px","append-to-body":"","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"t_a_c"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v("关闭")])],1)]},proxy:true}],null,false,3482362466)},[_c('el-row',[(_vm.tableData && _vm.tableData.length)?_c('el-col',{attrs:{"span":24}},[_c('el-form',{attrs:{"label-position":"top"}},_vm._l((_vm.tableData),function(item,index){return _c('el-form-item',{key:index},[_c('template',{slot:"label"},[_c('label',{staticClass:"label-text f_s_14"},[(item.required === '1')?_c('i',{staticStyle:{"color":"#ff4d4f"}},[_vm._v("*")]):_c('i',{staticStyle:{"color":"#fff"}},[_vm._v("*")]),_vm._v(" "+_vm._s(item.nameCn)+" "),(item.isMain === '0')?_c('span',[_vm._v("(关联标签)")]):_vm._e()])]),_c('el-row',{staticStyle:{"padding-left":"30px"}},[(
                item.multiple === '1' &&
                item.keyChildEditVOList &&
                item.keyChildEditVOList.length > 0
              )?_c('el-checkbox-group',{model:{value:(_vm.tableData[index].value),callback:function ($$v) {_vm.$set(_vm.tableData[index], "value", $$v)},expression:"tableData[index].value"}},_vm._l((item.keyChildEditVOList),function(child,i){return _c('el-checkbox',{key:i,attrs:{"label":child.keyChildId,"disabled":child.disabled}},[_vm._v(" "+_vm._s(child.nameCn)+" ")])}),1):_vm._e(),(
                item.multiple === '0' &&
                item.keyChildEditVOList &&
                item.keyChildEditVOList.length > 0
              )?_c('el-radio-group',{model:{value:(_vm.tableData[index].value),callback:function ($$v) {_vm.$set(_vm.tableData[index], "value", $$v)},expression:"tableData[index].value"}},_vm._l((item.keyChildEditVOList),function(child2){return _c('el-radio',{key:child2.keyChildId,attrs:{"label":child2.keyChildId,"disabled":child2.disabled}},[_vm._v(" "+_vm._s(child2.nameCn)+" ")])}),1):_vm._e()],1)],2)}),1)],1):_c('el-col',{staticClass:"t_a_c mt30 mb30",attrs:{"span":24}},[_vm._v(" 还没有可选择的产品标签... ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }