<!--
  功能：站内信-提醒
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年07月26日 10:56:58
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="site-msg-wrapper" :style="{ zIndex }">
    <h3>
      站内消息—
      <span :style="{ color: titleColor }">{{ title }}</span>
      <i class="el-icon-close close" @click="close"></i>
    </h3>
    <h4>订单跟踪</h4>
    <div class="main">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SiteMessageCommon',
    props: {
      title: {
        type: String,
        default: '提醒',
      },
      titleColor: {
        type: String,
        default: '',
      },
      zIndex: {
        type: Number,
        default: 10,
      },
    },

    methods: {
      close() {
        this.$emit('close')
      },
    },
  }
</script>

<style scoped lang="scss">
  .site-msg-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 300px;
    overflow: hidden;
    width: 400px;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    background-color: rgba(226, 238, 207, 1);
    overflow: auto;
    color: #303133;
    transition: 0.3s;
    padding: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    h3 {
      text-align: center;
    }
    .main {
      margin-top: 10px;
      max-height: 200px;
      overflow: auto;
      div {
        margin-bottom: 10px;
      }
    }
    .close {
      color: red;
      font-size: 16px;
      position: absolute;
      right: 15px;
      font-weight: 700;
      top: 15px;
      cursor: pointer;
    }
  }
</style>
