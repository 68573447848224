<template>
  <el-row :gutter="20" class="pro-container pro-valuation">
    <el-col :span="2">
      <h6 class="left-h6">
        <!-- 产品计价 -->
        {{ $t('productDetail.ProductPricing') }}
        <el-tooltip class="item ml5" effect="light" placement="top">
          <template slot="content">
            <!-- 当前计价为Blank价格 -->
            <p>{{ $t('other.BlankPrice') }}</p>
          </template>
          <i class="el-icon-warning-outline c_pointer"></i>
        </el-tooltip>
      </h6>
    </el-col>
    <el-col :span="22" class="right-content">
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="150px"
        class="el_form"
      >
        <!-- header -->
        <el-row>
          <el-col :span="6">
            <!-- 成本价 -->
            <el-form-item
              prop="costPrice"
              class="w100"
              :label="$t('productDetail.CostPrice') + '(￥)'"
            >
              <!-- 请输入 -->
              <el-input
                type="number"
                @mousewheel.native.prevent
                v-model="form.costPrice"
                :placeholder="$t('placeholder.plsInput')"
                @change="calcTax()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row type="flex" align="middle" class="calcu">
              <p>
                <!-- 当前汇率 -->
                <label>{{ $t('productDetail.CurrentExchangeRate') }}：</label>
                <span>{{ exchange || '--'}}</span>
              </p>
              <p class="ml20">
                <!-- 美金价 -->
                <label>{{ $t('productDetail.USDprice') }}：</label>
                <span>{{ dollarPrice }}</span>
              </p>
              <p class="ml20" style="display: flex; align-items: center">
                <label style="color: red" class="mr20">
                  <!-- 最新汇率 -->
                  {{ $t('productDetail.LatestExchangeRate') }} ：{{ rate }}
                </label>
                <el-input
                  style="width: 130px;"
                  :placeholder="$t('productDetail.PleaseEnterTheExchangeRate')"
                  v-model="newRate"
                  oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                ></el-input>
                <!-- 更换 -->
                <el-button style="height:32px" type="primary" size="mini" @click="changeRate">
                  {{ $t('productDetail.Change') }}
                </el-button>
              </p>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="border-bottom: 1px solid #ededed" class="mt15"></el-row>
        <el-row type="flex" class="pt15" v-if="false">
          <!-- 点击计算 -->
          <el-button type="primary" @click="calcTax()">
            {{ $t('productDetail.ClickCalculate') }}
          </el-button>
        </el-row>

        <!-- 阶梯-table -->
        <el-row style="max-height: 400px; overflow: hidden; overflow-y: auto">
          <el-table
            v-loading="loading"
            :data="bcglXiangXiList"
            :row-class-name="rowClassName"
            border
            stripe
            ref="dragTable"
            class="w100 mt15 table-height table-data"
          >
            <el-table-column align="center" prop="num" width="120" fixed="left">
              <template slot="header">
                <i class="red mr5">*</i>
                <!-- 数量 -->
                <label>{{ $t('productDetail.Quantity') }}</label>
              </template>
              <template slot-scope="scope">
                <!-- 整数数值 -->
                <el-input
                  type="number"
                  v-model="
                    bcglXiangXiList[scope.row.productPricingStepId - 1].num
                  "
                  @mousewheel.native.prevent
                  :placeholder="$t('placeholder.M2015')"
                  @change="numChange(scope.row, 'num',scope.$index)"
                  @input="inputChange('num')"
                />
              </template>
            </el-table-column>
            <el-table-column align="center" prop="profitRate" width="100">
              <template slot="header">
                <i class="red mr5">*</i>
                <!-- 利润率 -->
                <label>{{ $t('productDetail.ProfitRate') }}</label>
              </template>
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="
                    bcglXiangXiList[scope.row.productPricingStepId - 1]
                      .profitRate
                  "
                  @mousewheel.native.prevent
                  :placeholder="$t('placeholder.M2016')"
                  @change="numChange(scope.row, 'profitRate',scope.$index)"
                  oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                />
              </template>
            </el-table-column>
            <!-- 关税率 -->
            <el-table-column align="center" prop="taxRate" width="120">
               <template slot="header">
                <i class="red mr5">*</i>
                <label>{{ $t('productDetail.TaxRate') }}</label>
              </template>
              <template slot-scope="scope">
                <el-input
                  type="number"
                  @mousewheel.native.prevent
                  v-model="scope.row.taxRate"
                  :placeholder="$t('productDetail.CNBGT')"
                  @blur="taxRateChange(scope.row)"
                  @change="numChange(scope.row, 'taxRate',scope.$index)"
                  oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="tax" width="120">
              <template slot="header">
                <!-- 税费 -->
                {{ $t('productDetail.Taxes') }}
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <!-- 成本价*税率÷汇率÷利润率÷2 -->
                    <p>
                      {{ $t('productDetail.CostPrice') }}*{{
                        $t('productDetail.TaxRate')
                      }}÷{{ $t('productDetail.ExchangeRate') }}÷{{
                        $t('productDetail.ProfitRate')
                      }}÷2
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.tax || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="price" width="130">
              <template slot="header">
                <!-- 产品价 -->
                {{ $t('productDetail.ProductPrice') }}($)
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <!-- 成本÷汇率÷利润率+税费 -->
                    <p>
                      {{ $t('productDetail.CostPrice') }}÷{{
                        $t('productDetail.ExchangeRate')
                      }}÷{{ $t('productDetail.ProfitRate') }}+{{
                        $t('productDetail.Taxes')
                      }}
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="
                    bcglXiangXiList[scope.row.productPricingStepId - 1].price
                  "
                  @mousewheel.native.prevent
                  :placeholder="$t('placeholder.M2016')"
                  @change="numChange(scope.row, 'price', scope.$index)"
                />
              </template>
            </el-table-column>
            <!-- 装箱总体积(m³) -->
            <el-table-column
              :label="$t('productDetail.TotalPackingVolume')"
              align="center"
              prop="totalPackingVolume"
              width="150"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.totalPackingVolume || '0' }}</span>
              </template>
            </el-table-column>
            <!-- 装箱总重量(Kg) -->
            <el-table-column
              :label="$t('productDetail.TotalPackingWeight')"
              align="center"
              prop="totalPackingWeight"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.totalPackingWeight || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="freightSettingId" width="180">
              <template slot="header">
                <i class="red mr5">*</i>
                <!-- 运输类型 -->
                <label>{{ $t('productDetail.ShippingMethod') }}</label>
              </template>
              <template slot-scope="scope">
                <el-select
                  class="clearable"
                  v-model="
                    bcglXiangXiList[scope.row.productPricingStepId - 1]
                      .transportType
                  "
                  @change="
                    transportWayChange(
                      scope.row,
                      scope.row.freightSettingId,
                      scope.row.freightForProductStepVOS,
                      scope.$index
                    )
                  "
                >
                  <el-option
                    v-for="(item, index) in bcglXiangXiList[
                      scope.row.productPricingStepId - 1
                    ].freightForProductStepVOS"
                    :key="index"
                    :label="
                      settings === 'zh'
                        ? item.typeAndPriceAppendCn
                        : item.typeAndPriceAppendEn
                    "
                    :value="item.transportType"
                  />
                </el-select>
              </template>
            </el-table-column>
            <!-- 运费单价 -->
            <el-table-column
              :label="$t('productDetail.FreightUnitPrice')"
              align="center"
              prop="transportPrice"
              width="120"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="
                    bcglXiangXiList[scope.row.productPricingStepId - 1]
                      .transportPrice
                  "
                  @mousewheel.native.prevent
                  :placeholder="$t('placeholder.M2016')"
                  @input="transportPriceChange(scope.row, scope.$index)"
                />
              </template>
            </el-table-column>
            <!-- 运费总价 -->
            <el-table-column
              :label="$t('productDetail.TotalShippingPrice')"
              align="center"
              prop="totalTransportPrice"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{(Number(scope.row.transportPrice)*Number(scope.row.num)).toFixed(3)}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="netPrice" width="120">
              <template slot="header">
                <p>Blank</p>
                Net Price($)
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <!-- 产品价+运费÷利润率 -->
                    <p>
                      {{ $t('productDetail.ProductPrice') }}+{{
                        $t('other.freight')
                      }}÷{{ $t('productDetail.ProfitRate') }}
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.netPrice || '0'}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="listPrice" width="120">
              <template slot="header">
                <p>Blank</p>
                List Price($)
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.listPrice || '0' }}</span>
                <!-- <span>{{((Number(scope.row.price) + Number(scope.row.transportPrice)/Number(scope.row.profitRate))/form.priceRate).toFixed(3)}}</span> -->
              </template>
            </el-table-column>
            <!-- Net 总价($) -->
            <el-table-column align="center" prop="totalNetPrice" width="150">
              <template slot="header">
                <p>Blank</p>
                {{ $t('productDetail.NetTotalPrice') }}
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.totalNetPrice || '0' }}</span>
              </template>
            </el-table-column>
            <!-- List 总价($) -->
            <el-table-column align="center" prop="totalListPrice" width="150">
              <template slot="header">
                <p>Blank</p>
                {{ $t('productDetail.ListTotalPrice') }}
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.totalListPrice || '0' }}</span>
              </template>
            </el-table-column>
            <!-- 出运申报单价($) -->
            <el-table-column
              :label="$t('productDetail.ShippingDeclarationUnitPrice')"
              align="center"
              prop="shippingPrice"
              width="125"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  v-model="
                    bcglXiangXiList[scope.row.productPricingStepId - 1]
                      .shippingPrice
                  "
                  @mousewheel.native.prevent
                  :placeholder="$t('placeholder.M2016')"
                />
              </template>
            </el-table-column>
            <!-- 生产周期 -->
            <el-table-column
              :label="$t('productDetail.ProductionCycle')"
              align="center"
              prop="produceDay"
              width="100"
            >
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model.trim="
                    bcglXiangXiList[scope.row.productPricingStepId - 1]
                      .produceDay
                  "
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  :placeholder="$t('other.workingDay')"
                />
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
              :label="$t('productDetail.Operate')"
              align="center"
              width="80"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  circle
                  plain
                  @click="handleDeleteDetails(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <el-row type="flex" justify="center" class="mt30">
          <!-- 新增数量价格阶梯 -->
          <el-button @click="handleAddDetails">
            {{ $t('productDetail.AddQuantityPriceLadder') }}
          </el-button>
        </el-row>
        <specPrice class="mt50"/>
        <el-row class="mt30" style="display: flex">
          <!-- 支持打样 -->
          <el-form-item
            :label="$t('productDetail.SupportProofing')"
            class="mr20"
          >
            <el-switch v-model="Sample"></el-switch>
          </el-form-item>
          <!-- 打样费单价 -->
          <el-form-item
            v-show="Sample"
            :label="$t('productDetail.UPOPF')"
            class="mr20"
            prop="proofingFee"
          >
            <el-input v-model.trim="form.proofingFee"></el-input>
          </el-form-item>
          <!-- 打样周期 -->
          <el-form-item
            v-show="Sample"
            :label="$t('productDetail.ProofingCycle')"
            prop="proofingDay"
          >
            <input type="number" v-model.number="form.proofingDay" style="height:32px;border: 1px solid #c0c4cc;padding:0 15px" class="inputClass">
              <!-- 工作日 -->
            </input>
            <label for="">{{$t('productDetail.WorkingDay')}}</label>
          </el-form-item>
        </el-row>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import Sortable from 'sortablejs'
import { deepCopy } from 'kits'
import { getDetailByType } from '@/api/product/productSetting'
import {  listTransportMethod } from '@/api/product/productList'
import { mapGetters } from 'vuex'
import { ProductAddEditInteractor } from '@/core/interactors/product/productAddEdit'
import specPrice from './specExtraPrice'

export default {
  name: 'ProValuation',
  components: { specPrice },
  props: {
    editData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      form: {
        calcFreight: 1,
        priceRate: 0.6, //默认0.6，取产品设置里面的价格规则
        freightWay: 0,
        proofingFee: 0,
      },
      rules: {
        costPrice: [
          {
            required: true,
            // message: '请输入成本价',
            message: this.$t('rules.M2018'),
            trigger: 'blur',
          },
        ],
        taxRate: [
          {
            required: true,
            // message: '请输入税率',
            message: this.$t('rules.M2019'),
            trigger: 'blur',
          },
        ],
        standardCustomized: [
          // 请选择产品类型
          {
            required: true,
            message: this.$t('rules.M2004'),
            trigger: 'change',
          },
        ],
        // 打样费
        proofingFee: [
          {
            required: true,
            message: this.$t('rules.M2004'),
            trigger: 'blur',
          },
        ],
        proofingDay: [
          {
            required: true,
            message: this.$t('rules.M2004'),
            trigger: 'blur',
          },
        ],
      },
      dollarPrice: '', //美金价
      calcFreightBol: true,
      loading: false,
      bcglXiangXiList: [],
      checkedDetail: [],
      tableForm: {},
      options: [],
      specTableData: [],
      taxResult: null, //税费
      setObj: {},
      setObjIndex: 0,
      pubFlag: true,
      stopWatch: false,
      editDataParams: {},
      productEditId: '',
      flag: true,
      freightWay: '', //计算运费方式
      rate: null, //汇率
      isTableDataLength: 0, //是否有多规格数据
      isSingleSpecDataLength: 0, //是否有单规格数据
      isSpecs: true, //默认多规格
      exchange: '', //最新汇率
      newRate: '',
      Sample: true,
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/language',
    }),
  },
  watch: {
    'form.costPrice': {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) {
          return (this.dollarPrice = '')
        }
        //供应商付款方式下拉
        this.pubApi.getCurrency({ financeName: '美元' }).then((response) => {
          if (response.code === '000000') {
            this.rate = response.data
            if (Number(value) && this.rate) {
              this.dollarPrice = (value / this.exchange).toFixed(2)
            }
          }
        })
      },
    },
    bcglXiangXiList: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) {
          return
        }
        this.$Bus.$emit('bcglXiangXiList', value)
      },
    },

    // 监听修改值之后，是否点击了价格计算
  },
  created() {
    //供应商付款方式下拉
    this.pubApi.getCurrency({ financeName: '美元' }).then((response) => {
      if (response.code === '000000') {
        this.rate = response.data
        this.exchange = this.exchange ? this.exchange : this.rate
        if (this.$route.query.str === 'add') {
          this.exchange = this.rate
        }
      }
    })
    this.$Bus.$on('show-clear', () => {
      this.$set(this.form, 'costPrice', null)
      this.$set(this.form, 'taxRate', null)
      this.form.taxRate = null
      this.bcglXiangXiList = []
      this.stopWatch = true
      this.$forceUpdate()
    })
    //开启单规格多规格
    this.$Bus.$on('specsChange', (bol) => {
      this.isSpecs = bol
      this.bcglXiangXiList = []
    })

    //多规格
    this.$Bus.$on('SpecTableData', (val) => {
      if (val) {
        this.test(val)
      }
    })

    //单规格
    this.$Bus.$on('singleSpecData', (val) => {
      if (val) {
        this.test(val)
      }
    })

    //判断规格列表是否有数据
    this.$Bus.$on('isTableDataLength', (val) => {
      this.isTableDataLength = val.length
      if (val.length === 0) {
        this.bcglXiangXiList = []
        this.setObj = {}
      }
    })
    this.$Bus.$on('isSingleSpecDataLength', (val) => {
      this.isSingleSpecDataLength = val.length
      if (val.length === 0) {
        this.bcglXiangXiList = []
        this.setObj = {}
      }
    })

    //获取产品价格规格
    this.getDetailByType8()
  },
  methods: {
    inputChange(val) {
      this.$Bus.$emit('proValuationNum', this.bcglXiangXiList)
    },
    //产品汇率
    async getDetailByType8() {
      let response = await getDetailByType({ type: 8 })
      if (response.code === '000000' && response.data.length) {
        this.form.priceRate = Number(response.data[0].value)
      }
    },
    test(val) {
      if (val) {
        let newObj = new Set()
        val.forEach((item) => {
          if (Number(item.productWeightKgs) && Number(item.numberPerBox)) {
            //装箱数量，装箱体积，毛重,产品重量
            let calcu =
              item.numberPerBox +
              ',' +
              item.boxVolumeM +
              ',' +
              item.boxWeightKgs +
              ',' +
              item.productWeightKgs
            newObj.add(calcu)
          }
        })
        this.specTableData = val
        this.bcglXiangXiList = []
        if (newObj.size > 0) {
          newObj.forEach((item) => {
            let arr = item.split(',')
            let obj = {
              objIndex: this.setObjIndex,
              num: 0, //数量
              profitRate: 0, // 利润率
              tax: 0, //税费
              price: 0, //产品价
              totalPackingVolume: 0, //装箱总体积
              totalPackingWeight: 0, //装箱总重量
              packingVolume: Number(arr[1]) || 0, //装箱体积(不显示在页面上)
              packingWeight: Number(arr[2]) || 0, //装箱重量（不显示在页面上）
              numberPerBox: Number(arr[0]) || 0, //装箱数量(不显示在页面上)
              productWeight: Number(arr[3]) || 0, //产品重量(不显示在页面上)
              freightSettingId: '', // 运输方式
              freightForProductStepVOS: [], //运输方式下拉
              transportPrice: 0, //运费单价
              totalTransportPrice: 0, //运费总价
              netPrice: 0,
              listPrice: 0,
              totalNetPrice: 0, //Net 总价
              totalListPrice: 0, //List 总价
              shippingPrice: 0, //出运申报单价
              produceDay: 0, //生产周期
            }
            this.bcglXiangXiList.push(obj)
          })
          this.setObjIndex++
          this.setObj = newObj
        }
      }
    },
    editShow(editData) {
      let that = this
      that.editDataParams = editData
      this.exchange = editData.exchange ? editData.exchange : this.rate

      that.productEditId = that.editDataParams.productId || ''

      // 有打样周期或者打样费的时候  打样开关开
      this.form.proofingDay = editData.proofingDay
      this.form.proofingFee = editData.proofingFee
      if (this.editDataParams.proofingDay || this.editDataParams.proofingFee) {
        this.Sample = true
      } else {
        this.Sample = false
      }

      //单规格还是多规格
      if (that.editDataParams.specsType) {
        that.isSpecs = true
      } else {
        that.isSpecs = false
      }
      if (!that.stopWatch) {
        if (that.editDataParams.costPrice) {
          that.$set(that.form, 'costPrice', that.editDataParams.costPrice)
        }
        if (that.editDataParams.priceRate) {
          that.$set(that.form, 'priceRate', that.editDataParams.priceRate)
        }

        that.$set(that.form, 'taxRate', that.editDataParams.taxRate)

        if (that.editDataParams.overflowNum) {
          that.$set(that.form, 'overflowNum', that.editDataParams.overflowNum)
        }

        if (that.editDataParams.calcFreight === 0) {
          this.calcFreightBol = false
        } else {
          this.calcFreightBol = true
        }
        that.$set(that.form, 'calcFreight', that.editDataParams.calcFreight)
        //保存本地
        if (that.editDataParams.productPricingStepDTOList) {
          that.bcglXiangXiList = that.editDataParams.productPricingStepDTOList
          that.$Bus.$emit('bcglXiangXiList', that.bcglXiangXiList)
          that.setObj = new Set()
          that.bcglXiangXiList.forEach((item) => {
            if (Number(item.numberPerBox) && Number(item.packingVolume)) {
              //单个装箱数量，单个装箱体积，毛重，产品重量，
              let calcu =
                item.numberPerBox +
                ',' +
                item.packingVolume +
                ',' +
                item.packingWeight +
                ',' +
                item.productWeight
              // +
              // ',' +
              // item.totalPackingVolume +
              // ',' +
              // item.totalPackingWeight
              item.item.item.that.setObj.add(calcu)
            }
          })
        }
        if (that.editDataParams.productPricingStepVOList) {
          that.bcglXiangXiList = that.editDataParams.productPricingStepVOList
          that.$Bus.$emit('bcglXiangXiList', that.bcglXiangXiList)
          that.setObj = new Set()
          that.bcglXiangXiList.forEach((item) => {
            if (Number(item.numberPerBox) && Number(item.packingVolume)) {
              //装箱数量，装箱体积，毛重
              let calcu =
                item.numberPerBox +
                ',' +
                item.packingVolume +
                ',' +
                item.packingWeight +
                ',' +
                item.productWeight
              //  +
              // ',' +
              // item.totalPackingVolume +
              // ',' +
              // item.totalPackingWeight
              that.setObj.add(calcu)
            }
          })
        }
      }

      that.bcglXiangXiList.forEach((item) => {
        let netPrice = (
          Number(item.price) +
          Number(item.transportPrice) / item.profitRate
        ).toFixed(3)

        this.$set(item, 'netPrice', netPrice)

        this.$set(
          item,
          'listPrice',
          (Number(item.netPrice) / Number(this.form.priceRate)).toFixed(3)
        )

        this.$set(
          item,
          'totalNetPrice',
          (Number(item.netPrice) * Number(item.num)).toFixed(3)
        )

        this.$set(
          item,
          'totalListPrice',
          (Number(item.listPrice) * Number(item.num)).toFixed(3)
        )
      })
    },
    // 税率改变
    taxRateChange(val) {
      if (val.taxRate > 1) {
        // this.form.taxRate = ''
        this.$set(val, 'taxRate', '')
        return this.$baseMessage(
          // '税率不能大于1',
          this.$t('reqmsg.M2043'),
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
    },

    // 计算 获取运费类型
    async getFreightType() {
      let res = await ProductAddEditInteractor.getProductStep()
    },

    //1、点击计算
    calcTax(str) {
      if (this.bcglXiangXiList.length === 0) {
        return this.$baseMessage(
          // '请先完善规格数据',
          this.$t('reqmsg.M2044'),
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }

      let arr_f = this.bcglXiangXiList.filter((item) => {
        return item.num !== 0 || item.profitRate !== 0
      })
      let bcglXiangXiListNew = []
      if (arr_f.length === this.bcglXiangXiList.length) {
        bcglXiangXiListNew = this.bcglXiangXiList
      } else {
        bcglXiangXiListNew = arr_f
      }
      let params = []
      const { costPrice, taxRate } = this.form
      if (!costPrice) {
        return this.$baseMessage(
          // '请输入成本价',
          this.$t('reqmsg.M2045'),
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }

      // let flag1 = this.bcglXiangXiList.some((item) => {
      let flag1 = bcglXiangXiListNew.some((item) => {
        return item.num === 0
      })
      if (flag1) {
        return this.$baseMessage(
          // '请输入每行的数量',
          this.$t('reqmsg.M2046'),
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      // let flag2 = this.bcglXiangXiList.some((item) => {
      let flag2 = bcglXiangXiListNew.some((item) => {
        return item.profitRate === 0
      })
      let flag3 = bcglXiangXiListNew.some((item) => {
        return (
          item.taxRate === undefined ||
          item.taxRate === null ||
          item.taxRate === ''
        )
      })
      if (flag2) {
        return this.$baseMessage(
          // '请输入每行利润率',
          this.$t('reqmsg.M2047'),
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      if (flag3) {
        return this.$baseMessage(
          // '请输入每行关税率',
          this.$t('rules.M2019'),
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      if (!flag1 && !flag2) {
        // this.bcglXiangXiList.forEach((item, index) => {
        bcglXiangXiListNew.forEach((item, index) => {
          //税费(成本价*税率/汇率/利润率)
          let taxResult =
            (Number(costPrice) * Number(item.taxRate)) /
            this.exchange /
            Number(item.profitRate) /
            2
          item.tax = taxResult.toFixed(3)

          //产品价(成本/汇率/利润率+税费)
          let priceResult =
            Number(this.form.costPrice) /
              this.exchange /
              Number(item.profitRate) +
            Number(item.tax)
          item.price = priceResult.toFixed(3)

          //装箱总体积（数量/装箱数量*单个装箱体积）
          let totalPackingVolume = (
            (item.num / item.numberPerBox) *
            item.packingVolume
          ).toFixed(3)
          item.totalPackingVolume = totalPackingVolume

          let isThanZero = item.num % item.numberPerBox
          let totalPackingWeight = 0 //装箱总重量
          //出现无法整除时
          if (isThanZero > 0) {
            //箱子重
            let boxWeightKgs =
              item.packingWeight - item.productWeight * item.numberPerBox

            totalPackingWeight = (
              item.productWeight * item.num +
              boxWeightKgs * (item.num / item.numberPerBox)
            ).toFixed(3)
          } else {
            //装箱总重量（数量/装箱数量*单个装箱重量）
            totalPackingWeight = (
              (item.num / item.numberPerBox) *
              item.packingWeight
            ).toFixed(3)
          }

          item.totalPackingWeight = totalPackingWeight
          //if (this.form.calcFreight === 1) {
          // 运费参数
          let obj = {
            costPrice: Number(this.form.costPrice),
            num: Number(item.num), //数量
            profitRate: Number(item.profitRate), //利润率
            tax: Number(item.tax), //税费
            price: Number(item.price || 0), //产品价
            boxVolumeM: Number(item.packingVolume || 0), //装箱体积
            boxWeightKgs: Number(item.packingWeight || 0), //装箱重量
            numberPerBox: Number(item.numberPerBox || 0), //装箱数量
            productWeightKgs: Number(item.productWeight || 0), //产品重量
            transportMethod: item.freightSettingId || '', //运输方式
            // exchange: str === 'rate' ? this.exchange : this.rate, //汇率
            exchange: this.exchange,
            taxRate: Number(item.taxRate), //税率
            priceRate: Number(this.form.priceRate), //价格规则
            transportPrice: Number(item.transportPrice),
          }
          params.push(obj)

          if (str === 'profitRate' && item.freightSettingId) {
            item.netPrice = (
              Number(item.price) +
              Number(item.transportPrice) / item.profitRate
            ).toFixed(3)

            item.listPrice = (
              Number(item.netPrice) / Number(this.form.priceRate)
            ).toFixed(3)

            item.totalNetPrice = (
              Number(item.netPrice) * Number(item.num)
            ).toFixed(3)

            item.totalListPrice = (
              Number(item.listPrice) * Number(item.num)
            ).toFixed(3)
          }
          // else {
          //   this.getMinDataList(params, index)
          // }
        })
        this.getMinDataList(params)
      }
    },
    //
    async getMinDataList(params, index) {
      // let response = await getMinData(params)
      let response = await ProductAddEditInteractor.getProductStep(params)
      //产品价加税费
      if ((response.code = '000000')) {
        response.data?.forEach((item, i) => {
          //运费单价
          this.bcglXiangXiList[i].transportPrice = item.transportPrice

          //运费总价
          this.bcglXiangXiList[i].totalTransportPrice = item.totalTransportPrice

          //最优运输方式
          this.bcglXiangXiList[i].freightSettingId = item.freightSettingId

          //运输方式数组
          this.bcglXiangXiList[i].freightForProductStepVOS =
            item.freightForProductStepVOS

          // this.bcglXiangXiList[index].netPrice = item.netPrice
          this.bcglXiangXiList[i].netPrice = (
            Number(this.bcglXiangXiList[i].price) +
            Number(this.bcglXiangXiList[i].transportPrice) /
              Number(this.bcglXiangXiList[i].profitRate)
          ).toFixed(3)

          //listPrice(Net Price / 价格规则)
          this.bcglXiangXiList[i].listPrice = (
            Number(this.bcglXiangXiList[i].netPrice || 0) /
            Number(this.form.priceRate || 0)
          ).toFixed(3)

          //netPrice总价（Net Price * 数量）
          this.bcglXiangXiList[i].totalNetPrice = (
            Number(this.bcglXiangXiList[i].netPrice || 0) *
            Number(params[i].num || 0)
          ).toFixed(3)

          //listPrice总价(List Price * 数量)
          this.bcglXiangXiList[i].totalListPrice = (
            Number(this.bcglXiangXiList[i].listPrice || 0) *
            Number(params[i].num || 0)
          ).toFixed(3)
        })
      }
    },

    //运输方式change
    transportWayChange(row, freightSettingId, list, index) {
      //运输单价
      let transportPriceList = list.filter(
        (item) => item.transportType === row.transportType
      )
      this.$set(
        this.bcglXiangXiList[index],
        'transportPrice',
        transportPriceList[0]?.transportPrice
      )
      this.$set(
        this.bcglXiangXiList[index],
        'freightSettingId',
        transportPriceList[0]?.freightSettingId
      )
      //运输总价
      let totalTransportPrice = list.filter(
        (item) => item.transportType === row.transportType
      )[0].totalTransportPrice
      this.$set(
        this.bcglXiangXiList[index],
        'totalTransportPrice',
        totalTransportPrice
      )

      this.transportPriceChange(row, index, 'transportWayChange')
    },

    //运费单价修改
    transportPriceChange(row, index, str) {
      //运费总价（单价*数量）

      if (!str) {
        let totalTransportPrice = (
          Number(row.transportPrice) * Number(row.num)
        ).toFixed(3)
        this.$set(
          this.bcglXiangXiList[index],
          'totalTransportPrice',
          totalTransportPrice
        )
      }
      //Net price（产品价+（运费/利润率））
      let netPrice = (
        Number(row.price) +
        Number(row.transportPrice) / Number(row.profitRate)
      ).toFixed(3)

      this.$set(this.bcglXiangXiList[index], 'netPrice', netPrice)

      //List price(Net price/价格规则)
      let listPrice = (Number(netPrice) / Number(this.form.priceRate)).toFixed(
        3
      )
      this.$set(this.bcglXiangXiList[index], 'listPrice', listPrice)

      //netprice总价
      let totalNetPrice = (Number(netPrice) * Number(row.num)).toFixed(3)
      this.$set(this.bcglXiangXiList[index], 'totalNetPrice', totalNetPrice)

      //listprice总价
      let totalListPrice = (
        Number(totalNetPrice) / Number(this.form.priceRate)
      ).toFixed(3)
      this.$set(this.bcglXiangXiList[index], 'totalListPrice', totalListPrice)
    },

    async numChange(row, str, index) {
      let count = 0
      this.bcglXiangXiList.forEach((item) => {
        if (item.objIndex === row.objIndex) {
          if (str === 'num') {
            item.num = Number(row.num)
          } else if (str === 'profitRate') {
            // 利润率输入大于1 或是 0 或 不输入  都为1
            if (row.profitRate > 1 || row.profitRate == 0) {
              row.profitRate = 1
            }
            item.profitRate = Number(row.profitRate || 1)
          } else if (str === 'price') {
            let sl = Math.round(
              (Number(this.form.costPrice) /
                this.rate /
                (Number(row.price) - Number(row.tax))) *
                100
            )
            this.$set(this.bcglXiangXiList[index], 'profitRate', sl * 0.01)
          } else if (str === 'taxRate') {
            if (row.taxRate > 1) {
              row.taxRate = 1
            }
            item.taxRate = Number(row.taxRate)
          }
          count++
        }
        if (count === this.setObj.size - 1) {
          return
        }
      })
      this.pubFlag = false

      if (str === 'profitRate') {
        this.calcTax('profitRate')
      } else {
        this.calcTax()
      }
    },

    // 运输方式
    async getListTransportMethod() {
      let response = await listTransportMethod()
      if ((response.code = '000000')) {
        this.options = response.data
      }
    },

    // table操作
    rowClassName({ row, rowIndex }) {
      row.productPricingStepId = rowIndex + 1
    },
    // 运费change
    // calcFreightChange(val) {
    //   this.calcFreightBol = !this.calcFreightBol
    //   this.calcTax()
    // },

    //新增一行
    handleAddDetails() {
      if (this.isSpecs) {
        if (!this.setObj || this.isTableDataLength === 0) {
          return this.$baseMessage(
            // '请先完善产品规格列表数据1',
            this.$t('reqmsg.M2048'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      } else {
        if (!this.setObj || this.isSingleSpecDataLength === 0) {
          return this.$baseMessage(
            // '请先完善产品规格列表数据2',
            this.$t('reqmsg.M2049'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      }

      // if (this.bcglXiangXiList.length === 0) {
      //   return this.$baseMessage(
      //     '请先完善产品规格数据',
      //     'warning',
      //     false,
      //     'erp-hey-message-warning'
      //   )
      // }
      let test = deepCopy(this.setObj)
      if (this.bcglXiangXiList == undefined) {
        this.bcglXiangXiList = new Array()
      }
      let objIndexVal = null
      if (this.bcglXiangXiList.length) {
        objIndexVal =
          this.bcglXiangXiList[this.bcglXiangXiList.length - 1].objIndex
      } else {
        objIndexVal = 0
      }

      this.setObjIndex = objIndexVal + 1

      if (this.setObj.size > 0) {
        this.setObj.forEach((item, index) => {
          let arr = item.split(',')
          let obj = {
            objIndex: this.setObjIndex,
            num: 0, //数量
            profitRate: '', // 利润率
            tax: 0, //税费
            price: 0, //产品价
            totalPackingVolume: 0, //装箱总体积
            totalPackingWeight: 0, //装箱总重量
            packingVolume: Number(arr[1]) || 0, //装箱体积
            packingWeight: Number(arr[2]) || 0, //装箱重量
            numberPerBox: Number(arr[0]) || 0, //装箱数量(不显示在页面上)
            productWeight: Number(arr[3]) || 0, //产品重量(不显示在页面上)
            freightSettingId: '', // 运输方式
            freightForProductStepVOS: [], //运输方式下拉
            transportPrice: 0, //运费单价
            totalTransportPrice: 0, //运费总价
            netPrice: 0,
            listPrice: 0,
            totalNetPrice: 0, //Net 总价
            totalListPrice: 0, //List 总价
            shippingPrice: 0, //出运申报单价
            produceDay: 0, //生产周期
            productId: this.productEditId || '',
          }
          this.bcglXiangXiList.push(obj)
        })
        this.setObjIndex++
      } else {
        return this.$baseMessage(
          '请先完善产品规格列表数据2',
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
    },
    handleDeleteDetails(index) {
      this.bcglXiangXiList.splice(index, 1)
      this.$Bus.$emit('proValuationNum', this.bcglXiangXiList)
    },
    // 验证
    validateField() {
      if (this.bcglXiangXiList.length === 0) {
        return this.$baseMessage(
          '产品计价列表为空，请先完善规格列表信息',
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      if (this.bcglXiangXiList.length) {
        let flag = true
        flag = this.bcglXiangXiList.every(
          (item) => item.freightSettingId !== ''
        )
        if (!flag) {
          return this.$baseMessage(
            '产品计价列表请选择运输方式',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      }

      let success = false
      this.$refs.form.validate(async (isSuccess) => {
        if (isSuccess) {
          success = true
        } else {
          return this.$baseMessage(
            '请完善产品计价信息',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      })
      if (success) {
        this.form.costPrice = Number(this.form.costPrice)
        this.form.priceRate = Number(this.form.priceRate)
        // this.form.taxRate = Number(this.form.taxRate)
        const form = deepCopy(this.form)
        const bcglXiangXiList = deepCopy(this.bcglXiangXiList)

        bcglXiangXiList.forEach((item) => {
          item.listPrice = Number(item.listPrice)
          item.price = Number(item.price)
          item.tax = Number(item.tax)
          item.shippingPrice = Number(item.shippingPrice)
          item.produceDay = Number(item.produceDay)
          item.netPrice = Number(item.netPrice)
          item.numberPerBox = Number(item.numberPerBox)
          item.packingVolume = Number(item.packingVolume)
          item.packingWeight = Number(item.packingWeight)
          item.totalListPrice = Number(item.totalListPrice)
          item.totalNetPrice = Number(item.totalNetPrice)
          item.totalPackingVolume = Number(item.totalPackingVolume)
          item.totalPackingWeight = Number(item.totalPackingWeight)
          item.productId = this.productEditId || ''
          item.totalTransportPrice = (
            Number(item.transportPrice) * Number(item.num)
          ).toFixed(3)
          // item.transportWayList = JSON.stringify(item.transportWayList)
        })

        return {
          success: true,
          valuationParams: form,
          productPricingStepDTOList: bcglXiangXiList,
          proofingDay: this.form.proofingDay,
          proofingFee: this.form.proofingFee,
          exchange: this.exchange, //汇率,
        }
      } else {
        return {
          success: false,
        }
      }
    },
    // 保存到本地
    validateField2() {
      this.form.costPrice = Number(this.form.costPrice)
      this.form.priceRate = Number(this.form.priceRate)
      // this.form.taxRate = Number(this.form.taxRate)
      const form = deepCopy(this.form)
      const bcglXiangXiList = deepCopy(this.bcglXiangXiList)

      bcglXiangXiList.forEach((item) => {
        item.listPrice = Number(item.listPrice)
        item.price = Number(item.price)
        item.tax = Number(item.tax)
        item.taxRate = Number(item.taxRate)
        item.shippingPrice = Number(item.shippingPrice)
        item.produceDay = Number(item.produceDay)
        item.netPrice = Number(item.netPrice)
        item.numberPerBox = Number(item.numberPerBox)
        item.packingVolume = Number(item.packingVolume)
        item.packingWeight = Number(item.packingWeight)
        item.totalListPrice = Number(item.totalListPrice)
        item.totalNetPrice = Number(item.totalNetPrice)
        item.totalPackingVolume = Number(item.totalPackingVolume)
        item.totalPackingWeight = Number(item.totalPackingWeight)
        item.productId = this.productEditId || ''
        item.totalTransportPrice = (
          Number(item.transportPrice) * Number(item.num)
        ).toFixed(3)
        //item.transportWayList = JSON.stringify(item.transportWayList)
      })
      return {
        valuationParams: form,
        productPricingStepDTOList: bcglXiangXiList,
        proofingDay: this.form.proofingDay,
        proofingFee: this.form.proofingFee,
        exchange: this.exchange, //汇率,
      }
    },

    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        '.el-table__body-wrapper > table > tbody'
      )[0]
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: (evt) => {
          const targetRow = this.bcglXiangXiList.splice(evt.oldIndex, 1)[0]
          this.bcglXiangXiList.splice(evt.newIndex, 0, targetRow)
          const tempIndex = this.bcglXiangXiList.splice(evt.oldIndex, 1)[0]
          this.bcglXiangXiList.splice(evt.newIndex, 0, tempIndex)
        },
      })
    },
    // 更换汇率
    changeRate() {
      if (!this.newRate) return
      this.exchange = this.newRate
      this.dollarPrice = (this.form.costPrice / this.exchange).toFixed(2)
      this.newRate = ''
      this.calcTax('rate')
      // this.rate = this.newRate
    },
  },
}
</script>

<style scoped lang="scss">
.pro-container {
  .left-h6 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }
  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }
  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
    .calcu {
      margin-left: 110px;
      p {
        label {
          font-size: 12px;
          color: #999;
        }
      }
    }
    // .table-data {
    //   max-height: 285px;
    //   overflow: hidden;
    //   overflow-y: auto;
    // }
    .num-price-step {
      ::v-deep {
        .el-form-item__label {
          font-size: 16px;
          margin-right: 20px;
        }
      }
    }
  }
  ::v-deep {
    .inputClass {
      border-radius: 2.5px;
      color: #606266;
    }
    .inputClass:focus {
      border-color: #1890ff !important;
    }
  }
}
</style>
