<template>
  <div class="content">
    <el-row :gutter="20" class="detail-header">
      <el-col :span="7">
        <p>费用单号</p>
        <span>{{ form.costNumber }}</span>
        <span class="ml10">
          <el-tag type="primary" plain v-if="form.orderStatus === '4'" size="small">
            审核中
          </el-tag>
          <el-tag type="warning" plain v-if="form.orderStatus === '1'" size="small" class="ml10">
            待处理
          </el-tag>
          <el-tag type="danger" plain v-if="form.orderStatus === '3'" size="small">
            已驳回
          </el-tag>
          <el-tag type="success" plain v-if="form.orderStatus === '2'" size="small">
            已完成
          </el-tag>
        </span>
      </el-col>
      <el-col :span="12">
        <p>收款方</p>
        <span v-if=" form.dutyAccount">
          {{ form.accountName }}/{{ form.branchName }}/{{ form.bankAccount }}/{{
          form.dutyAccount
          }}
        </span>
        <span v-else>
          {{ form.accountName }}/{{ form.branchName }}/{{ form.bankAccount }}
        </span>
      </el-col>
    </el-row>

    <el-row class="mt30">
      <el-table border :data="topTableData">
        <el-table-column prop="costTypeName" align="center" show-overflow-tooltip>
          <template slot="header">
            费用类型
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                <p>国内物流费：包含国内的快递、货拉拉、国内货运费用</p>
                <p class="mt5">人工费：包含临时工的打包费、验货人工费用</p>
                <p class="mt5">
                  手续费：包含美金收款手续费、国内美金结汇手续费
                </p>
              </template>
              <i class="iconfont el-icon-question c_pointer"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="payName" align="center" label="支付方式"></el-table-column>
        <el-table-column prop="currency" align="center" label="币种"></el-table-column>
        <el-table-column align="center" label="支付金额($)">
          <template slot-scope="scope">
            <div class="bold" :class="scope.row.entryType == 1 ? 'red' : 'green'">
              {{ scope.row.entryType == 1 ? '-' : '' }}
              {{ utils.numberFormat(scope.row.totalPayAmount, 2) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalPayRmbAmount" align="center" label="支付金额(￥)">
          <template slot-scope="scope">
            <div class="bold" :class="scope.row.entryType == 1 ? 'red' : 'green'">
              {{ scope.row.entryType == 1 ? '-' : '' }}
              {{ utils.numberFormat(scope.row.totalPayRmbAmount, 2) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="payTime" align="center" label="付款时间"></el-table-column>
      </el-table>
    </el-row>
    <!-- 底部表格 -->
    <el-row>
      <template v-if="form.orderType === '1'">
        <el-table border :data="footTableData">
          <el-table-column prop="salesOrderNumber" align="center" label="销售订单号">
            <template slot-scope="scope">
              <router-link class="link" v-if="form.orderType === '1'" target="_blank" :to="`/order/orderList/orderDetail?orderCode=${
                  scope.row.salesOrderNumber
                }&noReturn=${true}`">
                {{ scope.row.salesOrderNumber }}
              </router-link>
              <span class="link" v-if="form.orderType === '2'"
                @click="purchaseOrderNumberClick(scope.row.purchaseOrderNumber)">
                {{ scope.row.purchaseOrderNumber }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="businessName" align="center" label="客户代表"></el-table-column>
          <el-table-column prop="area" align="center" label="地区">
            <template slot-scope="scope">
              {{ scope.row.area || '--' }}
            </template>
          </el-table-column>
          <el-table-column prop="currency" align="center" label="币种"></el-table-column>
          <el-table-column prop="orderTotalAmount" align="center" label="订单总金额">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click">
                <span class="blue-text" slot="reference"
                  v-if="scope.row.orderTotalAmount || [0,'0'].includes(scope.row.orderTotalAmount)"
                  @click="totlePriceClcik(scope.row.salesOrderNumber)">
                  {{ matchCurrency(scope.row.currency)
                  }}{{ scope.row.orderTotalAmount | orderMoneyFormat }}
                </span>
                <el-row>
                  <el-table :data="salesGatheringVOS" style="width: 100%" border class="mt15">
                    <el-table-column width="150" prop="paymentLink" label="收款节点" align="center" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{ scope.row.paymentLink || '--' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="paymentPropor" label="比例(%)" align="center" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{ scope.row.paymentPropor || '--' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="receivablePrice" label="应收金额" align="center" width="120"
                      show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span v-if="scope.row.receivablePrice || [0,'0'].includes(scope.row.receivablePrice)">
                          {{ moneySign }}
                        </span>
                        {{ scope.row.receivablePrice | orderMoneyFormat }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="actualReceivablePrice" label="实收金额" width="120" align="center"
                      show-overflow-tooltip>
                      <template slot-scope="scope">
                        <div :style="scope.row.entryType == 1 ? 'color:red' : ''">
                          <span v-if="scope.row.entryType == 1">-</span>
                          <span
                            v-if="scope.row.actualReceivablePrice || [0,'0'].includes(scope.row.actualReceivablePrice)">
                            {{ moneySign }}
                          </span>
                          {{

                          scope.row.actualReceivablePrice | orderMoneyFormat

                          }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="actualReceivablePriceDate" label="收款时间" align="center" width="120"
                      show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{ scope.row.actualReceivablePriceDate || '--' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="voucherNo" label="收款单号" align="center" width="150" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span class="blue-text" v-if="scope.row.voucherNo" @click="receiptNoClick(scope.row)">
                          {{ scope.row.voucherNo }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="financeMethod" label="收款方式" align="center" width="120" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{ scope.row.financeMethod || '--' }}
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="collectionAmount" align="center" label="已收金额">
            <template slot-scope="scope">
              <span v-if="scope.row.collectionAmount || [0,'0'].includes(scope.row.collectionAmount)">
                {{ matchCurrency(scope.row.currency)
                }}{{scope.row.collectionAmount | orderMoneyFormat }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="uncollectedAmount" align="center" label="未收金额">
            <template slot-scope="scope">
              <span v-if="scope.row.uncollectedAmount ||[0,'0'].includes(scope.row.uncollectedAmount)">
                {{ matchCurrency(scope.row.currency) }}
                {{ scope.row.uncollectedAmount | orderMoneyFormat }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="payAmount" align="center" label="支付金额">
            <template slot-scope="scope">
              <span v-if="scope.row.payAmount ||[0,'0'].includes(scope.row.payAmount)">
                <span :class="
                    topTableData[0] && topTableData[0].entryType == 1
                      ? 'red'
                      : ''
                  ">
                  <span v-show="topTableData[0] && topTableData[0].entryType == 1" style="margin-left: -3px">
                    -
                  </span>
                  <span class="bold" :class="
                      topTableData[0] && topTableData[0].entryType == 1
                        ? ''
                        : 'green'
                    ">
                    {{ matchCurrency(form.currency) }}
                    {{ scope.row.payAmount | orderMoneyFormat }}
                  </span>
                </span>
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="产品分摊金额" width="140" align="center">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click">
                <product-expense :expenseList.sync="expenseList" :orderType="form.orderType"
                  :entryType="form.entryType"></product-expense>

                <el-link slot="reference" type="primary" @click="getExpense(scope.row)">
                  点击查看
                </el-link>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <!-- 采购订单表格 -->
        <div v-for="(item, index) in footTableData" :Key="index">
          <el-table border :data="[item]">
            <el-table-column prop="salesOrderNumber" align="center" label="采购订单号">
              <template slot-scope="scope">
                <router-link class="link" v-if="form.orderType === '1'" target="_blank" :to="`/order/orderList/orderDetail?orderCode=${
                    scope.row.salesOrderNumber
                  }&noReturn=${true}`">
                  {{ scope.row.salesOrderNumber }}
                </router-link>
                <span class="link" v-if="form.orderType === '2'" @click="
                    purchaseOrderNumberClick(scope.row.purchaseOrderNumber)
                  ">
                  {{ scope.row.purchaseOrderNumber }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="buyerName" align="center" label="采购开发"></el-table-column>
            <el-table-column prop="area" align="center" label="地区">
              <template slot-scope="scope">
                {{ scope.row.area || '--' }}
              </template>
            </el-table-column>
            <el-table-column prop="currency" align="center" label="币种"></el-table-column>
            <el-table-column prop="orderTotalAmount" align="center" label="订单总金额">
              <template slot-scope="scope">
                <span v-if="scope.row.orderTotalAmount || [0,'0'].includes(scope.row.orderTotalAmount)">
                  {{ matchCurrency(scope.row.currency)
                  }}{{ utils.numberFormat(scope.row.orderTotalAmount, 2) }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="collectionAmount" align="center" label="已付金额">
              <template slot-scope="scope">
                <span v-if="scope.row.collectionAmount || [0,'0'].includes(scope.row.collectionAmount)">
                  {{ matchCurrency(scope.row.currency)
                  }}{{ utils.numberFormat(scope.row.collectionAmount, 2) }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="uncollectedAmount" align="center" label="未付金额">
              <template slot-scope="scope">
                <span v-if="scope.row.uncollectedAmount || [0,'0'].includes(scope.row.uncollectedAmount)">
                  {{ matchCurrency(scope.row.currency) }}
                  {{ utils.numberFormat(scope.row.uncollectedAmount, 2) }}
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="payAmount" align="center" label="支付金额">
              <template slot-scope="scope">
                <span v-if="scope.row.payAmount || [0,'0'].includes(scope.row.payAmount)">
                  <span :class="
                      topTableData[0] && topTableData[0].entryType == 1
                        ? 'red'
                        : ''
                    ">
                    <span v-show="topTableData[0] && topTableData[0].entryType == 1" style="margin-left: -3px">
                      -
                    </span>
                    <span class="bold" :class="
                        topTableData[0] && topTableData[0].entryType == 1
                          ? ''
                          : 'green'
                      ">
                      {{ matchCurrency(form.currency) }}
                      {{ utils.numberFormat(scope.row.payAmount, 2) }}
                    </span>
                  </span>
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="产品分摊金额" width="140" align="center">
              <template slot-scope="scope">
                <el-popover placement="bottom" trigger="click">
                  <product-expense :expenseList.sync="expenseList" :orderType="form.orderType"
                    :entryType="form.entryType"></product-expense>
                  <el-link slot="reference" type="primary" @click="getExpense(scope.row)">
                    点击查看
                  </el-link>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="item.financeOrderVOList" border style="width: 100%">
            <el-table-column align="center" width="55" label="#">
              <template slot-scope="scope">
                <span v-if="scope.$index < 9">0{{ scope.$index + 1 }}</span>
                <span v-else>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column width="140" prop="orderCode" align="center" label="销售订单号">
              <template slot-scope="scope">
                <span class="blue-text">
                  <router-link target="_blank" :to="`/order/orderList/orderDetail?orderCode=${
                      scope.row.orderCode
                    }&noReturn=${true}`">
                    {{ scope.row.orderCode }}
                  </router-link>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="businessName" align="center" label="客户代表"></el-table-column>
            <el-table-column prop="area" align="center" label="地区"></el-table-column>
            <el-table-column prop="currency" align="center" label="币种"></el-table-column>
            <el-table-column prop="totlePrice" align="center" label="订单总金额">
              <template slot-scope="scope" v-if="scope.row.totlePrice">
                <el-popover placement="bottom" trigger="click">
                  <span class="blue-text" slot="reference"
                    v-if="scope.row.totlePrice || [0,'0'].includes(scope.row.totlePrice)"
                    @click="totlePriceClcik(scope.row.orderCode)">
                    {{ matchCurrency(scope.row.currency)
                    }}{{ scope.row.totlePrice | orderMoneyFormat }}
                  </span>
                  <sale-detail-table :salesGatheringVOS="salesGatheringVOS"
                    @receiptNoClick="receiptNoClick"></sale-detail-table>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="amountReceived" align="center" label="已收金额">
              <template slot-scope="scope">
                <span v-if="
                    scope.row.currency === '人民币' && scope.row.amountReceived
                  ">
                  ￥
                </span>
                <span v-if="
                    scope.row.currency === '美元' && scope.row.amountReceived
                  ">
                  $
                </span>

                {{ scope.row.amountReceived | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="outstandingAmount" align="center" label="未收金额">
              <template slot-scope="scope"
                v-if="scope.row.outstandingAmount || [0,'0'].includes(scope.row.outstandingAmount)">
                <span v-if="scope.row.currency === '人民币'">￥</span>
                <span v-if="scope.row.currency === '美元'">$</span>
                {{ scope.row.outstandingAmount | orderMoneyFormat}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </el-row>
    <el-row class="mt20 remark">
      <el-col :span="8">
        <p>备注</p>
        <span style="word-break: break-all">{{ form.remark || '--' }}</span>
      </el-col>
      <el-col :span="8">
        <p>附件</p>
        <div v-show="fileList.length">
          <div class="flie-list" v-for="(item, i) in fileList" :key="i">
            <i class="el-icon-document"></i>
            <a class="page-link ml5">
              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                <span class="ell">
                  <router-link :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                    {{ item.name }}
                  </router-link>
                </span>
              </el-tooltip>
            </a>
            <span class="size ml10">
              {{ utils.calculateFileSize(item.size) }}
            </span>
            <span class="ml10">{{ item.time }}</span>
          </div>
        </div>
        <span v-show="!fileList.length">--</span>
      </el-col>
      <el-col :span="4">
        <p>创建人</p>
        <span>{{ form.creatorName }}</span>
      </el-col>
      <el-col :span="4">
        <p>创建时间</p>
        <span>{{ form.createTime }}</span>
      </el-col>
    </el-row>
    <el-row class="mt20">
      <el-col :span="6">
        <p>审核单号</p>
        <span v-if="form.auditNumber">
          {{ form.auditNumber || '--' }}
        </span>
        <span v-else>--</span>
        <p style="font-size: 12px; margin-top: 10px">
          审核通过时间：{{ form.auditPassTime || '--' }}
        </p>
      </el-col>
      <!--收款单详情弹窗 -->
      <ReceiptDetailModelOut ref="ReceiptDetail" />
    </el-row>
  </div>
</template>

<script>
  import { FinanceCostInteractor, PaymentInteractor, OrderInteractor, CostManageInteractor } from '@/core'
  import ReceiptDetailModelOut from '@/views/finance-center/credit-manage/components/receipt-detail-model'
  import saleDetailTable from './sale-detail-table.vue'
  import productExpense from '@/components/productExpense'
  export default {
    name: 'costDetail',
    components: {
      ReceiptDetailModelOut,
      saleDetailTable,
      productExpense,
    },
    props: {
      detailId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        amendRow: '',
        dialogReceiptDetailVisible: false, //费用单详情弹窗
        tagStatus: 1,
        form: {},
        topTableData: [{}],
        footTableData: [],
        fileList: [], //附件
        salesGatheringVOS: [],
        moneySign: '',
        expenseList: [],
      }
    },
    watch: {
      detailId: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.showReceiptDetail(value)
        },
      },
    },
    methods: {
      async showReceiptDetail(value) {
        console.log(value)
        let response = await FinanceCostInteractor.getDetailApi({ costCode: value })
        if (response.code || response.code == '000000') {
          this.dialogReceiptDetailVisible = true
          this.$set(this.topTableData, 0, response.data)
          this.footTableData = response.data.costOrderList
          this.fileList = response.data.annex
            ? JSON.parse(response.data.annex)
            : ''
          this.form = response.data
        }
      },
      matchCurrency(str) {
        return str === '美元' ? '$' : '￥'
      },

      //采购单号跳转
      async purchaseOrderNumberClick(code) {
        // 采购订单号跳转
        let response = await PaymentInteractor.dinggetDetailForShowApi({
          businessId: code,
        })
        let data = response.data
        if (response.code == '000000') {
          let routeData = this.$router.resolve({
            path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
            query: {
              id: data.purchaseOrderId,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      close() {
        this.dialogReceiptDetailVisible = false
        this.form = {}
        this.topTableData = []
        this.footTableData = []
      },
      //订单总金额点击
      async totlePriceClcik(orderCode) {
        let response = await OrderInteractor.orderGetDetailByOrderCodeApi({ orderCode: orderCode })
        if (response.code === '000000') {
          let arr =
            response.data.orderPayVOS?.map((item) => {
              return {
                ...item,
                voucherNo: item.financeCode,
                actualReceivablePriceDate: item.payTime,
                actualReceivablePrice: item.totalPrice,
                voucherStatus: item.financeStatus,
              }
            }) || []
          arr = arr?.filter((ele) => {
            return ele.financeStatus == '4'
          })
          this.salesGatheringVOS = [...response.data.salesGatheringVOS, ...arr]
          this.moneySign = response.data.currency === '美元' ? '$' : '￥'
        }
      },
      //点击收款单号
      receiptNoClick(row) {
        this.$refs['ReceiptDetail'].showReceiptDetail(
          row.voucherNo,
          row.voucherStatus
        )
      },
      // 查看费用分摊
      async getExpense(row) {
        this.expenseList =
          await CostManageInteractor.getListProductExpenseByCostOrderId(
            row.costOrderId
          )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;

    .detail-header {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    .remark {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    /deep/ .el-divider--horizontal {
      margin: 30px 0;
    }
  }

  .link {
    text-decoration: underline;
    color: #409eff;
    cursor: pointer;
  }

  ::v-deep {
    .el-dialog__header {
      padding-bottom: 0;

      >.el-dialog__title {
        font-size: 16px;
      }
    }
  }
</style>