<template>
  <el-dialog
    title="询价单详情"
    width="1200px"
    class="custom-dialog"
    :visible.sync="inquiryDemandVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div class="container">
      <div class="custom-detail">
        <el-row>
          <el-col>
            <em>询盘单号：</em>
            <span class="page-link">
              <router-link
                :to="`/order/inquiryManage/inquiryDetail?enquiryId=${
                  detail.enquiryId
                }&noReturn=${true}`"
                target="_blank"
              >
                {{ detail.numberPrefix }}
              </router-link>
            </span>
          </el-col>
          <el-col>
            <em>创建人：</em>
            <span>{{ detail.creator || '--' }}</span>
          </el-col>
          <el-col>
            <em>所属地区：</em>
            <span>{{ detail.creatorArea || '--' }}</span>
          </el-col>
          <el-col>
            <em>创建时间：</em>
            <span>{{ detail.createTime || '--' }}</span>
          </el-col>
        </el-row>
      </div>

      <div class="c-table-wrap">
        <table class="custom-table">
          <thead>
            <tr>
              <th width="300">产品名称</th>
              <th width="100">产品图片</th>
              <th width="100">需求数量</th>
              <th width="300">产品类别</th>
              <th width="300">产品描述</th>
              <th width="300">印刷描述</th>
              <th width="100">印刷参考图</th>
              <th width="300">备注说明</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ detail.productEn || '--' }}</td>
              <td>
                <div class="img-wrap">
                  <el-badge
                    v-show="
                      detail.productImg &&
                      detail.productImg.split(',').length > 1
                    "
                    :value="
                      detail.productImg
                        ? detail.productImg.split(',').length
                        : ''
                    "
                  ></el-badge>
                  <el-image
                    v-if="detail.productImg"
                    :src="detail.productImg.split(',')[0]"
                    class="c_pointer"
                    @click="handlePreview(detail.productImg.split(','))"
                  ></el-image>
                  <div v-else>--</div>
                </div>
              </td>
              <td>{{ detail.amount || '--' }}</td>
              <td>{{ detail.category || '--' }}</td>
              <td>{{ detail.productDescribe || '--' }}</td>
              <td>{{ detail.printingDescribe || '--' }}</td>
              <td>
                <div class="img-wrap">
                  <el-badge
                    v-show="
                      detail.printingImg &&
                      detail.printingImg.split(',').length > 1
                    "
                    :value="
                      detail.printingImg
                        ? detail.printingImg.split(',').length
                        : ''
                    "
                  ></el-badge>
                  <el-image
                    v-if="detail.printingImg"
                    :src="detail.printingImg.split(',')[0]"
                    class="c_pointer"
                    @click="handlePreview(detail.printingImg.split(','))"
                  ></el-image>
                  <span v-else>--</span>
                </div>
              </td>
              <td>{{ detail.remarks || '--' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="c-table-wrap mt20">
        <table class="custom-table">
          <thead>
            <tr>
              <th class="index">#</th>
              <!-- 供应商名称 -->
              <th class="more">
                {{ $t('quoteDetails.SupplierName') }}
              </th>
              <!-- 产品图片 -->
              <th>{{ $t('quoteDetails.ProductImage') }}</th>
              <!-- 印刷logo位置/尺寸 -->
              <th class="more">
                {{ $t('quoteDetails.PrintingLogoPositionSize') }}
              </th>
              <!-- 产品颜色 -->
              <th>{{ $t('quoteDetails.ProductColor') }}</th>
              <!-- 起订量 -->
              <th>{{ $t('quoteDetails.MinimumOrderQuantity') }}</th>
              <!-- 起订价(元) -->
              <th>{{ $t('quoteDetails.MinimumOrderPrice') }}</th>
              <!-- 交期(工作日) -->
              <th>{{ $t('quoteDetails.DeliveryTime') }}</th>
              <!-- 印刷方式 -->
              <th>{{ $t('quoteDetails.PrintingMethod') }}</th>
              <!-- 打样费用(元) -->
              <th>{{ $t('quoteDetails.ProofingFee') }}</th>
              <!-- 打样时间(工作日) -->
              <th>{{ $t('quoteDetails.ProofingTime') }}</th>
              <!-- 箱规(外箱尺寸,装箱数,毛重) -->
              <th class="more">{{ $t('quoteDetails.BoxGauge') }}</th>
              <!-- 单个产品克重(克) -->
              <th>{{ $t('quoteDetails.SingleProductGramWeight') }}</th>
              <!-- 产品材质 -->
              <th>{{ $t('quoteDetails.ProductMaterials') }}</th>
              <!-- 产品尺寸 -->
              <th>{{ $t('quoteDetails.ProductSize') }}</th>
              <!-- 联系人 -->
              <th>{{ $t('quoteDetails.Contacts') }}</th>
              <!-- 联系方式 -->
              <th>{{ $t('quoteDetails.ContactDetails') }}</th>
              <!-- 产品链接 -->
              <th class="more">{{ $t('quoteDetails.ProductLinks') }}</th>
              <!-- 采购员意见/评价 -->
              <th class="more">
                {{ $t('quoteDetails.BuyerCommentsEvaluations') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="list.length">
            <tr v-for="(item, i) in list" :key="i">
              <td>{{ i + 1 }}</td>
              <td>
                {{ item.supplierName }}
                <i
                  :class="item.recommendFlag == 1 ? 'iconfont icon-good' : ''"
                ></i>
              </td>
              <td>
                <div class="img-wrap">
                  <el-badge
                    v-show="item.image && item.image.split(',').length > 1"
                    :value="item.image ? item.image.split(',').length : ''"
                  ></el-badge>
                  <el-image
                    v-if="item.image"
                    :src="item.image.split(',')[0]"
                    class="c_pointer"
                    @click="handlePreview(item.image.split(','))"
                  ></el-image>
                  <span v-else>--</span>
                </div>
              </td>
              <td>{{ item.actualLogoSize || '--' }}</td>
              <td>{{ item.productColor || '--' }}</td>
              <td>{{ item.quantity || '--' }}</td>
              <td>{{ item.price || '--' }}</td>
              <td>{{ item.completeDays || '--' }}</td>
              <td>{{ item.printingWay || '--' }}</td>
              <td>{{ item.proofingFee || '--' }}</td>
              <td>{{ item.proofingDays || '--' }}</td>
              <td>{{ item.proofingFee || '--' }}</td>
              <td>{{ item.productWeightg || '--' }}</td>
              <td>{{ item.material || '--' }}</td>
              <td>{{ item.productSize || '--' }}</td>
              <td>{{ item.contacts || '--' }}</td>
              <td>{{ item.contactInformation || '--' }}</td>
              <td>{{ item.linkHref || '--' }}</td>
              <td>{{ item.purchasingComments || '--' }}</td>
            </tr>
          </tbody>
          <tbody v-else class="empty">
            <tr>
              <td colspan="99">暂无数据</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="custom-detail mt20">
        <el-row>
          <el-col>
            <em>提交人：</em>
            <span>{{ detail.submitter || '--' }}</span>
          </el-col>
          <el-col>
            <em>提交时间：</em>
            <span>{{ detail.handlerTime || '--' }}</span>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="text-center mt20">
      <el-button @click="close()">关闭</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </el-dialog>
</template>

<script>
import { inquiryGetDetailForShow } from '@/api/order'
import { InquiryOrderInteractor } from '@/core/interactors/purchase/InquiryOrder'
export default {
  data() {
    return {
      detail: {},
      list: [],
      inquiryDemandVisible: false,
      showViewer: false, //图片预览参数
      imgUrl: '', //图片预览url
    }
  },
  methods: {
    // 初始化数据
    initLoading(id) {
      InquiryOrderInteractor.inquiryGetDetailForShowApi({ businessId: id }).then((res) => {
        if (res && res.code == '000000') {
          this.detail = res.data || {}
          this.list = res.data.inquiryOrderSupplierVOList || []
          this.inquiryDemandVisible = true
        }
      })
    },

    // 大图预览
    handlePreview(item) {
      if (Array.isArray(item)) {
        this.imgUrl = item
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    // 取消预览
    closeViewer() {
      this.showViewer = false
    },

    // 关闭弹窗重置表单
    close() {
      this.inquiryDemandVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  .container {
    min-height: 360px;
    max-height: 600px;
    overflow-y: scroll;
    i {
      font-size: 16px;
      color: #ffba00;
      font-weight: 600;
    }
    .el-row {
      em {
        width: 75px;
      }
    }
    .img-wrap {
      position: relative;
      .el-image {
        width: 56px;
        height: 56px;
        object-fit: cover;
        display: block;
        margin: 0 auto;
        border-radius: 2px;
      }
      .el-badge {
        position: absolute;
        top: -4px;
        right: 12px;
        z-index: 2;
      }
    }
  }
}
</style>
