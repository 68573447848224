<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input
            v-model.trim="form.name"
            maxlength="50"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <el-row class="t_a_c">
        <el-button type="primary" :loadng="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    supplierTypeInsertPO,
    supplierTypeUpdatePO,
  } from '@/api/client-supplier/supplier-setting'
  export default {
    name: 'SupplierType',
    data() {
      return {
        loading: false,
        title: '供应商类型',
        form: {
          name: '',
        },
        rules: {
          name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit(row) {
        if (row) {
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            if (this.form.supplierTypeId) {
              let response = await supplierTypeUpdatePO(this.form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '编辑成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              let response = await supplierTypeInsertPO(this.form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '新增成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
