<template>
  <div class="login-con">
    <div class="title">
      <span>
        {{ lang === 'zh' ? '安全验证' : 'Safety Verification' }}
      </span>
      <em @click="back">{{ lang === 'zh' ? '< 返回' : '< Back' }}</em>
    </div>

    <el-form label-width="0px" :model="form" :rules="rules" ref="form">
      <el-form-item prop="phone">
        <!-- 请输入手机号 -->
        <el-input
          type="input"
          v-model="phone"
          :placeholder="
            lang === 'zh'
              ? '请输入手机号'
              : 'Please enter your mobile phone number'
          "
          :disabled="true"
          clearable
        >
          <i slot="prefix" class="el-icon-phone"></i>
        </el-input>
      </el-form-item>

      <div class="verify">
        <el-form-item
          prop="smsCode"
          :rules="[
            {
              required: true,
              message: lang === 'zh' ? '请输入' : 'please enter',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <!-- 请输入验证码 -->
          <el-input
            type="password"
            v-model.trim="form.smsCode"
            :placeholder="
              lang === 'zh'
                ? '请输入验证码'
                : 'Please enter the verification code'
            "
            maxlength="10"
            clearable
          >
            <i slot="prefix" class="iconfont icon-anquanyanzheng"></i>
          </el-input>
        </el-form-item>

        <el-button @click="getCode" :disabled="disVerify">
          {{
            disVerify
              ? `${second}s ${lang === 'zh' ? '后可重新获取' : 'regain'}`
              : lang === 'zh'
              ? '获取验证码'
              : 'get code'
          }}
        </el-button>
      </div>
    </el-form>

    <!-- 登 录 -->
    <el-button class="login-btn" @click="login" type="primary">
      {{ lang === 'zh' ? '确认并登录' : 'Confirm and Sign in' }}
    </el-button>
  </div>
</template>

<script>
import { systemInteractor } from '@/core'
import { countdownMixin } from '@/utils/mixin-util'
export default {
  props: {
    lang: {
      type: String,
      default: 'zh',
    },
    phoneUuid: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
  },
  mixins: [countdownMixin],
  data() {
    return {
      form: {
        smsCode: '',
      },
      rules: {},
      second: 60,
      disVerify: false,
      dialogVisible: false,
    }
  },
  methods: {
    // 初始化
    init() {
      this.dialogVisible = true
    },

    // 获取验证码
    async getCode() {
      const res = await systemInteractor.anomalySendSms(this.phoneUuid)

      if (res && res.code === '000000') {
        this.disVerify = true
        this.startTimer()
      }
    },

    // 保存
    login() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$emit('handleLogin', this.form)
        }
      })
    },

    // 关闭
    handleClose() {
      this.$refs['form'].resetFields()
      this.dialogVisible = false
    },

    // 返回登录页
    back() {
      this.$parent.loginType = 0
    },
  },
}
</script>

<style lang="scss" scoped>
$bold: 'PingFang Bold';
.login-content {
  width: 960px;
  height: 464px;
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 2px 11px 6px rgba(234, 234, 234, 0.5);
  border-radius: 4px;
  overflow: hidden;
  .title {
    font-size: 21px;
    margin-bottom: 20px;
    font-family: $bold;
    color: #282c34;
    padding-bottom: 13px;
    border-bottom: 1px solid #282c34;
    display: flex;
    justify-content: space-between;
    em {
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
  }
  .login-btn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-family: $bold;
    color: #ffffff;
    border: 0;
    margin-top: 10px;
  }
  .verify {
    display: flex;
    justify-content: space-between;
    height: 46px;
    margin-bottom: 20px;
    .el-input {
      width: 230px;
    }
    .el-button {
      width: 120px;
    }
  }
}
</style>
