<template>
  <div class="order-node">
    <div>
      <el-row>
        <el-col :span="2">
          <h4 class="node-title">自定义跟单节点</h4>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="22" :offset="1">
          <el-radio @change="changeTemp" v-model="nodeTemplate" :label="1">
            节点模板一
          </el-radio>
          <el-button type="text" @click="addDefault">添加节点</el-button>
          <el-button type="text" @click="resetMould">恢复初始值</el-button>
          <el-form ref="defaultForm" :model="form">
            <el-table :data="form.defaultTable" border>
              <el-table-column label="节点序号" width="120" align="center">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column width="180" align="center">
                <template slot="header">
                  <span style="color: red">*</span>
                  <span>节点名称</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'defaultTable.' + scope.$index + '.nodeNameCn'"
                    :rules="{
                      required: defaultTableRequire,
                      message: '节点名称不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="scope.row.nodeNameCn"
                      placeholder="请输入"
                      clearable
                      :maxlength="50"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="180" align="center" :maxlength="50">
                <template slot="header">
                  <span style="color: red">*</span>
                  <span>节点名称（英文）</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'defaultTable.' + scope.$index + '.nodeNameEn'"
                    :rules="{
                      required: defaultTableRequire,
                      message: '节点名称（英文）不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="scope.row.nodeNameEn"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="date" width="180" align="center">
                <template slot="header">
                  <span>指定默认值</span>
                  <el-tooltip :content="tips" placement="top" effect="dark">
                    <template slot="content">
                      <span class="popperStyle">{{ tips }}</span>
                    </template>
                    <i
                      class="el-icon-warning-outline"
                      style="color: red; margin-left: 5px"
                    ></i>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.nodeType"
                    placeholder="请选择"
                    clearable
                    @change="
                      defaultChange(form.defaultTable, scope.row, scope.$index)
                    "
                  >
                    <el-option
                      v-for="item in nodeTypeOption"
                      :key="item.code"
                      :label="item.nameCn"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- <el-table-column width="180" align="center">
              <template slot="header" slot-scope="scope">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="selectAll"
                >
                  全选/反全选
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.checked" @change="checkItem">
                  需要附件
                </el-checkbox>
              </template>
            </el-table-column> -->
              <el-table-column width="180" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="deleteItem(form.defaultTable, scope)"
                  >
                    删除
                  </el-button>
                  <el-button
                    type="text"
                    v-if="scope.$index > 0"
                    @click="upItem(form.defaultTable, scope)"
                  >
                    上移
                  </el-button>
                  <el-button
                    type="text"
                    v-if="scope.$index < form.defaultTable.length - 1"
                    @click="downItem(form.defaultTable, scope)"
                  >
                    下移
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 20px">
      <el-row>
        <el-col :span="22" :offset="1">
          <el-radio @change="changeTemp" v-model="nodeTemplate" :label="2">
            节点模板二（自定义）
          </el-radio>
          <el-button type="text" @click="addCustom">添加节点</el-button>
          <el-form ref="customTableForm" :model="form">
            <el-table :data="form.customTable" border>
              <el-table-column label="节点序号" width="120" align="center">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column width="180" align="center">
                <template slot="header">
                  <span style="color: red">*</span>
                  <span>节点名称</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'customTable.' + scope.$index + '.nodeNameCn'"
                    :rules="{
                      required: customTableRequire,
                      message: '节点名称不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="scope.row.nodeNameCn"
                      placeholder="请输入"
                      clearable
                      :maxlength="50"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="180" align="center">
                <template slot="header">
                  <span style="color: red">*</span>
                  <span>节点名称（英文）</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'customTable.' + scope.$index + '.nodeNameEn'"
                    :rules="{
                      required: customTableRequire,
                      message: '节点名称（英文）不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="scope.row.nodeNameEn"
                      placeholder="请输入"
                      clearable
                      :maxlength="50"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="date" width="180" align="center">
                <template slot="header">
                  <span>指定默认值</span>
                  <el-tooltip placement="top" effect="dark">
                    <template slot="content">
                      <span class="popperStyle">{{ tips }}</span>
                    </template>
                    <i
                      class="el-icon-warning-outline"
                      style="color: red; margin-left: 5px"
                    ></i>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.nodeType"
                    placeholder="请选择"
                    clearable
                    @change="defaultChange(form.customTable, scope.row)"
                  >
                    <el-option
                      v-for="item in nodeTypeOption"
                      :key="item.code"
                      :label="item.nameCn"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- <el-table-column width="180" align="center">
              <template slot="header" slot-scope="scope">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="selectAll"
                >
                  全选/反全选
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.checked" @change="checkItem">
                  需要附件
                </el-checkbox>
              </template>
            </el-table-column> -->
              <el-table-column width="180" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="deleteItem(form.customTable, scope)"
                  >
                    删除
                  </el-button>
                  <el-button
                    type="text"
                    v-if="scope.$index > 0"
                    @click="upItem(form.customTable, scope)"
                  >
                    上移
                  </el-button>
                  <el-button
                    type="text"
                    v-if="scope.$index < form.customTable.length - 1"
                    @click="downItem(form.customTable, scope)"
                  >
                    下移
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import STATIC from '../../utils/staticData.js'
import _ from 'lodash'
export default {
  name: 'order-node',
  data() {
    return {
      radio: '',
      tips: '工厂交期：系统可以根据录入实际日期选择自动计算出周期。QC之间，物流货运，客户签收这些特殊默认值建议不修改。',
      operOption: STATIC.oper_list,
      value: '',
      checkAll: false,
      isIndeterminate: false,
      // defaultTable: [], // 默认模板
      // customTable: [{}], // 自定义模板
      nodeTemplate: 1, // 1节点模板一， 2节点模板二(自定义)
      form: {
        defaultTable: [], // 默认模板
        customTable: [{ nodeNameEn: '', nodeNameCn: '' }], // 自定义模板
      },
    }
  },
  props: {
    // 默认模板
    initDefaultList: {
      default: () => [],
      type: Array,
    },
    initCustomList: {
      default: () => [],
      type: Array,
    },
    initNodeList: {
      default: () => [],
      type: Array,
    },
    // 节点模板类型
    nodeTypeOption: {
      default: () => [],
      type: Array,
    },
  },
  computed: {
    defaultTableRequire() {
      return this.nodeTemplate === 1
    },
    customTableRequire() {
      return this.nodeTemplate === 2
    },
  },
  watch: {
    initDefaultList: {
      handler() {
        // this.defaultTable = _.cloneDeep(this.initDefaultList)
        this.form.defaultTable = _.cloneDeep(this.initDefaultList)
      },
      immediate: true,
      deep: true,
    },
    initCustomList: {
      handler() {
        // this.customTable = _.cloneDeep(this.initCustomList)
        this.form.customTable = _.cloneDeep(this.initCustomList)
        this.nodeTemplate = this.form.customTable?.every(
          (item) => item.tickButton === 1
        )
          ? 2
          : 1
        if (this.form.customTable.length === 0) {
          this.nodeTemplate = 1
        }
      },
      immediate: true,
      deep: true,
    },
    'form.defaultTable': {
      handler() {
        this.sendNodeData()
      },
      deep: true,
    },
    'form.customTable': {
      handler() {
        this.sendNodeData()
      },
      deep: true,
    },
  },
  methods: {
    // 全选
    selectAll() {
      let status = this.form.defaultTable.every((item) => item.checked)
      status ? this.setTableChecked(false) : this.setTableChecked(true)
      this.isIndeterminate = false
    },
    // 设置列表全选状态
    setTableChecked(checked) {
      this.form.defaultTable.forEach((item) => {
        this.$set(item, 'checked', checked)
      })
    },
    // 点击每一项框
    checkItem() {
      this.checkAll = this.form.defaultTable?.every((item) => item.checked)
      let someChecked = this.form.defaultTable?.some((item) => item.checked)
      this.isIndeterminate = !this.checkAll && someChecked
    },
    //
    // 重置模板
    resetMould() {
      this.$confirm(
        '<p style="text-align:left">是否恢复系统默认值，恢复有可能会清空当前节点配置的数据，请认真核对。</p>',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true,
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          this.form.defaultTable = _.cloneDeep(this.initNodeList)
        })
        .catch(() => {})
    },
    // 添加 （默认模板）
    addDefault() {
      this.form.defaultTable.push({})
    },
    // 删除
    deleteItem(table, item) {
      table.splice(item.$index, 1)
    },
    // 上移
    upItem(table, item) {
      let temp = table[item.$index]
      table.splice(item.$index, 1)
      table.splice(item.$index - 1, 0, temp)
    },
    // 下移
    downItem(table, item) {
      let temp = table[item.$index]
      table.splice(item.$index, 1)
      table.splice(item.$index + 1, 0, temp)
    },
    // 添加（自定义）
    addCustom() {
      this.form.customTable.push({})
    },
    // 选择模板
    changeTemp() {
      this.sendNodeData()
    },
    // 发送数据
    sendNodeData() {
      let obj = {}
      obj.trackingNodeSettingDTOList1 = this.form.defaultTable?.map(
        (item, index) => {
          return {
            ...item,
            nodeTemplateNum: 1,
            tickButton: this.nodeTemplate == 1 ? 1 : 0,
            nodeSerialNumber: index + 1,
          }
        }
      )
      obj.trackingNodeSettingDTOList2 = this.form.customTable?.map(
        (item, index) => {
          return {
            ...item,
            nodeTemplateNum: 2,
            tickButton: this.nodeTemplate == 2 ? 1 : 0,
            nodeSerialNumber: index + 1,
          }
        }
      )

      this.$emit('getNodeData', obj, this.nodeTemplate)
    },
    defaultChange(typeTable, item, index) {
      if (item.nodeType === '') return
      let arr = typeTable.map((ele) => ele.nodeType)
      let newArr = []
      newArr = arr.filter((ele) => ele === item.nodeType)
      if (newArr.length > 1) {
        this.$message.warning('相同指定默认值只能选择一次！')
        this.$set(item, 'nodeType', '')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.order-node {
  .node-title {
    display: inline-block;
    border-bottom: 1px solid red;
    box-sizing: border-box;
    padding: 5px 8px;
  }
}
.popperStyle {
  display: inline-block;
  width: 350px !important;
  line-height: 18px;
}
::v-deep {
  .el-form-item {
    margin-top: 18px;
  }
}
</style>