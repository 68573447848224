<!-- 我是客户详情 -->
<template>
  <div class="custom-container">
    <el-row>
      <div class="flex justify_start">
        <!-- 新增/编辑报价 -->
        <el-page-header :title="$t('companydetail.Return')" @back="goBack()"
          :content="info.customerName"></el-page-header>
      </div>
    </el-row>
    <!-- /基础资料 -->
    <h4>{{ $t('companydetail.BasicData') }}</h4>
    <el-row class="mt30">
      <!-- 客户编码 -->
      <el-col :span="4" style="display: flex">
        <span style="word-break: keep-all">
          {{ $t('cusManage.CustomerCode') }}：
        </span>
        <el-tooltip class="item" effect="dark" :content="info.customerCode" placement="top-start"
          :disabled="!info.customerCode">
          <span style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            ">
            {{ info.customerCode || '--' }}
          </span>
        </el-tooltip>
      </el-col>
      <!-- 客户名称 -->
      <el-col :span="4" style="display: flex">
        <span style="word-break: keep-all">
          {{ $t('cusManage.CustomerName') }}：
        </span>
        <el-tooltip class="item" effect="dark" :content="info.customerName" placement="top-start"
          :disabled="!info.customerName">
          <span style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            ">
            {{ info.customerName || '--' }}
          </span>
        </el-tooltip>
      </el-col>
      <el-col :span="4" style="display: flex">
        <!-- 邮箱 -->
        <span style="word-break: keep-all">
          {{ $t('cusManage.mailbox') }}：
        </span>
        <el-tooltip class="item" effect="dark" :content="linkMainEmails" placement="top-start"
          :disabled="!info.linkMainEmails">
          <span style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            ">
            {{ linkMainEmails || '--' }}
          </span>
        </el-tooltip>
      </el-col>
      <!-- 联系电话 -->
      <el-col :span="4">
        {{ $t('cusManage.ContactNumber') }}：{{ info.phone || '--' }}
      </el-col>
      <!-- 客户等级 -->
      <el-col :span="4" style="display: flex">
        {{ $t('cusManage.CustomerLevel') }}：
        <el-rate v-model="info.customerRank" disabled text-color="#ff9900"></el-rate>
      </el-col>
      <!-- 公司名称 -->
      <el-col :span="4" style="display: flex">
        <span style="word-break: keep-all">
          {{ $t('cusManage.CompanyName') }}：
        </span>
        <el-tooltip class="item" effect="dark" :content="info.companyName" placement="top-start"
          :disabled="!info.companyName">
          <span style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            ">
            {{ info.companyName || '--' }}
          </span>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row class="mt20">
      <el-col :span="4">Skype：{{ info.skype || '--' }}</el-col>
      <el-col :span="4">WhatsApp：{{ info.whatsapp || '--' }}</el-col>
      <el-col :span="4">WeChat：{{ info.wechat || '--' }}</el-col>
      <!-- 客户来源 -->
      <el-col :span="4">
        {{ $t('cusManage.CustomerSource') }}：{{
        lang == 'zh'
        ? info.customerSourceName || '--'
        : info.customerSourceNameEn || '--'
        }}
      </el-col>
      <!-- 客户类型 -->
      <el-col :span="4">
        {{ $t('cusManage.CustomerType') }}：{{
        info.customerType | customerTypeFilter(that)
        }}
      </el-col>
      <!-- 余额 -->
      <el-col :span="4">
        {{ $t('cusManage.Balance') }}：{{ info.balance || '--' }}
      </el-col>
    </el-row>
    <el-row class="mt20">
      <el-col :span="4" style="display: flex">
        <span style="word-break: keep-all">MKT：</span>
        <el-tooltip class="item" effect="dark" :content="info.mktRemark" placement="top-start"
          :disabled="!info.mktRemark">
          <span style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            ">
            {{ info.mktRemark || '--' }}
          </span>
        </el-tooltip>
      </el-col>
      <!-- 业务备注 -->
      <el-col :span="4" style="display: flex">
        <span style="word-break: keep-all">
          {{ $t('cusManage.BusinessRemarks') }}：
        </span>
        <el-tooltip class="item" effect="dark" :content="info.businessRemark" placement="top-start"
          :disabled="!info.businessRemark">
          <span style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            ">
            {{ info.businessRemark || '--' }}
          </span>
        </el-tooltip>
      </el-col>
      <el-col :span="4">
        <!-- 进入公海倒计时 -->
        {{ $t('cusManage.CountdownToEnteringTheHighSeas') }}：{{
        info.timeRemaining || '--'
        }}
      </el-col>
      <!-- 负责人 -->
      <el-col :span="4">
        {{ $t('cusManage.ResponsiblePerson') }}：{{
        lang == 'zh' ? info.belongerName || '--' : info.belongerNameEn || '--'
        }}
      </el-col>
      <!-- 分管人 -->
      <el-col :span="4">
        {{ $t('cusManage.PersonInCharge') }}：{{
        lang == 'zh' ? manager || '--' : managerEN || '--'
        }}
      </el-col>
      <!-- 创建时间 -->
      <el-col :span="4">
        {{ $t('cusManage.CreationTime') }}：{{ info.createTime }}
      </el-col>
    </el-row>
    <el-row class="mt20">
      <!-- 标签 -->
      <el-col :span="4">
        {{ $t('cusManage.Tag') }}：{{ info.tag | tagFilter(that) }}
      </el-col>
      <!-- 系统备注 -->
      <el-col :span="20" style="display: flex">
        {{ $t('cusManage.SystemRemark') }}
        <!-- 系统自动标记的，不支持修改，有退款则自动统计，多收退款不计入 -->
        <el-tooltip class="item" effect="dark" :content="$t('cusManage.tooltip')" placement="top">
          <i class="el-icon-question"></i>
        </el-tooltip>
        :
        <p class="ml10" style="word-break: break-all" v-if="info.systemRemark && info.systemRemark != '0'">
          <!-- 当前客户历史一共有 单退款，请仔细沟通和加大服务力度！-->
          {{ $t('cusManage.systemRemarkTips1') }}
          {{ info.systemRemark || '0' }}
          {{ $t('cusManage.systemRemarkTips2') }}，{{
          $t('cusManage.systemRemarkTips3')
          }}
        </p>
        <p v-else>--</p>
      </el-col>
    </el-row>
    <!-- 单据明细 -->
    <h4 class="mt30">{{ $t('cusManage.DocumentDetails') }}</h4>
    <el-row class="mt20">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabList" :key="item.code + tabKey"
          :label="lang == 'zh' ? item.name : $t(item.langKey)" :name="item.code" lazy>
          <CustomerDetailCustomer :key="item.code + tabKey" :code="activeName" :infoId="infoId" :companyId="companyId"
            v-if="item.code != 3"></CustomerDetailCustomer>
          <MailRecord v-else :email="email" ref="MailRecord"></MailRecord>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
  import { TabList } from './helper/detailBaseCustomer'
  import CustomerDetailCustomer from './components/customer-detail-customer'
  import CompanyDetailListInteractor from '@/core/interactors/new-customer-manage/detail'
  import MailRecord from './components/mail-record'
  import { getEmailListByInfoId } from '@/api/client-supplier/client-manage'
  import { mapGetters } from 'vuex'

  export default {
    name: 'CustomerDetail',
    components: { CustomerDetailCustomer, MailRecord },

    data() {
      return {
        tabKey: Math.random(),
        // tabList: TabList,
        activeName: '1',
        infoId: '',
        info: {},
        linkMainEmails: '', // 邮箱
        manager: '', //分管人
        managerEN: '',
        companyId: '',
        email: '',
        that: this,
      }
    },
    created() {
      this.infoId = this.$route.query.infoId
      this.companyId = this.$route.query.companyId
      this.getInfo()
      getEmailListByInfoId(this.infoId).then((res) => {
        if (res && res.code === '000000') {
          this.email = (res.data || []).join(',')
        }
      })
      if (this.lang == 'zh') {
        if (this.$route.query.a) {
          this.activeName = this.$route.query.a
        } else {
          this.activeName = '1'
        }
      } else {
        this.activeName = '11'
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
        permissions: 'user/permissions',
      }),
      tabList() {
        let arr = []
        if (this.lang == 'zh') {
          arr = [...TabList]
        } else {
          arr = TabList.filter((it) => {
            return [10, 11].includes(Number(it.code))
          })
          this.activeName = '10'
        }

        if (this.permissions.indexOf('Email:Record') > -1) {
          return arr
        } else {
          return arr.filter((it) => {
            return it.code != 3
          })
        }
      },
    },
    filters: {
      customerTypeFilter(val, that) {
        let menu = {
          // 0: '潜在客户',
          // 1: '正式客户',
          // 2: '黑名单客户',
          0: that.$t('cusManage.PotentialCustomers'),
          1: that.$t('cusManage.OfficialCustomer'),
          2: that.$t('cusManage.BlacklistedCustomers'),
        }
        return menu[val]
      },
      tagFilter(val, that) {
        let menu = {
          // 1: '有意向客户',
          // 2: '待跟进客户',
          // 3: '下单未签收客户',
          // 4: '成交客户',
          // 5: '重点客户',
          1: that.$t('cusManage.InterestedCustomers'),
          2: that.$t('cusManage.CustomersToBeFollowedUp'),
          3: that.$t('cusManage.UnsignedCustomer'),
          4: that.$t('cusManage.TransactionCustomers'),
          5: that.$t('cusManage.KeyCustomers'),
        }
        return menu[val] || '--'
      },
    },

    mounted() { },

    methods: {
      goBack() {
        this.$router.go(-1)
      },
      async getInfo() {
        let res = await CompanyDetailListInteractor.getCustomerInfo(this.infoId)
        if (res?.code === '000000') {
          this.info = res?.data
          this.linkMainEmails = res?.data?.linkMainEmails
            ?.map((it) => {
              return it.email
            })
            .toString()
          this.manager = res?.data?.managerVOs
            ?.map((it) => {
              return it.managerName
            })
            ?.toString()
          this.managerEN = res?.data?.managerVOs
            ?.map((it) => {
              return it.managerNameEn
            })
            .toString()
        }

      },
      handleClick(tab, event) {
        this.tabKey = Math.random()
        if (tab.name == '3') {
          setTimeout(() => {
            this.$refs.MailRecord && this.$refs.MailRecord[0] && this.$refs.MailRecord[0].getPageList()
          }, 500)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-page-header__title {
      word-break: keep-all !important;
    }
  }
</style>