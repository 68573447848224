var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"pro-container pro-base-msg",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":2}},[_c('h6',{staticClass:"left-h6"},[_vm._v(_vm._s(_vm.$t('productDetail.SpecificationList')))])]),_c('el-col',{staticClass:"right-content",attrs:{"span":22}},[_c('el-row',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.specSetClick}},[_vm._v(" "+_vm._s(_vm.$t('productDetail.SpecificationSetting'))+" ")])],1),_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSingleBol),expression:"!isSingleBol"}],attrs:{"span":21}},[_c('el-table',{ref:"dragTable",staticClass:"mt30 w100 table-data",attrs:{"data":_vm.singleSpecData,"stripe":"","border":""}},[(_vm.skuShow)?_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.ProductCode'),"prop":"sku","width":"120"}}):_vm._e(),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PackingMethod'),"prop":"packingMethodEn"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.ProductWeight'),"prop":"productWeightKgs"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.ProductSize'),"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.sizeUnit === 'FT' || row.sizeUnit === 'CM')?_c('el-row',[(row.productLengthIn)?_c('span',[_vm._v(" "+_vm._s(row.productLengthIn)+"''  ")]):_vm._e(),(row.productWidthIn)?_c('span',[(row.productLengthIn)?_c('span',[_vm._v("x ")]):_vm._e(),_vm._v(" "+_vm._s(row.productWidthIn)+"''  ")]):_vm._e(),(row.productHeightIn)?_c('span',[(row.productWidthIn || row.productLengthIn)?_c('span',[_vm._v(" x  ")]):_vm._e(),_vm._v(" "+_vm._s(row.productHeightIn)+"'' ")]):_vm._e()]):_vm._e(),(row.sizeUnit === 'IN' || !row.sizeUnit)?_c('el-row',[(row.productLengthCm)?_c('span',[_vm._v(" "+_vm._s(row.productLengthCm)+"''  ")]):_vm._e(),(row.productWidthCm)?_c('span',[(row.productLengthCm)?_c('span',[_vm._v("x ")]):_vm._e(),_vm._v(" "+_vm._s(row.productWidthCm)+"''  ")]):_vm._e(),(row.productHeightCm)?_c('span',[(row.productWidthCm || row.productLengthCm)?_c('span',[_vm._v(" x  ")]):_vm._e(),_vm._v(" "+_vm._s(row.productHeightCm)+"'' ")]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PrintingAreaSize')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.printUnit === 'FT' || row.printUnit === 'CM')?_c('el-row',[(row.printLengthCovert)?_c('span',[_vm._v(" "+_vm._s(row.printLengthCovert)+"''  ")]):_vm._e(),(row.printWidthCovert)?_c('span',[(row.printLengthCovert)?_c('span',[_vm._v("x ")]):_vm._e(),_vm._v(" "+_vm._s(row.printWidthCovert)+"'' ")]):_vm._e()]):_vm._e(),(row.printUnit === 'IN' || !row.printUnit)?_c('el-row',[(row.printLength)?_c('span',[_vm._v(" "+_vm._s(row.printLength)+"''  ")]):_vm._e(),(row.printWidth)?_c('span',[(row.printLength)?_c('span',[_vm._v("x ")]):_vm._e(),_vm._v(" "+_vm._s(row.printWidth)+"'' ")]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PackingQuantity'),"prop":"numberPerBox"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.GrossWeight'),"prop":"boxWeightKgs"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PackingSize')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.boxLengthIn)?_c('span',[_vm._v(" "+_vm._s(row.boxLengthIn)+"'' x  ")]):_vm._e(),(row.boxWidthIn)?_c('span',[_vm._v(" "+_vm._s(row.boxWidthIn)+"'' x  ")]):_vm._e(),(row.boxHeightIn)?_c('span',[_vm._v(_vm._s(row.boxHeightIn)+"''")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.Operate')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","icon":"el-icon-edit","circle":"","plain":""},on:{"click":function($event){return _vm.specEditClick(scope.row, scope.$index)}}})]}}])})],1)],1),_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSingleBol),expression:"isSingleBol"}],staticStyle:{"max-height":"400px","overflow":"hidden","overflow-y":"auto"}},[_c('el-table',{ref:"dragTable",staticClass:"mt30 w100 table-data",attrs:{"data":_vm.tableData,"stripe":"","border":"","row-key":"id"}},[(_vm.skuShow)?_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.ProductCode'),"prop":"sku","width":"120"}}):_vm._e(),_vm._l((_vm.tableSpecsType),function(item,index){return _c('el-table-column',{key:index,attrs:{"align":"center","label":item,"prop":'specsValue' + (index + 1)}})}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.ProductWeight'),"prop":"productWeightKgs"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.ProductSize'),"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.sizeUnit === 'FT' || row.sizeUnit === 'CM')?_c('el-row',[(row.productLengthIn)?_c('span',[_vm._v(" "+_vm._s(row.productLengthIn)+"''  ")]):_vm._e(),(row.productWidthIn)?_c('span',[(row.productLengthIn)?_c('span',[_vm._v("x ")]):_vm._e(),_vm._v(" "+_vm._s(row.productWidthIn)+"''  ")]):_vm._e(),(row.productHeightIn)?_c('span',[(row.productWidthIn || row.productLengthIn)?_c('span',[_vm._v(" x  ")]):_vm._e(),_vm._v(" "+_vm._s(row.productHeightIn)+"'' ")]):_vm._e()]):_vm._e(),(row.sizeUnit === 'IN' || !row.sizeUnit)?_c('el-row',[(row.productLengthCm)?_c('span',[_vm._v(" "+_vm._s(row.productLengthCm)+"''  ")]):_vm._e(),(row.productWidthCm)?_c('span',[(row.productLengthCm)?_c('span',[_vm._v("x ")]):_vm._e(),_vm._v(" "+_vm._s(row.productWidthCm)+"''  ")]):_vm._e(),(row.productHeightCm)?_c('span',[(row.productWidthCm || row.productLengthCm)?_c('span',[_vm._v(" x  ")]):_vm._e(),_vm._v(" "+_vm._s(row.productHeightCm)+"'' ")]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PrintingAreaSize')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.printUnit === 'FT' || row.printUnit === 'CM')?_c('el-row',[(row.printLengthCovert)?_c('span',[_vm._v(" "+_vm._s(row.printLengthCovert)+"''  ")]):_vm._e(),(row.printWidthCovert)?_c('span',[(row.printLengthCovert)?_c('span',[_vm._v("x ")]):_vm._e(),_vm._v(" "+_vm._s(row.printWidthCovert)+"'' ")]):_vm._e()]):_vm._e(),(row.printUnit === 'IN' || !row.printUnit)?_c('el-row',[(row.printLength)?_c('span',[_vm._v(" "+_vm._s(row.printLength)+"''  ")]):_vm._e(),(row.printWidth)?_c('span',[(row.printLength)?_c('span',[_vm._v("x ")]):_vm._e(),_vm._v(" "+_vm._s(row.printWidth)+"'' ")]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PackingQuantity'),"prop":"numberPerBox"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('other.packVolume'),"prop":"boxVolumeM"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.Operate'),"show-overflow-tooltip":"","width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","icon":"el-icon-edit","circle":"","plain":""},on:{"click":function($event){return _vm.specEditClick(scope.row, scope.$index)}}})]}}])})],2)],1)],1),_c('ProductSpecListModel',{ref:"productSpecListModel",on:{"spec-row":_vm.specRow}}),(_vm.showViewer)?_c('el-image-viewer',{attrs:{"on-close":_vm.closeViewer,"url-list":[_vm.imgUrl]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }