<!--
  功能：客户分管组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月16日 20:00:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard title="客户分管" :loading="loading">
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="customer-charge-item">
          <div class="content" @click="showDetail(item)">
            <div class="left">
              <div class="top">
                <span style="color: #282c34">
                  ({{ item.branchedName }})申请分管客户
                </span>
                <span :style="boldStyle.bold">{{ item.customerCode }}</span>
              </div>
              <div class="bottom">
                <span class="user">{{ item.customerName }}</span>
                <span>{{ item.companyName | lengthLimit }}</span>
                <span class="remove" @click.stop="handleRemove(item)">
                  移除申请
                </span>
              </div>
            </div>
            <div class="right index-card-time">
              {{ item.applyTime | timeFilter }}
            </div>
            <!-- 确认分管弹窗 -->
            <el-dialog
              :visible.sync="show"
              title="确认分管"
              width="600px"
              append-to-body
              @close="onClose"
            >
              <div class="erpdialog-detail-style" style="border: none">
                <el-row class="line">
                  <el-col :span="8" class="item">
                    <em style="paddingtop: 0">客户编码</em>
                    <span
                      class="bold erp-table-link"
                      style="color: #409eff"
                      @click="goDetail"
                    >
                      {{ current.customerCode }}
                    </span>
                  </el-col>
                  <el-col :span="8" class="item">
                    <em style="paddingtop: 0">客户姓名</em>
                    <span class="bold">{{ current.customerName }}</span>
                  </el-col>
                  <el-col :span="8" class="item">
                    <em style="paddingtop: 0">公司名称</em>
                    <span class="bold">
                      <el-tooltip
                        :content="current.companyName"
                        placement="top"
                        :disabled="showTopTip(current.companyName)"
                      >
                        <span>
                          {{ current.companyName | lengthLimit }}
                        </span>
                      </el-tooltip>
                    </span>
                  </el-col>
                  <el-col :span="8" class="item" style="margin-top: 30px">
                    <em>申请人</em>
                    <span class="bold">{{ current.branchedName }}</span>
                  </el-col>
                  <el-col :span="8" class="item" style="margin-top: 30px">
                    <em>申请时间</em>
                    <span class="bold">
                      {{ current.applyTime | timeFilter(true) }}
                    </span>
                  </el-col>
                </el-row>
              </div>

              <div class="footer-wrapper mt30 t_a_c" style="text-align: center">
                <el-button type="primary" class="ml30" @click="confirm">
                  确认
                </el-button>
                <el-button @click="onClose">取 消</el-button>
              </div>
            </el-dialog>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import { HomeInteractor } from '@/core'
  export default {
    name: 'CustomerCharge',
    mixins: [mixins],
    props: {},

    // 组件状态值
    data() {
      return {
        list: [],
        show: false,
        current: {},
      }
    },
    computed: {},
    filters: {
      lengthLimit(val) {
        if (val?.length > 100) {
          return val.substring(0, 100) + '...'
        } else {
          return val ? val : '--'
        }
      },
    },

    methods: {
      showTopTip(val) {
        return val?.length > 100 ? false : true
      },
      showDetail(item) {
        this.current = item
        this.show = true
      },
      onClose() {
        this.show = false
      },
      goDetail() {
        const { infoId } = this.current
        let routeData = this.$router.resolve({
          path: '/customer-manage/index/customer-detail',
          query: {
            infoId,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
      // 查询
      getList() {
        this.loading = true
        HomeInteractor.merchantsList({
          belongerId: this.userInfo.userId,
        }).then((res) => {
          if (res?.code === '000000') {
            this.list = res.data.data
          }
          this.loading = false
        })
      },

      confirm() {
        const { branchedId, infoId, managerApplyId } = this.current
        HomeInteractor.agreeBranched({
          branchedId,
          infoId,
          managerApplyId,
        }).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
            this.show = false
          }
          this.getList()
        })
      },
      // 移除申请
      handleRemove({ branchedId, infoId, managerApplyId }) {
        this.$confirm(
          '是否确认移除，移除之后，不可恢复，如果申请人需要继续分管客户，他需要重新发起！',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(async () => {
            const params = { branchedId, infoId, managerApplyId }
            const { code } = await HomeInteractor.removeBranchedApi(params)
            if (code === '000000') {
              this.$message.success('移除成功！')
              this.getList()
            }
          })
          .catch(() => {})
      },
    },
    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .customer-charge-item {
    position: relative;
    .content {
      display: flex;
      cursor: pointer;
      .left {
        display: flex;
        flex-direction: column;
        color: #000;
        flex: 1;
        .top {
          margin-bottom: 10px;
        }
        .bottom {
          color: #5e5e5e;
          font-size: 12px;
          .user {
            margin-right: 13px;
          }
          .remove {
            position: absolute;
            bottom: 8px;
            right: 0;
            color: #409eff;
          }
        }
      }
      .right {
        padding-left: 10px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
</style>
