<template>
  <div class="message-container">
    <ul class="left">
      <li v-for="(item, index) in tabs" :key="item.msgType" class="nav-item"
        :class="{ active: item.msgType === currentType }" @click="handleClick(item, index)">
        <span class="title">{{ item.msgTypeShow }}</span>
        <span class="el-badge__content count" v-if="item.msgNum > 0">
          {{ item.msgNum }}
        </span>
        <span class="line" v-if="item.msgType === currentType"></span>
      </li>
    </ul>
    <div class="right">
      <div class="top">
        <p :class="{ active: unRead }" @click="tabclick(true)">未读消息</p>
        <p :class="{ active: !unRead }" @click="tabclick(false)">已读消息</p>
        <div class="line"></div>
        <div class="search">
          <el-button class="mr-default" @click="markALL" v-if="unRead">
            全部标记为已读
          </el-button>
          <el-date-picker v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" class="mr-default"></el-date-picker>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="reset">清空</el-button>
        </div>
      </div>
      <transition name="slide-fade" mode="out-in">
        <div class="middle" :key="unRead">
          <template v-if="list.length > 0">
            <div class="message-item" v-for="msg in list" :key="msg.messageId"
              :style="{ cursor: msg.readFlag === 1 ? 'pointer' : '' }" @click="markSingle(msg)">
              <span class="mark-dot" v-if="unRead" :style="{
                  backgroundColor: msg.readFlag === 1 ? 'red' : '#909399',
                }"></span>
              <span class="title">【{{ msg.title }}】</span>
              <span class="content">
                {{ msg.content }}
              </span>
              <span class="msg-link" v-if="msg.sysMessageType == 9" @click="getDetail(msg)">
                立即查看>>
              </span>
              <span class="time">{{ msg.publishTime | timeFormatter }}</span>
            </div>
          </template>
          <p v-else class="empty">暂无数据</p>
        </div>
      </transition>
      <div class="bottom">
        <el-pagination style="width: 700px" background :current-page="pageNumber"
          layout="total, sizes,  prev, pager, next, jumper" :page-size="pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100, 200]" :total="total" :pager-count="5" @current-change="pageChange"
          @size-change="sizeChange" />
      </div>
    </div>
  </div>
</template>

<script>
  import * as _ from 'dayjs'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import { MessageInteractors } from '@/core'

  export default {
    name: 'Index',
    components: {},
    data() {
      return {
        tabs: [],
        currentIndex: '-1',
        currentType: '',
        unRead: true,
        time: '',
        pageNumber: 1,
        total: 0,
        pageSize: 10,
        pageCount: 10,
        list: [],
        startTime: '',
      }
    },
    methods: {
      markALL() {
        const unreadMsg = this.list.filter((msg) => msg.readFlag === 1)
        if (!unreadMsg || unreadMsg.length === 0) {
          return this.$message.warning('没有未读消息')
        }
        const messageId = this.list.map((i) => i.messageId),
          { userId, tenantId } = this.userInfo
        MessageInteractors.readMessage({
          messageId,
          sourceSys: 'erp',
          tenantId,
          userId,
        }).then((res) => {
          if (res.code === '000000') {
            this.$message.success('操作成功')
            this.pageNumber = 1
            this.list.forEach((i) => {
              i.readFlag = 0
            })
            const msgNum = this.tabs[this.currentIndex].msgNum
            if (msgNum >= this.list.length) {
              this.tabs[this.currentIndex].msgNum = msgNum - this.list.length
            }
          }
        })
      },

      markSingle(msg) {
        let { messageId, readFlag } = msg
        if (!this.unRead || readFlag !== 1) {
          return
        }
        const { userId, tenantId } = this.userInfo

        MessageInteractors.readMessage({
          messageId: [messageId],
          sourceSys: 'erp',
          tenantId,
          userId,
        }).then((res) => {
          if (res.code === '000000') {
            msg.readFlag = 0
            if (this.tabs[this.currentIndex].msgNum > 0) {
              this.tabs[this.currentIndex].msgNum--
            }
          }
        })
      },

      getDetail({ extra }) {
        navigateTo(this, {
          pageType: PAGE_TYPES.trackDetail,
          roleJudge: false,
          orderTrackingId: extra,
        })
      },

      tabclick(unread) {
        if (this.unRead === unread) {
          return
        }
        this.unRead = unread
        this.reset()
        this.getList()
      },
      reset() {
        this.pageSize = 10
        this.pageNumber = 1
        this.pageCount = 10
        this.startTime = ''
        this.endTime = ''
        this.time = ''
        this.search()
      },
      search() {
        if (this.time) {
          this.startTime = _(this.time[0]).format('YYYY-MM-DD HH:mm:ss')
          this.endTime = _(this.time[1])
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .format('YYYY-MM-DD HH:mm:ss')
        }
        this.getList(this.currentType)
      },
      handleClick(item, index) {
        const { msgType } = item
        this.currentIndex = index
        if (this.currentType === msgType) {
          return
        }
        this.currentType = msgType
        this.unRead = true
        this.reset()
        this.getList()
      },
      pageChange(val) {
        this.pageNumber = val
        this.getList()
      },
      sizeChange(val) {
        this.pageNumber = 1
        this.pageSize = val
        this.getList()
      },
      // 获取消息列表
      getList(sysMessageType) {
        sysMessageType = sysMessageType || this.currentType
        const { tenantId, userId } = this.userInfo
        const requestParams = {
          startTime: this.startTime,
          endTime: this.endTime,
          sourceSys: 'erp',
          messageType: '1',
          readFlag: this.unRead ? 1 : 0,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          tenantId, //测试
          userId, //测试，
          sysMessageType,
        }

        MessageInteractors.getUserMessagePage(requestParams).then((res) => {
          if (res.code === '000000') {
            const { total, list } = (res.data || {})
            this.list = list || []
            this.total = total || 0
          }
        })
      },

      getMsgTypeNum() {
        const userInfoStr = localStorage.getItem('setUserInfo')
        this.userInfo = userInfoStr ? JSON.parse(userInfoStr) : {}
        const { userId, tenantId } = this.userInfo
        MessageInteractors.getMsgTypeNum({
          sourceSys: 'erp',
          tenantId,
          userId,
        }).then((res) => {
          if (res.code === '000000') {
            this.tabs = res.data
            this.currentType = this.tabs[0].msgType
            this.currentIndex = 0
            this.getList(this.currentType)
          }
        })
      },
    },
    filters: {
      timeFormatter(val) {
        return _(val).format('MM-DD HH:mm')
      },
    },

    created() {
      this.getMsgTypeNum()
    },
  }
</script>

<style lang="scss" scoped>
  .message-container {
    padding: 0 !important;
    overflow-x: scroll !important;
    display: flex;
    background-color: #fff;

    .left {
      height: 100%;

      li {
        padding: 20px;
        height: 60px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #f5f7fa;
        }

        .count {
          margin-left: 4px;
        }

        .line {
          width: 2px;
          display: inline-block;
          height: 60px;
          background-color: #1890ff;
          position: absolute;
          top: 0;
          right: -1px;
        }

        &.active {
          background-color: rgba(68, 170, 238, 0.3);
        }
      }
    }

    .right {
      border-left: 1px solid #eee;
      overflow: hidden;
      padding: 15px;
      flex: 1;
      display: flex;
      flex-direction: column;

      .top {
        p {
          display: inline-block;
          padding: 10px;
          cursor: pointer;

          &.active {
            color: #1890ff;
            font-weight: bold;
          }
        }

        .line {
          height: 1px;
          background-color: #eee;
        }

        .search {
          padding: 10px;
          padding-left: 0px;
          padding-bottom: 15px;
        }
      }

      .middle {
        flex: 1;
        overflow: auto;
        position: relative;

        .message-item {
          word-break: break-all;
          padding: 15px 5px;
          border-bottom: 1px solid #eee;

          &:hover {
            background-color: #f5f7fa;
          }

          .mark-dot {
            display: inline-block;
            height: 10px;
            width: 10px;
            border-radius: 10px;
            margin-right: 10px;
          }

          .title {
            position: relative;
            left: -10px;
            font-weight: bold;
            color: black;
            line-height: 1.6;
          }

          .content {
            color: black;
          }

          .time {
            margin-left: 15px;
            color: #5e5e5e;
            font-size: 13px;
          }

          .msg-link {
            color: #1890ff;
            cursor: pointer;
          }
        }

        .empty {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .bottom {
        border-top: 1px solid #eee;
      }
    }

    .mr-default {
      margin-right: 10px;
    }
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    animation: slide 0.2s;
  }

  @keyframes slide {
    0% {
      transform: translateX(30%);
    }

    50% {
      transform: translateX(70%);
    }

    100% {
      transform: translateX(100%);
    }
  }
</style>
