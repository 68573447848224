<template>
  <el-dialog
    title="退款提示"
    :visible.sync="refundDialogModel"
    width="30%"
    :before-close="cancel"
  >
    <p>{{ detail.amountName }}</p>
    <div class="mt10">
      <el-radio v-model="detail.amountStatus" :label="1">启用</el-radio>
      <el-radio v-model="detail.amountStatus" :label="0">停用</el-radio>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      // refundDialog:false
      detail: {},
    }
  },
  props: {
    refundDialogModel: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    refundDialogModel: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.detail = { ...this.info }
        }
      },
      immediate: true,
    },
  },
  methods: {
    cancel() {
      this.detail = {}
      this.$emit('update:refundDialogModel', false)
    },
    save() {
      this.$emit('saveRefund', this.detail)
      this.cancel()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .dialog-footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>