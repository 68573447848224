
// companyCode: 'CU22834491', //公司编码
// "companyName": "都是",
// customerRank: '1级',
// companyType: 1,
// website: 'baidu.com',
// companyAddress: '合肥',
// postcode: '我是邮编',
// faxNumber: '传真号',
// remark: '我是备注',
export const companyColumns = [
  {
    label: '公司编码',
    prop: 'companyCode',
    isShow: true,
    checkable: false,
    width: 160,
    enWidth: 180,
    langKey: 'cusCenter.companycode',
  },

  {
    label: '公司名称',
    prop: 'companyName',
    isShow: true,
    checkable: false,
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.CompanyName',
  },

  {
    label: '公司等级',
    prop: 'companyLevel',
    isShow: true,
    checkable: false,
    width: 160,
    enWidth: 180,
    langKey: 'cusCenter.companyLevel',
  },
  {
    label: '公司类型',
    prop: 'status',
    isShow: true,
    width: 160,
    enWidth: 180,
    langKey: 'cusDetail.CompanyType',
  },
  {
    label: '网址',
    prop: 'website',
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.WebAddress',
  },
  {
    label: '公司地址',
    prop: 'companyAddress',
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.CompanyAddress',
  },
  {
    label: '邮编',
    prop: 'postcode',
    width: 160,
    enWidth: 180,
    langKey: 'cusDetail.ZipCode',
  },
  {
    label: '传真',
    prop: 'faxNumber',
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.FaxNumber',
  },
  {
    label: '备注',
    prop: 'remark',
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.Remark',
  },



]



export const customerColumns = [
  //table列字段
  {
    label: '客户编码',
    prop: 'customerCode',
    isShow: true,
    checkable: false,
    width: 130,
    enWidth: 130,
    langKey: 'cusManage.CustomerCode',
  },

  {
    label: '客户名称',
    prop: 'linkman',
    isShow: true,
    checkable: false,
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.CustomerName',
  },


  {
    label: '客户等级',
    prop: 'customerRank',
    isShow: true,
    checkable: false,
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.CustomerLevel',
  },


  {
    label: '联系电话',
    prop: 'phone',
    isShow: true,
    checkable: false,
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.ContactNumber',
  },
  {
    label: '客户邮箱',
    prop: 'email',
    isShow: true,
    checkable: false,
    width: 160,
    enWidth: 180,
    langKey: 'cusCenter.CustomerMailbox',
  },

  {
    label: '客户类型',
    prop: 'customerType',
    isShow: true,
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.CustomerType',
  },
  {
    label: '客户来源',
    prop: 'customerSourceName',
    isShow: true,
    width: 120,
    enWidth: 180,
    langKey: 'cusManage.CustomerSource',
  },
  {
    label: '客户公司名称',
    prop: 'companyName',
    isShow: true,
    width: 120,
    enWidth: 180,
    langKey: 'cusDetail.CCompanyName',
  },
  {
    label: '回归公海剩余时间',
    prop: 'timeRemaining',
    isShow: true,
    width: 150,
    enWidth: 300,
    langKey: 'cusManage.ReturnToTheHighSeasRemainingTime',
  },

  {
    label: '订单总成交量',
    prop: 'totalOrderNum',
    isShow: false,
    width: 160,
    enWidth: 200,
    langKey: 'cusManage.OrderVolume',
  },
  {
    label: '订单总成交额',
    prop: 'totalOrderAmountUs',
    isShow: false,
    width: 160,
    enWidth: 200,
    langKey: 'cusManage.TotalOrderAmount',
  },
  {
    label: '负责人',
    prop: 'belongerName',
    isShow: true,
    width: 160,
    enWidth: 180,
    langKey: 'cusManage.ResponsiblePerson',
  },
  {
    label: '分管人',
    prop: 'allCharge',
    isShow: true,
    width: 160,
    enWidth: 180,
    langKey: 'cusDetail.PersonInCharge',
  },
  {
    label: '状态',
    prop: 'status',
    isShow: true,
    width: 160,
    enWidth: 180,
    langKey: 'cusCenter.Status',
  },
]


export const companyLevelList = [
  {
    code: 1,
    label: "★"
  },
  {
    code: 2,
    label: "★★"
  },
  {
    code: 3,
    label: "★★★"
  },
  {
    code: 4,
    label: "★★★★"
  },
  {
    code: 5,
    label: "★★★★★"
  },

]



export const customerStatuList = [{
  code: 1,
  name: '已完成',
  nameEn: 'Completed'

},
{
  code: 2,
  name: '修改审核中',
  nameEn: 'Modifying And Reviewing'

},
{
  code: 3,
  name: '客户转入黑名单审核中',
  nameEn: 'Customer Transferred to blacklist review'

},
{
  code: 4,
  name: '审核驳回',
  nameEn: 'Review rejected'

},

]


