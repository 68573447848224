<template>
  <div>
    <el-popover placement="bottom" :trigger="triggerType" v-if="showTable">
      <el-table
        :data="tableData"
        border
        :header-cell-style="{ 'text-align': 'center' }"
        :row-style="{ 'text-align': 'center' }"
      >
        <el-table-column width="100" label="产品图" align="center">
          <template slot-scope="scope">
            <erp-image :imgs="scope.row.parentUrl"></erp-image>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          property="skuId"
          label="产品编码"
        ></el-table-column>
        <el-table-column width="150" label="产品名称" align="center">
          <template slot-scope="scope">
            <span>
              {{ scope.row.productName || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          property="parentSpecsValue"
          label="规格"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.parentSpecsValue || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          property="expectOutNum"
          :label="numName"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.numCombination || '--' }}
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="reference"
        class="popover-title"
        :class="
          itemTitle === '主'
            ? 'green-box'
            : itemTitle === '子'
            ? 'origin-box'
            : ''
        "
      >
        {{ itemTitle }}
      </span>
    </el-popover>
  </div>
</template>
<script>
export default {
  name: 'popover-ship',
  props: {
    itemTitle: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    showTable: {
      type: Boolean,
      default: true,
    },
    triggerType: {
      type: String,
      default: 'hover',
    },
    numName: {
      type: String,
      default: '订单数量',
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.popover-title {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 13px;
  margin-left: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.popover-title:hover {
  cursor: pointer;
}
.green-box {
  background: rgba(112, 182, 3, 0.2);
  color: #70b603;
}
.origin-box {
  background: rgba(245, 154, 35, 0.2);
  color: #f59a23;
}
</style>