<!--
  功能：客户跟进提醒组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月16日 20:00:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard title="客户跟进提醒" :loading="loading">
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="customer-follow-reminder-item">
          <div
            class="content"
            @click="clientFollowClick(item)"
            :style="{ cursor: active === 'ME' ? 'pointer' : '' }"
          >
            <div class="reminder-item--top">
              <span class="topic" :style="boldStyle.bold">
                {{ item.companyName }}
              </span>
              -
              <span class="sender" :style="boldStyle.bold">
                {{ item.customerName }}
              </span>
            </div>
            <div class="reminder-item--bottom">
              <template v-if="item.remainFollowDays > 0">
                <span class="mr-5">剩余回归公海时间:</span>
                <span
                  class="mr-5"
                  :style="{
                    color: item.remainFollowDays < 4 ? '#E02020' : '',
                    ...boldStyle.bold,
                  }"
                >
                  {{ item.remainFollowDays }}
                </span>
                <span>天</span>
              </template>

              <span class="mr-5" v-else-if="item.remainFollowDays === 0">
                <span
                  :style="{
                    ...boldStyle.bold,
                  }"
                >
                  今日
                </span>
                回归公海
              </span>
              <span v-else>
                回归公海时间已超过
                <span
                  :style="{
                    ...boldStyle.bold,
                    color: '#E02020',
                  }"
                >
                  {{ Math.abs(item.remainFollowDays) }}
                </span>
                天
              </span>
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else />
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'

  import { HomeInteractor } from '@/core'
  export default {
    name: 'CustomerFollowReminder',
    mixins: [mixins],
    props: {},

    // 组件状态值
    data() {
      return {
        list: [],
      }
    },
    computed: {},

    methods: {
      getList() {
        this.loading = true
        HomeInteractor.listFollowAgencyPage({
          userId: this.userInfo.userId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.list = res.data.data
          } else {
            this.list = []
          }
          this.loading = false
        })
      },

      clientFollowClick({ infoId }) {
        this.$router.push({
          path: '/client-supplier/client-manage-detail',
          query: {
            customerId: infoId,
            operate: 'show',
            tabSpanCurrent: 1,
          },
        })
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .customer-follow-reminder-item {
    font-size: 14px;
    .reminder-item--top {
      color: #282c34;
      margin-bottom: 6px;
      font-size: 14px;
    }
    .reminder-item--bottom {
      font-size: 12px;
      color: #5e5e5e;
      .mr-5 {
        margin-right: 5px;
      }
    }
  }
</style>
