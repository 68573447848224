<template>
  <div class="custom-wrap">
    <el-row>
      <div class="flex justify_start">
        <el-page-header
          :title="$t('other.back')"
          @back="goBack()"
          :content="$t('Documentary.newAdd')"
        ></el-page-header>
      </div>
    </el-row>

    <!-- 基本信息 -->
    <div class="track-content">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <!-- 基本信息 -->
        <h4 class="mb20">{{ $t('Documentary.BI') }}</h4>
        <el-row>
          <el-col :span="6">
            <!-- 采购订单编号 -->
            <el-form-item
              :label="$t('Documentary.PON') + '：'"
              prop="purchaseOrderNumber"
              :label-width="lang === 'en' ? '200px' : '120px'"
              :rules="[
                {
                  required: true,
                  message: $t('Documentary.PE'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model.trim="form.purchaseOrderNumber"
                @blur="purchaseOrderNumberBlur"
                :placeholder="$t('Documentary.PE')"
                clearable
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 关联编号 -->
            <el-form-item
              :label="$t('Documentary.AN') + '：'"
              prop="guanlianCode"
              :label-width="lang === 'en' ? '200px' : '130px'"
              class="label-style"
            >
              <em>
                <el-tooltip
                  placement="top"
                  effect="dark"
                  :content="$t('Documentary.AONON')"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </em>
              <el-select
                v-model="guanlianCode"
                :placeholder="$t('Documentary.PC')"
                @change="purchaseOrderChange"
                clearable
              >
                <el-option
                  v-for="item in purchaseOrderList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 是否有样品 -->
            <el-form-item
              :label="$t('Documentary.ITAS') + '：'"
              prop="sample"
              :label-width="lang === 'en' ? '200px' : '140px'"
            >
              <el-select
                v-model="form.sample"
                :placeholder="$t('Documentary.PC')"
                clearable
              >
                <el-option
                  :label="$t('Documentary.none')"
                  value="0"
                ></el-option>
                <el-option
                  :label="$t('Documentary.have')"
                  value="1"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 工厂交期(周期) -->
            <el-form-item
              :label="$t('Documentary.FD') + '：'"
              prop="deliveryDays"
              :label-width="lang === 'en' ? '200px' : '160px'"
              class="label-style"
            >
              <em>
                <el-tooltip
                  placement="top"
                  effect="dark"
                  :content="$t('Documentary.FDPOD')"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </em>
              <el-input
                v-model.trim="form.deliveryDays"
                :placeholder="$t('Documentary.PE')"
                clearable
                maxlength="4"
                :disabled="true"
              >
                <template slot="append">{{ $t('Documentary.day') }}</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 客户代表 -->
            <el-form-item
              :label="$t('Documentary.CR') + '：'"
              :label-width="lang === 'en' ? '200px' : '120px'"
            >
              {{
                productList.length
                  ? lang === 'en'
                    ? productList[0].businessNameEn || '--'
                    : productList[0].businessName || '--'
                  : '--'
              }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 地区 -->
            <el-form-item
              :label="$t('Documentary.area') + '：'"
              :label-width="lang === 'en' ? '200px' : '130px'"
            >
              {{ form.buyerArea | buyerAreaTransl }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 供应商名称 -->
            <el-form-item
              :label="$t('Documentary.supplierName') + '：'"
              prop="supplierName"
              :label-width="lang === 'en' ? '200px' : '140px'"
            >
              {{ info.supplierName || '--' }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 采购开发 -->
            <el-form-item
              :label="$t('Documentary.PD') + '：'"
              :label-width="lang === 'en' ? '200px' : '160px'"
            >
              {{
                lang === 'en'
                  ? info.buyerNameEn || '--'
                  : info.buyerName || '--'
              }}
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 质检员(QC) -->
            <el-form-item
              :label="$t('Documentary.QI') + '：'"
              :label-width="lang === 'en' ? '200px' : '120px'"
            >
              {{
                lang === 'en'
                  ? info.merchandiserNameEn || '--'
                  : info.merchandiserName || '--'
              }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 订单支持 -->
            <el-form-item
              :label="$t('Documentary.OS') + '：'"
              :label-width="lang === 'en' ? '200px' : '130px'"
            >
              {{
                productList.length
                  ? lang === 'en'
                    ? productList[0].fromBusinessNameEn || '--'
                    : productList[0].fromBusinessName || '--'
                  : '--'
              }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 类型 -->
            <el-form-item
              :label="$t('Documentary.type') + '：'"
              :label-width="lang === 'en' ? '200px' : '140px'"
              class="label-style"
            >
              <em>
                <el-tooltip
                  placement="top"
                  effect="dark"
                  :content="$t('Documentary.SAPNN')"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </em>
              {{
                productList.length
                  ? productList[0].salesOrderId
                  : 0 | modeTypeName
              }}
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 采购物品 -->
        <h4 class="mb10">{{ $t('Documentary.PI') }}</h4>
        <el-table
          border
          ref="multipleTable"
          :data="productList"
          style="width: 100%"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <!-- 选择 -->
          <el-table-column :label="$t('Documentary.choose')" width="80">
            <template slot-scope="scope">
              <el-radio
                class="radio-style"
                v-model="currentRow"
                :label="scope.$index"
                @change.native="getCurrentRow(scope.$index)"
              ></el-radio>
            </template>
          </el-table-column>
          <!-- 产品编码 -->
          <el-table-column :label="$t('Documentary.PRC')">
            <template slot-scope="scope">{{ scope.row.sku }}</template>
          </el-table-column>
          <!-- 产品名称 -->
          <el-table-column prop="nameCn" :label="$t('Documentary.PN')">
            <template slot-scope="scope">
              {{ lang === 'en' ? scope.row.nameEn : scope.row.nameCn }}
            </template>
          </el-table-column>
          <!-- 采购数量 -->
          <el-table-column
            prop="numPurchase"
            :label="$t('Documentary.PQ')"
          ></el-table-column>
          <!-- 产品总金额(￥) -->
          <el-table-column
            prop="amountIncludingTax"
            :label="$t('Documentary.TPA')"
          >
            <template slot-scope="scope">
              {{ toFixed3(scope.row.amountIncludingTax) }}
            </template>
          </el-table-column>
        </el-table>
        <!-- 跟单节点 -->
        <h4 class="mt30 mb20">{{ $t('Documentary.DN') }}</h4>

        <track-node
          :nodeDTO="form.nodeDTO"
          :code="guanlianCode"
          :product="form.productDTO"
          :purchaseOrderNumber="form.purchaseOrderNumber"
          nodeStatus="add"
        ></track-node>
      </el-form>
    </div>

    <div class="oper-btn">
      <!-- 返回 -->
      <el-button @click="goBack">{{ $t('Documentary.return') }}</el-button>
      <!-- 保存 -->
      <el-button @click="save" type="primary" :loading="loading">
        {{ $t('Documentary.save') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { trackInterface } from '@/core'
import trackNode from './components/track-node.vue'
import { areaTransl } from '@/utils/translate.js'
import { mapGetters } from 'vuex'

let self = null
export default {
  components: { trackNode },
  data() {
    self = this
    return {
      guanlianCode: '', // 关联编号
      amountTotal: 0, // 采购单总金额
      createTime: '', // 采购订单创建时间
      info: {}, // 基本信息
      purchaseOrderList: [], // 关联编号下拉项
      purchaseMap: {}, // 采购物品信息集合
      productList: [], // 采购物品table
      form: {
        purchaseOrderNumber: '', // 采购订单编号
        purchaseOrderId: '', // 订单编号
        sample: '', // 是否有样品（0：无，1有）
        deliveryDays: '', // 工厂交期
        businessName: '', // 客户代表
        buyerArea: '', // 地区
        supplierName: '', // 供应商名称
        buyerName: '', // 采购开发
        merchandiserName: '', // 质检员
        fromBusinessName: '', // 订单支持
        modeType: '', // 类型
        productDTO: [], // 采购单产品信息
        nodeDTO: [],
      },
      rules: {},
      multipleSelection: [],
      loading: false,
      currentRow: 0,
      specNodes: ['QC', 'LOGISTICS_FREIGHT', 'THE_CUSTOMER_TO_SIGN_FOR'], // 特殊节点
    }
  },
  filters: {
    modeTypeName(n) {
      if (n === 0) {
        return '--'
      } else if (n) {
        self.form.modeType = 1
        return self.lang === 'en' ? 'Purchase on demand' : '按需采购'
      } else {
        self.form.modeType = 2
        return self.lang === 'en' ? 'Stock purchase' : '备货采购'
      }
    },
    buyerAreaTransl(n) {
      return self.lang === 'en' ? areaTransl(n) : n
    },
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  methods: {
    // 获取模板节点
    async getNode() {
      const res = await trackInterface.getUsingOrDefalutNode(
        this.info.buyerArea
      )

      if (res && res.code === '000000') {
        let _arr = []
        // 备货采购单过滤物流货运、客户签收特殊节点
        if (this.form.modeType === 2) {
          _arr = res.data.filter(
            (item) =>
              !['LOGISTICS_FREIGHT', 'THE_CUSTOMER_TO_SIGN_FOR'].includes(
                item.nodeType
              )
          )
        } else {
          _arr = res.data
        }
        this.form.nodeDTO = _arr.map((item) => {
          return {
            ...item,
            deferred: 0, // 是否延期（0：未延期，1：延期）
            deferredDays: 0, // 延期天数
            dueDate: '', // 预计日期
            realDate: '', // 实际日期
            inspectionResult: '', // 验货结果
            shipNo: '', // 物流单号
            enclosure: '', // 附件
          }
        })
      }
    },

    // 选择采购物品
    getCurrentRow(i) {
      this.currentRow = i
      this.form.productDTO = [...this.productList].splice(i, 1)
      // 计算工厂交期
      this.deliveryDaysCalc(
        this.form.productDTO[0].actualDeliveryDate,
        this.createTime
      )
    },

    // 获取生成跟踪单的数量并给出提示
    async queryRecord(id) {
      const res = await trackInterface.queryOrderTrackingRecord(id)

      if (res && res.code === '000000') {
        const num = res.data?.orderTrackingNumber
        if (num.length > 0) {
          this.$confirm(
            `
            <p>${
              this.lang === 'en'
                ? 'The current order number has been generated'
                : '当前单号已经生成了'
            }${num.length}${
              this.lang === 'en' ? 'tracking slip' : '笔跟踪单'
            }</p>
            <p style="word-break: break-all">【${
              this.lang === 'en' ? 'order tracking number' : '订单跟踪单号'
            }：${num}】</p>
            <p>${
              this.lang === 'en'
                ? 'Whether to continue to generate another tracking ticket'
                : '是否继续再生成一笔跟踪单'
            }}</p>
          `,
            this.$t('Documentary.hint'), // 提示
            {
              type: 'warning',
              dangerouslyUseHTMLString: true,
            }
          )
            .then(() => {})
            .catch(() => {
              this.guanlianCode = ''
              this.productList = []
              this.form.nodeDTO = []
              this.form.purchaseOrderNumber = ''
            })
        }
      }
    },

    // 采购订单编号失焦事件
    async purchaseOrderNumberBlur() {
      if (!this.form.purchaseOrderNumber) return false
      const res = await trackInterface.orderTrackingInformation(
        this.form.purchaseOrderNumber
      )

      if (res && res.code === '000000') {
        if (res?.data) {
          if (![5, 6, 7, 11].includes(res.data.state)) {
            this.$confirm(
              `${
                this.lang === 'en'
                  ? 'The purchase order must be an approved document, please go back to check the document!'
                  : '采购单必须为审核通过的单据，请返回检查单据！'
              }`,
              this.$t('Documentary.hint'), // 提示
              {
                type: 'warning',
              }
            ).then(() => {})
          } else {
            this.info = res.data
            this.purchaseMap = res.data.map
            this.createTime = res.data.createTime
            this.amountTotal = res.data.amountIncludingTax
            this.form.purchaseOrderId = res.data.purchaseOrderId
            this.purchaseOrderList = Object.keys(res.data.map).map((item) => {
              return {
                label: item,
                value: item,
              }
            })

            // 查询当前单号是否生成跟踪单
            this.queryRecord(res.data.purchaseOrderId)

            // 只有一条关联单号自动带出
            if (this.purchaseOrderList.length === 1) {
              this.guanlianCode = this.purchaseOrderList[0].value
              this.productList = this.purchaseMap[this.guanlianCode]
              // 采购物品默认选中第一条
              this.form.productDTO = [...this.productList].splice(0, 1)
              // 计算工厂交期
              this.deliveryDaysCalc(
                this.productList[0].actualDeliveryDate,
                this.createTime
              )
            } else {
              this.guanlianCode = ''
              this.productList = []
            }

            this.getNode()
          }
        } else {
          this.$confirm(
            `${
              this.lang === 'en'
                ? 'The current order number does not exist in the purchase order, please go to the purchase order to confirm whether the order number exists.'
                : '当前单号在采购单中不存在，请去采购单中确认单号是否存在。'
            }`,
            this.$t('Documentary.hint'), // 提示
            {
              type: 'warning',
            }
          ).then(() => {})
        }
      }
    },

    // 关联单号change
    purchaseOrderChange(val) {
      if (!val) {
        this.productList = []
      } else {
        this.productList = this.purchaseMap[val]
        this.form.productDTO = [...this.productList].splice(0, 1)
        // 计算工厂交期
        this.deliveryDaysCalc(
          this.form.productDTO[0].actualDeliveryDate,
          this.createTime
        )
      }
    },

    // 保存
    save() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          // 采购物品至少选择一条
          if (this.form.productDTO.length === 0) {
            this.$message.warning(this.$t('Documentary.PSO'))
            return false
          }

          // 实际日期校验-中间节点不能为空
          let index = 0,
            boo = false,
            nodeDTO = this.form.nodeDTO
          for (let i = nodeDTO.length - 1; i >= 0; i--) {
            if (
              nodeDTO[i].realDate &&
              !this.specNodes.includes(nodeDTO[i].nodeType)
            ) {
              index = i
              break
            }
          }
          for (let i = 0; i < index; i++) {
            if (
              !nodeDTO[i].realDate &&
              !this.specNodes.includes(nodeDTO[i].nodeType)
            ) {
              this.$message.warning(
                `${this.lang === 'en' ? 'The first' : '第'}${i + 1}${
                  this.lang === 'en'
                    ? 'actual date of the node is required'
                    : '节点实际日期必填'
                }`
              )
              boo = true
              break
            }
          }
          if (boo) return false

          this.loading = true
          // 采购物品数据处理
          this.form.productDTO = this.form.productDTO.map((item) => {
            return {
              ...item,
              productName: item.nameCn, // 产品名称
              productNameEn: item.nameEn, // 产品英文名称
              quantity: item.numPurchase, // 采购数量
              amount: item.amountIncludingTax, // 采购总金额
              amountTotal: this.amountTotal, // 采购单总金额
              supplierName: this.info.supplierName, // 供应商名称
              buyerid: this.info.buyerId, // 采购开发id
              buyerName: this.info.buyerName, // 采购开发名称
              buyerNameEn: this.info.buyerNameEn, // 采购开发英文名称
              merchandiserId: this.info.merchandiserId, // 跟单员id
              merchandiserName: this.info.merchandiserName, // 跟单员名称
              merchandiserNameEn: this.info.merchandiserNameEn, // 跟单员英文名称
              currency: this.info.currency,
              exchangeRate: this.info.exchangeRate, // 汇率
            }
          })
          // 跟单节点数据处理
          this.form.nodeDTO = nodeDTO.map((item, i) => {
            return {
              ...item,
              nodeSerialNumber: i + 1, // 跟单节点序号赋值
              deferred: item.deferred ? 1 : 0, // 是否延期格式处理
              deliveryDays: Number(item.deliveryDays), // 工厂交期
            }
          })

          let params = {
            ...this.form,
            area: this.info.buyerArea,
          }

          const res = await trackInterface.trackInsertPO(params)
          if (res && res.code === '000000') {
            this.$router.push('/track/orderTracking/trackList')
            this.$message.success(this.$t('Documentary.SS'))
            this.loading = false
          } else {
            this.loading = false
          }
        }
      })
    },

    // 计算工厂交期(交货日期 - 订单创建日期)
    deliveryDaysCalc(date1, date2) {
      let a1 = Date.parse(new Date(date1))
      let a2 = Date.parse(new Date(date2.substring(0, 10)))
      let day = parseInt((a1 - a2) / (1000 * 60 * 60 * 24))

      this.form.deliveryDays = day
    },

    // 返回
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.track-content {
  min-height: calc(100% - 120px);
  h4 {
    font-weight: 600;
    font-size: 14px;
    margin: 10px 0 0 0;
  }
  .el-input,
  .el-select {
    width: 100%;
  }
  .label-style {
    position: relative;
    em {
      position: absolute;
      top: -8px;
      left: -18px;
      z-index: 2;
      i {
        font-size: 16px;
      }
    }
  }
}
.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
}
::v-deep {
  .el-input-group__append {
    background: none;
    padding: 0 10px;
  }
  .radio-style {
    color: #fff;
    margin-left: 25px;
    .el-radio__label {
      margin-left: 30px;
    }
  }
}
</style>
