<!--
  功能：上传附件弹窗
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年05月04日 15:53:05
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-dialog
    title="上传附件"
    :visible.sync="dialogFormVisible"
    width="980px"
    @close="close"
    :close-on-click-modal="false"
  >
    <ErpUpload
      ref="ErpUpload"
      :uploadParams="uploadParams"
      @uploadList="uploadList"
    />
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'AttachFileDialog',
  data() {
    return {
      uploadParams: {
        size: 1024 * 1024 * 20, //单个文件上传大小
        amount: 9,
      },
      dialogFormVisible: false,
      financeUrl: '',
    }
  },
  watch: {},
  methods: {
    uploadList(fileList) {
      this.financeUrl = JSON.stringify(fileList)
    },
    show() {
      this.dialogFormVisible = true
    },
    save() {
      this.$emit('add-file', this.financeUrl)
    },
    close() {
      this.dialogFormVisible = false
    },

    initUpload(data) {
      this.$refs.ErpUpload.initUpload(data)
    },
  },
}
</script> 

<style scoped lang="scss">
::v-deep .el-dialog .el-dialog__body {
  padding-top: 0;
}
</style>
