<template>
  <!-- 绑定邮箱 -->
  <el-dialog
    :title="$t('mailConfig.addSubAccount')"
    :visible.sync="dialogFormVisible"
    width="450px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item
          :label="$t('mailConfig.grantauthorizationto')"
          :rules="[
            {
              required: true,
              trigger: 'change',
              message: $t('placeholder.plsSel'),
            },
          ]"
          prop="authUserId"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <UserSelect
                @user-choose="chooseUser"
                style="width: 100%"
                ref="userSelect"
              ></UserSelect>
            </el-col>
          </el-row>
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="24">
            <!-- 我的邮箱 -->
            <el-form-item
              :label="$t('mailConfig.myemail')"
              prop="emailAccount"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select v-model="form.emailAccount">
                <el-option
                  v-for="(item, index) in mailServerList"
                  :key="item.mailAccountId"
                  :label="item.emailAccount"
                  :value="item.emailAccount"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template v-slot:footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          <!-- 确 认 -->
          {{ $t('mailConfig.Confirm') }}
        </el-button>
        <el-button @click="close">
          <!-- 取 消 -->
          {{ $t('mailConfig.Cancel') }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UserSelect from '@/components/user-select'
  import { MailSettingInteractor } from '@/core'
  export default {
    name: 'AccountAuthorizationForm',

    data() {
      return {
        loading: false,
        mailServerList: [],
        form: {
          emailAccount: '',
          authUserId: '', //授权用户ID
          authUserNameCn: '', //授权用户中文名称
          authUserNameEn: '', //授权用户英文名称
        },
        dialogFormVisible: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        settings: 'settings/language',
      }),
      rules() {},
    },
    components: {
      UserSelect,
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit() {
        this.getMailServerList()
      },

      // 获取邮箱账号下拉列表
      getMailServerList() {
        MailSettingInteractor.getMailAccountListApi().then((res) => {
          if (res?.code === '000000') {
            this.mailServerList = (res.data || []).filter(
              (item) => item.mailServer === 'Gmail'
            )
            if (this.mailServerList.length === 1) {
              this.form.emailAccount = this.mailServerList[0].emailAccount
            }
            this.dialogFormVisible = true
          }
        })
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$refs.userSelect?.clearUser()
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            MailSettingInteractor.authEmailApi({
              ...this.form,
            }).then((res) => {
              if (res?.code === '000000') {
                // 设置成功
                this.$message.success(this.$t('reqmsg.$9'))
                this.$emit('fetch-data')
                this.close()
              }
            })
          }
        })
      },
      chooseUser(user) {
        this.form.authUserId = user ? user.userId : ''
        this.form.authUserNameCn = user ? user.userName : ''
        this.form.authUserNameEn = user ? user.englishName : ''
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    overflow: hidden;
    overflow-y: auto;
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
