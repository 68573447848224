<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="180px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="List Price = Net Price /" prop="value">
            <el-input
              class="no-number"
              type="number"
              v-model="form.value"
              placeholder="请输入"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/product/productSetting'
  export default {
    name: 'PriceRuleModel',
    data() {
      return {
        loading: false,
        title: '价格规则设置',
        form: {
          type: 8,
          productSettingId: '',
          value: null,
        },
        rules: {
          value: [
            { required: true, trigger: 'blur', message: '请输入' },
            {
              validator: this.$formValidation.listPriceCheck,
              trigger: 'blur',
            },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //展示弹框
      showAddEdit(data) {
        if (data) {
          this.form.productSettingId = data.productSettingId
          this.form.value = data.value
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.$refs['form'].resetFields()
        this.form = {
          type: 8,
          productSettingId: '',
          value: null,
        }
        this.dialogFormVisible = false
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            if (!this.form.productSettingId) {
              let response = await insertPO(this.form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '新增成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              let response = await updatePO(this.form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '编辑成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.close()
            this.$emit('price-rule', this.form.type)
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
