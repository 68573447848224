<template>
  <el-row class="delivery-period">
    <el-col>
      <p class="f_s_16">运输周期 & 运费计算规则</p>
    </el-col>
    <el-col>
      <el-form :model="deliveryPeriodForm" :rules="rules" ref="deliveryPeriodForm" label-width="0" :key="formKey">
        <el-radio-group v-model="radioVal" @change="radioChangeFn">
          <el-radio :label="0">
            <div class="delivery-period-item">
              <p>最近</p>
              <el-form-item :prop="radioVal === 0 ? 'day' : ''">
                <el-input v-model="deliveryPeriodForm.day" type="number"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+0)}"></el-input>
              </el-form-item>
              <p>天内完成的出运委托</p>
            </div>
          </el-radio>
          <el-radio :label="1">
            <div class="delivery-period-item">
              <p>最近</p>
              <el-form-item :prop="radioVal === 1 ? 'orderNum' : ''">
                <el-input v-model="deliveryPeriodForm.orderNum" type="number"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+0)}"></el-input>
              </el-form-item>
              <p>单完成的出运委托</p>
            </div>
          </el-radio>
        </el-radio-group>
        <div class="delivery-period-item ratio">
          <p>去掉</p>
          <el-form-item prop="ratio">
            <el-input v-model="deliveryPeriodForm.ratio" type="number"
              onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
              oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"></el-input>
          </el-form-item>
          <span class="tip">%</span>
          <p>的最大值和最小值计算平均数</p>
        </div>
        <div class="delivery-period-btn">
          <el-form-item>
            <el-button type="primary" @click="onSubmit" :loading="loading">
              保存
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
  import {
    productTransportRuleInfo,
    addOrUpdate,
  } from '@/api/product/productSetting'

  export default {
    data() {
      return {
        loading: false,
        formKey: 1,
        radioVal: 0,
        deliveryPeriodForm: {
          productTransportRuleId: '',
          day: 0,
          orderNum: 0,
          ratio: 0,
        },
        rules: {
          day: [
            { required: true, message: '请输入', trigger: 'blur' },
            {
              validator: this.$formValidation.isNum,
              trigger: 'blur',
            },
          ],
          orderNum: [
            { required: true, message: '请输入', trigger: 'blur' },
            {
              validator: this.$formValidation.isNum,
              trigger: 'blur',
            },
          ],
          ratio: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
      }
    },
    created() {
      this.getInfo()
    },
    methods: {
      // 获取运输周期详情
      async getInfo() {
        let response = await productTransportRuleInfo()
        if (response?.code === '000000') {
          this.deliveryPeriodForm = Object.assign({}, response.data)
          if (
            this.deliveryPeriodForm.day === 0 &&
            this.deliveryPeriodForm.orderNum !== 0
          ) {
            this.radioVal = 1
          } else {
            this.radioVal = 0
          }
        }
      },
      radioChangeFn() {
        this.formKey++
        this.$refs.deliveryPeriodForm.clearValidate()
      },
      onSubmit() {
        this.$refs.deliveryPeriodForm.validate(async (valid) => {
          if (valid) {
            this.loading = true
            let params = this.utils.deepCopy(this.deliveryPeriodForm)
            params.day = this.radioVal === 0 ? params.day : 0
            params.orderNum = this.radioVal === 1 ? params.orderNum : 0
            let response = await addOrUpdate(params)
            this.loading = false
            if ((response.code = '000000')) {
              this.getInfo()
              this.$baseMessage(
                '保存成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .delivery-period {
    border: 1px solid #ededed;
    padding: 10px;

    ::v-deep .el-radio {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    .delivery-period-item {
      display: flex;
      align-items: center;

      &.ratio {
        margin: 20px 0;

        .tip {
          margin: 0 5px 0 -5px;
        }
      }

      ::v-deep .el-form-item {
        width: 60px;
        margin: 0 10px !important;

        .el-input__inner {
          padding: 0 4px;
          text-align: center;
        }
      }
    }

    .delivery-period-btn {
      ::v-deep .el-form-item {
        .el-form-item__content {
          text-align: center;
        }
      }
    }
  }
</style>