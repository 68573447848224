<template>
  <el-dialog
    title="添加规则"
    :visible.sync="modelVisible"
    width="800px"
    :before-close="cancel"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="14">
          <el-form-item label="选择国家" prop="country" label-width="105px">
            <el-select
              v-model="ruleForm.country"
              placeholder="请选择"
              multiple
              @change="selectCountry"
            >
              <el-option
                v-for="item in countryOptions"
                :key="item.areaId"
                :label="item.areaName"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="计价类型" prop="pricingType" label-width="105px">
        <el-radio-group v-model="ruleForm.pricingType">
          <el-radio :label="1">单价</el-radio>
          <el-radio :label="0">总价</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-row>
        <el-col :span="2" :offset="1">
          <span style="color: red">*</span>
          <span>运费规则</span>
        </el-col>
        <el-col :span="20">
          <el-table
            :data="ruleForm.ladderAndPriceDTOS"
            border
            style="width: 100%"
            class="ml10 no-space form-rule-table"
          >
            <el-table-column
              label="> 开始值"
              align="center"
              label-class-name="required-th"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'ladderAndPriceDTOS.' + scope.$index + '.startBillingLadder'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.startBillingLadder"
                    clearable
                    placeholder="请输入"
                    :disabled="scope.$index !== 0"
                    type="number"
                    oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                  >
                    <template slot="suffix">
                      {{ addRuleType === 'weight' ? 'kg ' : 'm³' }}
                    </template>
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="≤ 结束值"
              align="center"
              label-class-name="required-th"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'ladderAndPriceDTOS.' + scope.$index + '.billingLadder'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.billingLadder"
                    clearable
                    placeholder="请输入"
                    @input="changePrice(scope)"
                    @change="checkSize(scope)"
                    type="number"
                    oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                  >
                    <template slot="suffix">
                      {{ addRuleType === 'weight' ? 'kg' : 'm³' }}
                    </template>
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="价格"
              align="center"
              label-class-name="required-th"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'ladderAndPriceDTOS.' + scope.$index + '.price'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.price"
                    clearable
                    placeholder="请输入"
                    type="number"
                    oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                  >
                    <template slot="suffix">
                      <span>
                        {{ currency === '美元' ? '美元' : '元' }}
                      </span>
                      <span v-if="ruleForm.pricingType == 1">
                        /{{ addRuleType === 'weight' ? 'kg' : 'm³' }}
                      </span>
                    </template>
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  plain
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  v-show="
                    scope.$index == ruleForm.ladderAndPriceDTOS.length - 1 &&
                    scope.$index !== 0
                  "
                  @click="del(scope)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row class="text-center mt10">
        <el-button type="text" @click="addRule">添加范围</el-button>
      </el-row>
      <el-row class="text-center mt20">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="cancel">取 消</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
  import _ from 'lodash'
  export default {
    props: {
      modelVisible: {
        type: Boolean,
        default: false,
      },
      addRuleType: {
        type: String,
        default: '',
      },
      currency: {
        type: String,
        default: '',
      },
      countryOptions: {
        type: Array,
        default: () => [],
      },
      editMsg: {
        type: Object,
        default: () => {},
      },
      // 已经选择的国家，新增或者编辑  禁止选择
      selectdCountry: {
        type: String,
        default: '',
      },
      modelType: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        ruleForm: {
          country: [],
          pricingType: '',
          ladderAndPriceDTOS: [
            { startBillingLadder: '0', billingLadder: '', price: '' },
          ],
        },
        countryAllName: [],
        rules: {
          country: [{ required: true, message: '请选择', trigger: 'change' }],
          pricingType: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
        },
        notChooseCountry: [],
      }
    },
    watch: {
      modelVisible() {
        if (this.modelVisible) {
          if (this.editMsg?.ladderAndPriceDTOS?.length > 0) {
            this.ruleForm = this.editMsg
          }
          this.notChooseCountry = Array.from(
            new Set(this.selectdCountry.split(','))
          )
          if (this.notChooseCountry.indexOf(this.ruleForm?.country[0]) > -1) {
            this.notChooseCountry.splice(
              this.notChooseCountry.indexOf(this.ruleForm?.country[0]),
              1
            )
          }

          this.countryAllName = this.countryOptions.filter((item) => {
            return item.code === this.ruleForm?.country[0]
          })
        }
      },
    },
    methods: {
      cancel() {
        this.$refs['ruleForm'].resetFields()
        this.$emit('update:modelVisible', false)
        this.ruleForm.pricingType = ''
        this.ruleForm.ladderAndPriceDTOS = [
          { startBillingLadder: '0', billingLadder: '', price: '' },
        ]
        this.ruleForm.country = []
        this.countryAllName = []
        this.notChooseCountry = []
      },
      save() {
        let arr = []
        let list = []
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            this.countryAllName.forEach((item) => {
              list = this.ruleForm.ladderAndPriceDTOS.map((ele) => {
                return {
                  ...ele,
                  ...item,
                  pricingType: this.ruleForm.pricingType,
                }
              })
              arr = arr.concat(list)
            })
            this.$emit('saveRule', arr)
            this.cancel()
          } else {
            return false
          }
        })
      },
      addRule() {
        let obj = { startBillingLadder: '0', billingLadder: null, price: null }
        if (this.ruleForm.ladderAndPriceDTOS.length > 0) {
          obj.startBillingLadder =
            this.ruleForm.ladderAndPriceDTOS[
              this.ruleForm.ladderAndPriceDTOS?.length - 1
            ].billingLadder
        }
        this.ruleForm.ladderAndPriceDTOS.push(obj)
      },
      del(scope) {
        this.ruleForm.ladderAndPriceDTOS.splice(scope.$index, 1)
      },
      // 输入结束值
      changePrice(scope) {
        if (this.ruleForm.ladderAndPriceDTOS.length - 1 <= scope.$index) {
          return
        }

        this.$set(
          this.ruleForm.ladderAndPriceDTOS[scope.$index + 1],
          'startBillingLadder',
          scope.row.billingLadder
        )
      },
      // 判断结束值不能小于开始值
      checkSize(scope) {
        if (
          Number(scope.row.startBillingLadder) > Number(scope.row.billingLadder)
        ) {
          this.$message.warning('开始值不能大于结束值！')
          this.$set(scope.row, 'billingLadder', '')
          return
        }
      },
      selectCountry(val) {
        this.countryAllName = []
        this.countryOptions.forEach((item) => {
          if (val.indexOf(item.code) > -1) {
            let obj = {
              country: item.code,
              countryCn: item.areaName,
              countryEn: item.nameEn,
            }
            this.countryAllName.push(obj)
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-table--border {
      border-right: 1px solid #ebeef5 !important;
    }
    .el-table__body-wrapper {
      max-height: 400px;
      overflow-y: scroll;
    }
  }
</style>
