// 验货结果翻译
export function inspectionResultTransl(res) {
  const obj = {
    合格: 'pass',
    不合格: 'fail',
    无需验货: 'Without the inspection',
  }
  return obj?.[res] || res
}

// 地区翻译
export function areaTransl(res) {
  const obj = {
    合肥: 'HeFei',
    苏州: 'Suzhou',
    义乌: 'YiWu',
  }
  return obj?.[res] || res
}

// 标准品订制品翻译
export function productType(str) {
  if (str === '标准品') {
    return 'Standard Product'
  } else if (str === '订制品') {
    return 'Customized Product'
  } else {
    return ''
  }
}
