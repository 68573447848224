<template>
  <!-- 运输周期 -->
  <el-row :gutter="20" class="delivery-period">
    <el-col :span="2">
      <h6 class="left-h6">{{ $t('productDetail.TransportationSettings') }}</h6>
    </el-col>
    <el-col :span="22" class="right-content" style="padding: 20px 20px">
      <el-form ref="transportationCycle" inline :rules="rules" :model="transportationCycle" label-width="88px"
        :label-position="lang === 'zh' ? 'left' : 'top'" class="el_form">
        <el-row>
          <el-col v-if="isSkuSetProduct" :span="24" class="specifications-box">
            <!-- 装箱数量 -->
            <el-form-item :label="$t('productDetail.PackingQuantity')" prop="msgForm.numberPerBox" class="form-cont"
              :rules="[
                {
                  required: true,
                  message: this.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="transportationCycle.msgForm.numberPerBox" type="number"
                :placeholder="$t('placeholder.plsInput')" @change="transportationChangeFn($event, 3)"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
            </el-form-item>
            <el-form-item :label="$t('productDetail.GrossWeight')" prop="msgForm.boxWeightKgs" class="form-cont" :rules="[
                {
                  required: true,
                  message: this.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
                {
                  pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                  message: this.$t('rules.M2014'),
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="transportationCycle.msgForm.boxWeightKgs" type="number"
                :placeholder="$t('placeholder.plsInput')" @change="transportationChangeFn($event, 3)"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
              <p class="t_a_c calcu-p">
                LBS:
                <span>
                  {{
                  (
                  (transportationCycle.msgForm.boxWeightKgs || 0) * 2.2046
                  ).toFixed(1)
                  }}
                </span>
              </p>
            </el-form-item>
            <el-form-item v-if="transportationCycle.transportType != 2" prop="msgForm.boxLengthCm"
              :label="$t('productDetail.PackingSize')" :rules="[
                {
                  required: true,
                  message: this.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
              ]">
              <!-- 长度 -->
              <el-input v-model="transportationCycle.msgForm.boxLengthCm" type="number" @input="boxSize('Length')"
                :placeholder="$t('placeholder.M2008')" @change="transportationChangeFn($event, 3)"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                <template slot="append">CM</template>
              </el-input>
              <p class="t_a_r calcu-p">
                <span>{{ transportationCycle.msgForm.boxLengthIn }}</span>
                IN
              </p>
            </el-form-item>
            <span class="symbol" :class="{ mat32: lang !== 'zh' }" v-if="transportationCycle.transportType != 2">
              x
            </span>
            <el-form-item :class="{ mat32: lang !== 'zh' }" v-if="transportationCycle.transportType != 2"
              prop="msgForm.boxWidthCm" :rules="[
                {
                  required: true,
                  message: this.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
              ]">
              <!-- 宽度 -->
              <el-input v-model="transportationCycle.msgForm.boxWidthCm" type="number" @input="boxSize('Width')"
                @change="transportationChangeFn($event, 3)" :placeholder="$t('placeholder.M2009')"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                <template slot="append">CM</template>
              </el-input>
              <p class="t_a_r calcu-p">
                <span>{{ transportationCycle.msgForm.boxWidthIn }}</span>
                IN
              </p>
            </el-form-item>
            <span :class="['symbol', { mat32: lang !== 'zh' }]" v-if="transportationCycle.transportType != 2">
              x
            </span>
            <el-form-item :class="{ mat32: lang !== 'zh' }" v-if="transportationCycle.transportType != 2"
              prop="msgForm.boxHeightCm" :rules="[
                {
                  required: true,
                  message: this.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="transportationCycle.msgForm.boxHeightCm" type="number" @input="boxSize('Height')"
                @change="transportationChangeFn($event, 3)" :placeholder="$t('placeholder.M2010')"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                <template slot="append">CM</template>
              </el-input>
              <p class="t_a_r calcu-p">
                <span>{{ transportationCycle.msgForm.boxHeightIn }}</span>
                IN
              </p>
            </el-form-item>
          </el-col>
          <el-col class="select-col">
            <el-form-item :label="$t('productDetail.TransportationType')" prop="transportType" class="form-cont">
              <el-select v-model="transportationCycle.transportType" :placeholder="$t('placeholder.plsSel')"
                @change="typeChangeFn" clearable>
                <el-option v-for="(item, index) in transportationTypeOptions" :key="item.transportType"
                  :label="lang === 'zh' ? item.nameCn : item.nameEn" :value="item.transportType"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="transportationCycle.transportType">
              <el-button size="medium" plain @click="initialValueFn">
                {{ $t('productDetail.RestoreInitialValue') }}
              </el-button>
            </el-form-item>
          </el-col>
          <el-col v-if="transportationCycle.transportType">
            <el-table :data="transportationCycle.tableList" style="width: 898px" :key="tableKey"
              :span-method="objectSpanMethod" border>
              <el-table-column align="center" width="296">
                <template slot-scope="scope">
                  <span>
                    {{ lang === 'zh' ? scope.row.nameCn1 : scope.row.nameEn1 }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="220">
                <template slot="header">
                  <div>
                    <p>{{ $t('productDetail.AverageShippingCost') }}</p>
                    <p>（¥/kg）</p>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-form-item class="price" label-width="0" :prop="'tableList.' + scope.$index + '.oldPrice'" :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: 'blur',
                      },
                    ]">
                    <el-input type="number" @change="transportationChangeFn($event, 1)"
                      @focus="handleTableFocus(scope.row, 'oldPrice')" v-model="scope.row.oldPrice"
                      :placeholder="$t('placeholder.plsInput')"
                      oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="200">
                <template slot="header">
                  <div>
                    <p>{{ $t('productDetail.FreightUnitPrice') }}</p>
                    <p>{{ lang === 'zh' ? '(¥/个)' : '(¥/piece)' }}</p>
                  </div>
                </template>
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.price }}
                  </span>
                  <el-tooltip effect="light" placement="right" v-if="scope.$index === 0">
                    <div slot="content">
                      <p>= {{ FirstLegTipShoe }}</p>
                      <p v-if="
                          transportationCycle.transportType == 2 ||
                          chooseSize === 1
                        ">
                        = ¥ {{ scope.row.oldPrice || 0 }} ×
                        {{ transportationCycle.msgForm.boxWeightKgs || 0 }} ÷
                        {{ transportationCycle.msgForm.numberPerBox || 0 }}
                      </p>
                      <p v-else>
                        = ¥ {{ scope.row.oldPrice || 0 }} × (
                        {{ transportationCycle.msgForm.boxLengthCm || 0 }} ×
                        {{ transportationCycle.msgForm.boxWidthCm || 0 }} ×
                        {{ transportationCycle.msgForm.boxHeightCm || 0 }} ÷
                        {{
                        transportationCycle.transportType == 1 ? 6000 : 5000
                        }}) ÷
                        {{ transportationCycle.msgForm.numberPerBox || 0 }}
                      </p>
                    </div>
                    <i class="el-icon-warning-outline" style="color: #909399"></i>
                  </el-tooltip>
                  <el-tooltip effect="light" placement="right" v-if="scope.$index === 1">
                    <div slot="content">
                      <p>= {{ LastLegTipShoe }}</p>
                      <p v-if="
                          transportationCycle.transportType == 2 ||
                          chooseSize === 1
                        ">
                        = ¥ {{ scope.row.oldPrice || 0 }} ×
                        {{ transportationCycle.msgForm.boxWeightKgs || 0 }} ÷
                        {{ transportationCycle.msgForm.numberPerBox || 0 }}
                      </p>
                      <p v-else>
                        = ¥ {{ scope.row.oldPrice || 0 }} × (
                        {{ transportationCycle.msgForm.boxLengthCm || 0 }} ×
                        {{ transportationCycle.msgForm.boxWidthCm || 0 }} ×
                        {{ transportationCycle.msgForm.boxHeightCm || 0 }} ÷
                        {{
                        transportationCycle.transportType == 1 ? 6000 : 5000
                        }}) ÷
                        {{ transportationCycle.msgForm.numberPerBox || 0 }}
                      </p>
                    </div>
                    <i class="el-icon-warning-outline" style="color: #909399"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column align="center" width="180">
                <template slot="header">
                  <p>{{ $t('productDetail.DeliveryPeriod') }}</p>
                  <p>（{{ $t('productDetail.WorkingDay') }}）</p>
                </template>
                <template slot-scope="scope">
                  <el-form-item class="price day" label-width="0" :prop="'tableList.' + scope.$index + '.transportDay'"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: 'blur',
                      },
                    ]">
                    <p>
                      {{ $t('productDetail.FirstLeg') }} +
                      {{ $t('productDetail.LastLeg') }}
                    </p>
                    <!-- 整数数值 -->
                    <el-input type="number" v-model="scope.row.transportDay" @change="transportationChangeFn($event, 2)"
                      :placeholder="$t('placeholder.plsInput')" @focus="handleTableFocus(scope.row, 'transportDay')"
                      onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}"
                      oninput="if(isNaN(value)) { value  = null }if(value<1) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+1)}" />
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col class="tip-el-col" v-if="transportationCycle.transportType">
            <span class="required">*</span>
            <span v-if="lang === 'zh'">
              以上数据根据最近{{
              deliveryPeriodForm.day
              ? deliveryPeriodForm.day + '天内'
              : deliveryPeriodForm.orderNum + '个订单'
              }}， 测算的平均运输周期和平均运输单价（去除{{
              deliveryPeriodForm.ratio
              }}%最大值和{{ deliveryPeriodForm.ratio }}%的最小值）
            </span>
            <span v-else>
              The above data is based on the average shipping lead times and
              average shipping costs calculated within the most recent
              {{
              deliveryPeriodForm.day
              ? deliveryPeriodForm.day + 'days'
              : deliveryPeriodForm.orderNum + 'orders'
              }}
              (Excluding the top {{ deliveryPeriodForm.ratio }}% and the bottom
              {{ deliveryPeriodForm.ratio }}% of the values).
            </span>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
  import _ from 'lodash'
  import { mapGetters } from 'vuex'
  import { elFormErrorScrollIntoView } from 'kits'
  import { getTransportDayAndPriceListByTenantId } from '@/api/product/productList'
  import { productTransportRuleInfo } from '@/api/product/productSetting'
  export default {
    data() {
      return {
        tableKey: 1,
        transportationCycle: {
          transportType: null,
          msgForm: {
            boxWeightKgs: '',
            numberPerBox: '',
            boxLengthCm: '',
            boxWidthCm: '',
            boxHeightCm: '',
            boxLengthIn: '',
            boxWidthIn: '',
            boxHeightIn: '',
          }, //产品信息
          tableList: [
            {
              nameCn1: '头程',
              nameEn1: 'FirstLeg',
              price: '',
              transportDay: '',
              oldPrice: 0,
            },
            {
              nameCn1: '尾程',
              nameEn1: 'LastLeg',
              price: '',
              transportDay: '',
              oldPrice: 0,
            },
          ],
        },
        isSkuSetProduct: false, //是否开启多规格
        isChangeByProMsg: true, // 装箱信息 是否根据 产品信息联动
        transportationTypeOptions: [], //运输类型
        rules: {
          transportType: [
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
        },
        selectItem: {},
        pageInfoData: {
          productTransportId: '', //业务id
          transportType: '', //运输类型
          transportDay: '', //运输周期
          transportHeadPrice: '', //运输头程单价
          avgHeadPrice: '',
          transportTailPrice: '', //运输尾程单价
          avgTailPrice: '',
        }, //运输周期信息
        requestNum: 1,
        deliveryPeriodForm: {},
        chooseSize: 1,
      }
    },
    props: {
      editData: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      FirstLegTipShoe() {
        if (this.transportationCycle.transportType === 2) {
          return this.$t('productDetail.FirstLegTip')
        } else if (this.transportationCycle.transportType === 1) {
          return this.$t('productDetail.FirstLegTip1')
        } else {
          return this.$t('productDetail.FirstLegTip2')
        }
      },
      LastLegTipShoe() {
        if (this.transportationCycle.transportType === 2) {
          return this.$t('productDetail.LastLegTip')
        } else if (this.transportationCycle.transportType === 1) {
          return this.$t('productDetail.LastLegTip1')
        } else {
          return this.$t('productDetail.LastLegTip2')
        }
      },
    },
    watch: {
      lang() {
        this.tableKey++
      },
      'pageInfoData.transportHeadPrice'() {
        this.$Bus.$emit('freightPriceChange', this.pageInfoData)
      },
      'pageInfoData.transportDay'() {
        this.$Bus.$emit('freightPriceChange', this.pageInfoData)
      },
    },
    created() {
      this.getInfo()
      this.getTransportDayAndPriceListByTenantId()
      this.$Bus.$on('undateFreightPrice', (val) => {
        this.tableKey++
        this.transportationCycle.msgForm.numberPerBox = val?.numberPerBox || ''
        this.transportationCycle.msgForm.boxWeightKgs = val?.boxWeightKgs || ''
        this.transportationCycle.msgForm.boxLengthCm = val?.boxLengthCm || ''
        this.transportationCycle.msgForm.boxWidthCm = val?.boxWidthCm || ''
        this.transportationCycle.msgForm.boxHeightCm = val?.boxHeightCm || ''
        this.transportationCycle.msgForm.boxLengthIn = val?.boxLengthIn || ''
        this.transportationCycle.msgForm.boxWidthIn = val?.boxWidthIn || ''
        this.transportationCycle.msgForm.boxHeightIn = val?.boxHeightIn || ''
        this.updateTableDataFn()
      })
      this.$Bus.$on('sktSetProduct', (val, form, table) => {
        this.isSkuSetProduct = val
        if (val && table && table.length > 0) {
          this.transportationCycle.msgForm.numberPerBox =
            table[0].numberPerBox || ''
          this.transportationCycle.msgForm.boxWeightKgs =
            table[0].boxWeightKgs || ''
          this.transportationCycle.msgForm.boxLengthCm =
            table[0].boxLengthCm || ''
          this.transportationCycle.msgForm.boxWidthCm =
            table[0].boxWidthCm || ''
          this.transportationCycle.msgForm.boxHeightCm =
            table[0].boxHeightCm || ''
          this.transportationCycle.msgForm.boxLengthIn =
            table[0].boxLengthCm || ''
          this.transportationCycle.msgForm.boxWidthIn =
            table[0].boxWidthCm || ''
          this.transportationCycle.msgForm.boxHeightIn =
            table[0].boxHeightCm || ''
        } else {
          this.transportationCycle.msgForm.numberPerBox = ''
          this.transportationCycle.msgForm.boxWeightKgs = ''
          this.transportationCycle.msgForm.boxLengthCm = ''
          this.transportationCycle.msgForm.boxWidthCm = ''
          this.transportationCycle.msgForm.boxHeightCm = ''
          this.transportationCycle.msgForm.boxLengthIn = ''
          this.transportationCycle.msgForm.boxWidthIn = ''
          this.transportationCycle.msgForm.boxHeightIn = ''
        }
        // this.initialValueFn()
        this.selectItem = this.transportationTypeOptions.filter((value) => {
          return value.transportType == 1
        })[0]
        this.transportationCycle.transportType = this.selectItem?.transportType
        // this.transportationCycle.tableList.map((item, index) => {
        //   item.transportDay = this.selectItem?.transportDay
        //   return item
        // })
        this.updateTableDataFn()
      })
      this.$Bus.$on('specProMsg', (val) => {
        if (this.isChangeByProMsg && val?.length > 0 && this.isSkuSetProduct) {
          this.transportationCycle.msgForm.numberPerBox =
            val[0].numberPerBox || ''
          this.transportationCycle.msgForm.boxWeightKgs =
            val[0].boxWeightKgs || ''
          this.transportationCycle.msgForm.boxLengthCm =
            val[0].boxLengthCm || ''
          this.transportationCycle.msgForm.boxWidthCm = val[0].boxWidthCm || ''
          this.transportationCycle.msgForm.boxHeightCm =
            val[0].boxHeightCm || ''
          this.transportationCycle.msgForm.boxLengthIn =
            val[0].boxLengthIn || ''
          this.transportationCycle.msgForm.boxWidthIn = val[0].boxWidthIn || ''
          this.transportationCycle.msgForm.boxHeightIn =
            val[0].boxHeightIn || ''
          this.updateTableDataFn()
        }
      })
      // 切换产品类别
      this.$Bus.$on('show-clear', () => {
        this.transportationCycle.transportType = ''
      })
    },
    beforeDestroy() {
      // 关闭$bus
      this.$Bus.$off('undateFreightPrice')
      this.$Bus.$off('sktSetProduct')
      this.$Bus.$off('specProMsg')
    },
    methods: {
      // 获取运输周期详情
      async getInfo() {
        let response = await productTransportRuleInfo()
        if (response?.code === '000000') {
          this.deliveryPeriodForm = response.data
        }
      },
      // 获取当前租户运输周期、运费
      async getTransportDayAndPriceListByTenantId() {
        if (this.requestNum > 3) return
        try {
          let response = await getTransportDayAndPriceListByTenantId()
          if (response.code === '000000') {
            this.setTransportationTypeOptions(response.data)
          } else {
            this.transportationTypeOptions = []
          }
        } catch (err) {
          this.requestNum++
          this.getTransportDayAndPriceListByTenantId()
        }
      },
      // 运输类型添加中英名称
      setTransportationTypeOptions(arr) {
        let newArr = arr.filter((item) => !!item.transportType)
        const obj = {
          1: (item) => {
            item.nameCn = '空运'
            item.nameEn = 'Air Transport'
          },
          2: (item) => {
            item.nameCn = '海运'
            item.nameEn = 'Ocean Shipping'
          },
          3: (item) => {
            item.nameCn = '快递'
            item.nameEn = 'Express'
          },
        }
        newArr.map((item) => {
          obj[item['transportType']](item)
          return item
        })
        for (let k in newArr) {
          this.$set(this.transportationTypeOptions, k, newArr[k])
        }
        if (this.$route.query.str === 'add') {
          this.transportationCycle.transportType = 1
          this.typeChangeFn(1)
        }
      },
      transportationChangeFn(val, num) {
        const obj = {
          1: () => {
            this.updateTableDataFn()
          },
          2: () => {
            this.pageInfoData.transportDay = val
          },
          3: () => {
            this.isChangeByProMsg = false
            this.updateTableDataFn()
          },
        }
        obj[num]()
      },
      // 运输类型切换
      typeChangeFn(val) {
        this.selectItem = this.transportationTypeOptions.filter((value) => {
          return value.transportType === val * 1
        })[0]
        this.pageInfoData.transportDay = this.selectItem?.transportDay
        this.pageInfoData.transportType = this.selectItem?.transportType
        this.transportationCycle.tableList.map((item, index) => {
          item.transportDay = this.selectItem?.transportDay
          if (index === 0) {
            item.oldPrice = this.selectItem?.transportHeadPrice
            this.pageInfoData.avgHeadPrice = item.oldPrice
          } else {
            item.oldPrice = this.selectItem?.transportTailPrice
            this.pageInfoData.avgTailPrice = item.oldPrice
          }
          return item
        })
        this.updateTableDataFn()
      },
      // 更新表格数据
      updateTableDataFn() {
        this.transportationCycle.tableList.map((item, index) => {
          if (this.transportationCycle.transportType * 1 === 2) {
            item.price =
              Number(item.oldPrice) *
              (Number(this.transportationCycle.msgForm.boxWeightKgs) /
                Number(this.transportationCycle.msgForm.numberPerBox))
          } else {
            const { packingSize, size } = this.getPackingSize(
              this.transportationCycle.transportType
            )
            if (size === 1) {
              item.price =
                Number(item.oldPrice) *
                (Number(this.transportationCycle.msgForm.boxWeightKgs) /
                  Number(this.transportationCycle.msgForm.numberPerBox))
            } else {
              item.price =
                Number(item.oldPrice) *
                (Number(packingSize) /
                  Number(this.transportationCycle.msgForm.numberPerBox))
            }
          }
          item.price = !!item.price ? item.price.toFixed(3) : 0
          if (index === 0) {
            this.pageInfoData.transportHeadPrice = item.price
            this.pageInfoData.avgHeadPrice = item.oldPrice
          } else {
            this.pageInfoData.transportTailPrice = item.price
            this.pageInfoData.avgTailPrice = item.oldPrice
          }
          return item
        })

        this.tableKey++
      },
      // 获取装箱尺寸大小
      getPackingSize(type) {
        const unit = type * 1 === 1 ? 6000 : 5000
        let packingSize = 0
        let size = 1 //1是毛重大 2是装箱尺寸大
        if (
          !this.transportationCycle.msgForm.boxLengthCm ||
          !this.transportationCycle.msgForm.boxWidthCm ||
          !this.transportationCycle.msgForm.boxHeightCm
        ) {
          packingSize = 0
        } else {
          packingSize =
            (Number(this.transportationCycle.msgForm.boxLengthCm) *
              Number(this.transportationCycle.msgForm.boxWidthCm) *
              Number(this.transportationCycle.msgForm.boxHeightCm)) /
            unit
        }
        if (packingSize > Number(this.transportationCycle.msgForm.boxWeightKgs))
          size = 2
        this.chooseSize = size
        return {
          packingSize,
          size,
        }
      },
      // 编辑数据回显
      editTableDataFn(editData) {
        this.isChangeByProMsg = false
        this.isSkuSetProduct = editData.skuSettingForProduct == 1 ? true : false
        if (this.isSkuSetProduct) {
          this.transportationCycle.msgForm.numberPerBox = editData?.numberPerBox
          this.transportationCycle.msgForm.boxWeightKgs = editData?.boxWeightKgs
          this.transportationCycle.msgForm.boxLengthCm = editData?.boxLengthCm
          this.transportationCycle.msgForm.boxWidthCm = editData?.boxWidthCm
          this.transportationCycle.msgForm.boxHeightCm = editData?.boxHeightCm
          this.transportationCycle.msgForm.boxLengthIn = editData?.boxLengthIn
          this.transportationCycle.msgForm.boxWidthIn = editData?.boxWidthIn
          this.transportationCycle.msgForm.boxHeightIn = editData?.boxHeightIn
        } else {
          this.transportationCycle.msgForm.numberPerBox =
            editData?.productSpecsEditVOList?.[0]?.numberPerBox
          this.transportationCycle.msgForm.boxWeightKgs =
            editData?.productSpecsEditVOList?.[0]?.boxWeightKgs
          this.transportationCycle.msgForm.boxLengthCm =
            editData?.productSpecsEditVOList?.[0]?.boxLengthCm
          this.transportationCycle.msgForm.boxWidthCm =
            editData?.productSpecsEditVOList?.[0]?.boxWidthCm
          this.transportationCycle.msgForm.boxHeightCm =
            editData?.productSpecsEditVOList?.[0]?.boxHeightCm
          this.transportationCycle.msgForm.boxLengthIn =
            editData?.productSpecsEditVOList?.[0]?.boxLengthIn
          this.transportationCycle.msgForm.boxWidthIn =
            editData?.productSpecsEditVOList?.[0]?.boxWidthIn
          this.transportationCycle.msgForm.boxHeightIn =
            editData?.productSpecsEditVOList?.[0]?.boxHeightIn
        }
        for (let k in this.pageInfoData) {
          this.pageInfoData[k] = editData?.productTransportVO?.[k]
        }
        if (this.$route.query.str === 'clone') {
          this.pageInfoData.productTransportId = ''
        }
        this.transportationCycle.transportType =
          editData?.productTransportVO?.transportType ?? null
        this.getPackingSize(this.transportationCycle.transportType)
        this.transportationCycle.tableList.map((item, index) => {
          item.transportDay = editData?.productTransportVO?.transportDay
          if (index === 0) {
            item.price = editData?.productTransportVO?.transportHeadPrice
            item.oldPrice = editData?.productTransportVO?.avgHeadPrice
          } else {
            item.price = editData?.productTransportVO?.transportTailPrice
            item.oldPrice = editData?.productTransportVO?.avgTailPrice
          }
          // item.oldPrice = (
          //   (Number(item.price) *
          //     Number(this.transportationCycle.msgForm.numberPerBox)) /
          //   Number(this.transportationCycle.msgForm.boxWeightKgs)
          // ).toFixed(3)
          return item
        })
        this.tableKey++
      },
      // 回复初始值
      initialValueFn() {
        this.typeChangeFn(this.pageInfoData.transportType)
      },
      // 详情数据回显
      editShow(editData) {
        if (editData) {
          this.editTableDataFn(editData)
        }
      },
      validateField2() {
        let productinfo = {}
        if (this.isSkuSetProduct) {
          productinfo.numberPerBox =
            this.transportationCycle.msgForm.numberPerBox
          productinfo.boxWeightKgs =
            this.transportationCycle.msgForm.boxWeightKgs
          if (this.transportationCycle.transportType != 2) {
            productinfo.boxLengthCm =
              this.transportationCycle.msgForm.boxLengthCm
            productinfo.boxWidthCm = this.transportationCycle.msgForm.boxWidthCm
            productinfo.boxHeightCm =
              this.transportationCycle.msgForm.boxHeightCm
            productinfo.boxLengthIn =
              this.transportationCycle.msgForm.boxLengthIn
            productinfo.boxWidthIn = this.transportationCycle.msgForm.boxWidthIn
            productinfo.boxHeightIn =
              this.transportationCycle.msgForm.boxHeightIn
          }
        } else {
          productinfo = {}
        }
        return {
          productTransportDTO: this.pageInfoData,
          productinfo,
        }
      },
      // 换算尺寸IN
      boxSize(type) {
        this.isChangeByProMsg = false
        this.changePrice = false
        this.transportationCycle.msgForm[`box${type}In`] = (
          this.transportationCycle.msgForm[`box${type}Cm`] * 0.3937
        ).toFixed(2)
      },
      // 验证
      validateField() {
        let success = false
        let productinfo = {}
        this.$refs.transportationCycle.validate(async (valid) => {
          if (valid) {
            success = true
            if (this.isSkuSetProduct) {
              productinfo.numberPerBox =
                this.transportationCycle.msgForm.numberPerBox
              productinfo.boxWeightKgs =
                this.transportationCycle.msgForm.boxWeightKgs
              if (this.transportationCycle.transportType != 2) {
                productinfo.boxLengthCm =
                  this.transportationCycle.msgForm.boxLengthCm
                productinfo.boxWidthCm =
                  this.transportationCycle.msgForm.boxWidthCm
                productinfo.boxHeightCm =
                  this.transportationCycle.msgForm.boxHeightCm
                productinfo.boxLengthIn =
                  this.transportationCycle.msgForm.boxLengthIn
                productinfo.boxWidthIn =
                  this.transportationCycle.msgForm.boxWidthIn
                productinfo.boxHeightIn =
                  this.transportationCycle.msgForm.boxHeightIn
              }
            } else {
              productinfo = {}
            }
          } else {
            success = false
          }
          if (!success) {
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.el-form-item__error')
            })
          }
        })
        return {
          success,
          productinfo,
          productTransportDTO: this.pageInfoData,
        }
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 3) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },
      handleTableFocus(row, key) {
        // row[key] = ''
      },
    },
  }
</script>
<style lang="scss">
  .delivery-period {
    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;

      .form-cont {
        .el-form-item__content {
          width: 180px;
        }
      }

      .select-col {
        width: 898px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .el-form-item {
          margin-right: 0;

          .el-button {
            border-radius: 6px;
          }
        }
      }

      .tip-el-col {
        margin-top: 20px;
        color: #999;

        .required {
          color: #ff4d4f;
        }
      }

      .specifications-box {
        .el-form-item {
          margin-right: 30px;

          .el-input__inner {
            width: 180px !important;
            height: 32px !important;
          }
        }

        .symbol {
          display: inline-block;
          margin-right: 15px !important;
          margin-left: -15px;
          margin-top: 6px;

          &.mat32 {
            margin-top: 38px;
          }
        }
      }
    }

    .price {
      &.el-form-item {
        margin: 0;

        .el-form-item__content {
          display: flex;
          align-items: center;

          .el-input {
            width: 100px;

            .el-input__inner {
              text-align: center;
            }
          }

          .el-tooltip {
            margin: 0 10px;
          }

          .el-form-item__error {
            display: none;
          }
        }

        &.day {
          .el-form-item__content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }

    .mat32 {
      display: inline-block;
      margin-top: 32px;
    }
  }
</style>