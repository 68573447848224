<template>
  <div class="pro_material">
    <el-row :gutter="20" class="num-total">
      <el-col :span="4">
        <h4>{{ orderTotal.tradingVolume || 0 }}</h4>
        <!-- 订单成交量 -->
        <p>{{ $t('productDetail.OrderVolume') }}</p>
      </el-col>
      <el-col :span="4">
        <h4>
          <span>$</span>
          {{ orderTotal.tradingMoney || 0 }}
        </h4>
        <!-- 总金额 -->
        <p>{{ $t('productDetail.TotalAmount') }}</p>
      </el-col>
    </el-row>
    <el-table class="mt15" :data="orderData" border style="width: 100%">
      <!-- 序号 -->
      <el-table-column
        type="index"
        width="60"
        align="center"
        :label="$t('productDetail.SerialNumber')"
      ></el-table-column>
      <!-- 订单编号 -->
      <el-table-column
        prop="orderCode"
        align="center"
        :label="$t('productDetail.OrderNumber')"
      >
        <template slot-scope="scope">
          <span
            :class="[scope.row.skip === 1 ? 'blue-text' : '']"
            @click="goToPage(scope.row.orderCode, scope.row.skip)"
          >
            {{ scope.row.orderCode }}
          </span>
        </template>
      </el-table-column>
      <!-- 签约日期 -->
      <el-table-column
        align="center"
        prop="contractDate"
        :label="$t('productDetail.DateOfSigning')"
      ></el-table-column>
      <!-- /客户姓名 -->
      <el-table-column
        align="center"
        prop="customerName"
        :label="$t('productDetail.CustomerName')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 公司名称 -->
      <el-table-column
        align="center"
        prop="companyName"
        :label="$t('productDetail.CompanyName')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 客户代表 -->
      <el-table-column
        align="center"
        prop="businessName"
        :label="$t('productDetail.CustomerRepresentative')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ getName(scope.row, 'businessName') }}
        </template>
      </el-table-column>
      <!-- 单价 -->
      <el-table-column
        align="center"
        prop="netPrice"
        :label="$t('productDetail.UnitPrice')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- Setup Charge -->
      <el-table-column
        align="center"
        prop="setUpCharge"
        :label="$t('productDetail.SetupCharge')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 订单数量 -->
      <el-table-column
        align="center"
        prop="amount"
        :label="$t('productDetail.OrderQuantity')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 订单金额 -->
      <el-table-column
        align="center"
        :label="$t('productDetail.OrderAmount')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <!-- <p>￥{{ scope.row.rmbTotle }}</p> -->
          <p>${{ scope.row.usTotle }}</p>
        </template>
      </el-table-column>
      <!-- 订单完成日期 -->
      <el-table-column
        align="center"
        prop="completeTime"
        :label="$t('productDetail.OrderCompletionDate')"
        show-overflow-tooltip
        width="180"
      >
        <template slot-scope="scope">
          {{ scope.row.completeTime || '--' }}
        </template>
      </el-table-column>
      <!-- 创建时间 -->
      <el-table-column
        align="center"
        prop="createTime"
        :label="$t('productDetail.CreationTime')"
        show-overflow-tooltip
        width="180"
      ></el-table-column>
    </el-table>

    <Pagination
      v-show="total > 0"
      :limit.sync="queryForm.pageLe"
      :page.sync="queryForm.pageNo"
      :total="total"
      @pagination="proSelectSalesOrderProductPageVO"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Index'
import nameMixin from '@/utils/name-mixin'
import {
  getOrderProductVO,
  selectSalesOrderProductPageVO,
} from '@/api/product/product-info'
export default {
  name: 'OrderRecord',
  mixins: [nameMixin],
  components: { Pagination },
  data() {
    return {
      queryForm: {
        pageLe: 10,
        pageNo: 1, //当前页数
      },
      total: 0,
      productId: '', //产品id
      orderTotal: {}, //订单的一些总计
      orderData: [], //订单的记录列表
    }
  },

  created() {
    if (this.$route.query.proId) {
      this.productId = this.$route.query.proId
      //产品订单记录与订单记录列表
      this.proGetOrderProductVO()
      this.proSelectSalesOrderProductPageVO()
    }
  },
  mounted() {},
  methods: {
    //订单记录
    async proGetOrderProductVO() {
      let response = await getOrderProductVO({ productId: this.productId })
      if ((response.code = '000000')) {
        this.orderTotal = response.data
      }
    },
    //订单记录列表
    async proSelectSalesOrderProductPageVO(pageData) {
      if(pageData) {
        this.queryForm.pageNo = pageData.page
        this.queryForm.pageLe = pageData.limit
      }
      let response = await selectSalesOrderProductPageVO({
        ...this.queryForm,
        productId: this.productId,
      })
      if ((response.code = '000000')) {
        this.orderData = response.data.data
        this.total = response.data.total
      }
    },
    //跳转
    goToPage(code, skip) {
      let routeData = this.$router.resolve({
        path: '/order/orderList/orderDetail',
        query: {
          orderCode: code,
          noReturn: true,
        },
      })
      if (skip === 1) {
        window.open(routeData.href, '_blank')
      } else {
        return
      }
    },
  },
}
</script>

<style scoped lang="scss">
.num-total {
  padding-left: 15px;
  > .el-col {
    border: 1px solid #ededed;
    padding: 15px 20px;
    border-radius: 2px;
    h4 {
      font-size: 30px;
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }
    p {
      margin-top: 5px;
    }
  }
  .el-col + .el-col {
    margin-left: 10px;
  }
}
::v-deep {
  .el-tabs__content {
    padding-left: 50px !important;
  }
}
</style>
