<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="900px"
      @close="close"
      v-if="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="中文名称" prop="nameCn">
              <el-input v-model="form.nameCn" placeholder="输入中文名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="nameEn" label="英文名称">
              <el-input v-model="form.nameEn" placeholder="输入英文名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="12">
          <el-form-item label="匹配材质" prop="materialIds">
            <el-select
              v-model="form.materialIds"
              placeholder="请选择"
              collapse-tags
              multiple
            >
              <el-option
                v-for="item in proMaterialData"
                :key="item.productSettingId"
                :label="item.nameEn"
                :value="item.productSettingId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
          <el-col :span="12">
            <el-form-item label="可选规格" prop="specsIds">
              <el-select
                v-model="form.specsIds"
                placeholder="请选择"
                collapse-tags
                multiple
                @change="specsChange"
              >
                <el-option
                  v-for="item in proSpecData"
                  :key="item.productSettingId"
                  :label="item.nameCn"
                  :value="item.productSettingId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上架平台" prop="platformFlags">
              <el-checkbox-group v-model="form.platformFlags">
                <el-checkbox
                  v-for="item of platformDs"
                  :key="item.value"
                  :label="item.value"
                  :disabled="!chooseList.includes(item.value)"
                >
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="SAGE 平台类目">
              <RemoteSelect
                :isEdit="type === 'edit'"
                v-model="form.sageCategoryId"
                :sageCategoryName.sync="form.sageCategoryName"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="关联标签" prop="labelIds">
            <el-select
              v-model="form.labelIds"
              placeholder="请选择"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in parentLabelList"
                :key="item.keyParentId"
                :label="item.nameCn + '(' + item.nameEn + ')'"
                :value="item.keyParentId"
              />
            </el-select>
          </el-form-item>
        </el-row>
        <!-- <el-row>
        <el-col :span="12">
          <el-form-item label="排序" prop="sort">
            <el-input
              v-model="form.sort"
              placeholder="输入数值"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            />
          </el-form-item>
        </el-col>
      </el-row>-->

        <el-row>
          <el-form-item label="图片" prop="img">
            <!-- <template slot="label">
            <i style="color: #ff4d4f">*</i>
            图片
          </template>-->
            <el-upload
              v-model="form.img"
              ref="coverUpload"
              name="upload"
              action
              :before-upload="beforePicUpload"
              :http-request="uploadImgAction"
              :file-list="imgMainFileList"
              :on-preview="handlePreview"
              :on-remove="handleRemoveImgMain"
              :limit="1"
              :auto-upload="true"
              list-type="picture-card"
              accept=".jpg, .png, .jpeg"
            >
              <i class="el-icon-plus" />
              <div slot="tip" class="el-upload__tip mt20">
                支持jpg/jpeg/png，最多上传
                <span class="f_s_16">1</span>
                张，不允许超过2MB
              </div>
            </el-upload>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              maxlength="500"
              show-word-limit
              style="white-space: pre-wrap"
              :autosize="{ minRows: 2 }"
              placeholder="请输入500个字符以内"
            />
          </el-form-item>
        </el-row>
      </el-form>
      <template #footer>
        <el-row class="text-center">
          <el-button type="primary" :loading="saveLoading" @click="save">
            确 认
          </el-button>
          <el-button @click="close">取消</el-button>
        </el-row>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="dialogPreview">
      <img width="100%" :src="dialogPreviewUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
  import { getDetailByType } from '@/api/product/productSetting'
  import { listParentForSelect } from '@/api/product/labelManage'
  import { insertPO, selectPO, savePO } from '@/api/product/catesManager'
  import { deepCopy } from 'kits'
  import RemoteSelect from './RemoteSelect'
  import { platformDs } from '@/utils/dicts'
  export default {
    name: 'CategoryModel',
    components: { RemoteSelect },
    data() {
      return {
        saveLoading: false,
        form: {
          nameEn: '',
          img: '',
          level: 0,
          pid: '0',
          specsIds: [], // 可选规格
          platformFlags: [], // 上架平台
          sageCategoryId: '', // 平台类目
          sageCategoryName: '', // 平台类名名称
        },
        rules: {
          nameCn: [
            { required: true, trigger: 'blur', message: '请输入中文名称' },
          ],
          nameEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
          specsIds: [
            { required: true, message: '请选择规格', trigger: 'change' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        dialogPreviewUrl: '',
        dialogPreview: false,
        proSpecData: [], //规格数据1
        parentLabelList: [], //关联标签
        imgMainFileList: [], //类别图片
        categoryId: '', //类别id
        loading: null,
        platformDs: platformDs.filter(n => n.value !== 'SAGE'), // 上架平台字典
        type: '', // add=新增 edit=编辑
        chooseList: [], // 当前可操作的上架平台
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.getDetailByType1()
        this.listParentForSelect()
      },
      //规格下拉
      async getDetailByType1() {
        let response = await getDetailByType({ type: 1 })
        if ((response.code = '000000')) {
          this.proSpecData = response.data
        }
      },
      // 关联标签
      async listParentForSelect() {
        let response = await listParentForSelect()
        if ((response.code = '000000')) {
          this.parentLabelList = response.data
        }
      },
      // 规格选择(取规格名称)
      specsChange(id) {
        let idsArr = id
        let idsArrFilter = []
        idsArr.forEach((item) => {
          this.proSpecData.forEach((item2) => {
            if (item === item2.productSettingId) {
              idsArrFilter.push(item2.nameCn)
            }
          })
        })
        this.form.specsNames = idsArrFilter.join(',')
      },
      // 规格
      categoryModel(str, row, type) {
        this.chooseList = row.chooseList
        this.type = type
        if (str === 'top' || str === 'addChild') {
          this.title = '新增类别'
          this.form.platformFlags = [] // 上架平台
          this.form.sageCategoryId = '' // 平台类目
          this.form.sageCategoryName = '' // 平台类目名称
          if (str === 'top') {
            this.form.level = 0
            this.form.pid = '0'
          }
          //新增子级，level加1
          if (row && row.productCategoryId) {
            this.form.pid = row.productCategoryId
            this.form.level = row.level + 1
          }
        } else {
          this.title = '编辑类别'
          this.categoryDetail(row.productCategoryId)
          this.categoryId = row.productCategoryId
        }
        this.dialogFormVisible = true
      },
      // 详情
      async categoryDetail(productCategoryId) {
        let response = await selectPO({ productCategoryId: productCategoryId })
        if (response.code == '000000') {
          this.form = response.data
          if(this.form.platformFlags) {
            this.form.platformFlags = this.form.platformFlags.split(',') // 上架平台回显
          } else {
            this.form.platformFlags = []
          }
          if(this.form.specsIds) {
            this.form.specsIds = this.form.specsIds.split(',')
          }

          if (this.form.labelIds) {
            this.form.labelIds = this.form.labelIds.split(',')
          }

          if (this.form.img) {
            this.imgMainFileList = [{ url: this.form.img }]
          }
        }
      },
      close() {
        this.specsIds = []
        this.imgMainFileList = []
        this.$refs['form'].resetFields()
        this.form = {
          nameEn: '',
          img: '',
          level: 0,
          pid: '0',
          imgId: '',
          specsIds: [],
          platformFlags: [], // 上架平台
          sageCategoryId: '', // 平台类目
          sageCategoryName: '', // 平台类目名称
        }
        this.categoryId = ''
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let form = deepCopy(this.form)
            // form.materialIds = form.materialIds.join(',')
            form.specsIds = form.specsIds.join(',')
            form.platformFlags = form.platformFlags.join()
            if (form.labelIds && form.labelIds.length) {
              form.labelIds = form.labelIds.join(',')
            } else {
              form.labelIds = ''
            }
            // console.log('提交', JSON.parse(JSON.stringify(form)))
            // return
            let response
            this.saveLoading = true
            if (!this.categoryId) {
              response = await insertPO(form)
              this.saveLoading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '新增成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              } else {
                this.$baseMessage(
                  response.desc,
                  'error',
                  false,
                  'erp-hey-message-error'
                )
              }
            } else {
              response = await savePO(form)
              this.saveLoading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '修改成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }

            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      // 上传之前验证
      beforePicUpload(file) {
        this.loading = this.$loading({
          lock: true,
          text: '图片上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.3)',
        })
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension =
          testmsg === 'jpg' ||
          testmsg === 'JPG' ||
          testmsg === 'png' ||
          testmsg === 'PNG' ||
          testmsg === 'jpeg' ||
          testmsg === 'JPEG'
        const isLt50M = file.size / 1024 / 1024 < 2
        if (!extension) {
          this.loading.close()
          this.$baseMessage(
            '上传图片只能是jpg/png/jpeg格式',
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false //必须加上return false; 才能阻止
        }
        if (!isLt50M) {
          this.loading.close()
          this.$baseMessage(
            '上传图片大小不能超过2MB!',
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        }
        return true
      },
      // 主图上传
      uploadImgAction({ file }) {
        this.utils.ossUpload({ file, type: 'productImg' }).then(({ res }) => {
          if (res.requestUrls.length > 0) {
            let url = res.requestUrls[0].split('?uploadId')
            this.form.img = url[0]
          } else {
            this.imgMainFileList = []
            this.form.img = ''
            return this.$baseMessage(
              '类别图片上传失败',
              'error',
              false,
              'erp-hey-message-error'
            )
          }

          this.loading.close()
        })
      },
      // 删除主图
      handleRemoveImgMain(file, fileList) {
        this.imgMainFileList = []
        this.form.img = ''
        this.form.imgId = ''
      },
      // 预览主图、轮播图
      handlePreview(file) {
        this.dialogPreviewUrl = file.url
        this.dialogPreview = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .price_el {
    border-bottom: 1px solid #f5f5f5;
  }

  .el-divider--horizontal {
    margin: 30px 0 28px 0 !important;

    .f_s_12 {
      color: #909399;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }

    .el-textarea__inner {
      padding: 5px 50px 5px 10px;
    }
  }
</style>
