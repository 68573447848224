var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.planOrderVisible)?_c('el-dialog',{staticClass:"plan-order",attrs:{"title":"安排订单","visible":_vm.planOrderVisible,"before-close":_vm.close,"width":"1400px"},on:{"update:visible":function($event){_vm.planOrderVisible=$event}}},[_c('div',{staticClass:"container"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form}},[_c('div',{staticClass:"c-table-wrap"},[_c('el-table',{attrs:{"data":_vm.form.tableData,"span-method":function () {
                  var rest = [], len = arguments.length;
                  while ( len-- ) rest[ len ] = arguments[ len ];

                  return _vm.mergeRowByProp.apply(void 0, rest.concat( [_vm.form.tableData], [_vm.mergeList] ));
},"border":"","max-height":"500"}},[_c('el-table-column',{attrs:{"prop":"orderCode","label":"订单编号","min-width":"160","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"Item","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.item && row.item.includes('****') ? row.item.split('****')[1] : '--')+" ")]}}],null,false,3454437925)}),_c('el-table-column',{attrs:{"label":"产品编号","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.productCode)?_c('span',{staticClass:"page-link"},[_vm._v(" "+_vm._s(row.productCode)+" ")]):_c('span',[_vm._v("/")])]}}],null,false,2034554464)}),_c('el-table-column',{attrs:{"label":"规格","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.specification || '/')+" ")]}}],null,false,799746893)}),_c('el-table-column',{attrs:{"label":"Qty","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.amount || '/')+" ")]}}],null,false,968378652)}),_c('el-table-column',{attrs:{"label":"印刷方式","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.printingWayEn || '--')+" ")]}}],null,false,2082236266)}),_c('el-table-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" 期望交期 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'tableData[' + $index + '].expectedDeliveryDate',"rules":_vm.rules.expectedDeliveryDate}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"选择日期","value-format":"yyyy-MM-dd"},model:{value:(row.expectedDeliveryDate),callback:function ($$v) {_vm.$set(row, "expectedDeliveryDate", $$v)},expression:"row.expectedDeliveryDate"}})],1)]}}],null,false,2199193441)}),_c('el-table-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" 采购开发 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'tableData[' + $index + '].salesId',"rules":_vm.rules.salesId}},[_c('UserSelect',{attrs:{"roleName":"采购员","defaultUser":{
                    userName: row.salesName,
                    englishName: row.salesNameEn,
                    userId: row.salesId,
                  }},on:{"user-choose":function (user) { return _vm.handleUserChoose(user, row); }}})],1)]}}],null,false,2077931356)}),_c('el-table-column',{attrs:{"prop":"id","label":"订单备注","min-width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
                  var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'tableData[' + $index + '].remark',"rules":_vm.rules.remark}},[_c('el-input',{attrs:{"placeholder":"请输入","clearable":""},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}})],1)]}}],null,false,282882631)})],1),_c('el-checkbox',{staticClass:"mt10",model:{value:(_vm.sendEmailFlag),callback:function ($$v) {_vm.sendEmailFlag=$$v},expression:"sendEmailFlag"}},[_vm._v(" 发送邮件 ")]),_c('p',{staticClass:"mt5 f_s_12",staticStyle:{"color":"#999","margin-left":"23px"}},[_vm._v(" 勾选后，采购订单初次审核通过后，会自动发送“安排生产”邮件给客户 ")])],1)])],1),_c('div',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.isLoading ? '加载中' : '确认')+" ")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }