<template>
  <div>
    <p class="title">
      <!-- 邮件同步数量设置：系统绑定邮箱后，会根据设置，拉取邮件： -->
      <span>{{ $t('mailConfig.SyncQuantitySettingTips') }}：</span>
    </p>
    <!-- <el-radio-group v-model="type"></el-radio-group> -->
    <el-radio-group v-if="flag" v-model="form.optional">
      <p>
        <!-- 同步最近邮件 -->
        <el-radio :label="0">
          {{ $t('mailConfig.SyncRecentEmails') }}：
        </el-radio>
        <el-select
          v-model="form.mailDate"
          :placeholder="$t('placeholder.plsSel')"
          class="w-150"
          style="width: 150px"
        >
          <el-option
            v-for="item in mailDateList"
            :key="item.value"
            :label="settings === 'en' ? item.labelEn : item.label"
            :value="item.mailDate"
          ></el-option>
        </el-select>
      </p>
      <p>
        <!-- 邮件同步数量 -->
        <el-radio :label="1">{{ $t('mailConfig.EmailSyncCount') }}：</el-radio>
        <el-select
          v-model="form.mailNumber"
          :placeholder="$t('placeholder.plsSel')"
          style="width: 150px"
        >
          <el-option
            v-for="item in mailNumberList"
            :key="item.value"
            :label="settings === 'en' ? item.labelEn : item.label"
            :value="item.mailNumber"
          ></el-option>
        </el-select>
      </p>
    </el-radio-group>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'mailReceivingSetting',
  props: {
    mailDateList: {
      default: () => [],
      type: Array,
    },
    mailNumberList: {
      default: () => [],
      type: Array,
    },
    baseForm: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      settings: 'settings/language',
    }),
  },
  watch: {
    settings() {
      this.flag = false
      this.flag = true
    },
    baseForm: {
      handler() {
        this.form = { ...this.baseForm }
      },
      deep: true,
      immediate: true,
    },
    form: {
      handler() {
        this.changeMailSetting()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  data() {
    return {
      form: {},
      type: '',
      flag: true,
    }
  },
  methods: {
    changeMailSetting() {
      this.$emit('changeMailSetting', this.form)
    },
  },
}
</script>
<style lang="scss" scoped>
.el-radio-group {
  p {
    margin-bottom: 15px;
    padding-left: 20px;
  }
}
::v-deep {
  .el-tabs__content {
    height: 100%;
  }
}
.leave {
  color: #f56c6c;
  white-space: nowrap;
}
.bottom {
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
}
.title {
  margin-bottom: 20px;
  color: #000;
  font-size: 15px;

  .info {
    color: #999;
  }
}
</style>