<!--
  功能：装箱尺寸展示组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月25日 14:11:06
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="size-wrapper">
    <!-- :title="size.boxLengthCm + 'x' + size.boxWidthCm + 'x' + size.boxHeightCm" -->
    <p v-for="(size, index) in sizes" :key="index" class="line-one">
      <!-- 长x宽x高 -->
      <span>{{ size.boxLengthCm }}</span>
      <span class="seperator">x</span>
      <span>{{ size.boxWidthCm }}</span>
      <span class="seperator">x</span>
      <span>{{ size.boxHeightCm }};</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PackingSizeDetail',
  props: {
    sizes: {
      type: Array,
      default: () => [],
    },
  },
}
</script> 

<style scoped lang="scss">
div {
  display: flex;
  align-items: flex-start;
  justify-content: 'center';
  flex-direction: column;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  }

  .seperator {
    margin: 0 5px;
  }
}
</style>
