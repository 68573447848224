<template>
  <el-dialog
    :title="title"
    width="600px"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <div v-if="isSend">
      <p>密码已发送至手机号：{{ phone }}，请联系员工检查手机短信</p>

      <div class="text-right mt20">
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </div>
    <div v-else>
      <p>密码未发送或发送失败，你也可以手动复制密码</p>

      <div class="text-right mt20">
        <el-button
          type="primary"
          v-clipboard:copy="password"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          复制密码
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '账号创建成功',
    },
    phone: {
      type: String,
      default: '',
    },
    password: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSend: true, // 是否发送成功
      dialogVisible: false,
    }
  },
  methods: {
    // 初始化
    init(n) {
      n === 1 ? (this.isSend = true) : (this.isSend = false)
      this.dialogVisible = true
    },

    // 确定
    submit() {
      this.handleClose()
      this.$emit('close')
    },

    // 复制成功回调
    onCopy: function (e) {
      this.$message.success('复制成功！')
      this.handleClose()
      this.$emit('close')
    },

    // 复制失败回调
    onError: function (e) {
      this.$message.error('复制失败！')
      this.handleClose()
      this.$emit('close')
    },

    handleClose() {
      this.dialogVisible = false
      this.$emit('close')
    },
  },
}
</script>