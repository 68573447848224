<!--
  功能：订单结算列表页
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月28日 14:28:04
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="custom-container">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="18" class="page-header-left">
        <el-row type="flex" class="align_center flex_row_y flex_wrap top-search">
          <el-form ref="form" :inline="true" :model="queryForm" label-width="49px" @submit.native.prevent>
            <el-form-item>
              <el-input v-model="queryForm.orderNumber" placeholder="请输入结算单号/订单编号" clearable
                @keyup.enter.native="getList" @input="searchInput" />
            </el-form-item>
          </el-form>
          <el-button @click="onButtonSearch">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            搜索
          </el-button>
          <el-button class="ml10" @click="showDrawer">高级筛选</el-button>
          <el-button class="ml10" @click="batchSettlement" v-show="showBatchSettlement"
            v-allowed="['SETTLEMENT:SETTLEMENT']">
            批量结算
          </el-button>
          <el-button class="ml10" @click="batchEdit" v-show="queryForm.checkBoxStatus == 2"
            v-allowed="['SETTLEMENT:EDIT']">
            批量编辑
          </el-button>
          <el-button class="ml10" @click="assignSettlementSpecialist" v-if="showBatchButton">
            分配结算专员
          </el-button>
          <!-- 导出处理中excel -->
          <el-button class="ml10" @click="exportInProgressFn"  v-allowed="['SETTLEMENT:EXPORTINPROGRESS']">
            导出处理中excel
          </el-button>
          <!-- 导出已结算excel -->
          <el-tooltip class="item" effect="dark" :content="existTodayTaskInfo.message" placement="top" v-if="existTodayTaskInfo.exist">
            <el-button class="ml10" disabled v-allowed="['SETTLEMENT:EXPORTSETTLEDEXCEL']">
              导出已结算excel<i class="el-icon-warning" style="color: red"></i>
            </el-button>
          </el-tooltip>
          <el-button class="ml10" v-if="!existTodayTaskInfo.exist" @click="getHistoryTaskList"
          v-allowed="['SETTLEMENT:EXPORTSETTLEDEXCEL']"
          >
              导出已结算excel
            </el-button>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-row type="flex" justify="end">
          <!-- 结算条件设置 -->
          <el-button @click="setSettlementConditions" v-allowed="['SETTLEMENT:CONDITION']">
            结算条件设置
          </el-button>
        </el-row>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <!-- 状态列表 -->
    <div style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      ">
      <el-radio-group class="mb5" v-model="queryForm.checkBoxStatus">
        <el-radio v-for="(item, i) in statusList" :key="i" :label="item.code">
          {{ item.name }}
        </el-radio>
      </el-radio-group>
      <el-popover ref="showOption" popper-class="custom-table-checkbox" trigger="hover">
        <erp-draggable v-bind="dragOptions" :list="columns">
          <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
            :disabled="item.checkable === false" :label="item.label">
            {{ item.label }}
          </el-checkbox>
        </erp-draggable>
        <!-- 保存自定义显示列 -->
        <div class="save-btn">
          <el-button type="primary" class="ml10" size="mini" plain @click="operateSave">
            保存
          </el-button>
        </div>
        <template #reference>
          <el-tooltip effect="dark" content="列表可拖动排序" placement="top-start">
            <el-button plain type="primary" class="pub-h-36">
              自定义显示
            </el-button>
          </el-tooltip>
        </template>
      </el-popover>
    </div>

    <ErpTable :page-number.sync="page.pageNo" :page-size.sync="page.pageLe" :table-data="tableData" :total="total"
      :extraHeight="30" @query="getList" class="table-fixed" ref="ErpTable" @selection-change="setSelectRows"
      :span-method="objectSpanMethod" :cell-style="cellStyle" :key="tablekey" :handleCancelRow="true">
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="item.label" show-overflow-tooltip
        :width="item.width" min-width="120" :resizable="false" align="center" :prop="item.prop">
        <template slot="header">
          <el-tooltip class="item" effect="dark" placement="top" :content="
              item.prop === 'homeFreight'
                ? '包含国内物流与国内快递'
                : '费用单中的其他费用合计'
            ">
            <i class="el-icon-question mr-5" v-if="item.prop === 'homeFreight' || item.prop === 'otherCharges'"></i>
          </el-tooltip>
          <span>{{ item.label }}</span>
        </template>
        <template #default="{ row }">
          <div v-if="item.prop === 'settlementTypeDesc'">
            {{ row.settlementTypeDesc ? row.settlementTypeDesc : '--' }}
            <el-link icon="el-icon-arrow-down" :underline="false" @click="checkRecode(row)"
              v-if="row.settlementFlag == 2"></el-link>
          </div>
          <div v-else-if="item.prop === 'settlementOrder'">
            <span class="page-link" @click="showSettlementDetails(row)" v-if="row.settlementOrder">
              {{ row.settlementOrder }}
            </span>
            <span v-else>--</span>
          </div>
          <div v-else-if="item.prop === 'company'" class="whi">
            {{ row.company ? row.company : '--' }}
          </div>

          <el-popover placement="bottom" trigger="click" width="500" @show="getOtherDetail(row)"
            v-else-if="item.prop === 'otherCharges'">
            <el-table :data="gridData" border>
              <el-table-column property="costNumber" label="费用单号" align="center"></el-table-column>
              <el-table-column property="costName" label="费用类型" align="center"></el-table-column>
              <el-table-column property="shareCostPrice" label="金额" align="center">
                <template slot-scope="scope">
                  <span :class="scope.row.entryType == 1 ? 'red' : 'green'">
                    {{ scope.row.entryType == 1 ? '-' : ''
                    }}{{ scope.row.shareCostPrice }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-link type="priamry" slot="reference">
              {{ row.otherCharges | orderMoneyFormat }}
            </el-link>
          </el-popover>
          <!-- 产品信息 -->
          <div v-else-if="item.prop === 'sku'">
            <div class="product-info">
              <el-popover class="pro-item" trigger="click" :disabled="row.combinationProduct !== 2">
                <el-table :data="productList" border>
                  <el-table-column width="100" label="产品图" align="center">
                    <template slot-scope="scope">
                      <erp-image :imgs="scope.row.productImg"></erp-image>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" align="center" prop="productCode" label="产品编码">
                    <template slot-scope="scope">
                      <span class="page-link" @click="showProDetail(scope.row)">
                        {{ scope.row.productCode }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column width="150" align="center" label="中文品名/英文品名" prop="parentName">
                    <template slot-scope="scope">
                      <span>
                        {{ scope.row.nameCn ? scope.row.nameCn : '--' }}
                      </span>
                      /
                      <span>
                        {{ scope.row.nameEn ? scope.row.nameEn : '--' }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column width="80" align="center" prop="parentSpecsValue" label="规格">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.productSpec }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column width="80" prop="numDemand" label="数量" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.num || '--' }}
                    </template>
                  </el-table-column>
                </el-table>
                <p class="left" @click="getProductList(row)" slot="reference">
                  <span :style="{
                      cursor: row.combinationProduct === 2 ? 'pointer' : '',
                    }">
                    {{ row.sku }}
                  </span>

                  <span class="pro-item" :style="{
                      cursor: row.combinationProduct === 2 ? 'pointer' : '',
                    }">
                    {{ row.productNameCn }}
                  </span>
                </p>
              </el-popover>
              <p>
                <!-- 产品类型 -->
                <el-tag v-if="row.productStandardCustomized">
                  {{ row.productStandardCustomized }}
                </el-tag>
                <el-tag type="warning" v-if="row.productionLine">
                  {{ row.productionLine }}
                </el-tag>
              </p>
            </div>
          </div>

          <div v-else-if="item.prop === 'settlementAttach'" @click="setSettlementUsers([row])">
            <span v-if="!row['settlementAttach']" class="page-link">
              未设置
            </span>
            <span v-else class="page-link">{{ row['settlementAttach'] }}</span>
          </div>
          <!-- 订单编号 -->
          <div v-else-if="item.prop === 'salesOrder'">
            <span class="page-link" @click="showOrderDetail(row)">
              {{ row.salesOrder }}
            </span>
          </div>
          <!-- 结算状态 -->
          <div v-else-if="item.prop === 'settlementStatus'">
            <el-tag :type="row.tagType" @click="statusTypeClick(row)" :style="{
                cursor: ['2', '4', '5'].includes(row.settlementStatus)
                  ? 'pointer'
                  : '',
              }">
              {{ row.settlementStatus | settlementStatus }}
            </el-tag>
          </div>
          <div v-else-if="item.prop === 'salesNum'">
            {{ row[item.prop] || row[item.prop] === 0 ? row[item.prop] : '' }}
          </div>
          <div v-else-if="item.prop === 'purchaseNum'">
            {{ row[item.prop] || row[item.prop] === 0 ? row[item.prop] : '' }}
          </div>
          <div v-else-if="item.prop === 'warehouseOutNum'">
            {{ row[item.prop] || row[item.prop] === 0 ? row[item.prop] : '' }}
          </div>

          <div v-else-if="item.isMoney">
            <SettlementCurrency :money="row[item.prop]" :control="true" :invisibleJudgment="item.invisibleJudgment" />
          </div>
          <div v-else>
            <SettlementField :invisibleJudgment="!!item.invisibleJudgment" :value="row[item.prop]" />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150" fixed="right" :resizable="false">
        <template slot-scope="scope">
          <el-tooltip class="item" content="撤销" placement="top" v-if="['4'].includes(scope.row.settlementStatus)">
            <el-button type="primary" plain size="mini" @click="revert(scope.row)" icon="iconfont icon-quxiao"
              circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" content="编辑" placement="top"
            v-if="['2', '1', '3'].includes(scope.row.settlementStatus)">
            <el-button v-allowed="['SETTLEMENT:EDIT']" type="primary" plain size="mini" @click="
                onSettlement(
                  scope.row.settlementId,
                  scope.row.settlementOrder,
                  true
                )
              " icon="iconfont el-icon-edit" circle></el-button>
          </el-tooltip>

          <el-tooltip class="item" content="结算" placement="top" v-if="['6'].includes(scope.row.settlementStatus)">
            <el-button type="primary" plain size="mini" v-allowed="['SETTLEMENT:SETTLEMENT']"
              @click="onSettlement(scope.row.settlementSalesOrderId)" icon="iconfont icon-quotation" circle></el-button>
          </el-tooltip>

          <el-tooltip class="item" content="结算驳回" placement="top"
            v-if="['1', '2', '3', '6'].includes(scope.row.settlementStatus)">
            <el-button type="danger" plain icon="el-icon-close" circle size="mini"
              @click="rejectClick(scope.row, 'reject')"></el-button>
          </el-tooltip>

          <el-tooltip class="item" content="查看" placement="top" v-if="['5'].includes(scope.row.settlementStatus)">
            <el-button @click="showSettlementDetails(scope.row)" type="primary" plain size="mini" icon="el-icon-search"
              circle></el-button>
          </el-tooltip>
          <!-- <el-tooltip
            class="item"
            content="结算调整"
            placement="top"
            v-if="['5'].includes(scope.row.settlementStatus)"
          >
            <el-button
              @click="rejectClick(scope.row, 'adjust')"
              type="primary"
              plain
              size="mini"
              icon="iconfont icon-shenqing-bianji-04"
              circle
            ></el-button>
          </el-tooltip> -->
          <el-tooltip class="item" content="附件下载" placement="top" v-if="scope.row.enclosure">
            <el-button type="primary" plain size="mini" icon="iconfont icon-xiazaidaoru" circle
              @click="downloadDocu(scope.row)"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </ErpTable>
    <!-- 结算条件设置 -->
    <SettlementConditionSetting ref="settlementConditionSetting" />
    <!-- 撤销 -->

    <!-- 撤销弹窗 -->
    <el-dialog title="撤销原因" :visible="cancelVisible" append-to-body width="500px" @close="onCancel"
      modal-append-to-body>
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input type="textarea" :rows="3" placeholder="请输入撤销原因" maxlength="500" show-word-limit
        v-model="cancelReason"></el-input>
      <div class="footer mt30 t_a_c">
        <el-button type="primary" @click="cancelSure">确 认</el-button>
        <el-button @click="onCancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 审核进度 -->
    <el-drawer title="审核进度" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="settlementId" :auditType="auditType" @cancelClick="statusClose" />
    </el-drawer>

    <!-- 高级筛选 -->
    <!-- 高级筛选 -->
    <ErpAdvancedFiltering ref="filterDrawer" @confirm="onDrawerConfirm" @open="onDrawerOpen" @cancel="onDrawerCancel"
      @reset="onDrawerReset" confirmButtonText="确 定" showReset :showCancel="false">
      <div class="form-wrapper">
        <el-form label-position="top">
          <el-form-item label="客户代表" @click="getUser('businessIdList')">
            <el-input clearable @clear="filterForm.businessIdList = []" @focus="getUser('businessIdList')"
              v-model="filterForm.business" placeholder="请选择"></el-input>
          </el-form-item>
          <el-form-item label="订单月份">
            <div class="item-fee">
              <el-select v-model="filterForm.year" clearable class="item" placeholder="请选择年份">
                <el-option v-for="y in years" :key="y" :value="y" :label="y + '年'">
                  {{ y }}年
                </el-option>
              </el-select>
              <span style="margin: 0 5px">-</span>
              <el-select v-model="filterForm.month" clearable class="item" multiple collapse-tags placeholder="请选择月份">
                <el-option v-for="m in months" :key="m.value" :value="m.value" :label="m.label">
                  {{ m.label }}
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input clearable v-model="filterForm.company" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="产品编码 / 产品名称">
            <el-input clearable v-model="filterForm.productCodeOrName" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="产品类型">
            <el-select clearable v-model="filterForm.productStandardCustomized" placeholder="请选择">
              <el-option v-for="type in productTypes" :key="type.code" :label="type.label"
                :value="type.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品运营">
            <el-input clearable @clear="filterForm.productOperatingIdList = []" v-model="filterForm.productOperating"
              @focus="getUser('productOperatingIdList')" placeholder="请选择"></el-input>
          </el-form-item>
          <el-form-item label="采购开发">
            <el-input clearable @clear="filterForm.buyerIdList = []" @focus="getUser('buyerIdList')"
              v-model="filterForm.buyer" placeholder="请选择"></el-input>
          </el-form-item>
          <el-form-item v-for="(item, index) in fees" :key="index" :label="item.label">
            <div class="item-fee">
              <el-input-number :controls="false" class="item" clearable
                v-model="filterForm[item.start]"></el-input-number>
              <span style="margin: 0 5px">至</span>
              <el-input-number :controls="false" class="item" clearable
                :min="filterForm[item.start] ? filterForm[item.start] : 0"
                v-model="filterForm[item.end]"></el-input-number>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </ErpAdvancedFiltering>
    <!-- 驳回弹窗 -->
    <settlement-reject :orderRejectVisible.sync="orderRejectVisible" :title="modelTitle" :type="modelType"
      :salesOrderId="rejectSalesOrderId" :settlementSalesOrderId="rejectSettlementSalesOrderId"
      :tenantId="userInfo.tenantId" :settlementOrder="settlementOrder" :area="area" :dialogObj="dialogObj"
      @refreshList="onButtonSearch"></settlement-reject>
    <settlement-record :tableData="recordList" :dialogVisible.sync="recordVisible"></settlement-record>
    <el-dialog
      title="已结算PI导出确认"
      :visible.sync="exportConfirmation"
      width="420px"
      >
      <div class="export-content">
        <p><i class="el-icon-warning"></i>已结算PI最近10次导出记录如下，请确认是否仍导出？</p>
        <div v-for="(item,index) in existTodayTaskInfoList" :key="index">
          <span>
            {{ index+1 }}、
          </span>
          <span style="width:150px;">
            {{ item.createTime }}
          </span>
          <span>
            {{ item.creator }}
          </span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportConfirmation = false">取 消</el-button>
        <el-button type="primary" @click="exportConfirmationFn">确 定</el-button>
      </span>
    </el-dialog>
     <!-- 导出成功 -->
     <success-model :visible.sync="downloadVisible"></success-model>
  </div>
</template>

<script>
  import columns, { statusList, options } from './helper'
  import { mapGetters } from 'vuex'
  import SettlementConditionSetting from './components/settlement-condition-setting'
  import SettlementCurrency from './components/settlement-list-currency'
  import SettlementField from './components/settlement-list-field'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import { getAuditObject, cancel } from '@/api/stock-center/inbound-sheet'
  import { getRangeYears } from 'kits'
  import CustomCache from '@/utils/custom-cache'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import ErpDraggable from 'vuedraggable'
  import { OrderSettleInteractor } from '@/core/interactors/finance/order-settlement'
  import settlementReject from './components/settlement-reject'
  import settlementRecord from './components/settlement-record'
  import SuccessModel from '@/components/tableImport/successModel'
  import {
    getListData, // 列表数据
    setSettlemenSpecialist, // 设置结算专员
    getPurchesForFinance,
    checkOrderRelCostStatus,
  } from '@/api/order-settlement'
  const userKeys = [
    'businessName',
    'productOperating',
    'buyerName',
    'orderSupportManager',
    'businessManager',
    'productOperatingManager',
    'buyerManager',
    'orderSupportManager',
  ]
  const indexToUserLabel = {
    0: '客户代表',
    1: '产品运营',
    2: '采购开发',
    3: '订单支持',
    4: '客户代表管理',
    5: '产品运营管理',
    6: '采购开发管理',
    7: '订单支持管理',
  }
  function _genUsers() {
    return userKeys.map((key, index) => {
      const obj = {}
      obj[key] = ''
      obj[key + 'Id'] = []
      obj.id = obj[key + 'Id']
      obj.label = indexToUserLabel[index]
      return obj
    })
  }

  const filterForm = {
    year: '',
    month: '',
    business: '',
    businessIdList: [],
    orderCreateTimeList: [],
    company: '',
    productStandardCustomized: '',
    productOperatingIdList: [],
    productOperating: '',
    buyer: '',
    buyerIdList: [],
    startAirSeaFreight: undefined,
    endAirSeaFreight: undefined,
    startInternationalDeliveryCharge: undefined,
    endInternationalDeliveryCharge: undefined,
    startCommissionAmount: undefined,
    endCommissionAmount: undefined,
    startGrossProfitAmount: undefined,
    endGrossProfitAmount: undefined,
  }

  // 需要合并的列
  const isTableColums = [
    '结算类型',
    '结算单号',
    '结算日期',
    '结算专员',
    '结算状态',
    '结算备注',
    '订单编号',
    '订单月份',
    '客户代表',
    '地区',
    '公司名称',
    '操作',
  ]

  const years = Object.freeze(getRangeYears())

  export default {
    name: 'OrderSettlement',
    components: {
      ErpDraggable,
      SettlementConditionSetting,
      SettlementCurrency,
      SettlementField,
      Audit,
      settlementReject,
      settlementRecord,
      SuccessModel
    },

    data() {
      const _freeze = (obj) => Object.freeze(obj)
      return {
        years,
        downloadVisible: false, // 导出成功提示框
        cancelReason: '',
        cancelVisible: false,
        columns,
        statusList: _freeze(statusList),
        options: _freeze(options),
        queryForm: {
          orderNumber: '', //查询单号
          orderIdentity: '', //订单标识
          checkBoxStatus: '',
        },
        areas: ['苏州', '合肥', '义乌'],
        lines: [0, 1],
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        productList: [],
        total: 0,
        tableData: [],
        spanArr: [],
        pos: 0,
        selection: [], //表格选择行
        settlementId: '',
        auditType: 12,
        statusDrawer: false,
        filterForm: {
          year: '',
          month: '',
          business: '',
          businessIdList: [],
          orderCreateTimeList: [],
          company: '',
          productStandardCustomized: '',
          productOperatingIdList: [],
          productOperating: '',
          buyer: '',
          buyerIdList: [],
          startAirSeaFreight: undefined,
          endAirSeaFreight: undefined,
          startInternationalDeliveryCharge: undefined,
          endInternationalDeliveryCharge: undefined,
          startCommissionAmount: undefined,
          endCommissionAmount: undefined,
          startGrossProfitAmount: undefined,
          endGrossProfitAmount: undefined,
        },
        productTypes: Object.freeze([
          {
            label: '标准品',
            code: 0,
          },
          {
            label: '订制品',
            code: 1,
          },
        ]),
        users: _genUsers(),
        fees: [
          {
            label: '空海运费',
            start: 'startAirSeaFreight',
            end: 'endAirSeaFreight',
          },
          {
            label: '国际快递费',
            start: 'startInternationalDeliveryCharge',
            end: 'endInternationalDeliveryCharge',
          },
          {
            label: '手续费',
            start: 'startCommissionAmount',
            end: 'endCommissionAmount',
          },
          {
            label: '毛利额',
            start: 'startGrossProfitAmount',
            end: 'endGrossProfitAmount',
          },
        ],
        months: Object.freeze([
          {
            label: '1月',
            value: '01',
          },
          {
            label: '2月',
            value: '02',
          },
          {
            label: '3月',
            value: '03',
          },
          {
            label: '4月',
            value: '04',
          },
          {
            label: '5月',
            value: '05',
          },
          {
            label: '6月',
            value: '06',
          },
          {
            label: '7月',
            value: '07',
          },
          {
            label: '8月',
            value: '08',
          },
          {
            label: '9月',
            value: '09',
          },
          {
            label: '10月',
            value: '10',
          },
          {
            label: '11月',
            value: '11',
          },
          {
            label: '12月',
            value: '12',
          },
        ]),
        userCustomizeColumnId: '',
        gridData: [], //其他费用明细
        orderRejectVisible: false, //驳回
        modelTitle: '', // 弹窗名称
        modelType: '', // 驳回或结算调整
        rejectSalesOrderId: '', // 驳回id
        rejectSettlementSalesOrderId: '', // 驳回id
        settlementOrder: '', //结算单号
        area: '', //区域
        recordVisible: false,
        recordList: [],
        dialogObj: {},
        tablekey: Math.random(),
        existTodayTaskInfo: {
          exist:false,
          message:''
        },
        existTodayTaskInfoList: [],
        exportConfirmation:false,//导出确认弹出
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
      // 分配结算专员
      showBatchButton() {
        return this.selection.every((item) => item.settlementStatus === '6')
      },
      // 批量结算
      showBatchSettlement() {
        return this.selection.every(
          (item) => item.settlementStatus === '6' && item.settlementType !== 0
        )
      },
    },

    filters: {
      /**
       * 结算状态
       */
      settlementStatus(status) {
        const code2text = {
          4: '审核中',
          5: '已完成',
          1: '录入中',
          2: '审核驳回',
          3: '已撤销',
          6: '待结算',
        }
        return code2text[status]
      },
    },

    activated() {
      this.onButtonSearch()
      // //获取自定义保存
      this.initGetcolumn()
    },

    watch: {
      'queryForm.checkBoxStatus': function (newVal) {
        this.onButtonSearch()
      },
      columns: {
        handler(val) {
          this.tablekey = Math.random()
        },
        deep: true,
      },
    },

    methods: {
      // 已结算excel-是否存在今天的导出任务
     async getExistTodayTask (msg=''){
        const res = await OrderSettleInteractor.existTodayTaskApi();
        if (res?.code === '000000') {
          this.existTodayTaskInfo = res?.data
          if(msg){
            this.exportConfirmation = false;
            this.$message.success('操作成功，请于明日在任务中心查看导出结果!')
          }
        }
      },
      // 获取已结算excel-获取历史任务列表
      async getHistoryTaskList(){
        const res = await OrderSettleInteractor.historyTaskListApi();
        if (res.code === '000000') {
          this.existTodayTaskInfoList = res?.data ?? []
          this.exportConfirmation = true;
        }
      },
      // 已结算excel-创建导出任务
     async exportConfirmationFn(){
      const res = await OrderSettleInteractor.createTaskApi();
      if (res.code === '000000'){
          let msg = res?.desc ?? 'SUCCESS'
          this.getExistTodayTask(msg)
        }
      },
      // 导出处理中excel
      async exportInProgressFn(){
        const res = await OrderSettleInteractor.exprotDoingApi();
        if (res.code === '000000') this.downloadVisible = true
      },
      // 初始化列
      initGetcolumn() {
        CustomCache.getColumns(13, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },
      // 设置列样式
      cellStyle({ row, column, rowIndex, columnIndex }) {
        let arr = [
          'operatingCostRatio',
          'businessCommissionRatio',
          'productOperatingCommissionRatio',
          'buyerCommissionRatio',
          'orderSupportCommissionRatio',
          'businessManagerCommissionRatio',
          'productOperatingManagerCommissionRatio',
          'buyerManagerCommissionRatio',
          'orderSupportManagerCommissionRatio',
        ]
        if (arr.indexOf(column.property) > -1) {
          return 'background-color:#EAF3FF'
        }
      },
      showProDetail({ productId }) {
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId: productId,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
      getProductList({
        parentSku,
        salesOrderId,
        combinationProduct,
        orderProductUniqueId,
      }) {
        if (combinationProduct !== 2) return

        // 添加orderProductUniqueId
        getPurchesForFinance({
          sku: parentSku,
          salesOrderId: salesOrderId,
          orderProductUniqueId,
        }).then((res) => {
          if (res.code === '000000') {
            this.productList = [res.data]
          }
        })
      },
      statusTypeClick({
        settlementId,
        settlementSalesOrderId,
        settlementStatus,
        settlementAdjustmentStatus,
      }) {
        if (['1', '2', '3'].includes(settlementAdjustmentStatus)) {
          this.auditType = 12
          this.settlementId = settlementId
        } else {
          this.auditType = 14
          this.settlementId = settlementSalesOrderId
        }
        if (['2', '4', '5'].includes(settlementStatus)) {
          this.statusDrawer = true
        }
      },

      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },
      onCancel() {
        this.cancelVisible = false
        this.cancelReason = ''
      },
      cancelSure() {
        if (!this.cancelReason) {
          return this.$message.warning('请输入撤销原因')
        }
        const { auditObjectId, auditType = 12, cancelReason } = this
        const { tenantId, userId, userName } = this.userInfo
        cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        }).then((res) => {
          if (res.code === '000000') {
            this.$message.success('撤销成功')
            this.cancelVisible = false
            this.onButtonSearch()
          }
        })
      },
      searchInput() { },
      /**
       * 重置到首页
       */
      _resetPageNo() {
        this.page.pageNo = 1
      },
      _getOrderCreateTimeList() {
        let orderCreateTimeList = []
        const { year, month } = this.filterForm

        if (year && month && month.length !== 0) {
          orderCreateTimeList = month.map((item) => {
            return `${year}-${item}`
          })
        }

        return orderCreateTimeList
      },
      // 获取查询请求参数
      _getSearchRequestParams() {
        return {
          ...this.filterForm,
          ...this.queryForm,
          ...this.page,
          orderCreateTimeList: this._getOrderCreateTimeList(),
        }
      },
      // 高级筛选确定
      onDrawerConfirm() {
        this._resetPageNo()
        const requestParams = this._getSearchRequestParams()
        getListData(requestParams).then((res) => {
          if (res && res.code === '000000') {
            const { total, data } = res.data
            this.tableData = data.map((item) => {
              item.tagType = this._setTagTypeByStatus(item.settlementStatus)
              item.settlementTypeDesc =
                item.settlementType == 1 ? '系统结算' : '手动结算'
              return item
            })
            // 在调用接口后重置表格组件重置keys
            this.$refs.ErpTable.resetKeys()
            this.getSpanArr(this.tableData)
            this.total = total
          }
        })
      },
      // 高级筛选重置
      onDrawerReset() {
        this.filterForm = this.$options.data().filterForm
        this.isRest = true
        this.onDrawerConfirm()
      },
      // 高级筛选取消
      onDrawerCancel() {
        if (this.isRest) {
          this.isRest = false
          return
        }
        this.filterForm = this.copyForm
        this.businessList = this.copyBusinessList
        this.clerkList = this.copyClerkList
        this.creatorList = this.copyCreatorList
      },
      // 高级筛选open
      onDrawerOpen() {
        const _ = (obj) => JSON.parse(JSON.stringify(obj))
        this.copyForm = _(this.filterForm)
      },
      /**
       * 点击查询按钮
       */
      onButtonSearch() {
        this._resetPageNo()
        this._getList()
      },

      /**
       * 根据状态设置tag类型
       */
      _setTagTypeByStatus(status) {
        const status2Type = {
          4: '',
          5: 'success',
          1: 'info',
          2: 'danger',
          3: 'info',
          6: 'warning',
        }
        return status2Type[status]
      },
      /**
       * 请求分页数据
       */
      _getList() {
        const requestData = this._getSearchRequestParams()
        getListData(requestData).then((res) => {
          if (res && res.code === '000000') {
            const { total, data } = res.data
            this.tableData = data.map((item) => {
              item.tagType = this._setTagTypeByStatus(item.settlementStatus)
              item.settlementTypeDesc =
                item.settlementType == 1 ? '系统结算' : '手动结算'

              return item
            })
            // 在调用接口后重置表格组件重置keys
            this.$refs.ErpTable.resetKeys()
            this.getSpanArr(this.tableData)
            this.total = total
          }
        })
      },
      showDrawer() {
        this.$refs.filterDrawer.showDrawer()
      },

      _beforeBatch() {
        if (this.selection.length === 0) {
          this.$message.warning('请选择')
          return false
        }
        return true
      },

      _toOrderSettlementDetail(query) {
        let { settlementSalesOrderIds, isEdit } = query
        if (!isEdit) {
          settlementSalesOrderIds = settlementSalesOrderIds.split(',')
          checkOrderRelCostStatus(settlementSalesOrderIds).then((res) => {
            if (res?.code === '000000') {
              this.$router.push({
                path: '/finance-center/order-settlement-detail',
                query,
              })
            }
          })
        } else {
          this.$router.push({
            path: '/finance-center/order-settlement-detail',
            query,
          })
        }
      },

      /**
       * 批量结算
       */
      batchSettlement() {
        let idArr = Array.from(
          new Set(this.selection.map((item) => item.salesOrder))
        )
        let typeArr = Array.from(
          new Set(this.selection.map((item) => item.settlementType))
        )
        if (typeArr.length > 1) {
          this.$message.warning('手动结算只能单条结算!')
          return
        }
        // type 1 系统结算，0 手动结算
        if (typeArr.length == 1 && typeArr[0] == 0 && idArr.length > 1) {
          this.$message.warning('手动结算只能单条结算!')
          return
        }

        if (!this._beforeBatch()) {
          return
        }
        this.onSettlement(
          this.selection.map((i) => i.settlementSalesOrderId).join(',')
        )
      },
      // 批量编辑
      batchEdit() {
        if (!this._beforeBatch()) {
          return
        }

        let arr = this.selection.map((i) => i.settlementOrder)
        this.onSettlement(
          this.selection.map((i) => i.settlementId).join(','),
          Array.from(new Set(arr)).join(','),
          true
        )
      },

      /**
       * 单条结算
       */
      onSettlement(settlementSalesOrderIds, settlementOrder, isEdit) {
        let query = {
          settlementSalesOrderIds,
        }
        query = this._assignQuery(query, isEdit, settlementOrder)
        this._toOrderSettlementDetail(query)
      },
      _assignQuery(query, isEdit, settlementOrder) {
        isEdit && (query.isEdit = isEdit)
        settlementOrder && (query.settlementOrder = settlementOrder)
        return query
      },
      /**
       * 批量分配结算专员
       */
      assignSettlementSpecialist() {
        if (!this._beforeBatch()) {
          return
        }
        this.setSettlementUsers(this.selection, true)
      },

      /**
       * 获取roleId
       */
      _getRoleId(rowList, isBatch) {
        if (!isBatch) {
          const singleRow = rowList[0]
          const { settlementAttachId, settlementAttach } = singleRow
          if (settlementAttachId) {
            return [
              {
                id: settlementAttachId,
                name: settlementAttach,
              },
            ]
          }
        }
        return ''
      },
      /**
       * 人员选择
       */
      getUser(type) {
        event.target.blur()
        const nameField = type.split('I')[0]
        const names =
          this.filterForm[nameField] && this.filterForm[nameField].split(',')
        let roleId = this.filterForm[type]
        if (roleId && roleId.length > 0) {
          roleId = roleId.map((item, index) => {
            return {
              name: names[index],
              id: item,
            }
          })
        } else {
          roleId = ''
        }
        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId,
            singleSelect: false,
            canSelectZeroUser: true,
          })
          .then((users) => {
            if (Array.isArray(users) && users.length > 0) {
              this.filterForm[nameField] = users
                .map((item) => item.name)
                .join(',')
              this.filterForm[type] = users.map((item) => item.id)
            } else {
              this.filterForm[nameField] = ''
              this.filterForm[type] = []
            }
          })
      },
      /**
       * 设置结算专员
       */
      setSettlementUsers(rowList, isBatch) {
        let row0 = rowList[0]
        if (row0.settlementStatus !== '6') {
          return this.$message.warning('仅未结算状态可设置/切换结算专员')
        }
        const roleId = this._getRoleId(rowList, isBatch)
        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId,
            singleSelect: true,
          })
          .then((users) => {
            if (Array.isArray(users) && users.length > 0) {
              const { id, name, englishName } = users[0]
              const settlementAttachDTOS = rowList.map((row) => {
                const user = {}
                user.settlementAttach = `${name} ${englishName}`
                user.settlementAttachId = id
                user.settlementSalesOrderId = row.settlementSalesOrderId
                return user
              })
              setSettlemenSpecialist(settlementAttachDTOS).then((res) => {
                if (res?.code === '000000') {
                  this.$message.success('操作成功')
                  rowList.forEach((row) => {
                    row.settlementAttach = `${name} ${englishName}`
                    row.settlementAttachId = id
                  })
                }
              })
            } else {
            }
          })
      },
      /**
       * 设置结算条件
       */
      setSettlementConditions() {
        this.showSettlementConditionSetting()
      },

      /**
       * 撤销
       */
      revert({
        settlementId,
        settlementAdjustmentStatus,
        settlementSalesOrderId,
      }) {
        let orderId

        if (['1', '2', '3'].includes(settlementAdjustmentStatus)) {
          this.auditType = 12
          orderId = settlementId
        } else {
          this.auditType = 14
          orderId = settlementSalesOrderId
        }

        getAuditObject({
          orderId,
          auditType: this.auditType,
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            // 获取提交审核（撤销所用）auditObjectId
            this.auditObjectId = res.data?.auditObjectId
            this.cancelVisible = true
          }
        })
      },

      /**
       * 获取分页数据
       */
      getList(val) {
        this._getList()
      },
      /**
       * 表格勾选
       */
      setSelectRows(val) {
        this.selection = val
      },

      /**
       * 显示结算条件设置弹窗
       */
      showSettlementConditionSetting() {
        this.$refs.settlementConditionSetting.show()
      },
      /**
       * 合并单元格getSpanArr
       */
      getSpanArr(list) {
        this._resetTableCombineData()
        for (var i = 0; i < list.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            if (list[i].salesOrder === list[i - 1].salesOrder) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      /**
       * 合并单元格objectSpanMethod
       */
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0 || isTableColums.includes(column.label)) {
          // 1.6.0 版本修改
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      /**
       * 重置合并
       */
      _resetTableCombineData() {
        this.spanArr = []
        this.pos = 0
      },

      /**
       * 结算详情
       */
      showSettlementDetails({ settlementId, settlementStatus }) {
        this.$router.push({
          path: '/finance-center/settlement-detail',
          query: {
            settlementId,
            isDetail: true,
          },
        })
      },

      /**
       * 销售订单详情
       * 不做权限判断
       */
      showOrderDetail({ salesOrder }) {
        navigateTo(this, {
          pageType: PAGE_TYPES.salesOrderDetail,
          orderNumber: salesOrder,
          roleJudge: false,
        })
      },
      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 13,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            // 操作成功
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
      // 驳回
      rejectClick(row, type) {
        this.dialogObj = row
        this.rejectSalesOrderId = row.salesOrderId
        this.rejectSettlementSalesOrderId = row.settlementSalesOrderId
        this.settlementOrder = row.settlementOrder
        this.orderRejectVisible = true
        this.getRecordList = row.businessArea
        this.modelType = type
        this.modelTitle =
          this.modelType === 'reject'
            ? '结算驳回'
            : this.modelType === 'adjust'
              ? '结算调整'
              : ''
      },
      // 其他费用明细
      async getOtherDetail(row) {
        this.gridData = await OrderSettleInteractor.getOtherChargesDetail(
          row.settlementProductId
        )
      },

      // 查看记录
      checkRecode(row) {
        this.recordVisible = true
        this.getRecordList(row)
      },
      // 查询结算调整记录
      async getRecordList(row) {
        let res = await OrderSettleInteractor.getListAdjustmentRecord(
          row.salesOrder
        )
        this.recordList = res
      },
      // 下载
      downloadDocu(row) {
        if (row.enclosure) {
          window.open(JSON.parse(row.enclosure)[0].url.split('?')[0], '_self')
        }
      },
    },
    created() {
      // 已结算excel-是否存在今天的导出任务
      this.getExistTodayTask()
    }
  }
</script>

<style scoped lang="scss">
  .el-form {
    padding: 0 10px 0 0;

    .el-select {
      width: 100%;
    }

    .item-fee {
      display: flex;

      .item {
        flex: 1;
      }
    }
  }

  .custom-container {
    .el-divider--horizontal {
      margin-bottom: 15px;
    }

    .product-info {
      display: flex;
      padding: 10px;
      text-align: left;

      .pro-item {
        width: 100%;
        white-space: pre-wrap;
        margin-bottom: 5px;
      }

      .left {
        flex: 1;
        // cursor: pointer;
      }

      p {
        display: flex;
        flex-direction: column;

        .el-tag {
          margin-bottom: 5px;
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .whi {
    white-space: pre-wrap;
    text-align: left;
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .el-icon-arrow-down {
    font-size: 20px;
    font-weight: bolder;
  }
  .export-content{
    p{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      i{
        font-size: 20px;
        color: rgb(236, 142, 18);
        margin-right: 4px;
      }
    }
    div{
      display: flex;
      align-items: center;
      padding-left: 25px;
      span{
        margin-right: 8px;
      }
    }
  }
</style>

<style>
  .el-popover {
    max-height: 500px;
    overflow: auto;
  }
</style>
