<template>
  <div class="custom-container">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="22" class="page-header-left">
        <el-row
          type="flex"
          class="align_center flex_row_y flex_wrap top-search"
        >
          <div>
            <el-form
              ref="form"
              :inline="true"
              :model="queryForm"
              label-width="49px"
              @submit.native.prevent
            >
              <el-form-item>
                <el-input
                  v-model.trim="queryForm.costAdjustNumber"
                  placeholder="调整单号"
                  maxlength="50"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  :disabled="productFlag != 1"
                  v-model.trim="queryForm.lotNo"
                  placeholder="入库批次"
                  maxlength="50"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  :disabled="productFlag != 1"
                  v-model.trim="queryForm.product"
                  placeholder="产品编码/产品名称"
                  maxlength="50"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="queryForm.costAdjustStatus"
                  placeholder="状态"
                  clearable
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="queryForm.costWarehouseType"
                  placeholder="调整类型"
                  clearable
                >
                  <el-option
                    v-for="item in costAdjustStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="queryForm.costAdjustType"
                  placeholder="单据来源"
                  clearable
                >
                  <el-option
                    v-for="item in costAdjustTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <el-button @click="init" type="primary" plain>搜索</el-button>
          <el-button @click="reset">重置</el-button>
          <el-button @click="highSearch">高级筛选</el-button>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="mt15">
      <el-col :span="12">
        <el-button
          @click="add"
          icon="el-icon-plus"
          type="primary"
          v-show="hasAddRight"
        >
          新增
        </el-button>
        <el-button
          @click="del(selectRows)"
          type="danger"
          plain
          v-show="isDelete && operateVisible && hasDelRight"
        >
          删除
        </el-button>
        &nbsp;
      </el-col>
      <el-col :span="12" class="top-sty">
        <el-checkbox
          v-model="productFlag"
          :true-label="1"
          :false-label="0"
          @change="changeProduct"
          class="ml10"
        >
          显示调整产品
        </el-checkbox>
        <el-row type="flex" justify="end" v-show="productFlag === 1">
          <!-- 自定义展示列 -->
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="item + index"
                v-model="item.isShow"
                :disabled="item.checkable === false"
                :label="item.label"
              >
                {{ item.label }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button
                type="primary"
                class="ml10"
                size="mini"
                plain
                @click="operateSave(true)"
              >
                保存
              </el-button>
            </div>
            <template #reference>
              <el-tooltip
                effect="dark"
                content="列表可拖动排序"
                placement="top-start"
              >
                <el-button plain type="primary">自定义显示</el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>

    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :total="total"
      :extraHeight="45"
      :key="customTableKey"
      class="table-fixed mt15"
      @query="getTableData"
      @selection-change="setSelectRows"
      :default-sort="defaultSort"
      @sort-change="sortChange"
    >
      <el-table-column
        align="center"
        type="selection"
        :selectable="() => operateVisible"
        width="55"
      />
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
        :class-name="item.prop"
        :sortable="item.sortable ? 'custom' : false"
      >
        <template #default="{ row }">
          <div v-if="item.prop === 'costAdjustNumber'">
            <span class="page-link" @click="detail(row.costAdjustId)">
              {{ row.costAdjustNumber }}
            </span>
          </div>
          <!-- 调整金额 -->
          <span
            v-else-if="item.prop === 'adjustPrice'"
            :class="{ red: row.adjustPrice < 0 }"
          >
            {{ row.adjustPrice && row.adjustPrice.toFixed(3) }}
          </span>
          <!-- 单价 -->
          <span v-else-if="item.prop === 'priceExcludingTax'">
            {{ row.priceExcludingTax ? row.priceExcludingTax.toFixed(3) : 0 }}
          </span>
          <!-- 总成本 -->
          <span v-else-if="item.prop === 'totalPriceExcluding'">
            {{
              row.totalPriceExcluding ? row.totalPriceExcluding.toFixed(3) : 0
            }}
          </span>
          <!-- 调整后单价 -->
          <span v-else-if="item.prop === 'priceExcludingTaxed'">
            {{
              row.priceExcludingTaxed ? row.priceExcludingTaxed.toFixed(3) : 0
            }}
          </span>
          <!-- 调整后总成本 -->
          <span v-else-if="item.prop === 'totalPriceExcluded'">
            {{ row.totalPriceExcluded ? row.totalPriceExcluded.toFixed(3) : 0 }}
          </span>
          <!-- 入库类型 -->
          <span v-else-if="item.prop === 'warehouseType'">
            {{ row.warehouseType | warehouseTypeName }}
          </span>
          <!-- 调整类型 -->
          <span v-else-if="item.prop === 'costWarehouseType'">
            {{ row.costWarehouseType | costWarehouseTypeName }}
          </span>
          <!-- 单据来源 -->
          <span v-else-if="item.prop === 'costAdjustType'">
            {{ row.costAdjustType | costAdjustTypeName }}
          </span>
          <!-- 状态 -->
          <span v-else-if="item.prop === 'costAdjustStatus'">
            <el-tooltip
              class="item"
              effect="light"
              :content="row.auditRemark"
              placement="top-start"
              :disabled="![3, 4].includes(row.costAdjustStatus)"
            >
              <el-tag
                :type="statusTags(row.costAdjustStatus)"
                @click="showReviewProgress(row)"
                style="cursor: pointer"
              >
                {{ row.costAdjustStatus | costAdjustStatusName }}
              </el-tag>
            </el-tooltip>
          </span>
          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        width="120"
        fixed="right"
        v-if="operateVisible"
      >
        <template slot-scope="scope">
          <!-- 已完成状态操作显示-- -->
          <span v-show="scope.row.costAdjustStatus === 2">--</span>
          <el-tooltip
            class="item"
            effect="dark"
            content="编辑"
            placement="top-start"
            v-show="[3, 4].includes(scope.row.costAdjustStatus) && hasEditRight"
          >
            <el-button
              type="primary"
              plain
              icon="el-icon-edit"
              circle
              size="mini"
              @click="edit(scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="撤销"
            placement="top-start"
            v-show="[1].includes(scope.row.costAdjustStatus)"
          >
            <el-button
              type="primary"
              plain
              icon="iconfont icon-quxiao"
              circle
              size="mini"
              @click="withdraw(scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="删除"
            placement="top-start"
            v-show="[4].includes(scope.row.costAdjustStatus) && hasDelRight"
          >
            <el-button
              type="primary"
              plain
              icon="el-icon-delete"
              circle
              size="mini"
              @click="del(scope.row)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </ErpTable>

    <!-- 高级筛选Drawer -->
    <el-drawer
      title="高级筛选"
      :visible.sync="drawer"
      :before-close="highSearchClose"
    >
      <costAdjustmentSearch
        :productFlag="productFlag"
        @cancelClick="highSearchClose"
        @searchClick="searchClick"
      />
    </el-drawer>

    <!-- 撤销 -->
    <el-dialog
      title="撤销原因"
      :visible="cancelVisible"
      append-to-body
      width="500px"
      @close="onCancel"
      modal-append-to-body
    >
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input
        type="textarea"
        :rows="3"
        placeholder="请输入"
        maxlength="500"
        show-word-limit
        v-model="cancelReason"
      ></el-input>
      <div class="footer mt30 t_a_c">
        <el-button type="primary" @click="cancelSure">确认</el-button>
        <el-button @click="onCancel">取消</el-button>
      </div>
    </el-dialog>
    <!-- 审核进度Drawer -->
    <el-drawer
      title="审核进度"
      :visible.sync="statusDrawer"
      :before-="statusClose"
    >
      <Audit
        ref="Audit"
        @cancelClick="statusClose"
        :auditObjectId="currentAuditObjectId"
      />
    </el-drawer>
  </div>
</template>

<script>
  import costAdjustInteractor from '@/core/interactors/storage/cost-adjustment'
  import ErpDraggable from 'vuedraggable'
  import costAdjustmentSearch from '../cost-adjustment/components/cost-adjustment-search.vue'
  import CustomCache from '@/utils/custom-cache'
  import costAdjustDicts from './dict/index'
  import { mapGetters } from 'vuex'
  import { hasRight } from '@/utils/permissionBtn'
  import { InboundSheet } from '@/core'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import customTableMixin from '@/utils/custom-table-mixin'
  export default {
    name: 'cost-adjustment',
    mixins: [customTableMixin],
    components: {
      ErpDraggable,
      costAdjustmentSearch,
      Audit,
    },
    data() {
      return {
        productFlag: 1, // 显示调整产品
        queryForm: {
          costAdjustNumber: '', // 调整单号
          lotNo: '', // 入库批次
          product: '', // 产品名称或产品编码
          costAdjustStatus: '', // 成本调整状态
          costWarehouseType: '', // 成本调整类型
          costAdjustType: '', // 新增成本单类型
          sortName: '', // 升序降序名称
          sortType: '', // 升序降序（ASC,DESC）
        },
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        userCustomizeColumnId: '', // 自义定保存id
        tableData: [],
        selectRows: [], //table勾选
        columns: [],
        statusList: costAdjustDicts.statusList, // 状态下拉项
        costAdjustStatusList: costAdjustDicts.costAdjustStatusList, // 调整类型下拉项
        costAdjustTypeList: costAdjustDicts.costAdjustTypeList, // 单据来源
        columnsComplex: costAdjustDicts.columnsComplex, // 显示调整产品表头
        columnsSimple: costAdjustDicts.columnsSimple, // 不显示调整产品表头
        cancelReason: '', // 撤销原因
        auditObjectId: '', // 审核id
        cancelVisible: false, // 撤销
        drawer: false, // 高级筛选抽屉
        isDelete: true, // 是否显示删除
        operateVisible: false, // 操作栏是否显示
        hasAddRight: false, // 新增权限
        hasEditRight: false, // 编辑权限
        hasDelRight: false, // 删除权限
        statusDrawer: false,
        currentAuditObjectId: '',
        defaultSort: {}, //排序,
      }
    },
    computed: {
      ...mapGetters({
        permissions: 'user/permissions',
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        this.columns.forEach((item) => {
          if (item.isSort) {
            this.defaultSort = { prop: item.prop, order: item.sortType }
          }
        })
        this.customTableKey = Math.random()
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.getColumsData()
        }
      },
    },
    filters: {
      // 入库类型
      warehouseTypeName(n) {
        return costAdjustDicts.getWarehouseTypeName(n)
      },
      // 调整类型
      costWarehouseTypeName(n) {
        return costAdjustDicts.getCostWarehouseTypeName(n)
      },
      // 单据来源
      costAdjustTypeName(n) {
        return costAdjustDicts.getCostAdjustTypeName(n)
      },
      // 状态
      costAdjustStatusName(n) {
        return costAdjustDicts.getCostAdjustStatusName(n)
      },
    },
    created() {
      this.hasAddRight = hasRight('COSTADJUST-ADD')
      this.hasEditRight = hasRight('COSTADJUST-EDIT')
      this.hasDelRight = hasRight('COSTADJUST-DEL')

      this.columns = this.columnsComplex
      this.getColumsData()
    },
    methods: {
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(14, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.columns = res.columns
              // 处理排序搜索条件
              this.columns.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'createTime') {
                    this.queryForm.sortName = 'create_time'
                  }
                  this.queryForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.queryForm.sortName = null)
                }
              })
              this.getTableData()
            }
          })
          .catch(() => {
            this.getTableData()
          })
      },
      // 排序
      sortChange(column) {
        if (column.order) {
          if (column.prop === 'createTime') {
            this.queryForm.sortName = 'create_time'
          } else {
            this.queryForm.sortName = ''
          }
        } else {
          this.queryForm.sortName = ''
        }
        this.queryForm.sortType = column.order
        this.saveSort(column)
        this.getTableData()
      },
      // 保存排序
      saveSort(column) {
        this.columns.forEach((item) => {
          if (item.prop === column.prop) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave()
      },

      // 保存自定义显示列
      operateSave(flag) {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 14,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000' && flag) {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 初始化加载
      init() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.getTableData()
      },

      // 获取列表
      async getTableData() {
        const res = await costAdjustInteractor.getCostAdjustList(
          this.productFlag,
          this.queryForm,
          this.page
        )
        setTimeout(() => {
          this.customTableKey = Math.random()
        }, 300)

        if (res && res.code === '000000') {
          this.tableData = res?.data?.data || []
          this.total = res?.data?.total || 0
          this.selectRows = []
        }
      },

      // 显示调整产品
      changeProduct(val) {
        if (val === 1) {
          this.columns = this.columnsComplex
          this.productFlag = 1
          this.operateVisible = false
          this.reset()
        } else {
          this.columns = this.columnsSimple
          this.productFlag = 0
          this.operateVisible = true
          this.reset()
        }
      },

      // 重置
      reset() {
        this.queryForm = this.$options.data().queryForm
        this.init()
      },

      // 新增
      add() {
        this.$router.push('/stock-center/cost-adjustment/add')
      },

      // 编辑
      edit(row) {
        this.$router.push(
          `/stock-center/cost-adjustment/add?id=${row.costAdjustId}`
        )
      },

      // 详情
      detail(id) {
        this.$router.push(`/stock-center/cost-adjustment/detail?id=${id}`)
      },

      // 删除
      del(row) {
        let ids = []
        if (Array.isArray(row)) {
          if (row.length === 0) {
            this.$message.warning('请选择一条数据')
            return false
          } else {
            row.forEach((item) => ids.push(item.costAdjustId))
          }
        } else {
          ids = [row.costAdjustId]
        }
        this.$confirm(`是否确认删除?`, '提示', { type: 'warning' })
          .then(async () => {
            const res = await costAdjustInteractor.getDeleteCostAdjustById(ids)
            if (res && res.code === '000000') {
              this.$message.success('删除成功')
              this.getTableData()
            }
          })
          .catch(() => {})
      },

      // 表格勾选
      setSelectRows(rows) {
        this.selectRows = rows
        this.selectRows.every((item) => item.costAdjustStatus === 4)
          ? (this.isDelete = true)
          : (this.isDelete = false)
      },

      // 高级筛选
      highSearch() {
        this.drawer = true
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },

      // 高级筛选触发
      searchClick(val) {
        const form = {
          ...this.queryForm,
          ...val,
        }
        this.queryForm = form
        this.init()
        this.highSearchClose()
      },

      // 状态Tags
      statusTags(n) {
        return costAdjustDicts.getStatusTags(n)
      },

      // 撤销
      async withdraw(row) {
        let res = await InboundSheet.getAuditObject({
          costAdjustId: row.costAdjustId,
          auditType: 15,
          tenantId: this.userInfo.tenantId,
        })
        if (res && res.code === '000000') {
          this.auditObjectId = res.data.auditObjectId
          this.cancelVisible = true
        }
      },

      //确定撤销
      async cancelSure() {
        if (!this.cancelReason) {
          return this.$message.warning('请输入撤销原因')
        }
        const { auditObjectId, auditType = 15, cancelReason } = this
        const { tenantId, userId, userName } = this.userInfo
        let res = await InboundSheet.cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        })
        if (res.code === '000000') {
          this.$message.success('撤销成功')
          this.cancelVisible = false
          this.getTableData()
        }
      },

      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },
      async showReviewProgress({ costAdjustStatus, costAdjustId }) {
        this.progressStatus = costAdjustStatus
        let map = [
          1, // '审核中',
        ]
        if (!map.includes(costAdjustStatus)) {
          return
        }

        const res = await InboundSheet.getAuditObject({
          orderId: costAdjustId,
          auditType: 15,
          tenantId: this.userInfo.tenantId,
        })

        if (res && res.code === '000000') {
          // 获取提交审核（撤销所用）auditObjectId
          if (!res.data || !res.data.auditObjectId) {
            this.$message.warning('未获取到审核对象')
          } else {
            this.currentAuditObjectId = res.data.auditObjectId
            this.statusDrawer = true
          }
        }
      },
      statusClose() {
        this.statusDrawer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .top-sty {
    display: flex;
    justify-content: flex-end;
    .el-checkbox {
      margin: 5px 10px;
    }
  }
  ::v-deep {
    .el-table__row .el-button {
      margin: 0 5px;
    }
    .costAdjustNumber .cell,
    .productCn .cell,
    .lotNo .cell {
      width: auto !important;
    }
    .page-header {
      .el-input {
        width: 160px;
      }
    }
    .iconfont {
      font-size: 14px;
    }
  }
</style>
