<template>
  <div class="subject">
    <el-dialog
      title="绑定科目"
      :visible.sync="dialogFormVisible"
      width="350px"
      @close="close"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item prop="sujectId">
          <el-select
            v-model="form.sujectId"
            placeholder="请选择"
            style="width: 100%"
            @change="subjectChange"
          >
            <el-option
              v-for="item in subjectOptions"
              :key="item.financeSetupId"
              :label="item.financeName"
              :value="item.financeSetupId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { updateCourseTitle } from '@/api/finance/finance-cost'
export default {
  name: 'subject',
  data() {
    return {
      amendRow: '',
      form: {
        suject: '', //科目
        sujectId: '', //科目id
        costId: '',
      },
      dialogFormVisible: false,
      // 科目
      subjectOptions: [],
      rules: {
        sujectId: [{ required: true, message: '请选择', trigger: 'change' }],
      },
    }
  },
  created() {
    this.getSubject()
  },
  methods: {
    async getSubject() {
      let response = await this.pubApi.financeSelect({ financeType: 4 })
      this.subjectOptions = response.data
    },
    // 科目的选择
    subjectChange(val) {
      this.subjectOptions.forEach((item) => {
        if (item.financeSetupId == val) {
          this.form.suject = item.financeName
        }
      })
    },
    showAdd() {
      this.dialogFormVisible = true
    },
    close() {
      this.dialogFormVisible = false
      this.$refs.form.resetFields()
    },
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let response = await updateCourseTitle({
            ...this.form,
            costId: this.amendRow,
          })
          if (response.code == '000000') {
            this.$message.success('新增成功')
            this.close()
            this.$emit('getList')
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.subject {
  ::v-deep {
    .el-dialog__body {
      padding-top: 10px;
    }
    .el-dialog__header {
      > .el-dialog__title {
        font-size: 14px;
      }
    }
    .el-dialog__footer {
      padding-top: 0;
      text-align: center;
    }
  }
}
</style>
