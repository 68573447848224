<!--
  功能：人员提成汇总
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年04月08日 10:25:38
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="sumarry-wrapper">
    <div>
      <el-button @click="download">导 出</el-button>
    </div>
    <!-- 表格 -->
    <el-table :data="summaryList" border stripe class="mt15">
      <el-table-column align="center" label="#">
        <template slot-scope="scope">
          {{ scope.$index + 1 !== summaryList.length ? scope.$index + 1 : '' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="人员姓名" prop="userName">
        <template slot-scope="scope">
          {{ scope.row.userName ? scope.row.userName : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="地区" prop="">
        <template slot-scope="scope">
          {{ scope.row.area ? scope.row.area : '-' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        label="客户代表提成(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <Field
            :value="scope.row.businessCommission"
            :type="1"
            @showDetail="onShowDetail"
            :userId="scope.row.userId"
            :isLink="scope.row.isLink === false ? false : true"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        label="产品运营提成(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <Field
            :value="scope.row.productOperatingCommission"
            :type="2"
            :userId="scope.row.userId"
            @showDetail="onShowDetail"
            :isLink="scope.row.isLink === false ? false : true"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        label="采购开发提成(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <Field
            :value="scope.row.buyerCommission"
            :type="3"
            @showDetail="onShowDetail"
            :userId="scope.row.userId"
            :isLink="scope.row.isLink === false ? false : true"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        label="订单支持提成(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <Field
            :value="scope.row.orderSupportCommission"
            :type="4"
            @showDetail="onShowDetail"
            :userId="scope.row.userId"
            :isLink="scope.row.isLink === false ? false : true"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        label="客户代表管理提成(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <Field
            :value="scope.row.businessManagerCommission"
            :type="5"
            @showDetail="onShowDetail"
            :userId="scope.row.userId"
            :isLink="scope.row.isLink === false ? false : true"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        label="产品运营管理提成(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <Field
            :value="scope.row.productOperatingManagerCommission"
            :type="6"
            @showDetail="onShowDetail"
            :userId="scope.row.userId"
            :isLink="scope.row.isLink === false ? false : true"
          />
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        width="160"
        label="采购员管理提成(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <Field
            :value="scope.row.buyerManagerCommission"
            :type="7"
            @showDetail="onShowDetail"
            :userId="scope.row.userId"
            :isLink="scope.row.isLink === false ? false : true"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        label="订单支持管理提成(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <Field
            :value="scope.row.orderSupportManagerCommission"
            :type="8"
            @showDetail="onShowDetail"
            :userId="scope.row.userId"
            :isLink="scope.row.isLink === false ? false : true"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        label="总提成金额(￥)"
        prop=""
      >
        <template slot-scope="scope">
          <!-- type默认传一个不存在的type -->
          <Field
            :value="scope.row.totalCommission"
            @showDetail="onShowDetail"
            :userId="scope.row.userId"
            :isLink="false"
          />
        </template>
      </el-table-column>
    </el-table>
    <!-- 详情 -->
    <Detail ref="detail" />
  </div>
</template>

<script>
import Field from './personnel-commission-summary-field'
import Detail from './personnel-commission-details'
import { listCommissionDetailVO } from '@/api/order-settlement'
export default {
  name: 'PersonnelCommissionSummary',
  props: {
    summaryList: {
      type: Array,
      default: () => [],
    },

    settlementOrder: {
      type: String,
      default: '',
    },

    settlementId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      list: [],
      type: 1,
    }
  },

  components: {
    Field,
    Detail,
  },

  methods: {
    download() {
      this.$emit('download')
    },

    onShowDetail(type, userId, isLink) {
      if (!isLink) return
      listCommissionDetailVO({
        settlementId: this.settlementId,
        type,
        userId,
      }).then((res) => {
        if (res?.code === '000000') {
          this.$refs.detail.showAddAndEdit(res.data, type)
        }
      })
    },
  },
}
</script> 

<style scoped>
</style>
