<template>
  <el-dialog
  :visible.sync="dialogVisible"
  :close-on-click-modal="false"
  :close-on-press-escape="false"
  :show-close="false"
  width="425px"
  custom-class="dialog-box"
 >
  <div  slot="title" class="dialog-title">
    <i :class="successFlag ? 'el-icon-success':'el-icon-error'" :style="{color:successFlag ?'#52C41A':'#F5222D'}"></i>{{ successFlag ? $t('productDetail.inventoryUpdatedSuccessfully'):$t('productDetail.inventoryUpdateFailed') }}
  </div>
  <span>
    {{ successFlag ? desc :`${$t('productDetail.causeOfFailure')}：${desc}` }}
  </span>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="confirmFn">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>
  export default {
    props:{
      dialogBol:{
        type:Boolean
      },
      // 是否成功
      successFlag:{
        type:Boolean,
        default:false
      },
      // 提示语
      desc:{
        type:String,
        default:''
      }
    },
    computed:{
      dialogVisible:{
        get(){
          return this.dialogBol
        },
        set(val){
          this.$emit('update:dialogBol',val)
        }
      }
    },
    data() {
      return {
      
      };
    },
    methods: {
      confirmFn(){
        this.$emit('confirmFn')
      }
    }
  };
</script>
<style lang="scss" scoped>
.dialog-box{
  .dialog-title{
    font-size: 14px;
    color: rgba(0,0,0,.8);
    display: flex;
    align-items: center;
    i{
      font-size: 24px;
      margin-right: 8px;
    }

  }
}

</style>