var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"pro-container pro-base-msg",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":2}},[_c('h6',{staticClass:"left-h6"},[_vm._v("装箱信息")])]),_c('el-col',{staticClass:"right-content",attrs:{"span":22}},[_c('el-form',{ref:"form",staticClass:"mt20",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"120px"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{attrs:{"label":"装箱数量","prop":"numberPerBox"}},[_c('el-input',{staticStyle:{"width":"200px"},attrs:{"type":"number","placeholder":_vm.$t('placeholder.plsInput')},model:{value:(_vm.form.numberPerBox),callback:function ($$v) {_vm.$set(_vm.form, "numberPerBox", $$v)},expression:"form.numberPerBox"}})],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{attrs:{"label":"毛重","prop":"boxWeightKgs"}},[_c('el-input',{staticStyle:{"width":"200px"},attrs:{"type":"number","placeholder":_vm.$t('placeholder.plsInput')},model:{value:(_vm.form.boxWeightKgs),callback:function ($$v) {_vm.$set(_vm.form, "boxWeightKgs", $$v)},expression:"form.boxWeightKgs"}})],1)],1)],1),_c('el-row',{staticClass:"mt30"},[_c('el-form-item',[_c('template',{slot:"label"},[_c('i',{staticStyle:{"color":"#ff4d4f"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('productDetail.PackingSize'))+" ")]),_c('el-row',{attrs:{"gutter":10,"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"prop":"boxLengthCm","rules":[
                  {
                    required: true,
                    message: _vm.$t('rules.M2015'),
                    trigger: 'blur',
                  },
                  {
                    pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                    message: _vm.$t('rules.M2014'),
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"type":"number","placeholder":_vm.$t('placeholder.M2008')},on:{"input":function($event){return _vm.boxSize('Length')}},model:{value:(_vm.form.boxLengthCm),callback:function ($$v) {_vm.$set(_vm.form, "boxLengthCm", $$v)},expression:"form.boxLengthCm"}},[_c('template',{slot:"append"},[_vm._v("cm")])],2)],1),_c('p',{staticClass:"t_a_r calcu-p"},[_c('span',[_vm._v(_vm._s(_vm.form.boxLengthIn))]),_vm._v(" IN ")])],1),_c('span',[_vm._v("x")]),_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"prop":"boxWidthCm","rules":[
                  {
                    required: true,
                    message: _vm.$t('rules.M2016'),
                    trigger: 'blur',
                  },
                  {
                    pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                    message: _vm.$t('rules.M2014'),
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"type":"number","placeholder":_vm.$t('placeholder.M2009')},on:{"input":function($event){return _vm.boxSize('Width')}},model:{value:(_vm.form.boxWidthCm),callback:function ($$v) {_vm.$set(_vm.form, "boxWidthCm", $$v)},expression:"form.boxWidthCm"}},[_c('template',{slot:"append"},[_vm._v("cm")])],2)],1),_c('p',{staticClass:"t_a_r calcu-p"},[_c('span',[_vm._v(_vm._s(_vm.form.boxWidthIn))]),_vm._v(" IN ")])],1),_c('span',[_vm._v("x")]),_c('el-col',{attrs:{"span":5}},[_c('el-form-item',{attrs:{"prop":"boxHeightCm","rules":[
                  {
                    required: true,
                    message: _vm.$t('rules.M2017'),
                    trigger: 'blur',
                  },
                  {
                    pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                    message: _vm.$t('rules.M2014'),
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"type":"number","placeholder":_vm.$t('placeholder.M2010')},on:{"input":function($event){return _vm.boxSize('Height')}},model:{value:(_vm.form.boxHeightCm),callback:function ($$v) {_vm.$set(_vm.form, "boxHeightCm", $$v)},expression:"form.boxHeightCm"}},[_c('template',{slot:"append"},[_vm._v("cm")])],2)],1),_c('p',{staticClass:"t_a_r calcu-p"},[_c('span',[_vm._v(_vm._s(_vm.form.boxHeightIn))]),_vm._v(" IN ")])],1),_c('el-col',{attrs:{"span":7,"offset":2}},[_c('span',{staticClass:"mr30"},[_vm._v(" "+_vm._s(_vm.$t('productDetail.VolumeM3'))+"："+_vm._s(_vm.boxM3)+" "),_c('span')]),_c('span',{staticClass:"ml30"},[_vm._v(" "+_vm._s(_vm.$t('productDetail.VolumeFT3'))+"："+_vm._s(_vm.boxFt3)+" "),_c('span')])])],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }