<template>
  <div class="custom-container">
    <el-table :data="tableData" border>
      <el-table-column
        label="物流公司"
        prop="company"
        align="center"
      ></el-table-column>
      <el-table-column
        label="仓库地址"
        prop="whLoc"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">-</el-table-column>
    </el-table>
  </div>
</template>

<script>
import { deliveryAddressList as getLogisticsWarehouseAddress } from '@/api/stock-center/shipment'
export default {
  name: 'logistics-warehouse-configuration',
  data() {
    return {
      tableData: [],
    }
  },

  created() {
    getLogisticsWarehouseAddress().then((res) => {
      if (res && res.code === '000000') {
        this.tableData = res.data
      }
    })
  },
}
</script>

<style>
</style>
