import request from '@/core/services/request'

//用户角色关系，根据ID删除
export function roleDeleteById(data) {
  return request({
    url: '/system/userRole/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//用户角色关系，根据userId查找角色
export function roleGetDetail(data) {
  return request({
    url: '/system/userRole/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//用户角色关系，新增实体
export function roleInsertPO(data) {
  return request({
    url: '/system/userRole/insertPO',
    method: 'post',
    data,
  })
}
