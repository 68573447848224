<!--
  功能：提成汇总字段
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年04月18日 10:31:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <span
    v-if="value !== null && value !== undefined"
    :class="{ 'page-link': isLink }"
    @click="showDetail"
    :style="{ color: value < 0 ? 'red' : '' }"
  >
    {{ value.toFixed(3) }}
  </span>
  <span v-else>-</span>
</template>

<script>
export default {
  name: 'PersonnelCommissionSummaryField',
  props: {
    type: {
      type: Number,
      default: 1, //1客户代表提成, 2产品运营提成, 3采购开发提成, 4订单支持提成, 5客户代表管理提成, 6产品运营管理提成, 7采购开发管理提成, 8订单支持管理提成
    },
    value: {
      type: Number,
    },
    isLink: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  components: {},
  // 组件状态值
  data() {
    return {}
  },

  methods: {
    showDetail() {
      this.$emit('showDetail', this.type, this.userId, this.isLink)
    },
  },
}
</script> 

<style scoped>
</style>
