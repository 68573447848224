<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    v-if="dialogFormVisible"
    width="50%"
    @close="close"
    :close-on-click-modal="false"
  >
    <!-- 仓库 -->
    <div class="dialog-content">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          prop="warehouseName"
          label="仓库名称"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="num"
          label="库存数量"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="lockNum"
          label="锁定量"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="outNum"
          label="待出库"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="usableNum"
          label="可用量"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="unusableNum"
          label="产品不可用量"
          align="center"
        ></el-table-column>
        <el-table-column label="库位" align="center">
          <template slot-scope="scope">
            <el-tooltip
              effect="light"
              placement="bottom"
              v-if="scope.row.warehouseLocationVOList.length"
            >
              <el-row slot="content" class="warehouseLocationVOList-row">
                <p>
                  <span>库位号</span>
                  <span>在仓数</span>
                </p>
                <p
                  v-for="(item, index) of scope.row.warehouseLocationVOList"
                  :key="index"
                >
                  <span>{{ item.locationNumber }}</span>
                  <span>{{ item.num }}</span>
                </p>
              </el-row>
              <el-row>
                <div
                  class="d_block link c_pointer"
                  v-for="(item, index) of scope.row.warehouseLocationVOList"
                  :key="index"
                >
                  <p v-show="index <= 3">
                    {{ item.locationNumber || '--' }}
                  </p>
                  <i v-show="index === 4">...</i>
                </div>
              </el-row>
            </el-tooltip>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total"
        :current-page="form.pageNo"
        :page-size="form.pageLe"
        :total="total"
        @current-change="currentChange"
      />
    </div>
  </el-dialog>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import { storeManage } from '@/core/interactors/stock-center/stock-manage'

  export default {
    name: 'ProWarehouseNameModel',
    components: { Pagination },
    data() {
      return {
        title: '仓库',
        form: {
          skuId: '',
          pageLe: 10,
          pageNo: 1,
        },
        total: 0,
        tableData: [],
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //查看
      showAddEdit(id) {
        this.form.skuId = id
        this.getStockInfoBySkuId()
        this.dialogFormVisible = true
      },

      currentChange(val) {
        this.form.pageNo = val
        this.getStockInfoBySkuId()
      },

      //仓库详情
      async getStockInfoBySkuId() {
        let response = await storeManage.stockInfoBySkuId({ ...this.form })

        if (response.code === '000000') {
          this.tableData = response.data.data
          this.total = response.data.total
        }
      },

      //关闭
      close() {
        this.form = { skuId: '', pageLe: 10, pageNo: 1 }
        this.tableData = []
        this.total = 0
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .text-hidden {
    width: 150px;
  }
  .link {
    color: #1890ff;
  }
  .warehouseLocationVOList-row {
    p {
      border-bottom: 1px solid #ededed;
      padding-bottom: 5px;
      padding-top: 5px;
    }
    span {
      display: inline-block;
      width: 100px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
