<!--
  功能：运营成本配置新增编辑页面
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月22日 11:16:59
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="table-container pro-container">
    <el-page-header @back="goBack()" :content="content"></el-page-header>
    <div class="content-wrapper">
      <component
        :is="isComponent"
        :ref="'new' + newType"
        @back="back"
      ></component>
    </div>

    <div class="btn-wrapper">
      <el-button @click="goBack">返回</el-button>
      <el-button type="primary" @click="saveTpl">保存</el-button>
    </div>
  </div>
</template>

<script>
  import TplNew from './operating-cost-config-tpl/operating-cost-config-tpl-new.vue'
  import UserNew from './operating-cost-config-user/operating-cost-config-user-new.vue'
  import CusNew from './operating-cost-config-customer/operating-cost-config-customer-new.vue'
  import { mapGetters } from 'vuex'

  export default {
    name: 'OperatingCostConfigNew',
    props: {},
    components: {
      TplNew,
      UserNew,
      CusNew,
    },
    // 组件状态值
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      tenantId() {
        return this.userInfo.tenantId
      },
      newType() {
        return this.$route.query.type
      },

      isComponent() {
        switch (this.newType) {
          case '1':
            return TplNew
          case '2':
            return UserNew
          case '3':
            return CusNew
        }
      },
      content() {
        const type2content = {
          1: '模板配置',
          2: '人员查询',
          3: '客户配置',
        }
        return `${type2content[this.newType]}${this.$route.query.businessId ? '编辑':'新增'}`
      },
    },
    watch: {},
    methods: {
      // 保存
      saveTpl() {
        const comp = this.$refs['new' + this.newType]
        comp.save(this.tenantId)
      },

      // 返回
      goBack() {
        const comp = this.$refs['new' + this.newType]
        const list = comp.form.tableData,
         listItemKeys =comp.checkedKeys,
          isEdit = !!this.$route.query.businessId,
         
        hasData = list.some((item) => {
          for (let i = 0, l = listItemKeys.length; i < l; i++) {
            if (item[listItemKeys[i]]) {
              return true
            }
          }
        })
        if (!isEdit && hasData) {
          this.$confirm('数据没有保存，是否返回', '提示', {
            type: 'warning',
            showCancelButton: true,
          })
            .then(() => {
              this.$router.push({
                name: 'ConfigManage',
                query: {
                  tab: '1',
                  radio: this.newType,
                },
              })
            })
            .catch(() => {})
        } else {
           this.$router.push({
                name: 'ConfigManage',
                query: {
                  tab: '1',
                  radio: this.newType,
                },
              })
        }
      },
      back() {
         this.$router.push({
                name: 'ConfigManage',
                query: {
                  tab: '1',
                  radio: this.newType,
                },
              })
      }
    },
  }
</script>

<style scoped lang="scss">
  .table-container {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .content-wrapper {
      flex: 1;
      overflow: auto;
    }
    .btn-wrapper {
      height: 40px;
      margin-top: 10px;
      text-align: center;
      .el-button {
        margin: 0 8px;
      }
    }
  }
</style>
