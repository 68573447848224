<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2024年11月19日 10:50:11
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="pro-container">
    <el-button type="primary" @click="handleEdit">编辑值班</el-button>
    <el-table :data="tableData" border class="mt20">
      <el-table-column type="index" label="序号" width="50" align="center" />
      <el-table-column label="时段" align="center">
        <template slot-scope="scope">
          {{ scope.row.timeData[0] }}-{{ scope.row.timeData[1] }}
          <div style="color: #ff4d4f">
            <i class="el-icon-warning" />
            {{
              subtractOneHour(scope.row.timeData, scope.row.assignTimeConfig)
            }}后此时段不再接收分配邮件
          </div>
        </template>
      </el-table-column>
      <el-table-column label="星期一" align="center">
        <template slot-scope="scope">
          <div v-for="name of scope.row.userNameList1" :key="name">
            {{ name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="星期二" align="center">
        <template slot-scope="scope">
          <div v-for="name of scope.row.userNameList2" :key="name">
            {{ name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="星期三" align="center">
        <template slot-scope="scope">
          <div v-for="name of scope.row.userNameList3" :key="name">
            {{ name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="星期四" align="center">
        <template slot-scope="scope">
          <div v-for="name of scope.row.userNameList4" :key="name">
            {{ name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="星期五" align="center">
        <template slot-scope="scope">
          <div v-for="name of scope.row.userNameList5" :key="name">
            {{ name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="星期六" align="center">
        <template slot-scope="scope">
          <div v-for="name of scope.row.userNameList6" :key="name">
            {{ name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="星期日" align="center">
        <template slot-scope="scope">
          <div v-for="name of scope.row.userNameList7" :key="name">
            {{ name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="assignTimeConfig"
        label="截止分配时间（h）"
        align="center"
      />
    </el-table>
    <!-- 编辑值班弹框 -->
    <EditDialog
      v-model="editDialogVisible"
      :tableData="tableData"
      @refresh="getList"
    />
  </div>
</template>
<script>
  import EditDialog from './components/EditDialog'
  import { getTableData, subtractOneHour } from './utils'
  import { MailSettingInteractor } from '@/core'
  export default {
    name: 'DutyConfig',
    components: { EditDialog },
    data() {
      return {
        tableData: [],
        editDialogVisible: false,
      }
    },
    methods: {
      subtractOneHour,
      async getList() {
        const res = await MailSettingInteractor.mailUserScheduleQueryApi()
        if (res?.code === '000000' && Array.isArray(res?.data)) {
          const tableData = getTableData(res.data)
          this.tableData = tableData
        }
      },
      handleEdit() {
        this.editDialogVisible = true
      },
    },
  }
</script>
