<template>
  <!-- 平台价格 -->
  <el-row :gutter="20" class="pro-container pro-base-msg pro-price" v-if="productType === '标准品'">
    <!-- <el-button @click="showData"></el-button> -->
    <el-col :span="2">
      <!-- 印刷计价 -->
      <h6 class="left-h6">{{ $t('productDetail.PlatformPrice') }}</h6>
    </el-col>
    <el-col :span="22" class="right-content" style="padding: 20px 20px">
      <el-form ref="form" inline :rules="rules" :model="form" label-width="140px" class="el_form"
        :label-position="lang === 'zh' ? 'right' : 'top'">
        <el-form-item>
          <!-- Sage展示价 -->
          <span style="color: red">*</span>
          <span class="ml5">{{ $t('productDetail.SageDisplayPrice') }}</span>
        </el-form-item>
        <el-form-item>
          <el-cascader class="cascader-demo" v-model="plantPrintWay" :options="printOptions"
            :props="{ label: lang === 'zh' ? 'label' : 'labelEn' }" :key="cascaderIdx" :placeholder="placeholderVal"
            :append-to-body="false" @change="changePlantPrintWay"></el-cascader>
        </el-form-item>
        <el-form-item class="ml20">
          <div slot="label">
            <span style="color: red">*</span>
            <!-- 平台展示免运费 -->
            <span class="ml5">
              {{ $t('productDetail.PlatformDisplayFreeShipping') }}
            </span>
          </div>
          <el-switch v-model="calcFreight"></el-switch>
        </el-form-item>
      </el-form>
      <el-table :data="calcList" style="width: 100%" border>
        <!-- 数量 -->
        <el-table-column prop="num" :label="$t('productDetail.Quantity')" align="center">
          <template slot-scope="scope" v-if="printingWaySelectObj">
            <span>{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <!-- netPrice -->
        <el-table-column prop="netPrice" label="Sage Net Price($)" align="center">
          <template slot-scope="scope">
            <span>
              {{ addNumber(scope.row.netPrice, scope.row.printPrice) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="listPrice" align="center">
          <template slot="header">
            <p>Sage</p>
            <p>List Price($)</p>
          </template>
          <template slot-scope="scope">
            {{ addListNumber(scope.row.netPrice, scope.row.printPrice, 1) }}
          </template>
        </el-table-column>
        <!-- Sage Net 总价 -->
      </el-table>
      <el-row class="mt10" v-if="printingMarkup && printingMarkup.length">
        <el-col class="mt10">
          <p class="mt10">
            {{ $t('productDetail.PrintingMarkup') }}
          </p>
          <el-table style="width: 500px" border :data="printingMarkup">
            <!-- 数量 -->
            <el-table-column :label="$t('productDetail.Quantity')" align="center" prop="num"></el-table-column>
            <el-table-column label="Additonal Imprint ($)" align="center" prop="price">
              <template slot-scope="scope">{{ scope.row.price }} ea</template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row class="mt10" v-if="false && plantPrintWay.length > 0 && plantPrintWay[0] != ''">
        <el-col v-if="sagePrintPrice > 0">
          <p class="mt10">
            {{ $t('productDetail.PrintingMarkup') }}
          </p>
          Additonal Imprint：$
          {{ setNumber(sagePrintPrice, minPrintPrice) }} ea
        </el-col>
        <el-col class="mt10" v-if="isNumberStep && finalStepNumber.length > 0">
          <p class="mt10">
            {{ $t('productDetail.PrintingMarkup') }}
          </p>
          <el-table style="width: 500px" border :data="finalStepNumber">
            <!-- 数量 -->
            <el-table-column :label="$t('productDetail.Quantity')" align="center" prop="num"></el-table-column>
            <el-table-column label="Additonal Imprint ($)" align="center" prop="price">
              <template slot-scope="scope">
                {{ setNumber(scope.row.price, scope.row.number_price_min) }} ea
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-row v-if="this.showAddPrice && plantPrintWay.length > 0">
          <el-col class="mt30" v-if="!showAddPriceTitle">
            {{ $t('productDetail.SpecificationMarkup') }}
          </el-col>
          <div class="mt10" v-for="(item, index) in this.specTable" :key="index">
            <el-col v-for="(it, i) in item.value" :key="i + 100">
              <span v-show="it.basePricesSetting == 0 && it.specsPrice != 0">
                {{ it.specsValue }} Additonal：$ {{ it.specsPrice }} ea
              </span>
            </el-col>
          </div>
        </el-row>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import { getDetailByType } from '@/api/product/productSetting'
  import { elFormErrorScrollIntoView } from 'kits'
  export default {
    data() {
      return {
        cascaderIdx: 0,
        productType: '标准品',
        showPlantSelect: false, // 多规格展示，一个规格不展示
        form: {},
        rules: {},
        printingWaySelectObj: {},
        waySelectObj: {},
        specsList: [], // 规格列表
        printOptions: [{ label: 'Blank', labelEn: 'Blank', value: '' }], // 平台价格展示的级联选择
        plantPrintWay: [], // 平台价格展示的 计价方式
        calcFreight: false, // 是否展示免运费
        calcList: [], // 平台价格列表
        printListAll: [], //所有的印刷方式
        printList: [], //印刷计价选择的印刷方式
        netPriceList: [], // 数量阶梯对应的价格
        pageType: '',
        sagePrintPrice: 0, // 平台价格 选中的 印刷价格
        minPrintPrice: 0, // 选中的印刷方式最低的印刷价格
        isNumberStep: false, // 是否是数量阶梯
        waySelect: {},
        printingWaySelect: {},
        specTable: [],
        stepNumber: [],
        showAddPrice: true,
        currentPrice: 0, // 当前印刷方式价格
        priceRate: 0,
        printOptionskey: '',
        placeholderVal: this.$t('placeholder.plsSel'),
        printingMarkup: [], //印刷加价
        sortResult: [], //最小值数组
        toFixedNumber: 2, //保留几位小数
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      showAddPriceTitle() {
        let arr = []
        if (
          this.specTable.length == 1 &&
          typeof this.specTable[0].value == 'undefined'
        ) {
          return true
        } else {
          this.specTable.forEach((it) => {
            it.value?.forEach((ot) => {
              arr.push(ot.specsPrice)
            })
          })
          let state = arr.every((it) => {
            return it == 0
          })
          return state
        }
      },
      finalStepNumber() {
        let arr = this.stepNumber.filter((it) => {
          return it.price != it.number_price_min
        })
        return arr
      },
      AdditonalPrice() {
        return parseFloat(
          Number((this.sagePrintPrice - this.minPrintPrice).toFixed(3))
        )
      },
    },

    watch: {
      printOptions: {
        handler(val) {
          this.cascaderIdx++
        },
        deep: true,
      },

      printList: {
        deep: true,
        handler(val) {
          this.handlePrintOptions(val, this.printOptions)
          this.getPrintingMarkupFn(this.plantPrintWay)
        },
        immediate: true,
      },
    },

    created() {
      this.getPrintWay()
      this.getPrintList()
      this.getSendPrintWay()
      this.getNetPrice()
      this.getExtraSpecPrice()
      // 印刷计价 改变，删除，新增
      this.getPricingConfigChange()
      this.getPrintWayDel()
      this.getPrintWayAdd()
      this.getPrintWayByPriceChange()

      this.$Bus.$on('categoryId', (id) => {
        this.plantPrintWay = []
        this.calcList = []
      })

      this.$Bus.$on('isSingleSpec', (val) => {
        this.showAddPrice = val
        if (!this.showAddPrice) {
          this.specTable = []
        }
      })
      this.$Bus.$on('switchChange', () => {
        // this.printOptions = []
        this.printingWaySelect = {}
        this.waySelect = {}
        this.plantPrintWay = []
        this.placeholderVal = this.$t('placeholder.plsSel')
      })

      // 切换小数位
      this.$Bus.$on('changePriceScale', (val) => {
        this.toFixedNumber = val
      })
      this.getProductType()
      this.getDetailByType8()
    },

    methods: {
      // 获取印刷加价
      getPrintingMarkupFn(val) {
        if (!val || val.length < 2) {
          this.printingMarkup = []
          return
        }
        let printingMarkup = []
        this.printingMarkup = []
        const payObj = this.printList.filter(
          (item) => item.productPrintingWayId === val[0]
        )?.[0]
        if (!payObj) return
        this.getMinValue(payObj.productPrintingFullValuationDTOList)
        let printingPositionNumArr =
          payObj.productPrintingFullValuationDTOList.filter(
            (item) => item.printingPositionNum === 1
          )
        printingPositionNumArr.map((item) => {
          const idx = payObj.valuationMethodValue.findIndex(
            (k) => k === item.valuationMethod
          )
          item.valuationMethodId = payObj.valuationMethodIds[idx]
          return item
        })

        let currentSelect = printingPositionNumArr.filter(
          (item) => item.valuationMethodId === val[1]
        )?.[0]

        this.netPriceList.forEach((item, index) => {
          printingMarkup.push({
            num: item.num,
            price: currentSelect ? currentSelect['price' + index] : '',
          })
        })

        for (var i = 0; i < printingMarkup.length; i++) {
          if (Number(printingMarkup[i].price) - Number(this.sortResult[i])) {
            this.printingMarkup.push({
              num: printingMarkup[i].num,
              price: (
                Number(printingMarkup[i].price) - Number(this.sortResult[i])
              ).toFixed(2),
            })
          }
        }
      },
      // 找出最小值
      getMinValue(val) {
        if (!val || !val.length) return
        let arr = []
        val.forEach((item) => {
          let subArr = []
          this.netPriceList.forEach((sub, subIndex) => {
            subArr.push(item['price' + subIndex])
          })
          arr.push(subArr)
        })
        const arrSort = arr.reduce((acc, curr) => {
          curr.forEach((value, index) => {
            if (!Array.isArray(acc[index])) {
              acc[index] = []
            }
            acc[index].push(value)
          })
          return acc
        }, [])
        this.sortResult = arrSort.map((subArr) => {
          let min = undefined
          for (const value of subArr) {
            if (
              (typeof value === 'number' && value !== 0) ||
              (typeof value === 'string' && value.trim() !== '')
            ) {
              if (min === undefined || value * 1 < min) {
                min = value * 1
              }
            }
          }
          return min // 返回最小的非零且非空元素
        })
      },
      // 处理下拉项
      handlePrintOptions(printMethods = [], printOptions) {
        printOptions = JSON.parse(JSON.stringify(printOptions))
        let arr = []
        printMethods.forEach((method) => {
          const {
            nameCn,
            nameEn,
            valuationMethodIds,
            valuationMethodValue,
            valuationMethodValueEn,
          } = method

          printOptions.forEach((pr) => {
            if (pr.label != 'Blank') {
              // 定义oldChildren记住原始数据
              pr.value = pr.productPrintingWayId || pr.productPrintingPricingId
              pr.oldChildren = pr.oldChildren || pr.children
              if (pr.nameCn === nameCn || pr.nameEn === nameEn) {
                let children = pr.oldChildren
                if (valuationMethodIds) {
                  // 有id根据id判断
                  children = pr.oldChildren.filter((item) =>
                    valuationMethodIds.includes(
                      item.productPrintingPricingOptionsId
                    )
                  )
                  pr.children = children
                } else {
                  // 没id根据名称判断
                  children = pr?.oldChildren?.filter((item) =>
                    valuationMethodValue?.includes(item.pricingValue)
                  )
                  pr.children = children
                }
              }
            }
          })
          this.printOptions = printOptions
          if (this.plantPrintWay && this.plantPrintWay.length) {
            if (
              this.plantPrintWay[0] === '' ||
              (printOptions?.length === 1 && !printOptions?.[0].value)
            )
              return
            const includesArr = printOptions.filter(
              (item) =>
                item.productPrintingPricingId ||
                item.productPrintingWayId === this.plantPrintWay?.[0]
            )
            if (includesArr && includesArr.length) {
              if (
                includesArr[0].children.some(
                  (item) =>
                    item.productPrintingPricingOptionsId ===
                    this.plantPrintWay?.[1]
                )
              ) {
                const textCN = `${this.printingWaySelect.nameCn}/${this.waySelect.pricingValue}-1位`
                const textEN = `${this.printingWaySelect.nameEn}/${this.waySelect.pricingValueEn}-1position`
                this.placeholderVal = this.lang === 'zh' ? textCN : textEN
              } else {
                this.printingWaySelect = {}
                this.waySelect = {}
                this.plantPrintWay = []
                this.placeholderVal = this.$t('placeholder.plsSel')
              }
            } else {
              this.printingWaySelect = {}
              this.waySelect = {}
              this.plantPrintWay = []
              this.placeholderVal = this.$t('placeholder.plsSel')
            }
          } else {
            this.placeholderVal = this.$t('placeholder.plsSel')
            this.printingWaySelect = {}
            this.waySelect = {}
            this.plantPrintWay = []
          }
        })

        this.printOptionskey = Date.now()
      },
      // 点击选择印刷方式时 触发
      getPrintWay() {
        this.$Bus.$on('printWay', (val) => {
          this.setPrintOptions(val)
        })
      },
      // 获取印刷方式
      getPrintList() {
        this.$Bus.$on('printList', (val) => {
          this.printList = val
          if (this.pageType === 'edit') {
            this.setEditPrintOptions()
            this.setPlantPrice()
          }
          // if (val.length == 0) {
          //   this.plantPrintWay = []
          //   this.printingWaySelect.nameCn = ''
          //   this.printingWaySelect.nameEn = ''
          //   this.printingWaySelect.productPrintingPricingId = ''
          //   this.waySelect.pricingValue = ''
          //   this.waySelect.pricingValueEn = ''
          //   this.waySelect.productPrintingPricingOptionsId = ''
          //   this.placeholderVal = this.$t('placeholder.plsSel')
          // }
          this.getPrintPriceByChoose(this.plantPrintWay)
          this.setAddPrintPrice(this.plantPrintWay)
        })
      },

      // 获取所有的印刷方式
      getSendPrintWay() {
        this.$Bus.$on('sendPrintWay', (val) => {
          this.setPricePrintList(val)
        })
      },

      // 获取netprice 相关数据
      getNetPrice() {
        this.$Bus.$on('bcglXiangXiList', (val) => {
          this.netPriceList = val
          this.getPrintingMarkupFn(this.plantPrintWay)
          this.stepNumber = this.netPriceList.map((it) => {
            return {
              num: it.num,
            }
          })
          if (this.plantPrintWay.length !== 0) {
            this.setPlantPrice()
            this.setAddPrintPrice(this.plantPrintWay)
          }
        })
      },
      // 获取规格加价
      getExtraSpecPrice() {
        this.$Bus.$on('extraSpecPrice', (val) => {
          this.specTable = _.cloneDeep(val)
        })
      },
      // 印刷方式的计价方式改变
      getPricingConfigChange() {
        this.$Bus.$on('changePricingConfig', (id, val) => {
          this.setPricePrintList(val)
          this.setAddPrintPrice(this.plantPrintWay)
        })
      },

      // 删除印刷方式的某一项
      getPrintWayDel() {
        this.$Bus.$on('delPrintWayItem', (id, val1, val2) => {
          this.setPricePrintList(val2)
          this.setPrintOptions(val1)
          if (this.plantPrintWay[1] === id) {
            this.calcList = []
            this.plantPrintWay = []
            this.printingWaySelect = {}
            this.waySelect = {}
            this.placeholderVal = this.$t('placeholder.plsSel')
          }
        })
      },
      // 新增印刷方式的某一项
      getPrintWayAdd() {
        this.$Bus.$on('addPrintWayItem', (val1, val2) => {
          this.setPricePrintList(val2)
          this.setPrintOptions(val1)
        })
      },
      // 印刷加价 数值改变
      getPrintWayByPriceChange() {
        this.$Bus.$on('changePricingValue', (val) => {
          this.setPricePrintList(val)
          if (this.plantPrintWay.length !== 0) {
            this.setAddPrintPrice(this.plantPrintWay)
          }
        })
      },
      // 获取产品类型
      getProductType() {
        this.$Bus.$on('setProductType', (val) => {
          let i = 0
          this.productType = val
          this.calcList = []
          this.printingMarkup = []
          this.placeholderVal = this.$t('placeholder.plsSel')
          this.plantPrintWay = []
          for (const key in this.waySelect) {
            this.waySelect[key] = ''
          }
          for (const key in this.printingWaySelect) {
            this.printingWaySelect[key] = ''
          }
        })
      },

      //产品汇率
      async getDetailByType8() {
        let response = await getDetailByType({ type: 8 })
        if (response.code === '000000' && response.data.length) {
          this.priceRate = Number(response.data[0].value)
        }
      },

      // 编辑回显
      async editShow(detail) {
        this.productType = detail.standardCustomized
        this.toFixedNumber = detail.priceScale || 3
        let arr_1 = detail.productPrintingWayVOList
        this.printListAll = arr_1?.map((it) => {
          return {
            ...it,
            optionsVOS: JSON.parse(it.printingWay),
            optionsVOSMulity: JSON.parse(it.printingWay),
          }
        })

        if (
          detail.printingWaySelectId &&
          detail.printingWaySelect &&
          detail.printingWaySelectEn
        ) {
          this.plantPrintWay = [detail.printingWaySelectId, detail.waySelectId]
          const textCN = `${detail.printingWaySelect}/${detail.waySelect}-1位`
          const textEN = `${detail.printingWaySelectEn}/${detail.waySelectEn}-1position`
          this.placeholderVal = this.lang === 'zh' ? textCN : textEN
          this.waySelect.productPrintingPricingOptionsId = detail.waySelectId
          this.waySelect.pricingValue = detail.waySelect
          this.waySelect.pricingValueEn = detail.waySelectEn
          this.printingWaySelect.nameCn = detail.printingWaySelect
          this.printingWaySelect.nameEn = detail.printingWaySelectEn
          this.printingWaySelect.productPrintingWayId =
            detail.printingWaySelectId
        } else if (
          detail.printingWaySelect === 'Blank' &&
          detail.printingWaySelectEn === 'Blank'
        ) {
          this.plantPrintWay = []
          this.printingWaySelect.nameCn = detail.printingWaySelect
          this.printingWaySelect.nameEn = detail.printingWaySelectEn
          this.placeholderVal =
            this.lang === 'zh'
              ? this.printingWaySelect.nameCn
              : this.printingWaySelect.nameEn
          this.calcList = detail.productLadderPriceVOList.map((it) => {
            return {
              listPrice: it.listPrice,
              netPrice: it.netPrice,
              totalListPrice: it.totalListPrice,
              totalNetPrice: it.totalNetPrice,
              price: it.price, // 产品价格
              shippingPrice: it.shippingPrice,
              num: it.num, // 产品数量
            }
          })
        } else {
          this.plantPrintWay = []
        }
        this.pageType = 'edit'
        this.setSelected(detail)
      },

      setNumber(val1, val2) {
        return parseFloat(Number((val1 - val2).toFixed(this.toFixedNumber)))
      },

      // 给规格赋值选中
      setSpecList() {
        this.specsList = this.specsList.map((it) => {
          return {
            ...it,
            choose: '',
          }
        })
      },
      changePlantPrintWay(val) {
        this.placeholderVal = this.$t('placeholder.plsSel')
        // 获取印刷加价
        this.getPrintingMarkupFn(val)
        if (val && val.length > 1) {
          this.setPlantPrice()
          // 找到数量阶梯的价格
          val = this.getPrintPriceByChoose(val)

          // 设置印刷加价
          this.setAddPrintPrice(val)
          // 获取到选择对应的中文名，英文名
          this.getFullName(val)
        } else {
          // 选择Blank 时
          this.setPlantPrice()

          this.printingWaySelect.nameCn = 'Blank'
          this.printingWaySelect.nameEn = 'Blank'
          this.printingWaySelect.productPrintingPricingId = ''
          this.waySelect.pricingValue = ''
          this.waySelect.pricingValueEn = ''
          this.waySelect.productPrintingPricingOptionsId = ''
        }
      },

      getFullName(val) {
        let obj = this.printOptions.find((it) => {
          return (
            it.productPrintingPricingId === val[0] ||
            it.productPrintingWayId === val[0]
          )
        })
        this.printingWaySelect = JSON.parse(JSON.stringify(obj))
        this.waySelect = this.printingWaySelect.optionsVOS.find((ot) => {
          return ot.productPrintingPricingOptionsId === val[1]
        })
      },
      // 设置印刷方式下拉 所有的
      setPricePrintList(list) {
        let arr = _.cloneDeep(list)
        arr?.forEach((it) => {
          it.children = it.optionsVOS.map((item) => {
            return {
              ...item,
              label: `${item.pricingValue}-1${this.lang === 'zh' ? '位' : 'position'
                }`,
              labelEn: `${item.pricingValueEn}-1${this.lang === 'zh' ? '位' : 'position'
                }`,
              value: item.productPrintingPricingOptionsId,
            }
          })
        })

        this.printListAll = arr.map((item) => {
          return {
            ...item,
            children: item.children,
            label: item.nameCn,
            labelEn: item.nameEn,
            value: item.productPrintingPricingId,
          }
        })
      },
      // 获取选择的印刷方式
      setPrintOptions(ids) {
        this.printOptions = []
        this.printOptions = this.printListAll.filter((it) => {
          return ids.indexOf(it.value) > -1
        })
        if (this.printOptions && !this.printOptions.length) {
          this.printingWaySelect = {}
          this.waySelect = {}
          this.plantPrintWay = []
          this.placeholderVal = this.$t('placeholder.plsSel')
        }
        this.printOptions.push({
          label: 'Blank',
          labelEn: 'Blank',
          value: '',
        })
      },
      // 根据平台价格选择的印刷方式 找到对应的价格
      getPrintPriceByChoose(ids) {
        // 没选
        if (ids.length == 0) {
          this.calcList = []
        } else if (ids[0] === '') {
          // 选中Blank
          this.calcList = this.calcList.map((it, i) => {
            return {
              ...it,
              printPrice: 0,
              totalPrintPrice: 0,
            }
          })
        } else {
          // 1 找到印刷方式
          if (ids[0] === undefined) {
            // 通过ids[1]去反找ids[0]
            let ids1 = ids[1]

            let obj = this.printList.find((s) => {
              if (
                s.optionsVOS.some(
                  (item) => item.productPrintingPricingOptionsId === ids1
                )
              ) {
                return true
              } else {
                return false
              }
            })
            if (obj) {
              ids[0] = obj.productPrintingWayId
            }
          }
          let arr_1 = this.printList.find((it) => {
            // productPrintingPricingId修改为productPrintingWayId
            // return it.productPrintingPricingId == ids[0]
            return (
              it.productPrintingWayId == ids[0] ||
              it.productPrintingPricingId == ids[0]
            )
          })
          let arr_2 = arr_1?.optionsVOS?.find((it) => {
            return it.productPrintingPricingOptionsId == ids[1]
          })
          // V2.0.4 shopping版本新增
          const { pricingValue, pricingValueEn } = arr_2 || {}
          const productPrintingFullValuationObj =
            arr_1?.productPrintingFullValuationDTOList.find((s) =>
              [pricingValue, pricingValueEn].includes(s.valuationMethod)
            )
          // 2 找到位 价格  数量阶梯取number_price  统一设置取price
          // 2_1.判断是数量阶梯还是统一设置 pricingConfiguration 0数量阶梯  1不是数量阶梯
          if (arr_1?.pricingConfiguration == 0) {
            let priceList = arr_1.optionsVOS.map((it) => Number(it.price))
            // 取最小价格
            if (priceList && priceList.length > 0) {
              // this.minPrintPrice = Math.min(...priceList)
              this.minPrintPrice = 0
            }
          } else {
            // 按数量阶梯
            let priceList = []
            arr_1?.optionsVOS.forEach((it, i) => {
              priceList.push(it[`number_price_${i}`])
            })

            if (priceList && priceList.length > 0) {
              // this.minPrintPrice = Math.min(...priceList)
              this.minPrintPrice = 0
            }
            // 找到最低值。赋值到stepNumber 中
            this.stepNumber.forEach((it, i) => {
              let price = []
              arr_1?.optionsVOS.forEach((ot, oi) => {
                price.push(ot[`number_price_${i}`])
                this.$set(it, 'price', ot[`number_price_${i}`])
              })

              this.$set(it, 'number_price_min', Math.min(...price))
            })

            this.stepNumber.forEach((it, i) => {
              it.price = arr_2?.[`number_price_${i}`]
            })
          }

          if (arr_1) {
            this.calcList = this.calcList.map((it, i) => {
              this.$set(
                it,
                'printPrice',
                arr_1?.pricingConfiguration == 1
                  ? this.stepNumber[i].number_price_min
                  : this.minPrintPrice
              )
              return {
                ...it,
                totalPrintPrice: Number(it.num) * Number(it.printPrice),
              }
            })
            // shopping版本新加
            if (productPrintingFullValuationObj) {
              this.calcList = this.calcList.map((it, i) => {
                if (i === 0) {
                  this.sagePrintPrice = (
                    productPrintingFullValuationObj[`price${i}`] * 1
                  ).toFixed(this.toFixedNumber)
                }
                it.netPrice = productPrintingFullValuationObj[`price${i}`]
                return it
              })
            } else {
              this.calcList = []
            }
          } else {
            this.calcList = []
            this.plantPrintWay = []
          }
          return ids
        }
      },

      // 设置平台价格
      setPlantPrice() {
        this.calcList = this.netPriceList.map((it) => {
          return {
            listPrice: it.listPrice,
            netPrice: it.netPrice,
            totalListPrice: it.totalListPrice,
            totalNetPrice: it.totalNetPrice,
            price: it.price, // 产品价格
            shippingPrice: it.shippingPrice,
            num: it.num, // 产品数量
          }
        })
      },

      // 两数相加
      // addNumber(a, b) {

      //   let no = (Number(a || 0) + Number(b || 0)).toFixed(3)
      //   return no
      // },
      addNumber(a) {
        a = a * 1
        if (!a && a !== 0) return '--'
        if (
          this.plantPrintWay &&
          this.plantPrintWay.length &&
          this.plantPrintWay[0] === ''
        ) {
          return a.toFixed(this.toFixedNumber)
        } else {
          return a.toFixed(this.toFixedNumber)
        }
      },

      // listprice
      addListNumber(a) {
        a = a * 1
        let no = Number((Number(a || 0) / this.priceRate).toFixed(3))
        return no.toFixed(2)
      },

      // 设置已选择的项
      setSelected(detail) {
        this.waySelect.productPrintingPricingOptionsId = detail.waySelectId
        this.waySelect.pricingValue = detail.waySelect
        this.waySelect.pricingValueEn = detail.waySelectEn
        this.printingWaySelect.productPrintingPricingId =
          detail.printingWaySelectId
        this.printingWaySelect.nameCn = detail.printingWaySelect
        this.printingWaySelect.nameEn = detail.printingWaySelectEn

        if (
          this.printingWaySelect.productPrintingPricingId === '' &&
          this.printingWaySelect.nameCn == 'Blank'
        ) {
          this.plantPrintWay = ['']
        }
        if (
          this.printingWaySelect.productPrintingPricingId === '' &&
          this.printingWaySelect.nameCn == '' &&
          this.printingWaySelect.nameEn == ''
        ) {
          this.plantPrintWay = []
        }
      },

      setEditPrintOptions() {
        this.pageType = 'add'
        let arr = _.cloneDeep(this.printList)

        arr.forEach((it) => {
          it.children = it.optionsVOS.map((item) => {
            return {
              ...item,
              label: `${item.pricingValue}-1${this.lang === 'zh' ? '位' : 'position'
                }`,
              labelEn: `${item.pricingValueEn}-1${this.lang === 'zh' ? '位' : 'position'
                }`,
              value: item.productPrintingPricingOptionsId,
            }
          })
        })

        this.printOptions = arr.map((item) => {
          return {
            ...item,
            children: item.children,
            label: item.nameCn,
            labelEn: item.nameEn,
            value: item.productPrintingPricingId,
          }
        })
        this.printOptions.push({
          label: 'Blank',
          labelEn: 'Blank',
          value: '',
        })
      },
      setAddPrintPrice(ids) {
        if (!this.printListAll || this.printListAll.length == 0) return
        // 找到选择的印刷方式对应的印刷单价  this.printOptions
        // 找到对应的印刷方式的信息
        if (this.printList.length == 0) {
          this.printList = _.cloneDeep(this.printListAll)
        }
        let obj_1 = this.printList.find((it) => {
          return it.productPrintingPricingId == ids[0]
        })
        // 统一设置
        if (obj_1?.pricingConfiguration == 0) {
          this.isNumberStep = false
          let obj_2 = obj_1.optionsVOS.find((it) => {
            return it.productPrintingPricingOptionsId === ids[1]
          })
          let priceList = obj_1.optionsVOS.map((it) => {
            return Number(it.price)
          })

          // 取最小价格
          // this.minPrintPrice = Math.min(...priceList)
          this.minPrintPrice = 0
          if (obj_2) {
            // this.sagePrintPrice = Number(obj_2.price)
          }
        }
        // 按数量阶梯
        if (obj_1?.pricingConfiguration == 1) {
          this.isNumberStep = true
        }
      },

      validateField() {
        let success = false
        if (
          (!this.printingWaySelect.nameCn || !this.printingWaySelect.nameEn) &&
          this.productType === '标准品'
        ) {
          success = false
          // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
          this.$nextTick(() => {
            elFormErrorScrollIntoView('.pro-price')
          })
          this.$baseMessage(
            this.$t('productDetail.PlatformPriceIsRequired'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          success = true
        }
        return {
          success,
          calcFreight: this.calcFreight ? 1 : 0,
          specsSelect: '',
          specsSelectEn: '',

          waySelectId: this.waySelect.productPrintingPricingOptionsId,
          waySelect: this.waySelect.pricingValue,
          waySelectEn: this.waySelect.pricingValueEn,

          printingWaySelect: this.printingWaySelect.nameCn,
          printingWaySelectEn: this.printingWaySelect.nameEn,
          printingWaySelectId:
            this.printingWaySelect.productPrintingWayId ||
            this.printingWaySelect.productPrintingPricingId,
          productPrintingPricingId:
            this.printingWaySelect.productPrintingWayId ||
            this.printingWaySelect.productPrintingPricingId,
        }
      },
      validateField2() {
        return {
          calcFreight: this.calcFreight ? 1 : 0,
          specsSelect: '',
          specsSelectEn: '',
          waySelectId: this.waySelect.productPrintingPricingOptionsId,
          waySelect: this.waySelect.pricingValue,
          waySelectEn: this.waySelect.pricingValueEn,
          printingWaySelect: this.printingWaySelect.nameCn,
          printingWaySelectEn: this.printingWaySelect.nameEn,
          printingWaySelectId:
            this.printingWaySelect.productPrintingWayId ||
            this.printingWaySelect.productPrintingPricingId,
          productPrintingPricingId:
            this.printingWaySelect.productPrintingWayId ||
            this.printingWaySelect.productPrintingPricingId,
        }
      },
    },
    beforeDestroy() {
      this.$Bus.$off('printWay')
      this.$Bus.$off('printList')
      this.$Bus.$off('sendPrintWay')
      this.$Bus.$off('bcglXiangXiList')
      this.$Bus.$off('extraSpecPrice')
      this.$Bus.$off('changePricingConfig')
      this.$Bus.$off('delPrintWayItem')
      this.$Bus.$off('addPrintWayItem')
      this.$Bus.$off('changePricingValue')
      this.$Bus.$off('setProductType')
      this.$Bus.$off('switchChange')
      this.$Bus.$off('changePriceScale')
    },
  }
</script>
<style lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }

    .content {
      height: 100%;
      border: 1px solid #e4e7ed;
      border-radius: 3px;

      .content-head {
        height: 50px;
        line-height: 50px;
        background-color: #f2f2f2;
        width: 100%;
        padding-left: 10px;
      }
    }

    .cascader-demo {
      .el-input__inner::placeholder {
        color: #606266;
      }
    }
  }
</style>