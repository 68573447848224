<template>
  <div>

    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <i class="iconfont icon-tag" style="margin-right: 10px; color: #606266"></i>
          {{ $t('myMail.Label') }}
        </template>
        <div class="mark-search-wrapper" v-if="maskList && maskList.length > 0">
          <el-input :placeholder="$t('myMail.searchMark')" clearable v-model.trim="search" size="mini">
          </el-input>
        </div>
        <el-tree class="filter-tree" :data="maskList" :indent="10" :filter-node-method="filterNode" ref="tree"
          :expand-on-click-node="false" @node-click="data => getDetail(data)"
          @node-expand="(data,node) => toggelExpand(data,node,'EXPAND')"
          @node-collapse="(data,node) => toggelExpand(data,node,'COLLAPSE')"
          :default-expanded-keys="expandMailLabels[emailAccount] || []" node-key="mailLabelId"
          :auto-expand-parent="false" :highlight-current="showCurrent" :current-node-key="currentMailLabelId">
          <div class="custom-tree-node label-item" slot-scope="{ node, data }" :id="data.mailLabelId + ''">
            <span class="label-mask" :style="styleObj(data.labelColor)"></span>
            <span class="label-ctx">{{ data.labelName }}</span>
          </div>
        </el-tree>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { BusOn } from '@/utils/eventBus'
  export default {
    name: 'LeftAsideMaskLabel',
    mixins: [BusOn],
    data() {
      return {
        activeName: '1',
        search: "",
        defaultProps: {
          children: 'children',
          label: 'labelName'
        },
        currentMailLabelId: '',
        showCurrent: true
      }
    },
    props: {
      maskList: {
        type: Array,
        default: () => [],
      },
      currentLabelId: {
        type: String,
        default: '',
      },
      mailAccountId: {
        type: String,
        default: '',
      },
      emailAccount: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapGetters({
        markSearchedKeyWords: 'mail/markSearchedKeyWords',
        expandMailLabels: 'mail/expandMailLabels',
      })
    },

    created() {
      this.search = this.markSearchedKeyWords[this.mailAccountId] || ''
      this.currentMailLabelId = this.$route.query.mailLabelId
    },

    watch: {
      search(val) {
        this.$store.commit('mail/setMarkSearchedKeyWords', {
          [this.mailAccountId]: val
        })
        this.$refs.tree.filter(val);
      }
    },
    methods: {
      searchAll(val) {

        this.showCurrent = false

      },
      toggelExpand(data, node, flag) {
        let mailLabelId = data.mailLabelId
        let nodeIds = this.expandMailLabels[this.emailAccount] || []
        if (flag === 'EXPAND') { // 展开节点
          nodeIds.push(mailLabelId)
        } else {
          // 过滤掉该节点
          nodeIds = nodeIds.filter(expandkey => expandkey !== mailLabelId)
        }

        this.$store.dispatch('mail/saveExpandMailLabels', {
          emailAccount: this.emailAccount,
          nodeIds,
          type: 0
        })
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.labelName.indexOf(value) !== -1;
      },
      styleObj(val) {
        let obj = {
          backgroundColor: val,
        }
        return obj
      },
      getDetail(item) {
        this.currentMailLabelId = item.mailLabelId
        this.$emit('getDetail', item, this.mailAccountId)
      },
      setView() {
        let node = document.getElementById(this.currentLabelId) // 通过Id获取到对应的dom元素
        if (node) {
          this.$nextTick(() => {
            node.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            }) // 通过scrollIntoView⽅法将对应的dom元素定位到可见区域【block: 'center'】这个属性是在垂直⽅向居中显⽰
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .label-item {
    display: flex;
    align-items: center;

    .label-mask {
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-right: 10px;
    }

    &.active,
    &:hover {
      cursor: pointer;
      background-color: #f5f7fa;
    }
  }

  ::v-deep {
    .el-tree {
      padding-bottom: 10px !important;
      margin-left: 15px;
    }
  }

  ::v-deep .el-tree-node__content {
    height: 26px !important;
  }

  ::v-deep {
    .el-collapse-item__header {
      padding-left: 21px !important;
    }

    .el-collapse-item__content {
      padding-bottom: 0px;
    }
  }

  .mark-search-wrapper {
    padding-left: 21px;
    padding-right: 8px;
    margin-bottom: 10px;
  }
</style>
