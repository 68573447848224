<template>
  <el-dialog
    :title="$t('orderList.ManualSettlement')"
    :visible.sync="manualSettleVisible"
    width="700px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form
        :model="rejectForm"
        :rules="rejectRules"
        ref="rejectForm"
        label-width="80px"
      >
        <el-form-item :label="$t('reqmsg.M2065')" prop="statusRemark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="100"
            show-word-limit
            v-model.trim="rejectForm.statusRemark"
            :placeholder="$t('placeholder.plsInput')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save" :loading="loading">
          提 交
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { OrderListInteractor } from '@/core/interactors/order/order-list'
  export default {
    props: {
      manualSettleVisible: {
        default: false,
        type: Boolean,
      },
      manualSettleId: {
        default: '',
        type: String,
      },
    },
    data() {
      return {
        rejectForm: {
          statusRemark: '', // 驳回原因
        },
        rejectRules: {
          statusRemark: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        loading: false,
      }
    },
    created() {},
    methods: {
      //关闭
      close() {
        this.$refs['rejectForm'].resetFields()
        this.$emit('update:manualSettleVisible', false)
        if (this.rejectForm.statusRemark) {
          // 数据未保存，是否离开
          this.$confirm(this.$t('reqmsg.M2064'), this.$t('reqmsg.M2005'), {
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            type: 'warning',
          }).then(() => {
            this.rejectForm.statusRemark = ''
          })
        }
      },

      // 点击确定
      save() {
        this.$refs['rejectForm'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            let params = {
              initiatingSettlementRemark: this.rejectForm.statusRemark,
              orderId: this.manualSettleId,
            }

            let res = await OrderListInteractor.setManualSettlement(
              params
            ).catch((err) => {
              this.loading = false
            })

            this.loading = false
            this.$emit('refreshList')
            this.$refs['rejectForm'].resetFields()
            this.$emit('update:manualSettleVisible', false)
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .text-hidden {
    width: 150px;
  }
</style>
