<template>
  <div
    :class="active ? 'receive-item active' : 'receive-item'"
    @click.stop="goPageList"
  >
    <div v-if="!defaultFlag">
      <i class="iconfont icon-folder-fill"></i>

      <el-tooltip
        v-if="!editFlag"
        class="item"
        effect="dark"
        :disabled="receiveConfig.name.length < 12"
        :content="receiveConfig.name"
        placement="top"
      >
        <div class="doc-item">
          <span class="doc-name">{{ receiveConfig.name }}</span>
          <span class="warning-text">
            {{
              receiveConfig.mailTotalCount
                ? `(${receiveConfig.mailTotalCount})`
                : ''
            }}
          </span>
        </div>
      </el-tooltip>
      <el-input
        ref="receiveValue"
        v-else
        size="mini"
        v-model="editValue"
        maxlength="20"
        show-word-limit
        @blur="onBlur"
      ></el-input>
    </div>

    <div v-else @click.stop="$emit('goReceive')">
      <i class="iconfont icon-folder-fill"></i>
      <div class="doc-item">
        <!-- <span class="doc-name">默认</span> -->
        <span class="doc-name">{{ $t('other.default') }}</span>
        <span class="warning-text">{{ accoutConfigNum }}</span>
      </div>
    </div>

    <div class="sub-btns" v-if="editFlag && !defaultFlag">
      <div>
        <i class="el-icon-success" @mousedown="handleSave"></i>
      </div>
      <div>
        <i class="el-icon-error" @mousedown="handleClear"></i>
      </div>
    </div>
    <div class="edit-btn" v-else-if="!editFlag && !defaultFlag">
      <i class="iconfont icon-lajitong1 warning" @click.stop="handleRemove"></i>
      <i class="iconfont icon-bi" @click.stop="handleEdit"></i>
    </div>
  </div>
</template>

<script>
import {
  updateReceiveCatagory,
  deleteReceiveCatagory,
  CanDeleteReceiveCatagory,
} from '@/api/mail/mail.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      resolve: false,
      editFlag: false,
      editValue: '',
    }
  },
  props: {
    accoutConfigNum: {
      type: [String, Number],
      default: '',
    },
    receiveConfig: {
      type: Object,
      default: null,
    },
    defaultFlag: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  methods: {
    handleSave() {
      this.resolve = true
      if (this.editValue) {
        updateReceiveCatagory({
          name: this.editValue,
          emailAccount: this.$store.state.mail.currentAccount,
          catagoryId: this.receiveConfig.catagoryId,
          parentId: this.receiveConfig.parentId,
          businessId: this.userInfo.userId,
        }).then((res) => {
          if (res.code == '000000') {
            this.$message({
              type: 'success',
              // message: '更新成功！',
              message: this.$t('reqmsg.$2'),
            })
            this.$emit('updateCatagory')
            this.editFlag = false
          }
          this.resolve = false
        })
      } else {
        this.$message({
          type: 'warning',
          // message: '请填写分类名称',
          message: this.$t('reqmsg.$57'),
        })
      }
    },
    handleRemove() {
      CanDeleteReceiveCatagory(this.receiveConfig.catagoryId).then((res) => {
        if (res.code == '000000') {
          // this.$confirm('是否确认删除？', '提示', {
          //   type: 'warning',
          // })
          this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
            type: 'warning',
          }).then(() => {
            deleteReceiveCatagory(this.receiveConfig.catagoryId).then((r) => {
              if (r.code == '000000') {
                this.$message({
                  type: 'success',
                  // message: '删除成功！',
                  message: this.$t('reqmsg.M2008'),
                })
                this.$emit('updateCatagory')
                this.editFlag = !this.editFlag
              }
            })
          })
        }
      })
    },
    handleEdit() {
      this.editValue = this.receiveConfig.name
      this.editFlag = !this.editFlag
      this.$nextTick(() => {
        this.$refs.receiveValue.focus()
      })
    },
    handleClear() {
      this.resolve = true
      this.editValue = ''
      this.editFlag = false
      this.$nextTick(function () {
        this.resolve = false
      })
    },
    onBlur() {
      if (!this.resolve) {
        // this.$confirm('此操作将放弃编辑, 是否继续?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // })
        this.$confirm(this.$t('reqmsg.$33'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('reqmsg.M2006'),
          cancelButtonText: this.$t('reqmsg.M2007'),
          type: 'warning',
        })
          .then(() => {
            this.editValue = ''
            this.editFlag = false
          })
          .catch(() => {
            this.$refs.receiveValue.focus()
          })
      }
    },

    goPageList() {
      if (!this.editFlag) {
        this.$emit('goReceive', this.receiveConfig)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.receive-item {
  text-indent: 0;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  box-sizing: border-box;
  height: 36px;
  & > div:first-child {
    flex: 1;
    line-height: 36px;
    font-size: 14px;
    color: #303030;
    cursor: pointer;
    display: flex;
    .iconfont {
      color: #999;
    }
    .doc-item {
      margin-left: 14px;
      text-indent: 0;
      width: 120px;
      display: flex;
      align-items: center;
      .doc-name {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .warning-text {
        color: #e02020;
        margin-left: 0;
      }
    }
    .el-input {
      margin-left: 14px;
    }
    /deep/ .el-input__suffix {
      right: 1px;
    }
    /deep/ .el-input__inner {
      height: 24px;
      padding: 0 2px !important;
    }
  }
  & > .edit-btn {
    flex-shrink: 0;
    display: none;
    font-size: 14px;
    i {
      &.warning {
        margin-right: 6px;
        color: #e02020;
      }
    }
  }
  &.active {
    background: #fff;
    & > div:first-child {
      color: #0486fe;
      .iconfont {
        color: inherit;
      }
    }
  }
  &:hover {
    background: #fff;
    & > div:first-child {
      color: #0486fe;
      .iconfont {
        color: inherit;
      }
    }
    & > .edit-btn {
      display: block;
      i {
        font-size: 10px;
      }
    }
  }
  .sub-btns {
    flex-shrink: 0;
    text-indent: 0;
    margin-left: 1px;
    display: flex;
    & > div {
      padding: 0 2px;
      width: 20px;
      i {
        line-height: 36px;
        font-size: 16px;
      }
    }
  }
}
</style>
