<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="800px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content" v-if="dialogFormVisible">
      <el-row type="flex" align="middle" class="floor">
        <el-col :span="6">
          <el-checkbox v-model="form.superior">所属组织负责人</el-checkbox>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-checkbox v-model="form.designatedPerson">指定人员</el-checkbox>
            <span
              @click="designatedChooseClick"
              class="ml10 c_pointer"
              style="color: #1890ff"
            >
              选择
            </span>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-checkbox v-model="form.optional">发起人自选</el-checkbox>
        </el-col>
      </el-row>
      <el-row v-if="form.designatedPerson || form.optional">
        <!-- 勾选指定人员才显示 -->
        <el-row class="floor floor2" v-if="form.designatedPerson">
          <el-row
            v-if="form.designatedPersonJson.length"
            class="tag-row-content"
          >
            <el-tag
              v-for="(tag, index) in form.designatedPersonJson"
              :key="tag.name"
              closable
              @close="designatedleClose(tag, index)"
            >
              {{ tag.name }}
            </el-tag>
            <span
              class="ml20 c_pointer"
              @click="clearDesignated"
              v-if="
                form.designatedPersonJson && form.designatedPersonJson.length
              "
            >
              清空
            </span>
          </el-row>
          <el-row v-else class="f_s_12" style="color: #f44336">
            还未选择指定人员...
          </el-row>
          <el-row class="mt15" v-if="form.designatedPersonJson.length">
            <el-radio
              v-model="form.designatedPersonEdit"
              :label="1"
              @click.native.prevent="onRadioChange(form.designatedPersonEdit)"
            >
              人员可编辑
            </el-radio>
          </el-row>
        </el-row>
        <!-- 勾选发起人自选才显示 -->
        <el-row class="floor floor3" v-if="form.optional">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-select v-model="form.optionalType" placeholder="请选择">
                <el-option
                  v-for="item in personNum"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-row type="flex" align="middle">
                <el-select
                  v-model="form.optionalRange"
                  placeholder="请选择范围"
                  @change="optionalRangeChange"
                >
                  <el-option
                    v-for="item in departmentRange"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span
                  v-if="optionalRangeBol"
                  @click="chooseOptionalRange"
                  class="base-color w_nowrap ml10 c_pointer"
                >
                  选择
                </span>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="mt20 tag-row-content">
            <el-tag
              v-for="(tag, index) in form.optionalPersonJson"
              :key="tag.name"
              closable
              @close="optionalHandleClose(tag, index)"
            >
              {{ tag.name }}
            </el-tag>
            <span
              class="ml20 c_pointer"
              @click="clearPptional"
              v-if="form.optionalPersonJson && form.optionalPersonJson.length"
            >
              清空
            </span>
          </el-row>
        </el-row>
        <el-row
          class="floor floor4"
          v-if="(form.optional || form.designatedPerson) && nodeType === 0"
        >
          <label>审核方式：</label>
          <el-radio-group v-model="form.auditMethod">
            <el-radio :label="0">或签（一名审核人同意/拒绝即可）</el-radio>
            <el-radio :label="2">会签（须所有审核人同意）</el-radio>
            <el-radio :label="1">依次审核</el-radio>
          </el-radio-group>
        </el-row>
      </el-row>
      <!-- <el-row v-else class="dialog-content flex_xc_yc">请先勾选...</el-row> -->
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>

    <!-- 组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 选择部门 -->
    <DepartmentChoose
      ref="DepartmentChoose"
      @choose-department="chooseDepartment"
    />
  </el-dialog>
</template>

<script>
import UserChoose from '@/components/userChoose'
import DepartmentChoose from '@/components/departmentChoose'
export default {
  components: {
    UserChoose,
    DepartmentChoose,
  },
  name: 'AuditNodeEditModel',
  data() {
    return {
      title: '人员设置',
      rules: {},
      dialogFormVisible: false,
      personNum: [
        {
          value: 0,
          label: '自选一个人',
        },
        {
          value: 1,
          label: '自选多个人',
        },
      ],
      departmentRange: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '指定部门',
        },
        {
          value: 2,
          label: '指定成员',
        },
      ],
      //---------------------------
      form: {
        auditMethod: 0, //审核方式,0.或签,1.依次审核,2.会签
        designatedPerson: false, //所属组织负责人,0,默认,1.选择
        designatedPersonEdit: 0, //指定人员可编辑按钮 0,默认,1.选择
        nodeSort: null,
        optional: false, //自选按钮 0,默认,1.选择
        optionalRange: 0, //自选范围 ,0.全选,1.部门,2.人员
        optionalType: 0, //自选类型0.单选,1.多选
        superior: false, //指定人员按钮 0,默认,1.选择
        optionalPersonJson: [], //自选人员json
        designatedPersonJson: [], //指定人员json
        isAdd: 'edit',
        auditType: null,
        nodeName: '',
        nodeType: 0,
      },
      currentIndex: 0, //当前编辑的index
      currentChooseStr: '',
      optionalRangeBol: false, // 发起人自选，选择按钮的bol
      flagEqual: false,
      nodeType: 0, //节点类型，0审核，1抄送
    }
  },
  created() {},
  methods: {
    //编辑
    showAddEdit(item, index) {
      let deepCopyItem = this.utils.deepCopy(item)
      if (item.isAdd) {
        this.form = deepCopyItem
        if (this.form.optionalRange !== 0) {
          this.optionalRangeBol = true
        }
      } else {
        this.form.superior = false //指定人员按钮 0,默认,1.选择
        this.form.designatedPerson = false //所属组织负责人,0,默认,1.选择
        this.form.designatedPersonEdit = 0 //指定人员可编辑按钮 0,默认,1.选择
        this.form.optional = false //自选按钮 0,默认,1.选择
        this.form.optionalType = 0 //自选类型0.单选,1.多选
        this.form.optionalRange = 0 //自选范围 ,0.全选,1.部门,2.人员
        this.form.auditMethod = 0 //审核方式,0.或签,1.依次审核,2.会签
        this.form.isAdd = 'edit' //审核方式,0.或签,1.依次审核,2.会签
        this.form.designatedPersonJson = []
        this.form.optionalPersonJson = []
        this.form.auditType = deepCopyItem.auditType
        this.form.nodeName = deepCopyItem.nodeName
        this.form.nodeType = deepCopyItem.nodeType
      }
      this.nodeType = deepCopyItem.nodeType
      this.currentIndex = index
      this.dialogFormVisible = true
    },

    //自选人员tag删除
    optionalHandleClose(tag, index) {
      this.form.optionalPersonJson.splice(index, 1)
    },
    //自选人员清空
    clearPptional() {
      this.form.optionalPersonJson = []
    },

    //指定人员tag删除
    designatedleClose(tag, index) {
      this.form.designatedPersonJson.splice(index, 1)
    },
    //清空指定人员
    clearDesignated() {
      this.form.designatedPersonJson = []
    },
    //指定人员选择(调组织架构树)
    designatedChooseClick() {
      this.form.designatedPerson = true
      this.currentChooseStr = 'designated'
      this.$refs['UserChoose'].showAddEdit(
        'designated',
        this.form.designatedPersonJson
      )
    },
    //人员可编辑
    onRadioChange(e) {
      if (e === 1) {
        this.form.designatedPersonEdit = 0
      } else {
        this.form.designatedPersonEdit = 1
      }
    },

    //自选范围下拉
    optionalRangeChange(val) {
      this.form.optionalPersonJson = []
      if (val !== 0) {
        this.optionalRangeBol = true
      } else {
        this.optionalRangeBol = false
      }
    },
    //自选范围下拉出现选择（调组2织架构树、1部门数）
    chooseOptionalRange() {
      if (this.form.optionalRange === 1) {
        this.$refs['DepartmentChoose'].showAddEdit(this.form.optionalPersonJson)
      } else {
        this.currentChooseStr = 'Optional'
        this.$refs['UserChoose'].showAddEdit(
          'Optional',
          this.form.optionalPersonJson
        )
      }

      if (
        this.form.optionalPersonJson.length &&
        this.form.designatedPersonJson.length
      ) {
        this.form.optionalPersonJson.forEach((element) => {
          this.form.designatedPersonJson.forEach((element2) => {
            if (element.id === element2.id) {
              this.flagEqual = true
            } else {
              this.flagEqual = false
            }
          })
        })
        if (this.flagEqual) {
          return this.$baseMessage(
            '同一节点审核人员重复！',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      }
    },

    //部门数返回数据
    chooseDepartment(arrDeptId) {
      this.form.optionalPersonJson = arrDeptId
    },

    //组织架构树返回数据（分指定和自选）
    chooseUser(checkedUser) {
      let mapArr = checkedUser.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      })
      if (this.currentChooseStr === 'designated') {
        this.form.designatedPersonJson = mapArr
      } else if (this.currentChooseStr === 'Optional') {
        this.form.optionalPersonJson = mapArr
      }
    },

    //保存
    save() {
      //let form = JSON.parse(JSON.stringify(this.form))
      //判断所属组织负责人（superior），指定人员（designatedPerson），发起人自选（optional）
      if (
        !this.form.superior &&
        !this.form.designatedPerson && !this.form.optional
      ) {
        return this.$baseMessage(
          '还未选择审核人员/部门',
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      if (
        this.form.designatedPerson &&
        !this.form.designatedPersonJson.length
      ) {
        return this.$baseMessage(
          '请选择指定人员',
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      if (this.form.optional) {
        if (this.form.optionalRange !== 0) {
          if (
            this.form.optionalRange === 1 &&
            !this.form.optionalPersonJson.length
          ) {
            return this.$baseMessage(
              '请选择指定部门',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
          if (
            this.form.optionalRange === 2 &&
            !this.form.optionalPersonJson.length
          ) {
            return this.$baseMessage(
              '请选择指定人员',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
        }
      }
      if (
        this.form.optionalPersonJson.length &&
        this.form.designatedPersonJson.length
      ) {
        this.form.optionalPersonJson.forEach((element) => {
          this.form.designatedPersonJson.forEach((element2) => {
            if (element.id === element2.id) {
              this.flagEqual = true
            } else {
              this.flagEqual = false
            }
          })
        })
        if (this.flagEqual) {
          return this.$baseMessage(
            '同一节点审核人员重复！',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      }
      let test = this.utils.deepCopy(this.form)
      this.$emit('node-obj', test, this.currentIndex)
      this.close()
    },

    close() {
      this.form = {
        auditMethod: 0,
        designatedPerson: false,
        designatedPersonEdit: 0,
        nodeSort: null,
        optional: false,
        optionalRange: '',
        optionalType: '',
        superior: false,
        optionalPersonJson: [],
        designatedPersonJson: [],
        isAdd: 'edit',
      }
      this.currentIndex = 0
      this.currentChooseStr = ''
      this.optionalRangeBol = false
      this.dialogFormVisible = false
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  min-height: 300px;
  .floor2,
  .floor3,
  .floor4 {
    margin: 25px 0;
    padding-top: 25px;
    border-top: 1px solid #ededed;
  }
}
.tag-row-content {
  margin-left: -10px;
  .el-tag {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>
