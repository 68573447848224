<template>
  <el-dialog
    class="plan-order"
    title="设置Marks"
    :visible.sync="marksVisible"
    v-if="marksVisible"
    @before-close="close"
    width="800px"
  >
    <div class="container">
      <p class="mb10">选择打印Marks时，需要展示的字段</p>
      <el-checkbox-group @change="defaultChange" v-model="checkList">
        <el-checkbox label="Item"></el-checkbox>
        <el-checkbox label="客户订单号"></el-checkbox>
        <el-checkbox label="Ship"></el-checkbox>
        <el-checkbox label="Made in China"></el-checkbox>
      </el-checkbox-group>
      <div class="mt10 mb10">
        <el-button @click="add" icon="el-icon-plus">添加自定义字段</el-button>
      </div>
      <el-form :model="form" ref="ruleForm">
        <table class="custom-table no-space">
          <thead>
            <tr>
              <th width="60">
                <input
                  type="checkbox"
                  ref="checkboxAll"
                  class="pointer"
                  @click="checkAll"
                />
              </th>
              <th width="300">
                <span>
                  <i>*</i>
                  前缀
                </span>
              </th>
              <th width="300">后缀</th>
              <th width="80">操作</th>
            </tr>
          </thead>
          <tbody id="sort">
            <tr v-for="(item, i) in form.orderMarkDTOS" :key="i">
              <td>
                <input
                  ref="checkbox"
                  type="checkbox"
                  class="pointer"
                  :sign="item.markPrefix"
                  :checked="item.checkFlag == 1"
                  @click="checkChange(i, item.markPrefix)"
                  :disabled="item.markPrefix === 'PI'"
                />
              </td>
              <td>
                <div v-if="item.templateFlag == 1">{{ item.markPrefix }}</div>
                <div v-else>
                  <el-form-item
                    :prop="'orderMarkDTOS.' + i + '.markPrefix'"
                    :rules="[
                      {
                        required: true,
                        message: '前缀必填',
                        trigger: 'blur',
                      },
                      {
                        max: 50,
                        message: '字符长度不能超过50',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      placeholder="请输入"
                      v-model="form.orderMarkDTOS[i].markPrefix"
                    ></el-input>
                  </el-form-item>
                </div>
              </td>
              <td>
                <div v-if="item.templateFlag == 1">{{ item.markSuffix }}</div>
                <div v-else>
                  <el-form-item
                    :prop="'orderMarkDTOS.' + i + '.markSuffix'"
                    :rules="[
                      {
                        max: 50,
                        message: '字符长度不能超过50',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      placeholder="请输入"
                      v-model="form.orderMarkDTOS[i].markSuffix"
                    ></el-input>
                  </el-form-item>
                </div>
              </td>
              <td>
                <el-button
                  class="icon-r"
                  v-show="!item.templateFlag"
                  @click="del(i)"
                  size="mini"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
      <p class="mt10">Tips：打印前 前缀和后缀之间可以手动输入内容</p>
    </div>
    <div class="text-center mt20">
      <el-button type="primary" @click="save()" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Sortable from 'sortablejs'
import { orderShowMark, orderInsertMark } from '@/api/order.js'
export default {
  data() {
    return {
      orderId: '',
      form: {
        orderMarkDTOS: '',
      },
      checkList: [],
      marksVisible: false,
      isLoading: false,
      sortable: null,
    }
  },
  created() {},
  methods: {
    // 回显marks
    initLoading(id) {
      let self = this
      self.orderId = id
      self.checkList = []
      orderShowMark({ orderId: id }).then((res) => {
        self.form.orderMarkDTOS = res.data
        self.form.orderMarkDTOS.forEach((item) => {
          if (item.checkFlag == 1) {
            self.checkList.push(item.markPrefix)
          }
        })
        self.marksVisible = true
        setTimeout(() => {
          self.isCheckboxAll()
          self.setSort()
        }, 0)
      })
    },

    // 拖拽
    setSort() {
      const el = document.getElementById('sort')
      this.sortable = Sortable.create(el, {
        setData: function (dataTransfer) {},
        onEnd: (evt) => {},
      })
    },

    // 监听是否为全选
    isCheckboxAll() {
      let self = this
      const flag = self.form.orderMarkDTOS.every((item) => item.checkFlag == 1)
      if (flag) {
        self.$refs.checkboxAll.checked = true
      } else {
        self.$refs.checkboxAll.checked = false
      }
    },

    // 全选全不选
    checkAll() {
      let self = this
      const flag = self.form.orderMarkDTOS.every((item) => item.checkFlag == 1)
      if (flag) {
        self.$refs.checkbox.forEach((item, i) => {
          if(item.getAttribute('sign') != 'PI') {
            self.$refs.checkbox[i].checked = false
            self.form.orderMarkDTOS[i].checkFlag = 0
            self.checkList = []
          }          
        })
      } else {
        self.$refs.checkbox.forEach((item, i) => {
          self.$refs.checkbox[i].checked = true
          self.form.orderMarkDTOS[i].checkFlag = 1
          self.checkList = ['Item', '客户订单号', 'Ship', 'Made in China']
        })
      }
    },

    // 默认展示字段选中事件
    defaultChange(val) {
      let self = this
      self.form.orderMarkDTOS.forEach((item, i) => {
        if (val.includes(item.markPrefix)) {
          self.$refs.checkbox[i].checked = true
          self.form.orderMarkDTOS[i].checkFlag = 1
        } else {
          self.$refs.checkbox[i].checked = false
          self.form.orderMarkDTOS[i].checkFlag = 0
        }
      })
    },

    // checkbox选中事件
    checkChange(i, name) {
      let self = this
      let checked = self.$refs.checkbox[i].checked
      if (checked) {
        self.form.orderMarkDTOS[i].checkFlag = 1
        self.checkList.push(name)
      } else {
        self.form.orderMarkDTOS[i].checkFlag = 0
        self.checkList.forEach((item, k) => {
          if (item == name) {
            self.checkList.splice(k, 1)
          }
        })
      }
      self.isCheckboxAll()
    },

    // 添加自定义字段
    add() {
      const list = {
        checkFlag: 0,
        markPrefix: '',
        markSuffix: '',
        marksId: null,
        orderId: null,
        orderNum: '',
        orderType: 1,
      }
      this.form.orderMarkDTOS.push(list)
      this.$refs.checkboxAll.checked = false
    },

    // 哈希判断是否存在重复元素
    isRepeat(arr) {
      let hash = {}
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true
        }
        hash[arr[i]] = true
      }
      return false
    },

    // 重新排序
    sort() {
      let self = this
      const el = document.getElementById('sort')
      const trList = el.getElementsByTagName('tr')
      trList.forEach((item, i) => {
        let td = item.getElementsByTagName('td')[1]
        let text = td.innerText || td.getElementsByTagName('input')[0].value
        self.form.orderMarkDTOS.forEach((item, k) => {
          if (item.markPrefix == text) {
            self.form.orderMarkDTOS[k].orderNum = i
          }
        })
      })
    },

    // 保存
    save() {
      let self = this
      self.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const arr = []
          self.form.orderMarkDTOS.forEach((item) => arr.push(item.markPrefix))
          if (self.isRepeat(arr)) {
            self.$message.warning('添加字段前缀不能重复！')
            return false
          }
          self.isLoading = true
          // 修改排序
          self.sort()
          const params = {
            orderId: self.orderId,
            orderMarkDTOS: self.form.orderMarkDTOS,
          }
          orderInsertMark(params).then((res) => {
            if (res && res.code == '000000') {
              self.$message.success('保存成功')
              self.$parent.getList()
              self.isLoading = false
              self.marksVisible = false
            }
          })
        }
      })
    },

    // 删除
    del(i) {
      this.$confirm(`是否确认删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.form.orderMarkDTOS.splice(i, 1)
          this.$message.success('删除成功！')
          this.isCheckboxAll()
        })
        .catch(() => {})
    },

    // 关闭弹窗
    close() {
      this.isLoading = false
      this.marksVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
