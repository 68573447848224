import { shippingOrderPurchase } from '@/api/stock-center/shipment'
import { deepCopy } from 'kits'
export const mixin = {
  data() {
    return {
      drawbackVisible: false, // 是否退税提示弹框显示隐藏
      focusLock: true, // 默认为true，第一次聚焦后变为false
      prevShippingOrderProductLotDTOList: [], // 出运数量改变前的入库批次
      drawbackIndex: '', // 当前操作的是否退税的下标
      curChangeDrawbackIndex: '', // 下标，表示是哪条数据在进行是否退税change
    }
  },
  methods: {
    getRecommendLotDTOs(
      index,
      productArranges,
      lotKey = 'shippingOrderProductLotDTOList'
    ) {
      productArranges = productArranges.slice()
      productArranges.splice(index, 1)
      const recommendLotDTOs = []
      productArranges.forEach((item) => {
        recommendLotDTOs.push(...item[lotKey])
      })
      return recommendLotDTOs.map((item) => {
        return {
          stockLotId: item.stockLotId,
          lotNo: item.lotNo,
          num: item.num,
        }
      })
    },
    columnCheck(tableData, checkFields) {
      if (!Array.isArray(tableData)) {
        return false
      }
      const keys = Reflect.ownKeys(checkFields)
      for (let i = 0; i < keys.length; i++) {
        let columnData = tableData.map((item) => item[keys[i]])
        if (columnData.every((column) => +column === 0)) {
          this.$message.warning(checkFields[keys[i]])
          return false
        }
      }
      return true
    },
    // 是否退税逻辑优化-start
    /*
      feature-V2.2.4-shipPurchase版本逻辑梳理（产品：王晓峰，后端：张洋洋）：
        1、是否退税字段前面添加一列：买方
        2、初始化时
            a.如果出库仓库默认有值，则根据入库批次id请求接口shippingOrderPurchase，如果返回的fromSale为1，需要回显买方和是否退税；否则不回显
            b.未选择出库仓库，是否退税为禁用状态，选填；选了出库仓库，是否退税为可选状态，必填
        3、入库批次发生变化时，请求接口shippingOrderPurchase
            a.选择出库仓库
            b.改变出运数量
            c.批次选择抽屉中，点击提交按钮
        4、是否退税change
            a.如果此行数据是【按需采购】的批次（fromSale==1）
              则弹框提示，点击确定按钮，直接关闭弹框；点击取消按钮，恢复成原来的值
              修改后，在select组件后面加上感叹号icon，鼠标移入给出提示
            b.如果此行数据是【非按需采购】的批次，没有弹框提示，没有感叹号icon
            c.批量设置：原来是批量设置所有的项，现在改为：如果是否退税没有值，并且不是禁用状态，则需要批量设置

    */
    // 根据入库批次号查询是否退税及买方
    async shippingOrderPurchase(list = [], index, type = 1) {
      if (list.length) {
        // 如果批次发生改变，点击提交时需要调一次接口重新回显买方和是否退税
        const curProductArranges = this.getCurProductArranges(type)
        const bool = this.getBlurByPrevAndCur(
          this.prevShippingOrderProductLotDTOList,
          curProductArranges[index].shippingOrderProductLotDTOList
        ) // blur为true表示批次一样，为false表示批次不一样
        if (bool) return
        // 收集入库批次数量大于0的批次号
        const lotNos = list
          .flatMap((n) => (n.lotNumber > 0 ? n.lotNo : []))
          .join()
        try {
          const { code, data } = await shippingOrderPurchase(lotNos)
          if (code === '000000') {
            // purchaser：买方 drawback：是否退税 fromSale：是否按需采购，1=是 0=否
            const { purchaser, drawback, fromSale } = data
            const item = curProductArranges[index]
            item.fromSale = fromSale
            if (fromSale == 1) {
              // 回显买方
              this.$set(item, 'purchaser', purchaser || '')
              // 回显是否退税
              this.$set(item, 'drawback', drawback || '')
              this.$set(item, 'prevDrawback', drawback || '')
            } else {
              this.$set(item, 'purchaser', '')
              this.$set(item, 'drawback', '')
              this.$set(item, 'prevDrawback', '')
            }
            // 只要选了出库仓库，就一定会有入库批次，是否退税为可选状态
            this.$set(item, 'drawbackDisabled', false)
            // 隐藏红色感叹号提示
            this.$set(item, 'showTip', false)
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        this.setEmptyByIndex(index, type)
      }
    },
    // 将买方和是否退税置空，并且是否退税为禁用状态
    setEmptyByIndex(index, type) {
      const curProductArranges = this.getCurProductArranges(type)
      const item = curProductArranges[index]
      this.$set(item, 'purchaser', '')
      this.$set(item, 'drawback', '')
      this.$set(item, 'drawbackDisabled', true)
      this.$set(item, 'showTip', false)
    },
    // 仓库下拉框出现/隐藏时触发
    whVisibleChange(bool, index, type = 1) {
      if (bool) {
        const curProductArranges = this.getCurProductArranges(type) 
        this.prevShippingOrderProductLotDTOList = deepCopy(
          curProductArranges[index].shippingOrderProductLotDTOList
        )
      }
    },
    // 出运数量聚焦-仅创建委托单页面用到
    shippedNumfocus(index) {
      // 出运数量聚焦时，存储当前的入库批次
      this.prevShippingOrderProductLotDTOList = deepCopy(
        this.form.productArranges[index].shippingOrderProductLotDTOList
      )
    },
    // 是否退税change
    changeDrawback(isDrawback, index, type) {
      this.curChangeDrawbackIndex = index
      const curProductArranges = this.getCurProductArranges(type)
      const item = curProductArranges[index] // 编辑后的这一行数据
      // 如果值发生变化
      if (item?.drawback != item?.prevDrawback) {
        item.showTip = true
        // 按需采购需要弹框提示
        if (item?.fromSale == 1) {
          this.drawbackVisible = true
        }
      } else {
        item.showTip = false
      }

      // 批量设置
      if (this.batchSetted) return
      this.batchSetted = true
      curProductArranges.map((item) => {
        // 如果是否退税没有值，并且不是禁用状态，则需要批量设置
        if (!item.drawback && !item.drawbackDisabled) {
          item.drawback = isDrawback
        }
        return item
      })
    },
    // 是否退税提示弹框-取消
    handleDrawbackCancle(type) {
      const curProductArranges = this.getCurProductArranges(type)
      const item = curProductArranges[this.curChangeDrawbackIndex]
      if (item.drawback != item.prevDrawback) {
        item.drawback = item.prevDrawback
        item.showTip = !item.showTip
      }
      this.drawbackVisible = false
    },
    // 是否退税提示弹框-确定
    handleDrawbackConfirm() {
      this.drawbackVisible = false
    },
    // 传入上一次和当前的入库批次，判断2个数组是否相等
    getBlurByPrevAndCur(prevList = [], curList = []) {
      if (prevList.length !== curList.length) return false
      const prevLotNos = prevList.map((n) => n.lotNo).sort()
      const curLotNos = curList.map((n) => n.lotNo).sort()
      return JSON.stringify(prevLotNos) === JSON.stringify(curLotNos)
    },
    // 根据type值获取curProductArranges
    getCurProductArranges(type) {
      // 创新委托单页面传1取的是this.form.productArranges，出库申请弹框传2取的是this.applyForm.applyList
      return type == 1
        ? this?.form?.productArranges
        : this?.applyForm?.applyList
    },
    // 是否退税逻辑优化-end
  },
}
