<template>
  <div ref="baseTable" class="base-table">
    <div class="table-custom">
      <el-table
        ref="table"
        v-loading="isRequesting"
        border
        :cell-class-name="cellClass"
        :cell-style="{ padding: '5px 0' }"
        :class="[tableClass]"
        :data="tableData ? tableData : []"
        :header-cell-style="headerClass"
        :row-style="{ height: '26px' }"
        :height="maxHeight"
        style="width: 100%; font-size: 12px"
        header-cell-class-name="base-table-header"
        :default-sort="defaultSort"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <slot></slot>
        <template slot="empty">
          <span style="color: #969799">暂无数据...</span>
        </template>
      </el-table>
    </div>
    <div v-if="pagination" class="page-custom">
      <el-pagination
        background
        :current-page="pageNumber"
        layout="total, sizes,  prev, pager, next, jumper"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
        :total="total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BaseTable',
    props: {
      // 当前页
      pageNumber: {
        default: 1,
        type: Number,
      },
      // 数据总条数
      total: {
        default: 20,
        type: Number,
      },
      pageSize: {
        default: 20,
        type: Number,
      },
      tableData: {
        default: () => [],
        type: Array,
      },
      isRequesting: {
        default: false,
        type: Boolean,
      },
      // 是否带有分页器
      pagination: {
        type: Boolean,
        default: true,
      },
      defaultSort: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        maxHeight: 0,
        tableClass: '',
      }
    },
    watch: {
      tableData: {
        handler: function () {
          this.initTableStyle()
        },
        immediate: true,
      },
    },
    mounted() {
      window.addEventListener('resize', this.resizeEvent)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeEvent)
    },
    methods: {
      initTableStyle() {
        this.$nextTick(() => {
          const baseTableEle = this.$refs['baseTable']
          // 有分页则减去分页器的高度
          const maxHeight = this.pagination
            ? baseTableEle?.offsetHeight - 58
            : baseTableEle?.offsetHeight
          this.maxHeight = maxHeight
        })
      },
      sizeChange(val) {
        this.$emit('update:pageSize', val)
        this.$emit('fetchData')
      },
      pageChange(val) {
        this.$emit('update:pageNumber', val)
        this.$emit('fetchData')
      },
      headerClass() {
        return 'height:37px !important;padding:1px;'
      },
      // 表格多选
      handleSelectionChange(rows) {
        this.$emit('selection-change', rows)
      },
      resizeEvent() {
        this.initTableStyle()
      },
      cellClass() {
        return 'border'
      },
      sortChange(rows) {
        this.$emit('sort-change', rows)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .base-table {
    height: 100%;
    .table-custom {
      height: calc(100% - 52px);
    }
    .page-custom {
      height: 52px;
      overflow: hidden;
    }
    ::v-deep {
      .el-pagination {
        margin: 10px 0;
        display: flex;
        justify-content: center;
      }
      .el-table__row {
        height: 45px !important;
      }
      // .el-table {
      //   border-left: none;
      // }
      // .base-table-header {
      //   .cell {
      //     white-space: nowrap;
      //     text-overflow: ellipsis;
      //     overflow: hidden;
      //   }
      // }
      // .no-border {
      //   border-right: 1px solid transparent !important;
      // }

      // 固定列底部边线去除
      .el-table__fixed::before,
      .el-table__fixed-right::before {
        height: 0;
      }
    }
  }
</style>
