/**
 * element-ui 组件公共处理
 */

import { dayGetTime } from 'kits'
// 日期选择器公共配置
export function getCommonPickOptions() {
  return {
    shortcuts: [
      {
        text: '最近一周',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(dayGetTime(7))
          picker.$emit('pick', [start, end])
        },
      },
      {
        text: '最近一个月',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(dayGetTime(30))
          picker.$emit('pick', [start, end])
        },
      },
      {
        text: '最近三个月',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(dayGetTime(90))
          picker.$emit('pick', [start, end])
        },
      },
    ],
  }
}

// 获取表格高度mixin
export const tableHeightMixin = {
  data() {
    return {
      tableHeight: 400,
    }
  },
  methods: {
    getTableHeight() {
      setTimeout(() => {
        const wrapperH = document
          .querySelector('.custom-container')
          ?.getBoundingClientRect()?.height

        const otherH =
          document.querySelector('.search-form')?.getBoundingClientRect()
            ?.height + this.extraHeight
        this.tableHeight = wrapperH - otherH
      }, 200)
    },
  },
  mounted() {
    this.getTableHeight()
    this.$Bus.$on('form-trigger', () => {
      this.getTableHeight()
    })
  },
}
