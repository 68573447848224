v<!--
  功能：库存溢出比表单
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月20日 14:29:26
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    label-width="50px"
    label-position="top"
  >
    <el-form-item label="规则设置:" prop="value">
      <el-radio-group
        v-model="form.value"
        style="display: flex; flex-direction: column"
      >
        <el-radio :label="1" class="mt10">手动设置</el-radio>
        <p class="p-tip">每次进行出库操作时，手动选择出库库位</p>
        <el-radio :label="2">库位数量从多到少扣减</el-radio>
        <p class="p-tip">产品若放置多个库位，优先从多的库存开始依次扣减</p>
        <el-radio :label="3">库位数量从少到多扣减</el-radio>
        <p class="p-tip">产品若放置多个库位，优先从少的库存开始依次扣减</p>
        <el-radio :label="4" disabled>先进先出扣减（开发中）</el-radio>
        <p class="p-tip p-tip-disabled">先入库的产品先进行扣减</p>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="启用仓库" prop="wharehouseList">
      <el-select
        placeholder="请选择"
        v-model="form.wharehouseList"
        class="w100"
        multiple
        collapse-tags
        @change="wharehouseListChange"
      >
        <el-option
          :label="item.name"
          :value="item.warehouseId"
          v-for="item in whs"
          :key="item.warehouseId"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'InventoryLocktime',
  props: {
    whs: {
      type: Array,
      default: () => {},
    },
  },
  components: {},
  // 组件状态值
  data() {
    return {
      form: {
        type: 4,
        value: 1,
        wharehouseList: [],
      },
      warehouseSettingWarehouseDTOS: [],
      rules: {
        value: [
          {
            required: true,
            message: '请选择',
          },
        ],

        wharehouseList: [
          {
            required: true,
            message: '请选择启用仓库',
          },
          {
            validator: (rule, value, callback) => {
              if (!value || value.length === 0) {
                callback(new Error('请选择启用仓库'))
              } else {
                callback()
              }
            },
            trigger: [''],
          },
        ],
      },
    }
  },

  methods: {
    // 重置
    resetFields() {
      this.form = {
        type: 4,
        value: 1,
        wharehouseList: [],
      }
    },

    //wharehouseListChange
    wharehouseListChange(val) {
      let warehouseArr = val
      let warehouseSettingWarehouseDTOS = []
      warehouseArr.forEach((item, index) => {
        this.whs.forEach((item2, index2) => {
          if (item === item2.warehouseId) {
            let obj = {
              warehouseId: item2.warehouseId,
              warehouseName: item2.name,
            }
            warehouseSettingWarehouseDTOS.push(obj)
          }
        })
      })
      this.warehouseSettingWarehouseDTOS = warehouseSettingWarehouseDTOS
    },

    //保存
    validate() {
      return new Promise((resove, reject) => {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            //编辑
            if (this.form.warehouseSettingId) {
              if (
                this.warehouseSettingWarehouseDTOS &&
                this.warehouseSettingWarehouseDTOS.length
              ) {
                this.form.wharehouseList = this.warehouseSettingWarehouseDTOS
              } else {
                this.form.wharehouseList =
                  this.form.warehouseSettingWarehouseShowVOList
                delete this.form.warehouseSettingWarehouseShowVOList
              }
            } else {
              //新增
              this.form.wharehouseList = this.warehouseSettingWarehouseDTOS
            }
            resove({ ...this.form })
          } else {
            reject()
          }
        })
      })
    },
  },

  created() {},

  mounted() {},
}
</script>

<style scoped lang="scss">
.form {
  .w100 {
    width: 100%;
  }
  .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio {
      margin-bottom: 10px;
    }
  }
}
.p-tip {
  font-size: 12px;
  color: #909399;
  margin-bottom: 25px;
  margin-top: 5px;
}
.p-tip-disabled {
  color: #c0c4cc !important;
}
</style>
