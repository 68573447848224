<!--
  功能：订单跟踪
  作者：jiangyongqiang
  时间：2021年11月16日 20:20:50
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard
    title="订单跟踪"
    @tab-click="handleTabClick"
    :tabs="tabs"
    :singleNum="singleNum"
    :teamNum="teamNum"
    :loading="loading"
  >
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem class="unread-mails-item">
          <div
            :key="index"
            class="content"
            @click="clientFollowClick(item.data)"
            :style="itemStyleObj"
          >
            <div class="mails-item--top">
              <p v-if="item.data.agencyType == 5 || item.data.agencyType == 4">
                订单跟踪编号：
                <span
                  class="page-link noline"
                  @click="showTrackDetail(item.data)"
                >
                  {{ item.data.orderTrackingNumber }}
                </span>
                节点【
                <span>
                  {{ item.data.nodeNameCn }}
                </span>
                】 预计时间: {{ item.data.dueDate }}
              </p>
              <p v-else-if="item.data.agencyType == 2">
                订单跟踪编号：
                <span
                  class="page-link noline"
                  @click="showTrackDetail(item.data)"
                >
                  {{ item.data.orderTrackingNumber }}
                </span>
                节点【
                <span>
                  {{ item.data.nodeNameCn }}
                </span>
                】 预计时间: {{ item.data.dueDate }}
              </p>
              <p v-else>
                订单跟踪编号：
                <span
                  class="page-link noline"
                  @click="showTrackDetail(item.data)"
                >
                  {{ item.data.orderTrackingNumber }}
                </span>
                已经超过 {{ item.data.createTrackingTickTime }} 小时未处理；
              </p>
            </div>
            <div class="mails-item--middle">
              <p class="item">
                <span class="label">订单支持:</span>
                <span class="content">
                  {{ item.data.fromBusinessName || '--' }}
                </span>
              </p>
              <p class="item">
                <span class="label">采购员:</span>
                <span class="content">{{ item.data.buyerName || '--' }}</span>
              </p>
              <p class="item">
                <span class="label">客户代表:</span>
                <span class="content">
                  {{ item.data.businessName || '--' }}
                </span>
              </p>
            </div>
            <div class="mails-item--bottom">
              <span v-if="item.data.agencyType == 5">
                发生预警,请及时处理！
              </span>
              <span v-else-if="item.data.agencyType == 2">
                发生延期,请及时处理！
              </span>
              <span v-else>请及时处理！</span>
              ,
              <span class="time">{{ item.createTime }}</span>
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import { HomeInteractor } from '@/core'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  export default {
    name: 'UnreadMails',
    mixins: [mixins],
    props: {},
    components: {},
    // 组件状态值
    data() {
      return {
        list: [],
        tabs: false,
      }
    },
    computed: {},

    methods: {
      getList() {
        this.loading = true
        const { userId, tenantId } = this.userInfo
        HomeInteractor.orderTrackListTodo({
          messageType: 1,
          handle: 1,
          userId,
          tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            if (!res.data) {
              this.list = []
            } else {
              this.list = res.data.map((item) => {
                item.data = JSON.parse(item.data)
                return item
              })
            }
          } else {
            this.list = []
          }
          this.loading = false
        })
      },

      clientFollowClick({ orderTrackingId }) {
        navigateTo(this, {
          pageType: PAGE_TYPES.trackUpdate,
          roleJudge: false,
          orderTrackingId,
        })
      },

      showTrackDetail({ orderTrackingId }) {
        navigateTo(this, {
          pageType: PAGE_TYPES.trackDetail,
          roleJudge: false,
          orderTrackingId,
        })
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .unread-mails-item {
    font-size: 14px;
    .mails-item--top {
      margin-bottom: 6px;
      word-break: break-all;
      color: #000;
    }
    .mails-item--middle {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      p {
        color: #5e5e5e;
        font-size: 12px;
      }
    }
    .mails-item--bottom {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      margin-top: 5px;
    }
    .noline {
      text-decoration: none;
    }
  }
</style>
