<template>
  <el-dialog
    title="上传印刷文件"
    :visible.sync="visible"
    :before-close="handleClose"
    width="815px"
  >
    <ErpUpload
      ref="ErpUpload"
      :uploadParams="agreeUploadParams"
      @uploadList="agreeUploadList"
      @initUpload="initUpload"
    ></ErpUpload>
    <el-row slot="footer" class="dialog-footer text-center">
      <el-button type="primary" @click="confirmChoose">确认</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  name: 'chooseImages',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 默认回显图片
    defaultFileList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageList: [],
      agreeUploadParams: {
        amount: 1,
        size: 1024 * 1024 * 20, //单个文件上传大小
        format: 'jpg、png、pdf、cdr、ai、wmf、eps', //支持格式
      },
      agreementFile: '',
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.initUpload(this.defaultFileList)
      }
    },
  },
  methods: {
    // 处理协议信息附件上传
    agreeUploadList(fileList) {
      this.agreementFile = JSON.stringify(fileList)
    },
    // 附件回显
    initUpload(data) {
      this.$refs.ErpUpload.initUpload(data)
    },

    // 关闭 dialog
    handleClose() {
      this.$emit('update:visible', false)
    },

    // 确认
    confirmChoose() {
      this.handleClose()
      this.$emit('confirm', this.agreementFile)
    },
  },
}
</script>
<style scoped lang="scss">
.wrapper-status {
  margin-top: 10px;
}
</style>
