<!--入库单详情-->
<template>
  <div class="table-container">
    <p class="title">
      <span class="header mr15">
        入库单号:
        <span class="ml15">{{ detail.warehouseInNumber }}</span>
      </span>
      <el-tag v-if="detail.adjustStatus == 1" type="scuucess">已调整</el-tag>
      <el-tag v-else-if="detail.adjustStatus == 2" type="info">调整中</el-tag>
      <span v-else></span>
    </p>

    <div class="detail-fields">
      <el-row :gutter="24" class="mt20 detail-block">
        <el-col
          :span="6"
          v-if="typeIsCg || typeIsTh || typeIsQt"
          class="detail-item"
        >
          <span class="detail-label">入库方式</span>
          <span class="detail-content">{{ detail.type | inBoundType }}</span>
        </el-col>
        <el-col :span="6" v-if="typeIsTh" class="detail-item">
          <span class="detail-label">订单编号</span>
          <span class="detail-content">
            {{ detail.orderNumber ? detail.orderNumber : '--' }}
          </span>
        </el-col>
        <el-col :span="6" v-if="typeIsCg" class="detail-item">
          <span class="detail-label">采购单号</span>
          <span class="detail-content">
            {{ detail.orderNumber ? detail.orderNumber : '--' }}
          </span>
        </el-col>
        <el-col :span="6" v-if="typeIsCg" class="detail-item">
          <span class="detail-label">供应商名称</span>
          <span class="detail-content">
            {{ detail.supplierName ? detail.supplierName : '--' }}
          </span>
        </el-col>
        <el-col
          :span="6"
          v-if="typeIsCg || typeIsTh || typeIsQt"
          class="detail-item"
        >
          <span class="detail-label">入库仓库</span>
          <span class="detail-content">
            {{ detail.warehouseId | warehouseName }}
          </span>
        </el-col>
        <el-col
          :span="6"
          v-if="typeIsCg || typeIsTh || typeIsQt"
          class="detail-item"
        >
          <span class="detail-label">操作人</span>
          <span class="detail-content">{{ detail.operationName }}</span>
        </el-col>
        <el-col :span="24" class="detail-item">
          <span class="detail-label">备注</span>
          <span class="detail-content">
            {{ detail.remark ? detail.remark : '--' }}
          </span>
        </el-col>
      </el-row>
    </div>
    <div class="detail-table">
      <p class="detail-title">入库产品</p>
      <el-table
        border
        :data="detail.warehouseInProductVOList"
        class="mt20"
        show-summary
        sum-text="总计"
        :summary-method="getSummaries"
      >
        <el-table-column
          align="center"
          prop="skuId"
          label="SKU ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productName"
          label="产品名称"
        ></el-table-column>
        <el-table-column align="center" prop="productSpec" label="规格">
          <template slot-scope="scope">
            {{ scope.row.productSpec ? scope.row.productSpec : '--' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="productType"
          label="产品类别"
        ></el-table-column>
        <el-table-column
          align="center"
          width="80px"
          prop="productUnit"
          label="单位"
        ></el-table-column>
        <el-table-column align="center" prop="contractNum" label="合同数量">
          <template slot-scope="scope">
            {{ scope.row.contractNum ? scope.row.contractNum : '--' }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="warehouseInNum"
          label="实际入库数量"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="giftNum"
          label="备用品数量"
        ></el-table-column>

        <el-table-column
          align="center"
          :label="typeIsCg ? '不含税单价' : '单价'"
          prop="priceExcludingTax"
        ></el-table-column>
        <el-table-column
          align="center"
          label="含税单价"
          v-if="typeIsCg"
          prop="priceIncludingTax"
        ></el-table-column>
        <el-table-column
          align="center"
          :label="typeIsCg ? '不含税成本' : '成本'"
          prop="totalPriceExcluding"
        >
          <template slot-scope="scope">
            {{ toFixed3(scope.row.totalPriceExcluding) }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="含税成本"
          v-if="typeIsCg"
          prop="totalPriceIncluding"
        >
          <template slot-scope="scope">
            {{ toFixed3(scope.row.totalPriceIncluding) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.remark ? scope.row.remark : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="80px">
          <template slot-scope="scope">
            <p class="operation">
              <el-tooltip
                class="item"
                effect="dark"
                content="库位详情"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-map-location"
                  circle
                  size="mini"
                  @click="addLoc(scope.row)"
                ></el-button>
              </el-tooltip>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 库位 -->
    <Loc
      :params="locParams"
      @close="onClose('loc')"
      ref="loc"
      :showStatistics="false"
      width="600px"
    ></Loc>
    <div class="detail-files mt20">
      <p class="detail-title">相关附件</p>
    </div>
  </div>
</template>

<script>
import { InboundSheet } from '@/core'
import Loc from '@/views/stock-center/inbound-sheet/components/loc'
let self
export default {
  name: 'inbound-sheet-detail', 
  props: {
    detailId: {
      type: String,
      required: true,
    },
  },
  data() {
    self = this
    return {
      detail: {
        area: '',
        createWay: 0,
        fileNames: '',
        fileUrls: '',
        operationId: '',
        operationName: '',
        orderNumber: '',
        remark: '',
        status: 0,
        supplierName: '',
        type: 0,
        warehouseId: '',
        warehouseInId: '',
        warehouseInNumber: '',
        adjustStatus: 0,

        warehouseInProductVOList: [
          {
            contractNum: 0,
            giftNum: 0,
            priceExcludingTax: 0,
            priceIncludingTax: 0,
            productName: '',
            productSpec: '',
            productType: '',
            productUnit: '',
            remark: '',
            skuId: '',
            tenantId: '',
            totalPriceExcluding: 0,
            totalPriceIncluding: 0,
            warehouseInId: '',
            warehouseInNum: 0,
            warehouseInProductId: '',
            warehouseInProductLocationVOList: [
              {
                locationNumber: '',
                num: 0,
                warehouseInProductLocationId: '',
              },
            ],
          },
        ],
      },
      warehouses: [],
      locParams: {
        show: false,
        disabled: true,
      },
    }
  },

  components: {
    Loc,
  },
  computed: {
    warehouseInId() {
      return detailId
    },
    typeIsCg() {
      //采购入库
      return this.detail.type === 0
    },
    typeIsTh() {
      return this.detail.type === 1
    },
    typeIsQt() {
      return this.detail.type === 2
    },
  },

  methods: {
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
          return
        }

        const summayIndexs = this.typeIsCg ? [5, 6, 7, 10, 11] : [5, 6, 7, 9]

        if (!summayIndexs.includes(index)) {
          sums[index] = ''
          return
        }
        const values = data
          .map((item) => Number(item[column.property]))
          .filter((val) => val || val === 0)

        if (values.length > 0) {
          sums[index] = values.reduce((prev, next) => {
            if ([5, 6, 7].includes(index)) {
              return prev + next
            } else {
              return this.BigMath.add(prev, next)
            }
          })
        } else {
          sums[index] = 0
        }
        if (index == 11 || index == 9 || index == 10) {
          sums[index] = this.toFixed3(sums[index])
        }

        if (index == 5 && sums[index] === 0) {
          sums[index] = '--'
        }
      })

      return sums
    },

    onClose() {
      this.locParams.show = false
    },
    addLoc(row) {
      this.locParams.show = true
      this.$nextTick().then(() => {
        this.$refs.loc.locs = row.warehouseInProductLocationVOList.map((i) => {
          i.currentNum = 0
          return i
        })
      })
    },
  },

  filters: {
    inBoundType(val) {
      const types = [
        //入库方式
        {
          code: 0,
          name: '采购入库',
        },
        {
          code: 1,
          name: '退货入库',
        },
        {
          code: 2,
          name: '其他入库',
        },
      ]
      let type = types.find((item) => item.code === val)
      return type ? type.name : '--'
    },

    warehouseName(val) {
      const warehouse = self.warehouses.find((item) => item.warehouseId === val)
      return warehouse ? warehouse.name : '--'
    },
  },
  created() {
    if (this.detailId) {
      InboundSheet.findWarehouseList().then((res) => {
        this.warehouses = res.data || []
      })
      InboundSheet.getDetail({ warehouseInId: this.detailId }).then((res) => {
        if (res && res.code == '000000') {
          if (res.data && res.data.warehouseInProductVOList) {
            res.data.warehouseInProductVOList.forEach((i) => {
              i.totalPriceExcluding = this.toFixed3(i.totalPriceExcluding)
              i.priceExcludingTax = this.toFixed3(i.priceExcludingTax)
              i.priceIncludingTax = this.toFixed3(i.priceIncludingTax)
            })
          }
          this.detail = res.data
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.table-container {
  padding: 20px !important;
  & .title {
    display: flex;
    align-items: center;
  }
  & .detail-fields {
    // background-color: #f5f7fa;
    .el-row {
      margin: 20px 0;
      & .label {
        color: #282c34;
        width: 75px;
        text-align: right;
        display: inline-block;
        margin-right: 15px;
      }
      & .content {
        color: #000016;
      }
    }
  }
}
.header {
  font-size: 18px;
  color: #606266;
  font-family: 'PingFang Bold';
}

.mr15 {
  margin-right: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mt20 {
  margin-top: 20px;
}
.pointer {
  cursor: pointer;
}
</style>


<style>
.el-tooltip__popper {
  max-width: 400px;
}
</style>

