import { render, staticRenderFns } from "./importModel.vue?vue&type=template&id=71020e25&scoped=true&"
import script from "./importModel.vue?vue&type=script&lang=js&"
export * from "./importModel.vue?vue&type=script&lang=js&"
import style0 from "./importModel.vue?vue&type=style&index=0&id=71020e25&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71020e25",
  null
  
)

export default component.exports