<template>
  <div class="content">
    <h3 style="text-align: center">客户信息审核</h3>
    <h4 style="margin-top: 0;">公司信息</h4>
    <el-table
      :data="[passRow.auditContent.afterContent.companyVO]"
      border
      stripe
    >
      <el-table-column
        label="序号"
        type="index"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="公司编码"
        prop="companyCode"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ passRow.auditContent.customerCode || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        label="公司名称"
        prop="companyName"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="公司等级"
        prop="companyLevel"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-rate
            v-model="passRow.auditContent.afterContent.companyVO.companyLevel"
            disabled
            text-color="#ff9900"
          ></el-rate>
        </template>
      </el-table-column>
    </el-table>
    <h4 v-if="passRow.auditContent.beforeContent">修改前数据</h4>
    <el-tabs v-if="passRow.auditContent.beforeContent" v-model="beforeTab">
      <el-tab-pane
        label="客户信息"
        name="1"
        v-if="passRow.auditContent.beforeContent.infoShowVO"
      >
        <el-table
          :data="[passRow.auditContent.beforeContent.infoShowVO]"
          border
          stripe
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column
            label="客户编码"
            prop="customerCode"
            align="center"
          ></el-table-column>
          <el-table-column
            label="客户名称"
            prop="customerName"
            align="center"
          ></el-table-column>
          <el-table-column label="客户等级" prop="customerRank" align="center">
            <template slot-scope="scope">
              <el-rate
                v-model="scope.row.customerRank"
                disabled
                text-color="#ff9900"
              ></el-rate>
            </template>
          </el-table-column>

          <el-table-column
            label="联系电话"
            prop="phone"
            align="center"
          ></el-table-column>
          <el-table-column
            label="客户邮箱"
            prop="email"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ getEmail(passRow.auditContent.beforeContent.infoShowVO) }}
            </template>
          </el-table-column>
          <el-table-column label="客户类型" prop="customerType" align="center">
            <template slot-scope="scope">
              {{ customerTypeStatus(scope.row.customerType) }}
            </template>
          </el-table-column>
          <el-table-column
            label="客户来源"
            prop="customerSourceName"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.customerSourceName || '--' }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- <el-tab-pane
        label="付款方式"
        name="2"
        v-if="passRow.auditContent.beforeContent.companyVO.paymentEditVOs && passRow.auditContent.beforeContent.companyVO.paymentEditVOs.length"
      >
        <el-table
          :data="passRow.auditContent.beforeContent.companyVO.paymentEditVOs"
          border stripe
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column
            label="付款方式"
            prop="payway"
            align="center"
          ></el-table-column>
          <el-table-column
            label="金额区间"
            prop="content"
            align="center"
          >
          <template slot-scope="scope">
            <el-row v-if="scope.row.payType === 1">全部</el-row>
            <el-row v-else class="pl5 pr5">
              <span
                v-if="
                  (scope.row.beginSection === 0 || scope.row.beginSection) &&
                  scope.row.endSection
                "
              >
                {{ scope.row.beginSection }}-{{ scope.row.endSection }}
              </span>
              <span v-else>&gt;{{ scope.row.beginSection }}</span>
            </el-row>
           </template>
         </el-table-column>
          <el-table-column
            label="操作"
            prop="option"
            align="center"
          >
          <template slot-scope="scope">
            {{scope.row.option | optionFilter}}
          </template>
        </el-table-column>
        </el-table>
      </el-tab-pane> -->
      <el-tab-pane
        label="收货地址"
        name="3"
        v-if="
          passRow.auditContent.beforeContent.companyVO
            .receivingAddressConfigVOs &&
          passRow.auditContent.beforeContent.companyVO.receivingAddressConfigVOs
            .length
        "
      >
        <el-table
          :data="
            passRow.auditContent.beforeContent.companyVO
              .receivingAddressConfigVOs
          "
          border
          stripe
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column label="详细收货地址" prop="address" align="center">
            <template slot-scope="scope">
              {{ getFullAdress(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column
            label="收货公司"
            prop="companyName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="收货人"
            prop="consigneeName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="联系电话"
            prop="consigneePhone"
            align="center"
          ></el-table-column>
          <el-table-column
            label="国家"
            prop="nation"
            align="center"
          ></el-table-column>
          <el-table-column
            label="邮编"
            prop="postcode"
            align="center"
          ></el-table-column>
          <el-table-column
            label="州/省"
            prop="province"
            align="center"
          ></el-table-column>
          <el-table-column
            label="城市"
            prop="city"
            align="center"
          ></el-table-column>
          <el-table-column
            label="街道地址"
            prop="street"
            align="center"
          ></el-table-column>
          <el-table-column
            label="单元号地址"
            prop="unit"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" prop="option" align="center">
            <template slot-scope="scope">
              {{ scope.row.option | optionFilter }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <h4 v-if="passRow.auditContent.afterContent">修改后数据</h4>
    <el-tabs v-if="passRow.auditContent.afterContent" v-model="afterTab">
      <el-tab-pane
        label="客户信息"
        name="1"
        v-if="passRow.auditContent.afterContent.infoShowVO"
      >
        <el-table
          :data="[passRow.auditContent.afterContent.infoShowVO]"
          border
          stripe
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column
            label="客户编码"
            prop="customerCode"
            align="center"
          ></el-table-column>
          <el-table-column
            label="客户名称"
            prop="customerName"
            align="center"
          ></el-table-column>
          <el-table-column label="客户等级" prop="customerRank" align="center">
            <template slot-scope="scope">
              <el-rate
                v-model="scope.row.customerRank"
                disabled
                text-color="#ff9900"
              ></el-rate>
            </template>
          </el-table-column>

          <el-table-column
            label="联系电话"
            prop="phone"
            align="center"
          ></el-table-column>
          <el-table-column
            label="客户邮箱"
            prop="email"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              {{ getEmail(passRow.auditContent.afterContent.infoShowVO) }}
            </template>
          </el-table-column>
          <el-table-column label="客户类型" prop="customerType" align="center">
            <template slot-scope="scope">
              {{ customerTypeStatus(scope.row.customerType) }}
            </template>
          </el-table-column>
          <el-table-column
            label="客户来源"
            prop="customerSourceName"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.customerSourceName || '--' }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- <el-tab-pane
        label="付款方式"
        name="2"
        v-if="passRow.auditContent.afterContent.companyVO.paymentEditVOs && passRow.auditContent.afterContent.companyVO.paymentEditVOs.length"
      >
        <el-table
        border stripe
          :data="passRow.auditContent.afterContent.companyVO.paymentEditVOs"
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column
            label="付款方式"
            prop="payway"
            align="center"
          ></el-table-column>
          <el-table-column
            label="金额区间"
            prop="content"
            align="center"
          >
           <template slot-scope="scope">
            <el-row v-if="scope.row.payType === 1">全部</el-row>
            <el-row v-else class="pl5 pr5">
              <span
                v-if="
                  (scope.row.beginSection === 0 || scope.row.beginSection) &&
                  scope.row.endSection
                "
              >
                {{ scope.row.beginSection }}-{{ scope.row.endSection }}
              </span>
              <span v-else>&gt;{{ scope.row.beginSection }}</span>
            </el-row>
           </template>
         </el-table-column>
          <el-table-column
            label="操作"
            prop="option"
            align="center"
          >
          <template slot-scope="scope">
            {{scope.row.option | optionFilter}}
          </template>
        </el-table-column>
        </el-table>
      </el-tab-pane> -->
      <el-tab-pane
        label="收货地址"
        name="3"
        v-if="
          passRow.auditContent.afterContent.companyVO
            .receivingAddressConfigVOs &&
          passRow.auditContent.afterContent.companyVO.receivingAddressConfigVOs
            .length
        "
      >
        <el-table
          :data="
            passRow.auditContent.afterContent.companyVO
              .receivingAddressConfigVOs
          "
          border
          stripe
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column label="详细收货地址" prop="address" align="center">
            <template slot-scope="scope">
              {{ getFullAdress(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column
            label="收货公司"
            prop="companyName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="收货人"
            prop="consigneeName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="联系电话"
            prop="consigneePhone"
            align="center"
          ></el-table-column>
          <el-table-column
            label="国家"
            prop="nation"
            align="center"
          ></el-table-column>
          <el-table-column
            label="邮编"
            prop="postcode"
            align="center"
          ></el-table-column>
          <el-table-column
            label="州/省"
            prop="province"
            align="center"
          ></el-table-column>
          <el-table-column
            label="城市"
            prop="city"
            align="center"
          ></el-table-column>
          <el-table-column
            label="街道地址"
            prop="street"
            align="center"
          ></el-table-column>
          <el-table-column
            label="单元号地址"
            prop="unit"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" prop="option" align="center">
            <template slot-scope="scope">
              {{ scope.row.option | optionFilter }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { customerTypeStatusLang } from '@/utils/en-match-zh'
  export default {
    name: 'ErpWebNewCustomerCenterAudit',
    props: {
      passRow: {
        type: [Object, Array],
        required: true,
      },
    },

    data() {
      return {
        beforeTab: '',
        afterTab: '',
      }
    },

    mounted() {
      if (this.passRow.auditContent.beforeContent?.infoShowVO) {
        this.beforeTab = '1'
      } else {
        this.beforeTab = '3'
      }
      if (this.passRow.auditContent.afterContent?.infoShowVO) {
        this.afterTab = '1'
      } else {
        this.afterTab = '3'
      }
    },

    methods: {
      // 获取邮箱
      getEmail(infoShowVO) {
        const emails = (infoShowVO.linkMainEmails || [])
          .map((i) => i.email)
          .join(',')
        return emails || '--'
      },
      //客户类型匹配
      customerTypeStatus(val) {
        return this.$t(customerTypeStatusLang(val))
      },
      // 获取详细收货地址
      getFullAdress(item) {
        const { street, unit, city, province, postcode } = item
        const _ = (field) => {
          return field ? field : ''
        }
        return `${_(street)}${_(unit)}${_(city)}${_(province)}${_(postcode)}`
      },
    },

    filters: {
      optionFilter(val) {
        switch (val) {
          case 1:
            return '新增'
          case 2:
            return '修改'
          case 3:
            return '删除'
          default:
            return '--'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding:0 15px 15px;
  }

  h4 {
    margin-top: 20px;
  }
</style>
