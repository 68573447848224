<!--
  功能：跟单节点组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年07月27日 10:27:17
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-drawer
    :with-header="false"
    :visible.sync="visible"
    :size="604"
    :before-close="beforeClose"
  >
    <div v-if="detail.status == '7' || detail.status == '4'" class="mask"></div>
    <span v-if="detail.status == '7'" class="mask-span">无需跟单</span>
    <span v-if="detail.status == '4'" class="mask-span">已作废</span>
    <div class="wrapper">
      <!-- 顶部 -->
      <div class="top">
        <h3 class="left">
          <!-- 跟单节点 -->
          {{ $t('orderList.DocumentaryNode') }}
        </h3>
        <div class="middle">
          <p>
            <i
              v-if="showArrow"
              class="el-icon-arrow-left"
              :class="{
                disabled: currentIndex == 0,
              }"
              @click="changeIndex(-1)"
            ></i>
            <span>
              <!-- 订单跟踪编号 -->
              {{ $t('orderList.OrderTrackingNumber') }}

              :
              <span
                class="page-link no-line"
                :style="{ cursor: lang !== 'en' ? 'pointer' : 'context-menu' }"
                @click="showDetail"
              >
                {{ current.orderTrackingNumber }}
              </span>
            </span>
            <i
              v-if="showArrow"
              class="el-icon-arrow-right"
              :class="{
                disabled: currentIndex == ids.length - 1,
              }"
              @click="changeIndex(1)"
            ></i>
          </p>
          <p>
            <!-- 产品名称： -->
            {{ $t('orderList.ProductName') }}:
            {{ lang === 'en' ? current.productNameEn : current.productName }}
          </p>
        </div>
        <div class="right">
          <p>
            <button class="back" @click="back">
              <!-- 返回 -->
              {{ $t('orderList.Return') }}
            </button>
            <button v-if="!hideSave" class="save" @click="save">
              <!-- 保存 -->
              {{ $t('orderList.Save') }}
            </button>
          </p>
        </div>
      </div>
      <!-- 节点列表 -->
      <p class="red">
        <!-- 提示：录入【实际日期】请确认无误，实际录入保存，不再支持修改 -->
        {{ $t('orderList.Tips') }}
      </p>
      <div v-if="!noData" class="bottom">
        <el-form ref="nodeform" :model="detail" :rule="rules">
          <el-steps
            direction="vertical"
            :active="detail.orderTrackingNodeVOList.length"
          >
            <el-step
              v-for="(item, index) in detail.orderTrackingNodeVOList"
              :key="index"
            >
              <div slot="description">
                <!-- 节点名称 -->
                <div class="no">
                  {{ detail.orderTrackingNodeVOList.length - index }}
                </div>
                <div class="node-wrapper">
                  <div class="left">
                    <!-- 节点名称 -->
                    {{ $t('orderList.NodeName') }}
                    ：
                  </div>
                  <div class="middle">
                    {{ lang === 'en' ? item.nodeNameEn : item.nodeNameCn }}
                  </div>
                  <div class="right">
                    <!-- 日期更新人 -->
                    {{ $t('orderList.DateUpdater') }}
                    ：{{
                      lang === 'en' ? item.timeUpdatorEn : item.timeUpdator
                    }}
                  </div>
                </div>
                <!-- 预计日期 -->
                <div class="node-wrapper">
                  <!-- v-if="!item.specialNode" -->
                  <div class="left">
                    <span style="color: red">*</span>
                    <!-- 预计日期 -->
                    {{ $t('orderList.EstimatedDate') }}
                    ：
                  </div>
                  <div class="middle">
                    <el-form-item
                      :prop="'orderTrackingNodeVOList.' + index + '.dueDate'"
                      :rules="[
                        {
                          required: true,
                          trigger: ['blur', 'change'],
                          message: $t('placeholder.plsSel'),
                        },
                      ]"
                    >
                      <el-date-picker
                        v-model="item.dueDate"
                        type="date"
                        size="mini"
                        value-format="yyyy-MM-dd hh:mm:ss"
                        :disabled="
                          item.disabled || item.status == 2 || item.status == 3
                        "
                        :placeholder="$t('placeholder.plsSel')"
                        @change="(val) => onDateChange(val, item)"
                      />
                    </el-form-item>
                  </div>
                  <div class="right"></div>
                </div>
                <!-- 实际日期 -->
                <div class="node-wrapper">
                  <div class="left">
                    <!-- 实际日期 -->
                    <!-- {{
                    !item.specialNode
                      ? $t('orderList.ActualDate')
                      : getDateLabelWithNodeType(item)
                  }} -->
                    {{ $t('orderList.ActualDate') }}
                    ：
                  </div>
                  <div class="middle">
                    <!-- 特殊节点实际日期置灰 -->
                    <!-- (item.specialNode && item.status == 2) -->

                    <el-date-picker
                      v-model="item.realDate"
                      size="mini"
                      type="date"
                      value-format="yyyy-MM-dd hh:mm:ss"
                      :placeholder="$t('placeholder.plsSel')"
                      :disabled="
                        item.specialNode ||
                        (!item.specialNode &&
                          (!item.dueDate || item.disabled)) ||
                        (!item.specialNode &&
                          !beforeNodes[item.orderTrackingNodeId].before
                            .realDate)
                      "
                      @change="(val) => onDateChange(val, item)"
                    />
                  </div>

                  <div class="right">
                    <!-- v-if="!item.specialNode" -->
                    <!-- 延期勾选框 -->
                    <!-- <el-checkbox
                    v-model="item.deferred"
                    :true-label="1"
                    :false-label="0"
                    class="ml5"
                    :disabled="
                      item.status == 2 ||
                      item.status == 3 ||
                      item.disabled ||
                      !item.realDate ||
                      !item.dueDate ||
                      +new Date(item.realDate) < +new Date(item.dueDate)
                    "
                    @change="(val) => onToggleChecked(val, item, 1)"
                  /> -->
                    <!-- 延期 -->
                    <!-- {{ $t('orderList.Deferment') }} -->
                  </div>

                  <!-- <div v-else class="right">
                  <span
                    style="color: #409eff; cursor: pointer"
                    class="sel"
                    :style="{
                      cursor:
                        item.specialNode && item.status == 2
                          ? 'not-allowed'
                          : '',
                      color:
                        item.specialNode && item.status == 2
                          ? 'gray'
                          : '#409eff',
                    }"
                    @click="specSelect(item, index)"
                  >
                    {{ lang == 'en' ? 'Select' : '请选择' }}
                  </span>
                </div> -->
                </div>
                <div v-if="item.status != 2" class="node-wrapper">
                  <div class="left">
                    <!-- 延期天数  -->
                    {{ $t('orderList.DeferredDays') }}
                    ：
                  </div>
                  <div class="middle">
                    <el-input-number
                      v-model="item.deferredDays"
                      style="width: 220px"
                      :controls="false"
                      :min="0"
                      :precision="0"
                      disabled
                    />
                  </div>
                </div>
                <!-- 物流货运物流单号 -->
                <!-- <div
                v-if="item.nodeType == 'LOGISTICS_FREIGHT'"
                class="node-wrapper"
              >
                <div class="left">
                  {{ $t('orderList.LogisticsOrderNo') }}
                  ：
                </div>
                <div class="middle">
                  <el-input
                    v-model="item.shipNo"
                    style="width: 220px"
                    :maxlength="200"
                    :disabled="item.specialNode && item.status == 2"
                  />
                </div>
                <div class="right"></div>
              </div> -->
                <!-- qc质检 验货结果 -->
                <!-- <div v-if="item.nodeType == 'QC'" class="node-wrapper">
                <div class="left">
                  {{ $t('orderList.InspectionResult') }}
                  ：
                </div>
                <div class="middle">
                  <el-input
                    v-model="item.inspectionResult"
                    style="width: 220px"
                    :maxlength="200"
                    :disabled="item.specialNode && item.status == 2"
                  />
                </div>
                <div class="right"></div>
              </div> -->
                <!-- 备注 -->
                <div class="node-wrapper">
                  <div class="left">
                    <!-- 备&nbsp;&nbsp;注 -->
                    {{ $t('orderList.Remark') }}
                    ：
                  </div>
                  <div class="middle">
                    <el-input
                      v-model="item.remark"
                      type="textarea"
                      style="width: 220px"
                      :disabled="item.disabled"
                      :maxlength="200"
                    />
                  </div>
                  <div class="right"></div>
                </div>
              </div>
            </el-step>
          </el-steps>
        </el-form>
      </div>
      <!-- <div v-if="noData" class="noData">暂无数据</div> -->
      <el-empty
        v-if="noData"
        :description="lang == 'en' ? 'NoData' : '暂无数据'"
      />

      <!--选择组件 -->
      <el-dialog
        :title="$t(nodeTitle)"
        width="900px"
        :visible.sync="nodeVisible"
        center
        :modal="false"
        placement="bottom"
      >
        <!-- QC质检 -->
        <template v-if="nodeType === 'QC'">
          <el-table
            ref="nodeTable"
            key="qc"
            border
            :data="nodeTable"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column :label="$t('orderList.Choose')" width="100">
              <template slot-scope="scope">
                <el-radio
                  v-model="currentRow1"
                  class="radio-style"
                  :label="scope.$index"
                  @change.native="getCurrentRow(scope.$index)"
                >
                  <span class="no-show">{{ scope.$index }}</span>
                </el-radio>
              </template>
            </el-table-column>
            <!-- label="验货单号" -->
            <el-table-column
              prop="number"
              :label="$t('orderList.InspectionOrderNo')"
            >
              <template slot-scope="scope">{{ scope.row.number }}</template>
            </el-table-column>
            <!-- label="验货日期" -->
            <el-table-column
              prop="inspectionDate"
              :label="$t('orderList.InspectionDate')"
            >
              <template slot-scope="scope">
                {{ scope.row.inspectionDate | dateFilter }}
              </template>
            </el-table-column>
            <!-- label="验货结果" -->
            <el-table-column
              prop="actualResults"
              :label="$t('orderList.InspectionResult')"
            >
              <template slot-scope="scope">
                {{
                  scope.row.actualResults ? scope.row.actualResults : '无需验货'
                }}
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 物流货运 -->
        <template v-else-if="nodeType === 'LOGISTICS_FREIGHT'">
          <el-table
            ref="nodeTable"
            key="LOGISTICS_FREIGHT"
            border
            :data="nodeTable"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column :label="$t('orderList.Choose')" width="100">
              <template slot-scope="scope">
                <el-radio
                  v-model="currentRow2"
                  class="radio-style"
                  :label="scope.$index"
                  @change.native="getCurrentRow2(scope.$index)"
                >
                  <span class="no-show">{{ scope.$index }}</span>
                </el-radio>
              </template>
            </el-table-column>
            <!--  label="委托单号" -->
            <el-table-column
              property="number"
              :label="$t('orderList.ShippingOrderNumber')"
              show-oveflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.number }}
              </template>
            </el-table-column>
            <!-- label="出货日期" -->
            <el-table-column
              property="shippingDate"
              show-oveflow-tooltip
              :label="$t('orderList.ShippingDate')"
            >
              <template slot-scope="scope">
                {{ scope.row.shippingDate | dateFilter }}
              </template>
            </el-table-column>
            <!-- label="物流单号" -->
            <el-table-column
              property="shipNo"
              show-oveflow-tooltip
              :label="$t('orderList.LogisticsOrderNo')"
            >
              <template slot-scope="scope">
                {{ scope.row.shipNo }}
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 客户签收 -->
        <template v-else>
          <el-table
            ref="nodeTable"
            key="333"
            border
            :data="nodeTable"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column :label="$t('orderList.Choose')" width="100">
              <template slot-scope="scope">
                <el-radio
                  v-model="currentRow3"
                  class="radio-style"
                  :label="scope.$index"
                  @change.native="getCurrentRow3(scope.$index)"
                >
                  <span class="no-show">{{ scope.$index }}</span>
                </el-radio>
              </template>
            </el-table-column>
            <!-- label="委托单号" -->
            <el-table-column
              property="number"
              show-oveflow-tooltip
              :label="$t('orderList.ShippingOrderNumber')"
            >
              <template slot-scope="scope">
                {{ scope.row.number }}
              </template>
            </el-table-column>
            <!-- label="签收日期" -->
            <el-table-column
              property="manualSignInDate"
              show-oveflow-tooltip
              :label="$t('orderList.DateOfReceipt')"
            >
              <template slot-scope="scope">
                {{ scope.row.manualSignInDate | dateFilter }}
              </template>
            </el-table-column>
          </el-table>
        </template>

        <span slot="footer" class="dialog-footer">
          <!-- 取消 -->
          <el-button @click="nodeClose">
            {{ lang === 'en' ? 'Cancel' : '取消' }}
          </el-button>
          <!-- 确认 -->
          <el-button type="primary" @click="nodeSave()">
            {{ lang === 'en' ? 'Save' : '保存' }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </el-drawer>
</template>

<script>
  import {
    getTrackingDetail,
    updateTrackingPO,
    SPECIAL_NODES,
  } from '@/api/order'
  import {
    listByPurchaseOrderNumber,
    queryShippingDate,
    querySignedDate,
  } from '@/api/track'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

  import mixin from './mixins'

  const _ = (s) => +new Date(s)
  export default {
    name: 'TrackingNodes',
    filters: {
      dateFilter(val) {
        if (val) {
          return val.split(' ')[0]
        }
        return '--'
      },
    },
    mixins: [mixin],
    data() {
      return {
        rules: {},
        visible: false, //侧滑控制符
        ids: [], //切换时的参数id
        currentIndex: -1, //当前ids对应的index
        detail: {}, //跟踪单详情
        uploadParams: {
          //上传参数
          amount: 9,
          size: 1024 * 1024 * 20, //单个文件上传大小
          showTip: false,
        },
        nodeTable: [], // 跟单节点list
        nodeVisible: false, // 跟单节点是否可见
        nodeTitle: '', // 跟单节点标题
        nodeType: '', //跟单节点类型
        nodeIndex: '', // 跟单节点索引
        /**
         * QC质检
         */
        currentRow1: '', // 当前选中行1
        currentRowObj1: {}, // 当前选中行弹窗选择数据1
        /**
         * 物流货运
         */
        currentRow2: '', // 当前选中行2
        currentRowObj2: {}, // 当前选中行弹窗选择数据2
        /**
         * 客户签收
         */
        currentRow3: '', // 当前选中行2
        currentRowObj3: {}, // 当前选中行弹窗选择数据2
        hideSave: false, //是否显示保存按钮
        beforeNodes: {}, // 初始化节点信息
      }
    },
    computed: {
      current() {
        if (this.currentIndex < 0) return {}
        return this.ids[this.currentIndex]
      },
      noData() {
        return (
          !this.detail.orderTrackingNodeVOList ||
          this.detail.orderTrackingNodeVOList.length === 0
        )
      },

      showArrow() {
        return this.ids && this.ids.length > 1
      },
    },

    watch: {
      currentIndex(val) {
        if (val < 0) {
          return
        } else {
          const { orderTrackingId } = this.ids[val]

          getTrackingDetail(orderTrackingId).then((res) => {
            if (res?.code === '000000') {
              this.isSaved = false
              var { orderTrackingNodeVOList, status } = res.data

              if (orderTrackingNodeVOList) {
                if (status == 3 || status == 4 || status == 7) {
                  orderTrackingNodeVOList = this.setNodeDisabledByOrderStatus(
                    orderTrackingNodeVOList
                  )
                  this.hideSave = true
                } else {
                  this.hideSave = false
                  if (orderTrackingNodeVOList.length === 1) {
                    orderTrackingNodeVOList[0].disabled = false
                  } else {
                    orderTrackingNodeVOList.forEach((item, index, arr) => {
                      if (item.realDate && item.dueDate) {
                        item.deferredDays = this._getDeferredDays(item)
                      }
                      if (!item.deferredDays && item.deferredDays !== 0) {
                        item.deferredDays = undefined
                      }
                      // 标记特殊节点
                      this._markSpecial(item)
                      // if (index > 0) {
                      //   // 先根据上一个节点判断状态
                      //   if (item.realDate) {
                      //     arr[index - 1].disabled = true
                      //   } else {
                      //     arr[index - 1].disabled = false
                      //   }
                      // }
                    })
                  }
                }
                let simpleNodes = orderTrackingNodeVOList.filter(
                  (item) => !item.specialNode
                )
                let beforeNodes = {} //orderTrackingNodeId: "430009281435013120"
                for (let i = 1; i < simpleNodes.length; i++) {
                  if (simpleNodes[i].realDate) {
                    simpleNodes[i - 1].disabled = true
                  } else {
                    simpleNodes[i - 1].disabled = false
                  }
                }

                for (let i = 0; i < simpleNodes.length; i++) {
                  // 处理最后一个节点
                  if (i == 0) {
                    beforeNodes[simpleNodes[i]['orderTrackingNodeId']] = {}
                    beforeNodes[simpleNodes[i]['orderTrackingNodeId']].before =
                      {
                        realDate: 'xxxx',
                      }
                  } else {
                    beforeNodes[simpleNodes[i]['orderTrackingNodeId']] = {}
                    beforeNodes[simpleNodes[i]['orderTrackingNodeId']].before =
                      simpleNodes[i - 1]
                  }
                }

                this.beforeNodes = beforeNodes
                res.data.orderTrackingNodeVOList =
                  orderTrackingNodeVOList.reverse()
              }

              this.detail = res.data
              this.initNodeList = JSON.stringify(
                res.data.orderTrackingNodeVOList
              )
            }
          })
        }
      },
    },

    methods: {
      setNodeDisabledByOrderStatus(list) {
        return list.map((item) => {
          item.disabled = true
          // this._markSpecial(item)
          if (SPECIAL_NODES.includes(item.nodeType)) {
            item.specialNode = true
          }
          return item
        })
      },
      // QC质检选择
      getCurrentRow(i) {
        this.currentRow1 = i
        this.currentRowObj1 = this.nodeTable[i]
      },

      // 物流货运
      getCurrentRow2(i) {
        this.currentRow2 = i
        this.currentRowObj2 = this.nodeTable[i]
      },

      // 客户签收
      getCurrentRow3(i) {
        this.currentRow3 = i
        this.currentRowObj3 = this.nodeTable[i]
      },
      nodeClose() {
        this.currentRow1 = ''
        this.currentRow2 = ''
        this.currentRow3 = ''
        this.currentRowObj1 = {}
        this.currentRowObj2 = {}
        this.currentRowObj3 = {}
        this.nodeVisible = false
      },
      specSelect({ nodeType, specialNode, status }, nodeIndex) {
        if (specialNode && status == 2) {
          return
        }
        if (!this.detail.salesOrderNumber) {
          this.$message.warning('请先选择关联编号')
          return false
        }
        if (!this.detail.orderTrackingProductVOList[0].sku) {
          this.$message.warning('请先选择采购物品')
          return false
        }

        switch (nodeType) {
          case 'QC':
            this.nodeTitle = 'orderList.QCQualityInspectionOptions'
            this.nodeTitle = 'QC质检选择'
            listByPurchaseOrderNumber({
              sku: this.detail.orderTrackingProductVOList[0].sku,
              tenantId: this.userInfo.tenantId,
              purchaseOrderNumber: this.detail.purchaseOrderNumber,
            }).then((res) => {
              if (res && res.code === '000000') {
                this.nodeTable = res.data
              }
            })
            break
          case 'LOGISTICS_FREIGHT':
            this.nodeTitle = 'orderList.LogisticsFreightOptions'
            queryShippingDate({
              salesOrderNumber: this.detail.salesOrderNumber,
              sku: this.detail.orderTrackingProductVOList[0].sku,
              parentSku: this.detail.orderTrackingProductVOList[0].parentSku,
            }).then((res) => {
              if (res && res.code === '000000') {
                this.nodeTable = res.data
              }
            })
            break
          case 'THE_CUSTOMER_TO_SIGN_FOR':
            this.nodeTitle = 'orderList.CustomerSignatureOption'
            querySignedDate({
              salesOrderNumber: this.detail.salesOrderNumber,
              sku: this.detail.orderTrackingProductVOList[0].sku,
              parentSku: this.detail.orderTrackingProductVOList[0].parentSku,
            }).then((res) => {
              if (res && res.code === '000000') {
                this.nodeTable = res.data
              }
            })
            break
        }

        this.nodeType = nodeType
        this.nodeIndex = nodeIndex
        this.nodeVisible = true
      },
      // 处理日期{}
      _handleDate(dateStr) {
        if (dateStr) {
          if (dateStr.split(' ').length === 1) {
            dateStr = dateStr + ' ' + '00:00:00'
          }
        }
        return dateStr
      },

      // 特殊节点保存
      nodeSave() {
        const type = this.nodeType,
          item = this.detail.orderTrackingNodeVOList[this.nodeIndex]

        switch (type) {
          case 'QC':
            if (this.currentRowObj1) {
              item.realDate = this._handleDate(
                this.currentRowObj1.inspectionDate
              )
              // item.actualResults = this.currentRowObj1.actualResults
              this.$set(
                item,
                'inspectionResult',
                this.currentRowObj1.actualResults
                  ? this.currentRowObj1.actualResults
                  : '无需验货'
              )
            }
            break

          case 'LOGISTICS_FREIGHT':
            if (this.currentRowObj2) {
              item.realDate = this._handleDate(this.currentRowObj2.shippingDate)
              // item.shipNo = this.currentRowObj2.shipNo
              this.$set(item, 'shipNo', this.currentRowObj2.shipNo)
            }
            break
          case 'THE_CUSTOMER_TO_SIGN_FOR':
            if (this.currentRowObj3) {
              item.realDate = this._handleDate(
                this.currentRowObj3.manualSignInDate
              )
            }
            break
        }
        this.nodeClose()
      },
      specialNode({ nodeType }) {
        this.nodeType = nodeType
      },
      getDateLabelWithNodeType({ nodeType }) {
        const type2label = {
          QC: 'InspectionDate',
          LOGISTICS_FREIGHT: 'ShippingDate',
          THE_CUSTOMER_TO_SIGN_FOR: 'DateOfReceipt',
        }
        return this.$t(`orderList.${type2label[nodeType]}`)
      },
      // 标记特殊节点
      _markSpecial(node) {
        if (SPECIAL_NODES.includes(node.nodeType)) {
          node.specialNode = true
        }
      },
      // onDateChange(val, item) {
      //   if (!val || this._dateCompare(item)) {
      //     //清空延期时间并且清除勾选

      //     item.deferredDays = undefined
      //     item.deferred = 0
      //   }
      //   const { realDate, dueDate } = item
      //   if (!realDate || !dueDate) return
      //   item.deferredDays = this._getDeferredDays(item)
      // },

      onToggleChecked(val, item) {
        if (val === 0) {
          item.deferredDays = undefined
        } else {
          const { realDate, dueDate } = item
          if (!realDate || !dueDate) return
          item.deferredDays = this._getDeferredDays(item)
        }
      },

      showDetail() {
        if (this.lang == 'en') {
          return
        }
        navigateTo(this, {
          pageType: PAGE_TYPES.trackDetail,
          roleJudge: false,
          orderTrackingId: this.current.orderTrackingId,
        })
      },

      _getMsg() {
        const isEn = this.lang === 'en'
        return `${
          isEn
            ? 'The data changes, do you want to save it?'
            : '数据发生变化，是否保存?'
        }`
      },

      _simpleConfirm() {
        return this.$confirm(
          this._getMsg(),
          `${this.lang === 'en' ? 'Info' : '提示'}`,
          {
            confirmButtonText: `${this.lang === 'en' ? 'Save' : '保存'}`,
            cancelButtonText: `${this.lang === 'en' ? 'No Save' : '不保存'}`,
            type: 'warning',
          }
        )
      },
      changeIndex(index) {
        if (
          (this.currentIndex === 0 && index == -1) ||
          (this.currentIndex === this.ids.length - 1 && index == 1)
        ) {
          return
        }
        if (this._isChange() && !this.isSaved) {
          this._simpleConfirm()
            .then(() => {
              this.save()
            })
            .catch(() => {
              this.currentIndex += index
            })
        } else {
          this.currentIndex += index
        }
      },
      initData(ids) {
        this.visible = true
        this.ids = ids
        this.currentIndex = 0
      },

      uploadList() {},

      beforeClose(done) {
        if (this._isChange() && !this.isSaved) {
          this._simpleConfirm()
            .then(() => {
              this.save(done)
            })
            .catch(() => {
              this.visible = false
              this._clearData()
            })
        } else {
          this.visible = false
          this._clearData()
        }
      },
      back() {
        if (this._isChange() && !this.isSaved) {
          this._simpleConfirm()
            .then(() => {
              this.save()
            })
            .catch(() => {
              this.visible = false
              this._clearData()
            })
        } else {
          this.visible = false
          this._clearData()
        }
      },
      transformReqData() {
        let reqData = JSON.parse(JSON.stringify(this.detail))
        reqData.nodeDTO = reqData.orderTrackingNodeVOList.map((item) => {
          const { dueDate, realDate } = item
          if (+new Date(realDate) < +new Date(dueDate)) {
            item.deferredDays = ''
          }
          return item
        })
        // let qc = reqData.nodeDTO.find((item) => item.nodeType === 'QC')
        // if (qc) {
        //   if (qc.actualResults) {
        //     qc.inspectionResult = qc.actualResults
        //   }
        // }
        reqData.productDTO = reqData.orderTrackingProductVOList
        delete reqData.orderTrackingNodeVOList
        delete reqData.orderTrackingProductVOList
        return reqData
      },
      save(cb) {
        this.$refs.nodeform.validate((res) => {
          if (res) {
            if (this.checkRealDate()) {
              updateTrackingPO(this.transformReqData()).then((res) => {
                if (res && res.code === '000000') {
                  this.isSaved = true
                  this.visible = false
                  this.$message.success(
                    this.lang === 'en' ? 'Save Successfully' : '保存成功'
                  )
                  typeof cb === 'function' && cb()
                  this._clearData()
                  this.$emit('node-save-success')
                }
              })
            }
          }
        })
      },

      _isChange() {
        if (this.hideSave) {
          return false
        }
        return (
          this.initNodeList !==
          JSON.stringify(this.detail.orderTrackingNodeVOList)
        )
      },

      _clearData() {
        this.detail = {}
        this.currentIndex = -1
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-drawer__body {
    overflow: hidden;
    box-sizing: border-box;
    .el-steps--vertical {
      height: auto;
    }
  }
  .wrapper {
    padding: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      justify-content: space-between;
      height: 45px;
      align-items: flex-start;
      .middle {
        width: 476px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
        i {
          cursor: pointer;
          padding: 0 5px;
          color: #409eff;
          &.disabled {
            cursor: not-allowed;
            color: gray;
          }
        }
      }

      .right {
        width: 120px;
        display: flex;
        flex-direction: column;
        padding-top: 22px;
      }
      .left {
        width: 80px;
      }
      button {
        padding: 2px 3px;
        color: #000000;
        background: none;
        border: 1px solid #eee;
        font-size: 12px;
        cursor: pointer;
        border-radius: 3px;
        &.back {
          margin-right: 10px;
        }
        &.save {
          background: #409eff;
          color: white;
        }
      }
    }

    .node-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      align-items: center;
      color: #000000 !important;
      width: 500px;
      .left {
        width: 130px;
      }
      .middle {
        margin-right: 5px;
        flex: 1;
      }
      .right {
        margin-right: 5px;
        width: 120px;
      }
    }
    .bottom {
      height: calc(100vh - 95px);
      overflow: auto;
      .no {
        position: absolute;
        z-index: 3;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: #409eff;
        font-size: 14px;
        color: white;
        background-color: #409eff;
        font-size: 14px;
        color: white;
        border-radius: 24px;
        top: 0;
        left: 0;
        text-align: center;
      }
    }

    .ml5 {
      margin-right: 5px;
    }
  }
  .no-line {
    text-decoration: none;
  }
  .red {
    color: red;
    font-size: 12px;
    text-align: center;
  }
  .sel {
    cursor: pointer;
  }
  .no-show {
    visibility: hidden;
  }
  .mask {
    position: absolute;
    top: 72px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: 9999999;
  }
  .mask-span {
    display: inline-block;
    font-size: 59px;
    color: red;
    z-index: 10000002;
    top: 220px;
    right: 10px;
    position: absolute;
    transform: rotate(45deg);
  }
</style>
