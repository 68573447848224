<template>
  <div class="tab-content">
    <el-form ref="form" :model="form" :rules="rules" size="small" inline>
      <el-row class="setting-item">
        <el-checkbox v-model="form.delayedSendFlag" :true-label="1" :false-label="0"></el-checkbox>
        <span class="desc">
          <!-- 延迟 -->
          {{ lang === 'en' ? 'delay' : '延迟' }}
        </span>
        <el-form-item prop="delayedSend" :style="{ width: lang === 'en' ? '200px' : '150px' }">
          <el-input v-model.number="form.delayedSend" style="width: 100%"
            :placeholder="$t('placeholder.plsInput')"></el-input>
        </el-form-item>
        <span class="desc">
          <!-- 分钟发送邮件。 -->
          {{
          lang === 'en' ? 'minutes before send email。 ' : '分钟发送邮件。'
          }}
        </span>
        <span class="tips">
          <!-- 邮件在发送之前，可在草稿箱查看或取消发送。 -->
          {{
          lang === 'en'
          ? 'Messages can be viewed or unsent in the draft box before being sent. '
          : '邮件在发送之前，可在草稿箱查看或取消发送。'
          }}
        </span>
      </el-row>
      <el-row class="setting-item">
        <el-checkbox v-model="form.autoSaveIntervalFlag" :true-label="1" :false-label="0"></el-checkbox>
        <span class="desc">
          <!-- 写邮件时，每隔 -->
          {{ lang === 'en' ? 'When writing emails, every' : '写邮件时，每隔 ' }}
        </span>
        <el-form-item prop="autoSaveInterval" :style="{ width: lang === 'en' ? '200px' : '150px' }">
          <el-input :style="{ width: lang === 'en' ? '200px' : '150px' }" v-model.number="form.autoSaveInterval"
            :placeholder="$t('placeholder.plsInput')"></el-input>
        </el-form-item>
        <span class="desc">
          <!-- 分钟自动保存邮件。 -->
          {{
          lang === 'en'
          ? 'Minutes Automatically saves emails.'
          : '分钟自动保存邮件。'
          }}
        </span>
        <span class="tips">
          <!-- 邮件未发送前，会自动保存至草稿。 -->
          {{
          lang === 'en'
          ? 'The email is automatically saved to the draft before being sent.'
          : '邮件未发送前，会自动保存至草稿。'
          }}
        </span>
      </el-row>
      <!-- 昵称 -->
      <el-row class="setting-item">
        <el-checkbox v-model="form.nickNameFlag" :true-label="1" :false-label="0"></el-checkbox>
        <span class="desc">
          <!-- 发信昵称设置： -->
          {{ lang === 'en' ? 'Sending nickname Settings:' : '发信昵称设置：' }}
          <!-- content="收件人显示为设置后的发信昵称" -->
          <el-tooltip effect="light" :content="
              lang === 'en'
                ? 'The recipient is displayed as the configured sending nickname'
                : '收件人显示为设置后的发信昵称'
            " placement="top">
            <i class="iconfont icon-wenhao"></i>
          </el-tooltip>
        </span>
        <el-form-item prop="autoSaveInterval" :style="{ width: lang === 'en' ? '200px' : '150px' }">
          <el-input style="width: 155px" :style="{ width: lang === 'en' ? '200px' : '150px' }" v-model="form.nickName"
            :maxlength="50" :placeholder="$t('placeholder.plsInput')"></el-input>
        </el-form-item>
      </el-row>
      <el-row class="setting-item">

        <el-checkbox v-model="form.searchFilterFlag" :true-label="1" :false-label="0"></el-checkbox>
        <span class="desc">
          {{ lang === 'en' ? 'Search and filter the "All Mail" file.' : '搜索过滤“所有邮件”文件。' }}
        </span>
        <span class="tips">{{ lang === 'en' ? '(This is mainly to prevent the same email from appearing twice. When the
          search cannot be found, you can choose to uncheck it and save it and search again.)' :
          '(主要防止同一封邮件出现2次的情况，当搜索不出来时，可以选择去除勾选保存后再次重新搜索。)' }}</span>
      </el-row>
    </el-form>
    <div class="foot">
      <el-button plain @click="$router.go(-1)">
        <!-- 取消 -->
        {{ $t('boxsetting.Cancel') }}
      </el-button>
      <el-button type="primary" @click="submit">
        <!-- 保存 -->
        {{ $t('boxsetting.Confirm') }}
      </el-button>
    </div>
  </div>
</template>
<script>
  import {
    getMailOtherConfig,
    mailOtherConfigSave,
  } from '@/api/mail/mail-setting'
  import { mapState, mapGetters } from 'vuex'
  export default {
    data() {
      const self = this
      return {
        form: {
          delayedSend: '',
          autoSaveInterval: '',
          autoSaveIntervalFlag: 0,
          delayedSendFlag: 0,
          mailOtherConfigId: null,
          nickNameFlag: '',
          nickName: '',
          searchFilterFlag: 1
        },
        rules: {
          delayedSend: [
            {
              validator: (rule, value, cb) => {
                if (this.form.delayedSendFlag) {
                  if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                    // cb(new Error('请输入正整数'))
                    cb(
                      new Error(
                        self.lang === 'en'
                          ? 'Please enter a positive integer'
                          : '请输入正整数'
                      )
                    )
                  } else {
                    cb()
                  }
                } else {
                  cb()
                }
              },
              trigger: 'blur',
            },
          ],
          autoSaveInterval: [
            {
              validator: (rule, value, cb) => {
                if (this.form.autoSaveIntervalFlag) {
                  if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                    // cb(new Error('请输入正整数'))
                    cb(
                      new Error(
                        self.lang === 'en'
                          ? 'Please enter a positive integer'
                          : '请输入正整数'
                      )
                    )
                  } else {
                    cb()
                  }
                } else {
                  cb()
                }
              },
              trigger: 'blur',
            },
          ],
        },
      }
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
        accountList: (state) =>
          state.mail.filter((item) => item.authStatus === null),
      }),
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    created() {
      this.pageInit()
    },
    methods: {
      pageInit() {
        getMailOtherConfig({
          businessId: this.userInfo.userId,
        }).then((res) => {
          if (res.code === '000000') {
            if (res.data && res.data.length) {
              let config = res.data[0]
              for (const k in this.form) {
                if (Object.hasOwnProperty.call(this.form, k)) {
                  this.form[k] = config[k]
                }
              }
            }
          }
        })
      },
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            mailOtherConfigSave({
              businessId: this.userInfo.userId,
              ...this.form,
            }).then((res) => {
              if (res.code == '000000') {
                // this.$message.success('保存成功！')
                this.$message.success(this.$t('reqmsg.$9'))
                this.pageInit()
              }
            })
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .tab-content {
    overflow-y: auto;
    height: calc(100vh - 200px);
    padding-top: 20px;
  }

  .setting-item {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
    padding-left: 20px;

    .el-checkbox {
      margin-right: 10px;
    }

    .el-form-item {
      margin-bottom: 0;
      margin-left: 10px;
      // width: 300px;
    }

    .desc {
      color: #333;
    }

    .tips {
      color: #999;
    }
  }

  .foot {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
</style>