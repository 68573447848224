<template>
  <el-dialog :title="isCancel ? '取消出运' : '提示'" :visible.sync="dialogFormVisible" width="1200px" @close="close"
    :append-to-body="false" :close-on-click-modal="false">
    <div class="dialog-content">
      <div v-if="isCancel">
        若取消出运，请将已出库的产品退回至仓库（出库中的产品会自动撤销）：
      </div>
      <div v-else>
        对修改出运数量的产品执行以下操作（出库中的产品系统会自动调整）：
      </div>
      <div class="table-wrapper">
        <div style="margin-bottom: 10px; margin-left: 22px" v-if="isCancel">
          请设置退货入库仓库：
        </div>
        <div style="margin-bottom: 10px; margin-left: 22px" v-else>
          将扣减的出运产品退还至仓库：
        </div>
        <el-form ref="cancelForm" :model="cancelForm" :rules="cancelFormRules">
          <!-- :row-class-name="handleRowCLass" -->
          <el-table border stripe :data="cancelForm.tableData">
            <el-table-column align="center" :label="isCancel ? 'SKU' : '产品编码'" show-overflow-tooltip="" width="120px">
              <template slot-scope="scope">
                <el-link type="primary" :underline="false" @click="skuIdClick(scope.row.sku)">
                  {{ scope.row.sku }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column align="center" label="产品名称" show-overflow-tooltip prop="productCn"></el-table-column>
            <el-table-column align="center" label="出库单号" width="120px" show-overflow-tooltip=""
              prop="warehouseOutNumber">
              <template slot-scope="scope">
                <el-link type="primary" :underline="false" v-if="scope.row.warehouseOutNumber"
                  @click="ckClick(scope.row.warehouseOutNumber)">
                  {{ scope.row.warehouseOutNumber }}
                </el-link>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="出库仓库" prop="warehouseName">
              <template slot-scope="scope">
                {{ scope.row.warehouseName ? scope.row.warehouseName : '--' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="出库状态">
              <template slot-scope="scope">
                {{ scope.row.shippingOrderProductStatus | stateFilter }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="入库仓库" width="120px">
              <template slot-scope="scope">
                <template v-if="!scope.row.warehouseName">
                  <span>--</span>
                </template>
                <template v-else>
                  <el-form-item :prop="'tableData.' + scope.$index + '.inStockId'" :rules="cancelFormRules.inStockId"
                    style="width: 100px; margin-bottom: 0" v-if="
                      scope.row.shippingOrderProductStatus === 1 ||
                      scope.row.shippingOrderProductStatus === 0
                    ">
                    <el-select v-model="scope.row.inStockId" placeholder="入库仓库" style="width: 100px" clearable @change="
                        warehouseChange(scope.row.inStockId, scope.$index)
                      " :disabled="scope.row.shippingOrderProductStatus === 0">
                      <el-option v-for="item in whs" :key="item.warehouseId" :value="item.warehouseId"
                        :label="item.name"></el-option>
                    </el-select>
                  </el-form-item>
                </template>

                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="退回数量" prop="returnNum">
              <template slot-scope="scope">
                <span v-if="
                    scope.row.shippingOrderProductStatus != 2 &&
                    scope.row.shippingOrderProductStatus != 6
                  ">
                  {{ scope.row.returnNum }}
                  <i v-if="!isCancel && scope.row.isLotSel !== false" class="el-icon-edit"
                    style="color: #1890ff; cursor: pointer" @click="selLot(scope.row)"></i>
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="150px" label="产品是否可用">
              <template slot="header">
                产品是否可用
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <template slot="content">
                    <p>退货的产品是否可以进行再次销售</p>
                  </template>
                  <i class="iconfont el-icon-question c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-select v-if="
                    scope.row.shippingOrderProductStatus === 1 ||
                    scope.row.shippingOrderProductStatus === 0
                  " :disabled="
                    scope.row.shippingOrderProductStatus == 0 ||
                    scope.row.isEdit
                  " v-model="scope.row.usable" placeholder="请选择" style="width: 100px" clearable>
                  <el-option v-for="item in useful" :key="item.code" :value="item.code" :label="item.name"></el-option>
                </el-select>
                <span v-else>--</span>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="showReject" style="margin: 10px 0">
            出库驳回的产品请再次发起出库申请：
          </div>
          <el-table v-if="showReject" border stripe :data="cancelForm.tableData2" :row-class-name="handleRowCLass">
            <el-table-column align="center" :label="isCancel ? 'SKU' : '产品编码'" show-overflow-tooltip="" width="120px">
              <template slot-scope="scope">
                <el-link type="primary" :underline="false" @click="skuIdClick(scope.row.sku)">
                  {{ scope.row.sku }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column align="center" label="产品名称" show-overflow-tooltip prop="productCn"></el-table-column>
            <el-table-column align="center" label="可用库存" show-overflow-tooltip="" prop="warehouseOutNumber">
              <template slot-scope="scope">
                <p v-for="stock in scope.row.shippingPlanOrderProductStockVOS" :key="stock.storageId">
                  <span>{{ stock.storageName }}</span>
                  :
                  <span>{{ stock.stockNum }}</span>
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="申请出库数量" prop="outStockNum"></el-table-column>
            <el-table-column align="center" label="出库仓库" prop="warehouseName">
              <template slot="header">
                <span style="color: red">*</span>
                <span>出库仓库</span>
              </template>
              <template slot-scope="scope">
                <div style="
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  ">
                  <el-form-item :prop="'tableData2.' + scope.$index + '.storageId'" :rules="cancelFormRules.storageId"
                    style="width: 100px; margin-bottom: 0">
                    <el-select v-model="scope.row.storageId" placeholder="请选择" style="width: 100px" clearable>
                      <el-option v-for="item in scope.row
                          .shippingPlanOrderProductStockVOS" :key="item.storageId" :value="item.storageId"
                        :label="item.storageName"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>

            <el-table-column align="center" label="操作" width="100px">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                  <el-button type="danger" plain icon="el-icon-delete-solid" circle size="mini"
                    @click="deleteRejectPro(scope.$index)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <!-- 备注 -->
      <el-form label-position="top">
        <el-form-item label="原因说明">
          <el-input type="text-area" placeholder="请输入内容,字数500字以内" maxlength="500" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <h3 class="mt30 mb20">附件</h3>
      <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList" @initUpload="initUpload"
        style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"></ErpUpload>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
    <!-- 选择入库批次 -->
    <el-dialog title="入库批次" :visible="lotSel" width="680px" @close="closeLot" append-to-body custom-class="inner-class"
      :close-on-click-modal="false">
      <el-table :data="currentRow.shippingOrderProductLotDTOList" border stripe>
        <el-table-column align="center" label="入库批次" prop="lotNo"></el-table-column>
        <el-table-column align="center" label="出库数量" prop="maxNum"></el-table-column>
        <el-table-column align="center" label="采购单号" prop="purchaseNumber" :formatter="formatter"></el-table-column>
        <el-table-column align="center" label="采购员" prop="buyer" :formatter="formatter"></el-table-column>
        <el-table-column align="center" label="退回数量" prop="buyer" width="150px">
          <template slot-scope="scope">
            <el-input-number style="width: 100px" :controls="false" :precision="0" :min="0" :max="scope.row.maxNum"
              v-model="scope.row.returnNum"></el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-row class="text-center">
          <el-button type="primary" @click="saveLot">确 认</el-button>
          <el-button @click="cancelLot">取 消</el-button>
        </el-row>
      </template>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import { statusListMap } from '../helper/data'
  import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'

  export default {
    name: 'CancelShipping',
    props: {
      isCancel: {
        type: Boolean,
        default: true,
      },
      showReject: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        title: '取消出运',
        lotSel: false, // 入库批次选择弹窗
        currentRow: {}, //当前选中行
        dialogFormVisible: false,
        value: null,
        isUpdata: false,
        cancelForm: {
          tableData: [],
          tableData2: [],
        },
        cancelFormRules: {
          inStockId: {
            required: true,
            message: '请选择',
          },
          storageId: {
            required: true,
            message: '请选择',
          },
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
          amount: 9,
        },
        whs: [],
        useful: [
          {
            code: '0',
            name: '否',
          },
          {
            code: '1',
            name: '是',
          },
        ],
        remark: '',
      }
    },
    created() {
      ShipmentMangeInteractor.findWarehouseList().then((res) => {
        if (res && res.code === '000000') {
          this.whs = res.data || []
        }
      })
    },
    methods: {
      formatter(row, column, cellValue, index) {
        return cellValue ? cellValue : '--'
      },
      saveLot() {
        // 数量校验
        const shippingOrderProductLotDTOList =
          this.currentRow.shippingOrderProductLotDTOList
        const lotReturnNum = shippingOrderProductLotDTOList
          .filter((i) => i.returnNum)
          .reduce((prev, next) => {
            prev += next.returnNum
            return prev
          }, 0)

        if (isNaN(lotReturnNum) || lotReturnNum === 0) {
          return this.$message.warning(
            '至少指定一个入库批次的退回数量不为空或0'
          )
        }
        if (lotReturnNum !== this.currentRow.returnNum) {
          return this.$message.warning(
            `入库批次退回数量${lotReturnNum}和产品退回数量${this.currentRow.returnNum}不相等`
          )
        } else {
          shippingOrderProductLotDTOList.forEach((item) => {
            item.warehouseId = this.currentRow.inStockId
            item.num = item.returnNum
          })
        }
        this.lotSel = false
      },
      cancelLot() {
        this.currentRow.shippingOrderProductLotDTOList =
          this.initShippingOrderProductLotDTOList
        this.closeLot()
      },
      closeLot() {
        this.lotSel = false
      },
      // 选择批次
      selLot(row) {
        this.currentRow = row
        this.initShippingOrderProductLotDTOList = JSON.parse(
          JSON.stringify(this.currentRow.shippingOrderProductLotDTOList)
        )
        if (!row.inStockId) {
          return this.$message.warning('请选择入库仓库')
        }
        // this.currentRow.shippingOrderProductLotDTOList =
        //   this.currentRow.shippingOrderProductLotDTOList.map((item) => {
        //     item.returnNum = undefined
        //     return item
        //   })

        this.lotSel = true
      },
      // 删除驳回产品
      deleteRejectPro(index) {
        this.cancelForm.tableData2.splice(index, 1)
      },
      handleRowCLass({ row, rowIndex }) {
        if (row && row.shippingOrderProductStatus == '2') {
          return 'hidden'
        }
      },
      //产品编码click
      skuIdClick(skuId) {
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        let response = await ShipmentMangeInteractor.productIdByCode({
          productCode: id,
        })
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },
      ckClick(warehouseOutId) {
        let routeData = this.$router.resolve({
          path: '/stock-center/stock-center/outbound-sheet/outbound-sheet-detail',
          query: {
            warehouseOutId,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
      //取消出运
      showAddEdit(row) {
        this.dialogFormVisible = true
      },

      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
      uploadList(fileList) {
        // this.files = JSON.stringify(fileList)
        this.files = fileList
      },

      close() {
        this.dialogFormVisible = false
        this.$emit('dialog-cancel')
        this.$refs.cancelForm.clearValidate()
      },
      save() {
        this.$refs.cancelForm.validate((flag) => {
          if (flag) {
            // 不是取消出运时校验
            if (!this.isCancel) {
              let tableData = this.cancelForm.tableData
              for (let i = 0; i < tableData.length; i++) {
                let { returnNum, warehouseId } = tableData[i]
                let lotReturnNum = tableData[i].shippingOrderProductLotDTOList
                  .filter((i) => i.returnNum)
                  .reduce((prev, next) => {
                    prev += next.returnNum
                    return prev
                  }, 0)
                if (returnNum !== lotReturnNum && warehouseId) {
                  return this.$message.warning(
                    `退回数量和入库批次退回数量不相等`
                  )
                }
              }
            }

            let fileObj = {}
            // 处理出运数量变化的数据
            this.cancelForm.tableData = this.cancelForm.tableData.map((td) => {
              if (td.shippingOrderProductStatus === 1) {
                td.inStockName = this.whs.find(
                  (item) => item.warehouseId === td.inStockId
                ).name
              }
              return td
            })
            // 处理驳回的产品
            this.cancelForm.tableData2 = this.cancelForm.tableData2.map(
              (td) => {
                td.storageName = td.shippingPlanOrderProductStockVOS.find(
                  (item) => item.storageId === td.storageId
                ).storageName
                return td
              }
            )
            if (this.files && this.files.length > 0) {
              fileObj.originFileUrls = JSON.stringify(this.files)
              fileObj.originFileNames = ''
              fileObj.mark = this.remark
            }
            this.$emit(
              'dialog-confirm',
              this.cancelForm.tableData,
              fileObj,
              this.cancelForm.tableData2
            )
            this.dialogFormVisible = false
          }
        })
      },
      // 选择入库仓库
      warehouseChange(id, i) {
        const r = this.whs.find((item) => item.warehouseId === id)
        if (r && r.belong === '2') {
          this.cancelForm.tableData[i].usable = '1'
          this.cancelForm.tableData[i].isEdit = true
        } else {
          this.cancelForm.tableData[i].isEdit = false
        }
      },
    },
    filters: {
      stateFilter(val) {
        // let map = {
        //   // 0: '出库中',
        //   // 1: '已出库',
        //   // 2: '出库驳回',
        // }
        return statusListMap[val] ? statusListMap[val] : '--'
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }

    .table-wrapper {
      background-color: #f5f7fa;
      padding: 15px;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }

    tr.el-table__row.hidden {
      display: none;
    }
  }

  tr.el-table__row.hidden {
    display: none;
  }

  .inner-class {
    /* height: 230px !important;
    padding: 15px 15px 0 15px; */
  }
</style>
