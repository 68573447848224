<template>
  <el-dialog
    :title="
      customerComplainId ? $t('cusCenter.editTitle') : $t('cusCenter.newTitle')
    "
    width="1200px"
    class="concent"
    :visible.sync="params.show"
    :close-on-click-modal="false"
    :before-close="close"
    height="90%"
    append-to-body
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      :label-position="lang === 'en' ? 'top' : 'right'"
    >
      <el-row>
        <el-col :span="6">
          <!-- 评价类型 -->
          <el-form-item
            prop="evaluationType"
            :label="$t('cusCenter.Evaluationtype')"
            label-width="80px"
            :rules="[
              {
                required: true,
                trigger: ['blur', 'change'],
                message: $t('placeholder.plsSel'),
              },
            ]"
          >
            <el-select
              v-model="form.evaluationType"
              clearable
              :class="[lang == 'en' ? 'w180' : 'w160']"
            >
              <el-option
                v-for="item in evaluationTypeList"
                :key="item.code"
                :label="lang === 'en' ? item.nameEn : item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 评价原因 -->
          <el-form-item
            prop="reason"
            label-width="80px"
            :label="$t('cusCenter.evaluationReason')"
            :rules="[
              {
                required: true,
                trigger: ['blur', 'change'],
                message: $t('placeholder.plsSel'),
              },
            ]"
          >
            <el-select
              v-model="form.reason"
              clearable
              :class="[lang == 'en' ? 'w180' : 'w160']"
            >
              <el-option
                v-for="item in computedEvaluationReasonList"
                :key="item.code"
                :label="lang === 'en' ? item.nameEn : item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--原因说明 -->
          <el-form-item
            prop="reasonExplain"
            label-width="80px"
            :label="$t('cusCenter.CauseDescription')"
            :rules="[
              {
                required: true,
                trigger: ['blur', 'change'],
                message: $t('placeholder.plsSel'),
              },
            ]"
          >
            <el-select
              v-model="form.reasonExplain"
              clearable
              :class="[lang == 'en' ? 'w180' : 'w160']"
            >
              <el-option
                v-for="item in computedEvaluationCauseList"
                :key="item.code"
                :label="lang === 'en' ? item.nameEn : item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <!-- label="评价来源" -->
          <el-form-item
            label-width="80px"
            prop="evaluationSource"
            :label="$t('cusCenter.EvaluationSource')"
            :rules="[
              {
                required: true,
                trigger: ['blur', 'change'],
                message: $t('placeholder.plsSel'),
              },
            ]"
          >
            <el-select
              v-model="form.evaluationSource"
              clearable
              :class="[lang == 'en' ? 'w180' : 'w160']"
            >
              <el-option
                v-for="item in evaluationSourceList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- label="评价等级" -->
          <el-form-item
            prop="level"
            :label="$t('cusCenter.EvaluationLevel')"
            :rules="[
              {
                required: true,
                trigger: ['blur', 'change'],
                message: $t('placeholder.plsSel'),
              },
            ]"
          >
            <el-select
              v-model="form.level"
              clearable
              :class="[lang == 'en' ? 'w180' : 'w160']"
            >
              <el-option
                v-for="item in computedRatingList"
                :key="item.code"
                :value="item.code"
                :label="lang === 'en' ? item.nameEn : item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- label="评价日期" -->
          <el-form-item
            label-width="80px"
            prop="evaluationDate"
            :label="$t('cusCenter.EvaluationDate')"
            :rules="[
              {
                required: true,
                trigger: ['blur', 'change'],
                message: $t('placeholder.plsSel'),
              },
            ]"
          >
            <el-date-picker
              :placeholder="lang == 'en' ? 'please select' : '请选择'"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.evaluationDate"
              maxlength="50"
              clearable
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <!-- 客户邮箱 -->
          <el-form-item
            label-width="80px"
            prop="releatedCustomerEmail"
            :label="$t('cusCenter.CustomerMailbox')"
            :rules="[
              {
                required: true,
                trigger: 'change',
                message: $t('placeholder.plsSel'),
              },
            ]"
          >
            <el-select
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.releatedCustomerEmail"
              filterable
              remote
              clearable
              reserve-keyword
              :placeholder="$t('placeholder.plsSel')"
              :remote-method="querySearchAsyncLinkman"
              @focus="(val) => getCusAll(val, 'linkman')"
              value-key="customerCode"
              @change="(val) => changeEmail(val)"
              popper-class="cssbb"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.email"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 客户名称 -->
          <el-form-item
            label-width="80px"
            prop="releatedCustomer"
            :label="$t('cusCenter.CustomerName')"
            :rules="[
              {
                required: true,
                trigger: 'change',
                message: $t('placeholder.plsSel'),
              },
            ]"
          >
            <el-input
              :disabled="true"
              :placeholder="$t('placeholder.plsSel')"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.releatedCustomer"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 客户公司 -->
          <el-form-item
            label-width="80px"
            prop="companyName"
            :label="$t('cusCenter.CustomerCompany')"
          >
            <el-input
              :disabled="true"
              :placeholder="$t('placeholder.plsSel')"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.companyName"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 客户电话 -->
          <el-form-item
            label-width="80px"
            prop="releatedCustomerPhone"
            :label="$t('cusCenter.CustomerPhone')"
          >
            <el-input
              :disabled="true"
              :placeholder="$t('placeholder.plsSel')"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.releatedCustomerPhone"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <!-- 销售订单 -->
          <el-form-item
            label-width="80px"
            prop="orderNumber"
            :label="$t('cusCenter.SalesOrder')"
          >
            <el-input
              @input="handleOrderNumberInput"
              :placeholder="$t('placeholder.plsInput')"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.orderNumber"
              maxlength="20"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 产品名称 -->
          <el-form-item
            label-width="80px"
            prop="productCn"
            :label="$t('cusCenter.ProductName')"
          >
            <template v-if="productList.length > 1">
              <el-select
                v-if="lang === 'en'"
                v-model="form.productEn"
                :placeholder="$t('placeholder.plsSel')"
                @change="productChange"
                clearable
                class="w180"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.sku"
                  :value="item.sku"
                  :label="item.productEn"
                ></el-option>
              </el-select>
              <el-select
                v-else
                v-model="form.productCn"
                :placeholder="$t('placeholder.plsSel')"
                @change="productChange"
                clearable
                class="w160"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.sku"
                  :value="item.sku"
                  :label="item.productCn"
                ></el-option>
              </el-select>
            </template>
            <template v-else>
              <div class="sel-pro">
                <el-input
                  v-if="lang === 'en'"
                  v-model="form.productEn"
                  :placeholder="$t('placeholder.plsSel')"
                  :class="[lang == 'en' ? 'w180' : 'w160']"
                  maxlength="50"
                  clearable
                ></el-input>
                <el-input
                  v-else
                  v-model="form.productCn"
                  :placeholder="$t('placeholder.plsSel')"
                  :class="[lang == 'en' ? 'w180' : 'w160']"
                  maxlength="50"
                  clearable
                ></el-input>
                <i
                  v-show="!productList.length"
                  class="el-icon-circle-plus-outline"
                  @click="proVisible = true"
                ></i>
              </div>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 产品编码 -->
          <el-form-item
            label-width="80px"
            prop="sku"
            :label="$t('cusCenter.ProductCode')"
          >
            <el-input
              :disabled="true"
              :placeholder="$t('placeholder.plsSel')"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.sku"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 产品数量 -->
          <el-form-item
            label-width="80px"
            prop="productNum"
            :label="$t('cusCenter.Quantity')"
            :rules="[
              {
                pattern: /^[0-9]+?$/,
                message: $t('placeholder.integer'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              :placeholder="$t('placeholder.plsInput')"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.productNum"
              maxlength="9"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <!--客户订单号 -->
          <el-form-item
            label-width="80px"
            prop="customerOrderCode"
            :label="$t('cusCenter.CustomerOrderNumber')"
          >
            <el-input
              :placeholder="$t('placeholder.plsInput')"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              v-model="form.customerOrderCode"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--公司抬头 -->
          <el-form-item
            label-width="80px"
            prop="exportsRise"
            :label="$t('cusCenter.ExportsRise')"
          >
            <el-select
              v-model="form.exportsRise"
              :placeholder="$t('placeholder.plsSel')"
              :class="[lang == 'en' ? 'w180' : 'w160']"
              clearable
            >
              <el-option
                v-for="item in exportsRiseList"
                :key="item.amountShort"
                :label="item.amountShort"
                :value="item.amountShort"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 客户代表 -->
          <el-form-item
            prop="salesman"
            :label="$t('cusCenter.CustomerRepresentative')"
          >
            <el-input
              v-if="lang === 'en'"
              class="w180"
              v-model="form.salesmanEn"
              maxlength="200"
              clearable
              placeholder="please select"
              @focus="userSel('salesman')"
            ></el-input>
            <el-input
              v-else
              class="w160"
              v-model="form.salesman"
              maxlength="200"
              clearable
              :placeholder="$t('placeholder.plsSel')"
              @focus="userSel('salesman')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="18">
          <!-- 反馈内容-->
          <el-form-item
            label-width="80px"
            :label="$t('cusCenter.Feedbackcontent')"
            prop="concent"
            :rules="[
              {
                required: true,
                trigger: ['blur', 'change'],
                message: $t('placeholder.plsInput'),
              },
            ]"
          >
            <el-input
              v-model="form.concent"
              type="textarea"
              maxlength="5000"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 附件-->
      <el-form-item :label="$t('cusCenter.appendix')" prop="concent">
        <ErpUpload
          ref="ErpUpload"
          :uploadParams="uploadParams"
          @uploadList="uploadList"
          @initUpload="initUpload"
          style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
        ></ErpUpload>
      </el-form-item>
    </el-form>

    <div class="text-center">
      <el-button type="primary" :loading="loading" @click="save">
        {{ $t('cusCenter.Confirm') }}
      </el-button>
      <el-button @click="close()">{{ $t('cusCenter.Cancel') }}</el-button>
    </div>

    <!-- 选取产品 -->
    <el-dialog
      width="1200px"
      :title="$t('editInquiry.SelectProduct')"
      :visible.sync="proVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <product-list
        v-if="proVisible"
        :requestType="true"
        :single="true"
        @getProductData="getProductData"
        @productClose="proVisible = false"
      ></product-list>
    </el-dialog>

    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {
    evaluationTypeList,
    evaluationSourceList,
    evaluationReasonList,
    evaluationLevelList,
    evaluationCauseList,
  } from '@/views/customer-manage/helper/data'
  import { orderGetDict, selectAllCustomerNoAuth } from '@/api/order'
  import {
    insertPO,
    complainOrderProductDTO,
    getDetail,
    updateCustomerComplainPO,
  } from '@/api/customer-manage/customer-center'
  import productList from '@/components/productList'
  import UserChoose from '@/components/userChoose'
  import debounce from 'lodash/debounce'

  export default {
    name: 'ReviewAdd',
    props: {
      params: {
        type: Object,
        default: () => ({
          show: false,
        }),
      },
    },
    data() {
      return {
        loading: false,
        customerComplainId: '',
        form: {
          evaluationType: 0, // 评价类型
          reason: '', // 评价原因
          reasonExplain: '', // 原因说明
          evaluationSource: '', // 评价来源
          level: '', // 评价等级
          evaluationDate: new Date(), // 评价日期
          releatedCustomerEmail: '', // 客户邮箱
          releatedCustomer: '', // 客户名称
          releatedCustomerEn: '', // 客户名称英文
          releatedCustomerId: '', // 客户名称Id
          companyName: '', // 客户公司
          company: '', // 客户公司Id
          releatedCustomerPhone: '', // 客户电话
          orderNumber: '', // 销售订单
          productCn: '', // 产品名称
          productEn: '', // 产品名称英文
          sku: '', // 产品编码
          productNum: '', // 产品数量
          customerOrderCode: '', // 客户订单号
          exportsRise: '', // 公司抬头
          salesman: '', // 客户代表
          salesmanEn: '', // 客户代表（英文）
          salesmanId: '', // 客户代表id
          concent: '', // 客户反馈内容
          fileAnnex: '',
        },
        visible: false,
        rules: {},
        currentIndex: 0,
        evaluationTypeList,
        evaluationSourceList,
        evaluationReasonList,
        evaluationLevelList,
        evaluationCauseList,
        options: [],
        exportsRiseList: [],
        productList: [],
        proVisible: false,
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
          amount: 9,
        },
        handleOrderNumberInput: null,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      // 评价原因
      computedEvaluationReasonList() {
        if (!this.form.evaluationType && this.form.evaluationType !== 0) {
          return []
        }
        if (this.form.evaluationType === 0) {
          return this.evaluationReasonList.filter((item) => item.code > 20)
        } else {
          return this.evaluationReasonList.filter((item) => item.code < 20)
        }
      },
      // 原因说明
      computedEvaluationCauseList() {
        const reason = this.form.reason

        if (!reason) {
          return []
        }
        if (reason === 11) {
          return this.evaluationCauseList.filter((item) => item.code < 20)
        } else if (reason === 12) {
          return this.evaluationCauseList.filter(
            (item) => item.code > 20 && item.code < 30
          )
        } else if (reason === 13) {
          return this.evaluationCauseList.filter(
            (item) => item.code > 30 && item.code < 40
          )
        } else if (reason === 21) {
          return this.evaluationCauseList.filter(
            (item) => item.code > 40 && item.code < 50
          )
        } else {
          return this.evaluationCauseList.filter((item) => item.code > 50)
        }
      },
      computedRatingList() {
        if (!this.form.evaluationSource) {
          return []
        }
        if (this.form.evaluationType === 0) {
          return this.evaluationLevelList
            .filter((item, index) => index < 14)
            .filter((item, index) =>
              item.sourceKey.includes(this.form.evaluationSource)
            )
        } else {
          return this.evaluationLevelList.filter((item, index) => index > 13)
        }
      },
    },
    components: {
      UserChoose,
      productList,
    },
    watch: {
      'form.evaluationType': function (val) {
        this.form.reason = ''
        this.form.reasonExplain = ''
        this.form.evaluationSource = ''
      },
      'form.reason': function (val) {
        this.form.reasonExplain = ''
      },
      'form.evaluationSource': function (val) {
        this.form.level = ''
      },
      'form.productCn': function (val) {
        if (!val) {
          this.form.productEn = ''
          this.form.sku = ''
          this.form.productNum = ''
        }
      },
      'form.productEn': function (val) {
        if (!val) {
          this.form.productCn = ''
          this.form.sku = ''
          this.form.productNum = ''
        }
      },
      'form.orderNumber': function (val) {
        if (!val) {
          this.form.sku = ''
          this.form.productCn = ''
          this.form.productEn = ''
          this.form.productNum = ''
          this.form.customerOrderCode = ''
          this.productList = []
          this.form.exportsRise = '' // 公司抬头
          this.form.salesman = '' // 客户代表
          this.form.salesmanEn = '' // 客户代表（英文）
          this.form.salesmanId = '' // 客户代表id
        }
      },
    },
    created() {
      this.getDict()
      this.handleOrderNumberInput = debounce(() => {
        this.getProductInfo()
      }, 500)
    },
    beforeDestroy() {
      this.handleOrderNumberInput = null
    },
    methods: {
      // 获取字典
      getDict() {
        // 获取出口抬头
        orderGetDict({ amountType: 1 }).then((res) => {
          res?.data?.forEach((item) => {
            if (item.amountStatus == 1) {
              this.exportsRiseList.push({ ...item })
            }
          })
        })
      },

      // 选中客户邮箱获取基本信息
      changeEmail(_obj) {
        this.form.releatedCustomerEmail = _obj.email
        this.form.releatedCustomer = _obj.linkman
        this.form.releatedCustomerEn = _obj.linkman
        this.form.releatedCustomerId = _obj.infoId
        this.form.companyName = _obj.companyName
        this.form.company = _obj.companyId
        this.form.releatedCustomerPhone = _obj.phone
        this.options = []

        this.getProductInfo()
      },
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
      uploadList(fileList) {
        this.form.fileAnnex = JSON.stringify(fileList)
      },
      getCusAll(val, type) {
        let companyName = '',
          reqData = {
            highSeasType: 2,
          }

        reqData[type] =
          this.form[
            type === 'linkman'
              ? typeof this.form.releatedCustomer == 'object'
                ? this.form.releatedCustomer.linkman
                : this.form.releatedCustomer
              : 'companyName'
          ]

        if (!reqData.companyName) {
          reqData.limit = 10
        }

        selectAllCustomerNoAuth(reqData).then((res) => {
          if (res.code === '000000') {
            this.options = res.data || []
          }
        })
      },
      querySearchAsyncLinkman(queryString) {
        selectAllCustomerNoAuth({
          email: queryString,
          highSeasType: 2,
        }).then((res) => {
          if (res.code === '000000') {
            this.options = res.data || []
          }
        })
      },
      // 客户模糊搜索
      querySearchAsync(queryString) {
        selectAllCustomerNoAuth({
          companyName: queryString,
          highSeasType: 2,
        }).then((res) => {
          if (res.code === '000000') {
            this.options = res.data || []
          }
        })
      },

      // 添加产品
      getProductData(_arr) {
        this.form.productCn = _arr[0].nameCn
        this.form.productEn = _arr[0].nameEn
        this.form.sku = _arr[0].skuId
        this.proVisible = false
      },

      // 根据订单查询产品信息
      getProductInfo() {
        const params = {
          infoId: this.form.releatedCustomerId,
          orderNumber: this.form.orderNumber,
        }

        if (params.infoId && params.orderNumber) {
          complainOrderProductDTO(params).then((res) => {
            if (res && res.code === '000000') {
              let [data, form] = [res.data, this.form]

              if (data.warningMessageCn) {
                this.$message.warning(
                  this.lang === 'en'
                    ? data.warningMessageEn
                    : data.warningMessageCn
                )
              }

              form.customerOrderCode = data.customerOrderCode // 客户订单号
              form.exportsRise = data.exportsRise // 公司抬头
              form.salesman = data.salesman // 客户代表
              form.salesmanEn = data.salesmanEn // 客户代表英文
              form.salesmanId = data.salesmanId // 客户代表id
              // 查询到一条产品直接带出
              if (data.products?.length === 1) {
                form.productCn = data.products[0].productCn
                form.productEn = data.products[0].productEn
                form.productNum = data.products[0].productNum
                form.sku = data.products[0].sku
              }
              this.productList = data.products || []
            }
          })
        }
      },

      // 选择产品带入产品信息
      productChange(val) {
        const _obj = this.productList.find((item) => item.sku === val)
        if(_obj) {
          this.form.productCn = _obj.productCn
          this.form.productEn = _obj.productEn
          this.form.sku = _obj.sku
          this.form.productNum = _obj.productNum
        }

      },

      //客户代表返回
      chooseUser(users) {
        if (Array.isArray(users) && users.length > 0) {
          const { id, name, englishName } = users[0]
          if (id == this.form.salesmanId) {
            return
          }
          this.form['salesman'] = name
          this.form['salesman' + 'Id'] = id
          this.form['salesman' + 'En'] = englishName
        }
      },

      userSel(userType) {
        const id = this.form['salesman' + 'Id'],
          name = this.form['salesman'],
          englishName = this.form['salesmanEn']
        this.$refs['UserChoose'].showAddEdit(
          '',
          id ? [{ id, name, englishName }] : '',
          true
        )
      },
      show(id) {
        id && this.getDetail(id)
        this.params.show = true
        this.form.evaluationDate = new Date()
      },
      // 获取编辑详情
      getDetail(id) {
        this.customerComplainId = id
        getDetail(id).then((res) => {
          if (res?.code === '000000') {
            const data = res.data

            this.form = {
              ...data,
              concent: data?.customerComplainRecordVOs[0]?.concent,
              fileAnnex: data?.customerComplainRecordVOs[0]?.fileAnnex,
            }
            this.form.fileAnnex && this.initUpload(this.form.fileAnnex)

            this.$nextTick(() => {
              this.form.reason = Number(res.data.reason)
              this.form.evaluationSource = res.data.evaluationSource
              // 原因说明、评价等级赋值添加宏任务，防止结果被覆盖
              setTimeout(() => {
                this.form.level = res.data.level
                this.form.reasonExplain = res.data.reasonExplain
              }, 0)
            })
          }
        })
      },
      // 获取保存的参数
      _getReqData(obj) {
        const reqData = JSON.parse(JSON.stringify(obj))

        return reqData
      },
      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const params = this.customerComplainId
              ? {
                  ...this.form,
                  customerComplainId: this.customerComplainId,
                  customerComplainRecordId:
                    this.form?.customerComplainRecordVOs[0]
                      ?.customerComplainRecordId,
                }
              : this.form
            const reqData = this._getReqData(params)
            const portRequest = this.customerComplainId
              ? updateCustomerComplainPO
              : insertPO
            this.loading = true
            portRequest(reqData).then((res) => {
              this.loading = false
              if (res?.code === '000000') {
                this.$message.success(this.$t('reqmsg.$7'))
                this.$emit('refresh')
                this.close()
              }
            })
          }
        })
      },

      // 关闭弹窗重置表单
      close() {
        this.currentIndex = 0
        this.customerComplainId = ''
        this.$refs.ruleForm.resetFields()
        this.$refs.ErpUpload.clearFiles()
        this.form = this.$options.data().form
        this.params.show = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .w160 {
    width: 160px;
  }
  .w180 {
    width: 180px;
  }
  .sel-pro {
    i {
      font-size: 24px;
      cursor: pointer;
      vertical-align: middle;
      color: #409eff;
      margin-left: 5px;
    }
  }
</style>

<style>
  .cssbb .el-select-dropdown__wrap {
    max-height: 300px !important;
  }
</style>
