import request from '@/core/services/request'

// 产品详情——询盘记录
export function getEnquiryProductVO(data) {
  return request({
    url: '/order/salesOrder/getEnquiryProductPageVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品详情——询盘记录列表
export function selectEnquiryProductPageVO(data) {
  return request({
    url: '/order/salesOrder/selectEnquiryProductPageVO',
    method: 'post',
    data,
  })
}

// 产品详情——订单记录
export function getOrderProductVO(data) {
  return request({
    url: '/order/salesOrder/getOrderProductPageVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品详情——订单记录列表
export function selectSalesOrderProductPageVO(data) {
  return request({
    url: '/order/salesOrder/selectSalesOrderProductPageVO',
    method: 'post',
    data,
  })
}

// 产品详情——采购记录
export function purchaseOrderForProduct(data) {
  return request({
    url: '/order/purchaseOrder/getPurchaseOrderForProduct',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品详情——根据采购需求单号查询采购需求单id
export function getPurchasingDemandId(data) {
  return request({
    url: '/order/purchasingDemandProduct/getSalesOrderId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
