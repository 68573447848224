<template>
  <el-dialog :visible.sync="ratioVisible" width="30%" center @close="cancel">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        prop="beginAmount"
        label="区间金额(开始)"
        align="center"
      ></el-table-column>
      <el-table-column prop="endAmount" label="区间金额(结束)" align="center">
        <template slot-scope="scope">
          {{ scope.row.endAmount ? scope.row.endAmount : '无穷大' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="ratio"
        label="提成点(%)"
        align="center"
      ></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">返 回</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { SettleConfigInteractor } from '@/core/interactors/finance/config-manage'
export default {
  data() {
    return {
      tableData: [],
    }
  },
  props: {
    ratioVisible: {
      default: false,
      type: Boolean,
    },
    ratioId: {
      default: '',
      type: String,
    },
  },
  watch: {
    ratioVisible(newVal, oldVal) {
      if (newVal) {
        this.getList(this.ratioId)
      }
    },
  },
  methods: {
    async getList() {
      this.tableData =
        await SettleConfigInteractor.getConfigConditionSettlementtiereds(
          this.ratioId
        )
    },
    cancel() {
      this.$emit('update:ratioVisible', false)
    },
  },
}
</script>
