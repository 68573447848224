<template>
  <div class="table-container">
    <el-row :gutter="20">
      <!-- 供应商性质 + 供应商类型-->
      <el-col :span="6">
        <!-- 供应商性质 -->
        <!-- v-allowed="['Supplier:Nature']" 1.4.3内容-->
        <el-row class="public-col nature-row">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">供应商性质</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="clentNatureClick()"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt30"
            :data="supplierNatureData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="name"
              align="center"
              label="名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="150"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-row v-if="scope.row.name === '工厂'">--</el-row>
                <el-row v-else>
                  <el-tooltip content="编辑" placement="top">
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      circle
                      plain
                      size="mini"
                      @click="clentNatureClick(scope.row)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      plain
                      circle
                      size="mini"
                      @click="clentNatureDelClick(scope.row.supplierNatureId)"
                    ></el-button>
                  </el-tooltip>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <!-- 供应商类型 -->
        <!-- v-allowed="['Supplier:Type']" 1.4.3内容-->
        <el-row class="public-col public-col-2">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">供应商类型</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="clentTypeClick()"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt30"
            :data="supplierTypeData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="name"
              align="center"
              label="名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="150"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="mini"
                    @click="clentTypeClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    circle
                    size="mini"
                    @click="clentTypeDelClick(scope.row.supplierTypeId)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>

      <!-- 付款方式 -->
      <!-- v-allowed="['Supplier:Pay:Way']" -->
      <el-col :span="18">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">付款方式</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="supplierPayClick()"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            :data="supplierPayData"
            width="100%"
            border
            class="mt30 list-table"
            :height="tableHeight"
            ref="sortableTable"
            :key="sortableTableKey"
          >
            <!-- <el-table-column label="金额区间" align="center" prop="payType">
              <template slot-scope="scope">
                <el-row v-if="scope.row.payType === 1">全部</el-row>
                <el-row v-else>
                  <span
                    v-if="
                      (scope.row.beginSection === 0 ||
                        scope.row.beginSection) &&
                      scope.row.endSection
                    "
                  >
                    {{ scope.row.beginSection }}-{{ scope.row.endSection }}
                  </span>
                  <span v-else>&gt;{{ scope.row.beginSection }}</span>
                </el-row>
              </template>
            </el-table-column> -->
            <el-table-column
              label="付款方式"
              align="center"
              prop="payway"
              show-overflow-tooltip
              width="250"
            ></el-table-column>
            <el-table-column
              label="创建人"
              align="center"
              prop="createName"
            ></el-table-column>
            <el-table-column
              label="更新时间"
              prop="updateTime"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                {{ scope.row.updateTime || '--' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="priority"
              label="账期优先级"
              align="center"
              width="120"
            ></el-table-column>
            <el-table-column label="状态" prop="status" align="center">
              <template slot-scope="scope">
                <AuditProcess
                  :isTag="false"
                  v-if="scope.row.status !== '4'"
                  :text="putPayStatus(scope.row.status)"
                  :type="scope.row.status | statusFilter"
                  :showProcess="
                    scope.row.status === '1' || scope.row.status === '3'
                      ? true
                      : false
                  "
                  :auditParams="{
                    auditType,
                    auditObjectId: scope.row.paymentId,
                  }"
                ></AuditProcess>
                <el-row v-if="scope.row.status === '4'">
                  <el-tooltip placement="top">
                    <div slot="content" class="tip-content">
                      作废原因：{{ scope.row.deleteReason || '--' }}
                      <br />
                      作废人：{{ scope.row.deleteName || '--' }}
                      <br />
                      作废时间：{{ scope.row.deleteTime || '--' }}
                    </div>
                    <el-tag type="info" class="c_pointer">已作废</el-tag>
                  </el-tooltip>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                  <el-tooltip content="编辑" placement="top" v-if="scope.row.status === '2' || scope.row.status === '3'">
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      @click="supplierPayClick(scope.row.paymentId)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="拖动设置优先级" placement="top"> 
                    <el-button
                      style="cursor: all-scroll;"
                      type="primary"
                      plain
                      icon="el-icon-sort"
                      circle
                      size="mini"
                      class="allScroll"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="作废" placement="top" v-if="scope.row.status === '2' || scope.row.status === '3'">
                    <el-button
                      type="info"
                      plain
                      circle
                      size="mini"
                      @click="invoiceDeleteClick(scope.row.paymentId)"
                    >
                      <i class="iconfont icon-void"></i>
                    </el-button>
                  </el-tooltip>
                <el-tooltip
                  content="删除"
                  placement="top"
                  v-if="scope.row.status === '4'"
                >
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="supplierPayDelClick(scope.row.paymentId)"
                  ></el-button>
                </el-tooltip>
               
                <!-- <span v-if="scope.row.status === '1'">--</span> -->
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>

    <!-- 供应商性质 -->
    <SupplierNature
      ref="SupplierNature"
      @fetch-data="getSupplierNatureListAll"
    />
    <!-- 供应商类型 -->
    <SupplierType ref="SupplierType" @fetch-data="getSupplierTypeListAll" />

    <!-- 付款方式 -->
    <SipplierSettingPayModel
      ref="SipplierSettingPayModel"
      @fetch-data="getPaymentListPage"
    />
   <!-- 优先级设置 -->
   <SpplierPrioritySetting
      ref="SpplierPrioritySetting"
      @fetch-data="getPaymentListPage"
   />
    <!-- 作废 -->
    <el-dialog
      title="作废"
      :visible.sync="operatVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="offer-dialog">
        <div class="container">
          <el-form
            :model="operatForm"
            :rules="operatRules"
            ref="operatForm"
            label-width="60px"
          >
            <el-form-item label="原因" prop="deleteReason">
              <el-input v-model="operatForm.deleteReason"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="text-center">
          <el-button type="primary" @click="operatSave">确认</el-button>
          <el-button @click="operatCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { dataMethod } from '@/utils/table-merge.js'
  import SupplierNature from './components/supplier-nature-model'
  import SupplierType from './components/supplier-type-model'
  import SipplierSettingPayModel from './components/supplier-setting-pay-model'
  import SpplierPrioritySetting from './components/spplier-priority-setting'
  import AuditProcess from '@/components/AuditProcess/index'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  import Sortable from 'sortablejs'
  import {
    supplierNatureListAll,
    supplierNatureDeleteById,
    supplierTypeListAll,
    supplierTypeDeleteById,
    paymentListPage,
    paymentToVoid,
    paymentDeleteById,
  } from '@/api/client-supplier/supplier-setting'
  import { putPayStatus } from '@/utils/en-match-zh' 

  export default {
    name: 'SupplierSetting',
    components: {
      SupplierNature,
      SupplierType,
      SipplierSettingPayModel,
      AuditProcess,
      SpplierPrioritySetting
    },
    data() {
      return {
        supplierNatureData: [], //供应商性质
        supplierTypeData: [], //供应商类型
        supplierPayData: [], //付款方式
        tableHeight: '100% - 40',
        auditObjectId: '',
        auditType: 11,
        spanObj: {},
        operatForm: {
          deleteReason: '', //作废理由
          paymentId: '',
          payee: 2, //供应商
        },
        operatRules: {
          deleteReason: [
            { required: true, message: '请输入原因', trigger: 'blur' },
            {
              max: 50,
              message: '长度不能超过50个字符',
              trigger: 'blur',
            },
          ],
        },
        operatVisible: false,
        changeTable:[],
        sortableTableKey:1
      }
    },
    filters: {
      //供应商审核状态
      statusFilter(status) {
        const statusMap = {
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'info',
        }
        return statusMap[status]
      },
    },
    created() {
      this.init()
    },
    mounted() {
      this.$nextTick(() => {
          this.initializeSortable()
        })
    },
    watch:{
      sortableTableKey(){
        this.$nextTick(() => {
          this.initializeSortable()
        })
      }
    },
    methods: {
      // 拖拽
      initializeSortable() {
        const el = this.$refs.sortableTable.$el.querySelector('tbody');
        new Sortable(el, {
          // 可以根据你的表格结构调整以下配置
          handle: '.allScroll', // 拖动手柄，可以设置为表格行或其他元素
          animation: 150, // 拖动时的动画时长
          onEnd: this.handleSortEnd, // 拖动结束时的回调函数
        });
      },
      handleSortEnd(evt) {
          this.changeTable = _.cloneDeep(this.supplierPayData)
          const currRow = this.changeTable.splice(evt.oldIndex, 1)[0]
          this.changeTable.splice(evt.newIndex, 0, currRow)
          this.$nextTick(()=>{
            const params = this.changeTable.map((item,index)=>{
              return {
                paymentId:item.paymentId,
                priority:index+1
              }
            })
            this.setPriorityFn(params)
          })
      },
      // 设置优先级
     async setPriorityFn(params){
        let res = await CustomerConfigInteractor.setPriorityApi(params)
          if(res.code === "000000"){
            this.getPaymentListPage()
          }
      },
      //获取供应商性质，类型,付款方式
      init() {
        this.getSupplierNatureListAll()
        this.getSupplierTypeListAll()
        this.getPaymentListPage()
      },
      //获取供应商性质
      async getSupplierNatureListAll() {
        let response = await supplierNatureListAll()
        if (response.code === '000000') {
          this.supplierNatureData = response.data
        }
      },
      //获取供应商类型
      async getSupplierTypeListAll() {
        let response = await supplierTypeListAll()
        if (response.code === '000000') {
          this.supplierTypeData = response.data
        }
      },

      //供应商付款方式
      async getPaymentListPage() {
        let response = await paymentListPage({ payee: 2 })
        if (response.code === '000000') {
          this.supplierPayData = response.data
          this.sortableTableKey++
        }
      },

      //供应商付款方式-合并
      objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          const _row = this.flitterData(this.supplierPayData).one[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      flitterData(arr) {
        let spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.payType === 1) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return {
          one: spanOneArr,
        }
      },

      //供应商性质新增,编辑
      clentNatureClick(row) {
        this.$refs['SupplierNature'].showAddEdit(row)
      },
      //供应商类型新增,编辑
      clentTypeClick(row) {
        this.$refs['SupplierType'].showAddEdit(row)
      },

      //付款方式新增编辑
      supplierPayClick(paymentId) {
        this.$refs['SipplierSettingPayModel'].showAddEdit(paymentId)
      },
      // 优先级设置
      prioritySettings(paymentId,priority){
        this.$refs['SpplierPrioritySetting'].showAddEdit(paymentId,priority)
      },
      //付款方式删除
      async supplierPayDelClick(paymentId) {
        this.$confirm('确定将付款方式删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await paymentDeleteById({
            paymentId: paymentId,
            payee: 2,
          })
          if (response.code === '000000') {
            this.$baseMessage(
              '删除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
            this.getPaymentListPage()
          }
        })
      },

      //付款作废
      invoiceDeleteClick(paymentId) {
        this.operatForm.paymentId = paymentId
        this.operatVisible = true
      },
      // 作废保存
      operatSave(type) {
        this.$refs['operatForm'].validate((valid) => {
          if (valid) {
            this.$confirm(`确定作废, 操作之后无法修改?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                paymentToVoid(this.operatForm).then((res) => {
                  if (res.data) {
                    this.$baseMessage(
                      '作废成功',
                      'success',
                      false,
                      'erp-hey-message-success'
                    )
                    this.operatCancel()
                    this.getPaymentListPage()
                  } else {
                    this.$baseMessage(
                      '作废失败',
                      'error',
                      false,
                      'erp-hey-message-success'
                    )
                    this.operatCancel()
                  }
                })
              })
              .catch(() => {})
          }
        })
      },
      //作废取消
      operatCancel() {
        this.$refs['operatForm'].resetFields()
        this.operatVisible = false
      },

      //删除供应商性质
      clentNatureDelClick(id) {
        if (id) {
          this.$confirm('是否确认删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            let response = await supplierNatureDeleteById({ businessId: id })
            if (response.code == '000000' && response.data != 0) {
              this.$baseMessage(
                '删除成功',
                'success',
                false,
                'erp-hey-message-success'
              )
              this.getSupplierNatureListAll()
            } else {
              this.$baseMessage(
                '删除失败',
                'error',
                false,
                'erp-hey-message-error'
              )
            }
          })
        }
      },
      //删除供应商类型
      clentTypeDelClick(id) {
        if (id) {
          this.$confirm('是否确认删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            let response = await supplierTypeDeleteById({ businessId: id })
            if (response.code == '000000' && response.data != 0) {
              this.$baseMessage(
                '删除成功',
                'success',
                false,
                'erp-hey-message-success'
              )
              this.getSupplierTypeListAll()
            } else {
              this.$baseMessage(
                '删除失败',
                'error',
                false,
                'erp-hey-message-error'
              )
            }
          })
        }
      },

      //审核状态匹配
      putPayStatus(val) {
        return putPayStatus(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  .main-height {
    height: calc(100% - 52px);
  }
  .table-height-box {
    height: calc(100% - 4px);
    overflow: hidden;
    overflow-y: auto;
  }
  .public-col {
    height: 87vh;
    border: 1px solid #ededed;
    padding: 10px;
    .el-table {
      height: calc(100% - 62px);
      overflow-y: auto;
      margin-bottom: 10px;
      border-bottom: 1px solid #ededed;
    }
    p {
      line-height: 32px;
    }
  }
  .nature-row {
    height: 40vh;
  }
  .public-col-2 {
    height: 45vh;
    margin-top: 20px;
  }
  ::v-deep {
    .el-tag {
      border: none;
    }
    .el-tag--plain {
      background: none;
    }
  }
</style>
