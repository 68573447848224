<template>
  <el-dialog
    :visible.sync="modelDetailVisible"
    width="800px"
    center
    :before-close="cancelModel"
  >
    <el-table :data="tableData" style="width: 100%; margin-top: 10px" border>
      <el-table-column
        label="区间金额（开始）"
        align="center"
        prop="beginAmount"
      ></el-table-column>
      <el-table-column label="区间金额（结束）" align="center" prop="endAmount">
        <template slot-scope="scope">
          {{ scope.row.endAmount ? scope.row.endAmount : '无穷大' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="ratio"
        label="提成比（%）"
        align="center"
      ></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelModel">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // 弹窗显示
    modelDetailVisible: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    cancelModel() {
      this.$emit('update:modelDetailVisible', false)
      this.$emit('update:tableData', [])
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
