var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"mail-template-dialog",attrs:{"title":_vm.$t(_vm.title),"width":"50%","visible":_vm.isDialogVisible,"close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.isDialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"100px","label-position":"top"}},[_c('el-form-item',{attrs:{"prop":"emailAccount","label":_vm.$t('boxsetting.ExecuteMailbox'),"rules":[
        {
          required: true,
          message: _vm.$t('placeholder.plsSel'),
          trigger: 'blur',
        } ]}},[_c('el-select',{ref:"accountRef",attrs:{"placeholder":_vm.$t('placeholder.plsSel'),"value-key":"emailAccount","multiple":!_vm.isEdit,"disabled":_vm.isEdit},model:{value:(_vm.form.emailAccount),callback:function ($$v) {_vm.$set(_vm.form, "emailAccount", $$v)},expression:"form.emailAccount"}},_vm._l((_vm.accountList),function(item){return _c('el-option',{key:item.mailAccountId,attrs:{"label":item.emailAccount,"value":item.emailAccount}})}),1)],1),_c('el-form-item',{attrs:{"prop":"templateName","label":_vm.$t('boxsetting.TemplateName'),"rules":[
        {
          required: true,
          message: _vm.$t('placeholder.plsInput'),
          trigger: 'change',
        } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsInput'),"maxlength":"50"},model:{value:(_vm.form.templateName),callback:function ($$v) {_vm.$set(_vm.form, "templateName", $$v)},expression:"form.templateName"}})],1),_c('el-form-item',{attrs:{"prop":"templateContent","label":_vm.$t('boxsetting.TemplateContent'),"rules":[
        {
          required: true,
          message: _vm.$t('placeholder.plsInput'),
          trigger: 'change',
        } ]}},[_c('div',{staticClass:"editor"},[_c('Ueditor',{ref:"ueditor",attrs:{"id":"ueditorTemplate","frameHeight":"300"},on:{"ueditor-focus":_vm.onEditorFocus}})],1)])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('boxsetting.Cancel')))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('boxsetting.Confirm'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }