<template>
  <div
    class="table-container custom-container"
    v-if="$route.meta.showParent"
    :key="$route.path"
  >
    <el-row
      class="page-header align_center"
      type="flex"
      justify="space-between"
    >
      <el-row type="flex" class="align_center">
        <!-- 查询表单 -->
        <el-form
          ref="form"
          class="ml10"
          :inline="true"
          label-width="49px"
          :model="form"
          size="small"
          @submit.native.prevent
        >
          <el-form-item prop="auditType">
            <AuditTypeSelect
              clearable
              v-model="form.auditType"
              style="width: 196px"
              @get-type-list="getTypeList"
            />
          </el-form-item>
          <el-form-item prop="auditNumber">
            <el-input
              style="width: 196px"
              v-model="form.auditNumber"
              clearable
              @change="resetPageNo"
              @keyup.enter.native="resetPageNo"
              placeholder="审核单号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="subId">
            <UserSelect
              placeholder="申请人"
              @user-choose="chooseUser"
              ref="userselect"
            />
          </el-form-item>

          <!-- 时间 -->
          <el-form-item prop="date">
            <el-date-picker
              clearable
              v-model="form.date"
              align="right"
              type="daterange"
              style="width: 240px"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="resetPageNo"
              :pickerOptions="pickOption"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </el-row>
    </el-row>
    <el-row>
      <!-- 变更处理人 -->
      <HanderChange
        :showAfterTab="false"
        @user-change="changeDataBelongUser"
        @from-user-change="changeAuditUserId"
      />
    </el-row>

    <ErpTable
      class="mt15"
      :tableData="tableData"
      :extraHeight="120"
      :pagination="false"
      @selection-change="handlerSelectionChange"
    >
      <el-table-column type="selection" width="50px"></el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <span v-if="item.prop == 'auditType'">
            {{ getTypeName(scope.row[item.prop]) }}
          </span>
          <span v-else>{{ scope.row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>

      <template v-slot:pagination>
        <Pagination
          v-if="page.total > 0"
          :limit.sync="page.pageLe"
          :page.sync="page.pageNo"
          :total="page.total"
          @pagination="onPagination"
        />
      </template>
    </ErpTable>
  </div>
  <div v-else class="pro-container" :key="$route.path">
    <router-view />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { auditInteractor } from '@/core'
  import { getCommonPickOptions } from '@/utils/element-util'
  import Pagination from '@/components/Pagination/Index'
  import UserSelect from '@/components/user-select'
  import AuditTypeSelect from '@/components/AuditTypeSelect'
  import HanderChange from '@/views/system-manage/audit-transfer/components/hander-change'

  export default {
    name: 'AuditTransfer',
    components: { UserSelect, Pagination, AuditTypeSelect, HanderChange },
    data() {
      return {
        form: {
          auditType: '', // 审核类型
          auditNumber: '', //审核单号
          subId: '', //申请人id
          subName: '', //申请人名称
          orderBy: 'id desc',
          startSubTime: '', //开始日期
          endSubTime: '', //结束日期
          auditUserId: '', //变更前处理人
          date: [],
        },
        tableData: [], // 列表数据
        page: {
          //分页数据
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        columns: [
          // 列表列
          {
            label: '审核类型',
            prop: 'auditType',
            isShow: true,
            checkable: false,
          },
          {
            label: '审核单号',
            prop: 'auditNumber',
            isShow: true,
            checkable: false,
            width: 140,
          },
          {
            label: '申请人',
            prop: 'subName',
            isShow: true,
            checkable: true,
          },

          {
            label: '申请时间',
            prop: 'subTime',
            isShow: true,
            checkable: true,
          },
        ],
        pickOption: getCommonPickOptions(), // 日期时间选择器组件
        auditTypeList: [], // 审核类型列表
        mutilSelection: [], //勾选项
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },

    watch: {
      'form.auditType': function () {
        this.resetPageNo()
      },
      'form.subId': function () {
        this.resetPageNo()
      },
      'form.auditUserId': function () {
        this.resetPageNo()
      },
    },

    async created() {
      this.onSearch()
    },

    // activated() {
    //   this.resetPageNo()
    // },

    methods: {
      // 勾选事件
      handlerSelectionChange(selection) {
        this.mutilSelection = selection
      },

      // 获取审核类型
      getTypeList(auditTypeList) {
        this.auditTypeList = auditTypeList
      },

      // 获取审核类型名称
      getTypeName(val) {
        return this.auditTypeList.find((item) => item.auditType === val)
          ?.auditTypeName
      },

      // 分页处理
      onPagination({ page }) {
        this.page.pageNo = page
        this.onSearch()
      },

      // 重置pageNo
      resetPageNo() {
        this.page.pageNo = 1
        this.onSearch()
      },

      // 搜索
      async onSearch() {
        const res = await auditInteractor.auditTransferQuery({
          ...this.page,
          ...this.form,
          tenantId: this.userInfo.tenantId,
        })
        if (res.code === '000000') {
          this.tableData = res.data.data || []
          this.page.total = res.data.total
        }
      },

      // 搜索表单重置
      onReset() {
        this.page.pageNo = 1
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
        this.currentStatus = ''
        this.onSearch()
      },

      // 申请人选择
      chooseUser(user) {
        const form = this.form
        form.subId = user ? user.userId : ''
        form.subName = user ? user.userName : ''
      },

      // 当前处理人变更
      changeAuditUserId({ fromUserId }) {
        this.form.auditUserId = fromUserId
      },

      // 数据变更
      changeDataBelongUser(val) {
        if (!val.transferAllData && this.mutilSelection.length === 0) {
          return this.$message.warning('请选择数据')
        }
        this.submitData(val)
      },

      // 提交数据
      submitData(form) {
        const len = form.transferAllData
          ? this.page.total
          : this.mutilSelection.length
        if (!len) return this.$message.warning('没有需要变更的数据')
        this.$confirm(
          `确认将${len}条数据的审核人从${form.auditUserName}变为${form.afterUserName}吗？此操作不可撤销`,
          '提示',
          {
            confirmButtonText: '确认变更',
            cancelButtonText: '取消变更',
            type: 'warning',
          }
        )
          .then(async () => {
            auditInteractor
              .auditTransfer({
                ...form,
                ...this.form,
                auditObjectIds: this.mutilSelection.map(
                  (item) => item.auditObjectId
                ),
              })
              .then((res) => {
                if (res && res.code === '000000') {
                  this.$message.warning('变更处理人成功')
                  this.onSearch()
                }
              })
          })
          .catch(() => {})
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    height: 33px;
  }
  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .pointer {
    cursor: pointer;
  }

  .link {
    text-decoration: underline;
    color: #1890ff;
  }

  .error-color {
    color: #f56c6c;
  }
  .success-color {
    color: #67c23a;
  }
  .warning-color {
    color: #e6a23c;
  }
  .war .pointer {
    cursor: pointer;
  }

  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }
  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
