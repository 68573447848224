<template>
  <el-row class="content">
    <el-row type="flex" align="middle" :gutter="20">
      <el-col :span="8">
        <label>售后类型</label>
        <span>{{ detail.type | customerServiceFilter }}</span>
      </el-col>
      <el-col :span="8">
        <label>订单编号</label>
        <span
          class="blue-text"
          style="color: #1890ff"
          @click="purchaseOrderNumberClick(detail.orderCode)"
        >
          {{ detail.orderCode }}
        </span>
      </el-col>
      <el-col :span="8">
        <label>客户代表</label>
        <span>{{ detail.businessName || '--' }}</span>
      </el-col>
      <el-col :span="8">
        <label>退款金额</label>
        <span style="color: #f44336; font-weight: bold">
          {{ matchCurrency(detail.currency) }}
          {{ detail.amount }}
        </span>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" :gutter="20" class="mt20">
      <el-col :span="8">
        <label>客户姓名</label>
        <span>{{ detail.customerName || '--' }}</span>
      </el-col>
      <el-col :span="8">
        <label>公司名称</label>
        <span>{{ detail.companyName || '--' }}</span>
      </el-col>
      <el-col :span="8">
        <el-row style="display: inline-block">
          <label>订单总金额</label>
          <el-popover placement="bottom" trigger="hover">
            <el-row
              slot="reference"
              class="blue-text"
              style="color: #1890ff; font-weight: bold"
            >
              {{ matchCurrency(detail.currency) }} {{ detail.totalAmount || 0 }}
            </el-row>
            <el-row>
              <el-table
                :data="detail.salesGatheringVOS"
                style="width: 100%"
                border
                class="mt15"
              >
                <el-table-column
                  width="150"
                  prop="paymentLink"
                  label="收款节点"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.paymentLink || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="paymentPropor"
                  label="比例(%)"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.paymentPropor || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="receivablePrice"
                  label="应收金额"
                  align="center"
                  width="120"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.receivablePrice">
                      {{ matchCurrency(detail.currency) }}
                    </span>
                    {{ scope.row.receivablePrice || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="actualReceivablePrice"
                  label="实收金额"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.actualReceivablePrice">
                      {{ matchCurrency(detail.currency) }}
                    </span>
                    {{ scope.row.actualReceivablePrice || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="actualReceivablePriceDate"
                  label="收款时间"
                  align="center"
                  width="120"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.actualReceivablePriceDate || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="voucherNo"
                  label="收款单号"
                  align="center"
                  width="150"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span
                      class="blue-text"
                      v-if="scope.row.voucherNo"
                      @click="receiptNoClick(scope.row)"
                    >
                      {{ scope.row.voucherNo }}
                    </span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="financeMethod"
                  label="收款方式"
                  align="center"
                  width="120"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.financeMethod || '--' }}
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-popover>
        </el-row>
      </el-col>
      <el-col :span="8">
        <label>已收金额</label>
        <span style="color: #4caf50; font-weight: bold">
          {{ matchCurrency(detail.currency) }} {{ detail.receivedPrice || 0 }}
        </span>
      </el-col>
    </el-row>
    <el-row class="mt20">
      <label>退款账户</label>
      <span>
        {{ detail.refundAccount || '--' }}
      </span>
    </el-row>
    <el-row class="mt20">
      <label>退款原因</label>
      <p>{{ detail.refundReason || '--' }}</p>
    </el-row>

    <el-row class="mt20" v-if="detail.refundOrderProductVOS">
      <label>关联产品</label>
      <el-table
        :data="detail.refundOrderProductVOS"
        style="width: 98%"
        border
        class="mt15"
      >
        <el-table-column
          prop="productCode"
          label="产品编码"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="productCn"
          label="产品名称"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="specification"
          label="规格"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="standardCustomized"
          label="产品类型"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="printingWay"
          label="是否需要印刷"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.printingWay.toUpperCase() === 'BLANK' ? '否' : '是' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          label="订单数量"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="shippingProductAmount"
          label="实际出运数量"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="refundProductAmount"
          label="退回数量"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="warehouseName"
          label="退回仓库"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseName || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="mt20">
      <label>附件</label>
      <div v-show="fileList.length">
        <div class="flie-list" v-for="(item, i) in fileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <span class="ell">
                <router-link
                  :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                  target="_blank"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
      <span v-show="!fileList.length">-</span>
    </el-row>

    <el-row class="mt20">
      <el-table :data="detail.simpleOrders" style="width: 50%" border>
        <el-table-column
          type="index"
          label="#"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="typeMemo"
          label="类型"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="orderNumbers"
          label="单据号"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="单据信息" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.amountName }}：{{ scope.row.amount }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="mt20" :gutter="20">
      <el-col :span="6">
        <label>创建人</label>
        <span>{{ detail.creatorName || '--' }}</span>
      </el-col>
      <el-col :span="6">
        <label>创建时间</label>
        <span>{{ detail.createTime || '--' }}</span>
      </el-col>
    </el-row>

    <!--收款单详情弹窗 -->
    <ReceiptDetailModel ref="ReceiptDetail" />
  </el-row>
</template>

<script>
import { getRefundOrderShow } from '@/api/audit/auditMange'
import ReceiptDetailModel from '@/views/finance-center/credit-manage/components/receipt-detail-model'
import { paymentStatusVO } from '@/api/finance/finance-payment'

export default {
  name: 'CustomerServiceDetail',
  components: { ReceiptDetailModel },
  props: {
    detailId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      detail: {}, //售后详情
      fileList: [], //附件
    }
  },
  watch: {
    detailId: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) return
        this.showCustomerServiceDetail(value)
      },
    },
  },
  // 状态过滤
  filters: {
    customerServiceFilter(status) {
      const createWay = {
        1: '销售订单退款',
        2: '采购订单退款',
      }
      return createWay[status]
    },
  },
  methods: {
    //售后详情
    async showCustomerServiceDetail(value) {
      let response = await getRefundOrderShow({ refundOrderId: value })
      if (response.code === '000000') {
        this.detail = response.data
        this.fileList = response.data.enclosureUrl
          ? JSON.parse(response.data.enclosureUrl)
          : ''
      }
    },

    //销售订单号跳转
    async purchaseOrderNumberClick(code) {
      let response = await paymentStatusVO({
        orderCode: code,
      })
      if (response.code == '000000') {
        let orderStatus = response.data.orderStatus
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: { orderCode: code, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      }
    },

    //点击收款单号
    receiptNoClick(row) {
      this.$refs['ReceiptDetail'].showReceiptDetail(
        row.voucherNo,
        row.voucherStatus
      )
    },

    //订单编号跳转
    orderCodeClick(id) {},
    matchCurrency(str) {
      return str === '美元' ? '$' : '￥'
    },
  },
}
</script>

<style scoped lang="scss">
.content {
  padding: 20px 20px 30px !important;
  .el-row {
    label {
      display: block;
      color: #666;
    }
    span {
      display: block;
      margin-top: 10px;
      font-size: 16px;
      color: #000;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      word-wrap: break-word;
    }
  }
  .flie-list span {
    font-size: 14px !important;
    color: #666 !important;
  }
}
</style>
