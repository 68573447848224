<!--
  功能：入库单详情产品详情
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月28日 14:58:47
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <!-- 其他出库表格 -->
  <div>
    <el-table v-if="type == '5' || type == '12' || type =='16'" :data="detailTableData" :span-method="objectSpanMethod"
      border striped :key="5" :max-height="400">
      <el-table-column align="center" label="产品编码">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" v-if="scope.row.skuId.indexOf('FL') === -1"
            @click="skuIdClick(scope.row)">
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.combinationFlag == 1
                ? '主'
                : scope.row.combinationFlag == 0 && scope.row.mainFlag == 0
                ? '子'
                : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec"></el-table-column>
      <el-table-column align="center" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="单位" prop="productUnit"></el-table-column>
      <el-table-column align="center" label="实际出库数量" prop="warehouseOutNum"></el-table-column>
      <!-- V1.6.1入库批次 -->
      <el-table-column align="center" prop="lotNo" label="入库批次"></el-table-column>
      <!-- V1.6.1采购单号 -->
      <el-table-column align="center" prop="purchaseNumber" label="采购单号" width="150">
        <template slot-scope="scope">
          <span class="page-link-noline" @click="showPruchaseDetail(scope.row)" v-if="scope.row.purchaseNumber">
            {{ scope.row.purchaseNumber }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- V1.6.1采购开发 -->
      <el-table-column align="center" prop="buyer" :formatter="formatter" label="采购开发"></el-table-column>
      <!-- V1.6.1批次出库数量 -->
      <el-table-column align="center" prop="num" label="批次出库数量"></el-table-column>
      <el-table-column align="center" label="出库单价" prop="">
        <template slot-scope="scope">
          {{ scope.row.price ? fix3(scope.row.price) : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="出库成本" prop="">
        <template slot-scope="scope">
          {{ scope.row.totalPrice ? fix3(scope.row.totalPrice) : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="出库库位" v-if="showLocs">
        <template slot-scope="scope">
          <div v-if="scope.row.childProduct && scope.row.childProduct.length > 0" class="page-link"
            style="text-decoration: none" @click="viewChildProduct(scope.row)">
            点击查看
          </div>
          <div v-else>
            <SelectedLocs :locs="scope.row.warehouseOutProductLocationVOList" @add-loc="showLoc" :status="detail.status"
              :tableIndex="scope.$index" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 领料出库表格 -->
    <el-table v-else-if="type == '13'" :data="detailTableData" :span-method="objectSpanMethod" border striped :key="5">
      <el-table-column align="center" label="产品编码">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" v-if="scope.row.skuId.indexOf('FL') === -1"
            @click="skuIdClick(scope.row)">
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.combinationFlag == 1
                ? '主'
                : scope.row.combinationFlag == 0 && scope.row.mainFlag == 0
                ? '子'
                : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec"></el-table-column>
      <el-table-column align="center" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="申请出库数量/单位" prop="productUnit">
        <template slot-scope="scope">
          {{ scope.row.expectOutNum || '-' }}/{{ scope.row.productUnit || '-' }}
        </template>
      </el-table-column>

      <!-- V1.6.1入库批次 -->
      <el-table-column align="center" prop="lotNo" label="入库批次"></el-table-column>
      <!-- V1.6.1采购单号 -->
      <el-table-column align="center" prop="purchaseNumber" label="采购单号" width="150">
        <template slot-scope="scope">
          <span class="page-link-noline" @click="showPruchaseDetail(scope.row)" v-if="scope.row.purchaseNumber">
            {{ scope.row.purchaseNumber }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- V1.6.1采购开发 -->
      <el-table-column align="center" prop="buyer" :formatter="formatter" label="采购开发"></el-table-column>
      <!-- V1.6.1批次出库数量 -->
      <el-table-column align="center" prop="num" label="批次出库数量"></el-table-column>
      <el-table-column align="center" label="出库单价" prop="">
        <template slot-scope="scope">
          {{ scope.row.price ? fix3(scope.row.price) : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="出库成本" prop="">
        <template slot-scope="scope">
          {{ scope.row.totalPrice ? fix3(scope.row.totalPrice) : '-' }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="备注" prop="remark">
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="出库库位" v-if="showLocs">
        <template slot-scope="scope">
          <div v-if="scope.row.childProduct && scope.row.childProduct.length > 0" class="page-link"
            style="text-decoration: none" @click="viewChildProduct(scope.row)">
            点击查看
          </div>
          <div v-else>
            <SelectedLocs :locs="scope.row.warehouseOutProductLocationVOList" @add-loc="showLoc" :status="detail.status"
              :tableIndex="scope.$index" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 销售出库表格 -->
    <el-table v-else-if="type == '3'" :data="detailTableData" :span-method="objectSpanMethod" border striped
      :key="detail.type">
      <el-table-column align="center" label="产品编码">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" v-if="scope.row.skuId.indexOf('FL') === -1"
            @click="skuIdClick(scope.row)">
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.combinationFlag == 1
                ? '主'
                : scope.row.combinationFlag == 0 && scope.row.mainFlag == 0
                ? '子'
                : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec"></el-table-column>
      <el-table-column align="center" label="产品类别">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="申请出库数量/单位">
        <template slot-scope="scope">
          {{ scope.row.expectOutNum }}/{{
          scope.row.productUnit ? scope.row.productUnit : '-'
          }}
        </template>
      </el-table-column>
      <!-- V1.6.1入库批次 -->
      <el-table-column align="center" prop="lotNo" label="入库批次"></el-table-column>
      <!-- V1.6.1采购单号 -->
      <el-table-column align="center" prop="purchaseNumber" label="采购单号" :formatter="formatter" width="150">
        <template slot-scope="scope">
          <span class="page-link-noline" @click="showPruchaseDetail(scope.row)" v-if="scope.row.purchaseNumber">
            {{ scope.row.purchaseNumber }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- V1.6.1采购开发 -->
      <el-table-column align="center" prop="buyer" label="采购开发" :formatter="formatter"></el-table-column>
      <el-table-column align="center" prop="num" label="批次出库数量"></el-table-column>
      <el-table-column align="center" label="出库单价" prop="">
        <template slot-scope="scope">
          {{ scope.row.price ? fix3(scope.row.price) : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="出库成本" prop="">
        <template slot-scope="scope">
          {{ scope.row.totalPrice ? fix3(scope.row.totalPrice) : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark">
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="出库库位" v-if="showLocs">
        <template slot-scope="scope">
          <div v-if="scope.row.childProduct && scope.row.childProduct.length > 0" class="page-link"
            style="text-decoration: none" @click="viewChildProduct(scope.row)">
            点击查看
          </div>
          <div v-else>
            <SelectedLocs :locs="scope.row.warehouseOutProductLocationVOList" @add-loc="showLoc" :status="detail.status"
              :tableIndex="scope.$index" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 退货出库表格 -->
    <el-table :key="detail.type" v-else :data="detailTableData" border striped :span-method="objectSpanMethod">
      <el-table-column align="center" label="产品编码">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" v-if="scope.row.skuId.indexOf('FL') === -1"
            @click="skuIdClick(scope.row.skuId)">
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.combinationFlag == 1
                ? '主'
                : scope.row.combinationFlag == 0 && scope.row.mainFlag == 0
                ? '子'
                : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec"></el-table-column>
      <el-table-column align="center" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="已入库数量/单位">
        <template slot-scope="scope">
          {{ scope.row.warehouseInNum ? scope.row.warehouseInNum : '-' }}/{{
          scope.row.productUnit ? scope.row.productUnit : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="申请出库数量" prop="expectOutNum"></el-table-column>
      <el-table-column align="center" label="出库单价" prop="price">
        <template slot-scope="scope">
          {{ scope.row.price ? scope.row.price : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="出库成本" prop="totalPrice">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ scope.row.totalPrice }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark">
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="出库库位" v-if="showLocs">
        <template slot-scope="scope">
          <div v-if="scope.row.childProduct && scope.row.childProduct.length > 0" class="page-link"
            style="text-decoration: none" @click="viewChildProduct(scope.row)">
            点击查看
          </div>
          <div v-else>
            <SelectedLocs :locs="scope.row.warehouseOutProductLocationVOList" @add-loc="showLoc" :status="detail.status"
              :tableIndex="scope.$index" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next,total" :total="total" :current-page.sync="pageNo"
      :page-size="pageSize" @current-change="handleCurrentChange" v-if="detail.type == 16">
    </el-pagination>
    <LocDetail ref="loc-detail" :tableData="currentRow.warehouseOutProductLocationVOList"></LocDetail>
    <stock-bom-view ref="stockBom" ruleType="1" :warehouseId="warehouseId"></stock-bom-view>
  </div>
</template>

<script>
  import BaseStar from '@/components/base-star/base-star'
  import LocDetail from './base-loc-detail'
  import SelectedLocs from '../../inbound-sheet/components/selected-locs.vue'
  import stockBomView from './stockBomView.vue'
  import popoverShipClick from './popoverShipClick.vue'
  import { tableMixin } from '../../helper/mixin'
  import { ProductListInteractor } from '@/core'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import _ from 'lodash'

  export default {
    name: 'BaseProductDetail',
    mixins: [tableMixin],
    props: {
      detailId: {
        type: String,
        default: '',
      },
      detail: {
        type: Object,
        default: () => ({}),
      },
    },

    components: {
      BaseStar,
      LocDetail,
      SelectedLocs,
      stockBomView,
      popoverShipClick,
    },
    data() {
      return {
        currentRow: {},
        warehouseId: '',
        detailTableData: [],
        total: 0, //总页码
        pageSize: 200, //分页数量
        pageNo: 1, //第几页
        initTableData: [], //初始数据
      }
    },
    computed: {
      type() {
        return this.detail.type
      },
      showLocs() {
        if (
          this.$route.path === '/audit-process/audit-manage/index' ||
          this.$route.path === '/home'
        ) {
          return false
        } else {
          return true
        }
      },
    },

    created() {
      if (this.detail.type == 16) {
        this.initTableData = this.detail.warehouseOutProductVOList
        this.total = this.initTableData.length
        this.getPaginationData()
      } else {
        this.getTableData()
      }

    },

    methods: {
      showPruchaseDetail({ purchaseNumber: orderNumber }) {
        if (!orderNumber) return
        navigateTo(this, {
          pageType: PAGE_TYPES.purchaseOrderDetail,
          roleJudge: true,
          orderNumber,
        })
      },
      formatter(row, column, cellValue, index) {
        return cellValue ? cellValue : '-'
      },
      handleCurrentChange(val) {
        this.pageNo = val
        this.getPaginationData()
      },
      getPaginationData() {
        let initTableData = this.initTableData,
          pageNo = this.pageNo,
          pageSize = this.pageSize
        // 确保页码不小于1
        pageNo = Math.max(pageNo, 1);
        // 计算起始索引
        const startIndex = (pageNo - 1) * pageSize;
        // 计算结束索引，注意JavaScript数组索引是从0开始的
        const endIndex = startIndex + pageSize;

        // 获取指定页的数据
        let tableData = initTableData.slice(startIndex, endIndex);
        this.pos = 0
        this.spanArr = []
        this.getTableData(tableData)
      },
      getTableData(tableData) {

        let newArr = _.cloneDeep(tableData || this.detail.warehouseOutProductVOList)

        newArr?.map((item) => {
          if (item.mainFlag == 0) {
            let obj = {
              productName: item.parentName,
              parentProductUnit: item.parentProductUnit,
              skuId: item.parentSku,
              parentSpecsValue: item.parentSpecsValue,
              parentStandardCustomized: item.parentStandardCustomized,
              parentUrl: item.parentUrl,
              numCombination: item.numCombination,
            }

            return {
              ...item,
              parentProduct: [obj],
            }
          } else {
            return item
          }
        })
        if (!newArr) return []
        this.detailTableData = this.formatTableData(
          newArr,
          'warehouseOutLotVOList'
        )
        this.getSpanArr(this.detailTableData)
      },
      fix3(val) {
        return val.toFixed(3)
      },
      showLoc(index) {
        let row = this.detailTableData[index]

        this.$refs['loc-detail'].show()
        this.currentRow = row
      },

      skuIdClick(row) {
        this.getProductIdByCode(row.skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        if (id.indexOf('FL') > -1) return
        let response = await ProductListInteractor.productIdByCode(id)
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },
      // 查看子产品
      viewChildProduct(val) {
        this.warehouseId = this.detail.warehouseId
        this.$refs.stockBom.showDialog()
        this.$refs.stockBom.gridData = val.childProduct
      },
    },

    filters: {
      emptyFilter(val) {
        return val ? val : '-'
      },
    },
  }
</script>

<style scoped></style>
