export const statusList = [
  {
    value: null,
    label: '全部',
    type: '',
  },
  {
    value: 1,
    label: '未盘点',
    type: 'info',
  },
  {
    value: 2,
    label: '审核中',
    type: 'warning',
  },
  {
    value: 3,
    label: '已完成',
    type: 'success',
  },
  {
    value: 4,
    label: '已驳回',
    type: 'danger',
  },
  {
    value: 5,
    label: '已撤销',
    type: 'danger',
  },
  {
    value: 6,
    label: '已取消',
    type: 'danger',
  },
]

export const columns = [
  {
    label: '盘点单号',
    prop: 'stockTakingCode',
    isShow: true,
    width: '',
  },
  {
    label: '盘点仓库',
    prop: 'warehouseName',
    isShow: true,
    width: '',
  },
  {
    label: '盘点类型',
    prop: 'takingRange',
    isShow: true,
  },
  {
    label: '状态',
    prop: 'status',
    isShow: true,
    width: '',
  },
  {
    label: '盘点人',
    prop: 'takingUser',
    isShow: true,
    width: '',
  },
  {
    label: '创建人',
    prop: 'creator',
    isShow: true,
    width: '',
  },
  {
    label: '创建时间',
    prop: 'createTime',
    isShow: true,
    width: '180',
  },
  {
    label: '盘点时间',
    prop: 'takingTime',
    isShow: true,
    width: '180',
  },
  // {
  //   label: '盘点结果',
  //   prop: 'takingResult',
  //   isShow: true,
  //   width: '',
  // },
  // {
  //   label: '盘点差异',
  //   prop: 'takingDiff',
  //   isShow: true,
  //   width: '',
  // },
  {
    label: '备注',
    prop: 'remark',
    isShow: true,
    width: '',
  },
]

export const result = [
  {
    label: '无误差',
    value: '1',
    type: 'success',
  },
  {
    label: '盘盈',
    value: '2',
    type: 'danger',
  },
  {
    label: '盘亏',
    value: '3',
    type: 'danger',
  },
]
