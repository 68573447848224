<!--
  功能：操作日志详情
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月04日 16:31:21
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="log-wrapper" width="250">
    <h4>{{ log.operatorNameCn }}&nbsp;&nbsp;{{ log.createTime }}</h4>
    <p class="bold mb5">【{{ getText(log.operationRecordType) }}】</p>
    <div v-for="(item, index) in log.content" class="list-item">
      <p :key="index" v-html="item"></p>
    </div>
  </div>
</template>

<script>
  import { type2LogText } from '../helper'

  export default {
    name: 'TrackDataDetailLogDetail',
    props: {
      log: {
        type: Object,
        default: () => {},
      },
    },
    components: {},
    // 组件状态值
    data() {
      return {}
    },
    methods: {
      getText(num) {
        return type2LogText[num]
      },
    },
  }
</script>

<style scoped lang="scss">
  .log-wrapper {
    padding: 5px 15px 15px 15px;
    .list-item {
      margin-bottom: 10px;
      line-height: 26px;
      padding-left: 5px;
    }
  }
</style>
