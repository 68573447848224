<!--入库单打印-->
<template>
  <PrintWrapper>
    <div style="background: #fff; height: 100vh" id="printTest1">
      <div
        class="print-wrapper"
        style="width: 900px; margin: 0 auto; padding-top: 30px"
      >
        <p class="title">
          <span class="header mr15">
            入库单号:
            <span class="ml15">{{ detail.warehouseInNumber }}</span>
          </span>
        </p>
        <table style="width: 100%; border-collapse: collapse" class="mt20">
          <!-- 采购入库 -->
          <template v-if="typeIsCg">
            <template v-if="detail.warehouseName !== '虚拟仓库'">
              <tr>
                <td class="label" width="111">入库方式</td>
                <td width="300" colspan="2">{{ detail.type | inBoundType }}</td>
                <td class="label">入库仓库</td>
                <td colspan="2">{{ detail.warehouseName }}</td>
                <td class="label" width="111">采购单号</td>
                <td width="300" colspan="2">
                  {{ detail.orderNumber ? detail.orderNumber : '--' }}
                </td>
              </tr>
              <tr>
                <td class="label" width="111">供应商</td>
                <td colspan="2">
                  {{ detail.supplierName ? detail.supplierName : '--' }}
                </td>
                <td class="label">采购开发</td>
                <td colspan="2">{{ detail.buyer ? detail.buyer : '--' }}</td>
                <td class="label">创建人</td>
                <td colspan="2">
                  {{ detail.creator ? detail.creator : '--' }}
                </td>
              </tr>
              <tr>
                <td class="label">所属地区</td>
                <td colspan="2">
                  {{ detail.creatorArea ? detail.creatorArea : '--' }}
                </td>
                <td class="label">创建时间</td>
                <td colspan="2">
                  {{ detail.createTime ? detail.createTime : '--' }}
                </td>
                <td class="label">操作人</td>
                <td colspan="8">
                  {{ detail.operationName ? detail.operationName : '--' }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="label" width="111">入库方式</td>
                <td width="300" colspan="2">{{ detail.type | inBoundType }}</td>
                <td class="label">入库仓库</td>
                <td colspan="2">{{ detail.warehouseName }}</td>
                <td class="label" width="111">采购单号</td>
                <td width="300" colspan="2">
                  {{ detail.orderNumber ? detail.orderNumber : '--' }}
                </td>
              </tr>
              <tr>
                <td class="label" width="111">供应商</td>
                <td colspan="2">
                  {{ detail.supplierName ? detail.supplierName : '--' }}
                </td>
                <td class="label">采购开发</td>
                <td colspan="2">{{ detail.buyer ? detail.buyer : '--' }}</td>
                <td class="label">所属地区</td>
                <td colspan="2">
                  {{ detail.creatorArea ? detail.creatorArea : '--' }}
                </td>
              </tr>
              <!-- <tr>
              <td class="label">操作人</td>
              <td colspan="8">{{ detail.operationName }}</td>
            </tr> -->
            </template>
          </template>

          <!-- 退货入库 -->
          <template v-if="typeIsTh">
            <tr>
              <td class="label" width="111">入库方式</td>
              <td width="300" colspan="2">{{ detail.type | inBoundType }}</td>
              <td class="label" width="111">入库仓库</td>
              <td colspan="2">
                {{ detail.warehouseName }}
              </td>
              <td class="label" width="111">销售单号</td>
              <td width="300" colspan="2">
                {{ detail.orderNumber ? detail.orderNumber : '--' }}
              </td>
            </tr>
            <tr>
              <td class="label" width="111">客户代表</td>
              <td width="300" colspan="2">
                {{ detail.businessName ? detail.businessName : '--' }}
              </td>
              <td class="label">客户名称</td>
              <td colspan="2">
                {{ detail.customerName ? detail.customerName : '--' }}
              </td>
              <td class="label" width="111">创建人</td>
              <td width="300" colspan="2">
                {{ detail.creator ? detail.creator : '--' }}
              </td>
            </tr>
            <tr>
              <td class="label" width="111">所属地区</td>
              <td width="300" colspan="2">
                {{ detail.creatorArea ? detail.creatorArea : '--' }}
              </td>
              <td class="label">创建时间</td>
              <td colspan="2">
                {{ detail.createTime ? detail.createTime : '--' }}
              </td>
              <td class="label" width="111">操作人</td>
              <td width="300" colspan="2">
                {{ detail.operationName ? detail.operationName : '--' }}
              </td>
            </tr>
          </template>

          <!-- 其他入库 -->
          <template v-if="typeIsQt">
            <tr>
              <td class="label" width="111">入库方式</td>
              <td width="300" colspan="2">{{ detail.type | inBoundType }}</td>
              <td class="label" width="111">入库仓库</td>
              <td colspan="2" width="300">
                {{ detail.warehouseName }}
              </td>
              <td class="label" width="111">创建人</td>
              <td width="300" colspan="2">
                {{ detail.creator ? detail.creator : '--' }}
              </td>
            </tr>
            <tr>
              <td class="label" width="111">所属地区</td>
              <td width="300" colspan="2">
                {{ detail.creatorArea ? detail.creatorArea : '-' }}
              </td>
              <td class="label" width="111">创建时间</td>
              <td colspan="2" width="300">
                {{ detail.createTime }}
              </td>
              <td class="label" width="111">操作人</td>
              <td width="300" colspan="2">
                {{ detail.operationName ? detail.operationName : '--' }}
              </td>
            </tr>
          </template>
          <tr>
            <td class="label">入库时间</td>
            <td colspan="8" class="lineh-22">
              {{ detail.warehouseInDate ? detail.warehouseInDate : '--' }}
            </td>
          </tr>
          <tr>
            <td class="label">{{ detail.type === 1 ? '原因说明' : '备注' }}</td>
            <td colspan="8" class="lineh-22">
              {{ detail.remark ? detail.remark : '--' }}
            </td>
          </tr>

          <!-- 产品 -->

          <!--退货入库 -->
          <tr v-if="detail.type === 1">
            <td class="title">出库单号</td>
            <td class="title">产品编码</td>
            <td class="title">产品名称</td>
            <td class="title">规格</td>
            <td class="title">产品类型</td>
            <td class="title">已出库数量/单位</td>
            <td class="title">申请入库数量</td>
            <td class="title">产品是否可用</td>
          </tr>
          <!-- 采购入库 -->
          <tr v-else-if="detail.type === 0">
            <td class="title">产品编码</td>
            <td class="title" colspan="2">产品名称</td>
            <td class="title">规格</td>
            <td class="title">产品类型</td>
            <td class="title" colspan="3">申请入库数量/单位</td>
            <td class="title">实际入库数量</td>
          </tr>
          <!-- 其他入库 -->
          <tr v-else>
            <td class="title">产品编码</td>
            <td class="title" :colspan="detail.createWay !== 0 ? 1 : 2">
              产品名称
            </td>
            <td class="title" colspan="2">规格</td>
            <td class="title">产品类型</td>
            <td class="title">单位</td>

            <td class="title">实际入库数量</td>
          </tr>

          <template v-for="pro in detail.warehouseInProductVOList">
            <!-- 退货入库 -->
            <tr :key="`${pro.skuId}1`" v-if="detail.type === 1">
              <td class="label bg">
                {{ pro.warehouseOutNumber ? pro.warehouseOutNumber : '--' }}
              </td>
              <td class="label bg">{{ pro.skuId }}</td>
              <td class="label bg">
                {{ pro.productName }}
              </td>
              <td class="label bg">
                {{ pro.productSpec ? pro.productSpec : '--' }}
              </td>
              <td class="bg">{{ pro.productType }}</td>
              <td class="bg">{{ pro.productUnit }}</td>
              <td class="bg">
                {{ pro.contractNum ? pro.contractNum : '--' }}
              </td>
              <td class="bg" colspan="2">{{ pro.warehouseInNum }}</td>
            </tr>

            <!-- 采购入库 -->
            <tr :key="`${pro.skuId}1`" v-else-if="detail.type === 0">
              <td class="label bg">{{ pro.skuId }}</td>
              <td class="label bg" colspan="2">
                {{ pro.productName }}
              </td>
              <td class="label bg">
                {{ pro.productSpec ? pro.productSpec : '--' }}
              </td>
              <td class="bg">{{ pro.productType }}</td>
              <td class="bg" colspan="3">
                {{ pro.expectInNum }} / {{ pro.productUnit }}
              </td>
              <td class="bg">
                {{ pro.warehouseInNum ? pro.warehouseInNum : '--' }}
              </td>
            </tr>
            <!-- 其他入库 -->
            <tr :key="`${pro.skuId}1`" v-else>
              <td class="label bg">{{ pro.skuId }}</td>
              <td class="label bg" :colspan="detail.createWay !== 0 ? 1 : 2">
                {{ pro.productName }}
              </td>
              <td class="label bg" colspan="2">
                {{ pro.productSpec ? pro.productSpec : '--' }}
              </td>
              <td class="bg">{{ pro.productType }}</td>
              <td class="bg">{{ pro.productUnit }}</td>
              <td class="bg" v-if="detail.createWay !== 0" colspan="2">
                {{ pro.expectInNum ? pro.expectInNum : '--' }}
              </td>
              <td class="bg">{{ pro.warehouseInNum }}</td>
            </tr>

            <tr :key="`${pro.skuId}2`">
              <td class="label">入库库位</td>
              <td colspan="8">{{ pro | loctionFilter }}</td>
            </tr>
            <tr :key="`${pro.skuId}3`">
              <td class="label">备注</td>
              <td colspan="8" class="lineh-22">
                {{ pro.remark ? pro.remark : '--' }}
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </PrintWrapper>
</template>

<script>
  import { InboundSheet } from '@/core'
  import PrintWrapper from '@/views/print/printWrapper'
  let self
  export default {
    name: 'PrintInboundSheet',
    components: { PrintWrapper },
    props: {
      detail: {
        type: Object,
        default: () => ({}),
      },
      warehouses: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      self = this
      return {
        detail: {},
        warehouses: [],
        locParams: {
          show: false,
          disabled: true,
        },
      }
    },
    // components: {
    //   BaseStar,
    // },
    computed: {
      // warehouseInId() {
      //   return this.$route.query.warehouseInId
      // },
      //采购入库
      typeIsCg() {
        return this.detail.type === 0
      },
      //退货入库
      typeIsTh() {
        return this.detail.type === 1
      },
      //其他出库
      typeIsQt() {
        return this.detail.type === 2
      },
    },

    methods: {
      getSums(field) {
        let arr = this.detail.warehouseInProductVOList
          .map((item) => item[field])
          .filter((item) => !isNaN(item))
        if (arr.length === 0) {
          return '--'
        } else {
          return arr.reduce((prev, next) => prev + next)
        }
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }
          if (![5, 6, 7, 8].includes(index)) {
            sums[index] = ''
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          }
          if (index == 5) {
            if (sums[index] == 0) {
              sums[index] = '--'
            }
          }
        })

        return sums
      },
      onClose() {
        this.locParams.show = false
      },
      addLoc(row) {
        this.locParams.show = true
        this.$nextTick().then(() => {
          this.$refs.loc.locs = row.warehouseInProductLocationVOList.map(
            (i) => {
              i.currentNum = 0
              return i
            }
          )
        })
      },
    },

    filters: {
      warehouseName(val) {
        const warehouse = self.warehouses.find(
          (item) => item.warehouseId === val
        )
        return warehouse ? warehouse.name : '--'
      },
      inBoundType(val) {
        const types = [
          //入库方式
          {
            code: 0,
            name: '采购入库',
          },
          {
            code: 1,
            name: '退货入库',
          },
          {
            code: 2,
            name: '其他入库',
          },
        ]
        let type = types.find((item) => item.code === val)
        return type ? type.name : '--'
      },
      loctionFilter(val) {
        const { warehouseInProductLocationVOList } = val
        if (
          !warehouseInProductLocationVOList ||
          warehouseInProductLocationVOList.length === 0
        ) {
          return '--'
        }

        return warehouseInProductLocationVOList
          .map((item) => `${item.locationNumber}(${item.num})`)
          .join('; ')
      },
    },
    created() {
      const { warehouseInId } = this.$route.query
      InboundSheet.findWarehouseList({
        hasCloudWarehouse: 1,
      }).then((res) => {
        this.warehouses = res.data || []
      })
      InboundSheet.getDetail({ warehouseInId }).then((res) => {
        if (res.code == '000000') {
          this.detail = res.data
        }
      })
      $(document)
        .unbind('keydown')
        .bind('keydown', function (e) {
          if (e.ctrlKey && e.keyCode == 80) {
            document.querySelector('#printbutton').click()
            // 返回false, 防止重复触发copy事件
            return false
          }
        })
    },
  }
</script>

<style scoped lang="scss">
  .table-container {
    height: 100vh;
    background: #fff;
    padding: 20px !important;

    & .title {
      display: flex;
      align-items: center;
    }
    & .detail-fields {
      // background-color: #f5f7fa;
      .el-row {
        margin: 15px 0;
        & .label {
          color: #606266;
          width: 100px;
          display: inline-block;
          margin-right: 15px;
        }
        & .content {
          color: #000016;
        }
      }
    }
  }
  .header {
    font-size: 14px;
    color: #303030;
    font-family: 'PingFang Bold';
  }
  .fs14 {
    font-size: 14px;
    font-family: 'PingFang Bold';
    color: #282c34;
  }
  .mr15 {
    margin-right: 15px;
  }
  .ml15 {
    margin-left: 15px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .pointer {
    cursor: pointer;
  }
  .center {
    text-align: center;
  }

  .print-wrapper {
    width: 900px;
    margin: 0 auto;
    padding: 30px 0;

    table td {
      border: 1px solid #f0f4fb;
      padding: 14px 16px;
      color: #303030;
      box-sizing: border-box;
      &.label {
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-family: 'PingFang Bold';
        color: #303030;
      }
      &.title {
        font-family: MicrosoftYaHei;
        color: #777777;
      }
      &.bg {
        background-color: #f8fbff;
        -webkit-print-color-adjust: exact;
      }
      &.lineh-22 {
        line-height: 22px;
      }
    }
  }
</style>
