<template>
  <div class="custom-container" v-if="$route.meta.showParent">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="22" class="page-header-left">
        <el-row type="flex" class="align_center flex_row_y flex_wrap top-search">
          <el-form ref="form" :inline="true" :model="queryForm" label-width="49px" @submit.native.prevent>
            <el-radio-group v-model="tabsType" class="mr10">
              <el-radio-button label="1">采购需求</el-radio-button>
              <el-radio-button label="2">备货需求</el-radio-button>
            </el-radio-group>

            <el-button v-allowed="['Purchase:Demand:Add']" v-show="!selectRows.length" type="primary" plain class="mr10"
              @click="add(0)">
              备货申请
            </el-button>
            <el-form-item v-show="!selectRows.length">
              <el-input style="width: 230px" v-model="queryForm.numberCodeName" placeholder="请输入订单编号/产品编码/名称" clearable
                @keyup.enter.native="initLoading()" />
            </el-form-item>
          </el-form>
          <el-button class="mr10" v-show="!selectRows.length" @click="initLoading()">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            搜索
          </el-button>
          <el-row v-show="selectRows.length" type="flex" class="align_center">
            <p class="mr10">
              已选择
              <strong class="f_s_16">{{ selectRows.length }}</strong>
              个
            </p>
          </el-row>
          <el-button @click="highSearch">高级筛选</el-button>
          <el-button v-show="isDelivery && tabsType == 1" @click="delivery(1)">
            出库申请
          </el-button>
          <el-button v-show="tabsType == 2" @click="clone()">克隆</el-button>
          <el-button @click="createPurchase()" v-show="isDemandOffer && purchaseOrderRight">
            生成采购订单
          </el-button>
          <el-button @click="allotClick(1)" v-show="isBuyer">
            设置采购开发
          </el-button>
          <el-button v-show="tabsType == 1 && showOutProcess" @click="outProcess()">
            委外加工
          </el-button>
        </el-row>
      </el-col>
      <el-col :span="2">
        <el-row type="flex" justify="end">
          <!-- 自定义展示列 -->
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <erp-draggable v-bind="dragOptions" :list="tabsType == 1 ? purchaseDemandColumns : stockDemandColumns">
              <template v-if="tabsType == 1">
                <el-checkbox v-for="(item, index) in purchaseDemandColumns" :key="item + index" v-model="item.isShow"
                  :disabled="item.checkable === false" :label="item.label">
                  {{ item.label }}
                </el-checkbox>
              </template>
              <template v-else>
                <el-checkbox v-for="(item, index) in stockDemandColumns" :key="item + index" v-model="item.isShow"
                  :disabled="item.checkable === false" :label="item.label">
                  {{ item.label }}
                </el-checkbox>
              </template>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button type="primary" class="ml10" size="mini" plain @click="operateSave()">
                保存
              </el-button>
            </div>
            <template #reference>
              <el-tooltip effect="dark" content="列表可拖动排序" placement="top-start">
                <el-button plain type="primary">自定义显示</el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>

    <el-radio-group class="mt15 mb15" v-model="queryForm.state" v-if="tabsType == 1" @change="initLoading()">
      <el-radio v-for="(item, i) in orderStatusList1" :key="i" :label="item.value">
        {{ item.label }}
      </el-radio>
    </el-radio-group>
    <el-radio-group class="mt15 mb15" v-model="queryForm.state" v-else @change="initLoading()">
      <el-radio v-for="(item, i) in orderStatusList2" :key="i" :label="item.value">
        {{ item.label }}
      </el-radio>
    </el-radio-group>

    <ErpTable :page-number.sync="page.pageNo" :page-size.sync="page.pageLe" :table-data="tableData" :total="total"
      :extraHeight="30" :treeProps="{ children: 'children', hasChildren: 'hasChildren' }" class="table-fixed"
      :rowKey="'purchasingDemandProductId'" :expandAll="isExpandAll" :key="isExpandAll + customTableKey + tabsType"
      @query="getList" @selection-change="setSelectRows">
      <el-table-column align="center" type="selection" :selectable="checkSelectable" width="55" />
      <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="item.label" show-overflow-tooltip
        :width="item.width" min-width="120" :resizable="false" align="center" :prop="item.prop" :class-name="item.prop">
        <template slot="header">
          <span v-if="item.prop === 'number' && tabsType == 1" @click="toggeleExpand">
            <i class="toggle-arrow" :class="
                !isExpandAll ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
              "></i>
          </span>
          <span>{{ item.label }}</span>
        </template>
        <template #default="{ row }">
          <div v-if="item.prop === 'number'">
            <span class="page-link" @click="goDetail(row)">
              {{ row.number }}
            </span>
            <em :class="
                row.mainFlag == 1
                  ? 'main-split'
                  : row.mainFlag == 0
                  ? 'child-split'
                  : ''
              ">
              {{ row.mainFlag == 1 ? '主' : row.mainFlag == 0 ? '子' : '' }}
            </em>
            <erp-mouseover-tip v-if="
                row.mainFlag != 0 &&
                item.label === '采购需求编号' &&
                (row.updateFlag || row.renderingStatus)
              ">
              <template name="content">
                <div class="tip_content">
                  <!-- <span
                    style="display: inline-block; white-space: pre-line"
                    v-html="row.renderingRemarks || row.updateRemark"
                  ></span> -->

                  <span style="
                      white-space: pre-line;
                      display: inline-block;
                      min-width: 180px;
                    " v-if="row.renderingStatus && !row.updateFlag">
                    {{ row.renderingRemarks }}
                  </span>
                  <div v-if="row.updateFlag">
                    <h3>销售订单异常</h3>
                    <span>异常原因：{{ row.updateRemark }}</span>
                  </div>

                  <div class="mt10 text-right" v-if="row.renderingStatus && !row.updateFlag">
                    <el-button style="display: inline-block" size="mini" @click="changeStatus(row)">
                      知道了
                    </el-button>
                  </div>
                </div>
              </template>
            </erp-mouseover-tip>
          </div>
          <div v-else-if="item.prop === 'standardCustomized'">
            {{ row.sonProductType === 1 ? '辅料' : row.standardCustomized }}
          </div>
          <div class="img-wrap" v-else-if="item.prop === 'imgMain'">
            <div v-if="row.mainFlag == 0">--</div>
            <div v-else>
              <el-badge v-show="row.imgMain && row.imgMain.split(',').length > 1"
                :value="row.imgMain ? row.imgMain.split(',').length : ''"></el-badge>
              <el-image v-if="row.imgMain" :src="row.imgMain.split(',')[0] + URL_COMPRESS" class="c_pointer"
                @click="handlePreview(row.imgMain.split(','))"></el-image>
              <span v-else>--</span>
            </div>
          </div>
          <div class="ell" v-else-if="item.prop === 'nameCn'">
            {{ row.nameCn }}
            <span v-show="row.specsValue1">;{{ row.specsValue1 }}</span>
            <span v-show="row.specsValue2">,{{ row.specsValue2 }}</span>
          </div>
          <div class="ell" v-else-if="item.prop === 'businessName'">
            {{ row.businessName }}
          </div>
          <div class="ell" v-else-if="item.prop === 'printingWay'">
            {{
            row.printingWay && row.printingWay.toUpperCase() === 'BLANK'
            ? '否'
            : '是'
            }}
          </div>
          <div class="ell" v-else-if="item.prop === 'printingWayEn'">
            {{ row.printingWay || '--' }}
          </div>
          <div v-else-if="item.prop === 'stockAvailableNumList'">
            <span v-if="
                row.stockAvailableNumList &&
                row.stockAvailableNumList.length > 1
              ">
              <el-popover v-if="row.stockAvailableNumList.length > 1" placement="right" width="200" trigger="hover"
                :content="formatStock(row.stockAvailableNumList)">
                <span slot="reference">
                  {{ formatStock(row.stockAvailableNumList.slice(0, 1)) }}
                  <span class="more">
                    等{{ row.stockAvailableNumList.length }}个
                  </span>
                </span>
              </el-popover>
            </span>
            <span v-else>
              {{ formatStock(row.stockAvailableNumList) }}
            </span>
          </div>
          <div v-else-if="item.prop === 'stockLockNumList'">
            <div v-if="row.stockLockNumList && row.stockLockNumList.length > 0">
              <p class="page-link" v-for="(item, i) in row.stockLockNumList" :key="i" @click="stockLock(row)">
                {{ item.warehouseName }}:{{ item.num }}
              </p>
            </div>
            <div v-else>--</div>
          </div>
          <div v-else-if="item.prop === 'numUncompleted'">
            <div>{{ row.numUncompleted }}</div>
          </div>
          <div v-else-if="item.prop === 'buyerName'">
            <div v-if="tabsType == 1">
              <!-- 采购开发修改添加限制条件：当前采购开发没有进行锁定库存操作 -->
              <div v-if="
                  row.state == 5 &&
                  row.numDemand == row.numUncompleted &&
                  !row.stockLockNumList.length &&
                  row.mainFlag != 1 &&
                  hasBuyerRight
                " class="page-link" @click="allotClick(2, row.purchasingDemandProductId)">
                {{ row.buyerName || '--' }}
              </div>
              <div v-else>{{ row.buyerName || '--' }}</div>
            </div>
            <div v-else>
              <div v-if="
                  buyerNameRight &&
                  row.state == 3 &&
                  row.numDemand == row.numUncompleted
                " class="page-link" @click="allotClick(2, row.purchasingDemandProductId)">
                {{ row.buyerName || '待分配' }}
              </div>
              <div v-else>{{ row.buyerName || '--' }}</div>
            </div>
          </div>
          <!-- 订单支持 备货可编辑选择，采购不支持修改 -->
          <div v-else-if="item.prop === 'fromBusinessName'">
            <div v-if="tabsType == 1">{{ row.fromBusinessName }}</div>
            <div v-else>
              <!-- 权限控制 -->
              <span v-if="
                  hasBussinessRight &&
                  row.state == 3 &&
                  row.numDemand == row.numUncompleted
                " class="page-link" @click="setBusinessName(row)">
                {{ row.fromBusinessName || '待分配' }}
              </span>
              <span v-else>{{ row.fromBusinessName || '--' }}</span>
            </div>
          </div>
          <!-- 1录入，2审核中，3审核通过，4已驳回，5待处理, 6 已撤销 -->
          <div v-else-if="item.prop === 'state'">
            <el-tag v-if="row.state == 1" type="info">录入</el-tag>
            <el-tag v-else-if="row.state == 2" type="primary" @click="statusTypeClick(row)" class="c_pointer">
              审核中
            </el-tag>
            <el-tag v-else-if="row.state == 3" type="success" @click="statusTypeClick(row)" class="c_pointer">
              审核通过
            </el-tag>

            <el-row v-else-if="row.state == 4">
              <el-tooltip :content="'原因：' + row.auditReason" placement="top">
                <el-tag type="danger">已驳回</el-tag>
              </el-tooltip>
            </el-row>
            <el-tag v-else-if="row.state == 5" type="info">待处理</el-tag>
            <el-tag v-else-if="row.state == 6" type="info">已撤销</el-tag>
            <el-tag v-else-if="row.state == 7" type="default">出库中</el-tag>
            <el-tag v-else-if="row.state == 8" type="success">出库完成</el-tag>
            <el-tag v-else type="info">/</el-tag>
          </div>
          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120" fixed="right" :resizable="false">
        <template slot-scope="scope">
          <div v-show="!scope.row.updateFlag">
            <el-tooltip v-if="tabsType == 1" content="生成采购订单" placement="top">
              <el-button v-show="
                  purchaseOrderRight &&
                  scope.row.state == 5 &&
                  scope.row.operatePurchaseDemand === '1' &&
                  scope.row.numUncompleted > 0 &&
                  scope.row.settlementStatus < 2 &&
                  scope.row.splitFlag != 1
                " type="primary" plain @click="
                  createOffer(
                    scope.row.purchasingDemandProductId,
                    scope.row.exportsRise,
                    1,
                    scope.row
                  )
                " size="mini" icon="iconfont icon-gouwu" circle></el-button>
            </el-tooltip>
            <el-tooltip v-else content="生成采购订单" placement="top">
              <el-button v-show="
                  purchaseOrderRight &&
                  scope.row.operatePurchaseDemand === '1' &&
                  scope.row.numUncompleted > 0 &&
                  scope.row.state == 3 &&
                  scope.row.buyerId &&
                  scope.row.settlementStatus < 2 &&
                  scope.row.splitFlag != 1
                " type="primary" plain @click="
                  createOffer(
                    scope.row.purchasingDemandProductId,
                    scope.row.exportsRise,
                    2,
                    scope.row
                  )
                " size="mini" icon="iconfont icon-gouwu" circle></el-button>
            </el-tooltip>

            <el-dropdown>
              <el-button type="primary" plain icon="el-icon-more" circle style="padding: 8px"></el-button>
              <el-dropdown-menu slot="dropdown" placement="bottom">
                <el-dropdown-item v-show="tabsType == 1">
                  <p @click="outProcess(scope.row)">委外加工</p>
                </el-dropdown-item>
                <el-dropdown-item v-show="tabsType == 1">
                  <p @click="splitPurchase(scope.row)" v-show="
                      scope.row.splitFlag === 0 &&
                      scope.row.mainFlag === null &&
                      scope.row.numDemand == scope.row.numUncompleted
                    ">
                    拆分采购
                  </p>
                  <p @click="cancelSplit(scope.row.purchasingDemandProductId)" v-show="scope.row.splitFlag === 1">
                    取消拆分
                  </p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p v-show="
                      tabsType == 1 &&
                      scope.row.operatePurchaseDemand === '1' &&
                      scope.row.standardCustomized != '订制品' &&
                      scope.row.numUncompleted > 0 &&
                      scope.row.settlementStatus < 2 &&
                      scope.row.mainFlag != 1 &&
                      (scope.row.stockAvailableNumList.length ||
                        scope.row.stockLockNumList.length)
                    " @click="delivery(2, scope.row)">
                    出库申请
                  </p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p v-show="
                      scope.row.operatePurchaseDemand === '1' &&
                      (scope.row.state == 4 || scope.row.state == 6) &&
                      scope.row.updateFlag != 1
                    " @click="edit(scope.row)">
                    编辑
                  </p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </ErpTable>

    <!-- 审核进度Drawer -->
    <el-drawer title="审核进度" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" :auditType="auditType" @cancelClick="statusClose" />
    </el-drawer>

    <!-- 撤销弹窗 -->
    <el-dialog title="撤销原因" :visible="cancelVisible" append-to-body width="500px" @close="onCancel"
      modal-append-to-body>
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input type="textarea" :rows="3" placeholder="请输入撤销原因" maxlength="500" show-word-limit
        v-model="cancelReason"></el-input>
      <div class="footer mt30 t_a_c">
        <el-button type="primary" class="ml30" @click="cancelSure">
          确 定
        </el-button>
        <el-button @click="onCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 高级筛选Drawer -->
    <el-drawer title="高级筛选" :visible.sync="drawer" :before-close="highSearchClose">
      <!-- 采购需求 -->
      <demandHighSearch ref="demandHighSearch" @cancelClick="highSearchClose" @searchClick="searchClick" />
    </el-drawer>

    <!-- 出库申请 -->
    <purchase-demand-delivery ref="purchaseDemandDelivery"></purchase-demand-delivery>

    <!-- 新增采购需求 -->
    <purchase-demand-add :purchaseUsers="purchaseUsers" ref="purchaseDemandAdd"></purchase-demand-add>

    <!-- 拆分采购 -->
    <split-model ref="splitModel" @bomDrawerOpen="bomDrawerOpen"></split-model>

    <!-- 解锁库存 -->
    <unlock ref="unlock"></unlock>

    <!-- 设置采购开发 -->
    <setBuyer :purchaseUsers="purchaseUsers" ref="setBuyer" @setBuyer="setBuyer"></setBuyer>

    <!-- 设置订单支持 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 备货单详情 -->
    <stockUp-detail ref="stockUp"></stockUp-detail>

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />

    <!-- 创建BOM Drawer -->
    <el-drawer title="创建BOM单" size="1500px" :visible.sync="bomDrawer" v-if="bomDrawer" :before-close="bomDrawerClose"
      :wrapperClosable="false">
      <bom-model ref="bomModel" @splitOpen="splitOpen" @bomDrawerClose="bomDrawerClose"></bom-model>
    </el-drawer>
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import { purchaseDemandInterator, CreditManageInteractor } from '@/core'
  import UserChoose from '@/components/userChoose'
  import ErpDraggable from 'vuedraggable'
  import { listUserByRoleName } from '@/api/finance/credit-manage-list'
  import PurchaseDemandAdd from './components/add.vue'
  import splitModel from './components/splitModel.vue'
  import PurchaseDemandDelivery from './components/delivery.vue'
  import unlock from './components/unlock.vue'
  import { cancel } from '@/api/stock-center/inbound-sheet'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import demandHighSearch from './components/demandHighSearch.vue'
  import stockUpDetail from './components/stockUpDetail'
  import bomModel from './components/bomModel'
  import setBuyer from './components/setBuyer'
  import { mapGetters } from 'vuex'
  import { hasRight } from '@/utils/permissionBtn'
  import CustomCache from '@/utils/custom-cache'
  import customTableMixin from '@/utils/custom-table-mixin'
  import { URL_COMPRESS } from '@/config'
  const purchaseDemandColumns = [
    {
      label: '采购需求编号',
      prop: 'number',
      isShow: true,
      checkable: false,
      width: 200,
    },
    {
      label: '期望交期',
      prop: 'expectedDeliveryDate',
      isShow: true,
      checkable: true,
    },
    {
      label: '产品编号',
      prop: 'sku',
      isShow: false,
      checkable: true,
    },
    {
      label: '产品图片',
      prop: 'imgMain',
      isShow: true,
      checkable: true,
    },
    {
      label: '订单备注',
      prop: 'alignOrderRemark',
      isShow: true,
      checkable: true,
    },
    {
      label: '产品名称',
      prop: 'nameCn',
      isShow: true,
      checkable: true,
    },
    {
      label: '产品类型',
      prop: 'standardCustomized',
      isShow: true,
      checkable: true,
    },
    {
      label: '是否需要印刷',
      prop: 'printingWay',
      isShow: true,
      checkable: true,
    },
    {
      label: '印刷方式',
      prop: 'printingWayEn',
      isShow: true,
      checkable: true,
    },
    {
      width: 160,
      label: '库存可用量',
      prop: 'stockAvailableNumList',
      isShow: true,
      checkable: true,
    },
    {
      width: 160,
      label: '库存锁定量',
      prop: 'stockLockNumList',
      isShow: true,
      checkable: true,
    },
    {
      label: '需求数量',
      prop: 'numDemand',
      isShow: true,
      checkable: true,
    },
    {
      label: '待处理数量',
      prop: 'numUncompleted',
      isShow: true,
      checkable: true,
    },
    {
      label: '客户代表',
      prop: 'businessName',
      isShow: true,
      checkable: false,
    },
    {
      label: '订单支持',
      prop: 'fromBusinessName',
      isShow: true,
      checkable: true,
    },
    {
      label: '状态',
      prop: 'state',
      isShow: true,
    },
    {
      label: '采购开发',
      prop: 'buyerName',
      isShow: true,
      checkable: false,
    },
    {
      label: '创建人',
      prop: 'creator',
      isShow: false,
      checkable: true,
    },
    {
      label: '创建时间',
      prop: 'createTime',
      isShow: true,
      checkable: true,
    },
  ],
    stockDemandColumns = purchaseDemandColumns
  export default {
    components: {
      ErpDraggable,
      Audit,
      PurchaseDemandAdd,
      splitModel,
      PurchaseDemandDelivery,
      unlock,
      demandHighSearch,
      stockUpDetail,
      bomModel,
      setBuyer,
      UserChoose,
    },
    name: 'purchaseDemand',
    mixins: [customTableMixin],

    data() {
      return {
        queryForm: {
          state: 0,
        },
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        tableData: [],
        tabsType: 1, // 1销售单 2备货单
        auditObjectId: '', //审核id（产品id）
        auditType: 2,
        cancelVisible: false,
        cancelReason: '', // 撤销原因
        cancelType: '', // 撤销类型 2审核撤销，7出库撤销
        cancelId: '', // 出库撤销Id
        statusDrawer: false, //审核进度,
        selectRows: [], //table勾选
        drawer: false, //高级筛选抽屉
        isDemandOffer: true, // 生成采购订单
        isDelivery: true, // 出库申请
        isBuyer: true, // 设置采购开发
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        bomDrawer: false, // bom单抽屉
        isExpandAll: false, // 是否全部展开
        hasBuyerRight: false, // 采购员操作权限
        buyerNameRight: false, // 采购开发操作权限
        purchaseOrderRight: false, // 生成采购订单操作权限
        purchasingDemandProductIds: '',
        parentForm: {}, // 选中的当前列表row
        printingWayList: [], // 打印方式下拉选，拆分需要
        bomList: [], // bom单选中的数据
        treeProps: { children: 'children', hasChildren: 'hasChildren' },
        purchaseUsers: [], //采购开发下拉列表
        orderStatusList1: [
          {
            label: '全部',
            value: 0,
          },
          {
            label: '待处理',
            value: 5,
          },
          {
            label: '出库中',
            value: 7,
          },
          {
            label: '出库完成',
            value: 8,
          },
        ],
        purchaseDemandColumns, //采购需求自定义字段
        stockDemandColumns, //备货需求自定义字段
        orderStatusList2: [
          {
            label: '全部',
            value: 0,
          },
          {
            label: '审核中',
            value: 2,
          },
          {
            label: '审核通过',
            value: 3,
          },
          {
            label: '已驳回',
            value: 4,
          },
        ],
        purchasingDemandProductId: '',
        hasBussinessRight: false,
        URL_COMPRESS: URL_COMPRESS,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        let columns =
          this.tabsType == 1
            ? this.purchaseDemandColumns
            : this.stockDemandColumns
        return columns.filter((item) => item.isShow === true)
      },
      showOutProcess() {
        // 同一个销售单 展示
        return this.selectRows.every(
          (it) => it.salesOrderNumber === this.selectRows[0].salesOrderNumber
        )
      },
    },
    watch: {
      tabsType(type) {
        this.tabsType = type
        this.queryForm = {
          state: 0,
        }
        this.initLoading()
        // 销售单，备货单自定义显示 --备货单不展示客户代表,是否需要印刷
        const _columns =
          this.tabsType == 1 ? purchaseDemandColumns : stockDemandColumns
        if (type == 1) {
          _columns.forEach((item, i) => {
            if (item.prop == 'number') {
              _columns[i].label = '采购需求编号'
            }
            if (item.prop == 'businessName') {
              _columns[i].isShow = true
            }
            if (item.prop == 'printingWay') {
              _columns[i].isShow = true
            }
            if (item.prop == 'stockLockNumList') {
              _columns[i].isShow = true
            }
            if (item.prop == 'alignOrderRemark') {
              _columns[i].isShow = true
            }
          })
        } else {
          _columns.forEach((item, i) => {
            if (item.prop == 'number') {
              _columns[i].label = '备货需求编号'
            }
            if (item.prop == 'businessName') {
              _columns[i].isShow = false
            }
            if (item.prop == 'printingWay') {
              _columns[i].isShow = false
            }
            if (item.prop == 'stockLockNumList') {
              _columns[i].isShow = false
            }
            if (item.prop == 'alignOrderRemark') {
              _columns[i].isShow = false
            }
          })
        }
      },

      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          let { tabsType } = this.$route.query
          this.tabsType = tabsType || 1
          this.getList()
        }
      },
    },
    activated() {
      let { tabsType } = this.$route.query
      this.tabsType = tabsType || 1
      this.getList()
      this.hasBuyerRight = hasRight('Edit:Buyer')
      this.hasBussinessRight = hasRight('Allot:Business')
      this.purchaseOrderRight = hasRight('Purchase:Order:Create')
      this.buyerNameRight = hasRight('Allot:Buyer')
      this.getListUserByRoleName()
    },
    created() {
      this.getColumns()
    },
    methods: {
      // 获取保存的自定义列
      getColumns() {
        //获取自定义保存
        // 31采购需求
        CustomCache.getColumns(31, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnIdPurcasse = res.userCustomizeColumnId
            this.purchaseDemandColumns = res.columns
          }
        })
        // 35备货需求
        CustomCache.getColumns(35, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnIdStock = res.userCustomizeColumnId
            this.stockDemandColumns = res.columns
          }
        })
      },

      toggeleExpand() {
        this.isExpandAll = !this.isExpandAll
      },
      initLoading(type) {
        type ? (this.tabsType = type) : ''
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.getList()
      },

      //权限-客户代表角色下拉
      async getListUserByRoleName() {
        let response = await CreditManageInteractor.listUserByRoleName({
          divideGroup: 0,
          name: '采购员',
          tenantId: this.userInfo.tenantId,
        })
        if (response?.code === '000000') {
          this.purchaseUsers = response.data
        }
      },

      // 获取列表
      async getList() {
        const params = {
          ...this.queryForm,
          ...this.page,
          type: this.tabsType,
        }
        const res = await purchaseDemandInterator.purchasingDListPage(params)

        if (res && res.code === '000000') {
          const data = res.data

          this.tableData =
            data?.data.map((item) => {
              return {
                ...item,
                imgMain: item.printingImg || item.imgMain, // 产品图先取效果图再取主图
              }
            }) || []
          this.total = data?.total || 0

          if (this.tabsType == 1 && this.total > 0) this.expandTable()
        }
        this.selectRows = []
      },

      // 列表扩展子列表数据
      async expandTable() {
        let skus = []
        let table = this.tableData
        table.forEach((item) => skus.push(item.purchasingDemandProductId))
        const res = await purchaseDemandInterator.getPurchasingChildList(skus)

        if (res?.data) {
          const child = res.data

          child.forEach((children) => {
            table.forEach((item, i) => {
              if (children.parentId == item.purchasingDemandProductId) {
                const childrenNode = Object.assign(
                  {},
                  { ...children },
                  {
                    updateFlag: item.updateFlag,
                    updateRemark: item.updateRemark,
                    settlementStatus: item.settlementStatus,
                    renderingStatus: item.renderingStatus,
                    renderingRemarks: item.renderingRemarks,
                    alignOrderRemark: item.alignOrderRemark,
                    operatePurchaseDemand: item.operatePurchaseDemand,
                  }
                )
                table[i].children.push(JSON.parse(JSON.stringify(childrenNode)))
              }
            })
          })
        }
      },

      // 主表复选框按钮不可操作
      checkSelectable(row) {
        if (row.mainFlag === 1) {
          return false
        } else {
          return true
        }
      },

      // 保存自定义显示列
      operateSave() {
        const {
          tabsType,
          purchaseDemandColumns,
          stockDemandColumns,
          userCustomizeColumnIdStock,
          userCustomizeColumnIdPurcasse,
        } = this
        const customizeData = JSON.stringify(
          tabsType == 1 ? purchaseDemandColumns : stockDemandColumns
        ),
          userCustomizeColumnId =
            tabsType == 1
              ? userCustomizeColumnIdPurcasse
              : userCustomizeColumnIdStock,
          menuType = tabsType == 1 ? 31 : 35
        let params = {
          userCustomizeColumnId,
          menuType,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this[
              tabsType == 1
                ? 'userCustomizeColumnIdPurcasse'
                : 'userCustomizeColumnIdStock'
            ]
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 详情跳转
      goDetail(val) {
        if (!val.salesOrderNumber) {
          this.$refs.stockUp.initLoading(val.numberPrefix)
        } else {
          this.$router.push({
            path: '/order/orderList/orderDetail',
            query: {
              orderCode: val.salesOrderNumber,
              orderId: val.salesOrderId,
              updateFlag: val.updateFlag ? val.updateFlag : 0,
            },
          })
        }
      },

      // 表格勾选事件
      setSelectRows(rows) {
        this.selectRows = []
        this.selectRows = rows
        if (this.selectRows.length) {
          // 生成采购订单操作按钮显示 销售单标准品不需要印刷需求数量>0，备货单需求数量>0，审核通过有采购开发也需要显示
          this.isDemandOffer =
            this.tabsType == 1
              ? this.selectRows.every(
                (item) =>
                  item.state == 5 &&
                  item.operatePurchaseDemand === '1' &&
                  item.numUncompleted > 0 &&
                  !item.updateFlag &&
                  item.settlementStatus < 2
              )
              : this.selectRows.every(
                (item) =>
                  item.operatePurchaseDemand === '1' &&
                  item.numUncompleted > 0 &&
                  item.state == 3 &&
                  item.buyerId &&
                  !item.updateFlag &&
                  item.settlementStatus < 2
              )
          // 出库申请按钮显示 1有库存，2存在他人权限，3标准品，4不需要印刷
          this.isDelivery = this.selectRows.every(
            (item) =>
              (item.stockAvailableNumList.length ||
                item.stockLockNumList.length) &&
              item.operatePurchaseDemand === '1' &&
              item.standardCustomized != '订制品' &&
              item.numUncompleted > 0 &&
              !item.updateFlag &&
              item.settlementStatus < 2
          )
          // 设置采购开发
          this.isBuyer = this.selectRows.every(
            (item) =>
              item.numDemand == item.numUncompleted &&
              (item.state == 3 || item.state == 5)
          )
        } else {
          this.isDelivery = true
          this.isDemandOffer = true
          this.isBuyer = true
          this.isProcess = true
        }
      },

      /**
       * 生成采购订单
       * @param id
       * @param exportsRise 出口抬头
       * @param type 1:采购需求 2:备货需求
       */
      createOffer(id, exportsRise, type, row) {
        const { hasOut, outType } = (row || {})
        if (hasOut === 1 && outType === 0) {
          return this.$message.warning('该SKU在该销售单下已有备货出库，不允许再按需采购。')
        }
        const routeData = this.$router.resolve({
          path: '../purchase/purchaseDemand/createPurchaseOrder',
          query: {
            ids: id,
            exportsRise: exportsRise,
            isStock: type === 2 && this.userInfo.area === '合肥' ? true : false,
            showSwitch: type === 2 ? 1 : 0, // 1 是备货需求 0是采购需求
          },
        })
        window.open(routeData.href, '_blank')
      },

      //审核进度查看
      statusTypeClick(row) {
        this.auditObjectId = row.purchasingDemandId
        this.statusDrawer = true
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      // 编辑
      edit(row) {
        this.add(row.numberPrefix)
      },

      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },

      //确定撤销
      async cancelSure() {
        if (!this.cancelReason) {
          return this.$message.warning('请输入撤销原因')
        }
        if (this.cancelType == 2) {
          const {
            auditObjectId,
            auditStatus = 4,
            auditType = 2,
            cancelReason,
          } = this
          const { tenantId, userId, userName } = this.userInfo
          cancel({
            auditObjectId,
            auditStatus: 4,
            auditType,
            tenantId,
            userId,
            userName,
            remarks: cancelReason,
          }).then((res) => {
            if (res.code === '000000') {
              this.$message.success('撤销成功')
              this.cancelVisible = false
              this.initLoading(2)
            }
          })
        } else {
          const res = await purchaseDemandInterator.revokeWarehouseOut(
            this.cancelId,
            this.cancelReason
          )

          if (res.code == '000000') {
            this.$message.success('撤销成功')
            this.cancelVisible = false
            this.initLoading(1)
          }
        }
      },

      // 生成采购订单
      createPurchase() {
        if (this.selectRows.length == 0) {
          this.$message.warning('请选择一条状态为待处理的数据！')
          return false
        } else {
          let ids = []
          let exportsRiseList = []
          this.selectRows.forEach((item) => {
            ids.push(item.purchasingDemandProductId)
            exportsRiseList.push(item.exportsRise)
          })
          // 出口抬头必须保持一致
          if (Array.from(new Set(exportsRiseList)).length > 1) {
            this.$message.warning('出口抬头必须保持一致')
            return false
          }

          if (this.selectRows.some(row => row.hasOut === 1 && row.outType === 0)) {
            this.$message.warning('该SKU在该销售单下已有备货出库，不允许再按需采购。')
            return false
          }
          // 判断是否存在审核模板
          let auditParams = {
            auditType: 3,
            status: 0,
            tenantId: this.userInfo.tenantId,
          }
          this.pubApi.getAuditTemplates(auditParams).then((response) => {
            if (!response?.data?.length) {
              this.$baseMessage(
                '请联系管理员配置审核模板',
                'warning',
                false,
                'erp-hey-message-warning'
              )
              return false
            } else {
              const bool =
                this.tabsType == 2 && this.userInfo.area === '合肥'
                  ? true
                  : false

              const routeData = this.$router.resolve({
                path: '../purchase/purchaseDemand/createPurchaseOrder',
                query: {
                  ids: ids.join(','),
                  exportsRise: this.selectRows[0].exportsRise,
                  isStock: bool,
                  showSwitch: this.tabsType == 1 ? 0 : 1,
                },
              })
              window.open(routeData.href, '_blank')
            }
          })
        }
      },

      // 显示出库申请 type：1列表操作-2：操作栏操作，row数据源
      delivery(type, row) {
        let ids = []
        const delivery = this.$refs.purchaseDemandDelivery
        if (type == 1) {
          if (!this.selectRows.length) {
            this.$message.warning('请至少选择一条数据！')
            return false
          } else {
            this.selectRows.forEach((item) => {
              ids.push(item.purchasingDemandProductId)
            })
            ids = ids.join(',')
          }
        } else {
          ids = row.purchasingDemandProductId
        }
        delivery.initLoading(ids)
      },

      //克隆
      async clone() {
        if (this.selectRows.length == 0) {
          this.$message.warning('请选择一条产品类型为标准品的数据！')
          return false
        } else if (this.selectRows.length > 1) {
          this.$message.warning('克隆只能操作一条数据！')
          return false
        } else if (this.selectRows[0].standardCustomized != '标准品') {
          this.$message.warning('克隆只能选择标准品！')
          return false
        } else {
          const res = await purchaseDemandInterator.purchasingDClone(
            this.selectRows[0].purchasingDemandProductId
          )

          if (res && res.code === '000000') {
            this.$refs.purchaseDemandAdd.initData(res.data)
            this.add()
          }
        }
      },

      // 新增采购需求
      add(id) {
        if (id) {
          this.$refs.purchaseDemandAdd.getEditData(id)
        }
        this.$refs.purchaseDemandAdd.demandAddVisable = true
      },

      // 格式化库存数据
      formatStock(stockData) {
        stockData = stockData || []
        let isArray = (stockData.constructor = Array)
        let __stockInfo = []

        if (isArray && stockData.length > 0) {
          for (let i = 0; stockData.length > i; i++) {
            __stockInfo.push(
              `${stockData[i].warehouseName}:${stockData[i].num}`
            )
          }
        } else {
          __stockInfo.push(`--`)
        }
        return __stockInfo.toString()
      },

      //待分配 type:1批量设置，2单个设置
      allotClick(type, buyerId) {
        if (type == 1) {
          if (!this.selectRows.length) {
            this.$message.warning('请至少选择一条数据！')
            return false
          } else {
            let arr = []
            this.selectRows.forEach((item) =>
              arr.push(item.purchasingDemandProductId)
            )
            this.purchasingDemandProductIds = arr.join(',')
          }
        } else {
          this.purchasingDemandProductIds = buyerId
        }

        this.$refs.setBuyer.init()
      },

      // 设置采购开发
      async setBuyer(form) {
        let params = {
          buyerId: form.buyerId,
          buyerName: form.buyerName,
          buyerNameEn: form.buyerNameEn,
          buyerArea: form.area,
          purchasingDemandProductIds: this.purchasingDemandProductIds,
        }
        const res = await purchaseDemandInterator.updatePurchasingDemandBuyer(
          params
        )

        if (res?.code === '000000') {
          this.$baseMessage(
            '采购开发分配成功',
            'success',
            false,
            'erp-hey-message-success'
          )
          this.initLoading(this.tabsType)
        }
      },

      // 拆分采购
      splitPurchase(row, bomList) {
        if (row) {
          this.parentForm = { ...row }
          if (
            this.parentForm.printingWay.toUpperCase() === 'BLANK' ||
            !this.parentForm.printingWay
          ) {
            this.printingWayList = [{ label: 'Blank', value: 'Blank' }]
          } else {
            this.printingWayList = [
              { label: 'Blank', value: 'Blank' },
              {
                label: this.parentForm.printingWay,
                value: this.parentForm.printingWay,
              },
            ]
          }
        }

        this.$refs.splitModel.initLoading(row, bomList)
      },

      // 取消拆分
      cancelSplit(id) {
        this.$confirm(`是否取消拆分?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const res = await purchaseDemandInterator.cancelSplit(id)
            if (res?.code === '000000') {
              this.$message.success('取消拆分成功！')
              this.getList()
            }
          })
          .catch(() => { })
      },

      // 备货单详情采购开发回显
      buyerNameFormat(data) {
        if (data) {
          const arr = []
          data.forEach((item) =>
            item.buyerName ? arr.push(item.buyerName) : ''
          )
          return Array.from(new Set(arr)).toString()
        } else {
          return '--'
        }
      },

      // 查询库存锁定量
      stockLock(data) {
        this.$refs.unlock.initLoading(data)
      },

      // 高级筛选
      highSearch() {
        this.drawer = true
        this.$nextTick(() => {
          this.$refs.demandHighSearch.type = this.tabsType
        })
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },

      // 高级筛选触发
      searchClick(val) {
        this.queryForm = val
        this.initLoading()
        this.highSearchClose()
      },

      // 点击知道了，修改状态。
      async changeStatus(val) {
        let data = {
          salesOrderNumber: val.salesOrderNumber,
          sku: val.sku,
          orderProductUniqueId: val.orderProductUniqueId,
        }
        const res =
          await purchaseDemandInterator.updateRenderingProductStatusUsingPOST(
            data
          )

        if (res && res.code.toString() === '000000') {
          this.$message.success('操作完成！')
          this.getList()
        }
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      // 取消预览
      closeViewer() {
        this.showViewer = false
      },

      // 打开拆分采购
      splitOpen(row, bomList) {
        this.splitPurchase(null, bomList)
      },

      // 打开bom单
      bomDrawerOpen(list) {
        this.bomDrawer = true
        this.$nextTick(() => {
          this.$refs.bomModel.upDataBomList(list ? [...list] : [])
        })
      },

      // 关闭bom单
      bomDrawerClose() {
        this.bomDrawer = false
      },

      // 设置订单支持
      setBusinessName(row) {
        this.purchasingDemandProductId = row.purchasingDemandProductId
        this.$refs['UserChoose'].showAddEdit('', '', true)
      },

      // 设置订单支持
      async chooseUser(userIdArr) {
        let res = await purchaseDemandInterator.setDemandFromBusiness({
          ...userIdArr[0],
          purchasingDemandProductId: this.purchasingDemandProductId,
        })
        if (res.code == '000000') {
          this.$message({
            message: '操作成功',
            type: 'success',
          })
        }
        this.initLoading(2)
      },

      // 委外加工校验
      outProcessCheck(list) {
        return list.some((item) => {
          if (item.splitFlag === 1 || item.bomProductId) {
            this.$message.warning('采购需求已拆分，无法生成加工单')
            return true
          } else if (
            !item.stockAvailableNumList?.length &&
            item.stockLockNumList.length === 0
          ) {
            this.$message.warning('库存不足，无法生成加工单')
            return true
          } else if (item.updateFlag === 1) {
            this.$message.warning('销售订单异常，无法生成加工单')
            return true
          } else if (item.state != 5) {
            this.$message.warning('采购需求状态不是待处理，无法生成加工单')
            return true
          } else {
            return false
          }
        })
      },

      // 委外加工
      outProcess(row) {
        if (!row && this.selectRows.length === 0) {
          this.$message.warning('请至少选择一条数据！')
          return
        }
        let selectRows = row ? [row] : this.selectRows
        if (this.outProcessCheck(selectRows)) {
          return
        }
        let selectRowsPros = selectRows.map((i) => {
          const { sku, orderProductUniqueId, salesOrderId, salesOrderNumber } =
            i
          return {
            sku,
            orderProductUniqueId,
            salesOrderId,
            salesOrderNumber,
          }
        })
        const routeData = this.$router.resolve({
          path: '/purchase/outsourceProcess/outsource-add',
          query: {
            purchasepros: encodeURI(JSON.stringify(selectRowsPros)),
          },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-table__row .el-button {
      margin: 0 5px;
    }

    .el-icon-edit,
    .el-icon-sell,
    .el-icon-folder-checked {
      font-size: 16px;
    }

    .el-divider--horizontal {
      margin: 8px 0 !important;
    }

    .number {
      .cell {
        position: relative;

        .el-table__expand-icon {
          position: absolute;
          left: 5px;
          top: 2px;
        }

        em {
          position: absolute;
          top: 3px;
          right: 6px;
        }

        i {
          margin: 0;
        }

        .el-table__indent,
        .el-table__placeholder {
          display: none;
        }
      }
    }
  }

  .tip_content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 5px 5px;
    min-width: 80px;
    line-height: 22px;
  }

  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;

    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }

    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }

  .toggle-arrow {
    cursor: pointer;
    position: absolute;
    left: 9px;
    top: 5px;
  }
</style>
