var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"mail-sign-dialog",attrs:{"title":_vm.$t(_vm.title),"width":"800px","visible":_vm.isDialogVisible,"close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.isDialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"120px","label-position":"top"}},[_c('el-form-item',{attrs:{"prop":"emailAccount","label":_vm.$t('boxsetting.ExecuteMailbox'),"rules":[
        {
          required: true,
          message: _vm.$t('placeholder.plsSel'),
          trigger: 'change',
        } ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('placeholder.plsInput'),"value-key":"emailAccount"},on:{"change":_vm.emailAccountChange},model:{value:(_vm.form.emailAccount),callback:function ($$v) {_vm.$set(_vm.form, "emailAccount", $$v)},expression:"form.emailAccount"}},_vm._l((_vm.accountList),function(item){return _c('el-option',{key:item.mailAccountId,attrs:{"label":item.emailAccount,"value":item.emailAccount}})}),1)],1),_c('el-form-item',{attrs:{"required":"","label":_vm.$t('reqmsg.$62')}},[_c('div',{staticClass:"inner"},[_c('span',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.lang == 'en' ? 'Always move' : '总把')+" ")]),_c('el-form-item',{attrs:{"prop":"content","label-width":"0","rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              validator: _vm.$formValidation.isEmail,
              trigger: 'change',
            } ]}},[_c('el-input',{staticStyle:{"width":"240px","margin":"0 3px 10px"},attrs:{"placeholder":_vm.lang != 'en'
                ? '请输入邮箱地址内容'
                : 'please input mailAddress content',"maxlength":"50"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.content"}})],1),_c('span',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.lang == 'en' ? 'message to this folder：' : '的邮件，移动到此文件夹：')+" ")]),_c('el-form-item',{attrs:{"prop":"catagoryId","label-width":"0","rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsSel'),
              trigger: 'change',
            } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsInput'),"value-key":"catagoryId"},on:{"change":_vm.changeCatagory},model:{value:(_vm.form.catagoryId),callback:function ($$v) {_vm.$set(_vm.form, "catagoryId", $$v)},expression:"form.catagoryId"}},_vm._l((_vm.receiveCatgoryList[_vm.form.emailAccount]),function(item){return _c('el-option',{key:item.catagoryId,attrs:{"label":item.name,"value":item.catagoryId}})}),1)],1)],1)])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('boxsetting.Cancel')))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('boxsetting.Confirm'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }