/**
 * 自定义表格高级筛选监听columns变化，重置tableKey,解决渲染错乱的问题
 */

export default {
  data() {
    return {
      customTableKey: 1, //表格绑定的key值
    }
  },

  created() {
    if (this.finallyColumns && Array.isArray(this.finallyColumns)) {
      //通用finallyColumns
      this.$watch('finallyColumns', () => {
        this.customTableKey = Math.random()
      })
    }

    if (
      this.expressFinallyColumns &&
      Array.isArray(this.expressFinallyColumns)
    ) {
      //出运快递
      this.$watch('expressFinallyColumns', () => {
        this.customTableKey = Math.random()
      })
    }

    if (
      this.shippingFinallyColumns &&
      Array.isArray(this.shippingFinallyColumns)
    ) {
      // 出运海运空运
      this.$watch('shippingFinallyColumns', () => {
        this.customTableKey = Math.random()
      })
    }
  },
}
