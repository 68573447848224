<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="50%"
    @close="close"
    v-if="dialogFormVisible"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="el-dialog-div">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        :label-position="settings === 'zh' ? 'left' : 'top'"
      >
        <el-row :gutter="15">
          <!-- 规格 -->
          <el-col
            :span="12"
            v-if="formData.typeArr && formData.typeArr.length > 0"
          >
            <el-form-item :label="formData.typeArr[0]">
              <el-input :value="formData.specsValue1" disabled />
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="formData.typeArr && formData.typeArr.length > 1"
          >
            <el-form-item :label="formData.typeArr[1]">
              <el-input :value="formData.specsValue2" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <!-- 包装方式/英文 -->
            <el-form-item
              :label="$t('productDetail.PackingMethodEnglish')"
              prop="packingMethodEn"
            >
              <!-- 请选择 -->
              <el-select
                v-model="form.packingMethodEn"
                :placeholder="$t('placeholder.plsSel')"
                disabled
              >
                <el-option
                  v-for="(item, index) of proPackingData"
                  :key="index"
                  :label="item.nameEn"
                  :value="item.nameEn"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row></el-row>
        <el-row
          style="border-top: 1px solid #ededed"
          class="mt20 pt20"
          :gutter="15"
        >
          <el-row class="mb20">
            <!-- 产品情况 -->
            <h4>{{ $t('productDetail.ProductSituation') }}</h4>
          </el-row>
          <el-col :span="12">
            <!-- 产品重量 -->
            <el-form-item
              :label="$t('productDetail.ProductWeight')"
              prop="productWeightKgs"
            >
              <!-- 请输入 -->
              <el-input
                v-model="form.productWeightKgs"
                :placeholder="$t('placeholder.plsInput')"
                disabled
              />
              <p class="calcu-p">
                LBS:
                <span>{{ productWeightKgsLbs }}</span>
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 印刷区域尺寸 -->
            <el-form-item
              :label="$t('productDetail.PrintingAreaSize')"
              prop="printSize"
            >
              <el-row :gutter="10" type="flex" justify="space-between">
                <el-col :span="12">
                  <!-- 长度 -->
                  <el-input
                    v-model="form.printLength"
                    type="number"
                    :placeholder="$t('placeholder.M2008')"
                    disabled
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                  >
                    <template slot="append">
                      {{ form.printUnit }}
                    </template>
                  </el-input>
                  <p class="t_a_r calcu-p">
                    <span>{{ form.printLengthCovert }}</span>
                    <!-- {{ proPrintUnitText }} -->
                  </p>
                </el-col>
                <el-col :span="1">x</el-col>
                <el-col :span="12">
                  <!-- 宽度 -->
                  <el-input
                    v-model="form.printWidth"
                    oninput="value=value.replace(/^\.+|[^\d\.]/g, '').replace(/^0{1,}/g, '')"
                    type="number"
                    disabled
                    :placeholder="$t('placeholder.M2009')"
                  >
                    <template slot="append">
                      {{ form.printUnit }}
                    </template>
                  </el-input>
                  <p class="t_a_r calcu-p">
                    <span>{{ form.printWidthCovert }}</span>
                  </p>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="mt20">
          <!-- 产品尺寸 -->
          <el-form-item :label="$t('productDetail.ProductSize')">
            <el-row :gutter="10" type="flex" justify="space-between">
              <el-col :span="7">
                <!-- 长度 -->
                <el-input
                  v-model="form.productLengthCm"
                  type="number"
                  :placeholder="$t('placeholder.M2008')"
                  disabled="disabled"
                >
                  <template slot="append">
                    {{ form.sizeUnit }}
                  </template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.productLengthIn }}</span>
                  {{ proSizeUnitText }}
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 宽度 -->
                <el-input
                  v-model="form.productWidthCm"
                  type="number"
                  :placeholder="$t('placeholder.M2009')"
                  disabled
                >
                  <template slot="append">
                    {{ form.sizeUnit }}
                  </template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.productWidthIn }}</span>
                  {{ proSizeUnitText }}
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 高度 -->
                <el-input
                  v-model="form.productHeightCm"
                  type="number"
                  :placeholder="$t('placeholder.M2010')"
                  disabled
                >
                  <template slot="append">
                    {{ form.sizeUnit }}
                  </template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.productHeightIn }}</span>
                  {{ proSizeUnitText }}
                </p>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
        <el-row
          style="border-top: 1px solid #ededed"
          class="mt20 pt20"
          :gutter="15"
        >
          <el-row class="mb20">
            <!-- 装箱情况 -->
            <h4>{{ $t('productDetail.PackingSituation') }}</h4>
          </el-row>
          <el-col :span="12">
            <!-- 装箱数量 -->
            <el-form-item
              :label="$t('productDetail.PackingQuantity')"
              prop="numberPerBox"
            >
              <!-- 请输入 -->
              <el-input
                v-model.number="form.numberPerBox"
                :placeholder="$t('placeholder.plsInput')"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 毛重 -->
            <el-form-item
              :label="$t('productDetail.GrossWeight')"
              prop="boxWeightKgs"
            >
              <!-- 请输入 -->
              <el-input
                v-model.number="form.boxWeightKgs"
                :placeholder="$t('placeholder.plsInput')"
                disabled
              />
              <p class="calcu-p">
                LBS:
                <span>{{ boxWeightKgsLbs }}</span>
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- 装箱尺寸 -->
          <el-form-item :label="$t('productDetail.PackingSize')">
            <el-row :gutter="10" type="flex" justify="space-between">
              <el-col :span="7">
                <!-- 长度 -->
                <el-input
                  v-model.number="form.boxLengthCm"
                  :placeholder="$t('placeholder.M2008')"
                  disabled
                >
                  <template slot="append">cm</template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxLengthIn }}</span>
                  IN
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 宽度 -->
                <el-input
                  v-model.number="form.boxWidthCm"
                  :placeholder="$t('placeholder.M2009')"
                  disabled
                >
                  <template slot="append">cm</template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxWidthIn }}</span>
                  IN
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 高度 -->
                <el-input
                  v-model.number="form.boxHeightCm"
                  :placeholder="$t('placeholder.M2010')"
                  disabled
                >
                  <template slot="append">cm</template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxHeightIn }}</span>
                  IN
                </p>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="end">
          <p>
            <!-- 体积 -->
            {{ $t('productDetail.VolumeM3') }}(M³)：{{ boxM3 }}
            <span></span>
          </p>
          <p class="ml30">
            <!-- 体积 -->
            {{ $t('productDetail.VolumeFT3') }}(ft³)：{{ boxFt3 }}
            <span></span>
          </p>
        </el-row>
        <el-row style="border-top: 1px solid #ededed" class="mt20 pt20">
          <!-- 备注信息 -->
          <el-form-item :label="$t('productDetail.Remarks')">
            <!-- 请输入500个字符以内 -->
            <el-input
              v-model="form.remarks"
              type="textarea"
              maxlength="500"
              show-word-limit
              :autosize="{ minRows: 2 }"
              :placeholder="$t('placeholder.M2004')"
              disabled
            />
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <el-row class="t_a_c">
        <!-- 关闭 -->
        <el-button @click="close">{{ $t('productDetail.Close') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { ProductListInteractor } from '@/core'
  import { deepCopy } from 'kits'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ProductSpecListModel',
    props: {
      formData: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    data() {
      return {
        title: this.$t('productDetail.AddedSpecifications'),
        form: {},
        rules: {
          packingMethodEn: [
            {
              required: true,
              message: '请选择包装方式/英文',
              trigger: 'change',
            },
          ],
          productWeightKgs: [
            {
              required: true,
              message: '请輸入产品重量',
              trigger: 'blur',
            },
          ],
          numberPerBox: [
            {
              required: true,
              message: '请輸入装箱数量',
              trigger: 'blur',
            },
          ],
          boxWeightKgs: [
            {
              required: true,
              message: '请输入毛重(KGS)',
              trigger: 'blur',
            },
          ],
        },
        specSelect: [],
        dialogFormVisible: false,
        proPackingData: [], //包装方式数据3:[]
        editIndex: null,
        productWeightKgsLbs: null, //产品重量换算
        boxWeightKgsLbs: null, //产品毛重换算
        boxLengthCmIn: null, //装箱尺寸-长度
        boxWidthCmIn: null, //装箱尺寸-宽度
        boxHeightCmIn: null, //装箱尺寸-高度
        productLengthCmIn: null, //产品尺寸-长度
        productWidthCmIn: null, //产品尺寸-宽度
        productHeightCm: null, //产品尺寸-高度
        boxFt3: null,
        boxM3: null,
        imgMainFileList: [],
        image: '',
        proSizeUnitText: 'CM',
      }
    },
    computed: {
      //装箱M3，t3
      boxM3Ft3Calcu() {
        const { boxLengthCm, boxWidthCm, boxHeightCm } = this.form,
          obj = {
            boxLengthCm,
            boxWidthCm,
            boxHeightCm,
          }
        return obj
      },
      //产品M3
      productM3Calcu() {
        const { productLengthCm, productWidthCm, productHeightCm } = this.form,
          obj = {
            productLengthCm,
            productWidthCm,
            productHeightCm,
          }
        return obj
      },
      ...mapGetters({
        settings: 'settings/language',
      }),
    },
    watch: {
      // 装箱boxM3，boxFt3
      boxM3Ft3Calcu(v) {
        if (!v.boxLengthCm || !v.boxWidthCm || !v.boxHeightCm) {
          this.boxM3 = ''
          this.boxFt3 = ''
          return
        }
        if (
          Number(v.boxLengthCm) ||
          Number(v.boxWidthCm) ||
          Number(v.boxHeightCm)
        )
          this.boxM3 = Math.round(
            v.boxLengthCm * v.boxWidthCm * v.boxHeightCm * 0.000001
          )

        this.form.boxVolumeM = (
          v.boxLengthCm *
          v.boxWidthCm *
          v.boxHeightCm *
          0.000001
        ).toFixed(6)
        this.boxFt3 = Math.round(
          v.boxLengthCm * v.boxWidthCm * v.boxHeightCm * 0.000001 * 35.31467
        )
      },
      // 产品boxM3
      productM3Calcu(v) {
        if (!v.productLengthCm || !v.productWidthCm || !v.productHeightCm) {
          this.form.productVolume = ''
          return
        }
        if (
          Number(v.productLengthCm) ||
          Number(v.productWidthCm) ||
          Number(v.productHeightCm)
        )
          this.form.productVolume = (
            v.productLengthCm *
            v.productWidthCm *
            v.productHeightCm *
            0.000001
          ).toFixed(6)
      },

      // 产品重量
      'form.productWeightKgs': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.productWeightKgsLbs = '')
          }
          if (Number(value)) {
            this.productWeightKgsLbs = (value * 2.2046).toFixed(1)
          }
        },
      },
      // 产品毛重
      'form.boxWeightKgs': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.boxWeightKgsLbs = '')
          }
          if (Number(value)) {
            this.boxWeightKgsLbs = Math.round(value * 2.2046)
          }
        },
      },
      // 产品尺寸（长度）
      'form.productLengthCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.productLengthIn = '')
          }
          if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
            //英尺换算成英寸
            if (this.form.sizeUnit === 'FT') {
              this.form.productLengthIn = (value * 12).toFixed(2)
            }
            //厘米换算成英寸
            if (this.form.sizeUnit === 'CM') {
              this.form.productLengthIn = (value * 0.3937).toFixed(2)
            }
          } else {
            //英寸换算成厘米
            this.form.productLengthIn = (value * 2.54).toFixed(2)
          }
        },
      },
      // 产品尺寸（宽度）
      'form.productWidthCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.productWidthIn = '')
          }
          if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
            //英尺换算成英寸
            if (this.form.sizeUnit === 'FT') {
              this.form.productWidthIn = (value * 12).toFixed(2)
            }
            //厘米换算成英寸
            if (this.form.sizeUnit === 'CM') {
              this.form.productWidthIn = (value * 0.3937).toFixed(2)
            }
          } else {
            //英寸换算成厘米
            this.form.productWidthIn = (value * 2.54).toFixed(2)
          }
        },
      },
      // 产品尺寸（高度）
      'form.productHeightCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.productHeightIn = '')
          }
          if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
            //英尺换算成英寸
            if (this.form.sizeUnit === 'FT') {
              this.form.productHeightIn = (value * 12).toFixed(2)
            }
            //厘米换算成英寸
            if (this.form.sizeUnit === 'CM') {
              this.form.productHeightIn = (value * 0.3937).toFixed(2)
            }
          } else {
            //英寸换算成厘米
            this.form.productHeightIn = (value * 2.54).toFixed(2)
          }
        },
      },

      // 装箱尺寸（长度）
      'form.boxLengthCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.boxLengthIn = '')
          }
          if (Number(value)) {
            this.form.boxLengthIn = (value * 0.3937).toFixed(2)
          }
        },
      },
      // 装箱尺寸（宽度）
      'form.boxWidthCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.boxWidthIn = '')
          }
          if (Number(value)) {
            this.form.boxWidthIn = (value * 0.3937).toFixed(2)
          }
        },
      },
      // 装箱尺寸（高度）
      'form.boxHeightCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.boxHeightIn = '')
          }
          if (Number(value)) {
            this.form.boxHeightIn = (value * 0.3937).toFixed(2)
          }
        },
      },
      form: {
        //用于编辑回显的规格下拉
        immediate: true,
        deep: true,
        handler(value) {
          if (
            value.specsValue1 &&
            !value.specsValue2 &&
            !Array.isArray(value.specsValue1)
          ) {
            value.specsValue1 = value.specsValue1.split(',')
          } else if (value.specsValue1 && value.specsValue2) {
            if (!Array.isArray(value.specsValue1)) {
              value.specsValue1 = value.specsValue1.split(',')
            } else if (!Array.isArray(value.specsValue2)) {
              value.specsValue2 = value.specsValue2.split(',')
            }
          }
        },
      },
    },
    created() {
      this.$Bus.$on('specSendBus', (spec) => {
        if (spec) {
          this.specSelect = spec
        }
      })
      // 包装方式
      this.getDetailByType3()
    },
    methods: {
      //包装方式下拉
      async getDetailByType3() {
        let response = await ProductListInteractor.getDetailByTypeApi({
          type: 3,
        })
        if (response?.code == '000000') {
          this.proPackingData = response.data
        }
      },
      //展示弹框
      productSpecListModel(row, index) {
        if (row) {
          // this.title = '查看规格'
          this.title = this.$t('productDetail.ViewSpecifications')
          this.editIndex = index
          this.form = Object.assign({}, deepCopy(row))
          if (!this.form.sizeUnit) {
            this.form.sizeUnit = 'IN'
          }
          if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
            this.proSizeUnitText = 'IN'
          } else {
            this.proSizeUnitText = 'CM'
          }
          if (this.form.image) {
            this.image = this.form.image
            this.imgMainFileList.push({ url: this.form.image })
          }
        }
        this.dialogFormVisible = true
      },
      // 主图上传
      uploadImgAction({ file }) {
        this.utils.ossUpload({ file, type: 'productImg' }).then(({ res }) => {
          this.image = res.requestUrls[0]
        })
      },
      // 删除主图
      handleRemoveImgMain(file, fileList) {
        this.imgMainFileList = []
        this.image = ''
      },
      // 预览主图、轮播图
      handlePreview(file) {
        this.dialogPreviewUrl = file.url
        this.dialogPreview = true
      },
      // 上传之前验证
      beforePicUpload(file) {
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension =
          testmsg === 'jpg' ||
          testmsg === 'JPG' ||
          testmsg === 'png' ||
          testmsg === 'PNG' ||
          testmsg === 'jpeg' ||
          testmsg === 'JPEG'
        const isLt50M = file.size / 1024 / 1024 < 2
        if (!extension) {
          this.$baseMessage(
            '上传图片只能是jpg/png/jpeg格式',
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false //必须加上return false; 才能阻止
        } else if (!isLt50M) {
          this.$baseMessage(
            '上传图片大小不能超过2MB!',
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        } else {
          return true
        }
      },
      // 关闭
      close() {
        this.title = '新增规格'
        this.dialogFormVisible = false
        this.image = ''
        this.imgMainFileList = []
        this.boxM3 = ''
        this.boxFt3 = ''
        this.editIndex = ''
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
      },
      // 保存
      save() {
        this.form.image = this.image
        let form = deepCopy(this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.$emit('spec-row', form, this.editIndex)
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .calcu-p {
    font-size: 12px;
    color: #999;
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .el-dialog-div {
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
  }
  ::v-deep {
    .el-dialog-div {
      .el-form {
        width: 95% !important;
        margin: 0 auto !important;
      }
      .el-input-group__append,
      .el-input-group__prepend {
        padding: 0 10px;
      }
      .append-select {
        color: rgba(0, 0, 0, 0.65);
        height: 34px;
        border: none;
        background: #f5f7fa;
        cursor: pointer;
      }
    }
  }
</style>
