<template>
  <div class="search_form">
    <el-form ref="form" :model="form" label-position="top">
      <!-- 产品来源 -->
      <el-form-item :label="$t('productCenter.ProductSource')">
        <el-select
          v-model="form.createWay"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <!-- <el-option label="自行新增" value="0" /> -->
          <!-- <el-option label="生产系统" value="1" /> -->
          <!-- <el-option label="询盘生成" value="2" /> -->
          <el-option :label="$t('productCenter.SelfAdd')" value="0" />
          <el-option :label="$t('productCenter.ProductionSystem')" value="1" />
          <el-option :label="$t('productCenter.InquiryGeneration')" value="2" />
        </el-select>
      </el-form-item>
      <!-- 产品类型 -->
      <el-form-item :label="$t('productCenter.ProductType')">
        <el-select
          v-model="form.standardCustomized"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          :popper-append-to-body="false"
          clearable
        >
          <el-option
            v-for="item in proTypeData"
            :key="item.nameCn"
            :label="$t(item.codeName)"
            :value="item.nameCn"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 产品类别 -->
      <el-form-item :label="$t('productCenter.ProductCategory')">
        <el-cascader
          ref="cascaderAddr"
          :options="categoryOptions"
          v-model="form.categoryId"
          :props="optionProps"
          clearable
        ></el-cascader>
      </el-form-item>
      <!-- 产线 -->
      <el-form-item :label="$t('productCenter.ProductionLine')">
        <el-select
          v-model="form.productionLineId"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          :popper-append-to-body="false"
          clearable
        >
          <el-option
            v-for="item in proLineData"
            :key="item.nameCn"
            :label="
              settings === 'zh'
                ? item.nameCn + '-' + item.belongerName
                : item.nameEn + '-' + item.belongerNameEn
            "
            :value="item.productSettingId"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="仓库(改)">
        <el-select v-model="form.cangku" placeholder="请选择" class="w100">
          <el-option label="调接口" value="调接口" />
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="库存(改)">
        <div class="line_input">
          <el-input
            v-model="form.kucun1"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input
            v-model="form.kucun2"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
        </div>
      </el-form-item> -->
      <!-- 供应商 -->
      <el-form-item :label="$t('other.supplier')">
        <el-autocomplete
          v-model="supplierName"
          :fetch-suggestions="querySearchAsync"
          :placeholder="$t('placeholder.plsInput')"
          @select="handleSelect"
          clearable
          class="w100"
          @change="supplierNameChange"
        ></el-autocomplete>
      </el-form-item>
      <!-- 商城状态 -->
      <!-- <el-form-item :label="$t('productCenter.MallStatus')">
        <el-select
          v-model="form.mallStatus"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <el-option
            v-for="it in mallPlatFormStatus"
            :key="it.id"
            :label="settings === 'en' ? it.labelEn : it.label"
            :value="it.value"
          />
        </el-select>
      </el-form-item> -->

      <!-- 选择平台 -->
      <el-form-item :label="$t('productCenter.listingPlatform')">
        <el-select
          v-model="form.platformList"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          multiple
          clearable
        >
          <el-option
            v-for="it in platList"
            :key="it.id"
            :label="settings === 'en' ? it.nameEn : it.name"
            :value="it.code"
          />
        </el-select>
      </el-form-item>
      <!-- 平台状态 -->
      <!-- <el-form-item :label="$t('productCenter.platstatus')">
        <el-select
          v-model="form.platformType"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <el-option
            v-for="it in platFormStatus"
            :key="it.value + 100"
            :label="settings === 'en' ? it.labelEn : it.label"
            :value="it.value"
          />
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="询价数">
        <div class="line_input">
          <el-input
            v-model="form.inquiryCountMin"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input
            v-model="form.inquiryCountMax"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
        </div>
      </el-form-item> -->
      <!-- <el-form-item label="下单数">
        <div class="line_input">
          <el-input
            v-model="form.contractCountMin"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input
            v-model="form.contractCountMax"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
        </div>
      </el-form-item> -->
      <!-- 产品运营 -->
      <el-form-item :label="$t('productCenter.ProductOperations')">
        <el-input
          clearable
          v-model="belongerName"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('belongerName')"
          @change="belongerNameChange"
        ></el-input>
      </el-form-item>
      <!-- 报关信息 -->
      <p class="mb5">{{ $t('productCenter.WhetherTheCustoms') }}</p>
      <el-form-item>
        <el-radio-group v-model="form.declareCompleteStatus">
          <el-radio :label="0">{{ $t('productCenter.AllProducts') }}</el-radio>
          <el-radio :label="1">
            {{ $t('productCenter.CustomsDeclarationNot') }}
          </el-radio>
          <el-radio :label="2">
            {{ $t('productCenter.CustomsDeclarationHas') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- youtube 视频 -->
      <p class="mb5">{{ $t('productCenter.YouTubeVideo') }}</p>
      <el-form-item>
        <el-radio-group v-model="form.hasYoutube">
          <el-radio label="">{{ $t('productCenter.all') }}</el-radio>
          <el-radio :label="1">
            {{ $t('productCenter.added') }}
          </el-radio>
          <el-radio :label="0">
            {{ $t('productCenter.notAdded') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- 创建人 -->
      <el-form-item :label="$t('productCenter.Creator')">
        <el-input
          clearable
          v-model="createName"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('createName')"
          @change="createNameChange"
        ></el-input>
      </el-form-item>
      <!-- 创建时间 -->
      <el-form-item :label="$t('productCenter.CreationTime')">
        <el-date-picker
          v-model="createDaterange"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
          :default-time="['00:00:00', '23:59:59']"
          @change="createDaterangeChange"
        />
      </el-form-item>
      <!-- 修改时间 -->
      <el-form-item :label="$t('productCenter.ModifiedTime')">
        <el-date-picker
          v-model="editDaterange"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
          :default-time="['00:00:00', '23:59:59']"
          @change="editDaterangeChange"
        />
      </el-form-item>
      <!-- 审核通过时间 -->
      <el-form-item :label="$t('orderList.ApprovalTime')">
        <el-date-picker
          v-model="ApprovalTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
          :default-time="['00:00:00', '23:59:59']"
          @change="ApprovalTimeChange"
        />
      </el-form-item>
      <div class="operate">
        <!-- 确定 -->
        <el-button type="primary" @click="searchClick">
          {{ $t('productCenter.Confirm') }}
        </el-button>
        <!-- 重置 -->
        <el-button @click="resetClick">
          {{ $t('productCenter.Reset') }}
        </el-button>
      </div>
    </el-form>

    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import { categoryListForSelect } from '@/api/product/productPrintWay'
  import { supplierListForByParam } from '@/api/client-supplier/supplier-manage'
  import UserChoose from '@/components/userChoose2'
  import { mapGetters } from 'vuex'
  import { ProductViewInteractor } from '@/core/interactors/product/productView'
  export default {
    name: 'ProductSearch',
    components: { UserChoose },
    data() {
      return {
        categoryOptions: [], //产品类别
        // optionProps: {
        //   value: 'productCategoryId',
        //   label: this.settings === 'zh' ? 'nameCn' : 'nameEn',
        //   children: 'children',
        //   checkStrictly: true,
        // },
        form: {
          declareCompleteStatus: 0, // 报关信息默认全部产品
          hasYoutube: '',
        },
        createDaterange: [],
        editDaterange: [],
        ApprovalTime: [],
        belongerName: '', //产品运营
        belongerNameId: [], //产品运营id
        createName: '', //创建人
        createNameId: [], //创建人id
        proTypeData: [], //产品类型7
        isUserChoose: '', //当前组织架构是谁调用
        supplierOption: [], //供应商下拉
        supplierName: '', //供应商名称
        proLineData: [], //产线
        platList: [],
        mallPlatFormStatus: [
          {
            label: '未上架',
            labelEn: 'Not Activate',
            value: 0,
          },
          {
            label: '已上架',
            labelEn: 'Activated',
            value: 1,
          },
        ],
        platFormStatus: [
          {
            label: '未上传',
            labelEn: 'Not Launched',
            value: 0,
          },
          {
            label: '已上架',
            labelEn: 'Activated',
            value: 1,
          },
          {
            label: '已下架',
            labelEn: 'Deactivated',
            value: 2,
          },
        ],
      }
    },
    created() {
      this.init()
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      optionProps() {
        return {
          value: 'productCategoryId',
          label: this.settings === 'zh' ? 'nameCn' : 'nameEn',
          children: 'children',
          checkStrictly: true,
        }
      },
    },
    watch: {
      settings() {
        this.init()
      },
    },
    methods: {
      init() {
        //产品类别
        this.getCategoryListForSelect()
        //产品类型
        this.getDetailByType7()
        //产品产线
        this.getDetailByType9()
        //默认获取所有供应商
        this.pubApi.supplierListForSelect().then((res) => {
          this.supplierOption = res.data
        })
        // 平台列表
        this.initPaltList()
      },

      // 确定searchClick
      searchClick() {
        //创建时间
        if (this.createDaterange && this.createDaterange.length) {
          this.form.createTimeMin = this.createDaterange[0]
          this.form.createTimeMax = this.createDaterange[1]
        }
        //修改时间
        if (this.editDaterange && this.editDaterange.length) {
          this.form.updateTimeMin = this.editDaterange[0]
          this.form.updateTimeMax = this.editDaterange[1]
        }
        // 审核通过时间
        if (this.ApprovalTime && this.ApprovalTime.length) {
          this.form.auditPassTimeMin = this.ApprovalTime[0]
          this.form.auditPassTimeMax = this.ApprovalTime[1]
        }
        //供应商
        if (this.form.supplierId === '' && this.supplierName) {
          this.form.supplierId = this.supplierName
        }
        //创建人
        if (this.createName) {
          this.form.creatorId = this.createNameId.join(',')
        }
        //产品运营
        if (this.belongerName) {
          this.form.belongerId = this.belongerNameId.join(',')
        }
        let params = JSON.parse(JSON.stringify(this.form))

        //类别
        if (params.categoryId) {
          params.categoryId = params.categoryId.pop()
        }
        this.$emit('searchClick', params)
      },

      // 供应商名称/编码模糊查询
      querySearchAsync(queryString, cb) {
        let that = this
        if (queryString) {
          this.form.supplierId = ''
          supplierListForByParam({ param: queryString }).then((res) => {
            let results = res.data.map((item) => {
              return {
                ...item,
                value: item.supplierName,
              }
            })
            cb(results)
          })
        } else {
          let list = that.supplierOption.map((item) => {
            return {
              ...item,
              value: item.supplierName,
            }
          })
          cb(list)
        }
      },
      //供应商选择
      handleSelect(val) {
        this.supplierName = val.supplierName
        this.form.supplierId = val.supplierId
      },
      //供应商清空
      supplierNameChange(val) {
        if (!val) {
          this.supplierName = ''
          this.form.supplierId = ''
        }
      },
      //产品类别
      async getCategoryListForSelect() {
        let response = await categoryListForSelect()
        if ((response.code = '000000')) {
          this.categoryOptions = response.data
        }
      },
      //产品类型
      async getDetailByType7() {
        this.pubApi.getDetailByType({ type: 7 }).then((response) => {
          if (response.code === '000000') {
            this.proTypeData = response.data.map((item) => {
              return {
                ...item,
                codeName:
                  item.nameCn === '标准品'
                    ? 'productCenter.StandardProduct'
                    : 'productCenter.CustomizedProduct',
              }
            })
          }
        })
      },
      //产品产线
      async getDetailByType9() {
        this.pubApi.getDetailByType({ type: 9 }).then((response) => {
          if (response.code === '000000') {
            this.proLineData = response.data
          }
        })
      },
      // 平台列表
      async initPaltList() {
        let res = await ProductViewInteractor.getPlatList()
        this.platList = res.data || []
      },
      //创建时间清空
      createDaterangeChange(val) {
        if (!val) {
          this.form.createTimeMin = ''
          this.form.createTimeMax = ''
        }
      },
      //修改时间清空
      editDaterangeChange(val) {
        if (!val) {
          this.form.updateTimeMin = ''
          this.form.updateTimeMax = ''
        }
      },
      //审核通过时间清空
      ApprovalTimeChange(val) {
        if (!val) {
          this.form.auditPassTimeMin = ''
          this.form.auditPassTimeMax = ''
        }
      },
      //产品运营，创建人选择
      selectUserClick(str) {
        this.isUserChoose = str
        if (str === 'belongerName') {
          this.$refs['UserChoose'].showAddEdit(
            'listSearch',
            this.belongerNameId
          )
        } else {
          this.$refs['UserChoose'].showAddEdit('listSearch', this.createNameId)
        }
      },
      //产品运营，创建人返回
      chooseUser(checkedUser) {
        if (this.isUserChoose === 'belongerName') {
          this.belongerNameChange()
        } else {
          this.createNameChange()
        }
        if (!checkedUser.length) {
          return
        }
        let userName = []
        checkedUser.forEach((item) => {
          userName.push(this.settings === 'en' ? item.englishName : item.name)

          if (this.isUserChoose === 'belongerName') {
            this.belongerName = userName.join(',')
            this.belongerNameId.push(item.id)
          } else if (this.isUserChoose === 'createName') {
            this.createNameId.push(item.id)
            this.createName = userName.join(',')
          }
        })
      },
      // 删除产品运营
      belongerNameChange() {
        this.belongerNameId = []
        this.belongerName = ''
        this.form.belongerId = ''
      },
      // 删除创建人
      createNameChange() {
        this.createNameId = []
        this.createName = ''
        this.form.creatorId = ''
      },
      // 重置取消
      resetClick() {
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
        this.createDaterange = ''
        this.editDaterange = ''
        this.ApprovalTime = ''
        this.belongerName = ''
        this.belongerNameId = []
        this.createName = ''
        this.createNameId = []
        this.supplierName = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search_form {
    height: 90%;
    padding: 0 20px;
    overflow-y: auto;
    .operate {
      position: fixed;
      width: 100%;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #ededed;
      padding: 20px;
    }
  }
  .search_form .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  ::v-deep {
    .el-cascader {
      width: 100%;
    }
    .el-date-editor {
      width: 100%;
    }
    .el-divider {
      margin: 70px 0 28px 0 !important;
    }
    .el-radio {
      margin-bottom: 5px;
    }
  }
</style>
