export default function handleOtherFee(informationVOS) {
  informationVOS = informationVOS || []
  const otherFeeProps = []
  const addPriceProps = []
  let expenseNameList = informationVOS.find(item => item.expenseNameList && item.expenseNameList.length > 0)
  // 报价单新增取specsPriceJson
  let addPricePOList = informationVOS.find(item => (item.addPricePOList && item.addPricePOList.length > 0) || item.specsPriceJson)
  if (expenseNameList) {
    informationVOS.forEach(item => {
      item.expenseNameList && item.expenseNameList.forEach(c => {
        item[c.label] = c.amount
        if (!otherFeeProps.includes(c.label)) {
          otherFeeProps.push(c.label)
        }
      })
    })
  }
  if (addPricePOList) {
    informationVOS.forEach(item => {
      const _arr = item.addPricePOList || JSON.parse(item.specsPriceJson)
      _arr.length && _arr.forEach(c => {
        item[c.specsValue] = c.specsPrice * item.num
        if (!addPriceProps.includes(c.specsValue) && c.specsPrice) {
          addPriceProps.push(c.specsValue)
        }
      })
    })
  }
  return {
    otherFeeProps,
    informationVOS,
    addPriceProps
  }

}

export function getproductStyle(item) {
  let style = {}
  return new Promise(async (res, rej) => {
    const {
      width,
      height,
    } = await getImgSize(item.productUrl)
    if (width == height) {
      style = {
        height: "100%",
        width: "100",
      }
    }
    if (width == height) {
      style = {
        height: "100%",
        width: "100",
      }
    }
    if (width > height) {
      style = {
        height: "auto",
        width: "100%",
      }
    }
    if (width < height) {
      style = {
        height: "100%",
        width: "auto",
      }
    }
    res(style)
  })


}


function getImgSize(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      resolve({})
    }
    let imgObj = new Image()
    imgObj.src = url
    imgObj.onload = () => {
      resolve({
        width: imgObj.width,
        height: imgObj.height
      })
    }
  })
}
