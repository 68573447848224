<template>
  <el-dialog
    :title="title"
    :append-to-body="true"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" label-width="130px">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="印刷条件（CN）"
            prop="nameCn"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="form.nameCn" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="印刷条件（EN）"
            prop="nameEn"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                validator: (rule, value, callback) => {
                  if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
                    callback('不能输入中文字符')
                  } else {
                    callback()
                  }
                },
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="form.nameEn" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="max-height: 400px; overflow-y: auto">
        <el-table :data="form.tableData" style="width: 100%" border>
          <el-table-column align="center">
            <template slot="header">
              <i style="color: #ff4d4f">*</i>
              计价方式（CN）
            </template>
            <template slot-scope="scope">
              <el-form-item
                style="margin: 20px 10px"
                :prop="'tableData.' + scope.$index + '.value'"
                label-width="0"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="scope.row.value"
                  @change="pricingMethodChange($event, scope.row, 1)"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <i style="color: #ff4d4f">*</i>
              计价方式（EN）
            </template>
            <template slot-scope="scope">
              <el-form-item
                style="margin: 20px 10px"
                :prop="'tableData.' + scope.$index + '.valueEn'"
                label-width="0"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
                        callback('不能输入中文字符')
                      } else {
                        callback()
                      }
                    },
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="scope.row.valueEn"
                  @change="pricingMethodChange($event, scope.row, 2)"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="120"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-tooltip content="删除" placement="top">
                <el-button
                  type="danger"
                  plain
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  @click="handleClose(scope.$index)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-col class="add-btn">
          <el-button type="text" @click="addClick">
            <i class="el-icon-plus"></i>
            添加计价方式
          </el-button>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="20">
        <el-col :span="20">
          <el-form-item>
            <template slot="label">
              <i style="color: #ff4d4f">*</i>
              计价方式
            </template>
            <el-input
              v-model="tagValue"
              @keyup.enter.native="addClick"
              placeholder="请输入"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button @click="addClick" class="pub-h-36">新增</el-button>
        </el-col>
      </el-row> -->
    </el-form>
    <!-- <el-row class="mt10" style="margin-left: 90px">
      <el-tag
        v-for="tag in tags"
        :key="tag"
        size="medium"
        closable
        @close="handleClose(tag)"
      >
        {{ tag }}
      </el-tag>
    </el-row> -->

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/product/productSetting'
  export default {
    name: 'PriceWayModel',
    data() {
      return {
        loading: false,
        title: '计价方式设置',
        form: {
          nameCn: '',
          nameEn: '',
          tableData: [],
          productSettingId: '',
          type: '',
        },
        dialogFormVisible: false,
        tagValue: '',
        tags: [],
      }
    },
    created() {},
    methods: {
      //展示弹框
      showAddEdit(row) {
        if (!row) {
          this.form.type = 2
        } else {
          let valueArr = []
          let valueEnArr = []
          let tableData = []
          if (row.value) {
            valueArr = row.value.split(',')
          }
          if (row.valueEn) {
            valueEnArr = row.valueEn.split(',')
          }
          tableData = valueArr.map((item, index) => {
            return {
              value: item,
              valueEn: valueEnArr[index] ? valueEnArr[index] : '',
            }
          })
          this.form = Object.assign({}, row)
          this.form.tableData = tableData
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.tagValue = ''
        this.tags = []
        this.$refs['form'].resetFields()
        this.form = {
          nameCn: '',
          nameEn: '',
          productSettingId: '',
          type: '',
          tableData: [],
        }
        this.dialogFormVisible = false
      },
      // 校验计价方式是否重复
      pricingMethodChange(val, row, num) {
        if (num === 1) {
          // 中文
          row.value = val.trim();
          const nameArr = this.form.tableData.map((item) => item.value)
          const count = nameArr.reduce(
            (count, element) => (element === val ? count + 1 : count),
            0
          )
          if (count > 1) {
            this.$baseMessage(
              '该计价方式中文名称已存在，请勿重复添加！',
              'warning',
              false,
              'erp-hey-message-warning'
            )
            row.value = ''
          }
        } else {
          row.valueEn = val.trim();
          const nameEnArr = this.form.tableData.map((item) => item.valueEn)
          const count = nameEnArr.reduce(
            (count, element) => (element === val ? count + 1 : count),
            0
          )
          if (count > 1) {
            this.$baseMessage(
              '该计价方式英文名称已存在，请勿重复添加！',
              'warning',
              false,
              'erp-hey-message-warning'
            )
            row.valueEn = ''
          }
        }
      },
      // 新增
      addClick() {
        this.form.tableData.push({
          value: '',
          valueEn: '',
        })
      },
      // 标签删除
      handleClose(idx) {
        this.form.tableData.splice(idx, 1)
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.tableData.length === 0) {
              return this.$baseMessage(
                '计价方式值不能为空',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            } else {
              let params = JSON.parse(JSON.stringify(this.form))
              const value = params.tableData.map((item) => item.value).join(',')
              const valueEn = params.tableData
                .map((item) => item.valueEn)
                .join(',')
              params.value = value
              params.valueEn = valueEn
              let response
              this.loading = true
              if (this.form.productSettingId === '') {
                response = await insertPO(params)
                this.loading = false

                if (response.code === '000000') {
                  this.$baseMessage(
                    '新增成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                  this.$emit('price-way', this.form.type)
                  this.close()
                }
              } else {
                response = await updatePO(params)
                this.loading = false

                if (response.code === '000000') {
                  this.$baseMessage(
                    '編輯成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                  this.$emit('price-way', this.form.type)
                 this.close()
                }
              }
            }
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-tag {
      margin-bottom: 10px;
      margin-left: 10px;
    }
    .add-btn {
      padding: 20px 0;
      text-align: center;
    }
  }
</style>
