<!-- 图片预览 -->
<template>
  <div>
    <div v-if="Images && Images.length">
      <div v-if="Images.length === 1" class="img-wrap">
        <el-image :src="getImgUrl(Images[0])" class="c_pointer" @click="handlePreview(Images[0])" />
      </div>
      <div v-else class="img-wrap">
        <el-badge :value="Images.length" />
        <el-image :src="getImgUrl(Images[0])" class="c_pointer" @click="multiPreview(Images[0])" />
      </div>
    </div>
    <div v-else>--</div>

    <!-- 多图预览 -->
    <el-dialog v-if="Images.length > 1" :title="`图片预览`" height="600px" width="900px" :visible.sync="dialogVisible"
      :before-close="handleClose" append-to-body>
      <div class="info" v-if="info.length">
        <span>产品编码：<em>{{ info[imgIndex-1].skuId || '--' }}</em></span>
        <span>产品名称：<em>{{ info[imgIndex-1].productName || '--' }}</em></span>
        <span>入库批次：<em>{{ info[imgIndex-1].lotNo || '--' }}</em></span>
      </div>
      <div class="img-container">
        <div class="text-left">
          <i class="el-icon-arrow-left" @click="prev"></i>
        </div>
        <div class="C">
          <el-image :src="Images[imgIndex - 1]" class="c_pointer" @click="handlePreview(Images[imgIndex - 1])" />
        </div>
        <div class="text-right">
          <i class="el-icon-arrow-right" @click="next"></i>
        </div>
      </div>
      <div class="text-right mt10">{{ imgIndex + '/' + Images.length }}</div>
    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />
  </div>
</template>

<script>
  import { InboundSheet } from '@/core'
  import { URL_COMPRESS } from '@/config'
  export default {
    name: 'PreviewImage',
    props: ['id', 'imageType', 'type', 'Images'],
    data() {
      return {
        imgUrl: '', // 图片预览url
        imgIndex: 1, // 图片索引
        info: [], // 图片信息
        showViewer: false, // 图片预览参数
        dialogVisible: false,
      }
    },
    created() { },
    methods: {
      // 缩略图url
      getImgUrl(url) {
        if (url) {
          url = `${url}${URL_COMPRESS}`
        }
        return url
      },
      // 多图预览
      multiPreview() {
        this.imgIndex = 1
        this.dialogVisible = true
        this.getImageInfo()
      },

      // 多图预览关闭
      handleClose() {
        this.dialogVisible = false
      },

      // 获取图片信息
      async getImageInfo() {
        if (this.Images.length > 1) {
          // 入库单
          if (this.type === 'inbound') {
            const res = await InboundSheet.inStockProductImage(this.id, this.imageType)

            if (res.code === '000000') {
              this.info = res.data
            }
          } else {
            const res = await InboundSheet.outStockProductImage(this.id, this.imageType)

            if (res.code === '000000') {
              this.info = res.data
            }
          }
        }
      },

      // 上一页
      prev() {
        if (this.imgIndex === 1) {
          this.imgIndex = this.Images.length
        } else {
          this.imgIndex = this.imgIndex - 1
        }
      },

      // 下一页
      next() {
        if (this.imgIndex === this.Images.length) {
          this.imgIndex = 1
        } else {
          this.imgIndex = this.imgIndex + 1
        }
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      // 取消预览
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .img-wrap {
    position: relative;
    padding: 5px 0;
    width: 50px;
    margin: 0 auto;

    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }

    .el-badge {
      position: absolute;
      top: 5px;
      right: -10px;
      z-index: 2;
    }
  }

  .info {
    text-indent: 10px;
    margin-bottom: 10px;

    span {
      margin-right: 10px;
      color: #999;

      em {
        color: #333;
      }
    }
  }

  .img-container {
    display: flex;
    height: 600px;

    i {
      font-size: 50px;
      cursor: pointer;
      width: 50px;
      line-height: 600px;
    }

    i:hover {
      color: #409eff;
    }

    .C {
      width: 100%;

      ::v-deep {
        .el-image {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  ::v-deep {
    .el-dialog__title {
      text-align: left;
      display: block;
    }

    .el-dialog__body {
      padding: 10px;
    }
  }
</style>