<template>
  <div class="pro-container">
    <div class="pro-header flex-row justify-between">
      <el-page-header
        @back="goBack"
        :content="$t(pageTitle)"
        :title="$t('other.back')"
      ></el-page-header>
      <LinkMaintenanceGuidelines/>
    </div>
    <el-row class="pro-content mt30">
      <el-col :span="2">
        <h6 class="left-h6">
          {{ $t('productDetail.PlatformProductInformation') }}
        </h6>
      </el-col>
      <el-col :span="22" class="right-content">
        <el-form
          ref="form"
          :key="formKey"
          :model="platformData"
          class="el_form"
          :rules="platformRules"
        >
          <!-- 上架平台 -->
          <el-row>
            <el-col>
              <el-form-item
                prop="platformValue"
                label-width="190px"
                :label="$t('productDetail.AfterPassingTheReview')"
              >
                <div slot="label" style="text-align: left">
                  <span>{{ $t('productDetail.AfterPassingTheReview') }}</span>
                </div>
                <el-checkbox-group v-model="platformData.platformValue">
                  <el-checkbox
                    v-for="item in checkoutPlatforms"
                    :key="item.value"
                    :label="item.value"
                    :disabled="item.disabled"
                  >
                    {{ lang === 'zh' ? item.label : item.labelEn }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 平台价格 -->
          <el-row>
            <el-col :span="6">
              <el-form-item
                prop="plantPrintWay"
                :label="$t('productDetail.PlatformPrice')"
                :key="plantPrintWayKey"
                :rules="[
                  {
                    required: platformData.platformValue.includes('SAGE')
                      ? true
                      : false,
                    message: $t('placeholder.plsSel'),
                    trigger: 'change',
                  },
                ]"
              >
                <el-cascader
                  class="cascader-demo"
                  v-model="platformData.plantPrintWay"
                  :options="printOptions"
                  :props="{
                    label: lang === 'zh' ? 'label' : 'labelEn',
                    value: 'id',
                  }"
                  :append-to-body="false"
                  @change="plantPrintWayChange"
                  placeholder=""
                  :disabled="!platformData.platformValue.includes('SAGE')"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <div slot="label">
                  <span class="ml5">
                    {{ $t('productDetail.PlatformDisplayFreeShipping') }}
                  </span>
                </div>
                <el-switch
                  v-model="platformData.freeFreightForPlatform"
                  :active-value="1"
                  :inactive-value="0"
                  @change="freeFreightForPlatformChange"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 平台信息 -->
          <div
            class="platform-info"
            v-show="platformData.platformValue.includes('SAGE')"
          >
            <div class="platform-info-tab">
              <!-- <div class="platform-info-tab-item active">SAGE</div> -->
            </div>
            <div class="platform-panel">
              <!-- 基础信息 -->
              <div
                class="basic-information bor"
                v-if="platformData.plantPrintWay.length"
              >
                <h5 class="platform-panel-title">
                  {{ $t('productDetail.basicInformation') }}
                </h5>
                <el-row class="basic-information-cont">
                  <el-col :span="8">
                    <el-form-item
                      prop="cat1Name"
                      label="Category 1"
                      label-width="140px"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsSel'),
                          trigger: 'change',
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (value && value === platformData.cat2Name) {
                              callback($t('productDetail.categoryDuplication'))
                            } else {
                              callback()
                            }
                          },
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        popper-class="category-select"
                        v-model="platformData.cat1Name"
                        v-loadmore:el-select-dropdown__list="category1LoadMore"
                        filterable
                        remote
                        ref="category1Ref"
                        placeholder=""
                        :remote-method="categoryRemoteMethod"
                        :loading="category1PageInfo.loading"
                        clearable
                        @focus="() => categoryRemoteMethod('')"
                        @visible-change="onBlur1"
                        @change="(val) => categoryChange(val, 1)"
                      >
                        <el-option
                          v-for="item in categoryOptions"
                          :key="item.id"
                          :label="item.categoryName"
                          :value="item.categoryName"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" class="categor-demo">
                    <el-form-item
                      prop="cat2Name"
                      label="Category 2"
                      label-width="140px"
                      :rules="[
                        {
                          validator: (rule, value, callback) => {
                            if (value && value === platformData.cat1Name) {
                              callback($t('productDetail.categoryDuplication'))
                            } else {
                              callback()
                            }
                          },
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        popper-class="category-select"
                        v-model="platformData.cat2Name"
                        v-loadmore:el-select-dropdown__list="category1LoadMore"
                        filterable
                        remote
                        ref="category2Ref"
                        placeholder=""
                        :remote-method="categoryRemoteMethod"
                        :loading="category1PageInfo.loading"
                        clearable
                        @focus="() => categoryRemoteMethod('')"
                        @visible-change="onBlur2"
                        @change="(val) => categoryChange(val, 2)"
                      >
                        <el-option
                          v-for="item in categoryOptions"
                          :key="item.id"
                          :label="item.categoryName"
                          :value="item.categoryName"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      prop="themes"
                      label="Themes"
                      label-width="140px"
                      class="themes-demo"
                      :rules="[
                        {
                          validator: (rule, value, callback) => {
                            if (value && value.length > 5) {
                              callback($t('placeholder.plsThemes'))
                            } else {
                              callback()
                            }
                          },
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="platformData.themes"
                        multiple
                        filterable
                        placeholder=""
                      >
                        <el-option
                          v-for="option in themesOptions"
                          :key="option.id"
                          :label="option.themeName"
                          :value="option.themeName"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      prop="madeIn"
                      label="Made In"
                      label-width="140px"
                    >
                      <el-select v-model="platformData.madeIn">
                        <el-option
                          v-for="item in inOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      prop="assembledIn"
                      label="Assembled In"
                      label-width="140px"
                    >
                      <el-select v-model="platformData.assembledIn">
                        <el-option
                          v-for="item in inOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      prop="decoratedIn"
                      label="Decorated In"
                      label-width="140px"
                    >
                      <el-select v-model="platformData.decoratedIn">
                        <el-option
                          v-for="item in inOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      prop="shipPoint"
                      label="Ship Point"
                      label-width="140px"
                    >
                      <el-select v-model="platformData.shipPoint">
                        <el-option
                          v-for="item in inOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" class="working-days">
                    <el-form-item
                      prop="rushServieceLo"
                      label="Rush Serviece"
                      label-width="140px"
                      :rules="[
                        {
                          validator: (rule, value, callback) => {
                            if (
                              platformData.rushServieceHi &&
                              Number(platformData.rushServieceHi) <
                                Number(value)
                            ) {
                              callback('No greater than right')
                            } else {
                              callback()
                            }
                          },
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        @input="numInteger2Digits('rushServieceLo')"
                        v-model="platformData.rushServieceLo"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      prop="rushServieceHi"
                      label="to"
                      label-width="30px"
                      :rules="[
                        {
                          validator: (rule, value, callback) => {
                            if (
                              Number(value) <
                              Number(platformData.rushServieceLo)
                            ) {
                              callback('No less than left')
                            } else {
                              callback()
                            }
                          },
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        @input="numInteger2Digits('rushServieceHi')"
                        v-model="platformData.rushServieceHi"
                      ></el-input>
                    </el-form-item>
                    <span class="working-days-text">working days</span>
                  </el-col>
                  <el-col :span="8" class="working-days">
                    <el-form-item
                      prop="prodTimeLo"
                      label="Prod. Time"
                      label-width="140px"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'change',
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (
                              platformData.prodTimeHi &&
                              Number(platformData.prodTimeHi) < Number(value)
                            ) {
                              callback('No greater than right')
                            } else {
                              callback()
                            }
                          },
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        @input="numInteger2Digits('prodTimeLo')"
                        v-model="platformData.prodTimeLo"
                        :disabled="prodTimeLoDisabled"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      prop="prodTimeHi"
                      label="to"
                      label-width="30px"
                      class="max-prodTime"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'change',
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (
                              Number(value) < Number(platformData.prodTimeLo)
                            ) {
                              callback('No less than left')
                            } else {
                              callback()
                            }
                          },
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        @input="numInteger2Digits('prodTimeHi')"
                        v-model="platformData.prodTimeHi"
                        :disabled="prodTimeLoDisabled"
                      ></el-input>
                    </el-form-item>
                    <span class="working-days-text">working days</span>
                  </el-col>
                </el-row>
                <!-- 印刷信息 -->
                <el-row class="print-information-box">
                  <el-col :span="24" class="setup-charge-top-box">
                    <div class="setup-charge-tit">
                      {{ $t('productDetail.printInformation') }}
                    </div>
                    <div class="divider-line"></div>
                  </el-col>
                  <el-row class="print-information-cont">
                    <el-col :span="14">
                      <el-form-item
                        prop="decorationMethodEn"
                        label="Imp Method"
                        label-width="140px"
                      >
                        <el-input
                          v-model="platformData.decorationMethodEn"
                          placeholder=""
                          @input="
                            cannotInputChinese(
                              platformData,
                              'decorationMethodEn',
                              50
                            )
                          "
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" class="refresh-box">
                      <el-button
                        type="text"
                        icon="iconfont icon-dibudaohanglan-"
                        @click="refreshPrintingSFn"
                      ></el-button>
                    </el-col>
                  </el-row>
                  <el-row
                    class="print-information-cont"
                    v-for="(
                      print, index
                    ) in platformData.platformProductPrintingS"
                    :key="index"
                  >
                    <el-col :span="8">
                      <el-form-item
                        :prop="`platformProductPrintingS.${index}.locationEn`"
                        :label="
                          index > 0
                            ? index + 1 + `nd Imp Location`
                            : `Imp Location`
                        "
                        label-width="140px"
                      >
                        <el-input
                          v-model="print.locationEn"
                          placeholder=""
                          style="max-width: 196px"
                          @input="
                            cannotInputChinese(
                              platformData.platformProductPrintingS[index],
                              'locationEn',
                              100
                            )
                          "
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="16">
                      <el-row :gutter="10">
                        <el-col :span="8">
                          <el-form-item
                            :prop="`platformProductPrintingS.${index}.printingWidth`"
                            :label="
                              index > 0 ? index + 1 + `nd Imp Area` : `Imp Area`
                            "
                            label-width="140px"
                          >
                            <el-input
                              v-model="print.printingWidth"
                              placeholder=""
                              @input="
                                validateNumber(
                                  platformData.platformProductPrintingS[index],
                                  'printingWidth'
                                )
                              "
                            ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="3">
                          <el-form-item
                            :prop="`platformProductPrintingS.${index}.printingWidthUnit`"
                            label-width="0"
                          >
                            <el-select
                              v-model="print.printingWidthUnit"
                              placeholder=""
                            >
                              <el-option
                                v-for="item in impAreaOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="5">
                          <el-form-item
                            :prop="`platformProductPrintingS.${index}.printingHeight`"
                            label="W ×"
                            label-width="40px"
                          >
                            <el-input
                              v-model="print.printingHeight"
                              @input="
                                validateNumber(
                                  platformData.platformProductPrintingS[index],
                                  'printingHeight'
                                )
                              "
                              placeholder=""
                            ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="3">
                          <el-form-item
                            :prop="`platformProductPrintingS.${index}.printingHeightUnit`"
                            label-width="0"
                          >
                            <el-select
                              v-model="print.printingHeightUnit"
                              placeholder=""
                            >
                              <el-option
                                v-for="item in impAreaOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="2" style="line-height: 32px">H</el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
              </div>
              <!-- 价格信息 -->
              <div
                class="basic-information bor"
                v-if="platformData.plantPrintWay.length"
              >
                <h5 class="platform-panel-title">
                  {{ $t('productDetail.priceInformation') }}
                </h5>
                <el-row class="price-information-cont">
                  <el-col :span="24" class="quantity-info-box">
                    <span class="quantity-info-label">Quantity</span>
                    <div class="quantity-info-cont">
                      <el-checkbox-group
                        v-model="checkoutedQuantity"
                        :min="1"
                        :max="6"
                      >
                        <el-checkbox
                          :label="item"
                          v-for="item in numberStepList"
                          :key="item"
                        ></el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </el-col>
                  <el-col :span="24" class="quantity-info-box">
                    <span class="quantity-info-label catalog-price-lable">
                      Catalog Price
                    </span>
                    <div class="quantity-info-cont catalog-price-box">
                      <template
                        v-for="(
                          item, index
                        ) in platformData.productPlatformCatalogPriceS"
                      >
                        <el-form-item
                          :prop="`productPlatformCatalogPriceS.${index}.catalogPrice`"
                          label-width="0"
                          :key="index"
                          :rules="[
                            {
                              validator: (rule, value, callback) => {
                                if (
                                  index > 0 &&
                                  Number(value) >
                                    platformData.productPlatformCatalogPriceS[
                                      index - 1
                                    ].catalogPrice
                                ) {
                                  callback('No greater than left')
                                } else {
                                  callback()
                                }
                              },
                              trigger: 'blur',
                            },
                          ]"
                        >
                          <el-input
                            v-model="item.catalogPrice"
                            :key="index"
                            disabled
                          ></el-input>
                        </el-form-item>
                      </template>
                    </div>
                  </el-col>
                  <el-col :span="24" class="quantity-info-box">
                    <span class="quantity-info-label">Pieces Per Unit</span>
                    <div class="quantity-info-cont">
                      <template
                        v-for="(
                          item, index
                        ) in platformData.productPlatformPriceUnitS"
                      >
                        <el-input
                          v-model="item.piecesPerUnit"
                          @input="validateNumber(item, 'piecesPerUnit')"
                          :key="index"
                        ></el-input>
                      </template>
                    </div>
                  </el-col>
                  <el-col
                    :span="24"
                    class="quantity-info-box price-includes-box"
                  >
                    <span class="quantity-info-label">Price Includes</span>
                    <div class="quantity-info-cont">
                      <el-autocomplete
                        v-model="priceIncludes.color"
                        :disabled="
                          platformData.noDecoration == '1' ? true : false
                        "
                        popper-class="price-includes-popper"
                        :fetch-suggestions="(queryString, cb)=>{
                          const results =  [
                            {
                              value: 'Blank',
                            },
                            {
                              value: '1 color',
                            },
                            {
                              value: '2 color',
                            },
                            {
                              value: '3 color',
                            },
                            {
                              value: '4 color',
                            },
                            {
                              value: '4 color process',
                            },
                            {
                              value: 'Full color',
                            }
                          ]
                          cb(results)
                        }"
                        @input="cannotInputChinese(priceIncludes, 'color', 30)"
                      ></el-autocomplete>
                      <el-autocomplete
                        v-model="priceIncludes.side"
                        :disabled="
                          platformData.noDecoration == '1' ? true : false
                        "
                        popper-class="price-includes-popper"
                        :fetch-suggestions="(queryString, cb)=>{
                          const results =  [
                            {
                              value: '1 side',
                            },
                            {
                              value: '2 sides',
                            }
                          ]
                          cb(results)
                        }"
                        @input="cannotInputChinese(priceIncludes, 'side', 30)"
                      ></el-autocomplete>
                      <el-autocomplete
                        v-model="priceIncludes.location"
                        :disabled="
                          platformData.noDecoration == '1' ? true : false
                        "
                        popper-class="price-includes-popper"
                        :fetch-suggestions="(queryString, cb)=>{
                          const results =  [
                            {
                              value: '1 location',
                            },
                            {
                              value: '2 locations',
                            },
                            {
                              value: 'Full wrap',
                            },
                            {
                              value: 'Wrap-around',
                            }
                          ]
                          cb(results)
                        }"
                        @input="cannotInputChinese(priceIncludes, 'location', 30)"
                      ></el-autocomplete>
                      <el-checkbox
                        v-model="platformData.noDecoration"
                        :true-label="1"
                        :false-label="0"
                        :disabled="
                          platformData.plantPrintWay[0] === 'Blank'
                            ? true
                            : false
                        "
                      >
                        No decoration included in price.
                      </el-checkbox>
                    </div>
                  </el-col>
                  <el-col :span="24" class="quantity-info-box">
                    <span class="quantity-info-label">Price Code</span>
                    <div class="quantity-info-cont">
                      <el-input v-model="priceCode" disabled></el-input>
                      <el-tooltip
                        class="pricecode-tooltip"
                        effect="dark"
                        placement="bottom-start"
                      >
                        <div slot="content">
                          R：List Price = Net Price ÷ {{ priceCoefficient }};
                          <br />
                          {{ $t('productCenter.priceCodeTips') }}
                        </div>
                        <i class="iconfont icon-wenhao1"></i>
                      </el-tooltip>
                    </div>
                  </el-col>
                  <!-- Options -->
                  <el-col :span="24" class="options-box">
                    <div class="add-options-box">
                      <div class="add-options-tit">Options</div>
                      <el-button
                        class="add-options-btn"
                        type="primary"
                        @click="addOptionsFn"
                        :disabled="
                          platformData.productPlatformPriceOptionS.length < 3
                            ? false
                            : true
                        "
                      >
                        {{ $t('productCenter.addOption') }}
                      </el-button>

                      <el-dropdown
                        v-if="
                          (!hasImprint && hasImprintData) ||
                          (!hasSpecification && hasSpecificationData)
                        "
                      >
                        <el-button
                          type="primary"
                          plain
                          icon="el-icon-more"
                          size="mini"
                          circle
                          style="margin: 0 7px"
                        ></el-button>
                        <el-dropdown-menu slot="dropdown" placement="bottom">
                          <el-dropdown-item style="width: 160px">
                            <p
                              v-if="!hasImprint && hasImprintData"
                              @click="addOptionsItem(1)"
                              style="color: #409eff"
                            >
                              Add Imprint
                            </p>
                          </el-dropdown-item>
                          <el-dropdown-item style="width: 160px">
                            <p
                              v-if="!hasSpecification && hasSpecificationData"
                              @click="addOptionsItem(2)"
                              style="color: #409eff"
                            >
                              Add Specifications
                            </p>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <div class="divider-line"></div>
                    </div>
                    <!-- Options Item -->
                    <div
                      class="options-item"
                      v-for="(
                        option, index
                      ) in platformData.productPlatformPriceOptionS"
                      :key="index"
                    >
                      <div class="options-item-top">
                        <el-form-item
                          :prop="`productPlatformPriceOptionS.${index}.optionName`"
                          label="Name"
                          label-width="60px"
                          :rules="
                            option.optionSource != 1
                              ? [
                                  {
                                    required: true,
                                    message: $t('placeholder.plsInput'),
                                    trigger: 'blur',
                                  },
                                  {
                                    validator: (rule, value, callback) => {
                                      let optionNames = []
                                      for (let k in platformData.productPlatformPriceOptionS) {
                                        if (k != index) {
                                          optionNames.push(
                                            platformData
                                              .productPlatformPriceOptionS[k][
                                              'optionName'
                                            ]
                                          )
                                        }
                                      }
                                      if (optionNames.includes(value)) {
                                        callback(
                                          $t('productDetail.repeatedOptions')
                                        )
                                      } else {
                                        callback()
                                      }
                                    },
                                    trigger: 'blur',
                                  },
                                ]
                              : [
                                  {
                                    required: true,
                                    message: $t('placeholder.plsInput'),
                                    trigger: 'blur',
                                  },
                                ]
                          "
                        >
                          <el-input
                            clearable
                            v-model="option.optionName"
                            :disabled="option.optionSource == 1 ? true : false"
                            @input="
                              cannotInputChinese(option, 'optionName', 100)
                            "
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="options-item-cont">
                        <div class="delete-box">
                          <i
                            class="iconfont icon-shanchu1"
                            @click="
                              deleteOptionFn(
                                index,
                                option.optionName,
                                option.optionSource
                              )
                            "
                          ></i>
                        </div>
                        <div class="ptions-item-table">
                          <el-table
                            :data="option.productPlatformPriceColS"
                            style="max-width: 940px; overflow-x: auto"
                            border
                          >
                            <el-table-column align="center" width="220">
                              <template slot="header">
                                <span style="color: #ff4d4f">*</span>
                                Values
                              </template>
                              <template slot-scope="scope">
                                <el-form-item
                                  :prop="`productPlatformPriceOptionS.${index}.productPlatformPriceColS.${scope.$index}.values`"
                                  label-width="0"
                                  :rules="[
                                    {
                                      required: true,
                                      message: $t('placeholder.plsInput'),
                                      trigger: 'blur',
                                    },
                                    {
                                      validator: (rule, value, callback) => {
                                        let optionNames = []
                                        for (let k in platformData
                                          .productPlatformPriceOptionS[index]
                                          .productPlatformPriceColS) {
                                          if (k != scope.$index) {
                                            optionNames.push(
                                              platformData
                                                .productPlatformPriceOptionS[
                                                index
                                              ].productPlatformPriceColS[k][
                                                'values'
                                              ]
                                            )
                                          }
                                        }
                                        if (optionNames.includes(value)) {
                                          callback(
                                            $t('productDetail.repeatedOptions')
                                          )
                                        } else {
                                          callback()
                                        }
                                      },
                                      trigger: 'blur',
                                    },
                                  ]"
                                >
                                  <el-input
                                    v-model="scope.row.values"
                                    :disabled="!scope.row.defaultFlag"
                                    @input="
                                      cannotInputChinese(
                                        scope.row,
                                        'values',
                                        100
                                      )
                                    "
                                  ></el-input>
                                </el-form-item>
                              </template>
                            </el-table-column>
                            <el-table-column
                              v-for="(column, columnIndex) in checkoutQuantity"
                              :key="columnIndex + 1000"
                              :label="column + ''"
                              align="center"
                              width="100"
                            >
                              <template slot-scope="scope">
                                <el-form-item
                                  class="profit-rate"
                                  :prop="`productPlatformPriceOptionS.${index}.productPlatformPriceColS.${scope.$index}.${column}`"
                                  label-width="0"
                                  :rules="[
                                    {
                                      validator: (rule, value, callback) => {
                                        const hasSubsequentValue =
                                          checkoutQuantity
                                            .slice(columnIndex + 1)
                                            .some((key) => scope.row[key])
                                        if (
                                          scope.row.values &&
                                          hasSubsequentValue &&
                                          !value
                                        ) {
                                          callback('required')
                                        } else {
                                          callback()
                                        }
                                      },
                                      trigger: 'blur',
                                    },
                                  ]"
                                >
                                  <el-input
                                    v-prevent-mousewheel
                                    :disabled="!scope.row.defaultFlag"
                                    v-model="scope.row[column]"
                                    @input="validateNumber(scope.row, column)"
                                  ></el-input>
                                </el-form-item>
                              </template>
                            </el-table-column>
                            <el-table-column
                              :label="$t('productCenter.Operation')"
                              align="center"
                              width="90"
                            >
                              <template slot-scope="scope">
                                <el-button
                                  size="mini"
                                  type="text"
                                  icon="el-icon-delete"
                                  :class="[
                                    'table-delete-btn',
                                    { disable: !scope.row.defaultFlag },
                                  ]"
                                  :disabled="!scope.row.defaultFlag"
                                  @click="deleteRowFn(index, scope.$index)"
                                ></el-button>
                              </template>
                            </el-table-column>
                          </el-table>
                          <el-button type="text" @click="addMoreFn(index)">
                            + {{ $t('productDetail.AddNewRow') }}
                          </el-button>
                        </div>
                        <div class="ptions-item-info">
                          <el-col :span="24" class="quantity-info-box">
                            <span>Price Code</span>
                            <div class="quantity-info-cont">
                              <el-input v-model="priceCode" disabled></el-input>
                              <el-tooltip
                                class="pricecode-tooltip"
                                effect="dark"
                                placement="bottom-start"
                              >
                                <div slot="content">
                                  R：List Price = Net Price ÷
                                  {{ priceCoefficient }};
                                  <br />
                                  {{ $t('productCenter.priceCodeTips') }}
                                </div>
                                <i class="iconfont icon-wenhao1"></i>
                              </el-tooltip>
                            </div>
                          </el-col>
                          <el-col :span="24" class="ptions-item-radio-box">
                            <el-radio
                              v-model="option.pricingIsTotal"
                              :disabled="
                                option.optionSource == 1 ? true : false
                              "
                              :label="0"
                            >
                              Upcharge
                              <el-tooltip
                                class="pricecode-tooltip"
                                effect="dark"
                                placement="bottom-start"
                              >
                                <div slot="content">
                                  {{ $t('productCenter.priceCodeTips3') }}
                                </div>
                                <i class="iconfont icon-wenhao1"></i>
                              </el-tooltip>
                            </el-radio>
                            <el-radio
                              v-model="option.pricingIsTotal"
                              :label="1"
                              :disabled="
                                option.optionSource == 1 ? true : false
                              "
                            >
                              Total Price
                              <el-tooltip
                                class="pricecode-tooltip"
                                effect="dark"
                                placement="bottom-start"
                              >
                                <div slot="content">
                                  {{ $t('productCenter.priceCodeTips4') }}
                                </div>
                                <i class="iconfont icon-wenhao1"></i>
                              </el-tooltip>
                            </el-radio>
                          </el-col>
                        </div>
                      </div>
                      <div
                        class="divider-line-box"
                        v-if="
                          index !=
                          platformData.productPlatformPriceOptionS.length - 1
                        "
                      >
                        <div class="divider-line-left"></div>
                        <div class="divider-line"></div>
                      </div>
                    </div>
                    <!-- Setup Charges -->
                    <div class="setup-charge-box">
                      <el-col :span="24" class="setup-charge-top-box">
                        <div class="setup-charge-tit">Setup Charges</div>
                        <div class="divider-line"></div>
                      </el-col>
                      <el-row class="setup-charge-cont">
                        <el-col :span="8">
                          <el-form-item
                            prop="setupCharge"
                            label-width="120px"
                            label="Setup Charge"
                            :rules="[
                              {
                                required: true,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input
                              v-prevent-mousewheel
                              :disabled="
                                platformData.plantPrintWay[0] === 'Blank'
                                  ? false
                                  : true
                              "
                              @input="
                                validateNumber(platformData, 'setupCharge')
                              "
                              class="setup-charge-cont-input"
                              v-model="platformData.setupCharge"
                            ></el-input>
                            <el-input
                              value="V"
                              disabled
                              class="setup-charge-cont-v"
                            ></el-input>
                            <el-tooltip
                              class="pricecode-tooltip"
                              effect="dark"
                              placement="bottom-start"
                            >
                              <div slot="content">
                                V：List Price = Net Price ÷ 0.8;
                                <br />
                                {{ $t('productCenter.priceCodeTips') }}
                              </div>
                              <i class="iconfont icon-wenhao1"></i>
                            </el-tooltip>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            prop="repeatSetup"
                            label-width="120px"
                            label="Repeat Setup"
                            :rules="[
                              {
                                required: false,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input
                              v-prevent-mousewheel
                              :disabled="
                                platformData.plantPrintWay[0] === 'Blank'
                                  ? false
                                  : true
                              "
                              class="setup-charge-cont-input"
                              @input="
                                validateNumber(platformData, 'repeatSetup')
                              "
                              v-model="platformData.repeatSetup"
                            ></el-input>
                            <el-input
                              value="V"
                              disabled
                              class="setup-charge-cont-v"
                            ></el-input>
                            <el-tooltip
                              class="pricecode-tooltip"
                              effect="dark"
                              placement="bottom-start"
                            >
                              <div slot="content">
                                V：List Price = Net Price ÷ 0.8;
                                <br />
                                {{ $t('productCenter.priceCodeTips') }}
                              </div>
                              <i class="iconfont icon-wenhao1"></i>
                            </el-tooltip>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            prop="colorSetup"
                            label-width="150px"
                            label="Addt’l Color Setup"
                            :rules="[
                              {
                                required: false,
                                message: $t('placeholder.plsInput'),
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input
                              v-prevent-mousewheel
                              class="setup-charge-cont-input"
                              @input="
                                validateNumber(platformData, 'colorSetup')
                              "
                              v-model="platformData.colorSetup"
                            ></el-input>
                            <el-input
                              value="V"
                              disabled
                              class="setup-charge-cont-v"
                            ></el-input>
                            <el-tooltip
                              class="pricecode-tooltip"
                              effect="dark"
                              placement="bottom-start"
                            >
                              <div slot="content">
                                V：List Price = Net Price ÷ 0.8;
                                <br />
                                {{ $t('productCenter.priceCodeTips') }}
                              </div>
                              <i class="iconfont icon-wenhao1"></i>
                            </el-tooltip>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row class="setup-charge-cont">
                        <el-col :span="14" class="run-chgs-box">
                          <el-form-item label-width="120px">
                            <template slot="label">
                              <div style="line-height: 26px">
                                <p>Addt’l Color</p>
                                <p>Run Chg</p>
                              </div>
                            </template>
                          </el-form-item>
                          <template
                            v-for="(
                              item, index
                            ) in platformData.productPlatformColorPriceS"
                          >
                            <el-form-item
                              v-if="index < checkoutQuantity.length"
                              :key="index"
                              :prop="`productPlatformColorPriceS.${index}.colorPrice`"
                              label-width="0"
                              :rules="[
                                {
                                  required: false,
                                  message: $t('placeholder.plsInput'),
                                  trigger: 'blur',
                                },
                                {
                                  validator: (rule, value, callback) => {
                                    if (
                                      index > 0 &&
                                      Number(value) >
                                        platformData.productPlatformColorPriceS[
                                          index - 1
                                        ].colorPrice
                                    ) {
                                      callback('No greater than left')
                                    } else {
                                      callback()
                                    }
                                  },
                                  trigger: 'blur',
                                },
                              ]"
                            >
                              <el-input
                                @input="validateNumber(item, 'colorPrice')"
                                v-model="item.colorPrice"
                              ></el-input>
                            </el-form-item>
                          </template>
                        </el-col>
                        <el-col :span="10" class="quantity-info-box">
                          <span class="quantity-info-label">Price Code</span>
                          <div class="quantity-info-cont">
                            <el-input v-model="priceCodeV" disabled></el-input>
                            <el-tooltip
                              class="pricecode-tooltip"
                              effect="dark"
                              placement="bottom-start"
                            >
                              <div slot="content">
                                V：List Price = Net Price ÷ 0.8;
                                <br />
                                {{ $t('productCenter.priceCodeTips') }}
                              </div>
                              <i class="iconfont icon-wenhao1"></i>
                            </el-tooltip>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- 图片信息 -->
              <div
                class="basic-information bor"
                v-show="platformData.plantPrintWay.length"
              >
                <h5 class="platform-panel-title">
                  {{ $t('productDetail.imageInformation') }}
                </h5>
                <el-row class="image-information-cont">
                  <el-col>
                    <el-form-item
                      prop="syncImgType"
                      :label="$t('productDetail.imageOptions')"
                    >
                      <el-radio-group
                        v-model="platformData.syncImgType"
                        @change="getPlatformProductImgS"
                      >
                        <el-radio :label="1">
                          {{ $t('productDetail.syncPictures') }}
                        </el-radio>
                        <el-radio :label="2">
                          {{ $t('productDetail.syncAllPictures') }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col class="image-information-panel">
                    <div class="image-information-item">
                      <div class="image-information-item-image tit-bg">
                        Image
                      </div>
                      <div class="image-information-item-caption tit-bg">
                        Caption
                      </div>
                      <div class="image-information-item-is-primary tit-bg">
                        Is Primary
                      </div>
                      <div class="image-information-item-has-logo tit-bg">
                        Has Logo
                      </div>
                    </div>
                    <!-- 主图 -->
                    <div
                      class="image-information-panle-box"
                      ref="mageInformationPanleBox"
                      @scroll="handleScroll"
                    >
                      <template
                        v-for="(
                          item, index
                        ) in platformData.platformProductImgS"
                      >
                        <div
                          class="image-information-item"
                          :key="index"
                          v-if="item.imgType != 4"
                        >
                          <el-tooltip
                            class="upload-limit"
                            effect="dark"
                            :content="$t('productDetail.upload1800Tips')"
                            placement="top-start"
                            v-if="item.reg"
                          >
                            <i class="el-icon-info"></i>
                          </el-tooltip>
                          <div class="image-information-item-image">
                            <div class="upload-mask">
                              <span class="upload-item-preview">
                                <i
                                  class="el-icon-zoom-in"
                                  @click="handlePreview(item.imgUrl)"
                                ></i>
                              </span>
                            </div>
                            <el-form-item
                              :prop="`platformProductImgS.${index}.imgUrl`"
                            >
                              <img :src="item.imgUrl + URL_COMPRESS" alt="" />
                            </el-form-item>
                          </div>
                          <div class="image-information-item-caption">
                            <el-form-item
                              :prop="`platformProductImgS.${index}.caption`"
                            >
                              <el-input
                                v-model="item.caption"
                                @input="
                                  cannotInputChinese(item, 'caption', 100)
                                "
                              ></el-input>
                            </el-form-item>
                          </div>
                          <div class="image-information-item-is-primary">
                            <el-form-item
                              :prop="`platformProductImgS.${index}.isPrimary`"
                              v-if="item.imgType == 1"
                            >
                              <el-checkbox
                                disabled
                                v-model="item.isPrimary"
                                :true-label="1"
                                :false-label="0"
                              ></el-checkbox>
                            </el-form-item>
                          </div>
                          <div class="image-information-item-has-logo">
                            <el-form-item
                              :prop="`platformProductImgS.${index}.hasLogo`"
                            >
                              <el-checkbox
                                v-model="item.hasLogo"
                                :true-label="1"
                                :false-label="0"
                              ></el-checkbox>
                            </el-form-item>
                          </div>
                        </div>
                      </template>

                      <!-- 自定义上传 -->
                      <div :class="['drag-box cursor-scroll']">
                        <template
                          v-for="(
                            item, index
                          ) in platformData.platformProductImgS"
                        >
                          <div
                            class="image-information-item sort-panle"
                            :key="index"
                            v-if="item.imgType == 4"
                          >
                            <el-tooltip
                              class="upload-limit"
                              effect="dark"
                              :content="$t('productDetail.upload1800Tips')"
                              placement="top-start"
                              v-if="item.reg"
                            >
                              <i class="el-icon-info"></i>
                            </el-tooltip>
                            <div class="image-information-item-image">
                              <div class="upload-mask">
                                <span class="upload-item-preview">
                                  <i
                                    class="el-icon-zoom-in"
                                    @click="handlePreview(item.imgUrl)"
                                  ></i>
                                </span>
                                <span class="upload-item-repeta">
                                  <i
                                    class="el-icon-upload"
                                    @click="handleUpload(index)"
                                  ></i>
                                </span>
                                <span class="upload-item-delete">
                                  <i
                                    class="el-icon-delete"
                                    @click="handleRemoveImage(index)"
                                  ></i>
                                </span>
                              </div>
                              <el-form-item
                                :prop="`platformProductImgS.${index}.imgUrl`"
                              >
                                <img :src="item.imgUrl + URL_COMPRESS" alt="" />
                              </el-form-item>
                            </div>
                            <div class="image-information-item-caption">
                              <el-form-item
                                :prop="`platformProductImgS.${index}.caption`"
                              >
                                <el-input
                                  v-model="item.caption"
                                  @input="
                                    cannotInputChinese(item, 'caption', 100)
                                  "
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="image-information-item-is-primary">
                              <el-form-item
                                :prop="`platformProductImgS.${index}.isPrimary`"
                                v-if="item.imgType == 1"
                              >
                                <el-checkbox
                                  disabled
                                  v-model="item.isPrimary"
                                  :true-label="1"
                                  :false-label="0"
                                ></el-checkbox>
                              </el-form-item>
                            </div>
                            <div class="image-information-item-has-logo">
                              <el-form-item
                                :prop="`platformProductImgS.${index}.hasLogo`"
                              >
                                <el-checkbox
                                  v-model="item.hasLogo"
                                  :true-label="1"
                                  :false-label="0"
                                ></el-checkbox>
                              </el-form-item>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <!-- 上传按钮 -->
                    <div
                      class="image-information-item"
                      style="box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1)"
                    >
                      <div
                        class="image-information-item-image bor-right bor-left"
                      >
                        <el-upload
                          class="imgage-uploader"
                          action=""
                          :http-request="uploadImgAction"
                          :before-upload="beforeUpload"
                          :multiple="false"
                          :show-file-list="false"
                          :limit="imageUpload.limit"
                          :accept="imageUpload.accept"
                          ref="uploadRef"
                        >
                          <el-button
                            slot="trigger"
                            size="small"
                            type="text"
                            id="uploadBtn"
                          >
                            <i class="el-icon-plus"></i>
                          </el-button>
                        </el-upload>
                      </div>
                      <div
                        class="image-information-item-caption bor-right bor-left"
                      ></div>
                      <div
                        class="image-information-item-is-primary bor-right bor-left"
                      ></div>
                      <div
                        class="image-information-item-has-logo bor-right bor-left"
                      ></div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- 其他信息 -->
              <div
                class="other-information bor"
                v-if="platformData.plantPrintWay.length"
              >
                <h5 class="platform-panel-title">
                  {{ $t('productDetail.otherInformation') }}
                </h5>
                <el-row class="other-information-cont">
                  <el-col class="other-information-input">
                    <el-form-item
                      prop="platformProductOther.compliance "
                      label="Compliance"
                      label-width="160px"
                    >
                      <el-select
                        v-model="platformData.platformProductOther.compliance"
                        multiple
                        filterable
                        :multiple-limit="10"
                        placeholder=""
                      >
                        <el-option
                          v-for="option in complianceOptions"
                          :key="option.complianceName"
                          :label="option.complianceName"
                          :value="option.complianceName"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item
                      prop="platformProductOther.commented"
                      label="Comment "
                      label-width="160px"
                    >
                      <div class="otehrInfo-tags">
                        <el-checkbox
                          label="Free PMS Color Matching."
                          v-model="freeMatching"
                        ></el-checkbox>
                      </div>
                      <div class="otehrInfo-tags">
                        <el-checkbox disabled v-model="freeShippingBol">
                          Free Shipping.
                        </el-checkbox>
                      </div>
                      <div style="display: inline-block">
                        <el-checkbox disabled v-model="noFreeShippingBol">
                          NOTE: Please request shipping estimates before
                          ordering.
                        </el-checkbox>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item
                      prop="platformProductOther.tags"
                      label="Tags"
                      label-width="160px"
                    >
                      <el-checkbox-group
                        v-model="platformData.platformProductOther.tags"
                      >
                        <div
                          :class="['otehrInfo-tags']"
                          v-for="item in tagsCheckout"
                          :key="item.value"
                        >
                          <el-checkbox :label="item.value">
                            {{ item.label }}
                          </el-checkbox>
                        </div>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :span="24"
                    v-if="
                      platformData.platformProductOther.tags.includes('103')
                    "
                  >
                    <el-col :span="8">
                      <el-form-item
                        prop="platformProductOther.catalogYear"
                        label="Catalog year "
                        label-width="160px"
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsSel'),
                            trigger: 'change',
                          },
                        ]"
                      >
                        <el-select
                          placeholder=""
                          v-model="
                            platformData.platformProductOther.catalogYear
                          "
                        >
                          <el-option
                            v-for="item in catalogYearOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        prop="platformProductOther.expirationDate"
                        label="Expiration Date"
                        label-width="160px"
                        :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-date-picker
                          v-model="
                            platformData.platformProductOther.expirationDate
                          "
                          type="date"
                          placeholder=""
                          value-format="yyyy-MM-dd"
                          :picker-options="pickerOptions"
                        ></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <el-row class="pro-footer">
      <!-- 上一步 -->
      <el-button class="ml10" @click="previousFn">
        {{ $t('productDetail.Previous') }}
      </el-button>
      <!-- 保存 -->
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="saveFn('return')"
      >
        {{ $t('productDetail.Save') }}
      </el-button>
      <!-- 确认 -->
      <el-button
        type="primary"
        class="ml10"
        :loading="confirmLoading"
        @click="confirmFn"
      >
        {{ $t('productDetail.Confirm') }}
      </el-button>
    </el-row>
    <!-- 图片预览 -->
    <el-dialog
      :visible.sync="dialogIamgeVisible"
      append-to-body
      width="40%"
      top="50px"
    >
      <img width="100%" height="auto" :src="dialogImageUrl" alt />
    </el-dialog>
    <!-- 审核选择 -->
    <AuditChoose
      ref="AuditChoose"
      @auditData="saveAuditClick2"
      :defaultUser="{
        userId: lastProduct ? lastProduct.belongerId : '',
        userName: lastProduct ? lastProduct.belongerName : '',
        userNameEn: lastProduct ? lastProduct.belongerNameEn : '',
      }"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Sortable from 'sortablejs'
  import _ from 'lodash'
  import { deepCopy, getImageSize } from 'kits'
  import { PlatformProductsInteractor } from '@/core'
  import AuditChoose from '@/components/AuditChoose'
  import { elFormErrorScrollIntoView } from 'kits'
  import { URL_COMPRESS } from '@/config'
  import { mixin } from './helper/mixin'
  import LinkMaintenanceGuidelines from './components/LinkMaintenanceGuidelines.vue'
  export default {
    components: {
      AuditChoose,
      LinkMaintenanceGuidelines
    },
    mixins: [mixin],
    data() {
      return {
        URL_COMPRESS,
        imgBoxScrollLeft: 0,
        requestFlag: 1, //是否是当前也面第一次发起请求 1：是；0：否
        lastProduct: {}, //产品运营信息
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < new Date('2024-01-01').getTime()
          },
        },
        version: null,
        priceCoefficient: 0.6,
        formKey: 1,
        plantPrintWayKey: 1,
        productId: '', //产品id
        cloneProductId: '', //克隆id
        numberStepList: [], //数量阶梯
        blankOptions: [],
        optionsData: [], //options数据
        saveLoading: false,
        confirmLoading: false,
        pageTitle: 'productDetail.AddProduct',
        dialogIamgeVisible: false,
        dialogImageUrl: '',
        checkoutedQuantity: [], //选中的数量阶梯
        oldCheckoutedQuantity: [], //上次选中的数量阶梯
        categoryQuery: '', //分类搜索值
        priceIncludes: {
          color: '',
          side: '',
          location: '',
        }, //price Includes价格包含项
        platformProductPrintingSTemplate: [
          {
            locationCn: '', //印刷面名称
            locationEn: '', //印刷面英文名
            printingHeight: '', //印刷区域宽度
            printingHeightUnit: 1, //印刷区域宽度单位
            printingWidth: '', //印刷区域宽度
            printingWidthUnit: 1, //印刷区域宽度单位
            sort: 1, //排序
          },
          {
            locationCn: '', //印刷面名称
            locationEn: '', //印刷面英文名
            printingHeight: '', //印刷区域宽度
            printingHeightUnit: 1, //印刷区域宽度单位
            printingWidth: '', //印刷区域宽度
            printingWidthUnit: 1, //印刷区域宽度单位
            sort: 2, //排序
          },
        ], //印刷信息模版
        platformData: {
          noDecoration: 0, //No decoration included in price.
          platformValue: [], //平台
          plantPrintWay: [], //平台价格
          printingWaySelectId: '', //平台价格印刷方式id
          printingWaySelectEn: '', //平台价格印刷方式英文
          printingWaySelect: '', //平台价格印刷方式中文
          waySelect: '', //计价方式中文
          waySelectId: '', //平台价格计价方式id
          waySelectEn: '', //平台价格计价方式
          freeFreightForPlatform: 0, //是否免运费
          cat1Id: '', //sage类别id
          cat1Name: '', //sage类别名称
          cat2Id: '', //sage类别id
          cat2Name: '', //sage类别名称
          themes: [], //主题
          madeIn: 'CN', //生产地
          shipPoint: 'CN', //发件地
          decoratedIn: 'CN', //印刷地
          assembledIn: 'CN',
          rushServieceHi: '', //最大运输周期
          rushServieceLo: '', //最小运输周期
          prodTimeLo: '', //最小生产周期
          prodTimeHi: '', //最大生产周期
          decorationMethodEn: '', //印刷方式英文
          platformProductPrintingS: [
            {
              locationCn: '', //印刷面名称
              locationEn: '', //印刷面英文名
              printingHeight: '', //印刷区域宽度
              printingHeightUnit: 1, //印刷区域宽度单位
              printingWidth: '', //印刷区域宽度
              printingWidthUnit: 1, //印刷区域宽度单位
              sort: 1, //排序
            },
            {
              locationCn: '', //印刷面名称
              locationEn: '', //印刷面英文名
              printingHeight: '', //印刷区域宽度
              printingHeightUnit: 1, //印刷区域宽度单位
              printingWidth: '', //印刷区域宽度
              printingWidthUnit: 1, //印刷区域宽度单位
              sort: 2, //排序
            },
          ], //印刷区域
          platformProductOther: {
            compliance: [],
            commented: '',
            tags: [],
            catalogYear: '',
            expirationDate: '',
          }, //其他信息
          syncImgType: 1, //图片来源（1：仅同步规格为 Color 图片；2：同步全部规格图片）
          platformProductImgS: [], //图片信息
          productPlatformPriceQuantityS: [], //数量阶梯
          productPlatformCatalogPriceS: [], //Catalog Price价格阶梯
          productPlatformPriceUnitS: [], //Pieces Per Unit单位件数
          productPlatformPriceIncludesS: [], //Price Includes价格包含项
          setupCharge: '', //Setup Charge
          repeatSetup: '', //Repeat Setup
          colorSetup: '', //Addt’l Color Setup
          productPlatformColorPriceS: [], //Addt’l Color Run Chg
          productPlatformPriceOptionS: [], //optionName 列表
        },
        impAreaOptions: [
          {
            label: 'Inches',
            value: 1,
          },
          {
            label: 'Feet',
            value: 2,
          },
        ],
        tagsCheckout: [
          {
            label: 'Environmentally Friendly',
            value: '101',
          },
          {
            label: 'Recyclable',
            value: '102',
          },
          {
            label: 'New Product',
            value: '103',
          },
          {
            label: 'Food',
            value: '104',
          },
          {
            label: 'Clothing',
            value: '105',
          },
          {
            label: 'Exclusive',
            value: '106',
          },
          {
            label: 'Not Suitable For All Audiences',
            value: '107',
          },
        ],
        categoryOptions: [], //分类下拉框
        themesOptions: [], //主图下拉框
        complianceOptions: [], //compliance下拉框
        inOptions: [
          {
            label: 'CN',
            value: 'CN',
          },
          {
            label: 'US',
            value: 'US',
          },
        ],
        platformRules: {},
        printOptions: [],
        checkoutPlatforms: [
          {
            label: 'RP商城',
            labelEn: 'RP Site',
            value: 'RP',
            disabled: false,
          },
          {
            label: 'RG商城',
            labelEn: 'RG Site',
            value: 'RG',
            disabled: false,
          },
          {
            label: 'SW商城',
            labelEn: 'SW Site',
            value: 'SW',
            disabled: false,
          },
          {
            label: 'SAGE(68786)',
            labelEn: 'SAGE(68786)',
            value: 'SAGE',
            disabled: false,
          },
        ],
        // 图片上传限制
        imageUpload: {
          accept: '.jpg,.jpeg,.png',
          size: 2,
          fileType: ['jpg', 'jpeg', 'png'],
          uploadSize: 1800,
          limit: 99,
        },
        repeatUploadIndex: null,
        category1PageInfo: {
          loading: false,
          pageLe: 30,
          pageNo: 1,
          total: 0,
        },
        catalogYearOptions: [
          '2021',
          '2022',
          '2023',
          '2024',
          '2025',
          '2026',
          '2027',
          '2028',
          '2029',
          '2030',
          '2031',
          '2032',
          '2033',
          '2034',
          '2035',
        ],
        commentedOptionsArr: [
          'Free PMS Color Matching.',
          'Free Shipping.',
          'NOTE: Please request shipping estimates before ordering.',
        ],
        freeMatching: false,
        freeShippingBol: false,
        noFreeShippingBol: true,
        hasImprint: true,
        hasImprintData: {},
        hasSpecification: true,
        hasSpecificationData: {},
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
        userInfo: 'user/userInfo',
        productAddEditParams: 'product/productAddEditParams',
      }),
      // Prod. Time是否可输入
      prodTimeLoDisabled() {
        if (this.platformData.plantPrintWay[0] === 'Blank') {
          return false
        } else {
          return true
        }
      },
      checkoutQuantity() {
        return this.checkoutedQuantity.sort((a, b) => a - b)
      },
      priceCode() {
        return this.checkoutQuantity.reduce((previousValue, currentValue) => {
          previousValue += 'R'
          return previousValue
        }, '')
      },
      priceCodeV() {
        return this.checkoutQuantity.reduce((previousValue, currentValue) => {
          previousValue += 'V'
          return previousValue
        }, '')
      },
      hasMore1() {
        return (
          Math.ceil(
            this.category1PageInfo.total / this.category1PageInfo.pageLe
          ) > this.category1PageInfo.pageNo
        )
      },
    },
    async created() {
      this.productId = this.$route.query.proId
      this.cloneProductId = this.$route.query.proCloneId
      this.getThemeListPage() //获取科目
      this.selectProductPlatformCodeFn() //获取上架平台
      this.getSageComplianceListPage() // Sage平台协议名称列表查询
      await this.getProductPlatformPriceWayFn() //获取平台价格
      this.getDetails()
    },
    methods: {
      // 获取详情
      async getDetails() {
        await this.getPlatformProductWay()
        if (this.platformData.plantPrintWay.length) {
          this.getProductPlatformPrice()
            .then((data) => {
              if (data) {
                this.getDetailsDataFn(data)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },
      // 获取详情内容
      async getDetailsDataFn(data) {
        this.platformData.prePreversionPlatformProductImgS =
          data.prePreversionPlatformProductImgS || []
        // 获取数量阶梯和选中的数量阶梯
        if (data.productPlatformPriceQuantityS) {
          data.productPlatformPriceQuantityS = _.orderBy(
            data.productPlatformPriceQuantityS,
            ['sort'],
            ['asc']
          )

          this.numberStepList = data.productPlatformPriceQuantityS.map(
            (item) => item.quanqtity
          )
          for (let k of data.productPlatformPriceQuantityS) {
            if (k.quanqtityFlag == '1') {
              this.checkoutedQuantity.push(k.quanqtity)
            }
          }
          // platformData.productPlatformColorPriceS
        }

        // 转换options格
        for (let k of data?.productPlatformPriceOptionS) {
          k['productPlatformPriceColS'] = this.transformPriceColS(
            k['productPlatformPriceColS']
          )
        }
        data.productPlatformPriceOptionS = data?.productPlatformPriceOptionS
          ? _.orderBy(data.productPlatformPriceOptionS, ['sort'], ['asc'])
          : []

        this.hasImprint = data.hasImprint
        this.hasSpecification = data.hasSpecification
        this.hasImprintData = data.productPlatformPriceOptionS.find(
          (item) => item.optionSource === 1 && item.optionName === 'Imprint'
        )
        this.hasSpecificationData = data.productPlatformPriceOptionS.find(
          (item) =>
            item.optionSource === 1 && item.optionName === 'Specifications'
        )

        let hasImprintIndex = data.productPlatformPriceOptionS.findIndex(
          (item) => item.optionSource === 1 && item.optionName === 'Imprint'
        )

        if (!this.hasImprint && hasImprintIndex != -1)
          data.productPlatformPriceOptionS.splice(hasImprintIndex, 1)

        let hasSpecificationIndex = data.productPlatformPriceOptionS.findIndex(
          (item) =>
            item.optionSource === 1 && item.optionName === 'Specifications'
        )
        if (!this.hasSpecification && hasSpecificationIndex != -1)
          data.productPlatformPriceOptionS.splice(hasSpecificationIndex, 1)

        //转换productPlatformPriceIncludesS
        if (data.productPlatformPriceIncludesS) {
          data.productPlatformPriceIncludesS = _.orderBy(
            data.productPlatformPriceIncludesS,
            ['sort'],
            ['asc']
          )
          this.priceIncludes = {
            color: data.productPlatformPriceIncludesS[0].priceIncludes,
            side: data.productPlatformPriceIncludesS[1].priceIncludes,
            location: data.productPlatformPriceIncludesS[2].priceIncludes,
          }
        }

        // themes
        data.themes = data.themes ? data.themes.split(', ') : []
        //处理compliance
        if (data.platformProductOther) {
          data.platformProductOther.compliance =
            data.platformProductOther && data.platformProductOther.compliance
              ? data.platformProductOther.compliance.split(', ')
              : []

          data.platformProductOther.tags =
            data.platformProductOther && data.platformProductOther.tags
              ? data.platformProductOther.tags.split(',')
              : []
        } else {
          delete data.platformProductOther
        }
        // 处理Comment
        if (
          data.platformProductOther &&
          data.platformProductOther.commented &&
          data.platformProductOther.commented.includes(
            this.commentedOptionsArr[0]
          )
        ) {
          this.freeMatching = true
        } else {
          this.freeMatching = false
        }

        if (
          !data.platformProductPrintingS ||
          !data.platformProductPrintingS.length
        ) {
          data.platformProductPrintingS = this.platformProductPrintingSTemplate
        } else if (
          data.platformProductPrintingS &&
          data.platformProductPrintingS.length === 1
        ) {
          data.platformProductPrintingS.push({
            locationCn: '', //印刷面名称
            locationEn: '', //印刷面英文名
            printingHeight: '', //印刷区域宽度
            printingHeightUnit: 1, //印刷区域宽度单位
            printingWidth: '', //印刷区域宽度
            printingWidthUnit: 1, //印刷区域宽度单位
            sort: 2, //排序
          })
        }

        //获取配置换货率
        this.priceCoefficient = data.priceCoefficient ?? 0.6
        // 图片信息按类型排序
        data.platformProductImgS = data.platformProductImgS
          ? _.orderBy(data.platformProductImgS, ['imgType'], ['asc'])
          : []
        await Promise.all(
          data.platformProductImgS.map(async (item) => {
            let { width, height } = await getImageSize(item.imgUrl)
            if (
              width < this.imageUpload.uploadSize &&
              height < this.imageUpload.uploadSize
            ) {
              item.reg = true
            }
            return item
          })
        )
        data.cat1Name = data.cat1Name ? data.cat1Name : ''
        for (let k in this.platformData) {
          if (k !== 'freeFreightForPlatform') {
            this.platformData[k] = data[k] ? data[k] : this.platformData[k]
          }
        }

        if (this.platformData.freeFreightForPlatform) {
          this.freeShippingBol = true
          this.noFreeShippingBol = false
        } else {
          this.freeShippingBol = false
          this.noFreeShippingBol = true
        }
      },
      addOptionsItem(num) {
        if (num === 1) {
          let obj = deepCopy(this.hasImprintData)
          this.platformData.productPlatformPriceOptionS.unshift(obj)
          this.hasImprint = true
        } else {
          let obj = deepCopy(this.hasSpecificationData)
          let data = this.platformData?.productPlatformPriceOptionS?.[0] ?? {}
          if (data.optionName === 'Imprint' && data.optionSource === 1) {
            this.platformData.productPlatformPriceOptionS.splice(1, 0, obj)
          } else {
            this.platformData.productPlatformPriceOptionS.unshift(obj)
          }
          this.hasSpecification = true
        }
      },
      // 获取编辑获取保存平台价格下拉
      async getPlatformProductWay() {
        let params = { productId: this.productId }
        let res = await PlatformProductsInteractor.getPlatformProductWayApi(
          params
        )
        if (res?.code === '000000' && res?.data) {
          if (res.data.printingWaySelectId && res.data.waySelectId) {
            this.pageTitle = 'productDetail.EditProduct'
            let obj = this.printOptions.find(
              (item) => item.id === res.data.printingWaySelectId
            )
            let wayObj = obj
              ? obj.children.find((item) => item.id === res.data.waySelectId)
              : false
            if (obj && wayObj) {
              this.platformData.plantPrintWay = [
                res.data.printingWaySelectId,
                res.data.waySelectId,
              ]
              this.platformData.printingWaySelectEn =
                res.data.printingWaySelectEn
              this.platformData.printingWaySelect = res.data.printingWaySelect
              this.platformData.printingWaySelectId =
                res.data.printingWaySelectId
              this.platformData.waySelectEn = res.data.waySelectEn
              this.platformData.waySelect = res.data.waySelect
              this.platformData.waySelectId = res.data.waySelectId
            } else {
              this.platformData.plantPrintWay = []
              this.platformData.printingWaySelectEn = ''
              this.platformData.printingWaySelect = ''
              this.platformData.printingWaySelectId = ''
              this.platformData.waySelectEn = ''
              this.platformData.waySelectId = ''
              this.platformData.waySelect = ''
            }
          } else if (res.data.printingWaySelectId === 'Blank') {
            this.pageTitle = 'productDetail.EditProduct'
            const bol = this.printOptions.some((item) => item.id === 'Blank')
            if (!bol) {
              this.platformData.plantPrintWay = []
              this.platformData.printingWaySelectEn = ''
              this.platformData.printingWaySelect = ''
              this.platformData.printingWaySelectId = ''
              this.platformData.waySelectEn = ''
              this.platformData.waySelect = ''
              this.platformData.waySelectId = ''
              return
            }
            this.platformData.plantPrintWay = [res.data.printingWaySelectId]
            this.platformData.printingWaySelectEn = res.data.printingWaySelectEn
            this.platformData.printingWaySelect = res.data.printingWaySelect
            this.platformData.printingWaySelectId = res.data.printingWaySelectId
            this.platformData.waySelectEn = ''
            this.platformData.waySelect = ''
            this.platformData.waySelectId = ''
          } else {
            if (this.cloneProductId)
              this.pageTitle = 'productDetail.CloneProduct'
            this.platformData.plantPrintWay = []
            this.platformData.printingWaySelectEn = ''
            this.platformData.printingWaySelect = ''
            this.platformData.printingWaySelectId = ''
            this.platformData.waySelectEn = ''
            this.platformData.waySelect = ''
            this.platformData.waySelectId = ''
          }
          this.$nextTick(() => {
            this.initializeSortable()
          })
        } else {
          this.platformData.plantPrintWay = []
        }
      },
      // 根据平台价格获取详情
      async getProductPlatformPrice() {
        return new Promise(async (resolve, reject) => {
          try {
            let params = {
              printingWaySelectEn: this.platformData.printingWaySelectEn,
              printingWaySelect: this.platformData.printingWaySelect,
              printingWaySelectId: this.platformData.printingWaySelectId,
              waySelectEn: this.platformData.waySelectEn,
              waySelect: this.platformData.waySelect,
              waySelectId: this.platformData.waySelectId,
              productId: this.productId,
              requestFlag: this.requestFlag,
            }
            let res =
              await PlatformProductsInteractor.getProductPlatformPriceApi(
                params
              )

            if (res?.code === '000000' && res?.data) {
              resolve(res.data)
              this.requestFlag = 0
            } else {
              resolve(false)
            }
          } catch (error) {
            reject(error)
          }
        })
      },
      // 印刷信息刷新
      async refreshPrintingSFn() {
        let params = { productId: this.productId }
        let res =
          await PlatformProductsInteractor.getPlatformProductPrintingApi(params)
        if (res?.code === '000000') {
          this.platformData.decorationMethodEn =
            res?.data?.decorationMethodEn ?? ''
          if (
            res.data &&
            res.data.platformProductPrintingS &&
            res.data.platformProductPrintingS.length
          ) {
            this.platformData.platformProductPrintingS =
              res.data.platformProductPrintingS
            if (res.data.platformProductPrintingS.length === 1) {
              this.platformData.platformProductPrintingS.push({
                locationCn: '', //印刷面名称
                locationEn: '', //印刷面英文名
                printingHeight: '', //印刷区域宽度
                printingHeightUnit: 1, //印刷区域宽度单位
                printingWidth: '', //印刷区域宽度
                printingWidthUnit: 1, //印刷区域宽度单位
                sort: 2, //排序
              })
            }
          } else {
            this.platformData.platformProductPrintingS =
              this.platformProductPrintingSTemplate
          }
        }
      },
      // 图片信息切换
      async getPlatformProductImgS(val) {
        let params = {
          productId: this.productId,
          syncImgType: val,
        }
        let res = await PlatformProductsInteractor.getPlatformProductImgSApi(
          params
        )
        if (res?.code === '000000') {
          let imgArr = res?.data?.platformProductImgS ?? []
          await Promise.all(
            imgArr.map(async (item) => {
              let { width, height } = await getImageSize(item.imgUrl)
              if (
                width !== this.imageUpload.uploadSize ||
                height !== this.imageUpload.uploadSize
              ) {
                item.reg = true
              }
              return item
            })
          )
          let otherImgArr = this.platformData.platformProductImgS.filter(
            (item) => item.imgType != 3
          )
          let newImgArr = [...otherImgArr, ...imgArr]
          newImgArr = _.orderBy(newImgArr, ['imgType'], ['asc'])
          this.$set(this.platformData, 'platformProductImgS', newImgArr)
        }
      },
      // 获取平台下拉数据
      async getProductPlatformPriceWayFn() {
        let parms = { productId: this.productId }
        let res =
          await PlatformProductsInteractor.getProductPlatformPriceWayApi(parms)
        if (res?.code === '000000' && res?.data) {
          this.printOptions = res.data
        } else {
          this.printOptions = []
        }
      },
      // 审核通过后自动上架到平台勾选
      async selectProductPlatformCodeFn() {
        let parms = {
          productId: this.productId,
          cloneProductId: this.cloneProductId,
        }
        let res = await PlatformProductsInteractor.selectProductPlatformCodeApi(
          parms
        )
        if (res?.code === '000000' && res?.data) {
          if (res.data?.productPlatformCodeVOS?.length) {
            this.platformData.platformValue =
              res.data.productPlatformCodeVOS.map((item) => item.platformCode)
            let disabledArr = []
            for (let k of res.data.productPlatformCodeVOS) {
              if (!k.type) disabledArr.push(k.platformCode)
            }
            if (disabledArr.length) {
              for (let k of this.checkoutPlatforms) {
                if (disabledArr.includes(k['value'])) {
                  k.disabled = true
                }
              }
            }
          }
          // 是克隆产品其他信息取克隆产品的
          if (this.cloneProductId && res?.data?.platformProductOtherVO) {
            for (let k in res.data.platformProductOtherVO) {
              if (k === 'compliance' || k === 'tags') {
                this.platformData.platformProductOther[k] = res.data
                  .platformProductOtherVO[k]
                  ? res.data.platformProductOtherVO[k].split(',')
                  : this.platformData.platformProductOther[k]
              } else {
                this.platformData.platformProductOther[k] = res.data
                  .platformProductOtherVO[k]
                  ? res.data.platformProductOtherVO[k]
                  : this.platformData.platformProductOther[k]
              }
            }
            if (
              this.platformData.platformProductOther.commented.includes(
                this.commentedOptionsArr[0]
              )
            ) {
              this.freeMatching = true
            } else {
              this.freeMatching = false
            }
          }
          this.platformData.freeFreightForPlatform =
            res?.data?.freeFreightForPlatform ?? 0
          if (this.platformData.freeFreightForPlatform) {
            this.freeShippingBol = true
            this.noFreeShippingBol = false
          } else {
            this.freeShippingBol = false
            this.noFreeShippingBol = true
          }

          this.version = res?.data?.version ?? null
          // 获取产品运营信息
          this.lastProduct.belongerId =
            res?.data?.productionLineOperationId ?? ''
          this.lastProduct.belongerName =
            res?.data?.productionLineOperation ?? ''
          this.lastProduct.belongerNameEn =
            res?.data?.productionLineOperationEn ?? ''
        }
      },
      // 平台免运费切换
      freeFreightForPlatformChange(val) {
        this.freeShippingBol = val ? true : false
        this.noFreeShippingBol = !this.freeShippingBol
      },
      // 转换options表格格式
      transformPriceColS(data) {
        if (!data) return []
        // 先按照 colSort 和 rowSort 进行排序
        data.sort((a, b) => {
          if (a.rowSort !== b.rowSort) {
            return a.rowSort - b.rowSort
          } else {
            return a.colSort - b.colSort
          }
        })
        const transformedData = []
        let currentRowKey = null
        let currentOption = null
        // 遍历每个元素
        data.forEach((item) => {
          // 如果当前行名不同于上一个行名，则创建一个新的选项对象
          if (item.rowKey !== currentRowKey) {
            // 如果不是第一个行名，将上一个选项对象添加到结果数组中
            if (currentOption !== null) {
              transformedData.push(currentOption)
            }
            // 创建一个新的选项对象
            currentOption = {
              values: item.rowKey,
              defaultFlag: item.defaultFlag,
            }
            currentRowKey = item.rowKey
          }
          // 将当前列名和值添加到选项对象中
          currentOption[item.colKey] = item?.attributeValue?.toString() ?? ''
        })
        if (currentOption !== null) {
          transformedData.push(currentOption)
        }

        return transformedData
      },
      transformSaveData(transformedData) {
        const flattenedData = []
        // 遍历数据进行转换
        transformedData.forEach((item, index) => {
          const keys = Object.keys(item).filter(
            (key) => key !== 'values' && key !== 'defaultFlag'
          )
          const sortedKeys = keys.sort((a, b) => parseInt(a) - parseInt(b)) // 按照数值大小排序
          const newRowKey = item.values // 获取rowKey为values的值
          const newRowSort = index + 1 // 行号为数组的下标 + 1
          const defaultFlag = item.defaultFlag

          // 添加新的属性并进行转换
          sortedKeys.forEach((key, colSort) => {
            flattenedData.push({
              attributeValue: parseFloat(item[key]),
              colKey: key,
              colSort: colSort + 1, // 列号从1开始
              defaultFlag,
              rowKey: newRowKey,
              rowSort: newRowSort,
            })
          })
        })

        return flattenedData
      },
      // 添加addOptionsFn
      addOptionsFn() {
        if (this.platformData.productPlatformPriceOptionS.length >= 3) {
          this.$baseMessage(
            this.$t('productDetail.addUpOptions'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return
        }
        let obj = {
          values: '',
          defaultFlag: 1,
        }
        for (let k of this.numberStepList) {
          obj[k] = ''
        }
        let optionItem = {
          optionName: '',
          optionSource: 3,
          pricingIsTotal: 0,
          productPlatformPriceColS: [obj],
        }
        this.platformData.productPlatformPriceOptionS.push(optionItem)
      },
      // 删除deleteOptionFn
      deleteOptionFn(index, optionName, optionSource) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('myMail.Confirm'),
          cancelButtonText: this.$t('myMail.Cancel'),
          type: 'warning',
        })
          .then(() => {
            this.platformData.productPlatformPriceOptionS.splice(index, 1)
            if (optionName === 'Imprint' && optionSource === 1)
              this.hasImprint = false
            if (optionName === 'Specifications' && optionSource === 1)
              this.hasSpecification = false
            for (let k in this.platformData.productPlatformPriceOptionS) {
              this.$refs.form.validateField([
                `productPlatformPriceOptionS.${k}.optionName`,
              ])
            }
          })
          .catch(() => {})
      },
      // 平台价格改变
      plantPrintWayChange(val) {
        this.$nextTick(() => {
          this.initializeSortable()
        })
        let bol = deepCopy(this.platformData.printingWaySelectId)
        if (val.length > 1) {
          let obj = this.printOptions.find((item) => item.id === val[0])
          let wayObj = obj.children.find((item) => item.id === val[1])
          this.platformData.printingWaySelectId = val[0]
          this.platformData.printingWaySelectEn = obj.labelEn
          this.platformData.printingWaySelect = obj.label
          this.platformData.waySelectId = val[1]
          this.platformData.waySelectEn = wayObj?.labelEn?.replace(
            /1-location/g,
            ''
          )
          this.platformData.waySelect = wayObj?.label?.replace(/-1位/g, '')
        } else if (val.length === 1 && val[0] === 'Blank') {
          this.platformData.printingWaySelectId = 'Blank'
          this.platformData.printingWaySelectEn = 'Blank'
          this.platformData.printingWaySelect = 'Blank'
          this.platformData.waySelectId = ''
          this.platformData.waySelectEn = ''
          this.platformData.waySelect = ''
          this.platformData.noDecoration = 1
          this.priceIncludes = {
            color: '',
            side: '',
            location: '',
          }
        } else {
          this.platformData.printingWaySelectId = ''
          this.platformData.printingWaySelectEn = ''
          this.platformData.printingWaySelect = ''
          this.platformData.waySelectId = ''
          this.platformData.waySelectEn = ''
          this.platformData.waySelect = ''
        }
        this.getProductPlatformPrice()
          .then((data) => {
            if (!bol) {
              this.getDetailsDataFn(data)
            } else {
              // 修改Prod. Time
              this.platformData.prodTimeLo = data.prodTimeLo
              this.platformData.prodTimeHi = data.prodTimeHi

              // 修改Catalog Price
              this.platformData.productPlatformCatalogPriceS =
                data.productPlatformCatalogPriceS

              // 修改Price Includes
              if (val.length === 1 && val[0] === 'Blank') {
                this.priceIncludes = {
                  color: '',
                  side: '',
                  location: '',
                }
              } else {
                this.priceIncludes.color =
                  data.productPlatformPriceIncludesS &&
                  data.productPlatformPriceIncludesS.find(
                    (item) => item.sort === 1
                  )?.priceIncludes

                this.priceIncludes.side = '1 side'
                this.priceIncludes.location = '1 location'
                this.platformData.noDecoration = 0
              }

              // 修改setupCharge
              this.platformData.setupCharge = data.setupCharge
              this.platformData.repeatSetup = data?.repeatSetup ?? ''
              // 处理import表格
              // 1找到Imprint表格的下标
              for (let k of data?.productPlatformPriceOptionS) {
                k['productPlatformPriceColS'] = this.transformPriceColS(
                  k['productPlatformPriceColS']
                )
              }
              data.productPlatformPriceOptionS =
                data?.productPlatformPriceOptionS
                  ? _.orderBy(
                      data.productPlatformPriceOptionS,
                      ['sort'],
                      ['asc']
                    )
                  : []
              let importIndex =
                this.platformData.productPlatformPriceOptionS.findIndex(
                  (item) =>
                    item.optionName === 'Imprint' && item.optionSource === 1
                )
              let importData = data.productPlatformPriceOptionS.find(
                (item) =>
                  item.optionName === 'Imprint' && item.optionSource === 1
              )
              this.hasImprintData = deepCopy(importData)
              if (importIndex != -1) {
                // 2拿到import表格数据过滤默认的
                let importTableDataArr =
                  this.platformData.productPlatformPriceOptionS[importIndex]
                    .productPlatformPriceColS
                importTableDataArr = importTableDataArr.filter(
                  (item) => item.defaultFlag == 1
                )
                // 3处理新获取的import表格数据

                // 4找到新获取的options数据Imprint表格的下标
                let newImportIndex = data.productPlatformPriceOptionS.findIndex(
                  (item) => item.optionName === 'Imprint' && item.optionSource
                )
                //5拿到新import表格数据过滤手动添加的
                let newImportTableDataArr =
                  data.productPlatformPriceOptionS[newImportIndex]
                    .productPlatformPriceColS
                newImportTableDataArr = newImportTableDataArr.filter(
                  (item) => item.defaultFlag != 1
                )
                let newProductPlatformPriceColS = [
                  ...newImportTableDataArr,
                  ...importTableDataArr,
                ]

                this.platformData.productPlatformPriceOptionS[
                  importIndex
                ].productPlatformPriceColS = newProductPlatformPriceColS
              } else {
                if (importData) {
                  this.platformData.productPlatformPriceOptionS.unshift(
                    deepCopy(importData)
                  )
                  this.hasImprint = true
                }
              }

              let specificationsIndex =
                this.platformData.productPlatformPriceOptionS.findIndex(
                  (item) =>
                    item.optionName === 'Specifications' && item.optionSource
                )
              let specificationsData = data.productPlatformPriceOptionS.find(
                (item) =>
                  item.optionName === 'Specifications' &&
                  item.optionSource === 1
              )
              this.hasSpecificationData = deepCopy(specificationsData)
              if (specificationsIndex == -1) {
                if (specificationsData) {
                  if (
                    this.platformData.productPlatformPriceOptionS?.[0]
                      ?.optionName === 'Imprint' &&
                    this.platformData.productPlatformPriceOptionS?.[0]
                      ?.optionSource === 1
                  ) {
                    this.platformData.productPlatformPriceOptionS.splice(
                      1,
                      0,
                      deepCopy(specificationsData)
                    )
                  } else {
                    this.platformData.productPlatformPriceOptionS.unshift(
                      deepCopy(specificationsData)
                    )
                  }
                  this.hasSpecification = true
                }
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      // imporint表格添加
      addMoreFn(index) {
        let obj = {
          values: '',
          defaultFlag: 1,
        }
        for (let k of this.numberStepList) {
          obj[k] = ''
        }
        this.platformData.productPlatformPriceOptionS[
          index
        ].productPlatformPriceColS.push(obj)
      },
      // 删除某一行
      deleteRowFn(index, rowIndex) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('myMail.Confirm'),
          cancelButtonText: this.$t('myMail.Cancel'),
          type: 'warning',
        })
          .then(() => {
            this.platformData.productPlatformPriceOptionS[
              index
            ].productPlatformPriceColS.splice(rowIndex, 1)

            for (let k in this.platformData.productPlatformPriceOptionS[index]
              .productPlatformPriceColS) {
              this.$refs.form.validateField([
                `productPlatformPriceOptionS.${index}.productPlatformPriceColS.${k}.values`,
              ])
            }
          })
          .catch(() => {})
      },
      // 分类1
      category1LoadMore() {
        if (!this.hasMore1) return
        this.category1PageInfo.pageNo++
        this._getOptions(true)
      },
      onBlur1(val) {
        if (!val) {
          this.$refs.category1Ref.blur()
        }
      },
      categoryRemoteMethod(query) {
        this.categoryQuery = query
        this._getOptions(false)
      },
      _reset() {
        this.category1PageInfo.pageNo = 1
        this.category1PageInfo.total = 0
        this.categoryOptions = []
      },
      async _getOptions(loadMore) {
        this.category1PageInfo.loading = true
        if (!loadMore) {
          this._reset()
        }
        let params = {
          categoryName: this.categoryQuery,
          pageLe: this.category1PageInfo.pageLe,
          pageNo: this.category1PageInfo.pageNo,
        }
        let res = await PlatformProductsInteractor.listPageApi(params)
        if (res?.code === '000000' && res?.data?.data) {
          this.categoryOptions = loadMore
            ? [...this.categoryOptions, ...res.data.data]
            : res.data.data
          this.category1PageInfo.total = res?.data?.total ?? 0
        } else {
          this.categoryOptions = []
          this.category1PageInfo.total = 0
        }
        this.category1PageInfo.loading = false
      },
      // 分类2
      onBlur2(val) {
        if (!val) {
          this.$refs.category2Ref.blur()
        }
      },
      // 选中分类根据id找到名称
      categoryChange(val, num) {
        this.$nextTick(() => {
          this.$refs.form && num === 1
            ? this.$refs.form.clearValidate(['cat2Name'])
            : this.$refs.form.clearValidate(['cat1Name'])
        })
        let obj = this.categoryOptions.filter(
          (item) => item.categoryName === val
        )?.[0]
        if (!obj) return
        this.platformData[num === 1 ? 'cat1Id' : 'cat2Id'] = obj.sageCategoryId
      },
      // 获取科目下拉
      async getThemeListPage() {
        let params = {
          pageLe: 999,
          pageNo: 1,
          themeName: '',
        }
        let res = await PlatformProductsInteractor.themeListPageApi(params)
        if (res?.code === '000000' && res?.data?.data?.length) {
          this.themesOptions = res.data.data
        }
      },
      // Sage平台协议名称列表查询
      async getSageComplianceListPage() {
        let params = {
          pageLe: 999,
          pageNo: 1,
          themeName: '',
        }
        let res = await PlatformProductsInteractor.sageComplianceListPageApi(
          params
        )
        if (res?.code === '000000' && res?.data?.data?.length) {
          this.complianceOptions = res.data.data
        }
      },
      // 整数不能超过2位
      numInteger2Digits(str) {
        this.platformData[str] = this.platformData[str].replace(/[^\d]/g, '')
        if (this.platformData[str].length > 2) {
          this.platformData[str] = this.platformData[str].slice(0, 2)
        }
      },
      // 文本，不能输入中文和中文标点
      cannotInputChinese(data, field, num) {
        data[field] = data[field].replace(/[^\x00-\xff]/g, '').substring(0, num)
      },
      // 数字，最多2位小数，不能超过 999.99
      validateNumber(data, field, maxNum = 999.99) {
        data[field] = data[field].replace(/[^\d.]/g, '') // 过滤非数字和小数点
        data[field] = data[field].replace(/^\./g, '') // 开头不能是小数点
        data[field] = data[field].replace(/\.{2,}/g, '.') // 不能连续输入小数点
        data[field] = data[field]
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.') // 不能多次输入小数点
        data[field] = data[field].replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 限制两位小数
        if (parseFloat(data[field]) > maxNum) {
          // 超过999.99则设置为999.99
          data[field] = maxNum
        }
      },
      // 上一步
      previousFn() {
        this.$confirm(
          `<p style="color:#333;">${this.$t(
            'productDetail.previousTips'
          )}</p><p style="color:#999;">${this.$t(
            'productDetail.previousTips1'
          )}</p>`,
          {
            distinguishCancelAndClose: true,
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('productDetail.SAVE'),
            cancelButtonText: this.$t('productDetail.LEAVE'),
            type: 'warning',
          }
        )
          .then(() => {
            this.saveFn()
          })
          .catch((action) => {
            action === 'cancel' &&
              this.$router.push({
                path: '/product/ProductAddEdit/ProductAddEdit',
                query: {
                  str: 'edit',
                  proId: this.productId,
                },
              })
          })
      },
      // 点击确定
      confirmFn() {
        if (this.platformData.platformValue.includes('SAGE')) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              if (!this.checkoutedQuantity.length) {
                this.$baseMessage(
                  this.$t('productDetail.checkoutedQuantityTips'),
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
                return
              } else if (
                this.platformData.productPlatformPriceOptionS.length > 3
              ) {
                this.$baseMessage(
                  this.$t('productDetail.addUpOptions'),
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
                return
              } else {
                this.reviewFn()
              }
            } else {
              this.$nextTick(() => {
                elFormErrorScrollIntoView('.el-form-item__error')
              })
              console.log('error submit!!')
              return false
            }
          })
        } else {
          this.$refs.form && this.$refs.form.clearValidate()
          this.reviewFn()
        }
      },
      // 保存
      async saveFn(type = '') {
        if (type === 'return') this.saveLoading = true
        let params = this.setSaveData()
        let res = await PlatformProductsInteractor.stagingApi(params)
        this.saveLoading = false
        if (res?.code === '000000') {
          this.$baseMessage(
            this.$t('reqmsg.$9'),
            'success',
            false,
            'erp-hey-message-success'
          )
          if (type === 'return') {
            this.goBack()
          } else {
            this.$router.push({
              path: '/product/ProductAddEdit/ProductAddEdit',
              query: {
                str: 'edit',
                proId: this.productId,
              },
            })
          }
        }
      },
      // 设置保存提交数据
      setSaveData(auditObjectDTO = null) {
        // 把options表格数据处理
        let params = {
          erpProductId: this.productId,
          auditObjectDTO: auditObjectDTO,
          version: this.version,
          draftPlatformCode: this.platformData.platformValue
            ? this.platformData.platformValue.join(',')
            : '',
        }
        let saveData = deepCopy(this.platformData)
        let optionsData = saveData.productPlatformPriceOptionS

        for (let productPlatformPriceColS of optionsData) {
          for (let key of productPlatformPriceColS[
            'productPlatformPriceColS'
          ]) {
            for (let k in key) {
              if (
                !this.checkoutQuantity.includes(k.toString()) &&
                k != 'values' &&
                k != 'defaultFlag'
              )
                delete key[k]
            }
          }
        }
        for (let k of optionsData) {
          k['productPlatformPriceColS'] = this.transformSaveData(
            k['productPlatformPriceColS']
          )
        }
        for (let k in optionsData) {
          optionsData[k].sort = Number(k) + 1
          if (optionsData[k].optionSource == 2) optionsData[k].optionSource = 3
        }
        saveData.productPlatformPriceOptionS = optionsData
        //处理Themes
        let themes = this.platformData?.themes?.join(', ') ?? ''
        saveData.themes = themes
        //处理compliance,tags
        let compliance =
          saveData?.platformProductOther?.compliance?.join(', ') ?? ''
        let tags = saveData?.platformProductOther?.tags?.join(',') ?? ''
        saveData.platformProductOther.compliance = compliance
        saveData.platformProductOther.tags = tags
        //处理 platformProductOther  commented
        let commented = ''
        if (this.freeMatching) commented = this.commentedOptionsArr[0]
        if (this.freeShippingBol) {
          commented = commented + ' ' + this.commentedOptionsArr[1]
        }
        if (this.noFreeShippingBol) {
          commented = commented + ' ' + this.commentedOptionsArr[2]
        }
        saveData.platformProductOther.commented = commented
        let productPlatformPriceIncludesS = []
        for (const [index, [key, value]] of Object.entries(
          this.priceIncludes
        ).entries()) {
          productPlatformPriceIncludesS.push({
            sort: index + 1,
            priceIncludes: this.platformData.noDecoration == '1' ? '' : value,
          })
        }
        saveData.productPlatformPriceIncludesS = productPlatformPriceIncludesS
        // 处理平台价格
        saveData.waySelectCn = saveData.waySelect ?? ''
        saveData.printingWaySelectCn = saveData.printingWaySelect ?? ''

        delete saveData.plantPrintWay

        // 处理上架平台
        delete saveData.platformValue

        // 处理选中数量阶梯
        let productPlatformPriceQuantityS = this.checkoutQuantity.map(
          (item, index) => {
            return {
              quanqtity: item,
              sort: index + 1,
            }
          }
        )
        // 处理Addt’l Color Run Chg
        for (let k in saveData.productPlatformColorPriceS) {
          if (k >= this.checkoutedQuantity.length) {
            saveData.productPlatformColorPriceS[k]['colorPrice'] = ''
          }
        }

        saveData.productPlatformPriceQuantityS = productPlatformPriceQuantityS

        // 过滤Catalog Price和Pieces Per Unit
        let checkoutedIndexArr = this.numberStepList.reduce(
          (previousValue, currentValue, currentIndex) => {
            if (this.checkoutQuantity.includes(currentValue)) {
              previousValue.push(currentIndex + 1)
            }
            return previousValue
          },
          []
        )
        let productPlatformPriceUnitS = []
        for (let k of saveData.productPlatformPriceUnitS) {
          if (checkoutedIndexArr.includes(Number(k.sort))) {
            productPlatformPriceUnitS.push(k)
          }
        }
        productPlatformPriceUnitS = productPlatformPriceUnitS.map(
          (item, index) => {
            return {
              piecesPerUnit: item.piecesPerUnit,
              sort: index + 1,
            }
          }
        )

        saveData.productPlatformPriceUnitS = productPlatformPriceUnitS

        let productPlatformCatalogPriceS = []
        for (let k of saveData.productPlatformCatalogPriceS) {
          if (checkoutedIndexArr.includes(Number(k.sort))) {
            productPlatformCatalogPriceS.push(k)
          }
        }
        productPlatformCatalogPriceS = productPlatformCatalogPriceS.map(
          (item, index) => {
            return {
              catalogPrice: item.catalogPrice,
              sort: index + 1,
            }
          }
        )

        saveData.productPlatformCatalogPriceS = productPlatformCatalogPriceS
        params.freeFreightForPlatform = saveData.freeFreightForPlatform
        params.platformProductSage = this.platformData.platformValue.includes(
          'SAGE'
        )
          ? saveData
          : null
        return params
      },
      // 返回列表
      goBack() {
        this.$router.push('/product/ProductList/ProductList')
      },
      // 上传
      async uploadImgAction({ file }) {
        const [file_name, file_size] = [file.name, file.size]
        let { res } = await this.utils.ossUpload({ file, type: 'productImg' })
        if (
          res.requestUrls.length > 0 &&
          res.requestUrls[0].split('?uploadId')
        ) {
          let url = res.requestUrls[0].split('?uploadId')
          if (this.repeatUploadIndex != null) {
            this.platformData.platformProductImgS[
              this.repeatUploadIndex
            ].imgUrl = url[0]
            this.repeatUploadIndex = null
          } else {
            const uploadData = {
              imgId: '',
              imgUrl: url[0],
              hasLogo: 0,
              caption: '',
              imgType: 4,
              isPrimary: 0,
            }
            this.platformData.platformProductImgS.push(uploadData)
          }
          this.$nextTick(() => {
            if (
              this.$refs.mageInformationPanleBox.scrollWidth >
              this.$refs.mageInformationPanleBox.clientWidth
            ) {
              this.$refs.mageInformationPanleBox.scrollLeft =
                this.$refs.mageInformationPanleBox.scrollWidth + 101
            }
          })
        }
      },
      // 上传之前验证
      beforeUpload(file) {
        let fileTypeValue = file.name
          .substring(file.name.lastIndexOf('.') + 1)
          .toLowerCase()

        // 校验文件类型
        if (!this.imageUpload.fileType.includes(fileTypeValue)) {
          this.$baseMessage(
            this.$t('reqmsg.M2032'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        }

        // 校验文件大小
        const isLt50M = file.size / 1024 / 1024 < this.imageUpload.size
        if (!isLt50M) {
          this.$baseMessage(
            this.$t('reqmsg.M2033'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        }
        // 检查图片尺寸是否为1800x1800
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = (e) => {
            const img = new Image()
            img.src = e.target.result
            img.onload = () => {
              if (
                img.width >= this.imageUpload.uploadSize ||
                img.height >= this.imageUpload.uploadSize
              ) {
                resolve(file) // 图片尺寸符合要求，允许上传
              } else {
                this.$baseMessage(
                  this.$t('productDetail.upload1800Tips'),
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
                reject(new Error('图片尺寸至少一个方向不低于 1800px')) // 图片尺寸不符合要求，拒绝上传
              }
            }
            img.onerror = () => {
              this.$baseMessage(
                '无法加载图片',
                'warning',
                false,
                'erp-hey-message-warning'
              )
              reject(new Error('无法加载图片'))
            }
          }
          reader.readAsDataURL(file)
        })
      },
      // 删除图片
      handleRemoveImage(idx) {
        this.platformData.platformProductImgS.splice(idx, 1)
      },
      // 预览图片
      handlePreview(url) {
        this.dialogIamgeVisible = true
        this.dialogImageUrl = url
      },
      // 重新上传
      handleUpload(idx) {
        this.repeatUploadIndex = idx
        document.getElementById('uploadBtn').click()
      },
      // 拖拽
      initializeSortable() {
        const el = document.querySelector('.drag-box')
        new Sortable(el, {
          handle: '.sort-panle',
          animation: 150,
          onEnd: this.handleSortEnd,
        })
      },
      handleSortEnd(evt) {
        const num = this.platformData.platformProductImgS.filter(
          (item) => item.imgType != 4
        ).length
        const itemToMove = this.platformData.platformProductImgS.splice(
          evt.oldIndex + num,
          1
        )[0]
        this.platformData.platformProductImgS.splice(
          evt.newIndex + num,
          0,
          itemToMove
        )
        this.formKey++
        this.$nextTick(() => {
          this.initializeSortable()
          this.$refs.mageInformationPanleBox.scrollLeft = this.imgBoxScrollLeft
        })
      },
      handleScroll(event) {
        this.imgBoxScrollLeft = event.target.scrollLeft
      },
      // 审核
      reviewFn() {
        const auditSubType = this.getAuditSubType(this.productAddEditParams, 2)
        let params = {
          auditType: 6,
          status: 0,
          tenantId: this.userInfo.tenantId,
          auditSubType, // 1=不需要美工审核 0=需要美工审核
        }
        this.pubApi.getAuditTemplates(params).then((response) => {
          if (!response.data.length) {
            // 请联系管理员配置审核模板
            this.$baseMessage(
              this.$t('reqmsg.M2015'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(params)
          }
        })
      },
      //有审核模板(但也可能没有所属组织负责人)
      async saveAuditClick2(auditData) {
        this.confirmLoading = true
        let params = this.setSaveData(auditData)
        let res = await PlatformProductsInteractor.productConfirmApi(params)
        this.confirmLoading = false
        if (res?.code === '000000') {
          this.$baseMessage(
            this.$t('reqmsg.$65'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.goBack()
        }
      },
    },
  }
</script>
<style scoped lang="scss">
  .pro-container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .pro-footer {
      position: fixed;
      width: 90%;
      bottom: 0;
      text-align: center;
      padding: 20px 0;
      z-index: 999;
      background: #fff;
    }
    .bor {
      border: 1px solid #e4e7ed;
      border-radius: 2px;
      overflow: hidden;
      margin-bottom: 20px;
    }
    .divider-line {
      width: 100%;
      height: 0;
      border-top: 1px dashed #e4e7ed;
    }
    .platform-panel-title {
      font-weight: normal;
      padding-left: 20px;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      border-bottom: 1px solid #e4e7ed;
      background: #f5f7fa;
    }
    .pro-content {
      width: 95%;
      margin: 0 auto;
      margin-bottom: 100px;
      .themes-demo {
        ::v-deep .el-form-item__content {
          .el-select__tags-text {
            display: inline-block;
            max-width: 100%;
            overflow: unset;
            text-overflow: unset;
          }
        }
      }
      .left-h6 {
        display: flex;
        font-size: 16px;
        align-items: center;
        font-family: 'PingFang Bold';
        padding: 0 10px;
        &::before {
          content: '';
          display: block;
          height: 16px;
          width: 2px;
          background: #282c34;
          margin-right: 5px;
        }
      }
      .right-content {
        height: 100%;
        padding: 20px;
        border: none;
        .platform-info {
          display: flex;
        }
        .platform-info-tab {
          width: 60px;
          flex-shrink: 1;
          min-height: 500px;
          // border-right: 1px solid #e4e7ed;
          .platform-info-tab-item {
            height: 40px;
            line-height: 40px;
            border-right: 2px solid transparent;
            margin-right: -1px;
            color: #999;
            &.active {
              color: rgb(24, 144, 255);
              border-right-color: rgb(24, 144, 255);
            }
          }
        }
        .platform-panel {
          width: calc(100% - 60px);
          flex: 1;
          padding-left: 20px;
          padding-top: 10px;
          .basic-information-cont {
            padding: 20px 20px 0 0;
            .working-days {
              display: flex;
              align-items: center;
              ::v-deep.el-input {
                width: 60px;
              }
              .working-days-text {
                margin-bottom: 18px;
                margin-left: 10px;
              }
              .max-prodTime {
                ::v-deep.el-form-item__label {
                  &::before {
                    display: none;
                  }
                }
              }
            }
          }
          .print-information-box {
            .setup-charge-top-box {
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              padding-right: 20px;
              .setup-charge-tit {
                width: 100px;
                text-align: center;
              }
            }
            .print-information-cont {
              padding-right: 20px;
              .refresh-box {
                ::v-deep.el-button {
                  margin: -6px 0 0 10px;
                  i {
                    font-size: 24px;
                  }
                }
              }
            }
          }
          .other-information {
            .other-information-cont {
              padding-top: 18px;
              .other-information-input {
                padding-right: 50px;
                ::v-deep.el-select {
                  width: 100%;
                }
                ::v-deep.el-input {
                  width: 100%;
                }
              }
              .otehrInfo-tags {
                display: inline-block;
                width: 25%;
                &.highlight {
                  ::v-deep.el-checkbox__label {
                    color: rgb(217, 0, 27);
                  }
                }
              }
            }
          }
          .image-information-cont {
            padding: 20px;
            .image-information-panel {
              width: 100%;
              display: flex;
              border-radius: 2px;
              position: relative;
              box-sizing: border-box;
              .image-information-panle-box {
                display: flex;
                max-width: calc(100% - 150px);
                overflow-x: auto;
              }
              .bor-right {
                border-right: 1px solid #e4e7ed;
                border-left: none !important;
              }
              .bor-left {
                border-left: 1px solid #e4e7ed !important;
              }
              .drag-box {
                display: flex;
                &.cursor-scroll {
                  .image-information-item {
                    cursor: all-scroll;
                  }
                }
              }
              .image-information-item {
                position: relative;
                .upload-limit {
                  position: absolute;
                  right: 0;
                  top: 2px;
                  z-index: 2;
                  cursor: pointer;
                  color: red;
                }
                ::v-deep.el-form-item {
                  margin: 0;
                  width: 100%;
                  height: 100%;
                  .el-form-item__content {
                    width: 100%;
                    height: 100%;
                    line-height: 26px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                    .el-input {
                      .el-input__inner {
                        padding: 0 4px;
                        line-height: 26px !important;
                        height: 26px !important;
                      }
                    }
                  }
                }
                .tit-bg {
                  background: #f5f7fa;
                  line-height: 26px;
                  border-left: 1px solid #e4e7ed;
                }
                .image-information-item-image {
                  width: 101px;
                  height: 102px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-bottom: 1px solid #e4e7ed;
                  border-left: 1px solid #e4e7ed;
                  border-top: 1px solid #e4e7ed;
                  box-sizing: border-box;
                  padding: 12px;
                  position: relative;
                  .upload-mask {
                    position: absolute;
                    left: 12px;
                    top: 12px;
                    right: 12px;
                    bottom: 12px;
                    color: #fff;
                    opacity: 0;
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.5);
                    transition: opacity 0.3s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 3;
                    cursor: default;
                    .upload-item-delete {
                      margin-left: 10px;
                      cursor: pointer;
                    }
                    .upload-item-repeta {
                      margin-left: 10px;
                      cursor: pointer;
                    }
                    .upload-item-preview {
                      cursor: pointer;
                    }
                    &:hover {
                      opacity: 1;
                    }
                  }
                  .imgage-uploader {
                    width: 100%;
                    height: 100%;
                    ::v-deep.el-upload {
                      width: 100%;
                      height: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                }
                .image-information-item-caption {
                  width: 101px;
                  height: 32px;
                  box-sizing: border-box;
                  padding: 2px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-bottom: 1px solid #e4e7ed;
                  border-left: 1px solid #e4e7ed;
                }
                .image-information-item-is-primary {
                  width: 101px;
                  height: 32px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-bottom: 1px solid #e4e7ed;
                  border-left: 1px solid #e4e7ed;
                }
                .image-information-item-has-logo {
                  width: 101px;
                  height: 32px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-left: 1px solid #e4e7ed;
                  border-bottom: 1px solid #e4e7ed;
                }
              }
            }
          }
          .basic-information {
            .price-information-cont {
              padding-top: 5px;
              padding-bottom: 15px;
              .pricecode-tooltip {
                font-size: 20px;
                cursor: pointer;
              }
              .quantity-info-box {
                display: flex;
                align-items: center;
                margin-top: 15px;
                .quantity-info-label {
                  width: 140px;
                  flex-shrink: 0;
                  padding: 0 10px;
                  text-align: right;
                  margin-bottom: 5px;
                  // &.catalog-price-lable {
                  //   margin-bottom: 18px;
                  // }
                }
                .quantity-info-cont {
                  flex: 1;
                  display: flex;
                  flex-wrap: wrap;
                  padding-right: 10px;
                  &.catalog-price-box {
                    ::v-deep.el-form-item {
                      margin-bottom: 0;
                      .el-form-item__error {
                        padding-top: 0;
                      }
                    }
                  }
                  ::v-deep.el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    .el-checkbox {
                      width: 120px;
                      height: 32px;
                      border: 1px solid #e4e7ed;
                      margin-right: 10px;
                      border-radius: 2px;
                      box-sizing: border-box;
                      padding: 0 4px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-bottom: 5px;
                      .el-checkbox__input {
                        margin-top: 2px;
                      }
                      .el-checkbox__label {
                        line-height: 32px;
                      }
                    }
                  }
                  ::v-deep.el-input {
                    width: 120px;
                    height: 32px;
                    padding: 0;
                    margin-right: 10px;
                    margin-bottom: 5px;
                    box-sizing: border-box;
                    .el-input__inner {
                      width: 120px;
                      height: 32px;
                      line-height: 32px;
                      padding: 0 4px;
                      box-sizing: border-box;
                    }
                  }
                }
                &.price-includes-box {
                  .quantity-info-cont {
                    ::v-deep.el-input {
                      width: 120px;
                      .el-input__inner {
                        width: 120px;
                      }
                    }
                    ::v-deep.el-checkbox {
                      margin-top: 4px;
                    }
                  }
                }
              }
              .options-box {
                padding: 20px 10px 20px 0;
                .add-options-box {
                  display: flex;
                  align-items: center;
                  .add-options-tit {
                    width: 80px;
                    text-align: center;
                    flex-shrink: 0;
                  }
                  .add-options-btn {
                    flex-shrink: 0;
                    margin-right: 10px;
                  }
                }
                .options-item {
                  .options-item-top {
                    padding: 20px 80px 0;
                    width: 450px;
                  }
                  .options-item-cont {
                    display: flex;
                    align-items: center;
                    overflow-x: auto;
                    .delete-box {
                      flex-shrink: 0;
                      width: 80px;
                      text-align: center;
                      color: #ff4d4f;
                      .iconfont {
                        font-size: 28px;
                        cursor: pointer;
                      }
                      &.disable {
                        opacity: 0.3;
                        .iconfont {
                          font-size: 28px;
                          cursor: default;
                        }
                      }
                    }
                    .ptions-item-table {
                      ::v-deep.el-table__cell {
                        padding: 0;
                      }
                      ::v-deep.el-form-item {
                        margin: 14px 0;
                        .el-input__inner {
                          padding: 0 5px;
                        }
                      }
                      .table-delete-btn {
                        color: #666;
                        &.disable {
                          opacity: 0.5;
                        }
                      }
                      ::v-deep.el-button {
                        text-decoration: underline;
                        margin: 10px 0;
                      }
                    }
                    .ptions-item-info {
                      align-self: flex-start;
                      padding: 0 20px;
                      .quantity-info-box {
                        margin-top: 0;
                      }
                      .quantity-info-cont {
                        margin-left: 10px;
                      }
                      .ptions-item-radio-box {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        ::v-deep.el-radio {
                          display: flex;
                          align-items: center;
                          .el-radio__label {
                            display: flex;
                            align-items: center;
                            .el-tooltip {
                              margin-left: 5px;
                            }
                          }
                        }
                      }
                    }
                  }
                  .divider-line-box {
                    display: flex;
                    align-items: center;
                    .divider-line-left {
                      width: 80px;
                      height: 0;
                      flex-shrink: 0;
                    }
                  }
                }
                .setup-charge-box {
                  margin-top: 12px;
                  .setup-charge-top-box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    .setup-charge-tit {
                      width: 140px;
                      text-align: center;
                    }
                  }
                  .setup-charge-cont {
                    padding: 0 20px;
                    ::v-deep.el-form-item__content {
                      display: flex;
                      align-items: center;
                      .setup-charge-cont-input {
                        max-width: 180px;
                      }
                      .setup-charge-cont-v {
                        width: 60px;
                        margin: 0 5px;
                        .el-input__inner {
                          padding: 0 5px;
                        }
                      }
                    }
                    .run-chgs-box {
                      display: flex;
                      align-items: center;
                      ::v-deep.el-input {
                        margin-right: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
<style>
  .el-form-item__content .category-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .category-select .el-select-dropdown__list {
    max-height: 300px !important;
    overflow: auto;
    overflow-x: hidden;
  }
  .price-includes-popper.el-autocomplete-suggestion li{
    padding: 0 8px;
    font-size: 14px;
  }
</style>
