<!--
  功能：订单设置
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月02日 14:36:12
  版本：v1.4
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="proSetting-container">
    <!-- 上 -->
    <el-row :gutter="10">
      <!-- 出口抬头 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">出口抬头</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showDialog(1)"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="list1" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountName" align="center" label="名称" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>
                  {{ scope.row.amountName || '-' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="amountShort" align="center" label="简称" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>
                  {{ scope.row.amountShort || '-' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <span v-if="scope.row.amountStatus === 0">-</span>
                <el-tooltip content="编辑" placement="top">
                  <el-button type="primary" plain icon="el-icon-edit" circle size="mini" @click="edit(1, scope.row)"
                    v-if="scope.row.amountStatus !== 0"></el-button>
                </el-tooltip>

                <el-tooltip content="作废" placement="top">
                  <el-button type="danger" plain circle size="mini" v-if="scope.row.amountStatus === 1"
                    @click="setvoid(scope.row)">
                    <i class="iconfont icon-void"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>

      <!-- 询盘来源 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">询盘来源</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showDialog(2)"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="list2" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountName" align="center" label="名称" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>
                  {{ scope.row.amountName || '-' }}
                </span>
              </template>
            </el-table-column>

            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <template v-if="scope.row.amountName !== 'Email'">
                  <el-tooltip content="编辑" placement="top">
                    <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
                      @click="edit(2, scope.row)"></el-button>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top">
                    <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                      @click="deleteItem(2, scope.row)"></el-button>
                  </el-tooltip>
                </template>
                <template v-else>-</template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <!-- 线下订单来源 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">线下订单来源</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showDialog(3)"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="list3" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountName" align="center" label="名称" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>
                  {{ scope.row.amountName || '-' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100" :resizable="false">
              <template slot-scope="scope">
                <template v-if="scope.row.amountName !== 'Email'">
                  <el-tooltip content="编辑" placement="top">
                    <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
                      @click="edit(3, scope.row)"></el-button>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top">
                    <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                      @click="deleteItem(3, scope.row)"></el-button>
                  </el-tooltip>
                </template>
                <template v-else>-</template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <!-- 采购溢装数限制 -->
      <el-col :span="6">
        <el-row class="public-col" style="height: 100px">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">采购溢装数设置</p>
            <el-tooltip content="编辑" placement="top">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="edit(4, list4)"></el-button>
            </el-tooltip>
          </el-row>
          <p>
            <span style="font-weight: bold; margin-right: 10px">上限</span>
            <span>{{ (list4 && list4[0] && list4[0].amountName) || 0 }}%</span>
          </p>
        </el-row>
        <el-row>
          <p class="clerk-setting">单证员默认设置</p>
          <el-table :data="list7" border stripe>
            <el-table-column align="center" label="运输方式" prop="transportWay"></el-table-column>
            <el-table-column align="center" label="单证员" prop="amountShort" show-overflow-tooltip>
              <template slot-scope="scope">
                <p v-if="scope.row.clerkNameCn">
                  <span>{{ scope.row.clerkNameCn }}</span>
                  <span>
                    {{ scope.row.clerkNameEn }}
                  </span>
                </p>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <!--   :disabled="!hasClerkEditRight" -->
                <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
                  @click="editClerk(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- 单证员弹窗 -->
        <el-dialog title="单证员设置" :append-to-body="true" :visible.sync="clerkFormVisible" width="400px" @close="close"
          :close-on-click-modal="false">
          <el-form ref="clerkForm" :model="clerkForm" :rules="clerkFormRules" label-position="top">
            <el-form-item prop="clerkNameCn" label="单证员">
              <el-input v-model="clerkForm.clerkNameCn" placeholder="请选择" @focus="showUserChoose" />
            </el-form-item>
          </el-form>
          <template #footer>
            <el-row class="text-center">
              <el-button type="primary" @click="saveClerkForm" :loading="loading">确认</el-button>
              <el-button @click="closeClerkForm">取消</el-button>
            </el-row>
          </template>
        </el-dialog>

        <!-- 单证员选择 -->
        <UserChoose ref="UserChoose" @choose-user="chooseUser"></UserChoose>
      </el-col>
    </el-row>
    <!-- 中 -->
    <el-row :gutter="10">
      <!-- 贸易条款 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">贸易条款</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showDialog(5)"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="list5" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountName" align="center" label="贸易条款" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.amountName || '-' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <el-tooltip content="删除" placement="top">
                  <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                    @click="deleteItem(5, scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <!-- 订单自动完结设置 -->
      <el-col :span="6">
        <div class="my-container">
          <div class="public-col">
            <el-row type="flex" justify="space-between" align="middle">
              <p class="f_s_16">订单自动完结条件配置</p>
              <el-tooltip content="编辑" placement="top">
                <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editAutoClose"></el-button>
              </el-tooltip>
            </el-row>
            <p>
              <el-row>
                <span style="font-weight: bold; margin-right: 10px">
                  条件1：
                </span>
                <span>已收金额-应收金额误差值</span>
              </el-row>
              <el-row>
                <span>
                  误差金额区间：{{ list6[0] ? list6[0].amountName : '0' }}
                  ~
                  {{ list6[0] ? list6[0].amountShort || '+∞' : '+∞' }}
                </span>
              </el-row>
            </p>
          </div>
          <!-- 出库数量配置 -->
          <div class="public-col mt15">
            <el-row type="flex" justify="space-between" align="middle">
              <p class="f_s_16">出库数量配置</p>
              <el-tooltip content="编辑" placement="top">
                <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="showOutConfig"></el-button>
              </el-tooltip>
            </el-row>
            <p v-if="outConfigParams.amountId">
              是否允许出库数量大于订单数量：
              <span style="font-weight: bold; margin-right: 10px">
                {{ outConfigParams.value ? '是' : '否' }}
              </span>
            </p>
          </div>
          <!-- 退款提示 -->
          <div class="public-col mt15">
            <el-row type="flex" justify="space-between" align="middle">
              <p class="f_s_16">退款提示</p>
              <el-tooltip content="编辑" placement="top">
                <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="showRefundConfig"></el-button>
              </el-tooltip>
            </el-row>
            <p v-if="list9[0]">
              {{list9[0].amountName}}：
              <span style="font-weight: bold; margin-right: 10px">
                {{ list9[0].amountStatus ? '启用' : '停用' }}
              </span>
            </p>
          </div>

        </div>
      </el-col>
      <!-- 季度业绩取值 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">季度业绩取值</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showDialog(7)"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="listMonthly" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountName" align="center" label="取值来源" show-overflow-tooltip>
              <template slot-scope="scope">
                <!-- {{ scope.row.amountName || '-' }} -->
                销售订单
              </template>
            </el-table-column>
            <el-table-column prop="amountShort" align="center" label="取值状态" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ statusMap[scope.row.amountShort] || '-' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
                    @click="editMonthly(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                    @click="deleteItem(7, scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">收货公司、地址黑名单</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showDialog(10)"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="list10" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountShort" align="center" label="类型">
              <template slot-scope="scope">
                {{ scope.row.amountShort === 'company' ? '公司' : '地址' }}
              </template>
            </el-table-column>
            <el-table-column prop="amountName" align="center" label="关键字" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
                    @click="editBlackAddress(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                    @click="deleteItem(10, scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 下 -->
    <el-row :gutter="10">
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">修改收货信息钉钉消息提醒对象

            </p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showDialog(12)"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="list12" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountName" align="center" label="地区" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="c_pointer">
                  {{ scope.row.amountName || '-' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="amountShort" align="center" label="提醒岗位" show-overflow-tooltip>

              <template slot-scope="scope">
                <span class="c_pointer">
                  {{processAmount(scope.row.amountShort)}}
                </span>

              </template>

            </el-table-column>
            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <span v-if="scope.row.amountStatus === 0">-</span>
                <el-tooltip content="编辑" placement="top">
                  <el-button type="primary" plain icon="el-icon-edit" circle size="mini" @click="editdd(scope.row)"
                    v-if="scope.row.amountStatus !== 0"></el-button>
                </el-tooltip>

                <el-tooltip content="删除" placement="top">

                  <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                    @click="deletedd(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <!-- 协助客代 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">协助客代

            </p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showAssistForm"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="assistList" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountName" align="center" label="人员" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="c_pointer">
                  {{ scope.row.userName || '-' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="amountShort" align="center" label="地区" show-overflow-tooltip>

              <template slot-scope="scope">
                {{ scope.row.area || '-' }}
              </template>

            </el-table-column>
            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <span v-if="scope.row.amountStatus === 0">-</span>


                <el-tooltip content="删除" placement="top">
                  <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                    @click="deleteAssist(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <!-- 订单作废 -->
      <el-col :span="6">
        <div class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">订单审核通过后<span class="invalid-day">{{ (list13 && list13[0] && list13[0].amountShort) || '--'
                }}</span>天内无操作直接作废</p>
            <el-tooltip content="编辑" placement="top">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editInvalidDay"></el-button>
            </el-tooltip>
          </el-row>
          <p class="f_s_12 tip">
            提示审核通过后、订单被安排、订单上挂了费用、订单有收款。
          </p>
          <p class="f_s_12">订单被生成采购需求/采购订单、挂有委外加工、委托单，有这些操作均不作废。</p>
        </div>
      </el-col>
      <!-- 下载箱唛设置 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">下载箱唛设置</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showMarkForm()"></el-button>
            </el-tooltip>
          </div>
          <downloadMarkSettingTable ref="downloadMarkSettingTable" setting-type="ORDER" @edit-mark="editMark" />

        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <!-- 客户收货地址运费波动关键词提醒 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">客户收货地址运费波动关键词提醒</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showDialog(14)"></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15 keywordTable" :data="list14" border style="width: 100%" :height="tableHeight">
            <el-table-column prop="amountName" align="center" label="关键词" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
                    @click="editShippingAddress(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                    @click="deleteItem(14, scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <!-- 订单提醒设置 -->
      <el-col :span="6">
        <div class="my-container">
          <div class="public-col">
            <el-row type="flex" justify="space-between" align="middle">
              <p class="f_s_16">订单提醒设置</p>
              <el-tooltip content="编辑" placement="top">
                <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="showOrderRemindConfig"></el-button>
              </el-tooltip>
            </el-row>
            <p>
              订单装运日期提醒提前期：
              <span style="font-weight: bold; margin-right: 10px">
                {{ orderRemindConfig.outNumSettingFlag || '--' }} 天
              </span>
            </p>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 订单自动完结条件配置弹窗 -->
    <el-dialog title="订单已完结条件配置" :visible.sync="autoCloseVisible" width="550px">
      <autoClose @saveSet="saveSet" @cancelSet="cancelSet" :editData="list6[0]"></autoClose>
    </el-dialog>
    <!-- 订单过期天数配置弹窗 -->
    <el-dialog title="订单审核通过倒计时作废" :visible.sync="invalidDayVisible" width="550px">
      <invalidDay :invalidDayVisible.sync="invalidDayVisible" @saveDaySet="saveDaySet" :dayNumber="dayNumber">
      </invalidDay>
    </el-dialog>
    <!-- 弹窗 -->
    <el-dialog :title="title" :append-to-body="true" :visible.sync="dialogFormVisible" width="500px" @close="close"
      v-if="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" :rules="rules" :label-width="currentAmountType === 4 ? '100px' : '50px'">
        <template v-if="currentAmountType !== 4">
          <el-form-item prop="amountName" label="名称">
            <el-input v-model="form.amountName" placeholder="请输入" />
          </el-form-item>
          <el-form-item prop="amountShort" label="简称" v-if="currentAmountType == 1">
            <el-input v-model="form.amountShort" placeholder="请输入" />
          </el-form-item>
        </template>
        <el-form-item prop="amountName" label="上限（%）" v-else>
          <el-input-number style="width: 100%" v-model="form.amountName" placeholder="请输入" controls-position="right"
            :precision="0" :controls="controls" :min="0" :max="100" />
        </el-form-item>
      </el-form>

      <template #footer>
        <el-row class="text-center">
          <el-button type="primary" @click="save" :loading="loading">确认</el-button>
          <el-button @click="close">取消</el-button>
        </el-row>
      </template>
    </el-dialog>
    <!-- 季度业绩弹窗 -->
    <MonthlyAchievementValue :params="params" ref="monthlyAchievementValue" @sure="onMonthlySave">
    </MonthlyAchievementValue>
    <!-- 出库数量配置弹窗 -->
    <OutboundQuantityConfiguration ref="outboundQuantityConfiguration" :params="outConfigParams"
      @refresh="getOutboundQuantityConfig" />
    <!-- 退款提示弹窗 -->
    <RefundDialog :refundDialogModel.sync="refundDialogModel" :info="list9[0]" @saveRefund="saveRefund"></RefundDialog>

    <BlackAddress :blackAddressVisible.sync="blackAddressVisible" ref="blackAddress" @saveBlack="saveBlack">
    </BlackAddress>
    <!-- 客户收货地址运费波动关键词提醒-弹框 -->
    <ShippingAddress :shippingAddressVisible.sync="shippingAddressVisible" ref="shippingAddress" @saveShipping="saveShipping" />
    <GoodsDD :blackAddressVisible.sync="goodsDDVisible" ref="goodsDD" @saveGoodsDD="saveGoodsDD"></GoodsDD>
    <AssistForm ref="assistForm" @assist-refresh="getAssistList"></AssistForm>
    <!-- 箱唛设置弹窗 -->
    <downloadMarkSettingDialog ref="downloadMarkSettingDialog" @mark-refresh="refreshMarkTable" />

    <!-- 订单提醒设置 -->
    <OrderRemind :orderRemindVisible.sync="orderRemindVisible" ref="orderRemind" @saveOrderRemind="saveOrderRemind" />

  </div>
</template>

<script>

  import { hasRight } from '@/utils/permissionBtn'
  import { mapGetters, mapActions } from 'vuex'
  import UserChoose from '@/components/userChoose/index'
  import autoClose from './components/autoClose.vue'
  import MonthlyAchievementValue from './components/monthly-achievement-value'
  import OutboundQuantityConfiguration from './components/outbound-quantity-configuration.vue'
  import { OrderSettingInteractor } from '@/core/interactors/order-setting'
  import RefundDialog from './components/refundEdit.vue'
  import BlackAddress from './components/blackAddress.vue'
  import ShippingAddress from './components/ShippingAddress.vue' // 客户收货地址运费波动关键词提醒-弹框
  import GoodsDD from './components/goods-dd.vue'
  import AssistForm from './components/assist-form'
  import invalidDay from './components/invalidDay.vue'
  import OrderRemind from './components/OrderRemind.vue'
  import downloadMarkSettingTable from './components/downloadMarkSetting/downloadMarkSettingTable.vue'
  import downloadMarkSettingDialog from './components/downloadMarkSetting/downloadMarkSettingDialog.vue'
  import { markSettingMixin } from './components/downloadMarkSetting/markSettingMixin.js'

  let self
  export default {
    name: 'ProductSetting',
    mixins: [markSettingMixin],
    components: {
      UserChoose,
      autoClose,
      MonthlyAchievementValue,
      OutboundQuantityConfiguration,
      RefundDialog,
      BlackAddress,
      ShippingAddress,
      GoodsDD,
      AssistForm,
      invalidDay,
      downloadMarkSettingTable,
      downloadMarkSettingDialog,
      OrderRemind,
    },
    props: {},
    data() {
      self = this
      return {
        outConfigParams: {
          //出库数量配置
          value: 1,
          showDialog: false,
        },

        params: {
          // 季度业绩取值弹窗
          showDialog: false,
        },
        statusMap: {
          5: '已通过',
          6: '待发货',
          8: '待收货',
          9: '已收货',
          10: '已完成',
        },
        currentAmountType: 1, //1:出口抬头，2:询盘来源,3:线下订单来源,4:采购放量数设置,5:贸易条款
        dialogFormVisible: false,
        clerkFormVisible: false,
        controls: true,
        list1: [],
        list2: [],
        list3: [],
        list4: [],
        list5: [],
        list6: [],
        list7: [],
        list9: [],
        list12: [],
        list13: [],//作废天数
        list14: [], // 关键词列表
        listMonthly: [],
        list10: [], // 收货公司，地址黑名单
        assistList: [], // 协助客代列表
        blackAddressVisible: false, // 收货公司，地址黑名单弹窗
        shippingAddressVisible: false, // 客户收货地址运费波动关键词提醒-弹框
        goodsDDVisible: false,
        form: {
          amountName: '',
          amountShort: '',
        },
        clerkForm: {
          clerkNameCn: '',
          clerkNameEn: '',
          clerkId: '',
        },
        clerkFormRules: {
          clerkNameCn: [
            {
              required: true,
              message: '请选择',
            },
          ],
        },
        rulesNoShort: {
          amountName: [
            { required: true, trigger: 'blur', message: '请输入' },
            {
              validator: (rule, value, callback) => {
                if (value && value.trim().length === 0) {
                  callback(new Error('请输入'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
            {
              min: 1,
              max: 50,
              message: '长度在 1 到 50 个字符',
              trigger: 'blur',
            },
          ],
        },
        rulesHasShort: {
          amountName: [
            { required: true, trigger: 'blur', message: '请输入' },
            {
              min: 1,
              max: 50,
              message: '长度在 1 到 50 个字符',
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                if (value && value.trim().length === 0) {
                  callback(new Error('请输入'))
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
          ],
          amountShort: [
            { required: true, trigger: 'blur', message: '请输入简称' },
            {
              min: 1,
              max: 3,
              message: '长度在 1 到 3 个字符',
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                var reg = /^[A-Za-z]*$/
                if (!reg.test(value)) {
                  callback(new Error('请输入字母,不包含数字或其他字符'))
                } else {
                  if (value !== value.toUpperCase()) {
                    callback(new Error('请输入大写'))
                  } else {
                    callback()
                  }
                }
              },
              trigger: 'blur',
            },
          ],
        },
        rulesNumber: {
          amountName: [{ required: true, trigger: 'blur', message: '请输入' }],
        },
        hasClerkEditRight: false,
        autoCloseVisible: false,
        refundDialogModel: false,
        loading: false,
        outNumSettingFlag: 0,
        invalidDayVisible: false,
        dayNumber: '',
        orderRemindVisible: false, // 订单提醒设置弹框显示隐藏
        orderRemindConfig: {}, // 订单提醒设置
      }
    },

    computed: {
      rules() {
        if (this.currentAmountType === 1) {
          return this.rulesHasShort
        } else if (this.currentAmountType === 4) {
          return this.rulesNumber
        } else {
          return this.rulesNoShort
        }
      },
      title() {
        const type2text = {
          1: '出口抬头',
          2: '询盘来源',
          3: '线下订单来源',
          4: '采购溢装数设置',
          5: '贸易条款',
          6: '结算条件',
        }
        return type2text[this.currentAmountType]
      },
      label() {
        if (this.currentAmountType === 4) {
          return '上限（%）'
        } else {
          return '名称'
        }
      },
      tableHeight() {
        return (window.innerHeight - 45 - 20 - 20 - 20) / 2 - 30 - 20 - 20
      },


    },
    created() {
      const listTypes = [1, 2, 3, 4, 5, 6, 7, 9, 10, 12, 13, 14]
      listTypes.forEach((type) => {
        this.getListByType(type)
      })
      this.getOutboundQuantityConfig()
      this.getClerkList()
      this.hasClerkEditRight = hasRight('CLERK:EDIT')
      // 获取协助客代
      this.getAssistList()
      // 获取订单提醒设置
      this.getOrderRemindConfig()
    },



    methods: {
      processAmount(amount) {
        const map = {
          '1': '订单支持',
          '2': '客户代表',
          '3': '采购开发'
        };

        // 将字符串按逗号分割成数组
        const numbers = amount.split(',');

        // 去除数组中的重复项
        const uniqueNumbers = [...new Set(numbers)];

        // 对数组中的数字进行排序（从小到大）
        const sortedNumbers = uniqueNumbers.map(Number).sort((a, b) => a - b);

        // 根据映射关系转换数字为中文描述，并拼接成字符串
        const result = sortedNumbers.map(num => map[num.toString()]).join(',');

        return result;
      },
      // 获取协助客代
      getAssistList() {
        OrderSettingInteractor.listOrderAssistPageApi().then(res => {
          if (res && res.code === '000000') {
            this.assistList = res.data?.data || []
          }
        })
      },
      deletedd(row) {
        this.$confirm('删除后无法恢复,是否删除?', '提示', {
          type: 'warning',
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        })
          .then(() => {
            OrderSettingInteractor.deleteById(row.amountId).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('删除成功')
                this.getListByType(12)
              }
            })
          })
          .catch(() => { })
      },
      editdd(row) {

        this.$refs.goodsDD.getDetail(row)
        this.goodsDDVisible = true
      },
      // 季度业绩编辑
      editMonthly(row) {
        this.$refs.monthlyAchievementValue.showAddEdit(row)
      },
      // 季度业绩弹窗确定事件
      onMonthlySave(params) {
        const requestData = {
          ...params,
          amountType: 7,
        }

        OrderSettingInteractor.insertPO(requestData).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
            this.params.showDialog = false
            this.getListByType(7)
          }
        })
      },
      saveClerkForm() {
        this.$refs.clerkForm.validate(async (flag) => {
          if (flag) {
            this.loading = true
            let requestData = {
              ...this.currentRow,
              ...this.clerkForm,
            }
            const res = await clerkSettingUpdate(requestData)

            if (res && res.code === '000000') {
              this.$message.success('操作成功')
              this.clerkFormVisible = false
              this.currentRow = {}
              this.clerkForm = {
                clerkNameCn: '',
                clerkNameEn: '',
                clerkId: '',
              }
              this.getClerkList()
            }
            this.loading = false
          }
        })
      },
      closeClerkForm() {
        this.clerkFormVisible = false
      },
      // 单证员选择
      chooseUser([{ id, name, englishName }]) {
        this.currentRow.amountShort = name //amountShort放人员名称
        this.currentRow.amountStatus = id // amountStatus放人员id
        // 选择的单证员
        this.clerkForm = {
          clerkNameCn: name,
          clerkNameEn: englishName,
          clerkId: id,
        }
      },
      // 单证员编辑
      editClerk(row) {
        this.currentRow = row
        this.clerkForm = {
          ...this.clerkForm,
          ...row,
        }
        this.clerkFormVisible = true
      },

      showUserChoose() {
        const id = this.currentRow.clerkId,
          name = this.currentRow.clerkNameCn
        this.$refs.UserChoose.showAddEdit(
          '',
          id
            ? [
              {
                id,
                name,
              },
            ]
            : '',
          true
        )
      },
      edit(type, item) {
        if (type === 4) {
          if (this.list4.length > 0) {
            this.form.amountName = this.list4[0].amountName
          } else {
            this.form.amountName = 0
          }
        } else {
          this.form = { ...item } //赋值
        }

        this.showDialog(type)
      },
      showDialog(type) {

        if (type === 7) {
          this.params.showDialog = true
          this.$refs.monthlyAchievementValue.form = {
            amountName: 'ORDER', // 取值类型
            amountShort: '', //取值状态
          }
          this.$nextTick().then(() => {
            this.$refs.monthlyAchievementValue.clearValidate()
          })
        } else if (type == 10) {
          this.blackAddressVisible = true
        } else if (type == 12) {
          this.goodsDDVisible = true
        } else if (type == 14) {
          this.shippingAddressVisible = true
        } else {
          this.dialogFormVisible = true
        }
        this.currentAmountType = type
      },
      deleteItem(type, { amountId }) {
        this.$confirm('删除后无法恢复,是否删除?', '提示', {
          type: 'warning',
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        })
          .then(() => {
            OrderSettingInteractor.deleteById(amountId).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('删除成功')
                this.getListByType(type)
              }
            })
          })
          .catch(() => { })
      },
      setvoid({ amountId }) {
        this.$confirm('是否作废?', '提示', {
          type: 'warning',
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        })
          .then(() => {
            OrderSettingInteractor.obsolete(amountId).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('作废成功')
                this.getListByType('1')
              }
            })
          })
          .catch(() => { })
      },
      close() {
        this.dialogFormVisible = false
        this.form = {
          amountName: '',
          amountShort: '',
        }
      },
      getAddRequestData() {
        const { amountName } = this.form,
          { currentAmountType: amountType } = this
        let requestData = {
          ...this.form,
          amountType,
        }
        if (this.currentAmountType === 4) {
          requestData = { amountName, amountShort: amountName, amountType }
        }
        return requestData
      },
      add() {
        const requestData = this.getAddRequestData()
        OrderSettingInteractor.insertPO(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.getListByType(this.currentAmountType)
            this.$message.success('操作成功')
            this.loading = false
          }
          this.form = {
            amountName: '',
            amountShort: '',
          }
          this.dialogFormVisible = false
        })
      },
      getListByType(amountType) {
        OrderSettingInteractor.listPage({ amountType }).then((res) => {
          if (res && res.code === '000000') {
            if (amountType === 7) {
              this.listMonthly = res.data
            } else {
              this['list' + amountType] = res.data
            }
          }
        })
      },
      // 获取单证员列表
      getClerkList() {
        OrderSettingInteractor.clerkSettingList().then((res) => {
          if (res && res.code === '000000') {
            this.list7 = res.data || []
          }
        })
      },
      save() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true
            this.add()
          }
        })
      },
      // 自动完结条件设置
      editAutoClose() {
        this.autoCloseVisible = true
      },
      //订单过期天数
      editInvalidDay() {
        this.dayNumber = this.list13?.[0]?.amountShort ?? ''
        this.invalidDayVisible = true
      },
      // 订单过期天数保存
      saveDaySet(obj) {
        let dt = {
          // amountId:this.list13?.[0]?.amountId ?? '',
          amountType: 13,
          amountName: 13,
          amountShort: obj?.day ?? '',
        }
        OrderSettingInteractor.insertPO(dt).then((res) => {
          if (res?.code === '000000') {
            this.invalidDayVisible = false
            this.$message.success('操作成功')
            this.getListByType(13)
          }
        })
      },
      // 订单完结条件保存
      saveSet(val) {
        let dt = {
          ...val,
          amountType: 6,
        }
        OrderSettingInteractor.insertPO(dt).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
            this.getListByType(6)
            this.autoCloseVisible = false
          }
        })
      },
      cancelSet() {
        this.autoCloseVisible = false
      },
      // 退款提示弹窗
      showRefundConfig() {
        this.refundDialogModel = true
      },
      // 保存退款提示
      saveRefund(detail) {
        OrderSettingInteractor.insertPO(detail).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
            this.getListByType(9)
            this.refundDialogModel = false
          }
        })
      },
      // 黑名单 编辑
      editBlackAddress(row) {
        this.blackAddressVisible = true
        this.$refs.blackAddress.getDetail(row)
      },
      // 客户收货地址运费波动关键词提醒-编辑回显
      editShippingAddress(row) {
        this.shippingAddressVisible = true
        this.$refs.shippingAddress.getDetail(row)
      },
      // 收货公司、地址黑名单保存
      saveBlack(val) {
        let dt = {
          ...val,
          amountType: 10,
        }
        OrderSettingInteractor.insertPO(dt).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
            this.getListByType(10)
            this.autoCloseVisible = false
          }
        })
      },
      // 客户收货地址运费波动关键词提醒-保存
      saveShipping(val, done) {
        let dt = {
          ...val,
          amountType: 14,
        }
        OrderSettingInteractor.insertPO(dt).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
            this.getListByType(14)
            this.$refs.shippingAddress.cancel()
          }
        }).finally(() => {
          done()
        })
      },

      saveGoodsDD(val) {
        let dt = {
          ...val,
          amountType: 12,
        }
        OrderSettingInteractor.insertPO(dt).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
            this.getListByType(12)
            this.goodsDDVisible = false
          }
        })
      },

      // 获取出库数量配置
      getOutboundQuantityConfig() {
        OrderSettingInteractor.getOutboundQuantityConfig().then((data) => {
          this.outConfigParams = {
            showDialog: false,
            value: data?.value,
            amountId: data?.amountId,
            show: true
          }
        })
      },

      showOutConfig() {
        this.outConfigParams.showDialog = true
        this.$nextTick(() => {
          this.$refs.outboundQuantityConfiguration.show(this.outConfigParams.value)
        })
      },

      showAssistForm() {
        this.$refs.assistForm.showAddEdit()
      },




      deleteAssist(row) {
        this.$confirm('删除后无法恢复,是否删除?', '提示', {
          type: 'warning',
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        })
          .then(() => {
            OrderSettingInteractor.deleteOrderAssistByIdApi(row.orderAssistId).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('删除成功')
                this.getAssistList()
              }
            })
          })
          .catch(() => { })
      },

      // 订单提醒设置-获取设置详情
      async getOrderRemindConfig () {
        try {
          const { code, data } = await OrderSettingInteractor.listPage({ amountType: 15 })
          if (code === '000000' && data?.length) {
            this.orderRemindConfig = data[0]
            const { outNumSettingFlag, amountStatus } = data[0]
            Object.assign(this.orderRemindConfig, {
              outNumSettingFlagCopy: outNumSettingFlag,
              outNumSettingFlagPrev: outNumSettingFlag,
              outNumSettingFlag: amountStatus === 1 ? outNumSettingFlag : '--'
            })
          }
        } catch (e) {
          console.log(e)
        }
      },
      // 订单提醒设置-打开弹窗
      showOrderRemindConfig() {
        this.orderRemindVisible = true
        this.$refs.orderRemind.form = { ...this.orderRemindConfig }
      },
      // 订单提醒设置-保存
      saveOrderRemind(form, done) {
        OrderSettingInteractor.insertPO(form).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
            this.getOrderRemindConfig()
            this.$refs.orderRemind.cancel()
          }
        }).finally(() => {
          done()
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .proSetting-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    >.el-row {
      /* flex: 1; */
      min-height: 430px;

      &+.el-row {
        margin-top: 10px;
      }

      >.el-col {
        height: 100%;
      }
    }
  }

  .public-col {
    height: 100%;
    border: 1px solid #ededed;
    padding: 10px;
    display: flex;
    flex-direction: column;

    p {
      line-height: 32px;

      &.tip {
        margin-top: 30px;
      }

      .invalid-day {
        font-size: 20px;
        color: #333;
        margin: 0 4px;
      }
    }

  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .row-bottom {
    margin-top: 2vh;
  }

  .clerk-setting {
    margin: 15px 0;
  }

  // 关键词table
  .keywordTable {
    /deep/ td.el-table__cell > .cell {
      white-space: pre; // 识别出每个空格
    }
  }
</style>
