<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogConfirmVisible"
    width="80%"
    @close="close"
    :close-on-click-modal="false"
    class="dialog-content"
  >
    <div class="dialog-content">
      <el-form :model="confirmForm" :rules="confirmRules" ref="confirmForm">
        <table class="custom-table join-table mt20">
          <thead>
            <tr>
              <th width="180">
                <span>
                  <i>*</i>
                  付款方
                </span>
              </th>
              <th width="150">
                <span>
                  <i>*</i>
                  收款方式
                </span>
              </th>
              <!-- <th width="150">
                <span>
                  <i>*</i>
                  出口抬头
                </span>
              </th> -->
              <th width="120">
                <span>
                  <i>*</i>
                  收款账户
                </span>
              </th>
              <th width="180">
                <span>
                  <i>*</i>
                  票据单号
                </span>
              </th>
              <th width="100">币种</th>
              <th width="120">收款金额</th>
              <!-- <th width="100">手续费金额</th> -->
              <th width="200">
                <span>
                  <i>*</i>
                  收款日期
                </span>
              </th>
              <th width="200">
                <span>
                  <i>*</i>
                  银行入账日期
                </span>
              </th>
            </tr>
          </thead>
          <tbody v-if="confirmForm.tableData">
            <tr v-for="(item, i) in confirmForm.tableData" :key="i">
              <!-- 付款方 -->
              <td>
                <el-form-item
                  :prop="'tableData.' + i + '.shroffAccountId'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="confirmForm.tableData[i].shroffAccountId"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
              <!-- 收款方式 -->
              <td style="width: 120px">
                <el-form-item
                  :prop="'tableData.' + i + '.financeMethod'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    v-model="confirmForm.tableData[i].financeMethod"
                    placeholder="请选择"
                    @change="
                      financeMethodChange(
                        confirmForm.tableData[i].financeMethod,
                        confirmForm.tableData[i].currency,
                        confirmForm.tableData[i].amountCollected,
                        i
                      )
                    "
                  >
                    <el-option
                      v-for="(item, index) in paymentOptions"
                      :key="index"
                      :label="item.financeName"
                      :value="item.financeName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </td>
              <!-- 出口抬头 -->
              <!-- <td>
                <el-form-item
                  :prop="'tableData.' + i + '.exportsRise'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    v-model="confirmForm.tableData[i].exportsRise"
                    placeholder="请选择"
                    style="width: 100%"
                    @change="payChange"
                  >
                    <el-option
                      v-for="(item, index) in exportTitleOptions"
                      :key="index"
                      :label="item.amountShort"
                      :value="item.amountShort"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </td> -->
              <!-- 收款账户 -->
              <td style="width: 200px">
                <el-form-item
                  :prop="'tableData.' + i + '.payerId'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="confirmForm.tableData[i].payerId"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
              <!-- 票据单号 -->
              <td>
                <el-form-item
                  :prop="'tableData.' + i + '.ticketNumber'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="confirmForm.tableData[i].ticketNumber"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
              <!-- 币种 -->
              <td style="width: 80px">
                {{ confirmForm.tableData[i].currency }}
              </td>
              <!-- 收款金额 -->
              <td>
                <span class="price">
                  {{ confirmForm.tableData[i].amountCollected }}
                </span>
              </td>
              <!-- 手续费金额 -->
              <!-- <td>
                {{ confirmForm.tableData[i].commissionAmount }}
              </td> -->
              <!-- 收款日期 -->
              <td>
                <el-form-item
                  :prop="'tableData.' + i + '.amountTime'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="confirmForm.tableData[i].amountTime"
                    style="width: 100%"
                    type="date"
                    placeholder="年/月/日"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </td>
              <!-- 银行入账日期 -->
              <td>
                <el-form-item
                  :prop="'tableData.' + i + '.bankAcceptanceTime'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="confirmForm.tableData[i].bankAcceptanceTime"
                    style="width: 100%"
                    type="date"
                    placeholder="年/月/日"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
    </div>
    <el-row class="text-center mt30">
      <el-button type="primary" @click="save('confirmForm')">确 认</el-button>
      <el-button @click="close">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import {
    selectOptions,
    purchaseRefundConfirm,
    purchaseRefundDetail,
  } from '@/api/finance/credit-manage-list'
  import { orderAmountListPage } from '@/api/finance/credit-note-add'
  import { detailByFinanceOrder } from '@/api/finance/credit-manage-list'
  import { serviceCharge } from '@/api/finance/credit-note-add'
  import { deepCopy } from 'kits'
  export default {
    name: 'PurchaseCollectionModel',
    data() {
      return {
        dialogConfirmVisible: false,
        confirmForm: {
          tableData: [
            // {
            //   financeId: '', //业务id
            //   shroffAccountId: '', //付款方
            //   financeMethod: '', //收款方式
            //   exportsRise: '', //出口抬头
            //   payerId: '', //收款账户
            //   ticketNumber: '', //票据单号
            //   currency: '', //币种
            //   amountCollected: '', //收款金额
            //   commissionAmount: '', //手续费金额
            //   amountTime: '', //收款日期
            //   bankAcceptanceTime: '', //银行入账日期
            // },
          ],
        },
        confirmRules: {},
        paymentOptions: [], //收款方式下拉
        exportTitleOptions: [], //出口抬头
        status: null, //采购退款状态
        receiptData: {}, //详情
        title: '确认收款',
      }
    },
    created() {},

    methods: {
      showConfirmReceipt(id, financeStatus, str) {
        if (str === 'more') {
          this.title = '批量确认收款'
        }
        this.status = Number(financeStatus)
        this.getSelectOptions(3) //收款方式
        //this.getExportTitle() //出口抬头
        this.getDetailByFinanceOrder(id)

        this.dialogConfirmVisible = true
      },

      //收款单详情
      async getDetailByFinanceOrder(id) {
        let response = await purchaseRefundDetail({ financeIds: id })
        if (response.code === '000000') {
          this.receiptData = response.data
          let test = []
          response.data.forEach((item, index) => {
            let obj = {
              financeId: item.financeId, //业务id
              shroffAccountId: item.shroffAccountId, //付款方
              financeMethod: item.financeMethod, //收款方式
              exportsRise: '', //出口抬头
              payerId: '', //收款账户
              ticketNumber: '', //票据单号
              currency: item.currency, //币种
              amountCollected: item.amountCollected, //收款金额
              commissionAmount: 0, //手续费金额
              amountTime: item.amountTime, //收款日期
              bankAcceptanceTime: '', //银行入账日期
            }
            // this.getserviceCharge(
            //   item.currency,
            //   item.amountCollected,
            //   item.financeMethodCode,
            //   index
            // )
            test.push(obj)
          })
          this.confirmForm.tableData = test
        }
      },

      //手续费金额
      // async getserviceCharge(currency, amountCollected, financeSetupId, index) {
      //   if (financeSetupId) {
      //     let params = {
      //       currency: currency,
      //       payMoney: amountCollected,
      //       financeMethodCode: financeSetupId,
      //     }
      //     let response = await serviceCharge(params)
      //     if (response.code === '000000') {
      //       this.confirmForm.tableData[index].commissionAmount = response.data
      //     }
      //   }
      // },

      //保存
      save(confirmForm) {
        this.$refs[confirmForm].validate((valid) => {
          if (valid) {
            let params = deepCopy(this.confirmForm.tableData)
            purchaseRefundConfirm(params).then((res) => {
              if (res.code === '000000') {
                this.$message.success('确认收款成功')
              }
            })
            this.$emit('updateList')
            this.close()
          }
        })
      },

      //收款方式change
      financeMethodChange(name, currency, amountCollected, index) {
        if (name) {
          //收款方式的id
          let financeSetupId = this.paymentOptions.filter(
            (item) => item.financeName === name
          )[0].financeSetupId
          let params = {
            currency: currency,
            payMoney: amountCollected,
            financeMethodCode: financeSetupId,
          }
          serviceCharge(params).then((res) => {
            if (res.code === '000000') {
              this.$set(
                this.confirmForm.tableData[index],
                'commissionAmount',
                res.data
              )
              this.$forceUpdate()
            }
          })
        }
      },

      //出口抬头
      payChange() {},

      // 获取收款方式下拉
      async getSelectOptions(type) {
        let response = await selectOptions({
          financeType: Number(type),
        })
        if (response.code === '000000') {
          if (type === 3) {
            this.paymentOptions = response.data
          }
        }
      },
      // 获取出口抬头列表
      async getExportTitle() {
        let response = await orderAmountListPage({ amountType: 1 })
        if (response.code === '000000') {
          response.data.forEach((item) => {
            if (item.amountStatus == 1) {
              this.exportTitleOptions.push({ ...item })
            }
          })
        }
      },

      //关闭
      close() {
        this.title = '确认收款'
        this.$refs['confirmForm'].resetFields()
        this.confirmForm = {
          tableData: [
            {
              financeId: '', //业务id
              shroffAccountId: '', //付款方
              financeMethod: '', //收款方式
              exportsRise: '', //出口抬头
              payerId: '', //收款账户
              ticketNumber: '', //票据单号
              currency: '', //币种
              amountCollected: '', //收款金额
              commissionAmount: '', //手续费金额
              amountTime: '', //收款日期
              bankAcceptanceTime: '', //银行入账日期
            },
          ],
        }
        this.exportTitleOptions = []
        this.paymentOptions = []
        this.status = null
        this.receiptData = {}
        this.dialogConfirmVisible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    ::v-deep {
      .el-dialog__body {
        padding: 10px 20px 30px;
      }
      .el-dialog__title {
        font-size: 16px;
      }
    }
  }
  .custom-table thead th {
    min-width: 100px;
  }
  .price {
    color: green;
    font-weight: bold;
  }
  ::v-deep .el-table__row.expanded + tr {
    height: auto;
  }
  ::v-deep .el-table__cell.el-table__expanded-cell:empty {
    height: 0;
    padding: 0;
  }
  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }
  }
</style>
