
import { ProductListInteractor, OutProcessing, OutboundSheetInteractor } from '@/core'

export const mixin = {
  methods: {
    //产品编码click
    skuIdClick(val) {
      let skuId = ''
      if (val.childProduct && val.childProduct.length > 0) {
        skuId = val.parentSku
      } else {
        skuId = val.skuId
      }
      this.getProductIdByCode(skuId.split('-')[0])
    },

    //根据产品code，查询产品id
    async getProductIdByCode(id) {
      if (id.indexOf('FL') > -1) return
      let response = await ProductListInteractor.productIdByCode(id)
      if (response.code === '000000') {
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId: response.data,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      }
    },

    // 获取默认仓库id
    async _initDefaultWarehouseId(inOrOut, type) {
      // inOrOut的值，必传，in 或 out
      // type的值，选传，inOrOut为in时传，JIA=加工入库 TUI=退料入库
      const field = inOrOut === 'in' ? 'defaultInWarehouseId' : 'defaultOutWarehouseId'
      const res = await OutProcessing.getOutProcessingConfig()
      if (res && res.code === '000000') {
        this.form.warehouseId =
          res.data.processConfigVO && res.data.processConfigVO[field]
        if (res.data?.processConfigDefaultWareHouseVOS?.length) {
          const item = res.data.processConfigDefaultWareHouseVOS.find(n => n.supplierId === this.form.supplierId)
          if (inOrOut === 'in') {
            this.form.needAudit = item?.needAudit
            if (type === 'JIA') {
              this.form.warehouseId = item?.defaultInWarehouseId
            }
          } else if (inOrOut === 'out') {
            // pickingProductQuery中返回，去getDetail返回的processConfigDefaultWareHouseVOS中匹配，获取到defaultOutWarehouseId
            this.form.warehouseId = item?.defaultOutWarehouseId
          }
        }
      }
    },
    // 获取仓库列表
    async _getWarehouseList() {
      let res = await OutboundSheetInteractor.findWarehouseList(1)
      this.warehouses = res?.data || []
    },
  }
}


