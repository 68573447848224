<template>
  <div class="custom-container">
    <div class="credit">
      <!-- header -->
      <el-row class="page-header" type="flex" align="middle" justify="space-between"
        style="padding-bottom: 20px; border-bottom: 1px solid #d8d8d8">
        <el-col :span="24">
          <el-row v-if="!selectRows.length" type="flex">
            <el-col :span="11" style="display: flex; margin-right: 10px">
              <el-button icon="el-icon-plus" type="primary" @click="addClick" v-allowed="['Make:Collections']">
                新增
              </el-button>
              <el-form ref="queryForm" :inline="true" :model="queryForm" label-width="50px" @submit.native.prevent>
                <el-form-item>
                  <el-input placeholder="请输入收款单号/订单编号" v-model.trim="queryForm.searchNumber" class="width-300 ml10"
                    @change="searchList"></el-input>
                </el-form-item>
              </el-form>
              <el-button @click="searchList(queryForm.searchNumber)">
                搜索
              </el-button>
              <el-button plain v-allowed="['Affirm:Collect']" @click="confirmClick()">
                确认收款
              </el-button>
              <el-button @click="productFilterClick">高级筛选</el-button>
              <el-tooltip content="请先选择数据" placement="bottom" v-allowed="['Credit:Reject']">
                <el-button type="danger" plain>驳回</el-button>
              </el-tooltip>
              <el-tooltip content="请先选择数据" placement="bottom">
                <el-button plain v-allowed="['Bank:Number']">
                  录入银行流水号
                </el-button>
              </el-tooltip>

              <!-- 收款导入 -->
              <TableImport class="ml10" v-allowed="['Payee:Import']" :param="{
                  type: 'credit',
                  isCredit: true,
                  btnName: '收款导入',
                }"></TableImport>
            </el-col>
          </el-row>
          <!-- 表格勾选展示 -->
          <el-row type="flex" align="middle" v-else>
            <p>
              已选择
              <strong class="f_s_18 f_w_7">{{ selectRows.length }}</strong>
              个 &emsp; 合计
              <strong class="f_s_18 f_w_7" :style="totalAmount < 0 ? 'color:red' : ''">
                {{ totalAmount }}
              </strong>
              美元
            </p>
            <el-button v-show="isReturn" class="ml10" type="danger" plain @click="rejectClick(rejectForm.financeIds)"
              v-allowed="['Credit:Reject']">
              驳回
            </el-button>
            <el-button class="ml10" plain v-allowed="['Affirm:Collect']" @click="confirmClick2()" v-show="isConfirm">
              确认收款
            </el-button>
            <el-button class="ml10" plain @click="bankNumberClick(bankIds)" v-allowed="['Bank:Number']">
              录入银行流水号
            </el-button>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <p class="divider"></p>
        <el-radio-group v-model="queryForm.status" class="mt15 mb5" @change="creditStatusChange">
          <el-radio v-for="(item, i) in statusRadio" :key="i" :label="item.value">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-row>

      <!-- table -->
      <ErpTable ref="BaseTable" :page-number.sync="pageNo" :page-size.sync="pageLe" :table-data="baseTableData"
        :total="total" :extraHeight="60" :key="customTableKey" @query="fetchData" class="mt10"
        @selection-change="setSelectRows" :default-sort="defaultSort" @sort-change="sortChange">
        <el-table-column align="center" type="selection" width="55" v-show="queryForm.status !== 3" />
        <template v-for="(item) in columns">
          <el-table-column :key="item.prop" :prop="item.prop" :label="item.label" align="center" :min-width="item.width"
            v-if="item.isShow" :sortable="item.sortable ? 'custom' : false" show-overflow-tooltip>
            <template #default="{ row }">
              <div v-if="item.prop == 'financeCode'">
                <span class="blue-text" @click="receiptNoClick(row)">
                  {{ row.financeCode || '--' }}
                </span>
              </div>
              <!-- 收款来源 -->
              <div v-else-if="item.prop == 'financeType'">
                <span>
                  {{ row.financeType | financeTypeFilter }}
                </span>
              </div>
              <!-- 订单编号 -->
              <div v-else-if="item.prop == 'orderCode'">
                <div v-if="row.orderCode == null">--</div>
                <div v-else>
                  <span class="blue-text" @click="
                      orderDetailClick(row.orderCode, row.financeType, row)
                    " v-if="row.orderCode.split('/').length <= 1">
                    {{ row.orderCode }}
                  </span>
                  <el-popover placement="right" trigger="hover" v-else>
                    <p v-for="(item, index) in row.orderCode.split('/')" :key="index + Math.random()" class="blue-text"
                      @click="orderDetailClick(item, row.financeType, row)">
                      {{ item }}
                    </p>
                    <span slot="reference">
                      <span class="blue-text">
                        {{ row.orderCode.split('/')[0] }}
                      </span>
                      <span class="dot-span">
                        {{ row.orderCode.split('/').length }}
                      </span>
                    </span>
                  </el-popover>
                </div>
              </div>
              <div v-else-if="item.prop == 'amountCollected'">
                <span v-if="row.entryType == 1" style="color: red; margin-right: -3px">
                  -
                </span>
                <span :style="row.entryType == 1 ? 'color:red' : ''">
                  {{ row.amountCollected | orderMoneyFormat }}
                </span>
              </div>
              <!-- 状态 -->
              <div v-else-if="item.prop == 'financeStatus'">
                <el-tag type="warning" plain v-if="row.financeStatus === '1'" size="small">
                  待银行处理
                </el-tag>
                <el-tag type="danger" plain v-if="row.financeStatus === '2'" size="small">
                  待认领
                </el-tag>
                <el-tooltip placement="top" effect="light" v-if="['3', '10'].includes(row.financeStatus)">
                  <div slot="content">
                    <span>
                      {{
                      (row.financeStatus === '3' ? '驳回人：' : '撤销人：') +
                      (row.updateName || '--')
                      }}
                    </span>
                    <span class="d_block mt5">
                      {{
                      (row.financeStatus === '3'
                      ? '驳回原因：'
                      : '撤销原因：') + (row.statusRemark || '--')
                      }}
                    </span>
                    <span class="d_block mt5">
                      {{
                      (row.financeStatus === '3'
                      ? '驳回时间：'
                      : '撤销时间：') + (row.updateTime || '--')
                      }}
                    </span>
                  </div>
                  <el-tag v-if="row.financeStatus === '3'" type="danger" plain size="small">
                    已驳回
                  </el-tag>
                  <el-tag v-else type="danger" plain size="small">
                    已撤销
                  </el-tag>
                </el-tooltip>
                <el-tag v-if="row.financeStatus === '9'" type="danger" plain size="small" @click="statusTypeClick(row)"
                  class="c_pointer">
                  审核驳回
                </el-tag>
                <el-tag type="success" plain v-if="row.financeStatus === '4'" size="small">
                  已完成
                </el-tag>
                <!-- <el-tag
                      type="info"
                      plain
                      v-if="row.financeStatus === '5'"
                      size="small"
                    >
                      已作废
                    </el-tag> -->
                <el-tooltip placement="top" effect="light" v-if="row.financeStatus === '5'">
                  <div slot="content">
                    <span>{{ '作废人' + (row.updateName || '--') }}</span>
                    <span class="d_block mt5">
                      {{ '作废原因：' + (row.statusRemark || '--') }}
                    </span>
                    <span class="d_block mt5">
                      {{ '作废时间：' + (row.updateTime || '--') }}
                    </span>
                  </div>
                  <el-tag type="info" plain size="small">已作废</el-tag>
                </el-tooltip>

                <el-tag type="warning" plain v-if="row.financeStatus === '6'" size="small">
                  已认领
                </el-tag>
                <el-tag type="danger" plain v-if="row.financeStatus === '7'" size="small">
                  待业务确认
                </el-tag>
                <el-tag type="primary" plain v-if="row.financeStatus === '8'" size="small" @click="statusTypeClick(row)"
                  class="c_pointer">
                  审核中
                </el-tag>
              </div>
              <!-- 科目 -->
              <div v-else-if="item.prop == 'courseTitle'">
                <span v-if="row.courseTitle">{{ row.courseTitle }}</span>
                <span class="blue-text" v-else @click="subjectClick(row)">
                  未设置
                </span>
              </div>
              <!-- 操作 -->
              <span v-else>{{ row[item.prop] || '--' }}</span>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center" fixed="right" width="120" v-if="queryForm.status != 4">
          <template slot-scope="scope">
            <el-row type="flex" align="middle" justify="center">
              <div v-if="
                  ['1', '2', '3', '6', '7', '9'].includes(
                    scope.row.financeStatus
                  ) && scope.row.operateFinanceOrder === '1'
                " style="white-space: nowrap; margin-right: 5px">
                <!-- 6 已认领，财务确认 -->
                <el-tooltip content="确认" placement="top" v-if="
                    scope.row.financeStatus === '6' &&
                    scope.row.confirmClaimFlag === 0
                  ">
                  <el-button v-allowed="['Affirm:Collect']" type="primary" plain icon="el-icon-check" circle size="mini"
                    @click="
                      confirmClick(
                        scope.row.financeId,
                        scope.row.financeCode,
                        scope.row.financeStatus,
                        scope.row.financeType,
                        'operate'
                      )
                    "></el-button>
                </el-tooltip>
                <el-tooltip content="确认收款" placement="top" v-if="
                    !['3', '6', '9'].includes(scope.row.financeStatus) &&
                    scope.row.confirmCollectionFlag !== 1
                  ">
                  <el-button v-allowed="['Affirm:Collect']" type="primary" plain icon="el-icon-check" circle size="mini"
                    @click="
                      confirmClick(
                        scope.row.financeId,
                        scope.row.financeCode,
                        scope.row.financeStatus,
                        scope.row.financeType,
                        'operate',
                        scope.row.entryType
                      )
                    "></el-button>
                </el-tooltip>
                <el-tooltip content="驳回" placement="top" v-if="
                    !['3', '9'].includes(scope.row.financeStatus) &&
                    scope.row.financeType !== 3 &&
                    scope.row.remark !== '在线支付'
                  ">
                  <el-button type="danger" plain icon="el-icon-close" circle size="mini"
                    @click="rejectClick(scope.row.financeId)" v-allowed="['Credit:Reject']"></el-button>
                </el-tooltip>
              </div>
              <!-- <el-tooltip
                    content="编辑"
                    placement="top"
                    v-if="
                      ['3', '9'].includes(scope.row.financeStatus) &&
                      scope.row.financeType !== 3
                    "
                  >
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      @click="editClick(scope.row.financeId, 'edit')"
                    ></el-button>
                  </el-tooltip> -->
              <el-tooltip content="作废" placement="top" v-if="
                  ['2', '3', '9'].includes(scope.row.financeStatus) ||
                  (scope.row.financeStatus === '7' &&
                    scope.row.remark !== '在线支付')
                ">
                <el-button type="info" plain circle size="mini" @click="voidClick(scope.row.financeId)"
                  v-allowed="['Credit:Void']">
                  <i class="iconfont icon-void"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" content="审核撤销" placement="top">
                <el-button v-show="
                    scope.row.financeStatus === '8' &&
                    scope.row.withdrawnHandleFlag === 1
                  " type="danger" plain @click="revokeClick(scope.row)" size="mini" icon="iconfont icon-quxiao"
                  circle></el-button>
              </el-tooltip>

              <el-tooltip content="重新提交" placement="top">
                <el-button v-if="
                    ['3', '9', '10'].includes(scope.row.financeStatus) &&
                    userInfo.userId === scope.row.creatorId
                  " type="primary" plain circle size="mini" @click="editClick(scope.row, 'resubmit')">
                  <i class="iconfont icon-zhongxintijiao"></i>
                </el-button>
              </el-tooltip>
            </el-row>
            <span v-if="
                ['4', '5'].includes(scope.row.financeStatus) ||
                (scope.row.financeStatus === '3' && scope.row.financeType === 3)
              ">
              --
            </span>
          </template>
        </el-table-column>
      </ErpTable>
      <!-- 设置科目弹窗 -->
      <el-dialog title="绑定科目" :visible.sync="dialogSubjectVisible" width="400px" @close="closeSubject"
        :close-on-click-modal="false">
        <div class="subject-dialog-content">
          <el-select v-model="courseTitleId" placeholder="请选择" @change="courseTitleChange">
            <el-option v-for="item in subjectOptions" :key="item.financeSetupId" :label="item.financeName"
              :value="item.financeSetupId"></el-option>
          </el-select>
        </div>
        <template #footer>
          <div class="text-center mt20">
            <el-button type="primary" @click="saveSubject">确 认</el-button>
            <el-button @click="closeSubject">取 消</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <!-- 全选驳回判断符合条件弹窗 -->
    <!-- 新增收款单弹窗 -->
    <CreditNoteAddModel ref="CreditNoteAdd" @updateList="fetchData2" />

    <!--收款单详情弹窗 -->
    <!-- 销售订单，其他费用ReceiptDetailModel1，采购退款ReceiptDetailModel2-->
    <ReceiptDetailModel1 ref="ReceiptDetailModel1" @updateList="fetchData2" />
    <ReceiptDetailModel2 ref="ReceiptDetailModel2" @updateList="fetchData2" />

    <!-- 订单详情-采购订单 -->
    <OrderDetailModel ref="OrderDetail" />
    <!-- 订单详情-销售订单 -->
    <OrderDetailModel2 ref="OrderDetail2" />

    <!-- 销售订单确认收款弹窗 -->
    <ConfirmReceiptModel ref="ConfirmReceipt" @confirmRefresh="fetchData2" />

    <!-- 采购订单确认收款弹窗 -->
    <PurchaseCollectionModel ref="PurchaseCollectionModel" @updateList="fetchData" />

    <!-- 录入银行流水号 -->
    <EnterBankNumberModel ref="EnterBankNumber" @updateList="fetchData2" />

    <!-- 作废 -->
    <CancelModel ref="CancelModel" @updateList="fetchData2" />

    <!-- 驳回 -->
    <RejectModel ref="RejectModel" @updateList="fetchData2" />

    <!-- 高级筛选Drawer -->
    <el-drawer title="高级筛选" :visible.sync="drawer" :before-="productFilterClose">
      <CreditSearch @cancelClick="productFilterClose" @searchClick="searchClick" />
    </el-drawer>

    <!-- 撤销弹窗 -->
    <el-dialog title="撤销原因" :visible="cancelVisible" width="500px" @close="onCancel" append-to-body>
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input type="textarea" :rows="3" placeholder="请输入" maxlength="500" show-word-limit
        v-model="cancelReason"></el-input>
      <div class="footer mt30 t_a_c">
        <!-- 确认 -->
        <el-button type="primary" @click="cancelSure">确认</el-button>
        <!-- 取消 -->
        <el-button @click="onCancel">取消</el-button>
      </div>
    </el-dialog>

    <!-- 审核进度Drawer -->
    <el-drawer title="审核进度" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" :auditType="auditType" @cancelClick="statusClose" />
    </el-drawer>
    <!--撤销弹窗 -->
    <CostCancel ref="CostCancel" @getList="fetchData2" :auditType="13" />
  </div>
</template>

<script>
  import {
    financeListPage,
    selectOptions,
    updateCourseTitle,
  } from '@/api/finance/credit-manage-list'
  import { financeGetCurrency } from '@/api/order'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import { getAuditObject, cancel } from '@/api/stock-center/inbound-sheet'
  import CreditNoteAddModel from './components/credit-note-add-model'
  import ReceiptDetailModel from './components/receipt-detail-model'
  import ReceiptDetailModel1 from './components/receipt-detail-model1'
  import ReceiptDetailModel2 from './components/receipt-detail-model2'
  import OrderDetailModel from './components/order-detail-model'
  import OrderDetailModel2 from './components/order-detail-model2'
  import ConfirmReceiptModel from './components/confirm-receipt-model'
  import EnterBankNumberModel from './components/enter-bank-number-model'
  import CreditSearch from './components/credit-search'
  import PurchaseCollectionModel from './components/purchase-collection-model'
  import CancelModel from './components/cancel-model'
  import RejectModel from './components/reject-model'
  import CostCancel from '@/views/finance-center/cost-manage/components/cost-cancel'
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import TableImport from '@/components/tableImport'
  import { mapGetters } from 'vuex'
  import { getKeyIds } from '@/utils/pagelist-util'
  import CustomCache from '@/utils/custom-cache'

  export default {
    name: 'CreditManage',
    components: {
      Audit,
      CreditNoteAddModel,
      ReceiptDetailModel,
      OrderDetailModel,
      OrderDetailModel2,
      ConfirmReceiptModel,
      EnterBankNumberModel,
      CreditSearch,
      ReceiptDetailModel1,
      ReceiptDetailModel2,
      PurchaseCollectionModel,
      CancelModel,
      RejectModel,
      CostCancel,
      TableImport,
    },
    data() {
      return {
        customTableKey: '',
        queryForm: {
          status: '1', //点击单选的状态
          searchNumber: '', //收款单号订单编号
          sortName: 'amountTime',
          sortType: 'descending',
        },
        statusRadio: [
          {
            label: '待处理',
            value: '1',
          },
          {
            label: '审核中',
            value: '8',
          },
          {
            label: '已完成',
            value: '4',
          },
          {
            label: '已驳回',
            value: '3',
          },
          {
            label: '已撤销',
            value: '10',
          },
          {
            label: '全部',
            value: '',
          },
        ],
        columns: [
          {
            label: '收款单号',
            width: '120',
            prop: 'financeCode',
            isShow: true,
          },
          {
            label: '银行流水号',
            width: '120',
            prop: 'serialNumber',
            isShow: true,
          },
          {
            label: '收款账户',
            width: '120',
            prop: 'payerName',
            isShow: true,
          },
          {
            label: '收款来源',
            width: '100',
            prop: 'financeType',
            isShow: true,
          },
          {
            label: '订单编号',
            width: '150',
            prop: 'orderCode',
            isShow: true,
          },
          {
            label: '付款方',
            width: '140',
            prop: 'payer',
            isShow: true,
          },
          {
            label: '币种',
            width: '80',
            prop: 'currency',
            isShow: true,
          },
          {
            label: '收款金额',
            width: '100',
            prop: 'amountCollected',
            isShow: true,
          },
          {
            label: '收款日期',
            width: '140',
            prop: 'amountTime',
            isShow: true,
            isSort: true,
            sortable: true,
            sortType: 'descending'
          },
          {
            label: '收款方式',
            width: '100',
            prop: 'financeMethod',
            isShow: false,
          },
          {
            label: '票据单号',
            width: '120',
            prop: 'ticketNumber',
            isShow: true,
          },
          {
            label: '客户代表',
            width: '150',
            prop: 'businessName',
            isShow: false,
          },
          {
            label: '状态',
            width: '100',
            prop: 'financeStatus',
            isShow: true,
          },
          {
            label: '科目',
            width: '100',
            prop: 'courseTitle',
            isShow: false,
          },
          {
            label: '创建人',
            width: '120',
            prop: 'creatorName',
            isShow: true,
          },
          {
            label: '创建时间',
            width: '150',
            prop: 'createTime',
            isShow: true,
            isSort: false,
            sortable: true,
          },
        ],
        defaultSort: {}, // 默认收款日期倒序
        isRequesting: false,
        rejectForm: {
          financeIds: [],
          statusRemark: '', // 驳回原因
        },
        rejectRules: {
          statusRemark: [
            { required: true, message: '请填写驳回原因', trigger: 'blur' },
          ],
        },
        voidForm: {
          financeId: '',
          statusRemark: '', // 作废原因
        },
        voidRules: {
          statusRemark: [
            { required: true, message: '请填写作废原因', trigger: 'blur' },
          ],
        },
        totalAmount: 0, // 总计金额
        exchangeRate: null, // 汇率
        selectRows: [], //表格勾选的数量
        bankIds: [], // 勾选的银行流水号id
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数
        cancelReason: '', // 撤销原因
        auditObjectId: '', // 审核id（产品id）
        auditType: 13, // 审核类型
        statusDrawer: false, //审核进度,
        baseTableData: [], // 表格数据
        dialogSubjectVisible: false, // 绑定科目弹窗显隐
        dialogRejectVisible: false, // 驳回弹窗显隐
        dialogVoidVisible: false, // 作废弹窗显隐
        subjectOptions: [], // 所有科目数据
        financeId: '', // 业务id
        courseTitleId: '', // 科目id
        courseTitle: '', // 科目名字
        isEntering: true, //是否可以进行录入银行流水操作()
        drawer: false,
        financeStatus: 1,
        isReturn: true, //是否显示驳回按钮
        isConfirm: true, //是否显示批量确认付款按钮
        cancelVisible: false,
        tableImportType: '',
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    // 状态过滤
    filters: {
      financeTypeFilter(status) {
        const financeType = {
          1: '销售订单',
          2: '其他费用',
          3: '采购退款',
          '1_1': '销售退款',
        }
        return financeType[status]
      },
    },
    created() {
      this.beforeFetchData()
      // this.fetchData()
      this.getColumsData()
      this.getExchangeRate()
    },
    methods: {
      matchCurrency(str) {
        return str === '美元' ? '$' : '￥'
      },

      // 获取汇率
      getExchangeRate() {
        financeGetCurrency({ financeName: '美元' }).then((res) => {
          if (res && res.code == '000000') {
            this.exchangeRate = res.data
          }
        })
      },

      fetchData2() {
        this.pageNo = 1
        this.fetchData()
      },
      // 处理tab
      beforeFetchData() {
        if (window.location.href.indexOf('keyids') > 0) {
          this.queryForm.status = ''
        }
      },
      // 获取列表数据
      async fetchData(isTablekeyChange = true) {
        // this.isRequesting = true
        let params = {
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          ...this.queryForm,
          cusName: this.queryForm.searchNumber, //fixbug【ID1000577】【收款管理】搜索后翻页、搜索记录被清空
          keyIds: getKeyIds(this),
        }
        await this.getListInfo(params)
        if (isTablekeyChange) {
          setTimeout(() => {
            this.customTableKey = Math.random()
          }, 300)
        }
      },

      async getListInfo(params) {

        let response = await financeListPage(params)

        if (response.code === '000000') {
          this.baseTableData = response?.data?.data?.map((item) => {
            if (item.entryType && item.entryType == 1) {
              item.financeType = '1_1'
            }
            return item
          })
          this.total = response?.data?.total
        }
      },

      // 状态的改变
      creditStatusChange(val) {
        this.isRequesting = true
        this.pageNo = 1
        if (val == 1) {
          // 待处理：隐藏收款方式、客户代表、科目
          this.columnTable(['financeMethod', 'businessName', 'courseTitle'])
        } else if (val == 4) {
          // 已完成：隐藏客户代表、状态
          this.columnTable(['businessName', 'financeStatus'])
        } else if (val == 3) {
          // 已驳回：隐藏银行流水号、收款方式、客户代表、科目
          this.columnTable(['serialNumber', 'financeMethod', 'businessName', 'courseTitle'])
        } else {
          // 全部('') 审核中(8) 已撤销(10)：隐藏银行流水号、科目
          this.columnTable(['serialNumber', 'courseTitle'])
        }
        this.queryForm.status = val
        this.fetchData()
      },

      // 隐藏列
      columnTable(arr) {
        // this.$nextTick(() => {
        this.columns.forEach((item, index) => {
          if (arr.includes(item.prop)) {
            this.$set(this.columns[index], 'isShow', false)
          } else {
            this.$set(this.columns[index], 'isShow', true)
          }
        })
        // })
      },

      // 搜索处理
      searchList(searchNumber) {
        this.pageNo = 1
        let params = {
          cusName: searchNumber,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          status: this.queryForm.status,
          sortName: this.queryForm.sortName,
          sortType: this.queryForm.sortType,
        }
        this.getListInfo(params)
      },

      //点击新增收款单
      addClick() {
        this.$refs['CreditNoteAdd'].showCreditAdd()
        this.$refs['CreditNoteAdd'].resetType()
      },

      // 驳回操作中的编辑收款单
      editClick(row, str) {
        this.$refs['CreditNoteAdd'].showCreditAdd(row, str)
      },

      //点击收款单号
      receiptNoClick(row) {
        //销售订单-详情
        if (
          row.financeType === 1 ||
          row.financeType === 2 ||
          row.financeType === '1_1'
        ) {

          this.$refs['ReceiptDetailModel1'].showReceiptDetail(
            row.financeCode,
            row.financeStatus,
            false
          )
        } else {
          this.$refs['ReceiptDetailModel2'].showReceiptDetail(
            row.financeCode,
            row.financeStatus
          )
        }
      },

      //点击订单编号
      orderDetailClick(orderCode, financeType, row) {
        if (financeType === 1 || financeType === 2 || financeType == '1_1') {
          //销售订单,其他费用
          this.$refs['OrderDetail2'].amendRow = orderCode
          this.$refs['OrderDetail2'].payType = financeType
          this.$refs['OrderDetail2'].showOrderDetail()
        } else {
          //采购退款
          this.$refs['OrderDetail'].amendRow = orderCode
          this.$refs['OrderDetail'].payType = financeType
          this.$refs['OrderDetail'].showOrderDetail()
        }
      },

      // 表格勾选
      setSelectRows(val) {
        this.bankIds = []
        let selectFinanceIds = []
        this.selectRows = val
        this.selectRows.forEach((item) => {
          selectFinanceIds.push(item.financeId)
        })

        // 计算合计金额
        let totalArray = []
        if (this.selectRows.length) {
          this.selectRows.forEach((item) => {
            let obj = {
              num: 0,
              type: 0,
            }
            obj.num =
              item.currency == '人民币'
                ? Number(item.amountCollected / this.exchangeRate)
                : item.amountCollected
            obj.type = item.entryType
            totalArray.push(obj.type === 1 ? 0 - obj.num : obj.num)
          })
          this.totalAmount = totalArray.reduce((p, v) => p + v).toFixed(3)
        }

        //是否可进行录入流水操作
        this.isEntering = this.selectRows.some((item) => {
          return item.serialNumber || item.financeStatus === '3'
        })

        //是否可进行驳回操作
        /*
          financeStatus：1=待银行处理 6=已认领
          operateFinanceOrder：数据权限，1=有权限 0=无权限
          financeType：收款来源，1=销售订单 2=其他费用 3=采购退款 1_1=销售退款
        */
        this.isReturn = this.selectRows.every((item) => {
          // 批量驳回的显示逻辑和列表中的驳回保持一致
          return (
            // (item.financeStatus === '1' || item.financeStatus === '6') &&
            ['1', '2', '6', '7'].includes(item.financeStatus) &&
            item.operateFinanceOrder === '1' &&
            item.financeType !== 3 &&
            item.remark !== '在线支付'
          )
        })

        /*
          是否显示批量确认付款按钮
            financeStatus：1=待银行处理 7=待业务确认
            operateFinanceOrder：数据权限，1=有权限 0=无权限
            confirmCollectionFlag：财务对已认领收款单的确认标记(0未确认 1已确认)
        */
        let isConfirm = this.selectRows.every((item) => {
          return ['1', '7'].includes(item.financeStatus) && item.operateFinanceOrder === '1' && item.confirmCollectionFlag != 1
        })
        let isConfirm12 = []
        this.selectRows.forEach((item) => {
          isConfirm12.push(item.financeType)
        })
        isConfirm12 = [...new Set(isConfirm12)]
        let test = null
        if (isConfirm12.length === 1) {
          if (isConfirm12.includes(1) || isConfirm12.includes(2)) {
            test = true
          }
          if (isConfirm12.includes(3)) {
            test = true
          }
        } else if (isConfirm12.length === 2) {
          if (isConfirm12.includes(1) && isConfirm12.includes(2)) {
            test = true
          }
          if (
            isConfirm12.includes(3) &&
            (isConfirm12.includes(1) || isConfirm12.includes(2))
          ) {
            test = false
          }
        } else if (isConfirm12.length === 3) {
          test = false
        }

        this.isConfirm = isConfirm && test
        if (
          isConfirm12.includes(3) &&
          (isConfirm12.includes(1) || isConfirm12.includes(2))
        ) {
          test = false
        } else {
          test = true
        }
        this.bankIds = selectFinanceIds
        this.$set(this.rejectForm, 'financeIds', selectFinanceIds)
      },

      // 获取所有下拉框数据
      async getSelectOptions(type) {
        // 1货币设置 2发票设置 3支付方式 4科目 5费用杂项
        let response = await selectOptions({
          financeType: Number(type),
        })
        if (response.code === '000000') {
          this.subjectOptions = response.data
        }
      },

      //撤销
      revokeClick(row) {
        const { payOrderId } = row

        let dt = {
          orderId: row.financeId,
          auditType: 13,
          tenantId: this.userInfo.tenantId,
        }
        CostManageInteractor.getAudit(dt).then((res) => {
          this.$refs.CostCancel.showCancel(res.data.auditObjectId)
        })
      },

      //确定撤销
      cancelSure() {
        if (!this.cancelReason) {
          return this.$message.warning('请输入撤销原因')
        }
        const { auditObjectId, auditType = 13, cancelReason } = this
        const { tenantId, userId, userName } = this.userInfo
        cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        }).then((res) => {
          if (res.code === '000000') {
            this.$message.success('撤销成功')
            this.cancelVisible = false
            this.fetchData2()
          }
        })
      },

      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        this.cancelReason = ''
      },

      // 设置科目
      async updateSubject() {
        let params = {
          businessId: this.financeId,
          courseTitle: this.courseTitle,
          courseTitleId: this.courseTitleId,
        }
        let response = await updateCourseTitle(params)
        if (response.code === '000000') {
          this.$message.success('更新科目成功！')
          this.fetchData()
          this.closeSubject()
        }
      },

      // 科目选择调用
      courseTitleChange(val) {
        let subjectItem = this.subjectOptions.filter((item) => {
          return val == item.financeSetupId
        })
        this.courseTitle = subjectItem[0].financeName
        this.courseTitleId = subjectItem[0].financeSetupId
      },

      // 点击未设置科目
      subjectClick(value) {
        this.financeId = value.financeId
        this.getSelectOptions(4)
        this.dialogSubjectVisible = true
      },

      saveSubject() {
        this.updateSubject()
      },

      closeSubject() {
        this.dialogSubjectVisible = false
      },

      // 点击驳回
      rejectClick(id) {
        this.rejectForm.financeIds = []
        if (Array.isArray(id)) {
          this.rejectForm.financeIds.push(...id)
        } else {
          this.rejectForm.financeIds.push(id)
        }
        this.$refs['RejectModel'].rejectForm.financeIds =
          this.rejectForm.financeIds
        this.$refs['RejectModel'].showAddEdit()
        //this.dialogRejectVisible = true
      },

      // 点击作废
      voidClick(id) {
        this.$refs['CancelModel'].voidForm.financeId = id
        this.$refs['CancelModel'].showAddEdit()
      },

      // 点击确认收款
      confirmClick(
        id,
        financeCode,
        financeStatus,
        financeType,
        str,
        entryType
      ) {
        console.log(entryType)
        if (!str) {
          let ids = this.selectRows.reduce((total, item) => {
            return (total += ',' + item.financeId)
          }, '')
          if (!ids) {
            this.$message.info('请最少选择一条')
            return
          }
        }

        if (financeType === 1 || financeType === 2 || financeType === '1_1') {
          //待认领-财务需要二次确认，走详情里面的确认
          if (financeStatus === '6') {
            this.$refs['ReceiptDetailModel1'].showReceiptDetail(
              financeCode,
              financeStatus,
              true
            )
          } else {
            //销售订单的确认收款
            if (id) {
              this.$refs['ConfirmReceipt'].showConfirmReceipt(
                id,
                'one',
                entryType
              )
            } else {
              //销售订单批量确认收款
              ids = ids.substring(1)
              this.$refs['ConfirmReceipt'].showConfirmReceipt(
                ids,
                'more',
                entryType
              )
            }
          }
        } else {
          //采购订单的确认收款
          this.$refs['PurchaseCollectionModel'].showConfirmReceipt(
            id,
            financeStatus,
            'one'
          )
        }
      },

      //批量确认收款
      confirmClick2() {
        let ids = this.selectRows.reduce((total, item) => {
          return (total += ',' + item.financeId)
        }, '')
        if (!ids) {
          this.$message.warning('请最少选择一条')
          return
        }
        let financeType = [] //收款单类型
        this.selectRows.forEach((item) => {
          financeType.push(item.financeType)
        })
        if (
          financeType.includes(3) &&
          !financeType.includes(1) &&
          !financeType.includes(2)
        ) {
          let financeIdStr = []
          let financeStatus = this.selectRows[0].financeStatus

          this.selectRows.forEach((item) => {
            financeIdStr.push(item.financeId)
          })

          //采购订单的确认收款
          this.$refs['PurchaseCollectionModel'].showConfirmReceipt(
            financeIdStr.join(','),
            financeStatus,
            'more'
          )
        } else if (
          (!financeType.includes(3) && financeType.includes(1)) ||
          (!financeType.includes(3) && financeType.includes(2))
        ) {
          //销售订单的确认收款
          ids = ids.substring(1)
          this.$refs['ConfirmReceipt'].showConfirmReceipt(ids, 'more')
        }
      },

      // 录入银行流水号
      bankNumberClick(ids) {
        if (this.isEntering) {
          return this.$message.warning(
            '银行流水号已存在/收款单状态已驳回，不可录入'
          )
        }
        this.$refs['EnterBankNumber'].showBankNumber(ids)
      },

      //审核进度查看
      statusTypeClick(row) {
        this.auditObjectId = row.financeId
        this.statusDrawer = true
      },

      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      // 高级筛选
      productFilterClick() {
        // this.financeStatus = this.queryForm.status
        this.drawer = true
      },

      // 高级筛选关闭
      productFilterClose() {
        this.drawer = false
      },

      // 高级筛选触发
      searchClick(val) {
        this.pageNo = 1
        val.sortType = this.queryForm.sortType
        val.sortName = this.queryForm.sortName
        this.queryForm = val
        this.$set(this.queryForm, 'status', '')
        this.fetchData()
        this.productFilterClose()
      },

      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(67, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.columns = res.columns
              // 处理排序搜索条件
              this.columns.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'amountTime') {
                    this.queryForm.sortName = 'amountTime'
                  } else if (item.prop === 'createTime') {
                    this.queryForm.sortName = 'createTime'
                  }
                  this.queryForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.queryForm.sortName = null)
                }
              })
              this.creditStatusChange('1')
            } else {
              // 初始化时，从CustomCache中未获取到columns，则默认收款日期倒序
              this.defaultSort = { prop: 'amountTime', order: 'descending' }
            }
          })
          .catch(() => {
            // 初始化时，从CustomCache中未获取到columns，则默认收款日期倒序
            this.defaultSort = { prop: 'amountTime', order: 'descending' }
            this.creditStatusChange('1')
          })
      },

      // 排序
      sortChange(column) {
        this.defaultSort = { prop: column.prop, order: column.order }
        if (column.prop === 'amountTime') {
          this.queryForm.sortName = 'amountTime'
        } else if (column.prop === 'createTime') {
          this.queryForm.sortName = 'createTime'
        } else {
          this.queryForm.sortName = ''
        }
        this.queryForm.sortType = column.order
        this.saveSort(column)
        this.pageNo = 1
        this.fetchData()
      },
      // 保存排序
      saveSort(column) {
        this.columns.forEach((item) => {
          if (item.prop === column.prop) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave()
      },
      // 保存自定义显示列
      operateSave(bool) {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 67,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000' && bool) {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    box-sizing: content-box;

    /deep/ .el-form-item--small {
      margin-bottom: 0;
    }
  }

  .main-height {
    height: calc(100% - 100px);
  }

  .table-height-box {
    height: calc(100% - 4px);
    overflow: auto;
    overflow-y: auto;
  }

  //自定义显示
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .width-300 {
    width: 300px;
  }

  .subject-dialog-content {
    .el-select--small {
      width: 360px;
    }
  }

  .reject-dialog-content {
    display: flex;

    .el-input--small {
      width: 360px;
      margin-left: 20px;
    }
  }

  .dot-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 12px;
    background: #409eff;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
  }

  ::v-deep {
    .credit {
      .el-table__body-wrapper {
        height: 650px !important;
      }

      .el-table {
        overflow: auto !important;
        height: 700px !important;
      }
    }
  }
</style>
