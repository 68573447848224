<template>
  <div>
    <el-button style="height: 34px" @click="showHigh">高级筛选</el-button>
    <el-drawer
      title="高级筛选"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
      custom-class="demo-drawer"
    >
      <div class="search_form">
        <el-form ref="form" :model="form" label-position="top">
          <el-form-item label="调拨仓库">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="form.warehouseOutId"
              clearable
              @clear="form.warehouseOutId = ''"
            >
              <el-option
                v-for="item in inspectionStageOptions"
                :key="item.value"
                :label="item.name"
                :value="item.warehouseId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作人">
            <UserSelect
              @user-choose="chooseUser"
              style="width: 100%"
              :key="id"
              ref="userSelect1"
              :defaultUser="defaultUser1"
            ></UserSelect>
          </el-form-item>
          <el-form-item label="创建人" prop="creatorName">
            <UserSelect
              :key="id"
              ref="userSelect2"
              @user-choose="chooseCreator"
              style="width: 100%"
              :defaultUser="defaultUser2"
            ></UserSelect>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              align="right"
              v-model="form.createTime"
              type="daterange"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <!-- <el-form-item label="盘点时间">
          <el-date-picker
            v-model="form.checkTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item> -->

          <div class="btn">
            <el-button type="primary" @click="submit">确定</el-button>
            <el-button @click="reset">重置</el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import AllotInquireInteractor from '@/core/interactors/stock-center/allot-inquire'
  import UserSelect from '@/components/user-select'

  export default {
    name: 'manageSearch',
    components: {
      UserSelect,
    },
    data() {
      return {
        form: {
          warehouseOutId: '', //调拨仓库
          updatorId: '', //操作人
          updatorName: '',
          updatorNameEn: '',
          creatorId: '', //创建人
          creatorName: '',
          creatorNameEn: '',
          createTime: [], // 创建时间
          // checkTime: '', // 盘点时间
        },
        inspectionStageOptions: [], // 仓库列表
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        id: 100,
        drawer: false,
        defaultUser1: {},
        defaultUser2: {},
      }
    },
    computed: {
      defaultUser() {
        if (this.form.userId) {
          let user = {
            userId: this.form.creatorId,
            userName: this.form.creatorName,
            englishName: this.form.creatorNameEn,
          }
          this.$refs.userSelect2.initUser(user)
          return user
        } else {
          return {
            userId: '',
            userName: '',
            englishName: '',
          }
        }
      },
    },
    watch: {
      // 创建时间
      'form.createTime': function (dateArray) {
        if (dateArray) {
          this.form.beginCreateTime = dateArray[0]
          this.form.endCreateTime = dateArray[1]
        } else {
          this.form.beginCreateTime = ''
          this.form.endCreateTime = ''
        }
      },
    },
    created() {
      AllotInquireInteractor.findWarehouseList().then((res) => {
        if (res && res.code === '000000') {
          this.inspectionStageOptions = res.data || []
        }
      })
    },
    methods: {
      showHigh() {
        this.drawer = true
        let cache = localStorage.getItem('ALLOT_INQUIRE_HIGH_FORM')
        if (cache) {
          this.form = JSON.parse(cache)
          setTimeout(() => {
            if (this.form.creatorId) {
              this.defaultUser2 = {
                userId: this.form.creatorId,
                userName: this.form.creatorName,
                englishName: this.form.creatorNameEn,
              }
            }
            if (this.form.updatorId) {
              this.defaultUser1 = {
                userId: this.form.updatorId,
                userName: this.form.updatorName,
                englishName: this.form.updatorNameEn,
              }
            }
          }, 200)
        }
      },
      setHighForm(cacheForm) {
        localStorage.setItem(
          'ALLOT_INQUIRE_HIGH_FORM',
          JSON.stringify(this.form)
        )
      },
      // 获取高级筛选查询参数
      _getHighForm() {
        return JSON.stringify(this.form)
      },
      // 安排订单选择采购开发
      chooseUser(user) {
        this.form.updatorId = user ? user.userId : ''
        this.form.updatorName = user ? user.userName : ''
        this.form.updatorNameEn = user ? user.englishName : ''
      },

      // 选择创建人
      chooseCreator(user) {
        this.form.creatorId = user ? user.userId : ''
        this.form.creatorName = user ? user.userName : ''
        this.form.creatorNameEn = user ? user.englishName : ''
      },

      // 关闭弹窗
      handleClose() {
        this.drawer = false
      },

      // 提交
      submit() {
        this.setHighForm()
        this.$emit('highSearch', this.form)
        this.drawer = false
      },

      // 重置
      reset() {
        this.form = this.$options.data().form
        id++
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search_form {
    padding: 0 20px 80px 20px;
    .el-date-editor--datetimerange.el-input__inner {
      width: 100%;
    }
    .btn {
      padding: 20px 0;
      width: 100%;
      position: fixed;
      bottom: 0;
      background: white;
    }
  }
  ::v-deep {
    .el-form-item {
      margin-bottom: 18px !important;
    }
    .el-date-editor {
      width: 100%;
    }
  }
</style>
