var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"添加规则","visible":_vm.modelVisible,"width":"800px","before-close":_vm.cancel},on:{"update:visible":function($event){_vm.modelVisible=$event}}},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules}},[_c('el-row',[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"选择国家","prop":"country","label-width":"105px"}},[_c('el-select',{attrs:{"placeholder":"请选择","multiple":""},on:{"change":_vm.selectCountry},model:{value:(_vm.ruleForm.country),callback:function ($$v) {_vm.$set(_vm.ruleForm, "country", $$v)},expression:"ruleForm.country"}},_vm._l((_vm.countryOptions),function(item){return _c('el-option',{key:item.areaId,attrs:{"label":item.areaName,"value":item.code}})}),1)],1)],1)],1),_c('el-form-item',{attrs:{"label":"计价类型","prop":"pricingType","label-width":"105px"}},[_c('el-radio-group',{model:{value:(_vm.ruleForm.pricingType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "pricingType", $$v)},expression:"ruleForm.pricingType"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("单价")]),_c('el-radio',{attrs:{"label":0}},[_vm._v("总价")])],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":2,"offset":1}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_c('span',[_vm._v("运费规则")])]),_c('el-col',{attrs:{"span":20}},[_c('el-table',{staticClass:"ml10 no-space form-rule-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.ruleForm.ladderAndPriceDTOS,"border":""}},[_c('el-table-column',{attrs:{"label":"> 开始值","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'ladderAndPriceDTOS.' + scope.$index + '.startBillingLadder',"rules":[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入","disabled":scope.$index !== 0,"type":"number","oninput":"if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"},model:{value:(scope.row.startBillingLadder),callback:function ($$v) {_vm.$set(scope.row, "startBillingLadder", $$v)},expression:"scope.row.startBillingLadder"}},[_c('template',{slot:"suffix"},[_vm._v(" "+_vm._s(_vm.addRuleType === 'weight' ? 'kg ' : 'm³')+" ")])],2)],1)]}}])}),_c('el-table-column',{attrs:{"label":"≤ 结束值","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'ladderAndPriceDTOS.' + scope.$index + '.billingLadder',"rules":[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入","type":"number","oninput":"if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"},on:{"input":function($event){return _vm.changePrice(scope)},"change":function($event){return _vm.checkSize(scope)}},model:{value:(scope.row.billingLadder),callback:function ($$v) {_vm.$set(scope.row, "billingLadder", $$v)},expression:"scope.row.billingLadder"}},[_c('template',{slot:"suffix"},[_vm._v(" "+_vm._s(_vm.addRuleType === 'weight' ? 'kg' : 'm³')+" ")])],2)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"价格","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'ladderAndPriceDTOS.' + scope.$index + '.price',"rules":[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入","type":"number","oninput":"if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"},model:{value:(scope.row.price),callback:function ($$v) {_vm.$set(scope.row, "price", $$v)},expression:"scope.row.price"}},[_c('template',{slot:"suffix"},[_c('span',[_vm._v(" "+_vm._s(_vm.currency === '美元' ? '美元' : '元')+" ")]),(_vm.ruleForm.pricingType == 1)?_c('span',[_vm._v(" /"+_vm._s(_vm.addRuleType === 'weight' ? 'kg' : 'm³')+" ")]):_vm._e()])],2)],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(
                  scope.$index == _vm.ruleForm.ladderAndPriceDTOS.length - 1 &&
                  scope.$index !== 0
                ),expression:"\n                  scope.$index == ruleForm.ladderAndPriceDTOS.length - 1 &&\n                  scope.$index !== 0\n                "}],attrs:{"type":"danger","plain":"","icon":"el-icon-delete","circle":"","size":"mini"},on:{"click":function($event){return _vm.del(scope)}}})]}}])})],1)],1)],1),_c('el-row',{staticClass:"text-center mt10"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.addRule}},[_vm._v("添加范围")])],1),_c('el-row',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确 认")]),_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }