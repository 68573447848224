<template>
  <el-row :gutter="20" class="pro-container pro-valuation">
    <el-col :span="2">
      <h6 class="left-h6">
        <!-- 产品计价 -->
        {{ $t('productDetail.ProductPricing') }}
        <el-tooltip class="item ml5" effect="light" placement="top">
          <template slot="content">
            <!-- 当前计价为Blank价格 -->
            <p>{{ $t('other.BlankPrice') }}</p>
          </template>
          <i class="el-icon-warning-outline c_pointer"></i>
        </el-tooltip>
      </h6>
    </el-col>
    <el-col :span="22" class="right-content">
      <el-form ref="form" :rules="rules" :model="form" label-width="150px" class="el_form">
        <!-- header -->
        <el-row>
          <el-col :span="6">
            <!-- 成本价 -->
            <el-form-item prop="costPrice" class="w100" :label="$t('productDetail.CostPrice') + '(￥)'">
              <el-input type="number" v-model="form.costPrice" :placeholder="$t('placeholder.plsInput')" maxlength="20"
                @change="handleChangeCostPrice" @input="getDollers" @focus="handleFocus('costPrice')"
                @blur="handleBlur('costPrice')"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row type="flex" align="middle" class="calcu">
              <p>
                <!-- 当前汇率 -->
                <label>{{ $t('productDetail.CurrentExchangeRate') }}：</label>
                <span>{{ exchange || '--' }}</span>
              </p>
              <p class="ml20">
                <!-- 美金价 -->
                <label>{{ $t('productDetail.USDprice') }}：</label>
                <span>{{ dollarPrice }}</span>
              </p>
              <p class="ml20" style="display: flex; align-items: center">
                <label style="color: red" class="mr20">
                  <!-- 最新汇率 -->
                  {{ $t('productDetail.LatestExchangeRate') }} ：{{ rate }}
                </label>
                <!-- 成本价 -->
                <el-form-item prop="newRate" label-width="0" style="margin-bottom: 0">
                  <el-input type="number" v-model="form.newRate" :placeholder="
                      $t('productDetail.PleaseEnterTheExchangeRate')
                    " maxlength="20" @focus="handleFocus('newRate')"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    style="width: 130px"></el-input>
                  <!-- 更换 -->
                  <el-button style="height: 32px" type="primary" size="mini" @click="changeRate">
                    {{ $t('productDetail.Change') }}
                  </el-button>
                </el-form-item>
              </p>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="border-bottom: 1px solid #ededed" class="mt15" v-if="isSkuSetProduct">
          <el-form ref="packForm" :model="packForm" :rules="packRules">
            <el-col :span="6">
              <!-- 装箱数量 -->
              <el-form-item :label="$t('productDetail.PackingQuantity')" prop="numberPerBox" label-width="150px"
                class="mr10">
                <el-input v-model="packForm.numberPerBox" type="number" :placeholder="$t('placeholder.plsInput')"
                  @input="changePackMsg(packForm.numberPerBox, 'numberPerBox')" @focus="handleFocusPack('numberPerBox')"
                  @blur="handleBlurPack('numberPerBox')"
                  oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!-- 毛重 -->
              <el-form-item :label="$t('productDetail.GrossWeight')" prop="boxWeightKgs" label-width="150px">
                <el-input style="width: 200px" v-model="packForm.boxWeightKgs" type="number"
                  :placeholder="$t('placeholder.plsInput')"
                  @input="changePackMsg(packForm.boxWeightKgs, 'boxWeightKgs')" @focus="handleFocusPack('boxWeightKgs')"
                  @blur="handleBlurPack('boxWeightKgs')"
                  oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
                <p class="t_a_r calcu-p">
                  LBS:
                  <span>
                    {{ ((packForm.boxWeightKgs || 0) * 2.2046).toFixed(1) }}
                  </span>
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="150px">
                <template slot="label">
                  <!-- 装箱尺寸 -->
                  <i style="color: #ff4d4f">*</i>
                  {{ $t('productDetail.PackingSize') }}
                </template>
                <el-row :gutter="10" type="flex">
                  <el-col :span="7">
                    <!-- 请输入长度  请输入>0的数值 -->
                    <el-form-item prop="boxLengthCm">
                      <!-- 长度 -->
                      <el-input v-model="packForm.boxLengthCm" type="number" :placeholder="$t('placeholder.M2008')"
                        @input="boxSize('Length')" @focus="handleFocusPack('boxLengthCm')"
                        @blur="handleBlurPack('boxLengthCm')"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                        <template slot="append">CM</template>
                      </el-input>
                      <p class="t_a_r calcu-p">
                        <span>{{ packForm.boxLengthIn }}</span>
                        IN
                      </p>
                    </el-form-item>
                  </el-col>
                  <span>x</span>
                  <el-col :span="7">
                    <!-- 请输入宽度 请输入>0的数值-->
                    <el-form-item prop="boxWidthCm">
                      <!-- 宽度 -->
                      <el-input v-model="packForm.boxWidthCm" type="number" :placeholder="$t('placeholder.M2009')"
                        @input="boxSize('Width')" @focus="handleFocusPack('boxWidthCm')"
                        @blur="handleBlurPack('boxWidthCm')"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                        <template slot="append">CM</template>
                      </el-input>
                      <p class="t_a_r calcu-p">
                        <span>{{ packForm.boxWidthIn }}</span>
                        IN
                      </p>
                    </el-form-item>
                  </el-col>
                  <span>x</span>
                  <el-col :span="7">
                    <!-- 请输入高度 请输入>0的数值-->
                    <el-form-item prop="boxHeightCm">
                      <el-input v-model="packForm.boxHeightCm" type="number" :placeholder="$t('placeholder.M2010')"
                        @input="boxSize('Height')" @focus="handleFocusPack('boxHeightCm')"
                        @blur="handleBlurPack('boxHeightCm')"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                        <template slot="append">CM</template>
                      </el-input>
                      <p class="t_a_r calcu-p">
                        <span>{{ packForm.boxHeightIn }}</span>
                        IN
                      </p>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>

        <!-- 阶梯-table -->
        <el-row :style="isSkuSetProduct ? 'transform: translateY(-26px)' : ''">
          <el-table v-loading="loading" :data="form.bcglXiangXiList" border stripe ref="dragTable"
            class="w100 mt15 table-height table-data" max-height="400">
            <!-- 数量 -->
            <el-table-column :label="$t('productDetail.Quantity')" prop="num" align="center" width="120" fixed="left"
              label-class-name="required-th">
              <template slot-scope="scope">
                <el-form-item :prop="'bcglXiangXiList.' + scope.$index + '.num'" label-width="0" :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]">
                  <!-- 整数数值 -->
                  <el-input type="number" v-model="scope.row.num" :placeholder="$t('placeholder.M2015')"
                    @change="handleChange(scope.row, 'num', scope.$index)"
                    @focus="handleTableFocus('num', scope.$index)"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}" />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 利润率 -->
            <el-table-column :label="$t('productDetail.ProfitRate')" prop="profitRate" align="center" width="120"
              label-class-name="required-th">
              <template slot-scope="scope">
                <el-form-item :prop="'bcglXiangXiList.' + scope.$index + '.profitRate'" label-width="0" :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]">
                  <el-input type="number" v-model="scope.row.profitRate" :placeholder="$t('productDetail.CNBGT')"
                    @change="
                      handleChange(scope.row, 'profitRate', scope.$index)
                    " @focus="handleTableFocus('profitRate', scope.$index)"
                    oninput="if(isNaN(value)) { value  = null }if(value>1) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 关税率 -->
            <el-table-column :label="$t('productDetail.TaxRate')" prop="taxRate" align="center" width="120"
              label-class-name="required-th">
              <template slot-scope="scope">
                <el-form-item :prop="'bcglXiangXiList.' + scope.$index + '.taxRate'" label-width="0" :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]">
                  <el-input type="number" v-model="scope.row.taxRate" :placeholder="$t('productDetail.CNBGT')"
                    @change="handleChange(scope.row, 'taxRate', scope.$index)"
                    @focus="handleTableFocus('taxRate', scope.$index)"
                    oninput="if(isNaN(value)) { value  = null }if(value>1) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 关税 -->
            <el-table-column align="center" prop="tax" width="120">
              <template slot="header">
                {{ $t('productDetail.Taxes') }}
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <!-- 成本价*税率÷汇率÷利润率÷2 -->
                    <p>
                      {{ $t('productDetail.CostPrice') }}*{{
                      $t('productDetail.TaxRate')
                      }}÷{{ $t('productDetail.ExchangeRate') }}÷{{
                      $t('productDetail.ProfitRate')
                      }}÷2
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.tax || '0' }}</span>
              </template>
            </el-table-column>
            <!-- 产品价 -->
            <el-table-column align="center" prop="price" width="130">
              <template slot="header">
                {{ $t('productDetail.ProductPrice') }}($)
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <!-- 成本÷汇率÷利润率+税费 -->
                    <p>
                      {{ $t('productDetail.CostPrice') }}÷{{
                      $t('productDetail.ExchangeRate')
                      }}÷{{ $t('productDetail.ProfitRate') }}+{{
                      $t('productDetail.Taxes')
                      }}
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.price" :placeholder="$t('placeholder.M2016')"
                  @change="handleChange(scope.row, 'price', scope.$index)"
                  @focus="handleTableFocus('price', scope.$index)"
                  oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
              </template>
            </el-table-column>
            <!-- 装箱总体积(m³) -->
            <el-table-column :label="$t('productDetail.TotalPackingVolume')" align="center" prop="totalPackingVolume"
              width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.totalPackingVolume || '0' }}</span>
              </template>
            </el-table-column>
            <!-- 装箱总重量(Kg) -->
            <el-table-column :label="$t('productDetail.TotalPackingWeight')" align="center" prop="totalPackingWeight"
              width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.totalPackingWeight || '0' }}</span>
              </template>
            </el-table-column>
            <!-- 运输类型 -->
            <el-table-column :label="$t('productDetail.ShippingMethod')" prop="freightSettingId" align="center"
              width="180" label-class-name="required-th">
              <template slot-scope="scope">
                <el-form-item :prop="'bcglXiangXiList.' + scope.$index + '.transportType'" label-width="0" :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsSel'),
                      trigger: 'change',
                    },
                  ]">
                  <el-select class="clearable" v-model="scope.row.transportType" :placeholder="$t('placeholder.plsSel')"
                    @change="
                      transportWayChange(
                        scope.row,
                        scope.row.freightForProductStepVOS,
                        scope.$index
                      )
                    ">
                    <el-option v-for="(item, index) in scope.row
                        .freightForProductStepVOS" :key="index" :label="
                        settings === 'zh'
                          ? item.typeAndPriceAppendCn
                          : item.typeAndPriceAppendEn
                      " :value="item.transportType" />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 运费单价 -->
            <el-table-column :label="$t('productDetail.FreightUnitPrice')" align="center" prop="transportPrice"
              width="120">
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.transportPrice" :placeholder="$t('placeholder.M2016')"
                  @input="transportPriceChange(scope.row, scope.$index)"
                  @focus="handleTableFocus('transportPrice', scope.$index)"
                  oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
              </template>
            </el-table-column>
            <!-- 运费总价 -->
            <el-table-column :label="$t('productDetail.TotalShippingPrice')" align="center" prop="totalTransportPrice"
              width="120">
              <template slot-scope="scope">
                <span>
                  {{
                  (
                  Number(scope.row.transportPrice) * Number(scope.row.num)
                  ).toFixed(3)
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="netPrice" width="120">
              <template slot="header">
                <p>Blank</p>
                Net Price($)
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <!-- 产品价+运费单价÷利润率 -->
                    <p>
                      {{ $t('productDetail.ProductPrice') }}+{{
                      $t('other.freight')
                      }}÷{{ $t('productDetail.ProfitRate') }}
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.netPrice || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="listPrice" width="120">
              <template slot="header">
                <p>Blank</p>
                List Price($)
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.listPrice || '0' }}</span>
              </template>
            </el-table-column>
            <!-- Net 总价($) -->
            <el-table-column align="center" prop="totalNetPrice" width="150">
              <template slot="header">
                <p>Blank</p>
                {{ $t('productDetail.NetTotalPrice') }}
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.totalNetPrice || '0' }}</span>
              </template>
            </el-table-column>
            <!-- List 总价($) -->
            <el-table-column align="center" prop="totalListPrice" width="150">
              <template slot="header">
                <p>Blank</p>
                {{ $t('productDetail.ListTotalPrice') }}
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.totalListPrice || '0' }}</span>
              </template>
            </el-table-column>
            <!-- 出运申报单价($) -->
            <el-table-column :label="$t('productDetail.ShippingDeclarationUnitPrice')" align="center"
              prop="shippingPrice" width="125">
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.shippingPrice" :placeholder="$t('placeholder.M2016')"
                  @focus="handleTableFocus('shippingPrice', scope.$index)"
                  oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
              </template>
            </el-table-column>
            <!-- 生产周期 -->
            <el-table-column :label="$t('productDetail.ProductionCycle')" prop="produceDay" align="center" width="100"
              fixed="right" label-class-name="required-th">
              <template slot-scope="scope">
                <el-form-item :prop="'bcglXiangXiList.' + scope.$index + '.produceDay'" label-width="0" :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]">
                  <el-input type="text" v-model.trim="scope.row.produceDay" :placeholder="$t('other.workingDay')"
                    @focus="handleTableFocus('produceDay', scope.$index)"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}" />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column :label="$t('productDetail.Operate')" align="center" width="80" fixed="right">
              <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-delete" size="mini" circle plain
                  @click="handleDeleteDetails(scope.$index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <el-row type="flex" justify="center" class="mt30">
          <!-- 新增数量价格阶梯 -->
          <el-button @click="handleAddDetails">
            {{ $t('productDetail.AddQuantityPriceLadder') }}
          </el-button>
        </el-row>
        <!-- 规格加价 -->
        <spec-extra-price v-if="isSingleSpecs" ref="specExtraPrice" :pageType="pageType" :specList="specList"
          :specTableList="specTableList" :bcglXiangXiList="form.bcglXiangXiList" :priceRate="form.priceRate"
          class="mt50"></spec-extra-price>

        <el-row class="mt30" style="display: flex">
          <!-- 支持打样 -->
          <el-form-item :label="$t('productDetail.SupportProofing')" class="mr20">
            <el-switch v-model="Sample"></el-switch>
          </el-form-item>
          <!-- 打样费单价 -->
          <el-form-item v-if="Sample" :label="$t('productDetail.UPOPF')" class="mr20" prop="proofingFee">
            <el-input v-model.trim="form.proofingFee" type="number" :placeholder="$t('placeholder.plsInput')"
              @focus="handleFocus('proofingFee')" @blur="handleBlur('proofingFee')"
              oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
              onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
          </el-form-item>
          <!-- 打样周期 -->
          <el-form-item v-if="Sample" :label="$t('productDetail.ProofingCycle')" prop="proofingDay">
            <div style="display: flex">
              <el-input type="number" :key="inputKey" v-model.number="form.proofingDay"
                :placeholder="$t('other.workingDay')" @focus="handleFocus('proofingDay')"
                @blur="handleBlur('proofingDay')"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}"></el-input>
              <label style="word-break: keep-all" for="">
                {{ $t('productDetail.WorkingDay') }}
              </label>
            </div>

            <!-- 工作日 -->
          </el-form-item>
        </el-row>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
  import { elFormErrorScrollIntoView } from 'kits'
  import { deepCopy } from 'kits'
  import { getDetailByType } from '@/api/product/productSetting'
  import { mapGetters } from 'vuex'
  import { ProductAddEditInteractor } from '@/core/interactors/product/productAddEdit'
  import specExtraPrice from './specExtraPrice'
  import _ from 'lodash'

  export default {
    name: 'ProValuation',
    components: { specExtraPrice },
    props: {
      editData: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        form: {
          calcFreight: 1,
          priceRate: 0, //默认0.6，取产品设置里面的价格规则
          freightWay: 0,
          proofingFee: 0,
          proofingDay: 0,
          bcglXiangXiList: [],
          costPrice: '',
          newRate: '',
        },
        rules: {
          costPrice: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],

          newRate: [
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              message: this.$t('rules.M2014'),
              trigger: 'blur',
            },
          ],
          // 打样费
          proofingFee: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          proofingDay: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        // 装箱信息
        packForm: {
          numberPerBox: '', // 装箱数量
          boxWeightKgs: '', // 毛重
          // 装箱尺寸
          boxLengthCm: '',
          boxLengthIn: '',
          boxWidthCm: '',
          boxWidthIn: '',
          boxHeightCm: '',
          boxHeightIn: '',
        },
        packRules: {
          numberPerBox: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              message: this.$t('rules.M2014'),
              trigger: 'blur',
            },
          ],
          boxWeightKgs: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxLengthCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxWidthCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxHeightCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        dollarPrice: '', //美金价
        calcFreightBol: true,
        loading: false,

        checkedDetail: [],
        tableForm: {},
        specTableData: [],
        taxResult: null, //税费
        setObj: {},
        stopWatch: false,
        editDataParams: {},
        productEditId: '',
        flag: true,
        freightWay: '', //计算运费方式
        rate: null, //汇率
        isTableDataLength: 0, //是否有多规格数据
        isSingleSpecDataLength: 0, //是否有单规格数据
        isSpecs: true, //默认多规格
        exchange: '', //最新汇率
        Sample: true,
        proPackMsg: null,
        // 1.8.0
        specList: [], // 规格类列表
        specTableList: [], // 规格值列表
        proMsgList: [], // 产品信息
        isChangeByProMsg: true, // 装箱信息 是否根据 产品信息联动
        pageType: '',
        isSkuSetProduct: false, // 按规格设置
        isSingleSpecs: true, // 单规格
        calcSet: '',
        inputKey: Math.random(),
        editIndex: 0,
        changePrice: true,
      }
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
    },
    watch: {
      'form.bcglXiangXiList': {
        immediate: true,
        deep: true,
        handler(value) {
          this.$Bus.$emit('bcglXiangXiList', value)
        },
      },
      specList: {
        handler() { },
        immediate: true,
        deep: true,
      },
    },
    created() {
      //获取产品价格规格
      this.getDetailByType8()
      // 规格设置信息
      this.getSpecMsg()
      // 产品信息
      this.getProMsg()
      // 获取汇率
      this.getCurrencyData()
      // 切换产品类别
      this.$Bus.$on('show-clear', () => {
        for (const key in this.packForm) {
          this.packForm[key] = ''
        }
        this.specList = []
        this.specTableList = []
        this.proMsgList = []
        this.form.bcglXiangXiList = []
      })
      // 是否开启多规格
      this.getSpecs()
      this.getSpecsOpen()
      //产品信息表单修改后触发
      this.$Bus.$on('msgForm', (val) => {
        if (!this.isSkuSetProduct) {
          this.changePrice = false
        }
        this.packForm.numberPerBox = val.numberPerBox
        this.packForm.boxWeightKgs = val.boxWeightKgs
        this.packForm.boxLengthCm = val.boxLengthCm
        this.packForm.boxWidthCm = val.boxWidthCm
        this.packForm.boxHeightCm = val.boxHeightCm
        this.packForm.boxLengthIn = val.boxLengthIn
        this.packForm.boxWidthIn = val.boxWidthIn
        this.packForm.boxHeightIn = val.boxHeightIn
        this.setBcgList()
      })

      this.$Bus.$on('batchSetMsg', (val) => {
        this.packForm.numberPerBox = val[0].numberPerBox
        this.packForm.boxWeightKgs = val[0].boxWeightKgs
        this.packForm.boxLengthCm = val[0].boxLengthCm
        this.packForm.boxWidthCm = val[0].boxWidthCm
        this.packForm.boxHeightCm = val[0].boxHeightCm
        this.packForm.boxLengthIn = val[0].boxLengthIn
        this.packForm.boxWidthIn = val[0].boxWidthIn
        this.packForm.boxHeightIn = val[0].boxHeightIn

        setTimeout(() => {
          this.setBcgList()
        }, 200)
      })
    },
    methods: {
      //产品汇率
      async getDetailByType8() {
        let response = await getDetailByType({ type: 8 })
        if (response.code === '000000' && response.data.length) {
          this.form.priceRate = Number(response.data[0].value)
        }
      },

      // 规格信息 规格加价
      getSpecMsg() {
        this.$Bus.$on('specSendBus', (val1, val2) => {
          this.pageType = ''
          if (val1) {
            this.specList = _.cloneDeep(val1)
          }

          if (val2) {
            this.specTableList = _.cloneDeep(val2)
          }
        })
      },

      // 产品信息列表修改后触发
      getProMsg() {
        this.$Bus.$on('specProMsg', (val) => {
          this.proMsgList = val
          if (
            (this.isChangeByProMsg && this.proMsgList?.length > 0) ||
            !this.isSkuSetProduct
          ) {
            this.packForm.numberPerBox = this.proMsgList[0].numberPerBox || ''
            this.packForm.boxWeightKgs = this.proMsgList[0].boxWeightKgs || ''
            this.packForm.boxLengthCm = this.proMsgList[0].boxLengthCm || ''
            this.packForm.boxWidthCm = this.proMsgList[0].boxWidthCm || ''
            this.packForm.boxHeightCm = this.proMsgList[0].boxHeightCm || ''

            this.packForm.boxLengthIn = this.proMsgList[0].boxLengthIn || ''
            this.packForm.boxWidthIn = this.proMsgList[0].boxWidthIn || ''
            this.packForm.boxHeightIn = this.proMsgList[0].boxHeightIn || ''
          }
          if (!this.isSkuSetProduct) {
            this.form.bcglXiangXiList.forEach((it) => {
              this.$set(it, 'boxVolumeM', 0)
              this.$set(it, 'boxWeightKgs', 0)
              this.$set(it, 'numberPerBox', 0)
              this.$set(it, 'totalPackingVolume', 0)
              this.$set(it, 'totalPackingWeight', 0)
            })
            for (const key in this.packForm) {
              this.packForm[key] = ''
            }

            if (this.packForm && Number(this.packForm.numberPerBox) != 0) {
              // 重新计算产品计价
              this.setBcgList()
            }
          }
        })
      },

      // 获取汇率
      getCurrencyData() {
        this.pubApi.getCurrency({ financeName: '美元' }).then((response) => {
          if (response.code === '000000') {
            this.rate = response.data
            this.exchange = this.exchange ? this.exchange : this.rate
            if (this.$route.query.str === 'add') {
              this.exchange = this.rate
            }
          }
        })
      },
      //产品信息 按规格设置 切换触发
      getSpecs() {
        this.$Bus.$on('sktSetProduct', (val, form, table) => {
          this.isSkuSetProduct = val

          // 设置装箱信息
          if (val && table && table.length > 0) {
            this.packForm.numberPerBox = table[0].numberPerBox || ''
            this.packForm.boxWeightKgs = table[0].boxWeightKgs || ''
            this.packForm.boxLengthCm = table[0].boxLengthCm || ''
            this.packForm.boxWidthCm = table[0].boxWidthCm || ''
            this.packForm.boxHeightCm = table[0].boxHeightCm || ''
            this.packForm.boxLengthIn = table[0].boxLengthIn || ''
            this.packForm.boxWidthIn = table[0].boxWidthIn || ''
            this.packForm.boxHeightIn = table[0].boxHeightIn || ''
          } else {
            this.packForm.numberPerBox = form.numberPerBox
            this.packForm.boxWeightKgs = form.boxWeightKgs
            this.packForm.boxLengthCm = form.boxLengthCm
            this.packForm.boxWidthCm = form.boxWidthCm
            this.packForm.boxHeightCm = form.boxHeightCm
            this.packForm.boxLengthIn = form.boxLengthIn
            this.packForm.boxWidthIn = form.boxWidthIn
            this.packForm.boxHeightIn = form.boxHeightIn
          }
          this.form.bcglXiangXiList.forEach((it) => {
            this.$set(it, 'boxVolumeM', 0)
            this.$set(it, 'boxWeightKgs', 0)
            this.$set(it, 'numberPerBox', 0)
            this.$set(it, 'totalPackingVolume', 0)
            this.$set(it, 'totalPackingWeight', 0)
          })
          //重新计算数量阶梯
          this.setBcgList()
        })
      },

      getSpecsOpen() {
        this.$Bus.$on('isSingleSpec', (val, type) => {
          this.isSingleSpecs = val
          // 关闭 装箱信息清空
          if (!this.isSingleSpecs) {
            this.specList = []
            this.specTableList = []
            if (type !== 'edit' || this.skuSettingForProduct) {
              for (const key in this.packForm) {
                this.packForm[key] = ''
              }
            }
            if (this.skuSettingForProduct) {
              this.form.bcglXiangXiList.forEach((it) => {
                this.$set(it, 'boxVolumeM', 0)
                this.$set(it, 'boxWeightKgs', 0)
                this.$set(it, 'numberPerBox', 0)
                this.$set(it, 'totalPackingVolume', 0)
                this.$set(it, 'totalPackingWeight', 0)
              })
            }
          }

          if (!val) {
            this.skuSettingForProduct = false
            this.isSkuSetProduct = false
          }
        })
      },

      // 按规格设置关闭时。产品信息会影响到产品计价
      setBcgList() {
        let packingVolume = Number(
          (
            Number(this.packForm.boxLengthCm) *
            Number(this.packForm.boxWidthCm) *
            Number(this.packForm.boxHeightCm) *
            0.000001
          ).toFixed(6)
        )
        let packingWeight = Number(this.packForm.boxWeightKgs)
        let numberPerBox = Number(this.packForm.numberPerBox)
        if (numberPerBox == 0) {
          return
        }

        this.form.bcglXiangXiList = this.form.bcglXiangXiList.map((it) => {
          return {
            ...it,
            packingVolume,
            packingWeight,
            numberPerBox,
          }
        })
        setTimeout(() => {
          this.calcTax()
        }, 200)
      },

      editShow(editData) {
        this.editDataParams = editData
        this.exchange = editData.exchange ? editData.exchange : this.rate
        this.productEditId = this.editDataParams.productId || ''

        // 有打样周期或者打样费的时候  打样开关开
        this.form.proofingDay = editData.proofingDay
        this.form.proofingFee = editData.proofingFee
        if (
          this.editDataParams.proofingDay ||
          this.editDataParams.proofingFee
        ) {
          this.Sample = true
        } else {
          this.Sample = false
        }

        //单规格还是多规格
        if (this.editDataParams.specsType) {
          this.isSpecs = true
        } else {
          this.isSpecs = false
        }
        if (!this.stopWatch) {
          this.$set(this.form, 'costPrice', this.editDataParams.costPrice)
          this.$set(this.form, 'priceRate', this.editDataParams.priceRate)
          this.$set(this.form, 'taxRate', this.editDataParams.taxRate)
          this.$set(this.form, 'overflowNum', this.editDataParams.overflowNum)

          if (this.editDataParams.calcFreight === 0) {
            this.calcFreightBol = false
          } else {
            this.calcFreightBol = true
          }
          this.$set(this.form, 'calcFreight', this.editDataParams.calcFreight)
          //保存本地
          if (this.editDataParams.productPricingStepDTOList) {
            this.form.bcglXiangXiList =
              this.editDataParams.productPricingStepDTOList
            this.$Bus.$emit('bcglXiangXiList', this.form.bcglXiangXiList)
            this.setObj = new Set()
            this.form.bcglXiangXiList.forEach((item) => {
              if (Number(item.numberPerBox) && Number(item.packingVolume)) {
                //单个装箱数量，单个装箱体积，毛重，产品重量，
                let calcu =
                  item.numberPerBox +
                  ',' +
                  item.packingVolume +
                  ',' +
                  item.packingWeight +
                  ',' +
                  item.productWeight
                item.item.item.this.setObj.add(calcu)
              }
            })
          }
          if (this.editDataParams.productPricingStepVOList) {
            this.form.bcglXiangXiList =
              this.editDataParams.productPricingStepVOList
            this.$Bus.$emit('bcglXiangXiList', this.form.bcglXiangXiList)
            this.setObj = new Set()
            this.form.bcglXiangXiList.forEach((item) => {
              if (Number(item.numberPerBox) && Number(item.packingVolume)) {
                //装箱数量，装箱体积，毛重
                let calcu =
                  item.numberPerBox +
                  ',' +
                  item.packingVolume +
                  ',' +
                  item.packingWeight +
                  ',' +
                  item.productWeight
                this.setObj.add(calcu)
              }
            })
          }
          this.getDollers()
        }

        this.isSkuSetProduct = editData.skuSettingForProduct

        // 设置 产品计价里的 装箱信息
        this.setPackForm()
        // 设置规格加价
        this.setExtraPrice(editData)
      },

      // 编辑回显 设置装箱信息
      setPackForm() {
        this.isChangeByProMsg = false
        this.changePrice = false
        let {
          numberPerBox,
          boxWeightKgs,
          boxLengthCm,
          boxWidthCm,
          boxHeightCm,
          boxLengthIn,
          boxWidthIn,
          boxHeightIn,
        } = this.editDataParams

        let obj = {
          numberPerBox,
          boxWeightKgs,
          boxLengthCm,
          boxWidthCm,
          boxHeightCm,
          boxLengthIn,
          boxWidthIn,
          boxHeightIn,
        }
        for (const key in obj) {
          if (obj[key] === 0) {
            // 老数据，默认都是0  装箱信息选择第一条数据
            let pack = this.editDataParams.productSpecsEditVOList
              ? this.editDataParams.productSpecsEditVOList[0]
              : {}
            this.packForm.numberPerBox = pack.numberPerBox
            this.packForm.boxWeightKgs = pack.boxWeightKgs
            this.packForm.boxLengthCm = pack.boxLengthCm
            this.packForm.boxWidthCm = pack.boxWidthCm
            this.packForm.boxHeightCm = pack.boxHeightCm
            this.packForm.boxLengthIn = pack.boxLengthIn
            this.packForm.boxWidthIn = pack.boxWidthIn
            this.packForm.boxHeightIn = pack.boxHeightIn
          } else {
            this.packForm = obj
          }
        }
      },

      // 编辑回显规格加价
      setExtraPrice(editData) {
        this.pageType = 'edit'
        let specList = editData.specsType.split(',')

        this.specList.forEach((it) => {
          this.$set(it, 'nameCn')
        })

        specList = specList.map((it, i) => {
          let arr = editData.productSpecsAddPriceVOList?.filter((ot) => {
            return ot.positionType == i
          })
          return {
            nameCn: it,
            disabled: true,
            value: arr,
          }
        })

        this.specList = specList
        this.specTableList = editData.productSpecsEditVOList
      },

      // 装箱信息修改
      changePackMsg(val, tag) {
        // 装箱信息修改后，装箱信息不跟随产品信息变化
        this.isChangeByProMsg = false
        this.changePrice = false
        this.setBcgList()
      },

      //运输方式change
      transportWayChange(row, list, $index) {
        //运输单价
        let transportPriceList = list.filter(
          (item) => item.transportType === row.transportType
        )
        this.$set(
          this.form.bcglXiangXiList[$index],
          'transportPrice',
          transportPriceList[0]?.transportPrice
        )
        this.$set(
          this.form.bcglXiangXiList[$index],
          'freightSettingId',
          transportPriceList[0]?.freightSettingId
        )
        //运输总价
        let totalTransportPrice = list.filter(
          (item) => item.transportType === row.transportType
        )[0].totalTransportPrice
        this.$set(
          this.form.bcglXiangXiList[$index],
          'totalTransportPrice',
          totalTransportPrice
        )

        this.transportPriceChange(row, $index, 'transportWayChange')
      },

      //运费单价修改
      transportPriceChange(row, $index, str) {
        //运费总价（单价*数量）

        if (!str) {
          let totalTransportPrice = (
            Number(row.transportPrice) * Number(row.num)
          ).toFixed(3)
          this.$set(
            this.form.bcglXiangXiList[$index],
            'totalTransportPrice',
            totalTransportPrice
          )
        }

        // 成本价/ 当前汇率 / 利润率 + 关税
        let price =
          Number(this.form.costPrice) / this.exchange / Number(row.profitRate) +
          Number(row.tax)
        //Net price 产品价+运费/利润率
        let netPrice = (
          Number(price) +
          Number(row.transportPrice) / Number(row.profitRate)
        ).toFixed(3)

        this.$set(this.form.bcglXiangXiList[$index], 'netPrice', netPrice)

        //List price(Net price/价格规则)
        let listPrice = (
          Number(netPrice) / Number(this.form.priceRate)
        ).toFixed(3)
        this.$set(this.form.bcglXiangXiList[$index], 'listPrice', listPrice)

        //netprice总价
        let totalNetPrice = (Number(netPrice) * Number(row.num)).toFixed(3)
        this.$set(
          this.form.bcglXiangXiList[$index],
          'totalNetPrice',
          totalNetPrice
        )

        //listprice总价
        let totalListPrice = (Number(listPrice) * Number(row.num)).toFixed(3)
        this.$set(
          this.form.bcglXiangXiList[$index],
          'totalListPrice',
          totalListPrice
        )
      },

      // 更换汇率
      changeRate() {
        if (this.form.newRate && Number(this.form.newRate)) {
          this.changePrice = true
          this.editIndex += 1
          this.exchange = this.form.newRate
          this.getDollers()
          this.form.newRate = ''
          this.calcTax()
        }
      },

      handleChangeCostPrice() {
        this.changePrice = true
        this.calcTax()
      },

      //改变数量、利润率、关税率
      async handleChange(row, type, $index) {
        // 利润率输入大于1 或是 0 或 不输入  都为1
        if (type === 'profitRate') {
          if (row.profitRate > 1 || row.profitRate == 0) {
            row.profitRate = 1
          }
        }
        if (type == 'profitRate' || type == 'taxRate') {
          this.changePrice = true
        } else {
          this.changePrice = false
        }

        this.calcTax($index)
      },

      //计算参数封装
      calcTax($index) {
        this.editIndex += 1
        if (this.form.bcglXiangXiList.length == 0) return
        // 数量和利润率同时未填写时，不计算运费价格
        let bcglXiangXiListNew = []
        if ($index === undefined) {
          bcglXiangXiListNew = this.form.bcglXiangXiList.filter(
            (item) => item.numberPerBox && item.profitRate
          )
        } else {
          bcglXiangXiListNew = this.form.bcglXiangXiList.filter(
            (item, index) =>
              $index == index && item.numberPerBox && item.profitRate
          )
        }

        if (bcglXiangXiListNew.length == 0) return
        let params = []
        bcglXiangXiListNew.forEach((item) => {
          // 计算相关参数
          this.setSetpTableNum(item)

          // 运费参数
          let obj = {
            costPrice: Number(this.form.costPrice),
            num: Number(item.num), //数量
            profitRate: Number(item.profitRate), //利润率
            tax: Number(item.tax), //关税费
            price: Number(item.price), //产品价
            boxVolumeM: Number(item.packingVolume), //装箱体积
            boxWeightKgs: Number(item.packingWeight), //装箱重量
            numberPerBox: Number(item.numberPerBox), //装箱数量
            transportMethod: item.freightSettingId || '', //运输方式
            exchange: this.exchange, //当前税率
            taxRate: Number(item.taxRate), //关税率
            priceRate: Number(this.form.priceRate), //价格规则
            transportPrice: Number(item.transportPrice), //运费单价
          }
          params.push(obj)
        })

        this.getMinDataList(params, $index)
      },

      // 输入框失焦 计算对应数量
      setSetpTableNum(item) {
        //关税=(成本价*关税率/当前汇率/利润率/2)
        let tax =
          (Number(this.form.costPrice) * Number(item.taxRate)) /
          this.exchange /
          Number(item.profitRate) /
          2
        this.$set(item, 'tax', tax.toFixed(3))
        //产品价(成本/当前汇率/利润率+税费)
        let price =
          Number(this.form.costPrice) /
          this.exchange /
          Number(item.profitRate) +
          Number(item.tax)

        if (this.changePrice) {
          this.$set(item, 'price', price.toFixed(3))
        }
        //装箱总体积（数量/装箱数量*单个装箱体积）
        let totalPackingVolume =
          (item.num / item.numberPerBox) * item.packingVolume
        this.$set(item, 'totalPackingVolume', totalPackingVolume.toFixed(3))

        //装箱总重量（数量/装箱数量*单个装箱重量）
        let totalPackingWeight =
          (item.num / item.numberPerBox) * item.packingWeight
        this.$set(item, 'totalPackingWeight', totalPackingWeight.toFixed(3))
      },

      //获取后台计算结果
      async getMinDataList(params, $index) {
        let response = await ProductAddEditInteractor.getProductStep(params)
        //产品价加税费
        if ((response.code = '000000')) {
          response.data?.forEach((item, i) => {
            //运费单价
            this.form.bcglXiangXiList[
              $index === undefined ? i : $index
            ].transportPrice = item.transportPrice

            //运费总价
            this.form.bcglXiangXiList[
              $index === undefined ? i : $index
            ].totalTransportPrice = item.totalTransportPrice

            //最优运输方式
            this.form.bcglXiangXiList[
              $index === undefined ? i : $index
            ].freightSettingId = item.freightSettingId

            //运输方式数组
            this.form.bcglXiangXiList[
              $index === undefined ? i : $index
            ].freightForProductStepVOS = item.freightForProductStepVOS

            // 成本价/ 当前汇率 / 利润率 + 关税
            let price =
              Number(this.form.costPrice) /
              this.exchange /
              Number(params[i].profitRate) +
              Number(params[i].tax)

            // netPrice =产品价+ 运费单价/利润率
            this.form.bcglXiangXiList[
              $index === undefined ? i : $index
            ].netPrice = (
              Number(
                this.form.bcglXiangXiList[$index === undefined ? i : $index]
                  .price
              ) +
              Number(
                this.form.bcglXiangXiList[$index === undefined ? i : $index]
                  .transportPrice
              ) /
              Number(
                this.form.bcglXiangXiList[$index === undefined ? i : $index]
                  .profitRate
              )
            ).toFixed(3)

            //listPrice(Net Price / 价格规则)
            this.form.bcglXiangXiList[
              $index === undefined ? i : $index
            ].listPrice = (
              Number(
                this.form.bcglXiangXiList[$index === undefined ? i : $index]
                  .netPrice
              ) / Number(this.form.priceRate)
            ).toFixed(3)

            //netPrice总价（Net Price * 数量）
            this.form.bcglXiangXiList[
              $index === undefined ? i : $index
            ].totalNetPrice = (
              Number(
                this.form.bcglXiangXiList[$index === undefined ? i : $index]
                  .netPrice
              ) * Number(params[i].num)
            ).toFixed(3)

            //listPrice总价(List Price * 数量)
            this.form.bcglXiangXiList[
              $index === undefined ? i : $index
            ].totalListPrice = (
              Number(
                this.form.bcglXiangXiList[$index === undefined ? i : $index]
                  .listPrice
              ) * Number(params[i].num)
            ).toFixed(3)
          })
        }
      },

      //新增一行
      handleAddDetails() {
        // 验证装箱信息
        for (const key in this.packForm) {
          if (!this.packForm[key]) {
            // 装箱信息不完整
            this.$message.warning(
              this.$t('productDetail.IncompletePackingInformation')
            )
            return
          }
        }
        // 装箱体积
        let packingVolume =
          Number(this.packForm.boxLengthCm) *
          Number(this.packForm.boxWidthCm) *
          Number(this.packForm.boxHeightCm) *
          0.000001
        let obj = {
          num: 0, //数量
          profitRate: 0, // 利润率
          taxRate: 0, // 关税率
          tax: 0, //关税
          price: 0, //产品价
          totalPackingVolume: 0, //装箱总体积
          totalPackingWeight: 0, //装箱总重量
          packingVolume: packingVolume.toFixed(3), //装箱体积
          packingWeight: this.packForm.boxWeightKgs, //毛重
          numberPerBox: this.packForm.numberPerBox, //装箱数量(不显示在页面上)
          freightSettingId: '', // 运输方式
          freightForProductStepVOS: [], //运输方式下拉
          transportPrice: 0, //运费单价
          totalTransportPrice: 0, //运费总价
          netPrice: 0,
          listPrice: 0,
          totalNetPrice: 0, //Net 总价
          totalListPrice: 0, //List 总价
          shippingPrice: 0, //出运申报单价
          produceDay: '', //生产周期
          productId: this.productEditId || '',
        }
        this.form.bcglXiangXiList.push(obj)
      },

      handleDeleteDetails(index) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          this.form.bcglXiangXiList.splice(index, 1)
          this.$Bus.$emit('proValuationNum', this.form.bcglXiangXiList)
        })
      },

      // 验证
      validateField() {
        let success = false
        let validFlag = false
        let tableFlag = true
        let validPackFlag = false
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            validFlag = true
          } else {
            success = false
          }
        })
        if (this.isSkuSetProduct) {
          this.$refs.packForm.validate(async (valid) => {
            if (valid) {
              validPackFlag = true
            } else {
              success = false
            }
          })
        } else {
          validPackFlag = true
        }
        if (validFlag && validPackFlag) {
          if (this.form.bcglXiangXiList.length) {
            tableFlag = true
          } else {
            tableFlag = false
            // 产品计价列表为空，请先完善规格列表信息
            this.$baseMessage(
              this.$t('productDetail.pricingTips'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
            // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.pro-valuation')
            })
          }
        } else {
          // 请完善产品计价信息
          this.$baseMessage(
            this.$t('productDetail.proValueTips'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
          this.$nextTick(() => {
            elFormErrorScrollIntoView('.el-form-item__error')
          })
        }
        if (this.Sample == 0) {
          this.form.proofingFee = 0
          this.form.proofingDay = 0
        }
        if (tableFlag && validFlag && validPackFlag) {
          success = true
        } else {
          success = false
        }
        if (success) {
          this.form.costPrice = Number(this.form.costPrice)
          this.form.priceRate = Number(this.form.priceRate)
          this.packForm.numberPerBox = Number(this.packForm.numberPerBox)
          this.packForm.boxWeightKgs = Number(this.packForm.boxWeightKgs)
          this.packForm.boxLengthCm = Number(this.packForm.boxLengthCm)
          this.packForm.boxWidthCm = Number(this.packForm.boxWidthCm)
          this.packForm.boxHeightCm = Number(this.packForm.boxHeightCm)
          this.packForm.boxLengthIn = Number(this.packForm.boxLengthIn)
          this.packForm.boxWidthIn = Number(this.packForm.boxWidthIn)
          this.packForm.boxHeightIn = Number(this.packForm.boxHeightIn)
          const form = deepCopy(this.form)
          const bcglXiangXiList = deepCopy(this.form.bcglXiangXiList)

          bcglXiangXiList.forEach((item) => {
            item.listPrice = Number(item.listPrice)
            item.price = Number(item.price)
            item.tax = Number(item.tax)
            item.shippingPrice = Number(item.shippingPrice)
            item.produceDay = item.produceDay ? Number(item.produceDay) : ''
            item.netPrice = Number(item.netPrice)
            item.numberPerBox = Number(item.numberPerBox)
            item.packingVolume = Number(item.packingVolume)
            item.packingWeight = Number(item.packingWeight)
            item.totalListPrice = Number(item.totalListPrice)
            item.totalNetPrice = Number(item.totalNetPrice)
            item.totalPackingVolume = Number(item.totalPackingVolume)
            item.totalPackingWeight = Number(item.totalPackingWeight)
            item.productId = this.productEditId || ''
            item.totalTransportPrice = (
              Number(item.transportPrice) * Number(item.num)
            ).toFixed(3)
          })

          return {
            success: true,
            valuationParams: form,
            productPricingStepDTOList: bcglXiangXiList,
            proofingDay: this.form.proofingDay,
            proofingFee: this.form.proofingFee,
            exchange: this.exchange, //汇率,
            ...this.packForm,
            productSpecsAddPriceDTOList: this.getExtraPrice(),
          }
        } else {
          return {
            success: false,
          }
        }
      },

      // 保存到本地
      validateField2() {
        this.form.costPrice = Number(this.form.costPrice)
        this.form.priceRate = Number(this.form.priceRate)
        this.packForm.numberPerBox = Number(this.packForm.numberPerBox)
        this.packForm.boxWeightKgs = Number(this.packForm.boxWeightKgs)
        this.packForm.boxLengthCm = Number(this.packForm.boxLengthCm)
        this.packForm.boxWidthCm = Number(this.packForm.boxWidthCm)
        this.packForm.boxHeightCm = Number(this.packForm.boxHeightCm)
        this.packForm.boxLengthIn = Number(this.packForm.boxLengthIn)
        this.packForm.boxWidthIn = Number(this.packForm.boxWidthIn)
        this.packForm.boxHeightIn = Number(this.packForm.boxHeightIn)
        const form = deepCopy(this.form)
        const bcglXiangXiList = deepCopy(this.form.bcglXiangXiList)

        bcglXiangXiList.forEach((item) => {
          item.listPrice = Number(item.listPrice)
          item.price = Number(item.price)
          item.tax = Number(item.tax)
          item.taxRate = Number(item.taxRate)
          item.shippingPrice = Number(item.shippingPrice)
          item.produceDay = item.produceDay ? Number(item.produceDay) : ''
          item.netPrice = Number(item.netPrice)
          item.numberPerBox = Number(item.numberPerBox)
          item.packingVolume = Number(item.packingVolume)
          item.packingWeight = Number(item.packingWeight)
          item.totalListPrice = Number(item.totalListPrice)
          item.totalNetPrice = Number(item.totalNetPrice)
          item.totalPackingVolume = Number(item.totalPackingVolume)
          item.totalPackingWeight = Number(item.totalPackingWeight)
          item.productId = this.productEditId || ''
          item.totalTransportPrice = (
            Number(item.transportPrice) * Number(item.num)
          ).toFixed(3)
        })
        if (this.Sample == 0) {
          this.form.proofingFee = 0
          this.form.proofingDay = 0
        }

        return {
          valuationParams: form,
          productPricingStepDTOList: bcglXiangXiList,
          proofingDay: this.form.proofingDay,
          proofingFee: this.form.proofingFee,
          exchange: this.exchange, //汇率,
          ...this.packForm,
          productSpecsAddPriceDTOList: this.getExtraPrice(),
        }
      },

      getExtraPrice() {
        let data = this.$refs.specExtraPrice?.specTable
        let arr = []
        data?.forEach((it, i) => {
          it.value.forEach((ot) => {
            let obj = {
              productSpecsAddPriceId: ot.productSpecsAddPriceId,
              basePricesSetting: ot.basePricesSetting,
              positionType: i,
              specsName: it.nameCn,
              specsNameEn: '',
              specsPrice: ot.specsPrice,
              specsValue: ot.specsValue,
              specsValueEn: '',
            }
            arr.push(obj)
          })
        })
        return arr
      },

      // 换算尺寸IN
      boxSize(type) {
        this.isChangeByProMsg = false
        this.changePrice = false
        this.packForm[`box${type}In`] = (
          this.packForm[`box${type}Cm`] * 0.3937
        ).toFixed(2)
        // if (!this.packForm[`box${type}Cm`]) {
        //   this.form.bcglXiangXiList.forEach((item) => {
        //     this.$set(item, 'totalPackingVolume', 0)
        //   })
        // }
        this.setBcgList()
      },

      // 计算美金价
      getDollers() {
        this.dollarPrice = (this.form.costPrice / this.exchange).toFixed(2)
      },

      handleFocus(name) {
        if (this.form[name] == 0) {
          this.$set(this.form, name, '')
        }
      },

      handleTableFocus(name, $index) {
        if (this.form.bcglXiangXiList[$index][name] == 0) {
          this.$set(this.form.bcglXiangXiList[$index], name, '')
        }
      },

      handleBlur(name) { },

      handleFocusPack(name) {
        if (this.packForm[name] == 0) {
          this.$set(this.packForm, name, '')
        }
      },

      handleBlurPack(name) { },
    },
    beforeDestroy() {
      this.$Bus.$off('packBoxMsg')
      this.$Bus.$off('specProMsg')
      this.$Bus.$off('specSendBus')
      this.$Bus.$off('show-clear')
      this.$Bus.$off('sktSetProduct')
      this.$Bus.$off('isSingleSpec')
      this.$Bus.$off('msgForm')
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;

      .calcu {
        margin-left: 110px;

        p {
          label {
            font-size: 12px;
            color: #999;
          }
        }
      }

      .num-price-step {
        ::v-deep {
          .el-form-item__label {
            font-size: 16px;
            margin-right: 20px;
          }
        }
      }
    }

    ::v-deep {
      .inputClass {
        border-radius: 2.5px;
        color: #606266;
      }

      .inputClass:focus {
        border-color: #1890ff !important;
      }

      .el-table {
        .el-table__body td {
          height: 86px;

          .el-form-item {
            margin: 18px 0;
          }
        }
      }
    }
  }

  .calcu-p {
    font-size: 12px;
    color: #999;
    line-height: 32px;
    transform: translateX(-10px);
  }
</style>