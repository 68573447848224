<template>
  <div class="search_form">
    <el-form ref="form" :model="search" :rules="rules" label-position="top">
      <!-- 创建人 -->
      <el-form-item :label="$t('cusCenter.Creator')">
        <el-input
          v-if="lang === 'en'"
          v-model="search.creatorEn"
          :placeholder="$t('placeholder.plsSel')"
          @focus="userSel('creator')"
        ></el-input>
        <el-input
          v-else
          v-model="search.creator"
          :placeholder="$t('placeholder.plsSel')"
          @focus="userSel('creator')"
        ></el-input>
      </el-form-item>

      <!-- 客户代表 -->
      <el-form-item :label="$t('cusCenter.CustomerRepresentative')">
        <el-input
          v-if="lang === 'en'"
          :placeholder="$t('placeholder.plsSel')"
          @focus="userSel('salesman')"
          v-model="search.salesmanEn"
        ></el-input>
        <el-input
          v-else
          :placeholder="$t('placeholder.plsSel')"
          @focus="userSel('salesman')"
          v-model="search.salesman"
        ></el-input>
      </el-form-item>

      <!-- 状态 -->
      <el-form-item :label="$t('cusCenter.Status')">
        <el-select
          clearable
          v-model="search.status"
          :placeholder="$t('placeholder.plsSel')"
        >
          <el-option
            v-for="item in statusList"
            :key="item.code"
            :label="lang === 'en' ? item.nameEn : item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 客户公司 -->
      <el-form-item :label="$t('cusCenter.CustomerCompany')">
        <el-input
          :placeholder="$t('placeholder.plsInput')"
          v-model="search.companyName"
        ></el-input>
      </el-form-item>

      <!-- 客户邮箱 -->
      <el-form-item :label="$t('cusCenter.CustomerMailbox')">
        <el-input
          :placeholder="$t('placeholder.plsInput')"
          v-model="search.releatedCustomerEmail"
        ></el-input>
      </el-form-item>

      <!-- 销售订单号 -->
      <el-form-item :label="$t('cusCenter.SalesOrder')">
        <el-input
          :placeholder="$t('placeholder.plsInput')"
          v-model="search.orderNumber"
        ></el-input>
      </el-form-item>

      <!-- 客户订单号(PO) -->
      <el-form-item :label="$t('cusCenter.CustomerOrderNumber')">
        <el-input
          :placeholder="$t('placeholder.plsInput')"
          v-model="search.customerOrderCode"
        ></el-input>
      </el-form-item>

      <!-- 客评单号 -->
      <el-form-item :label="$t('cusCenter.No')">
        <el-input
          :placeholder="$t('placeholder.plsInput')"
          v-model="search.evaluationCode"
        ></el-input>
      </el-form-item>

      <div class="btn">
        <!-- 确定 -->
        <el-button type="primary" @click="searchClick">
          {{ $t('inquiryList.Confirm') }}
        </el-button>
        <!-- 重置 -->
        <el-button @click="resetClick">
          {{ $t('inquiryList.Reset') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { statusList } from '@/views/customer-manage/helper/data'
import { orderGetDict } from '@/api/order'
import { mapGetters } from 'vuex'
export default {
  name: 'inquirySearch',
  data() {
    return {
      statusList,
      search: {
        creator: '', // 创建人
        creatorEn: '', // 创建人英文
        creatorId: '', // 创建人Id
        dateType: '', // 查询的日期类型
        date: '', // 选择的日期
        salesman: '', // 客户代表
        salesmanEn: '', // 客户代表英文
        salesmanId: '', // 客户代表Id
        status: '', // 状态
        companyName: '', // 客户公司
        releatedCustomerEmail: '', // 客户邮箱
        orderNumber: '', // 销售订单
        customerOrderCode: '', // 客户订单
        evaluationCode: '', // 客评编号
      },
      daterange: [],
      rules: {},
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  created() {
    this.getDict()
  },
  watch: {
    daterange(dateArray) {
      if (dateArray) {
        this.form.startSeasTime = dateArray[0]
        this.form.endSeasTime = dateArray[1]
      } else {
        this.form.startSeasTime = ''
        this.form.endSeasTime = ''
      }
    },
  },
  methods: {
    getDict() {
      orderGetDict({ amountType: 2 }).then((res) => {
        this.enquirySourceNameList = res.data
      })
    },

    // 选择申请人
    userSel(userType) {
      const id = this.search[userType + 'Id'],
        name = this.search[userType],
        englishName = this.search[userType + 'En']

      this.$userChoose(this)
        .showPromisedUserAddEdit({
          str: '',
          roleId: id
            ? [
                {
                  id,
                  name,
                  englishName,
                },
              ]
            : '',
          canSelectZeroUser: false,
          singleSelect: true,
        })
        .then((users) => {
          if (Array.isArray(users) && users.length > 0) {
            const { id, name, englishName } = users[0]
            if (this.search[userType + 'Id'] === id) {
              return
            }
            this.search[userType] = name
            this.search[userType + 'En'] = englishName
            this.search[userType + 'Id'] = id
          }
        })
    },

    searchClick() {
      this.$emit('highSearch', this.search)
      // this.resetClick()
    },

    resetClick() {
      this.$refs.form.resetFields()
      this.search = this.$options.data().search
    },
  },
}
</script>

<style lang="scss" scoped>
.search_form {
  padding: 0 20px 20px 20px;
  .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  .btn {
    padding: 20px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>
