/**
 * 自定义列操作
 *
 */
import { getUserColumn, addUserColumn, updateUserColumn } from '@/api/public'

//获取自定义保存
export async function getColums(menuType, userId) {
  let getColums = {}
  let response = await getUserColumn({ menuType: menuType, userId: userId })
  if (
    response.code === '000000' &&
    response.data &&
    response.data.customizeData
  ) {
    getColums.userCustomizeColumnId = response.data.userCustomizeColumnId
    getColums.columns = JSON.parse(response.data.customizeData)
  }
  return getColums
}

//新增修改自定义保存
export async function operateColums(params) {
  let response = ''
  if (!params.userCustomizeColumnId) {
    response = await addUserColumn(params)
  } else {
    response = await updateUserColumn(params)
  }
  return response
}
