<template>
  <div>
    <el-dialog
      title="成本计算"
      :visible.sync="costVisible"
      width="800px"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form">
        <el-form-item label-width="140px">
          <template slot="label">
            <span>是否分仓核算：</span>
            <Qtip
              content="启用后将商品的成本将根据各分仓核算, 且可以以仓库的维度设置出库模式和成本计价方式；未启用根据总仓核算，且所有仓库遵循一种出库模式和成本计价方式；"
              effect="dark"
            />
          </template>

          <el-radio-group v-model="form.accountRule" @change="changeType">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-table width="100%" border :data="form.stockCostConfigureVOS">
          <el-table-column
            show-overflow-tooltip
            align="center"
            label="仓库名称"
            prop="warehouseName"
            width="120px"
            v-if="form.accountRule == 1"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            label-class-name="required-th"
          >
            <template slot="header">
              <span style="margin-right: 5px">出库模式</span>
              <Qtip
                content="先进先出：是指先采购进仓库的商品在销售的时候优先出库；指定批次：是指每次销售出库时手动选择采购入库的商品"
                effect="dark"
              />
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'stockCostConfigureVOS.' + scope.$index + '.warehouseOutType'
                "
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'change',
                  },
                ]"
              >
                <el-select
                  v-model="scope.row.warehouseOutType"
                  placeholder="请选择"
                  @change="typeChange(scope, 'warehouseOutType')"
                >
                  <el-option
                    v-for="item in checkoutOption"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            label-class-name="required-th"
          >
            <template slot="header">
              <span style="margin-right: 5px">入库批次可修改</span>
              <Qtip
                content="选择是：系统推荐入库批次后，可手动修改；选择否：系统推荐入库批次后，不可更改"
                effect="dark"
              />
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'stockCostConfigureVOS.' + scope.$index + '.warehouseInBatch'
                "
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'change',
                  },
                ]"
              >
                <el-select
                  v-model="scope.row.warehouseInBatch"
                  placeholder="请选择"
                  @change="typeChange(scope, 'warehouseInBatch')"
                  :disabled="scope.row.warehouseOutType == 2"
                >
                  <el-option
                    v-for="item in alterOption"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            label-class-name="required-th"
          >
            <template slot="header">
              <span style="margin-right: 5px">计价方式</span>
              <Qtip
                content="批次计价法：出库单价取各批次入库单价；移动加权：入库根据当前库存成本和库存数量计算平均单价"
                effect="dark"
              />
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'stockCostConfigureVOS.' + scope.$index + '.priceType'"
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'change',
                  },
                ]"
              >
                <el-select
                  v-model="scope.row.priceType"
                  placeholder="请选择"
                  @change="typeChange(scope, 'priceType')"
                  @focus="saveOldVal(scope)"
                >
                  <el-option
                    v-for="item in valuationOption"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <type-change-dialog
      :visible.sync="tipVisible"
      :msgType="msgType"
      @checkTrue="checkTrue"
      @checkFalse="checkFalse"
    ></type-change-dialog>
  </div>
</template>
<script>
  import Qtip from '@/components/base-question-tip'
  import typeChangeDialog from './typeChangeDialog.vue'
  import { checkoutType, valuationType, alter } from '../helper/index'
  import { WarehouseSettingInteractor } from '@/core'
  import _ from 'lodash'
  export default {
    name: 'cost-calculation',
    components: { Qtip, typeChangeDialog },
    props: {
      costConfig: {
        type: Object,
        default: () => {},
      },
      costVisible: {
        type: Boolean,
        default: false,
      },
      costConfigureId: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        // 默认： 批次，可修改，批次计价 ：
        form: {
          accountRule: 1, // 1 分仓，2 总仓
        },
        // tableData: [{ name: '1' }],
        checkoutOption: checkoutType,
        valuationOption: valuationType,
        alterOption: alter,
        tipVisible: false,
        currentIndex: 0,
        currentType: '',
        msgType: 0, // 提示窗内容
        oldVal: 0,
      }
    },
    watch: {
      costVisible(newval, oldval) {
        if (newval) {
          this.form = _.cloneDeep(this.costConfig)
        }
      },
    },
    methods: {
      typeChange(scope, type) {
        this.currentIndex = scope.$index
        this.currentType = type
        if (type === 'priceType') {
          this.msgType = scope.row.priceType == 1 ? 3 : 4
          this.tipVisible = true
        }
        if (type == 'warehouseOutType' && scope.row.warehouseOutType == 2) {
          scope.row.warehouseInBatch = 1
        }
      },
      saveOldVal(scope) {
        this.oldVal = scope.row.priceType
      },

      handleClose() {
        this.$emit('update:costVisible', false)
      },
      cancel() {
        this.$emit('update:costVisible', false)
      },
      submit() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            let res = await WarehouseSettingInteractor.setCostConfigurePO(
              this.form,
              this.costConfigureId
            )
            if (res?.code === '000000') {
              this.$message.success('操作成功！')
              this.$emit('update:costVisible', false)
              this.$emit('refresh')
            }
          }
        })
      },
      // 弹窗点击取消
      checkFalse() {
        if (this.msgType == 1 || this.msgType == 2) {
          this.form.accountRule = this.msgType == 1 ? 2 : 1
        } else {
          // this.$set(
          //   this.form.stockCostConfigureVOS[this.currentIndex],
          //   this.currentType,
          //   null
          // )
          this.$set(
            this.form.stockCostConfigureVOS[this.currentIndex],
            this.currentType,
            this.oldVal
          )
        }
      },
      // 弹窗点击确定
      checkTrue() {
        if (this.msgType == 1 || this.msgType == 2) {
          this.getWareList()
        }
      },
      // 分仓切换
      changeType(type) {
        this.msgType = type
        this.tipVisible = true
      },
      async getWareList() {
        this.form.stockCostConfigureVOS = []
        this.form = await WarehouseSettingInteractor.getConfigureDetail(
          this.msgType
        )
        if (
          !this.form.stockCostConfigureVOS ||
          this.form.stockCostConfigureVOS.length == 0
        ) {
          this.form.stockCostConfigureVOS = []
          this.form.stockCostConfigureVOS.push({
            warehouseOutType: 1,
            warehouseInBatch: 1,
            priceType: 1,
          })
        }
        this.form.accountRule = this.msgType
      },
    },
  }
</script>
