/**
 * 询盘管理列表
 */
import request from '@/core/services/request'

// 报价提醒保存
export function orderRemindSave(data) {
  return request({
    url: '/order/orderRemind/insertPO',
    method: 'post',
    data,
  })
}

// 报价提醒获取
export function orderRemindDetail() {
  return request({
    url: '/order/orderRemind/getDetail',
    method: 'post',
  })
}

// 根据分页查找列表
export function orderEnquiryList(data) {
  return request({
    url: '/order/orderEnquiry/listPage',
    method: 'post',
    data,
  })
}

// 询盘操作
export function orderEnquiryStatus(data) {
  return request({
    url: '/order/orderEnquiry/updateStatus',
    method: 'post',
    data,
  })
}

// 分配业务员
export function updateSale(data) {
  return request({
    url: '/order/orderEnquiry/updateSale',
    method: 'post',
    data,
  })
}

// 询盘单查询销售订单数据
export function enquiryProduceOrder(data) {
  return request({
    url: '/order/orderEnquiry/enquiryProduceOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 删除询盘列表
export function deletePO(data) {
  return request({
    url: '/order/orderEnquiry/deletePO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}


/**
 * 询盘导出
 */

export function selectAllExcel(data = {}) {
  return request({
    url: '/order/orderEnquiry/selectAllExcel',
    method: 'post',
    data,
    responseType: 'blob',

  })
}
