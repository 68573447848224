<template>
  <el-dialog title="添加人员" :visible.sync="dialogFormVisible" width="900px" @close="close" :close-on-click-modal="false">
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="姓名" prop="userName">
              <el-input v-model.trim="form.userName" placeholder="请输入" maxlength="50" />
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="英文名" prop="englishName">
              <el-input v-model.trim="form.englishName" placeholder="请输入" maxlength="50" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="手机号码" prop="telephone">
              <el-input v-model.trim="form.telephone" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model.trim="form.email" placeholder="请输入" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="所属组织" prop="deptId">
              <el-cascader v-model="deptId" :options="treeData" :show-all-levels="true" :props="optionProps"
                class="w100"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="角色" prop="roleId">
              <el-cascader :options="treeData2" v-model="form.roleId" :props="optionProps2" :show-all-levels="false"
                class="w100"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="职位" prop="postType">
              <el-select v-model="form.postType" placeholder="请选择">
                <el-option v-for="(item, index) of jobList" :key="index" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>

    <!-- 短信验证提示 -->
    <sms-hint ref="sms" :title="'账号创建成功'" :phone="form.telephone" :password="form.password"
      @close="handleClose"></sms-hint>
  </el-dialog>
</template>

<script>
  import { systemInteractor } from '@/core'
  import PasswordCheck from '@/components/password-check/index'
  import { userInsertPO } from '@/api/organization-user'
  import { generatePassword } from '@/utils/utils'
  import SmsHint from './sms-hint.vue'
  export default {
    name: 'PersonAddModel',
    components: { PasswordCheck, SmsHint },
    data() {
      return {
        form: {
          userName: '',
          englishName: '',
          telephone: '',
          email: '',
          status: 1,
          roleId: '',
          postType: '',
          password: 'wewo1234',
        },
        rules: {
          userName: [
            { required: true, trigger: 'blur', message: '请输入姓名' },
          ],
          englishName: [
            { required: true, trigger: 'blur', message: '请输入英文姓名' },
          ],
          telephone: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入正确手机号码',
              min: 11,
            },
          ],
          email: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入正确邮箱',
            },
            {
              validator: this.$formValidation.isEmail,
              trigger: 'blur',
            },
          ],
          roleId: [{ required: true, trigger: 'blur', message: '请选择角色' }],
          postType: [
            { required: true, trigger: 'blur', message: '请选择职位' },
          ],
          password: [
            {
              required: true,
              trigger: ['blur', 'change'],
              message: '请输入密码',
            },
          ],
        },
        disabled: false, // 校验密码强度
        dialogFormVisible: false,
        value: null,
        treeData: [
          //组织
        ],
        treeData2: [
          //角色
        ],
        jobList: [], //职位下拉
        optionProps: {
          value: 'deptId',
          label: 'name',
          children: 'children',
          checkStrictly: true,
        },
        optionProps2: {
          value: 'roleId',
          label: 'name',
          children: 'children',
        },
        deptId: 2, //所属组织
        jiaose: '', //角色
      }
    },
    created() { },
    methods: {
      textareablur(val) {
        if (!val) return
        let reg = new RegExp('^[A-Z0-9][A-z0-9]*$')
        if (!reg.test(val)) {
          this.form.englishName = val
            .replace(/[^\sA-Za-z0-9]+/g, '')
            .replace(/\b\w+\b/g, (word) => {
              return word.substring(0, 1).toUpperCase() + word.substring(1)
            })
        }
      },
      //新增
      showAddEdit(deptId) {
        this.deptId = deptId
        this.dialogFormVisible = true
        //获取组织
        this.pubApi.getListDepartmrnt().then((response) => {
          if (response.code === '000000') {
            this.treeData = response.data
          }
        })
        //获取角色
        this.pubApi.getRolelistPaged().then((response) => {
          if (response.code === '000000') {
            let treeData2 = response.data
            let i = treeData2.length
            while (i--) {
              if (treeData2[i].roleType === 1 && treeData2[i].children) {
                treeData2[i].children.forEach((item3, index3) => {
                  if (item3.name === '超级管理员' && item3.adminRole === 1) {
                    treeData2[i].children.splice(index3, 1)
                  }
                })
              }
              if (treeData2[i].adminRole === 0 && !treeData2[i].children) {
                treeData2.splice(i, 1)
              }
            }
            this.treeData2 = treeData2
          }
        })
        //获取职位下拉
        this.pubApi.getJobList().then((response) => {
          if (response.code === '000000') {
            this.jobList = response.data
          }
        })
      },

      // 密码是否通过
      isPass(bool) {
        this.disabled = bool
      },



      //关闭
      close() {
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },

      //保存
      save() {
        let flag = Array.isArray(this.deptId)

        if (flag) {
          this.form.deptId = [...this.deptId].pop()
        } else {
          this.form.deptId = this.deptId
        }
        if (Array.isArray(this.form.roleId)) {
          this.form.roleId = this.form.roleId.pop()
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            // if (!this.disabled) {
            //   return this.$message.warning('密码强度不够')
            // }

            let form = this.utils.deepCopy(this.form)
            let response = await userInsertPO(form)
            if (response.code === '000000') {
              // this.smsHintEvent()
              this.$message.success('添加人员成功！')
              this.$emit('fetch-data')
              this.close()
            }
          }
        })
      },

      // 短信通知
      async smsHintEvent() {
        const params = {
          phone: this.form.telephone,
          userName: this.form.userName,
          password: this.form.password,
        }

        const res = await systemInteractor.addUserSendSms(params)
        if (res && res.code === '000000') {
          this.$refs.sms.init(res?.data)
        }
      },

      // 关闭弹窗
      handleClose() {
        this.$emit('fetch-data')
        this.close()
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }

    .box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      line-height: 32px;

      i {
        font-size: 16px;
        cursor: pointer;
        margin-left: 3px;
      }
    }

    p {
      text-indent: 80px;
      color: #e6a341;
    }

    .grade {
      margin-left: 80px;
      width: 300px;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }

    .box {
      .el-input {
        width: 300px;
      }
    }
  }
</style>