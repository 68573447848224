<template>
  <el-select
    :key="lang"
    ref="sel"
    v-model="cnaps"
    v-loadmore:el-select-dropdown__list="loadMore"
    popper-class="cnps-sel-select"
    value-key="cnapsCode"
    filterable
    remote
    placeholder="请选择"
    :remote-method="remoteMethod"
    :loading="loading"
    clearable
    :disabled="disabled"
    @focus="() => remoteMethod('')"
    @visible-change="onBlur"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :value="item"
      :label="item.fullName"
    ></el-option>
  </el-select>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {} from '@/api/finance/finance-setting'
  import { queryCnaps } from '@/api/finance/finance-setting'

  export default {
    name: 'UserSelect',
    props: {
      defaultCnaps: {
        type: [Object, Array],
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        cnaps: [],
        chooseUser: '',
        loading: false,
        options: [],
        pageLe: 30,
        pageNo: 1,
        total: 0,
      }
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
        userInfo: 'user/userInfo',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },

    watch: {
      cnaps: {
        deep: true,
        handler: function (val) {
          this.$emit('cnaps-choose', val)
        },
      },
      defaultCnaps: {
        deep: true,
        handler: function (val) {
          this.initCnps(val)
        },
      },
    },
    filters: {
      //审核状态匹配
      supplierAuditStatus(val) {
        return supplierAuditStatus(val)
      },
    },
    methods: {
      onBlur(val) {
        if (!val) {
          this.$refs.sel.blur()
        }
      },
      initCnps(cnaps) {
        if (cnaps?.cnapsCode) {
          this.options = [cnaps]
        } else {
          this.options = cnaps
        }
        this.cnaps = cnaps
      },
      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },

      remoteMethod(query) {
        this.loading = true
        this.query = query
        this._getOptions()
      },
      _reset() {
        this.pageNo = 1
        this.total = 0
        this.options = []
      },
      _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        let query = this.query
        queryCnaps({
          pageLe: this.pageLe,
          pageNo: this.pageNo,
          fits: (this.query && this.query.split(' ')) || [],
        }).then((res) => {
          if (res && res.code === '000000') {
            this.loading = false
            this.options = loadMore
              ? this.options.concat(res.data.data)
              : res.data.data
            this.total = res.data.total
          } else {
            this.loading = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
<style>
  .el-form-item__content .cnps-sel-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .cnps-sel-select .el-select-dropdown__list {
    height: 200px !important;
    overflow: auto;
    overflow-x: hidden;
  }
</style>
