<template>
  <div class="mail-page">
    <div>
      <MailList ref="MailList" :listConfig="listConfig" @showInfo="showInfo" @clearMail="currentMail = null" />
    </div>
    <div>
      <MailInfo v-if="currentMail" ref="mailInfo" @updatePage="updatePage" />
      <NoMail v-else />
    </div>
  </div>
</template>

<script>
  import MailInfo from './components/MailInfo.vue'
  import MailList from './components/MailList.vue'
  import NoMail from './components/NoMail.vue'
  export default {
    components: {
      MailList,
      MailInfo,
      NoMail,
    },
    watch: {
      '$store.state.mail.currentAccount': {
        handler(n) {
          this.currentMail = null
        },
      },
    },
    data() {
      return {
        currentMail: null,
        listConfig: {
          flag: false,
          type: 0,
          all: 1,
        },
      }
    },
    mounted() {
      // this.updatePage()
    },
    methods: {
      showInfo(data) {
        this.currentMail = data
        this.$nextTick(function () {
          if (data) {
            this.$refs.mailInfo.show(data)
          }
        })
      },
      updatePage() {
        this.$refs.MailList.updatePage(this.$route.params)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mail-page {
    height: 100%;
    display: flex;

    &>div {
      height: 100%;
    }

    &>div:first-child {

      flex-shrink: 0;
    }

    &>div:last-child {
      flex: 1;
      width: 0;
    }
  }
</style>