<template>
  <div class="proSetting-container">
    <!-- 上 -->
    <el-row :gutter="10">
      <!-- 出口抬头 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">按需采购(QC验货通过后)入库申请状态:</p>
          </div>
          <el-table class="mt15" :data="tableData" border style="width: 100%" :max-height="tableHeight">
            <el-table-column prop="amountName" align="center">
              <template slot="header">
                地区
                <!-- <el-tooltip class="item" effect="dark" placement="bottom">
                  <template slot="content">
                    <p>产品运营所属地区</p>
                  </template>
                  <i class="iconfont el-icon-question c_pointer"></i>
                </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column prop="amountShort" align="center">
              <template slot="header">
                状态
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <template slot="content">
                    <p>启用：QC验货通过后，需手动发起入库申请</p>
                    <p class="mt5">关闭：QC验货通过后，默认直接入库虚拟仓库</p>
                  </template>
                  <i class="iconfont el-icon-question c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                {{ scope.row.amountStatus === 1 ? '启动' : '关闭' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120" :resizable="false">
              <template slot-scope="scope">
                <el-button type="text" size="medium" @click="operateamountStatus(scope.row)">
                  {{ scope.row.amountStatus === 1 ? '关闭' : '启动' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>

      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">已入库采购合同变更，自动成本调整设置:</p>
            <el-button type="primary" plain icon="el-icon-edit" circle size="mini" @click="handleEdit()"></el-button>
          </div>
          <el-row type="flex" justify="center" class="mt15">
            <el-col>是否自动生成成本调整：</el-col>
            <el-col style="margin-right: 50px">
              {{ costInfo.generateCost | generateCost_filter }}
            </el-col>
          </el-row>
          <el-row type="flex" justify="center" class="mt15" v-if="costInfo.generateCost == 1">
            <el-col>是否需要增加审核流程：</el-col>
            <el-col style="margin-right: 50px">
              {{ costInfo.generateAudit | generateAudit_filter }}
            </el-col>
          </el-row>
          <el-row type="flex" justify="center" class="mt15"
            v-if="costInfo.generateAudit == 1 && costInfo.generateCost == 1">
            <el-col>已选择的审核模版名称：</el-col>
            <el-col style="margin-right: 50px">{{ costInfo.auditName }}</el-col>
          </el-row>
          <AuditDetail ref="auditDetail" v-if="
              auditContent.auditUserDTOS &&
              costInfo.generateAudit == 1 &&
              costInfo.generateCost == 1
            " :auditUserDTOS="auditContent.auditUserDTOS" hasAuditUser></AuditDetail>
        </div>
      </el-col>
      <!-- 下载箱唛设置 -->
      <el-col :span="8">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">下载箱唛设置</p>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="showMarkForm()"></el-button>
            </el-tooltip>
          </div>
          <downloadMarkSettingTable ref="downloadMarkSettingTable" setting-type="PURCHASE_ORDER"
            @edit-mark="editMark" />
        </div>
      </el-col>
    </el-row>
    <el-dialog title="已入库采购合同变更，自动成本调整设置" :visible.sync="dialogVisible" width="30%" @close="close"
      :close-on-click-modal="false">
      <div class="el-dialog-div">
        <el-form ref="form" :model="form" :rules="rules" label-width="180px">
          <el-row :gutter="15">
            <el-col :span="18">
              <el-form-item label="是否自动生成成本调整" prop="generateCost">
                <!-- 请选择 -->
                <el-select v-model="form.generateCost" :placeholder="$t('placeholder.plsSel')" style="width: 100%">
                  <el-option label="否" value="0" />
                  <el-option label="是" value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18" v-if="form.generateCost == 1">
              <el-form-item label="是否需要增加审核流程" prop="generateAudit">
                <!-- 请选择 -->
                <el-select v-model="form.generateAudit" :placeholder="$t('placeholder.plsSel')" style="width: 100%">
                  <el-option label="否" value="0" />
                  <el-option label="是" value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18" v-if="form.generateAudit == 1 && form.generateCost == 1">
              <el-form-item label="请选择审核模版" prop="auditName">
                <!-- 请选择 -->
                <el-input v-model="form.auditName" :placeholder="$t('placeholder.plsSel')"
                  @focus="chooseTemplate"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <template #footer>
        <el-row class="text-center">
          <!-- 确认 -->
          <el-button type="primary" @click="save">
            {{ $t('productDetail.Confirm') }}
          </el-button>
          <!-- 取消 -->
          <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
        </el-row>
      </template>
    </el-dialog>
    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />
    <!-- 箱唛设置弹窗 -->
    <downloadMarkSettingDialog ref="downloadMarkSettingDialog" @mark-refresh="refreshMarkTable"
      setting-type="PURCHASE_ORDER" />
  </div>
</template>

<script>
  import {
    insertPO,
    listPage,
    costAdjustList,
    costAdjustSave,
  } from '@/api/order-setting'
  import AuditChoose from '@/components/AuditChoose'
  import AuditDetail from '@/components/Audit/ApprovalsDrawer'
  import downloadMarkSettingTable from '@/views/order/orderSetting/components/downloadMarkSetting/downloadMarkSettingTable.vue'
  import downloadMarkSettingDialog from '@/views/order/orderSetting/components/downloadMarkSetting/downloadMarkSettingDialog.vue'
  import { markSettingMixin } from '@/views/order/orderSetting/components/downloadMarkSetting/markSettingMixin.js'
  import { mapGetters } from 'vuex'
  export default {
    name: 'PurchasetSetting',
    mixins: [markSettingMixin],
    components: {
      AuditChoose,
      AuditDetail,
      downloadMarkSettingTable,
      downloadMarkSettingDialog
    },
    props: {},
    data() {
      return {
        dialogVisible: false,
        tableData: [],
        costInfo: {
          generateCost: '0',
          generateAudit: '0',
          auditName: '',
        },
        form: {
          purchaseCostSetId: '',
          generateCost: '0',
          generateAudit: '0',
          auditName: '',
          auditContent: '',
        },
        auditContent: {
          auditObjectId: '',
          auditType: '',
        },
        rules: {
          generateCost: [
            {
              required: true,
              message: '请选择',
              trigger: 'change',
            },
          ],
          generateAudit: [
            {
              required: true,
              message: '请选择',
              trigger: 'change',
            },
          ],
          auditName: [
            {
              required: true,
              message: '请选择',
              trigger: 'change',
            },
          ],
        },
      }
    },

    computed: {
      tableHeight() {
        return (window.innerHeight - 45 - 20 - 20 - 20) / 2 - 30 - 20 - 20
      },
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    filters: {
      generateCost_filter(val) {
        const dicts = {
          0: '否',
          1: '是',
        }
        return dicts[val] || '--'
      },
      generateAudit_filter(val) {
        const dicts = {
          0: '否',
          1: '是',
        }
        return dicts[val] || '--'
      },
    },
    created() {
      this.queryInfo()
    },
    methods: {
      queryInfo() {
        this.getConfig(11)
        this.queryCostAdjustInfo()
      },
      // 获取配置项
      getConfig(amountType) {
        listPage({ amountType }).then((res) => {
          if (res && res.code === '000000') {
            this.tableData = res.data
          }
        })
      },

      //获取成本调整信息
      async queryCostAdjustInfo() {
        const response = await costAdjustList()
        if (response.code === '000000') {
          this.costInfo.auditName = response.data.auditName
          this.costInfo.generateCost = response.data.generateCost + ''
          this.costInfo.generateAudit = response.data.generateAudit + ''
          this.form.auditContent = response.data.auditContent
          this.form.purchaseCostSetId = response.data.purchaseCostSetId
          this.form.auditName = response.data.auditName
          this.form.generateCost = response.data.generateCost + ''
          this.form.generateAudit = response.data.generateAudit + ''
          if (response.data?.auditContent) {
            this.auditContent = JSON.parse(response.data.auditContent)
            setTimeout(() => {
              this.$refs.auditDetail?.getAuditUser()
            }, 400)
          }
        }
      },

      // 地区操作按钮
      operateamountStatus(row) {
        this.$confirm(
          `是否${row.amountStatus === 1 ? '关闭' : '开启'}${row.amountName
          }地区按需采购入库申请？`,
          '提示',
          {
            type: 'warning',
          }
        ).then(() => {
          const params = {
            ...row,
            amountStatus: row.amountStatus === 1 ? 0 : 1,
          }
          insertPO(params).then((res) => {
            if (res.code === '000000') {
              this.$message.success('操作成功')
              this.getConfig(11)
            }
          })
        })
      },

      //编辑成本调整
      handleEdit() {
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },

      //选择审核模板
      chooseTemplate() {
        let auditParams = {
          auditType: 15,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(auditParams).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(auditParams)
          }
        })
      },

      // 保存审核模板信息
      saveClick(data) {
        if (data) {
          this.form.auditName = data.name
          this.form.auditContent = JSON.stringify(data)
        } else {
          this.form.auditName = ''
          this.form.auditName = ''
        }
        this.save()
      },

      //保存列表
      save() {
        if (this.form.generateCost == 0) {
          this.form.generateAudit == 0
        }
        if (this.form.generateCost == 0 || this.form.generateAudit == 0) {
          this.form.auditContent = ''
          this.form.auditName = ''
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const response = await costAdjustSave({ ...this.form })
            if (response.code === '000000') {
              this.$baseMessage(
                // '保存成功',
                this.$t('reqmsg.$9'),
                'success',
                false,
                'erp-hey-message-success'
              )
              this.close()
              this.queryCostAdjustInfo()
            }
          }
        })
      },

      close() {
        this.dialogVisible = false
        this.queryInfo()
      },
    },
  }
</script>

<style scoped lang="scss">
  .proSetting-container {
    display: flex;
    flex-direction: column;

    >.el-row {
      flex: 1;

      &+.el-row {
        margin-top: 10px;
      }

      >.el-col {
        height: 100%;
      }
    }
  }

  .public-col {
    height: 100%;
    border: 1px solid #ededed;
    padding: 10px;

    p {
      line-height: 32px;
    }
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>