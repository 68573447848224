var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"lead_time_form",staticClass:"lead-time-table",attrs:{"model":_vm.tableData}},[_c('el-table',{key:_vm.tableKey,ref:"table",staticStyle:{"width":"100%","border-top":"none"},attrs:{"data":_vm.tableData.productPrintingFullValuationDTOList,"span-method":_vm.objectSpanMethod,"border":""}},[_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PrintingPosition'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.printingPositionNum)+"-location")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PricingMethod'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? scope.row.valuationMethod : scope.row.valuationMethodEn)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.QuantityLadder')}},_vm._l((_vm.numberStepListClomun),function(item,index){return _c('el-table-column',{key:index + 1000,attrs:{"label":item + '',"align":"center"}},[_c('el-table-column',{attrs:{"align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                _vm.tableData.deliveryProductNum &&
                _vm.tableData.deliveryProductNum < item &&
                _vm.tableData.blurBol
              )?_c('el-form-item',[_c('el-input',{staticClass:"disabled-demo",attrs:{"disabled":""}})],1):_c('el-form-item',{class:{
                'warn-demo':
                  scope.row['printingDay' + index] &&
                  scope.row['printingDay' + index] * 1 <
                    scope.row['printingFrontDay' + index] * 1,
              },attrs:{"prop":'productPrintingFullValuationDTOList.' +
                scope.$index +
                '.printingDay' +
                index,"label-width":"0","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"type":"number","oninput":"if(isNaN(value)) { value  = 0 } if(value < 0 ) { value  = 0 } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+0)}","onkeypress":"if(event.keyCode==45||event.keyCode==101){return false}"},on:{"change":function($event){return _vm.printingCycleChangeFn($event, scope.row, index)}},model:{value:(scope.row['printingDay' + index]),callback:function ($$v) {_vm.$set(scope.row, 'printingDay' + index, $$v)},expression:"scope.row['printingDay' + index]"}})],1)]}}],null,true)},[_c('template',{slot:"header"},[_c('div',[_c('p',{staticClass:"required-symbol"},[_vm._v(" "+_vm._s(_vm.$t('productDetail.PrintingCycle'))+" ")]),_c('p',[_vm._v("("+_vm._s(_vm.$t('productDetail.WorkingDay'))+")")])])])],2),_c('el-table-column',{attrs:{"align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" = "+_vm._s(_vm.$t('productDetail.LeadTimeTips'))+" "),_c('br'),_vm._v(" = "+_vm._s(Number(scope.row['printingDay' + index]))+" + "+_vm._s(_vm.pricingTableData.transportDay || 0)+" ")]),(
                  _vm.tableData.deliveryProductNum &&
                  _vm.tableData.deliveryProductNum < item &&
                  _vm.tableData.blurBol
                )?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(scope.row['deliverDay' + index])+" ")])])]}}],null,true)},[_c('template',{slot:"header"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('productDetail.LeadTime'))+" ")]),_c('p',[_vm._v("("+_vm._s(_vm.$t('productDetail.WorkingDay'))+")")])])])],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }