import {
  SettleConfigInteractor,
  FinanceThemeSetting,
} from '@/core/interactors/finance/config-manage'


const mixins = {
  data() {
    return {
      areaList: [],
      roleList: [],
      roleMap: null,
    }
  },
  methods: {
    // 获取区域 
    async getArea() {
      this.areaList = await FinanceThemeSetting.allDeptAreaByTenant()
    },
    // 获取角色，用于表格
    async getRoles() {
      this.roleList =
        await SettleConfigInteractor.getConfigConditionSettlementRoles()
      this.roleMap = new Map()
      // 设置角色map （'订单支持'，'order'）
      this.roleList?.forEach((ele) => {
        this.roleMap.set(ele.message, ele.code)
      })

    },

    async getRoleList() {
      this.roleList =
        await SettleConfigInteractor.getConfigConditionSettlementRoles()
    },
    // 设置角色map 
    setRoleCode() {
      this.roleMap = new Map()
      this.roleList?.forEach((ele) => {
        this.roleMap.set(ele.message, ele.code)
      })
    },
    //  通过message 获取到code  （'订单支持'，'order'）
    getRoleCode(msg) {
      return this.roleMap.get(msg)
    },
  },
}

export default mixins