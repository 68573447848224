<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="600px"
      @close="close"
      v-if="dialogFormVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      top="10vh"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="中文名称" prop="nameCn">
          <el-input
            v-model="form.nameCn"
            placeholder="请输入长度1到100字符"
            maxlength="100"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="英文名称" prop="nameEn">
          <el-input
            v-model="form.nameEn"
            placeholder="请输入长度1到100字符"
            maxlength="100"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="单位">
          <el-input
            v-model="form.productUnit"
            maxlength="50"
            placeholder="请输入长度1到50字符"
          />
        </el-form-item>
        <el-form-item label="关联供应商">
          <el-input
            v-model="form.supplierNames"
            placeholder="点击选择供应商"
            class="c_pointer"
            :readonly="true"
            @click.native="selectSupplierClick"
          />
        </el-form-item>
        <el-form-item label="成本价">
          <el-input
            v-model="form.costPrice"
            type="number"
            min="0"
            @change="formatNum(form.costPrice, 'costPrice', 3)"
          />
        </el-form-item>
        <el-form-item label="材质">
          <el-input
            v-model="form.material"
            maxlength="50"
            placeholder="请输入长度1到50个字符"
          />
        </el-form-item>
        <el-form-item label="尺寸">
          <el-row>
            <el-col :span="7">
              <el-input
                v-model="form.productLengthCm"
                placeholder="长"
                type="number"
                min="0"
                @change="formatNum(form.productLengthCm, 'productLengthCm', 2)"
              >
                <span style="color: #000" slot="suffix">CM</span>
              </el-input>
            </el-col>
            <el-col :span="1" style="text-align: center">X</el-col>
            <el-col :span="7">
              <el-input
                v-model="form.productWidthCm"
                placeholder="宽"
                type="number"
                min="0"
                @change="formatNum(form.productWidthCm, 'productWidthCm', 2)"
              >
                <span style="color: #000" slot="suffix">CM</span>
              </el-input>
            </el-col>
            <el-col :span="1" style="text-align: center">X</el-col>
            <el-col :span="8">
              <el-input
                v-model="form.productHeightCm"
                placeholder="高"
                type="number"
                min="0"
                @change="formatNum(form.productHeightCm, 'productHeightCm', 2)"
              >
                <span style="color: #000" slot="suffix">CM</span>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="重量">
          <el-input
            v-model="form.productWeightKgs"
            type="number"
            min="0"
            @change="formatNum(form.productWeightKgs, 'productWeightKgs', 2)"
          >
            <span style="color: #000" slot="suffix">KGS</span>
          </el-input>
        </el-form-item>
        <el-form-item label="图片" prop="productImg">
          <erp-upload-images
            ref="uploadImages"
            allowedType="jpg、 png、jpeg"
            :limit="6"
            :tips-visible="false"
            tip-text="支持格式：jpg/jpeg/png，建议尺寸：1800px*1800px，最多上传6张，单个文件大小不允许超过2MB"
            :default-file-list="imageList"
            @change="changeUploadList4"
          ></erp-upload-images>
        </el-form-item>
        <el-form-item label="其他描述">
          <el-input
            v-model="form.description"
            placeholder="请输入内容，字数500字以内"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="500"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row class="text-center">
          <el-button type="primary" :loading="loading" @click="save">
            确认
          </el-button>
          <el-button @click="close">取消</el-button>
        </el-row>
      </template>
      <!-- 供应商列表弹框 -->
    </el-dialog>
    <SupplierList ref="supplierListModel" @supplierSelect="supplierSelect" />
  </div>
</template>
<script>
  import { detailSubProduct } from '@/api/product/productList'
  import SupplierList from './linkSupply.vue'
  import Util from '@/utils/utils'
  export default {
    name: 'add-sub-product',
    components: {
      SupplierList,
    },
    data() {
      return {
        loading: false,
        title: '新增辅料',
        dialogFormVisible: false,
        form: {
          productImg: '',
          costPrice: '',
          productLengthCm: '',
          productWidthCm: '',
          productHeightCm: '',
          productWeightKgs: '',
        },
        rules: {
          nameCn: [
            { required: true, message: '请输入中文名称', trigger: 'blur' },
          ],
          nameEn: [
            { required: true, message: '请输入英文名称', trigger: 'blur' },
          ],
          productImg: [
            { required: true, message: '请输入上传图片', trigger: 'change' },
          ],
        },
        imageList: [],
        productId: '',
      }
    },
    methods: {
      // 保存 新增，编辑
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (!this.productId) {
              this.$emit('saveSubProduct', this.form)
            } else {
              this.$emit('updateSubProduct', this.form, this.productId)
            }
          } else {
            this.$message.warning('必填信息不完整！')
            return false
          }
        })
      },
      // 关闭弹窗
      close() {
        this.dialogFormVisible = false
        for (const key in this.form) {
          this.form[key] = ''
        }
        this.imageList = []
        this.productId = ''
      },
      // 详情
      getDetail(val) {
        this.productId = val.productAccessoriesId
        detailSubProduct(val.accessoriesNumber).then((res) => {
          this.form = res?.data
          this.imageList = this.form?.productImg.split(',').map((item) => {
            return {
              url: item,
              name: '',
            }
          })
          this.$refs.uploadImages.uploadFileList = this.imageList
          this.form.costPrice = this.form.costPrice ? this.form.costPrice : ''
          this.form.productLengthCm = this.form.productLengthCm
            ? this.form.productLengthCm
            : ''
          this.form.productWidthCm = this.form.productWidthCm
            ? this.form.productWidthCm
            : ''
          this.form.productHeightCm = this.form.productHeightCm
            ? this.form.productHeightCm
            : ''
          this.form.productWeightKgs = this.form.productWeightKgs
            ? this.form.productWeightKgs
            : ''
        })
      },
      changeUploadList4(file) {
        let urlArr = []
        file.forEach((ele) => {
          urlArr.push(ele.url)
        })
        this.form.productImg = urlArr.toString()
      },
      // 格式化数据，保留三位小数 并且大于0
      formatNum(val, type, number) {
        if (val <= 0) {
          this.form[type] = ''
        } else {
          this.form[type] = Util.numberFormat(val, number)
        }
      },
      selectSupplierClick() {
        this.$refs['supplierListModel'].supplierListModel(this.form.supplierIds)
      },
      // 供应商选择保存
      supplierSelect(selectRows) {
        let supplierIds = [] //所有选中的供应商id
        let supplierNames = [] // 所有选中的供应商
        selectRows.forEach((item) => {
          supplierNames.push(item.supplierName)
          supplierIds.push(item.supplierId)
        })
        this.form.supplierIds = supplierIds.toString()
        this.form.supplierNames = supplierNames.toString()
        this.$forceUpdate()
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog {
      max-height: 85vh;
      overflow-y: scroll;
      .el-dialog__header {
        position: sticky;
        top: 0;
        background-color: #ffffff;
        z-index: 1;
      }
      .el-dialog__footer {
        position: sticky;
        bottom: 0px;
        background-color: #ffffff;
        z-index: 1;
      }
    }
  }
</style>
