import request from '@/core/services/request'

// 列表头部按状态统计数据
export function getGroupCount(data) {
  return request({
    url: '/order/orderTracking/groupCount',
    method: 'post',
    data,
  })
}

// 根据分页查找列表
export function getListPage(data) {
  return request({
    url: '/order/orderTracking/listPage',
    method: 'post',
    data,
  })
}

// 作废跟踪单
export function invalidTrack(data) {
  return request({
    url: '/order/orderTracking/invalid',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//采购单恢复
export function recoverTrack(data) {
  return request({
    url: '/order/orderTracking/recover',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 无须跟单
export function notTracking(data) {
  return request({
    url: '/order/orderTracking/notTracking',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 解除预警
export function cancelWarning(data) {
  return request({
    url: '/order/orderTracking/cancelWarning',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据跟踪单查询所有备注
export function queryRemarksById(data) {
  return request({
    url: '/order/orderTrackingRemark/queryRemarks',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增备注
export function insertPORemark(data) {
  return request({
    url: '/order/orderTrackingRemark/insertPO',
    method: 'post',
    data,
  })
}

// 导出跟踪单

export function exportOrderTracking(data) {
  return request({
    url: '/order/orderTracking/exportOrderTracking',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 刷新节点
export function manualUpdate(data) {
  return request({
    url: '/order/orderTrackingNode/manualUpdate',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
