<template>
  <div class="detail-msg">
    <!-- 基本信息 -->
    <el-row>
      <h4 class="f_s_15">基本信息</h4>
      <el-row :gutter="10" class="mt15 base-box">
        <el-col :span="6">
          <el-row type="flex" align="middle">
            <label>供应商标识：</label>
            <el-row
              type="flex"
              align="middle"
              class="supplierIdentification-row"
            >
              <el-tag
                type="primary"
                class="ml5"
                v-if="dataPass.supplierIdentification === '0'"
              >
                线下
              </el-tag>
              <el-tag
                type="success"
                class="ml5"
                v-if="dataPass.supplierIdentification === '1'"
              >
                网拍
              </el-tag>
              <el-row
                v-if="
                  dataPass.supplierIdentification === '0,1' ||
                  dataPass.supplierIdentification === '1,0'
                "
              >
                <el-tag type="primary" size="mini" class="ml5">线下</el-tag>
                <el-tag type="success" size="mini" class="ml5">网拍</el-tag>
              </el-row>
              <el-tag
                class="ml5"
                type="danger"
                v-if="dataPass.isAgreement === 1"
              >
                协议
              </el-tag>
            </el-row>
          </el-row>
        </el-col>
        <el-col :span="6">
          <label>供应商名称：</label>
          <span class="col-span">{{ dataPass.supplierName || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>地址：</label>
          <span class="col-span">{{ dataPass.address || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>纳税人资质：</label>
          <span class="col-span">{{ dataPass.ratepayerAptitudeType | taxpayerQualifications}}</span>
        </el-col>
        <el-col :span="6">
          <el-row type="flex" align="middle">
            <label>供应商等级：</label>
            <el-rate
              v-model="dataPass.supplierGrade"
              disabled
              text-color="#ff9900"
            ></el-rate>
          </el-row>
        </el-col>
        <el-col :span="6">
          <label>供应商类型：</label>
          <span class="col-span">{{ dataPass.supplierTypeName || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>网址：</label>
          <span class="col-span">{{ dataPass.website || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>邮编：</label>
          <span class="col-span">{{ dataPass.zipCode || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>成立年份：</label>
          <span class="col-span">{{ dataPass.establishmentDate || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>创建人：</label>
          <span class="col-span">{{ dataPass.creatorName || '--' }}</span>
        </el-col>
        <el-col :span="6">
          <label>创建时间：</label>
          <span class="col-span">{{ dataPass.createTime || '--' }}</span>
        </el-col>
      </el-row>
    </el-row>
    <!-- 联系人信息 -->
    <el-row class="mt30">
      <h4 class="f_s_15">联系人信息</h4>
      <el-table class="mt20" border :data="dataPass.linkmanVOS">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column prop="lianxiren" align="center" label="姓名">
          <template slot-scope="scope">{{ scope.row.linkman }}</template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="联系电话">
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column
          prop="email"
          align="center"
          label="电子邮箱"
          show-overflow-tooltip
          width="200"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.email"
              class="c_pointer prod-code"
              @click="emailClick(scope.row.email)"
            >
              {{ scope.row.email }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="WhatsApp" align="center" label="部门">
          <template slot-scope="scope">
            {{ scope.row.department || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="Skype" align="center" label="职务">
          <template slot-scope="scope">{{ scope.row.duties || '--' }}</template>
        </el-table-column>
        <el-table-column prop="WeChat" align="center" label="备注">
          <template slot-scope="scope">
            {{ scope.row.remarks || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="zhuyao" align="center" label="是否主要">
          <template slot-scope="scope">
            <el-switch :value="scope.row.major === 1 ? true : false" disabled />
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 协议信息 -->
    <el-row class="mt30" v-if="dataPass.isAgreement === 1">
      <h4 class="f_s_15">协议信息</h4>
      <el-table class="mt20 w90" border :data="supplierData">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column align="center" label="协议名称">
          <template slot-scope="scope">{{ dataPass.agreementName }}</template>
        </el-table-column>
        <el-table-column align="center" label="协议文件" width="440">
          <template slot-scope="scope">
            <div v-show="fileList2.length">
              <div class="flie-list" v-for="(item, i) in fileList2" :key="i">
                <i class="el-icon-document"></i>
                <a class="page-link ml5">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="top-start"
                  >
                    <span class="ell">
                      <router-link
                        :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                        target="_blank"
                      >
                        {{ item.name }}
                      </router-link>
                    </span>
                  </el-tooltip>
                </a>
                <span class="size ml10">
                  {{ utils.calculateFileSize(item.size) }}
                </span>
                <span class="ml10">{{ item.time }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="协议有效期" width="240">
          <template slot-scope="scope">
            {{
              dataPass.agreementStartDate + ' -- ' + dataPass.agreementEndDate
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" width="240">
          <template slot-scope="scope">
            {{ dataPass.agreementMemo || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="zhuangtai"
          align="center"
          label="状态"
          width="140"
        >
          <template slot-scope="scope">
            {{ dataPass.agreementStatus || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 付款方式 -->
    <el-row class="mt30">
      <h4 class="f_s_15">
        付款方式
        <span class="his-account-period" @click="showHisAccountPeriodFn">
          历史账期
        </span>
      </h4>
      <div class="c-table-wrap">
        <table class="custom-table mt10 w90" border bordercolor="#ebeef5">
          <thead>
            <tr>
              <th width="60">序号</th>
              <th width="150">金额区间</th>
              <th>付款方式</th>
              <th width="150">占比(%)</th>
              <th width="160">付款环节</th>
              <th width="160">备注</th>
              <th width="160">优先级</th>
              <th width="180">添加日期</th>
              <th width="160">添加人</th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in dataPass.payVOS" :key="index">
            <tr>
              <td rowspan="4">{{ index + 1 }}</td>
              <td rowspan="4">{{ item.intervalName }}</td>
              <td rowspan="4">{{ item.paymentMethodName || '--' }}</td>
              <td>{{ item.deposit || '--' }}</td>
              <td>
                <span>定金</span>
              </td>
              <!-- <td>{{ item.depositAfter }}</td> -->
              <td rowspan="4">{{ item.remark }}</td>
              <td rowspan="4">{{ item.priority }}</td>
              <td rowspan="4">{{ item.addTime }}</td>
              <td rowspan="4">{{ item.addUserName }}</td>
            </tr>
            <tr v-if="item.beforeShipping">
              <td>{{ item.beforeShipping || '-' }}</td>
              <td>
                <span>发货前</span>
              </td>
              <!-- <td>{{ item.beforeShippingAfter }}</td> -->
            </tr>
            <tr v-if="item.uponDelivery">
              <td>{{ item.uponDelivery || '--' }}</td>
              <td>
                <span>发货后</span>
                <span v-if="item.uponDeliveryAfter">
                  {{ item.uponDeliveryAfter }}天
                </span>
              </td>
              <!-- <td>{{ item.uponDeliveryAfter }}</td> -->
            </tr>
            <tr v-if="item.finalPayment">
              <td>{{ item.finalPayment || '--' }}</td>
              <td>
                <span>收货后</span>
                <span v-if="item.finalPaymentAfter">
                  {{ item.finalPaymentAfter }}天
                </span>
              </td>
              <!-- <td>{{ item.finalPaymentAfter }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </el-row>

    <!-- 企业资料 -->
    <el-row class="mt30">
      <h4 class="f_s_15">企业资料</h4>
      <el-table :data="supplierAttestationDTO" border class="mt20 w90">
        <el-table-column
          label="营业执照"
          align="center"
          prop="businessLicensePhoto"
        >
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              v-if="scope.row.businessLicensePhoto"
            >
              <el-image
                :src="scope.row.businessLicensePhoto"
                class="c_pointer"
                @click="handlePreview(scope.row.businessLicensePhoto)"
                style="width: 70px; height: 70px"
              ></el-image>
            </el-row>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="社会统一信用代码" align="center">
          <template slot-scope="scope">
            {{ scope.row.creditCode }}
          </template>
        </el-table-column>
        <el-table-column label="有效期" align="center">
          <template slot-scope="scope">
            {{ scope.row.businessLicensestard }}--{{
              scope.row.businessLicenseend
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="注册资金"
          align="center"
          prop="registeredCapital"
        >
          <template slot-scope="scope">
            {{ scope.row.registeredCapital || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          label="企业真实照片"
          align="center"
          prop="enterprisePhoto"
        >
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              v-if="scope.row.enterprisePhoto"
            >
              <el-image
                :src="scope.row.enterprisePhoto.split(',')[0]"
                class="c_pointer"
                @click="handlePreview(scope.row.enterprisePhoto.split(','))"
                style="width: 70px; height: 70px"
              ></el-image>
              <span
                class="fileAnnex-span"
                v-if="scope.row.enterprisePhoto.split(',').length > 1"
              >
                {{ scope.row.enterprisePhoto.split(',').length }}
              </span>
            </el-row>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="supplierAttestationDTO" border class="mt15 w90">
        <el-table-column label="法人身份证照片" align="center">
          <template slot-scope="scope">
            <el-row type="flex" justify="center">
              <el-row
                type="flex"
                align="middle"
                justify="center"
                v-if="scope.row.legalPersonPhotoFront"
              >
                <el-image
                  :src="scope.row.legalPersonPhotoFront"
                  class="c_pointer"
                  @click="handlePreview(scope.row.legalPersonPhotoFront)"
                  style="width: 70px; height: 70px"
                ></el-image>
              </el-row>
              <el-row
                class="ml15"
                type="flex"
                align="middle"
                justify="center"
                v-if="scope.row.legalPersonPhotoBack"
              >
                <el-image
                  :src="scope.row.legalPersonPhotoBack"
                  class="c_pointer"
                  @click="handlePreview(scope.row.legalPersonPhotoBack)"
                  style="width: 70px; height: 70px"
                ></el-image>
              </el-row>
              <span
                v-if="
                  !scope.row.legalPersonPhotoFront &&
                  !scope.row.legalPersonPhotoBack
                "
              >
                --
              </span>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="法人姓名" align="center" prop="legalPerson">
          <template slot-scope="scope">
            {{ scope.row.legalPerson || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="法人身份证号" align="center" prop="cardNumber">
          <template slot-scope="scope">
            {{ scope.row.cardNumber || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="身份证有效期" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.cardStard">
              {{ scope.row.cardStard }}-{{ scope.row.cardEnd }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 账户信息 -->
    <el-row class="mt30">
      <h4 class="f_s_15">账户信息</h4>
      <el-table class="mt20 w90" border :data="dataPass.bankAccountVOS">
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column align="center" label="账户类型">
          <template slot-scope="scope">
            {{ scope.row.accountType | accountType_filter }}
          </template>
        </el-table-column>
        <el-table-column prop="mudi" align="center" label="户名">
          <template slot-scope="scope">
            {{ scope.row.accountName || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="fangxiang" align="center" label="账号">
          <template slot-scope="scope">
            {{ scope.row.bankAccount || '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="开户行">
          <template slot-scope="scope">
            {{ scope.row.bankName || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="zhuangtai" align="center" label="税号">
          <template slot-scope="scope">
            {{ scope.row.dutyAccount || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 认证情况 -->
    <el-row class="mt30">
      <h4 class="f_s_15">认证情况</h4>
      <el-table
        class="mt20 w90"
        border
        :data="dataPass.certificateAuthenticationcVOS"
      >
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column prop="lianxiren" align="center" label="证书名称">
          <template slot-scope="scope">{{ scope.row.cardName }}</template>
        </el-table-column>
        <el-table-column prop="way" align="center" label="证书照片">
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="center"
              v-if="scope.row.cardPhoto"
            >
              <el-image
                :src="scope.row.cardPhoto.split(',')[0]"
                class="c_pointer"
                @click="handlePreview(scope.row.cardPhoto.split(','))"
                style="width: 70px; height: 70px"
              ></el-image>
              <span
                class="fileAnnex-span"
                v-if="scope.row.cardPhoto.split(',').length > 1"
              >
                {{ scope.row.cardPhoto.split(',').length }}
              </span>
            </el-row>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" align="center" label="备注" width="480">
          <template slot-scope="scope">
            {{ scope.row.remarks || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 备注 -->
    <el-row class="mt30">
      <h4 class="f_s_15">备注</h4>
      <p style="margin-top: 10px">{{ dataPass.remarks || '未填写' }}</p>
    </el-row>
    <!-- 附件 -->
    <el-row class="mt30">
      <h4 class="f_s_15">附件</h4>
      <div v-show="fileList.length">
        <div class="flie-list" v-for="(item, i) in fileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <span class="ell">
                <router-link
                  :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                  target="_blank"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
      <p class="mt10" v-show="!fileList.length">未填写</p>
    </el-row>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />

    <!-- <SupplierManageDatum ref="SupplierManageDatum" /> -->
    <hisAccountPeriod :tableData="tableData" ref="hisAccountPeriod" />
  </div>
</template>

<script>
  import hisAccountPeriod from './his-account-period.vue'
  export default {
    name: 'ClientManageDetailMsg',
    props: {
      dataPass: {
        type: Object,
        default: () => {},
      },
    },
    components: {
      hisAccountPeriod,
    },
    data() {
      return {
        supplierData: [{}],
        dialogImageUrl: '',
        dialogVisible: false,
        showViewer: false, //图片预览参数
        imgUrl: [], //图片预览url
        fileList: [], //协议文件
        fileList2: [], //协议信息的协议文件
        supplierAttestationDTO: [], //企业资料
        tableData: [], //历史账期
      }
    },
    watch: {
      dataPass: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!Object.keys(value).length) return false
          //this.supplierAttestationDTO[0] = value.supplierAttestationVO
          this.$set(
            this.supplierAttestationDTO,
            0,
            value.supplierAttestationVO || []
          )

          if (value.payVOS && value.payVOS.length) {
            let payDTOS = []
            value.payVOS.forEach((item) => {
              let pushObj = {
                paymentId: item.paymentId,
                payType: item.payType,
                intervalName: item.intervalName,
                paymentMethodName: item.paymentMethodName,
                deposit: item.deposit,
                depositAfter: item.depositAfter,
                beforeShipping: item.beforeShipping,
                beforeShippingAfter: item.beforeShippingAfter,
                uponDelivery: item.uponDelivery,
                uponDeliveryAfter: item.uponDeliveryAfter,
                finalPayment: item.finalPayment,
                finalPaymentAfter: item.finalPaymentAfter,
              }
              payDTOS.push(pushObj)
            })
            value.payDTOS = payDTOS
          }
          this.fileList = value.enclosure ? JSON.parse(value.enclosure) : ''

          this.fileList2 = value.agreementFile
            ? JSON.parse(value.agreementFile)
            : ''
        },
      },
    },

    filters: {
      accountType_filter(n) {
        const dicts = {
          1: '对公账户',
          2: '个人银行卡',
          3: '支付宝',
          4: '网拍',
        }
        return dicts[n] || '--'
      },
      taxpayerQualifications(n) {
        const dicts = {
          0: '一般纳税人',
          1: '小规模纳税人'
        }
        return dicts[n] || '--'
      },
    },

    methods: {
      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.getImgUrl({
            url: item,
            content: 'EQSTEP·ERP',
            cb: (base64Url) => {
              this.imgUrl = [base64Url]
            },
          })
        }
        this.showViewer = true
      },

      //邮件跳转
      emailClick(email) {
        let routeData = this.$router.resolve({
          name: 'MailReceiveAll',
          query: { searchValue: email },
        })
        window.open(routeData.href, '_blank')
      },

      getImgUrl({
        url = '',
        textAlign = 'left',
        textBaseline = 'top',
        font = '18px Microsoft Yahei',
        fillStyle = 'rgba(255, 255, 255, 0.5)',
        content = '我是默认的水印参数',
        cb = null,
        textX = 100,
        textY = 30,
      } = {}) {
        // 创建所需要添加水印的img图片
        const img = new Image()
        img.crossOrigin = 'anonymous'
        img.setAttribute('crossOrigin', 'anonymous')
        img.src = url

        img.onload = function () {
          // 创建canvas，并将创建的img绘制成canvas
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')

          ctx.drawImage(img, 0, 0)
          ctx.textAlign = textAlign
          ctx.textBaseline = textBaseline
          ctx.font = font
          ctx.fillStyle = fillStyle
          ctx.rotate((Math.PI / 180) * 15)
          // 循环绘制水印
          //ctx.fillText(content, img.width - textX, img.height - textY)
          for (let i = 0; i < img.height / 120; i++) {
            for (let j = 0; j < img.width / 50; j++) {
              ctx.fillText(content, i * 200, j * 100, img.width)
            }
          }
          // 将绘制完成的canvas转换为base64的地址
          const base64Url = canvas.toDataURL()
          cb && cb(base64Url)
        }
      },
      closeViewer() {
        this.imgUrl = []
        this.showViewer = false
      },
      // 显示历史账期
      showHisAccountPeriodFn() {
        this.$refs.hisAccountPeriod.show()
      },
    },
  }
</script>

<style scoped lang="scss">
  .detail-msg {
    padding: 20px;
    border-left: 1px solid #d7d7d7;
    min-height: 700px;
  }
  .base-box {
    label {
      color: #666;
    }
    ::v-deep.el-col{
      height:32px;
    }
    .col-span {
      color: #000;
    }
  }
  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }
  .w90 {
    width: 90%;
  }
  .table-pay {
    width: 80%;
  }
  .table-pay th {
    font-weight: normal;
  }
  .table-pay td {
    height: 36px;
  }
  // 邮箱链接
  .prod-code {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
  .his-account-period {
    font-weight: normal;
    margin-left: 10px;
    color: #409eff;
    cursor: pointer;
  }
</style>
