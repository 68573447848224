<template>
  <el-dialog
    class="plan-order"
    title="安排订单"
    v-if="planOrderVisible"
    :visible.sync="planOrderVisible"
    :before-close="close"
    width="1400px"
  >
    <div class="container">
      <el-form :model="form" ref="ruleForm">
        <!-- <el-checkbox v-model="form.checked" class="mb10">
          勾选后，采购订单审核通过，会自动发送 “安排生产” 邮件给客户
        </el-checkbox> -->
        <div class="c-table-wrap">
          <el-table
            :data="form.tableData"
            :span-method="(...rest) => mergeRowByProp(...rest, form.tableData, mergeList)"
            border
            max-height="500"
          >
            <el-table-column
              prop="orderCode"
              label="订单编号"
              min-width="160"
              show-overflow-tooltip
            />
            <el-table-column label="Item" min-width="200" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{
                  row.item && row.item.includes('****')
                    ? row.item.split('****')[1]
                    : '--'
                }}
              </template>
            </el-table-column>
            <el-table-column label="产品编号" show-overflow-tooltip>
              <template v-slot="{ row }">
                <span v-if="row.productCode" class="page-link">
                  {{ row.productCode }}
                </span>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column label="规格" min-width="200" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{ row.specification || '/' }}
              </template>
            </el-table-column>
            <el-table-column label="Qty" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{ row.amount || '/' }}
              </template>
            </el-table-column>
            <el-table-column label="印刷方式" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{ row.printingWayEn || '--' }}
              </template>
            </el-table-column>
            <el-table-column min-width="160">
              <template #header>
                <span style="color: red">*</span>
                期望交期
              </template>
              <template v-slot="{ row, $index }">
                <el-form-item
                  :prop="'tableData[' + $index + '].expectedDeliveryDate'"
                  :rules="rules.expectedDeliveryDate"
                >
                  <el-date-picker
                    v-model="row.expectedDeliveryDate"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column min-width="160">
              <template #header>
                <span style="color: red">*</span>
                采购开发
              </template>
              <template v-slot="{ row, $index }">
                <el-form-item
                  :prop="'tableData[' + $index + '].salesId'"
                  :rules="rules.salesId"
                >
                  <UserSelect
                    roleName="采购员"
                    @user-choose="(user) => handleUserChoose(user, row)"
                    :defaultUser="{
                      userName: row.salesName,
                      englishName: row.salesNameEn,
                      userId: row.salesId,
                    }"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="订单备注" min-width="160">
              <template v-slot="{ row, $index }">
                <el-form-item
                  :prop="'tableData[' + $index + '].remark'"
                  :rules="rules.remark"
                >
                  <el-input
                    v-model="row.remark"
                    placeholder="请输入"
                    clearable
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <el-checkbox class="mt10" v-model="sendEmailFlag">
            发送邮件
          </el-checkbox>
          <p class="mt5 f_s_12" style="color: #999; margin-left: 23px">
            勾选后，采购订单初次审核通过后，会自动发送“安排生产”邮件给客户
          </p>
        </div>
      </el-form>
    </div>
    <div class="text-center mt20">
      <el-button type="primary" @click="save()" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import UserChoose from '@/components/userChoose'
  import UserSelect from '@/components/user-select'
  import { orderAlignOrderShow, orderAlignOrderList } from '@/api/order.js'
  import { listUserByRoleName } from '@/api/finance/credit-manage-list'
  import { mapGetters } from 'vuex'
  import { getSettingMsg } from '@/views/system-manage/mail-setting/util/utils.js'
  import { mergeRowByProp } from 'kits'

  export default {
    props: ['orderIds'],
    components: {
      UserChoose,
      UserSelect,
    },
    data() {
      return {
        mergeRowByProp,
        form: {
          tableData: [],
        },
        planOrderVisible: false,
        isLoading: false,
        sendEmailFlag: false, // 是否默认发送邮件 1:发送 0不发送
        purchaseUsers: [], //采购开发下拉列表
        mergeList: [
          { column: 0, prop: 'orderCode' },
          { column: 1, prop: 'item' },
        ],
        rules: {
          expectedDeliveryDate: [
            { required: true, message: '期望交期必填', trigger: 'change' },
          ],
          salesId: [
            { required: true, message: '采购开发必填', trigger: 'change' },
          ],
          remark: [
            { max: 500, message: '字符长度不能超过500', trigger: 'blur' },
          ],
        },
        lock: true, // 第一次打开弹框，选中某个采购开发，将每一行的采购开发都回显该值，再次选择时，不需要联动
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },

    methods: {
      //权限-客户代表角色下拉
      async getListUserByRoleName() {
        let response = await listUserByRoleName({
          divideGroup: 0,
          name: '采购员',
          tenantId: this.userInfo.tenantId,
        })
        if (response.code === '000000') {
          this.purchaseUsers = response.data
        }
      },
      // 订单回显
      showOrderInfo(ids, rows) {
        this.planOrderVisible = false
        orderAlignOrderShow({
          orderIds: ids,
          orderProductUniqueId: rows[0].orderProductUniqueId || '',
        }).then((res) => {
          if(res?.code === '000000') {
            this.planOrderVisible = true
            this.lock = true
            const data = this.getDataByResData(res.data, rows)
            this.form.tableData = data
          }

        })

        // 安排订单
        this.getListUserByRoleName()
      },

      getDataByResData(data, rows) {
        /**
         * 接口返回的数据结构：
         *  [
         *    { orderCode: 1, item: 'xxx', vos: [{ id: 1 }] },
         *    { orderCode: 2, item: 'yyy', vos: [{ id: 2 }, { id: 3 }] },
         *  ]
         * 需要处理成：
         *  [
         *    { orderCode: 1, item: '1****xxx', id: 1, expectedDeliveryDate: '' },
         *    { orderCode: 2, item: '2****yyy', id: 2, expectedDeliveryDate: '' },
         *    { orderCode: 2, item: '2****yyy', id: 3, expectedDeliveryDate: '' },
         *  ]
         */
        const getItem = (row, vo) => {
          return {
            orderCode: row.orderCode,
            item: `${row.orderCode}****${row.item}`,
            ...vo,
            // 期望交期：列表中的shippingDate
            expectedDeliveryDate: rows.find(
              (n) => n.orderCode === row.orderCode
            )?.shippingDate,
          }
        }

        if(!data) return
        const newData = data.reduce((finalList, row) => {
          if (Array.isArray(row.vos)) {
            if (row.vos.length === 1) {
              finalList.push(getItem(row, row.vos[0]))
            } else {
              for (const vo of row.vos) {
                finalList.push(getItem(row, vo))
              }
            }
          }
          return finalList
        }, [])
        return newData
      },

      // 采购开发选中事件
      handleUserChoose(user, row) {
        row.salesId = user?.userId
        row.salesName = user?.userName
        row.salesNameEn = user?.englishName
        if (this.lock && user) {
          this.lock = false
          for (const item of this.form.tableData) {
            item.salesId = user?.userId
            item.salesName = user?.userName
            item.salesNameEn = user?.englishName
          }
        }
      },

      // 保存数据处理
      saveFormatData(list) {
        let arr = []
        list.forEach((item, i) => {
          item.vos.forEach((obj, k) => {
            arr.push(obj)
          })
        })
        return arr
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.isLoading = true
            orderAlignOrderList({
              alignOrderDTOS: this.form.tableData,
              sendEmailFlag: this.sendEmailFlag ? 1 : 0,
            }).then((res) => {
              if (res && res.code == '000000') {
                this.$message.success('保存成功')
                this.close()
                this.$parent.getList()
              } else {
                this.isLoading = false
              }
            })
          }
        })
      },

      // 关闭弹窗
      close() {
        this.isLoading = false
        this.sendEmailFlag = false
        this.planOrderVisible = false
      },
    },

    created() {
      getSettingMsg(this.userInfo.tenantId).then((data) => {})
    },
  }
</script>

<style lang="scss" scoped>
  .plan-order {
    .container {
      /deep/.el-form {
        .el-form-item {
          margin-bottom: 0;
          &.is-error {
            margin-bottom: 18px;
          }
          .el-date-editor {
            width: 100%;
          }
          .el-form-item__error {
            z-index: 1000;
          }
        }
      }
      // max-height: 600px;
      // min-height: 300px;
      // overflow-y: auto;
      // .multi-line {
      //   padding: 0;
      //   .line {
      //     text-align: center;
      //     height: 60px;
      //     line-height: 60px;
      //     padding: 0 10px;
      //     border-bottom: 1px solid #ebeef5;
      //     .el-input,
      //     .el-date-editor,
      //     .salesName-input {
      //       margin: 13px 0;
      //     }
      //   }
      //   .line:last-child {
      //     border-bottom: none;
      //   }
      //   .el-form-item__error {
      //     margin-top: 0;
      //   }
      // }
    }
    ::v-deep {
      .el-form-item__error {
        margin-top: 0;
      }
    }
  }
</style>
