<template>
  <div>
    <el-dialog title="BOM单库存扣减" :visible.sync="dialogTableVisible">
      <h4>BOM单库存扣减设置</h4>
      <el-table
        :data="gridData"
        border
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column property="skuId" label="产品编码" width="120">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false">
              {{ scope.row.skuId }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          property="productName"
          label="产品名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="parentSpecsValue"
          label="规格"
          width="100"
        ></el-table-column>
        <el-table-column
          property="productType"
          label="产品类别"
          width="80"
        ></el-table-column>
        <el-table-column property="date" label="申请出库数量/单位" width="150">
          <template slot-scope="scope">
            <span>
              {{ scope.row.expectOutNum || '--' }}/{{
                scope.row.productUnit || '--'
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column property="address" label="出库库位" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.warehouseOutProductLocationVOList.length > 0">
              <div
                v-for="(item, index) in scope.row
                  .warehouseOutProductLocationVOList"
                :key="index"
                class="page-link"
                style="text-decoration: none"
                @click="showLoc(scope.row)"
              >
                {{ item.locationNumber }}（{{ item.num }}）；
              </div>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-wrapper mt30 t_a_c" style="text-align: center">
        <el-button type="primary" class="ml30" @click="sumbit">确定</el-button>
        <el-button @click="closeDia">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="出库库位" :visible.sync="locVisible" width="50%">
      <el-table :data="locTableData" border>
        <el-table-column
          property="locationNumber"
          label="库位号"
          align="center"
        ></el-table-column>
        <el-table-column
          property="num"
          label="出库数量"
          align="center"
        ></el-table-column>
      </el-table>
      <div class="font-class">
        <span>总放置数量：{{ totalNum }}</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import SelectedLocs from '../../inbound-sheet/components/selected-locs.vue'
  import Loc from './loc.vue'
  export default {
    components: {
      SelectedLocs,
      Loc,
    },
    data() {
      return {
        gridData: [],
        dialogTableVisible: false,
        locParams: {
          show: false,
          locs: [],
        },
        currentRow: {},
        locTableData: [],
        locVisible: false,
        totalNum: 0,
      }
    },
    props: {
      ruleType: {
        type: String,
      },
      warehouseId: {
        type: String,
      },
    },
    methods: {
      showLoc(showLoc) {
        this.locTableData = showLoc.warehouseOutProductLocationVOList
        this.locTableData.forEach((item) => {
          this.totalNum += item.num
        })
        this.locVisible = true
      },

      showDialog() {
        this.dialogTableVisible = true
      },
      setStock(val, index) {
        this.$emit('setStock', val, index)
      },
      closeDia() {
        this.dialogTableVisible = false
      },
      sumbit() {
        this.$emit('stockSubmit', this.gridData)
        this.dialogTableVisible = false
      },
      onClose(isLoc) {
        if (isLoc) {
          return (this.locParams.show = false)
        }
        this.locParams.show = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .font-class {
      text-align: right;
      line-height: 18px;
      padding-right: 10px;
      margin-top: 10px;
    }
  }
</style>
