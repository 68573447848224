import { mailSettingDetail } from '@/api/mail/setting.js'
export function getSettingMsg(val) {
  return new Promise((resolve, reject) => {
    mailSettingDetail({ tenantId: val }).then((res) => {
      if (res?.code === '000000') {
        return resolve(res?.data)
      }
    })
  })
}
