<!-- 出库申请弹窗 -->
<template>
  <el-dialog
    v-if="params.showApply"
    :visible.sync="params.showApply"
    title="出库申请"
    width="1400px"
    append-to-body
    modal-append-to-body
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :rules="rules" :model="applyForm">
      <el-table :data="applyForm.applyList" border striped>
        <el-table-column label="产品编码" align="center" prop="">
          <template slot-scope="scope">
            <el-link
              type="priamry"
              :underline="false"
              @click="skuIdClick(scope.row)"
            >
              {{ scope.row.sku }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="产品名称"
          align="center"
          prop="productCn"
        ></el-table-column>
        <el-table-column label="出库状态" align="center" prop="productCn">
          <template slot-scope="scope">
            {{ statusListMap[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column
          label="出库数量"
          align="center"
          prop="shippedNum"
        ></el-table-column>
        <el-table-column
          label="可用库存"
          align="center"
          prop=""
          :formatter="formatter"
        >
          <template slot="header">
            <i class="red">*</i>
            可用库存
          </template>
          <template slot-scope="scope">
            <span
              v-if="
                !scope.row.shippingPlanOrderProductStockVOS ||
                scope.row.shippingPlanOrderProductStockVOS.length === 0
              "
            >
              <span>-</span>
            </span>
            <template v-else>
              <p
                v-for="(w, index) in scope.row.shippingPlanOrderProductStockVOS"
                :key="index"
              >
                <span>{{ w.storageName }}:</span>
                <span>{{ w.stockNum }}</span>
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="出库仓库" align="center" prop="" width="180">
          <template slot="header">
            <i class="red">*</i>
            出库仓库
          </template>
          <template slot-scope="scope">
            <template
              v-if="
                scope.row.shippingPlanOrderProductStockVO &&
                scope.row.shippingPlanOrderProductStockVO.length === 0
              "
            >
              --
            </template>
            <template v-else></template>
            <!-- 没有出库单 -->
            <div v-if="scope.row.alreadyStockIn !== '1'">
              <template>
                <div style="display: flex; align-items: center">
                  <el-form-item
                    style="margin-bottom: 0"
                    :prop="'applyList.' + scope.$index + '.warehouseId'"
                    :rules="rules.storage"
                    v-if="
                      scope.row.shippingPlanOrderProductStockVOS &&
                      scope.row.shippingPlanOrderProductStockVOS.length > 0
                    "
                  >
                    <el-select
                      v-model="scope.row.warehouseId"
                      placeholder="请选择"
                      clearable
                      :disabled="
                        scope.row.stockDisable ||
                        [1, 2].includes(scope.row.hasNotCompleteInStock)
                      "
                      @change="(val) => whChange(val, scope.row, scope.$index)"
                      @visible-change="(bool) => whVisibleChange(bool, scope.$index, 2)"
                    >
                      <el-option
                        v-for="subitem in scope.row
                          .shippingPlanOrderProductStockVOS"
                        :key="subitem.storageId"
                        :label="subitem.storageName"
                        :value="subitem.storageId"
                      />
                    </el-select>
                  </el-form-item>
                  <ErpMouseoverTip
                    effect="dark"
                    placement="top"
                    v-if="[1, 2].includes(scope.row.hasNotCompleteInStock)"
                  >
                    <div>
                      <p
                        class="info-text"
                        v-if="scope.row.hasNotCompleteInStock === 1"
                      >
                        委外加工单未加工入库，请等待加工入库后进行此操作
                      </p>
                      <p class="info-text" v-else>
                        存在未确认入库的退货入库单，请确认入库后再出运
                      </p>
                    </div>
                  </ErpMouseoverTip>
                </div>
              </template>
            </div>
            <div v-else>
              <p
                v-for="(stock, index) in scope.row
                  .shippingPlanOrderProductArrangeStockVOS"
                :key="index"
              >
                {{ stock.warehouseName }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="入库批次"
          align="center"
          prop="shippedNum"
          width="180px"
        >
          <template slot="header">
            <i class="red">*</i>
            入库批次
          </template>
          <template slot-scope="scope">
            <!-- 批次详情 -->
            <LotDetail
              :lotList="scope.row.shippingOrderProductLotDTOList"
              :isChoose="scope.row.isChoose"
              @lot-sel="selLot"
              :index="scope.$index"
              :key="scope.$index + tableKey"
              :ref="scope.$index + 'locDetail'"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="批次出库数量"
          align="center"
          prop=""
          width="120"
        >
          <template slot-scope="scope">
            {{ getLotCountSum(scope.row.shippingOrderProductLotDTOList) }}
          </template>
        </el-table-column>
        <el-table-column label="买方" align="center" width="120">
          <template slot-scope="scope">
            {{ scope.row.purchaser || '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="120">
          <template slot="header">
            <i class="red">*</i>
            是否退税
          </template>
          <template slot-scope="scope">
            <el-form-item
              class="flex"
              :prop="'applyList.' + scope.$index + '.drawback'"
              :rules="[
                {
                  required: !scope.row.drawbackDisabled,
                  message: '是否退税必填',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="scope.row.drawback"
                placeholder="请选择"
                @change="(val) => changeDrawback(val, scope.$index, 2)"
                :disabled="scope.row.drawbackDisabled"
              >
                <el-option
                  v-for="item in drawbacks"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
              <ErpMouseoverTip
                v-if="scope.row.fromSale == 1 && scope.row.showTip"
                effect="dark"
              >
                你已修改此选项，且与采购订单上的「是否退税」选项不一致，请仔细核对
              </ErpMouseoverTip>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top-start"
            >
              <el-button
                type="danger"
                plain
                icon="el-icon-delete-solid"
                circle
                size="mini"
                @click="deletePro(scope.$index)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="saveApply" :loading="loading">
          确 定
        </el-button>
        <el-button @click="handleClose">取 消</el-button>
      </el-row>
    </template>
    <!-- 入库批次选择 -->
    <LotBatchSel ref="lotBatchSel" @lotlist-set="setLocList" />
    <!-- 修改是否退税提示弹框 -->
    <el-dialog
      :visible.sync="drawbackVisible"
      width="450px"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      custom-class="drawbackDialog"
    >
      <div class="content">
        <i class="el-icon-warning" />
        <div class="text">
          <p>
            你已修改「是否退税」选项，与采购订单上的此选项不一致，是否确认修改？
          </p>
          <p>建议你与采购人员进行核实，确认此产品是否退税。</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleDrawbackCancle(2)">
          取 消
        </el-button>
        <el-button @click="handleDrawbackConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import LotBatchSel from '@/components/lot-batch-sel/index.vue'
  import LotDetail from './lot-detail'
  import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'
  import { mixin } from '../helper/mixin'
  import { mapGetters } from 'vuex'
  import { statusListMap } from '../helper/data'
  import { deepCopy } from 'kits'
  export default {
    name: 'ApplyOut',
    mixins: [mixin],

    props: {
      params: {
        type: Object,
        default: () => ({
          showApply: false,
        }),
      },
    },

    data() {
      return {
        loading: false,
        applyForm: {
          applyList: [],
        },

        statusListMap,
        rules: {
          storage: [
            {
              required: true,
              message: '请选择',
            },
          ],
        },
        tableKey: Math.random(),
        // 是否退税字典
        drawbacks: [
          { name: '是', code: '1' },
          { name: '否', code: '0' },
        ],
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },

    components: {
      LotBatchSel,
      LotDetail,
    },
    mounted() {},

    methods: {
      // 删除产品
      deletePro(index) {
        if (this.applyForm.applyList.length === 1) {
          this.$message.warning('至少保留一条数据')
        } else {
          this.applyForm.applyList.splice(index, 1)
          this.$refs.form.clearValidate() // 删除时清除校验
        }
      },
      // 表格数据空时显示--
      formatter(row, column, cellValue, index) {
        return cellValue ? cellValue : '--'
      },
      // 数量校验
      _checkCount(tableData) {
        // 校验入库批次数量和出库数量相等
        for (let i = 0, l = tableData.length; i < l; i++) {
          const { shippedNum, shippingOrderProductLotDTOList, sku } =
            tableData[i]
          if (
            shippedNum !==
            shippingOrderProductLotDTOList.reduce((prev, next) => {
              return (prev += next.lotNumber * 1)
            }, 0)
          ) {
            this.$message.warning(`产品${sku}的入库批次数量和出库数量不相等`)
            return false
          }
        }
        return true
      },
      // 保存请求参数处理
      _getSaveRequestData(tableData) {
        const requestData = {}
        requestData.businessNumber = this.businessNumber
        requestData.number = this.number
        requestData.shippingOrderId = this.shippingOrderId
        const shippingOrderApplyOutStockDTOList = []
        tableData.forEach((item) => {
          var obj = {}
          obj.shippedNum = item.shippedNum
          obj.productCn = item.productCn
          obj.purchaser = item.purchaser // 买方
          obj.drawback = item.drawback // 是否退税
          obj.shippingOrderProductId = item.shippingOrderProductId
          obj.alreadyStockIn = item.alreadyStockIn
          if (obj.alreadyStockIn === '1') {
            item.shippingPlanOrderProductArrangeStockVOS.forEach((subItem) => {
              let childObj = { ...obj }
              childObj.warehouseOutNumber = subItem.warehouseOutNumber
              childObj.warehouseOutNum = subItem.warehouseOutNum
              childObj.outStockNum = subItem.warehouseOutNum
              childObj.storageId = subItem.warehouseId
              childObj.storageName = subItem.warehouseName
              childObj.shippingOrderProductLotDTOList = []
              shippingOrderApplyOutStockDTOList.push(childObj)
            })
          } else {
            obj.outStockNum = item.shippedNum
            obj.shippingOrderProductLotDTOList =
              item.shippingOrderProductLotDTOList
            obj.storageId = item.warehouseId
            obj.storageName = item.shippingPlanOrderProductStockVOS.find(
              (s) => s.storageId === item.warehouseId
            )?.storageName
            shippingOrderApplyOutStockDTOList.push(obj)
          }
        })
        requestData.shippingOrderApplyOutStockDTOList =
          shippingOrderApplyOutStockDTOList
        return requestData
      },
      // 取消
      handleClose() {
        this.batchSetted = false // 关闭弹框时，需要将变量重置设置为false，下次再打开弹框时还可以批量设置
        this.params.showApply = false
        this.loading = false
      },
      //保存
      saveApply() {
        this.$refs.form.validate((result) => {
          if (result) {
            let tableData = JSON.parse(JSON.stringify(this.applyForm.applyList))
            if (!this._checkCount(tableData)) return
            // console.log('提交', deepCopy(this._getSaveRequestData(tableData)))
            // return
            this.loading = true
            ShipmentMangeInteractor.applyOutStock(
              this._getSaveRequestData(tableData)
            ).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('操作成功')
                this.handleClose()
                this.$emit('refresh')
              } else {
                this.loading = false
              }
            })
          }
        })
      },
      // 产品编码点击跳转
      skuIdClick({ sku }) {
        this.getProductIdByCode(sku.split('-')[0])
      },
      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        if (id.indexOf('FL') > -1) return
        let response = await ShipmentMangeInteractor.productIdByCode({
          productCode: id,
        })
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      // 选择批次
      selLot(index) {
        this.currentLotIndex = index
        // 判断是否选择入库仓库
        const cr = this.applyForm.applyList[index]
        if (cr.alreadyStockIn == '1') {
          return this.$message.warning('无法更改')
        }
        if (!cr.warehouseId) {
          return this.$message.warning('请选择出库仓库')
        }
        const list = JSON.parse(
          JSON.stringify(cr.shippingOrderProductLotDTOList)
        )
        // 添加orderProductUniqueId
        this.$refs.lotBatchSel.show({
          list, // 已经选择的批次
          sku: cr.sku,
          orderProductUniqueId: cr.orderProductUniqueId,
          productName: cr.productCn,
          warehouseId: cr.warehouseId,
          applyNum: cr.shippedNum,
          orderCode: cr.businessNumber,
        })
        // 点击编辑icon时，存储当前的批次
        this.prevShippingOrderProductLotDTOList = deepCopy(
          cr.shippingOrderProductLotDTOList
        )
      },

      // 批次选择回调
      setLocList(list) {
        const { currentLotIndex: index } = this
        const currentLotList = list.map((item) => {
          item.lockLotFlag = 'lockLotFlag' in item ? item.lockLotFlag : 0
          item.num = item.lotNumber
          return item
        })
        this.applyForm.applyList[index].shippingOrderProductLotDTOList =
          currentLotList
        this.tableKey = Math.random()
        // 如果批次发生改变，点击提交时需要调一次接口重新回显买方和是否退税
        this.shippingOrderPurchase(currentLotList, index, 2)
      },

      // 显示申请弹窗
      async showApply({ list, option }) {
        this.loading = false
        list = JSON.parse(JSON.stringify(list))
        this.setExtraParams(option)
        list = list.map((item) => {
          if (item.alreadyStockIn === '1') {
            item.shippingOrderProductLotDTOList =
              item.warehouseInLotArrangeVOList.map((item) => {
                item.lotNumber = item.num
                return item
              }) || []

            let lockStock = item.shippingPlanOrderProductStockVOS.find(
              (item) => item.lockLotFlag === 1
            )
            if (lockStock) {
              item.warehouseId = lockStock.storageId
              item.hasLock = true
            }
          }
          let lockStock = item.shippingPlanOrderProductStockVOS.find(
            (item) => item.lockLotFlag === 1
          )
          if (lockStock) {
            item.warehouseId = lockStock.storageId
            item.hasLock = true
          }
          if ([1, 2].includes(item.hasNotCompleteInStock)) {
            item.warehouseId = ''
          }
          return {
            ...item,
            parentProduct: item.parentProduct ? [item.parentProduct] : [],
            shippingOrderProductLotDTOList: item.shippingOrderProductLotDTOList
              ? item.shippingOrderProductLotDTOList
              : [],
          }
        })
        this.applyForm.applyList = list
        this.params.showApply = true

        for (let i = 0; i < list.length; i++) {
          let pro = list[i]
          if (pro.warehouseId) {
            const {
              shippedNum: num,
              sku: skuId,
              warehouseId,
              hasLock,
              mainFlag,
              shippingPlanOrderProductId,
              orderProductUniqueId,
              hasNotCompleteInStock,
            } = pro
            const res = await ShipmentMangeInteractor.getStockCostConfigureVO({
              warehouseId: warehouseId,
            })
            if (res && res.code === '000000') {
              this.$set(
                pro,
                'isChoose',
                res.data?.warehouseInBatch === 1 ? true : false
              )
              pro.warehouseOutType = res.data?.warehouseOutType
            }
            if (
              pro.warehouseOutType == 1 &&
              pro.alreadyStockIn !== '1' &&
              ![1, 2].includes(pro.hasNotCompleteInStock)
            ) {
              if (num && warehouseId) {
                const res2 = await ShipmentMangeInteractor.lotRecommendArrange({
                  num,
                  skuId,
                  tenantId: this.userInfo.tenantId,
                  salesOrderNumber: pro.businessNumber,
                  warehouseId,
                  mainFlag,
                  shippingPlanOrderProductId,
                  orderProductUniqueId,
                  recommendLotDTOs: this.getRecommendLotDTOs(
                    i,
                    this.applyForm.applyList
                  ),
                })
                if (res2 && res2.code === '000000') {
                  pro.shippingOrderProductLotDTOList = res2.data.map(
                    (subItem) => {
                      subItem.lotNumber = subItem.num
                      return subItem
                    }
                  )
                  this.tableKey = Math.random() * Math.random()
                  // 初始化时，入库批次重置时，需要调接口回显买方和是否退税
                  this.shippingOrderPurchase(pro.shippingOrderProductLotDTOList, i, 2)
                }
                // 处理processOrReturn
                if (pro.shippingOrderProductLotDTOList?.length > 0) {
                  if (
                    pro.shippingOrderProductLotDTOList.some(
                      (item) => item.processOrReturn
                    )
                  ) {
                    this.$set(pro, 'stockDisable', true)
                  }
                }
              }
            }
          } else {
            // 初始化时没有出库单号，买方和是否退税重置，是否退税为禁用状态
            this.setEmptyByIndex(i, 2)
          }
        }
      },

      // 设置额外参数
      setExtraParams(obj) {
        for (let i in obj) {
          this[i] = obj[i]
        }
      },

      // 计算批次出库数量
      getLotCountSum(list = []) {
        const sum = list.reduce((prev, next) => {
          return (prev += next.lotNumber)
        }, 0)
        return sum ? sum : '-'
      },
      // 切换仓库
      async whChange(val, pro, index) {
        // 清空仓库时，重置买方、是否退税，是否退税为禁用状态
        if (!val) {
          this.setEmptyByIndex(index, 2)
        }
        pro.shippingOrderProductLotDTOList = []
        const { shippedNum: num, sku: skuId } = pro
        const warehouseId = val
        pro.shippingOrderProductLotDTOList = []
        if (!warehouseId) return
        const res = await ShipmentMangeInteractor.getStockCostConfigureVO({
          warehouseId,
        })
        if (res && res.code === '000000') {
          this.$set(
            pro,
            'isChoose',
            res.data?.warehouseInBatch === 1 ? true : false
          )
          pro.warehouseOutType = res.data?.warehouseOutType
        }

        if (pro.warehouseOutType == 1) {
          // 清空批次信息
          if (num && warehouseId) {
            ShipmentMangeInteractor.lotRecommend({
              num,
              skuId,
              tenantId: this.userInfo.tenantId,
              salesOrderNumber: this.businessNumber,
              warehouseId,
              recommendLotDTOs: this.getRecommendLotDTOs(
                index,
                this.applyForm.applyList
              ),
            }).then((res) => {
              if (res && res.code === '000000') {
                pro.shippingOrderProductLotDTOList = res.data.map((item) => {
                  item.lotNumber = item.num
                  return item
                })
                this.tableKey = Math.random()
                // 仓库change时，实时更新买方和是否退税
                this.shippingOrderPurchase(pro.shippingOrderProductLotDTOList, index, 2)
              }
            })
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .red {
    color: red;
  }
  .flex {
    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
    }
  }
</style>
<style lang="scss">
  // 是否退税确认弹框
  .drawbackDialog {
    .el-dialog__body {
      padding-top: 0;
      .content {
        display: flex;
        i {
          color: #ffba00;
          font-size: 24px;
          margin-right: 16px;
        }
        p:first-of-type {
          font-weight: bold;
        }
      }
    }
  }
</style>
