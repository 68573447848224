<template>
  <div class="track-list">
    <div class="track-search">
      <el-form :inline="true" :model="searchData" class="demo-form-inline">
        <!-- 地区 -->
        <el-form-item :label="$t('Documentary.area')" label-width="80" v-if="!isMini">
          <el-select v-model="searchData.area" clearable :placeholder="$t('Documentary.PC')" style="width: 75px">
            <el-option v-for="item in areaOption" :key="item.id" :label="lang === 'en' ? item.nameEn : item.name"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <!-- 供应商名称 -->
        <el-form-item :label="$t('Documentary.supplierName')" label-width="80">
          <el-input v-model="searchData.supplierName" style="width: 150px" :placeholder="$t('Documentary.PE')"
            @keyup.enter.native="onSubmit" clearable></el-input>
        </el-form-item>

        <el-form-item label-width="120px">
          <template slot="label">
            <el-select v-model="numberType" clearable @change="changeType">
              <el-option v-for="item in numberOption" :label="lang === 'en' ? item.nameEn : item.name"
                :value="item.value" :key="item.id"></el-option>
            </el-select>
          </template>
          <el-input v-model="orderNumber" style="width: 140px" :placeholder="$t('Documentary.PE')" clearable
            @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>

        <!-- 单据创建日期 -->
        <el-form-item :label="$t('Documentary.DCD')" label-width="120">
          <el-date-picker style="width: 260px" value-format="yyyy-MM-dd" v-model="searchTime" type="daterange"
            range-separator="~" :start-placeholder="$t('Documentary.SD')" :end-placeholder="$t('Documentary.ED')"
            @keyup.enter.native="onSubmit"></el-date-picker>
        </el-form-item>

        <!-- 角色 -->
        <el-form-item :label="$t('Documentary.Role')" label-width="80" v-if="!isMini">
          <el-input style="width: 100px" @focus="fromBusinessChange" v-model="userName" readonly
            :placeholder="$t('Documentary.PC')" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>

        <!-- 状态 -->
        <el-form-item :label="$t('Documentary.state')" label-width="60" v-if="!isMini">
          <el-select style="width: 85px" v-model="searchData.status" :placeholder="$t('Documentary.PC')"
            @keyup.enter.native="onSubmit">
            <el-option v-for="item in statusOption" :label="lang === 'en' ? item.nameEn : item.name" :value="item.value"
              :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <!-- 搜索 -->
          <el-button type="primary" @click="onSubmit">
            {{ $t('Documentary.search') }}
          </el-button>
          <!-- 重置 -->
          <el-button @click="reset">{{ $t('Documentary.reset') }}</el-button>
          <!-- 高级筛选 -->
          <el-button v-if="isMini" @click="highSearch">
            {{ $t('Documentary.AF') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-head">
      <div class="track-status" style="margin-right: 20px">
        <!-- 新增 -->
        <el-button type="primary" style="margin-right: 20px" @click="add">
          {{ $t('Documentary.add') }}
        </el-button>
        <div v-for="item in statusOption" :key="item.id"
          :class="item.active ? 'status-item status--active' : 'status-item'" @click="filterList(item)">
          <el-badge :value="setStatusNum(item.value)" class="item" :max="99">
            <span>{{ lang === 'en' ? item.nameEn : item.name }}</span>
          </el-badge>
        </div>
      </div>

      <div>
        <!-- 导出 -->
        <el-button type="primary" icon="el-icon-download" class="mr10" @click="downloadTable" v-show="lang === 'zh'">
          {{ $t('Documentary.export') }}
        </el-button>
        <!-- 自定义列表 -->
        <el-popover ref="showOption" popper-class="custom-table-checkbox" trigger="hover">
          <erp-draggable v-bind="dragOptions" :list="columns">
            <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
              :disabled="item.checkable === false" :label="item.label">
              {{ $t(item.langKey) }}
            </el-checkbox>
          </erp-draggable>
          <!-- 保存自定义显示列 -->
          <div class="save-btn">
            <el-button type="primary" class="ml10" size="mini" plain @click="operateSave">
              <!-- 保存 -->
              {{ $t('Documentary.save') }}
            </el-button>
          </div>
          <template #reference>
            <el-tooltip effect="dark" :content="$t('iconbtn.dragSort')" placement="top-start">
              <!-- 自定义显示 -->
              <el-button plain type="primary">
                {{ $t('Documentary.CD') }}
              </el-button>
            </el-tooltip>
          </template>
        </el-popover>
      </div>
    </div>
    <div class="track-table">
      <ErpTable :page-number.sync="page.pageNo" :page-size.sync="page.pageLe" :table-data="tableData" :total="total"
        :extraHeight="30" :class="isMini ? 'table-fixed-mini' : 'table-fixed'" @query="getList"
        @row-click="changeCurrent" highlight-current-row :key="customTableKey" ref="trackTable">
        <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="$t(item.langKey)"
          show-overflow-tooltip :width="lang === 'en' ? item.widthEn : item.width" min-width="120" :resizable="true"
          align="center" :prop="item.prop" :class-name="item.prop">
          <template slot-scope="scope">
            <div v-if="item.prop === 'NO'">{{ scope.$index + 1 }}</div>
            <!-- 区域 -->
            <div v-else-if="item.prop === 'area'">
              {{ scope.row.area | buyerAreaTransl }}
            </div>
            <!-- 节点名称 -->
            <div v-else-if="item.prop === 'nodeNameCn'">
              {{ lang === 'en' ? scope.row.nodeNameEn : scope.row.nodeNameCn }}
            </div>
            <!-- 采购开发 -->
            <div v-else-if="item.prop === 'buyerName'">
              {{ lang === 'en' ? scope.row.buyerNameEn : scope.row.buyerName }}
            </div>
            <!-- 订单支持 -->
            <div v-else-if="item.prop === 'fromBusinessName'">
              {{
              lang === 'en'
              ? scope.row.fromBusinessNameEn
              : scope.row.fromBusinessName
              }}
            </div>
            <!-- 客户代表 -->
            <div v-else-if="item.prop === 'businessName'">
              {{
              lang === 'en'
              ? scope.row.businessNameEn
              : scope.row.businessName
              }}
            </div>
            <!-- 产品名称 -->
            <div v-else-if="item.prop === 'productName'">
              {{
              lang === 'en' ? scope.row.productNameEn : scope.row.productName
              }}
            </div>
            <!-- 产品总金额 -->
            <div v-else-if="item.prop === 'amount'">
              {{ scope.row.currency === '美元' ? '$' : '￥'
              }}{{ scope.row.amount }}
            </div>
            <!-- 采购单总金额 -->
            <div v-else-if="item.prop === 'amountTotal'">
              {{ scope.row.currency === '美元' ? '$' : '￥'
              }}{{ scope.row.amountTotal }}
            </div>
            <div v-else-if="item.prop === 'diffDays'">
              <span v-if="scope.row.status == 5">
                <!-- 剩余 -->
                {{ $t('Documentary.remaining') }}
              </span>
              <span v-if="scope.row.status == 6">
                <!-- 已延期 -->
                {{ $t('Documentary.postponed') }}
              </span>
              <span v-if="isInclude(scope.row.status, [5, 6])">
                {{ scope.row.diffDays }}
              </span>
              <span v-if="isInclude(scope.row.status, [5, 6])">
                <!-- 天 -->
                {{ $t('Documentary.day') }}
              </span>
            </div>
            <div v-else-if="item.prop === 'status'">
              <el-tag :type="filterStatus(scope.row.status).type">
                {{
                lang === 'en'
                ? filterStatus(scope.row.status).nameEn
                : filterStatus(scope.row.status).nameCn
                }}
              </el-tag>
            </div>
            <div v-else-if="item.prop === 'amount'">
              <span v-if="isRight">
                {{ scope.row.amount }}
              </span>
              <span v-else>******</span>
            </div>
            <div v-else-if="item.prop === 'deliveryDays'">
              {{
              scope.row.deliveryDays || scope.row.deliveryDays === 0
              ? scope.row.deliveryDays
              : '--'
              }}
            </div>
            <div v-else>{{ scope.row[item.prop] || '--' }}</div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column :label="$t('Documentary.operate')" width="260" align="center" fixed="right">
          <!-- power 1：查看，2：操作（不含作废），3：操作（含作废） -->
          <template slot-scope="scope">
            <el-button v-if="
                isInclude(scope.row.status, [1, 2, 3, 4, 5, 6, 7]) &&
                isInclude(scope.row.power, [1, 2, 3])
              " type="text" @click="goDetail(scope.row)">
              <!-- 详情 -->
              {{ $t('Documentary.details') }}
            </el-button>
            <el-button v-show="
                isInclude(scope.row.status, [1, 2, 5, 6]) &&
                isInclude(scope.row.power, [2, 3])
              " v-allowed="['TRACK:UPDATE_NODE']" type="text" @click="goUpdate(scope.row)">
              <!-- 更新 -->
              {{ $t('Documentary.renew') }}
            </el-button>
            <el-button v-show="
                isInclude(scope.row.status, [1, 2, 5, 6]) &&
                isInclude(scope.row.power, [3])
              " v-allowed="['TRACK:MARK_VOID']" type="text" @click="showDialog(scope.row, 'cancel')">
              <!-- 作废 -->
              {{ $t('Documentary.void') }}
            </el-button>
            <el-button type="text" v-if="
                isInclude(scope.row.status, [1, 2, 5, 6]) &&
                isInclude(scope.row.power, [2, 3])
              " @click="setMark(scope.row)">
              <!-- 备注 -->
              {{ $t('Documentary.remark') }}
            </el-button>
            <el-button v-if="
                isInclude(scope.row.status, [1]) &&
                isInclude(scope.row.power, [2, 3])
              " type="text" @click="showDialog(scope.row, 'noNeedTrack')">
              <!-- 无需跟单 -->
              {{ $t('Documentary.NNTD') }}
            </el-button>
            <el-button v-if="
                isInclude(scope.row.status, [5]) &&
                isInclude(scope.row.power, [2, 3])
              " type="text" @click="showDialog(scope.row, 'relieveWarning')">
              <!-- 解除预警 -->
              {{ $t('Documentary.CW') }}
            </el-button>
            <el-button v-if="
                isInclude(scope.row.status, [1, 2, 5, 6]) &&
                scope.row.manualUpdateType === 0
              " type="text" @click="refreshNode(scope.row)">
              <!-- 刷新节点 -->
              {{ $t('Documentary.RN') }}
            </el-button>
            <el-button @click="showDialog(scope.row, 'recovery')" v-if="
                isInclude(scope.row.status, [4]) &&
                isInclude(scope.row.power, [2, 3])
              " type="text">
              <!-- 恢复 -->
              {{ $t('Documentary.recover') }}
            </el-button>
          </template>
        </el-table-column>
      </ErpTable>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="350px" :show-close="false">
      <h3 v-if="dialogType === 'relieveWarning'">
        <!-- 是否解除预警，解除预警后 状态恢复进行中 -->
        {{ $t('Documentary.WTRTW') }}
      </h3>
      <div v-if="dialogType === 'noNeedTrack'">
        <!-- 是否确定无需跟单 -->
        <h3>{{ $t('Documentary.AYSYD') }}</h3>
        <!-- 确认后 状态=无需跟单，并且不可逆 -->
        <h3>{{ $t('Documentary.ACSNN') }}</h3>
        <!-- 订单跟踪编号 -->
        <p style="margin-top: 10px">
          {{ $t('Documentary.OTN') }}：{{ orderTrackingNumber }}
        </p>
      </div>
      <div v-if="dialogType === 'cancel'">
        <!-- 是否要作废这笔单据 -->
        <h3>{{ $t('Documentary.DYWTV') }}</h3>
        <!-- 订单跟踪编号 -->
        <p style="margin-top: 10px">
          {{ $t('Documentary.OTN') }}：{{ orderTrackingNumber }}
        </p>
      </div>
      <div v-if="dialogType === 'recovery'">
        <!-- 是否要恢复这笔单据 -->
        <h3>{{ $t('Documentary.DYWTR') }}</h3>
        <!-- 订单跟踪编号 -->
        <p style="margin-top: 10px">
          {{ $t('Documentary.OTN') }}：{{ orderTrackingNumber }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="canelDialog">
          {{ $t('Documentary.Cancel') }}
        </el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="commitDialog">
          {{ $t('Documentary.Sure') }}
        </el-button>
      </span>
    </el-dialog>
    <remark-drawer :addRemark="addRemark" :orderTrackingId="orderTrackingId" @closeDrawer="closeDrawer"></remark-drawer>
    <!-- 选择成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    <el-drawer :title="$t('Documentary.AF')" :visible.sync="highSearchVisible">
      <track-high-search @highSearch="highSearchForm" @highSearchcCancel="highSearchcCancel"
        :isReset="isReset"></track-high-search>
    </el-drawer>
  </div>
</template>
<script>
  let self = null
  import { trackInterface } from '@/core'
  import STATIC from '../../utils/staticData.js'
  import remarkDrawer from './remark-drawer.vue'
  import UserChoose from '@/components/userChoose'
  import { mapGetters } from 'vuex'
  import CustomCache from '@/utils/custom-cache'
  import ErpDraggable from 'vuedraggable'
  import trackHighSearch from './track-high-search.vue'
  import customTableMixin from '@/utils/custom-table-mixin'
  import { areaTransl } from '@/utils/translate.js'
  import { deepCopy } from 'kits'
  import { hasRight } from '@/utils/permissionBtn.js'
  import * as dayjs from 'dayjs'
  import _ from 'lodash'
  export default {
    name: 'track-table',
    mixins: [customTableMixin],
    components: { remarkDrawer, UserChoose, ErpDraggable, trackHighSearch },
    data() {
      self = this
      return {
        tablekey: Math.random(),
        searchData: {
          area: '',
          supplierName: '',
          status: '',
        }, //查询条件
        userName: '', // 选择人员名称
        numberType: 'purchaseOrderNumber', // 选择的单号类型
        searchTime: [], // 时间
        orderNumber: '', //单号
        areaOption: STATIC.area_list, // 地区列表
        numberOption: STATIC.number_list, // 单据编号种类
        statusOption: _.cloneDeep(STATIC.status_list), // 状态
        columns: STATIC.columns, // 表格列
        dialogVisible: false,
        dialogType: '',
        addRemark: false, // 添加备注
        tableData: [],
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        listStatusNum: new Map(), //表格头部状态值
        orderTrackingId: '', // 跟踪单id
        orderTrackingNumber: '', // 跟踪单号
        userCustomizeColumnId: '', //列表自定义保存
        highSearchVisible: false, // 高级筛选
        isReset: false, // 是否重置
      }
    },
    created() {
      // 取当月月初和月末
      this.searchTime = [
        dayjs().startOf('month').format('YYYY-MM-DD'),
        dayjs().endOf('month').format('YYYY-MM-DD'),
      ]

      this.getColumsData()
    },
    activated() {
      this.getStatusNum()
      this.searchData.startTime = this.searchTime
        ? `${this.searchTime[0]} 00:00:00`
        : ''
      this.searchData.endTime = this.searchTime
        ? `${this.searchTime[1]} 00:00:00`
        : ''
      if (this.numberType != '') {
        this.searchData[this.numberType] = this.orderNumber
      }
      this.getPageList({ ...this.page, ...this.searchData })
    },
    filters: {
      buyerAreaTransl(n) {
        return self.lang === 'en' ? areaTransl(n) : n
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      isRight() {
        return hasRight('TRACK:VIEW_COST')
      },
      isMini() {
        return document.body.clientHeight > 801 ? false : true
      },
    },
    watch: {
      columns: {
        handler() {
          this.tablekey = Math.random()
        },
        deep: true,
      },
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.getList()
        }
      },
    },
    methods: {
      // 表格头部 状态数据
      async getStatusNum() {
        const obj = {
          startTime: this.searchTime ? `${this.searchTime[0]} 00:00:00` : '',
          endTime: this.searchTime ? `${this.searchTime[1]} 00:00:00` : '',
        }
        const res = await trackInterface.getGroupCount(
          obj.startTime,
          obj.endTime
        )

        if (res.code === '000000') {
          // 3,4 已完成。已作废 不展示总数
          let arr = res?.data?.filter((item) => {
            return [3, 4, 7].indexOf(item.statusCode) < 0
          })
          arr?.forEach((item) => {
            this.listStatusNum.set(item.statusCode, item.count)
          })
        }
      },
      setStatusNum(status) {
        return this.listStatusNum.get(Number(status))
      },
      // 表格数据
      async getPageList() {
        const lang = {
          have: this.lang === 'en' ? 'have' : '有',
          no: this.lang === 'en' ? 'none' : '无',
        }
        const params = {
          ...this.page,
          ...this.searchData,
          checkFlag: hasRight('TRACK:VIEW_COST') ? 1 : 0,
        }
        const res = await trackInterface.getListPage(params)

        if (res.code === '000000') {
          this.tableData = res?.data?.data?.map((item) => {
            return {
              ...item,
              sampleDesc:
                item.sample == 1
                  ? lang.have
                  : item.sample == 0
                    ? lang.no
                    : item.sample,
            }
          })
          this.tableData?.length > 0
            ? this.changeCurrent(this.tableData[0])
            : this.changeCurrent()
          this.$nextTick(() => {
            this.$refs.trackTable.$refs.table.setCurrentRow(this.tableData[0])
          })

          this.total = res?.data?.total
        }
      },
      // 状态筛选
      filterStatus(status) {
        let obj = {
          1: {
            value: '1',
            nameCn: '待安排',
            nameEn: 'To be arranged',
            type: '',
          },
          2: { value: '2', nameCn: '进行中', nameEn: 'processing', type: '' },
          3: {
            value: '3',
            nameCn: '已完成',
            nameEn: 'completed',
            type: 'success',
          },
          4: {
            value: '4',
            nameCn: '已作废',
            nameEn: 'Canceled',
            type: 'info',
          },
          5: {
            value: '5',
            nameCn: '预警中',
            nameEn: 'warning',
            type: 'danger',
          },
          6: { value: '6', nameCn: '延期', nameEn: 'Extended', type: 'danger' },
          7: {
            value: '7',
            nameCn: '无需跟单',
            nameEn: 'No need to document',
            type: 'info',
          },
        }
        return obj[status]
      },
      // 状态是否包含
      isInclude(val, arr) {
        arr = arr.map((item) => Number(item))
        return arr.includes(Number(val))
      },

      changeType() {
        this.numberOption.forEach((item) => {
          this.searchData[item.value] = ''
        })
      },

      // 查询
      onSubmit() {
        this.searchData.startTime = this.searchTime
          ? `${this.searchTime[0]} 00:00:00`
          : ''
        this.searchData.endTime = this.searchTime
          ? `${this.searchTime[1]} 00:00:00`
          : ''
        if (this.numberType != '') {
          this.searchData[this.numberType] = this.orderNumber
        }
        this.page.pageNo = 1
        let params = {
          ...this.searchData,
          ...this.page,
        }
        this.statusOption.forEach((item) => {
          this.$set(item, 'active', false)
        })
        this.statusOption.forEach((item) => {
          if (item.value === this.searchData.status) {
            this.$set(item, 'active', true)
          }
        })
        this.getPageList(params)
      },
      // 重置
      reset() {
        this.userName = ''
        this.numberType = 'purchaseOrderNumber'
        this.orderNumber = ''
        for (const key in this.searchData) {
          this.searchData[key] = ''
        }
        this.searchTime = [
          dayjs().startOf('month').format('YYYY-MM-DD'),
          dayjs().endOf('month').format('YYYY-MM-DD'),
        ]
        this.isReset = true
        this.onSubmit()
      },
      // 高级筛选
      highSearch() {
        this.highSearchVisible = true
      },
      highSearchForm(data) {
        this.searchData = {
          ...this.searchData,
          ...data,
        }
        this.onSubmit()
        this.highSearchVisible = false
      },
      highSearchcCancel() {
        this.highSearchVisible = false
      },
      // 分页
      getList() {
        this.getPageList()
      },
      // 状态查询
      filterList(statusItem) {
        this.statusOption.forEach((item) => {
          this.$set(item, 'active', false)
        })
        this.$set(statusItem, 'active', true)
        this.searchData.status = statusItem.value
        this.onSubmit()
      },
      // 备注
      setMark(val) {
        this.addRemark = true
        this.orderTrackingId = val.orderTrackingId
      },
      // 关闭drawer
      closeDrawer() {
        this.addRemark = false
        this.getStatusNum()
      },

      // 新增
      add() {
        this.$router.push('/track/orderTracking/trackAdd')
      },

      // 更新
      goUpdate(row) {
        this.$router.push({
          path: '/track/orderTracking/trackUpdate',
          query: {
            orderTrackingId: `${row.orderTrackingId}`,
            trackNumber: `${row.orderTrackingNumber}`,
            checkFlag: `${this.isRight}`,
          },
        })
      },

      // 详情
      goDetail(row) {
        this.$router.push({
          path: '/track/orderTracking/trackDetail',
          query: {
            orderTrackingId: `${row.orderTrackingId}`,
            trackNumber: `${row.orderTrackingNumber}`,
            checkFlag: `${this.isRight}`,
          },
        })
      },

      // 选择角色
      fromBusinessChange() {
        this.$refs['UserChoose'].showAddEdit(
          '1',
          deepCopy(this.formBusinessArray),
          true
        )
      },

      chooseUser(userIdArr) {
        this.searchData.userId = userIdArr[0].id
        this.userName = userIdArr[0].name
      },
      // 弹窗 显示
      showDialog(val, type) {
        this.orderTrackingId = val.orderTrackingId
        this.orderTrackingNumber = val.orderTrackingNumber
        this.dialogVisible = true
        this.dialogType = type
      },
      // 弹窗 确定
      commitDialog() {
        switch (this.dialogType) {
          // 解除预警
          case 'relieveWarning':
            this.openDialog(trackInterface.cancelWarning)
            break
          // 无需跟单
          case 'noNeedTrack':
            this.openDialog(trackInterface.notTracking)
            break
          // 废除订单
          case 'cancel':
            this.openDialog(trackInterface.invalidTrack)
            break
          // 恢复订单
          case 'recovery':
            this.openDialog(trackInterface.recoverTrack)
            break
        }
      },
      // 弹窗取消
      canelDialog() {
        this.dialogVisible = false
        this.orderTrackingId = ''
      },
      async openDialog(inter) {
        const res = await inter(this.orderTrackingId)

        if (res.code === '000000') {
          this.$message.success(this.$t('Documentary.SO'))
          this.dialogVisible = false
          this.onSubmit()
          this.getStatusNum()
        }
      },
      // 点击一行
      changeCurrent(listItem) {
        listItem = listItem || {
          orderTrackingId: 999999999999999,
        }
        const { orderTrackingId } = listItem

        if (this.oldOldTrackingId === orderTrackingId) {
        } else {
          this.$emit('current-change', listItem)
        }
        this.oldOldTrackingId = orderTrackingId
      },
      // 导出
      async downloadTable() {
        let data = {
          ...this.searchData,
          ...this.page,
          checkFlag: hasRight('TRACK:VIEW_COST') ? 1 : 0,
        }
        let that = this
        await trackInterface.exportOrderTracking(data).then((res) => {
          load(res)
        })

        // 下载文件流
        function load(data) {
          var linkElement = document.createElement('a')
          var blob = new Blob([data], {
            type: 'application/vnd.ms-excel;',
          })
          var url = window.URL.createObjectURL(blob)
          linkElement.setAttribute('href', url)
          const downloadDate = that.utils.timeFormat(new Date())
          let fileName = `${downloadDate}-${that.lang === 'en' ? 'tracking slip' : '跟踪单'
            }`
          linkElement.setAttribute('download', `${fileName}.xlsx`)
          document.body.appendChild(linkElement)
          linkElement.click()
          document.body.removeChild(linkElement)
          URL.revokeObjectURL(linkElement.href) // 释放URL 对象
        }
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(12, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },

      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 12,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              this.$t('Documentary.SS'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
      // 刷新节点
      async refreshNode({ orderTrackingId }) {
        const res = await trackInterface.manualUpdate({ orderTrackingId })
        if (res.code === '000000') {
          let data = res.data
          for (const key in data) {
            this.$message({
              type: 'success',
              message: `${key}${this.$t('Documentary.renew')}${data[key]
                }${this.$t('Documentary.DA')}！`,
            })
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .track-list {
    box-sizing: border-box;
    flex: 2;

    .track-search {
      // background-color: #f0f0f0;
      padding: 10px 10px 0px 10px;

      ::v-deep {
        .el-form-item {
          margin-right: 20px;
        }
      }
    }

    .table-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }

    .track-status {
      display: flex;
      align-items: center;

      .status-item {
        box-sizing: border-box;
        padding: 3px 5px;
        margin-right: 15px;
      }

      .status--active {
        border-bottom: 1px solid #1890ff;
        color: #1890ff;
      }

      .status-item:hover {
        cursor: pointer;
        color: #1890ff;
      }
    }

    .track-table {
      margin-top: 20px;

      .table-fixed {
        max-height: 400px !important;

        ::v-deep {
          th {
            height: 35% !important;
            max-height: 35px !important;
            line-height: 35px !important;
          }

          td {
            height: 35% !important;
            max-height: 35px !important;
            line-height: 35px !important;
          }
        }
      }

      .table-fixed-mini {
        max-height: 300px !important;
      }
    }
  }

  ::v-deep {
    .masBox {
      height: 1000px !important;
    }

    .area .cell {
      width: auto !important;
    }
  }
</style>