<template>
  <div class="oper">
    <el-row>
      <el-page-header
        @back="goBack"
        :content="
          pageType == 'detail' ? '结算人员配置角色详情' : '结算人员配置角色编辑'
        "
        title="返回"
      ></el-page-header>
    </el-row>
    <el-row :gutter="20" class="m30">
      <el-col :span="1" class="item-title">角色</el-col>
      <el-col :span="23" :offset="1" class="m10">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            align="center"
            prop="area"
            show-overflow-tooltip
            width="80"
            label="地区"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="standardCustomized"
            show-overflow-tooltip
            width="100"
            label="产品类型"
          ></el-table-column>
          <el-table-column
            v-for="item in roleList"
            :key="item.code"
            :prop="item.code"
            :label="item.message"
            min-width="120"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row[getRoleCode(item.message)]">
                <span>
                  {{ scope.row[getRoleCode(item.message)].mark }}
                </span>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="m30">
      <el-col :span="1" class="item-title">人员</el-col>
      <el-col :span="23" :offset="1" class="m10">
        <el-form :rules="rules" :model="form" ref="ruleForm">
          <el-table
            :data="form.personnelTable"
            border
            style="width: 100%"
            class="input-table"
          >
            <el-table-column
              label="员工姓名（英文）"
              align="center"
              label-class-name="required-th"
            >
              <template slot-scope="scope">
                <span>
                  {{ scope.row.personnoelCn }}（{{ scope.row.personnoelEn }}）
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="条件"
              align="center"
              prop="conditionDesc"
              width="500"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.conditionDesc"
                  placeholder="请选择"
                  clearable
                  @focus="setCondition(scope)"
                  :disabled="inputEdit"
                  @clear="clearRoleCondition(scope)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="条件提成点" align="center" prop="ratio">
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'personnelTable.' + scope.$index + '.configCommissionDesc'
                  "
                  :rules="[
                    {
                      required: scope.row.conditionDesc,
                      message: '请选择',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.configCommissionDesc"
                    placeholder="请选择"
                    clearable
                    @focus="setAppoint(scope)"
                    :disabled="inputEdit"
                    @clear="clearCommission(scope)"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="条件角色" align="center" prop="roleType">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'personnelTable.' + scope.$index + '.roleType'"
                  :rules="[
                    {
                      required: scope.row.conditionDesc,
                      message: '请选择',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="scope.row.roleType"
                    placeholder="请选择"
                    :disabled="inputEdit"
                  >
                    <el-option
                      v-for="item in scope.row.rowRoleList"
                      :key="item.code"
                      :label="item.message"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="模板生效时间"
              align="center"
              prop="effectiveTime"
              label-class-name="required-th"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'personnelTable.' + scope.$index + '.effectiveTime'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    :disabled="inputEdit"
                    v-model="scope.row.effectiveTime"
                    type="date"
                    placeholder="请选择"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="120px"
              v-if="!inputEdit"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="deleteItem(scope)" size="medium">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-col>
      <el-col :span="1" :offset="11">
        <el-button type="text" @click="pushNew" v-if="!inputEdit" size="medium">
          新增一行
        </el-button>
      </el-col>
    </el-row>
    <el-row class="stick30" style="margin-top: 60px">
      <el-col :offset="11" :span="1">
        <el-button @click="goBack">返回</el-button>
      </el-col>
      <el-col :span="1" v-if="!inputEdit">
        <el-button type="primary" @click="save">保存</el-button>
      </el-col>
    </el-row>
    <!-- 条件模板选择 -->
    <condititon-model
      :modelVisible.sync="modelVisible"
      @submitCondition="submitCondition"
    ></condititon-model>
    <!-- 条件提成点 -->
    <person-condition-appoint
      :appointVisible.sync="appointVisible"
      @setRow="setRow"
    ></person-condition-appoint>
  </div>
</template>
<script>
import { templateConfigOperColumns } from '@/views/finance-center/config-manage/helper'
import { SettleConfigInteractor } from '@/core/interactors/finance/config-manage'
import condititonModel from './components/condititon-model'
import personConditionAppoint from './components/person-condition-appoint'
import _ from 'lodash'

import mixins from './mixins'
export default {
  mixins: [mixins],
  components: {
    condititonModel,
    personConditionAppoint,
  },
  data() {
    return {
      roleColumn: templateConfigOperColumns,
      tableData: [],
      form: {
        personnelTable: [],
      },

      configCommissionId: '',
      personnelId: '',
      pageType: '', // 详情 或者 编辑
      modelVisible: false,
      appointVisible: false,
      conditionRoleList: [],
      currentIndex: 1,
      deleteUser: {},
      rules: {},
    }
  },
  created() {
    this.getRoles()
    this.personnelId = this.$route.query.uid
    this.configCommissionId = this.$route.query.id
    this.pageType = this.$route.query.str
    this.getDetail()
  },
  computed: {
    inputEdit() {
      return this.pageType === 'detail'
    },
  },
  methods: {
    async getDetail() {
      let params = {
        personnelId: this.personnelId,
        configCommissionId: this.configCommissionId,
      }
      let res = await SettleConfigInteractor.getPersonnelGetDetail(params)
      this.tableData.push(res.configCommissionVO)
      this.form.personnelTable = res.configCommissionPersonnelVO.map((item) => {
        return {
          ...item,
          rowRoleList:
            item.conditioning?.indexOf('ROLE_AREA') > -1
              ? this.roleList
              : JSON.parse(item.conditioning),
          configCommissionDesc:
            item.ratio || item.ratio === 0 ? `固定,${item.ratio}` : '',
        }
      })
      this.deleteUser = {
        personnoelCn: this.form.personnelTable[0].personnoelCn,
        personnoelEn: this.form.personnelTable[0].personnoelEn,
        personnoelId: this.form.personnelTable[0].personnelId,
      }
    },
    getRoleByCode(code) {
      let obj = this.roleList.find((item) => {
        return item.code === code
      })

      return obj.message
    },
    // 清空提成点
    clearCommission(scope) {
      this.$set(
        this.form.personnelTable[scope.$index],
        'configCommissionId',
        ''
      )
      this.$set(this.form.personnelTable[scope.$index], 'ratio', '')
    },
    // 清空条件
    clearRoleCondition(scope) {
      this.$set(this.form.personnelTable[scope.$index], 'roleType', '')
      this.$set(this.form.personnelTable[scope.$index], 'conditionResult', '')
      this.$set(this.form.personnelTable[scope.$index], 'conditioning', '')
      delete this.form.personnelTable[scope.$index].rowRoleList
    },
    // 条件
    setCondition(scope) {
      this.currentIndex = scope.$index
      this.modelVisible = true
    },
    // 条件提成点
    setAppoint(scope) {
      this.currentIndex = scope.$index
      this.appointVisible = true
    },
    // 新增一行
    pushNew() {
      let obj = {
        ...this.deleteUser,
      }
      this.form.personnelTable.push(obj)
    },
    // 删除
    deleteItem(scope) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.form.personnelTable.splice(scope.$index, 1)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 条件模板
    submitCondition(chooseobj) {
      this.$set(
        this.form.personnelTable[this.currentIndex],
        'conditionDesc',
        chooseobj.conditionDesc
      )
      this.$set(
        this.form.personnelTable[this.currentIndex],
        'conditionResult',
        chooseobj.result
      )
      this.$set(
        this.form.personnelTable[this.currentIndex],
        'conditioning',
        chooseobj.conditionResult
      )
      this.$set(
        this.form.personnelTable[this.currentIndex],
        'rowRoleList',
        JSON.parse(chooseobj.conditionResult)
      )
      this.$set(this.form.personnelTable[this.currentIndex], 'roleType', '')
      if (chooseobj.type == 2) {
        this.$set(
          this.form.personnelTable[this.currentIndex],
          'rowRoleList',
          this.roleList
        )
      }
    },
    // 条件提成点
    setRow(row) {
      this.$set(
        this.form.personnelTable[this.currentIndex],
        'configCommissionId',
        this.businessId
      )
      this.$set(
        this.form.personnelTable[this.currentIndex],
        'ratio',
        row.fixedRatio
      )

      this.$set(
        this.form.personnelTable[this.currentIndex],
        'configCommissionDesc',
        `${row.typeDesc},${row.fixedRatio}`
      )
    },
    // 返回
    goBack() {
      this.$router.push({
        path: '/finance-center/config-manage',
        query: {
          tab: 2,
          configTab: 'person',
        },
      })
    },
    // 保存
    async save() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          let arr = _.cloneDeep(this.form.personnelTable)
          arr = arr.map((item) => {
            delete item.conditionDesc
            delete item.configCommissionDesc
            delete item.rowRoleList
            item.configCommissionId = this.configCommissionId
            return {
              ...item,
            }
          })
          let params = {
            configCommissionId: this.configCommissionId,
            personnelDTOs: [...arr],
            personnoelId: this.personnelId,
          }

          let res = await SettleConfigInteractor.setPersonnelUpdatePOs(params)
          if (res) {
            this.$message.success('编辑成功')
            this.goBack()
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.oper {
  padding: 20px 10px;
  height: calc(100vh - 45px);
  overflow-y: scroll;
  .item-title {
    text-align: right;
    padding: 0px !important;
    transform: translateX(10px);
  }
  .item-title::before {
    content: '';
    border-right: 7px solid blue;
    margin-right: 5px;
  }
  .m30 {
    margin-top: 30px;
  }
  .m10 {
    margin-top: 10px;
  }
  .stick30 {
    position: sticky;
    bottom: 30px;
  }
}
</style>
