<!--出库单详情-->
<template>
  <PrintWrapper>
    <div style="background: #fff; height: 100vh" id="printTest1">
      <div
        class="print-wrapper"
        style="width: 900px; margin: 0 auto; padding-top: 30px"
      >
        <p class="title">
          <span class="header mr15">
            出库单号:
            <span class="ml15">{{ detail.warehouseOutNumber }}</span>
          </span>
        </p>
        <table style="width: 100%; border-collapse: collapse" class="mt20">
          <!--销售出库 -->
          <template v-if="typeIsXS">
            <tr>
              <td class="label" width="111">出库方式</td>
              <td width="320" colspan="2">{{ detail.type | typeFilter }}</td>
              <td class="label">出库仓库</td>
              <td colspan="2">{{ detail.warehouseName }}</td>
              <td class="label" width="111">订单编号</td>
              <td width="320" colspan="2">
                {{ detail.orderNumber ? detail.orderNumber : '--' }}
              </td>

              <!-- <td class="label" width="111">客户姓名</td>
            <td colspan="2">
              {{ detail.customerName ? detail.customerName : '--' }}
            </td> -->
            </tr>
            <template v-if="typeIsConfirm">
              <tr>
                <td class="label" width="130">预计出库时间</td>
                <td width="320" colspan="2">
                  {{ detail.expectOutDate ? detail.expectOutDate : '--' }}
                </td>
                <td class="label" width="111">创建人</td>
                <td width="320" colspan="2">
                  {{ detail.creator ? detail.creator : '--' }}
                </td>
                <!-- <td class="label" width="111">所属地区</td>
              <td width="320" colspan="2">
                {{ detail.creatorArea ? detail.creatorArea : '--' }}
              </td> -->
                <!-- <td class="label">操作人</td>
              <td colspan="2">
                {{ detail.operationName ? detail.operationName : '--' }}
              </td> -->
                <td class="label">所属地区</td>
                <td colspan="2">
                  {{ detail.creatorArea ? detail.creatorArea : '--' }}
                </td>
              </tr>
              <!-- <tr>
              <td class="label" width="111">预计出库时间</td>
              <td colspan="6">
                {{ detail.expectOutDate ? detail.expectOutDate : '--' }}
              </td>
            </tr> -->
              <tr>
                <td class="label" width="130">创建时间</td>
                <td width="320" colspan="2">
                  {{ detail.createTime ? detail.createTime : '--' }}
                </td>
                <td class="label" width="111">操作人</td>
                <td width="320" colspan="2">
                  {{ detail.operationName ? detail.operationName : '--' }}
                </td>
                <!-- <td class="label" width="111">所属地区</td>
              <td width="320" colspan="2">
                {{ detail.creatorArea ? detail.creatorArea : '--' }}
              </td> -->
                <!-- <td class="label">操作人</td>
              <td colspan="2">
                {{ detail.operationName ? detail.operationName : '--' }}
              </td> -->
                <td class="label">出库时间</td>
                <td colspan="2">
                  {{ detail.actualOutDate ? detail.actualOutDate : '--' }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="label" width="111">客户代表</td>
                <td width="320" colspan="2">
                  {{ detail.businessName ? detail.businessName : '--' }}
                </td>
                <td class="label" width="111">出库仓库</td>
                <td width="320" colspan="2">
                  {{ detail.warehouseName }}
                </td>
                <td class="label" width="111">操作人</td>
                <td colspan="2">
                  {{ detail.operationName ? detail.operationName : '--' }}
                </td>
              </tr>
            </template>
          </template>

          <!-- 退货出库 -->
          <template v-if="typeIsTh">
            <tr>
              <td class="label" width="111">出库方式</td>
              <td width="320" colspan="2">{{ detail.type | typeFilter }}</td>
              <td class="label" width="111">采购单号</td>
              <td width="320" colspan="2">
                {{ detail.orderNumber ? detail.orderNumber : '--' }}
              </td>
              <td class="label" width="111">供应商</td>
              <td colspan="2">
                {{ detail.supplierName ? detail.supplierName : '--' }}
              </td>
            </tr>
            <tr>
              <td class="label" width="111">出库仓库</td>
              <td width="320" colspan="2">{{ detail.warehouseName }}</td>

              <td class="label" width="111">创建人</td>
              <td width="320" colspan="2">
                {{ detail.creator ? detail.creator : '--' }}
              </td>
              <td class="label" width="111">所属地区</td>
              <td width="320" colspan="2">
                {{ detail.creatorArea ? detail.creatorArea : '--' }}
              </td>
            </tr>
            <tr>
              <td class="label" width="111">操作人</td>
              <td width="320" colspan="8">
                {{ detail.operationName ? detail.operationName : '--' }}
              </td>
            </tr>
          </template>

          <!-- 其他出库 -->
          <template v-if="typeIsQt">
            <tr>
              <td class="label" width="111">出库方式</td>
              <td width="320" colspan="2">{{ detail.type | typeFilter }}</td>
              <td class="label" width="111">出库仓库</td>
              <td width="320" colspan="2">
                {{ detail.warehouseName }}
              </td>
              <!-- <td class="label" width="111">操作人</td>
            <td colspan="2">
              {{ detail.operationName ? detail.operationName : '--' }}
            </td> -->
              <td class="label" width="111">创建人</td>
              <td width="320" colspan="2">
                {{ detail.creator ? detail.creator : '--' }}
              </td>
            </tr>
            <tr>
              <!-- <td class="label" width="111">创建人</td>
            <td width="320" colspan="2">{{ detail.creator }}</td> -->
              <td class="label">所属地区</td>
              <td colspan="2">
                {{ detail.creatorArea ? detail.creatorArea : '--' }}
              </td>
              <td class="label" width="130">创建时间</td>
              <td width="320" colspan="2">
                {{ detail.createTime ? detail.createTime : '--' }}
              </td>
              <td class="label" width="111">操作人</td>
              <td width="320" colspan="4">
                {{ detail.operationName ? detail.operationName : '--' }}
              </td>
              <!-- <td class="label" width="111">预计出库时间</td>
            <td colspan="2">
              {{ detail.expectOutDate ? detail.expectOutDate : '--' }}
            </td> -->
            </tr>
            <tr>
              <td class="label">出库时间</td>
              <td colspan="8" class="lineh-22" v-if="typeIsQt">
                {{ detail.actualOutDate ? detail.actualOutDate : '--' }}
              </td>
            </tr>
          </template>

          <tr>
            <td class="label">备注</td>
            <td colspan="8" class="lineh-22">
              {{ detail.remark ? detail.remark : '--' }}
            </td>
          </tr>

          <!--销售出库 -->
          <tr v-if="typeIsXS">
            <td class="title">产品编码</td>
            <td class="title" colspan="3">产品名称</td>
            <td class="title" colspan="2">规格</td>
            <td class="title">产品类别</td>
            <!-- <td class="title">单位</td> -->
            <td class="title" width="140" colspan="2">申请出库数量/单位</td>
            <!-- <td class="title" colspan="2">实际出库数量</td>
          <td class="title">备用品数量</td> -->
          </tr>

          <!-- 退货出库 -->
          <tr v-if="typeIsTh">
            <td class="title">SKU ID</td>
            <td class="title">产品名称</td>
            <td class="title">规格</td>
            <td class="title">产品类型</td>
            <td class="title">单位</td>
            <td class="title">已入库数量</td>
            <td class="title" colspan="2">实际出库数量</td>
          </tr>

          <!-- 其他出库 -->
          <tr v-if="typeIsQt">
            <td class="title">产品编码</td>
            <td class="title" colspan="2">产品名称</td>
            <td class="title" colspan="2">规格</td>
            <td class="title">产品类型</td>
            <td class="title">单位</td>
            <td class="title">申请出库数量/单位</td>
          </tr>

          <template v-for="pro in detail.warehouseOutProductVOList">
            <!-- 销售处库 -->
            <tr :key="`${pro.skuId}1`" v-if="typeIsXS">
              <td class="label bg">{{ pro.skuId }}</td>
              <td class="label bg" colspan="3">
                {{ pro.productName }}
              </td>
              <td class="label bg" colspan="2">
                {{ pro.productSpec ? pro.productSpec : '--' }}
              </td>
              <td class="bg">{{ pro.productType }}</td>
              <td class="bg">{{ pro.expectOutNum }}/{{ pro.productUnit }}</td>
              <!-- <td class="bg">
              {{ pro.expectOutNum / pro.productUnit }}
            </td> -->
            </tr>
            <!--退货出库 -->
            <tr :key="`${pro.skuId}1`" v-if="typeIsTh">
              <td class="label bg">{{ pro.skuId }}</td>
              <td class="label bg">
                {{ pro.productName }}
              </td>
              <td class="label bg">
                {{ pro.productSpec ? pro.productSpec : '--' }}
              </td>
              <td class="bg">{{ pro.productType }}</td>
              <td class="bg">
                {{ pro.productUnit }}
              </td>
              <td class="bg">
                {{ pro.contractNum }}
              </td>

              <td class="bg" colspan="2">{{ pro.warehouseOutNum }}</td>
            </tr>

            <!-- 其他出库 -->
            <tr :key="`${pro.skuId}1`" v-if="typeIsQt">
              <td class="label bg">{{ pro.skuId }}</td>
              <td class="label bg" colspan="2">
                {{ pro.productName }}
              </td>
              <td class="label bg" colspan="2">
                {{ pro.productSpec ? pro.productSpec : '--' }}
              </td>
              <td class="bg">{{ pro.productType }}</td>
              <td class="bg">
                {{ pro.productUnit }}
              </td>
              <td class="bg">{{ pro.expectOutNum }}/ {{ pro.productUnit }}</td>
            </tr>

            <tr :key="`${pro.skuId}2`">
              <td class="label">出库库位</td>
              <td colspan="8">{{ pro | loctionFilter }}</td>
            </tr>
            <tr :key="`${pro.skuId}3`">
              <td class="label">备注</td>
              <td colspan="8" class="lineh-22">
                {{ pro.remark ? pro.remark : '--' }}
              </td>
            </tr>
          </template>

          <!--销售出库 -->
          <!-- <tr v-if="typeIsXS">
          <td class="label bg">总计</td>
          <td class="bg" colspan="4"></td>
          <td class="label bg">{{ getSums('expectOutNum') }}</td>
          <td class="label bg" colspan="2">
            {{ getSums('warehouseOutNum') }}
          </td>
          <td class="label bg">{{ getSums('giftNum') }}</td>
        </tr> -->

          <!-- 退货出库 -->
          <!-- <tr v-if="typeIsTh">
          <td class="label bg">总计</td>
          <td class="bg" colspan="4"></td>
          <td class="label bg">{{ getSums('contractNum') }}</td>

          <td class="label bg" colspan="2">{{ getSums('warehouseOutNum') }}</td>
        </tr> -->

          <!-- 其他出库 -->
          <!-- <tr v-if="typeIsQt">
          <td class="label bg">总计</td>
          <td class="bg" colspan="4"></td>

          <td class="label bg">{{ getSums('warehouseOutNum') }}</td>
          <td class="label bg" colspan="2">{{ getSums('giftNum') }}</td>
        </tr> -->
        </table>
      </div>
    </div>
  </PrintWrapper>
</template>

<script>
import { getDetail } from '@/api/stock-center/outbound-sheet'
import { findWarehouseList } from '@/api/stock-center/inbound-sheet'
import Loc from './components/loc'
import PrintWrapper from '@/views/print/printWrapper'
let self
export default {
  name: 'outbound-sheet-detail',
  components: {
    Loc,
    PrintWrapper,
  },
  data() {
    self = this
    return {
      warehouses: [],
      showOutLocDialog: false,
      detail: {
        area: 'aaaaaaaa',
        createWay: 0,
        fileNames: '',
        fileUrls: '',
        operationId: '',
        operationName: 'xxxxx',
        orderNumber: 'xxxxxxxx',
        remark: 'xxxxxxxx',
        status: 0,
        supplierName: 'xxxxxx',
        type: 3,
        warehouseId: '',
        warehouseInId: '',
        warehouseInNumber: '',
        adjustStatus: 0,

        warehouseOutProductVOList: [
          {
            contractNum: 0,
            giftNum: 0,
            priceExcludingTax: 0,
            priceIncludingTax: 0,
            productName: '',
            productSpec: '',
            productType: '',
            productUnit: '',
            remark: '',
            skuId: '',
            tenantId: '',
            totalPriceExcluding: 0,
            totalPriceIncluding: 0,
            warehouseInId: '',
            warehouseInNum: 0,
            warehouseInProductId: '',
            warehouseInProductLocationVOList: [
              {
                locationNumber: '',
                num: 0,
                warehouseInProductLocationId: '',
              },
            ],
          },
        ],
      },
      locs: [],
    }
  },

  computed: {
    warehouseInId() {
      return this.$route.query.warehouseInId
    },
    //销售出库
    typeIsXS() {
      return this.detail.type === 3
    },
    //退货出库
    typeIsTh() {
      return this.detail.type === 4
    },
    //其他出库
    typeIsQt() {
      return this.detail.type === 5
    },
    typeIsConfirm() {
      return this.detail.createWay !== 0
    },
    thTitle() {
      if (this.typeIsXS) {
        return '订单待出库数量'
      } else {
        return '合同数量'
      }
    },
  },

  methods: {
    getSums(field) {
      let arr = this.detail.warehouseOutProductVOList
        .map((item) => item[field])
        .filter((item) => !isNaN(item))
      if (arr.length === 0) {
        return '--'
      } else {
        var sums = arr.reduce((prev, next) => prev + next)
        if (sums === 0 && field === 'contractNum') {
          return '--'
        } else {
          return sums
        }
      }
    },

    onClose() {
      this.locParams.show = false
    },
    addLoc(row) {
      this.locs = row.warehouseOutProductLocationVOList || []
      if (this.locs.length === 0) {
        return this.$message.warning('没有出库库位数据')
      }
      this.showOutLocDialog = true
    },
  },
  filters: {
    typeFilter(val) {
      const types = [
        //入库方式
        {
          code: 3,
          name: '销售出库',
        },
        {
          code: 4,
          name: '退货出库',
        },
        {
          code: 5,
          name: '其他出库',
        },
      ]
      const targetType = types.find((type) => type.code === val)
      return targetType ? targetType.name : '--'
    },
    loctionFilter(val) {
      const { warehouseOutProductLocationVOList } = val
      if (
        !warehouseOutProductLocationVOList ||
        warehouseOutProductLocationVOList.length === 0
      ) {
        return '--'
      }

      return warehouseOutProductLocationVOList
        .map((item) => `${item.locationNumber}(${item.num})`)
        .join('/')
    },
  },
  created() {
    const { warehouseOutId } = this.$route.query
    getDetail({ warehouseOutId }).then((res) => {
      if (res.code == '000000') {
        this.detail = res.data
      }
    })
    findWarehouseList().then((res) => {
      this.warehouses = res.data || []
    })
  },
}
</script>

<style scoped lang="scss">
.table-container {
  background: #fff;
  padding: 20px !important;
  height: 100vh;
  & .title {
    display: flex;
    align-items: center;
  }
  & .detail-fields {
    // background-color: #f5f7fa;
    .el-row {
      margin: 15px 0;
      & .label {
        color: #282c34;
        width: 130px !important;
        display: inline-block;
        margin-right: 15px;
      }
      & .content {
        color: #000016;
      }
    }
  }
}
.header {
  font-size: 14px;
  color: #303030;
  font-family: 'PingFang Bold';
}
.fs14 {
  font-size: 14px;
  font-family: 'PingFang Bold';
  color: #282c34;
}
.mr15 {
  margin-right: 15px;
}
.mt30 {
  margin-top: 30px;
}
.pointer {
  cursor: pointer;
}
.mb20 {
  margin-bottom: 20px;
}

.loc-content {
  max-height: 320px;
  overflow: auto;
}

.center {
  text-align: center;
}

.print-wrapper {
  width: 900px;
  margin: 0 auto;
  padding: 30px 0;

  table td {
    border: 1px solid #f0f4fb;
    padding: 14px 16px;
    color: #303030;
    box-sizing: border-box;

    &.label {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-family: 'PingFang Bold';
      color: #303030;
    }
    &.title {
      font-family: MicrosoftYaHei;
      color: #777777;
    }
    &.bg {
      background-color: #f8fbff;
      -webkit-print-color-adjust: exact;
    }
    &.lineh-22 {
      line-height: 22px;
    }
  }
}
</style>
