<!--
  功能：快捷入口组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月16日 20:00:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard title="快捷入口" noOperation>
    <el-row :gutter="gutter">
      <el-col
        :span="6"
        v-for="item in dataList"
        :key="item.icon"
        v-allowed="[item.auth]"
      >
        <div
          class="card-item"
          @click="handleClick(item)"
          :style="{ 'margin-bottom': gutter + 'px' }"
        >
          <i
            :class="['iconfont', item.icon]"
            :style="{ color: item.color }"
          ></i>
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.name"
            placement="bottom-end"
          >
            <span class="name">{{ item.name }}</span>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <!-- 新增客户 -->
    <ClientManageAddModel ref="clientManageAdd" />
    <PurchaseDemandAdd ref="purchaseDemandAdd" />
    <CostAddModel ref="CostAddModel" />
  </IndexCard>
</template>

<script>
  import IndexCard from './index-card'
  import ClientManageAddModel from '@/views/new-customer-manage/customer-center/components/client-manage-add-model' //新增客户。
  import PurchaseDemandAdd from '@/views/purchase/purchaseDemand/components/add.vue' // 新增备货单
  import CostAddModel from '@/views/finance-center/cost-manage/components/cost-add-model' //新增费用单
  import { hasRight } from '@/utils/permissionBtn'

  import { mapGetters } from 'vuex'
  let dataList = [
    {
      name: '写邮件',
      auth: 'My:Mail',
      icon: 'icon-xieyoujian',
      color: '#FFB005',
      methodName: 'writeMail',
    },
    {
      name: '新增产品',
      auth: 'PRODUCT-LIST',
      icon: 'icon-chanpin2',
      color: '#F56C6C',
      path: '/product/ProductAddEdit/ProductAddEdit',
      query: {
        str: 'add',
      },
    },
    {
      name: '新增客户',
      auth: 'CLIENT:MANAGE',
      icon: 'icon-kehu',
      color: '#1890FF',
      methodName: 'addNewCus',
    },
    {
      name: '新增供应商',
      auth: 'SUPPLIER:MANAGE',
      icon: 'icon-pinzhigongyingshang',
      color: '#00B188',
      path: '/client-supplier/supplier-manage-add',
    },
    // {
    //   name: '新增询盘',
    //   auth: 'INQUIRY:MANAGE',
    //   icon: 'icon-kehuxunpan',
    //   color: '#1890FF',
    //   path: '/order/inquiryManage/inquiryAdd',
    // },
    {
      name: '新增订单',
      auth: 'Order:List',
      icon: 'icon-dingdan',
      color: '#2EB797',
      path: '/order/orderList/orderAdd',
    },
    // {
    //   name: '新增出运',
    //   auth: 'SHIMENT-MANAGE',
    //   icon: 'icon-huoche',
    //   color: '#1890FF',
    //   path: '/order/new-shipment-sheet',
    // },
    {
      name: '备货申请',
      auth: 'Purchase:Demand:Add',
      icon: 'icon-beihuozhong',
      color: '#F56C6C',
      methodName: 'addNewPurGood',
    },
    // {
    //   name: '费用申请',
    //   auth: 'Add:Cost',
    //   icon: 'icon-feiyongyujing-feiyongbuzu',
    //   color: '#F7B500',
    //   methodName: 'addNewFeeSheet',
    // },
    {
      name: '我的邮件',
      auth: 'My:Mail',
      icon: 'icon-youxiang',
      color: '#1890FF',
      path: '/mail/my-mail/receive',
    },
    {
      name: '采购需求',
      auth: 'PURCHASE:DEMAND',
      icon: 'icon-caigouxuqiu',
      color: '#F56C6C',
      path: '/purchase/purchaseDemand/purchaseDemand',
    },
    {
      name: '询价需求',
      auth: 'INQUIRY:DEMAND',
      icon: 'icon-inquiry_fill',
      color: '#00B188',
      path: '/purchase/inquiryDemand/inquiryDemand',
    },
  ]

  if (hasRight('Inquiry:Add')) {
    dataList.splice(3, 0, {
      name: '新增询盘',
      auth: 'INQUIRY:MANAGE',
      icon: 'icon-kehuxunpan',
      color: '#1890FF',
      path: '/order/inquiryManage/inquiryAdd',
    })
  }

  export default {
    name: 'QuickEntrance',
    props: {},
    components: {
      IndexCard,
      ClientManageAddModel,
      PurchaseDemandAdd,
      CostAddModel,
    },
    // 组件状态值
    data() {
      return {
        gutter: 15,
        dataList: Object.freeze(dataList),
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },

    methods: {
      getGutter() {
        const ConstGutter = 15
        let h = document.body.getBoundingClientRect().width
        var gutter = Math.ceil((h / 1920) * ConstGutter)
        this.gutter = gutter < 3 ? 3 : gutter
      },
      handleClick({ path, methodName, query }) {
        if (!path && !methodName) {
          return
        }
        if (path) {
          let routeData = this.$router.resolve({
            path,
            query: query ? query : {},
          })

          window.open(routeData.href, '_blank')
        } else {
          this[methodName]()
        }
      },
      // 写邮件
      writeMail() {
        let url = `${window.location.origin}/#/write?from=homepage`
        this.utils.openWindow(url)
      },
      /**
       * 新增客户
       */
      addNewCus() {
        this.$refs.clientManageAdd.showAddEdit()
      },
      /**
       * 新增备货申请
       */
      addNewPurGood() {
        this.$refs.purchaseDemandAdd.demandAddVisable = true
      },
      /**
       * 新增费用单
       */
      addNewFeeSheet() {
        let params = {
          auditType: 8,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(params).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['CostAddModel'].paramsData = params
            this.$refs['CostAddModel'].showAdd()
          }
        })
      },
    },

    created() {
      window.onresize = () => {
        this.getGutter()
      }
    },
  }
</script>

<style scoped lang="scss">
  .card-item {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f0f4fb;
    // margin-bottom: 15px;
    cursor: pointer;
    .name {
      font-weight: 400;
      color: #282c34;
      font-size: 12px;
      display: inline-block;
      height: 17px;
      line-height: 17px;
      margin-top: 5px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 3px;
      text-align: center;
    }
    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(206, 206, 206, 0.5);
      .name {
        color: #1890ff;
      }
    }
  }
</style>
