<template>
  <div class="mail-setting-container">
    <el-row>
      <div class="flex justify_start">
        <!-- content="我的邮件" -->
        <el-page-header @back="goBack()" :content="$t('menu.MyEmail')"></el-page-header>
      </div>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleTabChange">

      <el-tab-pane :label="$t('boxsetting.PersonalizedSignature')" name="sign" lazy>
        <mail-sign></mail-sign>
      </el-tab-pane>
      <el-tab-pane :label="$t('boxsetting.EmailTemplate')" name="template" lazy>
        <mail-template></mail-template>
      </el-tab-pane>
      <el-tab-pane :label="$t('boxsetting.OtherSettings')" name="other">
        <mail-other v-if="activeName == 'other'"></mail-other>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import MailSign from '@/views/mail/setting/components/mail-sign'
  import MailRules from '@/views/mail/setting/components/mail-rules'
  import MailTemplate from '@/views/mail/setting/components/mail-template'
  import MailOther from '@/views/mail/setting/components/mail-other-setting'
  import { mapState } from 'vuex'
  export default {
    components: {
      MailSign,
      MailRules,
      MailTemplate,
      MailOther,
    },
    data() {
      return {
        activeName: 'sign',
      }
    },
    computed: {
      ...mapState({
        accountList: (state) =>
          state.mail.accountList.filter((item) => item.authStatus === null),
      }),
    },
    created() {
      if (this.accountList.length == 0) {
        this.$store.dispatch('mail/getMailAccountList')
      }
    },
    methods: {
      handleTabChange(val) { },
      // // 新增编辑签名
      // editSign(row) {
      //   this.$refs.EditSignModel.showDialog(row)
      // },
      // //删除签名
      // removeSign(row) {
      //   this.$confirm('是否确认删除？', '提示', {
      //     type: 'warning',
      //   }).then(() => {
      //     mailSignDelete({ mailSignatureId: row.mailSignatureId }).then((res) => {
      //       if (res.code == '000000') {
      //         this.$message.success('删除成功！')
      //         this.getMailSignList()
      //       }
      //     })
      //   })
      // },
      // // 获取签名列表
      // getMailSignList() {
      //   mailSignList({
      //     businessId: this.userInfo.userId,
      //   }).then((res) => {
      //     if (res.code == '000000') {
      //       let list = res.data
      //       let obj = _.groupBy(list, 'emailAccount') //根据相同邮箱，把数组转成对象，key是邮箱，value是数组
      //       this.signTable = obj
      //       // let arr = []
      //       // for (let key in obj) {
      //       //   let item = {}
      //       //   item['emailAccount'] = key
      //       //   item['children'] = obj[key]
      //       //   arr.push(item)
      //       // }
      //     }
      //   })
      // },
      // // 新增编辑规则
      // editRules(row) {
      //   this.$refs.EditRulesModel.showDialog(row)
      // },
      // // 获取规则列表
      // getMailRulesList() {
      //   mailRulesList({
      //     businessId: this.userInfo.userId,
      //   }).then((res) => {
      //     if (res.code == '000000') {
      //       let list = res.data
      //       let obj = _.groupBy(list, 'emailAccount') //根据相同邮箱，把数组转成对象，key是邮箱，value是数组
      //       this.ruleTable = obj
      //       // for (let key in obj) {
      //       //   let item = {}
      //       //   item['emailAccount'] = key
      //       //   item['children'] = obj[key]
      //       //   this.ruleTable.push(item)
      //       // }
      //     }
      //   })
      // },
      // //删除规则
      // removeRules(row) {
      //   this.$confirm('是否确认删除？', '提示', {
      //     type: 'warning',
      //   }).then(() => {
      //     mailRulesDelete({
      //       mailReceivingRulesId: row.mailReceivingRulesId,
      //     }).then((res) => {
      //       if (res.code == '000000') {
      //         this.$message.success('删除成功！')
      //         this.getMailRulesList()
      //       }
      //     })
      //   })
      // },
      // //启用、禁用
      // triggerRules(row) {
      //   if (row.status == 1) {
      //     let params = {
      //       mailReceivingRulesId: row.mailReceivingRulesId,
      //       status: 0,
      //     }
      //     this.$confirm(
      //       `<div style="font-size:14px;color:#333"><i class="el-icon-warning" style="font-size:20px;color:#ffba00"></i> 是否立即执行此规则？</div>
      //         <p style="font-size:12px;color:#999;padding-left:20px;">若选择“确定”，系统将收件箱内的所有历史邮件都移动到指定文件夹内；若选择“取消”，历史邮件不移动，收到的新邮件会自动分到指定文件夹内。</p>`,
      //       '提示',
      //       {
      //         dangerouslyUseHTMLString: true,
      //       }
      //     )
      //       .then(() => {
      //         params['implement'] = 1
      //       })
      //       .catch((err) => {
      //         params['implement'] = 0
      //       })
      //       .finally(() => {
      //         mailRulesTrigger(params).then((res) => {
      //           if (res.code == '000000') {
      //             this.$message.success('已启用')
      //             this.getMailRulesList()
      //           }
      //         })
      //       })
      //   } else {
      //     mailRulesTrigger({
      //       mailReceivingRulesId: row.mailReceivingRulesId,
      //       status: 1,
      //     }).then((res) => {
      //       if (res.code == '000000') {
      //         this.$message.success('已禁用')
      //         this.getMailRulesList()
      //       }
      //     })
      //   }
      // },
      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>