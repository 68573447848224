<template>
  <div class="reject">
    <el-dialog
      title="驳回"
      :visible.sync="dialogFormVisible"
      width="500px"
      @click="close"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="驳回原因" prop="statusRemark" label-width="80px">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="500"
            show-word-limit
            v-model="form.statusRemark"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { updateCostStatus } from '@/api/finance/finance-cost'
export default {
  data() {
    return {
      amendRow: '',
      dialogFormVisible: false,
      form: {
        statusRemark: '',
      },
      rules: {
        statusRemark: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    }
  },
  methods: {
    showReject() {
      this.dialogFormVisible = true
    },

    close() {
      this.dialogFormVisible = false
      this.$refs.form.resetFields()
    },
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let response = await updateCostStatus({
            ...this.form,
            costSheetId: this.amendRow,
          })
          if (response.code == '000000') {
            this.$message.success('已驳回')
            this.close()
            this.$emit('getList')
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .el-dialog__header {
    > .el-dialog__title {
      font-size: 14px;
    }
  }
  .el-dialog__footer {
    padding-top: 0;
    text-align: center;
  }
}
</style>
