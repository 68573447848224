import request from '@/core/services/request'

//  运费设置——列表
export function freighListPage(data) {
  return request({
    url: '/product/freightSetting/listPage',
    method: 'post',
    data,
  })
}
//  运费设置——新增
export function freighInsertPO(data) {
  return request({
    url: '/product/freightSetting/insertPO',
    method: 'post',
    data,
  })
}
//  运费设置——更新
export function freighUpdatePO(data) {
  return request({
    url: '/product/freightSetting/updatePO',
    method: 'post',
    data,
  })
}
//  运费设置——详情
export function freighShow(data) {
  return request({
    url: '/product/freightSetting/getDetailForEditShow',
    method: 'post',
    data,
  })
}
//  运费设置——启用停用
export function freighDeactivate(data) {
  return request({
    url: '/product/freightSetting/deactivate',
    method: 'post',
    data,
  })
}

// 运费设置——启用停用
export function listTransportWayOrDeliveryArea(data) {
  return request({
    url: '/product/freightSetting/listTransportWayOrDeliveryArea',
    method: 'post',
    data,
  })
}
