<template>
  <el-dialog
    width="1200px"
    class="address"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    :modal-append-to-body="true"
    :show-close="false"
  >
  <div slot="title" style="display: flex;align-items: center;max-height: 500px;overflow: auto;">
    <i class="el-icon-warning" style="color:#e6a23c;font-size:20px;margin-right: 8px;"></i>
    <span style="font-size: 14px;color:#1a1a1a">{{ $t('dialogTitle.repeatOrders') }}</span>
  </div>
  <el-table :data="gridData" border style="max-height: 500px; overflow: auto;">
    <el-table-column align="center"  prop="orderCode" :label="$t('orderDetails.OrderNumber')" ></el-table-column>
    <el-table-column align="center"  prop="email" :label="$t('orderDetails.CustomerMailbox')" ></el-table-column>
    <el-table-column align="center"  prop="companyName" :label="$t('orderDetails.CompanyName')"></el-table-column>
    <el-table-column align="center"  prop="customerOrderCode" :label="$t('orderDetails.CustomerOrderNumber')" width="120"></el-table-column>
    <el-table-column align="center"  prop="productSkus" :label="$t('orderDetails.ProductCode')"></el-table-column>
    <el-table-column align="center"  :prop="lang === 'en' ? 'creatorNameEn' : 'creatorName' " :label="$t('orderDetails.Creator')" width="120"></el-table-column>
    <el-table-column align="center"  prop="createTime" :label="$t('orderDetails.CreationTime')"></el-table-column>
  </el-table>
  <div slot="footer" class="dialog-footer">
    <el-button @click="cancelFn">{{ $t('reqmsg.M2076') }}</el-button>
    <el-button type="primary" @click="confirmFn">{{ $t('reqmsg.M2075') }}</el-button>
  </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      repeatOrdersBol:{
        type: Boolean
      },
      gridData:{
        type: Array ,
        default: ()=>[
          {
            orderCode:'ORP240948127',
            email:'info@ipromo.com',
            companyName:'Well Branded Solutions',
            customerOrderCode:'3366', 
            productSkus:'202090-02/202090-03/202090',
            creatorName:'张三',
            creatorNameEn:'2024-10-16 12:12:12',
            createTime:'Zhang San' 
          }
        ]
      }
    },
    data() {
      return {
  
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      dialogFormVisible :{
        get(){
          return this.repeatOrdersBol
        },
        set(val){
          this.$emit('update:repeatOrdersBol', val)
        } 
      }
    },
    methods: {
      // 点击确认
      confirmFn(){
        this.$emit('confirmFn')
      },
      // 点击取消
      cancelFn(){
        this.$emit('cancelFn')
      }
    }
  }
       
</script>

<style lang="scss" scoped>

</style>
