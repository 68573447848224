<template>
  <el-row :gutter="20" class="pro-container pro-base-msg pro-print">
    <!-- <el-button @click="showData"></el-button> -->
    <el-col :span="2">
      <!-- 印刷计价 -->
      <h6 class="left-h6">{{ $t('productDetail.PrintingPricing') }}</h6>
    </el-col>
    <el-col :span="22" class="right-content" style="padding: 20px 20px">
      <el-row type="flex" align="middle" class="mb20 align_center">
        <!-- 印刷方式 -->
        <span class="mr10">{{ $t('productDetail.PrintingMethod') }}</span>
        <!-- 请选择(多选) -->
        <el-select v-model="printWayValue" @change="printWayChange" @visible-change="printWayVisibleChange"
          :placeholder="$t('placeholder.M2006')" multiple collapse-tags>
          <el-option v-for="(item, index) of printWay" :key="index" :label="lang === 'zh' ? item.nameCn : item.nameEn"
            :value="item.productPrintingPricingId" />
        </el-select>
        <!-- 请先选择产品类别 -->
        <p class="ml15">tip:{{ $t('reqmsg.M2018') }}</p>
      </el-row>
      <!-- 印刷方式详情 -->
      <div>
        <div class="content mt20" v-for="(print, index) in printList" :key="index">
          <!-- 印刷方式： -->
          <p class="content-head">
            {{ $t('productDetail.PrintingMethod') }}：{{
            lang === 'zh' ? print.nameCn : print.nameEn
            }}
          </p>
          <div style="padding: 20px">
            <!-- 可选印刷位： -->
            <el-row>
              {{ $t('productDetail.OptionalPrintingPosition') }}：

              <el-select v-if="!productPrintingSwitch" v-model="print.printPositionValue"
                :placeholder="$t('placeholder.M2006')" multiple :key="index" collapse-tags
                @change="printPositionChange(print)">
                <el-option v-for="(item, index) of printPosition" :key="index" v-if="
                    print.printingPositionIds.indexOf(item.productSettingId) >
                    -1
                  " :label="lang === 'zh' ? item.nameCn : item.nameEn" :value="item.productSettingId" />
              </el-select>
              <el-select :key="index + print.printPositionValue" v-else v-model="print.printPositionValue"
                :placeholder="$t('placeholder.M2006')" multiple collapse-tags @change="printPositionChange(print)">
                <el-option v-for="(item, index) of options" :key="index"
                  :label="lang === 'zh' ? item.nameCn : item.nameEn" :value="item.nameEn" />
              </el-select>
            </el-row>
            <el-row :gutter="5" class="mt20">
              <el-col :span="8">
                <!-- 计价方式 -->
                {{ $t('productDetail.PricingMethod') }}
                <el-radio-group v-model="print.pricingConfiguration" class="ml20" @change="
                    typeChange(
                      'price',
                      print,
                      print.pricingConfiguration,
                      index
                    )
                  ">
                  <!-- 统一设置  按数量阶梯-->
                  <el-radio :label="0">
                    {{ $t('productDetail.UnifiedSettings') }}
                  </el-radio>
                  <el-radio :label="1">
                    {{ $t('productDetail.LadderByQuantity') }}
                  </el-radio>
                </el-radio-group>
              </el-col>
              <el-col :span="10">
                <!-- 印刷时间 -->
                {{ $t('productDetail.PrintingCycle') }}
                <el-radio-group v-model="print.transportationConfiguration" class="ml20" @change="
                    typeChange(
                      'time',
                      print,
                      print.transportationConfiguration,
                      index
                    )
                  ">
                  <!-- 统一设置  按数量阶梯-->
                  <el-radio :label="0">
                    {{ $t('productDetail.UnifiedSettings') }}
                  </el-radio>
                  <el-radio :label="1">
                    {{ $t('productDetail.LadderByQuantity') }}
                  </el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row>
              <el-form :model="print" :ref="'print_form_' + index">
                <el-table :data="print.optionsVOS" border style="width: 100%" class="mt20 ml10 form-rule-table"
                  ref="myTable_1" :key="tableKey + 100">
                  <!-- 计价方式 -->
                  <el-table-column :label="$t('productDetail.PricingMethod')" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.pricingValue }}-1{{
                      $t('productDetail.Position')
                      }}
                    </template>
                  </el-table-column>
                  <!-- 印刷单价 -->
                  <el-table-column v-if="print.pricingConfiguration === 0"
                    :label="$t('productDetail.PrintingUnitPrice')" align="center" label-class-name="required-th">
                    <template slot-scope="scope">
                      <el-form-item :prop="'optionsVOS.' + scope.$index + '.price'" :rules="[
                          {
                            required: print.pricingConfiguration === 0,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model="scope.row.price" type="number" :placeholder="$t('placeholder.plsInput')"
                          @focus="handleFocus('price', index, scope.$index)"
                          @input="changePrice(print.optionsVOSMulity, scope)"
                          oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                          onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <!-- 数量阶梯对应的印刷单价 -->
                  <el-table-column v-if="print.pricingConfiguration === 1" :label="$t('productDetail.StepPrice')"
                    align="center">
                    <el-table-column v-for="(item, index_) in numberStepList" :key="index_ + 1000"
                      :label="item.num + ''" align="center" label-class-name="required-th">
                      <template slot-scope="scope">
                        <el-form-item :prop="
                            'optionsVOS.' +
                            scope.$index +
                            '.number_price_' +
                            index_
                          " :rules="[
                            {
                              required: print.pricingConfiguration === 1,
                              message: $t('placeholder.plsInput'),
                              trigger: 'blur',
                            },
                          ]">
                          <el-input type="number" v-model="scope.row['number_price_' + index_]" @input="
                              stepPriceChange(
                                'price',
                                scope,
                                item.num,
                                index_,
                                print.optionsVOSMulity
                              )
                            " @focus="
                              handleStepFocus(
                                'number_price_' + index_,
                                index,
                                scope.$index
                              )
                            "
                            oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                            onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <!-- 统一设置  印刷周期 -->
                  <!-- 印刷周期(工作日) -->
                  <el-table-column v-if="print.transportationConfiguration === 0" :label="
                      $t('productDetail.PrintingCycle') +
                      '(' +
                      $t('productDetail.WorkingDay') +
                      ')'
                    " align="center" label-class-name="required-th">
                    <template slot-scope="scope">
                      <el-form-item :prop="'optionsVOS.' + scope.$index + '.duration'" :rules="[
                          {
                            required: print.pricingConfiguration === 0,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model="scope.row.duration" type="number" :placeholder="$t('other.workingDay')"
                          @focus="handleFocus('duration', index, scope.$index)"
                          oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                          onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <!-- 数量阶梯对应的印刷周期(工作日) -->
                  <el-table-column v-if="print.transportationConfiguration === 1"
                    :label="$t('productDetail.PrintingcycleDay')" align="center" label-class-name="required-th">
                    <el-table-column v-for="(item, index_) in numberStepList" :key="index_ + 1000"
                      :label="item.num + ''" align="center">
                      <template slot-scope="scope">
                        <el-form-item :prop="
                            'optionsVOS.' +
                            scope.$index +
                            '.number_time_' +
                            index_
                          " :rules="[
                            {
                              required: print.transportationConfiguration === 1,
                              message: $t('placeholder.plsInput'),
                              trigger: 'blur',
                            },
                          ]">
                          <el-input v-model="scope.row['number_time_' + index_]" :placeholder="$t('other.workingDay')"
                            @focus="
                              handleStepFocus(
                                'number_time_' + index_,
                                index,
                                scope.$index
                              )
                            " @input="
                              stepPriceChange(
                                'time',
                                scope,
                                item.num,
                                index_,
                                print.optionsVOSMulity
                              )
                            " oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                            onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <!-- 版费 -->
                  <el-table-column :label="$t('productDetail.EditionFee')" align="center"
                    label-class-name="required-th">
                    <template slot-scope="scope">
                      <el-form-item :prop="'optionsVOS.' + scope.$index + '.fee'" :rules="[
                          {
                            required: true,
                            message: $t('placeholder.plsInput'),
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model="scope.row.fee" type="number" :placeholder="$t('placeholder.plsInput')"
                          @focus="handleFocus('fee', scope.$index)"
                          oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+10)}"
                          onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <!-- 操作 -->
                  <el-table-column :label="$t('productDetail.Operate')" align="center">
                    <template slot-scope="scope">
                      <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                        @click="delItem(print, scope, index)"></el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="flex_xc_yc mt10">
                  <!-- 新增 -->
                  <el-button @click="addPrintWay(print)">
                    {{ $t('productDetail.Add') }}
                  </el-button>
                </div>
              </el-form>
            </el-row>
            <el-row v-if="
                print.printPositionColumn &&
                print.printPositionColumn.length > 1
              " style="padding: 10px 10px">
              <!-- 多位价格系数设置 -->
              <p>{{ $t('productDetail.MDPFS') }}</p>
              <el-table :data="
                  print.pricingConfiguration === 1
                    ? print.optionsVOSMulity
                    : print.optionsVOS
                " border style="width: 100%" class="mt20" :span-method="
                  print.pricingConfiguration === 1
                    ? (arg) => {
                        return spanMethod(arg, index)
                      }
                    : () => {}
                " :key="tableKey + 10000" ref="myTable_2">
                <!-- 计价方式 -->
                <el-table-column prop="pricingValue" :label="$t('productDetail.PricingMethod')"
                  align="center"></el-table-column>
                <el-table-column align="center" v-for="(item, index) in print.printPositionColumn"
                  :key="index + 100 + setRatiokey" prop="printPosition">
                  <template slot="header">
                    <!-- 位 -->
                    <span>
                      {{ index + 1 }}{{ $t('productDetail.Position') }}
                    </span>
                    <el-popover placement="top" width="160" trigger="click" :ref="'aa_' + item.id"
                      @show="changeRatio(item.id)">
                      <!-- 批量设置系数 -->
                      <p>{{ $t('productDetail.BatchSettingFactor') }}</p>
                      <input :id="'ratioInput_' + index" :ref="'ratioInput_' + index" @input="ss"
                        class="ratio_input mb10 mt10" :value="setRatio" />
                      <!-- <input type="text" value="8888" /> -->
                      <div style="text-align: center">
                        <!-- 取消 -->
                        <el-button size="mini" type="text" @click="cancelSet(print, item)">
                          {{ $t('productDetail.Cancel') }}
                        </el-button>
                        <!-- 确定 -->
                        <el-button type="primary" size="mini" @click="saveSet(item, index, print)">
                          <!-- 确定 -->
                          {{ $t('productDetail.Confirm') }}
                        </el-button>
                      </div>
                      <i slot="reference" class="el-icon-edit-outline" style="color: #409eff" v-if="index !== 0"></i>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <el-input :disabled="index === 0" v-model="scope.row['ratio_' + index]"
                      @input="inputChange(print, scope, index)" type="number"
                      oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"></el-input>
                  </template>
                </el-table-column>
                <!-- 数量阶梯 -->
                <el-table-column align="center" :label="$t('productDetail.QuantityLadder')" prop="numberStep"
                  v-if="print.pricingConfiguration === 1">
                  <template slot-scope="scope">
                    {{ scope.row.numberStep }}
                  </template>
                </el-table-column>
                <el-table-column prop="printPositionValue" align="center"
                  v-for="(item, index) in print.printPositionColumn" :key="index + 10">
                  <!-- 位单价 -->
                  <template slot="header">
                    <span>
                      {{ index + 1 }}{{ $t('productDetail.Position')
                      }}{{ $t('productDetail.UnitPrice') }}
                    </span>
                  </template>
                  <template slot-scope="scope">
                    <div v-show="print.pricingConfiguration === 0">
                      {{
                      mulityNumber(
                      scope.row['ratio_' + index],
                      scope.row.price
                      )
                      }}
                    </div>
                    <div v-show="print.pricingConfiguration === 1">
                      {{
                      setPricePosition(
                      scope.row['ratio_' + index],
                      scope,
                      print
                      )
                      }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
  import { elFormErrorScrollIntoView } from 'kits'
  import { ProductAddEditInteractor } from '@/core/interactors/product/productAddEdit'
  import { mapGetters } from 'vuex'
  import mixins from './util/mixins'
  import _ from 'lodash'
  import { getDetailByType } from '@/api/product/productSetting'
  export default {
    mixins: [mixins],
    name: 'ProPrintCalcuNew',
    data() {
      return {
        printWay: [], //印刷方式options
        printWayValue: [], // 印刷方式
        printList: [],
        printPosition: [], // 印刷位
        printPositionValue: [], //印刷位
        radio: 1,
        tableData: [{}],
        numberStepList: [], //数量阶梯
        tableKey: 1,
        finallData: [],
        form: {},
        rules: {},
        tableData: [],
        plantPriceData: [],
        showPlantPrice: [],
        bcglXiangXiList: [],
        setRatio: 0,
        setRatiokey: Math.random(),
        priceRate: 0,
        productPrintingSwitch: false, // 印刷区域配置开关
        options: [],
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    watch: {
      printWay: {
        handler() {
          this.printWay.forEach((item, i) => {
            item.printPositionColumn = item.printPositionValue?.map(
              (ele, j) => {
                return {
                  id: ele,
                  setVisible: false,
                }
              }
            )
            item.optionsVOS?.forEach((ele, j) => {
              this.$set(ele, `ratio_0`, 1) // 设置一位的系数
              // 设置一位的单价
              // 用于印刷单价改变，同时改变 位数单价
              this.$set(ele, 'priceLength', item.printPositionColumn?.length)
            })
            item.optionsVOSMulity?.forEach((ele) => {
              // 设置一位的单价
              // 用于印刷单价改变，同时改变 位数单价
              this.$set(ele, 'priceLength', item.printPositionColumn?.length)
              this.$set(ele, 'ratio_0', 1)
            })
          })
        },
        deep: true,
        immediate: true,
      },
      printList: {
        handler(oldval, newval) {
          // 发送数据到sage平台
          this.$Bus.$emit('printList', this.printList)
          // 处理印刷计价下拉数据
          console.log(oldval, newval)
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      // 获取印刷位
      this.getPrintPositionByType()
    },
    mounted() {
      this.getPrintWayByCategoryId()
      this.getNumberStep()
      this.getSpecList()
      this.getDetailByType8()
      this.watchProductPrintingSwitch()
    },

    methods: {
      // 印刷位置
      async getPrintPositionByType() {
        let res = await ProductAddEditInteractor.getPrintPosition(5)
        this.printPosition = res.code === '000000' ? res.data : []
      },

      // 基本信息 产品类别改变，印刷方式改变
      getPrintWayByCategoryId() {
        this.$Bus.$on('categoryId', async (categoryId) => {
          // 清空上次选择
          this.printWayValue = []
          this.printList = []
          let res = await ProductAddEditInteractor.getPrintWay(categoryId)
          this.printWay = res.code === '000000' ? res.data : []
          this.$Bus.$emit('sendPrintWay', this.printWay)
        })
      },

      // 获取数量阶梯
      getNumberStep() {
        this.$Bus.$on('bcglXiangXiList', (val) => {
          this.bcglXiangXiList = val
          this.bcglXiangXiList = this.bcglXiangXiList.map((it) => {
            return {
              ...it,
              tag: `${it.packingWeight}-${it.numberPerBox}-${it.packingVolume}-${it.productWeight}`, // 判断是不是同一种规格
            }
          })
          this.numberStepList = val.map((item) => {
            return {
              num: Number(item.num),
              profitRate: item.profitRate,
            }
          })

          // 数量阶梯 去重
          this.numberStepList = _.uniqBy(this.numberStepList, 'num')
          this.setPrintWay()
        })
      },

      getSpecList() {
        //判断规格列表是否有数据
        this.$Bus.$on('isTableDataLength', (val) => {
          this.plantPriceData = val
        })
        this.$Bus.$on('isSingleSpecDataLength', (val) => { })
      },

      // 印刷区域配置开关
      watchProductPrintingSwitch() {
        this.$Bus.$on(
          'productPrintingSwitchChange',
          this.onPrintingSwitchChange.bind(this)
        )
      },

      onPrintingSwitchChange({ productPrintingSwitch, options }) {
        this.productPrintingSwitch = productPrintingSwitch == 1 ? true : false
        this.options = options
        let optionsValue = options.map((item) => item.nameEn)
        this.printList.forEach((print) => {
          // 处理选中选项
          print.printPositionValue = (print.printPositionValue || []).filter(
            (item) => optionsValue.includes(item)
          )
        })
      },

      async getDetailByType8() {
        let response = await getDetailByType({ type: 8 })
        if (response.code === '000000' && response.data.length) {
          this.priceRate = Number(response.data[0].value)
        }
      },

      // 编辑详情回显
      async editShow(edit) {

        this.priceRate = edit.priceRate
        let res = null
        if (edit.categoryId) {
          res = await ProductAddEditInteractor.getPrintWay(
            edit.categoryId?.split(',')[edit.categoryId?.split(',').length - 1]
          )
        }
        this.printWay = res?.code === '000000' ? res.data : []
        this.$Bus.$emit('sendPrintWay', this.printWay)
        let list = edit?.productPrintingWayVOList
          ? edit?.productPrintingWayVOList
          : []
        list = list?.map((item) => {
          if (item.printingWaySelectId) {
            this.printWayValue.push(item.printingWaySelectId)
          }

          return {
            ...item,
            oldOptionsVOS: item.optionsVOS,
            optionsVOS: JSON.parse(item.printingWay),
            printPositionValue: item.printingPositions
              ? item.printingPositions.split(',')
              : [],
          }
        })
        this.oldPrintWayValue = this.printWayValue
        console.log(' this.oldPrintWayValue', this.oldPrintWayValue)
        this.printWay.forEach((item, i) => {
          this.$set(item, 'oldOptionsVOS', _.cloneDeep(item.optionsVOS))
          list?.forEach((ele, j) => {
            if (item.productPrintingPricingId === ele.printingWaySelectId) {
              item.optionsVOS = ele.optionsVOS.map((jt, jti) => {
                return {
                  ...item.optionsVOS[jti],
                  ...jt,
                }
              })
              this.$set(
                item,
                'printPositionValue',
                ele.printingPositions ? ele.printingPositions.split(',') : []
              )
              this.$set(item, 'pricingConfiguration', ele.pricingConfiguration)
              this.$set(
                item,
                'transportationConfiguration',
                ele.transportationConfiguration
              )
              this.$set(item, 'price', ele.price)
              this.$set(item, 'productPrintingWayId', ele.productPrintingWayId)
              this.$set(item, 'productId', edit.productId)
              this.printList.push(item)
              this.setPrintWay()
            }
          })
          item.optionsVOS = item.optionsVOS.map((it) => {
            return {
              ...it,
              pricingValue: it.way ? it.way : it.pricingValue,
              pricingValueEn: it.wayEn ? it.wayEn : it.pricingValueEn,
            }
          })
        })
      },

      ss(val) {
        this.setRatio = val.target.value
      },

      printWayChange(val) {
        this.printList = this.printWay.filter((item) => {
          this.$set(item, 'oldOptionsVOS', _.cloneDeep(item.optionsVOS))
          // 产品新增，默认计价方式 和  印刷时间 为 统一设置
          this.$set(
            item,
            'pricingConfiguration',
            item.pricingConfiguration || 0
          )
          this.$set(
            item,
            'transportationConfiguration',
            item.transportationConfiguration || 0
          )
          return val.includes(item.productPrintingPricingId)
        })
        this.setPrintWay()
        this.$Bus.$emit('printWay', this.printWayValue)
      },

      printWayVisibleChange(hide) {
        if (!hide) {
          if (this.productPrintingSwitch) {
            this.setDefaultPrintPosition(this.printWayValue)
          }
        }
      },

      // 开始印刷区域后设置默认印刷位
      setDefaultPrintPosition(val) {
        let arr = []
        if (!this.oldPrintWayValue) {
          arr = val
        } else {
          arr = val.filter((item) => {
            return !this.oldPrintWayValue.includes(item)
          })
        }
        // 找到存在val数组中不存在oldPrintWayValue数组中的元素，返回一个不存在的数组

        // 遍历 this.printList每项item,如果item.productPrintingPricingId存在于arr中，则将item的printPositionValue设置为this.options数组中所有元素的nameEn属性组成的新数组
        this.printList.forEach((item) => {
          if (arr.includes(item.productPrintingPricingId)) {
            if (
              !item.printPositionValue ||
              item.printPositionValue.length === 0
            ) {
              item.printPositionValue = this.options.map((it) => {
                return it.nameEn
              })
            }
          }
        })

        this.oldPrintWayValue = val
      },

      setPrintWay() {
        let num = 0
        // 设置多位价格系统 的表格
        this.printWay?.forEach((item1, index) => {
          let arr = []
          item1.optionsVOS.forEach((item2) => {
            this.$set(
              item2,
              'duration',
              item2.duration === 0 ? '' : item2.duration
            )
            this.numberStepList?.forEach((item3) => {
              this.$set(item2, 'profitRate', item3.profitRate)
              let obj = {
                ...item2,
                numberStep: item3.num,
                profitRate: item3.profitRate,
              }
              arr.push(obj)
            })
          })

          this.$set(item1, 'optionsVOSMulity', arr)
        })
        this.printList.forEach((it, i) => {
          if (it.pricingConfiguration === 1) {
            this.getSpanArr(it.optionsVOSMulity, i)
          }
        })
      },

      // 批量修改系数
      cancelSet(print, item) {
        this.$refs[`aa_${item.id}`][0].doClose()
        this.setRatio = 0
        this.setRatiokey = Math.random()
        let el = document.getElementById(`ratioInput_${index}`)
        el.setAttribute('value', 0)
      },

      saveSet(item, index, print) {
        if (print.pricingConfiguration == 1) {
          print.optionsVOSMulity.forEach((it) => {
            this.$set(it, `ratio_${index}`, this.setRatio)
          })
        } else {
          print.optionsVOS.forEach((it) => {
            this.$set(it, `ratio_${index}`, this.setRatio)
          })
        }
        this.setRatio = 0
        this.setRatiokey = Math.random()
        let el = document.getElementById(`ratioInput_${index}`)
        el.setAttribute('value', 0)

        this.$refs[`aa_${item.id}`][0].doClose()
        if (print.pricingConfiguration == 1) {
          print.optionsVOS.forEach((item, i) => {
            print.optionsVOSMulity.forEach((ele, ei) => {
              if (item.pricingValue === ele.pricingValue) {
                this.numberStepList.forEach((ec, ci) => {
                  this.$set(item, `ratio_${ci}`, ele[`ratio_${ci}`])
                })
              }
            })
          })
        }
      },

      changeRatio(id) {
        this.setRatio = 0
        this.$set(this, 'setRatio', 0)
        this.$refs[`aa_${id}`][0].$el.style.maxHeight = '600px'
      },

      // 删除
      delItem(print, scope, index) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          let len = this.numberStepList.length
          if (print.optionsVOS.length == 1) {
            this.$message.warning(this.$t('other.keepData'))
            return
          }
          print.optionsVOS.splice(scope.$index, 1)
          print.optionsVOSMulity.splice(scope.$index * len, len)

          this.getSpanArr(print.optionsVOSMulity, index)
          this.$Bus.$emit(
            'delPrintWayItem',
            scope.row.productPrintingPricingOptionsId,
            this.printWayValue,
            this.printWay
          )
        })
      },

      // 可选印刷位改变
      printPositionChange(list) { },

      // 印刷单价
      changePrice(list, scope) {
        // 设置印刷单价 的位数价格 后面计算可能用到
        // priceLength： 有几位
        // 设置多位价格系统的 位数价格 用于展示
        // 计价方式 相等，用同一个系数price
        list.forEach((item, i) => {
          if (item.pricingValue === scope.row.pricingValue) {
            for (let index = 0; index < scope.row.priceLength; index++) {
              this.$set(item, 'price', scope.row.price)
            }
          }
        })
      },

      inputChange(print, scope, index) {
        // 设置number_price_ 这个属性
        if (print.pricingConfiguration == 1) {
          print.optionsVOS.forEach((item, i) => {
            print.optionsVOSMulity.forEach((ele, ei) => {
              if (item.pricingValue === ele.pricingValue) {
                this.numberStepList.forEach((ec, ci) => {
                  this.$set(
                    ele,
                    'number_price_' + ci,
                    item['number_price_' + ci]
                  )
                })
              }
            })
          })
        } else {
          print.optionsVOS.forEach((item, i) => {
            print.optionsVOSMulity.forEach((ele, ei) => {
              this.$set(ele, `number_price_${i}`, item.price)
            })
          })
        }

        // 按数量阶梯计算
        if (print.pricingConfiguration === 1) {
          // 输入位系数。位单价改变
          // 位系数改变，对应的数量阶梯全都要改变 numberStepList 数量阶梯列表
          print.optionsVOSMulity.forEach((item, j) => {
            // 计价方式相同
            if (item.pricingValue === scope.row.pricingValue) {
              // 设置位的系统保持一致
              this.$set(item, 'ratio_' + index, scope.row['ratio_' + index])
            }
          })
          print.optionsVOS.forEach((item, i) => {
            print.optionsVOSMulity.forEach((ele, ei) => {
              if (item.pricingValue === ele.pricingValue) {
                this.numberStepList.forEach((ec, ci) => {
                  this.$set(item, `ratio_${ci}`, ele[`ratio_${ci}`])
                })
              }
            })
          })
        }
      },

      // 计价方式改变
      typeChange(type, print, num, index) {
        console.log(
          print.optionsVOSMulity,
          print.optionsVOS,
          print.printPositionColumn
        )
        this.tableKey++
        if (type === 'price' && num === 1) {
          this.printList.forEach((it, i) => {
            this.getSpanArr(it.optionsVOSMulity, i)
          })

          // 将optionsVOS，optionsVOSMulity 的number_price_ 清空
          print.optionsVOSMulity?.forEach((it, i) => {
            print.printPositionColumn?.forEach((ot, j) => {
              this.$set(it, `number_price_${j}`, 0)
              this.$set(it, `ratio_${j}`, 0)
            })
            this.$set(it, `ratio_${0}`, 1)
          })

          print.optionsVOS?.forEach((it, i) => {
            print.printPositionColumn?.forEach((ot, j) => {
              this.$set(it, `number_price_${j}`, 0)
            })
          })
        }
        // 切换到统一设置时，清除optionsVOS 里面的number_price_ 属性
        if (type === 'price' && num === 0) {
          print.optionsVOS.forEach((it) => {
            for (const key in it) {
              if (key.indexOf('number_price_') > -1) {
                delete it[key]
              }
            }
            this.$set(it, 'price', 0)
          })
        }

        if (type === 'price') {
          this.$Bus.$emit(
            'changePricingConfig',
            print.productPrintingPricingId,
            this.printWay
          )
        }
      },

      // 按数量数量阶梯 各阶梯对应的单价
      // type：price价格 time周期  step：数量阶梯对应的值
      // priceLength 有几位 就取几
      // single_price_多位单价
      stepPriceChange(type, scope, step, j, list) {
        this.$set(scope.row, `step_${j}`, step)
        list.forEach((item) => {
          if (item.pricingValue === scope.row.pricingValue) {
            this.$set(item, `number_price_${j}`, scope.row[`number_price_${j}`])
          }
        })

        // 有用。同步平台价格
        if (type === 'price') {
          this.$Bus.$emit('changePricingValue', this.printWay)
        }
      },

      showData() {
        this.calcData()
        // 处理数据， 主要是加上printingWay printingWayEn
        this.finallData = this.printList.map((ot) => {
          ot.newOptionsVOS = ot.newOptionsVOS.map((item, index) => {
            let stepChildList = []
            let cycleChildList = []
            let positionChildList = []
            if (this.numberStepList.length > 0) {
              for (let i = 0; i < this.numberStepList.length; i++) {
                //计价方式 统一设置时，阶梯子节点 为空 0 为统一设置，1为按阶梯数量
                if (ot.pricingConfiguration === 1) {
                  let obj_1 = {
                    // num: item[`step_${i}`],
                    num: this.numberStepList[i].num,
                    price: item[`number_price_${i}`],
                  }
                  stepChildList.push(obj_1)
                }
                // 印刷时间为统一设置时，cycleChildList为空，0为统一设置，1为按阶梯数量
                if (ot.transportationConfiguration === 1) {
                  let obj_2 = {
                    // num: item[`step_${i}`],
                    num: this.numberStepList[i].num,
                    duration: item[`number_time_${i}`],
                  }
                  cycleChildList.push(obj_2)
                }
              }
            }

            for (let j = 0; j < item.priceLength; j++) {
              let obj_3 = {
                digit: j + 1,
                coefficient: item[`ratio_${j}`],
              }
              positionChildList.push(obj_3)
            }
            return {
              ...item,
              //
              fee: ot.optionsVOS[index].fee,
              price: ot.optionsVOS[index].price,
              way: item.way || item.pricingValue,
              wayEn: item.wayEn || item.pricingValueEn,
              stepChildList: JSON.stringify(stepChildList),
              cycleChildList: JSON.stringify(cycleChildList),
              positionChildList: JSON.stringify(positionChildList),
            }
          })
          ot.newOptionsVOSEn = ot.newOptionsVOS.map((item) => {
            return {
              ...item,
              wayEn: item.pricingValueEn || item.pricingValue,
              way: item.pricingValue,
            }
          })
          return {
            printingWay: JSON.stringify(ot.newOptionsVOS),
            printingWayEn: JSON.stringify(ot.newOptionsVOSEn),
            printingWaySelect: ot.nameCn,
            printingWaySelectEn: ot.nameEn,
            printingWaySelectId: ot.productPrintingPricingId,
            productPrintingPricingId: ot.productPrintingPricingId,
            productId: ot.productId,
            productPrintingWayId: ot.productPrintingWayId,
            pricingConfiguration: ot.pricingConfiguration,
            transportationConfiguration: ot.transportationConfiguration,
            printingPositions: ot.printPositionValue?.length
              ? ot.printPositionValue.toString()
              : '',
          }
        })
      },

      // 把optionsVOS 数组 和  optionsVOSMulity 整理在一起
      calcData() {
        this.printList.forEach((item) => {
          this.$set(
            item,
            'newOptionsVOSMulity',
            _.uniqBy(item.optionsVOSMulity, 'pricingValue')
          )
          // 计价方式 两种状态，印刷时间两种状态，总共四种情况
          // 合并成一个数组
          if (
            item.pricingConfiguration === 0 &&
            item.transportationConfiguration === 0
          ) {
            item.newOptionsVOSMulity.forEach((ele, i) => {
              for (let index = 0; index < ele.priceLength; index++) {
                this.$set(
                  ele,
                  `ratio_${index}`,
                  item.optionsVOS[i][`ratio_${index}`]
                )
              }
              this.$set(ele, 'duration', item.optionsVOS[i].duration)
            })

            item.newOptionsVOS = item.newOptionsVOSMulity
          }
          if (
            item.pricingConfiguration === 1 &&
            item.transportationConfiguration === 0
          ) {
            item.newOptionsVOS = item.newOptionsVOSMulity.map((ele, i) => {
              for (let index = 0; index < this.numberStepList.length; index++) {
                this.$set(
                  ele,
                  `step_${index}`,
                  item.optionsVOS[i][`step_${index}`]
                )
                this.$set(
                  ele,
                  `number_price_${index}`,
                  item.optionsVOS[i][`number_price_${index}`]
                )
              }
              return {
                ...ele,
                fee: item.optionsVOS[i].fee,
                duration: item.optionsVOS[i].duration,
              }
            })
          }
          if (
            item.pricingConfiguration === 0 &&
            item.transportationConfiguration === 1
          ) {
            item.newOptionsVOS = item.optionsVOS
          }
          if (
            item.pricingConfiguration === 1 &&
            item.transportationConfiguration === 1
          ) {
            item.newOptionsVOSMulity.forEach((ele, i) => {
              for (let index = 0; index < this.numberStepList.length; index++) {
                this.$set(
                  ele,
                  'number_time_' + index,
                  item.optionsVOS[i]['number_time_' + index]
                )
                this.$set(
                  ele,
                  'step_' + index,
                  item.optionsVOS[i]['step_' + index]
                )
              }
            })
            item.newOptionsVOS = item.newOptionsVOSMulity
          }
        })
      },

      // 保存
      validateField2() {
        this.showData()
        return {
          success: true,
          productPrintingWayDTOList: this.finallData,
        }
      },

      // 确认
      validateField() {
        this.showData()
        let obj = {
          productPrintingWayDTOList: this.finallData,
        }

        let success = false
        let state = []
        this.printList.forEach((item, index) => {
          this.$refs[`print_form_${index}`][0].validate((valid) => {
            if (valid) {
              success = true
              state.push(true)
            } else {
              success = false
              state.push(false)
              // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
              this.$nextTick(() => {
                elFormErrorScrollIntoView('.pro-print')
              })
            }
          })
        })
        success = state.every((item) => item)
        if (success) {
          return {
            success: true,
            ...obj,
          }
        } else {
          return {
            success: false,
            ...obj,
          }
        }
      },

      // 两数相乘
      rideNumber(a, b) {
        if (!a) a = 0
        if (!b) b = 0
        return Number(a) * Number(b)
      },

      // 两数想加
      addNumber(val1, val2) {
        if (!val1) val1 = 0
        if (!val2) val2 = 0
        return (Number(val1) + Number(val2)).toFixed(3)
      },

      // 两数相除
      divisionNumber(val1, val2) {
        if (!val1 || !val2) return 0
        return (Number(val1) / val2).toFixed(3)
      },

      mulityNumber(val1, val2) {
        if (!val1 || !val2) return 0
        return (Number(val1) * Number(val2)).toFixed(3)
      },

      setPricePosition(val1, scope, print) {
        if (!val1) return 0
        // 1、找到相同计价方式的 optionsVOS项 为了找到对应number_price_
        let obj = print.optionsVOS.find((it) => {
          return it.pricingValue == scope.row.pricingValue
        })
        // 2、找到此时的数量阶梯是哪一个 对到对应的number_price_
        let stepIndex = 0
        this.numberStepList.forEach((it, i) => {
          if (it.num == scope.row.numberStep) {
            stepIndex = i
          }
        })
        let price = ''
        if (obj) {
          price = obj[`number_price_${stepIndex}`]
        }
        if (!price) return 0

        return (Number(val1) * Number(price)).toFixed(3)
      },

      // 新增计价方式
      addPrintWay(print) {
        let oldOptions = this.printWay.find((item) => {
          return (
            item.productPrintingPricingId === print.productPrintingPricingId
          )
        })
        this.$set(print, 'oldOptionsVOS', oldOptions.oldOptionsVOS)
        if (print.oldOptionsVOS.length === print.optionsVOS.length) {
          return this.$baseMessage(
            // '没有更多的计价方式了',
            this.$t('reqmsg.M2041'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          let newArr = print.optionsVOS
          let oldArr = print.oldOptionsVOS
          let result = []
          for (let i = 0; i < oldArr.length; i++) {
            let obj = oldArr[i]
            let num = obj.pricingValue
            let isExist = false
            for (let j = 0; j < newArr.length; j++) {
              let aj = newArr[j]
              let n = aj.pricingValue
              if (n == num) {
                isExist = true
                break
              }
            }
            if (!isExist) {
              result.push(obj)
            }
          }
          result.forEach((e) => {
            let obj = {
              pricingValue: e.pricingValue,
              way: e.pricingValue,
              price: '',
              fee: e.fee,
              pricingValueEn: e.pricingValueEn,
              wayEn: e.pricingValueEn,
            }
            print.optionsVOS.push(obj)
          })
        }

        this.setPrintWay()
        this.$Bus.$emit('addPrintWayItem', this.printWayValue, this.printWay)
      },
      handleFocus(name, outIndex, $index) {
        if (this.printList[outIndex].optionsVOS[$index][name] == 0) {
          this.$set(this.printList[outIndex].optionsVOS[$index], name, '')
        }
      },
      handleStepFocus(name, outIndex, $index) {
        if (this.printList[outIndex].optionsVOS[$index][name] == 0) {
          this.$set(this.printList[outIndex].optionsVOS[$index], name, '')
        }
      },
    },
    beforeDestroy() {
      this.$Bus.$off('categoryId')
      this.$Bus.$off('bcglXiangXiList')
      this.$Bus.$off('isTableDataLength')
      this.$Bus.$off('isSingleSpecDataLength')
      this.$Bus.$off('productPrintingSwitchChange')
    },
  }
</script>

<style scoped lang="scss">
  .ratio_input {
    width: 130px;
  }

  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }

    .content {
      height: 100%;
      border: 1px solid #e4e7ed;
      border-radius: 3px;

      .content-head {
        height: 50px;
        line-height: 50px;
        background-color: #f2f2f2;
        width: 100%;
        padding-left: 10px;
      }
    }

    ::v-deep {
      .el-table {
        .el-table__body td {
          height: 86px;

          .el-form-item {
            margin: 18px 0;
          }
        }
      }
    }
  }
</style>
<style>
  .el-popover {
    overflow: hidden;
  }
</style>