<template>
  <!-- :title="跟进记录" -->
  <el-dialog
    :title="$t('cusDetail.FollowUpRecord')"
    :visible.sync="dialogFormVisible"
    width="45%"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-row type="flex" align="middle" :gutter="20">
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 联系人 -->
              {{ $t('cusDetail.Contacts') }}
            </span>
            <p>{{ followDetail.linkman || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 跟进方式 -->
              {{ $t('cusDetail.FollowUpWay') }}
            </span>
            <p>{{ followDetail.linkType || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 跟进主题 -->
              {{ $t('cusDetail.FollowUpTopic') }}
            </span>
            <p>{{ followDetail.theme || '--' }}</p>
          </el-row>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" :gutter="20" class="mt20">
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 跟进目的 -->
              {{ $t('cusDetail.FollowUpPurpose') }}
            </span>
            <p>{{ followDetail.followGoal || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 跟进时间 -->
              {{ $t('cusDetail.FollowUpTime') }}
            </span>
            <p>{{ followDetail.followTime || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 跟进方向 -->
              {{ $t('cusDetail.FollowUpDirection') }}
            </span>

            <p>
              {{
                followDetail.direction
                  ? followDetail.direction === '进'
                    ? $t('other.in')
                    : $t('other.out')
                  : '--'
              }}
            </p>
          </el-row>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" :gutter="20" class="mt20">
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 订单编号 -->
              {{ $t('cusDetail.OrderNumber') }}
            </span>
            <p>{{ followDetail.serial || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 创建人 -->
              {{ $t('cusDetail.Creator') }}
            </span>
            <p>{{ getName(followDetail, 'creatorName') || '--' }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <span>
              <!-- 创建时间 -->
              {{ $t('cusDetail.CreationTime') }}
            </span>
            <p>{{ followDetail.createTime || '--' }}</p>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="mt20">
        <span>
          <!-- 备注 -->
          {{ $t('cusDetail.Remark') }}
        </span>
        <p>{{ followDetail.remark || '--' }}</p>
      </el-row>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">
          <!-- 关闭 -->
          {{ $t('other.close') }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import nameMixin from '@/utils/name-mixin'

  export default {
    name: 'FollowDetailModel',
    mixins: [nameMixin],

    data() {
      return {
        dialogFormVisible: false, // 弹窗控制
        followDetail: {}, //跟进记录详情
      }
    },
    methods: {
      //打开
      showAddEdit(row) {
        this.followDetail = row
        this.dialogFormVisible = true
      },

      //关闭
      close() {
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    padding: 0 15px;
    overflow: hidden;
    .el-row {
      span {
        color: #666;
      }
      p {
        margin-top: 10px;
        font-size: 16px;
        color: #000;
      }
    }
    .floor {
      border-top: 1px solid #ededed;
      padding-top: 20px;
    }
  }
</style>
