<template>
  <div class="custom-container">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="22" class="page-header-left">
        <el-row
          type="flex"
          class="align_center flex_row_y flex_wrap top-search"
        >
          <el-button @click="crmAdd" class="mr10" type="primary">
            <i class="el-icon-plus"></i>
            验货申请
          </el-button>
          <div>
            <el-form
              ref="form"
              :inline="true"
              :model="queryForm"
              label-width="49px"
              @submit.native.prevent
            >
              <el-form-item>
                <el-input
                  style="width: 300px"
                  v-model="queryForm.number"
                  @input="numberClear"
                  placeholder="请输入验货单号/采购单号/销售单号/加工单号"
                  clearable
                  @keyup.enter.native="initLoading"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  style="width: 200px"
                  v-model="queryForm.productInfo"
                  @input="productInfoChange"
                  placeholder="产品编号/产品名称"
                  clearable
                  @keyup.enter.native="initLoading"
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  placeholder="请选择验货类型"
                  v-model="queryForm.inspectionType"
                  clearable
                >
                  <el-option label="采购单验货" :value="0"></el-option>
                  <el-option label="加工单验货" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  placeholder="是否全部出运"
                  v-model="queryForm.shipFlag"
                  clearable
                >
                  <el-option label="是" :value="1"></el-option>
                  <el-option label="否" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <el-button @click="initLoading">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            搜索
          </el-button>
          <el-button @click="highSearch">高级筛选</el-button>
        </el-row>
      </el-col>
      <el-col :span="2">
        <el-row type="flex" justify="end">
          <!-- 自定义展示列 -->
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="item + index"
                v-model="item.isShow"
                :disabled="item.checkable === false"
                :label="item.label"
              >
                {{ item.label }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button
                type="primary"
                class="ml10"
                size="mini"
                plain
                @click="operateSave(true)"
              >
                保存
              </el-button>
            </div>
            <template #reference>
              <el-tooltip
                effect="dark"
                content="列表可拖动排序"
                placement="top-start"
              >
                <el-button plain type="primary">自定义显示</el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>

    <el-radio-group
      class="mt15 mb15"
      v-model="queryForm.type"
      @change="initLoading"
    >
      <el-radio
        v-for="(item, i) in orderStatusList"
        :key="i"
        :label="item.value"
      >
        {{ item.label }}
      </el-radio>
    </el-radio-group>
    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :total="total"
      :extraHeight="30"
      class="table-fixed"
      :key="customTableKey"
      :default-sort="defaultSort"
      @query="getList"
      @sort-change="sortChange"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
        :sortable="item.sortable ? 'custom' : false"
      >
        <!-- 累计合格数量 -->
        <template #header v-if="item.prop === 'sumQualityNum'">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">
              在验货管理中相同PI下相同
              <br />
              SKU的验货合格数量之和
            </div>
            <div>
              {{ item.label }}
              <i class="el-icon-warning-outline"></i>
            </div>
          </el-tooltip>
        </template>
        <!-- 订单数量 -->
        <template #header v-else-if="item.prop === 'orderNum'">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">验货单下SKU在销售订单中的数量之和</div>
            <div>
              {{ item.label }}
              <i class="el-icon-warning-outline"></i>
            </div>
          </el-tooltip>
        </template>
        <!-- 出运数量 -->
        <template #header v-else-if="item.prop === 'shipNum'">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">
              验货单下SKU在出运委托中实际的出运数
              <br />
              量之和（不包含“已取消”的委托单）
            </div>
            <div>
              {{ item.label }}
              <i class="el-icon-warning-outline"></i>
            </div>
          </el-tooltip>
        </template>
        <template #default="{ row }">
          <div
            class="page-link text-left"
            v-if="item.prop === 'number'"
            @click="detail(row.inspectionOrderId, 'show')"
          >
            {{ row.number }}
          </div>
          <div v-else-if="item.prop === 'inspectionType'">
            {{ row.inspectionType == 1 ? '加工单验货' : '采购单验货' }}
          </div>
          <div v-else-if="item.prop === 'purchaseOrderNumber'">
            <div
              v-if="row.purchaseExistFlag == 1"
              class="page-link text-left"
              @click="goTopurchaseDetail(row.purchaseOrderNumber)"
            >
              {{ row.purchaseOrderNumber }}
            </div>
            <div v-else class="text-left">
              {{ row.purchaseOrderNumber || '--' }}
            </div>
          </div>
          <div v-else-if="item.prop === 'businessNames'">
            {{ row.businessNames || row.businessName }}
          </div>
          <div v-else-if="item.prop === 'inspectionStage'">
            {{ row.inspectionStage | inspectionStageFormat }}
          </div>
          <div v-else-if="item.prop === 'inspectionResult'">
            <template v-if="[null, undefined].includes(row.inspectionResult)">--</template>
            <el-tooltip v-else placement="top">
              <div slot="content" class="tip-content">
                <p v-for="(text, n) in row.proDetail.split(',')" :key="n">
                  {{ text }}
                </p>
              </div>
              <el-tag :type="row.inspectionResult == 0 ? 'success' : 'danger'">
                {{ row.inspectionResult | inspectionResultFormat }}
              </el-tag>
            </el-tooltip>
          </div>
          <div v-else-if="item.prop === 'createType'">
            {{ row.createType | createTypeFilter }}
          </div>
          <div v-else-if="item.prop === 'state'">
            <el-tag v-if="row.state == 1" type="info">无需验货</el-tag>
            <el-tag v-else-if="row.state == 2" type="info">待验货</el-tag>
            <!-- <el-tag
              v-else-if="row.state == 3"
              class="c_pointer"
              @click="detail(row.inspectionOrderId, 'show')"
            >
              验货中
            </el-tag> -->
            <el-tag
              type="info"
              v-else-if="row.state == 5"
              @click="detail(row.inspectionOrderId, 'show')"
            >
              已撤销
            </el-tag>
            <el-row v-else>
              <div v-show="row.proDetail">
                <el-tooltip placement="top">
                  <div slot="content" class="tip-content">
                    <p v-for="(text, n) in row.proDetail.split(',')" :key="n">
                      {{ text }}
                    </p>
                  </div>
                  <el-tag type="success">已完成</el-tag>
                </el-tooltip>
              </div>
            </el-row>
          </div>
          <!-- 验货员 -->
          <div v-else-if="item.prop === 'inspector'">
            <div
              class="page-link"
              v-if="!row.inspector && isInspector"
              @click="setInspector(row.inspectionOrderId)"
            >
              未设置
            </div>
            <div v-else>
              <span v-if="[2, 5].includes(row.state)">
                <div
                  class="page-link"
                  v-if="isInspector"
                  @click="setInspector(row.inspectionOrderId)"
                >
                  {{ row.inspector }}
                </div>
                <div v-else>
                  {{ row.inspector }}
                </div>
              </span>
              <span v-else>
                {{ row.inspector }}
              </span>
            </div>
          </div>
          <!-- 效果图 -->
          <div v-else-if="item.prop === 'skusImages'">
            <PreviewImage :Images="row.skusImages"></PreviewImage>
          </div>
          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        width="150"
        fixed="right"
        :resizable="false"
      >
        <template slot-scope="scope">
          <el-tooltip
            content="撤销"
            placement="top"
            v-show="
              [2, 3].includes(scope.row.state) &&
              userInfo.userId === scope.row.creatorId
            "
          >
            <el-button
              type="primary"
              plain
              circle
              size="mini"
              @click="revokeClick(scope.row.inspectionOrderId)"
            >
              <i class="iconfont icon-quxiao f_s_12"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            content="重新提交"
            placement="top"
            v-show="
              [5].includes(scope.row.state) &&
              [5, 6, null].includes(scope.row.purchaseOrderState) &&
              userInfo.userId === scope.row.creatorId
            "
          >
            <el-button
              type="primary"
              plain
              circle
              size="mini"
              @click="
                resubmit(scope.row.inspectionOrderId, scope.row.inspectionType)
              "
            >
              <i class="iconfont icon-zhongxintijiao f_s_12"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            content="验货"
            placement="top"
            v-show="
              scope.row.operateInspectionOrder === '1' &&
              scope.row.inspector &&
              (scope.row.state === 2 || scope.row.state === 3)
            "
          >
            <el-button
              type="primary"
              @click="
                cargo(scope.row.inspectionOrderId, scope.row.inspectionType)
              "
              plain
              size="mini"
              icon="iconfont icon-icon--copy f_s_12"
              circle
            ></el-button>
          </el-tooltip>

          <el-tooltip
            content="安排出运"
            placement="top"
            v-show="isArrangeShipment(scope.row)"
          >
            <el-button
              type="primary"
              plain
              @click="arrangeShipmentFn(scope.row)"
              size="mini"
              icon="iconfont icon-plane f_s_12"
              circle
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </ErpTable>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 验货 -->
    <cargo-manage-modal ref="cargoManageModal"></cargo-manage-modal>

    <!-- crm验货 -->
    <crm-modal ref="crmModal"></crm-modal>

    <!-- 查看验货详情 -->
    <cargo-manage-detail ref="cargoManageDetail"></cargo-manage-detail>

    <!-- 高级筛选Drawer -->
    <el-drawer
      title="高级筛选"
      :visible.sync="drawer"
      :before-close="highSearchClose"
    >
      <!-- 验货管理 -->
      <manageHighSearch
        @cancelClick="highSearchClose"
        @searchClick="searchClick"
      />
    </el-drawer>
  </div>
</template>

<script>
  import ErpDraggable from 'vuedraggable'
  import UserChoose from '@/components/userChoose'
  import { inspectionListPage, inspectionUpdateInspector } from '@/api/order'
  import { purchaseExamineGoods } from '@/core/interactors/purchase/examineGoods'
  import crmModal from './components/crmModal.vue'
  import cargoManageModal from './components/cargoManageModal.vue'
  import cargoManageDetail from './components/cargoManageDetail.vue'
  import manageHighSearch from '../cargoManage/components/manageHighSearch.vue'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import { mapGetters } from 'vuex'
  import CustomCache from '@/utils/custom-cache'
  import customTableMixin from '@/utils/custom-table-mixin'
  import { getKeyIds } from '@/utils/pagelist-util'
  import PreviewImage from '@/components/preview-image/index'

  export default {
    components: {
      ErpDraggable,
      crmModal,
      cargoManageModal,
      cargoManageDetail,
      UserChoose,
      manageHighSearch,
      PreviewImage,
    },
    mixins: [customTableMixin],
    data() {
      return {
        queryForm: {
          number: '',
          productInfo: '',
          type: 0,
          inspectionType: '',
          sortName: '', // 升序降序名称
          sortType: '', // 升序降序（ASC,DESC）
          shipFlag: '', //是否全部出运
        },
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        inspectionOrderId: '',
        userCustomizeColumnId: '', //自义定保存id
        isInspector: true,
        tableData: [],
        defaultSort: {}, // 默认排序
        drawer: false, //高级筛选抽屉
        orderStatusList: [
          {
            label: '全部',
            value: 0,
          },
          {
            label: '待验货',
            value: 1,
          },
          // {
          //   label: '验货中',
          //   value: 2,
          // },
          {
            label: '已撤销',
            value: 5,
          },
          {
            label: '已完成',
            value: 3,
          },
        ],
        columns: [
          {
            label: '验货单号',
            prop: 'number',
            isShow: true,
            checkable: false,
            width: 160,
            isSort: false,
            sortType: null,
          },
          {
            label: '验货类型',
            prop: 'inspectionType',
            isShow: true,
            checkable: true,
            width: 120,
            isSort: false,
            sortType: null,
          },
          {
            label: '产品编号',
            prop: 'skus',
            isShow: true,
            checkable: true,
            width: 120,
            isSort: false,
            sortType: null,
          },
          {
            label: '产品效果图',
            prop: 'skusImages',
            isShow: true,
            checkable: true,
            width: 120,
            isSort: false,
            sortType: null,
          },
          {
            label: '采购订单编号',
            prop: 'purchaseOrderNumber',
            isShow: true,
            checkable: false,
            isSort: false,
            sortType: null,
            width: 150,
          },
          {
            label: '采购开发',
            prop: 'buyerName',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '销售订单编号',
            prop: 'salesOrderNumbers',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
            width: 150,
          },
          {
            label: '验货阶段',
            prop: 'inspectionStage',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '验货结果',
            prop: 'inspectionResult',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '客户代表',
            prop: 'businessNames',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '交货日期',
            prop: 'expectDeliveryDate',
            isShow: true,
            checkable: true,
            sortable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '实际验货日期',
            prop: 'actualInspectionDate',
            isShow: true,
            checkable: true,
            sortable: true,
            width: 180,
            isSort: false,
            sortType: null,
          },
          {
            label: '期望验货日期',
            prop: 'inspectionDate',
            isShow: true,
            checkable: true,
            sortable: true,
            width: 160,
            isSort: false,
            sortType: null,
          },
          {
            label: '验货数量',
            prop: 'numInspection',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '本次合格数量',
            prop: 'qualityNum',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '累计合格数量',
            prop: 'sumQualityNum',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '订单数量',
            prop: 'orderNum',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '出运数量',
            prop: 'shipNum',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },

          {
            label: '验货单来源',
            prop: 'createType',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '验货状态',
            prop: 'state',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: 'QC',
            prop: 'inspector',
            isShow: true,
            checkable: false,
            isSort: false,
            sortType: null,
          },
          {
            label: '创建人',
            prop: 'creator',
            isShow: false,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: false,
            checkable: true,
            sortable: true,
            isSort: false,
            sortType: null,
            width: 180,
          },
        ],
        flag: false,
      }
    },
    computed: {
      ...mapGetters({
        permissions: 'user/permissions',
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        this.columns.forEach((item) => {
          if (item.isSort) {
            this.defaultSort = { prop: item.prop, order: item.sortType }
          }
        })
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    filters: {
      inspectionStageFormat(n) {
        if (n) {
          switch (n) {
            case 1:
              return '初期验货'
            case 2:
              return '中期验货'
            case 3:
              return '尾期验货'
          }
        } else {
          return '--'
        }
      },
      // 验货结果
      inspectionResultFormat(n) {
        if (n == 0) {
          return '合格'
        } else if (n == 1) {
          return '部分不合格'
        } else if (n == 2) {
          return '不合格'
        } else {
          return '--'
        }
      },
      createTypeFilter(val) {
        const num2text = {
          0: '采购订单',
          1: '手动新增',
          2: '加工单',
        }
        return num2text[val] || '--'
      },
    },
    created() {
      const params = this.$route.query

      if (params.processOrderCode) {
        this.queryForm.number = params.processOrderCode
        this.queryForm.productInfo = params.sku.split('-')[0]
      }
      this.getColumsData()
      this.permissionsInspector()
      // 如果路由参数中有inspectionOrderId，初始化时打开弹框
      if (params.inspectionOrderId) {
        this.$nextTick(() => {
          this.detail(params.inspectionOrderId, 'show')
        })
      }
    },
    methods: {
      // 是否显示安排出运按钮
      isArrangeShipment(row) {
        if (
          Number(row.orderNum) > Number(row.shipNum) &&
          Number(row.sumQualityNum) > Number(row.shipNum)
        ) {
          return true
        } else {
          return false
        }
      },
      // 安排出运
      arrangeShipmentFn(row) {
        const routeUrl = this.$router.resolve({
          path: '/order/shipmentManage/shipment-plan',
          query: {
            skus: row.skus,
            salesOrderNumbers: row.salesOrderNumbers,
          },
        })
        window.open(routeUrl.href, '_blank')
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(33, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.columns = res.columns

              // 处理排序搜索条件
              this.columns.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'expectDeliveryDate') {
                    this.queryForm.sortName = 'expect_delivery_date'
                  } else if (item.prop === 'actualInspectionDate') {
                    this.queryForm.sortName = 'actual_inspection_date'
                  } else if (item.prop === 'inspectionDate') {
                    this.queryForm.sortName = 'inspection_date'
                  } else if (item.prop === 'createTime') {
                    this.queryForm.sortName = 'create_time'
                  } else {
                    this.queryForm.sortName = ''
                  }

                  this.queryForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.queryForm.sortName = null)
                }
              })
              this.getList()
            }
          })
          .catch(() => {
            this.getList()
          })
      },

      // 保存自定义显示列
      operateSave(bool) {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 33,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000' && bool) {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 排序
      sortChange(column) {
        if (column.prop === 'expectDeliveryDate') {
          this.queryForm.sortName = 'expect_delivery_date'
        } else if (column.prop === 'actualInspectionDate') {
          this.queryForm.sortName = 'actual_inspection_date'
        } else if (column.prop === 'inspectionDate') {
          this.queryForm.sortName = 'inspection_date'
        } else if (column.prop === 'createTime') {
          this.queryForm.sortName = 'create_time'
        } else {
          this.queryForm.sortName = ''
        }
        this.queryForm.sortType = column.order
        this.saveSort(column)
        this.initLoading()
      },

      // 保存排序
      saveSort(column) {
        this.columns.forEach((item) => {
          if (item.prop === column.prop) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave()
      },

      // 产品编码失焦事件
      productInfoChange(val) {
        this.flag = true
      },

      initLoading() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.getList()
      },

      // 获取列表
      getList() {
        let params = {
          ...this.queryForm,
          ...this.page,
          keyIds: getKeyIds(this),
        }

        if (this.$route.params.processOrderCode) {
          let { orderProductUniqueId } = this.$route.params
          params = {
            ...params,
            number: this.queryForm.number,
            productInfo: this.queryForm.productInfo,
            orderProductUniqueId: this.flag ? '' : orderProductUniqueId,
          }
        }

        purchaseExamineGoods.inspectionListPageApi(params).then((res) => {
          const data = res?.data
          this.tableData = (data?.data ?? []).map((i) => {
            i.skusImages = (i.skusImages || '')
              .split(',')
              .filter((a) => a)
              .map((s) => {
                return {
                  printingImg: s,
                  purchasingDemandNumber: i.number,
                }
              })
            return i
          })
          this.total = data?.total ? data.total : 0
          this.customTableKey = Math.random()
        })
      },

      // 单号清空事件
      numberClear(val) {
        this.queryForm.number = val
      },

      // crm验货
      crmAdd() {
        this.$refs.crmModal.crmModalVisable = true
      },

      // 验货员权限控制
      permissionsInspector() {
        this.isInspector = this.permissions.some(
          (item) => item == 'Allot:Examine:Goods'
        )
      },

      // 选择验货员
      setInspector(id) {
        this.inspectionOrderId = id
        this.$refs['UserChoose'].showAddEdit()
      },

      // 获取客户代表
      chooseUser(userIdArr) {
        let self = this
        const params = {
          businessId: self.inspectionOrderId,
          inspector: userIdArr[0].name,
          inspectorId: userIdArr[0].id,
        }
        purchaseExamineGoods
          .inspectionUpdateInspectorApi(params)
          .then((res) => {
            if (res && res.code == '000000') {
              self.$message.success('设置成功!')
              self.initLoading()
            }
          })
      },

      // 验货
      cargo(id, type) {
        this.$refs.cargoManageModal.initLoading(id, type)
      },

      // 详情
      detail(id, str) {
        this.$refs.cargoManageDetail.initLoading(id, str)
      },

      // 重新提交
      resubmit(id) {
        this.$refs.crmModal.resubmit(id)
      },

      // 撤销
      revokeClick(id) {
        this.$confirm(`是否确定撤销验货单?`, '提示', {
          type: 'warning',
        })
          .then(async () => {
            const res = await purchaseExamineGoods.inspectionOrderRevoke(id)

            if (res && res.code == '000000') {
              this.$message.success('撤销成功')
              this.getList()
            }
          })
          .catch(() => {})
      },

      //跳转到采购订单详情
      goTopurchaseDetail(purchaseOrderNumber) {
        navigateTo(this, {
          pageType: PAGE_TYPES.purchaseOrderDetail,
          orderNumber: purchaseOrderNumber,
          roleJudge: false,
        })
      },

      // 高级筛选
      highSearch() {
        this.queryFormType = this.queryForm.type
        this.drawer = true
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },

      // 高级筛选触发
      searchClick(val) {
        this.queryForm = {
          ...val,
          sortName: this.queryForm.sortName,
          sortType: this.queryForm.sortType,
        }
        this.$set(this.queryForm, 'type', this.queryFormType)
        this.initLoading()
        this.highSearchClose()
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-table__row .el-button {
      margin: 0 5px;
    }
  }
</style>
