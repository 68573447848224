<template>
  <div class="custom-container pl30 pr30" style="margin-top: -12px;">
    <div>
      <h4>基本信息</h4>
      <el-table
        :data="baseInfoTable"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        border
      >
        <el-table-column prop="supplierName" label="供应商名称" width="200">
          <template slot-scope="scope">
            <el-row
              type="flex"
              align="middle"
              justify="space-between"
              class="supplierName-row"
            >
              <tooltip-over
                class="tooltipOver"
                :content="scope.row.supplierName"
                refName="tooltipOver5"
              ></tooltip-over>
              <p>
                <template
                  v-if="supplierOtherMsg.supplierIdentification === '0'"
                >
                  <el-tag
                    type="warning"
                    v-show="supplierOtherMsg.status === 1"
                    size="mini"
                    class="ml5"
                  >
                    审核中
                  </el-tag>
                  <el-tag
                    type="success"
                    v-show="supplierOtherMsg.status === 2"
                    size="mini"
                    class="ml5"
                  >
                    已审核
                  </el-tag>
                  <el-tag
                    type="danger"
                    v-show="supplierOtherMsg.status === 3"
                    size="mini"
                    class="ml5"
                  >
                    审核驳回
                  </el-tag>
                </template>
                <el-tag
                  v-if="scope.row.isAgreement === 1"
                  type="danger"
                  size="mini"
                  class="ml5"
                >
                  协议
                </el-tag>
              </p>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          prop="agreementEndDate"
          label="协议结束日期"
          width="180"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.agreementEndDate || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="supplierLinkmanName"
          label="联系人"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.supplierLinkmanName || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="supplierLinkmanPhone"
          label="联系电话"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.supplierLinkmanPhone || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="buyerName" label="采购开发"></el-table-column>
        <el-table-column prop="merchandiserName" label="QC"></el-table-column>
        <el-table-column
          prop="receivingAddress"
          label="交货地址"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="transportWay"
          label="运输方式"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="freightPayer"
          label="费用承担方"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="creator"
          label="创建人"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ utils.cutDate(scope.row.createTime) }}
          </template>
        </el-table-column>
      </el-table>

      <h4>产品列表</h4>
      <el-table
        :data="productListTable"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        border
      >
        <el-table-column
          align="center"
          width="180"
          prop="nameCn"
          label="产品"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="ell">
              <span>{{ scope.row.sku }}</span>
              <span>/{{ scope.row.nameCn }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="priceIncludingTax"
          width="100"
          label="含税单价"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          width="100"
          prop="taxRate"
          label="税率"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.taxRate * 100 }}%</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="priceExcludingTax"
          width="100"
          label="未税单价"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ utils.moneyFormat(scope.row.priceExcludingTax) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="130"
          prop="salesOrderNumber"
          label="采购需求单号"
        >
          <template slot-scope="scope">
            <div class="page-link">
              <span @click="salesOrderNumberClick(scope.row.salesOrderNumber)">
                {{ scope.row.salesOrderNumber }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="expectedDeliveryDate"
          label="期望交期"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="numDemand"
          label="数量"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="numPurchase"
          label="本次采购数量"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="numRelax"
          label="溢装数"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="specsValue1"
          label="规格"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="ell">
              {{ scope.row.specsValue1 }}
              {{ scope.row.specsValue2 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="180"
          prop="remarkOrder"
          label="订单备注"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          width="180"
          prop="fromBusinessName"
          label="订单支持"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" width="180" label="产品描述">
          <template slot-scope="scope">
            <div
              style="text-align: left; word-break: break-all"
              v-html="scope.row.productSize.replace(/\n/g, '<br>') || '--'"
            ></div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="isDrawback"
          label="是否退税"
        >
          <template slot-scope="scope">
            {{ scope.row.isDrawback == 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="180"
          prop="packingMethodEn"
          label="包装方式"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="numberPerBox"
          label="装箱数量"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="boxVolumeMActual"
          label="外箱体积"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="boxWeightKgs"
          label="外箱重量"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="productPrintingPricingId"
          label="印刷方式"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="productSettingNameCn"
          label="印刷位"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          width="400"
          prop="logoInfo"
          label="印刷文件"
        >
          <template slot-scope="scope">
            <el-row>
              <div
                v-if="
                  scope.row.fileList &&
                  scope.row.fileList.length &&
                  scope.row.printingWay.toUpperCase() != 'BLANK'
                "
              >
                <div
                  class="flie-list"
                  v-for="(item, i) in scope.row.fileList"
                  :key="i"
                >
                  <i class="el-icon-document"></i>
                  <a class="page-link ml5" :href="item.url.split('?')[0]">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.name"
                      placement="top-start"
                    >
                      <span class="ell">{{ item.name }}</span>
                    </el-tooltip>
                  </a>
                  <span class="size ml10">
                    {{ utils.calculateFileSize(item.size) }}
                  </span>
                  <span class="ml10">{{ item.time }}</span>
                </div>
              </div>
              <div v-else>--</div>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="180"
          prop="productPrintingDescribe"
          label="印刷描述"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="printingImg"
          label="效果图"
        >
          <template slot-scope="scope">
            <div class="img-wrap">
              <el-badge
                v-show="
                  scope.row.printingImg &&
                  scope.row.printingImg.split(',').length > 1
                "
                :value="
                  scope.row.printingImg
                    ? scope.row.printingImg.split(',').length
                    : ''
                "
              ></el-badge>
              <el-image
                v-if="scope.row.printingImg"
                :src="scope.row.printingImg.split(',')[0] + URL_COMPRESS"
                class="c_pointer"
                @click="handlePreview(scope.row.printingImg.split(','))"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="supplierDiscount"
          label="供应商折扣金额"
        >
          <template slot-scope="scope">
            {{ utils.numberFormat(scope.row.supplierDiscount, 3) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="supplierDiscountRemaks"
          label="供应商折扣备注"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.supplierDiscountRemaks || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="setUpCharge"
          label="印刷费"
        >
          <template slot-scope="scope">
            {{ utils.numberFormat(scope.row.setUpCharge, 3) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="otherCost"
          label="其他费用"
        >
          <template slot-scope="scope">
            {{ utils.numberFormat(scope.row.otherCost, 3) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="180"
          prop="costDescription"
          label="费用说明"
        ></el-table-column>
        <el-table-column
          align="center"
          width="180"
          prop="remark"
          label="备注"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          width="150"
          prop="actualDeliveryDate"
          label="交货日期"
          fixed="right"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="amountExcludingTax"
          width="150"
          label="总计-未税"
          fixed="right"
        >
          <template slot-scope="scope">
            {{ isRmb ? '￥' : '$'
            }}{{ utils.numberFormat(scope.row.amountExcludingTax, 2) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="amountIncludingTax"
          width="150"
          label="总计-含税"
          fixed="right"
        >
          <template slot-scope="scope">
            {{ isRmb ? '￥' : '$'
            }}{{ utils.numberFormat(scope.row.amountIncludingTax, 2) }}
          </template>
        </el-table-column>
      </el-table>
      <div class="account-num text-right mt10">
        总数量:
        <span>{{ detail.numTotal }}</span>
        未税金额:
        <span class="red">
          {{ isRmb ? '￥' : '$'
          }}{{ utils.numberFormat(detail.amountExcludingTax, 2) }}
        </span>
        含税金额:
        <span class="red">
          {{ isRmb ? '￥' : '$'
          }}{{ utils.numberFormat(detail.amountIncludingTax, 2) }}
        </span>
        <template>抵扣金额:&nbsp;</template>
        <span class="red relative" style="padding-right: 15px">
          -{{ isRmb ? '￥' : '$'
          }}{{ utils.moneyFormat(detail.discountAmount) || 0 }}
          <el-tooltip
            class="item"
            effect="dark"
            :content="detail.discountReason"
            placement="top-start"
            :disabled="!detail.discountReason"
          >
            <i class="el-icon-warning" style="color: black"></i>
          </el-tooltip>
        </span>
      </div>

      <h4>付款情况</h4>
      <div class="c-table-wrap">
        <table class="custom-table mt20">
          <thead>
            <tr>
              <th>币种</th>
              <th>订单总金额</th>
              <th>账户类型</th>
              <th>户名</th>
              <th>账号</th>
              <th>开户行</th>
              <th>税号</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="gray">
                {{ isRmb ? '人民币' : '美元' }}
              </td>
              <td class="gray">
                {{ isRmb ? '￥' : '$'
                }}{{ utils.numberFormat(detail.amountTotal, 2) }}
              </td>
              <td>{{ detail.accountType | accountType_filter }}</td>
              <td>{{ detail.accountName || '--' }}</td>
              <td>{{ detail.bankAccount || '--' }}</td>
              <td>{{ detail.bankName || '--' }}</td>
              <td>{{ detail.financeSetupDuty || '--' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="c-table-wrap">
        <table class="custom-table mt20" style="width: 50%">
          <thead>
            <tr>
              <th width="120">付款环节</th>
              <th width="100">付款比例</th>
              <th width="100">应付款金额</th>
            </tr>
          </thead>
          <tbody v-if="paymentList.length">
            <tr v-for="(item, i) in paymentList" :key="i">
              <td>{{ item.paymentNodeName || '--' }}</td>
              <td>{{ parseInt(item.percent * 100) || 0 }}%</td>
              <td>
                {{ isRmb ? '￥' : '$'
                }}{{ utils.numberFormat(item.amountPayable, 2) }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="empty">
              <td colspan="12">暂无数据</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 v-if="detail.financeSetupName && detail.financeSetupDuty">
        发票情况
      </h4>
      <div
        class="c-table-wrap"
        v-if="detail.financeSetupName && detail.financeSetupDuty"
      >
        <table class="custom-table mt20" style="width: 50%">
          <thead>
            <tr>
              <th width="300">公司名称</th>
              <th width="300">税号</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ detail.financeSetupName }}</td>
              <td>{{ detail.financeSetupDuty }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <el-row :gutter="20">
        <el-col :span="12">
          <div class="flex-btn mt10 mb15">
            <h4>箱唛</h4>
          </div>
          <p style="white-space: pre-wrap">
            {{ detail.marks }}
          </p>
        </el-col>
        <el-col :span="12">
          <h4>备注</h4>
          <p>{{ detail.remark || '--' }}</p>
        </el-col>
      </el-row>

      <el-button class="mt20" @click="printContract">查看合同</el-button>
      <h4 v-if="sealedContract && sealedContract.length">已盖章合同</h4>
      <div class="file_list" v-if="sealedContract && sealedContract.length">
        <div v-for="(item, i) in sealedContract" :key="i" class="file_line">
          <i class="icon_file el-icon-link"></i>
          <span class="file_name c_pointer">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <router-link
                class="d_block upload-file-color"
                :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                target="_blank"
              >
                {{ item.name }}
              </router-link>
            </el-tooltip>
          </span>
          <span class="size">{{ utils.calculateFileSize(item.size) }}</span>
          <span class="time">{{ item.time }}</span>
        </div>
      </div>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />

    <!-- 备货单详情 -->
    <stockUp-detail ref="stockUp"></stockUp-detail>
  </div>
</template>

<script>
  import {
    OrderInteractor,
    SupplierManageInteractor,
    MailInteractor,
  } from '@/core'
  import { mapState } from 'vuex'
  import tooltipOver from '@/components/base-tooltip'
  import stockUpDetail from '@/views/purchase/purchaseDemand/components/stockUpDetail'
  import { URL_COMPRESS } from '@/config'
  export default {
    components: {
      tooltipOver,
      stockUpDetail,
    },
    props: {
      detailId: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        active: '1',
        detail: {},
        /**
         * 订单信息
         */
        baseInfoTable: [], // 基本信息列表
        productListTable: [], // 产品列表
        /**
         * 采购产品信息
         */
        cargoCollectList: [], // 采购收货列表
        cargoEnterList: [], // 采购入库列表
        cargoFinishList: [], // 采购完成列表
        collectSelectRows: [], // 收货集合
        enterSelectRows: [], // 入库集合
        /**
         * 付款/发票
         */
        paymentList: [], // 付款环节列表
        invoiceList: [], // 发票列表
        isRmb: true,
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        fileList1: [],
        fileList2: [],
        supplierOtherMsg: {}, //供应商状态信息
        sealedContract: '',
        URL_COMPRESS: URL_COMPRESS,
      }
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
        accountList: (state) => state.mail.accountList,
        exportHeader: (state) => state.mail.exportHeader,
      }),
    },
    watch: {
      detailId: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.getDetail(value)
        },
      },
    },
    filters: {
      accountType_filter(n) {
        const dicts = {
          1: '对公账户',
          2: '个人银行卡',
          3: '支付宝',
          4: '网拍',
        }
        return dicts[n] || '--'
      },
    },

    created() {
      if (this.$route.query.id) {
        this.getDetail(this.$route.query.id)
      }
      if (this.accountList.length == 0) {
        // 先获取邮箱列表
        this.$store.dispatch('mail/getMailAccountList')
      }
    },
    methods: {
      // 获取订单详情
      getDetail(id) {
        let self = this
        OrderInteractor.purchaseGetDetailForShowApi({ businessId: id }).then(
          (res) => {
            const data = res.data ? res.data : null
            console.log(data)
            if (data) {
              //供应商状态
              if (data.supplierId) {
                this.getSupplierForAuditor(data.supplierId)
              }
              // 赋值全部数据
              self.detail = res.data
              this.sealedContract = res.data.sealedContract
                ? JSON.parse(res.data.sealedContract)
                : []
              // 赋值基本信息
              self.baseInfoTable = []
              self.baseInfoTable.push({
                supplierName: data.supplierName, //供应商名称
                agreementEndDate: data.agreementEndDate, //协议结束日期
                supplierLinkmanName: data.supplierLinkmanName, //联系人
                supplierLinkmanPhone: data.supplierLinkmanPhone, //联系电话
                buyerName: data.buyerName, //采购开发
                merchandiserName: data.merchandiserName, //QC
                receivingAddress: data.receivingAddress, //交货地址
                expectDeliveryDate: data.expectDeliveryDate, //交货日期
                transportWay: data.transportWay, //运输方式
                freightPayer: data.freightPayer, //费用承担方
                creator: data.creator, //创建人
                createTime: data.createTime, //创建时间
                isAgreement: data.isAgreement,
              })
              self.isRmb = data.currency == '人民币' ? true : false
              // 赋值附件
              self.fileList1 = res.data.contractToBeApproved
                ? JSON.parse(res.data.contractToBeApproved)
                : []
              self.fileList2 = res.data.sealedContract
                ? JSON.parse(res.data.sealedContract)
                : []
              // 赋值产品列表
              self.productListTable = data.purchaseProductShowVOList
              self.productListTable.forEach((item) => {
                item.fileList = item.logoInfo ? JSON.parse(item.logoInfo) : []
              })

              // 赋值采购收货列表
              self.cargoCollectList = data.purchaseProductShowVOList.filter(
                (item) => {
                  if (item.state == 11) {
                    return {
                      ...item,
                    }
                  }
                }
              )
              // 赋值采购入库列表
              self.cargoEnterList = data.purchaseProductShowVOList.filter(
                (item) => {
                  if (item.state != 0 && item.state != 11 && item.state != 31) {
                    return {
                      ...item,
                    }
                  }
                }
              )
              // 赋值采购完成列表
              self.cargoFinishList = data.purchaseProductShowVOList.filter(
                (item) => {
                  if (item.state == 31) {
                    return {
                      ...item,
                    }
                  }
                }
              )
              // 赋值付款环节列表
              self.paymentList = data.purchasePaymentMethodShowVOList
              // 赋值发票列表
              self.invoiceList = data.purchaseInvoiceShowVOList
              // 计算付款/发票(交易金额，已付金额)
              if (self.paymentList.length) {
                self.calculateMoney(self.paymentList)
              }
              // 计算付款/发票,申请付款出现的位置
              if (self.paymentList.length) {
                let initFlag = null
                let stateFlag = true
                initFlag = self.paymentList.every((item) => item.state == 0)
                // 第一次加载付款状态全为空申请付款显示在第一条
                if (initFlag) {
                  self.paymentIndex = 0
                } else {
                  // 已完成状态申请付款显示在下一条
                  self.paymentList.forEach((item, i) => {
                    if (i < self.paymentList.length - 1) {
                      // 第一次满足条件即退出循环
                      if (stateFlag) {
                        if (
                          item.state == 4 &&
                          self.paymentList[i + 1].state == 0 &&
                          self.paymentList[i + 1].applicationPayOrderState == -1
                        ) {
                          self.paymentIndex = i + 1
                          stateFlag = false
                        } else {
                          self.paymentIndex = null
                        }
                      }
                    }
                  })
                }
              }
              // 已付金额>=交易金额
              self.paymentVisible = self.paidMoney >= self.transactionMoney
            }
          }
        )
      },
      getMailInfo() {
        if (this.detail.isReviewed == 1) {
          return
        }
        OrderInteractor.listSendMailInfoApi({
          businessId: this.detail.purchaseOrderId,
        }).then((res) => {
          if (res.code == '000000') {
            let list = res.data
            list.forEach((item) => {
              let header = this.pubApi.exportHeader.find((obj) => {
                return item.exportHead == obj.code
              })
              let content = `<p><span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">Hi ${
                item.customerName
              }</span></p>
              <br/>
              <p>
                <span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">Thank you for your payment and and order confirmation.</span>
              </p>
              <p>
                <span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">The order has already been arranged into production.</span>
              </p>
              <p>
                <span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">The estimated ship date is :&nbsp;</span>
                <span style=\"-webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; font-size: 13px; font-family: &quot;Arial Negreta&quot;, &quot;Arial Normal&quot;, Arial, sans-serif; font-weight: 700; color: rgb(51, 51, 51);\">${
                  item.shippingDate ? item.shippingDate.substr(0, 10) : ''
                }</span>
              </p>
              <p>
                <span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">The estimated delivery date is :&nbsp;</span>
                <span style=\"-webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; font-size: 13px; font-family: &quot;Arial Negreta&quot;, &quot;Arial Normal&quot;, Arial, sans-serif; font-weight: 700; color: rgb(51, 51, 51);\">${
                  item.deliveryDate ? item.deliveryDate.substr(0, 10) : ''
                }</span>
              </p>
              <p style=\"white-space: normal;\">
                <span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">Tracking no. will be advised once it is available.</span>
              </p>
              <br/>
              <hr/>
              <br/>
              <p><span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">Best regards,</span></p>
              <p><span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">${
                header.companyName
              }</span></p>
              <p><span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">${
                header.address
              }</span></p>
              <p><span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">Tel: ${
                header.tel
              }</span></p>
              <p><span style=\"color: rgb(51, 51, 51); font-family: &quot;Arial Normal&quot;, Arial, sans-serif; font-size: 13px; \">Fax: ${
                header.fax
              }</span></p>`
              let title = `PO ${item.poNumber} - ${item.piNumber} production arranged`
              let sendForm = {
                singleDisplay: 0, // 1群发单显 0取消
                bccEmailList: [], // 密送人
                ccEmailList: [], // 抄送人
                content, // 邮件内容
                emailAccount: item.emailAccount, // 发件人
                enclosureList: [],
                receiveList: [item.customerEmail], // 收件人
                title, // 主题
                businessId: item.businessId,
              }
              let res = MailInteractor.sendMailApi(sendForm)
            })
          }
        })
      },
      calculateMoney(list) {
        let self = this
        let arr = []
        list.forEach((item) => arr.push(Number(item.amountActual)))
        self.paidMoney = parseFloat(arr.reduce((p, v) => p + v)).toFixed(3)
        // 交易金额 = 订单总金额 - 已退金额
        self.transactionMoney = self.detail.amountTotal - 0
      },

      //唛头格式转换
      getMark(item) {
        if (!item) {
          return []
        } else {
          return item.split('\n')
        }
      },

      //采购需求单号跳转
      salesOrderNumberClick(number) {
        if (number.includes('PD')) {
          this.$refs.stockUp.initLoading(number.split('--')[0])
        } else {
          let routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail?orderCode',
            query: {
              orderCode: number,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      // 查看合同
      printContract() {
        let bool = false
        let type = '1'
        if (['4', '5', '6'].includes(this.detail.modelName)) {
          bool =
            this.userInfo.area === '合肥' &&
            this.productListTable[0].salesOrderNumber.includes('PD')
              ? true
              : false
          const obj = {
            4: '1',
            5: '2',
            6: '3',
          }
          type = obj[this.detail.modelName]
        } else {
          type = this.detail.modelName
        }
        const path = bool
          ? '/print/purchaseContract-BH'
          : '/print/purchaseContract'
        let routeData = this.$router.resolve({
          path,
          query: {
            id: this.detailId,
            type,
          },
        })
        window.open(routeData.href, '_blank')
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      //供应商状态信息
      getSupplierForAuditor(supplierId) {
        this.supplierOtherMsg = {}
        SupplierManageInteractor.supplierForAuditorApi({
          supplierId: supplierId,
        }).then((res) => {
          if (res.code === '000000') {
            this.supplierOtherMsg = res.data
          }
        })
      },
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-container {
    .account-num {
      span {
        font-size: 16px;
        margin-right: 5px;
        font-family: 'PingFang Bold';
      }
    }
  }

  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;

    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }

    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }

  .supplierName-row {
    .tooltipOver {
      display: inline-block;
      width: 10px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .file_list {
    .file_line {
      width: 420px;
      height: 32px;
      background: #f5f7fa;
      border-radius: 3px;
      border: 1px solid #ebeef5;
      line-height: 32px;
      font-size: 12px;
      color: #606266;
      position: relative;
      display: flex;
      justify-content: flex-start;
      margin-top: 5px;

      span {
        margin-right: 15px;
      }

      .icon_file {
        font-size: 16px;
        color: #fa6400;
        position: absolute;
        left: 5px;
        top: 8px;
      }

      .file_name {
        padding-left: 25px;
        width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .size {
        width: 70px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .wrap {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: bottom;
    text-align: left;
  }
  // 采购订单详情页，产品列表-印刷文件样式优化
  // 此处仅处理当前页面，后面需要在custom.scss中统一处理，这里的样式可以删除
  .flie-list {
    display: flex;
    .page-link {
      flex: 1;
      overflow: hidden;
      text-align: left;
      span.ell {
        max-width: none; // 去掉max-width: 300px;
        width: 100%;
        > a {
          width: 100%;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
</style>
