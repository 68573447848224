<template>
  <div class="mail-write">
    <div v-show="!sendStatus" class="write-box">
      <div class="write-board">
        <div class="mail-base-item">
          <div class="title-btn">发件人</div>
          <div>
            <el-select v-model="sendForm.emailAccount" @change="changeCurrent">
              <el-option
                v-for="item in accountList"
                :label="item.emailAccount"
                :value="item.emailAccount"
                :key="item.emailAccount"
              ></el-option>
            </el-select>
          </div>

          <div class="right-btns">
            <el-button v-if="ccShowFlag" type="text" @click="changeCc">
              {{ ccVisible ? '删除抄送' : '添加抄送' }}
            </el-button>
            <span v-if="ccShowFlag">|</span>
            <el-button type="text" @click="handleForward">
              {{ sendForm.singleDisplay === 1 ? '取消群发单显' : '群发单显' }}
            </el-button>
          </div>
        </div>
        <div class="mail-base-item">
          <div class="title-btn">收件人</div>
          <div class="mail-base-item-container">
            <MailUser :list="sendForm.receiveList" @inhere="inputStatus = 1" />
          </div>
        </div>
        <div class="mail-base-item" v-if="ccVisible && ccShowFlag">
          <div class="title-btn">抄送人</div>
          <div>
            <MailUser :list="sendForm.ccEmailList" @inhere="inputStatus = 2" />
          </div>
        </div>
        <div class="mail-base-item">
          <div class="title-btn">主题</div>
          <div class="mail-base-item_inner">
            <el-input v-model="sendForm.title"></el-input>
          </div>
        </div>
        <div class="mail-attach">
          <el-dropdown>
            <div>
              <i class="iconfont icon-biezhen"></i>
              <span>添加附件(20M)</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>搜索附件</el-dropdown-item> -->
              <el-dropdown-item @click.native="handleUpload">
                浏览计算机
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="attach-files">
            <div
              v-for="(file, index) in sendForm.enclosureList"
              :key="file.fileId"
            >
              <i class="iconfont icon-biezhen"></i>
              <el-tooltip
                class="item"
                effect="dark"
                :content="file.enclosureName"
                placement="top"
              >
                <span class="file-name">
                  {{ file.enclosureName }}
                </span>
              </el-tooltip>
              <span>{{ file.enclosureSize | sizeFilter }}</span>
              <i class="el-icon-close" @click="deleteFile(index)"></i>
            </div>
          </div>
        </div>
        <div class="ueditor-write">
          <Ueditor :id="'ueditor' + new Date().getTime()" ref="ueditor" />
        </div>
      </div>

      <div class="board-bottom">
        <div>
          <el-button type="primary" @click="sendAction">发送</el-button>
          <!-- <el-button type="primary" plain @click="sendTimingAction">
            定时发送
          </el-button> -->
          <el-button type="primary" plain @click="saveDraft">存草稿</el-button>
          <el-button type="default" plain @click="handleGiveUp">放弃</el-button>

          <!-- <el-checkbox class="ml-20">紧急</el-checkbox>
          <el-checkbox>需要回执</el-checkbox>
          <el-checkbox>显示发件人</el-checkbox> -->
        </div>
        <!-- <div>
        <el-button type="text">选择模板</el-button>
        <el-button type="text">签名设置</el-button>
      </div> -->
      </div>

      <input
        ref="AddFile"
        type="file"
        style="display: none"
        @change="changeFile"
      />
    </div>
    <div class="mail-success" v-show="sendStatus">
      <div v-if="successConfig.type == 'immediate'">
        <div class="success-icon">
          <i class="iconfont icon-qingqiuyifasong"></i>
        </div>
        <p class="mail-success-tip1">您的邮件已发送</p>
        <p class="mail-success-tip2">
          {{
            sendForm.ccEmailList.length
              ? `此邮件已成功发送至${sendForm.receiveList.length}个收件人${sendForm.ccEmailList.length}个抄送人，并已保存到“已发邮件”文件夹`
              : `此邮件已成功发送至${sendForm.receiveList.length}个收件人，并已保存到“已发邮件”文件夹`
          }}
        </p>
        <div>
          <el-button type="primary" plain @click="handleBackToReceive">
            返回邮件列表
          </el-button>
          <el-button type="primary" plain @click="handleRewrite">
            再写一封
          </el-button>
          <el-button type="primary" plain @click="handleBackToSend">
            查看已发邮件
          </el-button>
        </div>
      </div>
      <div v-if="successConfig.type == 'timing'">
        <div class="success-icon">
          <i class="iconfont icon-qingqiuyifasong"></i>
        </div>
        <p class="mail-success-tip1">您的定时邮件保存成功</p>
        <p class="mail-success-tip2">
          该邮件展示保存在“草稿箱”中，它将在您指定的时间发出。
        </p>
        <div>
          <el-button type="primary" plain @click="handleBackToReceive">
            返回邮件列表
          </el-button>
          <el-button type="primary" plain @click="handleRewrite">
            再写一封
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFile } from '@/api/mail/mail.js'
import Ueditor from '@/components/Ueditor/Ueditor.vue'
import MailUser from './MailUser.vue'
import { orderEnquiryDetail } from '@/api/order'
import { mapGetters } from 'vuex'
import {
  sendMail,
  addDraft,
  getMailDetailById,
  updateDraft,
} from '@/api/mail/mail.js'
export default {
  components: {
    Ueditor,
    MailUser,
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
    accountList() {
      let list = this.$store.state.mail.accountList
      if (!this.sendForm.emailAccount) {
        if (!this.$store.state.mail.currentAccount) {
          this.$store.commit(
            'mail/setCurrentAccount',
            this.sendForm.emailAccount
          )
        } else {
          this.sendForm.emailAccount = this.$store.state.mail.currentAccount
        }
      }
      return list
    },
  },
  watch: {
    '$route.query.from': {
      handler(n) {
        if (n === undefined) {
          this.sendForm = {
            bccEmailList: [], // 密送人
            ccEmailList: [], // 抄送人
            content: '', // 邮件内容
            emailAccount: '', // 发件人
            enclosureList: [],
            receiveList: [], // 收件人
            title: '', // 主题
            businessJson: null,
          }
          setTimeout(() => {
            this.$refs.ueditor.setReadyContent('')
          }, 666)
        }
      },
      immediate: true,
    },
  },
  data() {
    const editHeight = () => {
      const H = document.body.clientHeight
      return H - 428
    }
    return {
      ccShowFlag: true,
      ccVisible: true,
      allVisible: true,
      inputStatus: 1, //当前是在输入那个收件人
      sendStatus: false, //发送状态
      successConfig: {
        // 提示config
        type: 'immediate',
      },
      sendForm: {
        singleDisplay: 0, // 1群发单显 0取消
        bccEmailList: [], // 密送人
        ccEmailList: [], // 抄送人
        content: '', // 邮件内容
        emailAccount: '', // 发件人
        enclosureList: [],
        receiveList: [], // 收件人
        title: '', // 主题
      },
      draftMailId: '',
      fileSize: 20,
    }
  },
  methods: {
    addContacts(v) {
      if (this.inputStatus == 1) {
        if (!this.sendForm.receiveList.includes(v.contactEmail)) {
          this.sendForm.receiveList.push(v.contactEmail)
        }
      } else {
        if (!this.sendForm.ccEmailList.includes(v.contactEmail)) {
          this.sendForm.ccEmailList.push(v.contactEmail)
        }
      }
    },
    getValue() {},
    inSertAfter() {
      this.$refs.ueditor.inSertAfter()
    },

    changeFile(e) {
      const file = e.target.files,
        nomarFiles = [],
        maxAttachLen = 20,
        fileType = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf']

      let successLen = 0

      if (maxAttachLen < file.length) {
        this.$message.error(`附件数量超出${maxAttachLen}个`)
        return
      }

      for (let v of file) {
        if (v.size / 1024 / 1024 >= this.fileSize) {
          this.$message.error(`${v.name}大小超过${this.fileSize}M，无法上传`)
          return
        }
        nomarFiles.push({
          file: v,
          flagSaveName: 1,
          source: 1,
          uploadType: 1,
        })
      }

      e.target.value = ''

      let that = this
      nomarFiles.forEach((v) => {
        uploadFile(v).then((res) => {
          if ((res.code = '0000')) {
            this.sendForm.enclosureList.push({
              enclosureName: res.data.fileName,
              fileId: res.data.fileId,
              enclosureSize: res.data.size,
              enclosureUrl: res.data.path,
              suffix: res.data.suffix,
              uploadType: 1,
              mailEnclosureId: res.data.fileId,
              source: 1,
            })
          }
        })
      })
    },
    // 删除上传附件
    deleteFile(index) {
      this.sendForm.enclosureList.splice(index, 1)
    },

    handleUpload() {
      this.$refs.AddFile.click()
    },

    // 发送操作
    sendAction() {
      this.sendForm.content = this.$refs.ueditor.getUEContent()
      let data = { ...this.sendForm }
      if (
        this.$route.query.from === 'draft' &&
        this.$route.query.mailId != undefined
      ) {
        data.mailId = this.$route.query.mailId
      }
      if (!this.sendForm.receiveList.length) {
        return this.$message({
          type: 'warning',
          message: '请选择收件人',
        })
      }
      if (this.sendForm.title && this.sendForm.title.length > 150) {
        return this.$message({
          type: 'warning',
          message: '邮件主题限制150个字符',
        })
      }
      data.businessId = this.userInfo.userId
      if (!this.sendForm.title) {
        this.$confirm('当前邮件未填写主题 ?', '提示', {
          confirmButtonText: '确定发送',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            sendMail(data).then((res) => {
              this.successConfig.type = 'immediate'
              this.$message({
                type: 'success',
                message: '发送成功！',
              })
              this.draftMailId = ''
              this.replyTag()
              this.$emit('updateLatest')
              Bus.$emit('refreshData') // 触发更新邮件分类数量
              this.sendStatus = true
            })
          })
          .catch(() => {})
      } else {
        sendMail(data).then((res) => {
          this.successConfig.type = 'immediate'
          this.$message({
            type: 'success',
            message: '发送成功！',
          })
          this.draftMailId = ''
          this.replyTag()
          this.$emit('updateLatest')
          Bus.$emit('refreshData') // 触发更新邮件分类数量
          this.sendStatus = true
        })
      }
    },

    // 定时发送
    sendTimingAction(event) {
      alert('功能正在开发')
      return
    },

    // 保存至草稿
    saveDraft(event) {
      if (this.sendForm.title && this.sendForm.title.length > 150) {
        return this.$message({
          type: 'warning',
          message: '邮件主题限制150个字符',
        })
      }
      this.sendForm.content = this.$refs.ueditor.getUEContent()
      let data = { ...this.sendForm }

      data.businessId = this.userInfo.userId
      if (
        this.draftMailId ||
        (this.$route.query.from == 'draft' &&
          this.$route.query.mailId != undefined)
      ) {
        data.mailId = this.draftMailId || this.$route.query.mailId
        updateDraft(data).then((res) => {
          this.$message({
            type: 'success',
            message: '邮件已保存至草稿箱',
          })
        })
      } else {
        addDraft(data).then((res) => {
          if (res.code === '000000') {
            this.draftMailId = res.data
            this.$message({
              type: 'success',
              message: '邮件已保存至草稿箱',
            })
            Bus.$emit('refreshData') // 触发更新邮件分类数量
          }
        })
      }
      this.initButtonStyle(event)
    },

    initButtonStyle(event) {
      let target = event.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = event.target.parentNode
      }
      target.blur()
    },

    handleBackToSend() {
      // 处理跳转至收件箱列表
      this.$router.push({ path: '/mail/my-mail/send' })
    },

    handleBackToReceive() {
      // 处理跳转至收件箱列表
      this.$router.push({ path: '/mail/my-mail/receive' })
    },

    handleGiveUp() {
      // 处理跳转至收件箱列表
      this.$router.push({ path: '/mail/my-mail/receive' })
    },

    // 再写一封
    handleRewrite() {
      this.draftMailId = ''
      this.sendForm = {
        bccEmailList: [], // 密送人
        ccEmailList: [], // 抄送人
        content: '', // 邮件内容
        emailAccount: '', // 发件人
        enclosureList: [],
        receiveList: [], // 收件人
        title: '', // 主题
        businessJson: null,
        singleDisplay: 0,
      }
      // 再写一封邮件
      this.sendStatus = false
      setTimeout(() => {
        this.$refs.ueditor.setReadyContent('')
      }, 666)
    },

    // 切换当前发件人
    changeCurrent(val) {
      this.sendForm.emailAccount = val
      this.$store.commit('mail/setCurrentAccount', val)
    },

    // 切换抄送人
    changeCc() {
      if (this.ccVisible) {
        this.sendForm.ccEmailList = []
        this.inputStatus = 2
      }
      this.ccVisible = !this.ccVisible
    },

    // 回复邮件给之前的邮件打标识
    replyTag() {
      if (
        this.$route.query.from === 'reply' ||
        this.$route.query.from === 'business'
      ) {
        if (
          this.$route.query.mailId &&
          this.$route.query.mailId !== undefined
        ) {
          this.$store
            .dispatch('mail/setMailTag', {
              mailId: this.$route.query.mailId,
              reply: 1,
              businessId: this.userInfo.userId,
            })
            .then((res) => {})
            .catch((err) => {})
        }
      } else if (this.$route.query.from === 'transform') {
        this.$store
          .dispatch('mail/setMailTag', {
            mailId: this.$route.query.mailId,
            isForward: 1,
            businessId: this.userInfo.userId,
          })
          .then((res) => {})
          .catch((err) => {})
      }
    },

    // 群发单显功能
    handleForward() {
      if (this.sendForm.singleDisplay) {
        this.ccShowFlag = true
        this.sendForm.singleDisplay = 0
      } else {
        this.ccShowFlag = false
        this.sendForm.ccEmailList = []
        this.sendForm.singleDisplay = 1
      }
    },

    // 转化收件人，抄送人
    getMailStr(str) {
      if (str) {
        let reStr = str
          .split(',')
          .map((ele) => {
            return `${ele.split('@')[0]}&lt;${ele}&gt;`
          })
          .join(';')
        return reStr
      } else {
        return '--'
      }
    },
  },

  created() {
    // 在写邮件页面再次点击写邮件
    this.$root.$on('initWriteBoard', () => {
      this.handleRewrite()
    })
  },

  mounted() {
    // 来源 book 通讯录 draft 草稿箱 transform 转发的  reply 回复的  offer 报价业务 business 其他业务
    if (this.$route.query.from === 'draft') {
      getMailDetailById(this.$route.query.mailId).then((res) => {
        this.draftMailId = this.$route.query.mailId
        this.sendForm = {
          bccEmailList: [], // 密送人
          ccEmailList: res.data.extendInfo.ccEamils
            ? res.data.extendInfo.ccEamils.split(',')
            : [], // 抄送人
          content: res.data.extendInfo.content || '', // 邮件内容
          emailAccount: this.$store.state.mail.currentAccount, // 发件人
          enclosureList: res.data.enclosureList || [],
          receiveList: res.data.extendInfo.receiveEmails
            ? res.data.extendInfo.receiveEmails.split(',')
            : [], // 收件人
          title: res.data.baseInfo.title, // 主题
          businessJson: res.data.baseInfo.businessJson,
          singleDisplay: 0,
        }
        if (res.data.extendInfo.ccEamils) {
          this.ccVisible = true
        }
        if (res.data.extendInfo.content) {
          setTimeout(() => {
            this.$refs.ueditor.setReadyContent(res.data.extendInfo.content)
          }, 666)
        }
      })
    } else if (this.$route.query.from === 'transform') {
      getMailDetailById(this.$route.query.mailId).then((res) => {
        let ccStr = res.data.extendInfo.ccEamils
          ? `<p><strong>抄送人：</strong>${this.getMailStr(
              res.data.extendInfo.ccEamils
            )}</p>`
          : ''
        let xpStr = res.data.baseInfo.businessJson
          ? `<p><strong>询盘单号：</strong>${
              res.data.baseInfo.businessJson || '--'
            }</p>`
          : (res.data.businessJson
          ? `<p><strong>询盘单号：</strong>${res.data.businessJson || '--'}</p>`
          : '')
        let receiveManStr = this.getMailStr(res.data.extendInfo.receiveEmails)
        let str = `${xpStr}<br/><br/><hr/><p><strong>发件人：</strong>${
          res.data.baseInfo.sendEmail
        }</p>
        <p><strong>发送时间：</strong>${this.utils.formatTime(
          res.data.baseInfo.sendTime,
          'mail-info'
        )}</p>
        <p><strong>收件人：</strong>${receiveManStr}</p>
        ${ccStr}
        <p><strong>主题：</strong>${res.data.baseInfo.title || ''}</p><hr/>`
        let content = str + (res.data.extendInfo.content || '')
        this.sendForm = {
          bccEmailList: [], // 密送人
          ccEmailList: [], // 抄送人
          content, // 邮件内容
          emailAccount: this.$store.state.mail.currentAccount, // 发件人
          enclosureList: res.data.enclosureList ? res.data.enclosureList : [],
          receiveList:
            this.$route.query.receiveMail !== undefined
              ? [this.$route.query.receiveMail]
              : [], // 收件人
          title: '转发:' + (res.data.baseInfo.title || ''), // 主题
          businessJson: res.data.baseInfo.businessJson,
          singleDisplay: 0,
        }
        if (content) {
          setTimeout(() => {
            this.$refs.ueditor.setReadyContent(content)
          }, 666)
        }
      })
    } else if (this.$route.query.from === 'reply') {
      let hasFile = false
      if (typeof this.$route.query.file === 'string') {
        hasFile = this.$route.query.file === 'true'
      } else {
        hasFile = this.$route.query.file
      }
      getMailDetailById(this.$route.query.mailId).then((res) => {
        let ccStr = res.data.extendInfo.ccEamils
          ? `<p><strong>抄送人：</strong>${this.getMailStr(
              res.data.extendInfo.ccEamils
            )}</p>`
          : ''
        let xpStr = res.data.baseInfo.businessJson
          ? `<p><strong>询盘单号：</strong>${
              res.data.baseInfo.businessJson || '--'
            }</p>`
          : ''
        let str = `${xpStr}<br/><br/><hr/><p><strong>发件人：</strong>${
          res.data.baseInfo.sendEmail
        }</p>
        <p><strong>发送时间：</strong>${this.utils.formatTime(
          res.data.baseInfo.sendTime,
          'mail-info'
        )}</p>
        <p><strong>收件人：</strong>${this.getMailStr(
          res.data.extendInfo.receiveEmails
        )}</p>
        ${ccStr}
        <p><strong>主题：</strong>${res.data.baseInfo.title || ''}</p><hr/>`
        let content = str + (res.data.extendInfo.content || '')
        this.sendForm = {
          bccEmailList: [], // 密送人
          ccEmailList: [], // 抄送人
          content, // 邮件内容
          emailAccount: this.$store.state.mail.currentAccount, // 发件人
          enclosureList: hasFile ? res.data.enclosureList || [] : [],
          receiveList: [res.data.baseInfo.sendEmail], // 收件人
          title: '回复:' + (res.data.baseInfo.title || ''), // 主题
          businessJson: res.data.baseInfo.businessJson,
          singleDisplay: 0,
        }
        if (content) {
          setTimeout(() => {
            this.$refs.ueditor.setReadyContent(content)
          }, 666)
        }
      })
    } else if (this.$route.query.from === 'offer') {
      let file = JSON.parse(this.$route.query.file)
      this.sendForm = {
        bccEmailList: [], // 密送人
        ccEmailList: [], // 抄送人
        content: '', // 邮件内容
        emailAccount: '', // 发件人
        enclosureList: [file],
        receiveList: [this.$route.query.email], // 收件人
        title: '', // 主题
        businessJson: null,
        singleDisplay: 0,
      }
    } else if (this.$route.query.from === 'business') {
      if (this.$route.query.mailId && this.$route.query.mailId !== undefined) {
        getMailDetailById(this.$route.query.mailId).then((res) => {
          let ccStr = res.data.extendInfo.ccEamils
            ? `<p><strong>抄送人：</strong>${this.getMailStr(
                res.data.extendInfo.ccEamils
              )}</p>`
            : ''
          let xpStr = res.data.baseInfo.businessJson
            ? `<p><strong>询盘单号：</strong>${
                res.data.baseInfo.businessJson || '--'
              }</p>`
            : ''
          let str = `${xpStr}<br/><br/><hr/><p><strong>发件人：</strong>${
            res.data.baseInfo.sendEmail
          }</p>
          <p><strong>发送时间：</strong>${this.utils.formatTime(
            res.data.baseInfo.sendTime,
            'mail-info'
          )}</p>
          <p><strong>收件人：</strong>${this.getMailStr(
            res.data.extendInfo.receiveEmails
          )}</p>
          ${ccStr}
          <p><strong>主题：</strong>${res.data.baseInfo.title || ''}</p><hr/>`
          let content = str + (res.data.extendInfo.content || '')
          let file = JSON.parse(this.$route.query.file)
          let isArray = file instanceof Array
          this.sendForm = {
            bccEmailList: [], // 密送人
            ccEmailList: [], // 抄送人
            content, // 邮件内容
            emailAccount: '', // 发件人
            enclosureList: isArray ? file : [file],
            receiveList: [this.$route.query.email], // 收件人
            title: '回复:' + (res.data.baseInfo.title || ''), // 主题// 主题
            businessJson: res.data.baseInfo.businessJson,
            singleDisplay: 0,
          }
          if (content) {
            setTimeout(() => {
              this.$refs.ueditor.setReadyContent(content)
            }, 666)
          }
        })
      } else {
        let file = ''
        let isArray = null
        if (this.$route.query.file) {
          file = JSON.parse(this.$route.query.file)
          isArray = file instanceof Array
          this.sendForm = {
            bccEmailList: [], // 密送人
            ccEmailList: [], // 抄送人
            content: '', // 邮件内容
            emailAccount: '', // 发件人
            enclosureList: isArray ? file : [file],
            title: '', // 主题
            businessJson: null,
            singleDisplay: 0,
          }
        } else {
          // 处理订单详情生成链接跳转
          this.sendForm = {
            bccEmailList: [], // 密送人
            ccEmailList: [], // 抄送人
            content: '', // 邮件内容
            emailAccount: '', // 发件人
            title: '', // 主题
            businessJson: null,
            singleDisplay: 0,
          }
          setTimeout(() => {
            this.$refs.ueditor.setReadyContent(this.$route.query.content)
            this.$forceUpdate()
          }, 666)
        }
        this.sendForm.receiveList = [this.$route.query.email] //收件人

        if (
          this.$route.query.enquiryId &&
          this.$route.query.enquiryId !== undefined
        ) {
          let str = ''
          orderEnquiryDetail({ businessId: this.$route.query.enquiryId }).then(
            (res) => {
              let products = res.data.products
              if (products.length) {
                //循环产品
                products.forEach((item, index) => {
                  str += `<p style="margin-top:5px;"></p><p>Item #：${item.productCn}</p>
                              <p>Quantity：${item.amount}</p>
                              <p>Color：${item.specification}</p>
                              <p>Printing：${item.printingWay}</p>
                              <p>Deadline：</p>
                              <p>Shipping to zip- code：</p>`
                })
              }
              let ccStr = `<p>Hello , </p>
          <p>Thank you for your interest in our products.  We will be pleased to provide you the information you need.  Please review the following information to the best of your knowledge and we will reply with current pricing, complete with production time breakdown as soon as possible.</p>
          `

              let content = ccStr + str
              if (content) {
                setTimeout(() => {
                  this.$refs.ueditor.setReadyContent(content)
                  this.$forceUpdate()
                }, 666)
              }
            }
          )
        }
      }
    } else if (this.$route.query.from === 'book') {
      this.sendForm = {
        bccEmailList: [], // 密送人
        ccEmailList: [], // 抄送人
        content: '', // 邮件内容
        emailAccount: '', // 发件人
        enclosureList: [],
        receiveList: this.$route.query.receive.split(','), // 收件人
        title: '', // 主题
        businessJson: null,
        singleDisplay: 0,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.mail-write {
  height: 100%;
}
.write-box {
  height: 100%;
}
.ml-20 {
  margin-left: 40px;
}
.write-board {
  padding: 10px 20px 20px;
  box-sizing: border-box;
  background: #fff;
  box-sizing: border-box;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  .title-btn {
    flex-shrink: 0;
    width: 82px;
    background: #f8fbff;
    border-radius: 3px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    color: #303030;
    height: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .mail-base-item {
    padding: 10px 0 0;
    display: flex;
    line-height: 30px;
    border-bottom: 1px solid #ebeef5;
    position: relative;
    /deep/ .el-input__inner {
      border: none !important;
      height: 30px !important;
      line-height: 30px !important;
    }
    .mail-base-item_inner {
      flex: 1;
      /deep/ .el-input__inner {
        width: 100%;
        border: none !important;
        height: 30px !important;
        line-height: 30px !important;
      }
    }
    .right-btns {
      position: absolute;
      right: 0;
      top: 0;
      height: 30px;
      line-height: 30px;
    }
  }
  .mail-attach {
    padding: 10px 0;
    & > div:first-child {
      line-height: 16px;
      cursor: pointer;
      i {
        color: #fa6400;
        font-size: 10px;
      }
      span {
        margin-left: 10px;
        font-size: 12px;
        color: #303030;
      }
    }
    .attach-files {
      display: flex;
      flex-wrap: wrap;
      & > div {
        height: 30px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #ebeef5;
        padding: 0 12px;
        margin-top: 10px;
        margin-right: 20px;
        line-height: 30px;
        display: flex;
        & > i {
          line-height: 30px;
        }
        & > span {
          margin-right: 10px;
        }
        & > .file-name {
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & > i:first-child {
          font-size: 12px;
          color: #fa6400;
          margin-right: 10px;
        }
        & > i:last-child {
          cursor: pointer;
        }
      }
    }
  }
  .ueditor-write {
    flex: 1;
    overflow-y: auto;
    #edui1 {
      height: 100%;
    }
  }
}
.board-bottom {
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(223, 223, 223, 0.5);
  border-radius: 0px 0px 3px 3px;
  padding: 14px 21px;
  border-bottom: 2px solid #f8fbff;
  display: flex;
  justify-content: space-between;
}

.mail-success {
  background: #fff;
  height: 100%;
  padding-top: 85px;
  text-align: center;
  & > .success-icon {
    i {
      font-size: 60px;
      color: #2eb797;
    }
  }
  .mail-success-tip1 {
    font-size: 18px;
    color: #303030;
    margin: 24px auto 12px;
  }
  .mail-success-tip2 {
    font-size: 12px;
    color: #777777;
    margin-bottom: 32px;
  }
}
</style>
