<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年05月10日 13:12:35
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <span v-if="!fields || fields.length === 0">--</span>
  <span v-else class="fields-wrapper" :class="{ 'page-link': link }">
    {{ fields.join(' / ') }}
  </span>
</template>

<script>
  export default {
    name: 'MutipleFields',
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
      link: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped lang="scss">
  .fields-wrapper {
    display: inline-block;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
</style>
