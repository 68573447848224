<template>
  <el-table class="mt15" :data="tableData" border style="width: 100%">
    <el-table-column prop="area" align="center" label="地区" show-overflow-tooltip>
      <template slot-scope="scope">
        <span class="c_pointer">
          {{ scope.row.area || '-' }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="rendImgDisplayedText" align="center" label="产品效果图" show-overflow-tooltip>
    </el-table-column>
    <el-table-column prop="qrCodeDisplayedText" align="center" label="下载预览箱唛带二维码" show-overflow-tooltip>
    </el-table-column>
    <el-table-column align="center" label="操作" width="120" :resizable="false">
      <template slot-scope="scope">
        <span v-if="scope.row.amountStatus === 0">-</span>
        <el-tooltip content="编辑" placement="top">
          <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
            @click="editMark(scope.row)"></el-button>
        </el-tooltip>

        <el-tooltip content="删除" placement="top">

          <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
            @click="deleteMark(scope.row)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
  import { OrderSettingInteractor } from '@/core'
  export default {
    name: 'downloadMarkSettingTable',
    props: {
      settingType: {
        type: String,
        default: "ORDER"
      }
    },
    data() {
      return {
        tableData: []
      }
    },
    methods: {
      async getTableData() {
        this.tableData = await OrderSettingInteractor.marksConfigPageApi(this.settingType === 'ORDER' ? 0 : 1)
      },

      editMark(row) {
        this.$emit('edit-mark', row)
      },
      deleteMark(row) {

        this.$confirm('删除后无法恢复,是否删除?', '提示', {
          type: 'warning',
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        })
          .then(() => {
            OrderSettingInteractor.marksConfigDeleteApi(row.id).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('删除成功')
                this.getTableData()
              }
            })
          })
          .catch(() => { })

      }
    },
    created() {
      this.getTableData()
    }
  }
</script>
<style lang="scss"></style>