<!--
  功能：结算模板配置
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月19日 15:10:51
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div>
    <div class="index-page" v-if="!newpage">
      <!-- 搜索表单 -->
      <div class="track-search">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <!-- 新增按钮 -->
          <el-form-item>
            <el-button type="primary" @click="addNew">新增</el-button>
          </el-form-item>
          <el-form-item label="模板编号" label-width="80">
            <el-input
              v-model="queryForm.templateNumber"
              clearable
              placeholder="请输入"
              size="medium"
              @keyup.enter.native="onSearch"
              style="width: 175px"
            ></el-input>
          </el-form-item>

          <el-form-item label="模板名称" label-width="80">
            <el-input
              v-model="queryForm.name"
              clearable
              placeholder="请输入"
              size="medium"
              @keyup.enter.native="onSearch"
              style="width: 175px"
            ></el-input>
          </el-form-item>

          <el-form-item label="计算类型" label-width="80">
            <el-select
              v-model="queryForm.type"
              clearable
              size="medium"
              placeholder="请选择"
              style="width: 175px"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="固定" :value="1"></el-option>
              <el-option label="阶梯" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="模板状态" label-width="80">
            <el-select
              v-model="queryForm.status"
              clearable
              size="medium"
              placeholder="请选择"
              style="width: 175px"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="启用" :value="1"></el-option>
              <el-option label="停用" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSearch">搜索</el-button>
            <el-button @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 列表 -->
      <div class="table-wrapper">
        <PageTable
          @get-list="getList"
          @template-freeze="freezeTpl"
          @ladder-detail="showLadderDetail"
          @tpl-edit="editTpl"
          :tableData="list"
          :total="total"
          ref="table"
        />
      </div>
      <!--明细弹窗-->
      <LadderDetail :tableData="ladderList" :config="config" />
    </div>
    <div class="new-page" v-else>
      <New ref="new"/>
    </div>
  </div>
</template>

<script>
  import PageTable from './settlement-tpl-config-table.vue'
  import LadderDetail from './settlement-tpl-config-ladder-detail'
  import { TplConfigInteractor } from '@/core/interactors/finance/config-manage'
  import New from './settlement-tpl-config-new'

  export default {
    name: 'SettlementTplConfigInteractor',
    components: {
      PageTable, //分页列表
      LadderDetail,
      New, //新增或编辑
    },
    // 组件状态值
    data() {
      return {
        queryForm: {
          //搜索表单
          templateNumber: '', //	模板编号
          name: '', //	模板名称
          type: '', //	模板类型（1：固定，2阶梯）
          status: '', //	状态 1：启用 0：冻结
        },
        list: [], // 列表数据
        total: 0, // 总数量
        config: {
          visible: false,
        }, //弹窗控制
        ladderList: [], //明细列表数据
        newpage: false
      }
    },
    computed: {},

    methods: {
      addNew() {
        this.$router.push({
          name: 'settlementTplConfigInteractorNew',
          query: {
            o: 'new', // operation="new"
          },
        })
      },
      // 获取列表数据
      async getList(params) {
        const res = await TplConfigInteractor.getListPage({
          ...params,
          ...this.queryForm,
        })

        if (res && res.code === '000000') {
          this.list = res.data.data
          this.total = res.data.total
        } else {
          this.list = []
          this.total = 0
        }
      },

      // 点击搜索
      onSearch() {
        this.getList({
          pageNo: 1,
          pageLe: this.$refs.table.pageLe,
        })
      },

      // 重置
      reset() {
        this.$refs.table.resetPage()

        this.queryForm = this.$options.data().queryForm
        this.getList({
          pageLe: 10,
          pageNo: 1,
          ...this.queryForm,
        })
      },

      // 阶梯明细
      async showLadderDetail({ configCommissionTemplateId }) {
        const res = await TplConfigInteractor.tieredsDetails(configCommissionTemplateId)
        if (res && res.code === '000000') {
          this.ladderList = res.data
          this.config.visible = true
        }
      },

      // 冻结
      async freezeTpl({ configCommissionTemplateId, status }) {
        const res = await TplConfigInteractor.tplEnabled({
          configCommissionTemplateId,
          status: status,
        })
        if (res.code === '000000') {
          this.$message.success(status === 1 ? '启用成功' : '冻结成功')
          this.getList({
            ...this.queryForm,
            pageNo: this.$refs.table.pageNo,
            pageLe: this.$refs.table.pageLe,
          })
        }
      },

      // 模板编辑
      editTpl({ configCommissionTemplateId }) {
        this.$router.push({
          name: 'settlementTplConfigInteractorNew',
          query: {
            o: 'edit', // operation="new",
            bid: configCommissionTemplateId,
          },
        })
      },

      
    },

    created() {
      this.getList({
        pageLe: 10,
        pageNo: 1,
        ...this.queryForm,
      })
    },
  }
</script>

<style scoped lang="scss">
  .track-search {
    display: flex;
  }
  .table-wrapper {
    // margin-top: 15px;
  }
  .el-form {
    display: flex;
  }
</style>
