export const mixin = {
  methods: {
    /*
      在获取审核模板前，需要拿主图、细节图、规格图、印刷底图、SAGE图和审核通过时的图片进行比对，如果发生变化，返回0，此时需要美工审核；否则返回1，不需要美工审核
      入参：
        params：Object类型，必传，当前页面的infoParams
        page：Number类型，选传，如果传了2，表示第二页调用该函数，需要比较SAGE图

      返回值：
        Number类型，1=不需要美工审核 0=需要美工审核
    */
    getAuditSubType(params, page) {
      const {
        imgMain, // 主图
        productSpecsDTOList, // 规格图
        surfaceDTOList, // 印刷底图
        productFileDTOList, // 细节图、刀模图
        productPrevisonFileEditVOList, // 审核通过时的图片数据
      } = params
      if (!productPrevisonFileEditVOList) return 0
      // 规格图urls
      const productSpecsUrls = productSpecsDTOList?.length
        ? productSpecsDTOList.map((n) => n.image)
        : []
      // 印刷底图urls
      const surfaceUrls = surfaceDTOList?.length
        ? surfaceDTOList.reduce((finalList, item) => {
            if (item.surfaceRelationDTOList?.length) {
              for (const n of item.surfaceRelationDTOList) {
                finalList.push(n.fileUrl)
              }
            }
            return finalList
          }, [])
        : []
      // 细节图urls
      const imgSwiperUrls = productFileDTOList?.length
        ? productFileDTOList.flatMap((n) => (n.type === '细节图' ? n.url : []))
        : []
      let prevImgMain = '' // 上一次的主图
      const prevProductSpecsUrls = [] // 上一次的规格图
      const prevSurfaceUrls = [] // 上一次的印刷底图
      const prevImgSwiperUrls = [] // 上一次的细节图
      for (const item of productPrevisonFileEditVOList) {
        if (item.type === '主图') {
          prevImgMain = item.url
        } else if (item.type === '规格图') {
          prevProductSpecsUrls.push(item.url)
        } else if (item.type === '印刷底图') {
          prevSurfaceUrls.push(item.url)
        } else if (item.type === '细节图') {
          prevImgSwiperUrls.push(item.url)
        }
      }
      // console.log('主图', imgMain === prevImgMain)
      // console.log('规格图', this.isInclude(prevProductSpecsUrls, productSpecsUrls),{prevProductSpecsUrls, productSpecsUrls})
      // console.log('印刷底图', this.isInclude(prevSurfaceUrls, surfaceUrls),{prevSurfaceUrls, surfaceUrls})
      // console.log( '细节图', this.isInclude(prevImgSwiperUrls, imgSwiperUrls),{prevImgSwiperUrls, imgSwiperUrls})
      // 产品主图、规格图、印刷底图、细节图是否有变化
      let haveChange =
        imgMain === prevImgMain &&
        this.isInclude(prevProductSpecsUrls, productSpecsUrls) &&
        this.isInclude(prevSurfaceUrls, surfaceUrls) &&
        this.isInclude(prevImgSwiperUrls, imgSwiperUrls)
      // 产品编辑第二页
      if (page === 2) {
        const { platformProductImgS, prePreversionPlatformProductImgS } =
          this.platformData
        // 当前的SAGE图
        const sageUrls = platformProductImgS?.length
          ? platformProductImgS.map((n) => n.imgUrl)
          : []
        // 上一次SAGE图
        const prevSageUrls = prePreversionPlatformProductImgS?.length
          ? prePreversionPlatformProductImgS.map((n) => n.imgUrl)
          : []
        // console.log('sage图', this.isInclude(prevSageUrls, sageUrls),{prevSageUrls,sageUrls})
        haveChange = haveChange && this.isInclude(prevSageUrls, sageUrls)
      }
      if (haveChange) {
        return 1 // 无变化
      } else {
        return 0 // 有变化
      }
    },
    // 判断arr1是否包含arr2
    isInclude: (arr1, arr2) => arr2.every((val) => arr1.includes(val)),
  },
}
