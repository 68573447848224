<template>
  <div class="product-ricing">
    <h3 class="product-ricing-tit">Product</h3>
    <el-row class="product-ricing-search pl40">
      <el-form :model="product">
        <el-col
          :span="6"
          :class="[
            'form-box',
            'mb20',
            { removed: auditProcess == 2 && mallStatus != 1 },
          ]"
        >
          <el-form-item prop="itemNo">
            <template #label>
              <i style="color: #ff4d4f">*</i>
              Item No.：
            </template>
            <el-select
              placeholder="please select"
              popper-class="itemNo-select"
              v-model="product.productId"
              v-loadmore:el-select-dropdown__list="itemNoLoadMore"
              filterable
              remote
              clearable
              ref="itemNoRef"
              :remote-method="itemNoMethod"
              :loading="itemNoPageInfo.loading"
              @focus="() => itemNoMethod('')"
              @visible-change="itemNoBlur"
              @change="itemNoChange"
              onkeypress="if(event.keyCode==32){return false}"
              @keydown.native.enter="itemNoEnterFn"
            >
              <el-option
                v-for="item in itemNoOptions"
                :key="item.productId"
                :label="item.productCode"
                :value="item.productId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="form-box mb20">
          <el-form-item prop="printingMethodId">
            <template #label>
              <i style="color: #ff4d4f">*</i>
              Imprint Method：
            </template>
            <el-select
              v-model="product.printingMethodId"
              placeholder="please select"
              @change="printingMethodIdChange"
            >
              <el-option
                v-for="item in printingMethodVOList"
                :key="item.printingMethodId"
                :label="item.printingMethodNameEn"
                :value="item.printingMethodId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="form-box mb20">
          <el-form-item prop="printingPriceId">
            <template #label>
              <i style="color: #ff4d4f">*</i>
              Imprint Color/Stitches：
            </template>
            <el-select
              v-model="product.printingPriceId"
              :placeholder="
                product.printingMethodId != 'Blank' &&
                product.printingMethodId != 'Sample'
                  ? 'please select'
                  : ''
              "
              @change="imprintColorChange"
              :disabled="
                product.printingMethodId != 'Blank' &&
                product.printingMethodId != 'Sample'
                  ? false
                  : true
              "
            >
              <el-option
                v-for="item in printingPriceVOListOptions"
                :key="item.printingPriceId"
                :label="item.printingPriceNameEn"
                :value="item.printingPriceId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col class="form-box mb20" :span="6">
          <el-form-item prop="printingLocationId">
            <template #label>
              <i style="color: #ff4d4f">*</i>
              Imprint Location：
            </template>
            <el-select
              v-model="product.printingLocationId"
              :placeholder="
                product.printingMethodId != 'Blank' &&
                product.printingMethodId != 'Sample'
                  ? 'please select'
                  : ''
              "
              @change="printingLocationIdChange"
              :disabled="
                product.printingMethodId != 'Blank' &&
                product.printingMethodId != 'Sample'
                  ? false
                  : true
              "
            >
              <el-option
                v-for="item in printingLocationVOListOptions"
                :key="item.printingLocationId"
                :label="item.printingLocationName"
                :value="item.printingLocationId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :span="6"
          class="form-box"
          v-if="!productSpecsAddPriceVOListTableData.length"
        >
          <el-form-item prop="quantities">
            <template #label>
              <i style="color: #ff4d4f">*</i>
              Quantity：
            </template>
            <el-input
              style="max-width: 196px"
              v-model="product.quantities"
              placeholder="please input"
              @input="numInteger"
              @blur="onBlurQuantities"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-else style="margin-bottom: 20px">
          <span>
            <i style="color: #ff4d4f">*</i>
            Quantity：
          </span>
          <p class="unit-price">
            The price varies depending on the specifications.
          </p>
          <el-table
            :data="productSpecsAddPriceVOListTableData"
            style="width: 100%"
            :key="tableKey"
            border
          >
            <el-table-column
              align="center"
              :min-width="addPriceCount == 1 ? '160px' : '120px'"
            >
              <template slot="header" v-if="addPriceCount == 1">
                <div>
                  <p>Specification</p>
                  <p>（Upcharge each）</p>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div v-if="scope.row.specsPrice">
                    <p>{{ scope.row.name }}</p>
                    <p>（+ ${{ scope.row.specsPrice }}）</p>
                  </div>
                  <p v-else>{{ scope.row.name }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              v-for="(item, index) in horizontalTableColumn"
              :key="index"
              min-width="100px"
            >
              <template slot="header" slot-scope="scope">
                <div>
                  <div v-if="item.specsPrice">
                    <p>{{ item.specsValue }}</p>
                    <p>（+ ${{ item.specsPrice }}）</p>
                  </div>
                  <p v-else>{{ item.specsValue }}</p>
                </div>
              </template>
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row['quantity' + index]"
                  :placeholder="$t('placeholder.plsInput')"
                  @input="numIntegerMarkup(scope.row, index)"
                  @blur="onBlurQuantity($event, scope.row, 'quantity' + index)"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="reOrder">
            <template #label>Reorder：</template>
            <el-radio-group
              v-model="product.reOrder"
              @change="calculateProductQuotation"
              :disabled="
                product.printingMethodId != 'Blank' &&
                product.printingMethodId != 'Sample'
                  ? false
                  : true
              "
            >
              <el-radio :label="1">Yes</el-radio>
              <el-radio :label="0">No</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <h3 class="product-ricing-tit">Shipping Address</h3>
    <el-row class="product-ricing-search pl40">
      <el-form
        :model="shippingAddress"
        :rules="shippingAddressRules"
        ref="shippingAddressRefs"
        :key="shippingAddressForm"
      >
        <el-col :span="24">
          <el-form-item prop="reOrder">
            <template #label>
              <span style="margin-left: -28px">Shipping Rates：</span>
            </template>
            <el-radio-group
              v-model="shippingAddress.estimateType"
              @change="estimateTypeChange"
            >
              <el-radio :label="1">Estimate by ZIP Code</el-radio>
              <el-radio :label="0">Estimate by detailed address</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="form-box">
          <el-form-item prop="postcode" class="zip-code">
            <template #label>
              <i style="color: #ff4d4f">*</i>
              ZIP Code：
            </template>
            <el-input
              style="max-width: 196px"
              v-model="shippingAddress.postcode"
              placeholder="please input"
              @blur="() => resetQuantityCalculationBlur()"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col
          :span="6"
          class="form-box"
          v-if="shippingAddress.estimateType === 0"
        >
          <el-form-item
            prop="street"
            class="zip-code"
            :rules="[
              {
                required: true,
                message: 'Please Input',
                trigger: 'blur',
              },
            ]"
          >
            <template #label>Address 1：</template>
            <el-input
              style="max-width: 196px"
              v-model="shippingAddress.street"
              placeholder="please input"
              @blur="() => calculateProductQuotation(true)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-else>
          <el-form-item prop="residentialType">
            <template #label>Residential：</template>
            <el-radio-group
              v-model="shippingAddress.residentialType"
              @change="() => calculateProductQuotation(true)"
            >
              <el-radio :label="1">Yes</el-radio>
              <el-radio :label="0">No</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col class="form-box" :span="6">
          <el-form-item prop="country">
            <template #label>Country：</template>
            <el-select
              v-model="shippingAddress.country"
              @change="() => calculateProductQuotation(true)"
            >
              <el-option
                v-for="item in countryOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col class="form-box" :span="6">
          <el-button type="primary" @click="shippingAddressFn">
            Estimate Shipping
          </el-button>
        </el-col> -->
      </el-form>
    </el-row>
    <h3 class="product-ricing-tit">Pricing</h3>
    <el-row class="pl40 pricing">
      <el-col :span="6" class="d-flex">
        <span class="label wid108">Net Price：</span>
        <p>{{ pricing.netPrice | formatNumber }}</p>
      </el-col>
      <el-col :span="6">
        <span class="label">Setup Charge：</span>
        <span>
          {{ pricing.setupCharge | formatNumber }}
        </span>
      </el-col>
      <el-col :span="6">
        <span class="label">Shipping Charge：</span>
        <span>
          {{ pricing.shippingCharge | formatNumber }}
        </span>
      </el-col>
      <el-col :span="6" class="el-dropdown-box">
        <span class="label">Additional Charges：</span>
        <span>
          {{ pricing.additionalCharge | formatNumber }}
        </span>
        <el-dropdown v-if="pricing.additionalCharges.length">
          <i
            class="el-icon-warning-outline"
            style="font-size: 18px; margin-left: 8px; color: #db9200"
          ></i>
          <el-dropdown-menu slot="dropdown" placement="bottom-end">
            <el-dropdown-item
              v-for="(item, index) in pricing.additionalCharges"
              :key="index"
            >
              <p
                style="
                  width: 260px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span style="color: #999">{{ item.specsType }}</span>
                <span>{{ item.charge | formatNumber }}</span>
              </p>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col
        class="unit-price"
        style="padding-left: 34px"
        v-if="pricing.addPrice && pricing.addPrice * 1 !== 0"
      >
        Unit price for add'l imp.：$ {{ pricing.addPrice }} (not included in Net
        Price)
      </el-col>
      <el-col class="grand-total-box">
        <div class="grand-total">
          Grand Total：$
          <span class="num">{{ pricing.totalPrice | formatNumber2 }}</span>
          <span
            v-if="
              (pricing.shippingCharge == '' ||
                pricing.shippingCharge == null) &&
              product.productId && pricing.totalPrice
            "
            style="margin-left: 10px"
          >
            Shipping charging not included
          </span>
        </div>
      </el-col>
      <el-col class="pricing-remark" v-if="infoData.pricingRemark">
        <span class="pricing-remark-tit wid108">Remarks：</span>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 20 }"
          maxlength="500"
          show-word-limit
          :placeholder="$t('placeholder.plsInput')"
          clearable
          v-model="infoData.pricingRemark"
          class="pricing-remark-cont"
          disabled
        ></el-input>
      </el-col>
      <el-col class="grand-total-box" v-if="priceTableData.length">
        <el-table :data="priceTableData" style="width: 100%" border :key="priceTableDataKey">
          <el-table-column
            align="center"
            v-for="(item, index) in priceTableColumn"
            :key="index"
            min-width="100px"
            :prop="item.prop"
          >
            <template slot="header">
              <div>
                {{ index === 0 ? item.label : `${item.label} + ` }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <h3 class="product-ricing-tit">Delivery Time</h3>
    <el-row class="delivery-time pl40">
      <el-col :span="6" class="d-flex">
        <span class="label wid108">Production：</span>
        <p>
          {{
            deliveryTime.productionDay
              ? deliveryTime.productionDay + ' working days'
              : '--'
          }}
        </p>
      </el-col>
      <el-col :span="6" class="d-flex">
        <span class="label wid108">Shipping：</span>
        <span v-if=" deliveryTime.shippingDay &&  deliveryTime.endShippingDay">
          {{ deliveryTime.shippingDay + ' - ' + deliveryTime.endShippingDay + ' working days' + ' (' + deliveryTime.transportTypeEn + ')' }}
        </span>
        <span v-else>
          {{
            deliveryTime.shippingDay
              ? deliveryTime.shippingDay + ' working days' + ' (' + deliveryTime.transportTypeEn + ')'
              : '--'
          }}
        </span>
      </el-col>
      <el-col :span="12" class="img-box">
        <p>
          <span class="label">Estimated Delivery Date：</span>
          <span
            v-if="
              deliveryProductNum &&
              deliveryProductNum !== 'unlimited' &&
              Number(product.quantities) > deliveryProductNum
            "
          >
            TBD
          </span>
          <span v-else>
            <span v-if="deliveryTime.estimatedDelivery && deliveryTime.endEstimatedDelivery">
              {{ deliveryTime.estimatedDelivery + ' - ' + deliveryTime.endEstimatedDelivery}}
            </span>
           <span v-else>
            {{ deliveryTime.estimatedDelivery || '--' }}
           </span>
          </span>
        </p>
        <p class="unit-price">
          <i class="iconfont icon-weixian"></i>
          If payment is completed and the proof is approved within 24 hours.
        </p>
        <div class="img-cont">
          <el-image
            style="width: 100%; height: 100%"
            v-if="infoData.imgMain"
            :src="infoData.imgMain + URL_COMPRESS"
            :preview-src-list="srcList"
          ></el-image>
        </div>
      </el-col>
    </el-row>
    <h3 class="product-ricing-tit">Product Details</h3>
    <el-row class="pl40 product-details">
      <el-col :span="24" class="pricing-remark">
        <span class="label pricing-remark-tit">Item Name：</span>
        <span class="pricing-remark-cont">{{ infoData.nameEn || '--' }}</span>
      </el-col>
      <el-col :span="24" class="pricing-remark mt20">
        <span class="label pricing-remark-tit">Materials：</span>
        <span class="pricing-remark-cont">{{ infoData.material || '--' }}</span>
      </el-col>
      <el-col :span="24" class="pricing-remark mt20">
        <span class="label pricing-remark-tit product-owner-tit">
          Product Owner：
        </span>
        <span class="pricing-remark-cont">
          {{ infoData.belongerEn || '--' }}
        </span>
      </el-col>
      <el-col
        :span="24"
        class="pricing-remark mt20"
        v-for="(item, index) in specsDetailData"
        :key="index"
      >
        <span class="label pricing-remark-tit">{{ item.name }}：</span>
        <span class="pricing-remark-cont">{{ item.value }}</span>
      </el-col>
      <el-col :span="24" class="pricing-remark mt20">
        <span class="label pricing-remark-tit">Description：</span>
        <span class="pricing-remark-cont">
          {{ infoData.descriptionEn || '--' }}
        </span>
      </el-col>
    </el-row>
    <el-row
      class="product-details-size pl40"
      v-if="!infoData.skuSettingForProduct"
    >
      <el-col :span="6" class="mt20 d-flex">
        <span class="label wid108">Packaging：</span>
        <span class="cont">
          {{ infoData.productPricingSpecsVOList[0].packaging || '--' }}
        </span>
      </el-col>
      <el-col :span="6" class="mt20 d-flex">
        <span class="label">Product Weight：</span>
        <span
          class="cont"
          v-if="infoData.productPricingSpecsVOList[0].productWeightLbs"
        >
          {{ infoData.productPricingSpecsVOList[0].productWeightLbs }} LBS
        </span>
        <span class="cont" v-else>--</span>
      </el-col>
      <el-col :span="6" class="mt20 d-flex">
        <span class="label">Product Size：</span>
        <span
          class="cont"
          v-if="
            infoData.productPricingSpecsVOList[0].productLength ||
            infoData.productPricingSpecsVOList[0].productWidth ||
            infoData.productPricingSpecsVOList[0].productHeight
          "
        >
          {{ infoData.productPricingSpecsVOList[0].productLength || '--' }} ×
          {{ infoData.productPricingSpecsVOList[0].productWidth || '--' }} ×
          {{ infoData.productPricingSpecsVOList[0].productHeight || '--' }}
          {{ infoData.productPricingSpecsVOList[0].productUnit || '--' }}
        </span>
        <span class="cont" v-else>--</span>
      </el-col>
      <el-col :span="6" class="mt20 d-flex">
        <span class="label">Imprint Area：</span>
        <span
          class="cont"
          v-if="
            infoData.productPricingSpecsVOList[0].printLength ||
            infoData.productPricingSpecsVOList[0].printWidth
          "
        >
          {{ infoData.productPricingSpecsVOList[0].printLength || '--' }} ×
          {{ infoData.productPricingSpecsVOList[0].printWidth || '--' }}
          {{ infoData.productPricingSpecsVOList[0].printUnit || '--' }}
        </span>
        <span v-else class="cont">--</span>
      </el-col>
      <el-col :span="6" class="mt20 d-flex">
        <span class="label wid108">Units/Ctn：</span>
        <span class="cont">
          {{ infoData.productPricingSpecsVOList[0].numberPerBox || '--' }}
        </span>
      </el-col>
      <el-col :span="6" class="mt20 d-flex">
        <span class="label">Weight/Ctn：</span>
        <span
          class="cont"
          v-if="infoData.productPricingSpecsVOList[0].boxWeightLbs"
        >
          {{ infoData.productPricingSpecsVOList[0].boxWeightLbs }} LBS
        </span>
        <span class="cont" v-else>--</span>
      </el-col>
      <el-col :span="6" class="mt20 d-flex">
        <span class="label">Ctn Dimensions：</span>
        <span
          class="cont"
          v-if="
            infoData.productPricingSpecsVOList[0].boxLengthIn ||
            infoData.productPricingSpecsVOList[0].boxWidthIn ||
            infoData.productPricingSpecsVOList[0].boxHeightIn
          "
        >
          {{ infoData.productPricingSpecsVOList[0].boxLengthIn || '--' }} ×
          {{ infoData.productPricingSpecsVOList[0].boxWidthIn || '--' }} ×
          {{ infoData.productPricingSpecsVOList[0].boxHeightIn || '--' }} IN
        </span>
        <span class="cont" v-else>--</span>
      </el-col>
    </el-row>
    <el-row class="pl40 product-details-table" v-else>
      <el-table
        :data="infoData.productPricingSpecsVOList"
        style="width: 100%"
        border
      >
        <el-table-column
          align="center"
          v-for="(item, index) of specsDetailData"
          :key="index"
          :label="item.name"
          :prop="'specsValue' + (index + 1)"
        ></el-table-column>
        <el-table-column
          prop="packaging"
          label="Packaging"
          align="center"
        ></el-table-column>
        <el-table-column label="Product Weight" align="center">
          <template slot-scope="scope">
            {{
              scope.row.productWeightLbs
                ? scope.row.productWeightLbs + ' LBS'
                : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column label="Product Size" align="center">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.productLength ||
                scope.row.productWidth ||
                scope.row.productHeight
              "
            >
              {{ scope.row.productLength || '--' }} ×
              {{ scope.row.productWidth || '--' }} ×
              {{ scope.row.productHeight || '--' }}
              {{ scope.row.productUnit || '--' }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="Imprint Area" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.printLengt || scope.row.printWidth">
              {{ scope.row.printLength || '--' }} ×
              {{ scope.row.printWidth || '--' }}
              {{ scope.row.printUnit || '--' }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Units/Ctn"
          prop="numberPerBox"
          align="center"
        ></el-table-column>
        <el-table-column label="Weight/Ctn" align="center">
          <template slot-scope="scope">
            {{
              scope.row.boxWeightLbs ? scope.row.boxWeightLbs + ' LBS' : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column label="Ctn Dimensions" align="center">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.boxLengthIn ||
                scope.row.boxWidthIn ||
                scope.row.boxHeightIn
              "
            >
              {{ scope.row.boxLengthIn || '--' }} ×
              {{ scope.row.boxWidthIn || '--' }} ×
              {{ scope.row.boxHeightIn || '--' }} IN
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script>
  import { inquiryInteractor, ProductPricingInteractor } from '@/core'
  import { URL_COMPRESS } from '@/config'
  import { deepCopy } from 'kits'
  export default {
    name: 'productPricing',
    data() {
      let validateZipCode = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please Input'))
        } else {
          const pattern = /^(?:\d{5}|\d{5}-\d{4})$/
          if (!pattern.test(value)) {
            callback(new Error('Invalid ZIP Code'))
          } else { 
            const pattern = /^(006|007|008|009|967|968|995|996|997|998)/;
           const militaryPattern =  /^(969|09[0-9]|96[2-6]|340|96799)/;
            if (pattern.test(value) && value != 96799){
              callback(new Error('Please enter a ZIP code for continental US.'))
            }else if (militaryPattern.test(value)){
              callback(new Error('WARNING! This ZIP code includes military addresses.'))
            }else{
              callback()
            }
          }
        }
      }
      return {
        URL_COMPRESS,
        shippingAddressForm: 1,
        product: {
          productId: '', //Item No
          printingLocationId: '', //  Imprint Location
          printingMethodId: '', //Imprint Method
          printingPriceId: '', // Imprint Color/Stiches
          quantities: '', //Quantity
          quantityDTOList: [], //产品规格数量阶梯
          reOrder: 0, //是否开启返单版费 0-否 1-是
        },
        shippingAddress: {
          estimateType: 1,
          postcode: '',
          street: '',
          residentialType: 0,
          country: 'US',
        },
        pricing: {
          netPrice: '',
          setupCharge: '',
          shippingCharge: '',
          additionalCharge: '',
          totalPrice: '',
          additionalCharges: [],
          addPrice: '0',
        },
        deliveryTime: {
          estimatedDelivery: '', //预计到达时间
          estimatedDeliveryMillis: '', //预计到达时间
          productionDay: '', //生产周期
          shippingDay: '', //运输周期
          transportTypeEn:'',//运输方式
          endShippingDay:'',//结束运输周期
          endEstimatedDelivery:'',//结束预计到达时间
        },
        infoData: {
          pricingRemark: '', //报价备注
          imgMain: '', //主图
          descriptionEn: '',
          nameEn: '',
          specsType: '',
          specsValue: '',
          skuSettingForProduct: 0, //是否开启产品多规格 0-否 1-是
          productPricingSpecsVOList: [{}],
          productSpecsAddPriceVOList: [], //加价信息
          material: '', //材质
          belongerEn: '', //归属人英文名称
        },
        printingMethodVOList: [], //印刷方式
        printingLocationVOListOptions: [], //印刷位置
        printingPriceVOListOptions: [], //计价方式
        itemNoQuery: '',
        itemNoOptions: [],
        itemNoPageInfo: {
          loading: false,
          pageLe: 30,
          pageNo: 1,
          total: 0,
        },
        srcList: [], //预览大图
        specsDetailData: [], //规格详情
        productSpecsAddPriceVOListTableData: [], //规格加价表格数据
        horizontalTableColumn: [], //规格加价纵向表头
        minQuantity: 10, //数量阶梯最小值
        deliveryProductNum: 200, //单订单产品数量
        oldPrintingMethodId: '', //上一次选中的印刷方式
        addPriceCount: 0, //规格加价规格数
        tableKey: 1,
        priceTableDataKey:1,
        auditProcess: 0, //产品审核状态
        mallStatus: 0, //产品上架RP商城状态
        printingPriceLadderVOList: [], //加价信息
        shippingAddressRules: {
          postcode: [{ validator: validateZipCode, trigger: 'blur' }],
        },
        countryOptions: [
          {
            id: 'US',
            name: 'US',
          },
        ],
      }
    },
    computed: {
      hasMore() {
        return (
          Math.ceil(this.itemNoPageInfo.total / this.itemNoPageInfo.pageLe) >
          this.itemNoPageInfo.pageNo
        )
      },
      priceTableData() {
        let obj = {
          tit: 'Net Price each',
        }
        if (this.printingPriceLadderVOList.length) {
          for (let price of this.printingPriceLadderVOList) {
            obj[price.num] = `$ ${price.netPrice}`
          }
          return [obj]
        } else {
          return []
        }
      },
      priceTableColumn() {
        this.priceTableDataKey++
        let arr = [
          {
            label: 'Quantity',
            prop: 'tit',
          },
        ]
        if (this.printingPriceLadderVOList.length) {
          for (let price of this.printingPriceLadderVOList) {
            arr.push({
              label: price.num,
              prop: price.num.toString(),
            })
          }
          return arr
        } else {
          return []
        }
      },
    },
    filters: {
      formatNumber(value) {
        if (value == '' || value == null) return '--'
        return '$ ' + String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      formatNumber2(value) {
        if (value == '' || value == null) return '--'
        return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    },
    methods: {
      estimateTypeChange() {
        this.shippingAddressForm++
        this.calculateProductQuotation(true)
        setTimeout(()=>{
          this.$refs.shippingAddressRefs.validateField('postcode')
        })
      },
      // 重新计算价格和交期
      resetQuantityCalculationBlur() {
        this.calculateDeliveryDate()
        this.calculateProductQuotation(true)
      },
      // 获取详情
      async getDetails(id) {
        let bol = false

        let res = await ProductPricingInteractor.getDetailApi({
          productId: id,
        })
        if (res?.code === '000000' && res?.data) {
          bol = true
          this.horizontalTableColumn = []
          for (let k in this.infoData) {
            if (k === 'productSpecsAddPriceVOList') {
              this.infoData[k] = res.data[k] ?? []
            } else if (k === 'productPricingSpecsVOList') {
              this.infoData[k] = res.data[k] ?? [{}]
            } else {
              this.infoData[k] = res.data[k] ?? ''
            }
          }

          this.addPriceCount = res.data.addPriceCount
          this.minQuantity = res.data.minQuantity
          this.srcList = [this.infoData.imgMain]
          this.specsDetailData = this.specsDetails()
          this.productSpecsAddPriceVOListTableData =
            this.getProductSpecsAddPriceVOListFn()
          this.printingMethodVOList = res.data?.printingMethodVOList ?? []
          if (
            !res.data.productSpecsAddPriceVOList ||
            !res.data.productSpecsAddPriceVOList.length
          ) {
            this.product.quantities = this.minQuantity
          } else {
            this.productSpecsAddPriceVOListTableData[0]['quantity0'] =
              this.minQuantity
          }
        } else {
          bol = false
          this.product = {
            productId: '', //Item No
            printingLocationId: '', //  Imprint Location
            printingMethodId: '', //Imprint Method
            printingPriceId: '', // Imprint Color/Stiches
            quantities: '', //Quantity
            quantityDTOList: [], //产品规格数量阶梯
            reOrder: 0, //是否开启返单版费 0-否 1-是
          }
          this.infoData = {
            pricingRemark: '', //报价备注
            imgMain: '', //主图
            descriptionEn: '',
            nameEn: '',
            specsType: '',
            specsValue: '',
            skuSettingForProduct: 0, //是否开启产品多规格 0-否 1-是
            productPricingSpecsVOList: [{}],
            productSpecsAddPriceVOList: [], //加价信息
            material: '', //材质
            belongerEn: '', //归属人英文名称
          }
          this.printingMethodVOList = [] //印刷方式
          this.printingLocationVOListOptions = [] //印刷位置
          this.printingPriceVOListOptions = [] //计价方式
          this.specsDetailData = []
          this.productSpecsAddPriceVOListTableData = []
          this.resetQuantityCalculation()
          this.resetDeliveryDate()
        }
        this.tableKey++
        return bol
      },
      // 计算产品报价
      async calculateProductQuotation(bol = false) {
        if (bol) {
          this.$refs.shippingAddressRefs.validate(async (valid) => {
            if (valid) {
              let params = Object.assign(this.product, this.shippingAddress)
              params.auditProcess = this.auditProcess
              params.mallStatus = this.mallStatus
              let res = await ProductPricingInteractor.priceApi(params)
              if (res?.code === '000000' && res?.data) {
                for (let k in res.data) {
                  this.pricing[k] = res.data?.[k] ?? ''
                }
              } else {
                this.resetQuantityCalculation()
              }
            } else {
              this.pricing.shippingCharge = ''
              this.pricing.totalPrice = ''
              return false
            }
          })
        } else {
          let params = Object.assign(this.product, this.shippingAddress)
          params.auditProcess = this.auditProcess
          params.mallStatus = this.mallStatus
          const pattern = /^(006|007|008|009|967|968|995|996|997|998|969|09[0-9]|06[2-6]|340|96799)/;
          if(params.postcode && pattern.test(params.postcode)){
            params.postcode = ''
          }
          let res = await ProductPricingInteractor.priceApi(params)
          if (res?.code === '000000' && res?.data) {
            for (let k in res.data) {
              this.pricing[k] = res.data?.[k] ?? ''
            }
          } else {
            this.resetQuantityCalculation()
          }
        }
      },
      // 计算交付周期
      async calculateDeliveryDate() {
        let params = JSON.parse(JSON.stringify(this.product))
        params.auditProcess = this.auditProcess
        params.mallStatus = this.mallStatus
        params.postcode = this.shippingAddress.postcode
        let res = await ProductPricingInteractor.deliveryApi(params)
        if (res?.code === '000000' && res?.data) {
          for (let k in res.data) {
            this.deliveryTime[k] = res.data[k]
          }
        } else {
          this.resetDeliveryDate()
        }
      },
      // 获取规格
      specsDetails() {
        let specsType = this.infoData.specsType
          ? this.infoData.specsType.split(',')
          : []

        let specsValue = this.infoData.specsValue
        ? JSON.parse(this.infoData.specsValue)
        : []


        let arr = []
        if (
          specsType.length &&
          specsValue.length &&
          specsType.length === specsValue.length
        ) {
          for (let k in specsType) {
            arr.push({
              name: specsType[k],
              value: specsValue[k]?.join(', ') ?? '',
            })
          }
        }
        return arr
      },
      // 获取规格加价
      getProductSpecsAddPriceVOListFn() {
        if (this.infoData.productSpecsAddPriceVOList.length) {
          let specsType = this.infoData.specsType
            ? this.infoData.specsType.split(',')
            : []
          if (this.addPriceCount == 1) {
            let arr = this.infoData.productSpecsAddPriceVOList
            let obj = arr.reduce(
              (previousValue, currentValue, currentIndex) => {
                previousValue[`productSpecsAddPriceId${currentIndex}`] =
                  currentValue.productSpecsAddPriceId
                previousValue[`quantity${currentIndex}`] = 0
                this.horizontalTableColumn.push({
                  specsValue: currentValue.specsValue,
                  specsPrice: currentValue.specsPrice,
                })
                return previousValue
              },
              {}
            )
            obj.name = 'Quantity'
            obj.nameId = ''
            return [obj]
          } else if (this.addPriceCount == 2) {
            let portraitArr = [] //纵向
            let horizontalArr = [] //横向
            let newDataArr = []
            portraitArr = this.infoData.productSpecsAddPriceVOList.filter(
              (item) => item.specsName === specsType[0]
            )
            horizontalArr = this.infoData.productSpecsAddPriceVOList.filter(
              (item) => item.specsName === specsType[1]
            )
            let horizontalObj = horizontalArr.reduce(
              (previousValue, currentValue, currentIndex) => {
                previousValue[`productSpecsAddPriceId${currentIndex}`] =
                  currentValue.productSpecsAddPriceId
                previousValue[`quantity${currentIndex}`] = 0
                this.horizontalTableColumn.push({
                  specsValue: currentValue.specsValue,
                  specsPrice: currentValue.specsPrice,
                })
                return previousValue
              },
              {}
            )
            for (let k of portraitArr) {
              newDataArr.push({
                ...horizontalObj,
                name: k.specsValue,
                nameId: k.productSpecsAddPriceId,
                specsPrice: k.specsPrice,
              })
            }
            return newDataArr
          } else {
            return []
          }
        } else {
          return []
        }
      },
      // 数量提示规则
      quantityReminderRules(total) {
        if (!total) {
          this.$baseMessage(
            'The quantity cannot be empty.',
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        }
        if (this.product.printingMethodId === 'Sample') {
          return true
        }
        if (Number(total) < this.minQuantity) {
          let masg = `Quantity should not be less than ${this.minQuantity}`
          this.$baseMessage(masg, 'warning', false, 'erp-hey-message-warning')
          return false
        }
        if (
          this.deliveryProductNum &&
          this.deliveryProductNum !== 'unlimited' &&
          Number(total) > this.deliveryProductNum
        ) {
          this.$baseMessage(
            'Please contact sales for a quote.',
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        }
        return true
      },
      // 重置数量计算为空
      resetQuantityCalculation() {
        for (let k in this.pricing) {
          if (k === 'additionalCharges') {
            this.pricing[k] = []
          } else {
            this.pricing[k] = ''
          }
        }
        for (let k in this.pricing) {
          if (k === 'shippingAddress') {
            this.shippingAddress[k] = ''
          }
        }
      },
      // 重置交付日期为空
      resetDeliveryDate() {
        for (let k in this.deliveryTime) this.deliveryTime[k] = ''
      },

      // 输入数量是否有效
      getDataFn() {
        if (this.productSpecsAddPriceVOListTableData.length) {
          // 有规格加价
          let tableData = this.setTableData()
          let total = tableData.reduce((previousValue, currentValue) => {
            previousValue += Number(currentValue.quantities)
            return previousValue
          }, 0)
          this.product.quantities = total
          if (this.quantityReminderRules(total)) {
            this.product.quantityDTOList = tableData
            this.calculateProductQuotation()
            this.calculateDeliveryDate()
          } else {
            this.resetQuantityCalculation()
            this.resetDeliveryDate()
          }
        } else {
          // 无规格加价
          if (this.quantityReminderRules(this.product.quantities)) {
            this.calculateProductQuotation()
            this.calculateDeliveryDate()
          } else {
            this.resetQuantityCalculation()
            this.resetDeliveryDate()
          }
        }
      },

      // 无规格加价失去焦点
      onBlurQuantities() {
        // product.quantities
        this.getDataFn()
      },
      // 有规格加价数量失去焦点
      onBlurQuantity(e, row, key) {
        if (this.product.printingMethodId === 'Sample') {
          for (let quantity in this.productSpecsAddPriceVOListTableData) {
            for (let k in this.horizontalTableColumn) {
              if (
                !(
                  this.productSpecsAddPriceVOListTableData[quantity].nameId ===
                    row.nameId && `quantity${k}` === key
                )
              ) {
                this.productSpecsAddPriceVOListTableData[quantity][
                  `quantity${k}`
                ] = 0
              }
            }
          }
        }
        this.getDataFn()
      },
      // 获取规格加价提交表格数据
      setTableData() {
        let tableData = deepCopy(this.productSpecsAddPriceVOListTableData)
        let paramsTableData = tableData.reduce(
          (previousValue, currentValue) => {
            let arr = []
            for (let k in this.horizontalTableColumn) {
              arr.push({
                productSpecsAddPriceId: currentValue.nameId
                  ? `${currentValue.nameId},${
                      currentValue[`productSpecsAddPriceId${k}`]
                    }`
                  : currentValue[`productSpecsAddPriceId${k}`],
                quantities: currentValue[`quantity${k}`],
              })
            }
            previousValue.push(arr)
            return previousValue
          },
          []
        )
        paramsTableData = paramsTableData
          .flat()
          .filter((item) => item.quantities && item.quantities !== '0')
        return paramsTableData
      },
      // 产品下拉加载更多
      itemNoLoadMore() {
        if (!this.hasMore) return
        this.itemNoPageInfo.pageNo++
        this._getOptions(true)
      },
      // 产品输入框失去焦点
      itemNoBlur(val) {
        if (!val) {
          this.$refs.itemNoRef.blur()
        }
      },
      // 搜索产品
      itemNoMethod(query) {
        this.itemNoQuery = query
        this._getOptions(false)
      },
      // 重置产品搜索
      _reset() {
        this.itemNoPageInfo.pageNo = 1
        this.itemNoPageInfo.total = 0
        this.itemNoOptions = []
      },
      // 获取产品下拉框
      async _getOptions(loadMore) {
        this.itemNoPageInfo.loading = true
        if (!loadMore) {
          this._reset()
        }
        let res = await inquiryInteractor.listEnquiryProductByParam(
          this.itemNoQuery,
          this.itemNoPageInfo.pageLe,
          this.itemNoPageInfo.pageNo,
          1
        )
        if (res?.code === '000000' && res?.data?.data) {
          this.itemNoOptions = loadMore
            ? [...this.itemNoOptions, ...res.data.data]
            : res.data.data
          this.itemNoPageInfo.total = res?.data?.total ?? 0
        } else {
          this.itemNoOptions = []
          this.itemNoPageInfo.total = 0
        }
        this.itemNoPageInfo.loading = false
      },
      // 按Enter
      itemNoEnterFn() {
        if (this.itemNoOptions.length) {
          let product = this.itemNoOptions[0]
          this.product.productId = product.productId
          this.itemNoChange(product.productId)
        }
        this.$refs.itemNoRef.blur()
      },
      // 选中产品
      async itemNoChange(val) {
        if (!val) return
        let currProduct =
          this.itemNoOptions?.find((item) => item.productId === val) ?? {}
        this.auditProcess = currProduct.auditProcess
        this.mallStatus = currProduct.mallStatus
        let bol = await this.getDetails(val)
        if (!bol) return
        this.oldPrintingMethodId = ''
        //切换产品默认取第一个值
        this.printingPriceLadderVOList =
          this.printingMethodVOList?.[0]?.printingPriceLadderVOList ?? []

        this.product.printingMethodId =
          this.printingMethodVOList?.[0]?.printingMethodId ?? ''
        this.deliveryProductNum =
          this.printingMethodVOList?.[0]?.deliveryProductNum ?? 'unlimited'
        this.printingLocationVOListOptions =
          this.printingMethodVOList?.[0]?.printingLocationVOList ?? []
        this.product.printingLocationId =
          this.printingLocationVOListOptions?.[0]?.printingLocationId ?? ''

        this.printingPriceVOListOptions =
          this.printingLocationVOListOptions?.[0]?.printingPriceVOList ?? []
        this.product.printingPriceId =
          this.printingPriceVOListOptions?.[0]?.printingPriceId ?? ''
        this.product.quantityDTOList = []
        this.product.quantities = this.minQuantity
        this.product.reOrder = 0
        if (this.productSpecsAddPriceVOListTableData.length) {
          this.product.quantityDTOList = this.setTableData()
          this.product.quantities = this.product.quantityDTOList.reduce(
            (previousValue, currentValue) => {
              previousValue += Number(currentValue.quantities)
              return previousValue
            },
            0
          )
        }

        if (this.product.printingMethodId === 'Sample') {
          if (!this.productSpecsAddPriceVOListTableData.length) {
            this.product.quantities = 1
          } else {
            for (let quantity in this.productSpecsAddPriceVOListTableData) {
              for (let k in this.horizontalTableColumn) {
                if (quantity == 0 && k == 0) {
                  this.productSpecsAddPriceVOListTableData[quantity][
                    `quantity${k}`
                  ] = 1
                } else {
                  this.productSpecsAddPriceVOListTableData[quantity][
                    `quantity${k}`
                  ] = 0
                }
              }
            }
          }
        }
        if (bol) this.getDataFn()
      },
      // 切换印刷方式
      printingMethodIdChange(val) {
        let obj = this.printingMethodVOList.find(
          (item) => item.printingMethodId === val
        )
        this.printingPriceLadderVOList = obj?.printingPriceLadderVOList ?? []
        this.deliveryProductNum = obj?.deliveryProductNum ?? 'unlimited'
        this.printingLocationVOListOptions = obj?.printingLocationVOList ?? []
        this.product.printingLocationId =
          this.printingLocationVOListOptions?.[0]?.printingLocationId ?? ''

        this.printingPriceVOListOptions =
          this.printingLocationVOListOptions?.[0]?.printingPriceVOList ?? []
        this.product.printingPriceId =
          this.printingPriceVOListOptions?.[0]?.printingPriceId ?? ''
        if (val === 'Sample') {
          if (!this.productSpecsAddPriceVOListTableData.length) {
            this.product.quantities = 1
          } else {
            for (let quantity in this.productSpecsAddPriceVOListTableData) {
              for (let k in this.horizontalTableColumn) {
                if (quantity == 0 && k == 0) {
                  this.productSpecsAddPriceVOListTableData[quantity][
                    `quantity${k}`
                  ] = 1
                } else {
                  this.productSpecsAddPriceVOListTableData[quantity][
                    `quantity${k}`
                  ] = 0
                }
              }
            }
          }
        }
        if (this.oldPrintingMethodId === 'Sample') {
          if (!this.productSpecsAddPriceVOListTableData.length) {
            this.product.quantities = this.minQuantity
          } else {
            for (let quantity in this.productSpecsAddPriceVOListTableData) {
              for (let k in this.horizontalTableColumn) {
                if (quantity == 0 && k == 0) {
                  this.productSpecsAddPriceVOListTableData[quantity][
                    `quantity${k}`
                  ] = this.minQuantity
                } else {
                  this.productSpecsAddPriceVOListTableData[quantity][
                    `quantity${k}`
                  ] = 0
                }
              }
            }
          }
        }
        if (val === 'Blank' || val === 'Sample') {
          this.product.reOrder = 0
        }
        this.getDataFn()
        this.oldPrintingMethodId = val
      },
      // 切换印刷位置
      printingLocationIdChange(val) {
        let obj = this.printingLocationVOListOptions.find(
          (item) => item.printingLocationId === val
        )
        let oldPrintingPriceId = deepCopy(this.product.printingPriceId)
        let newPrintingPriceVOList = obj.printingPriceVOList
        let oldPrintingPriceName =
          this.printingPriceVOListOptions.find(
            (item) => item.printingPriceId === oldPrintingPriceId
          )?.printingPriceNameEn ?? ''
        if (oldPrintingPriceName) {
          this.product.printingPriceId =
            newPrintingPriceVOList.find(
              (item) => item.printingPriceNameEn === oldPrintingPriceName
            )?.printingPriceId ?? ''
        }
        this.printingPriceVOListOptions = newPrintingPriceVOList
        this.getDataFn()
      },
      imprintColorChange() {
        this.getDataFn()
      },
      // 规格加价输入框只能输入整数
      numIntegerMarkup(row, index) {
        row[`quantity${index}`] = row[`quantity${index}`].replace(/[^\d]/g, '')
        if (row[`quantity${index}`].length > 8) {
          row[`quantity${index}`] = row[`quantity${index}`].slice(0, 8)
        }
        if (
          this.product.printingMethodId === 'Sample' &&
          Number(row[`quantity${index}`]) > 1
        ) {
          row[`quantity${index}`] = 1
        }
      },
      numInteger() {
        this.product.quantities = this.product.quantities.replace(/[^\d]/g, '')
        if (this.product.quantities.length > 8) {
          this.product.quantities = this.product.quantities.slice(0, 8)
        }

        if (
          this.product.printingMethodId === 'Sample' &&
          Number(this.product.quantities) > 1
        ) {
          this.product.quantities = 1
        }
      },
    },
  }
</script>
<style scope lang="scss">
  .product-ricing {
    height: calc(100vh - 65px);
    box-sizing: border-box;
    padding: 20px 40px;
    overflow-y: auto;
    .form-box {
      .el-form-item {
        .el-input {
          width: 140px !important;
        }
      }
    }
    .d-flex {
      display: flex;
    }
    .pl40 {
      box-sizing: border-box;
      padding-left: 40px;
    }
    .label {
      color: #999 !important;
    }
    .mb20 {
      margin-bottom: 20px;
    }
    .unit-price {
      font-size: 14px;
      color: #db9200;
      line-height: 28px;
      display: flex;
      align-items: center;
    }
    .product-ricing-tit {
      padding: 18px 0;
    }
    .product-ricing-search {
      .removed {
        position: relative;
        &::after {
          content: 'Removed from online store.';
          font-size: 14px;
          color: #db9200;
          position: absolute;
          left: 90px;
          top: 40px;
        }
        .el-input__inner {
          border-color: #db9200 !important;
        }
      }
    }
    .pricing-remark {
      display: flex;
      margin-bottom: 20px;
      .pricing-remark-tit {
        width: 108px !important;
        flex-shrink: 1;
        color: rgba(217, 0, 27, 0.6);
        &.product-owner-tit {
          width: 140px !important;
          margin-left: -32px;
        }
      }
      .pricing-remark-cont {
        flex: 1;
        .el-textarea__inner {
          background: #fff !important;
          color: #515a6e;
        }
      }
    }
    .pricing {
      .grand-total-box {
        padding-left: 34px;
        .grand-total {
          height: 70px;
          border: 1px solid #db9200;
          border-radius: 4px;
          text-align: center;
          line-height: 70px;
          background: rgba(219, 146, 0, 0.05);
          margin: 10px 0 20px;
          .num {
            font-size: 24px;
            color: 3000;
            font-weight: 700;
          }
        }
      }
    }
    .delivery-time {
      .img-box {
        position: relative;
        padding-right: 200px;
        .img-cont {
          position: absolute;
          right: 0;
          top: -40px;
          width: 180px;
          height: 180px;
          // background: #f3f3f3;
          padding: 10px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .product-details {
      .label {
        width: 88px;
        text-align: right;
      }
    }
    .product-details-size {
      .label {
        display: inline-block;
        width: 120px;
        text-align: right;
      }
    }
    .product-details-table {
      margin: 20px 0;
    }
    .el-dropdown-box {
      display: flex;
      .el-dropdown {
        height: 20px;
      }
    }
    .wid108 {
      width: 108px !important;
      display: inline-block;
      text-align: right;
    }
    .zip-code {
      .el-form-item__label {
        margin-left: -3px;
      }
      .el-form-item__content {
        .el-form-item__error {
          margin-left: 100px;
        }
      }
    }
  }
</style>
<style>
  .el-form-item__content .itemNo-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .itemNo-select .el-select-dropdown__list {
    max-height: 300px !important;
    overflow: auto;
    overflow-x: hidden;
  }
</style>
