var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[(_vm.detail)?_c('div',{staticClass:"mail-detail"},[_c('div',{staticClass:"mail-detail-base"},[_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"mail-main-title"},[_c('span',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.detail_title)}}),(_vm.detail.baseInfo && _vm.detail.baseInfo.businessJson)?_c('span',{staticClass:"order-num"},[_vm._v(" "+_vm._s(_vm.$t('other.inquiryNumber'))+" "+_vm._s(_vm.detail.baseInfo.businessJson)+" ")]):_vm._e(),_vm._l((_vm.detail.mailLabelConfigVOS),function(label){return _c('div',{key:label.mailLabelConfigId,staticClass:"label-item",style:({ 'background-color': label.labelColor })},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(label.labelName))])])})],2)]),_c('div',{staticClass:"info-wrapper"},[(_vm.infoDetailed)?_c('div',{staticClass:"info-main"},[_c('dl',{staticClass:"base-item"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t('myMail.Sender'))+" ")]),(_vm.detail.mailHeader && _vm.detail.mailHeader.originFrom)?_c('dd',[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(_vm.detail.mailHeader.originFrom))])]):_vm._e(),(
                !_vm.detail.mailHeader &&
                _vm.detail.baseInfo &&
                _vm.detail.baseInfo.sendEmail
              )?_c('dd',[_c('span',{staticClass:"weight"},[_vm._v(" "+_vm._s(_vm.detail.baseInfo.sendEmail.split('@')[0])+" ")]),_c('span',{domProps:{"textContent":_vm._s(("<" + (_vm.detail.baseInfo.sendEmail) + ">"))}})]):_vm._e()]),_c('dl',{staticClass:"base-item"},[_c('dt',[_vm._v(_vm._s(_vm.$t('myMail.Time')))]),_c('dd',[(_vm.lang != 'en')?_c('span',{staticClass:"weight"},[_vm._v(" "+_vm._s(_vm.detail.baseInfo.timingSwitch ? _vm.utils.formatTime( _vm.detail.baseInfo.timingSendTime, 'mail-info' ) : _vm.utils.formatTime(_vm.detail.baseInfo.sendTime, 'mail-info'))+" ")]):_c('span',{staticClass:"weight"},[_vm._v(" "+_vm._s(_vm.detail.baseInfo.timingSwitch ? _vm.utils.formatTimeToEnglishDate( _vm.detail.baseInfo.timingSendTime ) : _vm.utils.formatTimeToEnglishDate(_vm.detail.baseInfo.sendTime))+" ")])])]),_c('dl',{staticClass:"base-item"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t('myMail.Recipient'))+" ")]),(
                !_vm.detail.mailHeader &&
                _vm.detail.extendInfo &&
                _vm.detail.extendInfo.receiveEmails
              )?_c('dd',_vm._l((_vm.detail.extendInfo.receiveEmails.split(
                  ','
                )),function(v,index){return _c('span',{key:index},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(v.split('@')[0]))]),_c('span',{domProps:{"textContent":_vm._s(("<" + v + ">;"))}})])}),0):_vm._e(),(_vm.detail.mailHeader && _vm.detail.mailHeader.originTo)?_c('dd',_vm._l((_vm.detail.mailHeader.originTo.split(',')),function(v,index){return _c('span',{key:index},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(v + ';'))])])}),0):_vm._e()]),(
              _vm.detail.extendInfo &&
              _vm.detail.extendInfo.ccEamils &&
              !('mailHeader' in _vm.detail)
            )?_c('dl',{staticClass:"base-item"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t('other.CCperson'))+" ")]),(_vm.detail.mailHeader)?_c('dd',[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(_vm.detail.mailHeader.originCc))])]):_vm._e(),(
                !_vm.detail.mailHeader &&
                _vm.detail.extendInfo &&
                _vm.detail.extendInfo.ccEamils
              )?_c('dd',_vm._l((_vm.detail.extendInfo.ccEamils.split(',')),function(v,index){return _c('span',{key:index},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(v.split('@')[0]))]),_c('span',{domProps:{"textContent":_vm._s(("<" + v + ">;"))}})])}),0):_vm._e()]):_vm._e(),(
              'mailHeader' in _vm.detail &&
              _vm.detail.mailHeader &&
              _vm.detail.mailHeader.originCc
            )?_c('dl',{staticClass:"base-item"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t('other.CCperson'))+" ")]),_c('dd',_vm._l((_vm.detail.mailHeader.originCc.split(',')),function(v,index){return _c('span',{key:index},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(v + ';'))])])}),0)]):_vm._e(),(
              _vm.detail.extendInfo &&
              _vm.detail.extendInfo.ccEamils &&
              !('mailHeader' in _vm.detail)
            )?_c('dl',{staticClass:"base-item"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t('other.CC'))+" ")]),_c('dd',_vm._l((_vm.detail.extendInfo.bccEmails.split(',')),function(v,index){return _c('span',{key:index},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(v.split('@')[0]))]),_c('span',{domProps:{"textContent":_vm._s(("<" + v + ">;"))}})])}),0),(_vm.detail.mailHeader && _vm.detail.mailHeader.originBcc)?_c('dd',[_c('span',{domProps:{"textContent":_vm._s(("<" + (_vm.detail.mailHeader.originBcc) + ">;"))}})]):_vm._e()]):_vm._e(),(
              'mailHeader' in _vm.detail &&
              _vm.detail.mailHeader &&
              _vm.detail.mailHeader.originBcc
            )?_c('dl',{staticClass:"base-item"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t('other.CC'))+" ")]),_c('dd',_vm._l((_vm.detail.mailHeader.originBcc.split(',')),function(v,index){return _c('span',{key:index},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(v + ';'))])])}),0)]):_vm._e()]):_c('div',{staticClass:"info-main"},[_c('div',{staticClass:"info-simple"},[(_vm.detail.mailHeader && _vm.detail.mailHeader.originFrom)?_c('span',[_vm._v(" "+_vm._s(_vm.detail.mailHeader.originFrom)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.detail.baseInfo && _vm.detail.baseInfo.sendEmail ? _vm.detail.baseInfo.sendEmail.split('@')[0] : '')+" "+_vm._s(_vm.detail.baseInfo && _vm.detail.baseInfo.sendEmail ? '<' + _vm.detail.baseInfo.sendEmail + '>' : '')+" ")]),(_vm.detail.baseInfo)?_c('span',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.$t('other.At'))+" "),(_vm.lang != 'en')?_c('span',[_vm._v(" "+_vm._s(_vm.detail.baseInfo.timingSwitch ? _vm.utils.formatTime( _vm.detail.baseInfo.timingSendTime, 'mail-info' ) : _vm.utils.formatTime(_vm.detail.baseInfo.sendTime, 'mail-info'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.detail.baseInfo.timingSwitch ? _vm.utils.formatTimeToEnglishDate( _vm.detail.baseInfo.timingSendTime ) : _vm.utils.formatTimeToEnglishDate(_vm.detail.baseInfo.sendTime))+" ")]),_vm._v(" "+_vm._s(_vm.$t('other.sendto'))+" ")]):_vm._e(),(_vm.detail.mailHeader && _vm.detail.mailHeader.originTo)?_vm._l((_vm.detail.mailHeader.originTo.split(',')),function(v,index){return _c('span',{key:index},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(v + ';'))])])}):_vm._e(),(
                !_vm.detail.mailHeader &&
                _vm.detail.extendInfo &&
                _vm.detail.extendInfo.receiveEmails
              )?_vm._l((_vm.detail.extendInfo.receiveEmails.split(
                  ','
                )),function(v,index){return _c('span',{key:index},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(v.split('@')[0]))]),_c('span',{domProps:{"textContent":_vm._s(("<" + v + ">;"))}})])}):_vm._e()],2)]),(_vm.detail.baseInfo)?_c('span',{staticClass:"btn-key",on:{"click":function($event){_vm.infoDetailed = !_vm.infoDetailed}}},[_vm._v(" "+_vm._s(_vm.infoDetailed ? _vm.$t('other.Condensedinformation') : _vm.$t('other.details'))+" ")]):_vm._e()]),(_vm.remarkFlag)?_c('div',{staticClass:"myremark"},[(!_vm.remarkEditFlag)?_c('div',{staticClass:"remark-text"},[_c('span',{staticClass:"weight"},[_vm._v(_vm._s(_vm.detail.baseInfo.emailMemo))])]):_vm._e()]):_vm._e()]),_c('iframe',{staticClass:"mail-iframe",staticStyle:{"border":"none"},attrs:{"src":"./mailinfo.html"}}),_c('div',{staticClass:"mail-deatil-bottom"},[_c('MailFiles',{ref:"mailFiles",attrs:{"show-close":false}})],1)]):_c('div',{staticClass:"no-mail"},[_c('div',[_c('img',{attrs:{"src":require("../../../../assets/mail_images/nomail.png"),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('other.Mailnotselected')))])])]),_c('AssignmentAndTransfer',{ref:"assignmentAndTransfer",attrs:{"assignmentAndTransferBol":_vm.assignmentAndTransferBol,"mailIdArr":_vm.mailIdArr,"title":_vm.$t('mailConfig.assign'),"mailServer":_vm.mailServer},on:{"update:assignmentAndTransferBol":function($event){_vm.assignmentAndTransferBol=$event},"update:assignment-and-transfer-bol":function($event){_vm.assignmentAndTransferBol=$event},"saveFn":_vm.saveFn}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }