<template>
  <div class="template-config">
    <el-row>
      <el-page-header
        @back="goBack"
        :content="content"
        title="返回"
      ></el-page-header>
    </el-row>
    <el-row>
      <el-col :span="2">
        <span class="title">角色模板配置</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20" :offset="2">
        <div class="template-table">
          <el-form>
            <el-table :data="tableData" style="width: 100%" border>
              <el-table-column align="center">
                <template slot="header">
                  <span>地区</span>
                  <span style="color: red">*</span>
                </template>
                <template slot-scope="scope">
                  <!-- <el-form-item
                    :prop="'tableData.' + [scope.$index] + '.area'"
                    :rules="[
                      {
                        required: true,
                        message: '请选择地区',
                        trigger: 'change',
                      },
                    ]"
                  > -->
                  <el-select v-model="scope.row.area" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in areaList"
                      :key="index"
                      :label="item.areaName"
                      :value="item.areaName"
                    ></el-option>
                  </el-select>
                  <!-- </el-form-item> -->
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <span>产品类型</span>
                  <span style="color: red">*</span>
                </template>
                <template slot-scope="scope">
                  <!-- <el-form-item
                    :prop="
                      'tableData.' + [scope.$index] + '.standardCustomized'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请选择产品类型',
                        trigger: 'change',
                      },
                    ]"
                  > -->
                  <el-select
                    v-model="scope.row.standardCustomized"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in productTypeOption"
                      :key="item.code"
                      :label="item.name"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                  <!-- </el-form-item> -->
                </template>
              </el-table-column>

              <el-table-column
                v-for="item in roleList"
                :key="item.code"
                :label="item.message"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input
                    placeholder="请选择"
                    clearable
                    v-model="scope.row[item.code]"
                    @focus="showConfig(scope, scope.row)"
                    @clear="clearChoosed(scope, scope.row)"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="deleteItem(scope.$index, scope.row)"
                    size="medium"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <div class="addTemplate">
      <el-button type="text" @click="add" size="medium">新增一行</el-button>
    </div>

    <div class="addTemplate stick30" style="margin-top: 60px">
      <el-button @click="goBack">返回</el-button>
      <el-button type="primary" style="margin-left: 20px" @click="save">
        保存
      </el-button>
    </div>

    <!-- 条件提成点 -->
    <condition-appoint
      :appointVisible.sync="appointVisible"
      @setRow="setRow"
    ></condition-appoint>
  </div>
</template>
<script>
import conditionAppoint from './components/condition-appoint'
import {
  SettleConfigInteractor,
  FinanceThemeSetting,
} from '@/core/interactors/finance/config-manage'
import { productType, areas } from '@/views/finance-center/config-manage/helper'
import { mapGetters } from 'vuex'
import mixins from './mixins'

export default {
  components: { conditionAppoint },
  mixins: [mixins],
  data() {
    return {
      productTypeOption: [
        {
          name: '订制品',
          code: '订制品',
        },
        {
          name: '标准品',
          code: '标准品',
        },
        {
          name: '辅料',
          code: '辅料',
        },
      ],
      tableData: [],
      appointVisible: false,
      roleList: [], // 角色清单
      currentIndex: 0,
      currentCode: '',
      operTable: [], // 保存用的table
      configColumnRow: {}, 
      content: '结算人员配置模板新增',
      businessIds: '', // 编辑用到的id
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    this.businessIds = this.$route.query.businessIds
      ? this.$route.query.businessIds
      : ''

    this.getRoles()

    this.getArea()
  },
  methods: {
    //角色 选择模板
    showConfig(scope, row) {
      this.currentIndex = scope.$index
      this.currentCode = this.getRoleCode(scope.column.label)
      this.appointVisible = true
    },
    // 删除某一项  同时将configCommissionRoleDTOs 对应的项删除
    clearChoosed(scope, row) {
      this.tableData[scope.$index].configCommissionRoleDTOs.forEach(
        (item, index) => {
          if (item.roleType === this.getRoleCode(scope.column.label)) {
            this.tableData[scope.$index].configCommissionRoleDTOs.splice(
              index,
              1
            )
            this.operTable[scope.$index].configCommissionRoleDTOs.splice(
              index,
              1
            )
          }
        }
      )
    },
    // 新增一条数据
    add() {
      let obj = {
        area: '',
        standardCustomized: '',
        configCommissionRoleDTOs: [],
      }
      this.tableData.push(obj)
      this.operTable.push(obj)
    },
    // 删除一条数据
    deleteItem(index, row) {
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        this.tableData.splice(index, 1)
        this.operTable.splice(index, 1)
      })
    },
    // 判断列表内是否未空  true 不是空数组，false是空数组
    checkTableEmpty() {
      for (let index = 0; index < this.operTable.length; index++) {
        if (
          this.operTable[index].area ||
          this.operTable[index].standardCustomized ||
          this.operTable[index].configCommissionRoleDTOs.length > 0
        ) {
          return true
        }
      }
    },
    // 返回
    goBack() {
      if (this.checkTableEmpty()) {
        this.$confirm('数据未保存，是否返回？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          this.$router.push({
            path: '/finance-center/config-manage',
            query: {
              tab: 2,
              configTab: 'template',
            },
          })
        })
      } else {
        this.$router.push({
          path: '/finance-center/config-manage',
          query: {
            tab: 2,
            configTab: 'template',
          },
        })
      }
    },
    // 保存
    async save() {
      this.operTable[this.currentIndex].area =
        this.tableData[this.currentIndex].area
      this.operTable[this.currentIndex].standardCustomized =
        this.tableData[this.currentIndex].standardCustomized
      // 新增
      let arr = this.operTable.filter((item) => {
        return item.area == '' || item.standardCustomized == ''
      })
      if (arr.length > 0) {
        this.$message.warning('地区和产品类型必填！')
        return
      }
      let res = await SettleConfigInteractor.setConfigConditioninsertPOs(
        this.operTable
      )
      if (res) {
        this.$message.success('操作成功！')
        this.$router.push({
          path: '/finance-center/config-manage',
          query: {
            tab: 2,
            configTab: 'template',
          },
        })
      }
    },
    // 角色列表
    async getRoles() {
      this.roleList =
        await SettleConfigInteractor.getConfigConditionSettlementRoles()
    },
    // 获取角色code
    getRoleCode(msg) {
      let roleMap = new Map()
      this.roleList.forEach((ele) => {
        roleMap.set(ele.message, ele.code)
      })
      return roleMap.get(msg)
    },
    setRow(value) {
      this.configColumnRow = { ...value }
      let fixedRatioShow = ''
      if (value.type == 2) {
        let stepDesc = value.tieredDTOS.map((item) => item.ratio)
        fixedRatioShow = `${value.typeDesc}${stepDesc.join(',')}`
      } else if (value.type == 1) {
        fixedRatioShow = `${value.typeDesc}${value.fixedRatio}`
      }
      this.$set(
        this.tableData[this.currentIndex],
        this.currentCode,
        fixedRatioShow
      )
      this.setOperTable()
    },
    // 编辑传参
    setOperTable() {
      let configCommissionRole = {
        configCommissionId: '',
        configCommissionRoleId: '',
        ratio: this.configColumnRow.fixedRatio,
        ratioType: this.configColumnRow.type,
        roleType: this.currentCode,
        tenantId: this.configColumnRow.tenantId,
        tieredDTOs: this.configColumnRow.tieredDTOS,
      }
      let obj = this.operTable[this.currentIndex].configCommissionRoleDTOs.find(
        (ele) => {
          return ele.roleType === this.currentCode
        }
      )
      if (obj) {
        this.operTable[this.currentIndex].configCommissionRoleDTOs.splice(
          this.operTable[this.currentIndex].configCommissionRoleDTOs.indexOf(
            obj
          ),
          1
        )
      }
      this.operTable[this.currentIndex].configCommissionRoleDTOs.push(
        configCommissionRole
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.template-config {
  box-sizing: border-box;
  padding: 10px 0px;
  width: 100%;
  height: calc(100vh - 55px);
  overflow-y: scroll;
  .title {
    display: inline-block;
    text-align: right;
  }
  .title::before {
    content: '';
    border-right: 7px solid blue;
    margin-right: 5px;
  }
  .template-table {
    margin-top: 15px;
  }
  .addTemplate {
    text-align: center;
    margin-top: 15px;
  }
  .stick30 {
    position: sticky;
    bottom: 30px;
  }
}
</style>
