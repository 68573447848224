<template>
  <div class="pro-container">
    <div class="wrapper">
      <span class="ml10">{{ $t('mailConfig.enableEmailCenter') }}</span>

      <el-tooltip effect="light" :content="$t('mailConfig.enableEmailCenterInfo')" placement="top">
        <i class="iconfont icon-wenhao ml10"></i>
      </el-tooltip>
      <el-switch v-model="form.enabled" :disabled="disabled" :active-value="1" :inactive-value="0"
        class="ml10"></el-switch>
    </div>
    <div class="ml30" v-if="form.enabled == 1">
      <div>
        <!-- 单选 -->
        <el-radio-group :disabled="disabled" v-model="form.scope">
          <el-radio :label="0">{{ $t('mailConfig.systemwide') }}</el-radio>
          <el-radio :label="1">
            {{ $t('mailConfig.enablespecifiedaccount') }}
          </el-radio>
        </el-radio-group>
        <el-tooltip effect="light" :content="$t('mailConfig.Notice1')" placement="top">
          <i class="iconfont icon-wenhao ml10"></i>
        </el-tooltip>
      </div>
      <!-- 表格 -->
      <el-table border stripe ref="multipleTable" class="mail-table" :data="tableData" align="center"
        @selection-change="handleSelectionChange" v-if="form.scope == 1">
        <el-table-column type="selection" v-if="!disabled" align="center"></el-table-column>

        <el-table-column align="center" prop="emailAccount" :label="$t('mailConfig.emailaccount')"></el-table-column>
        <el-table-column :label="$t('mailConfig.owner')" align="center">
          <template slot-scope="scope">
            {{ scope.row[isEn ? 'businessNameEn' : 'businessName'] }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="save">
        {{ $t('inquiryList.Save') }}
      </el-button>
    </div>
  </div>
</template>

<script>
  import { hasRight } from '@/utils/permissionBtn'
  import { MailSettingInteractor } from '@/core'
  import { mapGetters } from 'vuex'

  export default {
    name: 'MailCenter',

    data() {
      return {
        form: {
          enabled: '', //是否开启中心站 0关闭 1开启
          scope: '', // 范围 0 全局 1：指定账号
          id: '', // 业务id
        },
        tableData: [],
        prevSelection: [],
        disabled: true,
        formChange: false,
        multipleSelection: [],
      }
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      isEn() {
        return this.settings === 'en'
      },
    },
    mounted() { },

    methods: {
      // 获取mailAccountIds
      getParams() {
        let params = {}
        let { prevSelection, multipleSelection } = this
        let openAccountIds = this.multipleSelection.map(
          (item) => item.mailAccountId
        )
        let closeAccountIds = this.tableData
          .filter((item) => !openAccountIds.includes(item.mailAccountId))
          .map((i) => i.mailAccountId)

        let { enabled, scope, id } = this.form
        params = {
          enabled,
          scope,
          openAccountIds,
          closeAccountIds,
          id,
        }
        if (scope != 1) {
          delete params.openAccountIds
          delete params.closeAccountIds
        }

        return params
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },

      save() {
        MailSettingInteractor.centerEnableApi({
          ...this.getParams(),
        }).then((res) => {
          if (res?.code === '000000') {
            this.$message.success(this.$t('reqmsg.$9'))
          }
        })
      },
      // 获取当前开启数据
      getPrevSelection(list) {
        this.prevSelection = list
          .filter((item) => item.centerEnabled === 1)
          .map((item) => {
            return {
              ...item,
            }
          })
      },
      // 获取列表数据
      getList() {
        MailSettingInteractor.tenantEmailsApi().then((res) => {
          if (res?.code === '000000') {
            this.tableData = res.data?.records || []
            this.getPrevSelection(this.tableData)
            setTimeout(() => {
              this.tableData
                .filter((i) => i.centerEnabled === 1)
                .forEach((row) => {
                  this.$refs.multipleTable?.toggleRowSelection(row)
                })
              this.initLock = false
            }, 200)
          }
        })
      },
      //获取表单详情
      getDetail() {
        MailSettingInteractor.findCentralConfigApi().then((res) => {
          if (res?.code === '000000') {
            this.form = res.data || {}

            this.formChange = false
          }
        })
        this.disabled = !hasRight('mail:centerOperation')
        if (this.form.scope === 1) {
          this.getList()
        }
      },
      setChecked() {
        MailSettingInteractor.centralConfigApi({
          ...this.form,
        }).then((res) => {
          if (res?.code === '000000' && res?.data) {
            this.$message.success(this.$t('reqmsg.$9'))
            this.getDetail()
          }
        })
      },
    },
    watch: {
      'form.scope': {
        immediate: true,
        handler: function (val) {
          if (val == 1) {
            this.getList()
          }
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pro-container {
    font-size: 13px;

    .wrapper {
      display: flex;
      align-items: center;
    }

    .mail-table {
      margin-top: 10px;
      width: 550px;
    }
  }

  .mail-center-tip {
    font-size: 12px;
    color: #7f7f7f;
  }

  .bottom {
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
  }
</style>
