import request from '@/core/services/request'

// 客户管理

//分页列表查询全部客户
export function clentListPage(data) {
  return request({
    url: '/merchants/info/listPage',
    method: 'post',
    data,
  })
}

//分页列表查询我负责的客户
export function listTakeCustomerPage(data) {
  return request({
    url: '/merchants/info/listTakeCustomerPage',
    method: 'post',
    data,
  })
}

// 新增实体（新增客户）
export function clientInsertPO(data) {
  return request({
    url: '/merchants/info/insertPO',
    method: 'post',
    data,
  })
}
// 更新实体（更新客户）
export function clientUpdatePO(data) {
  return request({
    url: '/merchants/info/updatePO',
    method: 'post',
    data,
  })
}

// 查看详情（详情）
export function getDetailForEditShow(data) {
  return request({
    url: '/merchants/info/getDetailForEditShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 查看详情（编辑）
export function getDetailForEdit(data) {
  return request({
    url: '/merchants/info/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 设置黑名单
export function customerBlackList(data) {
  return request({
    url: '/merchants/info/customerBlackList',
    method: 'post',
    data,
  })
}

// 客户管理历史记录
export function customerManagerHistory(data) {
  return request({
    url: '/merchants/info/customerManagerHistory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 转入公海
export function throwBackHighSeas(data) {
  return request({
    url: '/merchants/info/throwBackHighSeas',
    method: 'post',
    data,
  })
}

// 转让
export function customerTransfer(data) {
  return request({
    url: '/merchants/info/customerTransfer',
    method: 'post',
    data,
  })
}

// 分管
export function customerBranched(data) {
  return request({
    url: '/merchants/info/customerBranched',
    method: 'post',
    data,
  })
}

// 跟进
export function customerFollerRecord(data) {
  return request({
    url: '/merchants/info/customerFollerRecord',
    method: 'post',
    data,
  })
}

//客户付款方式下拉框
export function customerPaymentType(data) {
  return request({
    url: '/merchants/pay/paymentType',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//客商详情——订单记录
export function customerGetOrderInfoVO(data) {
  return request({
    url: '/order/salesOrder/getOrderInfoVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//客商详情——订单记录列表
export function customerGetOrderInfoPageVO(data) {
  return request({
    url: '/order/salesOrder/getOrderInfoPageVO',
    method: 'post',
    data,
  })
}

//客商详情——询盘记录
export function customerGetEnquiryInfoVO(data) {
  return request({
    url: '/order/salesOrder/getEnquiryInfoVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//客商详情——询盘记录列表
export function customerGetEnquiryInfoPageVO(data) {
  return request({
    url: '/order/salesOrder/getEnquiryInfoPageVO',
    method: 'post',
    data,
  })
}

//客户来源接口下拉
export function customerSourceList(data) {
  return request({
    url: '/merchants/source/listPage',
    method: 'post',
    data,
  })
}

//支付模板接口下拉查询（payee：1客户，2供应商）
export function paymentList(data) {
  return request({
    url: '/merchants/payment/listForSelect',
    method: 'post',
    data,
  })
}

//公司详情
export function companyDetail(data) {
  return request({
    url: '/merchants/info/companyDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//联系人下拉
export function linkmanList(data) {
  return request({
    url: '/merchants/linkman/listPage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//校验邮箱是否重复
export function checkEmail(data) {
  return request({
    url: '/merchants/info/checkEmail',
    method: 'post',
    data,
  })
}

//查询客户公司
export function listCompany(data) {
  return request({
    url: '/merchants/info/listPageCompany',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//分页列表查询客户的评价记录
export function evaluationRecordslistPage(data) {
  return request({
    url: '/merchants/evaluationRecords/listPage',
    method: 'post',
    data,
  })
}
//新增客户评价
export function evaluationRecordsInsertPO(data) {
  return request({
    url: '/merchants/evaluationRecords/insertPO',
    method: 'post',
    data,
  })
}
//客户评价-详情
export function judgeDetailForShow(data) {
  return request({
    url: '/merchants/evaluationRecords/getDetailForShow',
    method: 'post',
    data,
  })
}
//客户评价-提交反馈
export function updateFeedback(data) {
  return request({
    url: '/merchants/evaluationRecords/updateFeedback',
    method: 'post',
    data,
  })
}
//客户管理历史记录-分管人
export function customerManagerHistoryForCharge(data) {
  return request({
    url: '/merchants/info/customerManagerHistoryForCharge',
    method: 'post',
    data,
  })
}
//客户管理历史记录-负责人
export function customerManagerHistoryForOwner(data) {
  return request({
    url: '/merchants/info/customerManagerHistoryForOwner',
    method: 'post',
    data,
  })
}
//查询客户所有分管人
export function queryBranchedList(data) {
  return request({
    url: '/merchants/manager/queryBranchedList',
    method: 'post',
    data,
  })
}
//移除分管人
export function removeBranchedList(data) {
  return request({
    url: '/merchants/manager/removeBranchedList',
    method: 'post',
    data,
  })
}

//分管申请
export function customerBranchedApply(data) {
  return request({
    url: '/merchants/info/customerBranchedApply',
    method: 'post',
    data,
  })
}

//1.4.3根据客户id查询待分配金额(查看)
export function listAllocatedMoney(data) {
  return request({
    url: '/finance/customerAccountBalance/listAllocatedMoney',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//1.4.3根据公司名称查询审核通过的单据（除了已作废、已完成）下拉
export function listOrderNumberByCompany(data) {
  return request({
    url: '/order/salesOrder/listOrderNumberByCompany',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//1.4.3待分配金额绑定订单（确认）
export function allocatedMoneyBindOrder(data) {
  return request({
    url: '/finance/customerAccountBalance/allocatedMoneyBindOrder',
    method: 'post',
    data,
  })
}

//1.4.3待分配金额退款
export function allocatedMoneyRefund(data) {
  return request({
    url: '/finance/payOrder/allocatedMoneyRefund',
    method: 'post',
    data,
  })
}


// 新增或保存供应商产品
export function saveSupplierProduct(data) {
  return request({
    url: '/merchants/supplier/saveOrUpdateSupplierProduct',
    method: 'post',
    data,
  })
}

// 新增或保存供应商产品
export function deleteSupplierProduct(data) {
  return request({
    url: '/merchants/supplier/deleteSupplierProduct',
    method: 'post',
    data,
  })
}