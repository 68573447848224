var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"width":"1080px","top":"20%","visible":_vm.netPriceVisible,"before-close":_vm.close,"close-on-click-modal":false,"append-to-body":""},on:{"update:visible":function($event){_vm.netPriceVisible=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('table',{staticClass:"custom-table no-space"},[_c('thead',[_c('tr',[_c('th',[_c('span',[_c('i',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.lang === 'en' ? 'Cost price (￥)' : '成本价(￥)')+" ")])]),_c('th',[_c('span',[_c('i',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.lang === 'en' ? 'rate of profit' : '利润率')+" ")])]),_c('th',[_c('span',[_c('i',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.lang === 'en' ? 'Tax rate' : '税率')+" ")])]),_c('th',[_c('span',[_c('i',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.lang === 'en' ? 'Packing volume' : '装箱体积')+" ")])]),_c('th',[_c('span',[_c('i',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.lang === 'en' ? 'Packing weight' : '装箱重量')+" ")])]),_c('th',{attrs:{"width":"240"}},[_c('span',[_c('i',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.lang === 'en' ? 'Shipping Method' : '运输方式')+" ")])]),_c('th',[_c('span',[_c('i',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.lang === 'en' ? 'The freight cost' : '运费单价')+" ")])])])]),_c('tbody',[_c('tr',[_c('td',[_c('el-form-item',{attrs:{"prop":"costPrice","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'change',
                },
                {
                  pattern: /^\d+(\.\d{1,3})?$/,
                  message: _vm.$t('placeholder.decimals'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"maxlength":"20","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(_vm.form.costPrice),callback:function ($$v) {_vm.$set(_vm.form, "costPrice", $$v)},expression:"form.costPrice"}})],1)],1),_c('td',[_c('el-form-item',{attrs:{"prop":"profitRate","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'change',
                },
                {
                  pattern: /^\d+(\.\d{1,3})?$/,
                  message: _vm.$t('placeholder.decimals'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"maxlength":"20","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(_vm.form.profitRate),callback:function ($$v) {_vm.$set(_vm.form, "profitRate", $$v)},expression:"form.profitRate"}})],1)],1),_c('td',[_c('el-form-item',{attrs:{"prop":"taxRate","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'change',
                },
                {
                  pattern: /^\d+(\.\d{1,3})?$/,
                  message: _vm.$t('placeholder.decimals'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"maxlength":"20","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(_vm.form.taxRate),callback:function ($$v) {_vm.$set(_vm.form, "taxRate", $$v)},expression:"form.taxRate"}})],1)],1),_c('td',[_c('el-form-item',{attrs:{"prop":"totalBoxVolumeM","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'change',
                },
                {
                  pattern: /^\d+(\.\d{1,3})?$/,
                  message: _vm.$t('placeholder.decimals'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"maxlength":"20","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(_vm.form.totalBoxVolumeM),callback:function ($$v) {_vm.$set(_vm.form, "totalBoxVolumeM", $$v)},expression:"form.totalBoxVolumeM"}})],1)],1),_c('td',[_c('el-form-item',{attrs:{"prop":"totalWeightKgs","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'change',
                },
                {
                  pattern: /^\d+(\.\d{1,3})?$/,
                  message: _vm.$t('placeholder.decimals'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"maxlength":"20","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(_vm.form.totalWeightKgs),callback:function ($$v) {_vm.$set(_vm.form, "totalWeightKgs", $$v)},expression:"form.totalWeightKgs"}})],1)],1),_c('td',[_c('el-form-item',{attrs:{"prop":"transportWay"}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsSel'),"disabled":_vm.netPrice == 0 ? true : false,"clearable":""},on:{"change":_vm.transportWayChange},model:{value:(_vm.form.transportWay),callback:function ($$v) {_vm.$set(_vm.form, "transportWay", $$v)},expression:"form.transportWay"}},_vm._l((_vm.transpWayList),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.lang === 'en' ? item.labelEn : item.label,"value":item.value}})}),1)],1)],1),_c('td',[_c('el-form-item',{attrs:{"prop":"transportPrice"}},[_c('el-input',{attrs:{"maxlength":"20","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(_vm.form.transportPrice),callback:function ($$v) {_vm.$set(_vm.form, "transportPrice", $$v)},expression:"form.transportPrice"}})],1)],1)])])])]),_c('div',{staticClass:"flex mt20"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.calculatePrice}},[_vm._v(" "+_vm._s(_vm.lang === 'en' ? 'Click on the calculation' : '点击计算')+" ")]),_c('h4',{staticClass:"ml10"},[_vm._v(" Net price($): "),_c('span',[_vm._v(_vm._s(_vm.netPrice))])])],1),_c('div',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('inquiryList.Confirm'))+" ")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('inquiryList.Cancel')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }