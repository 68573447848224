<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="中文名称" prop="nameCn">
            <el-input v-model="form.nameCn" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="英文名称" prop="nameEn">
            <el-input v-model="form.nameEn" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="产线运营" prop="belongerName">
            <el-input
              clearable
              v-model="form.belongerName"
              placeholder="请选择"
              @focus="selectUserClick('belongerName')"
              @change="belongerNameChange"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="采购开发" prop="buyerName">
            <el-input
              clearable
              v-model="form.buyerName"
              placeholder="请选择"
              @focus="selectUserClick('buyerName')"
              @change="createNameChange"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="成员" prop="memberNames">
            <el-input
              clearable
              v-model="form.memberNames"
              placeholder="请选择"
              @focus="selectUserClick('memberNames')"
              @change="memberNamesChange"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="关联产品类别">
            <el-cascader
              :options="options"
              :props="{
                multiple: true,
                checkStrictly: true,
                value: 'productCategoryId',
                label: 'nameCn',
              }"
              clearable
              size="medium"
              v-model="categoryId"
              ref="cascader"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="强制产线协助">
            <el-select v-model="form.compulsoryAssistance" placeholder="请选择">
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
            <span style="display: block; color: #999999; font-size: 12px">
              Tips:此规则只对订制品生效
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="上架平台">
            <el-checkbox-group v-model="form.platformCode">
              <el-checkbox
                v-for="item of platformDs"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>

    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/product/productSetting'
  import UserChoose from '@/components/userChoose2'
  import { listPage } from '@/api/product/catesManager'
  import { platformDs } from '@/utils/dicts'
  import { deepCopy } from 'kits'
  export default {
    name: 'ProductLineModel',
    components: {
      UserChoose,
    },
    data() {
      return {
        loading: false,
        title: '产线设置',
        form: {
          nameCn: '',
          nameEn: '',
          type: 9,
          productSettingId: '',
          value: null,
          belongerName: '',
          belongerNameEn: '',
          buyerName: '',
          buyerNameEn: '',
          memberNames: '',
          memberNamesEn: '',
          category: '',
          compulsoryAssistance: 0,
          platformCode: [], // 上架平台
        },
        rules: {
          nameCn: [{ required: true, trigger: 'blur', message: '请输入' }],
          nameEn: [{ required: true, trigger: 'blur', message: '请输入' }],
          belongerName: [
            { required: true, trigger: 'change', message: '请选择' },
          ],
          buyerName: [{ required: true, trigger: 'change', message: '请选择' }],
        },
        dialogFormVisible: false,
        belongerNameId: [], //归属人id
        buyerNameId: [], //采购开发id
        memberId: [], //成员id
        options: [], // 产品类目列表
        chooseList: [],
        categoryId: [],
        platformDs // 上架平台字典
      }
    },
    created() {},
    watch: {
      dialogFormVisible(newval, oldval) {
        if (newval) {
          this.getCateList()
        }
      },
    },
    methods: {
      //展示弹框
      showAddEdit(data) {
        if (data) {
          this.form.productSettingId = data.productSettingId
          this.form.value = data.value
          this.form.type = data.type
          this.form.belongerName = data.belongerName
          this.form.buyerName = data.buyerName
          this.form.buyerNameEn = data.buyerNameEn
          this.form.belongerId = data.belongerId
          this.form.buyerId = data.buyerId
          this.form.nameCn = data.nameCn
          this.form.nameEn = data.nameEn
          this.form.memberNames = data.memberNames
          this.form.memberNamesEn = data.memberNamesEn
          this.form.memberIds = data.memberIds
          this.form.compulsoryAssistance = data.compulsoryAssistance
            ? Number(data.compulsoryAssistance)
            : 0
          this.form.platformCode = data.platformCode ? data.platformCode.split(',') : [] // 回显上架平台
          this.belongerNameId = data.belongerId?.split(',')
          this.buyerNameId = data.buyerId?.split(',')
          this.memberId = data.memberIds?.split(',')
          this.categoryId = data.value ? JSON.parse(data.value) : []
        }
        this.dialogFormVisible = true
      },

      //归属人，采购开发，成员选择
      selectUserClick(str) {
        this.isUserChoose = str
        if (str === 'belongerName') {
          this.$refs['UserChoose'].showAddEdit(
            'listSearch',
            this.belongerNameId,
            true
          )
        } else if (str === 'buyerName') {
          this.$refs['UserChoose'].showAddEdit('listSearch', this.buyerNameId)
        } else if (str === 'memberNames') {
          this.$refs['UserChoose'].showAddEdit('listSearch', this.memberId)
        }
      },
      //归属人，采购开发，成员，返回
      chooseUser(checkedUser) {
        if (this.isUserChoose === 'belongerName') {
          this.belongerNameChange()
        } else if (this.isUserChoose === 'buyerName') {
          this.createNameChange()
        } else {
          this.memberNamesChange()
        }
        if (!checkedUser.length) {
          return
        }
        let userName = [],
          userNameEn = []
        checkedUser.forEach((item) => {
          userName.push(item.name)
          userNameEn.push(item.englishName)
          if (this.isUserChoose === 'belongerName') {
            this.form.belongerName = userName.join(',')
            this.form.belongerNameEn = userNameEn.join(',')
            this.belongerNameId.push(item.id)
          } else if (this.isUserChoose === 'buyerName') {
            this.buyerNameId.push(item.id)
            this.form.buyerName = userName.join(',')
            this.form.buyerNameEn = userNameEn.join(',')
          } else if (this.isUserChoose === 'memberNames') {
            this.memberId?.push(item.id)
            this.form.memberNames = userName.join(',')
            this.form.memberNamesEn = userNameEn.join(',')
          }
        })
      },
      // 删除g归属人
      belongerNameChange() {
        this.belongerNameId = []
        this.form.belongerName = ''
        this.form.belongerNameEn = ''
        this.form.belongerId = ''
      },
      // 删除采购开发
      createNameChange() {
        this.buyerNameId = []
        this.form.buyerName = ''
        this.form.buyerNameEn = ''
        this.form.buyerId = ''
      },
      // 删除成员
      memberNamesChange() {
        this.memberId = []
        this.form.memberNames = ''
        this.form.memberNamesEn = ''
        this.form.memberIds = ''
      },

      // 关闭
      close() {
        this.$refs['form'].resetFields()
        this.form = {
          nameCn: '',
          nameEn: '',
          type: 9,
          productSettingId: '',
          value: null,
          belongerName: '',
          buyerName: '',
          memberNames: '',
          category: '',
          compulsoryAssistance: 0,
          platformCode: [],
        }
        this.categoryId = []
        this.belongerNameId = []
        this.buyerNameId = []
        this.memberId = []
        this.dialogFormVisible = false
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.belongerId = this.belongerNameId.join(',')
            this.form.buyerId = this.buyerNameId.join(',')
            this.form.memberIds = this.memberId?.join(',')

            let _categoryId = []

            this.categoryId.forEach((item) => {
              _categoryId.push(item[item.length - 1])
            })

            // this.form = {
            //   ...this.form,
            //   // category: this.dealCateName(this.categoryId)?.toString(),
            //   categoryId: _categoryId?.toString(),
            //   value: JSON.stringify(this.categoryId),
            //   platformCode: this.form.platformCode.join()
            // }
            const params = deepCopy(this.form)
            Object.assign(params, {
              categoryId: _categoryId?.toString(),
              value: JSON.stringify(this.categoryId),
              platformCode: this.form.platformCode.join()
            })
            this.loading = true
            if (!this.form.productSettingId) {
              let response = await insertPO(params)
              this.loading = false

              if (response.code === '000000') {
                this.$baseMessage(
                  '新增成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              let response = await updatePO(params)
              this.loading = false

              if (response.code === '000000') {
                this.$baseMessage(
                  '编辑成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.close()
            this.$emit('line-way', this.form.type)
          }
        })
      },
      getCateList() {
        let dt = {
          pageLe: 999,
          pageNo: 1,
        }
        listPage(dt).then((res) => {
          this.options = res.data.data
        })
      },
      dealCateName(arr) {
        let nameCN = []
        let b = {}
        arr?.forEach((item) => {
          b = this.options.find((ele) => {
            return ele.productCategoryId == item
          })
          nameCN.push(b.nameCn)
        })

        return nameCN
      },

      flatDeep(arr) {
        let result = []
        for (let i = 0; i < arr?.length; i++) {
          if (Array.isArray(arr[i].children)) {
            result.push(arr[i])
            result = result.concat(this.flatDeep(arr[i].children))
          } else {
            result.push(arr[i])
          }
        }
        return result
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-cascader,
    .el-select {
      width: 100%;
    }
  }
</style>
