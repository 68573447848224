<template>
  <!-- 平台价格 -->
  <el-row :gutter="20" class="pro-container pro-base-msg pro-price" v-if="productType === '标准品'">
    <el-col :span="2">
      <h6 class="left-h6">{{ $t('productDetail.PlatformPrice') }}</h6>
    </el-col>
    <el-col :span="22" class="right-content" style="padding: 20px 20px">
      <el-form ref="form" inline :rules="rules" :model="form" label-width="140px" class="el_form"
        :label-position="lang === 'zh' ? 'right' : 'top'">
        <el-form-item>
          <!-- Sage展示价 -->
          <span style="color: red">*</span>
          <span class="ml5">{{ $t('productDetail.SageDisplayPrice') }}</span>
        </el-form-item>
        <el-form-item>
          <el-cascader class="cascader-demo" v-model="plantPrintWay" :options="printOptions"
            :props="{ label: lang === 'zh' ? 'label' : 'labelEn' }" :key="cascaderIdx" :placeholder="placeholderVal"
            :append-to-body="false" @change="changePlantPrintWay"></el-cascader>
        </el-form-item>
        <el-form-item class="ml20">
          <div slot="label">
            <span style="color: red">*</span>
            <!-- 平台展示免运费 -->
            <span class="ml5">
              {{ $t('productDetail.PlatformDisplayFreeShipping') }}
            </span>
          </div>
          <el-switch v-model="calcFreight" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <el-table :data="calcList" style="width: 100%" border>
        <!-- 数量 -->
        <el-table-column prop="num" :label="$t('productDetail.Quantity')" align="center">
          <template slot-scope="scope" v-if="printingWaySelectObj">
            <span>{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <!-- netPrice -->
        <el-table-column prop="netPrice" label="Sage Net Price($)" align="center">
          <template slot-scope="scope">
            <span>
              {{ scope.row.netPrice }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="listPrice" align="center">
          <template slot="header">
            <p>Sage</p>
            <p>List Price($)</p>
          </template>
          <template slot-scope="scope">
            {{ scope.row.listPrice }}
          </template>
        </el-table-column>
        <!-- Sage Net 总价 -->
      </el-table>
      <el-row class="mt10" v-if="printingMarkup && printingMarkup.length">
        <el-col class="mt10">
          <p class="mt10">
            {{ $t('productDetail.PrintingMarkup') }}
          </p>
          <el-table style="width: 700px" border :data="printingMarkup">
            <!-- 数量 -->
            <el-table-column :label="$t('productDetail.Quantity')" align="center" prop="num"
              width="120"></el-table-column>
            <el-table-column label="Net Price for Additional Imprint ($)" align="center" prop="price">
              <template slot-scope="scope">{{ scope.row.price }} ea</template>
            </el-table-column>
            <el-table-column label="List Price for Additional Imprint ($)" align="center">
              <template slot-scope="scope">
                {{ (Number(scope.row.price) / Number(priceRate)).toFixed(2) }}
                ea
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-row v-if="this.showAddPrice && plantPrintWay.length > 0">
          <el-col class="mt30" v-if="!showAddPriceTitle">
            {{ $t('productDetail.SpecificationMarkup') }}
          </el-col>
          <div class="mt10" v-for="(item, index) in this.specTable" :key="index">
            <el-col v-for="(it, i) in item.value" :key="i + 100">
              <span v-show="it.basePricesSetting == 0 && it.specsPrice != 0">
                {{ it.specsValue }} Additonal：$ {{ it.specsPrice }} ea
              </span>
            </el-col>
          </div>
        </el-row>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import Decimal from 'decimal.js'
  import { getDetailByType } from '@/api/product/productSetting'
  import { elFormErrorScrollIntoView } from 'kits'
  export default {
    data() {
      return {
        tableKey: 1,
        cascaderIdx: 0,
        productType: '标准品',
        showPlantSelect: false, // 多规格展示，一个规格不展示
        form: {},
        rules: {},
        printingWaySelectObj: {},
        waySelectObj: {},
        specsList: [], // 规格列表
        printOptions: [], // 平台价格展示的级联选择
        plantPrintWay: [], // 平台价格展示的 计价方式
        calcFreight: 0, // 是否展示免运费
        calcList: [], // 平台价格列表
        printListAll: [], //所有的印刷方式
        printList: [], //印刷计价选择的印刷方式
        netPriceList: [], // 数量阶梯对应的价格
        pageType: '',
        sagePrintPrice: 0, // 平台价格 选中的 印刷价格
        minPrintPrice: 0, // 选中的印刷方式最低的印刷价格
        isNumberStep: false, // 是否是数量阶梯
        waySelect: {},
        printingWaySelect: {},
        specTable: [],
        stepNumber: [],
        showAddPrice: true,
        currentPrice: 0, // 当前印刷方式价格
        priceRate: 0,
        printOptionskey: '',
        placeholderVal: this.$t('placeholder.plsSel'),
        printingMarkup: [], //印刷加价
        sortResult: [], //最小值数组
        toFixedNumber: 2, //保留几位小数
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      showAddPriceTitle() {
        let arr = []
        if (
          this.specTable.length == 1 &&
          typeof this.specTable[0].value == 'undefined'
        ) {
          return true
        } else {
          this.specTable.forEach((it) => {
            it.value?.forEach((ot) => {
              arr.push(ot.specsPrice)
            })
          })
          let state = arr.every((it) => {
            return it == 0
          })
          return state
        }
      },
    },
    watch: {
      printOptions: {
        handler(val) {
          this.cascaderIdx++
        },
        deep: true,
      },

      printList: {
        deep: true,
        handler(val) {
          this.handlePrintOptions(val)
        },
        immediate: true,
      },
    },

    created() {
      this.getExtraSpecPrice()
      this.getDetailByType8()
      this.$Bus.$on('ChangeShippingCosts', (val) => {
        this.getNetPriceListFn(val)
      })
      this.$Bus.$on('printList', (val) => {
        this.printList = val
      })
      this.$Bus.$on('categoryId', (id) => {
        this.plantPrintWay = []
        this.calcList = []
      })

      this.$Bus.$on('isSingleSpec', (val) => {
        this.showAddPrice = val
        if (!this.showAddPrice) {
          this.specTable = []
        }
      })
      // 切换产品列表情况印刷加价
      this.$Bus.$on('changeCategoryId', async (categoryId) => {
        this.printingMarkup = []
      })
      // this.$Bus.$on('switchChange', () => {
      //   this.printingWaySelect = {}
      //   this.waySelect = {}
      //   this.plantPrintWay = []
      //   this.placeholderVal = this.$t('placeholder.plsSel')
      // })

      // 切换小数位
      this.$Bus.$on('changePriceScale', (val) => {
        this.toFixedNumber = val
      })
      this.getProductType()
    },

    methods: {
      changePlantPrintWay(val) {
        if (!val) {
          this.calcList = []
          return
        }
        if (val.length && val[0] == '') {
          this.calcList = this.netPriceList
          this.printingMarkup = []
        } else {
          this.getPrintingMarkupFn(val)
        }
      },
      // 获取印刷加价
      getPrintingMarkupFn(val) {
        if (!val || val.length < 2) {
          this.printingMarkup = []
          return
        }
        let printingMarkup = []
        let calcListArr = []
        this.printingMarkup = []
        const payObj = this.printList.filter(
          (item) => item.productPrintingWayId === val[0]
        )?.[0]
        if (!payObj) return
        let printingPositionNumArr =
          payObj.productPrintingFullValuationDTOList.filter(
            (item) => item.printingPositionNum === 1
          )
        this.getMinValue(printingPositionNumArr)
        printingPositionNumArr.map((item) => {
          const idx = payObj.valuationMethodValue.findIndex(
            (k) => k === item.valuationMethod
          )
          item.valuationMethodId = payObj.valuationMethodIds[idx]
          return item
        })

        let currentSelect = printingPositionNumArr.filter(
          (item) => item.valuationMethodId === val[1]
        )?.[0]

        this.netPriceList.forEach((item, index) => {
          printingMarkup.push({
            num: item.num,
            price: currentSelect ? currentSelect['price' + index] : '',
          })
          calcListArr.push({
            num: item.num,
          })
        })
        for (var i = 0; i < printingMarkup.length; i++) {
          if (Number(printingMarkup[i].price) - Number(this.sortResult[i])) {
            this.printingMarkup.push({
              num: printingMarkup[i].num,
              price: new Decimal(printingMarkup[i].price || 0)
                .sub(new Decimal(this.sortResult[i]) || 0)
                .toDecimalPlaces(this.toFixedNumber)
                .toNumber()
                .toFixed(this.toFixedNumber),
            })
          }
        }
        calcListArr.map((item, index) => {
          item.netPrice = this.sortResult[index]
          item.listPrice = this.sortResult[index]
            ? (Number(this.sortResult[index]) / Number(this.priceRate)).toFixed(
              2
            )
            : ''
          return item
        })
        this.calcList = calcListArr
      },
      // 找出最小值
      getMinValue(val) {
        if (!val || !val.length) return
        let arr = []
        val.forEach((item) => {
          let subArr = []
          this.netPriceList.forEach((sub, subIndex) => {
            subArr.push(item['price' + subIndex])
          })
          arr.push(subArr)
        })
        const arrSort = arr.reduce((acc, curr) => {
          curr.forEach((value, index) => {
            if (!Array.isArray(acc[index])) {
              acc[index] = []
            }
            acc[index].push(value)
          })
          return acc
        }, [])
        this.sortResult = arrSort.map((subArr) => {
          let min = undefined
          for (const value of subArr) {
            if (
              (typeof value === 'number' && value !== 0) ||
              (typeof value === 'string' && value.trim() !== '')
            ) {
              if (min === undefined || value * 1 < min) {
                min = value * 1
              }
            }
          }
          return min // 返回最小的非零且非空元素
        })
      },
      // 处理下拉项
      handlePrintOptions(arr) {
        this.printOptions = []
        for (let k of arr) {
          if (
            k['productPrintingFullValuationDTOList'] &&
            k['productPrintingFullValuationDTOList'].length
          ) {
            let children = []
            for (let i in k['valuationMethodIds']) {
              children.push({
                label: k['valuationMethodValue'][i] + '-1位',
                labelEn: k['valuationMethodValueEn'][i] + '1-location',
                value: k['valuationMethodIds'][i],
              })
            }
            this.printOptions.push({
              label: k.nameCn,
              labelEn: k.nameEn,
              value: k.productPrintingWayId,
              children,
            })
          }
        }
        this.printOptions.push({
          label: 'Blank',
          labelEn: 'Blank',
          value: '',
        })
        this.hasRemoveFn(arr)
      },
      // 判断当前已选择的方式是否被移除
      hasRemoveFn(arr) {
        if (
          !this.plantPrintWay ||
          !this.plantPrintWay.length ||
          this.plantPrintWay[0] == ''
        )
          return

        const currentObj = arr.filter(
          (item) => item.productPrintingWayId === this.plantPrintWay[0]
        )?.[0]
        if (currentObj?.productPrintingFullValuationDTOList?.length) {
          const productPrintingWayIds = arr.map(
            (item) => item.productPrintingWayId
          )
          let valuationMethodIds = []
          for (let k of arr) {
            valuationMethodIds = [
              ...valuationMethodIds,
              ...k['valuationMethodIds'],
            ]
          }
          if (
            !productPrintingWayIds.includes(this.plantPrintWay[0]) ||
            !valuationMethodIds.includes(this.plantPrintWay[1])
          ) {
            this.calcList = []
            this.printingMarkup = []
          } else {
            this.getPrintingMarkupFn(this.plantPrintWay)
          }
        } else {
          this.plantPrintWay = []
          this.calcList = []
          this.printingMarkup = []
        }
      },
      // 获取数量阶梯数据
      getNetPriceListFn(arr) {
        this.netPriceList = arr.map((item) => {
          return {
            num: Number(item.num),
            netPrice: item.netPrice,
            listPrice: (Number(item.netPrice) / Number(this.priceRate)).toFixed(
              2
            ),
          }
        })
        if (this.plantPrintWay?.length && this.plantPrintWay[0] == '')
          this.calcList = this.netPriceList
      },
      // 获取规格加价
      getExtraSpecPrice() {
        this.$Bus.$on('extraSpecPrice', (val) => {
          this.specTable = _.cloneDeep(val)
        })
      },
      //产品汇率
      async getDetailByType8() {
        let response = await getDetailByType({ type: 8 })
        if (response.code === '000000' && response.data.length) {
          this.priceRate = Number(response.data[0].value)
        }
      },
      // 获取产品类型
      getProductType() {
        this.$Bus.$on('setProductType', (val) => {
          this.productType = val
          this.calcList = []
          this.printingMarkup = []
          this.plantPrintWay = []
        })
      },
      // 编辑回显
      async editShow(detail) {
        this.productType = detail.standardCustomized
        this.toFixedNumber = detail.oldFlag
          ? 3
          : detail.priceScale
            ? detail.priceScale
            : 2
        this.priceRate = detail.priceRate
        this.calcFreight = detail.calcFreight
        if (
          detail.printingWaySelect === 'Blank' &&
          detail.printingWaySelectEn === 'Blank'
        ) {
          this.plantPrintWay = ['']
        } else if (
          detail.printingWaySelectId &&
          detail.printingWaySelect &&
          detail.printingWaySelectEn &&
          detail.waySelect &&
          detail.waySelectEn &&
          detail.waySelectId
        ) {
          this.plantPrintWay = [detail.printingWaySelectId, detail.waySelectId]
        } else {
          this.plantPrintWay = []
        }
        // 获取数量阶梯
        if (detail.oldFlag) {
          // 老数据
          this.getNetPriceListFn(detail.productPricingStepVOList || [])
        } else {
          this.getNetPriceListFn(detail.productLadderPriceVOList || [])
        }
      },

      // 给规格赋值选中
      setSpecList() {
        this.specsList = this.specsList.map((it) => {
          return {
            ...it,
            choose: '',
          }
        })
      },
      validateField() {
        let success = false
        if (this.productType === '标准品' && !this.plantPrintWay.length) {
          success = false
          // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
          this.$nextTick(() => {
            elFormErrorScrollIntoView('.pro-price')
          })
          this.$baseMessage(
            this.$t('productDetail.PlatformPriceIsRequired'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          const { waySelectId, printingWaySelectId, printingWaySelect } =
            this.getParamsData()
          if (
            (!printingWaySelectId || !waySelectId) &&
            printingWaySelect != 'Blank' &&
            this.productType === '标准品'
          ) {
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.pro-price')
            })
            this.$baseMessage(
              this.$t('productDetail.PlatformPriceIsRequired'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            success = true
          }
        }
        return {
          success,
          ...this.getParamsData(),
        }
      },
      validateField2() {
        return {
          ...this.getParamsData(),
        }
      },
      // 获取保存参数
      getParamsData() {
        if (!this.plantPrintWay || !this.plantPrintWay.length) {
          return {
            calcFreight: this.calcFreight,
            specsSelect: '',
            specsSelectEn: '',
            waySelectId: '',
            waySelect: '',
            waySelectEn: '',
            printingWaySelect: '',
            printingWaySelectEn: '',
            printingWaySelectId: '',
            productPrintingPricingId: '',
          }
        } else {
          if (this.plantPrintWay[0] == '') {
            return {
              calcFreight: this.calcFreight,
              specsSelect: '',
              specsSelectEn: '',
              waySelectId: '',
              waySelect: '',
              waySelectEn: '',
              printingWaySelect: 'Blank',
              printingWaySelectEn: 'Blank',
              printingWaySelectId: '',
              productPrintingPricingId: '',
            }
          } else {
            let printingWayObj = this.printOptions.filter(
              (item) => item.value === this.plantPrintWay[0]
            )?.[0]
            let waySelectObj = printingWayObj?.children.filter(
              (item) => item.value === this.plantPrintWay[1]
            )?.[0]
            if (!printingWayObj || !waySelectObj) {
              printingWayObj = {}
              waySelectObj = {}
            }
            let printingWaySelect = printingWayObj?.label ?? ''
            let printingWaySelectEn = printingWayObj?.labelEn ?? ''
            let printingWaySelectId = printingWayObj?.value ?? ''
            let waySelect = waySelectObj?.label ?? ''
            let waySelectEn = waySelectObj?.labelEn ?? ''
            let waySelectId = waySelectObj?.value ?? ''
            return {
              calcFreight: this.calcFreight,
              specsSelect: '',
              specsSelectEn: '',
              waySelectId,
              waySelect,
              waySelectEn,
              printingWaySelect,
              printingWaySelectEn,
              printingWaySelectId,
              productPrintingPricingId: printingWaySelectId,
            }
          }
        }
      },
    },
    beforeDestroy() {
      this.$Bus.$off('printList')
      this.$Bus.$off('bcglXiangXiList')
      this.$Bus.$off('extraSpecPrice')
      this.$Bus.$off('setProductType')
      this.$Bus.$off('changePriceScale')
      this.$Bus.$off('changeCategoryId')
    },
  }
</script>
<style lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }

    .content {
      height: 100%;
      border: 1px solid #e4e7ed;
      border-radius: 3px;

      .content-head {
        height: 50px;
        line-height: 50px;
        background-color: #f2f2f2;
        width: 100%;
        padding-left: 10px;
      }
    }

    .cascader-demo {
      .el-input__inner::placeholder {
        color: #606266;
      }
    }
  }
</style>