<template>
  <div class="p10 add-box">
    <div class="add-box-header">
      <el-page-header @back="
        () => {
          $router.go(-1)
        }
      " content="新增盘点" class="page-header-box" title="返回"></el-page-header>
      <div class="tip">重要提示：盘点前请将盘点仓库的所有待出库单据处理完毕。</div>
    </div>
    <el-form :model="addForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="盘点仓库" prop="warehouseId">
            <el-select v-model="addForm.warehouseId" placeholder="请选择" @change="changeWare" class="w100">
              <el-option v-for="item in warehouseList" :key="item.warehouseId" :label="item.name"
                :value="item.warehouseId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="盘点人">
            <UserSelect ref="userSelect2" @user-choose="chooseUser" style="width: 100%"></UserSelect>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="创建人">
            <el-input v-model="addForm.creater" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="盘点方式" prop="takingRange">
            <el-select v-model="addForm.takingRange" placeholder="请选择" class="w100">
              <el-option v-for="item in takingRangeList" :key="item.code" :label="item.name"
                :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="库存分类" prop="stockUsableType" v-if="addForm.takingRange === 1">
            <el-select v-model="addForm.stockUsableType" placeholder="请选择" class="w100">
              <el-option v-for="item in stockUsableTypeList" :key="item.code" :label="item.name"
                :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="mt10">
        <el-col :span="18">
          <el-form-item label="备注">
            <el-input type="textarea" v-model="addForm.remark" :autosize="{ minRows: 2, maxRows: 4 }" maxlength="500"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="mt10" v-if="showProduct">
      <h4 class="mt10">盘点产品</h4>
      <el-button @click="selPro" class="pro-button">选择产品</el-button>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column align="center" label="产品图片" prop="" fixed="left">
          <template slot-scope="scope">
            <erp-image :imgs="scope.row.productImage"></erp-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="产品编码" prop="skuId" fixed="left"></el-table-column>
        <el-table-column align="center" label="产品名称" prop="productName" fixed="left"></el-table-column>
        <el-table-column align="center" label="规格" prop="productSpec" fixed="left"></el-table-column>
        <el-table-column align="center" label="单位" prop="productUnit"></el-table-column>
        <el-table-column align="center" label="产品类别" prop="productType"></el-table-column>
        <el-table-column align="center" label="是否可用库存" prop="usable">
          <template slot-scope="scope">
            {{ scope.row.usable == 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="库位" prop="fullLocationNumber"></el-table-column>
        <el-table-column align="center" label="库位数" prop="num"></el-table-column>
        <el-table-column align="center" label="可用量" prop="enableNum">
          <template slot="header">
            <div>
              <span>可用库位数</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  包含该仓库所有销售出库待出库数量、锁定量及该库位可用库存。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="不可用量" prop="disableNum" width="140">

          <template slot="header">
            <div>
              <span>不可用库位数</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  该库位不可用库存。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
              <el-button type="danger" plain icon="el-icon-delete-solid" circle size="mini"
                @click="deletePro(scope)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="oper-sticky">


      <el-button type="primary" class="mr20" @click="submit" :loading="loading">
        确认
      </el-button>
      <el-button class="ml10" @click="cancel">取消</el-button>
    </div>



    <ProductSelect :visible.sync="visible" @confirm="proSel" :warehouseId="addForm.warehouseId" />
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

  </div>
</template>
<script>
  import ProductSelect from './components/product-select'
  import UserChoose from '@/components/userChoose'
  import { mapGetters } from 'vuex'
  import { mixin } from './help/mixins'
  import { StoreCheck } from '@/core'
  import UserSelect from '@/components/user-select'
  import { deepCopy } from 'kits'
  import _ from 'lodash'
  export default {
    components: { ProductSelect, UserChoose, UserSelect },
    mixins: [mixin],
    data() {
      return {
        addForm: {
          warehouseId: '',
          warehouseName: '',
          takingUserId: '',
          takingUser: '',
          takingRange: 0, //盘点方式
          stockUsableType: 1, //库存分类
          remark: '',
        },
        rules: {
          warehouseId: [
            { required: true, message: '请选择', trigger: 'change' },

          ],
          takingRange: [
            { required: true, message: '请选择', trigger: 'change' },

          ],
          stockUsableType: [
            { required: true, message: '请选择', trigger: 'change' },

          ],
        },
        visible: false,
        tableData: [],
        loading: false,
        takingRangeList: Object.freeze([{
          code: 1,
          name: '全部盘点'
        }, {
          code: 0,
          name: '部分盘点'
        }]),
        stockUsableTypeList: Object.freeze([{
          code: 1,
          name: '可用库存'
        }, {
          code: 0,
          name: '不可用库存'
        }, {
          code: 2,
          name: '全部库存'
        }]),
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      // 是否展示选择产品按钮和表格
      showProduct() {
        return this.addForm.takingRange === 0
      }
    },
    created() {
      this.addForm.creater = this.userInfo.userName
      this.addForm.takingUser = this.userInfo.userName
      this.addForm.takingUserId = this.userInfo.userId
      this.getWarehouseList()
    },
    methods: {

      // 选择产品
      selPro() {
        if (this.addForm.warehouseId == '') {
          this.$message.warning('请先选择盘点仓库！')
          return
        }
        this.visible = true
      },
      // 选择产品确认
      proSel(products) {
        products = deepCopy(products)

        // 根据产品id，库位id，是否可用来判断 合并
        let new_product = this.tableData.concat(products)
        let arr = new_product.map((it) => {
          return {
            ...it,
            tag: `${it.skuId}-${it.warehouseLocationId}-${it.usable}`,
          }
        })
        let uniqueData = _.unionBy(arr, 'tag')
        let tableData = []
        uniqueData = uniqueData.sort((a, b) => a.skuId - b.skuId)
        this.tableData = uniqueData
      },
      // 选择人员
      choosePeople() {
        this.$refs.UserChoose.showAddEdit('1', '', true, 0)
      },
      // 选择仓库
      changeWare() {
        this.clearData()
        let obj = this.warehouseList.find(
          (it) => it.warehouseId === this.addForm.warehouseId
        )
        this.addForm.warehouseName = obj.name
      },
      // 删除仓库，清空产品列表
      clearData() {
        this.addForm.warehouseName = ''
        this.tableData = []
      },
      // 删除产品
      deletePro(scope) {
        this.tableData.splice(scope.$index, 1)
      },
      // 保存
      async submit() {
        if (this.tableData.length == 0 && this.addForm.takingRange === 0) {
          this.$message.warning('请选择产品')
          return
        }
        this.loading = true
        try {
          let dt = {
            ...this.addForm,
            stockTakingProducts: this.tableData,
          }
          let res = await StoreCheck.insertStockPO(dt)
          if (res?.code === '000000') {
            this.$message.success('新增成功')
            this.cancel()
          }
        } finally {
          this.loading = false
        }
      },
      // 取消
      cancel() {
        this.$router.push('/stock-center/stock-check/index')
      },
      // 选择人员
      chooseUser(user) {
        this.addForm.takingUser = user ? user.userName : ''
        this.addForm.takingUserId = user ? user.userId : ''
      },
    },


  }
</script>
<style lang="scss" scoped>
  .oper-sticky {
    width: calc(100% - 105px);
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #ccc;
    height: 80px;
    align-items: center;
    background-color: #fff;
  }

  .pro-button {
    border-color: rgb(40, 130, 255);
    color: rgb(40, 130, 255);
    margin: 15px 0;
  }

  .add-box {
    overflow: hidden;
    overflow-y: scroll;
    margin-bottom: 100px !important;

    height: calc(100vh - 145px) !important;
    .add-box-header {
      display: flex;
      align-items: center;
      .tip {
        margin-left: 40px;
        margin-bottom: 20px;
        color: red;
        font-size: 16px;
      }
    }
  }
</style>