var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialogFormVisible)?_c('el-dialog',{attrs:{"title":_vm.title,"append-to-body":true,"visible":_vm.dialogFormVisible,"width":"600px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" 确认 ")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("取消")])],1)]},proxy:true}],null,false,3305536302)},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"130px"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"印刷条件（CN）","prop":"nameCn","rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsInput'),
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.nameCn),callback:function ($$v) {_vm.$set(_vm.form, "nameCn", $$v)},expression:"form.nameCn"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"印刷条件（EN）","prop":"nameEn","rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              validator: function (rule, value, callback) {
                if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
                  callback('不能输入中文字符')
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.nameEn),callback:function ($$v) {_vm.$set(_vm.form, "nameEn", $$v)},expression:"form.nameEn"}})],1)],1)],1),_c('el-row',{staticStyle:{"max-height":"400px","overflow-y":"auto"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.form.tableData,"border":""}},[_c('el-table-column',{attrs:{"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{staticStyle:{"margin":"20px 10px"},attrs:{"prop":'tableData.' + scope.$index + '.value',"label-width":"0","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{on:{"change":function($event){return _vm.pricingMethodChange($event, scope.row, 1)}},model:{value:(scope.row.value),callback:function ($$v) {_vm.$set(scope.row, "value", $$v)},expression:"scope.row.value"}})],1)]}}],null,false,1619815751)},[_c('template',{slot:"header"},[_c('i',{staticStyle:{"color":"#ff4d4f"}},[_vm._v("*")]),_vm._v(" 计价方式（CN） ")])],2),_c('el-table-column',{attrs:{"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{staticStyle:{"margin":"20px 10px"},attrs:{"prop":'tableData.' + scope.$index + '.valueEn',"label-width":"0","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                },
                {
                  validator: function (rule, value, callback) {
                    if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
                      callback('不能输入中文字符')
                    } else {
                      callback()
                    }
                  },
                  trigger: 'blur',
                } ]}},[_c('el-input',{on:{"change":function($event){return _vm.pricingMethodChange($event, scope.row, 2)}},model:{value:(scope.row.valueEn),callback:function ($$v) {_vm.$set(scope.row, "valueEn", $$v)},expression:"scope.row.valueEn"}})],1)]}}],null,false,3561106792)},[_c('template',{slot:"header"},[_c('i',{staticStyle:{"color":"#ff4d4f"}},[_vm._v("*")]),_vm._v(" 计价方式（EN） ")])],2),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"120","resizable":false},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"content":"删除","placement":"top"}},[_c('el-button',{attrs:{"type":"danger","plain":"","icon":"el-icon-delete","circle":"","size":"mini"},on:{"click":function($event){return _vm.handleClose(scope.$index)}}})],1)]}}],null,false,1157447433)})],1)],1),_c('el-row',[_c('el-col',{staticClass:"add-btn"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.addClick}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 添加计价方式 ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }