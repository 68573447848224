<template>
  <div class="table-container" ref="mallOrder">
    <el-row class="page-header" type="flex">
      <!-- 查询表单 -->
      <el-form ref="form" :inline="true" :model="queryForm" @submit.native.prevent :label-width="lang === 'en' ? '160px' : '100px'">
        <el-form-item :label="$t('orderDetails.MallOrderNumber') + '：'">
          <el-input v-model="queryForm.mallOrderCode" clearable class="pub-h-36" />
        </el-form-item>
        <el-form-item :label="$t('orderDetails.CompanyName') + '：'">
          <el-input v-model="queryForm.companyName" clearable class="pub-h-36" />
        </el-form-item>
        <el-form-item :label="$t('orderDetails.PayType') + '：'">
          <el-select v-model="queryForm.payType" multiple clearable>
            <el-option label="Credit Card" value="0" />
            <el-option label="Paper Check" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('orderDetails.OrderTime') + '：'">
          <el-date-picker
            v-model="queryForm.orderTime"
            type="datetimerange"
            :start-placeholder="$t('Documentary.SD')"
            :end-placeholder="$t('Documentary.ED')"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="searchClick" class="pub-h-36">
        {{ $t('orderDetails.Search') }}
      </el-button>
    </el-row>
    <el-row class="ml10">
      <el-col>
        <el-radio-group v-model="queryForm.handleFlag" @change="handleFlagChangeFn">
          <el-radio label="">{{ $t('orderDetails.All') }}</el-radio>
          <el-radio :label="0">{{ $t('orderDetails.NotProcessed') }}</el-radio>
          <el-radio :label="1">{{ $t('orderDetails.Processed') }}</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <!-- 表格 -->
    <div class="main-table mt20">
      <el-row :style="{ height: `${maxHeight}px` }" class="mt10" style="overflow-y: auto">
        <el-table :data="tableData" style="width: 100%" border ref="testTable" class="test-table">
          <el-table-column prop="mallOrderCode" :label="$t('orderDetails.MallOrderNumber')"
            align="center"></el-table-column>
          <el-table-column prop="contractDate" :label="$t('orderDetails.OrderDate')" align="center" min-width="100"></el-table-column>
          <el-table-column :label="$t('orderDetails.ProductNumber')" align="center">
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="dark" :content="scope.row.productCode">
                <p class="product">{{ scope.row.productCode }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('orderDetails.PayType')" align="center">
            <template slot-scope="scope">
              <p class="product">
                {{ scope.row.payType == 0 ? 'Credit Card' : 'Paper Check' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column :label="$t('orderDetails.ProductName')" align="center">
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="dark" :content="scope.row.productEn">
                <p class="product">{{ scope.row.productEn }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('orderDetails.TotalAmount')" align="center">
            <template slot-scope="scope">
              <span>${{ scope.row.totlePrice | orderMoneyFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('orderDetails.CustomerRepresentative')"
            align="center">
            <template slot-scope="scope">
              {{ lang === 'en' ? scope.row.businessNameEn : scope.row.businessName }}
            </template>
          </el-table-column>
          <el-table-column min-width="140px" prop="companyName" :label="$t('orderDetails.CompanyName')"
            align="center"></el-table-column>
          <el-table-column prop="handleFlag" :label="$t('orderDetails.Status')" align="center">
            <template slot-scope="scope">
              <span>
                {{
                scope.row.handleFlag === 1
                ? $t('orderDetails.Processed')
                : $t('orderDetails.NotProcessed')
                }}
              </span>
              <el-tooltip placement="top" effect="dark" v-if="scope.row.payType == 1 && scope.row.mallOrderStatus == 2 && scope.row.handleFlag == 1 && scope.row.rejectRemark">
                <div slot="content">
                  {{ scope.row.rejectRemark }}
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
              <el-tooltip placement="top" effect="dark" v-if="[1, 2].includes(scope.row.rendingFlag) && scope.row.handleFlag == 0">
                <div slot="content">
                  <span v-if="scope.row.rendingFlag == 1">
                    {{$t('orderDetails.NoArtWork')}}
                  </span>

                  <span v-else-if="scope.row.rendingFlag == 2">
                    {{$t('orderDetails.toConfirm')}}
                  </span>
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column :label="$t('AddOrder.Operation')" align="center" width="180">
            <template slot-scope="scope">
              <!-- handleFlag：订单是否进行过处理（1是；0否） -->
              <template v-if="scope.row.handleFlag === 1 && scope.row.viewMallOrder == 1">
                <!-- mallOrderStatus：商城订单状态（1：正常；2：驳回） -->
                <span v-if="scope.row.mallOrderStatus == 2">--</span>
                <router-link
                  v-else
                  :to="`/order/orderList/orderDetail?orderCode=${scope.row.salesOrderCode}&orderId=${scope.row.orderId}`">
                  <el-button type="text">
                    {{ $t('orderDetails.CheckOrder') }}
                  </el-button>
                </router-link>
              </template>
              <el-button
                v-else-if="
                  scope.row.handleFlag === 0 && scope.row.operateMallOrder == 1
                "
                type="text"
                @click="handleDealWith(scope.row)"
              >
                <!-- 处理 -->
                {{ $t('orderDetails.DealWith') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <Pagination v-if="tableData && tableData.length" :limit.sync="queryForm.pageLe" :page.sync="queryForm.pageNo"
        :total="queryForm.total" @pagination="fetchData" />
    </div>
    <!-- 纸质支票审核弹框 -->
    <paperCheckDialog v-model="paperCheckDialogVisible" :row="paperCheckDialogRow" @refresh="fetchData" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import paperCheckDialog from './components/paperCheckDialog'
  import { MallOrderInteractor } from '@/core'
  import {
    mapGetters
  } from 'vuex'
  export default {
    name: 'MallOrder',
    data() {
      return {
        tableData: [],
        queryForm: {
          mallOrderCode: '',
          companyName: '',
          payType: [], // 支付方式
          orderTime: null, // 下单时间
          handleFlag: '',
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        maxHeight: 0,
        paperCheckDialogVisible: false,
        paperCheckDialogRow: {},
      }
    },
    computed:{
      ...mapGetters({
        lang: 'settings/language'
      }),
    },
    components: {
      Pagination,
      paperCheckDialog,
    },
    created() {
      if(this.$route.query.handleFlag) {
        this.queryForm.handleFlag = this.$route.query.handleFlag * 1
      }
      this.initTableStyle()

      this.fetchData()
    },
    methods: {
      initTableStyle() {
        this.$nextTick(() => {
          const baseTableEle = this.$refs['mallOrder']
          const maxHeight = baseTableEle.offsetHeight - 212
          this.maxHeight = maxHeight
        })
      },
      searchClick() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData(pageData) {
        if (pageData) {
          this.queryForm.pageNo = pageData.page
          this.queryForm.pageLe = pageData.limit
        }
        const data = {
          mallOrderCode: this.queryForm.mallOrderCode,
          companyName: this.queryForm.companyName,
          payType: this.queryForm.payType,
          orderTime: this.queryForm.orderTime,
          handleFlag: this.queryForm.handleFlag,
          pageNo: this.queryForm.pageNo,
          pageLe: this.queryForm.pageLe,
        }
        const res = await MallOrderInteractor.listPageMallOrderApi(data)
        if (res.code === '000000') {
          this.queryForm.total = res.data.total
          this.tableData = res.data.data
        }
      },
      handleFlagChangeFn(val) {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      // 处理
      handleDealWith(row) {
        if (row.payType == 0) {
          // CC
          this.$router.push(`/order/mallOrder/dea-with?id=${row.id}`)
        } else {
          // PC
          this.paperCheckDialogVisible = true
          this.paperCheckDialogRow = row
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep.table-container {
    .page-header {
      padding-top: 10px;
    }
    .product {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
