<template>
  <div>
    <el-tag v-if="isTag" @click="showDrawer" :type="type" class="pointer">
      {{ text }}
    </el-tag>
    <span v-else @click="showDrawer" :style="computedStyle" class="pointer">
      {{ text }}
    </span>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'AuditProcess',
    props: {
      type: {
        type: String,
        default: 'primary',
      },
      text: {
        type: String,
      },
      isTag: {
        type: Boolean,
        default: true,
      },
      tagType: {
        type: String,
        default: 'primary',
      },
      auditParams: {
        type: Object,
        default: () => ({}),
      },
      showProcess: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      computedStyle() {
        let color
        switch (this.type) {
          case 'primary':
            color = 'rgb(64, 158, 255)'
            break
          case 'success':
            color = '#67C23A'
            break
          case 'warning':
            color = '#E6A23C'
            break
          case 'danger':
            color = '#F56C6C'
            break
          case 'info':
            color = '#909399'
            break
        }
        return {
          color,
        }
      },
    },

    data() {
      return {
        statusDrawer: false,
      }
    },

    mounted() {},

    methods: {
      ...mapActions({
        setAuditProcess: 'audit/setAuditProcess',
        setDrawer: 'audit/setDrawer',
      }),
      showDrawer() {
        if (!this.showProcess) return
        this.setAuditProcess(this.auditParams)
        this.setDrawer(true)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }
</style>
