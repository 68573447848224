<template>
  <el-dialog title="录入银行流水号" :visible.sync="dialogBankNumberVisible" width="75%" @close="close" top="5vh"
    :close-on-click-modal="false">
    <el-row class="dialog-content">
      <el-row v-for="(item, index) in pageInfo" :key="index">
        <el-row class="mb30">
          <!-- 收款单详情表格 -->
          <div class="c-table-wrap">
            <table class="custom-table">
              <thead>
                <tr>
                  <th style="width: 50px">#</th>
                  <th>收款单号</th>
                  <th>收款来源</th>
                  <th>付款方</th>
                  <th>收款状态</th>
                  <th>收款方式</th>
                  <!-- <th>出口抬头</th> -->
                  <th>票据单号</th>
                  <th>币种</th>
                  <th>收款金额</th>
                  <th>收款日期</th>
                  <th>银行入账日期</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>
                      {{ index + 1 }}
                    </span>
                  </td>
                  <td>{{ item.financeCode || '--' }}</td>
                  <td>
                    <span v-if="item.financeType === 1 && item.entryType === 1">
                      销售退款
                    </span>
                    <span v-else>
                      {{ item.financeType === 1 ? '销售订单' : '其他费用' }}
                    </span>
                  </td>
                  <td>{{ item.payee || '--' }}</td>
                  <td>
                    <el-tag :type="item.financeStatus | statusFilter" plain size="small">
                      {{ item.financeStatus | statusTextFilter }}
                    </el-tag>
                  </td>
                  <td>{{ item.financeMethod || '--' }}</td>
                  <!-- <td>{{ item.exportsRise || '--' }}</td> -->
                  <td>{{ item.ticketNumber || '--' }}</td>
                  <td>{{ item.currency || '--' }}</td>
                  <td>
                    <span v-if="item.entryType == 1" style="color: red">
                      -{{ item.amountCollected || '--' }}
                    </span>
                    <span v-else class="c_success f_w_6">
                      {{ item.amountCollected |orderMoneyFormat }}
                    </span>
                  </td>
                  <td>{{ item.amountTime || '--' }}</td>
                  <td>{{ item.bankAcceptanceTime || '--' }}</td>
                  <td class="text-center">
                    <el-button type="danger" plain icon="el-icon-delete" circle
                      @click="deleteCurrent(item, index)"></el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- 订单详情表格 -->
          <!-- <p class="p1">{{ index+1>9?index+1:"0"+(index+1) }}</p> -->
          <el-table :data="item.orderSerialVOS" style="width: 100%" class="mb10" border v-if="item.orderSerialVOS">
            <el-table-column label="#" align="center" type="index" width="50"></el-table-column>
            <el-table-column prop="orderCode" label="订单编号" align="center"></el-table-column>
            <el-table-column prop="customerName" label="客户姓名" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="companyName" label="公司名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="businessName" label="客户代表" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="area" label="所属地区" align="center"></el-table-column>
            <el-table-column prop="currency" label="币种" align="center"></el-table-column>
            <el-table-column prop="totlePrice" label="订单金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.totlePrice | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="outstandingAmount" label="未收金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.outstandingAmount | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="amountCollected" label="收款金额" align="center">
              <template slot-scope="scope">
                <div :style="item.entryType == 1 ? 'color:red' : ''">
                  <span v-if="item.entryType == 1">-</span>
                  <span>{{ scope.row.amountCollected | orderMoneyFormat}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="commissionAmount" label="手续费金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.commissionAmount | orderMoneyFormat }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-row>

      <el-row class="mt20" :gutter="20">
        <el-form :model="bankNumberForm" :rules="bankNumberRules" ref="bankNumberForm" @close="close">
          <el-col :span="8">
            <el-form-item label="银行流水号" prop="serialNumber" label-width="100px">
              <el-input v-model.trim="bankNumberForm.serialNumber" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收款总金额" prop="totlePrice" label-width="100px">
              <span v-if="pageInfo[0] && pageInfo[0].entryType == 1" style="color: red">
                -{{ totlePrice | orderMoneyFormat }}
              </span>
              <span class="f_w_6 c_success" v-else>
                {{ totlePrice | orderMoneyFormat}}
              </span>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </el-row>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { listSerialVOS, updateSerialVOS } from '@/api/finance/credit-note-add'
  export default {
    name: 'EnterBankNumberModel',
    data() {
      return {
        dialogBankNumberVisible: false,
        pageInfo: '',
        bankIds: [],
        bankNumberForm: {
          serialNumber: '', // 银行流水号
        },
        totlePrice: '', // 收款总金额
        bankNumberRules: {
          serialNumber: [
            { required: true, message: '请输入银行流水号', trigger: 'blur' },
          ],
        },
      }
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: 'warning',
          1: 'warning',
          2: 'danger',
          3: 'danger',
          4: 'success',
          5: 'info',
          6: 'warning',
          7: 'danger',
          8: 'danger',
          9: 'info',
        }
        return statusMap[status]
      },
      statusTextFilter(status) {
        const statusTextMap = {
          1: '待处理',
          2: '待认领',
          3: '已驳回',
          4: '已完成',
          5: '已作废',
          6: '已认领',
          7: '待确认',
          8: '审核中',
          9: '审核驳回',
          10: '已撤销',
        }
        return statusTextMap[status]
      },
    },
    methods: {
      showBankNumber(ids) {
        this.bankIds = []
        this.bankIds = ids
        this.dialogBankNumberVisible = true
        listSerialVOS({
          financeIds: ids,
          serialNumber: '',
        }).then((response) => {
          if (response.code === '000000') {
            this.pageInfo = response.data.serialVOS
            this.totlePrice = response.data.totlePrice
          }
        })
      },
      // 删除当前行
      deleteCurrent(item, index) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.pageInfo.splice(index, 1)
            this.bankIds.splice(index, 1)
            let money = 0
            this.pageInfo.forEach((item) => {
              money += item.amountCollected
            })
            this.totlePrice = money
          })
          .catch(() => { })
      },
      close() {
        // this.$refs['bankNumberForm'].resetFields()
        this.bankNumberForm.serialNumber = ''
        this.$emit('updateList')
        this.dialogBankNumberVisible = false
      },
      // 保存录入银行流水号
      async saveSerialVOS() {
        let response = await updateSerialVOS()
      },
      save() {
        this.$refs['bankNumberForm'].validate(async (valid) => {
          if (valid) {
            updateSerialVOS({
              financeIds: this.bankIds,
              serialNumber: this.bankNumberForm.serialNumber,
            }).then((response) => {
              if (response.code === '000000')
                this.$message.success('录入银行流水号成功！')
              this.$emit('updateList')
            })
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;

    >.el-row {
      width: 98%;
      margin: 0 auto;
    }

    ::v-deep {
      .el-dialog__body {
        padding: 10px 20px 30px;
      }

      .el-dialog__title {
        font-size: 16px;
      }
    }
  }
</style>
