<template>
  <div class="msg-box">
    <el-button size="mini" class="ml10" @click="showPlantInfo">
      <!-- 平台信息 -->
      {{ $t('productDetail.PlatformInfo') }}
    </el-button>

    <el-dialog
      :title="$t('productDetail.PlatformInfo')"
      :visible.sync="visible"
      width="800px"
      :before-close="handleClose"
    >
      <div>
        <div>
          <el-button
            size="mini"
            class="mr10"
            @click="oper('1')"
            :disabled="!showUp"
          >
            <!-- 上架 -->
            {{ $t('productDetail.Activate') }}
          </el-button>
          <el-popover placement="bottom" width="250" v-model="operVisible">
            <div style="text-align: right; margin: 0">
              <!-- 填写下架原因 -->
              <el-input
                type="textarea"
                :rows="2"
                :placeholder="$t('productDetail.FillInActivatedReason')"
                maxlength="500"
                v-model="removeReason"
              ></el-input>
              <div class="mt10">
                <el-button size="mini" type="text" @click="cancelOper">
                  <!-- 取消 -->
                  {{ $t('productDetail.Cancel') }}
                </el-button>
                <el-button type="primary" size="mini" @click="oper('2')">
                  <!-- 确定 -->
                  {{ $t('productDetail.Confirm') }}
                </el-button>
              </div>
            </div>
            <el-button
              size="mini"
              class="mr10"
              slot="reference"
              :disabled="!showDown"
            >
              <!-- 下架 -->
              {{ $t('productDetail.Deactivate') }}
            </el-button>
          </el-popover>
        </div>
        <el-table
          border
          class="mt20"
          :data="tableData"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :selectable="checkSelectable"
          ></el-table-column>
          <!-- 平台名称 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.PlatformName')"
            prop="platformName"
            :selection="false"
          >
            <template slot-scope="scope">
              {{
                settings === 'en'
                  ? scope.row.platformEnName
                  : scope.row.platformName
              }}
            </template>
          </el-table-column>
          <!-- 当前状态 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.CurrentStatus')"
          >
            <template slot-scope="scope">
              {{ typeFilter(scope.row.type) }}
            </template>
          </el-table-column>
          <!-- 最近操作时间 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.LastOperationTime')"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime || '--' }}
            </template>
          </el-table-column>
          <!-- 最近下架原因 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.RecentDeactivateReason')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.removeReason || '--' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { ProductViewInteractor } from '@/core/interactors/product/productView'
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
        visible: false,
        tableData: [],
        selects: [],
        operVisible: false,
        removeReason: '',
        that: this,
      }
    },
    props: {
      productId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      // 上架
      showUp() {
        let downs = this.selects.filter((it) => {
          return it.type == 2 || it.type == null
        })
        return this.selects.length > 0 && downs.length == this.selects.length
      },
      // 下架
      showDown() {
        let ups = this.selects.filter((it) => {
          return it.type == 1 || it.type == null
        })
        return ups.length == this.selects.length && this.selects.length > 0
      },
    },
    methods: {
      typeFilter(val) {
        let obj = {
          1: this.settings === 'zh' ? '已上架' : 'Activated',
          2: this.settings === 'zh' ? '已下架' : 'Deactivated',
        }
        // 未上传
        return obj[val] || this.$t('productDetail.NotLaunched')
      },
      handleClose() {
        this.visible = false
        this.$emit('getList')
      },
      // 展示平台信息
      showPlantInfo() {
        this.visible = true
        this.getList(this.productId)
      },
      async getList(productId) {
        let res = await ProductViewInteractor.getProductPlatform(productId)
        if (res?.data?.length) {
          let platformCodeArr = ['SAGE', 'RP', 'RG', 'SW']
          res.data.map((item) => {
            if (platformCodeArr.includes(item.platformCode)) {
              item.nonSelectable = true
            }
            return item
          })
          this.tableData = res.data.filter(
            (item) => item.platformCode != 'website'
          )
        } else {
          this.tableData = []
        }
      },
      // 选择
      handleSelectionChange(val) {
        this.selects = val
      },
      // 上 下 架
      async oper(type) {
        let plats = this.selects.map((it) => it.platformCode)
        if (type == 2 && this.removeReason == '') {
          // 请输入下架原因
          this.$message.warning(this.$t('productDetail.FillInActivatedReason'))
          return
        }

        let res = await ProductViewInteractor.setProductPlatform({
          type,
          productId: this.productId,
          platformCodes: plats.toString(),
          removeReason: this.removeReason,
        })
        if (res.code == '000000') {
          // 操作成功
          this.$message.success(this.$t('reqmsg.$7'))
          this.getList(this.productId)
          this.cancelOper()
        }
      },
      cancelOper() {
        this.operVisible = false
        this.removeReason = ''
      },
      checkSelectable(row) {
        // 如果row.nonSelectable是true，则这一行不可选中
        return !row.nonSelectable
      },
    },
  }
</script>
<style lang="scss" scoped>
  .msg-box {
    display: inline-block;
  }
</style>
