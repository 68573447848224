<template>
  <el-dialog title="上传图片" :visible.sync="visible" :before-close="handleClose" width="815px">
    <erp-upload-images :default-file-list="defaultFileList" @change="changeUploadList" v-if="visible"
      :compress="true"></erp-upload-images>
    <el-row slot="footer" class="dialog-footer text-center">
      <el-button type="primary" @click="confirmChoose">确认</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
  export default {
    name: 'chooseImages',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      // 默认回显图片
      defaultFileList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        imageList: [],
      }
    },
    watch: {
      visible(newVal) {
        if (newVal) {
          this.imageList = JSON.parse(JSON.stringify(this.defaultFileList))
        }
      },
    },
    methods: {
      // 关闭 dialog
      handleClose() {
        this.$emit('update:visible', false)
      },
      // 上传印刷效果图
      changeUploadList(list) {
        this.imageList = list
      },
      // 格式化数据
      formatData() {
        let __urls = []
        const __imageList = this.imageList

        for (let i = 0; i < __imageList.length; i++) {
          __urls.push(__imageList[i].url)
        }
        return {
          urls: __urls.toString(),
        }
      },
      // 确认
      confirmChoose() {
        this.handleClose()
        this.$emit('confirm', this.formatData())
      },
    },
  }
</script>
<style scoped lang="scss">
  .wrapper-status {
    margin-top: 10px;
  }
</style>