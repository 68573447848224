<template>
  <div class="bom">
    <el-row :gutter="20">
      <el-col :span="8" v-for="(item, index) in bomData" :key="index + 100">
        <el-card class="bom-item">
          <p class="bom-name">
            <erp-image
              :imgs="item.parentUrl"
              style="width: 40px; height: 40px; margin-right: 5px"
            ></erp-image>
            <span v-if="item.bomKey">{{ item.bomKey }}</span>
            <span v-if="item.parentSpecsValue">
              /{{ item.parentSpecsValue }}
            </span>
          </p>
          <el-timeline>
            <el-timeline-item
              placement="top"
              v-for="(childItem, i) in item.bomVal"
              :key="i"
              :timestamp="childItem.title"
            >
              <el-table
                :data="childItem.list"
                style="width: 100%"
                border
                align="center"
              >
                <!-- 产品编码 -->
                <el-table-column
                  prop="sku"
                  :label="$t('productDetail.ProductCode')"
                  width="80"
                  align="center"
                ></el-table-column>
                <!-- 产品图片 -->
                <el-table-column
                  prop="imgMain"
                  :label="$t('productDetail.ProductImage')"
                  width="80"
                  align="center"
                >
                  <template slot-scope="scope">
                    <erp-image
                      style="height: 40px; width: 40px"
                      :imgs="scope.row.img || scope.row.imgMain"
                    ></erp-image>
                  </template>
                </el-table-column>
                <!-- 产品名称 -->
                <el-table-column

                  :label="$t('productDetail.ProductName')"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{getName(scope.row, 'nameCn', 'nameEn')}}
                  </template>
                </el-table-column>
                <!-- 产品类型 -->
                <el-table-column
                  :label="$t('productDetail.ProductType')"
                  width="80"
                  align="center"
                >
                  <template slot-scope="scope">
                    <!-- 辅料 -->
                    <span v-if="scope.row.sonProductType == 1">
                      {{ $t('orderDetails.Excipients') }}
                    </span>
                    <span v-else>
                      {{
                        scope.row.standardCustomized === '标准品'
                          ? $t('productCenter.StandardProduct')
                          : scope.row.standardCustomized === '订制品'
                          ? $t('productCenter.CustomizedProduct')
                          : '--'
                      }}
                    </span>
                  </template>
                </el-table-column>
                <!-- 组合数量 -->
                <el-table-column
                  prop="numCombination"
                  :label="$t('productDetail.NumberOfCombinations')"
                  width="80"
                  align="center"
                ></el-table-column>
              </el-table>
            </el-timeline-item>
          </el-timeline>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { productBomData } from '@/api/order'
import nameMixin from '@/utils/name-mixin'
import _ from 'lodash'
export default {
  name: 'productBom',
  mixins: [nameMixin],
  data() {
    return {
      productCode: '',
      tableData: [],
      // bomData: [],
      bomDetail:{}
    }
  },
  computed:{
    bomData() {

      return this.setData(this.bomDetail)

    }
  },
  created() {
    this.productCode = this.$route.query.productCode
      ? this.$route.query.productCode
      : 0
    if (this.productCode) {
      this.getData()
    } else {
      this.bomData = []
    }
  },

  methods: {
    getData() {
      productBomData({ productCode: this.productCode }).then((res) => {
        this.bomDetail = res?.data
      })
    },
    setData(val) {
      let keys = []
      let values = []
      let newObjArr = []
      if(!val) {
        return
      }
      keys = Object.keys(val)
      values = Object.values(val)

      //将bomOrderId放进一个数组，去重，确定循环多少次
      let arr_2 = [] // 存放最终数组，
      let arr_1 = [] // 存放values 去重之后的数组
      values.forEach((ele, index) => {
        arr_1 = ele.map((item) => {
          return item.bomOrderId
        })
        // 数组去重
        arr_1 = _.uniq(arr_1)
        let arr_2_child = []
        arr_1.forEach((a, ai) => {
          let obj = {}
          let findArr = []
          // 找到bomOrderId 相同的数组 放在一起
          findArr = ele.filter((aitem) => {
            return aitem.bomOrderId == a
          })
          obj = {
            title: `${this.$t('productDetail.Creator')}：${this.getName(
              findArr[0],
              'creator'
            )} ${this.$t('productDetail.CreationTime')}：${
              findArr[0].createTime?.split(' ')[0]
            }`,
            list: findArr,
          }
          // 外面嵌套一层数组，二位数组
          arr_2_child[ai] = obj
        })
        arr_2.push(arr_2_child)
      })
      // 将需要的信息组合放在各个属性上面
      for (let i = 0; i < keys.length; i++) {
        let newObj = {
          bomKey: keys[i],
          bomVal: arr_2[i],
          parentSku: values[0][0].parentSku,
          parentSpecsValue: values[0][0].parentSpecsValue,
          parentUrl: values[i][0].parentUrl,
        }
        newObjArr.push(newObj)
      }
      return newObjArr
    },
  },
}
</script>
<style lang="scss" scoped>
.bom {
  min-width: 1440px;
  z-index: 1;
  .bom-item {
    border: 1px solid #ebeef5;
    min-width: 420px;

    padding: 0px 0px 0px 10px;
    margin-bottom: 20px;
    .bom-name {
      height: 50px;
      line-height: 50px;
      padding: 0px 10px;
      display: flex;
      align-items: center;
    }
    ::v-deep {
      .el-timeline {
        height: 450px;
        overflow-y: scroll;
      }
      .el-timeline-item__node {
        border: 1px solid #2882ff;
        background: #fff;
      }
      .el-timeline-item__node--normal {
        left: 0;
      }
    }
  }
}
::v-deep .el-image__inner {
  height: 40px !important;
  width: 40px !important;
}
</style>
