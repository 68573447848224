<template>
  <div class="tab-content">
    <el-button type="primary" @click="editRules()">
      <!-- 新增 -->
      {{ $t('boxsetting.Add') }}
    </el-button>
    <div
      class="account-group"
      v-for="(item, index) in accountList"
      :key="index"
    >
      <div class="title">
        <!-- 所属账号： -->
        {{ $t('boxsetting.AttributableAccount') }}: {{ item.emailAccount }}
      </div>
      <el-table border :data="ruleTable[item.emailAccount]">
        <!-- label="收信规则" -->
        <el-table-column
          :label="$t('boxsetting.MailReceivingRules')"
          prop="content"
        >
          <template slot-scope="scope">
            <!-- <div>
              总把&lt;{{ scope.row.content }}&gt;的邮件，移动到此文件夹&lt;{{
                scope.row.catagoryName
              }}&gt;
            </div> -->
            <div>
              <!-- 总把 -->
              {{ lang == 'en' ? 'Always move' : '总把' }}
              &lt;{{ scope.row.content }}&gt;
              <!-- 的邮件，移动到此文件夹 -->
              {{
                lang == 'en'
                  ? 'message to this folder：'
                  : '的邮件，移动到此文件夹：'
              }}
              &lt;{{ scope.row.catagoryName }}&gt;
            </div>
          </template>
        </el-table-column>
        <!-- label="状态" -->
        <el-table-column width="100" :label="$t('boxsetting.Status')">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 0">
              <!-- 已启用 -->
              {{ lang === 'en' ? 'Is enabled' : '已启用' }}
            </el-tag>
            <el-tag type="danger" v-else>
              <!-- 已禁用 -->
              {{ lang === 'en' ? 'Has been disabled' : '已禁用' }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- label="操作" -->
        <el-table-column width="100" :label="$t('boxsetting.Operate')">
          <template slot-scope="scope">
            <!-- content="禁用" -->
            <el-tooltip
              :content="lang === 'en' ? 'disable' : '禁用'"
              placement="top"
            >
              <el-button
                type="text"
                icon="el-icon-video-pause"
                @click="triggerRules(scope.row)"
                v-if="scope.row.status == 0"
              ></el-button>
            </el-tooltip>
            <!-- content="启用" -->
            <el-tooltip
              :content="lang === 'en' ? 'enable' : '启用'"
              placement="top"
            >
              <el-button
                type="text"
                icon="el-icon-video-play"
                @click="triggerRules(scope.row)"
                v-if="scope.row.status == 1"
              ></el-button>
            </el-tooltip>
            <!-- content="编辑" -->
            <el-tooltip :content="$t('iconbtn.edit')" placement="top">
              <el-button
                type="text"
                icon="el-icon-edit"
                @click="editRules(scope.row)"
                v-if="scope.row.status == 1"
              ></el-button>
            </el-tooltip>
            <!-- content="删除" -->
            <el-tooltip :content="$t('iconbtn.del')" placement="top">
              <el-button
                type="text"
                icon="el-icon-delete"
                @click="removeRules(scope.row)"
                v-if="scope.row.status == 1"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <EditRulesModel ref="EditRulesModel" @success="getMailRulesList" />
  </div>
</template>
<script>
  import {
    mailRulesList,
    mailRulesDelete,
    mailRulesTrigger,
  } from '@/api/mail/mail-setting'
  import EditRulesModel from '@/views/mail/setting/components/edit-rules-model'
  import { mapState, mapGetters } from 'vuex'
  import _ from 'lodash'
  export default {
    components: {
      EditRulesModel,
    },
    data() {
      return {
        ruleTable: {}, //规则列表
      }
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
        accountList: (state) =>
          state.mail.filter((item) => item.authStatus === null),
      }),
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    created() {
      this.getMailRulesList()
    },
    methods: {
      // 新增编辑规则
      editRules(row) {
        this.$refs.EditRulesModel.showDialog(row)
      },
      // 获取规则列表
      getMailRulesList() {
        mailRulesList({
          businessId: this.userInfo.userId,
        }).then((res) => {
          if (res.code == '000000') {
            let list = res.data
            let obj = _.groupBy(list, 'emailAccount') //根据相同邮箱，把数组转成对象，key是邮箱，value是数组
            this.ruleTable = obj
            // for (let key in obj) {
            //   let item = {}
            //   item['emailAccount'] = key
            //   item['children'] = obj[key]
            //   this.ruleTable.push(item)
            // }
          }
        })
      },
      //删除规则
      removeRules(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          mailRulesDelete({
            mailReceivingRulesId: row.mailReceivingRulesId,
          }).then((res) => {
            if (res.code == '000000') {
              this.$message.success('删除成功！')
              this.getMailRulesList()
            }
          })
        })
      },
      //启用、禁用
      triggerRules(row) {
        if (row.status == 1) {
          let params = {
            mailReceivingRulesId: row.mailReceivingRulesId,
            status: 0,
          }
          this.$confirm(
            `<div style="font-size:14px;color:#333"><i class="el-icon-warning" style="font-size:20px;color:#ffba00"></i> 是否立即执行此规则？</div>
              <p style="font-size:12px;color:#999;padding-left:20px;">若选择“确定”，系统将收件箱内的所有历史邮件都移动到指定文件夹内；若选择“取消”，历史邮件不移动，收到的新邮件会自动分到指定文件夹内。</p>`,
            '提示',
            {
              dangerouslyUseHTMLString: true,
            }
          )
            .then(() => {
              params['implement'] = 1
            })
            .catch((err) => {
              params['implement'] = 0
            })
            .finally(() => {
              mailRulesTrigger(params).then((res) => {
                if (res.code == '000000') {
                  this.$message.success('已启用')
                  this.getMailRulesList()
                }
              })
            })
        } else {
          mailRulesTrigger({
            mailReceivingRulesId: row.mailReceivingRulesId,
            status: 1,
          }).then((res) => {
            if (res.code == '000000') {
              this.$message.success('已禁用')
              this.getMailRulesList()
            }
          })
        }
      },
    },
  }
</script>
<style scoped lang="scss">
  .tab-content {
    overflow-y: auto;
    height: calc(100vh - 200px);
  }
  .account-group {
    margin-top: 20px;
    .title {
      font-size: 16px;
      color: #333;
      height: 40px;
      line-height: 40px;
    }
    .sign-content {
      width: 100%;
      overflow: hidden;
      ::v-deep img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
</style>
