<template>
  <el-dialog
    title="加工费用调整"
    :visible.sync="dialogVisible"
    width="1590px"
    :before-close="handleClose"
    custom-class="justment-expenses-dialog"
    :close-on-click-modal="false"
  >
    <el-form :model="formData" :rules="rules" ref="formRef">
      <el-form-item label="调整原因" prop="remark" label-width="80px">
        <el-input v-model="formData.remark" clearable />
      </el-form-item>
      <el-table :data="formData.processProductFeeDTOS" border max-height="600">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
              v-if="scope.row.expandData.length"
              :data="scope.row.expandData"
            >
              <el-table-column label="调整前">
                <el-table-column
                  label="加工费单价"
                  prop="feeUnitPrice"
                  width="110"
                />
                <el-table-column label="版费" prop="editionFee" width="100" />
                <el-table-column label="其他费用" prop="otherFee" width="100" />
                <el-table-column
                  label="折扣金额"
                  prop="discountAmount"
                  width="100"
                />
                <el-table-column label="合计费用" prop="totalFee" width="100" />
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="加工单号" prop="processOrderCode" width="100" />
        <el-table-column label="销售单号" prop="salesOrderCode" width="100" />
        <el-table-column label="产品编码" prop="skuId" width="100" />
        <el-table-column label="产品颜色" prop="printColor" width="100" />
        <el-table-column label="印刷方式" prop="printWay" width="100" />
        <el-table-column label="产品名称" prop="productCn" width="100" />
        <el-table-column label="合格数量" prop="qualityNum" width="100" />
        <el-table-column label="损耗数量" prop="processLoseNum" width="100" />
        <el-table-column label="调整后">
          <el-table-column
            label="加工费单价"
            width="110"
            label-class-name="required-th"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'processProductFeeDTOS.' + scope.$index + '.feeUnitPrice'
                "
                :rules="rules.feeUnitPrice"
              >
                <el-input
                  v-model="scope.row.feeUnitPrice"
                  v-only-number.positive="2"
                  clearable
                  @input="handleInput(scope.row, scope.$index)"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="版费" width="100">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.editionFee"
                v-only-number.positive="2"
                clearable
                @input="handleInput(scope.row, scope.$index)"
              />
            </template>
          </el-table-column>
          <el-table-column label="其他费用" width="100">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.otherFee"
                v-only-number.positive="2"
                clearable
                @input="handleInput(scope.row, scope.$index)"
              />
            </template>
          </el-table-column>
          <el-table-column label="折扣金额" width="100">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.discountAmount"
                v-only-number.positive="2"
                clearable
                @input="handleInput(scope.row, scope.$index)"
              />
            </template>
          </el-table-column>
          <el-table-column label="合计费用" prop="totalFee" width="100" />
        </el-table-column>
        <el-table-column label="本次调整金额" width="110">
          <template slot-scope="scope">
            <span class="amount" :class="+scope.row.adjustFee != 0 && 'red'">
              <span v-if="+scope.row.adjustFee > 0">+</span>
              {{ scope.row.adjustFee }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template
            v-if="formData.processProductFeeDTOS.length > 1"
            slot-scope="scope"
          >
            <el-button
              type="danger"
              icon="el-icon-delete"
              plain
              circle
              size="mini"
              @click="handleDelete(scope.$index)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <ErpLoadingButton type="primary" @click="handleSubmit">
        确 认
      </ErpLoadingButton>
      <el-button @click="handleClose">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { OutProcessing } from '@/core'
  import { sub } from 'kits'
  export default {
    props: {
      value: { type: Boolean, default: false },
      processProductFeeDTOS: { type: Array, default: () => [] },
      getTotalFee: { type: Function }, // 计算合计费用
    },
    data() {
      return {
        formData: { remark: '', processProductFeeDTOS: [] },
        rules: {
          remark: [
            { required: true, message: '请输入原因', trigger: 'change' },
            { max: 50, message: '最多输入50个字', trigger: 'change' },
          ],
          feeUnitPrice: [
            { required: true, message: '请输入', trigger: 'change' },
          ],
        },
      }
    },
    computed: {
      dialogVisible: {
        get() {
          if (this.value) {
            this.formData.processProductFeeDTOS = this.processProductFeeDTOS
          }
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },
    },
    methods: {
      async handleSubmit(e, done) {
        this.$refs.formRef.validate(async (valid) => {
          if (valid) {
            try {
              const { code } = await OutProcessing.processProductAdjustFeeApi(
                this.formData
              )
              if (code === '000000') {
                this.$emit('refresh')
                this.handleClose()
              }
            } catch (e) {
              console.log(e)
            } finally {
              done()
            }
          } else {
            done()
          }
        })
      },
      handleClose() {
        this.$refs.formRef.resetFields()
        this.dialogVisible = false
      },
      // 当【加工费单价、版费、其他费用、折扣金额】发生变化时，计算合计费用和本次调整金额
      handleInput(row, index) {
        const totalFee = this.getTotalFee(row)
        this.formData.processProductFeeDTOS[index].totalFee = totalFee
        // 本次调整金额 = 当前合计费用 - 上一次合计费用
        this.formData.processProductFeeDTOS[index].adjustFee = sub(
          totalFee,
          row.expandData[0].totalFee
        )
      },
      handleDelete(index) {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.formData.processProductFeeDTOS.splice(index, 1)
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-dialog__wrapper {
    display: flex;
    /deep/.el-dialog {
      margin: auto !important;
    }
  }
  /deep/ .justment-expenses-dialog {
    .el-dialog__footer {
      text-align: center;
    }
    // 调整原因宽度
    .el-form > .el-form-item:first-of-type {
      width: 500px;
    }
    .el-table {
      .cell {
        text-align: center;
      }
      // 表格头部的间距
      tr {
        height: auto;
        th.el-table__cell {
          padding: 2px 0;
          height: auto;
        }
      }
      // 调整前 位置
      .el-table__body-wrapper {
        .el-table__expanded-cell {
          padding-left: 847px;
          .gutter {
            display: none;
          }
        }
      }
      .amount {
        display: flex;
        justify-content: center;
        &.red {
          color: red;
        }
      }
      // 去掉底部边距
      .el-form-item {
        margin-bottom: 0;
        .el-form-item__content {
          position: static;
          .el-form-item__error {
            top: inherit;
            left: inherit;
            padding-top: 3px;
          }
        }
      }
    }
  }
</style>
