export async function getImageBytesWithUrl(url, option = {}) {
  let { fileName = 'image.jpg', fileType = 'image/jpeg' } = option
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    const file = new File([blob], fileName, { type: fileType })

    return file.size
  } catch (err) {
    // console.error(err)
  }
}
