<template>
  <el-dialog
    title="货币设置历史记录"
    :visible.sync="dialogVisible"
    width="60%"
    @close="close"
    class="dialog-middle"
  >
    <el-table :data="tableData" border>
      <el-table-column label="创建时间" prop="createTime" align="center" />
      <el-table-column
        prop="financeName"
        align="center"
        label="国家货币"
      ></el-table-column>
      <el-table-column
        prop="code"
        align="center"
        label="货币代码"
      ></el-table-column>
      <!-- <el-table-column
        prop="currencyMark"
        align="center"
        label="货币标识"
      ></el-table-column> -->
      <el-table-column
        prop="exchangeRate"
        align="center"
        label="汇率"
      ></el-table-column>
      <el-table-column
        prop="effTime"
        align="center"
        label="生效时间"
      ></el-table-column>
      <el-table-column
        prop="creatorName"
        align="center"
        label="创建人"
      ></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="close">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { FinanceCurrencyRecord } from '@/api/finance/finance-setting'
export default {
  name: 'CurrencyRecord',
  data() {
    return {
      loading: false,
      dialogVisible: false,
      record: {},
      tableData: [],
    }
  },
  methods: {
    showDialog(id) {
      this.dialogVisible = true
      this.getList(id)
    },
    getList(id) {
      this.loading = true
      this.tableData = []
      FinanceCurrencyRecord({ financeSetupId: id })
        .then((res) => {
          this.loading = false
          if (res.code == '000000') {
            this.tableData = res.data || []
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    close() {
      this.dialogVisible = false
    },
  },
}
</script>
<style scoped lang="scss">
</style>