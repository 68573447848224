<!--
  功能：加工设置
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月02日 14:36:12
  版本：v1.4
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="proSetting-container">
    <template v-if="isSupplierOtherSetting">
      <ErpSubTitle title="自动流程设置" />
      <div class="content">
        <p>
          <span>自动生成单据</span>
          <el-switch
            v-model="form.processConfigVO.autoInspection"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <span class="small-text">
            开启后，加工完成后自动生成验货单和入库申请单
          </span>
        </p>
        <p v-for="(item, index) in form.processConfigQcVOS" :key="index">
          <span>下给</span>
          <SupplierSelect
            :multiple="false"
            :defaultSupplier="{
              supplierId: item.supplierId,
              supplierName: item.supplierName,
            }"
            @supplier-choose="(val) => chooseSuplier(true, val, index)"
            @clear-selectFn="() => clearSelectFn(index)"
          />
          的加工单，加工完成后自动生成验货单，并默认指派给 QC

          <UserSelect
            roleName="QC"
            @user-choose="(user) => chooseUser(user, index)"
            :defaultUser="{
              userId: item.qcUserId,
              userName: item.qcUserName,
              englishName: item.qcUserNameEn,
            }"
          ></UserSelect>
          <el-button
            v-if="index != 0"
            style="margin-left: 8px"
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
            plain
            @click="deleteRowFn(index)"
          ></el-button>
        </p>
        <el-button
          class="add-rows"
          type="text"
          style="color: #1890ff; margin-left: 370px"
          @click="addRowFn"
        >
          + 添加一行
        </el-button>
      </div>
      <ErpSubTitle title="供应商设置" />
      <div class="content">
        <span>加工单可选供应商范围</span>
        <SupplierSelect
          :defaultSupplier="defaultMultipleSuppplier"
          @supplier-choose="(val) => chooseSuplier(false, val)"
        />
      </div>
      <div class="content" v-for="item in tableDataList" :key="item.type">
        <p>{{ item.title }}</p>
        <el-table border stripe :data="item.tableData">
          <el-table-column
            v-for="(column, columnIndex) in item.columnArr"
            :key="columnIndex"
            :label="column.label"
            :prop="column.prop"
            align="center"
            :width="column.width"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <template v-if="column.prop === 'needAudit'">
                {{ scope.row[column.prop] ? '是' : '否' }}
              </template>
              <template v-else>
                {{ scope.row[column.prop] || '--' }}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="180px">
            <template slot-scope="scope">
              <el-link
                :underline="false"
                @click="editFee(scope.row, item.type)"
                type="primary"
              >
                编辑
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 费用设置 -->
      <el-dialog
        :visible="visible"
        :title="dialogTitle"
        @close="close"
        width="540px"
        :close-on-click-modal="false"
      >
        <el-form :model="feeSettingForm" :rules="rules" ref="feeSettingForm">
          <template v-if="dialogType === 'printingCosts'">
            <el-form-item label="供应商" label-width="100px">
              <el-select
                v-model="feeSettingForm.supplierId"
                disabled
                class="w100"
                clearable
              >
                <el-option
                  v-for="item in supplierList"
                  :key="item.financeSetupId"
                  :label="item.supplierName"
                  :value="item.supplierId"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="支付方式" prop="payId" label-width="100px">
              <el-select
                v-model="feeSettingForm.payId"
                placeholder="请选择"
                class="w100"
                clearable
                value-key="financeSetupId"
              >
                <el-option
                  v-for="item in paymentWay"
                  :key="item.financeSetupId"
                  :label="item.financeName"
                  :value="item.financeSetupId"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="收款方"
              prop="shroffAccountId"
              label-width="100px"
            >
              <el-autocomplete
                clearable
                v-model.trim="codeOrName"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入"
                class="w100"
                @select="costtHandleSelect"
                @clear="clearShroffAccountId"
              />
            </el-form-item>
            <el-form-item
              prop="financeSetupId"
              label="费用类型"
              label-width="100px"
            >
              <el-select
                v-model="feeSettingForm.financeSetupId"
                placeholder="请选择"
                class="w100"
                clearable
                @change="(val) => storehouseChange(val, 4)"
              >
                <el-option
                  v-for="item in costypeOptions"
                  :key="item.financeSetupId"
                  :label="item.financeName"
                  :value="item.financeSetupId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="paymentShroffAccountId"
              label="付款账号"
              label-width="100px"
            >
              <el-select
                v-model="feeSettingForm.paymentShroffAccountId"
                placeholder="请选择"
                class="w100"
                clearable
                @change="(val) => storehouseChange(val, 5)"
              >
                <el-option
                  v-for="item in Payer"
                  :key="item.paymentShroffAccountId"
                  :label="item.paymentShroffAccountName"
                  :value="item.paymentShroffAccountId"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="dialogType === 'defaultWarehouse'">
            <el-form-item
              label="加工单出库默认仓库"
              label-width="160px"
              prop="defaultOutWarehouseId"
            >
              <el-select
                v-model="feeSettingForm.defaultOutWarehouseId"
                placeholder="出库仓库"
                class="w100"
                clearable
                @change="(val) => storehouseChange(val, 1)"
              >
                <el-option
                  v-for="item in warehouses"
                  :key="item.warehouseId"
                  :value="item.warehouseId"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="成品入库默认仓库"
              label-width="160px"
              prop="defaultInWarehouseId"
            >
              <el-select
                v-model="feeSettingForm.defaultInWarehouseId"
                placeholder="入库仓库"
                class="w100"
                clearable
                @change="(val) => storehouseChange(val, 2)"
              >
                <el-option
                  v-for="item in warehouses"
                  :key="item.warehouseId"
                  :value="item.warehouseId"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="加工入库单和退料入库单是否需要审核"
              label-width="160px"
            >
              <el-switch
                v-model="feeSettingForm.needAudit"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </template>
          <template v-if="dialogType === 'personnelPermissions'">
            <el-form-item
              label="可查看该供应商委外加工单人员"
              label-width="220px"
              prop="userNameStr"
            >
              <el-input
                v-model="feeSettingForm.userNameStr"
                @focus="choosePersonnel"
              ></el-input>
            </el-form-item>
          </template>
          <template v-if="dialogType === 'printingMethod'">
            <el-form-item
              label="印刷方式"
              label-width="160px"
              prop="printingIdStr"
            >
              <el-select
                v-model="feeSettingForm.printingIdStr"
                placeholder="请选择"
                class="w100"
                clearable
                filterable
                multiple
                @change="(val) => storehouseChange(val, 3)"
              >
                <el-option
                  v-for="item in printingMethodOptions"
                  :key="item.productPrintingPricingId"
                  :value="item.productPrintingPricingId"
                  :label="item.nameCn"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
          <div class="btn-wrapper">
            <el-button type="primary" size="mini" @click="saveFeeSetting">
              确认
            </el-button>
            <el-button size="mini" @click="close">取消</el-button>
          </div>
        </el-form>
      </el-dialog>
      <!-- 人员组织架构树 -->
      <UserChoose ref="UserChoose" @choose-user="chooseUserListFn" />
    </template>
    <div v-if="isCostSettingByFinance" class="content">
      <p>加工单供应商费用调整期限设置</p>
      <ul>
        <li
          v-for="(item, index) of processConfigSupplierDTOS"
          :key="item.supplierId"
        >
          下给
          <b>{{ item.supplierName }}</b>
          的当月完工的加工单的加工费用调整调整时间不可晚于下月的
          <el-input
            v-model="item.adjustDay"
            clearable
            @input="handleInput(item.adjustDay, index)"
          />
          号。
        </li>
      </ul>
    </div>
    <div
      v-if="isSupplierOtherSetting || isCostSettingByFinance"
      class="button-wrapper"
    >
      <el-button type="primary" @click="savePageData">保存</el-button>
    </div>
  </div>
</template>

<script>
  import {
    InboundSheet,
    ProductAddEditInteractor,
    OutProcessing,
    FinanceCostInteractor,
  } from '@/core'
  import { listShorffAccountFee } from '@/api/finance/finance-cost'
  import UserSelect from '@/components/user-select'
  import SupplierSelect from '@/components/supplier-select'
  import UserChoose from '@/components/userChoose2'
  import { hasRight } from '@/utils/permissionBtn'
  export default {
    name: 'ProductSetting',
    components: {
      UserSelect,
      SupplierSelect,
      UserChoose,
    },
    props: {},
    data() {
      return {
        // 弹窗类型
        dialogType: 'printingCosts',
        // 表格数据
        tableDataList: [
          {
            title: '加工完成后自动生成印刷费用的设置',
            type: 'printingCosts',
            columnArr: [
              {
                label: '供应商',
                prop: 'supplierName',
                width: '260px',
              },
              {
                label: '支付方式',
                prop: 'payName',
              },
              {
                label: '收款方',
                prop: 'shroffAccountName',
              },
              {
                label: '费用类型',
                prop: 'financeName',
              },
              {
                label: '付款账号',
                prop: 'paymentShroffAccountName',
              },
            ],
            tableData: [],
          },
          {
            title: '加工单供应商默认仓库设置',
            type: 'defaultWarehouse',
            columnArr: [
              {
                label: '供应商',
                prop: 'supplierName',
                width: '260px',
              },
              {
                label: '加工单出库默认仓库',
                prop: 'defaultOutWarehouseName',
              },
              {
                label: '成品入库默认仓库',
                prop: 'defaultInWarehouseName',
              },
              {
                label: '加工入库单和退料入库单是否需要审核',
                prop: 'needAudit',
              },
            ],
            tableData: [],
          },
          {
            title: '加工单供应商人员权限设置',
            type: 'personnelPermissions',
            columnArr: [
              {
                label: '供应商',
                prop: 'supplierName',
                width: '260px',
              },
              {
                label: '可查看该供应商委外加工单人员',
                prop: 'userNameStr',
              },
            ],
            tableData: [],
          },
          {
            title: '加工单供应商默认印刷方式设置',
            type: 'printingMethod',
            columnArr: [
              {
                label: '供应商',
                prop: 'supplierName',
                width: '260px',
              },
              {
                label: '印刷方式',
                prop: 'printingNameStr',
              },
            ],
            tableData: [],
          },
        ],
        feeSettingForm: {
          payId: '', // 支付方式
          payName: '', //支付方式名称
          shroffAccountId: '', //收款方id
          shroffAccountName: '', //收款方名称
          supplierId: '',
          financeSetupId: '', //费用类型
          financeName: '', //费用名称

          paymentShroffAccountId: '', //付款账号
          paymentShroffAccountName: '', //付款账号名称

          defaultOutWarehouseId: '', //加工单出库默认仓库
          defaultOutWarehouseName: '',
          defaultInWarehouseId: '', //成品入库默认仓库
          defaultInWarehouseName: '',
          needAudit: 0, //加工入库单和退料入库单是否需要审核？

          userNameStr: '', //可查看该供应商委外加工单人员
          userIdStr: '',

          printingIdStr: [], //印刷方式
          printingNameStr: [],
        },
        selectSupplier: [],
        form: {
          processConfigVO: {
            autoInspection: 0, //自带生成单据
          },
          //Qc配置
          processConfigQcVOS: [
            {
              supplierId: '',
              supplierName: '',
              qcUserId: '',
              qcUserName: '',
              qcUserNameEn: '',
            },
          ],
          //印刷费用的设置
          processConfigSupplierVOS: [],
          // 仓库配置
          processConfigDefaultWareHouseVOS: [],
          // 人员权限设置
          processConfigSupplierUserVOS: [],
          // 印刷方式设置
          processConfigSupplierPrintingVOS: [],
        },
        warehouses: [], //仓库下拉列表
        defaultMultipleSuppplier: [],
        visible: false,
        codeOrName: '',
        triggerBol: false,
        paymentWay: [],
        currentSupplier: {},
        inititalProcessConfigSupplierVO: [], //默认印刷费用的设置数据
        inititalDefaultWarehouseVO: [], //默认仓库设置数据
        inititalPersonnelPermissionsVO: [], //默认人员权限设数据
        inititalPrintingMethodVO: [], //默认印刷方式设数据
        printingMethodOptions: [],
        // 费用类型
        costypeOptions: [],
        // 付款人
        Payer: [],
        // 加工单供应商费用调整期限设置 列表
        processConfigSupplierDTOS: [],
      }
    },
    computed: {
      dialogTitle() {
        const titleObj = {
          printingCosts: '费用设置',
          defaultWarehouse: '默认仓库设置',
          personnelPermissions: '人员权限设置',
          printingMethod: '印刷方式设置',
        }
        return titleObj[this.dialogType]
      },
      supplierList() {
        return [this.currentSupplier]
      },
      rules() {
        return {
          payId: [
            {
              required: true,
              trigger: 'change',
              message: '请选择',
            },
          ],
          financeSetupId: [
            {
              required: true,
              trigger: 'change',
              message: '请选择',
            },
          ],
          paymentShroffAccountId: [
            {
              required: true,
              trigger: 'change',
              message: '请选择',
            },
          ],
          shroffAccountId: [
            {
              required: true,
              trigger: 'change',
              message: '请选择',
            },
          ],
          defaultInWarehouseId: [
            {
              required: false,
              trigger: 'change',
              message: '请选择',
            },
          ],
          defaultOutWarehouseId: [
            {
              required: false,
              trigger: 'change',
              message: '请选择',
            },
          ],
          userNameStr: [
            {
              required: true,
              trigger: 'blur',
              message: '请选择',
            },
          ],
          printingIdStr: [
            {
              required: false,
              trigger: 'change',
              message: '请选择',
            },
          ],
        }
      },
      // 是否有【供应商其他设置】权限
      isSupplierOtherSetting() {
        return hasRight('ORDER:SUPPLIEROTHERSETTING')
      },
      // 是否有【费用调整期限设置-财务用】权限
      isCostSettingByFinance() {
        return hasRight('ORDER:COSTSETTINGBYFINANCE')
      },
    },
    /*
      4种情况：
        ①有【供应商其他设置】权限，有【费用调整期限设置-财务用】权限
          初始化时，调getData方法，回显所有的内容，不需要调getAdjustDayList
          保存，调insertPO/updateAllPO保存除【加工单供应商费用调整期限设置】以外的内容，调saveAdjustDayList保存【加工单供应商费用调整期限设置】
        ②有【供应商其他设置】权限，无【费用调整期限设置-财务用】权限
          初始化时，仅需要调getData方法，回显除【加工单供应商费用调整期限设置】以外所有的内容
          保存，仅调insertPO/updateAllPO保存除【加工单供应商费用调整期限设置】以外的内容
        ③无【供应商其他设置】权限，有【费用调整期限设置-财务用】权限
          初始化时，仅需要调getAdjustDayList回显【加工单供应商费用调整期限设置】
          保存，仅调saveAdjustDayList
        ④无【供应商其他设置】权限，无【费用调整期限设置-财务用】权限
          啥也不显示，一个接口都不调
    */

    created() {
      console.log('供应商其他设置', this.isSupplierOtherSetting)
      console.log('费用调整期限设置-财务用', this.isCostSettingByFinance)
      if (this.isSupplierOtherSetting) {
        this.getData()
        this.getWhs()
        this.getPaymentWay()
        this.getPrintingMethodS()
        this.getCostType()
        this.getPayer()
      }
      // 没有【供应商其他设置】权限，但有【费用调整期限设置-财务用】权限
      if (!this.isSupplierOtherSetting && this.isCostSettingByFinance) {
        this.getAdjustDayList()
      }
    },

    methods: {
      // 费用类型
      async getCostType() {
        let response = await this.pubApi.financeSelect({ financeType: 5 })
        this.costypeOptions = response?.data ?? []
      },
      // 付款人
      async getPayer() {
        this.Payer = await FinanceCostInteractor.listShorffAccountPayApi({
          cusName: '',
          shroffType: 4,
          cbsFlag: 0,
        })
      },
      // 点击添加一行
      addRowFn() {
        this.form.processConfigQcVOS.push({
          supplierId: '',
          supplierName: '',
          qcUserId: '',
          qcUserName: '',
          qcUserNameEn: '',
        })
      },
      // 删除某一行
      deleteRowFn(index) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.processConfigQcVOS.splice(index, 1)
          })
          .catch((e) => {
            console.log(e)
          })
      },
      // 选择人员
      choosePersonnel() {
        let ids = this.feeSettingForm?.userIdStr?.split(',') ?? []
        this.$refs['UserChoose'].showAddEdit('listSearch', ids)
      },
      // 选择人员确定
      chooseUserListFn(val) {
        if (val?.length) {
          let personnelName = []
          let personnelId = []
          for (let k of val) {
            personnelName.push(k.name)
            personnelId.push(k.id)
          }
          this.feeSettingForm.userNameStr = personnelName.join(',')
          this.feeSettingForm.userIdStr = personnelId.join(',')
          this.$refs.feeSettingForm.clearValidate()
        } else {
          this.feeSettingForm.userNameStr = ''
          this.feeSettingForm.userIdStr = ''
        }
      },
      // 选择下拉框
      storehouseChange(val, num) {
        if (num === 1) {
          // 加工单出库默认仓库
          const obj = this.warehouses.find((item) => item.warehouseId === val)
          this.feeSettingForm.defaultOutWarehouseName = obj?.name ?? ''
        } else if (num === 2) {
          // 成品入库默认仓库
          const obj = this.warehouses.find((item) => item.warehouseId === val)
          this.feeSettingForm.defaultInWarehouseName = obj?.name ?? ''
        } else if (num === 3) {
          // 选择印刷方式
          let names = []
          for (let k of val) {
            const obj = this.printingMethodOptions.find(
              (item) => item.productPrintingPricingId === k
            )
            names.push(obj?.nameCn)
          }
          this.feeSettingForm.printingNameStr = names
        } else if (num === 4) {
          const obj = this.costypeOptions.find(
            (item) => item.financeSetupId === val
          )
          this.feeSettingForm.financeName = obj?.financeName ?? ''
        } else if (num === 5) {
          const obj = this.Payer.find(
            (item) => item.paymentShroffAccountId === val
          )
          this.feeSettingForm.paymentShroffAccountName =
            obj?.paymentShroffAccountName ?? ''
        }
      },
      checkTableData() {
        let flag = true
        const { processConfigQcVOS } = this.form
        flag = processConfigQcVOS?.every((item) => {
          return item.supplierId && item.qcUserId
        })
        if (!flag) {
          this.$message.warning('请完善供应商及对应QC数据')
          return
        }

        let supplierIdObj = {}
        for (let k of processConfigQcVOS) {
          if (supplierIdObj[k.supplierId]) {
            flag = false
            break
          } else {
            supplierIdObj[k.supplierId] = true
          }
        }
        if (!flag) {
          this.$message.warning('同一供应商不能设置多个QC数据')
          return
        }
        if (!this.selectSupplier?.length) return flag
        const processConfigSupplierDTOList = this.tableDataList.find(
          (item) => item.type === 'printingCosts'
        )

        flag = processConfigSupplierDTOList?.tableData?.every((item) => {
          return (
            item.shroffAccountId &&
            item.payId &&
            item.financeSetupId &&
            item.paymentShroffAccountId
          )
        })
        if (!flag) {
          this.$message.warning('请完善支付方式、收款方、费用类型、付款账号')
          return
        }

        const processConfigSupplierUserDTOList = this.tableDataList.find(
          (item) => item.type === 'personnelPermissions'
        )

        flag = processConfigSupplierUserDTOList?.tableData?.every((item) => {
          return item.userIdStr
        })
        if (!flag) {
          this.$message.warning('请完善可查看供应商委外加工单人员')
          return
        }

        return flag
      },
      savePageData() {
        // 有【供应商其他配置】权限
        if (this.isSupplierOtherSetting) {
          if (!this.checkTableData()) return
          OutProcessing[this.isAdd ? 'insertPO' : 'updateAllPO'](
            this.form,
            this.tableDataList
          ).then((res) => {
            if (res && res.code === '000000') {
              // 有【费用调整期限设置-财务用】权限
              if (this.isCostSettingByFinance) {
                this.saveAdjustDayList().then(() => {
                  this.$message.success('操作成功')
                  // 刷新页面
                  this.getData()
                })
              } else {
                this.$message.success('操作成功')
                // 刷新页面
                this.getData()
              }
            }
          })
        } else {
          // 无【供应商其他配置】权限
          if (this.isCostSettingByFinance) {
            // 有【费用调整期限设置-财务用】权限
            this.saveAdjustDayList().then(() => {
              this.getAdjustDayList()
              this.$message.success('操作成功')
            })
          }
        }
      },
      close() {
        this.feeSettingForm = this.$options.data().feeSettingForm
        this.$nextTick(() => {
          this.$refs.feeSettingForm.clearValidate()
        })
        this.visible = false
      },
      saveFeeSetting() {
        this.$refs.feeSettingForm.validate((validate) => {
          if (validate) {
            this.editTableLine({
              ...this.feeSettingForm,
            })

            this.close()
          }
        })
      },
      editTableLine(data) {
        const { supplierId } = data
        for (let k of this.tableDataList) {
          if (k.type === this.dialogType) {
            let tableItem = k.tableData.find(
              (item) => item.supplierId === supplierId
            )
            let tableDataObj = {
              printingCosts: () => {
                const {
                  payId,
                  shroffAccountId,
                  shroffAccountName,
                  financeSetupId,
                  financeName,
                  paymentShroffAccountId,
                  paymentShroffAccountName,
                } = data
                tableItem.payId = payId
                tableItem.payName = this.getPayName(payId)
                tableItem.shroffAccountId = shroffAccountId
                tableItem.shroffAccountName = shroffAccountName
                tableItem.financeSetupId = financeSetupId
                tableItem.financeName = financeName
                tableItem.paymentShroffAccountId = paymentShroffAccountId
                tableItem.paymentShroffAccountName = paymentShroffAccountName
              },
              defaultWarehouse: () => {
                const {
                  defaultOutWarehouseId,
                  defaultOutWarehouseName,
                  defaultInWarehouseId,
                  defaultInWarehouseName,
                  needAudit,
                } = data
                tableItem.defaultOutWarehouseId = defaultOutWarehouseId
                tableItem.defaultOutWarehouseName = defaultOutWarehouseName
                tableItem.defaultInWarehouseId = defaultInWarehouseId
                tableItem.defaultInWarehouseName = defaultInWarehouseName
                tableItem.needAudit = needAudit
              },
              personnelPermissions: () => {
                const { userNameStr, userIdStr } = data
                tableItem.userNameStr = userNameStr
                tableItem.userIdStr = userIdStr
              },
              printingMethod: () => {
                const { printingIdStr, printingNameStr } = data
                tableItem.printingIdStr = printingIdStr?.join(',')
                tableItem.printingNameStr = printingNameStr?.join(',')
              },
            }
            tableDataObj[k.type]()
          }
        }
      },
      getPayName(payId) {
        this.cache = this.cache || {}
        if (this.cache[payId]) return this.cache[payId]
        this.cache[payId] = this.paymentWay.find(
          (item) => item.financeSetupId === payId
        )?.financeName
        return this.cache[payId]
      },
      clearShroffAccountId(val) {
        this.feeSettingForm.shroffAccountId = ''
      },
      editFee(item, type) {
        this.visible = true
        this.$nextTick(() => {
          this.$refs.feeSettingForm && this.$refs.feeSettingForm.clearValidate()
        })
        this.dialogType = type
        this.feeSettingForm.supplierId = item.supplierId
        let keyArr = []
        switch (type) {
          case 'printingCosts':
            this.currentSupplier = item
            this.codeOrName = item.shroffAccountName
            keyArr = [
              'shroffAccountId',
              'shroffAccountName',
              'payId',
              'financeSetupId',
              'paymentShroffAccountId',
              'financeName',
              'paymentShroffAccountName',
            ]
            for (let k of keyArr) {
              this.feeSettingForm[k] = item[k]
            }
            break
          case 'defaultWarehouse':
            keyArr = [
              'defaultOutWarehouseId',
              'defaultOutWarehouseName',
              'defaultInWarehouseId',
              'defaultInWarehouseName',
              'needAudit',
            ]
            for (let k of keyArr) {
              this.feeSettingForm[k] = item[k]
            }

            break
          case 'personnelPermissions':
            this.feeSettingForm.userNameStr = item.userNameStr
            this.feeSettingForm.userIdStr = item.userIdStr
            break
          case 'printingMethod':
            this.feeSettingForm.printingIdStr = item?.printingIdStr
              ? item?.printingIdStr?.split(',')
              : []
            this.feeSettingForm.printingNameStr = item?.printingNameStr
              ? item?.printingNameStr?.split(',')
              : []
            break
        }
      },
      // 支付方式
      async getPaymentWay() {
        this.paymentWay = await this.Dicts.getFinanceData(3)
      },
      // 获取印刷方式
      async getPrintingMethodS() {
        let res = await ProductAddEditInteractor.listAllApi()
        if (res?.code === '000000') {
          this.printingMethodOptions = res?.data ?? []
        }
      },
      //收款方搜索
      async querySearchAsync(queryString, cb) {
        this.triggerBol = true
        await listShorffAccountFee({ cusName: this.codeOrName }).then((res) => {
          let results = res.data.map((item) => {
            return {
              ...item,
              value: item.assembleStr,
            }
          })
          cb(results)
        })
      },
      // 选择收款方
      costtHandleSelect(item) {
        if (item.shroffAccountId) {
          this.feeSettingForm.shroffAccountId = item.shroffAccountId
          this.feeSettingForm.shroffAccountName = item.value
        }
      },
      // 清楚供应商
      clearSelectFn(index) {
        this.form.processConfigQcVOS[index].supplierId = ''
        this.form.processConfigQcVOS[index].supplierName = ''
      },
      // 选择供应商
      chooseSuplier(isSingle, val, index) {
        if (isSingle) {
          //单选 qc对应供应商
          val = val[0]
          if (!val) return
          this.form.processConfigQcVOS[index].supplierId = val?.supplierId
          this.form.processConfigQcVOS[index].supplierName = val?.supplierName
        } else {
          // 当【加工单可选供应商范围】change时，重新渲染【加工单供应商费用调整期限设置】
          this.getAdjustDayListByChange(val)
          //加工单可选范围供应商
          const oldChoose = JSON.parse(JSON.stringify(this.selectSupplier))
          let operate = 'getData' //getData获取 addData 新增  delteData 删除
          let changeArr = []
          if (val?.length > oldChoose?.length) {
            operate = 'addData'
            let supplierIds = oldChoose.map((item) => item.supplierId)
            for (let k of val) {
              if (!supplierIds.includes(k.supplierId)) {
                changeArr.push(k)
              }
            }
          } else if (val?.length < oldChoose?.length) {
            operate = 'delteData'
            let supplierIds = val.map((item) => item.supplierId)
            for (let k of oldChoose) {
              if (!supplierIds.includes(k.supplierId)) {
                changeArr.push(k)
              }
            }
          } else {
            operate = 'getData'
            changeArr = val
          }
          this.selectSupplier = val
          for (let k of this.tableDataList) {
            k['tableData'] = this.setTableDataFn(k['type'], operate, changeArr)
          }
        }
      },
      setTableDataFn(type, operate, changeArr) {
        if (!changeArr || !changeArr.length) return []
        let tableData = []
        for (let k of this.tableDataList) {
          if (k['type'] === type) {
            tableData = k['tableData']
            break
          }
        }
        let target = {}
        let targetArr = []
        let addObj = {}
        for (let change of changeArr) {
          let tableObj = {
            printingCosts: () => {
              target = this.form.processConfigSupplierVOS.find(
                (t) => t.supplierId === change.supplierId
              )
              if (target) {
                target?.processConfigId && delete target.processConfigId
                target?.processConfigSupplierId &&
                  delete target.processConfigSupplierId
                targetArr.push(target)
                addObj = target
              } else {
                addObj = {
                  supplierId: change.supplierId,
                  supplierName: change.supplierName,
                  payId: '',
                  payName: '',
                  shroffAccountId: '',
                  shroffAccountName: '',
                  financeSetupId: '',
                  financeName: '',
                  paymentShroffAccountId: '',
                  paymentShroffAccountName: '',
                }
                targetArr.push(addObj)
              }
            },
            defaultWarehouse: () => {
              target = this.form.processConfigDefaultWareHouseVOS.find(
                (t) => t.supplierId === change.supplierId
              )
              if (target) {
                target.processConfigId && delete target.processConfigId
                targetArr.push(target)
                addObj = target
              } else {
                addObj = {
                  supplierId: change.supplierId,
                  supplierName: change.supplierName,
                  defaultOutWarehouseId: '',
                  defaultOutWarehouseName: '',
                  defaultInWarehouseId: '',
                  defaultInWarehouseName: '',
                  needAudit: 0,
                }
                targetArr.push(addObj)
              }
            },
            personnelPermissions: () => {
              target = this.form.processConfigSupplierUserVOS.find(
                (t) => t.supplierId === change.supplierId
              )
              if (target) {
                targetArr.push(target)
                addObj = target
              } else {
                addObj = {
                  supplierId: change.supplierId,
                  supplierName: change.supplierName,
                  userNameStr: '',
                  userIdStr: '',
                }
                targetArr.push(addObj)
              }
            },
            printingMethod: () => {
              target = this.form.processConfigSupplierPrintingVOS.find(
                (t) => t.supplierId === change.supplierId
              )
              if (target) {
                targetArr.push(target)
                addObj = target
              } else {
                addObj = {
                  supplierId: change.supplierId,
                  supplierName: change.supplierName,
                  printingNameStr: '',
                  printingIdStr: '',
                }
                targetArr.push(addObj)
              }
            },
          }
          tableObj[type]()
          if (operate === 'addData') {
            tableData.push(addObj)
          } else if (operate === 'delteData') {
            let dix = tableData.findIndex(
              (item) => item.supplierId === change.supplierId
            )
            tableData.splice(dix, 1)
          } else {
            tableData = targetArr
          }
        }

        return tableData
      },
      // 选择QC
      chooseUser(user, index) {
        const { userId, userName, englishName } = user
        this.form.processConfigQcVOS[index].qcUserId = userId
        this.form.processConfigQcVOS[index].qcUserName = userName
        this.form.processConfigQcVOS[index].qcUserNameEn = englishName
      },
      // 获取页面初始数据
      async getData() {
        let res = await OutProcessing.getOutProcessingConfig()
        if (res && res.code === '000000') {
          if (
            !res.data?.processConfigVO &&
            !res.data?.processConfigQcVOS &&
            !res.data?.processConfigSupplierVOS &&
            !res.data?.processConfigDefaultWareHouseVOS &&
            !res.data?.processConfigSupplierUserVOS &&
            !res.data?.processConfigSupplierPrintingVOS
          ) {
            this.isAdd = true
          }
          for (let k in this.form) {
            this.form[k] = res.data?.[k] ?? this.form[k]
          }
          this._initDefault(this.form)
        }
      },

      // 回显设置
      _initDefault(data = {}) {
        const { processConfigSupplierVOS } = data
        this.defaultMultipleSuppplier = processConfigSupplierVOS.map(
          (item) => ({
            supplierId: item.supplierId,
            supplierName: item.supplierName,
          })
        )
        this.selectSupplier = this.defaultMultipleSuppplier
      },

      // 获取仓库下拉列表
      async getWhs() {
        const res = await InboundSheet.findWarehouseList({
          hasCloudWarehouse: 1,
        })

        if (res && res.code === '000000') {
          this.warehouses = res.data
        }
      },
      // 保存【加工单供应商费用调整期限设置】列表
      async saveAdjustDayList() {
        try {
          const { code } = await OutProcessing.updateAdjustDayApi(
            this.processConfigSupplierDTOS
          )
          if (code === '000000') {
            return Promise.resolve()
          }
        } catch (e) {
          console.log(e)
        }
      },
      // 初始化获取【加工单供应商费用调整期限设置】列表
      async getAdjustDayList() {
        try {
          const { code, data } = await OutProcessing.listAdjustDayApi()
          if (code === '000000' && Array.isArray(data)) {
            this.processConfigSupplierDTOS = data
            console.log(
              '没有【供应商其他设置】权限，但有【费用调整期限设置-财务用】权限',
              JSON.parse(JSON.stringify(data))
            )
          }
        } catch (e) {
          console.log(e)
        }
      },
      // 【加工单可选供应商范围】change，实时渲染【加工单供应商费用调整期限设置】列表
      getAdjustDayListByChange(list) {
        if (this.isCostSettingByFinance) {
          const { processConfigSupplierVOS } = this.form
          const ids1 = list.map((n) => n.supplierId)
          const ids2 = processConfigSupplierVOS.map((n) => n.supplierId)
          const target = processConfigSupplierVOS.filter((n) =>
            ids1.includes(n.supplierId)
          )
          const otherList = list.filter((n) => !ids2.includes(n.supplierId))
          const result = target.concat(otherList)
          this.processConfigSupplierDTOS = result
        }
      },
      // 输入day，限制只能输入[1, 31]之间的整数
      handleInput(val, index) {
        if (val === '') {
          this.processConfigSupplierDTOS[index].adjustDay = ''
        } else {
          let adjustDay = val.replace(/[^\d]/g, '')
          if (adjustDay > 31) {
            adjustDay = 31
          } else if (adjustDay < 1) {
            adjustDay = 1
          }
          this.processConfigSupplierDTOS[index].adjustDay = +adjustDay
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .proSetting-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px !important;
    .content {
      margin: 10px 0 20px 0;
      p {
        margin: 10px 0 20px 0;
      }
      .content-item {
        margin-bottom: 25px;
      }
      > ul {
        padding-left: 24px;
        li {
          list-style: none;
          line-height: 40px;
          .el-input {
            width: 200px;
          }
        }
      }
    }
    span {
      display: inline-block;
      margin: 0 5px;
      &.small-text {
        color: #aaaaaa;
        font-size: 12px;
      }
    }
    .button-wrapper {
      width: calc(100% - 152px);
      position: fixed;
      bottom: 10px;
      text-align: center;
      background-color: #fff;
    }
    .w100 {
      width: 100%;
    }
    .btn-wrapper {
      margin-top: 40px;
      text-align: center;
    }
  }
</style>
