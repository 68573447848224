<!--出库单详情-->
<template>
  <div class="table-container">
    <p class="title">
      <span class="header mr15">
        出库单号：
        {{ detail.warehouseOutNumber }}
      </span>
    </p>
    <div class="detail-fields">
      <el-row :gutter="24" class="mt20 detail-block">
        <el-col :span="6" class="detail-item">
          <span class="detail-label">出库方式</span>
          <span class="detail-content">{{ detail.type | typeFilter }}</span>
        </el-col>
        <el-col :span="6" class="detail-item" v-if="typeIsTh">
          <span class="detail-label">采购单号</span>
          <span class="detail-content">
            {{ detail.orderNumber ? detail.orderNumber : '--' }}
          </span>
        </el-col>
        <el-col :span="6" class="detail-item" v-if="typeIsXS">
          <span class="detail-label">订单编号</span>
          <span class="detail-content">
            {{ detail.orderNumber ? detail.orderNumber : '--' }}
          </span>
        </el-col>
        <el-col :span="6" class="detail-item" v-if="typeIsXS">
          <span class="detail-label">客户姓名</span>
          <span class="detail-content">
            {{ detail.customerName ? detail.customerName : '--' }}
          </span>
        </el-col>
        <el-col :span="6" class="detail-item" v-if="typeIsXS">
          <span class="detail-label">客户代表</span>
          <span class="detail-content">
            {{ detail.businessName ? detail.businessName : '--' }}
          </span>
        </el-col>

        <el-col :span="6" class="detail-item" v-if="typeIsXS">
          <span class="detail-label">合同地区</span>
          <span class="detail-content">
            {{ detail.area ? detail.area : '--' }}
          </span>
        </el-col>
        <el-col :span="6" class="detail-item" v-if="typeIsTh">
          <span class="detail-label">供应商</span>
          <span class="detail-content">
            {{ detail.supplierName ? detail.supplierName : '--' }}
          </span>
        </el-col>
        <el-col :span="6" class="detail-item">
          <span class="detail-label">出库仓库</span>
          <span class="detail-content">
            {{ detail.warehouseId | warehouseName }}
          </span>
        </el-col>
        <el-col :span="6" class="detail-item">
          <span class="detail-label">操作人</span>
          <span class="detail-content">{{ detail.operationName }}</span>
        </el-col>

        <el-col :span="24" class="detail-item">
          <span class="detail-label">备注</span>
          <span class="detail-content">{{ detail.remark }}</span>
        </el-col>
      </el-row>
    </div>
    <div class="detail-table mt20">
      <p class="detail-title">出库产品</p>
      <el-table
        border
        :data="detail.warehouseOutProductVOList"
        class="mt20"
        show-summary
        sum-text="总计"
        :summary-method="getSummaries"
      >
        <el-table-column
          align="center"
          prop="skuId"
          label="SKU ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productName"
          label="产品名称"
        ></el-table-column>
        <el-table-column align="center" prop="productSpec" label="规格">
          <template slot-scope="scope">
            {{ scope.row.productSpec ? scope.row.productSpec : '--' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="productType"
          label="产品类别"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productUnit"
          label="单位"
        ></el-table-column>
        <el-table-column align="center" prop="contractNum" label="合同数量">
          <template slot-scope="scope">
            {{ scope.row.contractNum ? scope.row.contractNum : '--' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="warehouseOutNum"
          label="实际出库数量"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="giftNum"
          label="备用品数量"
        ></el-table-column>

        <el-table-column align="center" label="出库单价" prop="price">
          <template slot-scope="scope">
            {{ (scope.row.price * 1).toFixed(3) }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="出库成本" prop="totalPrice">
          <template slot-scope="scope">
            {{ (scope.row.totalPrice * 1).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="remark"
          label="备注"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <p class="operation">
              <el-tooltip
                class="item"
                effect="dark"
                content="出库库位"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-map-location"
                  circle
                  size="mini"
                  @click="addLoc(scope.row)"
                ></el-button>
              </el-tooltip>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 出库库位弹窗 -->
    <el-dialog
      :visible.sync="showOutLocDialog"
      title="出库库位"
      width="480px"
      append-to-body
      @close="onClose"
      modal-append-to-body
    >
      <div class="loc-content">
        <el-row v-for="(loc, index) in locs" :key="index" class="mb20">
          <el-col :span="12">
            <span class="mr15">库位号:</span>
            <span>{{ loc.locationNumber }}</span>
          </el-col>
          <el-col :span="12">
            <span class="mr15">出库数量:</span>
            <span>
              <el-input
                v-model="loc.num"
                disabled
                size="mini"
                style="width: 100px"
              ></el-input>
            </span>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <div class="detail-files mt20">
      <p class="detail-title">相关附件</p>
    </div>
  </div>
</template>

<script>
import { getDetail } from '@/api/stock-center/outbound-sheet'
import { findWarehouseList } from '@/api/stock-center/inbound-sheet'
import Loc from '@/views/stock-center/outbound-sheet/components/loc'
let self
export default {
  name: 'outbound-sheet-detail',
  props: {
    detailId: {
      type: String,
      required: true,
    },
  },
  data() {
    self = this
    return {
      showOutLocDialog: false,
      warehouses: [],
      detail: {
        area: 'aaaaaaaa',
        createWay: 0,
        fileNames: '',
        fileUrls: '',
        operationId: '',
        operationName: 'xxxxx',
        orderNumber: 'xxxxxxxx',
        remark: 'xxxxxxxx',
        status: 0,
        supplierName: 'xxxxxx',
        type: 0,
        warehouseId: '',
        warehouseInId: '',
        warehouseInNumber: '',
        adjustStatus: 0,

        warehouseOutProductVOList: [
          {
            contractNum: 0,
            giftNum: 0,
            priceExcludingTax: 0,
            priceIncludingTax: 0,
            productName: '',
            productSpec: '',
            productType: '',
            productUnit: '',
            remark: '',
            skuId: '',
            tenantId: '',
            totalPriceExcluding: 0,
            totalPriceIncluding: 0,
            warehouseInId: '',
            warehouseInNum: 0,
            warehouseInProductId: '',
            warehouseInProductLocationVOList: [
              {
                locationNumber: '',
                num: 0,
                warehouseInProductLocationId: '',
              },
            ],
          },
        ],
      },
      locs: [],
    }
  },
  components: {
    Loc,
  },
  watch: {
    detailId: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) return
        this.init(value)
      },
    },
  },
  computed: {
    warehouseInId() {
      return this.detailId
    },
    typeIsXS() {
      //出库
      return this.detail.type === 0
    },
    typeIsTh() {
      return this.detail.type === 1
    },
    typeIsQt() {
      return this.detail.type === 2
    },
  },

  methods: {
    init() {
      getDetail({
        warehouseOutId: this.detailId,
      }).then((res) => {
        if (res.code == '000000') {
          this.detail = res.data
        }
      }),
        findWarehouseList().then((res) => {
          this.warehouses = res.data || []
        })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
          return
        }
        if (![5, 6, 7, 9].includes(index)) {
          sums[index] = ''
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          if (index == 9) {
            sums[index] = this.toFixed3(sums[index])
          }
          if (index == 5) {
            if (!sums[index]) {
              sums[index] = '--'
            }
          }
        }
      })

      return sums
    },
    onClose() {
      this.locParams.show = false
    },
    addLoc(row) {
      this.locs = row.warehouseOutProductLocationVOList || []
      if (this.locs.length === 0) {
        return this.$message.warning('没有出库库位数据')
      }
      this.showOutLocDialog = true
    },
  },

  filters: {
    warehouseName(val) {
      const warehouse = self.warehouses.find((item) => item.warehouseId === val)
      return warehouse ? warehouse.name : '--'
    },
    typeFilter(val) {
      const types = [
        //入库方式
        {
          code: 0,
          name: '销售出库',
        },
        {
          code: 1,
          name: '退货出库',
        },
        {
          code: 2,
          name: '其他出库',
        },
      ]
      const targetType = types.find((type) => type.code === val)
      return targetType ? targetType.name : '--'
    },
  },
}
</script>

<style scoped lang="scss">
.table-container {
  padding: 20px !important;
  & .title {
    display: flex;
    align-items: center;
  }
  & .detail-fields {
    .el-row {
      & .label {
        color: #282c34;
        width: 100px;
        text-align: right;
        display: inline-block;
        margin-right: 15px;
      }
      & .content {
        color: #000016;
      }
    }
  }
}
.header {
  font-size: 18px;
  color: #606266;
  font-family: 'PingFang Bold';
}
.fs14 {
  font-size: 14px;
  font-family: 'PingFang Bold';
  color: #282c34;
}
.mr15 {
  margin-right: 15px;
}
.mt20 {
  margin-top: 20px;
}
.pointer {
  cursor: pointer;
}
.mb20 {
  margin-bottom: 20px;
}

.loc-content {
  max-height: 300px;
  overflow: auto;
}
</style>
