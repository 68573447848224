<template>
  <el-dialog
    v-if="modelVisible"
    width="880px"
    :title="`Additional Imprint ${lang === 'en' ? 'Calculate' : '计算'}`"
    :visible.sync="modelVisible"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-row :gutter="20">
      <!-- 印刷基准单价 -->
      <el-col :span="8" class="text-right">
        {{ $t('inquiryDetails.PrintingBase') }}
      </el-col>
      <el-col :span="4">{{ info.info.basicPrintingPrice }}</el-col>
      <!-- 印刷标准 -->
      <el-col :span="4" class="text-right">
        {{ $t('inquiryDetails.PrintingStandard') }}
      </el-col>
      <el-col :span="8">
        <span v-if="info.info.basicWay">
          {{ info.info.basicWay + ' - 1' }}{{ $t('addInquiry.Position') }}
        </span>
        <span v-else>--</span>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 当前印刷单价 -->
      <el-col :span="8" class="text-right">
        {{ $t('inquiryDetails.CurrentNnit') }}
      </el-col>
      <el-col :span="4">{{ info.info.printingPrice }}</el-col>
      <!-- 印刷标准 -->
      <el-col :span="4" class="text-right">
        {{ $t('inquiryDetails.PrintingStandard') }}
      </el-col>
      <el-col :span="8">
        <span v-if="info.info.way">
          {{ info.info.way + ' - ' + info.digitSelect
          }}{{ $t('addInquiry.Position') }}
        </span>
        <span v-else>--</span>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 当前数量 -->
      <el-col :span="8" class="text-right">
        {{ $t('inquiryDetails.TheCurrentNumber') }}
      </el-col>
      <el-col :span="4">{{ info.info.num }}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8" class="text-right">
        Additional Imprint
        <em>
          <el-tooltip
            placement="top"
            effect="light"
            :content="$t('inquiryDetails.Tips11')"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </em>
      </el-col>
      <el-col :span="4">
        {{
          (
            (info.info.printingPrice - info.info.basicPrintingPrice) *
            info.info.num
          ).toFixed(3)
        }}
      </el-col>
    </el-row>

    <div class="text-center mt30">
      <!-- 确认 -->
      <el-button type="primary" @click="save()">
        {{ $t('inquiryList.Confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      title: '',
      info: {},
      modelVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  created() {},
  methods: {
    // 确认
    save() {
      this.close()
    },

    // 取消
    close() {
      this.modelVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  line-height: 30px;
}
</style>
