<template>
  <el-dropdown @command="(val) => $emit('command', val)">
    <el-button class="pub-h-36">收款导入</el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="SHOU_KUAN_HAS_ORDER">
        有销售订单
      </el-dropdown-item>
      <p class="split-div"></p>
      <el-dropdown-item command="SHOU_KUAN_NO_ORDER">
        无销售订单
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
  export default {
    name: 'ImportShipping',
  }
</script>

<style lang="scss" scoped>
  .split-div {
    height: 1px;
    background-color: #dcdfe6;
    margin: 5px 0;
  }
  .ml-15 {
    margin-left: 15px;
  }
</style>
