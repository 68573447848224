<template>
  <!-- 标签设置 -->
  <el-dialog
    :title="$t('productDetail.LabelSettings')"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-row>
      <el-col :span="24" v-if="tableData && tableData.length">
        <el-form label-position="top">
          <el-form-item v-for="(item, index) in tableData" :key="index">
            <template slot="label">
              <label class="label-text f_s_14">
                <i style="color: #ff4d4f" v-if="item.required === '1'">*</i>
                <i style="color: #fff" v-else>*</i>
                {{ settings === 'zh' ? item.nameCn : item.nameEn }}
                <!-- 关联标签 -->
                <span v-if="item.isMain === '0'">
                  ({{ $t('productDetail.AssociatedLabels') }})
                </span>
              </label>
            </template>
            <!-- 多选 -->
            <el-row style="padding-left: 30px">
              <el-checkbox-group
                v-if="
                  item.multiple === '1' &&
                  item.keyChildEditVOList &&
                  item.keyChildEditVOList.length > 0
                "
                v-model="tableData[index].value"
              >
                <el-checkbox
                  v-for="(child, i) in item.keyChildEditVOList"
                  :key="i"
                  :label="child.keyChildId"
                  :disabled="child.disabled"
                  @change="mainChange(item.isMain, item.linkedParentIds)"
                >
                  {{ settings === 'zh' ? child.nameCn : child.nameEn }}
                </el-checkbox>
              </el-checkbox-group>
              <!-- 单选 -->
              <el-radio-group
                v-if="
                  item.multiple === '0' &&
                  item.keyChildEditVOList &&
                  item.keyChildEditVOList.length > 0
                "
                v-model="tableData[index].value"
              >
                <el-radio
                  v-for="child2 in item.keyChildEditVOList"
                  :key="child2.keyChildId"
                  :label="child2.keyChildId"
                  :disabled="child2.disabled"
                  @change="mainChange(item.isMain, item.linkedParentIds)"
                >
                  {{ settings === 'zh' ? child2.nameCn : child2.nameEn }}
                </el-radio>
              </el-radio-group>
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
      <!-- 还没有可选择的产品标签 -->
      <el-col :span="24" v-else class="t_a_c mt30 mb30">
        {{ $t('productDetail.ProductLabelsTips') }}
      </el-col>
    </el-row>
    <el-row class="mt30" style="display: none">
      <el-col :span="24" v-if="tableData2 && tableData2.length">
        <el-form label-position="top">
          <el-form-item v-for="(item, index) in tableData2" :key="index">
            <template slot="label">
              <label class="label-text f_s_14">
                <i style="color: #ff4d4f" v-if="item.required === '1'">*</i>
                <i style="color: #fff" v-else>*</i>
                {{ settings === 'zh' ? item.nameCn : item.nameEn }}
              </label>
            </template>
            <!-- 多选 -->
            <el-row style="padding-left: 30px">
              <el-checkbox-group
                v-if="
                  item.multiple === '1' &&
                  item.keyChildEditVOList &&
                  item.keyChildEditVOList.length > 0
                "
                v-model="tableData2[index].value"
              >
                <el-checkbox
                  v-for="(child, i) in item.keyChildEditVOList"
                  :key="i"
                  :label="child.keyChildId"
                  :disabled="child.disabled"
                  @change="chiildChange()"
                >
                  {{ settings === 'zh' ? child.nameCn : child.nameEn }}
                </el-checkbox>
              </el-checkbox-group>
              <!-- 单选 -->
              <el-radio-group
                v-if="
                  item.multiple === '0' &&
                  item.keyChildEditVOList &&
                  item.keyChildEditVOList.length > 0
                "
                v-model="tableData2[index].value"
              >
                <el-radio
                  v-for="child2 in item.keyChildEditVOList"
                  :key="child2.keyChildId"
                  :label="child2.keyChildId"
                  :disabled="child2.disabled"
                  @change="chiildChange()"
                >
                  {{ settings === 'zh' ? child2.nameCn : child2.nameEn }}
                </el-radio>
              </el-radio-group>
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    listForPopupByCategoryId,
    getDetailForEdit,
  } from '@/api/product/labelManage'
  import { deepCopy } from 'kits'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ProLabelApplyModel',
    data() {
      return {
        // title: '标签设置',
        // title: this.$t('productDetail.LabelSettings'),
        form: { checkedCities: [] },
        dialogFormVisible: false,
        categoryId: '', // 产品分类id
        tableData: [],
        productKeyEditDTOList: [], //产品标签数据
        checkedEquipments: [],
        checkList: [],
        radioArray: [],
        keyChildEditVOList: [],
        labelList: [],
        isRelate: false, //是否是关联标签
        test: [],
        tableData2: [],
        selLinkChildIds: [], // 第一次请求的数据选中时关联的linkedChildIds
        childLabelChecked: [], //关联标签勾选的值
      }
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
    },
    created() {},
    methods: {
      //展示弹框
      proLabelApplyModel(categoryId, keyChildEditVOList) {
        this.keyChildEditVOList = []
        this.tableData = []
        if (categoryId) {
          this.categoryId = categoryId
          this.getListForPopupByCategoryId()
        }

        if (keyChildEditVOList && keyChildEditVOList.length) {
          this.keyChildEditVOList = keyChildEditVOList
        }

        this.dialogFormVisible = true
      },
      // 标签弹框数据
      async getListForPopupByCategoryId() {
        let that = this
        let response = await listForPopupByCategoryId({
          categoryId: this.categoryId,
        })

        //判断标签中是否有关联标签
        let initData = response.data
        let initKeyParentId = [] //标签的id
        let initlinkedParentIds = [] //标签的关联id

        let requestParentIds = []

        const keyParentIds = initData.map((i) => i.keyParentId)
        initData.forEach((item) => {
          item.linkedParentIds &&
            initlinkedParentIds.push(...item.linkedParentIds.split(','))
        })

        initlinkedParentIds.forEach((item) => {
          if (keyParentIds.includes(item)) {
            requestParentIds.push(item)
          }
        })
        let isNoRelate = requestParentIds.length //标签内部是否有关联其他标签

        //如果没关联标签，那么标签就不受控制，所有标签都可以自由选择
        if (isNoRelate) {
          if ((response.code = '000000')) {
            this.tableData = response.data.map((item) => {
              if (item.multiple == '1') {
                return {
                  ...item,
                  value: [],
                }
              } else {
                return {
                  ...item,
                  value: '',
                }
              }
            })
            if (that.keyChildEditVOList.length) {
              that.tableData.forEach((item, index) => {
                if (
                  item.keyParentId == that.keyChildEditVOList[index].parentKeyId
                ) {
                  item.value =
                    item.multiple === '1'
                      ? that.keyChildEditVOList[index].childKeyIds.split(',')
                      : that.keyChildEditVOList[index].childKeyIds
                  item.productId =
                    that.keyChildEditVOList[index].productId || ''
                  item.editId =
                    that.keyChildEditVOList[index].productKeyId || ''
                }
              })
            }
            if (this.labelList.length) {
              this.tableData = this.labelList
            }
          }
        } else {
          //如果有关联标签，那也只能是一个主标签，根据其他关联标签（关联标签可能多个）
          //查找当前标签下面关联的其他标签
          let linkedParentIdsArr = []
          response.data.forEach((item, index) => {
            if (item.linkedParentIds) {
              linkedParentIdsArr.push(...item.linkedParentIds.split(','))
            }
          })

          let allRelateArr = [] //所有关联标签
          linkedParentIdsArr.forEach((item, index) => {
            allRelateArr.push(
              new Promise((resolve, reject) => {
                getDetailForEdit({ keyParentId: item }).then((response) => {
                  resolve(response.data)
                })
              })
            )
          })

          //主标签定义main=0，主标签里面的关联标签定义main=1
          Promise.all(allRelateArr).then((result, index) => {
            this.tableData2 = result
            this.tableData2.forEach((item, index) => {
              item.keyChildEditVOList.forEach((item2, index2) => {
                item2.disabled = true
              })
              item.isMain = '0'
            })
            initData.forEach((item, index) => {
              item.keyChildEditVOList.forEach((item2, index2) => {
                item2.disabled = false
              })
              item.isMain = '1'
            })

            let mergeData = initData.concat(this.tableData2)
            this.tableData = mergeData.map((item) => {
              if (item.multiple == '1') {
                return {
                  ...item,
                  value: [],
                }
              } else {
                return {
                  ...item,
                  value: '',
                }
              }
            })
            //编辑回显
            if (that.keyChildEditVOList.length) {
              this.tableData.forEach((item, index) => {
                if (
                  item.keyParentId == that.keyChildEditVOList[index].parentKeyId
                ) {
                  item.value =
                    item.multiple === '1'
                      ? that.keyChildEditVOList[index].childKeyIds.split(',')
                      : that.keyChildEditVOList[index].childKeyIds
                  item.productId =
                    that.keyChildEditVOList[index].productId || ''
                  item.editId =
                    that.keyChildEditVOList[index].productKeyId || ''
                }
              })

              let childLabelChecked = [] //选中的关联标签的id
              let linkedChildIds = [] //主标签选中的id关联的id
              //对选中的id回显，对未选中的id变为可选

              let copyTableData = JSON.parse(JSON.stringify(this.tableData))
              copyTableData.forEach((item) => {
                //主标签选中的id
                if (
                  item.value &&
                  item.value.length > 0 &&
                  item.isMain === '1'
                ) {
                  let value = Array.isArray(item.value)
                    ? item.value
                    : item.value.split(',')
                  value.forEach((child) => {
                    item.keyChildEditVOList.forEach((subItem) => {
                      if (subItem.keyChildId === child) {
                        subItem.keyLinkageEditVOList.forEach((j) => {
                          linkedChildIds.push(...j.linkedChildIds.split(','))
                        })
                      }
                    })
                  })
                }
                this.selLinkChildIds = Array.from(new Set(linkedChildIds))

                //关联标签选中的id及未选中的id
                if (item.isMain === '0') {
                  let value = item.value
                  value.forEach((child) => {
                    childLabelChecked.push(child)
                  })
                  item.keyChildEditVOList.forEach((item2) => {
                    item2.disabled = true
                    if (
                      this.selLinkChildIds &&
                      this.selLinkChildIds.includes(item2.keyChildId)
                    ) {
                      item2.disabled = false
                    }
                  })
                }
              })

              this.tableData = copyTableData
            }
            if (this.labelList.length) {
              this.tableData = this.labelList
            }
          })
        }
      },
      //主标签选择（选中主标签，同时释放该主标签关联标签的选项）
      mainChange(isMain, linkedParentIds) {
        if (isMain === '0' || (isMain === '1' && linkedParentIds === '')) {
          return
        }
        // 获取tableData中选中的
        let linkedChildIds = []

        //关联标签的id
        this.tableData.forEach((item) => {
          if (item.value && item.value.length > 0 && item.isMain === '1') {
            let value = Array.isArray(item.value)
              ? item.value
              : item.value.split(',')
            value.forEach((child) => {
              item.keyChildEditVOList.forEach((subItem) => {
                if (subItem.keyChildId === child) {
                  subItem.keyLinkageEditVOList.forEach((j) => {
                    linkedChildIds.push(...j.linkedChildIds.split(','))
                  })
                }
              })
            })
          }
        })
        this.selLinkChildIds = Array.from(new Set(linkedChildIds))
        let childLabelChecked = []

        let copyTableData = JSON.parse(JSON.stringify(this.tableData))
        copyTableData.forEach((item) => {
          if (item.isMain === '0') {
            if (item.value && item.value.length > 0) {
              let value = item.value
              value.forEach((child) => {
                childLabelChecked.push(child)
              })
            }
            item.keyChildEditVOList.forEach((item2) => {
              if (
                this.selLinkChildIds &&
                this.selLinkChildIds.includes(item2.keyChildId)
              ) {
                item2.disabled = false
              } else {
                item2.disabled = true
                if (item.multiple === '1') {
                  item.value = []
                } else {
                  item.value = ''
                }
              }
            })
          }
        })
        this.tableData = copyTableData
      },

      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
      },
      // 保存
      save() {
        let flag = true
        for (let i in this.tableData) {
          const t = this.tableData[i]
          if (t.required === '1' && (t.value.length === 0 || t.value === '')) {
            flag = false
            return this.$baseMessage(
              // `请勾选${t.nameCn}字段`,
              `${this.$t('reqmsg.M2029')} ${
                this.settings === 'zh' ? t.nameCn : t.nameEn
              }${this.$t('reqmsg.M2030')}`,
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
        }

        if (flag) {
          this.labelList = deepCopy(this.tableData)
          //还要再判断有没有数据
          let productKeyEditDTOList = []
          this.tableData.forEach((item) => {
            let obj = {
              childKeyIds: Array.isArray(item.value)
                ? item.value.join(',')
                : item.value.toString(),
              parentKeyId: item.keyParentId,
              productKeyEditId: item.editId || '',
              productId: item.productId || '',
            }
            productKeyEditDTOList.push(obj)
          })
          this.$emit('productKeyEditDTOList', productKeyEditDTOList)
          this.close()
        }
      },
      // 关闭
      close() {
        this.labelList = []
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-radio {
      line-height: 32px;
    }
  }
</style>
