import request from '@/core/services/request'

/**
 * 新建评价
 * @param {*} data
 * @returns
 */
export function insertPO(data = {}) {
  return request({
    url: '/merchants/customerComplain/insertPO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/**
 * 编辑
 */
export function updateCustomerComplainPO(data = {}) {
  return request({
    url: '/merchants/customerComplain/updateCustomerComplainPO',
    method: 'post',
    data,
  })
}

/**
 * 根据业务ID删除
 */

export function deleteById(businessId = '') {
  return request({
    url: '/merchants/customerComplain/deleteById',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 申请投诉完结
 */

export function applyDone(data = {}) {
  return request({
    url: '/merchants/customerComplain/applyDone',
    method: 'post',
    data,
  })
}

/**
 * 业务回复或客户反馈追加
 */

export function feedbackOrAppend(data = {}) {
  return request({
    url: '/merchants/customerComplain/feedbackOrAppend',
    method: 'post',
    data,
  })
}

/**
 * 根据客诉id查找客诉记录
 */

export function getCustomerComplainRecords(businessId = '') {
  return request({
    url: '/merchants/customerComplain/getCustomerComplainRecords',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 根据客诉详情
 */
export function getDetail(businessId = '') {
  return request({
    url: '/merchants/customerComplain/getDetail',
    method: 'post',
    params: {
      businessId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 根据分页查找列表
 */
export function listPage(data = {}) {
  return request({
    url: '/merchants/customerComplain/listPage',
    method: 'post',
    data,
  })
}

// 新增客评，查询客户订单产品信息
export function complainOrderProductDTO(data) {
  return request({
    url: '/order/salesOrder/complainOrderProductDTO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 编辑客评校验
export function checkUpdateCustomerComplain(data) {
  return request({
    url: '/merchants/customerComplain/checkUpdateCustomerComplain',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
