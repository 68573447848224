<template>
  <el-dialog
    title="新增询盘"
    width="1200px"
    class="address"
    :visible.sync="addVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div class="inquiry-content">
      <el-form :model="form" :rules="rules" ref="InquiryAddForm">
        <h4 class="mt0">基本信息</h4>
        <el-row :gutter="24" class="mt20">
          <el-col :span="8">
            <el-form-item
              label="出口抬头"
              prop="exportsRiseId"
              label-width="80px"
              class="label-left"
            >
              <el-select
                v-model="form.exportsRiseId"
                placeholder="请选择"
                clearable
                @change="exportsRiseChange"
              >
                <el-option
                  v-for="item in exportsRiseList"
                  :key="item.amountId"
                  :label="item.amountShort"
                  :value="item.amountId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="询盘来源"
              prop="enquirySourceId"
              label-width="90px"
            >
              <el-select
                v-model="form.enquirySourceId"
                placeholder="请选择"
                @change="enquirySourceChange"
                clearable
              >
                <el-option
                  v-for="item in enquirySourceNameList"
                  :key="item.amountId"
                  :label="item.amountName"
                  :value="item.amountId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="客户订单号"
              prop="customerOrderCode"
              label-width="110px"
            >
              <el-input
                v-model="form.customerOrderCode"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <h4>客户信息</h4>
        <div class="c-table-wrap">
          <table class="custom-table">
            <thead>
              <tr>
                <th>
                  <span>
                    <i>*</i>
                    客户姓名/编码
                  </span>
                </th>
                <th>
                  <span>
                    <i>*</i>
                    邮箱
                  </span>
                </th>
                <th>
                  <span>
                    <i>*</i>
                    公司名称
                  </span>
                </th>
                <th>
                  <span>
                    <i>*</i>
                    联系电话
                  </span>
                </th>
                <th>MKT备注</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <el-form-item prop="customerName">
                    <el-autocomplete
                      v-model="form.customerName"
                      :fetch-suggestions="querySearchAsync"
                      placeholder="请输入"
                      @select="handleSelect"
                      clearable
                    ></el-autocomplete>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item prop="customerEmail">
                    <el-input
                      v-model="form.customerEmail"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item prop="companyName">
                    <el-input
                      v-model="form.companyName"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item prop="customerPhone">
                    <el-input
                      v-model="form.customerPhone"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item prop="customerRemark">
                    <el-input
                      v-model="form.customerRemark"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p class="mt10 mb10">需求描述</p>
        <el-input
          type="textarea"
          v-model="form.demand"
          maxlength="500"
          show-word-limit
          :rows="3"
        ></el-input>
        <div class="inquiry-title">
          <h4>产品列表</h4>
          <el-button @click="getProduct" type="primary">选取产品</el-button>
        </div>
        <div class="pro-list">
          <!-- <div class="pro-btn">
            <el-button @click="add">新增产品</el-button>
          </div> -->
          <div class="c-table-wrap">
            <table class="custom-table mt10">
              <thead>
                <tr>
                  <th width="60">序号</th>
                  <th width="130">Item No.</th>
                  <th width="180" minWidth="150">
                    <span>
                      <i>*</i>
                      Item Name
                    </span>
                  </th>
                  <th width="130" minWidth="150">规格</th>
                  <th width="110">
                    <span>
                      <i>*</i>
                      Qty
                    </span>
                  </th>
                  <th width="180" minWidth="150">需求描述</th>
                  <th width="120" minWidth="120">Net price($)</th>
                  <th width="140" minWidth="120">Set up Charge($)</th>
                  <th width="130" minWidth="120">Shipping Fee($)</th>
                  <th width="120" minWidth="120">Total Price($)</th>
                  <th width="80">操作</th>
                </tr>
              </thead>
              <tbody v-if="form.products.length">
                <tr v-for="(item, i) in form.products" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.productCode || '--' }}</td>
                  <td>
                    <el-form-item
                      :prop="'products.' + i + '.productEn'"
                      :rules="[
                        {
                          required: true,
                          message: 'Item Name必填',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^[^\u4e00-\u9fa5]+$/,
                          message: '请输入英文',
                          trigger: 'blur',
                        },
                        {
                          max: 100,
                          message: 'Item Name长度不能超过100个字符',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.products[i].productEn"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="'products.' + i + '.specification'"
                      :rules="[
                        {
                          required: false,
                          max: 50,
                          message: '规格长度不能超过50个字符',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.products[i].specification"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="'products.' + i + '.amount'"
                      :rules="[
                        {
                          required: true,
                          message: 'Qty必填',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^[0-9]+?$/,
                          message: '请输入>=0的整数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="10"
                        v-model="form.products[i].amount"
                        placeholder="请输入"
                        clearable
                        @change="calculateTotal(i)"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="'products.' + i + '.demand'"
                      :rules="[
                        {
                          required: false,
                          max: 500,
                          message: '需求描述长度不能超过500个字符',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.products[i].demand"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="'products.' + i + '.netPrice'"
                      :rules="[
                        {
                          required: false,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: '>=0最多三位小数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="form.products[i].netPrice"
                        placeholder="请输入"
                        @change="calculateTotal(i)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="'products.' + i + '.setUpCharge'"
                      :rules="[
                        {
                          required: false,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: '>=0最多三位小数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="form.products[i].setUpCharge"
                        placeholder="请输入"
                        @change="calculateTotal(i)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="'products.' + i + '.shippingFee'"
                      :rules="[
                        {
                          required: false,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: '>=0最多三位小数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="form.products[i].shippingFee"
                        placeholder="请输入"
                        @change="calculateTotal(i)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-input
                      :disabled="true"
                      v-model="form.products[i].totalPrice"
                    ></el-input>
                  </td>
                  <td class="text-center">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="del(i)"
                    ></el-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="empty" v-if="!form.products.length">暂无数据</div>
          </div>
        </div>

        <div class="inquiry-mail">
          <div>
            <el-checkbox v-model="sendFlag">
              同时将询盘邮件转发至客户代表邮箱
            </el-checkbox>
          </div>
          <div>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item
                  label="客户代表"
                  :rules="
                    sendFlag
                      ? [
                          {
                            required: true,
                            message: '客户代表必填',
                            trigger: 'change',
                          },
                        ]
                      : [{ required: false }]
                  "
                  label-width="80px"
                  prop="businessName"
                >
                  <el-input
                    v-model="form.businessName"
                    placeholder="请选择"
                    @focus="businessChange"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="客户代表邮箱"
                  :rules="
                    sendFlag
                      ? [
                          {
                            required: true,
                            message: '客户代表邮箱必填',
                            trigger: 'change',
                          },
                        ]
                      : [{ required: false }]
                  "
                  prop="businessEmail"
                  label-width="90px"
                >
                  <el-select v-model="form.businessEmail" placeholder="请选择">
                    <el-option
                      v-for="item in mailOptions"
                      :key="item.emailAccount"
                      :value="item.emailAccount"
                      :label="item.emailAccount"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- <p class="mt20">附件</p>
        <ErpUpload
          :uploadParams="uploadParams"
          @uploadList="uploadList"
        ></ErpUpload> -->
      </el-form>
    </div>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 选取产品 -->
    <FastQuotedPrice
      ref="FastQuotedPrice"
      title="选取产品"
      @chooseProduct="chooseProduct"
    />

    <div class="inquiry-footer">
      <el-button @click="createInquiry" type="primary">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  orderGetDict,
  selectAllCustomer,
  orderEnquiryAdd,
  orderEnquiryUpdate,
  orderEnquiryDetail,
} from '@/api/order'
import UserChoose from '@/components/userChoose'
import FastQuotedPrice from '@/components/FastQuotedPrice'
import { getMailAccountListByUserId } from '@/api/mail/setting.js'
export default {
  name: 'inquiry-add',
  components: {
    UserChoose,
    FastQuotedPrice,
  },
  data() {
    return {
      sendFlag: false,
      addVisible: false,
      mailOptions: [],
      form: {
        /**
         * 基本信息
         */
        exportsRise: '', //出口抬头
        exportsRiseId: '', //出口抬头id
        enquirySourceName: '', //询盘来源
        enquirySourceId: '', //询盘来源id
        businessId: '', //客户代表id
        businessName: '', //客户代表名称
        customerOrderCode: '', //客户订单号
        /**
         * 客户信息
         */
        customerName: '', //客户姓名
        customerCode: '', //客户编码
        customerEmail: '', //邮箱
        companyName: '', //公司名称
        customerPhone: '', //联系电话
        customerRemark: '', //客户备注
        demand: '', //需求描述
        /**
         * 产品列表
         */
        products: [],
        /**
         * 附件
         */
        accessoryUrl: '',

        businessEmail: '',
      },
      enquiryId: '',
      enquiryCode: '',
      businessArray: [],
      exportsRiseList: [],
      enquirySourceNameList: [],
      productList: {},
      offerSheetInfo: { enquiryIds: '', isBatch: true },
      rules: {
        // 基本信息
        exportsRiseId: [
          { required: true, message: '请选择出口抬头', trigger: 'change' },
        ],
        enquirySourceId: [
          { required: true, message: '请选择询盘来源', trigger: 'change' },
        ],
        customerOrderCode: [
          { required: false, trigger: 'blur' },
          { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
        ],
        // 客户信息
        customerName: [
          { required: true, message: '客户姓名/编码必填', trigger: 'change' },
          { max: 50, message: '长度不能超过50个字符', trigger: 'change' },
        ],
        customerEmail: [
          { required: true, message: '邮箱必填', trigger: 'blur' },
          {
            validator: this.$formValidation.isEmail,
            trigger: 'blur',
          },
        ],
        companyName: [
          { required: true, message: '公司名称必填', trigger: 'blur' },
          { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
        ],
        customerPhone: [
          { required: true, message: '联系电话必填', trigger: 'blur' },
          { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
        ],
        customerRemark: [
          { required: false, trigger: 'blur' },
          { max: 500, message: '长度不能超过500个字符', trigger: 'blur' },
        ],
        businessEmail: [
          { required: true, message: '客户代表邮箱必填', trigger: 'change' },
        ],
        businessName: [
          { required: true, message: '客户代表必填', trigger: 'change' },
        ],
      },
      isEdit: false,
      offerSheetVisible: false,
      uploadParams: {
        type: 1, // 1按钮上传, 2图片上传
        size: 1024 * 1024 * 20, //单个文件上传大小
        format: 'pdf、word、excel、txt、jpg、png、bmp、gif、rar、zip', //支持格式
      },
    }
  },
  created() {},
  watch: {},
  methods: {
    showModal() {
      this.getDict()
      this.form.products = []
      this.addVisible = true
    },

    close() {
      this.sendFlag = false
      this.$refs.InquiryAddForm.resetFields()
      this.addVisible = false
    },

    // 获取出口抬头，询盘来源字典项
    getDict() {
      let that = this
      orderGetDict({ amountType: 1 }).then((res) => {
        that.exportsRiseList = res.data
      })
      orderGetDict({ amountType: 2 }).then((res) => {
        that.enquirySourceNameList = res.data
      })
    },

    // 选择出口抬头
    exportsRiseChange(val) {
      this.exportsRiseList.find((item) => {
        if (item.amountId == val) {
          this.form.exportsRise = item.amountShort
        }
      })
    },

    // 选择询盘来源
    enquirySourceChange(val) {
      this.enquirySourceNameList.find((item) => {
        if (item.amountId == val) {
          this.form.enquirySourceName = item.amountName
        }
      })
    },

    // 编辑初始化数据
    getEdit(id) {
      let that = this
      orderEnquiryDetail({ businessId: id }).then((res) => {
        const data = res.data
        that.form.exportsRise = data.exportsRise
        that.form.exportsRiseId = data.exportsRiseId
        that.form.enquirySourceName = data.enquirySourceName
        that.form.enquirySourceId = data.enquirySourceId
        that.form.businessId = data.businessId
        that.form.businessName = data.businessName
        that.form.customerOrderCode = data.customerOrderCode
        that.form.customerName = data.customerName
        that.form.customerEmail = data.customerEmail
        that.form.companyName = data.companyName
        that.form.customerPhone = data.customerPhone
        that.form.customerRemark = data.customerRemark
        that.form.demand = data.demand
        that.form.products = data.products
        that.form.infoId = data.infoId
      })
    },

    querySearchAsync(queryString, cb) {
      let that = this
      selectAllCustomer({ linkman: queryString }).then((res) => {
        let results = res.data.map((item) => {
          return {
            ...item,
            value: item.linkman,
          }
        })
        cb(results)
      })
    },

    handleSelect(item) {
      let that = this
      that.form.infoId = item.infoId
      that.form.customerName = item.linkman
      that.form.customerCode = item.customerCode
      that.form.customerEmail = item.email
      that.form.companyName = item.companyName
      that.form.customerPhone = item.phone
      that.form.customerRemark = item.remark
    },

    // 返回上一页
    goBack() {
      this.$router.push('/order/inquiryManage/inquiryList')
    },

    // 选择客户代表
    businessChange() {
      this.$refs['UserChoose'].showAddEdit('1', this.businessArray)
    },

    // 获取客户代表
    chooseUser(userIdArr) {
      let that = this
      that.businessArray = userIdArr
      that.form.businessId = userIdArr[0].id
      that.form.businessName = userIdArr[0].name
      getMailAccountListByUserId({ businessId: that.form.businessId }).then(
        (res) => {
          if (res.code === '000000') {
            this.form.businessEmail = ''
            this.mailOptions = res.data
          }
        }
      )
    },

    // 选取产品
    getProduct() {
      this.$refs['FastQuotedPrice'].showAddEdit('showSaveBtn')
    },

    // 获取产品信息
    chooseProduct(data) {
      let product = data.product[0]
      let sku = ''
      // 格式化数据
      if (product.productSpecsEditVOList.length == 1) {
        sku = product.productSpecsEditVOList[0].sku
      } else {
        const list = product.productSpecsEditVOList
        list.forEach((item, i) => {
          if (
            item.specsValue1 == data.specsValue1 &&
            item.specsValue2 == data.specsValue2
          ) {
            sku = item.sku
          }
        })
      }
      this.form.products.push({
        productCode: product.productCode, //Item No
        productEn: product.nameEn, //Item Name 英文
        productCn: product.nameCn, //Item Name 中文
        specification: product.specsType + product.specsValue, //规格
        amount: data.amount, //Qty
        demand: '', //需求描述
        netPrice: '', //Net price($)
        setUpCharge: '', //Set up Charge($) 印刷费+版费
        shippingFee: data.allPrice.transportPrice, //Shipping Fee($)
        totalPrice: '', //Total Price($)
        printingWay: data.productPrintingPricingId, // 印刷方式
        standardCustomized: product.standardCustomized, //产品类型
        portShipment: '', // 出货地
        portTime: '', // 出货日期
        pricing: '', // 计价方式
        digitSelect: '', // 印刷位
        productId: product.productId, // 产品Id
        shippingAddress: data.freightArea, // 收货地址
        sku: sku,
      })
    },

    // 计算totalPrice = Qty*netPrice + setUpCharge + shippingFee
    calculateTotal(i) {
      let that = this
      let products = that.form.products
      if (products[i].amount && products[i].netPrice) {
        products[i].totalPrice = (
          Number(products[i].amount * products[i].netPrice) +
          Number(products[i].setUpCharge) +
          Number(products[i].shippingFee)
        ).toFixed(3)
      } else {
        products[i].totalPrice = ''
      }
    },

    // 新增产品
    add() {
      let that = this
      that.form.products.push({
        productCode: '', //Item No
        productEn: '', //Item Name
        specification: '', //规格
        amount: '', //Qty
        demand: '', //需求描述
        netPrice: '', //Net price($)
        setUpCharge: '', //Set up Charge($)
        shippingFee: '', //Shipping Fee($)
        totalPrice: '', //Total Price($)
      })
    },

    // 删除产品
    del(i) {
      let that = this
      that
        .$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          that.form.products.splice(i, 1)
        })
    },

    // 获取上传文件地址
    uploadList(fileList) {
      let urlArray = []
      fileList.forEach((item) => {
        urlArray.push(item.url)
      })
      this.form.accessoryUrl = urlArray.join(',')
    },

    // 生成询盘单
    createInquiry() {
      let that = this
      that.$refs['InquiryAddForm'].validate((valid) => {
        if (valid) {
          if (!that.form.products.length) {
            that.$message.warning('至少添加一条产品！')
            return false
          }
          let params = { ...that.form }
          orderEnquiryAdd(params).then((res) => {
            if (res.code == '000000') {
              that.$message.success('生成询盘单成功！')
              if (this.sendFlag) {
                this.$emit('generateInquire', {
                  enquiryCode: res.data.enquiryCode,
                  mail: this.form.businessEmail,
                })
                this.addVisible = false
              } else {
                this.$emit('generateInquire', {
                  enquiryCode: res.data.enquiryCode,
                  mail: '',
                })
                this.addVisible = false
              }
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.inquiry-content {
  padding: 0 10px 20px;
  .inquiry-title {
    line-height: 32px;
    display: flex;
    height: 32px;
    margin: 20px 0 10px 0;
    h4 {
      line-height: 32px;
      font-family: 'PingFang Bold';
      font-size: 14px;
      margin: 0 10px 0 0;
    }
  }
  .inquiry-mail {
    margin-top: 20px;
    & > div:first-child {
      margin-bottom: 10px;
    }
    & > div:last-child {
      padding: 20px 0;
      background: #f5f7fa;
      box-sizing: border-box;
    }
  }
  h4 {
    font-family: 'PingFang Bold';
    font-size: 14px;
    margin: 20px 0 10px 0;
    &.mt0 {
      margin: 0 0 10px 0;
    }
  }
  .el-input,
  .el-select {
    width: 100%;
  }
  .empty {
    width: 100%;
    border: 1px solid #ebeef5;
    border-top: none;
    text-align: center;
    line-height: 50px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
.offer-container {
  height: 600px;
  overflow-y: auto;
}
.oper-btn {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 20px 0;
  background: white;
}

.inquiry-footer {
  text-align: center;
}
</style>

