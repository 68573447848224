<template>
  <el-dialog :title="`${title || '快速报价'}`" :visible.sync="dialogFormVisible" width="60%" top="10vh" append-to-body
    :close-on-click-modal="false" @close="close">
    <div class="dialog-content">
      <!-- 搜索信息 -->
      <el-row type="flex" align="middle" :gutter="20">
        <el-col :span="12" class="el-form">
          <el-row type="flex" align="middle">
            <label>
              <i>*</i>
              产品编码/英文名称
            </label>
            <el-autocomplete v-model.trim="codeOrName" :fetch-suggestions="querySearchAsync" class="ml10 w100"
              placeholder="请输入" :trigger-on-focus="triggerBol" clearable
              @select="productHandleSelect"></el-autocomplete>
          </el-row>
        </el-col>
      </el-row>

      <el-row class="mt10">
        <el-tag v-for="(pro, index) in productTags" :key="pro + index" closable size="medium" @close="handleClose(pro)">
          {{ `${pro.productCode}&ensp;/&ensp;${pro.nameCn}（${pro.nameEn}）` }}
        </el-tag>
      </el-row>

      <!-- 报价信息 -->
      <el-row v-if="productTags && productTags.length">
        <div class="pro-container">
          <el-row class="mt15 testData-row" v-if="testData && testData.length" :style="'width:' + testDataWidth + '%'">
            <table class="custom-table" style="width: 100%">
              <thead>
                <tr>
                  <th colspan="1" rowspan="2" style="width: 50px">数量</th>
                  <th colspan="1" rowspan="2">Net Price</th>
                  <th colspan="1" rowspan="2">List Price</th>
                  <th v-show="
                      testData[0].setUpCharge && testData[0].setUpCharge.length
                    " :colspan="testData[0].setUpCharge.length">
                    Set-up Charge
                  </th>
                </tr>
                <tr v-show="
                    testData[0].setUpCharge && testData[0].setUpCharge.length
                  ">
                  <th v-for="(item, index) of testData[0].setUpCharge" :key="index">
                    {{ item.printWay }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) of testData" :key="index">
                  <td style="width: 50px">
                    {{ item.num }}
                  </td>
                  <td>
                    <p v-for="(net, netIndex) of item.netPrice" :key="netIndex">
                      {{ net }}
                    </p>
                  </td>
                  <td>
                    <p v-for="(list, listIndex) of item.listPrice" :key="listIndex">
                      {{ list }}
                    </p>
                  </td>
                  <td v-show="
                      testData[0].setUpCharge && testData[0].setUpCharge.length
                    " v-for="(item2, index2) of item.setUpCharge" :key="index2">
                    <el-tooltip placement="top" effect="light">
                      <div slot="content" class="tooltip-content">
                        <p v-for="(
                            charge, chargeIndex
                          ) of item2.printWayValueList" :key="chargeIndex">
                          {{ charge }}
                        </p>
                      </div>
                      <el-row class="row-charge">
                        <p v-for="(
                            charge, chargeIndex
                          ) of item2.printWayValueList" :key="chargeIndex">
                          {{ charge }}
                          <i v-show="chargeIndex === 4">...</i>
                        </p>
                      </el-row>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-row>
          <!-- 规格,判断单规格还是多规格 -->
          <el-row v-if="infoParames.specsType && infoParames.specsValue">
            <!-- 规格1 -->
            <el-row class="mt15 spec-row" type="flex" align="middle"
              v-if="specArray[0] && specArray[0].type && specArray[0].value">
              <label class="mr15">
                <i class="price-red">*</i>
                {{ specArray[0].type }}
              </label>
              <el-row>
                <el-tag v-for="(tag1, tagIndex1) in specArray[0].value" :key="tagIndex1" v-model="specsValue1"
                  size="medium" type="info" class="c_pointer" :class="{ active: tagIndex1 === specValueCurrentIndex1 }"
                  @click="specClick1(tag1, tagIndex1)">
                  {{ tag1 }}
                </el-tag>
              </el-row>
            </el-row>
            <!-- 规格2 -->
            <el-row class="mt15 spec-row" type="flex" align="middle"
              v-if="specArray[1] && specArray[1].type && specArray[1].value">
              <label class="mr15">
                <i class="price-red">*</i>
                {{ specArray[1].type }}
              </label>
              <el-row>
                <el-tag v-for="(tag2, tagIndex2) in specArray[1].value" :key="tagIndex2" v-model="specsValue2"
                  size="medium" type="info" class="c_pointer" :class="{ active: tagIndex2 === specValueCurrentIndex2 }"
                  @click="specClick2(tag2, tagIndex2)">
                  {{ tag2 }}
                </el-tag>
              </el-row>
            </el-row>
          </el-row>

          <!-- 数量 -->
          <el-row class="mt15">
            <el-row type="flex" align="middle">
              <label>
                <i class="price-red">*</i>
                数量
              </label>
              <el-input v-model="numVal" oninput="value=value.replace(/[^0-9]/g,'')" class="ml10 no-number w_200"
                placeholder="请输入" clearable type="text" @input="getAreaSelect" maxlength="10" />
            </el-row>
            <el-row style="display: none" class="mt10" type="flex" v-if="
                infoParames.productPricingStepVOList &&
                infoParames.productPricingStepVOList.length
              ">
              <table class="product_info_table" style="margin-left: 38px">
                <tr>
                  <td style="width: 15%">数量</td>
                  <td v-for="(
                      item, index
                    ) of infoParames.productPricingStepVOList" :key="index">
                    {{ item.num }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 15%">产品价($)</td>
                  <td v-for="(
                      item, index
                    ) of infoParames.productPricingStepVOList" :key="index">
                    {{ item.price }}
                  </td>
                </tr>
              </table>
            </el-row>
          </el-row>

          <!-- 印刷 -->
          <el-row type="flex" class="pt20 mb20 align_center">
            <h4>
              <i class="price-red">*</i>
              印刷方式
            </h4>
            <el-tag v-for="(tag, index) in printWay" :key="index" size="medium" class="c_pointer ml10" type="info"
              :class="{
                active: tag.productPrintingPricingId === currentIndex,
              }" @click="tagsClick(tag, index)">
              {{ tag.nameEn || tag.nameCn }}
            </el-tag>
          </el-row>
          <!-- 印刷位 -->
          <el-row v-for="(item, index) of productPrintingWayVOList" :key="index">
            <template v-if="item.productPrintingPricingId === currentIndex">
              <el-row type="flex" align="middle">
                <h4>
                  <i class="price-red">*</i>
                  印刷位
                </h4>
                <el-checkbox-group v-model="positionNum" class="ml10">
                  <el-checkbox-button v-for="(tag, tagIndex) in item.printingPositions" :key="tagIndex"
                    :label="tag.nameEn"></el-checkbox-button>
                </el-checkbox-group>
              </el-row>
              <el-row class="mt20 mb20" type="flex" align="middle">
                <h4>
                  <i class="price-red">*</i>
                  计价方式
                </h4>
                <el-select class="ml10" v-model="item.printValue" placeholder="请选择" style="width: 200px" collapse-tags
                  @change="test(item.productPrintingPricingId)">
                  <el-option v-for="(item2, k) in JSON.parse(item.printingWay)" :key="k" :label="item2.way"
                    :value="item2.way" />
                </el-select>
              </el-row>
            </template>
          </el-row>

          <!-- 收货地址 -->
          <el-row type="flex" class="pt20 align_center address-row" style="border-top: 1px solid #ededed">
            <el-col :span="9">
              <el-row type="flex" align="middle">
                <h4>
                  <i class="price-red">*</i>
                  运输方式
                </h4>
                <el-select v-model="form.freightSettingId" placeholder="请选择" style="width: 200px"
                  @change="transportWayChange">
                  <el-option v-for="(item, index) in transportWayList" :key="index" :label="item.transportWayCn"
                    :value="item.freightSettingId" />
                </el-select>
              </el-row>
            </el-col>
            <el-col :span="9">
              <el-row type="flex" align="middle">
                <h4>
                  <i class="price-red">*</i>
                  收货地址
                </h4>
                <el-select v-model="form.freightArea" placeholder="请选择" style="width: 200px">
                  <el-option v-for="(item, index) in deliveryAreaList" :key="index" :label="item" :value="item" />
                </el-select>
              </el-row>
            </el-col>

            <el-col :span="6">
              <el-row type="flex" align="middle">
                <h4>尾程运费</h4>
                <el-input v-model="tailFee" oninput="value=value.replace(/[^0-9.]/g,'')" class="ml10 no-number"
                  placeholder="请输入" clearable type="text" @input="endFreightChange" />
              </el-row>
            </el-col>
          </el-row>

          <!-- 总计费用 -->
          <el-row class="mt20 pt20" style="border-top: 1px solid #ededed">
            <el-row type="flex" class="align_center">
              <el-col>
                <el-button type="primary" :loading="calcuClickLoading" @click="calcuClick(1)">
                  总计费用计算
                </el-button>
              </el-col>
            </el-row>
            <el-row class="mt20 align_center calcu-result" type="flex">
              <p>
                <label>&ensp;Net Price</label>
                <el-popover placement="top-start" width="200" trigger="hover">
                  <div>
                    <p>产品价：{{ allPrice.productPrice }}</p>
                    <p class="mt5">税费：{{ allPrice.tax }}</p>
                    <p class="mt5">运费：{{ allPrice.transportPrice }}</p>
                  </div>
                  <span slot="reference" class="price-red ml0 c_pointer">
                    {{ allPrice.netPrice || 0 }}
                  </span>
                </el-popover>
              </p>
              <p>
                <label>List Price</label>
                <span>{{ allPrice.listPrice || 0 }}</span>
              </p>
              <p>
                <label>印刷费</label>
                <span>{{ allPrice.printingPrice || 0 }}</span>
              </p>
              <p>
                <label>版费</label>
                <span>{{ allPrice.toolingCharge || 0 }}</span>
              </p>
              <p v-if="tailFee">
                <label>尾程运费</label>
                <span>{{ tailFee }}</span>
              </p>
              <p>
                <label>预计收货周期</label>
                <span class="specical-span">
                  {{ allPrice.transportDay || 0 }}天
                </span>
              </p>
            </el-row>
            <el-row class="mt20 align_cente calcu-result" type="flex">
              <p>
                <label>&ensp;总金额</label>
                <el-popover placement="top-start" trigger="hover">
                  <div>
                    <p>总金额：Net Price x 数量 + 印刷费 + 版费 + 尾程运费</p>
                  </div>

                  <span slot="reference" class="price-red ml0 c_pointer">
                    {{ allPrice.totalPrice || 0 }}
                  </span>
                </el-popover>
              </p>
            </el-row>
          </el-row>
        </div>
      </el-row>
      <el-row v-else class="empty-content">还未搜索产品信息...</el-row>
    </div>
    <template #footer>
      <el-button @click="chooseProduct" type="primary" v-if="isSaveBtn">
        确定
      </el-button>
      <el-button @click="close">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    calcProudctPrice,
    getDetailByCodeOrName2,
    getAreaSelect,
    listProductByParam,
    listDeliveryArea,
  } from '@/api/product/productList'
  import { listProductSettingByIds } from '@/api/product/productSetting'
  export default {
    name: 'FastQuotedPrice',
    props: ['title'],
    data() {
      return {
        dialogFormVisible: false,
        codeOrName: '', //根据产品名称、编码进行搜索
        form: {
          positionNum: null, //印刷位数
          productPrintingPricingId: '', //印刷方式id
          pricingValue: '', //计价方式
          productId: null, //产品id
          endFreight: null, //尾程运费
        },
        infoParames: {}, //产品详情
        productTags: [], //产品数组里面最多只有一个产品数据
        numVal: '', //绑定的数量
        specsValue2: '', //规格2绑定的值，与上面组成一个数组
        specValueCurrentIndex1: null, //规格1选中
        specValueCurrentIndex2: null, //规格2选中
        printWay: [], //印刷方式（blank，sample等）
        productPrintingWayVOList: [], //印刷方式对应的印刷计价
        currentIndex: 0,
        positionNum: [],
        //listFreightArea: [], //收货地址下拉
        deliveryAreaList: [], //收货地址下拉
        transportWayList: [], //运输方式下拉
        allPrice: {}, //价格计算
        calcuClickLoading: false, //计算按钮加载
        hideCharges: false, //其他费用的隐藏显示
        specsValue1: '', //规格1绑定的值
        isSaveBtn: false, //是否显示确定按钮
        isCheck: false,
        positionNumCurrent: null, //印刷位
        noPositionNum: false,
        triggerBol: false,
        testData: [],
        numVal2: '',
        testDataWidth: 75,
        tailFee: null, //尾程运费
        initTotalPrice: 0,
      }
    },
    computed: {
      typeArr: {
        get() {
          if (this.infoParames.specsType) {
            let typearr = this.infoParames.specsType.split(',')
            return typearr
          }
        },
      },
      specArray: {
        get() {
          if (this.infoParames.specsType && this.infoParames.specsValue) {
            let typearr = this.infoParames.specsType.split(',')
            let svalue = this.infoParames.specsValue
            let repalcevalue = svalue.replace(/\'/g, '"')
            var valarr = JSON.parse(repalcevalue)
            let arr = []
            for (let i = 0; i < typearr.length; i++) {
              let obj = {
                type: typearr[i],
                value: valarr[i],
              }
              arr.push(obj)
            }
            return arr
          }
        },
      },
    },
    watch: {
      codeOrName: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            this.triggerBol = false
          }
        },
      },
    },
    created() { },
    methods: {
      //显示弹框
      showAddEdit(str) {
        if (str === 'showSaveBtn') {
          this.isSaveBtn = true
        }
        this.handleClose()
        this.dialogFormVisible = true
      },
      //搜索
      async querySearchAsync(queryString, cb) {
        if (!this.codeOrName) return
        await listProductByParam({ param: this.codeOrName }).then((res) => {
          let results = res.data.map((item) => {
            return {
              ...item,
              value: item.productCode + ' / ' + item.nameEn,
            }
          })
          cb(results)
        })
      },
      // 选择产品
      productHandleSelect(item) {
        if (item.productId) {
          this.handleClose()
          this.form.productId = item.productId
          this.getProductDetail(item.productId)
          //this.productTags.push(item)
        }

        //运输方式下拉
        //this.getlistFreightArea()
      },

      async query() {
        this.clearData()
        if (this.codeOrName === '') {
          return this.$baseMessage(
            `请输入完整的产品编码/名称`,
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        this.getProductDetail(this.codeOrName)
        //this.getlistFreightArea()
      },
      //产品详情
      async getProductDetail(productId) {
        let response = await getDetailByCodeOrName2({ param: productId })
        if ((response.code = '000000' && response.data)) {
          this.form.productId = response.data.productId
          this.productTags.push(response.data)
          this.infoParames = response.data

          if (
            this.infoParames.procuctStepForPrintingVOList ||
            this.infoParames.procuctStepForPrintingVOList.length
          ) {
            this.testData = []

            let test = this.infoParames.procuctStepForPrintingVOList
            test.forEach((item, index) => {
              if (item.setUpCharge) {
                if (item.setUpCharge.length >= 2) {
                  this.testDataWidth = 100
                }
                let test2 = []
                for (let i = 0; i < item.setUpCharge.length; i++) {
                  if (item.setUpCharge[i].printWayValueList.length) {
                    test2.push(item.setUpCharge[i])
                  }
                }
                item.setUpCharge = test2
              }
            })
            this.testData = test
          }

          //印刷方式sample，blank等，用#分割
          if (this.infoParames.printingWay) {
            let test = this.infoParames.printingWay.split('#')
            if (test[0] === '1') {
              this.printWay.push({
                productPrintingPricingId: '99991',
                nameCn: 'Blank',
              })
            }
            if (test[1] === '1') {
              this.printWay.push({
                productPrintingPricingId: '99992',
                nameCn: 'Sample',
              })
            }
            let printSelect = []
            printSelect = JSON.parse(test[2])
            if (printSelect.length) {
              printSelect.forEach((item) => {
                this.printWay.push(item)
              })
            }

            //计价去重
            // let productPricingStepVOList = infoParames.productPricingStepVOList

            let obj = {}
            this.infoParames.productPricingStepVOList =
              this.infoParames.productPricingStepVOList.reduce((cur, next) => {
                obj[next.num] ? '' : (obj[next.num] == true && cur.push(next))
                return cur
              }, [])

            //印刷方式对应的印刷计价
            if (this.infoParames.productPrintingWayVOList) {
              this.productPrintingWayVOList =
                this.infoParames.productPrintingWayVOList
              Object.assign(
                {},
                { ...this.productPrintingWayVOList, printValue: [] }
              )
              this.productPrintingWayVOList.forEach((item, index) => {
                item.nameCn = printSelect[index].nameCn
                this.getListProductSettingByIds(item.printingPositions, index)
              })
            }
          }
        }
      },
      endFreightChange(val) {
        if (this.initTotalPrice) {
          this.allPrice.totalPrice = this.initTotalPrice + Number(val)
        }
      },
      clearData() {
        this.infoParames = {}
        this.form = {
          positionNum: null,
          productPrintingPricingId: '',
          pricingValue: '',
          productId: null,
          endFreight: null,
        }
        this.specsValue1 = ''
        this.specsValue2 = ''
        this.numVal = ''
        this.productTags = []
        this.printWay = []
        this.productPrintingWayVOList = []
        this.currentIndex = 0
        this.specValueCurrentIndex1 = null
        this.specValueCurrentIndex2 = null
        this.allPrice = {}
        this.hideCharges = false
        this.isCheck = false
      },
      //删除产品
      handleClose() {
        this.codeOrName = ''
        this.clearData()
      },

      // 规格1选择
      specClick1(item, index) {
        this.form.specsValue1 = item
        this.specValueCurrentIndex1 = index
        this.getAreaSelect()
      },
      // 规格2选择
      specClick2(item, index) {
        this.specsValue2 = item
        this.specValueCurrentIndex2 = index
        this.getAreaSelect()
      },

      //印刷方式点击
      tagsClick(tag, index) {
        this.positionNum = []
        this.positionNumCurrent = null
        if (tag.nameCn.toUpperCase() === 'BLANK') {
          this.form.productPrintingPricingId = 'Blank'
        } else if (tag.nameCn === 'Sample') {
          this.form.productPrintingPricingId = 'Sample'
        } else {
          this.form.productPrintingPricingId = tag.productPrintingPricingId
        }
        this.currentIndex = tag.productPrintingPricingId
        this.childCurrentIndex = 0
        this.positionNum = []
        this.form.pricingValue = ''

        this.productPrintingWayVOList.forEach((item) => {
          if (item.printValue) {
            item.printValue = ''
          }
        })
        if (tag.nameCn.toUpperCase() === 'BLANK' || tag.nameCn === 'Sample') {
          this.noPositionNum = true
        } else {
          this.noPositionNum = false
        }
      },

      //印刷位点击
      positionNumClick(tag, index) {
        this.positionNum = tag.productSettingId
        this.positionNumCurrent = index
      },

      // 印刷位
      async getListProductSettingByIds(ids, index) {
        let response = await listProductSettingByIds({ productSettingIds: ids })
        this.productPrintingWayVOList[index].printingPositions = response.data
      },

      //收货地址
      async getAreaSelect() {
        if (
          this.specArray &&
          this.specArray.length === 1 &&
          !this.form.specsValue1
        ) {
          return
        }
        if (
          (this.specArray &&
            this.specArray.length === 2 &&
            !this.specsValue2) ||
          (this.specArray &&
            this.specArray.length === 2 &&
            !this.form.specsValue1)
        ) {
          return
        }
        if (this.numVal === '') {
          return
        }
        this.$set(this.form, 'freightArea', '')

        let specsNumList = [
          {
            num: Number(this.numVal),
            specsValue2: this.specsValue2 || '',
          },
        ]
        let params = {
          ...this.form,
          specsNumList: specsNumList,
        }

        if (this.specArray && this.specArray.length === 1) {
          if (params.specsValue1 === '' || params.specsNumList[0].num === 0) {
            return
          }
        }
        if (this.specArray && this.specArray.length === 2) {
          if (
            !params.specsValue1 ||
            params.specsValue1 === '' ||
            params.specsNumList[0].num === 0 ||
            params.specsNumList[0].specsValue2 === ''
          ) {
            return
          }
        }
        this.listFreightArea = []
        let response = await getAreaSelect(params)
        if (response.code === '000000') {
          //最优地址
          this.form.freightSettingId = response.data.freightSettingId
          this.transportWayList = response.data.transportWayVOList
          // //最优地区
          params.freightSettingId = response.data.freightSettingId
          // this.getListDeliveryArea(params)
          // this.form.freightArea = response.data.deliveryArea
          // this.deliveryAreaList = response.data.deliveryAreaList
        }
      },

      // 运输方式change
      transportWayChange(id) {
        if (
          this.specArray &&
          this.specArray.length === 1 &&
          !this.form.specsValue1
        ) {
          return
        }
        if (
          (this.specArray &&
            this.specArray.length === 2 &&
            !this.specsValue2) ||
          (this.specArray &&
            this.specArray.length === 2 &&
            !this.form.specsValue1)
        ) {
          return
        }
        if (this.numVal === '') {
          return
        }
        this.$set(this.form, 'freightArea', '')

        let specsNumList = [
          {
            num: Number(this.numVal),
            specsValue2: this.specsValue2 || '',
          },
        ]
        let params = {
          ...this.form,
          specsNumList: specsNumList,
        }

        if (this.specArray && this.specArray.length === 1) {
          if (params.specsValue1 === '' || params.specsNumList[0].num === 0) {
            return
          }
        }
        if (this.specArray && this.specArray.length === 2) {
          if (
            !params.specsValue1 ||
            params.specsValue1 === '' ||
            params.specsNumList[0].num === 0 ||
            params.specsNumList[0].specsValue2 === ''
          ) {
            return
          }
        }
        params.freightSettingId = id
        this.getListDeliveryArea(params)
      },

      //根据运输方式查地区
      async getListDeliveryArea(params) {
        this.deliveryAreaList = []
        this.form.freightArea = ''
        let response = await listDeliveryArea(params)
        if (response.code === '000000') {
          this.deliveryAreaList = response.data.deliveryAreaList
          this.form.freightArea = response.data.deliveryArea
        }
      },

      //总计费用计算
      calcuClick(type) {
        this.isCheck = false
        this.allPrice = {}
        if (
          this.specArray &&
          this.specArray.length &&
          typeof this.form.specsValue1 === 'undefined'
        ) {
          return this.$baseMessage(
            `请选择${this.specArray[0].type}`,
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        if (
          this.specArray &&
          this.specArray.length === 2 &&
          this.specsValue2 === ''
        ) {
          return this.$baseMessage(
            `请选择${this.specArray[1].type}`,
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        if (this.numVal === '') {
          return this.$baseMessage(
            '请输入数量',
            'error',
            false,
            'erp-hey-message-error'
          )
        }

        if (this.form.productPrintingPricingId === '') {
          return this.$baseMessage(
            '请选择印刷方式',
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        if (
          this.productPrintingWayVOList.length > 0 &&
          this.positionNum.length === 0 &&
          !this.noPositionNum
        ) {
          return this.$baseMessage(
            '请选择印刷位',
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        //1、判断当前有几个规格值，如果1个，那么第一行不显示
        this.form.positionNum = this.positionNum.length
        this.form.productPrintingPricingId = this.form.productPrintingPricingId
        let specsNumList = [
          {
            num: Number(this.numVal),
            specsValue2: this.specsValue2 || '',
          },
        ]

        let params = {
          ...this.form,
          specsNumList: specsNumList,
        }
        if (this.form.pricingValue === '' && !this.noPositionNum) {
          return this.$baseMessage(
            '请选择计价方式',
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        if (this.form.freightSettingId === '') {
          return this.$baseMessage(
            '请选择运输方式',
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        if (this.form.freightArea === '') {
          return this.$baseMessage(
            '请选择收货地址',
            'error',
            false,
            'erp-hey-message-error'
          )
        }
        this.calcuClickLoading = true

        this.getCalcProudctPrice(params, type)
      },

      test(id) {
        let pricingValue = ''
        this.productPrintingWayVOList.some((item) => {
          if (item.productPrintingPricingId == id) {
            pricingValue = item.printValue.toString()
          }
        })
        this.form.pricingValue = pricingValue
      },

      //价格计算
      async getCalcProudctPrice(params, type) {
        let response = await calcProudctPrice(params)
        this.calcuClickLoading = false
        if (response.code == '000000') {
          this.allPrice = response.data
          if (this.allPrice.totalPrice && this.tailFee) {
            this.initTotalPrice =
              this.allPrice.totalPrice - this.allPrice.endFreight

            this.allPrice.totalPrice =
              this.allPrice.totalPrice + Number(this.tailFee)
          }

          this.calcuClickLoading = false
          this.isCheck = true
          // 存在title表明当前是选取产品，确定需在调用一次
          if (this.title && type == 2) {
            this.chooseProduct()
          }
        }
      },

      hideChargesClick() {
        this.hideCharges = !this.hideCharges
      },

      //关闭弹框
      close() {
        this.handleClose()
        this.dialogFormVisible = false
      },

      // 获取运输方式
      getTransWayName(id) {
        let name = null
        this.transportWayList.find((item) => {
          if (item.freightSettingId == id) {
            name = item.transportWayCn
          }
        })

        return name
      },

      // 根据id查找印刷方式英文名称
      getPrintWayName(id) {
        let name = ''
        this.printWay.find((item) => {
          if (item.productPrintingPricingId == id) {
            name = item.nameEn || item.nameCn
          }
        })

        return name
      },

      chooseProduct() {
        let self = this
        if (!self.productTags.length) {
          return self.$baseMessage(
            '请搜索要添加的产品',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        // 根据规格筛选产品
        let product = [...self.productTags]
        let productInfo = null
        if (product[0].productSpecsEditVOList.length == 1) {
          productInfo = product[0].productSpecsEditVOList[0]
        } else {
          product[0].productSpecsEditVOList.find((item) => {
            if (
              item.specsValue1 == self.form.specsValue1 &&
              item.specsValue2 == self.specsValue2
            ) {
              productInfo = item
            }
          })
        }
        // 根据条件判断产品尺寸
        if (productInfo.sizeUnit === 'IN') {
          productInfo.demand =
            'Material:' +
            product[0].material +
            '; ProductSize:' +
            productInfo.productLengthCm +
            '"x' +
            productInfo.productWidthCm +
            '"x' +
            productInfo.productHeightCm +
            '"'
        } else {
          productInfo.demand =
            'Material:' +
            product[0].material +
            '; ProductSize:' +
            productInfo.productLengthIn +
            '"x' +
            productInfo.productWidthIn +
            '"x' +
            productInfo.productHeightIn +
            '"'
        }
        const data = {
          amount: self.numVal,
          specsValue1: self.form.specsValue1 || '',
          specsValue2: self.specsValue2 || '',
          freightArea: self.form.freightArea || '',
          productPrintingPricingId: self.form.productPrintingPricingId,
          allPrice: self.allPrice,
          transportationType: self.getTransWayName(self.form.freightSettingId),
          freightSettingId: self.form.freightSettingId,
          positionNum: self.form.positionNum,
          pricingValue: self.form.pricingValue,
          product: product,
          productInfo: productInfo,
          shippingFee: self.tailFee,
          printingWay:
            self.getPrintWayName(self.form.productPrintingPricingId) ||
            self.form.productPrintingPricingId,
        }
        if (self.isCheck) {
          self.$emit('chooseProduct', data)
        } else {
          self.calcuClick(2)
          return false
        }
        self.close()
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 20px;

    .el-form {
      label {
        white-space: nowrap;

        i {
          color: red;
        }
      }
    }
  }

  label {
    white-space: nowrap;
  }

  .spec-row {
    >.el-row {
      margin-left: -10px;
    }

    .el-tag {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }

  .empty-content {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // 规格

  ::v-deep {
    .el-select {
      width: 100%;
    }
  }

  h4 {
    font-weight: normal;
  }

  .pro-container {
    margin-bottom: 50px;
  }

  .product_info_table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      height: 50px;
      text-align: center;
      border: 1px solid #ebeef5;
    }

    th {
      background-color: #f5f7fa;
    }
  }

  .price-red {
    color: red;
  }

  .c_pointer.active {
    background-color: #e8f4ff;
    border-color: #d1e9ff;
    color: #1890ff;
  }

  .calcu-result {
    p+p {
      margin-left: 35px;
    }

    span {
      font-size: 16px;
      font-weight: bold;
      margin-left: 10px;
    }

    .specical-span {
      font-weight: normal;
      color: #666;
    }
  }

  .address-row {
    h4 {
      white-space: nowrap;
      margin-right: 10px;
    }
  }

  ::v-deep {
    .el-checkbox-group {
      .el-checkbox-button+.el-checkbox-button {
        margin-left: 10px;
        border-left: 1px solid #f2f2f2;
      }

      .el-checkbox-button__inner {
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        background-color: #f2f2f2;
        border-color: rgba(228, 228, 228, 0.93);
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .el-checkbox-button.is-checked .el-checkbox-button__inner {
      color: #1890ff;
      background-color: #e8f4ff;
      border-color: #e8f4ff;
      box-shadow: -1px 0 0 0 #e8f4ff;
    }

    .el-dialog__footer {
      text-align: center;
    }
  }

  .custom-table {
    overflow: auto;

    p+p {
      margin-top: 3px;
    }
  }

  .tooltip-content {
    max-height: 350px;
    overflow: hidden;
    overflow-y: auto;
    padding: 15px;

    p {
      font-size: 14px;
    }

    p+p {
      margin-top: 8px;
    }
  }

  .custom-table {
    td {
      .row-charge {
        height: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }

  .testData-row {
    max-height: 370px;
    overflow-y: auto;
  }
</style>
