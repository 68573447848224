<template>
  <el-dialog
    v-if="dialogFormVisible"
    :title="title"
    :visible.sync="dialogFormVisible"
    max-width="100%"
    :close-on-click-modal="false"
    append-to-body
    @close="close"
  >
    <el-row type="flex" align="middle">
      <el-row class="el-row-right-content">
        <el-row v-for="(user, userIndex) in allUserData" :key="user.userId">
          <el-row type="flex" align="middle">
            <el-row class="t_a_c user-area">
              <p style="position: relative; display: inline-block">
                <el-avatar
                  style="width: 35px; height: 35px"
                  src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                />
                <i
                  v-show="user.isEdit === true"
                  class="el-icon-close close"
                  @click="deleteUser(node, userIndex)"
                ></i>
              </p>
              <p class="mt10 user-name">{{ user.userName }}</p>
            </el-row>
            <el-row v-if="userIndex === allUserData.length - 1 ? false : true">
              <i class="el-icon-arrow-right"></i>
            </el-row>
          </el-row>
        </el-row>
      </el-row>
      <!-- 加号选择 -->
      <div
        v-if="showPlusIcon(node)"
        class="choose-row ml20"
        @click="chooseUserClick(node)"
      >
        <i class="el-icon-plus" style="color: #1890ff; font-weight: bold"></i>
        <i class="require-icon">*</i>
      </div>
    </el-row>
    <el-row slot="footer" class="dialog-footer text-center">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </el-row>
    <!-- <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </template> -->

    <!-- 选择人员 -->
    <UserChoose ref="UserChoose" :filters="filters" @choose-user="chooseUser" />
    <!-- 选择部门 -->
    <el-dialog
      title="选择部门"
      :visible.sync="dialogVisible"
      width="30%"
      :modal="false"
      @close="dialogVisible = false"
    >
      <el-form
        ref="deptForm"
        :rules="deptRules"
        :model="deptForm"
        label-width="80px"
      >
        <el-form-item prop="selectedDeptName" label="部门">
          <el-select
            v-model="deptForm.selectedDeptName"
            prop="selectedDeptName"
          >
            <el-option
              v-for="dept in depts"
              :key="dept.id"
              placeholder="请选择部门"
              :value="dept.name"
              :label="dept.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 认</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import UserChoose from '@/components/userChoose'
  export default {
    name: 'PriceRuleModel',
    components: { UserChoose },
    data() {
      return {
        title: '全部',
        dialogFormVisible: false,
        allUserData: [],
        node: [],
        auditTemplateId: '', //选中的审核模板id
        auditTypeTemplate: [], //审核模板（再次选择模板里面不同的什么模板）
        auditTypeTemplateDetail: [], //选择的审核模板详情
        auditTypeTemplateDetailNode: [], //选择的审核模板详情的节点
        filters: {}, //人员选择过滤条件
        depts: [], //部门
        deptForm: {
          selectedDeptName: '',
        },
        deptRules: {
          selectedDeptName: [
            {
              required: true,
              trigger: 'change',
              message: '请选择部门',
            },
          ],
        },
        dialogVisible: false,
        deptUser: [], //选择部门是，传递的已选择人员
        optionalType: null, //自选一个还是多个
      }
    },
    methods: {
      //展示弹框
      showAddEdit(name, node, data) {
        if (name) {
          this.title = name
        }
        if (data) {
          this.allUserData = data
        }
        if (node) {
          this.node = node
        }
        this.dialogFormVisible = true
      },
      //选择审核人
      chooseUserClick(node) {
        this.currentNode = node
        const { optional, optionalRange, optionalPersonJson } = node

        //已选中的审核人员
        let noChooseUser = []
        if (node.auditUserVOS) {
          node.auditUserVOS.forEach((item) => {
            noChooseUser.push(item.userId)
          })
        }

        if (optional === 1 && optionalRange === 0) {
          // 自选一个人全部
          this.filters = {
            // deptName: this.deptName,
            deptName: '',
          }
        }
        // 自选一个人指定部门
        if (optional === 1 && optionalRange === 1) {
          let departments = JSON.parse(optionalPersonJson)
          if (departments.length === 1) {
            //指定一个部门
            this.filters = {
              deptName: departments[0].name,
            }
          } else {
            this.deptUser = noChooseUser //已选中人员
            this.optionalType = node.optionalType //自选1个还是多个
            this.depts = departments
            this.dialogVisible = true
            return
          }
        }
        this.$nextTick().then(() => {
          this.$refs['UserChoose'].getDeptTreeWithUser(
            node.optionalType,
            noChooseUser
          )
          this.$refs['UserChoose'].dialogFormVisible = true
        })
      },
      chooseUser(users) {
        const auditType = this.auditTypeTemplateDetail.auditType,
          tenantId = this.tenantId,
          {
            auditMethod,
            nodeSort,
            nodeType,
            nodeName,
            auditUserVOS,
            optionalType,
          } = this.currentNode
        let baseAuditUserFields = {
          auditMethod,
          auditStatus: 0, //默认
          auditTime: '',
          auditType,
          nodeSort,
          nodeType,
          remarks: '',
          tenantId,
          nodeName,
        }
        let _warning = () => {
          this.$message.warning('只能选择一个人员')
        }
        if (optionalType === 0 && users.length > 1) {
          // 0 单选
          return _warning()
        }
        let optionalUsers = auditUserVOS.filter((user) => user.optionalUser)
        if (optionalType === 0 && optionalUsers.length > 0) {
          return _warning()
        }
        users.forEach((user) => {
          auditUserVOS.push({
            ...baseAuditUserFields,
            userName: user.name,
            userId: user.id,
            optionalUser: true,
            isEdit: true,
          })
        })
      },
      onConfirm() {
        this.$refs.deptForm.validate((flag) => {
          if (flag) {
            this.filters = {
              deptName: this.deptForm.selectedDeptName,
            }
            this.$nextTick().then(() => {
              this.$refs['UserChoose'].getDeptTreeWithUser(
                this.optionalType,
                this.deptUser
              )
              this.$refs['UserChoose'].dialogFormVisible = true
            })
            this.dialogVisible = false
          }
        })
      },
      // 是否展示添加选择人员按钮判断
      showPlusIcon(node = {}) {
        const { optional, optionalRange } = node
        if (optional !== 1) {
          return false
        } else {
          if (optionalRange === 2) {
            return false
          } else {
            return true
          }
        }
      },
      // 关闭
      close() {
        this.dialogFormVisible = false
      },
      // 人员可编辑删除
      deleteUser(node, userIndex) {
        let { auditUserVOS } = node
        if (auditUserVOS && auditUserVOS.length === 1) {
          return this.$message.warning('至少保留一个人员')
        }
        auditUserVOS.splice(userIndex, 1)
      },
      // 保存
      save() {
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-row-right-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    > .el-row {
      margin-bottom: 10px;
    }
  }
  .choose-row {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    border: 1.5px dashed #1890ff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    .require-icon {
      position: absolute;
      display: inline-block;
      color: red;
      font-size: 20px;
      right: -4px;
      top: -2px;
      height: 10px;
      line-height: 10px;
      width: 10px;
      background: #fff;
    }
  }
  .user-name {
    white-space: nowrap;
  }
  .close {
    position: absolute;
    top: 0;
    right: 9px;
    color: #c0c4cc;
    cursor: pointer;
    font-size: 12px;
  }
  .user-area {
    width: 80px;
    overflow: hidden;
    p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .user-area:hover .close {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #46a6ff;
    text-align: center;
    line-height: 15px;
    color: #fff;
  }
</style>
