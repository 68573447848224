<template>
  <el-dialog title="新建备货单" width="1200px" class="custom-dialog" :visible.sync="demandAddVisable"
    :close-on-click-modal="false" :before-close="close">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="阐述备货原因" prop="stockUpReason">
            <el-input type="textarea" v-model="form.stockUpReason" maxlength="500" show-word-limit :rows="3"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="销售运营计划" prop="salesPlan">
            <el-input type="textarea" v-model="form.salesPlan" maxlength="500" show-word-limit :rows="3"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="top">
        <h4>产品列表</h4>
        <el-button type="primary" @click="selectProduct">选择产品</el-button>
      </div>
      <div class="c-table-wrap">
        <table class="custom-table no-space">
          <thead>
            <tr>
              <th class="index">序号</th>
              <th>产品编号</th>
              <th>产品名称</th>
              <th>规格</th>
              <th>库存可用量</th>
              <th>
                <span>
                  <i>*</i>
                  需求数量
                </span>
              </th>
              <th>
                <span>
                  <i>*</i>
                  采购单价(元)
                </span>
              </th>
              <th>采购预算总成本</th>
              <th>采购开发</th>
              <th>订单支持</th>
              <th>月询盘数量</th>
              <th>月订单数量</th>
              <th>月订单金额($)</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody v-if="form.purchasingDemandProductEditDTOList.length">
            <tr v-for="(item, i) in form.purchasingDemandProductEditDTOList" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.sku }}</td>
              <td>{{ item.nameCn }}</td>
              <td>
                <span>{{ item.specsValue1 || '--' }}</span>
                <span v-if="item.specsValue2">/{{ item.specsValue2 }}</span>
              </td>
              <td>
                {{ formatStock(item.stockWarehouseNumVOList) }}
              </td>
              <td>
                <el-form-item :prop="
                    'purchasingDemandProductEditDTOList.' + i + '.numDemand'
                  " :rules="[
                    {
                      required: true,
                      message: '需求数量必填',
                      trigger: 'blur',
                    },
                    {
                      pattern: /^[1-9]\d*$/,
                      message: '请输入>0的整数',
                      trigger: 'blur',
                    },
                    {
                      pattern: /^\d{1,8}$/,
                      message: '只能输入8位数字',
                      trigger: 'blur',
                    },
                  ]">
                  <el-input maxlength="10" v-model="
                      form.purchasingDemandProductEditDTOList[i].numDemand
                    " @input="calculateAmount(i)" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item :prop="
                    'purchasingDemandProductEditDTOList.' + i + '.costPrice'
                  " :rules="[
                    {
                      required: true,
                      message: '采购单价必填',
                      trigger: 'blur',
                    },
                    {
                      pattern: /^\d+(\.\d{1,3})?$/,
                      message: '>=0最多三位小数',
                      trigger: 'blur',
                    },
                  ]">
                  <el-input maxlength="10" v-model="
                      form.purchasingDemandProductEditDTOList[i].costPrice
                    " @input="calculateAmount(i)" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </td>
              <td>
                <span v-if="item.costPrice && item.numDemand">
                  {{ utils.moneyFormat(item.costPrice * item.numDemand) }}
                </span>
                <span v-else>--</span>
              </td>
              <td>
                <span class="page-link" @click="allotClick(i)">
                  {{
                  form.purchasingDemandProductEditDTOList[i].buyerName ||
                  '待分配'
                  }}
                </span>
              </td>
              <td>
                <p class="page-link" @click="setBusinessName(i)">
                  {{
                  form.purchasingDemandProductEditDTOList[i]
                  .fromBusinessName || '待分配'
                  }}
                </p>
              </td>
              <td>{{ item.enquiryMonthNum }}</td>
              <td>{{ item.orderMonthNum }}</td>
              <td>{{ item.orderMonthAmount }}</td>
              <td>
                <el-button class="icon-r" @click="delProduct(i)" size="mini" icon="el-icon-delete" circle></el-button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="99">暂无数据</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="mt10 mb20 text-right">
        总数量：
        <span class="mr20">{{ amount }}</span>
        总成本：
        <span>{{ utils.moneyFormat(costAmout) }}</span>
      </p>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="期望交期" prop="expectedDeliveryDate" class="label-left">
            <el-date-picker style="width: 100%" v-model="form.expectedDeliveryDate" type="date" placeholder="选择日期"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="收货地址" prop="receivingAddress">
            <el-input v-model="form.receivingAddress" maxlength="50" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p>备注</p>
      <el-input type="textarea" v-model="form.remark" maxlength="500" show-word-limit :rows="3"></el-input>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save" :loading="loading">
        确认
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 选取产品 -->
    <el-dialog width="1100px" title="选取产品" :visible.sync="productListVisible" :close-on-click-modal="false"
      append-to-body>
      <product-list v-if="productListVisible" ref="product" :selectProductList="selectProductList"
        :purchaseDemandAddFlag="1" @getProductData="getProductData" @productClose="productClose"></product-list>
    </el-dialog>

    <!-- 设置采购开发 -->
    <setBuyer ref="setBuyer" :purchaseUsers="purchaseUsers || purchaseUsersNew" @setBuyer="setBuyer"></setBuyer>

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" v-if="demandAddVisable" />
    <!-- 设置订单支持 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </el-dialog>
</template>

<script>
  import { CreditManageInteractor, purchaseDemandInterator } from '@/core'
  import productList from './productList'
  import AuditChoose from '@/components/AuditChoose'
  import setBuyer from './../components/setBuyer'
  import { mapGetters } from 'vuex'
  import UserChoose from '@/components/userChoose'
  export default {
    components: {
      productList,
      AuditChoose,
      setBuyer,
      UserChoose,
    },
    props: ['purchaseUsers'],
    data() {
      return {
        numberPrefix: '',
        form: {
          /**
           * 基本信息
           */
          stockUpReason: '', // 阐述备货原因
          salesPlan: '', // 销售运营计划
          expectedDeliveryDate: '', // 期望交期
          receivingAddress: '', // 收货地址
          remark: '', // 备注
          createTime: '', // 创建时间
          creator: '', // 创建人
          numDemand: '',
          purchasingDemandId: '',
          /**
           * 采购需要产品列表
           */
          purchasingDemandProductEditDTOList: [],
          /**
           * 审核信息
           */
          auditObjectDTO: {},
        },
        amount: 0,
        costAmout: 0,
        allotIndex: null,
        demandAddVisable: false,
        selectProductList: [],
        purchaseUsersNew: [],
        selectProductMap: {},
        rules: {
          stockUpReason: [
            { required: true, message: '请输入阐述备货原因', trigger: 'blur' },
          ],
          salesPlan: [
            { required: true, message: '请输入销售运营计划', trigger: 'blur' },
          ],
          expectedDeliveryDate: [
            { required: true, message: '请选择期望交期', trigger: 'change' },
          ],
          receivingAddress: [
            { required: true, message: '请输入收货地址', trigger: 'blur' },
            { max: 50, message: '字符长度不能超过50', trigger: 'blur' },
          ],
        },
        productListVisible: false,
        loading: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    watch: {
      'form.purchasingDemandProductEditDTOList.length'() {
        this.calculateAmount()
      },
    },
    methods: {
      // 初始化数据
      initData(data) {
        if (data.expectedDeliveryDate) {
          this.form.stockUpReason = data.stockUpReason
          this.form.salesPlan = data.salesPlan
          this.form.expectedDeliveryDate = data.expectedDeliveryDate
          this.form.receivingAddress = data.receivingAddress
          this.form.remark = data.remark
          this.amount = data.numDemand
          this.form.purchasingDemandProductEditDTOList =
            data.purchasingDemandProductEditVOList
        }
      },

      // 获取编辑数据
      async getEditData(id) {
        let form = this.form
        this.numberPrefix = id
        const res = await purchaseDemandInterator.purchasingDGetDetailForEdit(
          id
        )

        if (res && res.code == '000000') {
          const data = res.data
          form.stockUpReason = data.stockUpReason
          form.salesPlan = data.salesPlan
          form.expectedDeliveryDate = data.expectedDeliveryDate
          form.receivingAddress = data.receivingAddress
          form.createTime = data.createTime
          form.creator = data.creator
          form.numDemand = data.numDemand
          form.purchasingDemandId = data.purchasingDemandId
          form.remark = data.remark
          form.purchasingDemandProductEditDTOList =
            data.purchasingDemandProductEditVOList
        }
      },

      /**
       * @desc 权限-客户代表角色下拉
       * @desc 首页入口拿不到purchaseUsers，需要重新赋值新数组
       */
      async getListUserByRoleName() {
        let response = await CreditManageInteractor.listUserByRoleName({
          divideGroup: 0,
          name: '采购员',
          tenantId: this.userInfo.tenantId,
        })
        if (response.code === '000000') {
          this.purchaseUsersNew = response.data

          this.$refs.setBuyer.init()
        }
      },


      formatSelectProduct(list) {
        const __selectIds = []

        for (let i = 0; i < list.length; i++) {
          __selectIds.push(list[i].sku)
        }
        return __selectIds
      },

      // 选择产品
      selectProduct() {
        this.selectProductList = this.formatSelectProduct(
          this.form.purchasingDemandProductEditDTOList
        )
        this.productListVisible = true
      },

      // 关闭选择产品
      productClose() {
        this.productListVisible = false
      },

      // 获取产品数据
      async getProductData(data, data2) {
        let arr = []

        this.form.purchasingDemandProductEditDTOList = []
        data.forEach((item) => {
          if (!item.skuId.includes('FL')) {
            arr.push(item.skuId)
          }
        })

        const res = await purchaseDemandInterator.producGetProductInfoBySku(arr)

        if (res && res.code === '000000') {
          let _arr = res.data.map((item, i) => {
            const unit = this.getSizeUnit(data[i].sizeUnit)
            return {
              ...item,
              productSize:
                'Material:' +
                item.material +
                '; ProductSize:' +
                item.productLengthCm +
                unit +
                'x' +
                item.productWidthCm +
                unit +
                'x' +
                item.productHeightCm +
                unit +
                '',
            }
          })
          this.form.purchasingDemandProductEditDTOList =
            this.form.purchasingDemandProductEditDTOList
              .concat(_arr)
              .concat(data2)
          this.productListVisible = false
        }
      },

      /**
       * 获取产品尺寸符号 CM显示cm, FT显示', IN显示''
       * @param {sizeUnit} 产品单位
       */
      getSizeUnit(sizeUnit) {
        if (sizeUnit === 'CM') {
          return 'cm'
        } else if (sizeUnit === 'FT') {
          return "'"
        } else {
          return "''"
        }
      },

      // 计算总数
      calculateAmount() {
        let totalNum = []
        let costTotalNum = []
        this.form.purchasingDemandProductEditDTOList.forEach((item, i) => {
          totalNum.push(Number(item.numDemand) || 0)
          costTotalNum.push(Number(item.numDemand * item.costPrice) || 0)
        })

        if (totalNum.length) {
          this.amount = totalNum.reduce((prev, cur) => {
            return prev + cur
          })
          this.costAmout = costTotalNum.reduce((prev, cur) => {
            return prev + cur
          })
        }
      },

      // 分配采购开发
      allotClick(i) {
        this.allotIndex = i
        if (this.purchaseUsers) {
          this.$refs.setBuyer.init()
        } else {
          this.getListUserByRoleName()
        }
      },

      //人员组织架构返回
      setBuyer(from) {
        this.$set(
          this.form.purchasingDemandProductEditDTOList,
          this.allotIndex,
          Object.assign(
            {},
            this.form.purchasingDemandProductEditDTOList[this.allotIndex],
            {
              buyerId: from.buyerId,
              buyerName: from.buyerName,
              buyerArea: from.area,
            }
          )
        )
      },

      // 设置订单支持
      setBusinessName(i) {
        this.allotIndex = i
        this.$refs['UserChoose'].showAddEdit('', '', true)
      },
      chooseUser(userIdArr) {
        this.$set(
          this.form.purchasingDemandProductEditDTOList[this.allotIndex],
          'fromBusinessId',
          userIdArr[0].id
        )
        this.$set(
          this.form.purchasingDemandProductEditDTOList[this.allotIndex],
          'fromBusinessName',
          userIdArr[0].name
        )
        this.$set(
          this.form.purchasingDemandProductEditDTOList[this.allotIndex],
          'fromBusinessNameEn',
          userIdArr[0].englishName
        )
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (!this.form.purchasingDemandProductEditDTOList.length) {
              this.$message.warning('至少选择一条产品')
              return false
            }

            // 判断是否存在审核模板
            let auditParams = {
              auditType: 2,
              status: 0,
              tenantId: this.userInfo.tenantId,
            }
            this.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
                return false
              } else {
                // 配置审核模板
                this.$refs['AuditChoose'].showAddEdit(auditParams)
              }
            })
          } else {
            return false
          }
        })
      },

      // 保存loading审核模板并提交
      async saveClick(data) {
        this.loading = true
        this.form.auditObjectDTO = data
        const params = {
          ...this.form,
        }
        if (this.numberPrefix) {
          const res = await purchaseDemandInterator.purchasingDUpdatePO(params)

          if (res && res.code === '000000') {
            this.$message.success('保存成功')
            this.$parent.initLoading(2)
            this.$refs['AuditChoose'].close()
            this.close()
          }
          this.loading = false
        } else {
          const res = await purchaseDemandInterator
            .purchasingDInsertByBuyer(params)
            .catch(() => {
              this.loading = false
            })

          if (res && res.code === '000000') {
            this.$message.success('保存成功')
            this.$parent.initLoading(2)
            this.$refs['AuditChoose'].close()
            this.close()
          }
          this.loading = false
        }
      },

      // 删除产品
      delProduct(i) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.purchasingDemandProductEditDTOList.splice(i, 1)
            this.$message.success('删除成功！')
          })
          .catch(() => { })
      },
      // 关闭弹窗重置表单
      close() {
        this.form = this.$options.data().form
        this.amount = 0
        this.costAmout = 0
        this.$refs.ruleForm.resetFields()
        this.demandAddVisable = false
      },

      // 格式化库存数据
      formatStock(stockData) {
        if (stockData) {
          let isArray = (stockData.constructor = Array)
          let __stockInfo = []

          if (stockData.length > 0) {
            for (let i = 0; i < stockData.length; i++) {
              __stockInfo.push(
                `${stockData[i].warehouseName}:${stockData[i].num}`
              )
            }
          } else {
            __stockInfo.push(`--`)
          }
          return __stockInfo.toString()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    .top {
      display: flex;
      justify-content: flex-start;
      height: 32px;
      line-height: 30px;
      margin-bottom: 20px;

      h4 {
        font-size: 14px;
        font-family: 'PingFang Bold';
        margin: 0 20px 0 0;
      }
    }

    p {
      margin: 10px 0;

      span {
        font-size: 16px;
        font-family: 'PingFang Bold';
      }
    }
  }
</style>