<template>
  <div class="reject">
    <el-dialog :visible.sync="dialogFormVisible" width="500px" @click="close" :close-on-click-modal="false">
      <el-form :model="form" ref="form" label-position="top" :rules="rules">
        <el-form-item label="撤销原因" label-width="80px" required prop="remarks">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="500" show-word-limit
            v-model="form.remarks" placeholder="请输入500个字符以内"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      auditType: {
        type: Number,
        default: 8,
      },
    },
    data() {
      return {
        amendRow: '',
        dialogFormVisible: false,
        form: {
          remarks: '',
        },
        cancelAuditId: '',
        rules: {
          remarks: [
            { required: true, message: '请输入', trigger: ['blur', 'change'] },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    methods: {
      showCancel(id) {
        this.dialogFormVisible = true
        this.cancelAuditId = id
      },

      close() {
        this.dialogFormVisible = false
        this.form.remarks = ''
      },
      async save() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            let response = await CostManageInteractor.setCancel({
              ...this.form,
              auditObjectId: this.cancelAuditId,
              auditStatus: 4,
              auditType: this.auditType,
              tenantId: this.userInfo.tenantId,
              userId: this.userInfo.userId,
              userName: this.userInfo.userName,
            })

            if (response?.code == '000000') {
              this.$message.success('已撤销')
              setTimeout(() => {
                this.$emit('getList')
                this.close()
              }, 800)
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .el-dialog__header {
      >.el-dialog__title {
        font-size: 14px;
      }
    }

    .el-dialog__footer {
      padding-top: 0;
      text-align: center;
    }
  }
</style>
