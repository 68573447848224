var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[([4, 5, 6, 8, 9, 10, 11, 12, 13].includes(Number(_vm.code)))?_c('CompanyDetailSearch',{attrs:{"serchForm":_vm.serchForm,"code":_vm.code},on:{"serchForm":_vm.search}}):_vm._e(),_c('ErpTable',{key:_vm.tableKey,ref:"erpTable",staticClass:"table-fixed",attrs:{"page-number":_vm.pageNo,"page-size":_vm.pageLe,"table-data":_vm.tableData,"total":_vm.total},on:{"update:pageNumber":function($event){_vm.pageNo=$event},"update:page-number":function($event){_vm.pageNo=$event},"update:pageSize":function($event){_vm.pageLe=$event},"update:page-size":function($event){_vm.pageLe=$event},"query":_vm.query,"row-click":_vm.rowClick}},[_c('el-table-column',{attrs:{"align":"center","type":"index","width":"55","label":_vm.lang === 'en' ? 'No.' : '序号'}}),_vm._l((_vm.finallyColumn),function(item,index){return _c('el-table-column',{key:index,attrs:{"label":[1, 4, 14].includes(Number(_vm.code)) ? _vm.$t(item.langKey) : item.label,"show-overflow-tooltip":"","resizable":false,"align":"center","min-width":_vm.lang === 'en' ? item.enWidth : item.width,"width":item.width,"prop":item.prop},scopedSlots:_vm._u([(item.prop == 'customerRank' && _vm.code == 1)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-rate',{attrs:{"disabled":"","text-color":"#ff9900"},model:{value:(row.customerRank),callback:function ($$v) {_vm.$set(row, "customerRank", $$v)},expression:"row.customerRank"}})]}}:(item.prop == 'allChargeCn')?{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.lang == 'zh' ? row.allChargeCn || '--' : row.allChargeEn || '--')+" ")]}}:(item.prop == 'linkman')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(row.linkman))])]}}:(item.prop == 'customerName')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(row.customerName))])]}}:(item.prop == 'salesman' && _vm.code == 4)?{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.lang == 'zh' ? row.salesman || '--' : row.salesmanEn || '--')+" ")]}}:(item.prop == 'releatedCustomer' && _vm.code == 4)?{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.lang == 'zh' ? row.releatedCustomer || '--' : row.releatedCustomerEn || '--')+" ")]}}:(item.prop == 'creator' && _vm.code == 4)?{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.lang == 'zh' ? row.creator || '--' : row.creatorEn || '--')+" ")]}}:(item.prop == 'belongerName' && _vm.code == 1)?{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.lang == 'zh' ? row.belongerName || '--' : row.belongerNameEn || '--')+" ")]}}:(item.prop == 'creator' && _vm.code == 14)?{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.lang == 'zh' ? row.creator || '--' : row.creatorEn || '--')+" ")]}}:(item.prop == 'orderCode' && _vm.code == 5)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"page-link",on:{"click":function($event){return _vm.goOrderDetail(row.orderCode)}}},[_vm._v(" "+_vm._s(row.orderCode)+" ")])]}}:(item.prop == 'businessNumber' && _vm.code == 10)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"page-link",on:{"click":function($event){return _vm.goOrderDetail(row.businessNumber)}}},[_vm._v(" "+_vm._s(row.businessNumber)+" ")])]}}:(item.prop == 'salesOrder' && _vm.code == 12)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"page-link",on:{"click":function($event){return _vm.goOrderDetail(row.salesOrder)}}},[_vm._v(" "+_vm._s(row.salesOrder)+" ")])]}}:{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row[item.prop] || '--'))])]}}],null,true)})})],2),([5, 6].includes(Number(_vm.code)))?_c('div',{staticClass:"mt20"},[_c('detail-statistics',{attrs:{"infoId":_vm.infoId,"code":_vm.code,"type":"company","isInit":_vm.refresh,"searchData":_vm.requestForm},on:{"update:isInit":function($event){_vm.refresh=$event},"update:is-init":function($event){_vm.refresh=$event}}})],1):_vm._e(),(_vm.code == 4)?_c('div',{staticClass:"mt20"},[_c('ReplayRecord',{attrs:{"tableData":_vm.currentHistory}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }