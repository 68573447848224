<template>
  <div class="pro-container">
    <el-row type="flex" align="center">
      <el-col :span="1.5">
        <!-- 绑定邮箱 -->
        <el-button type="primary" @click="handleAdd">
          {{ $t('mailConfig.BindEmail') }}
        </el-button>
      </el-col>
    </el-row>
    <!-- 切换类型 -->
    <div class="mb5 mt5">
      <el-button
        type="text"
        size="medium"
        @click="toggleActiveType('PRIMARY')"
        :style="{ color: activeType !== 'PRIMARY' ? '#AAAAAA' : '' }"
      >
        {{ $t('mailConfig.primaryaccount') }}
      </el-button>
      <el-button
        type="text"
        size="medium"
        @click="toggleActiveType('SUB')"
        :style="{ color: activeType !== 'SUB' ? '#AAAAAA' : '' }"
      >
        {{ $t('mailConfig.subaccount') }}
      </el-button>
    </div>
    <ErpTable
      :page-number.sync="pageNo"
      :page-size.sync="pageSize"
      :table-data="accountList"
      :total="total"
      @query="getList"
      :extra-height="30"
      class="table-fixed"
    >
      <!-- 序号 -->
      <el-table-column
        type="index"
        align="center"
        :label="$t('mailConfig.SerialNumber')"
        width="100"
      ></el-table-column>
      <!-- 邮箱地址 -->
      <el-table-column
        prop="emailAccount"
        align="center"
        :label="$t('mailConfig.EmailAddress')"
      >
        <template slot-scope="scope">{{ scope.row.emailAccount }}</template>
      </el-table-column>
      <!-- 邮箱类型 -->
      <el-table-column
        prop="nickName"
        align="center"
        :label="$t('mailConfig.MailType')"
      >
        <template slot-scope="scope">
          {{
            settings === 'en' ? scope.row.mailServerEn : scope.row.mailServer
          }}
        </template>
      </el-table-column>
      <!-- 出口抬头 -->
      <el-table-column
        prop="exportHead"
        align="center"
        :label="$t('mailConfig.ExportsRise')"
      >
        <template slot-scope="scope">
          {{ scope.row.exportHead || '--' }}
        </template>
      </el-table-column>

      <!-- 邮箱归属人 -->
      <el-table-column
        :prop="activeType === 'PRIMARY' ? 'businessName' : 'userNameEn'"
        align="center"
        :label="$t('mailConfig.EmailOwner')"
      >
        <template slot-scope="scope">
          <template v-for="user in scope.row.userList">
            <!-- Dimission 已离职 -->
            <span
              :class="user.status == 1 ? '' : 'leave'"
              v-if="activeType === 'PRIMARY'"
            >
              {{ settings === 'en' ? user.nameEn : user.name
              }}{{ user.status == 1 ? '' : $t('other.Dimission') }};
            </span>

            <span :class="user.status == 1 ? '' : 'leave'" v-else>
              {{ settings === 'en' ? user.nameEn : user.name
              }}{{ user.status == 1 ? '' : $t('other.Dimission') }};
            </span>
          </template>
        </template>
      </el-table-column>
      <!-- 邮件拉取状态 -->
      <el-table-column
        prop="pulled"
        align="center"
        :label="$t('mailConfig.Mailpullstatus')"
        v-if="activeType === 'PRIMARY'"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.pulled == 0 || scope.row.pulled == 1">
            {{ $t('mailConfig.Pulling') }}
          </span>
          <span v-else-if="scope.row.pulled == 2">
            {{ $t('mailConfig.Pullcompleted') }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column
        align="center"
        :label="$t('mailConfig.Operate')"
        fixed="right"
        :resizable="false"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 编辑 -->
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('iconbtn.edit')"
            placement="top"
            v-if="activeType === 'PRIMARY'"
          >
            <el-button
              type="primary"
              plain
              size="mini"
              circle
              @click="handleEdit(scope.row)"
              icon="el-icon-edit"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('iconbtn.unbound')"
            placement="top"
          >
            <el-button
              type="primary"
              plain
              size="mini"
              circle
              @click="handleUnbound(scope.row)"
            >
              <i style="font-size: 14px" class="iconfont icon-unlink"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </ErpTable>

    <SettingModal ref="SetMail" @fetch-data="getList" />
  </div>
</template>

<script>
  import SettingModal from './componetns/SettingModal.vue'
  import {
    getMailAllAccountList,
    reBindMailAccount,
  } from '@/core/services/api/mail/setting.js'
  import {
    getListDepartmrnt,
    departmentListUser,
    departmentDelete,
  } from '@/api/organization-manage'
  import { MailSettingInteractor } from '@/core'

  import { mapGetters } from 'vuex'
  export default {
    components: {
      SettingModal,
    },
    data() {
      return {
        accountList: [],
        pageNo: 1,
        pageSize: 10,
        total: 0,
        userIdList: [], //在职员工id列表
        activeType: 'PRIMARY', //当前类型
      }
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
    },
    created() {
      this.getListDepartmrntUser()
    },
    methods: {
      //获取组织
      async getListDepartmrntUser() {
        let response = await getListDepartmrnt()
        if (response.code === '000000') {
          let deptId = response.data[0].deptId
          let res = await departmentListUser({
            status: 1, //在职
            pageLe: 9999,
            pageNo: 1,
            deptId,
          })
          if (res.code === '000000') {
            let data = res.data.data || []
            this.userIdList = data.map((item) => {
              return item.userId
            })
          }
          this.getList()
        } else {
          this.getList()
        }
      },
      //获取组织下用户
      async getDepartmentListUser() {
        this.loading = true
        this.queryForm.deptId = this.deptId
        let response = await departmentListUser(this.queryForm)
        if (response.code === '000000') {
          this.tableData = response.data.data
          this.total = response.data.total
        }
        this.loading = false
      },
      // 获取正常授权
      getList() {
        let reqMethod =
          this.activeType === 'PRIMARY'
            ? getMailAllAccountList
            : MailSettingInteractor.pageByAuthUserApi
        reqMethod({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        })
          .then((res) => {
            if (res && res.code === '000000') {
              let list = res.data.records || []
              this.accountList = list.map((item) => {
                if (this.activeType === 'PRIMARY') {
                  // 把归属人改成对象数组
                  let names = item.businessName
                    ? item.businessName.split(',')
                    : []
                  let namesEn = item.businessNameEn
                    ? item.businessNameEn.split(',')
                    : []
                  let ids = item.businessId ? item.businessId.split(',') : []
                  let userList = []
                  ids.forEach((id, index) => {
                    let user = {
                      id,
                      name: names[index],
                      nameEn: namesEn[index],
                      status: 1,
                    }
                    if (!this.userIdList.includes(id)) {
                      // 在职员工id列表不包含此人id，则状态改为0，表示离职
                      user.status = 0
                    }
                    userList.push(user)
                  })
                  item['userList'] = userList
                  return item
                } else {
                  // 把归属人改成对象数组
                  var names = [item.userNameCn]
                  var namesEn = [item.userNameEn]
                  var ids = [item.userId]
                  var userList = []
                  ids.forEach((id, index) => {
                    var user = {
                      id,
                      name: names[index],
                      nameEn: namesEn[index],
                      status: 1,
                    }
                    if (!this.userIdList.includes(id)) {
                      // 在职员工id列表不包含此人id，则状态改为0，表示离职
                      user.status = 0
                    }
                    userList.push(user)
                  })
                  item['userList'] = userList
                  return item
                }
              })
              this.total = res.data.total
            } else {
              this.accountList = []
              this.total = 0
            }
          })
          .catch(() => {
            this.accountList = []
            this.total = 0
          })
      },
      handleAdd() {
        this.$refs['SetMail'].showAddEdit()
      },
      handleEdit(row) {
        this.$refs['SetMail'].showAddEdit(row)
      },

      bindGmail(val) {
        this.$refs['SetMail'].bindGmailShow(val)
      },

      handleUnbound(val) {
        this.$confirm(this.$t('reqmsg.M2062'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('reqmsg.M2006'),
          cancelButtonText: this.$t('reqmsg.M2007'),
          type: 'warning',
        }).then(() => {
          // return
          let params = {
            tenantId: val.tenantId,
            emailAccount: val.emailAccount,
          }
          if (this.activeType === 'PRIMARY') {
            reBindMailAccount(params).then((res) => {
              if (res.code === '000000') {
                this.$message({
                  type: 'success',
                  message: this.$t('reqmsg.$7'),
                })
                this.getList()
              }
            })
          } else {
            MailSettingInteractor.otherCancelAuthEmailApi({
              id: val.id,
            }).then((res) => {
              if (res.code === '000000') {
                this.$message({
                  type: 'success',
                  message: this.$t('reqmsg.$7'),
                })
                this.getList()
              }
            })
          }
        })
      },

      // 切换类型
      toggleActiveType(type) {
        this.activeType = type
        this.pageNo = 1
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mail-setting {
    padding: 20px;
  }
  .leave {
    color: #f56c6c;
    white-space: nowrap;
  }
</style>
