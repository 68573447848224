var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"latest-aside"},[_c('div',{staticClass:"search-container",attrs:{"id":"search-container"}},[_c('shrink-bar',{ref:"defaultContact",on:{"getList":function($event){return _vm.getListByType(_vm.defaultConfig)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"search-item-header"},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"nowrap"}},[_c('i',{staticClass:"el-icon-arrow-down mr-10"}),_c('span',{staticStyle:{"display":"inline-block","white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t('other.recentcontacts'))+" ("+_vm._s(_vm.defaultConfig.num)+") ")])]),_c('el-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path == '/write'),expression:"$route.path == '/write'"}],attrs:{"name":"last","isIndeterminate":_vm.defaultConfig.isIndeterminate,"label":_vm.$t('other.selectall')},on:{"change":function (val) {
                _vm.handleCheckAllChange(val, _vm.defaultConfig)
              }},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.defaultConfig.isCheckAll),callback:function ($$v) {_vm.$set(_vm.defaultConfig, "isCheckAll", $$v)},expression:"defaultConfig.isCheckAll"}})],1)]},proxy:true},{key:"main",fn:function(){return [_c('el-checkbox-group',{on:{"change":function (val) {
              _vm.handleCheckedContactsChange(val, _vm.defaultConfig)
            }},model:{value:(_vm.checkedContacts),callback:function ($$v) {_vm.checkedContacts=$$v},expression:"checkedContacts"}},_vm._l((_vm.defaultFilterList),function(v){return _c('div',{key:v.contactId,class:_vm.currentContactMail == v.contactEmail
                ? 'search-item active'
                : 'search-item',on:{"click":function($event){return _vm.getDetail(v)}}},[_c('p',{staticClass:"wrapper-p"},[(!_vm.search)?_c('el-checkbox',{attrs:{"label":v.contactId},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s('')+" ")]):_vm._e(),(!v.inputFlag)?_c('span',[_vm._v(" "+_vm._s(v.noteName ? v.noteName : v.contactEmail)+" ")]):_c('el-input',{attrs:{"type":"text","size":"mini"},on:{"blur":function($event){return _vm.updateNodeName(v)}},model:{value:(v.noteName),callback:function ($$v) {_vm.$set(v, "noteName", $$v)},expression:"v.noteName"}})],1)])}),0)]},proxy:true}])}),_vm._l((_vm.typeList),function(item){return _c('shrink-bar',{key:item.contactCatagoryId,on:{"getList":function($event){return _vm.getListByType(item)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.$route.path == '/mail/my-mail/book')?_c('book-edit',{attrs:{"config":item,"search":_vm.search},on:{"handleWrite":_vm.handleWrite,"initTypeData":_vm.initTypeData}}):_vm._e(),(_vm.$route.path == '/write')?_c('div',{staticClass:"search-item-header"},[_c('div',[_c('i',{staticClass:"el-icon-arrow-down mr-10"}),_c('span',[_vm._v(_vm._s(item.name)+"("+_vm._s(item.num)+")")])]),(_vm.$route.path == '/write' && item.filterList)?_c('el-checkbox',{attrs:{"name":item.name,"label":_vm.$t('other.selectall')},on:{"change":function (val) {
                _vm.handleCheckAllChange(val, item)
              }},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(item.isCheckAll),callback:function ($$v) {_vm.$set(item, "isCheckAll", $$v)},expression:"item.isCheckAll"}}):_vm._e()],1):_vm._e()]},proxy:true},{key:"main",fn:function(){return [_c('el-checkbox-group',{on:{"change":function (val) {
              _vm.handleCheckedContactsChange(val, item)
            }},model:{value:(_vm.checkedContacts),callback:function ($$v) {_vm.checkedContacts=$$v},expression:"checkedContacts"}},_vm._l((item.filterList),function(v){return _c('div',{key:v.contactId,class:_vm.currentContactMail == v.contactEmail
                ? 'search-item active'
                : 'search-item',on:{"click":function($event){return _vm.getDetail(v)}}},[(!_vm.search)?_c('el-checkbox',{attrs:{"label":v.contactId},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s('')+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(v.noteName ? v.noteName : v.contactEmail))])],1)}),0)]},proxy:true}],null,true)})}),(_vm.addFlag)?_c('div',{staticClass:"contact-add"},[_c('el-input',{ref:"addTypeInput",attrs:{"maxlength":"20","show-word-limit":""},on:{"blur":_vm.addTypeBlur},model:{value:(_vm.addTypeValue),callback:function ($$v) {_vm.addTypeValue=$$v},expression:"addTypeValue"}}),_c('i',{staticClass:"el-icon-success",on:{"mousedown":_vm.handleAddSave}}),_c('i',{staticClass:"el-icon-error",on:{"mousedown":_vm.handleAddRemove}})],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }