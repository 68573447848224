<template>
  <div>
    <h3 style="text-align: center">转入黑名单审批</h3>
    <el-table
      :data="tableData"
      border
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      class="mt20"
    >
      <el-table-column label="序号" type="index"></el-table-column>
      <el-table-column label="公司编码">
        <template slot-scope="scope">
          {{ scope.row.companyCode || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="公司名称">
        <template slot-scope="scope">
          {{ scope.row.companyName || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="公司等级">
        <template slot-scope="scope">
          <el-rate
            v-model="scope.row.companyLevel"
            disabled
            text-color="#ff9900"
          ></el-rate>
        </template>
      </el-table-column>
      <el-table-column label="客户编码">
        <template slot-scope="scope">
          {{ scope.row.customerCode || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="客户名称">
        <template slot-scope="scope">
          {{ scope.row.linkman || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="客户等级">
        <template slot-scope="scope">
          <el-rate
            v-model="scope.row.customerRank"
            disabled
            text-color="#ff9900"
          ></el-rate>
        </template>
      </el-table-column>
      <el-table-column label="客户邮箱">
        <template slot-scope="scope">
          {{ scope.row.email || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话">
        <template slot-scope="scope">
          {{ scope.row.phone || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="客户类型">
        <template slot-scope="scope">
          <span v-if="scope.row.customerType == 0">潜在客户</span>
          <span v-else-if="scope.row.customerType == 1">正式客户</span>
          <span v-else>黑名单客户</span>
        </template>
      </el-table-column>
      <el-table-column label="客户来源">
        <template slot-scope="scope">
          {{ scope.row.customerSourceName || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="负责人">
        <template slot-scope="scope">
          {{ scope.row.belongerName || '--' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import {CustomerManageInteractor} from '@/core'

  export default {
    name: 'BlackList',
    props: {
      passRow: {
        type: [Object, Array],
        required: true,
      },
    },
    data() {
      return {
        tableData: [],
      }
    },

    mounted() {},

    methods: {},
    created() {
      const infoIds = this.passRow.auditContent
        .map((item) => item.infoId)
        .join(',')
      CustomerManageInteractor.selectAllCustomerByInfoIds(infoIds).then(
        (res) => {
          if (res && res.code === '000000') {
            this.tableData = res.data
          }
        }
      )
    },
  }
</script>

<style lang="scss" scoped></style>
