<template>
  <div>
    <el-row class="mt20">
      <el-col :span="23">
        <h4>{{ $t('productDetail.PrintingPosition') }}</h4>
        <p class="mt10">
          <span>{{ $t('productDetail.PrintingPositionOpen') }}:</span>
          <el-switch v-model="productInfo.productPrintingSwitch" :active-value="1" :inactive-value="0"
            disabled></el-switch>
        </p>
        <!-- 列表 -->
        <el-table v-if="!noColor && productInfo.productPrintingSwitch" :data="productInfo.surfaceVOList" stripe border
          :key="lang" class="mt15 w100 list-table">
          <!-- 印刷面 -->
          <el-table-column align="center" :label="$t('productDetail.PrintingSurface')" width="230px" :resizable="false">
            <template slot="header">
              <div class="special-header">
                <span class="special-header--left">
                  {{ $t('productDetail.PrintingSurface') }}
                </span>
                <span class="special-header--right">Color</span>
              </div>
            </template>
            <template slot-scope="scope">
              {{
              scope.row[
              lang === 'en'
              ? 'printingPositionNameEn'
              : 'printingPositionNameCn'
              ]
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" v-for="(item, index) in specColorList" :key="index" :label="item">
            <template slot="header">
              <span>{{ item }}</span>
            </template>
            <template slot-scope="scope">
              <div class="wrapper-box">
                <el-row type="flex" align="middle" justify="center" v-if="getPrintFaceSpecImage(item, scope.row)">
                  <el-image :src="getPrintFaceSpecImage(item, scope.row)" class="c_pointer"
                    @click="handlePreview(item, scope.row)" style="width: 50px; height: 50px"></el-image>
                </el-row>
                <!-- 无规格图 -->
                <span v-else>
                  {{ lang === 'en ' ? 'No Image' : '暂无图片' }}
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-if="noColor && productInfo.productPrintingSwitch" :data="productInfo.surfaceVOList" stripe border
          class="mt20 w100 list-table">
          <el-table-column align="center" :label="$t('productDetail.PrintingSurface')" width="120px">
            <template slot-scope="scope">
              <div>
                <p style="margin-bottom: 2px">
                  {{
                  scope.row[
                  lang === 'en'
                  ? 'printingPositionNameEn'
                  : 'printingPositionNameCn'
                  ]
                  }}
                </p>
              </div>
            </template>
          </el-table-column>
          <!-- 底图 -->
          <el-table-column align="center" :label="$t('productDetail.PrintingUnderlay')">
            <template slot-scope="scope">
              <el-row type="flex" align="middle" justify="center" v-if="getPrintFaceSpecImage('none', scope.row)">
                <el-image :src="getPrintFaceSpecImage('none', scope.row)" class="c_pointer"
                  @click="handlePreview('none', scope.row)" style="width: 50px; height: 50px"></el-image>
              </el-row>
              <!-- 无规格图 -->
              <span v-else>
                {{ lang === 'en ' ? 'No Image' : '暂无图片' }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div class="mt20" v-if="productInfo.productPrintingSwitch == 1">
          <div class="printing-surface-box">
            <p>
              <span>{{ $t('productDetail.PrintingSurface') }}:</span>
              <span>
                {{
                lang == 'en'
                ? printingPositionNameEns
                : printingPositionNameCns
                }}
              </span>
            </p>
            <div class="rendering-desc">
              <el-checkbox v-model="irregularFlag" disabled>
                {{ $t('productDetail.renderingDesc') }}
              </el-checkbox>
            </div>
          </div>
          <el-table :data="tableData" border stripe class="mt20">
            <!-- 印刷位置 -->
            <el-table-column align="center" :label="$t('productDetail.PrintingPosition')">
              <template slot-scope="scope">
                {{ scope.row[lang === 'en' ? 'nameEn' : 'nameCn'] }}
              </template>
            </el-table-column>
            <!-- 印刷区域尺寸 -->
            <el-table-column align="center" :label="$t('productDetail.Printingareasize')">
              <template slot-scope="scope">
                <span>
                  W:&nbsp;{{ scope.row.regionWidth }}&nbsp;IN&nbsp;&nbsp;x&nbsp;
                  H:&nbsp;{{ scope.row.regionHigh }}&nbsp;IN
                </span>
              </template>
            </el-table-column>
            <!-- 印刷区域位置 -->
            <el-table-column align="center" :label="$t('productDetail.printingarealocation')">
              <template slot-scope="scope">
                <span>
                  X:&nbsp;{{ scope.row.regionX }}&nbsp;IN&nbsp;&nbsp;x&nbsp;
                  Y:&nbsp;{{ scope.row.regionY }}&nbsp;IN
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('productDetail.Operate')">
              <template slot-scope="scope">
                <span class="page-link" @click="previewPrinting(scope.row, scope.$index)">
                  {{ lang === 'en' ? 'Preview' : '预览' }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[imgUrl]" />

    <!-- 印刷效果图预览 -->
    <el-dialog v-if="dialogVisible" :title="$t('productDetail.previewPrintArea')" :visible.sync="dialogVisible"
      width="620px" :before-close="handleClose" append-to-body>
      <div class="printing-area mt20 pb30">
        <div class="mask"></div>
        <printing-canvas ref="printCanvas" :id="`canvas${currentIndex}`" :scaleSize="printingInfo.scaleSize"
          :scalePoint="printingInfo.scalePoint" :prints="printingInfo.prints" :images="printingInfo.images" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import PrintingCanvas from '@/components/printingCanvas'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ProductOperationDetail',
    props: {
      productInfo: {
        type: Object,
        default: () => ({
          surfaceVOList: [],
        }),
      },
    },
    data() {
      return {
        currentIndex: 0,
        dialogVisible: false,
        imageUrl: '',
        showViewer: false,
        printingInfo: {},
      }
    },
    components: { PrintingCanvas },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      irregularFlag() {
        return this.productInfo?.irregularFlag ? true : false
      },
      printingPositionNameCns() {
        if (this.productInfo.surfaceVOList?.length === 0) {
          return '--'
        }
        return this.productInfo?.surfaceVOList
          ?.map((surface) => surface.printingPositionNameCn)
          ?.join(', ')
      },
      printingPositionNameEns() {
        if (this.productInfo?.surfaceVOList.length === 0) {
          return '--'
        }
        return this.productInfo?.surfaceVOList
          .map((surface) => surface.printingPositionNameEn)
          .join(', ')
      },

      tableData() {
        let surfaceVOList = this.productInfo.surfaceVOList
        if (!surfaceVOList || surfaceVOList.length === 0) {
          return []
        }

        return surfaceVOList
          .map((item) => {
            item.surfaceRegionVOList.map((subItem) => {
              let obj = {}
              subItem.nameEn = `${item.printingPositionNameEn}-${subItem.regionName || '--'
                }`
              subItem.nameCn = `${item.printingPositionNameCn}-${subItem.regionName || '--'
                }`
              return subItem
            })
            return item
          })
          .flatMap((item) => item.surfaceRegionVOList)
      },

      // 没有规格颜色
      noColor() {
        let surfaceVOList = this.productInfo.surfaceVOList
        if (surfaceVOList?.length === 0) {
          return false
        }
        return surfaceVOList?.some((item) => {
          return item.surfaceRelationVOList.some((subItem) => {
            return subItem.name === 'none'
          })
        })
      },
      // 颜色规格集合
      specColorList() {
        let surfaceVOList = this.productInfo.surfaceVOList
        if (surfaceVOList?.length === 0) {
          return []
        }
        let surfaceRelationVOList = surfaceVOList?.[0]?.surfaceRelationVOList
        return surfaceRelationVOList?.map((item) => item.name)
      },
    },
    mounted() { },

    methods: {
      handlePreview(color, item) {
        let src = this.getPrintFaceSpecImage(color, item)
        this.imgUrl = src
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
      getPrintFaceSpecImage(color, item) {
        let target = item.surfaceRelationVOList.find(
          (ele) => ele.name === color && ele.fileUrl
        )
        if (target) {
          return target.fileUrl
        }
      },

      // 预览印刷面
      previewPrinting(row, index) {
        const _list = this.productInfo.surfaceVOList
        for (let i = 0; i < _list.length; i++) {
          if (
            row.productPrintingSurfaceId === _list[i].productPrintingSurfaceId
          ) {
            this.printingInfo = {
              scaleSize: _list[i].scaleSize,
              scalePoint: _list[i].scalePoint,
              prints: _list[i].surfaceRegionVOList.filter(
                (item) => item.regionName === row.regionName
              ),
              images: _list[i].surfaceRelationVOList,
            }
          }
        }
        this.currentIndex = index
        this.dialogVisible = true
      },

      // 关闭预览印刷区域
      handleClose() {
        this.$refs.printCanvas.currentIndex = 1
        this.$refs.printCanvas.imgs = []
        this.$refs.printCanvas.canvas = null
        this.dialogVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-table__header-wrapper .el-table__cell {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .special-header {
    position: relative;

    span {
      display: inline-block;
      width: 50%;
      text-align: right;

      &.special-header--right {
        text-align: left;

        &::after {
          content: '';
          display: inline-block;
          width: 400px;
          height: 1px;
          background-color: #ebeef5;
          /* background-color: red; */
          position: relative;
          left: -124px;
          transform: rotate(11deg);
        }
      }
    }
  }

  .printing-area {
    position: relative;

    .mask {
      width: 582px;
      height: 582px;
      position: absolute;
      z-index: 999;
      background: transparent;
    }
  }

  ::v-deep {
    .el-dialog__body {
      padding: 0 10px;
    }
  }

  .printing-surface-box {
    display: flex;
    align-items: center;

    .rendering-desc {
      padding: 0 10px;
      flex: 1;
      width: 0;

      ::v-deep .el-checkbox {
        display: flex;
        align-items: center;

        .el-checkbox__label {
          font-size: 14px;
          flex: 1;
          width: 0;
          word-break: break-all;
          white-space: normal;
        }

        .el-checkbox__input {
          margin-top: 2px;
        }
      }
    }
  }
</style>