<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月24日 17:54:13
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="index-card-item-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'IndexCardItem',
  components: {},
  // 组件状态值
  data() {
    return {}
  },
}
</script> 

<style scoped lang="scss">
.index-card-item-wrapper {
  padding: 8px 0;
  border-bottom: 1px dashed #eaeaea;
  font-size: 14px;
  color: #282c34;
  &:first-child {
    padding-top: 0;
  }
}
</style>
