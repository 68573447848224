<template>
  <el-dialog
    :title="$t('productDetail.AddSupplier')"
    :visible.sync="dialogFormVisible"
    width="800px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <template #title>
      <div class="flex_row_y flex_wrap">
        <!-- 新增供应商 -->
        <p class="p_title f_s_16">{{ $t('productDetail.AddSupplier') }}</p>
      </div>
    </template>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      :label-position="settings === 'zh' ? 'right' : 'top'"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <!-- 供应商名称 -->
          <el-form-item
            :label="$t('productDetail.SupplierName')"
            prop="supplierName"
          >
            <el-input
              v-model="form.supplierName"
              :placeholder="$t('placeholder.plsInput')"
              @blur="checkName"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 地址 -->
          <el-form-item :label="$t('productDetail.Address')" prop="address">
            <el-input
              v-model="form.address"
              :placeholder="$t('placeholder.plsInput')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <!-- 联系人 -->
          <el-form-item :label="$t('productDetail.Contacts')" prop="linkman">
            <el-input
              v-model="form.linkman"
              :placeholder="$t('placeholder.plsInput')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 联系电话 -->
          <el-form-item :label="$t('productDetail.ContactNumber')" prop="phone">
            <el-input
              v-model="form.phone"
              :placeholder="$t('placeholder.plsInput')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <!-- 付款方式 -->
          <el-form-item
            :label="$t('productDetail.PaymentMethod')"
            prop="supplierPayId"
          >
            <el-select
              v-model="form.supplierPayId"
              :placeholder="$t('placeholder.plsSel')"
              class="w100"
              multiple
              collapse-tags
              @change="supplierPayIdChange"
            >
              <el-option
                v-for="item in payWaySelect"
                :key="item.paymentId"
                :label="item.payway"
                :value="item.paymentId"
                v-show="item.deleted === 0"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <!-- 完善详细信息请前往供应商模 -->
      <span class="f_s_12 ml30">
        Tips：{{ $t('productDetail.SupplierDetailsTips') }}
      </span>
    </el-row>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { saveSupplier } from '@/api/product/productList'
  import { mapGetters } from 'vuex'
  import { SupplierManageInteractor } from '@/core'
  export default {
    name: 'SupplierAddModel',
    data() {
      return {
        form: {
          productCategoryId: '',
          supplierName: '',
          supplierPayId: [],
          address: '',
          linkman: '',
          phone: '',
          linkmanDTOS: [], //供应商联系人信息
        },
        dialogFormVisible: false,
        payWaySelect: [], //付款方式
        payDTOS: [],
        qujian: [],
      }
    },

    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      rules() {
        return {
          supplierName: [
            // 请输入供应商名称
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          // 请输入地址
          address: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          // 请输入联系人
          linkman: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          // 请输入联系电话
          phone: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          supplierPayId: [
            // 请选择付款方式
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
        }
      },
    },
    created() {
      //供应商付款方式下拉
      this.pubApi.paymentList({ payee: 2 }).then((response) => {
        if (response.code === '000000') {
          this.payWaySelect = response.data
        }
      })
    },
    methods: {
      checkName() {
        const { supplierName } = this.form
        if (!this.form.supplierName) {
          return
        }
        SupplierManageInteractor.checkSupplierName(supplierName).then((res) => {
          if (res?.code === '000000') {
            if (res.data === true) {
              // 供应商已存在，请直接选择
              this.$message.warning(this.$t('reqmsg.M2073'))
              this.form.supplierName = ''
            }
          }
        })
      },
      //展示弹框
      supplierAddModel(id) {
        this.dialogFormVisible = true
      },

      //付款方式选择
      supplierPayIdChange(val) {
        let payDTOS = []
        let qujian = []
        this.payWaySelect.forEach((item, index) => {
          val.forEach((item2) => {
            if (item.paymentId === item2) {
              payDTOS.push(item)
            }
          })
        })
        payDTOS.forEach((item) => {
          item.paymentMethodName = item.payway
          item.intervalName = item.payway.split('/')[0]
          qujian.push(item.payway.split('/')[0])
        })

        if (new Set(qujian).size != qujian.length) {
          this.$set(
            this.form,
            'supplierPayId',
            this.form.supplierPayId.slice(0, this.form.supplierPayId.length - 1)
          )
          this.$baseMessage(
            // '相同的付款区间只能选择一个',
            this.$t('reqmsg.M2050'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      },
      // 关闭
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.linkman && this.form.phone) {
              this.form.linkmanDTOS = []
              let obj = {
                linkman: this.form.linkman,
                phone: this.form.phone,
              }
              this.form.linkmanDTOS.push(obj)
            }

            if (this.form.supplierPayId) {
              let payDTOS = []
              this.payWaySelect.forEach((item, index) => {
                if (this.form.supplierPayId[index] === item.paymentId) {
                  payDTOS.push(item)
                }
              })
              let qujian = []
              payDTOS.forEach((item) => {
                item.paymentMethodName = item.payway
                item.intervalName = item.payway.split('/')[0]
                qujian.push(item.payway.split('/')[0])
                //delete item.payway
              })
              this.form.payDTOS = payDTOS
            }

            if (this.form.linkmanDTOS.length) {
              delete this.form.linkman
              delete this.form.phone
            }
            // if (this.form.payDTOS.length) {
            //   delete this.form.supplierPayId
            // }

            try {
              let response = await saveSupplier(this.form)
              if ((response.code = '000000')) {
                this.$baseMessage(
                  // '创建成功，请联系管理员及时完善资料',
                  this.$t('reqmsg.M2051'),
                  'success',
                  false,
                  'erp-hey-message-success'
                )
                this.$emit('afterSave', response.data)

                this.close()
              } else {
                this.$set(
                  this.form,
                  'linkman',
                  this.form.linkmanDTOS[0]?.linkman
                )
                this.$set(this.form, 'phone', this.form.linkmanDTOS[0]?.phone)
              }
            } catch (error) {
              this.$set(this.form, 'linkman', this.form.linkmanDTOS[0]?.linkman)
              this.$set(this.form, 'phone', this.form.linkmanDTOS[0]?.phone)
              console.log(error)
            }
          }
        })
      },
    },
  }
</script>
