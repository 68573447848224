<template>
  <el-dialog :title="title" width="1200px" class="custom-dialog" v-if="crmModalVisable" :visible.sync="crmModalVisable"
    :close-on-click-modal="false" :before-close="close">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="验货类型" label-width="80px" prop="inspectionType">
            <el-select placeholder="请选择验货类型" style="width: 100%" v-model="form.inspectionType"
              @change="changeInspectionType" :disabled="isBaseEdit || type === 'OutProcessing'">
              <el-option label="采购单验货" :value="0"></el-option>
              <el-option label="加工单验货" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="采购订单" prop="purchaseOrderNumber" label-width="120px" :rules="[
              {
                required: form.inspectionType === 0,
                message: '请输入采购单号',
                trigger: 'blur',
              },
            ]">
            <el-input v-model="form.purchaseOrderNumber" @blur="purchaseOrderChange(form.purchaseOrderNumber, 1)"
              placeholder="请输入采购单号" clearable :disabled="isBaseEdit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商名称" prop="supplierName" label-width="130px" :rules="[
              {
                required: form.inspectionType === 0,
                message: '请输入供应商名称',
                trigger: 'blur',
              },
              { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
            ]">
            <el-input v-model="form.supplierName" placeholder="请输入" clearable :disabled="isBaseEdit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="采购开发" prop="buyerName" label-width="80px" :rules="[
              {
                required: form.inspectionType === 0,
                message: '请选择采购开发',
                trigger: 'change',
              },
            ]">
            <el-input @focus="buyerChange('buyer')" v-model="form.buyerName" placeholder="请选择" clearable
              @change="clearbuyerName('buyer')" :disabled="isBaseEdit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工厂交期" prop="expectDeliveryDate" label-width="120px" :rules="[
              {
                required: form.inspectionType === 0,
                message: '请选择工厂交期',
                trigger: 'change',
              },
            ]">
            <el-date-picker v-model="form.expectDeliveryDate" type="date" placeholder="选择日期" format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="验货阶段" prop="inspectionStage" label-width="130px">
            <el-select placeholder="请选择" style="width: 100%" :disabled="form.inspectionType === 1 || isBaseEdit"
              v-model="form.inspectionStage">
              <el-option v-for="item in inspectionStageOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="QC" prop="inspector" label-width="80px" :rules="[
              {
                required: form.inspectionType === 0,
                message: '请选择',
                trigger: 'change',
              },
            ]">
            <el-input v-model="form.inspector" @focus="buyerChange('QC')" placeholder="请选择" clearable
              @change="clearbuyerName('QC')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="期望验货时间" prop="inspectionDate" label-width="120px" :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'change',
              },
            ]">
            <el-date-picker v-model="form.inspectionDate" type="date" placeholder="选择日期" format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="物流公司及运单号" prop="logisticsCompanyNo" label-width="130px">
            <el-input v-model="form.logisticsCompanyNo" placeholder="请输入" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="验货地址" prop="inspectionAddress" label-width="80px" class="input-full" :rules="[
              {
                required: form.inspectionType === 0,
                message: '请输入验货地址',
                trigger: 'change',
              },
              { max: 200, message: '长度不能超过200个字符', trigger: 'blur' },
            ]">
            <el-input v-model="form.inspectionAddress" clearable>
              <el-select v-model="form.inspectionAddressType" @change="addressTypeChange(form.inspectionAddressType)"
                slot="prepend" placeholder="请选择">
                <el-option v-for="item in addressList" :key="item.warehouseId" :label="item.label"
                  :value="item.label"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-button class="mt10" type="primary" @click="chooseModal(form.inspectionType)"
        v-show="!isBaseEdit && type != 'OutProcessing'">
        {{ form.inspectionType == '0' ? '选择产品' : '选择销售订单' }}
      </el-button>

      <el-table class="no-space form-rule-table mt15" :data="form.list" :max-height="240" border
        :header-cell-style="{ 'text-align': 'center' }">
        <el-table-column prop="salesOrderNumber" width="160" label="销售订单编号"
          :label-class-name="form.inspectionType === 0 ? '' : 'required-th'">
          <template slot-scope="scope">
            <div v-if="scope.row.orderExist || crmDisabled">
              {{ scope.row.salesOrderNumber }}
            </div>
            <el-form-item v-else :prop="'list.' + [scope.$index] + '.salesOrderNumber'" :rules="[
                {
                  required: form.inspectionType === 1,
                  message: '销售订单编号必填',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="form.list[scope.$index].salesOrderNumber" :disabled="isListEdit1 || isListEdit2"
                maxlength="20" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="businessName" label="客户代表" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.orderExist || crmDisabled">
              {{ scope.row.businessName || '--' }}
            </div>
            <el-form-item v-else :prop="'list.' + [scope.$index] + '.businessName'">
              <el-input v-model="form.list[scope.$index].businessName" :disabled="isListEdit1 || isListEdit2"
                @focus="buyerChange('business', scope.$index)" @change="clearBusinessName(scope.$index)"
                placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="sku" width="140" label="产品编码">
          <template slot-scope="scope">
            <el-form-item>
              <div v-if="scope.row.isExist || scope.row.orderExist || crmDisabled">
                {{ scope.row.sku }}
              </div>
              <el-input v-else v-model="form.list[scope.$index].sku" :disabled="isListEdit1 || isListEdit2"
                maxlength="50" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="nameCn" width="200" label="产品名称" label-class-name="required-th" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-form-item :prop="'list.' + [scope.$index] + '.nameCn'" :rules="[
                {
                  required: true,
                  message: '产品名称必填',
                  trigger: 'blur',
                },
              ]">
              <div v-if="scope.row.isExist || scope.row.orderExist || crmDisabled">
                {{ scope.row.nameCn }}
              </div>
              <el-input v-else v-model="form.list[scope.$index].nameCn" :disabled="isListEdit1 || isListEdit2"
                maxlength="50" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="specsValue1" width="120" label="规格">
          <template slot-scope="scope">
            <el-form-item>
              <div v-if="crmDisabled">
                {{ form.list[scope.$index].specsValue1 }}
              </div>
              <el-input v-else v-model="form.list[scope.$index].specsValue1" :disabled="isListEdit1" maxlength="50"
                placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="printingImg" label="效果图"
          :label-class-name="form.inspectionType === 0 ? '' : 'required-th'">
          <template slot-scope="scope">
            <div v-if="
                (scope.row.orderExist && scope.row.printingImg) ||
                isListEdit1 ||
                isListEdit2
              " class="img-wrap">
              <el-badge v-show="
                  scope.row.printingImg &&
                  scope.row.printingImg.split(',').length > 1
                " :value="
                  scope.row.printingImg
                    ? scope.row.printingImg.split(',').length
                    : ''
                "></el-badge>
              <el-image v-if="scope.row.printingImg" :src="scope.row.printingImg.split(',')[0]" class="c_pointer"
                @click="handlePreview(scope.row.printingImg.split(','))"></el-image>
            </div>
            <div v-else class="wrapper-box" @click="choseRendingImages(scope.$index, scope.row.printingImg)">
              <el-badge v-show="
                  scope.row.printingImg &&
                  scope.row.printingImg.split(',').length > 1
                " :value="
                  scope.row.printingImg
                    ? scope.row.printingImg.split(',').length
                    : ''
                "></el-badge>
              <div class="wrapper-image" v-show="scope.row.printingImg">
                <img :src="getFirstImgUrl(scope.row.printingImg)" alt />
              </div>
              <div class="wrapper-add" v-show="!scope.row.printingImg">
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="numActual" width="120" label="采购数量">
          <template slot-scope="scope">
            <el-form-item :prop="'list.' + [scope.$index] + '.numActual'" :rules="[
                {
                  pattern: /^[0-9]+?$/,
                  message: '请输入>=0的整数',
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="form.list[scope.$index].numActual" :disabled="isListEdit1" maxlength="10"
                placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="120" prop="provideSamples" label="是否提供样品">
          <template slot-scope="scope">
            <el-form-item>
              <el-select placeholder="请选择" v-model="form.list[scope.$index].provideSamples" clearable>
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="numInspection" width="120" label="验货数量"
          :label-class-name="form.inspectionType === 0 ? 'required-th' : ''">
          <template slot-scope="scope">
            <el-form-item :prop="'list.' + [scope.$index] + '.numInspection'" :rules="[
                {
                  required: form.inspectionType === 0,
                  message: '验货数量必填',
                  trigger: 'blur',
                },
                {
                  pattern: /^[0-9]+?$/,
                  message: '请输入>=0的整数',
                  trigger: 'blur',
                },
              ]">
              <el-input maxlength="10" v-model="form.list[scope.$index].numInspection" placeholder="请输入"
                clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="180" prop="actualInspectionStandard" label="检验标准"
          :label-class-name="form.inspectionType === 0 ? 'required-th' : ''">
          <template slot-scope="scope">
            <el-form-item :prop="'list.' + [scope.$index] + '.actualInspectionStandard'" :rules="[
                {
                  required: form.inspectionType === 0,
                  message: '检验标准必填',
                  trigger: 'change',
                },
              ]">
              <el-select placeholder="请选择" v-model="form.list[scope.$index].actualInspectionStandard" clearable>
                <el-option label="全检" value="全检"></el-option>
                <el-option label="按照QC标准检验" value="按照QC标准检验"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="inspectionRequirements" label="验货要求" width="200"
          :label-class-name="form.inspectionType === 0 ? 'required-th' : ''">
          <template slot-scope="scope">
            <el-form-item :prop="'list.' + [scope.$index] + '.inspectionRequirements'" :rules="[
                {
                  required: form.inspectionType === 0,
                  message: '验货要求必填',
                  trigger: 'blur',
                },
              ]">
              <el-input type="textarea" maxlength="500" show-word-limit :rows="3"
                v-model="form.list[scope.$index].inspectionRequirements" placeholder="请输入验货要求" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="packingInformation" label="装箱信息" width="200"
          :label-class-name="form.inspectionType === 0 ? 'required-th' : ''">
          <template slot-scope="scope">
            <el-form-item :prop="'list.' + [scope.$index] + '.packingInformation'" :rules="[
                {
                  required: form.inspectionType === 0,
                  message: '装箱信息必填',
                  trigger: 'blur',
                },
              ]">
              <el-input type="textarea" maxlength="500" show-word-limit :rows="3"
                v-model="form.list[scope.$index].packingInformation" placeholder="请输入装箱信息" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="actualMark" width="200" label="唛头"
          :label-class-name="form.inspectionType === 0 ? 'required-th' : ''">
          <template slot-scope="scope">
            <el-form-item :prop="'list.' + [scope.$index] + '.actualMark'" :rules="[
                {
                  required: form.inspectionType === 0,
                  message: '唛头必填',
                  trigger: 'blur',
                },
              ]">
              <el-input type="textarea" maxlength="500" show-word-limit :rows="3"
                v-model="form.list[scope.$index].actualMark" placeholder="请输入唛头" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column width="80" prop label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button class="icon-r" @click="delProduct(scope.$index)" size="mini" icon="el-icon-delete"
              circle></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="add-btn mb10">
        <span @click="addRow" v-show="!isBaseEdit && type != 'OutProcessing'">
          添加一行
        </span>
      </div>

      <el-row :gutter="50">
        <el-col :span="12">
          <p>备注</p>
          <el-input type="textarea" v-model="form.remark" maxlength="500" show-word-limit :rows="3"
            placeholder="请输入500个字符以内"></el-input>
        </el-col>
        <el-col :span="12">
          <p>附件</p>
          <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList" @initUpload="initUpload">
          </ErpUpload>
        </el-col>
      </el-row>
    </el-form>

    <div class="text-center mt30">
      <el-button type="primary" @click="save" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close">取消</el-button>
    </div>

    <!-- 选取产品 -->
    <el-dialog width="1200px" title="选取产品" :visible.sync="productListVisible" :close-on-click-modal="false"
      append-to-body>
      <product-list v-if="productListVisible" ref="product" :requestType="true" @getProductData="getProductData"
        @productClose="productListVisible = false"></product-list>
    </el-dialog>

    <!-- 选择销售订单 -->
    <el-dialog width="1200px" title="选择销售单" :visible.sync="orderVisible" :close-on-click-modal="false" append-to-body>
      <order-list v-if="orderVisible" ref="order" @getOrderData="getOrderData"
        @orderClose="orderVisible = false"></order-list>
    </el-dialog>

    <!-- 选择图片 -->
    <choose-images :visible.sync="chooseImagesDialogVisible" :default-file-list="currentDefaultRendingList"
      @confirm="setRendingImages"></choose-images>

    <!-- 分配客户代表 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />
  </el-dialog>
</template>

<script>
  import ChooseImages from '../components/chooseImages'
  import productList from '@/components/productList'
  import orderList from '../components/orderList'
  import { purchaseExamineGoods } from '@/core/interactors/purchase/examineGoods'
  import { ProductListInteractor } from '@/core/interactors/product/productList'
  import { formatDataToTreeChecked } from 'kits'
  import { deepCopy } from 'kits'
  import UserChoose from '@/components/userChoose'
  import { mapGetters } from 'vuex'
  import * as dayjs from 'dayjs'
  export default {
    components: {
      productList,
      UserChoose,
      ChooseImages,
      orderList,
    },
    data() {
      return {
        type: '', // 入口来源
        processOrderCode: '', // 加工单号
        numberPrefix: '',
        form: {
          /**
           * 基本信息
           */
          inspectionSource: 1, // 1.PC, 2.小程序
          createType: 1, // 创建类型 - 0.采购验货创建, 1.手动创建
          purchaseOrderNumber: '', // 采购订单
          supplierName: '', // 供应商名称
          expectDeliveryDate: '', // 工厂交期
          inspectionStage: '', // 验货阶段
          buyerId: '', // 采购开发id
          buyerName: '', // 采购开发名称
          inspectionAddress: '', // 验货地址
          inspectionAddressType: '', // 验货地址类型
          inspectionDate: dayjs(Date.now()).format('YYYY-MM-DD'), // 期望验货时间
          logisticsCompanyNo: '', // 物流公司及单号
          remark: '', // 备注
          fileAnnex: '', // 附件
          /**
           * 采购需求产品列表
           */
          list: [],
          inspectionType: 0, // 验货类型
          inspector: '', // qc
          inspectorId: '', // QCid
        },
        chooseIndex: 0,
        businessArray: [], // 客户代表
        formBusinessArray: [], // 采购开发
        formQCArray: [], // QC
        businessType: 0, // 选择类型
        crmModalVisable: false,
        isLoading: false,
        isBaseEdit: false,
        isListEdit1: false, // 采购单验货列表编辑控制
        isListEdit2: false, // 加工单验货列表编辑控制
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        rules: {
          purchaseOrderNumber: [
            { required: true, message: '请输入采购订单', trigger: 'blur' },
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
          supplierName: [
            { required: true, message: '请输入供应商名称', trigger: 'blur' },
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
          expectDeliveryDate: [
            { required: true, message: '请选择工厂交期', trigger: 'change' },
          ],
          buyerName: [
            { required: true, message: '请选择采购开发', trigger: 'change' },
          ],
          inspectionAddress: [
            { required: true, message: '请输入验货地址', trigger: 'blur' },
            { max: 200, message: '长度不能超过200个字符', trigger: 'blur' },
          ],
          logisticsCompanyNo: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
          inspectionType: [
            { required: true, message: '请选择验货类型', trigger: 'change' },
          ],
          inspectionStage: [
            { required: true, message: '请选择验货阶段', trigger: 'change' },
          ],
          // inspector: [{ required: true, message: '请选择QC', trigger: 'change' }],
        },
        addressList: [
          {
            label: '供应商地址',
            address: '',
            warehouseId: '',
          },
        ], // 验货地址下拉数据
        inspectionStageOptions: [
          {
            label: '初期验货',
            value: 1,
          },
          {
            label: '中期验货',
            value: 2,
          },
          {
            label: '尾期验货',
            value: 3,
          },
        ], // 验货阶段字典
        orderVisible: false,
        productListVisible: false,
        chooseImagesDialogVisible: false, // 选择图片弹窗
        currentRendingIndex: -1, // 当前选择图片的产品，对应的 index
        currentDefaultRendingList: [], // 当前选中的图片
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
          amount: 9,
          multiple: true,
        },
        title: '新增CRM验货单',
        crmDisabled: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    watch: {},
    created() {
      this.getAddressList()
    },
    methods: {
      // 重新提交生成验货单
      resubmit(id) {
        this.crmModalVisable = true
        purchaseExamineGoods
          .getResubDetailForShowApi({ businessId: id })
          .then((res) => {
            const data = res.data

            this.form.reSubInspectionOrderId = id
            this.form.createType = data.createType
            this.form.purchaseOrderNumber = data.purchaseOrderNumber
            this.form.supplierName = data.supplierName
            this.form.expectDeliveryDate = data.expectDeliveryDate
            this.form.inspectionStage = data.inspectionStage
            this.form.buyerId = data.buyerId
            this.form.buyerName = data.buyerName
            this.form.inspectionAddress = data.inspectionAddress
            this.form.inspectionAddressType = data.inspectionAddressType
            this.form.inspectionDate = data.inspectionDate
            this.form.logisticsCompanyNo = data.logisticsCompanyNo
            this.form.fileAnnex = data.fileAnnex
            this.form.inspectionType = data.inspectionType
            this.form.inspector = data.inspector
            this.form.inspectorId = data.inspectorId
            this.form.purchaseOrderId = data.purchaseOrderId
            this.form.remark = data.remark
            this.form.list =
              data.inspectionProductEditVOList?.map((item) => {
                return {
                  ...item,
                  inspectionOrderId: '',
                  inspectionProductId: '',
                  provideSamples: String(item.provideSamples),
                }
              }) || []

            // 重新验货部分字段是否可编辑 inspectionType 0:采购单验货 1:加工单验货
            if (this.form.inspectionType === 0) {
              this.isBaseEdit = true
              // createType 0.采购验货创建-列表部分信息不可编辑, 1.手动创建列表全都可编辑
              if (this.form.createType === 0) {
                this.isListEdit1 = true
              }
            } else {
              this.isListEdit2 = true
            }
            //附件
            if (this.form.fileAnnex) {
              setTimeout(() => {
                this.initUpload(this.form.fileAnnex)
              }, 300)
            }
          })
      },

      // 获取验货地址
      async getAddressList() {
        const res = await purchaseExamineGoods.warehouseList(0, 0)
        if (res?.data && res?.code === '000000') {
          const _arr = res.data.map((item) => {
            return {
              label: item.name,
              address: item.address,
              warehouseId: item.warehouseId,
            }
          })

          this.addressList = this.addressList.concat(_arr)
        }
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.fileAnnex = JSON.stringify(fileList)
      },

      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },

      // 添加一行
      addRow() {
        this.form.list.push({
          salesOrderNumber: '', // 销售订单编号
          productCode: '', // 产品编码
          businessId: '', // 客户代表Id
          businessName: '', // 客户代表
          businessNameEn: '', // 客户代表英文
          nameCn: '', // 产品名称
          specsValue1: '', // 规格1
          specsValue2: '', // 规格2
          numActual: '', // 采购数量
          provideSamples: '', // 是否提供样品
          numInspection: '', // 验货数量
          actualInspectionStandard: '', // 检验标准
          printingImg: '', // 效果图
          inspectionRequirements: '', // 验货要求
          packingInformation: '', // 装箱信息
          actualMark: '', // 唛头
        })
      },

      // 获取产品数据
      getProductData(data) {
        let arr = []

        data.forEach((item) => arr.push(item.skuId))
        ProductListInteractor.producGetProductInfoBySkuApi({
          param: arr.join(','),
        }).then((res) => {
          let _arr = res.data.map((item, i) => {
            return {
              ...item,
              salesOrderNumber: '', // 销售订单编号
              numActual: '', // 采购数量
              provideSamples: '', // 是否提供样品
              numInspection: '', // 验货数量
              actualInspectionStandard: '', // 检验标准
              printingImg: '', // 效果图
              inspectionRequirements: '', // 验货要求
              packingInformation: '', // 装箱信息
              actualMark: '', // 唛头
              isExist: true, // 选择带出的产品
              packingInformation: this.getPackingInfo(item, 2), // 装箱信息
            }
          })
          this.form.list = this.form.list.concat(_arr)
          this.productListVisible = false
        })
      },

      // 选择销售单数据
      getOrderData(_arr) {
        this.form.list = this.form.list.concat(_arr)
        this.orderVisible = false
      },

      // 根据采购订单赋值相关信息
      async purchaseOrderChange(number, type) {
        if (number) {
          const res = await purchaseExamineGoods.GetHasProduct(number)

          if (res.data?.data?.length) {
            const _res = res.data.data[0]

            this.form.supplierName = _res.supplierName
            this.form.expectDeliveryDate = _res.actualDeliveryDate
            this.form.buyerId = _res.buyerId
            this.form.buyerName = _res.buyerName
            this.addressList[0].address = _res.supplierAddress
            this.form.list = [] // 先清空再赋值
            this.form.list = _res.purchaseProductEditVOS.map((item) => {
              return {
                salesOrderNumber: item.salesOrderNumber, // 销售订单编号
                productCode: item.sku, // 产品编码
                businessId: item.businessId, // 客户代表Id
                businessName: item.businessName, // 客户代表
                businessNameEn: item.businessNameEn, // 客户代表英文
                nameCn: item.nameCn, // 产品名称
                specsValue1: item.specsValue1 + ';' + item.specsValue2, // 规格1
                specsValue2: item.specsValue2, // 规格2
                numActual: item.numActual, // 采购数量
                orderProductUniqueId: item.orderProductUniqueId, // 唯一Id
                sku: item.sku, // sku
                provideSamples: '', // 是否提供样品
                numInspection: '', // 验货数量
                actualInspectionStandard: '', // 检验标准
                inspectionRequirements: '', // 验货要求
                packingInformation: this.getPackingInfo(item, type), // 装箱信息
                printingImg: item.printingImg, // 效果图
              }
            })

            // 采购开发回显
            this.formBusinessArray = formatDataToTreeChecked(
              _res.buyerId,
              _res.buyerName,
              _res.buyerNameEn
            )
          } else {
            this.$message.warning('采购订单不存在，请手动设置产品验货信息')
          }
        }
      },

      // 获取装箱信息
      getPackingInfo(item, type) {
        let [L, W, H] = [0, 0, 0]

        if (type === 1) {
          L = item.boxLengthCm
          W = item.boxWidthCm
          H = item.boxHeightCm
        } else {
          L = item.boxLengthIn
          W = item.boxWidthIn
          H = item.boxHeightIn
        }

        const info = `装箱数量：${item.numberPerBox
          }\n装箱尺寸：${L}'' x ${W}'' x ${H}''\n装箱重量：${item.boxWeightKgs || 0
          }Kgs`

        return info
      },

      // 选择验货地址
      addressTypeChange(name) {
        const _arr = this.addressList.filter((item) => item.label === name)

        this.form.inspectionAddress = _arr[0].address
      },

      // 选择客户代表 采购开发 QC
      buyerChange(role, i) {
        if (role === 'business') {
          this.businessType = 1
          this.chooseIndex = i

          if (this.form.list[i].businessName) {
            this.businessArray = [
              {
                name: this.form.list[i].businessName,
                id: this.form.list[i].businessId,
              },
            ]
          } else {
            this.businessArray = []
          }
          this.$refs['UserChoose'].showAddEdit(
            '1',
            deepCopy(this.businessArray),
            true
          )
        }
        if (role === 'buyer') {
          this.businessType = 2
          this.$refs['UserChoose'].showAddEdit(
            '1',
            deepCopy(this.formBusinessArray),
            true
          )
        }
        if (role === 'QC') {
          this.businessType = 3
          this.$refs['UserChoose'].showAddEdit(
            '1',
            deepCopy(this.formQCArray),
            true
          )
        }
      },

      // 删除客户代表
      clearBusinessName(i) {
        this.form.list[i].businessId = ''
        this.form.list[i].businessName = ''
        this.businessArray = []
      },

      // 删除采购开发
      clearbuyerName(role) {
        if (role === 'buyer') {
          this.form.fromBusinessId = ''
          this.form.buyerName = ''
          this.formBusinessArray = []
        }
        if (role === 'QC') {
          this.form.inspectorId = ''
          this.form.inspector = ''
        }
      },

      // 获取客户代表
      chooseUser(userIdArr) {
        if (this.businessType == 1) {
          this.businessArray = userIdArr

          let [ids, names] = [[], [], []]
          userIdArr.forEach((item) => {
            ids.push(item.id)
            names.push(item.name)
          })
          this.form.list[this.chooseIndex].businessId = ids.join(',')
          this.form.list[this.chooseIndex].businessName = names.join(',')
        }
        if (this.businessType == 2) {
          this.formBusinessArray = userIdArr
          this.form.buyerId = userIdArr[0].id
          this.form.buyerName = userIdArr[0].name
        }
        if (this.businessType == 3) {
          this.formQCArray = userIdArr
          this.form.inspectorId = userIdArr[0].id
          this.form.inspector = userIdArr[0].name
        }
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            if (!this.form.list.length) {
              this.$message.warning('至少选择一条产品')
              return false
            }

            if (
              !this.form.list.every((item) => item.printingImg) &&
              this.form.inspectionType === 1
            ) {
              this.$message.warning('效果图必填')
              return false
            } else {
              this.isLoading = true
              const params = this.dataProcessing()
              const res = await purchaseExamineGoods.InsertPO(params)

              if (res && res.code == '000000') {
                this.$message.success('保存成功')
                if (this.type === 'OutProcessing') {
                  this.$emit('examineCargo')
                } else {
                  this.$parent.initLoading()
                }
                this.close()
              }
              this.isLoading = false
            }
          } else {
            return false
          }
        })
      },

      // 保存数据处理
      dataProcessing() {
        this.form.inspectionProductEditDTOList = this.form.list
        return {
          ...this.form,
          processOrderCode: this.processOrderCode,
        }
      },

      // 删除产品
      delProduct(i) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.list.splice(i, 1)
            this.$message.success('删除成功！')
          })
          .catch(() => { })
      },

      // 获取第一张图片
      getFirstImgUrl(urls) {
        return urls ? urls.split(',')[0] : ''
      },

      // 显示 选择图片 弹窗
      choseRendingImages(index, urls) {
        this.chooseImagesDialogVisible = true
        this.currentRendingIndex = index
        this.currentDefaultRendingList = []
        if (urls) {
          let _urls = urls.split(',')
          for (let i = 0; i < _urls.length; i++) {
            this.currentDefaultRendingList.push({
              url: _urls[i],
              name: '',
            })
          }
        }
      },

      // 设置对应产品的 图片
      setRendingImages(url) {
        this.form.list[this.currentRendingIndex].printingImg = url
      },

      // 选择产品、选择销售订单
      chooseModal(type) {
        type === 0
          ? (this.productListVisible = true)
          : (this.orderVisible = true)
      },

      // 关闭弹窗重置表单
      close() {
        this.form = this.$options.data().form
        this.$refs.ruleForm.resetFields()
        this.clearbuyerName()
        this.isBaseEdit = false
        this.isListEdit1 = false
        this.isListEdit2 = false
        this.crmModalVisable = false
        this.businessArray = []
        this.formBusinessArray = []
        this.formQCArray = []
      },

      // 验货类型切换 清空数据
      changeInspectionType(val) {
        this.$refs.ruleForm.resetFields()
        this.form.inspectionType = val
        this.businessArray = []
        this.formBusinessArray = []
        this.formQCArray = []
        this.form.list = []

        // 加工验货-验货阶段默认为尾期验货
        if (val === 1) this.form.inspectionStage = 3
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      // 委外加工  验货通知
      setCrmData(data) {
        this.title = data.title
        this.type = data.type
        this.processOrderCode = data.processOrderCode
        this.form.inspectionType = 1
        this.form.inspectionStage = 3
        this.form.createType = 2
        this.form.processOrderFlag = 1
        this.form.supplierName = data.supplierName
        this.form.inspector = data.inspector
        this.form.inspectorId = data.inspectorId
        this.form.list = data.list
        // QC回显
        this.formQCArray = formatDataToTreeChecked(
          this.form.inspectorId,
          this.form.inspector
        )
        this.crmModalVisable = true
        // 销售订单编号，客户代表，产品编码，产品名称，规格  不可修改
        this.crmDisabled = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    .img-wrap {
      position: relative;
      padding: 3px 0;
      width: 50px;
      margin: 0 auto;

      .el-image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        display: block;
      }

      .el-badge {
        position: absolute;
        top: 3px;
        right: -10px;
        z-index: 2;
      }
    }

    .wrapper-box {
      text-align: center;
      position: relative;

      .wrapper-add {
        display: inline-block;
        width: 64px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        border: 1px dotted #c0cbda;
        border-radius: 4px;
        cursor: pointer;

        i {
          font-size: 18px;
        }
      }

      .wrapper-image {
        img {
          width: 64px;
          height: 64px;
          margin-top: 8px;
          border-radius: 2px;
          object-fit: cover;
          cursor: pointer;
        }
      }

      .el-badge {
        position: absolute;
        top: 4px;
        right: 8px;
      }
    }

    .el-form-item {
      .el-input {
        width: 100%;
      }
    }

    p {
      margin: 10px 0;

      span {
        font-size: 16px;
        font-family: 'PingFang Bold';
      }
    }

    .add-btn {
      width: 100%;
      text-align: center;
      line-height: 40px;

      span {
        color: #409eff;
        cursor: pointer;
      }
    }
  }

  /* 带表单验证的table */
  .form-rule-table {
    /deep/ {
      .cell {
        overflow: initial;
        text-align: center;

        .el-form-item__content {
          padding: 5px 0;

          .el-form-item__error {
            top: 38px;
            margin-left: 2px;
          }
        }
      }
    }
  }

  ::v-deep {
    .input-full {
      .el-select .el-input {
        width: 120px;
      }
    }
  }
</style>
