<template>
  <el-dialog
    title="认领设置"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-row type="flex" align="middle">
        <label>个人重复认领超过</label>
        <el-input
          type="number"
          v-model="form.claim"
          placeholder="请输入"
          class="no-number"
          style="width: 150px; padding: 0 15px"
        ></el-input>
        <span>次，不可再认领</span>
      </el-row>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import CustomerCenterInteractor from '@/core/interactors/customer-manage/customer-center'
export default {
  name: 'PublicClientClaimModel',
  data() {
    return {
      form: {},
      dialogFormVisible: false,
      value: null,
    }
  },
  created() {},
  methods: {
    //新增、编辑
    showAddEdit() {
      this.getClaimSetting()
      this.dialogFormVisible = true
    },

    //获取认领设置
    async getClaimSetting() {
      const res = await CustomerCenterInteractor.claimSettingEdit()

      if (res.code === '000000') {
        this.form = res.data
      }
    },
    close() {
      this.dialogFormVisible = false
    },
    async save() {
      if (!this.form.claim) {
        return this.$baseMessage(
          '请填写认领次数',
          'error',
          false,
          'erp-hey-message-error'
        )
      } else {
        this.form.claim = Number(this.form.claim)

        const res = await CustomerCenterInteractor.claimSetting(this.form)
        if (res.code === '000000') {
          this.$baseMessage(
            '认领设置成功',
            'success',
            false,
            'erp-hey-message-success'
          )
        }
      }
      this.$emit('fetch-data')
      this.close()
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>
