<template>
  <el-select :key="lang" ref="sel" v-model="supplier" v-loadmore:el-select-dropdown__list="loadMore"
    popper-class="user-sel-select" value-key="supplierId" filterable remote :placeholder="$t('placeholder.plsSel')"
    :remote-method="remoteMethod" :loading="loading" clearable :disabled="disabled" :multiple="multiple"
    :collapse-tags="true" @focus="() => remoteMethod('')" @visible-change="onBlur" @clear="clearSelectFn">
    <el-option v-for="item in options" :key="item.supplierId" :value="item" :label="item.supplierName"
      :disabled="item.status != 6 && item.status != 2">
      <p class="flex">
        <span class="flex-1">{{ item.supplierName || '--' }}</span>
        <span v-if="item.status != 6 && item.status != 2" style="color: #8492a6">
          {{ item.status | supplierAuditStatus }}
        </span>
      </p>
    </el-option>
  </el-select>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { systemInteractor } from '@/core'
  import { listSupplierByProductCategoryId } from '@/api/product/productList'
  import { supplierAuditStatus } from '@/utils/en-match-zh'

  export default {
    name: 'UserSelect',
    props: {
      roleName: {
        type: String,
        default: '',
      },
      divideGroup: {
        type: Number,
        default: 0,
      },
      defaultSupplier: {
        type: [Object, Array],
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        supplier: [],
        chooseUser: '',
        loading: false,
        options: [],
        pageLe: 30,
        pageNo: 1,
        total: 0,
      }
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
        userInfo: 'user/userInfo',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },

    watch: {
      supplier: {
        deep: true,
        handler: function (val) {
          // 多选单选数据格式统一
          val = Array.isArray(val) ? val : [val]
          this.$emit('supplier-choose', val)
        },
      },
      defaultSupplier: {
        deep: true,
        handler: function (val) {
          this.initSupplier(val)
        },
      },
    },
    filters: {
      //审核状态匹配
      supplierAuditStatus(val) {
        return supplierAuditStatus(val)
      },
    },
    methods: {
      // 点击清楚按钮
      clearSelectFn() {
        this.$emit('clear-selectFn')
      },
      onBlur(val) {
        if (!val) {
          this.$refs.sel.blur()
        }
      },
      initSupplier(supplier) {
        if (supplier?.supplierId) {
          this.options = [supplier]
        } else {
          this.options = supplier
        }
        this.supplier = supplier
      },
      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },

      remoteMethod(query) {
        this.loading = true
        this.query = query
        this._getOptions()
      },
      _reset() {
        this.pageNo = 1
        this.total = 0
        this.options = []
      },
      _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        let query = this.query
        listSupplierByProductCategoryId({
          pageLe: this.pageLe,
          pageNo: this.pageNo,
          params: this.query,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.loading = false
            this.options = loadMore
              ? this.options.concat(res.data.data)
              : res.data.data
            this.total = res.data.total
          } else {
            this.loading = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-select-dropdown__item .wrapper {
    display: flex;

    span {
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tl {
    text-align: left;
    padding-right: 5px;
  }

  .tr {
    text-align: right;
    padding-left: 5px;
    color: #909399;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-1 {
    flex: 1;
  }
</style>
<style>
  .el-form-item__content .user-sel-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .user-sel-select .el-select-dropdown__list {
    height: 200px !important;

    overflow: auto;
    overflow-x: hidden;
  }

  .user-sel-select .el-select-dropdown__list li {
    width: 300px !important;
  }
</style>
