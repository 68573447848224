<!--
  功能：编辑销售订单原因
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月14日 10:26:39
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <!-- 修改原因 -->
  <el-dialog
    :title="$t('AddOrder.ReasonForModification')"
    width="400px"
    class="dialog"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <span>*</span>
      <el-form-item
        prop="updateRemark"
        label-position="left"
        :rules="[
          {
            required: true,
            trigger: 'change',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input
          v-model="form.updateRemark"
          maxlength="500"
          :autosize="{ minRows: 4, maxRows: 8 }"
          type="textarea"
          :placeholder="$t('placeholder.plsInput')"
          prop="updateRemark"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="text-center mt20">
      <!-- 确认 -->
      <el-button type="primary" @click="save">
        {{ $t('inquiryList.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="cancel">
        {{ $t('inquiryList.Cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
let _resolve = null,
  _reject = null
export default {
  name: 'UpdateSheetRemark',
  props: {},
  components: {},
  // 组件状态值
  data() {
    return {
      form: {
        updateRemark: '',
      },
      rules: {},
      visible: false,
    }
  },
  watch: {},
  methods: {
    save() {
      this.$refs.form.validate((res) => {
        if (res) {
          _resolve(this.form.updateRemark)
        }
      })
    },

    cancel() {
      this.visible = false
      _reject()
    },

    showAddAndEdit(updateRemark) {
      return new Promise((resolve, reject) => {
        _resolve = resolve
        _reject = reject
        if (updateRemark) {
          this.form.updateRemark = updateRemark
        }
        this.visible = true
      })
    },
  },
}
</script> 

<style scoped lang="scss">
.el-form {
  display: flex;
  span {
    color: red;
    margin-right: 5px;
  }
  .el-form-item {
    flex: 1;
  }
}
</style>
