<template>
  <div class="custom-container">
    <div v-show="!isAdd">
      <!-- 头部操作栏 -->
      <el-row class="page-header">
        <el-col :span="18" class="page-header-left">
          <el-button type="primary" class="mr10" @click="add">新增</el-button>
        </el-col>
      </el-row>

      <!-- table -->
      <el-row>
        <el-table border :data="tableData" class="list-table mt20">
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="area"
            label="地区"
          ></el-table-column>
          <el-table-column align="center" prop="role" label="角色">
            <template slot-scope="scope">
              {{ getRole(scope.row.role) }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="manager"
            label="主管"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="address"
            label="状态"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status === 1">启用</span>
              <span v-else>冻结</span>
            </template>
          </el-table-column>

          <!-- 操作 -->
          <el-table-column align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="medium"
                @click="setStatus(scope.row)"
              >
                {{ scope.row.status === 1 ? '冻结' : '启用' }}
              </el-button>
              <el-button type="text" size="medium" @click="edit(scope.row)">
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 分页 -->
      <el-pagination
        background
        layout="total, sizes,  prev, pager, next, jumper"
        :current-page="page.pageNo"
        :page-size="page.pageLe"
        :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
        :total="total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>

    <!-- 新增 -->
    <theme-add
      :isAdd="isAdd"
      :areaArray="areaArray"
      :roleArray="roleArray"
      @Cancel="Cancel"
      @Add="themeAdd"
    ></theme-add>

    <!-- 编辑 -->
    <theme-edit
      :isEdit="isEdit"
      :editRow="editRow"
      :areaArray="areaArray"
      :roleArray="roleArray"
      @Cancel="isEdit = false"
      @Edit="themeEdit"
    ></theme-edit>
  </div>
</template>

<script>
import themeAdd from './add'
import themeEdit from './edit'
import { FinanceThemeSetting } from '@/core/interactors/finance/config-manage'
export default {
  name: 'themeConfig',
  components: {
    themeAdd,
    themeEdit,
  },
  // 组件状态值
  data() {
    return {
      page: {
        pageNo: 1,
        pageLe: 10,
      },
      tableData: [],
      areaArray: [], // 地区信息
      // 角色信息
      roleArray: [
        {
          code: 'ORDER',
          message: '订单支持',
        },
        {
          code: 'PRODUCT',
          message: '产品运营',
        },
        {
          code: 'BUYER',
          message: '采购开发',
        },
        {
          code: 'BUSINESS',
          message: '客户代表',
        },
      ],
      total: 0,
      editRow: {}, // 编辑数据
      isAdd: false,
      isEdit: false,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getArea()
    this.getList()
  },
  methods: {
    // 获取地区
    async getArea() {
      this.areaArray = await FinanceThemeSetting.allDeptAreaByTenant()
    },

    // 获取列表
    async getList() {
      let res = await FinanceThemeSetting.ThemeListPage(this.page)

      this.tableData = res.data
      this.total = res.total
    },

    // 角色渲染
    getRole(role) {
      return this.roleArray.find((item) => item.code === role)?.message || '--'
    },

    // 设置状态 status(1启用, 0冻结)
    setStatus(row) {
      let params = {
        status: row.status === 1 ? 0 : 1,
        configManagerId: row.configManagerId,
      }
      if (row.status === 1) {
        this.$confirm(`冻结后，新单据则无法调用此模板！`, '提示', {
          type: 'warning',
        })
          .then(() => {
            this.setStatusRes(params, false)
          })
          .catch(() => {})
      } else {
        this.setStatusRes(params, true)
      }
    },

    // 设置状态回调 type true:启用 false:冻结
    async setStatusRes(params, type) {
      let res = await FinanceThemeSetting.ThemeUpdateStatus(params)
      if (res.code === '000000') {
        this.$message.success(`${type ? '启用' : '冻结'}成功`)
        this.getList()
      }
    },

    //切换分页
    pageChange(val) {
      this.page.pageNo = val
      this.getList()
    },

    // 分页数量变化
    sizeChange(val) {
      this.page.pageLe = val
      this.getList()
    },

    // 新增
    add() {
      this.isAdd = true
    },

    // 编辑
    edit(row) {
      this.editRow = row
      this.isEdit = true
    },

    // 新增返回
    Cancel() {
      this.isAdd = false
    },

    // 新增回调
    themeAdd() {
      this.page.pageNo = 1
      this.getList()
      this.isAdd = false
    },

    // 保存回调
    themeEdit() {
      this.page.pageNo = 1
      this.getList()
      this.isEdit = false
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .list-table {
    height: calc(100vh - 200px);
    border-left: none;
    overflow-y: auto;
  }
  .el-pagination {
    height: 36px;
  }
}
</style>
