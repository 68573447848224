<!--
  功能：结算模板配置-阶梯
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月23日 09:18:39
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="ladder-wrapper">
    <el-form
      :model="form"
      label-width="100px"
      :rules="rules"
      size="small"
      ref="form"
    >
      <el-table :data="form.tableData" border :max-height="tableHeight">
        <el-table-column
          align="center"
          prop="num"
          label="区间金额(开始)"
          label-class-name="require-th"
          border
        >
          <template slot-scope="scope">
            <div class="input-group">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.beginAmount'"
                :rules="[
                  {
                    required: true,
                    validator: (rule, value, cb) => {
                      startNumValid(rule, value, cb, scope.$index)
                    },
                    trigger: 'blur',
                  },
                ]"
                label-width="0"
              >
                <el-input
                  size="small"
                  @change="val => padThree(val,scope.row,'beginAmount')"
                  v-model="scope.row.beginAmount"
                  placeholder="请输入"
                />
              </el-form-item>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="num"
          label="区间金额(结束)"
          label-class-name="require-th"
          border
        >
          <template slot-scope="scope">
            <div class="input-group">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.endAmount'"
                :rules="[
                  {
                    required: true,
                    validator: (rule, value, cb) => {
                      endNumValid(rule, value, cb, scope.$index)
                    },
                    trigger: 'blur',
                  },
                ]"
                label-width="0"
              >
                <el-input
                  size="small"
                  @change="val => padThree(val,scope.row,'endAmount')"
                  v-model="scope.row.endAmount"
                  placeholder="不填写则默认为+∞"
                />
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="num" label="提成点(%)">
          <template slot-scope="scope">
            <el-form-item
              label-width="0"
              :prop="'tableData.' + scope.$index + '.ratio'"
              :rules="[
                
                {
                
                  validator: (rule, value, cb) => {
                    percentValid(rule, value, cb, scope.$index)
                  },
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                size="small"
                v-model="scope.row.ratio"
                placeholder="请输入"
              />
            </el-form-item>
            <span v-if="scope.row.ratio > 50" class="info-p">
              当前录入的提成点较高，请核对，是否有误
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="date" label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.$index == form.tableData.length - 1 &&
                form.tableData.length != 1
              "
              :disabled="form.tableData.length == 1"
              icon="el-icon-delete"
              type="text"
              size="mini"
              class="red"
              @click="removeItem(scope.$index)"
            ></el-button>

            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="btn-wrapper">
      <el-button @click="addNew" type="text">新增一行</el-button>
    </div>
    </el-form>
    
  </div>
</template>

<script>
  import * as _ from 'lodash'
  const baseItem = {
    beginAmount: undefined,
    endAmount: undefined,
    ratio: undefined,
  }

  export default {
    name: 'SettlementTplConfigInteractorLadder',
    props: {},
    components: {},
    // 组件状态值
    data() {
      return {
        form: {
          tableData: [], //列表数据
        },
        rules: {}, //校验规则
        tableHeight: 300,
      }
    },
    methods: {
      // 补充三位小数
      padThree(val,row,field) {
        
        if(val) {
          val = +val
          if(!isNaN(val)) {
            row[field] = val.toFixed(3)
          }
          
        }
      },
      // 获取表格高度
      getTableHeight() {
        const W_H = document.body.getBoundingClientRect().height
        this.tableHeight = W_H - 54 - 200
      },
      // resize监听
      onResize() {
        window.addEventListener('resize', this.getTableHeight, false)
      },
      // 新增一行
      addNew() {
        this.form.tableData.push({ ...baseItem })
      },

      // 删除一行
      removeItem(index) {
        this.form.tableData.splice(index, 1)
      },
      // 初始化列表数据
      initTableData() {
        if (this.form.tableData?.length === 0) {
          this.form.tableData.push({
            ...baseItem,
            beginAmount: 0,
          })
        }
      },

      // 百分比
      percentValid(rule, value, callback, index) {
        
        if (value === '' || value === undefined || value === null) {
          callback()
        } else {
          
          if (isNaN(value)) {
            callback(new Error('请输入数字'))
          } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            callback(new Error('请输入大于等于0的数字,最多精确到小数点后两位'))
          } else if (value > 100) {
            callback(new Error('不能超过100'))
          } else {
            callback()
          }
        }
      },
      // 最小金额校验
      startNumValid(rule, value, callback, index) {
        if (isNaN(value) || value === '') {
          callback(new Error('请输入数字'))
        } else if (index == 0 && value == '0') {
          //第一行可以输入0
          callback()
        } else if (!/^\d+(\.\d{1,3})?$/.test(value)) {
          callback(new Error('最多精确到小数点后三位'))
        } else if (
          index > 0 &&
          Number(value) < this.form.tableData[index - 1].endAmount
        ) {
          callback(new Error('必须大于上一条金额(结束)'))
        } else {
          callback()
        }
      },
      // 最大金额校验
      endNumValid(rule, value, callback, index) {
        if (index == this.form.tableData.length - 1 && !value) {
          //最后一条可以不填写
          callback()
        } else {
          if (isNaN(value)) {
            callback(new Error('请输入数字'))
          } else if (!/^\d+(\.\d{1,3})?$/.test(value)) {
            callback(new Error('最多精确到小数点后三位'))
          } else if (Number(value) < this.form.tableData[index].beginAmount) {
            callback(new Error('不能小于起始金额'))
          } else {
            callback()
          }
        }
      },
      // 保存
      save() {
        
        this.$refs.form.validate((flag) => {
          if (flag) {
            
            let tableData = this.form.tableData.slice().map((item) => {
              if (item.beginAmount) {
                // item.beginAmount *= 1
              }
              if (item.endAmount) {
                // item.endAmount *= 1
              }
              if (item.ratio) {
                // item.ratio *= 1
              }
              return item
            })
            if (tableData.length) {
              //如果最后一行不填，表示无穷大，需要传给后端null
              if (tableData[tableData.length - 1].endAmount == '') {
                tableData[tableData.length - 1].endAmount = null
              }
            }
          
            this.$emit(
              this.$route.query.bid ? 'ladder-edit' : 'ladder-save',
              tableData
            )
          }
        })
      },
    },

    created() {
      this.initTableData()
      this.getTableHeight()
      this.onResize()
    },
  }
</script>

<style scoped lang="scss">
  .ladder-wrapper {
    .el-button {
      border-top: none;
      width: 100%;
    }
  }
  ::v-deep .el-table--border {
    border-right: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
  }

  .info-p {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 43px;
    left: -9px;
    text-align: right;
    display: inline-block;
    width: 100%;
    text-align: right;
  }
</style>
