<template>
  <div>
    <div style="display: flex; align-items: center">
      <p>条件1：已收金额-应收金额误差值：</p>
      <el-tooltip class="item" effect="dark" placement="top-start">
        <div slot="content">
          1.当已收金额-应收金额的差值在设置的区间范围内，且货物已签收的情况下，订单会自动完结；
          <br />
          2.金额区间为包含关系；
        </div>
        <i class="el-icon-question"></i>
      </el-tooltip>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="120px"
      style="margin-top: 20px"
      :inline="true"
    >
      <el-form-item label="误差金额区间" prop="amountName">
        <el-input
          v-model="form.amountName"
          style="width: 140px"
          @change="changeNum(form.amountName, 'amountName')"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item>~</el-form-item>
      <el-form-item>
        <el-input
          v-model="form.amountShort"
          style="width: 140px"
          @change="changeNum(form.amountShort, 'amountShort')"
          placeholder="不填写默认为+∞"
        ></el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content: center">
      <el-button type="primary" @click="submitForm">确 认</el-button>
      <el-button @click="resetForm">取 消</el-button>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: {
    editData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        amountName: '',
        amountShort: '',
      },
      rules: {
        amountName: [
          { required: true, message: '请输入', trigger: 'blur' },
          { type: 'number', message: '必须为数字值' },
        ],
      },
    }
  },
  watch: {
    editData: {
      handler() {
        if (this.editData) {
          this.form = {
            ...this.editData,
            amountName: Number(this.editData.amountName),
            amountShort:
              this.editData.amountShort === '' ||
              this.editData.amountShort === null
                ? ''
                : Number(this.editData.amountShort),
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (
            this.form.amountShort !== '' &&
            Number(this.form.amountName) >= Number(this.form.amountShort)
          ) {
            this.$message.warning('结束金额应大于起始金额！')
            return
          }
          this.$emit('saveSet', this.form)
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.form.amountShort = ''
      this.$emit('cancelSet')
    },
    changeNum(val, tag) {
      val == '' ? (this.form[tag] = '') : (this.form[tag] = _.round(val, 6))
    },
  },
}
</script>