var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"custom-dialog",attrs:{"title":"拆分采购","width":"900px","visible":_vm.modelVisible,"before-close":_vm.close},on:{"update:visible":function($event){_vm.modelVisible=$event}}},[(_vm.isSplit)?_c('div',[_c('p',{staticClass:"mb5"},[_vm._v("产品BOM单：")]),_c('el-form',{ref:"ruleForm",staticClass:"container",attrs:{"model":_vm.form}},[_c('el-table',{staticClass:"input-table",attrs:{"data":_vm.form.bomProductDTOList,"border":"","cell-style":{ textAlign: 'center' },"header-cell-style":{ textAlign: 'center' }}},[_c('el-table-column',{attrs:{"label":"产品编码","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.skuId || scope.row.sku || scope.row.accessoriesNumber)+" ")]}}],null,false,950678165)}),_c('el-table-column',{attrs:{"label":"产品图片","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"img-wrap"},[_c('el-badge',{directives:[{name:"show",rawName:"v-show",value:(
                  scope.row.imgMain && scope.row.imgMain.split(',').length > 1
                ),expression:"\n                  scope.row.imgMain && scope.row.imgMain.split(',').length > 1\n                "}],attrs:{"value":scope.row.imgMain ? scope.row.imgMain.split(',').length : ''}}),(scope.row.imgMain)?_c('el-image',{staticClass:"c_pointer",attrs:{"src":scope.row.imgMain.split(',')[0]},on:{"click":function($event){_vm.handlePreview(scope.row.imgMain.split(','))}}}):_c('span',[_vm._v("--")])],1)]}}],null,false,177004586)}),_c('el-table-column',{attrs:{"label":"产品名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"ell"},[_vm._v(_vm._s(scope.row.nameCn))])]}}],null,false,2446297912)}),_c('el-table-column',{attrs:{"label":"产品类型","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.sonProductType === '1' ? '辅料' : scope.row.standardCustomized)+" ")]}}],null,false,3269766035)}),_c('el-table-column',{attrs:{"label":"组合数量","width":"120","prop":"numCombination","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'bomProductDTOList.' + scope.$index + '.numCombination',"rules":[
                {
                  required: true,
                  message: '组合数量必填',
                  trigger: 'change',
                },
                {
                  pattern: /^[1-9]\d*$/,
                  message: '请输入>0的整数',
                  trigger: 'change',
                } ]}},[_c('el-input',{attrs:{"maxlength":"20","disabled":true,"placeholder":"请输入","clearable":""},model:{value:(_vm.form.bomProductDTOList[scope.$index].numCombination),callback:function ($$v) {_vm.$set(_vm.form.bomProductDTOList[scope.$index], "numCombination", $$v)},expression:"form.bomProductDTOList[scope.$index].numCombination"}})],1)]}}],null,false,699557344)}),_c('el-table-column',{attrs:{"label":"印刷方式","width":"150","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'bomProductDTOList.' + scope.$index + '.printingWay',"rules":[
                {
                  required: true,
                  message: '请选择印刷方式',
                  trigger: 'change',
                } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.form.bomProductDTOList[scope.$index].printingWay),callback:function ($$v) {_vm.$set(_vm.form.bomProductDTOList[scope.$index], "printingWay", $$v)},expression:"form.bomProductDTOList[scope.$index].printingWay"}},_vm._l((_vm.printingWayList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}],null,false,4265688713)})],1)],1),_c('div',{staticClass:"flex tips mt10"},[_c('div',[_vm._v("Tips:")]),_c('div',{staticClass:"ml5"},[_c('p',[_vm._v("确定后，产品会根据BOM单自动拆分采购需求。")]),_c('p',[_vm._v(" 若BOM单不满足采购需求，请点击 "),_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.createBom(_vm.form.bomProductDTOList)}}},[_vm._v(" 修改 ")])],1)])]),_c('div',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.loading ? '加载中' : '确认')+" ")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")])],1)],1):_c('div',[_c('div',{staticClass:"empty"},[_c('p',[_vm._v(" 无BOM单， "),_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.createBom(null)}}},[_vm._v("去创建>>")])],1)])]),(_vm.showViewer)?_c('el-image-viewer',{attrs:{"on-close":_vm.closeViewer,"url-list":_vm.imgUrl}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }