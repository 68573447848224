<template>
  <el-dialog
    :visible.sync="params.show"
    title="出库库位"
    width="500px"
    append-to-body
    @close="onClose"
    modal-append-to-body
  >
    <el-table :data="locs" border striped>
      <el-table-column
        align="cernter"
        prop="locationNumber"
        label="库位号"
      ></el-table-column>
      <el-table-column
        align="cernter"
        prop="num"
        label="当前数量"
      ></el-table-column>
      <el-table-column align="cernter" prop="outNum" label="出库数量">
        <template slot-scope="scope">
          <el-input-number
            :precision="0"
            :max="scope.row.num"
            :min="0"
            :disabled="params.disabled"
            v-model.number="scope.row.outNum"
            placeholder="输入出库数量"
          ></el-input-number>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-tabs v-model="activeName">
      <el-tab-pane label="标准库" name="1">
        <div>
          <div class="loc-wrapper">
            <el-row
              v-for="(item, index) in locs"
              :key="index"
              class="loc-item mt20"
            >
              <el-col :span="6">
                <span>库位号:</span>
                <span>{{ item.locationNumber }}</span>
              </el-col>
              <el-col :span="10">
                <span>出库数量:</span>
                <el-input-number
                  class="ml15"
                  :precision="0"
                  :max="item.num"
                  :min="0"
                  :disabled="params.disabled"
                  v-model.number="item.outNum"
                  placeholder="输入出库数量"
                ></el-input-number>
              </el-col>
              <el-col :span="6">
                <span class="ml15">
                  剩余: {{ item.num - (item.outNum ? item.outNum : 0) }}
                </span>
              </el-col>
            </el-row>
          </div>
          <p class="mt20">已选出库数量：{{ totolCounts }}</p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="备用品库" name="2" v-if="this.storeType === 5">
        <el-row style="display: flex; align-items: center">
          <el-col :span="8">
            <span>库位号:</span>
            <span class="ml15">备用品库位</span>
          </el-col>
          <el-col :span="10">
            <span>出库数量:</span>
            <span>
              <el-input-number
                :max="params.giftCount"
                :min="0"
                :precision="0"
                class="ml15"
                v-model="giftCount"
                placeholder="请输入"
              ></el-input-number>
            </span>
            <p class="error-p" v-if="isNaN(giftCount)">不能为空</p>
          </el-col>
          <el-col :span="6" class="ml15">
            <span>剩余:</span>
            <span class="ml15">
              {{ params.giftCount - (isNaN(giftCount) ? 0 : giftCount) }}
            </span>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs> -->
    <p class="mt20">总数量：{{ totolCounts }}</p>
    <div class="footer-wrapper mt30 t_a_c" v-if="params.disabled !== true">
      <el-button type="primary" class="ml30" @click="sureAction">
        保存
      </el-button>
      <el-button @click="cancelAction">取 消</el-button>
    </div>
    <!-- 查询表单 -->
  </el-dialog>
</template>

<script>
import { InboundSheet } from '@/core'
export default {
  name: 'locForOut',
  props: {
    params: {
      type: Object,
      default: () => {},
    },

    warehouseId: [String],
    warehouseOutNum: [Number],
  },
  data() {
    return {
      activeName: '1',
      giftCount: '',
    }
  },

  computed: {
    totolCounts() {
      const arr = this.locs.map((i) => i.outNum).filter((i) => i)

      if (arr.length === 0) {
        return 0
      } else {
        return arr.reduce((prev, next) => prev + next)
      }
    },

    totalNum() {
      const arr = this.locs.map((i) => i.num)
      if (arr.length === 0) {
        return 0
      } else {
        return arr.reduce((prev, next) => prev + next)
      }
    },
    locs() {
      if (this.params && this.params.locs) {
        return this.params.locs
      } else {
        return []
      }
    },
  },
  created() {},

  methods: {
    handleClick(tab, event) {},
    addNewLoc() {
      this.locs.push({
        locationNumber: '',
        num: '',
        currentNum: 0,
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 获取产品列表
    getProducts() {},
    async onSearch(row) {
      const { locationNumber } = row
      if (locationNumber || locationNumber === 0) {
        let res = await InboundSheet.getWarehouseLocationNum({
          locationNumber,
          warehouseId: this.warehouseId,
        })
        row.currentNum = res.data
      } else {
        this.$message.warning('请输入库位号')
      }
    },
    onReset() {
      this.param = ''
      this.onSearch()
    },
    cancelAction() {
      this.onClose()
    },
    sureAction() {
      const { totolCounts } = this
      if (totolCounts === 0) {
        return this.$message.warning(`已选出库数量只能为正整数`)
      }
      if (this.params.isConfirm) {
        if (totolCounts !== this.params.lotNumber) {
          return this.$message.warning('出库数量不等于批次出库数量')
        }
      }

      if (totolCounts !== this.params.lotNumber) {
        return this.$message.warning('出库数量不等于批次出库数量')
      }

      // if (totolCounts > this.warehouseOutNum) {
      //   return this.$message.warning('已选出库数量不能大于实际出库数量')
      // }

      // if (isNaN(this.giftCount)) {
      //   return
      // }
      // 过滤出库数量为0的库位
      const locs = this.params.locs.filter(
        (item) => item.outNum && item.outNum !== 0
      )
      this.$emit('set-loc', locs, this.totolCounts, this.giftCount)
      this.$emit('close')
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.footer {
  .el-button + .el-button {
    margin-left: 30px;
  }
}
.ml15 {
  margin-left: 15px;
}
.loc-item {
  display: flex;
  align-items: center;
}
.mt20 {
  margin-top: 20px;
}
.pointer {
  cursor: pointer;
}

.error-p {
  position: absolute;
  left: 249px;
  top: 40px;
  color: #ff4d4f;
  font-size: 12px;
}

::v-deep .loc-wrapper .el-input-number--small {
  width: 100px !important;
}

::v-deep .el-input-number--small .el-input__inner {
  padding: 0 10px !important;
}
::v-deep .el-input-number--small .el-input-number__increase,
::v-deep .el-input-number--small .el-input-number__decrease {
  display: none !important;
}
</style>
