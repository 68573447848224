<template>
  <div class="supplier-setting">
    <el-row>
      <el-button icon="el-icon-plus" type="primary" @click="addClick">
        新增
      </el-button>
    </el-row>
    <el-row class="mt20">
      <el-card shadow="always" v-for="(item, index) of tableData" :key="index">
        <p>{{ item.label }}</p>
      </el-card>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'SppplierSettingType',
  props: {},
  data() {
    return {
      tableData: [
        { id: '001', label: '工厂', value: '工厂' },
        { id: '002', label: '贸易商', value: '贸易商' },
        { id: '003', label: '走私商', value: '走私商' },
        { id: '004', label: '小作坊', value: '小作坊' },
        { id: '005', label: '贸易商', value: '贸易商' },
        { id: '006', label: '贸易商', value: '贸易商' },
      ],
    }
  },
  components: {},
  filters: {},
  watch: {},
  computed() {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>
