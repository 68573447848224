<template>
  <div class="normal-wrapper">
    <p>设置角色对应的他人权限</p>
    <el-table :data="hisRoleTemplates" border>
      <el-table-column
        align="center"
        label="应用名称"
        prop="menuName"
        width="200px"
      ></el-table-column>
      <el-table-column align="center" label="允许查看范围" min-width="50px">
        <template slot-scope="scope">
          <div style="text-align: left">
            <el-radio-group
              v-model="scope.row.authType"
              @change="(value) => onchange(value, scope.row, scope.$index)"
            >
              <el-radio
                :label="item.type"
                v-for="(item, i) in authTypes"
                :key="i"
              >
                {{ item.name }}
                <p
                  v-if="item.type === 3 && scope.row.authType === 3"
                  class="my-p"
                >
                  (已选
                  <span>{{ scope.row.count }}</span>
                  部门)
                  <span class="edit" @click="editDeparts(scope.row)">编辑</span>
                </p>
                <p
                  v-if="item.type === 4 && scope.row.authType === 4"
                  class="my-p"
                >
                  (已选
                  <span>{{ scope.row.count }}</span>
                  人)
                  <span
                    class="edit"
                    @click="editUsers(scope.row, scope.$index)"
                  >
                    编辑
                  </span>
                </p>
              </el-radio>
            </el-radio-group>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="查看他人权限" align="center" min-width="70px">
        <template slot-scope="scope">
          <el-row>
            <el-col
              :span="12"
              v-for="(item, index) in scope.row.authTemplateChildVOList"
              :key="index"
              style="text-align: left"
            >
              <el-checkbox
                v-if="item.authOperation === 1"
                v-model="item.auth"
                :true-label="1"
                :false-label="0"
                :disabled="![1, 2, 3, 4].includes(scope.row.authType)"
              >
                <span>{{ item.authName }}</span>
              </el-checkbox>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="操作他人权限" align="center" min-width="70px">
        <template slot-scope="scope">
          <el-row>
            <el-col
              :span="12"
              v-for="(item, index) in scope.row.authTemplateChildVOList"
              :key="index"
              style="text-align: left"
            >
              <el-checkbox
                v-if="item.authOperation === 2"
                v-model="item.auth"
                :true-label="1"
                :false-label="0"
                :disabled="![1, 2, 3, 4].includes(scope.row.authType)"
              >
                <span>{{ item.authName }}</span>
              </el-checkbox>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="mt20" style="text-align: left" v-if="superUser !== 1">
      <el-button
        type="primary"
        :loading="loading"
        @click="save"
        v-allowed="['Power:Edit']"
      >
        保存
      </el-button>
    </el-row>
    <!-- 部门选择 -->

    <DepartmentChoose
      ref="DepartmentChoose"
      @choose-department="chooseDepartment"
    />

    <!-- 人员选择 -->
    <UserChoose2 ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import {
    getAuthDetail,
    addHis,
    updateHis,
    getAuthTemplate,
  } from '@/api/power-group'
  import DepartmentChoose from '@/components/departmentChoose'
  import UserChoose2 from '@/components/userChoose2'
  export default {
    name: 'his-role',
    props: {
      roleId: [String],
      superUser: {
        //超级管理员
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        hisRoleTemplates: [],
        tableData: [],
        time: {},
        authTypes: [
          {
            name: '全部',
            type: 1,
          },
          {
            name: '本部门',
            type: 2,
          },
          {
            name: '指定部门',
            type: 3,
          },
          {
            name: '指定人员',
            type: 4,
          },
          {
            name: '无',
            type: 0,
          },
        ],
        currentRow: '',
      }
    },
    components: {
      DepartmentChoose,
      UserChoose2,
    },
    watch: {
      roleId(val) {
        this.getDetailByRoleId()
      },
    },
    created() {
      //获取他人权限数据
      this.getAuthTemplate()
    },

    methods: {
      //获取他人权限数据
      getAuthTemplate() {
        getAuthTemplate().then((res) => {
          if (res && res.code === '000000') {
            this.hisRoleTemplates = res.data.map((item) => {
              item.authType = 0
              item.authValue = ''
              if (item.authTemplateChildVOList) {
                item.authTemplateChildVOList = item.authTemplateChildVOList.map(
                  (subItem) => {
                    subItem.auth = 0
                    return subItem
                  }
                )
              }
              return item
            })
            this.originTemplate = JSON.parse(
              JSON.stringify(this.hisRoleTemplates)
            )
          }
        })
      },

      getCounts(val) {
        var count
        if (val) {
          count = val.split(',').length
        }
        if (!this.currentRow.count) {
          this.$set(this.currentRow, 'count', count)
        } else {
          this.currentRow.count = count
        }
      },
      $_setCurrentRow(row) {
        this.currentRow = row
      },
      chooseDepartment(val) {
        if (val.length === 0) {
          return
        }
        this.currentRow.authValue = val.map((item) => item.id).join(',')
        this.getCounts(this.currentRow.authValue)
      },
      chooseUser(val) {
        if (val.length === 0) {
          return
        }

        this.currentRow.authValue = val.map((item) => item.id).join(',')
        this.getCounts(this.currentRow.authValue)
      },
      //部门选择
      editDeparts(row) {
        this.$_setCurrentRow(row)
        this.$refs['DepartmentChoose'].showAddEdit(row.authValue.split(','))
      },
      //指定人员选择
      editUsers(row, index) {
        this.$_setCurrentRow(row)
        this.$refs['UserChoose'].showAddEdit(
          'hisRole',
          row.authValue.split(',')
        )
      },
      //指定部门或指定人员非空校验
      checkBeforeSubmit() {
        for (let i = 0, l = this.hisRoleTemplates.length; i < l; i++) {
          let item = this.hisRoleTemplates[i]
          var checkResult = true
          if ([3, 4].includes(item.authType)) {
            //指定部门或指定人员
            if (item.authValue.length === 0) {
              let msgPart = item.authType === 3 ? '指定部门' : '指定人员'
              this.$message.warning(
                `应用名称${item.menuName}${msgPart}不能为空`
              )
              checkResult = false
              break
            }
          }
        }
        return checkResult
      },
      onchange(value, row, index) {
        this.$set(this.hisRoleTemplates[index], 'count', 0)
        this.$set(this.hisRoleTemplates[index], 'authValue', '')
        if (value === 0) {
          row.authTemplateChildVOList.forEach((item) => {
            item.auth = 0
          })
        }
      },
      $_setChecked(list) {
        // this.hisRoleTemplates = this.originTemplate
        this.resetHisTemplate()

        if (list.length === 0) {
          return
        }

        list.forEach((item) => {
          let targetItem = this.hisRoleTemplates.find(
            (template) => template.menuType === item.menuType
          )
          if (targetItem) {
            // 设置允许查看范围选中
            targetItem.authType = item.authType
            targetItem.systemRoleOtherId = item.systemRoleOtherId
            targetItem.authValue = item.authValue
            // 设置count
            if (item.authValue.length > 0) {
              this.$set(targetItem, 'count', item.authValue.split(',').length)
            }
            // 设置查看他人权限选中
            if (item.authType !== 0) {
              item.systemOtherAuthVOList.forEach((subItem) => {
                let subTarget = targetItem.authTemplateChildVOList.find(
                  (tp) => tp.authName === subItem.authName
                )
                if (subTarget) {
                  subTarget.auth = subItem.auth
                  // subTarget.systemOtherAuthId = item.systemOtherAuthId
                  // subTarget.systemOtherAuthId = item.systemOtherAuthVOList.find(
                  //   (t) => t.authName === subTarget.authName
                  // ).systemOtherAuthId
                }
              })
            }
            // 设置systemOtherAuthId
            targetItem.authTemplateChildVOList.forEach((_item) => {
              let _t = item.systemOtherAuthVOList.find(
                (j) =>
                  j.authType === _item.authType &&
                  j.authOperation === _item.authOperation
              )
              if (_t && _t.systemOtherAuthId) {
                _item.systemOtherAuthId = _t.systemOtherAuthId
              } else {
                _item.systemOtherAuthId = ''
              }
            })
          }
        })
      },

      getDetailByRoleId() {
        getAuthDetail(this.roleId).then((res) => {
          if (res && res.code === '000000') {
            if (Array.isArray(res.data) && res.data.length === 0) {
              this.submitType = 'add'
              //
              this.hisRoleTemplates.forEach((template) => {
                template.systemRoleOtherId = ''
              })
            } else {
              this.submitType = 'edit'
            }
            // this.hisRoleTemplates.forEach((temp) => {
            //   let authTemplateChildVOList = temp.
            // })

            this.$_setChecked(res.data)
          }
        })
      },

      resetHisTemplate() {
        this.hisRoleTemplates.forEach((item) => {
          item.authType = 0
          item.authTemplateChildVOList.forEach((subItem) => (subItem.auth = 0))
        })
      },

      //保存
      save() {
        if (!this.checkBeforeSubmit()) {
          return
        }
        let deepCopyTemplates = JSON.parse(
          JSON.stringify(this.hisRoleTemplates)
        )
        //  && template.authType !== 0
        let requestData = deepCopyTemplates
          .filter((template) => template.authType !== '')
          .map((item) => {
            // item.systemOtherAuthDTOList = item.authTemplateChildVOList.filter(
            //   (j) => j.auth === 1
            // )
            item.systemOtherAuthDTOList = item.authTemplateChildVOList
            item.roleId = this.roleId
            delete item.authTemplateChildVOList
            return item
          })
        let method = this.submitType === 'add' ? addHis : updateHis
        this.loading = true
        method(requestData).then((res) => {
          this.loading = false
          if (res && res.code === '000000') {
            this.$message.success('保存成功')
            this.getDetailByRoleId()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .normal-wrapper {
    height: 100%;
    .el-table {
      margin-top: 15px;
      height: calc(100% - 100px);
      overflow: auto;
    }
  }
  .el-radio {
    display: list-item;
    list-style: none;
    margin: 20px 0px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .my-p {
    display: inline-block;
    margin-left: 20px;
    color: rgb(96, 98, 102);
    & .edit {
      color: rgb(24, 144, 255);
    }
  }
</style>
