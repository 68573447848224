<template>
  <el-dialog
    :title="title"
    :append-to-body="true"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="中文名称" prop="nameCn">
        <el-input v-model="form.nameCn" placeholder="输入中文名称" />
      </el-form-item>
      <el-form-item prop="nameEn">
        <template slot="label">
          <i style="color: #ff4d4f">*</i>
          英文名称
        </template>
        <el-input
          v-model="form.nameEn"
          v-inptRules
          placeholder="输入英文名称"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/product/productSetting'
  export default {
    name: 'ProPublicModel',
    data() {
      return {
        loading: false,
        title: '设置',
        form: {
          nameCn: '',
          nameEn: '',
          id: '',
          type: '',
          value: '',
        },
        rules: {
          // nameCn: [
          //   { required: true, trigger: 'blur', message: '请输入中文名称' },
          // ],
          nameEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //展示弹框
      publickModel(str, row) {
        if (str === 'packing') {
          this.title = '包装方式设置'
          this.form.type = 3
        } else if (str === 'material') {
          this.title = '产品材质设置'
          this.form.type = 4
        } else if (str === 'print') {
          this.title = '印刷位设置'
          this.form.type = 5
        }
        if (!row) {
        } else {
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.dialogFormVisible = false
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
      },
      // 保存
      async save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.nameEn === '') {
              return this.$baseMessage(
                '请输入英文名称',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            let response
            this.loading = true
            if (this.form.id === '') {
              response = await insertPO(this.form)
              this.loading = false
              if ((response.code = '000000')) {
                this.$baseMessage(
                  '操作成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              response = await updatePO(this.form)
              this.loading = false
              if ((response.code = '000000')) {
                this.$baseMessage(
                  '操作成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }

            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
