<!--
  功能：结算模板配置-新增
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月23日 09:18:39
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="table-container pro-container">
    <el-page-header @back="goBack()" content="结算模板配置"></el-page-header>
    <div class="wrapper">
      <div class="form-wrapper">
        <el-form
          label-position="left"
          :model="form"
          :rules="rules"
          :inline="true"
          label-width="80"
          ref="form"
        >
          <el-form-item label="模板名称" prop="name">
            <el-input
              v-model="form.name"
              clearable
              placeholder="请输入"
              size="medium"
            ></el-input>
          </el-form-item>

          <el-form-item prop="type">
            <span name="label">
              计算类型&nbsp;&nbsp;
              <el-tooltip content="选择不同类型，展示不同模板" placement="top">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
            <el-select
              :value="form.type"
              size="medium"
              :disabled="isEdit"
              @change="onFormTypeChange"
              placeholder="请选择"
            >
              <el-option label="固定" :value="1"></el-option>
              <el-option label="阶梯" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="统计方式" v-if="form.type == 2" prop="mode">
            <el-select
              v-model="form.mode"
              clearable
              size="medium"
              placeholder="请选择"
              style="width: 180px"
            >
              <el-option label="自然季" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <span class="info-p" v-if="form.type == 2">
            自然季 (1-3月 4-6月 7-9月 10-12月)
          </span>
          <el-form-item
            label="固定提成点(%)"
            v-if="form.type == 1"
            prop="fixedRatio"
            class="fixedRatio"
          >
            <p class="tip-p" v-if="form.fixedRatio > 50">
              当前录入的提成点较高，请核对，是否有误
            </p>
            <el-input-number
              :controls="false"
              :min="0"
              :max="100"
              :precision="2"
              v-model="form.fixedRatio"
              clearable
              placeholder="请输入"
              size="medium"
              style="width: 175px"
            ></el-input-number>
          </el-form-item>
          <el-button
            type="primary"
            @click="saveTemp"
            v-if="form.type == 1 && !isEdit"
          >
            暂存下方
          </el-button>
        </el-form>
      </div>
      <div class="content-wrapper">
        <Ladder
          v-if="form.type == 2"
          ref="ladder"
          @ladder-save="ladderSave"
          @ladder-edit="editLadder"
        />
        <Fixed v-if="form.type == 1 && !isEdit" ref="fixed" />
      </div>
    </div>

    <div class="btn-wrapper">
      <el-button @click="goBack">返回</el-button>
      <el-button type="primary" @click="saveTpl">保存</el-button>
    </div>
  </div>
</template>

<script>
  import Ladder from './settlement-tpl-config-ladder.vue'
  import Fixed from './settlement-tpl-config-fixed.vue'
  import { TplConfigInteractor } from '@/core/interactors/finance/config-manage'

  import { mapGetters } from 'vuex'
  export default {
    name: 'SettlementTplConfigInteractorLadder',
    props: {},
    // 组件状态值
    data() {
      return {
        form: {
          type: 1, //计算类型 （1：固定，2阶梯）
          name: '', //模板名称
          mode: 1, //	统计方式（1：自然季），固定提成时为0
          fixedRatio: undefined, //	固定提成比例，阶梯时为null
        },
        rules: {
          name: [
            {
              required: true,
              message: '请输入模板名称',
              trigger: ['blur'],
            },
            {
              min: 2,
              max: 12,
              message: '请输入2-12个文字以内的名称',
              trigger: 'blur',
            },
          ],
          type: [
            {
              required: true,
              message: '请选择计算类型',
              trigger: ['blur', 'change'],
            },
          ],
          fixedRatio: [
            { required: true, message: '请输入固定比例', trigger: 'blur' },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      tenantId() {
        return this.userInfo.tenantId
      },
      isEdit() {
        return !!this.$route.query.bid
      },
    },
    components: {
      Ladder, //阶梯
      Fixed, //固定
    },

    created() {
      this.doEdit()
    },

    methods: {
      // 获取列表数据
      _getList() {
        const { type } = this.form
        if (type == 1) {
          return this.$refs.fixed.list || []
        }
        return this.$refs.ladder.form.tableData || []
      },

      async doEdit() {
        if (this.isEdit) {
          const res = await TplConfigInteractor.templateGetDetail(this.$route.query.bid)
          if (res && res.code === '000000') {
            const detail = res.data
            // 保存业务id，编辑保存时用
            this.configCommissionTemplateId = detail.configCommissionTemplateId
            this.templateNumber = detail.templateNumber
            Reflect.ownKeys(this.form).forEach((item) => {
              this.form[item] = detail[item]
            })
            if (this.form.type === 2) {
              setTimeout(() => {
                this.$refs.ladder.form.tableData = detail.tiereds.map(item => {
                  const amounts = ['beginAmount', 'endAmount']
                  amounts.forEach(field=> {
                    if(!Number.isNaN(item[field]) && item[field] !== null) {
                      item[field] = item[field].toFixed(3)
                    }
                  })
                  return item
                })
              }, 400)
            }
          }
        }
      },
      _back() {
        this.$router.push({
          name: 'ConfigManage',
          query: {
            tab: '3',
          },
        })
      },

      onFormTypeChange(val) {
        const list = this._getList()
        if (list.length > 0) {
          return this.$confirm('内容没有保存,切换后将清空数据,是否切换页面', '提示', {
            type: 'warning',
            showCancelButton: true,
          })
            .then(() => {
              this.form.type = val
            })
            .catch(() => {})
        }
         this.form.type = val
      },
      // 返回
      goBack() {
        if (this.isEdit) {
          return this._back()
        }
        const list = this._getList()
        if (!this.isSaved && list.length > 0) {
          return this.$confirm('返回后,填写的数据不会保存', '提示', {
            type: 'warning',
            showCancelButton: true,
          })
            .then(() => {
              this._back()
            })
            .catch(() => {})
        }
        return this._back()
      },

      //暂存
      saveTemp() {
        this.$refs.form.validate((flag) => {
          if (flag) {
            this.$refs.fixed.saveTemp({
              ...this.form,
            })
            this.form.name = ''
            this.form.fixedRatio = undefined
          }
        })
      },

      // 保存
      saveTpl() {
        if (!this.isEdit && this.form.type == 1) {
          return this.saveFixed()
        }
        return this.$refs.form.validate((result) => {
          if (result) {
            if (this.form.type == 1) {
              this.editFixed()
            } else {
              this.saveLadder()
            }
          }
        })
      },

      // 固定提成点保存
      async saveFixed() {
        const configCommissionTemplateDTOs = this.$refs.fixed.list
          .slice()
          .map((item) => {
            item.mode = 0
            item.type = 1
            return item
          })
        if (configCommissionTemplateDTOs.length === 0) {
          return this.$message.warning('请输入数据')
        }
        const res = await TplConfigInteractor.templateInsertPOs(
          configCommissionTemplateDTOs
        )
        if (res && res.code === '000000') {
          this.isSaved = true
          this.$message.success('保存成功')
          this.goBack()
        }
      },

      // 固定提成点编辑保存
      async editFixed() {
        const dto = {
          configCommissionTemplateId: this.configCommissionTemplateId,
          templateNumber: this.templateNumber,
          mode: 0,
          type: 1,
          fixedRatio: this.form.fixedRatio,
           name: this.form.name,
        }
        const res = await TplConfigInteractor.templateUpdatePOs(dto)
        if (res && res.code === '000000') {
          this.$message.success('保存成功')
          this.goBack()
        }
      },

     

      saveLadder() {
        this.$refs.ladder.save()
      },

      async ladderSave(list) {
        const obj = {
          fixedRatio: 0,
          mode: 1,
          type: 2,
          name: this.form.name,
          tenantId: this.tenantId,
          tieredDTOS: list.map((item) => {
            item.tenantId = this.tenantId
            return item
          }),
        }

        const res = await TplConfigInteractor.templateInsertPOs([obj])
        if (res && res.code === '000000') {
          this.$message.success('保存成功')
          this.isSaved = true
          this.goBack()
        }
      },

      async editLadder(list) {
        const obj = {
          fixedRatio: 0,
          mode: 1,
          type: 2,
          name: this.form.name,
          tenantId: this.tenantId,
          configCommissionTemplateId: this.configCommissionTemplateId,
          templateNumber: this.templateNumber,
          tieredDTOS: list.map((item) => {
            item.tenantId = this.tenantId
            return item
          }),
        }

        const res = await TplConfigInteractor.templateUpdatePOs(obj)
        if (res && res.code === '000000') {
          this.$message.success('保存成功')
          this.goBack()
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .table-container .wrapper {
    // max-width: 800px;
    margin: 0 auto;
    overflow-y: hidden !important;
  }

  .btn-wrapper {
    position: fixed;
    width: 100%;
    bottom: 15px;
    height: 40px;
    text-align: center;
    .el-button {
      margin: 0 8px;
    }
  }
  .el-icon-question {
    position: absolute;

    top: -1px;
    left: 51px;
  }
  .tip-p {
    position: absolute;
    left: -103px;
    color: red;
    bottom: -25px;
  }

  .info-p {
    position: absolute;
    top: 83px;
    font-size: 12px;
  }
  .form-wrapper {
    // padding-top: 35px;
  }
</style>
