<template>
  <el-dialog title="批量导入" class="import-dialog" :visible.sync="dialogVisible" width="520px">
    <div class="import-inner">
      <dl class="row">
        <dt>1、</dt>
        <dd>
          <el-link type="primary" @click="downLoad">
            点击下载批量导入模板
          </el-link>
          <p class="desc">下载Excel模板，按照表格内提示填写信息</p>
        </dd>
      </dl>
      <dl class="row">
        <dt>2、</dt>
        <dd>
          <el-upload action="" class="upload-btn" :http-request="() => {}" :show-file-list="false"
            :before-upload="beforeUpload">
            <el-button type="primary" size="medium">选择xls/xlsx文件</el-button>
          </el-upload>
          <div class="wait-upload" v-if="fileName">
            <span class="label">待上传文件:</span>
            <span class="name">{{ fileName }}</span>
            <i class="el-icon-close icon-close" @click="remove"></i>
          </div>
          <p class="desc">按模板格式填写完信息后点击此处上传xls或xlsx文件</p>
        </dd>
      </dl>
      <div class="tips">
        注意：
        <br />
        文件后缀名必须为：xls或xlsx（即Excel格式），文件大小不得大于10M
        <br />
        必填项不允许为空，一旦为空将会导致导入失败
        <br />
        其他信息若不填写请复制模板第一行内的默认数据
        <br />
        模板中的表头名称不可更改，表头行不能删除
        <br />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <!-- <el-button type="primary" @click="upload" :loading="loading">
        立即导入
      </el-button> -->
      <ErpLoadingButton type="primary" @click="upload">
        立即导入
      </ErpLoadingButton>
      <el-button @click="dialogVisible = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { StoreCheck } from '@/core'
  export default {
    data() {
      return {
        dialogVisible: false,
        file: null,
        fileName: '',
        loading: false,
      }
    },
    props: {
      stockTakingId: {
        default: '',
        type: String,
      },
    },
    watch: {
      dialogVisible(val) {
        if (val) {
          this.file = null
          this.fileName = ''
        } else {
          this.loading = false
        }
      },
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
      }),
    },
    methods: {
      show() {
        this.dialogVisible = true
      },
      beforeUpload(file) {
        let suffix = file.name.substr(file.name.lastIndexOf('.'))
        const isExcel = ['.xls', '.xlsx'].includes(suffix)
        const isLt10M = file.size / 1024 / 1024 < 10

        if (!isExcel) {
          this.$message.error('上传文件只支持xls或xlsx格式!')
        }
        if (!isLt10M) {
          this.$message.error('上传文件大小不能超过 10MB!')
        }
        if (isExcel && isLt10M) {
          this.file = file
          this.fileName = file.name
        }
        return isExcel && isLt10M
      },
      remove() {
        this.fileName = ''
        this.file = null
      },
      async upload(e, done) {
        // 参数
        if (this.file) {
          let formData = new FormData()
          formData.append('file', this.file)
          formData.append('stockTakingId', this.stockTakingId)

          let res = await StoreCheck.takingImport(formData)

          if (res?.code == '000000') {
            this.$emit('importSuccess', res.data.stockTakingProducts)
            this.$message.success('导入成功！')
            this.dialogVisible = false
            done()
          } else {
            this.$alert('提示', {
              confirmButtonText: '确定',
              message: `<div style="max-height:200px; overflow:auto">${res?.desc}</div>`,
              dangerouslyUseHTMLString: true,
              type: 'error',
              'customClass': 'error-alert'
            });
          }
          done()
        } else {
          this.$message.warning('请选择上传文件！')
          done()
        }
      },
      async downLoad() {
        const res = await StoreCheck.stockTakingImport()
        const reader = new FileReader()
        reader.readAsDataURL(res)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '盘点结果模板'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .import-dialog {
    .row {
      display: flex;
      margin-bottom: 20px;

      dt {
        font-size: 20px;
        color: #666;
      }

      dd {
        flex: 1;
      }

      .el-link {
        font-size: 16px;
        line-height: 32px;
      }

      .wait-upload {
        display: flex;
        max-width: 100%;
        align-items: center;
        margin-top: 10px;

        .label {
          margin-right: 10px;
        }

        .name {
          font-size: 14px;
          color: #333;
        }

        .icon-close {
          color: #ccc;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          margin-left: 10px;

          &:hover {
            color: #1890ff;
          }
        }
      }

      .desc {
        font-size: 14px;
        color: #999;
        line-height: 24px;
        margin-top: 4px;
      }
    }

    .tips {
      font-size: 14px;
      color: #999;
      line-height: 24px;
    }

    .dialog-footer {
      text-align: center;
    }
  }
</style>
<style>
  .error-alert {
    min-width: 610px;
  }
</style>
