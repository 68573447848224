<template>
  <el-dialog
    title="编辑"
    :visible.sync="isEdit"
    :close-on-click-modal="false"
    :before-close="Cancel"
    width="1200px"
  >
    <div class="offer-dialog">
      <div class="container">
        <el-form :model="form" ref="ruleForm">
          <!-- 条件配置 -->
          <el-form-item
            v-for="(item, i) in conditionArray"
            :key="i"
            class="condition-item"
          >
            <el-select v-model="item.code" placeholder="请选择">
              <el-option
                v-for="item in isMan ? optionsMan : optionsArea"
                :key="item.code"
                :label="item.message"
                :value="item.code"
                :disabled="conditionArray.some((i) => i.code === item.code)"
              ></el-option>
            </el-select>
            <span class="icon">
              {{ conditionArray.length === i + 1 ? '=' : '+' }}
            </span>
          </el-form-item>

          <!-- 结果配置 -->
          <el-form-item style="margin: 0">
            <el-input v-model="form.result" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="text-center mt20">
        <el-button @click="Cancel">返回</el-button>
        <el-button type="primary" @click="Save">保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { FinanceConfigSetting } from '@/core/interactors/finance/config-manage'
export default {
  props: ['isEdit', 'editRow', 'areasCode', 'conditionOptions'],
  // 组件状态值
  data() {
    return {
      form: {
        condition: '', // 条件
        result: '', // 结果
        configConditionDTO: [], // 暂存数据
      },
      conditionArray: [], // 条件数据
      optionsMan: [], // 人员配置
      optionsArea: [], // 地区配置
      isMan: false, // 是否为角色
    }
  },
  watch: {
    isEdit(val) {
      if (val) {
        this.disposeData()
      }
    },
  },
  created() {},
  methods: {
    // 数据处理
    disposeData() {
      this.conditionArray = JSON.parse(this.editRow.conditionResult)
      this.form.result = JSON.parse(this.editRow.result)[0].message
      // 赋值角色字典
      this.optionsMan = this.conditionOptions.settlementRoleVO
      // 赋值地区字典
      this.optionsArea = this.conditionOptions.settlementAreaVO
      // 判断下拉框为哪种类型字典
      if (this.areasCode.includes(this.conditionArray[0].code)) {
        this.isMan = false
      } else {
        this.isMan = true
      }
    },

    // 返回
    Cancel() {
      this.$emit('Cancel')
    },

    // 保存
    async Save() {
      let [arr, conditionResult, options] = [
        [],
        [],
        this.isMan ? this.optionsMan : this.optionsArea,
      ]
      this.conditionArray.forEach((item) => arr.push(item.code))
      for (let i = 0; i < arr.length; i++) {
        options.find((item) =>
          item.code === arr[i] ? conditionResult.push(item) : ''
        )
      }

      let res = await FinanceConfigSetting.conditionUpdate(
        conditionResult,
        this.editRow,
        this.isMan
      )
      if (res.code === '000000') {
        this.$message.success('保存成功')
        this.$emit('Edit')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  overflow-x: auto;
  .el-form {
    width: 100%;
    display: flex;
    justify-content: center;
    .el-form-item {
      margin-right: 30px;
    }
  }
}
::v-deep {
  .condition-item {
    position: relative;
    .icon {
      position: absolute;
      top: 0;
      right: -22px;
      font-size: 24px;
    }
  }
}
</style>
