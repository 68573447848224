import _ from 'lodash'
const mixins = {
  data() {
    return {
      spanArr: [],
      pos: 0,
      spanObj: {}
    }
  },
  methods: {
    getSpanArr(list, index) {
      this.$set(this.spanObj, `arr_${index}`, [])
      this.$set(this.spanObj, `pos_${index}`, 0)

      this.spanObj[`arr_${index}`] = []
      for (let i = 0; i < list?.length; i++) {
        if (i === 0) {
          this.spanObj[`arr_${index}`].push(1)
          this.spanObj[`pos_${index}`] = 0

        } else {
          if (list[i].pricingValue == list[i - 1].pricingValue) {
            this.spanObj[`arr_${index}`][this.spanObj[`pos_${index}`]] += 1
            this.spanObj[`arr_${index}`].push(0)
          } else {
            this.spanObj[`pos_${index}`] = i
            this.spanObj[`arr_${index}`].push(1)
          }
        }
      }

    },
    // 单元格合并
    spanMethod({ row, column, rowIndex, columnIndex }, index) {

      if (
        column.property === 'pricingValue' || column.property === 'printPosition'
      ) {
        const _row = this.spanObj[`arr_${index}`][rowIndex]
        // const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },

    // 产品换算单位
    getProSizeUnitText(val) {
      if (val === 'FT' || val === 'CM') {
        return 'IN'
      } else {
        return 'CM'
      }
    },

    // 产品装箱尺寸换算 type 1：产品尺寸。2：印刷区域尺寸
    getProSizeUnit(row, val, unit, type) {
      let rate = 0
      switch (unit) {
        case 'FT':
          rate = 12
          break
        case 'CM':
          rate = 0.3937
          break
        case 'IN':
          rate = 2.54
          break
      }

      if (type === 1) {
        return (row[`product${val}Cm`] * rate || 0).toFixed(2)
      } else {
        return (row[`print${val}`] * rate || 0).toFixed(2)
      }
    },
  },
}

export default mixins
