<template>
  <el-dialog
    title="客户收货地址运费波动关键词提醒"
    :visible.sync="shippingAddressVisible"
    width="550px"
    :before-close="cancel"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="关键词" prop="amountName">
        <el-input
          placeholder="请输入"
          v-model="form.amountName"
          type="textarea"
          :rows="3"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item style="text-align: center">
        <!-- <el-button type="primary" @click="submit" :loading="loading">
          确认
        </el-button> -->
        <ErpLoadingButton type="primary" @click="submit">
          确认
        </ErpLoadingButton>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    shippingAddressVisible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        amountName: '',
      },
      rules: {
        amountName: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    }
  },
  methods: {
    submit(e, done) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('saveShipping', { ...this.form, amountName: this.form.amountName.trim() }, done)
        } else {
          done()
        }
      })
    },
    cancel() {
      this.form = this.$options.data().form
      this.$refs.form.resetFields()
      this.$emit('update:shippingAddressVisible', false)
    },
    // 编辑展示详情
    getDetail(row) {
      this.form = { ...row }
    },
  },
}
</script>