<template>
  <div class="stock-check p10">
    <el-row class="check-search" type="flex" justify="space-between">
      <div>
        <el-button size="small" type="primary" @click="
            () => {
              $router.push('/stock-center/stock-check/add')
            }
          ">
          新增
        </el-button>
        <el-input size="small" placeholder="盘点单号" v-model="searchForm.stockTakingCode" clearable
          style="width: 200px; margin: 0 10px"></el-input>
        <el-button size="small" @click="init">搜索</el-button>
        <!-- <el-button size="medium">高级筛选</el-button> -->
        <ListHigherSearch class="ml10 mr10" @highSearch="highSearch" />
        <el-button size="small" @click="listExport">导出</el-button>
      </div>
      <div>
        <el-popover ref="showOption" popper-class="custom-table-checkbox" trigger="hover">
          <erp-draggable v-bind="dragOptions" :list="columns">
            <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
              :disabled="item.checkable === false" :label="item.label">
              {{ item.label }}
            </el-checkbox>
          </erp-draggable>
          <!-- 保存自定义显示列 -->
          <div class="save-btn">
            <el-button type="primary" class="ml10" size="mini" plain @click="operateSave">
              保存
            </el-button>
          </div>
          <template #reference>
            <el-tooltip effect="dark" content="列表可拖动排序" placement="top-start">
              <el-button plain type="primary" class="pub-h-36">
                自定义显示
              </el-button>
            </el-tooltip>
          </template>
        </el-popover>
      </div>
    </el-row>
    <el-row type="flex" class="mt15">
      <el-radio-group v-model="searchForm.status" @change="changeStatus">
        <el-radio v-for="(item, index) in statusList" :key="index" :label="item.value">
          {{ item.label }}
        </el-radio>
      </el-radio-group>
    </el-row>
    <el-row class="mt20 main-height">
      <ErpTable :page-number.sync="pageNo" :page-size.sync="pageLe" :table-data="tableData" :total="total"
        @query="query" class="table-fixed" @selection-change="setSelectRows" :key="customTableKey" tooltip-effect="dark"
        :extraHeight="40">
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="item.label" show-overflow-tooltip
          :min-width="120" :resizable="false" align="center" :prop="item.prop" :width="item.width">
          <template #default="{ row }">
            <!-- 盘点单号 -->
            <div v-if="item.prop === 'stockTakingCode'">
              <span class="page-link" @click="showDetail(row)">
                {{ row.stockTakingCode }}
              </span>
            </div>
            <div v-else-if="item.prop === 'takingRange'">
              {{ row.takingRange === 1 ? '全部盘点' : '部分盘点' }}
            </div>
            <!-- 盘点人 -->
            <div v-else-if="item.prop === 'takingUser'">
              <span v-if="row.status == 1 && hasRight('TAKINGUSER')" class="page-link" @click="setChecker(row)">
                {{ row.takingUser || '请设置' }}
              </span>
              <span v-else>{{ row.takingUser || '--' }}</span>
            </div>
            <!-- 状态 -->
            <div v-else-if="item.prop === 'status'">
              <erp-mouseover-tip v-if="row.handleFlag == 2 && row.handleMsg">
                <template name="content">
                  <div>{{ row.handleMsg || '--' }}</div>
                </template>
              </erp-mouseover-tip>
              <el-tag :type="row.statusType" @click="showReviewProgress(row)">
                {{ row.statusDesc }}
              </el-tag>

            </div>
            <!-- 盘点结果 -->
            <div v-else-if="item.prop === 'takingResult'">
              <el-tag v-if="row.takingResult" :type="row.resultType">
                {{ row.resultDesc }}
              </el-tag>
              <span v-else>--</span>
            </div>

            <div v-else>{{ row[item.prop] || '--' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="150" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top-start">
              <el-button type="primary" plain icon="iconfont icon-chakan" circle size="mini"
                @click="showDetail(scope.row)"></el-button>
            </el-tooltip>

            <el-dropdown style="margin-left: 15px">
              <el-button type="primary" plain icon="el-icon-more" circle
                :disabled="[3, 6].includes(scope.row.status)"></el-button>
              <el-dropdown-menu slot="dropdown" placement="bottom">
                <el-dropdown-item v-if="scope.row.status == 1">
                  <p @click="checkResult(scope.row, 'result')">盘点结果</p>
                </el-dropdown-item>
                <el-dropdown-item v-if="[1, 4, 5].includes(scope.row.status)">
                  <p @click="cancelCheck(scope.row)">取消盘点</p>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.status == 2">
                  <p @click="resetProcess(scope.row)">撤销审核</p>
                </el-dropdown-item>
                <el-dropdown-item v-if="[5, 4].includes(scope.row.status)">
                  <p @click="checkResult(scope.row, 'edit')">编辑</p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </ErpTable>
    </el-row>
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    <el-drawer title="审核进度" :visible.sync="statusDrawer">
      <Audit ref="Audit" @cancelClick="statusDrawer = false" :auditObjectId="currentAuditObjectId" />
    </el-drawer>
    <!-- 导出任务创建成功提示 -->
    <SuccessModel :visible.sync="downloadVisible" />
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'
  import { StoreCheck, InboundSheet } from '@/core'
  import CustomCache from '@/utils/custom-cache'
  import { hasRight } from '@/utils/permissionBtn'
  import { statusList, columns, result } from './help/data.js'
  import ListHigherSearch from './components/listHigherSearch'
  import UserChoose from '@/components/userChoose'
  import ErpDraggable from 'vuedraggable'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import SuccessModel from '@/components/tableImport/successModel'
  export default {
    name: 'stock-check',
    components: {
      ListHigherSearch,
      UserChoose,
      ErpDraggable,
      Audit,
      SuccessModel,
    },
    data() {
      return {
        statusList: statusList,
        result: result,
        columns: columns,

        searchForm: {
          stockTakingCode: '',
          status: null,
        },
        radio: 0,
        pageNo: 0,
        pageLe: 10,
        tableData: [],
        total: 0,
        customTableKey: Math.random(),
        userCustomizeColumnId: '',
        setTakUserTakingId: '',
        selectRows: [],
        currentAuditObjectId: '', // 审核id
        statusDrawer: false, // 审核侧滑
        downloadVisible: false, //导出成功提示框
      }
    },
    created() {
      this.init()

      //获取自定义保存
      CustomCache.getColumns(16, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
    },
    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.init()
        }
      },
    },
    filters: {},
    methods: {
      hasRight(val) {
        return hasRight(val)
      },
      init() {
        this.pageNo = 1
        this.pageLe = 10
        this.query()
      },
      // 高级筛选
      highSearch(form) {
        this.searchForm = {
          ...this.searchForm,
          ...form,
        }
        this.pageNo = 1
        this.query()
      },
      async query() {
        let dt = {
          ...this.searchForm,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
        }
        let res = await StoreCheck.stockTakingListPage(dt)
        this.tableData = res.data?.data?.map((it) => {
          return {
            ...it,
            statusDesc: this.getStatusType(it.status).label,
            statusType: this.getStatusType(it.status).type,
            resultDesc: this.getResultType(it.takingResult)?.label,
            resultType: this.getResultType(it.takingResult)?.type,
          }
        })
        this.total = res?.data?.total
      },
      // 选择状态
      changeStatus() {
        this.pageNo = 1
        this.query()
      },
      // 不同的状态显示不同标签
      getStatusType(status) {
        return this.statusList.find((it) => {
          return it.value == status
        })
      },
      // 不同的盘点结果显示不同标签
      getResultType(result) {
        return this.result.find((it) => {
          return it.value == result
        })
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      // 取消盘点
      cancelCheck(row) {
        this.$confirm('是否确认取消盘点？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let res = await StoreCheck.cancelTaking(row.stockTakingId)
          if (res?.code === '000000') {
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
            this.init()
          }
        })
      },
      // 撤销审核
      resetProcess(row) {
        this.$confirm('是否确定撤销审核？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const res = await InboundSheet.getAuditObject({
            orderId: row.stockTakingId,
            auditType: 21,
            tenantId: this.userInfo.tenantId,
          })
          if (res && res.code === '000000') {
            // 获取提交审核（撤销所用）auditObjectId
            const auditObjectId = res.data.auditObjectId
            const auditStatus = 4
            const auditType = 21
            const { tenantId, userId, userName } = this.userInfo
            const res_1 = await InboundSheet.cancel({
              auditObjectId,
              auditStatus,
              auditType,
              tenantId,
              userId,
              userName,
              remarks: '',
            })

            if (res_1?.code === '000000') {
              // this.$message.success('撤销成功')
              this.init()
            }
          }

          this.$message({
            type: 'success',
            message: '操作成功!',
          })
        })
      },
      // 设置盘点人
      setChecker(row) {
        this.setTakUserTakingId = row.stockTakingId
        this.$refs.UserChoose.showAddEdit('1', '', true, 0)
      },
      // 盘点人
      async chooseUser(val) {
        let res = await StoreCheck.updateTakingUser(
          val[0],
          this.setTakUserTakingId
        )
        if (res?.code === '000000') {
          this.$message.success('设置成功')
          this.setTakUserTakingId = ''
          this.init()
        }
      },
      // 导出
      async listExport() {
        if (this.selectRows.length === 0) {
          this.$message.warning('请选择需要导出的数据！')
          return
        }

        let list = this.selectRows.map((it) => it.stockTakingId)
        let res = await StoreCheck.exportStockTaking(list)
        if (res && res.code === '000000') {
          this.downloadVisible = true
        }
      },
      // 盘点结果
      checkResult(row, type) {
        this.$router.push({
          path: '/stock-center/stock-check/edit',
          query: {
            type,
            stockTakingId: row.stockTakingId,
          },
        })
      },
      // 查看详情
      showDetail(row) {
        this.$router.push({
          path: '/stock-center/stock-check/detail',
          query: {
            stockTakingId: row.stockTakingId,
            // showType: row.status == 1 || row.status == 6 ? 'id' : 'sku',
            showType: 'id',
          },
        })
      },

      //自定义保存
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 16,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res?.code === '000000') {
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
      // 查看审核进度
      async showReviewProgress({ status, stockTakingId }) {
        let map = [
          1, // '审核中',
          6, //'已驳回',
        ]
        if (map.includes(status)) {
          return
        }

        const res = await InboundSheet.getAuditObject({
          orderId: stockTakingId,
          auditType: 21,
          tenantId: this.userInfo.tenantId,
        })

        if (res && res.code === '000000') {
          // 获取提交审核（撤销所用）auditObjectId
          if (!res.data || !res.data.auditObjectId) {
            this.$message.warning('未获取到审核对象')
          } else {
            this.currentAuditObjectId = res.data.auditObjectId
            this.statusDrawer = true
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .stock-check {
    box-sizing: border-box;
  }

  //自定义显示
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .el-tooltip__popper {
    max-width: 400px;
  }
</style>