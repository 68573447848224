<template>
  <IndexCard
    title="入库异常"
    :tabs="tabs"
    @tab-click="handleTabClick"
    :loading="loading"
    :singleNum="singleNum"
    :teamNum="teamNum"
  >
    <div v-if="list && list.length">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="collection-notice-item">
          <div class="content" @click="dispose(item)" :style="itemStyleObj">
            <div class="mails-item--top">
              采购订单
              <span class="mail-code" :style="boldStyle.bold">
                {{ item.number }}
              </span>
              申请的入库数量异常
            </div>
            <div class="mails-item--bottom">
              <p>
                <span class="label">采购开发:</span>
                <span class="content">{{ item.buyerName }}</span>
              </p>
              <p>
                <span>{{ item.updateTime }}</span>
              </p>
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>

    <!-- 入库异常 -->
    <enter-cargo-error
      ref="enterCargoError"
      @fetch-data="getList"
    ></enter-cargo-error>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import { HomeInteractor } from '@/core'
  import enterCargoError from '@/views/purchase/purchaseOrder/components/enterCargoError.vue'
  export default {
    name: 'InboundUnusual',
    components: { enterCargoError },
    mixins: [mixins],
    data() {
      return {
        list: [],
        tabs: true,
        show: false,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.loading = true
        const requestData = {
          authFlag: this.active === 'ME' ? 0 : 1,
          pageLe: 99999,
          pageNo: 1,
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
        }
        HomeInteractor.listHomePurchaseWarehouseInAbnormal(requestData).then(
          (res) => {
            if (res && res.code === '000000') {
              const { ownTotal, teamTotal } = res.data || {}
              this.list = res.data.dataList.data || {}
              this.singleNum = ownTotal || 0
              this.teamNum = teamTotal || 0
            } else {
              this.list = []
            }
            this.loading = false
          }
        )
      },
      //入库异常点击
      dispose(item) {
        if (this.active === 'GROUP') return
        let arr = []
        let wArr = []
        this.list.forEach((listLtem) => {
          if (listLtem.warehouseInNumber === item.warehouseInNumber) {
            arr.push(listLtem)
            wArr.push(listLtem.warehouseInNumber)
          }
        })
        this.$refs.enterCargoError.isHome = true
        this.$refs.enterCargoError.number = '< ' + item.number + ' >'
        this.$refs.enterCargoError.warehouseInNumber =
          '< ' + wArr.join(',') + ' >'
        // 赋值采购订单和入库单
        this.$refs.enterCargoError.initLoading(arr)
      },

      //关闭
      onClose() {
        this.fileList = []
        this.show = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .collection-notice-item {
    font-size: 14px;
    .mails-item--top {
      margin-bottom: 6px;
      color: #000;
      .mail-code {
        font-family: Semibold;
      }
      .sender {
        font-family: Regular;
      }
      .topic {
        font-family: Semibold;
      }
    }
    .mails-item--bottom {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      p {
        color: #5e5e5e;
        font-family: Regular;
        .label {
          margin-right: 5px;
        }
      }
    }
  }
  .erpdialog-detail-style {
    .el-row {
      label {
        color: #666;
      }
      p {
        color: #000;
        font-size: 16px;
        white-space: nowrap;
        margin-top: 5px;
      }
    }
    .link {
      color: #46a6ff;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .green-bold {
    color: green;
    font-weight: bold;
  }
  .no-text {
    color: #999 !important;
  }
</style>
