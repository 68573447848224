<template>
  <div style="margin: 0 10px">
    <el-button v-if="showBtn" @click="actionModel">
      {{ param.btnName }}
    </el-button>
    <el-dropdown v-else @command="actionType">
      <el-button class="el-dropdown-link">
        {{ param.btnName }}
      </el-button>
      <el-dropdown-menu v-if="param.isCredit" slot="dropdown">
        <el-dropdown-item command="SHOU_KUAN_HAS_ORDER">
          有销售订单
        </el-dropdown-item>
        <p class="split-div"></p>
        <el-dropdown-item command="SHOU_KUAN_NO_ORDER">
          无销售订单
        </el-dropdown-item>
      </el-dropdown-menu>
      <el-dropdown-menu v-else slot="dropdown">
        <el-dropdown-item command="AUTO">自动分摊</el-dropdown-item>
        <p class="split-div"></p>
        <el-dropdown-item command="MANUAL">手动调整</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
      title="导入"
      :visible.sync="visible"
      width="450px"
      :before-close="handleClose"
    >
      <div v-if="!success" class="dialog-box">
        <p>
          <span class="mr10">按照模板格式录入数据</span>
          <el-link type="primary" @click="downLoad">下载导入模板</el-link>
        </p>
        <el-upload
          ref="elUpload"
          class="upload-demo mt10"
          drag
          action=""
          :limit="1"
          :multiple="false"
          :before-upload="beforeUpload"
          :http-request="() => {}"
          :file-list="fileList"
          :on-exceed="handleExceed"
          :on-remove="handleRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
        </el-upload>
        <div class="mt10">
          <p>注意：</p>
          <p>1、上传xls或xlsx文件不得大于10M</p>
          <p>2、模版中的表头不可更改或删除</p>
          <!-- <p>3、单次导入数据不得超过5000行</p> -->
        </div>
      </div>
      <!-- 导入成功 -->
      <div v-if="success">
        <el-result icon="success">
          <div slot="subTitle">
            <p>导入任务创建成功</p>
            <p class="mt5">请在任务中心查看导入进度</p>
          </div>
        </el-result>
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="!success">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" :loading="loading" @click="startTask">
            开始导入
          </el-button>
        </div>
        <!-- 导入成功 -->
        <div v-if="success">
          <el-button type="primary" @click="viewTask">立即查看</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { ShippingPlanInteractor } from '@/core/interactors/shipment/shipment-plan'
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import { OrderListInteractor } from '@/core/interactors/order/order-list'
  import { CreditManageInteractor } from '@/core'
  import { systemInteractor } from '@/core'
  import { mapGetters, mapActions, mapState } from 'vuex'

  export default {
    name: 'TableImport',
    props: {
      // type 导入类型 btnName 按钮名称
      param: {
        type: Object,
        default: () => {},
      },
      // 上传文件数量
      limit: {
        type: Number,
        default: 1,
      },
      // 上传文件类型
      fileType: {
        type: String,
        default: 'xlsx,xls',
      },
      // 上传文件大小 单位M
      fileSize: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        visible: false,
        success: false, // 导入成功后展示弹窗
        fileList: [], // 上传文件
        importType: '',
        uploadFile: null,
        loading: false,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      ...mapState({
        taskVisible: (state) => state.task.taskVisible,
      }),

      // 下载模板接口
      // eslint-disable-next-line vue/return-in-computed-property
      downloadInteractor() {
        // 导入运费
        if (this.param.type == 'ship') {
          return this.importType === 'AUTO'
            ? ShippingPlanInteractor.autoTemplate
            : ShippingPlanInteractor.manualTemplate
        }
        // 费用管理 导入excel
        if (this.param.type == 'cost') {
          return CostManageInteractor.financeDownload
        }
        // 询盘导入模板
        if (this.param.type == 'inquiry') {
          return OrderListInteractor.enquiryDownload
        }
        // 收款导入
        if (this.param.type == 'credit') {
          return this.importType === 'SHOU_KUAN_HAS_ORDER' // 有销售订单
            ? CreditManageInteractor.hasPIReceiptTemplate
            : CreditManageInteractor.noPIReceiptTemplate
        }
      },
      // 按钮类型
      showBtn() {
        return ['cost', 'inquiry'].includes(this.param.type)
      },
      // 下载文件名称
      // eslint-disable-next-line vue/return-in-computed-property
      downloadName() {
        // 导入运费
        if (this.param.type == 'ship') {
          return this.importType === 'AUTO'
            ? '自动分摊导入单'
            : '手动调整导入单'
        }
        // 费用管理
        if (this.param.type == 'cost') {
          return '费用导入单'
        }
        // 费用管理
        if (this.param.type == 'inquiry') {
          return 'enquiry'
        }
        // 收款导入
        if (this.param.type == 'credit') {
          return this.importType === 'SHOU_KUAN_HAS_ORDER' // 有销售订单
            ? '有销售订单导入单'
            : '无销售订单导入单'
        }
      },
      // 导入接口
      // eslint-disable-next-line vue/return-in-computed-property
      upLoadInteractor() {
        // 费用导入
        if (this.param.type == 'cost') {
          return CostManageInteractor.financeUpload
        }
        // 运费导入
        if (this.param.type == 'ship') {
          return systemInteractor.shipImportCheck
        }
        // 询盘倒入
        if (this.param.type == 'inquiry') {
          return OrderListInteractor.enquiryImport
        }
        if (this.param.type == 'credit') {
          return this.importType === 'SHOU_KUAN_HAS_ORDER'
            ? CreditManageInteractor.importReceiptWithOrder
            : CreditManageInteractor.importReceiptWithoutOrder
        }
      },
      // 导入前校验接口 20240704 目前只有收款管理导入需要check，有返回值，其他场景返回undefined
      // eslint-disable-next-line vue/return-in-computed-property
      checkInteractor() {
        // 收款管理
        if (this.param.type == 'credit') {
          return this.importType === 'SHOU_KUAN_HAS_ORDER' // 有销售订单
            ? CreditManageInteractor.importReceiptWithOrderCheck
            : CreditManageInteractor.importReceiptWithoutOrderCheck
        }
      },
    },
    watch: {
      taskVisible(val) {
        if (val) {
          // 显示任务中心列表时，关闭弹窗
          this.visible = false
          this.success = false
        }
      },
    },

    methods: {
      ...mapActions({
        setViewTask: 'task/setViewTask',
      }),
      handleExceed(file, fileList) {
        this.beforeUpload(file && file[0])
        this.fileList = [...file]
      },
      actionType(val) {
        this.importType = val
        this.visible = true
      },
      actionModel() {
        this.visible = true
      },
      handleClose() {
        this.visible = false
        this.success = false
        this.fileList = []
        this.uploadFile = null
      },
      // 开始导入
      async startTask() {
        if (!this.uploadFile) {
          return this.$message.warning(
            this.lang === 'en' ? 'Please select File' : '请选择上传文件'
          )
        }
        if (this.uploadFile) {
          this.loading = true
          let formData = new FormData()
          let params = null
          formData.append('file', this.uploadFile)

          if (this.param.type == 'cost') {
            params = formData
          } else {
            formData.append('importFlag', this.importType === 'AUTO' ? 1 : 2)
            params = formData
          }
          const sendRequest = async () => {
            let res = await this.upLoadInteractor(params)
            if (res && res.code === '000000') {
              this.success = true
              this.fileList = []
              this.uploadFile = null
            }
            this.loading = false
          }
          this.verifyBeforeSend(sendRequest, params)
        }
      },
      // 导入前校验
      async verifyBeforeSend(sendRequest, params) {
        // 20240704 目前只有收款管理导入需要check，
        if (this.checkInteractor) {
          try {
            const { code, data } = await this.checkInteractor(params)
            if (code === '000000') {
              if (data?.messages?.length) {
                // 如果数组有值，显示弹框提示，点击确认可以继续提交
                let msg = ''
                data.messages.forEach((item, index) => {
                  msg += `${index + 1}. ${item}\n`
                })
                this.$confirm(
                  msg,
                  '付款方、收款方式和票据单号组合在系统中已存在',
                  {
                    confirmButtonText: '仍要导入',
                    cancelButtonText: '取消导入',
                    customClass: 'import-tip',
                  }
                ).then(() => {
                  sendRequest()
                })
              } else {
                // 数组是空的，直接提交
                sendRequest()
              }
            }
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        } else {
          // 其他场景没有check，直接提交
          sendRequest()
        }
      },
      // 显示任务中心弹窗
      viewTask() {
        this.setViewTask(true)
        this.visible = false
        this.fileList = []
        this.success = false
        this.uploadFile = null
      },

      // 文件上传之前
      async beforeUpload(file) {
        // 文件格式校验暂不处理
        const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1)
        if (!this.fileType.includes(suffixName.toLowerCase())) {
          this.$message.error(`上传文件只支持xls或xlsx格式!`)

          // 除去上传失败的文件
          const errFileIndex = this.$refs.elUpload.uploadFiles.findIndex(
            (item) => (item.uid = file.file.uid)
          )
          if (errFileIndex != -1) {
            this.$refs.elUpload.uploadFiles.splice(errFileIndex, 1)
          }

          return false
        }
        if (file.size > 1024 * 1024 * this.fileSize) {
          this.$message.error(`上传文件大小不能超过20M!`)
          return false
        }
        this.uploadFile = file
      },

      // 移除上传文件
      handleRemove(file, fileList) {
        this.uploadFile = null
      },

      // 下载导入模板
      downLoad() {
        this.downloadInteractor().then((res) => {
          const reader = new FileReader()
          reader.readAsDataURL(res)
          reader.onload = (e) => {
            const a = document.createElement('a')
            // 下载文件名称
            a.download = this.downloadName
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dialog-box {
    box-sizing: border-box;
    padding: 0 20px;
  }
</style>
<style lang="scss">
  .el-message-box.import-tip {
    width: 500px;
    .el-message-box__message p {
      word-break: break-all; // 单词可以截断
      white-space: pre-line; // 识别 \n
      max-height: 450px;
      overflow: auto;
    }
  }
</style>
