<template>
  <el-row :gutter="20" class="pro-container pro-base-msg" v-if="isSingleBol">
    <el-row>
      <el-col :span="2">
        <!-- 规格设置 -->
        <h6 class="left-h6">{{ $t('productDetail.SpecificationSetting') }}</h6>
      </el-col>
      <el-col :span="22" class="right-content">
        <el-row type="flex" class="align_center">
          <!-- 选择规格： -->
          <span>{{ $t('productDetail.SelectASpecification') }}：</span>
          <!-- 请选择规格 -->
          <el-select
            v-model="specName"
            :placeholder="$t('placeholder.M2013')"
            class="ml10"
            @change="specSelChange"
          >
            <el-option
              v-for="(item, index) of specData"
              :key="index"
              :label="item.nameCn"
              :value="item"
              :disabled="item.disabled"
            />
          </el-select>
          <!-- Tip：请先选择产品类别，且规格最多配置两个 -->
          <p class="f_s_12 ml15">Tip：{{ $t('productDetail.Tips') }}</p>
        </el-row>
        <!-- 规格名称值展示 -->
        <el-table :data="tableData" stripe border class="mt30 w100">
          <!-- 序号 -->
          <el-table-column
            align="center"
            type="index"
            width="55"
            :label="$t('productDetail.SerialNumber')"
          />
          <!-- 规格名称 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.SpecificationName')"
            width="150"
            prop="nameCn"
          />
          <!-- 规格值(可拖动排序) -->
          <el-table-column
            align="center"
            :label="$t('productDetail.SpecificationValue')"
          >
            <template slot-scope="scope" inline-template>
              <el-row type="flex" align="middle" class="pr20 draggable-row">
                <erp-draggable
                  v-bind="dragOptions"
                  :list="scope.row.value"
                  @end="endClick"
                  style="width: 75%"
                >
                  <el-tag
                    class="c_pointer ml10"
                    v-for="(tag, index) in scope.row.value"
                    :key="index"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(scope.row, tag, scope.$index)"
                  >
                    {{ tag }}
                  </el-tag>
                </erp-draggable>
                <el-row
                  type="flex"
                  align="middle"
                  style="flex: 1"
                  class="saveTagInput-row"
                >
                  <el-input
                    v-if="currentEditing && currentEditing === scope.row"
                    ref="saveTagInput"
                    v-model="inputValue"
                    v-focus
                    class="input-new-tag w_100 ml10"
                    @input="inputValueblur"
                    @blur="handleInputConfirm(scope.row, scope.$index)"
                  />
                  <!-- 添加规格值 -->
                  <el-button
                    class="button-new-tag ml10"
                    @click="showInput(scope.row)"
                  >
                    {{ $t('other.addSpec') }}
                  </el-button>
                  <!-- 一键清空 -->
                  <span
                    v-if="scope.row.value.length"
                    class="f_s_12 ml10 c_pointer"
                    style="color: #1890ff; white-space: nowrap; margin-top: 3px"
                    @click="specDelClick1(scope.row, scope.$index)"
                  >
                    {{ $t('other.oneClear') }}
                  </span>
                </el-row>
              </el-row>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.Operate')"
            show-overflow-tooltip
            width="100"
            fixed="right"
          >
            <template slot-scope="scope">
              <!-- <el-button
                type="text"
                @click="specDelClick(scope.row, scope.$index)"
              >
                删除
              </el-button> -->
              <el-button
                type="danger"
                plain
                icon="el-icon-delete"
                circle
                size="mini"
                @click="specDelClick(scope.row, scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-row class="mt10 f_s_12">Tip：规格值可拖动排序</el-row> -->
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import ErpDraggable from 'vuedraggable'
import { listSpecByCategoryId } from '@/api/product/catesManager'
export default {
  name: 'ProductSpecSet',
  // props: {
  //   editData: {
  //     type: Object,
  //     required: false,
  //   },
  // },
  components: { ErpDraggable },
  data() {
    return {
      categoryId: '', //产品分类id
      specData: [], //产品规格
      specName: '', //选择的规格名称
      specValue: [], //选择的规格值
      tableData: [], //规格table
      currentEditing: null, //规格input
      inputValue: '', //规格input的model
      specsType: '', //
      specsValue: '',
      stopWatch: false, //停止监听
      isSingleBol: true, //单规格false和多规格判断true,用来判断验证,默认单规格
      editData: [],
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 600,
        group: 'description',
      }
    },
  },
  created() {
    // 产品分类id
    this.$Bus.$on('categoryId', (id) => {
      if (id) {
        this.categoryId = id
        if (this.categoryId) {
          this.getListSpecByCategoryId()
        } else {
          this.specName = ''
          this.specData = []
          this.tableData = []
          this.stopWatch = true
          this.$forceUpdate()
        }
      }
    })
    //开启单规格多规格
    this.$Bus.$on('specsChange', (bol, str) => {
      this.isSingleBol = bol
      this.specsType = ''
      this.specsValue = ''
      this.specData = []
      if (str !== 'edit') {
        this.tableData = []
      }
      if (this.categoryId) {
        this.getListSpecByCategoryId()
      }
    })
  },
  mounted() {},
  methods: {
    editShow(editData) {
      if (editData) {
        this.editData = editData
        if (!this.stopWatch) {
          if (this.editData.categoryId) {
            //根据产品分类id获取规格下拉
            this.categoryId =
              this.editData.categoryId.split(',')[
                this.editData.categoryId.split(',').length - 1
              ]
            if (this.categoryId && this.categoryId.length) {
              this.getListSpecByCategoryId()
            }
          }
          // 是否多规格
          if (this.editData.specsType === '') {
            this.isSingleBol = false
          } else {
            this.isSingleBol = true
          }
          //规格转换
          if (this.editData.specsType && this.tableData.length === 0) {
            let specsType = this.editData.specsType.split(',')
            let specsValue = JSON.parse(
              this.editData.specsValue.replace(/\'/g, '"')
            )
            this.tableData = []
            specsType.forEach((item, index) => {
              let obj = {
                disabled: true,
                nameCn: item,
                value: specsValue[index],
              }
              this.tableData.push(obj)
            })
            setTimeout(() => {
              this.$Bus.$emit('specSendBusEdit', this.tableData)
            }, 100)
          }
        }
      }
    },

    //规格值首字母大写
    inputValueblur(val) {
      if (!val) return
      let reg = new RegExp('^[\u4E00-\u9FA5]*$')
      if (reg.test(val)) {
        return (this.inputValue = '')
      }
      if (!reg.test(val)) {
        this.inputValue = val
          .replace(/[\u4E00-\u9FA5]/g, '')
          .replace(/\b\w+\b/g, (word) => {
            return word.substring(0, 1).toUpperCase() + word.substring(1)
          })
      }
    },

    // 根据分类id获取产品关联的规格
    async getListSpecByCategoryId() {
      const self = this
      // 旧规格
      self.prevSpecDataNames = this.specData
        .map((spec) => spec.nameCn)
        .join(',')
      let response = await listSpecByCategoryId({
        productCategoryId: this.categoryId,
      })
      if ((response.code = '000000')) {
        self.specData = response.data
        const specDataNames = this.specData.map((spec) => spec.nameCn).join(',')
        if (
          self.prevSpecDataNames !== specDataNames &&
          self.prevSpecDataNames
        ) {
          this.tableData = []
          this.specName = ''
          this.$forceUpdate()
          setTimeout(() => {
            this.$Bus.$emit('show-clear')
          }, 100)
        }

        this.specData.forEach((item) => {
          item.value = item.value.split(',')
          item.disabled = false
        })

        this.tableData.forEach((item) => {
          this.specData.forEach((item2) => {
            if (item.nameCn === item2.nameCn) {
              item2.disabled = true
            }
          })
        })
      }
    },
    // 产品规格选择
    specSelChange(val) {
      if (this.tableData.length >= 2) {
        this.specName = ''
        return this.$baseMessage(
          // '产品规格最多配置两个',
          this.$t('reqmsg.M2026'),
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      this.specData.find((item, index) => {
        if (item.nameCn === val.nameCn) {
          item.disabled = true
        }
      })
      this.tableData.push(val)
      this.specName = ''
      setTimeout(() => {
        this.$Bus.$emit('specSendBus', this.tableData)
      }, 100)
    },
    //规格单个tag删除
    handleClose(row, tag) {
      row.value.splice(row.value.indexOf(tag), 1)
      setTimeout(() => {
        this.$Bus.$emit('specSendBus', this.tableData)
      }, 100)
    },
    showInput(row) {
      this.currentEditing = row
    },
    // 规格input确认
    handleInputConfirm(row, index) {
      if (row.value.includes(this.inputValue)) {
        this.inputValue = ''
        return this.$baseMessage(
          // '规格值重复',
          this.$t('reqmsg.M2027'),
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      if (this.inputValue.indexOf("'") > -1) {
        this.$baseMessage(
          // '请先选择规格',
          this.$t('productDetail.SpecialSymbolsAreNotAllowed'),
          'error',
          false,
          'erp-hey-message-error'
        )
        return
      }

      if (this.inputValue) {
        this.tableData[index].value.push(this.inputValue)
        this.inputValue = ''
        this.currentEditing = null
        setTimeout(() => {
          this.$Bus.$emit('specSendBus', this.tableData)
        }, 100)
      }
    },
    //拖拽结束之后
    endClick() {
      this.$Bus.$emit('specSendBus', this.tableData)
    },
    //一键清空
    specDelClick1(row) {
      this.tableData.find((item, index) => {
        if (row.nameCn === item.nameCn) {
          this.$nextTick(function () {
            this.tableData[index].value = []
          })
        }
      })
      setTimeout(() => {
        this.$Bus.$emit('specSendBus', this.tableData)
      }, 100)
      this.getListSpecByCategoryId()
    },
    // 规格整行删除
    specDelClick(row) {
      this.specData.find((item, index) => {
        if (item.nameCn === row.nameCn) {
          item.disabled = false
        }
      })
      this.tableData.find((item, index) => {
        if (row.nameCn === item.nameCn) {
          this.$nextTick(function () {
            this.tableData.splice(index, 1)
          })
        }
      })
      setTimeout(() => {
        this.$Bus.$emit('specSendBus', this.tableData)
      }, 100)
      //this.getListSpecByCategoryId()
    },

    //验证
    validateField() {
      let specsType = []
      let specsValue = []
      this.tableData.forEach((item, index) => {
        specsType.push(item.nameCn)
        specsValue.push(item.value)
      })
      specsType = specsType.join(',')
      specsValue = JSON.stringify(specsValue).replace(/\"/g, "'")

      if (!this.isSingleBol) {
        specsType = ''
        specsValue = ''
      }
      if (specsType === '' && this.isSingleBol) {
        this.$baseMessage(
          // '请先选择规格',
          this.$t('reqmsg.M2028'),
          'error',
          false,
          'erp-hey-message-error'
        )
        return {
            success: false,
          }
      } else {
        return {
            success: true,
            specsType,
            specsValue,
          }
      }
    },
    //保存到本地
    validateField2() {
      let specsType = []
      let specsValue = []
      this.tableData.forEach((item, index) => {
        specsType.push(item.nameCn)
        specsValue.push(item.value)
      })
      specsType = specsType.join(',')
      specsValue = JSON.stringify(specsValue).replace(/\"/g, "'")
      if (!this.isSingleBol) {
        specsType = ''
        specsValue = ''
      }
      return {
        specsType,
        specsValue,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }
  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }
  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
  }
}

.draggable-row {
  > div {
    text-align: left;
  }
}
::v-deep {
  .el-tag {
    margin-top: 5px;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .saveTagInput-row {
    .el-input--small .el-input__inner {
      height: 32px !important;
    }
  }
}
</style>
