<template>
  <el-dialog
    :visible.sync="params.show"
    top="5vh"
    title="选择产品"
    width="880px"
    append-to-body
    @close="onClose"
    @opened="onOpened"
    modal-append-to-body
  >
    <!-- 查询表单 -->
    <div>
      <!-- 表单 -->
      <el-tabs v-model="activeName" @tab-click="onTabClick">
        <el-tab-pane label="可用库存" name="USE">
          <el-form ref="form">
            <el-form-item>
              <el-input
                placeholder="搜索产品名称/SKU ID/规格"
                style="width: 220px"
                v-model="param"
              ></el-input>
              <el-button @click="onOpened" class="ml15">搜索</el-button>
              <el-button @click="onReset" class="ml15">重置</el-button>
            </el-form-item>
          </el-form>
          <div class="table-wrapper">
            <el-table
              ref="multipleTable"
              border
              :height="500"
              :data="tableData"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                align="center"
              ></el-table-column>
              <el-table-column align="center" label="产品图片">
                <template slot-scope="scope">
                  <div v-if="scope.row.productImage" class="img-wrap">
                    <el-image
                      :src="scope.row.productImage"
                      class="c_pointer"
                      @click="handlePreview(scope.row.productImage)"
                    ></el-image>
                  </div>
                  <div v-else>-</div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="产品编码"
                prop="skuId"
              ></el-table-column>

              <el-table-column
                align="center"
                label="产品名称"
                prop="productName"
              ></el-table-column>

              <el-table-column align="center" label="规格" prop="productSpec">
                <template slot-scope="scope">
                  {{ scope.row.productSpec ? scope.row.productSpec : '--' }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="产品类型"
                prop="productType"
              >
                <template slot-scope="scope">
                  {{ scope.row.productType ? scope.row.productType : '--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="仓库"
                align="center"
                show-overflow-tooltip
              >
                <template>
                  {{ params.warehouseName }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="可用库存"
                prop="usableNum"
              ></el-table-column>
              <!-- <el-table-column
                align="center"
                label="预出库数量"
                prop="lockNum"
              ></el-table-column> -->
            </el-table>
          </div>
          <Pagination
            v-if="page.total > 0"
            :limit.sync="page.pageLe"
            :page.sync="page.pageNo"
            :total="page.total"
            @pagination="onPagination"
          ></Pagination>
        </el-tab-pane>
        <el-tab-pane
          label="不可用库存"
          name="USELESS"
          v-if="source != 'AllotInquire'"
        >
          <el-form ref="form">
            <el-form-item>
              <el-input
                placeholder="搜索产品名称/SKU ID/规格"
                style="width: 220px"
                v-model="param"
              ></el-input>
              <el-button @click="onOpened" class="ml15">搜索</el-button>
              <el-button @click="onReset" class="ml15">重置</el-button>
            </el-form-item>
          </el-form>
          <div class="table-wrapper">
            <el-table
              ref="multipleTable"
              border
              :height="500"
              :data="tableData"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                align="center"
              ></el-table-column>
              <el-table-column align="center" label="产品图片">
                <template slot-scope="scope">
                  <div v-if="scope.row.productImage" class="img-wrap">
                    <el-image
                      :src="scope.row.productImage"
                      class="c_pointer"
                      @click="handlePreview(scope.row.productImage)"
                    ></el-image>
                  </div>
                  <div v-else>-</div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="SKU ID"
                prop="skuId"
              ></el-table-column>

              <el-table-column
                align="center"
                label="产品名称"
                prop="productName"
              ></el-table-column>
              <el-table-column align="center" label="规格" prop="productSpec">
                <template slot-scope="scope">
                  {{ scope.row.productSpec ? scope.row.productSpec : '--' }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="产品类型"
                prop="productType"
              >
                <template slot-scope="scope">
                  {{ scope.row.productType ? scope.row.productType : '--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="仓库"
                align="center"
                show-overflow-tooltip
              >
                <template>
                  {{ params.warehouseName }}
                </template>
              </el-table-column>

              <!-- <el-table-column
                align="center"
                label="产品类型"
                prop="productSpec"
              >
                <template slot-scope="scope">
                  {{ scope.row.productSpec ? scope.row.productSpec : '--' }}
                </template>
              </el-table-column> -->

              <el-table-column
                align="center"
                label="不可用库存"
                prop="unusableNum"
              ></el-table-column>
              <!-- <el-table-column
                align="center"
                label="预出库数量"
                prop="lockNum"
              ></el-table-column> -->
            </el-table>
          </div>
          <Pagination
            v-if="page.total > 0"
            :limit.sync="page.pageLe"
            :page.sync="page.pageNo"
            :total="page.total"
            @pagination="onPagination"
          ></Pagination>
        </el-tab-pane>
      </el-tabs>

      <div class="footer-wrapper t_a_c">
        <el-button type="primary" class="ml30" @click="sureAction">
          确 定
        </el-button>
        <el-button @click="cancelAction">取 消</el-button>
      </div>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </el-dialog>
</template>

<script>
import Pagination from '@/components/Pagination/Index'
import { OutboundSheetInteractor } from '@/core'
export default {
  name: 'productForOut',
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    source: {
      type: String,
      default: '',
    },
    selectedPro: {
      type: Array,
      default: () => [],
    },
    warehouseId: [String],
  },
  data() {
    return {
      productions: [], //产品列表
      visible: true,
      tableData: [],
      param: '',
      page: {
        pageLe: 10,
        pageNo: 1,
        total: 0,
      },
      activeName: 'USE',
      multipleSelection: [],
      showViewer: false, //图片预览参数
      imgUrl: '', //图片预览url
      // ware,
    }
  },
  components: {
    Pagination,
  },
  methods: {
    onPagination({ page }) {
      this.page.pageNo = page
      this.onSearch().then(() => {
        this.$_afterOpen()
      })
    },

    $_afterOpen() {
      let rows = this.tableData.filter((item) =>
        this.selectedPro.find((pro) => pro.skuId === item.skuId)
      )

      this.toggleSelection(rows)
    },
    onOpened() {
      this.page.pageNo = 1
      this.onSearch().then(() => {
        this.$_afterOpen()
      })
    },
    toggleSelection(rows) {
      rows.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 获取产品列表
    getProducts() {},
    onSearch() {
      if (!this.warehouseId) {
        return
      }
      const data = {
        param: this.param,
        ...this.page,
        warehouseId: this.warehouseId,
        usable: this.activeName === 'USE' ? 1 : 0,
      }
      return new Promise(async (resolve, reject) => {
        let res = await OutboundSheetInteractor.listProductForStock(data)
        this.tableData = res.data.data.map((item) => {
          if (item.costPrice) {
            item.priceExcludingTax = item.costPrice
          }
          return item
        })
        this.page.total = res.data.total
        resolve()
      })
    },

    onTabClick(val) {
      this.activeName = val.name
      this.onSearch()
    },
    onReset() {
      this.page.pageNo = 1
      this.param = ''
      this.onSearch()
    },
    cancelAction() {
      this.$emit('close')
    },
    sureAction() {
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        return this.$message.warning('请选择产品')
      }
      let pros = JSON.parse(JSON.stringify(this.multipleSelection))
      pros = pros.map((i) => {
        i.usable = this.activeName === 'USE' ? 1 : 0
        return i
      })
      this.$emit('select-pro', pros)
      this.$emit('close')
    },

    onClose() {
      this.$refs.multipleTable.clearSelection()
      this.$emit('close')
    },

    // 大图预览
    handlePreview(item) {
      if (Array.isArray(item)) {
        this.imgUrl = item
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    // 取消预览
    closeViewer() {
      this.showViewer = false
    },
  },

  watch: {
    activeName(newval, oldval) {},
  },
}
</script>

<style scoped lang="scss">
.footer {
  .el-button + .el-button {
    margin-left: 30px;
  }
}
.ml15 {
  margin-left: 15px;
}
.img-wrap {
  position: relative;
  padding: 3px 0;
  width: 50px;
  margin: 0 auto;
  .el-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
  }
  .el-badge {
    position: absolute;
    top: 3px;
    right: -10px;
    z-index: 2;
  }
}
::v-deep {
  .el-dialog__body {
    padding: 0 20px 20px;
  }
}
</style>
