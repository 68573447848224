<template>
  <!-- <div>通讯录</div> -->
  <div>{{ $t('other.addressbook') }}</div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>
