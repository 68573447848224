<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" :width="width" top="0" custom-class="dialog"
    :close-on-click-modal="false">
    <div class="dialog-content">
      <el-form ref="form" :model="form" :label-width="settings === 'zh' ? '78px' : '170px'"
        :label-position="settings === 'zh' ? 'left' : 'top'">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item prop="forwardEmailAccount" :label="$t('mailConfig.emailaccount')" :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]">
              <el-select v-model="form.forwardEmailAccount" v-loadmore:el-select-dropdown__list="loadMore"
                popper-class="bank-select-emali" filterable remote clearable :placeholder="emailPlaceholder"
                :remote-method="remoteMethod" :loading="selectLoading" auto-complete="new-password"
                @change="handleSelect" @focus="() => remoteMethod('')">
                <el-option v-for="(item, index) in options" :key="index" :label="item.emailAccountShow"
                  :value="item.emailAccount" />
              </el-select>
            </el-form-item>
          </el-col>
          <p v-if="type === 1 && hasoVacationBol == 1" :class="['has-ovacation', settings === 'zh' ? 'pl78' : '']">
            {{ $t('mailConfig.HasoVacation') }}
          </p>
          <template v-if="type === 2">
            <el-col :span="11">
              <el-form-item prop="leaveStartTime" label-width="0" :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: $t('placeholder.plsSel'),
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (
                        form.leaveEndTime &&
                        new Date(value) > new Date(form.leaveEndTime)
                      ) {
                        callback($t('mailConfig.selectTimeTips1'))
                      } else {
                        callback()
                      }
                    },
                    trigger: 'change',
                  },
                ]">
                <el-date-picker v-model="form.leaveStartTime" type="datetime" format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm" :placeholder="$t('mailConfig.leaveStartTime')"
                  @change="tiemChange('leaveStartTime')" />
              </el-form-item>
            </el-col>
            <el-col :span="2" style="line-height: 32px">-</el-col>
            <el-col :span="11">
              <el-form-item prop="leaveEndTime" label-width="0" :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: $t('placeholder.plsSel'),
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (
                        form.leaveStartTime &&
                        new Date(value) < new Date(form.leaveStartTime)
                      ) {
                        callback($t('mailConfig.selectTimeTips'))
                      } else {
                        callback()
                      }
                    },
                    trigger: 'change',
                  },
                ]">
                <el-date-picker v-model="form.leaveEndTime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
                  type="datetime" :placeholder="$t('mailConfig.leaveEndTime')" @change="tiemChange('leaveStartTime')" />
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button @click="dialogFormVisible = false">
          <!-- 取 消 -->
          {{ $t('mailConfig.Cancel') }}
        </el-button>
        <el-button v-if="type === 2 && showLeaveCanceledBol" type="warning" :loading="cancelLoading"
          @click="leaveCancelFn">
          <!-- 转交取消 -->
          {{ $t('mailConfig.LeaveCanceled') }}
        </el-button>
        <el-button type="primary" :loading="saveLoading" @click="save">
          <!-- 确 认 -->
          {{ $t('mailConfig.Confirm') }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { systemInteractor } from '@/core'
  import { parseTime } from '@/utils'
  export default {
    name: 'AssignmentAndTransfer',
    props: {
      assignmentAndTransferBol: {
        // 控制弹窗显示和关闭
        type: Boolean,
        required: true,
      },
      width: {
        type: String,
        default: '504px',
      },
      type: {
        // 1是分配 2是转交休假
        type: Number,
        default: 1,
      },
      title: {
        //弹窗标题
        type: String,
        default: '提示',
      },
      // 邮件id集合
      mailIdArr: {
        type: Array,
        default: () => [],
      },
      // 邮件服务
      mailServer: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        saveLoading: false,
        cancelLoading: false,
        selectLoading: false,
        options: [],
        userLeava: [],
        pageNo: 1,
        total: 0,
        pageSize: 10,
        query: '',
        form: {
          forwardEmailAccount: '',
          forwardUserNameCn: '',
          forwardUserNameEn: '',
          forwardUserId: '',
          leaveStartTime: '',
          leaveEndTime: '',
        },
        showLeaveCanceledBol: false, //是否展示休假取消按钮
        hasoVacationBol: 0, //否在休假(1是0否)
      }
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      hasMore() {
        return (
          Math.ceil(Number(this.total) / Number(this.pageSize)) > this.pageNo
        )
      },
      // 控制弹窗开关
      dialogFormVisible: {
        get() {
          return this.assignmentAndTransferBol
        },
        set(val) {
          this.$emit('update:assignmentAndTransferBol', val)
        },
      },
      // 输入框提示语
      emailPlaceholder() {
        return this.type === 1
          ? this.$t('mailConfig.assignTo')
          : this.$t('mailConfig.forwardTo')
      },
    },
    watch: {
      // 监听弹窗休假转交回显数据
      dialogFormVisible(val) {
        this.resetForm()
        this._reset()
        if (val && this.type === 2) {
          // 处理转交 回显逻辑
          this.getUserLeavaFoewardFn()
        }
      },
    },
    methods: {
      // 下拉加载更多
      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },
      // 选择邮箱
      handleSelect(val) {
        if (!val) {
          this.hasoVacationBol = 0
          return
        }
        const obj =
          this.options.filter((item) => item.emailAccount === val)?.[0] ?? {}
        this.userLeava = obj?.userLeava ?? []
        this.form.forwardEmailAccount = val
        this.form.forwardUserNameCn = this.userLeava[0].businessName
        this.form.forwardUserNameEn = this.userLeava[0].businessNameEn
        this.form.forwardUserId = this.userLeava[0].businessId
        this.hasoVacationFn(this.form.forwardUserId)
      },
      // 判断当前人是否在休假(1是；0否)
      async hasoVacationFn(userId) {
        if (!userId || this.type !== 1) return
        let res = await systemInteractor.checkUserLeavaApi({ userId })
        if (res?.code === '000000') this.hasoVacationBol = res?.data ?? 0
      },
      // 选择绑定人
      handleSelectMan(val) {
        const obj = this.userLeava.filter((item) => item.businessId === val)
        this.form.forwardUserNameCn = obj.businessName
        this.form.forwardUserNameEn = obj.businessNameEn
      },
      // 搜索邮箱
      remoteMethod(query) {
        this.query = query
        this.selectLoading = true
        this._getOptions()
      },
      _reset() {
        this.pageNo = 1
        this.total = 0
        this.options = []
      },
      async _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        const params = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          emailAccount: this.query,
        }
        const res =
          this.type === 1
            ? await systemInteractor.getUserLeavaAccountVOApi(params)
            : await systemInteractor.getUserHolidayAccountVOApi(params)
        this.selectLoading = false
        if (res?.code === '000000' && res?.data?.records) {
          this.total = res.data?.total
          this.options = loadMore
            ? [...this.options, ...res.data.records]
            : res.data.records
        } else {
          this.options = []
        }
      },
      // 选择日期重新校验开始和结束日期
      tiemChange(str) {
        str === 'leaveStartTime'
          ? this.$refs['form'].validateField('leaveEndTime')
          : this.$refs['form'].validateField('leaveStartTime')
      },
      // 重置表单
      resetForm() {
        if (!this.$refs.form) return
        this.form.forwardUserNameCn = ''
        this.form.forwardUserNameEn = ''
        this.form.forwardUserId = ''
        this.userLeava = []
        this.options = []
        this.showLeaveCanceledBol = false
        this.hasoVacationBol = 0
        this.$refs.form.resetFields()
      },
      // 关闭按钮loading
      closeLoadingFn() {
        this.saveLoading = false
        this.cancelLoading = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let params = {}
            // 点击确认按钮
            if (this.type === 1) {
              // 分配
              this.$confirm(
                this.$t('mailConfig.aAssignMailConfirm'),
                this.$t('myMail.Tips'),
                {
                  confirmButtonText: this.$t('myMail.Confirm'),
                  cancelButtonText: this.$t('myMail.Cancel'),
                  type: 'warning',
                }
              )
                .then(() => {
                  this.assignSaveFn()
                })
                .catch(() => { })
            } else {
              // 休假转交
              params.now = parseTime(new Date())
              Object.assign(params, this.form)
              this.saveUserLeavaFoewardFn(params)
            }
          }
        })
      },
      // 转交取消
      leaveCancelFn() {
        // 点击休假取消按钮
        this.$confirm(this.$t('reqmsg.$52'), this.$t('myMail.Tips'), {
          confirmButtonText: this.$t('myMail.Confirm'),
          cancelButtonText: this.$t('myMail.Cancel'),
          type: 'warning',
        })
          .then(() => {
            this.gcancellationUserLeavaFoewardFn()
          })
          .catch(() => { })
      },
      // 分配确认
      async assignSaveFn() {
        let params = {
          mailIdList: this.mailIdArr,
          mailServer: this.mailServer,
          assignEmailAccount: this.form.forwardEmailAccount,
        }
        this.saveLoading = true
        let res = await systemInteractor.assignApi(params)
        this.saveLoading = false
        if (res?.code === '000000') {
          // 分配成功对外抛出成功，刷新邮件列表操作
          this.$emit('saveFn')
          this.dialogFormVisible = false
          this.$message.success(this.$t('reqmsg.$7'))
        }
      },
      // 转交提交
      async saveUserLeavaFoewardFn(params) {
        this.saveLoading = true
        const res = await systemInteractor.saveUserLeavaFoewardApi(params)
        this.saveLoading = false
        if (res?.code === '000000') {
          this.dialogFormVisible = false
          this.$message.success(this.$t('reqmsg.$7'))
        }
      },
      // 获取转交详情
      async getUserLeavaFoewardFn() {
        const res = await systemInteractor.getUserLeavaFoewardApi({
          now: parseTime(new Date()),
        })
        if (res?.code === '000000') {
          this.query = res?.data?.forwardEmailAccount
          if (!this.query) return
          this.showLeaveCanceledBol = true
          await this._getOptions()
          // this.handleSelect(this.query)
          this.form.leaveStartTime = res?.data?.leaveStartTime
          this.form.leaveEndTime = res?.data?.leaveEndTime
          this.form.forwardEmailAccount = res?.data?.forwardEmailAccount
          this.form.forwardUserNameCn = res?.data?.forwardUserNameCn
          this.form.forwardUserNameEn = res?.data?.forwardUserNameEn
          this.form.forwardUserId = res?.data?.forwardUserId
        }
      },
      // 休假取消
      async gcancellationUserLeavaFoewardFn() {
        this.cancelLoading = true
        const res = await systemInteractor.gcancellationUserLeavaFoewardApi()
        this.cancelLoading = false
        if (res?.code === '000000') {
          this.dialogFormVisible = false
          this.$message.success(this.$t('reqmsg.$7'))
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep .dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .dialog-content {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
    min-height: 92px;

    .el-form {
      width: 90%;
      margin: 0 auto;
    }

    ::v-deep {
      .el-select {
        width: 100%;
      }

      .el-date-editor {
        &.el-input {
          width: 100%;
        }
      }
    }

    .has-ovacation {
      color: #ff4d4f;

      &.pl78 {
        padding-left: 88px;
      }
    }
  }
</style>
<style>
  .bank-select-emali .el-select-dropdown__list {
    max-height: 185px !important;
    overflow: auto;
    overflow-x: hidden;
  }
</style>