import request from '@/core/services/request'
// 客户跟进列表
export function clientFollowList(data) {
  return request({
    url: '/merchants/follow/listPage',
    method: 'post',
    data,
  })
}
// 客户跟进编辑回显
export function clientFollowEdit(data) {
  return request({
    url: '/merchants/follow/getDetailForEdit',
    method: 'post',
    data,
  })
}
// 客户跟进更新
export function clientFollowUpdate(data) {
  return request({
    url: '/merchants/follow/updatePO',
    method: 'post',
    data,
  })
}
// 客户来源列表
export function clientSourceList(data) {
  return request({
    url: '/merchants/source/listPage',
    method: 'post',
    data,
  })
}
// 客户来源新增
export function clientSourceAdd(data) {
  return request({
    url: '/merchants/source/insertPO',
    method: 'post',
    data,
  })
}
// 客户来源修改
export function clientSourceUpdate(data) {
  return request({
    url: '/merchants/source/updatePO',
    method: 'post',
    data,
  })
}
// 客户来源删除
export function clientSourceDelete(data) {
  return request({
    url: '/merchants/source/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 查询客户预警额度
export function customerWarningDetail(data) {
  return request({
    url: '/merchants/customerWarning/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 修改客户预警额度
export function customerWarningUpdate(data) {
  return request({
    url: '/merchants/customerWarning/updatePO',
    method: 'post',
    data,
  })
}
