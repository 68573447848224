<template>
  <el-dialog
    title="收款单详情"
    :visible.sync="dialogReceiptVisible"
    width="1200px"
    @close="close"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="content">
      <!-- floor1 -->
      <el-row type="flex" :gutter="80">
        <el-col>
          <label>收款单号</label>
          <p>
            {{ receiptData.financeCode }}
            <el-tag class="ml5" :type="status | statusFilter">
              {{ status | statusTextFilter }}
            </el-tag>
          </p>
        </el-col>
        <el-col>
          <label>收款来源</label>
          <p>采购退款</p>
        </el-col>
        <el-col>
          <label>收款方式</label>
          <p>{{ receiptData.financeMethod || '--' }}</p>
        </el-col>
        <el-col>
          <label>收款日期</label>
          <p>{{ receiptData.amountTime || '--' }}</p>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30" class="mt20">
        <el-col :span="7">
          <label>收款金额</label>
          <p class="green-bold" v-if="receiptData.amountCollected">
            {{ receiptData.currency === '美元' ? '$' : '￥' }}
            {{ receiptData.amountCollected }}
          </p>
          <p v-else>--</p>
        </el-col>
        <el-col :span="20">
          <label>收款原因</label>
          <tooltip-over
            v-if="receiptData.collectionReason"
            class="c_pointer tooltipOver tooltip-p"
            :content="receiptData.collectionReason"
            refName="tooltipOver5"
          ></tooltip-over>
          <p v-else class="no-text">未填写</p>
        </el-col>
      </el-row>

      <!-- floor2 收款table-->
      <el-table border class="w100 mt30" :data="receiptData.financeOrderVOS">
        <el-table-column label="采购订单编号" align="center" prop="orderCode">
          <template slot-scope="scope">
            <router-link
              target="_blank"
              :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
                scope.row.orderId
              }&noReturn=${true}`"
            >
              <span class="page-link">{{ scope.row.orderCode }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          label="供应商名称"
          align="center"
          show-overflow-tooltip
          prop="customerName"
        ></el-table-column>
        <el-table-column
          label="所属地区"
          align="center"
          show-overflow-tooltip
          prop="area"
        ></el-table-column>
        <el-table-column
          label="币种"
          align="center"
          prop="currency"
        ></el-table-column>
        <el-table-column label="订单金额" align="center" prop="totlePrice">
          <template slot-scope="scope">
            {{ scope.row.totlePrice || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="已付金额" align="center" prop="amountPaid">
          <template slot-scope="scope">
            {{ scope.row.amountPaid }}
          </template>
        </el-table-column>
        <el-table-column
          label="未付金额"
          align="center"
          prop="outstandingAmount"
        >
          <template slot-scope="scope">
            {{ scope.row.outstandingAmount || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          label="交易金额"
          align="center"
          prop="amountReceivable"
        >
          <template slot-scope="scope">
            {{ scope.row.amountReceivable || '--' }}
          </template>
        </el-table-column>
      </el-table>

      <!-- floor3 备注等其他 -->
      <el-row :gutter="20" type="flex" class="mt30">
        <el-col :span="6">
          <label>创建人</label>
          <p>{{ receiptData.creatorName || '--' }}</p>
        </el-col>
        <el-col :span="6">
          <label>创建时间</label>
          <p>{{ receiptData.createTime || '--' }}</p>
        </el-col>
      </el-row>

      <el-row class="row-line"></el-row>

      <!-- 已完成 退款table floor4 -->
      <!-- table-->
      <el-table border class="w100 mt30" v-show="status === 4" :data="testData">
        <el-table-column
          label="付款方"
          align="center"
          show-overflow-tooltip
          prop="payee"
        ></el-table-column>
        <el-table-column
          label="收款方式"
          align="center"
          prop="financeMethod"
        ></el-table-column>
        <el-table-column
          label="出头抬头"
          align="center"
          prop="exportsRise"
        ></el-table-column>
        <el-table-column
          label="收款账户"
          align="center"
          show-overflow-tooltip
          prop="payerId"
        ></el-table-column>
        <el-table-column
          label="票据单号"
          align="center"
          show-overflow-tooltip
          prop="ticketNumber"
        ></el-table-column>
        <el-table-column
          label="币种"
          align="center"
          prop="currency"
        ></el-table-column>
        <el-table-column
          label="收款金额"
          align="center"
          prop="amountCollected"
        ></el-table-column>
        <el-table-column
          label="手续费金额"
          align="center"
          prop="commissionAmount"
        ></el-table-column>
        <el-table-column
          label="收款日期"
          align="center"
          prop="amountTime"
        ></el-table-column>
        <el-table-column
          label="银行入账日期"
          align="center"
          prop="bankAcceptanceTime"
        ></el-table-column>
      </el-table>

      <!-- 已完成  floor5-->
      <el-row :gutter="20" type="flex" class="mt30" v-show="status === 4">
        <el-col>
          <label>确认人</label>
          <p>{{ receiptData.updateName || '--' }}</p>
        </el-col>
        <el-col>
          <label>确认时间</label>
          <p>{{ receiptData.updateTime || '--' }}</p>
        </el-col>
      </el-row>

      <!-- 已驳回 floor5-->
      <el-row :gutter="20" type="flex" class="mt30" v-show="status === 3">
        <el-col>
          <label>驳回人</label>
          <p>{{ receiptData.updateName || '--' }}</p>
        </el-col>
        <el-col>
          <label>驳回原因</label>
          <p>{{ receiptData.statusRemark || '--' }}</p>
        </el-col>
        <el-col>
          <label>驳回时间</label>
          <p>{{ receiptData.updateTime || '--' }}</p>
        </el-col>
      </el-row>

      <el-row>
        <div style="margin-top: 30px" class="t_a_c">
          <el-button @click="close">关 闭</el-button>
        </div>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { detailByFinanceOrder } from '@/api/finance/credit-manage-list'
import tooltipOver from '@/components/base-tooltip'
export default {
  name: 'ReceiptDetailModel',
  components: {
    tooltipOver,
  },
  data() {
    return {
      dialogReceiptVisible: false,
      status: '', // 收款单状态
      receiptData: '', // 收款详情数据
      orderTableData: [], // 底部订单表格
      fileList: [], //附件
      testData: [],
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: 'warning',
        1: 'warning',
        2: 'danger',
        3: 'danger',
        4: 'success',
        5: 'info',
        6: 'warning',
        7: 'danger',
        8: 'danger',
        9: 'info',
      }
      return statusMap[status]
    },
    statusTextFilter(status) {
      const statusTextMap = {
        1: '待处理',
        2: '待认领',
        3: '已驳回',
        4: '已完成',
        5: '已作废',
        6: '已认领',
        7: '待确认',
      }
      return statusTextMap[status]
    },
  },
  methods: {
    showReceiptDetail(financeCode, financeStatus) {
      this.status = Number(financeStatus)
      this.getDetailByFinanceOrder(financeCode)
      this.dialogReceiptVisible = true
    },

    //收款单详情
    async getDetailByFinanceOrder(financeCode) {
      let response = await detailByFinanceOrder({ financeCode: financeCode })
      if (response.code === '000000') {
        this.receiptData = response.data

        //销售单table
        this.orderTableData = response.data.financeOrderVOS
        let obj = {
          payee: response.data.payee || '--',
          financeMethod: response.data.financeMethod || '--',
          exportsRise: response.data.exportsRise || '--',
          payerId: response.data.payerId || '--',
          ticketNumber: response.data.ticketNumber || '--',
          currency: response.data.currency || '--',
          amountCollected: response.data.amountCollected || '--',
          commissionAmount: response.data.financeOrderVOS[0].commissionAmount,
          amountTime: response.data.amountTime || '--',
          bankAcceptanceTime: response.data.bankAcceptanceTime || '--',
        }
        this.testData.push(obj)
        //附件
        this.fileList = this.receiptData.financeUrl
          ? JSON.parse(this.receiptData.financeUrl)
          : ''
      }
    },
    close() {
      this.testData = []
      this.status = ''
      this.receiptData = ''
      this.orderTableData = []
      this.fileList = []
      this.dialogReceiptVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px 0px;
  .el-row {
    label {
      color: #666;
    }
    p {
      color: #000;
      font-size: 16px;
      white-space: nowrap;
      margin-top: 5px;
    }
  }
  .row-line {
    border-bottom: 1px solid #ededed;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
::v-deep {
  .tooltip-p {
    span {
      color: #000;
      font-size: 16px !important;
      white-space: nowrap;
      margin-top: 5px;
    }
  }
}

.green-bold {
  color: green !important;
  font-weight: bold;
}
.link {
  color: #46a6ff;
  cursor: pointer;
  text-decoration: underline;
}
.no-text {
  color: #999 !important;
}
</style>
