<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年05月10日 13:12:35
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-row>
    <h4>发货信息</h4>
    <div class="custom-detail">
      <template v-for="(item, index) in shipNos">
        <el-row :key="index + '1'">
          <el-col>
            <em>运输方式：</em>
            <span>
              {{ item.shippingMethod }}
            </span>
          </el-col>
          <el-col>
            <em>运单号:</em>
            <span>
              {{ item.shipNo }}
            </span>
          </el-col>
          <el-col>
            <em style="width: 110px">预计到达时间：</em>
            <span style="width: calc(100% - 110px)">
              {{ item.arriveTime ? item.arriveTime.split(' ')[0] : '--' }}
            </span>
          </el-col>

          <el-col>
            <em>总重量:</em>
            <span>{{ item.grossWeight || '--' }}</span>
          </el-col>
        </el-row>

        <el-row :key="index + '2'">
          <el-col>
            <em>总箱数:</em>
            <span>{{ item.caseNum || '--' }}</span>
          </el-col>
          <el-col>
            <em>总体积：</em>
            <span>{{ item.volume || '--' }}</span>
          </el-col>

          <el-col>
            <em>装箱尺寸：</em>
            <span>{{ item.packingSize || '--' }}</span>
          </el-col>
        </el-row>
        <el-row :key="index + '3'">
          <el-col :span="24" style="width: 100%">
            <em style="vertical-align: top">发货备注：</em>
            <span>{{ item.remarks || '--' }}</span>
          </el-col>
        </el-row>
      </template>
      <el-row>
        <el-col>
          <em>附件：</em>
        </el-col>
      </el-row>
      <ErpUpload
        ref="ErpUpload"
        :uploadParams="uploadParams"
        style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
      ></ErpUpload>
    </div>
  </el-row>
</template>

<script>
  export default {
    name: 'ShippingInformation',
    props: {
      shipNos: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        uploadParams: {
          uploadButton: false,
        },
      }
    },

    methods: {
      initFile(shipAccessoryUrl, close) {
        this.$refs.ErpUpload.initUpload(shipAccessoryUrl, close)
      },
    },
  }
</script>

<style scoped lang="scss"></style>
