<!--
  功能：物流状态通知
  作者：jiangyongqiang
  时间：2021年11月16日 20:20:50
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard
    title="物流状态通知"
    @tab-click="handleTabClick"
    :tabs="tabs"
    :singleNum="singleNum"
    :teamNum="teamNum"
    :loading="loading"
  >
    <div>
      <div v-if="list.length > 0">
        <template v-for="(item, index) in list">
          <IndexCardItem :key="index" class="unread-mails-item">
            <div
              class="content"
              @click="clientFollowClick(item)"
              :style="itemStyleObj"
            >
              <div class="mails-item--top" v-if="item.type != 3">
                <el-tag :type="tagMaps[item.type].type">
                  {{ getTag(item).text }}
                </el-tag>
                <p>
                  销售单:
                  <span class="topic mr5" :style="boldStyle.bold">
                    {{ item.salesOrderNumber || '--' }}
                  </span>
                  委托单:
                  <span class="topic mr5" :style="boldStyle.bold">
                    {{ item.shippingOrderNumber || '--' }}
                  </span>
                  在
                  <span class="mr5">
                    {{ item.toBeTime | timeFilter }}
                  </span>
                  <span :style="boldStyle.bold">
                    {{ tagMaps[item.type].message }}
                  </span>
                  ；
                </p>
              </div>
              <div
                class="mails-item--top"
                v-else
                @click="
                  logisticsProgress(
                    item.shipNoLogisticsId,
                    item.transportNumber
                  )
                "
              >
                <el-tag :type="tagMaps[item.type].type">
                  {{ getTag(item).text }}
                </el-tag>
                <p>
                  运单号
                  <span class="topic mr5" :style="boldStyle.bold">
                    {{ item.shipNo || '--' }},
                  </span>
                  销售单
                  <span class="mr5" :style="boldStyle.bold">
                    {{ item.salesOrderNumber | timeFilter }}
                  </span>
                  出运单
                  <span :style="boldStyle.bold">
                    {{ item.shippingOrderNumber || '--' }}
                  </span>
                  在
                  <span class="mr5">
                    {{ item.toBeTime | timeFilter }}
                  </span>
                  <span :style="boldStyle.bold">状态异常</span>
                  ；
                </p>
              </div>
            </div>
          </IndexCardItem>
        </template>
        <div
          @click="readMore"
          style="text-align: center; margin-top: 10px"
          v-if="showMore"
        >
          <el-link type="primary">查看更多</el-link>
        </div>
      </div>
      <IndexCardEmpty v-else></IndexCardEmpty>
    </div>
    <!-- 选择大货照 -->
    <SelectBigGoodPhoto
      :option="selPhotoOption"
      :list="bigGoodsList"
      :shippingOrderId="shippingOrderId"
      @refresh="refresh"
    />
    <!-- 物流进度查询 -->
    <logistics-progress ref="logistics" @refresh="refresh"></logistics-progress>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import { ShipmentMangeInteractor } from '@/core'
  import sendGoodsMixin from '@/views/order/shipmentManage/components/select-big-good-photo/mixin'
  import SelectBigGoodPhoto from '@/views/order/shipmentManage/components/select-big-good-photo/index.vue'
  import { getSignMailContent } from '@/views/order/shipmentManage/helper/send-mail'
  import LogisticsProgress from '@/views/order/shipmentManage/components/logistics-progress'
  const tagMaps = {
    1: {
      text: '已出运',
      type: 'warning',
      message: '已发货',
    },
    2: {
      text: '已签收',
      type: 'success',
      message: '状态已签收',
    },
    3: {
      text: '异常',
      type: 'danger',
      message: '状态异常',
    },
  }
  export default {
    name: 'CustomerReview',
    mixins: [mixins, sendGoodsMixin],
    props: {},
    components: {
      SelectBigGoodPhoto,
      LogisticsProgress,
    },
    // 组件状态值
    data() {
      return {
        list: [],
        tabs: false,
        detail: {},
        params: {
          //控制参数
          show: false,
          transactionType: 2,
        },
        total: 0,
        tagMaps: Object.freeze(tagMaps),
      }
    },
    computed: {
      showMore() {
        return this.total > 30
      },
    },

    filters: {
      timeFilter(val) {
        return val ? val.split(' ')[0] : '--'
      },
    },

    methods: {
      refresh() {
        this.getList()
      },
      getTag(item) {
        const map = {
          1: {
            text: '已出运',
            type: 'warning',
          },
          2: {
            text: '已签收',
            type: 'success',
          },
          3: {
            text: '异常',
            type: 'danger',
          },
        }
        const { type, text } = map[item.type]
        return {
          type,
          text,
        }
      },
      readMore() {
        let routeData = this.$router.resolve({
          path: '/client-supplier/customer-center',
        })
        window.open(routeData.href, '_blank')
      },

      refresh() {
        this.getList()
      },

      getList() {
        ShipmentMangeInteractor.logisticsNeedToBe().then((res) => {
          if (res && res.code === '000000') {
            this.list = res.data || []
          }
        })
      },
      // 发送签收邮件
      async sendSignMail({ shippingOrderId, shipOrderNumber }) {
        // 获取邮件内容
        let sendForm = await getSignMailContent({
          shippingOrderId,
          ctx: this,
        })
        sendForm.receiveList = sendForm.receiveEmail?.split(',') || []
        sendForm.enclosureList = []
        localStorage.setItem('SIGN_MAIL_CONTENT', JSON.stringify(sendForm))
        let routeData = this.$router.resolve({
          path: '/write',
          query: { from: 'sign', sh: shipOrderNumber },
        })
        this.utils.openWindow(routeData.href)
      },
      clientFollowClick(item) {
        const { type, shippingOrderId, toBeTime: shipTime } = item

        switch (type) {
          case 1: //大货照
            this.sendGoodsMail({
              shippingOrderId,
              shipTime,
            })
            break
          case 2: // 签收邮件
            this.sendSignMail(item)
            const signBC = new BroadcastChannel('SIGN_MAIL')
            signBC.onmessage = () => {
              ShipmentMangeInteractor.signMailSended(shippingOrderId).then(
                (res) => {
                  if (res && res.code === '000000') {
                    // 刷新页面
                    this.getList()
                  }
                }
              )
            }
            break
          case 3: // 物流进度异常提示
            break
        }
      },
      // 物流进度追踪
      logisticsProgress(id, number) {
        this.$refs.logistics.init(id, 3, number)
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .unread-mails-item {
    font-size: 14px;
    .mails-item--top {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      word-break: break-all;
      color: #000;
      .el-tag {
        margin-right: 10px;
      }
    }
    .mails-item--bottom {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      p {
        color: #5e5e5e;

        .label {
          margin-right: 5px;
        }
      }
    }
    .mr5 {
      margin-right: 5px;
    }
  }
</style>
