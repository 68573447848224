<template>
  <el-container class="inspection-container">
    <el-aside width="200px">
      <LeftAside />
    </el-aside>
    <el-container>
      <el-header>
        <ToolBar />
      </el-header>
      <el-container>
        <el-aside width="400px">
          <mail-list></mail-list>
        </el-aside> 
        <el-main>
          <mail-info></mail-info>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
import LeftAside from '@/views/mail/inspection/components/left-aside'
import ToolBar from '@/views/mail/inspection/components/tool-bar'
import MailList from '@/views/mail/inspection/components/mail-list'
import MailInfo from '@/views/mail/inspection/components/mail-info'
export default {
  components: {
    LeftAside,
    ToolBar,
    MailList,
    MailInfo,
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.inspection-container {
  padding: 0 !important;
  .el-header {
    border-bottom: 1px solid #f5f7fa;
    display: flex;
    align-items: center;
    height: 50px !important;
  }
  .el-main {
    border-left: 1px solid #f5f7fa;
  }
  .el-container .el-container {
    height: 500px;
  }
}
</style>