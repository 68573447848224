<template>
  <el-row class="product-row">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="rule-form"
      label-width="100px"
    >
      <el-row class="mb30">
        <!-- 新增产品 -->
        <el-page-header
          @back="goBack"
          :content="$t('productDetail.AddProduct')"
        ></el-page-header>
      </el-row>
     

      <!-- 基本信息 -->
      <el-row class="floor floor1">
        <el-row class="floor-left">
          <!-- 基本信息 -->
          <h6>{{ $t('productDetail.BasicInformation') }}</h6>
        </el-row>
        <el-row class="floor-right" :gutter="20">
          <el-row>
            <el-col :span="12">
              <!-- 英文名称 -->
              <el-form-item
                :label="$t('productDetail.EnglishName')"
                prop="nameEn"
                class="w100"
              >
                <el-input
                  v-model="ruleForm.nameEn"
                  :placeholder="$t('placeholder.M2001')"
                  maxlength="100"
                  show-word-limit
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('productDetail.ChineseName')"
                prop="nameCn"
                class="w100"
              >
                <el-input
                  v-model="ruleForm.nameCn"
                  :placeholder="$t('placeholder.M2001')"
                  maxlength="100"
                  show-word-limit
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 产品类型 -->
              <el-form-item
                :label="$t('productDetail.ProductType')"
                prop="standardCustomized"
                class="w100"
              >
                <el-select
                  v-model="ruleForm.standardCustomized"
                  :placeholder="$t('placeholder.plsSel')"
                  class="w100"
                  @change="standardCustomizedChange"
                >
                  <el-option
                    v-for="(item, index) in proTypeData"
                    :key="item + index"
                    :label="item.nameCn"
                    :value="item.nameCn"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 产线 -->
              <!-- 标准品-产线必填 -->
              <el-form-item
                :label="$t('productDetail.ProductionLine')"
                prop="productionLine"
                class="w100"
                :rules="{
                  required:
                    ruleForm.standardCustomized === '标准品' ? true : false,
                  trigger: 'blur',
                  message: $t('rules.M2013'),
                }"
              >
                <el-select
                  v-model="ruleForm.productionLine"
                  :placeholder="$t('placeholder.plsSel')"
                  class="w100"
                  @change="proLineChange"
                >
                  <el-option
                    v-for="(item, index) in proLineData"
                    :key="item + index"
                    :label="item.nameCn"
                    :value="item.nameCn"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 产品运营-标准品显示 -->
            <el-col :span="8" v-show="ruleForm.standardCustomized === '标准品'">
              <!-- 产品运营 -->
              <!-- 请选择产品运营 -->
              <el-form-item
                :label="$t('productDetail.ProductOperations')"
                prop="belonger"
                class="w100"
                :rules="[
                  {
                    required:
                      ruleForm.standardCustomized === '标准品' ? true : false,
                    message: $t('rules.M2005'),
                    trigger: 'change',
                  },
                ]"
              >
                <el-select
                  v-model="ruleForm.belonger"
                  :placeholder="$t('placeholder.plsSel')"
                  class="w100"
                  @change="belongerChange"
                >
                  <el-option
                    v-for="(item, index) in belongData"
                    :key="item + index"
                    :label="lang === 'en' ? item.userNameEn : item.userName"
                    :value="item.userName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 单位 -->
              <el-form-item
                :label="$t('productDetail.ProductOperations')"
                prop="productUnit"
                class="w100"
              >
                <el-select
                  v-model="ruleForm.productUnit"
                  :placeholder="$t('placeholder.plsSel')"
                  class="w100"
                >
                  <el-option
                    v-for="(item, index) in proUnitData"
                    :key="item + index"
                    :label="item.nameEn"
                    :value="item.nameEn"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 产品类别 -->
              <el-form-item
                class="w100"
                :label="$t('productDetail.ProductCategory')"
                prop="categoryId"
              >
                <el-cascader
                  class="w100"
                  ref="cascaderAddr"
                  :options="categoryOptions"
                  v-model="ruleForm.categoryId"
                  @change="proCategoryChange()"
                  :props="optionProps"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 材质 -->
              <el-form-item
                :label="$t('productDetail.ProductCategory')"
                prop="material"
                class="w100"
              >
                <!-- 请输入 -->
                <el-input
                  v-model="ruleForm.material"
                  :placeholder="$t('placeholder.plsInput')"
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-row :gutter="15">
                <el-col :span="21">
                  <!-- 供应商 -->
                  <el-form-item
                    class="w100"
                    :label="$t('other.supplier')"
                    prop="supplierNames"
                  >
                    <!-- 点击选择供应商 -->
                    <el-input
                      v-model="ruleForm.supplierNames"
                      :placeholder="$t('placeholder.M2002')"
                      class="c_pointer"
                      :readonly="true"
                      @click.native="selectSupplierClick"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-button
                    type="primary"
                    icon="el-icon-plus"
                    circle
                    @click="supplierAddClick"
                  ></el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <!-- 英文描述 -->
          <el-row>
            <el-form-item
              :label="$t('productDetail.EnglishDescription')"
              prop="descriptionEn"
              class="w100"
            >
              <!-- 请输入1000个字符以内 -->
              <el-input
                v-model="ruleForm.descriptionEn"
                :placeholder="$t('placeholder.M2003')"
                maxlength="1000"
                show-word-limit
                class="input-textarea"
                style="white-space: pre-wrap"
                type="textarea"
                :autosize="{ minRows: 2 }"
              />
            </el-form-item>
          </el-row>
          <!-- 关键词 -->
          <el-row>
            <el-form-item
              :label="$t('productDetail.Keywords')"
              prop="keyWord"
              class="w100"
            >
              <el-input
                v-model="ruleForm.keyWord"
                type="textarea"
                :placeholder="$t('placeholder.M2003')"
                maxlength="1000"
                style="white-space: pre-wrap"
                show-word-limit
                :autosize="{ minRows: 2 }"
              />
            </el-form-item>
          </el-row>
          <el-row v-if="isChineseDescShow">
            <!-- 中文描述 -->
            <el-form-item
              :label="$t('productDetail.ChineseDescription')"
              class="w100"
            >
              <!-- 请输入500个字符以内 -->
              <el-input
                v-model="ruleForm.descriptionCn"
                type="textarea"
                maxlength="500"
                show-word-limit
                style="white-space: pre-wrap"
                :autosize="{ minRows: 2 }"
                :placeholder="$t('placeholder.M2004')"
              />
            </el-form-item>
          </el-row>
          <!-- 标签设置，中文描述，多规格，客户特批额度适用 -->
          <el-row style="margin-left: 100px" type="flex" align="middle">
            <el-button @click="labelApplyClick" type="primary">
              <!-- 标签设置 -->
              {{ $t('productDetail.LabelSettings') }}
            </el-button>
            <el-row class="ml30">
              <!-- 中文描述 -->
              <label class="mr10">
                {{ $t('productDetail.ChineseDescription') }}
              </label>
              <el-switch
                v-model="isChineseDescShow"
                @change="chineseDescChange"
              ></el-switch>
            </el-row>
            <el-row class="ml30">
              <!-- 开启多规格 -->
              <label class="mr10">
                {{ $t('productDetail.EnableMultipleSpecifications') }}
              </label>
              <el-switch
                v-model="isSpecsShow"
                @change="specsChange"
              ></el-switch>
            </el-row>
            <el-row class="ml30">
              <!-- 客户信用额度适用 -->
              <label class="mr10">
                {{ $t('productDetail.CustomerCreditLimitsApplicable') }}
              </label>
              <el-switch
                v-model="ruleForm.creditLimit"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-row>
          </el-row>
        </el-row>
      </el-row>
      <!-- 供应商-后续版本 -->
      <!-- 规格设置-多规格 -->
      <!-- 规格列表 -->
      <!-- 产品价格 -->
      <!-- 印刷计价 -->
      <!-- 图片信息 -->
    </el-form>
    <!-- 操作按钮 -->
    <el-row class="operate">
      <!-- 保存 -->
      <el-button type="primary">
        {{ $t('productDetail.Save') }}
      </el-button>
      <!--确认 -->
      <el-button type="primary" class="ml10" @click="saveClick">
        {{ $t('productDetail.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button class="ml10">
        {{ $t('productDetail.Cancel') }}
      </el-button>
    </el-row>
  </el-row>
</template>

<script>
import { categoryListForSelect } from '@/api/product/productPrintWay'
import { getDetailByType } from '@/api/product/productSetting'
import { listUserForProductionLine } from '@/api/product/productList'
import { mapGetters } from 'vuex'
export default {
  name: 'ProductAdd',
  data() {
    return {
      //产品参数
      ruleForm: {
        nameEn: '', //英文名称
        nameCn: '', //中文名称
        standardCustomized: '', //产品类型（标准品需填写-产线/产品运营,且必须验证）
        belonger: '', //归属人
        categoryId: '', //类别
        productUnit: '', //单位
        material: '', //材质
        supplierNames: '', //供应商
        descriptionEn: '', //英文描述
        keyWord: '', //关键词
        descriptionCn: '', //中文描述
        creditLimit: 1, //客户信用额度适用
      },
      // rules: {
      //   nameEn: [
      //     // 请输入英文名称
      //     { required: true, message: this.$t('rules.M2002'), trigger: 'blur' },
      //   ],
      //   nameCn: [
      //     // 请输入中文名称
      //     { required: true, message: this.$t('rules.M2003'), trigger: 'blur' },
      //   ],
      //   standardCustomized: [
      //     // 请选择产品类型
      //     {
      //       required: true,
      //       message: this.$t('rules.M2004'),
      //       trigger: 'change',
      //     },
      //   ],
      //   belonger: [
      //     // 请选择产品运营
      //     {
      //       required: true,
      //       message: this.$t('rules.M2005'),
      //       trigger: 'change',
      //     },
      //   ],
      //   categoryId: [
      //     // 请选择产品类别
      //     {
      //       required: true,
      //       message: this.$t('rules.M2006'),
      //       trigger: 'change',
      //     },
      //   ],
      //   productUnit: [
      //     // 请选择产品单位
      //     {
      //       required: true,
      //       message: this.$t('rules.M2007'),
      //       trigger: 'change',
      //     },
      //   ],
      //   material: [
      //     // 请输入产品材质
      //     { required: true, message: this.$t('rules.M2008'), trigger: 'blur' },
      //   ],
      //   supplierNames: [
      //     // 请选择供应商
      //     { required: true, message: this.$t('rules.M2009'), trigger: 'blur' },
      //   ],
      //   descriptionEn: [
      //     // 请输入英文描述
      //     { required: true, message: this.$t('rules.M2010'), trigger: 'blur' },
      //   ],
      //   // 请输入关键词
      //   keyWord: [
      //     { required: true, message: this.$t('rules.M2011'), trigger: 'blur' },
      //   ],
      // },
      categoryOptions: [], //产品类别
      //产品类别数据转换
      optionProps: {
        value: 'productCategoryId',
        label: 'nameCn',
        children: 'children',
        checkStrictly: true,
      },
      proUnitData: [], //产品单位，6，产品设置中获取
      proTypeData: [], //产品类型，7，产品设置中获取
      proLineData: [], //产品产线，9，产品设置中获取
      belongData: [], //归属人下拉
      isChineseDescShow: false, //是否开启中文描述
      isSpecsShow: true, //是否开启多规格（默认开启）
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
    rules() {
      return {
        nameEn: [
          // 请输入英文名称
          { required: true, message: this.$t('rules.M2002'), trigger: 'blur' },
        ],
        nameCn: [
          // 请输入中文名称
          { required: true, message: this.$t('rules.M2003'), trigger: 'blur' },
        ],
        standardCustomized: [
          // 请选择产品类型
          {
            required: true,
            message: this.$t('rules.M2004'),
            trigger: 'change',
          },
        ],
        belonger: [
          // 请选择产品运营
          {
            required: true,
            message: this.$t('rules.M2005'),
            trigger: 'change',
          },
        ],
        categoryId: [
          // 请选择产品类别
          {
            required: true,
            message: this.$t('rules.M2006'),
            trigger: 'change',
          },
        ],
        productUnit: [
          // 请选择产品单位
          {
            required: true,
            message: this.$t('rules.M2007'),
            trigger: 'change',
          },
        ],
        material: [
          // 请输入产品材质
          { required: true, message: this.$t('rules.M2008'), trigger: 'blur' },
        ],
        supplierNames: [
          // 请选择供应商
          { required: true, message: this.$t('rules.M2009'), trigger: 'blur' },
        ],
        descriptionEn: [
          // 请输入英文描述
          { required: true, message: this.$t('rules.M2010'), trigger: 'blur' },
        ],
        // 请输入关键词
        keyWord: [
          { required: true, message: this.$t('rules.M2011'), trigger: 'blur' },
        ],
      }
    },
  },
  created() {
    //init 基础数据
    this.init()
  },
  methods: {
    //---------------------------获取基础数据--------------------------start
    init() {
      this.getCategoryListForSelect() //产品类别下拉
      this.getDetailByType6() //单位下拉
      this.getDetailByType7() //产品类型下拉
      this.getDetailByType9() //产线下拉
    },

    //产品类别
    async getCategoryListForSelect() {
      let response = await categoryListForSelect()
      if ((response.code = '000000')) {
        this.categoryOptions = response.data
      }
    },
    //单位下拉
    async getDetailByType6() {
      let response = await getDetailByType({ type: 6 })
      if (response.code === '000000') {
        this.proUnitData = response.data
      }
    },
    //产品类型下拉
    async getDetailByType7() {
      let response = await getDetailByType({ type: 7 })
      if (response.code === '000000') {
        this.proTypeData = response.data
      }
    },
    //产线下拉
    async getDetailByType9() {
      let response = await getDetailByType({ type: 9 })
      if (response.code === '000000') {
        this.proLineData = response.data
      }
    },
    //产品运营下拉
    async getListUserForProductionLine(productSettingId) {
      let response = await listUserForProductionLine({
        productSettingId: productSettingId,
      })
      if (response.code === '000000') {
        this.belongData = response.data
      }
    },

    //---------------------------获取基础数据--------------------------end

    // ----------------------------基本信息----------------------------start
    //类型change
    standardCustomizedChange() {},

    //产线change
    proLineChange(val) {
      //清除归属人，归属人id
      this.$set(this.ruleForm, 'belonger', '')
      this.$set(this.ruleForm, 'belongerId', '')
      let productSettingId = this.proLineData.filter(
        (item) => val === item.nameCn
      )[0].productSettingId
      //获取当前产线的产品运营数据
      this.getListUserForProductionLine(productSettingId)
    },

    //归属人change
    belongerChange() {},

    //供应商选择
    selectSupplierClick() {},

    //供应商新增
    supplierAddClick() {},

    //标签设置
    labelApplyClick() {},

    //开启中文描述
    chineseDescChange(val) {
      //关闭-清空数据
      if (!val) {
        this.ruleForm.descriptionCn = ''
      }
    },

    //开启多规格 change
    specsChange() {},

    // ----------------------------基本信息----------------------------end

    //确认
    saveClick() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          //alert('submit!')
        } else {
          return false
        }
      })
    },
    //返回
    goBack() {},
  },
}
</script>

<style lang="scss" scoped>
.product-row {
  width: 100%;
  height: 100%;
  .rule-form {
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    overflow-y: auto;
    .floor {
      display: flex;
      .floor-left {
        width: 10%;
        text-align: center;
        h6 {
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h6:before {
          content: '';
          display: inline-block;
          width: 2px;
          height: 16px;
          background: #000;
          margin-right: 5px;
        }
      }
      .floor-right {
        flex: 1;
        padding-left: 30px;
        border: 1px solid #ededed;
        padding: 30px;
        padding-left: 0;
        margin-right: 15px;
      }
    }
  }
  .operate {
    position: absolute;
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
