<template>
  <div class="company-msg">
    <el-row :gutter="20" class="company-money">
      <el-col :span="6">
        <h3>{{ companyInfo.tradingVolume || 0 }}</h3>
        <p>
          <!-- 订单成交量 -->
          {{ $t('companydetail.OrderVolume') }}
        </p>
      </el-col>
      <el-col :span="6">
        <h3>{{ companyInfo.tradingMoney || 0 }}</h3>
        <p>
          <!-- 订单总金额 -->
          {{ $t('companydetail.TotalOrderAmount') }}
        </p>
      </el-col>
      <el-col :span="6">
        <h3>{{ companyInfo.amountUnpaid || 0 }}</h3>
        <p>
          <!-- 未收款金额 -->
          {{ $t('companydetail.UnpaidAmount') }}
        </p>
      </el-col>
    </el-row>
    <el-row class="mt30">
      <p class="f_s_15">
        <!-- 客户列表 -->
        {{ $t('companydetail.CustomerList') }}
      </p>
      <el-table class="mt20" border :data="companyInfo.companyInfoVOS">
        <!-- label="序号" -->
        <el-table-column
          type="index"
          :label="$t('companydetail.SerialNumber')"
          align="center"
          width="50"
        />
        <!-- label="客户姓名" -->
        <el-table-column
          prop="customerName"
          align="center"
          :label="$t('companydetail.CustomerName')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.customerName }}</template>
        </el-table-column>
        <!-- label="客户邮箱" -->
        <el-table-column
          prop="email"
          align="center"
          :label="$t('companydetail.CustomerMailbox')"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope" show-overflow-tooltip>
            {{ scope.row.email }}
          </template>
        </el-table-column>
        <!-- label="负责人" -->
        <el-table-column
          prop="belongerName"
          align="center"
          :label="$t('companydetail.ResponsiblePerson')"
          show-overflow-tooltip
        >
            <template slot-scope="scope">{{ getName(scope.row, 'belongerName') }}</template>
        </el-table-column>
        <!-- label="询盘次数" -->
        <el-table-column
          prop="enquiryNum"
          align="center"
          :label="$t('companydetail.NumberOfEnquiries')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.enquiryNum }}</template>
        </el-table-column>
        <!-- label="订单成交量" -->
        <el-table-column
          prop="orderTradingVolume"
          align="center"
          :label="$t('companydetail.OrderVolume')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.orderTradingVolume }}
          </template>
        </el-table-column>
        <!-- label="订单总金额" -->
        <el-table-column
          prop="totlePrice"
          align="center"
          :label="$t('companydetail.TotalOrderAmount')"
        >
          <template slot-scope="scope">{{ scope.row.totlePrice }}</template>
        </el-table-column>
        <!-- label="未收款金额" -->
        <el-table-column
          prop="amountUnpaid"
          align="center"
          :label="$t('companydetail.UnpaidAmount')"
        >
          <template slot-scope="scope">{{ scope.row.amountUnpaid }}</template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="mt30" style="margin-bottom: 100px">
      <p class="f_s_15">
        <!-- 付款方式 -->
        {{ $t('companydetail.PaymentMethod') }}
      </p>
      <el-table class="mt20" border :data="companyInfo.companyPayVOS">
        <!-- label="序号" -->
        <el-table-column
          type="index"
          :label="$t('companydetail.SerialNumber')"
          align="center"
          width="50"
        />
        <!-- label="金额区间" -->
        <el-table-column
          prop="paySection"
          align="center"
          :label="$t('companydetail.AmountRange')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ lang === 'en' ? scope.row.paySectionEn : scope.row.paySection }}
          </template>
        </el-table-column>
        <!-- label="付款方式" -->
        <el-table-column
          prop="payMethod"
          align="center"
          :label="$t('companydetail.PaymentMethod')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.payMethod }}</template>
        </el-table-column>
        <!-- label="负责人" -->
        <el-table-column
          prop="belongerName"
          align="center"
          :label="$t('companydetail.ResponsiblePerson')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ getName(scope.row, 'belongerName') }}</template>
        </el-table-column>
      </el-table>
    </el-row>
    <div class="operate">
      <el-button @click="closeClick">
        <!-- 关闭 -->
        {{ $t('companydetail.Close') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { companyDetail } from '@/api/client-supplier/client-manage'
import { mapGetters } from 'vuex'
import nameMixin from '@/utils/name-mixin'

export default {
  name: 'ClientManageCompanyMsg',
  mixins:[nameMixin],
  props: {
    companyId: { type: String, default: '' },
  },
  data() {
    return {
      companyInfo: {}, //公司信息
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  watch: {
    companyId: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) return
        this.getCompanyDetail(value)
      },
    },
  },
  methods: {
    //公司详情
    async getCompanyDetail(value) {
      let response = await companyDetail({ companyId: value })
      if (response.code === '000000') {
        if (response?.data?.companyPayVOS) {
          response.data.companyPayVOS = response.data.companyPayVOS.map(
            (payItem) => {
              if (payItem.paySection) {
                payItem.paySectionEn = payItem.paySection.replace('全部', 'All')
              }
              return payItem
            }
          )
        }
        this.companyInfo = response.data
      }
    },

    // searchClick
    closeClick() {
      this.companyInfo = {}
      this.companyId = ''
      this.$emit('closeClick')
    },
  },
}
</script>

<style lang="scss" scoped>
.company-msg {
  padding: 20px;
  .company-money {
    > .el-col {
      border: 1px solid #ededed;
      padding: 20px;
      h3 {
        font-size: 24px;
      }
      p {
        margin-top: 10px;
      }
    }
    > .el-col + .el-col {
      margin-left: 2%;
    }
    > .el-col:nth-child(3) {
      h3 {
        color: red;
      }
    }
  }
}
.operate {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ededed;
  padding: 20px;
  padding-left: 0;
}
</style>
<style lang="scss" scoped>
.search_form .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}
::v-deep {
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 30px 0 28px 0 !important;
  }
  .el-drawer__body {
    height: calc(100% - 44px);
  }
}
</style>
