<template>
  <!-- 报价产品管理 -->
  <el-dialog
    width="1000px"
    :title="$t('inquiryDetails.QuoteProductManagement')"
    :visible.sync="modelVisible"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <!-- 添加产品 -->
    <el-button type="primary" @click="add">
      {{ $t('inquiryDetails.AddProduct') }}
    </el-button>

    <el-table
      border
      v-if="tableVisible"
      class="mt15 list-table"
      :data="tableData"
      :cell-style="{ 'text-align': 'center' }"
      :header-cell-style="{ 'text-align': 'center' }"
      :Key="tableKey"
    >
      <!-- 序号 -->
      <el-table-column
        :label="$t('inquiryDetails.SerialNumber')"
        width="60"
        :key="tableKey"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <!-- 拖动排序 -->
      <el-table-column :label="$t('inquiryDetails.DragTheSorting')" :width="80">
        <template>
          <div class="sort">
            <i class="el-icon-sort"></i>
          </div>
        </template>
      </el-table-column>
      <!-- 产品编码 -->
      <el-table-column
        prop="productCode"
        :label="$t('productCenter.ProductCode')"
        width="120"
      ></el-table-column>
      <!-- 英文名称 -->
      <el-table-column
        prop="productEn"
        :label="$t('productCenter.EnglishName')"
      ></el-table-column>
      <!-- 产品类型 -->
      <el-table-column
        prop="standardCustomized"
        :label="$t('productCenter.ProductType')"
        width="180"
      >
        <template slot-scope="scope">
          {{ productTypeTransl(scope.row.standardCustomized || '订制品') }}
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column :label="$t('addInquiry.Operate')" width="140">
        <template slot-scope="scope">
          <div class="btns">
            <!-- 复制 -->
            <span @click="Copy(scope.$index)">{{ $t('other.copy') }}</span>
            <!-- 删除 -->
            <span @click="Del(scope.$index)" v-show="tableData.length > 1">
              {{ $t('other.delete') }}
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="text-center mt20">
      <!-- 取消 -->
      <el-button @click="close()">{{ $t('inquiryList.Cancel') }}</el-button>
      <!-- 保存 -->
      <el-button @click="save()" type="primary">
        {{ $t('inquiryList.Save') }}
      </el-button>
    </div>

    <!-- 添加产品 -->
    <el-dialog
      width="720px"
      :title="$t('inquiryDetails.AddProduct')"
      :visible.sync="productVisible"
      :before-close="productClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="140px">
        <el-form-item
          :label="$t('addInquiry.SelectProduct')"
          prop="codeOrName"
          :rules="[
            {
              required: true,
              message: $t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model.trim="codeOrName"
            v-loadmore:el-select-dropdown__list="loadMore"
            filterable
            remote
            reserve-keyword
            clearable
            class="w100"
            :placeholder="$t('placeholder.plsInput')"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="productHandleSelect"
            popper-class="bank-select"
          >
            <el-option
              v-for="item in productOptions"
              :key="item.productId"
              :label="item.value"
              :value="item.productId"
            ></el-option>
          </el-select>

          <div class="text-center mt30">
            <!-- 取消 -->
            <el-button @click="productClose()">
              {{ $t('inquiryList.Cancel') }}
            </el-button>
            <!-- 保存 -->
            <el-button @click="addSave()" type="primary">
              {{ $t('inquiryList.Save') }}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import Sortable from 'sortablejs'
  import {
    getEnquiryProductDetail,
    listEnquiryProductByParam,
  } from '@/api/product/productList'
  import { inquiryInteractor } from '@/core/interactors/inquiry/inquiry'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  export default {
    data() {
      return {
        codeOrName: '', //根据产品名称、编码进行搜索
        form: {},
        rows: {},
        rules: {},
        tableData: [],
        productOptions: [], // 可选产品列表
        sortable: null,
        tableKey: Math.random(),
        modelVisible: false,
        productVisible: false,
        tableVisible: true,
        loading: false,
        // 印刷方式默认配置
        defaultPrint: [
          {
            printingWayId: 'Blank',
            nameCn: 'Blank',
            nameEn: 'Blank',
          },
          {
            printingWayId: 'Sample',
            nameCn: 'Sample',
            nameEn: 'Sample',
          },
        ],
        changeTable: [],
        pageLe: 30,
        pageNo: 1,
        total: 0,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },
    watch: {
      modelVisible(val) {
        if (val) {
          this.$nextTick(() => {
            this.setSort()
          })
        }
      },
    },
    mounted() {},
    methods: {
      // 产品类型转化
      productTypeTransl(str) {
        if (this.lang === 'en') {
          if (str === '标准品') {
            return 'Standard Product'
          } else if (str === '订制品') {
            return 'Customized Product'
          }
        }
        return str
      },

      // 添加产品
      add() {
        this.productVisible = true
      },

      // 复制
      Copy(i) {
        const rows = JSON.parse(JSON.stringify(this.tableData[i]))
        rows.id = ''
        rows.priceSheetId = ''
        this.tableData.splice(i + 1, 0, rows)
        this.changeTable = this.tableData
      },

      // 删除
      Del(i) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          this.tableData.splice(i, 1)
          this.changeTable = [...this.tableData]
        })
      },

      // 保存
      save() {
        this.tableData = this.changeTable
        this.$emit('offerProductSave', this.changeTable)
        this.tableData = []
        this.close()
      },

      // 取消
      close() {
        this.modelVisible = false
      },

      // 新增产品保存
      addSave() {
        if (!this.codeOrName) {
          this.$message.warning(this.$t('addInquiry.PSP'))
          return false
        }
        this.getPorductsInfo(this.rows.productId)
      },

      // 新增产品取消
      productClose() {
        this.codeOrName = ''
        this.rows = {}
        this.productVisible = false
      },

      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },
      async remoteMethod(query) {
        this.loading = true
        this.codeOrName = query && query.trim()
        this._getOptions()
      },
      _reset() {
        this.pageNo = 1
        this.total = 0
        this.productOptions = []
      },
      _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        inquiryInteractor
          .listEnquiryProductByParam(this.codeOrName, this.pageLe, this.pageNo)
          .then((res) => {
            if (res && res.code === '000000') {
              if (Array.isArray(res?.data.data)) {
                res.data.data = res.data.data.map((item) => {
                  return {
                    ...item,
                    value:
                      item.productCode +
                      ' / ' +
                      (item.nameEn ? item.nameEn : ''),
                  }
                })
              }
              this.loading = false
              this.productOptions = loadMore
                ? this.productOptions.concat(res.data.data)
                : res.data.data
              this.total = res.data.total
            } else {
              this.loading = false
            }
          })
      },

      // 选择产品
      productHandleSelect(item) {
        if (item) {
          this.getProductDetail(item)
        }
      },

      //产品详情
      async getProductDetail(productId) {
        let res = await getEnquiryProductDetail({ param: productId })
        if (res?.code == '000000' && res?.data) {
          const data = res.data

          this.rows = {
            ...data,
            specsValue1: '', // 规格1
            specsValue2: '', // 规格2
            productUrl: data.imgMain, // 产品图片
            productEn: data.nameEn, // 产品英文名称
            productCn: data.nameCn, // 产品中文名称
            productOperation: data.belonger, // 产品运营
            productOperationEn: data.belongerEn, // 产品运营英文
            productOperationId: data.belongerId, // 产品运营id
            shippingCountry: 'US', // 默认美国
            deliveryToTheDoor: 1,
            informationDTOS: [],
          }
        }
      },

      // 获取产品基本信息
      async getPorductsInfo(id) {
        let res = await inquiryInteractor.getEnquiryProductDetailList(id)

        this.tableVisible = false
        if (res && res.code === '000000') {
          const data = res.data[0]
          const _ways = data?.productPrintingWayVOList
          const _specs = data?.productSpecsEditVOList?.map((item) => {
            return {
              ...item,
              boxLengthCm: item.boxLengthCm || 0,
              boxWidthCm: item.boxWidthCm || 0,
              boxHeightCm: item.boxHeightCm || 0,
            }
          }) || [{}]

          this.rows = Object.assign({}, this.rows, {
            specsType: data.specsType,
            specsValue: data.specsValue,
            selectQuantity: '', // 报价数量
            material: data.material, // 材质
            exchange: data.exchange, // 汇率
            productPrintingWayVOList: _ways, // 印刷信息
            printingWay: '',
            printingWayEn: '',
            printingWayId: '',
            waySelect: '',
            digitSelect: '',
            printingPosition: '',
            printingWayOptions: this.getWayOptions(_ways, 1), // 印刷方式
            waySelectOptions: this.getWayOptions(_ways, 2), // 印刷细节
            digitSelectOptions: this.getWayOptions(_ways, 3), // 印刷位置
            productPricingStepVOList: data.productPricingStepVOList, // 默认阶梯
            productSpecsEditVOList: _specs, // 产品尺寸集合
            specsDTOS: [_specs[0]], // 产品尺寸默认显示第一条
          })
          // 默认带出第一条sku, 选择规格的时候会更新
          this.rows.sku = this.rows.specsDTOS?.length
            ? this.rows.specsDTOS[0].sku
            : ''
          this.tableData = this.tableData.concat([this.rows])
          this.tableVisible = true
          this.productClose()
          this.$nextTick(() => {
            this.setSort()
          })
        }
      },

      /**
       * 印刷方式,印刷细节,印刷位置下拉项数据处理
       * @param _arr 数据源
       * @param n 数据类型 1:印刷方式 2:印刷细节 3:印刷位置
       */
      getWayOptions(_arr, n) {
        let _res = []
        switch (n) {
          case 1:
            if (_arr?.length) {
              _res = _arr.map((item) => {
                return {
                  printingWayId: item.printingWaySelectId,
                  nameCn: item.printingWaySelect,
                  nameEn: item.printingWaySelectEn,
                }
              })
            }
            _res = _res.concat(this.defaultPrint)
            return _res
          case 2:
            if (_arr?.length) {
              const waySelect = JSON.parse(_arr[0].printingWay)
              if (waySelect?.length) {
                _res = waySelect.map((item) => {
                  return {
                    label: item.way,
                    labelEn: item.pricingValueEn,
                    value: item.way,
                  }
                })
                return _res
              } else {
                _res = []
              }
              return _res
            }
          case 3:
            if (_arr?.length) {
              const _len = _arr[0].printingPositions.split(',').length
              for (let i = 0; i < _len; i++) {
                _res.push({
                  label: i + 1,
                  value: i + 1,
                })
              }
              return _res
            }
        }
      },

      // 拖拽
      async setSort() {
        const tbody = document.querySelector('.list-table tbody')
        const _this = this
        this.changeTable = _.cloneDeep(this.tableData)
        await Sortable.create(tbody, {
          onEnd({ newIndex, oldIndex }) {
            const currRow = _this.changeTable.splice(oldIndex, 1)[0]
            _this.changeTable.splice(newIndex, 0, currRow)
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sort {
    text-align: center;
    cursor: all-scroll;

    i {
      font-size: 18px;
    }
  }

  .btns span {
    color: #409eff;
    margin: 0 5px;
    cursor: pointer;
  }

  ::v-deep {
    .el-dialog__body {
      padding: 10px 20px 20px;
    }
  }

  .el-select-dropdown {
    width: 500px !important;
  }
</style>

<style>
  .el-form-item__content .bank-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .bank-select .el-select-dropdown__list {
    height: 300px !important;

    overflow: auto;
    overflow-x: hidden;
  }

  .bank-select .el-select-dropdown__list li {
    width: 530px !important;
  }
</style>
