/***
 * 首页模块接口
 */

import request from '@/core/services/request'
const loading = false

/**
 *  查询首页设置
 */
export function getHomePageConfig(params = {}) {
  return request({
    url: '/system/config/getHomePageConfig',
    method: 'post',
    params,
    loading,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 更新首页设置（勾选状态）
 */
export function updateChooseStatus(data) {
  return request({
    url: '/system/config/updateChooseStatus',
    method: 'post',
    data,
    loading,
  })
}

/**
 *
 * 排序
 */
export function updateDivSort(sortedList) {
  return request({
    url: '/system/config/updateDivSort',
    method: 'post',
    data: {
      sortedList,
    },
    loading,
  })
}

/**
 *
 * 首页询盘报价
 *
 */

export function listHomeEnquiry(data) {
  return request({
    url: '/order/orderEnquiry/listHomeEnquiry',
    method: 'post',
    data,
    loading,
  })
}

/**
 *
 *
 */

// 首页顶部7种单据的待处理数据接口
export function indexPendingOrderNum(url) {
  return request({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    loading,
  }).then(res => {
    if (res && res.code === '000000') {
      return res.data
    } else {
      return 0
    }
  })
}

/***
 * 客户分管
 */
export function merchantsList(data = {}) {
  return request({
    url: '/merchants/managerApply/listPage',
    method: 'post',
    data,
    loading,
  })
}

/**
 * 确认分管
 */

export function agreeBranched(data = {}) {
  return request({
    url: '/merchants/managerApply/agreeBranched',
    method: 'post',
    data,
    loading,
  })
}

/**
 * 客户跟进
 */

export function listFollowAgencyPage(params = {}) {
  return request({
    url: '/merchants/info/listFollowAgencyPage',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    loading,
  })
}

/**
 * 邮件卡片/system/config/homeEmailList/{type}
 *
 *
 */

export function homeEmailList(type) {
  return request({
    url: `/system/config/homeEmailList/${type}`,
    method: 'post',
    params: {
      // pageSize: 99999999,
      pageSize: 20,
      pageNum: 1,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    loading,
  })
}

/***
 * 收款通知
 */
export function listHomeOrder(data = {}) {
  return request({
    url: '/order/salesOrder/listHomeOrder',
    method: 'post',
    data,
    loading,
  })
}

/**
 * 首页采购需求通知代办
 */
export function listHomePurchasingDemand(data = {}) {
  return request({
    url: '/order/purchasingDemandProduct/listHomePurchasingDemand',
    method: 'post',
    data,
    loading,
  })
}

/**
 * 首页询价需求通知代办
 */
export function homeInquiryOrderListPage(data = {}) {
  return request({
    url: '/order/inquiryOrder/homeInquiryOrderListPage',
    method: 'post',
    data,
    loading,
  })
}

/**
 * 首页采购付款通知待办
 */

export function listHomePurchasePayment(data = {}) {
  return request({
    url: '/order/purchaseOrder/listHomePurchasePayment',
    method: 'post',
    data,
    loading,
  })
}

/***
 * 收款认领列表
 */
export function collectionClaimList(data = {}) {
  return request({
    url: '/finance/finance/collectionClaimList',
    method: 'post',
    data,
    loading,
  })
}
/***
 * 收款认领-确认
 */
export function collectionClaim(data = {}) {
  return request({
    url: '/finance/finance/collectionClaim',
    method: 'post',
    data,
    loading,
  })
}
/***
 * 收款认领详情
 */
export function collectionClaimDetail(data = {}) {
  return request({
    url: '/finance/finance/collectionClaimDetail',
    method: 'post',
    data,
    loading,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/***
 * 收款确认列表
 */
export function collectionConfirmList(data = {}) {
  return request({
    url: '/finance/finance/collectionConfirmList',
    method: 'post',
    data,
    loading,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
/***
 * 收款单确认
 */
export function collectionConfirm(data = {}) {
  return request({
    url: '/finance/finance/collectionConfirm',
    method: 'post',
    data,
    loading,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/***
 * 收款单确认
 */
export function shipmentEnquiry(data = {}) {
  return request({
    url: '/storage/express/v2/shipmentEnquiry',
    method: 'post',
    data,
    loading,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/***
 * 出入库待办列表(1入库，2出库)
 */
export function listWarehouseTodoList(data = {}) {
  return request({
    url: '/storage/warehouse/listWarehouseTodoList',
    method: 'post',
    data,
    loading,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/***
 * 入库异常
 */
export function listHomePurchaseWarehouseInAbnormal(data = {}) {
  return request({
    url: '/order/purchaseOrder/listHomePurchaseWarehouseInAbnormal',
    method: 'post',
    data,
    loading,
  })
}

/**
 * 订单跟踪待办
 */

export function orderTrackListTodo(data = {}) {
  return request({
    url: '/system/systemTask/listAll',
    method: 'post',
    data,
    loading,
  })
}

/**
 * 首页客诉列表
 */

export function needToBe(data = {}) {
  return request({
    url: '/merchants/customerComplain/needToBe',
    method: 'post',
    data,
    loading,
  })
}


