<template>
  <el-dialog
    :title="title"
    :append-to-body="true"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item
        label="中文名称"
        prop="nameCn"
        :rules="[
          {
            required: title === '单位设置',
            trigger: 'blur',
            message: '请输入中文名称',
          },
        ]"
      >
        <el-input v-model="form.nameCn" placeholder="请输入" />
      </el-form-item>
      <el-form-item prop="nameEn" label="英文名称">
        <el-input v-model="form.nameEn" placeholder="请输入" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/product/productSetting'
  export default {
    name: 'PublicModel',
    data() {
      return {
        loading: false,
        title: '设置',
        form: {
          nameCn: '',
          nameEn: '',
          productSettingId: '',
          type: '',
          value: '',
        },
        rules: {
          nameEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //展示弹框
      showAddEdit(str, row) {
        if (str === 'packing') {
          this.title = '包装方式设置'
          this.form.type = 3
        } else if (str === 'material') {
          this.title = '产品材质设置'
          this.form.type = 4
        } else if (str === 'print') {
          this.title = '印刷位设置'
          this.form.type = 5
        } else if (str === 'unit') {
          this.title = '单位设置'
          this.form.type = 6
        }
        if (!row) {
        } else {
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.dialogFormVisible = false
        this.$refs['form'].resetFields()
        this.form = {
          nameCn: '',
          nameEn: '',
          productSettingId: '',
          type: '',
          value: '',
        }
      },
      // 保存
      async save() {
        let form = this.utils.deepCopy(this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let response
            this.loading = true
            if (form.productSettingId === '') {
              response = await insertPO(form)
              this.loading = false

              if ((response.code = '000000')) {
                this.$baseMessage(
                  '新增成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              response = await updatePO(form)
              this.loading = false

              if ((response.code = '000000')) {
                this.$baseMessage(
                  '编辑成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.$emit('public-model', form.type)
            this.close()
          }
        })
      },
    },
  }
</script>
