var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"oper"},[_c('el-row',[_c('el-page-header',{attrs:{"content":"结算人员配置角色新增","title":"返回"},on:{"back":_vm.goBack}})],1),_c('el-row',{staticClass:"m30",attrs:{"gutter":20}},[_c('el-col',{staticClass:"item-title",attrs:{"span":1}},[_vm._v("角色")]),_c('el-col',{staticClass:"m10",attrs:{"span":23,"offset":1}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.roleTable,"border":""}},[_c('el-table-column',{attrs:{"prop":"area","align":"center","width":"80","label":"区域","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"standardCustomized","align":"center","width":"80","label":"产品类型","show-overflow-tooltip":""}}),_vm._l((_vm.roleList),function(item){return _c('el-table-column',{key:item.code,attrs:{"prop":item.code,"label":item.message,"min-width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row[_vm.getRoleCode(item.message)])?_c('div',[_c('span',[_vm._v(" "+_vm._s(scope.row[_vm.getRoleCode(item.message)].mark)+" ")])]):_c('div',[_vm._v("--")])]}}],null,true)})})],2)],1)],1),_c('el-row',{staticClass:"m30",attrs:{"gutter":20}},[_c('el-col',{staticClass:"item-title",attrs:{"span":1}},[_vm._v("人员")]),_c('el-col',{staticClass:"m10",attrs:{"span":23,"offset":1}},[_c('el-form',{ref:"ruleForm",attrs:{"rules":_vm.rules,"model":_vm.form}},[_c('el-table',{staticClass:"input-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.form.tableData,"border":""}},[_c('el-table-column',{attrs:{"type":"selection","width":"55","align":"center"}}),_c('el-table-column',{attrs:{"min-width":"100","align":"center","label-class-name":"required-th","label":"员工姓名（英文）"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.personnoelAllName',"rules":[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'change',
                  } ]}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请选择"},on:{"focus":function($event){return _vm.fromBusinessChange(scope)},"clear":function($event){return _vm.clearFromBusinessName(scope)}},model:{value:(scope.row.personnoelAllName),callback:function ($$v) {_vm.$set(scope.row, "personnoelAllName", $$v)},expression:"scope.row.personnoelAllName"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"条件","min-width":"250","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"placeholder":"请选择","clearable":""},on:{"focus":function($event){return _vm.setCondition(scope)},"clear":function($event){return _vm.clearRoleCondition(scope)}},model:{value:(scope.row.conditionDesc),callback:function ($$v) {_vm.$set(scope.row, "conditionDesc", $$v)},expression:"scope.row.conditionDesc"}})]}}])}),_c('el-table-column',{attrs:{"align":"center","min-width":"160px","label":"条件提成点"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.configCommissionDesc',"rules":[
                  {
                    required: scope.row.conditionDesc,
                    message: '请选择',
                    trigger: 'change',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"请选择","clearable":""},on:{"focus":function($event){return _vm.setAppoint(scope)},"clear":function($event){return _vm.clearCommission(scope)}},model:{value:(scope.row.configCommissionDesc),callback:function ($$v) {_vm.$set(scope.row, "configCommissionDesc", $$v)},expression:"scope.row.configCommissionDesc"}})],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","min-width":"160px","label":"条件角色"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.roleType',"rules":[
                  {
                    required: scope.row.conditionDesc,
                    message: '请选择',
                    trigger: 'change',
                  } ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(scope.row.roleType),callback:function ($$v) {_vm.$set(scope.row, "roleType", $$v)},expression:"scope.row.roleType"}},_vm._l((scope.row.conditionRoleList),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.message,"value":item.code}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","min-width":"160px","label-class-name":"required-th","label":"模板生效时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.effectiveTime',"rules":[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'change',
                  } ]}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"请选择","value-format":"yyyy-MM-dd"},model:{value:(scope.row.effectiveTime),callback:function ($$v) {_vm.$set(scope.row, "effectiveTime", $$v)},expression:"scope.row.effectiveTime"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"medium"},on:{"click":function($event){return _vm.deleteItem(scope.$index)}}},[_vm._v(" 删除 ")])]}}])})],1)],1)],1),_c('el-col',{attrs:{"span":1,"offset":11}},[_c('el-button',{attrs:{"type":"text","size":"medium"},on:{"click":_vm.pushNew}},[_vm._v(" 新增一行 ")])],1)],1),_c('el-row',{staticClass:"stick30",staticStyle:{"margin-top":"60px"}},[_c('el-col',{attrs:{"offset":11,"span":1}},[_c('el-button',{on:{"click":_vm.goBack}},[_vm._v("返回")])],1),_c('el-col',{attrs:{"span":1}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")])],1)],1),_c('condititon-model',{attrs:{"modelVisible":_vm.modelVisible},on:{"update:modelVisible":function($event){_vm.modelVisible=$event},"update:model-visible":function($event){_vm.modelVisible=$event},"submitCondition":_vm.submitCondition}}),_c('person-condition-appoint',{attrs:{"appointVisible":_vm.appointVisible},on:{"update:appointVisible":function($event){_vm.appointVisible=$event},"update:appoint-visible":function($event){_vm.appointVisible=$event},"setRow":_vm.setRow}}),_c('UserChoose',{ref:"UserChoose",on:{"choose-user":_vm.chooseUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }