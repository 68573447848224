<template>
  <!-- 确认报价单 -->
  <el-dialog :title="$t('other.ConfirmQuotation')" width="900px" :visible.sync="offerSheetVisible"
    :close-on-click-modal="false" :before-close="close" top="5vh">
    <el-row class="offer-top">
      <el-col :span="12">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <!-- 报价单号 -->
          <el-form-item :label="$t('inquiryList.QuotationNumber')" prop="offerSheetId" :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'change',
              },
            ]">
            <el-select v-model="form.offerSheetId" :placeholder="$t('placeholder.plsSel')" @change="offerSheetChange">
              <el-option v-for="item in offerSheetList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>

      <el-col :span="12" class="text-right">
        <!-- 复制报价 -->
        <el-button type="primary" @click="copyOffer">
          {{ $t('inquiryDetails.CopyQuote') }}
        </el-button>
        <el-button type="primary" v-if="offerData.status == 1" @click="generatePdf(offerData.priceSheetCode)">
          {{ lang === 'en' ? 'Send mail' : '发送邮件' }}
        </el-button>
        <!-- 下载报价单 -->
        <el-dropdown @command="handleCommand">
          <el-button class="ml10">
            <!-- 下载报价单 -->
            {{ $t('inquiryDetails.Downloadquotation') }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="PDF">
              <!-- pdf文件 -->
              {{ $t('inquiryDetails.pdffile') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 打印报价单 -->
        <el-button type="primary" @click="printOfferPdf" style="margin-left: 10px">
          {{ $t('other.PrintQuotation') }}
        </el-button>
      </el-col>
    </el-row>

    <!-- 报价单内容组件 -->
    <OfferSheetContent :offerData="offerData" id="offerSheetContent" style="padding: 0; width: 834px" />

    <div class="text-center mt20">
      <!-- 生成订单 -->
      <el-button type="primary" @click="createOrder()" v-if="mailSend && lang != 'en'">
        {{ $t('inquiryList.GenerateOrders') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="close()">{{ $t('addInquiry.Cancel') }}</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[imgUrl]" />
  </el-dialog>
</template>

<script>
  import {
    orderPriceSheetCodeNew as orderPriceSheetCode,
    getPriceSheetDetail,
    orderSaleOrderByPriceSheetCode,
  } from '@/api/order.js'
  import OfferSheetContent from './offer-sheet-content'
  import handleOtherFee, { getproductStyle } from '../helper/util'
  import { mapGetters } from 'vuex'
  import InquiryPrint from '../inquiryPrint.vue'
  import { getNowDate } from '@/utils/index'
  import { uploadPdfFile } from '@/api/mail/mail.js'
  import * as clipboard from 'clipboard-polyfill'
  export default {
    props: {
      mailSend: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        enquiryId: '',
        offerSheetVisible: false,
        form: {
          offerSheetId: '',
        },
        offerSheetList: [],
        offerData: {},
        rules: {},
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        isShowPrint: false,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    components: {
      OfferSheetContent,
      InquiryPrint,
    },
    created() { },
    methods: {
      generatePdf(id) {
        this.$emit('send-mail', id)
        return
      },
      printOfferPdf() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const query = {
              enquiryIds: self.enquiryId,
              priceSheetCode: self.form.offerSheetId,
            }

            this.$emit('print', this.offerData.priceSheetCode)
          } else {
            return false
          }
        })
      },
      // 下载pdf
      downloadPdf() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const query = {
              enquiryIds: self.enquiryId,
              priceSheetCode: self.form.offerSheetId,
            }
            self.$emit('downloadpdf', self.offerData.priceSheetCode)
          } else {
            return false
          }
        })
      },
      // html 转成 PDF
      domToPdf(customerEmail) {
        let title = `quotation-${getNowDate()}`
        this.getPdfMultiplePage(title, 'pdfEl2', false).then((res) => {
          //res拿到base64的pdf
          let pdfBase64Str = res
          let title = `quotation-${getNowDate()}`
          var myfile = this.dataURLtoFile(pdfBase64Str, title + '.pdf') //调用一下下面的转文件流函数
          var formdata = new FormData()
          formdata.append('file', myfile) // 文件对象
          formdata.append('flagSaveName', 1)
          formdata.append('source', 1)
          formdata.append('uploadType', 1)
          //该uploadMy为接口，直接以formdata格式传给后台

          uploadPdfFile(formdata)
            .then((res) => {
              let file = {
                enclosureName: res.data.fileName,
                fileId: res.data.fileId,
                enclosureSize: res.data.size,
                enclosureUrl: res.data.path,
                suffix: res.data.suffix,
                uploadType: 1,
                mailEnclosureId: res.data.fileId,
                source: 1,
              }
              let url = ''
              if (this.mailDetail) {
                // 从邮件直接生成报价单
                url = `${window.location.origin}/#/write?from=business&mailId=${this.mailDetail.baseInfo.mailId
                  }&file=${JSON.stringify([file])}&email=${this.mailDetail.baseInfo.sendEmail
                  }`
              } else {
                url = `${window.location.origin
                  }/#/write?from=business&file=${JSON.stringify(
                    file
                  )}&email=${customerEmail}`
              }
              // this.$router.push({
              //   path: '/order/quotations-list',
              // })
              this.utils.openWindow(url)
            })
            .catch((err) => { })
            .finally(() => {
              this.isShowPrint = false
            })
        })
      },
      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
      },
      // 获取下拉选项
      getSelectList(id, priceSheetCode) {
        this.enquiryId = id
        const reqData = { enquiryId: id }
        if (priceSheetCode) {
          reqData.priceSheetCode = priceSheetCode
        }
        orderPriceSheetCode(reqData).then((res) => {
          this.offerSheetList = res.data
          this.form.offerSheetId = res.data[0]
          this.offerSheetChange(this.form.offerSheetId)
        })
      },

      // 报价单详情
      offerSheetChange(val) {
        const params = {
          // enquiryId: this.enquiryId,
          priceSheetCode: val,
        }
        // orderPrintSheet(params).then((res) => {
        //   this.offerData = res.data
        // })
        getPriceSheetDetail(params).then(async (res) => {
          for (let i = 0; i < res.data.priceSheets.length; i++) {
            let item = res.data.priceSheets[i]
            const { informationVOS, otherFeeProps, addPriceProps } =
              handleOtherFee(item.informationVOS)
            item.productStyle = await getproductStyle(item)
            item.informationVOS = informationVOS
            item.otherFeeProps = otherFeeProps
            item.addPriceProps = addPriceProps
          }
          this.offerData = res.data
        })
      },

      // 生成订单
      createOrder() {
        let self = this
        orderSaleOrderByPriceSheetCode({
          priceSheetCode: self.form.offerSheetId,
        }).then((res) => {
          if (res.code === '000000') {
            self.$refs.ruleForm.validate((valid) => {
              if (valid) {
                self.$router.push(
                  `/order/orderList/orderAdd?priceSheetCode=${this.form.offerSheetId}`
                )
              }
            })
          }
        })
      },
      handleCommand(type) {
        if (type === 'PDF') {
          this.downloadPdf()
        }
      },


      // 打印报价单
      printOffer(d) {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const query = {
              enquiryIds: self.enquiryId,
              priceSheetCode: self.form.offerSheetId,
            }

            if (d === true) {
              query.d = '1'
            }
            let routeData = self.$router.resolve({
              path: '/order/inquiryManage/inquiryPrint',
              query,
            })
            window.open(routeData.href, '_blank')
          } else {
            return false
          }
        })
      },

      // 大图预览
      handlePreview(item) {
        this.imgUrl = item
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      // 关闭弹窗
      close() {
        this.$refs.ruleForm.resetFields()
        this.offerSheetVisible = false
      },

      // 格式化 rendingsUrl
      formatRendingsUrl(urls) {
        return urls ? urls.split(',') : []
      },

      // 复制报价
      copyOffer: function (e) {
        var item = new clipboard.ClipboardItem({
          'text/html': new Blob(
            [document.getElementById('offerSheetContent').outerHTML],
            {
              type: 'text/html',
            }
          ),
        })
        clipboard.write([item])
        this.$message.success(this.lang === 'en' ? 'Copy success' : '复制成功')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .offer-top {
    display: flex;
    justify-content: space-between;
  }

  .offer-container {
    height: auto;
    padding: 0 5px 20px 0;
    overflow-y: auto;

    .box {
      .top {
        display: flex;

        >div {
          height: 40px;
          line-height: 40px;
          display: inline-block;
          margin-right: 20px;

          span {
            font-weight: 400;
          }
        }
      }

      .base-info {
        display: flex;

        >div {
          border: 1px solid rgba(234, 235, 237, 1);
          width: calc(50% - 8px);
          padding: 10px;
          line-height: 26px;

          em {
            font-weight: 500;
            font-style: normal;
          }
        }

        >div:last-child {
          margin-left: 16px;
        }
      }
    }

    .wrapper-img {
      display: inline-block;
      margin: 0 5px;

      img {
        width: 90px;
        height: 90px;
        object-fit: cover;
        display: block;
        cursor: pointer;
      }
    }

    p {
      text-align: left;
    }

    .pro-item {
      border: 1px solid #eee;
      margin-top: 10px;
      padding: 5px;
    }

    .item-wrapper {
      .img-wrapper {
        display: flex;
      }

      margin-bottom: 10px;
      margin-top: 10px;

      .info-wrapper {
        .label {
          font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold',
            'PingFang SC', sans-serif;
          font-weight: 650;
          font-style: normal;
          font-size: 14px;
          color: #666666;
          text-align: left;
        }

        .el-row {
          display: flex;

          .el-col {
            border: 1px solid #eee;
            display: flex;
            align-items: center;
            padding: 5px;

            &.nobr {
              border-right: none;
            }

            &.nobt {
              border-top: none;
            }

            &.nobb {
              border-bottom: none;
            }

            &.nobl {
              border-right: none;
            }
          }
        }
      }
    }
  }
</style>