<template>
  <div class="order-product">
    <el-tabs v-model="activeName">
      <el-tab-pane label="产品库" name="1">
        <el-form ref="ruleForm" :model="ruleForm">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item prop="codeOrName">
                <el-input
                  v-model="ruleForm.codeOrName"
                  placeholder="请输入产品名称/编码"
                  maxlength="50"
                  clearable
                  @keyup.enter.native="search"
                />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item prop="categoryId">
                <el-cascader
                  ref="cascaderAddr"
                  v-model="categoryId"
                  :options="categoryList"
                  :props="optionProps"
                  clearable
                  class="w100"
                  :show-all-levels="false"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <div class="text-left">
                <el-button class="pub-h-36" @click="search">搜索</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div class="container">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            height="450"
            :row-key="
              (row) => {
                return row.skuId
              }
            "
            @selection-change="selectionChange"
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              width="40"
            />
            <el-table-column
              align="center"
              label="产品编码"
              prop="skuId"
              width="120"
            >
              <template slot-scope="scope">
                <div class="page-link">
                  <router-link
                    :to="`/product/ProductView?proId=${
                      scope.row.productId
                    }&noReturn=${true}`"
                    target="_blank"
                  >
                    {{ scope.row.skuId }}
                  </router-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="200"
              align="center"
              label="产品名称"
              prop="nameCn"
              show-overflow-tooltip
            />
            <el-table-column
              width="180"
              align="center"
              label="产品类别"
              prop="categoryName"
              v-if="columnsHidden"
            />
            <el-table-column
              width="180"
              align="center"
              label="产品类型"
              prop="standardCustomized"
              v-if="columnsHidden"
            />
            <el-table-column align="center" label="规格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{
                  formatColumn(
                    scope.row.specsType,
                    scope.row.specsValue1,
                    scope.row.specsValue2
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              width="180"
              align="center"
              label="可用库存"
              show-overflow-tooltip
              v-if="columnsHidden"
            >
              <template slot-scope="scope">
                <span
                  v-if="
                    scope.row.stockWarehouseNumVOList &&
                    scope.row.stockWarehouseNumVOList.length > 1
                  "
                >
                  <el-popover
                    v-if="scope.row.stockWarehouseNumVOList.length > 1"
                    placement="right"
                    width="200"
                    trigger="hover"
                    :content="formatStock(scope.row.stockWarehouseNumVOList)"
                  >
                    <span slot="reference">
                      {{
                        formatStock(
                          scope.row.stockWarehouseNumVOList.slice(0, 1)
                        )
                      }}
                      <span class="more">
                        等{{ scope.row.stockWarehouseNumVOList.length }}个
                      </span>
                    </span>
                  </el-popover>
                </span>
                <span v-else>
                  {{ formatStock(scope.row.stockWarehouseNumVOList) }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-pagination
          :current-page="page.pageNo"
          :page-size="page.pageLe"
          :total="total"
          @current-change="currentChange"
        />
      </el-tab-pane>
      <el-tab-pane label="辅料库" name="2">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-input
              v-model="queryString"
              placeholder="搜索产品名称/产品编码"
              maxlength="50"
              clearable
            />
          </el-col>
          <el-col :span="3">
            <div class="text-left">
              <el-button @click="search2">搜索</el-button>
            </div>
          </el-col>
        </el-row>

        <!-- 辅料库Table -->
        <el-table
          class="mt10"
          ref="multipleTable2"
          border
          :data="tableData2"
          :row-key="
            (row) => {
              return row.accessoriesNumber
            }
          "
          @selection-change="selectionChange2"
        >
          <el-table-column
            type="selection"
            :reserve-selection="true"
            width="40"
          />
          <el-table-column align="center" label="产品编码" width="120">
            <template slot-scope="scope">
              <div class="page-link">
                {{ scope.row.accessoriesNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="产品图片"
            prop="productImg"
            width="100"
          >
            <template slot-scope="scope">
              <div class="img-wrap">
                <el-badge
                  v-show="
                    scope.row.productImg &&
                    scope.row.productImg.split(',').length > 1
                  "
                  :value="
                    scope.row.productImg
                      ? scope.row.productImg.split(',').length
                      : ''
                  "
                ></el-badge>
                <el-image
                  v-if="scope.row.productImg"
                  :src="scope.row.productImg.split(',')[0]"
                  class="c_pointer"
                  @click="handlePreview(scope.row.productImg.split(','))"
                ></el-image>
                <span v-else>--</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="产品名称"
            prop="nameCn"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            label="库存"
            prop="nameCn"
            width="300"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.warehouseStockList &&
                  scope.row.warehouseStockList.length > 1
                "
              >
                <el-popover
                  v-if="scope.row.warehouseStockList.length > 1"
                  placement="right"
                  width="200"
                  trigger="hover"
                  :content="formatStock(scope.row.warehouseStockList)"
                >
                  <span slot="reference">
                    {{ formatStock(scope.row.warehouseStockList.slice(0, 1)) }}
                    <span class="more">
                      等{{ scope.row.warehouseStockList.length }}个
                    </span>
                  </span>
                </el-popover>
              </span>
              <span v-else>
                {{ formatStock(scope.row.warehouseStockList) }}
              </span>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          :current-page="page2.pageNo"
          :page-size="page2.pageLe"
          :total="total2"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          @current-change="pageChange2"
          @size-change="sizeChange2"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </el-tab-pane>
    </el-tabs>

    <div class="text-center mt20">
      <el-button type="primary" @click="save()">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
  import purchaseDemandInterator from '@/core/interactors/purchase/purchaseDemand'
  import { categoryListForSelect } from '@/api/product/productPrintWay'
  export default {
    props: {
      selectProductList: {
        type: Array,
        default: () => [],
      },
      purchaseDemandAddFlag: {
        type: Number,
        required: false,
      },

      requestType: {
        type: Boolean,
        default: false,
      },
      columnsHidden: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        activeName: '1',
        tableData: [],
        categoryList: [],
        optionProps: {
          value: 'productCategoryId',
          label: 'nameCn',
          children: 'children',
          checkStrictly: true,
        },
        page: {
          pageLe: 10,
          pageNo: 1,
        },
        total: 0,
        ruleForm: {
          codeOrName: '',
          categoryId: '',
        },
        categoryId: '',
        selectRows1: [], // 产品库选中的数据
        selectRows2: [], // 辅料库选中的数据
        selectRowsAll: [],
        queryString: '', // 辅料库搜索参数
        page2: {
          pageLe: 10,
          pageNo: 1,
        },
        total2: 0,
        tableData2: [],
        imgUrl: [], //图片预览url
        showViewer: false, //图片预览参数
      }
    },
    created() {
      this.getList()
      this.getList2()
      this.getDict()
    },
    methods: {
      // 获取下拉字典项
      getDict() {
        categoryListForSelect().then((res) => {
          this.categoryList = res.data || []
        })
      },

      async getList() {
        const params = {
          ...this.page,
          ...this.ruleForm,
        }
        if (this.purchaseDemandAddFlag) {
          params.flag = this.purchaseDemandAddFlag
        }
        const res = this.requestType
          ? await purchaseDemandInterator.selectProductList(params)
          : await purchaseDemandInterator.productGetSkuByProductId(params)

        if (res && res.code) {
          this.tableData = res.data.data || []
          this.total = res.data.total || 0
        }
      },

      // 获取辅料库
      async getList2() {
        const params = {
          ...this.page2,
          queryString: this.queryString,
        }
        const res = await purchaseDemandInterator.subProductList(params)

        if (res && res.code === '000000') {
          this.tableData2 = res?.data?.list || []
          this.total2 = res?.data?.total || 0
        }
      },

      // 搜索
      search() {
        this.ruleForm.categoryId = this.categoryId[this.categoryId.length - 1]
        this.page.pageNo = 1
        this.getList()
      },

      // 辅料库搜索
      search2() {
        this.page2.pageLe = 10
        this.page2.pageNo = 1
        this.getList2()
      },

      // 数据格式处理
      formatColumn(type, value1, value2) {
        if (type) {
          const arr = type.split(',')
          if (!arr[0]) {
            return '/'
          } else if (arr[0] && arr.length == 1) {
            return arr + ':' + value1
          } else {
            return arr[0] + ':' + value1 + '，' + arr[1] + ':' + value2
          }
        } else {
          return ''
        }
      },

      // 选中
      selectionChange(rows) {
        this.selectRows1 = rows
      },

      // 辅料库复选框勾选
      selectionChange2(rows) {
        this.selectRows2 = rows.map((item) => {
          return {
            ...item,
            sonProductType: '1',
            productCode: item.accessoriesNumber,
            productId: item.productAccessoriesId,
            sku: item.accessoriesNumber,
            imgMain: item.productImg,
          }
        })
      },

      // 切换分页
      currentChange(val) {
        this.page.pageNo = val
        this.getList()
      },

      // 辅料库分页切换
      pageChange2(val) {
        this.page2.pageNo = val
        this.getList2()
      },

      // 辅料库页码切换
      sizeChange2(val) {
        this.page2.pageLe = val
        this.getList2()
      },

      // 确认
      save() {
        if (!this.selectRows1.length && !this.selectRows2.length) {
          this.$message.warning('至少选择一条产品')
          return false
        }
        let data = []
        data = this.selectRows1.map((item) => {
          return {
            ...item,
            specification: this.formatColumn(
              item.specsType,
              item.specsValue1,
              item.specsValue2
            ),
          }
        })
        this.$emit('getProductData', data, this.selectRows2)
      },

      // 关闭
      close() {
        this.$emit('productClose')
        this.$refs['ruleForm'].resetFields()
      },

      // 格式化库存数据
      formatStock(stockData) {
        if (!stockData) {
          return '--'
        }
        let isArray = (stockData.constructor = Array)
        let __stockInfo = []

        if (isArray && stockData.length > 0) {
          for (let i = 0; stockData.length > i; i++) {
            __stockInfo.push(
              `${stockData[i].warehouseName}:${
                stockData[i].num || stockData[i].warehouseNum
              }`
            )
          }
        } else {
          __stockInfo.push(`/`)
        }
        return __stockInfo.toString()
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .order-product {
    margin-top: -20px;
    .el-table {
      max-height: 450px;
      overflow-y: auto;
    }
  }
  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;
    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }
    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }
  .more {
    color: #a0a0a0;
  }
</style>
