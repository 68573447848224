import { StoreCheck } from '@/core'

const mixin = {
  data() {
    return {
      warehouseList: []
    }
  },
  methods: {
    // 数据格式处理
    formatColumn(type, value1, value2) {
      const arr = type.split(',')
      if (!arr[0]) {
        return '/'
      } else if (arr[0] && arr.length == 1) {
        return arr + ':' + value1
      } else {
        return arr[0] + ':' + value1 + '，' + arr[1] + ':' + value2
      }
    },
    // 获取仓库
    async getWarehouseList() {
      let res = await StoreCheck.findWarehouseList()
      this.warehouseList = res.data || []
    },
  }
}

export {
  mixin
}