import {
  orderRemindSave,
  orderRemindDetail,
  orderEnquiryList,
  orderEnquiryStatus,
  updateSale,
  enquiryProduceOrder,
  deletePO,
  selectAllExcel
} from '@/core/services/api/inquiry/list'

class Inquiry {
  static getInstance() {
    return this._instance
  }

  /**
   * 询盘列表-报价提醒保存
   * @param {remind} 报价提醒时间
   */
  remindSave(remind) {
    return orderRemindSave({ remind: remind })
  }

  /**
   * 询盘列表-获取报价提醒时间
   * @param {*}
   */
  remindDetail() {
    return orderRemindDetail()
  }

  /**
   * 询盘列表-获取询盘列表
   * @param {keywords} 询盘单号/客户邮箱/公司名称
   * @param {pageNo} 分页页码
   * @param {pageLe} 分页一页显示多少条
   */
  inquiryList(params) {
    return orderEnquiryList(params)
  }

  /**
   * 询盘列表-询盘状态保存
   * @param {enquiryId} 询盘id
   * @param {statusRemark} 备注
   * @param {type} 类型
   */
  inquiryStatus(ids, remark, type) {
    return orderEnquiryStatus({
      enquiryId: ids,
      statusRemark: remark,
      type: type,
    })
  }

  /**
   * 询盘列表-分配客户代表
   * @param {enquiryId} 询盘id
   * @param {businessId} 客户id
   * @param {businessName} 客户中文名
   * @param {businessNameEn} 客户英文名
   */
  updateSale(id, user) {
    return updateSale({
      enquiryId: id,
      businessId: user.id,
      businessName: user.name,
      businessNameEn: user.englishName,
    })
  }

  /**
   * 询盘列表-生成订单
   * @param {enquiryId} 询盘id
   */
  enquiryProduceOrder(id) {
    return enquiryProduceOrder({ enquiryId: id })
  }

  /**
   * 询盘列表-删除订单
   * @param {enquiryId} 询盘id
   */
  deletePO(id) {
    return deletePO({ enquiryId: id })
  }

  /**
   * 询盘导出
   */
  selectAllExcel({
    businessId,
    checkFlag,
    companyName,
    completeInquiry,
    creator,
    creatorId,
    customerEmail,
    customerOrderCode,
    customerPhone,
    demand,
    endSeasTime,
    enquiryBegin,
    enquiryCode,
    enquiryEnd,
    enquiryIds,
    enquirySourceId,
    enquiryStatus,
    keywords,
    operateInquiry,
    priceSheetCode,
    productInfo,
    startSeasTime,
    status,
    tenantId,
    userId,
    users

  }) {
    return selectAllExcel({
      businessId,
      checkFlag,
      companyName,
      completeInquiry,
      creator,
      creatorId,
      customerEmail,
      customerOrderCode,
      customerPhone,
      demand,
      endSeasTime,
      enquiryBegin,
      enquiryCode,
      enquiryEnd,
      enquiryIds,
      enquirySourceId,
      enquiryStatus,
      keywords,
      operateInquiry,
      priceSheetCode,
      productInfo,
      startSeasTime,
      status,
      tenantId,
      userId,
      users
    })
  }

}

Inquiry._instance = new Inquiry()

export const InquiryList = Inquiry.getInstance()
