<template>
  <div class="preview-warp-box">
    <!-- txt下载 -->
    <i
      v-if="fileType == 'txt' || fileType == 'TXT'"
      class="el-icon-download download-btn"
      @click="downloadTxt()"
    ></i>
    <!-- 关闭 -->
    <i :class="['el-icon-circle-close close', fileType]" @click="close"></i>
    <template
      v-if="
        fileType == 'png' ||
        fileType == 'jpg' ||
        fileType == 'gif' ||
        fileType == 'jpeg' ||
        fileType == 'bmp'
      "
    >
      <div v-loading="loading" class="loading">
        <el-image
          ref="imgSize"
          :class="[showAuto ? 'img-box img-box-auto' : 'img-box']"
          :src="fileUrl"
          fit="contain"
          :preview-src-list="[fileUrl]"
          @load="imgload"
        />
      </div>
    </template>
    <template v-else-if="fileType == 'pdf'">
      <div v-loading="loading" class="loading">
        <iframe
          ref="iframe"
          height="100%"
          width="100%"
          :src="fileUrl"
          frameborder="none"
        ></iframe>
      </div>
    </template>
    <!-- txt 预览使用textarea -->
    <template v-else-if="fileType == 'txt'">
      <el-input v-model="txtPre" type="textarea" class="txt-box" readonly />
    </template>
    <template
      v-else-if="
        fileType == 'doc' ||
        fileType == 'docx' ||
        fileType == 'xls' ||
        fileType == 'xlsx' ||
        fileType == 'ppt' ||
        fileType == 'pptx'
      "
    >
      <div v-loading="loading" class="loading">
        <iframe
          ref="iframe"
          height="100%"
          width="100%"
          :src="url"
          frameborder="none"
        ></iframe>
      </div>
    </template>

    <template
      v-else-if="
        fileType == 'mp4' ||
        fileType == 'avi' ||
        fileType == 'rm' ||
        fileType == 'rmvb' ||
        fileType == 'mov' ||
        fileType == 'qt' ||
        fileType == 'asf' ||
        fileType == 'wmv'
      "
    >
      <video :src="fileUrl" controls height="100%" width="100%"></video>
    </template>
    <!-- 其他文件下载 -->
    <a v-else :href="fileUrl" download>
      <div class="tips">
        <i class="el-icon-warning" style="font-size: 16px"></i>
        抱歉，该类型文件暂不支持预览
      </div>
      <i :class="['el-icon-download download-btn', fileType]"></i>
    </a>
  </div>
</template>
<script>
  export default {
    name: 'Index',

    data() {
      return {
        title: '附件预览',
        previewFileShow: true,
        txtPre: '',
        //标识使用image宽高auto样式
        showAuto: false,
        fileUrl: '',
        fileType: '',
        loading: true,
      }
    },
    computed: {
      url() {
        let shanghaiUrl = `https://view.officeapps.live.com/op/view.aspx?src=${this.fileUrl}`
        return shanghaiUrl.replace(
          'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/',
          'https://weiwo-erp.oss-accelerate.aliyuncs.com/'
        )
      },
    },
    created() {
      this.fileType = ''
      if (this.$route.query.suffix) {
        this.fileType = this.$route.query.suffix?.toLowerCase()?.trim()
      } else {
        this.fileType = this.getQueryVariable(this.$route.fullPath, 'name')
          .slice(
            this.getQueryVariable(this.$route.fullPath, 'name').lastIndexOf(
              '.'
            ) + 1
          )
          .toLowerCase() //截取文件类型
        console.log(this.fileType)
      }

      let origin = 'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/'
      let url =
        this.$route.query.url ||
        this.getQueryVariable(this.$route.fullPath, 'url')
      if (url) {
        this.fileUrl = `${url.indexOf('http') > -1 ? '' : origin}${
          url.includes('?') ? url.split('?')[0] : url
        }`
      }

      //去除拼接的uploadId参数，导致显示异常

      //iframe 加loading
      let that = this
      this.$nextTick(() => {
        const { iframe } = this.$refs
        if (iframe) {
          iframe.onload = () => {
            that.stateChange()
          }
        }
      })
      if (this.fileType == 'TXT' || this.fileType == 'txt') {
        this.txtPre = this.readFile(this.fileUrl)
      }
    },
    methods: {
      // 获取文件类型
      getQueryVariable(url, variable) {
        let vars = url.split('?')[1].split('&')

        for (var i = 0; i < vars.length; i++) {
          let pair = vars[i].split('=')
          if (pair[0] == variable) {
            return pair[1]
          }
        }
      },

      stateChange() {
        this.loading = false
      },
      //关闭
      close() {
        window.close()
      },
      //读取txt编码之后的内容
      readFile(filePath) {
        // 创建一个新的xhr对象
        let xhr = null,
          okStatus = document.location.protocol === 'file' ? 0 : 200
        xhr = window.XMLHttpRequest
          ? new XMLHttpRequest()
          : new ActiveXObject('Microsoft.XMLHTTP')
        xhr.open('GET', filePath, false)
        xhr.overrideMimeType('text/html;charset=utf-8')
        xhr.send(null)
        return xhr.status === okStatus ? xhr.responseText : null
      },
      //下载txt
      downloadTxt() {
        let file = this.fileUrl.slice(this.fileUrl.lastIndexOf('/') + 1)
        let fileName = file.split('.')[0]
        const element = document.createElement('a')
        element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' + encodeURIComponent(this.txtPre)
        )
        element.setAttribute('download', fileName)
        element.style.display = 'none'
        element.click()
      },
      //图片过长宽高100% 使用fit:contain 自适应
      imgload() {
        let imageHeight = this.$refs['imgSize'].imageHeight
        if (imageHeight < 1080) {
          this.showAuto = true
        } else {
          this.showAuto = false
        }
        this.loading = false
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-dialog__body {
      position: relative;
      height: 100%;
      padding: 0 !important;
    }

    .el-dialog__header {
      display: none;
    }

    .el-textarea .el-textarea__inner {
      padding: 10px;
      height: 100% !important;
      border: none;
    }
  }

  .preview-warp-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;

    .loading {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: auto;
    }

    .download-btn {
      position: fixed;
      right: 60px;
      top: 13px;
      z-index: 1;
      font-size: 26px;
      color: #606266;
      cursor: pointer;

      &.doc,
      &.docx,
      &.xlsx,
      &.xls {
        color: #fff;
      }
    }

    .close {
      position: fixed;
      right: 20px;
      top: 13px;
      z-index: 1;
      font-size: 26px;
      cursor: pointer;

      &.pdf {
        color: #fff;
        background: rgba(50, 54, 57, 1);
      }

      &.doc,
      &.docx,
      &.xlsx,
      &.xls {
        color: #fff;
      }
    }

    .img-box {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.img-box-auto {
        height: auto;
        width: auto;
      }
    }

    .txt-box {
      height: 100%;
      width: 100%;
      border: none;
    }

    .tips {
      font-size: 16px;
    }
  }
</style>
