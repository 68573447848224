<template>
  <el-row :gutter="20" class="pro-container pro-base-msg">
    <el-col :span="2">
      <!-- 产品信息 -->
      <h6 class="left-h6">装箱信息</h6>
    </el-col>
    <el-col :span="22" class="right-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        class="mt20"
      >
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="装箱数量" prop="numberPerBox">
              <el-input
                style="width: 200px"
                v-model="form.numberPerBox"
                type="number"
                :placeholder="$t('placeholder.plsInput')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="毛重" prop="boxWeightKgs">
              <el-input
                style="width: 200px"
                v-model="form.boxWeightKgs"
                type="number"
                :placeholder="$t('placeholder.plsInput')"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="mt30">
          <el-form-item>
            <template slot="label">
              <!-- 装箱尺寸 -->
              <i style="color: #ff4d4f">*</i>
              {{ $t('productDetail.PackingSize') }}
            </template>
            <el-row :gutter="10" type="flex" justify="space-between">
              <el-col :span="5">
                <!-- 请输入长度  请输入>0的数值 -->
                <el-form-item
                  prop="boxLengthCm"
                  :rules="[
                    {
                      required: true,
                      message: $t('rules.M2015'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <!-- 长度 -->
                  <el-input
                    v-model="form.boxLengthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2008')"
                    @input="boxSize('Length')"
                  >
                    <template slot="append">cm</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxLengthIn }}</span>
                  IN
                </p>
              </el-col>
              <span>x</span>
              <el-col :span="5">
                <!-- 请输入宽度 请输入>0的数值-->
                <el-form-item
                  prop="boxWidthCm"
                  :rules="[
                    {
                      required: true,
                      message: $t('rules.M2016'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <!-- 宽度 -->
                  <el-input
                    v-model="form.boxWidthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2009')"
                    @input="boxSize('Width')"
                  >
                    <template slot="append">cm</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxWidthIn }}</span>
                  IN
                </p>
              </el-col>
              <span>x</span>
              <el-col :span="5">
                <!-- 请输入高度 请输入>0的数值-->
                <el-form-item
                  prop="boxHeightCm"
                  :rules="[
                    {
                      required: true,
                      message: $t('rules.M2017'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.boxHeightCm"
                    type="number"
                    :placeholder="$t('placeholder.M2010')"
                    @input="boxSize('Height')"
                  >
                    <template slot="append">cm</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxHeightIn }}</span>
                  IN
                </p>
              </el-col>
              <el-col :span="7" :offset="2">
                <span class="mr30">
                  {{ $t('productDetail.VolumeM3') }}：{{ boxM3 }}
                  <span></span>
                </span>
                <span class="ml30">
                  <!-- 体积 -->
                  {{ $t('productDetail.VolumeFT3') }}：{{ boxFt3 }}
                  <span></span>
                </span>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        numberPerBox: '', // 装箱数量
        boxWeightKgs: '', // 毛重
        // 装箱尺寸
        boxLengthCm: '',
        boxLengthIn: '',
        boxWidthCm: '',
        boxWidthIn: '',
        boxHeightCm: '',
        boxHeightIn: '',
      },
      // boxM3: '',
      // boxFt3: '',
      rules: {
        numberPerBox: [
          {
            required: true,
            message: '请輸入装箱数量',
            trigger: 'blur',
          },
          {
            pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
            message: '装箱数量需大于0',
            trigger: 'blur',
          },
        ],
        boxWeightKgs: [
          {
            required: true,
            message: '请输入毛重(KGS)',
            trigger: 'blur',
          },
          {
            pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
            message: '毛重(KGS)需大于0',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    // 体积
    boxFt3() {
      return Math.round(
        this.form.boxLengthCm *
          this.form.boxWidthCm *
          this.form.boxHeightCm *
          0.000001 *
          35.31467
      )
    },
    boxM3() {
      return Math.round(
        this.form.boxLengthCm *
          this.form.boxWidthCm *
          this.form.boxHeightCm *
          0.000001
      )
    },
  },
  watch: {
    // 装箱信息变化 同步到产品计价模块
    form: {
      handler() {
        this.$Bus.$emit('packBoxMsg', {
          ...this.form,
          // boxFt3: this.boxFt3,
          // boxM3: this.boxM3,
        })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 设置装箱尺寸 IN
    boxSize(type) {
      this.form[`box${type}In`] = (this.form[`box${type}Cm`] * 0.3937).toFixed(
        2
      )
    },
    // 验证
    validateField() {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang="scss" scoped>
.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }
  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }
  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px !important;
    border-radius: 3px;
  }
}
</style>