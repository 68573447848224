<!--
  功能：询盘报价组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月16日 20:00:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard title="询盘报价" :tabs="tabs" @tab-click="handleTabClick" :singleNum="singleNum" :teamNum="teamNum"
    :loading="loading">
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="inquiry-quotation-item">
          <div class="content" @click="clientFollowClick(item)">
            <div class="left">
              <div class="top">
                询盘单
                <span :style="boldStyle.bold">{{ item.enquiryCode }}</span>
                生成后
                <em style="color: red">{{ item.hours }}H</em>
                未报价,请及时处理
              </div>
              <div class="bottom">
                <span class="user">客户代表</span>
                <span>{{ item.businessName }}</span>
              </div>
            </div>
            <div class="right index-card-time">
              {{ item.createTime | timeFilter }}
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
  </IndexCard>
</template>

<script>
  import { HomeInteractor } from '@/core'
  import mixins from '../mixins'
  export default {
    name: 'InquiryQuotation',
    mixins: [mixins],
    props: {},

    // 组件状态值
    data() {
      return {
        list: [],
        tabs: true,
        authFlag: 0, //0个人 1团队
      }
    },
    methods: {
      addSuccess() {
        this.getList()
      },
      getList() {
        this.loading = true
        HomeInteractor.listHomeEnquiry({
          authFlag: this.active === 'ME' ? 0 : 1,
          pageLe: 99999,
          pageNo: 1,
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
        }).then((res) => {
          if (res && res.code === '000000') {
            const { singleNum, teamNum, pageVO } = res.data
            this.list = pageVO.data || []
            this.singleNum = singleNum || 0
            this.teamNum = teamNum || 0
          } else {
            this.list = []
          }
          this.loading = false
        })
      },

      clientFollowClick(item) {

      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .inquiry-quotation-item {
    .content {
      display: flex;

      .left {
        display: flex;
        flex-direction: column;
        color: #000;
        flex: 1;

        .top {
          margin-bottom: 4px;

          span {}
        }

        .bottom {
          color: #5e5e5e;
          font-size: 12px;

          .user {
            margin-right: 7px;
          }
        }
      }

      .right {
        padding-left: 10px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
</style>
