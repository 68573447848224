var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.marksVisible)?_c('el-dialog',{staticClass:"plan-order",attrs:{"title":"设置Marks","visible":_vm.marksVisible,"width":"800px"},on:{"update:visible":function($event){_vm.marksVisible=$event},"before-close":_vm.close}},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"mb10"},[_vm._v("选择打印Marks时，需要展示的字段")]),_c('el-checkbox-group',{on:{"change":_vm.defaultChange},model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('el-checkbox',{attrs:{"label":"Item"}}),_c('el-checkbox',{attrs:{"label":"客户订单号"}}),_c('el-checkbox',{attrs:{"label":"Ship"}}),_c('el-checkbox',{attrs:{"label":"Made in China"}})],1),_c('div',{staticClass:"mt10 mb10"},[_c('el-button',{attrs:{"icon":"el-icon-plus"},on:{"click":_vm.add}},[_vm._v("添加自定义字段")])],1),_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form}},[_c('table',{staticClass:"custom-table no-space"},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"60"}},[_c('input',{ref:"checkboxAll",staticClass:"pointer",attrs:{"type":"checkbox"},on:{"click":_vm.checkAll}})]),_c('th',{attrs:{"width":"300"}},[_c('span',[_c('i',[_vm._v("*")]),_vm._v(" 前缀 ")])]),_c('th',{attrs:{"width":"300"}},[_vm._v("后缀")]),_c('th',{attrs:{"width":"80"}},[_vm._v("操作")])])]),_c('tbody',{attrs:{"id":"sort"}},_vm._l((_vm.form.orderMarkDTOS),function(item,i){return _c('tr',{key:i},[_c('td',[_c('input',{ref:"checkbox",refInFor:true,staticClass:"pointer",attrs:{"type":"checkbox","sign":item.markPrefix,"disabled":item.markPrefix === 'PI'},domProps:{"checked":item.checkFlag == 1},on:{"click":function($event){return _vm.checkChange(i, item.markPrefix)}}})]),_c('td',[(item.templateFlag == 1)?_c('div',[_vm._v(_vm._s(item.markPrefix))]):_c('div',[_c('el-form-item',{attrs:{"prop":'orderMarkDTOS.' + i + '.markPrefix',"rules":[
                    {
                      required: true,
                      message: '前缀必填',
                      trigger: 'blur',
                    },
                    {
                      max: 50,
                      message: '字符长度不能超过50',
                      trigger: 'blur',
                    } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.orderMarkDTOS[i].markPrefix),callback:function ($$v) {_vm.$set(_vm.form.orderMarkDTOS[i], "markPrefix", $$v)},expression:"form.orderMarkDTOS[i].markPrefix"}})],1)],1)]),_c('td',[(item.templateFlag == 1)?_c('div',[_vm._v(_vm._s(item.markSuffix))]):_c('div',[_c('el-form-item',{attrs:{"prop":'orderMarkDTOS.' + i + '.markSuffix',"rules":[
                    {
                      max: 50,
                      message: '字符长度不能超过50',
                      trigger: 'blur',
                    } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.orderMarkDTOS[i].markSuffix),callback:function ($$v) {_vm.$set(_vm.form.orderMarkDTOS[i], "markSuffix", $$v)},expression:"form.orderMarkDTOS[i].markSuffix"}})],1)],1)]),_c('td',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!item.templateFlag),expression:"!item.templateFlag"}],staticClass:"icon-r",attrs:{"size":"mini","icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.del(i)}}})],1)])}),0)])]),_c('p',{staticClass:"mt10"},[_vm._v("Tips：打印前 前缀和后缀之间可以手动输入内容")])],1),_c('div',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.isLoading ? '加载中' : '确认')+" ")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }