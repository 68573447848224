<template>
  <el-dialog
    title="条件模板选择"
    :visible.sync="modelVisible"
    width="800px"
    :before-close="cancelModel"
    center
  >
    <!-- <el-button type="primary" @click="newModel">新增</el-button> -->
    <el-table :data="tableData" style="width: 100%; margin-top: 10px" border>
      <el-table-column width="80" align="center">
        <template slot-scope="scope">
          <el-radio
            style="transform: translateX(10px)"
            v-model="chooseRow"
            :value="scope.row.configConditionId"
            :label="scope.row.configConditionId"
          >
            &nbsp
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column label="序号" width="80" align="center">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>

      <el-table-column
        prop="conditionDesc"
        label="条件内容"
        align="center"
      ></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelModel">取 消</el-button>
      <el-button type="primary" @click="confirmModel">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { SettleConfigInteractor } from '@/core/interactors/finance/config-manage'
export default {
  props: {
    // 弹窗显示
    modelVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      chooseRow: '',
    }
  },
  watch: {
    modelVisible(newval, oldval) {
      if (newval) {
        this.getList()
      }
    },
  },
  methods: {
    async getList() {
      this.tableData = await SettleConfigInteractor.getConfigConditionList()
    },
    cancelModel() {
      this.$emit('update:modelVisible', false)
      this.chooseRow = ''
    },
    confirmModel() {
      if (!this.chooseRow) {
        this.$message.warning('请选择一条数据！')
        return
      }
      let choose = this.tableData.find((item) => {
        return item.configConditionId == this.chooseRow
      })

      this.$emit('update:modelVisible', false)
      this.$emit('submitCondition', choose)
      this.chooseRow = ''
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
