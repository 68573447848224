<template>
  <div class="custom-container" v-if="$route.meta.showParent">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="18" class="page-header-left">
        <el-row
          type="flex"
          class="align_center flex_row_y flex_wrap top-search"
        >
          <el-button
            v-show="!selectRows.length"
            icon="el-icon-plus"
            type="primary"
            class="mr10"
            @click="add"
          >
            <!-- 新增 -->
            {{ $t('orderList.Add') }}
          </el-button>
          <el-form
            v-show="!selectRows.length"
            ref="form"
            :inline="true"
            :model="queryForm"
            label-width="49px"
            @submit.native.prevent
          >
            <el-form-item>
              <el-input
                v-model="queryForm.cusName"
                :placeholder="$t('placeholder.orderListEnter')"
                clearable
                @keyup.enter.native="getList"
                @input="searchInput"
              />
            </el-form-item>
          </el-form>
          <el-button v-show="!selectRows.length" @click="initLoadingList">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            <!-- 搜索 -->
            {{ $t('orderList.Search') }}
          </el-button>

          <span v-show="selectRows.length" class="mr10">
            {{ lang === 'en' ? 'Selected' : '已选择' }}
            <strong class="f_s_16">{{ selectRows.length }}</strong>
            {{ lang === 'en' ? 'Inquiry' : '个' }}
          </span>
          <!-- 高级筛选 -->
          <el-button class="ml10" @click="highSearch">
            {{ $t('orderList.AdvancedFilter') }}
          </el-button>
          <!-- 克隆 -->
          <el-button plain @click="cloneForm" v-show="isClone">
            {{ $t('orderList.Clone') }}
          </el-button>
          <!-- 作废 -->
          <el-button
            plain
            @click="cancellation"
            v-show="isCancellation && lang != 'en'"
          >
            {{ $t('orderList.Invalid') }}
          </el-button>
          <!-- 删除 -->
          <!-- <el-button
            plain
            @click="operateDelete(false)"
            v-show="isDelete && lang != 'en'"
          >
            {{ $t('orderList.Delete') }}
          </el-button> -->
          <!-- 安排订单 -->
          <el-button
            plain
            @click="planOrder"
            v-show="isPlanOrder && lang != 'en'"
          >
            {{ $t('orderList.ArrangeOrder') }}
          </el-button>
          <!-- 导出 -->
          <el-dropdown @command="download" class="ml10" v-show="lang != 'en'">
            <el-button plain>
              {{ $t('orderList.Export') }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!-- 导出选中 -->
              <el-dropdown-item command="checked">
                {{ $t('orderList.ExportSelected') }}
              </el-dropdown-item>
              <!-- 导出全部 -->
              <el-dropdown-item command="all">
                {{ $t('orderList.ExportAll') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 退款申请 -->
          <el-button
            plain
            class="ml10"
            @click="refundApply"
            v-show="isRefund && lang != 'en'"
          >
            {{ $t('orderList.RefundApplication') }}
          </el-button>
          <!-- 费用申请 -->
          <el-button
            plain
            class="ml10"
            v-show="isCost && lang != 'en'"
            @click="costApply"
          >
            {{ $t('orderList.FeeApplication') }}
          </el-button>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-row type="flex" justify="end">
          <!-- 自定义展示列 -->
          <el-popover
            ref="showOption"
            popper-class="custom-table-checkbox"
            trigger="hover"
          >
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="item + index"
                v-model="item.isShow"
                :disabled="item.checkable === false"
                :label="item.label"
              >
                {{ $t(item.langKey) }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button
                type="primary"
                class="ml10"
                size="mini"
                plain
                @click="operateSave"
              >
                <!-- 保存 -->
                {{ $t('inquiryList.Save') }}
              </el-button>
            </div>
            <template #reference>
              <el-tooltip
                effect="dark"
                :content="$t('iconbtn.dragSort')"
                placement="top-start"
              >
                <!-- 自定义显示 -->
                <el-button plain type="primary">
                  {{ $t('inquiryList.CustomDisplay') }}
                </el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>
    <!-- table or pic -->

    <el-radio-group
      class="mt5 mb5"
      v-model="queryForm.orderStatus"
      @change="orderStatusChange"
    >
      <el-radio
        v-for="(item, i) in orderStatusList"
        :key="i"
        :label="item.value"
      >
        {{ lang === 'en' ? item.labelEn : item.label }}
      </el-radio>
    </el-radio-group>

    <el-row class="mt10">
      <ErpTable
        :page-number.sync="page.pageNo"
        :page-size.sync="page.pageLe"
        :table-data="tableData"
        :total="total"
        :extraHeight="30"
        @query="getList"
        class="table-fixed"
        ref="ErpTable"
        :key="tablekey"
        :default-sort="defaultSort"
        @selection-change="setSelectRows"
        @update:pageSize="page.pageNo = 1"
        @sort-change="sortChange"
        @expand-change="expandChange"
      >
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column align="center" type="expand" width="55">
          <template slot="header">
            <i
              :class="[
                'el-icon-arrow-right expand-all',
                isExpandAll ? 'active' : '',
              ]"
              v-show="page.pageLe <= 20"
              @click="expandAll"
            ></i>
          </template>
          <template slot-scope="product">
            <!-- 产品拓展信息 -->
            <order-product-info
              :tableData="product.row.orderProductListVOS"
              :lang="lang"
            ></order-product-info>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :label="$t(item.langKey)"
          show-overflow-tooltip
          :width="
            item.prop === 'orderCode'
              ? '180'
              : lang === 'en'
              ? '180'
              : item.width
          "
          min-width="120"
          :resizable="false"
          align="center"
          :prop="item.prop"
          :sortable="item.sortable ? 'custom' : false"
          :style="item.prop === 'orderCode' ? 'position: relative' : ''"
        >
          <template slot="header" v-if="item.prop === 'noSettlementRemark'">
            <!-- 未结算原因 -->
            <div>
              <span>{{ $t('orderList.UnsettledReason') }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('reqmsg.M2063')"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <!-- 签约日期 -->
            <div v-if="item.prop === 'contractDate'">
              {{ row.contractDate }}
            </div>
            <!-- 客户代表 -->
            <div v-else-if="item.prop === 'businessName'">
              {{ lang === 'en' ? row.businessNameEn : row.businessName }}
            </div>
            <!-- 订单支持 -->
            <div v-else-if="item.prop === 'fromBusinessName'">
              {{
                lang === 'en' ? row.fromBusinessNameEn : row.fromBusinessName
              }}
            </div>
            <!-- 创建人 -->
            <div v-else-if="item.prop === 'creatorName'">
              {{ lang === 'en' ? row.creatorNameEn : row.creatorName }}
            </div>
            <!-- 订单总金额 -->
            <div v-else-if="item.prop === 'totlePrice'">
              <div v-if="isViewCost">
                ${{ row.totlePrice | orderMoneyFormat }}
              </div>
              <NoViewCost v-else />
            </div>
            <!-- 订单原金额 -->
            <div
              v-else-if="item.prop === 'fromTotlePrice'"
              :style="{
                color: row.fromTotlePrice !== row.totlePrice ? 'red' : '',
              }"
            >
              <div v-if="isViewCost">
                ${{ row.fromTotlePrice | orderMoneyFormat }}
              </div>
              <NoViewCost v-else />
            </div>
            <!-- 实际收款金额 -->
            <div v-else-if="item.prop === 'actualReceivablePrice'">
              <div v-if="isViewCost">
                {{ row.actualReceivablePrice | orderMoneyFormat }}
              </div>
              <NoViewCost v-else />
            </div>
            <!-- 是否使用折扣 -->
            <div v-else-if="item.prop === 'useDisAccount'">
              <span v-if="row.useDisAccount == 0">{{ lang === 'en' ? 'no' : '否' }}</span>
              <span v-else-if="row.useDisAccount == 1">{{ lang === 'en' ? 'yes' : '是' }}</span>
              <span v-else>--</span>
            </div>
            <!-- 订单状态 -->
            <div v-else-if="item.prop === 'orderStatus'">
              <!-- 录入中 -->
              <el-tag v-if="row.orderStatus == 1">
                {{ $t('orderList.Inputting') }}
              </el-tag>
              <el-row v-else-if="row.orderStatus == 2">
                <el-tooltip placement="top" effect="light">
                  <div slot="content" class="tip-content">
                    {{ $t('orderList.ReasonForRejection') }}：{{
                      row.orderCause || '--'
                    }}
                    <br />
                    {{ $t('orderList.ReviewRejectionTime') }}：{{
                      row.auditFailTime || '--'
                    }}
                  </div>
                  <!-- 已驳回 -->
                  <el-tag type="danger" class="c_pointer">
                    {{ $t('orderList.Rejected') }}
                  </el-tag>
                </el-tooltip>
              </el-row>
              <el-row v-else-if="row.orderStatus == 3">
                <el-tooltip placement="top" effect="light">
                  <div slot="content" class="tip-content">
                    {{ $t('orderList.ReasonForRevocation') }}：{{
                      row.orderCause || '--'
                    }}
                    <br />
                    {{ $t('orderList.ReviewRevocationTime') }}：{{
                      row.auditFailTime || '--'
                    }}
                  </div>
                  <!-- 已撤销 -->
                  <el-tag type="danger" class="c_pointer">
                    {{ $t('orderList.Revoked') }}
                  </el-tag>
                </el-tooltip>
              </el-row>
              <el-tag
                v-else-if="row.orderStatus == 4"
                type="primary"
                class="c_pointer"
                @click="statusTypeClick(row)"
              >
                <!-- 审核中 -->
                {{ $t('orderList.InReview') }}
              </el-tag>
              <el-tag
                v-else-if="row.orderStatus == 5"
                type="success"
                class="c_pointer"
                @click="statusTypeClick(row)"
              >
                <!-- 已通过 -->
                {{ $t('orderList.Passed') }}
              </el-tag>
              <el-row v-else-if="row.orderStatus == 6">
                <el-tooltip placement="top" effect="light">
                  <div
                    slot="content"
                    class="tip-content"
                    v-for="(item, index) of row.orderSendVOS"
                    :key="index"
                  >
                    {{ lang === 'en' ? 'Buyer' : '采购开发' }}：{{
                      lang === 'en' ? item.salesNameEn : item.salesName
                    }}
                    <br />
                    <!-- {{ lang === 'en' ? 'current state' : '当前状态' }}：
                    <el-tag :class="item.salesProductStatus | statusFilter">
                      {{ orderSendStatus(item.salesProductStatus, lang) }}
                    </el-tag>
                    <br /> -->
                    {{
                      lang === 'en'
                        ? 'Expected date of shipment'
                        : '期望装运日期'
                    }}：{{ item.expectedDeliveryDate }}
                  </div>
                  <!-- 待发货 -->
                  <el-tag type="warning" class="c_pointer">
                    {{ $t('orderList.PendingShipment') }}
                  </el-tag>
                </el-tooltip>
              </el-row>
              <el-row v-else-if="row.orderStatus == 8">
                <el-tooltip placement="top" effect="light">
                  <div
                    slot="content"
                    class="tip-content"
                    v-for="(item, index) of row.salesWaitVO"
                    :key="index"
                  >
                    {{ lang === 'en' ? 'waybill number' : '运单号' }}：{{
                      item.number
                    }}
                    <br />
                    {{ lang === 'en' ? 'date of shipment' : '装运日期' }}：{{
                      item.shippingDate
                    }}
                    <br />
                    {{
                      lang === 'en' ? 'mode of transportation' : '运输方式'
                    }}：{{ item.shippingMethodDesc }}
                    <br />
                    {{
                      lang === 'en'
                        ? 'Estimated date of arrival'
                        : '预计到货日期'
                    }}:{{ item.deliveryDate }}
                  </div>
                  <!-- 待收货 -->
                  <el-tag type="warning" class="c_pointer">
                    {{ $t('orderList.PendingDelivery') }}
                  </el-tag>
                </el-tooltip>
              </el-row>
              <el-row v-else-if="row.orderStatus == 9">
                <el-tooltip placement="top" effect="light">
                  <div
                    slot="content"
                    class="tip-content"
                    v-for="(item, index) of row.salesWaitVO"
                    :key="index"
                  >
                    {{ lang === 'en' ? 'waybill number' : '运单号' }}：{{
                      item.number
                    }}
                    <br />
                    {{ lang === 'en' ? 'date of shipment' : '装运日期' }}：{{
                      item.shippingDate
                    }}
                    <br />
                    {{
                      lang === 'en' ? 'mode of transportation' : '运输方式'
                    }}：{{ item.shippingMethodDesc }}
                    <br />
                    {{ lang === 'en' ? 'date of lodgment' : '签收日期' }}:{{
                      item.arriveDate
                    }}
                  </div>
                  <!-- 已收货 -->
                  <el-tag type="warning" class="c_pointer">
                    {{ $t('orderList.Delivered') }}
                  </el-tag>
                </el-tooltip>
              </el-row>
              <!-- 已完成 -->
              <el-tag v-else-if="row.orderStatus == 10" type="info">
                {{ $t('orderList.Completed') }}
              </el-tag>
              <el-row v-else-if="row.orderStatus == 11">
                <el-tooltip placement="top">
                  <div slot="content" class="tip-content">
                    {{ lang === 'en' ? 'Reason for voiding' : '作废原因' }}：{{
                      row.orderCause || '--'
                    }}
                    <br />
                    {{ lang === 'en' ? 'void time' : '作废时间' }}：{{
                      row.updateTime || '--'
                    }}
                  </div>
                  <!-- 已作废 -->
                  <el-tag type="info" class="c_pointer">
                    {{ $t('orderList.Voided') }}
                  </el-tag>
                </el-tooltip>
              </el-row>
              <el-tag v-else>/</el-tag>
            </div>
            <div v-else-if="item.prop === 'endStateDesc'">
              <span>{{ getLangFinishType(row.endStateDesc) || '--' }}</span>
              <el-tooltip placement="top-start" :content="row.endStateReason">
                <i
                  style="margin-left: 5px"
                  class="el-icon-tickets"
                  v-show="row.endState == 2"
                ></i>
              </el-tooltip>
            </div>
            <!-- 收款进度 -->
            <div v-else-if="item.prop === 'paymentSchedule'">
              <span v-if="lang === 'en'">
                {{ getLangProgress(row.paymentSchedule) }}
              </span>
              <span v-else>{{ row.paymentSchedule }}</span>
            </div>
            <!-- 结算状态 -->
            <div v-else-if="item.prop === 'settlementStatus'">
              <el-tag v-if="row.settlementStatus == 0" type="info">-</el-tag>
              <div v-else-if="row.settlementStatus == 1">
                <!-- 结算驳回 -->
                <el-tooltip
                  v-if="row.settlementFlag == 1"
                  placement="top"
                  effect="light"
                >
                  <div slot="content">
                    <!-- 【驳回原因】 -->
                    <p class="mb5">【{{ $t('orderList.ReasonRejection') }}】</p>
                    <!-- 驳回时间 -->
                    <p class="settleTip">
                      {{ $t('orderList.TimeRejection') }}：{{
                        row.settlementInfo.split('&&')[0]
                      }}
                    </p>
                    <!-- 驳回说明 -->
                    <p class="settleTip">
                      {{ $t('orderList.RejectionDescription') }}：{{
                        row.settlementInfo.split('&&')[1]
                      }}
                    </p>
                  </div>
                  <el-tag type="danger" class="c_pointer">
                    {{ $t('orderList.SettlementReject') }}
                  </el-tag>
                </el-tooltip>
                <!-- 结算调整 -->
                <el-tooltip
                  v-else-if="row.settlementFlag == 2"
                  placement="top"
                  effect="light"
                >
                  <div slot="content">
                    <!-- 结算调整】 -->
                    <p class="mb5">
                      【{{ $t('orderList.SettlementAdjustment') }}】
                    </p>
                    <!-- 调整时间： -->
                    <p>
                      {{ $t('orderList.TimeAdjustment') }}：{{
                        row.settlementInfo.split('&&')[0]
                      }}
                    </p>
                    <!-- 调整说明 -->
                    <p class="settleTip">
                      {{ $t('orderList.AdjustmentDescription') }}：{{
                        row.settlementInfo.split('&&')[1]
                      }}
                    </p>
                  </div>
                  <el-tag type="danger" class="c_pointer">
                    {{ $t('orderList.SettlementAdjustment') }}
                  </el-tag>
                </el-tooltip>
                <!-- 未结算 -->
                <el-tag v-else type="info">-</el-tag>
              </div>
              <!-- 结算中 -->
              <el-tag v-else-if="row.settlementStatus == 2" type="warning">
                {{ $t('orderList.Settling') }}
              </el-tag>
              <!-- 已结算 -->
              <el-tag v-else-if="row.settlementStatus == 3" type="success">
                {{ $t('orderList.Settled') }}
              </el-tag>
              <!-- 待结算 -->
              <el-tag v-else-if="row.settlementStatus == 4" type="success">
                {{ $t('orderList.ToSettled') }}
              </el-tag>
            </div>

            <!-- 订单编号 -->
            <div v-else-if="item.prop === 'orderCode'">
              <div class="text-left">
                <div
                  class="statusTag"
                  v-if="row.evaluationType && row.evaluationType.length > 0"
                >
                  <span
                    class="textBg"
                    v-for="it in row.evaluationType"
                    :key="it"
                    :style="
                      it == 1
                        ? 'background-color:#f44336'
                        : 'background-color:#8bc34a'
                    "
                  >
                    <!-- 投诉  反馈-->
                    {{
                      it == 1
                        ? $t('orderList.complain')
                        : $t('orderList.feedback')
                    }}
                  </span>
                </div>
                <router-link
                  :to="`/order/orderList/orderDetail?orderCode=${row.orderCode}&orderId=${row.orderId}`"
                >
                  <span style="text-decoration-line: underline">
                    {{ row.orderCode }}
                  </span>
                </router-link>
              </div>
            </div>
            <!-- 进程 -->
            <div v-else-if="item.prop === 'progress'">
              <el-button
                type="primary"
                plain
                @click="progerssDrawer(row.orderId)"
                size="mini"
                icon="el-icon-date"
                circle
              ></el-button>
            </div>
            <!-- 客评单号 -->
            <div v-else-if="item.prop === 'evaluationCodes'">
              <div v-for="it in row.evaluationCodesList" :key="it">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="
                    () => {
                      $router.push(
                        '/client-supplier/customer-center?evaluationCode=' + it
                      )
                    }
                  "
                >
                  {{ it }}
                </el-link>
              </div>
            </div>
            <!-- 地区 -->
            <div v-else-if="item.prop === 'area'">
              <span v-if="lang === 'en'">
                {{ getLangArea(row.area) || row.area }}
              </span>
              <span v-else>{{ row.area }}</span>
            </div>
            <!-- 跟单进度 -->
            <div v-else-if="item.prop === 'nodeNameCn'">
              <span class="colorblue" @click="showTrackingDetail(row)">
                {{ row[lang === 'en' ? 'nodeNameEn' : 'nodeNameCn'] }}
              </span>
            </div>
            <!-- 其他 -->
            <span v-else>{{ row[item.prop] || '--' }}</span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          align="center"
          :label="$t('inquiryList.Operate')"
          v-if="queryForm.orderStatus != 11"
          width="150"
          fixed="right"
          :resizable="false"
        >
          <template slot-scope="scope">
            <div v-if="lang === 'en'">
              <!-- 编辑 -->
              <el-tooltip
                class="item"
                :content="$t('inquiryList.Edit')"
                placement="top"
              >
                <el-button
                  v-show="
                    scope.row.operateSalesOrder === '1' &&
                    isInclude(scope.row.orderStatus, [1, 2, 3, 5, 6])
                  "
                  type="primary"
                  plain
                  @click="edit(scope.row.orderId, scope.row.orderCode)"
                  size="mini"
                  icon="iconfont el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
              <el-tooltip class="item" content="Undo" placement="top">
                <el-button
                  v-show="
                    scope.row.operateSalesOrder === '1' &&
                    scope.row.orderStatus == 4
                  "
                  type="primary"
                  plain
                  @click="revokeClick(scope.row)"
                  size="mini"
                  icon="iconfont icon-quxiao"
                  circle
                ></el-button>
              </el-tooltip>
              <el-popover
                placement="top-start"
                trigger="hover"
                v-show="
                  scope.row.operateSalesOrder === '1' &&
                  isInclude(scope.row.orderStatus, [4, 5, 6, 7, 8, 9, 10])
                "
              >
                <div class="text-center">
                  <router-link
                    :to="`/print/orderContract?orderId=${scope.row.orderId}`"
                    target="_blank"
                  >
                    Print contract
                  </router-link>
                  <span
                    class="page-link-noline"
                    @click="downloadContract(scope.row)"
                  >
                    &nbsp;|&nbsp;Download contract
                  </span>
                </div>
                <div
                  class="text-center page-link no-underline"
                  @click="showInvoceDialog(scope.row, 4)"
                  v-show="
                    scope.row.orderStatus != 4 && hasRight('ORDERPRINTINVOICE')
                  "
                >
                  <!-- 打印发票 -->
                  {{ $t('orderList.invoice') }}
                </div>
                <el-button
                  type="primary"
                  plain
                  slot="reference"
                  size="mini"
                  icon="iconfont icon-dayin"
                  circle
                ></el-button>
              </el-popover>
            </div>
            <div v-else>
              <el-popover
                placement="top-start"
                trigger="hover"
                v-show="
                  scope.row.operateSalesOrder === '1' &&
                  isInclude(scope.row.orderStatus, [4, 5, 6, 7, 8, 9, 10])
                "
              >
                <div class="text-center">
                  <router-link
                    :to="`/print/orderContract?orderId=${scope.row.orderId}`"
                    target="_blank"
                  >
                    打印合同
                  </router-link>
                  <span
                    class="page-link-noline"
                    @click="downloadContract(scope.row)"
                  >
                    &nbsp;|&nbsp;下载合同
                  </span>
                </div>
                <div
                  class="text-center page-link no-underline"
                  @click="showInvoceDialog(scope.row, 4)"
                  v-show="
                    scope.row.orderStatus != 4 && hasRight('ORDERPRINTINVOICE')
                  "
                >
                  <!-- 打印发票 -->
                  {{ $t('orderList.invoice') }}
                </div>
                <div
                  class="text-center page-link no-underline"
                  @click="showInvoceDialog(scope.row, 5)"
                  v-show="scope.row.orderStatus != 4"
                >
                  打印简易发票
                </div>

                <div class="text-center">
                  <router-link
                    :to="`/print/orderShipMark?orderId=${scope.row.orderId}&settingtype=0`"
                    target="_blank"
                  >
                    打印Marks &nbsp;|
                  </router-link>
                  <span class="page-link" @click="marksSet(scope.row.orderId)">
                    &nbsp;设置
                  </span>
                </div>
                <div class="text-center" v-show="scope.row.orderStatus != 4">
                  <router-link
                    :to="`/print/orderReport?orderId=${scope.row.orderId}`"
                    target="_blank"
                  >
                    打印Packing
                  </router-link>
                </div>
                <el-button
                  type="primary"
                  plain
                  slot="reference"
                  size="mini"
                  icon="iconfont icon-dayin"
                  circle
                ></el-button>
              </el-popover>
              <!-- 编辑 -->
              <el-tooltip
                class="item"
                :content="$t('inquiryList.Edit')"
                placement="top"
              >
                <el-button
                  v-show="
                    scope.row.operateSalesOrder === '1' &&
                    isInclude(scope.row.orderStatus, [1, 2, 3, 5, 6])
                  "
                  type="primary"
                  plain
                  @click="edit(scope.row.orderId, scope.row.orderCode)"
                  size="mini"
                  icon="iconfont el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                :content="`${lang === 'en' ? 'Undo' : '撤销'}`"
                placement="top"
              >
                <el-button
                  v-show="
                    scope.row.operateSalesOrder === '1' &&
                    scope.row.orderStatus == 4
                  "
                  type="primary"
                  plain
                  @click="revokeClick(scope.row)"
                  size="mini"
                  icon="iconfont icon-quxiao"
                  circle
                ></el-button>
              </el-tooltip>
              <!-- 删除 -->
              <!-- <el-tooltip
                class="item"
                :content="$t('inquiryList.Delete')"
                placement="top"
              >
                <el-button
                  v-show="isInclude(scope.row.orderStatus, [1, 2, 3])"
                  type="primary"
                  plain
                  @click="operateDelete(scope.row.orderId)"
                  size="mini"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </el-tooltip> -->
              <!-- 手动结算  -->
              <el-tooltip
                class="item"
                :content="$t('orderList.ManualSettlement')"
                placement="top"
              >
                <el-button
                  v-show="
                    isInclude(scope.row.orderStatus, [10]) &&
                    isInclude(scope.row.settlementFlag, [1, 2])
                  "
                  type="primary"
                  plain
                  size="mini"
                  icon="iconfont icon-jiesuan"
                  circle
                  @click="manualSettle(scope.row)"
                ></el-button>
              </el-tooltip>
              <!-- <el-tooltip class="item" content="手动完结" placement="top">
                <el-button
                  v-show="isInclude(scope.row.orderStatus, [5, 6, 8, 9])"
                  plain
                  size="mini"
                  @click="manualEnd(scope.row)"
                  icon="iconfont icon-quotation"
                  circle
                  type="primary"
                ></el-button>
              </el-tooltip> -->
            </div>
          </template>
        </el-table-column>
      </ErpTable>
    </el-row>

    <!-- 高级筛选Drawer -->
    <el-drawer
      :title="$t('orderList.AdvancedFilter')"
      :visible.sync="drawer"
      :before-close="highSearchClose"
    >
      <orderHighSearch
        ref="orderHighSearch"
        @cancelClick="highSearchClose"
        @searchClick="searchClick"
      />
    </el-drawer>

    <!-- 订单进程Drawer -->
    <el-drawer
      :title="`${lang === 'en' ? 'The order process' : '订单进程'}`"
      ref="progressDrawer"
      :visible.sync="progressDrawerVisible"
    >
      <progressDrawer
        v-if="progressDrawerVisible"
        @progressCancel="progressCancel"
        :progressId="progressId"
      ></progressDrawer>
    </el-drawer>

    <!-- 作废 -->
    <el-dialog
      :title="$t('orderList.Invalid')"
      :visible.sync="operatVisible"
      :close-on-click-modal="false"
      width="720px"
    >
      <div class="cancellation-dialog">
        <div class="container">
          <el-table border :data="cancellationList">
            <el-table-column
              type="index"
              width="50"
              label="#"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="number"
              align="center"
              :label="`${lang === 'en' ? 'document number' : '单据号'}`"
            ></el-table-column>
            <el-table-column
              prop="typeMemo"
              align="center"
              :label="`${lang === 'en' ? 'type' : '类型'}`"
            ></el-table-column>
            <!-- 状态 -->
            <el-table-column
              prop="statusMemo"
              align="center"
              :label="$t('orderList.Status')"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.statusMemo == '已通过' ||
                    scope.row.statusMemo == '已完成'
                  "
                >
                  <el-tag type="success">
                    {{ scope.row.statusMemo }}
                  </el-tag>
                </div>
                <div v-else>
                  <el-tag type="primary">
                    {{ scope.row.statusMemo }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="`${lang === 'en' ? 'Associated documents' : '关联单据'}`"
            >
              <template slot-scope="scope">
                {{ scope.row.relationCode || '--' }}
              </template>
            </el-table-column>
          </el-table>
          <el-form
            v-show="cancellationVisible"
            class="mt20"
            :model="operatForm"
            :rules="operatRules"
            ref="operatForm"
            label-width="80px"
          >
            <el-form-item
              class="label-left"
              :label="`${lang === 'en' ? 'Invalid reason' : '作废理由'}`"
              prop="orderCause"
            >
              <el-input
                type="textarea"
                maxlength="500"
                show-word-limit
                :rows="3"
                v-model="operatForm.orderCause"
              ></el-input>
            </el-form-item>
          </el-form>
          <p class="mt20">
            {{
              lang === 'en'
                ? 'Order invalidation conditions: no collection, no inventory acquisition, no purchase order generation'
                : '订单作废条件：没有收款、没有费用、没有库存领用、未生成采购订单'
            }}
            <br />
          </p>
        </div>
        <div v-if="cancellationVisible" class="btn text-center">
          <!-- 取消 -->
          <el-button
            @click="
              operatVisible = false
              operatForm.orderCause = ''
            "
          >
            {{ $t('inquiryList.Cancel') }}
          </el-button>
          <!-- 确认 -->
          <el-button type="primary" @click="operatSave">
            {{ $t('inquiryList.Confirm') }}
          </el-button>
        </div>
        <div v-else class="btn text-center">
          <el-button @click="operatVisible = false">
            {{ lang === 'en' ? 'close' : '关闭' }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 安排订单 -->
    <planOrder ref="planOrder"></planOrder>

    <!-- 打印marks设置 -->
    <marksSet ref="marksSet"></marksSet>

    <!-- 费用申请 -->
    <CostApply ref="costApply" :isViewCost="isViewCost"></CostApply>

    <!-- 审核进度Drawer -->
    <el-drawer
      :title="`${lang === 'en' ? 'Review progress' : '审核进度'}`"
      :visible.sync="statusDrawer"
      :before-close="statusClose"
    >
      <Audit
        ref="Audit"
        :auditObjectId="auditObjectId"
        :auditType="auditType"
        @cancelClick="statusClose"
      />
    </el-drawer>

    <!-- 撤销弹窗 -->
    <el-dialog
      :title="`${lang === 'en' ? 'Cause for Undo' : '撤销原因'}`"
      :visible="cancelVisible"
      append-to-body
      width="500px"
      @close="onCancel"
      modal-append-to-body
    >
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input
        type="textarea"
        :rows="3"
        :placeholder="$t('placeholder.plsInput')"
        maxlength="500"
        show-word-limit
        v-model="cancelReason"
      ></el-input>
      <div class="footer mt30 t_a_c">
        <!-- 确认 -->
        <el-button type="primary" @click="cancelSure">
          {{ $t('inquiryList.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="onCancel">{{ $t('inquiryList.Cancel') }}</el-button>
      </div>
    </el-dialog>
    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
    <!-- 发票弹窗 -->
    <InvoiceDialog ref="invoiceDialog" @confirm="invoiceConfirm" />

    <!-- 退款申请 -->
    <RefundApplyModel ref="refundApply"></RefundApplyModel>
    <!-- 跟单进度 -->
    <TrackingNodes
      ref="tracking-nodes"
      @node-save-success="nodeSaveSuccess"
    ></TrackingNodes>
    <!-- 手动结算 -->
    <manual-settle-model
      :manualSettleVisible.sync="manualSettleVisible"
      :manualSettleId="manualSettleId"
      @refreshList="getList"
    ></manual-settle-model>
    <!-- 导出成功 -->
    <success-model :visible.sync="downloadVisible"></success-model>
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import {
    orderListPage,
    easyOrderListPage,
    orderUpdateCancellation,
    cancellationPage,
    orderBatchDelete,
    orderWhetherEditor,
    exportSalesOrdersSelect,
    exportSalesOrdersAll,
    orderPrintOrder,
    checkOrderInvoice,
    getTrackingNos,
    orderpdf,
  } from '@/api/order'
  import ErpDraggable from 'vuedraggable'
  import orderHighSearch from './components/orderHighSearch'
  import progressDrawer from './components/progressDrawer'
  import planOrder from './components/planOrder'
  import marksSet from './components/marksSet'
  import CostApply from '@/components/costApply'
  import InvoiceDialog from './components/invoice-dialog'
  import RefundApplyModel from './components/refundApplyModel'
  import manualEndDModel from './components/manualEndDModel.vue'
  import { getAuditObject, cancel } from '@/api/stock-center/inbound-sheet'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  import info from './util/data'
  import viewCost from '@/views/order/orderList/util'
  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import TrackingNodes from '@/components/tracking-nodes'
  import manualSettleModel from './components/manualSettle'
  import orderProductInfo from './components/order-product-info'
  import { OrderListInteractor } from '@/core/interactors/order/order-list'
  import SuccessModel from '@/components/tableImport/successModel'
  import { getKeyIds } from '@/utils/pagelist-util'
  import { hasRight } from '@/utils/permissionBtn'
  import { downloadUrl, download } from 'kits'

  let timer = null //查询定时器
  export default {
    name: 'OrderList',

    props: {},
    components: {
      ErpDraggable,
      orderHighSearch,
      progressDrawer,
      planOrder,
      marksSet,
      CostApply,
      Audit,
      InvoiceDialog,
      RefundApplyModel,
      manualEndDModel,
      NoViewCost,
      TrackingNodes,
      manualSettleModel,
      orderProductInfo,
      SuccessModel,
    },
    beforeRouteLeave(to, from, next) {
      if (
        to?.path !== '/order/orderList/orderDetail' ||
        to?.path !== '/order/orderList/orderAdd'
      ) {
        localStorage.removeItem('ORDER_LIST_FORM')
        localStorage.removeItem('ORDER_SEARCH_TYPE')
      }
      next()
    },
    data() {
      return {
        isViewCost: viewCost(),
        tablekey: Math.random(),
        // 1录入中 2已驳回 3已撤销 4审核中 5已通过 6待发货 7已发货 8待收货 9已收货 10已完成 11已作废
        queryForm: {
          orderStatus: '0',
          sortName: '', // 升序降序名称
          sortType: '', // 升序降序（ASC,DESC）
        },
        selectRows: [], //table勾选
        orderIds: [], //选中的id集合
        tableData: [],
        drawer: false, //高级筛选抽屉
        progressDrawerVisible: false, //订单进程抽屉
        planOrderVisible: false, //安排订单
        columns: info.columns,
        userCustomizeColumnId: '', //自义定保存id
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        progressId: '',
        orderStatusList: info.orderStatusList,
        operatVisible: false,
        isPlanOrder: true,
        isCancellation: true,
        isDelete: true,
        isCost: true,
        isClone: true, //是否显示克隆按钮
        isRefund: true,
        operatForm: {
          orderCause: '',
        },
        operatRules: {
          orderCause: [
            { required: true, message: '请输入原因', trigger: 'blur' },
          ],
        },
        auditObjectId: '', // 审核id（产品id）
        statusDrawer: false, // 审核进度,
        auditType: 1,
        cancelVisible: false,
        cancelReason: '', // 撤销原因
        cancellationList: [], // 作废列表
        cancellationVisible: true, // 作废保存按钮是否显示
        isExpandAll: false, // 是否展开全部
        showViewer: false, //图片预览参数
        imgUrl: [], //图片预览url
        manualVisible: false, //手动完结弹窗显示
        manualData: {},
        manualOrderId: '',
        searchType: null, // highSearch:高级筛选 null:普通筛选
        reasonObj: {}, // 驳回，调整原因
        manualSettleVisible: false,
        manualSettleId: '',
        showReason: false,
        printType: 0, // 4 打印发票  5 打印简易发票
        downloadVisible: false, // 导出成功提示框
        defaultSort: {}, // 默认排序
      }
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (to.query.cardClick === 'true') {
          vm.queryForm.orderStatus = '5'
        }
      })
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        this.columns.forEach((item) => {
          if (item.isSort) {
            this.defaultSort = { prop: item.prop, order: item.sortType }
          }
        })
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          1: 'primary',
          2: 'danger',
          3: 'primary',
          4: 'primary',
          5: 'warning',
          6: 'warning',
          7: 'success',
          8: 'danger',
        }
        return statusMap[status]
      },
    },

    activated() {
      this.getColumsData()
    },
    watch: {
      columns: {
        handler(val) {
          this.tablekey = Math.random()
        },
        deep: true,
      },
    },
    methods: {
      hasRight(val) {
        return hasRight(val)
      },
      showTrackingDetail({ orderId }) {
        getTrackingNos(orderId, 'ORDER').then((res) => {
          if (res?.code === '000000') {
            if (!res.data || res.data.length === 0) {
              this.$message.warning(this.lang === 'en' ? 'No Data' : '没有数据')
            } else {
              this.$refs['tracking-nodes'].initData(res.data)
            }
          }
        })
      },
      nodeSaveSuccess() {
        this.getList()
      },
      // 获取自定义保存
     async getColumsData(bol=true) {
        CustomCache.getColumns(2, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.columns = res.columns
              // 处理排序搜索条件
              this.columns.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'contractDate') {
                    this.queryForm.sortName = 'contractDate'
                  } else if (item.prop === 'shippingDate') {
                    this.queryForm.sortName = 'shippingDate'
                  } else if (item.prop === 'deliveryDate') {
                    this.queryForm.sortName = 'deliveryDate'
                  } else if (item.prop === 'auditPassTime') {
                    this.queryForm.sortName = 'auditPassTime'
                  } else if (item.prop === 'createTime') {
                    this.queryForm.sortName = 'createTime'
                  }
                  this.queryForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.queryForm.sortName = null)
                }
              })
              bol &&this.getList()
            }
          })
          .catch((err) => {
            console.log(err)
            this.getList()
          })
      },

      // 排序
      sortChange(column) {
        if (column.prop === 'contractDate') {
          this.queryForm.sortName = 'contractDate'
        } else if (column.prop === 'shippingDate') {
          this.queryForm.sortName = 'shippingDate'
        } else if (column.prop === 'deliveryDate') {
          this.queryForm.sortName = 'deliveryDate'
        } else if (column.prop === 'auditPassTime') {
          this.queryForm.sortName = 'auditPassTime'
        } else if (column.prop === 'createTime') {
          this.queryForm.sortName = 'createTime'
        } else {
          this.queryForm.sortName = ''
        }
        this.queryForm.sortType = column.order
        this.saveSort(column)
        this.initLoadingList()
      },
      // 保存排序
      saveSort(column) {
        this.columns.forEach((item) => {
          if (item.prop === column.prop) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave(false)
      },

      // 保存自定义显示列
      operateSave(showMessage) {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 2,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            if (showMessage !== false) {
              return this.$baseMessage(
                this.$t('reqmsg.$7'),
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          }
        })
      },
      // 下载合同
      downloadContract({ orderId, orderCode }) {
        orderpdf({
          orderId,
          printType: 1,
          checkFlag: viewCost() ? 1 : 0,
        }).then((res) => {
          if (res?.code === '000000') {
            downloadUrl(
              `https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/${res.data?.path}`,
              `${orderCode}.pdf`
            )
          }
        })
      },
      // 显示打印发票弹窗
      showInvoceDialog(row, type) {
        this.printType = type
        this.currentRow = row
        checkOrderInvoice(row.orderId).then((res) => {
          if (res && res.code === '000000') {
            this.$refs.invoiceDialog.showAddEdit(res.data, row.orderCode, type)
          } //是否生成发票（1：是；0否）
        })
      },

      // 点击发票确定事件
      invoiceConfirm(form) {
        orderPrintOrder({
          ...form,
          printType: this.printType,
          orderId: this.currentRow.orderId,
          checkFlag: this.isViewCost ? 1 : 0,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.$refs.invoiceDialog.close()
            if (res.data && res.data.printInvoiceVO) {
              const invoiceInfo = res.data.printInvoiceVO
              navigateTo(this, {
                pageType: PAGE_TYPES.previewFile,
                url: invoiceInfo.invoicePath,
                name: invoiceInfo.invoiceFileName,
              })
            }
          } else {
            this.$message.warning(res?.desc)
          }
        })
      },

      // 初始化列表
      initLoadingList() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.searchType = null
        this.queryForm = {
          orderStatus: '0',
          sortName: this.queryForm.sortName,
          sortType: this.queryForm.sortType,
          cusName: this.queryForm.cusName,
          orderStatus: this.queryForm.orderStatus,
        }
        this.getList()
      },

      // 获取列表数据
      getList() {
        // 重置展开的产品数据
        this.clearExpandCache()
        const params = {
          ...this.queryForm,
          ...this.page,
          orderStatus2: this.orderStatusHandle(this.queryForm.orderStatus2),
          keyIds: getKeyIds(this),
        }

        // 包含产品状态走高级搜索接口，其他的走快速搜索接口
        let reqMethod
        if (this.searchType === 'highSearch' && params.productStatus) {
          reqMethod = orderListPage
        } else {
          reqMethod = easyOrderListPage
        }

        reqMethod(params).then(async (res) => {
          const data = res?.data?.data || []

          // 结算状态数据处理
          let [_arr, _ids] = [[], []]
          data.forEach((item) => item.settlementFlag && _ids.push(item.orderId))
          if (_ids.length) {
            const _res = await OrderListInteractor.getStatusReason(_ids)

            _res.code === '000000' && (_arr = _res.data)
          }

          this.tableData = data.map((item, i) => {
            return {
              ...item,
              endStateDesc:
                item.endState == '1'
                  ? '自动完结'
                  : item.endState == '2'
                  ? '手动完结'
                  : item.endState,
              evaluationCodesList: item.evaluationCodes?.split(','),
              settlementInfo:
                (_arr.length && this.getSettlementInfo(_arr, item.orderCode)) ||
                '', // 结算状态信息
            }
          })
          this.total = res?.data?.total ?? 0
          this.isExpandAll = false
        })
        this.selectRows = []
      },

      // 获取结算状态信息
      getSettlementInfo(_arr, code) {
        const obj = _arr.find((item) => item.orderCode === code) || {}
        return obj.settlementTime + '&&' + obj.settlementRemark
      },

      // 显示邮箱列表
      emailDrawer(email) {
        this.recordDrawer = true
        this.clientEmail = email
      },

      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
        if (this.selectRows.length) {
          this.orderIds = []
          this.selectRows.forEach((item) => {
            this.orderIds.push(item.orderId)
          })
          // 判断同一币种
          const currency = this.selectRows[0].currency
          // 判断同一用户
          const infoId = this.selectRows[0].infoId
          // 安排订单
          /**
           * operateSalesOrder：1=有操作权限 0=无操作权限
           * settlementStatus：结算状态 0=初始化 1=未结算 2=结算中 3=已结算 4=待结算
           * orderStatus：5=已通过
           */
          this.isPlanOrder = this.selectRows.every(
            (item) =>
              item.operateSalesOrder === '1' &&
              item.settlementStatus < 2 &&
              item.orderStatus == 5
          )
          //克隆
          this.isClone = this.selectRows.every(
            (item) => item.operateSalesOrder === '1'
          )
          //删除
          this.isDelete = this.selectRows.every((item) => {
            return (
              item.settlementStatus < 2 &&
              this.isInclude(item.orderStatus, [1, 2, 3])
            )
          })
          // 作废
          this.isCancellation = this.selectRows.every(
            (item) =>
              item.operateSalesOrder === '1' &&
              item.settlementStatus < 2 &&
              this.isInclude(item.orderStatus, [1, 2, 3, 5, 6])
          )
          // 费用申请
          this.isCost = this.selectRows.every(
            (item) =>
              item.currency == currency &&
              (item.settlementStatus < 2 || item.settlementStatus == 4) &&
              this.isInclude(item.orderStatus, [5, 6, 7, 8, 9, 10])
          )
          // 退款申请
          this.isRefund = this.selectRows.every(
            (item) =>
              item.currency == currency &&
              item.infoId == infoId &&
              (item.settlementStatus < 2 || item.settlementStatus == 4) &&
              this.isInclude(item.orderStatus, [4, 5, 6, 7, 8, 9, 10])
          )
        } else {
          this.isPlanOrder = true
          this.isClone = true
          this.isDelete = true
          this.isCancellation = true
          this.isCost = true
          this.isRefund = true
        }
      },
      // 判断包含某个数据
      isInclude(val, arr) {
        arr = arr.map((item) => Number(item))
        return arr.includes(Number(val))
      },

      //审核进度查看
      statusTypeClick(row) {
        this.auditObjectId = row.orderId
        this.statusDrawer = true
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      //如果input为空
      searchInput(val) {
        val === '' ? this.getList() : ''
      },

      // 选择状态
     async orderStatusChange() {
        let val = Number(this.queryForm.orderStatus)
        if(val === 0){
         await this.getColumsData(false)
        }else{
          this.columnTable('contractDate', val, [2, 3, 5, 6, 7, 8, 9, 10, 11])
          this.columnTable('orderStatus', val, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 11])
          this.columnTable('shippingDate', val, [6])
          this.columnTable('recentPayDate', val, [8])
          this.columnTable('praDeliveryDate', val, [9, 10])
          this.columnTable('paymentSchedule', val, [5,9])
          this.columnTable('actualReceivablePrice', val, [10])
        }
        this.initLoadingList()
      },

      // 显示隐藏table某一列
      columnTable(prop, val, status) {
        if (status.includes(val)) {
          this.columns.find((item, i) => {
            if (item.prop == prop) {
              this.columns[i].isShow = true
            }
          })
        } else {
          this.columns.find((item, i) => {
            if (item.prop == prop) {
              this.columns[i].isShow = false
            }
          })
        }
      },

      // 选择进程
      progerssDrawer(id) {
        this.progressId = id
        this.progressDrawerVisible = true
      },

      // 高级筛选
      highSearch() {
        this.drawer = true
        let highForm = localStorage.getItem('ORDER_LIST_FORM')
        if (highForm) {
          highForm = JSON.parse(highForm)
        }
        setTimeout(() => {
          if (this.$refs.orderHighSearch) {
            this.$refs.orderHighSearch.initCreateDate()
            if (highForm) {
              this.$refs.orderHighSearch.setHighForm(highForm)
            }
          }
        }, 200)
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
        // 存在订单状态单独处理
        if (this.queryForm.orderStatus2?.length) {
          let status2
          if (!Array.isArray(this.queryForm.orderStatus2)) {
            status2 = this.queryForm.orderStatus2.split(',')
          } else {
            status2 = this.queryForm.orderStatus2
          }

          if (status2.includes('1')) {
            for (let i = status2.length - 1; i >= 0; i--) {
              if (status2[i] == '3') {
                status2.splice(i, 1)
              }
            }
          }
          this.queryForm.orderStatus2 = status2
        }
      },

      // 高级筛选订单状态处理
      orderStatusHandle(data) {
        if (Array.isArray(data)) {
          let array = [...new Set(data)]

          array.includes('1') ? array.push('2', '3') : ''
          return array.join(',')
        } else {
          return ''
        }
      },

      // 高级筛选触发
      searchClick(val, highForm) {
        // 变量保存
        val = JSON.parse(JSON.stringify(val)) || {}
        this.page.pageNo = 1
        if (Array.isArray(val.orderStatus2)) {
          val.orderStatus = '0'
        }
        val.sortType = this.queryForm.sortType
        val.sortName = this.queryForm.sortName
        this.queryForm = val
        this.searchType = 'highSearch'
        this.getList()
        this.highSearchClose()
        // 触发了高级筛选就设置缓存高级筛选表单数据
        localStorage.setItem('ORDER_LIST_FORM', highForm)
        // 设置查询类型
        localStorage.setItem(
          'ORDER_SEARCH_TYPE',
          this.searchType === 'highSearch' ? 'HIGH_SEARCH' : ''
        )
      },

      // 进程关闭
      progressCancel() {
        this.progressDrawerVisible = false
      },

      // 新增
      add() {
        this.$router.push('/order/orderList/orderAdd')
      },

      // 编辑
      edit(id, code) {
        // 待发货状态需要判断能否编辑
        orderWhetherEditor({ orderId: id }).then((res) => {
          if (res && res.code == '000000') {
            this.$router.push(
              `/order/orderList/orderAdd?orderId=${id}&orderCode=${code}`
            )
          }
        })
      },

      // 费用申请
      costApply() {
        if (this.selectRows.length < 1) {
          this.$message.warning(this.$t('reqmsg.G002'))
        } else {
          this.$refs.costApply.source = 'list'
          this.$refs.costApply.initLoading(1, this.selectRows)
        }
      },

      // 克隆
      cloneForm() {
        if (!this.selectRows.length) {
          this.$message.warning(this.$t('reqmsg.G002'))
        } else {
          if (this.selectRows.length > 1) {
            this.$message.warning(this.$t('reqmsg.G009'))
            return false
          } else {
            let id = this.selectRows[0].orderId
            this.$router.push(
              `/order/orderList/orderAdd?orderId=${id}&isClone=1`
            )
          }
        }
      },

      // 删除
      operateDelete(id) {
        if (!this.selectRows.length && !id) {
          this.$message.warning(this.$t('reqmsg.G002'))
        } else {
          let ids = id ? [id] : []
          if (!id) {
            this.selectRows.forEach((item) => ids.push(item.orderId))
          }
          this.$confirm(`是否确认删除?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              orderBatchDelete({ orderIds: ids }).then((res) => {
                if (res.code == '000000') {
                  this.$message.success('删除成功!')
                  this.initLoadingList()
                }
              })
            })
            .catch(() => {})
        }
      },

      // 显示作废弹窗
      cancellation() {
        let self = this
        if (!self.selectRows.length) {
          self.$message.warning(this.$t('reqmsg.G002'))
        } else if (self.selectRows.length > 1) {
          self.$message.warning(this.$t('reqmsg.G009'))
        } else {
          let code = self.selectRows[0].orderCode
          cancellationPage({ orderCode: code }).then((res) => {
            if (res.data && res.data.length) {
              const boo = res.data.every((item) => item.relationCode)
              self.cancellationVisible = boo ? true : false
            } else {
              self.cancellationVisible = true
            }
            self.cancellationList = res.data || []
            self.operatVisible = true
          })
        }
      },

      // 作废取消
      offerRemindCancel() {
        this.$refs['operatForm'].resetFields()
        this.operatVisible = false
      },

      // 作废提示
      operatSave() {
        this.$refs['operatForm'].validate((valid) => {
          if (valid) {
            this.$confirm(`确定作废, 操作之后无法修改?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.enquiryStatusSave()
              })
              .catch(() => {})
          }
        })
      },

      // 作废保存
      enquiryStatusSave() {
        const params = {
          orderIds: this.orderIds,
          orderCause: this.operatForm.orderCause,
        }
        orderUpdateCancellation(params).then((res) => {
          if (res && res.code == '000000') {
            this.operatVisible = false
            this.initLoadingList()
            this.$refs['operatForm'].resetFields()
            this.$message({ type: 'success', message: '作废成功!' })
          }
        })
      },

      // 安排订单
      planOrder() {
        if (!this.selectRows.length) {
          this.$message.warning(this.$t('reqmsg.G002'))
        } else if (this.selectRows.length > 20) {
          this.$message.warning(this.$t('reqmsg.G010'))
          // if (this.selectRows.length > 1) {
          //   this.$message.warning(this.$t('reqmsg.G009'))
          //   return false
          // } else {
          //   this.$refs.planOrder.showOrderInfo(
          //     this.orderIds,
          //     this.selectRows[0]
          //   )
          // }
        } else {
          this.$refs.planOrder.showOrderInfo(this.orderIds, this.selectRows)
        }
      },

      // 打印marks设置
      marksSet(id) {
        this.$refs.marksSet.initLoading(id)
      },

      //撤销
      revokeClick(row) {
        getAuditObject({
          orderId: row.orderId,
          auditType: 1,
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            // 获取提交审核（撤销所用）auditObjectId
            this.auditObjectId = res.data.auditObjectId
            this.cancelVisible = true
            this.getList()
          }
        })
      },

      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },

      //确定撤销
      cancelSure() {
        // 请输入撤销原因
        if (!this.cancelReason) {
          return this.$message.warning(this.$t('placeholder.plsRevocation'))
        }
        const {
          auditObjectId,
          auditStatus = 4,
          auditType = 1,
          cancelReason,
        } = this
        const { tenantId, userId, userName } = this.userInfo
        cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        }).then((res) => {
          if (res?.code === '000000') {
            // 撤销成功
            this.$message.success(this.$t('reqmsg.M2012'))
            this.cancelVisible = false
            this.getList()
          }
        })
      },

      // 导出
      download(command) {
        if (command == 'checked') {
          // 导出选中
          if (this.selectRows.length == 0) {
            this.$message.warning(this.$t('reqmsg.G002'))
            return
          }
          let ids = this.selectRows
            .map((item) => {
              return item.orderId
            })
            .join(',')
          exportSalesOrdersSelect({
            orderIds: ids,
            checkFlag: this.isViewCost ? 1 : 0,
          })
        } else {
          // 导出全部
          const params = {
            ...this.queryForm,
            orderStatus2: this.queryForm.orderStatus2
              ? this.queryForm.orderStatus2.join(',')
              : '',
            checkFlag: this.isViewCost ? 1 : 0,
          }
          exportSalesOrdersAll(params)
        }
        this.downloadVisible = true
      },

      // 展开不知道
      expandAll() {
        this.isExpandAll = !this.isExpandAll
        this.tableData.forEach((item) => {
          this.$refs.ErpTable.$refs.table.toggleRowExpansion(
            item,
            this.isExpandAll
          )
        })
      },
      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      // 退款申请
      refundApply() {
        if (this.selectRows.length < 1) {
          this.$message.warning(this.$t('reqmsg.G002'))
        } else {
          this.$refs.refundApply.init(this.selectRows, 1)
        }
      },

      // 手动结算
      manualSettle(row) {
        this.manualSettleVisible = true
        this.manualSettleId = row.orderId
      },

      // 结算状态中英文翻译
      getLangFinishType(state) {
        let text = ''
        switch (state) {
          case '手动完结':
            text = this.$t('orderList.ManuallyFinish')
            break
          case '自动完结':
            text = this.$t('orderList.AutoFinish')
            break
        }
        return text
      },

      // 地区中英文翻译
      getLangArea(state) {
        let text = ''
        switch (state) {
          case '合肥':
            text = this.$t('orderList.Hefei')
            break
          case '苏州':
            text = this.$t('orderList.Suzhou')
            break
          case '义乌':
            text = this.$t('orderList.Yiwu')
            break
        }
        return text
      },

      // 收款进度翻译
      getLangProgress(state) {
        let text = ''
        switch (state) {
          case '未收款':
            text = this.$t('orderList.Unpaid')
            break
          case '客户已付待入账':
            text = this.$t('orderList.CustomerHasPaidToBeCredited')
            break
          case '部分收款':
            text = this.$t('orderList.PartialPayment')
            break
          case '已收款':
            text = this.$t('orderList.PaymentReceived')
            break
          case '财务待确认':
            text = this.$t('orderList.FinanceToBeConfirmed')
            break
        }
        return text
      },

      expandChange({ row, expandedRows }) {
        console.log(row, expandedRows)
        this.expandedRows = expandedRows
        console.log('expandChange')
        if (timer) {
          console.log('timer')
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          this.queryProductList({ row, expandedRows })
        }, 1000)
      },
      async queryProductList({ row, expandedRows }) {
        this.expandedOrderCodes = this.expandedOrderCodes || []
        // 未请求的订单
        let unRequestOrderCodes = []
        expandedRows.forEach((expandedRow) => {
          if (!this.expandedOrderCodes.includes(expandedRow.orderCode)) {
            unRequestOrderCodes.push(expandedRow.orderCode)
          }
        })
        if (unRequestOrderCodes.length > 0) {
          let res = await OrderListInteractor.queryProductListApi(
            unRequestOrderCodes
          )
          this.handleProductList(res, expandedRows)
        }
      },
      // 处理请求获取的产品数据
      handleProductList(res, expandedRows) {
        if (res?.code === '000000') {
          if (Array.isArray(res.data)) {
            res.data.forEach((orderObj) => {
              let matchedTableItem = this.tableData.find(
                (tableData) => tableData.orderCode == orderObj.orderCode
              )

              if (matchedTableItem) {
                matchedTableItem.orderProductListVOS =
                  orderObj.orderProducts || []
              }
            })
          }
          expandedRows.forEach((expandedRow) => {
            if (!this.expandedOrderCodes.includes(expandedRow.orderCode)) {
              this.expandedOrderCodes.push(expandedRow.orderCode)
            }
          })
        }
      },
      clearExpandCache() {
        this.expandedOrderCodes = []
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    /deep/ .el-button,
    /deep/ .el-form {
      margin-bottom: 10px;
    }
  }
  .no-underline {
    text-decoration: none;
  }

  .tip-content {
    line-height: 18px;

    .el-tag--small {
      height: 12px;
      line-height: 12px;
      font-size: 12px;
      padding: 0;
      background: none;
      border: none;
    }
  }

  .tip-content + .tip-content {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px dashed #999;
  }

  .cancellation-dialog {
    .container {
      margin-bottom: 20px;

      .el-input-number {
        width: 120px;
        margin: 0 5px;
      }

      .el-checkbox-group {
        line-height: 20px;
      }

      p {
        line-height: 20px;
      }
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .expand-all {
    cursor: pointer;
    padding: 5px;
    transition: 0.2s;

    &.active {
      transform: rotate(90deg);
    }
  }

  ::v-deep {
    .el-table__row .el-button {
      margin: 0 5px;
    }
  }

  .colorblue {
    color: #1890ff;
    cursor: pointer;
  }

  .settleTip {
    max-width: 600px;
  }

  .statusTag {
    text-align: left;
    color: #333333;

    .textBg {
      box-sizing: border-box;
      padding: 1px 1px;
      margin-right: 5px;
      font-size: calc(12px * 0.8);
      color: #fff;
      border-radius: 2px;
    }
  }
</style>
