<template>
  <el-dialog
    v-if="modelVisible"
    width="1200px"
    :title="title"
    :visible.sync="modelVisible"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <div class="container">
        <div class="L">
          <div class="item">
            <!-- 成本价 -->
            <div class="T">{{ $t('inquiryDetails.CostPrice') }}</div>
            <div class="C">
              <el-row :gutter="10">
                <el-col :span="12">
                  <div v-if="netPriceInfo.base.standardCustomized === '标准品'">
                    ￥{{ form.costPrice }}
                  </div>
                  <div v-else>
                    <el-input
                      v-model="form.costPrice"
                      @blur="calcTariff"
                      :placeholder="$t('placeholder.plsInput')"
                      type="number"
                      clearable
                      maxlength="10"
                      :min="0"
                    >
                      <template slot="prepend">&nbsp;￥&nbsp;</template>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="item mt5 mb5">
            <!-- 汇率 -->
            <div class="T">
              <i class="red">*</i>
              {{ $t('inquiryDetails.ExchangeRate') }}
            </div>
            <div class="C">
              <el-row>
                <el-col :span="12">
                  <div v-if="netPriceInfo.base.standardCustomized === '标准品'">
                    {{ form.exchange }}
                  </div>
                  <div v-else>
                    <el-input
                      v-model="form.exchange"
                      @blur="exchangeBlur"
                      :placeholder="$t('placeholder.plsInput')"
                      type="number"
                      clearable
                      oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="12">
                  <span class="ml20">
                    ${{ (form.costPrice / form.exchange).toFixed(3) }}
                  </span>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="item mt5 mb5">
            <!-- 利润率 -->
            <div class="T">
              <i class="red">*</i>
              {{ $t('inquiryDetails.RateOfProfit') }}
            </div>
            <div class="C">
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    prop="profit"
                    :rules="[
                      {
                        required: true,
                        trigger: 'blur',
                        message: $t('placeholder.plsInput'),
                      },
                    ]"
                  >
                    <el-input
                      v-model="form.profit"
                      @blur="calcTariff('profit')"
                      @mousewheel.native.prevent
                      :placeholder="$t('placeholder.plsInput')"
                      type="number"
                      clearable
                      maxlength="10"
                      oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="item mt5 mb5">
            <!-- 关税率 -->
            <div class="T">
              <i class="red">*</i>
              {{ $t('inquiryDetails.TariffRate') }}
            </div>
            <div class="C">
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    prop="tariff"
                    :rules="[
                      {
                        required: true,
                        trigger: 'blur',
                        message: $t('placeholder.plsInput'),
                      },
                    ]"
                  >
                    <el-input
                      v-model="form.tariff"
                      @blur="calcTariff('tariff')"
                      @mousewheel.native.prevent
                      :placeholder="$t('placeholder.plsInput')"
                      type="number"
                      clearable
                      maxlength="10"
                      oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="item">
            <!-- 关税 -->
            <div class="T">
              {{ $t('inquiryDetails.Tarif') }}
              <em>
                <el-tooltip
                  placement="top"
                  effect="light"
                  :content="$t('inquiryDetails.Tips1')"
                >
                  <div slot="content">
                    <p>{{ $t('inquiryDetails.Tips1') }}</p>
                    <p class="mt5">
                      {{ form.costPrice || 0 }}*{{ form.tariff || 0 }}÷{{
                        form.exchange || 0
                      }}÷{{ form.profit || 0 }}÷2
                    </p>
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
            </div>
            <div class="C">$ {{ tariffValue }}</div>
          </div>
          <div class="item">
            <!-- 运费单价 -->
            <div class="T">{{ $t('inquiryDetails.ShippingFee') }}</div>
            <div class="C">
              <el-row>
                <el-col :span="12">
                  <div v-if="netPriceInfo.base.standardCustomized === '标准品'">
                    $ {{ form.transportPrice }}
                  </div>
                  <div v-else>
                    <el-input
                      v-model="form.transportPrice"
                      :placeholder="$t('placeholder.plsInput')"
                      type="number"
                      clearable
                      maxlength="10"
                      :min="0"
                      oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    >
                      <template slot="prepend">&nbsp;$&nbsp;</template>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="item">
            <div class="T">
              Blank Net Price
              <em>
                <el-tooltip
                  placement="top"
                  effect="light"
                  :content="$t('inquiryDetails.Tips2')"
                >
                  <div slot="content">
                    <p>{{ $t('inquiryDetails.Tips2') }}</p>
                    <p class="mt5">
                      （{{ form.costPrice || 0 }}/{{ form.exchange || 0 }} +
                      {{ form.transportPrice || 0 }}）/{{ form.profit || 0 }} +
                      {{ tariffValue || 0 }}
                    </p>
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
            </div>
            <div class="C">$ {{ calcBlankNetPrice() }}</div>
          </div>
          <div
            class="item"
            v-show="
              !['Blank', 'Sample'].includes(netPriceInfo.base.printingWay)
            "
          >
            <!-- 印刷单价 -->
            <div class="T">{{ $t('inquiryDetails.PringingFee') }}</div>
            <div class="C">
              <el-row>
                <el-col :span="12">
                  <div v-if="netPriceInfo.base.standardCustomized === '标准品'">
                    $ {{ form.basicPrintingPrice }}
                  </div>
                  <div v-else>
                    <el-input
                      v-model="form.basicPrintingPrice"
                      :placeholder="$t('placeholder.plsInput')"
                      type="number"
                      clearable
                      maxlength="10"
                      :min="0"
                      oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    >
                      <template slot="prepend">&nbsp;$&nbsp;</template>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div
            class="item"
            v-show="netPriceInfo.base.standardCustomized != '标准品'"
          >
            <!-- 规格加价 -->
            <div class="T">{{ $t('inquiryDetails.SpecificationMarkup') }}</div>
            <div class="C">
              <el-row>
                <el-col :span="12">
                  <div>
                    <el-input
                      v-model="form.specsPrice"
                      :placeholder="$t('placeholder.plsInput')"
                      type="number"
                      clearable
                      maxlength="10"
                      :min="0"
                      oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    >
                      <template slot="prepend">&nbsp;$&nbsp;</template>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="item">
            <div class="T">
              {{ $t('inquiryDetails.Trial') }} Net Price
              <em v-if="netPriceInfo.base.standardCustomized === '标准品'">
                <el-tooltip
                  placement="top"
                  effect="light"
                  :content="$t('inquiryDetails.Tips3')"
                >
                  <div slot="content">
                    <p>{{ $t('inquiryDetails.Tips3') }}</p>
                    <p class="mt5">
                      （{{ form.costPrice || 0 }}/{{ form.exchange || 0 }} +
                      {{ form.transportPrice || 0 }}）/{{ form.profit || 0 }} +
                      {{ tariffValue || 0 }} +
                      {{ form.basicPrintingPrice || 0 }}
                    </p>
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
              <em v-else>
                <el-tooltip
                  placement="top"
                  effect="light"
                  :content="$t('inquiryDetails.Tips24')"
                >
                  <div slot="content">
                    <p>{{ $t('inquiryDetails.Tips24') }}</p>
                    <p class="mt5">
                      （{{ form.costPrice || 0 }}/{{ form.exchange || 0 }} +
                      {{ form.transportPrice || 0 }}）/{{ form.profit || 0 }} +
                      {{ tariffValue || 0 }} +
                      {{ form.basicPrintingPrice || 0 }} +
                      {{ form.specsPrice }}
                    </p>
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
            </div>
            <div class="C">$ {{ calcTrialNetPrice() }}</div>
          </div>
        </div>
        <div class="R">
          <div class="item">
            <!-- 报价数量 -->
            <div class="T">{{ $t('inquiryDetails.QuoteQuantity') }}</div>
            <div class="C">{{ quoteNumber }}</div>
          </div>
          <div class="item">
            <!-- 装箱数量 -->
            <div class="T">{{ $t('productDetail.PackingQuantity') }}</div>
            <div class="C">
              {{ pro.numberPerBox }}
            </div>
          </div>
          <div class="item">
            <!-- 装箱尺寸 -->
            <div class="T">{{ $t('productDetail.PackingSize') }}</div>
            <div class="C">
              {{
                pro.boxLengthCm +
                'cm' +
                ' X ' +
                pro.boxWidthCm +
                'cm' +
                ' X ' +
                pro.boxHeightCm +
                'cm'
              }}
            </div>
          </div>
          <div class="item">
            <!-- 快递基准运费 -->
            <div class="T">{{ $t('inquiryDetails.EBF') }}</div>
            <div class="C">
              <span v-if="netPriceInfo.info.transportWayCn">
                {{
                  lang === 'en'
                    ? netPriceInfo.info.transportWayEn
                    : netPriceInfo.info.transportWayCn
                }}
              </span>
              <span v-else>--</span>
              <!-- 查看详情 -->
              <span
                class="view"
                @click="view"
                v-show="netPriceInfo.info.transportWayCn && lang != 'en'"
              >
                {{ $t('inquiryDetails.ViewDetails') }}
              </span>
            </div>
          </div>
          <div class="item">
            <!-- 计费方式 -->
            <div class="T">{{ $t('inquiryDetails.ChargeMode') }}</div>
            <div class="C">{{ billingMethodName }}</div>
          </div>

          <section v-if="billingMethod === 1">
            <div class="item">
              <!-- 装箱体积 -->
              <div class="T">{{ $t('inquiryDetails.PackingVolume') }}</div>
              <div class="C">
                <span v-if="volume">
                  {{ Number(volume / 1000000).toFixed(3) }} m³
                </span>
                <span v-else>--</span>
              </div>
            </div>
            <div class="item">
              <!-- 计费体积 -->
              <div class="T">
                {{ $t('inquiryDetails.BillingVolume') }}
                <em>
                  <el-tooltip
                    placement="top"
                    effect="light"
                    :content="$t('inquiryDetails.Tips14')"
                  >
                    <div slot="content">
                      <p>{{ $t('inquiryDetails.Tips14') }}</p>
                      <p class="mt5">
                        {{ quoteNumber || 0 }}/{{ pro.numberPerBox || 0 }}*{{
                          Number(volume / 1000000).toFixed(3) || 0
                        }}
                      </p>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </em>
              </div>
              <div class="C">
                <span v-if="pro.numberPerBox && volume">
                  {{
                    Number(
                      ((quoteNumber / pro.numberPerBox) * volume) / 1000000
                    ).toFixed(3)
                  }}
                  m³
                </span>
                <span v-else>--</span>
              </div>
            </div>
          </section>
          <section v-else-if="[2, 3].includes(billingMethod)">
            <div class="item">
              <!-- 体积重 -->
              <div class="T">
                {{ $t('inquiryDetails.VolumeWeight') }}
                <em>
                  <el-tooltip
                    placement="top"
                    effect="light"
                    :content="
                      billingMethod === 2
                        ? $t('inquiryDetails.Tips12')
                        : $t('inquiryDetails.Tips4')
                    "
                  >
                    <div slot="content">
                      <p>
                        {{
                          billingMethod === 2
                            ? $t('inquiryDetails.Tips12')
                            : $t('inquiryDetails.Tips4')
                        }}
                      </p>
                      <p class="mt5">
                        {{ pro.boxLengthCm }}X{{ pro.boxWidthCm }}X{{
                          pro.boxHeightCm
                        }}/{{ billingMethod === 2 ? '6000' : '5000' }}
                      </p>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </em>
              </div>
              <div class="C">
                {{ (billingMethod === 2 ? volume6000 : volume5000).toFixed(3) }}
                kg
              </div>
            </div>
            <div class="item">
              <!-- 装箱重量 -->
              <div class="T">{{ $t('inquiryDetails.PackingWeight') }}</div>
              <div class="C">
                <span v-if="pro.boxWeightKgs">
                  {{ Number(pro.boxWeightKgs).toFixed(3) }} kg
                </span>
                <span v-else>--</span>
              </div>
            </div>
            <div class="item">
              <!-- 计费重量 -->
              <div class="T">
                {{ $t('inquiryDetails.ChargedWeight') }}
                <em>
                  <el-tooltip
                    placement="top"
                    effect="light"
                    :content="$t('inquiryDetails.Tips5')"
                  >
                    <div slot="content">
                      <p>{{ $t('inquiryDetails.Tips5') }}</p>
                      <p class="mt5">
                        <span>{{ quoteNumber }}/{{ pro.numberPerBox }}*</span>
                        <span>
                          {{
                            Number(
                              (billingMethod === 2
                                ? volume6000
                                : volume5000
                              ).toFixed(3)
                            ) > Number(Number(pro.boxWeightKgs).toFixed(3))
                              ? (billingMethod === 2
                                  ? volume6000
                                  : volume5000
                                ).toFixed(3)
                              : Number(pro.boxWeightKgs).toFixed(3)
                          }}
                        </span>
                      </p>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </em>
              </div>
              <div class="C" v-if="billingMethod === 2">
                <span
                  v-if="quoteNumber && pro.numberPerBox && pro.boxWeightKgs"
                >
                  {{
                    (
                      (quoteNumber / pro.numberPerBox) *
                      (pro.boxWeightKgs > volume6000
                        ? pro.boxWeightKgs
                        : volume6000)
                    ).toFixed(3)
                  }}
                  kg
                </span>
                <span v-else>--</span>
              </div>
              <div class="C" v-else>
                <span
                  v-if="quoteNumber && pro.numberPerBox && pro.boxWeightKgs"
                >
                  {{
                    (
                      (quoteNumber / pro.numberPerBox) *
                      (pro.boxWeightKgs > volume5000
                        ? pro.boxWeightKgs
                        : volume5000)
                    ).toFixed(3)
                  }}
                  kg
                </span>
                <span v-else>--</span>
              </div>
            </div>
          </section>
          <section v-else-if="billingMethod === 5">
            <div class="item">
              <!-- 装箱重量 -->
              <div class="T">{{ $t('inquiryDetails.PackingWeight') }}</div>
              <div class="C">
                <span v-if="pro.boxWeightKgs">
                  {{ Number(pro.boxWeightKgs).toFixed(3) }} kg
                </span>
                <span v-else>--</span>
              </div>
            </div>
            <div class="item">
              <!-- 计费重量 -->
              <div class="T">
                {{ $t('inquiryDetails.ChargedWeight') }}
                <em>
                  <el-tooltip
                    placement="top"
                    effect="light"
                    :content="$t('inquiryDetails.Tips13')"
                  >
                    <div slot="content">
                      <p>{{ $t('inquiryDetails.Tips13') }}</p>
                      <p class="mt5">
                        <span>{{ quoteNumber }}/{{ pro.numberPerBox }}*</span>
                        <span>
                          {{ Number(Number(pro.boxWeightKgs).toFixed(3)) || 0 }}
                        </span>
                      </p>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </em>
              </div>
              <div class="C">
                <span
                  v-if="quoteNumber && pro.numberPerBox && pro.boxWeightKgs"
                >
                  {{
                    (
                      (quoteNumber / pro.numberPerBox) *
                      pro.boxWeightKgs
                    ).toFixed(3)
                  }}
                  kg
                </span>
                <span v-else>--</span>
              </div>
            </div>
          </section>

          <div class="item" v-show="tableData.length">
            <!-- 匹配的计价区间 -->
            <div class="T">{{ $t('inquiryDetails.TMVI') }}</div>
            <div class="C">
              <el-table
                :data="tableData"
                border
                :cell-style="{ 'text-align': 'center' }"
                :header-cell-style="{ 'text-align': 'center' }"
              >
                <!-- 国家 -->
                <el-table-column
                  :label="$t('inquiryDetails.Country')"
                  prop="country"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.country">
                      {{
                        lang === 'en'
                          ? scope.row.countryEn
                          : scope.row.countryCn
                      }}
                    </div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <!-- 计价类型 -->
                <el-table-column
                  :label="$t('inquiryDetails.ValuationType')"
                  width="120"
                >
                  <template slot-scope="scope">
                    <div v-if="[0, 1].includes(scope.row.pricingType)">
                      {{
                        scope.row.pricingType === 0
                          ? lang === 'en'
                            ? 'total prices'
                            : '总价'
                          : lang === 'en'
                          ? 'unit price'
                          : '单价'
                      }}
                    </div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <!-- 范围 -->
                <el-table-column :label="$t('inquiryDetails.Scope')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.startBillingLadder != null">
                      {{
                        scope.row.startBillingLadder +
                        '~' +
                        scope.row.billingLadder
                      }}
                      {{ billingMethod === 1 ? 'm³' : 'kg' }}
                    </div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <!-- 价格 -->
                <el-table-column
                  :label="$t('inquiryDetails.Price')"
                  prop="price"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.price">
                      {{
                        (netPriceInfo.info.currency === '美元' ? '$ ' : '￥ ') +
                        scope.row.price
                      }}
                    </div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="item">
            <!-- 运费单价 -->
            <div class="T">
              {{ $t('inquiryDetails.ShippingFee') }}
              <em v-if="tableData.length">
                <el-tooltip placement="top" effect="light">
                  <div slot="content">
                    <p>
                      {{ $t('inquiryDetails.Tips6') }}
                    </p>
                    <p class="mt5">
                      <span v-if="tableData[0].pricingType === 0">
                        {{ tableData[0].price || 0 }}/{{ exchange || 0 }}/{{
                          quoteNumber || 0
                        }}
                      </span>
                      <span v-else>
                        {{ tableData[0].price || 0 }}
                        *{{
                          billingMethod === 1
                            ? (
                                (quoteNumber / pro.numberPerBox) *
                                Number(volume / 1000000)
                              ).toFixed(3)
                            : (
                                (quoteNumber / pro.numberPerBox) *
                                pro.boxWeightKgs
                              ).toFixed(3)
                        }}/{{ netPriceInfo.base.exchange || 0 }}/{{
                          quoteNumber || 0
                        }}
                      </span>
                    </p>
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </em>
            </div>
            <div class="C">$ {{ totalTransportPrice }}</div>
          </div>
        </div>
      </div>
    </el-form>

    <div class="text-center mt30">
      <!-- 取消 -->
      <el-button @click="close()">{{ $t('inquiryList.Cancel') }}</el-button>
      <!-- 确认 -->
      <el-button type="primary" @click="save()">
        {{ $t('inquiryList.Confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      title: '',
      volume: 0,
      pro: {},
      type: 0, // 0：标准品 1：订制品
      exchange: 1, // 汇率
      volume5000: 0,
      volume6000: 0,
      totalTransportPrice: 0, // 运费单价
      tariffValue: 0, // 关税
      trialNetPrice: 0, // 试算NetPrice
      quoteNumber: '', // 报价数量
      billingMethod: 0, // 计费方式类型
      billingMethodName: '--', // 计费方式名称
      billingOptions: [
        {
          value: 1,
          label: '体积',
          labelEn: 'Volume',
        },
        {
          value: 2,
          label: '毛重、体积重(体积/6000) 取Max',
          labelEn: 'The gross weight and volume weight (volume /6000) are Max',
        },
        {
          value: 3,
          label: '毛重、体积重(体积/5000) 取Max',
          labelEn:
            'The gross weight and volume weight (volume /5000) shall be Max',
        },
        {
          value: 5,
          label: '重量',
          labelEn: 'Weight',
        },
      ],
      form: {
        exchange: 1, // 税率
        costPrice: '', // 成本价
        profit: '', // 利润率
        tariff: '', // 关税率
        transportPrice: '', // 运费单价
        basicPrintingPrice: '', // 印刷单价
        specsPrice: '', // 规格加价
      },
      tableData: [],
      info: {},
      rules: {},
      netPriceInfo: {},
      modelVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  watch: {
    modelVisible(val) {
      if (val) {
        this.init()
      }
    },
  },
  created() {},
  methods: {
    // 初始化
    async init() {
      let base = this.netPriceInfo.base
      let info = this.netPriceInfo.info

      this.type = base.standardCustomized === '标准品' ? 0 : 1
      if (this.lang === 'en') {
        this.title =
          this.type === 0 ? 'Trial Net Price' : 'Adjustment Net Price'
      } else {
        this.title = this.type === 0 ? '试算Net Price' : '调整Net Price'
      }
      this.exchange = info.currency === '美元' ? 1 : info.exchange
      this.form.exchange = info.exchange
      this.form.costPrice = info.costPrice
      this.form.profit =
        info.profitRate < 1
          ? Number(info.profitRate).toFixed(3)
          : Number(info.profitRate)
      this.form.tariff =
        info.taxRate > 0 && info.taxRate < 1
          ? Number(info.taxRate).toFixed(3)
          : Number(info.taxRate)
      this.quoteNumber = info.num
      this.pro = this.netPriceInfo.base.specsDTOS[0]
      this.volume = Number(
        this.pro.boxLengthCm * this.pro.boxWidthCm * this.pro.boxHeightCm
      )
      this.volume5000 = Number(this.volume / 5000)
      this.volume6000 = Number(this.volume / 6000)
      this.form.basicPrintingPrice =
        this.type === 0 ? info.basicPrintingPrice : info.printingPrice
      this.form.specsPrice = this.type === 0 ? 0 : info.specsPrice.toFixed(3)
      // 左边的运费单价
      this.form.transportPrice =
        this.type === 0 ? info.transportPrice : info.totalTransportPrice
      // 右边的运费单价
      this.totalTransportPrice = info.transportPrice
      this.tableData = info.freightSettingLadderVO
        ? [{ ...info.freightSettingLadderVO }]
        : []
      this.billingMethod = info.billingMethod // 计费方式
      this.getBillingMethod(info.billingMethod)
      this.calcTariff()
    },

    // 获取计费方式名称
    getBillingMethod(n) {
      let item = this.billingOptions.find((item) => item.value == n)
      if (item) {
        this.billingMethodName = this.lang === 'en' ? item.labelEn : item.label
      }
    },

    // Blank NetPrice
    calcBlankNetPrice() {
      let [costPrice, exchange, profit, transportPrice] = [
        this.form.costPrice,
        this.form.exchange,
        Number(this.form.profit),
        Number(this.form.transportPrice),
      ]

      if (costPrice && exchange && profit) {
        const price = (
          (costPrice / exchange + transportPrice) / profit +
          this.tariffValue
        ).toFixed(3)
        return price
      } else {
        return 0
      }
    },

    // 计算关税
    calcTariff(str) {
      if (str === 'profit') {
        if (this.form.profit == 0 || this.form.profit > 1) this.form.profit = 1
      } else if (str === 'tariff') {
        if (this.form.tariff > 1) this.form.tariff = 1
      }
      let [costPrice, tariff, profit, exchange] = [
        this.form.costPrice,
        Number(this.form.tariff),
        Number(this.form.profit),
        this.form.exchange,
      ]
      if (costPrice && profit && exchange) {
        this.tariffValue = Number(
          ((costPrice * tariff) / exchange / profit / 2).toFixed(3)
        )
      }
    },

    // 试算NetPrice
    calcTrialNetPrice() {
      let [
        costPrice,
        profit,
        exchange,
        transportPrice,
        basicPrintingPrice,
        specsPrice,
      ] = [
        this.form.costPrice,
        Number(this.form.profit),
        this.form.exchange,
        Number(this.form.transportPrice),
        Number(this.form.basicPrintingPrice),
        Number(this.form.specsPrice),
      ]
      if (costPrice && profit && exchange) {
        this.trialNetPrice = (
          (costPrice / exchange + transportPrice) / profit +
          this.tariffValue +
          basicPrintingPrice +
          specsPrice
        ).toFixed(3)

        return this.trialNetPrice
      }
    },

    // 汇率失焦事件
    exchangeBlur() {
      if (this.form.exchange == 0) this.form.exchange = 1
      if (this.form.exchange.length > 10) {
        this.form.exchange = this.form.exchange.substring(0, 10)
      }
      this.calcTariff()
    },

    // 查看快递基准运费详情
    view() {
      let id = this.netPriceInfo.info.freightSettingId
      let routeData = this.$router.resolve({
        path: '/product/freightSettings/detailFreight',
        query: {
          id: id,
          noReturn: true,
        },
      })
      window.open(routeData.href, '_blank')
    },

    // 确认
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit(
            'adjustmentNetPrice',
            [
              this.form.costPrice,
              this.form.profit,
              this.form.tariff,
              this.form.transportPrice,
              this.form.basicPrintingPrice,
              this.trialNetPrice,
              this.form.exchange,
              this.form.specsPrice,
            ], // 成本价-利润率-关税率-运费单价-印刷单价-试算Net Price-汇率-规格加价
            this.netPriceInfo.baseIndex,
            this.netPriceInfo.infoIndex
          )
          this.close()
        }
      })
    },

    // 取消
    close() {
      this.$refs.ruleForm.resetFields()
      this.modelVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  .item {
    display: flex;
    line-height: 40px;
    .T {
      text-align: right;
    }
  }
  .L {
    width: 520px;
    .T {
      width: 130px;
    }
    .C {
      width: 390px;
      margin-left: 20px;
    }
  }
  .R {
    width: 640px;
    border-left: 1px solid #ededed;
    overflow-y: auto;
    padding-left: 20px;
    .T {
      width: 150px;
    }
    .C {
      width: 480px;
      margin-left: 20px;
      .view {
        color: #409eff;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
::v-deep {
  .el-input {
    width: 100%;
  }
  .el-form-item--small.el-form-item {
    margin: 4px 0;
  }
  .el-table {
    .el-table__header tr,
    .el-table__header th {
      padding: 0;
    }
  }
}
</style>
