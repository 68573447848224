<template>
  <div class="box">
    <div class="mail-detail" v-if="detail">
      <div class="mail-detail-base">
        <div class="title-box">
          <div class="mail-main-title">
            <span class="title" v-html="detail_title"></span>
            <span class="order-num" v-if="detail.baseInfo && detail.baseInfo.businessJson">
              <!-- 询盘号 -->
              {{ $t('other.inquiryNumber') }}
              {{ detail.baseInfo.businessJson }}
            </span>
            <div class="label-item" v-for="label in detail.mailLabelConfigVOS" :key="label.mailLabelConfigId"
              :style="{ 'background-color': label.labelColor }">
              <span class="label">{{ label.labelName }}</span>
            </div>
          </div>
        </div>
        <div class="info-wrapper">
          <div class="info-main" v-if="infoDetailed">
            <dl class="base-item">
              <dt>
                <!-- 发件人 -->
                {{ $t('myMail.Sender') }}
              </dt>
              <dd v-if="detail.mailHeader && detail.mailHeader.originFrom">
                <span class="weight">{{ detail.mailHeader.originFrom }}</span>
              </dd>
              <dd v-if="
                  !detail.mailHeader &&
                  detail.baseInfo &&
                  detail.baseInfo.sendEmail
                ">
                <span class="weight">
                  {{ detail.baseInfo.sendEmail.split('@')[0] }}
                </span>
                <span v-text="`<${detail.baseInfo.sendEmail}>`"></span>
              </dd>
            </dl>
            <dl class="base-item">
              <dt>{{ $t('myMail.Time') }}</dt>
              <dd>
                <span class="weight" v-if="lang != 'en'">
                  {{
                  detail.baseInfo.timingSwitch
                  ? utils.formatTime(
                  detail.baseInfo.timingSendTime,
                  'mail-info'
                  )
                  : utils.formatTime(detail.baseInfo.sendTime, 'mail-info')
                  }}
                </span>
                <span v-else class="weight">
                  {{
                  detail.baseInfo.timingSwitch
                  ? utils.formatTimeToEnglishDate(
                  detail.baseInfo.timingSendTime
                  )
                  : utils.formatTimeToEnglishDate(detail.baseInfo.sendTime)
                  }}
                </span>
              </dd>
            </dl>
            <dl class="base-item">
              <dt>
                <!-- 收件人 -->
                {{ $t('myMail.Recipient') }}
              </dt>
              <dd v-if="
                  !detail.mailHeader &&
                  detail.extendInfo &&
                  detail.extendInfo.receiveEmails
                ">
                <span v-for="(v, index) in detail.extendInfo.receiveEmails.split(
                    ','
                  )" :key="index">
                  <span class="weight">{{ v.split('@')[0] }}</span>
                  <span v-text="`<${v}>;`"></span>
                </span>
              </dd>
              <dd v-if="detail.mailHeader && detail.mailHeader.originTo">
                <span v-for="(v, index) in detail.mailHeader.originTo.split(',')" :key="index">
                  <span class="weight">{{ v + ';' }}</span>
                </span>
              </dd>
            </dl>
            <dl class="base-item" v-if="
                detail.extendInfo &&
                detail.extendInfo.ccEamils &&
                !('mailHeader' in detail)
              ">
              <dt>
                <!-- 抄送人 -->
                {{ $t('other.CCperson') }}
              </dt>
              <dd v-if="detail.mailHeader">
                <span class="weight">{{ detail.mailHeader.originCc }}</span>
              </dd>
              <dd v-if="
                  !detail.mailHeader &&
                  detail.extendInfo &&
                  detail.extendInfo.ccEamils
                ">
                <span v-for="(v, index) in detail.extendInfo.ccEamils.split(',')" :key="index">
                  <span class="weight">{{ v.split('@')[0] }}</span>
                  <span v-text="`<${v}>;`"></span>
                </span>
              </dd>
            </dl>
            <dl class="base-item" v-if="
                'mailHeader' in detail &&
                detail.mailHeader &&
                detail.mailHeader.originCc
              ">
              <dt>
                <!-- 抄送人 -->
                {{ $t('other.CCperson') }}
              </dt>
              <dd>
                <span v-for="(v, index) in detail.mailHeader.originCc.split(',')" :key="index">
                  <span class="weight">{{ v + ';' }}</span>
                </span>
              </dd>
            </dl>
            <dl class="base-item" v-if="
                detail.extendInfo &&
                detail.extendInfo.ccEamils &&
                !('mailHeader' in detail)
              ">
              <dt>
                <!-- 密送人 -->
                {{ $t('other.CC') }}
              </dt>
              <dd>
                <span v-for="(v, index) in detail.extendInfo.bccEmails.split(',')" :key="index">
                  <span class="weight">{{ v.split('@')[0] }}</span>
                  <span v-text="`<${v}>;`"></span>
                </span>
              </dd>
              <dd v-if="detail.mailHeader && detail.mailHeader.originBcc">
                <span v-text="`<${detail.mailHeader.originBcc}>;`"></span>
              </dd>
            </dl>
            <dl class="base-item" v-if="
                'mailHeader' in detail &&
                detail.mailHeader &&
                detail.mailHeader.originBcc
              ">
              <dt>
                <!-- 密送人 -->
                {{ $t('other.CC') }}
              </dt>
              <dd>
                <span v-for="(v, index) in detail.mailHeader.originBcc.split(',')" :key="index">
                  <span class="weight">{{ v + ';' }}</span>
                </span>
              </dd>
            </dl>
          </div>
          <div class="info-main" v-else>
            <div class="info-simple">
              <span v-if="detail.mailHeader && detail.mailHeader.originFrom">
                {{ detail.mailHeader.originFrom }}
              </span>
              <span v-else>
                {{
                detail.baseInfo && detail.baseInfo.sendEmail
                ? detail.baseInfo.sendEmail.split('@')[0]
                : ''
                }}
                {{
                detail.baseInfo && detail.baseInfo.sendEmail
                ? '&lt;' + detail.baseInfo.sendEmail + '&gt;'
                : ''
                }}
              </span>
              <span class="desc" v-if="detail.baseInfo">
                <!-- 于 -->
                {{ $t('other.At') }}
                <span v-if="lang != 'en'">
                  {{
                  detail.baseInfo.timingSwitch
                  ? utils.formatTime(
                  detail.baseInfo.timingSendTime,
                  'mail-info'
                  )
                  : utils.formatTime(detail.baseInfo.sendTime, 'mail-info')
                  }}
                </span>
                <span v-else>
                  {{
                  detail.baseInfo.timingSwitch
                  ? utils.formatTimeToEnglishDate(
                  detail.baseInfo.timingSendTime
                  )
                  : utils.formatTimeToEnglishDate(detail.baseInfo.sendTime)
                  }}
                </span>
                <!-- 发送给 -->
                {{ $t('other.sendto') }}
              </span>
              <template v-if="detail.mailHeader && detail.mailHeader.originTo">
                <span v-for="(v, index) in detail.mailHeader.originTo.split(',')" :key="index">
                  <span class="weight">{{ v + ';' }}</span>
                </span>
              </template>
              <template v-if="
                  !detail.mailHeader &&
                  detail.extendInfo &&
                  detail.extendInfo.receiveEmails
                ">
                <span v-for="(v, index) in detail.extendInfo.receiveEmails.split(
                    ','
                  )" :key="index">
                  <span class="weight">{{ v.split('@')[0] }}</span>
                  <span v-text="`<${v}>;`"></span>
                </span>
              </template>
            </div>
          </div>
          <span class="btn-key" @click="infoDetailed = !infoDetailed" v-if="detail.baseInfo">
            <!-- {{ infoDetailed ? '精简信息>>' : '详细信息>>' }} -->
            {{
            infoDetailed
            ? $t('other.Condensedinformation')
            : $t('other.details')
            }}
          </span>
        </div>
        <div class="myremark" v-if="remarkFlag">
          <div class="remark-text" v-if="!remarkEditFlag">
            <span class="weight">{{ detail.baseInfo.emailMemo }}</span>
          </div>
        </div>
      </div>
      <iframe class="mail-iframe" style="border: none" src="./mailinfo.html"></iframe>
      <div class="mail-deatil-bottom">
        <!-- 邮件附件 -->
        <MailFiles ref="mailFiles" :show-close="false" />
      </div>
    </div>
    <div class="no-mail" v-else>
      <div>
        <img src="../../../../assets/mail_images/nomail.png" alt="" />
        <!-- <p>未选择邮件</p> -->
        <p>{{ $t('other.Mailnotselected') }}</p>
      </div>
    </div>
    <AssignmentAndTransfer :assignmentAndTransferBol.sync="assignmentAndTransferBol" :mailIdArr="mailIdArr"
      :title="$t('mailConfig.assign')" @saveFn="saveFn" ref="assignmentAndTransfer" :mailServer="mailServer">
    </AssignmentAndTransfer>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import { BusEmit, BusOn } from '@/utils/eventBus'
  import AssignmentAndTransfer from '@/components/AssignmentAndTransfer/index'
  import { addTargetBlank } from 'kits'
  import { getEnclosureList, getUnprocessedContentAttachments, processFile, replaceSrcWithLoadingSrc } from '../../my-mail/utils/file'
  import MailFiles from '@/components/MailFiles'
  export default {
    name: 'MailCenterMailInfos',
    mixins: [BusOn],
    components: {
      AssignmentAndTransfer,
      MailFiles
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
      }),
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    data() {
      return {
        infoDetailed: false, //是否显示详细信息
        addContactVisible: false,
        addContactConfig: null,
        detail: {},
        quickReplyInfo: '',
        remarkEditFlag: false,
        remarkFlag: false,
        myRemark: '',
        detail_content: '',
        detail_title: '',
        allowAssign: false, //是否显示分配按钮
        assignmentAndTransferBol: false,
        mailIdArr: [],
        mailOptions: [],
        mailServer: '', //邮箱服务
      }
    },
    methods: {
      //获取邮箱分配权限
      getAllowAssignFn(bol) {
        this.allowAssign = bol
      },
      //邮箱下拉框数据
      getMailOptions(arr) {
        this.mailOptions = arr
      },
      // 点击分配
      distributeFn() {
        this.mailIdArr = [this.detail?.baseInfo?.mailId]
        this.assignmentAndTransferBol = true
      },
      // 分配成功刷新邮件列表
      saveFn() {
        BusEmit('MailCenterToolBars', 'searchFilter')
      },
      handleOffer() {
        this.$refs.addOffer.addOfferVisible = true
      },
      setContent(mail) {
        this.detail_content = mail.content
        this.detail_title = mail.title
      },
      // 处理正文中未处理的附件
      handleContentAttachments(unprocessedContentAttachments) {
        // 获取正文中未处理的附件
        unprocessedContentAttachments = getUnprocessedContentAttachments(unprocessedContentAttachments)
        if (unprocessedContentAttachments.length > 0) {
          unprocessedContentAttachments.forEach(attach => {
            processFile(attach, this, 3, true)
          })
        }
      },
      // 展示详情
      async show(data) {
        this.remarkEditFlag = false
        this.remarkFlag = false
        this.myRemark = ''
        this.detail = data
        if (this.detail && this.detail.extendInfo)
          this.detail.extendInfo.content = this.detail_content
        if (!data) {
          return
        }
        // 附件处理
        data.enclosureList = getEnclosureList(data.enclosureList || [], data.unprocessedAttachments)
        setTimeout(() => {
          this.$refs.mailFiles.showFiles(data.enclosureList)
        }, 1000)
        BusEmit('MailCenterMailLists', 'resetMailItemContet', {
          content: data?.extendInfo?.content,
          mailId: data?.baseInfo.mailId,
        })
        let iframe = document.querySelector('.mail-iframe')
        if (!iframe) {
          await new Promise(res => {
            setTimeout(res, 1500)
          })
          iframe = document.querySelector('.mail-iframe')
        }

        const iframedoc =
          iframe.contentDocument || iframe.contentWindow.document
        const body = iframedoc.getElementsByTagName('body')[0]
        let content = addTargetBlank(
          this.detail.extendInfo.content
        )
        //附件资源处理
        this.detail.extendInfo.content = replaceSrcWithLoadingSrc(content, data?.unprocessedAttachments || [])
        body.innerHTML = this.detail.extendInfo.content
        // 处理正文附件
        this.handleContentAttachments(data.unprocessedAttachments)

        this.infoDetailed = false
        if (data.baseInfo.emailMemo && data.baseInfo.emailMemo.trim()) {
          this.remarkFlag = true
        }
      },

      download(url, filename) {
        /**
         * 获取 blob
         * @param  {String} url 目标文件地址
         * @return {cb}
         */

        function getBlob(url, cb) {
          var xhr = new XMLHttpRequest()
          xhr.open('GET', url, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            if (xhr.status === 200) {
              cb(xhr.response)
            }
          }
          xhr.send()
        }

        /**
         * 保存
         * @param  {Blob} blob
         * @param  {String} filename 想要保存的文件名称
         */

        function saveAs(blob, filename) {
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, filename)
          } else {
            var link = document.createElement('a')
            var body = document.querySelector('body')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            // fix Firefox
            link.style.display = 'none'
            body.appendChild(link)
            link.click()
            body.removeChild(link)
            window.URL.revokeObjectURL(link.href)
          }
        }

        getBlob(url, function (blob) {
          saveAs(blob, filename)
        })
      },

      // 下载文件
      handleDownload(fileArr) {
        fileArr.forEach((item, index) => {
          if (item.enclosureUrl.includes('http')) {
            this.download(item.enclosureUrl, item.enclosureName)
          } else {
            this.download(
              'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/' +
              item.enclosureUrl,
              item.enclosureName
            )
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .box {
    width: 100%;
    height: 100%;
  }

  .mail-detail {
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .assign-icon {
      text-align: right;

      i {
        cursor: pointer;
        color: #1890ff;
      }
    }

    .mail-detail-base {
      border-bottom: 1px solid #f5f7fa;
      box-sizing: border-box;

      .title-box {
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        margin-bottom: 10px;

        .title {
          font-size: 18px;
          font-weight: bold;
          color: #303030;
          max-width: 150em;
          word-break: break-all;
        }

        .order-num {
          padding: 0 3px;
          white-space: nowrap;
          color: #1890ff;
          background: #e8f4ff;
          font-size: 14px;
          margin-left: 10px;

          .icon-close {
            color: #ccc;
          }
        }

        .label-item {
          display: inline-block;
          margin-left: 10px;
          height: 20px;
          font-size: 12px;
          color: #fff;
          padding: 0 4px;
          border-radius: 2px;
          max-width: 100%;
          line-height: 20px;

          .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            vertical-align: middle;
          }

          .remove-icon {
            margin-left: 4px;
            cursor: pointer;
            display: none;
            vertical-align: middle;
          }

          &:hover {
            .remove-icon {
              display: inline-block;
            }
          }
        }
      }

      .operate-btns {
        margin-left: 20px;

        .el-button {
          width: 28px;
          height: 28px;
          padding: 4px;
          font-size: 18px;
        }
      }

      .info-wrapper {
        display: flex;

        .info-main {
          flex: 1;
          width: 200px;
          margin-bottom: 10px;
        }

        .info-simple {
          font-size: 14px;
          color: #303030;
          line-height: 24px;
          word-break: break-all;

          .desc {
            color: #999;
          }
        }

        .btn-key {
          font-size: 14px;
          color: #999;
          line-height: 24px;
          cursor: pointer;

          &:hover {
            color: #1890ff;
          }
        }
      }

      .base-item {
        display: flex;
        margin-bottom: 10px;
        line-height: 24px;

        dt {
          width: 82px;
          height: 24px;
          background: #f8fbff;
          border-radius: 3px;
          text-align: center;
          line-height: 24px;
          margin-right: 10px;
        }

        dd {
          flex: 1;
          width: 0;
          word-wrap: break-word;
          font-size: 12px;
          color: #777;

          .weight {
            color: #303030;
          }

          .star {
            color: #fa6400;
          }
        }
      }

      .myremark {
        background-color: #f8f8f8;
        padding: 4px 6px;
        margin-top: 6px;

        .remark-text {
          display: flex;
          align-items: flex-end;

          .weight {
            font-size: 14px;
            line-height: 28px;
            word-wrap: break-word;
            flex: 1;
            width: 200px;
          }
        }

        .remark-form {
          display: flex;
          align-items: center;

          .input {
            flex: 1;
            margin-right: 10px;
          }
        }
      }
    }

    .mail-iframe {
      flex: 1;
      height: 500px;
    }

    .mail-detail-attach {
      padding: 20px;
      box-sizing: border-box;

      .attach-title {
        margin-bottom: 10px;

        i {
          color: #fa6400;
          font-size: 12px;
        }

        &>span {
          font-size: 12px;
          font-weight: bold;
          color: #303030;
          margin: 0 10px;
        }
      }

      .attach-list {
        display: flex;
        flex-wrap: wrap;

        &>div {
          padding: 0 12px;
          background: #f5f7fa;
          border-radius: 3px;
          border: 1px solid #f5f7fa;
          font-size: 12px;
          margin-right: 10px;
          line-height: 30px;
          margin-bottom: 10px;
          cursor: pointer;
          width: 200px;
          display: flex;

          i {
            color: #fa6400;
            font-size: 12px;
          }

          .title {
            flex: 1;
            width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #303030;
            line-height: 30px;
            margin-left: 10px;
          }

          .preview {
            color: #1890ff;
            margin-left: 10px;
          }

          .size {
            color: #777;
            margin-left: 10px;
          }
        }
      }
    }

    .mail-deatil-bottom {
      overflow-y: auto;
      max-height: 120px;
    }
  }

  .no-mail {
    width: 100%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
    position: relative;

    &>div {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100px;
        height: 100px;
      }

      p {
        margin-top: 33px;
        color: #777;
        font-size: 14px;
      }
    }
  }
</style>