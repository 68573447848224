<template>
  <el-dialog :visible.sync="params.show" title="入库库位" :width="$attrs.width || '800px'" append-to-body @close="onClose"
    modal-append-to-body>
    <!-- 查询表单 -->
    <div>
      <div class="loc-wrapper">
        <!-- v1.4.2修改成表格展示 -->
        <el-table :data="locs" border stripe max-height="400">
          <el-table-column align="center" label="库位号">
            <template slot-scope="scope">
              <el-select v-model="scope.row.locationNumber" placeholder="选择库位"
                @change="(val) => changeloc(val, scope.$index)">
                <el-option v-for="loc in locList" :key="loc.warehouseLocationId" :label="loc.locationNumber"
                  :value="loc.locationNumber"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column align="center" label="当前数量">
            <template slot-scope="scope">

              {{ scope.row.locationNumber ? scope.row.currentNum : '' }}
              <!-- bugfix 7991 入库单-其它入库新增，入库库位中当前数量不用实时计算加上放置数量 -->
              <!-- {{ scope.row.currentNum }} -->
            </template>
          </el-table-column>
          <el-table-column align="center" label="放置数量">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.num" :min="0" v-if="scope.row.locationNumber" :precision="0"
                controls-position="right" label="输入放置数量"></el-input-number>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="50px">
            <template slot-scope="scope">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" @click="locs.splice(scope.$index, 1)"
                  style="color: #f56c6c; cursor: pointer"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-row v-if="params.disabled !== true">
          <el-button @click="addNewLoc" icon="el-icon-plus" style="width: 100%; color: #1890ff; border-top: none">
            添加库位
          </el-button>
        </el-row>
      </div>
      <p class="mt20" v-if="showStatistics" style="text-align: right">
        总放置数量：{{ totolCounts }}
      </p>
      <div class="footer-wrapper mt30 t_a_c" v-if="params.disabled !== true" style="text-align: center">
        <el-button type="primary" class="ml30" @click="sureAction">
          保存
        </el-button>
        <el-button @click="cancelAction">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'product',
    props: {
      params: {
        type: Object,
        default: () => { },
      },
      showStatistics: {
        type: Boolean,
        default: true,
      },
      warehouseId: [String],
      warehouseInNum: [Number, String],
      locList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        locs: [
          {
            num: 0,
            currentNum: 0,
            warehouseLocationId: '',
            locationNumber: '',
          },
        ],
        totolCounts: 0,
      }
    },

    computed: {},

    watch: {
      locs: {
        deep: true,
        handler(val) {
          let sum = 0
          if (val.length === 0) {
            sum = 0
          } else {
            sum = val.map((i) => i.num).reduce((prev, next) => prev + next)
          }
          this.totolCounts = sum
        },
      },
    },

    methods: {
      changeloc(val, index) {
        const loc = this.locList.find((i) => i.locationNumber === val)
        if (loc) {
          this.locs[index].warehouseLocationId = loc.warehouseLocationId
          this.locs[index].currentNum = loc.currentNum
          this.locs[index].locationNumber = loc.locationNumber
        }
      },
      addNewLoc() {
        this.locs.push({
          num: '',
          currentNum: 0,
          warehouseLocationId: '',
          locationNumber: '',
        })
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      // 获取产品列表
      getProducts() { },

      cancelAction() {
        this.onClose()
      },
      sureAction() {
        if (this.locs.some((i) => !i.locationNumber)) {
          return this.$message.warning('库位号不能为空')
        }
        if (this.locs.some((i) => !i.num)) {
          return this.$message.warning('放置数量不能为空或0')
        }
        if (this.totolCounts !== this.warehouseInNum) {
          return this.$message.warning('实际入库数量和库位放置数量不等')
        }
        const ids = this.locs.map(n => n.warehouseLocationId)
        const idsNoRepeat = [...new Set(ids)]
        if (ids.length !== idsNoRepeat.length) {
          return this.$message.warning('库位号不能重复')
        }

        this.$emit('set-loc', this.locs, this.totolCounts)
        this.$emit('close')
      },
      onClose() {
        this.$emit('close')
      },
    },

    created() { },
  }
</script>

<style scoped lang="scss">
  .footer {
    .el-button+.el-button {
      margin-left: 30px;
    }
  }

  .ml15 {
    margin-left: 15px;
  }

  .loc-item {
    display: flex;
    align-items: center;
  }

  .mt20 {
    margin-top: 20px;
  }

  .pointer {
    cursor: pointer;
  }

  ::v-deep .loc-wrapper .el-input-number--small {
    width: 200px !important;
  }

  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
    text-align: left;
  }

  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }

  .count {
    display: inline-block;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-col-6 {
    display: flex;
    align-items: center;
  }
</style>