<!--
  功能：结算详情字段组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月30日 15:18:47
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <span
    v-if="value || value === 0"
    class="text"
    :style="{ color: value * 1 < 0 ? 'red' : '' }"
  >
    {{ value }}
  </span>
  <span v-else>--</span>
</template>

<script>
  export default {
    name: 'SettlementCurrency',
    props: {
      value: {
        type: [Number, String],
        default: 0,
      },
      invisibleJudgment: {
        type: [Boolean],
        default: false,
      },
    },
  }
</script>

<style scoped lang="scss">
  .text {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
