import request from '@/core/services/request'

//供应商性质-新增
export function supplierNatureInsertPO(data) {
  return request({
    url: '/merchants/supplierNature/insertPO',
    method: 'post',
    data,
  })
}
//供应商性质-更新
export function supplierNatureUpdatePO(data) {
  return request({
    url: '/merchants/supplierNature/updatePO',
    method: 'post',
    data,
  })
}
//供应商性质-删除
export function supplierNatureDeleteById(data) {
  return request({
    url: '/merchants/supplierNature/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//供应商性质-所有
export function supplierNatureListAll(data) {
  return request({
    url: '/merchants/supplierNature/listAll',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//供应商类型-新增
export function supplierTypeInsertPO(data) {
  return request({
    url: '/merchants/supplierType/insertPO',
    method: 'post',
    data,
  })
}
//供应商类型-更新
export function supplierTypeUpdatePO(data) {
  return request({
    url: '/merchants/supplierType/updatePO',
    method: 'post',
    data,
  })
}
//供应商类型-删除
export function supplierTypeDeleteById(data) {
  return request({
    url: '/merchants/supplierType/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//供应商类型-所有
export function supplierTypeListAll(data) {
  return request({
    url: '/merchants/supplierType/listAll',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//支付模板-所有
export function paymentListPage(data) {
  return request({
    url: '/merchants/payment/listPage',
    method: 'post',
    data,
  })
}

//客商付款方式-新增（payee：1客户，2供应商）
export function paymentInsertPO(data) {
  return request({
    url: '/merchants/payment/insertPO',
    method: 'post',
    data,
  })
}
//客商付款方式-更新
export function paymentUpdatePO(data) {
  return request({
    url: '/merchants/payment/updatePO',
    method: 'post',
    data,
  })
}
//客商付款方式-删除
export function paymentDeleteById(data) {
  return request({
    url: '/merchants/payment/deleteById',
    method: 'post',
    data,
  })
}
//客商付款方式-详情
export function paymentDetail(data) {
  return request({
    url: '/merchants/payment/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//客商付款方式-作废
export function paymentToVoid(data) {
  return request({
    url: '/merchants/payment/toVoid',
    method: 'post',
    data,
  })
}

//客户分管申请设置-根据租户id查找
export function customerManagerSettingGetDetail(data) {
  return request({
    url: '/merchants/customerManagerSetting/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//客户分管申请设置-修改
export function customerManagerSettingUpdatePO(data) {
  return request({
    url: '/merchants/customerManagerSetting/updatePO',
    method: 'post',
    data,
  })
}
