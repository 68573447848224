import request from '@/core/services/request'

// 订单跟踪新增-输入采购订单编号匹配信息
export function orderTrackingInformation(data) {
  return request({
    url: '/order/purchaseOrder/orderTrackingInformation',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单跟踪新增-手动创建跟踪单
export function trackInsertPO(data) {
  return request({
    url: '/order/orderTracking/insertPO',
    method: 'post',
    data,
  })
}

// 根据地区查询选中的模板或者默认的模板
export function getUsingOrDefalutNode(data) {
  return request({
    url: '/order/trackingSetting/getUsingOrDefalutNode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单跟踪更新-获取详情
export function getDetailForEdit(data) {
  return request({
    url: '/order/orderTracking/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单跟踪更新-更新实体
export function updatePO(data) {
  return request({
    url: '/order/orderTracking/updatePO',
    method: 'post',
    data,
  })
}

// 查询存在的跟踪记录
export function queryOrderTrackingRecord(data) {
  return request({
    url: '/order/orderTracking/queryOrderTrackingRecord',
    method: 'post',
    data,
  })
}

// 订单跟踪详情-QC质检
export function listByPurchaseOrderNumber(data) {
  return request({
    url: '/order/inspectionOrder/listByPurchaseOrderNumber',
    method: 'post',
    data,
  })
}

// 订单跟踪详情-物流货运
export function queryShippingDate(data) {
  return request({
    url: '/storage/shippingOrder/v2/queryShippingDate',
    method: 'post',
    data,
  })
}

// 订单跟踪详情-客户签收
export function querySignedDate(data) {
  return request({
    url: '/storage/shippingOrder/v2/querySignedDate ',
    method: 'post',
    data,
  })
}
