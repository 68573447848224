<template>
  <div class="platform-product table-container">
    <!-- 查询项 -->
    <el-form
      inline
      :model="queryForm"
      label-width="100px"
      ref="formRef"
      class="search-form"
    >
      <el-form-item label="平台" prop="platformCode">
        <el-select
          v-model="queryForm.platformCode"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item of platformDs"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="平台产品编码" prop="platformProductCode">
        <el-input
          v-model="queryForm.platformProductCode"
          placeholder="请输入"
          clearable
          @keyup.enter.native="handleSearch"
          @clear="handleSearch"
          @blur="handleSearch"
        />
      </el-form-item>
      <el-form-item label="平台产品名称" prop="platformNameEn">
        <el-input
          v-model="queryForm.platformNameEn"
          placeholder="请输入"
          clearable
          @keyup.enter.native="handleSearch"
          @clear="handleSearch"
          @blur="handleSearch"
        />
      </el-form-item>
      <el-form-item label="ERP 产品编码" prop="erpProductCode">
        <el-input
          v-model="queryForm.erpProductCode"
          placeholder="请输入"
          clearable
          @keyup.enter.native="handleSearch"
          @clear="handleSearch"
          @blur="handleSearch"
        />
      </el-form-item>
      <el-form-item label="ERP 产品名称" prop="erpProductNameEn">
        <el-input
          v-model="queryForm.erpProductNameEn"
          placeholder="请输入"
          clearable
          @keyup.enter.native="handleSearch"
          @clear="handleSearch"
          @blur="handleSearch"
        />
      </el-form-item>
      <el-form-item label="状态" prop="platformProductStatus">
        <el-select
          v-model="queryForm.platformProductStatus"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item of statusDs"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="匹配状态" prop="matchStatus">
        <el-select
          v-model="queryForm.matchStatus"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item of matchStatusDs"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <ErpTable
      :page-number.sync="queryForm.pageNo"
      :page-size.sync="queryForm.pageLe"
      :table-data="tableData"
      :total="total"
      @query="fetchData"
      :extraHeight="120"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :sortable="item.sortable"
        :resizable="false"
        align="center"
        :prop="item.prop"
        :min-width="item.width"
      >
        <template slot-scope="scope">
          <template v-if="item.prop === 'platformCode'">
            {{ scope.row[item.prop] | platformCodeFilter }}
          </template>
          <template v-else-if="item.prop === 'platformProductCode'">
            <span
              v-if="scope.row[item.prop]"
              @click="handleDetail(scope.row)"
              class="prod_code"
            >
              {{ scope.row[item.prop] }}
            </span>
            <template v-else>--</template>
          </template>
          <template v-else-if="item.prop === 'erpProductCode'">
            <span
              v-if="scope.row[item.prop]"
              @click="productView(scope.row)"
              class="prod_code"
            >
              {{ scope.row[item.prop] }}
            </span>
            <template v-else>--</template>
          </template>
          <template v-else-if="item.prop === 'platformProductStatus'">
            {{ scope.row[item.prop] | statusFilter }}
            <ErpMouseoverTip
              v-if="[2, 3].includes(+scope.row.platformProductStatus)"
              effect="dark"
              icon="el-icon-warning-outline"
              color="#303133"
              openDelay="0"
            >
              {{ scope.row.createTime }}
              <br />
              {{ scope.row.lisitingReason }}
            </ErpMouseoverTip>
          </template>
          <template v-else>
            {{ scope.row[item.prop] || '--' }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="160" fixed="right">
        <template #default="{ row }">
          <el-tooltip
            v-for="item of btns"
            :key="item.icon"
            :content="item.content"
            placement="top"
          >
            <el-button
              v-if="item.allowed"
              v-allowed="item.allowed"
              v-show="item.show(item, row)"
              type="primary"
              plain
              :icon="item.icon"
              circle
              size="mini"
              @click="item.action(row)"
            />
            <el-button
              v-else
              v-show="item.show(item, row)"
              type="primary"
              plain
              :icon="item.icon"
              circle
              size="mini"
              @click="item.action(row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </ErpTable>
    <!-- 上架弹框 -->
    <PutawayDialog
      v-model="putawayDialogVisible"
      :row="row"
      @refresh="fetchData"
    />
    <!-- 下架弹框 -->
    <SoldoutDialog
      v-model="soldoutDialogVisible"
      :row="row"
      @refresh="fetchData"
    />
    <!-- 手动匹配弹框 -->
    <ConnectionDialog
      v-model="connectionDialogVisible"
      :row="row"
      @refresh="fetchData"
    />
  </div>
</template>
<script>
  import { PlatformProductListInteractor } from '@/core'
  import PutawayDialog from './components/PutawayDialog'
  import SoldoutDialog from './components/SoldoutDialog'
  import ConnectionDialog from './components/ConnectionDialog'
  import { platformDs, statusDs, matchStatusDs } from './dicts'
  export default {
    components: { PutawayDialog, SoldoutDialog, ConnectionDialog },
    data() {
      return {
        platformDs,
        statusDs,
        matchStatusDs,
        queryForm: {
          pageLe: 10,
          pageNo: 1,
          platformCode: '', // 平台
          platformProductCode: '', // 平台产品编码
          platformNameEn: '', // 平台产品名称
          erpProductCode: '', // ERP产品编码
          erpProductNameEn: '', // ERP产品名称
          platformProductStatus: '', // 状态
          matchStatus: '', // 匹配状态
        },
        tableData: [],
        total: 0,
        finallyColumns: [
          {
            label: '平台',
            prop: 'platformCode',
            width: '160',
          },
          {
            label: '平台产品编码',
            prop: 'platformProductCode',
            width: '200',
          },
          {
            label: '平台产品名称',
            prop: 'platformNameEn',
            width: '200',
          },
          {
            label: 'ERP 产品编码',
            prop: 'erpProductCode',
            width: '200',
          },
          {
            label: 'ERP 产品名称',
            prop: 'erpProductNameEn',
            width: '200',
          },
          {
            label: '状态',
            prop: 'platformProductStatus',
            width: '100',
          },
          {
            label: '产品运营',
            prop: 'belongerEn',
            width: '200',
          },
        ],
        // 操作栏按钮列表
        btns: [
          {
            content: '查看',
            icon: 'el-icon-search',
            action: this.handleDetail,
            show: () => true,
          },
          {
            content: '手动匹配',
            icon: 'el-icon-connection',
            action: this.handleMatch,
            show: this.show,
          },
          {
            content: '上架',
            icon: 'el-icon-top',
            action: this.handlePutaway,
            allowed: ['Product:Putaway'],
            show: this.show,
          },
          {
            content: '从平台下架',
            icon: 'el-icon-bottom',
            action: this.handleSoldout,
            allowed: ['Product:Soldout'],
            show: this.show,
          },
        ],
        putawayDialogVisible: false, // 上架弹框显示隐藏
        soldoutDialogVisible: false, // 下架弹框显示隐藏
        connectionDialogVisible: false, // 手动匹配弹框显示隐藏
        row: {}, // 当前这行的数据
      }
    },
    filters: {
      // 平台
      platformCodeFilter(code) {
        const item = platformDs.find((n) => n.value === code) || {}
        return item.label || '--'
      },
      // 状态
      statusFilter(status) {
        const item = statusDs.find((n) => n.value + '' === status + '') || {}
        return item.label
      },
    },
    activated() {
      this.fetchData()
    },
    methods: {
      // 查询
      handleSearch() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      // 重置
      handleReset() {
        this.$refs.formRef.resetFields()
        this.queryForm.pageLe = 10
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      // 平台产品列表
      async fetchData() {
        try {
          const {
            platformProductCode,
            platformNameEn,
            erpProductCode,
            erpProductNameEn,
            ...params
          } = this.queryForm
          Object.assign(params, {
            platformProductCode: platformProductCode.trim(),
            platformNameEn: platformNameEn.trim(),
            erpProductCode: erpProductCode.trim(),
            erpProductNameEn: erpProductNameEn.trim(),
          })
          const { code, data: list } =
            await PlatformProductListInteractor.listPageApi(params)
          if (code === '000000') {
            const { data, total } = list
            this.tableData = data
            this.total = total
          }
        } catch (err) {
          console.log(err)
        }
      },
      // 查看
      async handleDetail({
        id,
        platformCode,
        erpProductId,
        erpProductCode,
        platformProductId,
      }) {
        // sage跳转至platformProductDetail详情页，rp商城跳转至platformProductProductView详情页
        if (platformCode === 'SAGE') {
          this.$router.push({
            name: 'platformProductDetail',
            query: {
              id,
              platformCode,
              erpProductCode,
              type: '0', // 0：查看 1：上架
              platformProductId,
            },
          })
        } else {
          this.$router.push({
            name: 'platformProductProductView',
            query: {
              id,
              productId: erpProductId,
              platformCode,
              erpProductCode,
              platformProductId,
            },
          })
        }
      },
      // 手动匹配
      handleMatch(row) {
        const targetItem =
          platformDs.find((n) => n.value === row.platformCode) || {}
        this.row = { ...row, platformName: targetItem.label }
        this.connectionDialogVisible = true
      },
      // 上架
      handlePutaway({
        id,
        platformProductId,
        platformCode,
        erpProductCode,
        erpProductId,
      }) {
        // sage跳转至平台上架页面，rp商城打开弹框
        if (platformCode === 'SAGE') {
          this.$router.push({
            name: 'platformProductPutaway',
            query: {
              id,
              platformCode,
              erpProductCode,
              type: '1', // 0：查看 1：上架
              erpProductId,
              platformProductId,
            },
          })
        } else {
          this.row = { erpProductId, platformCode }
          this.putawayDialogVisible = true
        }
      },
      // 下架
      async handleSoldout(row) {
        this.row = row
        this.soldoutDialogVisible = true
      },
      // 通过产品编码点击跳转至详情，和【产品列表】点击产品编码跳转到同样的页面
      productView({ erpProductId, erpProductCode }) {
        const routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: { proId: erpProductId, productCode: erpProductCode },
        })
        window.open(routeData.href, '_blank')
      },
      /*
        按钮显示逻辑：
          1、查看按钮：一直显示
          2、匹配按钮：未匹配时显示
          3、上架按钮：上架失败、更新失败时显示，已下架且已匹配时显示
          4、下架按钮：已上架、更新失败时显示
      
      */
      show({ icon }, { platformProductStatus: status, erpProductCode: code }) {
        if (icon === 'el-icon-connection') {
          if (!code) return true // erpProductCode有值表示已匹配，没值表示未匹配
        } else if (icon === 'el-icon-top') {
          if ([2, 3].includes(+status) || (+status === 0 && code)) return true
        } else if (icon === 'el-icon-bottom') {
          if ([1, 3].includes(+status)) return true
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .platform-product {
    .search-form {
      /deep/.el-form-item__content {
        width: 170px;
      }
    }
    .prod_code {
      color: #409eff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
</style>
