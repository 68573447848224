<template>
  <el-dialog
    title="提示"
    :visible.sync="visible"
    width="500px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <div>
      <div style="display: flex; align-items: center">
        <i class="el-icon-warning-outline"></i>
        <h4 v-if="msgType == 1">是否确认启用分仓核算？</h4>
        <h4 v-if="msgType == 2">是否确认启用总仓核算？</h4>
        <h4 v-if="msgType == 3">是否确认更改为“批次计价法”？</h4>
        <h4 v-if="msgType == 4">是否确认更改为“移动加权”？</h4>
      </div>
      <div style="margin-left: 40px" v-if="msgType == 1">
        <p>启用分仓注意事项：</p>
        <p>
          1）若总仓成本计价为移动加权，历史库存出库单价仍按照修改前的期末单价出库，直至历史库存消耗完毕；
        </p>
        <p>
          2）启用分仓后，仓库的出库模式和成本计价方式默认和总仓核算时保持一致；
        </p>
        <p>
          3）分仓核算后，已产生的出库单据成本不会改变，新单据成本根据新规则计算；
        </p>
        <el-checkbox v-model="checked" style="margin-top: 15px">
          我已知晓
        </el-checkbox>
      </div>
      <div style="margin-left: 40px" v-if="msgType == 2">
        <p>启用总仓注意事项：</p>
        <p>1）启用总仓后，库存成本计价将遵循一种方式；</p>
        <p>
          2）分仓时，成本计价方式为移动加权，若出库库存为规则更改前产生，出库单价依然为仓库库存平均单价，从规则更改后入库的库存出库，出库单价根据新规则执行；
        </p>
        <el-checkbox v-model="checked" style="margin-top: 15px">
          我已知晓
        </el-checkbox>
      </div>
      <div style="margin-left: 40px" v-if="msgType == 3">
        <p>更改注意事项：</p>
        <p>1）已产生的出库单据成本不会改变，新单据成本根据新规则计算；</p>
        <p>
          2）出库库存为规则更改前产生的，出库单价依然为库存平均单价，从规则更改后入库的库存出库，出库单价为批次单价；
        </p>
        <el-checkbox v-model="checked" style="margin-top: 15px">
          我已知晓
        </el-checkbox>
      </div>
      <div style="margin-left: 40px" v-if="msgType == 4">
        <p>更改注意事项：</p>
        <p>1）已产生的出库单据成本不会改变，新单据成本根据新规则计算；</p>
        <p>2）更改后的出库成本根据平均库存单价进行计算；</p>
        <el-checkbox v-model="checked" style="margin-top: 15px">
          我已知晓
        </el-checkbox>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button :disabled="!checked" type="primary" @click="confirm">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      msgType: {
        type: [Number, String],
        default: 0,
      },
    },
    data() {
      return {
        checked: false,
        // type: 2,
      }
    },
    methods: {
      handleClose() {
        this.$emit('checkFalse')
        this.$emit('update:visible', false)
        this.checked = false
      },
      confirm() {
        this.$emit('checkTrue')
        this.$emit('update:visible', false)
        this.checked = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-icon-warning-outline {
    color: #e6a23c;
    font-size: 30px;
    margin-right: 10px;
  }
</style>
