<template>
  <el-dialog title="发货邮件" :visible.sync="visible" width="960px" @close="close" :modal="false"
    :close-on-click-modal="false">
    <div class="pro-list">
      <div class="img-list" v-if="imgList.length > 0">
        <div class="img-item" v-for="(img,i) in imgList" @click="toggleChecked(img)">
          <img v-lazy="img.src + '?x-oss-process=image/resize,p_10'" alt="">
          <div class="mask" v-if="img.checked">
            <i class="el-icon-success"></i>
          </div>
        </div>

      </div>
      <div class="no-data" v-else>
        <i class="el-icon-picture-outline
        "></i>
        <span>暂无大货照</span>
      </div>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 认</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'PhotoList',
    props: {
      imgList: {
        //产品列表
        type: Array,
        default: () => [],
      },
      pIndex: {
        type: Number,
        default: 0
      },

      cIndex: {
        type: Number,
        default: 0
      },

    },

    data() {
      return {
        visible: false, // 弹窗控制
      }
    },

    methods: {
      toggleChecked(img) {
        img.checked = !img.checked
      },
      // 关闭
      close() {
        this.visible = false
      },

      // 确认
      save() {
        const checked = this.imgList.filter(item => item.checked).map(i => i.src)
        const delChecked = this.imgList.filter(item => item.outChoose && !item.checked).map(i => i.src)
        this.close()
        this.$emit('img-sel', checked, delChecked, this.pIndex, this.cIndex)
      },

      // 展示发货邮件弹窗
      showDialog() {
        this.visible = true
      },

      getChecked(index, imgIndex) {
        const imgList = this.productList[index].imgList
        const defaultImgList = this.defaultList[index].imgList
        return defaultImgList.includes(imgList[imgIndex])
      }
    },
  }
</script>

<style lang="scss" scoped>
  .mr-15 {
    margin-right: 15px;
  }

  .pro-item {
    .pro-title {
      font-size: 16px;
      font-family: 'Arial Normal', 'Arial', sans-serif;
      color: #333333;
      font-weight: 400;
    }

  }

  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 120px;
    }

    span {
      margin-top: 10px;
      color: #909399;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .img-list {
    max-height: 400px;
    overflow: auto;

    .img-item {
      height: 100px;
      width: 100px;
      border-radius: 6px;
      display: inline-block;
      margin: 5px;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        border-radius: 6px;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 999;
        background-color: rgba(103, 117, 111, 0.5);
        text-align: center;
        border-radius: 6px;

        i {
          position: absolute;
          right: 5px;
          bottom: 5px;
          color: #88d805;
          font-size: 18px;
        }
      }
    }

  }
</style>
</template>