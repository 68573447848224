<!-- 查询表单 -->
<template>
  <div class="page-header-left">
    <el-row type="flex">
      <el-col :span="20">
        <!-- 评价类型 -->
        <span v-allowed="['REMARKCENTER:ADD']">
          <el-button type="primary" @click="addReview" size="small">
            <i class="el-icon-plus"></i>
            {{ $t('cusCenter.new') }}
          </el-button>
        </span>

        <el-select
          class="ml10"
          clearable
          v-model="search.evaluationType"
          :placeholder="$t('cusCenter.Evaluationtype')"
        >
          <el-option
            v-for="item in evaluationTypeList"
            :key="item.code"
            :label="lang === 'en' ? item.nameEn : item.name"
            :value="item.code"
          ></el-option>
        </el-select>
        <!-- 评价来源 -->
        <el-select
          class="ml10"
          clearable
          v-model="search.evaluationSource"
          :placeholder="$t('cusCenter.EvaluationSource')"
        >
          <el-option
            v-for="item in evaluationSourceList"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
        <el-select class="w-140 ml10" v-model="search.dateType">
          <el-option
            value="evaluationDate"
            :label="lang === 'en' ? 'Feedback Date' : '评价日期'"
          ></el-option>
          <el-option
            value="createDate"
            :label="lang === 'en' ? 'Created Time' : '创建日期'"
          ></el-option>
        </el-select>
        <el-date-picker
          class="w-230 ml10"
          value-format="yyyy-MM-dd"
          type="daterange"
          v-model="search.date"
          :disabled="!search.dateType"
          range-separator="-"
        ></el-date-picker>
        <!-- 搜索 -->
        <el-button type="primary" class="ml10" @click="doSearch">
          {{ $t('cusCenter.Search') }}
        </el-button>
        <!-- 重置 -->
        <el-button @click="doReset" class="ml10">
          {{ $t('cusCenter.Reset') }}
        </el-button>
        <!-- 高级筛选 -->
        <el-button @click="drawer = true">
          {{ $t('inquiryList.AdvancedFilter') }}
        </el-button>
      </el-col>
      <el-col :span="4" class="text-right">
        <!-- 自定义展示列 -->
        <el-popover
          ref="showOption"
          popper-class="custom-table-checkbox"
          trigger="hover"
        >
          <erp-draggable v-bind="dragOptions" :list="columns">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="item + index"
              v-model="item.isShow"
              :disabled="item.checkable === false"
              :label="item.label"
            >
              {{ lang === 'en' ? item.labelEn : item.label }}
            </el-checkbox>
          </erp-draggable>
          <!-- 保存自定义显示列 -->
          <div class="save-btn">
            <!-- 保存 -->
            <el-button
              type="primary"
              class="ml10"
              size="mini"
              plain
              @click="customSave"
            >
              {{ $t('inquiryList.Save') }}
            </el-button>
          </div>
          <template #reference>
            <!-- 列表可拖动排序 -->
            <el-tooltip
              effect="dark"
              :content="$t('iconbtn.dragSort')"
              placement="top-start"
            >
              <!-- 自定义显示 -->
              <el-button plain type="primary">
                {{ $t('inquiryList.CustomDisplay') }}
              </el-button>
            </el-tooltip>
          </template>
        </el-popover>
      </el-col>
    </el-row>

    <!-- 高级筛选Drawer -->
    <el-drawer
      :title="$t('inquiryList.AdvancedFilter')"
      :visible.sync="drawer"
      :before-close="highSearchClose"
    >
      <customerSearch
        @cancelClick="highSearchClose"
        @searchClick="searchClick"
        @highSearch="highSearch"
      />
    </el-drawer>

    <!-- 新增评价 -->
    <ReviewAdd ref="reviewAdd" @refresh="doSearch"></ReviewAdd>
  </div>
</template>

<script>
  import {
    evaluationTypeList,
    evaluationSourceList,
  } from '@/views/customer-manage/helper/data'
  import ReviewAdd from './review-add'
  import customerSearch from './customer-search'
  import ErpDraggable from 'vuedraggable'
  import { mapGetters } from 'vuex'

  export default {
    name: 'CusCenterSearchForm',
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        evaluationTypeList, //类型下拉
        evaluationSourceList, // 来源下拉
        search: {
          evaluationType: '', // 评价类型
          evaluationSource: '', //评价来源
          dateType: '', //查询的日期类型
          date: '', //选择的日期
          status: '', //状态
        },
        queryForm: {},
        drawer: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
    },
    components: {
      ErpDraggable,
      ReviewAdd,
      customerSearch,
    },
    mounted() {
      this.$Bus.$on('cus-edit', (id) => {
        this.$refs.reviewAdd.show(id)
      })
    },
    created() {},
    methods: {
      // 保存自定义显示列
      customSave() {
        this.$emit('operateSave')
      },

      // 重置
      doReset() {
        this.search = this.$options.data().search
        this.doSearch()
      },
      // 搜索
      doSearch() {
        this.$emit('do-search', this.getSearchParams())
      },
      // 高级搜索
      highSearch(data) {
        this.search = Object.assign({}, this.search, { ...data })
        this.doSearch()
        this.highSearchClose()
      },
      // 获取查询参数
      getSearchParams() {
        const searchForm = {
          ...this.search,
        }
        const { dateType, date } = searchForm
        if (dateType && date) {
          searchForm[dateType + 'Start'] = date[0]
          searchForm[dateType + 'End'] = date[1]
        }
        // delete searchForm.dateType
        return searchForm
      },

      // 新增
      addReview() {
        this.$refs.reviewAdd.show()
      },

      // 高级筛选触发
      searchClick(val) {
        this.queryForm = val
        this.highSearchClose()
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },
    },
    beforeDestroy() {
      this.$Bus.$off('cus-edit')
    },
  }
</script>

<style lang="scss" scoped>
  .w-230 {
    width: 150px;
  }
  .w-100 {
    width: 100px;
  }
  .w-120 {
    width: 120px;
  }
  .w-130 {
    width: 140px;
  }
  .w-140 {
    width: 140px;
  }
  .w-150 {
    width: 150px;
  }
  .w-230 {
    width: 230px;
  }
</style>
