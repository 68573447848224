<template>
  <div class="pro-container">
    <el-row class="page-header">
      <el-row class="page-header-line" type="flex" align="middle" justify="space-between">
        <!-- 详情页面 -->
        <el-page-header @back="goBack" :content="$t('other.detailPage')" class="page-header-box"
          :title="$t('other.back')">
          <template slot="content">
            <el-row type="flex" align="middle">
              <p>{{ infoParames.erpProductNameEn }}</p>
              <el-tag class="ml10" type="primary" v-if="infoParames.standardCustomized === '标准品'">
                <!-- 标准品 -->
                {{ $t('productCenter.StandardProduct') }}
              </el-tag>
              <el-tag class="ml10" v-if="infoParames.standardCustomized === '订制品'" type="success">
                <!-- 订制品 -->
                {{ $t('productCenter.CustomizedProduct') }}
              </el-tag>
            </el-row>
          </template>
        </el-page-header>
        <el-row :class="[
            !$route.query.noReturn ? 'fix-msg-operate' : 'fix-msg-operate-no',
            'align_center',
          ]" type="flex">
          <p class="ml15">
            <!-- 产品编码： -->
            <label>{{ $t('productDetail.ProductCode') }}：</label>
            <span>{{ infoParames.erpProductCode }}</span>
          </p>
        </el-row>
      </el-row>
    </el-row>

    <el-row class="tabs-content">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('productDetail.ProductInformation')" name="first">
          <ProductInfo :infoParames="infoParames" isPlatForm />
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="second" v-if="lang != 'en'">
          <OperateLogs v-if="activeName === 'second'" :showModule="false" :bizId="productId" />
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
  import ProductInfo from '@/views/product/ProductView/components/ProductInfo'
  import OperateLogs from '@/components/operate-logs'

  import { PlatformProductListInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ProductView',
    components: { ProductInfo, OperateLogs },
    data() {
      return { activeName: 'first', infoParames: {} }
    },
    computed: {
      ...mapGetters({ lang: 'settings/language' }),
      productId() {
        return this.$route.query.productId
      },
    },
    created() {
      // this.getProductDetail(this.productId)
      this.getInfoParames()
    },
    methods: {
      async getInfoParames() {
        try {
          const { id, platformCode, erpProductCode, platformProductId } =
            this.$route.query
          const params = { id, platformCode, erpProductCode, platformProductId }
          const { code, data } =
            await PlatformProductListInteractor.getPlatformProductShowDetailApi(
              params
            )
          if (code === '000000') {
            const { baseVO, selfProductVO } = data
            const { selfVO } = selfProductVO
            // 组装数据：页面用的是和【产品列表】一样的，但是接口由张洋洋新提供的，这里将数据组装好传递给ProductInfo组件
            const newObj = {
              nameCn: baseVO.erpProductName, // 中文名称
              productId: baseVO.erpProductId, // 单位
              material: baseVO.erpMaterial, // 材质
              category: baseVO.erpCategory, // 产品类别-中文
              categoryEn: baseVO.erpCategoryEn, // 产品类别-英文
              descriptionEn: baseVO.erpDescriptionEn, // 英文描述
              keyWord: baseVO.erpKeyWord, // 关键词
              standardCustomized: '标准品',
              hadPlatFormData: true, // 为了将proPrintCalcInfo组件中【平台价格】和【印刷加价】隐藏掉，这里传true
            }
            this.infoParames = Object.assign(
              selfProductVO,
              baseVO,
              selfVO,
              newObj
            )
            // console.log('新数据', JSON.parse(JSON.stringify(this.infoParames)))
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 返回
      goBack() {
        this.$router.push('/product/platformProduct/index')
      },
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: relative;

    .fix-msg-operate {
      h6 {
        font-size: 14px;
      }

      p {
        label {
          color: #999;
        }

        span {
          color: #000;
        }
      }
    }

    .tabs-content {
      padding: 8px 20px 0;
      height: calc(100% - 44px);
    }
  }

  ::v-deep {
    .el-tabs--border-card {
      box-shadow: 0 0 0 0 rgb(0, 0, 0);
    }

    .el-tabs {
      height: 100%;
    }

    .el-tabs__header {
      margin: 0 0 20px;
    }

    .el-tabs__content {
      height: calc(100% - 59px);
      overflow-y: auto;
      overflow-x: auto;
    }

    .el-page-header {
      margin: 0 !important;
    }
  }

  .page-header {
    padding: 0 20px;

    .page-header-line {
      border-bottom: 1px solid #ebeef5;
      padding-bottom: 13px;
      padding-top: 4px;
    }
  }
</style>
