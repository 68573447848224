<template>
  <div class="oper">
    <el-row>
      <el-page-header
        @back="goBack"
        :content="pageType == 'add' ? '新增运费' : '编辑运费'"
        title="返回"
      ></el-page-header>
    </el-row>
    <h3 class="mb10">基本信息</h3>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row :gutter="40">
        <el-col :span="6">
          <el-form-item label="运输方式" prop="transportWayCn">
            <el-input
              v-model.trim="form.transportWayCn"
              placeholder="请输入长度1到50个字符"
              minlength="1"
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="英文名称" prop="transportWayEn">
            <el-input
              v-model.trim="form.transportWayEn"
              placeholder="请输入长度1到50个字符"
              minlength="1"
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="运输类型" prop="transportType">
            <el-select v-model="form.transportType" placeholder="请选择">
              <el-option
                v-for="item in transportTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="报价币种" prop="currency">
            <el-select v-model="form.currency" placeholder="请选择">
              <el-option
                v-for="item in currencyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="6">
          <el-form-item label="计费方式" prop="billingMethod">
            <el-select v-model="form.billingMethod" placeholder="请选择">
              <el-option
                v-for="item in billingMethodOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="运输周期" prop="transportCycle">
            <el-input
              v-model.number="form.transportCycle"
              placeholder="请输入"
              oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.remark"
              maxlength="500"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="display: flex; align-items: center">
      <h3>运费信息</h3>
      <el-button class="ml10" type="primary" @click="setRule">
        添加规则
      </el-button>
    </div>
    <el-table
      :data="form.freightSettingLadderDTOList"
      border
      style="width: 100%; margin-bottom: 50px"
      class="mt20"
      :span-method="ruleSpanMethod"
      ref="myTable"
      :key="tableKey"
    >
      <el-table-column
        prop="countryCn"
        label="国家"
        align="center"
        width="300"
      ></el-table-column>
      <el-table-column
        label="计价类型"
        width="300"
        align="center"
        prop="pricingType"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.pricingType == 1 ? '单价' : '总价' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="范围" align="center" prop="billing">
        <template slot-scope="scope">
          <span>
            {{ scope.row.startBillingLadder }}~{{ scope.row.billingLadder }}
            {{ form.billingMethod == 1 ? 'm³' : 'kg' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center">
        <template slot-scope="scope">
          <span>
            {{ scope.row.price }}{{ form.currency === '美元' ? '美元' : '元' }}
          </span>
          <span v-if="scope.row.pricingType == 1">
            {{ form.billingMethod == 1 ? '/ m³' : '/ kg' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="oper" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="editRule(scope)">编辑</el-button>
          <el-button type="text" @click="delRule(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="text-center mt20 fixed">
      <el-button type="primary" :loading="loading" @click="save">
        确 认
      </el-button>
      <el-button @click="close" class="ml20">取 消</el-button>
    </el-row>
    <freight-rule-model
      :modelVisible.sync="modelVisible"
      :addRuleType="addRuleType"
      :countryOptions="countryOptions"
      @saveRule="saveRule"
      :editMsg="editMsg"
      :currency="this.form.currency"
      :selectdCountry="selectdCountry"
      :modelType="modelType"
    ></freight-rule-model>
  </div>
</template>
<script>
  import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'
  import freightRuleModel from './components/freight-rule-model'
  import { transportType, billingMethod, currency } from './util/help.js'
  import mixins from './util/mixins'
  export default {
    mixins: [mixins],
    components: {
      freightRuleModel,
    },
    data() {
      return {
        loading: false,
        pageType: '',
        form: {
          transportWayCn: '',
          transportWayEn: '',
          transportType: null,
          billingMethod: null,
          transportCycle: null,
          billingMethod: null,
          currency: '人民币',
          freightSettingLadderDTOList: [],
          remark: '',
        },
        transportTypeOptions: transportType,
        billingMethodOptions: billingMethod,
        currencyOptions: currency,
        countryOptions: [], // 国家列表
        rules: {
          transportWayCn: [
            { required: true, trigger: 'blur', message: '请输入运输方式' },
          ],
          transportWayEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
          transportType: [
            { required: true, trigger: 'change', message: '请选择运输类型' },
          ],
          currency: [
            { required: true, trigger: 'blur', message: '请选择报价币种' },
          ],
          billingMethod: [
            { required: true, trigger: 'change', message: '请选择计费方式' },
          ],
          billingLadder: [
            { required: true, trigger: 'blur', message: '请选择报价币种' },
          ],
          transportCycle: [
            { required: true, trigger: 'blur', message: '请输入运输周期' },
          ],
        },
        tableData: [],
        modelVisible: false, // 添加规则弹窗
        addRuleType: '', // 添加规则类型
        editMsg: {}, // 编辑规则对象
        modelType: 'add', // 弹窗类型，编辑 新增
        tableKey: 0,
        selectdCountry: '',
        noEditList: [],
      }
    },
    watch: {},
    created() {
      this.pageType = this.$route.query.pageType
      this.countryList()
      if (this.$route.query.id) {
        this.getDetail(this.$route.query.id)
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
        this.editMsg = {}
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.freightSettingLadderDTOList.length === 0) {
              this.$message.warning('至少添加一条规则！')
              return
            }
            let res = {}
            this.loading = true
            if (this.$route.query.id) {
              res = await FreightSettingInteractor.setFreighUpdatePO(this.form)
              this.loading = false
            } else {
              res = await FreightSettingInteractor.setFreighInsertPO(this.form)
              this.loading = false
            }

            if (res?.code === '000000') {
              this.$message.success('操作成功！')
              this.close()
            }
          }
        })
      },
      //关闭
      close() {
        this.$refs['form'].resetFields()
        this.form = {
          transportWayCn: '',
          transportWayEn: '',
          transportType: null,
          billingMethod: null,
          transportCycle: null,
          billingMethod: null,
          currency: '人民币',
          freightSettingLadderDTOList: [],
          remark: '',
        }
        this.goBack()
      },
      setRule() {
        if (this.form.billingMethod === null) {
          this.$message.warning('请选择计费方式！')
          return
        }
        this.addRuleType = this.form.billingMethod == 1 ? 'volume' : 'weight'
        this.modelVisible = true
        this.modelType = 'add'
        if (this.form.freightSettingLadderDTOList.length > 0) {
          let arr = this.form.freightSettingLadderDTOList.map(
            (item) => item.country
          )
          this.selectdCountry = arr.toString()
        }
      },
      // 获取国家列表
      async countryList() {
        this.countryOptions = await FreightSettingInteractor.getListCountry()
        this.countryOptions = this.countryOptions.map((ot) => {
          return {
            ...ot,
            country: ot.code,
            countryCn: ot.areaName,
            countryEn: ot.nameEn,
          }
        })
      },
      // 添加规则
      saveRule(ruleForm) {
        // 没有编辑的数据，组合到编辑传过来的数据
        if (this.modelType === 'add') {
          this.form.freightSettingLadderDTOList =
            this.form.freightSettingLadderDTOList.concat(ruleForm)
        } else {
          // 编辑的。
          this.form.freightSettingLadderDTOList = ruleForm.concat(
            this.noEditList
          )
        }

        // // 根据国家和计价类型 设置一个标识
        this.form.freightSettingLadderDTOList.forEach((ot) => {
          this.$set(ot, 'tag', `${ot.country}-${ot.pricingType}`)
        })

        this.spanArr = []

        this.getSpanArr(this.form.freightSettingLadderDTOList)
      },

      // 获取详情
      async getDetail(id) {
        let res = await FreightSettingInteractor.getFreightDetail(id)
        this.form = {
          ...res,
          freightSettingLadderDTOList: res.freightSettingLadderVOS,
        }
        // 根据国家和计价类型 设置一个标识
        this.form.freightSettingLadderDTOList.forEach((ot) => {
          this.$set(ot, 'tag', `${ot.country}-${ot.pricingType}`)
        })
        this.getSpanArr(this.form.freightSettingLadderDTOList)
      },
      // 编辑规则
      editRule(scope) {
        this.modelVisible = true
        this.modelType = 'edit'
        let arr = this.form.freightSettingLadderDTOList.filter((item) => {
          return item.tag === scope.row.tag
        })
        let arr_c = this.form.freightSettingLadderDTOList.map(
          (item) => item.code
        )
        this.selectdCountry = arr_c.toString()

        this.noEditList = this.form.freightSettingLadderDTOList.filter(
          (item) => {
            return item.tag !== scope.row.tag
          }
        )

        this.editMsg = {
          country: [arr[0].country],
          pricingType: arr[0].pricingType,
          ladderAndPriceDTOS: arr.map((item) => {
            return {
              billingLadder: item.billingLadder,
              price: item.price,
              startBillingLadder: item.startBillingLadder,
            }
          }),
        }
        if (this.form.freightSettingLadderDTOList.length > 0) {
          let arr = this.form.freightSettingLadderDTOList.map(
            (item) => item.country
          )
          this.selectdCountry = arr.toString()
        }
        this.addRuleType = this.form.billingMethod == 1 ? 'volume' : 'weight'
      },
      // 删除规则
      delRule(scope) {
        // 用国家和计价类型作为一个标记 用来判断删除
        this.$confirm('删除整条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.freightSettingLadderDTOList =
              this.form.freightSettingLadderDTOList.filter((item) => {
                return item.tag !== scope.row.tag
              })
            this.tableKey++

            this.$message({
              type: 'success',
              message: '删除成功!',
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      handleEdit(e) {
        let value = e.replace(/[^d]/g, '') // 只能输入数字
        value = value.replace(/^0+(d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
        value = value.replace(/(d{15})d*/, '$1') // 最多保留15位整数
        this.form.transportCycle = value
      },
      isnumber(val) {
        val = val.replace(/\b(0+)+[^0-9]*/gi, '')
        return val
      },
    },
  }
</script>

<style lang="scss" scoped>
  .oper {
    padding: 20px 10px;
    height: calc(100vh - 45px);
    overflow-y: scroll;
    ::v-deep {
      .el-select {
        width: 100%;
      }
    }
  }
  .fixed {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
