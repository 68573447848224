<!--
  功能：指定角色弹窗
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年02月09日 11:25:46
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="角色" prop="user">
          <el-input
            v-model.trim="form.momey"
            placeholder="请输入"
            @focus="selectUser"
          />
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <el-row class="t_a_c">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'ClientSettingUser',
  data() {
    return {
      title: '指定角色',
      form: {
        selectUser: '',
        momey: '',
      },
      rules: {
        selectUser: [
          { required: true, message: '请选择角色', trigger: 'change' },
        ],
      },
      dialogFormVisible: false,
      selectUser: '',
    }
  },
  created() {},
  methods: {
    //新增、编辑
    showAddEdit() {
      // this.form = Object.assign({}, row)
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('fetch-data')
          this.close()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>