var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[([4, 6].includes(Number(_vm.code)))?_c('CompanyDetailSearch',{attrs:{"serchForm":_vm.serchForm,"code":_vm.code},on:{"serchForm":_vm.search}}):_vm._e(),_c('ErpTable',{key:_vm.tableKey,ref:"erpTable",staticClass:"table-fixed",attrs:{"page-number":_vm.pageNo,"page-size":_vm.pageLe,"table-data":_vm.tableData,"total":_vm.total},on:{"update:pageNumber":function($event){_vm.pageNo=$event},"update:page-number":function($event){_vm.pageNo=$event},"update:pageSize":function($event){_vm.pageLe=$event},"update:page-size":function($event){_vm.pageLe=$event},"query":_vm.query,"row-click":_vm.rowClick}},[_c('el-table-column',{attrs:{"align":"center","type":"index","width":"55","label":_vm.lang === 'en' ? 'No.' : '序号'}}),_vm._l((_vm.finallyColumn),function(item,index){return _c('el-table-column',{key:index + _vm.tableKey,attrs:{"label":[10, 11].includes(Number(_vm.code)) ? _vm.$t(item.langKey) : item.label,"show-overflow-tooltip":"","resizable":false,"align":"center","min-width":_vm.lang === 'en' ? item.enWidth : item.width,"prop":item.prop},scopedSlots:_vm._u([(_vm.code == 1 && item.prop == 'totlePrice')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s('$ ' + row.totlePrice || '--'))])]}}:(item.prop == 'linkman')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(row.linkman))])]}}:(item.prop == 'customerName')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(row.customerName))])]}}:(_vm.code == 10 && item.prop == 'creator')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? row.creator || '--' : row.creatorEn || '--')+" ")])]}}:(_vm.code == 11 && item.prop == 'creator')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? row.creator || '--' : row.creatorEn || '--')+" ")])]}}:(item.prop == 'orderCode' && _vm.code == 1)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"page-link",on:{"click":function($event){return _vm.goOrderDetail(row.orderCode)}}},[_vm._v(" "+_vm._s(row.orderCode)+" ")])]}}:(item.prop == 'businessNumber' && _vm.code == 6)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"page-link",on:{"click":function($event){return _vm.goOrderDetail(row.businessNumber)}}},[_vm._v(" "+_vm._s(row.businessNumber)+" ")])]}}:(item.prop == 'salesOrder' && _vm.code == 8)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"page-link",on:{"click":function($event){return _vm.goOrderDetail(row.salesOrder)}}},[_vm._v(" "+_vm._s(row.salesOrder)+" ")])]}}:{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row[item.prop] || '--'))])]}}],null,true)})})],2),([1, 2].includes(Number(_vm.code)))?_c('div',{staticClass:"mt20"},[_c('detail-statistics',{attrs:{"infoId":_vm.infoId,"code":_vm.code,"type":"customer"}})],1):_vm._e(),(_vm.code == 10)?_c('div',{staticClass:"mt20"},[_c('ReplayRecord',{attrs:{"tableData":_vm.currentHistory}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }