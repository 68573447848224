<template>
  <IndexCard title="收款认领" :loading="loading">
    <div v-if="list && list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="customer-charge-item">
          <div class="content" @click="showDetail(item)">
            <div class="left">
              <div class="top">
                <span style="color: #282c34">收款单号</span>
                <span :style="boldStyle.bold" class="ml5">
                  {{ item.financeCode }}
                </span>
              </div>
              <div class="bottom">
                <span>{{ item.shroffAccountId }}&nbsp;-&nbsp;</span>
                <span>
                  <span>{{ item.currency === '美元' ? '$' : '￥' }}</span>
                  {{ item.amountCollected }}&nbsp;-&nbsp;
                </span>
                <span>{{ item.amountTime }}</span>
              </div>
            </div>
            <div class="right index-card-time">
              {{ item.createTime | timeFilter }}
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
    <!-- 收款认领弹窗 -->
    <el-dialog
      :visible.sync="show"
      title="收款认领"
      width="90%"
      append-to-body
      v-if="show"
      @close="onClose"
    >
      <div class="erpdialog-detail-style" style="border: none">
        <el-table border class="w100" :data="receiptTableData">
          <el-table-column
            label="付款方"
            align="center"
            prop="shroffAccountId"
          ></el-table-column>
          <el-table-column
            label="收款方式"
            align="center"
            prop="financeMethod"
          ></el-table-column>
          <el-table-column
            label="票据单号"
            align="center"
            prop="ticketNumber"
          ></el-table-column>
          <el-table-column
            label="币种"
            align="center"
            prop="currency"
          ></el-table-column>
          <el-table-column
            label="收款金额"
            align="center"
            prop="amountCollected"
          >
            <template slot-scope="scope">
              <span class="green-bold">
                {{ scope.row.amountCollected | orderMoneyFormat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="收款日期"
            align="center"
            prop="amountTime"
          ></el-table-column>
        </el-table>
        <el-row :gutter="20" type="flex" class="mt30">
          <el-col :span="8">
            <label>备注</label>
            <tooltip-over
              class="c_pointer"
              :content="receiptData.remark"
              refName="tooltipOver5"
            >
              <p>{{ receiptData.remark || '--' }}</p>
            </tooltip-over>
          </el-col>
          <el-col :span="8">
            <label>附件</label>
            <div v-show="fileList.length">
              <div class="flie-list" v-for="(item, i) in fileList" :key="i">
                <i class="el-icon-document"></i>
                <a class="page-link ml5">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="top-start"
                  >
                    <span class="ell">
                      <router-link
                        :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                        target="_blank"
                      >
                        {{ item.name }}
                      </router-link>
                    </span>
                  </el-tooltip>
                </a>
                <span class="size ml10">
                  {{ utils.calculateFileSize(item.size) }}
                </span>
                <span class="ml10">{{ item.time }}</span>
              </div>
            </div>
            <p v-show="!fileList.length">--</p>
          </el-col>
        </el-row>

        <!-- 订单 -->
        <el-form ref="form" :model="form" label-position="left" :rules="rules">
          <table class="custom-table mt30">
            <thead>
              <tr>
                <th width="150" minWidth="100">
                  <span>
                    <i v-show="form.financeOrderList.length ? true : false">
                      *
                    </i>
                    订单编号
                  </span>
                </th>
                <th width="100" minWidth="50">客户姓名</th>
                <th width="150" minWidth="100">公司名称</th>
                <th width="80" minWidth="50">客户代表</th>
                <th width="80" minWidth="50">所属地区</th>
                <th width="60" minWidth="50">币种</th>
                <th width="120" minWidth="50">订单金额</th>
                <th width="120" minWidth="100">
                  <span>
                    <i v-show="form.financeOrderList.length ? true : false">
                      *
                    </i>
                    收款金额
                  </span>
                </th>
                <th width="150" minWidth="100">
                  <span>
                    <i v-show="form.financeOrderList.length ? true : false">
                      *
                    </i>
                    手续费金额
                  </span>
                </th>
                <th width="100" minWidth="50">应收金额</th>
                <th width="40">操作</th>
              </tr>
            </thead>
            <tbody v-if="form.financeOrderList">
              <tr v-for="(item, i) in form.financeOrderList" :key="i">
                <!-- 订单编号 -->
                <td>
                  <el-form-item
                    style="width: 150px"
                    :prop="'financeOrderList.' + i + '.orderCode'"
                    :rules="[
                      {
                        required: true,
                        message: '订单编号必填',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model.trim="form.financeOrderList[i].orderCode"
                      placeholder="请输入"
                      @change="
                        (val) => {
                          handleOrderIdInput(val, i)
                        }
                      "
                    ></el-input>
                  </el-form-item>
                </td>
                <!-- 客户姓名 -->
                <td>{{ item.customerName || '--' }}</td>

                <!-- 公司名称 -->
                <td>{{ item.companyName || '--' }}</td>

                <!-- 客户代表 -->
                <td>{{ item.businessName || '--' }}</td>

                <!-- 所属地区 -->
                <td>{{ item.area || '--' }}</td>

                <!-- 币种 -->
                <td>{{ item.currency || '--' }}</td>

                <!-- 订单金额 -->
                <td>
                  {{ item.totlePrice | orderMoneyFormat }}
                </td>

                <!-- 收款金额 -->
                <td>
                  <el-form-item
                    :prop="'financeOrderList.' + i + '.amountCollected'"
                    :rules="[
                      {
                        required: form.financeOrderList.length ? true : false,
                        message: '收款金额必填',
                        trigger: 'blur',
                      },
                      {
                        pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
                        message: '格式不正确',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model.trim="form.financeOrderList[i].amountCollected"
                      controls-position="right"
                      placeholder="请输入"
                      @blur="
                        (val) => {
                          amountCollectedInput(val, i)
                        }
                      "
                      :min="0"
                      :precision="2"
                    ></el-input>
                  </el-form-item>
                </td>

                <!-- 手续费金额 -->
                <td>
                  <el-form-item
                    :prop="'financeOrderList.' + i + '.commissionAmount'"
                    :rules="[
                      {
                        required: form.financeOrderList.length ? true : false,
                        message: '手续费金额必填',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="form.financeOrderList[i].commissionAmount"
                      :disabled="true"
                      @change="
                        (val) => {
                          commissionAmountInput(val, i)
                        }
                      "
                    ></el-input>
                  </el-form-item>
                </td>

                <!-- 应收金额 -->
                <td>
                  <span>
                    {{
                      (item.amountReceivable =
                        item.amountCollected - item.commissionAmount)
                        | orderMoneyFormat
                    }}
                  </span>
                </td>

                <!-- 操作 -->
                <td class="text-center">
                  <el-button
                    v-if="i > 0"
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    @click="deleteNewLine(i)"
                  ></el-button>
                  <span v-else>--</span>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form>
        <el-row>
          <el-col class="pt20 f_s_16 text-center">
            <span style="color: #409eff; cursor: pointer" @click="addOneLine">
              新增一行
            </span>
          </el-col>
        </el-row>
      </div>
      <h3 class="mt30 mb20">
        <i class="required">*</i>
        附件
        <span style="color: #37376b">（请上传相关转账凭证或者邮件截图）</span>
      </h3>
      <ErpUpload
        ref="ErpUpload"
        :uploadParams="uploadParams"
        @uploadList="uploadList"
        @initUpload="initUpload"
        style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
      ></ErpUpload>

      <div class="footer-wrapper mt30 t_a_c" style="text-align: center">
        <el-button type="primary" class="ml30" @click="confirm('form')">
          确认
        </el-button>
        <el-button @click="onClose">取 消</el-button>
      </div>
    </el-dialog>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import { HomeInteractor, CreditManageInteractor } from '@/core'

  import tooltipOver from '@/components/base-tooltip'
  const math = require('mathjs')
  export default {
    name: 'CollectionClaim',
    mixins: [mixins],
    components: { tooltipOver },
    data() {
      return {
        list: [],
        financeId: '', //收款单id
        show: false,
        current: {},
        receiptData: {},
        receiptTableData: [],
        fileList: [],
        form: {
          financeOrderList: [
            {
              amountCollected: null, //收款金额1
              amountReceivable: null, //应收金额2
              area: '', //所属地区3
              businessId: '', //客户代表id4
              businessName: '', //客户代表名称5
              claimTime: '', //认领时间6
              commissionAmount: null, //手续费金额7
              companyName: '', //公司名称8
              currency: '', //币种9
              customerName: '', //客户名称10
              financeId: null, //收款id 11
              financeOrderId: null, //业务id 12
              infoId: '', //客户id 13
              orderCode: '', //订单编号 14
              orderId: '', //订单id 15
              orderType: 1, //主订单 16
              outstandingAmount: null, //未收金额 17
              totlePrice: null, //订单金额 18
            },
          ],
        },
        rules: {},
        paymentOptions: [], //收款方式
        commissionAmount: 0, // 国际手续费
        settleFee: 0, // 结算手续费
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        claimedAffixUrl: '',
      }
    },
    created() {
      this.getList()
      this.getSelectOptions(3)
    },

    methods: {
      initUpload() {},
      uploadList(fileList) {
        this.claimedAffixUrl = JSON.stringify(fileList)
      },
      showDetail(item) {
        this.financeId = item.financeId
        this.getCollectionClaimDetail(item.financeId)
        this.current = item
        this.show = true
      },
      async getCollectionClaimDetail(financeId) {
        let response = await HomeInteractor.collectionClaimDetail({
          financeId: financeId,
        })
        if (response?.code === '000000') {
          this.receiptData = response.data
          //收款单table
          let receiptTableObj = {
            shroffAccountId: this.receiptData.shroffAccountId || '--', //付款方
            financeMethod: this.receiptData.financeMethod || '--', //收款方式
            ticketNumber: this.receiptData.ticketNumber || '--', //票据单号
            currency: this.receiptData.currency || '--', //币种
            amountCollected:
              this.toFixed2(this.receiptData.amountCollected) || '--', //收款金额
            amountTime: this.receiptData.amountTime || '--', //收款日期
          }
          this.receiptTableData.push(receiptTableObj)

          //附件
          this.fileList = this.receiptData.financeUrl
            ? JSON.parse(this.receiptData.financeUrl)
            : ''

          // 计算手续费金额
          this.getAmountCollected()
        }
      },

      getAmountCollected() {
        let params = {
          amountCollected: this.receiptData.amountCollected,
          currency: this.receiptData.currency,
          amountTime: this.receiptData.amountTime,

          financeSetupId: this.paymentOptions.filter(
            (item) => item.financeName === this.receiptData.financeMethod
          )[0]?.financeSetupId,
        }
        if (Object.values(params).every((item) => !!item)) {
          HomeInteractor.calculationServiceCharge({
            ...params,
            entryType: 0,
          }).then((res) => {
            this.commissionAmount = res.data.commissionAmount // 国际手续费
            this.settleFee = res.data.settleFee // 结算手续费

            this.calcCommissionAmount()
          })
        }
      },

      // 计算手续费金额
      calcCommissionAmount() {
        let commissionAmount = this.commissionAmount
        let settleFee = this.settleFee
        let financeOrderList = this.form.financeOrderList
        if (
          financeOrderList.length &&
          financeOrderList.every((item) => item.amountCollected != '')
        ) {
          const total = this.utils.amountTotal(
            financeOrderList,
            'amountCollected',
            3
          )
          for (let i = 0; i < financeOrderList.length; i++) {
            // 赋值国际手续费
            this.$set(
              financeOrderList[i],
              'commissionAmount',
              commissionAmount > 0
                ? (
                    (financeOrderList[i].amountCollected / total) *
                    commissionAmount
                  ).toFixed(2)
                : 0
            )
            // 赋值结算手续费
            let dt_2 = math.format(
              (financeOrderList[i].amountCollected / total) * settleFee
            )
            this.$set(
              financeOrderList[i],
              'settleFee',
              settleFee > 0 ? Number(dt_2).toFixed(2) : 0
            )
          }
        }
      },

      onClose() {
        this.financeId = ''
        this.fileList = []
        this.receiptData = {}
        this.receiptTableData = []
        this.form.financeOrderList = [
          {
            amountCollected: null, //收款金额1
            amountReceivable: null, //应收金额2
            area: '', //所属地区3
            businessId: '', //客户代表id4
            businessName: '', //客户代表名称5
            claimTime: '', //认领时间6
            commissionAmount: null, //手续费金额7
            companyName: '', //公司名称8
            currency: '', //币种9
            customerName: '', //客户名称10
            financeId: null, //收款id 11
            financeOrderId: null, //业务id 12
            infoId: '', //客户id 13
            orderCode: '', //订单编号 14
            orderId: '', //订单id 15
            orderType: 1, //主订单 16
            outstandingAmount: null, //未收金额 17
            totlePrice: null, //订单金额 18
          },
        ]
        this.claimedAffixUrl = ''
        this.paymentOptions = []
        this.show = false
      },

      // 新增一行
      addOneLine() {
        this.form.financeOrderList.push({
          amountCollected: null, //收款金额1
          amountReceivable: null, //应收金额2
          area: '', //所属地区3
          businessId: '', //客户代表id4
          businessName: '', //客户代表名称5
          claimTime: '', //认领时间6
          commissionAmount: null, //手续费金额7
          companyName: '', //公司名称8
          currency: '', //币种9
          customerName: '', //客户名称10
          financeId: null, //收款id 11
          financeOrderId: null, //业务id 12
          infoId: '', //客户id 13
          orderCode: '', //订单编号 14
          orderId: '', //订单id 15
          orderType: 1, //主订单 16
          outstandingAmount: null, //未收金额 17
          totlePrice: null, //订单金额 18
        })
      },
      // 删除新增的订单
      deleteNewLine(i) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.financeOrderList.splice(i, 1)
            this.calcCommissionAmount()
          })
          .catch(() => {})
      },

      //订单编号
      handleOrderIdInput(val, index) {
        HomeInteractor.getOrderByOrderCode({ orderCode: val }).then((res) => {
          if (res && res.code === '000000') {
            this.form.financeOrderList[index].area = res.data.area //所属地区
            this.form.financeOrderList[index].businessId = res.data.businessId //客户代表id
            this.form.financeOrderList[index].businessName =
              res.data.businessName //客户代表名称
            this.form.financeOrderList[index].claimTime = res.data.claimTime //认领时间
            this.form.financeOrderList[index].companyName = res.data.companyName //公司名称
            this.form.financeOrderList[index].currency = res.data.currency //币种
            this.form.financeOrderList[index].customerName =
              res.data.customerName //客户名称
            this.form.financeOrderList[index].financeId = res.data.financeId //收款id
            this.form.financeOrderList[index].financeOrderId = //业务id
              res.data.financeOrderId
            this.form.financeOrderList[index].infoId = res.data.infoId //客户id
            this.form.financeOrderList[index].orderCode = res.data.orderCode //订单code
            this.form.financeOrderList[index].orderId = res.data.orderId //订单id
            this.form.financeOrderList[index].outstandingAmount = //未收金额
              res.data.outstandingAmount
            this.form.financeOrderList[index].totlePrice = res.data.totlePrice //订单金额
              ? res.data.totlePrice
              : 0
            this.$forceUpdate()
          }

          let currencyArr = this.form.financeOrderList.map(
            (item) => item.currency
          )
          let deWeight = [...new Set(currencyArr)]
          if (deWeight.length >= 2) {
            return this.$message.warning('订单币种必须保持一致')
          }

          if (
            res?.data?.currency != this.receiptData?.[0]?.currency &&
            this.receiptData?.[0]?.currency
          ) {
            this.$message.warning('订单币种与收款单币种不一致')
          }
        })
        //}
      },

      // 获取所有下拉框数据
      async getSelectOptions(type) {
        // 1币种设置 2发票设置 3支付方式 4科目 5费用杂项
        let response = await CreditManageInteractor.selectOptions({
          financeType: Number(type),
        })
        if (response?.code === '000000') {
          if (type === 3) {
            this.paymentOptions = response.data
          }
        }
      },

      //收款金额
      amountCollectedInput(e, index) {
        this.calcCommissionAmount()
      },

      // 查询
      getList() {
        this.list = []
        this.loading = true
        HomeInteractor.collectionClaimList({
          belongerId: this.userInfo.userId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.list = res.data
          }
          this.loading = false
        })
        this.loading = false
      },

      //确认
      confirm(form) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            if (this.claimedAffixUrl && this.claimedAffixUrl.length) {
              HomeInteractor.collectionClaim({
                financeId: this.financeId,
                financeOrderList: this.form.financeOrderList,
                claimedAffixUrl: this.claimedAffixUrl,
              }).then((res) => {
                if (res && res.code === '000000') {
                  this.$message.success('操作成功')
                  this.getList()
                  this.onClose()
                }
              })
            } else {
              this.$message.warning('请上传相关转账凭证或者邮件截图')
            }
          } else {
            this.$message.warning('请完善订单数据')
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .customer-charge-item {
    .content {
      display: flex;
      cursor: pointer;

      .left {
        display: flex;
        flex-direction: column;
        color: #000;
        flex: 1;

        .top {
          margin-bottom: 10px;
        }

        .bottom {
          color: #008000;
          font-size: 12px;

          .user {
            margin-right: 13px;
          }
        }
      }

      .right {
        padding-left: 10px;
        display: flex;
        align-items: flex-start;
      }
    }
  }

  .green-bold {
    color: green;
    font-weight: bold;
  }

  .custom-table {
    width: 100%;
  }

  ::v-deep {
    .el-form-item--small.el-form-item {
      margin-bottom: 0;
    }
  }
</style>
