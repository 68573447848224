<template>
  <div class="track-set custom-container">
    <!-- <div>
      当前模板配置归属:
      <el-select v-model="areaValue" placeholder="请选择" disabled>
        <el-option
          v-for="(item, index) in areaOption"
          :key="index"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </div> -->
    <!-- 订单同步 -->
    <order-synchro @getSynchroSetting="getSynchroSetting" :synchroInfo="trackSetInfo.trackingSynchroSettingVO"
      ref="orderSynchro"></order-synchro>
    <!-- 自定义订单节点 -->
    <order-node :initNodeList="initNodeList" :initDefaultList="trackSetInfo.trackingNodeSettingVOList1"
      :initCustomList="trackSetInfo.trackingNodeSettingVOList2" :nodeTypeOption="nodeTypeOption"
      @getNodeData="getNodeData" ref="orderNode"></order-node>
    <!-- 预警提醒 -->
    <warning-reminder @getReminderData="getReminderData" :reminderInfo="trackSetInfo.trackingWarningSettingVO"
      ref="warnReminder"></warning-reminder>
    <!-- 保存 -->

    <div class="submit">
      <el-button v-allowed="['TRACKCONFIG:UPDATE']" type="primary" @click="beforeSave" :loading="loading">
        保存
      </el-button>
    </div>
  </div>
</template>
<script>
  import { trackInterface } from '@/core'
  import orderSynchro from './components/orderSynchro.vue'
  import orderNode from './components/orderNode.vue'
  import warningReminder from './components/warningReminder.vue'
  import _ from 'lodash'
  import { mapGetters } from 'vuex'
  export default {
    name: 'track-setting',
    components: {
      orderSynchro,
      orderNode,
      warningReminder,
    },
    data() {
      return {
        areaOption: [], // 地区选择列表
        areaValue: '', // 地区
        initNodeList: [], // 默认木板
        nodeTypeOption: [], // 节点模板
        trackSetInfo: {}, // 页面信息
        nodeTemplate: 1, // 木板
        loading: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    created() {
      this.areaValue = this.userInfo.area
      // 初始化地区
      this.getLoactionList()
      // 查询初始化模板
      this.getInitialNode()
      // 初始化节点模板
      this.getListNodeType()

      // 获取订单跟踪设置详情
      this.getSetDetail()
    },
    methods: {
      async getLoactionList() {
        const res = await trackInterface.getListHomeLocation()
        if (res.code === '000000') {
          this.areaOption = res?.data
        }
      },
      async getInitialNode() {
        const res = await trackInterface.getListInitialNode()
        if (res.code === '000000') {
          this.initNodeList = res?.data
        }
      },
      async getListNodeType() {
        const res = await trackInterface.getListNodeType()
        if (res.code === '000000') {
          this.nodeTypeOption = res?.data
        }
      },
      async getSetDetail() {
        // area: this.areaValue 1.6.1改版 传固定地区
        const res = await trackInterface.getTrackDetail()

        if (res.code === '000000') {
          this.trackSetInfo = res?.data
          this.trackSetInfo.trackingNodeSettingVOList2 = this.trackSetInfo
            .trackingNodeSettingVOList2
            ? this.trackSetInfo.trackingNodeSettingVOList2
            : []
          this.trackSetInfo.trackingNodeSettingVOList1 = this.trackSetInfo
            .trackingNodeSettingVOList1
            ? this.trackSetInfo.trackingNodeSettingVOList1
            : []
        }
      },
      // 订单同步
      getSynchroSetting(synchroValue) {
        this.trackSetInfo.trackingSynchroSettingDTO = { ...synchroValue }
      },
      // 预警提醒
      getReminderData(reminderValue) {
        this.trackSetInfo.trackingWarningSettingDTO = { ...reminderValue }
      },
      // 跟单节点
      getNodeData(nodeValue, template) {
        this.nodeTemplate = template
        this.trackSetInfo = {
          ...this.trackSetInfo,
          ...nodeValue,
        }
      },
      checkRepeat(arr) {
        let status = false
        if (arr.length == 0) {
          return true
        }
        arr?.forEach((item, index) => {
          if (arr.indexOf(item) !== index) {
            status = false
          } else {
            status = true
          }
        })
        return status
      },
      // 保存之前做校验 判断
      beforeSave() {
        // 判断节点名称是否有重复
        let list_1_nodeNameCn =
          this.trackSetInfo.trackingNodeSettingDTOList1.map(
            (item) => item.nodeNameCn
          )
        let list_1_nodeNameEn =
          this.trackSetInfo.trackingNodeSettingDTOList1.map(
            (item) => item.nodeNameEn
          )
        let list_2_nodeNameCn =
          this.trackSetInfo.trackingNodeSettingDTOList2.map(
            (item) => item.nodeNameCn
          )
        let list_2_nodeNameEn =
          this.trackSetInfo.trackingNodeSettingDTOList2.map(
            (item) => item.nodeNameEn
          )
        if (
          !this.checkRepeat(list_1_nodeNameCn) ||
          !this.checkRepeat(list_1_nodeNameEn) ||
          !this.checkRepeat(list_2_nodeNameCn) ||
          !this.checkRepeat(list_2_nodeNameEn)
        ) {
          this.$message.warning('节点名称不能重复！')
          return
        }
        // 订单同步金额判断
        this.$refs.orderSynchro.$refs.form.validate((valie) => {
          if (valie) {
            // 模板内节点名称
            if (this.nodeTemplate === 1) {
              if (this.trackSetInfo.trackingNodeSettingDTOList1.length == 0) {
                this.$message.warning('模板内至少有一个节点！')
                return
              }
              this.$refs.orderNode.$refs.defaultForm.validate((valie_1) => {
                if (valie_1) {
                  this.checkWarn()
                } else {
                  this.$message.warning('节点名称必填！')
                }
              })
            }
            if (this.nodeTemplate === 2) {
              if (this.trackSetInfo.trackingNodeSettingDTOList2.length == 0) {
                this.$message.warning('模板内至少有一个节点！')
                return
              }
              this.$refs.orderNode.$refs.customTableForm.validate((valie_2) => {
                if (valie_2) {
                  this.checkWarn()
                } else {
                  this.$message.warning('节点名称必填！')
                }
              })
            }
          } else {
            this.$message.warning('金额不能为空！')
          }
        })
      },
      checkWarn() {
        this.$refs.warnReminder.$refs.form.validate((valie) => {
          if (valie) {
            this.save()
          } else {
            this.$message.warning('时间不能为空！')
          }
        })
      },
      // 保存
      async save() {
        // 订单同步form校验
        // 配置金额必填
        this.loading = true
        let newObj = _.cloneDeep(this.trackSetInfo)
        delete newObj.trackingNodeSettingVOList1
        delete newObj.trackingNodeSettingVOList2
        delete newObj.trackingSynchroSettingVO
        delete newObj.trackingWarningSettingVO
        let obj = {
          area: this.areaValue,
          ...newObj,
        }
        if (
          obj.trackingSynchroSettingDTO.startAmount >
          obj.trackingSynchroSettingDTO.endAmount
        ) {
          this.$message.warning('结束金额不能小于开始金额！')
          return
        }
        const res = await trackInterface.saveTrackSet(obj)

        if (res.code === '000000') {
          this.$message.success('保存成功！')
        }
        this.getSetDetail()
        this.loading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .track-set {
    .submit {
      display: flex;
      justify-content: center;
      // position: sticky;
      // bottom: 40px;
      margin: 80px 0;
    }
  }
</style>