<!--
  功能：运营成本配置-人员(新增或编辑)
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月22日 14:13:26
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="tpl-new-wrapper">
    <h3>
      <span class="vertical"></span>
      地区模板配置
    </h3>
    <el-table border :data="areaTplData">
      <el-table-column
        align="center"
        label="地区"
        prop="area"
      ></el-table-column>
      <el-table-column
        align="center"
        label="产品类型"
        prop="standardCustomized"
      ></el-table-column>
      <el-table-column align="center" label="毛利额" prop="conditionType">
        <template slot-scope="scope">
          {{ scope.row.conditionType == 1 ? '大于0' : '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="运营成本(%)"
        prop="ratio"
      ></el-table-column>
    </el-table>

    <h3 class="h3">
      <span class="vertical"></span>
      人员条件配置
    </h3>
    <el-form :rules="rules" :model="form" ref="form">
      <el-table :data="form.tableData" border stripe class="form-rule-table">
        <el-table-column label="员工姓名" prop="area" align="center">
          <template slot="header">
            <i class="red">*</i>
            员工姓名
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.personnoelCn'"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                },
              ]"
            >
              <el-input
                v-model="scope.row.personnoelCn"
                clearable
                @focus="selUser(scope.row)"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="员工条件运营成本(%)"
          prop="standardCustomized"
          align="center"
        >
          <template slot="header">
            <i class="red">*</i>
            员工条件运营成本(%)
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.ratio'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                },
              ]"
            >
              <el-input-number
                style="width: 100%"
                v-model="scope.row.ratio"
                :min="0"
                :max="100"
                :controls="false"
                :precision="2"
                clearable
              ></el-input-number>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="del(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-button class="new-btn" @click="addLine" v-if="!$route.query.businessId">
      新增一行
    </el-button>
  </div>
</template>

<script>
import {
  areas,
  productTypes,
} from '@/views/finance-center/config-manage/helper'
import { OperatingCostConfigInteractor } from '@/core/interactors/finance/config-manage'
import { newMixin } from '../mixins'
const baseTpl = {
  ratio: undefined,
  personnoelId: '',
  personnoelCn: '',
  personnoelEn: '',
}
export default {
  name: 'OperatingCostConfigUserNew',
  mixins: [newMixin],
  props: {},
  components: {},

  // 组件状态值
  data() {
    return {
      areaTplData: [], //地区模板配置数据
      form: {
        tableData: [], //列表数据
      },
      rules: {}, //校验规则
      baseTpl, // 新增一行
      checkedKeys: ['ratio', 'personnoelCn'], // 校验项
    }
  },

  methods: {
    // 初始化数据
    async init() {
      const { businessId } = this.$route.query
      const areaTplDataStr = localStorage.getItem(
        'OPERATE_COST_CONFIG_TEMPLATE'
      )
      if (!businessId && areaTplDataStr) {
        this.areaTplData = [JSON.parse(areaTplDataStr)]
      }
      // 调接口获取模板数据

      if (!businessId) {
        this.form.tableData = [{ ...baseTpl }]
      } else {
        const res = await OperatingCostConfigInteractor.userGetDetail({
          businessId,
        })
        if (res && res.code === '000000') {
          this.form.tableData = [res.data]
          this.areaTplData = [
            {
              ...res.data,
              ratio: res.data.costRatio,
            },
          ]
        }
      }
    },

    // 选择人员
    selUser(row) {
      const { personnoelCn, personnoelId } = row
      this.currentRow = row
      this.$userChoose(this)
        .showPromisedUserAddEdit({
          str: '',
          roleId:
            personnoelId && personnoelCn
              ? [
                  {
                    id: personnoelId,
                    name: personnoelCn,
                  },
                ]
              : '',
          canSelectZeroUser: false,
          singleSelect: true,
          userStatus: 1,
        })
        .then((users) => {
          if (Array.isArray(users) && users.length > 0) {
            const { id, name, englishName } = users[0]
            this.currentRow.personnoelCn = name
            this.currentRow.personnoelEn = englishName
            this.currentRow.personnoelId = id
          }
        })
    },
    // 获取请求数据
    _getReqData(tenantId) {
      return this.form.tableData.map((item) => {
        return {
          ...item,
          configOperateCostId: this.areaTplData[0].configOperateCostId,
          tenantId,
        }
      })
    },
    save(tenantId) {
      this.$refs.form.validate(async (flag) => {
        if (flag) {
          const res = await OperatingCostConfigInteractor.userSaveList(
            this._getReqData(tenantId)
          )
          if (res.code === '000000') {
            this.$message.success('操作成功')
            this.saved = true
            this.$emit('back')
          }
        } else {
          this.$message.warning(
            '员工姓名、员工条件运营成本(%)不支持为空，请录入数据'
          )
        }
      })
    },
  },

  created() {
    this.init()
  },
}
</script>

<style scoped lang="scss">
.vertical {
  display: inline-block;
  height: 20px;
  width: 5px;
  background-color: #409eff;
  vertical-align: middle;
}
.new-btn {
  width: 100%;
  border-top: none;
  color: #409eff;
}

.el-form-item__error {
  display: none !important;
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px !important;
}

.el-table {
  margin-top: 10px;
}
h3 {
  margin-top: 5px;
}
.h3 {
  margin-top: 40px;
}
</style>
