<template>
  <div class="oper">
    <el-row>
      <el-page-header
        @back="goBack"
        content="结算人员配置角色新增"
        title="返回"
      ></el-page-header>
    </el-row>
    <el-row :gutter="20" class="m30">
      <el-col :span="1" class="item-title">角色</el-col>
      <el-col :span="23" :offset="1" class="m10">
        <el-table :data="roleTable" border style="width: 100%">
          <el-table-column
            prop="area"
            align="center"
            width="80"
            label="区域"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="standardCustomized"
            align="center"
            width="80"
            label="产品类型"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-for="item in roleList"
            :key="item.code"
            :prop="item.code"
            :label="item.message"
            min-width="120"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row[getRoleCode(item.message)]">
                <span>
                  {{ scope.row[getRoleCode(item.message)].mark }}
                </span>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="m30">
      <el-col :span="1" class="item-title">人员</el-col>
      <el-col :span="23" :offset="1" class="m10">
        <el-form :rules="rules" :model="form" ref="ruleForm">
          <el-table
            :data="form.tableData"
            border
            style="width: 100%"
            class="input-table"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              min-width="100"
              align="center"
              label-class-name="required-th"
              label="员工姓名（英文）"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.personnoelAllName'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.personnoelAllName"
                    @focus="fromBusinessChange(scope)"
                    clearable
                    @clear="clearFromBusinessName(scope)"
                    placeholder="请选择"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="条件" min-width="250" align="center">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.conditionDesc"
                  placeholder="请选择"
                  clearable
                  @focus="setCondition(scope)"
                  @clear="clearRoleCondition(scope)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="160px"
              label="条件提成点"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.configCommissionDesc'"
                  :rules="[
                    {
                      required: scope.row.conditionDesc,
                      message: '请选择',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.configCommissionDesc"
                    placeholder="请选择"
                    clearable
                    @focus="setAppoint(scope)"
                    @clear="clearCommission(scope)"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" min-width="160px" label="条件角色">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.roleType'"
                  :rules="[
                    {
                      required: scope.row.conditionDesc,
                      message: '请选择',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select v-model="scope.row.roleType" placeholder="请选择">
                    <el-option
                      v-for="item in scope.row.conditionRoleList"
                      :key="item.code"
                      :label="item.message"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="160px"
              label-class-name="required-th"
              label="模板生效时间"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.effectiveTime'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="scope.row.effectiveTime"
                    type="date"
                    placeholder="请选择"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="deleteItem(scope.$index)"
                  size="medium"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-col>
      <el-col :span="1" :offset="11">
        <el-button type="text" @click="pushNew" size="medium">
          新增一行
        </el-button>
      </el-col>
    </el-row>
    <el-row class="stick30" style="margin-top: 60px">
      <el-col :offset="11" :span="1">
        <el-button @click="goBack">返回</el-button>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" @click="save">保存</el-button>
      </el-col>
    </el-row>
    <!-- 条件模板选择 -->
    <condititon-model
      :modelVisible.sync="modelVisible"
      @submitCondition="submitCondition"
    ></condititon-model>
    <!-- 新建条件 本期不做 -->
    <!-- <condition-new
      :addConditionVisible.sync="addConditionVisible"
    ></condition-new> -->
    <!-- 条件提成点 -->
    <person-condition-appoint
      :appointVisible.sync="appointVisible"
      @setRow="setRow"
    ></person-condition-appoint>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>
<script>
  import { templateConfigOperColumns } from '@/views/finance-center/config-manage/helper'
  import condititonModel from './components/condititon-model'
  import personConditionAppoint from './components/person-condition-appoint'
  import UserChoose from '@/components/userChoose'
  import { deepCopy } from 'kits'
  import { SettleConfigInteractor } from '@/core/interactors/finance/config-manage'
  import mixins from './mixins'
  import _ from 'lodash'
  export default {
    components: {
      condititonModel,
      UserChoose,
      personConditionAppoint,
    },
    mixins: [mixins],
    data() {
      return {
        roleColumn: templateConfigOperColumns,
        form: {
          tableData: [],
        },
        modelVisible: false, //条件模板弹窗
        //  addConditionVisible: false, //新建条件
        appointVisible: false, //条件提成点
        businessId: '', // 业务id
        roleTable: [],
        currentIndex: 0,
        conditionRoleList: [],
        rules: {},
      }
    },
    created() {
      this.businessId = this.$route.query.id
      this.getDetail()
      this.getRoles()
    },
    methods: {
      async getDetail() {
        this.roleTable = await SettleConfigInteractor.getConfigGetDetail(
          this.businessId
        )
      },
      // 新增一行
      pushNew() {
        let obj = {
          configCommissionId: this.businessId,
        }
        this.form.tableData.push(obj)
      },
      // 删除
      deleteItem(index) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.form.tableData.splice(index, 1)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      // 条件
      setCondition(scope) {
        scope.column.showPoint = '123'
        this.currentIndex = scope.$index
        this.modelVisible = true
      },
      // 条件提成点
      setAppoint(scope) {
        this.currentIndex = scope.$index
        this.appointVisible = true
      },
      // 清空提成点
      clearCommission(scope) {
        this.$set(this.form.tableData[scope.$index], 'configCommissionId', '')
        this.$set(this.form.tableData[scope.$index], 'ratio', '')
      },
      // 清空条件
      clearRoleCondition(scope) {
        this.$set(this.form.tableData[scope.$index], 'roleType', '')
        this.$set(this.form.tableData[scope.$index], 'conditionResult', '')
        this.$set(this.form.tableData[scope.$index], 'conditioning', '')
        delete this.form.tableData[scope.$index].conditionRoleList
      },
      // 判断列表内是否未空  true 不是空数组，false是空数组
      checkTableEmpty() {
        for (let index = 0; index < this.form.tableData.length; index++) {
          for (const key in this.form.tableData[index]) {
            if (this.form.tableData[index][key]) {
              return true
            }
          }
        }
      },
      // 返回
      goBack() {
        if (this.checkTableEmpty()) {
          this.$confirm('数据未保存，是否返回？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            this.$router.push({
              path: '/finance-center/config-manage',
              query: {
                tab: 2,
                configTab: 'person',
              },
            })
          })
        } else {
          this.$router.push({
            path: '/finance-center/config-manage',
            query: {
              tab: 2,
              configTab: 'person',
            },
          })
        }
      },
      fromBusinessChange(scope) {
        this.$refs['UserChoose'].showAddEdit(
          '1',
          deepCopy(this.businessArray),
          true,
          1
        )
        this.currentIndex = scope.$index
      },
      // 删除客户代表
      clearFromBusinessName(scope) {
        this.currentIndex = scope.$index
        this.clearUser()
      },
      clearUser() {
        this.$set(this.form.tableData[this.currentIndex], 'personnoelCn', '')
        this.$set(this.form.tableData[this.currentIndex], 'personnoelEn', '')
        this.$set(this.form.tableData[this.currentIndex], 'personnoelId', '')
      },
      async chooseUser(userIdArr) {
        let params = {
          configCommissionId: this.businessId,
          userId: userIdArr[0].id,
        }
        let res = await SettleConfigInteractor.getCheckIsExist(params)
        if (res) {
          this.$message.error('员工' + userIdArr[0].name + '已存在其他配置')
          this.clearUser()
        } else {
          this.$set(
            this.form.tableData[this.currentIndex],
            'personnoelCn',
            userIdArr[0].name
          )
          this.$set(
            this.form.tableData[this.currentIndex],
            'personnoelEn',
            userIdArr[0].englishName
          )
          this.$set(
            this.form.tableData[this.currentIndex],
            'personnoelId',
            userIdArr[0].id
          )
          this.$set(
            this.form.tableData[this.currentIndex],
            'personnoelAllName',
            `${userIdArr[0].name}(${userIdArr[0].englishName})`
          )
        }
      },
      // 条件模板
      submitCondition(chooseobj) {
        this.$set(
          this.form.tableData[this.currentIndex],
          'conditionDesc',
          chooseobj.conditionDesc
        )
        this.$set(
          this.form.tableData[this.currentIndex],
          'conditionResult',
          chooseobj.result
        )
        this.$set(
          this.form.tableData[this.currentIndex],
          'conditioning',
          chooseobj.conditionResult
        )
        this.conditionRoleList = JSON.parse(chooseobj.conditionResult)
        this.$set(
          this.form.tableData[this.currentIndex],
          'conditionRoleList',
          JSON.parse(chooseobj.conditionResult)
        )
        this.$set(this.form.tableData[this.currentIndex], 'roleType', '')

        if (chooseobj.type == 2) {
          this.$set(
            this.form.tableData[this.currentIndex],
            'conditionRoleList',
            this.roleList
          )
        }
      },
      // 条件提成点
      setRow(row) {
        this.$set(
          this.form.tableData[this.currentIndex],
          'configCommissionId',
          this.businessId
        )
        this.$set(
          this.form.tableData[this.currentIndex],
          'ratio',
          row.fixedRatio
        )

        this.$set(
          this.form.tableData[this.currentIndex],
          'configCommissionDesc',
          `${row.typeDesc},${row.fixedRatio}`
        )
      },
      // 保存
      async save() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            let arr = _.cloneDeep(this.form.tableData)
            arr = arr.map((item) => {
              delete item.configCommissionDesc
              delete item.personnoelAllName
              delete item.conditionDesc
              delete item.conditionRoleList
              return {
                ...item,
              }
            })
            let res =
              await SettleConfigInteractor.setCommissionPersonnelSavePOs(arr)
            if (res) {
              this.$message.success('新增成功')
              this.conditionRoleList = []
              this.$router.push({
                path: '/finance-center/config-manage',
                query: {
                  tab: 2,
                  configTab: 'person',
                },
              })
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .oper {
    padding: 20px 10px;
    height: calc(100vh - 45px);
    overflow-y: scroll;
    .item-title {
      text-align: right;
      padding: 0px !important;
      transform: translateX(10px);
    }
    .item-title::before {
      content: '';
      border-right: 7px solid blue;
      margin-right: 5px;
    }
    .m30 {
      margin-top: 30px;
    }
    .stick30 {
      position: sticky;
      bottom: 30px;
    }
    .m10 {
      margin-top: 10px;
    }
  }
</style>
