<template>
  <div class="proSetting-container">
    <el-row>
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="addClick"
        class="pub-h-36"
      >
        新增印刷方式
      </el-button>
    </el-row>
    <el-row :gutter="20" class="mt20 public-col">
      <el-col
        :span="7"
        v-for="(item, index) of tableData"
        :key="index"
        class="mb20"
        style="
          border: 1px solid #ededed;
          height: 300px;
          padding: 15px;
          margin: 0 10px;
          overflow-y: hidden;
        "
      >
        <el-row type="flex" class="align_center">
          <el-col :span="10">
            <h6 class="f_s_16">{{ item.nameCn }}</h6>
          </el-col>
          <el-col :span="14" style="text-align: right">
            <el-tooltip content="修改" placement="top">
              <el-button
                type="primary"
                plain
                icon="el-icon-edit"
                circle
                size="mini"
                @click="editClick(item.productPrintingPricingId)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <el-button
                type="danger"
                plain
                icon="el-icon-delete"
                circle
                size="mini"
                @click="delClick(item.productPrintingPricingId)"
              ></el-button>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-table
          :data="item.optionsVOList"
          border
          class="mt10"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column
            align="center"
            prop="pricingValue"
            label="计价方式"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="price"
            label="单价"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="toolingCharge"
            label="版费价格"
            align="center"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <ProPrintWayModel ref="proPrintWayModel" @fetch-data="fetchData" />
  </div>
</template>

<script>
import { listAll, deleteById } from '@/api/product/productPrintWay'
import ProPrintWayModel from './components/ProPrintWayModel'
export default {
  name: 'ProductPrint',
  props: {},
  data() {
    return {
      activeName: 'first', //选项卡第一个
      tableData: [],
      tableHeight: '100% - 45',
    }
  },
  components: { ProPrintWayModel },
  created() {
    this.fetchData()
  },
  methods: {
    // 列表
    async fetchData() {
      let response = await listAll()
      if ((response.code = '000000')) {
        this.tableData = response.data
      }
    },
    // 新增
    addClick() {
      this.$refs['proPrintWayModel'].proPrintWayModel()
    },
    // 修改
    editClick(id) {
      this.$refs['proPrintWayModel'].proPrintWayModel(id)
    },
    // 删除
    delClick(productPrintingPricingId) {
      if (productPrintingPricingId) {
        this.$baseConfirm('确定要删除吗', null, async () => {
          // 删除接口
          let test = productPrintingPricingId.toString()
          let response = await deleteById({
            productPrintingPricingId: productPrintingPricingId,
          })
          if ((response.code = '000000')) {
            this.$baseMessage(
              '刪除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
          this.fetchData()
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.public-col {
  height: 43vh;
  .el-table {
    height: calc(100% - 45px);
    overflow-y: auto;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
  }
  p {
    line-height: 32px;
  }
}
</style>
