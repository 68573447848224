<!-- 写邮件选择效果图弹窗 -->
<template>
  <el-dialog :title="$t('myMail.SelectRenderings')" width="430px" :visible.sync="chooseRendingsVisible"
    :close-on-click-modal="false" :close-on-press-escape="false" @close="closeDialog">
    <el-form label-position="right" label-width="100px">
      <!-- 销售订单号 -->
      <el-form-item :label="$t('myMail.PI')+ ':'">
        <div style="width: 270px;">
          <FuzzyMatching ref="fuzzyMatching" @fuzzy-choose="fuzzyChoose" code-key="orderCode"
            placeholder-key="writemailrendings" req-type="MAIL" :page-le="15" :clearable="clearable" />
        </div>

      </el-form-item>
      <!-- 效果图 -->
      <!-- 全选 -->
      <el-form-item :label="$t('myMail.Renderings') + ':'">
        <div class="img-wrapper" v-if="imgList && imgList.length > 0">
          <div><el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
              @change="handleCheckAllChange">{{$t('myMail.selectAll')}}</el-checkbox></div>
          <el-divider></el-divider>
          <el-checkbox-group v-model="checkedImgsIndex" @change="handleCheckedImgsChange">
            <div v-for="(item,index) in imgList" :key="index">
              <div><el-checkbox class="single-check" :label="index"
                  v-if="index === activeIndex">{{$t(status2i18n[imgList[index].rendingsStatus])}}</el-checkbox></div>
              <div class="wrapper-inner" v-if="activeIndex === index">
                <i class="el-icon-arrow-left" @click="clickImg(index,'prev')" v-if="imgList.length > 1"></i>
                <div class="img-content">
                  <el-image :src="item.url" :preview-src-list="getPreviewImages(index)" fit="container"
                    style="height: 100%;margin:0 auto;">
                  </el-image>
                </div>
                <i class="el-icon-arrow-right" @click="clickImg(index,'next')" v-if="imgList.length > 1"></i>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <div v-else>--</div>
      </el-form-item>
      <!-- 效果图状态 -->
      <el-form-item :label="$t('myMail.RenderingStatus')+ ':'">
        {{checkedImgsStatus}}
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">
        {{ $t('myMail.Cancel') }}
      </el-button>

      <ErpLoadingButton type="primary" @click="saveImgs">
        {{ $t('myMail.Confirm') }}
      </ErpLoadingButton>
    </span>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getImageBytesWithUrl } from 'kits'
  import FuzzyMatching from '@/components/FuzzyMatching'
  import { URL_COMPRESS } from '@/config'
  import { batchUploadImgsWithSrc } from '@/views/order/shipmentManage/helper/send-mail.js'
  import axios from 'axios'



  const maxFileTotalSize = 25
  const imgUrlSizeCache = {} //变量缓存图片大小


  export default {
    name: 'ChooseRendings',
    props: {
      uploadedSizes: {
        type: Number,
        default: 0
      }
    },

    components: {
      FuzzyMatching
    },

    data() {
      return {
        chooseRendingsVisible: false,
        activeIndex: 0,
        imgList: [],
        previewImgs: [],
        isIndeterminate: false,
        checkAll: false,
        checkedImgsIndex: [],
        emptyText: '',
        clearable: false,
        status2i18n: {
          1: 'orderList.HasBeenConfirmed',
          3: 'orderList.CustomerConfirmation',
          4: 'orderList.BeingRevised',
          5: 'orderList.CustomerHasUploaded',
        }
      };
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      // 选中图片地址
      checkedImgsSrc() {
        let imgs = []
        // console.log(this.checkedImgsIndex)
        this.checkedImgsIndex.forEach((item) => {
          imgs.push(this.previewImgs[item])
        })
        return imgs
      },
      checkedImgs() {
        let imgs = []
        this.checkedImgsIndex.forEach((item) => {
          imgs.push(this.imgList[item])
        })
        return imgs
      },

      // 图片状态
      checkedImgsStatus() {
        let status = []
        this.checkedImgsIndex.forEach((item) => {
          if (this.imgList[item] && this.imgList[item].rendingsStatus) {
            if (!status.includes(this.imgList[item].rendingsStatus)) {
              status.push(this.imgList[item].rendingsStatus)
            }
          }
        })
        status = status.sort((a, b) => a - b)
        let status2i18n = this.status2i18n
        let status2Text = status.map(s => this.$t(status2i18n[s]))
        return status2Text.join(',')
      },
    },


    methods: {
      getPreviewImages(index) {
        var imgList = [...this.previewImgs]
        if (index === 0) return imgList
        var start = imgList.splice(index)
        var remain = imgList.splice(0, index)
        return start.concat(remain)

      },
      getFileSizeInByte(file) {
        // 文件大小默认以字节为单位
        return file.size;

      },

      // 根据图片url获取图片大小
      async fetchAndGetSize(url) {
        if (imgUrlSizeCache[url]) {
          return imgUrlSizeCache[url]
        }
        const sizeInByte = await getImageBytesWithUrl(url);
        imgUrlSizeCache[url] = sizeInByte
        return sizeInByte
      },

      // 保存图片
      saveImgs(e, done) {
        if (!this.hasOrder) {
          done()
          return this.$message.warning(this.$t('myMail.InputOrderCode'))
        }
        if (this.checkedImgsIndex.length === 0) {
          done()
          return this.$message.warning(this.$t('myMail.PleaseSelectRenderings'))
        }
        let PromiseAll = []
        this.checkedImgsSrc.forEach((item) => {
          PromiseAll.push(this.fetchAndGetSize(item))
        })
        Promise.all(PromiseAll).then((imgSizes) => {
          if (!this.checkSizes(imgSizes)) {
            return done()
          } else {
            batchUploadImgsWithSrc(this.checkedImgs, done).then(res => {
              this.$emit('upload-rendings', res)
              this.closeDialog()
              done()
            }).catch(() => {
              done()
            })
          }
        })
      },

      // 校验选择的效果图的大小
      checkSizes(imgSizes) {
        let totalimgSize = imgSizes.reduce((prev, next) => {
          prev += next
          return prev
        }, 0)
        if (this.uploadedSizes + totalimgSize > maxFileTotalSize * 1024 * 1024) {
          const msg =
            this.lang === 'en'
              ? 'The total size of attachments cannot exceed 25MB'
              : '附件总大小不能超过25MB'
          this.$message.warning(msg)
          return false
        }
        return true
      },


      // 全选切换
      handleCheckAllChange(val) {
        this.checkedImgsIndex = val ? this.imgList.map((item, index) => index) : [];
        this.isIndeterminate = false;
      },

      // 选中变化
      handleCheckedImgsChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.imgList.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.imgList.length;
      },
      async fuzzyChoose(orderList) {
        if (orderList?.length > 0) {
          this.reset()
          this.imgList = orderList[0].imgs
          this.previewImgs = orderList[0].previewImgs
        }
        this.hasOrder = !!orderList[0]?.imgs

      },

      // 图片切换
      clickImg(index, type) {
        let activeIndex = this.activeIndex
        if (type === 'prev' && activeIndex === 0) {
          this.activeIndex = this.imgList.length - 1
          return
        }
        if (type === 'next' && activeIndex === this.imgList.length - 1) {
          this.activeIndex = 0
          return
        }
        switch (type) {
          case 'prev':
            this.activeIndex = activeIndex - 1
            break
          case 'next':
            this.activeIndex = activeIndex + 1
        }
      },

      // 打开弹窗
      showAddEdit() {
        this.chooseRendingsVisible = true
      },
      reset() {
        this.isIndeterminate = false
        this.checkAll = false
        this.activeIndex = 0
        this.checkedImgsIndex = []
      },
      // 关闭弹窗
      closeDialog() {
        this.chooseRendingsVisible = false
        this.imgList = []
        this.previewImgs = []
        this.reset()
        this.$refs.fuzzyMatching.initSupplier({})
      },


    },

  };
</script>

<style lang="scss" scoped>
  .img-wrapper {
    position: relative;
    border: 1px solid #eee;
    padding: 10px;
    display: inline-block;

    .wrapper-inner {
      display: flex;
      align-items: center;

      i {
        margin: 0 10px;
        cursor: pointer;

        &.el-icon-arrow-left {
          margin-left: 0px;
        }

        &.el-icon-arrow-right {
          margin-right: 0px;
        }
      }

      .img-content {
        height: 140px;
        width: 200px;
        display: flex;
      }
    }
  }

  .single-check {
    /* position: absolute;
    top: 49px;
    left: 10px; */
  }

  .el-divider--horizontal {
    margin: 5px 0 10px 0;
  }
</style>
