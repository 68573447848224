<!--
  功能：运营成本配置-模板(新增或编辑)
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月22日 14:13:26
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="tpl-new-wrapper">
    <h3>
      <span class="vertical"></span>
      地区模板配置
    </h3>
    <el-form :rules="rules" :model="form" ref="form">
      <el-table :data="form.tableData" border stripe class="form-rule-table">
        <el-table-column label="地区" prop="area" align="center">
        <template slot="header">
            <i class="red">*</i>
            地区
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.area'"
              :rules="[
                {
                  required: true,
                  message: '请选择区域',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="scope.row.area" clearable>
                <el-option
                  v-for="(a,index) in areas"
                  :label="a.areaName"
                  :value="a.areaName"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="产品类型"
          prop="standardCustomized"
          align="center"
        >
        <template slot="header">
            <i class="red">*</i>
            产品类型
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.standardCustomized'"
              :rules="[
                {
                  required: true,
                  message: '请选择产品类型',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="scope.row.standardCustomized" clearable>
                <el-option
                  v-for="item in productTypes"
                  :label="item.name"
                  :value="item.name"
                  :key="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="毛利额" prop="conditionType" align="center">
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.conditionType'"
              :rules="[
                {
                  required: true,
                  message: '请选择毛利额',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="scope.row.conditionType" disabled>
                <el-option
                  v-for="item in conditionTypes"
                  :label="item.name"
                  :value="item.code"
                  :key="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="运营成本(%)" prop="ratio" align="center">
         <template slot="header">
            <i class="red">*</i>
            运营成本(%)
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.ratio'"
              :rules="[
                {
                  required: true,
                  message: '请输入运营成本',
                  trigger: ['change','blur'],
                },
              ]"
            >
              <el-input-number
                v-model="scope.row.ratio"
                :controls="false"
                :min="0"
                :max="100"
                clearable
                :precision="2"
              ></el-input-number>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="del(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-button class="new-btn" @click="addLine" v-if="!$route.query.businessId">
      新增一行
    </el-button>
  </div>
</template>

<script>
  import {
    productTypes,
  } from '@/views/finance-center/config-manage/helper'
  import { OperatingCostConfigInteractor } from '@/core/interactors/finance/config-manage'
  import { newMixin } from '../mixins'
  import { FinanceThemeSetting  } from '@/core/interactors/finance/config-manage'

  const baseTpl = {
    area: '', //区域
    standardCustomized: '',
    conditionType: 1,
    conditionValue: 0,
    deleted: 0,
    ratio: undefined,
  }
  export default {
    name: 'OperatingCostConfigTplNew',
    mixins: [newMixin],

    props: {},
    components: {},

    // 组件状态值
    data() {
      return {
        areas:[], //区域下拉
        productTypes: Object.freeze(productTypes), //产品类型下拉
        conditionTypes: Object.freeze([
          {
            name: '大于0',
            code: 1,
          },
        ]),
        form: {
          tableData: [], //列表数据
        },
        rules: {}, //校验规则
        baseTpl, //新增一行,
        checkedKeys: ['area', 'standardCustomized', 'ratio'], // 校验项
      }
    },

    methods: {
      // 初始化数据
      async init() {
        const { businessId } = this.$route.query
        if (!businessId) {
          this.form.tableData = [{ ...baseTpl }]
        } else {
          const res = await OperatingCostConfigInteractor.tplGetDetail({
            businessId,
          })
          if (res && res.code === '000000') {
            this.form.tableData = [res.data]
          }
        }
        this._saveInitTableData(this.form.tableData)
        this._getArea()
      },

      // 保存参数
      _getReqData(tenantId) {
        this.delItems = this.delItems || []
        return [...this.form.tableData, ...this.delItems].map((item) => {
          item.tenantId = tenantId
          return item
        })
      },
      // 保存
      save(tenantId) {
        this.$refs.form.validate(async (flag) => {
          if (flag) {
            const res = await OperatingCostConfigInteractor.tplSaveList(
              this._getReqData(tenantId)
            )
            if (res.code === '000000') {
              this.$message.success('操作成功')
              this.saved = true
              this.$emit('back')
            }
          } else {
            this.$message.warning(
              '地区、产品类型、运营成本不支持为空，请录入数据'
            )
          }
        })
      },

      async _getArea() {
        this.areas = await FinanceThemeSetting.allDeptAreaByTenant()
      },
    },

    created() {
      this.init()
    },
  }
</script>

<style scoped lang="scss">
  .vertical {
    display: inline-block;
    height: 20px;
    width: 5px;
    background-color: #409eff;
    vertical-align: middle;
  }
  .new-btn {
    width: 100%;
    border-top: none;
    color: #409eff;
  }

  .el-form-item__error {
    display: none !important;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 0px !important;
  }
</style>
