/**
 * 询盘管理列表
 */
import request from '@/core/services/request'

// 询盘新增获取出口抬头和询盘来源
export function orderGetDict(data) {
  return request({
    url: '/order/orderAmount/listPage',
    method: 'post',
    data,
  })
}

// 分页列表查询全部客户
export function selectAllCustomer(data) {
  return request({
    url: '/merchants/info/selectAllCustomer',
    method: 'post',
    data,
  })
}

// 新增询盘单
export function orderEnquiryAdd(data) {
  return request({
    url: '/order/orderEnquiry/insertPO',
    method: 'post',
    data,
  })
}

// 更新询盘单
export function orderEnquiryUpdate(data) {
  return request({
    url: '/order/orderEnquiry/updatePO',
    method: 'post',
    data,
  })
}

// 编辑根据id查找
export function orderEnquiryDetail(data) {
  return request({
    url: '/order/orderEnquiry/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 克隆询盘单
export function enquiryClone(data) {
  return request({
    url: '/order/orderEnquiry/enquiryClone',
    method: 'post',
    data,
  })
}

// 询盘保存
export function saveUpdatePO(data) {
  return request({
    url: '/order/orderEnquiry/saveUpdatePO',
    method: 'post',
    data,
  })
}

// 根据联系人邮箱获取详情
export function getDetailForEmail(data = {}) {
  return request({
    url: '/system/mailBusiness/getDetailForEmail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
