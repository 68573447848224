import EventBus from '@/utils/eventBus'
const globalBackButtonMixin = {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!to.meta.showBackButton) {
        vm.$set(to.meta, 'showBackButton', true)
        EventBus.$emit('refresh-back-btn')
      }
    })
  },
}

export default globalBackButtonMixin
