<template>
  <div v-if="showData">
    <el-row class="mt10" v-if="productInfo.printingWaySelect !== 'Blank'">
      <el-col :span="6" v-if="!isNumberStep && choosePrice - minPrintPrice > 0">
        <!-- 印刷加价 -->
        <p>
          {{ $t('productDetail.PrintingMarkup') }}
        </p>
        Additonal Imprint：$ {{ setNumber(choosePrice, minPrintPrice) }} ea
      </el-col>
      <el-col :span="6" v-if="isNumberStep && finalNumberStepData.length > 0">
        <p>
          {{ $t('productDetail.PrintingMarkup') }}
        </p>
        <el-table border :data="finalNumberStepData">
          <!-- 数量 -->
          <el-table-column :label="$t('productDetail.Quantity')" align="center" prop="num"></el-table-column>
          <el-table-column label="Additonal Imprint ($)" align="center" prop="price">
            <template slot-scope="scope">
              {{ setNumber(scope.row.price, scope.row.number_price_min) }}ea
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 规格加价 -->
    <!-- 2.2.0需求：隐藏规格加价 -->
    <!-- <p class="mt15" v-if="addSpecList && addSpecList.length > 0">
      <span v-if="!showAddPriceTitle">
        {{ $t('productDetail.SpecificationMarkup') }}
      </span>
    </p>
    <p class="mt5" v-for="it in addSpecList" :key="it.productSpecsAddPriceId">
      <span v-if="it.specsPrice != 0">
        {{ it.specsValue }} Additional： $ {{ it.specsPrice }} ea
      </span>
    </p> -->
  </div>
</template>
<script>
  export default {
    data() {
      return {
        addSpecList: [],
        printList: [],
        choosePrice: 0,
        minPrintPrice: 0,
        isNumberStep: false, // 是否按照数量阶梯
        numberStepData: [], // 数量阶梯的加价
        showData: false,
      }
    },
    props: {
      productInfo: {
        type: Object,
        default: () => { },
      },
    },
    computed: {
      showAddPriceTitle() {
        let state = this.addSpecList.every((it) => {
          return it.specsPrice == 0
        })
        return state
      },
      finalNumberStepData() {
        let arr = this.numberStepData.filter((it) => {
          return it.price != it.number_price_min
        })
        return arr
      },
    },
    methods: {
      setNumber(val1, val2) {
        return parseFloat(Number((val1 - val2).toFixed(3)))
      },

      detailInfo(detail) {
        // 印刷加价
        this.setPrintList(detail)
        // 规格加价
        this.setSpecList(detail)
        // 规格加价展示
        this.setShowData(detail)
      },
      setShowData(detail) {
        // 定制品不展示
        if (detail.standardCustomized === '订制品') {
          this.showData = false
        } else if (
          !detail.printingWaySelect &&
          !detail.printingWaySelectEn &&
          !detail.printingWaySelectId
        ) {
          // 没有平台价格时不展示
          this.showData = false
        } else {
          this.showData = true
        }
      },
      setSpecList(detail) {
        this.addSpecList = detail.productSpecsAddPriceVOList?.filter((it) => {
          return it.basePricesSetting == 0
        })
      },
      setPrintList(detail) {
        // 找到对应的印刷方式
        if (detail.printingWaySelectId) {
          let choosePrintWay = detail.productPrintingWayVOList?.find((it) => {
            return it.printingWaySelectId == detail.printingWaySelectId
          })

          this.isNumberStep =
            choosePrintWay?.pricingConfiguration == 0 ? false : true

          let printPriceList = choosePrintWay?.printingWay
            ? JSON.parse(choosePrintWay.printingWay)
            : []
          // 找到对应的印刷位
          let positionWay = printPriceList?.find((it) => {
            return it.productPrintingPricingOptionsId == detail.waySelectId
          })

          if (this.isNumberStep && positionWay) {
            this.numberStepData = JSON.parse(positionWay.stepChildList)
          }

          this.numberStepData.forEach((it, i) => {
            let price = []
            printPriceList.forEach((ot, oi) => {
              price.push(ot[`number_price_${i}`])
            })
            this.$set(it, 'number_price_min', Math.min(...price))
          })

          this.choosePrice = Number(positionWay ? positionWay.price : 0)

          let priceList = printPriceList.map((it) => Number(it.price))
          // 取最小价格
          this.minPrintPrice = Math.min(...priceList)
        }
      },
    },
  }
</script>