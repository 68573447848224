import { render, staticRenderFns } from "./spplier-priority-setting.vue?vue&type=template&id=1eab4623&scoped=true&"
import script from "./spplier-priority-setting.vue?vue&type=script&lang=js&"
export * from "./spplier-priority-setting.vue?vue&type=script&lang=js&"
import style0 from "./spplier-priority-setting.vue?vue&type=style&index=0&id=1eab4623&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eab4623",
  null
  
)

export default component.exports