<!-- 客户折扣说明 -->
<template>
  <el-dialog
    :title="$t('AddOrder.Discount')"
    width="600px"
    :visible.sync="modelVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      :label-position="lang === 'en' ? 'top' : 'left'"
    >
      <!-- 折扣说明 -->
      <el-form-item
        :label="$t('AddOrder.DiscountDescription')"
        prop="discountReason"
        :rules="{
          required: true,
          trigger: 'change',
          message: $t('placeholder.plsInput'),
        }"
      >
        <el-input
          type="textarea"
          v-model="form.discountReason"
          maxlength="500"
          show-word-limit
          :rows="3"
          :placeholder="$t('placeholder.plsInput')"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="text-center">
      <!-- 确认 -->
      <el-button type="primary" @click="save">
        {{ $t('AddOrder.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="close()">
        {{ $t('AddOrder.Cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      index: 0,
      form: {
        discountReason: '',
      },
      modelVisible: false,
      rules: {},
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  methods: {
    // 初始化
    init(text, i) {
      this.index = i
      this.form.discountReason = text
      this.modelVisible = true
    },

    // 保存
    save() {
      let self = this
      self.$refs.ruleForm.validate((valid) => {
        if (valid) {
          self.$set(
            self.$parent.form.orderProductDTOS[self.index],
            'discountReason',
            self.form.discountReason
          )
          self.close()
        }
      })
    },

    // 关闭弹窗重置表单
    close() {
      this.$refs.ruleForm.resetFields()
      this.modelVisible = false
    },
  },
}
</script>
