<template>
  <div>
    <el-dialog
      title="选择模板"
      :visible="appointVisible"
      width="800px"
      center
      @close="cancelModel"
    >
      <div>
        <i class="el-icon-question"></i>
        模板没有数据，需要先去【结算模板配置】中新增数据
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 10px"
        border
        empty-text="模板没数据，需要先去【结算模板配置】中新增数据！"
      >
        <el-table-column width="60" align="center">
          <template slot-scope="scope">
            <el-radio
              style="transform: translateX(10px)"
              v-model="chooseRow"
              :value="scope.row.configCommissionTemplateId"
              :label="scope.row.configCommissionTemplateId"
            >
              &nbsp
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in modelColumns"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          min-width="100"
          :width="item.width"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'fixedRatio'">
              <el-link
                v-if="scope.row.mode == 1"
                type="primary"
                @click="preView(scope.row)"
              >
                预览
              </el-link>
              <span v-else>{{ scope.row.fixedRatio }}</span>
            </div>
            <div v-else>
              <span>{{ scope.row[item.prop] || '--' }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelModel">取 消</el-button>
        <el-button type="primary" @click="confirmModel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 提成点预览 -->
    <ratioModel
      :ratioVisible.sync="ratioVisible"
      :ratioId="ratioId"
    ></ratioModel>
  </div>
</template>
<script>
import { SettleConfigInteractor } from '@/core/interactors/finance/config-manage'

import { TplConfigInteractor } from '@/core/interactors/finance/config-manage'
import ratioModel from './ratioModel'
export default {
  components: {
    ratioModel,
  },
  props: {
    // 弹窗显示
    appointVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelColumns: [
        {
          label: '模板编号',
          prop: 'templateNumber',
          width: '140',
        },
        {
          label: '模板名称',
          prop: 'name',
        },
        {
          label: '计算类型',
          prop: 'typeDesc',
        },
        {
          label: '提成点',
          prop: 'fixedRatio',
        },
        {
          label: '统计方式',
          prop: 'modeDesc',
        },
        {
          label: '月份区间',
          prop: 'monthDesc',
        },
      ],
      tableData: [],
      chooseRow: '',
      ratioVisible: false,
      ratioId: '',
      chooseObj: {},
    }
  },
  watch: {
    appointVisible(newVal, oldVal) {
      if (newVal) {
        this.getList()
      }
    },
  },
  mounted() {
    // this.getList()
  },
  methods: {
    cancelModel() {
      this.$emit('update:appointVisible', false)
    },
    async confirmModel() {
      if (!this.chooseRow) {
        this.$message.warning('请选择一条数据！')
        return
      }
      this.chooseObj = this.tableData.find((item) => {
        return item.configCommissionTemplateId == this.chooseRow
      })
      if (this.chooseObj.type == 2) {
        let res = await TplConfigInteractor.templateGetDetail(
          this.chooseObj.configCommissionTemplateId
        )
        if (res.code === '000000') {
          this.chooseObj.tieredDTOS = res.data.tiereds
        }
      }
      this.$emit('setRow', this.chooseObj)
      this.$emit('update:appointVisible', false)
      this.chooseRow = ''
    },
    async getList() {
      this.tableData =
        await SettleConfigInteractor.getConfigConditionListDatas()
    },
    preView(row) {
      this.ratioId = row.configCommissionTemplateId
      this.ratioVisible = true
    },
    async getStepDetail() {
      let res = await TplConfigInteractor.templateGetDetail(
        this.chooseObj.configCommissionTemplateId
      )
      if (res.code === '000000') {
        this.chooseObj.tieredDTOS = res.data.tiereds
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
