<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    width="1400px"
  >
    <template slot="title">
      <span class="header-main-title">编辑值班</span>
      <span class="header-sub-title">以下均为北京时间</span>
    </template>
    <el-form :model="formData" ref="formRef" :rules="rules">
      <el-form-item label-width="100px">
        <template slot="label">
          <i style="color: #ff4d4f">*</i>
          值班时段
        </template>
        <div
          v-for="(item, index) in formData.timeList"
          :key="item.timeData[0]"
          class="time-item"
        >
          <el-time-picker
            is-range
            :value="item.timeData"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="HH:mm"
            format="HH:mm"
            readonly
          />
          <i class="el-icon-delete" @click="handleDeleteTimeData(index)" />
        </div>
        <el-time-picker
          is-range
          v-model="selectTime"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="HH:mm"
        />
        <el-button @click="handleAdd" class="ml20">新增时段</el-button>
      </el-form-item>
      <el-form-item label-width="100px">
        <template slot="label">
          <i style="color: #ff4d4f">*</i>
          值班人员
        </template>
        <el-button @click="handleBatchSet">批量设置</el-button>
        <el-button @click="handleClearAll" class="ml20">清空人员</el-button>
      </el-form-item>
      <el-table :data="formData.timeList" border>
        <el-table-column type="index" label="序号" width="50" align="center" />
        <el-table-column label="时段" align="center">
          <template slot-scope="scope">
            {{ scope.row.timeData[0] }}-{{ scope.row.timeData[1] }}
            <div style="color: #ff4d4f">
              <i class="el-icon-warning" />
              {{
                subtractOneHour(scope.row.timeData, scope.row.assignTimeConfig)
              }}后此时段不再接收分配邮件
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-for="item in weekDayList"
          :key="item.value"
        >
          <template #header>
            <div class="weekDayHeader">
              <i>*</i>
              {{ item.label }}
              <i
                class="el-icon-error"
                @click="handleClearWeekDay(item.value)"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="'timeList.' + scope.$index + '.userNameList' + item.value"
              :rules="rules.user"
            >
              <el-select
                v-model="scope.row['userNameList' + item.value]"
                multiple
                collapse-tags
                clearable
                placeholder="请选择"
                class="select-user"
                popper-class="select-user-popper"
                @focus="handleUserFocus(scope.$index, item.value)"
                @clear="handleUserClear(scope.$index, item.value)"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="截止分配时间（h）" align="center">
          <template slot-scope="scope">
            <el-form-item
              :prop="'timeList.' + scope.$index + '.assignTimeConfig'"
              :rules="rules.assignTimeConfig"
            >
              <el-input
                v-model="scope.row.assignTimeConfig"
                v-input.float
                clearable
              />
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <ErpLoadingButton type="primary" @click="handleSubmit">
        提 交
      </ErpLoadingButton>
      <el-button @click="handleClose">取 消</el-button>
    </div>
    <!-- 人员组织架构树 -->
    <UserChoose ref="userChooseRef" @choose-user="chooseUser" />
    <!-- 批量设置值班 -->
    <BatchSetDialog
      v-model="batchSetVisible"
      :timeOptions="timeOptions"
      @setUsers="setUsers"
    />
  </el-dialog>
</template>
<script>
  import UserChoose from '@/components/userChoose2'
  import BatchSetDialog from './BatchSetDialog'
  import { getParams, subtractOneHour } from '../../utils'
  import { deepCopy, dateTimeFormat } from 'kits'
  import { MailSettingInteractor } from '@/core'
  const checkNumber = (rule, value, callback) => {
    if (value && value <= 0) return callback(new Error('必须大于0'))
    if (value && value > 8) return callback(new Error('最大值为8'))
    callback()
  }
  export default {
    name: 'EditDialog',
    components: { UserChoose, BatchSetDialog },
    props: {
      value: { type: Boolean, default: false },
      tableData: { type: Array, default: () => [] },
    },
    data() {
      return {
        selectTime: [
          new Date(2016, 9, 10, 0, 0),
          new Date(2016, 9, 10, 23, 59),
        ],
        formData: { timeList: [] },
        rules: {
          user: [
            { required: true, message: '值班人员必选', trigger: 'change' },
          ],
          assignTimeConfig: [{ validator: checkNumber, trigger: 'change' }],
        },
        weekDayList: [
          { label: '星期一', value: 1 },
          { label: '星期二', value: 2 },
          { label: '星期三', value: 3 },
          { label: '星期四', value: 4 },
          { label: '星期五', value: 5 },
          { label: '星期六', value: 6 },
          { label: '星期日', value: 7 },
        ],
        tableIndex: -1, // 表格索引
        weekDay: 0, // 星期几 1-7对应星期一到星期日
        batchSetVisible: false, // 批量设置弹窗
        timeOptions: [],
      }
    },
    computed: {
      dialogVisible: {
        get() {
          if (this.value && this.tableData.length) {
            this.formData.timeList = deepCopy(this.tableData)
          }
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },
    },
    methods: {
      subtractOneHour,
      handleAdd() {
        let [startTime, endTime] = this.selectTime
        startTime = dateTimeFormat('hh:mm', startTime)
        endTime = dateTimeFormat('hh:mm', endTime)
        const { timeList } = this.formData
        if (timeList.length >= 5) {
          return this.$message.warning('最多添加5个时段')
        }
        if (!startTime || !endTime) {
          return this.$message.warning('请选择时间段')
        }
        const startTimeDate = new Date(`2023-01-01T${startTime}:00`)
        const endTimeDate = new Date(`2023-01-01T${endTime}:00`)
        if (startTimeDate >= endTimeDate) {
          return this.$message.warning('结束时间必须大于开始时间')
        }
        const lastTimeData = timeList[timeList.length - 1]?.timeData
        if (!lastTimeData) {
          return this.add(startTime, endTime)
        }
        const currentStartTimeDate = new Date(`2023-01-01T${startTime}:00`)
        const lastStartTimeDate = new Date(`2023-01-01T${lastTimeData[0]}:00`)
        if (currentStartTimeDate <= lastStartTimeDate) {
          return this.$message.warning('当前开始时间需大于上一时段的开始时间')
        }
        this.add(startTime, endTime)
      },
      add(startTime, endTime) {
        this.formData.timeList.push({
          timeData: [startTime, endTime],
          userNameList1: [],
          userNameList2: [],
          userNameList3: [],
          userNameList4: [],
          userNameList5: [],
          userNameList6: [],
          userNameList7: [],
          assignTimeConfig: '',
        })
        this.selectTime = [
          new Date(2016, 9, 10, 0, 0),
          new Date(2016, 9, 10, 23, 59),
        ]
      },
      // 批量设置
      handleBatchSet() {
        const timeOptions = this.formData.timeList.map((item) => item.timeData)
        this.timeOptions = timeOptions
        this.batchSetVisible = true
      },
      // 点击打开选择人员弹窗
      handleUserFocus(index, weekDay) {
        this.tableIndex = index
        this.weekDay = weekDay
        const idList = this.formData.timeList[index][`userIdList${weekDay}`]
        this.$refs.userChooseRef.showAddEdit('', idList)
      },
      // 选择人员
      chooseUser(userList) {
        const { tableIndex, weekDay } = this
        const nameList = userList.map((item) => item.name)
        const idList = userList.map((item) => item.id)
        this.formData.timeList[tableIndex][`userNameList${weekDay}`] = nameList
        this.formData.timeList[tableIndex][`userIdList${weekDay}`] = idList
      },
      // 删除当前选框内人员
      handleUserClear(index, weekDay) {
        this.formData.timeList[index][`userNameList${weekDay}`] = []
        this.formData.timeList[index][`userIdList${weekDay}`] = []
      },
      // 删除时段
      handleDeleteTimeData(index) {
        this.$confirm(`是否确认删除?`, '提示', { type: 'warning' })
          .then(() => {
            this.formData.timeList.splice(index, 1)
          })
          .catch(() => {})
      },
      // 清空当前星期的人员
      handleClearWeekDay(weekDay) {
        this.formData.timeList.forEach((item) => {
          item[`userNameList${weekDay}`] = []
          item[`userIdList${weekDay}`] = []
        })
      },
      // 清空所有人员
      handleClearAll() {
        this.$confirm(`是否确认清空所有人员?`, '提示', { type: 'warning' })
          .then(() => {
            const weekDays = this.weekDayList.map((item) => item.value)
            this.formData.timeList.forEach((item) => {
              weekDays.forEach((day) => {
                item[`userNameList${day}`] = ''
                item[`userIdList${day}`] = ''
              })
            })
          })
          .catch(() => {})
      },
      // 批量设置值班人员
      setUsers(data) {
        const { timeData, weekDayList, userNameList, userIdList } = data
        this.formData.timeList.forEach((item) => {
          if (item.timeData.join() === timeData.join()) {
            weekDayList.forEach((day) => {
              item[`userNameList${day}`] = userNameList
              item[`userIdList${day}`] = userIdList
            })
          }
        })
      },
      handleSubmit(e, done) {
        const { timeList } = this.formData
        if (timeList.length) {
          this.$refs.formRef.validate(async (valid) => {
            if (!valid) return done()
            const params = getParams(timeList)
            const res = await MailSettingInteractor.mailUserScheduleSaveApi(
              params
            )
            done()
            if (res?.code === '000000') {
              this.$message.success('编辑成功')
              this.$emit('refresh')
              this.handleClose()
            }
          })
        } else {
          this.$message.warning('请至少添加一个时段')
          done()
        }
      },
      handleClose() {
        this.$refs.formRef.resetFields()
        this.formData.timeList = []
        this.selectTime = [
          new Date(2016, 9, 10, 0, 0),
          new Date(2016, 9, 10, 23, 59),
        ]
        this.dialogVisible = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .header-main-title {
    color: #555;
    font-size: 18px;
  }
  .header-sub-title {
    color: #666;
    font-size: 14px;
    margin-left: 20px;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  ::v-deep {
    .is-active {
      background-color: #fff !important;
    }
    .select-user {
      .el-tag {
        & + .el-tag {
          margin-left: 2px;
        }
        .el-select__tags-text {
          max-width: 28px;
        }
      }
      .el-tag__close {
        display: none;
      }
    }
  }
  .time-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    > i {
      margin-left: 20px;
      cursor: pointer;
      color: #ff4d4f;
    }
  }
  .weekDayHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    > i {
      color: #ff4d4f;
      &:last-of-type {
        cursor: pointer;
        font-size: 18px;
        margin-left: 10px;
      }
    }
  }
</style>

<style>
  /* 隐藏下拉框的popper */
  .select-user-popper {
    display: none;
  }
</style>
