<template>
  <el-dialog
    :title="$t(title)"
    width="600px"
    class="address"
    :visible.sync="addressAddVisible"
    :close-on-click-modal="false"
    v-if="addressAddVisible"
    :before-close="close"
    height="90%"
    :append-to-body="true"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      :label-position="lang === 'en' ? 'top' : 'left'"
    >
      <!-- label="收货公司" -->
      <el-form-item
        prop="companyName"
        :label="$t('cusDetail.ReceivingCompany')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input
          v-model="form.companyName"
          maxlength="50"
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="收货人名称" -->
      <el-form-item
        prop="consigneeName"
        :label="$t('cusDetail.Receiver')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input
          v-model="form.consigneeName"
          maxlength="50"
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="收货人电话" -->
      <el-form-item
        prop="consigneePhone"
        :label="$t('cusDetail.ContactNumber')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input
          v-model="form.consigneePhone"
          maxlength="50"
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="国家" -->
      <el-form-item
        prop="nation"
        :label="$t('cusDetail.Country')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.nation" maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="邮编" -->
      <el-form-item
        prop="postcode"
        :label="$t('cusDetail.ZipCode')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.postcode" maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="州/省" -->
      <el-form-item
        prop="province"
        :label="$t('cusDetail.StateProvince')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.province" maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="城市" -->
      <el-form-item
        prop="city"
        :label="$t('cusDetail.City')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.city" maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="街道地址" -->
      <el-form-item
        prop="street"
        :label="$t('cusDetail.StreetAddress')"
        :rules="[
          {
            required: true,
            trigger: 'blur',
            message: $t('placeholder.plsInput'),
          },
        ]"
      >
        <el-input v-model="form.street" maxlength="200" clearable></el-input>
      </el-form-item>
      <!-- label="单元号地址" -->
      <el-form-item prop="unit" :label="$t('cusDetail.UnitNoAddress')">
        <el-input v-model="form.unit" maxlength="50" clearable></el-input>
      </el-form-item>
      <!-- label="完整地址" -->
      <el-form-item
        :label="$t('cusCenter.Detailedshippingaddress')"
        prop="address"
      >
        {{ addressComputed }}
      </el-form-item>
    </el-form>

    <div class="text-center">
      <!-- <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button> -->
      <el-button type="primary" @click="save">
        {{ $t('cusDetail.Confirm') }}
      </el-button>
      <el-button @click="close()">{{ $t('cusDetail.Cancel') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AddressAddModel',
  props: ['addressList'],
  data() {
    return {
      // title: '新增收货信息',
      title: 'dialogTitle.addreceivinginformation',
      form: {
        companyName: '',
        consigneeName: '',
        consigneePhone: '',
        nation: '',
        postcode: '',
        province: '',
        city: '',
        street: '',
        unit: '',
        address: '',
        addressConfigId: '',
        defaultAdress: 0,
        configFlag: new Date().getTime(),
        defaultAddressFlag: 0, // 默认地址标记 0否 1是
      },
      addressAddVisible: false,
      rules: {},
      currentIndex: -1,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
    // addressComputed() {
    //   let form = this.form
    //   let address = ''
    //   if (form.unit) {
    //     address =
    //       form.street +
    //       ' ' +
    //       form.unit +
    //       ' ' +
    //       form.city +
    //       ' ' +
    //       form.province +
    //       ' ' +
    //       form.postcode
    //   } else {
    //     address =
    //       form.street +
    //       ' ' +
    //       form.city +
    //       ' ' +
    //       form.province +
    //       ' ' +
    //       form.postcode
    //   }
    //   form.address = address
    //   return address
    // },
    addressComputed(item) {
      const { street, unit, city, province, postcode } = this.form
      const _ = (field) => {
        return field ? field : ''
      }
      return `${_(street)} ${_(unit)} ${_(city)} ${_(province)} ${_(postcode)}`
    },
  },
  methods: {
    showAddEdit(row, index) {
      if (row) {
        this.form = row
        this.currentIndex = index
        // this.title = '编辑收货信息'
        this.title = 'dialogTitle.editreceivinginformation'
      } else {
        // this.title = '新增收货信息'
        this.title = 'dialogTitle.addreceivinginformation'
        this.currentIndex = -1
      }
      this.addressAddVisible = true
    },

    // 保存
    save() {
      let self = this
      self.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const form = {
            ...self.form,
          }
          self.$emit('addressConfigSave', form, this.currentIndex)
          self.close()
        } else {
          return false
        }
      })
    },

    // 关闭弹窗重置表单
    close() {
      this.currentIndex = -1
      this.$refs.ruleForm.resetFields()
      this.form = {
        companyName: '',
        consigneeName: '',
        consigneePhone: '',
        nation: '',
        postcode: '',
        province: '',
        city: '',
        street: '',
        unit: '',
        address: '',
        addressConfigId: '',
        defaultAdress: 0,
        defaultAddressFlag: 0,
      }
      this.addressAddVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.address {
  .el-form-item {
    margin-bottom: 18px;
  }
}
</style>
