var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"成本计算","visible":_vm.costVisible,"width":"800px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.costVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label-width":"140px"}},[_c('template',{slot:"label"},[_c('span',[_vm._v("是否分仓核算：")]),_c('Qtip',{attrs:{"content":"启用后将商品的成本将根据各分仓核算, 且可以以仓库的维度设置出库模式和成本计价方式；未启用根据总仓核算，且所有仓库遵循一种出库模式和成本计价方式；","effect":"dark"}})],1),_c('el-radio-group',{on:{"change":_vm.changeType},model:{value:(_vm.form.accountRule),callback:function ($$v) {_vm.$set(_vm.form, "accountRule", $$v)},expression:"form.accountRule"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("否")])],1)],2),_c('el-table',{attrs:{"width":"100%","border":"","data":_vm.form.stockCostConfigureVOS}},[(_vm.form.accountRule == 1)?_c('el-table-column',{attrs:{"show-overflow-tooltip":"","align":"center","label":"仓库名称","prop":"warehouseName","width":"120px"}}):_vm._e(),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'stockCostConfigureVOS.' + scope.$index + '.warehouseOutType',"rules":[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                } ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":function($event){return _vm.typeChange(scope, 'warehouseOutType')}},model:{value:(scope.row.warehouseOutType),callback:function ($$v) {_vm.$set(scope.row, "warehouseOutType", $$v)},expression:"scope.row.warehouseOutType"}},_vm._l((_vm.checkoutOption),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)],1)]}}])},[_c('template',{slot:"header"},[_c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v("出库模式")]),_c('Qtip',{attrs:{"content":"先进先出：是指先采购进仓库的商品在销售的时候优先出库；指定批次：是指每次销售出库时手动选择采购入库的商品","effect":"dark"}})],1)],2),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'stockCostConfigureVOS.' + scope.$index + '.warehouseInBatch',"rules":[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":scope.row.warehouseOutType == 2},on:{"change":function($event){return _vm.typeChange(scope, 'warehouseInBatch')}},model:{value:(scope.row.warehouseInBatch),callback:function ($$v) {_vm.$set(scope.row, "warehouseInBatch", $$v)},expression:"scope.row.warehouseInBatch"}},_vm._l((_vm.alterOption),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)],1)]}}])},[_c('template',{slot:"header"},[_c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v("入库批次可修改")]),_c('Qtip',{attrs:{"content":"选择是：系统推荐入库批次后，可手动修改；选择否：系统推荐入库批次后，不可更改","effect":"dark"}})],1)],2),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'stockCostConfigureVOS.' + scope.$index + '.priceType',"rules":[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                } ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":function($event){return _vm.typeChange(scope, 'priceType')},"focus":function($event){return _vm.saveOldVal(scope)}},model:{value:(scope.row.priceType),callback:function ($$v) {_vm.$set(scope.row, "priceType", $$v)},expression:"scope.row.priceType"}},_vm._l((_vm.valuationOption),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)],1)]}}])},[_c('template',{slot:"header"},[_c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v("计价方式")]),_c('Qtip',{attrs:{"content":"批次计价法：出库单价取各批次入库单价；移动加权：入库根据当前库存成本和库存数量计算平均单价","effect":"dark"}})],1)],2)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1),_c('type-change-dialog',{attrs:{"visible":_vm.tipVisible,"msgType":_vm.msgType},on:{"update:visible":function($event){_vm.tipVisible=$event},"checkTrue":_vm.checkTrue,"checkFalse":_vm.checkFalse}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }