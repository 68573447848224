<!-- 图片预览 -->
<template>
  <div>
    <div v-if="Images && Images.length">
      <div v-if="Images.length === 1" class="img-wrap">
        <el-badge v-show="Images[0].printingImg.split(',').length > 1"
          :value="Images[0].printingImg.split(',').length" />
        <el-image :src="
            Images[0].printingImg.split(',')[0] +
            URL_COMPRESS
          " class="c_pointer" @click="handlePreview(Images[0].printingImg.split(','))" />
      </div>
      <div v-else class="img-wrap">
        <el-badge :value="badgeNum" />
        <el-image :src="Images[0].printingImg.split(',')[0] + URL_COMPRESS" class="c_pointer"
          @click="multiPreview(Images)" />
      </div>
    </div>
    <div v-else>--</div>

    <!-- 多图预览 -->
    <el-dialog v-if="imgs.length" :title="`效果图预览 ${imgs[imgIndex - 1].code}`" height="600px" width="900px"
      :visible.sync="dialogVisible" :before-close="handleClose" append-to-body>
      <div class="img-container">
        <div class="text-left">
          <i class="el-icon-arrow-left" @click="prev"></i>
        </div>
        <div class="C">
          <el-image :src="imgs[imgIndex - 1].img" class="c_pointer" @click="handlePreview(imgs[imgIndex - 1].img)" />
        </div>
        <div class="text-right">
          <i class="el-icon-arrow-right" @click="next"></i>
        </div>
      </div>
      <div class="text-right mt10">{{ imgIndex + '/' + badgeNum }}</div>
    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />
  </div>
</template>

<script>
  import { URL_COMPRESS } from '@/config'
  export default {
    name: 'PreviewImage',
    props: {
      Images: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        imgs: [], // 数据格式处理
        imgUrl: '', // 图片预览url
        imgIndex: 1, // 图片索引
        showViewer: false, // 图片预览参数
        dialogVisible: false,
        URL_COMPRESS: URL_COMPRESS
      }
    },
    computed: {
      badgeNum() {
        let nums = 0
        for (let i = 0; i < this.Images.length; i++) {
          const arr = this.Images[i].printingImg?.split(',') || []
          nums += arr.length
        }
        return nums
      },
    },
    created() { },
    methods: {
      // 多图预览
      multiPreview() {
        if (this.Images.length > 1) {
          const Images = this.Images

          for (let i = 0; i < Images.length; i++) {
            for (let n = 0; n < Images[i].printingImg.split(',').length; n++) {
              this.imgs.push({
                img: Images[i].printingImg.split(',')[n],
                code: Images[i].purchasingDemandNumber,
              })
            }
          }
        }
        this.imgIndex = 1
        this.dialogVisible = true
      },

      // 多图预览关闭
      handleClose() {
        this.imgs = []
        this.dialogVisible = false
      },

      // 上一页
      prev() {
        if (this.imgIndex === 1) {
          this.imgIndex = this.imgs.length
        } else {
          this.imgIndex = this.imgIndex - 1
        }
      },

      // 下一页
      next() {
        if (this.imgIndex === this.imgs.length) {
          this.imgIndex = 1
        } else {
          this.imgIndex = this.imgIndex + 1
        }
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      // 取消预览
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .img-wrap {
    position: relative;
    padding: 5px 0;
    width: 50px;
    margin: 0 auto;

    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }

    .el-badge {
      position: absolute;
      top: 5px;
      right: -10px;
      z-index: 2;
    }
  }

  .img-container {
    display: flex;
    height: 600px;

    i {
      font-size: 50px;
      cursor: pointer;
      width: 50px;
      line-height: 600px;
    }

    i:hover {
      color: #409eff;
    }

    .C {
      width: 100%;

      ::v-deep {
        .el-image {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  ::v-deep {
    .el-dialog__title {
      text-align: left;
      display: block;
    }

    .el-dialog__body {
      padding: 10px;
    }
  }
</style>