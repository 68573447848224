<template>
  <div style="display: inline-block">
    <el-button size="small" @click="doIt">高级筛选</el-button>
    <el-drawer
      title="高级筛选"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
      custom-class="demo-drawer"
    >
      <div class="p20">
        <el-form label-position="top" label-width="80px" :model="form">
          <el-form-item label="盘点仓库">
            <el-select
              v-model="form.warehouseId"
              placeholder="请选择"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in warehouseList"
                :key="item.warehouseId"
                :label="item.name"
                :value="item.warehouseId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="盘点人">
            <UserSelect
              ref="userSelect1"
              @user-choose="choosetakingUser"
              style="width: 100%"
            ></UserSelect>
          </el-form-item>
          <el-form-item label="创建人">
            <UserSelect
              ref="userSelect2"
              @user-choose="choosecreator"
              style="width: 100%"
            ></UserSelect>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="form.createTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="盘点时间">
            <el-date-picker
              v-model="form.takingTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <div class="operate">
          <el-button type="primary" @click="submit">确 定</el-button>
          <el-button @click="reset">重 置</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mixin } from '../help/mixins'
import UserSelect from '@/components/user-select'
export default {
  mixins: [mixin],
  components: { UserSelect },
  data() {
    return {
      drawer: false,
      form: {
        warehouseId: '',
        takingUserId: '',
        takingUser: '',
        creatorId: '',
        creator: '',
        createTime: [],
        takingTime: [],
      },
      currentType: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  methods: {
    handleClose() {
      this.drawer = false
    },
    doIt() {
      this.drawer = true
      this.getWarehouseList()
    },
    choosetakingUser(user) {
      this.form.takingUserId = user.userId
      this.form.takingUser = user.userName
    },
    choosecreator(user) {
      this.form.creatorId = user.userId
      this.form.creator = user.userName
    },
    submit() {
      this.$emit('highSearch', this.form)
      this.drawer = false
    },
    reset() {
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.$refs.userSelect1.user = {}
      this.$refs.userSelect2.user = {}
      this.form.createTime = []
      this.form.takingTime = []
      this.$emit('highSearch', this.form)
      this.drawer = false
    },
  },
}
</script>
<style lang="scss" scoped>
.operate {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ededed;
  padding: 20px;
}
</style>