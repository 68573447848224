<template>
  <el-dialog
    title="订单详情"
    :visible.sync="dialogReceiptDetailVisible"
    width="1100px"
    @close="close"
    :close-on-click-modal="false"
  >
    <!-- 销售退款及其他 -->
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="8">
          <p>订单编号</p>

          <!-- 销售订单 -->
          <router-link
            v-if="form.orderCode"
            target="_blank"
            :to="`/order/orderList/orderDetail?orderCode=${
              form.orderCode
            }&status=${form.orderStatus}&noReturn=${true}`"
          >
            <span class="page-link">{{ form.orderCode }}</span>
          </router-link>
        </el-col>
        <el-col :span="8">
          <p>客户代表</p>
          <span>{{ form.businessName }}</span>
        </el-col>
        <el-col :span="8">
          <p>订单状态</p>
          <span>
            <el-tag type="info" plain v-if="form.orderStatus == 1" size="small">
              录入中
            </el-tag>
            <el-tag
              type="danger"
              plain
              v-if="form.orderStatus == 2"
              size="small"
            >
              已驳回
            </el-tag>
            <el-tag
              type="danger"
              plain
              v-if="form.orderStatus == 3"
              size="small"
            >
              已撤销
            </el-tag>
            <el-tag
              type="primary"
              plain
              v-if="form.orderStatus == 4"
              size="small"
            >
              审核中
            </el-tag>
            <el-tag
              type="success"
              plain
              v-if="form.orderStatus == 5"
              size="small"
            >
              已通过
            </el-tag>
            <el-tag
              type="primary"
              plain
              v-if="form.orderStatus == 6"
              size="small"
            >
              待发货
            </el-tag>
            <el-tag
              type="primary"
              plain
              v-if="form.orderStatus == 8"
              size="small"
            >
              待收货
            </el-tag>
            <el-tag
              type="success"
              plain
              v-if="form.orderStatus == 9"
              size="small"
            >
              已收货
            </el-tag>
            <el-tag
              type="success"
              plain
              v-if="form.orderStatus == 10"
              size="small"
            >
              已完成
            </el-tag>
            <el-tag
              type="danger"
              plain
              v-if="form.orderStatus == 11"
              size="small"
            >
              已作废
            </el-tag>
          </span>
        </el-col>
      </el-row>

      <el-row class="mt30">
        <p class="f_s_15">交易记录</p>
        <!-- 自定义表格 -->
        <table
          class="table-custom mt10"
          align="center"
          cellspacing="0"
          cellpadding="0"
          width="100%"
        >
          <tr height="50px">
            <td width="94px">订单金额</td>
            <td width="94px" class="f_w_6">
              <span v-if="form.totlePrice">
                {{ utils.numberFormat(form.totlePrice, 2) }}
              </span>
              <span v-else>0</span>
            </td>
            <td width="94px">实收金额</td>
            <td width="94px" class="c_success f_w_6">
              <span v-if="form.netReceipts">
                {{ utils.numberFormat(form.netReceipts, 2) }}
              </span>
              <span v-else>0</span>
            </td>
            <td width="94px">已收金额</td>
            <td width="94px" class="c_danger f_w_6">
              <span v-if="form.receivedPrice">
                {{ utils.numberFormat(form.receivedPrice, 2) }}
              </span>
              <span v-else>0</span>
            </td>
            <td width="94px">未收金额</td>
            <td width="94px" class="c_danger f_w_6">
              <span v-if="form.receivedPrice">
                {{
                  utils.numberFormat(form.netReceipts - form.receivedPrice, 2)
                }}
              </span>
              <span v-else>0</span>
            </td>
            <td width="94px">退款金额</td>
            <td width="94px" class="c_danger f_w_6">
              <span v-if="form.refundAmount">
                {{ utils.numberFormat(form.refundAmount, 2) }}
              </span>
              <span v-else>0</span>
            </td>
          </tr>
        </table>
        <el-table
          border
          :data="orderTableData"
          :default-sort="{ prop: 'time', order: 'descending' }"
        >
          <el-table-column prop="reTransactionDate" align="center" label="时间">
            <template slot-scope="scope">
              {{ scope.row.reTransactionDate || '--' }}
            </template>
          </el-table-column>
          <el-table-column prop="typeMemo" align="center" label="类型">
            <template slot-scope="scope">
              {{ scope.row.typeMemo || '--' }}
            </template>
          </el-table-column>
          <el-table-column prop="reAmount" align="center" label="金额">
            <template slot-scope="scope">
              <el-row
                v-if="
                  (scope.row.type === '1' && scope.row.reAmount) ||
                  (scope.row.type === '2' && scope.row.reAmount)
                "
              >
                <span
                  v-if="scope.row.type === '1' && scope.row.reAmount"
                  class="f_w_6 c_success"
                >
                  +{{ moneySign
                  }}{{ utils.numberFormat(scope.row.reAmount, 2) }}
                </span>
                <span
                  v-if="scope.row.type === '2' && scope.row.reAmount"
                  class="c_danger f_w_6"
                >
                  -{{ moneySign
                  }}{{ utils.numberFormat(scope.row.reAmount, 2) }}
                </span>
              </el-row>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="danhao" align="center" label="单号">
            <template slot-scope="scope">
              <span
                class="blue-text"
                v-if="scope.row.transactionCode"
                @click="receiptNoClick(scope.row)"
              >
                {{ scope.row.transactionCode }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div style="margin-top: 40px" class="text-center">
            <el-button @click="close" class="pl20 pr20">关 闭</el-button>
          </div>
        </el-col>
      </el-row>

      <!-- 收款管理——销售订单详情弹框 -->
      <ReceiptDetailModel1 ref="ReceiptDetailModel1" />
      <!-- 收款管理——采购退款详情弹框 -->
      <ReceiptDetailModel2 ref="ReceiptDetailModel2" />

      <!-- 付款单详情弹窗1 -->
      <PaymentDetailModel ref="PaymentDetailModel" />
      <!-- 付款单详情弹窗2,销售退款 -->
      <PaymentDetailModel2 ref="PaymentDetailModel2" />
    </div>
  </el-dialog>
</template>

<script>
  import {
    paymentStatusVO,
    getDetailForShowByCode,
    getDetailForShow,
  } from '@/api/finance/finance-payment'

  import ReceiptDetailModel1 from '@/views/finance-center/credit-manage/components/receipt-detail-model1'
  import ReceiptDetailModel2 from '@/views/finance-center/credit-manage/components/receipt-detail-model2'

  import PaymentDetailModel from '@/views/finance-center/payment-manage/components/payment-detail-model'
  import PaymentDetailModel2 from '@/views/finance-center/payment-manage/components/payment-detail-model2'
  export default {
    name: 'OrderDetailModel',
    data() {
      return {
        dialogReceiptDetailVisible: false, //订单详情弹窗
        yifu: 0,
        form: {},
        payType: null, //1采购付款，2销售退款
        amendRow: '',
        orderTableData: [],
        moneySign: '', //货币符号
      }
    },
    components: {
      PaymentDetailModel,
      PaymentDetailModel2,
      ReceiptDetailModel1,
      ReceiptDetailModel2,
    },
    methods: {
      async showOrderDetail() {
        this.dialogReceiptDetailVisible = true
        let response = await paymentStatusVO({
          orderCode: this.amendRow,
        })
        if (response.code == '000000') {
          this.form = response.data
          this.moneySign = this.form.currency === '美元' ? '$' : '￥'
          this.orderTableData = this.form.otherOrderVOS
        }
      },

      //销售退款——点击单号（type：1收款单，2付款单）
      receiptNoClick(row) {
        // 收款单跳转 row.type=1
        if (row.type === '1') {
          let status = row.status === '9' ? 4 : 1
          if (this.payType === 1) {
            //采购付款的收款单
            this.$refs['ReceiptDetailModel2'].showReceiptDetail(
              row.transactionCode,
              status, //需要重新取值
              false
            )
          } else {
            //销售退款收款单
            this.$refs['ReceiptDetailModel1'].showReceiptDetail(
              row.transactionCode,
              status //需要重新取值
            )
          }
        } else {
          //付款单跳转
          getDetailForShow({ businessId: row.paymentOrderId }).then((res) => {
            if (res.data.payOrderId) {
              if (res.data.payType === 1) {
                this.$refs['PaymentDetailModel'].amendRow = res.data.payOrderId
                this.$refs['PaymentDetailModel'].showReceiptDetail()
              } else {
                this.$refs['PaymentDetailModel2'].amendRow = res.data.payOrderId
                this.$refs['PaymentDetailModel2'].showReceiptDetail()
              }
            }
          })
        }
      },

      close() {
        this.dialogReceiptDetailVisible = false
        this.yifu = 0
        this.form = {}
        this.orderTableData = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;
    .detail-header {
      > .el-col {
        p {
          margin-bottom: 14px;
        }
        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }
    .remark {
      > .el-col {
        p {
          margin-bottom: 14px;
        }
        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }
    .table-custom {
      border-collapse: collapse;
      text-align: left;
      td {
        border: #ebeef5 1px solid;
        text-align: center;
      }
      td:nth-child(odd) {
        background-color: #f5f7fa;
      }
    }
  }
</style>
