<!--
  功能：装箱尺寸组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年02月10日 10:08:37
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div>
    <!-- 装箱尺寸显示 -->
    <div class="packing-detail-wrapper">
      <template v-if="packingSizeForm.sizes.length > 0">
        <div class="content">
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              <Detail :sizes="packingSizeForm.sizes" />
            </div>
            <div>
              <Detail
                :sizes="isSizesNotOver5 ? theTop5Sizes : packingSizeForm.sizes"
              />
              <p v-if="isSizesNotOver5">...</p>
            </div>
          </el-tooltip>
        </div>
      </template>
      <p v-else class="item">-</p>
      <p :style="{ visibility: showEdit ? 'visible' : 'hidden' }">
        <i class="el-icon-edit" @click="showDialog"></i>
      </p>
    </div>
    <!-- 装箱尺寸弹窗 -->
    <el-dialog
      title="装箱尺寸"
      :visible="isShow"
      width="600px"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form :model="packingSizeForm" ref="form" :rules="rules">
        <el-table :data="packingSizeForm.sizes" border :max-height="400">
          <el-table-column align="center" label="装箱尺寸">
            <template slot-scope="scope">
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    :prop="'sizes.' + scope.$index + '.boxLengthCm'"
                    :rules="[
                      {
                        required: true,
                        message: '请输入',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      type="number"
                      placeholder="长 "
                      v-model="scope.row.boxLengthCm"
                    >
                      <template slot="append">CM</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    :prop="'sizes.' + scope.$index + '.boxWidthCm'"
                    :rules="[
                      {
                        required: true,
                        message: '请输入',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      type="number"
                      v-model="scope.row.boxWidthCm"
                      placeholder="宽 "
                    >
                      <template slot="append">CM</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    :prop="'sizes.' + scope.$index + '.boxHeightCm'"
                    :rules="[
                      {
                        required: true,
                        message: '请输入',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      type="number"
                      placeholder="高 "
                      v-model="scope.row.boxHeightCm"
                    >
                      <template slot="append">CM</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="80px">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="删除"
                placement="top-start"
              >
                <el-button
                  type="danger"
                  plain
                  circle
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteSize(scope.$index)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-button
            @click="addSize"
            icon="el-icon-plus"
            style="width: 100%; color: #1890ff; border-top: none"
          >
            新增
          </el-button>
        </el-row>
      </el-form>
      <template #footer>
        <el-row class="text-center">
          <el-button type="primary" @click="save">确 定</el-button>
          <el-button @click="close">取 消</el-button>
        </el-row>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Detail from './packing-size-detail'
export default {
  name: 'PackingSize',
  props: {
    title: {
      type: String,
      default: '装箱尺寸',
    },
    packingSizes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      packingSizeForm: {
        sizes: [],
      },
      rules: {},
      showEdit: false,
      isShow: false,
    }
  },
  components: {
    Detail,
  },
  computed: {
    isSizesNotOver5() {
      return this.packingSizeForm.sizes.length > 5
    },
    theTop5Sizes() {
      return this.packingSizeForm.sizes.slice(0, 5)
    },
  },
  methods: {
    deleteSize(index) {
      // 校验至少保留一条
      const { sizes } = this.packingSizeForm
      if (Array.isArray(sizes) && sizes.length === 1) {
        return this.$message.warning('至少保留1条数据')
      }
      sizes.splice(index, 1)
    },
    // 展示弹窗
    showDialog() {
      this.isShow = true
      const sizes =
        this.packingSizes.length > 0
          ? this.packingSizes
          : [
              {
                boxLengthCm: '',
                boxWidthCm: '',
                boxHeightCm: '',
              },
            ]

      this.packingSizeForm.sizes = JSON.parse(JSON.stringify(sizes))
    },
    toggleEditIcon(flag) {
      this.showEdit = flag
    },

    close() {
      this.packingSizeForm.sizes = this.packingSizes
      this.isShow = false
    },
    showSizes() {
      this.packingSizeForm.sizes = this.packingSizes
    },
    addSize() {
      if (this.packingSizeForm.sizes.length >= 20) {
        return this.$message.warning('装箱尺寸最多可新增20条数据!')
      }
      this.packingSizeForm.sizes.push({
        boxLengthCm: '',
        boxWidthCm: '',
        boxHeightCm: '',
      })
    },
    save() {
      this.$refs.form.validate((flag) => {
        if (flag) {
          this.$emit('set-sizes', this._string2num(this.packingSizeForm.sizes))
          this.isShow = false
        }
      })
    },
    // 将尺寸数据string类型改为number类型
    _string2num(arr) {
      return arr.map((item) => {
        const keys = ['boxLengthCm', 'boxWidthCm', 'boxHeightCm']
        keys.forEach((key) => {
          item[key] = item[key] * 1
        })
        return item
      })
    },
  },
  created() {
    this.showSizes()
  },
}
</script> 

<style scoped lang="scss">
::v-deep {
  .el-dialog__header {
    text-align: left;
  }
}
.line-one {
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.packing-detail-wrapper {
  display: flex;
  align-items: flex-end;
  .content {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
  }
  .item {
    width: 20px;
  }
}
.el-icon-edit {
  cursor: pointer;
  color: #409eff;
  cursor: 'pointer';
}
</style>
<style>
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
