import request from '@/core/services/request'

/***
 * 根据moduleId获取对应模块的翻译数据
 */

export function getInternationalTransInfo(moduleId, isTheLastQueue) {
  return request({
    url: '/system/international/getInternationalTransInfo',
    method: 'POST',
    data: {
      moduleId,
    },
    loading: false,
  }).then((res) => {
    if (res?.code === '000000') {
      return {
        data: res.data,
        moduleId: res?.data[0]?.moduleId,
        done: isTheLastQueue,
      }
    }
  })
}
