<template>
  <div class="custom-container">
    <el-row class="page-header align_center" type="flex">
      <el-row type="flex" class="flex-row">
        <div class="form-wrapper">
          <el-form
            ref="form"
            :inline="true"
            :model="queryForm"
            @submit.native.prevent
          >
            <el-form-item label="归属模块">
              <el-select
                v-model="queryForm.moduleCode"
                clearable
                style="width: 100px"
              >
                <el-option
                  v-for="item in moduleList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="操作人">
              <UserSelect
                ref="userSelect"
                style="width: 100px"
                @user-choose="chooseUser"
              />
            </el-form-item>

            <el-form-item label="操作时间">
              <el-date-picker
                v-model="operateDate"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                style="width: 340px"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="操作记录">
              <el-input
                v-model="queryForm.operationContent"
                placeholder="请输入"
                style="width: 100px"
                clearable
              />
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click="init">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </el-row>
    </el-row>
    <OperateLog
      ref="operateLog"
      showModule
      :extraHeight="5"
      :searchParams="queryForm"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UserSelect from '@/components/user-select'
  import OperateLog from '@/components/operate-logs'
  export default {
    data() {
      return {
        queryForm: {
          moduleCode: '', //归属模块
          operatorId: '', //操作人id
          operatorName: '', //操作人名称
          operationContent: '', //操作记录
          operationStartTime: '',
          operationEndTime: '',
        },

        operateDate: [],

        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },

        moduleList: [
          //归属模块下拉
          {
            code: '',
            name: '全部',
          },
          {
            code: 10001,
            name: '销售订单',
          },
          {
            code: 10002,
            name: '采购订单',
          },
          {
            code: 10003,
            name: '跟单管理',
          },
          {
            code: 10004,
            name: '客户管理',
          },
          {
            code: 10005,
            name: '产品列表',
          },
          {
            code: 10006,
            name: '权限分组',
          },
          {
            code: 10007,
            name: '委外加工',
          },
          {
            code: 10008,
            name: '询盘管理',
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    components: { UserSelect, OperateLog },

    watch: {
      // 登录时间
      operateDate(dateArray) {
        if (dateArray) {
          this.queryForm.operationStartTime = dateArray[0]
          this.queryForm.operationEndTime = dateArray[1]
        } else {
          this.queryForm.operationStartTime = ''
          this.queryForm.operationEndTime = ''
        }
      },
    },
    methods: {
      // 初始化
      init() {
        this.$refs.operateLog.search()
      },

      // 重置
      reset() {
        this.operateDate = []
        this.queryForm = this.$options.data().queryForm
        this.$refs.userSelect.user = ''
        setTimeout(() => {
          this.init()
        }, 200)
      },

      // 选择人员

      chooseUser(user) {
        const form = this.queryForm
        form.operatorId = user ? user.userId : ''
        form.operatorName = user ? user.userName : ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .flex-row {
    align-items: center;
    margin-bottom: 15px;
  }
</style>
