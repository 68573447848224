<template>
  <div class="tab-content">
    <el-button type="primary" @click="editTemplate()">
      <!-- 新增 -->
      {{ $t('boxsetting.Add') }}
    </el-button>
    <div class="account-group" v-for="(item, index) in accountList" :key="index">
      <div class="title">
        <!-- 所属账号： -->
        {{ $t('boxsetting.AttributableAccount') }}:
        {{ item.emailAccount }}
      </div>
      <el-table border :data="templateTable[item.emailAccount]">
        <!-- label="模板名称" -->
        <el-table-column align="center" width="200" :label="$t('boxsetting.TemplateName')"
          prop="templateName"></el-table-column>
        <!-- label="模板内容" -->
        <el-table-column :label="$t('boxsetting.TemplateContent')">
          <template slot-scope="scope">
            <div class="template-content" v-html="scope.row.templateContent"></div>
          </template>
        </el-table-column>
        <!-- label="操作" -->
        <el-table-column align="center" width="100" :label="$t('boxsetting.Operate')">
          <template slot-scope="scope">
            <!-- content="编辑" -->
            <el-tooltip placement="top" :content="$t('iconbtn.edit')">
              <el-button type="text" icon="el-icon-edit" @click="editTemplate(scope.row)"></el-button>
            </el-tooltip>
            <!-- content="删除"  -->
            <el-tooltip :content="$t('iconbtn.del')" placement="top">
              <el-button type="text" icon="el-icon-delete" @click="removeTemplate(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <EditTemplateModel ref="EditTemplateModel" @success="getMailTemplateList" />
  </div>
</template>
<script>
  import { mailTemplateList, mailTemplateDelete } from '@/api/mail/mail-setting'
  import EditTemplateModel from '@/views/mail/setting/components/edit-template-model'
  import { mapState } from 'vuex'
  import _ from 'lodash'
  export default {
    components: {
      EditTemplateModel,
    },
    data() {
      return {
        templateTable: {}, //签名列表
      }
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
        accountList: (state) =>
          state.mail.accountList.filter((item) => item.authStatus !== 2),
      }),
    },
    created() {
      this.getMailTemplateList()
    },
    methods: {
      // 新增编辑签名
      editTemplate(row) {
        this.$refs.EditTemplateModel.showDialog(row)
      },
      //删除签名
      removeTemplate(row) {
        // this.$confirm('是否确认删除？', '提示', {
        //   type: 'warning',
        // })
        this.$confirm(this.$t('reqmsg.$13'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          mailTemplateDelete({
            mailTemplateId: row.mailTemplateId,
          }).then((res) => {
            if (res.code == '000000') {
              // this.$message.success('删除成功！')
              this.$message.success(this.$t('reqmsg.M2008'))
              this.getMailTemplateList()
            }
          })
        })
      },
      // 获取签名列表
      getMailTemplateList() {
        mailTemplateList({
          businessId: this.userInfo.userId,
        }).then((res) => {
          if (res.code == '000000') {
            let list = res.data
            let obj = _.groupBy(list, 'emailAccount') //根据相同邮箱，把数组转成对象，key是邮箱，value是数组
            this.templateTable = obj
            // let arr = []
            // for (let key in obj) {
            //   let item = {}
            //   item['emailAccount'] = key
            //   item['children'] = obj[key]
            //   arr.push(item)
            // }
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .tab-content {
    overflow-y: auto;
    height: calc(100vh - 200px);
  }

  .account-group {
    margin-top: 20px;

    .title {
      font-size: 16px;
      color: #333;
      height: 40px;
      line-height: 40px;
    }

    .template-content {
      width: 100%;
      overflow: hidden;

      ::v-deep img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
</style>
