<template>
  <div class="custom-container">
    <div>
      <!-- 头部操作栏 -->
      <el-row class="flex page-header">
        <div>
          <el-form
            ref="form"
            :inline="true"
            :model="queryForm"
            label-width="49px"
            @submit.native.prevent
          >
            <el-form-item>
              <el-input
                v-model="queryForm.param"
                placeholder="中文名称/英文名称"
                clearable
                @input="getList"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="queryForm.showFlag"
                placeholder="是否展示"
                @change="getList"
                clearable
              >
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button class="mr10" @click="reset">重置</el-button>
      </el-row>
      <el-row class="mt10">
        <el-col :span="18" class="page-header-left">
          <el-button type="primary" class="mr10" @click="add">新增</el-button>
        </el-col>
      </el-row>

      <!-- table -->
      <el-row>
        <el-table
          border
          :data="tableData"
          row-key="customExpenseSettingId"
          class="list-table mt10"
        >
          <el-table-column label="拖动排序" :width="80">
            <template>
              <div class="sort">
                <i class="el-icon-sort"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="nameCn"
            label="中文名称"
            :width="200"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="nameEn"
            label="英文名称"
            :width="200"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="showFlag"
            label="是否展示"
            :width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.showFlag === 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="remark"
            label="备注"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="createTime"
            label="创建时间"
            :width="200"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="creator"
            label="创建人"
            :width="200"
          ></el-table-column>

          <!-- 操作 -->
          <el-table-column align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" size="medium" @click="edit(scope.row)">
                编辑
              </el-button>
              <el-button
                type="text"
                size="medium"
                @click="Del(scope.row.customExpenseSettingId)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>

    <custom-fees-add
      ref="add"
      @customFeesSave="customFeesSave"
    ></custom-fees-add>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import { OrderOfferSetting } from '@/core/interactors/order/order-offer-setting'
import customFeesAdd from '../../components/custom-fees/add'
export default {
  components: { customFeesAdd },
  name: 'themeConfig',
  // 组件状态值
  data() {
    return {
      queryForm: {
        param: '', // 中文名或英文名
        showFlag: '', // 是否展示(0不展示 1展示)
      },
      tableData: [],
      sortable: null,
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    this.setSort()
  },
  methods: {
    // 重置
    reset() {
      this.queryForm = {
        param: '',
        showFlag: '',
      }
      this.getList()
    },

    // 获取列表
    async getList() {
      let res = await OrderOfferSetting.listAll({ ...this.queryForm })

      if (res && res.code === '000000') {
        this.tableData = res.data
      }
    },

    // 新增
    add() {
      this.$refs.add.modelVisible = true
    },

    // 编辑
    edit(row) {
      this.$refs.add.form = { ...row }
      this.$refs.add.modelVisible = true
    },

    // 删除
    async Del(id) {
      this.$baseConfirm('删除不可恢复，确定删除', '提示', async () => {
        let res = await OrderOfferSetting.deletePO(id)

        if (res && res.code === '000000') {
          this.getList()
          this.$message.success('删除成功')
        }
      })
    },

    // 新增编辑回调
    customFeesSave() {
      this.getList()
    },

    // 拖拽
    setSort() {
      const tbody = document.querySelector('.list-table tbody')
      const _this = this
      Sortable.create(tbody, {
        async onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)

          // 拖动排序接口
          const params = _this.tableData.map((item, i) => {
            return {
              customExpenseSettingId: item.customExpenseSettingId,
              sort: i + 1,
            }
          })
          await OrderOfferSetting.batchUpdateSort(params)
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.sort {
  text-align: center;
  cursor: all-scroll;
  i {
    font-size: 18px;
  }
}
::v-deep {
  .list-table {
    height: calc(100vh - 200px);
    border-left: none;
    overflow-y: auto;
  }
  .el-pagination {
    height: 36px;
  }
}
</style>
