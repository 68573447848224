<template>
  <div class="custom-container">
    <!-- 发邮件 -->
    <div
      class="pdf-wrapper"
      style="position: absolute; left: -2000px; top: -2000px"
    >
      <InquiryPrint
        ref="InquiryPrint"
        @rendered="domToPdf"
        @rendered-pdf="domToPdfPrint"
        id="pdfEl"
        style="width: 914px"
      />
    </div>

    <el-row>
      <el-page-header
        :title="$t('other.back')"
        @back="goBack()"
        :content="$t('other.inquiryDetail')"
        v-if="!$route.query.noReturn"
      ></el-page-header>
    </el-row>

    <el-tabs v-model="activeName">
      <el-tab-pane :label="$t('inquiryDetails.InquiryInformation')" name="first" />
      <el-tab-pane :label="$t('inquiryDetails.OperationLog')" name="second" v-if="this.lang != 'en'"/>
    </el-tabs>
    <template v-if="activeName === 'first'">
      <!-- 基本信息 -->
      <h4>{{ $t('inquiryDetails.BasicInformation') }}</h4>
      <div class="custom-detail" :class="lang === 'en' ? 'en' : ''">
        <el-row>
          <el-col class="w_90">
            <!-- 询盘单号 -->
            <em>{{ $t('inquiryDetails.InquiryNumber') }}：</em>
            <span>{{ detail.enquiryCode || '--' }}</span>
          </el-col>
          <el-col class="w_90">
            <!-- 出口抬头 -->
            <em>{{ $t('inquiryDetails.ExportsRise') }}：</em>
            <span>{{ detail.exportsRise || '--' }}</span>
          </el-col>
          <el-col>
            <!-- 询盘来源 -->
            <em>{{ $t('inquiryDetails.InquirySource') }}：</em>
            <span>{{ detail.enquirySourceName || '--' }}</span>
          </el-col>
          <el-col>
            <!-- 客户代表 -->
            <em>{{ $t('inquiryDetails.CustomerRepresentative') }}：</em>
            <span>
              {{
                lang === 'en'
                  ? detail.businessNameEn || '--'
                  : detail.businessName || '--'
              }}
            </span>
          </el-col>
        </el-row>

        <el-row>
          <el-col class="w_90">
            <!-- 订单支持 -->
            <em>{{ $t('inquiryDetails.OrderSupport') }}：</em>
            <span>
              {{
                lang === 'en'
                  ? detail.fromBusinessNameEn || '--'
                  : detail.fromBusinessName || '--'
              }}
            </span>
          </el-col>
          <el-col class="w_90">
            <!-- 创建人 -->
            <em>{{ $t('inquiryDetails.Creator') }}：</em>
            <span>
              {{
                lang === 'en'
                  ? detail.creatorNameEn || '--'
                  : detail.creatorName || '--'
              }}
            </span>
          </el-col>
          <el-col>
            <!-- 创建时间 -->
            <em>{{ $t('inquiryDetails.CreationTime') }}：</em>
            <span>{{ detail.createTime || '--' }}</span>
          </el-col>
          <el-col>
            <!-- 询盘状态 -->
            <em>{{ $t('inquiryDetails.InquiryStatus') }}：</em>
            <span style="display: contents">
              <!-- 作废 -->
              <el-link
                v-if="detail.enquiryStatus == 1"
                type="info"
                :underline="false"
              >
                {{ $t('inquiryList.Invalid') }}
              </el-link>
              <!-- 询盘失败 -->
              <el-link
                v-else-if="detail.enquiryStatus == 2"
                type="info"
                :underline="false"
              >
                {{ $t('inquiryList.InquiryFailed') }}
              </el-link>
              <!-- 手动完成 -->
              <el-link
                v-else-if="detail.enquiryStatus == 3"
                type="success"
                :underline="false"
              >
                {{ $t('inquiryList.DoneManually') }}
              </el-link>
              <!-- 待报价 -->
              <el-link
                v-else-if="detail.enquiryStatus == 4"
                type="warning"
                :underline="false"
              >
                {{ $t('inquiryList.PendingQuotation') }}
              </el-link>
              <!-- 已报价 -->
              <el-link
                v-else-if="detail.enquiryStatus == 5"
                type="primary"
                :underline="false"
              >
                {{ $t('inquiryList.Quoted') }}
              </el-link>
              <!-- 已下单 -->
              <el-link
                v-else-if="detail.enquiryStatus == 6"
                type="warning"
                :underline="false"
              >
                {{ $t('inquiryList.Ordered') }}
              </el-link>
            </span>
          </el-col>
        </el-row>
      </div>

      <!-- 客户信息 -->
      <h4></h4>
      <div class="c-table-wrap">
        <table class="custom-table">
          <thead>
            <tr>
              <!-- 客户姓名/编码 -->
              <th>{{ $t('inquiryDetails.CustomerNameCode') }}</th>
              <!-- 邮箱 -->
              <th>{{ $t('inquiryDetails.Mail') }}</th>
              <!-- 公司名称 -->
              <th>{{ $t('inquiryDetails.CompanyName') }}</th>
              <!-- 公司详细地址 -->
              <th>{{ $t('companyAddress.CompanyDetailedAddress') }}</th>
              <!-- 联系电话 -->
              <th>{{ $t('inquiryDetails.ContactNumber') }}</th>
              <!-- 客户备注 -->
              <th>{{ $t('inquiryDetails.CustomerNotes') }}</th>
              <!-- 客户订单号 -->
              <th>{{ $t('inquiryDetails.CustomerOrderNumber') }}</th>
            </tr>
          </thead>
          <tbody class="short">
            <tr>
              <td>
                <span v-show="detail.customerName">
                  {{ detail.customerName }} / {{ detail.customerCode }}
                </span>
              </td>
              <td>
                <div
                  class="page-link"
                  @click="emailDrawer(detail.customerEmail)"
                >
                  {{ detail.customerEmail }}
                </div>
              </td>
              <td>{{ detail.companyName || '--' }}</td>
              <td>{{ computedCompanyDetailedAddress || '--' }}</td>
              <td>{{ detail.customerPhone || '--' }}</td>
              <td>{{ detail.customerRemark || '--' }}</td>
              <td>{{ detail.customerOrderCode || '--' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 产品信息 -->
      <h4>{{ $t('orderDetails.ProductInformation') }}</h4>
      <!-- 没有报价单的时候 展示产品信息 -->
      <el-table
        :data="products"
        border
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
      >
        <!-- 序号 -->
        <el-table-column
          type="index"
          :label="$t('addInquiry.SerialNumber')"
          width="55"
        ></el-table-column>
        <!-- 产品图片 -->
        <el-table-column
          prop="productUrl"
          :label="$t('addInquiry.ProductPicture')"
          width="130"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.productUrl" class="product-image">
              <span>{{ productTypeTransl(scope.row.standardCustomized) }}</span>
              <img
                :src="scope.row.productUrl"
                class="c_pointer"
                @click="handlePreview(scope.row.productUrl)"
              />
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <!-- 产品信息 -->
        <el-table-column :label="$t('addInquiry.ProductInfo')" min-width="220">
          <template slot-scope="scope">
            <div class="product-info text-left">
              <!-- 产品编码 英文名称 产品运营 -->
              <p>
                {{ $t('productCenter.ProductCode') }}:
                {{ scope.row.productCode }}
              </p>
              <p>
                {{ $t('productCenter.EnglishName') }}: {{ scope.row.productEn }}
              </p>
              <p>
                {{ $t('orderDetails.ProductOperations') }}:
                {{
                  lang === 'en'
                    ? scope.row.productOperationEn
                    : scope.row.productOperation
                }}
              </p>
            </div>
          </template>
        </el-table-column>
        <!-- 规格名称 -->
        <el-table-column
          prop="specification"
          :label="$t('addInquiry.SpecificationName')"
          width="120"
        ></el-table-column>
        <!-- 询盘数量 -->
        <el-table-column
          prop="selectQuantity"
          :label="$t('addInquiry.Inquiries')"
          width="120"
        ></el-table-column>
        <!-- 印刷方式 -->
        <el-table-column
          prop="printingWay"
          :label="$t('addInquiry.PrintingMethod')"
          width="120"
        >
          <template slot-scope="scope">
            {{
              lang === 'en' ? scope.row.printingWayEn : scope.row.printingWay
            }}
          </template>
        </el-table-column>
        <!-- 印刷细节 -->
        <el-table-column
          prop="waySelect"
          :label="$t('addInquiry.PrintingDetails')"
          width="120"
        ></el-table-column>
        <!-- 印刷位置 -->
        <el-table-column
          prop="digitSelect"
          :label="$t('addInquiry.PrintingPosition')"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.digitSelect">
              {{ scope.row.digitSelect }}
              {{ $t('addInquiry.Position') }}
            </span>
            <span v-else>{{ scope.row.printingPosition || '--' }}</span>
          </template>
        </el-table-column>
        <!-- 需求描述 -->
        <el-table-column
          prop="demand"
          :label="$t('addInquiry.RequirementDescription')"
          min-width="180"
        >
          <template slot-scope="scope">
            <div class="limit-text" :title="scope.row.demand">
              {{ scope.row.demand }}
            </div>
          </template>
        </el-table-column>
        <!-- 收货国家 -->
        <el-table-column
          prop="shippingCountry"
          :label="$t('addInquiry.ReceivingCountries')"
          width="120"
        >
          <template slot-scope="scope">
            {{ getCountry(scope.row.shippingCountry) }}
          </template>
        </el-table-column>
        <!-- 派送到门 -->
        <el-table-column
          prop="deliveryToTheDoor"
          :label="$t('addInquiry.DeliveryDoor')"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="lang === 'en'">
              {{ scope.row.deliveryToTheDoor === 1 ? 'Yes' : 'No' }}
            </span>
            <span v-else>
              {{ scope.row.deliveryToTheDoor === 1 ? '是' : '否' }}
            </span>
          </template>
        </el-table-column>
        <!-- 邮编 -->
        <el-table-column
          prop="zipCode"
          :label="$t('addInquiry.Postcode')"
          width="120"
        ></el-table-column>
        <!-- 收货地址 -->
        <el-table-column
          prop="shippingAddress"
          :label="$t('addInquiry.ShippingAddress')"
          min-width="180"
        >
          <template slot-scope="scope">
            <div class="limit-text">
              {{ scope.row.shippingAddress || '--' }}
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 报价记录 -->
      <h4>{{ $t('inquiryDetails.QuoteRecord') }}</h4>
      <div class="mb20" v-for="(item, i) in detail.priceSheets" :key="i">
        <div class="record-top">
          <div>
            <span>
              Quote No.:
              <em class="link" @click="quote(item.priceSheetCode)">
                {{ item.priceSheetCode }}
              </em>
            </span>
            <span>
              Quote Date：
              <em>{{ item.quoteDate }}</em>
            </span>
            <span>
              Founder：
              <em>{{ lang === 'en' ? item.founderEn : item.founder }}</em>
            </span>
          </div>
          <div>
            <span v-show="item.orderCode">
              Order No：
              <span class="page-link" style="font-weight: 500">
                <router-link
                  :to="`/order/orderList/orderDetail?orderCode=${item.orderCode}`"
                >
                  {{ item.orderCode }}
                </router-link>
              </span>
            </span>
            <!-- 打印报价单 -->
            <el-tooltip
              :content="$t('inquiryDetails.PrintQuotation')"
              placement="top"
            >
              <el-button
                style="padding: 5px"
                type="primary"
                plain
                @click="printOffer(item.priceSheetCode)"
                size="mini"
                icon="iconfont icon-dayin"
                circle
              ></el-button>
            </el-tooltip>
          </div>
        </div>

        <div class="c-table-wrap">
          <table class="custom-table">
            <thead>
              <tr>
                <th class="index">#</th>
                <!-- Item No. -->
                <th>{{ $t('inquiryDetails.ItemNo') }}</th>
                <!-- Item Name -->
                <th>{{ $t('inquiryDetails.ItemName') }}</th>
                <!-- 规格 -->
                <th>{{ $t('inquiryDetails.Specification') }}</th>
                <!-- Qty -->
                <th>{{ $t('inquiryDetails.Qty') }}</th>
                <!-- 需求描述 -->
                <th class="more">
                  {{ $t('inquiryDetails.RequirementDescription') }}
                </th>
                <!-- Net price($) -->
                <th>{{ $t('inquiryDetails.NetPrice') }}</th>
                <!-- Set up Charge($) -->
                <th>{{ $t('inquiryDetails.SetUpCharge') }}</th>
                <!-- 运输方式 -->
                <th class="more">{{ $t('inquiryDetails.ShippingMethod') }}</th>
                <!-- Shipping Fee -->
                <th>{{ $t('inquiryDetails.ShippingFee') }}</th>
                <!-- Subtotal($) -->
                <th>{{ $t('inquiryDetails.Subtotal') }}</th>
                <!-- <th width="120">Renderings</th> -->
              </tr>
            </thead>
            <tbody class="short">
              <tr v-for="(list, k) in item.priceSheets" :key="k">
                <td>{{ k + 1 }}</td>
                <td>{{ list.sku || list.productCode }}</td>
                <td>{{ list.productEn || '--' }}</td>
                <td>{{ list.specification || '--' }}</td>
                <td>{{ getQuoteInfo(list.informationVOS, 'num') }}</td>
                <td>{{ list.remark }}</td>
                <td>{{ getQuoteInfo(list.informationVOS, 'netPrice') }}</td>
                <td>{{ getQuoteInfo(list.informationVOS, 'fee') }}</td>
                <td>
                  <span v-if="list.transportType == 1">
                    {{ $t('addInquiry.AirTransportation') }}
                  </span>
                  <span v-else-if="list.transportType == 2">
                    {{ $t('addInquiry.SeaTransportation') }}
                  </span>
                  <span v-else-if="list.transportType == 3">
                    {{ $t('addInquiry.Expressage') }}
                  </span>
                  <span v-else>--</span>
                </td>
                <td>
                  {{ getQuoteInfo(list.informationVOS, 'transportPrice') }}
                </td>
                <td class="red f_s_16">
                  <span v-if="list.totalPrice">
                    $&nbsp;{{ list.totalPrice }}
                  </span>
                  <span v-else>--</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <el-row>
        <!-- 备注 -->
        <h4>{{ $t('inquiryDetails.Remark') }}：</h4>
        <span>{{ detail.demand || '--' }}</span>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <!-- 附件 -->
          <h4>{{ $t('inquiryDetails.Appendix') }}</h4>
          <div v-show="fileList.length">
            <div class="flie-list" v-for="(item, i) in fileList" :key="i">
              <i class="el-icon-document"></i>
              <a class="page-link ml5">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top-start"
                >
                  <span class="ell">
                    <router-link
                      :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                      target="_blank"
                    >
                      {{ item.name }}
                    </router-link>
                  </span>
                </el-tooltip>
              </a>
              <span class="size ml10">
                {{ utils.calculateFileSize(item.size) }}
              </span>
              <span class="ml10">{{ item.time }}</span>
            </div>
          </div>
          <div v-show="!fileList.length">{{ $t('other.no') }}</div>
        </el-col>
      </el-row>
    </template>
    <OperateLogs
      v-if="activeName === 'second'"
      :bizId="$route.query.enquiryId"
    />

    <!-- 报价单预览 -->
    <el-dialog :visible.sync="offerSheetVisible" width="1210px">
      <div class="offer-container">
        <offerSheet
          v-if="offerSheetVisible"
          :offerSheetInfo="offerSheetInfo"
        ></offerSheet>
      </div>
      <div class="text-center mt20">
        <!-- 关闭 -->
        <el-button @click="offerSheetVisible = false">
          {{ $t('inquiryDetails.Close') }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 询盘记录Drawer -->
    <el-drawer
      :title="`${$t('other.customRecords')}:${clientEmail}`"
      size="1000px"
      :visible.sync="recordDrawer"
      :before-close="recordDrawerClose"
    >
      <recordDrawer
        v-if="recordDrawer"
        :clientEmail="clientEmail"
        @recordDrawerClose="recordDrawerClose"
      />
    </el-drawer>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
  import offerSheet from './inquiryPrint.vue'
  import { orderEnquiryLookDetail, listOrderSource } from '@/api/order'
  import { OrderListInteractor } from '@/core'
  import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'
  import recordDrawer from './components/recordDrawer'
  import OperateLogs from '@/components/operate-logs'
  import sendMailMixin from './helper/mailsend-mixin'
  import { mapGetters } from 'vuex'
  export default {
    mixins: [sendMailMixin],
    components: {
      offerSheet,
      recordDrawer,
      OperateLogs,
    },
    data() {
      return {
        activeName: 'first',
        enquiryId: '',
        detail: '',
        clientEmail: '',
        offerSheetInfo: {
          enquiryIds: '',
          priceSheetCode: '',
        },
        products: [], // 产品列表
        fileList: [],
        quickRemaks: [],
        offerSheetVisible: false,
        recordDrawer: false,
        showViewer: false, //图片预览参数
        imgUrl: [], //图片预览url
        emptyImg: require('../../../assets/empty_images/picture.png'),
        computedCompanyDetailedAddress: '',
        countryOptions: [],
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    created() {
      if (this.$route.query.enquiryId) {
        this.enquiryId = this.$route.query.enquiryId
        this.offerSheetInfo.enquiryIds = this.$route.query.enquiryId
        this.getDetail(this.enquiryId)
      }
      this.countryList()
      this.getListOrderSource()
    },
    watch: {
      lang(newValue) {
        const detail = this.detail

        if (detail.orderSource > 0 && detail.orderSource != 99) {
          const item = this.quickRemaks.find(
            (item) => item.code === detail.orderSource
          )
          detail.demand = this.lang === 'en' ? item.messageEn : item.messageCn
        }
        if(newValue === 'en') this.activeName = 'first'
      },
    },
    methods: {
      // 获取国家列表
      async countryList() {
        let data = { level: 0 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.countryOptions = res.data
        } else {
          this.countryOptions = []
        }
      },
      // 获取订单来源列表
      getListOrderSource() {
        listOrderSource().then((res) => {
          this.quickRemaks = res?.data || []
        })
      },
      // 返回收货国家
      getCountry(code) {
        const item = this.countryOptions?.filter(
          (item) => item.code === code
        )[0]
        if (item) {
          return item.name
        } else {
          return '--'
        }
      },

      // 产品类型转化
      productTypeTransl(str) {
        if (this.lang === 'en') {
          if (str === '标准品') {
            return 'Standard Product'
          } else if (str === '订制品') {
            return 'Customized Product'
          }
        }
        return str
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          return res.data
        } else {
          return []
        }
      },
      // 获取名称
      getDetailedAddress() {
        let countryObj = this.countryOptions?.filter(
          (item) => item.code === this.detail.companyCountry
        )?.[0]
        if (!countryObj) {
          this.computedCompanyDetailedAddress = this.detail.companyAddress
          return
        }
        this.getStateFn(countryObj.id).then((data) => {
          let ProvinceObj = data.filter(
            (item) => item.code === this.detail.companyProvince
          )?.[0]
          let companyCountry = countryObj.name
          let companyProvince = ProvinceObj?.name
          let addressArr = [
            companyCountry,
            companyProvince || this.detail.companyProvince,
            this.detail.companyCity,
            this.detail.companyAddress,
          ]
          this.computedCompanyDetailedAddress = addressArr
            .filter((item) => item)
            .join('/')
        })
      },
      // 获取详情
      async getDetail() {
        await this.countryList()
        orderEnquiryLookDetail({ businessId: this.enquiryId }).then((res) => {
          if (res?.code === '000000' && res?.data) {
            this.detail = res.data ? res.data : {}

            this.products = res.data?.orderEnquiryProductVOS?.map((item) => {
              return {
                productUrl: item.productUrl || this.emptyImg,
                standardCustomized: item.standardCustomized || '--',
                productCode: item.productCode || '--',
                productEn: item.productEn || '--',
                productOperation: item.productOperation || '--',
                productOperationEn: item.productOperationEn  || '--',
                specification: item.specification || '--',
                selectQuantity: item.selectQuantity || '--',
                printingWay: item.printingWay || '--',
                printingWayEn: item.printingWayEn || '--',
                waySelect: item.waySelect || '--',
                digitSelect: item.digitSelect,
                demand: item.demand || '--',
                shippingCountry: item.shippingCountry || '--',
                deliveryToTheDoor: item.deliveryToTheDoor || '--',
                zipCode: item.zipCode || '--',
                shippingAddress: item.shippingAddress || '--',
              }
            })
            this.fileList = res.data?.accessoryUrl
              ? JSON.parse(res.data?.accessoryUrl)
              : []
          }

          this.getDetailedAddress()
        })
      },

      // 获取报价记录信息
      getQuoteInfo(_list, _field) {
        if (_list?.length) {
          let _str = ''
          for (let i = 0; i < _list.length; i++) {
            _str += (_list[i][_field] || 0) + ', '
          }
          return _str.substring(0, _str.length - 2)
        } else {
          return '--'
        }
      },

      // 返回上一页
      goBack() {
        this.$router.push('/order/inquiryManage/inquiryList')
      },

      // Quote No.
      quote(code) {
        this.offerSheetInfo.priceSheetCode = code
        this.offerSheetVisible = true
      },

      // 打印报价单
      printOffer(code) {
        this.onPrint(code)
      },

      // 显示邮箱列表
      emailDrawer(email) {
        this.recordDrawer = true
        this.clientEmail = email
      },

      //询盘记录抽屉关闭
      recordDrawerClose() {
        this.recordDrawer = false
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .record-top {
    display: flex;
    justify-content: space-between;
    background: #f5f7fa;
    border: 1px solid #ebeef5;
    border-bottom: none;
    height: 34px;
    line-height: 34px;
    padding: 0 20px;

    span {
      margin-right: 20px;
      font-family: 'PingFang Bold';

      em {
        font-weight: 500;
        font-style: normal;
      }

      .link {
        color: #409eff;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  span.el-tag {
    padding: 0 !important;
    width: 70px !important;
    text-align: center;
  }

  .offer-container {
    height: 600px;
    overflow-y: auto;
  }

  .wrapper-box {
    text-align: center;
    position: relative;

    .wrapper-image {
      img {
        width: 64px;
        height: 64px;
        margin-top: 8px;
        border-radius: 2px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .el-badge {
      position: absolute;
      top: -5px;
      right: 15px;
      z-index: 9;
    }
  }

  .el-link {
    display: contents;
  }

  .product-image {
    position: relative;
    padding: 10px 0;

    img {
      width: 66px;
      height: 66px;
      display: flex;
      margin: 0 auto;
    }

    span {
      font-size: 12px;
      position: absolute;
      top: 5px;
      right: 15px;
      background: #e8f4ff;
      border: 1px solid #d1e9ff;
      color: #409eff;
      line-height: 16px;
    }
  }
</style>
