<template>
  <div class="table-container outbound">
    <el-row v-if="$route.query.noReturn !== 'true'">
      <el-page-header @back="goBack" content="确认出库"></el-page-header>
    </el-row>
    <h3 class="mt30 mb20">基本信息</h3>
    <div class="form-wrapper">
      <!-- 领料出库 typeIsLl-->
      <el-form ref="form" v-if="typeIsLl" key="typeIsLl" label-width="100px">
        <!-- 出库方式 -->
        <el-row>
          <el-col :span="6">
            <el-form-item label="出库方式" prop="type">
              <el-select v-model="currentType" value-key="type" disabled style="width: 215px">
                <el-option v-for="item in typeNames" :key="item.type" :value="item" :label="item.typeName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 出库仓库 -->
            <el-form-item label="出库仓库" prop="warehouseId" x>
              <el-select disabled style="width: 215px" v-model="form.warehouseId" value-key="type"
                :popper-append-to-body="false">
                <el-option :title="item.name" v-for="item in warehouses" :key="item.warehouseId"
                  :value="item.warehouseId" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 订单编号 -->
            <el-form-item label="订单编号" prop="orderNumber">
              <el-input v-model="orderNumber" placeholder="请输入" disabled style="width: 215px">
                <!-- <i
                  slot="suffix"
                  class="el-input__icon el-icon-search pointer"
                  @click="searchOrder"
                ></i> -->
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 加工单号 -->
            <el-form-item label="加工单号" prop="orderNumber">
              <el-input style="width: 215px" v-model="form.orderNumber" placeholder="请输入" disabled>
                <!-- <i
                  slot="suffix"
                  class="el-input__icon el-icon-search pointer"
                  @click="searchOrder"
                ></i> -->
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <!-- 客户代表 -->
            <el-form-item label="客户代表">
              <el-input v-model="form.businessName" disabled style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="creator">
              <span slot="label">创建人</span>
              <el-input disabled v-model="form.creator" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属地区">
              <el-input disabled v-model="form.creatorArea" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间" prop="createTime">
              <el-input disabled v-model="form.createTime" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item prop="remark">
              <span slot="label">操作人</span>
              <el-input disabled v-model="form.operationName" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="remark">
              <span slot="label">备注</span>
              <el-input style="width: 90%" type="textarea" :autosize="{ minRows: 2 }" disabled
                v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- 采购出库表单 -->
      <el-form ref="form" v-else-if="typeIsTh" key="typeIsTh" label-width="100px">
        <!-- 出库方式 -->
        <el-row>
          <el-col :span="6">
            <el-form-item label="出库方式" prop="type">
              <el-select v-model="currentType" value-key="type" disabled>
                <el-option v-for="item in typeNames" :key="item.type" :value="item" :label="item.typeName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 出库仓库 -->
            <el-form-item label="出库仓库" prop="warehouseId">
              <el-select disabled v-model="form.warehouseId" value-key="type" :popper-append-to-body="false">
                <el-option :title="item.name" v-for="item in warehouses" :key="item.warehouseId"
                  :value="item.warehouseId" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 订单编号 -->
            <el-form-item label="订单编号" prop="orderNumber">
              <el-input v-model="form.orderNumber" placeholder="请输入" style="width: 215px" disabled>
                <!-- <i
                  slot="suffix"
                  class="el-input__icon el-icon-search pointer"
                  @click="searchOrder"
                ></i> -->
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="预计出库时间">
              <el-input disabled v-model="form.expectOutDate"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item prop="creator">
              <span slot="label">创建人</span>
              <el-input disabled v-model="form.creator" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属地区">
              <el-input disabled v-model="form.creatorArea" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--  操作人 -->
            <el-form-item prop="operationName">
              <span slot="label">操作人</span>
              <el-input disabled v-model="form.operationName" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sapn="24">
            <el-form-item prop="remark">
              <span slot="label">备注</span>
              <el-input style="width: 90%" type="textarea" :autosize="{ minRows: 2 }" disabled
                v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 销售出库表单 -->

      <el-form ref="form" v-else-if="typeIsXS" label-width="100px" key="typeIsXS">
        <!-- 出库方式 -->
        <el-row>
          <el-col :span="6">
            <el-form-item label="出库方式" prop="type">
              <el-select v-model="currentType" value-key="type" disabled>
                <el-option v-for="item in typeNames" :key="item.type" :value="item" :label="item.typeName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 出库仓库 -->
            <el-form-item label="出库仓库" prop="warehouseId">
              <el-input v-model="form.warehouseName" value-key="type" disabled
                :popper-append-to-body="false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 订单编号 -->

            <el-form-item label="订单编号" prop="orderNumber">
              <el-input v-model="form.orderNumber" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 预计出库时间 -->
            <el-form-item label="预计出库时间">
              <el-input v-model="form.expectOutDate" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <!-- 客户代表 -->
            <el-form-item label="客户代表">
              <el-input v-model="form.businessName" disabled></el-input>
            </el-form-item>
          </el-col>
          <!-- 创建人 -->
          <el-col :span="6">
            <el-form-item label="创建人" prop="creator">
              <el-input disabled v-model="form.creator"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属地区" prop="area">
              <el-input disabled v-model="form.area"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间" prop="createTime">
              <el-input disabled v-model="form.createTime"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="操作人" prop="operationName">
              <el-input disabled v-model="form.operationName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sapn="24">
            <el-form-item prop="remark">
              <span slot="label">备注</span>
              <el-input type="textarea" :rows="2" disabled v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form ref="form" v-else-if="typeIsPd" label-width="100px" key="typeIsPd">
        <!-- 出库方式 -->
        <el-row>
          <el-col :span="6">
            <el-form-item label="出库方式" prop="type">
              <el-select v-model="currentType" value-key="type" disabled>
                <el-option v-for="item in typeNames" :key="item.type" :value="item" :label="item.typeName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 出库仓库 -->
            <el-form-item label="出库仓库" prop="warehouseId">
              <el-input v-model="form.warehouseName" value-key="type" disabled
                :popper-append-to-body="false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 订单编号 -->
            <el-form-item label="盘点单号" prop="orderNumber">
              <el-input v-model="form.orderNumber" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 预计出库时间 -->
            <el-form-item label="预计出库时间">
              <el-input v-model="form.expectOutDate" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <!-- 客户代表 -->
            <el-form-item label="客户代表">
              <el-input v-model="form.businessName" disabled></el-input>
            </el-form-item>
          </el-col>
          <!-- 创建人 -->
          <el-col :span="6">
            <el-form-item label="创建人" prop="creator">
              <el-input disabled v-model="form.creator"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属地区" prop="area">
              <el-input disabled v-model="form.area"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间" prop="createTime">
              <el-input disabled v-model="form.createTime"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="操作人" prop="operationName">
              <el-input disabled v-model="form.operationName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sapn="24">
            <el-form-item prop="remark">
              <span slot="label">备注</span>
              <el-input type="textarea" :rows="2" disabled v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 入库产品 -->
    <h3 class="mt30 mb20">出库产品</h3>

    <el-table border :data="tableData" class="mt20" :span-method="objectSpanMethod" :max-height="400">
      <el-table-column align="center" prop="skuId" label="产品编码">
        <template slot-scope="scope">
          <el-link type="priamry" :underline="false" @click="skuIdClick(scope.row)">
            {{
            scope.row.childProduct && scope.row.childProduct.length > 0
            ? scope.row.parentSku
            : scope.row.skuId
            }}
          </el-link>
          <popoverShipClick :detailId="scope.row" v-if="scope.row.mainFlag != null" :itemTitle="
              scope.row.combinationFlag == 1
                ? '主'
                : scope.row.combinationFlag == 0 && scope.row.mainFlag == 0
                ? '子'
                : ''
            "></popoverShipClick>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="parentName" label="产品名称">
        <template slot-scope="scope">
          <span>
            {{
            scope.row.childProduct && scope.row.childProduct.length > 0
            ? scope.row.parentName
            : scope.row.productName || '-'
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="parentSpecsValue" label="规格">
        <template slot-scope="scope">
          {{
          scope.row.childProduct && scope.row.childProduct.length > 0
          ? scope.row.parentSpecsValue
          : scope.row.productSpec || '-'
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型"></el-table-column>
      <el-table-column align="center" prop="expectOutNum" label="申请出库数量/单位" v-if="typeIsXS || typeIsLl">
        <template slot-scope="scope">
          {{ scope.row.expectOutNum ? scope.row.expectOutNum : '-' }}/{{
          scope.row.childProduct && scope.row.childProduct.length > 0
          ? scope.row.parentStandardCustomized
          : scope.row.productUnit || '-'
          }}
        </template>
      </el-table-column>
      <!-- V1.6.1入库批次 -->
      <el-table-column align="center" prop="lotNo" label="入库批次">
        <template slot-scope="scope">
          <span v-if="scope.row.lotNo">{{ scope.row.lotNo }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <!-- V1.6.1采购单号 -->
      <el-table-column align="center" prop="purchaseNumber" label="采购单号">
        <template slot-scope="scope">
          <span v-if="scope.row.purchaseNumber">
            {{ scope.row.purchaseNumber }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <!-- V1.6.1采购开发 -->
      <el-table-column align="center" prop="buyer" label="采购开发">
        <template slot-scope="scope">
          <span v-if="scope.row.buyer">
            {{ scope.row.buyer }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="160" prop="num">
        <template slot="header" slot-scope="scope">
          <span class="red">*</span>
          <span>批次出库数量</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top-start">
            <el-input v-model="scope.row.remark" placeholder="请输入" @change="changeRemark(scope.row)"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请输入" v-else @change="changeRemark(scope.row)"></el-input>
          <p v-if=" scope.row.remark && scope.row.remark.length> 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <span style="color: red">*</span>
          <span>出库库位</span>
        </template>
        <template slot-scope="scope">
          <!-- 盘亏出库 -->
          <div v-if="typeIsPd">
            <div>
              <span style="display: none">{{ scope.row }}</span>
              <SelectedLocs :locs="scope.row.warehouseOutProductLocationVOList" @add-loc="showLoc"
                :tableIndex="scope.$index" />
            </div>
          </div>
          <!-- 其他 -->
          <div v-else>
            <div v-if="scope.row.combinationFlag == 0">
              <div v-if="ruleType !== '1'">
                {{ ruleType === '3' ? '从少到多自动扣减' : '从多到少自动扣减' }}
              </div>
              <div v-else>
                <span style="display: none">{{ scope.row }}</span>
                <SelectedLocs :locs="scope.row.warehouseOutProductLocationDTOList" @add-loc="addLoc"
                  :tableIndex="scope.$index" />
              </div>
            </div>
            <div class="page-link" style="text-decoration: none" v-if="scope.row.combinationFlag == 1"
              @click="setStock(scope.row, scope.$index)">
              请设置
            </div>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next,total" :total="total" :current-page.sync="pageNo"
      :page-size="pageSize" @current-change="handleCurrentChange" v-if="typeIsPd">
    </el-pagination>
    <h3 class="mt30 mb20">相关附件</h3>
    <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList" @initUpload="initUpload"
      style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"></ErpUpload>
    <p class="divider"></p>
    <div style="text-align: center; position: sticky; bottom: 0">
      <el-button @click="backCancel" class="ml15">取消</el-button>
      <el-button type="primary" @click="backSheet" v-if="!typeIsPd"
        style="border-color: #ff5757; color: #ff5757; background-color: #fff">
        驳回
      </el-button>
      <el-button type="primary" @click="onSubmit" :loading="isLoading">
        确认
      </el-button>
    </div>

    <Loc ref="loc" :params="locParams" :warehouseOutNum="currentRow.warehouseOutNum" :warehouseId="form.warehouseId"
      @set-loc="onSetLoc" @close="onClose('loc')"></Loc>
    <stockBom ref="stockBom" @setStock="setChildStock" @stockSubmit="stockSubmit" :warehouseId="form.warehouseId"
      :ruleType="ruleType"></stockBom>

    <!-- 驳回 -->
    <el-dialog title="驳回原因" :visible.sync="showReject" width="390px" show-close>
      <el-input type="textarea" maxlength="500" :rows="4" v-model="reason" placeholder="填写驳回原因,500字以内"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReject = false">取 消</el-button>
        <el-button type="primary" @click="confirmReject">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 出库库位详情弹框 -->
    <LocDetail ref="loc-detail" :tableData="locDetailTableData"></LocDetail>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProductDialg from './components/product.vue'
  import SelectedLocs from '../inbound-sheet/components/selected-locs.vue'
  import _ from 'lodash'
  import Loc from './components/loc.vue'
  import globalBackButtonMixin from '@/utils/global-back-button'
  import { tableMixin } from '../helper/mixin'
  import popoverShip from './components/popoverShip.vue'
  import popoverShipClick from './components/popoverShipClick.vue'
  import stockBom from './components/stockBom.vue'
  import LocDetail from '../inbound-sheet/components/base-loc-detail.vue' // 出库库位详情弹框

  import { OutboundSheetInteractor, ProductListInteractor } from '@/core'
  const baseProduct = {
    skuId: '',
    productName: '',
    productSpec: '',
    productType: '',
    productUnit: '',
    contractNum: '',
    giftNum: '',
    totalPrice: '0.000',
    price: '',
    warehouseOutNum: '',
    remark: '',
    warehouseOutProductLocationDTOList: [], //入库库位信息
    stockNum: 0, //库存数量
  }

  let self
  export default {
    name: 'new-inbound-sheet',
    components: {
      ProductDialg,
      Loc,
      SelectedLocs,
      popoverShip,
      stockBom,
      popoverShipClick,
      LocDetail,
    },
    data() {
      self = this
      return {
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        showReject: false,
        reason: '',
        form: {
          area: '',
          date: '',
          businessName: '',
          businessId: '',
          supplierName: '',
          type: 4, //出库方式code
          typeName: '', // 出库方式名称
          warehouseId: '', //出库仓库
          operationId: '',
          operationName: '',
          remark: '',
          price: '0.00',
          warehouseOutId: '',
          orderNumber: '',
          customerName: '',
          expectOutDate: '',
          warehouseOutNumber: '',
          warehouseName: '',
        },

        selectedPro: [], //已选择产品
        rules: {
          type: [
            {
              required: true,
              message: '请选择出库方式',
            },
          ],
          warehouseId: [
            {
              required: true,
              message: '请选择出库仓库',
            },
          ],
          orderNumber: [
            {
              required: true,
              message: '请输入订单编号',
            },
          ],
          remark: [
            {
              required: true,
              message: '请输入备注',
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                if (value && value.length > 500) {
                  return callback(new Error('备注长度不能超过500'))
                }
                callback()
              },
            },
          ],


        },
        isLoading: false,
        operation: {}, //登录人
        typeNames: [
          {
            type: 3,
            typeName: '销售出库',
          },
          {
            type: 4,
            typeName: '退货出库',
          },
          {
            type: 5,
            typeName: '其他出库',
          },
          {
            type: 12,
            typeName: '调拨出库',
          },
          {
            type: 13,
            typeName: '领料出库',
          },
          {
            type: 16,
            typeName: '盘亏出库',
          },
        ], //出库方式
        currentType: {}, //选中出库方式
        warehouses: [], //仓库列表
        params: {
          show: false,
          warehouseName: '',
        },
        locParams: {
          show: false,
          locs: [],
        },
        tableData: [],
        currentRow: {},
        files: '',
        showError: false,
        ruleType: '',

        // 改造后的data数据
        stockBomData: [],
        currentIndex: 0,
        productIndex: 0,
        _tableData: [],
        locDetailTableData: [], // 出库库位详情弹框-表格数据
        total: 0, //总页码
        pageSize: 200, //分页数量
        pageNo: 1, //第几页
        initTableData: [], //初始数据
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),

      tenantId() {
        return this.userInfo.tenantId
      },
      typeIsTh() {
        return this.form.type === 4
      },
      typeIsXS() {
        //销售出库
        return this.form.type === 3
      },
      typeIsQt() {
        return this.form.type === 5
      },
      // 领料出库
      typeIsLl() {
        return this.form.type === 13
      },
      typeIsPd() {
        return this.form.type === 16
      },
      isEdit() {
        return !!this.$route.query.warehouseOutId
      },
      fileNames() {
        return this.files.map((f) => f.name).join(',')
      },
      fileUrls() {
        return this.files.map((f) => f.url).join(',')
      },
      tableThText() {
        if (this.typeIsTh) {
          return '订单待出库数量'
        } else {
          return '合同数量'
        }
      },
      orderNumber() {
        if (this.typeIsLl) {
          return this.tableData[0]?.salesOrderNumber
        }
      },
    },
    mixins: [globalBackButtonMixin, tableMixin],
    watch: {
      currentType: {
        deep: true,
        handler(newval, oldval) {
          const { type, typeName } = (newval || {})

          this.form.type = type
          this.form.typeName = typeName
          if (type === 3 || type === 4) {
            this.rules.remark[0].required = false
            this.rules.orderNumber[0].required = true
            if (type === 3) {
              this.rules.orderNumber[0].message = '请输入采购单号'
            } else {
              this.rules.orderNumber[0].message = '请输入订单编号'
            }
          } else {
            this.rules.remark[0].required = true
            this.rules.orderNumber[0].required = false
          }
          if (this.isEdit) {
            if (this.isReset) {
              this.resetFormAndTableDataWhenTypeChanged()
            }
          } else {
            this.resetFormAndTableDataWhenTypeChanged()
          }

          if (!oldval.typeName) {
            return
          }
          if (this.isReset === false) {
            this.isReset = true
          }
        },
      },
      'form.warehouseId': function (newval, oldval) {
        if (this.isEdit) {
          if (this.isResetWhenWhChanged) {
            this.resetFormAndTableDataWhenWHChanged()
          }
        } else {
          this.resetFormAndTableDataWhenWHChanged()
        }

        if (this.isResetWhenWhChanged === false) {
          this.isResetWhenWhChanged = true
        }
      },
    },
    async created() {
      this.initData()
      let res_1 = await OutboundSheetInteractor.findWarehouseList(0)
      this.warehouses = res_1?.data || []

      if (this.isEdit) {
        const { warehouseOutId } = this.$route.query
        this.isReset = false
        this.isResetWhenWhChanged = false
        let res_2 = await OutboundSheetInteractor.getDetail(warehouseOutId)
        if (res_2.code == '000000') {
          if (res_2.data.fileUrls) {
            res_2.data.originFileUrls = res_2.data.fileUrls
            res_2.data.originFileNames = res_2.data.fileNames
          }
          let whName = res_2.data.warehouseName
          let res = await OutboundSheetInteractor.warehouseSetListPage(4)
          if (res && res.code === '000000') {
            this.settingRules = res.data || []

            let targetRule = this.settingRules.find(
              (r) => r.warehouseName.indexOf(whName) !== -1
            )
            if (targetRule) {
              // '3' 从少到多
              // '2' 从多到少
              // '1' 手动设置
              this.ruleType = targetRule.value
            } else {
              this.ruleType = '1'
            }
          }
          if (res_2?.data?.type == 16) {
            return this.getTableData(res_2)
          }
          this.form = { ...this.form, ...res_2.data }
          Array.isArray(res_2.data.warehouseOutProductVOList) &&
            (this.tableData = res_2.data.warehouseOutProductVOList.map(
              (item) => {
                item.warehouseOutProductLocationDTOList =
                  item.warehouseOutProductLocationVOList || []
                // 测试1.6.1 添加批次list结束

                delete item.warehouseOutProductLocationVOList
                return item
              }
            ))
          this.tableData = this.tableData.map((item) => {
            // 是子产品。将父产品信息放到子产品中
            if (item.mainFlag == 0) {
              let obj = {
                productName: item.parentName,
                parentProductUnit: item.parentProductUnit,
                skuId: item.parentSku,
                parentSpecsValue: item.parentSpecsValue,
                parentStandardCustomized: item.parentStandardCustomized,
                parentUrl: item.parentUrl,
                numCombination: item.numCombination,
              }
              return {
                ...item,
                parentProduct: [obj],
              }
            } else {
              return item
            }
          })
          // 表格合并前数据处理 按照一个批次一条数据

          this.tableData = this.formatTableData(this.tableData)
          this.tableData = this.tableData.map((item) => {
            item.warehouseOutLotVOList = [
              {
                adjustTotalPriceL: item.adjustTotalPrice,
                buyer: item.buyer,
                buyerId: item.buyer,
                lotNo: item.lotNo,
                num: item.num,
                price: item.price,
                purchaseNumber: item.purchaseNumber,
                skuId: item.skuId,
                stockLotId: item.stockLotId,
                tenantId: item.tenantId,
                totalPrice: item.totalPrice,
                warehouseId: item.warehouseId,
                warehouseOutLotId: item.warehouseOutLotId,
                warehouseOutProductId: item.warehouseOutProductId,
              },
            ]
            return item
          })

          this.getSpanArr(this.tableData)

          self.currentType = self.typeNames.find(
            (item) => item.type == res_2.data.type
          )

          this.files = res_2.data.fileUrls
          // 将带过来的附件打标记
          if (res_2.data.originFileUrls) {
            let originFileUrls = JSON.parse(res_2.data.originFileUrls)
            originFileUrls = originFileUrls.map((file) => {
              file.isOrign = true
              return file
            })
            this.initUpload(JSON.stringify(originFileUrls), false)
          }
        }
      }
    },
    methods: {
      changeRemark(product) {
        let { skuId, orderProductUniqueId, mainFlag, parentSku } = product
        if (this.typeIsPd) {
          for (let i = 0, len = this.initTableData.length; i < len; i++) {
            let pro = this.initTableData[i]
            if (`${pro.skuId}${pro.orderProductUniqueId}${pro.mainFlag}${pro.parentSku}` === `${skuId}${orderProductUniqueId}${mainFlag}${parentSku}`) {
              pro.remark = product.remark
            }
          }
        }
      },
      getTableHeight() {
        try {
          let heigth = getRemainingHeight(['file-wrapper', 'base-info2', 'el-pagination', 'return'], '', 170)
          this.tableHeight = heigth
        } catch (err) {
          this.tableHeight = 300
        }
      },
      handleCurrentChange(val) {
        this.pageNo = val
        this.getPaginationData()
      },
      getPaginationData() {
        let initTableData = this.initTableData,
          pageNo = this.pageNo,
          pageSize = this.pageSize
        // 确保页码不小于1
        pageNo = Math.max(pageNo, 1);
        // 计算起始索引
        const startIndex = (pageNo - 1) * pageSize;
        // 计算结束索引，注意JavaScript数组索引是从0开始的
        const endIndex = startIndex + pageSize;

        // 获取指定页的数据
        let tableData = initTableData.slice(startIndex, endIndex);


        // 表格合并前数据处理 按照一个批次一条数据

        tableData = this.formatTableData(tableData)
        this.tableData = tableData.map((item) => {
          item.warehouseOutLotVOList = [
            {
              adjustTotalPriceL: item.adjustTotalPrice,
              buyer: item.buyer,
              buyerId: item.buyer,
              lotNo: item.lotNo,
              num: item.num,
              price: item.price,
              purchaseNumber: item.purchaseNumber,
              skuId: item.skuId,
              stockLotId: item.stockLotId,
              tenantId: item.tenantId,
              totalPrice: item.totalPrice,
              warehouseId: item.warehouseId,
              warehouseOutLotId: item.warehouseOutLotId,
              warehouseOutProductId: item.warehouseOutProductId,
            },
          ]
          return item
        })

        this.pos = 0
        this.spanArr = []
        this.getSpanArr(this.tableData)
      },
      getTableData(res_2) {
        let tableData = []
        let warehouseOutProductVOList = res_2?.data?.warehouseOutProductVOList || []
        if (res_2.data.warehouseOutProductVOList) {
          delete res_2.data.warehouseOutProductVOList
        }

        this.form = { ...this.form, ...res_2.data }
        Array.isArray(warehouseOutProductVOList) &&
          (tableData = warehouseOutProductVOList.map(
            (item) => {
              item.warehouseOutProductLocationDTOList =
                item.warehouseOutProductLocationVOList || []
              // 测试1.6.1 添加批次list结束

              delete item.warehouseOutProductLocationVOList
              return item
            }
          ))
        tableData = tableData.map((item) => {
          // 是子产品。将父产品信息放到子产品中
          if (item.mainFlag == 0) {
            let obj = {
              productName: item.parentName,
              parentProductUnit: item.parentProductUnit,
              skuId: item.parentSku,
              parentSpecsValue: item.parentSpecsValue,
              parentStandardCustomized: item.parentStandardCustomized,
              parentUrl: item.parentUrl,
              numCombination: item.numCombination,
            }
            return {
              ...item,
              parentProduct: [obj],
            }
          } else {
            return item
          }
        })
        this.initTableData = tableData
        this.total = this.initTableData.length
        this.getPaginationData()


        self.currentType = self.typeNames.find(
          (item) => item.type == res_2.data.type
        )

        this.files = res_2.data.fileUrls
        // 将带过来的附件打标记
        if (res_2.data.originFileUrls) {
          let originFileUrls = JSON.parse(res_2.data.originFileUrls)
          originFileUrls = originFileUrls.map((file) => {
            file.isOrign = true
            return file
          })
          this.initUpload(JSON.stringify(originFileUrls), false)
        }
      },

      backCancel() {
        this.$router.push({
          path: '/stock-center/outbound-sheet/outbound-sheet',
        })
      },
      get2TypeFiles() {
        let files = {
          originFileNames: '',
          originFileUrls: '',
          fileUrls: '',
          fileNames: '',
        }
        if (this.files) {
          let filesObj = JSON.parse(this.files)
          let originFileUrls = JSON.stringify(
            filesObj.filter((file) => file.isOrign)
          )
          let fileUrls = JSON.stringify(
            filesObj.filter((file) => !file.isOrign)
          )

          files.fileUrls = fileUrls
          files.originFileUrls = originFileUrls
        }

        return files
      },
      //产品编码click
      skuIdClick(val) {
        let skuId = ''
        if (val.childProduct && val.childProduct.length > 0) {
          skuId = val.parentSku
        } else {
          skuId = val.skuId
        }
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        if (id.indexOf('FL') > -1) return
        let response = await ProductListInteractor.productIdByCode(id)
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },
      goBack() {
        this.$router.push('/stock-center/outbound-sheet/outbound-sheet')
      },
      checkEmpty(row, field) {
        if (
          row[field] === 0 ||
          row[field] === '' ||
          typeof row[field] === 'undefined'
        ) {
          if (!row[field + 'emptyCheck']) {
            this.$set(row, [field + 'EmptyCheck'], true)
          }
        }
      },
      // 提交时产品校验
      $_checkProduct() {
        let tableData = [...this._tableData]
        tableData.forEach((item) => {
          if (!item['warehouseOutNumEmptyCheck']) {
            this.$set(item, 'warehouseOutNumEmptyCheck', true)
          }
          if (!item['priceEmptyCheck']) {
            this.$set(item, 'priceEmptyCheck', true)
          }
        })

        return !!this._tableData.find((item) => !item.warehouseOutNum)
      },
      // 校验库位选择
      $_checkEmptyLoc() {
        let checResult = true
        if (this.form.type == 16) { // 盘亏出库不校验
          return checResult
        }
        let tableData = this.tableData
        for (let i = 0, l = tableData.length; i < l; i++) {
          let pro = tableData[i]
          let { warehouseOutProductLocationDTOList } = pro
          if (warehouseOutProductLocationDTOList.length === 0) {
            //step01 校验是否选择出库库位
            checResult = false
            this.$message.warning(`请选择产品 ${pro.productName}的出库库位`)
            return checResult
          }
        }
        return checResult
      },
      // 实际出库数量，备用品数量校验
      $_checkCount() {
        let checResult = true
        if (this.form.type == 16) {
          return checResult
        }
        let tableData = this._tableData
        for (let i = 0, l = tableData.length; i < l; i++) {
          let pro = tableData[i]
          let {
            warehouseOutProductLocationDTOList,
            warehouseOutNum,
            giftCount,
            giftNum,
            remark,
          } = pro

          if (warehouseOutProductLocationDTOList.length === 0) {
            //step01 校验是否选择出库库位
            checResult = false
            this.$message.warning(`请选择产品 ${pro.productName}的出库库位`)
            break
          } else {
            const _ = (obj) =>
              `${obj.skuId}${obj.mainFlag}${obj.parentSku}${obj.orderProductUniqueId}`
            //step02 校验实际出库数和库位选择总数是否相同
            // 获取产品sku
            const skuList = [...new Set(tableData.map((i) => _(i)))]
            for (let k = 0; k < skuList.length; k++) {
              const { warehouseOutNum, productName } = tableData.find(
                (item) => _(item) === skuList[k]
              )
              const lotNumberTotal = tableData
                .filter((j) => _(j) === skuList[k])
                .map((s) => s.num)
                .reduce((prev, next) => {
                  prev += next
                  return prev
                }, 0)

              if (warehouseOutNum !== lotNumberTotal) {
                this.$message.warning(
                  `产品${productName}的实际出库数量不等于批次出库数量`
                )
                checResult = false
                break
              }
            }
            //step03 备用品数量校验
            if (giftNum > giftCount) {
              this.$message.warning(
                `产品 ${pro.productName}的备用品数量校验${giftNum}不能大于备用品实际数量${giftCount}`
              )
              checResult = false
              break
            }
            // step04备注长度校验
            if (remark && remark.length > 500) {
              checResult = false
              break
            }
          }
          return checResult
        }

        return checResult
      },
      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },
      onChangewarehouseOutNum(row, e) {
        const { stockNum, warehouseOutNum, lockNum } = row
        if (stockNum - lockNum < warehouseOutNum) {
          this.$message.warning('库存不足')
          row.warehouseOutNum = 0
          event.target.value = ''
        }
        this.onChangePriceExcludingTax(row)
      },
      toFixed2(val) {
        if (!val && val !== 0) {
          return '0.00'
        } else {
          return (val * 1).toFixed(3)
        }
      },
      onChangePriceExcludingTax(row) {
        row.totalPrice = (row.price * row.warehouseOutNum).toFixed(3)
      },
      async searchPurchaseOrder() {
        let { orderNumber } = this.form
        if (!orderNumber) {
          return this.$message.warning('请输入采购单号!')
        }
        let res = await OutboundSheetInteractor.getDetailForShowByNumber(
          orderNumber
        )
        if (res && res.code === '000000') {
          this.form.supplierId = res.data.supplierId
          this.form.supplierName = res.data.supplierName
          let purchaseProductForStorageVOList =
            res.data.purchaseProductForStorageVOList.filter(
              (item) => item.standardCustomized === '标准品'
            )
          this.execPurchaseOrderData(purchaseProductForStorageVOList || [])
        } else {
          this.form.orderNumber = ''
        }
      },
      async getPrice(product) {
        if (!product || product.length === 0) {
          return
        }
        const skuIds = product.map((p) => p.skuId).join(',')
        let res = await OutboundSheetInteractor.listStockPriceBySkuIds({
          warehouseId: this.form.warehouseId,
          skuIds,
        })
        if (res && res.code === '000000') {
          res.data.forEach((item, index) => {
            product[index].price = item
          })
        }
      },
      async searchOrder() {
        const { orderNumber, warehouseId } = this.form
        if (!orderNumber) {
          return this.$message.warning('请输入订单编号!')
        }
        if (!warehouseId) {
          return this.$message.warning('请选择仓库!')
        }
        let res = await OutboundSheetInteractor.getDetailByOrderCode(
          orderNumber,
          1
        )
        if (res && res.code === '000000') {
          let d = res.data
          if (d[0]) {
            // 后端返回数据结构改变，相关字段放在产品同级
            this.form.businessId = d[0].businessId
            this.form.businessName = d[0].businessName
            this.form.customerName = d[0].customerName
          }

          let orderProductVOS = res.data.filter(
            (i) => i.standardCustomized === '标准品'
          )
          // 解析生成tableData
          this.execOrderData(orderProductVOS || [])
          // 获取单价
        } else {
          this.form.orderNumber = ''
        }
      },

      execOrderData(productList) {
        const tableData = []
        productList.forEach((item) => {
          let _basePro = { ...baseProduct, ...item }
          _basePro.skuId = item.sku
          _basePro.productName = item.productName
          _basePro.productSpec = item.specification
          _basePro.productType = item.productCategory
          _basePro.contractNum = item.amount
          _basePro.productImage = item.rendingsUrl
          _basePro.productCode = item.productCode
          _basePro.salesOrderId = _basePro.orderId
          _basePro.salesOrderNumber = this.form.orderNumber
          tableData.push(_basePro)
        })
        this.tableData = tableData
      },

      execPurchaseOrderData(productList) {
        const tableData = []
        productList.forEach((item) => {
          let _basePro = { ...baseProduct, ...item }
          _basePro.skuId = item.sku
          _basePro.price = item.priceExcludingTax
          _basePro.productName = item.nameCn
          _basePro.productSpec = item.specsValue1
          _basePro.productType = item.standardCustomized
          _basePro.contractNum = item.numPurchase + item.numRelax
          _basePro.productSpec = item.specsValue1
          _basePro.priceExcludingTax = item.priceExcludingTax
          _basePro.priceIncludingTax = item.priceIncludingTax
          _basePro.productImage = item.imgMain
          _basePro.productCode = item.productCode
          tableData.push(_basePro)
        })
        this.tableData = tableData
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }

          let summayIndexs

          if (this.typeIsTh || this.typeIsXS) {
            summayIndexs = [5, 6, 7, 8]
          } else {
            summayIndexs = [5, 6, 7, 8, 9]
          }

          if (!summayIndexs.includes(index)) {
            sums[index] = ''
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            if (index === 5 && sums[index] === 0) {
              sums[index] = '--'
            }
            if (this.typeIsQt) {
              if (index === 9) {
                sums[index] = this.toFixed3(sums[index])
              }
            }
          }
        })

        return sums
      },
      deletePro(index) {
        this.tableData.splice(index, 1)
      },
      onSetLoc(locs, totolCounts) {
        // this.$refs.stockBom.gridData[this.currentIndex].outLoc = locs
        locs = locs.map((loc) => {
          let obj = {}
          obj.locationNumber = loc.locationNumber
          obj.num = loc.outNum
          obj.skuId = this.currentRow.skuId
          obj.warehouseId = this.form.warehouseId
          obj.warehouseOutLotId = this.currentRow.warehouseOutLotId
          obj.warehouseOutProductId = this.currentRow.warehouseOutProductId
          obj.warehouseOutProductLocationId = loc.warehouseLocationId
          return obj
        })

        this.currentRow.warehouseOutProductLocationDTOList = locs
        // this.$set(this.currentRow, 'warehouseOutProductLocationDTOList', locs)
        // this.currentRow.warehouseOutNum = totolCounts
        // this.currentRow.giftNum = giftCount

        this.onChangewarehouseOutNum(this.currentRow)
      },
      //
      onOutNumChange(count, index) {
        const obj = {
          ...this.tableData[index],
          // warehouseOutNum: count,
        }
        this.tableData.splice(index, 1, obj)
        this.onChangePriceExcludingTax(this.currentRow)
      },
      async addLoc(index) {
        // 添加orderProductUniqueId
        let row = this.tableData[index]
        let { warehouseOutProductLocationDTOList } = row
        const {
          skuId,
          warehouseOutNum,
          giftNum,
          usable,
          lotNo,
          stockLotId,
          num, //批次出库数量
          orderProductUniqueId,
        } = row,
          // ,
          { warehouseId } = this.form
        // if (!warehouseId) {
        //   return this.$message.warning('请选择仓库')
        // }

        if (!warehouseOutNum && warehouseOutNum !== 0) {
          return this.$message.warning('请输入实际出库数量')
        }
        // this.$refs.loc.giftCount = giftNum
        // this.$refs.loc.activeName = '1'

        let res = await OutboundSheetInteractor.listWarehouseLocationUsableNum({
          skuId,
          warehouseId,
          usable,
          flag: 1,
          lotNo,
          stockLotId,
          orderProductUniqueId,
        })
        if (res && res.code === '000000') {
          res.data.forEach((item) => {
            item.outNum = 0
          })

          // 回显数据
          res.data = res.data.map((item) => {
            let loc = warehouseOutProductLocationDTOList.find(
              (loc) => loc.locationNumber === item.locationNumber
            )
            if (loc) {
              item = { ...item, outNum: loc.num }
            }
            return item
          })
          this.locParams.locs = res.data
          this.locParams.index = index
          this.locParams.expectOutNum = row.expectOutNum
          this.locParams.isConfirm = true
          this.locParams.lotNumber = num //入库批次数量
          this.currentRow = row
          this.locParams.show = true
        }
      },
      // 盘亏出库-点击出库库位查看详情
      showLoc(index) {
        const row = this.tableData[index]
        this.$refs['loc-detail'].show()
        this.locDetailTableData = row?.warehouseOutProductLocationVOList || []
      },
      onClose(isLoc) {
        if (isLoc) {
          return (this.locParams.show = false)
        }
        this.params.show = false
      },
      selectPro(val) {
        val.forEach((item) => {
          if (!this.tableData.find((pro) => pro.skuId === item.skuId)) {
            this.tableData.push({
              ...baseProduct,
              ...item,
              stockNum: item.num,
              price: 0,
            })
          }
        })
      },

      //审核模板返回数据-auditData
      async saveAuditClick(auditData) {
        auditData.orderId = this.$route.query.warehouseOutId //单据id
        const { type, typeName } = this.currentType

        const requestData = {
          originFileUrls: this.form.originFileUrls,
          fileUrls: files,
          operationId: this.form.operationId,
          operationName: this.form.operationName,
          orderNumber: this.form.orderNumber,
          remark: this.form.remark,
          supplierName: this.form.supplierName,
          type,
          typeName,
          warehouseId: this.form.warehouseId,
          warehouseName: this.warehouses.find(
            (item) => this.form.warehouseId === item.warehouseId
          ).name,
          warehouseOutId: this.form.warehouseOutId,
          warehouseOutProductDTOList: this.tableData,
          createWay: 1, //
          // auditObjectDTO: auditData,
          area: this.userInfo.area,
        }
        if (this.isEdit) {
          //处理编辑任务参数
          requestData.warehouseOutProductDTOList &&
            requestData.warehouseOutProductDTOList.forEach((item) => {
              item.warehouseOutProductLocationDTOList.forEach((j) => {
                j.warehouseInProductId = item.warehouseInProductId
                j.warehouseId = requestData.warehouseId
                j.skuId = item.skuId
              })
            })
          // 有子产品  将子产品数据提出来到父产品同一级
          // let arr = []
          // requestData.warehouseOutProductDTOList.forEach((item) => {
          //   if (item.childProduct?.length > 0) {
          //     arr.push(item.childProduct)
          //   }
          // })
          // requestData.warehouseOutProductDTOList.concat(arr)

          requestData.warehouseOutNumber = this.form.warehouseOutNumber
        }
        let res = await OutboundSheetInteractor.ensure(requestData)
        if (res && res.code === '000000') {
          this.$message.success('确认出库成功')
          this.$router.push({
            path: '/stock-center/outbound-sheet/outbound-sheet',
          })
        }
      },
      backSheet() {
        this.showReject = true
      },
      async confirmReject() {
        if (!this.reason || this.reason.trim() === '') {
          this.$message.warning('请输入驳回原因')
        } else {
          let res = await OutboundSheetInteractor.reject({
            reason: this.reason,
            warehouseOutId: this.form.warehouseOutId,
          })
          if (res && res.code === '000000') {
            this.$message.success('已驳回')

            this.$router.push({
              path: '/stock-center/outbound-sheet/outbound-sheet',
            })
          }
        }
      },
      async onSubmit() {
        let flag = false
        let arr = []
        let tableData = this.typeIsPd ? this.initTableData : this.tableData
        tableData.forEach((item) => {
          if (item.childProduct?.length > 0) {
            arr.push(...item.childProduct)
          } else {
            arr.push(item)
          }
        })
        this._tableData = [...arr]

        if (!this._tableData || this._tableData.length === 0) {
          return this.$message.warning('请选择产品')
        }

        if (this.$_checkProduct()) {
          return
        }
        if (this.ruleType === '1') {
          if (!this.$_checkEmptyLoc()) {
            return
          }
          if (!this.$_checkCount()) {
            return
          }
        }

        this.isLoading = true
        const requestData = {
          operationId: this.form.operationId,
          operationName: this.form.operationName,
          orderNumber: this.form.orderNumber,
          remark: this.form.remark,
          supplierName: this.form.supplierName,
          type: this.form.type,
          typeName: this.form.typeName,
          warehouseId: this.form.warehouseId,
          warehouseName: this.warehouses.find(
            (item) => this.form.warehouseId === item.warehouseId
          ).name,
          warehouseOutId: this.form.warehouseOutId,
          warehouseOutProductDTOList: this._tableData,
          createWay: 1,

          area: this.userInfo.area,
          ...this.get2TypeFiles(),
        }
        if (this.isEdit) {
          //处理编辑任务参数
          requestData.warehouseOutProductDTOList &&
            requestData.warehouseOutProductDTOList.forEach((item) => {
              item.warehouseOutProductLocationDTOList.forEach((j) => {
                j.warehouseInProductId = item.warehouseInProductId
                j.warehouseId = requestData.warehouseId
                j.skuId = item.skuId
              })
            })

          requestData.warehouseOutNumber = this.form.warehouseOutNumber
        }
        let res = await OutboundSheetInteractor.ensure(requestData)
        this.isLoading = false
        if (res && res.code === '111111') {
          this.$confirm(`出运数量调整,请重新出库`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          return
        }
        if (res && res.code === '000000') {
          this.$message.success('确认出库成功')
          // this.$router.back()
          this.$router.push({
            path: '/stock-center/outbound-sheet/outbound-sheet',
          })
        }
      },
      initData() {
        this.form.operationName = this.userInfo.userName
        this.form.operationId = this.form.operationId
        this.form.creatorArea = this.userInfo.area
        this.form.creator = this.userInfo.userName
      },
      resetFormAndTableDataWhenTypeChanged() {
        Reflect.ownKeys(this.form).forEach((key) => {
          if (
            key !== 'type' &&
            key !== 'operationName' &&
            key != 'creatorArea' &&
            key != 'creator'
          ) {
            this.form[key] = ''
          }
        })
        this.tableData = []
      },
      // 切换出库时重置
      resetFormAndTableDataWhenWHChanged() {
        Reflect.ownKeys(this.form).forEach((key) => {
          if (
            key !== 'type' &&
            key !== 'operationName' &&
            key !== 'warehouseId' &&
            key != 'creatorArea' &&
            key != 'creator'
          ) {
            this.form[key] = ''
          }
        })
        this.tableData = []
      },
      initUpload(data, showClose) {
        this.$refs.ErpUpload.initUpload(data, showClose)
      },
      setStock(val, index) {
        this.productIndex = index
        this.$refs.stockBom.showDialog()
        let arr = [...val.childProduct]
        arr = arr.map((item) => {
          item.warehouseOutProductLocationDTOList =
            item.warehouseOutProductLocationVOList
          delete item.warehouseOutProductLocationVOList
          return {
            ...item,
          }
        })
        this.$refs.stockBom.gridData = arr
      },
      async setChildStock(val, index) {
        this.currentIndex = index
        let obj = {
          skuId: val.skuId,
          warehouseId: this.form.warehouseId,
          usable: val.usable,
          flag: 1,
        }
        let res = await OutboundSheetInteractor.listWarehouseLocationUsableNum(
          obj
        )
        if (res && res.code === '000000') {
          res.data.forEach((item) => {
            item.outNum = 0
          })
          this.locParams.locs = res.data
          // this.locParams.index = index
          this.locParams.expectOutNum = val.expectOutNum
          this.locParams.isConfirm = true
          this.currentRow = val
        }
        this.locParams.show = true
      },
      stockSubmit(val) {
        this.tableData[this.productIndex].childProduct = [...val]
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    height: 33px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .mt30 {
    margin-top: 30px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mb15 {
    margin-bottom: 15px;
  }

  .mt15 {
    margin-top: 15px;
  }

  .table-container {
    padding: 20px 15px !important;
  }

  .operation {
    display: flex;
    justify-content: space-evenly;

    i {
      font-size: 20px;
    }
  }

  .error-info {
    color: #ff4d4f;
    font-size: 12px;
    line-height: 1;
    position: relative;
    padding-top: 5px;
  }

  .pointer {
    cursor: pointer;
  }

  ::v-deep .el-input-number--small {
    width: 100px !important;
  }

  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }

  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }

  ::v-deep .el-input-number--small.error .el-input__inner {
    border-color: #ff4d4f;
  }
</style>

<style>
  .outbound .el-select-dropdown__item {
    width: 200px;
  }

  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
