<!--
  功能：采购需求组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月16日 20:00:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard
    title="采购需求"
    :tabs="tabs"
    @tab-click="handleTabClick"
    :loading="loading"
    :singleNum="singleNum"
    :teamNum="teamNum"
  >
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="procurement-demand-item">
          <div
            class="content"
            @click="clientFollowClick(item)"
            :style="itemStyleObj"
          >
            <div class="procurement-demand-item--top">
              <span class="top-left">
                {{ item.type === 1 ? '采购需求单号' : '备货需求单号' }}
                <span :style="boldStyle.bold">{{ item.number }}</span>
              </span>
              <span class="top-right">{{ item.createTime }}</span>
            </div>
            <div class="procurement-demand-item--bottom">
              <p>
                <span class="label">采购开发:</span>
                <span class="content">{{ item.buyerName }}</span>
              </p>
              <p>
                <span class="label">需求数量:</span>
                <span class="content">{{ item.numDemand }}</span>
              </p>
              <p>
                <span class="label">待处理数量:</span>
                <span class="content">{{ item.numUncompleted }}</span>
              </p>
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'

  import { HomeInteractor } from '@/core'
  export default {
    name: 'ProcurementDemand',
    mixins: [mixins],
    props: {},

    // 组件状态值
    data() {
      return {
        list: [],
        tabs: true,
      }
    },
    computed: {},

    methods: {
      getList() {
        this.loading = true
        const { tenantId, userId } = this.userInfo
        const requestData = {
          pageLe: 99999,
          pageNo: 1,
          authFlag: this.active === 'ME' ? 0 : 1,
          tenantId,
          userId,
        }
        HomeInteractor.listHomePurchasingDemand(requestData).then((res) => {
          if (res && res.code === '000000') {
            const { ownTotal = 0, teamTotal = 0 } = res.data

            this.list = res.data.dataList.data || []
            this.singleNum = ownTotal
            this.teamNum = teamTotal
          }
        })
        this.loading = false
      },

      clientFollowClick({ type }) {
        let canClick = this.beforeListItemClick()
        if (canClick) {
          this.$router.push({
            path: '/purchase/purchaseDemand/purchaseDemand',
            query: {
              tabsType: type,
            },
          })
        }
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .procurement-demand-item {
    font-size: 14px;
    .procurement-demand-item--top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .top-left {
        font-size: 14px;
        font-family: Semibold;
        color: #000000;
      }
      .top-right {
        font-size: 12px;
        color: #5e5e5e;
      }
    }
    .procurement-demand-item--bottom {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      p {
        color: #5e5e5e;
        font-family: Regular;
        .label {
          margin-right: 5px;
        }
      }
    }
  }
</style>
