// /**
//  * 业务逻辑层，可以获取entities层提供的实体类。将实体类提供实体相关的业务逻辑和interactor业务逻辑相结合一起提供给view层。
//  */

import {
  batchUpdateSort,
  listAll,
  savePO,
  customUpdatePO,
  deletePO,
  priceQueryOne,
  priceUpdatePO,
} from '@/core/services/api/order-offer-setting'

class OperatingCostConfigInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 批量更新排序
   * @params 列表业务id、排序集合
   */
  async batchUpdateSort(params) {
    return await batchUpdateSort(params)
  }

  /**
   * 查询所有自定义费用
   * @param param 中文名/英文名
   * @param showFlag 是否展示
   */
  async listAll(form) {
    const params = {
      param: form.param,
      showFlag: form.showFlag,
    }
    return await listAll(params)
  }

  /**
   * 新增自定义费用
   * @param nameCn 中文名称
   * @param nameEn 英文名称
   * @param showFlag 是否展示(0不展示 1展示)
   * @param remark 备注
   */
  async savePO(form) {
    const params = {
      nameCn: form.nameCn,
      nameEn: form.nameEn,
      showFlag: form.showFlag,
      remark: form.remark,
    }
    return await savePO(params)
  }

  /**
   * 更新自定义费用
   * @param nameCn 中文名称
   * @param nameEn 英文名称
   * @param showFlag 是否展示(0不展示 1展示)
   * @param remark 备注
   * @param customExpenseSettingId 业务id
   */
  async customUpdatePO(form) {
    const params = {
      nameCn: form.nameCn,
      nameEn: form.nameEn,
      showFlag: form.showFlag,
      remark: form.remark,
      customExpenseSettingId: form.customExpenseSettingId,
    }
    return await customUpdatePO(params)
  }

  /**
   * 删除自定义费用
   * @param customExpenseSettingId 业务id
   */
  async deletePO(customExpenseSettingId) {
    return await deletePO(customExpenseSettingId)
  }

  /**
   * 查询报价限制
   */
  async priceQueryOne() {
    return await priceQueryOne()
  }

  /**
   * 保存报价限制
   * @param currency 币种
   * @param handlingMethod 处理方式(0 给出提示,但允许报价 1禁止提交报价)
   * @param limitFlag 运费报价限制(0不限制 1限制)
   * @param money 金额
   * @param percent 百分比
   * @param priceSheetSettingId 业务id
   */
  async priceUpdatePO(form) {
    const params = {
      currency: form.currency,
      handlingMethod: form.handlingMethod,
      limitFlag: form.limitFlag,
      money: form.money,
      percent: form.percent,
      priceSheetSettingId: form.priceSheetSettingId,
    }
    return await priceUpdatePO(params)
  }
}

OperatingCostConfigInteractor._instance = new OperatingCostConfigInteractor()

export const OrderOfferSetting = OperatingCostConfigInteractor.getInstance()
