<!--
  功能：运营成本配置-客户(新增或编辑)
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月22日 14:13:26
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="tpl-new-wrapper">
    <h3>
      <span class="vertical"></span>
      人员模板配置
    </h3>
    <el-table border :data="areaTplData">
      <el-table-column
        align="center"
        label="员工姓名"
        prop="personnoelCn"
      ></el-table-column>
      <el-table-column
        align="center"
        label="员工条件运营成本(%)"
        prop="ratio"
      ></el-table-column>
      <el-table-column
        align="center"
        label="运营成本配置编号"
        prop="configOperateNumber"
      ></el-table-column>
      <el-table-column
        align="center"
        label="产品类型"
        prop="standardCustomized"
      ></el-table-column>
      <el-table-column align="center" label="毛利额" prop="conditionType">
        <template slot-scope="scope">
          {{ scope.row.conditionType == 1 ? '大于0' : '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="运营成本(%)"
        prop="costRatio"
      ></el-table-column>
    </el-table>

    <h3 class="h3">
      <span class="vertical"></span>
      客户条件配置
    </h3>
    <el-form :rules="rules" :model="form" ref="form">
      <el-table :data="form.tableData" border stripe class="form-rule-table">
        <el-table-column label="客户名称" prop="customer" align="center">
          <template slot="header">
            <i class="red">*</i>
            客户名称
          </template>
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.customer'"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-select
                v-model="scope.row.customer"
                filterable
                remote
                clearable
                reserve-keyword
                placeholder="请选择"
                :remote-method="querySearchAsync"
                @focus="(val) => getCusAll(scope.row)"
                value-key="customerCode"
                @change="options = []"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.companyName"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="客户条件运营成本(%)"
          prop="standardCustomized"
          align="center"
        >
          <template slot-scope="scope">
            <!--
            <el-form-item
              :prop="'tableData.' + scope.$index + '.ratio'"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'change',
                },
              ]"
            >-->
            <el-input-number
              style="width: 100%"
              v-model="scope.row.ratio"
              :min="0"
              :max="100"
              :controls="false"
              :precision="2"
              clearable
            ></el-input-number>
            <!--  </el-form-item>-->
          </template>
        </el-table-column>

        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="del(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-button class="new-btn" @click="addLine" v-if="!$route.query.businessId">
      新增一行
    </el-button>
  </div>
</template>

<script>
  import {
    areas,
    productTypes,
  } from '@/views/finance-center/config-manage/helper'
  import { selectAllCustomer } from '@/api/order'
  import { OperatingCostConfigInteractor } from '@/core/interactors/finance/config-manage'
  import { newMixin } from '../mixins'

  const baseTpl = {
    ratio: undefined,
    personnoelId: '',
    personnoelCn: '',
    personnoelEn: '',
  }
  export default {
    name: 'OperatingCostConfigUserNew',
    mixins: [newMixin],
    props: {},
    components: {},

    // 组件状态值
    data() {
      return {
        areaTplData: [], //地区模板配置数据
        form: {
          tableData: [], //列表数据
        },
        rules: {}, //校验规则
        baseTpl, //新增一行
        checkedKeys: ['ratio', 'customer'], // 校验项
        options: [],
      }
    },

    methods: {
      // 初始化数据
      async init() {
        const { businessId } = this.$route.query
        const areaTplDataStr = localStorage.getItem('OPERATE_COST_CONFIG_USER')
        if (!businessId && areaTplDataStr) {
          this.areaTplData = [JSON.parse(areaTplDataStr)]
        }
        // 调接口获取模板数据

        if (!businessId) {
          this.form.tableData = [{ ...baseTpl }]
        } else {
          const res = await OperatingCostConfigInteractor.cusGetDetail({
            businessId,
          })
          if (res && res.code === '000000') {
            this.form.tableData = [res.data]
            this.areaTplData = [
              {
                ...res.data,
                ratio: res.data.personnelRatio,
              },
            ]
          }
        }
        this.getCusAll()
      },

      getCusAll(row) {
        let companyName = '',
          reqData = {
            highSeasType: 2,
          }
        if (row && row.customer) {
          if (typeof row.customer === 'object') {
            reqData.companyName = row.customer.companyName
          } else {
            reqData.companyName = row.customer
          }
        }

        if (!reqData.companyName) {
          reqData.limit = 10
        }

        selectAllCustomer(reqData).then((res) => {
          if (res.code === '000000') {
            this.options = res.data || []
          }
        })
      },

      // 客户模糊搜索
      querySearchAsync(queryString) {
        selectAllCustomer({ companyName: queryString, highSeasType: 2 }).then(
          (res) => {
            if (res.code === '000000') {
              this.options = res.data || []
            }
          }
        )
      },

      // handleSelect(item, row) {
      //
      //   row.customer = item.companyName
      //   row.customId = item.customerCode
      // },
      _getReqData() {
        const {
          configOperateCostId,
          configOperatePersonnelId,
          personnoelCn,
          personnoelEn,
          personnoelId,
        } = this.areaTplData[0]
        return this.form.tableData.map((item) => {
          return {
            ...item,
            configOperateCostId,
            configOperatePersonnelId,
            personnoelCn,
            personnoelEn,
            personnoelId,
            customer: item.customer.companyName,
            customId: item.customer.infoId,
          }
        })
      },
      save() {
        this.$refs.form.validate(async (flag) => {
          if (flag) {
            const res = await OperatingCostConfigInteractor.cusSaveList(
              this._getReqData()
            )
            if (res.code === '000000') {
              this.$message.success('操作成功')
              this.saved = true
              this.$emit('back')
            }
          } else {
            this.$message.warning('客户名称不支持为空，请录入数据')
          }
        })
      },
    },

    created() {
      this.init()
    },
  }
</script>

<style scoped lang="scss">
  .vertical {
    display: inline-block;
    height: 20px;
    width: 5px;
    background-color: #409eff;
    vertical-align: middle;
  }
  .new-btn {
    width: 100%;
    border-top: none;
    color: #409eff;
  }

  .el-form-item__error {
    display: none !important;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 0px !important;
  }

  .el-table {
    margin-top: 10px;
  }
  h3 {
    margin-top: 5px;
  }
  .h3 {
    margin-top: 40px;
  }
</style>
