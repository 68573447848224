<template>
  <el-dialog
    :visible.sync="dialogAccountVisible"
    width="30%"
    :close-on-click-modal="false"
    @close="closeAccountDialog"
  >
    <template #title>
      <div class="flex_row_y flex_wrap">
        <!-- 新增供应商 -->
        <p class="p_title f_s_16">{{ title }}收款账户</p>
      </div>
    </template>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <!-- 账户类型 -->
      <el-row>
        <el-col :span="24">
          <el-form-item label="账户类型" prop="accountType">
            <el-select
              v-model="form.accountType"
              placeholder="请选择"
              style="width: 100%"
              clearable
              @change="handleChangeAccountType"
            >
              <el-option
                v-for="(item, index) of accountTypeList"
                :key="item + '_' + index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 户名 -->
      <el-row>
        <el-col :span="24">
          <el-form-item label="户名" prop="accountName">
            <el-input
              v-show="isSpecial"
              v-model="form.accountName"
              v-no-special-chars="invalidChars"
              placeholder="请输入"
              clearable
            />
            <el-input
              v-show="!isSpecial"
              v-model="form.accountName"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 账号 -->
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="账号"
            prop="bankAccount"
            :rules="[
              {
                required: form.accountType == 4 ? false : true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                validator: checkBankAccount,
                trigger: 'blur',
              },
            ]"
          >
            <!-- 账号还要额外将中英文的小括号限制不能输入 -->
            <el-input
              v-show="isSpecial"
              v-model.trim="form.bankAccount"
              v-no-special-chars="invalidChars.concat('(', ')', '（', '）')"
              placeholder="请输入"
              clearable
              @change="handleChangeAccount"
            />
            <el-input
              v-show="!isSpecial"
              v-model.trim="form.bankAccount"
              placeholder="请输入"
              clearable
              @change="handleChangeAccount"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 开户行 -->
      <el-row v-if="form.accountType != 3 && form.accountType != 4">
        <el-col :span="24">
          <el-form-item label="开户行" prop="bankName">
            <bankNameQuery
              ref="bankNameQuery"
              :multiple="false"
              :dialog-visible="dialogAccountVisible"
              :default-bank-name="defaultBankName"
              @bank-name-choose="chooseBankName"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 税号 -->
      <el-row v-if="form.accountType != 3">
        <el-col :span="24">
          <el-form-item
            label="税号"
            prop="dutyAccount"
            :rules="[
              {
                required:
                  form.accountType == 4 || form.accountType == 2 ? false : true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.dutyAccount"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">确认</el-button>
        <!-- 取消 -->
        <el-button @click="closeAccountDialog">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import bankNameQuery from '@/components/bankNameQuery/index'
  var errorTip = ''
  var checkBankAccount = (rule, value, callback) => {
    if (errorTip) {
      callback(new Error('账号重复'))
    } else {
      callback()
    }
  }
  import { saveSupplierBankAccount } from '@/api/order'
  export default {
    components: {
      bankNameQuery,
    },
    props: {
      title: {
        default: '',
        type: String,
      },
      type: {
        default: '',
        type: String,
      },
      handleInfo: {
        type: Object,
        default: () => {
          return {}
        },
      },
      dialogAccountVisible: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        pageType: '',
        accountTypeList: [
          { label: '对公账户', value: 1 },
          { label: '个人银行卡', value: 2 },
          { label: '支付宝', value: 3 },
          { label: '网拍', value: 4 },
        ],
        defaultBankName: '',
        checkBankAccount: '',
        form: {
          accountType: '', //账户类型
          accountName: '', // 户名
          bankAccount: '', // 账号
          bankName: '', // 开户行
          dutyAccount: '', //税号
          cnapsCode: '', //联行号
        },
        rules: {
          accountType: [
            {
              required: true, //请选择账户类型
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
          accountName: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          bankName: [
            {
              required: true, //请选择开户行
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
        },
        // 特殊字符
        invalidChars: [
          '~',
          '`',
          '·',
          '!',
          '！',
          '@',
          '#',
          '$',
          '￥',
          '%',
          '^',
          '…',
          '&',
          '+',
          '-',
          '*',
          '/',
          ':',
          '：',
          ';',
          '；',
          '"',
          '“',
          '”',
          "'",
          '‘',
          '’',
          '\\',
          '|',
          '<',
          '>',
          '《',
          '》',
          ',',
          '，',
          '.',
          '。',
          '?',
          '？',
          '、',
          '_',
          '—',
          '=',
          ' ',
          '[',
          ']',
          '【',
          '】',
          '{',
          '}',
        ],
      }
    },
    computed: {
      // 对公账户和个人银行卡需要禁止输入特殊字符
      isSpecial() {
        return [1, 2].includes(this.form.accountType)
      },
    },
    created() {
      this.checkBankAccount = checkBankAccount
    },
    methods: {
      handleChangeAccountType() {
        const { isSpecial, invalidChars, form } = this
        if (isSpecial) {
          const filteredAccountName = form.accountName
            .split('')
            .filter((n) => !invalidChars.includes(n))
            .join('')
          const filteredBankAccount = form.bankAccount
            .split('')
            .filter((n) => !invalidChars.includes(n))
            .join('')
          this.form.accountName = filteredAccountName
          this.form.bankAccount = filteredBankAccount
        }
      },

      //判断添加的账号在列表中是否重复
      handleChangeAccount(val) {
        let obj = null
        if (this.type == 'order') {
          obj = this.$parent.bankAccountList?.find(
            (item) => item.bankAccount == val
          )
        }
        if (this.type == 'supplier') {
          obj = this.$parent.form.bankAccountDTOS.find(
            (item) => item.bankAccount == val
          )
        }
        if (obj) {
          errorTip = '账号重复'
        } else {
          errorTip = ''
        }
      },

      // 信息回显
      showMsg(val) {
        this.pageType = 'edit'
        this.form = { ...val }
        this.$nextTick(() => {
          if (this.form.accountType == 1 || this.form.accountType == 2) {
            this.defaultBankName =
              this.form.bankName + '/' + this.form.cnapsCode
          } else {
            this.defaultBankName = ''
          }
          this.$refs.form.clearValidate()
        })
      },

      //新增
      addMsg(obj) {
        this.pageType = 'add'
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
          if (obj?.code) this.form.dutyAccount = obj.code
        })
      },

      chooseBankName(val) {
        this.defaultBankName = val
        if (val) {
          this.form.bankName = val.split('/')[0]
          this.form.cnapsCode = val.split('/')[1]
        } else {
          this.form.bankName = ''
          this.form.cnapsCode = ''
        }
      },

      save() {
        let params = JSON.parse(JSON.stringify(this.form))
        if (this.form.accountType == 3) {
          params.bankName = ''
          params.dutyAccount = ''
          params.cnapsCode = ''
        }
        if (this.form.accountType == 4) {
          params.bankName = ''
          params.cnapsCode = ''
        }
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (this.type == 'supplier') {
              this.handleClose()
              this.$emit('confirm', params, this.handleInfo)
            }
            if (this.type == 'order') {
              saveSupplierBankAccount({
                ...params,
                supplierId: this.$parent.form.supplierId,
              }).then((res) => {
                if (res && res.code == '000000') {
                  this.$message.success('保存成功')
                  this.handleClose()
                  this.$emit('confirm', res.data)
                }
              })
            }
          } else {
            return false
          }
        })
      },

      //选择产品-关闭弹框
      closeAccountDialog() {
        this.$emit('update:dialogAccountVisible', false)
        this.handleClose()
        this.pageType = ''
      },
      handleClose() {
        // this.$refs.bankNameQuery.bankName = ''
        this.defaultBankName = ''
        this.form = {
          accountType: '', //账户类型
          accountName: '', // 户名
          bankAccount: '', // 账号
          bankName: '', // 开户行
          dutyAccount: '', //税号
          cnapsCode: '', //联行号
        }
      },
    },
  }
</script>

<style lang="scss">
  .account-dialog {
    .el-dialog {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .el-dialog .el-dialog__body {
      max-height: 100%;
      flex: 1;
      -ms-flex: 1 1 auto;
      /* 兼容IE */
      overflow-y: auto;
      overflow-x: hidden;
    }

    .el-dialog__wrapper {
      /*隐藏ie和edge中遮罩的滚动条*/
      overflow: hidden;
    }
  }
</style>
