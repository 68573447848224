<template>
  <el-autocomplete v-model="queryForm.customerEmail" :fetch-suggestions="querySearchAsyncEmail"
    :placeholder="$t('inquiryDetails.Mail')" @select="handleSelect" @keyup.enter.native="search" v-loadmore="loadMore"
    clearable @clear="onClear"></el-autocomplete>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { clientOrderInstance } from '@/core/interactors/client-supplier/client-order'
  let options = []
  export default {
    name: 'UserSelect',
    props: {
      roleName: {
        type: String,
        default: '',
      },
      divideGroup: {
        type: Number,
        default: 0,
      },
      defaultUser: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        queryForm: {
          customerName: '',
          customerEmail: '',
        },
        pageLe: 10,
        pageNo: 1,
        total: 0,
      }
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },

    watch: {
      queryForm: {
        deep: true,
        handler: function (val) {
          this.$emit('mail-choose', val)
        },
      },
    },

    methods: {
      search() {
        this.$emit('mail-choose', this.queryForm)
      },
      async querySearchAsyncEmail(queryString, cb) {
        this.cb = cb
        const requestData = {
          pageLe: this.pageLe,
          pageNo: this.pageNo,
          customerEmail: this.queryForm.customerEmail,
        }
        if (this.beforeQueryString != queryString) {
          options = []
        }
        this.beforeQueryString = queryString

        await clientOrderInstance.getCustomerEmail(requestData).then((res) => {
          if (res?.code === '000000') {
            let results = res.data?.data?.map((item) => {
              return {
                ...item,
                value: item.email,
              }
            })
            options = options.concat(results || [])
            this.total = res.data.total
            cb(options)
          }

        })
      },

      // 选择邮箱
      handleSelect(item) {
        this.queryForm.customerName = item.linkman
      },

      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this.querySearchAsyncEmail('', this.cb)
      },
      onClear() {
        this._reset()
      },
      _reset() {
        this.pageNo = 1
        this.total = 0
        options = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-select-dropdown__item .wrapper {
    display: flex;

    span {
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tl {
    text-align: left;
    padding-right: 5px;
  }

  .tr {
    text-align: right;
    padding-left: 5px;
    color: #909399;
  }
</style>
<style>
  .el-form-item__content .email-sel-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
</style>