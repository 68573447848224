<template>
  <el-dialog :visible.sync="params.show" title="设置库位" :width="$attrs.width || '500px'" append-to-body @close="onClose"
    modal-append-to-body :close-on-click-modal="false">
    <!-- 查询表单 -->
    <div>
      <div class="loc-wrapper">
        <!-- v1.4.2修改成表格展示 -->
        <el-table :data="locs" border stripe max-height="400">
          <el-table-column align="center" label="库位号">
            <template slot-scope="scope">
              <el-select v-model="scope.row.warehouseLocationId" placeholder="选择库位" class="w100">
                <el-option v-for="loc in locList" :key="loc.warehouseLocationId" :label="loc.locationNumber"
                  :value="loc.warehouseLocationId"></el-option>
              </el-select>
            </template>
          </el-table-column>


          <el-table-column align="center" label="操作" width="50px">
            <template slot-scope="scope">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" @click="deleteLoc(scope.$index)" style="color: #f56c6c; cursor: pointer"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-row v-if="params.disabled !== true">
          <el-button @click="addNewLoc" icon="el-icon-plus" style="width: 100%; color: #1890ff; border-top: none">
            添加库位
          </el-button>
        </el-row>
      </div>

      <div class="footer-wrapper mt30 t_a_c" v-if="params.disabled !== true" style="text-align: center">
        <el-button type="primary" class="ml30" @click="sureAction">
          保存
        </el-button>
        <el-button @click="cancelAction">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'product',
    props: {
      params: {
        type: Object,
        default: () => { },
      },
      warehouseId: [String],
      locList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        locs: [
          {
            warehouseLocationId: '',
            locationNumber: '',
          },
        ],


      }
    },

    methods: {
      // 删除库位
      deleteLoc(index) {
        if (this.locs.length === 1) {
          return this.$message.warning('至少保留一个库位')
        }
        this.locs.splice(index, 1)
      },
      addNewLoc() {
        this.locs.push({
          warehouseLocationId: '',
          locationNumber: '',
        })
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },

      cancelAction() {
        this.onClose()
      },

      // 确定事件
      sureAction() {
        if (this.locs.some((i) => !i.warehouseLocationId)) {
          return this.$message.warning('库位号不能为空')
        }

        let warehouseLocationIds = this.locs.map(item => item.warehouseLocationId)
        let noSameWarehouseLocationIds = Array.from(new Set(warehouseLocationIds))
        if (warehouseLocationIds.length > noSameWarehouseLocationIds.length) {
          return this.$message.warning('库位号重复')
        }
        let locs = this.locList.filter(loc => warehouseLocationIds.includes(loc.warehouseLocationId))
        this.$emit('set-loc', locs)
        this.$emit('close')
      },

      // 关闭
      onClose() {
        this.locs = []
        this.$emit('close')
      },
    },


  }
</script>

<style scoped lang="scss">
  .footer {
    .el-button+.el-button {
      margin-left: 30px;
    }
  }

  .ml15 {
    margin-left: 15px;
  }

  .loc-item {
    display: flex;
    align-items: center;
  }

  .mt20 {
    margin-top: 20px;
  }

  .pointer {
    cursor: pointer;
  }


  .el-col-6 {
    display: flex;
    align-items: center;
  }
</style>
