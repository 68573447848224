<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="实际签收时间:" prop="manualSignInDate">
              <el-date-picker
                v-model="form.manualSignInDate"
                type="date"
                style="width: 100%"
                placeholder="选择日期"
                value-format="yyyy-MM-dd hh:mm:ss"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'
  const PAD = 1000 * 60 * 60 * 24
  export default {
    name: 'SignModel',
    data() {
      return {
        title: '手动签收',
        form: {
          status: 98,
          number: '',
        },
        rules: {
          manualSignInDate: [
            { required: true, trigger: 'blur', message: `请选择签收时间` },
          ],
          totalFreight: [
            { required: true, trigger: 'blur', message: `请输入运费` },
            {
              pattern: /(^[1-9](\d+)?(\.\d{1,3})?$)|(^\d\.\d{1,3}$)/,
              message: '>0且最多保留三位小数',
              trigger: 'blur',
            },
          ],
        },
        dialogFormVisible: false,
        value: null,
        isUpdata: false,
        treeData: [],
        pickerOptions: {},
      }
    },

    created() {},
    methods: {
      setPickOptions(shipTime) {
        this.pickerOptions = {
          disabledDate: (time) => {
            time = new Date(time).getTime()
            if (
              time < new Date(shipTime).getTime() - PAD ||
              time > Date.now()
            ) {
              return true
            } else {
              return false
            }
          },
        }
      },
      //手动签收
      showAddEdit(row) {
        if (Array.isArray(row)) {
          this._batchSign(row)
        } else {
          this._singleSign(row)
        }
        this.dialogFormVisible = true
      },
      // 单个签收
      _singleSign(row) {
        this.isBatch = false
        this.currentRow = row
        this.form.number = row.number
        this.setPickOptions(row.shipTime)
      },
      // 批量签收
      _batchSign(rows) {
        this.isBatch = true
        this.currentRows = rows
        this.setPickOptions(
          Math.max(...rows.map((i) => new Date(i.shipTime).getTime()))
        )
      },

      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let requestData,
              manualSignInDate = this.form.manualSignInDate
            if (this.isBatch) {
              requestData = this.currentRows.map((i) => {
                const obj = {}
                obj.businessNumber = i.businessNumber
                obj.manualSignInDate = manualSignInDate
                obj.shippingOrderId = i.shippingOrderId
                return obj
              })
            } else {
              const { businessNumber, shippingOrderId } = this.currentRow
              requestData = {
                manualSignInDate,
                businessNumber,
                shippingOrderId,
              }
            }
            const requestMethod = this.isBatch
              ? ShipmentMangeInteractor.mutliManualSignIn
              : ShipmentMangeInteractor.manualSignIn
            let response = await requestMethod(requestData)
            if (response.code === '000000') {
              this.$baseMessage(
                '签收成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
            this.$emit('fetch-data')
            this.$parent.onSearch()
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
