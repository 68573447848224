<template>
  <div class="search-form">
    <el-form
      class="search-form-content"
      ref="form"
      :model="form"
      label-position="top"
    >
      <!-- label="公司名称" -->
      <el-form-item :label="$t('cusManage.CompanyName')">
        <el-input
          v-model="form.companyName"
          :placeholder="$t('placeholder.plsInput')"
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="联系人"  -->
      <el-form-item :label="$t('cusManage.Contacts')">
        <el-input
          v-model="form.linkman"
          :placeholder="$t('placeholder.plsSel')"
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="联系电话" -->
      <el-form-item :label="$t('cusManage.ContactNumber')">
        <el-input
          v-model="form.phone"
          :placeholder="$t('placeholder.plsInput')"
          clearable
        ></el-input>
      </el-form-item>
      <!-- label="客户来源" -->
      <el-form-item :label="$t('cusManage.CustomerSource')">
        <el-select
          class="w100"
          v-model="form.customerSourceId"
          :placeholder="$t('placeholder.plsSel')"
          clearable
        >
          <el-option
            v-for="item in customerSource"
            :key="item.sourceId"
            :label="lang ==='en' ? item.sourceNameEn : item.sourceName"
            :value="item.sourceId"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- label="客户类型" -->
      <el-form-item :label="$t('cusManage.CustomerType')">
        <el-select
          v-model="form.customerType"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <!-- <el-option label="潜在客户" :value="0" />
          <el-option label="正式客户" :value="1" />
          <el-option label="黑名单客户" :value="2" /> -->
          <el-option :label="$t('selOpt.Potentialcustomers')" :value="0" />
          <el-option :label="$t('selOpt.Formalcustomers')" :value="1" />
          <el-option :label="$t('selOpt.Blacklistcustomers')" :value="2" />
        </el-select>
      </el-form-item>
      <!-- label="客户等级" -->
      <el-form-item :label="$t('cusManage.CustomerLevel')">
        <el-select
          v-model="form.customerRank"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
          clearable
        >
          <el-option
            v-for="item in customerRankOptions"
            :key="item.value"
            :label="$t(item.langKey)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- label="负责人" -->
      <el-form-item :label="$t('cusManage.ResponsiblePerson')">
        <el-input
          v-model="belongerName"
          :placeholder="$t('placeholder.plsSel')"
          clearable
          @focus="selectUserClick('belongerName')"
          @change="belongerNameChange"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="订单成交量(改)">
        <div class="line_input">
          <el-input
            v-model="form.inquiryCountMin"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input
            v-model="form.inquiryCountMax"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
        </div>
      </el-form-item> -->
      <!-- <el-form-item label="订单总金额(改)">
        <div class="line_input">
          <el-input
            v-model="form.inquiryCountMin"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input
            v-model="form.inquiryCountMax"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请输入"
            clearable
          />
        </div>
      </el-form-item> -->
      <!-- label="回归公海剩余时间" -->
      <el-form-item :label="$t('cusManage.ReturnToTheHighSeasRemainingTime')">
        <div class="line_input">
          <el-input
            v-model="form.startTimeRemaining"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            :placeholder="$t('placeholder.plsInput')"
            clearable
            maxlength="12"
          />
          <!-- <span>至</span> -->
          <span>{{ $t('other.To') }}</span>
          <el-input
            v-model="form.endTimeRemaining"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            :placeholder="$t('placeholder.plsInput')"
            clearable
            maxlength="12"
          />
        </div>
      </el-form-item>
      <!-- label="创建人" -->
      <el-form-item :label="$t('cusManage.Creator')">
        <el-input
          v-model="createName"
          :placeholder="$t('placeholder.plsSel')"
          clearable
          @focus="selectUserClick('createName')"
          @change="createNameChange"
        ></el-input>
      </el-form-item>
      <!-- label="创建时间" -->
      <el-form-item :label="$t('cusManage.CreationTime')">
        <!-- start-placeholder="开始日期"
          end-placeholder="结束日期" -->
        <el-date-picker
          v-model="createDaterange"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
          :default-time="['00:00:00', '23:59:59']"
          @change="createDaterangeChange"
        />
      </el-form-item>
    </el-form>
    <div class="operate">
      <!-- <el-button type="primary" @click="searchClick">确定</el-button>
      <el-button @click="resetClick">重置</el-button> -->
      <el-button type="primary" @click="searchClick">
        {{ $t('other.confirm') }}
      </el-button>
      <el-button @click="resetClick">{{ $t('other.reset') }}</el-button>
    </div>
    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
import UserChoose from '@/components/userChoose2'
import { mapGetters } from 'vuex'
export default {
  name: 'ClientManageSearch',
  components: { UserChoose },
  data() {
    return {
      customerSource: [], //客户来源下拉
      customerRankOptions: [
        //客户等级
        {
          value: 1,
          label: '1级',
          langKey: 'selOpt.l1',
        },
        {
          value: 2,
          label: '2级',
          langKey: 'selOpt.l2',
        },
        {
          value: 3,
          label: '3级',
          langKey: 'selOpt.l3',
        },
        {
          value: 4,
          label: '4级',
          langKey: 'selOpt.l4',
        },
        {
          value: 5,
          label: '5级',
          langKey: 'selOpt.l5',
        },
      ],
      form: {},
      createDaterange: [],
      isUserChoose: '',
      linkman: '', //联系人
      linkmanId: [], //联系人id
      belongerName: '', //归属人
      belongerNameId: [], //归属人id
      createName: '', //创建人
      createNameId: [], //创建人id
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  created() {
    //客户来源下拉
    this.pubApi.customerSourceList({}).then((response) => {
      if (response.code === '000000') {
        this.customerSource = response.data
      }
    })
  },
  methods: {
    // 确定searchClick
    searchClick() {
      //创建时间
      if (this.createDaterange && this.createDaterange.length) {
        this.form.startCreateTime = this.createDaterange[0]
        this.form.endCreateTime = this.createDaterange[1]
      }
      //创建人
      if (this.createName) {
        this.form.creatorId = this.createNameId.join(',')
      }
      //负责人
      if (this.belongerName) {
        this.form.belongerId = this.belongerNameId.join(',')
      }
      // //联系人
      // if (this.linkman) {
      //   this.form.linkman = this.linkmanId.join(',')
      // }

      this.$emit('searchClick', this.form)
    },

    //创建时间
    createDaterangeChange() {
      this.form.startCreateTime = ''
      this.form.endCreateTime = ''
    },
    //，归属人，创建人选择
    selectUserClick(str) {
      this.isUserChoose = str
      if (str === 'belongerName') {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.belongerNameId)
      } else if (str === 'createName') {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.createNameId)
      }
    },

    //，归属人，创建人返回
    chooseUser(checkedUser) {
      if (this.isUserChoose === 'belongerName') {
        this.belongerNameChange()
      } else if (this.isUserChoose === 'createName') {
        this.createNameChange()
      }
      if (!checkedUser.length) {
        return
      }
     
      let userName = []
      checkedUser.forEach((item) => {
        userName.push(this.lang === 'en' ? item.englishName : item.name)
        if (this.isUserChoose === 'belongerName') {
          this.belongerName = userName.join(',')
          this.belongerNameId.push(item.id)
        } else if (this.isUserChoose === 'createName') {
          this.createName = userName.join(',')
          this.createNameId.push(item.id)
        }
      })
    },

    // 删除负责人
    belongerNameChange() {
      this.belongerNameId = []
      this.belongerName = ''
      this.form.belongerId = ''
     
    },
    // 删除创建人
    createNameChange() {
      this.createNameId = []
      this.createName = ''
      this.form.creatorId = ''
    },

    //重置
    resetClick() {
      this.$refs.form.resetFields()
      this.form = this.$options.data().form
      this.createDaterange = ''
      this.belongerName = ''
      this.belongerNameId = []
      this.createName = ''
      this.createNameId = []
       
    },
  },
}
</script>

<style lang="scss" scoped>
.search-form {
  height: 90%;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 100px;
  .search-form-content {
    padding: 0 20px;
  }
  .operate {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ededed;
    padding: 20px;
  }
}
</style>
<style lang="scss" scoped>
.search_form .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}
::v-deep {
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 30px 0 28px 0 !important;
  }
  .el-drawer__body {
    height: calc(100% - 44px);
  }
}
</style>
