<template>
  <el-dialog
    width="1000px"
    class="custom-dialog"
    :visible.sync="stockVisible"
    :close-on-click-modal="false"
  >
    <div class="container">
      <el-table
        border
        :data="stockLockData"
        :header-cell-style="{ textAlign: 'center' }"
      >
        <el-table-column width="130" label="销售单号" align="center">
          <template slot-scope="scope">
            <router-link
              target="_blank"
              :to="`/order/orderList/orderDetail?orderCode=${
                scope.row.salesOrderNumber
              }&noReturn=${true}`"
            >
              <span class="page-link">
                {{ scope.row.salesOrderNumber }}
              </span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="仓库" align="center">
          <template slot-scope="scope">
            {{ scope.row.warehouseName }}
          </template>
        </el-table-column>
        <el-table-column width="120" label="锁定量" align="center">
          <template slot-scope="scope">
            {{ scope.row.num }}
          </template>
        </el-table-column>
        <el-table-column width="120" label="剩余锁定时长" align="center">
          <template slot-scope="scope">
            {{ scope.row.remainLockTime }}
          </template>
        </el-table-column>
        <el-table-column width="120" label="锁定人" align="center">
          <template slot-scope="scope">
            {{ scope.row.createName }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="锁定时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.lockTime }}
          </template>
        </el-table-column>
        <el-table-column width="80" label="操作" align="center">
          <template slot-scope="scope">
            <el-tooltip content="解锁" placement="top" v-if="scope.row.state">
              <el-button
                v-show="isShow"
                @click="unlockDialog(scope.row)"
                type="primary"
                plain
                size="mini"
                icon="iconfont icon-jiesuo"
                circle
              ></el-button>
            </el-tooltip>
            <span v-if="!scope.row.state">--</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer mt30 t_a_c">
      <el-button @click="stockVisible = false">取 消</el-button>
    </div>

    <!-- 库存解锁 -->
    <el-dialog
      title="库存解锁"
      :visible="unlockVisible"
      width="600px"
      :close-on-click-modal="false"
      @close="unlockVisible = false"
      append-to-body
      modal-append-to-body
    >
      <el-row :gutter="20">
        <el-col :span="12">锁定仓库：{{ unlockData.warehouseName }}</el-col>
        <el-col :span="12">锁定量：{{ unlockData.num }}</el-col>
      </el-row>
      <el-row class="mt20">
        <el-form
          :model="lockForm"
          :rules="lockRules"
          ref="lockForm"
          label-width="70px"
        >
          <el-form-item label="解锁数" prop="unlockNum" class="label-left">
            <el-input
              v-model="lockForm.unlockNum"
              @input="numChange"
              maxlength="10"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <div class="footer mt30 t_a_c">
        <el-button type="primary" class="ml30" @click="unlock">确 定</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import purchaseDemandInterator from '@/core/interactors/purchase/purchaseDemand'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      stockLockData: [], // 库存信息
      unlockData: {
        warehouseName: '',
        unlockNum: '',
      }, // 库存解锁数据
      lockForm: { unlockNum: '' },
      isShow: true, // 是否显示解锁
      stockVisible: false,
      unlockVisible: false, // 库存解锁
      lockRules: {
        unlockNum: [
          { required: true, message: '请输入解锁数', trigger: 'blur' },
          {
            pattern: /^[1-9]\d*$/,
            message: '请输入>0的整数',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  methods: {
    // 初始化加载
    async initLoading(data) {
      const params = {
        salesOrderId: data.salesOrderId,
        salesOrderNumber: data.salesOrderNumber,
        sku: data.sku,
        warehouseId: data.warehouseId,
        orderProductUniqueId: data.orderProductUniqueId,
      }

      // 控制解锁显示权限
      data.operatePurchaseDemand === '1'
        ? (this.isShow = true)
        : (this.isShow = false)

      const res = await purchaseDemandInterator.listStockLockInfo(params)

      if (res && res.code === '000000') {
        this.stockLockData = res.data.map((item) => {
          return {
            ...item,
            state: this.userInfo.userId == item.creatorId ? true : false,
          }
        })
        this.stockVisible = true
      }
    },

    // 解锁弹窗
    unlockDialog(row) {
      this.unlockData = row
      this.unlockVisible = true
    },

    // 解锁
    unlock() {
      this.$refs['lockForm'].validate(async (valid) => {
        if (valid) {
          const res = await purchaseDemandInterator.stockLockRecordUnlock(
            this.lockForm.unlockNum,
            this.unlockData.stockLockRecordId
          )

          if (res && res.code === '000000') {
            this.close()
            this.stockVisible = false
            this.$message.success('保存成功！')
            this.$parent.getList()
          }
        }
      })
    },

    // 解锁数不能大于锁定量
    numChange() {
      if (this.lockForm.unlockNum > this.unlockData.num) {
        this.lockForm.unlockNum = this.unlockData.num
        this.$message.warning('解锁量不能大于锁定量')
      }
    },

    // 关闭弹窗重置表单
    close() {
      this.$refs.lockForm.resetFields()
      this.unlockVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  .container {
    min-height: 200px;
  }
}
</style>