v<!--
  功能：库存锁定次数表单
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月20日 14:29:26
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    label-width="50px"
    label-position="top"
  >
    <el-form-item label="单个产品最多可锁定次数" prop="value">
      <el-input
        type="text"
        v-model.number="form.value"
        maxlength="3"
        placeholder="请设置"
      >
        <span slot="append">次</span>
      </el-input>
    </el-form-item>
    <p style="font-size: 12px; color: #909399">
      销售单中的一个产品到达锁定上线后，将不可进行锁定操作
    </p>
    <el-form-item label="启用仓库" prop="wharehouseList">
      <el-select
        placeholder="请选择"
        v-model="form.wharehouseList"
        class="w100"
        multiple
        collapse-tags
        @change="wharehouseListChange"
      >
        <el-option
          :label="item.name"
          :value="item.warehouseId"
          v-for="item in whs"
          :key="item.warehouseId"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'InventoryLocktime',
  props: {
    whs: {
      type: Array,
      default: () => {},
    },
  },
  components: {},
  // 组件状态值
  data() {
    return {
      form: {
        type: 2,
        value: '',
        wharehouseList: [],
      },
      warehouseSettingWarehouseDTOS: [],
      rules: {
        value: [
          {
            required: true,
            message: '请输入',
          },
          {
            type: 'number',
            message: '库存锁定时长为数字值',
            trigger: ['blur', 'change'],
          },
        ],

        wharehouseList: [
          {
            required: true,
            message: '请选择启用仓库',
          },
          {
            validator: (rule, value, callback) => {
              if (!value || value.length === 0) {
                callback(new Error('请选择启用仓库'))
              } else {
                callback()
              }
            },
            trigger: [''],
          },
        ],
      },
    }
  },

  methods: {
    // 重置
    resetFields() {
      this.form = {
        value: '',
        wharehouseList: [],
      }
    },

    //wharehouseListChange
    wharehouseListChange(val) {
      let warehouseArr = val
      let warehouseSettingWarehouseDTOS = []
      warehouseArr.forEach((item, index) => {
        this.whs.forEach((item2, index2) => {
          if (item === item2.warehouseId) {
            let obj = {
              warehouseId: item2.warehouseId,
              warehouseName: item2.name,
            }
            warehouseSettingWarehouseDTOS.push(obj)
          }
        })
      })
      this.warehouseSettingWarehouseDTOS = warehouseSettingWarehouseDTOS
    },

    //保存
    validate() {
      return new Promise((resove, reject) => {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            //编辑
            if (this.form.warehouseSettingId) {
              if (
                this.warehouseSettingWarehouseDTOS &&
                this.warehouseSettingWarehouseDTOS.length
              ) {
                this.form.wharehouseList = this.warehouseSettingWarehouseDTOS
              } else {
                this.form.wharehouseList =
                  this.form.warehouseSettingWarehouseShowVOList
                delete this.form.warehouseSettingWarehouseShowVOList
              }
            } else {
              //新增
              this.form.wharehouseList = this.warehouseSettingWarehouseDTOS
            }
            resove({ ...this.form })
          } else {
            reject()
          }
        })
      })
    },
  },

  created() {},

  mounted() {},
}
</script>

<style scoped lang="scss">
.form {
  .w100 {
    width: 100%;
  }
}
</style>
