var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"custom-dialog",attrs:{"title":_vm.isEdit ? _vm.$t('other.inquiryEdit') : _vm.$t('inquiryList.Inquiry'),"width":"1200px","visible":_vm.inquiryDemandVisible,"close-on-click-modal":false,"before-close":_vm.close},on:{"update:visible":function($event){_vm.inquiryDemandVisible=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form}},[_c('el-table',{attrs:{"data":_vm.form.inquiryOrderDTOList,"border":"","header-cell-style":{ textAlign: 'center' },"cell-style":{ textAlign: 'center' },"header-cell-class-name":_vm.star}},[_c('el-table-column',{attrs:{"type":"index","label":"#","width":"55"}}),_c('el-table-column',{attrs:{"label":_vm.$t('quoteDetails.ProductName'),"prop":"productEn","width":"200"}}),_c('el-table-column',{attrs:{"label":_vm.$t('quoteDetails.ProductImage'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"wrapper-box",on:{"click":function($event){return _vm.choseRendingImages(
                scope.$index,
                scope.row.productImg,
                'productImg'
              )}}},[_c('el-badge',{directives:[{name:"show",rawName:"v-show",value:(
                scope.row.productImg &&
                scope.row.productImg.split(',').length > 1
              ),expression:"\n                scope.row.productImg &&\n                scope.row.productImg.split(',').length > 1\n              "}],attrs:{"value":scope.row.productImg
                  ? scope.row.productImg.split(',').length
                  : ''}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(scope.row.productImg),expression:"scope.row.productImg"}],staticClass:"wrapper-image"},[_c('img',{attrs:{"src":_vm.getFirstImgUrl(scope.row.productImg),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!scope.row.productImg),expression:"!scope.row.productImg"}],staticClass:"wrapper-add"},[_c('i',{staticClass:"el-icon-plus"})])],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('quoteDetails.RequiredQuantity'),"prop":"amount","width":"120"}}),_c('el-table-column',{attrs:{"label":_vm.$t('quoteDetails.ProductCategory'),"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'inquiryOrderDTOList.' + scope.$index + '.categoryId',"rules":[
              {
                required: true,
                message: _vm.$t('rules.M2006'),
                trigger: 'change',
              } ]}},[_c('el-cascader',{attrs:{"options":_vm.categoryOptions,"props":_vm.optionProps,"placeholder":_vm.$t('placeholder.plsSel')},on:{"change":function($event){return _vm.proCategoryChange(scope.$index)}},model:{value:(_vm.form.inquiryOrderDTOList[scope.$index].categoryId),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderDTOList[scope.$index], "categoryId", $$v)},expression:"form.inquiryOrderDTOList[scope.$index].categoryId"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('quoteDetails.ProductDescription'),"width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'inquiryOrderDTOList.' + scope.$index + '.productDescribe',"rules":[
              {
                required: true,
                message: '请输入',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"type":"textarea","rows":3,"maxlength":"500","show-word-limit":"","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(_vm.form.inquiryOrderDTOList[scope.$index].productDescribe),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderDTOList[scope.$index], "productDescribe", $$v)},expression:"form.inquiryOrderDTOList[scope.$index].productDescribe"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('quoteDetails.PrintingDescription'),"width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"type":"textarea","rows":3,"maxlength":"500","show-word-limit":"","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(
                _vm.form.inquiryOrderDTOList[scope.$index].printingDescribe
              ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderDTOList[scope.$index], "printingDescribe", $$v)},expression:"\n                form.inquiryOrderDTOList[scope.$index].printingDescribe\n              "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('quoteDetails.PrintingReferenceDrawing'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"wrapper-box",on:{"click":function($event){return _vm.choseRendingImages(
                scope.$index,
                scope.row.printingImg,
                'printingImg'
              )}}},[_c('el-badge',{directives:[{name:"show",rawName:"v-show",value:(
                scope.row.printingImg &&
                scope.row.printingImg.split(',').length > 1
              ),expression:"\n                scope.row.printingImg &&\n                scope.row.printingImg.split(',').length > 1\n              "}],attrs:{"value":scope.row.printingImg
                  ? scope.row.printingImg.split(',').length
                  : ''}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(scope.row.printingImg),expression:"scope.row.printingImg"}],staticClass:"wrapper-image"},[_c('img',{attrs:{"src":_vm.getFirstImgUrl(scope.row.printingImg),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!scope.row.printingImg),expression:"!scope.row.printingImg"}],staticClass:"wrapper-add"},[_c('i',{staticClass:"el-icon-plus"})])],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('quoteDetails.Remark'),"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"type":"textarea","rows":3,"maxlength":"500","show-word-limit":"","placeholder":_vm.$t('placeholder.plsInput'),"clearable":""},model:{value:(_vm.form.inquiryOrderDTOList[scope.$index].remarks),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderDTOList[scope.$index], "remarks", $$v)},expression:"form.inquiryOrderDTOList[scope.$index].remarks"}})],1)]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":_vm.$t('inquiryList.Operate'),"width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"icon-r",attrs:{"size":"mini","icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.del(scope.$index)}}})]}}])})],1)],1),_c('div',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('inquiryList.Confirm'))+" ")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('addInquiry.Cancel'))+" ")])],1),_c('choose-images',{attrs:{"visible":_vm.chooseImagesDialogVisible,"default-file-list":_vm.currentDefaultRendingList},on:{"update:visible":function($event){_vm.chooseImagesDialogVisible=$event},"confirm":_vm.setRendingImages}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }