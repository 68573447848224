<template>
  <div class="custom-container" style="calc(100% - 400px)">
    <order-settle-table
      contentType="detail"
      :isAudit="isAudit === '1'"
      :list="tableData"
      :key="tableData.length"
      :isAdjust="true"
      :tableHeight="tableHeight"
     
    ></order-settle-table>
  </div>
</template>
<script>
import orderSettleTable from '@/views/finance-center/order-settlement/components/order-settlement-table.vue'
import { getDetailBySettlementSalesOrderId } from '@/api/order-settlement/index.js'
import { mapGetters } from 'vuex'
export default {
  name: 'SettleAdjustDetail',
  components: {
    orderSettleTable,
  },
  props: {
    detailId: {
      type: String,
      default: '',
    },
    isAudit: {
      type: String,
      default: '0',
    },
    
  },
  data() {
    return {
      tableData: [],
      settlementId: '',
      canRun: true,
      tableHeight: 0,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    this.getData()
  },
  mounted() {
    this.getTableHeight()
  },
  methods: {
    getData() {
      
      getDetailBySettlementSalesOrderId(this.detailId).then((res) => {
        if (res?.code === '000000') {
          this.tableData = res?.data
        }
      })
    },
    getTableHeight() {
      const wrapperH = document
          .querySelector('.custom-container')
          .getBoundingClientRect().height,
        height = wrapperH - 200

      this.tableHeight = height
    },
  },
}
</script>