<template>
  <div class="table-container custom-padding">
    <el-page-header @back="$router.back()" content="库位设置"></el-page-header>
    <div class="loc-tree-wrapper">
      <el-tree
        :data="locs"
        :props="defaultProps"
        default-expand-all
        ref="tree"
        highlight-current
        :filter-node-method="filterNodeMethod"
        :expand-on-click-node="false"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <p class="node-content">
            <i
              :class="[
                'mr5',
                data.locationLevel !== 0
                  ? 'el-icon-location-outline'
                  : 'el-icon-house',
              ]"
            ></i>
            <el-tooltip
              :content="node.label"
              :disabled="node.label.length < 60"
            >
              <span class="node-label text-ellipsis">{{ node.label }}</span>
            </el-tooltip>
          </p>
          <span>
            <el-button
              type="text"
              size="mini"
              @click="() => append('SAME_LEVEL', node, data)"
            >
              平级
            </el-button>

            <el-button
              type="text"
              size="mini"
              v-if="showAddChild(node)"
              @click="() => append('CHILD_LEVEL', node, data)"
            >
              子级
            </el-button>

            <el-button type="text" size="mini" @click="() => edit(node, data)">
              编辑
            </el-button>

            <el-button
              type="text"
              size="mini"
              class="red"
              @click="() => remove(node, data)"
            >
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>
    <el-row style="text-align: center; margin-top: 20px">
      <el-button type="primary" @click="onConfirm">确认</el-button>
      <el-button @click="onCancel">取消</el-button>
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      :close-on-click-modal="false"
      width="400px"
      center
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item
            label-position="left"
            prop="locationNumber"
            :rules="[
              {
                required: true,
                message: '请输入',
              },
            ]"
          >
            <el-input
              maxlength="50"
              v-model="form.locationNumber"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-wrappper">
          <el-button type="primary" @click="saveLoc">确认</el-button>
          <el-button @click="cancelLoc">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import globalBackButtonMixin from '@/utils/global-back-button'
  import WarehouseManageInteractor from '@/core/interactors/stock-center/warehouse-manage'
  const MAX_LEVEL = 3
  let tempId = 1000
  export default {
    data() {
      return {
        locs: [], // 库区库位数据
        defaultProps: {
          // el-tree props
          children: 'children',
          label: 'locationNumber',
        },
        centerDialogVisible: false, //弹窗控制
        currentLocationLevel: 0, // 0库区,1库位
        form: {
          locationNumber: '', //库位
        },
        rules: {}, //校验规则
        isEdit: false, //是否是编辑
      }
    },
    computed: {
      title() {
        //弹窗标题
        let action = this.isEdit ? '编辑' : '新增'
        let level = this.currentLocationLevel === 0 ? '库区' : '库位'
        return `${action}${level}`
      },
    },
    mixins: [globalBackButtonMixin],
    methods: {
      // 是否显示新增子级按钮
      showAddChild(node) {
        let level = 0
        while (node.parent && node.parent.data.locationLevel) {
          level++
          node = node.parent
        }
        if (level == MAX_LEVEL) {
          return false
        } else {
          return true
        }
      },

      // 取消
      onCancel() {
        this.$router.back()
      },
      // 确认
      onConfirm() {
        WarehouseManageInteractor.saveWarehouseLocation(this.locs).then(
          (res) => {
            if (res && res.code === '000000') {
              this.$message.success('保存成功')
              this.$router.back()
            }
          }
        )
      },

      // 判断库位能否新增编辑和删除
      _canUpdate(locationIds) {
        return WarehouseManageInteractor.isDeleteWarehouseLocation({
          locationIds: locationIds.join(','),
          warehouseId: this.$route.query.warehouseId,
        }).then((res) => {
          this.locationIds = []
          if (res && res.code === '000000') {
            return true
          }
          return false
        })
      },
      // 编辑库位
      async edit(node, data) {
        this.currentData = data
        this.currentLevel = 'SAME_LEVEL'
        this.isEdit = true
        this.targetData = node.parent.data
        this._getLocationIds(data)
        let locationIds = this.locationIds
        let canUpdate = true
        if (locationIds && locationIds.length > 0) {
          canUpdate = await this._canUpdate(locationIds)
        }
        this.$refs.tree.filter('')
        if (!canUpdate) {
          return
        }
        this.form.locationNumber = data.locationNumber
        this.centerDialogVisible = true
      },

      // 新增库区库位
      async append(level, node, data) {
        this.isEdit = false
        let { locationLevel } = data
        if (level === 'CHILD_LEVEL') {
          if (data.warehouseLocationId) {
            let canAppend = await this._canUpdate([data.warehouseLocationId])
            if (!canAppend) return
          }
        }
        if (level === 'SAME_LEVEL') {
          this.currentLocationLevel = locationLevel
        } else {
          this.currentLocationLevel = 1 // 子级就是库位
          if (!data.children) {
            this.$set(data, 'children', [])
          }
        }

        // 新增平级还是新增子集
        let targetData = level === 'SAME_LEVEL' ? node.parent.data : data
        if (level === 'SAME_LEVEL') {
          if (targetData === this.locs) {
            this.parentId = this.locs[0].parentId
          } else {
            this.parentId = node.parent.data.id
          }
        } else {
          this.parentId = targetData.id
        }
        this.currentLevel = level
        if (!targetData.children) {
          this.$set(targetData, 'children', [])
        }
        this.targetData = targetData

        this.centerDialogVisible = true
      },

      // 删除前校验
      _beforeRemove(node, data) {
        if (
          data.locationLevel === 0 &&
          node.parent.data.filter((item) => item.deleteFlag !== true)
            ?.length === 1
        ) {
          this.$message.warning('无法全部删除')
          return false
        }
        return true
      },

      // 删除库区库位
      async remove(node, data) {
        let self = this
        if (!this._beforeRemove(node, data)) return
        this._getLocationIds(data)
        let locationIds = this.locationIds
        const parent = node.parent
        const children = parent.data.children || parent.data
        if (locationIds.length === 0) {
          let isDel = await this._deleteConfirm()
          if (!isDel) return
          //直接删除，不用调接口
          const index = children.findIndex((d) => d.tempId === data.tempId)
          children.splice(index, 1)
        } else {
          let canAppend = await this._canUpdate(locationIds)
          if (canAppend) {
            let isDel = await this._deleteConfirm()
            if (isDel) {
              this.markDeleted(data)
            }
          }

          self.$refs.tree.filter('')
        }
      },

      // 对删除的库位打标记
      markDeleted(data) {
        this.$set(data, 'deleteFlag', true)
        if (Array.isArray(data.children)) {
          data.children.forEach((item) => {
            this.markDeleted(item)
          })
        }
      },

      // 节点过滤
      filterNodeMethod(value, data, node) {
        return data.deleteFlag ? false : true
      },

      //删除二次确认公共
      async _deleteConfirm() {
        return this.$confirm(
          '此操作将永久删除该库位和下级库位, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
      },

      // 重名校验
      _checkName(targetData, locationNumber) {
        targetData = targetData
          .filter((item) => item.deleteFlag !== true) // 判断时去除已删除的
          .filter((i) => i.locationNumber?.trim() === locationNumber?.trim())
        if (targetData.length === 0) return true
        return false
        if (targetData.length === 1 && this.isEdit) return true
        return false
      },

      // 保存
      saveLoc() {
        let self = this
        this.$refs.form.validate((res) => {
          if (res) {
            let targetData =
              self.targetData === self.locs
                ? self.targetData
                : self.targetData.children
            if (
              this.isEdit &&
              this.form.locationNumber?.trim() ===
                this.currentData.locationNumber?.trim()
            ) {
              return this.cancelLoc()
            }
            if (!this._checkName(targetData, this.form.locationNumber)) {
              return this.$message.warning('名称重复')
            }
            if (this.isEdit) {
              this.currentData.locationNumber = this.form.locationNumber
              this.currentData.fullLocationNumber = ''
            } else {
              let loc = {
                locationLevel: this.currentLocationLevel,
                parentId: this.parentId,
                locationNumber: this.form.locationNumber,
                warehouseId: this.$route.query.warehouseId,
                fullLocationNumber: '',
                warehouseLocationId: '',
                tempId: tempId++,
              }

              targetData.push(loc)
            }

            this.cancelLoc()
          }
        })
      },

      //取消
      cancelLoc() {
        this.form.locationNumber = ''
        this.$refs.form.clearValidate()
        this.centerDialogVisible = false
      },

      // 获取locationIds
      _getLocationIds(data) {
        this.locationIds = this.locationIds || []
        if (data.warehouseLocationId) {
          this.locationIds.push(data.warehouseLocationId)
        }
        if (Array.isArray(data.children)) {
          data.children.forEach((item) => {
            this._getLocationIds(item)
          })
        }
      },

      // 删除库位
      delLoc({ locationNumber }, index) {
        const { warehouseId } = this.$route.query
        WarehouseManageInteractor.isDeleteWarehouseLocation({
          locationNumber,
          warehouseId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.locs.splice(index, 1)
          }
        })
      },
    },

    created() {
      const { warehouseId } = this.$route.query
      // 获取库区库位数据
      WarehouseManageInteractor.findLocationDetail(warehouseId).then((res) => {
        if (res.data?.length === 0) {
          res.data = [
            {
              fullLocationNumber: '系统库区',
              locationLevel: 0,
              locationNumber: '系统库区',
              warehouseId: this.$route.query.warehouseId,
              warehouseLocationId: '',
            },
          ]
        }
        this.locs = res.data || []
      })
    },
  }
</script>

<style lang="scss" scoped>
  .table-container {
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    .loc-tree-wrapper {
      flex: 1;
      overflow-y: auto;
      width: 60%;
      max-width: 560px;

      adding: 10px;
      border: 1px solid #eee;
      border-radius: 10px;

      padding: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .el-tree {
        width: 100%;
      }
    }
    .el-tree {
      width: 100%;
    }
    .page-header {
      flex: 1;
      align-items: flex-start;
      margin: 15px;
    }
  }
  .loc {
    width: 150px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
    & .el-icon-error {
      position: absolute;
      right: -5px;
      top: -5px;
      color: red;
      cursor: pointer;
      visibility: hidden;
    }
    &:hover {
      & .el-icon-error {
        visibility: visible;
      }
    }
  }
  .text-center {
    text-align: center;
  }
  .opt-wrapper {
  }

  .mr5 {
    margin-right: 5px;
  }
  .btn-wrappper {
    text-align: center;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;

    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    overflow: hidden;
    .node-content {
      width: calc(100% - 180px);
      .node-label {
        width: 100%;
        display: inline-block;
        vertical-align: bottom;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        font-weight: 500;
      }
    }
    .el-button {
      font-size: 15px;
    }
  }
</style>
<style>
  .loc-tree-popper {
    min-width: 180px !important;
  }
</style>
