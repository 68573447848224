<!--
  功能：客评中心
  作者：jiangyongqiang
  时间：2021年11月16日 20:20:50
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard
    title="客评中心"
    @tab-click="handleTabClick"
    :tabs="tabs"
    :singleNum="singleNum"
    :teamNum="teamNum"
    :loading="loading"
  >
    <div>
      <div v-if="list.length > 0">
        <template v-for="(item, index) in list">
          <IndexCardItem :key="index" class="unread-mails-item">
            <div
              class="content"
              @click="clientFollowClick(item)"
              :style="itemStyleObj"
            >
              <div class="mails-item--top">
                <span class="sender">你收到一条投诉,单号:</span>
                <span class="topic" :style="boldStyle.bold">
                  {{ item.evaluationCode }}
                </span>
              </div>
              <div class="mails-item--bottom">
                <p>
                  <span class="label">创建人:</span>
                  <span class="content">{{ item.creator }}</span>
                </p>
                <p>
                  <span class="label">接收时间:</span>
                  <span class="content">
                    {{ item.receiveDate | timeFilter(true) }}
                  </span>
                </p>
              </div>
            </div>
          </IndexCardItem>
        </template>
        <div
          @click="readMore"
          style="text-align: center; margin-top: 10px"
          v-if="showMore"
        >
          <el-link type="primary">查看更多</el-link>
        </div>
      </div>
      <IndexCardEmpty v-else></IndexCardEmpty>
    </div>
    <!-- 回复评价，客户追加 -->
    <BusinessReply
      ref="businessReply"
      :detail="detail"
      :params="params"
      @refresh="refresh"
    />
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import { HomeInteractor, CustomerCenterInteractor } from '@/core'
  import BusinessReply from '@/views/customer-manage/customer-center/components/business-reply'
  export default {
    name: 'CustomerReview',
    mixins: [mixins],
    props: {},
    components: {
      BusinessReply,
    },
    // 组件状态值
    data() {
      return {
        list: [],
        tabs: false,
        detail: {},
        params: {
          //控制参数
          show: false,
          transactionType: 2,
        },
        total: 0,
      }
    },
    computed: {
      showMore() {
        return this.total > 30
      },
    },

    methods: {
      readMore() {
        let routeData = this.$router.resolve({
          path: '/client-supplier/customer-center',
        })
        window.open(routeData.href, '_blank')
      },

      refresh() {
        this.getList()
      },

      getList() {
        HomeInteractor.needToBe({
          pageLe: 30,
          pageNo: 1,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.list = res.data.data || []
            this.total = res.data.total
          }
        })
      },
      clientFollowClick({ customerComplainId: businessId }) {
        CustomerCenterInteractor.getDetail(businessId).then((res) => {
          if (res?.code === '000000') {
            setTimeout(() => {
              this.$refs.businessReply.initFiles(businessId)
            }, 200)

            this.detail = res.data
            this.params.title = 'cusCenter.Replytoreview'
            this.params.show = true
          }
        })
        this.$emit('get-list')
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .unread-mails-item {
    font-size: 14px;
    .mails-item--top {
      margin-bottom: 6px;
      word-break: break-all;
      color: #000;
      .sender {
        margin-right: 10px;
      }
    }
    .mails-item--bottom {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      p {
        color: #5e5e5e;

        .label {
          margin-right: 5px;
        }
      }
    }
  }
</style>
