import { render, staticRenderFns } from "./cargoManage.vue?vue&type=template&id=2ef34aa4&scoped=true&"
import script from "./cargoManage.vue?vue&type=script&lang=js&"
export * from "./cargoManage.vue?vue&type=script&lang=js&"
import style0 from "./cargoManage.vue?vue&type=style&index=0&id=2ef34aa4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ef34aa4",
  null
  
)

export default component.exports