<template>
  <div class="tool-bar">
    <el-select
      placeholder="用户邮箱"
      v-model="form.emailAccount"
      value-key="emailAccount"
      @change="searchFilter"
      clearable
    >
      <el-option :label="$t('other.all')" value="" selected></el-option>
      <el-option
        v-for="(item, index) in accountList"
        :key="index"
        :value="item.emailAccount"
        :label="item.emailAccount"
      ></el-option>
    </el-select>
    <!-- placeholder="邮件类型" -->
    <el-select
      :placeholder="$t('placeholder.mailType')"
      v-model="form.boxType"
      @change="searchFilter"
      clearable
    >
      <!-- <el-option label="全部" :value="null" selected></el-option>
      <el-option label="收件" :value="0"></el-option>
      <el-option label="发件" :value="1"></el-option> -->
      <el-option :label="$t('other.all')" :value="null" selected></el-option>
      <el-option :label="$t('selOpt.receive')" :value="0"></el-option>
      <el-option :label="$t('selOpt.send')" :value="1"></el-option>
    </el-select>
    <!-- placeholder="邮件状态" -->
    <el-select
      :placeholder="$t('placeholder.mailStatus')"
      v-model="form.reply"
      @change="searchFilter"
      clearable
    >
      <!-- <el-option label="全部" :value="null" selected></el-option>
      <el-option label="已回复" :value="1"></el-option>
      <el-option label="未回复" :value="0"></el-option> -->
      <el-option :label="$t('other.all')" :value="null" selected></el-option>
      <el-option :label="$t('selOpt.replied')" :value="1"></el-option>
      <el-option :label="$t('selOpt.unanswered')" :value="0"></el-option>
    </el-select>
    <!-- placeholder="附件" -->
    <el-select
      :placeholder="$t('placeholder.appendix')"
      v-model="form.eliminate"
      @change="searchFilter"
      clearable
    >
      <!-- <el-option label="全部" :value="null" selected></el-option>
      <el-option label="有附件" :value="1"></el-option>
      <el-option label="无附件" :value="0"></el-option> -->
      <el-option :label="$t('other.all')" :value="null" selected></el-option>
      <el-option :label="$t('selOpt.withAttachments')" :value="1"></el-option>
      <el-option :label="$t('selOpt.noAttachment')" :value="0"></el-option>
    </el-select>
    <!-- start-placeholder="开始日期"
      end-placeholder="结束日期" -->
    <!-- range-separator="至" -->
    <el-date-picker
      v-model="range"
      type="daterange"
      class="mr10"
      style="width: 300px"
      value-format="yyyy-MM-dd"
      format="yyyy-MM-dd"
      :range-separator="$t('other.To')"
      :start-placeholder="$t('placeholder.startDate')"
      :end-placeholder="$t('placeholder.endDate')"
      size="medium"
      clearable
    ></el-date-picker>
    <!-- placeholder="请输入搜索内容" -->
    <el-input
      v-model="form.keyWord"
      :placeholder="$t('placeholder.plsInput')"
      style="width: 280px"
      clearable
      @clear="searchFilter"
      @keydown.native.enter="searchFilter"
      class="type-select"
    >
      <el-select
        v-model="form.keyWordSearchType"
        slot="prepend"
        :placeholder="$t('placeholder.plsSel')"
        clearable
      >
        <el-option :label="$t('other.all')" :value="0"></el-option>
        <el-option :label="$t('myMail.Sender')" :value="1"></el-option>
        <el-option :label="$t('myMail.Recipient')" :value="2"></el-option>
        <el-option :label="$t('myMail.Topic')" :value="5"></el-option>
        <el-option :label="$t('selOpt.content')" :value="6"></el-option>
      </el-select>
    </el-input>
    <el-button plain type="primary" class="ml10" @click="searchFilter">
      <!-- 搜索 -->
      {{ $t('mailcheck.Search') }}
    </el-button>
    <el-button plain class="ml10" @click="clearSearch">
      <!-- 重置 -->
      {{ $t('mailcheck.Reset') }}
    </el-button>
  </div>
</template>
<script>
  import { MailSettingInteractor } from '@/core'
  import { BusEmit, BusOn } from '@/utils/eventBus'
  export default {
    name: 'MailCenterToolBars',
    data() {
      return {
        accountList: [],
        form: {
          emailAccount: '',
          boxType: '',
          reply: '', //:0未回复,1已回复
          eliminate: '',
          keyWordSearchType: 0,
          keyWord: '',
          startDate: '',
          endDate: '',
          centralEmails: [],
        },
        range: [],
      }
    },
    mixins: [BusOn],
    watch: {
      range(value) {
        if (value && value.length) {
          this.form.startDate = value[0]
          this.form.endDate = value[1]
        } else {
          this.form.startDate = ''
          this.form.endDate = ''
        }
        this.searchFilter()
      },
      'form.emailAccount'(val) {
        if (val) {
          this.form.centralEmails = [val]
        } else {
          this.form.centralEmails = this.accountList.map(
            (item) => item.emailAccount
          )
        }
      },
    },
    methods: {
      // 邮件检查获取邮箱下拉框数据
      async getAccountList(id) {
        this.accountList = []
        if (!id) {
          return
        }
        let res = await MailSettingInteractor.getMailAccountListByUserIdApi({
          businessId: id,
        })
        if (res && res.code == '000000') {
          this.accountList = res.data
          this.form.emailAccount = ''
          BusEmit('MailCenterMailLists', 'searchFilter', this.form)
        }
      },
      // 邮件中心获取邮箱下拉数据
      setAccountList(arr) {
        this.accountList = arr
        this.form.centralEmails = arr.map((item) => item.emailAccount)
        BusEmit('MailCenterMailLists', 'searchFilter', this.form)
      },
      searchFilter() {
        BusEmit('MailCenterMailLists', 'searchInitFilter', this.form)
      },
      clearSearch() {
        this.range = []
        this.form = {
          emailAccount: '',
          boxType: null,
          reply: null, //:0未回复,1已回复
          eliminate: null,
          keyWordSearchType: 0,
          keyWord: '',
          startDate: '',
          endDate: '',
        }
        this.form.centralEmails = this.accountList.map(
          (item) => item.emailAccount
        )
        BusEmit('MailCenterMailLists', 'searchInitFilter', this.form)
      },
      clearSearchEmai() {
        this.form.emailAccount = ''
        this.form.centralEmails = this.accountList.map(
          (item) => item.emailAccount
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-input-group__prepend {
      background-color: #fff;
      .el-select {
        width: 80px;
        .el-input__inner {
          padding-left: 5px;
        }
      }
    }
    .el-range-separator {
      width: 2em;
    }
  }
  .tool-bar {
    display: flex;
    align-items: center;
    > .el-select {
      margin-right: 10px;
    }
    ::v-deep .type-select {
      .el-input-group__prepend {
        padding: 0 20px !important;
      }
    }
  }
</style>
