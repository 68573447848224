const renderHeaderMixin = {
  methods: {
    renderHeader(h, { column, $index }) {
      let realWidth = 0
      let span = document.createElement('span')
      span.style.display = 'inline-block'
      span.innerText = column.label
      document.body.appendChild(span)
      realWidth = span.clientWidth
      if (this.lang === 'en') {
        column.width = realWidth + 30
      } else {
        column.minWidth = realWidth + 30
      }
      document.body.removeChild(span)
      return h('span', {}, [column.label])
    },
  },
}

export default renderHeaderMixin
