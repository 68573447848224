<template>
  <div class="search-form">
    <el-form
      class="search-form-content"
      ref="form"
      :model="form"
      label-position="top"
    >
      <el-form-item label="公司名称">
        <el-input
          v-model="form.companyName"
          placeholder="请输入公司名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="客户来源">
        <el-select
          clearable
          class="w100"
          v-model="form.customerSourceId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerSource"
            :key="item.sourceId"
            :label="item.sourceName"
            :value="item.sourceId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户类型">
        <el-select
          clearable
          v-model="form.customerType"
          placeholder="请选择"
          class="w100"
        >
          <el-option label="潜在客户" :value="0" />
          <el-option label="正式客户" :value="1" />
          <el-option label="黑名单客户" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="客户等级">
        <el-select
          clearable
          v-model="form.customerRank"
          placeholder="请选择"
          class="w100"
        >
          <el-option
            v-for="item in customerRankOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="转入公海时间">
        <el-date-picker
          v-model="throwDaterange"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="throwDaterangeChange"
        />
      </el-form-item>

      <el-form-item label="创建人">
        <el-input
          v-model="createName"
          placeholder="请选择"
          clearable
          @focus="selectUserClick('createName')"
          @change="createNameChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="createDaterange"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="createDaterangeChange"
        />
      </el-form-item>
    </el-form>
    <div class="operate">
      <el-button type="primary" @click="searchClick">确定</el-button>
      <el-button @click="resetClick">重置</el-button>
    </div>

    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
import UserChoose from '@/components/userChoose2'
export default {
  name: 'ClientManageSearch',
  components: { UserChoose },
  data() {
    return {
      customerSource: [], //客户来源下拉
      customerRankOptions: [
        //客户等级
        {
          value: 1,
          label: '1级',
        },
        {
          value: 2,
          label: '2级',
        },
        {
          value: 3,
          label: '3级',
        },
        {
          value: 4,
          label: '4级',
        },
        {
          value: 5,
          label: '5级',
        },
      ],
      form: {},
      throwDaterange: [], //转入公海时间
      createDaterange: [], //创建时间
      createName: '', //创建人
      createNameId: [], //创建人id
      isUserChoose: '',
    }
  },
  created() {
    //客户来源下拉
    this.pubApi.customerSourceList({}).then((response) => {
      if (response.code === '000000') {
        this.customerSource = response.data
      }
    })
  },
  methods: {
    // 确定searchClick
    searchClick() {
      //转入公海时间
      if (this.throwDaterange && this.throwDaterange.length) {
        this.form.startSeasTime = this.throwDaterange[0]
        this.form.endSeasTime = this.throwDaterange[1]
      }
      //创建时间
      if (this.createDaterange && this.createDaterange.length) {
        this.form.startCreateTime = this.createDaterange[0]
        this.form.endCreateTime = this.createDaterange[1]
      }
      //创建人
      if (this.createName) {
        this.form.creatorId = this.createNameId.join(',')
      }
      this.$emit('searchClick', this.form)
    },
    //创建时间清空
    createDaterangeChange(val) {
      if (!val) {
        this.form.startCreateTime = ''
        this.form.endCreateTime = ''
      }
    },
    //转入公海时间清空
    throwDaterangeChange(val) {
      if (!val) {
        this.form.startSeasTime = ''
        this.form.endSeasTime = ''
      }
    },

    //创建人选择
    selectUserClick(str) {
      this.isUserChoose = str
      if (str === 'createName') {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.createNameId)
      }
    },
    //创建人返回
    chooseUser(checkedUser) {
      if (this.isUserChoose === 'createName') {
        this.createNameChange()
      }
      if (!checkedUser.length) {
        return
      }
      let userName = []
      checkedUser.forEach((item) => {
        userName.push(item.name)
        if (this.isUserChoose === 'createName') {
          this.createNameId.push(item.id)
          this.createName = userName.join(',')
        }
      })
    },
    // 删除创建人
    createNameChange() {
      this.createNameId = []
      this.createName = ''
      this.form.creatorId = ''
    },
    // cancelClick
    resetClick() {
      this.$refs.form.resetFields()
      this.form = this.$options.data().form
      this.throwDaterange = ''
      this.createDaterange = ''
      this.createName = ''
      this.createNameId = []
    },
  },
}
</script>

<style lang="scss" scoped>
.search-form {
  height: 85%;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 100px;
  .search-form-content {
    padding: 0 20px;
  }
  .operate {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ededed;
    padding: 20px;
  }
}
.search_form .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}
::v-deep {
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 30px 0 28px 0 !important;
  }
  .el-drawer__body {
    height: calc(100% - 44px);
  }
}
</style>
