<template>
  <div :class="expendFlag ? 'shrink-bar hidden' : 'shrink-bar'">
    <div class="header-aside" @click="handleExpend">
      <slot name="header"></slot>
    </div>
    <div class="main-aside">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expendFlag: true,
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.expendFlag = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleExpend() {
      this.expendFlag = !this.expendFlag
      if (!this.expendFlag) {
        this.$emit('getList')
      }
    },
    showExpend() {
      this.expendFlag = false
      this.$emit('getList')
    },
  },
}
</script>

<style lang="scss">
.shrink-bar {
  .el-icon-arrow-down {
    transform: rotate(180deg) !important;
  }
  &.hidden {
    .main-aside {
      display: none;
    }
    .el-icon-arrow-down {
      transform: rotate(0) !important;
    }
  }
}
</style>
