<template>
  <div class="list-wrapper">
    <div class="tab-cont" v-if="type === 1 && showMailTabsBol">
      <MailTabs @tabItemCkickFn="tabItemCkickFn" :tabs="tabs"></MailTabs>
    </div>
    <div class="mail-list">
      <div class="item" v-for="item in mailList" :key="item.mail" :class="
          currentMail == item.mailId ? 'search-item active' : 'search-item'
        " @click="getDetail(item)">
        <div class="item-head">
          <mark class="mark receive" v-if="getMailType(item.boxName) != 1">
            <!-- 收件 -->
            {{ $t('selOpt.receive') }}
          </mark>
          <mark class="mark send" v-if="getMailType(item.boxName) == 1">
            <!-- 发件 -->
            {{ $t('selOpt.send') }}
          </mark>
          <span class="name" :style="
              item.readFlag != 1
                ? 'font-weight:bold;color:#222222'
                : 'font-weight:normal;color:#222222'
            ">
            <!-- {{ item.nodeName }} -->
            <span v-html="item.nodeName_deal"></span>

            <el-tooltip effect="dark" :content="item.emailMemo" placement="top">
              <i v-show="item.emailMemo" class="remark iconfont icon-qizhi"></i>
            </el-tooltip>
            <span class="email-account" v-if="showAccount">
              {{ item.emailAccount }}
            </span>
          </span>
          <time class="time">
            {{ utils.formatTime(item.sendTime, 'mail') }}
          </time>
        </div>
        <div class="item-body">
          <div class="dt">
            <!-- v-if="item.boxType == 0" 弃用 -->
            <template>
              <!-- 未读 -->
              <!-- content="未读邮件" -->
              <el-tooltip :content="$t('iconbtn.unreadmail')" placement="top" v-if="item.readFlag != 1">
                <i class="iconfont icon-youxiang"></i>
              </el-tooltip>
              <!-- 已发邮件 -->
              <!-- content="已发邮件" -->
              <el-tooltip :content="$t('iconbtn.sentmail')" placement="top" v-if="item.issued == 1">
                <i class="iconfont icon-qingqiuyifasong send"></i>
              </el-tooltip>
              <!-- 回复and转发 -->
              <!-- content="已回复转发邮件" -->
              <el-tooltip :content="$t('iconbtn.forwardedmessagereplied')" placement="top"
                v-else-if="item.reply == 1 && item.isForward == 1">
                <i class="iconfont icon--huxiangguanzhu replyed"></i>
              </el-tooltip>
              <!-- 已转发 -->
              <!-- content="已转发邮件" -->
              <el-tooltip :content="$t('iconbtn.messageforwarded')" placement="top" v-else-if="item.isForward == 1">
                <i class="iconfont icon-zhuanfa1 replyed"></i>
              </el-tooltip>
              <!-- 已回复 -->
              <!-- content="已回复邮件" -->
              <el-tooltip :content="$t('iconbtn.messagereplied')" placement="top" v-else-if="item.reply == 1">
                <i class="iconfont icon-huifu replyed"></i>
              </el-tooltip>
              <!-- content="已读邮件" -->
              <el-tooltip :content="$t('iconbtn.readmail')" placement="top" v-else-if="item.readFlag == 1">
                <i class="iconfont icon-youxiang2 looked"></i>
              </el-tooltip>
              <!-- 分配邮件 -->
              <el-tooltip :content="$t('mailConfig.Assigned')" placement="top"
                v-if="item.allocationFlag == 1 && type === 1">
                <i class="iconfont icon-fenpei2 fenpei"></i>
              </el-tooltip>
            </template>
          </div>
          <div class="dd">
            <div class="dd-item">
              <p class="summary">
                <i v-if="item.enclosure == 1" class="iconfont icon-biezhen file"></i>
                <!-- <i class="iconfont icon-gantanhao warning"></i> -->
                <span v-if="item.title" class="mail-title" :style="
                    item.readFlag != 1
                      ? 'font-weight:bold;color:#222222'
                      : 'font-weight:normal;color:#666666'
                  " v-html="item.title_deal">
                  <!-- {{ utils.htmlToSetText(item.title) | lengthLimit(70) }} -->
                </span>
                <span v-else>--</span>
              </p>
              <el-tag class="email-center" v-if="type === 1 && item.centralStatus === 1">
                {{ $t('mailConfig.EmailCenter') }}
              </el-tag>
            </div>
            <p class="desc" v-if="item.content" :style="
                item.readFlag != 1
                  ? 'font-weight: normal; color: #666666'
                  : 'font-weight: normal; color: #999999'
              " v-html="item.content_deal">
              <!-- {{
                (item.content ? utils.removeTAG(item.content) || '--' : '--')
                  | lengthLimit(80)
              }} -->
            </p>
            <p class="desc" v-else>--</p>
            <ul class="label-list" v-if="item.labelList && item.labelList.length">
              <li v-for="label in item.labelList" :key="label.mailLabelConfigId"
                :style="{ 'background-color': label.labelColor }">
                <span class="label">{{ label.labelName }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="mailList.length == 0">
        <!-- 暂无数据 -->
        {{ $t('other.noData') }}
      </div>
    </div>
    <div class="search-pagination">
      <el-select class="page-size" v-model="page.pageSize" @change="changePageSize" size="mini">
        <!-- <el-option :value="10" label="10条/页"></el-option>
        <el-option :value="20" label="20条/页"></el-option>
        <el-option :value="50" label="50条/页"></el-option>
        <el-option :value="100" label="100条/页"></el-option>
        <el-option :value="200" label="200条/页"></el-option> -->
        <el-option :value="10" :label="$t('selOpt.$10')"></el-option>
        <el-option :value="20" :label="$t('selOpt.$20')"></el-option>
        <el-option :value="50" :label="$t('selOpt.$50')"></el-option>
        <el-option :value="100" :label="$t('selOpt.$100')"></el-option>
        <el-option :value="200" :label="$t('selOpt.$200')"></el-option>
      </el-select>
      <div class="page-current">
        <el-button type="text" icon="el-icon-arrow-left" @click="beforePage" :disabled="page.pageNo < 2">
          <!-- 上一页 -->
        </el-button>
        <span class="page-num">
          {{ page.total ? page.pageNo : 0 }} /
          {{ Math.ceil(page.total / page.pageSize) }}
        </span>
        <el-button type="text" icon="el-icon-arrow-right" @click="nextPage"
          :disabled="page.pageNo >= Math.ceil(page.total / page.pageSize)">
          <!-- 下一页 -->
        </el-button>
      </div>
      <div class="jump">
        <span>
          <!-- 前往 -->
          {{ $t('other.goto') }}
        </span>
        <el-input v-model.number="page.goPage" size="mini" @blur="goPage" :disabled="page.total == 1"></el-input>
        <span>
          <!-- 页 -->
          {{ $t('other.Page') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  import { MailSettingInteractor, systemInteractor } from '@/core'
  import { BusEmit, BusOn } from '@/utils/eventBus'
  import { getEmailName } from '../../my-mail/utils/mail-data'
  import { mapState } from 'vuex'
  import MailTabs from '@/components/MailTabs'
  import { getMailDealField } from '@/views/mail/my-mail/utils/tool'

  export default {
    name: 'InspectionMailList',
    mixins: [BusOn],
    data() {
      return {
        page: {
          pageNo: 1,
          pageSize: 10,
          goPage: 1,
          total: 0,
          pages: 0,
        },
        query: {},
        mailList: [],
        currentMail: '',
        userId: '', // 选中的员工
        showAccount: false,
        readProcessedFlag: 0, //点击状态
        tabs: [
          {
            nameCn: '全部',
            nameEn: 'All',
          },
          {
            nameCn: '未读',
            nameEn: 'Unread',
            num: 0,
          },
          {
            nameCn: '已读未处理',
            nameEn: 'Pending',
            num: 0,
          },
          {
            nameCn: '已处理',
            nameEn: 'Solved',
          },
        ],
        showMailTabsBol: true,
      }
    },
    components: {
      MailTabs,
    },
    props: {
      type: {
        type: Number,
        default: 1, //1是邮件检查 2 是中心站
      },
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
      }),
    },
    filters: {
      lengthLimit(val, num) {
        if (val.length > num) {
          return val.substring(0, num) + '...'
        } else {
          return val
        }
      },
    },
    methods: {
      //点击列表tab
      tabItemCkickFn(index) {
        this.page = {
          pageNo: 1,
          pageSize: 10,
          goPage: 1,
          total: 0,
          pages: 0,
        }
        this.readProcessedFlag = index
        this.getPageList()
      },
      // 搜索
      searchFilter(data = {}) {
        let dataArr = []
        let noKey = ['businessId', 'tenantId', 'centralEmails']
        let zoonKey = ['boxType', 'reply']
        for (let k in data) {
          if (
            (!noKey.includes(k) && data[k]) ||
            (zoonKey.includes(k) && data[k] === 0)
          ) {
            dataArr.push(k)
          }
        }
        if (dataArr.length) {
          this.showMailTabsBol = false
          this.readProcessedFlag = 0
        } else {
          this.showMailTabsBol = true
        }

        this.page.pageNo = 1
        this.page.goPage = 1
        this.query = data
        this.getPageList()
        this.showAccount = false
      },
      searchInitFilter(val) {
        this.searchFilter(val)
        this.showAccount = val.keyWordSearchType === 0 ? true : false
      },

      setUserId(id) {
        this.userId = id
        this.mailList = []
        BusEmit('InspectionMailInfo', 'show', null)
        // 邮件检查获取未读和已读未处理数量
        if (this.type === 1) {
          this.getQuantity(1)
          this.getQuantity(2)
        }
      },
      // 邮件检查获取未读和已读未处理数量
      async getQuantity(num) {
        const params = {
          businessId: this.userId,
          flag: num,
        }
        let res = await systemInteractor.getEmailCheckNumberApi(params)
        if (res?.code === '000000') {
          this.tabs[num].num = res?.data.num
        } else {
          this.tabs[num].num = 0
        }
      },
      // 获取邮件列表
      async getPageList() {
        let params = {
          ...this.query,
          ...this.page,
          businessId: this.userId || '999', //唐存说没有选中人员，就传999
          tenantId: this.userInfo.tenantId,
        }
        if (this.type === 1) {
          params.readProcessedFlag = this.readProcessedFlag
          delete params.centralEmails
        }
        if (this.type === 2) delete params.emailAccount
        let res = {}
        if (this.type === 1) {
          res = await MailSettingInteractor.getMemberMailListApi(params)
        } else {
          res = await MailSettingInteractor.centralEmailListApi(params)
        }
        if (res.code == '000000') {
          this.mailList = res.data?.records?.map((item) => {
            let { content_deal, nodeName_deal, title_deal } = getMailDealField(this.query.keyWord, item, this.utils)
            return {
              ...item,
              content_deal,
              nodeName_deal,
              title_deal,
            }
          })
          this.page.total = res.data.total || 0
          this.page.pages = res.data.pages || 0
          // 默认显示第一条邮件内容
          if (this.mailList.length) {
            // this.getDetail(this.mailList[0])
          } else {
            this.detail = null
            BusEmit('InspectionMailInfo', 'show', null)
          }
          // 标签转换
          this.mailList.forEach((item) => {
            if (item.label) {
              item['labelList'] = JSON.parse(item.label)
            } else {
              item['labelList'] = []
            }
            return item
          })
        }
      },
      //获取详情
      async getDetail(mail) {
        this.currentMail = mail.mailId
        this.myMailPrivilegeFn()
        BusEmit('InspectionMailInfo', 'setContent', mail)
        let res = await MailSettingInteractor.getMailDetailByIdApi(mail.mailId)
        if (res.code === '000000') {
          BusEmit('InspectionMailInfo', 'show', res.data)
        }
      },
      // 判断是否有分配权限
      async myMailPrivilegeFn() {
        let params = {
          mailIdList: [this.currentMail],
          requestSource: 2,
        }
        let res = await systemInteractor.myMailPrivilegeApi(params)
        if (res?.code === '000000') {
          BusEmit(
            'InspectionMailInfo',
            'getAllowAssignFn',
            res.data.allowAssign
          )
        }
      },

      //上一页
      beforePage() {
        if (this.page.pageNo > 1) {
          this.page.pageNo--
          this.page.goPage = this.page.pageNo
        }
        this.getPageList()
      },

      //切换页码
      changePageSize(val) {
        this.page.pageSize = val
        this.page.pageNo = 1
        this.page.goPage = this.page.pageNo
        this.getPageList()
      },

      //下一页
      nextPage() {
        if (this.page.pageNo < this.page.pages) {
          this.page.pageNo++
          this.page.goPage = this.page.pageNo
        }
        this.getPageList()
      },

      goPage() {
        if (Number(this.page.goPage) > Number(this.page.pages)) {
          this.page.goPage = this.page.pages
        }
        if (Number(this.page.goPage) < 0) {
          this.page.goPage = 1
        }
        this.page.pageNo = this.page.goPage
        this.getPageList()
      },
      getMailType(val) {
        return getEmailName(val) ? getEmailName(val).type : 0
      },

      // 重设邮件内容
      resetMailItemContet({ content, mailId }) {
        let orgExp = new RegExp('(' + this.query.keyWord + ')', 'ig')
        this.$set(currentMail, 'content', content)
        this.$set(
          currentMail,
          'content_deal',
          this.utils
            .removeTAG(content)
            .replace(orgExp, `<span style='background-color:yellow'>$1</span>`)
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  .list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .tab-cont {
    flex-shrink: 0;
    height: 40px;
  }

  .mail-list {
    flex: 1;
    overflow-y: auto;

    .no-data {
      text-align: center;
      color: #999;
      line-height: 40px;
    }

    .item {
      border-bottom: 1px solid #f5f7fa;
      padding: 6px 4px;
      background-color: #fff;
      cursor: pointer;

      &.active,
      &:hover {
        background-color: #f8f8f8;
      }
    }

    .item-head {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .mark {
        font-size: 12px;
        color: #fff;
        line-height: 16px;
        min-width: 30px;
        text-align: center;
        border-radius: 2px;
        margin-right: 10px;

        &.receive {
          background-color: #71aaff;
        }

        &.send {
          background-color: #facd91;
        }
      }

      .name {
        font-size: 14px;
        color: #333;
        line-height: 22px;
        flex: 1;
        display: inline-block;

        .icon-qizhi {
          color: #0486fe;
          margin-left: 5px;
        }
      }

      .email-account {
        font-size: 14px;
        color: #999999 !important;
        font-weight: normal !important;
        line-height: 22px;
        margin-right: 5px;
      }

      .time {
        font-size: 12px;
        color: #999;
        line-height: 22px;
      }
    }

    .item-body {
      display: flex;

      .dt {
        min-width: 30px;
        margin-right: 10px;
        text-align: right;

        i {
          color: #f7b500;

          &.mail-icon {
            display: block;
          }

          &.looked {
            color: #d9d9d9;
          }

          &.replyed {
            color: #2eb797;
          }

          &.send {
            color: #3abc38;
          }
        }
      }

      .dd {
        flex: 1;
        width: 200px;

        .file {
          color: #fa6400;
        }

        .dd-item {
          display: flex;
          align-items: center;
        }
      }

      .summary {
        flex: 1;
        font-size: 14px;
        color: #333;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.pr50 {
          padding-right: 50px;
        }

        &.pr100 {
          padding-right: 100px;
        }
      }

      .mail-title {
        font-weight: bold;
      }

      .desc {
        font-size: 14px;
        color: #999;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .label-list {
      overflow: hidden;

      li {
        float: left;
        margin-right: 10px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 12px;
        color: #fff;
        padding: 0 4px;
        border-radius: 2px;
        max-width: 100%;

        .label {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .remove-icon {
          margin-left: 4px;
          cursor: pointer;
          display: none;
        }

        &:hover {
          .remove-icon {
            display: block;
          }
        }
      }
    }
  }

  .search-pagination {
    flex-shrink: 0;
    height: 40px;
    background: #fff;
    border-top: 1px solid #f5f7fa;
    display: flex;
    align-items: center;
    padding: 0 20px;

    ::v-deep .el-select {
      width: 60px;
      margin-left: 2px;

      .el-input__inner {
        padding: 0 4px;
      }

      .el-input__suffix {
        display: none;
      }
    }

    .page-current {
      display: flex;
      width: 60px;
      flex: 1;
      align-items: center;
      justify-content: center;

      .page-num {
        padding: 0 10px;
      }
    }

    .jump {
      ::v-deep .el-input {
        width: 32px;
        margin-left: 2px;
        margin-right: 2px;

        .el-input__inner {
          padding-left: 4px;
          padding-right: 4px;
          text-align: center;
        }
      }
    }
  }

  i.fenpei {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #d9d9d9 !important;
  }
</style>