<template>
  <el-dialog
    title="收货公司、地址黑名单"
    :visible.sync="blackAddressVisible"
    width="550px"
    :before-close="cancel"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="类型" prop="amountShort">
        <el-select
          v-model="form.amountShort"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option label="公司" value="company"></el-option>
          <el-option label="地址" value="address"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键字" prop="amountName">
        <el-input
          placeholder="请输入"
          v-model="form.amountName"
          type="textarea"
          :rows="3"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button type="primary" @click="submit" :loading="loading">
          确认
        </el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    blackAddressVisible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        amountShort: '',
        amountName: '',
      },
      rules: {
        amountShort: [{ required: true, message: '请选择', trigger: 'change' }],
        amountName: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      loading: false,
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          this.save()
          this.cancel()
        }
      })
    },
    cancel() {
      this.form = this.$options.data().form
      this.$refs.form.resetFields()
      this.$emit('update:blackAddressVisible', false)
    },
    // 编辑展示详情
    getDetail(row) {
      this.form = { ...row }
    },
    // 父组件 传值
    save() {
      this.$emit('saveBlack', this.form)
      this.loading = false
    },
  },
}
</script>