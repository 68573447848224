<template>
  <el-dialog
    title="订单详情"
    :visible.sync="dialogReceiptDetailVisible"
    width="1100px"
    @close="close"
    :close-on-click-modal="false"
  >
    <!-- 销售订单 -->
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="8">
          <p>订单编号</p>
          <!-- 销售订单跳转 -->
          <router-link
            v-if="detail.purchaseOrderId"
            target="_blank"
            :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
              detail.purchaseOrderId
            }&noReturn=${true}`"
          >
            <span class="page-link">{{ detail.number }}</span>
          </router-link>
        </el-col>
        <el-col :span="8">
          <p>客户代表</p>
          <span class="span-text">{{ detail.buyerName }}</span>
        </el-col>
        <el-col :span="8">
          <p>订单状态</p>
          <el-tag class="c_f" :type="detail.state | orderStatusTagFilter">
            {{ purchaseOrderStatus(detail.state) }}
          </el-tag>
        </el-col>
      </el-row>

      <el-row class="mt30">
        <p class="f_s_15">付款情况</p>
        <!-- 上table -->
        <table
          class="table-custom mt10"
          align="center"
          cellspacing="0"
          cellpadding="0"
          width="100%"
        >
          <tr height="50px">
            <td width="94px">订单金额</td>
            <td width="94px" class="f_w_6">
              <span v-if="detail.amountTotal">
                {{ utils.moneyFormat(detail.amountTotal) }}
              </span>
              <span v-else>0</span>
            </td>

            <td width="94px">已付金额</td>
            <td width="94px" class="c_danger f_w_6">
              {{ utils.moneyFormat(yifu) }}
            </td>

            <td width="94px">未付金额</td>
            <td width="94px" class="c_success f_w_6">
              <span v-if="detail.receivablePrice">
                {{ utils.moneyFormat(detail.amountTotal - yifu) }}
              </span>
              <span v-else>0</span>
            </td>

            <td width="94px">退款金额</td>
            <td width="94px" class="c_danger f_w_6">0</td>

            <td width="94px">交易金额</td>
            <td width="94px" class="c_danger f_w_6">
              <span>
                {{ yifu }}
              </span>
            </td>
          </tr>
        </table>

        <!-- 下table -->
        <el-table
          border
          :data="orderTableData"
          :default-sort="{ prop: 'time', order: 'descending' }"
        >
          <el-table-column
            prop="paymentDate"
            align="center"
            label="时间"
          ></el-table-column>
          <el-table-column prop="paymentNodeName" align="center" label="类型">
            <template slot-scope="scope">
              账期-{{ scope.row.percent * 100 }}%&nbsp;{{
                scope.row.paymentNodeName
              }}
            </template>
          </el-table-column>
          <el-table-column prop="money" align="center" label="金额">
            <template slot-scope="scope">
              <span v-if="scope.row.amountActual > 0" class="f_w_6 c_success">
                +{{ moneySign }}{{ scope.row.amountActual }}
              </span>
              <span v-else class="c_danger f_w_6">
                -{{ moneySign }}{{ scope.row.amountActual }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="danhao" align="center" label="单号">
            <template slot-scope="scope">
              <span class="blue-text" @click="receiptNoClick(scope.row)">
                {{ scope.row.paymentOrderNumber }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div style="margin-top: 40px" class="text-center">
            <el-button @click="close" class="pl20 pr20">关 闭</el-button>
          </div>
        </el-col>
      </el-row>

      <!-- 收款单的详情 (销售订单)-->
      <ReceiptDetailModel1 ref="ReceiptDetailModel1" />
      <!-- 收款单的详情 (采购退款)-->
      <ReceiptDetailModel2 ref="ReceiptDetailModel2" />

      <!-- 付款单详情 (采购订单1) -->
      <PaymentDetailModel ref="PaymentDetailModel" />
      <!-- 付款单详情弹窗（销售退款2） -->
      <PaymentDetailModel2 ref="PaymentDetailModel2" />
    </div>
  </el-dialog>
</template>

<script>
import { dinggetDetailForShow } from '@/api/finance/finance-payment'
import { purchaseOrderStatus } from '@/utils/en-match-zh'

// 引入收款
import ReceiptDetailModel1 from '@/views/finance-center/credit-manage/components/receipt-detail-model1'
import ReceiptDetailModel2 from '@/views/finance-center/credit-manage/components/receipt-detail-model2'

//引入付款
import PaymentDetailModel from '@/views/finance-center/payment-manage/components/payment-detail-model'
import PaymentDetailModel2 from '@/views/finance-center/payment-manage/components/payment-detail-model2'

export default {
  name: 'PurchaseModel',
  components: {
    ReceiptDetailModel1,
    ReceiptDetailModel2,
    PaymentDetailModel,
    PaymentDetailModel2,
  },
  data() {
    return {
      detail: {},
      dialogReceiptDetailVisible: false, //订单详情弹窗
      yifu: '', //已付金额
      moneySign: '',
      orderTableData: [],
    }
  },
  // 状态过滤
  filters: {
    //销售订单状态
    orderStatusTagFilter(status) {
      const statusMap = {
        1: 'info', //录入
        2: 'danger', //已驳回
        3: 'warning', //已撤销
        4: 'primary', //审核中
        5: 'warning', //采购中
        6: 'warning', //验货中
        7: 'success', //已完成
        8: 'danger', //已作废
        9: 'warning', //销售订单异常
        // 10: 'warning', //供应商延期
        // 10: 'success', //已入库
        10: 'danger', //入库异常
      }
      return statusMap[status]
    },
  },
  methods: {
    //这里一定是采购订单
    showDetail(code) {
      this.getDinggetDetailForShow(code)
      this.dialogReceiptDetailVisible = true
    },

    //采购单单详情
    async getDinggetDetailForShow(code) {
      let response = await dinggetDetailForShow({
        businessId: code,
      })
      if (response.code == '000000') {
        this.detail = response.data
        this.moneySign = this.detail.currency === '美元' ? '$' : '￥'
        // 筛选出有时间的数据
        this.orderTableData =
          response.data.purchasePaymentMethodShowVOList.filter((item) => {
            return item.paymentDate
          })
        // 遍历数据相加已付金额
        this.orderTableData.forEach((item) => {
          this.yifu += item.amountActual
        })
      }
    },

    //单号click(Sk收款单1，FK付款单2)
    receiptNoClick(row) {
      if (row.paymentOrderNumber.includes('SK')) {
        //收款单（type2：销售收订单，type：1采购退款）
        let state = row.state === '9' ? 4 : 1
        if (row.type === 0) {
          this.$refs['ReceiptDetailModel1'].showReceiptDetail(
            row.paymentOrderNumber,
            state,
            false
          )
        } else {
          //采购退款收款单
          this.$refs['ReceiptDetailModel2'].showReceiptDetail(
            row.paymentOrderNumber,
            state
          )
        }
      } else {
        //付款单(销售退款2，采购付款0)
        if (row.type === 2) {
          this.$refs['PaymentDetailModel2'].amendRow = row.paymentOrderId
          this.$refs['PaymentDetailModel2'].showReceiptDetail()
        } else {
          this.$refs['PaymentDetailModel1'].amendRow = row.paymentOrderId
          this.$refs['PaymentDetailModel1'].showReceiptDetail()
        }
      }
    },

    close() {
      this.detail = {}
      this.dialogReceiptDetailVisible = false
    },
    purchaseOrderStatus(val) {
      return purchaseOrderStatus(val)
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px 0px;
  .detail-header {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      .span-text {
        font-size: 16px;
        color: #000;
      }
    }
  }
  .table-custom {
    border-collapse: collapse;
    text-align: left;
    td {
      border: #ebeef5 1px solid;
      text-align: center;
    }
    td:nth-child(odd) {
      background-color: #f5f7fa;
    }
  }
}
.page-link {
  color: #409eff !important;
}
</style>
