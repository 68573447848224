<!-- 客户中心列表页 -->
<template>
  <div class="cus-table">
    <!-- :class="isMini ? 'table-fixed-mini' : 'table-fixed'" -->
    <component
      :is="currentTableComponent"
      :pagination="pagination"
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :data="tableData"
      :total="total"
      :max-height="auditShow ? '100px' : tableHeight"
      :extraHeight="130"
      @query="pageChange"
      @row-click="changeCurrent"
      highlight-current-row
      border
      :key="lang"
      ref="trackTable"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="lang === 'en' ? item.labelEn : item.label"
        show-overflow-tooltip
        :width="lang === 'en' ? item.widthEn : item.width"
        min-width="120"
        :resizable="true"
        align="center"
        :prop="item.prop"
        :class-name="item.prop"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'NO.'">{{ scope.$index + 1 }}</div>

          <!-- 关联客户代表 -->
          <div v-else-if="item.prop === 'salesman'">
            <span v-if="!scope.row.salesmanEn">--</span>
            <span v-else>
              {{ lang === 'en' ? scope.row.salesmanEn : scope.row.salesman }}
            </span>
          </div>
          <!-- 客户名称 -->
          <div v-else-if="item.prop === 'releatedCustomer'">
            <span v-if="!scope.row.releatedCustomerEn">--</span>
            <span v-else>
              {{
                lang === 'en'
                  ? scope.row.releatedCustomerEn
                  : scope.row.releatedCustomer
              }}
            </span>
          </div>
          <!-- 创建人 -->
          <div v-else-if="item.prop === 'creator'">
            {{ lang === 'en' ? scope.row.creatorEn : scope.row.creator }}
          </div>
          <!-- 评价类型 -->
          <div v-else-if="item.prop === 'evaluationType'">
            {{ getEvaluationType(scope.row.evaluationType) }}
          </div>
          <!-- 评价原因 -->
          <div v-else-if="item.prop === 'reason'">
            {{ getshowReason(scope.row.reason) }}
          </div>
          <!-- 原因说明 -->
          <div v-else-if="item.prop === 'reasonExplain'">
            {{ getReasonExplain(scope.row.reasonExplain) }}
          </div>
          <!-- 评价等级 -->
          <div v-else-if="item.prop === 'level'">
            {{ getlevel(scope.row.level) }}
          </div>
          <!-- 产品名称 -->
          <div v-else-if="item.prop === 'productCn'">
            {{
              lang === 'en'
                ? scope.row.productEn || '--'
                : scope.row.productCn || '--'
            }}
          </div>
          <div v-else-if="item.prop === 'status'">
            <el-popover
              placement="right"
              width="400"
              trigger="click"
              :disabled="scope.row.status != 4"
            >
              <div style="padding: 15px">
                <p style="margin-left: -20px">
                  【{{ lang === 'en' ? 'Rejection reason' : '驳回原因' }}】
                </p>

                <p>
                  {{ lang === 'en' ? 'Reject reason' : '驳回时间' }}:&nbsp;{{
                    scope.row.rejectTime
                  }}
                </p>
                <p>
                  {{
                    lang === 'en' ? 'Rejection Instructions' : '驳回说明'
                  }}:&nbsp;{{ scope.row.rejectReason }}
                </p>
              </div>
              <!-- <span></span> -->
              <el-tag slot="reference" :type="gettagtype(scope.row.status)">
                {{ getEvaluationStatus(scope.row.status) }}
              </el-tag>
            </el-popover>
          </div>
          <!-- 评价等级 -->
          <div v-else-if="item.prop === 'level'">
            <p v-if="!scope.row.level.includes('★')">
              {{ scope.row.level }}
            </p>
            <template v-else>
              <span style="color: #fce048; font-size: 20px">
                {{ scope.row.level }}
              </span>
            </template>
          </div>
          <div v-else>{{ scope.row[item.prop] || '--' }}</div>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column
        :label="lang === 'en' ? 'Operate' : '操作'"
        width="240"
        align="center"
        fixed="right"
        v-if="!auditShow"
      >
        <template slot-scope="scope">
          <!-- 编辑 -->
          <el-tooltip
            :content="$t('cusCenter.Edit')"
            placement="top"
            v-if="[1, 4].includes(scope.row.status)"
          >
            <el-button
              @click="edit(scope.row)"
              type="primary"
              plain
              size="mini"
              icon="el-icon-edit"
              circle
            ></el-button>
          </el-tooltip>

          <!-- 业务回复 -->
          <el-tooltip
            :content="$t('cusCenter.businessreply')"
            placement="top"
            v-if="[1, 4].includes(scope.row.status)"
          >
            <el-button
              @click="businessReply(scope.row, 2)"
              type="primary"
              plain
              size="mini"
              icon="el-icon-edit-outline"
              circle
            ></el-button>
          </el-tooltip>

          <!-- 客户反馈追加 -->
          <el-tooltip
            :content="$t('cusCenter.Additionalcustomerfeedback')"
            placement="top"
            v-if="[1, 4].includes(scope.row.status)"
          >
            <el-button
              @click="businessReply(scope.row, 3)"
              type="primary"
              plain
              size="mini"
              icon="el-icon-chat-dot-square"
              circle
            ></el-button>
          </el-tooltip>

          <!-- 申请投诉完结 -->
          <el-tooltip
            v-if="[1, 4].includes(scope.row.status)"
            :content="$t('cusCenter.Complaintapplicationcompleted')"
            placement="top"
          >
            <el-button
              @click="finish(scope.row)"
              type="primary"
              plain
              size="mini"
              icon="iconfont icon-jieshu"
              circle
            ></el-button>
          </el-tooltip>

          <!-- 删除 -->
          <el-tooltip
            :content="$t('cusCenter.delete')"
            placement="top"
            v-allowed="['REMARKCENTER:DELETE']"
            v-if="[1, 4].includes(scope.row.status)"
          >
            <el-button
              @click="del(scope.row)"
              class="icon-r"
              type="primary"
              plain
              size="mini"
              icon="el-icon-delete"
              circle
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </component>
    <!-- 回复评价，客户追加 -->
    <BusinessReply
      ref="businessReply"
      :detail="detail"
      :params="params"
      @refresh="pageChange"
    />
    <!-- 审核选择 -->
    <AuditChoose ref="AuditChoose" @auditData="saveAuditClick" />
  </div>
</template>

<script>
  import {
    evaluationTypeList,
    evaluationSourceList,
    evaluationLevelList,
    statusList,
  } from '@/views/customer-manage/helper/data'
  import { mapGetters } from 'vuex'
  import BusinessReply from './business-reply'
  import {
    deleteById,
    listPage,
    getDetail,
    applyDone,
    checkUpdateCustomerComplain,
  } from '@/api/customer-manage/customer-center'
  import AuditChoose from '@/components/AuditChoose'
  import mixin from '../../helper/mixins'

  export default {
    name: 'CusTable',
    mixins: [mixin],
    props: {
      auditShow: {
        type: Boolean,
        default: false,
      },
      tableData: {
        type: Array,
        default: () => [],
      },
      page: {
        type: Object,
        default: () => ({
          pageNo: 1,
          pageLe: 10,
        }),
      },
      total: {
        type: Number,
        default: 0,
      },
      pagination: {
        type: Boolean,
        default: true,
      },
      tableHeight: {
        type: String,
        default: '',
      },
      columns: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        detail: {
          // 客诉详情
        },
        params: {
          //控制参数
          show: false,
          transactionType: 1,
        },
      }
    },
    computed: {
      finallyColumns() {
        const columns = this.columns.filter((item) => item.isShow === true)
        if (!this.auditShow) {
          return columns
        } else {
          return columns.filter((item) => item.prop !== 'status')
        }
      },
      isMini() {
        return document.body.clientHeight > 801 ? false : true
      },
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      currentTableComponent() {
        return this.auditShow ? 'el-table' : 'ErpTable'
      },
    },
    components: {
      BusinessReply,
      AuditChoose,
    },
    methods: {
      gettagtype(status) {
        const s2t = {
          1: 'info',
          2: 'warning',
          3: 'success',
          4: 'error',
        }
        return s2t[status]
      },
      // 权限
      _checkOption(option) {
        if (!option) {
          this.$message.warning(
            this.lang === 'en' ? 'No permission yet' : '暂无权限'
          )
          return false
        }
        return true
      },
      // 审核回调
      saveAuditClick(auditData) {
        auditData.orderId = this.currentRow.customerComplainId //单据id
        let tableData = JSON.parse(JSON.stringify(this.tableData))
        const requestData = {
          auditObjectDTO: auditData,
          customerComplainId: auditData.orderId,
        }

        applyDone(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success(this.$t('reqmsg.$7'))
            // this.$emit('reset-pageNo')
            this.$emit('get-list')
          }
        })
      },

      // 获取列表
      pageChange() {
        this.$emit('get-list')
      },
      // 编辑
      edit(row) {
        checkUpdateCustomerComplain({
          customerComplainId: row.customerComplainId,
        }).then((res) => {
          if (res?.code === '000000') {
            this.$Bus.$emit('cus-edit', row.customerComplainId)
          }
        })
      },
      // 申请完结
      finish(row) {
        if (!this._checkOption(row.applyDoneOption)) {
          return
        }
        const { customerComplainId, evaluationCode } = row
        this.currentRow = row
        const message = `
          <div >
            <p style="margin-left:-7px;margin-bottom:10px;">【${this.$t(
              'cusCenter.Customerreviewnumber'
            )}】= ${evaluationCode}</p>

            <p style="margin-bottom:10px;">${this.$t(
              'cusCenter.finsishTip'
            )}</p>
            <p >${this.$t('cusCenter.finsishTip2')}</p>
          </div>
        `
        this.$confirm('', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('reqmsg.M2006'),
          cancelButtonText: this.$t('reqmsg.M2007'),
          type: 'warning',
          message: message,
          customClass: 'finish-dialog',
          title: this.$t('cusCenter.Istheapplicationcompleted'),
        })
          .then(() => {
            let params = {
              auditType: 16, //出库5
              status: '',
              tenantId: this.userInfo.tenantId,
            }
            this.pubApi.getAuditTemplates(params).then((response) => {
              if (!response.data.length) {
                return this.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                this.$refs['AuditChoose'].showAddEdit(params)
              }
            })
          })
          .catch(() => {})
      },
      // 删除
      del({ customerComplainId, evaluationCode, deleteOption }) {
        if (!this._checkOption(deleteOption)) {
          return
        }
        const message = `
          <div>
            <p style="margin-left:-7px;">【${this.$t(
              'cusCenter.Customerreviewnumber'
            )}】= ${evaluationCode}，${this.$t('productCenter.SureDelete')}</p>
          </div>
        `
        this.$confirm(
          `【${this.$t(
            'cusCenter.Customerreviewnumber'
          )}】= ${evaluationCode}，${this.$t('productCenter.SureDelete')}`,
          '提示',
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            message: message,
            title: this.lang === 'en' ? 'info' : '提示',
            type: 'warning',
            customClass: 'finish-dialog2',
          }
        )
          .then(() => {
            deleteById(customerComplainId).then((res) => {
              // if (this.tableData.length === 1) {
              //   this.$emit('reset-pageNo')
              // }
              this.$emit('get-list')
              this.$message.success(this.$t('reqmsg.$7'))
            })
          })
          .catch(() => {})
      },
      // 业务回复
      businessReply(
        { customerComplainId: businessId, replyOption, appendOption },
        transactionType
      ) {
        const option = transactionType == 2 ? replyOption : appendOption

        if (!this._checkOption(option)) {
          return
        }
        getDetail(businessId).then((res) => {
          if (res?.code === '000000') {
            setTimeout(() => {
              this.$refs.businessReply.initFiles(businessId)
            }, 200)

            this.detail = res.data
            this.params.transactionType = transactionType
            this.params.title =
              transactionType == 2
                ? 'cusCenter.Replytoreview'
                : 'cusCenter.FeedbackAppend'
            this.params.show = true
          }
        })
        // this.$emit('reset-pageNo')
        this.$emit('get-list')
      },

      changeCurrent(item) {
        this.$emit('current-change', item)
      },

      // 获取状态
      getEvaluationStatus(status) {
        const s = statusList.find((item) => item.code == status) || {}
        return s[this.lang === 'en' ? 'nameEn' : 'name']
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cus-table {
    margin-top: 20px;
    .table-fixed {
      max-height: 400px !important;
      ::v-deep {
        th {
          height: 35% !important;
          max-height: 35px !important;
          line-height: 35px !important;
        }
        td {
          height: 35% !important;
          max-height: 35px !important;
          line-height: 35px !important;
        }
      }
    }
    .table-fixed-mini {
      max-height: 300px !important;
    }
    i {
      cursor: pointer;
      font-size: 14px;
      margin: 0 5px;
    }
  }
  ::v-deep {
    .level .cell,
    .orderNumber .cell,
    .releatedCustomerEmail .cell,
    .closeTime .cell,
    .createTime .cell,
    .evaluationCode .cell {
      width: auto !important;
    }
    .el-table__fixed-right {
      background: white;
    }
  }
</style>
<style>
  .finish-dialog {
    width: 660px;
  }
  .finish-dialog2 {
    width: 500px;
  }
</style>
