<template>
  <div class="platform-product-details">
    <!-- ERP 系统产品 -->
    <div v-if="type === '1'" class="area">
      <h4>{{ $t('productDetail.ERPSystemProduct') }}</h4>
      <el-row>
        <el-col v-for="item of ERPProduct" :key="item.label" :span="item.span">
          <span class="label">{{ $t(item.label) }}：</span>
          <span v-if="item.field === 'erpProductCode'" class="value">
            <span
              v-if="item.value && item.value !== '--'"
              class="prod_code"
              @click="productView"
            >
              {{ item.value }}
            </span>
            <template v-else>--</template>
          </span>
          <span v-else class="value">{{ item.value || '--' }}</span>
        </el-col>
      </el-row>
      <Divider :content="$t('productDetail.ProductRemark')" />
      <div class="remark">
        {{ (detailInfo.baseVO && detailInfo.baseVO.erpProductRemark) || '--' }}
      </div>
    </div>
    <!-- 产品信息 -->
    <div v-if="type === '1'" class="area">
      <h4>{{ $t('productDetail.ProductInformation') }}</h4>
      <el-row>
        <el-col v-for="item of productInfo" :key="item.label" :span="item.span">
          <span class="label" v-if="item.field === 'platformProductId'">
            {{ $t(item.label) }}：
          </span>
          <span class="label" v-else>{{ item.label }}</span>
          <span class="value">{{ item.value || '--' }}</span>
        </el-col>
      </el-row>
    </div>
    <!-- 平台产品信息 -->
    <div v-if="type === '2'" class="area">
      <h4>{{ $t('productDetail.PlatformProductInformation') }}</h4>
      <el-row>
        <el-col
          v-for="item of platformInfo"
          :key="item.label"
          :span="item.span"
        >
          <span class="label" :class="item.class">{{ $t(item.label) }}：</span>
          <el-checkbox-group
            v-if="item.field === 'draftPlatformCode'"
            v-model="item.value"
          >
            <el-checkbox
              v-for="{ value, label } of platformDs"
              :key="value"
              :label="value"
              disabled
               class="checkbox-disabled-highlight"
            >
              {{ label }}
            </el-checkbox>
          </el-checkbox-group>
          <el-switch
            v-else-if="item.field === 'freeFreightForPlatform'"
            v-model="item.value"
            disabled
          />
          <span v-else class="value">{{ item.value || '--' }}</span>
        </el-col>
      </el-row>
    </div>
    <template v-if="type === '1' || (type === '2' && hasSAGE)">
      <!-- 基础信息 -->
      <div class="area pb20">
        <h4>{{ $t('productDetail.basicInformation') }}</h4>
        <el-row>
          <el-col v-for="item of baseInfo" :key="item.label" :span="item.span">
            <span class="label">{{ item.label }}</span>
            <span class="value">{{ item.value || '--' }}</span>
          </el-col>
        </el-row>
        <!-- 印刷信息 -->
        <Divider :content="$t('productDetail.printInformation')" />
        <el-row class="margin0">
          <el-col :span="24">
            <span class="label">Imp Method：</span>
            <span class="value">
              {{
                (detailInfo &&
                  detailInfo.sageProductVO &&
                  detailInfo.sageProductVO.platformProductSageVO
                    .decorationMethodEn) ||
                '--'
              }}
            </span>
          </el-col>
        </el-row>
        <el-row
          v-for="(item, i) of detailInfo &&
          detailInfo.sageProductVO &&
          detailInfo.sageProductVO.platformProductPrintingVOS"
          :key="i"
          class="margin0"
        >
          <el-col :span="8">
            <span class="label">
              <template v-if="i >= 1">{{ i + 1 }}nd</template>
              Imp Location：
            </span>
            <span class="value">{{ item.locationEn || '--' }}</span>
          </el-col>
          <el-col :span="16">
            <span class="label">
              <template v-if="i >= 1">{{ i + 1 }}nd</template>
              Imp Area：
            </span>
            <span class="value">
              {{ item.impArea }}
            </span>
          </el-col>
        </el-row>
      </div>
      <!-- 价格信息 -->
      <div class="area">
        <h4>{{ $t('productDetail.priceInformation') }}</h4>
        <ul class="price-info">
          <li v-for="item of priceInfo" :key="item.label">
            <span class="label">{{ item.label }}</span>
            <template v-if="item.values.length">
              <span v-for="(val, i) of item.values" :key="i" class="value">
                {{ val }}
              </span>
            </template>
            <template v-else>--</template>
            <el-checkbox
              v-if="item.checkbox"
              v-model="item.checkbox.checked"
              :label="item.checkbox.label"
              disabled
              class="checkbox-disabled-highlight"
            />
          </li>
        </ul>
        <Divider content="Options" />
        <br v-if="!priceOptions.length" />
        <div class="price-options">
          <div v-for="(item, i) of priceOptions" :key="i">
            <div class="name">
              Name：
              <span
                :class="[
                  'value',
                  { 'height-text': markOptions && item.optionSource === 3 },
                ]"
              >
                {{ item.optionName || '--' }}
              </span>
            </div>
            <div class="table-wrapper">
              <el-table :data="item.tableData" border>
                <el-table-column
                  v-for="col of columns"
                  :key="col"
                  :prop="col"
                  :label="col"
                  :min-width="col === 'values' && 150"
                >
                  <template slot-scope="scope">
                    <span
                      :class="{
                        'height-text':
                          markOptions && scope.row.defaultFlag === 1,
                      }"
                    >
                      {{ scope.row[col] }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="tips">
                <span class="label">Price Code：</span>
                <span class="value">{{ item.priceCode }}</span>
                <el-radio-group v-model="item.radio" disabled>
                  <el-radio :label="0">Upcharge</el-radio>
                  <el-tooltip
                    placement="top"
                    effect="light"
                    :content="$t('productCenter.priceCodeTips3')"
                  >
                    <i class="el-icon-warning-outline" />
                  </el-tooltip>
                  <el-radio :label="1">Total Price</el-radio>
                  <el-tooltip
                    placement="top"
                    effect="light"
                    :content="$t('productCenter.priceCodeTips4')"
                  >
                    <i class="el-icon-warning-outline" />
                  </el-tooltip>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
        <Divider content="Setup Charges" />
        <el-row class="mt20">
          <el-col
            v-for="item of setupCharges"
            :key="item.label"
            :span="item.span"
            :style="{ marginTop: 0 }"
          >
            <span class="label">{{ item.label }}</span>
            <div class="values" v-if="item.values.length">
              <span v-for="(val, i) of item.values" :key="i" class="value">
                {{ val || '--' }}
              </span>
            </div>
            <template v-else>--</template>
          </el-col>
        </el-row>
      </div>
      <!-- 图片信息 -->
      <div class="area">
        <h4>{{ $t('productDetail.imageInformation') }}</h4>
        <ul class="image-info">
          <li
            v-for="item of imageInfo"
            :key="item.label"
            :style="{ width: `${(imageInfo[0].values.length + 1) * 120}px` }"
          >
            <div class="label">
              {{ item.label }}
            </div>
            <div v-for="(val, i) of item.values" :key="i" class="value">
              <img
                v-if="item.field === 'imageList'"
                v-lazy="val"
                @click="handlePreview(i)"
              />
              <span v-else-if="item.field === 'captionList'">{{ val }}</span>
              <template v-else>
                <el-checkbox
                  v-if="val.hasCheckbox"
                  :value="val.checked"
                  disabled
                  class="checkbox-disabled-highlight"
                />
              </template>
            </div>
          </li>
        </ul>
      </div>
      <!-- 其他信息 -->
      <div class="area">
        <h4>{{ $t('productDetail.otherInformation') }}</h4>
        <el-row>
          <el-col v-for="item of otherInfo" :key="item.label" :span="item.span">
            <template v-if="item.show">
              <span class="label">{{ item.label }}</span>
              <span v-if="item.field === 'tags'" class="value">
                <el-checkbox-group v-model="item.value">
                  <el-checkbox
                    class="checkbox-disabled-highlight"
                    disabled
                    v-for="{ value, label } of tags"
                    :key="value"
                    :label="value"
                  >
                    {{ label }}
                  </el-checkbox>
                </el-checkbox-group>
              </span>
              <span v-else class="value">{{ item.value || '--' }}</span>
            </template>
          </el-col>
        </el-row>
      </div>
    </template>
    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="urlList"
      :initial-index="initialIndex"
    />
  </div>
</template>
<script>
  import { platformDs } from '@/utils/dicts'
  import { mapGetters } from 'vuex'
  import Divider from './Divider'
  export default {
    components: { Divider },
    props: {
      type: { type: String, default: '1' }, // 1：平台产品列表  2：审核管理、系统产品管理
      markOptions: { type: Boolean }, //是否高亮自增options
      detailInfo: {
        type: Object,
        default: () => ({
          baseVO: {},
          sageProductVO: {
            platformProductImgVOS: [],
            platformProductPrintingVOS: [],
            platformProductSageVO: {},
            platformProductSpecVO: {},
            productPlatformCatalogPriceVOS: [],
            productPlatformColorPriceVOS: [],
            productPlatformPriceIncludesVOS: [],
            productPlatformPriceOptionVOS: [],
            productPlatformPriceQuantityVOS: [],
            productPlatformPriceUnitVOS: [],
          },
        }),
      },
    },
    data() {
      return {
        platformDs,
        // ERP 系统产品-type为1时展示
        ERPProduct: [
          {
            label: 'productDetail.PlatformName',
            field: 'platformName',
            span: 8,
          },
          {
            label: 'productDetail.ERPItemNo',
            field: 'erpProductCode',
            span: 8,
          },
          {
            label: 'productDetail.ERPProductName',
            field: 'erpProductNameEn',
            span: 8,
          },
          {
            label: 'productDetail.ProductCategory',
            field: 'erpCategory',
            span: 8,
          },
          {
            label: 'productDetail.ProductionLine',
            field: 'productionLine',
            span: 8,
          },
          {
            label: 'productDetail.ProductOperations',
            field: 'belonger',
            span: 8,
          },
        ],
        // 产品信息-type为1时展示
        productInfo: [
          {
            label: 'Item Number：',
            field: 'platformProductCode',
            span: 8,
          },
          {
            label: 'productDetail.PlatformProductID',
            field: 'platformProductId',
            span: 8,
          },
          {
            label: 'Product Name：',
            field: 'platformNameEn',
            span: 8,
          },
          {
            label: 'Descripiton：',
            field: 'erpDescriptionEn',
            span: 24,
          },
          {
            label: 'Keywords：',
            field: 'erpKeyWord',
            span: 24,
          },
          {
            label: 'Product Colors：',
            field: 'colors',
            span: 24,
          },
          {
            label: 'Packaging：',
            field: 'packingMethodEn',
            span: 8,
          },
          {
            label: 'Units/Ctn：',
            field: 'numberPerBox',
            span: 8,
          },
          {
            label: 'Weight/Ctn：',
            field: 'boxWeightLbs',
            span: 8,
          },
          {
            label: 'Dimensions：',
            field: 'dimensions',
            span: 8,
          },
          {
            label: 'Ctn Dimensions：',
            field: 'ctnDimensions',
            span: 8,
          },
        ],
        // 平台产品信息-type为2时展示
        platformInfo: [
          {
            label: 'productDetail.AfterPassingTheReview',
            field: 'draftPlatformCode',
            value: [],
            span: 24,
          },
          {
            label: 'productDetail.SageDisplayPrice',
            field: 'printingWaySelectEn',
            value: '',
            span: 8,
          },
          {
            label: 'productDetail.PlatformDisplayFreeShipping',
            field: 'freeFreightForPlatform',
            value: false,
            span: 8,
          },
        ],
        // 基础信息
        baseInfo: [
          { label: 'Category 1：', field: 'cat1Name', span: 8 },
          { label: 'Category 2：', field: 'cat2Name', span: 8 },
          { label: 'Themes：', field: 'themes', span: 8 },
          { label: 'Made In：', field: 'madeIn', span: 8 },
          { label: 'Assembled In：', field: 'assembledIn', span: 8 },
          { label: 'Decorated In：', field: 'decoratedIn', span: 8 },
          { label: 'Ship Point：', field: 'shipPoint', span: 8 },
          { label: 'Rush Serviece：', field: 'rushServiece', span: 8 },
          { label: 'Prod. Time：', field: 'rrodTime', span: 8 },
        ],
        // 价格信息
        priceInfo: [
          {
            label: 'Quantity：',
            field: 'productPlatformPriceQuantityVOS',
            variable: 'quanqtity',
            values: [],
          },
          {
            label: 'Catalog Price：',
            field: 'productPlatformCatalogPriceVOS',
            variable: 'catalogPrice',
            values: [],
          },
          {
            label: 'Pieces Per Unit：',
            field: 'productPlatformPriceUnitVOS',
            variable: 'piecesPerUnit',
            values: [],
          },
          {
            label: 'Price Includes：',
            field: 'productPlatformPriceIncludesVOS',
            variable: 'priceIncludes',
            values: [],
            checkbox: {
              label: 'No decoration included in price.',
              checked: null,
            },
          },
          {
            label: 'Price Code：',
            values: [],
          },
        ],
        columns: ['values'], // 价格信息-Options表格头部
        // 价格信息-Options
        priceOptions: [
          { optionName: '', radio: null, priceCode: '', tableData: [] },
          // { optionName: '', radio: null, priceCode: '', tableData: [] },
        ],
        // 价格信息-Setup Charges
        setupCharges: [
          {
            label: 'Setup Charge：',
            field: 'setupCharge',
            values: [],
            span: 8,
          },
          {
            label: 'Repeat Setup：',
            field: 'repeatSetup',
            values: [],
            span: 8,
          },
          {
            label: 'Addt’l Color Setup：',
            field: 'colorSetup',
            values: [],
            span: 8,
          },
          {
            label: 'Addt’l ColorRun Chg：',
            field: 'colorRunChg',
            values: [],
            span: 16,
          },
          {
            label: 'Price Code：',
            field: 'priceCode',
            values: [],
            span: 8,
          },
        ],
        // 图片信息
        imageInfo: [
          { label: 'Image', field: 'imageList', values: [] },
          { label: 'Caption', field: 'captionList', values: [] },
          { label: 'Is Primary', field: 'primaryList', values: [] },
          { label: 'Has Logo', field: 'hasLogoList', values: [] },
        ],
        // 其他信息
        otherInfo: [
          { label: 'Compliance：', field: 'compliance', span: 24, show: true },
          { label: 'Comment：', field: 'commented', span: 24, show: true },
          { label: 'Tags：', field: 'tags', value: [], span: 24, show: true },
          {
            label: 'Catalog year：',
            field: 'catalogYear',
            span: 8,
            show: true,
          },
          {
            label: 'Expiration Date：',
            field: 'expirationDate',
            span: 8,
            show: true,
          },
        ],
        tags: [
          { value: 101, label: 'Environmentally Friendly' },
          { value: 102, label: 'Recyclable' },
          { value: 103, label: 'New Product' },
          { value: 104, label: 'Food' },
          { value: 105, label: 'Clothing' },
          { value: 106, label: 'Exclusive' },
          { value: 107, label: 'Not Suitable For All Audiences' },
        ],
        // 图片预览-开始
        showViewer: false,
        initialIndex: 0,
        urlList: [],
        // 图片预览-结束
        hasSAGE: false, // SAGE是否为选中状态。type为2时，hasSAGE为true才会显示基础信息、价格信息、图片信息、其他信息
      }
    },
    computed: {
      ...mapGetters({ lang: 'settings/language' }),
      // 是否是英文
      isEn() {
        return this.lang === 'en'
      },
    },
    watch: {
      detailInfo: {
        handler(detailInfo) {
          if (detailInfo) this.setInfo(detailInfo)
        },
      },
      // 监听中英文切换，重新渲染Sage展示价，重新渲染上架平台字典
      isEn: {
        handler(flag) {
          if (this.type === '2') {
            const { baseVO, sageProductVO } = this.detailInfo
            this.setPlatformInfo(baseVO, sageProductVO)
          }
          this.platformDs = [
            { label: flag ? 'RP Site' : 'RP商城', value: 'RP' },
            { label: flag ? 'RG Site' : 'RG商城', value: 'RG' },
            { label: flag ? 'SW Site' : 'SW商城', value: 'SW' },
            { label: flag ? 'SAGE(68786)' : 'SAGE（68786）', value: 'SAGE' },
          ]
        },
      },
    },
    methods: {
      setInfo(detailInfo) {
        console.log('监听detailInfo', JSON.parse(JSON.stringify(detailInfo)))
        const { baseVO, sageProductVO } = this.manageData(
          detailInfo.baseVO,
          detailInfo.sageProductVO
        )
        // ERP 系统产品-type为1时展示
        if (this.type === '1') this.setERPProduct(baseVO)
        // 产品信息-type为1时展示
        if (this.type === '1') this.setProductInfo(baseVO, sageProductVO)
        // 设置平台产品信息-type为2时展示
        if (this.type === '2') this.setPlatformInfo(baseVO, sageProductVO)
        // 基础信息
        this.setBaseInfo(sageProductVO)
        // 基础信息-印刷信息
        this.setPrintingVOS(sageProductVO)
        // 价格信息
        this.setPriceInfo(sageProductVO)
        // 价格信息-Options
        this.setPriceOptions(sageProductVO)
        // 价格信息-setupCharges
        this.setSetupCharges(sageProductVO)
        // 图片信息
        this.setImageInfo(sageProductVO)
        // 其他信息
        this.setOtherInfo(sageProductVO)
      },
      // 数据兼容处理
      manageData(baseVO, sageProductVO) {
        baseVO = baseVO || {}
        sageProductVO = sageProductVO || {}
        // 数据兼容处理-start 后端返回的这些数据有可能为null，为避免使用方式时报错，这里统一做下处理
        sageProductVO.platformProductImgVOS =
          sageProductVO.platformProductImgVOS || []
        sageProductVO.platformProductPrintingVOS =
          sageProductVO.platformProductPrintingVOS || []
        sageProductVO.productPlatformCatalogPriceVOS =
          sageProductVO.productPlatformCatalogPriceVOS || []
        sageProductVO.productPlatformColorPriceVOS =
          sageProductVO.productPlatformColorPriceVOS || []
        sageProductVO.productPlatformPriceIncludesVOS =
          sageProductVO.productPlatformPriceIncludesVOS || []
        sageProductVO.productPlatformPriceOptionVOS =
          sageProductVO.productPlatformPriceOptionVOS || []
        sageProductVO.productPlatformPriceQuantityVOS =
          sageProductVO.productPlatformPriceQuantityVOS || []
        sageProductVO.productPlatformPriceUnitVOS =
          sageProductVO.productPlatformPriceUnitVOS || []
        sageProductVO.platformProductSageVO =
          sageProductVO.platformProductSageVO || {}
        sageProductVO.platformProductSpecVO =
          sageProductVO.platformProductSpecVO || {}
        // 数据兼容处理-end
        return { baseVO, sageProductVO }
      },
      // 设置ERP系统产品-type为1时展示
      setERPProduct(baseVO) {
        const target =
          platformDs.find((n) => n.value === baseVO.platformCode) || {}
        Object.assign(baseVO, { platformName: target.label })
        for (const item of this.ERPProduct) {
          item.value = baseVO[item.field] || '--'
        }
      },
      // 设置产品信息-type为1时展示
      setProductInfo(baseVO, { platformProductSageVO, platformProductSpecVO }) {
        /*
            1、Product Colors从platformProductSageVO中获取
            2、Packaging、Units/Ctn、Weight/Ctn从platformProductSpecVO中取
            3、Dimensions、Ctn Dimensions从platformProductSpecVO中取，需要拼接
          */
        const {
          packingMethodEn, // Packaging
          numberPerBox, // Units/Ctn
          boxWeightLbs, // Weight/Ctn
          productLength, // Dimensions长
          productWidth, // Dimensions宽
          productHeight, // Dimensions高
          boxLengthIn, // Ctn Dimensions长
          boxWidthIn, // Ctn Dimensions宽
          boxHeightIn, // Ctn Dimensions高
          productWidthUnit, // 1=英尺 2=英尺
        } = platformProductSpecVO
        const unit = +productWidthUnit === 1 ? "''" : "'" // ''表示英寸 '表示英尺
        Object.assign(baseVO, {
          colors: platformProductSageVO.colors,
          packingMethodEn,
          numberPerBox,
          boxWeightLbs: (boxWeightLbs || '--') + ' lbs',
          dimensions: `${productLength || '--'}${unit} W × ${
            productWidth || '--'
          }${unit} D × ${productHeight || '--'}${unit} H`,
          ctnDimensions: `${boxLengthIn || '--'}" L × ${
            boxWidthIn || '--'
          }" W × ${boxHeightIn || '--'}" H`,
        })
        for (const item of this.productInfo) {
          item.value = baseVO[item.field] || '--'
        }
      },
      // 平台产品信息-type为2时展示
      setPlatformInfo(
        { draftPlatformCode: codes, freeFreightForPlatform },
        { platformProductSageVO }
      ) {
        const {
          printingWaySelectEn,
          printingWaySelectCn,
          waySelectEn,
          waySelectCn,
        } = platformProductSageVO
        for (const item of this.platformInfo) {
          if (item.field === 'draftPlatformCode') {
            item.value = codes ? codes.split(',') : []
            item.class = this.isEn ? 'w400' : 'w200'
            this.hasSAGE = item.value.includes('SAGE')
          } else if (item.field === 'printingWaySelectEn') {
            const field =
              (this.isEn ? printingWaySelectEn : printingWaySelectCn) || '--'
            const isBlank = field && field.toLowerCase() === 'blank'
            item.value = isBlank
              ? field
              : `${field}/${(this.isEn ? waySelectEn : waySelectCn) || '--'}`
            if (!isBlank) {
              if (this.isEn) {
                item.value = item.value + '1-location' // 英文需要拼接上1-location
              } else {
                item.value = item.value + '-1位' // 中文需要拼接上-1位
              }
            }
          } else {
            item.value = +freeFreightForPlatform === 1
            item.class = this.isEn ? 'w300' : 'w200'
          }
        }
      },
      // 设置基础信息
      setBaseInfo({ platformProductSageVO }) {
        const {
          rushServieceLo, // Rush Serviece最小值
          rushServieceHi, // Rush Serviece最大值
          prodTimeLo, // Prod. Time最小值
          prodTimeHi, // Prod. Time最大值
        } = platformProductSageVO
        Object.assign(platformProductSageVO, {
          rushServiece: `${rushServieceLo || '--'} to ${
            rushServieceHi || '--'
          } working days`,
          rrodTime: `${prodTimeLo || '--'} to ${
            prodTimeHi || '--'
          } working days`,
        })
        for (const item of this.baseInfo) {
          item.value = platformProductSageVO[item.field] || '--'
        }
      },
      // 设置基础信息-印刷信息
      setPrintingVOS({ platformProductPrintingVOS }) {
        for (const item of platformProductPrintingVOS) {
          const {
            printingWidthUnit: wUnit,
            printingWidth: width,
            printingHeightUnit: hUnit,
            printingHeight: height,
          } = item
          // 1=英尺=inches="  2=英尺=feet='
          const unit = { 1: "''", 2: "'" }
          item.impArea = `${width || '--'}${unit[wUnit]} W × ${height || '--'}${
            unit[hUnit]
          } H`
        }
      },
      // 设置价格信息
      setPriceInfo(sageProductVO) {
        const { productPlatformPriceQuantityVOS, platformProductSageVO } =
          sageProductVO
        for (const item of this.priceInfo) {
          const list = sageProductVO[item.field] || []
          item.values = list.map((n) => n[item.variable] || '--')
          if (item.field === 'productPlatformPriceIncludesVOS') {
            item.checkbox.checked = !!platformProductSageVO.noDecoration
          } else if (item.label === 'Price Code：') {
            item.values = [this.getUnit(productPlatformPriceQuantityVOS, 'R')] // Quantity有几列，就显示几个R
          }
        }
      },
      // 设置价格信息Options
      setPriceOptions({
        productPlatformPriceQuantityVOS,
        productPlatformPriceOptionVOS,
      }) {
        const cols = productPlatformPriceQuantityVOS.map((n) => n.quanqtity)
        this.columns = this.columns.concat(cols) // 表格头部
        this.priceOptions = productPlatformPriceOptionVOS.map((ele) => {
          const result = {}
          ele?.productPlatformPriceColS?.forEach((item) => {
            if (!result[item.rowKey]) {
              result[item.rowKey] = {
                values: item.rowKey,
                defaultFlag: item.defaultFlag,
              }
            }
            result[item.rowKey][item.colKey] = item.attributeValue
          })
          const tableData = Object.values(result)
          return {
            optionName: ele.optionName || '--',
            priceCode: this.getUnit(productPlatformPriceQuantityVOS, 'R'),
            radio: +ele.pricingIsTotal,
            tableData,
            optionSource: ele.optionSource,
          }
        })
      },
      // 设置价格信息Setup Charges
      setSetupCharges({
        platformProductSageVO,
        productPlatformPriceQuantityVOS,
        productPlatformColorPriceVOS,
      }) {
        for (const item of this.setupCharges) {
          if (
            ['setupCharge', 'repeatSetup', 'colorSetup'].includes(item.field)
          ) {
            item.values = [platformProductSageVO[item.field] || '--', 'V']
          } else if (item.field === 'colorRunChg') {
            // 【Addt’l ColorRun Chg：】字段的长度不能大于【Quantity：】字段的长度
            productPlatformColorPriceVOS = productPlatformColorPriceVOS.slice(
              0,
              productPlatformPriceQuantityVOS.length
            )
            item.values = productPlatformColorPriceVOS.map((n) => n.colorPrice)
          } else if (item.field === 'priceCode') {
            item.values = [this.getUnit(productPlatformColorPriceVOS, 'V')]
          }
        }
      },
      // 设置图片信息
      setImageInfo({ platformProductImgVOS }) {
        const gather = {
          imageList: [],
          captionList: [],
          primaryList: [],
          hasLogoList: [],
        }
        platformProductImgVOS.forEach((item) => {
          gather.imageList.push(item.imgUrl)
          gather.captionList.push(item.caption)
          gather.primaryList.push({
            hasCheckbox: !!item.isPrimary,
            checked: true,
          })
          gather.hasLogoList.push({
            hasCheckbox: true,
            checked: !!item.hasLogo,
          })
        })
        for (const item of this.imageInfo) {
          item.values = gather[item.field]
        }
      },
      // 设置其他信息
      setOtherInfo({ platformProductSageVO }) {
        const tags = platformProductSageVO.tags
          ? platformProductSageVO.tags.split(',').map(Number)
          : []
        for (const item of this.otherInfo) {
          if (item.field === 'tags') {
            item.value = tags
          } else {
            item.value = platformProductSageVO[item.field] || '--'
            if (['catalogYear', 'expirationDate'].includes(item.field)) {
              item.show = tags.includes(103) // 当New Product是选中状态时，才会显示Catalog year和Expiration Date
            }
          }
        }
      },
      // 传入一个数组，数组有几项，就返回几个R或V
      getUnit(list = [], unit = '') {
        let str = ''
        if (!list.length) return str
        list.forEach(() => {
          str += unit
        })
        return str
      },
      // 点击产品编码，跳转至产品列表的产品详情页
      productView() {
        const { erpProductId, erpProductCode } = this.detailInfo.baseVO
        const routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: { proId: erpProductId, productCode: erpProductCode },
        })
        window.open(routeData.href, '_blank')
      },
      // 预览图片
      handlePreview(i) {
        this.urlList = this.imageInfo.find(
          (n) => n.field === 'imageList'
        ).values
        this.initialIndex = i
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .platform-product-details {
    .area {
      // border: 1px solid #e4e7ed;
      border-radius: 2px;
      margin-top: 20px;
      .height-text {
        color: #1890ff !important;
      }
      > h4 {
        // font-weight: normal;
        // padding-left: 20px;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        // border-bottom: 1px solid #e4e7ed;
        // background: #f5f7fa;
      }
      .el-row {
        margin: 20px 40px 20px 20px;
        &.margin0 {
          margin: 0;
        }
        .el-col {
          margin-top: 10px;
          display: flex;
          > span {
            &.label {
              display: inline-block;
              width: 160px;
              text-align: right;
              &.w200 {
                width: 200px;
              }
              &.w300 {
                width: 300px;
              }
              &.w400 {
                width: 400px;
              }
            }
            &.value {
              flex: 1;
              text-align: justify;
              word-break: break-all;
            }
          }
          .values {
            flex: 1;
            overflow: auto;
            .value {
              margin-right: 20px;
              margin-bottom: 10px;
              display: inline-block;
              width: 100px;
              line-height: 24px;
              padding: 0 10px;
              background-color: #f0f0f0;
            }
          }
        }
      }
      .el-divider {
        margin: 20px 0 10px;
      }
      .price-info {
        margin-top: 20px;
        > li {
          margin-top: 10px;
          .label {
            display: inline-block;
            width: 160px;
            text-align: right;
          }
          .value {
            margin-right: 20px;
            display: inline-block;
            min-width: 100px;
            line-height: 24px;
            padding: 0 10px;
            background-color: #f0f0f0;
          }
        }
      }
      .price-options {
        padding-left: 50px;
        .name {
          margin: 20px 0 10px;
          .value {
            display: inline-block;
            width: 200px;
            padding: 0 10px;
            line-height: 24px;
            background-color: #f0f0f0;
          }
        }
        .table-wrapper {
          display: flex;
          .tips {
            margin: 10px 20px 0;
            .value {
              margin-right: 20px;
              display: inline-block;
              width: 100px;
              line-height: 24px;
              padding: 0 10px;
              background-color: #f0f0f0;
            }
            .el-radio-group {
              margin-top: 20px;
              display: block;
              .el-radio {
                margin-right: 10px;
              }
              .el-tooltip {
                margin-right: 30px;
              }
            }
            ::v-deep .el-radio-group{
              .is-disabled{
                .el-radio__label{
                   color: #606266;
                }
              }
              .el-radio__inner{
                &::after{
                  background-color: #606266;
                }
              }
            }
          }
        }
      }
      .image-info {
        overflow-x: auto;
        margin: 20px;
        > li {
          display: flex;
          align-items: center;
          overflow: hidden;
          &:first-of-type {
            border-top: 1px solid #f0f0f0;
            > div {
              height: 120px;
            }
          }
          > div {
            flex: 1;
            height: 60px;
            border-right: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            &:first-of-type {
              border-left: 1px solid #f0f0f0;
              background-color: #f5f7fa;
            }
            &.value {
              padding: 10px;
              > img {
                width: 100%;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    li {
      list-style: none;
    }
    .prod_code {
      color: #409eff;
      text-decoration: underline;
      cursor: pointer;
    }
    .mb50 {
      margin-bottom: 50px;
    }
    .pb20 {
      padding-bottom: 20px;
    }
    .remark {
      margin: 20px 40px;
      white-space: pre-line;
    }
    ::v-deep.checkbox-disabled-highlight{
      &.is-disabled{
        .el-checkbox__inner{
          &::after{
            border-color: #606266;
          }
        }
        .el-checkbox__label{
          color: #606266;
        }
      }
    }
  }
</style>
