<template>
  <el-select
    v-model.trim="codeOrName"
    v-loadmore:el-select-dropdown__list="loadMore"
    class="remote-select"
    :popper-append-to-body="false"
    filterable
    remote
    reserve-keyword
    clearable
    placeholder="请输入关键字进行查询"
    :remote-method="remoteMethod"
    :loading="loading"
    @change="handleChange"
    @clear="remoteMethod('')"
  >
    <el-option
      v-for="item in productOptions"
      :key="item.productId"
      :label="item.value"
      :value="item.productId"
    />
  </el-select>
</template>
<script>
  /*
    主体逻辑：
      1、第一次点击输入框，加载全部下拉，滚动到底部加载下一页
      2、输入框输入关键字时，按关键字查询，滚动加载下一页
      3、关键字进行补充和减少时，按当前最新的关键字进行查询，并且页码回到第一页，滚动加载下一页
      4、点击输入框右侧的清空按钮时，需要手动触发remoteMethod，此时的name是''
  */
  import { inquiryInteractor } from '@/core/interactors/inquiry/inquiry'
  export default {
    data() {
      return {
        codeOrName: '', // 根据产品名称、编码进行搜索
        productOptions: [], // 可选产品列表
        loading: false,
        pageLe: 20,
        pageNo: 1,
        total: 0,
      }
    },
    created() {
      this.getOptions()
    },
    methods: {
      // 选中某一项
      handleChange(val) {
        this.$emit('input', val)
        const item = this.productOptions.find((n) => n.productId === val) || {}
        this.$emit('change', item)
      },
      // 加载更多
      loadMore() {
        if (this.total > this.productOptions.length) {
          this.pageNo++
          this.getOptions()
        }
      },
      // 当输入框内容发生变化时会触发remoteMethod，但是清空需要手动触发
      remoteMethod(query) {
        this.loading = true
        this.codeOrName = query && query.trim()
        this.reset() // 当用户输入不同的字符时，意味着需要重新加载下拉，需要将当前数据重置
        this.getOptions()
        if (!this.codeOrName) {
          this.loading = false
        }
      },
      // 重置当前页码，总数，下拉，并且将滚动条置顶
      reset() {
        this.pageNo = 1
        this.total = 0
        this.productOptions = []
        const targetDOM = document.querySelector(
          '.remote-select .el-select-dropdown__list'
        )
        targetDOM.scrollTop = 0
        this.$emit('clearValidate') // 清除校验
      },
      async getOptions() {
        try {
          const { codeOrName, pageLe, pageNo } = this
          const { listEnquiryProductByParam } = inquiryInteractor
          const { code, data } = await listEnquiryProductByParam(
            codeOrName,
            pageLe,
            pageNo
          )
          this.loading = false
          if (code === '000000' && Array.isArray(data.data)) {
            const list = data.data.map((item) => {
              return {
                ...item,
                value:
                  item.productCode + ' / ' + (item.nameEn ? item.nameEn : ''),
              }
            })
            if (pageNo === 1) {
              this.productOptions = list
            } else {
              this.productOptions.push(...list)
            }
            this.total = data.total
          }
        } catch (err) {
          console.log(err)
          this.loading = false
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .remote-select {
    width: 100%;
    /deep/ .el-select-dropdown__list {
      height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      li {
        max-width: 470px;
      }
    }
  }
</style>
