<template>
  <div class="custom-container">
    <el-row>
      <el-page-header @back="goBack()" :content="`${isEdit ? '编辑' : '生成'}采购订单`"></el-page-header>
    </el-row>
    <div class="purchase-content">
      <el-form :model="form" :rules="rules" ref="ruleForm" :label-position="isPad ? 'top' : 'right'">
        <h4>基本信息</h4>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item prop="supplierId" label-width="95px" label="供应商名称">
              <el-select v-model="form.supplierId" clearable @change="supplierChange(form.supplierId)"
                :disabled="form.isReviewed == 1">
                <el-option v-for="item in supplierList" :key="item.supplierId" :label="item.supplierName"
                  :value="item.supplierId" :disabled="[0, 1, 3, 4, 5, 7].includes(item.status)">
                  <span style="float: left">{{ item.supplierName }}</span>
                  <span v-show="[0, 1, 3, 4, 5, 7].includes(item.status)"
                    style="float: right; color: #8492a6; font-size: 13px">
                    {{ item.status | statusFilter }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="agreementEndDate" label-width="95px" label="协议结束日期">
              <el-input v-model="form.agreementEndDate" placeholder="请输入" clearable disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="supplierLinkmanId" label-width="95px" label="联系人">
              <el-select placeholder="请选择" @change="getLinkManPhone" v-model="form.supplierLinkmanId" clearable
                :disabled="state === 7">
                <el-option v-for="item in linkmanList" :key="item.supplierLinkmanId" :label="item.linkman"
                  :value="item.supplierLinkmanId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="supplierLinkmanPhone" label-width="95px" label="联系电话">
              <el-input v-model="form.supplierLinkmanPhone" :disabled="state === 7" placeholder="请输入"
                clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="buyerName" label-width="95px" label="采购开发">
              <UserSelect :defaultUser="defaultUser" :disabled="state === 7" roleName="采购员"
                @user-choose="(user) => userSelect(user, 'PURCHASE')"></UserSelect>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="merchandiserName" label-width="95px" label="QC">
              <UserSelect :defaultUser="defaultQC" :disabled="isReviewed"
                @user-choose="(user) => userSelect(user, 'QC')"></UserSelect>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="receivingAddress" label-width="95px" label="交货地址">
              <el-input v-model="form.receivingAddress" :disabled="state === 7" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="transportWay" label-width="95px" label="运输方式">
              <el-select v-model="form.transportWay" :disabled="state === 7" placeholder="请选择" clearable>
                <el-option label="快递" value="快递"></el-option>
                <el-option label="物流" value="物流"></el-option>
                <el-option label="货拉拉" value="货拉拉"></el-option>
                <el-option label="自提" value="自提"></el-option>
                <el-option label="工厂装柜" value="工厂装柜"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="freightPayer" label-width="95px" label="费用承担方">
              <el-select v-model="form.freightPayer" :disabled="state === 7" placeholder="请选择" clearable>
                <el-option label="甲方" value="甲方"></el-option>
                <el-option label="乙方" value="乙方"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="showSwitch === '0'">
            <el-form-item label-width="95px" label="选择入库仓库">
              <el-switch v-model="form.warehouseFlag" :disabled="state === 7" :active-value="1"
                :inactive-value="0"></el-switch>
              <el-tooltip placement="bottom" effect="light">
                <div slot="content" style="line-height: 24px">
                  开启：验货完成后，需要申请入库。发往合肥印刷厂加工的，请开启！
                  <br />
                  关闭：验货完成后，直接入库虚拟仓
                </div>
                <i class="el-icon-warning-outline ml10 c_pointer"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>

        <h4>付款方式</h4>
        <el-row :gutter="24" style="position: relative">
          <el-col :span="6">
            <el-form-item prop="paymentMethodId" label-width="95px" label="付款方式" style="position: relative">
              <el-select :disabled="!isPayment() || state === 7" placeholder="请选择" v-model="form.paymentMethodId"
                @change="paymentMethodChange" clearable>
                <el-option v-for="(item, index) in payList" :key="index"
                  :label="item.intervalName + '/' + item.paymentMethodName" :value="item.supplierPayId"
                  v-show="item.deleted === 0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="currency" label-width="95px" label="币种">
              <el-select :disabled="isReviewed" placeholder="请选择" v-model="form.currency" clearable>
                <el-option v-for="(c, index) in bz" :label="c.financeName" :value="c.financeName"
                  :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <p class="pay-tips" v-show="paymentMethodBol">
            当前选的账期，区间值与本次采购金额不符，请重新选择账期或联系供应商负责人维护供应商账期
          </p>
        </el-row>
        <table class="custom-table">
          <thead>
            <tr>
              <th>付款环节</th>
              <th>占比(%)</th>
            </tr>
          </thead>
          <tbody v-if="paylinkList.length">
            <tr v-for="(item, i) in paylinkList" :key="i">
              <td>{{ item.paymentNodeName }}</td>
              <td>{{ parseInt(item.percent * 100) }}</td>
            </tr>
          </tbody>
          <tbody v-else class="empty">
            <tr>
              <td colspan="3">暂无数据</td>
            </tr>
          </tbody>
        </table>

        <div class="flex column">
          <h4>账户信息</h4>
          <el-button @click="addAccountDetails" class="ml15">新增</el-button>
        </div>
        <table class="custom-table no-space">
          <thead>
            <tr>
              <th>
                <span>
                  <i>*</i>
                  户名
                </span>
              </th>
              <th>账号</th>
              <th>开户行</th>
              <th>税号</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <!-- 户名 -->
              <td>
                <el-form-item prop="accountName">
                  <el-select ref="select" v-model="form.accountName" filterable remote placeholder="请输入"
                    :remote-method="remoteMethod" clearable style="width: 100%" @change="handleSelect"
                    @visible-change="onBlur" @focus="() => remoteMethod('')">
                    <el-option v-for="item in bankList" :key="item.supplierBankAccountId" :label="item.accountName"
                      :value="item.accountName + '/' + item.bankAccount">
                      <span>
                        {{ item.accountName + '/' + item.bankAccount }}
                      </span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </td>
              <!-- 账号 -->
              <td>
                <el-form-item prop="bankAccount">
                  <el-input v-model="form.bankAccount" disabled placeholder="请输入" clearable></el-input>
                </el-form-item>
              </td>
              <!-- 开户行 -->
              <td>
                <el-form-item prop="bankName">
                  <el-input v-model="form.bankName" disabled placeholder="请输入" clearable></el-input>
                </el-form-item>
              </td>
              <!-- 税号 -->
              <td>
                <el-form-item prop="dutyAccount">
                  <el-input v-model="form.dutyAccount" disabled placeholder="请输入" clearable></el-input>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="flex column">
          <h4>发票信息</h4>
        </div>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item prop="purchaserObject" label="买方" label-width="85px">
              <!-- @change="financeSetupIdChange" -->
              <!-- <el-select
                :disabled="isReviewed"
                placeholder="请选择"
                v-model="form.purchaser"
                clearable
                @clear="clearAll"
              >
                <el-option
                  v-for="(item, index) in financeList"
                  :key="index"
                  :label="item.financeName"
                  :value="item.financeSetupId"
                ></el-option>
              </el-select> -->
              <el-select :disabled="isReviewed || disabledSettle" placeholder="请选择" v-model="form.purchaserObject"
                clearable @clear="clearAll" value-key="financeSetupId">
                <el-option v-for="(item, index) in financeList" :key="index" :label="item.financeName"
                  :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="invoiceType" label="发票类型" label-width="85px">
              <el-select :disabled="disabledSettle" placeholder="请选择" v-model="form.invoiceType" clearable>
                <el-option v-for="item in invoiceTypeList" :key="item.code" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <h4>产品列表</h4>
        <el-table class="no-space product-table" v-if="form.purchaseProductEditDTOList.length"
          :data="form.purchaseProductEditDTOList" border max-height="580px" :header-cell-class-name="star">
          <!-- 产品信息 -->
          <el-table-column align="center" width="220" prop="nameCn" label="产品信息">
            <template slot-scope="scope">
              <div class="info-wrapper">
                <p>
                  <span style="margin-right: 5px; margin-left: -5px">
                    产品编码:{{ scope.row.sku }}
                    <!-- 报关信息图标 -->
                    <span v-show="
                        (!scope.row.declareCn || !scope.row.declareUnit) &&
                        scope.row.standardCustomized != '辅料'
                      ">
                      <el-popover placement="right" effect="dark" trigger="hover" :content="declareTips(scope.row)">
                        <i slot="reference" class="tips-icon el-icon-warning"></i>
                      </el-popover>
                    </span>
                  </span>

                  <el-popover v-show="scope.row.mainFlag == 0" placement="right" width="" trigger="hover">
                    <div class="c-table-wrap">
                      <table class="custom-table text-center">
                        <thead>
                          <tr>
                            <th>产品图</th>
                            <th>产品编码</th>
                            <th class="more">中文品名/英文品名</th>
                            <th>规格</th>
                            <th>数量</th>
                          </tr>
                        </thead>
                        <tbody>
                          <td>
                            <div class="img-wrap">
                              <el-badge v-show="
                                  scope.row.parentUrl &&
                                  scope.row.parentUrl.split(',').length > 1
                                " :value="
                                  scope.row.parentUrl
                                    ? scope.row.parentUrl.split(',').length
                                    : ''
                                "></el-badge>
                              <el-image style="height: 50px; width: 50px" v-if="scope.row.parentUrl" :src="
                                  scope.row.parentUrl.split(',')[0] +
                                  URL_COMPRESS
                                " class="c_pointer" @click="
                                  handlePreview(scope.row.parentUrl.split(','))
                                "></el-image>
                              <span v-else>--</span>
                            </div>
                          </td>
                          <td>
                            <span v-if="!scope.row.productCode.includes('FL')" @click="
                                productView(
                                  scope.row.productId,
                                  scope.row.productCode
                                )
                              " class="page-link">
                              {{ scope.row.parentSku }}
                            </span>
                            <span v-else>{{ scope.row.parentSku }}</span>
                          </td>
                          <td>{{ scope.row.parentName }}</td>
                          <td>{{ scope.row.parentSpecsValue }}</td>
                          <td>
                            {{
                            scope.row.parentNumDemand /
                            scope.row.numCombination
                            }}
                          </td>
                        </tbody>
                      </table>
                    </div>

                    <em class="child-split" slot="reference" v-show="scope.row.mainFlag == 0">
                      子
                    </em>
                  </el-popover>
                </p>
                <p>产品名称:{{ scope.row.nameCn }}</p>
                <p>
                  <span>规格:</span>

                  <span v-if="scope.row.specsValue1 || scope.row.specsValue2">
                    {{ scope.row.specsValue1 }} {{ scope.row.specsValue2 }}
                  </span>
                  <span v-else>--</span>
                </p>
                <p>
                  <span>客户代表:</span>
                  <span>
                    {{ scope.row.businessName || '--' }}
                  </span>
                </p>
                <p>
                  <span>产品运营:</span>
                  <span>
                    {{ scope.row.productOperateName || '--' }}
                  </span>
                </p>
                <p>
                  <span>订单支持:</span>

                  <span>
                    {{ scope.row.fromBusinessName || '--' }}
                  </span>
                </p>
                <p>期望交期:{{ scope.row.expectedDeliveryDate || '--' }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="priceIncludingTax" width="120" label="含税单价">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' +
                  scope.$index +
                  '.priceIncludingTax'
                " :rules="[
                  {
                    required: true,
                    message: '含税单价必填',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input :disabled="disableSettlementStatusSku.includes(scope.row.sku)" maxlength="20"
                  @input="calculateTax(scope.$index, 'priceIncludingTax')" v-model="scope.row.priceIncludingTax"
                  placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" prop="taxRate" label="税率(%)">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' + scope.$index + '.taxRate'
                " :rules="[
                  {
                    required: true,
                    message: '税率必填',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-select v-model="scope.row.taxRate" v-if="form.invoiceType == 1" :key="form.invoiceType"
                  placeholder="请选择" :disabled="disabledSettle" @change="
                    calculateTax(scope.$index, 'priceIncludingTax', true)
                  ">
                  <el-option v-for="item in taxRateList" :value="item.rate" :key="item.financeTaxRateId">
                    {{ item.rate }}
                  </el-option>
                </el-select>
                <el-input v-else :key="form.invoiceType" v-model="scope.row.taxRate" disabled></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="priceExcludingTax" width="120" label="未税单价">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' +
                  scope.$index +
                  '.priceExcludingTax'
                " :rules="[
                  {
                    required: true,
                    message: '未税单价必填',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input :disabled="disableSettlementStatusSku.includes(scope.row.sku)" maxlength="20"
                  @input="calculateTax(scope.$index, 'priceExcludingTax')" v-model="scope.row.priceExcludingTax"
                  placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column align="center" width="120" prop="numDemand" label="数量"></el-table-column>
          <el-table-column align="center" width="120" label="本次采购数量" label-class-name="required-th">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' + scope.$index + '.numPurchase'
                " :rules="[
                  {
                    required: true,
                    pattern: /^[0-9]+?$/,
                    message: '请输入>=0的整数',
                    trigger: 'blur',
                  },
                ]">
                <el-input maxlength="10" @blur="calculateNumPurchase(scope.$index)"
                  @input="validateNum(scope.$index, 'numPurchase')" v-model="scope.row.numPurchase" :disabled="
                    (scope.row.state != 11 || state == 2 || state == 3) &&
                    isEdit
                  " placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" prop="numRelax" label="溢装数">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' + scope.$index + '.numRelax'
                " :rules="[
                  {
                    pattern: /^[0-9]+?$/,
                    message: '请输入>=0的整数',
                    trigger: 'blur',
                  },
                ]">
                <el-input maxlength="10" v-model="scope.row.numRelax" :disabled="
                    (scope.row.state != 11 || state == 2 || state == 3) &&
                    isEdit
                  " @input="calculateNumRelax(scope.$index, 'numRelax')" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column align="center" width="180" prop="remarkOrder" label="订单备注">
            <template slot-scope="scope">
              {{ scope.row.remarkOrder || '--' }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" prop="productSize" label="产品描述">
            <template slot-scope="scope">
              <el-form-item>
                <el-input :disabled="scope.row.state != 11 && isEdit" type="textarea" :rows="3" maxlength="500"
                  show-word-limit v-model="scope.row.productSize" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" prop="isDrawback" label="是否退税">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' + [scope.$index] + '.isDrawback'
                " :rules="[
                  {
                    required: true,
                    message: '请选择是否退税',
                    trigger: 'change',
                  },
                ]">
                <el-select :key="isDrawbackDisabled" :disabled="isDrawbackDisabled" placeholder="请选择"
                  @change="isDrawbackChange" v-model="scope.row.isDrawback" clearable>
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" label="印刷方式" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.printingWay || '--' }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" prop="printingImg" label="效果图">
            <template slot-scope="scope">
              <div v-if="
                  scope.row.printingWay.toUpperCase() === 'BLANK' &&
                  scope.row.mainFlag === 0
                ">
                --
              </div>
              <div v-else>
                <el-row type="flex" align="middle" justify="center" v-if="scope.row.printingImg">
                  <el-image :src="scope.row.printingImg.split(',')[0] + URL_COMPRESS" class="c_pointer" @click="
                      editPreview(
                        scope.row.printingImg.split(','),
                        scope.$index
                      )
                    " style="width: 64px; height: 64px"></el-image>
                  <span class="fileAnnex-span" v-if="scope.row.printingImg.split(',').length > 1">
                    {{ scope.row.printingImg.split(',').length }}
                  </span>
                </el-row>
                <span v-if="scope.row.printingImg === ''">-</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" prop="productPrintingDescribe" label="印刷描述" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="
                  scope.row.printingWay.toUpperCase() === 'BLANK' &&
                  scope.row.mainFlag === 0
                ">
                --
              </div>
              <div v-else>
                <el-form-item>
                  <el-input :disabled="scope.row.state != 11 && isEdit" type="textarea" :rows="3" maxlength="500"
                    show-word-limit v-model="scope.row.productPrintingDescribe" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" width="180" prop="packingMethodEn" label="包装方式" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' +
                  scope.$index +
                  '.packingMethodEn'
                " :rules="[
                  {
                    max: 50,
                    message: '字符长度不能超过50',
                    trigger: 'blur',
                  },
                ]">
                <el-input :disabled="scope.row.state != 11 && isEdit" v-model="scope.row.packingMethodEn"
                  placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" prop="numberPerBox" label="装箱数量">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' + scope.$index + '.numberPerBox'
                " :rules="[
                  {
                    pattern: /^[0-9]+?$/,
                    message: '请输入>=0的整数',
                    trigger: 'blur',
                  },
                ]">
                <el-input :disabled="scope.row.state != 11 && isEdit" maxlength="10" v-model="scope.row.numberPerBox"
                  @input="validateNum(scope.$index, 'numberPerBox')" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" prop="boxVolumeMActual" label="外箱体积">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' +
                  scope.$index +
                  '.boxVolumeMActual'
                " :rules="[
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input :disabled="scope.row.state != 11 && isEdit" maxlength="20"
                  v-model="scope.row.boxVolumeMActual" @input="validateNum(scope.$index, 'boxVolumeMActual')"
                  placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" prop="boxWeightKgs" label="外箱重量">
            <template slot-scope="scope">
              <el-form-item :disabled="scope.row.state != 11 && isEdit" :prop="
                  'purchaseProductEditDTOList.' + scope.$index + '.boxWeightKgs'
                " :rules="[
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input maxlength="20" v-model="scope.row.boxWeightKgs"
                  @input="validateNum(scope.$index, 'boxWeightKgs')" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column align="center" width="400" prop="logoInfo" label="印刷文件">
            <template slot-scope="scope">
              <div v-if="
                  scope.row.printingWay.toUpperCase() === 'BLANK' &&
                  scope.row.mainFlag === 0
                ">
                --
              </div>
              <div v-else>
                <div class="logoInfoBox">
                  <div class="shadow" v-if="scope.row.state != 11 && isEdit"></div>
                  <el-row>
                    <!-- <file-upload :ref="'ErpUploadLogoInfo' + scope.$index" :index="scope.$index"
                      @uploadList="multiUploadList" @initUpload="initUploadLogoInfo"></file-upload> -->
                    <FileUpload v-model="
                        form.purchaseProductEditDTOList[scope.$index].logoInfo
                      " />
                  </el-row>
                </div>
              </div>
            </template>
          </el-table-column>

          <!-- 1.8.3purchase  增加供应商抵扣金额 start  -->
          <el-table-column align="center" width="150" prop="" label="供应商折扣金额">
            <template slot-scope="scope">
              <div class="discount-sty">
                <el-form-item :prop="
                    'purchaseProductEditDTOList.' +
                    scope.$index +
                    '.supplierDiscount'
                  " :rules="[
                    {
                      pattern: /^\d+(\.\d{1,3})?$/,
                      message: '>0最多三位小数',
                      trigger: 'blur',
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (value == '') {
                          return callback()
                        }
                        value = +value
                        if (value === 0) {
                          callback(new error('不能为0'))
                        } else {
                          callback()
                        }
                      },

                      trigger: 'blur',
                    },
                  ]">
                  <el-input maxlength="10" style="width: 100px" @input="
                      checkPriceExcludingTaxTotal(
                        scope.row,
                        scope.$index,
                        'supplierDiscount'
                      )
                    " v-model="scope.row.supplierDiscount" :disabled="
                      ![0, 1].includes(scope.row.settlementStatus) && isEdit
                    " clearable></el-input>
                </el-form-item>
                <el-button :class="
                    form.purchaseProductEditDTOList[scope.$index]
                      .supplierDiscountRemaks
                      ? 'icon-b'
                      : 'icon-y'
                  " icon="el-icon-edit-outline" circle :disabled="
                    ![0, 1].includes(scope.row.settlementStatus) && isEdit
                  " @click="
                    discountClick(
                      scope.row.supplierDiscountRemaks,
                      scope.$index
                    )
                  "></el-button>
              </div>
            </template>
          </el-table-column>
          <!-- 1.8.3purchase  增加供应商抵扣金额 end  -->

          <el-table-column align="center" width="120" prop="setUpCharge" label="印刷费">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' + scope.$index + '.setUpCharge'
                " :rules="[
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input :disabled="
                    ![0, 1].includes(scope.row.settlementStatus) && isEdit
                  " maxlength="20" @input="
                    checkPriceExcludingTaxTotal(
                      scope.row,
                      scope.$index,
                      'setUpCharge'
                    )
                  " v-model="scope.row.setUpCharge" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" prop="otherCost" label="其他费用">
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' + scope.$index + '.otherCost'
                " :rules="[
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: '>=0最多三位小数',
                    trigger: 'blur',
                  },
                ]">
                <el-input :disabled="
                    ![0, 1].includes(scope.row.settlementStatus) && isEdit
                  " maxlength="20" @input="
                    checkPriceExcludingTaxTotal(
                      scope.row,
                      scope.$index,
                      'otherCost'
                    )
                  " v-model="scope.row.otherCost" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" prop="costDescription" label="费用说明">
            <template slot-scope="scope">
              <el-form-item>
                <el-input :disabled="scope.row.state != 11 && isEdit" type="textarea" :rows="3" maxlength="500"
                  show-word-limit v-model="scope.row.costDescription" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="240" prop="remark" label="备注" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-form-item>
                <el-input :disabled="scope.row.state != 11 && isEdit" type="textarea" :rows="3" maxlength="500"
                  show-word-limit v-model="scope.row.remark" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="160" fixed="right" label="交货日期">
            <template slot="header">
              <span style="color: red">*</span>
              <span>交货日期</span>
            </template>
            <template slot-scope="scope">
              <el-form-item :prop="
                  'purchaseProductEditDTOList.' +
                  [scope.$index] +
                  '.actualDeliveryDate'
                " :rules="[
                  {
                    required: true,
                    message: '交货日期必填',
                    trigger: 'change',
                  },
                ]">
                <el-date-picker :disabled="scope.row.state != 11 && isEdit" clearable
                  v-model="scope.row.actualDeliveryDate" type="date" placeholder="请选择" format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="150" prop="salesOrderNumber" label="采购需求编号" fixed="right">
            <template slot-scope="scope">
              <div class="page-link">
                <span @click="goDetail(scope.row.salesOrderNumber)">
                  {{ scope.row.salesOrderNumber }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="noTax" width="150" label="总计-未税" fixed="right">
            <template slot-scope="scope">
              {{ form.currency === '美元' ? '$' : '￥' }}
              {{
              numberFormat(
              Number(scope.row.priceExcludingTax) *
              (Number(scope.row.numPurchase) +
              Number(scope.row.numRelax)) +
              (Number(scope.row.setUpCharge) +
              Number(scope.row.otherCost) -
              Number(scope.row.supplierDiscount)) /
              (scope.row.taxRate / 100 + 1),
              2
              )
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="hasTax" width="150" label="总计-含税" fixed="right">
            <template slot-scope="scope">
              {{ form.currency === '美元' ? '$' : '￥' }}
              {{
              numberFormat(
              Number(scope.row.priceIncludingTax) *
              (Number(scope.row.numPurchase) +
              Number(scope.row.numRelax)) +
              Number(scope.row.setUpCharge) +
              Number(scope.row.otherCost) -
              Number(scope.row.supplierDiscount),
              2
              )
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="80" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button v-show="!(scope.row.state != 11 && isEdit)" class="icon-r"
                @click="delProduct(scope.row, scope.$index)" size="mini" icon="el-icon-delete" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="account-num text-right mt10 mb20">
          总数量:
          <span>{{ calculateTotal('numPurchase') || 0 }}</span>
          总体积:
          <span>{{ calculateTotal('boxVolumeMActual') || 0 }}</span>
          总重量:
          <span>{{ calculateTotal('boxWeightKgs') || 0 }}</span>
          未税金额:
          <span class="red">
            {{ form.currency === '美元' ? '$' : '￥'
            }}{{ noTaxAmount | fixed2 }}
          </span>
          退税金额:
          <span class="red">
            {{ form.currency === '美元' ? '$' : '￥' }}{{ taxAmount | fixed2 }}
          </span>
          含税金额:
          <span class="red">
            {{ form.currency === '美元' ? '$' : '￥'
            }}{{ hasTaxAmount | fixed2 }}
          </span>
          订单总金额:
          <span class="red">
            {{ form.currency === '美元' ? '$' : '￥'
            }}{{ numberFormat(amountTotal, 2) }}
          </span>
        </div>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item prop="modelName" label="合同模板" label-width="80px">
              <el-select :disabled="isReviewed" placeholder="请选择" v-model="form.modelName" clearable>
                <!-- <el-option v-for="item in isStock ? modelNameListBH : modelNameList" :key="item.value"
                  :label="item.name" :value="item.value"></el-option> -->
                <el-option v-for="item in modelNameList" :key="item.value" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="箱唛" label-width="80px">
              <el-input :disabled="isReviewed" type="textarea" v-model="form.marks" maxlength="2000" show-word-limit
                :rows="3" placeholder="请输入500个字符以内"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" label-width="85px">
              <el-input :disabled="isReviewed" type="textarea" v-model="form.remark" maxlength="500" show-word-limit
                :rows="3" placeholder="请输入500个字符以内"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="oper-btn">
      <el-button @click="preview" type="info">预览</el-button>
      <el-button @click="save(3)" type="primary">确认</el-button>
      <el-button @click="save(1)">保存</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </div>

    <!-- 备货单详情 -->
    <stockUp-detail ref="stockUp"></stockUp-detail>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />
    <!-- 上传图片 -->
    <choose-images :visible.sync="chooseImagesDialogVisible" :default-file-list="currentDefaultRendingList"
      @confirm="setRendingImages"></choose-images>
    <!-- 抵扣说明弹窗 -->
    <DiscountText ref="discountText" :modelVisible="modelVisible" :discountReason="form.discountReason"
      @reason-confirm="confirmReason" />
    <!-- 新增/编辑供应商 -->
    <bank-account ref="bankAccount" :dialogAccountVisible.sync="dialogAccountVisible" :title="accountTitle" type="order"
      @confirm="setAccountTable" class="account-dialog"></bank-account>
  </div>
</template>

<script>
  import UserChoose from '@/components/userChoose'
  import UserSelect from '@/components/user-select'
  import userSelectMixin from '@/components/user-select/mixin'
  import AuditChoose from '@/components/AuditChoose'
  // import fileUpload from './components/fileUpload' // todo
  import FileUpload from '@/components/FileUpload'
  import stockUpDetail from './components/stockUpDetail'
  import { mapGetters } from 'vuex'
  import { formatDataToTreeChecked } from 'kits'
  import { deepCopy, isEmpty } from 'kits'
  import ChooseImages from '../purchaseOrder/components/chooseImages.vue'
  import DiscountText from './components/discountText'
  import { purchaseOrderLogFields, collectLogFields } from '@/utils/log-utils'
  import bankAccount from '@/components/bankAccount'
  import { URL_COMPRESS } from '@/config'

  import {
    PurchaseOrderInteractor,
    OrderInteractor,
    CreditManageInteractor,
  } from '@/core'
  import Decimal from 'decimal.js'
  export default {
    name: 'purchase-add',
    components: {
      UserChoose,
      UserSelect,
      AuditChoose,
      // fileUpload,
      FileUpload,
      stockUpDetail,
      ChooseImages,
      DiscountText,
      bankAccount,
    },
    inject: ['isPad'],
    mixins: [userSelectMixin],
    data() {
      return {
        paymentMethodBol: false,
        dialogAccountVisible: false,
        modelVisible: false,
        id: '',
        bz: [],
        bankList: [],
        form: {
          /**
           * 基本信息
           */
          supplierName: '', //供应商名称
          supplierId: '', //供应商id
          supplierAddress: '', //供应商地址
          agreementEndDate: '', //协议结束日期
          supplierLinkmanId: '', //联系人ID
          supplierLinkmanName: '', //联系人名称
          supplierLinkmanPhone: '', //联系电话
          buyerName: '', //采购开发名称
          buyerNameEn: '', //采购开发英文名称
          buyerId: '', //采购开发id
          buyerArea: '', //采购开发地区
          merchandiserId: '', //跟单员id
          merchandiserName: '', //跟单员名称
          merchandiserNameEn: '', //跟单员名称英文
          receivingAddress: '', // 交货地址
          transportWay: '', //运输方式
          freightPayer: '', //费用承担方
          /**
           * 付款方式
           */
          paymentMethod: '', //付款方式
          paymentMethodId: '', //付款方式Id
          currency: '人民币', //币种

          /**
           * 账户信息
           */
          bankName: '', // 开户行
          bankAccount: '', //账户
          accountName: '', //户名
          dutyAccount: '', //税号
          cnapsCode: '', //联行号
          supplierBankAccountId: '', //开户行Id
          /**
           * 产品列表
           */
          purchaseProductEditDTOList: [],
          /**
           * 发票信息
           */
          financeSetupId: '', // 发票id
          financeSetupName: '', // 发票Name
          financeSetupDuty: '', // 发票税号
          /**
           * 其他
           */
          modelName: '', // 采购合同模板
          purchaser: '', // 买方
          purchaserObject: '', // 买方对象
          marks: '', //箱唛
          remark: '', //备注
          exportsRise: '', // 出口抬头
          number: '', // 保存的时候需要
          /**
           * 付款方式
           */
          purchasePaymentMethodEditDTOList: [],
          /**
           * 审核信息
           */
          auditObjectDTO: null,
          discountAmount: undefined, //抵扣金额
          discountReason: '', // 抵扣原因
          isReviewed: '',
          invoiceType: '', // 发票类型
          warehouseFlag: 0,
        },
        error: Error,
        rules: {
          supplierId: [
            { required: true, message: '请输入供应商名称', trigger: 'change' },
          ],
          buyerName: [
            { required: true, message: '请选择采购开发', trigger: 'change' },
          ],
          receivingAddress: [
            { required: true, message: '请输入交货地址', trigger: 'blur' },
            { max: 50, message: '字符长度不能超过50', trigger: 'blur' },
          ],
          transportWay: [
            { required: true, message: '请选择运输方式', trigger: 'change' },
          ],
          freightPayer: [
            { required: true, message: '请选择费用承担方', trigger: 'change' },
          ],
          currency: [
            { required: true, message: '请选择币种', trigger: 'change' },
          ],
          paymentMethodId: [
            { required: true, message: ' ', trigger: 'change' },
          ],
          accountName: [
            {
              required: true,
              message: '请输入',
              trigger: 'change',
            },
            {
              max: 100,
              message: '字符长度不能超过100',
              trigger: 'blur',
            },
          ],
          financeSetupId: [
            {
              required: false,
              message: '请选择发票信息',
              trigger: 'change',
            },
          ],
          modelName: [
            {
              required: true,
              message: '请选择采购合同模板',
              trigger: 'change',
            },
          ],
          purchaserObject: [
            {
              required: true,
              message: '买方必填',
              trigger: 'change',
            },
          ],
          invoiceType: [
            {
              required: true,
              message: '发票类型必选',
              trigger: 'change',
            },
          ],
        },
        state: null, // 采购单状态
        noTaxAmount: 0, // 未税总金额
        hasTaxAmount: 0, // 含税总金额
        taxAmount: 0, // 退税总金额
        amountTotal: 0,
        numRelax: null, // 溢装数
        payList: [],
        paylinkList: [],
        supplierList: [],
        bankAccountList: [],
        buyerArray: [],
        merchandiserArray: [],
        linkmanList: [],
        financeList: [],
        purchaseUsers: [], //采购开发下拉列表
        purchaserList: [],
        isEdit: false,
        bankDisabled: false,
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        chooseImagesDialogVisible: false, // 选择印刷效果图弹窗
        currentRendingIndex: -1, // 当前选择印刷效果图的产品，对应的 index
        currentDefaultRendingList: [], // 当前选中的图片
        currentDefaultRendingStatus: '1', // 当前选中的图片状态
        //备注信息的附件
        uploadParamsCertif2: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        isReviewed: false, // 曾经审核通过过 false全部可编辑，true只有产品列表可编辑
        chooseImagesDialogVisible: false, // 选择印刷效果图弹窗
        currentDefaultRendingList: [], // 当前选中的图片
        changeImageIndex: 0,
        isStock: false, // 是否走备货合同
        modelNameList: [
          {
            value: '1',
            name: '购销合同(简易版)（＜5000）',
          },
          {
            value: '2',
            name: '购销合同(正式版)（≥ 5000，＜5万）',
          },
          {
            value: '3',
            name: '购销合同(详细版)（≥ 5万）',
          },
        ],
        modelNameListBH: [
          {
            value: '1',
            name: '备货合同，简易版，5万以下（含）适用',
          },
          {
            value: '2',
            name: '备货合同、正式版、5万及以上适用',
          },
          {
            value: '3',
            name: '备货合同、详细版、50万以上（含）适用',
          },
        ],
        accountTitle: '新增',
        taxRateList: [], //税率下拉列表
        invoiceTypeList: [], //发票类型下拉数据
        isDrawbackDisabled: false, // 是否退税可编辑
        showSwitch: '2',
        URL_COMPRESS,
      }
    },
    watch: {
      'form.supplierId'(val) {
        if (!val) {
          this.form.supplierLinkmanId = ''
          this.form.supplierLinkmanName = ''
          this.form.supplierLinkmanPhone = ''
        }
      },
      'form.purchaserObject'(val) {
        if (!this.handled && this.isEdit) {
          // 编辑处理
          this.handled = true
          return
        }
        this.form.financeSetupId = val ? val?.financeSetupId : ''
        this.form.financeSetupName = val ? val?.financeName : ''
        this.form.purchaser = val ? val?.financeName : ''
        this.form.financeSetupDuty = val ? val?.dutyParagraph : ''
      },
      'form.currency'(val) {
        if (this.isEdit) {
          let t = this.bz.find((item) => item.financeName == val)
          if (t) {
            this.form.exchangeRate = t.exchangeRate
          }
        }
      },
      'form.financeSetupId'(val) {
        let invoiceTypeList = []
        if (!val) {
          this.invoiceTypeList = []
          this.form.invoiceType = ''
          return
        } else {
          // 获取买方类型
          let currentBuyer = this.financeList.find(
            (item) => item.financeSetupId == val
          )

          if (currentBuyer && currentBuyer.type) {
            let type = currentBuyer.type
            if (type == 1) {
              // 公司类型
              invoiceTypeList = [
                {
                  code: 1,
                  name: '增值税专用发票',
                },
                {
                  code: 2,
                  name: '增值税普通发票',
                },
              ]
            } else if (type == 2) {
              //个体户
              invoiceTypeList = [
                {
                  code: 2,
                  name: '增值税普通发票',
                },
                {
                  code: 3,
                  name: '不开票',
                },
              ]
            } else if (type == 3) {
              //海外
              invoiceTypeList = [
                {
                  code: 3,
                  name: '不开票',
                },
              ]
            }
            this.invoiceTypeList = invoiceTypeList
            if (this.isEdit && !this.invoiceTypeTriggered) {
              this.invoiceTypeTriggered = true
              return
            }
            const type2InvoiceType = {
              1: 1,
              2: 3,
              3: 3,
            }
            this.form.invoiceType = type2InvoiceType[type]
          }
        }
      },
      'form.invoiceType'(val) {
        if (this.isEdit && !this.triggered) {
          this.triggered = true
          return
        }
        this.form.purchaseProductEditDTOList =
          this.form.purchaseProductEditDTOList.map((pro, index) => {
            if (val == 1 || val == 2) {
              pro.isDrawback = '1'
              this.isDrawbackDisabled = false
            } else {
              pro.isDrawback = '0'
              this.isDrawbackDisabled = true
            }
            if (val == 1) {
              pro.taxRate = this.defaultTaxRate
            } else {
              pro.taxRate = 0
            }
            this.calculateTax(index, 'priceIncludingTax')
            return pro
          })
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      max() {
        return this.hasTaxAmount * 1
      },
      // 抵扣金额更改根据结算状态判断 0,1可编辑
      disableDiscount() {
        return this.form.purchaseProductEditDTOList.some(
          (item) => ![0, 1].includes(item.settlementStatus)
        )
      },
      disabledSettle() {
        if (!this.isEdit) {
          return false
        }
        let pros = this.form.purchaseProductEditDTOList
        if (pros.some((item) => ![0, 1].includes(item.settlementStatus))) {
          return true
        } else {
          return false
        }
      },
      disableSettlementStatusSku() {
        const arr = []
        if (!this.isEdit) {
          return arr
        }
        let pros = this.form.purchaseProductEditDTOList
        pros.forEach((pro) => {
          if (![0, 1].includes(pro.settlementStatus)) {
            arr.push(pro.sku)
          }
        })

        return arr
      },
    },
    filters: {
      fixed2(val) {
        if (typeof val === 'number') {
          return Decimal(val).toFixed(2)
        }
        return val
      },
      statusFilter(val) {
        let statusObj = {
          0: '提交',
          1: '审核中',
          2: '已通过',
          3: '已驳回',
          4: '已撤销',
          5: '录入中',
          6: '无需审核',
          7: '黑名单',
        }
        return statusObj[val]
      },
    },

    created() {
      this.isStock = JSON.parse(this.$route.query.isStock || false)
      this.showSwitch = this.$route.query.showSwitch
      this.getListUserByRoleName()
      this.getContactData()
      // 获取币种
      OrderInteractor.financeCurrencyList().then((res) => {
        if (res && res.code === '000000') {
          this.bz = res.data
        }
      })

      // 获取税率
      PurchaseOrderInteractor.getTaxRateList().then((res) => {
        if (res && res.code === '000000') {
          this.taxRateList = res.data
          this.defaultTaxRate = res.data.find(
            (item) => item.isDefault == 1
          )?.rate
        }
      })
    },

    async mounted() {
      await this.getDict()
      if (this.$route.query.purchaseOrderId) {
        this.isEdit = true
        this.getEdit(this.$route.query.purchaseOrderId)
      } else {
        this.isEdit = false
        this.initData(this.$route.query.ids)
      }
    },
    methods: {
      // 保留num位小数
      numberFormat(val, number) {
        if (isEmpty(val)) {
          return '--'
        }
        return Decimal(val).toFixed(number)
      },
      // 校验采购产品金额在不在金额区间
      checkoutAmount() {
        if (!this.amountTotal) return
        const currItem = this.payList.filter(
          (item) =>
            item.supplierPayId === this.form.paymentMethodId &&
            item.deleted === 0
        )?.[0]
        if (!currItem) return
        const beginSection = parseInt(currItem.beginSection, 10)
        const endSection = parseInt(currItem.endSection, 10)
        if (!beginSection && !endSection) return
        if (
          Number(this.amountTotal) >= beginSection &&
          Number(this.amountTotal) < endSection
        ) {
        } else {
          this.form.paymentMethodId = ''
          this.paylinkList = []
          this.paymentMethodBol = true
        }
      },

      // 单个校验未税金额大于等于0
      checkPriceExcludingTaxTotal(row, i, type) {
        this.validateNum(i, type)
        const {
          priceExcludingTax,
          numPurchase,
          numRelax,
          setUpCharge,
          otherCost,
          supplierDiscount,
          nameCn,
          sku,
          taxRate,
        } = row
        this.calculateTaxAmount()
        const fee = this.utils.moneyFormat(
          Number(priceExcludingTax) * (Number(numPurchase) + Number(numRelax)) +
          (Number(setUpCharge) +
            Number(otherCost) -
            Number(supplierDiscount)) /
          (taxRate / 100 + 1)
        )
        if (fee < 0) {
          this.$message.warning(`请检查，未税金额出现负数金额，不支持提交`)
          return false
        }
        return true
      },
      // 提交时校验未税金额大于0
      checkSupplierDiscount(row, index) {
        let products = this.form.purchaseProductEditDTOList
        for (let i = 0; i < products.length; i++) {
          if (!this.checkPriceExcludingTaxTotal(products[i])) {
            return Promise.reject()
          }
        }
        return Promise.resolve()
      },
      // 买方下拉字典项
      getContactData() {
        OrderInteractor.contractData({ tenantId: this.userInfo.tenantId }).then(
          (res) => {
            if (res && res.code === '000000') {
              this.purchaserList = res.data.map((item) => {
                return {
                  ...item,
                  name: item.name,
                  value: item.name,
                }
              })
            }
          }
        )
      },
      //权限-客户代表角色下拉
      async getListUserByRoleName() {
        let response = await CreditManageInteractor.listUserByRoleName({
          divideGroup: 0,
          name: '采购员',
          tenantId: this.userInfo.tenantId,
        })
        if (response.code === '000000') {
          this.purchaseUsers = response.data
        }

        // 默认带出当前登录人信息

        let bool = this.purchaseUsers.some(
          (item) => item.userId == this.userInfo.userId
        )
        if (!this.isEdit && bool) {
          this.form.buyerName = deepCopy(this.userInfo.userName)
          this.form.buyerId = deepCopy(this.userInfo.userId)
          this.form.englishName = deepCopy(this.userInfo.englishName)
          this.form.buyerPhone = deepCopy(this.userInfo.telephone)
        }
        if (!this.isEdit) {
          this.form.exportsRise = this.$route.query.exportsRise
        }
      },
      confirmReason(supplierDiscountRemaks, index) {
        const currentRow = this.form.purchaseProductEditDTOList[index]
        if (!currentRow.supplierDiscountRemaks) {
          this.$set(
            currentRow,
            'supplierDiscountRemaks',
            supplierDiscountRemaks
          )
        } else {
          currentRow.supplierDiscountRemaks = supplierDiscountRemaks
        }
      },
      discountClick(text, i) {
        this.$refs.discountText.init(text, i)
      },
      // 付款方式账户信息是否可编辑
      isPayment() {
        if (this.isEdit && this.form.purchasePaymentMethodEditDTOList?.length) {
          return this.form.purchasePaymentMethodEditDTOList.every(
            (item) =>
              (item.type === 0 && item.applicationPayOrderState === -1) ||
              item.type != 0
          )
        } else {
          return true
        }
      },

      // 获取字典项
      getDict() {
        // 获取溢装数比例
        OrderInteractor.orderGetDict({ amountType: 4 }).then((res) => {
          if (res.code === '000000' && res?.data?.length) {
            this.numRelax = res.data[0]?.amountShort
          }
        })
        // 获取发票信息
        return CreditManageInteractor.selectOptions({ financeType: 2 }).then(
          (res) => {
            if(res.code === '000000') {
              this.financeList = res.data
            }

          }
        )
      },

      // 获取编辑下数据
      getEdit(id) {
        this.resetTrigger()
        OrderInteractor.purchaseGetDetailForEdit({ businessId: id }).then(
          (res) => {
            let self = this
            const data = res.data
            if (data) {
              self.state = data.state
              self.id = data?.id
              self.isReviewed = data.hasPay ? true : false
              self.supplierList = data.supplierStatusVOList?.filter(
                (i) => i.freeze != 1
              )
              const supplierId = data.supplierId
              if (
                supplierId &&
                this.supplierList &&
                !this.supplierList.find((item) => item.supplierId == supplierId)
              ) {
                this.supplierList.push({
                  freeze: 0,
                  status: 5,
                  supplierId,
                  supplierName: data.supplierName || '',
                })
              }
              self.form.number = data?.number
              self.form.supplierAddress = data.address
              self.form.supplierId = data.supplierId
              self.form.supplierBankAccountId = data.supplierBankAccountId
              self.supplierChange(data.supplierId)
              self.form.agreementEndDate = data.agreementEndDate
              self.form.supplierLinkmanId = data.supplierLinkmanId
              self.form.supplierLinkmanPhone = data.supplierLinkmanPhone
              self.form.buyerName = data.buyerName
              self.form.buyerId = data.buyerId
              self.form.warehouseFlag = data.warehouseFlag
              this.defaultUser = {
                userId: data.buyerId,
                userName: data.buyerName,
                englishName: data.buyerNameEn,
              }
              this.defaultQC = {
                userId: data.merchandiserId,
                userName: data.merchandiserName,
                englishName: data.merchandiserNameEn,
              }
              self.form.discountAmount = data.discountAmount
              self.form.discountReason = data.discountReason
              self.form.buyerNameEn = data.buyerNameEn
              self.form.buyerArea = data.buyerArea
              self.form.merchandiserName = data.merchandiserName
              self.form.merchandiserId = data.merchandiserId
              // 用于 跟单员 tree 回显
              self.merchandiserArray = formatDataToTreeChecked(
                data.merchandiserId,
                data.merchandiserName
              )
              self.form.receivingAddress = data.receivingAddress
              self.form.transportWay = data.transportWay
              self.form.freightPayer = data.freightPayer
              self.form.paymentMethodId = data.paymentMethodId
              self.form.currency = data.currency
              self.form.purchasePaymentMethodEditDTOList = self.paylinkList =
                data.purchasePaymentMethodEditVOList
              self.form.accountType = data.accountType
              self.form.bankName = data.bankName
              self.form.bankAccount = data.bankAccount
              if (self.form.accountType == 1 || self.form.accountType == 2) {
                self.form.accountName =
                  data.accountName + '/' + data.bankAccount
              } else {
                self.form.accountName = data.accountName
              }
              self.form.dutyAccount = data.dutyAccount
              self.form.cnapsCode = data.cnapsCode
              self.form.modelName = ['4', '5', '6'].includes(data.modelName)
                ? ''
                : data.modelName
              self.form.purchaser = data.purchaser
              self.form.purchaseProductEditDTOList =
                data.purchaseProductEditVOList.map((item) => {
                  return {
                    ...item,
                    isDrawback: String(item.isDrawback),
                    taxRate: this.BigMath.multiply(item.taxRate, 100),
                    setUpCharge: item.setUpCharge
                      ? Decimal(item.setUpCharge).toFixed(3)
                      : 0,
                    otherCost: item.otherCost ? Decimal(item.otherCost).toFixed(3) : 0,
                    priceExcludingTax: Decimal(item.priceExcludingTax).toFixed(3),
                    priceIncludingTax: Decimal(item.priceIncludingTax).toFixed(3),
                    supplierDiscount: item.supplierDiscount
                      ? Decimal(item.supplierDiscount).toFixed(3)
                      : '',
                  }
                })
              this.form.exchangeRate = data.exchangeRate
              // 手动触发计算：未税金额、含税金额
              this.calculateTaxAmount()
              // 处理退税金额
              this.taxAmount = data.amountDrawbackTax || '--'

              self.form.financeSetupName = data.financeSetupName
              self.form.financeSetupDuty = data.financeSetupDuty
              self.form.marks = data.marks
              self.form.remark = data.remark
              self.form.isReviewed = data.isReviewed
              // 日志功能开始
              this.prevObj = deepCopy(self.form)
              // 日志功能结束
              // 手动回显印刷文件
              // self.$nextTick(() => {
              //   if (self.form?.purchaseProductEditDTOList) {
              //     self.form.purchaseProductEditDTOList.forEach(
              //       (item, index) => {
              //         self.initUploadLogoInfo(item.logoInfo, index)
              //       }
              //     )
              //   }
              // })
              // 处理老数据

              if (data.purchaser) {
                let realPurchaser = this.financeList.find(
                  (item) => item.financeName === data.purchaser
                )

                if (
                  realPurchaser &&
                  realPurchaser.financeSetupId != data.financeSetupId
                ) {
                  data.financeSetupId = realPurchaser.financeSetupId
                }
              }
              self.form.financeSetupId = data.financeSetupId
              // 发票类型回显
              self.form.invoiceType = data.invoiceType
              if (data.invoiceType != 1 && data.invoiceType != 2) {
                this.isDrawbackDisabled = true
              }
              //处理开关
              if (!data.financeSetupId && !data.invoiceType) {
                this.invoiceTypeTriggered = true
                this.handled = true
              }

              if (data.financeSetupId) {
                self.form.purchaserObject = {
                  financeSetupId: data.financeSetupId,
                }
              }
            }
          }
        )
      },

      // 初始化加载数据
      initData(ids) {
        if (!ids) return
        OrderInteractor.purchasingDCreatePurchaseOrder({
          businessIds: ids,
        }).then((res) => {

          if (res?.code === '000000') {
            // 获取供应商下拉项
            if (res?.data?.supplierSelectMap) {
              this.supplierList = res.data.supplierStatusVOList?.filter(
                (i) => i.freeze != 1
              )
            }
            // 获取产品列表
            this.form.purchaseProductEditDTOList =
              res.data?.purchaseProductEditVOList?.map((item) => {
                return {
                  ...item,
                  numPurchase: item.numPurchase || 0,
                  numRelax: item.numRelax || 0,
                  numberPerBox: item.numberPerBox || 0,
                  boxVolumeMActual: item.boxVolumeMActual || 0,
                  boxWeightKgsActual: item.boxWeightKgsActual || 0,
                  setUpCharge: item.setUpCharge ? Decimal(item.setUpCharge).toFixed(3) : 0,
                  otherCost: item.otherCost ? Decimal(item.otherCost).toFixed(3) : 0,
                  noTax: 0,
                  hasTax: 0,
                  isDrawback: '0', // 是否退税默认否
                  supplierDiscount: item.supplierDiscount || '',
                }
              })
            // 赋值箱唛
            this.form.marks = res.data.marks
            this.form.buyerArea = res.data.buyerArea
          }

        })
      },

      // 选择付款方式
      paymentMethodChange(id) {
        this.paymentMethodBol = false
        let self = this
        self.paylinkList = []
        self.payList.forEach((item, i) => {
          if (item.supplierPayId == id && item.deleted === 0) {
            const array = item?.paymentMethodName?.split(',') ?? []
            if (item.deposit > 0) {
              self.paylinkList.push({
                paymentNode: '1',
                paymentNodeName: self.getPayNode(array, '定金'),
                percent: item.deposit / 100,
                after: item.depositAfter,
              })
            }
            if (item.beforeShipping > 0) {
              self.paylinkList.push({
                paymentNode: '2',
                paymentNodeName: self.getPayNode(array, '发货前'),
                percent: item.beforeShipping / 100,
                after: item.beforeShippingAfter,
              })
            }
            if (item.uponDelivery > 0) {
              self.paylinkList.push({
                paymentNode: '3',
                paymentNodeName: self.getPayNode(array, '发货后'),
                percent: item.uponDelivery / 100,
                after: item.uponDeliveryAfter,
              })
            }
            if (item.finalPayment > 0) {
              self.paylinkList.push({
                paymentNode: '4',
                paymentNodeName: self.getPayNode(array, '收货后'),
                percent: item.finalPayment / 100,
                after: item.finalPaymentAfter,
              })
            }
          }
        })
        self.form.purchasePaymentMethodEditDTOList = self.paylinkList
        this.checkoutAmount()
      },

      // 根据付款方式显示付款环节
      getPayNode(arr, field) {
        let index = null
        arr.forEach((item, i) => {
          if (item.includes(field)) index = i
        })

        return arr[index].split('%')[1] || field
      },

      // 选择供应商名称
      async supplierChange(id) {
        // 切换供应商，清楚账户信息
        this.form.accountType = ''
        this.form.bankName = ''
        this.form.bankAccount = ''
        this.form.accountName = ''
        this.form.dutyAccount = ''
        this.form.cnapsCode = ''
        this.form.paymentMethodId = ''
        this.supplierBankAccountId = ''
        OrderInteractor.supplierGetDetail({ id: id }).then((res) => {
          const data = res.data ? res.data : null
          if (data) {
            this.form.supplierAddress = data.address
            this.form.agreementEndDate = data.agreementEndDate
            this.payList = data.payVOS
            this.linkmanList = data.linkmanVOS
            this.bankAccountList = data.bankAccountVOS
            if (this.bankAccountList?.length === 1) {
              this.form.cnapsCode = this.bankAccountList[0].cnapsCode
              this.form.accountType = this.bankAccountList[0].accountType
              this.form.bankName = this.bankAccountList[0].bankName
              this.form.bankAccount = this.bankAccountList[0].bankAccount

              if (this.form.accountType == 1 || this.form.accountType == 2) {
                this.form.accountName =
                  this.bankAccountList[0].accountName +
                  '/' +
                  this.bankAccountList[0].bankAccount
              } else {
                this.form.accountName = this.bankAccountList[0].accountName
              }
              this.form.dutyAccount = this.bankAccountList[0].dutyAccount
              this.form.supplierBankAccountId =
                this.bankAccountList[0].supplierBankAccountId
            }
            if (this.form.bankAccount) {
              this.bankList = data?.bankAccountVOS?.filter(
                (item) => item.bankAccount == this.form.bankAccount
              )
            } else {
              this.bankList = data.bankAccountVOS
            }
            // 只有一个联系人自动带入
            if (this.linkmanList?.length == 1) {
              this.form.supplierLinkmanId =
                this.linkmanList[0].supplierLinkmanId
              this.form.supplierLinkmanName = this.linkmanList[0].linkman
              this.form.supplierLinkmanPhone = this.linkmanList[0].phone
            } else if (this.linkmanList?.length > 1) {
              this.getLinkManPhone(this.form.supplierLinkmanId)
            }
          }
        })
        // 赋值供应商名称
        this.supplierList?.forEach((item) => {
          if (item.supplierId == id) {
            this.form.supplierName = item.supplierName
          }
        })
      },

      // 选择联系人带出联系电话
      getLinkManPhone(id) {
        this.linkmanList.forEach((item) => {
          if (item.supplierLinkmanId == id) {
            this.form.supplierLinkmanName = item.linkman
            this.form.supplierLinkmanPhone = item.phone
          }
        })
      },

      //账户信息-新增
      addAccountDetails() {
        if (this.form.supplierId) {
          this.dialogAccountVisible = true
          this.$refs.bankAccount.addMsg()
        } else {
          this.$message.warning('请选择供应商')
        }
      },

      onBlur(val) {
        if (!val) {
          this.$refs.select.blur()
        }
      },
      // 模糊搜索户名
      remoteMethod(query) {
        if (query) {
          this.bankList = this.bankAccountList?.filter(
            (item) => item.accountName.indexOf(query) > -1
          )
        } else {
          this.bankList = this.bankAccountList
        }
      },

      // 选择开户行
      handleSelect(val) {
        if (val) {
          let obj = this.bankAccountList.find(
            (item) => item.bankAccount == val.split('/')[1]
          )
          if(obj) {
            this.form.accountType = obj.accountType
            this.form.cnapsCode = obj.cnapsCode
            this.form.bankName = obj.bankName
            this.form.bankAccount = obj.bankAccount
            this.form.dutyAccount = obj.dutyAccount
            this.form.supplierBankAccountId = obj.supplierBankAccountId
          }

        } else {
          this.bankList = this.bankAccountList
          this.form.accountType = ''
          this.form.bankName = ''
          this.form.bankAccount = ''
          this.form.dutyAccount = ''
          this.form.cnapsCode = ''
          this.supplierBankAccountId = ''
        }
      },

      //新增收款账户后刷新模糊查询下拉框
      setAccountTable(data) {
        this.$nextTick(() => {
          this.bankAccountList = data || []
          this.dialogAccountVisible = false
        })
      },

      // 跳转详情
      goDetail(id) {
        if (id.includes('-')) {
          this.$refs.stockUp.initLoading(id)
        } else {
          let routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail',
            query: { orderCode: id, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        }
      },

      //提交订单
      save(type) {
        let self = this
        if (!this.isEdit) {
          this.form.logList = []
        } else {
          this.form.logList = this.saveLogFields()
        }
        // type:1保存， type:3提交
        if (type === 1) {
          if (!self.form.supplierId) {
            self.$message.warning('供应商名称必填！')
            return false
          }

          // 请求时数据格式处理， 税率(taxRate)/100
          self.form.purchaseProductEditDTOList =
            self.form.purchaseProductEditDTOList.map((item) => {
              return {
                ...item,
                taxRate: item.taxRate / 100,
              }
            })
          this.form.purchasePaymentMethodEditDTOList =
            this.form.purchasePaymentMethodEditDTOList.map((it) => {
              return {
                ...it,
                currency: this.form.currency,
              }
            })
          let accountName = this.form.accountName?.split('/')[0]
          let params = {
            id: self.id,
            state: type,
            purchaseOrderId: self.$route.query?.purchaseOrderId,
            ...self.form,
          }
          if (accountName) {
            params.accountName = accountName
          }
          OrderInteractor.purchaseInsertPO(params).then((res) => {
            if (res && res.code == '000000') {
              self.$message.success('保存成功')
              self.$router.push('/purchase/purchaseOrder/purchaseOrder')
            } else {
              // 请求失败数据格式处理， 税率(taxRate)*100
              self.form.purchaseProductEditDTOList =
                self.form.purchaseProductEditDTOList.map((item) => {
                  return {
                    ...item,
                    taxRate: item.taxRate * 100,
                  }
                })
            }
          })
        } else {
          self.$refs.ruleForm.validate((valid) => {
            if (valid) {
              self
                ._checkSupplierDiscountRemaks()
                .then((res) => {
                  res &&
                    self.checkSupplierDiscount().then(() => {
                      let auditParams = {
                        auditType: 3,
                        status: 0,
                        tenantId: self.userInfo.tenantId,
                      }
                      self.$refs['AuditChoose'].showAddEdit(auditParams)
                    })
                })

                .catch(() => { })
            } else {
              return false
            }
          })
        }
      },

      _checkSupplierDiscountRemaks() {
        let index = this.form.purchaseProductEditDTOList.findIndex(
          (item) => item.supplierDiscount && !item.supplierDiscountRemaks
        )
        if (index > -1) {
          let item = this.form.purchaseProductEditDTOList[index]
          this.$message.warning(
            `产品列表第${index + 1}行产品${item.sku}/${item.nameCn
            }的供应商折扣备注不能为空`
          )

          return Promise.resolve(false)
        } else {
          return Promise.resolve(true)
        }
      },

      _checkDiscountReason() {
        const { discountAmount, discountReason } = this.form
        if (
          discountAmount &&
          (!discountReason || discountReason.trim() === '')
        ) {
          this.$message.warning('请输入抵扣说明')
          return Promise.reject()
        } else {
          return Promise.resolve()
        }
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },

      // 保存审核模板信息
      saveClick(data) {
        this.form.auditObjectDTO = data
        this.submitSave()
      },

      // 确定提交
      submitSave() {
        let self = this
        // 请求时数据格式处理， 税率(taxRate)/100
        self.form.purchaseProductEditDTOList =
          self.form.purchaseProductEditDTOList.map((item) => {
            return {
              ...item,
              taxRate: item.taxRate / 100,
              currency: this.form.currency,
            }
          })
        let accountName = this.form.accountName?.split('/')[0]
        const params = {
          id: self.id,
          state: 4,
          ...self.form,
        }
        if (accountName) {
          params.accountName = accountName
        }
        if (self.isEdit) {
          params.purchaseOrderId = self.$route.query.purchaseOrderId
          OrderInteractor.purchaseUpdatePO(params).then((res) => {
            if (res && res.code == '000000') {
              self.$message.success('保存成功')
              self.$router.push('/purchase/purchaseOrder/purchaseOrder')
            } else {
              // 请求失败数据格式处理， 税率(taxRate)*100
              self.form.purchaseProductEditDTOList =
                self.form.purchaseProductEditDTOList.map((item) => {
                  return {
                    ...item,
                    taxRate: this.BigMath.multiply(item.taxRate, 100),
                  }
                })

              return this.$message({
                type: 'error',
                message: res?.desc,
                dangerouslyUseHTMLString: true,
              })
            }
          })
        } else {
          OrderInteractor.purchaseInsertPO(params).then((res) => {
            if (res && res.code == '000000') {
              self.$message.success('保存成功')
              self.$router.push('/purchase/purchaseOrder/purchaseOrder')
            } else {
              // 请求失败数据格式处理， 税率(taxRate)*100
              self.form.purchaseProductEditDTOList =
                self.form.purchaseProductEditDTOList.map((item) => {
                  return {
                    ...item,
                    taxRate: this.BigMath.multiply(item.taxRate, 100),
                  }
                })

              return this.$message({
                type: 'error',
                message: res?.desc,
                dangerouslyUseHTMLString: true,
              })
            }
          })
        }
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (
          columnIndex == 1 ||
          columnIndex == 2 ||
          columnIndex == 3 ||
          columnIndex == 12 ||
          columnIndex == 13
        ) {
          return 'star'
        }
      },

      // 计算总数量
      calculateTotal(val) {
        let self = this
        if (self.form.purchaseProductEditDTOList.length) {
          const arr = []
          self.form.purchaseProductEditDTOList.forEach((item) => {
            if (val == 'numPurchase') {
              arr.push(Number(item.numPurchase) + Number(item.numRelax))
            } else {
              arr.push(Number(item[val]))
            }
          })
          const total = arr.reduce((prev, cur) => prev + cur)
          if (val == 'numDemand') {
            return parseFloat(total)
          } else {
            return Decimal(total).toFixed(3)
          }
        }
      },

      // 计算总税额
      calculateTaxAmount() {
        let self = this
        if (self.form.purchaseProductEditDTOList.length) {
          const eArr = []
          const iArr = []
          self.form.purchaseProductEditDTOList.forEach((item) => {
            eArr.push(
              Number(item.priceExcludingTax) *
              (Number(item.numPurchase) + Number(item.numRelax)) +
              (Number(item.setUpCharge) +
                Number(item.otherCost) -
                Number(item.supplierDiscount)) /
              (item.taxRate / 100 + 1)
            )
            iArr.push(
              Number(item.priceIncludingTax) *
              (Number(item.numPurchase) + Number(item.numRelax)) +
              Number(item.setUpCharge) +
              Number(item.otherCost) -
              Number(item.supplierDiscount)
            )
          })
          self.noTaxAmount = this._reduceForTotal(eArr)

          self.hasTaxAmount = this._reduceForTotal(iArr)

          this.getAmountTotal()
        }
      },

      getAmountTotal() {
        this.amountTotal = Decimal(
          this.hasTaxAmount * 1 -
          (this.form.discountAmount ? this.form.discountAmount : 0) * 1
        ).toFixed(3)
        this.checkoutAmount()
      },

      // 限制只能输入数字
      validateNum(i, type) {
        const decimalList = [
          'priceIncludingTax',
          'priceExcludingTax',
          'boxVolumeMActual',
          'boxWeightKgs',
          'supplierDiscount',
          'setUpCharge',
          'otherCost',
        ] // 含税单价 未税单价 外箱体积 外箱重量 供应商折扣金额 印刷费 其他费用
        if (decimalList.includes(type)) {
          // 只能输入整数和小数，小数位数最多3位
          if (this.form.purchaseProductEditDTOList[i]?.[type]) {
            const regex = /^(\d+)?(\.\d{1,3})?$/
            let value = this.form.purchaseProductEditDTOList[i][type]
            if (!regex.test(value)) {
              if (/^(\d+)?\.?$/.test(value)) return // 允许数字后面输入一个小数点
              value = value + ''
              this.form.purchaseProductEditDTOList[i][type] = value.slice(
                0,
                value.length - 1
              )
            }
          }
        }

        const numList = ['numPurchase', 'numRelax', 'numberPerBox'] // 本次采购数量 溢装数 装箱数量
        if (numList.includes(type)) {
          // 禁止输入数字以外的字符
          if (this.form.purchaseProductEditDTOList[i]?.[type]) {
            this.form.purchaseProductEditDTOList[i][type] = (
              this.form.purchaseProductEditDTOList[i][type] + ''
            ).replace(/[^\d]/g, '')
          }
        }
      },

      // 计算税率
      calculateTax(i, type, isRate) {
        this.validateNum(i, type)
        let list = this.form.purchaseProductEditDTOList
        let taxRate = list[i].taxRate
        if (type == 'priceIncludingTax') {
          if (list[i].priceIncludingTax && list[i].taxRate >= 0) {
            const tax = list[i].taxRate / 100 + 1
            list[i].priceExcludingTax = Decimal(
              list[i].priceIncludingTax / tax
            ).toFixed(3)
          }
          if (
            +list[i].priceExcludingTax &&
            list[i].taxRate &&
            !list[i].priceIncludingTax
          ) {
            const tax = list[i].taxRate / 100 + 1
            list[i].priceIncludingTax = Decimal(
              list[i].priceExcludingTax * tax
            ).toFixed(3)
          }
        } else {
          if (list[i].priceExcludingTax && list[i].taxRate >= 0) {
            const tax = list[i].taxRate / 100 + 1
            list[i].priceIncludingTax = Decimal(
              list[i].priceExcludingTax * tax
            ).toFixed(3)
          }
        }
        // 产品含税单价，税率，未税单价必须相同
        this.form.purchaseProductEditDTOList.forEach((item, k) => {
          if (list[i].sku == item.sku) {
            this.form.purchaseProductEditDTOList[k].taxRate = taxRate
            this.form.purchaseProductEditDTOList[k].priceIncludingTax =
              list[i].priceIncludingTax
            this.form.purchaseProductEditDTOList[k].priceExcludingTax =
              list[i].priceExcludingTax
            this.form.purchaseProductEditDTOList[k].taxRate = taxRate
          }
        })
        if (isRate) {
          setTimeout(() => {
            this.batchCalcPriceEncludingTax(taxRate, i)
          }, 20)
        } else {
          // 计算总税金额
          this.calculateTaxAmount()
          // 计算退税金额
          this.isDrawbackChange()
        }
      },

      // 批量计算未税单价
      batchCalcPriceEncludingTax(taxRate, i) {
        this.form.purchaseProductEditDTOList.forEach((pro, index) => {
          pro.taxRate = taxRate
          if (pro.priceIncludingTax && pro.taxRate >= 0 && index != i) {
            const tax = pro.taxRate / 100 + 1
            pro.priceExcludingTax = Decimal(pro.priceIncludingTax / tax).toFixed(3)
          }
        })
        // 计算总税金额
        this.calculateTaxAmount()
        // 计算退税金额
        this.isDrawbackChange()
      },

      // 输入采购数量计算总计并判断采购数量不能大于产品剩余的需求待处理数量
      calculateNumPurchase(i) {
        if (this.isEdit) {
          let list = this.form.purchaseProductEditDTOList
          let [maxPurchase, sku, code, numPurchase] = [
            list[i].maxPurchase,
            list[i].sku,
            list[i].orderProductUniqueId,
            0,
          ]
          list.forEach((item) => {
            // 存在唯一编码需要匹配
            if (code) {
              if (sku == item.sku && code == item.orderProductUniqueId) {
                numPurchase = numPurchase + Number(item.numPurchase)
              }
            } else {
              if (sku == item.sku) {
                numPurchase = numPurchase + Number(item.numPurchase)
              }
            }
          })
          if (numPurchase > maxPurchase) {
            list[i].numPurchase =
              maxPurchase - (numPurchase - Number(list[i].numPurchase))
            this.$message.warning('采购数量不能大于产品剩余的需求待处理数量!')
          }
        }
        // 计算总税金额
        this.calculateTaxAmount()
        // 计算退税金额
        this.isDrawbackChange()
      },

      // 溢装数不能超过采购数量
      calculateNumRelax(i, type) {
        this.validateNum(i, type)
        let list = this.form.purchaseProductEditDTOList
        if (
          Number(list[i].numPurchase * this.numRelax) < Number(list[i].numRelax)
        ) {
          this.$message.warning('溢装数超出限制')
          list[i].numRelax = Math.floor(list[i].numPurchase * this.numRelax)
        }

        // 计算总税金额
        this.calculateTaxAmount()
        // 计算退税金额
        this.isDrawbackChange()
      },

      // 选择是否退税
      isDrawbackChange() {
        let self = this
        let isDrawback = self.form.purchaseProductEditDTOList.some(
          (item) => item.isDrawback == 1
        )
        // 当页面选择一条需要退税，发票信息为必填
        self.$refs.ruleForm.rules.financeSetupId[0].required = isDrawback
        // 计算退税总金额
        if (isDrawback) {
          const arr = []
          self.form.purchaseProductEditDTOList.forEach((item, i) => {
            if (item.isDrawback == 1) {
              arr.push(
                item.priceIncludingTax * item.numPurchase -
                item.priceExcludingTax * item.numPurchase
              )
            }
            self.taxAmount = this._reduceForTotal(arr)
          })
        } else {
          self.taxAmount = 0
        }
      },

      _reduceForTotal(arr) {
        let total = 0
        if (arr.length > 0) {
          arr.forEach((item) => {
            total += Decimal(item).toFixed(2) * 100
          })
        }
        return parseFloat(Decimal(total / 100).toFixed(2))
      },

      // 获取发票name
      financeSetupIdChange(val) {
        this.financeList.forEach((item) => {
          if (item.financeSetupId == val) {
            this.form.financeSetupId = val
            this.form.financeSetupName = item.financeName
            this.form.purchaser = item.financeName
            this.form.financeSetupDuty = item.dutyParagraph
          }
        })
      },

      // 删除产品列表
      delProduct(row, i) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            if (this.form.purchaseProductEditDTOList.length == 1) {
              this.$message.warning('至少保留一条数据！')
              return false
            }
            if (this.isEdit) {
              const params = {
                purchaseOrderNumber: this.form.number,
                purchaseProductId: row.purchaseProductId,
              }
              const res = await OrderInteractor.checkDeletePurchaseProduct(
                params
              )

              if (res.code === '000000') {
                this.form.purchaseProductEditDTOList.splice(i, 1)
                this.$message.success('删除成功！')
              }
            } else {
              this.form.purchaseProductEditDTOList.splice(i, 1)
              this.$message.success('删除成功！')
            }
          })
          .catch(() => { })
      },

      // 选择跟单员
      merchandiserChange() {
        this.$refs['UserChoose'].showAddEdit(
          '2',
          deepCopy(this.merchandiserArray)
        )
      },

      // 删除跟单员
      clearMerchandiserName() {
        this.form.merchandiserId = ''
        this.form.merchandiserName = ''
        this.form.merchandiserNameEn = ''
        this.merchandiserArray = []
      },
      userSelect(user, type) {
        let f = this.form
        if (type === 'PURCHASE') {
          if (!user?.userId) {
            f.buyerName = ''
            f.buyerArea = ''
            f.buyerNameEn = ''
            f.buyerPhone = ''
            f.buyerId = ''
          } else {
            f.buyerName = user.userName
            f.buyerArea = user.area
            f.buyerNameEn = user.englishName
            f.buyerPhone = user.telephone
            f.buyerId = user.userId
          }
        } else {
          if (!user?.userId) {
            f.merchandiserId = ''
            f.merchandiserName = ''
            f.merchandiserNameEn = ''
          } else {
            f.merchandiserId = user.userId
            f.merchandiserName = user.userName
            f.merchandiserNameEn = user.englishName
          }
        }
      },
      buyerChange(id) {
        if (!id) {
          this.form.buyerName = ''
          this.form.buyerArea = ''
          this.form.buyerNameEn = ''
          this.form.buyerPhone = ''
        }
        this.purchaseUsers.find((item) => {
          // ??? why use find？
          if (item.userId == id) {
            this.form.buyerName = item.userName
            this.form.buyerArea = item.area
            this.form.buyerNameEn = item.englishName
            this.form.buyerPhone = item.telephone
          }
        })
      },

      // 人员数返回
      chooseUser(userIdArr) {
        this.merchandiserArray = userIdArr
        let [ids, names, namesEn] = [[], [], []]
        userIdArr.forEach((item) => {
          ids.push(item.id)
          names.push(item.name)
          namesEn.push(item.englishName)
        })
        this.form.merchandiserId = String(ids)
        this.form.merchandiserName = String(names)
        this.form.merchandiserNameEn = String(namesEn)
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      //查看效果图
      editPreview(item, val) {
        this.changeImageIndex = val
        this.chooseImagesDialogVisible = true
        this.currentDefaultRendingList = item.map((ele) => {
          return {
            name: ele,
            url: `${ele}${URL_COMPRESS}`,
          }
        })
      },

      //印刷文件
      // initUploadLogoInfo(data, i) {
      //   this.$refs['ErpUploadLogoInfo' + i].initUpload(data)
      // },

      // 文件上传
      // multiUploadList(fileList, index) {
      //   this.form.purchaseProductEditDTOList[index].logoInfo =
      //     JSON.stringify(fileList)
      // },

      // 获取第一张图片
      getFirstImgUrl(urls) {
        return urls ? urls.split(',')[0] : ''
      },

      // 预览
      preview() {
        if (this.form.modelName) {
          let form = {
            ...this.form,
            amountTotal: this.amountTotal,
          }
          if (!form.creator) {
            form.creator = this.userInfo.userName
          }
          if (!form.creatorPhone) {
            form.creatorPhone = this.userInfo.telephone
          }
          sessionStorage.setItem(
            'purchaseOrder',

            JSON.stringify(form)
          )
          // let routeData = this.$router.resolve({
          //   path: this.isStock
          //     ? '/print/purchaseContract-BH'
          //     : '/print/purchaseContract',
          // })
          let routeData = this.$router.resolve({
            path: '/print/purchaseContract',
          })
          window.open(routeData.href, '_blank')
        } else {
          this.$message.warning('请先选择采购合同模板！')
          return false
        }
      },
      setRendingImages(data) {
        if (data.urls) {
          data.urls = data.urls.replaceAll(URL_COMPRESS, '')
        }
        this.form.purchaseProductEditDTOList[
          this.changeImageIndex
        ].printingImg = data.urls
      },

      // 跳转产品详情
      productView(productId, productCode) {
        let routeData = this.$router.resolve({
          path: '../../../../product/ProductView',
          query: {
            proId: productId,
            productCode: productCode,
          },
        })

        window.open(routeData.href, '_blank')
      },

      // 产品报关信息提示
      declareTips(row) {
        if (!row.declareCn && row.declareUnit) {
          return '此sku缺少报关中文名称，请联系单证人员及时完善报关信息'
        } else if (row.declareCn && !row.declareUnit) {
          return '此sku缺少报关法定计量单位，请联系单证人员及时完善报关信息'
        } else {
          return '此sku缺少报关中文名称、报关法定计量单位，请联系单证人员及时完善报关信息'
        }
      },
      // 日志变更字段请求
      saveLogFields() {
        this.prevObj.purchaseProductEditDTOList =
          this.prevObj.purchaseProductEditDTOList.map((item) => {
            return {
              ...item,
              taxRate: item.taxRate / 100,
            }
          })
        const logFields = collectLogFields(
          this.prevObj,
          this.form,
          purchaseOrderLogFields
        )
        return logFields
      },
      // 重置trigger
      resetTrigger() {
        this.invoiceTypeTriggered = false
      },
      clearAll() {
        this.form.purchaser = ''
        this.form.financeSetupId = ''
      },
    },
  }
</script>

<style scoped lang="scss">
  .custom-container {
    padding-bottom: 0 !important;

    .purchase-content {
      padding: 0 20px 0;

      h4 {
        font-weight: 600;
        font-size: 14px;
        margin: 20px 0 10px 0;
      }

      .column {
        height: 32px;
        margin: 20px 0;
        justify-content: flex-start;

        h4 {
          margin: 0;
          line-height: 32px;
        }
      }

      .el-input,
      .el-select {
        width: 100%;
      }
    }

    .fileAnnex-span {
      display: inline-block;
      width: 15px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      background: #9e9e9e;
      color: #fff;
      border-radius: 50%;
      margin-left: 5px;
      font-size: 12px;
    }

    .account-num {
      span {
        font-size: 16px;
        margin-right: 5px;
        font-weight: 600;
      }

      .el-input {
        width: 130px;
      }

      .el-input-number {
        width: 100px;
        margin-right: 5px;
        margin-left: 5px;
      }
    }

    .oper-btn {
      position: sticky;
      bottom: 0;
      text-align: center;
      background: white;
      padding: 10px 0;
      z-index: 100;
    }

    /deep/.el-table table th.star div:before {
      content: '*';
      color: red;
    }

    /deep/ .el-table__body tr,
    .el-table__body td {
      height: 90px;
    }

    .product-table {
      /deep/ {
        .cell {
          overflow: initial;

          .el-form-item__content {
            padding: 5px 0;

            .el-form-item__error {
              top: 42px;
            }
          }
        }
      }
    }

    .wrapper-box {
      .wrapper-add {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px dotted #c0cbda;
        border-radius: 3px;
        cursor: pointer;
      }
    }

    .logoInfoBox {
      position: relative;

      .shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 999;
      }
    }

    .split-style {
      position: relative;
      padding-right: 15px;

      em {
        position: absolute;
        top: 2px;
        right: 0;
        z-index: 999;
        cursor: pointer;
      }
    }

    .img-wrap {
      position: relative;
      padding: 3px 0;
      width: 50px;
      margin: 0 auto;

      .el-image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        display: block;
      }

      .el-badge {
        position: absolute;
        top: 3px;
        right: -10px;
        z-index: 2;
      }
    }
  }

  .discount-sty {
    position: relative;
    text-align: left;

    .el-button {
      position: absolute;
      top: 8px;
      right: -5px;
      z-index: 2;
      height: 28px;
      width: 28px;
      margin: 0;

      /deep/ i {
        font-size: 16px;
        margin: -3px;
      }
    }
  }

  .info-wrapper {
    text-align: left;
    width: 200px;

    p {
      width: 100%;
      padding: 2px 5px;
      white-space: break-spaces;
    }

    i {
      color: #f56c6c;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .pay-tips {
    font-size: 12px;
    color: red;
    position: absolute;
    left: 100px;
    top: 32px;
    line-height: 18px;
  }
</style>
