var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"custom-class":"shelves-dialog","before-close":_vm.cancelFn},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"dialog-cont"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(this.fromType !== 3),expression:"this.fromType !== 3"}],staticClass:"cont-title"},[_vm._v(_vm._s(_vm.contTitle))]),_c('el-form',{ref:"shelvesForm",attrs:{"model":_vm.shelvesForm,"rules":_vm.rules}},[_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(this.fromType !== 3),expression:"this.fromType !== 3"}],attrs:{"prop":"checkValue","rules":[
          {
            required: true,
            message: _vm.$t('placeholder.plsSel'),
            trigger: 'change',
          } ]}},[_c('el-checkbox-group',{model:{value:(_vm.shelvesForm.checkValue),callback:function ($$v) {_vm.$set(_vm.shelvesForm, "checkValue", $$v)},expression:"shelvesForm.checkValue"}},_vm._l((_vm.checkListArr),function(item){return _c('el-checkbox',{key:item.value,attrs:{"label":item.value,"disabled":item.disabled}},[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? item.label : item.labelEn)+" ")])}),1)],1),(_vm.fromType !== 1)?_c('el-form-item',{attrs:{"label":_vm.$t('productCenter.reasonForDelisting') + '：',"prop":"reasonForDelisting","label-width":"100px","rules":[
          {
            required: true,
            message: _vm.$t('placeholder.plsInput'),
            trigger: 'blur',
          } ]}},[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":_vm.$t('placeholder.plsInput'),"maxlength":500},model:{value:(_vm.shelvesForm.reasonForDelisting),callback:function ($$v) {_vm.$set(_vm.shelvesForm, "reasonForDelisting", $$v)},expression:"shelvesForm.reasonForDelisting"}})],1):_vm._e()],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancelFn}},[_vm._v(_vm._s(_vm.$t('other.cancel')))]),_c('el-button',{attrs:{"type":"primary","loading":_vm.confirmlloading},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t('other.confirm'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }