<!--
  功能:结算模板配置列表
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月23日 17:18:07
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div>
    <el-table :data="tableData" border stripe :height="tableHeight" style="width: 100%; font-size: 12px;overflow-x:auto">
      <el-table-column
        align="center"
        type="index"
        width="80"
        label="序号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="templateNumber"
        show-overflow-tooltip
        label="模板编号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="name"
        show-overflow-tooltip
        label="模板名称"
      ></el-table-column>
      <el-table-column align="center" prop="type" label="计算类型" width="80">
        <template slot-scope="scope">
          {{ scope.row.type == 2 ? '阶梯' : '固定' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="fixedRatio" label="提成点(%)">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">{{ scope.row.fixedRatio }}</span>
          <span class="page-link" v-else @click="showLadderDetail(scope.row)">
            预览
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="mode" label="统计方式">
        <template slot-scope="scope">
          {{ scope.row.mode == 1 ? '自然季' : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="" label="月份区间" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.mode == 1 ? '1-3,4-6,7-9,10-12' : '-' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="creator"
        label="创建人"
        show-overflow-tooltip
      >
           <template slot-scope="scope">
          {{ `${scope.row.creator}(${scope.row.creatorEn})`}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="status" label="状态" width="80">
        <template slot-scope="scope">
          {{ scope.row.status == 1 ? '启用' : '冻结' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="doEdit(scope.row)" size="medium">编辑</el-button>
          <el-button type="text" @click="toggleFreeze(scope.row)" size="medium">
            {{ scope.row.status == 1 ? '冻结' : '启用' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page-custom">
      <el-pagination
        background
        :current-page="pageNo"
        layout="total, sizes,  prev, pager, next, jumper"
        :page-size="pageLe"
        :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
        :total="total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SettlementTplConfigInteractorTable',
    props: {
      tableData: {
        //列表数据
        type: Array,
        default: () => [],
      },
      total: {
        type: Number,
        default: 0,
      },
    },
    components: {},
    // 组件状态值
    data() {
      return {
        pageLe: 10, //每页数量
        pageNo: 1, // 当前页
        tableHeight:300, //表格高度 
      }
    },
    
    created() {
      this.getTableHeight()
      this.onResize()
    },
    methods: {
      // 获取表格高度
      getTableHeight() {
        const W_H = document.body.getBoundingClientRect().height
        this.tableHeight = W_H -54 -180
      },
      // resize监听
      onResize() {
        window.addEventListener('resize', this.getTableHeight, false)
      },
      pageChange(val) {
        //切换分页
        this.pageNo = val
        if (typeof this.getList === 'function') {
          this.getList()
        }
      },
      sizeChange(val) {
        // 分页数量变化
        this.pageLe = val
        if (typeof this.getList === 'function') {
          this.getList()
        }
      },


      // 重置分页
      resetPage() {
        this.pageNo = 1
        this.pageLe = 10
      },


      // 获取列表
      getList() {
        const { pageLe, pageNo } = this
        this.$emit('get-list', {
          pageLe,
          pageNo,
        })
      },

      //弹窗明细
      showLadderDetail(item) {
        this.$emit('ladder-detail', item)
      },

      // 表格行启用或者冻结
      toggleFreeze(item) {
        const { status } = item
        if (status === 1) {
          //启用状态下冻结
          this.$confirm('冻结后，新单据则无法调用此模板！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.$emit('template-freeze', {
                ...item,
                status: 0, //冻结
              })
            })
            .catch(() => {})
        } else {
          this.$emit('template-freeze', {
            ...item,
            status: 1, // 启用
          })
        }
      },

      // 编辑
      doEdit(item) {
        this.$emit('tpl-edit', item)
      },
    },

    
  }
</script>

<style scoped lang="scss">
  .page-custom {
    height: 58px;
  }
  ::v-deep {
    .el-pagination {
      margin: 0;
      padding-top: 18.5px;
      display: flex;
      justify-content: center;
    }
    .base-table-header {
      // 不可删除，会导致样式错乱
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .page-link {
    text-decoration: none;
  }
</style>
