<template>
  <el-dialog :visible.sync="params.show" title="添加产品" width="880px" append-to-body @close="onClose" @opened="onOpened"
    modal-append-to-body>
    <!-- 查询表单 -->
    <el-tabs></el-tabs>
    <div>
      <!-- 表单 -->
      <el-form ref="form">
        <el-form-item>
          <el-input placeholder="搜索产品名称/SKU ID/规格" style="width: 220px" v-model="param"
            @keyup.enter.native="onOpened"></el-input>
          <el-button @click="onOpened" class="ml15">搜索</el-button>
          <el-button @click="onReset" class="ml15">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="table-wrapper">
        <el-table border ref="multipleTable" :data="tableData" max-height="500px"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column align="center" label="SKU ID" prop="skuId"></el-table-column>
          <el-table-column align="center" label="产品名称" prop="nameCn"></el-table-column>
          <el-table-column align="center" label="规格" prop="productSpec">
            <template slot-scope="scope">
              {{ scope.row.productSpec ? scope.row.productSpec : '--' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination v-if="page.total > 0" :limit.sync="page.pageLe" :page.sync="page.pageNo" :total="page.total"
        @pagination="onPagination"></Pagination>
      <div class="footer-wrapper mt30 t_a_c">
        <el-button type="primary" class="ml30" @click="sureAction">
          确 定
        </el-button>
        <el-button @click="cancelAction">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import { InboundSheet } from '@/core'

  export default {
    name: 'product',
    props: {
      params: {
        type: Object,
        default: () => { },
      },
      selectedPro: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        productions: [], //产品列表
        visible: true,

        param: '',
        page: {
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        tableData: [],
        multipleSelection: [],
      }
    },

    components: {
      Pagination,
    },
    methods: {
      onPagination({ page }) {
        this.page.pageNo = page
        this.onSearch().then(() => {
          let rows = this.tableData.filter((item) =>
            this.selectedPro.find((pro) => pro.skuId === item.skuId)
          )
          this.toggleSelection(rows)
        })
      },
      onOpened() {
        this.page.pageNo = 1
        this.onSearch().then(() => {
          let rows = this.tableData.filter((item) =>
            this.selectedPro.find((pro) => pro.skuId === item.skuId)
          )
          this.toggleSelection(rows)
        })
      },
      toggleSelection(rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },

      onSearch() {
        const params = {
          param: this.param,
          ...this.page,
        }
        // 查询设置选中
        return new Promise((resolve, reject) => {
          InboundSheet.listProductForStock(params).then((res) => {
            if (res?.code === '000000' && res.data?.data) {
              this.tableData = res.data.data.map((pro) => {
                pro.usable = 1
                return pro
              })

              this.page.total = res.data.total
              resolve()
            }

          })
        })
      },
      onReset() {
        this.page.pageNo = 1
        this.param = ''
        this.onSearch()
      },
      cancelAction() {
        this.$emit('close-pro')
      },
      sureAction() {
        if (!this.multipleSelection || this.multipleSelection.length == 0) {
          return this.$message.warning('请选择产品')
        }
        this.$emit('select-pro', this.multipleSelection)
        this.$emit('close-pro')
      },
      onClose() {
        this.$refs.multipleTable.clearSelection()
        this.$emit('close-pro')
      },
    },

    created() {
      this.onSearch()
    },
  }
</script>

<style scoped lang="scss">
  .footer {
    .el-button+.el-button {
      margin-left: 30px;
    }
  }

  .ml15 {
    margin-left: 15px;
  }
</style>
