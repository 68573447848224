<template>
  <div class="mail-send">
    <span v-if="status === 0">
      未发
      <el-tooltip placement="top" effect="dark" v-if="hasTip(prop, info)">
        <div slot="content">
          <div class="pre-wrap" v-html="handleMailSend(prop, info)"></div>
        </div>
        <i class="el-icon-warning-outline"></i>
      </el-tooltip>
    </span>
    <span v-else-if="status === 4" class="warning">
      Soft Bounce
      <el-tooltip placement="top" effect="dark" v-if="hasTip(prop, info)">
        <div slot="content">
          <div class="pre-wrap" v-html="handleMailSend(prop, info)"></div>
        </div>
        <i class="el-icon-warning"></i>
      </el-tooltip>
    </span>
    <span v-else-if="status === 5" class="danger">
      Hard Bounce
      <el-tooltip placement="top" effect="dark" v-if="hasTip(prop, info)">
        <div slot="content">
          <div class="pre-wrap" v-html="handleMailSend(prop, info)"></div>
        </div>
        <i class="el-icon-warning"></i>
      </el-tooltip>
    </span>
    <span v-else>
      {{ status | mailSendFilter }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    prop: {
      type: String,
      default: '',
    },
    status: {
      type: [Number, String],
      default: '',
    },
    info: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    // 发货/收货邮件类型转化
    mailSendFilter(n) {
      const dicts = {
        0: '未发',
        1: '手动',
        2: '自动',
        3: '发送失败',
      }
      return dicts[n] || '--'
    },
  },
  methods: {
    /**
     * 处理邮件提示信息
     * returnType [1,2]代表发货邮件，[3,4]代表收货邮件
     */
    handleMailSend(prop, info) {
      if (!info) return false
      const condition = prop === 'mailSend' ? [1, 2] : [3, 4]
      const _arr = info.filter((item) => condition.includes(item.returnType))

      if (_arr?.length) {
        if (this.status === 0) {
          return `<p>${_arr[0].returnRemark}</p>`
        } else {
          let _text = `
            <p>退回时间：${_arr[0].returnTime}</p>
            <p>退回原因：${_arr[0].returnRemark}</p>
            <p>诊断信息：${_arr[0].returnDiagnosis}</p>
          `
          return _text
        }
      } else {
        return null
      }
    },

    hasTip(prop, info) {
      if (!info) return false
      const condition = prop === 'mailSend' ? [1, 2] : [3, 4]
      const _arr = info.filter((item) => condition.includes(item.returnType))

      if (_arr.length) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped>
.mail-send {
  margin-left: -10px;
}
.warning {
  color: #ffba00;
}
.danger {
  color: #ff4d4f;
}
i {
  cursor: pointer;
  font-size: 16px;
}
</style>