<template>
  <div class="weclome-wrapper">
    <div class="name">
      <span class="fs-18 welcome">你好, {{useInfo && useInfo.userName }}, 欢迎回来</span>
      <span class="time">数据更新时间: {{ updateTime | timeFilter }}</span>
    </div>
    <div class="index-setting">
      <el-image :src="require('@/assets/index_setting.png')" @click="showDialog = true"></el-image>
    </div>

    <!-- 设置弹窗 -->
    <el-dialog title="显示设置" :visible.sync="showDialog" width="800px" @close="close" :close-on-click-modal="false">
      <div class="dialog-content">
        <el-tabs tab-position="left">
          <el-tab-pane v-for="c in dialogConfig" :key="c.showType" :label="c.showName">
            <template v-if="c.voList && c.voList.length > 0">
              <el-row :gutter="10">
                <el-col :span="6" v-for="item in c.voList" :key="item.configId">
                  <div class="item">
                    <el-checkbox v-model="item.divStatus" true-label="1" false-label="0">
                      {{ item.showName }}
                    </el-checkbox>
                  </div>
                </el-col>
              </el-row>

              <div class="tip" v-if="c.showType === '1'">
                Tips:最多不能超过6条数据
              </div>
            </template>
            <div v-else style="height: 100px; padding-top: 60px; text-align: center">
              暂无数据
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <template #footer>
        <div style="text-align: center">
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="close">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { HomeInteractor } from '@/core'
  import * as _ from 'dayjs'
  export default {
    name: 'WelcomeSetting',
    data() {
      return {
        dataUpdateTime: '',
        showDialog: false,
        dialogConfig: [],
        errorTip: false,
        updateTime: Date.now(),
      }
    },
    computed: {
      ...mapGetters({
        config: 'kanpan/homepageConfig',
        useInfo: 'user/userInfo',
      }),
    },
    methods: {
      close() {
        if (this.isChange() && !this.beforeCloseCheck) {
          this.$confirm('数据变化，是否保存', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              if (this.save()) {
                this.$message({
                  type: 'success',
                  message: '保存成功!',
                })
              }
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消保存',
              })
              // 解决二次确认
              this.beforeCloseCheck = true
              this.showDialog = false
            })
        } else {
          this.showDialog = false
        }
      },
      isChange() {
        let isChange = false
        let { config, dialogConfig } = this
        for (let i = 0; i < dialogConfig.length; i++) {
          for (let j = 0; j < dialogConfig[i].voList.length; j++) {
            if (
              dialogConfig[i].voList[j].divStatus !==
              config[i].voList[j].divStatus
            ) {
              isChange = true
              break
            }
          }
        }
        return isChange
      },
      /**
       * 获取勾选和未勾选ids
       */
      getIds() {
        let chooseIds = [],
          unChooseIds = []
        this.dialogConfig.forEach((item) => {
          item.voList.forEach((subItem) => {
            if (subItem.divStatus === '1') {
              chooseIds.push(subItem.configId)
            } else {
              unChooseIds.push(subItem.configId)
            }
          })
        })
        return {
          chooseIds,
          unChooseIds,
        }
      },
      save() {
        const checkedCount = this.dialogConfig
          .filter((i) => i.showType === '1')[0]
          .voList.filter((item) => item.divStatus === '1').length
        if (checkedCount > 6) {
          this.$message.warning('超过6条')
          return false
        }
        this.$store.dispatch('kanpan/setHomepageConfig', this.dialogConfig)
        HomeInteractor.updateChooseStatus(this.getIds()).then((res) => {
          if (res.code === '000000') {
            this.showDialog = false
          }
        })
        return true
      },

      _initData() {
        this.dialogConfig = JSON.parse(JSON.stringify(this.config))
      },
    },
    filters: {
      timeFilter(val) {
        return _(val).format('YYYY.MM.DD HH:mm:ss')
      },
    },
    created() {
      this.beforeCloseCheck = false
    },
    watch: {
      showDialog(val) {
        if (val === false) {
          this.close()
        } else {
          this._initData()
          this.beforeCloseCheck = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .weclome-wrapper {
    display: flex;
    min-width: 1160px;
    justify-content: space-between;
    height: 25px;
    margin-bottom: 15px;

    .index-setting {
      width: 25px;
      height: 25px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .el-image {
        height: 15px;
        width: 15px;
      }
    }

    .name {
      .welcome {
        height: 20px;
        font-size: 22px;
        font-weight: 600;
        color: #000000;
        line-height: 22px;
        margin-right: 10px;
      }

      .time {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
    }

    .fs-18 {
      font-size: 18px;
      margin-right: 10px;
    }

    div.item {
      padding: 15px;
    }

    .tip {
      font-size: 12px;
      font-weight: lighter;
      color: #8c95a9;
      padding-left: 15px;
    }

    .tip-error {
      color: red;
    }
  }
</style>
