<template>
  <el-tooltip :effect="effect" :content="content" placement="top">
    <i class="iconfont icon-wenhao" :style="{ color }"></i>
  </el-tooltip>
</template>

<script>
  export default {
    name: 'BaseQuestionTip',
    props: {
      content: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: '#909399',
      },
      effect: {
        type: String,
        default: 'light',
      },
    },
  }
</script>

<style scoped lang="scss">
  i {
    cursor: pointer;
  }
</style>
