// 临时登录接口
export function login() {
  return request({
    url: 'product/login2',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 退出接口
export function logout() {
  return request({
    url: 'logout.do',
    method: 'get',
  })
}
