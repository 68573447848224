<template>
  <div :class="['p20', 'add-box', detailId ? 'audit-box' : '']">
    <div class="base-wrapper">
      <el-page-header @back="
        () => {
          $router.go(-1)
        }
      " :content="content" class="page-header-box" title="返回" v-if="!detailId"></el-page-header>
      <h4>基本信息</h4>

      <StockCheckEditBaseInfo class="mt10" v-if="detailId" :detail="addForm"></StockCheckEditBaseInfo>
      <el-form v-else :model="addForm" :rules="rules" ref="addForm" class="base-form">
        <el-row>
          <el-col :span="6">
            <el-form-item label="盘点仓库" prop="warehouseName " label-width="100px">
              <el-select v-model="addForm.warehouseName" placeholder="请选择" disabled>
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="盘点人" label-width="100px">
              <el-input v-model="addForm.takingUser" placeholder="请选择" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建人" label-width="100px">
              <el-input v-model="addForm.creator" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="6">
            <el-form-item label="盘点方式" prop="takingRange" label-width="100px">
              <el-select v-model="addForm.takingRange" placeholder="请选择" disabled>
                <el-option v-for="item in takingRangeList" :key="item.code" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="库存分类" prop="stockUsableType" v-if="addForm.takingRange === 1" label-width="100px">
              <el-select v-model="addForm.stockUsableType" placeholder="请选择" class="w100" disabled>
                <el-option v-for="item in stockUsableTypeList" :key="item.code" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="18">
            <el-form-item label="备注" label-width="100px">
              <el-input type="textarea" v-model="addForm.remark" :rows="2" maxlength="500" show-word-limit
                resize="none"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-form :model="addForm" ref="addForm">
      <div class="mt10">
        <div class="pro-title">
          <h4 class="mt10 mr20 inline-block">盘点产品</h4>
          <el-button @click="selPro" class="pro-button mr20" v-if="!detailId">
            导入盘点结果
          </el-button>
          <span>总盘点数量: </span>

          <span class="mr20"> {{takingTotalNum}}</span>
          <span>总差异数量:</span> <span :style="
            takingTotalNum > 0
              ? 'color:green'
              : takingTotalNum < 0
              ? 'color:red'
              : ''
          ">{{takingTotalDiff}}</span>
        </div>

        <!-- 虚拟滚动表格 -->
        <vxe-table :data="addForm.stockTakingProducts" :height="tableHeight" border align="center"
          :cell-style="cellStyle" :cell-class-name="cellClassName"
          :edit-config="{trigger: 'click', mode: 'row', showStatus: true}" ref="xTable">
          <vxe-column type="seq" width="60"></vxe-column>
          <vxe-table-column width="100" title="产品图片" show-overflow>
            <template v-slot="{ row }">
              <erp-image :imgs="row.productImage"></erp-image>
            </template>
          </vxe-table-column>
          <vxe-table-column title="产品编码" field="skuId" show-overflow></vxe-table-column>
          <vxe-table-column title="产品名称" field="productName" show-overflow></vxe-table-column>
          <vxe-table-column title="规格" field="productSpec" show-overflow></vxe-table-column>
          <vxe-table-column title="产品类别" field="productType" show-overflow></vxe-table-column>
          <vxe-table-column title="是否可用库存" show-overflow>
            <template v-slot="{ row }">
              {{ row.usable == 1 ? '是' : '否' }}
            </template>
          </vxe-table-column>
          <vxe-table-column title="库位号" field="fullLocationNumber" show-overflow></vxe-table-column>
          <vxe-table-column title="建单库位数" field="num" show-overflow></vxe-table-column>
          <vxe-table-column title="可用量" field="enableNum" show-overflow>
            <template v-slot:header>
              <div>
                <span>可用库位数</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    包含该仓库所有销售出库待出库数量、锁定量及该库位可用库存。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column width="140" title="不可用量" field="disableNum" show-overflow>
            <template v-slot:header>
              <div>
                <span>不可用库位数</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    该库位不可用库存。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column title="盘点数量" field="takingNum" show-overflow>
            <template v-slot="{row,rowIndex}">
              <el-form-item v-if="!detailId">
                <el-input v-model="row.takingNum" placeholder="请输入" @change="takingNumInput(rowIndex, row)"
                  :key="rowIndex"></el-input>
              </el-form-item>

              <span v-else>{{ row.takingNum || '-' }}</span>
            </template>

          </vxe-table-column>
          <vxe-table-column title="差异数量" field="takingDiff" show-overflow>
            <template v-slot="{ row }">
              <span :style="
              row.takingDiff > 0
                ? 'color:green'
                : row.takingDiff < 0
                ? 'color:red'
                : ''
            ">
                {{ row.takingDiff }}
              </span>
            </template>
          </vxe-table-column>
          <vxe-table-column title="备注" field="remark" width="200px">
            <template v-slot="{row,rowIndex}">
              <el-form-item v-if="!detailId" :prop="'stockTakingProducts.' + rowIndex + '.remark'" :rules="[{
              required: !!row.takingDiff,
              message:'请填写备注',
              trigger:'change'
            }]">
                <el-input placeholder="请输入" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" maxlength="500"
                  show-word-limit v-model="row.remark" :key="rowIndex"></el-input>
              </el-form-item>
              <span v-else>{{ row.remark || '-' }}</span>
            </template></vxe-table-column>
        </vxe-table>

      </div>
      <div class="remark mt20 remark-wrapper">
        <p class="mb5" v-if="!detailId">盈亏说明</p>
        <el-form-item prop="takingResultDesc" v-if="!detailId">
          <el-input type="textarea" v-model="addForm.takingResultDesc" :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="500" show-word-limit></el-input>
        </el-form-item>

        <p v-else style="display: flex">
          <em style="width: 85px; display: block; white-space: nowrap">
            盈亏说明：
          </em>
          {{ addForm.takingResultDesc || '--' }}
        </p>
      </div>
    </el-form>
    <div class="oper-sticky">
      <ErpLoadingButton type="primary" class="mr20" @click="submit">确认</ErpLoadingButton>
      <el-button class="ml10" @click="cancel">取消</el-button>
    </div>
    <CheckResultImport ref="CheckResultImport" :stockTakingId="stockTakingId" @importSuccess="importSuccess" />
    <!-- 审核选择 -->
    <AuditChoose ref="AuditChoose" @auditData="saveAuditClick2" @auditData1="saveAuditClick2" />



  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { deepCopy, getRemainingHeight } from 'kits'
  import CheckResultImport from './components/checkResultImport.vue'
  import AuditChoose from '@/components/AuditChoose'
  import { mixin } from './help/mixins'
  import { StoreCheck } from '@/core'
  import StockCheckEditBaseInfo from './components/stock-check-edit-base-info'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'





  export default {
    components: { CheckResultImport, AuditChoose, StockCheckEditBaseInfo },
    mixins: [mixin],
    props: {
      detailId: {
        //业务id
        type: String,
        default: '',
      },
    },
    data() {
      return {
        addForm: {},
        rules: {},
        tableData: [],
        stockTakingId: '',
        tableKey: Math.random(),
        spanArr: [], //合并参数
        pos: 0, //合并参数
        takingRangeList: Object.freeze([{
          code: 1,
          name: '全部盘点'
        }, {
          code: 0,
          name: '部分盘点'
        }]),
        stockUsableTypeList: Object.freeze([{
          code: 1,
          name: '可用库存'
        }, {
          code: 0,
          name: '不可用库存'
        }, {
          code: 2,
          name: '全部库存'
        }]),
        tableHeight: 300
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      content() {
        return this.$route.query.type == 'result' ? '盘点结果' : '盘点编辑'
      },
      resultDescescStatus() {
        let resultDescescStatus = this.addForm.stockTakingProducts?.every(
          (it) => {
            return it.takingDiff == 0
          }
        )
        return !resultDescescStatus && !this.addForm.takingResultDesc

      },
      // 总盘点数量
      takingTotalNum() {
        let products = this.addForm.stockTakingProducts

        if (!products) {
          return '-'
        }
        let takingNums = products.filter(i => i.takingNum).map(i => i.takingNum * 1)

        return takingNums.reduce((prev, next) => prev += next, 0)
      },

      // 总盘点差异
      takingTotalDiff() {
        let products = this.addForm.stockTakingProducts
        if (!products) {
          return '-'
        }

        let takingDiffs = products.filter(i => i.takingDiff).map(i => i.takingDiff * 1)

        return takingDiffs.reduce((prev, next) => prev += next, 0)
      },
    },
    created() {
      this.amendRemarkCache = {}  //缓存备注和价格
      this.priceCache = {}//缓存备注和价格
      this.addForm.creater = this.userInfo.userName
      this.stockTakingId = this.$route.query.stockTakingId || this.detailId
      // if (this.$route.query.type == 'result' || this.detailId) {
      if (this.$route.query.type == 'result') {
        this.initResult()
      } else {
        if (this.detailId) {  // 审核
          this.initEdit()
        } else {
          this.initModify()
        }

      }
    },

    mounted() {
      setTimeout(() => {
        this.getTableHeight()
      }, 200)
    },
    methods: {
      cellClassName({ row, rowIndex, column, columnIndex }) {
        if (column.property === 'remark') {
          return 'col-remark'
        }

      },
      cellStyle({ row, rowIndex, column, columnIndex }) {
        if (column.property === 'remark') {
          return {
            'overflow': '',
            height: '100px'
          }

        }
        return {

          height: '100px'
        }
      },

      // 获取表格高度
      getTableHeight() {
        try {
          let heigth = getRemainingHeight(['base-wrapper', 'pro-title', 'remark-wrapper', 'oper-sticky'], '', 120)

          this.tableHeight = heigth
        } catch (err) {

        }
      },


      formatTableData(list, field = 'amends') {
        let self = this
        list = deepCopy(list)
        let tableData = []
        for (let i = 0; i < list.length; i++) {

          let item = list[i]
          let key = `${list[i].stockTakingProductId}${list[i].stockLotNo || ''}`
          if (item[field] && item[field].length !== 0) {
            item[field].forEach((lotItem) => {
              let lotNumber = lotItem.lotNumber || lotItem.num
              let price = this.priceCache[key] || lotItem.price
              let totalPrice = lotNumber * price
              if (isNaN(totalPrice)) {
                totalPrice = 0
              }
              tableData.push({
                ...lotItem,  //放在前面避免num属性覆盖
                amendNum: lotItem.num,
                ...item,
                num: item.num,
                stockTakingId: item.stockTakingId,
                stockTakingProductId: item.stockTakingProductId,
                lotNumber,
                totalPrice,
                amendRemark: this.amendRemarkCache[key] || lotItem.remark || item.remark,  //重新执行表格合并时出库入库备注处理
                lotNo: lotItem.lotNo || lotItem.stockLotNo,  // 导入时选中批次回显
                price: this.priceCache[key] || lotItem.price
              })
            })
          } else {
            tableData.push({
              ...item,
              amendRemark: this.amendRemarkCache[key] || item.remark,
              price: this.priceCache[key] || item.price
            })
          }
        }
        return tableData
      },





      // 初始化信息
      async initResult() {
        let res = await StoreCheck.toTaking(this.stockTakingId)
        if (res?.code === '000000') {
          // 记住初始产品数据initTakingProductVOList
          res.data.stockTakingProducts = this.formatTableData(res.data.stockTakingProducts)
          this.addForm = res.data
        }

      },
      async initEdit() {
        let res = await StoreCheck.getDetailById(this.stockTakingId)
        this.addForm = res?.data
        this.addForm.stockTakingProducts = this.formatTableData(res?.data?.takingProductVOList)
        delete this.addForm.takingProductVOList
      },

      async initModify() {
        let res = await StoreCheck.toModifyApi(this.stockTakingId)
        this.addForm = res.data
        this.initTakingProductVOList = deepCopy(res?.data?.stockTakingProducts || [])
        this.addForm.stockTakingProducts = this.formatTableData(res.data.stockTakingProducts)
        delete this.addForm.takingProductVOList
      },


      // 选择产品
      selPro() {
        this.$refs.CheckResultImport.show()
      },
      // 选择人员
      choosePeople() { },
      // 校验所有数据
      checkAllData() {

        let list = this.addForm.stockTakingProducts
        let checkResult = true
        for (let i = 0; i < list.length; i++) {
          let pro = list[i]
          let { takingNum, takingDiff, productName, skuId, remark } = pro

          if (takingDiff && takingNum !== '0' && takingNum !== 0) {
            if (!remark) {
              checkResult = false
              this.$alert('提示', {
                confirmButtonText: '确定',
                message: `${i + 1}行备注必填`,
                dangerouslyUseHTMLString: true,
                type: 'warning',
              });
              break
            }


          }

        }
        return checkResult
      },
      // 保存

      submit(e, done) {

        this.$refs['addForm'].validate((valid) => {
          if (valid) {

            if (this.checkAllData()) {
              let data = deepCopy(this.addForm)
              let tableData = this.getSaveTableData(data.stockTakingProducts)
              this.submitResult(done)
            }
          } else {
            this.checkAllData()
            done()
          }
        })


      },
      submitResult(done) {
        // 显示审核
        let auditParams = {
          auditType: 21,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(auditParams).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(auditParams)
          }
        }).finally(() => {
          done()
        })
      },

      // 取消
      cancel() {
        this.$router.push('/stock-center/stock-check/index')
      },
      // 审核
      async saveAuditClick2(auditData) {
        let data = deepCopy(this.addForm)
        data.stockTakingProducts = this.getSaveTableData(data.stockTakingProducts)
        let res = await StoreCheck.takingCommit(auditData, data)
        if (res?.code === '000000') {
          this.$message.success('操作成功')
          this.$router.push('/stock-center/stock-check/index')
        } else {
          // this.$baseMessage(res?.desc, 'error', true, 'erp-hey-message-error');
          this.$alert('提示', {
            confirmButtonText: '确定',
            message: `<div style="max-height:200px; overflow:auto">${res?.desc}</div>`,
            dangerouslyUseHTMLString: true,
            type: 'error',
            'customClass': 'error-alert'
          });
        }
      },

      changeAmendRemark(row) {
        let { stockTakingProductId, stockLotNo } = row
        this.amendRemarkCache[stockTakingProductId + (stockLotNo || '')] = row.amendRemark
      },



      // 盘点数量计算差异数量
      takingNumInput(index, row) {

        if (!row.takingNum && row.takingNum !== '0' && row.takingNum !== 0) {
          this.$set(row, 'takingDiff', null)
        } else {
          let takingDiff = Number(row.takingNum) - Number(row[row.usable ? 'enableNum' : 'disableNum'])
          this.$set(row, 'takingDiff', takingDiff)
        }



      },



      // 获取确认时产品数据
      getSaveTableData() {
        let tableData = this.addForm.stockTakingProducts?.map(pro => {
          // 盘点数量修改为非必填
          if (!pro.takingNum && pro.takingNum !== '0' && pro.takingNum !== 0) {
            pro.takingNum = null
            pro.takingDiff = null
          }
          return pro
        })
        return tableData

      },




      // 导入成功。替换产品列表
      importSuccess(list) {
        let stockTakingProducts = deepCopy(list)
        setTimeout(() => {
          this.addForm.stockTakingProducts = list
          this.tableKey = Math.random()
        }, 300)
      },

    },
  }
</script>
<style lang="scss" scoped>
  .oper-sticky {
    width: calc(100% - 105px);
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #ccc;
    height: 80px;
    align-items: center;
    background-color: #fff;
  }

  .pro-button {
    border-color: rgb(40, 130, 255);
    color: rgb(40, 130, 255);
    margin: 15px 0;
  }

  .add-box {
    overflow: hidden;
    overflow-y: hidden;
    height: calc(100vh - 145px);
    margin-bottom: 100px !important;

    &.audit-box {
      height: auto !important;
    }
  }

  em {
    color: #000;
  }

  .el-form-item {
    margin-bottom: 0px !important;
  }

  .lot-wrapper {
    display: flex;
    align-items: center;

    .el-input {
      flex: 1;
    }

    .el-icon-circle-plus-outline {
      margin-left: 5px;
      cursor: pointer;
      font-size: 20px;
      color: rgb(40, 130, 255);
    }

  }

  .inline-block {
    display: inline-block;
  }

  .mr20 {
    margin-right: 20px;
  }

  ::v-deep {
    .el-form-item__error {
      display: none;
    }
  }

  ::v-deep {
    .col-remark {
      .vxe-cell {
        overflow: visible !important;
      }

    }
  }
</style>

<style>
  .error-alert {
    min-width: 610px;
  }
</style>
