<template>
  <el-row :gutter="20" class="pro-container pro-base-msg">
    <el-col :span="2">
      <!-- 规格列表 -->
      <h6 class="left-h6">{{ $t('productDetail.SpecificationList') }}</h6>
    </el-col>
    <!-- 单规格 -->

    <el-col :span="22" class="right-content">
      <el-row>
        <!-- 规格配置 -->
        <el-button type="primary" @click="specSetClick">
          {{ $t('productDetail.SpecificationSetting') }}
        </el-button>
      </el-row>

      <!-- 单规格false -->
      <el-row :span="21" v-show="!isSingleBol">
        <el-table
          :data="singleSpecData"
          ref="dragTable"
          stripe
          border
          class="mt30 w100 table-data"
        >
          <!-- 产品编码 -->
          <el-table-column
            v-if="skuShow"
            align="center"
            :label="$t('productDetail.ProductCode')"
            prop="sku"
            width="120"
          />
          <!-- 包装方式 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.PackingMethod')"
            prop="packingMethodEn"
          />
          <!-- 产品重量(KGS) -->
          <el-table-column
            align="center"
            :label="$t('productDetail.ProductWeight')"
            prop="productWeightKgs"
          />
          <!-- 产品尺寸 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.ProductSize')"
            width="180"
          >
            <template #default="{ row }">
              <el-row v-if="row.sizeUnit === 'FT' || row.sizeUnit === 'CM'">
                <span v-if="row.productLengthIn">
                  {{ row.productLengthIn }}''&nbsp;
                </span>
                <span v-if="row.productWidthIn">
                  <span v-if="row.productLengthIn">x&nbsp;</span>
                  {{ row.productWidthIn }}''&nbsp;
                </span>
                <span v-if="row.productHeightIn">
                  <span v-if="row.productWidthIn || row.productLengthIn">
                    x&nbsp;
                  </span>
                  {{ row.productHeightIn }}''
                </span>
              </el-row>
              <el-row v-if="row.sizeUnit === 'IN' || !row.sizeUnit">
                <span v-if="row.productLengthCm">
                  {{ row.productLengthCm }}''&nbsp;
                </span>
                <span v-if="row.productWidthCm">
                  <span v-if="row.productLengthCm">x&nbsp;</span>
                  {{ row.productWidthCm }}''&nbsp;
                </span>
                <span v-if="row.productHeightCm">
                  <span v-if="row.productWidthCm || row.productLengthCm">
                    x&nbsp;
                  </span>
                  {{ row.productHeightCm }}''
                </span>
              </el-row>
            </template>
          </el-table-column>
          <!-- 印刷区域尺寸 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.PrintingAreaSize')"
          >
            <template #default="{ row }">
              <el-row v-if="row.printUnit === 'FT' || row.printUnit === 'CM'">
                <span v-if="row.printLengthCovert">
                  {{ row.printLengthCovert }}''&nbsp;
                </span>
                <span v-if="row.printWidthCovert">
                  <span v-if="row.printLengthCovert">x&nbsp;</span>
                  {{ row.printWidthCovert }}''
                </span>
              </el-row>
              <el-row v-if="row.printUnit === 'IN' || !row.printUnit">
                <span v-if="row.printLength">
                  {{ row.printLength }}''&nbsp;
                </span>
                <span v-if="row.printWidth">
                  <span v-if="row.printLength">x&nbsp;</span>
                  {{ row.printWidth }}''
                </span>
              </el-row>
            </template>
          </el-table-column>
          <!-- 装箱数量 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.PackingQuantity')"
            prop="numberPerBox"
          />
          <!-- 毛重 (KSG)-->
          <el-table-column
            align="center"
            :label="$t('productDetail.GrossWeight')"
            prop="boxWeightKgs"
          />
          <!-- 装箱尺寸 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.PackingSize')"
          >
            <template #default="{ row }">
              <span v-if="row.boxLengthIn">
                {{ row.boxLengthIn }}''&nbsp;x&nbsp;
              </span>
              <span v-if="row.boxWidthIn">
                {{ row.boxWidthIn }}''&nbsp;x&nbsp;
              </span>
              <span v-if="row.boxHeightIn">{{ row.boxHeightIn }}''</span>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column align="center" :label="$t('productDetail.Operate')">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                plain
                @click="specEditClick(scope.row, scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 多规格true -->
      <el-row
        v-show="isSingleBol"
        style="max-height: 400px; overflow: hidden; overflow-y: auto"
      >
        <el-table
          :data="tableData"
          ref="dragTable"
          stripe
          border
          row-key="id"
          class="mt30 w100 table-data"
        >
          <!-- 产品编码 -->
          <el-table-column
            v-if="skuShow"
            align="center"
            :label="$t('productDetail.ProductCode')"
            prop="sku"
            width="120"
          />
          <el-table-column
            align="center"
            v-for="(item, index) of tableSpecsType"
            :key="index"
            :label="item"
            :prop="'specsValue' + (index + 1)"
          ></el-table-column>
          <!-- 产品重量 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.ProductWeight')"
            prop="productWeightKgs"
          />
          <!-- 产品尺寸 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.ProductSize')"
            width="180"
          >
            <template #default="{ row }">
              <el-row v-if="row.sizeUnit === 'FT' || row.sizeUnit === 'CM'">
                <span v-if="row.productLengthIn">
                  {{ row.productLengthIn }}''&nbsp;
                </span>
                <span v-if="row.productWidthIn">
                  <span v-if="row.productLengthIn">x&nbsp;</span>
                  {{ row.productWidthIn }}''&nbsp;
                </span>
                <span v-if="row.productHeightIn">
                  <span v-if="row.productWidthIn || row.productLengthIn">
                    x&nbsp;
                  </span>
                  {{ row.productHeightIn }}''
                </span>
              </el-row>
              <el-row v-if="row.sizeUnit === 'IN' || !row.sizeUnit">
                <span v-if="row.productLengthCm">
                  {{ row.productLengthCm }}''&nbsp;
                </span>
                <span v-if="row.productWidthCm">
                  <span v-if="row.productLengthCm">x&nbsp;</span>
                  {{ row.productWidthCm }}''&nbsp;
                </span>
                <span v-if="row.productHeightCm">
                  <span v-if="row.productWidthCm || row.productLengthCm">
                    x&nbsp;
                  </span>
                  {{ row.productHeightCm }}''
                </span>
              </el-row>
            </template>
          </el-table-column>
          <!-- 印刷区域尺寸 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.PrintingAreaSize')"
          >
            <template #default="{ row }">
              <el-row v-if="row.printUnit === 'FT' || row.printUnit === 'CM'">
                <span v-if="row.printLengthCovert">
                  {{ row.printLengthCovert }}''&nbsp;
                </span>
                <span v-if="row.printWidthCovert">
                  <span v-if="row.printLengthCovert">x&nbsp;</span>
                  {{ row.printWidthCovert }}''
                </span>
              </el-row>
              <el-row v-if="row.printUnit === 'IN' || !row.printUnit">
                <span v-if="row.printLength">
                  {{ row.printLength }}''&nbsp;
                </span>
                <span v-if="row.printWidth">
                  <span v-if="row.printLength">x&nbsp;</span>
                  {{ row.printWidth }}''
                </span>
              </el-row>
            </template>
          </el-table-column>
          <!-- 装箱数量 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.PackingQuantity')"
            prop="numberPerBox"
          />
          <!-- 装箱体积 -->
          <el-table-column
            align="center"
            :label="$t('other.packVolume')"
            prop="boxVolumeM"
          />
          <!-- 操作 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.Operate')"
            show-overflow-tooltip
            width="150"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                plain
                @click="specEditClick(scope.row, scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-col>
    <ProductSpecListModel ref="productSpecListModel" @spec-row="specRow" />
    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[imgUrl]"
    />
  </el-row>
</template>

<script>
  import Sortable from 'sortablejs'
  import ProductSpecListModel from './ProductSpecListModel'
  import ChooseImages from './chooseImages'
  import { deepCopy } from 'kits'
  export default {
    name: 'ProductSpecList',
    // props: {
    //   editData: {
    //     type: Object,
    //     required: false,
    //   },
    // },
    data() {
      return {
        tableSpecsType: [], //table列表里面的规格列字段
        tableData: [], //table列表
        spec: [],
        noSpec: false, //有无规格值
        modelSetVal: [],
        uploadQcVisible: false,
        tempObj: [],
        skuShow: false, //规格编码是否显示
        stopWatch: false,
        specArray1: [],
        specArray2: [],
        editData: [],
        isSingleBol: true, //单规格false和多规格true
        singleSpecData: [], //单规格数据
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        imgLimit: 1, //上传图片限制张数
        chooseImagesDialogVisible: false, // 选择规格图片弹窗
        currentDefaultRendingList: [], // 当前选中的规格图片
        currentDefaultRendingStatus: '1', // 当前选中的规格图片状态
        currentRendingIndex: 0,
      }
    },
    watch: {
      singleSpecData: {
        immediate: true,
        deep: true,
        handler(value) {
          this.$Bus.$emit('isSingleSpecDataLength', value)
        },
      },
      tableData: {
        immediate: true,
        deep: true,
        handler(value) {
          this.$Bus.$emit('isTableDataLength', value)
        },
      },
    },
    components: { ProductSpecListModel, ChooseImages },
    created() {
      let that = this
      // 产品分类id
      // this.$Bus.$on('categoryId', (id) => {
      //   if (id) {
      //     this.tableData = []
      //     this.tableSpecsType = []
      //     this.singleSpecData = []
      //     this.stopWatch = true
      //     this.$forceUpdate()
      //   }
      // })

      this.$Bus.$on('show-clear', () => {
        this.tableData = []
        this.tableSpecsType = []
        this.singleSpecData = []
        this.stopWatch = true
        this.$forceUpdate()
      })
      //开启单规格多规格
      this.$Bus.$on('specsChange', (bol) => {
        if (bol) {
          this.tableData = []
          this.tableSpecsType = []
        } else {
          this.tableData = []
          this.singleSpecData = []
        }
        this.isSingleBol = bol
      })

      // 规格值
      that.$Bus.$on('specSendBus', (val) => {
        that.spec = val

        if (that.spec.length === 1) {
          //一个规格
          if (that.spec[0].value.length === 0) {
            //规格下面的规格值
            that.noSpec = true //无规格值
          } else {
            that.noSpec = false //有规格值
          }
        } else if (that.spec.length === 2) {
          //两个规格
          if (
            this.spec[0].value.length === 0 ||
            this.spec[1].value.length === 0
          ) {
            this.noSpec = true //无规格值
          } else {
            this.noSpec = false //有规格值
          }
        }

        // 在table中添加规格列
        that.tableSpecsType = []
        that.spec.forEach((item) => {
          that.tableSpecsType.push(item.nameCn)
        })

        let newTable = []
        if (this.spec.length > 0) {
          //规格1无值，规格2有值
          if (this.spec[0].value.length == 0) {
            if (this.spec.length > 1 && this.spec[1].value.length != 0) {
              for (let j = 0; j < this.spec[1].value.length; j++) {
                newTable.push({
                  specsValue1: '',
                  specsValue2: this.spec[1].value[j],
                })
              }
            } else {
              //规格1，规格2都无值
              this.tableData = []
            }
          } else {
            //规格1有值
            for (let i = 0; i < this.spec[0].value.length; i++) {
              if (this.spec.length > 1) {
                if (this.spec[1].value.length == 0) {
                  //规格2无值
                  newTable.push({
                    specsValue1: this.spec[0].value[i],
                  })
                } else {
                  //规格2有值，循环规格2
                  for (let j = 0; j < this.spec[1].value.length; j++) {
                    let flag = false
                    //循环规格列表
                    for (let k = 0; k < this.tableData.length; k++) {
                      let obj = this.tableData[k]
                      if (
                        obj.specsValue1 == this.spec[0].value[i] &&
                        obj.specsValue2 == this.spec[1].value[j]
                      ) {
                        newTable.push(obj)
                        this.tableData.remove(obj)
                        flag = true
                        break
                      }
                    }
                    if (!flag) {
                      newTable.push({
                        specsValue1: this.spec[0].value[i],
                        specsValue2: this.spec[1].value[j],
                      })
                    }
                  }
                }
              } else {
                //只有一个规格 即规格1
                let flag = false
                for (let k = 0; k < this.tableData.length; k++) {
                  let obj = this.tableData[k]
                  if (obj.specsValue1 == this.spec[0].value[i]) {
                    newTable.push(obj)
                    this.tableData.remove(obj)
                    flag = true
                    break
                  }
                }
                if (!flag) {
                  newTable.push({
                    specsValue1: this.spec[0].value[i],
                  })
                }

                // newTable.push({
                //   specsValue1: this.spec[0].value[i],
                // })
              }
            }
          }
          this.tableData = newTable
        } else {
          this.tableData = []
        }
      })
    },
    mounted() {
      if (
        this.$route.query.str === 'edit' ||
        this.$route.query.str === 'clone'
      ) {
        this.skuShow = true
      } else {
        this.skuShow = false
      }
    },
    methods: {
      closeViewer() {
        this.showViewer = false
      },
      setSort() {
        const el = this.$refs.dragTable.$el.querySelectorAll(
          '.el-table__body-wrapper > table > tbody'
        )[0]
        this.sortable = Sortable.create(el, {
          ghostClass: 'sortable-ghost',
          setData: function (dataTransfer) {
            dataTransfer.setData('Text', '')
          },
          onEnd: (evt) => {
            const targetRow = this.tableData.splice(evt.oldIndex, 1)[0]
            this.tableData.splice(evt.newIndex, 0, targetRow)
            const tempIndex = this.tableData.splice(evt.oldIndex, 1)[0]
            this.tableData.splice(evt.newIndex, 0, tempIndex)
            this.$nextTick(() => {})
          },
        })
      },
      editShow(editData) {
        if (editData) {
          this.editData = editData
          // 是否多规格
          if (this.editData.specsType === '') {
            this.isSingleBol = false
          } else {
            this.isSingleBol = true
          }
          if (this.editData.productSpecsEditVOList && this.isSingleBol) {
            this.tableData = this.editData.productSpecsEditVOList
            //this.skuShow = true
          } else if (
            this.editData.productSpecsEditVOList &&
            !this.isSingleBol
          ) {
            this.singleSpecData = this.editData.productSpecsEditVOList
            //this.skuShow = true
          }

          //保存到本地数据productSpecsDTOList
          if (this.editData.productSpecsDTOList && this.isSingleBol) {
            this.tableData = this.editData.productSpecsDTOList
          } else if (this.editData.productSpecsDTOList && !this.isSingleBol) {
            this.singleSpecData = this.editData.productSpecsDTOList
          }

          //规格列表的规格字段
          this.tableSpecsType = []
          if (this.editData.specsType) {
            let specsType = this.editData.specsType.split(',')
            specsType.forEach((item) => {
              this.tableSpecsType.push(item)
            })
          }
        }
      },
      // 对象转成数组
      objectArray(obj) {
        let arr = []
        for (let i in obj) {
          arr.push(obj[i]) //属性
        }
        return arr
      },
      // specDelClick
      specDelClick(row, index) {},
      handleAvatarSuccess(res, file, fileList, row, col) {
        // 上传成功
        this.$message.success(this.$t('reqmsg.M2023'))
        this.$set(this.tableData.events[row.event_index], col, fileList)
      },

      //规格配置新增
      specSetClick() {
        //判断单规格false还是多规格true
        if (this.isSingleBol) {
          if (!this.tableData.length) {
            return this.$baseMessage(
              // '请先配置规格',
              this.$t('reqmsg.M2024'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['productSpecListModel'].productSpecListModel()
          }
        } else {
          //单规格
          this.$refs['productSpecListModel'].productSpecListModel()
        }
      },

      // 规格配置编辑
      specEditClick(row, index) {
        this.$refs['productSpecListModel'].productSpecListModel(row, index)
      },

      // 规格弹框返回数据（判断单规格false还是多规格true）
      specRow(form, index) {
        let tableDataBus = true //修改了哪些参数，判断是否触发bus
        if (
          this.isSingleBol &&
          this.tableData &&
          this.tableData.length &&
          index !== 'null' &&
          index >= 0 &&
          this.tableData[index]
        ) {
          if (
            form.productWeightKgs === this.tableData[index].productWeightKgs &&
            form.numberPerBox === this.tableData[index].numberPerBox &&
            form.boxVolumeM === this.tableData[index].boxVolumeM &&
            form.boxWeightKgs === this.tableData[index].boxWeightKgs
          ) {
            tableDataBus = false
          } else {
            tableDataBus = true
          }
        } else if (
          !this.isSingleBol &&
          this.singleSpecData &&
          this.singleSpecData.length &&
          index !== '' &&
          this.singleSpecData[index]
        ) {
          if (
            form.productWeightKgs ===
              this.singleSpecData[index].productWeightKgs &&
            form.numberPerBox === this.singleSpecData[index].numberPerBox &&
            form.boxVolumeM === this.singleSpecData[index].boxVolumeM &&
            form.boxWeightKgs === this.singleSpecData[index].boxWeightKgs
          ) {
            tableDataBus = false
          } else {
            tableDataBus = true
          }
        }

        if (this.isSingleBol) {
          //多规格
          this.modelSetVal = []
          let objForm = form
          if (objForm.specsValue1.length > 0 && !objForm.specsValue2) {
            const { specsValue1, ...reset } = objForm
            const newArr = []
            for (let i = 0; i < specsValue1.length; i++) {
              newArr.push({
                specsValue1: specsValue1[i],
                ...reset,
              })
            }
            this.modelSetVal = newArr
          } else if (
            objForm.specsValue1.length > 0 &&
            objForm.specsValue2.length > 0
          ) {
            const { specsValue1, specsValue2, ...reset } = objForm
            const newArr = []
            for (let i = 0; i < specsValue1.length; i++) {
              for (let j = 0; j < specsValue2.length; j++) {
                if (specsValue1[i] && specsValue2[j]) {
                  newArr.push({
                    specsValue1: specsValue1[i],
                    specsValue2: specsValue2[j],
                    ...reset,
                  })
                }
              }
            }
            this.modelSetVal = newArr
          }

          if (this.tableSpecsType.length === 1) {
            this.tableData.forEach((item, index) => {
              this.modelSetVal.forEach((item2, index2) => {
                if (
                  (item.specsValue1 && item.specsValue1.toString()) ===
                  item2.specsValue1
                ) {
                  this.tableData[index] = item2
                }
              })
            })
          } else {
            this.tableData.forEach((item, index) => {
              this.modelSetVal.forEach((item2, index2) => {
                if (
                  (item.specsValue1 && item.specsValue1.toString()) ===
                  item2.specsValue1
                ) {
                  if (
                    item.specsValue2 &&
                    item.specsValue2.toString() === item2.specsValue2
                  ) {
                    this.tableData[index] = item2
                  }
                }
              })
            })
          }

          this.tableData = Object.assign([], this.tableData)

          if (tableDataBus) {
            this.$Bus.$emit('SpecTableData', this.tableData)
          }

          this.ProductSpecConfigVisble = false
        } else {
          this.singleSpecData = []
          this.singleSpecData.push(form)
          if (tableDataBus) {
            this.$Bus.$emit('singleSpecData', this.singleSpecData)
          }
        }
      },
      // 规格图设置
      specSetPicClick() {
        this.$refs['packingModel'].publickModel('packing')
      },

      // 验证
      validateField() {
        let success = false
        let tableData = []
        let singleSpecData = []
        if (this.tableData.length) {
          tableData = deepCopy(this.tableData)
        }
        if (this.singleSpecData.length) {
          singleSpecData = deepCopy(this.singleSpecData)
        }

        if (tableData.length === 0 && singleSpecData.length === 0) {
          success = false
          this.$baseMessage(
            // '请配置规格列表',
            this.$t('reqmsg.M2025'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return {
            success: false,
          }
        } else {
          if (tableData.length) {
            let flag = true
            tableData.forEach((item) => {
              if (Array.isArray(item.specsValue1)) {
                item.specsValue1 = item.specsValue1.toString()
              }
              if (Array.isArray(item.specsValue2)) {
                item.specsValue2 = item.specsValue2.toString()
              }

              //判断规格数据是否配置全
              if (
                item.packingMethodEn == undefined ||
                item.productWeightKgs == undefined ||
                item.numberPerBox == undefined ||
                item.boxWeightKgs == undefined ||
                item.boxVolumeM == undefined
              ) {
                flag = false
              }
            })

            if (flag) {
              return {
                success: true,
                dataList: tableData,
              }
            } else {
              // 请完善规格配置
              return this.$baseMessage(
                this.$t('productDetail.PITSAC'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
          } else {
            return {
              success: true,
              dataList: singleSpecData,
            }
          }
        }
      },
      //保存到本地
      validateField2() {
        if (this.tableData.length) {
          this.tableData.forEach((item) => {
            if (Array.isArray(item.specsValue1)) {
              item.specsValue1 = item.specsValue1.toString()
            }
            if (Array.isArray(item.specsValue2)) {
              item.specsValue2 = item.specsValue2.toString()
            }
          })
          return this.tableData
        } else {
          return this.singleSpecData
        }
      },
    },
  }
  // 查找指定的元素在数组中的位置
  Array.prototype.indexOf = function (val) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] == val) {
        return i
      }
    }
    return -1
  }
  // 通过索引删除数组元素
  Array.prototype.remove = function (val) {
    var index = this.indexOf(val)
    if (index > -1) {
      this.splice(index, 1)
    }
  }
</script>

<style scoped lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }
    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }
    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }
  }
</style>
