<template>
  <div class="content">
    <h3 style="text-align: center">客户账期审核</h3>
    <h4 style="margin: 10px 0">公司信息</h4>

    <el-table :data="companyData" border stripe>
      <el-table-column
        label="序号"
        type="index"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="公司编码"
        prop="companyCode"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span class="page-link" @click="showCompany">
            {{ scope.row.companyCode }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="公司名称"
        prop="companyName"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="公司等级"
        prop="companyLevel"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-rate
            v-model="scope.row.companyLevel"
            disabled
            text-color="#ff9900"
          ></el-rate>
        </template>
      </el-table-column>
    </el-table>
    <h4 style="margin: 10px 0">客户账期</h4>
    <el-table
      :data="passRow.auditContent.infoEditDetailVO.companyVO.paymentEditVOs"
      border
      stripe
    >
      <el-table-column
        label="序号"
        type="index"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="付款方式"
        prop="payway"
        align="center"
      ></el-table-column>
      <el-table-column label="金额区间" prop="content" align="center">
        <template slot-scope="scope">
          <el-row v-if="scope.row.payType === 1">全部</el-row>
          <el-row v-else class="pl5 pr5">
            <span
              v-if="
                (scope.row.beginSection === 0 || scope.row.beginSection) &&
                scope.row.endSection
              "
            >
              {{ scope.row.beginSection }}-{{ scope.row.endSection }}
            </span>
            <span v-else>&gt;{{ scope.row.beginSection }}</span>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="option" align="center">
        <template slot-scope="scope">
          {{ scope.row.option | optionFilter }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { customerTypeStatusLang } from '@/utils/en-match-zh'
  export default {
    name: 'ErpWebNewCustomerCenterAudit',
    props: {
      passRow: {
        type: [Object, Array],
        required: true,
      },
    },

    data() {
      return {
        companyData: [],
      }
    },

    mounted() {
      this.companyData = [this.passRow.auditContent.infoEditDetailVO.companyVO]
    },

    methods: {
      // 跳转公司详情
      showCompany() {
        let routeData = this.$router.resolve({
          path: '/customer-manage/index/company-detail',
          query: { companyId: this.companyData[0]?.companyId, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },
      // 获取邮箱
      getEmail(infoShowVO) {
        const emails = (infoShowVO.linkMainEmails || [])
          .map((i) => i.email)
          .join(',')
        return emails || '--'
      },
      //客户类型匹配
      customerTypeStatus(val) {
        return this.$t(customerTypeStatusLang(val))
      },
      // 获取详细收货地址
      getFullAdress(item) {
        const { street, unit, city, province, postcode } = item
        const _ = (field) => {
          return field ? field : ''
        }
        return `${_(street)}${_(unit)}${_(city)}${_(province)}${_(postcode)}`
      },
    },

    filters: {
      optionFilter(val) {
        switch (val) {
          case 1:
            return '新增'
          case 2:
            return '修改'
          case 3:
            return '删除'
          default:
            return '--'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 15px;
  }

  h4 {
    margin-top: 20px;
  }
</style>
