<template>
  <div class="funciton-container">
    <el-row>勾选控制将决定页面和按钮的显示与隐藏</el-row>
    <el-row class="mt20 content-box">
      <el-row class="function-content-box">
        <el-row class="el-row-border">
          <el-row class="el-row-header">
            <el-col :span="3">
              <h4>板块</h4>
            </el-col>
            <el-col :span="21">
              <el-checkbox
                v-model="isCheckAll"
                :indeterminate="indeterminate"
                @change="checkAll"
                class="pl10"
              >
                全选
              </el-checkbox>
            </el-col>
          </el-row>
          <el-row class="function-content">
            <el-row v-if="rightList.length" style="height: 100%">
              <!-- 左侧导航 -->
              <el-col :span="3">
                <ul>
                  <li
                    v-for="(item, index) of rightList"
                    :key="index"
                    :class="index === liCurrent ? 'current' : ''"
                    @click="navSelect(item.name, index)"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </el-col>
              <!-- 右侧内容 -->
              <el-col :span="21" class="function-content-right" ref="itemList">
                <el-row @scroll="handleScroll" ref="rigth">
                  <el-row
                    class="floor content_box"
                    v-for="(one, oneIndex) of rightList"
                    :key="oneIndex"
                    :ref="`content${oneIndex}`"
                  >
                    <p>{{ one.name }}</p>
                    <el-row>
                      <el-col
                        class="floor-el-col"
                        v-for="(two, twoIndex) of one.children"
                        :key="twoIndex"
                      >
                        <el-row class="floor-row-border" type="flex">
                          <el-col
                            :span="3"
                            :xs="6"
                            :sm="4"
                            :md="3"
                            :lg="3"
                            :xl="3"
                            class="floor-row-border-left"
                          >
                            <el-checkbox
                              style="
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                              "
                              v-model="two.flag"
                              :true-label="1"
                              :false-label="0"
                              :disabled="two.disabled"
                              :indeterminate="two.indeterminate"
                              @change="
                                checkedTwoAll(
                                  oneIndex,
                                  twoIndex,
                                  two.menuId,
                                  one.menuId,
                                  $event
                                )
                              "
                            >
                              {{ two.name }}
                            </el-checkbox>
                          </el-col>
                          <el-col
                            :span="21"
                            :xs="18"
                            :sm="21"
                            :md="21"
                            :lg="21"
                            :xl="21"
                            class="floor-row-border-right"
                            v-if="two.children.length > 0"
                          >
                            <el-row type="flex">
                              <el-col :span="24" class="three-row">
                                <el-row
                                  v-for="(three, threeIndex) of two.children"
                                  :key="threeIndex"
                                >
                                  <el-checkbox
                                    v-if="!three.isShow"
                                    :true-label="1"
                                    :false-label="0"
                                    v-model="three.flag"
                                    :indeterminate="three.indeterminate"
                                    @change="
                                      checkedThreeAll(
                                        oneIndex,
                                        twoIndex,
                                        threeIndex,
                                        three.menuId,
                                        two.menuId,
                                        $event
                                      )
                                    "
                                  >
                                    <span>{{ three.name }}</span>
                                  </el-checkbox>
                                </el-row>
                              </el-col>
                              <!-- <el-col
                                :span="1"
                                v-if="two.children && two.children.length > 1"
                              >
                                <span
                                  class="row-check-all"
                                  @click="
                                    rowCheckAllClick(
                                      oneIndex,
                                      twoIndex,

                                      two.menuId,
                                      $event
                                    )
                                  "
                                >
                                  {{ rowCheckAll }}
                                </span>
                              </el-col> -->
                            </el-row>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-row>
                </el-row>
              </el-col>
            </el-row>
            <el-row v-else class="no-power-text">暂无权限...</el-row>
          </el-row>
        </el-row>
      </el-row>
    </el-row>
    <el-row class="operate" v-if="superUser !== 1">
      <el-button
        :loading="loading"
        @click="saveClick"
        type="primary"
        class="pt10 pd10"
        v-allowed="['Power:Edit']"
      >
        保存
      </el-button>
    </el-row>
  </div>
</template>

<script>
  import { userMenuAll, insertRoleMenuPO } from '@/api/power-group'
  export default {
    name: 'FuntionPower',
    props: {
      roleId: {
        type: String,
        required: true,
      },
      superUser: {
        //超级管理员
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        liCurrent: 0,
        isCheckAll: 0,
        rightList: [],
        indeterminate: false,
        currentRoleId: '',
        scrollY: 0, //右侧列表滑动的y轴坐标
        rightBscroll: -1,
        index: 0,
        menuId: '', //权限最外层id
        test2: [],
        rowCheckAll: '全选',
      }
    },
    watch: {
      roleId: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.currentRoleId = value
          this.getUserMenuAll()
        },
      },
      scrollY() {
        this.initRightBoxHeight()
      },
    },
    mounted() {
      document.addEventListener('scroll', this.handleScroll, true)
    },
    methods: {
      handleScroll(e) {
        let scrollTop =
          e.target.scrollTop ||
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop // 滚动条偏移量
        let num = this.rightList.length
        for (let n = 0; n < num; n++) {
          let height =
            document?.getElementsByClassName('content_box')?.[n]?.offsetTop
          if (height < scrollTop + 80) {
            this.liCurrent = n
          }
        }
      },

      recursionTree(data) {
        data.forEach((item) => {
          if (item.permission === 'H&') {
            item.isShow = true
            item.flag = true
          } else {
            item.isShow = false
          }
          if (item.children && item.children.length) {
            this.recursionTree(item.children)
          }
        })
        return data
      },

      //获取权限
      async getUserMenuAll() {
        let response = await userMenuAll({ roleId: this.currentRoleId })
        this.menuId = response.data[0].menuId
        if (response.code === '000000') {
          let test = this.recursionTree(response.data[0].children)

          // 1.5.4 中英文版本，首页可以权限设置

          // test.forEach((item) => {
          //   if (item.name === '首页') {
          //     item.children.forEach((item2) => {
          //       item2.flag = 1
          //       item2.disabled = true
          //     })
          //   }
          // })

          this.rightList = test

          // this.rightList.forEach((oneItem, oneIndex) => {
          //   if (oneItem.children.length > 0) {
          //     let oneCountNum = oneItem.children.length
          //     let isOneCheckedNum = 0
          //     oneItem.children.forEach((twoItem) => {
          //       if (twoItem.flag) {
          //         isOneCheckedNum += 1
          //       }
          //       if (twoItem.children.length > 0) {
          //         let twoCountNum = twoItem.children.length
          //         let isTwoCheckedNum = 0
          //         twoItem.children.forEach((three) => {
          //           if (three.flag) {
          //             isTwoCheckedNum += 1
          //           }
          //         })
          //         twoItem.flag = isTwoCheckedNum === twoCountNum
          //         twoItem.indeterminate =
          //           isTwoCheckedNum > 0 && isTwoCheckedNum < twoCountNum
          //       }
          //     })
          //     oneItem.flag = isOneCheckedNum === oneCountNum
          //     oneItem.indeterminate =
          //       isOneCheckedNum > 0 && isOneCheckedNum < oneCountNum
          //   }
          // })
        }
      },

      navSelect(item, index) {
        this.liCurrent = index
        this.$nextTick(() => {
          let height =
            document.getElementsByClassName('content_box')[index].offsetTop
          document.getElementsByClassName(
            'function-content-right'
          )[0].scrollTop = height - 12
        })
      },

      initRightBoxHeight() {
        let itemArray = []
        let top = 0
        itemArray.push(top)
        //获取右边所有子盒子高度集合
        let allList = this.$refs.itemList.$el.children
        //allList伪数组转化成真数组
        Array.prototype.slice.call(allList).forEach((li) => {
          top += li.clientHeight //获取所有li的每一个高度
          itemArray.push(top)
        })
        this.rightLiTops = itemArray
        this.rightLiTops.forEach((item, index) => {
          if (item <= this.scrollY) {
            this.index = index
          }
        })
      },

      //全选
      checkAll(e) {
        this.ischeckAll = e
        if (e === 1) {
          //全选
          this.indeterminate = false
          for (var i = 0, len = this.rightList.length; i < len; i++) {
            //二级全选反选不确定
            this.rightList[i].flag = e
            this.rightList[i].indeterminate = false
            for (
              var j = 0, len1 = this.rightList[i].children.length;
              j < len1;
              j++
            ) {
              this.rightList[i].children[j].flag = e
              for (
                var k = 0, len2 = this.rightList[i].children[j].children.length;
                k < len2;
                k++
              ) {
                this.rightList[i].children[j].children[k].flag = e
              }
            }
          }
        } else {
          //取消全选
          this.indeterminate = false
          for (var i = 0, len = this.rightList.length; i < len; i++) {
            //三级全选反选不确定
            this.rightList[i].flag = e
            this.rightList[i].indeterminate = false
            for (
              var j = 0, len1 = this.rightList[i].children.length;
              j < len1;
              j++
            ) {
              this.rightList[i].children[j].flag = e
              for (
                var k = 0, len2 = this.rightList[i].children[j].children.length;
                k < len2;
                k++
              ) {
                this.rightList[i].children[j].children[k].flag = e
              }
            }
          }
        }
      },
      //二级change事件
      checkedTwoAll(oneIndex, twoIndex, twoId, oneId, e) {
        // var childrenArray = this.rightList[oneIndex].children
        // var tickCount = 0,
        //   unTickCount = 0,
        //   len = childrenArray.length
        // for (var i = 0; i < len; i++) {
        //   if (twoId === childrenArray[i].menuId) childrenArray[i].flag = e
        //   if (childrenArray[i].flag === 1) tickCount++
        //   if (childrenArray[i].flag === 0) unTickCount++
        // }
        // //判断二级下面是否还有三级，点击选择二级(选择与不选）时候下面三级是全选还是全不选
        // if (childrenArray[twoIndex].children.length > 0) {
        //   childrenArray[twoIndex].children.forEach((threeItem) => {
        //     threeItem.flag = e
        //   })
        //   //判断二级是否选中
        //   childrenArray[twoIndex].flag = e
        //   if (e === 0) {
        //     childrenArray[twoIndex].indeterminate = false
        //   }
        // }
        // if (tickCount === len) {
        //   //二级全勾选
        //   this.rightList[oneIndex].flag = e
        //   this.rightList[oneIndex].indeterminate = false
        // } else if (unTickCount === len) {
        //   //二级全不勾选
        //   this.rightList[oneIndex].flag = e
        //   this.rightList[oneIndex].indeterminate = false
        // } else {
        //   this.rightList[oneIndex].flag = e
        //   this.rightList[oneIndex].indeterminate = true //添加一级不确定状态
        // }
        // this.getIsCheckAll()
      },
      rowCheckAllClick(oneIndex, twoIndex, twoId, oneId, e) {
        this.rowCheckAll === '全选' ? '反选' : '全选'
      },

      //三级change事件
      checkedThreeAll(oneIndex, twoIndex, threeIndex, threeId, twoId, e) {
        let childrenArray = this.rightList[oneIndex].children[twoIndex].children
        let tickCount = 0,
          unTickCount = 0,
          len = childrenArray.length
        for (let i = 0; i < len; i++) {
          if (threeId === childrenArray[i].menuId) childrenArray[i].flag = e
          if (childrenArray[i].flag === 1) tickCount++
          if (childrenArray[i].flag === 0) unTickCount++
        }
        if (tickCount === len) {
          //三级全勾选
          this.rightList[oneIndex].children[twoIndex].flag = 1
          this.rightList[oneIndex].children[twoIndex].indeterminate = false
          this.rightList[oneIndex].flag = 1
          this.rightList[oneIndex].indeterminate = false //添加二级不确定状态
        } else if (unTickCount === len) {
          //三级全不勾选
          this.rightList[oneIndex].children[twoIndex].flag = false
          this.rightList[oneIndex].children[twoIndex].indeterminate = false
          this.rightList[oneIndex].flag = false
          this.rightList[oneIndex].indeterminate = true //添加二级不确定状态
          this.isCheckAll = 0
          this.indeterminate = true
        } else if (tickCount !== len) {
          //三级勾选几个
          this.rightList[oneIndex].children[twoIndex].flag = e
          this.rightList[oneIndex].children[twoIndex].indeterminate = true
          this.rightList[oneIndex].flag = 0
          this.rightList[oneIndex].indeterminate = true //添加二级不确定状态
          this.isCheckAll = 0
          this.indeterminate = true
        }
        this.getIsCheckAll()
      },

      /**
       *是否全选
       */
      getIsCheckAll() {
        let tickCount = 0,
          unTickCount = 0,
          ArrLength = this.rightList.length
        for (let j = 0; j < ArrLength; j++) {
          //全选checkbox状态
          if (this.rightList[j].flag === 1) tickCount++
          if (this.rightList[j].flag === 0) unTickCount++
        }
        // if (tickCount === ArrLength) {
        //   //二级全勾选
        //   this.isCheckAll = 0
        //   this.indeterminate = true
        // } else {
        //   this.isCheckAll = 1
        //   this.indeterminate = false
        // }
        if (tickCount === ArrLength) {
          //alert('111')
          //二级全勾选
          this.isCheckAll = 1
          this.indeterminate = false
        } else if (unTickCount === ArrLength) {
          //二级全不勾选
          this.isCheckAll = 0
          this.indeterminate = false
        } else {
          this.isCheckAll = 0
          this.indeterminate = true //添加一级不确定状态
        }
      },
      //保存
      async saveClick() {
        //('this.rightList', this.rightList)
        let menuIdArr = []
        this.rightList.forEach((item1) => {
          item1.children.forEach((item2) => {
            if (item2.flag) {
              menuIdArr.push(item2.menuId, item2.parentId)
            }
            item2.children.forEach((item3) => {
              if (item3.flag) {
                menuIdArr.push(
                  item3.menuId,
                  item3.parentId,
                  item2.menuId,
                  item1.menuId
                )
              }
            })
          })
        })
        menuIdArr.push(this.menuId)

        let menuIdArrSet = [...new Set(menuIdArr)]
        this.loading = true
        let response = await insertRoleMenuPO({
          roleId: this.currentRoleId,
          menuIds: menuIdArrSet,
        })
        this.loading = false
        if (response.code === '000000') {
          this.$baseMessage(
            '保存成功',
            'success',
            false,
            'erp-hey-message-success'
          )
          this.getUserMenuAll()
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .funciton-container {
    height: 100%;
    .content-box {
      height: calc(100% - 86px);
      .function-content-box {
        height: 100%;
      }
    }
    .operate {
      height: 50px;
      line-height: 50px;
      padding-left: 15px;
      border: 1px solid #d8d8d8;
      border-top: none;
    }
  }
  .el-row-border {
    border: 1px solid #ededed;
    height: 100%;
    border-radius: 3px;
    .el-row-header {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #d8d8d8;
      padding: 0 15px;
    }
    .function-content {
      height: 100%;
      ul {
        li {
          height: 40px;
          line-height: 40px;
          padding: 0 15px;
          cursor: pointer;
        }
        li.current {
          background: #f5f7fa;
          font-family: 'PingFang Bold';
          color: #1890ff;
        }
      }
      .function-content-right {
        background: #f5f7fa;
        height: calc(100% - 40px);
        min-height: 500px;
        padding: 10px 20px;
        overflow-y: auto;
        .floor {
          p {
            font-size: 14px;
            margin-bottom: 10px;
          }
          .floor-el-col {
            border: 1px solid #e4e7ed;
            .floor-row-border {
              background: #fff;
              .floor-row-border-left {
                padding: 10px;
                border-right: 1px solid #e4e7ed;
              }
              .floor-row-border-right {
                padding: 10px;
              }
            }
          }
          .floor-el-col + .floor-el-col {
            border-top: none;
          }
        }
        .floor + .floor {
          margin-top: 20px;
        }
      }
    }
  }
  .row-check-all {
    color: #1890ff;
    cursor: pointer;
  }
  .three-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-bottom: -5px;
    > .el-row {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }
  .no-power-text {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep {
    .el-tabs__content {
      height: calc(100% - 55px);
    }
  }
</style>
