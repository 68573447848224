<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="800px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-row class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="中文名称" prop="nameCn">
              <el-input
                v-model="form.nameCn"
                placeholder="请输入长度1到50个字符"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" prop="nameEn">
            <el-form-item label="英文名称" prop="nameEn">
              <el-input
                v-model="form.nameEn"
                placeholder="请输入长度1到50个字符"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否多选" prop="multiple">
              <el-radio-group v-model="form.multiple">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否必选" prop="required">
              <el-radio-group v-model="form.required">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
          <el-form-item label="排序">
            <el-input
              v-model="form.orderNum"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              type="text"
              placeholder="数值"
            />
          </el-form-item>
          </el-col>-->
          <el-col :span="24">
            <el-form-item label="关联标签">
              <el-select
                v-model="form.linkedParentIds"
                placeholder="请选择"
                multiple
                @change="test"
              >
                <el-option
                  v-for="item in parentLabelList"
                  :key="item.keyParentId"
                  :label="item.nameCn + ' ' + item.nameEn"
                  :value="item.keyParentId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 标签列表 -->
        <el-row>
          <p class="f_w_7">标签列表</p>
          <div class="mt20">
            <el-button @click="lableChildAdd">新增</el-button>
          </div>
          <el-col :span="24" class="mt10">
            <el-table ref="dragTable" :data="labelList">
              <el-table-column align="center" type="selection" width="55" />
              <el-table-column align="center" prop="nameCn" label="中文名称" />
              <el-table-column align="center" prop="nameEn" label="英文名称" />
              <el-table-column align="center" label="操作" width="150">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="labelEdit(scope.row, scope.$index)"
                  >
                    编辑
                  </el-button>
                  <el-button size="mini" @click="labelDel(scope.$index)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <!-- 按钮 -->
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>

    <!-- 内层 -->
    <LabelChildModel
      ref="labelChildModel"
      :childLabelIds="childLabelIds"
      :editStr="editStr"
      @deliver-data="deliverData"
    />
  </el-dialog>
</template>

<script>
  import Sortable from 'sortablejs'
  import LabelChildModel from './LabelChildModel'
  import {
    insertPO,
    updatePO,
    getDetailForEdit,
    listParentForSelect,
    getDetailForSelect,
  } from '@/api/product/labelManage'
  import { deepCopy } from 'kits'
  export default {
    name: 'LabelModel',
    data() {
      return {
        loading: false,
        title: '新增标签',
        form: {},
        rules: {
          nameCn: [
            { required: true, trigger: 'blur', message: '请输入中文名称' },
          ],
          nameEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
          linkedParentIds: [
            { required: true, message: '请选择关联标签', trigger: 'change' },
          ],
          multiple: [
            { required: true, message: '请选择是否多选', trigger: 'change' },
          ],
          required: [
            { required: true, message: '请选择是否必选', trigger: 'change' },
          ],
        },
        dialogFormVisible: false,

        sortable: null,
        oldList: [{ id: 1 }, { id: 2 }, { id: 3 }],
        newList: [{ id: 1 }, { id: 2 }, { id: 3 }],
        innerVisible: false,

        labelId: '',
        parentLabelList: [], //标签下拉父级数据
        // 关联标签列表
        labelList: [],
        childLabelIds: [], //内层弹框的下拉，需要父级的下拉id
        testArr: [],
        editStr: '', //是否是编辑
        chooseSelect: [], //内层弹框的下拉
      }
    },
    components: {
      LabelChildModel,
    },

    created() {},
    methods: {
      // 新增编辑标签
      labelAddModel(id) {
        if (!id) {
          this.title = '新增标签'
        } else {
          this.title = '编辑标签'
          this.labelId = id
          this.getDetailForEdit(id)
        }
        this.listParentForSelect()
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.setSort()
        })
      },

      // 编辑回显
      async getDetailForEdit(id) {
        let response = await getDetailForEdit({ keyParentId: id })
        if ((response.code = '000000')) {
          this.form = response.data

          if (this.form.linkedParentIds) {
            this.form.linkedParentIds = this.form.linkedParentIds.split(',')
          }

          this.labelList = this.form.keyChildEditVOList
          this.test(this.form.linkedParentIds, 'editedit')
        }
      },
      // 标签下拉父级数据
      async listParentForSelect() {
        let response = await listParentForSelect()
        if ((response.code = '000000')) {
          this.parentLabelList = response.data
        }
      },
      // 内层弹框的下拉
      test(val, str) {
        if (val.length > 0) {
          this.childLabelIds = val
          if (str) {
            this.editStr = str
          }
        }
        this.childLabelIds.forEach((item) => {
          this.getDetailForSelect(item, this.childLabelIds)
        })
      },
      // 标签关联父级下拉的子级数据（每勾选一个调用一次）
      async getDetailForSelect(id, val) {
        this.chooseSelect = []
        let response = await getDetailForSelect({ keyParentId: id })
        this.chooseSelect.push(response.data)

        let selectArray = []

        let delArr = []
        if (this.labelList.lentgh > 0) {
          if (
            (this.labelList[0].keyLinkageEditVOList &&
              this.labelList[0].keyLinkageEditVOList.length > 0) ||
            (this.labelList[0].keyLinkageEditDTOList &&
              this.labelList[0].keyLinkageEditDTOList.length > 0)
          ) {
            let rowArr =
              this.labelList[0].keyLinkageEditVOList ||
              this.labelList[0].keyLinkageEditDTOList
            val.forEach((element) => {
              rowArr.forEach((item, index) => {
                if (element !== item.linkedParentId) {
                  delArr.push(item.linkedParentId)
                }
              })
            })

            this.labelList.forEach((item, index) => {
              let rowArr =
                item.keyLinkageEditVOList || item.keyLinkageEditDTOList
              if (delArr.length !== rowArr.length) {
                rowArr.forEach((item2, index2) => {
                  if (delArr.includes(item2.linkedParentId)) {
                    rowArr.splice(index2, 1)
                  }
                })
              }
            })
          }
        }
      },

      // 新增子级标签
      lableChildAdd() {
        this.$refs['labelChildModel'].labelChildModel('新增', this.chooseSelect)
      },
      // 标签列表行编辑
      labelEdit(row, index) {
        let selectArray = []
        let rowArr = row.keyLinkageEditVOList || row.keyLinkageEditDTOList
        rowArr.forEach((item) => {
          selectArray.push(item.linkedParentId)
        })
        this.chooseSelect.forEach((item) => {
          if (!selectArray.includes(item.keyParentId)) {
            row.keyLinkageEditVOList.push({
              linkedParentId: item.keyParentId,
            })
          }
        })
        this.$refs['labelChildModel'].labelChildModel(
          '编辑',
          this.chooseSelect,
          row,
          index
        )
      },
      // 标签列表行删除
      labelDel(index) {
        this.labelList.splice(index, 1)
      },

      isRepeat(arr, key) {
        var obj = {}
        for (let i = 0; i < arr.length; i++) {
          if (obj[arr[i][key]]) {
            return false
          } else {
            obj[arr[i][key]] = arr[i]
          }
        }
        return obj
      },
      deliverData(form, index, title) {
        let flag = this.labelList.some((ele) => ele.nameCn === form.nameCn)
        if (flag && this.labelList.length && title === '新增标签') {
          return this.$baseMessage(
            '子标签中文名称重复',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          if (index !== '') {
            this.$set(this.labelList, index, form)
          } else {
            this.labelList.push(form)
          }
        }
      },

      // 关闭
      close() {
        this.labelList = []
        this.$refs['form'].resetFields()
        //this.form = this.$options.data().form
        this.form = {}
        this.labelId = ''
        this.dialogFormVisible = false
      },
      // 保存
      save() {
        if (this.form.linkedParentIds) {
          this.form.linkedParentIds = this.form.linkedParentIds.join(',')
        }
        let labelList = deepCopy(this.labelList)
        labelList.forEach((item) => {
          if (
            item.keyLinkageEditVOList &&
            item.keyLinkageEditVOList.length > 0
          ) {
            item.keyLinkageEditDTOList = item.keyLinkageEditVOList
            delete item.keyLinkageEditVOList
          }
        })

        this.form = {
          ...this.form,
          keyChildEditDTOList: labelList,
        }
        delete this.form.keyChildEditVOList
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (labelList.length === 0) {
              if (this.form.linkedParentIds) {
                this.form.linkedParentIds = this.form.linkedParentIds.split(',')
              }

              return this.$baseMessage(
                '请完善标签列表数据',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            //新增接口
            let response
            this.loading = true
            if (this.labelId === '') {
              response = await insertPO(this.form)
              this.loading = false
              if ((response.code = '000000')) {
                this.$baseMessage(
                  '操作成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              response = await updatePO(this.form)
              this.loading = false
              if ((response.code = '000000')) {
                this.$baseMessage(
                  '操作成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }

            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      // 排序
      setSort() {
        const el = this.$refs.dragTable.$el.querySelectorAll(
          '.el-table__body-wrapper > table > tbody'
        )[0]
        this.sortable = Sortable.create(el, {
          ghostClass: 'sortable-ghost',
          setData: function (dataTransfer) {
            dataTransfer.setData('Text', '')
          },
          onEnd: (evt) => {
            const targetRow = this.labelList.splice(evt.oldIndex, 1)[0]
            this.labelList.splice(evt.newIndex, 0, targetRow)
            const tempIndex = this.labelList.splice(evt.oldIndex, 1)[0]
            this.labelList.splice(evt.newIndex, 0, tempIndex)
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .sortable-ghost {
    opacity: 0.8;
    color: #fff !important;
    background: #42b983 !important;
  }
  .dialog-content {
    height: 45vh;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 50px;
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100% !important;
    }
    .el-radio {
      margin-right: 15px;
    }
  }
</style>
