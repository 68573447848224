<template>
  <!-- 利润率设置 -->
  <el-dialog
    :title="$t('other.setProfit')"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-row>
      <el-table :data="printingProfit" class="w100 table-height">
        <!-- 数量 -->
        <el-table-column
          prop="num"
          :label="$t('productDetail.Quantity')"
          width="180"
          align="center"
        />
        <!-- 利润率 -->
        <el-table-column
          :label="$t('productDetail.ProfitRate')"
          prop="rate"
          align="center"
        >
          <template #default="{ row }">
            <!-- 请输入价格 -->
            <el-input
              v-model="row.rate"
              :placeholder="$t('placeholder.M2005')"
              size="normal"
              clearable
              type="number"
            />
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          align="center"
          :label="$t('productDetail.Operate')"
          width="150"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="profitDel(scope.$index)">
              <!-- 删除 -->
              {{ $t('other.delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'PrintingProfitModel',
  data() {
    return {
      // title: '利润率设置',
      // title: this.$t('other.setProfit'),
      form: {},
      // rules: {
      //   proofingFee: [
      //     // 请输入打样费用
      //     { required: true, trigger: 'blur', message: this.$t('rules.M2012') },
      //   ],
      // },
      dialogFormVisible: false,
      bcglXiangXiList: [],
      printingProfit: [],
    }
  },
  created() {},
  computed: {
    rules() {
      return {
        proofingFee: [
          // 请输入打样费用
          { required: true, trigger: 'blur', message: this.$t('rules.M2012') },
        ],
      }
    },
  },
  methods: {
    unique(arr, val) {
      const res = new Map()
      return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
    },
    //展示弹框
    printingProfitModel(row) {
      let arrRepeat = this.unique(row, 'num')
      this.printingProfit = arrRepeat

      if (!row) {
      } else {
      }
      this.dialogFormVisible = true
    },
    // 利润率删除
    profitDel(index) {
      this.printingProfit.splice(index, 1)
    },
    // 关闭
    close() {
      this.printingProfit = []
      this.dialogFormVisible = false
    },
    // 保存
    save() {
      this.$emit('printingProfitModel', this.printingProfit)
      this.close()
    },
  },
}
</script>
