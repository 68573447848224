<template>
  <div class="table-container">
    <el-row class="page-header align_center pub-h-36" type="flex">
      <el-row type="flex" class="align_center" v-if="!selectRows.length">
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="addClick"
          class="pub-h-36"
        >
          新增
        </el-button>
        <!-- 查询表单 -->
        <el-form
          ref="form"
          class="ml10"
          :inline="true"
          :model="queryForm"
          label-width="49px"
          @submit.native.prevent
        >
          <el-form-item>
            <el-input
              v-model="queryForm.name"
              placeholder="请输入名称"
              @keyup.enter.native="fetchData"
              clearable
              @input="searchInput"
              class="pub-h-36"
            />
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="fetchData" class="pub-h-36">
          搜索
        </el-button>
        <el-button
          style="margin-bottom: 0"
          class="ml10 pub-h-36"
          @click="delClick('out')"
        >
          删除
        </el-button>
      </el-row>
      <el-row v-else type="flex" class="align_center">
        <p>
          已选
          <span>{{ selectRows.length }}</span>
          个标签
        </p>
        <el-button
          style="margin-bottom: 0"
          class="ml20 pub-h-36"
          @click="delClick('multiple')"
        >
          删除
        </el-button>
      </el-row>
    </el-row>
    <!-- 表格 -->

    <div class="main-table mt20 main-height">
      <ErpTable
        :is-requesting="isRequesting"
        :page-number.sync="queryForm.pageNo"
        :page-size.sync="queryForm.pageLe"
        :table-data="tableData"
        :total="total"
        @query="fetchData"
        @selection-change="setSelectRows"
      >
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column label="中文名称" align="center" prop="nameCn" />
        <el-table-column label="英文名称" align="center" prop="nameEn" />
        <el-table-column
          label="是否多选"
          width="130"
          align="center"
          prop="isMultiple"
        >
          <template #default="{ row }">
            <el-switch
              v-model="row.multiple"
              disabled
              active-value="1"
              inactive-value="0"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="是否必选"
          width="130"
          align="center"
          prop="isMultiple"
        >
          <template #default="{ row }">
            <el-switch
              v-model="row.required"
              disabled
              active-value="1"
              inactive-value="0"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="子选项(中文)"
          align="center"
          prop="nameCnChild"
          show-overflow-tooltip
        />
        <!-- 右侧固定列 -->
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template #default="{ row }">
            <el-tooltip content="编辑" placement="top">
              <el-button
                type="primary"
                plain
                icon="el-icon-edit"
                circle
                size="mini"
                @click="editClick(row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <el-button
                type="danger"
                plain
                icon="el-icon-delete"
                circle
                size="mini"
                @click="delClick('single', row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </ErpTable>
    </div>

    <!-- 新增编辑弹框 -->
    <LabelModel ref="labelModel" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getlistPage, logicDeleteByIds } from '@/api/product/labelManage'
  import LabelModel from './components/LabelModel'
  export default {
    name: 'LabelManager',
    components: {
      LabelModel,
    },
    data() {
      return {
        // 正在查询
        isRequesting: false,
        // 页码
        pageNo: 1,
        // 页展示行数
        pageLe: 10,
        // 总行数
        total: 0,
        queryForm: {
          pageLe: 10,
          pageNo: 1,
        },
        tableData: [],
        // 编辑对话框
        editDialogVisble: false,
        // 新增对话框
        addDialogVisble: false,
        // 当前行
        currentRow: {},
        selectRows: [],
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      // 标签列表
      async fetchData() {
        let response = await getlistPage(this.queryForm)
        if ((response.code = '000000')) {
          this.tableData = response.data.data
          this.total = response.data.total
        }
      },
      //如果input为空
      searchInput(val) {
        if (val === '') {
          this.fetchData()
        }
      },
      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
      },
      // 行编辑
      rowEdit(row) {
        this.currentRow = row
        this.editDialogVisble = true
      },
      changeSwitch() {},
      // 新增
      addClick() {
        this.$refs['labelModel'].labelAddModel()
      },
      // 编辑
      editClick(row) {
        this.$refs['labelModel'].labelAddModel(row.keyParentId)
      },
      // 删除
      delClick(str, row) {
        if (str === 'out') {
          return this.$baseMessage(
            '请先勾选要删除的标签',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.$baseConfirm('确定要删除吗', null, async () => {
          // 删除接口
          let ids
          if (str === 'single' && row.keyParentId) {
            ids = row.keyParentId
          } else {
            if (this.selectRows[0] instanceof Object) {
              let idsArr = this.selectRows.map((item) => {
                return item.keyParentId
              })
              ids = idsArr.join(',')
            } else {
              ids = this.selectRows.join(',')
            }
          }
          let response = await logicDeleteByIds({ ids: ids })
          if ((response.code = '000000')) {
            this.$baseMessage(
              '刪除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
          this.fetchData()
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .main-height {
    height: calc(100% - 56px);
  }
  .page-header {
    height: 33px;
  }
  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
</style>
