<template>
  <div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="120px"
      style="margin: 10px 0 30px"
      :inline="true"
    >
      <el-form-item label="订单审核通过后" prop="day">
        <el-input
          v-model.number="form.day"
          style="width: 140px"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item>天内无操作直接作废</el-form-item>
    </el-form>
    <div style="display: flex; justify-content: center">
      <el-button type="primary" @click="submitForm">确 认</el-button>
      <el-button @click="resetForm">取 消</el-button>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: {
    dayNumber: {
     type: [Number, String],
      default: () => {},
    },
    invalidDayVisible:{
      type:Boolean,
      default:false
    }
  },
  computed: {
    showDialog:{
      get(){
        return this.invalidDayVisible
      },
      set(val){
        this.$emit('update:invalidDayVisible',val)
      }
    } 
  },
  watch:{
    invalidDayVisible(val){
      if(val)this.form.day = this.dayNumber
    }
  },
  created() {
    this.form.day = this.dayNumber
  },
  data() {
    var validateDay = (rule, value, callback) => {
        if(value === ''){
          callback();
        }else if (isNaN(parseInt(value, 10)) || value < 1 || value > 365) {
          callback(new Error('请录入1-365数值，当前输入的不符合校验，请重新录入'));
        } else{
          callback();
        }
      };
    return {
      form: {
        day: '',
      },
      rules: {
        day: [
          { validator: validateDay, trigger: 'blur' }
        ],
      },
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$emit('saveDaySet', this.form)
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.form.day = ''
      this.showDialog = false
    },
  },
}
</script>