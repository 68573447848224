<template>
  <div class="order-synchro">
    <el-row>
      <el-col :span="2">
        <h4 class="synchro-title">订单同步</h4>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="22" :offset="1">
        <el-form :model="form" ref="form" inline :show-message="inputRequire">
          <div class="synchro-ctn">
            <span>创建采购单是否自动同步数据到订单跟踪页面</span>
            <el-checkbox v-model="checked" style="margin-left: 35px" disabled>
              采购订单多产品，拆成多条跟踪单
            </el-checkbox>
            <div class="set-box">
              <div class="synchro-set">
                <p>根据产品来生成</p>
                <el-checkbox-group
                  v-model="form.synchroSetting"
                  style="margin-left: 35px"
                >
                  <el-checkbox label="orderedProductsTick">订制品</el-checkbox>
                  <el-checkbox label="standardTick">标准品</el-checkbox>
                  <el-checkbox label="accessoriesTick">辅料</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="set-money">
                <p>产品根据金额区间来生成</p>
                <div style="margin-left: 35px">
                  <el-checkbox-group v-model="form.synchroSettingMoney">
                    <el-checkbox label="orderedProductsTick">
                      订制品（根据金额判断）
                    </el-checkbox>
                    <el-checkbox label="standardTick">
                      标准品（根据金额判断）
                    </el-checkbox>
                    <el-checkbox label="accessoriesTick">
                      辅料（根据金额判断）
                    </el-checkbox>
                  </el-checkbox-group>
                  <div style="margin-top: 10px">
                    <el-form-item>金额范围：</el-form-item>
                    <el-form-item
                      prop="startAmount"
                      :rules="{
                        required: inputRequire > 0,
                        message: '开始金额不能为空',
                        trigger: 'blur',
                      }"
                    >
                      <el-input
                        v-model="form.startAmount"
                        style="width: 150px"
                        @change="moneyChange('start')"
                        :disabled="!moneyInputDisabled"
                        :maxlength="10"
                      >
                        <!-- <template slot="append">￥</template> -->
                      </el-input>
                    </el-form-item>
                    <el-form-item>~</el-form-item>
                    <el-form-item
                      prop="endAmount"
                      :rules="{
                        required: inputRequire > 0,
                        message: '结束金额不能为空',
                        trigger: 'blur',
                      }"
                    >
                      <el-input
                        v-model="form.endAmount"
                        style="width: 150px"
                        @change="moneyChange('end')"
                        :disabled="!moneyInputDisabled"
                        :maxlength="10"
                      >
                        <!-- <template slot="append">￥</template> -->
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'order-synchro',
  data() {
    return {
      checked: true, // 订单同步选择
      form: {
        synchroSetting: [], // 订单同步 不带金额
        synchroSettingMoney: [], //  订单同步 带金额
        startAmount: '', // 开始金额
        endAmount: '', // 结束金额
      },
      inputRequire: false,
    }
  },
  props: {
    synchroInfo: {
      default: () => {},
      type: Object,
    },
  },
  watch: {
    synchroInfo: {
      handler() {
        this.setInfo(this.synchroInfo)
      },
      deep: true,
    },
    'form.synchroSetting': {
      handler() {
        if (this.form.synchroSetting.length > 0) {
          this.form.synchroSettingMoney = []
          this.form.startAmount = ''
          this.form.endAmount = ''
          this.inputRequire = false
        }
      },
      deep: true,
      immediate: true,
    },
    'form.synchroSettingMoney': {
      handler() {
        if (this.form.synchroSettingMoney.length > 0) {
          this.form.synchroSetting = []
          this.inputRequire = true
        }
      },
      deep: true,
      immediate: true,
    },
    form: {
      handler() {
        this.sendSynchroSetting()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    moneyInputDisabled() {
      return this.form.synchroSettingMoney.length > 0
    },
  },
  methods: {
    sendSynchroSetting() {
      let obj = {}
      if (this.form.synchroSetting.length > 0) {
        obj.amountJudgment = 0
        obj.accessoriesTick =
          this.form.synchroSetting.indexOf('accessoriesTick') > -1 ? 1 : 0
        obj.orderedProductsTick =
          this.form.synchroSetting.indexOf('orderedProductsTick') > -1 ? 1 : 0
        obj.standardTick =
          this.form.synchroSetting.indexOf('standardTick') > -1 ? 1 : 0
      }

      if (this.form.synchroSettingMoney.length > 0) {
        obj.amountJudgment = 1
        obj.accessoriesTick =
          this.form.synchroSettingMoney.indexOf('accessoriesTick') > -1 ? 1 : 0
        obj.orderedProductsTick =
          this.form.synchroSettingMoney.indexOf('orderedProductsTick') > -1
            ? 1
            : 0
        obj.standardTick =
          this.form.synchroSettingMoney.indexOf('standardTick') > -1 ? 1 : 0

        obj.startAmount = this.form.startAmount
        obj.endAmount = this.form.endAmount
      }
      if (
        this.form.synchroSettingMoney.length == 0 &&
        this.form.synchroSetting.length == 0
      ) {
        obj.accessoriesTick = 0
        obj.orderedProductsTick = 0
        obj.standardTick = 0
      }
      this.form.trackingSynchroSettingId
        ? (obj.trackingSynchroSettingId = this.form.trackingSynchroSettingId)
        : ''
      this.$emit('getSynchroSetting', obj)
    },

    moneyChange(type) {
      if (type === 'start') {
        this.form.startAmount =
          this.form.startAmount !== '' ? _.toInteger(this.form.startAmount) : 0
        this.form.startAmount =
          this.form.startAmount > 0 ? this.form.startAmount : 0
      }

      if (type === 'end') {
        this.form.endAmount =
          this.form.endAmount !== '' ? _.toInteger(this.form.endAmount) : ''
        this.form.endAmount = this.form.endAmount > 0 ? this.form.endAmount : 0
      }
    },
    setInfo(info) {
      this.form.startAmount = info.startAmount
      this.form.endAmount = info.endAmount
      this.form.trackingSynchroSettingId = info.trackingSynchroSettingId
      if (info.amountJudgment === 1) {
        info.accessoriesTick === 1
          ? this.form.synchroSettingMoney.push('accessoriesTick')
          : ''
        info.orderedProductsTick === 1
          ? this.form.synchroSettingMoney.push('orderedProductsTick')
          : ''
        info.standardTick === 1
          ? this.form.synchroSettingMoney.push('standardTick')
          : ''
      }
      if (info.amountJudgment === 0) {
        info.accessoriesTick === 1
          ? this.form.synchroSetting.push('accessoriesTick')
          : ''
        info.orderedProductsTick === 1
          ? this.form.synchroSetting.push('orderedProductsTick')
          : ''
        info.standardTick === 1
          ? this.form.synchroSetting.push('standardTick')
          : ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.order-synchro {
  .synchro-title {
    display: inline-block;
    border-bottom: 1px solid red;
    box-sizing: border-box;
    padding: 5px 8px;
  }
  .synchro-ctn {
    display: flex;
    flex-direction: column;
  }
  .set-box {
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    margin-top: 10px;
    .set-money {
      margin-top: 10px;
    }
  }
}
</style>
