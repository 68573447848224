<template>
  <div class="pro_material">
    <el-row :gutter="20" class="num-total">
      <el-col :span="4">
        <h4>{{ totalPurchase || 0 }}</h4>
        <!-- 订单成交量 -->
        <p>{{ $t('productDetail.OrderVolume') }}</p>
      </el-col>
      <el-col :span="4">
        <h4>
          {{ totalAmount || 0 }}
        </h4>
        <!-- 订单总金额 -->
        <p>{{ $t('productDetail.TotalOrderAmount') }} (￥)</p>
      </el-col>
    </el-row>
    <el-table class="mt15" :data="tableData" border style="width: 100%">
      <el-table-column
        type="index"
        width="60"
        align="center"
        label="#"
      ></el-table-column>
      <!-- 采购订单编号 -->
      <el-table-column
        prop="purchaseOrderNumber"
        align="center"
        :label="$t('productDetail.PurchaseOrderNumber')"
      >
        <template slot-scope="scope">
          <span class="blue-text">
            <router-link
              class="blue-text"
              :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
                scope.row.purchaseOrderId
              }&noReturn=${true}`"
              target="_blank"
            >
              {{ scope.row.purchaseOrderNumber }}
            </router-link>
          </span>
        </template>
      </el-table-column>
      <!-- 采购需求编号 -->
      <el-table-column
        prop="purchaseDemandNumber"
        align="center"
        :label="$t('productDetail.PurchaseRequirementNumber')"
      >
        <template slot-scope="scope">
          <el-row class="blue-text">
            <span
              @click="purchaseDemandNumberClick(scope.row.purchaseDemandNumber)"
            >
              {{ scope.row.purchaseDemandNumber }}
            </span>
            <el-row
              class="circle-span"
              v-if="scope.row.purchaseDemandNumberList.length > 1"
            >
              <el-popover placement="top-start" width="200" trigger="hover">
                <span slot="reference">
                  {{ scope.row.purchaseDemandNumberList.length }}
                </span>
                <el-row
                  class="blue-text"
                  @click="purchaseDemandNumberClick(item)"
                  v-for="(item, index) of scope.row.purchaseDemandNumberList"
                  :key="index"
                >
                  {{ item }}
                </el-row>
              </el-popover>
            </el-row>
          </el-row>
        </template>
      </el-table-column>
      <!-- 采购数量 -->
      <el-table-column
        align="center"
        prop="numPurchase"
        :label="$t('productDetail.PurchasingQuantity')"
      ></el-table-column>
      <!-- 金额 -->
      <el-table-column
        align="center"
        prop="amount"
        :label="$t('productDetail.Amount')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.currency === '美元' ? '$' : '￥' }}{{ scope.row.amount }}
        </template>
      </el-table-column>
      <!-- 采购状态 -->
      <el-table-column
        align="center"
        prop="state"
        :label="$t('productDetail.PurchaseStatus')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ $t(purchaseOrderStatus(scope.row.state)) }}
        </template>
      </el-table-column>
      <!-- 交货日期 -->
      <el-table-column
        align="center"
        prop="expectDeliveryDate"
        :label="$t('productDetail.DeliveryDate')"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 实际交货日期 -->
      <el-table-column
        align="center"
        prop="actualDeliveryDate"
        :label="$t('productDetail.ActualDeliveryDate')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.actualDeliveryDate || '--' }}
        </template>
      </el-table-column>
      <!-- 采购开发 -->
      <el-table-column
        align="center"
        prop="buyerName"
        :label="$t('productDetail.PurchasingDevelopment')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ getName(scope.row, 'buyerName', 'buyerEnName') }}
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      v-show="total > 0"
      :limit.sync="queryForm.pageSize"
      :page.sync="queryForm.pageNum"
      :total="total"
      @pagination="getPurchaseOrderForProduct"
    />

    <!-- 采购需求详情 -->
    <el-dialog
      :title="$t('other.M2002')"
      :visible.sync="purchaseVisible"
      width="900px"
    >
      <div class="custom-dialog">
        <!-- 产品列表 -->
        <h4>{{ $t('other.M2001') }}</h4>

        <table class="custom-table">
          <thead>
            <tr>
              <!-- 序号 -->
              <th width="40" class="index">
                {{ $t('productDetail.SerialNumber') }}
              </th>
              <!-- Item -->
              <th width="300">Item</th>
              <!-- 库存 -->
              <th width="150">{{ $t('other.M2003') }}</th>
              <!-- 需求数量 -->
              <th width="150">{{ $t('other.M2004') }}</th>
            </tr>
          </thead>
          <tbody v-if="productList.length">
            <tr v-for="(item, i) in productList" :key="i">
              <td>{{ i + 1 }}</td>
              <td style="max-width: 300px">
                <span v-show="item.productCode">{{ item.productCode }}/</span>
                <span v-show="item.nameEn">{{ item.nameEn }}/</span>
                <span v-show="item.specsValue1">{{ item.specsValue1 }}</span>
                <span v-show="item.specsValue2">;{{ item.specsValue2 }}</span>
              </td>
              <td style="max-width: 300px">
                <div
                  v-if="
                    item.stockWarehouseNumVOList &&
                    item.stockWarehouseNumVOList.length
                  "
                >
                  <div
                    v-for="(obj, i) in item.stockWarehouseNumVOList"
                    :key="i"
                  >
                    {{ obj.warehouseName }}:{{ obj.num }}
                  </div>
                </div>
                <div v-else>-</div>
              </td>
              <td>{{ item.numDemand || 0 }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <!-- 暂无数据 -->
              <td colspan="4">{{ $t('other.M2005') }}</td>
            </tr>
          </tbody>
        </table>
        <div class="text-right mt10 mb10">
          <!-- 总数量： -->
          {{ $t('other.M2006') }}：
          <span class="gray f_s_16">{{ amount }}</span>
        </div>
        <el-row :gutter="24">
          <el-col :span="8">
            <!-- 期望交期 -->
            <p>
              {{ $t('other.M2007') }}：{{ detail.expectedDeliveryDate || '--' }}
            </p>
          </el-col>
          <el-col :span="16">
            <!-- 收货地址： -->
            <p>
              {{ $t('other.M2008') }}：{{ detail.receivingAddress || '--' }}
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8">
            <!-- 创建人： -->
            <p>
              {{ $t('productDetail.Creator') }}：{{ detail.creator || '--' }}
            </p>
          </el-col>
          <el-col :span="16">
            <!-- 创建时间： -->
            <p>
              {{ $t('productDetail.CreationTime') }}：{{
                detail.createTime || '--'
              }}
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col>
            <!-- 备注： -->
            <p>{{ $t('productDetail.Remark') }}：{{ detail.remark }}</p>
          </el-col>
        </el-row>
        <div class="text-center mt20">
          <!-- 关闭 -->
          <el-button @click="purchaseVisible = false">
            {{ $t('productDetail.Close') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import {
    purchaseOrderForProduct,
    getPurchasingDemandId,
  } from '@/api/product/product-info'
  import { purchasingDGetDetailForEdit } from '@/api/order'
  import { purchaseOrderStatusTranlate } from '@/utils/en-match-zh'
  import nameMixin from '@/utils/name-mixin'
  export default {
    name: 'PurchaseOrder',
    components: { Pagination },
    mixins: [nameMixin],
    data() {
      return {
        queryForm: {
          pageSize: 10,
          pageNum: 1, //当前页数
        },
        total: 0,
        productId: '', //产品id
        tableData: [],
        totalAmount: 0,
        totalPurchase: 0,
        purchaseVisible: false,
        productList: [],
        amount: null,
        detail: {},
      }
    },
    // 状态过滤
    filters: {
      //采购订单状态
      stateFilter(status) {
        const statusMap = {
          1: '录入',
          2: '已驳回',
          3: '已撤销',
          4: '审核中',
          5: '采购中',
          6: '验货中',
          7: '已完成',
          8: '已作废',
          9: '销售订单异常',
          10: '供应商延期',
          11: '已入库',
        }
        return statusMap[status]
      },
    },

    created() {
      if (this.$route.query.proId) {
        this.productId = this.$route.query.proId
        //产品采购记录
        this.getPurchaseOrderForProduct()
      }
    },
    mounted() {},
    methods: {
      //采购记录
      async getPurchaseOrderForProduct(pageData) {
        if(pageData) {
          this.queryForm.pageSize = pageData.limit
          this.queryForm.pageNum = pageData.page
        }
        let response = await purchaseOrderForProduct({
          ...this.queryForm,
          productId: this.productId,
        })
        if (response.code === '000000' && response.data) {
          this.totalAmount = response.data.totalAmountEx
          this.totalPurchase = response.data.totalPurchase
          this.tableData = response.data?.purchaseOrderProductVOS?.list
          this.total = response.data?.purchaseOrderProductVOS?.total
        }
      },
      //采购订单跳转
      goToPage(id, number) {
        let routeData = this.$router.resolve({
          path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
          query: {
            id: id,
          },
        })
        window.open(routeData.href, '_blank')
      },

      //采购需求跳转
      async purchaseDemandNumberClick(number) {
        let response = await getPurchasingDemandId({
          purchasingDemandNumber: number,
        })
        if (response.code === '000000' && response.data) {
          let routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail',
            query: {
              orderCode: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        } else {
          purchasingDGetDetailForEdit({
            numberPrefix: number.split('--')[0],
          }).then((res) => {
            if (res && res.code == '000000') {
              this.detail = res.data ? res.data : {}
              this.productList =
                res.data.purchasingDemandProductEditVOList || []
              if (this.productList.length) {
                const arr = []
                this.productList.forEach((item) =>
                  arr.push(Number(item.numDemand))
                )
                this.amount = arr.reduce((p, v) => p + v)
              }
              this.purchaseVisible = true
            }
          })
        }
      },
      purchaseOrderStatus(val) {
        return purchaseOrderStatusTranlate(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  .num-total {
    padding-left: 15px;
    > .el-col {
      border: 1px solid #ededed;
      padding: 15px 20px;
      border-radius: 2px;
      h4 {
        font-size: 30px;
        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
      p {
        margin-top: 5px;
      }
    }
    .el-col + .el-col {
      margin-left: 10px;
    }
  }
  .circle-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    margin-left: 5px;
  }
  ::v-deep {
    .el-tabs__content {
      padding-left: 50px !important;
    }
  }
</style>
