<template>
  <el-dialog
    title="供应商折扣备注"
    width="600px"
    :visible.sync="modelVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
      <el-form-item label="折扣备注" prop="supplierDiscountRemaks">
        <el-input
          type="textarea"
          v-model="form.supplierDiscountRemaks"
          maxlength="500"
          show-word-limit
          :rows="3"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="text-center">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          supplierDiscountRemaks: '',
        },
        rules: {
          supplierDiscountRemaks: [
            {
              required: true,
              message: '请输入供应商折扣备注',
              trigger: 'blur',
            },
          ],
        },
        modelVisible: false,
      }
    },
    methods: {
      // 保存
      save() {
        let self = this
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$emit(
              'reason-confirm',
              this.form.supplierDiscountRemaks,
              this.index
            )
            this.close()
          }
        })
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.modelVisible = false
      },
      init(text, i) {
        this.index = i
        this.form.supplierDiscountRemaks = text
        this.modelVisible = true
      },
    },
  }
</script>
