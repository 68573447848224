import request from '@/core/services/request'

// 标签分页查找列表
export function getlistPage(data) {
  return request({
    url: '/product/keyParent/listPage',
    method: 'post',
    data,
  })
}

// 标签查找详情（用于编辑回显）
export function getDetailForEdit(data) {
  return request({
    url: '/product/keyParent/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 标签查找详情（用于下拉框数据）
export function getDetailForSelect(data) {
  return request({
    url: '/product/keyParent/getDetailForSelect',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 标签新增实体
export function insertPO(data) {
  return request({
    url: '/product/keyParent/insertPO',
    method: 'post',
    data,
  })
}
// 标签-新增产品弹框数据
export function listForPopupByCategoryId(data) {
  return request({
    url: '/product/keyParent/listForPopupByCategoryId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 标签下拉框父级数据
export function listParentForSelect(data) {
  return request({
    url: '/product/keyParent/listParentForSelect',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 标签批量ID删除
export function logicDeleteByIds(data) {
  return request({
    url: '/product/keyParent/logicDeleteByIds',
    method: 'post',
    data,
  })
}
// 标签更新实体
export function updatePO(data) {
  return request({
    url: '/product/keyParent/updatePO',
    method: 'post',
    data,
  })
}
