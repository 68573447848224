<!--
  功能：入库批次组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年02月10日 10:08:37
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div>
    <div
      class="packing-detail-wrapper"
      @mouseover="toggleEditIcon"
      @mouseleave="toggleEditIcon(false)"
    >
      <template>
        <div class="content" v-if="lotList.length > 0">
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              <div>
                <p v-for="(item, index) in lotList" :key="index">
                  {{ item.lotNo }}({{ item.num }})
                </p>
              </div>
            </div>
            <div>
              <!-- 不超过5条 -->
              <div>
                <p v-for="(item, index) in list" :key="index">
                  {{ item.lotNo }}({{ item.num }})
                </p>
              </div>
              <p v-if="isSizesNotOver5">...</p>
            </div>
          </el-tooltip>
        </div>
        <p v-else class="item">-</p>
      </template>

      <p>
        <i class="el-icon-edit" @click="show" v-if="isChoose"></i>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LotDetail',
    props: {
      lotList: {
        type: Array,
        default: () => [],
      },
      index: {
        type: Number,
        default: 0,
      },
      isChoose: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showEdit: false,
      }
    },

    computed: {
      isSizesNotOver5() {
        return this.lotList.length > 5
      },

      list() {
        if (this.isSizesNotOver5) {
          return this.lotList.slice(0, 5)
        }
        return this.lotList
      },
    },
    methods: {
      toggleEditIcon(flag) {
        this.showEdit = flag
      },

      show() {
        this.$emit('lot-sel', this.index)
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-dialog__header {
      text-align: left;
    }
  }
  .line-one {
    width: 125px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .packing-detail-wrapper {
    display: flex;
    align-items: flex-end;
    .content {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
    }
    .item {
      width: 20px;
    }
  }
  .el-icon-edit {
    cursor: pointer;
    color: #409eff;
    cursor: 'pointer';
  }
</style>
<style>
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
