<template>
  <div class="search-form">
    <el-form
      class="search-form-content"
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="登录名" prop="loginName">
            <span>{{ form.loginName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="姓名" prop="userName">
            <el-input
              v-model="form.userName"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else>{{ form.userName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="英文名" prop="englishName">
            <el-input
              v-model="form.englishName"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else>{{ form.englishName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="手机号码" prop="telephone">
            <el-input
              type="text"
              v-model="form.telephone"
              placeholder="请输入"
              class="no-number"
              v-if="!isShow"
            ></el-input>
            <span v-else>{{ form.telephone }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="form.email"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else-if="form.email">{{ form.email }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="所属组织" prop="deptId">
            <el-cascader
              class="w100"
              v-model="form.deptId"
              :options="treeData"
              :show-all-levels="true"
              :props="optionProps"
              v-if="!isShow"
            ></el-cascader>
            <span v-else>{{ form.deptName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="职位" prop="postType">
            <template>
              <el-select
                v-model="form.postType"
                placeholder="请选择"
                v-if="!isShow"
              >
                <el-option label="员工" :value="0" />
                <el-option label="部门主管" :value="1" />
              </el-select>
              <span v-else>
                {{ form.postType === 0 ? '员工' : '部门主管' }}
              </span>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="员工状态" prop="status">
            <el-radio-group
              v-model="form.status"
              @change="statusChange"
              v-if="!isShow"
            >
              <el-radio :label="1">在职</el-radio>
              <el-radio :label="2" v-show="form.status === 2">离职</el-radio>
            </el-radio-group>
            <span v-else>{{ form.status === 1 ? '在职' : '离职' }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="岗位" prop="station">
            <el-input
              v-model="form.station"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else-if="form.station">{{ form.station }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="isDimission">
        <el-col :span="12">
          <el-form-item label="离职时间" prop="leaveTime">
            <span v-if="form.leaveTime">{{ form.leaveTime }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="工号" prop="jobNumber">
            <el-input
              v-model="form.jobNumber"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else-if="form.jobNumber">{{ form.jobNumber }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入职时间" prop="joinTime">
            <el-date-picker
              v-model="form.joinTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="年/月/日"
              v-if="!isShow"
            ></el-date-picker>
            <span v-else>{{ form.joinTime }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="生日" prop="birthday">
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="form.birthday"
              type="date"
              placeholder="年/月/日"
              value-format="yyyy-MM-dd"
              v-if="!isShow"
            ></el-date-picker>
            <span v-else-if="form.birthday">{{ form.birthday }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年龄" prop="age">
            <el-input
              readonly
              type="Number"
              v-model="form.age"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else-if="form.age">{{ form.age }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择" v-if="!isShow">
              <el-option label="男" value="男" />
              <el-option label="女" value="女" />
            </el-select>
            <span v-else-if="form.sex">{{ form.sex }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号码" prop="idCard">
            <el-input
              v-model="form.idCard"
              :maxlength="18"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else-if="form.idCard">{{ form.idCard }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="职级" prop="rank">
            <el-input
              v-model="form.rank"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else-if="form.rank">{{ form.rank || '未填写' }}</span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用工性质" prop="natureEmployment">
            <el-input
              v-model="form.natureEmployment"
              placeholder="请输入"
              v-if="!isShow"
            ></el-input>
            <span v-else-if="form.natureEmployment">
              {{ form.natureEmployment }}
            </span>
            <el-row v-else class="no-text">未填写</el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-show="isShow">
        <el-col :span="12">
          <el-form-item label="密码" prop="rank">
            <span>******</span>
            <el-button
              v-allowed="['Reset:Password']"
              @click="resetPassword"
              size="mini"
              class="ml10"
              type="primary"
            >
              重置密码
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="operate">
      <el-button
        type="primary"
        :loading="loading"
        @click="searchClick"
        v-if="!isShow"
      >
        保存
      </el-button>
      <el-button @click="closeClick">取消</el-button>
    </div>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    <!-- 重置密码 -->
    <reset-password ref="resetPassword" :userForm="form"></reset-password>
  </div>
</template>

<script>
  import { systemInteractor } from '@/core'
  import { userGetDetail, userUpdatePO } from '@/api/organization-user'
  import { matchPosition, matchStaffStatus } from '@/utils/en-match-zh'
  import { isIdCard, isEmail } from '@/utils/validate'
  import UserChoose from '@/components/userChoose'
  import ResetPassword from './reset-password.vue'
  import { OrganizationManageInteractor } from '@/core'
  export default {
    name: 'PersonShowModel',
    components: {
      UserChoose,
      ResetPassword,
    },
    props: {
      userId: {
        type: String,
        required: false,
      },
      isShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() //选当前时间之前的时间
          },
        },
        categoryOptions: [], //产品类别
        form: {},
        belongerName: '', //归属权转让人姓名
        rules: {
          loginName: [
            { required: true, message: '请输入登录名', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
          userName: [
            { required: true, message: '请输入中文名', trigger: 'blur' },
          ],
          englishName: [
            { required: true, message: '请输入英文名', trigger: 'blur' },
          ],
          deptId: [
            { required: true, message: '请选择所属组织', trigger: 'blur' },
          ],
          email: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入正确邮箱',
            },
            {
              validator: this.$formValidation.isEmail,
              trigger: 'blur',
            },
          ],
          telephone: [
            {
              required: true,
              message: '请输入正确联系电话',
              trigger: 'blur',
              min: 11,
            },
          ],
          postType: [
            { required: true, message: '请选择职位', trigger: 'blur' },
          ],
          leaveTime: [
            { required: true, message: '请选择离职时间', trigger: 'change' },
          ],
        },
        daterange: [],
        edit: false,
        jobList: [], //职位下拉
        deptId: [], //组织
        optionProps: {
          value: 'deptId',
          label: 'name',
          children: 'children',
          checkStrictly: true,
        },
        treeData: [],
        isDimission: false, //是否离职
        dimissionUser: [], //离职归属人
      }
    },
    watch: {
      userId: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.pubApi.getListDepartmrnt().then((response) => {
            if (response.code === '000000') {
              this.treeData = response.data
            }
          })
          this.getUserGetDetail(value)
        },
      },
      'form.birthday': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.getAge(value)
        },
      },
    },
    created() {},
    methods: {
      textareablur(val) {
        if (!val) return
        let reg = new RegExp('^[A-Z0-9][A-z0-9]*$')
        if (!reg.test(val)) {
          this.form.englishName = val
            .replace(/[^\sA-Za-z0-9]+/g, '')
            .replace(/\b\w+\b/g, (word) => {
              return word.substring(0, 1).toUpperCase() + word.substring(1)
            })
        }
      },

      // 计算年龄
      getAge() {
        let birthdays = new Date(this.form.birthday.replace(/-/g, '/'))
        let d = new Date()
        let age =
          d.getFullYear() -
          birthdays.getFullYear() -
          (d.getMonth() < birthdays.getMonth() ||
          (d.getMonth() == birthdays.getMonth() &&
            d.getDate() < birthdays.getDate())
            ? 1
            : 0)
        this.$set(this.form, 'age', age)
      },

      // 员工状态
      statusChange(val) {
        val === 2 ? (this.isDimission = true) : (this.isDimission = false)
      },

      //离职转让归属权点击
      selectSupplierClick() {
        this.$refs['UserChoose'].showAddEdit('belongerUser', this.dimissionUser)
      },

      //人员组织架构返回
      chooseUser(checkedUser) {
        this.dimissionUser = checkedUser
        this.form.belongerId = checkedUser[0].id
        this.form.belongerName = checkedUser[0].name
      },

      //获取用户详情
      async getUserGetDetail(userId) {
        let response = await userGetDetail({ userId: userId })
        if (response.code === '000000') {
          this.form = response.data

          if (this.form.status === 2) {
            this.isDimission = true
          }
          if (this.form.belongerId && this.form.belongerName) {
            this.dimissionUser = [
              { id: this.form.belongerId, name: this.form.belongerName },
            ]
          }
        }
        //获取职位下拉
        this.pubApi.getJobList().then((response) => {
          if (response.code === '000000') {
            this.jobList = response.data
          }
        })
      },

      //编辑保存按钮
      async searchClick() {
        let form = JSON.parse(JSON.stringify(this.form))
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            if (form.email && !isEmail(form.email)) {
              return this.$baseMessage(
                '请输入正确的邮箱',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            if (form.idCard && !isIdCard(form.idCard)) {
              return this.$baseMessage(
                '请输入正确的身份证号',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }

            if (form.status === 1) {
              delete form.leaveTime
              delete form.belongerId
            }

            form.createTimeMin =
              this.daterange.length === 2 ? this.daterange[0] : ''
            form.createTimeMax =
              this.daterange.length === 2 ? this.daterange[1] : ''
            if (form.categoryId) {
              form.categoryId = form.categoryId.pop()
            }
            form.age = Number(form.age)
            delete form.password

            let flag = Array.isArray(form.deptId)
            if (flag) {
              form.deptId = [...form.deptId].pop()
            }
            if (!form.beforeDeptId || form.beforeDeptId === '') {
              form.beforeDeptId = form.deptId
            }
            this.loading = true
            let response = await userUpdatePO(form)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                '操作成功',
                'success',
                false,
                'erp-hey-message-success'
              )
              this.$emit('closeClick2')
            }
          }
        })
      },

      // 取消
      closeClick() {
        this.edit = false
        this.editText = '编辑'
        this.$emit('closeClick')
      },

      //职位匹配
      matchPosition(val) {
        return matchPosition(val)
      },

      //在职状态匹配
      matchStaffStatus(val) {
        return matchStaffStatus(val)
      },

      // 重置密码
      async resetPassword() {
        // this.$refs.resetPassword.init(this.form.userId)
        const res = await systemInteractor.resetUserPassWord(
          this.userId,
          'wewo1234'
        )
        if (res && res.code === '000000') {
          this.$message.success('重置密码成功！')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search-form {
    overflow-y: auto;
    margin-bottom: 80px;
    .search-form-content {
      padding: 0 20px;
      .el-form-item {
        span {
          color: #000;
        }
      }
    }
    .operate {
      position: fixed;
      width: 100%;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #ededed;
      padding: 20px;
    }
  }
  .no-text {
    color: #9e9e9e;
  }
  .search_form .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  ::v-deep {
    .el-date-editor {
      width: 100%;
    }
    .el-divider {
      margin: 30px 0 28px 0 !important;
    }
    .el-drawer__body {
      height: calc(100% - 44px);
    }
    .el-form-item--small.el-form-item {
      margin-bottom: 15px;
    }
  }
</style>
