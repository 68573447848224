<!-- 废弃页面 前端不开放入口 -->
<template>
  <el-dialog
    title="库存锁定"
    width="1200px"
    class="custom-dialog"
    :visible.sync="stockVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form class="container" :model="form" ref="ruleForm">
      <el-table
        class="input-table"
        :data="form.stockLockRecordDTOS"
        border
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        :header-cell-class-name="star"
      >
        <el-table-column type="index" label="#" width="55"></el-table-column>
        <el-table-column label="销售订单" width="120">
          <template slot-scope="scope">
            <router-link
              target="_blank"
              :to="`/order/orderList/orderDetail?orderCode=${
                scope.row.salesOrderNumber
              }&noReturn=${true}`"
            >
              <span class="page-link">{{ scope.row.salesOrderNumber }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="产品编码" width="120">
          <template slot-scope="scope">
            {{ scope.row.sku }}
          </template>
        </el-table-column>
        <el-table-column label="产品名称" width="180">
          <template slot-scope="scope">
            {{ scope.row.nameCn }}
            <span>/&nbsp;{{ scope.row.specsValue1 }}</span>
            <span v-show="scope.row.specsValue2">
              ;{{ scope.row.specsValue2 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="产品类型" width="120">
          <template slot-scope="scope">
            {{ scope.row.standardCustomized }}
          </template>
        </el-table-column>
        <el-table-column label="是否需要印刷" width="120">
          <template slot-scope="scope">
            {{ scope.row.printingWay.toUpperCase() === 'BLANK' ? '否' : '是' }}
          </template>
        </el-table-column>
        <el-table-column label="需求数量" width="120">
          <template slot-scope="scope">
            {{ scope.row.numDemand }}
          </template>
        </el-table-column>
        <el-table-column label="出库数量" width="120">
          <template slot-scope="scope">
            {{ scope.row.warehouseOutNum }}
          </template>
        </el-table-column>
        <el-table-column label="已锁定数量" width="120">
          <template slot-scope="scope">
            {{ scope.row.lockNum }}
          </template>
        </el-table-column>
        <el-table-column label="待锁定数量" width="120">
          <template slot-scope="scope">
            {{ scope.row.waitingLockNum }}
          </template>
        </el-table-column>
        <el-table-column label="库存可用量" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.stockAvailableNum.length">
              <p v-for="(item, i) in scope.row.stockAvailableNum" :key="i">
                {{ item.warehouseName }}：{{ item.num }}
              </p>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="锁定仓库" width="160" fixed="right">
          <template slot-scope="scope">
            <el-form-item
              :prop="'stockLockRecordDTOS.' + scope.$index + '.warehouseId'"
              :rules="[
                {
                  required: true,
                  message: '请选择锁定仓库',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                placeholder="请选择"
                clearable
                @change="stockChange(scope.$index)"
                v-model="form.stockLockRecordDTOS[scope.$index].warehouseId"
              >
                <el-option
                  v-for="item in delStockLock(scope.row.stockAvailableNum)"
                  :key="item.warehouseId"
                  :label="item.warehouseName"
                  :value="item.warehouseId"
                />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="锁定数量" width="130" fixed="right">
          <template slot-scope="scope">
            <el-form-item
              :prop="'stockLockRecordDTOS.' + scope.$index + '.num'"
              :rules="[
                {
                  required: true,
                  message: '锁定数量必填',
                  trigger: 'change',
                },
                {
                  pattern: /^[1-9]\d*$/,
                  message: '请输入>0的整数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                maxlength="10"
                :disabled="scope.row.warehouseId ? false : true"
                @change="stockNum(scope.$index)"
                v-model="form.stockLockRecordDTOS[scope.$index].num"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              class="icon-r"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  listPurchaseStockLockPre,
  stockLockRecordInsertPO,
  getWarehouseSettingValue,
} from '@/api/order'
export default {
  data() {
    return {
      form: {
        stockLockRecordDTOS: [], // 库存列表
      },
      stockVisible: false,
    }
  },
  methods: {
    // 初始化加载
    initLoading(params) {
      let self = this
      listPurchaseStockLockPre(params).then((res) => {
        if (res && res.code == '000000') {
          self.form.stockLockRecordDTOS = res.data.map((item) => {
            return {
              ...item,
              num: '', // 锁定数量
              warehouseId: '', // 仓库Id
              warehouseName: '', // 仓库名称
              warehouseNum: '', // 仓库数量
              maxNum: item.waitingLockNum, // 锁定数量最大可输入的值
            }
          })
          self.stockVisible = true
        }
      })
    },

    // 删掉虚拟仓
    delStockLock(list) {
      let stockList = []
      list.forEach((item) => {
        if (item.warehouseName != '虚拟仓库') {
          stockList.push(item)
        }
      })
      return stockList
    },

    // 选择仓库
    stockChange(i) {
      let list = this.form.stockLockRecordDTOS[i]

      if (list.warehouseId) {
        const params = {
          type: 3,
          warehouseId: list.warehouseId,
        }
        // 赋值仓库名称
        list.stockAvailableNum.find((item) => {
          if (item.warehouseId == list.warehouseId) {
            list.warehouseName = item.warehouseName
            list.warehouseNum = item.num
          }
        })
        getWarehouseSettingValue(params).then((res) => {
          if (res.data?.value) {
            list.maxNum = Math.floor(
              res.data.value * 0.01 * list.waitingLockNum + list.waitingLockNum
            )
          } else {
            list.maxNum = list.waitingLockNum
          }
        })
      } else {
        list.num = ''
      }
    },

    // 锁定数量
    stockNum(i) {
      let list = this.form.stockLockRecordDTOS[i]
      if (list.num > list.warehouseNum) {
        this.$message.warning('锁定数量不能超过库存数量！')
        list.num = list.warehouseNum
      }
      if (list.num > list.maxNum) {
        this.$message.warning('锁定数量超出上限！')
        list.num = list.maxNum
      }
    },

    // 保存
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 保存接口
          const params = this.form.stockLockRecordDTOS
          stockLockRecordInsertPO(params).then((res) => {
            if (res && res.code == '000000') {
              this.$message.success('保存成功')
              this.$parent.initLoading(1)
              this.close()
            }
          })
        } else {
          return false
        }
      })
    },

    // 删除产品
    del(i) {
      if (this.form.stockLockRecordDTOS.length == 1) {
        this.$message.warning('至少保留一条数据！')
        return false
      }
      this.$confirm(`是否确认删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.form.stockLockRecordDTOS.splice(i, 1)
          this.$message.success('删除成功！')
        })
        .catch(() => {})
    },

    // table添加必填样式
    star({ row, rowIndex, column, columnIndex }) {
      if (columnIndex == 11 || columnIndex == 12) {
        return 'star'
      }
    },

    // 关闭弹窗重置表单
    close() {
      this.$refs.ruleForm.resetFields()
      this.form = this.$options.data().form
      this.stockVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  .container {
    min-height: 300px;
    .el-form-item {
      margin-bottom: 18px;
    }
    p {
      text-align: left;
      line-height: 24px;
    }
  }
}
</style>
