<!--
  功能：人员提成详情
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年04月18日 14:27:06
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-dialog
    title="查看详情"
    :visible.sync="dialogFormVisible"
    width="1291px"
    :close-on-click-modal="false"
  >
    <el-table :data="tableData" border stripe max-height="400px">
      <el-table-column type="index" align="center" width="50"></el-table-column>
      <el-table-column
        align="center"
        width="110"
        label="结算单号"
        prop="settlementOrder"
      ></el-table-column>
      <el-table-column
        align="center"
        width="110"
        label="结算时间"
        prop="settlementDate"
      ></el-table-column>
      <el-table-column
        align="center"
        width="100"
        label="结算专员"
        prop="settlementAttach"
      >
        <template slot-scope="scope">
          {{ scope.row.settlementAttach ? scope.row.settlementAttach : '-' }}
        </template>
      </el-table-column>
      <el-table-column type="index" align="center" width="130" label="订单编号">
        <template slot-scope="scope">
          <span
            v-if="scope.row.salesOrder"
            class="page-link"
            @click="showOrderDetail(scope.row)"
          >
            {{ scope.row.salesOrder }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="100"
        label="订单月份"
        prop="orderCreateTime"
      ></el-table-column>
      <el-table-column
        align="center"
        width="100"
        label="客户代表"
        show-overflow-tooltip
        prop="businessName"
      ></el-table-column>
      <el-table-column
        align="center"
        width="100"
        label="地区"
        prop="businessArea"
      ></el-table-column>
      <el-table-column
        align="center"
        width="100"
        label="公司名称"
        prop="company"
      ></el-table-column>
      <el-table-column align="center" width="200" label="产品信息" prop="sku">
        <template slot-scope="scope">
          <div class="product-info">
            <p class="left">
              <span class="pro-item">
                {{ scope.row.sku }}
              </span>
              <span class="pro-item">
                {{ scope.row.productNameCn }}
              </span>
            </p>
            <p>
              <!-- 产品类型 -->
              <el-tag v-if="scope.row.productStandardCustomized">
                {{ scope.row.productStandardCustomized }}
              </el-tag>
              <el-tag type="warning" v-if="scope.row.productionLine">
                {{ scope.row.productionLine }}
              </el-tag>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="150"
        :label="label"
        prop="commission"
      >
        <template slot-scope="scope">
          {{
            scope.row.commission || scope.row.commission === 0
              ? scope.row.commission.toFixed(3)
              : '--'
          }}
        </template>
      </el-table-column>
    </el-table>
    <p class="mt15 tr">合计: {{ total }}</p>
    <p class="button-wrapper mt15">
      <el-button plain @click="dialogFormVisible = false">关 闭</el-button>
    </p>
  </el-dialog>
</template>

<script>
import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

export default {
  name: 'PersonnelCommissionDetails',
  props: {},
  components: {},

  // 组件状态值
  data() {
    return {
      dialogFormVisible: false,
      tableData: [],
      type: 1,
    }
  },

  computed: {
    label() {
      const type2lable = {
        1: '客户代表提成(￥)',
        2: '产品运营提成(￥)',
        3: '采购开发提成(￥)',
        4: '订单支持提成(￥)',
        5: '客户代表管理提成(￥)',
        6: '产品运营管理提成(￥)',
        7: '采购员管理提成(￥)',
        8: '订单支持管理提成(￥)',
      }
      return type2lable[this.type]
    },

    total() {
      let sum = 0
      if (this.tableData.length === 0) {
        return sum.toFixed(3)
      } else {
        sum = this.tableData
          .map((i) => i.commission * 1000)
          .reduce((prev, next) => {
            return prev + next
          })
        sum = sum / 1000
        return sum.toFixed(3)
      }
    },
  },
  methods: {
    showAddAndEdit(tableData, type) {
      this.tableData = tableData
      this.type = type
      this.dialogFormVisible = true
    },

    showOrderDetail({ salesOrder }) {
      navigateTo(this, {
        pageType: PAGE_TYPES.salesOrderDetail,
        orderNumber: salesOrder,
        roleJudge: false,
      })
    },
  },
}
</script> 

<style scoped lang="scss">
.tr {
  text-align: right;
}
.button-wrapper {
  text-align: center;
}
.product-info {
  display: flex;
  padding: 10px;
  text-align: left;
  .pro-item {
    width: 100%;
    white-space: pre-wrap;
    margin-bottom: 5px;
  }
  .left {
    flex: 1;
  }
  p {
    display: flex;
    flex-direction: column;
    .el-tag {
      margin-bottom: 5px;
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
