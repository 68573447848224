<template>
  <div class="search_form">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="销售订单编号" prop="salesOrderNumber">
        <el-input
          v-model="form.salesOrderNumber"
          placeholder="请输入"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="供应商名称" prop="supplierName">
        <el-input
          v-model="form.supplierName"
          placeholder="请输入"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="是否协议供应商" prop="isAgreement">
        <el-select
          style="width: 100%"
          placeholder="请选择"
          v-model="form.isAgreement"
          filterable
          clearable
        >
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款账户" prop="supplierInfo">
        <el-input
          v-model="form.supplierInfo"
          placeholder="请输入"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="产品编码/产品名称" prop="productInfo">
        <el-input
          v-model="form.productInfo"
          placeholder="请输入"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="买方" prop="purchaser">
        <el-select
          style="width: 100%"
          placeholder="请选择"
          v-model="form.purchaser"
          filterable
          clearable
        >
          <el-option
            v-for="item in financeList"
            :key="item.account"
            :label="item.financeName"
            :value="item.financeName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="采购总量">
        <div class="line_input">
          <el-form-item prop="startProductNum">
            <el-input
              v-model="form.startProductNum"
              maxlength="12"
              placeholder="请输入"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
          <span>至</span>
          <el-form-item prop="endProductNum">
            <el-input
              v-model="form.endProductNum"
              maxlength="12"
              placeholder="请输入"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="订单总金额">
        <div class="line_input">
          <el-form-item prop="startTotalPrice">
            <el-input
              v-model="form.startTotalPrice"
              maxlength="12"
              placeholder="请输入"
              clearable
              :disabled="!isViewCost"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
          <span>至</span>
          <el-form-item prop="endTotalPrice">
            <el-input
              v-model="form.endTotalPrice"
              maxlength="12"
              placeholder="请输入"
              clearable
              :disabled="!isViewCost"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="未付金额">
        <div class="line_input">
          <el-form-item prop="startUnpaidPrice">
            <el-input
              v-model="form.startUnpaidPrice"
              maxlength="12"
              placeholder="请输入"
              clearable
              :disabled="!isViewCost"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
          <span>至</span>
          <el-form-item prop="endUnpaidPrice">
            <el-input
              v-model="form.endUnpaidPrice"
              maxlength="12"
              placeholder="请输入"
              clearable
              :disabled="!isViewCost"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="未开票金额">
        <div class="line_input">
          <el-form-item prop="startUnInvoicePrice">
            <el-input
              v-model="form.startUnInvoicePrice"
              maxlength="12"
              placeholder="请输入"
              clearable
              :disabled="!isViewCost"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
          <span>至</span>
          <el-form-item prop="endUnInvoicePrice">
            <el-input
              v-model="form.endUnInvoicePrice"
              maxlength="12"
              placeholder="请输入"
              clearable
              :disabled="!isViewCost"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="交货日期" prop="completeDate">
        <el-date-picker
          v-model="completeDate"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="最后入库日期" prop="warehouseInDate">
        <el-date-picker
          v-model="warehouseInDate"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="采购开发">
        <el-input
          clearable
          v-model="businessName"
          placeholder="请选择"
          @focus="selectUserClick('business')"
          @change="businessNameChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户代表">
        <el-input
          clearable
          v-model="fromBusinessName"
          placeholder="请选择"
          @focus="selectUserClick('fromBusiness')"
          @change="fromBusinessNameChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="审核通过日期" prop="auditTime">
        <el-date-picker
          v-model="auditTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="创建人" prop="companyName">
        <el-input
          clearable
          v-model="creatorName"
          placeholder="请选择"
          @focus="selectUserClick('creatorName')"
          @change="creatorChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>

      <div class="btn">
        <el-button type="primary" @click="searchClick">确定</el-button>
        <el-button @click="resetClick">重置</el-button>
      </div>
    </el-form>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import { CreditManageInteractor } from '@/core'
  import UserChoose from '@/components/userChoose2'
  import viewCost from '../utils'
  export default {
    name: 'orderSearch',
    components: {
      UserChoose,
    },
    data() {
      return {
        isViewCost: viewCost(),
        businessName: '', // 客户代表名称
        businessNameId: [], // 客户代表id
        fromBusinessName: '', // 订单支持名称
        fromBusinessNameId: [], // 订单支持id
        creatorName: '', //创建人
        creatorId: [], //创建人id
        isUserChoose: '', //当前组织架构是谁调用
        auditTime: [], // 审核通过日期
        createTime: [], // 创建时间
        completeDate: [], // 交货日期
        warehouseInDate: [], //最后入库日期
        form: {},
        daterange: [],
        financeList: [],
        rules: {},
      }
    },
    watch: {
      // 创建时间
      createTime(dateArray) {
        if (dateArray) {
          this.form.startCreateTime = dateArray[0]
          this.form.endCreateTime = dateArray[1]
        } else {
          this.form.startCreateTime = ''
          this.form.endCreateTime = ''
        }
      },
      // 交货日期
      completeDate(dateArray) {
        if (dateArray) {
          this.form.startCompleteDate = dateArray[0]
          this.form.endCompleteDate = dateArray[1]
        } else {
          this.form.startCompleteDate = ''
          this.form.endCompleteDate = ''
        }
      },
      // 最后入库日期
      warehouseInDate(dateArray) {
        if (dateArray) {
          this.form.startWarehouseInDate = dateArray[0]
          this.form.endWarehouseInDate = dateArray[1]
        } else {
          this.form.startWarehouseInDate = ''
          this.form.endWarehouseInDate = ''
        }
      },
      // 审核通过日期
      auditTime(dateArray) {
        if (dateArray) {
          this.form.startAuditTime = dateArray[0]
          this.form.endAuditTime = dateArray[1]
        } else {
          this.form.startAuditTime = ''
          this.form.endAuditTime = ''
        }
      },
    },
    created() {
      this.getDict()
    },
    methods: {
      // 获取买方字典
      getDict() {
        CreditManageInteractor.selectOptions({ financeType: 2 }).then((res) => {
          this.financeList = res.data
        })
      },

      // 采购开发，客户代表，创建人选择
      selectUserClick(str) {
        this.isUserChoose = str
        if (str === 'business') {
          this.$refs['UserChoose'].showAddEdit(
            'listSearch',
            this.businessNameId
          )
        } else if (str == 'fromBusiness') {
          this.$refs['UserChoose'].showAddEdit(
            'listSearch',
            this.fromBusinessNameId
          )
        } else {
          this.$refs['UserChoose'].showAddEdit('listSearch', this.creatorId)
        }
      },

      // 获取客户代表
      chooseUser(checkedUser) {
        if (this.isUserChoose === 'business') {
          this.businessNameChange()
        } else if (this.isUserChoose === 'fromBusiness') {
          this.fromBusinessNameChange()
        } else {
          this.creatorChange()
        }

        if (!checkedUser.length) {
          return
        }
        let userName = []
        checkedUser.forEach((item) => {
          userName.push(item.name)
          if (this.isUserChoose === 'business') {
            this.businessName = userName.join(',')
            this.businessNameId.push(item.id)
          } else if (this.isUserChoose === 'fromBusiness') {
            this.fromBusinessName = userName.join(',')
            this.fromBusinessNameId.push(item.id)
          } else {
            this.creatorName = userName.join(',')
            this.creatorId.push(item.id)
          }
        })
      },

      // 查询
      searchClick() {
        let self = this
        self.$refs.form.validate((valid) => {
          if (valid) {
            if (self.businessNameId) {
              self.form.buyerId = self.businessNameId.join(',')
            }
            if (self.fromBusinessNameId) {
              self.form.businessId = self.fromBusinessNameId.join(',')
            }
            if (self.creatorId) {
              self.form.creatorId = self.creatorId.join(',')
            }
            self.$emit('searchClick', self.form)
          }
        })
      },

      // 重置
      resetClick() {
        this.createTime = []
        this.completeDate = []
        this.warehouseInDate = []
        this.auditTime = []
        this.businessNameChange()
        this.fromBusinessNameChange()
        this.creatorChange()
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
      },

      // 删除客户代表
      businessNameChange() {
        this.businessNameId = []
        this.businessName = ''
        this.form.buyerId = ''
      },

      // 删除订单支持
      fromBusinessNameChange() {
        this.fromBusinessNameId = []
        this.fromBusinessName = ''
        this.form.businessId = ''
      },

      // 删除创建人
      creatorChange() {
        this.creatorId = []
        this.creatorName = ''
        this.form.creatorId = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search_form {
    padding: 0 20px 80px 20px;
    .el-date-editor--datetimerange.el-input__inner {
      width: 100%;
    }
    .btn {
      padding: 20px 0;
      width: 100%;
      position: fixed;
      bottom: 0;
      background: white;
    }
  }
  ::v-deep {
    .el-input--suffix {
      padding-right: 5px !important;
    }
    .el-cascader {
      width: 100%;
    }
    .el-date-editor {
      width: 100%;
    }
    .el-divider {
      margin: 70px 0 28px 0 !important;
    }
  }
</style>
