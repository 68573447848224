<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="500px"
  >
    <template slot="title">
      <i class="el-icon-warning" />
      {{
        lang === 'en'
          ? 'Please re-select the following products'
          : '请重新选择以下产品'
      }}
    </template>
    <el-table :data="list" max-height="500">
      <el-table-column :label="lang === 'en' ? 'Item No.' : '产品编码'">
        <template slot-scope="scope">
          {{ scope.row.productCode || '--' }}
        </template>
      </el-table-column>
      <el-table-column :label="lang === 'en' ? 'Description' : '描述'">
        {{
          lang === 'en'
            ? 'This product has not been approved'
            : '此产品未审核通过'
        }}
      </el-table-column>
    </el-table>
    <template slot="footer">
      <el-button type="primary" @click="handleConfirm">
        {{ lang === 'en' ? 'Confirm' : '确 定' }}
      </el-button>
    </template>
  </el-dialog>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      value: { type: Boolean, default: false },
      tableData: { type: Array, default: () => [] },
    },
    computed: {
      dialogVisible: {
        get() {
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },
      ...mapGetters({ lang: 'settings/language' }),
      list() {
        const noRepeta = (arr, property) => {
          const map = new Map()
          for (const item of arr) {
            if (!map.has(item[property])) map.set(item[property], item)
          }
          return [...map.values()]
        }
        return noRepeta(this.tableData, 'productCode')
      },
    },
    methods: {
      handleConfirm() {
        this.handleClose()
      },
      handleClose() {
        this.dialogVisible = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-dialog__wrapper {
    /deep/ .el-dialog__header {
      display: flex;
      align-items: center;
      line-height: 24px;
      font-size: 18px;
      color: #303133;
      .el-icon-warning {
        margin-right: 10px;
        color: #ffba00;
        font-size: 24px;
      }
    }
  }
</style>
