<template>
  <div class="record-drawer">
    <div class="container">
      <div class="top">
        <div>
          <h1>{{ numArray.enquiryNum || 0 }}</h1>
          <!-- 询盘数 -->
          <p>{{ $t('inquiryDetails.NumberOfEnquiries') }}</p>
        </div>
        <div>
          <h1>{{ numArray.orderNum || 0 }}</h1>
          <!-- 订单成交量 -->
          <p>{{ $t('inquiryDetails.OrderVolume') }}</p>
        </div>
        <div>
          <h1>
            {{ numArray.orderPriceNum ? numArray.orderPriceNum.toFixed(3) : 0 }}
          </h1>
          <!-- 订单总金额 -->
          <p>{{ $t('inquiryDetails.TotalOrderAmount') }}</p>
        </div>
      </div>

      <el-tabs
        class="mt20"
        v-model="active"
        type="card"
        @tab-click="handleClick"
      >
        <!-- 询盘记录 -->
        <el-tab-pane :label="$t('inquiryDetails.InquiryRecords')" name="1">
          <ErpTable
            :page-number.sync="page1.pageNo"
            :page-size.sync="page1.pageLe"
            :table-data="tableData1"
            :total="total1"
            @query="getList1"
            :extraHeight="150"
            class="table-fixed"
          >
            <el-table-column align="center" type="index" width="55" />
            <!-- 询盘单号 -->
            <el-table-column
              prop="date"
              :label="$t('inquiryDetails.InquiryNumber')"
              width="150"
              align="center"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <router-link
                  :to="`/order/inquiryManage/inquiryDetail?enquiryId=${
                    row.enquiryId
                  }&noReturn=${true}`"
                  target="_blank"
                >
                  {{ row.enquiryCode }}
                </router-link>
              </template>
            </el-table-column>
            <!-- 产品编号 -->
            <el-table-column
              prop="productCode"
              :label="$t('inquiryDetails.ProductCode')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 产品英文名称 -->
            <el-table-column
              prop="productEn"
              :label="$t('inquiryDetails.ProductEnglishName')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 询盘数量 -->
            <el-table-column
              prop="amount"
              :label="$t('inquiryDetails.InquiryQuantity')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 询盘状态 -->
            <el-table-column
              prop="enquiryStatus"
              :label="$t('inquiryDetails.InquiryStatus')"
              align="center"
            >
              <template #default="{ row }">
                <!-- 已作废 -->
                <el-tag v-if="row.enquiryStatus == 1" type="info">
                  {{ $t('inquiryList.Invalid') }}
                </el-tag>
                <!-- 询盘失败 -->
                <el-tag v-else-if="row.enquiryStatus == 2" type="info">
                  {{ $t('inquiryList.InquiryFailed') }}
                </el-tag>
                <!-- 已完成 -->
                <el-tag v-else-if="row.enquiryStatus == 3" type="success">
                  {{ $t('inquiryList.DoneManually') }}
                </el-tag>
                <!-- 待报价 -->
                <el-tag v-else-if="row.enquiryStatus == 4" type="danger">
                  {{ $t('inquiryList.PendingQuotation') }}
                </el-tag>
                <!-- 已报价 -->
                <el-tag v-else-if="row.enquiryStatus == 5">
                  {{ $t('inquiryList.Quoted') }}
                </el-tag>
                <!-- 已下单 -->
                <el-tag v-else-if="row.enquiryStatus == 6">
                  {{ $t('inquiryList.Ordered') }}
                </el-tag>
              </template>
            </el-table-column>
            <!-- 询盘日期 -->
            <el-table-column
              prop="createTime"
              :label="$t('inquiryDetails.InquiryDate')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 客户代表 -->
            <el-table-column
              prop="businessName"
              :label="$t('inquiryDetails.CustomerRepresentative')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  lang === 'en'
                    ? scope.row.businessNameEn
                    : scope.row.businessName
                }}
              </template>
            </el-table-column>
          </ErpTable>
        </el-tab-pane>
        <!-- 订单记录 -->
        <el-tab-pane :label="$t('inquiryDetails.OrderRecords')" name="2">
          <ErpTable
            :page-number.sync="page2.pageNo"
            :page-size.sync="page2.pageLe"
            :table-data="tableData2"
            :total="total2"
            @query="getList2"
            :extraHeight="150"
            class="table-fixed"
          >
            <el-table-column align="center" type="index" width="55" />
            <!-- 订单编号 -->
            <el-table-column
              prop="date"
              :label="$t('inquiryDetails.OrderNumber')"
              width="150"
              align="center"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <router-link
                  :to="`/order/orderList/orderDetail?orderCode=${
                    row.orderCode
                  }&noReturn=${true}`"
                  target="_blank"
                >
                  {{ row.orderCode }}
                </router-link>
              </template>
            </el-table-column>
            <!-- 产品编号 -->
            <el-table-column
              prop="productCode"
              :label="$t('inquiryDetails.ProductCode')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 产品英文名称 -->
            <el-table-column
              prop="productEn"
              :label="$t('inquiryDetails.ProductEnglishName')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 订单金额 -->
            <el-table-column
              prop="totlePrice"
              :label="$t('inquiryDetails.OrderAmount')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 订单状态 -->
            <el-table-column
              prop="orderStatus"
              :label="$t('inquiryDetails.OrderStatus')"
              align="center"
            >
              <template #default="{ row }">
                <el-tag v-if="row.orderStatus == 1">
                  {{ lang === 'en' ? 'In the entry' : '录入中' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 2" type="danger">
                  {{ lang === 'en' ? 'Has been rejected' : '已驳回' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 3" type="danger">
                  {{ lang === 'en' ? 'Had withdrawn' : '已撤销' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 4" type="primary">
                  {{ lang === 'en' ? 'under review' : '审核中' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 5" type="success">
                  {{ lang === 'en' ? 'passed' : '已通过' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 6" type="warning">
                  {{ lang === 'en' ? 'To send the goods' : '待发货' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 8" type="warning">
                  {{ lang === 'en' ? 'wait for receiving ' : '待收货' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 9" type="warning">
                  {{ lang === 'en' ? 'received ' : '已收货' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 10" type="info">
                  {{ lang === 'en' ? 'finished' : '已完成' }}
                </el-tag>
                <el-tag v-else-if="row.orderStatus == 11" type="info">
                  {{ lang === 'en' ? 'have been voided' : '已作废' }}
                </el-tag>
                <el-tag v-else>/</el-tag>
              </template>
            </el-table-column>
            <!-- 下单日期 -->
            <el-table-column
              prop="createTime"
              :label="$t('inquiryDetails.OrderDate')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 客户代表 -->
            <el-table-column
              prop="businessName"
              :label="$t('inquiryDetails.CustomerRepresentative')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  lang === 'en'
                    ? scope.row.businessNameEn
                    : scope.row.businessName
                }}
              </template>
            </el-table-column>
          </ErpTable>
        </el-tab-pane>
        <!-- 邮件记录 -->
        <el-tab-pane :label="$t('inquiryDetails.MailRecords')" name="3">
          <ErpTable
            :page-number.sync="page3.pageNo"
            :page-size.sync="page3.pageSize"
            :table-data="tableData3"
            :total="total3"
            @query="getList3"
            :extraHeight="150"
            class="table-fixed"
          >
            <el-table-column align="center" type="index" width="55" label="#" />
            <!-- 邮件时间 -->
            <el-table-column
              prop="sendTime"
              :label="$t('inquiryDetails.MailTime')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 发件人 -->
            <el-table-column
              prop="sendEmail"
              :label="$t('inquiryDetails.Sender')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.sendEmail || '--' }}
              </template>
            </el-table-column>
            <!-- 主题 -->
            <el-table-column
              prop="title"
              :label="$t('inquiryDetails.Topic')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 收件人 -->
            <el-table-column
              prop="receiveEmails"
              :label="$t('inquiryDetails.Recipient')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.receiveEmails || '--' }}
              </template>
            </el-table-column>
          </ErpTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="btn text-right">
      <!-- 关闭 -->
      <el-button @click="close">{{ $t('inquiryDetails.Close') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  orderListEmail,
  listEmailEnquiryPage,
  listEnquiryOderVOPage,
  emailPageList,
} from '@/api/order'
export default {
  props: ['clientEmail'],
  data() {
    return {
      numArray: [],
      active: '1',
      tableData1: [],
      tableData2: [],
      tableData3: [],
      page1: {
        pageNo: 1,
        pageLe: 10,
      },
      page2: {
        pageNo: 1,
        pageLe: 10,
      },
      page3: {
        pageNo: 1,
        pageSize: 10,
      },
      total1: 0,
      total2: 0,
      total3: 0,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      lang: 'settings/language',
    }),
  },
  created() {
    this.getNumber(this.clientEmail)
    this.getList1()
  },
  methods: {
    // 获取询盘数量
    getNumber(email) {
      orderListEmail({ customerEmail: email }).then((res) => {
        this.numArray = res.data
      })
    },

    // 获取询盘记录
    getList1() {
      const params = {
        ...this.page1,
        customerEmail: this.clientEmail,
      }
      listEmailEnquiryPage(params).then((res) => {
        this.tableData1 = res.data.data ? res.data.data : []
        this.total1 = res.data.total ? res.data.total : 0
      })
    },

    // 获取订单记录
    getList2() {
      const params = {
        ...this.page2,
        customerEmail: this.clientEmail,
      }
      listEnquiryOderVOPage(params).then((res) => {
        this.tableData2 = res.data.data ? res.data.data : []
        this.total2 = res.data.total ? res.data.total : 0
      })
    },

    // 获取邮件记录
    getList3() {
      const params = {
        ...this.page3,
        boxType: null,
        flag: 1,
        keyWord: this.clientEmail,
        emailAccount: this.$store.state.mail.currentAccount,
        businessId: this.userInfo.userId,
        keyWordSearchType: 4,
        tenantId: this.userInfo.tenantId,
      }
      emailPageList(params).then((res) => {
        this.tableData3 = res.data.records ? res.data.records : []
        this.total3 = res.data.total ? res.data.total : 0
      })
    },

    // 关闭
    close() {
      this.$emit('recordDrawerClose')
    },

    // 选项卡点击事件
    handleClick(tab) {
      const index = Number(tab.index) + 1
      if (index == 1) {
        this.getList1()
      } else if (index == 2) {
        this.getList2()
      } else {
        this.getList3()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.record-drawer {
  .container {
    padding: 0 20px;
    .top {
      display: flex;
      flex-flow: row nowrap;
      > div {
        margin-right: 20px;
        width: 220px;
        height: 86px;
        border: 1px solid rgba(216, 216, 216, 1);
        border-radius: 4px;
        padding-left: 20px;
        h1 {
          font-size: 28px;
          color: #333;
          margin: 10px 0;
        }
        p {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
  .btn {
    position: fixed;
    bottom: 0;
    height: auto;
    width: 1000px;
    margin-top: 20px;
    padding: 15px 20px;
    box-shadow: 0px -2px 4px 0px rgba(223, 223, 223, 0.5);
  }
}
</style>
