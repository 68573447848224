<!-- 废弃页面 -->
<template>
  <el-dialog
    :title="`安排出运  销售订单编号:${orderCode}`"
    width="1200px"
    class="custom-dialog"
    :visible.sync="transportationVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <table class="custom-table no-space">
        <thead>
          <tr>
            <th width="300">收货地址</th>
            <th width="200">收货公司</th>
            <th width="150">收货人</th>
            <th width="150">联系电话</th>
            <th width="150">
              <span>
                <i>*</i>
                货运方式
              </span>
            </th>
            <th width="150">
              <span>
                <i>*</i>
                贸易条款
              </span>
            </th>
            <th width="150">
              <span>
                <i>*</i>
                发货地
              </span>
            </th>
            <th width="200">
              <span>
                <i>*</i>
                装运日期
              </span>
            </th>
          </tr>
        </thead>
        <tbody v-if="form.shippingOrderReceivingDTOS.length">
          <tr v-for="(item, i) in form.shippingOrderReceivingDTOS" :key="i">
            <td>
              {{ item.receivingAddress }}
            </td>
            <td>
              {{ item.receivingCompany }}
            </td>
            <td>{{ item.receivingMan }}</td>
            <td>{{ item.receivingPhone }}</td>
            <td>
              <el-form-item prop="shippingMethod">
                <el-select
                  v-model="form.shippingMethod"
                  @change="shippingMethodChange"
                  clearable
                >
                  <el-option
                    v-for="item in shippingMethodList"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type"
                  />
                </el-select>
              </el-form-item>
            </td>
            <td>
              <el-form-item prop="trade">
                <el-select v-model="form.trade" @change="tradeChange" clearable>
                  <el-option
                    v-for="item in tradeList"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type"
                  />
                </el-select>
              </el-form-item>
            </td>
            <td>
              <el-form-item prop="portShipment">
                <el-select v-model="form.portShipment" clearable>
                  <el-option label="苏州" value="苏州"></el-option>
                  <el-option label="义乌" value="义乌"></el-option>
                  <el-option label="合肥" value="合肥"></el-option>
                </el-select>
              </el-form-item>
            </td>
            <td>
              <el-form-item prop="shippingDate">
                <el-date-picker
                  v-model="form.shippingDate"
                  type="date"
                  placeholder="年/月/日"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">暂无数据</td>
          </tr>
        </tbody>
      </table>

      <div class="flex-btn mt20 mb10">
        <h4>产品列表</h4>
        <el-checkbox
          v-show="isDelivery"
          label="同时申请出库"
          disabled="disabled"
          :checked="true"
        ></el-checkbox>
      </div>
      <div class="product-table">
        <table class="custom-table no-space">
          <thead>
            <tr>
              <th style="width: 55px">#</th>
              <th style="width: 240px">产品</th>
              <th>产品类型</th>
              <th>是否需要印刷</th>
              <th style="width: 160px">库存</th>
              <th>订单数量</th>
              <th>待安排数量</th>
              <th>
                <span>
                  <i>*</i>
                  出库仓库
                </span>
              </th>
              <th>
                <span>
                  <i>*</i>
                  出运数量
                </span>
              </th>
              <th>赠品数量</th>
              <th>
                <span>
                  <i>*</i>
                  是否退税
                </span>
              </th>
              <th>
                <span>
                  <i>*</i>
                  总箱数
                </span>
              </th>
              <th>
                <span>
                  <i>*</i>
                  总体积(M³)
                </span>
              </th>
              <th>
                <span>
                  <i>*</i>
                  总重量(KGS)
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in form.shippingOrderProductDTOS" :key="i">
              <td>{{ i + 1 }}</td>
              <td>
                <span v-if="item.productCode">{{ item.productCode }}/</span>
                <span v-if="item.productEn">{{ item.productEn }}/</span>
                {{ item.specification }}
              </td>
              <td>{{ item.standardCustomized || '--' }}</td>
              <td>
                {{ item.printingWay.toUpperCase() === 'BLANK' ? '否' : '是' }}
              </td>
              <td>
                <!-- 标准品需要印刷不显示库存 -->
                <div v-if="item.warehouseNumber">
                  <div
                    v-if="
                      item.standardCustomized == '标准品' &&
                      item.printingWay.toUpperCase() === 'BLANK'
                    "
                  >
                    <p
                      style="margin: 0"
                      v-for="(text, k) in item.warehouseNumber.split(';')"
                      :key="k"
                    >
                      {{ text }}
                    </p>
                  </div>
                  <div v-else>-</div>
                </div>
                <div v-else>-</div>
              </td>
              <td>{{ item.productAmount || '--' }}</td>
              <td>{{ item.reamount || '--' }}</td>
              <td>
                <div v-if="item.standardCustomized == '标准品'">
                  <!-- 标准品否显示出库仓库，标准品是不显示 -->
                  <div v-if="item.printingWay.toUpperCase() === 'BLANK'">
                    <el-form-item
                      :prop="'shippingOrderProductDTOS.' + i + '.warehouseId'"
                      :rules="[
                        {
                          required: true,
                          message: '请选择出库仓库',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        maxlength="10"
                        @change="checkStock($event, i)"
                        v-model="form.shippingOrderProductDTOS[i].warehouseId"
                        placeholder="请输入"
                        clearable
                      >
                        <el-option
                          v-for="item in storageList"
                          :key="item.warehouseId"
                          :label="item.name"
                          :value="item.warehouseId"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>-</div>
                </div>
                <div v-else>{{ item.warehouseId }}</div>
              </td>
              <td>
                <div v-if="item.standardCustomized == '标准品'">
                  <el-form-item
                    :prop="'shippingOrderProductDTOS.' + i + '.shippingOutNum'"
                    :rules="[
                      {
                        required: true,
                        message: '出运数量必填',
                        trigger: 'blur',
                      },
                      {
                        pattern: /^[0-9]+?$/,
                        message: '请输入>=0的整数',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      maxlength="10"
                      v-model="form.shippingOrderProductDTOS[i].shippingOutNum"
                      @input="calculateNum(i)"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else>{{ item.quantTotle }}</div>
              </td>
              <td>
                <div v-if="item.standardCustomized == '标准品'">
                  <el-form-item
                    :prop="'shippingOrderProductDTOS.' + i + '.giftNum'"
                    :rules="[
                      {
                        pattern: /^[0-9]+?$/,
                        message: '请输入>=0的整数',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      maxlength="10"
                      v-model="form.shippingOrderProductDTOS[i].giftNum"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else>-</div>
              </td>
              <td>
                <el-form-item
                  :prop="'shippingOrderProductDTOS.' + i + '.drawback'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择是否退税',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    maxlength="10"
                    v-model="form.shippingOrderProductDTOS[i].drawback"
                    placeholder="请输入"
                    clearable
                  >
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                  </el-select>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  :prop="'shippingOrderProductDTOS.' + i + '.caseNum'"
                  :rules="[
                    {
                      required: true,
                      message: '总箱数必填',
                      trigger: 'blur',
                    },
                    {
                      pattern: /^[0-9]*[1-9][0-9]*$/,
                      message: '请输入>0的整数',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    @input="inputCaseNum(i)"
                    v-model="form.shippingOrderProductDTOS[i].caseNum"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  :prop="'shippingOrderProductDTOS.' + i + '.volume'"
                  :rules="[
                    {
                      required: true,
                      message: '总体积必填',
                      trigger: 'blur',
                    },
                    {
                      pattern: /^\d+(\.\d{1,3})?$/,
                      message: '>=0最多三位小数',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    @input="inputVolume(i)"
                    v-model="form.shippingOrderProductDTOS[i].volume"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  :prop="'shippingOrderProductDTOS.' + i + '.grossWeight'"
                  :rules="[
                    {
                      required: true,
                      message: '总重量必填',
                      trigger: 'blur',
                    },
                    {
                      pattern: /^\d+(\.\d{1,3})?$/,
                      message: '>=0最多三位小数',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    @input="inputWeight(i)"
                    v-model="form.shippingOrderProductDTOS[i].grossWeight"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="mt10 mb10 text-right">
        总数量：
        <span class="gray f_s_16">{{ amount }}</span>
      </p>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="交货方式" prop="deliveryMode" label-width="80px">
            <el-select
              v-model="form.deliveryMode"
              @change="deliveryModeListChange"
              clearable
            >
              <el-option
                v-for="item in deliveryModeList"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="!deliveryDateVisible">
          <el-form-item
            label="物流仓库"
            prop="deliveryAddress"
            label-width="80px"
          >
            <el-select
              v-model="form.deliveryAddress"
              @change="deliveryAddressListChange"
              clearable
            >
              <el-option
                v-for="item in deliveryAddressList"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="!deliveryDateVisible">
          <el-form-item label="送达时间" prop="arriveDate" label-width="80px">
            <el-date-picker
              style="width: 100%"
              v-model="form.arriveDate"
              type="date"
              placeholder="年/月/日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="deliveryDateVisible">
          <el-form-item label="上门时间" prop="arriveDate" label-width="80px">
            <el-date-picker
              style="width: 100%"
              v-model="form.arriveDate"
              type="date"
              placeholder="年/月/日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <p>备注</p>
      <el-input
        type="textarea"
        v-model="form.remark"
        maxlength="500"
        show-word-limit
        :rows="3"
        placeholder="请输入500个字符以内"
      ></el-input>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  storageShippingMethodList,
  storageTradeList,
  storageDeliveryModeList,
  storageDeliveryAddressList,
  storageInsertPO,
  storageFindWarehouseList2,
  insertWarehouseOutByShipping,
} from '@/api/order'
export default {
  data() {
    return {
      type: '', // 1表示入口是订单详情
      orderCode: '', // 销售订单编号
      salesOrderId: '', //销售订单ID
      form: {
        businessNumber: '', // 订单编号
        businessId: '', // 客户代表Id
        businessName: '', // 客户代表名称
        customerName: '', // 客户姓名
        deliveryDate: '', // 最晚交货日期
        shippingMethod: '', // 运输方式
        shippingMethodDesc: '', // 运输方式描述
        trade: 1, // 贸易条款
        tradeDesc: 'DDP', // 贸易条款说明
        portShipment: '', // 发货地
        shippingDate: '', // 装运日期
        shippingOrderReceivingDTOS: [], // 收货地址列表
        shippingOrderProductDTOS: [], // 产品列表
        deliveryMode: '', // 交货方式
        deliveryModeDesc: '', // 交货方式说明
        deliveryAddress: '', // 物流仓库
        deliveryAddressDesc: '', // 物流仓库名称
        arriveDate: '', // 送达时间
        remark: '', // 备注
        purchaseDemandDetail: '', // 内部备注
      },
      amount: 0,
      shippingMethodList: [],
      tradeList: [],
      storageList: [],
      deliveryModeList: [],
      deliveryAddressList: [],
      warehouseIdArr: [],
      transportationVisible: false,
      rules: {
        shippingMethod: [
          { required: true, message: '请选择货运方式', trigger: 'change' },
        ],
        trade: [
          { required: true, message: '请选择贸易条款', trigger: 'change' },
        ],
        portShipment: [
          { required: true, message: '请选择发货地点', trigger: 'change' },
        ],
        shippingDate: [
          { required: true, message: '请选择装运日期', trigger: 'change' },
        ],
        deliveryMode: [
          { required: true, message: '请选择交货方式', trigger: 'change' },
        ],
        deliveryAddress: [
          { required: true, message: '请选择物流仓库', trigger: 'change' },
        ],
        arriveDate: [
          { required: true, message: '请选择送达时间', trigger: 'change' },
        ],
      },
      productListVisible: false,
      deliveryDateVisible: false,
      isDelivery: true, // 加工出库不需要同时出库
    }
  },
  mounted() {
    this.getDict()
  },
  methods: {
    /**
     * 获取下拉框字典项
     */
    getDict() {
      // 获取货运方式下拉框
      storageShippingMethodList().then((res) => {
        this.shippingMethodList = res.data
      })

      // 获取贸易条款下拉框
      storageTradeList().then((res) => {
        this.tradeList = res.data
      })

      // 获取交货方式下拉框
      storageDeliveryModeList().then((res) => {
        this.deliveryModeList = res.data
      })

      // 获取物流仓库下拉框
      storageDeliveryAddressList().then((res) => {
        this.deliveryAddressList = res.data
      })

      // 获取出库仓库下拉框
      storageFindWarehouseList2({ hasAuth: 0, hasCloudWarehouse: 1 }).then(
        (res) => {
          this.storageList = res.data
        }
      )
    },

    // 获取货运方式名称
    shippingMethodChange(val) {
      this.shippingMethodList.find((item, i) => {
        if (item.type == val) {
          this.form.shippingMethodDesc = item.name
        }
      })
    },

    // 获取贸易条款名称
    tradeChange(val) {
      this.tradeList.find((item, i) => {
        if (item.type == val) {
          this.form.tradeDesc = item.name
        }
      })
    },

    // 获取交货方式名称
    deliveryModeListChange(val) {
      this.deliveryModeList.find((item, i) => {
        if (item.type == val) {
          this.form.deliveryModeDesc = item.name
        }
      })
      if (val == 1 || val == 3) {
        this.deliveryDateVisible = true
        this.$refs.ruleForm.rules.deliveryAddress[0].required = false
      } else {
        this.deliveryDateVisible = false
        this.$refs.ruleForm.rules.deliveryAddress[0].required = true
      }
      this.form.arriveDate = ''
    },

    // 获取物流仓库名称
    deliveryAddressListChange(val) {
      this.deliveryAddressList.find((item, i) => {
        if (item.type == val) {
          this.form.deliveryAddressDesc = item.name
        }
      })
    },

    // 计算总出库数量
    calculateNum(i) {
      let arr = []
      let list = this.form.shippingOrderProductDTOS
      this.form.shippingOrderProductDTOS.forEach((item) =>
        arr.push(Number(item.shippingOutNum))
      )
      arr.length ? (this.amount = arr.reduce((p, v) => p + v)) : ''

      if (i >= 0) {
        // 获取仓库库存
        // 存在库存且出库仓库已选
        if (list[i].warehouseNumber && list[i].warehouseId) {
          let kcNumber = 0 // 定义当前库存初始数量
          let kcName // 定义当前库存名称
          let kcArr = list[i].warehouseNumber.split(';') // 定义当前所选库存
          this.storageList.forEach((item) => {
            if (item.warehouseId == list[i].warehouseId) {
              kcName = item.name
            }
          })
          kcArr.forEach((item, i) => {
            if (item.split(':')[0] == kcName) {
              kcNumber = Number(item.split(':')[1])
            }
          })
          // 判断出运数量不能大于库存数量
          if (Number(list[i].shippingOutNum) > kcNumber) {
            list[i].shippingOutNum = kcNumber
            this.$message.warning('出运数量不能大于库存数量')
          }
        }
        if (Number(list[i].shippingOutNum) > Number(list[i].reamount)) {
          list[i].shippingOutNum = Number(list[i].reamount)
          this.$message.warning('出运数量不可大于待安排数量！')
        }
        // 计算总箱数，总体积， 总重量
        this.calculateNumber(i)
      }
    },

    // 计算总箱数
    calculateCaseNum(i) {
      let list = this.form.shippingOrderProductDTOS
      list[i].caseNum = Math.ceil(list[i].shippingOutNum / list[i].numberPerBox)
    },

    // 计算总体积
    calculateVolume(i) {
      let list = this.form.shippingOrderProductDTOS
      list[i].volume = parseFloat(list[i].boxVolumeM * list[i].caseNum).toFixed(
        3
      )
    },

    // 计算总重量
    calculateWeight(i) {
      let list = this.form.shippingOrderProductDTOS
      list[i].grossWeight = parseFloat(
        list[i].caseNum * list[i].boxWeightKgs
      ).toFixed(3)
    },

    // 输入总箱数
    inputCaseNum(i) {
      let list = this.form.shippingOrderProductDTOS
      list[i].volume = parseFloat(list[i].boxVolumeM * list[i].caseNum).toFixed(
        3
      )
      list[i].grossWeight = parseFloat(
        list[i].caseNum * list[i].boxWeightKgs
      ).toFixed(3)
    },

    // 输入总体积
    inputVolume(i) {
      let list = this.form.shippingOrderProductDTOS
      list[i].caseNum = Math.ceil(list[i].volume / list[i].boxVolumeM)
      list[i].grossWeight =
        Math.ceil(list[i].volume / list[i].boxVolumeM) * list[i].boxWeightKgs
    },

    // 输入总重量
    inputWeight(i) {
      let list = this.form.shippingOrderProductDTOS
      list[i].caseNum = Math.ceil(list[i].grossWeight / list[i].boxWeightKgs)
      list[i].volume = parseFloat(list[i].caseNum * list[i].boxVolumeM).toFixed(
        3
      )
    },

    // 计算总箱数，总体积， 总重量
    calculateNumber(i) {
      let list = this.form.shippingOrderProductDTOS
      // 计算总箱数
      list[i].caseNum = Math.ceil(list[i].shippingOutNum / list[i].numberPerBox)
      // 计算总体积
      list[i].volume = parseFloat(list[i].boxVolumeM * list[i].caseNum).toFixed(
        3
      )
      // 计算总重量
      list[i].grossWeight = parseFloat(
        list[i].caseNum * list[i].boxWeightKgs
      ).toFixed(3)
    },

    // 安排出库
    planDelivery() {
      const params = this.form.shippingOrderProductDTOS.map((item) => {
        return {
          area: item.area, // 合同地区
          customerName: this.form.customerName, // 客户姓名
          businessId: this.form.businessId, // 客户代表ID
          businessName: this.form.businessName, // 客户代表名称
          buyerId: item.buyerId, // 采购开发ID
          contractNum: item.reamount, // 合同数量
          expectOutDate: '', // 预计出库时间
          expectOutNum: item.shippingOutNum, // 期望出库数量
          giftNum: item.giftNum, // 赠品数量
          isPrinting: item.printingWay.toUpperCase() === 'BLANK' ? 0 : 1, // 是否需要印刷（0否1是）
          orderNumber: this.orderCode, // 关联单号
          orderProductId: item.orderProductId, // 产品业务ID
          productCode: item.productCode, // 产品编码
          productName: item.productCn, // 产品名称
          productSpec: item.specification, // 产品规格
          productType: item.standardCustomized, // 产品类别（标准品 订制品）
          productUnit: item.productUnit, // 单位
          salesOrderId: this.salesOrderId, // 销售订单ID
          salesOrderNumber: this.orderCode, // 销售订单编号
          skuId: item.sku, // skuID
          warehouseId: item.warehouseId, // 出库仓库
          orderProductUniqueId: item.orderProductUniqueId,
        }
      })

      insertWarehouseOutByShipping(params).then((res) => {
        if (res && res.code == '000000') {
          this.playTrans()
        }
      })
    },

    // 安排出运
    playTrans() {
      // 产品类别名称重新定义
      this.form.shippingOrderProductDTOS =
        this.form.shippingOrderProductDTOS.map((item) => {
          return {
            ...item,
            productType: item.standardCustomized,
          }
        })
      const params = {
        ...this.form,
      }
      storageInsertPO(params).then((res) => {
        if (res && res.code == '000000') {
          this.$message.success('保存成功')
          if (this.type == 1) {
            this.$parent.getDetail(this.orderCode, 2)
          } else {
            this.$parent.getDetail(this.salesOrderId)
          }
          this.close()
        }
      })
    },

    // 保存
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 先调安排出库后调安排出运，加工出库不需要调安排出库
          if (this.isDelivery) {
            this.planDelivery()
          } else {
            this.playTrans()
          }
        } else {
          return false
        }
      })
    },

    // 判断出库仓库和库存对应的仓库是否匹配
    checkStock(id, i) {
      let list = this.form.shippingOrderProductDTOS
      let currentStock = ''
      if (list[i].warehouseNumber) {
        currentStock = list[i].warehouseNumber.split(';')
      } else {
        if (this.isDelivery) {
          this.$message.warning('没有库存不能选择出库仓库')
          list[i].warehouseId = ''
          return false
        }
      }
      let currentStockIds = []
      if (currentStock.length) {
        currentStock.forEach((item) => {
          this.storageList.forEach((obj) => {
            if (item.split(':')[0] == obj.name) {
              currentStockIds.push(obj.warehouseId)
            }
          })
        })

        if (!currentStockIds.includes(id)) {
          this.$message({
            message: '出库仓库和库存对应的仓库不一致，请重新选择！',
            type: 'warning',
          })
          this.form.shippingOrderProductDTOS[i].warehouseId = ''
        } else {
          // 库存小于订单数量不允许出库 采购需求需要，订单详情不需要
          if (this.type != 1) {
            let storeName = ''
            this.storageList.forEach((item) => {
              if (item.warehouseId == id) {
                storeName = item.name
              }
            })
            currentStock.forEach((item) => {
              if (item.split(':')[0] == storeName) {
                // 出运数量不能大于仓库数量
                if (
                  Number(item.split(':')[1]) < Number(list[i].shippingOutNum)
                ) {
                  this.$message({
                    message: '库存不足！',
                    type: 'warning',
                  })
                  this.form.shippingOrderProductDTOS[i].warehouseId = ''
                }
              }
            })
          }
        }
      }
    },

    // 关闭弹窗重置表单
    close() {
      this.$refs.ruleForm.resetFields()
      this.form = this.$options.data().form
      this.amount = 0
      this.transportationVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  .el-form-item {
    margin-bottom: 18px;
  }
  p {
    margin: 10px 0;
  }
  .product-table {
    overflow-x: auto;
    .custom-table {
      min-width: max-content;
      width: 100%;
      th {
        width: 120px;
      }
    }
  }
}
</style>
