<template>
  <el-row :gutter="20" class="audit-container">
    <el-row>
      <el-page-header @back="goBack"></el-page-header>
    </el-row>
    <el-row class="audit-top">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="5">
            <el-form-item label="审核类型" prop="auditType">
              <el-select
                v-model="ruleForm.auditType"
                placeholder="请选择"
                @change="auditTypeChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in auditTypeList"
                  :key="item.id"
                  :label="item.auditTypeName"
                  :value="item.auditType"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="适用组织" prop="organizationId">
              <el-cascader
                ref="cascaderAddr"
                :options="organizationOptions"
                v-model="ruleForm.organizationEcho"
                :props="optionProps"
                @change="handleChange"
                collapse-tags
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item>
              <template slot="label">
                <span>
                  <i style="color: #ff4d4f">*</i>
                  审核流程管理者
                </span>
                <span
                  class="f_s_12 c_pointer"
                  style="color: #1890ff"
                  @click="addAuditManage"
                >
                  添加其他管理者
                  <i style="color: #666; font-style: normal" class="f_s_12">
                    (不超过三人)
                  </i>
                </span>
              </template>
              <el-tag
                v-for="(tag, index) in auditManageTags"
                :key="tag.name"
                :closable="index !== 0"
                @close="handleClose(tag)"
              >
                {{ tag.name }}
              </el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="审核名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="audit-main">
      <p>
        <i style="color: #ff4d4f">*</i>
        审核流程
      </p>
      <el-row class="mt30 pl30 pr30 audit-node">
        <el-timeline>
          <el-timeline-item type="primary" :style="{ width: widthT }">
            <template slot="dot">
              <el-button size="mini" class="end-btn">发起审核</el-button>
            </template>
          </el-timeline-item>
          <el-timeline-item
            v-for="(item, index) of auditNodeData"
            :key="index"
            type="primary"
          >
            <el-card>
              <!-- <el-button class="del-node"
                         type="primary"
                         plain
                         icon="el-icon-delete"
              @click="delAuditNode(index)"></el-button>-->
              <el-tooltip content="删除" placement="top">
                <el-button
                  type="danger"
                  plain
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  class="del-node"
                  @click="delAuditNode(index)"
                ></el-button>
              </el-tooltip>
              <el-row type="flex" align="middle">
                <label class="label-color">节点类型：</label>
                <el-radio-group v-model="item.nodeType">
                  <el-radio :label="0">审核</el-radio>
                  <el-radio :label="1">抄送</el-radio>
                </el-radio-group>
              </el-row>
              <el-row class="mt15">
                <el-row class="label-color">
                  <i style="color: #ff4d4f">*</i>
                  节点名称:
                </el-row>
                <el-input
                  v-model="item.nodeName"
                  placeholder="请输入"
                  class="mt15"
                ></el-input>
              </el-row>
              <el-row
                type="flex"
                align="middle"
                justify="space-between"
                class="mt15"
              >
                <label class="label-color">人员设置：</label>
                <!-- <el-button size="mini"
                           @click="editClick(item, index)">
                  编辑
                </el-button>-->
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="editClick(item, index)"
                  ></el-button>
                </el-tooltip>
              </el-row>
              <el-row class="choose-user-box">
                <el-row v-if="item.superior">
                  <p class="mb5 f_w_7">所属组织负责人</p>
                </el-row>
                <el-row
                  v-if="
                    (item.designatedPersonJson &&
                      item.designatedPersonJson.length) ||
                    (item.optionalPersonJson &&
                      item.optionalPersonJson.length) ||
                    (item.optional && item.optionalRange === 0) ||
                    item.superior
                  "
                >
                  <el-row
                    v-if="
                      item.designatedPersonJson &&
                      item.designatedPersonJson.length
                    "
                  >
                    <p class="mb5 f_w_7">指定人员：</p>
                    <span
                      v-for="(tag, index) of item.designatedPersonJson"
                      :key="index"
                    >
                      {{ tag.name + '，' }}
                    </span>
                  </el-row>
                  <el-row
                    v-if="
                      item.optionalPersonJson && item.optionalPersonJson.length
                    "
                    class="mt15"
                  >
                    <p class="mb5 f_w_7" v-if="item.optionalRange === 2">
                      发起人自选-指定成员：
                    </p>
                    <p class="mb10 f_w_7" v-if="item.optionalRange === 1">
                      发起人自选-指定部门：
                    </p>
                    <span
                      v-for="(tag, index) of item.optionalPersonJson"
                      :key="index"
                    >
                      {{ tag.name + '，' }}
                    </span>
                  </el-row>
                  <el-row
                    v-if="item.optional && item.optionalRange === 0"
                    class="mt10"
                  >
                    <p class="mb5 f_w_7">发起人自选：</p>
                    <span v-if="item.optionalType == 0">自选一个人-全部</span>
                    <span v-else>自选多个人-全部</span>
                  </el-row>
                </el-row>
                <el-row v-else>待绑定</el-row>
              </el-row>
            </el-card>
          </el-timeline-item>
          <el-timeline-item type="primary" style="width: 100px">
            <template slot="dot">
              <el-button
                @click="addAuditNode"
                icon="el-icon-plus"
                circle
                type="primary"
                size="mini"
              ></el-button>
            </template>
          </el-timeline-item>
          <el-timeline-item type="primary" style="width: 100px">
            <template slot="dot">
              <el-button size="mini" class="end-btn">流程结束</el-button>
            </template>
          </el-timeline-item>
        </el-timeline>
      </el-row>
    </el-row>
    <el-row class="pro-footer">
      <el-button type="primary" class="ml30" @click="saveClick()">
        确认
      </el-button>
      <el-button class="ml30" @click="goBack">取消</el-button>
      <!-- <el-button class="ml30" @click="test">测试</el-button> -->
    </el-row>

    <!-- 添加审核管理者 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    <!-- 编辑 -->
    <AuditNodeEditModel2 ref="AuditNodeEditModel2" @node-obj="nodeObj" />
  </el-row>
</template>

<script>
import { getListDepartmrnt } from '@/api/organization-manage'
import UserChoose from '@/components/userChoose'
import AuditNodeEditModel2 from './components/audit-node-edit-model2'
import { listByTenantId } from '@/api/audit/auditMange'
import { updatePO, getDetail } from '@/api/audit/auditTemplate'
import { mapGetters } from 'vuex'
function getCascaderObj(val, opt) {
  return val.map(function (value, index, array) {
    for (var itm of opt) {
      if (itm.id === value) {
        opt = itm.children
        return itm
      }
    }
    return null
  })
}
export default {
  name: 'AuditProcessSettingAdd',
  components: { UserChoose, AuditNodeEditModel2 },
  data() {
    return {
      pageTitle: '返回',
      ruleForm: {
        auditType: null, //审核类型，数字
        auditTypeName: '',
        manageId: '',
        manageName: '',
        name: '',
        organizationId: [], //组织架构id
        organizationName: [],
        organizationEcho: '',
      },
      organizationId: [], //
      organizationName: [],
      rules: {
        auditType: [
          {
            required: true,
            message: '请选择审核类型',
            trigger: 'change',
          },
        ],
        organizationId: [
          {
            required: true,
            message: '请选择适用组织',
            trigger: 'change',
          },
        ],
      },
      auditNodeData: [],
      auditTypeList: [], //审核类型下拉
      organizationOptions: [], //组织树
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        checkStrictly: false,
      },
      auditManageTags: [], //审核管理者
      manageId: '', //审核管理者的id，逗号隔开
      manageName: '', //审核管理者的姓名，逗号隔开
      auditId: '', //详情id
      widthT: '160px',
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    //获取组织
    this.getListDepartmrntUser()
    //获取审核类型下拉
    this.getListByTenantId()
  },
  methods: {
    //详情
    async getAuditDetail() {
      let response = await getDetail({ businessId: this.auditId })
      if (response.code === '000000') {
        this.ruleForm = response.data
        let manageId = this.ruleForm.manageId.split(',')
        let manageName = this.ruleForm.manageName.split(',')
        manageName.forEach((item, index) => {
          let obj = {
            id: manageId[index],
            name: item,
          }
          this.auditManageTags.push(obj)
        })

        this.ruleForm.organizationEcho = JSON.parse(
          this.ruleForm.organizationEcho
        )
        this.ruleForm.organizationName =
          this.ruleForm.organizationName.split(',')
        this.ruleForm.organizationId = this.ruleForm.organizationId.split(',')

        this.organizationId = this.ruleForm.organizationId
        this.organizationName = this.ruleForm.organizationName

        let auditNodeData = response.data.auditNodeVOS
        auditNodeData.forEach((item) => {
          if (item.optionalPersonJson.length) {
            item.optionalPersonJson = JSON.parse(item.optionalPersonJson)
          }
          if (item.designatedPersonJson.length) {
            item.designatedPersonJson = JSON.parse(item.designatedPersonJson)
          }

          item.designatedPerson = item.designatedPerson === 1 ? true : false
          item.optional = item.optional === 1 ? true : false
          item.superior = item.superior === 1 ? true : false
          item.isAdd = 'edit'
        })
        //this.auditNodeData.push(auditNodeData)
        //this.$set(this.auditNodeData, 0, auditNodeData)

        this.auditNodeData = auditNodeData
      }
    },

    test() {},
    //----------------------------------
    //组织树下拉
    async getListDepartmrntUser() {
      let response = await getListDepartmrnt()
      if (response.code === '000000') {
        this.organizationOptions = response.data
        if (this.$route.query.auditId) {
          this.auditId = this.$route.query.auditId
          this.getAuditDetail()
        }
      }
    },

    //审核类型下拉
    async getListByTenantId() {
      let response = await listByTenantId({
        tenantId: this.userInfo.tenantId,
        userId: this.userInfo.userId,
      })
      if (response.code === '000000') {
        this.auditTypeList = response.data
      }
    },
    //审核类型下拉change
    auditTypeChange(val) {
      let auditType = this.auditTypeList.filter((item) => {
        if (val === item.auditType) {
          return item.auditTypeName
        }
      })
      this.ruleForm.auditType = auditType[0].auditType
      this.ruleForm.auditTypeName = auditType[0].auditTypeName
      this.ruleForm.name = auditType[0].auditTypeName
    },
    changeDetSelect(key, treeData) {
      let arr = [] // 在递归时操作的数组
      let returnArr = [] // 存放结果的数组
      let depth = 0 // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1) //将目前匹配的数组，截断并保存到结果数组，
            break
          } else {
            if (childrenData[j].nodes) {
              depth++
              childrenEach(childrenData[j].nodes, depth)
            }
          }
        }
        return returnArr
      }
      return childrenEach(treeData, depth)
    },

    handleChange(val) {
      //this.ruleForm.organizationEcho = JSON.stringify(val)
      let checksNode = this.$refs['cascaderAddr'].getCheckedNodes()
      this.organizationId = checksNode.map((item) => {
        return item.value
      })
      this.organizationName = checksNode.map((item) => {
        return item.label
      })
    },

    //添加审核管理者
    addAuditManage() {
      //要把选中的回显上去
      this.$refs['UserChoose'].showAddEdit(
        'addAuditManage',
        this.auditManageTags
      )
    },
    //审核管理者返回
    chooseUser(userArr) {
      this.auditManageTags = []
      this.auditManageTags = userArr
    },
    //移除审核管理者
    handleClose(tag) {
      this.auditManageTags.splice(this.auditManageTags.indexOf(tag), 1)
    },
    //编辑
    editClick(item, index) {
      this.$refs['AuditNodeEditModel2'].showAddEdit(item, index)
    },
    //编辑回传
    nodeObj(passObj, index) {
      //let obj = Object.assign(passObj, this.auditNodeData[index])
      //this.auditNodeData[index] = obj
      this.$set(this.auditNodeData, index, passObj)
      this.$forceUpdate()
    },

    //新增审核节点
    addAuditNode() {
      let obj = {
        auditType: '',
        nodeType: 0,
      }
      this.auditNodeData.push(obj)
      if (this.auditNodeData.length > 3) {
        this.widthT = '190px'
      }
    },
    //刪除审核节点
    delAuditNode(index) {
      if (this.auditNodeData.length == 1) {
        return this.$baseMessage(
          '最少设置一个审核流程',
          'warning',
          false,
          'erp-hey-message-warning'
        )
      }
      this.$confirm('删除不可恢复，确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        this.auditNodeData.splice(index, 1)
      })
    },

    //保存
    async saveClick() {
      let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
      ruleForm.organizationId = this.organizationId.join(',')
      ruleForm.organizationName = this.organizationName.join(',')
      ruleForm.organizationEcho = JSON.stringify(ruleForm.organizationEcho)

      this.$refs.ruleForm.validate(async (isSuccess) => {
        if (isSuccess) {
          let auditNodeData = JSON.parse(JSON.stringify(this.auditNodeData))
          let auditManageTags = JSON.parse(JSON.stringify(this.auditManageTags))
          auditNodeData.forEach((item, index) => {
            item.optionalPersonJson = JSON.stringify(item.optionalPersonJson)
            item.auditType = ruleForm.auditType
            item.designatedPersonJson = JSON.stringify(
              item.designatedPersonJson
            )
            item.designatedPerson = item.designatedPerson === true ? 1 : 0
            item.optional = item.optional === true ? 1 : 0
            item.superior = item.superior === true ? 1 : 0
            item.nodeSort = index + 1
            delete item.isAdd
          })

          let flag1 = true
          for (let i = 0; i < auditNodeData.length; i++) {
            let item = auditNodeData[i]
            if (item.nodeName === '') {
              flag1 = false
              this.$baseMessage(
                `请配置审核流程—${i + 1}—的节点名称`,
                'warning',
                false,
                'erp-hey-message-warning'
              )
              break
            }
          }

          let flag = true
          for (let i = 0; i < auditNodeData.length; i++) {
            let item = auditNodeData[i]
            if (
              (!item.superior && !item.optionalPersonJson) ||
              !item.optionalPersonJson
            ) {
              flag = false
              this.$baseMessage(
                `请配置审核流程—${i + 1}—的人员设置`,
                'warning',
                false,
                'erp-hey-message-warning'
              )
              break
            }
          }
          let manageId = []
          let manageName = []
          auditManageTags.forEach((item) => {
            manageId.push(item.id)
            manageName.push(item.name)
          })
          ruleForm.manageId = manageId.join(',')
          ruleForm.manageName = manageName.join(',')

          ruleForm = {
            ...ruleForm,
            auditNodeDTOS: auditNodeData,
          }
          delete ruleForm.auditNodeVOS
          if (flag && flag1) {
            let response = await updatePO(ruleForm)
            if (response?.code === '000000') {
              this.$baseMessage(
                '操作成功',
                'success',
                false,
                'erp-hey-message-success'
              )
              this.$router.push('/audit-process/audit-process-setting/index')
            }
          }
        }
      })
    },
    goBack() {
      this.$router.push('/audit-process/audit-process-setting/index')
    },
  },
}
</script>

<style scoped lang="scss">
.audit-top {
  width: 90%;
  margin: 0 auto;
}
.audit-main {
  width: 90%;
  height: calc(100% - 217px);
  margin: 0 auto;
  margin-top: 20px;
  .audit-node {
    width: 100%;
    height: calc(100% - 46px);
    overflow-x: auto;
    padding-top: 50px;
    .label-color {
      color: #777474;
    }
  }
}
.choose-user-box {
  border: 1px solid #ededed;
  padding: 15px 10px;
  height: calc(100% - 152px);
  margin-top: 15px;
  border-radius: 2px;
  overflow-y: auto;
}
.pro-footer {
  position: fixed;
  bottom: 0;
  width: 86%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
  background: #fff;
}

::v-deep {
  .el-timeline {
    display: flex;
    .el-card {
      width: 300px;
      height: 400px;
      margin-top: 40px;
      position: relative;
      .el-card__body {
        padding: 20px 15px;
        height: 100%;
      }
      .del-node {
        position: absolute;
        top: 5px;
        right: 15px;
        cursor: pointer;
      }
    }
    .el-timeline-item:last-child {
      .el-timeline-item__node {
        display: none;
      }
    }
  }
  .el-timeline-item__tail {
    position: absolute;
    top: 5px;
    left: 4px;
    width: 100%;
    height: 1px;
    background: #ededed;
  }
  .el-timeline-item {
    padding-right: 30px;
  }
  .el-timeline-item__node--normal {
    left: 48%;
    width: 15px;
    height: 15px;
    top: -3px;
  }
  .el-timeline-item__content {
    margin-top: 20px;
  }
  .el-timeline-item__dot {
    top: -9px;
  }
  .el-timeline {
    li.el-timeline-item:first-child {
      padding-right: 90px !important;
    }
  }
}
</style>
