<template>
  <el-dialog
    width="600px"
    :title="`${form.customExpenseSettingId ? '编辑' : '新增'}自定义费用`"
    :visible.sync="modelVisible"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="90px">
      <el-form-item label="中文名称" prop="nameCn">
        <el-input
          v-model="form.nameCn"
          placeholder="请输入"
          maxlength="50"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="英文名称" prop="nameEn">
        <el-input
          v-model="form.nameEn"
          placeholder="请输入"
          maxlength="50"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="是否展示" prop="showFlag">
        <el-switch
          v-model="form.showFlag"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="form.remark"
          type="textarea"
          maxlength="500"
          show-word-limit
          :rows="3"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="text-center mt20">
      <el-button @click="close()">取消</el-button>
      <el-button type="primary" @click="save()" :loading="loading">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { OrderOfferSetting } from '@/core/interactors/order/order-offer-setting'
export default {
  data() {
    return {
      form: {
        nameCn: '', // 中文名称
        nameEn: '', // 英文名称
        showFlag: 0, // 是否展示
        remark: '', // 备注
        customExpenseSettingId: '',
      },
      rules: {
        nameCn: [{ required: true, message: '请输入', trigger: 'blur' }],
        nameEn: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      modelVisible: false,
      loading: false,
    }
  },
  methods: {
    // 确认
    save() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          /**
           * @param customExpenseSettingId 业务id true:编辑, false:新增
           */
          this.loading = true
          if (this.form.customExpenseSettingId) {
            let res = await OrderOfferSetting.customUpdatePO({ ...this.form })

            if (res && res.code === '000000') {
              this.$emit('customFeesSave')
              this.$message.success('编辑成功')
              this.close()
            }
            this.loading = false
          } else {
            let res = await OrderOfferSetting.savePO({ ...this.form })

            if (res && res.code === '000000') {
              this.$emit('customFeesSave')
              this.$message.success('新增成功')
              this.close()
            }
            this.loading = false
          }
        }
      })
    },

    // 取消
    close() {
      this.modelVisible = false
      this.$refs.ruleForm.resetFields()
      this.form = this.$options.data().form      
    },
  },
}
</script>

<style lang="scss" scoped>
h4 {
  line-height: 32px;
  span {
    margin-left: 5px;
    color: #1890ff;
  }
}
</style>
