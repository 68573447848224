<!--
  功能：入库单详情入库库位
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月28日 16:19:01
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-dialog
    :visible.sync="showDialog"
    title="出库库位"
    :width="$attrs.width || '500px'"
    append-to-body
    show-close
    @close="showDialog = false"
    modal-append-to-body
  >
    <el-table :data="tableData" border stripe="">
      <el-table-column
        align="center"
        label="库位号"
        prop="locationNumber"
      ></el-table-column>
      <!-- <el-table-column
        align="center"
        label="当前数量"
        prop="currentNum"
      ></el-table-column> -->
      <el-table-column
        align="center"
        label="出库数量"
        prop="num"
      ></el-table-column>
    </el-table>
    <p style="text-align: right; margin-top: 15px">总放置数量: {{ sums }}</p>
  </el-dialog>
</template>

<script>
export default {
  name: 'BaseLocDetail',
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sums() {
      if (this.tableData.length === 0) {
        return 0
      } else {
        return this.tableData
          .map((i) => i.num)
          .reduce((prev, next) => prev + next)
      }
    },
  },
  // 组件状态值
  data() {
    return {
      showDialog: false,
    }
  },

  methods: {
    show() {
      this.showDialog = true
    },
  },
}
</script> 

<style scoped>
</style>
