<template>
  <div class="connection-dialog">
    <el-dialog
      title="手动匹配产品"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <h3>平台产品信息</h3>
      <ul class="info-wrapper">
        <li v-for="item of infoList" :key="item.label">
          {{ item.label }} {{ row[item.field] }}
        </li>
      </ul>
      <h3 class="mb10">选择匹配的产品</h3>
      <el-form :model="form" :rules="rules" ref="formRef" label-width="140px">
        <el-form-item label="ERP 系统产品：" prop="productId">
          <RemoteSelect
            v-if="dialogVisible"
            v-model="form.productId"
            @clearValidate="clearValidate"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="handleSubmit" :loading="loading">
          确 定
        </el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import RemoteSelect from './RemoteSelect'
  import { PlatformProductListInteractor } from '@/core'
  export default {
    components: { RemoteSelect },
    props: {
      value: { type: Boolean, require: true },
      row: { type: Object, require: true },
    },
    data() {
      return {
        infoList: [
          { label: '平台：', field: 'platformName' },
          { label: '平台 Item Number：', field: 'platformProductCode' },
          { label: '平台 Product ID：', field: 'platformProductId' },
          { label: '平台 Product Name：', field: 'platformNameEn' },
        ],
        form: { productId: '' },
        rules: {
          productId: [
            { required: true, message: '请选择ERP系统产品', trigger: 'blur' },
          ],
        },
        loading: false,
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },
    },
    watch: {
      'form.productId': {
        handler(val) {
          if (val) {
            this.$refs.formRef.clearValidate()
          } else {
            this.$refs.formRef.validateField('productId')
          }
        },
      },
    },
    methods: {
      clearValidate() {
        this.$refs.formRef.clearValidate()
      },
      handleClose() {
        this.dialogVisible = false
        this.$refs.formRef.resetFields()
      },
      handleSubmit() {
        this.$refs.formRef.validate(async (valid) => {
          if (!valid) return
          const params = { id: this.row.id, erpProductId: this.form.productId }
          this.loading = true
          try {
            const { code } = await PlatformProductListInteractor.matchedApi(
              params
            )
            if (code === '000000') {
              this.$emit('refresh')
              this.$message.success('匹配成功！')
            }
          } catch (err) {
            console.log(err)
          } finally {
            this.handleClose()
            this.loading = false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .connection-dialog {
    .info-wrapper {
      margin: 10px 20px;
      li {
        list-style: none;
        display: flex;
        line-height: 36px;
      }
    }
  }
</style>
