<template>
  <el-dialog title="选择地区和提醒岗位" :visible.sync="blackAddressVisible" width="550px" :before-close="cancel">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="地区" prop="amountName">
        <el-select v-model="form.amountName" style="width: 100px">
          <el-option v-for="(item, index) in areaList" :key="index" :label="item.areaName"
            :value="item.areaName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提醒岗位" prop="amountShort">
        <el-checkbox-group v-model="form.amountShort">
          <el-checkbox :label="1">订单支持</el-checkbox>
          <el-checkbox :label="2">客户代表</el-checkbox>
          <el-checkbox :label="3">采购开发</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button type="primary" @click="submit" :loading="loading">
          确认
        </el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
  import { FinanceThemeSetting } from '@/core/interactors/finance/config-manage'

  export default {
    props: {
      blackAddressVisible: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        form: {
          amountShort: [],
          amountName: '',
        },
        rules: {
          amountShort: [
            {
              type: 'array',
              required: true,
              message: '请选择',
              trigger: 'change',
            },
          ],
          amountName: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        loading: false,
        areaList: [],
      }
    },
    methods: {
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true
            this.save()
            this.cancel()
          }
        })
      },
      cancel() {
        this.form = this.$options.data().form
        this.$refs.form.resetFields()
        this.$emit('update:blackAddressVisible', false)
      },
      // 编辑展示详情
      getDetail(row) {
        this.form = { ...row }
        this.form.amountShort = row.amountShort?.split(',').map((i) => {
          return i * 1
        })
      },
      // 父组件 传值
      save() {
        console.log('this.form', this.form)
        this.$emit('saveGoodsDD', {
          ...this.form,
          amountShort: this.form.amountShort?.join(','),
        })
        this.loading = false
      },
      async getAreaList() {
        this.areaList = await FinanceThemeSetting.allDeptAreaByTenant()
      },
    },
    created() {
      this.getAreaList()
    },
  }
</script>