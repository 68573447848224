<template>
  <div class="page-content">
    <el-row class="mt10">
      <!-- 按规格设置 -->
      <span>{{ $t('productDetail.SATS') }}</span>
      <el-switch class="ml10" disabled v-model="skuSetBol"></el-switch>
    </el-row>
    <div v-if="!skuSetBol" class="mt10">
      <el-row :gutter="20">
        <el-col :span="5" class="flex-warp">
          <!-- 中文报关名 -->
          {{ $t('productDetail.ChineseCustomsDeclarationName') }}：{{
            specDeclare[0].declareCn || '--'
          }}
        </el-col>
        <el-col :span="5" class="flex-warp">
          <!-- 英文报关名 -->
          {{ $t('productDetail.EnglishCustomsDeclarationName') }}：{{
            specDeclare[0].declareEn || '--'
          }}
        </el-col>
        <el-col :span="4" class="flex-warp">
          <!--  报关单位 -->
          {{ $t('productDetail.CustomsDeclarationUnit') }}：{{
            specDeclare[0].declareUnit || '--'
          }}
        </el-col>
        <el-col :span="4" class="flex-warp">
          <!--  海关编码 -->
          {{ $t('productDetail.CustomsCode') }}：{{
            specDeclare[0].customsCode || '--'
          }}
        </el-col>
        <el-col :span="6" class="flex-warp">
          <!-- 报关要素 -->
          {{ $t('productDetail.CustomsDeclarationElements') }}：{{
            specDeclare[0].declareRemark || '--'
          }}
        </el-col>
      </el-row>
    </div>

    <el-table v-if="skuSetBol" border :data="specDeclare" class="mt10">
      <!-- 产品编码 -->
      <el-table-column
        :label="$t('productDetail.ProductCode')"
        align="center"
        prop="sku"
      ></el-table-column>
      <el-table-column
        v-for="(it, i) in specTypeValue"
        :key="i"
        :label="it"
        align="center"
        :prop="'specsValue' + (i + 1)"
      ></el-table-column>
      <!-- 报关中文名 -->
      <el-table-column
        :label="$t('productDetail.ChineseCustomsDeclarationName')"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.declareCn || '--' }}
        </template>
      </el-table-column>
      <!-- 英文报关名 -->
      <el-table-column
        :label="$t('productDetail.EnglishCustomsDeclarationName')"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.declareEn || '--' }}
        </template>
      </el-table-column>
      <!-- 报关单位 -->
      <el-table-column
        :label="$t('productDetail.CustomsDeclarationUnit')"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.declareUnit || '--' }}
        </template>
      </el-table-column>
      <!-- 海关编码 -->
      <el-table-column
        :label="$t('productDetail.CustomsCode')"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.customsCode || '--' }}
        </template>
      </el-table-column>
      <!-- 报关要素 -->
      <el-table-column
        :label="$t('productDetail.CustomsDeclarationElements')"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.declareRemark || '--' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: {
    productInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      skuSetBol: false,
      specDeclare: [],
      specTypeValue: [], // 规格类型
    }
  },
  watch: {
    'productInfo.productSpecsDeclareVOList': {
      handler() {
        this.skuSetBol =
          this.productInfo?.productSpecsDeclareVOList[0]
            ?.skuSettingForDeclare == 1
            ? true
            : false
        this.setData()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setData() {
      this.specDeclare = _.cloneDeep(this.productInfo.productSpecsDeclareVOList)
      this.specTypeValue = this.specDeclare[0].specsType.split(',')
    },
  },
}
</script>
<style lang="scss" scope>
.page-content {
  width: 100%;
}
.flex-warp {
  word-break: break-all;
}
</style>