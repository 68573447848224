var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-warning"},[_c('el-row',[_c('el-col',{attrs:{"span":2}},[_c('h4',{staticClass:"warning-title"},[_vm._v("预警提醒")])])],1),_c('el-row',[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('el-col',{attrs:{"span":22,"offset":1}},[_c('div',[_c('el-radio',{attrs:{"label":1},model:{value:(_vm.form.deliveryTick),callback:function ($$v) {_vm.$set(_vm.form, "deliveryTick", $$v)},expression:"form.deliveryTick"}},[_c('span',[_vm._v("距离交期前")]),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":"deliveryDays","rules":{
                required: _vm.form.deliveryTick === 1,
                message: '时间不能为空',
                trigger: 'blur',
              }}},[_c('el-input',{staticStyle:{"width":"120px","margin":"0 5px"},on:{"change":function($event){return _vm.numberFormat('delivery')}},model:{value:(_vm.form.deliveryDays),callback:function ($$v) {_vm.$set(_vm.form, "deliveryDays", $$v)},expression:"form.deliveryDays"}},[_c('template',{slot:"append"},[_vm._v("天")])],2)],1),_c('span',[_vm._v("预警")])],1),_c('el-radio',{attrs:{"label":2},model:{value:(_vm.form.deliveryTick),callback:function ($$v) {_vm.$set(_vm.form, "deliveryTick", $$v)},expression:"form.deliveryTick"}},[_c('span',[_vm._v("距离每个节点到日期前")]),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":"everyNodeDays","rules":{
                required: _vm.form.deliveryTick === 2,
                message: '时间不能为空',
                trigger: 'blur',
              }}},[_c('el-input',{staticStyle:{"width":"120px","margin":"0 5px"},on:{"change":function($event){return _vm.numberFormat('everyNode')}},model:{value:(_vm.form.everyNodeDays),callback:function ($$v) {_vm.$set(_vm.form, "everyNodeDays", $$v)},expression:"form.everyNodeDays"}},[_c('template',{slot:"append"},[_vm._v("天")])],2)],1),_c('span',[_vm._v("预警")])],1),_c('small',{staticStyle:{"margin-left":"-30px"}},[_vm._v("（预计日期）")])],1),_c('div',[_c('div',[_c('el-checkbox',{model:{value:(_vm.form.createTrackingTick),callback:function ($$v) {_vm.$set(_vm.form, "createTrackingTick", $$v)},expression:"form.createTrackingTick"}},[_c('span',[_vm._v("生成跟踪单，状态=待更新超过")]),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":"createTrackingTickTime","rules":{
                  required: _vm.form.createTrackingTick,
                  message: '时间不能为空',
                  trigger: 'blur',
                }}},[_c('el-input',{staticStyle:{"width":"120px","margin":"0 5px"},on:{"change":function($event){return _vm.numberFormat('createTrackingTick')}},model:{value:(_vm.form.createTrackingTickTime),callback:function ($$v) {_vm.$set(_vm.form, "createTrackingTickTime", $$v)},expression:"form.createTrackingTickTime"}},[_c('template',{slot:"append"},[_vm._v("小时")])],2)],1),_c('span',[_vm._v("未更新预警")])],1),_c('small',[_vm._v("（关闭勾选则不提醒）")])],1),_c('div',[_c('el-checkbox',{model:{value:(_vm.form.autoWarningTick),callback:function ($$v) {_vm.$set(_vm.form, "autoWarningTick", $$v)},expression:"form.autoWarningTick"}},[_vm._v(" 当系统日期等于或超过节点日期时，系统预警自动触发待办延期和消息提醒 ")])],1)]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',[_vm._v("提醒方式")]),_c('div',{staticStyle:{"margin-left":"30px","margin-top":"5px"}},[_c('el-checkbox-group',{model:{value:(_vm.form.tipList),callback:function ($$v) {_vm.$set(_vm.form, "tipList", $$v)},expression:"form.tipList"}},[_c('el-checkbox',{attrs:{"label":"systemMessageTips"}},[_vm._v(" 系统消息提示 ")]),_c('el-checkbox',{attrs:{"label":"emailTips"}},[_vm._v("邮件提醒")]),_c('el-checkbox',{attrs:{"label":"dingdingTips"}},[_vm._v("手机钉钉")])],1),_c('small',[_vm._v(" 提示：提醒角色是根据单据上所有关联的人员核心(订单支持、采购、客户代表) 一个人绑定多个邮件时，只会取第一个邮件作为通知，不会全部通知到。 ")])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }