<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="45%"
    @close="close"
    top="5vh"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="运输方式" prop="transportWayCn">
              <el-autocomplete
                v-model.trim="form.transportWayCn"
                :fetch-suggestions="querySearchAsync2"
                class="w100"
                :minlength="1"
                :maxlength="50"
                placeholder="请输入长度1到50个字符"
                :trigger-on-focus="triggerBol"
                clearable
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="英文名称" prop="transportWayEn">
              <el-input
                v-model.trim="form.transportWayEn"
                placeholder="请输入长度1到50个字符"
                minlength="1"
                maxlength="50"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="运输类型" prop="transportType">
              <el-select v-model="form.transportType" placeholder="请选择">
                <el-option label="空运" :value="1"></el-option>
                <el-option label="海运" :value="2"></el-option>
                <el-option label="快递" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报价币种" prop="currency">
              <el-radio-group v-model="form.currency" type="flex">
                <el-radio label="人民币" value="人民币"></el-radio>
                <el-radio label="美元" value="美元"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="计费方式" prop="billingMethod">
              <el-select v-model="form.billingMethod" placeholder="请选择">
                <el-option label="体积 " :value="1"></el-option>
                <el-option
                  label="毛重、体积重(体积/6000) 取Max"
                  :value="2"
                ></el-option>
                <el-option
                  label=" 毛重、体积重(体积/5000) 取Max"
                  :value="3"
                ></el-option>
                <el-option label="体积/毛重" :value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运输周期">
              <el-input
                v-model.trim="form.transportCycle"
                placeholder="请输入"
                show-word-limit
              />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 计费阶梯 -->
        <el-row class="mt15">
          <p class="charging-p">计费阶梯</p>
          <el-row type="flex" :gutter="40" class="mt15">
            <el-col :span="12">
              <el-form-item>
                <template slot="label">
                  <i class="c-danger">*</i>
                  送达地区
                </template>
                <el-autocomplete
                  v-model.trim="area"
                  :fetch-suggestions="querySearchAsync"
                  class="w100"
                  placeholder="请输入,回车即确认"
                  :trigger-on-focus="triggerBol"
                  clearable
                  @select="areaEnter"
                  @keyup.enter.native="areaEnter"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- table -->
          <el-row
            class="mt10"
            v-for="(item, index) of freightSettingLadderDTOList"
            :key="index"
          >
            <el-row type="flex">
              <el-tag
                closable
                size="medium"
                :disable-transitions="false"
                @close="handleClose(index)"
              >
                {{ item.deliveryArea }}
              </el-tag>
              <el-form-item label="派送方式" prop="deliveryMethod" class="ml10">
                <el-input
                  v-model.trim="
                    freightSettingLadderDTOList[index].deliveryMethod
                  "
                  placeholder="请输入"
                  maxlength="50"
                />
              </el-form-item>
            </el-row>

            <table class="custom-table join-table mt10">
              <thead>
                <tr>
                  <th style="width: 50px">序号</th>
                  <th width="150">
                    <span>
                      <i>*</i>
                      计费阶梯
                    </span>
                  </th>
                  <th width="150">
                    <span>
                      <i>*</i>
                      单价
                    </span>
                  </th>
                  <th width="100">操作</th>
                </tr>
              </thead>
              <tbody
                v-if="item.ladderAndPriceDTOS && item.ladderAndPriceDTOS.length"
              >
                <tr v-for="(itemChild, i) in item.ladderAndPriceDTOS" :key="i">
                  <td style="width: 50px">{{ i + 1 }}</td>
                  <td>
                    <el-input
                      v-model="item.ladderAndPriceDTOS[i].billingLadder"
                      placeholder="请输入"
                      clearable
                      @keyup.native="
                        freightSettingLadderDTOList[index].ladderAndPriceDTOS[
                          i
                        ].billingLadder = oninput(
                          freightSettingLadderDTOList[index].ladderAndPriceDTOS[
                            i
                          ].billingLadder
                        )
                      "
                      @blur="
                        billingLadderBlur(
                          index,
                          i,
                          item.ladderAndPriceDTOS[i].billingLadder
                        )
                      "
                    ></el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="item.ladderAndPriceDTOS[i].price"
                      placeholder="请输入"
                      clearable
                      @keyup.native="
                        freightSettingLadderDTOList[index].ladderAndPriceDTOS[
                          i
                        ].price = oninput(
                          freightSettingLadderDTOList[index].ladderAndPriceDTOS[
                            i
                          ].price
                        )
                      "
                    ></el-input>
                  </td>
                  <td class="text-center">
                    <el-tooltip content="删除" placement="top">
                      <el-button
                        v-if="i !== 0"
                        type="danger"
                        icon="el-icon-delete"
                        size="mini"
                        circle
                        plain
                        @click="handleDeleteArea(index, i)"
                      ></el-button>
                      <span v-else>--</span>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
            <el-row
              type="flex"
              align="middle"
              justify="center"
              class="operate-row"
            >
              <el-button type="text" @click="addFreightStep(index)">
                新增一行
              </el-button>
            </el-row>
          </el-row>
        </el-row>

        <el-row class="mt30">
          <label class="d_block mb5">备注</label>
          <el-input
            type="textarea"
            maxlength="500"
            :autosize="{ minRows: 3, maxRows: 5 }"
            show-word-limit
            v-model="form.remark"
            placeholder="请输入500个字符以内"
          ></el-input>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    freighInsertPO,
    freighUpdatePO,
    freighShow,
    listTransportWayOrDeliveryArea,
  } from '@/api/product/freightSettings'
  export default {
    name: 'FreightModel',
    data() {
      return {
        loading: false,
        title: '新增',
        form: {
          transportWayCn: '',
          transportWayEn: '',
          transportType: null,
          transportCycle: null,
          billingMethod: null,
          currency: '人民币',
          freightSettingLadderDTOList: [],
          remark: '',
        },
        rules: {
          transportWayCn: [
            { required: true, trigger: 'blur', message: '请输入运输方式' },
          ],
          transportWayEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
          transportType: [
            { required: true, trigger: 'change', message: '请选择运输类型' },
          ],
          currency: [
            { required: true, trigger: 'blur', message: '请选择报价币种' },
          ],
          billingMethod: [
            { required: true, trigger: 'change', message: '请选择计费方式' },
          ],
          billingLadder: [
            { required: true, trigger: 'blur', message: '请选择报价币种' },
          ],
        },
        dialogFormVisible: false,
        area: '', //送达地区
        areaArr: [],
        //-----------------------
        tableDataObj: [],
        freightSettingLadderDTOList: [],
        triggerBol: false,
      }
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit(id) {
        if (id) {
          this.title = '编辑'
          this.getFreighShow(id)
        } else {
          this.title = '新增'
        }
        this.dialogFormVisible = true
      },

      //详情
      async getFreighShow(id) {
        let response = await freighShow({ freightSettingId: id })
        if (response.code === '000000') {
          this.form = response.data
          //字段转换
          this.form.freightSettingLadderVOS.forEach((item) => {
            item.ladderAndPriceDTOS = item.ladderAndPriceVOS
            delete item.ladderAndPriceVOS
          })
          this.freightSettingLadderDTOList = this.form.freightSettingLadderVOS
          delete this.form.freightSettingLadderVOS
        }
      },

      //失去焦点
      billingLadderBlur(index, i, billingLadder) {
        if (i > 0) {
          let prevBillingLadder =
            this.freightSettingLadderDTOList[index].ladderAndPriceDTOS[i - 1]
              .billingLadder
          if (Number(billingLadder) <= Number(prevBillingLadder)) {
            this.freightSettingLadderDTOList[index].ladderAndPriceDTOS[
              i
            ].billingLadder = ''
            return this.$baseMessage(
              '当前计费阶梯值需大于前一个计费阶梯值!',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
        }
      },

      //搜索
      async querySearchAsync(queryString, cb) {
        if (!this.area) return
        await listTransportWayOrDeliveryArea({ deliveryArea: this.area }).then(
          (res) => {
            let results = res.data.deliveryAreas.map((item) => {
              return {
                ...item,
                value: item,
              }
            })
            cb(results)
          }
        )
      },
      async querySearchAsync2(queryString, cb) {
        if (!this.form.transportWayCn) return
        await listTransportWayOrDeliveryArea({
          transportWayCn: this.form.transportWayCn,
        }).then((res) => {
          let results = res.data.transportWayCns.map((item) => {
            return {
              ...item,
              value: item,
            }
          })
          cb(results)
        })
      },

      //送达地区-回车
      areaEnter() {
        if (this.area === '') return
        if (this.areaArr.includes(this.area)) {
          return this.$baseMessage(
            '请勿重复添加地区!',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        let obj = {
          deliveryArea: this.area, //送达区域
          deliveryMethod: '', //运送方式
          freightSettingId: '', //运费配置业务id
          freightSettingLadderId: '', //业务ID
          ladderAndPriceDTOS: [{ billingLadder: '', price: '' }], //计费阶梯和价格
        }
        this.freightSettingLadderDTOList.push(obj)
        this.areaArr.push(this.area)
        this.area = ''
      },

      //删除地区tag
      handleClose(index) {
        this.areaArr.splice(index, 1)
        this.freightSettingLadderDTOList.splice(index, 1)
        this.$forceUpdate()
      },

      //删除某个地区的某个阶梯
      handleDeleteArea(ParentIndex, index) {
        this.freightSettingLadderDTOList[ParentIndex].ladderAndPriceDTOS.splice(
          index,
          1
        )
        this.$forceUpdate()
      },

      //新增一行
      addFreightStep(index) {
        this.freightSettingLadderDTOList[index].ladderAndPriceDTOS.push({
          billingLadder: '',
          price: '',
        })
        this.$forceUpdate()
      },

      //关闭
      close() {
        this.title = '新增'
        this.areaArr = []
        this.freightSettingLadderDTOList = []
        this.$refs['form'].resetFields()
        this.form = {
          transportWayCn: '',
          transportWayEn: '',
          transportType: null,
          transportCycle: null,
          billingMethod: null,
          currency: '人民币',
          freightSettingLadderDTOList: [],
          remark: '',
        }
        //this.form = this.$options.data().form
        this.dialogFormVisible = false
      },

      //保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (
              !this.freightSettingLadderDTOList ||
              !this.freightSettingLadderDTOList.length
            ) {
              return this.$baseMessage(
                '请至少填写一种送达地区！',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            let flag = true
            for (let i in this.freightSettingLadderDTOList) {
              const t = this.freightSettingLadderDTOList[i].ladderAndPriceDTOS
              for (let j = 0; j < t.length; j++) {
                if (t[j].billingLadder === '') {
                  flag = false
                  return this.$baseMessage(
                    `请补全${this.freightSettingLadderDTOList[i].deliveryArea}区域的计费阶梯`,
                    'warning',
                    false,
                    'erp-hey-message-warning'
                  )
                }
                if (t[j].price === '') {
                  flag = false
                  return this.$baseMessage(
                    `请补全${this.freightSettingLadderDTOList[i].deliveryArea}区域的计价格`,
                    'warning',
                    false,
                    'erp-hey-message-warning'
                  )
                }
              }
            }

            if (flag) {
              let form = this.utils.deepCopy(this.form)
              form.freightSettingLadderDTOList =
                this.freightSettingLadderDTOList
              this.loading = true
              if (this.title === '新增') {
                let response = await freighInsertPO(form)
                this.loading = false
                if (response.code === '000000') {
                  this.$baseMessage(
                    '新增成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                }
              } else {
                let response = await freighUpdatePO(form)
                this.loading = false
                if (response.code === '000000') {
                  this.$baseMessage(
                    '更新成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                }
              }
              this.$emit('fetch-data')
              this.close()
            }
          }
        })
      },

      oninput(num) {
        var str = num
        var len1 = str.substr(0, 1)
        var len2 = str.substr(1, 1)
        var lenLast = str.substr(str.length - 1)
        //如果第一位是0，第二位不是点，就用数字把点替换掉
        if (str.length > 1 && len1 == 0 && len2 != '.') {
          str = str.substr(1, 1)
        }
        //第一位不能是.
        if (len1 == '.') {
          str = ''
        }
        //最后一位不能是.
        if (str.length == 8 && lenLast == '.') {
          str = ''
        }
        //限制只能输入一个小数点
        if (str.indexOf('.') != -1) {
          var str_ = str.substr(str.indexOf('.') + 1)
          if (str_.indexOf('.') != -1) {
            str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
          }
        }
        //正则替换
        str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
        str = str.replace(/\.\d\d\d$/, '') // 小数点后只能输两位
        return str
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    height: 65vh;
    overflow: hidden;
    overflow-y: auto;
    .el-form {
      width: 98.5%;
      .charging-p {
        color: #9e9e9e;
      }
    }
    .operate-row {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid #ededed;
      border-top: none;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
    .el-tag--medium {
      height: 32px;
      line-height: 32px;
    }
    .join-table {
      .el-form-item__content {
        margin-left: 0 !important;
      }
      .el-form-item--small.el-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
  .w50 {
    width: 50%;
  }
  .join-table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      height: 38px;
      border: 1px solid #ededed;
    }
    th {
      height: 38px;
      line-height: 38px !important;
      font-weight: normal;
    }
    td:nth-child(even) {
      font-family: 'PingFang Bold';
      max-width: 300px;
      overflow: hidden;
      padding: 0 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    td:nth-child(odd) {
      width: 100px;
      text-align: center;
    }
  }
  .custom-table thead th {
    padding: 0;
    min-width: 50px;
  }
</style>
