<template>
  <div class="putaway-dialog">
    <el-dialog
      title="上架产品到商城"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <div class="mb20">确认将此产品上架到{{ row.platformCode }}商城？</div>
      <!-- <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item prop="platformCodes">
          <el-checkbox-group v-model="form.platformCodes">
            <el-checkbox
              v-for="{ value, label, disabled } of platformDs"
              :key="value"
              :label="value"
              :disabled="disabled"
            >
              {{ label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form> -->
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="handleSubmit" :loading="loading">
          确 定
        </el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { platformDs } from '@/utils/dicts'
  import { PlatformProductListInteractor } from '@/core'
  export default {
    props: {
      value: { type: Boolean, require: true },
      row: { type: Object, require: true },
    },
    data() {
      return {
        form: { platformCodes: [] },
        rules: {
          platformCodes: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
        },
        // platformDs中有4个值：RP、RG、SW、SAGE，这里需要过滤掉SAGE，并且只有RP是可选状态
        platformDs: platformDs.reduce((finalList, item) => {
          if (item.value !== 'SAGE') {
            finalList.push({ ...item, disabled: false })
          }
          return finalList
        }, []),
        loading: false,
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },
    },
    methods: {
      handleClose() {
        this.dialogVisible = false
        // this.$refs.formRef.resetFields()
      },
      async handleSubmit() {
        const params = {
          erpProductId: this.row.erpProductId,
          platformCodes: [this.row.platformCode],
        }
        this.loading = true
        try {
          const { code } = await PlatformProductListInteractor.upShelfApi(
            params
          )
          if (code === '000000') {
            this.$emit('refresh')
            this.$message.success('上架成功！')
          }
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
          this.handleClose()
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
