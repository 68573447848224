<template>
  <div class="search-wrapper" v-if="showType == 'company'">
    <el-select v-model="searchFormCompany.type" class="w130 mr10 mb10">
      <el-option :value="1" :label="$t('cusCenter.myCustomer')"></el-option>
      <el-option :value="2" :label="$t('cusCenter.allCustomer')"></el-option>
    </el-select>

    <!-- 公司名称 -->
    <el-input
      class="w150 mr10"
      v-model="searchFormCompany.companyName"
      :placeholder="$t('cusDetail.CompanyName')"
      @keyup.enter.native="search"
    ></el-input>
    <!-- 客户邮箱 -->
    <el-input
      class="w150 mr10"
      v-model="searchFormCompany.email"
      @keyup.enter.native="search"
      :placeholder="$t('cusDetail.customerMail')"
    ></el-input>

    <!-- 类型 -->
    <el-select
      v-model="searchFormCompany.type2"
      class="w130 mr10"
      :placeholder="$t('cusDetail.CompanyType')"
    >
      <el-option value="" :label="lang === 'en' ? 'All' : '全部'"></el-option>
      <el-option :value="1" :label="$t('cusCenter.formal')"></el-option>
      <el-option :value="2" :label="$t('cusCenter.potential')"></el-option>
      <el-option :value="3" :label="$t('cusCenter.blacklist')"></el-option>
    </el-select>
    <!-- 公司编码 -->
    <el-input
      class="w130 mr10"
      @keyup.enter.native="search"
      v-model="searchFormCompany.companyCode"
      :placeholder="$t('cusCenter.companycode')"
    ></el-input>
    <!-- 公司等级 -->
    <el-select
      class="w130 mr10"
      v-model="searchFormCompany.companyLevel"
      :placeholder="$t('cusCenter.companyLevel')"
    >
      <el-option
        v-for="item in companyLevelList"
        :key="item.code"
        :value="item.code"
        :label="item.label"
      ></el-option>
    </el-select>
    <!-- 搜索 -->
    <el-button type="primary" @click="search" class="mb10">
      {{ $t('cusCenter.Search') }}
    </el-button>
    <el-button @click="reset" class="mb10">
      {{ $t('cusCenter.Reset') }}
    </el-button>
  </div>
  <div class="search-wrapper" v-else>
    <el-select v-model="searchFormCompany.type" class="w130 mr10 mb10">
      <el-option :value="1" :label="$t('cusCenter.myCustomer')"></el-option>
      <el-option :value="2" :label="$t('cusCenter.allCustomer')"></el-option>
    </el-select>

    <!-- 公司名称 -->
    <el-input
      class="w150 mr10"
      v-model="searchFormCompany.companyName"
      :placeholder="$t('cusDetail.CompanyName')"
    ></el-input>
    <!-- 客户邮箱 -->
    <el-input
      class="w150 mr10"
      v-model="searchFormCompany.email"
      :placeholder="$t('cusDetail.customerMail')"
    ></el-input>

    <!-- 客户电话 -->
    <el-input
      v-if="showType !== 'company'"
      class="w150 mr10"
      v-model="searchFormCompany.email"
      :placeholder="$t('cusCenter.CustomerPhone')"
    ></el-input>
    <el-select
      class="w160 mr10"
      v-model="searchFormCompany.customerSourceId"
      :placeholder="$t('cusDetail.CustomerSource')"
      @change="customerSourceChange"
    >
      <el-option
        v-for="item in customerSource"
        :key="item.sourceId"
        :label="lang === 'en' ? item.sourceNameEn : item.sourceName"
        :value="item.sourceId"
      ></el-option>
    </el-select>
    <!-- 负责人/分管人 -->
    <el-select
      v-model="searchFormCompany.personType"
      class="w130 mr10"
      :placeholder="$t('placeholder.plsSel')"
    >
      <el-option :value="1" :label="$t('cusDetail.Creator')"></el-option>
      <el-option :value="2" :label="$t('cusDetail.PersonInCharge')"></el-option>
    </el-select>
    <el-input
      class="w150 mr10"
      v-model="searchFormCompany.personalValue"
    ></el-input>
    <!-- 类型 -->
    <el-select
      v-model="searchFormCompany.type2"
      class="w150 mr10"
      :placeholder="$t('cusDetail.CustomerType')"
    >
      <el-option :value="1" :label="$t('cusCenter.formal')"></el-option>
      <el-option :value="2" :label="$t('cusCenter.potential')"></el-option>
      <el-option :value="3" :label="$t('cusCenter.blacklist')"></el-option>
    </el-select>

    <!-- 搜索 -->
    <el-button type="primary" @click="search" class="mb10">
      {{ $t('cusCenter.Search') }}
    </el-button>
    <el-button @click="reset" class="mb10">
      {{ $t('cusCenter.Reset') }}
    </el-button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { companyLevelList } from '../helper'

  export default {
    name: 'SearchForm',
    props: {
      showType: {
        type: String,
        default: 'company',
      },
    },
    data() {
      return {
        searchFormCompany: {
          //查询表单
          type: 1, //类型 默认我的客户
          companyName: '', // 公司名称
          email: '', //客户邮箱
          companyType: '', //公司类型
          companyCode: '',
          cmpanyRank: '',
          code: '',
        },

        searchFormCustomer: {
          //查询表单
          type: 1, //类型 默认我的客户
          typeValue: '', //类型值
          companyName: '', // 公司名称
          email: '', //客户邮箱
          customerSourceId: '',
          customerSourceName: '',
          personType: '',
          personalValue: '',
          type2: '',
          type2Value: '',
          code: '',
        },

        customerSource: [],
        companyLevelList, //公司等级下拉数据
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },

    mounted() {},

    methods: {
      //客户来源选择
      customerSourceChange(val) {
        let changeItem = this.customerSource.filter((item) => {
          if (item.sourceId === val) {
            return item.sourceName
          }
        })
        this.form.customerSourceName = changeItem[0].sourceName
      },
      search() {
        console.log('search')
        this.$emit('search')
      },

      reset() {
        this.$emit('reset')
      },
    },

    created() {
      this.pubApi.customerSourceList({}).then((response) => {
        if (response.code === '000000') {
          this.customerSource = response.data
        }
      })
    },
  }
</script>

<style lang="scss" scoped>
  .search-wrapper {
    margin-top: 15px;
  }
  .w150 {
    width: 150px;
  }
  .w160 {
    width: 160px;
  }
  .w130 {
    width: 130px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
</style>
