export const warehouseTypeList = [{
  name: '采购入库',
  code: '采购入库'
}, {
  name: '其他入库',
  code: '其他入库'
}, {
  name: '退货入库',
  code: '退货入库'
},

{
  name: '退料入库',
  code: '退料入库'
},
{
  name: '加工入库',
  code: '加工入库'
},
{
  name: '盘盈入库',
  code: '盘盈入库'
},
{
  name: '盘亏出库',
  code: '盘亏出库'
},
{
  name: '调拨入库',
  code: '调拨入库'

},
{
  name: '销售出库',
  code: '销售出库'
}, {
  name: '其他出库',
  code: '其他出库'
},
{
  name: '领料出库',
  code: '领料出库'
},
{
  name: '调拨出库',
  code: '调拨出库'
},
{
  name: '入库成本调整',
  code: '入库成本调整'
}, {
  name: '出库成本调整',
  code: '出库成本调整'
}]

export const tableColumns = [
  {
    label: '操作时间',
    prop: 'createTime',
    isShow: true,
    checkable: false,
  },
  {
    label: 'SKU ID',
    prop: 'skuId',
    isShow: true,
    checkable: false,
  },
  {
    label: '核算维度',
    prop: 'accountRule',
    isShow: false,
    checkable: true,
  },
  {
    label: '计价方式',
    prop: 'priceType',
    isShow: false,
    checkable: true,
  },
  {
    label: '产品名称',
    prop: 'productName',
    isShow: true,
    checkable: true,
  },
  {
    label: '规格',
    prop: 'productSpec',
    isShow: true,
    checkable: true,
  },
  {
    label: '所属仓库',
    prop: 'warehouseName',
    isShow: true,
    checkable: true,
  },
  {
    label: '所属地区',
    prop: 'area',
    isShow: true,
    checkable: true,
  },
  {
    label: '单据类型',
    prop: 'warehouseType',
    isShow: true,
    checkable: true,
  },
  {
    label: '单据号',
    prop: 'docNo',
    isShow: true,
    checkable: false,
  },
  {
    label: '关联单据号',
    prop: 'ssociatedDocNo',
    isShow: true,
    checkable: true,
  },
  {
    label: '入库批次',
    prop: 'lotNo',
    isShow: true,
    checkable: true,
  },

  {
    label: '入库数量',
    prop: 'warehouseInNum',
    isShow: true,
    checkable: true,
  },
  {
    label: '入库单价',
    prop: 'warehouseInPrice',
    isShow: true,
    checkable: true,
  },
  {
    label: '入库成本',
    prop: 'warehouseInTotalPrice',
    isShow: true,
    checkable: true,
  },

  {
    label: '出库数量',
    prop: 'warehouseOutNum',
    isShow: true,
    checkable: true,
  },
  {
    label: '出库单价',
    prop: 'warehouseOutPrice;',
    isShow: true,
    checkable: true,
  },
  {
    label: '出库成本',
    prop: 'warehouseOutTotalPrice',
    isShow: true,
    checkable: true,
  },
  {
    label: '调整金额',
    prop: 'adjustPrice',
    isShow: true,
    checkable: true,
  },

  {
    label: '期末数量',
    prop: 'finalNum',
    isShow: true,
    checkable: false,
  },

  {
    label: '批次入库单价',
    prop: 'warehouseInLotPrice',
    isShow: true,
    checkable: true,
  },
  {
    label: '库存单价',
    prop: 'finalPrice',
    isShow: true,
    checkable: false,
  },
  {
    label: '期末总价',
    prop: 'finalTotalPrice',
    isShow: true,
    checkable: false,
  },

]
