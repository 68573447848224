<template>
  <el-dialog :title="title" :visible.sync="dialogReceiptDetailVisible" v-if="dialogReceiptDetailVisible" width="1420px"
    @close="close" :close-on-click-modal="false">
    <div class="content">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-table border :data="topTableData" max-height="420">
          <el-table-column width="120" prop="costTypeName" align="center" label="费用类型"
            show-overflow-tooltip></el-table-column>
          <el-table-column width="200" prop="payee" align="center" label="收款方">
            <template slot-scope="scope">
              {{ scope.row.payee || '--' }}
            </template>
          </el-table-column>
          <el-table-column prop="receivingAccount" align="center" label="收款账户"></el-table-column>

          <el-table-column width="200" prop="dutyParagraph" align="center" label="税号">
            <template slot-scope="scope">
              {{ scope.row.dutyParagraph || '--' }}
            </template>
          </el-table-column>
          <el-table-column width="100" align="center" label="支付金额">
            <template slot-scope="scope">
              <div :style="scope.row.entryType == 1 ? 'color:red' : ''">
                <span v-show="scope.row.entryType == 1" style="margin-right: -3px">
                  -
                </span>
                <span :class="scope.row.entryType == 1 ? 'f_w_6' : 'f_w_6 green'">
                  {{ scope.row.currency === '美元' ? '$' : '￥'
                  }}{{ utils.numberFormat(scope.row.payMoney, 2) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" prop="payTime" align="center" label="付款时间">
            <template slot-scope="scope">
              {{ scope.row.payTime || '--' }}
            </template>
          </el-table-column>
          <el-table-column width="120" align="center" label="产品分摊金额">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click">
                <product-expense :expenseList.sync="expenseList" :orderType="scope.row.orderType"
                  :entryType="scope.row.entryType"></product-expense>
                <el-link slot="reference" type="primary" @click="getExpense(scope.row)">
                  点击查看
                </el-link>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column width="80" align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="danger" plain circle size="mini" icon="el-icon-delete"
                @click="deletList(scope.$index)"></el-button>
            </template>
          </el-table-column>
        </el-table>


        <p class="mt10 mb10 text-right">
          支付合计：
          <span class="bold f_s_16">
            {{ topTableData[0].currency === '美元' ? '$' : '￥' }}
            {{ utils.amountTotal(topTableData, 'payMoney', 2) }}
          </span>
        </p>

        <el-row>
          <el-form-item class="labelStyle" label-width="100%" label="公账付款" style="width: 22%; display: inline-block"
            prop="publicPayment" v-if="!isCBS">
            <el-radio-group v-model="form.publicPayment">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="labelStyle" label-width="100%" label="付款账号" style="width: 27%; display: inline-block"
            prop="payId" v-if="payIdVisible">
            <el-select v-model="form.payId" filterable style="width: 73%">
              <el-option v-for="item in Payer" :key="item.shroffAccountId" :label="
                  item.payee +
                  ' / ' +
                  item.bankDeposit +
                  ' / ' +
                  item.bankAccount
                " :value="item.shroffAccountId">
                <span style="float: left">
                  {{ item.payee }} / {{ item.bankDeposit }} /
                  {{ item.bankAccount }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="labelStyle" label-width="100%" label="实际付款时间" style="width: 50%; display: inline-block"
            prop="payTime" v-if="!isCBS">
            <el-date-picker type="date" placeholder="年/月/日" v-model="form.payTime" value-format="yyyy-MM-dd"
              style="width: 30%"></el-date-picker>
          </el-form-item>
        </el-row>

        <el-form-item class="labelStyle" label-width="100%" label="备注" style="width: 49%; display: inline-block"
          prop="remark">
          <el-input placeholder="请输入500个字以内" type="textarea" rows="3" resize="none" style="width: 85%" show-word-limit
            :maxlength="500" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item class="labelStyle" label-width="100%" label="附件" style="width: 40%; display: inline-block">
          <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList"></ErpUpload>
        </el-form-item>
      </el-form>
    </div>
    <el-row slot="footer" class="dialog-footer text-center">
      <el-button type="primary" @click="save" :loading="loading">确 认</el-button>
      <el-button @click="close">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import productExpense from '@/components/productExpense'
  import {
    listShorffAccountPay,
    getDetialCost,
    insertCostPays,
  } from '@/api/finance/finance-cost'
  export default {
    name: 'affirmPayment',
    components: { productExpense },
    data() {
      return {
        amendRow: '',
        dialogReceiptDetailVisible: false,
        payIdVisible: true,
        // 费用阶段
        costStage: [
          {
            value: 1,
            label: '出库前',
          },
          {
            value: 2,
            label: '出库后',
          },
        ],
        form: {
          costStage: '', // 费用阶段
          financeUrl: '', // 凭证/附件
          payId: '', // 付款id
          payTime: '', // 付款时间
          publicPayment: 1, // 是否公账付款(1:是;0:否)
          remark: '', // 备注
        },
        // 付款人
        Payer: [],
        topTableData: [{}],
        rules: {
          payId: [{ required: true, message: '请选择', trigger: 'change' }],
          payTime: [{ required: true, message: '请选择', trigger: 'change' }],
          remark: [{ max: 500, message: '最多500个字符', trigger: 'blur' }],
        },
        moneySign: '', //货币符号
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        currency: '',
        expenseList: [], //产品分摊金额
        isCBS: false, // 是否是cbs付款
        loading: false
      }
    },

    computed: {
      title() {
        return this.isCBS ? '发起CBS支付' : '确认付款'
      },
    },

    methods: {
      // 付款人
      async getPayer() {

        let response = await listShorffAccountPay({
          cusName: '',
          shroffType: 4,
          cbsFlag: this.isCBS ? 1 : 0,
        })
        this.Payer = response.data
      },
      async showReceiptDetail() {
        this.dialogReceiptDetailVisible = true
        this.getPayer()
        let response = await getDetialCost({ costId: this.amendRow })
        this.topTableData = response.data
        this.form = { ...this.topTableData[0], payTime: new Date() }
        this.moneySign = this.form.currency === '美元' ? '$' : '￥'
        this.$refs.form.resetFields()
        this.form.publicPayment = 1
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.financeUrl = JSON.stringify(fileList)
      },

      close() {
        this.$refs.ErpUpload.uploadListEmpty()
        this.dialogReceiptDetailVisible = false
        this.$refs.form.resetFields()
        this.topTableData = [{}]
      },
      save() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            this.form.costStage = null
            this.form.costSheetId = this.form.costId
            let dt = this.topTableData.map((item) => {
              return {
                ...item,
                costSheetId: item.costId,
                payId: this.form.payId,
                remark: this.form.remark,
                payTime: this.form.payTime,
                publicPayment: this.form.publicPayment,
                financeUrl: this.form.financeUrl,
              }
            })
            let method = !this.isCBS
              ? insertCostPays
              : CostManageInteractor.launchCbsPay
            let curAccount = this.Payer.find(
              (item) => item.shroffAccountId == this.form.payId
            )
            let reqData = this.isCBS
              ? {
                confirmFileAnnex: dt[0]?.financeUrl,
                confirmRemark: dt[0]?.remark,
                payOrderId: dt[0]?.costSheetId,
                paymentAccount: curAccount?.bankAccount,
                paymentCnapsCode: curAccount?.cnapsCode,
                payId: this.form.payId,
              }
              : dt
            this.loading = true
            let response = await method(reqData).catch(err => {
              this.loading = true
            })
            if (this.isCBS) {
              if (response.data == '发起CBS支付成功') {
                this.$message.success('发起CBS支付成功')
                this.close()
                this.$emit('getList')
              } else {
                this.$message.error(response.data)
              }
            } else {
              if (response?.code == '000000') {
                this.$message.success('确认成功')
                this.close()
                this.$emit('getList')
              }
            }
            this.loading = false
          } else {
            this.loading = false
            return false
          }
        })
      },
      deletList(index) {
        this.topTableData.splice(index, 1)
      },
      // 查看费用分摊
      async getExpense(row) {
        this.expenseList =
          await CostManageInteractor.getListProductExpenseByCostId(row.costId)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;

    .detail-header {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    .remark {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    /deep/ .el-divider--horizontal {
      margin: 30px 0;
    }
  }

  ::v-deep {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 14px;
        color: #6f7279;
      }
    }

    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .el-dialog__footer {
      text-align: center;
    }

    .labelStyle {
      label {
        text-align: left;
      }

      .el-form-item__content {
        margin-left: 0 !important;
      }
    }

    .el-form-item--small.el-form-item {
      margin-bottom: 10px;
    }

    .el-table .el-form-item__content {
      margin-left: 0 !important;
    }
  }
</style>
