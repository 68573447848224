<template>
 <div class="his-warp">
  <div class="mask" v-if="dialogTableVisible"></div>
  <el-dialog title="历史账期" :visible.sync="dialogTableVisible" width="80%" :modal="false">
    <div class="c-table-wrap">
      <table class="custom-table mt10 w90" border bordercolor="#ebeef5">
        <thead>
          <tr>
            <th width="70">序号</th>
            <th width="150">金额区间</th>
            <th>付款方式</th>
            <th width="150">占比(%)</th>
            <th width="160">付款环节</th>
            <th width="160">优先级</th>
            <th width="160">添加日期</th>
            <th width="160">添加人</th>
            <th width="160">替换日期</th>
            <th width="160">替换人</th>
            <th width="160">备注</th>
          </tr>
        </thead>
        <template v-if="tableData && tableData.length">
          <tbody v-for="(item, index) in tableData" :key="index">
            <tr>
              <td rowspan="4">{{ index + 1 }}</td>
              <td rowspan="4">{{ item.intervalName }}</td>
              <td rowspan="4">{{ item.paymentMethodName || '--' }}</td>
              <td>{{ item.deposit || '--' }}</td>
              <td>
                <span>定金</span>
              </td>
              <td rowspan="4">{{ item.priority }}</td>
              <td rowspan="4">{{ item.addTime }}</td>
              <td rowspan="4">{{ item.addUserName }}</td>
              <td rowspan="4">{{ item.createTime }}</td>
              <td rowspan="4">{{ item.creator }}</td>
              <td rowspan="4">{{ item.remark }}</td>
            </tr>
            <tr v-if="item.beforeShipping">
              <td>{{ item.beforeShipping || '-' }}</td>
              <td>
                <span>发货前</span>
              </td>
            </tr>
            <tr v-if="item.uponDelivery">
              <td>{{ item.uponDelivery || '--' }}</td>
              <td>
                <span>发货后</span>
                <span v-if="item.uponDeliveryAfter">
                  {{ item.uponDeliveryAfter }}天
                </span>
              </td>
            </tr>
            <tr v-if="item.finalPayment">
              <td>{{ item.finalPayment || '--' }}</td>
              <td>
                <span>收货后</span>
                <span v-if="item.finalPaymentAfter">
                  {{ item.finalPaymentAfter }}天
                </span>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td colspan="11">暂无数据</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
    <span slot="footer">
      <el-button type="primary" @click="dialogTableVisible = false">
        关 闭
      </el-button>
    </span>
  </el-dialog>
 </div>
</template>
<script>
  name: 'his-account-period'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  export default {
    data() {
      return {
        tableData: [],
        dialogTableVisible: false,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      show() {
        this.dialogTableVisible = true
      },
      // 获取历史账期
      async getList() {
        console.log(this.$route.query.supplierId)
        if (this.$route?.query?.supplierId) {
          let data = {
            supplierId: this.$route.query.supplierId,
          }
          let res = await CustomerConfigInteractor.getListApi(data)
          if (res?.code === '000000') {
            this.tableData = res?.data
          } else {
            this.tableData = []
          }
        }
      },
    },
  }
</script>
<style scoped lang="scss">
.his-warp{
  .mask{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000000;
    z-index:1998;
  }
  ::v-deep .el-dialog {
    position:relative;
    z-index:1999;
    .el-dialog__body {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}

</style>
