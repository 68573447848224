<!--
  运营成本配置模板
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月19日 16:26:54
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="table-wrapper">
    <!-- 表格 -->
    <el-table :data="tableData" stripe border :height="tableHeight">
      <el-table-column
        label="序号"
        align="center"
        width="50"
        type="index"
      ></el-table-column>
      <el-table-column
        label="地区"
        align="center"
        prop="area"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="运营成本配置编号"
        align="center"
        show-overflow-tooltip
        prop="configOperateNumber"
      ></el-table-column>
      <el-table-column
        label="产品类型"
        align="center"
        show-overflow-tooltip
        prop="standardCustomized"
      ></el-table-column>
      <el-table-column label="毛利额" align="center" prop="conditionType">
        <template slot-scope="scope">
          {{ scope.row.conditionType == 1 ? '大于0' : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="运营成本(%)"
        align="center"
        prop="ratio"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          {{ scope.row.status == 0 ? '启用' : '冻结' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="doEdit(scope.row)" size="medium">
            编辑
          </el-button>
          <el-button type="text" @click="toggleFreeze(scope.row)" size="medium">
            {{ scope.row.status == 0 ? '冻结' : '启用' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page-custom">
      <el-pagination
        background
        :current-page="pageNumber"
        layout="total, sizes,  prev, pager, next, jumper"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
        :total="total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
  </div>
</template>

<script>
  import { mixin } from '../mixins'
  import { OperatingCostConfigInteractor } from '@/core/interactors/finance/config-manage'

  export default {
    name: 'OperatingCostConfigTplTable',
    mixins: [mixin],

    components: {},
    // 组件状态值
    data() {
      return {
        tableData: [], //表格数据
      }
    },

    methods: {
      // 获取列表
      async getList() {
        const { total, data } =
          await OperatingCostConfigInteractor.getTemplates(
            this.getSearchParams()
          )
        this.tableData = data
        this.setTotal(total)
      },
    },
  }
</script>
<style scoped lang="scss">
  .page-custom {
    height: 58px;
  }
  ::v-deep {
    .el-pagination {
      margin: 0;
      padding-top: 18.5px;
      display: flex;
      justify-content: center;
    }
    .base-table-header {
      // 不可删除，会导致样式错乱
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
</style>
