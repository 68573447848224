import request from '@/core/services/request'

export function listPage(data = {}) {
  return request({
    url: '/order/orderPriceSheet/listPage',

    method: 'post',
    data,
  })
}
