<template>
  <el-dialog
    title="批量导入"
    class="import-dialog"
    :visible.sync="dialogVisible"
    width="520px"
  >
    <div class="import-inner">
      <dl class="row">
        <dt>1、</dt>
        <dd>
          <el-link type="primary" @click="downLoad">
            点击下载批量导入模板
          </el-link>
          <p class="desc">下载Excel模板，按照表格内提示填写信息</p>
        </dd>
      </dl>
      <dl class="row">
        <dt>2、</dt>
        <dd>
          <el-upload
            :action="action"
            class="upload-btn"
            :http-request="() => {}"
            :show-file-list="false"
            :before-upload="beforeUpload"
          >
            <el-button type="primary" size="medium">选择xls/xlsx文件</el-button>
          </el-upload>
          <div class="wait-upload" v-if="fileName">
            <span class="label">待上传文件:</span>
            <span class="name">{{ fileName }}</span>
            <i class="el-icon-close icon-close" @click="remove"></i>
          </div>
          <p class="desc">按模板格式填写完信息后点击此处上传xls或xlsx文件</p>
        </dd>
      </dl>
      <div class="tips">
        注意：
        <br />
        文件后缀名必须为：xls或xlsx（即Excel格式），文件大小不得大于10M
        <br />
        必填项不允许为空，一旦为空将会导致导入失败
        <br />
        其他信息若不填写请复制模板第一行内的默认数据
        <br />
        模板中的表头名称不可更改，表头行不能删除
        <br />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="upload" :loading="loading">
        立即导入
      </el-button>
      <el-button @click="dialogVisible = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { ShippingPlanInteractor } from '@/core/interactors/shipment/shipment-plan'

  export default {
    data() {
      return {
        type: 1, //  快递导入，2海运空运导入
        dialogVisible: false,
        file: null,
        fileName: '',
        loading: false,
        action: null,
      }
    },
    watch: {
      dialogVisible(val) {
        if (val) {
          this.file = null
          this.fileName = ''
        }
      },
    },
    methods: {
      show(type) {
        this.type = type
        this.action = `/storage/shippingOrder/v2/${
          type === 'AUTO' ? 'freightAutoImport' : 'freightManualImport'
        }`
        this.dialogVisible = true
      },
      beforeUpload(file) {
        let suffix = file.name.substr(file.name.lastIndexOf('.'))
        const isExcel = ['.xls', '.xlsx'].includes(suffix)
        const isLt10M = file.size / 1024 / 1024 < 10

        if (!isExcel) {
          this.$message.error('上传文件只支持xls或xlsx格式!')
        }
        if (!isLt10M) {
          this.$message.error('上传文件大小不能超过 10MB!')
        }
        if (isExcel && isLt10M) {
          this.file = file
          this.fileName = file.name
        }
        return isExcel && isLt10M
      },
      remove() {
        this.fileName = ''
        this.file = null
      },
      upload() {
        // 参数
        if (this.file) {
          let formData = new FormData()
          formData.append('file', this.file)
          this.loading = true

          const shipImport =
            this.type === 'AUTO'
              ? ShippingPlanInteractor.autoImport
              : ShippingPlanInteractor.manualImport
          shipImport(formData)
            .then((res) => {
              if (res && res.code == '000000') {
                if (res.data) {
                  this.$message.success(
                    '导入成功！数据同步中，请等待十分钟左右...'
                  )
                  this.$emit('success')
                  this.dialogVisible = false
                } else {
                  const msgHtml = `<div style="max-height: 400px;overflow-y: auto">${res.desc}</div>`

                  this.$message.error({
                    dangerouslyUseHTMLString: true,
                    message: msgHtml,
                    duration: 5000,
                  })
                }
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.$message.warning('请选择上传文件！')
        }
      },
      // 下载导入模板
      downLoad() {
        const down =
          this.type === 'AUTO'
            ? ShippingPlanInteractor.autoTemplate
            : ShippingPlanInteractor.manualTemplate

        down().then((res) => {
          const reader = new FileReader()
          reader.readAsDataURL(res)
          reader.onload = (e) => {
            const a = document.createElement('a')
            a.download =
              this.type === 'AUTO' ? `自动分摊导入单` : `手动调整导入单`
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .import-dialog {
    .row {
      display: flex;
      margin-bottom: 20px;
      dt {
        font-size: 20px;
        color: #666;
      }
      dd {
        flex: 1;
      }
      .el-link {
        font-size: 16px;
        line-height: 32px;
      }
      .wait-upload {
        display: flex;
        max-width: 100%;
        align-items: center;
        margin-top: 10px;
        .label {
          margin-right: 10px;
        }
        .name {
          font-size: 14px;
          color: #333;
        }
        .icon-close {
          color: #ccc;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          margin-left: 10px;
          &:hover {
            color: #1890ff;
          }
        }
      }
      .desc {
        font-size: 14px;
        color: #999;
        line-height: 24px;
        margin-top: 4px;
      }
    }
    .tips {
      font-size: 14px;
      color: #999;
      line-height: 24px;
    }
    .dialog-footer {
      text-align: center;
    }
  }
</style>
