<template>
  <el-row class="organization-container">
    <el-col :span="4" class="left-col">
      <el-tree
        :data="treeData"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        :props="optionProps"
        @node-click="handleNodeClick"
      >
        <el-row
          type="flex"
          align="middle"
          justify="space-between"
          style="flex: 1"
          class="custom-tree-node"
          slot-scope="{ node, data }"
        >
          <el-row type="flex" align="middle" class="row-name">
            <tooltip-over
              class="c_pointer text-tooltip"
              style="color: #7b7777"
              :content="data.name || '--'"
              ref-name="tooltipOver5"
            />
            <span class="test-span">({{ data.deptEmployerNums }}人)</span>
          </el-row>

          <el-dropdown
            trigger="click"
            style="width: 20%; text-align: right"
            v-allowed="['Organization:Operate']"
          >
            <span class="f_w_7">...</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-link
                  @click.stop="addLowerClick(data)"
                  v-if="data.deptLevel < 9"
                >
                  新增下级
                </el-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-link
                  v-if="data.deptLevel !== 0"
                  @click.stop="editLowerClick(data)"
                >
                  编辑组织
                </el-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-link
                  v-if="data.deptLevel !== 0"
                  @click.stop="delLowerClick(data)"
                >
                  删除组织
                </el-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-row>
      </el-tree>
    </el-col>
    <el-col :span="20" class="right-col">
      <el-row class="right-col-top">
        <el-row
          v-show="!selectRows.length"
          type="flex"
          align="middle"
          style="height: 32px"
        >
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="mr10"
            @click="addPersonClick"
            v-allowed="['Add:Person']"
          >
            添加人员
          </el-button>
          <el-form
            ref="form"
            :inline="true"
            :model="queryForm"
            label-width="49px"
            @submit.native.prevent
          >
            <el-form-item>
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入人员名称"
                clearable
                @input="searchInput"
              />
            </el-form-item>
          </el-form>
          <el-button @click="searchClick" class="pub-h-36">搜索</el-button>
          <el-button
            class="ml10 pub-h-36"
            type="primary"
            plain
            @click="dimissionClick"
            v-allowed="['Dimission:Operate']"
          >
            离职
          </el-button>
          <el-checkbox class="ml15" v-model="status" @change="statusChange">
            显示离职人员
          </el-checkbox>
        </el-row>
        <el-row type="flex" align="middle" v-show="selectRows.length">
          <p style="line-height: 31px">
            已选择
            <strong class="f_s_18 f_w_7">{{ selectRows.length }}</strong>
            个
          </p>
          <el-button
            class="ml10"
            type="primary"
            plain
            @click="dimissionClick"
            v-allowed="['Dimission:Operate']"
          >
            离职
          </el-button>
        </el-row>
      </el-row>
      <el-row class="mt30 table-box">
        <el-row class="test-row">
          <el-table
            :data="tableData"
            @selection-change="setSelectRows"
            style="width: 100%"
            border
          >
            <el-table-column align="center" type="selection" width="55" />
            <el-table-column
              align="center"
              prop="loginName"
              label="登录名"
              width="180"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-row style="text-align: left">
                  <el-image v-if="scope.row.headUrl" :src="scope.row.headUrl" />
                  <span v-else class="iconfont icon-person user-avatar"></span>
                  <span
                    class="blue-text ml5"
                    @click="personShowClick(scope.row, true)"
                  >
                    {{ scope.row.loginName }}
                  </span>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              prop="englishName"
              label="英文名"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.englishName || '--' }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="sex" label="性别" width="80">
              <template slot-scope="scope">
                <span>{{ scope.row.sex || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="telephone"
              label="联系电话"
              show-overflow-tooltip
              width="200"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.telephone || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="area"
              label="所属地区"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ scope.row.area || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="deptName"
              label="所属部门"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column align="center" prop="postType" label="职位">
              <template slot-scope="scope">
                <span>{{ matchPosition(scope.row.postType) || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status === 1" type="success">
                  {{ matchStaffStatus(scope.row.status) || '--' }}
                </el-tag>
                <el-tag v-if="scope.row.status === 2" type="info">
                  {{ matchStaffStatus(scope.row.status) || '--' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="角色" min-width="160">
              <template slot-scope="scope">
                <span
                  v-show="scope.row.userRoleVOList.length"
                  class="blue-text ml5"
                  v-for="item of scope.row.userRoleVOList"
                  :key="item.roleId"
                  @click="handleJumpPower(item)"
                >
                  {{ item.roleName }}
                </span>
                <span v-show="!scope.row.userRoleVOList.length">--</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip
                  content="编辑"
                  placement="top"
                  v-if="scope.row.administratorAccount !== 1"
                >
                  <el-button
                    v-allowed="['Edit:Person']"
                    type="primary"
                    plain
                    circle
                    size="mini"
                    icon="el-icon-edit"
                    @click="personShowClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <span v-else>--</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <Pagination
          v-show="total > 0"
          :limit.sync="queryForm.pageLe"
          :page.sync="queryForm.pageNo"
          :total="total"
          @pagination="getDepartmentListUser"
        />
      </el-row>
    </el-col>

    <!-- 新增组织 -->
    <AddBranchModel ref="AddBranchModel" @fetch-data="getListDepartmrntUser" />

    <!-- 添加人员 -->
    <PersonAddModel ref="PersonAddModel" @fetch-data="initLoad()" />

    <!-- 离职人员 -->
    <LeaveOfficeModel ref="LeaveOfficeModel" @fetch-data="initLoad()" />

    <!-- 查看编辑抽屉 -->
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawer"
      :before-close="closeClick"
      :size="size"
    >
      <PersonShowModel
        v-if="drawer"
        ref="PersonShowModel"
        :userId="userId"
        :isShow="isShow"
        @closeClick="closeClick"
        @closeClick2="closeClick2"
      />
    </el-drawer>
  </el-row>
</template>

<script>
import PersonAddModel from './components/person-add-model'
import PersonShowModel from './components/person-show-model'
import AddBranchModel from './components/add-branch-model'
import LeaveOfficeModel from './components/leave-office-model'
import Pagination from '@/components/Pagination/Index'
import { matchPosition, matchStaffStatus } from '@/utils/en-match-zh'
import tooltipOver from '@/components/base-tooltip'
import { OrganizationManageInteractor } from '@/core'
import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
export default {
  name: 'OrganizationManage',
  components: {
    PersonAddModel,
    PersonShowModel,
    AddBranchModel,
    LeaveOfficeModel,
    Pagination,
    tooltipOver,
  },
  data() {
    return {
      treeData: [],
      queryForm: {
        status: 1,
        pageLe: 10,
        pageNo: 1, //当前页数
      },
      total: 10,
      tableData: [],
      drawer: false,
      size: '600px',
      selectRows: [],
      currentId: '', //当前右侧选择公司/部门的id
      optionProps: {
        value: 'id',
        label: 'departmentName',
        children: 'children',
        checkStrictly: true,
      },
      deptId: '', //部门id，用于获取组织下用户
      userId: '', //用户id
      loading: false,
      drawerTitle: '个人资料',
      isShow: false, //查看个人资料还是编辑个人资料
      status: false, //是否显示离职人员
    }
  },
  mounted() {
    this.getListDepartmrntUser()
  },
  methods: {
    initLoad() {
      this.treeData = []
      this.getListDepartmrntUser2()
      this.getDepartmentListUser()
    },
    async getListDepartmrntUser2() {
      let response = await OrganizationManageInteractor.getListDepartmrnt()
      if (response.code === '000000') {
        this.treeData = response.data
      }
    },

    //获取组织
    async getListDepartmrntUser() {
      let response = await OrganizationManageInteractor.getListDepartmrnt()
      if (response.code === '000000') {
        this.treeData = response.data
        this.deptId = response.data[0].deptId
        this.getDepartmentListUser()
      }
    },

    //是否显示离职人员
    statusChange(val) {
      val ? delete this.queryForm.status : (this.queryForm.status = 1)
      this.queryForm.pageNo = 1
      this.getDepartmentListUser()
    },
    //新增下级组织
    addLowerClick(data) {
      this.$refs['AddBranchModel'].showAddEdit(data, 'add')
    },
    //编辑组织
    editLowerClick(data) {
      this.$refs['AddBranchModel'].showAddEdit(data, 'edit')
    },
    //删除组织
    async delLowerClick(data) {
      this.$confirm('删除不可恢复，确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let response = await OrganizationManageInteractor.departmentDelete(
          data.deptId
        )
        if (response.code === '000000') {
          this.$baseMessage(
            '删除组织成功',
            'success',
            false,
            'erp-hey-message-success'
          )
          this.getListDepartmrntUser()
        }
      })
    },

    handleNodeClick(data) {
      this.tableData = []
      this.total = 0
      this.queryForm.pageLe = 10
      this.queryForm.pageNo = 1
      this.deptId = data.deptId
      this.getDepartmentListUser()
    },

    //获取组织下用户
    async getDepartmentListUser(pageData) {

      if(pageData){
        this.queryForm.pageLe = pageData.limit
        this.queryForm.pageNo = pageData.page
      }
      this.loading = true
      this.queryForm.deptId = this.deptId
      let response = await OrganizationManageInteractor.departmentListUser(
        this.queryForm
      )
      if (response.code === '000000') {
        this.tableData = response.data?.data?.map(n => ({ ...n, userRoleVOList: n.userRoleVOList || [] }))
        this.total = response.data.total
      }
      this.loading = false
    },

    //如果input为空
    searchInput(val) {
      if (val === '') {
        this.getDepartmentListUser()
      }
    },

    searchClick() {
      this.getDepartmentListUser()
    },
    //添加人员
    addPersonClick() {
      this.$refs['PersonAddModel'].showAddEdit(this.deptId)
    },
    //编辑人员
    personShowClick(row, bol) {
      this.isShow = bol
      this.drawerTitle = row.loginName + '的个人资料'
      this.userId = row.userId
      this.drawer = true
    },

    //离职
    async dimissionClick() {
      if (!this.selectRows.length) {
        return this.$baseMessage(
          '请选勾选人员',
          'error',
          false,
          'erp-hey-message-error'
        )
      }
      let userIds = [],
        userNames = []
      this.selectRows.forEach((item) => {
        userIds.push(item.userId)
        userNames.push(item.userName)
      })
      this.$refs['LeaveOfficeModel'].showAddEdit(userIds, userNames)
    },
    //关闭查看
    closeClick() {
      this.drawerTitle = '个人资料'
      this.drawer = false
      this.initLoad()
    },
    closeClick2() {
      this.drawerTitle = '个人资料'
      this.drawer = false
      this.initLoad()
    },
    // 表格勾选
    setSelectRows(val) {
      this.selectRows = val
    },
    //职位匹配
    matchPosition(val) {
      return matchPosition(val)
    },

    //在职状态匹配
    matchStaffStatus(val) {
      return matchStaffStatus(val)
    },

    // 跳转至权限分组页
    handleJumpPower({ roleId }) {
      // this.$router.push({
      //   path: '/system-manage/power-group/index',
      //   query: { roleId }
      // })
      navigateTo(this, {
        pageType: PAGE_TYPES.powerGroupIndex,
        roleId,
      })
    }
  },
}
</script>

<style scoped lang="scss">
.left-col {
  height: 90vh;
  overflow: auto;
}
.text-hidden {
  width: 60%;
}
.right-col {
  height: 100%;
  padding: 0 20px;
  .table-box {
    height: calc(100% - 62px);
    overflow-y: auto;
  }
  .test-row {
    height: calc(100% - 52px);
    overflow-y: auto;
  }
}
.user-avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-size: 100% 100%;
  background: #ededed;
  margin-right: 5px;
}

::v-deep {
  .el-image {
    width: 30px !important;
    height: 30px !important;
    margin-right: 5px;
    img {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      background-size: 100% 100%;
      background: #ededed;
      margin-right: 5px;
    }
  }
  .el-tree-node__content {
    height: 35px;
    padding: 0 10px;
  }
  .custom-tree-node {
    span:first-child {
      font-size: 14px;
    }
  }
  .right-col-top {
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-tree {
    .is-current {
      > .el-tree-node__content {
        background: #ededed;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  .el-tree-node__content:hover {
    background: #ededed;
  }
  .pagination-container {
    padding: 10px;
  }
  .el-pagination {
    margin-top: 0 !important;
  }
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
  .custom-tree-node {
    width: 100%;
    overflow: hidden;
  }
}
.row-name {
  width: 85%;
}
.text-tooltip {
  overflow: hidden;
}
.test-span {
  flex: 1;
}
</style>
