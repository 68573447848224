<template>
  <div class="table-container">
    <el-row :gutter="20">
      <!-- 客户来源 -->
      <el-col class="col" :span="5">
        <!-- v-allowed="['Early:Warning']" 1.4.3内容-->
        <div class="upper-limit">
          <p class="f_s_16">客户预警</p>
          <div class="desc">
            <strong>客户年成交金额上限预警</strong>
            <span v-if="limitInfo">
              {{ getCurrencyCode(limitInfo.currency) }}
              {{ limitInfo.money | formatPrice }}
            </span>
            <el-button type="text" @click="setUpperLimit">修改</el-button>
          </div>
        </div>
        <!-- v-allowed="['Client:Origin']" 1.4.3内容-->
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">客户来源</p> 
            <el-button
              circle
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="setClientSource()"
            ></el-button>
          </el-row>
          <el-table
            class="mt30"
            :data="tableData"
            border
            :max-height="tableHeight(170)"
          >
            <el-table-column
              prop="sourceName"
              align="center"
              label="名称"
            ></el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="120"
              :resizable="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.sourceName !== 'Email' ||
                    scope.row.sourceName !== 'Email'
                  "
                >
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="mini"
                    @click="setClientSource(scope.row)"
                  ></el-button>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    circle
                    size="mini"
                    @click="removeClientSource(scope.row)"
                  ></el-button>
                </template>
                <span v-else>--</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
      <!-- 客户跟进 -->
      <el-col class="col" :span="8">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">客户跟进</p>
          </el-row>
          <el-table
            class="mt30"
            :data="tableData1"
            :span-method="objectSpanMethod"
            border
            :max-height="tableHeight(170)"
          >
            <el-table-column
              prop="customerType"
              align="center"
              label="客户类型"
            >
              <template slot-scope="scope">
                {{ scope.row.customerType == 1 ? '正式客户' : '潜在客户' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="customerRank"
              align="center"
              label="客户级别"
              width="150"
            >
              <template slot-scope="scope">
                <el-rate
                  v-model="scope.row.customerRank"
                  disabled
                  text-color="#ff9900"
                ></el-rate>
              </template>
            </el-table-column>
            <el-table-column
              prop="followPeriod"
              align="center"
              label="跟进周期提醒"
            ></el-table-column>
            <el-table-column
              prop="returnSeas"
              align="center"
              label="未跟进自动回归公海周期"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              label="操作"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="setClientFollow(scope.row)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">客评中心消息通知</p>
          </el-row>
          <el-table
            class="mt30"
            :data="tableDataSetting"
            border
            :max-height="tableHeight(170)"
          >
            <el-table-column
              prop="operationName"
              align="center"
              width="160"
              label="类型"
            ></el-table-column>
            <el-table-column
              prop="customerRank"
              align="center"
              label="通知方式"
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.emailNotice"
                  :true-label="1"
                  :false-label="0"
                >
                  邮件通知
                </el-checkbox>
                <el-checkbox
                  v-model="scope.row.messageNotice"
                  :true-label="1"
                  :false-label="0"
                >
                  系统消息通知
                </el-checkbox>
              </template>
            </el-table-column>
          </el-table>
          <div class="operate">
            <el-button type="primary" :loading="loading" @click="save">
              保存
            </el-button>
          </div>
        </el-row>
      </el-col>
      <!-- 收款方式 -->
      <!-- v-allowed="['Client:Collection:Way']" 1.4.3内容-->
      <el-col class="col" :span="11">
        <el-row class="public-col2">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">收款方式</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="clinetPayClick()"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            :data="clientPayData"
            width="100%"
            border
            class="mt30"
            :max-height="tableHeight(180)"
            :span-method="objectSpanMethod2"
          >
            <el-table-column
              label="金额区间"
              align="center"
              prop="payType"
              fixed="left"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-row v-if="scope.row.payType === 1">全部</el-row>
                <el-row v-else class="pl5 pr5">
                  <span
                    v-if="
                      (scope.row.beginSection === 0 ||
                        scope.row.beginSection) &&
                      scope.row.endSection
                    "
                  >
                    {{ scope.row.beginSection }}-{{ scope.row.endSection }}
                  </span>
                  <span v-else>&gt;{{ scope.row.beginSection }}</span>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              label="收款方式"
              align="center"
              prop="payway"
              show-overflow-tooltip
              width="200"
            ></el-table-column>
            <el-table-column
              label="创建人"
              align="center"
              prop="createName"
              width="100"
            ></el-table-column>
            <el-table-column
              label="更新时间"
              prop="updateTime"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.updateTime || '--' }}
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" align="center">
              <template slot-scope="scope">
                <AuditProcess
                  v-if="scope.row.status !== '4'"
                  :isTag="false"
                  :text="putPayStatus(scope.row.status)"
                  :type="scope.row.status | statusFilter"
                  :auditParams="{
                    auditType,
                    auditObjectId: scope.row.paymentId,
                  }"
                ></AuditProcess>
                <el-row v-if="scope.row.status === '4'">
                  <el-tooltip placement="top">
                    <div slot="content" class="tip-content">
                      作废原因：{{ scope.row.deleteReason || '--' }}
                      <br />
                      作废人：{{ scope.row.deleteName || '--' }}
                      <br />
                      作废时间：{{ scope.row.deleteTime || '--' }}
                    </div>
                    <el-tag type="info" class="c_pointer">已作废</el-tag>
                  </el-tooltip>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="120"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-row
                  v-if="scope.row.status === '2' || scope.row.status === '3'"
                >
                  <el-tooltip content="编辑" placement="top">
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      @click="clinetPayClick(scope.row.paymentId)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="作废" placement="top">
                    <el-button
                      type="info"
                      plain
                      circle
                      size="mini"
                      @click="invoiceDeleteClick(scope.row.paymentId)"
                    >
                      <i class="iconfont icon-void"></i>
                    </el-button>
                  </el-tooltip>
                </el-row>
                <el-tooltip
                  content="删除"
                  placement="top"
                  v-if="scope.row.status === '4'"
                >
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="clientPayDelClick(scope.row.paymentId)"
                  ></el-button>
                </el-tooltip>
                <span v-if="scope.row.status === '1'">--</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>

    <!-- 金额设置 -->
    <ClientSettingLimit
      ref="ClientSettingLimit"
      @success="getClientLimit"
      :currency="currency"
    />
    <!-- 客户来源 -->
    <ClientSettingPublicModel
      ref="ClientSettingPublicModel"
      @success="getClientSourceList"
    />
    <!-- 客户跟进 -->
    <ClientSettingTypeModel
      ref="ClientSettingTypeModel"
      @success="getClientFollowList"
    />
    <!-- 收款方式 -->
    <ClientSettingPayModel
      ref="ClientSettingPayModel"
      @fetch-data="getPaymentListPage"
    />

    <!-- 客户分管申请-指定角色 -->
    <RoleModel
      ref="RoleModel"
      @role-close="roleClose"
      @fetch-data="getCustomerManagerSettingGetDetail"
    />

    <!-- 作废 -->
    <el-dialog
      title="作废"
      :visible.sync="operatVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="offer-dialog">
        <div class="container">
          <el-form
            :model="operatForm"
            :rules="operatRules"
            ref="operatForm"
            label-width="60px"
          >
            <el-form-item label="原因" prop="deleteReason">
              <el-input v-model="operatForm.deleteReason"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="text-center">
          <el-button type="primary" :loading="loading2" @click="operatSave">
            确认
          </el-button>
          <el-button @click="operatCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { dataMethod } from '@/utils/table-merge.js'
  import ClientSettingPublicModel from './components/client-setting-public-model'
  import ClientSettingTypeModel from './components/client-setting-type-model'
  import ClientSettingPayModel from './components/client-setting-pay-model'
  import ClientSettingLimit from './components/client-setting-limit'
  import RoleModel from './components/role-model'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import AuditProcess from '@/components/AuditProcess/index'

  import {
    clientFollowList,
    clientSourceList,
    clientSourceDelete,
    customerWarningDetail,
  } from '@/api/client-supplier/client-setting.js'
  import { FinanceCurrencyList } from '@/api/finance/finance-setting'
  import {
    paymentListPage,
    paymentToVoid,
    paymentDeleteById,
    customerManagerSettingUpdatePO,
    customerManagerSettingGetDetail,
  } from '@/api/client-supplier/supplier-setting'
  import { mapState } from 'vuex'
  import { putPayStatus } from '@/utils/en-match-zh'
  import {
    getDetail,
    insertCustomerMessageConfigurePO,
  } from '@/api/customer-manage/customer-setting'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  import { data } from 'cheerio/lib/api/attributes'
  export default {
    name: 'ClientSetting',
    components: {
      ClientSettingPublicModel,
      ClientSettingTypeModel,
      ClientSettingPayModel,
      ClientSettingLimit,
      Audit,
      RoleModel,
      AuditProcess,
    },
    data() {
      return {
        loading: false,
        loading2: false, //作废
        tableDataSetting: [],
        currency: [], //货币下拉
        requireForm: {}, //请求参数
        tableData: [], // 来源列表
        tableData1: [], // 跟进列表
        clientPayData: [], // 收款列表
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数
        spanObj: {}, //客户来源合并
        spanObj2: {}, //客户收款方式合并
        limitInfo: null,
        auditObjectId: '',
        auditType: 11,
        operatForm: {
          deleteReason: '', //作废理由
          paymentId: '',
          payee: 1, //客户
        },
        operatRules: {
          deleteReason: [
            { required: true, message: '请输入原因', trigger: 'blur' },
            {
              max: 50,
              message: '长度不能超过50个字符',
              trigger: 'blur',
            },
          ],
        },
        operatVisible: false,
        proposerParams: {
          handlerType: 0, //客户分管申请处理人
          roleName: '', //指定角色
          roleId: '', //指定角色id
          parentId: '', //指定角色父级d
          customerManagerSettingId: '', //业务唯一id
        },
        integralTable: [], // 节分配置
        levelTable: [], // 阶段配置
      }
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
      }),
    },
    filters: {
      //客户收款审核状态
      statusFilter(status) {
        const statusMap = {
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'info',
        }
        return statusMap[status]
      },
    },
    created() {
      this.getClientLimit()
      this.getClientFollowList()
      this.getClientSourceList()
      this.getPaymentListPage()
      this.getCurrency()
      getDetail().then((res) => {
        if (res.code === '000000') {
          this.tableDataSetting = res.data
        }
      })
      //查询客户分管申请数据
      //this.getCustomerManagerSettingGetDetail()
      // this.getCustomerIntegralConfigure()
      // this.customerIntegralLevel()
    },
    methods: {
      save() {
        this.loading = true
        insertCustomerMessageConfigurePO(
          this.tableDataSetting.map((item) => {
            let obj = {}
            obj.id = item.id
            obj.emailNotice = item.emailNotice
            obj.messageNotice = item.messageNotice
            return obj
          })
        ).then((res) => {
          this.loading = false
          if (res?.code === '000000') {
            this.$message.success('操作成功')
          }
        })
      },
      getCustomerManagerSettingGetDetail() {
        customerManagerSettingGetDetail({
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.proposerParams.handlerType = res.data.handlerType
            this.proposerParams.customerManagerSettingId =
              res.data.customerManagerSettingId
            this.proposerParams.roleName = res.data.roleName
            this.proposerParams.roleId = res.data.roleId
            this.proposerParams.parentId = res.data.parentId
          }
        })
      },
      //客户分管申请处理人change
      radioChange(val) {
        if (val === 1 && this.proposerParams.roleId === '') {
          this.$refs['RoleModel'].showAddEdit(this.proposerParams)
        } else if (val === 0) {
          this.proposerParams.roleName = ''
          this.proposerParams.roleId = ''
          this.proposerParams.parentId = ''
          customerManagerSettingUpdatePO(this.proposerParams).then((res) => {
            if (res && res.data) {
              this.$baseMessage(
                '修改成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          })
        }
      },
      changeChange() {
        this.$refs['RoleModel'].showAddEdit(this.proposerParams)
      },
      roleClose() {
        if (!this.proposerParams.roleName) {
          this.proposerParams.handlerType = 0
        }
      },
      // 货币
      getCurrency() {
        FinanceCurrencyList().then((res) => {
          if (res && res.code === '000000') {
            this.currency = res.data || []
          }
        })
      },

      getCurrencyCode(name) {
        const curCurr = this.currency.find((c) => c.financeName === name)
        return curCurr ? curCurr.code : '-'
      },
      // 表格最大高度计算
      tableHeight(num = 0) {
        return window.innerHeight - num + 'px'
      },
      //客户收款方式
      async getPaymentListPage() {
        let response = await paymentListPage({ payee: 1 })
        if (response.code === '000000') {
          this.clientPayData = response.data
        }
      },
      flitterData(arr) {
        let spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.payType === 1) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return {
          one: spanOneArr,
        }
      },
      objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          const _row = this.flitterData(this.clientPayData).one[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },

      //收款方式新增编辑
      clinetPayClick(paymentId) {
        this.$refs['ClientSettingPayModel'].showAddEdit(paymentId)
      },
      //收款方式删除
      async clientPayDelClick(paymentId) {
        this.$confirm('确定将该收款方式删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await paymentDeleteById({
            paymentId: paymentId,
            payee: 1,
          })
          if (response.code === '000000') {
            this.$baseMessage(
              '删除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
            this.getPaymentListPage()
          }
        })
      },
      //收款作废
      invoiceDeleteClick(paymentId) {
        this.operatForm.paymentId = paymentId
        this.operatVisible = true
      },
      // 作废保存
      operatSave() {
        this.$refs['operatForm'].validate((valid) => {
          if (valid) {
            this.$confirm(`确定作废, 操作之后无法修改?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.loading2 = true
                paymentToVoid(this.operatForm).then((res) => {
                  this.loading2 = false
                  if (res.data) {
                    this.$baseMessage(
                      '作废成功',
                      'success',
                      false,
                      'erp-hey-message-success'
                    )
                    this.operatCancel()
                    this.getPaymentListPage()
                  } else {
                    this.$baseMessage(
                      '作废失败',
                      'error',
                      false,
                      'erp-hey-message-success'
                    )
                    this.operatCancel()
                  }
                })
              })
              .catch(() => {})
          }
        })
      },
      //作废取消
      operatCancel() {
        this.$refs['operatForm'].resetFields()
        this.operatVisible = false
      },

      //审核状态匹配
      putPayStatus(val) {
        return putPayStatus(val)
      },

      //获取客户预警额度
      getClientLimit() {
        customerWarningDetail({
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res.code === '000000') {
            this.limitInfo = res.data
          }
        })
      },
      // 客户来源列表
      getClientSourceList() {
        clientSourceList({
          sourceId: this.userInfo.userId,
        }).then((res) => {
          if (res.code === '000000') {
            this.tableData = res.data || []
          }
        })
      },
      // 客户跟进列表
      getClientFollowList() {
        clientFollowList().then((res) => {
          if (res.code === '000000') {
            this.tableData1 = res.data || []
            //客户跟进
            this.spanObj = dataMethod(this.tableData1, ['customerType'])
          }
        })
      },
      //新增编辑客户来源
      setClientSource(row) {
        this.$refs['ClientSettingPublicModel'].showAddEdit(row)
      },
      //删除客户来源
      removeClientSource(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          clientSourceDelete({ businessId: row.sourceId }).then((res) => {
            if (res.code === '000000') {
              this.$message.success('删除成功！')
              this.getClientSourceList()
            }
          })
        })
      },
      // 设置金额上限
      setUpperLimit() {
        this.$refs['ClientSettingLimit'].showDialog(this.limitInfo)
      },

      //客户跟进编辑
      setClientFollow(row) {
        this.$refs['ClientSettingTypeModel'].showAddEdit(row)
      },
      //客户跟进合并
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        let _row = this.spanObj[column.property]
          ? this.spanObj[column.property][rowIndex]
          : 1
        let _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      },
      //收款方式新增编辑
      clientPayClick(str) {
        this.$refs['ClientSettingPayModel'].showAddEdit()
      },
      // 获取积分配置
      async getCustomerIntegralConfigure() {
        this.integralTable = await CustomerConfigInteractor.customerConfig()
        console.log(this.integralTable, '00')
      },
      // 更新积分配置
      async saveIntegral() {
        let res = await CustomerConfigInteractor.updateCustomerIntegralConfig(
          this.integralTable
        )

        if (res.code === '000000') {
          this.$message.success('更新成功')
          this.getCustomerIntegralConfigure()
        }
      },
      // 阶段配置
      async customerIntegralLevel() {
        this.levelTable =
          await CustomerConfigInteractor.getCustomerIntegralLevel()
        if (this.levelTable.length == 0) {
          let arr = ['一星', '二星', '三星', '四星', '五星']
          this.levelTable = arr.map((it) => {
            return {
              startValue: 0,
              endValue: 0,
              integralLevel: it,
            }
          })
        }
      },
      // 保存阶段配置 updateCustomerIntegralLevel
      async saveLeval() {
        let res = await CustomerConfigInteractor.updateCustomerIntegralLevel(
          this.levelTable
        )
        if (res.code === '000000') {
          this.$message.success('更新成功')
          this.customerIntegralLevel()
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-table {
    width: 100%;
  }
  .main-height {
    height: calc(100% - 52px);
  }
  .table-height-box {
    height: calc(100% - 4px);
    overflow: hidden;
    overflow-y: auto;
  }
  .col {
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
  }
  .upper-limit {
    border: 1px solid #ededed;
    padding: 10px;
    margin-bottom: 20px;
    .desc {
      line-height: 32px;
      font-size: 14px;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      strong {
        margin-right: 10px;
      }
      > span {
        flex: 1;
      }
    }
  }
  .public-col {
    border: 1px solid #ededed;
    padding: 10px;
    flex: 1;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: auto;

    p {
      line-height: 32px;
    }
  }
  .public-col2 {
    border: 1px solid #ededed;
    padding: 10px;
    p {
      line-height: 32px;
    }
  }
  ::v-deep {
    .el-tag {
      border: none;
    }
    .el-tag--plain {
      background: none;
    }
  }
</style>
