<!--客户中心  -->
<template>
  <div class="custom-container track-container">
    <div class="top-wrapper">
      <!-- 搜索表单 -->
      <SearchForm
        ref="searchForm"
        :columns="columns"
        @do-search="doButtonSearch"
        @operateSave="operateSave"
      />
      <!-- 列表 -->
      <CusTable
        ref="cusTable"
        :columns="columns"
        :tableData="tableData"
        @get-list="doSearch"
        @reset-pageNo="page.pageNo = 1"
        @current-change="setCurrentRow"
        :page="page"
        :total="total"
        :tableHeight="tableHeight"
      />
    </div>
    <div class="bottom-wrapper">
      <ReplayRecord :tableData="currentHistory"></ReplayRecord>
    </div>
  </div>
</template>

<script>
  import { columns } from '@/views/customer-manage/helper/data'
  import SearchForm from './components/search-form'
  import CusTable from './components/cus-table'
  import ReplayRecord from './components/replay-record'
  import {
    listPage,
    getCustomerComplainRecords,
  } from '@/api/customer-manage/customer-center'
  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  import { getKeyIds } from '@/utils/pagelist-util'

  export default {
    name: 'CustomerCenter',
    data() {
      return {
        currentHistory: [],
        tableData: [], //列表数据
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0, // 总条数
        currentRow: Object.create(null), //当前选中行
        tableHeight: 300,
        columns: [],
      }
    },
    components: {
      SearchForm,
      CusTable,
      ReplayRecord,
    },
    created() {
      this.columns = columns
      this.initTableData()
      this.getTableHeight()
      this.getColumsData()
    },
    watch: {
      'currentRow.customerComplainId': function (val) {
        val && this.getRecords(val)
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    methods: {
      getTableHeight() {
        const { height } = document.body.getBoundingClientRect()

        this.tableHeight = height * (3 / 5) - 160 + 'px'
      },
      // 获取历史记录
      getRecords(businessId) {
        getCustomerComplainRecords(businessId).then((res) => {
          if (res?.code === '000000') {
            this.currentHistory = res.data
          }
        })
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(15, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },
      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 15,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
      // 初始化列表数据
      initTableData() {
        let { evaluationCode } = this.$route.query
        let params = {}
        if (evaluationCode) {
          params.evaluationCode = evaluationCode
        } else {
          params = {}
        }
        params.keyIds = getKeyIds(this)
        listPage(params).then((res) => {
          if (res?.code === '000000') {
            this.tableData = res.data.data
            this.total = res.data.total
            if (this.tableData.length > 0) {
              this.setCurrentRow(this.tableData[0])
            }
          }
        })
      },

      // 搜索事件
      doButtonSearch() {
        this.page.pageNo = 1
        this.doSearch()
      },

      doSearch() {
        const params = this.$refs.searchForm.getSearchParams()
        listPage({
          ...params,
          ...this.page,
          keyIds: getKeyIds(this),
        }).then((res) => {
          if (res?.code === '000000') {
            this.tableData = res.data.data
            this.total = res.data.total
            if (this.tableData.length > 0) {
              this.setCurrentRow(this.tableData[0])
            }
          }
        })
      },
      setCurrentRow(row) {
        this.currentRow = row
        this.$nextTick(() => {
          this.$refs?.cusTable?.$refs?.trackTable?.$refs?.table?.setCurrentRow(
            row
          )
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-container.track-container {
    height: 100vh !important;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
  }
  .top-wrapper {
    flex: 3;
    overflow-y: hidden;
  }

  .bottom-wrapper {
    flex: 2;
    overflow: hidden;
  }
</style>
