var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-con"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? '修改密码' : 'Update Your Login Password')+" ")]),_c('div',{staticClass:"tips"},[_c('i',{staticClass:"el-icon-warning"}),_c('div',[_c('p',{staticClass:"top bold"},[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? '为了您的账号安全，请您及时修改登录密码' : 'For the security of your account, please promptly update your login password')+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? '8~20位数字、大/小写字母、符号，密码强度需达到「强」' : 'Password should be 8-20 characters long, including a combination of numbers, letters, symbols until the password has a strength rating of "Strong".')+" ")])])]),_c('el-form',{ref:"form",attrs:{"label-width":"0px","model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"password","rules":[
        {
          required: true,
          message: this.$t('rules.$10'),
          min: 8,
          max: 20,
          trigger: ['blur', 'change'],
        } ]}},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.lang === 'zh' ? '请输入新密码' : 'Please input a new password',"size":"small","clearable":"","tabindex":"2","maxlength":"20","show-password":"","onkeyup":"this.value = this.value.replace(/[\\u4E00-\\u9FA5]|[\\uFE30-\\uFFA0]/g,'')"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.password"}},[_c('i',{staticClass:"iconfont el-icon-s-goods",attrs:{"slot":"prefix"},slot:"prefix"})])],1),_c('password-check',{attrs:{"lang":_vm.lang,"password":_vm.form.password},on:{"isPass":_vm.isPass}}),_c('el-form-item',{attrs:{"prop":"confirmPassword","rules":[
        {
          required: true,
          message: this.$t('rules.$10'),
          min: 8,
          max: 20,
          trigger: ['blur', 'change'],
        } ]}},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.lang === 'zh'
            ? '请再次输入新密码'
            : 'Please enter a new password again',"size":"small","clearable":"","tabindex":"2","maxlength":"20","show-password":"","onkeyup":"this.value = this.value.replace(/[\\u4E00-\\u9FA5]|[\\uFE30-\\uFFA0]/g,'')"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.confirmPassword"}},[_c('i',{staticClass:"iconfont el-icon-s-goods",attrs:{"slot":"prefix"},slot:"prefix"})])],1),(_vm.isSame)?_c('p',{staticClass:"text-tips"},[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? '两次输入密码不一致' : 'The two passwords are inconsistent')+" ")]):_vm._e()],1),_c('el-button',{staticClass:"login-btn",attrs:{"type":"primary"},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.lang === 'zh' ? '确认并登录' : 'Confirm and Sign in')+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }