<template>
  <div>
    <p class="title">
      <!-- 安排发货邮件默认抄送设置：可设置抄送多个邮箱，设置后，发货邮件自动发送后，会自动抄送给设置的邮箱； -->
      <span>
        {{ $t('mailConfig.ScheduleShipmentEmailDefaultCcSettings') }}：
      </span>
    </p>
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
      style="margin-top: 20px"
    >
      <!-- 抄送邮箱 -->
      <el-form-item :label="$t('mailConfig.CcMailbox')">
        <!-- 请输入邮箱 -->
        <el-autocomplete
          class="inline-input"
          v-model="form.emailAccount"
          :fetch-suggestions="querySearch"
          :placeholder="$t('placeholder.enterEmail')"
          @change="handleChange"
          value-key="emailAccount"
          clearable
        ></el-autocomplete>
        <el-button
          style="margin-left: 20px"
          type="primary"
          plain
          @click="addTagList"
        >
          <!-- 添加 -->
          {{ $t('other.Add') }}
        </el-button>
      </el-form-item>
      <el-form-item style="margin-top: -10px">
        <el-tag
          @close="delteTag(item)"
          size="small"
          v-for="item in form.deliverCCEmail"
          :key="item"
          closable
        >
          {{ item }}
        </el-tag>
      </el-form-item>
      <!-- 抄送方式 -->
      <el-form-item :label="$t('mailConfig.CcMethod')">
        <el-radio-group v-model="form.deliverCCType">
          <!-- 正常抄送 -->
          <el-radio :label="0">{{ $t('other.Sendmail') }}</el-radio>
          <!-- 密送 -->
          <el-radio :label="1">{{ $t('myMail.Bcc') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  export default {
    name: 'plan-ship',
    data() {
      return {
        form: {
          emailAccount: '',
          deliverCCType: 0,
          deliverCCEmail: [],
        },
        tagList: [],
      }
    },
    props: {
      accountList: {
        default: () => [],
        type: Array,
      },
      baseForm: {
        default: () => {},
        type: Object,
      },
    },
    computed: {
      settingData() {
        let obj = {
          deliverCCType: this.form.deliverCCType,
          deliverCCEmail: this.tagList.toString(),
        }
        return obj
      },
    },
    watch: {
      form: {
        handler() {
          this.commitMsg()
        },
        deep: true,
        immediate: true,
      },
      baseForm: {
        handler() {
          this.form.deliverCCType = this.baseForm?.deliverCCType || 0
          this.form.deliverCCEmail = this.baseForm?.deliverCCEmail
            ? this.baseForm.deliverCCEmail?.split(',')
            : []
          this.form.emailAccount = ''
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      commitMsg() {
        let dt = {
          deliverCCEmail: this.form.deliverCCEmail?.toString(),
          deliverCCType: this.form.deliverCCType,
        }
        this.$emit('changePlanShipMsg', dt)
      },
      querySearch(queryString, cb) {
        var accountList = this.accountList
        var results = queryString
          ? accountList.filter(this.createFilter(queryString))
          : accountList
        // 调用 callback 返回建议列表的数据
        cb(results)
      },
      createFilter(queryString) {
        return (res) => {
          return (
            res.emailAccount
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) === 0
          )
        }
      },
      handleChange(val) {
        if (!this.utils.isEmail(val)) {
          // 邮件地址格式不正确
          this.$message.warning(this.$t('reqmsg.$63'))
          this.form.emailAccount = ''
          return
        }
        // this.form.emailAccount = this.utils.isEmail(val) ? val : ''
      },
      // 添加
      addTagList() {
        if (
          (this.form.deliverCCEmail.length == 0 ||
            this.form.deliverCCEmail?.indexOf(this.form.emailAccount) < 0) &&
          this.form.emailAccount != ''
        ) {
          this.form.deliverCCEmail.push(this.form.emailAccount)
        } else if (
          this.form.deliverCCEmail?.indexOf(this.form.emailAccount) >= 0
        ) {
          // 请勿重复添加邮箱
          this.$message.warning(this.$t('reqmsg.$64'))
        }

        this.form.emailAccount = ''
      },
      // 删除
      delteTag(item) {
        this.form.deliverCCEmail?.splice(
          this.form.deliverCCEmail.indexOf(item),
          1
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  .title {
    margin-bottom: 20px;
    color: #000;
    font-size: 15px;

    .info {
      color: #999;
    }
  }
</style>
