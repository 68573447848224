<template>
  <el-dialog
    title="货币设置"
    :visible.sync="dialogVisible"
    width="400px"
    @close="close"
    class="dialog-middle"
  >
    <el-form
      :model="form"
      label-width="80px"
      :rules="rules"
      size="small"
      ref="form"
    >
      <el-form-item label="国家货币" prop="financeName">
        <el-input
          v-model="form.financeName"
          maxlength="50"
          :disabled="form.financeSetupId == '1' || form.financeSetupId == '2'"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="货币代码" prop="code">
        <el-input
          v-model="form.code"
          maxlength="50"
          :disabled="form.financeSetupId == '1' || form.financeSetupId == '2'"
          placeholder="请输入"
        ></el-input>
        <!-- <el-select v-model="form.code" value-key="code">
          <el-option
            v-for="item in currencySelect"
            :key="item.ckey"
            :label="item.code"
            :value="item.ckey"
          ></el-option>
        </el-select> -->
      </el-form-item>
      <!-- jiangyongqiang modify fixbug bugid=7488 财务-财务设置，货币设置，货币标识编辑时不允许修改 -->
      <el-form-item label="货币标识" prop="currencyMark">
        <el-input
          v-model="form.currencyMark"
          maxlength="50"
          :disabled="form.financeSetupId == '1' || form.financeSetupId == '2'"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="汇率" prop="exchangeRate">
        <el-input
          v-model="form.exchangeRate"
          maxlength="50"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="生效时间" prop="effTime">
        <el-date-picker
          v-model="form.effTime"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickOption"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="close">取消</el-button>
      <el-button type="primary" size="mini" @click="submit" :loading="loading">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  FinanceCurrencyAdd,
  FinanceCurrencyUpdate,
} from '@/api/finance/finance-setting'
export default {
  name: 'CurrencySetting',
  data() {
    return {
      disabled: false,
      loading: false,
      dialogVisible: false,
      form: {
        financeName: '', // 货币名称
        code: '', // 代码
        currencyMark: '', // 符号
        exchangeRate: '', // 汇率
        effTime: '', // 日期
        financeSetupId: null,
      },
      pickOption: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
      },
      rules: {
        financeName: [{ required: true, message: '请输入', trigger: 'blur' }],
        code: [{ required: true, message: '请输入', trigger: 'blur' }],
        currencyMark: [{ required: true, message: '请输入', trigger: 'blur' }],
        exchangeRate: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: this.$formValidation.isRate, trigger: 'blur' },
        ],
        effTime: [{ required: true, message: '请选择', trigger: 'change' }],
      },
    }
  },
  created() {},
  methods: {
    //打开弹框
    showDialog(row) {
      this.dialogVisible = true
      // jiangyongqiang modify fixbug bugid=7488 财务-财务设置，货币设置，货币标识编辑时不允许修改  加了disabled
      if (row) {
        this.form = Object.assign({}, row)
        this.disabled = true
      } else {
        this.form.financeSetupId = null
        this.disabled = false
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    //清空表单
    close() {
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let Method
          if (this.form.financeSetupId) {
            Method = FinanceCurrencyUpdate
          } else {
            Method = FinanceCurrencyAdd
          }
          this.loading = true
          Method(this.form)
            .then((res) => {
              this.loading = false
              if (res.code == '000000') {
                this.$message.success(
                  this.form.financeSetupId ? '修改成功！' : '新增成功！'
                )
                this.$emit('success')
                this.close()
              }
            })
            .catch((err) => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.el-date-editor {
  width: 100%;
}
</style>
