<template>
  <div class="tab-content">
    <el-button type="primary" @click="editSign()">
      <!-- 新增 -->
      {{ $t('boxsetting.Add') }}
    </el-button>
    <div class="account-group" v-for="(item, index) in accountList" :key="index">
      <div class="title">
        <!-- 所属账号： -->
        {{ $t('boxsetting.AttributableAccount') }}: {{ item.emailAccount }}
      </div>
      <el-table border :data="signTable[item.emailAccount]">
        <!-- label="标题" -->
        <el-table-column align="center" width="200" :label="$t('boxsetting.Title')" prop="title"></el-table-column>
        <!-- label="内容" -->
        <el-table-column :label="$t('boxsetting.Content')">
          <template slot-scope="scope">
            <div class="sign-content" v-html="scope.row.content"></div>
          </template>
        </el-table-column>
        <!-- label="操作" -->
        <el-table-column align="center" width="100" :label="$t('boxsetting.Operate')">
          <template slot-scope="scope">
            <!-- content="编辑" -->
            <el-tooltip :content="$t('iconbtn.edit')" placement="top">
              <el-button type="text" icon="el-icon-edit" @click="editSign(scope.row)"></el-button>
            </el-tooltip>
            <!-- content="删除" -->
            <el-tooltip :content="$t('iconbtn.del')" placement="top">
              <el-button type="text" icon="el-icon-delete" @click="removeSign(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <EditSignModel ref="EditSignModel" @success="getMailSignList" />
  </div>
</template>
<script>
  import { mailSignList, mailSignDelete } from '@/api/mail/mail-setting'
  import EditSignModel from '@/views/mail/setting/components/edit-sign-model'
  import { mapGetters, mapState } from 'vuex'
  import _ from 'lodash'
  export default {
    name: 'MailSign',
    components: {
      EditSignModel,
    },
    data() {
      return {
        signTable: {}, //签名列表
      }
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.user.userInfo,
        accountList: (state) =>
          state.mail.accountList.filter((item) => item.authStatus !== 2),
      }),
    },
    created() {
      this.getMailSignList()
    },
    methods: {
      // 新增编辑签名
      editSign(row) {
        this.$refs.EditSignModel.showDialog(row)
      },
      //删除签名
      removeSign(row) {
        // this.$confirm('是否确认删除？', '提示', {
        //   type: 'warning',
        // })
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          mailSignDelete({ mailSignatureId: row.mailSignatureId }).then(
            (res) => {
              if (res.code == '000000') {
                // this.$message.success('删除成功！')
                this.$message.success(this.$t('reqmsg.M2008'))
                this.getMailSignList()
              }
            }
          )
        })
      },
      // 获取签名列表
      getMailSignList() {
        mailSignList({
          businessId: this.userInfo.userId,
        }).then((res) => {
          if (res.code == '000000') {
            let list = res.data
            let obj = _.groupBy(list, 'emailAccount') //根据相同邮箱，把数组转成对象，key是邮箱，value是数组
            this.signTable = obj

          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .tab-content {
    overflow-y: auto;
    height: calc(100vh - 200px);
  }

  .account-group {
    margin-top: 20px;

    .title {
      font-size: 16px;
      color: #333;
      height: 40px;
      line-height: 40px;
    }

    .sign-content {
      width: 100%;
      overflow: hidden;

      ::v-deep img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
</style>