<template>
  <!-- title="标签管理" -->
  <el-dialog :title="$t('myMail.LabelManagement')" width="600px" :visible.sync="isDialogVisible"
    :close-on-click-modal="false" :close-on-press-escape="false">
    <div class="manage-tree-wrappper">
      <el-tree class="filter-tree" :data="treeData" :indent="10" :expand-on-click-node="false" check-strictly
        :props="treeProps" @check-change="data => checkMailMark(data)">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <p><i class="square" :style="{ 'background-color': data.labelColor }"></i>
            <span>{{ data.labelName }}</span>
          </p>

          <span>
            <el-tooltip class="item" :content="$t('iconbtn.edit')" placement="top" :open-delay="1000">
              <el-button circle size="mini" icon="el-icon-edit" type="primary" plain
                @click="editMark(node, data)"></el-button>

            </el-tooltip>
            <el-tooltip class="item" :content="$t('iconbtn.del')" placement="top" :open-delay="1000">
              <el-button circle size="mini" icon="el-icon-delete" type="danger" plain
                @click="deleteMark(node, data )"></el-button>
            </el-tooltip>
          </span>
        </span>
      </el-tree>
    </div>

  </el-dialog>
</template>
<script>
  import { MailInteractor } from '@/core'
  import { mailLabelDelete, mailLabelPageList } from '@/api/mail/mail.js'
  import { mapState } from 'vuex'
  import { BusEmit, BusOn } from '@/utils/eventBus'
  function traverse(node, result) {
    result.push(node);
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => traverse(child, result));
    }
    return result
  }
  export default {
    name: 'MarkManageModel',
    mixins: [BusOn],
    data() {
      return {
        isDialogVisible: false,
        treeData: [],
        treeProps: {
          label: 'labelName',
          children: 'children'
        }
      }
    },
    computed: {
      ...mapState({
        currentAccount: (state) => state.mail.currentAccount,
        userInfo: (state) => state.user.userInfo,
      }),
    },
    methods: {
      showDialog(emailAccount) {

        this.getList(emailAccount)
        this.isDialogVisible = true

      },
      getList(emailAccount) {
        MailInteractor.mailLabelTreeListApi({
          emailAccount: emailAccount || this.currentAccount,
        })
          .then((res) => {
            if (res?.code == '000000') {
              this.treeData = res.data || []
            } else {
              this.$message.warning(res.desc)
            }
          })
      },

      editMark(node, data) {
        this.$emit('edit-mark', { node, data })
      },
      getDeleteMark(data) {
        let result = [];
        traverse(data, result); // 从根节点开始遍历
        return result
      },
      deleteMark(node, data) {
        const msgkey = data?.children?.length > 0 ? 'myMail.DeleteMarkWithChildren' : 'myMail.DeleteMark'
        this.$confirm(this.$t(msgkey), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {

          mailLabelDelete({ mailLabelId: data.mailLabelId }).then((res) => {
            if (res?.code == '000000') {
              this.getList(data.emailAccount)
              this.$store.dispatch('mail/getMailMarkList', { emailAccount: data.emailAccount })
              BusEmit('MailLeftAside', 'refreshData')
              // BusEmit('MailList', 'getPageList',)
              BusEmit('MailList', 'batchDeleteLabel', this.getDeleteMark(data))
              // emailAccount
              BusEmit('MailInfo', 'batchDeleteLabel', this.getDeleteMark(data))
              this.$message.success(this.$t('reqmsg.M2008'))
            } else {
              this.$message.warning(res?.desc)
            }
          })
        })
      },
      close() {
        this.isDialogVisible = false
      },
      // 切换分页
      handleCurrentChange(val) {
        this.pageNo = val
        this.getList()
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.getList()
      },
    },
  }
</script>
<style scoped lang="scss">
  .square {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    margin-right: 3px;
  }

  .manage-tree-wrappper {
    height: 400px;
    overflow: auto;
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

  ::v-deep .el-tree-node__content {
    height: 30px;
  }

  .el-button--mini.is-circle {
    padding: 3px;

  }
</style>
