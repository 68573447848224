<template>
  <el-select v-bind="$attrs" v-on="$listeners" placeholder="审核类型">
    <el-option
      v-for="type in auditTypeList"
      :key="type.auditType"
      :value="type.auditType"
      :label="type.auditTypeName"
    ></el-option>
  </el-select>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { auditInteractor } from '@/core'
  export default {
    name: 'AuditTypeSelect',
    data() {
      return {
        auditTypeList: [], //审核类型下拉数据
        // selectType: {},
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },

    created() {
      auditInteractor
        .getListByTenantId({
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
        })
        .then((res) => {
          if (res && res.code === '000000') {
            this.auditTypeList = res.data
            this.$emit('get-type-list', this.auditTypeList)
          }
        })
    },

    methods: {},
  }
</script>

<style lang="scss" scoped></style>
