export const type2LogText = {
  1: '新增',
  2: '编辑',
  3: '删除',
  4: '查看',
  5: '作废',
  6: '解除预警',
  7: '无须跟单',
  8: '恢复',
  9: '列表备注'
}


