<template>
  <div class="custom-container">
    <div v-show="!isAdd">
      <!-- 头部操作栏 -->
      <el-row class="page-header">
        <el-col :span="18">
          <el-row class="flex_wrap">
            <el-button type="primary" class="mr10" @click="add">新增</el-button>
            <!-- <el-input
              v-model="creatorId"
              style="width: 260px"
              placeholder="请选择创建人"
              clearable
            /> -->
            <el-input
              style="width: 260px"
              v-model="creatorName"
              @focus="businessChange"
              @change="clearBusinessName"
              placeholder="请选择创建人"
              clearable
            ></el-input>

            <el-button class="ml10" @click="search">
              <i class="iconfont icon-fangdajing f_s_14"></i>
              搜索
            </el-button>
          </el-row>
        </el-col>
      </el-row>

      <!-- table -->
      <el-row>
        <el-table border :data="tableData" class="mt20">
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="result"
            label="条件内容"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ getResultText(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="creator"
            label="创建人"
            width="300"
          >
            <template slot-scope="scope">
              {{ scope.row.creator + '(' + scope.row.creatorEn + ')' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            label="状态"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status === 1">启用</span>
              <span v-else>冻结</span>
            </template>
          </el-table-column>

          <!-- 操作 -->
          <el-table-column align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="medium"
                @click="setStatus(scope.row)"
              >
                {{ scope.row.status === 1 ? '冻结' : '启用' }}
              </el-button>
              <el-button type="text" size="medium" @click="edit(scope.row)">
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 分页 -->
      <el-pagination
        background
        layout="total, sizes,  prev, pager, next, jumper"
        :current-page="page.pageNo"
        :page-size="page.pageLe"
        :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
        :total="total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>

    <!-- 新增 -->
    <condition-add
      :isAdd="isAdd"
      :areasCode="areasCode"
      :conditionOptions="conditionOptions"
      @Cancel="isAdd = false"
      @Add="conditionAdd"
    ></condition-add>

    <!-- 编辑 -->
    <condition-edit
      :isEdit="isEdit"
      :editRow="editRow"
      :areasCode="areasCode"
      :conditionOptions="conditionOptions"
      @Cancel="isEdit = false"
      @Edit="conditionEdit"
    ></condition-edit>

    <!-- 人员组织树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
import conditionAdd from './add'
import conditionEdit from './edit'
import { FinanceConfigSetting } from '@/core/interactors/finance/config-manage'
import UserChoose from '@/components/userChoose'
export default {
  name: 'ConditionConfig',
  components: {
    conditionAdd,
    conditionEdit,
    UserChoose,
  },
  // 组件状态值
  data() {
    return {
      creatorId: '', // 创建人
      creatorName: '', // 创建人姓名
      page: {
        pageNo: 1,
        pageLe: 10,
      },
      tableData: [],
      conditionOptions: [], // 条件配置
      areasCode: [], // 地区code集合
      editRow: {}, // 编辑数据
      total: 0,
      isAdd: false,
      isEdit: false,
    }
  },
  created() {
    this.getList()
    this.getConditionOptions()
  },
  methods: {
    // 搜索
    search() {
      this.page.pageNo = 1
      this.getList()
    },

    // 选择主管
    businessChange() {
      this.$refs['UserChoose'].showAddEdit('', '', true)
    },

    // 获取主管
    chooseUser(arr) {
      this.creatorId = arr[0].id
      this.creatorName = arr[0].name + '(' + arr[0].englishName + ')'
    },

    // 删除主管
    clearBusinessName() {
      this.creatorId = ''
      this.creatorName = ''
    },

    // 获取条件配置
    async getConditionOptions() {
      this.conditionOptions = await FinanceConfigSetting.getConditionOptions()
      this.conditionOptions.settlementAreaVO.forEach((item) =>
        this.areasCode.push(item.code)
      )
    },

    // 获取列表
    async getList() {
      let res = await FinanceConfigSetting.getConditionList(
        this.page,
        this.creatorId
      )

      this.tableData = res.data
      this.total = res.total
    },

    //切换分页
    pageChange(val) {
      this.page.pageNo = val
      this.getList()
    },

    // 分页数量变化
    sizeChange(val) {
      this.page.pageLe = val
      this.getList()
    },

    // 条件内容格式化
    getResultText(row) {
      let [arr1, arr2, text1] = [
        JSON.parse(row.conditionResult),
        JSON.parse(row.result),
        '',
      ]
      for (let i = 0; i < arr1.length; i++) {
        text1 +=
          '【' +
          (arr1[i].message || '--') +
          '】' +
          (arr1.length === i + 1 ? '' : '和')
      }
      return text1 + ' = ' + arr2[0].message
    },

    // 设置状态 status(1启用, 0冻结)
    setStatus(row) {
      let params = {
        status: row.status === 1 ? 0 : 1,
        configConditionId: row.configConditionId,
      }
      if (row.status === 1) {
        this.$confirm(`冻结后，新单据则无法调用此模板！`, '提示', {
          type: 'warning',
        })
          .then(() => {
            this.setStatusRes(params, false)
          })
          .catch(() => {})
      } else {
        this.setStatusRes(params, true)
      }
    },

    // 设置状态回调 type true:启用 false:冻结
    async setStatusRes(params, type) {
      let res = await FinanceConfigSetting.conditionStatus(params)
      if (res.code === '000000') {
        this.$message.success(`${type ? '启用' : '冻结'}成功`)
        this.getList()
      }
    },

    // 新增
    add() {
      this.isAdd = true
    },

    // 编辑
    edit(row) {
      this.editRow = row
      this.isEdit = true
    },

    // 新增成功
    conditionAdd() {
      this.search()
      this.isAdd = false
    },

    // 编辑保存
    conditionEdit() {
      this.search()
      this.isEdit = false
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-table {
    height: calc(100vh - 200px);
    border-left: none;
    overflow-y: auto;
  }
  .el-pagination {
    height: 36px;
  }
}
</style>
