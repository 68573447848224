<template>
  <div class="content p30" style="margin-top: -22px;">
    <!-- 采购付款 -->
    <el-row v-if="payType === 1">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="5">
          <p>付款单号</p>
          <span>{{ form.number }}</span>
          <span class="ml10">
            <el-tag type="warning" plain v-if="form.state == 1" size="small">
              待处理
            </el-tag>
            <el-tag type="danger" plain v-if="form.state == 3" size="small">
              已驳回
            </el-tag>
            <el-tag type="success" plain v-if="form.state == 2" size="small">
              已完成
            </el-tag>
          </span>
        </el-col>
        <el-col :span="4">
          <p>付款类型</p>
          <span>
            {{ entryType == 0 ? '采购付款' : '采购退款' }}
          </span>
        </el-col>
        <el-col :span="5">
          <p>买方</p>
          <span>{{ form.purchaser || '--' }}</span>
        </el-col>

        <el-col :span="5" v-show="payType === 2">
          <p>客户代表</p>
          <span>{{ form.buyerName || '--' }}</span>
        </el-col>
        <el-col :span="5">
          <p>{{ entryType == 0 ? '付款总金额' : '退款总金额' }}</p>
          <span class="bold" :class="entryType == 0 ? 'green' : 'red'">
            <em>{{ entryType == 0 ? '' : '-' }}</em>
            {{ form.currency == '人民币' ? '￥' : '$'
            }}{{ utils.numberFormat(money, 2) }} &nbsp; &nbsp;
          </span>
          <span class="f_s_12" v-if="form.currency == '美元'">
            汇率：{{ form.exchangeRate }} &nbsp;&nbsp;RMB：
            <em :class="entryType == 0 ? 'green' : 'red'">
              <em>{{ entryType == 0 ? '' : '-' }}</em>
              {{ utils.numberFormat(money * form.exchangeRate, 2) }}
            </em>
          </span>
        </el-col>
      </el-row>
      <!-- 采购付款显示 -->
      <el-row
        class="mt20 detail-header"
        :gutter="20"
        v-if="topTableData && topTableData.length"
      >
        <el-col :span="7">
          <p>供应商名称</p>
          <span>
            {{ topTableData[0].supplierName || '--' }}
            <el-tag
              v-if="form.isAgreement == 1"
              type="danger"
              size="mini"
              style="width: auto; margin-left: 10px"
            >
              协议
            </el-tag>
          </span>
        </el-col>
        <el-col :span="16">
          <p>账户信息</p>
          <span>{{ topTableData[0].accountName }}</span>
          <span v-if="topTableData[0].bankName">
            / {{ topTableData[0].bankName }}
          </span>
          <span v-if="topTableData[0].bankAccount">
            / {{ topTableData[0].bankAccount }}
          </span>
          <span v-if="topTableData[0].dutyAccount">
            / {{ topTableData[0].dutyAccount }}
          </span>
        </el-col>
      </el-row>
      <!-- 底部表格 -->
      <el-row class="mt30">
        <div v-for="(tableData, index) in topTableData" :key="index">
          <el-table :data="[tableData]" border style="width: 100%">
            <!-- 采购信息 -->
            <el-table-column align="center" width="55" label="#">
              {{ index + 1 }}
            </el-table-column>
            <el-table-column width="140" align="center" label="采购订单号">
              <template slot-scope="scope">
                <span class="blue-text">
                  <router-link
                    :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
                      scope.row.purchaseOrderId
                    }&noReturn=${true}`"
                    target="_blank"
                  >
                    {{ scope.row.purchaseOrderNumber }}
                  </router-link>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              width="120"
              align="center"
              label="采购开发"
              prop="buyerName"
            ></el-table-column>
            <el-table-column
              width="80"
              align="center"
              label="币种"
              prop="currency"
            ></el-table-column>
            <el-table-column
              prop="amountApplication"
              align="center"
              :label="entryType == 0 ? '付款金额' : '退款金额'"
            >
              <template slot-scope="scope">
                <span :class="entryType == 0 ? 'green' : 'red'">
                  {{ entryType == 0 ? '' : '-' }}
                  {{ utils.numberFormat(scope.row.amountApplication, 2) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="amountTotal" align="center" label="订单金额">
              <template slot-scope="scope">
                <span>{{ utils.numberFormat(scope.row.amountTotal, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="amountActual"
              align="center"
              label="已付金额"
            >
              <template slot-scope="scope">
                <span class="green-bold">
                  {{
                    detailId.isNewPayMent
                      ? utils.numberFormat(scope.row.amountPaid, 2)
                      : utils.numberFormat(scope.row.amountActual, 2)
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="未付金额">
              <template slot-scope="scope">
                <span class="red-bold">
                  {{
                    detailId.isNewPayMent
                      ? utils.numberFormat(
                          scope.row.amountTotal - scope.row.amountPaid,
                          2
                        )
                      : utils.numberFormat(
                          scope.row.amountTotal -
                            scope.row.amountActual +
                            scope.row.amountRefunded,
                          2
                        )
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              align="center"
              label="付款方式"
              width="500"
            >
              <template slot-scope="scope">
                <template>
                  <span
                    v-for="(item, index) in scope.row
                      .purchasePaymentMethodShowVOList"
                    :key="index"
                  >
                    <i
                      :style="{
                        'font-style': 'normal',
                        display: item.percent === 0 ? 'none' : '',
                      }"
                      v-if="item.type === 0"
                    >
                      <i style="font-style: normal" v-show="index != 0">,</i>
                      {{ item.percent * 100 }}%{{ item.paymentNodeName }}
                    </i>
                  </span>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <el-table border :data="tableData.financeOrderVOList">
            <el-table-column align="center" type="index" width="55" label="#">
              <template slot-scope="scope">
                <span v-if="scope.$index < 9">0{{ scope.$index + 1 }}</span>
                <span v-else>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="140"
              prop="orderCode"
              align="center"
              label="销售订单号"
            >
              <template slot-scope="scope">
                <span
                  class="page-link"
                  @click="purchaseOrderNumberClick(scope.row.orderCode)"
                >
                  {{ scope.row.orderCode }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="businessName"
              align="center"
              label="客户代表"
            ></el-table-column>
            <el-table-column
              prop="area"
              align="center"
              label="地区"
            ></el-table-column>
            <el-table-column
              prop="customerName"
              align="center"
              label="客户姓名"
            >
              <template slot-scope="scope">
                {{ scope.row.customerName || '--' }}
              </template>
            </el-table-column>
            <el-table-column prop="companyName" align="center" label="公司名称">
              <template slot-scope="scope">
                {{ scope.row.companyName || '--' }}
              </template>
            </el-table-column>

            <el-table-column
              prop="totlePrice"
              align="center"
              label="订单总金额"
            >
              <template slot-scope="scope">
                <el-popover placement="bottom" trigger="click">
                  <span
                    class="blue-text"
                    slot="reference"
                    @click="totlePriceClcik(scope.row.orderCode)"
                  >
                    <i v-if="scope.row.totlePrice" style="font-style: normal">
                      {{ scope.row.currency === '美元' ? '$' : '￥' }}
                    </i>
                    {{ scope.row.totlePrice | orderMoneyFormat }}
                  </span>
                  <sale-detail-table
                    :moneySign="moneySign"
                    :salesGatheringVOS="salesGatheringVOS"
                    @receiptNoClick="receiptNoClick"
                  ></sale-detail-table>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              prop="amountReceived"
              align="center"
              label="已收金额"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.amountReceived">
                  {{ scope.row.currency === '美元' ? '$' : '￥' }}
                </span>
                {{ scope.row.amountReceived | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="outstandingAmount"
              align="center"
              label="未收金额"
            >
              <template slot-scope="scope">
                {{ scope.row.outstandingAmount | orderMoneyFormat }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-row>
      <el-row></el-row>
      <el-row class="mt20 remark">
        <el-col :span="8">
          <p>备注</p>
          <span>{{ form.remark || '未填写' }}</span>
        </el-col>
        <el-col :span="12">
          <p>附件</p>
          <div v-show="fileList.length">
            <div class="flie-list" v-for="(item, i) in fileList" :key="i">
              <i class="el-icon-document"></i>
              <a class="page-link ml5">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top-start"
                >
                  <span class="ell">
                    <router-link
                      :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                      target="_blank"
                    >
                      {{ item.name }}
                    </router-link>
                  </span>
                </el-tooltip>
              </a>
              <span class="size ml10">
                {{ utils.calculateFileSize(item.size) }}
              </span>
              <span class="ml10">{{ item.time }}</span>
            </div>
          </div>
          <div v-show="!fileList.length">无</div>
        </el-col>
      </el-row>
      <el-row class="mt20 remark">
        <el-col :span="8">
          <p>创建人</p>
          <span>{{ form.creator || '--' }}</span>
        </el-col>
        <el-col :span="8">
          <p>创建时间</p>
          <span>{{ form.createTime || '--' }}</span>
        </el-col>
      </el-row>
    </el-row>

    <!-- 销售退款 -->
    <el-row v-if="payType === 2">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="7">
          <p>付款单号</p>
          <span>{{ form.number }}</span>
          <span class="ml10">
            <el-tag type="warning" plain v-if="form.state == 1" size="small">
              待处理
            </el-tag>
            <el-tag type="danger" plain v-if="form.state == 3" size="small">
              已驳回
            </el-tag>
            <el-tag type="success" plain v-if="form.state == 2" size="small">
              已完成
            </el-tag>
          </span>
        </el-col>
        <el-col :span="5">
          <p>付款类型</p>
          <span>销售退款</span>
        </el-col>
        <el-col :span="5" v-show="payType === 2">
          <p>客户代表</p>
          <span>
            {{
              (form.applicationPayOrderChildShowVOList &&
                form.applicationPayOrderChildShowVOList[0].buyerName) ||
              '--'
            }}
          </span>
        </el-col>
        <el-col :span="7">
          <p>付款总金额</p>
          <span class="red bold">
            -{{ moneySign }}{{ money }} &nbsp; &nbsp;
          </span>
        </el-col>
      </el-row>
      <!-- 采购付款显示 -->
      <el-row class="mt20 detail-header" :gutter="20">
        <el-col :span="7">
          <p>客户姓名</p>
          <span>
            {{ topTableData[0].supplierName || '--' }}
          </span>
        </el-col>
        <el-col :span="16">
          <p>账户信息</p>
          <span v-if="topTableData[0].bankAccount">
            {{ topTableData[0].bankAccount || '--' }}
          </span>
        </el-col>
      </el-row>
      <!-- 底部表格 -->

      <el-row class="mt30">
        <el-table
          border
          :data="form.salesOrderShowVOS"
          :default-expand-all="true"
          style="width: 100%"
          class="bottom-table"
        >
          <el-table-column
            width="140"
            prop="orderCode"
            align="center"
            label="销售订单号"
          >
            <template slot-scope="scope">
              <span
                class="page-link"
                @click="purchaseOrderNumberClick(scope.row.orderCode)"
              >
                {{ scope.row.orderCode }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="businessName"
            align="center"
            label="客户代表"
          ></el-table-column>
          <el-table-column
            prop="area"
            align="center"
            label="地区"
          ></el-table-column>

          <el-table-column prop="companyName" align="center" label="公司名称">
            <template slot-scope="scope">
              {{ scope.row.companyName || '--' }}
            </template>
          </el-table-column>

          <el-table-column prop="totlePrice" align="center" label="订单总金额">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="hover">
                <span
                  class="blue-text"
                  slot="reference"
                  @click="totlePriceClcik(scope.row.orderCode)"
                >
                  <i v-if="scope.row.totlePrice" style="font-style: normal">
                    {{ scope.row.currency === '美元' ? '$' : '￥' }}
                  </i>
                  {{
                    scope.row.currency === '美元'
                      ? utils.numberFormat(scope.row.totlePrice, 2)
                      : utils.numberFormat(scope.row.rmbTotlePrice, 2)
                  }}
                </span>

                <el-row v-if="scope.row.salesGatheringVOS">
                  <el-table
                    :data="scope.row.salesGatheringVOS"
                    style="width: 100%"
                    border
                    class="mt15"
                  >
                    <el-table-column
                      width="150"
                      prop="paymentLink"
                      label="收款节点"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        {{ scope.row.paymentLink || '--' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="paymentPropor"
                      label="比例(%)"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        {{ scope.row.paymentPropor || '--' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="receivablePrice"
                      label="应收金额"
                      align="center"
                      width="120"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        <span v-if="scope.row.receivablePrice">
                          {{ moneySign }}
                        </span>
                        {{ utils.numberFormat(scope.row.receivablePrice, 2) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="actualReceivablePrice"
                      label="实收金额"
                      width="120"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        <span v-if="scope.row.actualReceivablePrice">
                          {{ moneySign }}
                        </span>
                        {{
                          utils.numberFormat(scope.row.actualReceivablePrice, 2)
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="actualReceivablePriceDate"
                      label="收款时间"
                      align="center"
                      width="120"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        {{ scope.row.actualReceivablePriceDate || '--' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="voucherNo"
                      label="收款单号"
                      align="center"
                      width="150"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        <span
                          class="blue-text"
                          v-if="scope.row.voucherNo"
                          @click="receiptNoClick(scope.row)"
                        >
                          {{ scope.row.voucherNo }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="financeMethod"
                      label="收款方式"
                      align="center"
                      width="120"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        {{ scope.row.financeMethod || '--' }}
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="amountReceived"
            align="center"
            label="已收金额"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.receivedPrice">
                {{ scope.row.currency === '美元' ? '$' : '￥' }}
              </span>
              {{ utils.numberFormat(scope.row.receivedPrice, 2) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="outstandingAmount"
            align="center"
            label="未收金额"
          >
            <template slot-scope="scope">
              {{
                utils.numberFormat(
                  scope.row.receivablePrice - scope.row.receivedPrice,
                  2
                )
              }}
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-row class="mt20 remark">
        <el-col :span="24">
          <p>退款原因</p>
          <span>{{ form.refundReason || '--' }}</span>
        </el-col>
      </el-row>

      <el-row class="mt20 remark">
        <el-col :span="8">
          <p>创建人</p>
          <span>{{ form.creator || '--' }}</span>
        </el-col>
        <el-col :span="8">
          <p>创建时间</p>
          <span>{{ form.createTime || '--' }}</span>
        </el-col>
      </el-row>
    </el-row>
    <!--收款单详情弹窗 -->
    <ReceiptDetailModelOut ref="ReceiptDetail" />
  </div>
</template>

<script>
  import { auditInteractor, PaymentInteractor, OrderInteractor } from '@/core'
  import ReceiptDetailModelOut from '@/views/finance-center/credit-manage/components/receipt-detail-model'
  import saleDetailTable from './sale-detail-table.vue'
  export default {
    name: 'ReceiptDetailModel',
    props: {
      detailId: {
        type: [Number, Object, String],
        required: true,
      },
      payType: {
        type: Number,
        required: true,
      },
      passRow: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        dialogReceiptDetailVisible: false, //付款单详情弹窗
        amendRow: '',
        tagStatus: 1,
        form: {},
        money: 0,
        topTableData: [],
        moneySign: '', //货币符号
        fileList: [], //附件
        salesGatheringVOS: [], //点击订单总金额，查询销售单付款进程
        entryType: null, // 0采购付款，1采购退款
        isNewPayMent: false, // 区分新旧两种付款
      }
    },
    watch: {
      detailId: {
        immediate: true,
        deep: true,
        handler(value) {
          console.log(value)
          if (!value || !value.detailId || !('isNewPayMent' in value)) return
          if (this.payType === 1) {
            this.showReceiptDetail(value.detailId)
          } else if (this.payType === 2) {
            this.showReceiptDetailOrder(value.detailId)
          }
        },
      },
    },
    components: {
      ReceiptDetailModelOut,
      saleDetailTable,
    },
    methods: {
      //销售退款的详情
      async showReceiptDetailOrder(payOrderId) {
        this.dialogReceiptDetailVisible = true
        let response = await auditInteractor.getSalesDetailForShowApi({
          businessId: payOrderId,
        })

        this.form = response.data
        this.topTableData = response.data.applicationPayOrderChildShowVOList
        this.moneySign = this.form.currency === '美元' ? '$' : '￥'
        this.topTableData?.forEach(async (item) => {
          this.money += item.amountApplication
        })
      },

      //采购订单的详情
      async showReceiptDetail(payOrderId) {
        let self = this
        self.dialogReceiptDetailVisible = true

        const method = self.detailId.isNewPayMent
          ? auditInteractor.getDetailForShowNewApi
          : auditInteractor.getDetailForShowApi
        let response = await method({ businessId: payOrderId })
        if(response?.code !== '000000') {
          return
        }
        self.form = response.data
        self.topTableData =
          response.data[
            self.detailId.isNewPayMent
              ? 'payOrderChildShowVOList'
              : 'applicationPayOrderChildShowVOList'
          ]
        self.entryType = response.data.entryType

        //付款方式过滤
        let count = 0
        this.topTableData?.forEach((item) => {
          // 付款方式过滤
          item.purchasePaymentMethodShowVOList =
            item.purchasePaymentMethodShowVOList.filter(
              (list) => list.entryType == 0
            )

          item.purchasePaymentMethodShowVOList.forEach((item2) => {
            if (item2.type === 0) {
              count++
            }
          })
          item.count = count
        })

        this.moneySign = this.form.currency === '美元' ? '$' : '￥'
        this.fileList = response.data.fileAnnex
          ? JSON.parse(response.data.fileAnnex)
          : ''

        this.topTableData?.forEach(async (item) => {
          this.money += item.amountApplication
        })
      },

      //订单总金额点击
      async totlePriceClcik(orderCode) {
        let response = await OrderInteractor.orderGetDetailByOrderCodeApi({
          orderCode: orderCode,
        })
        if (response.code === '000000') {
          let arr =
            response.data.orderPayVOS?.map((item) => {
              return {
                ...item,
                actualReceivablePriceDate: item.payTime,
                actualReceivablePrice: item.totalPrice,
                voucherNo: item.financeCode,
                voucherStatus: item.financeStatus,
              }
            }) || []
          arr = arr.filter((ele) => {
            return ele.financeStatus == '4'
          })
          this.salesGatheringVOS = [...response.data.salesGatheringVOS, ...arr]
          this.moneySign = response.data.currency === '美元' ? '$' : '￥'
        }
      },

      //点击收款单号
      receiptNoClick(row) {
        this.$refs['ReceiptDetail'].showReceiptDetail(
          row.voucherNo,
          row.voucherStatus
        )
      },

      //销售订单号跳转
      async purchaseOrderNumberClick(code) {
        let response = await PaymentInteractor.paymentStatusVOApi({
          orderCode: code,
        })
        if (response.code == '000000') {
          let orderStatus = response.data.orderStatus
          let routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail',
            query: { orderCode: code, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        }
      },

      close() {
        this.dialogReceiptDetailVisible = false
        this.money = 0
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;

    .detail-header {
      > .el-col {
        p {
          margin-bottom: 14px;
        }

        > span {
          color: #000;
        }
      }
    }

    .remark {
      > .el-col {
        p {
          margin-bottom: 14px;
        }

        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    /deep/ .el-divider--horizontal {
      margin: 30px 0;
    }
  }

  .green-bold {
    color: #008000;
    font-family: 'PingFang Bold';
  }

  .red-bold {
    color: red;
    font-family: 'PingFang Bold';
  }

  ::v-deep {
    .el-table__expanded-cell {
      padding: 0 0 0 0;
    }

    .el-table__expand-column .el-icon {
      visibility: hidden;
    }

    // .bottom-table
    //   > .el-table__header-wrapper
    //   > .el-table__header
    //   > .has-gutter
    //   > tr
    //   > th:nth-child(5) {
    //   background-color: #fff;
    //   border: none;
    // }
  }
</style>
