<template>
  <div>
    <el-popover placement="bottom" trigger="click" @show="getList">
      <div>
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ 'text-align': 'center' }"
          :row-style="{ 'text-align': 'center' }"
        >
          <el-table-column width="100" label="产品图" align="center">
            <template slot-scope="scope">
              <erp-image
                :imgs="scope.row.printingImg || scope.row.imgMain"
              ></erp-image>
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            property="sku"
            label="产品编码"
          ></el-table-column>
          <el-table-column width="150" label="产品名称" align="center">
            <template slot-scope="scope">
              <span>
                {{ scope.row.nameCn || '--' }}/{{ scope.row.nameEn || '--' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            width="80"
            property="parentSpecsValue"
            label="规格"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.specsValue1 || '--' }}
            </template>
          </el-table-column>
          <el-table-column width="80" label="数量" align="center">
            <template slot-scope="scope">
              {{ scope.row.numDemand || '--' }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span
        slot="reference"
        class="popover-title"
        :class="
          itemTitle === '主'
            ? 'green-box'
            : itemTitle === '子'
            ? 'origin-box'
            : ''
        "
      >
        {{ itemTitle }}
      </span>
    </el-popover>
  </div>
</template>
<script>
import { OrderListInteractor } from '@/core'
export default {
  name: 'popover-ship',
  props: {
    detailId: {
      type: Object,
      default: () => {},
    },
    itemTitle: {
      type: String,
      default: '',
    },
    orderNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
    }
  },
  methods: {
    async getList() {
      // 添加orderProductUniqueId
      if (this.itemTitle == '子') {
        let data = {
          orderCode: this.detailId.salesOrderNumber || this.orderNumber,
          sku: this.detailId.parentSku,
          parentSku: '',
          orderProductUniqueId: this.detailId.orderProductUniqueId,
        }
        let res = await OrderListInteractor.getShippingPurchesList(data)
        this.tableData = res.data?.vos
      } else if (this.itemTitle == '主') {
        let data = {
          orderCode: this.detailId.salesOrderNumber || this.orderNumber,
          sku: '',
          parentSku: this.detailId.skuId,
          orderProductUniqueId: this.detailId.orderProductUniqueId,
        }
        let res = await OrderListInteractor.getShippingPurchesList(data)
        this.tableData = res.data?.vos
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.popover-title {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 13px;
  margin-left: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.popover-title:hover {
  cursor: pointer;
}
.green-box {
  background: rgba(112, 182, 3, 0.2);
  color: #70b603;
}
.origin-box {
  background: rgba(245, 154, 35, 0.2);
  color: #f59a23;
}
</style>
