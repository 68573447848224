<template>
  <el-dialog
    :title="$t(title)"
    width="600px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="close"
    v-if="visible"
  >
    <el-form :model="form" :rules="{}" ref="form">
      <el-form-item
        prop="remark"
        :rules="[
          {
            required: true,
            message: $t('placeholder.plsInput'),
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          :rows="5"
          type="textarea"
          maxlength="500"
          show-word-limit
          v-model="form.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="text-center">
      <el-button type="primary" @click="save">
        {{ $t('cusDetail.Confirm') }}
      </el-button>
      <el-button @click="close()">{{ $t('cusDetail.Cancel') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'RemarkInput',
    props: {
      title: {
        // 标题
        type: String,
        default: '',
      },
    },

    data() {
      return {
        form: {
          remark: '', // 备注
        },
        visible: false,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },

    mounted() {},

    methods: {
      // 显示弹窗
      showRemark(val) {
        this.visible = true
        if (val) {
          this.form.remark = val
        }
      },
      // 保存
      save() {
        this.$refs.form.validate((flag) => {
          if (flag) {
            this.$emit('remark-confirm', this.form.remark)
            this.close()
          }
        })
      },
      // 关闭
      close() {
        this.form = {
          remark: '',
        } //重置表单,就是将表单的每一项置空
        this.visible = false //关闭弹窗
      },
    },
  }
</script>

<style lang="scss" scoped></style>
