<template>
  <el-dialog></el-dialog>
</template>

<script>
export default {
  data () {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>
