const Base_Data = {
  mailDateList: [
    {
      mailDate: 0,
      label: '全部',
      labelEn: 'All',
    },
    {
      mailDate: 730,
      label: '最近2年',
      labelEn: 'The last 2 years',
    },
    {
      mailDate: 365,
      label: '最近1年',
      labelEn: 'The last 1 years',
    },
    {
      mailDate: 183,
      label: '最近半年',
      labelEn: 'the last six months',
    },
  ],
  mailNumberList:[
    {
      mailNumber: 0,
      label: '全部',
      labelEn: 'All',
    },
    {
      mailNumber: 1000,
      label: '1000封',
      labelEn: '1000 letters',
    },
    {
      mailNumber: 2000,
      label: '2000封',
      labelEn: '2000 letters',
    },
    {
      mailNumber: 3000,
      label: '3000封',
      labelEn: '3000 letters',
    },
    {
      mailNumber: 4000,
      label: '4000封',
      labelEn: '4000 letters',
    },
    {
      mailNumber: 5000,
      label: '5000封',
      labelEn: '5000 letters',
    },
    {
      mailNumber: 10000,
      label: '10000封',
      labelEn: '10000 letters',
    }
  ]
}
module.exports = Base_Data