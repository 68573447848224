// 平台字典
export const platformDs = [
  { label: '全部', value: '' },
  { label: 'RP商城', value: 'RP' },
  { label: 'RG商城', value: 'RG' },
  { label: 'SW商城', value: 'SW' },
  { label: 'SAGE（68786）', value: 'SAGE' },
]

// 状态字典
export const statusDs = [
  { label: '全部', value: '' },
  { label: '已下架', value: '0' },
  { label: '已上架', value: '1' },
  { label: '上架失败', value: '2' },
  { label: '更新失败', value: '3' },
]

// 匹配状态字典
export const matchStatusDs = [
  { label: '全部', value: '' },
  { label: '未匹配', value: '0' },
  { label: '已匹配', value: '1' },
]
