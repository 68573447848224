<template>
  <span>
    {{ secondString }}
    <!-- 秒后发送 -->
    {{ $t('other.Sendinseconds') }}
  </span>
</template>

<script>
export default {
  data() {
    return {
      hour: '',
      minute: '',
      second: '',
      timer: '',
    }
  },
  props: {
    targetTime: {
      // 倒计时间总秒数
      default: '',
    },
  },
  mounted() {
    let num = Math.ceil(
      (new Date(this.targetTime).getTime() - new Date().getTime()) / 1000
    )
    if (num > 0) {
      this.hour = Math.floor((num / 3600) % 24)
      this.minute = Math.floor((num / 60) % 60)
      this.second = Math.floor(num % 60)
      this.countDowm()
    }
  },
  methods: {
    countDowm() {
      var self = this
      clearInterval(this.timer)
      this.timer = setInterval(function () {
        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0
            self.$emit('countDowmEnd', true)
            clearInterval(self.timer)
          } else {
            self.second -= 1
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1
            self.minute = 59
            self.second = 59
          } else {
            self.second -= 1
          }
        }
      }, 1000)
    },
    formatNum(num) {
      return num < 10 ? '0' + num : '' + num
    },
  },
  computed: {
    hourString() {
      return this.formatNum(this.hour)
    },
    minuteString() {
      return this.formatNum(this.minute)
    },
    secondString() {
      return this.formatNum(this.second)
    },
  },
}
</script>