<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月17日 15:18:56
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->

<template>
  <div class="proSetting-container">
    <el-row :gutter="10">
      <!-- 库存锁定时长 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">
              库存锁定时长
              <Qtip
                content="锁定库存后，到达设置的锁定时长，系统自动将库存解锁。若不设置，默认值为2天"
              />
            </p>

            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="add(1, -1)"
              ></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="tableData" border style="width: 100%">
            <el-table-column
              prop="warehouseName"
              align="center"
              label="仓库"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer">
                  {{ scope.row.warehouseName }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label="锁定时长"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer">
                  {{ scope.row.value || '-' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="120"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="edit(1, scope.row.warehouseSettingId)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="deleteItem(1, scope.row.warehouseSettingId)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>

      <!-- 库存锁定次数上限 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">
              库存锁定次数上限
              <Qtip
                content="每个产品锁定库存的次数有限，超过次数上线，不能再进行锁定操作。若不设置，默认值为不限次数"
              />
            </p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="add(2, -1)"
              ></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="list2" border style="width: 100%">
            <el-table-column
              align="center"
              label="仓库"
              show-overflow-tooltip
              prop="warehouseName"
            >
              <template slot-scope="scope">
                <span class="c_pointer">
                  {{ scope.row.warehouseName }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label="次数上限"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="120"
              :resizable="false"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.amountName !== 'Email'">
                  <el-tooltip content="编辑" placement="top">
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      @click="edit(2, scope.row.warehouseSettingId)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top">
                    <el-button
                      type="danger"
                      plain
                      icon="el-icon-delete"
                      circle
                      size="mini"
                      @click="deleteItem(2, scope.row.warehouseSettingId)"
                    ></el-button>
                  </el-tooltip>
                </template>
                <template v-else>-</template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <!-- 库存锁定数量溢出比 -->
      <el-col :span="6">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">
              库存锁定数量溢出比
              <Qtip
                content="设置溢出数量后，锁定量上限为订单数量+订单数量*溢出量；若不设置，默认值为数量不限"
              />
            </p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="add(3, -1)"
              ></el-button>
            </el-tooltip>
          </div>
          <el-table class="mt15" :data="list3" border style="width: 100%">
            <el-table-column
              prop="warehouseName"
              align="center"
              label="仓库"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.warehouseName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label="溢出比"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{ scope.row.value }}%</template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              :resizable="false"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.amountName !== 'Email'">
                  <el-tooltip content="编辑" placement="top">
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      @click="
                        edit(3, scope.row.warehouseSettingId, scope.$index)
                      "
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top">
                    <el-button
                      type="danger"
                      plain
                      icon="el-icon-delete"
                      circle
                      size="mini"
                      @click="deleteItem(3, scope.row.warehouseSettingId)"
                    ></el-button>
                  </el-tooltip>
                </template>
                <template v-else>-</template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <!-- 采购溢装数限制 -->
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">
              仓库库位自动扣减
              <Qtip
                content="若设置了扣减规则，创建出库单据或进行出库确认后，无需手动选择库位的出库数量，系统根据设置的规则自动扣减，若不设置，默认值为手动设置"
              />
            </p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="add(4, -1)"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table class="mt15" :data="list4" border style="width: 100%">
            <el-table-column
              align="center"
              label="仓库"
              show-overflow-tooltip
              prop="warehouseName"
            >
              <template slot-scope="scope">
                {{ scope.row.warehouseName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label="扣减规则"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.value | rule }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              :resizable="false"
            >
              <template slot-scope="scope">
                <template>
                  <el-tooltip content="编辑" placement="top">
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      @click="edit(4, scope.row.warehouseSettingId)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top">
                    <el-button
                      type="danger"
                      plain
                      icon="el-icon-delete"
                      circle
                      size="mini"
                      @click="deleteItem(4, scope.row.warehouseSettingId)"
                    ></el-button>
                  </el-tooltip>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-col :span="8">
        <div class="public-col">
          <div class="title-wrapper">
            <p class="f_s_16">成本计算</p>

            <el-tooltip content="编辑" placement="top">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="addCost"
              ></el-button>
            </el-tooltip>
          </div>
          <p v-if="costConfig">
            分仓核算：{{ costConfig.accountRule == 1 ? '是' : '否' }}
          </p>
          <el-table
            v-if="costConfig"
            class="mt15"
            :data="costConfig.stockCostConfigureVOS"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="warehouseName"
              align="center"
              label="仓库名称"
              show-overflow-tooltip
              width="140"
              v-if="costConfig.accountRule == 1"
            ></el-table-column>
            <el-table-column
              align="center"
              label-class-name="required-th"
              width="120"
            >
              <template slot="header">
                <span style="margin-right: 5px">出库模式</span>
                <Qtip
                  content="先进先出：是指先采购进仓库的商品在销售的时候优先出库；指定批次：是指每次销售出库时手动选择采购入库的商品"
                  effect="dark"
                />
              </template>
              <template slot-scope="scope">
                {{ scope.row.warehouseOutType == 1 ? '先进先出' : '指定批次' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label-class-name="required-th"
              width="160"
            >
              <template slot="header">
                <span style="margin-right: 5px">入库批次可修改</span>
                <Qtip
                  content="选择是：系统推荐入库批次后，可手动修改；选择否：系统推荐入库批次后，不可更改"
                  effect="dark"
                />
              </template>
              <template slot-scope="scope">
                {{ scope.row.warehouseInBatch == 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label-class-name="required-th"
            >
              <template slot="header">
                <span style="margin-right: 5px">计价方式</span>
                <Qtip
                  content="批次计价法：出库单价取各批次入库单价；移动加权：入库根据当前库存成本和库存数量计算平均单价"
                  effect="dark"
                />
              </template>
              <template slot-scope="scope">
                {{ scope.row.priceType == 1 ? '批次计价法' : '移动加权' }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 弹窗 -->
    <el-dialog
      :title="title"
      :append-to-body="true"
      :visible.sync="dialogFormVisible"
      width="600px"
      @close="close"
      v-if="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <component
        :is="currentComp"
        ref="inventoryLockDuration"
        :whs="whs"
      ></component>

      <template #footer>
        <el-row class="text-center">
          <el-button type="primary" @click="save">确认</el-button>
          <el-button @click="close">取消</el-button>
        </el-row>
      </template>
    </el-dialog>
    <cost-calculation
      :costConfig="costConfig"
      :costVisible.sync="costVisible"
      :costConfigureId="costConfigureId"
      @refresh="getCostConfig"
    ></cost-calculation>
  </div>
</template>

<script>
  import Qtip from '@/components/base-question-tip'

  import InventoryLockDuration from './components/inventory-lock-duration'
  import InventoryLockTimes from './components/inventory-lock-times'
  import InventoryLockOverflowRatio from './components/inventory-lock-overflow-ratio'
  import AutomaticDeductionLocation from './components/automatic-deduction-location'
  import CostCalculation from './components/cost-calculation'
  import { WarehouseSettingInteractor, OutboundSheetInteractor } from '@/core'

  export default {
    name: 'ProductSetting',
    props: {},
    data() {
      self = this
      return {
        currentType: 1, //1:出口抬头，2:询盘来源,3:线下订单来源,4:采购放量数设置
        dialogFormVisible: false,
        controls: true,
        list1: [],
        list2: [],
        list3: [],
        list4: [],
        tableHeight: '400px',
        form: {},
        whs: [], //仓库列表
        tableData: [],
        costConfig: null,
        costVisible: false, // 成本计算弹窗
        costConfigureId: '', // 计算成本业务id
      }
    },
    components: {
      Qtip,
      InventoryLockDuration,
      InventoryLockTimes,
      InventoryLockOverflowRatio,
      AutomaticDeductionLocation,
      CostCalculation,
    },
    computed: {
      // 弹窗内部组件
      currentComp() {
        const type2Comp = {
          1: InventoryLockDuration,
          2: InventoryLockTimes,
          3: InventoryLockOverflowRatio,
          4: AutomaticDeductionLocation,
        }
        return type2Comp[this.currentType]
      },
      // 弹窗标题
      title() {
        const type2text = {
          1: '库存配置',
          2: '库存配置',
          3: '锁定数量溢出比',
          4: '库位自动扣减',
          5: '成本计算',
        }
        return type2text[this.currentType]
      },
    },
    filters: {
      whNames(val) {
        return !val.length ? '--' : val.map((i) => i.wharehouseName).join(';')
      },

      rule(val) {
        const code2text = {
          1: '手动设置',
          2: '从多到少自动扣减',
          3: '从少到多自动扣减',
        }
        return code2text[val]
      },
    },

    created() {
      this.getList()
      this.getWhs()
      this.getCostConfig()
    },
    mounted() {
      // setTimeout(() => {
      //   this.getTableHeight()
      // }, 900)
    },

    methods: {
      async getCostConfig() {
        this.costConfig = await WarehouseSettingInteractor.getConfigureDetail(
          null
        )
        this.costConfigureId = this.costConfig.costConfigureId
      },
      // 获取列表数据
      getList() {
        for (let i = 1; i <= 4; i++) {
          this.getWarehouseSetListPage(i)
        }
      },
      getWarehouseSetListPage(i) {
        WarehouseSettingInteractor.warehouseSetListPage({ type: i }).then(
          (res) => {
            if (res && res.code === '000000') {
              //库存锁定时长
              if (i === 1) {
                this.tableData = res.data
              }
              //库存锁定次数上限
              else if (i === 2) {
                this.list2 = res.data
              }
              //库存锁定数量溢出比
              else if (i === 3) {
                this.list3 = res.data
              }
              //仓库库位自动扣减
              else if (i === 4) {
                this.list4 = res.data
              }
            }
          }
        )
      },
      //获取仓库下拉数据
      getWhs() {
        OutboundSheetInteractor.findWarehouseList({
          hasAuth: 0,
          hasCloudWarehouse: 0,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.whs = res.data
          }
        })
      },
      // 获取表格高度
      // getTableHeight() {
      //   const wrapperH = document
      //       .querySelector('.proSetting-container')
      //       .getBoundingClientRect().height,
      //     height = wrapperH - 180

      //   this.tableHeight = height
      // },
      // 新增
      add(type, index) {
        this.currentIndex = index
        this.currentType = type
        this.dialogFormVisible = true
      },
      // 编辑
      edit(type, warehouseSettingId, index) {
        this.currentIndex = index
        this.currentType = type
        this.dialogFormVisible = true

        WarehouseSettingInteractor.warehouseSetDetailForEditShow({
          warehouseSettingId: warehouseSettingId,
        }).then((res) => {
          let item = res.data

          this.$nextTick(() => {
            this.$refs.inventoryLockDuration.form = {
              ...item,
              wharehouseList: item.warehouseSettingWarehouseShowVOList.map(
                (i) => i.warehouseId
              ),
            }
          })
        })
      },
      // 关闭弹窗
      close() {
        this.dialogFormVisible = false
        this.$refs.inventoryLockDuration.resetFields()
      },

      // 删除
      deleteItem(type, warehouseSettingId, index) {
        this.$confirm('是否确认删除？删除后，仓库将执行默认规则。', '提示', {
          type: 'warning',
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        })
          .then(() => {
            WarehouseSettingInteractor.warehouseSetDeleteById({
              warehouseSettingId: warehouseSettingId,
            }).then((res) => {
              if (res && res.code === '000000') {
                this.getWarehouseSetListPage(type)
                this.$message.success('删除成功')
              }
            })
          })
          .catch(() => {})
      },
      //仓库唯一校验
      warehouseDuplicateVerification(checkData) {
        let checkFlag = true,
          whs = []
        let copyListWhs = this['list' + this.currentType].map(
          (item) => item.wharehouseList
        )

        if (copyListWhs.length === 0) {
          return checkFlag
        } else {
          this.currentIndex > 0 && copyListWhs.splice(this.currentIndex, 1)
          if (copyListWhs.length === 0) {
            return checkFlag
          } else {
            copyListWhs.forEach((item) => {
              if(Array.isArray(item)){
                whs = whs.concat(item.map((subItem) => subItem.wharehouseId))
              }
            })
          }
          for (let i = 0; i < checkData.wharehouseList.length; i++) {
            if (whs.includes(checkData.wharehouseList[i])) {
              checkFlag = false
              break
            }
          }
          return checkFlag
        }
      },

      // 保存
      save() {
        this.$refs.inventoryLockDuration.validate().then((form) => {
          form.warehouseSettingWarehouseDTOS = form.wharehouseList
          delete form.wharehouseList

          if (!form.warehouseSettingId) {
            WarehouseSettingInteractor.warehouseSetInsertPO(form)
              .then((res) => {
                if (res.code === '000000') {
                  this.getList()
                  this.$message.success('操作成功')
                  this.close()
                }
              })
              .catch(() => {
                this.$nextTick(() => {
                  let wharehouseList = []
                  form.warehouseSettingWarehouseDTOS.forEach((item, index) => {
                    wharehouseList.push(item.warehouseId)
                  })
                  this.$refs.inventoryLockDuration.form.wharehouseList =
                    wharehouseList
                })
              })
          } else {
            WarehouseSettingInteractor.warehouseSetUpdatePO(form)
              .then((res) => {
                if (res.code === '000000') {
                  this.getList()
                  this.$message.success('操作成功')
                  this.close()
                }
              })
              .catch(() => {
                this.$nextTick(() => {
                  this.$refs.inventoryLockDuration.form.wharehouseList = []
                })
              })
          }

          if (this.warehouseDuplicateVerification(form)) {
          } else {
            const type2msg = {
              1: '仓库的锁定时长不能重复设置',
              2: '一个仓库不能重复设置锁定次数',
              3: '一个仓库不能重复设置溢出比',
              4: '一个仓库不能重复设置库位自动扣减',
            }
            this.$message.warning(type2msg[this.currentType])
          }
        })
      },
      // 计算成本新增
      addCost() {
        this.costVisible = true
      },
    },
  }
</script>

<style scoped lang="scss">
  .public-col {
    // height: 40vh;
    border: 1px solid #ededed;
    padding: 10px;
    p {
      line-height: 32px;
    }
  }
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row-bottom {
    margin-top: 2vh;
  }
  ::v-deep {
    .el-table__body-wrapper {
      height: 300px;
      overflow-y: scroll;
    }
  }
</style>
