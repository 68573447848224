<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="800px"
    :close-on-click-modal="false"
    append-to-body
    @close="close"
  >
    <div class="dialog-content">
      <el-tree
        ref="tree"
        :data="treeData"
        show-checkbox
        default-expand-all
        node-key="deptId"
        highlight-current
        :expand-on-click-node="false"
        :props="optionProps"
        :default-checked-keys="checkedId"
        @check-change="handleCheckChange"
        @node-click="handleCheckChange"
      >
        <el-row
          slot-scope="{ data }"
          type="flex"
          align="middle"
          class="custom-tree-node w100"
        >
          <span class="iconfont icon-bumen"></span>
          <span class="text-hidden ml10">{{ data.name }}</span>
        </el-row>
      </el-tree>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { getListDepartmrnt } from '@/api/organization-manage'
  export default {
    name: 'DepartmentChoose',
    data() {
      return {
        title: '选择部门',
        dialogFormVisible: false,
        treeData: [],
        optionProps: {
          value: 'id',
          label: 'departmentName',
          children: 'children',
          checkStrictly: true,
        },
        checkedArr: [], //选中的部门
        checkedId: [], //回显的部门id
      }
    },
    methods: {
      showAddEdit(data) {
        this.getListDepartmrntUser()
        if (data) {
          // this.checkedId = data.map((item) => {
          //   return item.id
          // })
          this.checkedId = data
        }
        this.dialogFormVisible = true
      },
      //获取组织
      async getListDepartmrntUser() {
        let response = await getListDepartmrnt()
        if (response.code === '000000') {
          this.treeData = response.data
        }
      },
      handleCheckChange(data) {
        let res = this.$refs.tree.getCheckedNodes()
        let arrDeptId = []
        res.forEach((item) => {
          arrDeptId.push({ id: item.deptId, name: item.name })
        })
        this.$emit('choose-department', arrDeptId)
      },

      //关闭
      close() {
        this.dialogFormVisible = false
      },

      //保存
      save() {
        this.$emit('fetch-data')
        this.close()
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    height: 60vh;
    overflow-y: auto;
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
