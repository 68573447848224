<!-- 产品印刷效果图 -->
<template>
  <div class="print-area">
    <canvas :id="id" :height="defaultSize" :width="defaultSize"></canvas>
    <!-- 操作按钮 -->
    <div class="opera">
      <i class="iconfont icon-huanyuan reset" @click="reset"></i>
    </div>
    <!-- 图片切换 -->
    <div v-if="imgs.length > 1" class="switch">
      <i class="el-icon-arrow-left" @click="page('prev')"></i>
      <span>{{ currentIndex + ' / ' + imgs.length }}</span>
      <i class="el-icon-arrow-right" @click="page('next')"></i>
    </div>
  </div>
</template>

<script>
  import { fabric } from 'fabric'
  export default {
    name: 'PrintingCanvas',
    props: {
      id: {
        type: String,
        default: '',
      },
      index: {
        type: Number,
        default: 0,
      },
      defaultSize: {
        type: Number,
        default: 580,
      },
      scaleSize: {
        type: [Number, String],
        default: 0,
      },
      scalePoint: {
        type: [String, null],
        default: '',
      },
      images: {
        type: Array,
        default: () => [],
      },
      prints: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        canvas: null,
        ratio: 1, // 像素(px)与英寸(in)的比例
        currentIndex: 1, // 当前页
        scalePointArray: [0, 0], // 辅助线位置
        borderColor: 'rgba(255,255,255)', // 印刷区域边框颜色
        imgs: [], // 规格图
        imgLocation: {
          left: 0,
          right: 0,
        },
        // 辅助线
        subline: {
          // 主线
          main: {
            stroke: '#02A7F0',
            top: 15,
            strokeWidth: 2,
            selectable: false,
            hoverCursor: 'default',
          },
          // 副线
          side: {
            stroke: '#02A7F0',
            strokeWidth: 1,
            opacity: 0.35,
            strokeDashArray: [10, 4],
            selectable: false,
            hoverCursor: 'default',
          },
          // 圆
          circle: {
            radius: 8,
            top: 7,
            fill: 'white',
            stroke: '#02A7F0',
            strokeWidth: 2,
            hasControls: false,
            hasBorders: false,
            lockMovementY: true,
          },
        },
        // 印刷尺寸
        text: {
          fontFamily: 'Arial',
          backgroundColor: 'white',
          hasControls: false,
          hasBorders: false,
          selectable: false,
          hoverCursor: 'default',
        },
        // 印刷尺寸线
        line: {
          strokeWidth: 1,
          hasControls: false,
          hasBorders: false,
          selectable: false,
          hoverCursor: 'default',
        },
      }
    },
    computed: {
      newPrints() {
        return JSON.parse(JSON.stringify(this.prints))
      },
    },
    watch: {
      // 监听背景图片变化
      images: {
        handler() {
          this.$nextTick(() => {
            this.initCanvas()
          })
        },
        deep: true,
        immediate: true,
      },
      // 监听标尺尺寸变化
      scaleSize() {
        this.$nextTick(() => {
          this.setScaleSize(this.scaleSize)
        })
      },
      // prints: {
      //   handler(newval) {
      //     this.$nextTick(() => {
      //       this.setPrintingArea()
      //     })
      //   },
      //   deep: true,
      //   immediate: true,
      // },
      newPrints: {
        handler(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.$nextTick(() => {
              this.setPrintingArea()
            })
          }
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      // 解决某些场景下页面初始化时，无法拖动圆块
      setTimeout(() => {
        this.reset()
      }, 1500)
    },
    methods: {
      // 加载canvas
      initCanvas() {
        this.imgs = this.images.filter((item) => item.fileUrl)
        if (this.imgs.length) {
          if (this.canvas) {
            this.currentIndex = 1
            this.loadingImage(this.imgs[0].fileUrl)
          } else {
            this.canvas = new fabric.Canvas(this.id)
            this.canvas.selection = false
            // 加载图片
            this.loadingImage(this.imgs[0].fileUrl)
            // 设置画布辅助线
            this.setCanvasLine()
            // 添加canvas事件
            this.canvasEvents()
          }
        }
      },

      /**
       * 加载图片
       * @param {imgUrl} 背景图片
       */
      loadingImage(imgUrl) {
        // 清空画布
        fabric.Image.fromURL(imgUrl, (img) => {
          if (!this.canvas) {
            return
          }
          const scale = this.canvas.height / img.height

          this.getImageLocation(img, scale)
          this.canvas.setBackgroundImage(
            img,
            this.canvas.renderAll.bind(this.canvas),
            {
              // 缩放图片，使其完全填充满画布
              scaleX: scale,
              scaleY: scale,
              left: this.imgLocation.left,
            }
          )
        })
      },

      /**
       * 监听标尺尺寸变化
       * @param {val} 标尺尺寸
       */
      setScaleSize(val) {
        if (val > 0 && this.imgs.length) {
          // 设置画布辅助线
          const textCanvas = this.canvas
            .getObjects()
            .find((item) => item.name === 'text')

          if (!textCanvas) {
            // 设置文本
            const text = new fabric.Text(`${this.scaleSize} in`, {
              top: 6,
              name: 'text',
              fontSize: 14,
              strokeWidth: 5,
              ...this.text,
            })

            this.canvas.add(text)
            // 计算标尺文本位置
            this.setScaleLocation()
          } else {
            textCanvas.set({
              text: `${this.scaleSize} in`,
            })
          }
          this.setPrintingArea()
        } else {
          this.clearPrinting()
        }
      },

      /**
       * 设置画布辅助线
       */
      setCanvasLine() {
        // 设置top线
        const subline = this.subline
        const mainLine = new fabric.Line([0, 10, this.defaultSize, 10], {
          ...subline.main,
        })

        // 设置side线
        let [offsetX, offsetY, pointL, pointR] = [0, 0, 0, 0]
        if (this.scalePoint) {
          pointL = this.scalePoint.split(',')[0]
          pointR = this.scalePoint.split(',')[1]
          offsetX = pointL > 0 ? pointL : this.defaultSize * 0.1
          offsetY = pointR > 0 ? pointR : this.defaultSize * 0.9
        } else {
          offsetX = this.defaultSize * 0.1
          offsetY = this.defaultSize * 0.9
        }
        this.scalePointArray = [offsetX, offsetY]

        const lineL = new fabric.Line([offsetX, 0, offsetX, 580], {
          name: 'lineL',
          ...subline.side,
        })
        const lineR = new fabric.Line([offsetY, 0, offsetY, 580], {
          name: 'lineR',
          ...subline.side,
        })

        // 设置拖动圆
        const circleL = new fabric.Circle({
          name: 'circleL',
          left: offsetX - 8,
          ...subline.circle,
        })
        const circleR = new fabric.Circle({
          name: 'circleR',
          left: offsetY - 8,
          ...subline.circle,
        })

        this.canvas.add(mainLine, lineL, lineR, circleL, circleR)
        // 添加标尺尺寸
        this.setScaleSize(this.scaleSize)
      },

      /**
       * 获取渐变色
       *
       */
      getGradient({ width, height }) {
        let gradient = new fabric.Gradient({
          type: 'linear', // 线性渐变
          gradientUnits: 'pixels', // pixels or pencentage 像素 或者 百分比
          coords: { x1: 0, y1: 0, x2: width, y2: height }, // 至少2个坐标对（x1，y1和x2，y2）将定义渐变在对象上的扩展方式
          colorStops: this.getColorStops(),
        })
        return gradient
      },

      /**
       * 获取colorStops
       * @param {offset} 颜色偏移量
       */
      getColorStops(offset = 0.03) {
        let colorStops = []
        let color = 'white'
        for (let i = 0; i < 1; i = i + offset) {
          colorStops.push({
            offset: i,
            color,
          })
          color = color === 'black' ? 'white' : 'black'
        }
        return colorStops
      },

      /**
       * 绘制印刷区域
       * @param {ratio} 英寸与像素的比例
       */
      drawPrintArea(ratio) {
        const prints = this.prints
        if (prints.length) {
          // 清空印刷区域
          this.clearPrinting()

          for (let i = 0; i < prints.length; i++) {
            let width = prints[i].regionWidth * ratio
            let height = prints[i].regionHigh * ratio
            let gradient = this.getGradient({
              width,
              height,
            })
            const rect = new fabric.Rect({
              name: 'print',
              regionName: prints[i].regionName,
              top: this.setInScope(prints[i].regionY * ratio),
              left: this.setInScope(prints[i].regionX * ratio),
              width: prints[i].regionWidth * ratio,
              height: prints[i].regionHigh * ratio,
              fill: 'transparent',
              // stroke: this.borderColor,
              stroke: gradient,
              // strokeDashArray: [10, 3], // 虚线边框
            })
            rect.hasControls = false
            rect.hasBorders = false
            this.canvas.add(rect)

            // 没有设置路径偏移量的时候，居中显示
            if (!prints[i].regionX || !prints[i].regionY) {
              this.canvas.centerObject(rect)
              this.setPrintingLocation(rect, prints[i])
            }
            this.drawPrintSize(rect, prints[i])
          }
          this.canvas.renderAll()
        }
      },

      /**
       * 印刷区域添加印刷尺寸
       * @param {rect} 印刷区域图形
       * @param {print} 印刷区域信息
       */
      drawPrintSize(rect, print) {
        const [W_X, W_Y, H_X, H_Y] = [
          rect.left + rect.width / 2,
          rect.top + rect.height - 6,
          rect.left,
          rect.top + rect.height / 2,
        ]

        const textTitle = new fabric.Text(`${print.regionName}`, {
          top: W_Y,
          fontSize: 12,
          strokeWidth: 2,
          name: 'borderSize',
          sign: 'border' + print.regionName,
          ...this.text,
          backgroundColor: 'transparent',
        })
        const textX = new fabric.Text(`${print.regionWidth} in`, {
          top: W_Y,
          fontSize: 11,
          strokeWidth: 2,
          name: 'borderSize',
          sign: 'border' + print.regionName,
          ...this.text,
        })
        const textY = new fabric.Text(`${print.regionHigh} in`, {
          top: H_Y,
          fontSize: 11,
          strokeWidth: 2,
          name: 'borderSize',
          sign: 'border' + print.regionName,
          ...this.text,
        })

        const textX_W = textX.width / 2
        const textY_W = textY.width / 2
        textTitle.set({ left: H_X, top: rect.top - 16 })
        textX.set({ left: W_X - textX_W })
        textY.set({ left: H_X - textY_W })

        this.canvas.add(textTitle, textX, textY)
      },

      /**
       * 设置画布图片偏移量
       * @param {img}
       * @param {scale} 缩放比例
       */
      getImageLocation(img, scale) {
        this.imgLocation.left = (this.defaultSize - img.width * scale) / 2
        this.imgLocation.right = this.imgLocation.left + img.width * scale
      },

      /**
       * 计算标尺文本位置
       */
      setScaleLocation() {
        const _objects = this.canvas.getObjects()
        const _activeEl = _objects.find((item) => item.name === 'text')
        const [_L, _R] = [
          _objects.find((item) => item.name === 'lineL').left,
          _objects.find((item) => item.name === 'lineR').left,
        ]

        if (_activeEl) {
          _activeEl.set({
            left: Math.abs((_R - _L) / 2 + _L - _activeEl.width / 2),
          })
        }
      },

      /**
       * 计算印刷区域大小
       */
      setPrintingArea() {
        const _objects = this.canvas?.getObjects()

        if (_objects?.length) {
          const [_L, _R] = [
            _objects.find((item) => item.name === 'lineL').left,
            _objects.find((item) => item.name === 'lineR').left,
          ]
          const _scaleW = Math.abs(_R - _L)

          this.ratio = _scaleW / this.scaleSize // 像素(px)与英寸(in)的比例
          this.drawPrintArea(this.ratio)
        }
        this.printsPercentage(_objects)
      },

      /**
       * canvas事件
       * @event wheel 鼠标滚轮事件
       * @event move 鼠标移动事件
       * @event up 鼠标松开事件
       */
      canvasEvents() {
        // 鼠标滚轮事件
        this.canvas.on('mouse:wheel', (opt) => {
          let delta = opt.e.deltaY // 滚轮，向上滚一下是 -100，向下滚一下是 100
          let zoom = this.canvas.getZoom() // 获取画布当前缩放值
          zoom *= 0.999 ** delta
          if (zoom > 10) zoom = 10
          if (zoom < 0.1) zoom = 0.1
          this.canvas.zoomToPoint(
            {
              // 关键点
              x: opt.e.offsetX,
              y: opt.e.offsetY,
            },
            zoom
          )
          opt.e.preventDefault()
          opt.e.stopPropagation()
        })

        // 鼠标移动元素
        this.canvas.on('mouse:move', (opt) => {
          const target = opt.target
          const _objects = this.canvas.getObjects()

          if (!target) return false
          // 拖动辅助线
          if (target.name?.includes('circle')) {
            if (target.name === 'circleL') {
              const line = _objects.find((item) => item.name === 'lineL')

              if (target.left > 0 && target.left < this.defaultSize) {
                line.set({ left: target.left + 8 })
              } else {
                target.set({ left: 0 })
                line.set({ left: target.left + 8 })
              }
              this.scalePointArray[0] = target.left + 8
            } else if (target.name === 'circleR') {
              const line = _objects.find((item) => item.name === 'lineR')

              if (target.left > 0 && target.left < this.defaultSize - 18) {
                line.set({ left: target.left + 8 })
              } else {
                target.set({ left: this.defaultSize - 18 })
                line.set({ left: target.left + 8 })
              }
              this.scalePointArray[1] = target.left + 8
            }

            // 计算辅助线位置
            this.changeScalePoint()
            // 计算标尺文本位置
            this.setScaleLocation()
            // 计算印刷区域大小
            this.setPrintingArea()
          }

          // 拖动印刷区域
          if (target.name === 'print') {
            const _objects = this.canvas.getObjects()
            const el = this.prints.filter(
              (item) => item.regionName === target.regionName
            )[0]
            const sign = 'border' + target.regionName

            for (let i = 0; i < _objects.length; i++) {
              if (_objects[i].sign === sign) {
                this.canvas.remove(_objects[i])
              }
            }
            this.drawPrintSize(target, el)
          }
        })

        // 鼠标松开事件
        this.canvas.on('mouse:up', (opt) => {
          // 选中印刷区域
          if (opt.target?.name === 'print') {
            const prints = this.prints
            const L = opt.target.left > 0 ? opt.target.left : 0
            const T = opt.target.top > 0 ? opt.target.top : 0
            const X = (L / this.ratio).toFixed(2)
            const Y = (T / this.ratio).toFixed(2)

            for (let i = 0; i < prints.length; i++) {
              if (this.prints[i].regionName === opt.target.regionName) {
                prints[i].regionX = X
                prints[i].regionY = Y
              }
            }
          }
        })
      },

      /**
       * 图片切换
       * @param {type} prev:上一页 next:下一页
       */
      page(type) {
        const len = this.imgs.length

        if (type === 'prev') {
          if (this.currentIndex === 1) {
            this.currentIndex = len
          } else {
            this.currentIndex--
          }
        } else {
          if (this.currentIndex === len) {
            this.currentIndex = 1
          } else {
            this.currentIndex++
          }
        }
        this.loadingImage(this.imgs[this.currentIndex - 1].fileUrl)
      },

      /**
       * 计算画布印刷元素位置占比
       * @param {_objects}
       */
      printsPercentage(_objects) {
        const prints = _objects?.filter((item) => item.name === 'print')

        if (prints?.length) {
          for (let i = 0; i < prints.length; i++) {
            for (let j = 0; j < this.prints.length; j++) {
              if (prints[i].regionName === this.prints[j].regionName) {
                let _arr = []
                _arr.push({
                  width: (prints[i].width / this.defaultSize).toFixed(4),
                  height: (prints[i].height / this.defaultSize).toFixed(4),
                  top: (prints[i].top / this.defaultSize).toFixed(4),
                  left: (prints[i].left / this.defaultSize).toFixed(4),
                  regionWidth: this.prints[j].regionWidth,
                  regionHigh: this.prints[j].regionHigh,
                })
                this.prints[j].regionExtend = JSON.stringify(_arr)
              }
            }
          }
        }
      },

      /**
       * 居中对齐
       * @param {regionName} 印刷区域名称
       * @param {type} H:水平居中 V:垂直居中
       */
      canvasAlignCenter(regionName, type) {
        const _objects = this.canvas.getObjects()
        const prints = this.prints
        const el = _objects.filter((item) => item.regionName === regionName)[0]

        if (type === 'H') {
          el.centerH()
        } else {
          el.centerV()
        }
        for (let i = 0; i < prints.length; i++) {
          if (prints[i].regionName === regionName) {
            this.setPrintingLocation(el, prints[i])
          }
        }
        this.canvas.renderAll()
      },

      /**
       * 更新印刷区域位置
       * @param {el} 画布元素
       * @param {print} 印刷区域
       */
      setPrintingLocation(el, print) {
        const [X, Y] = [el.left / this.ratio, el.top / this.ratio]

        print.regionX = X && X > 0 ? X.toFixed(2) : 0
        print.regionY = Y && Y > 0 ? Y.toFixed(2) : 0
      },

      /**
       * 更新辅助线位置
       */
      changeScalePoint() {
        this.$emit(
          'changeScalePoint',
          this.scalePointArray.join(','),
          this.index
        )
      },

      /**
       * 清空画布印刷区域
       */
      clearPrinting() {
        const _objects = this.canvas.getObjects()

        for (let i = 0; i < _objects.length; i++) {
          if (['print', 'borderSize'].includes(_objects[i].name)) {
            this.canvas.remove(_objects[i])
          }
        }
      },

      /**
       * 控制印刷区域在画布图像内
       * @param {offset} 偏移量
       */
      setInScope(offset) {
        if (offset > 0) {
          return offset
        } else {
          return 0
        }
      },

      /**
       * 重置画布
       */
      reset() {
        this.canvas?.setZoom(1)
        this.canvas?.absolutePan(new fabric.Point(0, 0))
      },
    },
  }
</script>

<style lang="scss" scoped>
  .print-area {
    margin: 0 0 20px 0;
    height: 582px;
    width: 582px;
    border: 1px solid #ededed;
    position: relative;

    .opera {
      position: absolute;
      bottom: 10px;
      right: 10px;
      box-shadow: 1px 1px 5px #ccc;
      background: white;

      i {
        font-size: 20px;
        cursor: pointer;
        display: block;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
      }

      i.reset {
        font-size: 16px;
      }

      i:hover {
        color: #409eff;
      }
    }

    .switch {
      margin-top: 10px;
      width: 580px;
      text-align: center;
      color: #000;

      i {
        cursor: pointer;
        margin: 0 10px;
      }

      i:hover {
        color: #409eff;
      }
    }
  }
</style>
