<template>
  <div class="mail-user">
    <MailUserItem v-for="(v, index) in list" :key="index" :value="v" @update="(val) => updateValue(val, index)"
      @delete="deleteMail(index)" />
    <div class="mail-user-input">
      <el-select v-loadmore:el-select-dropdown__list="loadMore" v-model.trim="currentMail" filterable allow-create
        default-first-option size="small" class="search-input" placeholder="" ref="mailInput" @change="addMailUser"
        remote @focus="$emit('inhere')" popper-class="bank-select-emali" :remote-method="remoteMethod">
        <el-option v-for="item in contactList" :key="item.contactId" :label="item.contactEmail"
          :value="item.contactEmail">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
  import MailUserItem from './MailUserItem.vue'
  import { MailInteractor } from '@/core'
  export default {
    name: 'MailUser',
    components: {
      MailUserItem,
    },
    props: {
      list: {
        type: Array,
        default: [],
      },

      emailAccount: {
        type: String,
        default: ''
      }

    },

    data() {
      return {
        currentMail: '',
        pageNo: 1,
        total: 0,
        pageSize: 20,
        contactList: [],
        selectLoading: false,
        query: '', // 查询值
      }
    },
    computed: {
      hasMore() {
        return (
          Math.ceil(Number(this.total) / Number(this.pageSize)) > this.pageNo
        )
      },
    },
    methods: {
      // 下拉加载更多
      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },

      async _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        const params = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          emailAccount: this.emailAccount,
          keyword: this.query
        }
        const res =
          await MailInteractor.pageContactApi(params)
        this.selectLoading = false
        if (res?.code === '000000' && res?.data?.records) {
          this.total = res.data?.total
          this.contactList = loadMore
            ? [...this.contactList, ...res.data.records]
            : res.data.records
        } else {
          this.contactList = []
        }
      },

      _reset() {
        this.pageNo = 1
        this.total = 0
        this.contactList = []
      },

      // 搜索邮箱
      remoteMethod(query) {

        this.query = query
        this.selectLoading = true
        this._getOptions()
      },

      hide() {
        this.$refs.mailInput?.blur()
      },
      addMailUser() {
        if (this.currentMail) {
          let mailReg =/^[^@]+@[^@]+$/;
          if (mailReg.test(this.currentMail)){
            let arr = this.currentMail.split('@');
            let strReg = /^[a-zA-Z0-9](?:[a-zA-Z0-9._+-]*[a-zA-Z0-9])?$/;
            let endReg = /^(?!.*\.\.)([a-zA-Z0-9][a-zA-Z0-9\.-]*[a-zA-Z0-9]?)\.[a-zA-Z]+$/;
            if(arr?.length > 1 && strReg.test(arr[0]) && endReg.test(arr[1])){
              this._emailCheckPassed()
            }else{
              this.$message.warning(this.$t('reqmsg.$80'))
              this.currentMail = ''
            }

          }else{
            this.$message.warning(this.$t('reqmsg.$80'))
            this.currentMail = ''
          } 
        }
      },


      _emailCheckPassed() {
        if (this.list.includes(this.currentMail)) {
          return this.$message({
            type: 'warning',
            message: this.$t('reqmsg.$54'),
          })
        }
        this.list.splice(this.list.length - 1, 0, this.currentMail)
        this.$emit('input', this.list)
        this.currentMail = ''
      },
      updateValue(val, index) {
        if (this.list.includes(val) && this.list[index] !== val) {
          this.$message({
            type: 'warning',
            // message: '',
            message: this.$t('reqmsg.$54'),
          })
        } else {
          this.list.splice(index, 1, val)
          this.$emit('input', this.list)
        }
      },
      deleteMail(index) {
        this.list.splice(index, 1)
        this.$forceUpdate()
        this.$emit('input', this.list)
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-input__suffix {
      display: none !important;
    }
  }

  .mail-user {
    display: flex;
    flex-wrap: wrap;

    .mail-user-input {
      flex: 1;
      width: 100%;
      min-width: 100px;

      .el-select {
        width: 100%;


      }

      >>>.el-input__inner {
        min-width: 100px;
        border: none !important;
        height: 30px !important;
        line-height: 30px !important;
      }
    }
  }
</style>
<style>
  .bank-select-emali .el-select-dropdown__list {
    max-height: 185px !important;
    overflow: auto;
    overflow-x: hidden;
  }
</style>
