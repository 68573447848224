export const columns = [
  {
    label: '运输方式',
    prop: 'transportWayCn',
    width: '',
    isShow: true,
    checkable: true,
  },
  {
    label: '英文名称',
    prop: 'transportWayEn',
    width: '',
    isShow: true,
    checkable: true,
  },
  {
    label: '运输类型',
    prop: 'transportType',
    width: '',
    isShow: true,
    checkable: true,
  },
  {
    label: '报价币种',
    prop: 'currency',
    width: '',
    isShow: true,
    checkable: true,
  },
  {
    label: '计费方式',
    prop: 'billingMethod',
    width: '230',
    isShow: true,
    checkable: true,
  },
  {
    label: '运输周期',
    prop: 'transportCycle',
    width: '',
    isShow: true,
    checkable: true,
  },
  {
    label: '备注',
    prop: 'remark',
    width: '',
    isShow: true,
    checkable: true,
  },
  {
    label: '最近更新人',
    prop: 'updateName',
    width: '',
    isShow: true,
    checkable: true,
  },
  {
    label: '最近更新时间',
    prop: 'updateTime',
    width: '',
    isShow: true,
    checkable: true,
  },
  {
    label: '状态',
    prop: 'status',
    width: '',
    isShow: true,
    checkable: true,
  },
]

export const transportType = [
  {
    value: 1,
    label: "空运"
  },
  {
    value: 2,
    label: "海运"
  },
  {
    value: 3,
    label: "快递"
  }
]

export const billingMethod = [
  {
    value: 1,
    label: "体积"
  },
  {
    value: 2,
    label: "Max（重量，体积重：体积/6000）"
  },
  {
    value: 3,
    label: "Max（重量，体积重：体积/5000）"
  },
  {
    value: 5,
    label: "重量"
  },
]

export const currency = [
  {
    value: "人民币",
    label: "人民币",
  },
  {
    value: "美元",
    label: "美元",
  },
]