<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    v-if="dialogFormVisible"
    width="60%"
    @close="close"
    :close-on-click-modal="false"
  >
    <!--库存流水 -->
    <div class="dialog-content">
      <!-- 搜索 -->
      <el-row type="flex">
        <el-form ref="form" :inline="true" :model="ruleForm" label-width="49px">
          <el-form-item>
            <el-date-picker
              v-model="date"
              align="left"
              class="pub-h-36"
              type="daterange"
              style="width: 240px"
              value-format="yyyy-MM-dd"
              start-placeholder="操作开始时间"
              end-placeholder="操作结束时间"
              @change="dateChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select
              size="small"
              placeholder="类型筛选"
              class="mr10 pub-h-36"
              v-model="ruleForm.type"
              value-key="type"
              clearable
              @change="typeChange"
            >
              <el-option
                v-for="(item, index) of stockRecordTypeData"
                :key="index"
                :value="item.value"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="text-left">
          <el-button class="pub-h-36" @click="search">搜索</el-button>
        </div>
      </el-row>
      <!-- 列表 -->
      <el-table :data="tableData" class="w100 mt10 mb30" border>
        <el-table-column
          prop="createTime"
          label="操作时间"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column prop="type" label="类型" align="center">
          <template slot-scope="scope">
            <!-- {{ scope.row.type | typeFilter }} -->
            {{ scope.row.typeName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNumber"
          label="单据号"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span
              class="link"
              @click="
                orderNumberClcik(
                  scope.row.orderId,
                  scope.row.type,
                  scope.row.orderNumber
                )
              "
            >
              {{ scope.row.orderNumber }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="warehouseName"
          label="仓库"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseName || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="orderNum" label="数量" align="center">
          <template slot-scope="scope">
            <span
              v-show="
                scope.row.type === 3 ||
                scope.row.type === 4 ||
                scope.row.type === 5
              "
            >
              -
            </span>
            {{ scope.row.orderNum || '--' }}
          </template>
        </el-table-column>

        <!-- <el-table-column prop="orderPrice" label="单价" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.orderPrice == null">******</span>
            <span v-else>{{ toFixed3(scope.row.orderPrice) || '--' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="orderTotalPrice" label="成本" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.orderTotalPrice == null">******</span>
            <el-row v-else>
              <span
                v-show="
                  scope.row.type === 3 ||
                  scope.row.type === 4 ||
                  scope.row.type === 5
                "
              >
                -
              </span>
              <span :class="scope.row.entryType == 1 ? 'red' : ''">
                <span v-show="scope.row.entryType == 1">-</span>
                {{ toFixed3(scope.row.orderTotalPrice) || '--' }}
              </span>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.remark || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="creator"
          label="操作人"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.creator || '--' }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total"
        :current-page="ruleForm.pageNo"
        :page-size="ruleForm.pageLe"
        :total="total"
        @current-change="currentChange"
      />
    </div>
    <!-- 付款单详情弹窗 -->
    <PaymentDetailModel ref="PaymentDetailModel" />
  </el-dialog>
</template>

<script>
  import PaymentDetailModel from '@/views/finance-center/cost-manage/components/payment-detail-model'
  import { storeManage } from '@/core/interactors/stock-center/stock-manage'
  export default {
    name: 'ProTurnoverModel',
    components: {
      PaymentDetailModel,
    },
    data() {
      return {
        title: '库存流水',
        dialogFormVisible: false,
        ruleForm: {
          startDate: '',
          endDate: '',
          pageLe: 10,
          pageNo: 1,
          sku: '',
          stockId: '',
        },

        date: [], //日期
        tableData: [],
        total: 0,
        areas: ['合肥', '义乌', '苏州'],
        stockRecordTypeData: [
          { name: '采购入库', value: 0 },
          { name: '退货入库', value: 1 },
          { name: '其他入库', value: 2 },
          { name: '加工入库', value: 15 },
          { name: '退料入库', value: 14 },
          { name: '销售出库', value: 3 },
          { name: '退货出库', value: 4 },
          { name: '领料出库', value: 13 },
          { name: '其他出库', value: 5 },
          { name: '成本调整', value: 6 },
          { name: '库存盘点', value: 7 },
          { name: '入库成本调整', value: 8 },
          { name: '出库成本调整', value: 9 },
          { name: '盘盈入库', value: 11 },
          { name: '盘亏出库', value: 16 },
        ],
      }
    },
    // 状态过滤
    filters: {
      typeFilter(status) {
        const typeMap = {
          0: '采购入库',
          1: '退货入库',
          2: '其他入库',
          3: '销售出库',
          4: '退货出库',
          5: '其他出库',
          6: '成本调整',
          7: '库存盘点',
          8: '入库成本调整',
          9: '出库成本调整',
        }
        return typeMap[status]
      },
    },
    created() {},
    methods: {
      //查看
      showAddEdit(data, option) {
        const {
          wrehouseDimensionView, //1仓库维度查看
        } = option
        this.wrehouseDimensionView = wrehouseDimensionView
        this.warehouseId = data.warehouseIds
        this.ruleForm.pageLe = 10
        this.ruleForm.pageNo = 1
        this.ruleForm.startDate = ''
        this.ruleForm.endDate = ''
        this.ruleForm.sku = data.skuId

        if (data.skuId) {
          this.getList()
        }

        this.dialogFormVisible = true
      },

      //列表数据
      async getList() {
        const requestParams = { ...this.ruleForm }
        if (this.wrehouseDimensionView == 1) {
          requestParams.warehouseId = this.warehouseId
        }
        let response = await storeManage.stockListPage(requestParams)
        if (response.code === '000000') {
          this.tableData = response.data.data
          this.total = response.data.total
        }
      },

      //单据号跳转
      orderNumberClcik(id, type, orderNumber) {
        //入库
        if (type === 0 || type === 1 || type === 2) {
          let routeData = this.$router.resolve({
            path: '/stock-center/inbound-sheet-detail',
            query: { warehouseInId: id, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        } else if (type === 3 || type === 4 || type === 5) {
          let routeData = this.$router.resolve({
            name: 'outbound-sheet-detail',
            query: {
              warehouseOutId: id,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        } else if (type === 6 || type === 8 || type === 9) {
          //费用详情
          // this.$refs.PaymentDetailModel.amendRow = orderNumber
          // this.$refs['PaymentDetailModel'].showReceiptDetail()

          let routeData = this.$router.resolve({
            path: '/stock-center/cost-adjustment/detail',
            query: {
              id: id,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      //日期
      dateChange(val) {
        if (val) {
          this.ruleForm.startDate = val[0]
          this.ruleForm.endDate = val[1]
        } else {
          this.ruleForm.startDate = ''
          this.ruleForm.endDate = ''
          this.search()
        }
      },

      typeChange(val) {
        if (val === '') {
          this.search()
        }
      },

      //分页
      currentChange(val) {
        this.ruleForm.pageNo = val
        this.getList()
      },

      // 搜索
      search() {
        this.ruleForm.pageNo = 1
        this.getList()
      },

      //关闭
      close() {
        this.date = []
        this.tableData = []
        this.total = 0
        this.ruleForm = {
          startDate: '',
          endDate: '',
          pageLe: 1,
          pageNo: 10,
          sku: '',
        }
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .link {
      color: #1890ff;
      cursor: pointer;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
