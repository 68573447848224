<template>
  <IndexCard title="入库申请" :loading="loading">
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="my-audit-item">
          <div class="content" @click="clientFollowClick(item.orderId)">
            <div class="left">
              <div class="top">
                <span>{{ item.typeName }}</span>
                <span :style="boldStyle.bold" class="ml5">
                  {{ item.orderNumber }}
                </span>
              </div>
              <div class="bottom">
                <span>创建人：{{ item.creator }}</span>
              </div>
            </div>
            <div class="right index-card-time">
              {{ item.createTime | timeFilter }}
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import Detail from '@/views/audit-process/audit-manage/components/detail.vue'
  import { HomeInteractor } from '@/core'
  export default {
    name: 'InboundApply',
    props: {},
    mixins: [mixins],
    components: {
      Detail,
    },
    // 组件状态值
    data() {
      return {
        list: [],
      }
    },
    computed: {},
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.loading = true
        HomeInteractor.listWarehouseTodoList({
          type: 1,
        }).then((res) => {
          if (res?.code === '000000') {
            this.list = res?.data
          }
        })
        this.loading = false
      },
      //跳转入库单详情
      clientFollowClick(id) {
        let routeData = this.$router.resolve({
          path: '/stock-center/confirm-sheet',
          query: {
            warehouseInId: id,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style scoped lang="scss">
  .my-audit-item {
    .content {
      display: flex;
      cursor: pointer;
      .left {
        display: flex;
        flex-direction: column;
        color: #000;
        flex: 1;
        .top {
          margin-bottom: 10px;
          color: #000;
        }
        .bottom {
          color: #5e5e5e;
          font-size: 12px;
          .user {
            margin-right: 7px;
          }
        }
      }
      .right {
        padding-left: 10px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
</style>
