export const columns = [{
  label: '操作时间',
  prop: 'createTime',
  isShow: true,
  checkable: false,
},
{
  label: 'SKU ID',
  prop: 'skuId',
  isShow: true,
  checkable: false,
},
{
  label: '产品名称',
  prop: 'productName',
  isShow: true,
  checkable: false,
},
{
  label: '规格',
  prop: 'productSpec',
  isShow: true,
  checkable: true,
},
{
  label: '所属仓库',
  prop: 'warehouseName',
  isShow: true,
  checkable: true,
},
{
  label: '入库单号',
  prop: 'warehouseInNumber',
  isShow: true,
  checkable: true,
},
{
  label: '入库类型',
  prop: 'warehouseInType',
  isShow: true,
  checkable: true,
},
{
  label: '入库所属地区',
  prop: 'warehouseInArea',
  isShow: true,
  checkable: true,
},
{
  label: '入库数量',
  prop: 'warehouseInNum',
  isShow: true,
  checkable: true,
},
{
  label: '入库单价',
  prop: 'warehouseInPrice',
  isShow: true,
  checkable: true,
},
{
  label: '入库成本',
  prop: 'warehouseInTotalPrice',
  isShow: true,
  checkable: true,
},
{
  label: '出库单号',
  prop: 'warehouseOutNumber',
  isShow: true,
  checkable: true,
},
{
  label: '出库类型',
  prop: 'warehouseOutType',
  isShow: true,
  checkable: true,
},
{
  label: '出库所属地区',
  prop: 'warehouseOutArea',
  isShow: true,
  checkable: true,
},
{
  label: '出库数量',
  prop: 'warehouseOutNum',
  isShow: true,
  checkable: true,
},
{
  label: '出库单价',
  prop: 'warehouseOutPrice;',
  isShow: true,
  checkable: true,
},
{
  label: '出库成本',
  prop: 'warehouseOutTotalPrice',
  isShow: true,
  checkable: true,
},
{
  label: '费用调整金额',
  prop: 'adjustPrice',
  isShow: true,
  checkable: true,
},
{
  label: '差异数量',
  prop: 'differenceNum',
  isShow: true,
  checkable: true,
},
{
  label: '差异金额',
  prop: 'differencePrice',
  isShow: true,
  checkable: true,
},
{
  label: '期末数量',
  prop: 'finalNum',
  isShow: true,
  checkable: true,
},
{
  label: '期末单价',
  prop: 'finalPrice',
  isShow: true,
  checkable: true,
},
{
  label: '期末总价',
  prop: 'finalTotalPrice',
  isShow: true,
  checkable: true,
},
{
  label: '采购单号',
  prop: 'purchaseNumber',
  isShow: true,
  checkable: true,
},
{
  label: '销售单号',
  prop: 'piNumber',
  isShow: true,
  checkable: true,
},
]