<template>
  <el-dialog
    title="发票设置"
    :visible.sync="dialogVisible"
    width="800px"
    @close="close"
    class="dialog-middle"
  >
    <el-form
      :model="form"
      label-width="80px"
      :rules="rules"
      size="small"
      ref="form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="公司名称" prop="financeName">
            <el-input
              v-model="form.financeName"
              maxlength="50"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="类型" prop="type">
            <el-select
              v-model="form.type"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option label="公司" :value="1"></el-option>
              <el-option label="个体户" :value="2"></el-option>
              <el-option label="海外" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="税号" prop="dutyParagraph">
            <el-input
              v-model="form.dutyParagraph"
              maxlength="50"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行" prop="openingBank">
            <el-input
              v-model="form.openingBank"
              maxlength="50"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地址" prop="conpanyAddress">
            <el-input
              v-model="form.conpanyAddress"
              maxlength="200"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账号" prop="account">
            <el-input
              v-model="form.account"
              maxlength="50"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话" prop="phone">
            <el-input
              v-model="form.phone"
              maxlength="50"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              placeholder="请输入500个字符以内"
              maxlength="500"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="close">取消</el-button>
      <el-button type="primary" size="mini" @click="submit" :loading="loading">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    FinanceInvoiceAdd,
    FinanceInvoiceUpdate,
  } from '@/api/finance/finance-setting'
  export default {
    name: 'InvoiceSetting',
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: {
          account: '', // 账号
          conpanyAddress: '', // 地址
          dutyParagraph: '', // 税号
          financeName: '', //  公司名称
          financeSetupId: null, //  id
          openingBank: '', // 开户行
          phone: '',
          remark: '', //备注
          type: '', //公司类型 1公司 2个体户 3海外
        },
        rules: {
          financeName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
          ],
          dutyParagraph: [
            { required: true, message: '请输入税号', trigger: 'blur' },
          ],
          type: [
            { required: true, message: '请选择公司类型', trigger: 'blur' },
          ],
          phone: [
            { validator: this.$formValidation.connectVerify, trigger: 'blur' },
          ],
        },
      }
    },
    created() {},
    methods: {
      //打开弹框
      showDialog(row) {
        this.dialogVisible = true
        if (row) {
          this.form = Object.assign({}, row)
        } else {
          this.form.financeSetupId = null
        }
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
      //清空表单
      close() {
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            let Method
            if (this.form.financeSetupId) {
              Method = FinanceInvoiceUpdate
            } else {
              Method = FinanceInvoiceAdd
            }
            this.loading = true
            Method(this.form)
              .then((res) => {
                this.loading = false
                if (res.code == '000000') {
                  this.$message.success(
                    this.form.financeSetupId ? '修改成功！' : '新增成功！'
                  )
                  this.$emit('success')
                  this.close()
                }
              })
              .catch((err) => {
                this.loading = false
              })
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
