<template>
  <el-dialog :title="$t(title)" width="50%" :visible.sync="isDialogVisible" class="mail-template-dialog"
    :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" label-position="top">
      <!-- label="执行邮箱：" -->
      <el-form-item prop="emailAccount" :label="$t('boxsetting.ExecuteMailbox')" :rules="[
          {
            required: true,
            message: $t('placeholder.plsSel'),
            trigger: 'blur',
          },
        ]">
        <el-select v-model="form.emailAccount" :placeholder="$t('placeholder.plsSel')" value-key="emailAccount"
          :multiple="!isEdit" :disabled="isEdit" ref="accountRef">
          <el-option v-for="item in accountList" :key="item.mailAccountId" :label="item.emailAccount"
            :value="item.emailAccount"></el-option>
        </el-select>
      </el-form-item>
      <!-- label="模板名称：" -->
      <el-form-item prop="templateName" :label="$t('boxsetting.TemplateName')" :rules="[
          {
            required: true,
            message: $t('placeholder.plsInput'),
            trigger: 'change',
          },
        ]">
        <el-input v-model="form.templateName" :placeholder="$t('placeholder.plsInput')" maxlength="50"></el-input>
      </el-form-item>
      <!-- label="模板内容：" -->
      <el-form-item prop="templateContent" :label="$t('boxsetting.TemplateContent')" :rules="[
          {
            required: true,
            message: $t('placeholder.plsInput'),
            trigger: 'change',
          },
        ]">
        <div class="editor">
          <Ueditor id="ueditorTemplate" ref="ueditor" frameHeight="300" @ueditor-focus="onEditorFocus" />
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button> -->
      <el-button @click="close">{{ $t('boxsetting.Cancel') }}</el-button>
      <el-button type="primary" @click="save">
        {{ $t('boxsetting.Confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { mailTemplateSave } from '@/api/mail/mail-setting'
  import { mapState } from 'vuex'
  import Ueditor from '@/components/Ueditor/Ueditor.vue'
  import { MailInteractor } from '@/core'
  export default {
    components: {
      Ueditor,
    },
    data() {
      return {
        isDialogVisible: false,
        title: 'dialogTitle.addTpl',
        form: {
          emailAccount: '',
          templateName: '',
          templateContent: '',
          mailTemplateId: null,
        },
        rules: {

        },
        isEdit: false
      }
    },
    computed: {
      ...mapState({
        accountList: (state) =>
          state.mail.accountList.filter((item) => item.authStatus !== 2),
        userInfo: (state) => state.user.userInfo,
      }),
    },
    mounted() { },
    methods: {
      showDialog(data) {

        this.isDialogVisible = true
        this.title = data ? 'dialogTitle.editTpl' : 'dialogTitle.addTpl'
        this.isEdit = !!data
        if (data) {
          this.form = {
            ...this.form,
            ...data
          }
        } else {
          this.form = {
            emailAccount: '',
            templateName: '',
            templateContent: '',
            mailTemplateId: null,
          }
        }
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
        if (this.$refs.ueditor) {

          this.$refs.ueditor.setReadyContent(this.form.templateContent)
        } else {
          setTimeout(() => {
            this.$refs.ueditor.setReadyContent(this.form.templateContent)
          }, 800);
        }

      },
      onEditorFocus() {
        this.$refs.accountRef.blur()
      },
      close() {
        this.form = {
          emailAccount: '',
          templateName: '',
          templateContent: '',
          mailTemplateId: null,
        }
        this.$refs.ueditor.setReadyContent('')
        this.isDialogVisible = false
      },
      save() {
        this.form.templateContent = this.$refs.ueditor.getUEContent()
        this.$refs.form.validate((valid) => {
          if (valid) {
            let params = {
              ...this.form,
              businessId: this.userInfo.userId,
              tenantId: this.userInfo.tenantId
            }
            const reqMethod = this.isEdit ? mailTemplateSave : MailInteractor.addMailTemplateApi
            reqMethod(params).then((res) => {
              if (res?.code == '000000') {
                this.$emit('success')
                if (this.form.mailTemplateId) {
                  // this.$message.success('修改成功')
                  this.$message.success(this.$t('reqmsg.M2017'))
                } else {
                  this.$message.success(this.$t('reqmsg.M2016'))
                }
                this.close()
              }
            })
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .mail-template-dialog {
    .el-select {
      width: 100%;
    }

    .editor {
      height: 360px;
    }
  }
</style>
