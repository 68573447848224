import request from '@/core/services/request'

export function repeatApi(config) {
  return request({
    url: config.url,
    method: config.method,
    data: config.data,
    headers: config.headers,
  })
}
