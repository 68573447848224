<!--
  功能：阶梯明细
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月22日 10:24:31
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-dialog
    title="阶梯明细"
    :visible.sync="config.visible"
    width="900px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="table-wrapper">
      <el-table :data="tableData" border stripe>
        <el-table-column
          label="区间金额(开始)"
          align="center"
          prop="beginAmount"
        ></el-table-column>
        <el-table-column
          label="区间金额(结束)"
          align="center"
          prop="endAmount"
        >
          <template slot-scope="scope">
            {{scope.row.endAmount === null ? '无穷大':scope.row.endAmount}}
          </template>
        </el-table-column>

        <el-table-column
          label="提成点(%)"
          align="center"
          show-overflow-tooltip
          prop="ratio"
        ></el-table-column>
      </el-table>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">关 闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'SettlementTplConfigInteractorLadderDetail',
    props: {
      tableData: {
        //列表数据
        type: Array,
        default: () => [],
      },
      config: {
        type: Object,
        default: () => {
          return {
            visible: false
          }
        },
      },
    },

    methods: {
      // 关闭
      close() {
        this.config.visible = false
      },
    },
  }
</script>

<style scoped></style>
