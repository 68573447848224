<template>
  <div class="upload_file">
    <div class="flex">
      <el-upload action="" :http-request="uploadImgAction" :before-upload="beforeUpload" multiple
        :show-file-list="false" :limit="limit" :file-list="fileList">
        <el-button size="small">
          <!-- 上传附件 -->
          {{ $t('Documentary.UA') }}
        </el-button>
      </el-upload>
      <div slot="tip" class="el-upload__tip ml10">
        {{
        $t('Documentary.support')
        }}：jpg、png、pdf、cdr、ai、wmf、eps、zip、7z、xls、xlsx、doc、ppt、avi、mp4、wmv
      </div>
    </div>

    <div>
      <!-- 定义上传成功之后的样式 -->
      <div class="file_list">
        <div v-for="(item, i) in uploadFileList" :key="i" class="file_line">
          <i class="icon_file el-icon-link"></i>
          <span class="file_name c_pointer">
            <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
              <router-link class="d_block upload-file-color"
                :to="`/preview-file/index?name=${item.name}&url=${item.url}`" target="_blank">
                {{ item.name }}
              </router-link>
            </el-tooltip>
          </span>
          <span class="size">{{ calculateFileSize(item.size) }}</span>
          <span class="time">{{ item.time }}</span>
          <i class="icon_del el-icon-close" @click="delFile(i)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { PublicMethods } from '@/core'
  import OSS from 'ali-oss'
  import { OSS_PATH } from '@/config/net.config'
  import { Loading } from 'element-ui'
  import { mapGetters } from 'vuex'
  import { getRandomNumFixed, getFileSuffix } from 'kits'

  let loading, ossClient
  export default {
    name: 'ErpUpload',
    props: {
      uploadParams: {
        type: Object,
        default: () => { },
      },
      index: {
        type: Number,
        default: 0,
      },
      limit: {
        type: Number,
        default: 9,
      },
      enclosure: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        fileList: [],
        uploadFileList: this.enclosure ? JSON.parse(this.enclosure) : [],
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    methods: {
      // 上传接口
      ossUpload(param) {
        return new Promise((resolve, reject) => {
          new Promise(async (rs, re) => {
            const res = await PublicMethods.getSTSToken({})

            // loading = Loading.service({
            //   fullscreen: true,
            //   background: 'rgba(0, 0, 0, 0.4)',
            //   text: 'Loading',
            // })
            if (res.data) {
              ossClient = new OSS({
                bucket: 'weiwo-erp',
                region: 'oss-cn-shanghai',
                stsToken: res.data.SecurityToken,
                accessKeyId: res.data.AccessKeyId,
                accessKeySecret: res.data.AccessKeySecret,
                secure: true,
              })
              rs(ossClient)
            } else {
              // loading.close()
              return this.$baseMessage(
                this.$t('reqmsg.M2053'),
                'error',
                false,
                'erp-hey-message-error'
              )
            }
          }).then((client) => {
            client
              .multipartUpload(
                `${OSS_PATH[param.type || 'user']
                }${+new Date()}${getRandomNumFixed()}.${getFileSuffix(
                  param.file?.name
                )}`,
                param.file
              )
              .then((results) => {
                resolve(results)
                this.$message.success(this.$t('Documentary.US'))
                // loading.close()
              })
              .catch((err) => {
                ossClient = null
                this.ossUpload()
                reject(err)
              })
          })
        })
      },

      // 附件回显
      initUpload(data) {
        if (data) {
          this.uploadFileList = JSON.parse(data)
        } else {
          this.uploadFileList = ''
        }
      },

      //文件大小
      calculateFileSize(size) {
        if (size) {
          if (size >= 1024 * 1024) {
            return parseFloat((size / (1024 * 1024)).toFixed(2)) + 'MB'
          } else {
            return parseFloat((size / 1024).toFixed(2)) + 'KB'
          }
        }
      },

      // 上传
      uploadImgAction({ file }) {
        const [file_name, file_size] = [file.name, file.size]
        if (1024 * 1024 * 20 > file_size) {
          this.ossUpload({ file })
            .then(({ res }) => {
              const file = {
                name: file_name,
                url: res.requestUrls[0],
                time: this.utils.timeFormat(new Date()),
                size: file_size,
              }
              this.uploadFileList.push(file)
              this.$emit('uploadList', this.uploadFileList, this.index)
              this.fileList = [...this.uploadFileList]
            })
            .catch((res) => { })
        }
      },

      // 删除文件
      delFile(i) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(() => {
            this.fileList.splice(i, 1)
            this.uploadFileList.splice(i, 1)
            this.$emit('uploadList', this.uploadFileList, this.index)
          })
          .catch(() => { })
      },

      // 文件上传之前
      beforeUpload(file) {
        // 文件格式校验暂不处理
        const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1)
        const fileType =
          'jpg、png、pdf、cdr、ai、wmf、eps、zip、7z、xls、xlsx、doc、ppt、avi、mp4、wmv'
        if (!fileType.includes(suffixName.toLowerCase())) {
          this.$message.error(this.$t('reqmsg.M2054'))
          return false
        }
        if (file.size > 1024 * 1024 * 20) {
          this.$message.error(`${this.$t('reqmsg.M2056')}20M!`)
          return false
        }
      },


    },
  }
</script>

<style lang="scss" scoped>
  .upload_file {
    max-height: 70px;
    overflow-y: auto;

    .file_list {
      .file_line {
        width: 360px;
        height: 32px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #ebeef5;
        line-height: 32px;
        font-size: 12px;
        color: #606266;
        position: relative;
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;

        span {
          margin-right: 15px;
        }

        .icon_file {
          font-size: 16px;
          color: #fa6400;
          position: absolute;
          left: 5px;
          top: 8px;
        }

        .file_name {
          width: 160px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-left: 25px;
        }

        .size {
          width: 55px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .icon_success {
          color: #1989fa;
        }

        .icon_del {
          font-size: 16px;
          position: absolute;
          right: 5px;
          top: 8px;
          cursor: pointer;
        }
      }
    }
  }

  .el-upload__tip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>