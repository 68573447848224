<template>
  <div class="wrapper" :style="{height:showSearch ? '70px' : '40px' }">
    <ul :key="settings" class="tabs-cont">
      <li v-for="(item, index) in tabs" :key="index">
        <div :class="{ active: active == index }" @click="activeFn(index)">
          <span>{{ settings === 'zh' ? item.nameCn : item.nameEn }}</span>
          <span v-if="item.num" class="num">{{ item.num }}</span>
        </div>
      </li>
    </ul>
    <!-- 搜索条件 -->
    <div class="tab-search" v-if="showSearch && tabs.length && !hasSearchType">
      <el-input :placeholder="$t('placeholder.plsInput')" clearable v-model="tabSearchVal" size="mini"
        @keyup.enter.native="searchClick">
        <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
      </el-input>
    </div>
    <div class="tab-search" v-if="showSearch && tabs.length && hasSearchType">
      <!-- 全选 -->
      <el-checkbox v-if="showCheckAll" :indeterminate="checkAllParam.isIndeterminate" class="mr10 ml10"
        @change="handleCheckAllChange" v-model="checkAllParam.checkAll">
      </el-checkbox>

      <!-- 日期图标 -->
      <i class="el-icon-date" :class="{'active-date':mailTabSearchType !=='FULL_TEXT'}" @click="toggleSearchType"></i>
      <!-- 全文搜索 -->
      <el-input v-if="mailTabSearchType==='FULL_TEXT'" :placeholder=" $t('placeholder.plsInput')" clearable
        v-model="tabSearchVal" size="mini" @keyup.enter.native="searchClick">
      </el-input>
      <!-- 日期搜索 -->
      <el-date-picker v-else v-model="searchDate" type="daterange" class="" value-format="yyyy-MM-dd"
        format="yyyy-MM-dd" :range-separator="$t('other.To')" :start-placeholder="$t('placeholder.startDate')"
        :end-placeholder="$t('placeholder.endDate')" size="mini" clearable></el-date-picker>
      <el-button size="small" class="date-search-btn" @click="searchClick">
        <i class="el-icon-search"></i>
      </el-button>

    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { BusEmit } from '@/utils/eventBus'
  export default {
    props: {
      tabs: {
        type: Array,
        default: () => {
          return [
            {
              nameCn: '全部',
              nameEn: 'All',
            },
            {
              nameCn: '未读',
              nameEn: 'Unread',
              num: 0,
            },
            {
              nameCn: '已读未处理',
              nameEn: 'Pending',
              num: 0,
            },
            {
              nameCn: '已处理',
              nameEn: 'Solved',
            },

          ]
        },
      },

      showSearch: {
        type: Boolean,
        default: false
      },

      hasSearchType: {
        type: Boolean,
        default: false
      },

      showCheckAll: {  //是否展示全选
        type: Boolean,
        default: true
      },

      checkAllParam: {  //全选相关参数
        type: Object,
        default: () => {
          return {
            isIndeterminate: false,
            checkAll: false
          }
        }
      }
    },
    data() {
      return {
        active: 0,
        tabSearchVal: '', //搜索值
        mailTabSearchType: "FULL_TEXT", //默认搜索全文
        searchDate: '',

      }
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
        userInfo: 'user/userInfo',
      }),
      keyWordSearchType() {
        if (this.$route.query.mailName === 'INBOX' || this.$route.query.mailName === '所有邮件') {  // 收件箱下搜索搜索类型
          return 8
        }
        if (this.$route.query.boxFullName === 'erp_heart_folder') {  //心标邮件搜索
          return 9
        }
        return 7    // 账号目录下搜索类型
      },
      hasDate() {
        return Array.isArray(this.searchDate) && this.searchDate[0]
      },

      isText() {
        return this.mailTabSearchType === 'FULL_TEXT'
      },

      isDate() {
        return this.mailTabSearchType === 'DATE'
      }
    },
    methods: {
      activeFn(index) {
        this.active = index
        this.$emit('tabItemCkickFn', index, {
          ...this.getSearchParam()
        })
      },

      // 搜索条件查询
      searchClick() {
        let keyWord = this.tabSearchVal?.trim()

        if (this.isText && !keyWord) {
          return
        } else {
          // 邮件列表查询数据
          BusEmit('MailList', 'updatePage', {
            ...this.getSearchParam()
          })
          // 清空顶部搜索条件
          BusEmit('ActionBar', 'resetFilter')
        }
      },

      // 是否发送请求
      getSearchParam() {

        let keyWord = this.tabSearchVal?.trim()
        let obj = {

        }
        if (keyWord) {
          obj = {
            keyWordSearchType: this.keyWordSearchType,  //查询当前文件夹下全部
            keyWord,
            businessId: this.userInfo.userId,
            tenantId: this.userInfo.tenantId,
            showSum: true,
            showMailAccountUserIds: true,
          }
        }
        if (this.hasDate) { // 日期搜索
          obj = {
            ...obj,
            startDate: this.searchDate[0],
            endDate: this.searchDate[1],
          }

        }
        return obj
      },

      toggleSearchType() {
        if (this.isText) {
          this.mailTabSearchType = 'DATE'
          this.tabSearchVal = ''
        } else {
          this.mailTabSearchType = 'FULL_TEXT'
          this.searchDate = ''
        }
      },

      // 全选框
      handleCheckAllChange(val) {

        this.$emit('checkAllChange', val)
        this.checkAllParam.isIndeterminate = false
      }
    },

    mounted() {
      if (this.$route.query.mlt) {
        this.active = this.$route.query.mlt
      }
    },

    watch: {
      active: {
        immediate: true,
        handler: function (val) {
          this.$emit('currentActive', val)
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;


    .tabs-cont {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      flex: 1;
      justify-content: space-around;

      li {
        height: 100%;
        padding: 0 5px;
        list-style: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;


        div {
          cursor: pointer;
          position: relative;
          color: #666;
          font-size: 14px;

          &.active {
            font-weight: 700;
            color: #333;
          }

          .num {
            position: absolute;
            top: -10px;
            left: 100%;
            color: rgba(217, 0, 27, 1);
            font-weight: 700;
          }
        }
      }
    }


    .tab-search {
      i {
        margin-right: 10px;
      }

      padding: 0 10px;
      display: flex;
      align-items: center;

      .el-input-group__prepend {
        background: #fff !important;
      }
    }
  }

  .el-icon-date {
    cursor: pointer;

    &.active-date {
      color: #409EFF;
    }
  }

  .el-date-editor {
    flex: 1;
  }

  .date-search-btn {
    background-color: #F5F7FA;
    height: 28px;
    width: 47px;
    display: flex;
    align-items: center;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    i {
      margin-right: 0px;
    }

  }
</style>