<template>
  <el-dialog
    title="设置采购开发"
    width="500px"
    :visible.sync="modelVisible"
    :close-on-click-modal="false"
    :before-close="close"
    append-to-body
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      v-if="modelVisible"
    >
      <el-form-item label="采购开发" prop="buyerId">
        <UserSelect
          roleName="采购员"
          @user-choose="(user) => chooseFromBusiness(user)"
        ></UserSelect>
      </el-form-item>
    </el-form>

    <div class="text-center mt30">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import UserSelect from '@/components/user-select'

  export default {
    props: ['purchaseUsers'],
    data() {
      return {
        form: {
          buyerId: '',
          buyerName: '',
          buyerNameEn: '',
          area: '',
        },
        modelVisible: false,
        rules: {
          buyerId: [{ required: true, message: '请选择', trigger: 'change' }],
        },
      }
    },
    components: {
      UserSelect,
    },
    methods: {
      init() {
        this.form = this.$options.data().form
        this.modelVisible = true
      },

      // 设置采购开发
      chooseFromBusiness(user) {
        const form = this.form
        form.buyerId = user ? user.userId : ''
        form.buyerName = user ? user.userName : ''
        form.buyerNameEn = user ? user.englishName : ''
        form.area = user ? user.area : ''
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.purchaseUsers.find((item) => {
              if (item.userId == this.form.buyerId) {
                this.form.buyerName = item.userName
                this.form.buyerNameEn = item.englishName
                this.form.area = item.area
              }
            })
            this.$emit('setBuyer', this.form)
            this.close()
          }
        })
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.modelVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
