<template>
  <div class="search_form">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="销售订单编号" prop="salesOrderNumbers">
        <el-input v-model="form.salesOrderNumbers" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="验货阶段" prop="inspectionStage">
        <el-select placeholder="请选择" style="width: 100%" v-model="form.inspectionStage">
          <el-option v-for="item in inspectionStageOptions" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="验货结果" prop="inspectionResult">
        <el-select placeholder="请选择" style="width: 100%" v-model="form.inspectionResult">
          <el-option v-for="item in inspectionResultOptions" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="验货总量">
        <div class="line_input">
          <el-form-item prop="startNumInspection">
            <el-input v-model="form.startNumInspection" maxlength="10" placeholder="请输入" clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');" />
          </el-form-item>
          <span>至</span>
          <el-form-item prop="endNumInspection">
            <el-input v-model="form.endNumInspection" maxlength="10" placeholder="请输入" clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');" />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="到货日期" prop="arriveDate">
        <el-date-picker v-model="arriveDate" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item label="期望验货日期" prop="inspectionDate">
        <el-date-picker v-model="inspectionDate" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item label="实际验货日期" prop="inspectionDate">
        <el-date-picker v-model="realInspectionData" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item label="验货员">
        <el-input clearable v-model="businessName" placeholder="请选择" @focus="selectUserClick('business')"
          @change="businessNameChange"></el-input>
      </el-form-item>
      <el-form-item label="采购开发">
        <UserSelect @user-choose="(user) => userSelect(user)" ref="userselect"></UserSelect>
      </el-form-item>
      <el-form-item label="客户代表">
        <el-input clearable v-model="fromBusinessName" placeholder="请选择" @focus="selectUserClick('fromBusiness')"
          @change="fromBusinessNameChange"></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="companyName">
        <el-input clearable v-model="creatorName" placeholder="请选择" @focus="selectUserClick('creatorName')"
          @change="creatorChange"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-date-picker v-model="createTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-divider />

      <div class="btn">
        <el-button type="primary" @click="searchClick">确定</el-button>
        <el-button @click="resetClick">重置</el-button>
      </div>
    </el-form>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import UserChoose from '@/components/userChoose2'
  import UserSelect from '@/components/user-select'
  export default {
    name: 'manageSearch',
    components: {
      UserChoose,
      UserSelect,
    },
    data() {
      return {
        businessName: '', // 客户代表名称
        businessNameId: [], // 客户代表id
        fromBusinessName: '', // 订单支持名称
        fromBusinessNameId: [], // 订单支持id
        buyerId: '', // 采购开发id
        creatorName: '', //创建人
        creatorId: [], //创建人id
        isUserChoose: '', //当前组织架构是谁调用
        createTime: [], // 创建时间
        arriveDate: [], // 到货日期
        inspectionDate: [], // 期望验货日期
        realInspectionData: [], //实际验货日期
        form: {},
        daterange: [],
        inspectionStageOptions: [
          {
            label: '初期验货',
            value: 1,
          },
          {
            label: '中期验货',
            value: 2,
          },
          {
            label: '尾期验货',
            value: 3,
          },
        ], // 验货阶段字典
        inspectionResultOptions: [
          { label: '合格', value: 0 },
          { label: '部分不合格', value: 1 },
          { label: '不合格', value: 2 },
        ], // 验货结果字典
        rules: {
          salesOrderNumbers: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
          productInfo: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],

        },
      }
    },
    watch: {
      // 创建时间
      createTime(dateArray) {
        if (dateArray) {
          this.form.startCreateTime = dateArray[0]
          this.form.endCreateTime = dateArray[1]
        } else {
          this.form.startCreateTime = ''
          this.form.endCreateTime = ''
        }
      },
      // 到货日期
      arriveDate(dateArray) {
        if (dateArray) {
          this.form.startArriveDate = dateArray[0]
          this.form.endArriveDate = dateArray[1]
        } else {
          this.form.startArriveDate = ''
          this.form.endArriveDate = ''
        }
      },
      // 验货日期
      inspectionDate(dateArray) {
        if (dateArray) {
          this.form.startInspectionDate = dateArray[0]
          this.form.endInspectionDate = dateArray[1]
        } else {
          this.form.startInspectionDate = ''
          this.form.endInspectionDate = ''
        }
      },
      realInspectionData(dateArray) {
        if (dateArray) {
          this.form.startActualInspectionDate = dateArray[0]
          this.form.endActualInspectionDate = dateArray[1]
        } else {
          this.form.startActualInspectionDate = ''
          this.form.endActualInspectionDate = ''
        }
      },
    },
    created() { },
    methods: {
      // 采购开发，客户代表，创建人选择
      selectUserClick(str) {
        this.isUserChoose = str
        if (str === 'business') {
          this.$refs['UserChoose'].showAddEdit(
            'listSearch',
            this.businessNameId
          )
        } else if (str == 'fromBusiness') {
          this.$refs['UserChoose'].showAddEdit(
            'listSearch',
            this.fromBusinessNameId
          )
        } else {
          this.$refs['UserChoose'].showAddEdit('listSearch', this.creatorId)
        }
      },

      // 获取客户代表
      chooseUser(checkedUser) {
        if (this.isUserChoose === 'business') {
          this.businessNameChange()
        } else if (this.isUserChoose === 'fromBusiness') {
          this.fromBusinessNameChange()
        } else {
          this.creatorChange()
        }

        if (!checkedUser.length) {
          return
        }
        let userName = []
        checkedUser.forEach((item) => {
          userName.push(item.name)
          if (this.isUserChoose === 'business') {
            this.businessName = userName.join(',')
            this.businessNameId.push(item.id)
          } else if (this.isUserChoose === 'fromBusiness') {
            this.fromBusinessName = userName.join(',')
            this.fromBusinessNameId.push(item.id)
          } else {
            this.creatorName = userName.join(',')
            this.creatorId.push(item.id)
          }
        })
      },

      // 设置采购开发
      userSelect(user) {
        if (!user?.userId) {
          this.buyerId = ''
        } else {
          this.buyerId = user.userId
        }
      },

      // 查询
      searchClick() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.businessNameId) {
              this.form.inspectorId = this.businessNameId.join(',')
            }
            if (this.fromBusinessNameId) {
              this.form.businessId = this.fromBusinessNameId.join(',')
            }
            if (this.creatorId) {
              this.form.creatorId = this.creatorId.join(',')
            }
            this.form.buyerId = this.buyerId
            this.$emit('searchClick', this.form)
          }
        })
      },

      // 重置
      resetClick() {
        this.createTime = []
        this.arriveDate = []
        this.inspectionDate = []
        this.realInspectionData = []

        this.businessNameChange()
        this.fromBusinessNameChange()
        this.creatorChange()
        this.$refs.form.resetFields()
        this.resetBuyer()
        this.form = this.$options.data().form
      },
      // 重置采购开发
      resetBuyer() {
        this.buyerId = ''
        this.$refs.userselect.user = {}
      },

      // 删除客户代表
      businessNameChange() {
        this.businessNameId = []
        this.businessName = ''
        this.form.inspectorId = ''
      },

      // 删除订单支持
      fromBusinessNameChange() {
        this.fromBusinessNameId = []
        this.fromBusinessName = ''
        this.form.businessId = ''
      },

      // 删除创建人
      creatorChange() {
        this.creatorId = []
        this.creatorName = ''
        this.form.creatorId = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search_form {
    padding: 0 20px 80px 20px;

    .el-date-editor--datetimerange.el-input__inner {
      width: 100%;
    }

    .btn {
      padding: 20px 0;
      width: 100%;
      position: fixed;
      bottom: 0;
      background: white;
    }
  }

  ::v-deep {
    .el-input--suffix {
      padding-right: 5px !important;
    }

    .el-cascader {
      width: 100%;
    }

    .el-date-editor,
    .el-select {
      width: 100%;
    }

    .el-divider {
      margin: 70px 0 28px 0 !important;
    }
  }
</style>