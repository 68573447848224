<template>
  <div class="search-form">
    <el-form
      class="search-form-content"
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="24">
        <el-col :span="24">
          <!-- <el-form-item label="客户邮箱" prop="email"> -->
          <el-form-item
            :label="$t('cusManage.CustomerMailbox')"
            prop="email"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
                message: $t('other.Incorrectformat'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              disabled
              v-model="form.email"
              :placeholder="$t('placeholder.plsInput')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- <el-form-item label="客户姓名" prop="customerName"> -->
          <el-form-item
            :label="$t('cusManage.CustomerName')"
            prop="customerName"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              :disabled="onlyRead"
              v-model="form.customerName"
              :maxlength="20"
              show-word-limit
              :placeholder="$t('placeholder.plsInput')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- label="公司名称" -->
          <el-form-item
            :label="$t('cusManage.CompanyName')"
            prop="companyName"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :disabled="form.companyName.length < 25"
              :content="form.companyName"
              placement="top"
            >
              <el-input
                :disabled="onlyRead"
                v-model="form.companyName"
                :placeholder="$t('placeholder.plsInput')"
                type="textarea"
                show-word-limit
                maxlength="500"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- label="公司地址" -->
          <el-form-item
            prop="companyAddress"
            :label="$t('cusManage.CompanyAddress')"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :disabled="form.companyAddress.length < 25"
              :content="form.companyAddress"
              placement="top"
            >
              <el-input
                :disabled="onlyRead"
                :maxlength="50"
                show-word-limit
                v-model.trim="form.companyAddress"
                :placeholder="$t('placeholder.plsInput')"
              />
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- label="付款方式" -->
          <el-form-item :label="$t('cusManage.PaymentMethod')" prop="paymentId">
            <el-select
              :disabled="onlyRead"
              multiple
              collapse-tags
              v-model="form.paymentId"
            >
              <el-option
                v-for="item in paymentShowVOS"
                :key="item.paymentId"
                :value="item.paymentId"
                :label="item.payway || item.paymentId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- label="联系电话" -->
          <el-form-item prop="phone" :label="$t('cusManage.ContactNumber')">
            <el-tooltip
              class="item"
              effect="dark"
              :disabled="form.phone.length < 40"
              :content="form.phone"
              placement="top"
            >
              <el-input
                :disabled="onlyRead"
                v-model="form.phone"
                :maxlength="50"
                show-word-limit
                :placeholder="$t('placeholder.plsInput')"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- 客户来源 -->
          <el-form-item
            :label="$t('cusManage.CustomerSource')"
            prop="customerSourceId"
          >
            <el-select
              v-if="onlyRead"
              :disabled="onlyRead"
              v-model="form.customerSourceId"
            >
              <el-option
                v-for="item in sourceEditVOS"
                :key="item.sourceId"
                :value="item.sourceId"
                :label="item.sourceName"
              ></el-option>
            </el-select>
            <el-input v-else disabled :value="'邮箱'"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- label="客户等级" -->
          <el-form-item
            :label="$t('cusManage.CustomerLevel')"
            prop="customerRank"
          >
            <el-select :disabled="onlyRead" v-model="form.customerRank">
              <!-- <el-option :value="1" label="1级"></el-option>
              <el-option :value="2" label="2级"></el-option>
              <el-option :value="3" label="3级"></el-option>
              <el-option :value="4" label="4级"></el-option>
              <el-option :value="5" label="5级"></el-option> -->
              <el-option :value="1" :label="$t('selOpt.l1')"></el-option>
              <el-option :value="2" :label="$t('selOpt.l2')"></el-option>
              <el-option :value="3" :label="$t('selOpt.l3')"></el-option>
              <el-option :value="4" :label="$t('selOpt.l4')"></el-option>
              <el-option :value="5" :label="$t('selOpt.l5')"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- label="网址" -->
          <el-form-item :label="$t('cusManage.WebAddress')" prop="website">
            <el-tooltip
              class="item"
              effect="dark"
              :disabled="form.website.length < 40"
              :content="form.website"
              placement="top"
            >
              <el-input
                :disabled="onlyRead"
                v-model="form.website"
                :maxlength="50"
                show-word-limit
                :placeholder="$t('placeholder.plsInput')"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- label="传真" -->
          <el-form-item prop="faxNumber" :label="$t('cusManage.FaxNumber')">
            <el-tooltip
              class="item"
              effect="dark"
              :disabled="form.faxNumber.length < 40"
              :content="form.faxNumber"
              placement="top"
            >
              <el-input
                :disabled="onlyRead"
                v-model="form.faxNumber"
                :maxlength="50"
                show-word-limit
                :placeholder="$t('placeholder.plsInput')"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- label="备注" -->
          <el-form-item prop="remark" :label="$t('cusManage.Remark')">
            <el-tooltip
              class="item"
              effect="dark"
              :disabled="form.remark.length < 25"
              :content="form.remark"
              placement="top"
            >
              <el-input
                :disabled="onlyRead"
                v-model="form.remark"
                :maxlength="500"
                show-word-limit
                :placeholder="$t('placeholder.plsInput')"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="operate" v-if="!onlyRead">
      <!-- <el-button type="primary" @click="submitForm">确认</el-button>
      <el-button type="default" @click="closeClick">取消</el-button> -->
      <el-button type="primary" @click="submitForm">
        {{ $t('cusManage.Confirm') }}
      </el-button>
      <el-button type="default" @click="closeClick">
        {{ $t('cusManage.Cancel') }}
      </el-button>
    </div>

    <div class="operate" v-else>
      <!-- <el-button type="default" @click="closeClick">关闭</el-button> -->
      <el-button type="default" @click="closeClick">
        {{ $t('other.close') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { getCustomerSelectList, addCustomer } from '@/api/mail/contacts.js'
export default {
  data() {
    return {
      form: {
        companyAddress: '',
        companyId: '',
        companyName: '',
        customerCode: '',
        customerName: '',
        customerRank: '',
        customerSourceId: '',
        customerType: '',
        email: '',
        faxNumber: '',
        highSeas: 0,
        infoId: '',
        linkmanDTOS: [],
        paymentId: [],
        phone: '',
        receivingAddressConfigDTOS: [],
        remark: '',
        seasTime: '',
        tenantId: 0,
        timeRemaining: 0,
        website: '',
      },
      rules: {
        // email: [
        //   { required: true, message: '请输入', trigger: 'blur' },
        //   {
        //     pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
        //     message: '格式不正确',
        //     trigger: 'blur',
        //   },
        // ],
        // customerName: [
        //   { required: true, message: '请输入', trigger: 'blur' },
        //   { max: 20, message: '限制20个字符', trigger: 'blur' },
        // ],
        // phone: [{ max: 50, message: '限制50个字符', trigger: 'blur' }],
        // website: [{ max: 50, message: '限制50个字符', trigger: 'blur' }],
        // companyAddress: [{ max: 50, message: '限制50个字符', trigger: 'blur' }],
        // faxNumber: [{ max: 50, message: '限制50个字符', trigger: 'blur' }],
        // remark: [{ max: 500, message: '限制500个字符', trigger: 'blur' }],
        // companyName: [
        //   { required: true, message: '请输入', trigger: 'blur' },
        //   { max: 500, message: '限制500个字符', trigger: 'blur' },
        // ],
      },
      paymentShowVOS: [],
      sourceEditVOS: [],
    }
  },
  computed: {
    onlyRead() {
      return this.config !== null
    },
  },
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  methods: {
    showInfo(v) {
      getCustomerSelectList().then((res) => {
        this.paymentShowVOS = res.data.paymentShowVOS || []
        this.sourceEditVOS = res.data.sourceEditVOS || []

        this.form.email = v.baseInfo.sendEmail
        if (this.config) {
          // this.form = { ...this.config }
          for (const k in this.config) {
            if (Object.hasOwnProperty.call(this.config, k)) {
              this.form[k] = this.config[k] === null ? '' : this.config[k]
            }
          }
          this.form.customerType = this.form.customerType || ''
          if (this.config.pays && this.config.pays.length) {
            this.form.paymentId = this.config.pays
          }
        }
      })
    },
    closeClick() {
      this.$emit('closeClick')
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addCustomer({
            ...this.form,
            customerCode: this.$store.state.mail.currentAccount,
          }).then((res) => {
            if (res.code == '000000') {
              this.$message({
                type: 'success',
                // message: '新增客户成功！',
                message: this.$t('reqmsg.$16'),
              })
              this.$emit('closeClick')
            }
          })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.search-form {
  height: 90%;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 40px;
  .search-form-content {
    padding: 0 20px;
    .el-form-item {
      span {
        color: #000;
      }
      .el-select {
        width: 100%;
      }
    }
  }
  .operate {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ededed;
    padding: 20px;
  }
}
</style>
