<template>
  <el-dialog
    :visible.sync="params.show"
    :title="title"
    :width="viewType == 1 ? '560px' : '690px'"
    append-to-body
    @close="params.show = false"
    :close-on-click-modal="false"
    modal-append-to-body
  >
    <div class="table-wrapper">
      <el-table border ref="multipleTable" :data="tableData" max-height="350px">
        <el-table-column
          align="center"
          label="分摊类型"
          prop="shareType"
          :formatter="shareTypeFormatter"
        ></el-table-column>
        <el-table-column align="center" label="分摊金额" prop="shareAmount">
          <template slot-scope="scope">
            <span class="mr5">{{ scope.row.shareAmount }}</span>

            <el-popover
              placement="top"
              title=""
              trigger="hover"
              :ref="`node-popover-${scope.row.shippingFreightRecordId}`"
            >
              <p v-html="scope.row.calcuDesc" class="wpre"></p>
              <i
                slot="reference"
                class="el-icon-warning-outline"
                v-show="scope.row.shareType == 1 && viewType == 1"
              ></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sku"
          v-if="viewType == 2"
          label="产品编码"
        >
          <template slot-scope="scope">
            {{ scope.row.sku || '--' }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="createTime"
          width="150"
          :label="viewType == 1 ? '分摊时间' : '创建时间'"
        >
              <template slot-scope="scope">
            {{ scope.row.createTime || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="调整原因"
          prop="adjustReason"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.adjustReason || '--' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'ShippingDetails',
    props: {
      tableData: {
        type: Array,
        default: () => [],
      },
      viewType: {
        type: Number,
        default: 1, //1 分摊明细 2 运费明细
      },
      params: {
        type: Object,
        default: () => ({
          show: false,
        }),
      },
    },
    computed: {
      title() {
        return this.viewType == 1 ? '分摊明细' : '运费明细'
      },
    },

    methods: {
      shareTypeFormatter(row, column, cellValue, index) {
        return cellValue == 1 ? '自动分摊' : '手动调整'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wpre {
    white-space: pre;
  }
  .table-wrapper {
    padding-bottom: 20px;
  }
</style>
