<template>
  <el-dialog :title="$t(title)" width="50%" :visible.sync="isDialogVisible" class="mail-sign-dialog"
    :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" label-position="top">
      <!-- label="执行邮箱：" -->
      <el-form-item prop="emailAccount" :label="$t('boxsetting.ExecuteMailbox')" :rules="[
          {
            required: true,
            message: $t('placeholder.plsSel'),
            trigger: 'blur',
          },
        ]">
        <el-select v-model="form.emailAccount" :placeholder="$t('placeholder.plsSel')" value-key="emailAccount"
          :disabled="isEdit" :multiple="!isEdit" ref="accountRef">
          <el-option v-for="item in accountList" :key="item.mailAccountId" :label="item.emailAccount"
            :value="item.emailAccount"></el-option>
        </el-select>
      </el-form-item>
      <!-- label="签名标题：" -->
      <el-form-item prop="title" :label="$t('boxsetting.SignatureTitle')" :rules="[
          {
            required: true,
            message: $t('placeholder.plsInput'),
            trigger: 'change',
          },
        ]">
        <el-input v-model="form.title" :placeholder="$t('placeholder.plsInput')" maxlength="50"></el-input>
      </el-form-item>
      <!-- label="签名内容：" -->
      <el-form-item prop="content" :label="$t('boxsetting.SignatureContent')" :rules="[
          {
            required: true,
            message: $t('placeholder.plsInput'),
            trigger: 'change',
          },
        ]">
        <div class="editor">
          <Ueditor id="ueditorSign" ref="ueditor" frameHeight="300" @ueditor-focus="onEditorFocus" />
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button> -->
      <el-button @click="close">
        {{ $t('boxsetting.Cancel') }}
      </el-button>
      <el-button type="primary" @click="save">
        {{ $t('boxsetting.Confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { mailSignSave } from '@/api/mail/mail-setting'
  import { MailInteractor } from '@/core/interactors/mail/mail'

  import { mapState } from 'vuex'
  import Ueditor from '@/components/Ueditor/Ueditor.vue'
  export default {
    components: {
      Ueditor,
    },
    data() {
      return {
        isDialogVisible: false,

        title: 'boxsetting.AddSignature',
        form: {
          emailAccount: '',
          title: '',
          content: '',
        },
        rules: {

        },
        isEdit: false
      }
    },
    computed: {
      ...mapState({
        accountList: (state) =>
          state.mail.accountList.filter((item) => item.authStatus !== 2),
        userInfo: (state) => state.user.userInfo,
      }),
    },
    mounted() { },
    methods: {
      onEditorFocus() {
        this.$refs.accountRef.blur()
      },
      showDialog(data) {

        this.isDialogVisible = true
        this.title = data ? 'other.editSign' : 'boxsetting.AddSignature'
        this.isEdit = !!data
        if (data) {
          this.form = Object.assign({}, data)
        } else {
          this.form = {
            emailAccount: '',
            title: '',
            content: '',
          }
        }
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
        if (this.$refs.ueditor) {
          this.$refs.ueditor.setReadyContent(this.form.content)
        } else {

          setTimeout(() => {
            this.$refs.ueditor.setReadyContent(this.form.content)
          }, 800);
        }
      },
      close() {
        this.form = {
          emailAccount: '',
          title: '',
          content: '',
        }
        this.$refs.ueditor.setReadyContent(this.form.content)
        this.isDialogVisible = false
      },
      save() {
        this.form.content = this.$refs.ueditor.getUEContent()
        this.$refs.form.validate((valid) => {
          if (valid) {

            let params = {
              ...this.form,
              businessId: this.userInfo.userId,
              tenantId: this.userInfo.tenantId
            }
            const reqMethod = this.isEdit ? mailSignSave : MailInteractor.addMailSignatureApi
            reqMethod(params).then((res) => {
              if (res.code == '000000') {
                this.$emit('success')
                this.close()
              }
            })
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .mail-sign-dialog {
    .el-select {
      width: 100%;
    }

    .editor {
      height: 360px;
    }
  }
</style>