<template>
  <div class="search-form">
    <el-form class="search-form-content" ref="form" :model="form" label-position="top">
      <el-form-item label="联系人">
        <el-input clearable v-model="form.linkman" placeholder="请输入联系人"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="form.phone" placeholder="请输入联系电话" clearable></el-input>
      </el-form-item>
      <el-form-item label="联系人邮箱">
        <el-input clearable v-model="form.email" placeholder="请输入联系人邮箱"></el-input>
      </el-form-item>
      <el-form-item label="供应商等级" prop="supplierGrade">
        <el-select v-model="form.supplierGrade" placeholder="请选择" class="w100" clearable>
          <el-option v-for="item in supplierGradeOpts" :key="item.supplierGrade" :label="item.supplierGradeName"
            :value="item.supplierGrade"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商性质" prop="supplierNatureId">
        <el-select v-model="form.supplierNatureId" placeholder="请选择" @change="supplierNatureChange" class="w100"
          clearable>
          <el-option v-for="item in supplierNatureList" :key="item.supplierNatureId" :label="item.name"
            :value="item.supplierNatureId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商类型" prop="supplierTypeId">
        <el-select v-model="form.supplierTypeId" placeholder="请选择" @change="supplierTypeChange" class="w100" clearable>
          <el-option v-for="item in supplierTypeList" :key="item.supplierTypeId" :label="item.name"
            :value="item.supplierTypeId"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="订单成交量(改)">
        <div class="line_input">
          <el-input v-model="form.turnover" placeholder="请输入" clearable />
          <span>至</span>
          <el-input
            v-model="form.inquiryCountMax1"
            placeholder="请输入"
            clearable
          />
        </div>
      </el-form-item> -->
      <!-- <el-form-item label="订单总金额(改)">
        <div class="line_input">
          <el-input
            v-model="form.inquiryCountMin2"
            placeholder="请输入"
            clearable
          />
          <span>至</span>
          <el-input
            v-model="form.inquiryCountMax2"
            placeholder="请输入"
            clearable
          />
        </div>
      </el-form-item> -->
      <el-form-item label="付款方式" prop="payId">
        <el-select v-model="form.payId" placeholder="请选择" class="w100" clearable>
          <el-option v-for="item in payWayOpts" :key="item.paymentId" :label="item.payway"
            :value="item.paymentId"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="第三方认证">
        <el-input clearable v-model="form.cardName" placeholder="请输入第三方认证"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择状态" class="w100" clearable>
          <el-option v-for="item in statusOpts" :key="item.status" :label="item.statusName"
            :value="item.status"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建人">
        <el-input clearable v-model="createName" placeholder="请选择" @focus="selectUserClick('createName')"
          @change="createNameChange"></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker clearable v-model="createDaterange" type="daterange" value-format="yyyy-MM-dd"
          start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
          @change="createDaterangeChange" />
      </el-form-item>
    </el-form>
    <div class="operate">
      <el-button type="primary" @click="searchClick">确定</el-button>
      <el-button @click="resetClick">重置</el-button>
    </div>

    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import { SupplierManageInteractor } from '@/core'

  import UserChoose from '@/components/userChoose2'
  export default {
    name: 'ClientManageSearch',
    components: { UserChoose },
    data() {
      return {
        form: {},
        supplierGradeOpts: [
          {
            supplierGrade: 1,
            supplierGradeName: '1级',
          },
          {
            supplierGrade: 2,
            supplierGradeName: '2级',
          },
          {
            supplierGrade: 3,
            supplierGradeName: '3级',
          },
          {
            supplierGrade: 4,
            supplierGradeName: '4级',
          },
          {
            supplierGrade: 5,
            supplierGradeName: '5级',
          },
        ], // 供应商等级列表(未定)
        supplierNatureList: [], // 供应商性质列表
        supplierTypeList: [], // 供应商类型列表
        payWayOpts: [], // 付款方式
        statusOpts: [
          {
            status: 0,
            statusName: '提交',
          },
          {
            status: 1,
            statusName: '审核中',
          },
          {
            status: 2,
            statusName: '审核通过',
          },
          {
            status: 3,
            statusName: '审核驳回',
          },
          {
            status: 4,
            statusName: '已撤销',
          },
          {
            status: 5,
            statusName: '录入中',
          },
          {
            status: 6,
            statusName: '无需审核',
          },
          {
            status: 7,
            statusName: '黑名单',
          },
        ],
        createDaterange: [], //创建时间
        linkman: '', //联系人
        linkmanId: [], //联系人id
        createName: '', //创建人
        createNameId: [], //创建人id
      }
    },
    created() {
      this.getSupplierNatureListAll()
      this.getSupplierTypeListAll()
      this.getPaymentListPage()
    },
    methods: {
      //获取供应商性质下拉表
      async getSupplierNatureListAll() {
        let response = await SupplierManageInteractor.supplierNatureListAll({})
        if (response.code === '000000') {
          this.supplierNatureList = response.data
        }
      },
      //获取供应商类型下拉表
      async getSupplierTypeListAll() {
        let response = await SupplierManageInteractor.supplierTypeListAll({})
        if (response.code === '000000') {
          this.supplierTypeList = response.data
        }
      },
      // 供应商性质选择-过滤出名称
      supplierNatureChange(id) {
        let filterSupplierNature = this.supplierNatureList.find((item) => {
          return item.supplierNatureId === id
        })
        this.form.supplierNatureName = filterSupplierNature?.name
      },
      // 供应商类型选择-过滤出名称
      supplierTypeChange(id) {
        let filterSupplierType = this.supplierTypeList.find((item) => {
          return item.supplierTypeId === id
        })
        if (filterSupplierType) {
          this.form.supplierTypeName = filterSupplierType.name
        }

      },
      //付款方式
      async getPaymentListPage() {
        let response = await SupplierManageInteractor.paymentListPage({
          payee: 2,
        })
        if (response.code === '000000') {
          this.payWayOpts = response.data
        }
      },
      //创建时间清空
      createDaterangeChange(val) {
        if (!val) {
          this.form.startCreateTime = ''
          this.form.endCreateTime = ''
        }
      },
      searchClick() {
        //创建时间
        if (this.createDaterange && this.createDaterange.length) {
          this.form.startCreateTime = this.createDaterange[0]
          this.form.endCreateTime = this.createDaterange[1]
        }
        //创建人
        if (this.createName) {
          this.form.creatorId = this.createNameId.join(',')
        }

        this.$emit('searchClick', this.form)
      },
      //创建人选择
      selectUserClick(str) {
        this.isUserChoose = str
        this.$refs['UserChoose'].showAddEdit('listSearch', this.createNameId)
      },
      //创建人返回
      chooseUser(checkedUser) {
        this.createNameChange()
        if (!checkedUser.length) {
          return
        }
        let userName = []
        checkedUser.forEach((item) => {
          userName.push(item.name)
          if (this.isUserChoose === 'createName') {
            this.createNameId.push(item.id)
            this.createName = userName.join(',')
          }
        })
      },
      // 删除创建人
      createNameChange() {
        this.createNameId = []
        this.createName = ''
        this.form.creatorId = ''
      },
      resetClick() {
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
        this.createDaterange = ''
        this.createNameId = []
        this.createName = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search-form {
    height: 85%;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 100px;

    .search-form-content {
      padding: 0 20px;
    }

    .operate {
      position: fixed;
      width: 100%;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #ededed;
      padding: 20px;
    }
  }
</style>
<style lang="scss" scoped>
  .search_form .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  ::v-deep {
    .el-date-editor {
      width: 100%;
    }

    .el-divider {
      margin: 30px 0 28px 0 !important;
    }

    .el-drawer__body {
      height: calc(100% - 44px);
    }
  }
</style>
