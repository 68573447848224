<template>
  <!-- <el-dialog title="新建文件夹" :visible.sync="addFolderVisible" width="25%"> -->
  <el-dialog
    :title="$t('other.newFolder')"
    :visible.sync="addFolderVisible"
    width="25%"
  >
    <el-form :model="form" label-width="100px" ref="form" label-position="top">
      <!--   label="文件夹名称" -->
      <el-form-item
        :label="$t('other.foldername')"
        prop="name"
        :rules="[
          {
            required: true,
            message: $t('placeholder.plsfoldername'),
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <!-- <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button> -->
      <el-button @click="cancel">{{ $t('myMail.Cancel') }}</el-button>
      <el-button type="primary" @click="submit">
        {{ $t('myMail.Confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      addFolderVisible: false,
      form: {
        name: '',
      },
    }
  },
  methods: {
    showDialog() {
      this.addFolderVisible = true
    },
    cancel() {
      this.addFolderVisible = false
      this.form.name = ''
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('addNewFolder', this.form.name)
        } else {
          return false
        }
      })
    },
  },
}
</script>