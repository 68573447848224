<template>
  <el-dialog
    title="报价"
    width="1200px"
    :visible.sync="addOfferVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-row id="pdfDom">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <div class="offer-container">
          <h4>基本信息</h4>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                class="label-left"
                label="Zip code"
                prop="zipCode"
                label-width="80px"
              >
                <el-input
                  placeholder="请输入"
                  v-model="form.zipCode"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="Vaildity for"
                prop="validityDate"
                label-width="130px"
              >
                <el-input
                  placeholder="请输入"
                  v-model="form.validityDate"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <h4 class="mt5">产品列表</h4>
          <div class="product-container">
            <div v-for="(item, i) in form.orderPriceSheetDTO" :key="i">
              <el-table
                class="dyn-table"
                border
                :data="form.orderPriceSheetDTO[i]"
                :span-method="objectSpanMethod"
                :header-cell-style="{ textAlign: 'center' }"
                :header-cell-class-name="star"
              >
                <el-table-column label="Item" width="240" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.sku">
                      {{ scope.row.sku }}
                      <span v-show="scope.row.productCn">
                        /{{ scope.row.productCn }}
                      </span>
                      <span v-show="scope.row.specification">
                        /{{ scope.row.specification }}
                      </span>
                    </div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>

                <el-table-column label="Qty" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.amount'
                      "
                      :rules="[
                        {
                          required: true,
                          message: 'Qty必填',
                          trigger: 'change',
                        },
                        {
                          pattern: /^[0-9]+?$/,
                          message: '请输入>=0的整数',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="10"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].amount
                        "
                        placeholder="请输入"
                        clearable
                        @blur="
                          changeAmount(
                            scope.row.newFlag,
                            scope.row.productId,
                            scope.row.amount,
                            scope.row.sku,
                            i,
                            scope.$index
                          )
                        "
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Net price($)" width="150">
                  <template slot-scope="scope">
                    <div class="net-price">
                      <el-button
                        class="icon-b"
                        v-show="scope.row.newFlag == 1"
                        @click="
                          calculatePrice(i, scope.$index, scope.row.amount)
                        "
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                      <el-form-item
                        :prop="
                          'orderPriceSheetDTO.' +
                          i +
                          '.' +
                          scope.$index +
                          '.netPrice'
                        "
                        :rules="[
                          {
                            required: true,
                            pattern: /^\d+(\.\d{1,3})?$/,
                            message: '>=0最多三位小数',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          maxlength="20"
                          v-model="
                            form.orderPriceSheetDTO[i][scope.$index].netPrice
                          "
                          placeholder="请输入"
                          @input="calculateTotal(i, scope.$index)"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Set up Charge($)" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.setUpCharge'
                      "
                      :rules="[
                        {
                          required: false,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: '>=0最多三位小数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].setUpCharge
                        "
                        placeholder="请输入"
                        @input="calculateTotal(i, scope.$index)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Shiping Method"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.newFlag == 1">
                      <el-form-item
                        :prop="
                          'orderPriceSheetDTO.' +
                          i +
                          '.' +
                          scope.$index +
                          '.shippingMethodId'
                        "
                        :rules="[
                          {
                            required: true,
                            message: 'Shiping Method必填',
                            trigger: 'change',
                          },
                        ]"
                      >
                        <el-select
                          v-model="
                            form.orderPriceSheetDTO[i][scope.$index]
                              .shippingMethodId
                          "
                          placeholder="请选择"
                          clearable
                        >
                          <el-option
                            v-for="item in transpWayList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>
                      {{ scope.row.transportationType }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Shipping Fee($)" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.shippingFee'
                      "
                      :rules="[
                        {
                          required: true,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: '>=0最多三位小数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].shippingFee
                        "
                        placeholder="请输入"
                        @input="calculateTotal(i, scope.$index)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Other Fee($)" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.otherFee'
                      "
                      :rules="[
                        {
                          required: false,
                          pattern: /^\d+(\.\d{1,3})?$/,
                          message: '>=0最多三位小数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].otherFee
                        "
                        placeholder="请输入"
                        @input="calculateTotal(i, scope.$index)"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Costs that" width="240">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.costsThat'
                      "
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="500"
                        show-word-limit
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].costsThat
                        "
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Production time" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.productionTime'
                      "
                      :rules="[
                        {
                          max: 50,
                          message: '长度不能超过50个字符',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index]
                            .productionTime
                        "
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Sample time" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.sampleTime'
                      "
                      :rules="[
                        {
                          max: 50,
                          message: '长度不能超过50个字符',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].sampleTime
                        "
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Shipping time" width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.shippingTime'
                      "
                      :rules="[
                        {
                          max: 50,
                          message: '长度不能超过50个字符',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input
                        maxlength="20"
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index].shippingTime
                        "
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="Description" width="240">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'orderPriceSheetDTO.' +
                        i +
                        '.' +
                        scope.$index +
                        '.productDescription'
                      "
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        maxlength="500"
                        show-word-limit
                        v-model="
                          form.orderPriceSheetDTO[i][scope.$index]
                            .productDescription
                        "
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Total Cost"
                  width="150"
                  fixed="right"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span class="green">
                      ${{
                        form.orderPriceSheetDTO[i][scope.$index].totalPrice || 0
                      }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  label="操作"
                  width="80"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      class="icon-r"
                      icon="el-icon-delete"
                      circle
                      @click="del(i, scope.$index)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="add">
                <span @click="add(i)">复制一条</span>
              </div>

              <el-row :gutter="20">
                <el-col :span="16">
                  <h4>Virtual confirm</h4>
                  <erp-upload-images
                    :limit="9"
                    :tips-visible="false"
                    @change="changeUploadList($event, i)"
                  ></erp-upload-images>
                </el-col>
                <el-col :span="8">
                  <h4>Remarks</h4>
                  <el-form-item prop="remark" clearable v-if="!printBol">
                    <el-input
                      type="textarea"
                      :rows="4"
                      v-model="form.orderPriceSheetDTO[i][0].remark"
                      maxlength="500"
                      show-word-limit
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                  <div v-if="printBol">
                    <div v-text="form.orderPriceSheetDTO[i][0].remark"></div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-form>
    </el-row>
    <div class="wrapper-mail mt10">
      <el-checkbox v-model="sendMailToCustomer">发送邮件给客户</el-checkbox>
    </div>

    <div class="text-center mt20">
      <el-button type="info" @click="preview()">预览</el-button>
      <el-button type="primary" @click="save()">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 计算net Price弹窗 -->
    <calculate-price
      ref="calculatePrice"
      @calcNetPriche="calcNetPriche"
    ></calculate-price>
  </el-dialog>
</template>

<script>
  import { getStepByProductId } from '@/api/stock-center/shipment'
  import { transpotwayList } from '@/api/shipment/shipment-plan'
  import { orderPriceSheet, orderInsertPO } from '@/api/order.js'
  import calculatePrice from '@/views/order/inquiryManage/components/calculatePrice.vue'
  import { getNowDate } from '@/utils/index'
  import { uploadPdfFile, orderPriceSheetEmail } from '@/api/mail/mail.js'
  export default {
    components: { calculatePrice },
    data() {
      return {
        enquiryId: '',
        addOfferVisible: false,
        form: {
          zipCode: '',
          validityDate: '',
          orderPriceSheetDTO: [],
        },
        rules: {
          zipCode: [
            {
              required: true,
              message: 'Zip code必填',
              trigger: 'blur',
            },
            {
              max: 50,
              message: '长度不能超过50个字符',
              trigger: 'blur',
            },
          ],
          validityDate: [
            {
              required: true,
              message: 'Vaildity',
              trigger: 'blur',
            },
            {
              max: 50,
              message: '长度不能超过50个字符',
              trigger: 'blur',
            },
            {
              validator: this.$formValidation.isPositiveInt,
              trigger: 'blur',
            },
          ],
        },
        sendMailToCustomer: true,
        printBol: false,
        transpWayList: [], // 运输方式字典
        isCache: null, // 预览是否走缓存
        previewForm: {},
        previewInfo: {},
        mailDetail: null,
      }
    },
    created() {
      this.getDict()
    },
    methods: {
      // 获取运输方式字典
      getDict() {
        transpotwayList().then((res) => {
          this.transpWayList = res.data.map((item) => {
            return {
              label: item.transportWayCn,
              value: item.id,
            }
          })
        })
      },
      // 获取报价信息_来自邮件
      getOrderByMail(mail) {
        this.mailDetail = mail
        this.addOfferVisible = true
        let enquiryCode = mail.baseInfo.businessJson
        if (enquiryCode) {
          orderPriceSheetEmail(enquiryCode)
            .then((res) => {
              if (res && res.code == '000000') {
                this.form.customerEmail = res.data?.customerEmail
                this.form.orderPriceSheetDTO = res.data?.orderEnquiryProductVOS
                this.enquiryId = res.data?.enquiryId
                this.offerInfoFormat()
                this.isCache = 0
              }
            })
            .catch(() => {
              this.enquiryId = ''
              this.form.customerEmail = ''
              this.form.orderPriceSheetDTO = []
            })
        } else {
          this.$message({
            type: 'warning',
            // message: '请先关联询盘单号',
            message: this.$t('reqmsg.$29'),
          })
        }
      },

      // 获取报价信息
      getOrderPriceSheet(id, data) {
        this.mailDetail = null
        this.enquiryId = id
        if (data) {
          // 询盘新增编辑生成报价单入口
          let source = []
          data.products.forEach((item) => {
            source.push([item])
          })
          this.form.customerEmail = data.customerEmail
          this.form.orderPriceSheetDTO = source
          this.offerInfoFormat()
          // 赋值新增编辑询盘预览信息
          this.previewForm = data
          this.isCache = 1
        } else {
          // 询盘列表报价入口
          orderPriceSheet({ enquiryId: id }).then((res) => {
            if (res && res.code == '000000') {
              this.form.customerEmail = res.data?.customerEmail
              this.form.orderPriceSheetDTO = res.data?.orderEnquiryProductVOS
              this.offerInfoFormat()
              this.isCache = 0
            }
          })
        }
      },

      // 报价添加保存字段
      offerInfoFormat() {
        this.form.orderPriceSheetDTO = this.form.orderPriceSheetDTO.map(
          (list, i) => {
            return list.map((item, k) => {
              return {
                ...item,
                otherFee: '', // Other Fee
                costsThat: '', // Costs that
                productionTime: '', // Production time
                sampleTime: '', // Sample time
                shippingTime: '', // Shipping time
                productDescription: item.demand || '', // Description
                remark: '', // remark
              }
            })
          }
        )
        this.addOfferVisible = true
      },

      // 更改询盘数量时价格需要相应变更
      changeAmount(flag, id, amout, sku, i, n) {
        if (flag != 1) {
          getStepByProductId({
            num: Number(amout),
            productId: id,
            sku: sku,
          }).then((res) => {
            if (res && res.code === '000000') {
              if (res.data) {
                this.$set(
                  this.form.orderPriceSheetDTO[i][n],
                  'netPrice',
                  Number(res.data.netPrice).toFixed(3)
                )
                this.$set(
                  this.form.orderPriceSheetDTO[i][n],
                  'shippingFee',
                  Number(res.data.transportPrice * amout).toFixed(3)
                )
                this.calculateTotal(i, n)
              }
            }
          })
        } else {
          this.calculateTotal(i, n)
        }
      },

      // 计算totalPrice = Qty*netPrice + setUpCharge + shippingFee + otherFee
      calculateTotal(i, n) {
        let list = this.form.orderPriceSheetDTO[i][n]
        if (list.amount && list.netPrice) {
          list.totalPrice = parseFloat(
            Number(list.amount * list.netPrice) +
              Number(list.setUpCharge) +
              Number(list.shippingFee) +
              Number(list.otherFee)
          ).toFixed(3)
        } else {
          list.totalPrice = 0
        }
      },

      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
      },

      // 生成pdf并上传后台得到url
      generatePdf() {
        this.printBol = true
        let title = `quotation-${getNowDate()}`

        this.$nextTick(() => {
          this.getPdf(title, 'pdfDom', false).then((res) => {
            //res拿到base64的pdf
            let pdfBase64Str = res
            let title = `quotation-${getNowDate()}`
            var myfile = this.dataURLtoFile(pdfBase64Str, title + '.pdf') //调用一下下面的转文件流函数
            var formdata = new FormData()
            formdata.append('file', myfile) // 文件对象
            formdata.append('flagSaveName', 1)
            formdata.append('source', 1)
            formdata.append('uploadType', 1)
            //该uploadMy为接口，直接以formdata格式传给后台
            uploadPdfFile(formdata)
              .then((res) => {
                let file = {
                  enclosureName: res.data.fileName,
                  fileId: res.data.fileId,
                  enclosureSize: res.data.size,
                  enclosureUrl: res.data.path,
                  suffix: res.data.suffix,
                  uploadType: 1,
                  mailEnclosureId: res.data.fileId,
                  source: 1,
                }
                let url = ''
                if (this.mailDetail) {
                  // 从邮件直接生成报价单
                  url = `${
                    window.location.origin
                  }/#/write?from=business&mailId=${
                    this.mailDetail.baseInfo.mailId
                  }&file=${JSON.stringify([file])}&email=${
                    this.mailDetail.baseInfo.sendEmail
                  }`
                } else {
                  url = `${
                    window.location.origin
                  }/#/write?from=business&file=${JSON.stringify(file)}&email=${
                    this.form.customerEmail
                  }`
                }
                this.utils.openWindow(url)
              })
              .catch((err) => {})
          })
        })
      },

      // 计算单价
      calculatePrice(i, n, amount) {
        if (!Number(amount) > 0 || amount === 0) {
          // this.$message.warning('Qty格式不正确！')
          this.$message.warning(this.$t('other.QtyTips'))
          return false
        }
        this.$refs.calculatePrice.initLoading([i, n, amount])
      },

      // 获取计算的net Price
      calcNetPriche(i, n, netPrice) {
        this.form.orderPriceSheetDTO[i][n].netPrice = netPrice
      },

      // 生成订单
      save() {
        let self = this
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // 遍历赋值remark
            this.form.orderPriceSheetDTO.forEach((list) => {
              const remark = list[0].remark
              list.forEach((item) => {
                item.remark = remark
              })
            })
            const params = {
              enquiryId: this.enquiryId,
              ...this.form,
            }
            orderInsertPO(params).then((res) => {
              if (res && res.code == '000000') {
                this.$message({
                  type: 'success',
                  // message: '新增成功！'
                  message: this.$t('reqmsg.M2016'),
                })
                if (this.sendMailToCustomer) {
                  this.generatePdf()
                } else {
                  // this.$parent.getList()
                  this.close()
                }
              }
            })
          } else {
            return false
          }
        })
      },

      // 复制一条
      add(i) {
        const source = this.form.orderPriceSheetDTO[i]
        source.push(JSON.parse(JSON.stringify(source[source.length - 1])))
      },

      // 删除一条
      del(i, n) {
        const source = this.form.orderPriceSheetDTO[i]
        if (source.length === 1) {
          this.form.orderPriceSheetDTO.splice(i, 1)
        } else {
          source.splice(n, 1)
        }
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (
          columnIndex == 1 ||
          columnIndex == 2 ||
          columnIndex == 4 ||
          columnIndex == 5
        ) {
          return 'star'
        }
      },

      // 合并单元格
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if (rowIndex === 0) {
            return {
              rowspan: 999, //合并的行数 默认给一个上限
              colspan: 1, //合并的列数，设为０则直接不显示
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },

      // 预览
      preview() {
        // 新增编辑预览入口
        if (this.isCache) {
          const form = this.previewForm
          this.previewInfo = {
            exportsRise: form.exportsRise,
            priceSheetCode: form.enquiryCode,
            priceSheetDate: form.quoteDate,
            customerName: form.customerName,
            companyName: form.companyName,
            customerEmail: form.customerEmail,
            customerPhone: form.customerPhone,
            companyAddress: form.companyAddress,
            salesName: form.salesName,
            salesEmail: form.salesEmail,
            zipCode: this.form.zipCode,
            validityDate: this.form.validityDate,
          }
        }
        const data = {
          ...this.form,
          ...this.previewInfo,
          enquiryId: this.enquiryId,
        }
        sessionStorage.setItem('inquiryPrint', JSON.stringify(data))
        window.open(
          `#/order/inquiryManage/inquiryPrint?preview=true&isCache=${this.isCache}`,
          '_blank'
        )
      },

      // 关闭弹窗
      close() {
        this.$refs.ruleForm.resetFields()
        this.addOfferVisible = false
      },

      // 上传 rendingsUrl
      changeUploadList(list, index) {
        let _urls = []
        let _len = list.length

        if (_len) {
          for (let i = 0; i < _len; i++) {
            _urls.push(list[i].url)
          }
        }
        this.form.orderPriceSheetDTO[index].forEach((item) => {
          item.rendingsUrl = _urls.toString()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .offer-container {
    h4 {
      margin-top: 0px;
    }
    .product-container {
      max-height: 420px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 5px;
      .add {
        text-align: center;
        line-height: 30px;
        margin-bottom: 10px;
        span {
          color: #1890ff;
          cursor: pointer;
        }
      }
    }
    .net-price {
      position: relative;
      .el-button {
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 2;
        height: 28px;
        width: 28px;
      }
    }
  }
  ::v-deep {
    .net-price .el-icon-edit {
      margin-top: -2px;
      text-indent: -2px;
    }
    .el-upload--picture-card {
      width: 88px;
      height: 88px;
      i {
        line-height: 88px;
        display: block;
      }
    }
    .el-upload-list__item {
      width: 88px;
      height: 88px;
    }
  }
</style>
