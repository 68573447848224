<template>
  <div class>
    <el-dialog
      title="新增费用单"
      :visible.sync="dialogFormVisible"
      width="1100px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="dialog-content">
        <el-form ref="form" :model="form" label-width="30%" :rules="rules">
          <el-row>
            <el-col :span="8">
              <el-form-item label="销售订单号">
                <el-input
                  placeholder="请输入"
                  style="width: 90%"
                  v-model="form.orderCode"
                  @blur="orderCodeBlur"
                  @input="searchInput"
                  clearable
                  v-if="!orderCodeList.length"
                  @change="orderCodeChange"
                ></el-input>
                <el-select
                  v-else
                  v-model="form.orderCode"
                  placeholder="请选择"
                  clearable
                  @change="orderCodeChange"
                >
                  <el-option
                    v-for="item in orderCodeList"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="采购订单号" prop="purchaseCode">
                <el-input
                  v-if="!purchaseList.length"
                  placeholder="请输入"
                  style="width: 90%"
                  v-model="form.purchaseCode"
                  @input="searchInput2"
                  @blur="purchaseCodeBlur"
                  clearable
                ></el-input>
                <el-select
                  v-else
                  v-model="form.purchaseCode"
                  placeholder="请选择"
                  clearable
                  @change="purchaseCodeChange"
                >
                  <el-option
                    v-for="item in purchaseList"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收款方" prop="shroffAccountId">
                <el-select
                  v-model="form.shroffAccountId"
                  style="overflow: hidden"
                >
                  <el-option
                    v-for="item in payeeOptions"
                    :key="item.shroffAccountId"
                    style="width: 500px"
                    :label="
                      item.payee +
                      ' / ' +
                      item.bankDeposit +
                      ' / ' +
                      item.bankAccount
                    "
                    :value="item.shroffAccountId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 头部表格 -->
          <el-table
            class="topTable"
            :data="topTableData"
            border
            style="width: 100%"
          >
            <el-table-column prop="shoukuanfangshi" align="center">
              <template slot="header">
                费用类型
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <template slot="content">
                    <p>国内物流费：包含国内的快递、货拉拉、国内货运费用</p>
                    <p class="mt5">人工费：包含临时工的打包费、验货人工费用</p>
                    <p class="mt5">
                      手续费：包含美金收款手续费、国内美金结汇手续费
                    </p>
                  </template>
                  <i class="iconfont el-icon-question c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-form-item prop="costId">
                  <el-select
                    v-model="form.costId"
                    placeholder="请选择"
                    @change="costTypeChange"
                  >
                    <el-option
                      v-for="item in costypeOptions"
                      :key="item.financeSetupId"
                      :label="item.financeName"
                      :value="item.financeSetupId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" label="支付方式">
              <template slot-scope="scope">
                <el-form-item prop="payId">
                  <el-select
                    v-model="form.payId"
                    placeholder="请选择"
                    @change="paymentWayChange"
                  >
                    <el-option
                      v-for="item in paymentWay"
                      :key="item.code"
                      :label="item.financeName"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" label="币种">
              <template slot-scope="scope">
                <el-form-item prop="currency">
                  <el-select
                    v-model="form.currency"
                    @change="currencyChange"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in currencyOptions"
                      :key="item.financeSetupId"
                      :label="item.financeName"
                      :value="item.financeName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" label="支付金额">
              <template slot-scope="scope">
                <el-form-item prop="payMoney">
                  <el-input
                    @blur="payMoneyBlur"
                    v-model="form.payMoney"
                    :disabled="form.currency ? false : true"
                    placeholder="请输入"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column width="110" align="center" label="支付金额(￥)">
              <template slot-scope="scope">
                <span class="lineheight">{{ form.payRmbMoney }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="付款时间" width="150">
              <template slot-scope="scope">
                <el-form-item prop="payTime">
                  <el-date-picker
                    style="width: 100%"
                    size="small"
                    value-format="yyyy-MM-dd"
                    v-model="form.payTime"
                    type="date"
                    placeholder="年/月/日"
                  ></el-date-picker>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" label="费用阶段">
              <template slot-scope="scope">
                <el-form-item prop="costStage">
                  <el-select
                    v-model="form.costStage"
                    @change="costStageChange"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in costStage"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <!-- 中部表格 -->
          <el-table
            v-if="middleTableData[0].orderCode"
            class="middleTable"
            :data="middleTableData"
            border
            style="width: 100%"
          >
            <el-table-column prop="orderCode" align="center" label="销售订单号">
              <template slot-scope="scope">
                <router-link
                  :to="`/order/orderList/orderDetail?orderCode=${scope.row.orderCode}`"
                >
                  {{ scope.row.orderCode }}
                </router-link>
                <!-- <span class="blue-text">
                {{ scope.row.orderCode }}
                </span>-->
              </template>
            </el-table-column>
            <el-table-column
              prop="businessName"
              align="center"
              label="客户代表"
            ></el-table-column>
            <el-table-column
              prop="area"
              align="center"
              label="所属地区"
            ></el-table-column>
            <el-table-column
              prop="currency"
              align="center"
              label="币种"
            ></el-table-column>
            <el-table-column
              prop="totlePrice"
              align="center"
              label="订单总金额"
            >
              <template slot-scope="scope">
                <span style="color: #008052">
                  {{ scope.row.totlePrice | orderMoneyFormat }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="已收金额">
              <template slot-scope="scope">
                <span style="color: #008052">
                  {{ scope.row.amountReceived | orderMoneyFormat }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="7" align="center" label="未收金额">
              <template slot-scope="scope">
                <span style="color: #ff0000">
                  {{ scope.row.outstandingAmount | orderMoneyFormat }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100">
              <template slot-scope="scope">
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="delOrderCode()"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-form-item
            label-width="80px"
            label="备注"
            style="width: 50%; display: inline-block"
            class="label"
            prop="remark"
          >
            <el-input
              placeholder="请输入500个字符以内"
              rows="3"
              resize="none"
              style="width: 90%"
              class="light-blue-theme"
              v-model="form.remark"
              size="small"
              type="textarea"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            class="upload"
            label-width="80px"
            label="凭证/附件"
            style="width: 50%; display: inline-block"
          >
            <ErpUpload
              :uploadParams="uploadParamsCertif2"
              @uploadList="uploadCertifFileList"
            ></ErpUpload>
          </el-form-item>
        </el-form>
      </div>
      <el-row slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </el-dialog>
    <!-- 审核选择 -->
    <AuditChoose
      ref="AuditChoose"
      @auditData="saveClick"
      v-if="dialogFormVisible"
    />
  </div>
</template>

<script>
  import {
    listShorffAccount,
    getOrderByOrderCode,
    conversionExchangeRate,
    insertPO,
    listPurchaseNumberBySales,
    listSalesNumberByPurchase,
  } from '@/api/finance/finance-cost'
  import AuditChoose from '@/components/AuditChoose'
  export default {
    name: 'CreditNoteAddModel',
    components: { AuditChoose },
    data() {
      let validate = (rule, value, callback) => {
        if (!value != '') {
          callback(new Error('请输入'))
        } else {
          if (isNaN(value)) {
            callback(new Error('请填入数字'))
          } else if (!/(^[1-9]\d*$)/.test(value)) {
            callback(new Error('请输入正数'))
          } else if (!/^\d+(\.\d{1,3})?$/.test(value)) {
            callback(new Error('小数后最多留三位'))
          } else {
            callback()
          }
        }
      }
      return {
        uploadParamsCertif2: {
          size: 1024 * 1024 * 20, //单个文件上传大小
          format: 'pdf、word、excel、txt、jpg、png、bmp、gif、rar、zip', //支持格式
        },
        purchaseList: [], // 采购单号列表
        orderCodeList: [], //销售订单列表
        paramsData: {},
        auditData: {},
        dialogFormVisible: false,
        form: {
          //
          orderCode: '', // 销售订单号
          purchaseCode: '', // 采购订单号
          shroffAccountId: '', // 收款方id
          costId: '', //费用类型ID
          costName: '', // 费用名称
          payId: '', // 支付方式id
          payName: '', // 支付方式
          payMoney: '', // 支付金额
          payRmbMoney: '', // 人名币支付金额
          currency: '', //币种
          payTime: '', //付款时间
          costStage: '', // 费用阶段
          remark: '', // 备注
          financeUrl: '', // 凭证/附件
        },
        topTableData: [{}],
        middleTableData: [{}],
        middleForm: {},
        // 费用类型
        costypeOptions: [],
        // 币种
        currencyOptions: [],
        // 支付方式
        paymentWay: [],
        // 费用阶段
        costStage: [
          {
            value: 1,
            label: '出库前',
          },
          {
            value: 2,
            label: '出库后',
          },
        ],
        // 收款方下拉数据
        payeeOptions: [],
        rules: {
          payMoney: [{ required: true, validator: validate, trigger: 'blur' }],
          payId: [{ required: true, message: '请选择', trigger: 'change' }],
          currency: [{ required: true, message: '请选择', trigger: 'change' }],
          payTime: [{ required: true, message: '请选择', trigger: 'change' }],
          costStage: [{ required: true, message: '请选择', trigger: 'change' }],
          costId: [{ required: true, message: '请选择', trigger: 'change' }],
          shroffAccountId: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          purchaseCode: [
            { required: false, message: '请输入', trigger: 'blur' },
          ],
          remark: [{ max: 500, message: '最多500个字符', trigger: 'blur' }],
        },
      }
    },
    created() {
      // 支付方式
      this.getPaymentWay()
      // 币种
      this.getCurrency()
      // 费用类型
      this.getCostType()
      // 收款人
      this.getPayeeOptions()
    },
    methods: {
      // 支付方式
      async getPaymentWay() {
        let response = await this.pubApi.financeSelect({ financeType: 3 })

        this.paymentWay = response?.data
      },
      // 货币
      async getCurrency() {
        let response = await this.pubApi.financeSelect({ financeType: 1 })

        this.currencyOptions = response?.data
      },
      // 费用类型
      async getCostType() {
        let response = await this.pubApi.financeSelect({ financeType: 5 })

        this.costypeOptions = response?.data
      },
      // 收款人
      async getPayeeOptions() {
        let response = await listShorffAccount({ cusName: '' })

        this.payeeOptions = response?.data
      },

      // 销售订单的Blur事件
      async orderCodeBlur(val) {
        let response = await getOrderByOrderCode({
          orderCode: this.form.orderCode,
        })
        if (response.code == '000000') {
          this.$set(this.middleTableData, 0, response.data)
        } else {
          this.$set(this.middleTableData, 0, {})
          this.form.purchaseCode = ''
          this.purchaseList = []
        }

        //根据销售订单号查采购订单
        let response1 = await listPurchaseNumberBySales({
          salesNumber: this.form.orderCode,
        })
        this.purchaseList = response1.data
      },

      //销售订单下拉查询
      async orderCodeChangeOther() {
        if (!this.form.orderCode) {
          this.form.orderCode = ''
          this.orderCodeList = []
          return
        }
        this.orderCodeBlur()
      },

      // 选择费用
      costStageChange(val) {
        if (val == 1) {
          this.$refs.form.rules.purchaseCode[0].required = true
        } else {
          this.$refs.form.rules.purchaseCode[0].required = false
        }
      },

      //销售订单号清空
      orderCodeChange() {
        if (this.form.orderCode === '') {
          this.middleTableData[0].orderCode = ''
          this.form.orderCode = ''
          this.orderCodeList = []
        }
      },

      //销售订单号为空
      searchInput(val) {
        if (val === '') {
          this.form.purchaseCode = ''
          this.purchaseList = []
          this.form.orderCode = ''
          this.orderCodeList = []
        }
      },
      //采购订单号为空
      searchInput2(val) {
        if (val === '') {
          this.form.orderCode = ''
          this.orderCodeList = []
          this.form.purchaseCode = ''
          this.purchaseList = []
        }
      },

      //根据采购订单查询销售订单
      async purchaseCodeBlur() {
        this.form.orderCode = ''
        this.orderCodeList = []
        let response = await listSalesNumberByPurchase({
          purchaseNumber: this.form.purchaseCode,
        })
        this.orderCodeList = response.data
      },

      //采购订单下拉查询
      async purchaseCodeChange() {
        if (!this.form.purchaseCode) {
          this.form.purchaseCode = ''
          this.purchaseList = []
          return
        }
        let response = await getOrderByOrderCode({
          orderCode: this.form.orderCode,
        })
        if (response.code == '000000') {
          this.$set(this.middleTableData, 0, response.data)
        }
      },

      // 支付方式的选择
      paymentWayChange(val) {
        this.paymentWay.forEach((item) => {
          if (item.code == val) {
            this.form.payName = item.financeName
          }
        })
      },
      // 支付金额的blur事件
      payMoneyBlur(val) {
        this.$refs.form.validateField('payMoney', async (valid) => {
          if (!valid) {
            let response = await conversionExchangeRate({
              currency: this.form.currency,
              payMoney: val.target.value,
            })
            this.$set(this.form, 'payRmbMoney', response.data)
          } else {
            return false
          }
        })
      },
      // 币种选择清空金额
      currencyChange() {
        this.form.payMoney = ''
        this.form.payRmbMoney = ''
      },
      // 费用类型的选择
      costTypeChange(val) {
        this.costypeOptions.forEach((item) => {
          if (item.financeSetupId == val) {
            this.form.costName = item.financeName
          }
        })
      },
      // 文件上传
      uploadCertifFileList(fileList) {
        this.form.financeUrl = JSON.stringify(fileList)
      },
      showAdd() {
        this.dialogFormVisible = true
      },
      close() {
        this.dialogFormVisible = false
        this.$refs.form.resetFields()
        this.form.payRmbMoney = ''
        this.form.orderCode = ''
        this.form.purchaseCode = ''
        this.middleTableData = [{}]
        this.purchaseList = []
      },

      //删除销售订单
      delOrderCode() {
        this.middleTableData[0].orderCode = ''
        this.form.orderCode = ''
      },
      save() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.form.orderCode != '' || this.form.purchaseCode != '') {
              this.$refs['AuditChoose'].showAddEdit(this.paramsData)
            } else {
              this.$baseMessage(
                '销售订单号和采购订单号至少选填其一',
                'error',
                false,
                'erp-hey-message-error'
              )
            }
          } else {
            return false
          }
        })
      },
      async saveClick(data) {
        this.$set(this.form, 'auditObjectDTO', data)
        let params = {
          params: JSON.stringify(this.form),
        }
        let response = await insertPO(params)
        if (response.code == '000000') {
          this.$message.success('新增成功')
          this.close()
          this.$refs['AuditChoose'].close()
          this.$emit('getList')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-table .el-form-item__content {
      margin-left: 0 !important;
    }

    .topTable.el-table .el-table__row td {
      padding-bottom: 0;
    }

    .el-table .el-form-item__error {
      top: 106%;
    }

    .middleTable {
      margin-top: 10px;
    }

    .label label {
      margin: 10px 0;
      text-align: left !important;
    }

    .label .el-form-item__content {
      margin-left: 0 !important;
    }

    .el-dialog__footer {
      text-align: center;
      padding-top: 0;
    }

    .upload {
      label {
        width: 100% !important;
        text-align: left;
      }

      .el-form-item__content {
        margin-left: 0 !important;
      }
    }

    .el-table.topTable th .cell::before {
      content: '*';
      color: red;
      position: relative;
      top: 0;
      left: -6px;
    }

    .el-table.topTable th:nth-child(5) .cell::before {
      content: '';
    }
  }

  .lineheight {
    position: relative;
    top: -5px;
  }
</style>
