var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"configuration-list"},[_c('el-form',{key:_vm.formKey,ref:"configurationListForm",attrs:{"model":_vm.configurationListForm}},[_c('el-table',{staticStyle:{"width":"982px"},attrs:{"data":_vm.configurationListForm.tableData,"border":""}},[_c('el-table-column',{attrs:{"align":"center","prop":"email","label":_vm.$t('mailConfig.SendToPrimaryEmail'),"width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.email',"rules":[
              {
                required: true,
                message: _vm.$t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                validator: _vm.$formValidation.isEmail,
                trigger: 'blur',
              } ]}},[(scope.row.disabled)?_c('span',[_vm._v(_vm._s(scope.row.email))]):_c('el-input',{attrs:{"clearable":""},model:{value:(scope.row.email),callback:function ($$v) {_vm.$set(scope.row, "email", $$v)},expression:"scope.row.email"}})],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"defaultEmail","label":_vm.$t('mailConfig.DefaultCCEmail'),"width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.defaultEmail',"rules":[
              {
                required: true,
                message: _vm.$t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                validator: _vm.$formValidation.isEmail,
                trigger: 'blur',
              } ]}},[(scope.row.disabled)?_c('span',[_vm._v(" "+_vm._s(scope.row.defaultEmail)+" ")]):_c('el-input',{attrs:{"clearable":""},model:{value:(scope.row.defaultEmail),callback:function ($$v) {_vm.$set(scope.row, "defaultEmail", $$v)},expression:"scope.row.defaultEmail"}})],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"effect","label":_vm.$t('mailConfig.Function'),"width":"380"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.effect',"rules":[
              {
                required: true,
                message: _vm.$t('placeholder.plsSel'),
                trigger: 'change',
              } ]}},[_c('el-checkbox-group',{model:{value:(scope.row.effect),callback:function ($$v) {_vm.$set(scope.row, "effect", $$v)},expression:"scope.row.effect"}},[_c('el-checkbox',{attrs:{"label":"sended"}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Compose'))+" ")]),_c('el-checkbox',{attrs:{"label":"replied"}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.reply'))+" ")]),_c('el-checkbox',{attrs:{"label":"forwarded"}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.forword'))+" ")])],1)],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('mailConfig.Action'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text"},on:{"click":function($event){return _vm.removeFn(scope.$index)}}},[_c('i',{staticClass:"el-icon-delete"})])]}}])})],1)],1),_c('div',{staticClass:"btn-box"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.createNewLineFn}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.CreateNewLine'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }