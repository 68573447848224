<template>
  <div class="track-node">
    <div v-if="nodeDTO.length">
      <div class="node" v-for="(item, i) in nodeDTO" :key="i">
        <div class="L">
          <div class="el-step__icon">
            <i>{{ i + 1 }}</i>
          </div>
          <div
            class="oper"
            v-show="!nodeDisabled && ![2, 3].includes(item.status)"
          >
            <i
              class="el-icon-plus mr5"
              @click="add(i)"
              v-allowed="['TRACK:NEW_NODE']"
            ></i>
            <i
              class="el-icon-delete red"
              @click="del(i)"
              v-allowed="['TRACK:DEL_NODE']"
            ></i>
          </div>
        </div>
        <div class="R">
          <!-- 待安排 -->
          <div v-if="item.status === 1" class="state arrange">
            {{ $t('Documentary.TBA') }}
          </div>
          <!-- 已完成 -->
          <div v-else-if="item.status === 2" class="state success">
            {{ $t('Documentary.completed') }}
          </div>
          <!-- 延期 -->
          <div v-else-if="item.status === 3" class="state dange">
            {{ $t('Documentary.postpone') }}
          </div>
          <div class="tips red f_s_12" v-if="i > 0">
            <p class="mb5" v-if="item.nodeType === 'QC'">
              {{ $t('Documentary.TTCNA') }}
            </p>
            <p
              class="mb5"
              v-else-if="
                ['LOGISTICS_FREIGHT', 'THE_CUSTOMER_TO_SIGN_FOR'].includes(
                  item.nodeType
                )
              "
            >
              {{ $t('Documentary.TTCNI') }}
            </p>
            <p v-else>
              {{ $t('Documentary.TATCN') }}
            </p>
            <!-- <el-checkbox
              v-model="nodeDTO[i].deferred"
              @change="deferredChange(i)"
              :disabled="
                !(nodeDTO[i].dueDate || nodeDTO[i].realDate) ||
                !dateDisableed(nodeDTO[i].dueDate, nodeDTO[i].realDate) ||
                nodeDisabled ||
                item.deferred === 1 ||
                item.status === 2 ||
                item.status === 3
              "
            >
              延期
            </el-checkbox> -->
          </div>
          <el-descriptions
            class="margin-top"
            labelClassName="label-node"
            :column="4"
            size="medium"
            border
          >
            <el-descriptions-item :span="4" v-if="lang === 'en'">
              <template slot="label">
                <i class="red">*</i>
                <!-- 节点名称 -->
                {{ $t('Documentary.NN') }}
              </template>
              <el-form-item
                :prop="'nodeDTO.' + i + '.nodeNameEn'"
                :rules="[
                  {
                    required: true,
                    message: $t('Documentary.PE'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-if="lang === 'en'"
                  :disabled="
                    nodeDTO[i].nodeTemplateNum === 1 ||
                    item.status === 2 ||
                    item.status === 3 ||
                    nodeDisabled
                  "
                  v-model.trim="nodeDTO[i].nodeNameEn"
                  @blur="nodeNameBlur(nodeDTO[i].nodeNameEn, i)"
                  :placeholder="$t('Documentary.PE')"
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item :span="4" v-else>
              <template slot="label">
                <i class="red">*</i>
                <!-- 节点名称 -->
                {{ $t('Documentary.NN') }}
              </template>
              <el-form-item
                :prop="'nodeDTO.' + i + '.nodeNameCn'"
                :rules="[
                  {
                    required: true,
                    message: $t('Documentary.PE'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :disabled="
                    nodeDTO[i].nodeTemplateNum === 1 ||
                    item.status === 2 ||
                    item.status === 3 ||
                    nodeDisabled
                  "
                  v-model.trim="nodeDTO[i].nodeNameCn"
                  @blur="nodeNameBlur(nodeDTO[i].nodeNameCn, i)"
                  :placeholder="$t('Documentary.PE')"
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <!-- QC质检 -->
            <template v-if="nodeDTO[i].nodeType === 'QC'">
              <el-descriptions-item :span="2">
                <template slot="label">
                  <i class="red">*</i>
                  <!-- 预计日期 -->
                  {{ $t('Documentary.ESD') }}
                </template>
                <el-form-item
                  :prop="'nodeDTO.' + i + '.dueDate'"
                  :rules="[
                    {
                      required: true,
                      message: $t('Documentary.PE'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="nodeDTO[i].dueDate"
                    :disabled="
                      nodeDisabled ||
                      [2, 3].includes(item.status) ||
                      item.disabled
                    "
                    @change="dueDateChange(i)"
                    :placeholder="$t('Documentary.PC')"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd hh:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <!-- 实际日期 -->
                <template slot="label">{{ $t('Documentary.ACD') }}</template>
                <el-form-item class="item-style">
                  <el-date-picker
                    v-model="nodeDTO[i].realDate"
                    :disabled="true"
                    :placeholder="$t('Documentary.PC')"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd hh:mm:ss"
                    clearable
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="4" v-if="nodeDTO[i].deferred">
                <!-- 延期天数 -->
                <template slot="label">{{ $t('Documentary.DED') }}</template>
                <el-form-item>
                  <el-input v-model="nodeDTO[i].deferredDays" :disabled="true">
                    <!-- 天 -->
                    <template slot="append">
                      {{ $t('Documentary.day') }}
                    </template>
                  </el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <!-- 验货结果 -->
                <template slot="label">{{ $t('Documentary.IR') }}</template>
                <el-form-item>
                  <!-- <el-input
                    v-model="nodeDTO[i].inspectionResult"
                    :disabled="
                      nodeDisabled || item.status === 2 || item.status === 3
                    "
                    :placeholder="$t('Documentary.PE')"
                    maxlength="50"
                    clearable
                  ></el-input> -->
                  {{ nodeDTO[i].inspectionResult | IRTransl }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <!-- 验货附件 -->
                <template slot="label">{{ $t('Documentary.IA') }}</template>
                <div v-if="nodeDTO[i].inspectionFileAnnex">
                  <div
                    class="flie-list"
                    v-for="(item, i) in JSON.parse(
                      nodeDTO[i].inspectionFileAnnex
                    )"
                    :key="i"
                  >
                    <i class="el-icon-document"></i>
                    <a class="page-link ml5">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.name"
                        placement="top-start"
                      >
                        <span class="ell">
                          <router-link
                            :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                            target="_blank"
                          >
                            {{ item.name }}
                          </router-link>
                        </span>
                      </el-tooltip>
                    </a>
                    <span class="size ml10">
                      {{ utils.calculateFileSize(item.size) }}
                    </span>
                    <span class="ml10">{{ item.time }}</span>
                  </div>
                </div>
              </el-descriptions-item>
            </template>
            <!-- 物流货运 -->
            <template v-else-if="nodeDTO[i].nodeType === 'LOGISTICS_FREIGHT'">
              <el-descriptions-item :span="2">
                <template slot="label">
                  <i class="red">*</i>
                  <!-- 预计日期 -->
                  {{ $t('Documentary.ESD') }}
                </template>
                <el-form-item
                  :prop="'nodeDTO.' + i + '.dueDate'"
                  :rules="[
                    {
                      required: true,
                      message: $t('Documentary.PE'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="nodeDTO[i].dueDate"
                    :disabled="
                      nodeDisabled ||
                      [2, 3].includes(item.status) ||
                      item.disabled
                    "
                    @change="dueDateChange(i)"
                    :placeholder="$t('Documentary.PC')"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd hh:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <!-- 实际日期 -->
                <template slot="label">{{ $t('Documentary.ACD') }}</template>
                <el-form-item class="item-style">
                  <el-date-picker
                    v-model="nodeDTO[i].realDate"
                    :disabled="true"
                    :placeholder="$t('Documentary.PC')"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd hh:mm:ss"
                    clearable
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="4" v-if="nodeDTO[i].deferred">
                <!-- 延期天数 -->
                <template slot="label">{{ $t('Documentary.DED') }}</template>
                <el-form-item>
                  <el-input v-model="nodeDTO[i].deferredDays" :disabled="true">
                    <!-- 天 -->
                    <template slot="append">
                      {{ $t('Documentary.day') }}
                    </template>
                  </el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="4">
                <!-- 物流单号 -->
                <template slot="label">{{ $t('Documentary.TN') }}</template>
                <el-form-item>
                  <el-input
                    v-model="nodeDTO[i].shipNo"
                    :disabled="
                      nodeDisabled || item.status === 2 || item.status === 3
                    "
                    :placeholder="$t('Documentary.PE')"
                    maxlength="50"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
            </template>
            <!-- 客户签收 -->
            <template
              v-else-if="nodeDTO[i].nodeType === 'THE_CUSTOMER_TO_SIGN_FOR'"
            >
              <el-descriptions-item :span="2">
                <template slot="label">
                  <i class="red">*</i>
                  <!-- 预计日期 -->
                  {{ $t('Documentary.ESD') }}
                </template>
                <el-form-item
                  :prop="'nodeDTO.' + i + '.dueDate'"
                  :rules="[
                    {
                      required: true,
                      message: $t('Documentary.PE'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="nodeDTO[i].dueDate"
                    :disabled="
                      nodeDisabled ||
                      [2, 3].includes(item.status) ||
                      item.disabled
                    "
                    @change="dueDateChange(i)"
                    :placeholder="$t('Documentary.PC')"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd hh:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <!-- 实际日期 -->
                <template slot="label">{{ $t('Documentary.ACD') }}</template>
                <el-form-item class="item-style">
                  <el-date-picker
                    v-model="nodeDTO[i].realDate"
                    :disabled="true"
                    :placeholder="$t('Documentary.PC')"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd hh:mm:ss"
                    clearable
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="4" v-if="nodeDTO[i].deferred">
                <!-- 延期天数 -->
                <template slot="label">{{ $t('Documentary.DED') }}</template>
                <el-form-item>
                  <el-input v-model="nodeDTO[i].deferredDays" :disabled="true">
                    <!-- 天 -->
                    <template slot="append">
                      {{ $t('Documentary.day') }}
                    </template>
                  </el-input>
                </el-form-item>
              </el-descriptions-item>
            </template>
            <!-- 其他 -->
            <template v-else>
              <el-descriptions-item :span="2">
                <template slot="label">
                  <i class="red">*</i>
                  <!-- 预计日期 -->
                  {{ $t('Documentary.ESD') }}
                </template>
                <el-form-item
                  :prop="'nodeDTO.' + i + '.dueDate'"
                  :rules="[
                    {
                      required: true,
                      message: $t('Documentary.PE'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="nodeDTO[i].dueDate"
                    :disabled="
                      nodeDisabled ||
                      [2, 3].includes(item.status) ||
                      item.disabled
                    "
                    @change="dueDateChange(i)"
                    :placeholder="$t('Documentary.PC')"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd hh:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <template slot="label">
                  <i class="red" v-show="realDateRequired(i)">*</i>
                  <!-- 实际日期 -->
                  {{ $t('Documentary.ACD') }}
                </template>
                <el-form-item
                  :prop="'nodeDTO.' + i + '.realDate'"
                  :rules="[
                    {
                      required: realDateRequired(i),
                      message: $t('Documentary.PC'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-date-picker
                    :disabled="
                      (i > 0 ? realDateDisabeld(i) : false) ||
                      !nodeDTO[i].dueDate ||
                      nodeDisabled ||
                      item.disabled
                    "
                    v-model="nodeDTO[i].realDate"
                    @change="realDateChange(i)"
                    :placeholder="$t('Documentary.PC')"
                    type="date"
                    value-format="yyyy-MM-dd hh:mm:ss"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="4" v-if="nodeDTO[i].deferred">
                <!-- 延期天数 -->
                <template slot="label">{{ $t('Documentary.DED') }}</template>
                <el-form-item>
                  <el-input v-model="nodeDTO[i].deferredDays" :disabled="true">
                    <!-- 天 -->
                    <template slot="append">
                      {{ $t('Documentary.day') }}
                    </template>
                  </el-input>
                </el-form-item>
              </el-descriptions-item>
            </template>

            <el-descriptions-item :span="4">
              <!-- 备注 -->
              <template slot="label">{{ $t('Documentary.remark') }}</template>
              <el-input
                v-model="nodeDTO[i].remark"
                :disabled="nodeDisabled || item.disabled"
                type="textarea"
                :placeholder="$t('Documentary.PE')"
                :rows="3"
                :maxlength="200"
                show-word-limit
              ></el-input>
            </el-descriptions-item>
            <el-descriptions-item :span="4">
              <!-- 附件 -->
              <template slot="label">{{ $t('Documentary.appendix') }}</template>
              <div v-if="isDetail">
                <div v-if="nodeDTO[i].enclosure ? true : false">
                  <div
                    class="flie-list"
                    v-for="(item, i) in JSON.parse(nodeDTO[i].enclosure)"
                    :key="i"
                  >
                    <i class="el-icon-document"></i>
                    <a class="page-link ml5">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.name"
                        placement="top-start"
                      >
                        <span class="ell">
                          <router-link
                            class="file_name"
                            :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                            target="_blank"
                          >
                            {{ item.name }}
                          </router-link>
                        </span>
                      </el-tooltip>
                    </a>
                    <span class="size ml10">
                      {{ utils.calculateFileSize(item.size) }}
                    </span>
                    <span class="ml10">{{ item.time }}</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <!-- <file-upload
                  :ref="'enclosureRefs' + i"
                  :index="i"
                  @uploadList="multiUploadList"
                  v-show="!nodeDisabled || !item.disabled"
                  :enclosure="nodeDTO[i].enclosure"
                ></file-upload> -->
                <FileUpload v-show="!nodeDisabled || !item.disabled" v-model="nodeDTO[i].enclosure" />
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <p class="mt10 text-right">
            <span v-show="nodeStatus != 'add'">
              <!-- 时间更新人 -->
              {{ $t('Documentary.TU') }}：{{
                lang === 'en' ? item.timeUpdatorEn : item.timeUpdator
              }}
            </span>
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- 暂无节点 -->
      <el-empty :description="$t('Documentary.NNY')"></el-empty>
    </div>

    <!-- 新增节点 -->
    <el-dialog
      :title="$t('Documentary.NEN')"
      width="460px"
      :visible.sync="dialogVisible"
      :before-close="close"
      placement="bottom"
    >
      <p class="red f_s_12">
        {{ $t('Documentary.TIIRT') }}：工厂生产(Factory Production)
      </p>
      <p class="text-center mt20">
        <!-- 在此节点之前增加 -->
        {{ $t('Documentary.IBTN') }}
        <span class="blue ml10 c_pointer" @click="add('before')">
          <!-- 新增 -->
          {{ $t('Documentary.add') }}
        </span>
      </p>
      <p class="mt10 text-center">
        <!-- 在此节点之后增加 -->
        {{ $t('Documentary.IATN') }}
        <span class="blue ml10 c_pointer" @click="add('after')">
          <!-- 新增 -->
          {{ $t('Documentary.add') }}
        </span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">
          <!-- 取消 -->
          {{ $t('Documentary.Cancel') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
let self = null
// import fileUpload from '../components/fileUpload.vue'
import FileUpload from '@/components/FileUpload'
import { inspectionResultTransl } from '@/utils/translate.js'
import { mapGetters } from 'vuex'
export default {
  components: { FileUpload },
  name: 'track-node',
  props: {
    nodeDTO: {
      type: Array,
      default: () => [],
    },
    deleteNodeDTO: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Array,
      default: () => [],
    },
    nodeDisabled: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: '',
    },
    nodeStatus: {
      type: String,
      default: '',
    },
    purchaseOrderNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    self = this
    return {
      nodeType: '', // 节点单号
      nodeIndex: '', // 节点索引
      nodeTitle: '', // 节点名称
      nodeTable: [], // 节点数据
      /**
       * 新增节点
       */
      node: {
        creator: '', // 创建人
        creatorEn: '', // 创建人英文
        deferred: '', // 是否延期（0：未延期，1：延期）
        deferredDays: '', // 延期天数
        dueDate: '', // 预计日期
        realDate: '', // 实际日期
        enclosure: '', // 	附件
        nodeNameCn: '', // 	节点名称
        nodeNameEn: '', // 节点名称（英文）
        nodeType: '', // 节点类型
        orderTrackingId: '', // 	跟踪单ID
        remark: '', // 	备注
        status: '', // 状态(1：待安排，2：已完成；3：延期)
        tenantId: '', // 租户
        warningDischarge: '', // 预警解除标记（0：未操作，1：已解除）
      },
      dialogVisible: false,
      nodeVisible: false,
      specNodes: ['QC', 'LOGISTICS_FREIGHT', 'THE_CUSTOMER_TO_SIGN_FOR'], // 特殊节点
      /**
       * 特殊节点
       */
      currentRow: '',
      currentRowObj: {},
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      lang: 'settings/language',
    }),
  },
  filters: {
    IRTransl(n) {
      return self.lang === 'en' ? inspectionResultTransl(n) : n
    },
  },
  methods: {
    // 计算延期天数
    deferredDaysCalc(i) {
      const days = Math.abs(
        parseInt(
          (new Date(this.nodeDTO[i].realDate).getTime() -
            new Date(this.nodeDTO[i].dueDate).getTime()) /
            (1000 * 60 * 60 * 24)
        )
      )
      this.nodeDTO[i].deferredDays = days
    },

    // 实际日期是否大于预计日期
    dateDisableed(time1, time2) {
      return new Date(time2).getTime() - new Date(time1).getTime() > 0
    },

    // 判断更新实际日期是否必填
    realDateRequired(i) {
      const node = this.nodeDTO[i]
      if (
        this.nodeStatus === 'update' &&
        (node.status === 2 || node.status === 3)
      ) {
        return true
      } else {
        return false
      }
    },

    // 预计日期change事件
    dueDateChange(i) {
      if (!this.nodeDTO[i].dueDate) {
        this.nodeDTO[i].realDate = ''
        this.nodeDTO[i].deferred = 0
        this.nodeDTO[i].deferredDays = 0
      }
    },

    // 实际日期change事件
    realDateChange(i) {
      // boo:true 表示延期
      const boo = this.dateDisableed(
        this.nodeDTO[i].dueDate,
        this.nodeDTO[i].realDate
      )
      if (!boo) {
        this.nodeDTO[i].deferred = 0
        this.nodeDTO[i].deferredDays = 0
      } else {
        this.nodeDTO[i].deferred = 1
        this.deferredDaysCalc(i)
      }
    },

    // 普通节点上一个实际日期填写当前实际日期才可以填写
    realDateDisabeld(i) {
      if (i === 0) {
        return false
      } else {
        if (this.specNodes.includes(this.nodeDTO[i - 1].nodeType)) {
          return this.realDateDisabeld(i - 1)
        } else {
          if (this.nodeDTO[i - 1].realDate) {
            return false
          } else {
            return true
          }
        }
      }
    },

    // 节点名称不能重复
    nodeNameBlur(name, i) {
      let arr = []

      this.nodeDTO.forEach((item) => arr.push(item.nodeNameCn))
      arr.splice(i, 1)
      if (arr.includes(name)) {
        this.nodeDTO[i].nodeNameCn = ''
        this.$message.warning(this.$t('Documentary.NNCBR')) // 节点名称不能重复
      }
    },

    // 特殊节点选择
    getCurrentRow(i) {
      this.currentRow = i
      this.currentRowObj = this.nodeTable[i]
    },

    // 新增
    add(type) {
      if (typeof type === 'number') {
        this.nodeIndex = type
        this.dialogVisible = true
      } else {
        if (type === 'before') {
          this.nodeDTO.splice(this.nodeIndex, 0, Object.assign({}, this.node))
        } else {
          this.nodeDTO.splice(
            this.nodeIndex + 1,
            0,
            Object.assign({}, this.node)
          )
        }
        this.close()
        this.initEnclosure()
      }
    },

    // 删除
    del(i) {
      this.$confirm(
        `${this.lang === 'en' ? 'whether to delete the' : '是否删除第'}${
          i + 1
        }${this.lang === 'en' ? 'node' : '节点'}?`,
        this.$t('Documentary.hint'), // 提示
        {
          type: 'warning',
        }
      ).then(() => {
        // 删除节点只保存配置的跟单节点
        if (
          this.nodeDTO[i].orderTrackingNodeId &&
          this.nodeStatus === 'update'
        ) {
          // 直接传deferred会导致后台类型错误
          delete this.nodeDTO[i].deferred
          this.deleteNodeDTO.push(this.nodeDTO[i])
        }
        this.nodeDTO.splice(i, 1)
        this.$message.info(this.$t('Documentary.deleted'))
        this.initEnclosure()
      })
    },

    // 附件上传
    // multiUploadList(fileList, index) {
    //   this.nodeDTO[index].enclosure = JSON.stringify(fileList)
    // },

    // 附件回显
    initEnclosure() {
      console.log('>>>附件回显',JSON.parse(JSON.stringify(this.nodeDTO)))
      this.nodeDTO.forEach((item, i) => {
        if (
          this.$refs['enclosureRefs' + i] &&
          this.$refs['enclosureRefs' + i].length
        ) {
          this.$refs['enclosureRefs' + i][0].initUpload(item.enclosure)
        }
      })
    },

    // 关闭
    close() {
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.track-node {
  padding-left: 50px;
  .node {
    display: flex;
    .L {
      width: 40px;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      .el-step__icon {
        width: 28px;
        height: 28px;
        font-size: 16px;
        background: #fff;
        color: #409eff;
        border-radius: 50%;
        border: 2px solid;
        margin-top: 10px;
        i {
          display: inline-block;
          text-align: center;
          font-weight: 600;
          line-height: 1;
        }
      }
      .oper {
        margin-top: 5px;
        i {
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
    .R {
      padding: 0 0 10px 20px;
      width: 1000px;
      position: relative;
      .state {
        position: absolute;
        top: 35px;
        right: -120px;
        font-size: 24px;
        font-weight: 600;
        transform: rotate(30deg);
        text-shadow: 3px 3px 3px #ddd;
        z-index: 1;
      }
      .state.arrange {
        color: #aaa;
      }
      .state.success {
        color: #67c23a;
      }
      .state.dange {
        color: #f56c6c;
      }
      .tips {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .file_name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.el-form-item {
  margin: 0;
}
::v-deep {
  .el-input-group__append {
    background: none;
    padding: 0 10px;
  }
  .radio-style {
    color: #fff;
    margin-left: 15px;
    .el-radio__label {
      margin-left: 30px;
    }
  }
}
</style>
