<template>
  <div class="role-container">
    <el-button icon="el-icon-plus" type="primary" @click="addUser" v-show="divideGroupBol" v-allowed="['Power:Edit']">
      添加成员
    </el-button>
    <el-row class="table-row">
      <el-table :data="tableData" class="mt20 w100 table" border>
        <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
        <el-table-column align="center" prop="telephone" label="联系电话">
          <template slot-scope="scope">
            <span>{{ scope.row.telephone || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="area" label="所属地区">
          <template slot-scope="scope">
            <span>{{ scope.row.area || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="deptName" label="所属部门">
          <template slot-scope="scope">
            {{ scope.row.deptName || '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="postType" label="职位">
          <template slot-scope="scope">
            <span>{{ matchPosition(scope.row.postType) || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right" :resizable="false">
          <template slot-scope="scope">
            <!-- administratorAccount 1是 0否 -->
            <el-tooltip content="删除" placement="top" v-if="scope.row.administratorAccount === 0">
              <el-button v-allowed="['Power:Edit']" type="danger" plain circle size="mini" icon="el-icon-delete"
                @click="delClick(scope.row)"></el-button>
            </el-tooltip>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <Pagination v-show="total > 0" :limit.sync="queryForm.pageLe" :page.sync="queryForm.pageNo" :total="total"
      @pagination="getListUserByRole" />

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import UserChoose from '@/components/userChoose'
  import { roleInsertPO, roleDeleteById } from '@/api/user-role'
  import { listUserByRole } from '@/api/power-group'
  import { matchPosition } from '@/utils/en-match-zh'
  export default {
    components: {
      Pagination,
      UserChoose,
    },
    name: 'RoleMember',
    props: {
      roleId: {
        type: String,
        required: true,
      },
      divideGroup: {
        type: Number,
        required: true,
      },
      superUser: {
        //超级管理员
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        tableData: [],
        queryForm: {
          pageLe: 10,
          pageNo: 1, //当前页数
          roleId: '',
        },
        total: 0,
        divideGroupBol: false,
        loading: false,
      }
    },
    watch: {
      roleId: {
        immediate: true,
        deep: true,
        handler(newvalue, oldvalue) {
          if (!newvalue) return
          this.queryForm.roleId = newvalue
          if (newvalue !== oldvalue) {
            this.queryForm.pageLe = 10
            this.queryForm.pageNo = 1
            this.total = 0
          }

          if (this.divideGroup === 0 && this.superUser !== 1) {
            this.divideGroupBol = true
            this.getListUserByRole()
          } else {
            if (this.superUser === 1) {
              this.getListUserByRole()
            }
            this.tableData = []
            this.divideGroupBol = false
          }
        },
      },
    },
    created() { },
    mounted() { },
    methods: {
      //获取成员列表
      async getListUserByRole(pageData) {
        if(pageData) {
          this.queryForm.pageNo = pageData.page
          this.queryForm.pageLe = pageData.limit
        }
        let response = await listUserByRole(this.queryForm)
        if (response.code === '000000') {
          this.tableData = response.data.data
          //将管理员过滤掉不显示

          // this.tableData = response.data.data.filter(
          //   (item) => item.administratorAccount === 0
          // )
          this.total = response.data.total
        }
        // this.loading = false
      },

      //添加成员
      addUser() {
        this.$refs['UserChoose'].showAddEdit('role', this.queryForm.roleId)
      },
      //选择的成员
      async chooseUser(userArr) {
        let userIdArr = userArr.map((item) => {
          return item.id
        })
        // 权限优化 获取authorityUserDTOS
        let authorityUserDTOS = userArr
          .filter((user) => user.pid)
          .map(({ id: userId, pid: deptId }) => ({
            userId,
            deptId,
          }))
        let response = await roleInsertPO({
          roleId: this.queryForm.roleId,
          userId: userIdArr,
          authorityUserDTOS,
        })
        if (response.code === '000000') {
          this.$baseMessage(
            '操作成功',
            'success',
            false,
            'erp-hey-message-success'
          )
          this.getListUserByRole()
        }
      },

      //删除成员
      async delClick(row) {
        this.$confirm('删除不可恢复，确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await roleDeleteById({
            roleId: this.queryForm.roleId,
            userId: row.userId,
          })
          if (response.code === '000000') {
            this.$baseMessage(
              '删除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
            this.getListUserByRole()
          }
        })
      },
      //职位匹配
      matchPosition(val) {
        return matchPosition(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  .role-container {
    height: 100%;

    .table-row {
      height: calc(100% - 84px);
      overflow-y: auto;
    }
  }
</style>
