<template>
  <div class="print-pricing">
    <p class="mt10 mb10">
      {{ $t('productDetail.PrintingMethod') }}:
      {{ lang === 'zh' ? tableTitleCn : tableTitleEn }}
    </p>
    <el-row>
      <el-col
        class="content mt20"
        v-for="(print, index) in printList"
        :key="index"
      >
        <!-- 印刷方式： -->
        <p class="content-head">
          {{ $t('productDetail.PrintingMethod') }} ：{{
            lang === 'zh' ? print.nameCn : print.nameEn
          }}
        </p>
        <el-row class="panel-item mt15" type="flex" align="middle">
          <p class="ml20">
            <label>{{ $t('productDetail.PrintingPosition') }}:</label>
            <span>
              {{
                lang === 'zh'
                  ? print.printingPositionValue
                  : print.printingPositionValueEn
              }}
            </span>
          </p>
          <p class="ml20">
            <span>
              {{ print.locationNum ? print.locationNum + '-location' : '' }}
            </span>
          </p>
          <p class="ml20">
            <label>{{ $t('productDetail.PricingMethod') }}:</label>
            <span>
              {{
                lang === 'zh'
                  ? print.valuationMethodValue
                  : print.valuationMethodValueEn
              }}
            </span>
          </p>
          <p class="ml20">
            <label>{{ $t('productDetail.PriceMargin') }}:</label>
            <span>
              {{
                lang === 'zh'
                  ? print.profitRateTemplateNameCn
                  : print.profitRateTemplateNameEn
              }}
            </span>
          </p>
          <p class="ml20" v-if="!isPlatForm">
            <label>{{$t('productDetail.ReorderDiscount')}}:</label>
            <span>
              {{print.returnDiscount}} %
            </span>
          </p>
        </el-row>
        <el-row class="panle-item-title">
          <el-col>
            <h6 class="left-h6">
              {{ $t('productDetail.FinishedProductPricing') }}
            </h6>
          </el-col>

          <el-col>
            <el-table
              :data="print.productPrintingFullValuationDTOList"
              style="width: 100%"
              ref="table"
              border
              :span-method="print.objectSpanMethod"
            >
              <el-table-column
                align="center"
                :label="$t('productDetail.PrintingPosition')"
                width="120"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.printingPositionNum }}-location</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="$t('productDetail.PricingMethod')"
                width="120"
              >
                <template slot-scope="scope">
                  <span>
                    {{
                      lang === 'zh'
                        ? scope.row.valuationMethod
                        : scope.row.valuationMethodEn
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="132">
                <template slot="header">
                  <div>
                    <p class="required-symbol">
                      {{ $t('productDetail.ProductivityPerUnitTime') }}
                    </p>
                    <div class="select-box">
                      <el-select v-model.sync="print.capacityUnit" disabled>
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="lang === 'zh' ? item.nameCn : item.nameEn"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                      <el-tooltip
                        v-if="
                          print.capacityUnit != 3 && print.capacityUnit != 4
                        "
                        class="item"
                        effect="light"
                        :content="$t('productDetail.capacityUnitTip')"
                        placement="bottom"
                      >
                        <i class="el-icon-warning-outline"></i>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  <span>{{ scope.row.capacity }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="120">
                <template slot="header">
                  <div>
                    <p class="required-symbol">
                      {{ $t('productDetail.FinishedProductCostPrice') }}
                    </p>
                    <p>（{{ $t('productDetail.CNUnit') }}）</p>
                  </div>
                </template>
                <template slot-scope="scope">
                  <span>{{ scope.row.price }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="120">
                <template slot="header">
                  <div>
                    <p class="required-symbol">
                      {{ $t('productDetail.EditionFeeOther') }}
                    </p>
                  </div>
                </template>
                <template slot-scope="scope">
                  <span>{{ scope.row.publishFee }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="$t('productDetail.QuantityLadder')"
              >
                <el-table-column
                  v-for="(item, index) in numberStepList"
                  :key="index + 1000"
                  :label="item + ''"
                  align="center"
                >
                  <el-table-column align="center" width="128">
                    <template slot="header">
                      <div>
                        <p class="required-symbol">
                          {{ $t('productDetail.ProfitRate') }}
                        </p>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      <div class="profit-rate">
                        {{ scope.row['profitRate' + index] }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    :prop="'price' + index"
                    width="128"
                  >
                    <template slot="header">
                      <div>
                        <p>{{ $t('productDetail.FinishedProduct') }}</p>
                        <p>{{ $t('productDetail.Price') }}($)</p>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="light" placement="top">
                        <div slot="content">
                          = {{ $t('productDetail.FinishedProductTips') }}
                          <br />
                          =（{{ Number(scope.row.price) }} ÷ {{ exchange }} +
                          {{ scope.row['transportHeadPrice' + index] }}）÷（1 -
                          {{
                            Number(scope.row['profitRate' + index]) > 0
                              ? scope.row['profitRate' + index] + '%'
                              : '(' + scope.row['profitRate' + index] + '%)'
                          }}
                          ）
                          <p
                            v-if="
                              scope.row['price' + index] !=
                              getMinData(
                                print.productPrintingFullValuationDTOList
                              )[index]
                            "
                            style="color: #ff4d4f"
                          >
                            Unit Price for Additional Imprint ${{
                              scope.row['price' + index]
                            }}
                            - ${{
                              getMinData(
                                print.productPrintingFullValuationDTOList
                              )[index]
                            }}
                            = ${{
                              (
                                scope.row['price' + index] -
                                getMinData(
                                  print.productPrintingFullValuationDTOList
                                )[index]
                              ).toFixed(toFixedNum)
                            }}
                          </p>
                        </div>
                        <span>
                          {{ scope.row['price' + index] }}
                          <i
                            class="el-icon-warning-outline"
                            style="color: #909399"
                          ></i>
                        </span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row class="panle-item-title">
          <el-col>
            <h6 class="left-h6">{{ $t('productDetail.LeadTime') }}</h6>
          </el-col>
          <el-col class="outnumber-panle" v-if="lang === 'zh'">
            <p>
              {{
                $t('productDetail.transportDayTip') +
                '“' +
                transportationType.nameCn +
                '”' +
                ','
              }}{{ $t('productDetail.transportDayTip1') }}
            </p>
            <p>
              <el-input v-model="print.transportDay" disabled></el-input>
            </p>
            <p>{{ $t('productDetail.WorkingDay') }};</p>
          </el-col>
          <el-col class="outnumber-panle-other" v-else>
            {{
              $t('productDetail.transportDayTip') +
              '“' +
              transportationType.nameEn +
              '”' +
              ','
            }}{{ $t('productDetail.transportDayTip1') }},{{
              $t('productDetail.transportDayTip1')
            }}
            <el-input v-model="print.transportDay" disabled></el-input>
            {{ $t('productDetail.WorkingDay') }};
          </el-col>
          <el-col class="outnumber-panle" v-if="lang === 'zh'">
            <p>{{ $t('productDetail.QuantityExceedsTpis') }}</p>
            <p>
              <el-input v-model="print.deliveryProductNum" disabled></el-input>
            </p>
            <p>{{ $t('productDetail.QuantityExceedsTpis1') }}</p>
            <p>
              <el-input
                v-model="print.deliveryDayProductNum"
                disabled
              ></el-input>
            </p>
            <p>{{ $t('productDetail.QuantityExceedsTpis2') }}</p>
          </el-col>
          <el-col class="outnumber-panle-other" v-else>
            When the quantity of products in a single order exceeds
            <el-input v-model="print.deliveryProductNum" disabled></el-input>
            , or the cumulative quantity of products in a single day exceeds
            <el-input v-model="print.deliveryDayProductNum" disabled></el-input>
            , sales representative quotes the delivery date to the customer
          </el-col>
          <el-col>
            <el-table
              :data="print.productPrintingFullValuationDTOList"
              style="width: 100%"
              :span-method="print.objectSpanMethod"
              ref="table"
              border
            >
              <el-table-column
                align="center"
                :label="$t('productDetail.PrintingPosition')"
                width="120"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.printingPositionNum }}-location</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="$t('productDetail.PricingMethod')"
                width="120"
              >
                <template slot-scope="scope">
                  <span>
                    {{
                      lang === 'zh'
                        ? scope.row.valuationMethod
                        : scope.row.valuationMethodEn
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :label="$t('productDetail.QuantityLadder')"
              >
                <el-table-column
                  v-for="(item, index) in numberStepList"
                  :key="index + 1000"
                  :label="item + ''"
                  align="center"
                >
                  <el-table-column align="center" width="90">
                    <template slot="header">
                      <div>
                        <p class="required-symbol">
                          {{ $t('productDetail.PrintingCycle') }}
                        </p>
                        <p>({{ $t('productDetail.WorkingDay') }})</p>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      <div
                        :class="{
                          'warn-demo':
                            scope.row['printingDay' + index] &&
                            scope.row['printingDay' + index] * 1 <
                              scope.row['printingFrontDay' + index] * 1,
                        }"
                      >
                        <span
                          v-if="
                            !print.deliveryProductNum ||
                            item <= print.deliveryProductNum
                          "
                        >
                          {{ scope.row['printingDay' + index] }}
                        </span>
                        <span v-else>-</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="90">
                    <template slot="header">
                      <div>
                        <p>
                          {{ $t('productDetail.LeadTime') }}
                        </p>
                        <p>({{ $t('productDetail.WorkingDay') }})</p>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      <el-tooltip class="item" effect="light" placement="top">
                        <div slot="content">
                          = {{ $t('productDetail.LeadTimeTips') }}
                          <br />
                          = {{ Number(scope.row['printingDay' + index]) }} +
                          {{ print.transportDay }}
                        </div>
                        <span
                          v-if="
                            !print.deliveryProductNum ||
                            item <= print.deliveryProductNum
                          "
                        >
                          {{ scope.row['deliverDay' + index] }}
                        </span>
                        <span v-else>-</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { deepCopy } from 'kits'
  import { ProductAddEditInteractor } from '@/core/interactors/product/productAddEdit'
  import { listProductSetting } from '@/api/product/productSetting'
  export default {
    data() {
      return {
        tableTitleCn: '',
        tableTitleEn: '',
        productPrintingWayId: [],
        profitMarginOptions: [],
        printWay: [],
        printList: [],
        options: [
          {
            value: 0,
            nameCn: '个/秒钟',
            nameEn: 'Unit/Second',
          },
          {
            value: 1,
            nameCn: '个/分钟',
            nameEn: 'Unit/Minute',
          },
          {
            value: 2,
            nameCn: '个/小时',
            nameEn: 'Unit/Hour',
          },
          {
            value: 3,
            nameCn: '个/天(8小时)',
            nameEn: 'Unit/Day(8-hour)',
          },
          {
            value: 4,
            nameCn: '个/天(12小时)',
            nameEn: 'Unit/Day(12-hour)',
          },
        ],
        transportHeadPrice: '',
        transportDay: '',
        numberStepList: '',
        rowspanVal: 1,
        exchange: 0,
        toFixedNum: 2,
      }
    },
    props: {
      infoParames: {
        type: Object,
        default: () => {
          return {}
        },
      },
      // 是否是被平台产品ProductView页面使用，如果是，需要隐藏【返单版费折扣】，默认为false
      isPlatForm: {
        type: Boolean,
        default: false
      }
    },
    components: {},
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      // 运输类型
      transportationType() {
        const obj = {
          1: () => ({
            nameCn: '空运',
            nameEn: 'Air Transport',
          }),
          2: () => ({
            nameCn: '海运',
            nameEn: 'Ocean Shipping',
          }),
          3: () => ({
            nameCn: '快递',
            nameEn: 'Express',
          }),
        }
        if ([1, 2, 3].includes(this.transportDay * 1)) {
          return obj[this.transportDay]()
        } else {
          return {
            nameCn: '',
            nameEn: '',
          }
        }
      },
    },
    created() {
      this.editShow(this.infoParames)
    },
    methods: {
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if (rowIndex % this.rowspanVal === 0) {
            return {
              rowspan: this.rowspanVal,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },
      async getListProductSettingFn() {
        let response = await listProductSetting()
        if (response.code === '000000') {
          this.profitMarginOptions = response.data.filter(
            (item) => item.type === 10
          )
        }
      },
      async editShow(edit) {
        let res = null
        await this.getListProductSettingFn()
        this.transportHeadPrice = edit?.productTransportVO?.transportHeadPrice
        this.transportDay = edit.transportType
        this.exchange = edit.exchange
        this.toFixedNum = edit.priceScale
        if(Array.isArray(edit.productLadderPriceVOList)) {
        this.numberStepList = edit.productLadderPriceVOList.map(
          (item) => item.num
        )
        }
        if (edit.categoryId) {
          res = await ProductAddEditInteractor.getPrintWay(
            edit.categoryId?.split(',')[edit.categoryId?.split(',').length - 1]
          )
        }
        this.printWay = res?.code === '000000' ? res.data : []

        let list = edit?.productPrintingValuationVOList
          ? deepCopy(edit?.productPrintingValuationVOList)
          : []
        // let printWayIdsArr = this.printWay.map(
        //   (item) => item.productPrintingPricingId
        // )
        // list = list.reduce((previousValue, currentValue) => {
        //   if (printWayIdsArr.includes(currentValue.productPrintingWayId)) {
        //     previousValue.push(currentValue)
        //   }
        //   return previousValue
        // }, [])
        list = list?.map((currentValue) => {
          if (currentValue.productPrintingWayId) {
            this.productPrintingWayId.push(currentValue.productPrintingWayId)
          }
          let productPrintingFullValuationDTOList = []
          let capacityUnit = ''
          currentValue?.productPrintingFullValuationVOList?.forEach(
            (item, index) => {
              capacityUnit = item.capacityUnit
              let obj = {
                printingPositionNum: item.printingPositionNum,
                valuationMethod: item.valuationMethod,
                valuationMethodEn: item.valuationMethodEn,
                capacity: item.capacity,
                price: item.price,
                publishFee: item.publishFee,
                fullValuationId: item.fullValuationId,
              }
              item.productPrintingLadderVOList.forEach((subItem, sunIndex) => {
                obj['profitRate' + sunIndex] = subItem.profitRate
                obj['price' + sunIndex] = subItem.price
                obj['printingDay' + sunIndex] = subItem.printingDay
                obj['printingFrontDay' + sunIndex] = subItem.printingFrontDay
                obj['deliverDay' + sunIndex] = subItem.deliverDay
                obj['ladderNumId' + sunIndex] = subItem.ladderNumId
                obj['transportHeadPrice' + sunIndex] =
                  subItem.transportHeadPrice
              })
              productPrintingFullValuationDTOList.push(obj)
            }
          )
          // const payItem = this.printWay.filter(
          //   (item) =>
          //     item.productPrintingPricingId ===
          //     currentValue.productPrintingWayId
          // )?.[0]
          const interestRateObj = this.profitMarginOptions.filter((item) => {
            return item.productSettingId === currentValue.profitRateTemplateId
          })?.[0]
          return {
            capacityUnit,
            // optionsVOS: payItem.optionsVOS,
            nameCn: currentValue.productPrintingWayNameCn,
            nameEn: currentValue.productPrintingWayNameEn,
            // printingPositionIdsOptions: payItem.printingPositionIds,
            locationNum: currentValue.locationNum,
            printingPositionIds: currentValue.printingPositionIds
              ? currentValue.printingPositionIds.split(',')
              : [],
            valuationMethodIds: currentValue.valuationMethodIds
              ? currentValue.valuationMethodIds.split(',')
              : [],
            printingPositionValue: currentValue.printingPositionValue,
            printingPositionValueEn: currentValue.printingPositionValueEn,
            valuationMethodValue: currentValue.valuationMethodValue,
            valuationMethodValueEn: currentValue.valuationMethodValueEn,
            productPrintingWayId: currentValue.productPrintingWayId,
            profitRateTemplateId: currentValue.profitRateTemplateId,
            profitRateTemplateNameCn: interestRateObj?.nameCn,
            profitRateTemplateNameEn: interestRateObj?.nameEn,
            returnDiscount: currentValue.returnDiscount,
            deliveryProductNum: currentValue.deliveryProductNum,
            deliveryDayProductNum: currentValue.deliveryDayProductNum,
            transportDay: currentValue.transportDay,
            printingValuationId: currentValue.printingValuationId,
            productPrintingFullValuationDTOList,
            objectSpanMethod: ({ row, column, rowIndex, columnIndex }) => {
              const len = currentValue.valuationMethodIds
                ? currentValue.valuationMethodIds.split(',').length
                : 1
              if (columnIndex === 0) {
                if (rowIndex % len === 0) {
                  return {
                    rowspan: len,
                    colspan: 1,
                  }
                } else {
                  return {
                    rowspan: 0,
                    colspan: 0,
                  }
                }
              }
            },
          }
        })
        // 获取印刷方式名称
        let nameCn = []
        let nameEn = []
        list.forEach((item, index) => {
          this.$set(this.printList, index, item)
          nameCn.push(item.nameCn)
          nameEn.push(item.nameEn)
        })

        this.tableTitleCn = nameCn.join(',')
        this.tableTitleEn = nameEn.join(',')
      },
      getMinData(val) {
        let arr = []
        val.forEach((item) => {
          let subArr = []
          this.numberStepList.forEach((sub, subIndex) => {
            subArr.push(item['price' + subIndex])
          })
          arr.push(subArr)
        })
        const arrSort = arr.reduce((acc, curr) => {
          curr.forEach((value, index) => {
            if (!Array.isArray(acc[index])) {
              acc[index] = []
            }
            acc[index].push(value)
          })
          return acc
        }, [])
        const sortResult = arrSort.map((subArr) => {
          let min = undefined
          for (const value of subArr) {
            if (
              (typeof value === 'number' && value !== 0) ||
              (typeof value === 'string' && value.trim() !== '')
            ) {
              if (min === undefined || value * 1 < min) {
                min = value * 1
              }
            }
          }
          return min // 返回最小的非零且非空元素
        })
        return sortResult
      },
    },
  }
</script>
<style scoped lang="scss">
  .print-pricing {
    margin: 0 0 30px;
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }
    .content {
      height: 100%;
      border: 1px solid #e4e7ed;
      border-radius: 3px;
      .content-head {
        height: 50px;
        line-height: 50px;
        background-color: #f2f2f2;
        width: 100%;
        padding-left: 10px;
      }
      .panel-item {
        padding: 20px 10px 0;
      }
      .panle-item-title {
        padding: 10px 20px;
        .left-h6 {
          line-height: 30px;
          margin-bottom: 20px;
          &::before {
            content: '';
            display: block;
            height: 26px;
            width: 4px;
            background: rgba(2, 125, 180, 1);
            margin-right: 5px;
          }
        }
        .outnumber-panle {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          ::v-deep .el-input {
            width: 100px;
            margin: 0 10px;
          }
        }
        .outnumber-panle-other {
          margin-bottom: 20px;
          ::v-deep .el-input {
            width: 100px;
            margin: 0 10px;
          }
        }
      }
    }
    ::v-deep {
      .el-table {
        .el-table__body td {
          height: 86px;
          .el-form-item {
            margin: 18px 0;
          }
        }
      }
    }
  }
  .required-symbol {
    &::before {
      content: '*';
      color: #ff4d4f;
    }
  }
  .profit-rate {
    &::after {
      content: '%';
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
    .el-input__inner {
      padding: 0 18px 0 15px;
    }
  }
  .warn-demo {
    padding: 3px;
    border: 1px solid rgba(217, 0, 27, 1);
    background-color: rgba(217, 0, 27, 0.09803921568627451);
  }
  .select-box {
    display: flex;
    align-items: center;
    ::v-deep.el-tooltip {
      margin-left: 2px;
    }
  }
</style>
