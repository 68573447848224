var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$t('mailConfig.addSubAccount'),"visible":_vm.dialogFormVisible,"width":"450px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Confirm'))+" ")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Cancel'))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-position":"top"}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.grantauthorizationto'),"rules":[
          {
            required: true,
            trigger: 'change',
            message: _vm.$t('placeholder.plsSel'),
          } ],"prop":"authUserId"}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('UserSelect',{ref:"userSelect",staticStyle:{"width":"100%"},on:{"user-choose":_vm.chooseUser}})],1)],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.myemail'),"prop":"emailAccount","rules":[
              {
                required: true,
                trigger: 'change',
                message: _vm.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{model:{value:(_vm.form.emailAccount),callback:function ($$v) {_vm.$set(_vm.form, "emailAccount", $$v)},expression:"form.emailAccount"}},_vm._l((_vm.mailServerList),function(item,index){return _c('el-option',{key:item.mailAccountId,attrs:{"label":item.emailAccount,"value":item.emailAccount}})}),1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }