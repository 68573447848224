<!--首页数据看盘组件-->
<template>
  <el-row class="wrapper" :gutter="10">
    <el-col :span="4" v-for="(card, index) in listData" :key="card.configId">
      <div
        class="card"
        @click="jump(card)"
        :class="{ 'no-mr': index === listData.length - 1 }"
      >
        <div class="content">
          <div class="number">
            <span
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              customClass="index-loading"
            >
              <span class="count" :title="card.count > 99 ? card.count : ''">
                <Count
                  v-if="!loading"
                  :startVal="0"
                  :endVal="counts[card.showType]"
                  :duration="2000"
                ></Count>
              </span>
            </span>
            <span class="dw">条</span>
          </div>
          <div class="name">{{ card.showName }}</div>
        </div>
        <DataKanpanIcon :type="card.showType" />
      </div>
    </el-col>
    <el-col :span="4" v-for="(i, index) in padCount" :key="index">
      <div class="card card-empty" style="justify-content: center">
        <DataKanpanEmpty />
      </div>
    </el-col>
  </el-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import DataKanpanIcon from './data-kanpan-icon.vue'
  import DataKanpanEmpty from './data-kanpan-empty.vue'
  import { HomeInteractor } from '@/core'
  import Count from 'vue-count-to'
  import mixins from '../mixins'
  const MAX_CARD_COUNT = 6
  export default {
    name: 'DataKanPan',
    mixins: [mixins],
    data() {
      return {
        counts: {
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
        },
        loading: true,
      }
    },

    components: {
      DataKanpanIcon,
      DataKanpanEmpty,
      Count,
    },
    computed: {
      ...mapGetters({
        homepageConfig: 'kanpan/homepageConfig',
      }),
      listData() {
        if (!this.homepageConfig || this.homepageConfig.length === 0) {
          return []
        }
        let list = this.homepageConfig
          .filter((i) => i.showType === '1')[0]
          .voList.filter((j) => j.divStatus === '1')
        return list.length > 6 ? list.splice(0, 6) : list
      },
      padCount() {
        if (this.listData.length > MAX_CARD_COUNT) {
          return 0
        }
        return MAX_CARD_COUNT - this.listData.length
      },
    },
    methods: {
      getIconByType() {
        return require('@/assets/homepage_images/icon1.png')
      },
      jump(card) {
        let routeData

        switch (card.showName) {
          case '审核单':
            routeData = this.$router.resolve({
              path: '/audit-process/audit-manage/index',
            })
            break
          case '询盘报价单':
            routeData = this.$router.resolve({
              path: '/order/inquiryManage/inquiryList',
            })
            break
          case '销售待安排订单':
            routeData = this.$router.resolve({
              path: '/order/orderList/orderList',
              query: {
                cardClick: true,
              },
            })
            break
          case '采购待处理需求':
            routeData = this.$router.resolve({
              path: '/purchase/purchaseDemand/purchaseDemand',
            })
            break
          case '验货单':
            routeData = this.$router.resolve({
              path: '/purchase/cargoManage/cargoManage',
            })
            break
          case '付款单':
            routeData = this.$router.resolve({
              path: '/finance-center/payment-manage/index',
            })
            break
          case '费用单':
            routeData = this.$router.resolve({
              path: '/finance-center/cost-manage/index',
            })
            break
        }
        window.open(routeData.href, '_blank')
      },
    },

    filters: {
      countFilter(val) {
        if (val > 80) {
          return 80
        }
        return val
      },
    },

    watch: {
      listData: {
        deep: true,
        async handler(val) {
          let counts = {}
          const showType2Url = {
            4: '/audit/auditObject/getHomeAuditIngNum',
            5: '/order/orderEnquiry/getEnquiryOrderIndexNum',
            6: '/order/salesOrder/getSalesOrderIndexNum',
            7: '/order/purchasingDemandProduct/getPurchasingDemandIndexNum',
            8: '/order/inspectionOrder/getInspectionOrderIndexNum',
            9: '/finance/payOrder/getPayOrderIndexNum',
            10: '/finance/costSheet/getCostSheetIndexNum',
          }
          const showTypes = val?.map((i) => i.showType)
          for (let index in showTypes) {
            let showType = showTypes[index]
            let data = await HomeInteractor.indexPendingOrderNum(
              showType2Url[showType]
            )
            counts[showType] = data
          }
          this.loading = false
          this.counts = counts
        },
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-width: 1160px;
    font-family: PingFangSC-Regular, PingFang SC;
    .card {
      display: flex;
      padding: 20px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 10px;
      &.card-empty {
        height: 118px;
      }
      &:hover {
        box-shadow: 0px 1px 5px 0px rgba(206, 206, 206, 0.5);
      }

      .content {
        display: flex;
        flex-direction: column;
        flex: 1;

        span {
          display: inline-block;
        }
        .number {
          display: flex;
          margin-bottom: 8px;
          align-items: baseline;
          font-size: 14px;
          display: flex;
          align-items: baseline;

          .count {
            height: 50px;
            font-size: 36px;

            font-weight: 600;
            color: #000000;
            line-height: 50px;
          }
          .dw {
            width: 14px;
            height: 20px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin-left: 6px;
          }
        }
        .name {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #5e5e5e;
          line-height: 20px;
        }
      }
    }
    .no-mr {
      margin-right: 0;
    }
    .jst {
      justify-content: center;
    }
  }
</style>
