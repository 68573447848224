export const markSettingMixin = {
  methods: {
    // 唛头设置
    showMarkForm(row) {
      this.$refs.downloadMarkSettingDialog.showAddEdit(row)
    },

    // 唛头表格刷新
    refreshMarkTable() {
      this.$refs.downloadMarkSettingTable.getTableData()
    },

    editMark(row) {
      this.$refs.downloadMarkSettingDialog.showAddEdit(row)
    },
  },
}
