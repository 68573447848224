<template>
  <div class="link-standard" @click="handleToStandard">
    <i class="iconfont icon-shejiguifan"></i>
    <el-link type="primary" :underline="false">
      {{ $t('productDetail.linkMaintenanceGuidelines') }}
    </el-link>
  </div>
</template>

<script>
  export default {
    methods: {
      // 查看产品维护规范
      handleToStandard() {
        const url =
          'https://alidocs.dingtalk.com/i/nodes/AR4GpnMqJzY5Dm0zIZaol3ZXVKe0xjE3'
        window.open(url, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .link-standard {
    cursor: pointer;
    margin-right: 2%;
    .icon-shejiguifan {
      color: #1890ff;
    }
    .el-link {
      margin-left: 4px;
      text-decoration: underline;
      vertical-align: top;
    }
  }
</style>
