<template>
  <div class="pro-container">
    <div>
      <el-radio-group :disabled="disabled" v-model="form.emailReminded">
        <el-radio :label="1">{{ $t('mailConfig.Notice2') }}</el-radio>
        <el-radio :label="0">
          {{ $t('mailConfig.Notice3') }}
        </el-radio>
      </el-radio-group>
    </div>
    <!-- 心标 -->
    <div class="mt20">
      <el-radio-group v-model="form.heartEnabled">
        <el-radio :label="1">{{ $t('mailConfig.TurnOntheHeartIcon') }}</el-radio>
        <el-radio :label="0">
          {{ $t('mailConfig.TurnOfftheHeartIcon') }}
        </el-radio>
      </el-radio-group>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="save">
        {{ $t('inquiryList.Save') }}
      </el-button>
    </div>
  </div>
</template>

<script>
  import { hasRight } from '@/utils/permissionBtn'
  import { MailSettingInteractor } from '@/core'

  export default {
    name: 'MailOtherConfig',

    data() {
      return {
        disabled: true,
        form: {
          emailReminded: '',
          heartEnabled: 0,// 心标
        },
      }
    },

    mounted() { },

    methods: {
      // 获取表单详情
      getDetail() {
        MailSettingInteractor.myAuthConfigApi().then((res) => {
          if (res?.code === '000000') {
            this.form = {
              ...res.data,
            }
          }
        })
        this.disabled = !hasRight('mailOtherConfigOperation')
      },

      save() {
        this.setChecked()
      },

      setChecked() {
        MailSettingInteractor.changeUserAuthConfigApi({
          ...this.form,
        }).then((res) => {
          if (res?.code === '000000' && res?.data) {
            this.$message.success(this.$t('reqmsg.$9'))
            this.getDetail()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bottom {
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
  }
</style>
