import { systemInteractor } from '@/core'
import { BusEmit } from '@/utils/eventBus'

const mixin = {
  data() {
    return {
      showAssign: true, //展示分配按钮
      showReply: true, // 回复按钮
      showForward: true, //转发
    }
  },
  computed: {
    isGmail() {
      let isGmail = false
      let currentAccount = this.$store.state.mail.currentAccount
      let leftTableData = this.$store.state.mail.leftTableData
      if (currentAccount && leftTableData.length > 0) {
        let currentAccountObj = leftTableData.find(
          (item) => item.emailAccount === currentAccount
        )
        if (
          currentAccountObj?.mailServer === 'Gmail' &&
          currentAccountObj?.authStatus === null
        ) {
          isGmail = true
        }
      }
      return isGmail
    },
    noAssignDir() {
      let mailName = this.$route.query?.mailName
      return ['已发邮件', '草稿', '已删除邮件', '垃圾邮件'].includes(mailName)
    },
  },

  methods: {
    // flag allowAssign：分配
    async getPrivilege(mailIdList, emailAccount) {
      let res = await systemInteractor.myMailPrivilegeApi({
        emailAccount: emailAccount || this.$store.state.mail.currentAccount,
        mailIdList,
      })
      let privilege = {}
      if (res?.code === '000000') {
        privilege = res.data || {}
      } else {
        privilege = {}
      }
      return privilege
    },
  },
}

export default mixin

export function initMail(data, ctx) {
  if (data?.length == 0) {
    ctx.$store.commit('mail/setCurrentAccount', '')
    BusEmit('MailList', 'updatePage')
    return
  }
  if (!ctx.$route.query.fromHomePage && !ctx.$store.state.mail.currentAccount) {
    ctx.$store.commit(
      'mail/setCurrentAccount',
      data && data.length ? data[0].emailAccount : ''
    )
  }
}
