<template>
  <div class="bom flex">
    <div class="L">
      <el-tabs v-model="activeName">
        <el-tab-pane label="产品库" name="1">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-input
                v-model="codeOrName"
                placeholder="搜索产品名称/SKU ID/规格"
                maxlength="50"
                clearable
              />
            </el-col>
            <el-col :span="3">
              <div class="text-left">
                <el-button @click="search1">搜索</el-button>
              </div>
            </el-col>
          </el-row>

          <!-- 产品库Table -->
          <el-table
            class="mt10"
            ref="multipleTable1"
            border
            :data="tableData1"
            height="calc(100vh - 280px)"
            :row-key="
              (row) => {
                return row.skuId
              }
            "
            @selection-change="selectionChange1"
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              width="40"
            />
            <el-table-column
              align="center"
              label="产品编码"
              prop="skuId"
              width="120"
            >
              <template slot-scope="scope">
                <div class="page-link">
                  <router-link
                    :to="`/product/ProductView?proId=${
                      scope.row.productId
                    }&noReturn=${true}`"
                    target="_blank"
                  >
                    {{ scope.row.skuId }}
                  </router-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="产品图片" width="100">
              <template slot-scope="scope">
                <div class="img-wrap">
                  <el-badge
                    v-show="
                      scope.row.imgMain &&
                      scope.row.imgMain.split(',').length > 1
                    "
                    :value="
                      scope.row.imgMain
                        ? scope.row.imgMain.split(',').length
                        : ''
                    "
                  ></el-badge>
                  <el-image
                    v-if="scope.row.imgMain"
                    :src="scope.row.imgMain.split(',')[0]"
                    class="c_pointer"
                    @click="handlePreview(scope.row.imgMain.split(','))"
                  ></el-image>
                  <span v-else>--</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="180"
              align="center"
              label="产品名称"
              prop="nameCn"
              show-overflow-tooltip
            />
            <el-table-column align="center" label="规格" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.specsValue1 }}</span>
                <span v-show="scope.row.specsValue2">
                  /{{ scope.row.specsValue2 }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              align="center"
              label="产品类别"
              show-overflow-tooltip
              prop="standardCustomized"
            />
            <el-table-column
              width="100"
              align="center"
              label="产线"
              show-overflow-tooltip
              prop="productionLine"
            />
            <el-table-column
              width="100"
              align="center"
              label="产品运营"
              show-overflow-tooltip
              prop="productBelonger"
            />
          </el-table>

          <el-pagination
            :current-page="page1.pageNo"
            :page-size="page1.pageLe"
            :total="total1"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            @current-change="pageChange1"
            @size-change="sizeChange1"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </el-tab-pane>
        <el-tab-pane label="辅料库" name="2">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-input
                v-model="queryString"
                placeholder="搜索产品名称/产品编码"
                maxlength="50"
                clearable
              />
            </el-col>
            <el-col :span="3">
              <div class="text-left">
                <el-button @click="search2">搜索</el-button>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="text-right">
                <el-button @click="add">新增辅料</el-button>
              </div>
            </el-col>
          </el-row>

          <!-- 辅料库Table -->
          <el-table
            class="mt10"
            ref="multipleTable2"
            border
            :data="tableData2"
            height="calc(100vh - 280px)"
            :row-key="
              (row) => {
                return row.accessoriesNumber
              }
            "
            @selection-change="selectionChange2"
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              width="40"
            />
            <el-table-column align="center" label="产品编码" width="120">
              <template slot-scope="scope">
                <div class="page-link">
                  {{ scope.row.accessoriesNumber }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品图片"
              prop="productImg"
              width="100"
            >
              <template slot-scope="scope">
                <div class="img-wrap">
                  <el-badge
                    v-show="
                      scope.row.productImg &&
                      scope.row.productImg.split(',').length > 1
                    "
                    :value="
                      scope.row.productImg
                        ? scope.row.productImg.split(',').length
                        : ''
                    "
                  ></el-badge>
                  <el-image
                    v-if="scope.row.productImg"
                    :src="scope.row.productImg.split(',')[0]"
                    class="c_pointer"
                    @click="handlePreview(scope.row.productImg.split(','))"
                  ></el-image>
                  <span v-else>--</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品名称"
              prop="nameCn"
              show-overflow-tooltip
            />
          </el-table>

          <el-pagination
            :current-page="page2.pageNo"
            :page-size="page2.pageLe"
            :total="total2"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            @current-change="pageChange2"
            @size-change="sizeChange2"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="R">
      <p class="mt10">BOM单：</p>
      <el-form class="container" :model="form" ref="ruleForm">
        <el-table class="input-table mt10" border :data="form.bomList">
          <el-table-column
            align="center"
            label="产品编码"
            prop="skuId"
            width="120"
          >
            <template slot-scope="scope">
              {{
                scope.row.sku || scope.row.skuId || scope.row.accessoriesNumber
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="产品图片" width="100">
            <template slot-scope="scope">
              <div class="img-wrap">
                <el-badge
                  v-show="
                    scope.row.imgMain && scope.row.imgMain.split(',').length > 1
                  "
                  :value="
                    scope.row.imgMain ? scope.row.imgMain.split(',').length : ''
                  "
                ></el-badge>
                <el-image
                  v-if="scope.row.imgMain"
                  :src="scope.row.imgMain.split(',')[0]"
                  class="c_pointer"
                  @click="handlePreview(scope.row.imgMain.split(','))"
                ></el-image>
                <span v-else>--</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="产品名称"
            prop="nameCn"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="ell">{{ scope.row.nameCn }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="产品类型"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="ell">
                {{ scope.row.standardCustomized || '辅料' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            align="center"
            label="组合数量"
            label-class-name="required-th"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'bomList.' + scope.$index + '.numCombination'"
                :rules="[
                  {
                    required: true,
                    message: '组合数量必填',
                    trigger: 'change',
                  },
                  {
                    pattern: /^[1-9]\d*$/,
                    message: '请输入>0的整数',
                    trigger: 'change',
                  },
                ]"
              >
                <el-input
                  maxlength="20"
                  @blur="numCombinationChange"
                  v-model="form.bomList[scope.$index].numCombination"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column width="60" align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                class="icon-r"
                @click="del(scope)"
                size="mini"
                icon="el-icon-delete"
                circle
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>

    <div class="btns">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>

    <!-- 新增辅料 -->
    <sub-product
      ref="subProduct"
      @saveSubProduct="saveSubProduct"
    ></sub-product>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
import purchaseDemandInterator from '@/core/interactors/purchase/purchaseDemand'
import productList from '@/components/productList'
import subProduct from '@/views/product/subProduct/components/addSubProduct.vue'
export default {
  components: {
    productList,
    subProduct,
  },
  data() {
    return {
      isEdit: false,
      activeName: '1',
      codeOrName: '', // 产品库搜索参数
      queryString: '', // 辅料库搜索参数
      page1: {
        pageLe: 10,
        pageNo: 1,
      },
      page2: {
        pageLe: 10,
        pageNo: 1,
      },
      total1: 0,
      total2: 0,
      tableData1: [],
      tableData2: [],
      selectList1: [],
      selectList2: [],
      form: {
        bomList: [], // BOM单数据
      },
      bomListNum: [], // BOM单缓存组合数量
      bomListPrint: [], // BOM单缓存印刷方式
      showViewer: false, //图片预览参数
      imgUrl: '', //图片预览url
    }
  },
  created() {
    this.getList1()
    this.getList2()
  },
  methods: {
    // 编辑bom单数据回显
    upDataBomList(data) {
      if (data.length) {
        data.forEach((item, i) => {
          if (item.sku.includes('FL')) {
            this.$refs.multipleTable2.toggleRowSelection(item, true)
          } else {
            this.$refs.multipleTable1.toggleRowSelection(item, true)
          }
        })
        this.form.bomList = [...data]
        this.bomListNum = [...data]
        this.bomListPrint = [...data]
        this.isEdit = true
      }
    },

    // 产品库复选框勾选
    selectionChange1(val) {
      this.selectList1 = val
      this.upDateBomList()
    },

    // 辅料库复选框勾选
    selectionChange2(val) {
      this.selectList2 = val
      this.upDateBomList()
    },

    // 更新bom单
    upDateBomList() {
      let list = [...this.selectList1.concat(this.selectList2)]
      this.form.bomList = list.map((item) => {
        return {
          ...item,
          numCombination: this.getNumCombination(
            item.sku || item.skuId || item.accessoriesNumber
          ),
          imgMain: item.image || item.productImg || item.imgMain,
        }
      })
    },

    // 辅料库保存
    async saveSubProduct(form) {
      const res = await purchaseDemandInterator.addSubProduct(form)

      if (res?.code === '000000') {
        this.$message.success('新增成功')
      } else {
        this.$message.success(res.desc)
      }
      this.getList2()
      this.$refs.subProduct.close()
    },

    // 获取产品库
    async getList1() {
      const params = {
        ...this.page1,
        codeOrName: this.codeOrName,
      }
      const res = await purchaseDemandInterator.productGetSkuByProductId(params)

      if (res && res.code === '000000') {
        this.tableData1 =
          res?.data?.data.map((item) => {
            return {
              ...item,
              imgMain: item.image || item.imgMain,
            }
          }) || []
        this.total1 = res?.data?.total || 0
        this.toggleRowSelection1()
      }
    },

    // 获取辅料库
    async getList2() {
      const params = {
        ...this.page2,
        queryString: this.queryString,
      }
      const res = await purchaseDemandInterator.subProductList(params)

      if (res && res.code === '000000') {
        this.tableData2 =
          res?.data?.list.map((item) => {
            return {
              ...item,
              imgMain: item.productImg,
            }
          }) || []
        this.total2 = res?.data?.total || 0
        this.toggleRowSelection2()
      }
    },

    // 产品库table复选框回显
    toggleRowSelection1() {
      if (this.selectList1) {
        const array = this.selectList1.map((item) => item.sku || item.skuId)

        if (array.length) {
          this.$nextTick(() => {
            const ids = this.tableData1.map((item) => item.skuId)
            this.tableData1.forEach((row) => {
              if (this.isEdit) {
                for (let i = 0; i < this.selectList1.length; i++) {
                  if (ids.includes(this.selectList1[i].sku)) {
                    this.$refs.multipleTable1.toggleRowSelection(
                      this.selectList1[i],
                      false
                    )
                  }
                }
              }

              if (array.includes(row.skuId)) {
                this.$refs.multipleTable1.toggleRowSelection(row, true)
              }
            })
          })
        }
      }
    },

    // 辅料库table复选框回显
    toggleRowSelection2() {
      if (this.selectList2) {
        const array = this.selectList2.map(
          (item) => item.sku || item.accessoriesNumber
        )

        if (array.length) {
          this.$nextTick(() => {
            const ids = this.tableData2.map((item) => item.accessoriesNumber)
            this.tableData2.forEach((row) => {
              if (this.isEdit) {
                for (let i = 0; i < this.selectList2.length; i++) {
                  if (ids.includes(this.selectList2[i].sku)) {
                    this.$refs.multipleTable2.toggleRowSelection(
                      this.selectList2[i],
                      false
                    )
                  }
                }
              }
              if (array.includes(row.accessoriesNumber)) {
                this.$refs.multipleTable2.toggleRowSelection(row, true)
              }
            })
          })
        }
      }
    },

    // 组合数量触发事件
    numCombinationChange() {
      this.bomListNum = [...this.form.bomList].map((item) => {
        return {
          ...item,
          sku: item.sku || item.skuId || item.accessoriesNumber,
        }
      })
    },

    // table复选框事件获取缓存的组合数量
    getNumCombination(sku) {
      let num = null
      let arr = this.bomListNum.filter((item) => item.sku == sku)

      if (arr.length) num = arr[0].numCombination
      return num
    },

    // 删除BOM单去掉勾选
    clearSelect(item, boo) {
      if (this.form.bomList.length) {
        this.$nextTick(() => {
          // 判断删除的BOM单是产品库还是辅料库
          if (!boo) {
            //1. 获取当前页面的:data="tableData2"数据的id
            const tableSelectItemId = this.tableData2.map(
              (item) => item.accessoriesNumber
            )
            //2. 首先循环当前页的:data="tableData2"里的数据进行判断
            for (let i = 0; i < this.tableData2.length; i++) {
              if (
                this.tableData2[i].accessoriesNumber === item.accessoriesNumber
              ) {
                let index2 = null
                this.selectList2.forEach((obj, n) => {
                  if (obj.accessoriesNumber == item.accessoriesNumber) {
                    index2 = n
                  }
                })
                this.$refs.multipleTable2.toggleRowSelection(
                  this.selectList2[index2],
                  false
                )
              } else {
                //3. 然后在判断@selection-change="handleSelectionChange2"产生的数据
                for (let j = 0; j < this.selectList2.length; j++) {
                  //4. 判断id是否一样 && 当前页的:data="tableData2"里面不能包含其他页的id
                  if (
                    this.selectList2[j].sku &&
                    this.selectList2[j].sku === item.accessoriesNumber
                  ) {
                    this.$refs.multipleTable2.toggleRowSelection(
                      this.selectList2[j],
                      false
                    )
                  } else {
                    if (
                      this.selectList2[j].accessoriesNumber &&
                      this.selectList2[j].accessoriesNumber ===
                        item.accessoriesNumber &&
                      !tableSelectItemId.includes(
                        this.selectList2[j].accessoriesNumber
                      )
                    ) {
                      this.$refs.multipleTable2.toggleRowSelection(
                        this.selectList2[j],
                        false
                      )
                    }
                  }
                }
              }
            }
          } else {
            //1. 获取当前页面的:data="tableData1"数据的id
            const tableSelectItemId = this.tableData1.map((item) => item.skuId)
            //2. 首先循环当前页的:data="tableData1"里的数据进行判断
            for (let i = 0; i < this.tableData1.length; i++) {
              if (this.tableData1[i].skuId === item.skuId) {
                let index1 = null
                this.selectList1.forEach((obj, n) => {
                  if (obj.skuId == item.skuId) {
                    index1 = n
                  }
                })
                this.$refs.multipleTable1.toggleRowSelection(
                  this.selectList1[index1],
                  false
                )
              } else {
                //3. 然后在判断@selection-change="handleSelectionChange2"产生的数据
                for (let j = 0; j < this.selectList1.length; j++) {
                  //4. 判断id是否一样 && 当前页的:data="tableData1"里面不能包含其他页的id
                  if (
                    this.selectList1[j].sku &&
                    this.selectList1[j].sku === item.sku
                  ) {
                    this.$refs.multipleTable1.toggleRowSelection(
                      this.selectList1[j],
                      false
                    )
                  } else {
                    if (
                      this.selectList1[j].skuId &&
                      this.selectList1[j].skuId === item.skuId &&
                      !tableSelectItemId.includes(item.skuId)
                    ) {
                      this.$refs.multipleTable1.toggleRowSelection(
                        this.selectList1[j],
                        false
                      )
                    }
                  }
                }
              }
            }
          }
        })
      }
    },

    // 获取印刷方式
    getPrintingWay(sku) {
      let printWay = ''
      if (this.bomListPrint.length) {
        let arr = this.bomListPrint.filter(
          (item) =>
            item.sku == sku ||
            item.skuId == sku ||
            item.accessoriesNumber == sku
        )
        if (arr.length > 0) {
          printWay = arr[0].printingWay
        }
      }
      return printWay
    },

    // 确定
    save() {
      let self = this
      if (!self.form.bomList.length) {
        self.$message.warning('至少添加一条BOM单！')
        return false
      }

      self.$refs.ruleForm.validate((valid) => {
        if (valid) {
          self.form.bomList.forEach((item, i) => {
            if (
              (item.sku || item.skuId || item.accessoriesNumber).includes('FL')
            ) {
              self.form.bomList[i] = Object.assign({}, self.form.bomList[i], {
                sonProductType: '1',
                productCode: item.accessoriesNumber,
                productId: item.productAccessoriesId,
              })
            }

            self.form.bomList[i] = Object.assign({}, self.form.bomList[i], {
              printingWay: this.getPrintingWay(
                item.sku || item.skuId || item.accessoriesNumber
              ),
            })
          })
          self.$emit('splitOpen', null, self.form.bomList)
          self.$emit('bomDrawerClose')
        }
      })
    },

    // 取消
    close() {
      this.$emit('splitOpen')
      this.$emit('bomDrawerClose')
    },

    // 大图预览
    handlePreview(item) {
      if (Array.isArray(item)) {
        this.imgUrl = item
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    // 取消预览
    closeViewer() {
      this.showViewer = false
    },

    // bom单删除
    del(scope) {
      this.utils.baseConfirm(this).then(() => {
        this.clearSelect(scope.row, scope.row.standardCustomized ? true : false)
      })
    },

    // 新增辅料
    add() {
      this.$refs.subProduct.dialogFormVisible = true
    },

    // 产品库搜索
    search1() {
      this.page1.pageLe = 10
      this.page1.pageNo = 1
      this.getList1()
    },

    // 辅料库搜索
    search2() {
      this.page2.pageLe = 10
      this.page2.pageNo = 1
      this.getList2()
    },

    // 产品库分页切换
    pageChange1(val) {
      this.page1.pageNo = val
      this.getList1()
    },

    // 产品库页码切换
    sizeChange1(val) {
      this.page1.pageLe = val
      this.getList1()
    },

    // 辅料库分页切换
    pageChange2(val) {
      this.page2.pageNo = val
      this.getList2()
    },

    // 辅料库页码切换
    sizeChange2(val) {
      this.page2.pageLe = val
      this.getList2()
    },
  },
}
</script>

<style scoped lang="scss">
.bom {
  width: 100%;
  height: 100%;
  padding-bottom: 70px;
  position: relative;
  .L {
    width: 58%;
    padding: 0 10px 0 20px;
    border-right: 1px solid #ededed;
  }
  .R {
    width: 42%;
    padding: 0 20px;
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    text-align: right;
    padding: 14px 20px;
    border-top: 1px solid #ededed;
  }
  .el-tabs {
    .el-button {
      height: 34px !important;
    }
    .el-table {
      height: calc(100vh - 280px);
      overflow-y: auto;
    }
    .el-pagination {
      height: 40px;
    }
  }
  .el-form {
    height: calc(100vh - 149px);
    overflow-y: auto;
  }

  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;
    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }
    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }
}
</style>
