var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"custom-dialog",attrs:{"title":"提交询价","width":"1200px","visible":_vm.inquiryDemandVisible,"close-on-click-modal":false,"before-close":_vm.close},on:{"update:visible":function($event){_vm.inquiryDemandVisible=$event}}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"c-table-wrap"},[_c('table',{staticClass:"custom-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"300"}},[_vm._v("产品名称")]),_c('th',[_vm._v("产品图片")]),_c('th',[_vm._v("需求数量")]),_c('th',{attrs:{"width":"300"}},[_vm._v("产品类别")]),_c('th',{attrs:{"width":"300"}},[_vm._v("产品描述")]),_c('th',{attrs:{"width":"300"}},[_vm._v("印刷描述")]),_c('th',[_vm._v("印刷参考图")]),_c('th',{attrs:{"width":"300"}},[_vm._v("备注说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.detail.productEn))]),_c('td',[_c('div',{staticClass:"img-wrap"},[_c('el-badge',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.detail.productImg &&
                    _vm.detail.productImg.split(',').length > 1
                  ),expression:"\n                    detail.productImg &&\n                    detail.productImg.split(',').length > 1\n                  "}],attrs:{"value":_vm.detail.productImg
                      ? _vm.detail.productImg.split(',').length
                      : ''}}),(_vm.detail.productImg)?_c('el-image',{staticClass:"c_pointer",attrs:{"src":_vm.detail.productImg.split(',')[0]},on:{"click":function($event){_vm.handlePreview(_vm.detail.productImg.split(','))}}}):_c('div',[_vm._v("--")])],1)]),_c('td',[_vm._v(_vm._s(_vm.detail.amount))]),_c('td',[_vm._v(_vm._s(_vm.detail.category || '--'))]),_c('td',[_vm._v(_vm._s(_vm.detail.productDescribe))]),_c('td',[_vm._v(_vm._s(_vm.detail.printingDescribe || '--'))]),_c('td',[_c('div',{staticClass:"img-wrap"},[_c('el-badge',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.detail.printingImg &&
                    _vm.detail.printingImg.split(',').length > 1
                  ),expression:"\n                    detail.printingImg &&\n                    detail.printingImg.split(',').length > 1\n                  "}],attrs:{"value":_vm.detail.printingImg
                      ? _vm.detail.printingImg.split(',').length
                      : ''}}),(_vm.detail.printingImg)?_c('el-image',{staticClass:"c_pointer",attrs:{"src":_vm.detail.printingImg.split(',')[0]},on:{"click":function($event){_vm.handlePreview(_vm.detail.printingImg.split(','))}}}):_c('span',[_vm._v("--")])],1)]),_c('td',[_vm._v(_vm._s(_vm.detail.remarks || '--'))])])])])]),_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form}},[_c('el-table',{staticClass:"mt20",attrs:{"data":_vm.form.inquiryOrderSupplierVOList,"border":"","header-cell-style":{ textAlign: 'center' },"header-cell-class-name":_vm.star,"width":"100%"}},[_c('el-table-column',{attrs:{"type":"index","label":"#","width":"55","align":"center"}}),_c('el-table-column',{attrs:{"label":"供应商名称","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'inquiryOrderSupplierVOList.' + scope.$index + '.supplierName',"rules":[
                {
                  required: true,
                  message: '供应商必填',
                  trigger: 'blur',
                },
                {
                  max: 100,
                  message: '字符长度不能超过100',
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"maxlength":"100","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].supplierName
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "supplierName", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].supplierName\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"产品图片","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'inquiryOrderSupplierVOList.' + scope.$index + '.image',"rules":[
                {
                  required: true,
                  message: '产品图片必填',
                  trigger: 'blur',
                } ]}},[_c('div',{staticClass:"wrapper-box",on:{"click":function($event){return _vm.choseRendingImages(scope.$index, scope.row.image)}}},[_c('el-badge',{directives:[{name:"show",rawName:"v-show",value:(
                    scope.row.image && scope.row.image.split(',').length > 1
                  ),expression:"\n                    scope.row.image && scope.row.image.split(',').length > 1\n                  "}],attrs:{"value":scope.row.image ? scope.row.image.split(',').length : ''}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(scope.row.image),expression:"scope.row.image"}],staticClass:"wrapper-image"},[_c('img',{attrs:{"src":_vm.getFirstImgUrl(scope.row.image),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!scope.row.image),expression:"!scope.row.image"}],staticClass:"wrapper-add"},[_c('i',{staticClass:"el-icon-plus"})])],1)])]}}])}),_c('el-table-column',{attrs:{"label":"印刷logo位置/尺寸","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'inquiryOrderSupplierVOList.' +
                scope.$index +
                '.actualLogoSize',"rules":[
                {
                  required: true,
                  message: '印刷logo位置/尺寸必填',
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"maxlength":"500","type":"textarea","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].actualLogoSize
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "actualLogoSize", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].actualLogoSize\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"产品颜色","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"100","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].productColor
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "productColor", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].productColor\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"起订量","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'inquiryOrderSupplierVOList.' + scope.$index + '.quantity',"rules":[
                {
                  required: true,
                  message: '起订量必填',
                  trigger: 'blur',
                } ]}},[_c('el-input-number',{attrs:{"min":0,"precision":0,"controls":false,"placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].quantity
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "quantity", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].quantity\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"起订价(元)","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'inquiryOrderSupplierVOList.' + scope.$index + '.price',"rules":[
                {
                  required: true,
                  message: '起订价(元)必填',
                  trigger: 'blur',
                } ]}},[_c('el-input-number',{attrs:{"controls":false,"min":0,"precision":3,"placeholder":"请输入","clearable":""},model:{value:(_vm.form.inquiryOrderSupplierVOList[scope.$index].price),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "price", $$v)},expression:"form.inquiryOrderSupplierVOList[scope.$index].price"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"交期(工作日)","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'inquiryOrderSupplierVOList.' + scope.$index + '.completeDays',"rules":[
                {
                  required: true,
                  message: '交期(工作日)必填',
                  trigger: 'blur',
                } ]}},[_c('el-input-number',{staticStyle:{"width":"150px"},attrs:{"controls":false,"min":0,"precision":0,"placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].completeDays
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "completeDays", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].completeDays\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"印刷方式","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"100","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].printingWay
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "printingWay", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].printingWay\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"打样费用(元)","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input-number',{attrs:{"controls":false,"min":0,"precision":3,"placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].proofingFee
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "proofingFee", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].proofingFee\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"打样时间(工作日)","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input-number',{staticStyle:{"width":"150px"},attrs:{"controls":false,"min":0,"precision":0,"placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].proofingDays
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "proofingDays", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].proofingDays\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"箱规(外箱尺寸，装箱数，毛重)","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"500","type":"textarea","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].boxSpecs
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "boxSpecs", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].boxSpecs\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"单个产品克重(克)","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input-number',{attrs:{"controls":false,"min":0,"precision":3,"type":"textarea","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].productWeightg
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "productWeightg", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].productWeightg\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"产品材质","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"100","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].material
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "material", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].material\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"产品尺寸","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"100","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].productSize
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "productSize", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].productSize\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"联系人","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"100","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].contacts
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "contacts", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].contacts\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"联系方式","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"100","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index]
                    .contactInformation
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index]
                    , "contactInformation", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index]\n                    .contactInformation\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"产品链接","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"100","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index].linkHref
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index], "linkHref", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index].linkHref\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"采购员意见/评价","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-input',{attrs:{"maxlength":"500","type":"textarea","placeholder":"请输入","clearable":""},model:{value:(
                  _vm.form.inquiryOrderSupplierVOList[scope.$index]
                    .purchasingComments
                ),callback:function ($$v) {_vm.$set(_vm.form.inquiryOrderSupplierVOList[scope.$index]
                    , "purchasingComments", $$v)},expression:"\n                  form.inquiryOrderSupplierVOList[scope.$index]\n                    .purchasingComments\n                "}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{class:scope.row.recommendFlag == 1 ? 'icon-y' : '',attrs:{"size":"mini","icon":"iconfont icon-good","circle":""},on:{"click":function($event){return _vm.recommend(scope.$index)}}}),_c('el-button',{staticClass:"icon-r",attrs:{"size":"mini","icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.del(scope.$index)}}})]}}])})],1)],1),_c('div',{staticClass:"add"},[_c('span',{staticClass:"c_pointer",on:{"click":_vm.add}},[_vm._v("新增一行")])])],1),_c('div',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" 确定 ")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")])],1),_c('choose-images',{attrs:{"visible":_vm.chooseImagesDialogVisible,"default-file-list":_vm.currentDefaultRendingList},on:{"update:visible":function($event){_vm.chooseImagesDialogVisible=$event},"confirm":_vm.setRendingImages}}),(_vm.showViewer)?_c('el-image-viewer',{attrs:{"on-close":_vm.closeViewer,"url-list":_vm.imgUrl}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }