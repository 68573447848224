<template>
  <div class="custom-container">
    <order-settle-table
      contentType="detail"
      :isAudit="isAudit === '1'"
      :list="tableData"
      :key="tableData.length"
      :tableHeight="tableHeight"
       ref="table"
    ></order-settle-table>
  </div>
</template>
<script>
import { OrderSettlementInteractor } from '@/core'
import orderSettleTable from '@/views/finance-center/order-settlement/components/order-settlement-table.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'settleDetail',
  components: {
    orderSettleTable,
  },
  props: {
    detailId: {
      type: String,
      default: '',
    },
    isAudit: {
      type: String,
      default: '0',
    },

  },
  data() {
    return {
      tableData: [],
      settlementId: '',
      canRun: true,
      tableHeight: 0,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    this.getData()
  },
  mounted() {
    this.getTableHeight()
  },
  methods: {
   async getData() {
     let res =  await OrderSettlementInteractor.listDetailForEditApi(this.detailId);
        if (res?.code === '000000') {
          this.tableData = res?.data
        }
    },
    getTableHeight() {
      const wrapperH = document
          .querySelector('.custom-container')
          .getBoundingClientRect().height,
        height = wrapperH - 200

      this.tableHeight = height
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-container{
  width: calc(100% - 400px);
}
</style>
