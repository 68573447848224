<template>
  <el-dialog
    title="账户设置"
    :visible.sync="dialogVisible"
    width="800px"
    @close="close"
    class="dialog-middle"
  >
    <el-form
      :model="form"
      label-width="80px"
      :rules="rules"
      size="small"
      ref="form"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="type">
            <template slot="label">
              <span>类&nbsp;型</span>
            </template>
            <el-checkbox-group class="inline-block" v-model="form.type">
              <el-checkbox :label="3">收款</el-checkbox>
              <el-checkbox :label="4">付款</el-checkbox>
            </el-checkbox-group>
            <template v-if="form.type.includes(4)">
              <p class="inline-block">
                支持CBS付款
                <el-switch
                  v-model="form.cbsFlag"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </p>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="payee">
            <template slot="label">
              <span>户&nbsp;名</span>
            </template>
            <el-input
              v-model="form.payee"
              maxlength="255"
              placeholder="请输入"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="开户行" prop="bankDeposit">
            <el-input
              v-if="form.cbsFlag === 0 || disabled"
              :disabled="disabled"
              v-model="form.bankDeposit"
              maxlength="255"
              placeholder="请输入"
            ></el-input>
            <!-- CBS开启时开户行未下拉框 -->
            <CnpsSelect
              ref="cnpsSelect"
              v-else
              :disabled="disabled"
              style="width: 100%"
              @cnaps-choose="chooseCnaps"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="bankAccount">
            <template slot="label">
              <span>账&nbsp;号</span>
            </template>
            <el-input
              v-model="form.bankAccount"
              maxlength="255"
              :disabled="disabled"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="dutyParagraph">
            <template slot="label">
              <span>备&nbsp;注</span>
            </template>
            <el-input
              v-model="form.remark"
              maxlength="500"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="close">取消</el-button>
      <el-button type="primary" size="mini" @click="submit" :loading="loading">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    insertShroffAccountPO,
    updateShroffAccount,
  } from '@/api/finance/finance-setting'
  import CnpsSelect from '@/components/cnps-select/index'
  export default {
    name: 'ReceiverSetting',
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: {
          payee: '', // 户名
          payName: '', // 收款人
          bankDeposit: '', // 开户行
          bankAccount: '', //  账号
          dutyParagraph: '', //税号
          shroffAccountId: null,
          type: [],
          cbsFlag: 1, //支持CBS
          remark: '',
          cnapsCode: '',
        },
        rules: {
          type: [
            {
              required: true,
              type: 'array',
              message: '收款和付款至少选择一个',
              trigger: ['change', 'blur'],
            },
          ],
          payee: [{ required: true, message: '请输入', trigger: 'blur' }],
          bankDeposit: [{ required: true, message: '请输入', trigger: 'blur' }],
          bankAccount: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        options: [], //联行号信息下拉数据
        initTypes: [],
      }
    },

    components: {
      CnpsSelect,
    },

    computed: {
      disabled() {
        return !!this.form.shroffAccountId
      },
    },

    watch: {
      'form.cbsFlag': function (val) {
        if (this.disabled) {
          //编辑
          if (val + '' === '1') {
            if (!this.form.cnapsCode) {
              this.form.cbsFlag = 0
              return this.$message.warning(
                '未初始化CBS，请尝试新增账户并打开CBS。'
              )
            }
          }
        }
      },
    },

    methods: {
      //打开弹框
      showDialog(row) {
        if (row) {
          this.form = {
            ...this.form,
            ...row,
            type: row.shroffType == 2 ? [3, 4] : [row.shroffType],
          }
        }
        this.dialogVisible = true

        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },

      //清空表单
      close() {
        this.form = {
          payee: '', // 户名
          payName: '', // 收款人
          bankDeposit: '', // 开户行
          bankAccount: '', //  账号
          dutyParagraph: '', //税号
          shroffAccountId: null,
          type: [],
          cbsFlag: 0, //支持CBS
          cnapsCode: '',
        }
        this.dialogVisible = false
      },
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const reqMethod = this.form.shroffAccountId
              ? updateShroffAccount
              : insertShroffAccountPO

            this.loading = true
            reqMethod({
              ...this.form,
              shroffType: this.form.type.length > 1 ? 2 : this.form.type[0],
            })
              .then((res) => {
                this.loading = false
                if (res.code == '000000') {
                  this.$message.success(
                    this.form.shroffAccountId ? '修改成功！' : '新增成功！'
                  )
                  this.$emit('success')
                  this.close()
                }
              })
              .catch((err) => {
                this.loading = false
              })
          }
        })
      },

      // 选择cnaps
      chooseCnaps(val) {
        if (!val) {
          this.form.cnapsCode = ''
          this.form.bankDeposit = ''
        } else {
          this.form.cnapsCode = val.cnapsCode
          this.form.bankDeposit = val.fullName
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .inline-block {
    display: inline-block;
    margin-right: 30px;
  }
</style>
