<template>
  <div class="configuration-list">
    <el-form
      :model="configurationListForm"
      ref="configurationListForm"
      :key="formKey"
    >
      <el-table
        :data="configurationListForm.tableData"
        border
        style="width: 982px"
      >
        <el-table-column
          align="center"
          prop="email"
          :label="$t('mailConfig.SendToPrimaryEmail')"
          width="240"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.email'"
              :rules="[
                {
                  required: true,
                  message: $t('placeholder.plsInput'),
                  trigger: 'blur',
                },
                {
                  validator: $formValidation.isEmail,
                  trigger: 'blur',
                },
              ]"
            >
              <span v-if="scope.row.disabled">{{ scope.row.email }}</span>
              <el-input v-else v-model="scope.row.email" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="defaultEmail"
          :label="$t('mailConfig.DefaultCCEmail')"
          width="240"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.defaultEmail'"
              :rules="[
                {
                  required: true,
                  message: $t('placeholder.plsInput'),
                  trigger: 'blur',
                },
                {
                  validator: $formValidation.isEmail,
                  trigger: 'blur',
                },
              ]"
            >
              <span v-if="scope.row.disabled">
                {{ scope.row.defaultEmail }}
              </span>
              <el-input
                v-else
                v-model="scope.row.defaultEmail"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="effect"
          :label="$t('mailConfig.Function')"
          width="380"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.effect'"
              :rules="[
                {
                  required: true,
                  message: $t('placeholder.plsSel'),
                  trigger: 'change',
                },
              ]"
            >
              <el-checkbox-group v-model="scope.row.effect">
                <el-checkbox label="sended">
                  {{ $t('mailConfig.Compose') }}
                </el-checkbox>
                <el-checkbox label="replied">
                  {{ $t('mailConfig.reply') }}
                </el-checkbox>
                <el-checkbox label="forwarded">
                  {{ $t('mailConfig.forword') }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$t('mailConfig.Action')"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="removeFn(scope.$index)"
              style="color: red"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="btn-box">
      <el-button type="text" @click="createNewLineFn">
        {{ $t('mailConfig.CreateNewLine') }}
      </el-button>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'configurationList',
    props: {
      mailListObj: {
        type: Object,
        default: () => {
          return {
            tableData: [],
          }
        },
      },
    },
    data() {
      return {
        formKey: 0,
        configurationListForm: {},
      }
    },
    created() {
      this.configurationListForm = this.mailListObj
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    watch: {
      lang() {
        this.formKey++
      },
    },
    methods: {
      // 新建一行
      createNewLineFn() {
        this.configurationListForm.tableData.push({
          email: '',
          defaultEmail: '',
          effect: [],
        })
      },
      // 删除
      removeFn(idx) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('reqmsg.M2006'),
          cancelButtonText: this.$t('reqmsg.M2007'),
          type: 'warning',
        })
          .then(() => {
            this.configurationListForm.tableData.splice(idx, 1)
          })
          .catch(() => {})
      },
      // 保存
      submitForm() {
        return new Promise((resolve) => {
          this.$refs.configurationListForm.validate((valid) => {
            if (valid) {
              resolve({ bol: true, data: this.configurationListForm.tableData })
            } else {
              resolve({ bol: false })
            }
          })
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .configuration-list {
    ::v-deep .el-form-item {
      margin: 14px 20px;
      .el-form-item__error {
        width: 100%;
      }
    }
    .btn-box {
      width: 982px;
      text-align: center;
    }
  }
</style>
