<template>
  <div>
    <p class="title">
      <!-- 安排生产邮件默认抄送设置：可设置抄送多个邮箱，设置后，生产邮件自动发送后，会自动抄送给设置的邮箱； -->
      <span>
        {{ $t('mailConfig.ScheduleProductionEmailDefaultCcSettings') }}：
      </span>
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        style="margin-top: 20px"
      >
        <!-- 抄送邮箱 -->
        <el-form-item :label="$t('mailConfig.CcMailbox')">
          <!-- 请输入邮箱 -->
          <el-autocomplete
            class="inline-input"
            v-model="form.emailAccount"
            :fetch-suggestions="querySearch"
            :placeholder="$t('placeholder.enterEmail')"
            @change="handleChange"
            clearable
            value-key="emailAccount"
            trigger-on-focus
          ></el-autocomplete>
          <el-button
            style="margin-left: 20px"
            type="primary"
            plain
            @click="addTagList"
          >
            <!-- 添加 -->
            {{ $t('other.Add') }}
          </el-button>
        </el-form-item>
        <el-form-item style="margin-top: -10px">
          <el-tag
            @close="delteTag(item)"
            size="small"
            v-for="item in form.productionCCEmail"
            :key="item"
            closable
          >
            {{ item }}
          </el-tag>
        </el-form-item>
        <!-- 抄送方式 -->
        <el-form-item :label="$t('mailConfig.CcMethod')">
          <el-radio-group v-model="form.productionCCType">
            <!-- 正常抄送 -->
            <el-radio :label="0">{{ $t('other.Sendmail') }}</el-radio>
            <!-- 密送 -->
            <el-radio :label="1">{{ $t('myMail.Bcc') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </p>
  </div>
</template>
<script>
export default {
  name: 'plan-product',
  data() {
    return {
      form: {
        emailAccount: '',
        productionCCType: 0,
        productionCCEmail: [],
      },
      tagList: [],
    }
  },
  props: {
    accountList: {
      default: () => [],
      type: Array,
    },
    baseForm: {
      default: () => {},
      type: Object,
    },
  },
  watch: {
    form: {
      handler() {
        this.commitMsg()
      },
      deep: true,
      immediate: true,
    },
    baseForm: {
      handler() {
        this.form.productionCCType = this.baseForm?.productionCCType || 0
        this.form.productionCCEmail = this.baseForm?.productionCCEmail
          ? this.baseForm.productionCCEmail.split(',')
          : []
        this.form.emailAccount = ''
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    commitMsg() {
      let dt = {
        productionCCEmail: this.form.productionCCEmail?.toString(),
        productionCCType: this.form.productionCCType,
      }
      this.$emit('changePlanProductMsg', dt)
    },
    querySearch(queryString, cb) {
      var accountList = this.accountList
      var results = queryString
        ? accountList.filter(this.createFilter(queryString))
        : accountList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (res) => {
        return (
          res.emailAccount.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    handleChange(val) {
      // this.form.emailAccount = val.emailAccount
      if (!this.utils.isEmail(val)) {
        // 邮件地址格式不正确
        this.$message.warning(this.$t('reqmsg.$63'))
        this.form.emailAccount = ''
        return
      }
    },
    // 添加
    addTagList() {
      if (
        (this.form.productionCCEmail.length == 0 ||
          this.form.productionCCEmail?.indexOf(this.form.emailAccount) < 0) &&
        this.form.emailAccount != ''
      ) {
        this.form.productionCCEmail.push(this.form.emailAccount)
      } else if (
        this.form.productionCCEmail?.indexOf(this.form.emailAccount) >= 0
      ) {
        // 请勿重复添加邮箱
        this.$message.warning(this.$t('reqmsg.$64'))
      }

      this.form.emailAccount = ''
    },
    // 删除
    delteTag(item) {
      this.form.productionCCEmail?.splice(
        this.form.productionCCEmail.indexOf(item),
        1
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
  color: #000;
  font-size: 15px;

  .info {
    color: #999;
  }
}
</style>