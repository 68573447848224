<template>
  <el-dialog
    title="PI装运日期提醒提前期"
    :visible.sync="orderRemindVisible"
    width="30%"
    :before-close="cancel"
  >
    <div class="mb15">
      用于设定PI出运前款项未收齐提醒的开始日期。结合PI上装运日期，在【装运日期提前期0点】至【PI的第一笔出运委托单的已出运时间】时间范围内对满足条件的PI进行提醒。
    </div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item
        label="是否需要提醒"
        prop="amountStatus"
        label-width="150px"
      >
        <el-switch
          v-model="form.amountStatus"
          :active-value="1"
          :inactive-value="0"
          @change="handleSwitchChange"
        />
      </el-form-item>
      <el-form-item
        label="装运日期提醒提前期"
        prop="outNumSettingFlag"
        label-width="150px"
      >
        <el-input
          v-model="form.outNumSettingFlag"
          v-only-number.positive
          placeholder="请输入"
          clearable
          :disabled="!form.amountStatus"
          @change="handleInputChange"
        >
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <el-form-item style="text-align: center">
        <ErpLoadingButton type="primary" @click="submit">确认</ErpLoadingButton>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      const checkNum = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入'))
        } else if (+value > 90) {
          callback(new Error('不能超过90'))
        } else {
          callback()
        }
      }
      return {
        form: { amountStatus: false, outNumSettingFlag: '' },
        rules: {
          outNumSettingFlag: [
            { required: true, validator: checkNum, trigger: 'change' },
          ],
        },
      }
    },
    props: { orderRemindVisible: { type: Boolean, default: false } },
    methods: {
      cancel() {
        this.form = this.$options.data().form
        this.$refs.form.resetFields()
        this.$emit('update:orderRemindVisible', false)
      },
      submit(e, done) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const {
              amountId,
              amountStatus,
              outNumSettingFlag,
              outNumSettingFlagPrev,
            } = this.form
            const params = {
              amountType: 15,
              amountId,
              amountStatus,
              outNumSettingFlag:
                amountStatus === 1 ? outNumSettingFlag : outNumSettingFlagPrev,
            }
            this.$emit('saveOrderRemind', params, done)
          } else {
            done()
          }
        })
      },
      handleSwitchChange(num) {
        this.form.amountStatus = num
        this.form.outNumSettingFlag = num
          ? this.form.outNumSettingFlagCopy
          : '--'
      },
      handleInputChange(val) {
        this.form.outNumSettingFlagCopy = val
      },
    },
  }
</script>
