<template>
  <el-row class="organization-container">
    <el-col :span="4" class="left-col">
      <el-button type="primary" @click="roleAddClick('role')">
        添加角色
      </el-button>
      <el-button
        type="primary"
        @click="groupAddClick('group')"
        v-allowed="['Power:Edit']"
      >
        添加分组
      </el-button>
      <el-tree
        :data="treeData"
        node-key="roleId"
        :expand-on-click-node="true"
        :default-expanded-keys="defaultShowNodes"
        class="mt15 pt5"
        :accordion="true"
        style="border-top: 1px solid #ededed"
        @node-click="handleNodeClick"
        ref="TreeView1"
      >
        <el-row
          type="flex"
          align="middle"
          justify="space-between"
          class="custom-tree-node w100"
          :class="data.roleType === 1 ? 'test' : 'test1'"
          slot-scope="{ node, data }"
        >
          <span class="text-hidden">{{ data.name }}</span>
          <el-dropdown v-if="data.roleType !== 1" v-allowed="['Power:Edit']">
            <span class="f_w_7">...</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-link
                  v-if="data.divideGroup === 1"
                  @click.stop="groupAddClick('role', data)"
                >
                  新增角色
                </el-link>
              </el-dropdown-item>
              <el-dropdown-item v-if="data.divideGroup === 1">
                <el-link @click.stop="editGroupClick('group2', data)">
                  编辑分组
                </el-link>
              </el-dropdown-item>
              <el-dropdown-item v-if="data.divideGroup === 1">
                <el-link @click.stop="delGroupClick(data)">删除分组</el-link>
              </el-dropdown-item>
              <el-dropdown-item v-if="data.divideGroup === 0">
                <el-link @click.stop="editGroupClick('role2', data)">
                  编辑角色
                </el-link>
              </el-dropdown-item>
              <el-dropdown-item v-if="data.divideGroup === 0">
                <el-link @click.stop="delGroupClick(data)">删除角色</el-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-row>
      </el-tree>
    </el-col>
    <el-col :span="20" class="right-col">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="角色成员" name="first">
          <RoleMember
            ref="RoleMember"
            :roleId="roleId"
            :divideGroup="divideGroup"
            :superUser="superUser"
          />
        </el-tab-pane>
        <el-tab-pane label="功能权限" name="second" class="testtest">
          <FuntionPower
            ref="FuntionPower"
            :roleId="roleId"
            :superUser="superUser"
          />
        </el-tab-pane>
        <el-tab-pane label="他人权限" name="third">
          <HisRole ref="hisRole" :roleId="roleId" :superUser="superUser" />
        </el-tab-pane>
      </el-tabs>
    </el-col>

    <!-- 添加人员 -->
    <PowerGroupAddModel ref="PowerGroupAddModel" @fetch-data="getListPaged" />
    <!-- 添加角色-->
    <RoleAddModel ref="RoleAddModel" @fetch-data="getListPaged" />
  </el-row>
</template>

<script>
  import PowerGroupAddModel from './components/power-group-add.model'
  import RoleAddModel from './components/role-add.model'
  import RoleMember from './components/role-member'
  import FuntionPower from './components/funtion-power'
  import HisRole from './components/his-role'
  import { listPaged, deleteById, listPageTest } from '@/api/power-group'
  import { roleListPage } from '@/api/user-role'
  export default {
    name: 'PowerGroup',
    components: {
      PowerGroupAddModel,
      RoleMember,
      FuntionPower,
      HisRole,
      RoleAddModel,
    },
    data() {
      return {
        activeName: 'first',
        treeData: [],
        drawer: false,
        size: '600px',
        selectRows: [],
        queryForm: {
          pageLe: 10,
          pageNo: 1, //当前页数
        },
        roleId: '',
        divideGroup: 0, //判断点击的是组还是角色
        hisRoleTemplates: [],
        superUser: 1, //超级管理员
        defaultShowNodes: [],
      }
    },
    mounted() {
      this.getListPaged(this.$route.query.roleId)
    },
    methods: {
      //权限列表
      async getListPaged(roleId) {
        let response = await listPaged(this.queryForm)
        if (response.code === '000000') {
          this.treeData = response.data
          this.treeData.forEach((item) => {
            if (item.adminRole === 1) {
              this.roleId = item.roleId
            }
          })

          this.$nextTick(() => {
            if(roleId) {
              // 从组织架构跳转过来，只有初始化会传roleId
              const item = this.getNodeByRoleId(this.treeData, roleId)
              if(item) {
                this.defaultShowNodes.push(roleId)
                this.$refs.TreeView1?.setCurrentKey(item)
                this.handleNodeClick(item)
              } else {
                this.$baseMessage(
                  '当前用户对应的角色不存在，返回刷新检查！',
                  'error',
                  false,
                  'erp-hey-message-error'
                )
              }
            } else {
              // 其他情况
              if (this.treeData[0]?.children[0]?.roleId) {
                this.defaultShowNodes.push(this.treeData[0].children[0].roleId)
                this.$refs.TreeView1?.setCurrentKey(this.treeData[0].children[0])
                this.handleNodeClick(this.treeData[0].children[0])
              }
            }
            // if (
            //   this.treeData &&
            //   this.treeData[0].children &&
            //   this.treeData[0].children[0].roleId
            // ) {
            //   this.defaultShowNodes.push(this.treeData[0].children[0].roleId)
            //   this.$refs.TreeView1.setCurrentKey(this.treeData[0].children[0])
            //   this.handleNodeClick(this.treeData[0].children[0])
            // }
          })
        }
      },

      //添加角色
      roleAddClick() {
        this.$refs['RoleAddModel'].showAddEdit()
      },

      //选中角色，调用角色成员
      handleNodeClick(data) {
        if (data.divideGroup === 0) {
          this.divideGroup = data.divideGroup
          this.superUser = data.adminRole
          this.roleId = data.roleId
        }
      },

      //新增分组、角色
      groupAddClick(str, data) {
        this.$refs['PowerGroupAddModel'].showAddEdit(str, data)
      },

      //删除分组
      async delGroupClick(data) {
        this.$confirm('删除不可恢复，确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await deleteById({ roleId: data.roleId })
          if (response.code === '000000') {
            this.$baseMessage(
              '删除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
          this.getListPaged()
        })
      },

      //编辑分组
      editGroupClick(str, data) {
        this.$refs['PowerGroupAddModel'].showAddEdit(str, data, 'update')
      },
      //查看人员
      personShowClick() {
        this.drawer = true
      },
      //关闭查看
      closeClick() {
        this.drawer = false
      },
      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
      },
      //tab栏切换
      handleClick() {},
      // 根据roleId找到当前节点
      getNodeByRoleId (tree, roleId) {
        for (const item of tree) {
          if (item.roleId === roleId) return item
          if (item.children?.length) {
            const node = this.getNodeByRoleId(item.children, roleId)
            if (node) return node
          }
        }
      }

    },
  }
</script>

<style scoped lang="scss">
  .left-col {
    height: 100%;
    overflow-y: auto;
  }
  .right-col {
    height: 100%;
    padding: 0 20px;
  }
  .text-hidden {
    width: 80%;
  }

  ::v-deep {
    .el-tabs__content {
      height: calc(100% - 55px) !important;
      .el-tab-pane {
        height: 100%;
      }
    }
    .el-tabs {
      height: 100%;
    }
    .el-tree-node__content {
      height: 35px;
      padding: 0 10px;
    }
    .custom-tree-node {
      span:first-child {
        font-size: 14px;
      }
    }
    .right-col-top {
      .el-form-item {
        margin-bottom: 0;
      }
    }
    .el-tree {
      .is-current {
        > .el-tree-node__content {
          background: #ededed;
        }
      }
    }
    .el-select {
      width: 100%;
    }

    .el-tree-node__content:hover {
      background: #ededed;
    }
    .pagination-container {
      padding: 10px;
    }
    .el-pagination {
      margin-top: 0 !important;
    }
    input[type='number'] {
      -moz-appearance: textfield !important;
    }
  }
  .test {
    color: #46a6ff;
  }
</style>
