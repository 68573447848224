<template>
  <el-dialog
    title="上传图片"
    :visible.sync="visible"
    :before-close="handleClose"
    width="815px"
  >
    <erp-upload-images
      :default-file-list="defaultFileList"
      @change="changeUploadList"
      :limit="4"
      v-if="visible"
    ></erp-upload-images>
    <el-row slot="footer" class="dialog-footer text-center">
      <el-button type="primary" @click="confirmChoose">确 认</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  name: 'chooseImages',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 默认回显图片
    defaultFileList: {
      type: Array,
      default: () => [],
    },

    // 效果图当前行数据
    defaultRow: {
      type: Object,
      default: () => {},
    },
    // 效果图待安排已安排状态
  },
  data() {
    return {
      isFile: true,
      imageList: [],
      rendingsStatus: '1',
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.imageList = JSON.parse(JSON.stringify(this.defaultFileList))
      }
      // newVal && (this.imageList = JSON.parse(JSON.stringify(this.defaultFileList)))
    },
  },
  methods: {
    // 关闭 dialog
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 上传印刷效果图
    changeUploadList(list) {
      this.imageList = list
    },
    // 格式化数据
    formatData() {
      let __urls = []
      const __imageList = this.imageList

      for (let i = 0; i < __imageList.length; i++) {
        __urls.push(__imageList[i].url)
      }
      return {
        urls: this.rendingsStatus != 2 ? __urls.toString() : '',
        status: this.rendingsStatus,
        ...this.defaultRow,
      }
    },
    // 确认
    confirmChoose() {
      if (this.imageList.length) {
        this.handleClose()
        this.$emit('confirm', this.formatData())
      } else {
        this.$message.warning('请上传效果图！')
        return false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.wrapper-status {
  margin-top: 10px;
}
::v-deep {
  // .upload-images {
  //   .el-upload-list {
  //     .is-success {
  //       .el-upload-list__item-actions {
  //         .el-upload-list__item-delete {
  //           display: none !important;
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>
