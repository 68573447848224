<template>
  <div class="custom-container" v-if="isAdd">
    <el-form :rules="rules" :model="form" ref="ruleForm">
      <el-table :data="form.list" border stripe class="input-table">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="80"
        ></el-table-column>
        <el-table-column
          label="地区"
          align="center"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + scope.$index + '.area'"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                v-model="scope.row.area"
                @change="conditionChange($event, scope.$index, 'area')"
                clearable
              >
                <el-option
                  v-for="item in areaArray"
                  :label="item.areaName"
                  :value="item.areaName"
                  :key="item.areaName"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="角色"
          align="center"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + scope.$index + '.role'"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                v-model="scope.row.role"
                @change="conditionChange($event, scope.$index, 'role')"
                clearable
              >
                <el-option
                  v-for="item in roleArray"
                  :label="item.message"
                  :value="item.code"
                  :key="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="主管"
          align="center"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'list.' + scope.$index + '.manager'"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'change',
                },
              ]"
            >
              <el-input
                v-model="scope.row.manager"
                @focus="businessChange(scope.$index)"
                @change="clearBusinessName(scope.$index)"
                placeholder="请选择"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="Del(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button class="new-btn" @click="addLine">新增一行</el-button>
    </el-form>

    <div class="oper-btn text-center mt20">
      <el-button type="default" @click="Cancel">返回</el-button>
      <el-button type="primary" @click="Save">保存</el-button>
    </div>

    <!-- 人员组织树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import UserChoose from '@/components/userChoose'
  import { FinanceThemeSetting } from '@/core/interactors/finance/config-manage'
  export default {
    components: { UserChoose },
    props: ['isAdd', 'areaArray', 'roleArray'],
    // 组件状态值
    data() {
      return {
        form: {
          list: [
            {
              area: '', // 地区
              role: '', // 角色
              manager: '', // 主管
              managerId: '', // 主管Id
              managerArea: '', // 主管区域
              status: 1, // 状态
            },
          ],
        },
        rowIndex: 0,
        businessArray: [],
        rules: {},
      }
    },
    computed: {},
    watch: {},
    created() {},
    methods: {
      // 保存
      Save() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            let res = await FinanceThemeSetting.ThemeInsertPO(this.form.list)
            if (res.code === '000000') {
              this.$message.success('新增成功')
              this.$emit('Add')
              this.empty()
            }
          }
        })
      },

      // 校验重复数据 type(area:区域,role:角色)
      conditionChange(val, i, type) {
        let [_arr, _list] = [[], this.form.list]

        if (_list[i].area && _list[i].role) {
          for (let n = 0; n < _list.length; n++) {
            _arr.push(_list[n].area + ':' + _list[n].role)
          }

          if (_arr.length > Array.from(new Set(_arr)).length) {
            this.$message.warning('地区角色已存在')
            type === 'area' ? (_list[i].area = '') : (_list[i].role = '')
          }
        }
      },

      // 选择主管
      businessChange(i) {
        this.rowIndex = i
        this.$refs['UserChoose'].showAddEdit('', '', true, 1)
      },

      // 获取主管
      chooseUser(arr) {
        this.form.list[this.rowIndex].manager =
          arr[0].name + '(' + arr[0].englishName + ')'
        this.form.list[this.rowIndex].managerId = arr[0].id
        this.form.list[this.rowIndex].managerArea = arr[0].area
      },

      // 删除主管
      clearBusinessName(i) {
        this.form.list[i].manager = ''
        this.form.list[i].managerId = ''
        this.form.list[i].managerArea = ''
      },

      // 新增一行
      addLine() {
        this.form.list.push({
          area: '', // 地区
          role: '', // 角色
          manager: '', // 主管
          managerId: '', // 主管Id
          managerArea: '', // 主管区域
          status: 1, // 状态
        })
      },

      // 删除
      Del(i) {
        if (this.form.list.length === 1) {
          this.$message.warning('至少保留一条')
          return false
        }
        this.$confirm(`删除不可恢复，确定删除?`, '提示', { type: 'warning' })
          .then(() => {
            this.form.list.splice(i, 1)
          })
          .catch(() => {})
      },

      // 取消
      Cancel() {
        if (
          this.form.list.some((item) => item.area || item.role || item.manager)
        ) {
          this.$confirm(`当前页数据未保存，是否返回?`, '提示', {
            type: 'warning',
          })
            .then(() => {
              this.$emit('Cancel')
              this.empty()
            })
            .catch(() => {})
        } else {
          this.$emit('Cancel')
        }
      },

      // 清空数据
      empty() {
        this.form = this.$options.data().form
      },
    },
  }
</script>

<style scoped lang="scss">
  .new-btn {
    width: 100%;
    border-top: none;
    color: #409eff;
  }
  ::v-deep {
    .el-form {
      height: calc(100vh - 150px);
      border-left: none;
      overflow-y: auto;
    }
    .el-select {
      width: 100%;
    }
  }
</style>
