<template>
  <div class="pdfDom" id="pdfDom">
    <h1>
      QUOTATION
      <img src="@/assets/images/RG.png" />
    </h1>
    <div class="container">
      <div class="box" v-for="(detail, k) in offerList" :key="k">
        <div class="top">
          <div>
            Quote No.:
            <span>{{ detail.priceSheetCode }}</span>
          </div>
          <div>
            Quote Date：
            <span>{{ detail.priceSheetDate }}</span>
          </div>
          <div>
            Sales：
            <span>{{ detail.salesName }}</span>
          </div>
          <div>
            Sales Email：
            <span>{{ detail.salesEmail }}</span>
          </div>
        </div>

        <table class="layout-1">
          <thead>
            <tr>
              <th width="16%">Company</th>
              <th>Address</th>
              <th width="14%">Contact Name</th>
              <th width="14%">Tel</th>
              <th width="18%">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ detail.companyName }}</td>
              <td>{{ detail.companyAddress }}</td>
              <td>{{ detail.customerName }}</td>
              <td>{{ detail.customerPhone }}</td>
              <td>{{ detail.customerEmail }}</td>
            </tr>
          </tbody>
        </table>
        <div class="layout-2">
          <div class="title">Delivery address</div>
          <div>{{ detail.deliveryAddress }}</div>
          <div class="title">Delivery date</div>
          <div>{{ detail.daliveryDate }}</div>
        </div>

        <div v-for="(item, i) in detail.orderPriceSheetPOList" :key="i">
          <div class="layout-2">
            <div class="title">Item</div>
            <div>
              <span v-show="item.productCode">{{ item.productCode }} /</span>
              <span v-show="item.productEn">{{ item.productEn }} /</span>
              {{ item.specification }}
            </div>
            <div class="title">Qty</div>
            <div class="green">{{ item.amount }}</div>
          </div>
          <table
            class="layout-3"
            v-show="
              elementVisable([
                item.productDescription,
                item.netPrice,
                item.printingWay,
                item.setUpCharge,
                item.shippingMethod,
                item.shippingFee,
                item.totalPrice,
              ])
            "
          >
            <thead>
              <tr>
                <th v-show="elementVisable([item.productDescription])">
                  Product description
                </th>
                <th v-show="elementVisable([item.netPrice])">Product price</th>
                <th v-show="elementVisable([item.printingWay])">
                  Printing description
                </th>
                <th v-show="elementVisable([item.setUpCharge])">
                  Set up Charge
                </th>
                <th v-show="elementVisable([item.shippingMethod])">
                  Shipping Method
                </th>
                <th v-show="elementVisable([item.shippingFee])">
                  Shipping Fee
                </th>
                <th v-show="elementVisable([item.totalPrice])">Total Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-show="elementVisable([item.productDescription])">
                  {{ item.productDescription }}
                </td>
                <td v-show="elementVisable([item.netPrice])">
                  {{ item.netPrice }}
                </td>
                <td v-show="elementVisable([item.printingWay])">
                  {{ item.printingWay }}
                </td>
                <td v-show="elementVisable([item.setUpCharge])">
                  {{ item.setUpCharge }}
                </td>
                <td v-show="elementVisable([item.shippingMethod])">
                  {{ item.shippingMethod }}
                </td>
                <td v-show="elementVisable([item.shippingFee])">
                  {{ item.shippingFee }}
                </td>
                <td class="green" v-show="elementVisable([item.totalPrice])">
                  $&nbsp;{{ item.totalPrice }}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="layout-3"
            v-show="elementVisable([item.rendingsUrl, item.remark])"
          >
            <thead>
              <tr>
                <th width="65%" v-show="elementVisable([item.rendingsUrl])">
                  Renderings
                </th>
                <th width="35%" v-show="elementVisable([item.remark])">
                  Remarks
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="high">
                <td v-show="elementVisable([item.rendingsUrl])">
                  {{ item.rendingsUrl }}
                </td>
                <td v-show="elementVisable([item.remark])">
                  {{ item.remark }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom">
          <div>Remark：Prices valid for 30 days.</div>
          <div>
            Total Amount
            <span>$&nbsp;{{ detail.totalAmount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderPrintSheet, printOrderPriceSheet } from '@/api/order.js'

export default {
  props: ['offerSheetInfo'],
  data() {
    return {
      isBatch: false,
      offerSetString: '',
      priceSheetCode: '',
      enquiryIds: [],
      offerList: [],
    }
  },
  created() {},
  methods: {
    initPage(param) {
      /**
       * @description offerSetString [1,2,3,4]
       * 1:Sample Time, 2:Production Time, 3:Shipping Time, 4,内容为空的字段:
       */
      let query = param
      this.offerSetString = query.offerSetString || []
      this.enquiryIds = query.enquiryIds || this.offerSheetInfo.enquiryIds
      this.isBatch = query.isBatch ? true : false
      if (!query.isBatch) {
        this.priceSheetCode =
          query?.priceSheetCode || this.offerSheetInfo.priceSheetCode || ''
      }
      this.getPrintInfo()
    },
    // 获取打印信息
    getPrintInfo() {
      let that = this
      if (!that.isBatch) {
        // 打印
        orderPrintSheet({
          enquiryId: that.enquiryIds,
          priceSheetCode: that.priceSheetCode,
        }).then((res) => {
          that.offerList.push(res.data)
        })
      } else {
        // 批量打印
        printOrderPriceSheet({ enquiryId: that.enquiryIds.split(',') }).then(
          (res) => {
            that.offerList = res.data
            that.$nextTick(function () {
              that.$emit('print')
            })
          }
        )
      }
    },

    // 判断空元素是否显示
    elementVisable(arr) {
      let that = this
      if (that.offerSetString.includes(4)) {
        if (arr.every((item) => !item)) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
  },
}
</script>


<style lang="scss" scoped>
* {
  background: white;
}
.pdfDom {
  width: 1170px;
  margin: 0 auto;
  background: white;
  position: absolute;
  top: 500vh;
  left: 0;
  z-index: -1;
  .t-left {
    text-align: left !important;
    padding-left: 20px;
  }
  h1 {
    position: relative;
    margin: 0 auto;
    height: 100px;
    width: 1170px;
    line-height: 100px;
    text-align: center;
    font-size: 32px;
    img {
      height: 80px;
      position: absolute;
      top: 20px;
      right: 0;
    }
  }
  .container {
    padding: 0 20px 20px;
    border: 1px solid rgba(234, 235, 237, 1);
    font-size: 16px;
    .box {
      margin-bottom: 100px;
      .top {
        display: flex;
        > div {
          height: 40px;
          line-height: 40px;
          display: inline-block;
          margin-right: 20px;
          font-family: 'PingFang Bold';
          span {
            font-weight: 500;
          }
        }
      }

      .layout-1 {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        thead {
          width: 100%;
          height: 38px;
          line-height: 38px;
          border-right: none;
          background: #f5f7fa;
          th {
            border: 1px solid #ebeef5;
            color: #606266;
            text-align: center;
          }
        }
        tbody {
          tr {
            height: 50px;
          }
          td {
            min-height: 48px;
            height: 48px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #ebeef5;
          }
        }
      }

      .layout-2 {
        width: 100%;
        margin-top: 20px;
        border: 1px solid #ebeef5;
        height: 48px;
        line-height: 48px;
        display: flex;
        > div {
          border-right: 1px solid #ebeef5;
        }
        > div:nth-child(2) {
          width: 54%;
          padding-left: 20px;
        }
        > div:last-child {
          width: 18%;
          border-right: none;
          padding-left: 20px;
        }
        .title {
          width: 14%;
          text-align: center;
          font-family: 'PingFang Bold';
          background: #f5f7fa;
        }
      }

      .layout-3 {
        width: 100%;
        border-collapse: collapse;
        thead {
          width: 100%;
          height: 38px;
          line-height: 38px;
          border-right: none;
          th {
            border: 1px solid #ebeef5;
            border-top: none;
            color: #606266;
            text-align: center;
            height: 38px;
          }
        }
        tbody {
          tr {
            height: 110px;
            min-height: 110px;
          }
          .high {
            height: 180px;
            min-height: 180px;
          }
          td {
            line-height: 20px;
            text-align: center;
            border: 1px solid #ebeef5;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        > div:first-child {
          font-size: 14px;
        }
        > div:last-child {
          font-size: 16px;
          span {
            margin-left: 15px;
            color: red;
            font-family: 'PingFang Bold';
          }
        }
      }
    }
    .box:first-child {
      margin-bottom: 0;
    }
  }
}
</style>
