<!--
  功能：供应商标识组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月09日 11:00:24
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <p class="wrapper">
    <el-tag
      v-for="(item, index) in identification"
      :key="item"
      size="mini"
      :type="index === 0 ? 'primary' : 'success'"
    >
      {{ item == 0 ? '线下' : '网拍' }}
    </el-tag>
    <el-tag
      v-if="includeAagreement && isAgreement == 1"
      size="mini"
      type="danger"
    >
      协议
    </el-tag>
  </p>
</template>

<script>
  export default {
    name: 'SupplierIdentification',
    components: {},
    props: {
      value: {
        type: String,
        default: '',
      },
      includeAagreement: {
        //是否展示协议字段
        type: Boolean,
        default: true,
      },
      isAgreement: {
        type: [Number, String],
        default: 0,
      },
    },
    // 组件状态值
    data() {
      return {
        // identification: [],
      }
    },
    computed: {
      identification() {
        return this.value ? this.value.split(',') : []
      },
    },

    // watch: {
    //   value(val) {
    //     if(!val) {
    //      this.identification = []
    //     } else {
    //        this.identification  = val.split(',')
    //     }
    //   }
    // }
  }
</script>

<style scoped lang="scss">
  .wrapper {
    display: inline-block;
    margin: 0 5px;
  }
</style>
