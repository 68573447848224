var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview-warp-box"},[(_vm.fileType == 'txt' || _vm.fileType == 'TXT')?_c('i',{staticClass:"el-icon-download download-btn",on:{"click":function($event){return _vm.downloadTxt()}}}):_vm._e(),_c('i',{class:['el-icon-circle-close close', _vm.fileType],on:{"click":_vm.close}}),(
      _vm.fileType == 'png' ||
      _vm.fileType == 'jpg' ||
      _vm.fileType == 'gif' ||
      _vm.fileType == 'jpeg' ||
      _vm.fileType == 'bmp'
    )?[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"loading"},[_c('el-image',{ref:"imgSize",class:[_vm.showAuto ? 'img-box img-box-auto' : 'img-box'],attrs:{"src":_vm.fileUrl,"fit":"contain","preview-src-list":[_vm.fileUrl]},on:{"load":_vm.imgload}})],1)]:(_vm.fileType == 'pdf')?[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"loading"},[_c('iframe',{ref:"iframe",attrs:{"height":"100%","width":"100%","src":_vm.fileUrl,"frameborder":"none"}})])]:(_vm.fileType == 'txt')?[_c('el-input',{staticClass:"txt-box",attrs:{"type":"textarea","readonly":""},model:{value:(_vm.txtPre),callback:function ($$v) {_vm.txtPre=$$v},expression:"txtPre"}})]:(
      _vm.fileType == 'doc' ||
      _vm.fileType == 'docx' ||
      _vm.fileType == 'xls' ||
      _vm.fileType == 'xlsx' ||
      _vm.fileType == 'ppt' ||
      _vm.fileType == 'pptx'
    )?[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"loading"},[_c('iframe',{ref:"iframe",attrs:{"height":"100%","width":"100%","src":_vm.url,"frameborder":"none"}})])]:(
      _vm.fileType == 'mp4' ||
      _vm.fileType == 'avi' ||
      _vm.fileType == 'rm' ||
      _vm.fileType == 'rmvb' ||
      _vm.fileType == 'mov' ||
      _vm.fileType == 'qt' ||
      _vm.fileType == 'asf' ||
      _vm.fileType == 'wmv'
    )?[_c('video',{attrs:{"src":_vm.fileUrl,"controls":"","height":"100%","width":"100%"}})]:_c('a',{attrs:{"href":_vm.fileUrl,"download":""}},[_vm._m(0),_c('i',{class:['el-icon-download download-btn', _vm.fileType]})])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tips"},[_c('i',{staticClass:"el-icon-warning",staticStyle:{"font-size":"16px"}}),_vm._v(" 抱歉，该类型文件暂不支持预览 ")])}]

export { render, staticRenderFns }