<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    v-if="dialogFormVisible"
    :append-to-body="true"
    width="800px"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="中文名称" prop="nameCn">
            <el-input
              v-model="form.nameCn"
              placeholder="请输入长度1到50个字符"
              maxlength="50"
              show-word-limit
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="英文名称" prop="nameEn">
            <el-input
              v-model="form.nameEn"
              placeholder="请输入长度1到50个字符"
              maxlength="50"
              show-word-limit
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-for="(item, index) in changeArr" :key="index">
          <el-form-item prop="linkedChildIds" :label="item.nameCn">
            <el-select
              multiple
              placeholder="请选择"
              clearable
              collapse-tags
              v-model="item.linkedChildIds"
            >
              <el-option
                v-for="(j, indexj) in item.keyChildSelectVOList"
                :key="indexj"
                :label="j.nameCn"
                :value="j.keyChildId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 按钮 -->
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确认</el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { deepCopy } from 'kits'
  export default {
    name: 'LabelChildModel',
    props: {
      childLabelIds: {
        type: Array,
        required: true,
      },
      editStr: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        title: '新增标签',
        form: {
          keyChildId: '',
          nameCn: '',
          nameEn: '',
          orderNum: '',
          parentId: '',
        },
        rules: {
          nameCn: [
            { required: true, trigger: 'blur', message: '请输入中文名称' },
          ],
          nameEn: [
            { required: true, trigger: 'blur', message: '请输入英文名称' },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
        },
        dialogFormVisible: false,
        changeArr: [], //上个页面选中的关联标签
        changeArr2: [],
        editIndex: '', //修改的哪一行index
        changeArr3: [],
        chooseSelect: [],
      }
    },

    mouted() {},
    methods: {
      labelChildModel(str, chooseSelect, row, index) {
        this.changeArr = []

        if (str === '新增') {
          this.title = '新增标签'
          chooseSelect.forEach((item) => {
            let obj = {
              nameCn: item.nameCn,
              childId: '',
              keyLinkageId: '', //当前id
              linkedParentId: item.keyParentId, //这个标签的父id
              linkedChildIds: [], //选中的ids
              keyChildSelectVOList: item.keyChildSelectVOList,
            }
            this.changeArr.push(obj)
          })
        } else {
          //1、编辑分为编辑的编辑和新增编辑
          this.title = '编辑标签'
          this.editIndex = index
          this.form = deepCopy(row)

          let rowList = row.keyLinkageEditVOList || row.keyLinkageEditDTOList
          let newArray = []
          rowList.forEach((item, i) => {
            chooseSelect.forEach((obj, k) => {
              if (item.linkedParentId === obj.keyParentId) {
                newArray.push({
                  nameCn: obj.nameCn,
                  nameEn: obj.nameEn,
                  linkedParentId: obj.keyParentId,
                  linkedChildIds: item.linkedChildIds
                    ? item.linkedChildIds.split(',')
                    : '',
                  keyChildSelectVOList: obj.keyChildSelectVOList,
                })
              }
            })
          })

          this.changeArr = newArray
        }
        this.dialogFormVisible = true
      },

      // 关闭
      close() {
        this.editIndex = ''
        this.$refs['form'].resetFields()
        this.form = {}
        //this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let changeArr = deepCopy(this.changeArr)
            changeArr = changeArr.map((element) => {
              return {
                linkedParentId: element.linkedParentId,
                linkedChildIds: element.linkedChildIds.toString(),
              }
            })
            this.form = {
              ...this.form,
              keyLinkageEditDTOList: changeArr,
            }
            delete this.form.keyLinkageEditVOList
            //新增
            this.$emit('deliver-data', this.form, this.editIndex, this.title)
            this.close()
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
