<template>
  <div class="pro-container">
    <el-tabs
      tab-position="left"
      style="height: 100%"
      @tab-click="changeTab"
      v-model="activeTab"
    >
      <el-tab-pane
        v-for="item in configs"
        :key="item.key"
        :label="item.label"
        :name="item.name"
      >
        <component :is="item.comp" :ref="`configItem${item.name}`" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CustomFees from './components/custom-fees'
import PriceLimit from './components/price-limit'

export default {
  components: {
    CustomFees,
    PriceLimit,
  },
  data() {
    return {
      activeTab: '1',
      configs: [
        {
          label: '自定义费用',
          name: '1',
          comp: CustomFees,
        },
        {
          label: '报价限制',
          name: '2',
          comp: PriceLimit,
        },
      ],
    }
  },
  methods: {
    changeTab({ name }) {
      const comp = this.$refs['configItem' + name][0]
      if (comp && typeof comp.init === 'function') {
        comp.init()
      }
    },

    initTab() {
      const { tab } = this.$route.query
      if (tab) {
        this.activeTab = tab
      }
    },
  },

  created() {
    this.initTab()
  },
}
</script>

<style lang="scss" scoped>
.pro-container {
  ::v-deep {
    .el-tabs__item {
      height: 60px !important;
      line-height: 60px !important;
      padding: 0 40px;
    }
    .is-active {
      background-color: #ebf5ff;
    }
  }
}
</style>
