<template>
  <el-row class="detail-container">
    <div class="content">
      <component :is="currentComponent.component" :detailId="detailId" :isAudit="operation"
        :ref="currentComponent.nameEn" :payType="
          currentComponent.nameEn === 'PaymentDetailModel' ? payType : ''
        " :passRow="passRow" @warehouseId="getWarehouseId" @ids="getIds"></component>
    </div>

    <el-row class="operate" v-if="operation === '1'">
      <!-- 批量审核:审核 -->
      <el-popover v-model="popoverVisible1" placement="bottom" :title="
          isBatch ? $t('auditManage.BatchAudit') : $t('auditManage.Audit')
        " width="500" height="300" trigger="manual" offset="-200" ref="cancel1" v-clickoutside="cancelClick1">
        <el-row>
          <el-form label-position="top">
            <!-- 同意 -->
            <el-form-item :label="$t('auditManage.Agreement')" prop="remark">
              <el-input type="textarea" :placeholder="$t('placeholder.plsInput')" v-model="remark1" maxlength="500"
                show-word-limit :autosize="{ minRows: 6, maxRows: 8 }"></el-input>
            </el-form-item>
          </el-form>
          <el-row style="text-align: right">
            <el-button type="primary" @click="auditClickConfirm1" :loading="loading">
              <!-- 加载中:确认 -->
              {{
              loading ? $t('auditManage.Loading') : $t('auditManage.Confirm')
              }}
            </el-button>
            <!-- 取消 -->
            <el-button @click="cancelClick1">
              {{ $t('auditManage.Cancel') }}
            </el-button>
          </el-row>
        </el-row>
        <!-- 同意 -->
        <el-button slot="reference" type="primary" @click="handleCheckWarehousrLock('popoverVisible1')">
          {{ $t('auditManage.Agreement') }}
        </el-button>
      </el-popover>
      <!-- 批量驳回:驳回 -->
      <el-popover v-model="popoverVisible2" placement="bottom" :title="
          isBatch
            ? $t('auditManage.RejectionInBatches')
            : $t('auditManage.TurnDown')
        " width="500" height="300" trigger="manual" offset="-200" ref="cancel2" v-clickoutside="cancelClick2">
        <el-row>
          <el-form label-position="top">
            <el-form-item prop="remark">
              <template slot="label">
                <i style="color: #ff4d4f">*</i>
                <!-- 驳回意见 -->
                {{ $t('auditManage.OpinionOfRejection') }}
              </template>
              <el-input type="textarea" :placeholder="$t('placeholder.plsInput')" v-model="remark2" maxlength="500"
                show-word-limit :autosize="{ minRows: 6, maxRows: 8 }"></el-input>
            </el-form-item>
          </el-form>
          <el-row style="text-align: right">
            <el-button type="primary" @click="auditClickConfirm2">
              <!-- 确认 -->
              {{ $t('auditManage.Confirm') }}
            </el-button>
            <el-button @click="cancelClick2">
              <!-- 取消 -->
              {{ $t('auditManage.Cancel') }}
            </el-button>
          </el-row>
        </el-row>
        <el-button slot="reference" @click="handleCheckWarehousrLock('popoverVisible2')">
          <!-- 驳回 -->
          {{ $t('auditManage.TurnDown') }}
        </el-button>
      </el-popover>

      <!--批量备注-->
      <el-popover placement="bottom" :title="$t('auditManage.BatchRemarks')" width="500" height="300" trigger="click"
        offset="-200" ref="cancel3">
        <el-row>
          <el-form label-position="top">
            <el-form-item label="" prop="remark">
              <el-input type="textarea" :placeholder="$t('placeholder.plsInput')" v-model="batchRemark" maxlength="500"
                show-word-limit :autosize="{ minRows: 6, maxRows: 8 }"></el-input>
            </el-form-item>
          </el-form>
          <el-row style="text-align: right">
            <el-button type="primary" @click="setBatchRemark">
              <!-- 确认 -->
              {{ $t('auditManage.Confirm') }}
            </el-button>
            <el-button @click="cancelClick3">
              {{ $t('auditManage.Cancel') }}
            </el-button>
          </el-row>
        </el-row>
        <el-button slot="reference" type="primary" v-if="isBatch">
          <!-- 批量备注 -->
          {{ $t('auditManage.BatchRemarks') }}
        </el-button>
      </el-popover>
    </el-row>
    <el-row class="operate" v-else>
      <el-button @click="closeClick">{{ $t('auditManage.Cancel') }}</el-button>
    </el-row>
  </el-row>
</template>

<script>
  import OrderDetail from './order-detail'
  import PurchasesDetail from './purchases-detail'
  import PurchaseOrderDetail from './purchases-order-detail'
  import InboundSheetDetail from '@/views/stock-center/inbound-sheet/inbound-sheet-detail'
  import OutboundSheetDetail from '@/views/stock-center/outbound-sheet/outbound-sheet-detail'
  import PaymentDetailModel from './payment-detail'
  import FetDetailModel from './fet-detail'
  import ProductDetail from './product-detail'
  import CustomerServiceDetail from './customer-service-detail'
  import SupplierDetail from './supplier-detail'
  import PutPayDetail from './putPay-detail'
  import SettleDetail from './settle-detail.vue'
  import SettleDetailAdjust from './settle-detail-adjust.vue'
  import payeeManageDetail from './payee-manage-detail.vue'
  import costAdjustDetail from '@/views/stock-center/cost-adjustment/detail.vue'
  import CustomerCenterDetail from './customer-center-detail.vue'
  import CustomerCenterAudit from './customer-center-audit.vue'
  import AccountPeroid from './customer-center-accountperoid.vue'

  import BlackList from './black-list'
  import HighSea from './highsea'
  import StockCheckEdit from '@/views/stock-center/stock-check/stock-check-detail'
  import AllotInquireDetail from '@/views/stock-center/allot-inquire/detail'
  import { mapGetters } from 'vuex'
  import { AuditInteractor, StoreCheck } from '@/core'

  export default {
    name: 'Detail',
    props: {
      leftCurrentText: {
        type: String, //当前审核模块名称
        required: true,
      },
      payType: {
        type: Number, //只有付款才有值
        required: false,
        default: null,
      },
      detailId: {
        //传递给详情的id
        type: [Number, Object, String],
        required: true,
      },
      operation: {
        //是否显示审核按钮
        type: String,
        required: false,
        default: '1',
      },
      passRow: {
        type: [Object, Array],
        required: true,
      },
      auditObjectId: {
        type: String,
        default: '0',
      },
      isBatch: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      OrderDetail,
      PurchasesDetail,
      PurchaseOrderDetail,
      InboundSheetDetail,
      OutboundSheetDetail,
      FetDetailModel,
      PaymentDetailModel,
      ProductDetail,
      CustomerServiceDetail,
      SupplierDetail,
      PutPayDetail,
      SettleDetail,
      SettleDetailAdjust,
      costAdjustDetail,
      CustomerCenterDetail,
      CustomerCenterAudit,
      BlackList,
      HighSea,
      StockCheckEdit,
      AllotInquireDetail,
      AccountPeroid,
    },
    data() {
      return {
        remark1: '',
        remark2: '',
        batchRemark: '',
        loading: false,
        popoverVisible1: false, // 同意审核弹框显隐
        popoverVisible2: false, // 驳回审核弹框显隐
      }
    },
    computed: {
      currentComponent() {
        let obj = this.getComponents().find((ele) => {
          return ele.nameCN === this.leftCurrentText
        })
        return obj
      },
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
    },
    methods: {
      // 设置批量备注
      setBatchRemark() {
        if (this.batchRemark.trim() === '') {
          return
        }
        this.$refs.SettleDetail.tableData.forEach((item) => {
          this.$set(item, 'auditRemark', this.batchRemark.trim())
        })
        this.$refs.cancel3.doClose()
        this.batchRemark = ''
      },
      cancelClick3() {
        this.$refs.cancel3.doClose()
        this.batchRemark = ''
      },
      getComponents() {
        let arr = [
          {
            nameCN: '订单管理',
            component: OrderDetail,
            nameEn: 'OrderDetail',
          },
          {
            nameCN: '采购备货',
            component: PurchasesDetail,
            nameEn: 'PurchasesDetail',
          },
          {
            nameCN: '采购订单',
            component: PurchaseOrderDetail,
            nameEn: 'PurchaseOrderDetail',
          },
          {
            nameCN: '入库管理',
            component: InboundSheetDetail,
            nameEn: 'InboundSheetDetail',
          },
          {
            nameCN: '出库管理',
            component: OutboundSheetDetail,
            nameEn: 'OutboundSheetDetail',
          },
          {
            nameCN: '产品管理',
            component: ProductDetail,
            nameEn: 'ProductDetail',
          },
          {
            nameCN: '付款管理',
            component: PaymentDetailModel,
            nameEn: 'PaymentDetailModel',
          },
          {
            nameCN: '费用管理',
            component: FetDetailModel,
            nameEn: 'FetDetailModel',
          },
          {
            nameCN: '售后管理',
            component: CustomerServiceDetail,
            nameEn: 'CustomerServiceDetail',
          },
          {
            nameCN: '供应商管理',
            component: SupplierDetail,
            nameEn: 'SupplierDetail',
          },
          {
            nameCN: '收付款方式',
            component: PutPayDetail,
            nameEn: 'PutPayDetail',
          },
          {
            nameCN: '订单结算',
            component: SettleDetail,
            nameEn: 'SettleDetail',
          },
          {
            nameCN: '结算调整',
            component: SettleDetailAdjust,
            nameEn: 'SettleDetail',
          },
          {
            nameCN: '收款管理',
            component: payeeManageDetail,
            nameEn: 'payeeManageDetail',
          },
          {
            nameCN: '成本调整',
            component: costAdjustDetail,
            nameEn: 'costAdjustDetail',
          },
          {
            nameCN: '客评中心',
            component: CustomerCenterDetail,
            nameEn: 'costAdjustDetail',
          },
          {
            nameCN: '客户审核',
            component: CustomerCenterAudit,
            nameEn: 'customerAudit',
          },
          {
            nameCN: '客户黑名单审核',
            component: BlackList,
            nameEn: 'customerAudit',
          },
          {
            nameCN: '公海审核',
            component: HighSea,
            nameEn: 'customerAudit',
          },
          {
            nameCN: '调拨审核',
            component: AllotInquireDetail,
            nameEn: 'AllotInquireDetail',
          },
          {
            nameCN: '盘点审核',
            component: StockCheckEdit,
            nameEn: 'StockCheckDetail',
          },
          {
            nameCN: '客户账期',
            component: AccountPeroid,
            nameEn: 'AccountPeroid',
          },
        ]
        return arr
      },
      //提交审核备注
      submitAuditRemark() {
        let arr = []
        arr = this.$refs.SettleDetail.tableData.map((item) => {
          return {
            auditUserName: this.userInfo.userName,
            settleAuditRemark: item.auditRemark ? item.auditRemark : '',
            settlementSalesOrderId: item.settlementSalesOrderId,
          }
        })
        AuditInteractor.updateSettleRemarkApi(arr).then((res) => { })
      },
      //同意的取消
      cancelClick1() {
        this.$refs.cancel1.doClose()
      },
      //驳回的取消
      cancelClick2() {
        // this.$refs.PurchaseOrderDetail.sendMail()
        this.$refs.cancel2.doClose()
      },
      //同意确认
      auditClickConfirm1() {
        if (this.currentComponent.nameEn === 'SettleDetail') {
          this.submitAuditRemark()
        }
        if (this.isBatch) {
          this.batchAudit(this.remark1, 2)
        } else {
          this.audit(this.remark1, 2)
        }
      },
      //驳回确认
      auditClickConfirm2() {
        if (this.remark2 === '') {
          // 请输入您的审核驳回意见
          return this.$baseMessage(
            this.$t('auditManage.Tips3'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        if (this.isBatch) {
          this.batchAudit(this.remark2, 3)
        } else {
          this.audit(this.remark2, 3)
        }

        if (this.currentComponent.nameEn === 'SettleDetail') {
          this.submitAuditRemark()
        }
      },
      _filterPassRow() {
        const settlemenOrders = Array.from(
          new Set(
            this.$refs.SettleDetail.$refs.table.form.tableData.map(
              (i) => i.settlementOrder
            )
          )
        )
        return this.passRow.filter((item) =>
          settlemenOrders.includes(item.settlementOrder)
        )
      },
      async batchAudit(remark, num) {
        this.loading = true
        let passRows = this._filterPassRow()
        if (Array.isArray(passRows)) {
          passRows.forEach((passRow) => {
            passRow.auditStatus = num
            passRow.remarks = remark
          })
        }
        let response = await AuditInteractor.batchAuditApi(passRows)
        if (response.code === '000000') {
          // 操作成功
          this.$baseMessage(
            this.$t('reqmsg.$7'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.closeClick()
        } else {
          this.loading = false
        }
      },

      //审核
      async audit(remark, num) {
        this.loading = true
        this.passRow.auditStatus = num
        this.passRow.remarks = remark
        let response = await AuditInteractor.auditApi(this.passRow)
        if (response?.code === '000000') {
          // 操作成功
          this.$baseMessage(
            this.$t('reqmsg.$7'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.closeClick()
        } else {
          this.loading = false
        }
      },
      //关闭抽屉
      closeClick() {
        this.$emit('handleClose')
        this.loading = false
      },
      // 入库管理，出库管理详情接口返回的warehouseId
      getWarehouseId(id) {
        this.warehouseId = id
      },
      // 调拨审核详情接口返回的warehouseInId, warehouseOutId
      getIds(ids) {
        this.allotInquireDetailIds = ids
      },
      // 点击同意/驳回按钮，校验仓库是否被锁
      async handleCheckWarehousrLock(popoverVisible) {
        const list = ['入库管理', '出库管理', '调拨审核']
        if (list.includes(this.leftCurrentText)) {
          try {
            const warehouseId = this.leftCurrentText === '调拨审核' ? this.allotInquireDetailIds : this.warehouseId
            const { code } = await StoreCheck.auditCheckWarehousrLockApi(warehouseId)
            if (code === '000000') {
              this[popoverVisible] = true
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this[popoverVisible] = true
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .detail-container {
    .content {
      margin-bottom: 100px;
    }
  }

  .operate {
    height: 60px;
    line-height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 40px;
    background: #fff;
    border-top: 1px solid #ededed;
    z-index: 1000;
  }
</style>
