<template>
  <el-dialog
    title="金额设置"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :rules="rules" :model="form" label-width="80px">
      <el-form-item label="币种" prop="currency">
        <el-select
          v-model="form.currency"
          placeholder="请选择"
          style="width: 100%"
        >
          <!-- <el-option label="人民币" value="人民币"></el-option>
          <el-option label="美元" value="美元"></el-option> -->
          <el-option
            v-for="item in currency"
            :label="item.financeName"
            :value="item.financeName"
            :key="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预警金额" prop="money">
        <el-input v-model="form.money" placeholder="请选择"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>
<script>
  import { customerWarningUpdate } from '@/api/client-supplier/client-setting.js'

  export default {
    name: 'ClientSettingLimit',
    props: {
      currency: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        loading: false,
        dialogFormVisible: false,

        form: {
          currency: '',
          money: '',
          customerWarningId: '',
        },
        rules: {
          currency: [
            { required: true, message: '请选择币种', trigger: 'change' },
          ],
          money: [
            { required: true, message: '请输入金额', trigger: 'blur' },
            {
              validator: this.$formValidation.isPositiveInt,
              trigger: 'blur',
            },
          ],
        },
      }
    },
    methods: {
      showDialog(data) {
        this.dialogFormVisible = true
        this.form = Object.assign({}, data)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            // this.$baseMessage(msg, 'success', false, 'vab-hey-message-success')
            let { currency, customerWarningId, money } = this.form
            this.loading = true
            customerWarningUpdate({
              currency,
              customerWarningId,
              money,
            }).then((res) => {
              this.loading = false
              if (res.code === '000000') {
                this.$message.success('修改成功！')
                this.$emit('success')
                this.close()
              }
            })
          }
        })
      },
      close() {
        this.dialogFormVisible = false
      },
    },
    created() {},
  }
</script>
