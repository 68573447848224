var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tpl-new-wrapper"},[_vm._m(0),_c('el-form',{ref:"form",attrs:{"rules":_vm.rules,"model":_vm.form}},[_c('el-table',{staticClass:"form-rule-table",attrs:{"data":_vm.form.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"label":"地区","prop":"area","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.area',"rules":[
              {
                required: true,
                message: '请选择区域',
                trigger: 'change',
              } ]}},[_c('el-select',{attrs:{"clearable":""},model:{value:(scope.row.area),callback:function ($$v) {_vm.$set(scope.row, "area", $$v)},expression:"scope.row.area"}},_vm._l((_vm.areas),function(a,index){return _c('el-option',{key:index,attrs:{"label":a.areaName,"value":a.areaName}})}),1)],1)]}}])},[_c('template',{slot:"header"},[_c('i',{staticClass:"red"},[_vm._v("*")]),_vm._v(" 地区 ")])],2),_c('el-table-column',{attrs:{"label":"产品类型","prop":"standardCustomized","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.standardCustomized',"rules":[
              {
                required: true,
                message: '请选择产品类型',
                trigger: 'change',
              } ]}},[_c('el-select',{attrs:{"clearable":""},model:{value:(scope.row.standardCustomized),callback:function ($$v) {_vm.$set(scope.row, "standardCustomized", $$v)},expression:"scope.row.standardCustomized"}},_vm._l((_vm.productTypes),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.name}})}),1)],1)]}}])},[_c('template',{slot:"header"},[_c('i',{staticClass:"red"},[_vm._v("*")]),_vm._v(" 产品类型 ")])],2),_c('el-table-column',{attrs:{"label":"毛利额","prop":"conditionType","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.conditionType',"rules":[
              {
                required: true,
                message: '请选择毛利额',
                trigger: 'change',
              } ]}},[_c('el-select',{attrs:{"disabled":""},model:{value:(scope.row.conditionType),callback:function ($$v) {_vm.$set(scope.row, "conditionType", $$v)},expression:"scope.row.conditionType"}},_vm._l((_vm.conditionTypes),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"运营成本(%)","prop":"ratio","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' + scope.$index + '.ratio',"rules":[
              {
                required: true,
                message: '请输入运营成本',
                trigger: ['change','blur'],
              } ]}},[_c('el-input-number',{attrs:{"controls":false,"min":0,"max":100,"clearable":"","precision":2},model:{value:(scope.row.ratio),callback:function ($$v) {_vm.$set(scope.row, "ratio", $$v)},expression:"scope.row.ratio"}})],1)]}}])},[_c('template',{slot:"header"},[_c('i',{staticClass:"red"},[_vm._v("*")]),_vm._v(" 运营成本(%) ")])],2),_c('el-table-column',{attrs:{"label":"操作","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(scope.$index)}}},[_vm._v("删除")])]}}])})],1)],1),(!_vm.$route.query.businessId)?_c('el-button',{staticClass:"new-btn",on:{"click":_vm.addLine}},[_vm._v(" 新增一行 ")]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',[_c('span',{staticClass:"vertical"}),_vm._v(" 地区模板配置 ")])}]

export { render, staticRenderFns }