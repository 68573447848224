<!--
  功能：功能描述
  作者：jiangyongqiang
  时间：2021年11月16日 19:52:21
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="box-card">
    <div class="header">
      <span class="title">{{ title }}</span>
      <div class="operation" v-if="!noOperation">
        <i class="iconfont icon-yidongdao handle" title="拖动排序"></i>
        <i class="iconfont icon-yichu" title="关闭" @click="handleRemove"></i>
      </div>
    </div>
    <div class="role-tabs" v-if="tabs">
      <span
        class="role-tabs--me text"
        :class="{ active: current === 'ME' }"
        @click="tabClick('ME')"
      >
        我的
        <span class="count-tag" :class="{ active: current === 'ME' }">
          <span>{{ singleNum }}</span>
        </span>
      </span>
      <span
        class="role-tabs--group text"
        :class="{ active: current === 'GROUP' }"
        @click="tabClick('GROUP')"
      >
        团队
        <span class="count-tag" :class="{ active: current === 'GROUP' }">
          <span>{{ teamNum }}</span>
        </span>
      </span>
    </div>

    <div
      :key="current"
      class="card-content"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="transparent"
      :style="{ height: tabs === true ? '233px' : '277px' }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexCard',
  props: {
    title: {
      type: String,
      default: '快捷入口',
    },

    noOperation: {
      type: Boolean,
      default: false,
    },

    tabs: {
      //是否展示我的团队tab
      type: Boolean,
      default: false,
    },

    singleNum: {
      type: Number,
      default: 0,
    },
    teamNum: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {},

  data() {
    return {
      current: 'ME',
    }
  },
  computed: {},

  methods: {
    /**
     * @paams{Number} type 类型 ME我的 GROUP团队
     *
     */
    tabClick(type) {
      this.current = type
      this.$emit('tab-click', type)
    },

    handleRemove() {
      this.$parent.remove()
    },
  },

  created() {},
}
</script> 

<style scoped lang="scss">
.box-card {
  background-color: #fff;
  padding: 20px;
  padding-top: 16px;
  padding-bottom: 0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
    .title {
      height: 22px;
      font-size: 14px;
      font-weight: 600;
      color: #282c34;
      line-height: 22px;
    }
  }
  .role-tabs {
    margin: 15px 0;
    margin-bottom: 0;
    margin-right: 8px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
    span.text {
      display: inline-block;
      border-top: 1px solid #eaeaea;
      padding: 2px 5px;
      cursor: pointer;
      color: #999999;
      &.active {
        background-color: #dff0ff;
        color: #236ce9;
      }
    }
    .role-tabs--me {
      border-top-left-radius: 8px;
      border-left: 1px solid #eaeaea;
    }
    .role-tabs--group {
      border-top-right-radius: 8px;
      border-right: 1px solid #eaeaea;
    }
    .count-tag {
      border-radius: 17px;
      background: #ffeaea;
      border: 1px solid #ffcccc;
      color: #f56c6c;
      display: inline-block;
      padding: 0 8px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #fff;
      transform: scale(0.83);
      span {
        display: inline-block;
        font-size: 12px;
        line-height: 12px;
      }
      &.active {
        background-color: #f56c6c;
        color: #fff;
      }
    }
  }
  .card-content {
    height: 253px;
    margin-right: -8px;
    padding-right: 8px;

    padding-top: 15px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .operation {
    i.icon-yidongdao {
      color: #666;
    }
    i.icon-yichu {
      color: #f56c6c;
    }
    .handle {
      margin-right: 15px;
    }
    i {
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
