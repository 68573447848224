<!--
  功能：发货邮件内容
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年05月12日 13:12:22
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div
    class="mail-content"
    style="padding-bottom: 100px"
    :style="styles.baseStyle"
  >
    <p>
      <span :style="styles.baseStyle">
        <span style="margin-right: 5px">Hi</span>
        {{ config.customerName }},
      </span>
    </p>
    <br />
    <p>
      <span :style="styles.baseStyle">
        <!-- <span style="margin-right: 5px">Hi</span> -->
        Your PO&nbsp;&nbsp;
        {{ config.poNumber }}&nbsp;&nbsp;our PI&nbsp;&nbsp;{{
          config.piNumber
        }}&nbsp;&nbsp;has been shipped on&nbsp;&nbsp;{{
          config.shipTime && config.shipTime.split(' ')[0]
        }},
      </span>
    </p>
    <br />

    <div v-if="config.shipNos && config.shipNos.length > 0">
      <p :style="styles.baseStyle">Here is your tracking #:</p>
      <div v-for="(item, index) in config.shipNos" :key="index">
        <p>
          <span :style="styles.baseStyle">{{ item.shipNo }}&nbsp;</span>
          <span :style="styles.baseStyle">by&nbsp;</span>
          <span :style="styles.baseStyle">{{ item.shippingMethod }},</span>
          <span :style="styles.baseStyle">It includes</span>
          <span :style="styles.baseStyle">
            {{ item.caseNum }}
          </span>
          <span :style="styles.baseStyle">cartons and</span>
          <span :style="styles.baseStyle">&nbsp;ETA</span>
          <span :style="styles.baseStyle">
            {{ item.arriveTime ? item.arriveTime.substr(0, 10) : '' }}.
          </span>
        </p>
      </div>
    </div>

    <p>
      <span :style="styles.baseStyle">Please find</span>
      <span
        :style="styles.baseStyle"
        v-if="config.invoiceUrl && config.checkinnvoice"
      >
        the final invoice and
      </span>
      <span :style="styles.baseStyle">
        the production image for your record.
      </span>
    </p>
    <br />
    <p>
      <span :style="styles.baseStyle">
        If you have any questions，please feel free to contact us.
      </span>
    </p>
    <br />
    <p>
      <span :style="styles.baseStyle">Thank you!</span>
    </p>
    <br />

    <br />

    <p>
      <span :style="styles.baseStyle">
        ------------------------------------------------------------------------------------
      </span>
    </p>

    <!-- 公司信息 -->
    <p>
      <span :style="styles.baseStyle">Best Regards,</span>
    </p>
    <p>
      <span :style="styles.baseStyle">
        {{ header.companyName }}
      </span>
    </p>
    <p>
      <span :style="styles.baseStyle">
        {{ header.addressFirstLine }}
      </span>
    </p>
    <p>
      <span :style="styles.baseStyle">
        {{ header.addressSecondLine }}
      </span>
    </p>
    <p>
      <span :style="styles.baseStyle">
        {{ header.tel }}
      </span>
    </p>
    <p>
      <span :style="styles.baseStyle">
        {{ header.fax }}
      </span>
    </p>

    <p v-if="config.isRp" s:style="styles.baseStyle">
      <image
        src="https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/erp/user/164802188368739160.png"
      ></image>
    </p>
    <p v-if="config.isRp" :style="styles.baseStyle">
      SAGE #: 68786 PPAI #: 467928
    </p>
    <p v-if="config.isRp" :style="styles.baseStyle">
      <span style="color: #4b7902">Check us out below!</span>
    </p>
    <p v-if="config.isRp" :style="styles.baseStyle">
      <a
        style="text-decoration: underline; color: #ab1a2d; padding-bottom: 20px"
        href="https://www.riverspromo.com"
        target="_blank"
      >
        https://www.riverspromo.com
      </a>
    </p>

    <p v-if="config.isSW" :style="styles.baseStyle">
      <a
        style="text-decoration: underline; color: #ab1a2d; padding-bottom: 20px"
        href="http://www.slightworlds.com"
        target="_blank"
      >
        http://www.slightworlds.com
      </a>
    </p>

    <p v-if="config.isRg" :style="styles.baseStyle">
      <a
        style="text-decoration: underline; color: blue"
        href="https://www.riversgarment.com"
        target="_blank"
      >
        https://www.riversgarment.com
      </a>
    </p>
  </div>
</template>

<script>
  function createStyle() {
    return {
      baseStyle: {
        // color: 'rgb(51, 51, 51)',
        fontSize: '14px',
      },
    }
  }

  export default {
    name: 'MailContent',

    data() {
      return {
        styles: createStyle(),
      }
    },
  }
</script>

<style scoped></style>
