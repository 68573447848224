<template>
  <el-dialog
    :title="title"
    :append-to-body="true"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="印刷条件" prop="nameCn">
            <el-input
              v-model="form.nameCn"
              disabled
              placeholder="输入印刷条件"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20">
          <el-form-item style>
            <template slot="label">
              <i style="color: #ff4d4f">*</i>
              计价方式
            </template>
            <el-input
              v-model="tagValue"
              @keyup.enter.native="addClick"
              placeholder="输入计价方式值"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button @click="addClick">新增</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="mt10" style="margin-left: 90px">
      <el-tag
        v-for="(item, index) in pricingValue"
        :key="item + index"
        size="medium"
        closable
        @close="handleClose(item)"
      >
        {{ item }}
      </el-tag>
    </el-row>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/product/productSetting'
  export default {
    name: 'ProValutionModel',
    data() {
      return {
        loading: false,
        title: '计价方式设置',
        form: {
          productSettingId: '',
          type: 2,
          value: '',
        },
        rules: {
          nameCn: [
            { required: true, trigger: 'blur', message: '请输入印刷条件' },
          ],
        },
        dialogFormVisible: false,
        tagValue: '',
        tags: [],
        pricingValue: [],
      }
    },
    created() {},
    methods: {
      //展示弹框
      valutionModel(pricingName, pricingValue, pricingId) {
        this.form.nameCn = pricingName
        this.form.productSettingId = pricingId
        this.pricingValue = pricingValue.map((element) => {
          return element.pricingValue
        })
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.tagValue = ''
        //this.tags = []
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      // 新增
      addClick() {
        if (this.tagValue === '') {
          this.$baseMessage(
            '计价方式不能为空',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          if (this.pricingValue.includes(this.tagValue)) {
            this.tagValue = ''
            return this.$baseMessage(
              '该计价方式已存在，请勿重复添加！',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
          this.pricingValue.push(this.tagValue)
        }
        this.tagValue = ''
      },
      // 标签删除
      handleClose(tag) {
        this.pricingValue.splice(this.pricingValue.indexOf(tag), 1)
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.pricingValue.length === 0) {
              return this.$baseMessage(
                '计价方式值不能为空',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            } else {
              let arrConcat = this.pricingValue
              this.form.value = arrConcat.join(',')
              let response
              this.loading = true
              if (this.form.productSettingId === '') {
                response = await insertPO(this.form)
                this.loading = false
                if ((response.code = '000000')) {
                  this.$emit('fetch-data')
                  this.$baseMessage(
                    '操作成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                }
              } else {
                response = await updatePO(this.form)
                this.loading = false
                if ((response.code = '000000')) {
                  this.$emit('fetch-data')
                  this.$baseMessage(
                    '操作成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                }
              }

              this.close()
            }
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-tag {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
</style>
