<!--
  功能：站内信 
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年07月26日 10:56:58
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <SiteMsgCommon
    v-if="list.length > 0"
    title="待办"
    title-color="red"
    :z-index="15"
    @close="onClose"
  >
    <div v-for="(item, index) in list" :key="index">
      <p>{{ index + 1 }}、站内信时间：{{ item.createTime }}</p>
      <p>
        节点&lt;{{ item.nodeName }}>{{ item.content }},请立刻去处理。
        <span class="page-link noline" @click="handle">立即处理>></span>
        也可以稍后在【首页】——【订单跟踪】待办页签中去处理。请及时处理待办，系统预警系统一直在检测中...
      </p>
    </div>
  </SiteMsgCommon>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import SiteMsgCommon from './site-message-common'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

  export default {
    name: 'SiteMessageTodo',
    computed: {
      ...mapGetters({
        list: 'siteMsg/trackingTodo',
      }),
    },
    components: {
      SiteMsgCommon,
    },
    methods: {
      ...mapActions({ clear: 'siteMsg/setTrackingTodo' }),
      onClose() {
        _hmt &&
          _hmt.push([
            '_trackEvent',
            '【站内消息待办】点击关闭',
            '关闭',
            // opt_label,
            // opt_value,
          ])
        this.clear([])
      },
      handle() {
        _hmt.push([
          '_trackEvent',
          '【站内消息提醒】点击立即处理',
          '立即处理',
          // opt_label,
          // opt_value,
        ])
        navigateTo(this, {
          pageType: PAGE_TYPES.trackUpdate,
          roleJudge: false,
          query: {},
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .site-msg-wrapper {
    .main {
      margin-top: 10px;
      max-height: 200px;
      overflow: auto;
      div {
        margin-bottom: 10px;
      }
    }
  }
  .noline {
    text-decoration: none;
  }
</style>
