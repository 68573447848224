<template>
  <div class="custom">
    <el-row class="base-info-wrapper">
      <el-col :span="8">
        <em style="width: 85px">盘点仓库：</em>
        <span style="width: calc(100% - 85px)">
          {{ detail.warehouseName || '--' }}
        </span>
      </el-col>
      <el-col :span="8">
        <em>盘点人：</em>
        <span>
          {{ detail.takingUser || '--' }}
        </span>
      </el-col>
      <el-col :span="8">
        <em>创建人:</em>
        <span>{{ detail.creator || '--' }}</span>
      </el-col>
    </el-row>
    <el-row class="mt10">


      <el-col :span="8">
        <em style="width: 85px;display: inline-block;">盘点方式：</em>
        <span style="width: calc(100% - 85px);display: inline-block;">
          {{ detail.takingRange |takingRangeFilter }}
        </span>

      </el-col>
      <el-col :span="8" v-if="detail.takingRange === 1">


        <em style="width: 85px;display: inline-block;">库存分类：</em>
        <span style="width: calc(100% - 85px);display: inline-block;">
          {{ detail.stockUsableType |stockUsableTypeFilter }}
        </span>

      </el-col>
    </el-row>
    <el-row class="mt10">
      <el-col :span="18" style="display: flex">
        <em style="width: 85px; display: block">备注：</em>
        <span style="width: calc(100% - 85px)">
          {{ detail.remark || '--' }}
        </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  let takingRangeList = [{
    code: 1,
    name: '全部盘点'
  }, {
    code: 0,
    name: '部分盘点'
  }]

  let stockUsableTypeList = [{
    code: 1,
    name: '可用库存'
  }, {
    code: 0,
    name: '不可用库存'
  }, {
    code: 2,
    name: '全部库存'
  }]
  export default {
    name: 'StockCheckEditBaseInfo',
    props: {
      detail: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        diabled: true,
      }
    },
    filters: {
      takingRangeFilter(val) {
        return takingRangeList.find(s => s.code == val)?.name
      },
      stockUsableTypeFilter(val) {
        return stockUsableTypeList.find(s => s.code == val)?.name
      }
    }

  }
</script>

<style lang="scss" scoped>
  .base-info-wrapper {
    em {
      display: inline-block;
      width: 85px;
    }

    span {
      display: inline-block;
    }
  }
</style>
