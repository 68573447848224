<!-- 报价单列表 -->
<template>
  <div class="custom-container" v-if="$route.meta.showParent">
    <!-- 发邮件 -->
    <div
      class="pdf-wrapper"
      style="position: absolute; left: -2000px; top: -2000px"
    >
      <InquiryPrint
        ref="InquiryPrint"
        @rendered="domToPdf"
        @rendered-pdf="domToPdfPrint"
        id="pdfEl"
        style="width: 914px"
      />
    </div>
    <!-- 搜索 -->
    <div>
      <!-- 报价单号 -->
      <el-input
        clearable
        :placeholder="$t('inquiryDetails.quotationnumber')"
        class="w-150 mr-10"
        style="width: 128px"
        v-model="form.priceSheetCode"
      ></el-input>
      <!-- 询盘单号 -->
      <el-input
        clearable
        :placeholder="$t('inquiryDetails.InquiryNumber')"
        class="w-150 mr-10"
        style="width: 110px"
        v-model="form.enquiryCode"
      ></el-input>
      <!-- 客户邮箱 -->
      <el-input
        clearable
        :placeholder="$t('inquiryDetails.Customermailbox')"
        class="w-150 mr-10"
        v-model="form.customerEmail"
      ></el-input>
      <!-- 公司名称 -->
      <el-input
        clearable
        :placeholder="$t('inquiryDetails.CompanyName')"
        class="w-150 mr-10"
        style="width: 145px"
        v-model="form.companyName"
      ></el-input>
      <!-- 产品编码 -->
      <el-input
        clearable
        :placeholder="$t('inquiryDetails.productcode')"
        class="w-150 mr-10"
        style="width: 127px"
        v-model="form.productCode"
      ></el-input>
      <!-- 产品名称 -->
      <el-input
        clearable
        :placeholder="$t('inquiryDetails.productname')"
        class="w-150 mr-10"
        style="width: 127px"
        v-model="form.productEn"
      ></el-input>
      <!-- 报价人 -->
      <el-input
        clearable
        :placeholder="$t('inquiryDetails.bidder')"
        class="w-150 mr-10"
        @focus="userSel"
        @change="changebidder"
        v-model="form.creator"
        style="width: 85px"
      ></el-input>

      <el-date-picker
        clearable
        class="w-350 mr-10"
        style="width: 325px"
        v-model="form.date"
        value-format="yyyy-MM-dd"
        type="daterange"
        :start-placeholder="lang === 'en' ? 'start date' : '开始日期'"
        :end-placeholder="lang === 'en' ? 'end date' : '结束日期'"
        :range-separator="lang === 'en' ? 'to' : '至'"
      ></el-date-picker>
      <!-- 状态 -->
      <el-select
        :placeholder="lang === 'en' ? 'status' : '状态'"
        v-model="form.status"
        class="w-150 mr-10"
        style="width: 100px"
        clearable
      >
        <el-option
          v-for="(s, index) in statusList"
          :key="s.code"
          :value="s.code"
          :label="lang === 'en' ? s.nameEn : s.name"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="onButtonClick">
        {{ lang === 'en' ? 'search' : '搜索' }}
      </el-button>
      <el-button @click="onButtonReset" class="mr-10">
        {{ lang === 'en' ? 'reset' : '重置' }}
      </el-button>

      <!-- 自定义展示列 -->
      <el-popover
        ref="showOption"
        popper-class="custom-table-checkbox"
        trigger="hover"
      >
        <erp-draggable v-bind="dragOptions" :list="columns">
          <el-checkbox
            v-for="(item, index) in columns"
            :key="item + index"
            v-model="item.isShow"
            :disabled="item.checkable === false"
            :label="item.label"
          >
            {{ $t(item.langKey) }}
          </el-checkbox>
        </erp-draggable>
        <!-- 保存自定义显示列 -->
        <div class="save-btn">
          <!-- 保存 -->
          <el-button
            type="primary"
            class="ml10"
            size="mini"
            plain
            @click="operateSave"
          >
            {{ $t('inquiryList.Save') }}
          </el-button>
        </div>
        <template #reference>
          <!-- 列表可拖动排序 -->
          <el-tooltip
            effect="dark"
            :content="$t('iconbtn.dragSort')"
            placement="top-start"
          >
            <!-- 自定义显示 -->
            <el-button plain type="primary">
              {{ $t('inquiryList.CustomDisplay') }}
            </el-button>
          </el-tooltip>
        </template>
      </el-popover>
    </div>
    <!-- 表格 -->
    <el-row class="mt-15">
      <ErpTable
        :page-number.sync="page.pageNo"
        :page-size.sync="page.pageLe"
        :table-data="tableData"
        :total="total"
        :extraHeight="40"
        @query="getList"
        class="table-fixed"
        :key="lang"
      >
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :label="$t(item.langKey)"
          show-overflow-tooltip
          :max-width="180"
          :class-name="item.prop"
          min-width="120"
          :resizable="false"
          align="center"
          :prop="item.prop"
        >
          <template #default="{ row }">
            <!-- 报价单号 -->
            <div v-if="item.prop === 'priceSheetCode'">
              <span class="page-link" @click="showDetail(row)">
                {{ row.priceSheetCode }}
              </span>
            </div>
            <!-- 关联询盘单号 -->
            <div v-else-if="item.prop === 'enquiryCode'">
              <span class="page-link" @click="showInquiryDetail(row)">
                {{ row.enquiryCode }}
              </span>
            </div>
            <!-- 报价人 -->
            <div v-else-if="item.prop === 'creatorCn'">
              <span @click="showInquiryDetail(row)">
                {{
                  lang === 'en'
                    ? row.creatorEn
                      ? row.creatorEn
                      : '--'
                    : row.creatorCn
                    ? row.creatorCn
                    : '--'
                }}
              </span>
            </div>

            <!-- 状态 -->
            <div v-else-if="item.prop === 'status'">
              <span>{{ getStatus(row.status) }}</span>
            </div>

            <div v-else-if="item.prop === 'businessName'"></div>

            <!-- 其他 -->
            <span v-else>{{ row[item.prop] || '--' }}</span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          align="center"
          :label="$t('inquiryList.Operate')"
          width="190"
          fixed="right"
          :resizable="false"
        >
          <template slot-scope="scope">
            <div class="text-center">
              <!-- 发邮件 -->
              <el-tooltip
                :content="lang === 'en' ? 'Send mail' : '发送邮件'"
                placement="top"
                v-if="[1].includes(scope.row.status)"
              >
                <el-button
                  @click="generatePdf(scope.row.priceSheetCode)"
                  type="primary"
                  plain
                  size="mini"
                  icon="iconfont icon-xieyoujian"
                  circle
                ></el-button>
              </el-tooltip>
              <!-- 生成订单 -->
              <el-tooltip
                :content="$t('inquiryList.GenerateOrders')"
                placement="top"
                v-show="[1].includes(scope.row.status)"
              >
                <el-button
                  type="primary"
                  plain
                  @click="createOffer(scope.row.priceSheetCode)"
                  size="mini"
                  icon="el-icon-document"
                  circle
                ></el-button>
              </el-tooltip>
              <!-- 编辑 -->
              <el-tooltip
                :content="lang === 'en' ? 'edit' : '编辑'"
                placement="top"
                v-if="[0].includes(scope.row.status)"
              >
                <el-button
                  @click="edit(scope.row)"
                  type="primary"
                  plain
                  size="mini"
                  icon="el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
              <!-- 复制 -->
              <!-- <el-tooltip
                :content="lang === 'en' ? 'copy' : '复制'"
                placement="top"
              >
                <el-button
                  @click="copy(scope.row)"
                  type="primary"
                  plain
                  size="mini"
                  icon="el-icon-document-add"
                  circle
                ></el-button>
              </el-tooltip> -->
              <!-- 作废 -->
              <el-tooltip
                :content="lang === 'en' ? 'void' : '作废'"
                placement="top"
                v-if="[0, 1].includes(scope.row.status)"
              >
                <el-button
                  @click="disable(scope.row)"
                  type="danger"
                  plain
                  size="mini"
                  icon="iconfont icon-void"
                  circle
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </ErpTable>
    </el-row>
    <!-- 报价单详情 -->
    <OfferSheet
      ref="offerSheet"
      :mailSend="currentRow.status == 1"
      @send-mail="generatePdf"
      @print="onPrint"
      @downloadpdf="downloadPdf"
    />
    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>

  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import {
    getPriceToVoid,
    orderSaleOrderByPriceSheetCode,
  } from '@/api/order.js'
  import { listPage } from '@/api/quotations'
  import { quotationsColumns, statusList } from './helper/data'
  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  import ErpDraggable from 'vuedraggable'
  import OfferSheet from './components/offerSheet'
  import UserChoose from '@/components/userChoose'
  import sendMailMixin from './helper/mailsend-mixin'

  export default {
    name: 'QuotationsList',
    mixins: [sendMailMixin],
    data() {
      return {
        form: {
          priceSheetCode: '', //报价单号
          enquiryCode: '', //询盘单号,
          customerEmail: '', //客户邮箱
          companyName: '', // 公司名称
          productCode: '', //产品编码
          productEn: '', //产品名称
          date: [], //日期
          status: '',
          creator: '', //报价人
          creatorId: '', //报价人id
        },
        statusList, //状态下拉
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        columns: quotationsColumns,
        tableData: [],
        isShowPrint: false,
        currentRow: {},
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),

      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    filters: {},
    components: {
      ErpDraggable,
      OfferSheet,
      UserChoose,
    },
    created() {
      this.getColumsData()
      this.getList()
    },

    methods: {
      changebidder(val) {
        if (!val) {
          this.form.creatorId = ''
          this.form.creator = ''
          this.form.creatorCn = ''
          this.form.creatorEn = ''
        }
      },
      getStatus(val) {
        return statusList[val][this.lang === 'en' ? 'nameEn' : 'name']
        // switch (val) {
        //   case 0:
        //     return '录入中'
        //   case 1:
        //     return '已报价'
        //   case 2:
        //     return '已作废'
        // }
      },

      userSel(userType) {
        const id = this.form.creatorId,
          name = this.form.creatorCn
        this.$refs['UserChoose'].showAddEdit('', id ? [{ id, name }] : '', true)
      },
      chooseUser(users) {
        if (Array.isArray(users) && users.length > 0) {
          const { id, name, englishName } = users[0]
          if (id === this.form.creatorId) {
            return
          }
          this.form['creator'] = this.lang === 'en' ? englishName : name
          this.form.creatorCn = name
          this.form.creatorEn = englishName
          this.form.creatorId = id
        }
      },
      // 询价详情
      showDetail(row) {
        const { enquiryId, priceSheetCode } = row
        this.currentRow = row
        this.$refs.offerSheet.getSelectList(enquiryId, priceSheetCode)
        this.$refs.offerSheet.offerSheetVisible = true
      },
      // 询盘详情
      showInquiryDetail({ enquiryId }) {
        this.$router.push({
          path: '/order/inquiryManage/inquiryDetail',
          query: {
            enquiryId,
          },
        })
      },
      // 编辑
      edit(row) {
        this.$router.push({
          path: '/order/inquiryManage/inquiryOfferEdit',
          query: {
            enquiryId: row.enquiryId,
            priceSheetCode: row.priceSheetCode,
            isEdit: true,
          },
        })
      },
      // 复制
      copy(row) {
        this.$router.push({
          path: '/order/inquiryManage/inquiryOfferCopy',
          query: {
            enquiryId: row.enquiryId,
            priceSheetCode: row.priceSheetCode,
            isCopy: true,
          },
        })
      },
      // 作废
      disable(row) {
        this.$confirm(this.$t('other.InvalidTips'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(() => {
            getPriceToVoid({ priceSheetCode: row.priceSheetCode }).then(
              (res) => {
                if (res?.code === '000000') {
                  this.$message.success(this.$t('other.InvalidSuccess'))
                  this.getList()
                }
              }
            )
          })
          .catch(() => {})
      },
      // 生成订单
      createOffer(code) {
        orderSaleOrderByPriceSheetCode({ priceSheetCode: code }).then((res) => {
          if (res.code === '000000') {
            this.$router.push(
              `/order/orderList/orderAdd?priceSheetCode=${code}`
            )
          }
        })
      },
      getList() {
        const reqData = {
          ...this.form,
          ...this.page,
        }
        const date = this.form.date
        if (date?.length > 0) {
          reqData.startCreateTime = date[0]
          reqData.endCreateTime = date[1]
        }
        listPage(reqData).then((res) => {
          if (res?.code === '000000') {
            this.tableData = res.data.data
            this.total = res.data.total
          }
        })
      },
      // 搜索
      onButtonClick() {
        this.page.pageNo = 1
        this.getList()
      },
      // 重置
      onButtonReset() {
        this.form = this.$options.data().form
        this.page.pageNo = 1
        this.getList()
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(53, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },
      // 保存自定义
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 53,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-container {
    .w-150 {
      width: 150px;
    }
    .w-350 {
      width: 350px;
    }
    .mr-10 {
      margin-right: 10px;
    }
    .mt-15 {
      margin-top: 15px;
    }
  }
</style>
