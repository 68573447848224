var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialogFormVisible)?_c('el-dialog',{attrs:{"title":_vm.$t('mailConfig.BindEmail'),"visible":_vm.dialogFormVisible,"width":"450px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[(!_vm.isGmail || _vm.isEdit)?_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Confirm'))+" ")]):_vm._e(),_c('el-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Cancel'))+" ")])],1)]},proxy:true}],null,false,241708450)},[_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"120px"}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.MailType'),"prop":"mailServer","rules":[
              {
                required: true,
                trigger: 'change',
                message: _vm.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{attrs:{"disabled":_vm.isEdit},model:{value:(_vm.form.mailServer),callback:function ($$v) {_vm.$set(_vm.form, "mailServer", $$v)},expression:"form.mailServer"}},_vm._l((_vm.mailServerList),function(item,index){return _c('el-option',{key:index,attrs:{"label":_vm.settings === 'en' ? item.nameEn : item.name,"value":item.name}},[_vm._v(" "+_vm._s(_vm.settings === 'en' ? item.nameEn : item.name)+" ")])}),1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.EmailOwner'),"prop":"businessName","rules":[
              {
                required: true,
                trigger: 'change',
                message: _vm.$t('placeholder.plsSel'),
              } ]}},[(_vm.settings === 'en')?_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsSel')},on:{"focus":_vm.businessChange},model:{value:(_vm.form.businessNameEn),callback:function ($$v) {_vm.$set(_vm.form, "businessNameEn", $$v)},expression:"form.businessNameEn"}}):_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsSel')},on:{"focus":_vm.businessChange},model:{value:(_vm.form.businessName),callback:function ($$v) {_vm.$set(_vm.form, "businessName", $$v)},expression:"form.businessName"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.ExportsRise'),"prop":"exportHead","rules":[
              {
                required: true,
                trigger: 'change',
                message: _vm.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{attrs:{"value-key":"amountShort","disabled":_vm.isEdit && _vm.isGmail},model:{value:(_vm.form.exportHead),callback:function ($$v) {_vm.$set(_vm.form, "exportHead", $$v)},expression:"form.exportHead"}},_vm._l((_vm.exportsRiseList),function(item){return _c('el-option',{key:item.amountId,attrs:{"label":item.amountShort,"value":item.amountShort}})}),1)],1)],1),(!_vm.isGmail || _vm.isEdit)?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.EmailAddress'),"prop":"emailAccount","rules":[
              {
                required: true,
                trigger: 'change',
                message: _vm.$t('placeholder.plsInput'),
              } ]}},[_c('el-input',{attrs:{"disabled":_vm.form.mailAccountId !== undefined,"placeholder":((_vm.$t('placeholder.M2022')) + "：yourname@email.com")},model:{value:(_vm.form.emailAccount),callback:function ($$v) {_vm.$set(_vm.form, "emailAccount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.emailAccount"}})],1)],1):_vm._e(),(!_vm.isGmail)?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.Password'),"prop":"authCode","rules":[
              {
                required: true,
                trigger: 'change',
                message: _vm.$t('placeholder.plsInput'),
              } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsInput'),"type":"password"},model:{value:(_vm.form.authCode),callback:function ($$v) {_vm.$set(_vm.form, "authCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.authCode"}})],1)],1):_vm._e(),(_vm.isGmail && !_vm.isEdit)?_c('el-col',{staticClass:"text-center",attrs:{"span":24}},[_c('img',{staticClass:"pointer",attrs:{"src":_vm.chromeLogo},on:{"click":_vm.bindGmail}}),_c('div',[_vm._v(_vm._s(_vm.$t('mailConfig.chrome')))])]):_vm._e()],1)],1)],1),_c('UserChoose',{ref:"UserChoose",attrs:{"single-select":true},on:{"choose-user":_vm.chooseUser}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }