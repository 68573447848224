var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogFormVisible,"width":_vm.width,"top":"0","custom-class":"dialog","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Cancel'))+" ")]),(_vm.type === 2 && _vm.showLeaveCanceledBol)?_c('el-button',{attrs:{"type":"warning","loading":_vm.cancelLoading},on:{"click":_vm.leaveCancelFn}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.LeaveCanceled'))+" ")]):_vm._e(),_c('el-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Confirm'))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":_vm.settings === 'zh' ? '78px' : '170px',"label-position":_vm.settings === 'zh' ? 'left' : 'top'}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"forwardEmailAccount","label":_vm.$t('mailConfig.emailaccount'),"rules":[
              {
                required: true,
                trigger: 'change',
                message: _vm.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{directives:[{name:"loadmore",rawName:"v-loadmore:el-select-dropdown__list",value:(_vm.loadMore),expression:"loadMore",arg:"el-select-dropdown__list"}],attrs:{"popper-class":"bank-select-emali","filterable":"","remote":"","clearable":"","placeholder":_vm.emailPlaceholder,"remote-method":_vm.remoteMethod,"loading":_vm.selectLoading,"auto-complete":"new-password"},on:{"change":_vm.handleSelect,"focus":function () { return _vm.remoteMethod(''); }},model:{value:(_vm.form.forwardEmailAccount),callback:function ($$v) {_vm.$set(_vm.form, "forwardEmailAccount", $$v)},expression:"form.forwardEmailAccount"}},_vm._l((_vm.options),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.emailAccountShow,"value":item.emailAccount}})}),1)],1)],1),(_vm.type === 1 && _vm.hasoVacationBol == 1)?_c('p',{class:['has-ovacation', _vm.settings === 'zh' ? 'pl78' : '']},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.HasoVacation'))+" ")]):_vm._e(),(_vm.type === 2)?[_c('el-col',{attrs:{"span":11}},[_c('el-form-item',{attrs:{"prop":"leaveStartTime","label-width":"0","rules":[
                {
                  required: true,
                  trigger: 'change',
                  message: _vm.$t('placeholder.plsSel'),
                },
                {
                  validator: function (rule, value, callback) {
                    if (
                      _vm.form.leaveEndTime &&
                      new Date(value) > new Date(_vm.form.leaveEndTime)
                    ) {
                      callback(_vm.$t('mailConfig.selectTimeTips1'))
                    } else {
                      callback()
                    }
                  },
                  trigger: 'change',
                } ]}},[_c('el-date-picker',{attrs:{"type":"datetime","format":"yyyy-MM-dd HH:mm","value-format":"yyyy-MM-dd HH:mm","placeholder":_vm.$t('mailConfig.leaveStartTime')},on:{"change":function($event){return _vm.tiemChange('leaveStartTime')}},model:{value:(_vm.form.leaveStartTime),callback:function ($$v) {_vm.$set(_vm.form, "leaveStartTime", $$v)},expression:"form.leaveStartTime"}})],1)],1),_c('el-col',{staticStyle:{"line-height":"32px"},attrs:{"span":2}},[_vm._v("-")]),_c('el-col',{attrs:{"span":11}},[_c('el-form-item',{attrs:{"prop":"leaveEndTime","label-width":"0","rules":[
                {
                  required: true,
                  trigger: 'change',
                  message: _vm.$t('placeholder.plsSel'),
                },
                {
                  validator: function (rule, value, callback) {
                    if (
                      _vm.form.leaveStartTime &&
                      new Date(value) < new Date(_vm.form.leaveStartTime)
                    ) {
                      callback(_vm.$t('mailConfig.selectTimeTips'))
                    } else {
                      callback()
                    }
                  },
                  trigger: 'change',
                } ]}},[_c('el-date-picker',{attrs:{"format":"yyyy-MM-dd HH:mm","value-format":"yyyy-MM-dd HH:mm","type":"datetime","placeholder":_vm.$t('mailConfig.leaveEndTime')},on:{"change":function($event){return _vm.tiemChange('leaveStartTime')}},model:{value:(_vm.form.leaveEndTime),callback:function ($$v) {_vm.$set(_vm.form, "leaveEndTime", $$v)},expression:"form.leaveEndTime"}})],1)],1)]:_vm._e()],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }