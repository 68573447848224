var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAdd)?_c('div',{staticClass:"custom-container"},[_c('el-form',{ref:"ruleForm",attrs:{"rules":_vm.rules,"model":_vm.form}},[_c('el-table',{staticClass:"input-table",attrs:{"data":_vm.form.list,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"align":"center","type":"index","label":"序号","width":"80"}}),_c('el-table-column',{attrs:{"label":"地区","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'list.' + scope.$index + '.area',"rules":[
              {
                required: true,
                message: '请选择',
                trigger: 'change',
              } ]}},[_c('el-select',{attrs:{"clearable":""},on:{"change":function($event){return _vm.conditionChange($event, scope.$index, 'area')}},model:{value:(scope.row.area),callback:function ($$v) {_vm.$set(scope.row, "area", $$v)},expression:"scope.row.area"}},_vm._l((_vm.areaArray),function(item){return _c('el-option',{key:item.areaName,attrs:{"label":item.areaName,"value":item.areaName}})}),1)],1)]}}],null,false,182776557)}),_c('el-table-column',{attrs:{"label":"角色","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'list.' + scope.$index + '.role',"rules":[
              {
                required: true,
                message: '请选择',
                trigger: 'change',
              } ]}},[_c('el-select',{attrs:{"clearable":""},on:{"change":function($event){return _vm.conditionChange($event, scope.$index, 'role')}},model:{value:(scope.row.role),callback:function ($$v) {_vm.$set(scope.row, "role", $$v)},expression:"scope.row.role"}},_vm._l((_vm.roleArray),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.message,"value":item.code}})}),1)],1)]}}],null,false,1749302614)}),_c('el-table-column',{attrs:{"label":"主管","align":"center","label-class-name":"required-th"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'list.' + scope.$index + '.manager',"rules":[
              {
                required: true,
                message: '请选择',
                trigger: 'change',
              } ]}},[_c('el-input',{attrs:{"placeholder":"请选择"},on:{"focus":function($event){return _vm.businessChange(scope.$index)},"change":function($event){return _vm.clearBusinessName(scope.$index)}},model:{value:(scope.row.manager),callback:function ($$v) {_vm.$set(scope.row, "manager", $$v)},expression:"scope.row.manager"}})],1)]}}],null,false,516676601)}),_c('el-table-column',{attrs:{"label":"操作","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.Del(scope.$index)}}},[_vm._v("删除")])]}}],null,false,3471745768)})],1),_c('el-button',{staticClass:"new-btn",on:{"click":_vm.addLine}},[_vm._v("新增一行")])],1),_c('div',{staticClass:"oper-btn text-center mt20"},[_c('el-button',{attrs:{"type":"default"},on:{"click":_vm.Cancel}},[_vm._v("返回")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.Save}},[_vm._v("保存")])],1),_c('UserChoose',{ref:"UserChoose",on:{"choose-user":_vm.chooseUser}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }