<!--
  功能：数据看盘图标
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月24日 09:23:26
  版本：v1.4.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="image-wrapper" :style="{ backgroundColor: icons[type].color }">
    <i :class="['iconfont', icons[type].icon, { 'fs-25': type == '4' }]"></i>
  </div>
</template>
<script>
export default {
  name: 'DataKanPanIcon',
  props: {
    backgroundcolor: {
      type: String,
      default: '#1890FF',
    },
    type: {
      type: String,
      default: '4',
    },
  },

  data() {
    return {
      icons: {
        4: {
          icon: 'icon-shenhe',
          color: '#1890FF',
        },
        5: {
          icon: 'icon-kehuxunpan',
          color: '#00B188',
        },
        6: {
          icon: 'icon-dingdan',
          color: '#FF8282',
        },
        7: {
          icon: 'icon-caigouxuqiu',
          color: '#F7B500',
        },
        8: {
          icon: 'icon-yanhuo',
          color: '#00B188',
        },
        9: {
          icon: 'icon-fukuandan',
          color: '#1890FF',
        },
        10: {
          icon: 'icon-feiyongdan',
          color: '#FF8282',
        },
      },
    }
  },
  methods: {
    getIconClassByName() {
      const type = this.type + '' //如果是number类型转化为字符串类型
      const type2class = {
        4: 'icon-shenhe', //审核单
        5: 'icon-kehuxunpan', //询盘
        6: 'icon-dingdan', //销售待安排订单
        7: 'icon-caigouxuqiu', //采购待处理需求
        8: 'icon-yanhuo', //验货
        9: 'icon-fukuandan', //付款
        10: 'icon-feiyongdan', // 费用
      }
      const className = type2class[type]
      return className ? className : 'icon-shenhe'
    },
  },
}
</script> 

<style scoped lang="scss">
.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  i {
    color: #fff;
    &.fs-25 {
      font-size: 25px;
    }
  }
}
</style>
