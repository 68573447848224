<template>
  <div class="platform-product-detail">
    <el-page-header @back="handleBack" content="平台产品详情" />
    <el-tabs v-model="activeName">
      <el-tab-pane label="产品信息" name="first" />
      <!-- <el-tab-pane label="变更日志" name="second" /> -->
    </el-tabs>
    <div class="container" v-show="activeName === 'first'">
      <PlatformProductDetails type="1" :detailInfo="detailInfo" />
    </div>
    <OperateLogs v-if="activeName === 'second'" :bizId="query.id" />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import PlatformProductDetails from '../components/PlatformProductDetails'
  import OperateLogs from '@/components/operate-logs'
  import { PlatformProductListInteractor } from '@/core'
  export default {
    components: { PlatformProductDetails, OperateLogs },
    data() {
      return {
        activeName: 'first', // first-产品信息 second-变更日志
        detailInfo: {},
      }
    },
    computed: {
      ...mapGetters({ lang: 'settings/language' }),
      query() {
        return this.$route.query
      },
    },
    created() {
      this.getInfo()
    },
    methods: {
      handleBack() {
        this.$router.back()
      },
      async getInfo() {
        try {
          const { id, platformCode, erpProductCode, type, platformProductId } =
            this.query
          const { code, data } =
            await PlatformProductListInteractor.getPlatformProductShowDetailApi(
              {
                id,
                platformCode,
                erpProductCode,
                type,
                platformProductId,
              }
            )
          if (code === '000000') {
            this.detailInfo = data
          }
        } catch (err) {
          console.log(err)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .platform-product-detail {
    height: calc(100vh - 45px - 2 * 9px);
    padding: 9px;
    display: flex;
    flex-direction: column;
    .container {
      padding: 0 10px 60px;
      overflow: auto;
      flex: 1;
    }
  }
</style>
