const columnData  = [
  {
    label: '产品编码',
    prop: 'accessoriesNumber',
    isShow: true,
    checkable: false,
    width: 150,
  },
  {
    label: '产品图片',
    prop: 'productImg',
    isShow: true,
    checkable: false,
    width: 120,
  },
  {
    label: '中文名称',
    prop: 'nameCn',
    isShow: true,
    checkable: false,
  },
  {
    label: '英文名称',
    prop: 'nameEn',
    isShow: true,
    checkable: true,
    width: 120,
  },
  {
    label: '单位',
    prop: 'productUnit',
    isShow: true,
    checkable: true,
    width: 80,
  },
  {
    label: '成本价',
    prop: 'costPrice',
    isShow: true,
    checkable: true,
    width: 80,
  },
  {
    label: '尺寸（CM）',
    prop: 'size',
    isShow: true,
    checkable: true,
    width: 120,
  },
  {
    label: '重量（KGS）',
    prop: 'productWeightKgs',
    isShow: true,
    checkable: true,
    width: 120,
  },
  {
    label: '关联产品数',
    prop: 'numberOfRelatedProducts',
    isShow: true,
    checkable: true,
    width: 100,
  },
  {
    label: '可用库存',
    prop: 'stock',
    isShow: true,
    checkable: true,
    width: 130,
  },
  {
    label: '其他描述',
    prop: 'description',
    isShow: true,
    checkable: true,
    width: 120,
  },
  {
    label: '创建人',
    prop: 'creatorName',
    isShow: true,
    checkable: true,
    width: 100,
  },
  {
    label: '创建时间',
    prop: 'createTime',
    isShow: true,
    checkable: true,
    width: 200,
  },
  {
    label: '最近更新人',
    prop: 'updatorName',
    isShow: true,
    checkable: true,
    width: 100,
  },
  {
    label: '更新时间',
    prop: 'updateTime',
    isShow: true,
    checkable: true,
    width: 200,
  },
];
const getColumn = (data)=>{
  return data.map(item=>{
      return {
        label:item.label,
        prop:item.prop,
        isShow:item.isShow,
        checkable:item.checkable,
        width:item.width
      }
  })
}
export default getColumn(columnData)