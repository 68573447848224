// /**
//  * 业务逻辑层，可以获取entities层提供的实体类。将实体类提供实体相关的业务逻辑和interactor业务逻辑相结合一起提供给view层。
//  */

import {
  saveSupplierProduct,
  deleteSupplierProduct,
} from '@/core/services/api/client-supplier/client-manage'

class clientManageInteractor {
  static getInstance() {
    return this._instance
  }

  // 新增，编辑
  async setSupplierProduct(data, supplierId) {
    let dt = {
      ...data,
      supplierId,
    }
    try {
      return await saveSupplierProduct(dt).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }
  // 删除

  async delSupplierProduct(supplierId) {
    try {
      return await deleteSupplierProduct(supplierId).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }
}

clientManageInteractor._instance = new clientManageInteractor()

const ClientManageInteractor = clientManageInteractor.getInstance()
export default ClientManageInteractor
