import request from '@/core/services/request'

//  根据产品id查询报关信息
export function getDecalrById(data) {
  return request({
    url: '/product/productSpecsDeclare/listProductSpecsDeclare',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function setDeclarePrice(data) {
  return request({
    url: '/product/productSpecsDeclare/insertPO',
    method: 'post',
    data,
  })
}

// 产品详情 下载图片 downloadZip
export function downloadProImgZip(data) {
  return request({
    url: '/product/product/downloadZip',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    responseType: 'blob',
  })
}

export function checkDownloadZip(data) {
  return request({
    url: '/product/product/checkDownloadZip',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品详情。平台信息
export function productPlatform(data) {
  return request({
    url: '/product/productPlatform/getProductPlatformInfoVOList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 平台  上下架
export function operProductPlatform(data) {
  return request({
    url: '/product/productPlatform/operationProductPlatform',
    method: 'post',
    data,
  })
}
// 平台记录
export function productPlatformList(data) {
  return request({
    url: '/product/productPlatform/getPageListByPlatformRecord',
    method: 'post',
    data,
  })
}
// 平台列表 getProductPlatformSetList
export function platList(data) {
  return request({
    url: '/product/productPlatform/getProductPlatformSetList',
    method: 'post',
    data,
  })
}

// 产品报价备注新增/修改
export function priceUpdate(data) {
  return request({
    url: '/product/product/priceRemark/update',
    method: 'post',
    data,
    loading: false,
  })
}

// 产品报价备注查询
export function priceRemark(productId) {
  return request({
    url: '/product/product/priceRemark?productId=' + productId,
    method: 'post',
  })
}
