<template>
  <!-- 询价单详情 -->
  <el-dialog
    :title="$t('quoteDetails.InquiryFormDetails')"
    width="1200px"
    class="custom-dialog"
    :visible.sync="inquiryDemandVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div class="container">
      <div v-for="(item, i) in list" :key="i">
        <div class="detail-style">
          <div class="line">
            <div class="item">
              <!-- 询价单号 -->
              <em>{{ $t('quoteDetails.InquiryNo') }}</em>
              <span>
                {{ item.number || '--' }}
                <!-- 修改 -->
                <el-button
                  v-show="
                    (info.enquiryStatus === 4 || info.enquiryStatus === 5) &&
                    item.state === 0
                  "
                  @click="edit(i)"
                >
                  {{ $t('quoteDetails.Modify') }}
                </el-button>
              </span>
            </div>
            <div class="item">
              <!-- 创建人 -->
              <em>{{ $t('quoteDetails.Creator') }}</em>
              <span>
                {{
                  lang === 'en' ? item.creatorEn || '--' : item.creator || '--'
                }}
              </span>
            </div>
            <div class="item">
              <!-- 创建时间 -->
              <em>{{ $t('quoteDetails.CreationTime') }}</em>
              <span>{{ item.createTime || '--' }}</span>
            </div>
            <div class="item">
              <!-- 提交人 -->
              <em>{{ $t('quoteDetails.Submitter') }}</em>
              <span>
                {{
                  lang === 'en'
                    ? item.submitterEn || '--'
                    : item.submitter || '--'
                }}
              </span>
            </div>
            <div class="item">
              <!-- 提交时间 -->
              <em>{{ $t('quoteDetails.SubmitTime') }}</em>
              <span>{{ item.handlerTime || '--' }}</span>
            </div>
          </div>
        </div>

        <div class="c-table-wrap">
          <table class="custom-table">
            <thead>
              <tr>
                <!-- 产品名称 -->
                <th width="200">{{ $t('quoteDetails.ProductName') }}</th>
                <!-- 产品图片 -->
                <th width="100">{{ $t('quoteDetails.ProductImage') }}</th>
                <!-- 需求数量 -->
                <th width="100">{{ $t('quoteDetails.RequiredQuantity') }}</th>
                <!-- 产品类别 -->
                <th width="100">{{ $t('quoteDetails.ProductCategory') }}</th>
                <!-- 产品描述 -->
                <th width="200">{{ $t('quoteDetails.ProductDescription') }}</th>
                <!-- 印刷描述 -->
                <th width="200">
                  {{ $t('quoteDetails.PrintingDescription') }}
                </th>
                <!-- 印刷参考图 -->
                <th width="100">
                  {{ $t('quoteDetails.PrintingReferenceDrawing') }}
                </th>
                <!-- 备注说明 -->
                <th width="200">{{ $t('quoteDetails.Remark') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ item.productEn }}</td>
                <td>
                  <div class="img-wrap">
                    <el-badge
                      v-show="
                        item.productImg && item.productImg.split(',').length > 1
                      "
                      :value="
                        item.productImg ? item.productImg.split(',').length : ''
                      "
                    ></el-badge>
                    <el-image
                      v-if="item.productImg"
                      :src="item.productImg.split(',')[0]"
                      class="c_pointer"
                      @click="handlePreview(item.productImg.split(','))"
                    ></el-image>
                    <div v-else>--</div>
                  </div>
                </td>
                <td>{{ item.amount }}</td>
                <td>{{ lang === 'en' ? item.categoryEn : item.category }}</td>
                <td class="text-warp" style="text-align: left">
                  <p v-html="item.productDescribe.replace(/\n/g, '<br>')"></p>
                </td>
                <td>{{ item.printingDescribe || '--' }}</td>
                <td>
                  <div class="img-wrap">
                    <el-badge
                      v-show="
                        item.printingImg &&
                        item.printingImg.split(',').length > 1
                      "
                      :value="
                        item.printingImg
                          ? item.printingImg.split(',').length
                          : ''
                      "
                    ></el-badge>
                    <el-image
                      v-if="item.printingImg"
                      :src="item.printingImg.split(',')[0]"
                      class="c_pointer"
                      @click="handlePreview(item.printingImg.split(','))"
                    ></el-image>
                    <span v-else>--</span>
                  </div>
                </td>
                <td>{{ item.remarks || '--' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="c-table-wrap mt20">
          <table class="custom-table">
            <thead>
              <tr>
                <th class="index">#</th>
                <!-- 供应商名称 -->
                <th class="more">
                  {{ $t('quoteDetails.SupplierName') }}
                </th>
                <!-- 产品图片 -->
                <th>{{ $t('quoteDetails.ProductImage') }}</th>
                <!-- 印刷logo位置/尺寸 -->
                <th class="more">
                  {{ $t('quoteDetails.PrintingLogoPositionSize') }}
                </th>
                <!-- 产品颜色 -->
                <th>{{ $t('quoteDetails.ProductColor') }}</th>
                <!-- 起订量 -->
                <th>{{ $t('quoteDetails.MinimumOrderQuantity') }}</th>
                <!-- 起订价(元) -->
                <th>{{ $t('quoteDetails.MinimumOrderPrice') }}</th>
                <!-- 交期(工作日) -->
                <th>{{ $t('quoteDetails.DeliveryTime') }}</th>
                <!-- 印刷方式 -->
                <th>{{ $t('quoteDetails.PrintingMethod') }}</th>
                <!-- 打样费用(元) -->
                <th>{{ $t('quoteDetails.ProofingFee') }}</th>
                <!-- 打样时间(工作日) -->
                <th>{{ $t('quoteDetails.ProofingTime') }}</th>
                <!-- 箱规(外箱尺寸,装箱数,毛重) -->
                <th class="more">{{ $t('quoteDetails.BoxGauge') }}</th>
                <!-- 单个产品克重(克) -->
                <th>{{ $t('quoteDetails.SingleProductGramWeight') }}</th>
                <!-- 产品材质 -->
                <th>{{ $t('quoteDetails.ProductMaterials') }}</th>
                <!-- 产品尺寸 -->
                <th>{{ $t('quoteDetails.ProductSize') }}</th>
                <!-- 联系人 -->
                <th>{{ $t('quoteDetails.Contacts') }}</th>
                <!-- 联系方式 -->
                <th>{{ $t('quoteDetails.ContactDetails') }}</th>
                <!-- 产品链接 -->
                <th class="more">{{ $t('quoteDetails.ProductLinks') }}</th>
                <!-- 采购员意见/评价 -->
                <th class="more">
                  {{ $t('quoteDetails.BuyerCommentsEvaluations') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="item.inquiryOrderSupplierVOList.length">
              <tr v-for="(list, i) in item.inquiryOrderSupplierVOList" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  {{ list.supplierName }}
                  <i
                    :class="list.recommendFlag == 1 ? 'iconfont icon-good' : ''"
                  ></i>
                </td>
                <td>
                  <div class="img-wrap">
                    <el-badge
                      v-show="list.image && list.image.split(',').length > 1"
                      :value="list.image ? list.image.split(',').length : ''"
                    ></el-badge>
                    <el-image
                      v-if="list.image"
                      :src="list.image.split(',')[0]"
                      class="c_pointer"
                      @click="handlePreview(list.image.split(','))"
                    ></el-image>
                    <span v-else>--</span>
                  </div>
                </td>
                <td>{{ list.actualLogoSize || '--' }}</td>
                <td>{{ list.productColor || '--' }}</td>
                <td>{{ list.quantity || '--' }}</td>
                <td>{{ list.price || '--' }}</td>
                <td>{{ list.completeDays || '--' }}</td>
                <td>{{ list.printingWay || '--' }}</td>
                <td>{{ list.proofingFee || '--' }}</td>
                <td>{{ list.proofingDays || '--' }}</td>
                <td>{{ list.proofingFee || '--' }}</td>
                <td>{{ list.productWeightg || '--' }}</td>
                <td>{{ list.material || '--' }}</td>
                <td>{{ list.productSize || '--' }}</td>
                <td>{{ list.contacts || '--' }}</td>
                <td>{{ list.contactInformation || '--' }}</td>
                <td>{{ list.linkHref || '--' }}</td>
                <td>{{ list.purchasingComments || '--' }}</td>
              </tr>
            </tbody>
            <tbody v-else class="empty">
              <tr>
                <!-- 暂无数据 -->
                <td colspan="99">{{ $t('other.noData') }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <el-divider></el-divider>
      </div>
    </div>

    <div class="text-center mt20">
      <!-- 关闭 -->
      <el-button @click="close()">
        {{ $t('inquiryDetails.Close') }}
      </el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </el-dialog>
</template>

<script>
import { listInquiryDetailByEnquiryId } from '@/api/order'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      info: {},
      list: [],
      inquiryDemandVisible: false,
      showViewer: false, //图片预览参数
      imgUrl: '', //图片预览url
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  methods: {
    // 初始化数据
    init(row) {
      this.info = row
      listInquiryDetailByEnquiryId({ businessId: row.enquiryId }).then(
        (res) => {
          this.list = res.data || []
          this.inquiryDemandVisible = true
        }
      )
    },

    // 编辑
    edit(i) {
      this.inquiryDemandVisible = false
      this.$emit('enquiryEdit', this.list[i])
    },

    // 大图预览
    handlePreview(item) {
      if (Array.isArray(item)) {
        this.imgUrl = item
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    // 取消预览
    closeViewer() {
      this.showViewer = false
    },

    // 关闭弹窗重置表单
    close() {
      this.inquiryDemandVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 0 5px 20px 20px;
    .detail-style {
      .el-button {
        padding: 0px 9px;
        margin-left: 5px;
        span {
          font-size: 12px;
        }
      }
    }
  }
}
.container {
  min-height: 360px;
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 5px;
  i {
    font-size: 16px;
    color: #ffba00;
    font-weight: 600;
  }
  .detail-style {
    padding: 0;
    .item {
      min-width: 200px;
      max-width: none;
      em {
        padding: 0;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .img-wrap {
    position: relative;
    .el-image {
      width: 56px;
      height: 56px;
      object-fit: cover;
      display: block;
      margin: 0 auto;
      border-radius: 2px;
    }
    .el-badge {
      position: absolute;
      top: -4px;
      right: 12px;
      z-index: 2;
    }
  }
  .el-divider {
    margin: 30px 0;
  }
  > div:last-child .el-divider {
    display: none;
  }
}
</style>
