import {
  listPage,
  stockGiftListPage,
  updateStockGiftNum,
  inTransitBySku,
  warehouseInUnusableDetail,
  warehouseOutForStockDetail,
  stockLockInfo,
  productIdByCode,
  findWarehouseList2,
  dinggetDetailForShow,
  stockListPage,
  stockInfoBySkuId,
  stockLockRecordUnlock,
} from '@/core/services/api/stock-center/stock'

class Store {
  static getInstance() {
    return this._instance
  }

  /**
   * 产品库存-分页列表查询
   * @param {isShow} 是否显示库存为0的数据
   * @param {warehouseId} 仓库id
   * @param {pageLe} 页/条
   * @param {pageNo} 页码
   * @param {noVirtualInventory} 1：不显示虚拟库存
   * @param {wrehouseDimensionView}  0：仓库维护查看
   */
  listPage(params) {
    return listPage(params)
  }

  /**
   *
   * 产品库存-赠品库--分页列表查询
   * @param {proType} 产品类型
   * @param {pageLe} 页/条
   * @param {pageNo} 页码
   */
  stockGiftListPage(params) {
    return stockGiftListPage(params)
  }

  /**
   * 产品库存-赠品库--修改库存
   * @param {reason} 原因
   * @param {num} 数量
   * @param {stockGiftId} 仓库id
   */
  updateStockGiftNum(params) {
    return updateStockGiftNum(params)
  }

  /**
   * 产品库存-根据sku查询在途采购单
   * @param {sku} skuId
   */
  async inTransitBySku(skuId) {
    return inTransitBySku({ sku: skuId })
  }

  /**
   * 产品库存-入库单--库存不可用库存详情查询
   * @param {skuId} skuId
   */
  warehouseInUnusableDetail(skuId) {
    return warehouseInUnusableDetail({ skuId: skuId })
  }

  /**
   * 产品库存-库存待出库详情查询
   * @param {skuId} skuId
   */
  warehouseOutForStockDetail(skuId) {
    return warehouseOutForStockDetail({ skuId: skuId })
  }

  /**
   * 产品库存-根据sku查询库存锁定信息
   * @param {sku} skuId
   */
  stockLockInfo(skuId) {
    return stockLockInfo({ sku: skuId })
  }

  /**
   * 产品库存-根据产品编码查询产品id
   * @param {productCode} 产品code
   */
  productIdByCode(id) {
    return productIdByCode({ productCode: id })
  }

  /**
   * 产品库存-根据产品编码查询产品id
   */
  findWarehouseList2() {
    return findWarehouseList2({
      hasAuth: 0,
      hasCloudWarehouse: 1,
    })
  }

  /**
   * 产品库存-通过采购单号查找详情
   */
  dinggetDetailForShow(id) {
    return dinggetDetailForShow({ businessId: id })
  }

  /**
   * 产品库存-根据分页查找列表
   * @param {endDate} 结束时间	
   * @param {pageLe} 页/条
   * @param {pageNo} 页码
   * @param {sku} sku
   * @param {startDate} 开始时间
   * @param {type} 类型	
   * @param {warehouseId	} 仓库id
   */
  stockListPage(params) {
    return stockListPage(params)
  }

  /**
   * 产品库存-根据分页查找列表 	
   * @param {pageLe} 页/条
   * @param {pageNo} 页码
   * @param {skuId} skuId
   * 
   */
  stockInfoBySkuId(params) {
    return stockInfoBySkuId(params)
  }

  /**
   * 产品库存-采购单-解锁
   */
  stockLockRecordUnlock({
    stockLockRecordId,
    unlockNum
  }) {
    return stockLockRecordUnlock({
      stockLockRecordId,
      unlockNum
    })
  }
}

Store._instance = new Store()

export const storeManage = Store.getInstance()
