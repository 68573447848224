<!--
  功能：打印包装组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月16日 11:28:40
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="print-wrapper">
    <slot></slot>
  </div>
</template>

<script>
const _ = (selector) => document.querySelector(selector)
export default {
  name: 'PrintWrapper',
  methods: {
    initPageStyle() {
      // 设置body样式
      const body = _('body')
      const html = _('html')
      const app = _('#app')
      ;[html, body, app].forEach((el) => {
        el.style.height = 'auto'
        if (el == body || el == html) {
          el.style.overflow = 'auto'
        }
      })
    },
  },
  mounted() {
    this.initPageStyle()
  },
}
</script> 

<style scoped lang="scss">
.print-wrapper {
  min-height: 100vh;
  padding: 9px;
  overflow: auto;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
    color 0.1s, font-size 0s;

  font-family: 'microsoft yahei';
  color: #000000;
}
</style>
