<template>
  <el-dialog
    title="运费报价设置"
    :visible.sync="setPriceVisible"
    width="500px"
    :before-close="cancel"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="基准运费">
        <span>以下设置的运费模板将在产品计价和询盘报价中使用</span>
      </el-form-item>
      <el-form-item label="快递" prop="express">
        <el-select v-model="ruleForm.express" placeholder="请选择">
          <el-option
            v-for="item in expressOptions"
            :key="item.freightSettingId"
            :label="item.transportWayCn"
            :value="item.freightSettingId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="空运" prop="airTransport">
        <el-select v-model="ruleForm.airTransport" placeholder="请选择">
          <el-option
            v-for="item in airTransportOptions"
            :key="item.freightSettingId"
            :label="item.transportWayCn"
            :value="item.freightSettingId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="海运" prop="oceanShipping">
        <el-select v-model="ruleForm.oceanShipping" placeholder="请选择">
          <el-option
            v-for="item in oceanShippingOptions"
            :key="item.freightSettingId"
            :label="item.transportWayCn"
            :value="item.freightSettingId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="尾程快递" prop="tailExpress">
        <el-select v-model="ruleForm.tailExpress" placeholder="请选择">
          <el-option
            v-for="item in expressOptions"
            :key="item.freightSettingId"
            :label="item.transportWayCn"
            :value="item.freightSettingId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-row class="text-center mt20">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="cancel">取 消</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>
  import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'

  export default {
    props: {
      setPriceVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        ruleForm: {
          express: '',
          tailExpress: '',
          airTransport: '',
          oceanShipping: '',
        },
        rules: {
          express: [{ required: true, message: '请选择', trigger: 'change' }],
          tailExpress: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          airTransport: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          oceanShipping: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
        },
        expressOptions: [], //快递
        airTransportOptions: [], //空运
        oceanShippingOptions: [], //海运
      }
    },
    watch: {
      setPriceVisible() {
        if (this.setPriceVisible) {
          this.getSelectOptions()
        }
      },
    },
    methods: {
      async getSelectOptions() {
        this.expressOptions =
          await FreightSettingInteractor.getSelectByTransportType(3)
        this.airTransportOptions =
          await FreightSettingInteractor.getSelectByTransportType(1)
        this.oceanShippingOptions =
          await FreightSettingInteractor.getSelectByTransportType(2)

        await this.getDetail()
      },
      cancel() {
        this.$refs['ruleForm'].resetFields()
        this.$emit('update:setPriceVisible', false)
      },
      save() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            let res = null
            console.log(this.ruleForm, 'this.ruleForm')
            this.loading = true
            if (this.ruleForm.freightQuotationSettingId) {
              res = FreightSettingInteractor.setUpdatePO(this.ruleForm)
              this.loading = false
            } else {
              res = FreightSettingInteractor.setSavePO(this.ruleForm)
              this.loading = false
            }

            if (res) {
              this.$message.success('操作成功')
            }
            this.cancel()
          } else {
            return false
          }
        })
      },
      async getDetail() {
        this.ruleForm = await FreightSettingInteractor.getQueryOne()
        console.log(this.ruleForm, '222')
      },
    },
  }
</script>
