<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="95%"
    @close="close"
    :close-on-click-modal="false"
    top="5vh"
    center
  >
    <div class="dialog-content">
      <el-row :gutter="5" class="compare-row">
        <el-col :span="12" class="compare-col-left">
          <el-row class="tip-row">前一次操作记录（旧）</el-row>
          <el-row class="detail-content">
            <ProductInfo ref="ProductInfo" />
          </el-row>
        </el-col>
        <el-col :span="12" class="compare-cols-right">
          <el-row class="tip-row">当前操作记录（新）</el-row>
          <el-row class="detail-content">
            <ProductInfo ref="ProductInfo" />
          </el-row>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <el-button @click="close">取 消</el-button>
    </template>
  </el-dialog>
</template>

<script>
import ProductInfo from './ProductInfo.vue'
export default {
  name: 'EditCompareModel',
  components: { ProductInfo },
  data() {
    return {
      title: '操作记录比较',
      dialogFormVisible: false,
      leftDetail: {}, //左侧操作记录
      rightDetail: {}, //右侧操作记录
    }
  },
  created() {},
  methods: {
    //比较
    showAddEdit(row) {
      this.dialogFormVisible = true
    },

    //关闭
    close() {
      this.dialogFormVisible = false
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  height: 70vh;
  .compare-row {
    height: 100%;
    > .el-col {
      height: 100%;
      border: 1px solid #ededed;
      .tip-row {
        width: 100%;
        line-height: 25px;
        text-align: center;
        background: #ededed;
        font-family: 'PingFang Bold';
      }
      .detail-content {
        height: calc(100% - 25px);
        padding: 10px;
        overflow-y: auto;
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>
