<!--
  功能：选择的库位展示
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年01月11日 11:35:52
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div>
    <div v-if="noLocShow">--</div>
    <div v-else>
      <div v-if="locs.length > 0" @click="addLoc">
        <template v-for="(i, index) in locs">
          <p :key="index" :style="{color: !disabled && '#236ce9', 'text-align': 'center'}" v-if="index <= max">
            <span :class="!disabled ? 'el-link el-link--primary' : 'inlineFlex'">
              {{ i.locationNumber }}({{ i.num }})
            </span>
            <span class="el-link el-link--primary" v-if="showInloc">
              <i class="el-icon-right blue"></i>
              {{ i.locationInNumber }}({{ i.inNum }})
            </span>

            <span v-if="index == max-1 && locs.length > max">...</span>
            <span v-else>;</span>
          </p>
        </template>
      </div>
      <el-link type="primary" :underline="false" v-else @click="addLoc">
        请设置
      </el-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectedLoction',
    props: {
      locs: {
        type: Array,
        default: () => [],
      },
      tableIndex: {
        type: [Number, String],
        default: '0',
      },
      status: {
        type: Number,
        default: 0,
      },
      showInloc: {
        //是否展示调入库位
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false
      },
      max: {
        type: Number,
        default: 4
      }
    },
    components: {},
    computed: {
      isDetail() {
        //本组件会被入库和出库使用
        return this.isInboundDetail || this.isOutboundDetail
      },
      isInboundDetail() {
        return this.$route.path === '/stock-center/inbound-sheet-detail'
      },
      isOutboundDetail() {
        return (
          this.$route.path ===
          '/stock-center/stock-center/outbound-sheet/outbound-sheet-detail'
        )
      },
      noLocShow() {
        // 入库库位展示逻辑
        if (!this.isDetail) {
          //不是详情页不展示--
          return false
        } else {
          if (this.isInboundDetail) {
            // 已入库和异常入库
            if ([2, 5, 0].includes(this.status)) {
              return false
            } else {
              return true
            }
          } else {
            if (this.isOutboundDetail) {
              // 1=待出库 2=已出库
              if ([1, 2].includes(this.status)) {
                return false
              } else {
                return true
              }
            }
          }
        }
      },
    },
    // 组件状态值
    methods: {
      addLoc() {
        if (this.disabled) return
        this.$emit('add-loc', this.tableIndex, this.type)
      },
    },
  }
</script>

<style scoped>
.inlineFlex {
  display: inline-flex;
}
</style>
