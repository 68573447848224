<template>
  <div class="search_form">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item
        label="销售订单编号"
        prop="salesOrderNumber"
        v-if="type == 1"
      >
        <el-input
          v-model="form.salesOrderNumber"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item label="期望交期" prop="deliveryDate">
        <el-date-picker
          v-model="deliveryDate"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="需求数量">
        <div class="line_input">
          <el-form-item prop="startNumDemand">
            <el-input
              v-model="form.startNumDemand"
              maxlength="10"
              placeholder="请输入"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
          <span>至</span>
          <el-form-item prop="endNumDemand">
            <el-input
              v-model="form.endNumDemand"
              maxlength="10"
              placeholder="请输入"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="采购开发">
        <el-input
          clearable
          v-model="businessName"
          placeholder="请选择"
          @focus="selectUserClick('business')"
          @change="businessNameChange"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="type == 1" label="客户代表">
        <el-input
          clearable
          v-model="fromBusinessName"
          placeholder="请选择"
          @focus="selectUserClick('fromBusiness')"
          @change="fromBusinessNameChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="companyName">
        <el-input
          clearable
          v-model="creatorName"
          placeholder="请选择"
          @focus="selectUserClick('creatorName')"
          @change="creatorChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <!-- <el-divider /> -->

      <div class="btn">
        <el-button type="primary" @click="searchClick">确定</el-button>
        <el-button @click="resetClick">重置</el-button>
      </div>
    </el-form>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
import UserChoose from '@/components/userChoose2'
export default {
  name: 'demand-search',
  components: {
    UserChoose,
  },
  data() {
    return {
      type: null,
      state: null,
      businessName: '', // 客户代表名称
      businessNameId: [], // 客户代表id
      fromBusinessName: '', // 订单支持名称
      fromBusinessNameId: [], // 订单支持id
      creatorName: '', //创建人
      creatorId: [], //创建人id
      isUserChoose: '', //当前组织架构是谁调用
      deliveryDate: [], // 期望交期
      createTime: [], // 创建时间
      form: {},
      daterange: [],
      rules: {
        salesOrderNumber: [
          { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    // 期望交期
    deliveryDate(dateArray) {
      if (dateArray) {
        this.form.startExpectedDeliveryDate = dateArray[0]
        this.form.endExpectedDeliveryDate = dateArray[1]
      } else {
        this.form.startExpectedDeliveryDate = ''
        this.form.endExpectedDeliveryDate = ''
      }
    },
    // 创建时间
    createTime(dateArray) {
      if (dateArray) {
        this.form.startCreateTime = dateArray[0]
        this.form.endCreateTime = dateArray[1]
      } else {
        this.form.startCreateTime = ''
        this.form.endCreateTime = ''
      }
    },
  },
  created() {
    this.state = this.$parent.$parent.queryForm.state
  },
  methods: {
    // 客户代表，订单支持，创建人选择
    selectUserClick(str) {
      this.isUserChoose = str
      if (str === 'business') {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.businessNameId)
      } else if (str == 'fromBusiness') {
        this.$refs['UserChoose'].showAddEdit(
          'listSearch',
          this.fromBusinessNameId
        )
      } else {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.creatorId)
      }
    },

    // 获取客户代表
    chooseUser(checkedUser) {
      if (this.isUserChoose === 'business') {
        this.businessNameChange()
      } else if (this.isUserChoose === 'fromBusiness') {
        this.fromBusinessNameChange()
      } else {
        this.creatorChange()
      }

      if (!checkedUser.length) {
        return
      }
      let userName = []
      checkedUser.forEach((item) => {
        userName.push(item.name)
        if (this.isUserChoose === 'business') {
          this.businessName = userName.join(',')
          this.businessNameId.push(item.id)
        } else if (this.isUserChoose === 'fromBusiness') {
          this.fromBusinessName = userName.join(',')
          this.fromBusinessNameId.push(item.id)
        } else {
          this.creatorName = userName.join(',')
          this.creatorId.push(item.id)
        }
      })
    },

    // 查询
    searchClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.businessNameId) {
            this.form.buyerId = this.businessNameId.join(',')
          }
          if (this.fromBusinessNameId) {
            this.form.businessId = this.fromBusinessNameId.join(',')
          }
          if (this.creatorId) {
            this.form.creatorId = this.creatorId.join(',')
          }
          this.form.state = this.state
          this.$emit('searchClick', this.form)
        }
      })
    },

    // 重置
    resetClick() {
      this.deliveryDate = []
      this.createTime = []
      this.businessNameChange()
      this.fromBusinessNameChange()
      this.creatorChange()
      this.$refs.form.resetFields()
      this.form = this.$options.data().form
    },

    // 删除客户代表
    businessNameChange() {
      this.businessNameId = []
      this.businessName = ''
      this.form.buyerId = ''
    },

    // 删除订单支持
    fromBusinessNameChange() {
      this.fromBusinessId = []
      this.fromBusinessName = ''
      this.form.businessId = ''
      this.fromBusinessNameId = []
    },

    // 删除创建人
    creatorChange() {
      this.creatorId = []
      this.creatorName = ''
      this.form.creatorId = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.search_form {
  padding: 0 20px 20px 20px;
  .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  .btn {
    padding: 20px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
  }
}
::v-deep {
  .el-input--suffix {
    padding-right: 5px !important;
  }
  .el-cascader {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 70px 0 28px 0 !important;
  }
}
</style>
