import {
  orderGetDict,
  selectAllCustomer,
  orderEnquiryAdd,
  orderEnquiryUpdate,
  orderEnquiryDetail,
  enquiryClone,
  saveUpdatePO,
  getDetailForEmail,
} from '@/core/services/api/inquiry/add'

class Inquiry {
  static getInstance() {
    return this._instance
  }

  /**
   * 询盘新增-询盘新增获取出口抬头和询盘来源
   * @param {amountType} 1:出口抬头 2:询盘来源
   */
  orderGetDict(amountType) {
    return orderGetDict({ amountType: amountType })
  }

  /**
   * 询盘新增-新增询盘单
   * @param {*}
   */
  selectAllCustomer(linkman) {
    return selectAllCustomer({ linkman: linkman })
  }

  /**
   * 询盘新增-获取询盘新增
   * @param {enquiryId} 询盘id
   * @param {form} 表单信息
   * @param {buttonType} 默认2
   */
  orderEnquiryAdd(params) {
    return orderEnquiryAdd(params)
  }

  /**
   * 询盘新增-更新询盘单
   * @param {enquiryId} 询盘id
   * @param {form} 表单信息
   * @param {buttonType} 默认2
   */
  orderEnquiryUpdate(params) {
    return orderEnquiryUpdate(params)
  }

  /**
   * 询盘新增-编辑根据id查找
   * @param {businessId} 业务id
   */
  orderEnquiryDetail(id) {
    return orderEnquiryDetail({ businessId: id })
  }

  /**
   * 询盘新增-克隆询盘单
   * @param {enquiryId} 询盘id
   */
  enquiryClone(id) {
    return enquiryClone({ enquiryId: id })
  }

  /**
   * 询盘新增-询盘保存
   * @param {enquiryId} 询盘id
   * @param {form} 表单信息
   */
  saveUpdatePO(params) {
    return saveUpdatePO(params)
  }

  /**
   * 询盘新增-根据联系人邮箱获取详情
   * @param {email} 联系人邮箱
   */
  getDetailForEmail(email) {
    return getDetailForEmail({ email: email })
  }
}

Inquiry._instance = new Inquiry()

export const InquiryAdd = Inquiry.getInstance()
