import request from '@/core/services/request'

// 产品类别-根据ID删除
export function deletePO(data) {
  return request({
    url: '/product/productCategory/deletePO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品类别-新增
export function insertPO(data) {
  return request({
    url: '/product/productCategory/insertPO',
    method: 'post',
    data,
  })
}
// 产品类别-根据类别,查询关联的材质
export function listMaterialByCategoryId(data) {
  return request({
    url: '/product/productCategory/listMaterialByCategoryId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品类别-根据类别,查询关联的规格
export function listSpecByCategoryId(data) {
  return request({
    url: '/product/productCategory/listSpecByCategoryId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品类别-分页查询
export function listPage(data) {
  return request({
    url: '/product/productCategory/listPage',
    method: 'post',
    data,
  })
}
// 产品类别-编辑保存
export function savePO(data) {
  return request({
    url: '/product/productCategory/savePO',
    method: 'post',
    data,
  })
}
// 产品类别-根据ID查询
export function selectPO(data) {
  return request({
    url: '/product/productCategory/selectPO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
