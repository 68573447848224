<!--
  功能：入库单详情基础 区分三种类型
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月28日 13:50:01
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="custom-detail">
    <!-- 其他出库基本信息 -->
    <template v-if="detail.type == '5' || detail.type == '12' || detail.type == '16'">
      <el-row>
        <el-col>
          <em style="width: 85px">出库方式：</em>
          <span style="width: calc(100% - 85px)" v-if="detail.type == '16'">
            盘亏出库
          </span>
          <span style="width: calc(100% - 85px)" v-else>
            {{ detail.type == '5' ? '其他出库' : '调拨出库' }}
          </span>
        </el-col>
        <el-col>
          <em>出库仓库：</em>
          <span>
            {{ detail.warehouseName | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <em>创建人:</em>
          <span>{{ detail.creator | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>所属地区：</em>
          <span>{{ detail.creatorArea | emptyFilter }}</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <em>创建时间:</em>
          <span>{{ detail.createTime | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>操作人:</em>
          <span>{{ detail.operationName | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>出库时间:</em>
          <span>{{ detail.actualOutDate | emptyFilter }}</span>
        </el-col>
        <el-col v-if="detail.type == 12">
          <em>调拨单号:</em>
          <span class="page-link-noline" @click="showDetail(detail.orderNumber)">
            {{ detail.orderNumber }}
          </span>
        </el-col>
        <el-col v-if="detail.type == 16">
          <em>盘点单号:</em>
          <span class="page-link-noline" @click="showDetail(detail.orderNumber)">
            {{ detail.orderNumber }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="width: 100%">
          <em style="vertical-align: top">备注:</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
      </el-row>
    </template>

    <!-- 退货出库基本信息 -->

    <template v-else-if="detail.type == '4'">
      <el-row>
        <el-col>
          <em style="width: 85px">出库方式：</em>
          <span style="width: calc(100% - 85px)">
            <!-- {{ detail.type | typeFilter }} -->
            退货出库
          </span>
        </el-col>
        <el-col>
          <em>出库仓库：</em>
          <span>
            {{ detail.warehouseName | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <em>采购订单:</em>
          <span>{{ detail.orderNumber | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>供应商</em>
          <span>{{ detail.supplierName | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <!-- TODO -->
          <em style="width: 85px">采购开发：</em>
          <span style="width: calc(100% - 85px)">
            {{ detail.buyer | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <!-- TODO -->
          <em style="width: 85px">创建人</em>
          <span style="width: calc(100% - 85px)">
            {{ detail.buyer | emptyFilter }}
          </span>
        </el-col>

        <el-col>
          <em>所属地区：</em>
          <span>{{ detail.area | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>创建时间:</em>
          <span>{{ detail.warehouseInDate | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <em>操作人：</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
        <el-col :span="6">
          <em>出库时间：</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="width: 100%">
          <em style="vertical-align: top">原因说明：</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
      </el-row>
    </template>
    <!-- 领料出库基本信息 -->
    <template v-else-if="detail.type == '13'">
      <el-row>
        <el-col>
          <em style="width: 85px">出库方式：</em>
          <span style="width: calc(100% - 85px)">领料出库</span>
        </el-col>
        <el-col>
          <em>出库仓库：</em>
          <span>
            {{ detail.warehouseName | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <em>订单编号：</em>
          <span class="page-link-noline" @click="
              goRelatedDetail(
                detail.warehouseOutProductVOList[0].salesOrderNumber
              )
            ">
            {{
            (detail.warehouseOutProductVOList &&
            detail.warehouseOutProductVOList[0] &&
            detail.warehouseOutProductVOList[0].salesOrderNumber)
            | emptyFilter
            }}
          </span>
        </el-col>
        <el-col>
          <em>加工单号：</em>
          <span class="page-link-noline" @click="showDetail(detail.orderNumber)">
            {{ detail.orderNumber | emptyFilter }}
          </span>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <em>客户代表:</em>
          <span>{{ detail.businessName | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>创建人:</em>
          <span>{{ detail.creator | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>所属地区：</em>
          <span>{{ detail.creatorArea | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>创建时间:</em>
          <span>{{ detail.createTime | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <em>操作人:</em>
          <span>{{ detail.operationName | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>出库时间:</em>
          <span>{{ detail.actualOutDate | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="width: 100%">
          <em style="vertical-align: top">备注:</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
      </el-row>
    </template>
    <!-- 销售出库基本信息 -->
    <template v-else>
      <el-row>
        <el-col>
          <em style="width: 85px">出库方式：</em>
          <span style="width: calc(100% - 85px)">销售出库</span>
        </el-col>
        <el-col>
          <em>出库仓库：</em>
          <span>
            {{ detail.warehouseName | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <em>订单编号:</em>
          <span class="el-link el-link--primary" @click="goRelatedDetail(detail.orderNumber)" v-if="detail.orderNumber">
            {{ detail.orderNumber | emptyFilter }}
          </span>
          <span v-else>-</span>
        </el-col>
        <el-col>
          <em style="width: 100px">预计出库时间：</em>
          <span style="width: calc(100% - 100px)">
            {{ detail.expectOutDate | emptyFilter }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <em style="width: 85px">客户代表：</em>
          <span style="width: calc(100% - 85px)">
            {{ detail.businessName | emptyFilter }}
          </span>
        </el-col>
        <el-col>
          <em style="width: 85px">创建人：</em>
          <span style="width: calc(100% - 85px)">
            {{ detail.creator | emptyFilter }}
          </span>
        </el-col>

        <el-col>
          <em>所属地区：</em>
          <span>{{ detail.area | emptyFilter }}</span>
        </el-col>
        <el-col>
          <em>创建时间:</em>
          <span>{{ detail.createTime | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <em>操作人：</em>
          <span>{{ detail.operationName | emptyFilter }}</span>
        </el-col>
        <el-col :span="6">
          <em>出库时间：</em>
          <span>{{ detail.actualOutDate | emptyFilter }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="width: 100%">
          <em style="vertical-align: top">备注：</em>
          <span>{{ detail.remark | emptyFilter }}</span>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
  import { openPage } from 'kits'
  import { InboundSheet } from '@/core'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

  export default {
    name: 'BaseInboundDetail',
    props: {
      detail: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      showDetail(orderNumber) {
        const { type } = this.detail
        const type2strat = {
          12: 'allotInquireDetail',
          13: 'outprocessingDetail',
          16: 'stockCheckDetail'
        }
        return navigateTo(this, {
          orderNumber,
          showType: 'id',
          pageType: PAGE_TYPES[type2strat[type]],
        })
      },
      async goRelatedDetail(number) {
        if (this.detail.type === 4) {
          // 采购订单详情
          let res = await InboundSheet.roleDetailForShow(
            this.detail.orderNumber
          )
          if (res && res.data === 1) {
            openPage(
              this,
              {
                path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
                query: {
                  id: this.detail.orderNumber, // 采购订单详情id和number都可查询，参数统一为id
                  noReturn: true,
                },
              },
              false
            )
          } else {
            this.$message.warning('暂无查看权限')
          }
        } else {
          let res = await InboundSheet.shippingLookOrderAuthority(
            number || this.detail.orderNumber
          )
          if (res?.code === '000000') {
            if (res && res.data === 1) {
              openPage(
                this,
                {
                  path: '/order/orderList/orderDetail',
                  query: {
                    orderCode: number || this.detail.orderNumber,
                    noReturn: true,
                  },
                },
                true
              )
            } else {
              this.$message.warning('暂无查看权限')
            }
          }
        }
      },
    },

    filters: {
      emptyFilter(val) {
        return val ? val : '-'
      },
      // typeFilter(val) {
      //   if (val == 1) {
      //     return '退货入库'
      //   } else if (val == 2) {
      //     return '其他入库'
      //   } else {
      //     return '采购入库'
      //   }
      // },
    },
  }
</script>

<style scoped></style>