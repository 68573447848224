<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年07月27日 16:37:51
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="d-wrapper">
    <div class="tab-wrapper">
      <div v-for="item in tabs" :key="item.id" :class="
          item.id === cIndex ? 'status-item status--active' : 'status-item'
        " @click="toggleTab(item)">
        <span v-show="!(lang === 'en' && item.id === 2)">
          {{ lang === 'en' ? item.nameEn : item.name }}
        </span>
      </div>
    </div>
    <el-form :rules="{}" :model="form" ref="form" v-if="cIndex == 1">
      <el-table border stripe :data="form.nodeList" :key="cIndex + 1" :height="tableHeight" class="tab-table no-space">
        <!-- 节点序号 -->
        <el-table-column :label="$t('Documentary.NSN')" align="center" width="180">
          <template slot-scope="scope">
            {{ form.nodeList.length - scope.$index }}
          </template>
        </el-table-column>
        <!-- 节点名称 -->
        <el-table-column :label="$t('Documentary.NN')" align="center" prop="nodeNameCn">
          <template slot-scope="scope">
            {{ lang === 'en' ? scope.row.nodeNameEn : scope.row.nodeNameCn }}
          </template>
        </el-table-column>
        <el-table-column label="" align="center" prop="dueDate">
          <template slot="header" slot-scope="scope">
            <span class="red">*</span>
            <!-- 预计日期 -->
            <span>{{ $t('Documentary.ESD') }}</span>
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="'nodeList.' + scope.$index + '.dueDate'" :rules="[
                {
                  required: true,
                  message: $t('Documentary.PC'),
                  trigger: ['blur'],
                },
              ]">
              <el-date-picker v-model="scope.row.dueDate" type="date" size="mini" :placeholder="$t('Documentary.PC')"
                value-format="yyyy-MM-dd hh:mm:ss" :disabled="
                  status == '3' ||
                  status == '4' ||
                  scope.row.disabled ||
                  scope.row.status == 2 ||
                  scope.row.status == 3
                " @change="(val) => onDc(val, scope.row)" />
            </el-form-item>
          </template>
        </el-table-column>
        <!-- 实际日期 -->
        <el-table-column :label="$t('Documentary.ACD')" align="center" prop="realDate">
          <template slot-scope="scope">
            <el-date-picker :placeholder="$t('Documentary.PC')" v-model="scope.row.realDate" size="mini" type="date"
              value-format="yyyy-MM-dd hh:mm:ss" :disabled="
                scope.row.specialNode ||
                (!scope.row.specialNode &&
                  (!scope.row.dueDate || scope.row.disabled)) ||
                (!scope.row.specialNode &&
                  !beforeNodes[scope.row.orderTrackingNodeId].before.realDate)
              " @change="(val) => onDc(val, scope.row)" />
          </template>
        </el-table-column>
        <!-- 延期天数 -->
        <el-table-column :label="$t('Documentary.DED')" align="center" prop="nodeNameCn" width="150">
          <template slot-scope="scope">
            <span>
              {{ scope.row.deferredDays ? scope.row.deferredDays : '--' }}
            </span>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column :label="$t('Documentary.remark')" align="center" prop="remark">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark" style="width: 220px"
              :disabled="status == '3' || status == '4' || scope.row.disabled" :maxlength="200"></el-input>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column :label="$t('Documentary.operate')" align="center">
          <template slot-scope="scope">
            <span class="el-button--text" @click="updateTrackingPO(scope.row)"
              v-show="isInclude(status, [1, 2, 5, 6]) && isInclude(power, [2, 3])" v-allowed="['TRACK:UPDATE_NODE']">
              <!-- 保存 -->
              {{ $t('Documentary.save') }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-table border stripe :data="records" v-else-if="cIndex == 2" :height="tableHeight - 40" :key="cIndex + 2">
      <el-table-column v-for="(item, index) in columns" :key="index" :label="$t(item.tKey)"
        :width="item.width || 'auto'" :align="item.prop == 'operationContent' ? 'left' : 'center'" :prop="item.prop">
        <template slot-scope="scope">
          <p v-if="item.prop != 'operationContent'" class="text-ellipsis">
            {{ scope.row[item.prop] || '--' }}
          </p>
          <div v-else class="text-ellipsis">
            <div>
              <p>【{{ getText(scope.row.operationType) }}】</p>
              <!-- <p v-html="scope.row.operationContent"></p> -->
              <p>
                {{ replaceBR(scope.row.operationContent) }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Documentary.operate')" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click="showDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="cIndex == 2" background :current-page="pageNo"
      layout="total, sizes,  prev, pager, next, jumper" :page-size="pageLe" :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
      :total="total" @current-change="pageChange" @size-change="sizeChange" />
    <el-table border stripe :data="remarks" v-if="cIndex == 3" :key="cIndex + 3" :height="tableHeight">
      <!-- 序号 -->
      <el-table-column :label="$t('Documentary.SN')" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <!-- 时间 -->
      <el-table-column :label="$t('Documentary.time')" align="center" prop="createTime"></el-table-column>
      <!-- 姓名 -->
      <el-table-column :label="$t('Documentary.name')" align="center" prop="creator"></el-table-column>
      <!-- 备注内容 -->
      <el-table-column show-overflow-tooltip :label="$t('Documentary.Remarks')" align="center"
        prop="remark"></el-table-column>
    </el-table>
    <!-- 操作日志详情 -->
    <el-drawer :visible.sync="showDrawer" :title="$t('Documentary.OLD')">
      <LogDetail :log="currentLog" />
    </el-drawer>
  </div>
</template>

<script>
  // TABS
  import { trackInterface } from '@/core'
  import mixin from '@/components/tracking-nodes/mixins'
  import { type2LogText } from '../helper'
  import LogDetail from '@/components/operate-logs/operate-log-detail.vue'
  import { mapGetters } from 'vuex'

  const columns = [
    {
      label: '操作人',
      width: '120px',
      prop: 'operatorName',
      tKey: 'Documentary.Operator',
    },
    {
      label: '操作时间',
      width: '180px',
      prop: 'operationTime',
      tKey: 'Documentary.OPT',
    },
    {
      label: '操作记录',
      prop: 'operationContent',
      tKey: 'Documentary.OPR',
    },
  ]

  const TABS = [
    {
      id: 1,
      name: '跟单节点',
      nameEn: 'Stage',
      active: true,
    },
    {
      id: 2,
      name: '操作日志',
      nameEn: 'Operation log',
      value: '1',
      active: false,
    },
    {
      id: 3,
      name: '备注',
      nameEn: 'Remark',
      active: false,
    },
  ],
    // 映射关系
    index2WhichList = {
      // 1: 'form.nodeList',
      2: 'records',
      3: 'remarks',
    },
    _ = (s) => +new Date(s)

  export default {
    name: 'TrackDataDetail',
    mixins: [mixin],

    props: {
      cIndex: {
        type: Number,
        default: 1,
      },
    },
    // 组件状态值
    data() {
      return {
        columns,
        records: [], //记录列表
        remarks: [], //备注列表
        tabs: TABS,
        orderTrackingId: '',
        curId: 1,
        tableHeight: 100,
        status: '',
        power: '',
        showDrawer: false,
        currentLog: {},
        form: {
          nodeList: [],
        },
        beforeNodes: {},
        pageLe: 10,
        pageNo: 1,
        total: 0,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    components: {
      LogDetail,
    },

    methods: {
      sizeChange(val) {
        this.pageLe = val
        this.getTabInfo(this.orderTrackingId, this.curId)
      },
      pageChange(val) {
        this.pageNo = val
        this.getTabInfo(this.orderTrackingId, this.curId)
      },
      replaceBR(content) {
        if (content && content.includes('<br/>')) {
          content = content.replace(/<br\s*\/?>/g, ';')
        }
        return content
      },
      showDetail(log) {
        this.currentLog = log
        this.$nextTick(() => {
          this.showDrawer = true
        })
      },
      isInclude(val, arr) {
        arr = arr.map((item) => Number(item))
        return arr.includes(Number(val))
      },
      onDc(val, item) {
        this.onDateChange(val, item, false)
      },
      getText(num) {
        return type2LogText[num]
      },
      // 切换tab
      toggleTab(cur) {
        this.$emit('tabindex-change', cur.id)
        this.curId = cur.id
        this.getTabInfo(this.orderTrackingId, this.curId)
      },

      // 初始化
      initDetail({ status, power, orderTrackingId }) {
        this.status = status
        this.power = power
        this.orderTrackingId = orderTrackingId
      },

      // 获取tab列表
      async getTabInfo(orderTrackingId) {
        const res = await trackInterface.getTabInfo({
          orderTrackingId,
          index: this.curId,
          pageLe: this.pageLe,
          pageNo: this.pageNo,
        })

        if (this.curId == 1) {
          this.form.nodeList = res.list
          this.beforeNodes = res.beforeNodes
        } else if (this.curId == 2) {
          this[index2WhichList[this.curId]] = res.list.list
          this.total = res.list.total
        } else {
          this[index2WhichList[this.curId]] = res.list
        }

        this.specialNodeList = res.specialNodeList
        // 记录详情
        this.detail = res.detail
        if (this.curId == 1) {
          setTimeout(() => {
            this.$refs.form.clearValidate()
          }, 20)
        }
      },
      updateTrackingPO() {
        this.$refs.form.validate(async (res) => {
          if (res) {
            if (
              this.checkRealDate(
                this.form.nodeList.filter((item) => !item.hide)
              )
            ) {
              let detail = JSON.parse(JSON.stringify(this.detail))
              detail.nodeDTO = this.form.nodeList.map((item) => {
                item.deferred = item.deferredDays ? 1 : 0
                return item
              })
              detail.productDTO = detail.orderTrackingProductVOList
              delete detail.orderTrackingNodeVOList
              delete detail.orderTrackingProductVOList
              const res = await trackInterface.updateTrackingPO(detail)

              if (res && res.code === '000000') {
                this.isSaved = true
                this.visible = false
                this.$message.success(this.$t('Documentary.SS'))
                this.getTabInfo(this.orderTrackingId, 1)
                this.$emit('node-save-success')
              }
            }
          }
        })
      },

      getTableHeight() {
        const { height } = document.body.getBoundingClientRect()
        this.tableHeight = height * (2 / 5) - 150
      },
    },
    created() {
      this.getTableHeight()
      this.$Bus.$on('refresh-remarks', () => {
        if (this.curId == 3) {
          this.getTabInfo(this.orderTrackingId, 3)
        }
      })
    },

    watch: {
      orderTrackingId(val) {
        if (!val) return
        this.pageNo = 1
        this.getTabInfo(val, this.curId)
      },
    },
  }
</script>

<style scoped lang="scss">
  .d-wrapper {
    height: 100%;
  }

  .tab-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .status-item {
      box-sizing: border-box;
      padding: 3px 5px;
      margin-right: 15px;
    }

    .status--active {
      border-bottom: 1px solid red;
      color: red;
    }

    .status-item:hover {
      cursor: pointer;
      color: red;
    }
  }

  tr.row-hide {
    display: none !important;
  }

  .el-button--text {
    cursor: pointer;
  }

  .log-p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .red {
    color: red;
    margin-right: 5px;
  }

  .text-ellipsis {
    -webkit-line-clamp: 3;
    /*（用来限制在一个块元素显示的文本的行数，3 表示最多显示3 行。为了实现该效果，它需要组合其他的 WebKit 属性）*/
    display: -webkit-box;
    /*（和 webkit-line-clamp 结合使用，将对象作为弹性伸缩盒子模型显示 ）*/
    -webkit-box-orient: vertical;
    /*（和 webkit-line-clamp 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）*/
    overflow: hidden;
    /*（文本溢出限定的宽度就隐藏内容）*/
    text-overflow: ellipsis;
    /*（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)*/
  }
</style>