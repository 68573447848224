import { render, staticRenderFns } from "./quotations-list.vue?vue&type=template&id=ebced0a0&scoped=true&"
import script from "./quotations-list.vue?vue&type=script&lang=js&"
export * from "./quotations-list.vue?vue&type=script&lang=js&"
import style0 from "./quotations-list.vue?vue&type=style&index=0&id=ebced0a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebced0a0",
  null
  
)

export default component.exports