<template>
  <el-dialog
    :title="$t('productDetail.CustomsDeclaration')"
    :visible.sync="declarVisible"
    width="1200px"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      :label-position="lang === 'zh' ? 'right' : 'top'"
      :model="form"
      :rules="rules"
      label-width="110px"
    >
      <!-- 按规格设置 -->
      <el-form-item
        v-if="infoParames.specsType != ''"
        :label="$t('productDetail.SATS')"
      >
        <el-switch v-model="skuSetBol"></el-switch>
        <!-- Tip：未开启则所有SKU均使用相同报关信息 -->
        <small class="ml10">Tip：{{ $t('productDetail.declarTips') }}</small>
      </el-form-item>
      <div v-if="!skuSetBol">
        <!-- 中文报关名 -->
        <el-form-item
          :label="$t('productDetail.ChineseCustomsDeclarationName')"
          prop="declareCn"
          :rules="rules.mockInputRule"
        >
          <el-input
            v-model="form.declareCn"
            :placeholder="$t('placeholder.plsInput')"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- 英文报关名 -->
        <el-form-item
          :label="$t('productDetail.EnglishCustomsDeclarationName')"
        >
          <el-input
            v-model="form.declareEn"
            :placeholder="$t('placeholder.plsInput')"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- 报关单位 -->
        <el-form-item
          :label="$t('productDetail.CustomsDeclarationUnit')"
          prop="declareUnit"
          :rules="rules.mockInputRule"
        >
          <el-input
            v-model="form.declareUnit"
            :placeholder="$t('placeholder.plsInput')"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- 海关编码 -->
        <el-form-item :label="$t('productDetail.CustomsCode')">
          <el-input
            v-model="form.customsCode"
            :placeholder="$t('placeholder.plsInput')"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- 报关要素 -->
        <el-form-item :label="$t('productDetail.CustomsDeclarationElements')">
          <el-input
            v-model="form.declareRemark"
            :placeholder="$t('placeholder.plsInput')"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
      </div>

      <el-table v-if="skuSetBol" border :data="form.tableData" max-height="500">
        <!-- 产品编码 -->
        <el-table-column
          :label="$t('productDetail.ProductCode')"
          align="center"
          prop="sku"
        ></el-table-column>
        <el-table-column
          v-for="(it, i) in specTypeValue"
          :key="i"
          :label="it"
          align="center"
          :prop="'specsValue' + (i + 1)"
          width="100"
        ></el-table-column>
        <!-- 报关中文名 -->
        <el-table-column
          :label="$t('productDetail.ChineseCustomsDeclarationName')"
          align="center"
          prop="declareCn"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.declareCn'"
              :rules="rules.mockInputRule"
              label-width="0"
              style="margin-bottom: 0"
            >
              <el-input
                v-model="scope.row.declareCn"
                maxlength="100"
                show-word-limit
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- 英文报关名 -->
        <el-table-column
          :label="$t('productDetail.EnglishCustomsDeclarationName')"
          align="center"
          prop="declareEn"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.declareEn"
              maxlength="100"
              show-word-limit
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
            ></el-input>
          </template>
        </el-table-column>
        <!-- 法定计量单位 -->
        <el-table-column
          :label="$t('productDetail.CustomsDeclarationUnit')"
          align="center"
          prop="declareUnit"
          label-class-name="required-th"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.declareUnit'"
              :rules="rules.mockInputRule"
              label-width="0"
              style="margin-bottom: 0"
            >
              <el-input
                v-model="scope.row.declareUnit"
                maxlength="10"
                show-word-limit
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- 海关编码 -->
        <el-table-column
          :label="$t('productDetail.CustomsCode')"
          align="center"
          prop="customsCode"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.customsCode"
              maxlength="10"
              show-word-limit
            ></el-input>
          </template>
        </el-table-column>
        <!-- 报关要素 -->
        <el-table-column
          :label="$t('productDetail.CustomsDeclarationElements')"
          align="center"
          prop="declareRemark"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.declareRemark"
              maxlength="500"
              show-word-limit
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <!-- 取 消 -->
      <el-button @click="cancel">{{ $t('productDetail.Cancel') }}</el-button>
      <!-- 确 定 -->
      <el-button
        type="primary"
        :loading="loading"
        @click="validateForm('form')"
      >
        {{ $t('productDetail.Confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { ProductViewInteractor } from '@/core/interactors/product/productView'
  import _ from 'lodash'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        loading: false,
        form: {
          tableData: [], //  表格
        },
        skuSetBol: false, // 开关
        specTypeValue: [], // 规格类型
        rules: {
          mockInputRule: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
            },
          ],
        },
      }
    },
    props: {
      declarVisible: {
        default: false,
        type: Boolean,
      },
      productId: {
        default: '',
        type: String,
      },
      infoParames: {
        default: () => {},
        type: Object,
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    watch: {
      declarVisible: {
        handler() {
          if (this.declarVisible) {
            this.getDetail()
          }
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      handleClose() {
        for (const key in this.form) {
          this.form[key] = ''
        }
        this.form.tableData = []
        this.$emit('update:declarVisible', false)
      },
      // 设置规格
      setSpecsValue(_str) {
        return JSON.parse(_str.replace(/\'/g, '"'))
      },
      validateForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.save()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      async save() {
        let tempObj = {}
        for (const key in this.form) {
          if (key != 'tableData') {
            tempObj[key] = this.form[key]
          }
        }
        tempObj = this.form.tableData
        tempObj.map((item) => {
          delete item.tableData
        })
        let tableData = _.cloneDeep(tempObj)

        if (this.skuSetBol) {
          tableData = tableData.map((it) => {
            return {
              ...it,
              skuSettingForDeclare: 1,
            }
          })
        } else {
          tableData = tableData.map((it) => {
            return {
              ...it,
              ...this.form,
              skuSettingForDeclare: 0,
              productSpecsId: it.productSpecsId,
              sku: it.sku,
              id: it.id,
              productSpecsDeclareId: it.productSpecsDeclareId,
            }
          })
        }
        this.loading = true
        let res = await ProductViewInteractor.updateDeclarePrice(tableData)
        this.loading = false
        if (res?.code === '000000') {
          // 操作成功
          this.$message.success(this.$t('reqmsg.$7'))
          this.handleClose()
        }

        this.handleClose()
      },
      cancel() {
        this.handleClose()
      },
      async getDetail() {
        let res = await ProductViewInteractor.getDeclarMsg(this.productId)
        this.skuSetBol = res.data[0].skuSettingForDeclare == 1 ? true : false
        this.form = res.data[0]
        this.form.tableData = res.data || []
        this.specTypeValue = this.form.tableData[0].specsType.split(',')
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .dialog-footer {
      display: flex;
      justify-content: center;
    }
  }
</style>
