export const quotationsColumns = [
  {
    label: '报价单号',
    prop: 'priceSheetCode',
    isShow: true,
    checkable: false,
    width: 160,
    widthEn: 180,
    langKey: 'inquiryDetails.quotationnumber',
  },
  {
    label: '关联询盘单号',
    prop: 'enquiryCode',
    isShow: true,
    checkable: true,
    width: 160,
    widthEn: 220,
    langKey: 'inquiryDetails.AssociatedInquiryNumber',
  },
  {
    label: '客户邮箱',
    prop: 'customerEmail',
    isShow: true,
    checkable: true,
    width: 160,
    widthEn: 180,
    langKey: 'inquiryDetails.Customermailbox',
  },
  {
    label: '公司名称',
    prop: 'companyName',
    isShow: false,
    checkable: true,
    width: 160,
    widthEn: 180,
    langKey: 'inquiryDetails.CompanyName',
  },
  {
    label: '产品编号',
    prop: 'productCode',
    isShow: true,
    checkable: false,
    width: 160,
    widthEn: 180,
    langKey: 'inquiryDetails.productcode',
  },
  {
    label: '产品名称',
    prop: 'productEn',
    isShow: true,
    checkable: false,
    width: 160,
    widthEn: 180,
    langKey: 'inquiryDetails.productname',
  },
  {
    label: '报价人',
    prop: 'creatorCn',
    isShow: false,
    checkable: true,
    width: 160,
    widthEn: 180,
    langKey: 'inquiryDetails.bidder',
  },
  {
    label: '创建时间',
    prop: 'createTime',
    isShow: true,
    width: 160,
    widthEn: 180,
    checkable: true,
    langKey: 'inquiryDetails.CreationTime',
  },
  {
    label: '状态',
    prop: 'status',
    isShow: false,
    width: 160,
    widthEn: 180,
    checkable: true,
    langKey: 'inquiryDetails.status',
  },

]

export const statusList = [{
  code: 0,
  name: '录入中',
  nameEn: 'Entering',
}, {
  code: 1,
  name: '已报价',
  nameEn: 'Quoted',
}, {
  code: 2,
  name: '已作废',
  nameEn: 'out of date',
}]