<!--
  功能：入库单详情产品详情
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月28日 14:58:47
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <!-- 其他入库表格 -->
  <div>
    <el-table v-if="type == '2' || type == '10' || type == '11'" :key="detail.type"
      :data="type == 11 ? tableData: detail.warehouseInProductVOList" border striped :height="450">
      <el-table-column align="center" label="产品编码" width="180">
        <template slot-scope="scope">
          <el-link type="primary" @click="skuIdClick(scope.row)" v-if="scope.row.skuId.indexOf('FL') === -1">
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag == 0 && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库批次" prop="lotNo">
        <template slot-scope="scope">
          {{ (type == 10 ? scope.row.returnInLotNo : scope.row.lotNo) || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec"></el-table-column>
      <el-table-column align="center" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="单位" prop="productUnit">
        <template slot-scope="scope">{{ scope.row.productUnit }}</template>
      </el-table-column>
      <el-table-column align="center" label="实际入库数量" width="160" prop="warehouseInNum"></el-table-column>
      <el-table-column align="center" prop="usable" label="产品是否可用" width="160" v-if="type == '11'">
        <template slot-scope="scope">
          {{ scope.row.usable == '0' ? '否' : '是' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库单价" prop="priceIncludingTax">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <template v-if="detail.flag === 0 && !detailId">
              <BaseStar></BaseStar>
            </template>
            <template v-else>
              {{ scope.row.priceIncludingTax }}
            </template>
          </template>
          <template v-else>
            {{ scope.row.priceExcludingTax }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库成本" prop="totalPriceExcluding">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ toFixed3(scope.row.totalPriceExcluding) }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库库位" v-if="showLocs">
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationVOList" @add-loc="showLoc" :status="detail.status"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
    </el-table>

    <!-- 采购入库表格 -->
    <el-table :key="detail.type" v-else-if="type == '0'" :data="detail.warehouseInProductVOList" border striped>
      <el-table-column align="center" label="产品编码">
        <template slot-scope="scope">
          <el-link type="primary" @click="skuIdClick(scope.row)">
            {{ scope.row.skuId }}
          </el-link>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag == 0 && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库批次" prop="lotNo">
        <template slot-scope="scope">
          {{ scope.row.lotNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec"></el-table-column>
      <el-table-column align="center" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType ? scope.row.productType : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="申请入库数量/单位" width="160">
        <template slot-scope="scope">
          {{ scope.row.expectInNum ? scope.row.expectInNum : '-' }}/{{
          scope.row.productUnit ? scope.row.productUnit : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="实际入库数量" prop="warehouseInNum"></el-table-column>
      <el-table-column align="center" label="含税单价/不含税单价" prop="priceIncludingTax" width="200">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ scope.row.priceIncludingTax | emptyFilter }}/
            {{ scope.row.priceExcludingTax | emptyFilter }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="含税入库成本/不含税入库成本" prop="totalPriceExcluding" width="230">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ detail.currency === '美元' ? '$' : '￥' }}
            {{
            scope.row.totalPriceIncluding &&
            scope.row.totalPriceIncluding.toFixed(3)
            }}/
            {{ scope.row.totalPriceExcluding }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库库位" v-if="showLocs">
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationVOList" @add-loc="showLoc" :status="detail.status"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
    </el-table>
    <!-- 退料入库 -->
    <el-table v-else-if="type == '14'" :key="detail.type" :data="detail.warehouseInProductVOList" border striped
      :span-method="objectSpanMethod">
      <el-table-column align="center" label="产品编码" width="180">
        <template slot-scope="scope">
          <el-link type="primary" @click="skuIdClick(scope.row)" v-if="scope.row.skuId.indexOf('FL') === -1">
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag == 0 && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec"></el-table-column>

      <el-table-column align="center" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="单位" prop="productUnit">
        <template slot-scope="scope">
          {{ scope.row.productUnit || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="申请入库数量" width="160" prop="expectInNum"></el-table-column>
      <el-table-column align="center" label="实际入库数量" width="160" v-if="detail.status != 1"
        prop="warehouseInNum"></el-table-column>
      <el-table-column align="center" label="入库批次" prop="lotNo" width="200px">
        <template slot-scope="scope">
          {{ scope.row.lotNo || scope.row.returnInLotNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库库位" v-if="showLocs">
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationVOList" @add-loc="showLoc" :status="detail.status"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库单价" prop="priceIncludingTax" width="120">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ scope.row.priceExcludingTax }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库成本" prop="totalPriceExcluding" width="120">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ scope.row.totalPriceExcluding }}
          </template>
        </template>
      </el-table-column>

      <el-table-column align="center" label="备注" prop="remark" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 加工入库 -->
    <el-table v-else-if="type == '15'" :data="detail.warehouseInProductVOList" border striped :key="detail.type"
      :span-method="objectSpanMethod">
      <el-table-column align="center" label="产品编码" width="120" fixed="left">
        <template slot-scope="scope">
          <el-link type="primary" @click="skuIdClick(scope.row)">
            {{ scope.row.skuId }}
          </el-link>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag == 0 && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>

      <el-table-column align="center" label="产品名称" width="120" prop="productName"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec" width="120"></el-table-column>
      <el-table-column align="center" label="产品类型" width="120">
        <template slot-scope="scope">
          {{ scope.row.productType ? scope.row.productType : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="单位" width="150">
        <template slot-scope="scope">
          {{ scope.row.productUnit || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="合格品/报废品" prop="hege" width="150">
        <template slot-scope="scope">
          {{ scope.row.usable === '1' ? '合格品' : '报废品' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库批次" prop="lotNo" width="200px">
        <template slot-scope="scope">
          {{ scope.row.lotNo || scope.row.returnInLotNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="申请入库数量" prop="expectInNum" width="150"></el-table-column>
      <el-table-column align="center" label="实际入库数量" v-if="detail.status != 1" prop="warehouseInNum"
        width="150"></el-table-column>

      <el-table-column align="center" label="入库单价" prop="priceIncludingTax" width="120">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ scope.row.priceExcludingTax }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库成本" prop="totalPriceExcluding" width="120">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ scope.row.totalPriceExcluding }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库库位" v-if="showLocs" width="180">
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationVOList" @add-loc="showLoc" :status="detail.status"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
    </el-table>
    <!-- 退货入库表格 -->
    <el-table v-else :data="detail.warehouseInProductVOList" border striped :key="detail.type">
      <el-table-column align="center" label="出库单号" width="120">
        <!-- 出库单号 -->
        <template slot-scope="scope">
          <template v-if="scope.row.warehouseOutNumber">
            <el-link type="primary" @click="ckClick(scope.row.warehouseOutNumber)">
              {{ scope.row.warehouseOutNumber }}
            </el-link>
          </template>

          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品编码" width="120">
        <template slot-scope="scope">
          <el-link type="primary" @click="skuIdClick(scope.row)">
            {{ scope.row.skuId }}
          </el-link>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag == 0 && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" prop="productName" width="150"></el-table-column>
      <el-table-column align="center" label="规格" prop="productSpec" width="120"></el-table-column>
      <el-table-column align="center" label="产品类型" width="120">
        <template slot-scope="scope">
          {{ scope.row.productType ? scope.row.productType : '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="已出库数量/单位" width="150">
        <template slot-scope="scope">
          {{ scope.row.warehouseOutNum ? scope.row.warehouseOutNum : '-' }}/{{
          scope.row.productUnit ? scope.row.productUnit : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="returnInLotNo" label="入库批次" width="150">
        <template slot-scope="scope">
          {{ scope.row.returnInLotNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="purchaseNumber" label="采购单号" width="120">
        <template slot-scope="scope">
          {{ scope.row.purchaseNumber || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="buyer" label="采购开发" width="120">
        <template slot-scope="scope">
          {{ scope.row.buyer || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="warehouseOutNum" label="批次出库数量" width="150">
        <template slot-scope="scope">
          {{ scope.row.warehouseOutNum || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="申请入库数量" prop="warehouseInNum" width="150"></el-table-column>
      <el-table-column align="center" label="产品是否可用" prop="usable" width="150">
        <template slot-scope="scope">
          {{ scope.row.usable == '0' ? '否' : '是' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库单价" prop="priceIncludingTax" width="120">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ scope.row.priceExcludingTax }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库成本" prop="totalPriceExcluding" width="120">
        <template slot-scope="scope">
          <template v-if="detail.flag === 0 && !detailId">
            <BaseStar></BaseStar>
          </template>
          <template v-else>
            {{ scope.row.totalPriceExcluding }}
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.remark | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库库位" v-if="showLocs" width="120">
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationVOList" @add-loc="showLoc" :status="detail.status"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next,total" :total="total" :current-page.sync="pageNo"
      :page-size="pageSize" @current-change="handleCurrentChange" v-if="type == '11'">
    </el-pagination>
    <div>
      <LocDetail ref="loc-detail" :tableData="currentRow.warehouseInProductLocationVOList"></LocDetail>
    </div>
  </div>
</template>

<script>
  import BaseStar from '@/components/base-star/base-star'
  import LocDetail from './base-loc-detail'
  import SelectedLocs from './selected-locs.vue'
  import popoverShipClick from '../../outbound-sheet/components/popoverShipClick.vue'
  import { ProductListInteractor } from '@/core'
  const mergeColumns = [
    '产品编码',
    '产品名称',
    '规格',
    '产品类型',
    '单位',
    '结算备注',
    '审核备注',
    '操作',
    '已审核备注',
  ]

  export default {
    name: 'BaseProductDetail',
    props: {
      detailId: {
        type: String,
        default: '',
      },
      detail: {
        type: Object,
        default: () => ({}),
      },
    },

    components: {
      BaseStar,
      LocDetail,
      SelectedLocs,
      popoverShipClick,
    },
    data() {
      return {
        currentRow: {},
        spanArr: [],
        pos: 0,
        total: 0, //总页码
        pageSize: 200, //分页数量
        pageNo: 1, //第几页
        initTableData: [], //初始数据
        tableData: [],
        tableHeight: 300, //表格高度

      }
    },

    computed: {
      showLocs() {
        if (
          this.$route.path === '/audit-process/audit-manage/index' ||
          this.$route.path === '/home'
        ) {
          return false
        } else {
          return true
        }
      },
      type() {
        return this.detail.type
      },
    },

    watch: {
      detail: {
        deep: true,
        handler: function (detail) {
          if (
            detail?.warehouseInProductVOList.length > 0 &&
            (this.type == 15 || this.type == 14)
          ) {
            this.getSpanArr(detail.warehouseInProductVOList)
          }
          if (this.type == 11) {
            this.initTableData = detail?.warehouseInProductVOList
            this.total = this.initTableData.length
            this.getPaginationData()
          }
        },
      },
    },

    methods: {
      handleCurrentChange(val) {
        this.pageNo = val
        this.getPaginationData()
      },
      getPaginationData() {
        let initTableData = this.initTableData,
          pageNo = this.pageNo,
          pageSize = this.pageSize
        // 确保页码不小于1
        pageNo = Math.max(pageNo, 1);
        // 计算起始索引
        const startIndex = (pageNo - 1) * pageSize;
        // 计算结束索引，注意JavaScript数组索引是从0开始的
        const endIndex = startIndex + pageSize;

        // 获取指定页的数据
        const tableData = initTableData.slice(startIndex, endIndex);
        this.tableData = tableData;
      },
      showLoc(index) {
        let row = this.detail.warehouseInProductVOList[index]
        this.$refs['loc-detail'].show()
        this.currentRow = row
      },
      skuIdClick({ skuId }) {
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        // 辅料不能跳转
        if (id.indexOf('FL') > -1) return
        let response = await ProductListInteractor.productIdByCode(id)
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      ckClick(warehouseOutId) {
        let routeData = this.$router.resolve({
          path: '/stock-center/stock-center/outbound-sheet/outbound-sheet-detail',
          query: {
            warehouseOutId,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
      /**
       * 合并单元格objectSpanMethod
       */
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (mergeColumns.includes(column.label)) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      getSpanArr(list) {
        for (var i = 0; i < list.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            if (
              `${list[i].skuId}${list[i].orderProductUniqueId}` ===
              `${list[i - 1].skuId}${list[i - 1].orderProductUniqueId}`
            ) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
    },

    filters: {
      emptyFilter(val) {
        return val || '--'
      },
    },
  }
</script>

<style scoped></style>
