<template>
  <div>
    <ActionBar ref="actionbar" />
    <div class="erp-mail">
      <keep-alive>
        <LeftAside />
      </keep-alive>
      <div class="content-board">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import LeftAside from './components/LeftAside.vue'
  import ActionBar from './components/ActionBar'
  export default {
    components: {
      LeftAside,
      ActionBar,
    },


    destroyed() {
      this.$root.$off('initWriteBoard')
      this.$root.$off('updateList')
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('mail/setLeftTableData', [])
      next()
    },
  }
</script>

<style lang="scss" scoped>
  .erp-mail {
    display: flex;
    background: #f5f7fa;
    height: calc(100vh - 65px - 60px);
    overflow: hidden;

    .content-board {
      flex: 1;
      width: 0;
      height: 100%;
    }
  }
</style>
