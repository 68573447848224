<template>
  <el-select :key="lang" ref="sel" v-model.trim="supplier" v-loadmore:el-select-dropdown__list="loadMore"
    popper-class="user-sel-select" :value-key="codeKey" filterable remote
    :placeholder="$t('placeholder.' + placeholderKey)" :remote-method="remoteMethod" :loading="loading"
    :clearable="clearable" :disabled="disabled" @focus="() => remoteMethod('')" @visible-change="onBlur" no-data-text="" loading-text="">
    <el-option v-for="item in options" :key="item.order_id" :value="item" :label="item[codeKey]" />
  </el-select>
</template>

<script>
  import debounce from 'lodash/debounce'
  import { OutProcessing, OrderInteractor } from '@/core'
  import { mapGetters } from 'vuex'

  export default {
    name: 'UserSelect',
    props: {
      roleName: {
        type: String,
        default: '',
      },
      divideGroup: {
        type: Number,
        default: 0,
      },
      defaultOrderCode: {
        type: [Object, Array],
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholderKey: {
        type: String,
        default: 'plsSel'
      },
      reqType: {
        type: String,
        default: 'OUT_PROCESS'
      },
      codeKey: {
        type: String,
        default: 'order_code'
      },
      pageLe: {
        type: Number,
        default: 30
      },
      clearable: {
        type: Boolean,
        default: true
      },



    },
    data() {
      return {
        supplier: [],
        chooseUser: '',
        loading: false,
        options: [],
        pageNo: 1,
        total: 0,
        remoteMethod: null
      }
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
        userInfo: 'user/userInfo',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },

    watch: {
      supplier: {
        deep: true,
        handler: function (val) {
          // 多选单选数据格式统一
          val = Array.isArray(val) ? val : [val]
          this.$emit('fuzzy-choose', val)
        },
      },
      defaultOrderCode: {
        deep: true,
        handler: function (val) {
          this.initSupplier(val)
        },
      },
    },
    created() {
      this.remoteMethod = debounce((query) => {
        this.loading = true
        this.query = query || this.supplier[this.codeKey]

        this._getOptions()
      }, 0)
    },
    methods: {
      onBlur(val) {
        this.options = []
        if (!val) {
          this.$refs.sel.blur()
        }
      },
      initSupplier(supplier) {
        if (supplier?.order_id) {
          this.options = [supplier]
        } else {
          this.options = supplier
        }
        this.supplier = supplier
      },
      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },



      _reset() {
        this.pageNo = 1
        this.total = 0
        this.options = []
      },
      async _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        let reqMethod
        switch (this.reqType) {
          case 'OUT_PROCESS':
            reqMethod = OutProcessing.getSalesOrderCodeList
            break
          case 'MAIL':
            reqMethod = OrderInteractor.pageSalesOrderRendApi
            break
          default:
            reqMethod = OutProcessing.getSalesOrderCodeList
        }
        if (!this.query || !this.query?.trim()) {
          this.loading = false
          this.options = []
          return
        }
        const res = await reqMethod({
          pageLe: this.pageLe,
          pageNo: this.pageNo,
          salesOrderCode: this.query?.trim(),
        })

        if (res && res.code === '000000') {
          this.loading = false
          this.options = loadMore
            ? this.options.concat(res.data.data)
            : res.data.data
          this.total = res.data.total
        } else {
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-select-dropdown__item .wrapper {
    display: flex;

    span {
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tl {
    text-align: left;
    padding-right: 5px;
  }

  .tr {
    text-align: right;
    padding-left: 5px;
    color: #909399;
  }

  .el-select {
    width: 100%;
  }

  .el-form-item__content .user-sel-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .user-sel-select .el-select-dropdown__list {
    height: 200px !important;

    overflow: auto;
    overflow-x: hidden;
  }

  .user-sel-select .el-select-dropdown__list li {
    width: 100% !important;
  }
</style>
