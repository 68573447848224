<!--
  功能：订单结算
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年04月06日 09:24:11
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="custom-container">
    <el-row v-if="!$route.query.noReturn">
      <el-page-header @back="goBack">
        <p slot="content">
          <span>{{ settlementOrder | emptyFilter }}</span>
          <span class="header-text ml10">
            结算专员: {{ settlementAttach | emptyFilter }}
          </span>
          <span class="header-text ml10">
            结算时间: {{ settlementDate | emptyFilter }}
          </span>
          <span class="header-text ml10">审核单号:</span>
          <span
            class="page-link fs14"
            @click="statusTypeClick"
            v-if="auditNumber"
          >
            {{ auditNumber }}
          </span>
          <span v-else class="header-text fs14 ml5">--</span>
        </p>
      </el-page-header>
    </el-row>
    <div class="detail-content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="结算详情" name="TAB_DETAIL">
          <OrderSettlementTable
            :list="list"
            ref="table"
            :key="list.length"
            contentType="detail"
            :tableHeight="tableHeight"
          />
        </el-tab-pane>
        <el-tab-pane
          label="人员提成汇总"
          name="TAB_SUM"
          v-if="settlementStatus === '5'"
        >
          <PersonnelCommissionSummary
            :summaryList="summaryList"
            @download="onDownload"
            :settlementId="settlementId"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 审核进度 -->
    <!-- 审核进度Drawer -->
    <el-drawer
      title="审核进度"
      :visible.sync="statusDrawer"
      :before-="statusClose"
    >
      <Audit
        ref="Audit"
        :auditObjectId="settlementId"
        :auditType="auditType"
        @cancelClick="statusClose"
      />
    </el-drawer>
  </div>
</template>

<script>
  import OrderSettlementTable from './components/order-settlement-table'
  import PersonnelCommissionSummary from './components/personnel-commission-summary'
  import {
    listCommissionSummary,
    exportSummary,
    getDetailBySettlementId,
  } from '@/api/order-settlement'

  import {} from '@/api/order-settlement'

  import Audit from '@/components/Audit/ApprovalsDrawer'
  export default {
    name: 'SettlementDetail',
    components: {
      OrderSettlementTable,
      PersonnelCommissionSummary,
      Audit,
    },
    // 组件状态值
    data() {
      return {
        list: [],
        activeName: 'TAB_DETAIL',
        statusDrawer: false,
        auditType: 12,
        summaryList: [],
        tableHeight: 400,
      }
    },
    computed: {
      settlementOrder() {
        //结算单号
        return this.list[0]?.settlementOrder
      },

      settlementId() {
        return this.list[0]?.settlementId
      },
      settlementAttach() {
        //结算专员
        return this.list[0]?.settlementAttach
      },
      settlementDate() {
        //结算日期
        return this.list[0]?.settlementDate
      },
      // 审核单号
      auditNumber() {
        return this.list[0]?.auditNumber
      },
      settlementStatus() {
        return this.list[0]?.settlementStatus
      },
    },

    methods: {
      // 返回
      goBack() {
        this.$router.push('/finance-center/order-settlement')
      },
      //审核进度查看
      statusTypeClick() {
        this.statusDrawer = true
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      onDownload() {
        exportSummary(this.$route.query.settlementId).then((res) => {
          var linkElement = document.createElement('a')
          var blob = new Blob([res])
          var url = window.URL.createObjectURL(blob)
          linkElement.setAttribute('href', url)

          linkElement.setAttribute(
            'download',
            `${this.settlementOrder}人员提成汇总.xls`
          )
          document.body.appendChild(linkElement)
          linkElement.click()
          URL.revokeObjectURL(linkElement.href) // 释放URL 对象
        })
      },
      // 获取表格高度
      getTableHeight() {
        const wrapperH = document
            .querySelector('.custom-container')
            ?.getBoundingClientRect().height,
          height = wrapperH - 200

        this.tableHeight = height
      },
    },

    filters: {
      emptyFilter(val) {
        return val ? val : '--'
      },
    },

    created() {
      const { settlementId, isEdit } = this.$route.query
      getDetailBySettlementId(settlementId).then((res) => {
        if (res?.code === '000000') {
          this.list = res.data
        }
      })

      listCommissionSummary(settlementId).then((res) => {
        if (res?.code === '000000') {
          // 处理合计展示
          if (res?.data?.commissionVOList?.length > 0) {
            let fields = [
                'businessCommission',
                'businessManagerCommission',
                'buyerCommission',
                'buyerManagerCommission',
                'orderSupportCommission',
                'orderSupportManagerCommission',
                'productOperatingCommission',
                'productOperatingManagerCommission',
              ],
              total2Field = {}
            fields.forEach((i) => {
              total2Field[i] =
                res.data['total' + i[0].toUpperCase() + i.substring(1)]
            })
            total2Field.totalCommission = res.data.totalCommission
            total2Field.userName = '合计'
            total2Field.area = '  '
            total2Field.isLink = false
            res.data.commissionVOList.push(total2Field)
          }
          this.summaryList = res.data.commissionVOList
        }
      })
    },
    mounted() {
      setTimeout(() => {
        this.getTableHeight()
      }, 100)
    },
  }
</script>

<style scoped lang="scss">
  .detail-content {
    padding: 0 20px 90px;
  }
  .header-text {
    font-size: 14px;
    color: #606266;
  }
  .fs14 {
    font-size: 14px;
  }
</style>
