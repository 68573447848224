<!--
  功能：订单结算
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年04月06日 09:24:11
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="custom-container" style="calc(100% - 400px)">
    <el-row v-if="!$route.query.noReturn">
      <el-page-header
        @back="goBack"
        :content="settlementOrder"
      ></el-page-header>
    </el-row>
    <div class="detail-content">
      <OrderSettlementTable
        :contentType="contentType"
        :list="list"
        ref="table"
        @confirm="onConfirm"
        @save="onSave"
        :key="list.length"
        :tableHeight="tableHeight"
        class="table-content"
      />
      <!-- 审核模板-->
      <AuditChoose
        ref="AuditChoose"
        @auditData="saveClick"
        @audit-close="onAuditClose"
      />
    </div>
    <el-row
      style="text-align: center; position: fixed; bottom: 10px; width: 100%"
    >
      <el-button type="primary" @click="confirm">确 认</el-button>
      <el-button @click="save">保 存</el-button>
      <el-button @click="cancel">取 消</el-button>
    </el-row>
  </div>
</template>

<script>
  import OrderSettlementTable from './components/order-settlement-table'
  import AuditChoose from '@/components/AuditChoose'

  import {
    listWaitingSettlementInfo,
    orderSettlementConfirm,
    orderSettlementSave,
    getDetailForEdit,
  } from '@/api/order-settlement'
  import { mapGetters } from 'vuex'
  export default {
    name: 'OrderSettlementDetail',
    props: {},
    components: {
      OrderSettlementTable,
      AuditChoose,
    },
    // 组件状态值
    data() {
      return { list: [], contentType: 'add', tableHeight: 400 }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      settlementOrder() {
        return this.$route.query.settlementOrder
      },
    },

    methods: {
      // 返回
      goBack() {
        this.$router.push('/finance-center/order-settlement')
      },
      // 确认
      confirm() {
        this.$refs.table.confirm()
      },

      onConfirm(list) {
        this.submitList = list
        let auditParams = {
          auditType: 12,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(auditParams).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(auditParams)
          }
        })
      },

      // 保存
      save() {
        this.$refs.table.save()
      },

      saveClick(data) {
        let requestData = {
          auditObjectDTO: data,
          settlementProductSaveDTOList: this.submitList,
        }

        orderSettlementConfirm(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('操作成功')
            this.$router.push({
              path: '/finance-center/order-settlement',
            })
          }
        })
      },
      onAuditClose() {},
      onSave(settlementProductSaveDTOList) {
        orderSettlementSave({
          auditObjectDTO: {},
          settlementProductSaveDTOList,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('操作成功')
            this.$router.back()
          }
        })
      },

      // 取消
      cancel() {
        this.$router.push({
          path: '/finance-center/order-settlement',
        })
      },

      // 获取表格高度
      getTableHeight() {
        const wrapperH = document
            .querySelector('.custom-container')
            .getBoundingClientRect().height,
          height = wrapperH - 200

        this.tableHeight = height
      },
    },

    created() {
      let { settlementSalesOrderIds, isEdit } = this.$route.query,
        requestMethod = isEdit ? getDetailForEdit : listWaitingSettlementInfo
      if (!isEdit) {
        settlementSalesOrderIds = settlementSalesOrderIds.split(',')
      }
      requestMethod(settlementSalesOrderIds).then((res) => {
        if (res?.code === '000000') {
          this.list = res.data
        }
      })
    },

    mounted() {
      setTimeout(() => {
        this.getTableHeight()
      }, 100)
    },
  }
</script>

<style scoped lang="scss">
  .detail-content {
    padding: 0 20px 90px;
    position: relative;
  }
  .custom-container {
    overflow-y: hidden;
  }
</style>
