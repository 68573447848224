<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form :model="voidForm" :rules="voidRules" ref="voidForm">
        <el-form-item label="作废原因" prop="statusRemark" label-width="80px">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="500"
            show-word-limit
            v-model.trim="voidForm.statusRemark"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="saveVoid">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { cancellation } from '@/api/finance/credit-manage-list'
export default {
  name: 'CancelModel',
  data() {
    return {
      title: '作废',
      voidForm: {
        financeId: '',
        statusRemark: '', // 作废原因
      },
      voidRules: {
        statusRemark: [
          { required: true, message: '请填写作废原因', trigger: 'blur' },
        ],
      },
      dialogFormVisible: false,
    }
  },
  created() {},
  methods: {
    //显示
    showAddEdit() {
      this.dialogFormVisible = true
    },

    //关闭
    close() {
      this.$refs['voidForm'].resetFields()
      this.voidForm = this.$options.data().voidForm
      this.dialogFormVisible = false
    },
    // 作废
    async cancellationMethods() {
      let params = {
        financeId: this.voidForm.financeId,
        statusRemark: this.voidForm.statusRemark,
      }
      let response = await cancellation(params)
      if (response.code === '000000') {
        this.$message.success('作废成功！')
        this.$emit('updateList')
        this.close()
      }
    },

    // 点击确定作废
    saveVoid() {
      this.$refs['voidForm'].validate(async (valid) => {
        if (valid) {
          this.cancellationMethods()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
.text-hidden {
  width: 150px;
}
</style>
