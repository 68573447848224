<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="客户类型" prop="customerType">
          <el-select
            v-model="form.customerType"
            disabled
            placeholder="客户类型"
          >
            <el-option label="正式客户" :value="1"></el-option>
            <el-option label="潜在客户" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户级别" prop="customerRank">
          <el-rate
            v-model="form.customerRank"
            disabled
            text-color="#ff9900"
          ></el-rate>
        </el-form-item>
        <el-form-item label="超过" prop="followPeriod">
          <el-input v-model.trim="form.followPeriod" placeholder="天">
            <template slot="append">天未跟进，系统提醒</template>
          </el-input>
        </el-form-item>
        <el-form-item label="超过" prop="returnSeas">
          <el-input v-model.trim="form.returnSeas" placeholder="天">
            <template slot="append">天未跟进，自动回归公海</template>
          </el-input>
        </el-form-item>
        <el-form-item label-width="0">
          <p class="tips">Tips：设为0或空时，表示不提醒/不回归公海</p>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row class="t_a_c">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { clientFollowUpdate } from '@/api/client-supplier/client-setting.js'

  export default {
    name: 'ClientSettingTypeModel',
    data() {
      return {
        loading: false,
        title: '客户跟进修改',
        form: {
          customerType: '',
          customerRank: '',
          followPeriod: '',
          returnSeas: '',
        },
        rules: {
          followPeriod: [
            { validator: this.$formValidation.isPositiveInt, trigger: 'blur' },
          ],
          returnSeas: [
            { validator: this.$formValidation.isPositiveInt, trigger: 'blur' },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit(row) {
        if (!row) {
          //this.title = '新增客户来源'
        } else {
          //this.title = '编辑客户来源'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
        // this.form = this.$options.data().form
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            // this.$baseMessage(msg, 'success', false, 'vab-hey-message-success')
            let { followId, followPeriod, returnSeas } = this.form
            this.loading = true
            clientFollowUpdate({
              followId,
              followPeriod,
              returnSeas,
            }).then((res) => {
              if (res.code === '000000') {
                this.$message.success('修改成功！')
                this.$emit('success')
                this.close()
              }
            }).finally(() => {
              this.loading = false
            })
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  .tips {
    color: #999;
    font-size: 12px;
    padding-left: 12px;
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
    .el-rate {
      width: 100%;
      margin-top: 6px;
    }
  }
</style>
