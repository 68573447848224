<template>
  <div class="pro-container">
    <el-row
      class="mt20 test"
      v-for="(item, index) of specArray"
      :key="item.type + index"
    >
      <el-row
        v-if="specArray.length === 2 && index === 0"
        type="flex"
        class="align_center"
      >
        <el-col :span="2">
          <h4>{{ item.type }}</h4>
        </el-col>
        <el-col :span="20">
          <el-row>
            <el-tag
              v-for="(tag, tagIndex) in item.value"
              :key="tagIndex"
              size="medium"
              type="info"
              class="c_pointer"
              v-model="form.specsValue1"
              @click="specClick(tag, tagIndex)"
              :class="{ active: tagIndex === specValue1CurrentIndex }"
            >
              {{ tag }}
            </el-tag>
          </el-row>
        </el-col>
      </el-row>
    </el-row>

    <el-row>
      <el-col :span="2" style="color: #fff">.</el-col>
      <el-col :span="20" class="mt20">
        <table class="product_info_table">
          <tr>
            <td style="width: 20%">数量</td>
            <td v-for="(item, index) of productPricingStepVOList" :key="index">
              {{ item.num }}
            </td>
          </tr>
          <tr>
            <td style="width: 20%">List Price</td>
            <td v-for="(item, index) of productPricingStepVOList" :key="index">
              {{ item.listPrice }}
            </td>
          </tr>
          <tr>
            <td style="width: 20%">Net Price</td>
            <td v-for="(item, index) of productPricingStepVOList" :key="index">
              {{ item.netPrice }}
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>

    <!-- 购买数量 -->
    <el-row :gutter="20" class="mt20">
      <el-col :span="2">
        <h4>购买数量</h4>
      </el-col>
      <el-col :span="20">
        <el-row>
          <table class="product_info_table">
            <tr>
              <td style="width: 20%">规格</td>
              <td v-for="(item, index) of tableData" :key="index">
                {{ item.specsValue2 }}
              </td>
            </tr>
            <tr>
              <td style="width: 20%">数量</td>
              <td v-for="(item, index) of tableData" :key="index">
                <el-input
                  style="width: 80%"
                  type="number"
                  v-model="item.num"
                  placeholder="数值"
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  clearable
                ></el-input>
              </td>
            </tr>
          </table>
        </el-row>
        <p class="mt15">
          总数：
          <span class="f_s_18 f_w_7">{{ nums }}</span>
        </p>
      </el-col>
    </el-row>
    <!-- 印刷 -->
    <el-row
      type="flex"
      class="mt20 pt20 mb20 align_center"
      style="border-top: 1px solid #ededed"
    >
      <el-col :span="2">
        <h4>印刷方式</h4>
      </el-col>
      <el-col :span="20">
        <el-tag
          v-for="(tag, index) in printWay"
          :key="index"
          size="medium"
          class="c_pointer"
          type="info"
          @click="tagsClick(tag, index)"
          :class="{ active: tag.id === currentIndex }"
        >
          {{ tag.nameCn }}
        </el-tag>
      </el-col>
    </el-row>
    <el-row v-for="(item, index) of productPrintingWayVOList" :key="index">
      <template v-if="item.printingId === currentIndex">
        <el-row>
          <el-col :span="2">
            <h4>印刷位</h4>
          </el-col>
          <el-col :span="20">
            <el-checkbox-group v-model="positionNum">
              <el-checkbox-button
                v-for="(tag, tagIndex) in item.printingPositions"
                :label="tag.nameEn"
                :key="tagIndex"
              ></el-checkbox-button>
            </el-checkbox-group>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mt20">
          <el-col :span="2">
            <h4>计价方式</h4>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-select
                v-model="item.printValue"
                placeholder="请选择"
                style="width: 200px"
                collapse-tags
                @change="test(item.printingId)"
              >
                <el-option
                  v-for="(item2, k) in JSON.parse(item.printingWay)"
                  :key="k"
                  :label="item2.way"
                  :value="item2.way"
                ></el-option>
              </el-select>
            </el-row>
          </el-col>
        </el-row>
      </template>
    </el-row>

    <!-- 收货地址 -->
    <el-row
      :gutter="20"
      type="flex"
      class="mt20 pt20 align_center"
      style="border-top: 1px solid #ededed"
    >
      <el-col :span="2">
        <h4>收货地址</h4>
      </el-col>
      <el-col :span="20">
        <el-row type="flex" class="align_center" style="padding-left: -10px">
          <el-select
            v-model="form.freightArea"
            placeholder="请选择"
            style="width: 200px"
          >
            <el-option
              v-for="(item, index) in listFreightArea"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          <p class="ml20">
            预计到达时间：
            <span class="f_w_7">{{ allPrice.transportDay || 0 }}</span>
            天
          </p>
          <p class="ml20">
            运费：
            <span class="f_w_7" v-if="productInfo.calcFreight === 1">
              $&nbsp;{{ allPrice.transportPrice || 0 }}
            </span>
            <span class="f_w_7" v-else>免运费</span>
          </p>
        </el-row>
      </el-col>
    </el-row>
    <!-- 总计费用 -->
    <el-row class="mt20 pt20" style="border-top: 1px solid #ededed">
      <el-row type="flex" class="align_center">
        <el-col>
          <el-button
            @click="calcuClick"
            type="primary"
            :loading="calcuClickLoading"
          >
            总计费用计算
          </el-button>
        </el-col>
      </el-row>
      <el-row class="mt20 align_center" type="flex">
        <p>
          List Price
          <span class="f_w_7 f_s_16 price-red ml10">
            $&nbsp;{{ allPrice.listPrice || 0 }}
          </span>
        </p>
        <p class="ml20">
          Net Price
          <span class="f_w_7 f_s_16 price-red ml10">
            $&nbsp; {{ allPrice.netPrice || 0 }}
          </span>
        </p>
      </el-row>

      <el-row class="mt20">
        <el-link type="primary" @click="hideChargesClick">Hide Charges</el-link>
      </el-row>
      <el-row v-if="hideCharges">
        <el-row type="flex" class="align_center mt15">
          <p>
            {{ allPrice.calcDetail }}
          </p>
        </el-row>
        <el-row
          class="mt10"
          v-if="form.pricingId !== 0 && form.pricingId !== -1"
        >
          <p>
            印刷费
            <span>$&nbsp;{{ allPrice.printingPrice || 0 }}</span>
          </p>
          <p class="mt10">
            版费
            <span>$&nbsp;{{ allPrice.toolingCharge || 0 }}</span>
          </p>
        </el-row>
        <el-row class="mt10" v-else-if="form.pricingId === -1">
          <p>
            打样费
            <span>$&nbsp;{{ allPrice.proofingFee || 0 }}</span>
          </p>
          <p></p>
        </el-row>
      </el-row>
    </el-row>
  </div>
</template>

<script>
  import { deepCopy } from 'kits'
  import { listProductSettingByIds } from '@/api/product/productSetting'
  import { calcProudctPrice, listFreightArea } from '@/api/product/productList'
  export default {
    name: 'ProductPrice',
    props: {
      infoParames: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        productInfo: {},
        options: [{ id: '001', label: '暂无收货地址功能' }],
        value: '',
        radio2: '上海', //印刷方式
        checkboxGroup2: ['上海'], //印刷位
        cities: ['上海', '北京', '广州', '深圳'],
        input: '', //table input输入框
        productPrintingWayVOList: [],
        hideCharges: false,
        input1: 0,
        input2: 0,
        input3: 0,
        printWay: [],
        currentIndex: 0,
        childCurrentIndex: 0,
        productSpecsShowVOList: [],
        nums: 0,
        tableSpecsType: [],
        printValue: [], //选择的计价方式
        productPricingStepVOList: [],
        form: {
          positionNum: null, //印刷位数
          pricingId: '', //印刷方式id
          pricingValue: '', //计价方式
          productId: null, //产品id
        },
        specValue1CurrentIndex: null,
        positionNum: [],
        allPrice: {},
        tableData: [],
        listFreightArea: [],
        address: '',
        calcuClickLoading: false,
      }
    },
    computed: {
      typeArr: {
        get() {
          if (this.productInfo.specsType) {
            let typearr = this.productInfo.specsType.split(',')
            return typearr
          }
        },
      },
      specArray: {
        get() {
          if (this.productInfo.specsType && this.productInfo.specsValue) {
            let typearr = this.productInfo.specsType.split(',')
            let svalue = this.productInfo.specsValue
            let repalcevalue = svalue.replace(/\'/g, '"')
            let valarr = [[]]
            valarr = JSON.parse(repalcevalue)

            let arr = []
            for (let i = 0; i < typearr.length; i++) {
              let obj = {
                type: typearr[i],
                value: valarr[i],
              }
              arr.push(obj)
            }

            return arr
          }
        },
      },
    },
    watch: {
      infoParames: {
        immediate: true,
        // deep: true,
        handler(value) {
          if (!value.productId) return
          this.form.productId = value.productId
          this.productInfo = value
          //规格转换
          //规格列表的规格字段
          this.tableSpecsType = []
          if (this.productInfo.specsType) {
            let specsType = this.productInfo.specsType.split(',')
            specsType.forEach((item) => {
              this.tableSpecsType.push(item)
            })
          }

          if (value.specsType) {
            let specsType = value.specsType.split(',')
            let specsValue = JSON.parse(value.specsValue.replace(/\'/g, '"'))
            this.tableData = []

            if (specsType.length === 1) {
              specsValue[0].forEach((item) => {
                let obj = {
                  specName: specsType[0],
                  num: 0,
                  specsValue2: item,
                }
                this.tableData.push(obj)
              })
            } else {
              specsValue[1].forEach((item) => {
                let obj = {
                  specName: specsType[1],
                  num: 0,
                  specsValue2: item,
                }
                this.tableData.push(obj)
              })
            }
          }

          if (this.productInfo.productSpecsShowVOList) {
            this.productSpecsShowVOList = deepCopy(
              this.productInfo.productSpecsShowVOList
            )
          }
          if (this.productInfo.productPricingStepVOList) {
            this.productPricingStepVOList = deepCopy(
              this.productInfo.productPricingStepVOList
            )
          }

          //印刷方式
          if (this.productInfo.printingWay) {
            let test = this.productInfo.printingWay.split('#')
            if (test[0] === '1') {
              this.printWay.push({ id: '99991', nameCn: 'Blank' })
            }
            if (test[1] === '1') {
              this.printWay.push({ id: '99992', nameCn: 'Sample' })
            }
            let yin = []
            yin = JSON.parse(test[2])
            if (yin.length) {
              yin.forEach((item) => {
                this.printWay.push(item)
              })
            }

            if (this.productInfo.productPrintingWayVOList) {
              this.productPrintingWayVOList =
                this.productInfo.productPrintingWayVOList

              Object.assign(
                {},
                { ...this.productPrintingWayVOList, printValue: [] }
              )
              this.productPrintingWayVOList.forEach((item, index) => {
                item.nameCn = yin[index].nameCn
                this.getListProductSettingByIds(item.printingPositions, index)
              })
            }
          }
        },
      },
      //监听表格数据
      tableData: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          let test = value
          this.nums = 0
          test.forEach((item, index) => {
            if (Number(item.num)) {
              this.nums += Number(item.num)
            }
          })
        },
      },
    },

    created() {
      this.getlistFreightArea()
    },
    mounted() {},
    methods: {
      //收货地址
      async getlistFreightArea() {
        let response = await listFreightArea({})
        if (response.code === '000000') {
          this.listFreightArea = response.data
        }
      },
      //计算价格
      calcuClick() {
        this.allPrice = {}
        if (
          this.specArray.length === 2 &&
          typeof this.form.specsValue1 === 'undefined'
        ) {
          return this.$baseMessage(
            `请先选择${this.specArray[0].type}`,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        let flag = this.tableData.some((element) => {
          return element.num > 0
        })
        if (!flag) {
          return this.$baseMessage(
            '至少填写一个购买数量值',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        if (this.form.pricingId === '') {
          return this.$baseMessage(
            '请选择印刷方式',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        this.calcuClickLoading = true
        //1、判断当前有几个规格值，如果1个，那么第一行不显示
        this.form.positionNum = this.positionNum.length
        this.form.pricingId = Number(this.form.pricingId)
        let specsNumList
        specsNumList = this.tableData.map((item) => {
          return {
            num: Number(item.num),
            specsValue2: item.specsValue2,
          }
        })
        let params = {
          ...this.form,
          specsNumList: specsNumList,
        }
        this.getCalcProudctPrice(params)
      },
      // 印刷位
      async getListProductSettingByIds(ids, index) {
        let response = await listProductSettingByIds({ ids: ids })
        this.productPrintingWayVOList[index].printingPositions = response.data
      },
      //价格计算
      async getCalcProudctPrice(params) {
        let response = await calcProudctPrice(params)
        this.calcuClickLoading = false
        if (response.code == '000000') {
          this.allPrice = response.data
          this.calcuClickLoading = false
        }
      },
      //印刷方式切换click
      tagsClick(tag, index) {
        if (tag.nameCn.toUpperCase() === 'BLANK') {
          this.form.pricingId = 0
        } else if (tag.nameCn === 'Sample') {
          this.form.pricingId = -1
        } else {
          this.form.pricingId = tag.id
        }
        this.currentIndex = tag.id
        this.childCurrentIndex = 0
        this.positionNum = []
        this.form.pricingValue = ''
        this.productPrintingWayVOList.forEach((item) => {
          if (item.printValue) {
            item.printValue = ''
          }
        })
      },

      // 规格选择
      specClick(item, index) {
        this.form.specsValue1 = item
        this.specValue1CurrentIndex = index
      },

      test(id) {
        let pricingValue = ''
        this.productPrintingWayVOList.some((item) => {
          if (item.printingId == id) {
            pricingValue = item.printValue.toString()
          }
        })
        this.form.pricingValue = pricingValue
      },

      //价格隐藏
      hideChargesClick() {
        this.hideCharges = !this.hideCharges
      },
      rowClassName({ row, rowIndex }) {},
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    margin-bottom: 50px;
  }
  .product_info_table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      height: 50px;
      text-align: center;
      border: 1px solid #dcdfe6;
    }
  }
  .price-red {
    color: red;
  }
  .c_pointer.active {
    background-color: #e8f4ff;
    border-color: #d1e9ff;
    color: #1890ff;
  }
  ::v-deep {
    .el-checkbox-group {
      .el-checkbox-button + .el-checkbox-button {
        margin-left: 10px;
        border-left: 1px solid #f2f2f2;
      }
      .el-checkbox-button__inner {
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        background-color: #f2f2f2;
        border-color: rgba(228, 228, 228, 0.93);
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .el-checkbox-button.is-checked .el-checkbox-button__inner {
      color: #1890ff;
      background-color: #e8f4ff;
      border-color: #e8f4ff;
      box-shadow: -1px 0 0 0 #e8f4ff;
    }
  }
</style>
