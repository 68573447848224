<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="50%"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-row :gutter="20">
        <el-col :span="5">
          <span>运输方式</span>
          <p>{{ detail.transportWayCn || '--' }}</p>
        </el-col>
        <el-col :span="4">
          <span>英文名称</span>
          <p>{{ detail.transportWayEn }}</p>
        </el-col>
        <el-col :span="4">
          <span>运输类型</span>
          <p>{{ detail.transportType | transportTypeFilter }}</p>
        </el-col>
        <el-col :span="4">
          <span>报价币种</span>
          <p>{{ detail.currency || '--' }}</p>
        </el-col>
        <el-col :span="3">
          <span>运输周期</span>
          <p>{{ detail.transportCycle || '--' }}</p>
        </el-col>
        <el-col :span="4">
          <span>计费方式</span>
          <p>{{ detail.billingMethod | billingMethodFilter }}</p>
        </el-col>
      </el-row>
      <el-row class="mt20">
        <span>计费阶梯</span>
        <table class="step-table">
          <thead>
            <tr>
              <td class="w70">序号</td>
              <td>送达地区</td>
              <td v-for="(item, index) of tableCol" :key="index">
                <span v-if="detail.billingMethod == 4">1:{{ item }}</span>
                <span v-else>
                  {{ item }}
                </span>
              </td>
              <td>派送方式</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) of tableData" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.deliveryArea }}</td>
              <td
                v-for="(test, index2) of item.ladderAndPriceVOS"
                :key="index2"
              >
                {{ test.price || '--' }}
              </td>
              <td>{{ item.deliveryMethod || '--' }}</td>
            </tr>
          </tbody>
        </table>
      </el-row>
      <el-row class="mt20 row-line">
        <span>备注</span>
        <p>{{ detail.remark || '--' }}</p>
      </el-row>
      <el-row :gutter="20" class="mt20">
        <el-col :span="6">
          <span>创建人</span>
          <p>{{ detail.createName }}</p>
        </el-col>
        <el-col :span="6">
          <span>创建时间</span>
          <p>{{ detail.createTime }}</p>
        </el-col>
        <el-col :span="6">
          <span>最近更新人</span>
          <p>{{ detail.updateName || '--' }}</p>
        </el-col>
        <el-col :span="6">
          <span>最近更新时间</span>
          <p>{{ detail.updateTime || '--' }}</p>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">关闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { freighShow } from '@/api/product/freightSettings'
import { freightType } from '@/utils/en-match-zh'
export default {
  name: 'FreightDetailModel',
  data() {
    return {
      title: '查看',
      detail: {},
      tableData: [], //计费阶梯
      tableCol: [],
      dialogFormVisible: false,
    }
  },
  filters: {
    //运输方式
    transportTypeFilter(status) {
      const transportType = {
        1: '空运',
        2: '海运',
        3: '快递',
      }
      return transportType[status]
    },
    //计费方式
    billingMethodFilter(status) {
      const billingMethod = {
        1: '体积',
        2: '毛重、体积重(体积/6000) 取Max',
        3: '毛重、体积重(体积/5000) 取Max',
        4: '体积/毛重',
      }
      return billingMethod[status]
    },
  },
  methods: {
    showAddEdit(id) {
      this.getFreighShow(id)
      this.dialogFormVisible = true
    },
    //详情
    async getFreighShow(id) {
      let response = await freighShow({ freightSettingId: id })
      if (response.code === '000000') {
        this.detail = response.data

        // 计费阶梯数据
        let tableCol = []
        let tableData = response.data.freightSettingLadderVOS
        tableData.forEach((item) => {
          item.ladderAndPriceVOS.forEach((item2, index2) => {
            tableCol.push(item2.billingLadder)
          })
        })
        tableCol = Array.from(new Set(tableCol))
        tableData.forEach((item) => {
          tableCol.forEach((item2) => {
            item.ladderAndPriceVOS.forEach((item3) => {
              if (item2 !== item3.billingLadder && item3.price !== '--') {
                item.ladderAndPriceVOS.push({
                  billingLadder: item2,
                  price: '--',
                })
              }
            })
          })
        })
        tableData.forEach((item) => {
          let obj = {}
          let open = item.ladderAndPriceVOS.reduce((cur, next) => {
            obj[next.billingLadder]
              ? ''
              : (obj[next.billingLadder] = true && cur.push(next))
            return cur
          }, [])
          item.ladderAndPriceVOS = open.sort(
            this.compare('billingLadder', true)
          )
        })
        this.tableCol = tableCol.sort(this.sortNum)
        this.tableData = tableData
      }
    },
    //数值排序
    sortNum(a, b) {
      return a - b
    },
    compare(property, desc) {
      return function (a, b) {
        var value1 = a[property]
        var value2 = b[property]
        if (desc == true) {
          return value1 - value2
        } else {
          return value2 - value1
        }
      }
    },
    //关闭
    close() {
      this.dialogFormVisible = false
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-content {
  .el-row {
    span {
      color: #666;
    }
    p {
      font-size: 16px;
      color: #000;
      margin-top: 10px;
    }
  }
  .row-line {
    padding-bottom: 20px;
    border-bottom: 1px solid #eaebed;
  }
  .step-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    thead {
      background: #f5f7fa;
    }
    td {
      height: 38px;
      line-height: 38px;
      text-align: center;
      padding: 0 10px;
      border: 1px solid #ebeef5;
    }
  }
}
.w70 {
  width: 70px;
}
</style>
