<template>
  <div class="table-container">
    <el-row :gutter="20">
      <!-- 客评中心消息通知-->
      <el-col class="col" :span="10">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">客评中心消息通知</p>
          </el-row>
          <el-table
            class="mt30"
            :data="tableData"
            border
            :max-height="tableHeight(170)"
          >
            <el-table-column
              prop="operationName"
              align="center"
              width="160"
              label="类型"
            ></el-table-column>
            <el-table-column
              prop="customerRank"
              align="center"
              label="通知方式"
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.emailNotice"
                  :true-label="1"
                  :false-label="0"
                >
                  邮件通知
                </el-checkbox>
                <el-checkbox
                  v-model="scope.row.messageNotice"
                  :true-label="1"
                  :false-label="0"
                >
                  系统消息通知
                </el-checkbox>
              </template>
            </el-table-column>
          </el-table>
          <div class="operate">
            <el-button type="primary" @click="save">保存</el-button>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    getDetail,
    insertCustomerMessageConfigurePO,
  } from '@/api/customer-manage/customer-setting'

  export default {
    name: 'CustomerSetting',
    components: {},
    data() {
      return {
        tableData: [],
      }
    },
    computed: {
      // ...mapState({
      //   userInfo: (state) => state.user.userInfo,
      // }),
    },

    created() {
      getDetail().then((res) => {
        if (res.code === '000000') {
          this.tableData = res.data
        }
      })
    },
    methods: {
      // 表格最大高度计算
      tableHeight(num = 0) {
        return window.innerHeight - num + 'px'
      },
      save() {
        insertCustomerMessageConfigurePO(
          this.tableData.map((item) => {
            let obj = {}
            obj.id = item.id
            obj.emailNotice = item.emailNotice
            obj.messageNotice = item.messageNotice
            return obj
          })
        ).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('操作成功')
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .operate {
    text-align: center;
    margin-top: 20px;
  }
  .el-table {
    width: 100%;
  }
  .main-height {
    height: calc(100% - 52px);
  }
  .table-height-box {
    height: calc(100% - 4px);
    overflow: hidden;
    overflow-y: auto;
  }
  .col {
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
  }
  .upper-limit {
    border: 1px solid #ededed;
    padding: 10px;
    margin-bottom: 20px;
    .desc {
      line-height: 32px;
      font-size: 14px;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      strong {
        margin-right: 10px;
      }
      > span {
        flex: 1;
      }
    }
  }
  .public-col {
    border: 1px solid #ededed;
    padding: 10px;
    flex: 1;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: auto;

    p {
      line-height: 32px;
    }
  }
  .public-col2 {
    border: 1px solid #ededed;
    padding: 10px;
    p {
      line-height: 32px;
    }
  }
  ::v-deep {
    .el-tag {
      border: none;
    }
    .el-tag--plain {
      background: none;
    }
  }
</style>
