<template>
  <el-dialog
    :title="isEn ? 'Paper Check Review' : '纸质支票审核'"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="650px"
    :close-on-click-modal="false"
  >
    <div class="container">
      <div class="flex">
        <i class="el-icon-warning" />
        <div class="content" v-if="isEn">
          <div>
            Please review the paper checks uploaded by mall customers carefully,
            Key points:
          </div>
          <div>
            1. Payee name filled in accurately (e.g. Rivers Promo Inc.);
          </div>
          <div>
            2. Amount in upper and lower case are the same (e.g. 70 corresponds
            to Seventy and no/100 ********* Dollars);
          </div>
          <div>3. Customer's signature provided;</div>
          <div>
            If one of these information above is inaccurate, inconsistent or
            missing, please reject the check.
          </div>
        </div>
        <div class="content" v-else>
          <div>请仔细审核客户上传的纸质支票，重点关注：</div>
          <div>1.收款方填写是否准确（如Rivers Promo Inc.）；</div>
          <div>
            2.大小写金额是否一致（如70 对应 Seventy and no/100 *********
            Dollars）；
          </div>
          <div>3.是否有客户签名；</div>
          <div>若信息不准确、不一致或缺失，请驳回该支票。</div>
        </div>
      </div>
      <div class="fileList"> 
        <template  v-for="item of imgList"
        >
          <el-image 
            style="width: 175px; height: auto"
           :src="item.path"
           fit="contain"
            :preview-src-list="previewImgList">
          </el-image>
        </template>
        <span v-for="item of pdfList" :key="item.path" class="pdf">
          <i class="el-icon-document" />
          <el-tooltip
            effect="dark"
            :content="item.fileName"
            placement="top-start"
          >
            <span class="pdfName" @click="handlePreviewPdf(item)">
              {{ item.fileName }}
            </span>
          </el-tooltip>
        </span>
      </div>
    </div>
    <template slot="footer">
      <el-popover
        v-model="popoverVisible"
        placement="top"
        :title="$t('auditManage.TurnDown')"
        width="500"
        trigger="manual"
        v-clickoutside="cancelClick"
      >
        <el-row>
          <el-form ref="formRef" :model="form">
            <el-form-item
              :label="$t('auditManage.OpinionOfRejection')"
              prop="rejectRemark"
              :rules="[
                {
                  required: true,
                  message: isEn
                    ? 'please input opinion of rejection'
                    : '请输入驳回意见',
                  trigger: 'change',
                },
              ]"
            >
              <el-input
                type="textarea"
                :placeholder="$t('placeholder.plsInput')"
                v-model="form.rejectRemark"
                maxlength="500"
                show-word-limit
                :autosize="{ minRows: 6, maxRows: 8 }"
                clearable
              />
            </el-form-item>
          </el-form>
          <div style="text-align: right">
            <el-button type="primary" @click="handleRejectConfirm">
              <!-- 确认 -->
              {{ $t('auditManage.Confirm') }}
            </el-button>
            <el-button @click="cancelClick">
              <!-- 取消 -->
              {{ $t('auditManage.Cancel') }}
            </el-button>
          </div>
        </el-row>
        <el-button slot="reference" @click="popoverVisible = true">
          <!-- 驳回 -->
          {{ $t('auditManage.TurnDown') }}
        </el-button>
      </el-popover>
      <el-button type="primary" @click="handleAgree">
        <!-- 同意 -->
        {{ $t('auditManage.Agreement') }}
      </el-button>
    </template>
  </el-dialog>
</template>
<script>
  import { MallOrderInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  export default {
    name: 'TestDialog',
    props: { value: { type: Boolean, default: false }, row: { type: Object } },
    data() {
      return {
        popoverVisible: false,
        form: { rejectRemark: '' },
        imgList: [],
        pdfList: [],
        showViewer:false
      }
    },
    computed: {
      dialogVisible: {
        get() {
          if (this.value) this.getFileList(this.row.id)
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },
      ...mapGetters({ lang: 'settings/language' }),
      isEn() {
        return this.lang === 'en'
      },
      previewImgList(){
        return this.imgList.map(item=>item.path)
      }
    },
    methods: {
      handleAgree() {
        this.$router.push(`/order/mallOrder/dea-with?id=${this.row.id}`)
        this.handleClose()
      },
      handleClose() {
        this.imgList = []
        this.pdfList = []
        this.dialogVisible = false
        this.cancelClick()
      },
      // 驳回取消
      cancelClick() {
        this.$refs.formRef.resetFields()
        this.popoverVisible = false
      },
      // 驳回确认
      handleRejectConfirm() {
        this.$refs.formRef.validate(async (valid) => {
          if (!valid) return
          const params = {
            mallOrderId: this.row.id,
            rejectRemark: this.form.rejectRemark,
          }
          const { code } = await MallOrderInteractor.rejectMaillOrderApi(params)
          if (code === '000000') {
            this.handleClose()
            this.$emit('refresh')
            this.$message.success('操作成功')
          }
        })
      },
      async getFileList(id) {
        const { code, data } = await MallOrderInteractor.getMallOrderFileVOApi(
          id
        )
        if (code === '000000' && Array.isArray(data)) {
          for (const { suffix, fileName, path } of data) {
            if (suffix === 'pdf') {
              if (fileName) this.pdfList.push({ fileName, path })
            } else {
              if (fileName) this.imgList.push({ fileName, path })
            }
          }
        }
      },
      handlePreviewPdf({ fileName, path }) {
        const routeData = this.$router.resolve({
          path: '/preview-file/index',
          query: { name: fileName, url: path },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    max-height: 500px;
    overflow: auto;
    .flex {
      display: flex;
      .el-icon-warning {
        margin-right: 10px;
        color: #ffba00;
        font-size: 24px;
      }
      .content {
        line-height: 1.6;
        text-align: justify;
        word-break: break-word; // 不要截断单词
        > div:last-of-type {
          margin-top: 10px;
        }
      }
    }
    .fileList {
      margin-top: 10px;
      padding-left: 34px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      img {
        margin-bottom: 8px;
        width: 175px;
        height: 100px;
        cursor: pointer;
      }
      .pdf {
        display: flex;
        align-items: center;
        height: 175px;
        line-height: 175px;
        text-align: center;
        .pdfName {
          width: 70%;
          margin-left: 8px;
          color: #1890ff;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  /deep/.el-dialog__footer {
    text-align: center;
    .el-button--primary {
      margin-left: 10px;
    }
  }
</style>
