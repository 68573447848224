<template>
  <div class="search-form">
    <el-form class="search-form-content" ref="form" :model="form" label-position="top">
      <el-form-item label="收款方">
        <el-input v-model="form.accountName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="确认日期">
        <el-date-picker clearable v-model="confirmDaterange" type="daterange" value-format="yyyy-MM-dd"
          start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
          @change="confirmDaterangeChange" />
      </el-form-item>
      <el-form-item label="费用类型">
        <el-select v-model="form.costId" placeholder="请选择" class="w100">
          <el-option v-for="item in costypeOptions" :key="item.financeSetupId" :label="item.financeName"
            :value="item.financeSetupId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="币种">
        <el-select v-model="form.currency" placeholder="请选择" class="w100" clearable>
          <el-option v-for="item in currencys" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款金额" class="label-style">
        <el-form-item style="width: 45%; display: inline-block">
          <el-input-number placeholder="请输入" v-model="form.startPayMoney" :controls="false"
            style="width: 100%"></el-input-number>
        </el-form-item>
        <span style="padding: 0 18px">至</span>
        <el-form-item style="width: 45%; display: inline-block">
          <el-input-number placeholder="请输入" v-model="form.endPayMoney" :controls="false"
            style="width: 100%"></el-input-number>
        </el-form-item>
      </el-form-item>
      <el-form-item label="付款日期">
        <el-date-picker v-model="payDaterange" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" @change="payDaterangeChange" />
      </el-form-item>
      <el-form-item label="单据来源">
        <el-select v-model="form.source" placeholder="请选择" class="w100" clearable>
          <el-option label="销售单新增" :value="0"></el-option>
          <el-option label="采购单新增" :value="1"></el-option>
          <el-option label="费用导入" :value="2"></el-option>
          <el-option label="委托单导入" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公账付款">
        <el-select v-model="form.isPublic" placeholder="请选择" class="w100" clearable>
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款账户">
        <el-select v-model="form.payId" filterable class="w100" clearable>
          <el-option v-for="item in Payer" :key="item.shroffAccountId" :label="
              item.payee + ' / ' + item.bankDeposit + ' / ' + item.bankAccount
            " :value="item.shroffAccountId">
            <span style="float: left">
              {{ item.payee }} / {{ item.bankDeposit }} /
              {{ item.bankAccount }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付状态" prop="paymentAccount">
        <el-select v-model="payStatus" filterable class="w100">
          <el-option v-for="item in paymentStatusList" :key="item.shroffAccountId" :label="item.name"
            :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="电子回单" prop="hasReceipt">
        <el-select v-model="hasReceipt" placeholder="请选择" class="w100">
          <el-option label="有" value="1" />
          <el-option label="无" value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="创建人">
        <el-input clearable v-model="createName" placeholder="请选择" @focus="selectUserClick('createName')"
          @change="createNameChange"></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="createDaterange" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" @change="createDaterangeChange" />
      </el-form-item>
      <el-form-item label="审核通过时间" class="pdel">
        <el-date-picker v-model="auditPassTime" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          end-placeholder="结束日期" @change="createAuditPassTime" />
      </el-form-item>
    </el-form>
    <div class="operate">
      <el-button type="primary" @click="searchClick">确定</el-button>
      <el-button @click="resetClick">重置</el-button>
    </div>
    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import { categoryListForSelect } from '@/api/product/productPrintWay'
  import { listShorffAccountPay } from '@/api/finance/finance-cost'
  import UserChoose from '@/components/userChoose2'
  import { paymentStatusList } from '@/views/finance-center/payment-manage/helper/data'
  export default {
    name: 'ClientManageSearch',
    components: { UserChoose },
    data() {
      return {
        form: {},
        confirmDaterange: [], //确认日期
        payDaterange: [], //付款日期
        createDaterange: [], //创建日期
        createName: '', //创建人
        createNameId: [], //创建人id
        Payer: [], //付款账户下拉
        costypeOptions: [], // 费用类型
        auditPassTime: [], // 审核时间
        payStatus: '', //支付状态
        currencys: [
          {
            label: '美元',
            value: 'USD',
          },
          {
            label: '人民币',
            value: 'CNY',
          },
        ],
        hasReceipt: '',
        paymentStatusList: Object.freeze(paymentStatusList),


      }
    },
    created() {
      this.getCategoryListForSelect()
      this.getPayer()
      this.getCostType()
    },
    methods: {
      // 费用类型
      async getCostType() {
        let response = await this.pubApi.financeSelect({ financeType: 5 })
        this.costypeOptions = response.data
      },
      async getCategoryListForSelect() {
        let response = await categoryListForSelect()
        if ((response.code = '000000')) {
          this.categoryOptions = response.data
        }
      },
      // 付款人
      async getPayer() {
        let response = await listShorffAccountPay({
          cusName: '',
          shroffType: 3,
        })
        this.Payer = response.data
      },

      // searchClick
      searchClick() {
        //确认日期
        if (this.confirmDaterange && this.confirmDaterange.length) {
          this.form.startConfirmTime = this.confirmDaterange[0]
          this.form.endConfirmTime = this.confirmDaterange[1]
        }
        //付款日期
        if (this.payDaterange && this.payDaterange.length) {
          this.form.startPayTime = this.payDaterange[0]
          this.form.endPayTime = this.payDaterange[1]
        }
        //创建日期
        if (this.createDaterange && this.createDaterange.length) {
          this.form.startCreateTime = this.createDaterange[0]
          this.form.endCreateTime = this.createDaterange[1]
        }

        //创建人
        if (this.createName) {
          this.form.creatorId = this.createNameId.join(',')
        }
        // 审核时间
        if (this.auditPassTime && this.auditPassTime.length) {
          this.form.startAuditPassTime = this.auditPassTime[0]
          this.form.endAuditPassTime = this.auditPassTime[1]
        }
        if (this.hasReceipt) {
          this.form.hasReceipt = this.hasReceipt
        }
        // 支付状态
        this.form.payStatus = this.payStatus


        this.$emit('searchClick', this.form)
      },

      //确认日期清空
      confirmDaterangeChange(val) {
        if (!val) {
          this.form.startConfirmTime = ''
          this.form.endConfirmTime = ''
        }
      },
      //付款日期清空
      payDaterangeChange(val) {
        if (!val) {
          this.form.startPayTime = ''
          this.form.endPayTime = ''
        }
      },
      //创建时间清空
      createDaterangeChange(val) {
        if (!val) {
          this.form.startCreateTime = ''
          this.form.endCreateTime = ''
        }
      },
      // 审核时间清空
      createAuditPassTime(val) {
        if (!val) {
          this.form.startAuditPassTime = ''
          this.form.endAuditPassTime = ''
        }
      },

      //创建人选择
      selectUserClick(str) {
        this.isUserChoose = str
        this.$refs['UserChoose'].showAddEdit('listSearch', this.createNameId)
      },
      //创建人返回
      chooseUser(checkedUser) {
        this.createNameChange()
        if (!checkedUser.length) {
          return
        }
        let userName = []
        checkedUser.forEach((item) => {
          userName.push(item.name)
          this.createNameId.push(item.id)
          this.createName = userName.join(',')
        })
      },
      // 删除创建人
      createNameChange() {
        this.createNameId = []
        this.createName = ''
        this.form.creatorId = ''
      },
      // cancelClick
      resetClick() {
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
        this.createName = ''
        this.createNameId = []
        this.confirmDaterange = ''
        this.payDaterange = ''
        this.createDaterange = ''
        this.auditPassTime = []
        this.payStatus = ''
        this.hasReceipt = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search-form {
    height: 85%;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 100px;

    .search-form-content {
      padding: 0 20px;
    }

    .operate {
      position: fixed;
      width: 100%;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #ededed;
      padding: 20px;
    }
  }

  ::v-deep.pdel {
    margin-bottom: 0 !important;
  }
</style>
<style lang="scss" scoped>
  .search_form .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  ::v-deep {
    .el-date-editor {
      width: 100%;
    }

    .el-divider {
      margin: 30px 0 28px 0 !important;
    }

    .el-drawer__body {
      height: calc(100% - 44px);
    }

    .label-style {
      margin-bottom: 0;
    }
  }
</style>