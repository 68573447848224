<template>
  <div class="custom-container">
    <!-- header -->
    <el-row class="page-header" type="flex" justify="space-between">
      <el-col :span="24" class="page-header-left">
        <!-- 默认展示 -->
        <el-row v-if="!selectRows.length">
          <el-input
            type="text"
            placeholder="客户邮箱"
            clearable
            v-model="requireForm.email"
            maxlength="50"
          ></el-input>
          <el-input
            type="text"
            placeholder="公司名称"
            clearable
            v-model="requireForm.companyName"
            maxlength="50"
          ></el-input>
          <el-input
            type="text"
            placeholder="客户名称"
            clearable
            v-model="requireForm.linkman"
            maxlength="50"
          ></el-input>
          <el-select
            v-model="requireForm.customerSourceId"
            placeholder="客户来源"
            clearable
          >
            <el-option
              v-for="item in customerSource"
              :key="item.sourceId"
              :label="item.sourceName"
              :value="item.sourceId"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="throwDaterange"
            class="mr10"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="回归公海开始日期"
            end-placeholder="回归公海结束日期"
            :default-time="['00:00:00', '23:59:59']"
            @change="throwDaterangeChange"
          />
          <el-input
            type="text"
            placeholder="客户编码"
            clearable
            v-model="requireForm.customerCode"
            maxlength="50"
          ></el-input>
          <el-select
            v-model="requireForm.customerRank"
            placeholder="客户等级"
            clearable
          >
            <el-option
              v-for="item in customerRankOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="requireForm.customerType"
            placeholder="客户类型"
            clearable
          >
            <el-option label="潜在客户" :value="0" />
            <el-option label="正式客户" :value="1" />
            <el-option label="黑名单客户" :value="2" />
          </el-select>
          <el-select
            v-model="requireForm.toSeaType"
            placeholder="回归公海原因"
            clearable
          >
            <el-option label="全部" />
            <el-option label="人为转为公海" :value="1" />
            <el-option label="未维护到期自动转为公海" :value="2" />
          </el-select>
          <el-button @click="searchHandleClick" type="primary">搜索</el-button>
          <el-button class="ml10" @click="reset">重置</el-button>
        </el-row>
        <!-- 表格勾选展示 -->
        <el-row type="flex" align="middle" v-else>
          <p class="mb15">
            已选择
            <strong class="f_s_18 f_w_7">{{ selectRows.length }}</strong>
            个客户
          </p>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="mt5 button-wrapper">
      <el-col :span="12">
        <el-button
          type="primary"
          plain
          @click="claimClick"
          v-allowed="['Claim:Client']"
          v-show="isShowBtns"
        >
          认领
        </el-button>
        <el-button
          type="primary"
          plain
          @click="assign('batch')"
          class="ml10"
          v-allowed="['Claim:Dispatch']"
          v-show="isShowBtns"
        >
          指派
        </el-button>
        &nbsp;
      </el-col>
      <el-col :span="12" class="text-right">
        <el-button
          class="ml10 mr10"
          @click="claimSettingClick"
          v-allowed="['Claim:Set']"
        >
          认领设置
        </el-button>
        <el-popover
          ref="showOption"
          popper-class="custom-table-checkbox"
          trigger="hover"
        >
          <erp-draggable v-bind="dragOptions" :list="columns">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="item + index"
              v-model="item.isShow"
              :disabled="item.checkable === false"
              :label="item.label"
            >
              {{ item.label }}
            </el-checkbox>
          </erp-draggable>
          <!-- 保存自定义显示列 -->
          <div class="save-btn">
            <el-button
              type="primary"
              class="ml10"
              size="mini"
              plain
              @click="operateSave"
            >
              保存
            </el-button>
          </div>

          <template #reference>
            <el-tooltip
              effect="dark"
              content="列表可拖动排序"
              placement="top-start"
            >
              <el-button plain type="primary">自定义显示</el-button>
            </el-tooltip>
          </template>
        </el-popover>
      </el-col>
    </el-row>

    <!-- table -->
    <el-row class="mt15">
      <el-row class="table-height-box">
        <ErpTable
          :page-number.sync="pageNo"
          :page-size.sync="pageLe"
          :table-data="tableData"
          :total="total"
          :extraHeight="40"
          @query="fetchData"
          class="table-fixed"
          :key="customTableKey"
          @selection-change="setSelectRows"
        >
          <el-table-column
            align="center"
            type="selection"
            width="55"
            fixed="left"
          />
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            :label="item.label"
            show-overflow-tooltip
            :sortable="item.sortable"
            :width="item.width"
            min-width="120"
            :resizable="false"
            align="center"
            :prop="item.prop"
            :fixed="item.fixed || false"
          >
            <template #default="{ row }">
              <!-- 客户类型 -->
              <el-row v-if="item.prop === 'customerType'">
                <el-tooltip
                  :content="'原因：' + row.blackRemark"
                  placement="top"
                  v-if="row.customerType === 2"
                >
                  <span class="c_pointer">
                    {{ customerTypeStatus(row.customerType) }}
                  </span>
                </el-tooltip>
                <span v-else>{{ customerTypeStatus(row.customerType) }}</span>
              </el-row>
              <!-- 客户等级 -->
              <el-rate
                v-else-if="item.prop === 'customerRank'"
                v-model="row.customerRank"
                disabled
                text-color="#ff9900"
              ></el-rate>
              <!-- 回归公海原因 -->
              <el-row v-else-if="item.prop === 'toSeaType'">
                {{ row.toSeaType | toSeaTypeTrans }}
              </el-row>
              <!-- 状态 -->
              <el-row v-else-if="item.prop === 'status'">
                {{ row.status | statusTrans }}
              </el-row>
              <!-- 其他 -->
              <span v-else>{{ row[item.prop] || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="120"
            fixed="right"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-tooltip
                content="认领"
                placement="top"
                v-if="scope.row.customerType !== 2"
              >
                <el-button
                  v-allowed="['Claim:Client']"
                  type="primary"
                  plain
                  circle
                  size="mini"
                  icon="el-icon-user"
                  @click="claimClick('single', scope.row)"
                  v-show="scope.row.status === 1"
                ></el-button>
              </el-tooltip>
              <span v-else>/</span>
              <el-tooltip content="指派" placement="top">
                <el-button
                  v-allowed="['Claim:Dispatch']"
                  type="primary"
                  plain
                  circle
                  size="mini"
                  icon="el-icon-thumb"
                  @click="assign('single', scope.row)"
                  v-show="scope.row.status === 1"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </ErpTable>
      </el-row>
    </el-row>

    <!-- 高级筛选Drawer -->
    <el-drawer
      title="高级筛选"
      :visible.sync="drawer"
      :before-="seniorSearchClose"
    >
      <PublicClentSearch
        ref="PublicClentSearch"
        @cancelClick="seniorSearchClose"
        @searchClick="searchClick"
      />
    </el-drawer>

    <!-- 认领弹框 -->
    <PublicClientClaimModel ref="PublicClientClaimModel" />

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 认领审核模板-->
    <AuditChoose ref="AuditClaimChoose" @auditData="saveClaimClick" />

    <!-- 指派审核模板-->
    <AuditChoose ref="AuditAssignChoose" @auditData="saveAssignClick" />
  </div>
</template>

<script>
  import CustomerCenterInteractor from '@/core/interactors/customer-manage/customer-center'
  import ErpDraggable from 'vuedraggable'
  import BaseTable from '@/components/baseTable'
  import PublicClentSearch from './components/public-client-search'
  import PublicClientClaimModel from './components/public-client-claim-model'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  import { customerTypeStatus } from '@/utils/en-match-zh'
  import CustomCache from '@/utils/custom-cache'
  import customTableMixin from '@/utils/custom-table-mixin'
  import AuditChoose from '@/components/AuditChoose'
  import UserChoose from '@/components/userChoose'
  import { mapGetters } from 'vuex'
  export default {
    name: 'publicClient',
    mixins: [customTableMixin],
    components: {
      BaseTable,
      ErpDraggable,
      PublicClentSearch,
      PublicClientClaimModel,
      AuditChoose,
      UserChoose,
    },
    data() {
      return {
        activeName: 'first',
        requireForm: {}, //请求参数
        tableData: [], // 表格数据
        throwDaterange: [], //回归公海时间
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数
        columns: [
          //table列字段
          {
            label: '客户编码',
            prop: 'customerCode',
            isShow: true,
            checkable: false,
            fixed: 'left',
          },
          {
            label: '客户邮箱',
            prop: 'email',
            isShow: true,
            checkable: false,
            width: 160,
          },
          {
            label: '成单数量',
            prop: 'successOrderCount',
            isShow: true,
          },
          {
            label: '合计成单金额',
            prop: 'orderUsTotal',
            isShow: true,
          },
          {
            label: '询盘数量',
            prop: 'inquiryOrderCount',
            isShow: true,
          },
          {
            label: '客户来源',
            prop: 'customerSourceName',
            isShow: true,
          },
          {
            label: '客户类型',
            prop: 'customerType',
            isShow: true,
          },
          {
            label: '客户等级',
            prop: 'customerRank',
            isShow: true,
            width: '150',
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: true,
          },
          {
            label: '回归公海时间',
            prop: 'seasTime',
            isShow: true,
          },
          {
            label: '回归公海原因',
            prop: 'toSeaType',
            isShow: true,
            width: '180',
          },
          {
            label: '状态',
            prop: 'status',
            isShow: true,
          },
        ],
        userCustomizeColumnId: '', //自义定保存id
        selectRows: [], //表格勾选的数量
        isRequesting: false,
        stars: 5,
        customerSource: [
          { id: '001', label: '询盘生成', value: '询盘生成' },
          { id: '002', label: '业务添加', value: '业务添加' },
        ],
        customerRankOptions: [
          //客户等级
          {
            value: 1,
            label: '★',
          },
          {
            value: 2,
            label: '★★',
          },
          {
            value: 3,
            label: '★★★',
          },
          {
            value: 4,
            label: '★★★★',
          },
          {
            value: 5,
            label: '★★★★★',
          },
        ],
        drawer: false,
        isShowBtns: true, // 是否显示操作按钮
        isClaimCheck: false, // 认领是否走配置
        isAssignCheck: false, // 指派是否走配置
        customerSource: [], //客户来源下拉
        assignParams: {
          assignerCn: '',
          assignerEn: '',
          assignerId: '',
          infoId: [],
          auditObjectDTO: {},
        },
        infoIdArr: [],
        infoLogDTOList: [],
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    filters: {
      toSeaTypeTrans(n) {
        if (n == 1) {
          return '人为转为公海'
        } else if (n == 2) {
          return '未维护到期自动转为公海'
        } else {
          return '--'
        }
      },
      statusTrans(n) {
        if (n == 1) {
          return '可认领'
        } else {
          return '审核中'
        }
      },
    },
    created() {
      //获取自定义保存
      CustomCache.getColumns(51, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })
      this.fetchData()

      //客户来源
      this.pubApi.customerSourceList({}).then((response) => {
        if (response?.code === '000000') {
          this.customerSource = response.data
        }
      })
      this.getIsCheck()
    },
    methods: {
      // 判断是否需要审核
      async getIsCheck() {
        const _arr = await CustomerConfigInteractor.getCustomerCommonConfigure(
          1
        )

        this.isAssignCheck =
          _arr?.find((item) => item.operationType == '2')?.needTick === 1
            ? true
            : false
        this.isClaimCheck =
          _arr?.find((item) => item.operationType == '3')?.needTick === 1
            ? true
            : false
      },

      // 获取公海列表数据
      async fetchData() {
        const res = await CustomerCenterInteractor.listHighSeasPage({
          ...this.requireForm,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          highSeas: 1,
        })

        this.tableData = res?.data?.data
        this.total = res?.data?.total
      },

      //回归公海时间清空
      throwDaterangeChange(date) {
        if (date) {
          this.requireForm.startSeasTime = date[0]
          this.requireForm.endSeasTime = date[1]
        } else {
          this.requireForm.startSeasTime = ''
          this.requireForm.endSeasTime = ''
        }
      },

      //如果input为空
      searchInput(val) {
        if (val === '') {
          this.searchHandleClick()
        }
      },
      //搜索
      searchHandleClick() {
        this.pageNo = 1
        this.fetchData()
      },
      // 重置
      reset() {
        this.throwDaterange = []
        this.requireForm = {}
        this.searchHandleClick()
      },
      //认领,批量与单个
      claimClick(str, row) {
        let infoIdArr = []
        if (str === 'single') {
          infoIdArr.push(row.infoId)
          this.infoLogDTOList = [
            {
              customerCode: row.customerCode,
              customerName: row.customerName,
            },
          ]
        } else {
          if (!this.selectRows.length) {
            return this.$baseMessage(
              '请先选择要认领的客户',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
          infoIdArr = this.selectRows.map((item) => {
            return item.infoId
          })
          this.infoLogDTOList = this.selectRows.map((item) => {
            return {
              customerCode: item.customerCode,
              customerName: item.customerName,
            }
          })
        }
        this.infoIdArr = infoIdArr

        this.$confirm('是否确定认领？', '认领提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          if (this.isClaimCheck) {
            let auditParams = {
              auditType: 18,
              status: 0,
              tenantId: this.userInfo.tenantId,
            }
            this.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                this.$refs['AuditClaimChoose'].showAddEdit(auditParams)
              }
            })
          } else {
            this.saveClaimClick({})
          }
        })
      },

      // 指派
      assign(str, row) {
        if (str === 'batch') {
          if (!this.selectRows.length) {
            return this.$baseMessage(
              '请先选择要指派的客户',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
          this.assignParams.infoId = this.selectRows.map((item) => {
            return item.infoId
          })
          this.infoLogDTOList = this.selectRows.map((item) => {
            return {
              customerCode: item.customerCode,
              customerName: item.customerName,
            }
          })
        } else {
          this.assignParams.infoId = [row.infoId]
          this.infoLogDTOList = [
            {
              customerCode: row.customerCode,
              customerName: row.customerName,
            },
          ]
        }
        this.$refs['UserChoose'].showAddEdit('', '', true)
      },

      // 指派人员审核
      chooseUser(userIdArr) {
        this.assignParams.assignerCn = userIdArr[0].name
        this.assignParams.assignerEn = userIdArr[0].englishName
        this.assignParams.assignerId = userIdArr[0].id

        if (this.isAssignCheck) {
          let auditParams = {
            auditType: 18,
            status: 0,
            tenantId: this.userInfo.tenantId,
          }
          this.pubApi.getAuditTemplates(auditParams).then((response) => {
            if (!response.data.length) {
              this.$baseMessage(
                '请联系管理员配置审核模板',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            } else {
              this.$refs['AuditAssignChoose'].showAddEdit(auditParams)
            }
          })
        } else {
          this.saveAssignClick({})
        }
      },

      // 认领审核回调
      async saveClaimClick(data) {
        const params = {
          auditObjectDTO: data,
          infoId: this.infoIdArr,
          infoLogDTOList: this.infoLogDTOList,
        }
        const res = await CustomerCenterInteractor.customerClaim(params)

        if (res?.code == '000000' && res?.data != 0) {
          this.$message.success('操作成功')
          this.searchHandleClick()
        }
      },

      // 指派审核回调
      async saveAssignClick(data) {
        this.assignParams.auditObjectDTO = data
        this.assignParams.infoLogDTOList = this.infoLogDTOList
        const res = await CustomerCenterInteractor.customerAssign(
          this.assignParams
        )

        if (res && res.code == '000000') {
          this.$message.success('操作成功')
          this.fetchData()
        }
      },

      // 认领设置
      claimSettingClick() {
        this.$refs['PublicClientClaimModel'].showAddEdit()
      },

      //高级筛选
      seniorSearch() {
        this.drawer = true
      },
      // 高级筛选关闭
      seniorSearchClose() {
        this.drawer = false
      },
      // 高级筛选触发
      searchClick(val) {
        //回归公海时间
        if (this.throwDaterange && this.throwDaterange.length) {
          this.requireForm.startSeasTime = this.throwDaterange[0]
          this.requireForm.endSeasTime = this.throwDaterange[1]
        }

        this.pageNo = 1
        this.requireForm = val
        this.fetchData()
        this.seniorSearchClose()
      },

      //新增编辑客户
      addClick() {
        this.$refs['ClientManageAdd'].showAddEdit()
      },

      //自定义显示保存
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 51,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          this.userCustomizeColumnId = res.data.userCustomizeColumnId
          if (res?.code === '000000') {
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
        if (val.length) {
          this.isShowBtns = val.every((item) => item.status === 1)
        } else {
          this.isShowBtns = true
        }
      },

      //客户类型匹配
      customerTypeStatus(val) {
        return customerTypeStatus(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }
  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }
  .width-200 {
    width: 200px;
  }
  ::v-deep {
    .page-header-left {
      .el-input,
      .el-select {
        width: 130px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }
</style>
