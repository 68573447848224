<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
    top="10vh"
  >
    <el-form :model="form" label-width="100px">
      <el-form-item label="中文名称">
        <el-input
          v-model="form.nameCn"
          maxlength="100"
          show-word-limit
          disabled
        />
      </el-form-item>
      <el-form-item label="英文名称">
        <el-input
          v-model="form.nameEn"
          maxlength="100"
          show-word-limit
          disabled
        />
      </el-form-item>
      <el-form-item label="单位">
        <el-input v-model="form.productUnit" disabled />
      </el-form-item>
      <el-form-item label="关联供应商">
        <el-input v-model="form.supplierNames" disabled />
      </el-form-item>
      <el-form-item label="成本价">
        <el-input v-model="form.costPrice" type="number" disabled />
      </el-form-item>
      <el-form-item label="材质">
        <el-input v-model="form.material" disabled />
      </el-form-item>
      <el-form-item label="尺寸">
        <el-row>
          <el-col :span="7">
            <el-input
              v-model="form.productLengthCm"
              placeholder="长"
              type="number"
              disabled
            >
              <span style="color: #000" slot="suffix">CM</span>
            </el-input>
          </el-col>
          <el-col :span="1" style="text-align: center">X</el-col>
          <el-col :span="7">
            <el-input
              v-model="form.productWidthCm"
              placeholder="宽"
              type="number"
              disabled
            >
              <span style="color: #000" slot="suffix">CM</span>
            </el-input>
          </el-col>
          <el-col :span="1" style="text-align: center">X</el-col>
          <el-col :span="8">
            <el-input
              v-model="form.productHeightCm"
              placeholder="高"
              type="number"
              disabled
            >
              <span style="color: #000" slot="suffix">CM</span>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="重量">
        <el-input
          v-model="form.productWeightKgs"
          type="number"
          min="0"
          disabled
        >
          <span style="color: #000" slot="suffix">KGS</span>
        </el-input>
      </el-form-item>
      <el-form-item label="图片">
        <div style="width: 200px">
          <erp-image :imgs="form.productImg"></erp-image>
        </div>
      </el-form-item>
      <el-form-item label="其他描述">
        <el-input
          v-model="form.description"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          maxlength="500"
          show-word-limit
          disabled
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import { detailSubProduct } from '@/api/product/productList'
export default {
  data() {
    return {
      title: '辅料详情',
      dialogFormVisible: false,
      form: {
        productImg: '',
      },
      imageList: [],
    }
  },
  methods: {
    close() {
      this.dialogFormVisible = false
      for (const key in this.form) {
        this.form[key] = ''
      }
    },
    changeUploadList4(val) {
      let urlArr = []
      val.forEach((ele) => {
        urlArr.push(ele.url)
      })
      this.form.productImg = urlArr.toString()
    },
    // 详情
    getDetail(val) {
      detailSubProduct(val.accessoriesNumber).then((res) => {
        this.form = res?.data
        this.form.costPrice = this.form.costPrice ? this.form.costPrice : ''
        this.form.productLengthCm = this.form.productLengthCm
          ? this.form.productLengthCm
          : ''
        this.form.productWidthCm = this.form.productWidthCm
          ? this.form.productWidthCm
          : ''
        this.form.productHeightCm = this.form.productHeightCm
          ? this.form.productHeightCm
          : ''
        this.form.productWeightKgs = this.form.productWeightKgs
          ? this.form.productWeightKgs
          : ''
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    max-height: 85vh;
    overflow-y: scroll;
    .el-dialog__header {
      position: sticky;
      top: 0;
      background-color: #ffffff;
      z-index: 1;
    }
    .el-dialog__footer {
      position: sticky;
      bottom: 0px;
      background-color: #ffffff;
      z-index: 1;
    }
  }
  .imgs-sty{
    height: auto;
  }
}
</style>