<template>
  <div class="progress">
    <div class="main" v-if="processList.length">
      <div class="step" v-for="(item, i) in processList" :key="i">
        <div class="title">
          <!-- {{ item.orderCourse }} -->
          {{ getLangLabel(item.orderCourse, lang === 'en' ? true : false) }}
        </div>
        <div class="container">
          <div class="l-c">
            <p>{{ item.createTime }}</p>
            <p v-for="(text, k) in item.remarkArr" :key="k">
              <label v-if="lang === 'en'">
                {{ getLangLabel(text.label) }}:
              </label>
              <label v-else>{{ text.label }}：</label>
              <el-tag class="ml5" v-if="text.label === '当前进度:'" :type="text.value | statusFilter">
                {{ text.value || '--' }}
              </el-tag>
              <span v-else>
                <span v-if="isFormat(text.label)">******</span>
                <span v-else>
                  {{ langSpec(text.value, text.label) || '--' }}
                </span>
              </span>
            </p>
          </div>
          <div class="r-c">
            <!-- 操作人 -->
            {{ $t('orderList.Operator') }}：{{
            lang === 'en' ? item.creatorNameEn : item.creatorName
            }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty description="暂无内容"></el-empty>
    </div>

    <div class="btn text-center">
      <el-button @click="close">关闭</el-button>
    </div>
  </div>
</template>

<script>
  import viewCost from '@/views/order/orderList/util'
  import { orderCourse } from '@/api/order'
  import { mapGetters } from 'vuex'
  import { keyStr } from '@/config'
  import { base64Decode } from 'kits'
  export default {
    props: ['progressId'],
    name: 'order_progress',
    data() {
      return {
        isViewCost: viewCost(),
        processList: [],
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    // 状态过滤
    filters: {
      statusFilter(status) {
        const statusMap = {
          提交中: 'info',
          审核中: 'primary',
          审核通过: 'success',
          审核驳回: 'danger',
          审核撤销: 'info',
          重新提交: 'info',
        }
        return statusMap[status]
      },
    },
    created() {
      this.progress()
    },
    methods: {
      // 进程
      progress() {
        orderCourse({ orderId: this.progressId }).then((res) => {
          this.processList = res.data ? JSON.parse(base64Decode(res.data, keyStr)) : []
          this.processList.forEach((item) => {
            item.remarkArr = []
            item.remark.split(',').forEach((item2, k) => {
              let colon = item2.indexOf(':')
              let labelText = item2.substring(0, colon)
              let valueText = item2.substring(colon + 1)
              let obj = {
                label: labelText,
                value: valueText,
              }
              item.remarkArr.push(obj)
            })
          })
        })
      },

      // 数据转换
      isFormat(label) {
        return label.includes('金额') && !this.isViewCost ? true : false
      },

      // 关闭
      close() {
        this.$emit('progressCancel')
      },

      // 人员中英文翻译
      langSpec(val, label) {
        if (val.includes('//')) {
          return this.lang === 'en' ? val.split('//')[1] : val.split('//')[0]
        } else if (label.includes('当前进度') || label.includes('状态')) {
          if (this.lang === 'en') {
            let text = ''
            switch (val) {
              case '提交中':
                text = 'submitting'
                break
              case '审核中':
                text = 'under review'
                break
              case '审核通过':
                text = 'examination passed'
                break
              case '审核驳回':
                text = 'Review rejected'
                break
              case '审核撤销':
                text = 'Review revocation'
                break
              case '重新提交':
                text = 'Resubmit'
                break
              case '出库中':
                text = 'out of stock'
                break
              case '已出库':
                text = 'out of stock'
                break
              case '已出运':
                text = 'shipped'
                break
              case '作废':
                text = 'void'
                break
              case '已撤销':
                text = 'Revoked'
                break
              case '已取消':
                text = 'Cancelled'
                break
              case '出库驳回':
                text = 'Out of stock rejected'
                break
              case '采购中':
                text = 'Purchasing'
                break
              case '已完成':
                text = 'completed'
                break
              case '已驳回':
                text = 'rejected'
                break
              case '验货中':
                text = 'under inspection'
                break
              case '已入库':
                text = 'In stock'
                break
              case '未出库':
                text = 'Not out of stock'
                break
            }
            return text
          } else {
            return val
          }
        } else {
          return val
        }
      },

      // label中英文翻译
      getLangLabel(state) {
        let text = ''
        switch (state) {
          case '采购':
            text = this.$t('orderList.Procurement')
            break
          case '合同':
            text = this.$t('orderList.Contract')
            break
          case '下单':
            text = this.$t('orderList.Order')
            break
          case '收款':
            text = this.$t('orderList.ReceivePayment')
            break
          case '状态':
            text = this.$t('orderList.Status')
            break
          case '合同签订时间':
            text = this.$t('orderList.ContractSigningTime')
            break
          case '当前进度':
            text = this.$t('orderList.CurrentProgress')
            break
          case '审核通过时间':
            text = this.$t('orderList.ApprovalTime')
            break
          case '下单时间':
            text = this.$t('orderList.OrderTime')
            break
          case '关联报价单号':
            text = this.$t('orderList.AssociateQuotationNumber')
            break
          case '计划运输时间':
            text = this.$t('orderList.PlannedShippingTime')
            break
          case '出库委托单号':
            text = this.$t('orderList.OutboundOrderNumber')
            break
          case '收款单号':
            text = this.$t('orderList.ReceivePaymentNumber')
            break
          case '收款金额':
            text = this.$t('orderList.ReceiptAmount')
            break
          case '收款时间':
            text = this.$t('orderList.ReceivePaymentTime')
            break
          case '采购单号':
            text = this.$t('orderList.PurchaseOrderNumber')
            break
          case '采购开发':
            text = this.$t('orderDetails.PurchasingDevelopment')
            break
          case '当前进度':
            text = this.$t('orderList.CurrentProgress')
            break
          case '实际交期':
            text = this.$t('orderList.ActualDeliveryDate')
            break
          case '审核撤销时间':
            text = this.$t('orderList.ReviewRevocationTime')
            break
          case '撤销原因':
            text = this.$t('orderList.ReasonForRevocation')
            break
          case '审核驳回时间':
            text = this.$t('orderList.ReviewRejectionTime')
            break
          case '驳回原因':
            text = this.$t('orderList.ReasonForRejection')
            break
        }
        return text
      },
    },
  }
</script>

<style lang="scss" scoped>
  .progress {
    padding-bottom: 70px;

    .main {
      padding: 0 20px;

      .step {
        padding-left: 20px;
        border-left: 2px solid #e4e7ed;
        position: relative;
        margin-bottom: 8px;

        .title {
          font-size: 14px;
          font-family: 'PingFang Bold';
          color: #303133;
        }

        .container {
          margin-top: 12px;
          padding-bottom: 10px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          .l-c {
            width: 70%;

            p {
              color: rgb(123, 119, 119);
              margin-bottom: 10px;
            }
          }

          .r-c {
            width: 30%;
            text-align: right;
          }
        }
      }

      .step::before {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        left: -7px;
        right: 0;
        border-radius: 50%;
        background: rgb(70, 166, 255);
      }
    }

    .btn {
      box-shadow: 0px -2px 4px 0px rgba(223, 223, 223, 0.5);
      padding: 15px 20px;
      width: 100%;
      position: fixed;
      bottom: 0;
      background: white;
    }
  }
</style>