<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="名称" prop="nameCn">
            <el-input v-model="form.nameCn" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="审核" prop="value">
            <el-switch
              v-model="form.value"
              active-value="1"
              inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">确认</el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
  </el-dialog>
</template>

<script>
import { insertPO, updatePO } from '@/api/product/productSetting'
export default {
  name: 'PriceTypeModel',
  data() {
    return {
      loading:false,
      title: '产品类型设置',
      form: {
        type: 7,
        nameCn: '',
        productSettingId: '',
        value: '1',
      },
      rules: {
        nameCn: [{ required: true, trigger: 'blur', message: '请输入名称' }],
      },
      dialogFormVisible: false,
      operateStr: 'add',
    }
  },
  created() {},
  methods: {
    //展示弹框
    showAddEdit(row) {
      if (row) {
        this.operateStr = 'edit'
        this.form.nameCn = row.nameCn
        this.form.productSettingId = row.productSettingId
        this.form.value = row.value
      }
      this.dialogFormVisible = true
    },
    // 关闭
    close() {
      this.operateStr = 'add'
      this.$refs['form'].resetFields()
      this.form = {
        type: 7,
        nameCn: '',
        productSettingId: '',
        value: '1',
      }
      this.dialogFormVisible = false
    },
    // 保存
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          if (this.operateStr === 'add') {
            let response = await insertPO(this.form)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                '新增成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          } else {
            let response = await updatePO(this.form)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                '编辑成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          }
         
          this.close()
          this.$emit('product-type', this.form.type)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
