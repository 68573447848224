<template>
  <div class="search_form">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="收款方" prop="supplierName">
        <el-input v-model="form.supplierName" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="是否协议供应商" prop="isAgreement">
        <el-select v-model="form.isAgreement" placeholder="请选择" clearable style="width: 100%">
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="买方" prop="purchaser">
        <el-input v-model="form.purchaser" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="收款账户" prop="supplierInfo">
        <el-input v-model="form.supplierInfo" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="确认日期" prop="confirmDate">
        <el-date-picker v-model="confirmDate" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item label="税号" prop="isTax">
        <el-radio-group v-model="form.isTax">
          <el-radio label="0">无</el-radio>
          <el-radio label="1">有</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="币种">
        <el-select v-model="form.currency" placeholder="请选择" class="w100" clearable>
          <el-option v-for="item in currencyOptions" :key="item.financeSetupId" :label="item.financeName"
            :value="item.financeName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款类型" prop="payType">
        <el-select v-model="form.payTypeObj" placeholder="请选择" class="w100" value-key="index">
          <el-option v-for="item in payTypes" :label="item.label" :value="item" :key="item.index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款金额">
        <div class="line_input">
          <el-input-number placeholder="请输入" v-model="form.startAmountApplication" :controls="false"
            style="width: 50%"></el-input-number>
          <span>至</span>
          <el-input-number placeholder="请输入" v-model="form.endAmountApplication" :controls="false"
            style="width: 50%"></el-input-number>
        </div>
      </el-form-item>
      <el-form-item label="付款日期" prop="amountDate">
        <el-date-picker v-model="amountDate" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item label="公账付款" prop="publicPayment">
        <el-select v-model="form.publicPayment" placeholder="请选择" class="w100">
          <el-option label="是" value="1" />
          <el-option label="否" value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="付款账户" prop="paymentAccount">
        <el-select v-model="form.paymentAccount" filterable class="w100">
          <el-option v-for="item in paymentList" :key="item.shroffAccountId" :label="
              item.payee + ' / ' + item.bankDeposit + ' / ' + item.bankAccount
            " :value="item.bankAccount">
            <span style="float: left">
              {{ item.payee }} / {{ item.bankDeposit }} /
              {{ item.bankAccount }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付状态" prop="paymentAccount">
        <el-select v-model="form.payStatus" filterable class="w100">
          <el-option v-for="item in paymentStatusList" :key="item.shroffAccountId" :label="item.name"
            :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="电子回单" prop="hasReceipt">
        <el-select v-model="form.hasReceipt" placeholder="请选择" class="w100">
          <el-option label="有" value="1" />
          <el-option label="无" value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="创建人">
        <el-input clearable v-model="creator" placeholder="请选择" @focus="selectUserClick()"
          @change="creatorChange"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="createDate" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          end-placeholder="结束日期" />
      </el-form-item>
      <el-divider />

      <div class="btn">
        <el-button type="primary" @click="searchClick">确定</el-button>
        <el-button @click="resetClick">重置</el-button>
      </div>
    </el-form>

    <!-- 高级筛选Drawer -->
    <el-drawer title="高级筛选" :visible.sync="drawer" :before-close="highSearchClose">
      <paymentSearch @cancelClick="highSearchClose" @searchClick="searchClick" />
    </el-drawer>

    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
  import { orderGetDict } from '@/api/order'
  import UserChoose from '@/components/userChoose2'
  import paymentSearch from '../components/payment-search'
  import { listShorffAccountPay } from '@/api/finance/finance-cost'
  import { selectOptions } from '@/api/finance/credit-manage-list'
  import { paymentStatusList, payTypes } from '../helper/data'
  export default {
    name: 'paymentSearch',
    components: { UserChoose, paymentSearch },
    data() {
      return {
        confirmDate: [], // 确认时间
        amountDate: [], // 付款时间
        createDate: [], // 创建时间
        paymentList: [], // 付款账号
        creator: '', //创建人
        creatorId: [], //创建人id
        enquirySourceNameList: [],
        form: { currency: '人民币' },
        rules: {
          supplierName: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
          supplierInfo: [
            { max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
          ],
        },
        currencyOptions: [], //货币下拉

        drawer: false, //高级筛选抽屉
        payTypes: Object.freeze(payTypes),
        paymentStatusList: Object.freeze(paymentStatusList),
      }
    },
    created() {
      this.getDict()
      this.getPayment()
    },
    watch: {
      // 确认时间
      confirmDate(dateArray) {
        if (dateArray) {
          this.form.startConfirmTime = dateArray[0]
          this.form.endConfirmTime = dateArray[1]
        } else {
          this.form.startConfirmTime = ''
          this.form.endConfirmTime = ''
        }
      },
      // 付款时间
      amountDate(dateArray) {
        if (dateArray) {
          this.form.startPaymentDate = dateArray[0]
          this.form.endPaymentDate = dateArray[1]
        } else {
          this.form.startPaymentDate = ''
          this.form.endPaymentDate = ''
        }
      },
      // 创建时间
      createDate(dateArray) {
        if (dateArray) {
          this.form.startCreateTime = dateArray[0]
          this.form.endCreateTime = dateArray[1]
        } else {
          this.form.startCreateTime = ''
          this.form.endCreateTime = ''
        }
      },
    },
    methods: {
      // 付款账号
      async getPayment() {
        let response = await listShorffAccountPay({
          cusName: '',
          shroffType: 3,
        })
        this.paymentList = response.data
      },

      getDict() {
        orderGetDict({ amountType: 2 }).then((res) => {
          this.enquirySourceNameList = res.data
        })
      },

      //创建人选择
      selectUserClick() {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.creatorId)
      },

      //创建人返回
      chooseUser(checkedUser) {
        this.creatorChange()
        if (!checkedUser.length) {
          return
        }
        let userName = []
        checkedUser.forEach((item) => {
          userName.push(item.name)
          this.creatorId.push(item.id)
          this.creator = userName.join(',')
        })
      },

      // 删除创建人
      creatorChange() {
        this.creatorId = []
        this.creator = ''
      },

      searchClick() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const form = JSON.parse(JSON.stringify(this.form))
            if (this.creatorId) {
              form.creatorId = this.creatorId.join(',')
            }
            const { payTypeObj } = form
            if (payTypeObj) {
              form.entryType = payTypeObj.entryType
              form.payType = payTypeObj.payType
            }
            this.$emit('searchClick', form)
          }
        })
      },
      resetClick() {
        this.confirmDate = []
        this.amountDate = []
        this.createDate = []
        this.creator = ''
        this.creatorId = []
        this.$refs.form.resetFields()
        this.form = this.$options.data().form
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },
    },
    created() {
      // 1币种设置 2发票设置 3支付方式 4科目 5费用杂项
      selectOptions({
        financeType: 1,
      }).then((res) => {
        if (res?.code === '000000') {
          this.currencyOptions = res.data
        }
      })
    },
  }
</script>

<style lang="scss" scoped>
  .search_form {
    padding: 0 20px 20px 20px;

    .el-date-editor--datetimerange.el-input__inner {
      width: 100%;
    }

    .btn {
      padding: 20px 0;
      width: 100%;
      position: fixed;
      bottom: 0;
      background: white;
    }
  }

  ::v-deep {
    .el-input--suffix {
      padding-right: 5px !important;
    }

    .el-cascader {
      width: 100%;
    }

    .el-date-editor {
      width: 100%;
    }

    .el-divider {
      margin: 70px 0 28px 0 !important;
    }
  }
</style>