<template>
  <el-dialog title="下载箱唛设置" :visible.sync="markSettingVisible" width="550px" :before-close="cancel">
    <el-form ref="form" :model="form" :rules="rules" label-width="170px" label-position="left">
      <el-form-item label="地区" prop="area">
        <el-select v-model="form.area" style="width: 100px">
          <el-option v-for="(item, index) in areaList" :key="index" :label="item.areaName"
            :value="item.areaName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品效果图" prop="rendImgDisplayed">
        <el-checkbox v-model="form.rendImgDisplayed" :true-label="1" :false-label="0">是</el-checkbox>
        <el-radio-group v-model="form.productImgDisplayed" class="ml10">
          <el-radio :label="0">为空不取值</el-radio>
          <el-radio :label="1">为空取产品图</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="下载预览箱唛带二维码" prop="amountShort">
        <el-checkbox :label="1" v-model="form.qrCodeDisplayed" :true-label="1" :false-label="0">是</el-checkbox>
      </el-form-item>

      <div class="text-center">
        <el-button type="primary" @click="submit" :loading="loading">
          确认
        </el-button>
        <el-button @click="cancel">取消</el-button>
      </div>


    </el-form>
  </el-dialog>
</template>
<script>
  import { FinanceThemeSetting, OrderSettingInteractor } from '@/core'
  export default {
    props: {
      settingType: {
        type: String,
        default: "ORDER"
      }
    },
    data() {
      return {
        form: {
          area: '', //地区
          rendImgDisplayed: 0, // 是否展示效果图(0:否,1:是)
          productImgDisplayed: 0, //效果图为空时是否展示产品图(0:否,1:是)
          qrCodeDisplayed: 1, //二维码是否展示(0:否,1:是)
        },
        rules: {
          area: [
            {

              required: true,
              message: '请选择',
              trigger: 'change',
            },
          ],
          rendImgDisplayed: [{ type: 'number', required: true, message: '请选择', trigger: 'change' }],
        },
        loading: false,
        areaList: [],
        markSettingVisible: false
      }
    },
    methods: {
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true
            this.save()
            this.cancel()
          }
        })
      },
      showAddEdit(row) {
        if (row) {
          this.isEdit = true
          this.id = row.id
          this.form = {
            ...this.form,
            ...row
          }
        } else {
          this.form = this.$options.data().form
          this.isEdit = false
          this.id = ''
        }

        this.markSettingVisible = true
      },
      cancel() {
        this.form = this.$options.data().form
        this.$refs.form.resetFields()
        this.markSettingVisible = false
      },

      save() {
        const param = {
          ...this.form,
          type: this.settingType === 'ORDER' ? 0 : 1
        }
        const reqmethod = this.isEdit ? 'marksConfigUpdateApi' : 'marksConfigInsertApi'
        if (this.isEdit) {
          param.id = this.id
        }

        OrderSettingInteractor[reqmethod](param).then(res => {
          if (res?.code === '000000') {
            this.markSettingVisible = false
            this.loading = false
            this.$emit('mark-refresh')
          }
        }).then(() => {
          this.loading = false
        })

      },
      async getAreaList() {
        this.areaList = await FinanceThemeSetting.allDeptAreaByTenant()
      },
    },
    created() {
      this.getAreaList()
    },
  }
</script>