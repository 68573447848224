<template>
  <div>
    <el-form ref="personSearchForm" :model="personSearchForm" inline>
      <el-form-item>
        <el-button type="primary" @click="add">新增</el-button>
        <el-button type="primary" @click="batchEdit">编辑</el-button>
      </el-form-item>
      <!-- <el-form-item label="计算类型" label-width="80px">
        <el-select v-model="personSearchForm.countType" style="width: 100px">
          <el-option
            v-for="item in countOption"
            :key="item.code"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="地区" label-width="80px">
        <el-select v-model="personSearchForm.area" style="width: 100px">
          <el-option
            v-for="(item, index) in areaList"
            :key="index"
            :label="item.areaName"
            :value="item.areaName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" label-width="80px">
        <el-select
          v-model="personSearchForm.status"
          placeholder="状态"
          style="width: 100px"
        >
          <el-option
            v-for="item in statusOption"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <div class="config-table">
      <ErpTable
        :page-number.sync="page.pageNo"
        :page-size.sync="page.pageLe"
        :table-data="tableData"
        :total="total"
        :extraHeight="30"
        class="table-fixed"
        @query="getList"
        ref="trackTable"
        @selection-change="setSelectRows"
      >
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column
          v-for="(item, index) in columns"
          :key="index"
          :label="item.label"
          show-overflow-tooltip
          :width="item.width"
          min-width="120"
          :resizable="true"
          align="center"
          :prop="item.prop"
          :class-name="item.prop"
        >
          <template slot="header">
            <div v-if="item.prop === 'orderSuport'">
              {{ item.label }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="tipContent"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
            <div v-else>{{ item.label }}</div>
          </template>
          <template slot-scope="scope">
            <div v-if="item.prop === 'NO'">{{ scope.$index + 1 }}</div>
            <div v-else>{{ scope.row[item.prop] || '--' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in roleList"
          :key="item.code"
          :prop="item.code"
          align="center"
          width="120"
          show-overflow-tooltip
        >
          <template slot="header">
            <div>
              <span style="margin-right: 5px">{{ item.message }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="模板类型+提成点，阶梯用逗号区分开"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row[getRoleCode(item.message)]">
              <span v-if="scope.row[getRoleCode(item.message)].ratioType == 1">
                {{ scope.row[getRoleCode(item.message)].mark }}
              </span>
              <el-link
                v-if="scope.row[getRoleCode(item.message)].ratioType == 2"
                type="primary"
                @click="showDetail(scope.row[getRoleCode(item.message)])"
              >
                {{ scope.row[getRoleCode(item.message)].mark }}
              </el-link>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="statusDesc"
          label="状态"
          align="center"
        ></el-table-column>

        <el-table-column label="操作" width="120" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="medium">
              编辑
            </el-button>
            <el-button
              type="text"
              @click="freeze(scope.row)"
              v-if="scope.row.status === 1"
              size="medium"
            >
              冻结
            </el-button>
            <el-button
              type="text"
              @click="start(scope.row)"
              v-if="scope.row.status === 0"
              size="medium"
            >
              启用
            </el-button>
          </template>
        </el-table-column>
      </ErpTable>
    </div>
    <modelDetailDialog
      :modelDetailVisible.sync="modelDetailVisible"
      :tableData.sync="detailTable"
    ></modelDetailDialog>
  </div>
</template>
<script>
import {
  configStatus,
  calcTypes,
  areas,
  templateConfigColumns,
} from '@/views/finance-center/config-manage/helper'
import modelDetailDialog from './components/model-detali-dialog.vue'
import {
  SettleConfigInteractor,
  FinanceThemeSetting,
} from '@/core/interactors/finance/config-manage'
import mixins from './mixins'
export default {
  components: {
    modelDetailDialog,
  },
  mixins: [mixins],
  data() {
    return {
      personSearchForm: {
        area: '',
        status: '',
      },
      countOption: calcTypes,
      // areaOption: areas,
      statusOption: configStatus,
      columns: templateConfigColumns,
      page: {
        pageNo: 1,
        pageLe: 10,
      },
      total: 0,
      tableData: [],
      tipContent: '模板类型+提成点，阶梯用逗号区分开',
      modelDetailVisible: false, // 展示模板详情
      // roleList: [],
      // roleMap: null,
      detailTable: [],
      chooseRows: [],
    }
  },
  created() {
    this.getList()
    this.getRoles()
    this.getArea()
  },
  methods: {
    getRoleCode(msg) {
      return this.roleMap.get(msg)
    },
    // 搜索
    search() {
      this.page.pageNo = 1
      this.getList()
    },
    // 重置
    reset() {
      this.personSearchForm.area = null
      this.personSearchForm.status = ''
      this.page.pageNo = 1
      this.page.pageLe = 10
      this.getList()
    },
    // 获取列表数据
    async getList() {
      let res = await SettleConfigInteractor.getConfigConditionListPage({
        ...this.personSearchForm,
        ...this.page,
      })
      this.tableData = res.data
      this.total = res.total
    },
    // 阶梯 详情数据
    showDetail(row) {
      this.detailTable = row.tieredVO
      this.modelDetailVisible = true
    },
    // 新增
    add() {
      this.$router.push({
        path: '/finance-center/template-settlement-oper',
        query: {
          str: 'add',
        },
      })
    },
    // 编辑
    edit(row) {
      this.$router.push({
        path: '/finance-center/template-settlement-edit',
        query: {
          str: 'edit',
          businessIds: row.configCommissionId,
        },
      })
    },
    // 批量编辑
    batchEdit() {
      if (this.chooseRows.length === 0) {
        this.$message.warning('请选择一条数据！')
        return
      }
      let ids = this.chooseRows.map((item) => item.configCommissionId)
      this.$router.push({
        path: '/finance-center/template-settlement-edit',
        query: {
          str: 'edit',
          businessIds: ids.toString(),
        },
      })
    },
    // 冻结 启用
    freeze(val) {
      this.$confirm('冻结后，新单据则无法调用此模板！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let res = await SettleConfigInteractor.setConfigConditionEnabled({
          configCommissionId: val.configCommissionId,
          status: 0,
        })
        if (res === true) {
          this.$message({
            type: 'success',
            message: '操作成功！',
          })
          this.getList()
        }
      })
    },
    // 启用
    async start(row) {
      let res = await SettleConfigInteractor.setConfigConditionEnabled({
        configCommissionId: row.configCommissionId,
        status: 1,
      })
      if (res === true) {
        this.$message({
          type: 'success',
          message: '启用成功',
        })
        this.getList()
      }
    },
    // 多选
    setSelectRows(rows) {
      this.chooseRows = rows
    },
  },
}
</script>
<style lang="scss" scoped>
.config-table {
  .table-fixed {
    max-height: 600px !important;
    ::v-deep {
      .table-custom {
        height: 100%;
        .el-table {
          height: 100% !important;
        }
      }
      .el-tooltip {
        width: auto !important;
      }
    }
  }
}
</style>
