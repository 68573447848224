<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="1200px"
    :before-close="handleClose"
    center
  >
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column
        v-for="item in recordColumn"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'manualSettlementReason'">
            {{
              scope.row.settlementRecordStatus == '3'
                ? scope.row.manualSettlementReason
                : scope.row.adjustmentReason || '--'
            }}
          </div>
          <div
            v-else-if="item.prop === 'settlementRecordStatusDesc'"
            v-html="scope.row[item.prop]"
          ></div>
          <div v-else-if="item.prop === 'sku'">
            {{ scope.row.productNameCn || '--' }}
          </div>
          <div
            v-else-if="
              setMoney.indexOf(item.prop) > -1 &&
              scope.row.settlementRecordStatus == 2
            "
            style="color: red"
          >
            <span>
              {{
                scope.row[item.prop]
                  ? scope.row[item.prop] > 0
                    ? '-' + scope.row[item.prop]
                    : scope.row[item.prop] * -1
                  : '--'
              }}
            </span>
          </div>
          <div v-else>{{ scope.row[item.prop] || '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import columns from '../helper'
import _ from 'lodash'
//结算调整时，金额显示负值
const moneyColumn = [
  'amountCollected',
  'exchangeRate',
  'salesOrderAmount',
  'amountCollectedCny',
  'commissionAmount',
  'productCost',
  'internationalDeliveryCharge',
  'airSeaFreight',
  'homeFreight',
  'otherCharges',
  'totalCost',
  'grossProfitAmount',
  'grossProfitMargin',
  'operatingCostRatio',
  'operatingCost',
  'netProfit',
  'orderProfitMargin',
  'quarterly',
  'businessCommissionRatio',
  'businessCommission',
  'productOperatingCommissionRatio',
  'productOperatingCommission',
  'buyerCommissionRatio',
  'buyerCommission',
  'orderSupportCommissionRatio',
  'orderSupportCommission',
  'businessManagerCommissionRatio',
  'businessManagerCommission',
  'productOperatingManagerCommissionRatio',
  'productOperatingManagerCommission',
  'buyerManagerCommissionRatio',
  'buyerManagerCommission',
  'orderSupportManagerCommissionRatio',
  'orderSupportManagerCommission',
  'totalCommission',
  'companyProfit',
]
export default {
  data() {
    return {
      recordColumn: [],
      setMoney: moneyColumn,
    }
  },
  props: {
    dialogVisible: {
      default: false,
      type: Boolean,
    },
    tableData: {
      default: () => [],
      type: Array,
    },
  },
  watch: {
    dialogVisible(newVal, oldVal) {
      if (newVal) {
        this.recordColumn = _.cloneDeep(columns)
        this.recordColumn.splice(0, 1)
        let obj = [
          {
            label: '结算调整原因',
            prop: 'manualSettlementReason',
          },
          {
            label: '结算状态',
            prop: 'settlementRecordStatusDesc',
          },
        ]
        this.recordColumn = [...obj, ...this.recordColumn]
        this.recordColumn.splice(5, 1)
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>