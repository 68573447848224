<template>
  <div class="offer-remark">
    <el-button size="mini" class="ml10" @click="handleOpenDialog">
      <!-- 报价备注 -->
      {{ $t('productDetail.OfferRemark') }}
    </el-button>
    <el-dialog
      :title="$t('productDetail.OfferRemark')"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form :model="form" ref="formRef">
        <el-form-item
          :label="$t('productDetail.OfferRemarkUse')"
          prop="priceRemark"
        >
          <el-input
            type="textarea"
            v-model="form.priceRemark"
            :autosize="{ minRows: 10, maxRows: 10 }"
            maxlength="1000"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="handleClose">
          {{ $t('productDetail.Cancel') }}
        </el-button>
        <el-button type="primary" @click="handleSubmit" :loading="loading">
          {{ $t('productDetail.Confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { ProductViewInteractor } from '@/core/interactors/product/productView'
  export default {
    props: { productId: { type: String, default: '' } },
    data() {
      return { dialogVisible: false, form: { priceRemark: '' }, loading: false }
    },
    methods: {
      async handleSubmit() {
        const params = {
          productId: this.productId,
          priceRemark: this.form.priceRemark,
        }
        this.loading = true
        try {
          const { code } = await ProductViewInteractor.priceUpdateApi(params)
          if (code === '000000') {
            this.$message.success(this.$t('reqmsg.$7'))
            this.handleClose()
          }
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      },
      async handleOpenDialog() {
        try {
          const { code, data } = await ProductViewInteractor.priceRemarkApi(
            this.productId
          )
          if (code === '000000') {
            this.form.priceRemark = data || ''
          }
          this.dialogVisible = true
        } catch (err) {
          console.log(err)
        }
      },
      handleClose() {
        this.$refs.formRef.resetFields()
        this.dialogVisible = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .offer-remark {
    display: inline-block;
  }
</style>
