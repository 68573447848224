<template>
  <div class="page-content">
    <div v-if="!infoParames.oldFlag">
      <!--  印刷方式-->
      <proPrintCalcInfoItem :infoParames="infoParames" :isPlatForm="isPlatForm" v-if="showComponentsBol" />
    </div>
    <div v-else>
      <!--  印刷方式-->
      <p class="mt10 mb10">
        {{ $t('productDetail.PrintingMethod') }}:{{
        lang === 'zh' ? tableTitleCn : tableTitleEn
        }}
      </p>
      <div class="pro-container pro-base-msg" v-for="(item, ind) in printList"
        :key="item.printingWaySelectId + '_' + ind">
        <!-- 印刷方式 -->
        <h4 class="page_title">
          {{ $t('productDetail.PrintingMethod') }}：{{
          lang === 'zh' ? item.printingWaySelect : item.printingWaySelectEn
          }}
        </h4>
        <div class="cot">
          <!-- 可选印刷位 -->
          <p class="item-title">
            {{ $t('productDetail.OptionalPrintingPosition') }}：{{
            lang === 'zh' ? item.positionsCn : item.positionsEn
            }}
          </p>
          <p class="item-title">
            <span class="mr20">
              <!-- 计价方式 -->
              <!-- 统一设置 -->
              {{ $t('productDetail.PricingMethod') }}：{{
              item.pricingConfiguration === 0
              ? $t('productDetail.UnifiedSettings')
              : $t('productDetail.LadderByQuantity')
              }}
            </span>
            <!-- 印刷周期 -->
            <span class="ml20">
              {{ $t('productDetail.PrintingCycle') }}：{{
              item.transportationConfiguration === 0
              ? $t('productDetail.UnifiedSettings')
              : $t('productDetail.LadderByQuantity')
              }}
            </span>
          </p>
          <el-table border width="100%" :data="item.optionsVOS" :key="item.printingWaySelectId">
            <!-- 计价方式 -->
            <el-table-column :label="$t('productDetail.PricingMethod')" align="center" prop="pricingValue">
              <template slot-scope="scope">
                {{
                lang === 'zh'
                ? scope.row.way
                : scope.row.wayEn || scope.row.way
                }}-1{{ $t('productDetail.Position') }}
              </template>
            </el-table-column>
            <!-- 数量阶梯对应的印刷单价 -->
            <el-table-column :label="$t('productDetail.StepPrice')" align="center"
              v-if="item.pricingConfiguration === 1">
              <el-table-column v-for="(ele, ei) in item.stepChild" :key="ele.num" :label="ele.num + ''"
                :prop="'number_price_' + ei" align="center"></el-table-column>
            </el-table-column>
            <!-- 印刷单价（ -->
            <el-table-column :label="$t('productDetail.PrintingUnitPrice')" align="center" prop="price"
              v-if="item.pricingConfiguration === 0"></el-table-column>
            <!-- 数量阶梯对应的印刷周期（工作日） -->
            <el-table-column :label="$t('productDetail.PrintingcycleDay')" align="center"
              v-if="item.transportationConfiguration === 1">
              <el-table-column v-for="(ele, ei) in item.cycleChild" :key="ele.num" :label="ele.num + ''"
                :prop="'number_time_' + ei" align="center"></el-table-column>
            </el-table-column>
            <!-- 印刷周期（工作日） -->
            <el-table-column :label="
                $t('productDetail.PrintingCycle') +
                '(' +
                $t('productDetail.WorkingDay') +
                ')'
              " prop="duration" align="center" v-if="item.transportationConfiguration === 0">
              <template slot-scope="scope">
                <span>{{ scope.row.duration }}</span>
              </template>
            </el-table-column>
            <!-- 版费 -->
            <el-table-column :label="$t('productDetail.EditionFee') + '($)'" align="center"
              prop="fee"></el-table-column>
          </el-table>
          <div v-if="item.positionChild && item.positionChild.length > 1">
            <p class="item-title">{{ $t('productDetail.MDPFS') }}</p>
            <el-table :data="
                item.pricingConfiguration === 0
                  ? item.optionsVOS
                  : item.optionsVOSMulity
              " border :span-method="
                item.pricingConfiguration === 0
                  ? () => {}
                  : (arg) => {
                      return spanMethod(arg, ind)
                    }
              " :key="Math.random()">
              <!-- 计价方式 -->
              <el-table-column :label="$t('productDetail.PricingMethod')" prop="pricingValue" align="center"
                :key="tableKey + 201">
                <template slot-scope="scope">
                  <span>
                    {{
                    lang === 'zh'
                    ? scope.row.pricingValue
                    : scope.row.pricingValueEn || scope.row.pricingValue
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column v-for="(cp, ci) in item.positionChild" :key="ci + tableKey + 10101"
                :label="cp.digit + $t('productDetail.Position')" align="center" prop="printPosition">
                <template slot-scope="scope">
                  <span>{{ scope.row['ratio_' + ci] }}</span>
                </template>
              </el-table-column>
              <!-- 数量阶梯 -->
              <el-table-column :label="$t('productDetail.QuantityLadder')" v-if="item.pricingConfiguration === 1"
                align="center" prop="numberStep"></el-table-column>
              <!-- 位单价 -->
              <el-table-column v-for="(cp, ci) in item.positionChild" :key="ci + tableKey + 100" :label="
                  cp.digit +
                  $t('productDetail.Position') +
                  $t('productDetail.UnitPrice')
                " align="center" prop="printPositionValue">
                <template slot-scope="scope">
                  <span v-if="item.pricingConfiguration === 0">
                    {{
                    (
                    Number(scope.row.price || 0) *
                    Number(scope.row['ratio_' + ci] || 0)
                    ).toFixed(3)
                    }}
                  </span>
                  <span v-if="item.pricingConfiguration === 1">
                    {{
                    (
                    Number(scope.row['ratio_' + ci] || 0) *
                    Number(
                    scope.row[
                    `number_price_${getStepIndex(
                    scope.row.stepChildList,
                    scope.row.numberStep
                    )}`
                    ] || 0
                    )
                    ).toFixed(3)
                    }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!--
      2.2.0新增逻辑：hadPlatFormData为true表示将【平台价格】和【印刷加价】隐藏掉。
      审核页面的详情，hadPlatFormData为true需要展示平台详情组件PlatformProductDetails
    -->
    <template v-if="!infoParames.hadPlatFormData">
      <template v-if="!plantPriceObj.oldFlag">
        <div v-if="
            plantPriceObj.standardCustomized === '标准品' &&
            (plantPriceObj.printingWaySelect || plantPriceObj.printingWaySelectEn)
          ">
          <!-- 平台价格 -->
          <h4>{{ $t('productDetail.PlatformPrice') }}</h4>
          <div class="pro-container pro-base-msg mt10">
            <el-row>
              <el-col :span="10">
                <span>
                  {{ $t('productDetail.SageDisplayPrice') }}：
                  <span v-if="plantPriceObj.specsSelect !== ','">
                    {{
                    lang === 'zh'
                    ? plantPriceObj.specsSelect
                    : plantPriceObj.specsSelectEn || plantPriceObj.specsSelect
                    }}
                  </span>
                </span>
                <span>
                  {{
                  lang === 'zh'
                  ? plantPriceObj.printingWaySelect
                  : plantPriceObj.printingWaySelectEn ||
                  plantPriceObj.printingWaySelect
                  }}{{
                  plantPriceObj.waySelect || plantPriceObj.waySelectEn
                  ? '/'
                  : ''
                  }}
                  {{
                  lang === 'zh'
                  ? plantPriceObj.waySelect
                  : plantPriceObj.waySelectEn || plantPriceObj.waySelect
                  }}
                  {{
                  plantPriceObj.waySelect || plantPriceObj.waySelectEn
                  ? '1 ' + $t('productDetail.Position')
                  : ''
                  }}
                </span>
              </el-col>
              <el-col :span="10">
                <!-- 平台展示免运费 -->
                <span class="mr20">
                  {{ $t('productDetail.PlatformDisplayFreeShipping') }}
                </span>
                <el-switch disabled v-model="plantPriceObj.calcFreight === 1 ? true : false"></el-switch>
              </el-col>
            </el-row>
            <el-table :data="specListNew" border width="100%" class="mt20">
              <!-- 数量 -->
              <el-table-column :label="$t('productDetail.Quantity')" prop="num" align="center"></el-table-column>
              <el-table-column prop="num" align="center">
                <template slot="header">
                  <p>Sage</p>
                  <p>Net Price($)</p>
                </template>
                <template slot-scope="scope">
                  <span>{{ scope.row.price }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="num" align="center">
                <template slot="header">
                  <p>Sage</p>
                  <p>List Price($)</p>
                </template>
                <template slot-scope="scope">
                  <span>
                    {{ (scope.row.price / priceRate).toFixed(2) }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="
            plantPriceObj.standardCustomized === '标准品' &&
            (plantPriceObj.printingWaySelect || plantPriceObj.printingWaySelectEn)
          ">
          <!-- 平台价格 -->
          <h4>{{ $t('productDetail.PlatformPrice') }}</h4>
          <div class="pro-container pro-base-msg mt10">
            <el-row>
              <el-col :span="10">
                <!-- 展示价 -->
                <!-- <span>
                {{ $t('productDetail.SageDisplayPrice') }}：{{
                  lang === 'zh'
                    ? plantPriceObj.specsSelect
                    : plantPriceObj.specsSelectEn
                }}
                ，
              </span> -->
                <span>
                  {{ $t('productDetail.SageDisplayPrice') }}：
                  <span v-if="plantPriceObj.specsSelect !== ','">
                    {{
                    lang === 'zh'
                    ? plantPriceObj.specsSelect
                    : plantPriceObj.specsSelectEn || plantPriceObj.specsSelect
                    }}
                  </span>
                </span>
                <span>
                  {{
                  lang === 'zh'
                  ? plantPriceObj.printingWaySelect
                  : plantPriceObj.printingWaySelectEn ||
                  plantPriceObj.printingWaySelect
                  }}{{
                  plantPriceObj.waySelect || plantPriceObj.waySelectEn
                  ? '/'
                  : ''
                  }}
                  {{
                  lang === 'zh'
                  ? plantPriceObj.waySelect
                  : plantPriceObj.waySelectEn || plantPriceObj.waySelect
                  }}
                  {{
                  plantPriceObj.waySelect || plantPriceObj.waySelectEn
                  ? '1 ' + $t('productDetail.Position')
                  : ''
                  }}
                </span>
              </el-col>
              <el-col :span="10">
                <!-- 平台展示免运费 -->
                <span class="mr20">
                  {{ $t('productDetail.PlatformDisplayFreeShipping') }}
                </span>
                <el-switch disabled v-model="plantPriceObj.calcFreight === 1 ? true : false"></el-switch>
              </el-col>
            </el-row>
            <el-table :data="specList" border width="100%" class="mt20">
              <!-- 数量 -->
              <el-table-column :label="$t('productDetail.Quantity')" prop="num" align="center"></el-table-column>
              <el-table-column prop="num" align="center">
                <template slot="header">
                  <p>Sage</p>
                  <p>Net Price($)</p>
                </template>
                <template slot-scope="scope">
                  <span v-if="
                      waySelectObj &&
                      waySelectObj.numberStep &&
                      waySelectObj.numberStep.length > 0
                    ">
                    {{
                    addNumber(
                    scope.row.netPrice,
                    waySelectObj.numberStep[scope.$index].minPrice
                    )
                    }}
                  </span>
                  <span v-else>
                    {{ addNumber(scope.row.netPrice, waySelectObj.minPrice) }}
                  </span>
                  <el-tooltip class="item" effect="light" placement="top" v-if="
                      plantPriceObj.productPricingStepVOList &&
                      plantPriceObj.productPricingStepVOList.length
                    ">
                    <div slot="content">
                      <p>Blank Net Price($)：{{ scope.row.netPrice }}</p>
                      <p class="mt5">
                        <!-- 印刷单价 -->
                        {{ $t('productDetail.PrintingUnitPrice') }}：{{
                        waySelectObj &&
                        waySelectObj.numberStep &&
                        waySelectObj.numberStep.length > 0
                        ? waySelectObj.numberStep[scope.$index].minPrice
                        : waySelectObj.minPrice
                        }}
                      </p>
                    </div>
                    <span>
                      <i class="el-icon-warning-outline"></i>
                    </span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="num" align="center">
                <template slot="header">
                  <p>Sage</p>
                  <p>List Price($)</p>
                </template>
                <template slot-scope="scope">
                  <span v-if="
                      waySelectObj &&
                      waySelectObj.numberStep &&
                      waySelectObj.numberStep.length > 0
                    ">
                    {{
                    divisionNumber(
                    addNumber(
                    scope.row.netPrice,
                    waySelectObj.numberStep[scope.$index].minPrice
                    ),
                    priceRate
                    )
                    }}
                  </span>
                  <span v-else>
                    {{
                    divisionNumber(
                    addNumber(scope.row.netPrice, waySelectObj.minPrice),
                    priceRate
                    )
                    }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
      <el-row class="mt10" v-if="printingMarkup && printingMarkup.length">
        <el-col class="mt10">
          <p class="mt10">
            {{ $t('productDetail.PrintingMarkup') }}
          </p>
          <el-table style="width: 700px" border :data="printingMarkup">
            <!-- 数量 -->
            <el-table-column :label="$t('productDetail.Quantity')" align="center" prop="num"
              width="120"></el-table-column>
            <el-table-column label="Net Price for Additional Imprint ($)" align="center" prop="price">
              <template slot-scope="scope">{{ scope.row.price }} ea</template>
            </el-table-column>
            <el-table-column label="List Price for Additional Imprint ($)" align="center">
              <template slot-scope="scope">
                {{ (Number(scope.row.price) / Number(priceRate)).toFixed(2) }}
                ea
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </template>
  </div>
</template>
<script>
  import { ProductAddEditInteractor } from '@/core'
  import mixins from '@/views/product/ProductAddEdit/components/util/mixins'
  import proPrintCalcInfoItem from './proPrintCalcInfoItem.vue'
  import _ from 'lodash'
  import Decimal from 'decimal.js'
  import { mapGetters } from 'vuex'
  import { deepCopy } from 'kits'
  export default {
    props: {
      // 接收来自ProductInfo的值，传递给proPrintCalcInfoItem
      isPlatForm: {
        type: Boolean,
        default: false
      }
    },
    mixins: [mixins],
    components: {
      proPrintCalcInfoItem,
    },
    data() {
      return {
        printList: [],
        printPosition: [],
        tableTitleCn: '',
        tableTitleEn: '',
        plantPriceObj: {},
        specList: [],
        specListNew: [],
        waySelectObj: {},
        priceRate: 0,
        tableKey: Math.random(),
        productPrintingSwitch: 0,
        infoParames: {},
        showComponentsBol: false,
        printingMarkup: [],
        sortResult: [],
        netPriceList: [],
        toFixedNumber: 2, //保留几位小数
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    methods: {
      async detailInfo(detail) {
        this.infoParames = JSON.parse(JSON.stringify(detail))
        this.showComponentsBol = true
        this.tableKey = Math.random()
        this.priceRate = detail.priceRate
        this.toFixedNumber = detail.priceScale || 3
        this.productPrintingSwitch = detail.productPrintingSwitch
        await this.getPrintPositionByType()
        await this.setPrintList(detail)
        this.plantPriceObj = detail
        this.plantPriceObj.specsSelect = detail.specsSelect
        this.plantPriceObj.waySelect = detail.waySelect
        if (
          this.plantPriceObj.productLadderPriceVOList &&
          this.plantPriceObj.productLadderPriceVOList.length
        ) {
          this.specList = _.uniqBy(
            this.plantPriceObj.productLadderPriceVOList,
            'num'
          )
        } else {
          this.specList = _.uniqBy(
            this.plantPriceObj.productPricingStepVOList || [],
            'num'
          )
        }

        if (!detail.oldFlag) {
          let res
          if (Array.isArray(this.infoParames.productLadderPriceVOList)) {
            this.netPriceList = this.infoParames?.productLadderPriceVOList.map(
              (item) => {
                return {
                  num: item.num,
                  price: item.netPrice,
                }
              }
            )
          }
          if (
            detail.printingWaySelect == 'Blank' &&
            detail.printingWaySelectEn == 'Blank'
          ) {
            this.specListNew = this.netPriceList
            return
          }

          if (this.infoParames.categoryId) {
            res = await ProductAddEditInteractor.getPrintWay(
              this.infoParames.categoryId?.split(',')[
              this.infoParames.categoryId?.split(',').length - 1
              ]
            )
          }
          let printWay = res?.code === '000000' ? res.data : []
          let list = this.infoParames?.productPrintingValuationVOList
            ? deepCopy(this.infoParames?.productPrintingValuationVOList)
            : []
          // let printWayIdsArr = printWay.map(
          //   (item) => item.productPrintingPricingId
          // )
          // list = list.reduce((previousValue, currentValue) => {
          //   if (printWayIdsArr.includes(currentValue.productPrintingWayId)) {
          //     previousValue.push(currentValue)
          //   }
          //   return previousValue
          // }, [])
          list = list?.map((currentValue) => {
            let productPrintingFullValuationDTOList = []
            let capacityUnit = ''
            currentValue?.productPrintingFullValuationVOList?.forEach(
              (item, index) => {
                capacityUnit = item.capacityUnit
                let obj = {
                  printingPositionNum: item.printingPositionNum,
                  valuationMethod: item.valuationMethod,
                  valuationMethodEn: item.valuationMethodEn,
                  capacity: item.capacity,
                  price: item.price,
                  publishFee: item.publishFee,
                  fullValuationId: item.fullValuationId,
                }
                item.productPrintingLadderVOList.forEach(
                  (subItem, sunIndex) => {
                    obj['profitRate' + sunIndex] = subItem.profitRate
                    obj['price' + sunIndex] = subItem.price
                    obj['printingDay' + sunIndex] = subItem.printingDay
                    obj['printingFrontDay' + sunIndex] =
                      subItem.printingFrontDay
                    obj['deliveryDay' + sunIndex] = subItem.deliveryDay
                    obj['ladderNumId' + sunIndex] = subItem.ladderNumId
                  }
                )
                productPrintingFullValuationDTOList.push(obj)
              }
            )
            // const payItem = printWay.filter(
            //   (item) =>
            //     item.productPrintingPricingId ===
            //     currentValue.productPrintingWayId
            // )?.[0]
            return {
              capacityUnit,
              // optionsVOS: payItem.optionsVOS,
              // nameCn: payItem.nameCn,
              // nameEn: payItem.nameEn,
              // printingPositionIdsOptions: payItem.printingPositionIds,
              printingPositionIds: currentValue.printingPositionIds
                ? currentValue.printingPositionIds.split(',')
                : [],
              valuationMethodIds: currentValue.valuationMethodIds
                ? currentValue.valuationMethodIds.split(',')
                : [],
              printingPositionValue: currentValue.printingPositionValue,
              printingPositionValueEn: currentValue.printingPositionValueEn,
              valuationMethodValue: currentValue.valuationMethodValue,
              valuationMethodValueEn: currentValue.valuationMethodValueEn,
              productPrintingWayId: currentValue.productPrintingWayId,
              profitRateTemplateId: currentValue.profitRateTemplateId,
              deliveryProductNum: currentValue.deliveryProductNum,
              deliveryDayProductNum: currentValue.deliveryDayProductNum,
              printingValuationId: currentValue.printingValuationId,
              productPrintingFullValuationDTOList,
            }
          })
          if (
            this.infoParames.printingWaySelectId &&
            this.infoParames.waySelectId
          ) {
            this.getPrintingMarkupFn(
              [
                this.infoParames.printingWaySelectId,
                this.infoParames.waySelectId,
              ],
              list
            )
          }
        } else {
          this.getSpecList()
        }
      },
      // 获取印刷加价
      getPrintingMarkupFn(val, arr) {
        if (!val || val.length < 2) {
          this.printingMarkup = []
          return
        }
        let printingMarkup = []
        this.printingMarkup = []
        const payObj = arr.filter(
          (item) => item.productPrintingWayId === val[0]
        )?.[0]
        if (!payObj) return
        let printingPositionNumArr =
          payObj.productPrintingFullValuationDTOList.filter(
            (item) => item.printingPositionNum === 1
          )
        this.getMinValue(printingPositionNumArr)
        printingPositionNumArr.map((item) => {
          const idx = payObj.valuationMethodValue
            ?.split(',')
            ?.findIndex((k) => k === item.valuationMethod)
          item.valuationMethodId = payObj.valuationMethodIds[idx]
          return item
        })

        let currentSelect = printingPositionNumArr.filter(
          (item) => item.valuationMethodId === val[1]
        )?.[0]

        this.netPriceList.forEach((item, index) => {
          printingMarkup.push({
            num: item.num,
            price: currentSelect && currentSelect['price' + index],
          })
          this.specListNew.push({
            num: item.num,
          })
        })

        for (var i = 0; i < printingMarkup.length; i++) {
          if (Number(printingMarkup[i].price) - Number(this.sortResult[i])) {
            this.printingMarkup.push({
              num: printingMarkup[i].num,
              price: new Decimal(printingMarkup[i].price)
                .sub(new Decimal(this.sortResult[i]))
                .toDecimalPlaces(this.toFixedNumber)
                .toNumber()
                .toFixed(this.toFixedNumber),
              // price: (
              //   Number(printingMarkup[i].price) - Number(this.sortResult[i])
              // ).toFixed(2),
            })
          }
        }

        this.specListNew.map((item, index) => {
          item.price = this.sortResult[index]
          return item
        })
      },
      // 找出最小值
      getMinValue(val) {
        if (!val || !val.length) return
        let arr = []
        val.forEach((item) => {
          let subArr = []
          this.netPriceList.forEach((sub, subIndex) => {
            subArr.push(item['price' + subIndex])
          })
          arr.push(subArr)
        })
        const arrSort = arr.reduce((acc, curr) => {
          curr.forEach((value, index) => {
            if (!Array.isArray(acc[index])) {
              acc[index] = []
            }
            acc[index].push(value)
          })
          return acc
        }, [])
        this.sortResult = arrSort.map((subArr) => {
          let min = undefined
          for (const value of subArr) {
            if (
              (typeof value === 'number' && value !== 0) ||
              (typeof value === 'string' && value.trim() !== '')
            ) {
              if (min === undefined || value * 1 < min) {
                min = value * 1
              }
            }
          }
          return min // 返回最小的非零且非空元素
        })
      },
      setPrintList(detail) {
        this.tableKey = Math.random()
        this.printList = detail.productPrintingWayVOList
          ? JSON.parse(JSON.stringify(detail.productPrintingWayVOList))
          : []
        let titleCn = []
        let titleEn = []
        this.printList?.forEach((item, ind) => {
          this.$set(item, 'optionsVOS', JSON.parse(item.printingWay))
          let arr = []
          let arrEn = []
          // 根据印刷位置id  获取到名称
          this.printPosition.forEach((ele) => {
            if (item.printingPositions.indexOf(ele.productSettingId) > -1) {
              arr.push(ele.nameCn)
              arrEn.push(ele.nameEn)
            }
          })
          this.$set(item, 'positionsCn', arr.join(','))
          this.$set(item, 'positionsEn', arrEn.join(','))

          this.$set(
            item,
            'positionChild',
            item.optionsVOS[0]?.positionChildList
              ? JSON.parse(item.optionsVOS[0].positionChildList)
              : []
          )
          this.$set(
            item,
            'cycleChild',
            item.optionsVOS[0]?.cycleChildList
              ? JSON.parse(item.optionsVOS[0].cycleChildList)
              : []
          )
          this.$set(
            item,
            'stepChild',
            item.optionsVOS[0]?.stepChildList
              ? JSON.parse(item.optionsVOS[0].stepChildList)
              : []
          )

          titleCn.push(item.printingWaySelect)
          titleEn.push(item.printingWaySelectEn)

          let arrStep = []
          item.optionsVOS.forEach((item2) => {
            item.stepChild?.forEach((item3) => {
              let obj = {
                ...item2,
                numberStep: item3.num,
                profitRate: item3.profitRate,
              }
              arrStep.push(obj)
            })
          })
          this.$set(item, 'optionsVOSMulity', arrStep)

          this.getSpanArr(item.optionsVOSMulity, ind)
        })
        this.tableTitleCn = titleCn.toString()
        this.tableTitleEn = titleEn.toString()
      },
      // 印刷位置
      async getPrintPositionByType() {
        let res = await ProductAddEditInteractor.getPrintPosition(5)
        if (res.code === '000000') {
          this.printPosition = [...res.data]
        }
      },

      // 获取到对应的计价方式
      getSpecList() {
        let obj_1 = this.printList?.find((it) => {
          return (
            it.printingWaySelectId === this.plantPriceObj.printingWaySelectId
          )
        })
        if (obj_1?.optionsVOS && obj_1.pricingConfiguration == 1) {
          obj_1.optionsVOS = obj_1?.optionsVOS?.map((it) => {
            return {
              ...it,
              numberStep: JSON.parse(it.stepChildList || '[]'),
            }
          })
        }

        if (obj_1) {
          this.waySelectObj = obj_1.optionsVOS?.find((it) => {
            return (
              it.productPrintingPricingOptionsId ===
              this.plantPriceObj.waySelectId
            )
          })
        } else {
          this.specList.forEach((item, i) => {
            this.$set(this.waySelectObj, `number_price_${i}`, 0)
            this.$set(this.waySelectObj, `price`, 0)
          })
        }

        if (obj_1?.optionsVOS && obj_1.pricingConfiguration == 1) {
          this.waySelectObj?.numberStep?.forEach((it, i) => {
            let priceList = []
            obj_1.optionsVOS.forEach((ot, oi) => {
              priceList.push(ot[`number_price_${i}`])
            })
            let minPrice = Math.min(...priceList)
            this.$set(it, 'minPrice', minPrice)
          })
        }
        if (obj_1?.optionsVOS && obj_1.pricingConfiguration == 0) {
          let priceList = []
          obj_1.optionsVOS.forEach((ot, oi) => {
            priceList.push(ot.price)
          })
          let minPrice = Math.min(...priceList)
          this.$set(this.waySelectObj, 'minPrice', minPrice)
        }

        if (!this.waySelectObj) {
          this.specList?.forEach((item, i) => {
            let key = `number_price_${i}`
            this.waySelectObj = {
              ...this.waySelectObj,
              price: 0,
              [key]: 0,
            }
          })
        }
      },

      // 两数想加
      addNumber(val1, val2) {
        if (!val1) val1 = 0
        if (!val2) val2 = 0
        return (Number(val1) + Number(val2)).toFixed(3)
      },
      // 两数相乘
      divisionNumber(val1, val2) {
        if (!val1 || !val2) return 0
        return (Number(val1) / val2).toFixed(2)
      },
      getStepIndex(list, item) {
        let stepList = JSON.parse(list)
        let index = 0
        stepList.forEach((it, i) => {
          if (it.num === item) {
            index = i
          }
        })
        return index
      },
    },
  }
</script>
<style lang="scss" scoped>
  .page-content {
    width: 100%;

    .page_title {
      height: 50px;
      line-height: 50px;
      background-color: #f2f2f2;
      width: 100%;
      padding-left: 10px;
    }

    .cot {
      box-sizing: border-box;
      padding: 20px;

      .item-title {
        line-height: 40px;
        display: block;
        height: 40px;
      }
    }
  }
</style>