<template>
  <div>
    <div v-if="noLocShow">--</div>
    <div v-else>
      <div v-if="locs.length > 0" @click="addLoc">
        <template v-for="(i, index) in locs">
          <div
            :key="index"
            style="color: #236ce9; text-align: left"
            v-if="index <= 3"
          >
            <div v-if="type === 1" class="multi">
              <span class="el-link el-link--primary ell">
                {{ i.locationNumber }}({{ i.num }})
                <span v-if="index == 3 && locs.length > 4">...</span>
                <span v-else>;</span>
              </span>
              <span class="el-link el-link--primary ell">
                {{ i.locationInNumber }}({{ i.inNum }})
                <span v-if="index == 3 && locs.length > 4">...</span>
                <span v-else>;</span>
              </span>
            </div>
            <div v-else class="ell">
              <span class="el-link el-link--primary">
                {{ i.locationNumber }}({{ i.num }})
              </span>
              <span v-if="index == 3 && locs.length > 4">...</span>
              <span v-else>;</span>
            </div>
          </div>
        </template>
      </div>
      <el-link type="primary" :underline="false" v-else @click="addLoc">
        请设置
      </el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectedLoction',
  props: {
    type: {
      type: [Number, String],
      default: 0,
    },
    locs: {
      type: Array,
      default: () => [],
    },
    tableIndex: {
      type: [Number, String],
      default: '0',
    },
    status: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  computed: {
    isDetail() {
      //本组件会被入库和出库使用
      return this.isInboundDetail || this.isOutboundDetail
    },
    isInboundDetail() {
      return this.$route.path === '/stock-center/inbound-sheet-detail'
    },
    isOutboundDetail() {
      return (
        this.$route.path ===
        '/stock-center/stock-center/outbound-sheet/outbound-sheet-detail'
      )
    },
    noLocShow() {
      // 入库库位展示逻辑
      if (!this.isDetail) {
        //不是详情页不展示--
        return false
      } else {
        if (this.isInboundDetail) {
          // 已入库和异常入库
          if ([2, 5].includes(this.status)) {
            return false
          } else {
            return true
          }
        } else {
          if (this.isOutboundDetail) {
            if (this.status === 2) {
              return false
            } else {
              return true
            }
          }
        }
      }
    },
  },
  // 组件状态值
  methods: {
    addLoc() {
      this.$emit('add-loc', this.tableIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.multi {
  display: flex;
  justify-content: space-between;
  > span {
    width: 50%;
    text-align: left;
    display: block;
  }
}
</style>
