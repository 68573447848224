var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"packing-detail-wrapper"},[(_vm.packingSizeForm.sizes.length > 0)?[_c('div',{staticClass:"content"},[_c('el-tooltip',{attrs:{"placement":"top","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('Detail',{attrs:{"sizes":_vm.packingSizeForm.sizes}})],1),_c('div',[_c('Detail',{attrs:{"sizes":_vm.isSizesNotOver5 ? _vm.theTop5Sizes : _vm.packingSizeForm.sizes}}),(_vm.isSizesNotOver5)?_c('p',[_vm._v("...")]):_vm._e()],1)])],1)]:_c('p',{staticClass:"item"},[_vm._v("-")]),_c('p',{style:({ visibility: _vm.showEdit ? 'visible' : 'hidden' })},[_c('i',{staticClass:"el-icon-edit",on:{"click":_vm.showDialog}})])],2),_c('el-dialog',{attrs:{"title":"装箱尺寸","visible":_vm.isShow,"width":"600px","close-on-click-modal":false},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确 定")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("取 消")])],1)]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.packingSizeForm,"rules":_vm.rules}},[_c('el-table',{attrs:{"data":_vm.packingSizeForm.sizes,"border":"","max-height":400}},[_c('el-table-column',{attrs:{"align":"center","label":"装箱尺寸"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"prop":'sizes.' + scope.$index + '.boxLengthCm',"rules":[
                    {
                      required: true,
                      message: '请输入',
                      trigger: 'change',
                    } ]}},[_c('el-input',{attrs:{"type":"number","placeholder":"长 "},model:{value:(scope.row.boxLengthCm),callback:function ($$v) {_vm.$set(scope.row, "boxLengthCm", $$v)},expression:"scope.row.boxLengthCm"}},[_c('template',{slot:"append"},[_vm._v("CM")])],2)],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"prop":'sizes.' + scope.$index + '.boxWidthCm',"rules":[
                    {
                      required: true,
                      message: '请输入',
                      trigger: 'change',
                    } ]}},[_c('el-input',{attrs:{"type":"number","placeholder":"宽 "},model:{value:(scope.row.boxWidthCm),callback:function ($$v) {_vm.$set(scope.row, "boxWidthCm", $$v)},expression:"scope.row.boxWidthCm"}},[_c('template',{slot:"append"},[_vm._v("CM")])],2)],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"prop":'sizes.' + scope.$index + '.boxHeightCm',"rules":[
                    {
                      required: true,
                      message: '请输入',
                      trigger: 'change',
                    } ]}},[_c('el-input',{attrs:{"type":"number","placeholder":"高 "},model:{value:(scope.row.boxHeightCm),callback:function ($$v) {_vm.$set(scope.row, "boxHeightCm", $$v)},expression:"scope.row.boxHeightCm"}},[_c('template',{slot:"append"},[_vm._v("CM")])],2)],1)],1)],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"删除","placement":"top-start"}},[_c('el-button',{attrs:{"type":"danger","plain":"","circle":"","size":"mini","icon":"el-icon-delete"},on:{"click":function($event){return _vm.deleteSize(scope.$index)}}})],1)]}}])})],1),_c('el-row',[_c('el-button',{staticStyle:{"width":"100%","color":"#1890ff","border-top":"none"},attrs:{"icon":"el-icon-plus"},on:{"click":_vm.addSize}},[_vm._v(" 新增 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }