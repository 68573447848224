import { render, staticRenderFns } from "./client-manage-search.vue?vue&type=template&id=135fa54a&scoped=true&"
import script from "./client-manage-search.vue?vue&type=script&lang=js&"
export * from "./client-manage-search.vue?vue&type=script&lang=js&"
import style0 from "./client-manage-search.vue?vue&type=style&index=0&id=135fa54a&lang=scss&scoped=true&"
import style1 from "./client-manage-search.vue?vue&type=style&index=1&id=135fa54a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "135fa54a",
  null
  
)

export default component.exports