const mergeColumns = [
  '产品编码',
  '产品名称',
  '规格',
  '产品类型',
  '产品类别',
  '申请出库数量/单位',
  '单位', //其他入库
  '库存', //其他入库
  '实际出库数量',
]

export const tableMixin = {
  data() {
    return {
      spanArr: [], //合并参数
      pos: 0, //合并参数
    }
  },

  methods: {
    /**
     * 合并单元格objectSpanMethod
     */
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (mergeColumns.includes(column.label)) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },
    /**
     * 合并单元格getSpanArr
     */
    getSpanArr(list) {
      for (var i = 0; i < list.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
          // 添加orderProductUniqueId
          if (
            `${list[i].skuId}${list[i].orderProductUniqueId}${list[i].mainFlag}${list[i].parentSku}` ===
            `${list[i - 1].skuId}${list[i - 1].orderProductUniqueId}${
              list[i - 1].mainFlag
            }${list[i - 1].parentSku}`
          ) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },

    // 重置合并相关参数
    resetCombineParams() {
      this.spanArr = []
      this.pos = 0
    },

    formatTableData(list, field = 'warehouseOutLotVOList') {
      //warehouseOutLotVOList默认为出库
      let tableData = [],
        productCache = {} //解决重复数据
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        const sku = item.skuId || item.sku
        const mainFlag = item.mainFlag
        const parentSku = item.parentSku
        // 添加orderProductUniqueId
        const orderProductUniqueId = item.orderProductUniqueId
        if (productCache[sku + mainFlag + parentSku + orderProductUniqueId]) {
          continue
        }
        productCache[sku + mainFlag + parentSku + orderProductUniqueId] =
          sku + mainFlag + parentSku + orderProductUniqueId

        // 添加orderProductUniqueId
        if (item[field] && item[field].length !== 0) {
          item[field].forEach((lotItem) => {
            tableData.push({
              ...item,
              ...lotItem,
              salesOrderNumber: item.salesOrderNumber,
              skuId: item.skuId,
              orderProductUniqueId: item.orderProductUniqueId,
            })
          })
        } else {
          tableData.push({
            ...item,
          })
        }
        productCache[sku] = productCache[sku]
      }

      return tableData
    },
  },
}
