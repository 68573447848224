<template>
  <div class="search_form">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <!-- 报价单号 -->
      <el-form-item
        :label="$t('inquiryList.QuotationNumber')"
        prop="priceSheetCode"
      >
        <el-input
          v-model="form.priceSheetCode"
          :placeholder="$t('placeholder.plsInput')"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <!-- 公司名称 -->
      <el-form-item :label="$t('inquiryList.CompanyName')" prop="companyName">
        <el-input
          v-model="form.companyName"
          :placeholder="$t('placeholder.plsInput')"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <!-- 联系电话 -->
      <el-form-item
        :label="$t('inquiryList.ContactNumber')"
        prop="customerPhone"
      >
        <el-input
          v-model="form.customerPhone"
          :placeholder="$t('placeholder.plsInput')"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <!-- 询盘来源 -->
      <el-form-item
        :label="$t('inquiryList.InquirySource')"
        prop="enquirySourceId"
      >
        <el-select
          v-model="form.enquirySourceId"
          :placeholder="$t('placeholder.plsSel')"
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="item in enquirySourceNameList"
            :key="item.amountId"
            :label="item.amountName"
            :value="item.amountId"
          />
        </el-select>
      </el-form-item>
      <!-- 询盘状态 -->
      <el-form-item
        :label="$t('inquiryList.InquiryStatus')"
        prop="enquiryStatus"
      >
        <el-select
          v-model="form.enquiryStatus"
          :placeholder="$t('placeholder.plsSel')"
          class="w100"
        >
          <!-- 作废 -->
          <el-option :label="$t('inquiryList.Invalid')" value="1" />
          <!-- 询盘失败 -->
          <el-option :label="$t('inquiryList.InquiryFailed')" value="2" />
          <!-- 手动完成 -->
          <el-option :label="$t('inquiryList.DoneManually')" value="3" />
          <!-- 待报价 -->
          <el-option :label="$t('inquiryList.PendingQuotation')" value="4" />
          <!-- 已报价 -->
          <el-option :label="$t('inquiryList.Quoted')" value="5" />
          <!-- 已下单 -->
          <el-option :label="$t('inquiryList.Ordered')" value="6" />
        </el-select>
      </el-form-item>
      <!-- 客户订单号 -->
      <el-form-item
        :label="$t('inquiryList.CustomerOrderNumber')"
        prop="customerOrderCode"
      >
        <el-input
          v-model="form.customerOrderCode"
          :placeholder="$t('placeholder.plsInput')"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <!-- 产品编码/名称 -->
      <el-form-item
        :label="$t('inquiryList.ProductCodeName')"
        prop="productInfo"
      >
        <el-input
          v-model="form.productInfo"
          :placeholder="$t('placeholder.plsInput')"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <!-- 询盘数量 -->
      <el-form-item :label="$t('inquiryList.InquiryQuantity')">
        <div class="line_input">
          <el-form-item prop="enquiryBegin">
            <el-input
              v-model="form.enquiryBegin"
              maxlength="10"
              :placeholder="$t('placeholder.plsInput')"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
          <span>{{ $t('other.To') }}</span>
          <el-form-item prop="enquiryEnd">
            <el-input
              v-model="form.enquiryEnd"
              maxlength="10"
              :placeholder="$t('placeholder.plsInput')"
              clearable
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 创建人 -->
      <el-form-item :label="$t('inquiryList.Creator')">
        <el-input
          clearable
          v-if="lang === 'en'"
          v-model="creatorEn"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick()"
          @change="creatorChange"
        ></el-input>
        <el-input
          v-else
          clearable
          v-model="creator"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick()"
          @change="creatorChange"
        ></el-input>
      </el-form-item>
      <!-- 创建时间 -->
      <el-form-item :label="$t('inquiryList.CreationTime')">
        <el-date-picker
          v-model="daterange"
          type="daterange"
          value-format="yyyy-MM-dd"
          :start-placeholder="$t('placeholder.startDate')"
          :end-placeholder="$t('placeholder.endDate')"
        />
      </el-form-item>
      <!-- 客户代表 -->
      <el-form-item :label="$t('inquiryList.CustomerRepresentative')">
        <el-input
          clearable
          v-model="businessName"
          :placeholder="$t('placeholder.plsSel')"
          @focus="selectUserClick('business')"
          @change="businessNameChange"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('inquiryList.Remark')">
        <el-input
          clearable
          v-model="form.demand"
          :placeholder="$t('placeholder.plsInput')"
        ></el-input>
      </el-form-item>
      <el-divider />

      <div class="btn">
        <!-- 确定 -->
        <el-button type="primary" @click="searchClick">
          {{ $t('inquiryList.Confirm') }}
        </el-button>
        <!-- 重置 -->
        <el-button @click="resetClick">
          {{ $t('inquiryList.Reset') }}
        </el-button>
      </div>
    </el-form>

    <!-- 人员组织架构树 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>

<script>
import { orderGetDict } from '@/api/order'
import UserChoose from '@/components/userChoose2'
import { mapGetters } from 'vuex'
export default {
  name: 'inquirySearch',
  components: { UserChoose },
  data() {
    return {
      creator: '', //创建人
      creatorEn: '', //创建人英文
      userId: [], //创建人id
      businessName: '', // 客户代表名称
      businessNameId: [], // 客户代表id
      enquirySourceNameList: [],
      form: {},
      daterange: [],
      rules: {},
      isUserChoose: '', //当前组织架构是谁调用
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  created() {
    this.getDict()
  },
  watch: {
    daterange(dateArray) {
      if (dateArray) {
        this.form.startSeasTime = dateArray[0]
        this.form.endSeasTime = dateArray[1]
      } else {
        this.form.startSeasTime = ''
        this.form.endSeasTime = ''
      }
    },
  },
  methods: {
    getDict() {
      orderGetDict({ amountType: 2 }).then((res) => {
        this.enquirySourceNameList = res.data
      })
    },

    //创建人选择
    selectUserClick(str) {
      this.isUserChoose = str
      if (str === 'business') {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.businessNameId)
      } else {
        this.$refs['UserChoose'].showAddEdit('listSearch', this.userId)
      }
    },

    //创建人返回
    chooseUser(checkedUser) {
      if (this.isUserChoose === 'business') {
        this.businessNameChange()
      } else {
        this.creatorChange()
      }
      if (!checkedUser.length) {
        return
      }
      let userName = []
      let userNameEn = []

      checkedUser.forEach((item) => {
        userName.push(item.name)
        userNameEn.push(item.englishName)
        if (this.isUserChoose === 'business') {
          this.businessName = userName.join(',')
          this.businessNameId.push(item.id)
        } else {
          this.userId.push(item.id)
          this.creator = userName.join(',')
          this.creatorEn = userNameEn.join(',')
        }
      })
    },

    // 删除创建人
    creatorChange() {
      this.userId = []
      this.creator = ''
      this.creatorEn = ''
      this.form.userId = ''
    },

    // 删除客户代表
    businessNameChange() {
      this.businessNameId = []
      this.businessName = ''
      this.form.businessName = ''
    },

    searchClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.userId) {
            this.form.userId = this.userId.join(',')
          }
          if (this.businessNameId) {
            this.form.businessId = this.businessNameId.join(',')
          }
          this.$emit('searchClick', this.form)
        }
      })
    },
    resetClick() {
      this.creator = ''
      this.creatorEn = ''
      this.userId = []
      this.businessName = ''
      this.businessNameId = []
      this.daterange = []
      this.$refs.form.resetFields()
      this.form = this.$options.data().form
    },
  },
}
</script>

<style lang="scss" scoped>
.search_form {
  padding: 0 20px 20px 20px;
  .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  .btn {
    padding: 20px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
  }
}
::v-deep {
  .el-input--suffix {
    padding-right: 5px !important;
  }
  .el-cascader {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 70px 0 28px 0 !important;
  }
}
</style>
