<template>
  <el-dialog
    title="库存解锁"
    :visible="visible"
    width="600px"
    :close-on-click-modal="false"
    @close="visible = false"
    append-to-body
    modal-append-to-body
  >
    <el-row :gutter="20">
      <el-col :span="12">锁定仓库：{{ unlockData.warehouseName }}</el-col>
      <el-col :span="12">锁定量：{{ unlockData.num }}</el-col>
    </el-row>
    <el-row class="mt20">
      <el-form
        :model="lockForm"
        :rules="lockRules"
        ref="lockForm"
        label-width="70px"
      >
        <el-form-item label="解锁数" prop="unlockNum" class="label-left">
          <el-input
            v-model.number="lockForm.unlockNum"
            @input="numChange"
            maxlength="10"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <div class="footer mt30 t_a_c">
      <el-button type="primary" class="ml30" @click="unlock">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { storeManage } from '@/core/interactors/stock-center/stock-manage'
export default {
  data() {
    return {
      lockForm: {
        unlockNum: '',
      },
      unlockData: {},
      visible: false,
      lockRules: {
        unlockNum: [
          { required: true, message: '请输入解锁数', trigger: 'blur' },
          {
            pattern: /^[1-9]\d*$/,
            message: '请输入>0的整数',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    showDialog() {
      this.visible = true
    },
    // 解锁
    unlock() {
      this.$refs['lockForm'].validate(async (valid) => {
        if (valid) {
          const { stockLockRecordId, lotNo, stockLotId } = this.unlockData
          const params = {
            unlockNum: this.lockForm.unlockNum,
            stockLockRecordId,
            lotNo,
            stockLotId,
          }
          const res = await storeManage.stockLockRecordUnlock(params)

          if (res.code === '000000') {
            this.$message.success('保存成功！')
            this.close()
            this.$parent.onSearch()
          }
        }
      })
    },

    // 解锁数不能大于锁定量
    numChange() {
      if (this.lockForm.unlockNum > this.unlockData.num) {
        this.lockForm.unlockNum = this.unlockData.num
        this.$message.warning('解锁量不能大于锁定量')
      }
    },

    // 关闭弹窗重置表单
    close() {
      this.$refs.lockForm.resetFields()
      this.visible = false
    },
  },
}
</script>
>
