<template>
  <el-dialog
    title="选择产品"
    width="1100px"
    class="order-product"
    :visible.sync="productListVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="ruleForm" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-form-item prop="codeOrName">
            <el-input
              placeholder="请输入产品名称/编码"
              v-model="ruleForm.codeOrName"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="categoryId">
            <el-select
              style="width: 100%"
              placeholder="请选择类别"
              v-model="ruleForm.categoryId"
              clearable
            >
              <el-option
                v-for="item in categoryList"
                :key="item.productCategoryId"
                :label="item.nameEn"
                :value="item.productCategoryId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <div class="text-left">
            <el-button @click="search">搜索</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div class="container mt20">
      <el-table
        border
        :data="rowData"
        ref="multipleTable"
        @selection-change="changeFun"
        @select="selectMemoryFn"
        @select-all="selectAll"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column
          align="center"
          label="产品编号"
          prop="productCode"
          width="120"
        >
          <template slot-scope="scope">
            <div class="page-link">
              {{ scope.row.productCode }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          align="center"
          label="产品名称"
          prop="productName"
        />
        <el-table-column
          width="150"
          align="center"
          label="产品类别"
          prop="categoryName"
        />
        <el-table-column width="150" align="center" label="编码" prop="skuId" />
        <el-table-column width="200" align="center" label="规格">
          <template slot-scope="scope">
            {{
              formatColumn(
                scope.row.specsType,
                scope.row.specsValue1,
                scope.row.specsValue2
              )
            }}
          </template>
        </el-table-column>
        <el-table-column width="180" align="center" label="库存">
          <template slot-scope="scope">
            <span v-if="scope.row.stockWarehouseNumVOList.length">
              {{ scope.row.stockWarehouseNumVOList[0].warehouseName }}:
              {{ scope.row.stockWarehouseNumVOList[0].num }}
            </span>
            <span v-else>/</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
    ></el-pagination>

    <div class="text-center mt20">
      <el-button type="primary" @click="save()">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { productGetSkuByProductId } from '@/api/product/productList'
import { categoryListForSelect } from '@/api/product/productPrintWay'
export default {
  data() {
    return {
      productListVisible: false,
      rowData: [],
      categoryList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      ruleForm: {
        codeOrName: '',
        categoryId: '',
      },
      ids: [],
      multipleSelection: [],
    }
  },
  created() {
    this.getProductList()
    this.getDict()
  },
  watch: {
    rowData(oldVal, newVal) {
      // 监听数据变化，然后执行分页记忆自动选中回调
      this.$nextTick(() => {
        this.selectMemoriedDataFn()
      })
    },
  },
  methods: {
    init() {
      this.ids = []
      this.multipleSelection = []
      this.currentPage = 1
      this.getProductList()
    },

    // 获取下拉字典项
    getDict() {
      categoryListForSelect().then((res) => {
        this.categoryList = res.data || []
      })
    },

    getProductList() {
      let self = this
      const params = {
        pageNo: this.currentPage,
        pageLe: this.pageSize,
        ...this.ruleForm,
      }
      productGetSkuByProductId(params).then((res) => {
        self.rowData = res.data.data || []
        self.total = res.data.total || 0
        // self.$nextTick(function () {
        //   self.rowData.forEach((row) => {
        //     if (row.selected) {
        //       self.$refs.multipleTable.toggleRowSelection(row, true)
        //     }
        //   })
        // })
      })
    },
    selectAll(selection) {
      // 全选切换方法
      if (selection.length) {
        // 全选
        this.multipleSelection[this.currentPage - 1] = selection
      } else {
        // 全不选
        this.multipleSelection[this.currentPage - 1] = []
      }
    },
    selectMemoryFn(val, row) {
      // 设置分页记忆二位数组方法
      // 注意：val 传过来默认为数组类型 ，row 为 Object（当前选择数据对象）
      let currentArr = this.multipleSelection[this.currentPage - 1] // 当前分页对应数组
      if (!currentArr) {
        this.multipleSelection[this.currentPage - 1] = val // 不存在这个二维数组，则创建这个二位数组
      } else {
        // 存在
        if (val.includes(row)) {
          // 选中
          this.multipleSelection[this.currentPage - 1] = val
        } else {
          // 取消
          delete currentArr[currentArr.indexOf(row)]
        }
      }
    },
    selectMemoriedDataFn() {
      // 分页记忆自动选中方法
      let currentArr = this.multipleSelection[this.currentPage - 1] // 当前分页对应被选中数据
      if (currentArr && currentArr.length) {
        // 存在则继续执行
        let tempRowsIDs = this.rowData.map((L) => L.productId) // 当前分页被选中数据的id集合
        currentArr.forEach((item, index) => {
          // 遍历当前分页被选中数据
          if (tempRowsIDs.includes(item.productId)) {
            // id匹配上，则选中
            this.$refs.multipleTable.toggleRowSelection(
              this.rowData[tempRowsIDs.indexOf(item.productId)]
            )
          }
        })
      }
    },

    // 切换分页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getProductList()
    },

    // 搜索
    search() {
      this.currentPage = 1
      this.getProductList()
    },

    // 数据格式处理
    formatColumn(type, value1, value2) {
      const arr = type.split(',')
      if (!arr[0]) {
        return '/'
      } else if (arr[0] && arr.length == 1) {
        return arr + ':' + value1
      } else {
        return arr[0] + ':' + value1 + '，' + arr[1] + ':' + value2
      }
    },

    // 选中
    changeFun(val) {
      this.$nextTick(() => {
        let ids = []
        this.multipleSelection.forEach((L) => L.forEach((M) => ids.push(M.id)))
        this.ids = ids
      })
      // this.multipleSelection = rows
    },

    // 确认
    save() {
      if (!this.multipleSelection.length) {
        this.$message.warning('至少选择一条产品')
        return false
      }
      this.$emit('productListSave', this.multipleSelection)
    },

    // 关闭
    close() {
      this.productListVisible = false
      this.$refs['ruleForm'].resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.order-product {
  .container {
    height: 550px;
    overflow-y: auto;
  }
}
</style>
