<template>
  <div class="divider">
    <div class="sub_title">{{ content }}</div>
    <div class="dashed"></div>
  </div>
</template>
<script>
  export default {
    props: { content: { type: String, default: '' } },
  }
</script>
<style lang="scss" scoped>
  .divider {
    margin: 20px 0;
    display: flex;
    align-items: center;
    .sub_title {
      margin: 0 10px 0 20px;
    }
    .dashed {
      flex: 1;
      border-top: 1px dashed #ccc;
    }
  }
</style>
