<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
    append-to-body
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-row>
      <el-col :span="24" v-if="tableData && tableData.length">
        <el-form label-position="top">
          <el-form-item v-for="(item, index) in tableData" :key="index">
            <template slot="label">
              <label class="label-text f_s_14">
                <i style="color: #ff4d4f" v-if="item.required === '1'">*</i>
                <i style="color: #fff" v-else>*</i>
                {{ item.nameCn }}
                <span v-if="item.isMain === '0'">(关联标签)</span>
              </label>
            </template>
            <!-- 多选 -->
            <el-row style="padding-left: 30px">
              <el-checkbox-group
                v-if="
                  item.multiple === '1' &&
                  item.keyChildEditVOList &&
                  item.keyChildEditVOList.length > 0
                "
                v-model="tableData[index].value"
              >
                <el-checkbox
                  v-for="(child, i) in item.keyChildEditVOList"
                  :key="i"
                  :label="child.keyChildId"
                  :disabled="child.disabled"
                >
                  {{ child.nameCn }}
                </el-checkbox>
              </el-checkbox-group>
              <!-- 单选 -->
              <el-radio-group
                v-if="
                  item.multiple === '0' &&
                  item.keyChildEditVOList &&
                  item.keyChildEditVOList.length > 0
                "
                v-model="tableData[index].value"
              >
                <el-radio
                  v-for="child2 in item.keyChildEditVOList"
                  :key="child2.keyChildId"
                  :label="child2.keyChildId"
                  :disabled="child2.disabled"
                >
                  {{ child2.nameCn }}
                </el-radio>
              </el-radio-group>
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="24" v-else class="t_a_c mt30 mb30">
        还没有可选择的产品标签...
      </el-col>
    </el-row>
    <template #footer>
      <el-row class="t_a_c">
        <el-button @click="close">关闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { ProductListInteractor } from '@/core'
  import { deepCopy } from 'kits'
  export default {
    name: 'ProLabelApplyModel',
    data() {
      return {
        title: '查看标签',
        form: { checkedCities: [] },
        dialogFormVisible: false,
        categoryId: '', // 产品分类id
        tableData: [],
        productKeyEditDTOList: [], //产品标签数据
        checkedEquipments: [],
        checkList: [],
        radioArray: [],
        keyChildEditVOList: [],
        labelList: [],
        tableData2: [],
        selLinkChildIds: [], // 第一次请求的数据选中时关联的linkedChildIds
        childLabelChecked: [], //关联标签勾选的值
      }
    },
    created() {},
    methods: {
      //展示弹框
      proLabelApplyModel(categoryId, keyChildEditVOList) {
        if (categoryId) {
          this.categoryId = categoryId
          this.getListForPopupByCategoryId()
        }
        if (keyChildEditVOList && keyChildEditVOList.length) {
          this.keyChildEditVOList = keyChildEditVOList
        }

        this.dialogFormVisible = true
      },
      // 标签弹框数据
      async getListForPopupByCategoryId() {
        let that = this
        let response = await ProductListInteractor.listForPopupByCategoryIdApi({
          categoryId: this.categoryId,
        })

        //判断标签中是否有关联标签
        let initData = response.data
        let initlinkedParentIds = [] //标签的关联id

        let requestParentIds = []

        const keyParentIds = initData.map((i) => i.keyParentId)
        initData.forEach((item) => {
          item.linkedParentIds &&
            initlinkedParentIds.push(...item.linkedParentIds.split(','))
        })

        initlinkedParentIds.forEach((item) => {
          if (keyParentIds.includes(item)) {
            requestParentIds.push(item)
          }
        })
        let isNoRelate = requestParentIds.length //标签内部是否有关联其他标签

        if (isNoRelate) {
          if ((response.code = '000000')) {
            this.tableData = response.data.map((item) => {
              if (item.multiple == '1') {
                return {
                  ...item,
                  value: [],
                }
              } else {
                return {
                  ...item,
                  value: '',
                }
              }
            })
            if (that.keyChildEditVOList.length) {
              that.tableData.forEach((item, index) => {
                if (
                  item.keyParentId == that.keyChildEditVOList[index].parentKeyId
                ) {
                  item.value =
                    item.multiple === '1'
                      ? that.keyChildEditVOList[index].childKeyIds.split(',')
                      : that.keyChildEditVOList[index].childKeyIds
                  item.productId =
                    that.keyChildEditVOList[index].productId || ''
                  item.editId =
                    that.keyChildEditVOList[index].productKeyId || ''
                }
              })
            }
            if (this.labelList.length) {
              this.tableData = this.labelList
            }
          }
        } else {
          //如果有关联标签，那也只能是一个主标签，根据其他关联标签（关联标签可能多个）
          //查找当前标签下面关联的其他标签
          let linkedParentIdsArr = []
          response.data.forEach((item, index) => {
            if (item.linkedParentIds) {
              linkedParentIdsArr.push(...item.linkedParentIds.split(','))
            }
          })

          let allRelateArr = [] //所有关联标签
          linkedParentIdsArr.forEach((item, index) => {
            allRelateArr.push(
              new Promise((resolve, reject) => {
                ProductListInteractor.getDetailForEditApi({
                  keyParentId: item,
                }).then((response) => {
                  resolve(response.data)
                })
              })
            )
          })

          //主标签定义main=0，主标签里面的关联标签定义main=1
          Promise.all(allRelateArr).then((result, index) => {
            this.tableData2 = result
            this.tableData2.forEach((item, index) => {
              item.keyChildEditVOList.forEach((item2, index2) => {
                item2.disabled = true
              })
              item.isMain = '0'
            })
            initData.forEach((item, index) => {
              item.keyChildEditVOList.forEach((item2, index2) => {
                item2.disabled = false
              })
              item.isMain = '1'
            })

            let mergeData = initData.concat(this.tableData2)
            this.tableData = mergeData.map((item) => {
              if (item.multiple == '1') {
                return {
                  ...item,
                  value: [],
                }
              } else {
                return {
                  ...item,
                  value: '',
                }
              }
            })
            //编辑回显
            if (that.keyChildEditVOList.length) {
              this.tableData.forEach((item, index) => {
                if (
                  item.keyParentId == that.keyChildEditVOList[index].parentKeyId
                ) {
                  item.value =
                    item.multiple === '1'
                      ? that.keyChildEditVOList[index].childKeyIds.split(',')
                      : that.keyChildEditVOList[index].childKeyIds
                  item.productId =
                    that.keyChildEditVOList[index].productId || ''
                  item.editId =
                    that.keyChildEditVOList[index].productKeyId || ''
                }
                item.keyChildEditVOList.forEach((item2) => {
                  item2.disabled = true
                })
              })
            }
            if (this.labelList.length) {
              this.tableData = this.labelList
            }
          })
        }
      },
      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
      },
      // 保存
      save() {
        let flag = true
        for (let i in this.tableData) {
          const t = this.tableData[i]
          if (t.multiple === '1' && (t.value.length === 0 || t.value === '')) {
            flag = false
            return this.$baseMessage(
              `请勾选${t.nameCn}字段`,
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
        }

        if (flag) {
          this.labelList = deepCopy(this.tableData)
          //还要再判断有没有数据
          let productKeyEditDTOList = []
          this.tableData.forEach((item) => {
            let obj = {
              childKeyIds: Array.isArray(item.value)
                ? item.value.join(',')
                : item.value.toString(),
              parentKeyId: item.keyParentId,
              productKeyEditId: item.editId || '',
              productId: item.productId || '',
            }
            productKeyEditDTOList.push(obj)
          })
          this.$emit('productKeyEditDTOList', productKeyEditDTOList)
          this.close()
        }
      },
      // 关闭
      close() {
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-checkbox__input.is-disabled + span.el-checkbox__label,
    .el-radio__input.is-disabled + span.el-radio__label {
      color: #666 !important;
    }
    .el-radio {
      line-height: 32px;
    }
  }
</style>
