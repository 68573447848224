<template>
  <div class="proSetting-container">
    <!-- 上 -->
    <el-row :gutter="10">
      <!-- 产品类型 -->
      <el-col :span="6">
        <el-row class="public-col public-col2-1">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">产品类型</p>
          </el-row>
          <el-table
            class="mt15"
            :data="proTypeData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="nameCn"
              align="center"
              label="名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameCn)">
                  {{ scope.row.nameCn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label="是否需要审核"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.value === '1' ? '是' : '否' || '--' }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- 价格规则 -->
        <el-row class="public-col public-col2-2">
          <p class="f_s_16">价格规则</p>
          <el-row class="mt10">
            List Price = Net Price /
            <span
              v-if="proPriceRule && proPriceRule[0] && proPriceRule[0].value"
            >
              {{ proPriceRule[0].value }}
            </span>
            <span v-else>0.6</span>
            <span class="ml10 page-link-noline" @click="editPriceRuleClick">
              修改
            </span>
          </el-row>
        </el-row>
      </el-col>
      <!-- 单位 -->
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">单位</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="publicModelClick('unit')"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt15"
            :data="proUnitData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="nameCn"
              align="center"
              label="中文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameCn)">
                  {{ scope.row.nameCn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nameEn"
              align="center"
              label="英文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameEn)">
                  {{ scope.row.nameEn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="120"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="publicModelClick('unit', scope.row)"
                  ></el-button>
                </el-tooltip>

                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="publicModelDeleteClick(6, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>

      <!-- 规格 -->
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">规格</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="specClick()"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt15"
            :data="proSpecData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="nameCn"
              align="center"
              label="规格名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameCn)">
                  {{ scope.row.nameCn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label="规格值"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.value)">
                  {{ scope.row.value || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="120"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="specClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="publicModelDeleteClick(1, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
      <!-- 包装方式 -->
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">包装方式</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="publicModelClick('packing')"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt15"
            :data="proPackingData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="nameCn"
              align="center"
              label="中文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameCn)">
                  {{ scope.row.nameCn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nameEn"
              align="center"
              label="英文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameEn)">
                  {{ scope.row.nameEn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="publicModelClick('packing', scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="publicModelDeleteClick(3, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>

    <!-- 下 -->
    <el-row :gutter="20" class="row-bottom">
      <!-- 印刷位 -->
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">印刷位</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="publicModelClick('print')"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt15"
            :data="proPrintData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="nameCn"
              align="center"
              label="中文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameCn)">
                  {{ scope.row.nameCn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nameEn"
              align="center"
              label="英文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameEn)">
                  {{ scope.row.nameEn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="120"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="publicModelClick('print', scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="publicModelDeleteClick(5, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
      <!-- 计价方式 -->
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">计价方式</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="priceWayClick()"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt15"
            :data="proValuationData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="nameCn"
              align="center"
              label="印刷条件"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameCn)">
                  {{ scope.row.nameCn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              align="center"
              label="计价方式"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.value)">
                  {{ scope.row.value || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120">
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="priceWayClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="publicModelDeleteClick(2, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>

      <!-- 产线设置9 -->
      <el-col :span="12">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">产线设置</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="proLineClick()"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt15"
            :data="proLine"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="nameCn"
              align="center"
              label="中文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameCn)">
                  {{ scope.row.nameCn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nameEn"
              align="center"
              label="英文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameEn)">
                  {{ scope.row.nameEn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="belongerName"
              align="center"
              label="产线运营"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer">
                  {{ scope.row.belongerName || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="memberNames"
              align="center"
              label="成员"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer">
                  {{ scope.row.memberNames || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="buyerName"
              align="center"
              label="采购开发"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer">
                  {{ scope.row.buyerName || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="category"
              align="center"
              label="关联产品类别"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ scope.row.category || '--' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="强制产线协助"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.compulsoryAssistance == 1 ? '是' : '否' || '--'
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="上架平台"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>
                  {{ scope.row.platformName || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="proLineClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="publicModelDeleteClick(9, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>

    <!-- 下 -->
    <el-row :gutter="20" class="row-bottom">
      <!-- 运输周期 & 运费计算规则 -->
      <!-- <el-col :span="6">
        <DeliveryPeriodModel />
      </el-col> -->
      <!-- 利润率模板 -->
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="f_s_16">利润率模板</p>
            <el-tooltip content="新增" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="profitModelClick()"
              ></el-button>
            </el-tooltip>
          </el-row>
          <el-table
            class="mt15"
            :data="profitData"
            border
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              prop="nameCn"
              align="center"
              label="模板名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameCn)">
                  {{ scope.row.nameCn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nameEn"
              align="center"
              label="英文名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="c_pointer" @click="celldblclick(scope.row.nameEn)">
                  {{ scope.row.nameEn || '--' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="120"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="profitModelClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    circle
                    size="mini"
                    @click="publicModelDeleteClick(10, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>

    <!-- 价格规则 -->
    <PriceRuleModel ref="PriceRuleModel" @price-rule="publicRrefresh" />

    <!-- 产品类型设置 -->
    <ProductTypeModel ref="ProductTypeModel" @product-type="publicRrefresh" />

    <!-- 规格设置 -->
    <SpecModel ref="SpecModel" @spec="publicRrefresh" />

    <!-- 计价设置 -->
    <PriceWayModel ref="PriceWayModel" @price-way="publicRrefresh" />

    <!-- 产线设置 -->
    <ProductLineModel ref="ProductLineModel" @line-way="publicRrefresh" />
    <!-- 利润模版设置 -->
    <ProfitModel ref="ProfitModel" @public-model="publicRrefresh" />

    <!-- 公共类型设置(单位,材质) -->
    <PublicModel ref="PublicModel" @public-model="publicRrefresh" />
  </div>
</template>

<script>
  import {
    listProductSetting,
    getDetailByType,
    deleteById,
  } from '@/api/product/productSetting'

  import PriceRuleModel from './components/price-rule-model'
  import ProductTypeModel from './components/product-type-model'
  import SpecModel from './components/spec-model'
  import PriceWayModel from './components/price-way-model'
  import PublicModel from './components/public-model'
  import ProductLineModel from './components/product-line-model'
  import ProfitModel from './components/profit-model'
  import DeliveryPeriodModel from './components/delivery-period-model'
  import { platformDs } from '@/utils/dicts'
  export default {
    name: 'ProductSetting',
    props: {},
    data() {
      return {
        proSpecData: [], //规格数据1
        proValuationData: [], //计价方式2
        proPackingData: [], //包装方式3
        //proMaterialData: [], //产品材质4
        proPrintData: [], //印刷位5
        proUnitData: [], //单位6
        proTypeData: [], //产品类型7
        proPriceRule: [], //价格规则8
        proLine: [], //产线9
        proData: [],
        tableHeight: '100% - 45',
        profitData: [], //利润模版10
      }
    },
    components: {
      PriceRuleModel,
      ProductTypeModel,
      SpecModel,
      PriceWayModel,
      PublicModel,
      ProductLineModel,
      ProfitModel,
      DeliveryPeriodModel,
    },
    created() {},
    mounted() {
      this.fetchData()
    },
    methods: {
      //设置列表
      async fetchData() {
        this.proSpecData = [] //规格数据1
        this.proValuationData = [] //计价方式2
        this.proPackingData = [] //包装方式3
        //this.proMaterialData = [] //产品材质4
        this.proPrintData = [] //印刷位5
        this.proUnitData = [] //单位6
        this.proTypeData = [] //产品类型7
        this.proPriceRule = [] //价格规则8
        this.profitData = [] //利润模版10
        let response = await listProductSetting()
        if (response.code === '000000') {
          this.proData = response.data
          this.proData.forEach((item) => {
            if (item.type === 1) {
              this.proSpecData.push(item)
            } else if (item.type === 2) {
              this.proValuationData.push(item)
            } else if (item.type === 3) {
              this.proPackingData.push(item)
            } else if (item.type === 5) {
              this.proPrintData.push(item)
            } else if (item.type === 6) {
              this.proUnitData.push(item)
            } else if (item.type === 7) {
              this.proTypeData.push(item)
            } else if (item.type === 8) {
              this.proPriceRule.push(item)
            } else if (item.type === 9) {
              this.setPlatformName(item)
              this.proLine.push(item)
            } else if (item.type === 10) {
              this.profitData.push(item)
            }
          })
        }
      },

      // 传入一个对象，如果该对象中有platformCode，则根据platformDs字典给改对象设置platformName
      setPlatformName(item) {
        if(item.platformCode) {
          const platformList = platformDs.reduce((finalList, n) => {
            if(item.platformCode.includes(n.value)) {
              finalList.push(n.label)
            }
            return finalList
          }, [])
          item.platformName = platformList.join('; ') // 用英文分号+空格隔开
        }
      },

      //修改价格规则
      editPriceRuleClick() {
        this.$refs['PriceRuleModel'].showAddEdit(this.proPriceRule[0])
      },

      //刷新某一个类型
      async publicRrefresh(typeValue) {
        let response = await getDetailByType({ type: typeValue })
        if (response.code === '000000') {
          let data = response.data
          if (typeValue === 1) {
            this.proSpecData = data
          } else if (typeValue === 2) {
            this.proValuationData = data
          } else if (typeValue === 3) {
            this.proPackingData = data
          } else if (typeValue === 5) {
            this.proPrintData = data
          } else if (typeValue === 6) {
            this.proUnitData = data
          } else if (typeValue === 7) {
            this.proTypeData = data
          } else if (typeValue === 8) {
            this.proPriceRule = data
          } else if (typeValue === 9) {
            for(const item of data) this.setPlatformName(item)
            this.proLine = data
          } else if (typeValue === 10) {
            this.profitData = data
          } else {
            this.fetchData()
          }
          this.$forceUpdate()
        }
      },

      //删除
      publicModelDeleteClick(type, row) {
        this.$confirm('删除不可恢复，确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let response = await deleteById({
            productSettingId: row.productSettingId,
          })
          if (response.code === '000000' && response.data === 1) {
            this.$baseMessage(
              '删除成功',
              'success',
              false,
              'erp-hey-message-success'
            )
            this.publicRrefresh(type)
          } else {
            this.$baseMessage(
              '删除失败',
              'error',
              false,
              'erp-hey-message-error'
            )
          }
        })
      },

      //产品类型设置
      productTypeClick(row) {
        this.$refs['ProductTypeModel'].showAddEdit(row)
      },

      // 规格设置
      specClick(row) {
        this.$refs['SpecModel'].showAddEdit(row)
      },

      //计价设置PriceWayModel
      priceWayClick(row) {
        this.$refs['PriceWayModel'].showAddEdit(row)
      },

      //产线设置proLineClick
      proLineClick(row) {
        this.$refs['ProductLineModel'].showAddEdit(row)
      },

      //公共类型设置
      publicModelClick(str, row) {
        this.$refs['PublicModel'].showAddEdit(str, row)
      },

      // 利润模版设置
      profitModelClick(row) {
        this.$refs['ProfitModel'].showAddEdit(row)
      },

      // 复制
      celldblclick(text) {
        let _this = this
        _this.$copyText(text).then(
          function (e) {
            _this.copyClick = true
            _this.$baseMessage(
              '复制成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          },
          function (e) {
            _this.copyClick = true
            _this.$baseMessage(
              '复制失败，请手动复制',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        )
      },
    },
  }
</script>

<style scoped lang="scss">
  .public-col {
    height: 43vh;
    border: 1px solid #ededed;
    padding: 10px;
    .el-table {
      height: calc(100% - 45px);
      overflow-y: auto;
      margin-bottom: 10px;
    }
    p {
      line-height: 32px;
    }
  }
  .row-bottom {
    margin-top: 2vh;
  }
  .public-col2-1 {
    height: 32vh;
  }
  .public-col2-2 {
    height: 10vh;
    margin-top: 1vh;
  }
</style>
