<!-- 季度业绩取值弹窗 -->
<template>
  <el-dialog
    title="季度业绩取值"
    :append-to-body="true"
    :visible.sync="params.showDialog"
    width="450px"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width=" 100px">
      <el-form-item prop="amountName" label="取值来源">
        <el-select disabled v-model="form.amountName">
          <el-option
            v-for="item in sourceList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="amountShort" label="取值状态">
        <el-select v-model="form.amountShort">
          <el-option label="已通过" value="5" />
          <el-option label="待发货" value="6" />
          <el-option label="待收货" value="8" />
          <el-option label="已收货" value="9" />
          <el-option label="已完成" value="10" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确认</el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
  export default {
    name: 'MonthlyAchievementValue',
    props: {
      params: {
        showDialog: false,
      },
    },
    data() {
      return {
        sourceList: [
          {
            name: '销售订单',
            code: 'ORDER',
          },
        ], //取值来源
        form: {
          amountName: 'ORDER', // 取值类型
          amountShort: '', //取值状态
        },
        rules: {
          amountShort: [
            {
              required: true,
              message: '请选择',
            },
          ],
        },
      }
    },

    mounted() {},

    methods: {
      save() {
        this.$refs.form.validate((flag) => {
          flag && this.$emit('sure', this.form)
        })
      },
      clearValidate() {
        this.$refs.form.clearValidate()
      },
      showAddEdit(row) {
        this.form = { ...row }
        this.params.showDialog = true
      },
      close() {
        this.params.showDialog = false
      },
    },
  }
</script>

<style lang="scss" scoped></style>
