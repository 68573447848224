<template>
  <el-dialog
    title="出口抬头默认收款账户"
    :visible.sync="dialogVisible"
    width="600px"
    @close="close"
    class="dialog-middle"
  >
    <el-form
      :model="form"
      label-width="80px"
      :rules="rules"
      size="small"
      ref="form"
    >
      <el-form-item label="出口抬头" prop="exportsRiseId">
        <el-select
          v-model="form.exportsRiseId"
          placeholder="请选择"
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="item in riseList"
            :key="item.amountId"
            :label="item.amountShort"
            :value="item.amountId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="收款账户" prop="shroffAccount">
        <el-autocomplete
          v-model="form.shroffAccount"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入"
          @select="handleSelect"
          clearable
          style="width: 100%"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="close">取消</el-button>
      <el-button type="primary" size="mini" @click="submit" :loading="loading">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    shroffAccountExportsSave,
    shroffAccountExportsUpdate,
  } from '@/api/finance/finance-setting'
  import { orderGetDict } from '@/api/order'
  import { listShorffAccountPay } from '@/api/finance/credit-note-add'
  export default {
    name: 'riseSetting',
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: { exportsRiseId: '', shroffAccountId: '', shroffAccount: '', id: null },
        rules: {
          exportsRiseId: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          shroffAccount: [{ required: true, message: '请输入', trigger: 'change' }],
        },
        riseList: [], // 出口抬头下拉
      }
    },
    methods: {
      //打开弹框
      showDialog(row) {
        this.dialogVisible = true
        this.getRiseList()
        if (row) {
          this.form = Object.assign({}, row)
        } else {
          this.form = { exportsRiseId: '', shroffAccountId: '', shroffAccount: '', id: null }
        }
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
      //清空表单
      close() {
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const { id, exportsRiseId } = this.form
            const Method = id
              ? shroffAccountExportsUpdate
              : shroffAccountExportsSave
            this.loading = true
            const exportsRise = this.riseList.find(
              (n) => n.amountId === exportsRiseId
            ).amountShort
            const params = { ...this.form, exportsRise }
            Method(params)
              .then((res) => {
                this.loading = false
                if (res.code == '000000') {
                  this.$message.success(id ? '修改成功！' : '新增成功！')
                  this.$emit('success')
                  this.close()
                }
              })
              .catch((err) => {
                this.loading = false
              })
          }
        })
      },
      async getRiseList() {
        try {
          const { code, data } = await orderGetDict({ amountType: 1 })
          if (code === '000000' && Array.isArray(data)) {
            this.riseList = data.filter((n) => n.amountStatus == 1)
          }
        } catch (e) {
          console.log(e)
        }
      },
      // 收款账户change
      handleSelect(item) {
        this.form.shroffAccountId = item.shroffAccountId
        this.form.shroffAccount =
          item.payee + '/ ' + item.bankDeposit + ' /' + item.bankAccount
      },
      // 收款账户下拉
      querySearchAsync(cusName, cb) {
        listShorffAccountPay({ cusName, shroffType: 3 }).then((res) => {
          const results = res.data.map((item) => {
            return {
              ...item,
              value:
                item.payee + '/' + item.bankDeposit + '/' + item.bankAccount,
            }
          })
          cb && cb(results)
        })
      },
    },
  }
</script>
