<!--
  功能：请选择模板组件-人员
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年08月22日 10:24:31
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <el-dialog
    title="请选择模板"
    :visible.sync="dialogFormVisible"
    width="900px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="table-wrapper">
      <el-table :data="tableData" border stripe height="500">
        <el-table-column label="选择" align="center">
          <template slot-scope="scope">
            <el-radio v-model="selTpl" :label="scope.row">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          label="员工姓名"
          align="center"
          prop="personnoelCn"
        ></el-table-column>
        <el-table-column
          label="员工条件运营成本(%)"
          align="center"
          prop="ratio"
        ></el-table-column>

        <el-table-column
          label="运营成本配置编号"
          align="center"
          show-overflow-tooltip
          prop="configOperateNumber"
        ></el-table-column>
        <el-table-column
          label="产品类型"
          align="center"
          prop="standardCustomized"
        ></el-table-column>
        <el-table-column label="毛利额" align="center" prop="conditionType">
          <template slot-scope="scope">
            <span>大于0</span>
          </template>
        </el-table-column>
        <el-table-column
          label="运营成本(%)"
          align="center"
          prop="costRatio"
        ></el-table-column>
      </el-table>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="cancel">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'OperatingCostConfigUserSel',
    props: {},
    components: {},
    // 组件状态值
    data() {
      return {
        tableData: [], // 模板列表数据
        dialogFormVisible: false, // 弹窗是否可见
        selTpl: '', // 选择的模板
      }
    },
    methods: {
      // 关闭
      close() {
        this.selTpl = ''
        this._toggle(false)
      },
      // 确认
      save() {
        if (!this.selTpl) {
          return this.$message.warning('请选择模板')
        } else {
          this.$emit('user-sel', this.selTpl)
          this._toggle(false)
        }
      },
      //取消
      cancel() {
        this._toggle(false)
      },
      // 模板选择
      showUserSel({ tableData }) {
        this.tableData = tableData
        this._toggle(true)
      },
      // 弹窗可见切换
      _toggle(flag) {
        this.dialogFormVisible = flag
      },
    },
  }
</script>

<style scoped></style>
