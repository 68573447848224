<template>
  <div class="custom-container" v-if="$route.meta.showParent">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="22" class="page-header-left">
        <el-row type="flex" class="top-search">
          <el-button @click="add" class="mr10" type="primary">
            <i class="el-icon-plus"></i>
            新增
          </el-button>
          <div>
            <el-form
              ref="form"
              :inline="true"
              :model="queryForm"
              label-width="49px"
              @submit.native.prevent
            >
              <el-form-item>
                <el-input
                  style="width: 300px"
                  v-model="queryForm.transferOrderNumber"
                  placeholder="调拨单号"
                  clearable
                  @keyup.enter.native="init"
                />
              </el-form-item>
            </el-form>
          </div>
          <el-button @click="init">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            搜索
          </el-button>
          <ListHigherSearch class="ml10" @highSearch="highSearch" />
          <el-button
            class="ml10"
            type="default"
            @click="exportExcel"
            plain
            style="height: 34px"
          >
            导出
          </el-button>
        </el-row>
      </el-col>
      <el-col :span="2">
        <el-row type="flex" justify="end">
          <!-- 自定义展示列 -->
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="item + index"
                v-model="item.isShow"
                :disabled="item.checkable === false"
                :label="item.label"
              >
                {{ item.label }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button
                type="primary"
                class="ml10"
                size="mini"
                plain
                @click="operateSave()"
              >
                保存
              </el-button>
            </div>
            <template #reference>
              <el-tooltip
                effect="dark"
                content="列表可拖动排序"
                placement="top-start"
              >
                <el-button plain type="primary">自定义显示</el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>

    <el-radio-group
      class="mt15 mb15"
      v-model="queryForm.transferOrderStatus"
      @change="init"
    >
      <el-radio
        v-for="(item, i) in orderStatusList"
        :key="i"
        :label="item.value"
      >
        {{ item.label }}
      </el-radio>
    </el-radio-group>
    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :total="total"
      :extraHeight="30"
      class="table-fixed"
      @query="getList"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
      >
        <template #default="{ row }">
          <!-- 出库方式 -->
          <div
            class="blue-text"
            v-if="item.prop === 'transferOrderNumber'"
            @click="goDetail(row.transferOrderId)"
          >
            {{ row.transferOrderNumber }}
          </div>
          <!-- 出库方式 -->
          <div v-else-if="item.prop === 'warehouseOutType'">
            {{ row.warehouseOutType | warehouseOutTypeName }}
          </div>
          <!-- 交货方式 -->
          <div v-else-if="item.prop === 'deliveryMode'">
            {{ row.deliveryMode | deliveryModeName }}
          </div>
          <!-- 状态 -->
          <div v-else-if="item.prop === 'transferOrderStatus'">
            <el-tooltip
              placement="top"
              effect="light"
              v-if="[3].includes(row.transferOrderStatus)"
            >
              <div slot="content">
                <p>
                  {{ '驳回原因：' + (row.reasonRejection || '--') }}
                </p>
                <p>
                  {{ '驳回时间：' + (row.rejectionDate || '--') }}
                </p>
              </div>
              <el-tag type="danger" class="c_pointer">已驳回</el-tag>
            </el-tooltip>
            <el-tag
              v-else
              :type="transferOrderStatusType(row.transferOrderStatus)"
            >
              <span
                v-if="row.transferOrderStatus === 1"
                class="c_pointer"
                @click="statusTypeClick(row)"
              >
                审核中
              </span>
              <span v-else>
                {{ row.transferOrderStatus | transferOrderStatusName }}
              </span>
            </el-tag>
          </div>
          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        width="120"
        fixed="right"
        :resizable="false"
      >
        <template slot-scope="scope">
          <el-tooltip content="查看详情" placement="top">
            <el-button
              @click="goDetail(scope.row.transferOrderId)"
              type="primary"
              class="mr10"
              plain
              size="mini"
              icon="iconfont icon-icon--copy"
              circle
            ></el-button>
          </el-tooltip>

          <el-dropdown>
            <el-button
              type="primary"
              plain
              icon="el-icon-more"
              circle
              style="padding: 8px"
            ></el-button>

            <el-dropdown-menu slot="dropdown" placement="bottom">
              <el-dropdown-item
                v-show="[1].includes(scope.row.transferOrderStatus)"
              >
                <p @click="revocation(scope.row)">撤回</p>
              </el-dropdown-item>

              <el-dropdown-item
                v-show="[3, 4].includes(scope.row.transferOrderStatus)"
              >
                <p @click="edit(scope.row.transferOrderId)">编辑</p>
              </el-dropdown-item>

              <el-dropdown-item
                v-show="[3, 4].includes(scope.row.transferOrderStatus)"
              >
                <p @click="cancelAllot(scope.row.transferOrderId)">取消调拨</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </ErpTable>

    <!-- 审核进度Drawer -->
    <el-drawer
      title="审核进度"
      :visible.sync="statusDrawer"
      :before-close="statusClose"
    >
      <Audit
        ref="Audit"
        :auditType="20"
        :auditObjectId="auditObjectId"
        @cancelClick="statusClose"
      />
    </el-drawer>
    <!-- 导出任务创建成功提示 -->
    <SuccessModel :visible.sync="downloadVisible" />
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import dayjs from 'dayjs'

  import { InboundSheet, StoreCheck } from '@/core'
  import AllotInquireInteractor from '@/core/interactors/stock-center/allot-inquire'

  import CustomCache from '@/utils/custom-cache'
  import Dicts from './dict/index'
  import ErpDraggable from 'vuedraggable'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import ListHigherSearch from './components/ListHigherSearch.vue'
  import SuccessModel from '@/components/tableImport/successModel'

  export default {
    name: 'inquiryDemand',
    components: {
      ErpDraggable,
      ListHigherSearch,
      Audit,
      SuccessModel,
    },
    data() {
      return {
        queryForm: {
          transferOrderStatus: '',
        },
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        auditObjectId: '', // 审核id
        userCustomizeColumnId: '', // 自义定保存id
        tableData: [],
        selectRows: [], //table勾选
        statusDrawer: false,
        columns: Dicts.columns,
        typeNames: Dicts.typeNames, // 出库方式
        deliveryModes: Dicts.deliveryModes, // 交货方式
        orderStatusList: Dicts.statusList, // 状态
        downloadVisible: false, // 导出成功提示框
      }
    },
    computed: {
      ...mapGetters({
        permissions: 'user/permissions',
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    beforeRouteLeave(to, from, next) {
      if (to?.path !== '/stock-center/allot-inquire/detail') {
        localStorage.removeItem('ALLOT_INQUIRE_HIGH_FORM')
      }
      next()
    },
    filters: {
      transferOrderStatusName(n) {
        return Dicts.transferOrderStatusName(n)
      },
      deliveryModeName(n) {
        return Dicts.deliveryModeName(n)
      },
      warehouseOutTypeName(n) {
        return Dicts.warehouseOutTypeName(n)
      },
    },
    created() {
      this.getList()
      this.getColumsData()
    },
    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.getList()
        }
      },
    },
    methods: {
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(17, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },

      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 17,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 初始加载
      init() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.getList()
      },

      // 获取列表
      async getList() {
        const res = await AllotInquireInteractor.listTransferOrderPage({
          ...this.queryForm,
          ...this.page,
        })

        if (res && res.code == '000000') {
          const data = res.data
          this.tableData = data.data ? data.data : []
          this.total = data.total ? data.total : 0
        }
        this.selectRows = []
      },

      // 表格勾选
      setSelectRows(rows) {
        this.selectRows = rows
      },

      // 撤回
      async revocation({ transferOrderId: id, warehouseInId, warehouseOutId }) {
        try {
          const warehouseId = [warehouseInId, warehouseOutId].join()
          const { code } = await StoreCheck.auditCheckWarehousrLockApi(warehouseId)
          if(code === '000000') {
            this.$confirm(`是否确定撤销审核？`, '提示', { type: 'warning' }).then(
              async () => {
                const res = await InboundSheet.getAuditObject({
                  orderId: id,
                  auditType: 20,
                  tenantId: this.userInfo.tenantId,
                })
                if (res && res.code === '000000') {
                  // 获取提交审核（撤销所用）auditObjectId
                  const auditObjectId = res.data.auditObjectId
                  const auditStatus = 4
                  const auditType = 20
                  const { tenantId, userId, userName } = this.userInfo
                  const res_1 = await InboundSheet.cancel({
                    auditObjectId,
                    auditStatus,
                    auditType,
                    tenantId,
                    userId,
                    userName,
                    remarks: '',
                  })

                  if (res_1.code === '000000') {
                    this.$message.success('操作成功')
                    this.getList()
                  }
                }
              }
            )
          }
        } catch (e) {
          console.log(e)
        }
      },

      // 新增
      add() {
        this.$router.push('/stock-center/allot-inquire/add')
      },

      // 编辑
      edit(id) {
        this.$router.push(`/stock-center/allot-inquire/add?id=${id}`)
      },

      // 详情
      goDetail(id) {
        this.$router.push(`/stock-center/allot-inquire/detail?id=${id}`)
      },

      // 取消调拨
      cancelAllot(id) {
        this.$confirm(
          `是否确认取消调拨？取消后库存解锁，可进行出库入库操作。`,
          '提示',
          { type: 'warning' }
        ).then(async () => {
          const res = await AllotInquireInteractor.canceledTransferOrder(id)

          if (res.code === '000000') {
            this.$message.success('取消调拨成功')
            this.getList()
          }
        })
      },

      // 高级筛选
      highSearch(form) {
        this.queryForm = {
          ...this.queryForm,
          ...form,
        }

        this.pageNo = 1
        this.getList()
      },

      // 导出
      async exportExcel() {
        if (!this.selectRows.length) {
          this.$message.warning('请选择一条数据！')
          return false
        }

        let _ids = []

        this.selectRows.forEach((item) => _ids.push(item.transferOrderId))

        const res = await AllotInquireInteractor.exportCompressedPackage(_ids)
        if (res && res.code === '000000') {
          this.downloadVisible = true
        }
      },

      // 审核流程
      statusTypeClick(row) {
        this.auditObjectId = row.transferOrderId
        this.statusDrawer = true
      },

      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      // 状态标签转义
      transferOrderStatusType(n) {
        if ([1, 4].includes(n)) {
          return 'warning'
        }
        if ([5].includes(n)) {
          return 'success'
        }
        if ([3].includes(n)) {
          return 'danger'
        }
        return ''
      },
    },
  }
</script>

<style lang="scss" scoped></style>
