<template>
  <div>
    <el-radio-group v-model="tabValue">
      <el-radio-button
        v-for="item in tabList"
        :key="item.value"
        :label="item.value"
      >
        {{ item.label }}
      </el-radio-button>
    </el-radio-group>
    <div class="ctn">
      <component :is="isComponent"></component>
    </div>
  </div>
</template>

<script>
import personConfig from './person-config.vue'
import templateConfig from './template-config.vue'
import personConfigOper from './person-config-oper.vue'
export default {
  name: 'OperatingCostConfig',
  components: {
    personConfig,
    templateConfig,
    personConfigOper,
  },
  computed: {
    isComponent() {
      return this.tabValue === 'person'
        ? personConfig
        : this.tabValue === 'template'
        ? templateConfig
        : ''
    },
  },
  created() {
    this.tabValue = this.$route.query.configTab
      ? this.$route.query.configTab
      : 'template'
  },
  data() {
    return {
      tabList: [
        {
          value: 'template',
          label: '模板配置',
        },
        {
          value: 'person',
          label: '人员查询',
        },
      ],
      tabValue: 'person',
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.ctn {
  margin-top: 20px;
  width: 100%;
  min-height: 100vh;
}
</style>
