import {
  getDecalrById,
  setDeclarePrice,
  downloadProImgZip,
  checkDownloadZip,
  productPlatform,
  operProductPlatform,
  productPlatformList,
  platList,
  priceUpdate,
  priceRemark,
} from '@/core/services/api/product/productView'

class productViewInteractor {
  static getInstance() {
    return this._instance
  }

  // 根据id 查询详情
  async getDeclarMsg(id) {
    try {
      const res = await getDecalrById({ productId: id }).catch((err) => {
        throw new Error(err)
      })

      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 更新
  async updateDeclarePrice(data) {
    try {
      const res = await setDeclarePrice(data).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  // 下载图片
  async downloadImgZip(productId) {
    return await downloadProImgZip({ productId })
  }

  async checkDownloadZip(productId) {
    return await checkDownloadZip({ productId })
  }

  // 获取平台信息

  async getProductPlatform(productId) {
    return await productPlatform({ productId })
  }
  // 上下架

  async setProductPlatform(data) {
    let { productId, platformCodes, type, removeReason } = data
    return await operProductPlatform({
      productId,
      platformCodes,
      type,
      removeReason,
    })
  }
  // 平台记录
  async getProductPlatformList(data) {
    let { type, creatorId, time, pageNo, pageLe, productId, platform } = data
    let startTime = time ? time[0] : ''
    let endTime = time ? time[1] : ''
    platform = platform ? platform.join(',') : ''
    return await productPlatformList({
      type,
      creatorId,
      startTime,
      endTime,
      pageNo,
      pageLe,
      productId,
      platform,
    })
  }
  // 平台列表
  async getPlatList() {
    return await platList()
  }

  // 产品报价备注新增/修改
  priceUpdateApi({ productId, priceRemark }) {
    return priceUpdate({ productId, priceRemark })
  }

  // 产品报价备注查询
  priceRemarkApi(productId) {
    return priceRemark(productId)
  }
}

productViewInteractor._instance = new productViewInteractor()

export const ProductViewInteractor = productViewInteractor.getInstance()
