<template>
  <div class="pro-pic" ref="propic">
    <el-row class="pro-content" :gutter="40" :style="{ height: `${maxHeight}px` }" v-if="this.productData.length">
      <el-col :span="5" v-for="(item, index) of productData" :key="index">
        <el-card shadow="hover" :body-style="{ padding: '0px' }" class="pro_card" style="height: 350px">
          <el-row class="card-content">
            <div class="card-img" @mouseenter="mouseenterHover(index)" @mouseleave="mouseleaveHover">
              <el-image v-if="item.imgMain" :src="item.imgMain" fit="fill"></el-image>
              <el-image v-else>
                <div slot="error" class="image-slot">
                  <img :src="slogoPng" class="slogoPng" />
                </div>
              </el-image>
              <el-row class="card-operate" v-if="index === shadeIndex">
                <!-- 查看 -->
                <el-button @click="productView(item.productId)">
                  {{ $t('productCenter.Check') }}
                </el-button>

                <el-row v-if="
                    userInfo.userId === item.belongerId ||
                    item.editProduct === '1'
                  ">
                  <el-button v-allowed="['Product:Operate']" v-show="item.auditProcess !== 1"
                    @click="goToPage('edit', item.productId)" class="ml0 mt10">
                    <!-- 编辑 -->
                    {{ $t('productCenter.Edit') }}
                  </el-button>
                  <el-button v-allowed="['Product:Operate']" @click="revokeClick(item.productId)" class="ml0 mt10"
                    v-show="item.auditProcess === 1">
                    <!-- 撤销 -->
                    {{ $t('productCenter.Revoke') }}
                  </el-button>
                </el-row>
              </el-row>
              <!-- 审核状态 -->
              <el-tag class="audit-type" :type="item.auditProcess | statusFilter" @click="statusTypeClick(item)">
                {{ auditStatus(item.auditProcess) }}
              </el-tag>
            </div>
            <el-row class="card-msg">
              <el-row class="pro-type">
                <el-checkbox @change="picCheckClick(item.productId, item)" />
                <span class="ml10">
                  {{
                  settings === 'zh' ? item.category : item.categoryEn || '--'
                  }}
                </span>
              </el-row>
              <el-row class="pro-nameEn f_s_12 mt5">{{ item.nameEn }}</el-row>
              <el-row class="pro-nameCn f_w_7 f_s_15">{{ item.nameCn }}</el-row>
              <el-row class="mt5 c-666 f_s_12" v-if="item.mainSupplierName">
                <!-- 主要供应商 -->
                {{ $t('productCenter.MajorSuppliers') }}：{{
                item.mainSupplierName || '--'
                }}
              </el-row>
              <el-row type="flex" align="middle" justify="space-between" class="mt5">
                <p class="c-666">{{ item.productCode }}</p>
                <p class="f_s_12">
                  <!-- 低至 -->
                  {{ $t('productCenter.AsLowAs') }}
                  <span class="c-danger f_s_16">
                    ￥{{ toFixed3(item.netPrice) || 0 }}
                  </span>
                </p>
              </el-row>
            </el-row>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-else class="pro-content no-pro flex_xc_yc" :gutter="40" :style="{ height: `${maxHeight}px` }">
      <el-image :src="require('@/assets/empty_images/data_empty.png')" class="erp-data-empty" style="width: 30%" />
    </el-row>
    <el-pagination v-if="paginations" class="pagination" :current-page="pageQuery.pageNo"
      layout="total, sizes,  prev, pager, next, jumper" :page-size="pageQuery.pageLe"
      :page-sizes="[10, 20, 30, 40, 50, 100, 200]" :total="total" background @current-change="handleCurrentChange"
      @size-change="handleSizeChange" />

    <!-- 撤销弹窗 -->
    <el-dialog :title="$t('productCenter.ReasonForRevocation')" :visible="cancelVisible" append-to-body width="500px"
      @close="onCancel" modal-append-to-body>
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input type="textarea" :rows="3" :placeholder="$t('placeholder.plsRevocation')" maxlength="500" show-word-limit
        v-model="cancelReason"></el-input>
      <div class="footer mt30 t_a_c">
        <!-- 确 认 -->
        <el-button type="primary" @click="cancelSure">
          {{ $t('productCenter.Confirm') }}
        </el-button>
        <!-- 取 消 -->
        <el-button @click="onCancel">
          {{ $t('productCenter.Cancel') }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 审核进度Drawer -->
    <el-drawer :title="$t('productCenter.AuditProgress')" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" :auditType="auditType" @cancelClick="statusClose" />
    </el-drawer>
  </div>
</template>
<script>
  import { auditStatus } from '@/utils/en-match-zh'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import { getAuditObject, cancel } from '@/api/stock-center/inbound-sheet'
  import { mapGetters } from 'vuex'
  import slogoPng from '../../../../assets/logoLeft.png'
  export default {
    name: 'ProductPic',
    components: { Audit },
    props: {
      passProData: {
        type: Array,
        required: true,
        default: [],
      },
      passTotal: {
        type: Number,
        required: true,
        default: 0,
      },
      processList: {
        type: Array,
        default: () => [],
      },
      pageInfo: {
        type: Object,
        default: () => { },
      },
    },
    data() {
      return {
        slogoPng,
        maxHeight: 0,
        productData: [],
        shadeIndex: -1,
        total: 0,
        pageQuery: {
          pageNo: 1,
          pageLe: 10,
          title: '',
        },
        paginations: false,
        // statusMap: {
        //   1: 'info',
        //   2: 'success',
        //   3: 'danger',
        //   4: 'info',
        // },
        // statusName: {
        //   1: '审核中',
        //   2: '审核通过',
        //   3: '审核驳回',
        //   4: '已撤销',
        // },
        picCheckData: [],
        auditObjectId: '',
        auditType: 6,
        statusDrawer: false,
        cancelVisible: false,
        cancelReason: '', //撤销原因
        selectRows: [],
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        settings: 'settings/language',
      }),
    },
    // 状态过滤
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: 'info',
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'info',
          5: 'success',
          6: 'danger',
        }
        return statusMap[status]
      },
    },
    watch: {
      passProData: {
        immediate: true,
        deep: true,
        handler: function (val) {
          if (!val) return
          this.productData = val
          this.total = this.passTotal
          if (this.total > 0) {
            this.paginations = true
          } else {
            this.paginations = false
          }
          this.maxHeight = 0
          this.initTableStyle()
        },
      },
      pageInfo: {
        immediate: true,
        deep: true,
        handler: function (val) {
          if (!val) return
          this.pageQuery.pageNo = val.pageNo
          this.pageQuery.pageLe = val.pageLe
        },
      },
    },
    created() { },
    mounted() { },
    methods: {
      //查看
      productView(id) {
        this.$emit('productView', id)
      },
      //编辑
      goToPage(str, id) {
        this.$emit('goToPage', str, id)
      },
      //删除
      proDel(str, id) {
        this.$emit('proDel', str, id)
      },
      initTableStyle() {
        this.$nextTick(() => {
          const baseTableEle = this.$refs['propic']
          // 有分页则减去分页器的高度
          const maxHeight = this.paginations
            ? baseTableEle?.offsetHeight - 58
            : baseTableEle?.offsetHeight
          this.maxHeight = maxHeight
        })
      },

      //审核进度查看
      statusTypeClick(row) {
        this.auditObjectId = row.productId
        this.statusDrawer = true
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },
      //撤销
      revokeClick(productId) {
        getAuditObject({
          orderId: productId,
          auditType: 6,
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            // 获取提交审核（撤销所用）auditObjectId
            this.auditObjectId = res.data.auditObjectId
            this.cancelVisible = true
          }
        })
      },
      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },
      //确定撤销
      cancelSure() {
        if (!this.cancelReason) {
          // 请输入撤销原因
          return this.$message.warning(this.$t('reqmsg.plsRevocation'))
        }
        const {
          auditObjectId,
          auditStatus = 4,
          auditType = 6,
          cancelReason,
        } = this
        const { tenantId, userId, userName } = this.userInfo
        cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        }).then((res) => {
          if (res.code === '000000') {
            // 撤销成功
            this.$message.success(this.$t('reqmsg.M2012'))
            this.cancelVisible = false
            this.query()
          }
        })
      },
      // 商品勾选
      picCheckClick(id, item) {
        this.selectRows.push(item)
        const indexOfjudge = this.picCheckData.indexOf(id)
        if (indexOfjudge === -1) {
          this.picCheckData.push(id)
        } else {
          this.picCheckData.splice(indexOfjudge, 1)
        }

        let checkItme = []
        this.selectRows.forEach((item, index) => {
          this.picCheckData.forEach((item2) => {
            if (item.productId === item2) {
              checkItme.push(item)
            }
          })
        })

        let nArr = checkItme.filter((element, index, self) => {
          return (
            self.findIndex((x) => x.productId === element.productId) === index
          )
        })

        this.$emit('picOperate', nArr)
      },
      // mouseenterHover
      mouseenterHover(index) {
        this.shadeIndex = index
      },
      mouseleaveHover() {
        this.shadeIndex = -1
      },
      handleSizeChange(val) {
        this.pageQuery.pageLe = val
        this.$emit('query', this.pageQuery.pageLe, this.pageQuery.pageNo)
      },
      handleCurrentChange(val) {
        this.pageQuery.pageNo = val
        this.$emit('query', this.pageQuery.pageLe, this.pageQuery.pageNo)
      },
      //审核状态匹配
      auditStatus(val) {
        // return auditStatus(val)
        let obj = this.processList.find((item) => {
          return item.value == val
        })
        return obj.label
      },
      //格式化价格
      dateLong2String(value) {
        // 截取当前数据到小数点后三位
        let transformVal = Number(value).toFixed(4)
        let realVal = transformVal.substring(0, transformVal.length - 1) // num.toFixed(3)获取的是字符串
        return Number(realVal)
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-col-5 {
    width: 20% !important;
  }

  .pro-pic {
    height: $base-table-height;

    .pro-content {
      overflow-y: auto;

      .card-content {
        .card-img {
          position: relative;
          width: 100%;
          height: 209px;
          overflow: hidden;
          border-radius: 3px 3px 0 0;

          .el-image {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              transition: all 0.5s;
            }
          }

          .card-operate {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .audit-type {
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0 3px 0 0;
          }
        }

        .card-img:hover {
          .el-image {
            img {
              transform: scale(1.05);
            }
          }
        }

        .card-msg {
          padding: 10px 15px;

          >.el-row+.el-row {
            margin-top: 5px;
          }

          .pro-type {
            color: #999;
          }

          .pro-nameEn,
          .pro-nameCn {
            text-align: justify;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .pro-code {
            color: #1890ff;
          }
        }
      }
    }
  }

  ::v-deep {

    .el-card.is-hover-shadow:hover,
    .el-card.is-hover-shadow:focus {
      cursor: pointer;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    }

    .image-slot {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ededed;

      img {
        width: 152px !important;
        height: 50px !important;
        color: red;
      }
    }
  }
</style>