<!-- 退料入库-入库申请 -->
<template>
  <div class="table-container inbound">
    <el-row v-if="$route.query.noReturn !== 'true'">
      <el-page-header @back="goBack" content="入库申请"></el-page-header>
    </el-row>
    <h3 class="mb20">基本信息</h3>
    <el-form ref="form" :model="form" :rules="rules">
      <div class="form-wrapper">
        <el-row>
          <el-col :span="6">
            <el-form-item label="入库方式" prop="type" label-width="90px">
              <el-select
                v-model="currentType"
                value-key="type"
                disabled
                style="width: 100%"
              >
                <el-option
                  v-for="item in typeNames"
                  :key="item.type"
                  :value="item.type"
                  :label="item.typeName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item
              label="入库仓库"
              prop="warehouseId"
              label-width="90px"
              :rules="[
                {
                  required: true,
                  message: '请选择入库仓库',
                  trigger: ['change'],
                },
              ]"
            >
              <el-select
                clearable
                v-model="form.warehouseId"
                value-key="type"
                style="width: 100%"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in warehouses"
                  :key="item.warehouseId"
                  :value="item.warehouseId"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="销售单号" prop="creator" label-width="90px">
              <el-input disabled v-model="form.orderNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="加工单号" prop="creator" label-width="90px">
              <el-input disabled v-model="form.creator"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="创建人" prop="creator" label-width="90px">
              <el-input disabled v-model="form.creator"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="所属地区"
              prop="creatorArea"
              label-width="90px"
            >
              <el-input disabled v-model="form.creatorArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="remark" label-width="90px">
              <span slot="label">备注</span>
              <el-input
                type="textarea"
                :rows="2"
                show-word-limit
                v-model="form.remark"
                maxlength="500"
                placeholder="请输入备注,字数500字以内"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 入库产品 -->
      <h3 class="mt30 mb20 h3-wrapper">
        <span>入库产品</span>
      </h3>
      <el-table border :data="form.tableData" class="mt20">
        <el-table-column align="center" prop="skuId" label="产品编码">
          <template slot-scope="scope">
            <el-link
              v-if="scope.row.skuId.indexOf('FL') === -1"
              type="primary"
              :underline="false"
              @click="skuIdClick(scope.row.skuId)"
            >
              {{ scope.row.skuId }}
            </el-link>
            <span v-else>{{ scope.row.skuId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="productName"
          label="产品名称"
        ></el-table-column>
        <el-table-column align="center" prop="productSpec" label="规格">
          <template slot-scope="scope">
            {{ scope.row.productSpec | emptyFilter }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="productType"
          label="产品类型"
        ></el-table-column>
        <el-table-column align="center" prop="productUnit" label="单位">
          <template slot-scope="scope">
            {{ scope.row.productUnit | emptyFilter }}
          </template>
        </el-table-column>
        <template v-if="form.type == 15">
          <el-table-column
            align="center"
            prop="warehouseInNum"
            width="150px"
            label="实际入库数量"
          >
            <template slot="header">
              <i class="red">*</i>
              实际入库数量
            </template>
            <template slot-scope="scope">
              <el-form-item
                label-width=""
                :prop="'tableData.' + scope.$index + '.warehouseInNum'"
                :rules="[
                  {
                    required: true,
                    message: '请输入实际入库数量',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input-number
                  :controls="false"
                  v-model="form.tableData[scope.$index].warehouseInNum"
                  :precision="0"
                  :min="1"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            align="center"
            prop="warehouseInNum"
            width="150px"
            label="实际入库数量"
          >
            <template slot="header">
              <i class="red">*</i>
              合格品入库数量
            </template>
            <template slot-scope="scope">
              <el-form-item
                label-width=""
                :prop="'tableData.' + scope.$index + '.warehouseInNum'"
                :rules="[
                  {
                    required: true,
                    message: '请输入实际入库数量',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input-number
                  :controls="false"
                  v-model="form.tableData[scope.$index].warehouseInNum"
                  :precision="0"
                  :min="1"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="warehouseInNum"
            width="150px"
            label="报废品入库数量"
          >
            <template slot="header">
              <i class="red">*</i>
              报废品入库数量
            </template>
            <template slot-scope="scope">
              <el-form-item
                label-width=""
                :prop="'tableData.' + scope.$index + '.warehouseInNum'"
                :rules="[
                  {
                    required: true,
                    message: '请输入实际入库数量',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input-number
                  :controls="false"
                  v-model="form.tableData[scope.$index].warehouseInNum"
                  :precision="0"
                  :min="1"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
        </template>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <p class="operation">
              <el-tooltip
                class="item"
                effect="dark"
                content="删除"
                placement="top-start"
              >
                <el-button
                  type="danger"
                  plain
                  icon="el-icon-delete-solid"
                  circle
                  size="mini"
                  @click="delPro(scope.$index)"
                ></el-button>
              </el-tooltip>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <h3 class="mt30 mb20">相关附件</h3>
    <ErpUpload
      ref="ErpUpload"
      :uploadParams="uploadParams"
      @uploadList="uploadList"
      style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
    ></ErpUpload>
    <p class="divider"></p>
    <div style="text-align: center">
      <el-button type="primary" @click="onSubmit" :loading="isLoading">
        确认
      </el-button>
      <el-button @click="goBack" class="ml15">取消</el-button>
    </div>

    <!-- v1.4.2确认入库无需审核 -->
    <AuditChoose ref="AuditChoose" @auditData="saveAuditClick" />
  </div>
</template>

<script>
  import { InboundSheet, ProductListInteractor } from '@/core'
  import { OutProcessing } from '@/core/interactors/purchase/outsourceProcess'

  import { mapGetters } from 'vuex'
  import AuditChoose from '@/components/AuditChoose'
  import popoverShipClick from '../outbound-sheet/components/popoverShipClick.vue'
  export default {
    name: 'InboundApply',
    data() {
      return {
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        form: {
          type: 1, //入库方式code
          typeName: '', // 入库方式名称
          warehouseId: '', //入库仓库
          operationId: '',
          operationName: '',
          remark: '',
          orderNumber: '', //订单编号
          warehouseInId: '',
          purchaseOrderNumber: '', //采购单号
          supplierName: '',
          supplierId: '',
          warehouseInNumber: '',
          creator: '',
          creatorArea: '',
        },
        showError: false,
        rules: {},
        tableKey: '',
        typeNames: [
          {
            type: 0,
            typeName: '采购入库',
          },
          {
            type: 1,
            typeName: '退货入库',
          },
          {
            type: 2,
            typeName: '其他入库',
          },
          {
            type: 10,
            typeName: '调拨入库',
          },
          {
            type: 11,
            typeName: '盘盈入库',
          },
          {
            type: 14,
            typeName: '加工入库',
          },
          {
            type: 15,
            typeName: '退料入库',
          },
        ], //入库方式
        warehouses: [], //仓库列表
        params: {
          show: false,
        },

        tableData: [], // 产品数据
        currentRow: {}, //产品当前操作行
        files: '',
        isLoading: false,
        showEmptyError: false,
      }
    },

    components: {
      AuditChoose,
      popoverShipClick,
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      tenantId() {
        return this.userInfo.tenantId
      },
    },
    created() {
      this.tableKey = Date.now() + this.form.type
      this.initData()
      InboundSheet.findWarehouseList({
        hasCloudWarehouse: 1,
      }).then((res) => {
        this.warehouses = res.data || []
      })
      InboundSheet.inboundApply({}).then((res) => {
        if (res && res.code === '000000') {
          this.form = res.data
          this.form.tableData = res.data?.warehouseInProductVOList
        }
      })
    },
    filters: {
      emptyFilter(val) {
        return val || '--'
      },
    },
    methods: {
      // 删除产品
      delPro(index) {
        if (this.form.tableData.length === 1) {
          return this.$message.warning('至少保留一条数据')
        }
        this.$confirm('是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.tableData.splice(index, 1)
          })
          .catch(() => {})
      },

      // 点击产品链接
      skuIdClick(skuId) {
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        if (id.indexOf('FL') > -1) return
        let response = await ProductListInteractor.productIdByCode(id)
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      goBack() {
        this.$router.push('/purchase/outsourceProcess/outsource-process')
      },
      checkEmpty(row, field) {
        if (
          row[field] === 0 ||
          row[field] === '' ||
          typeof row[field] === 'undefined'
        ) {
          if (!row[field + 'emptyCheck']) {
            this.$set(row, [field + 'EmptyCheck'], true)
          }
        }
      },

      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },

      get2TypeFiles() {
        let files = {
          originFileNames: '',
          originFileUrls: '',
          fileUrls: '',
          fileNames: '',
        }
        if (this.files) {
          let filesObj = JSON.parse(this.files)
          let originFileUrls = JSON.stringify(
            filesObj.filter((file) => file.isOrign)
          )
          let fileUrls = JSON.stringify(
            filesObj.filter((file) => !file.isOrign)
          )

          files.fileUrls = fileUrls
          files.originFileUrls = originFileUrls
        }

        return files
      },

      deletePro(index) {
        this.form.tableData.splice(index, 1)
      },

      //提交审核
      saveAuditClick(auditObjectDTO) {
        this.saveData()
      },

      // 保存请求
      saveData(auditObjectDTO) {
        const form = { ...this.form }
        if (auditObjectDTO) {
          form.auditObjectDTO = auditObjectDTO
        }
        InboundSheet.saveInboundApply(form).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('操作成功')
            this.goBack()
          }
        })
      },
      async onSubmit() {
        this.$refs.form.validate(async (flag) => {
          if (flag) {
            // 判断是否需要审核
            let res = await OutProcessing.getOutProcessingConfig()
            let isAudit = res.data?.needAudit === 1
            if (isAudit) {
              this.showAuditTemplate()
            } else {
              this.saveData()
            }
          }
        })
      },

      // 选择审核模板
      showAuditTemplate() {
        let auditParams = {
          auditType: 4,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(auditParams).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(auditParams)
          }
        })
      },
      initData() {
        this.currentType = 14
        this.form.operationName = this.userInfo.userName
        this.form.operationId = this.userInfo.userId
        this.form.creatorArea = this.userInfo.area
      },

      initUpload(data, flag) {
        this.$refs.ErpUpload.initUpload(data, flag)
      },
    },
  }
</script>

<style scoped lang="scss">
  .h3-wrapper {
    display: flex;
    .inspection-result {
      flex: 1;
    }
  }
  .page-header {
    height: 33px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  .mb30 {
    margin-bottom: 30px;
  }
  .mt30 {
    margin-top: 30px;
  }

  .mb20 {
    margin-bottom: 20px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mb15 {
    margin-bottom: 15px;
  }
  .mt15 {
    margin-top: 15px;
  }
  .table-container {
    padding: 20px 15px !important;
  }
  .operation {
    display: flex;
    justify-content: space-evenly;
    i {
      font-size: 20px;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .error-info {
    color: #ff4d4f;
    font-size: 12px;
    line-height: 1;
    position: relative;
    padding-top: 5px;
  }

  ::v-deep .el-input-number--small {
    width: 100px !important;
  }
  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }
  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }
  ::v-deep .el-input-number--small.error .el-input__inner {
    border-color: #ff4d4f;
  }
</style>

<style>
  .inbound .el-select-dropdown__item {
    width: 200px;
  }
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
