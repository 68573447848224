<template>
  <el-dialog
    title="指定角色"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="角色" prop="roleId">
              <el-cascader
                :options="treeData2"
                v-model="form.roleId"
                :props="optionProps2"
                :show-all-levels="false"
                class="w100"
                @change="roleIdChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { customerManagerSettingUpdatePO } from '@/api/client-supplier/supplier-setting'
  function getCascaderObj(val, opt) {
    return val.map(function (value, index, array) {
      for (var itm of opt) {
        if (itm.roleId === value) {
          opt = itm.children
          return itm
        }
      }
      return null
    })
  }
  export default {
    name: 'RoleModel',
    data() {
      return {
        loading: false,
        form: {
          handlerType: 0, //客户分管申请处理人
          roleName: '', //指定角色
          roleId: '', //指定角色id
          parentId: '', //指定角色的父级
          customerManagerSettingId: '', //业务唯一id
        },
        rules: {
          roleId: [{ required: true, trigger: 'blur', message: '请选择角色' }],
        },
        treeData2: [], //角色
        optionProps2: {
          value: 'roleId',
          label: 'name',
          children: 'children',
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showAddEdit(params) {
        this.form = params
        this.form.roleId = [params.parentId, params.roleId]
        //获取角色
        this.pubApi.getRolelistPaged().then((response) => {
          if (response.code === '000000') {
            let treeData2 = response.data
            let i = treeData2.length
            while (i--) {
              if (treeData2[i].roleType === 1 && treeData2[i].children) {
                treeData2[i].children.forEach((item3, index3) => {
                  if (item3.name === '超级管理员' && item3.adminRole === 1) {
                    treeData2[i].children.splice(index3, 1)
                  }
                })
              }
              if (treeData2[i].adminRole === 0 && !treeData2[i].children) {
                treeData2.splice(i, 1)
              }
            }
            this.treeData2 = treeData2
          }
        })

        this.dialogFormVisible = true
      },

      //关闭
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$emit('role-close')
      },
      roleIdChange(val) {
        //let roleObjArr = getCascaderObj(val, this.treeData2)
        // this.form.roleName = roleObjArr.slice(-1)[0].name
        // this.form.roleId = val.slice(-1).toString()
      },
      //保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let form = this.utils.deepCopy(this.form)
            if (form.roleId) {
              let roleId = this.utils.deepCopy(form.roleId)
              let roleObjArr = getCascaderObj(roleId, this.treeData2)
              form.roleName = roleObjArr.slice(-1)[0].name
              form.roleId = roleId.slice(-1).toString()
              form.parentId = roleId.slice(0, 1).toString()
            }
            this.loading = true
            let response = await customerManagerSettingUpdatePO(form)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                '操作成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
