<template>
  <el-dialog
    title="费用类型"
    :visible.sync="dialogVisible"
    width="400px"
    @close="close"
    class="dialog-middle"
  >
    <el-form
      :model="form"
      label-width="100px"
      :rules="rules"
      size="small"
      ref="form"
    >
      <el-form-item label="费用类型" prop="financeName">
        <el-input
          v-model="form.financeName"
          maxlength="50"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item prop="expenseMethod">
        <template slot="label">
          <span>分摊方式</span>
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">
              <p>1）申请费用时，将费用类型对应的默认分摊方式带出，可以更改；</p>
              <p>2）导入费用，根据默认的分摊方式进行分摊；</p>
              <p>3）导入的费用默认方式不能为手动分摊；</p>
            </div>

            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <el-select
          v-model="form.expenseMethod"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in expenseMethodOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="费用说明" prop="remark">
        <el-input
          v-model="form.remark"
          maxlength="500"
          placeholder="请输入"
          type="textarea"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="close">取消</el-button>
      <el-button type="primary" size="mini" @click="submit" :loading="loading">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    FinanceCostAdd,
    FinanceCostUpdate,
  } from '@/api/finance/finance-setting'
  export default {
    name: 'CostSetting',
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: {
          financeName: '', // 中文名
          remark: '',
          financeSetupId: null,
          expenseMethod: '',
        },
        rules: {
          financeName: [{ required: true, message: '请输入', trigger: 'blur' }],
          expenseMethod: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
        },
        expenseMethodOptions: [
          {
            label: '数量比例',
            value: 1,
          },
          {
            label: '金额比例',
            value: 2,
          },
        ],
      }
    },
    created() {},
    methods: {
      //打开弹框
      showDialog(row) {
        this.dialogVisible = true
        if (row) {
          this.form = Object.assign({}, row)
        } else {
          this.form.financeSetupId = null
        }
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
      //清空表单
      close() {
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            let Method
            if (this.form.financeSetupId) {
              Method = FinanceCostUpdate
            } else {
              Method = FinanceCostAdd
            }
            this.loading = true
            Method(this.form)
              .then((res) => {
                this.loading = false
                if (res.code == '000000') {
                  this.$message.success(
                    this.form.financeSetupId ? '修改成功！' : '新增成功！'
                  )
                  this.$emit('success')
                  this.close()
                }
              })
              .catch((err) => {
                this.loading = false
              })
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
