<template>
  <!-- 上传图片 -->
  <el-dialog
    :title="$t('reqmsg.G004')"
    :visible.sync="visible"
    :before-close="handleClose"
    width="900px"
    :modal-append-to-body="true"
    :append-to-body="true"
  >
    <erp-upload-images
      :limit="1"
      @change="changeUploadList"
      v-if="visible"
    ></erp-upload-images>
    <el-row slot="footer" class="dialog-footer text-center">
      <!-- 确认 -->
      <el-button type="primary" @click="confirmChoose">
        {{ $t('addInquiry.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="handleClose">{{ $t('addInquiry.Cancel') }}</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  name: 'chooseImages',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageList: [],
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.imageList = JSON.parse(JSON.stringify(this.defaultFileList))
      }
    },
  },
  methods: {
    // 关闭 dialog
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 上传印刷效果图
    changeUploadList(list) {
      this.imageList = list
    },
    // 格式化数据
    formatData() {
      let __urls = []
      const __imageList = this.imageList

      for (let i = 0; i < __imageList.length; i++) {
        __urls.push(__imageList[i].url)
      }
      return __urls.toString()
    },
    // 确认
    confirmChoose() {
      if (this.imageList.length) {
        this.handleClose()
        this.$emit('confirm', this.formatData())
      } else {
        this.$message.warning(this.$t('reqmsg.G005'))
        return false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.wrapper-status {
  margin-top: 10px;
}
</style>
