<template>
  <el-row :gutter="20" class="pro-container pro-base-msg pro-print">
    <el-col :span="2">
      <!-- 印刷计价 -->
      <h6 class="left-h6">
        {{ $t('productDetail.PrintingPricing') }}
      </h6>
    </el-col>
    <el-col :span="22" class="right-content" style="padding: 20px 20px">
      <el-row type="flex" align="middle" class="mb20 align_center">
        <!-- 印刷方式 -->
        <span class="mr10">{{ $t('productDetail.PrintingMethod') }}</span>
        <!-- 请选择(多选) -->
        <el-select v-model="productPrintingWayId" @change="printWayChange" :placeholder="$t('placeholder.M2006')"
          multiple @visible-change="printWayVisibleChange" collapse-tags>
          <el-option v-for="(item, index) of printWay" :key="index" :label="lang === 'zh' ? item.nameCn : item.nameEn"
            :value="item.productPrintingPricingId" />
        </el-select>
        <!-- 请先选择产品类别 -->
        <p class="ml15">tip:{{ $t('reqmsg.M2018') }}</p>
      </el-row>
      <!-- 方式详情 -->
      <el-row v-if="printList && printList.length">
        <el-col class="content mt20" v-for="(print, index) in printList" :key="index">
          <!-- 印刷方式： -->
          <p class="content-head">
            {{ $t('productDetail.PrintingMethod') }}：{{
            lang === 'zh' ? print.nameCn : print.nameEn
            }}
          </p>
          <el-row class="panel-item">
            <tableSearch :searchData.sync="print" :ref="'table_search_' + index" :numberStepList.sync="numberStepList"
              :transportHeadPrice.sync="transportHeadPrice" :transportDay.sync="transportDay"
              :productPrintingSwitch="productPrintingSwitch" :options="options" :exchange="exchange" :key="updateKey"
              :toFixedNumber="toFixedNumber" :standardBol="standardBol" />
          </el-row>
          <el-row class="panle-item-title">
            <el-col>
              <h6 class="left-h6">
                {{ $t('productDetail.FinishedProductPricing') }}
              </h6>
            </el-col>
            <el-col>
              <pricingTable :pricingTableData.sync="print" :ref="'pricing_table' + index"
                :numberStepList.sync="numberStepList" :transportHeadPrice="transportHeadPrice"
                :transportDay="transportDay" :key="updateKey" :exchange="exchange" :toFixedNumber="toFixedNumber" />
            </el-col>
          </el-row>
          <el-row class="panle-item-title">
            <el-col>
              <h6 class="left-h6">
                {{ $t('productDetail.TurnaroundDelivery') }}
              </h6>
            </el-col>
            <el-col class="outnumber-panle">
              <div v-show="lang === 'zh'" class="box">
                <p>
                  {{
                  $t('productDetail.transportDayTip') +
                  '“' +
                  transportInfo.nameCn +
                  '”' +
                  ','
                  }}
                  {{ $t('productDetail.transportDayTip1') }}
                </p>
                <p>
                  <el-input v-model.number="print.transportDay" type="number"
                    oninput="if(isNaN(value)) { value  = null }"
                    onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}"></el-input>
                </p>
                <p>{{ $t('productDetail.WorkingDay') }};</p>
              </div>
              <div v-show="lang === 'zh'" class="box">
                <p>{{ $t('productDetail.QuantityExceedsTpis') }}</p>
                <p>
                  <el-input v-model="print.deliveryProductNum" type="number" @focus="focusFn(print)"
                    @blur="blurFn(print)" oninput="if(isNaN(value)) { value  = null }"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                </p>
                <p>{{ $t('productDetail.QuantityExceedsTpis1') }}</p>
                <p>
                  <el-input v-model="print.deliveryDayProductNum" type="number"
                    oninput="if(isNaN(value)) { value  = null }"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                </p>
                <p>{{ $t('productDetail.QuantityExceedsTpis2') }}</p>
              </div>
              <p v-show="lang !== 'zh'">
                {{
                $t('productDetail.transportDayTip') +
                '“' +
                transportInfo.nameEn +
                '”' +
                ','
                }}
                {{ $t('productDetail.transportDayTip1') }}
                <el-input v-model.number="print.transportDay" type="number" @focus="focusFn(print)"
                  @blur="blurFn(print)" oninput="if(isNaN(value)) { value  = null }"
                  onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}"></el-input>
                {{ $t('productDetail.WorkingDay') }};
              </p>
              <p v-show="lang !== 'zh'">
                When the quantity of products in a single order exceeds
                <el-input v-model="print.deliveryProductNum" type="number" @focus="focusFn(print)" @blur="blurFn(print)"
                  oninput="if(isNaN(value)) { value  = null }"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                , or the cumulative quantity of products in a single day exceeds
                <el-input v-model="print.deliveryDayProductNum" type="number"
                  oninput="if(isNaN(value)) { value  = null }"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                , sales representative quotes the delivery date to the customer
              </p>

              <el-button size="medium" plain style="margin-left: 60px" @click="calculateFn(print)"
                class="calculation-cycle">
                {{ $t('productDetail.CalculationCycle') }}
              </el-button>
            </el-col>
            <el-col>
              <leadTimeTable :pricingTableData.sync="print" :ref="'lead_time_table' + index"
                :numberStepList.sync="numberStepList" :transportDay="transportDay" :toFixedNumber="toFixedNumber"
                :key="updateKey" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
  import { ProductAddEditInteractor } from '@/core/interactors/product/productAddEdit'
  import { ProductListInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  import tableSearch from './components/tableSearch.vue'
  import pricingTable from './components/pricingTable.vue'
  import leadTimeTable from './components/leadTimeTable.vue'
  import { elFormErrorScrollIntoView } from 'kits'
  import _ from 'lodash'
  import { deepCopy } from 'kits'
  export default {
    props: {
      standardBol: {
        type: Boolean,
      },
    },
    data() {
      return {
        printWay: [], //印刷方式options
        productPrintingWayId: [], // 印刷方式
        printList: [],
        numberStepList: [], //数量阶梯
        transportHeadPrice: 0, //运输头程单价
        transportDay: 0, //运城周期
        productPrintingSwitch: false,
        options: [],
        updateKey: 1,
        exchange: 0, //汇率
        transportInfo: {},
        transportationTypeOptions: [],
        toFixedNumber: 2,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    components: {
      tableSearch,
      pricingTable,
      leadTimeTable,
    },
    created() {
      if (this.$route.query.str === 'add') this.getCurrencyData()
      // 运输单价改变
      this.$Bus.$on('freightPriceChange', (obj) => {
        this.transportHeadPrice = Number(obj?.transportHeadPrice)
        this.transportDay = Number(obj?.transportDay)
      })
      this.$Bus.$on(
        'productPrintingSwitchChange',
        this.onPrintingSwitchChange.bind(this)
      )
      this.$Bus.$on('switchChange', () => {
        this.printList.map((item) => {
          item.printingPositionIds = []
          item.printingPositionValue = []
          item.printingPositionValueEn = []
        })
        this.updateKey++
      })
      // 切换产品类别
      this.$Bus.$on('show-clear', () => {
        this.printList.splice(0, this.printList.length)
        this.productPrintingWayId = []
        console.log('切换类别')
        this.updateKey++
      })
      // 改变汇率
      this.$Bus.$on('changeRate', (num) => {
        this.exchange = num * 1
      })
      // 切换运输类型
      this.$Bus.$on('transportTypeChangeFn', (val) => {
        this.transportInfo = this.transportationTypeOptions.filter(
          (item) => item.transportType == val
        )?.[0] || {}
        this.printList.map((item) => {
          item.transportDay = this.transportInfo?.transportDay
        })
      })
      // 切换小数位
      this.$Bus.$on('changePriceScale', (val) => {
        this.toFixedNumber = val
      })
      if (this.$route.query.str === 'add') {
        this.getTransportDayAndPriceListByTenantId()
      }
    },
    beforeDestroy() {
      this.$Bus.$off('freightPriceChange')
      this.$Bus.$off('productPrintingSwitchChange')
      this.$Bus.$off('switchChange')
      this.$Bus.$off('show-clear')
      this.$Bus.$off('changeRate')
      this.$Bus.$off('transportTypeChangeFn')
      this.$Bus.$off('ChangeShippingCosts')
      this.$Bus.$off('changePriceScale')
      this.$Bus.$off('changeCategoryId')
    },
    mounted() {
      this.getPrintWayByCategoryId()
      this.getNumberStep()
    },
    methods: {
      // 获取当前租户运输周期、运费
      async getTransportDayAndPriceListByTenantId() {
        let response = await ProductListInteractor.getTransportDayApi()
        if (response.code === '000000') {
          this.setTransportationTypeOptions(response.data)
        } else {
          this.transportationTypeOptions = []
        }
      },
      // 运输类型添加中英名称
      setTransportationTypeOptions(arr) {
        let newArr = arr.filter((item) => !!item.transportType)
        const obj = {
          1: (item) => {
            item.nameCn = '空运'
            item.nameEn = 'Air Transport'
          },
          2: (item) => {
            item.nameCn = '海运'
            item.nameEn = 'Ocean Shipping'
          },
          3: (item) => {
            item.nameCn = '快递'
            item.nameEn = 'Express'
          },
        }
        newArr.map((item) => {
          obj[item['transportType']](item)
          return item
        })
        for (let k in newArr) {
          this.$set(this.transportationTypeOptions, k, newArr[k])
        }
        if (this.$route.query.str === 'add') {
          this.transportInfo = this.transportationTypeOptions.filter(
            (item) => item.transportType == 1
          )?.[0]
        }
      },
      // 计算运输周期
      calculateFn(data) {
        data.productPrintingFullValuationDTOList.map((item, index) => {
          this.numberStepList.forEach((value, index) => {
            item['printingDay' + index] = this.generationTimeFn(
              data.capacityUnit,
              item.capacity,
              value.num
            )
            item['printingFrontDay' + index] = item['printingDay' + index]
            item['deliverDay' + index] =
              Number(item['printingDay' + index]) + Number(data.transportDay)
          })
          return item
        })
      },
      // 秒、分、小时向上取值转换天8小时
      generationTimeFn(type, num, totalNum) {
        const obj = {
          0: () => {
            const dayNUm = Number(num) * 60 * 60 * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          1: () => {
            const dayNUm = num * 60 * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          2: () => {
            const dayNUm = num * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          3: () => {
            return num ? Math.ceil(Number(totalNum) / Number(num)) : ''
          },
          4: () => {
            return num ? Math.ceil(Number(totalNum) / Number(num)) : ''
          },
        }
        return obj[type]()
      },
      printWayVisibleChange(hide) {
        if (!hide) {
          if (this.productPrintingSwitch) {
            this.setDefaultPrintPosition(this.productPrintingWayId)
          }
        }
      },
      // 获取汇率
      getCurrencyData() {
        this.pubApi.getCurrency({ financeName: '美元' }).then((response) => {
          if (response.code === '000000') this.exchange = response.data
        })
      },
      // 开始印刷区域后设置默认印刷位
      setDefaultPrintPosition(val) {
        let arr = []
        if (!this.oldPrintWayValue) {
          arr = val
        } else {
          arr = val.filter((item) => {
            return !this.oldPrintWayValue.includes(item)
          })
        }
        // 找到存在val数组中不存在oldPrintWayValue数组中的元素，返回一个不存在的数组

        // 遍历 this.printList每项item,如果item.productPrintingPricingId存在于arr中，则将item的printPositionValue设置为this.options数组中所有元素的nameEn属性组成的新数组
        this.printList.forEach((item) => {
          if (arr.includes(item.productPrintingPricingId)) {
            if (
              !item.printingPositionIds ||
              item.printingPositionIds.length === 0
            ) {
              item.printingPositionIds = this.options.map((it) => {
                return it.nameEn
              })
            }
          }
        })

        this.oldPrintWayValue = val
      },
      getNewData(edit) {
        edit.transportType = edit.transportType ? edit.transportType : 1
        this.transportInfo = this.transportationTypeOptions.filter(
          (item) => item.transportType == edit.transportType
        )?.[0]
        this.toFixedNumber = edit.priceScale ? edit.priceScale : 2
        let numberStepList = edit.productLadderPriceVOList.map((item) => {
          return {
            num: Number(item.num),
            transportHeadPrice: Number(item.transportHeadPrice),
          }
        })
        numberStepList = _.uniqBy(numberStepList, 'num')
        this.numberStepList = _.orderBy(numberStepList, ['num'], ['asc'])
        let list = edit?.productPrintingValuationVOList
          ? deepCopy(edit?.productPrintingValuationVOList)
          : []
        let printWayIdsArr = this.printWay.map(
          (item) => item.productPrintingPricingId
        )
        list = list.reduce((previousValue, currentValue) => {
          if (printWayIdsArr.includes(currentValue.productPrintingWayId)) {
            previousValue.push(currentValue)
          }
          return previousValue
        }, [])
        list = list?.map((currentValue) => {
          if (currentValue.productPrintingWayId) {
            this.productPrintingWayId.push(currentValue.productPrintingWayId)
          }

          const payItem = this.printWay.filter(
            (item) =>
              item.productPrintingPricingId ===
              currentValue.productPrintingWayId
          )[0]

          const optionsVOSNames =
            payItem?.optionsVOS.map((item) => item.pricingValue) || []
          let valuationMethodIdsNew = []
          let valuationMethodValueNew = []
          let valuationMethodValueEnNew = []

          let productPrintingFullValuationDTOList = []
          let capacityUnit = ''
          currentValue?.productPrintingFullValuationVOList?.forEach(
            (item, index) => {
              capacityUnit = item.capacityUnit
              const idObj =
                payItem?.optionsVOS.filter(
                  (ovs) => ovs.pricingValue === item.valuationMethod
                )?.[0] ?? {}
              let obj = {
                printingPositionNum: item.printingPositionNum,
                valuationMethod: item.valuationMethod,
                valuationMethodEn: item.valuationMethodEn
                  ? item.valuationMethodEn
                  : idObj?.pricingValueEn ?? '',
                capacity: item.capacity ?? '',
                price: item.price,
                publishFee: item.publishFee,
                fullValuationId: item.fullValuationId,
              }
              item.productPrintingLadderVOList?.forEach((subItem, sunIndex) => {
                obj['profitRate' + sunIndex] = subItem.profitRate
                obj['price' + sunIndex] = subItem.price
                obj['printingDay' + sunIndex] = subItem.printingDay
                obj['printingFrontDay' + sunIndex] = subItem.printingFrontDay
                obj['deliverDay' + sunIndex] = subItem.deliverDay
                obj['ladderNumId' + sunIndex] = subItem.ladderNumId
                obj['transportHeadPrice' + sunIndex] =
                  subItem.transportHeadPrice
              })
              if (optionsVOSNames.includes(item.valuationMethod)) {
                productPrintingFullValuationDTOList.push(obj)
                const idObj =
                  payItem?.optionsVOS.filter(
                    (ovs) => ovs.pricingValue === item.valuationMethod
                  )?.[0] ?? {}
                valuationMethodValueEnNew.push(idObj?.pricingValueEn)
                valuationMethodValueNew.push(item.valuationMethod)
                valuationMethodIdsNew.push(
                  idObj?.productPrintingPricingOptionsId
                )
              }
            }
          )
          valuationMethodIdsNew = _.uniq(valuationMethodIdsNew)
          valuationMethodValueNew = _.uniq(valuationMethodValueNew)
          valuationMethodValueEnNew = _.uniq(valuationMethodValueEnNew)

          return {
            capacityUnit:
              !capacityUnit && capacityUnit !== 0 ? 1 : capacityUnit,
            optionsVOS: payItem?.optionsVOS,
            nameCn: payItem?.nameCn,
            nameEn: payItem?.nameEn,
            locationNum: currentValue.locationNum,
            printingPositionIdsOptions: payItem?.printingPositionIds,
            printingPositionIds: currentValue.printingPositionIds
              ? currentValue.printingPositionIds.split(',')
              : [],
            valuationMethodIds: valuationMethodIdsNew,
            printingPositionValue: currentValue.printingPositionValue
              ? currentValue.printingPositionValue.split(',')
              : [],
            printingPositionValueEn: currentValue.printingPositionValueEn
              ? currentValue.printingPositionValueEn.split(',')
              : [],
            valuationMethodValue: valuationMethodValueNew,
            valuationMethodValueEn: valuationMethodValueEnNew,
            productPrintingWayId: currentValue.productPrintingWayId,
            profitRateTemplateId: currentValue.profitRateTemplateId,
            returnDiscount: currentValue.returnDiscount,
            deliveryProductNum: currentValue.deliveryProductNum,
            transportDay: currentValue.transportDay,
            blurBol: true,
            deliveryDayProductNum: currentValue.deliveryDayProductNum,
            blurBol: true,
            printingValuationId: currentValue.printingValuationId,
            productPrintingFullValuationDTOList,
          }
        })
        this.oldPrintWayValue = this.productPrintingWayId
        list?.forEach((item, index) => {
          this.$set(this.printList, index, item)
        })
      },
      async getOldData(edit) {
        const tyep = Number(
          edit?.productPricingStepVOList?.[0]?.transportType ?? ''
        )
        edit.transportType = tyep || 1
        this.transportInfo = this.transportationTypeOptions.filter(
          (item) => item.transportType == edit.transportType
        )?.[0]
        this.toFixedNumber = 3
        let getPrintPosition = await ProductAddEditInteractor.getPrintPosition(
          5
        )
        let printPositionAllArr =
          getPrintPosition.code === '000000' ? getPrintPosition.data : []
        let numberStepList = edit?.productPricingStepVOList?.map((item) => {
          return {
            num: Number(item.num),
            transportHeadPrice: Number(item.transportPrice),
          }
        })
        numberStepList = _.uniqBy(numberStepList, 'num')
        this.numberStepList = _.orderBy(numberStepList, ['num'], ['asc'])
        let list = edit?.productPrintingWayVOList
          ? deepCopy(edit?.productPrintingWayVOList)
          : []
        let printWayIdsArr = this.printWay.map(
          (item) => item.productPrintingPricingId
        )
        list = list.reduce((previousValue, currentValue) => {
          if (printWayIdsArr.includes(currentValue.printingWaySelectId)) {
            previousValue.push(currentValue)
          }
          return previousValue
        }, [])
        list = list.map((currentValue) => {
          let productPrintingFullValuationDTOList = []
          let printingPositionsArr = currentValue.printingPositions
            ? currentValue.printingPositions.split(',')
            : []
          currentValue.printingWay = JSON.parse(currentValue.printingWay)
          if (currentValue.productPrintingWayId) {
            this.productPrintingWayId.push(currentValue.printingWaySelectId)
          }
          const payItem = this.printWay.filter(
            (item) =>
              item.productPrintingPricingId === currentValue.printingWaySelectId
          )?.[0]
          const pricingValueArr =
            payItem?.optionsVOS.map((item) => item.pricingValue) ?? []
          currentValue.printingWay = currentValue.printingWay.filter(
            (item) =>
              item.productPrintingPricingOptionsId &&
              pricingValueArr.includes(item.pricingValue) &&
              item.pricingValueEn
          )
          for (let k in printingPositionsArr) {
            for (let sunKey in currentValue.printingWay) {
              let obj = {
                printingPositionNum: k * 1 + 1,
                valuationMethod:
                  currentValue.printingWay[sunKey]['pricingValue'],
                valuationMethodEn:
                  currentValue.printingWay[sunKey]['pricingValueEn'],
                capacity: '',
                price: '',
                publishFee: '',
                fullValuationId: '',
              }
              this.numberStepList.forEach((subItem, sunIndex) => {
                obj['profitRate' + sunIndex] = ''
                obj['price' + sunIndex] = ''
                obj['printingDay' + sunIndex] = ''
                obj['printingFrontDay' + sunIndex] = ''
                obj['deliverDay' + sunIndex] = ''
                obj['ladderNumId' + sunIndex] = ''
                obj['transportHeadPrice' + sunIndex] =
                  subItem.transportHeadPrice
              })
              productPrintingFullValuationDTOList.push(obj)
            }
          }
          return {
            capacityUnit: 1,
            optionsVOS: payItem?.optionsVOS,
            nameCn: payItem?.nameCn,
            nameEn: payItem?.nameEn,
            printingPositionIdsOptions: payItem?.printingPositionIds,
            printingPositionIds: currentValue.printingPositions
              ? currentValue.printingPositions.split(',')
              : [],
            locationNum: currentValue.printingPositions
              ? currentValue.printingPositions.split(',').length
              : '',
            printingPositionValue: printPositionAllArr
              .filter((item) =>
                currentValue.printingPositions
                  .split(',')
                  .includes(item.productSettingId)
              )
              .map((item) => item.nameCn),
            printingPositionValueEn: printPositionAllArr
              .filter((item) =>
                currentValue.printingPositions
                  .split(',')
                  .includes(item.productSettingId)
              )
              .map((item) => item.nameEn),
            valuationMethodIds: currentValue.printingWay.map((item) => {
              for (let k of payItem?.optionsVOS) {
                if (item.pricingValue === k['pricingValue']) {
                  return k['productPrintingPricingOptionsId']
                }
              }
            }),
            valuationMethodValue: currentValue.printingWay.map(
              (item) => item.pricingValue
            ),
            valuationMethodValueEn: currentValue.printingWay.map(
              (item) => item.pricingValueEn
            ),
            productPrintingWayId: currentValue.printingWaySelectId,
            profitRateTemplateId: 'old',
            deliveryProductNum: '',
            transportDay: this.transportInfo?.transportDay || '',
            blurBol: true,
            deliveryDayProductNum: '',
            blurBol: true,
            printingValuationId: '',
            productPrintingFullValuationDTOList,
            selectProfitMarginArr: [],
          }
        })
        this.oldPrintWayValue = this.productPrintingWayId
        list?.forEach((item, index) => {
          this.$set(this.printList, index, item)
        })
      },
      async editShow(edit) {
        let res = null
        await this.getTransportDayAndPriceListByTenantId()
        this.exchange = edit.exchange
        if (edit.categoryId) {
          res = await ProductAddEditInteractor.getPrintWay(
            edit.categoryId?.split(',')[edit.categoryId?.split(',').length - 1]
          )
        }
        this.printWay = res?.code === '000000' ? res.data : []
        this.$Bus.$emit('sendPrintWay', this.printWay)
        if (edit.oldFlag) {
          this.getOldData(edit)
        } else {
          this.getNewData(edit)
        }
      },
      validateField2() {
        const productPrintingValuationDTOList = this.showData()
        let success = this.chekcouNum(productPrintingValuationDTOList)
        if (
          (!success && this.productPrintingSwitch) ||
          (!success && this.standardBol)
        ) {
          success = false
          this.$baseMessage(
            this.$t('productDetail.locationtTps'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          success = true
        }
        return {
          productPrintingValuationDTOList,
          success,
        }
      },
      validateField() {
        let success = false
        try {
          if (!this.productPrintingWayId.length) {
            return {
              success: true,
              productPrintingValuationDTOList: [],
            }
          }
          this.printList.forEach((item, index) => {
            this.$refs[`table_search_${index}`][0].$refs.tableSearch.validate(
              (valid) => {
                if (valid) {
                  success = true
                } else {
                  success = false
                  this.$nextTick(() => {
                    elFormErrorScrollIntoView('.el-form-item__error')
                  })
                  throw new Error('填写信息不完善')
                }
              }
            )
            this.$refs[`pricing_table${index}`][0].$refs.print_form.validate(
              (valid) => {
                if (valid) {
                  success = true
                } else {
                  success = false
                  this.$nextTick(() => {
                    elFormErrorScrollIntoView('.el-form-item__error')
                  })
                  throw new Error('填写信息不完善')
                }
              }
            )

            this.$refs[
              `lead_time_table${index}`
            ][0].$refs.lead_time_form.validate((valid) => {
              if (valid) {
                success = true
              } else {
                success = false
                this.$nextTick(() => {
                  elFormErrorScrollIntoView('.el-form-item__error')
                })
                throw new Error('填写信息不完善')
              }
            })
          })
          const productPrintingValuationDTOList = this.showData()
          let bol = this.chekcouNum(productPrintingValuationDTOList)
          if (
            (!bol && this.productPrintingSwitch) ||
            (!bol && this.standardBol)
          ) {
            success = false
            this.$baseMessage(
              this.$t('productDetail.locationtTps'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
          return {
            success,
            productPrintingValuationDTOList,
          }
        } catch (e) {
          return {
            success,
            productPrintingValuationDTOList: [],
          }
        }
      },
      // 验证印刷位置数量和位置计算数量是否一样
      chekcouNum(arr) {
        let printingPositionIdsArr = arr.map((item) => {
          return {
            printingPositionIds: item?.printingPositionIds
              ? item.printingPositionIds.split(',').length
              : 0,
            locationNum: item.locationNum,
          }
        })
        return printingPositionIdsArr.every(
          (item) => item.printingPositionIds === item.locationNum
        )
      },
      showData() {
        let cloneBol = false
        if (this.$route.query.str === 'clone') cloneBol = true
        let paramsArr = deepCopy(this.printList)
        let productPrintingValuationDTOList = []
        paramsArr.forEach((currentValue) => {
          let obj = {
            locationNum: currentValue.locationNum,
            printingPositionIds: currentValue.printingPositionIds.join(','),
            valuationMethodIds: currentValue.valuationMethodIds.join(','),
            printingPositionValue: currentValue.printingPositionValue.join(','),
            printingPositionValueEn:
              currentValue.printingPositionValueEn.join(','),
            valuationMethodValue: currentValue.valuationMethodValue.join(','),
            valuationMethodValueEn:
              currentValue.valuationMethodValueEn.join(','),
            productPrintingWayId: currentValue.productPrintingWayId,
            profitRateTemplateId: currentValue.profitRateTemplateId,
            returnDiscount: currentValue.returnDiscount,
            deliveryProductNum: currentValue.deliveryProductNum,
            transportDay: currentValue.transportDay,
            deliveryDayProductNum: currentValue.deliveryDayProductNum,
            printingValuationId: !cloneBol
              ? currentValue.printingValuationId
              : '',
            productPrintingFullValuationDTOList: [],
          }
          currentValue.productPrintingFullValuationDTOList.forEach(
            (item, index) => {
              let subObj = {
                sort: index + 1,
                printingPositionNum: item.printingPositionNum,
                valuationMethod: item.valuationMethod,
                valuationMethodEn: item.valuationMethodEn,
                capacity: item.capacity,
                capacityUnit: currentValue.capacityUnit,
                price: item.price,
                publishFee: item.publishFee,
                fullValuationId: !cloneBol ? item.fullValuationId : '',
                productPrintingLadderDTOList: [],
              }
              for (let k in this.numberStepList) {
                let sunObj = {}
                if (
                  currentValue.deliveryProductNum &&
                  this.numberStepList[k]['num'] >
                  currentValue.deliveryProductNum
                ) {
                  sunObj = {
                    num: this.numberStepList[k]['num'],
                    transportHeadPrice:
                      this.numberStepList[k]['transportHeadPrice'],
                    profitRate: item['profitRate' + k],
                    price: item['price' + k],
                    printingDay: null,
                    printingFrontDay: null,
                    deliverDay: null,
                    ladderNumId: !cloneBol ? item['ladderNumId' + k] : '',
                  }
                } else {
                  sunObj = {
                    num: this.numberStepList[k]['num'],
                    transportHeadPrice:
                      this.numberStepList[k]['transportHeadPrice'],
                    profitRate: item['profitRate' + k],
                    price: item['price' + k],
                    printingDay: item['printingDay' + k],
                    printingFrontDay: item['printingFrontDay' + k],
                    deliverDay: item['deliverDay' + k],
                    ladderNumId: !cloneBol ? item['ladderNumId' + k] : '',
                  }
                }
                subObj.productPrintingLadderDTOList.push(sunObj)
              }
              obj.productPrintingFullValuationDTOList.push(subObj)
            }
          )
          productPrintingValuationDTOList.push(obj)
        })
        return productPrintingValuationDTOList
      },
      async printWayChange(val) {
        if (!val || !val.length) {
          this.printList = []
          this.$Bus.$emit('printWay', [])
          return
        }
        if (
          !this.transportInfo?.transportDay &&
          this.transportInfo?.transportDay != 0
        ) {
          await this.getTransportDayAndPriceListByTenantId()
          this.transportInfo = this.transportationTypeOptions.filter(
            (item) => item.transportType == 1
          )?.[0]
        }

        if (!this.exchange) {
          await this.getCurrencyData()
        }

        const obj = {
          productPrintingWayId: '', //印刷方式
          printingPositionIds: [], //印刷位置
          locationNum: 0,
          printingPositionValue: [], //印刷位置中文
          printingPositionValueEn: [], //印刷位置英文
          valuationMethodIds: [], //计价方式
          valuationMethodValue: [], //计价方式中文
          valuationMethodValueEn: [], //计价方式英文
          profitRateTemplateId: '', //利润率模版id
          returnDiscount: '', // 返单版费折扣
          capacityUnit: 1, //单位时间产能
          deliveryProductNum: '', //单订单的产品数量超过数
          transportDay: this.transportInfo?.transportDay, //运输周期
          blurBol: true,
          deliveryDayProductNum: '', //日累计的产品数量超过数
          blurBol: true,
          printingValuationId: '', //每一个产品信息id
          productPrintingFullValuationDTOList: [], //表格数据
          optionsVOS: [], //计价方式下拉框
          printingPositionIdsOptions: '', //印刷位置过滤
          selectProfitMarginArr: [], //利润率模版利率
        }
        const idArr = this.printList.map((item) => item.productPrintingWayId)
        if (val.length > idArr.length || !idArr.length) {
          // 新增
          for (let k of val) {
            if (!idArr.includes(k)) {
              const currItem = this.printWay.filter(
                (item) => item.productPrintingPricingId === k
              )?.[0]
              obj.productPrintingWayId = currItem.productPrintingPricingId
              obj.optionsVOS = currItem.optionsVOS
              obj.printingPositionIdsOptions = currItem.printingPositionIds
              obj.nameCn = currItem.nameCn
              obj.nameEn = currItem.nameEn
              this.$set(this.printList, this.printList.length, obj)
            }
          }
        } else {
          // 减少
          for (let k of idArr) {
            if (!val.includes(k)) {
              const idx = this.printList.findIndex(
                (item) => item.productPrintingWayId === k
              )
              this.printList.splice(idx, 1)
            }
          }
        }
        this.updateKey++
        this.$Bus.$emit('printWay', this.productPrintingWayId)
      },
      // 基本信息 产品类别改变，印刷方式改变
      getPrintWayByCategoryId() {
        this.$Bus.$on('changeCategoryId', async (categoryId) => {
          // 清空上次选择
          this.productPrintingWayId = []
          this.printList.splice(0, this.printList.length)
          let res = await ProductAddEditInteractor.getPrintWay(categoryId)
          this.printWay = res.code === '000000' ? res.data : []
          this.$Bus.$emit('sendPrintWay', this.printWay)
          this.updateKey++
        })
      },
      // 获取数量阶梯
      getNumberStep() {
        this.$Bus.$on('ChangeShippingCosts', (val) => {
          let bcglXiangXiList = val
          bcglXiangXiList = bcglXiangXiList.map((it) => {
            return {
              ...it,
              tag: `${it.packingWeight}-${it.numberPerBox}-${it.packingVolume}-${it.productWeight}`, // 判断是不是同一种规格
            }
          })
          this.numberStepList = val.map((item) => {
            return {
              num: Number(item.num),
              transportHeadPrice: Number(item.transportHeadPrice),
            }
          })
          this.numberStepList = _.uniqBy(this.numberStepList, 'num')
        })
      },
      onPrintingSwitchChange({ productPrintingSwitch, options }) {
        this.productPrintingSwitch = productPrintingSwitch == 1 ? true : false
        this.options = options
      },
      focusFn(obj) {
        obj.blurBol = false
      },
      blurFn(obj) {
        obj.blurBol = true
      },
    },
    watch: {
      printWay: {
        handler() {
          this.printWay.forEach((item, i) => {
            item.printPositionColumn = item.printPositionValue?.map(
              (ele, j) => {
                return {
                  id: ele,
                  setVisible: false,
                }
              }
            )
            item.optionsVOS?.forEach((ele, j) => {
              this.$set(ele, `ratio_0`, 1) // 设置一位的系数
              // 设置一位的单价
              // 用于印刷单价改变，同时改变 位数单价
              this.$set(ele, 'priceLength', item.printPositionColumn?.length)
            })
            item.optionsVOSMulity?.forEach((ele) => {
              // 设置一位的单价
              // 用于印刷单价改变，同时改变 位数单价
              this.$set(ele, 'priceLength', item.printPositionColumn?.length)
              this.$set(ele, 'ratio_0', 1)
            })
          })
        },
        deep: true,
        immediate: true,
      },
      printList: {
        handler(oldval, newval) {
          // 发送数据到sage平台
          this.$Bus.$emit('printList', this.printList)
        },
        deep: true,
        immediate: true,
      },
    },
  }
</script>
<style scoped lang="scss">
  .ratio_input {
    width: 130px;
  }

  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }

    .content {
      height: 100%;
      border: 1px solid #e4e7ed;
      border-radius: 3px;

      .content-head {
        height: 50px;
        line-height: 50px;
        background-color: #f2f2f2;
        width: 100%;
        padding-left: 10px;
      }

      .panel-item {
        padding: 20px 10px 0;
      }

      .panle-item-title {
        padding: 10px 20px;

        .left-h6 {
          line-height: 30px;
          margin-bottom: 20px;

          &::before {
            content: '';
            display: block;
            height: 26px;
            width: 4px;
            background: rgba(2, 125, 180, 1);
            margin-right: 5px;
          }
        }

        .outnumber-panle {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          position: relative;

          ::v-deep .el-input {
            width: 100px;
            margin: 0 10px;
          }

          .box {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            ::v-deep .el-input {
              width: 100px;
              margin: 0 10px;
            }
          }

          .calculation-cycle {
            position: absolute;
            right: 0;
            top: 0px;
          }
        }
      }
    }

    ::v-deep {
      .el-table {
        .el-table__body td {
          height: 86px;

          .el-form-item {
            margin: 18px 0;
          }
        }
      }
    }
  }
</style>