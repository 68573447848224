<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="300"
    :before-close="handleClose"
  >
    <el-table border :data="list" max-height="500" style="width: 100%">
      <el-table-column
        align="center"
        label="库位号"
        prop="fullLocationNumber"
      ></el-table-column>
      <el-table-column
        align="center"
        label="在仓数"
        prop="num"
      ></el-table-column>
      <el-table-column
        align="center"
        label="盘点数量"
        prop="takingNum"
      ></el-table-column>
      <el-table-column
        align="center"
        label="差异数量"
        prop="takingDiff"
      ></el-table-column>
    </el-table>
    <p class="mt10">
      差异数量总计：
      <span :style="diffTotal < 0 ? 'color:red' : ''">{{ diffTotal }}</span>
    </p>
  </el-dialog>
</template>
<script>
import { StoreCheck } from '@/core'
export default {
  props: {
    dialogVisible: {
      default: false,
      type: Boolean,
    },
    sku: {
      default: '',
      type: String,
    },
    stockTakingId: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      list: [],
    }
  },
  watch: {
    dialogVisible(newval, oldval) {
      if (newval) {
        this.init(this.sku, this.stockTakingId)
      }
    },
  },
  computed: {
    diffTotal() {
      let list = []
      if (this.list.length > 0) {
        list = this.list?.map((it) => it.takingDiff)
        return list?.reduce((prev, cur, index, arr) => {
          return prev + cur
        })
      } else {
        return 0
      }
    },
  },
  methods: {
    async init(sku, stockTakingId) {
      let res = await StoreCheck.getDetailBySkuAndStockTakingId(
        sku,
        stockTakingId
      )
      this.list = res.data || []
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
  },
}
</script>