<template>
  <!-- 抵扣金额 -->
  <el-dialog
    width="900px"
    :visible.sync="dialogVisible"
    :title="$t('AddOrder.Deduction')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form">
      <el-table border :data="form.tableData" max-height="500">
        <el-table-column label="#" type="index"></el-table-column>
        <!-- 关联订单 -->
        <el-table-column
          :label="$t('cusDetail.AssociatedOrder')"
          prop="orderNumber"
        ></el-table-column>
        <!-- 可抵扣金额 -->
        <el-table-column
          :label="$t('AddOrder.DeductibleAmount')"
          prop="collectionAmountUsd"
        ></el-table-column>
        <!-- 本单抵扣金额 -->
        <el-table-column :label="$t('AddOrder.AmountDeductedFromThisOrder')">
          <template slot-scope="scope">
            <el-form-item
              :prop="'tableData.' + scope.$index + '.num'"
              :rules="{
                validator: (rule, value, call) => {
                  validDeduc(rule, value, call, scope.row)
                },
                trigger: 'blur',
              }"
            >
              <el-input
                v-model.trim="scope.row.num"
                @blur="calculatePrice"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
      <div class="total">
        <!-- 抵扣总金额 -->
        {{ $t('AddOrder.DeductTheTotalAmount') }}：$
        {{ totalAmount || calcAmount | formatPrice }}
      </div>
    </el-form>
    <div class="text-center mt20">
      <!-- 确认 -->
      <el-button type="primary" @click="confirmDedu">
        {{ $t('AddOrder.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="dialogVisible = false">
        {{ $t('AddOrder.Cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDeductionAmountList } from '@/api/finance/finance-cost'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        tableData: [],
      },
      totalMoney: 0,
      calcAmount: 0,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
    totalAmount() {
      return this.form.tableData.reduce((total, item) => {
        if (!isNaN(item.collectionAmountUsd)) {
          return total + Number(item.num)
        } else {
          return total + 0
        }
      }, 0)
    },
  },
  methods: {
    calculatePrice() {
      let array = []
      this.form.tableData.forEach((item) =>
        item?.num ? array.push(Number(item.num)) : 0
      )
      this.calcAmount = array.length ? array.reduce((p, v) => p + v) : ''
    },

    show(data) {
      this.dialogVisible = true
      this.totalMoney = data.totalMoney
      let abdDTOS = data.abdDTOS || []

      getDeductionAmountList(data).then((res) => {
        if (res.code === '000000') {
          let list = res.data || []
          this.form.tableData = list.map((item) => {
            for (let i = 0; i < abdDTOS.length; i++) {
              if (
                item.customerAccountBalanceId ==
                abdDTOS[i].customerAccountBalanceId
              ) {
                item['num'] = abdDTOS[i].collectionAmountUs
              }
            }
            return item
          })
        }
      })
    },
    //校验
    validDeduc(rule, value, cb, row) {
      if (value === '0' || value === 0) {
        cb()
      } else if (
        !/(^[1-9]\d*(\.\d{1,3})?$)|(^0(\.\d{1,3})?$)/.test(value) &&
        value
      ) {
        const msg =
          this.lang === 'en'
            ? 'Please enter a positive number with a maximum of 3 decimals'
            : '请输入正数,最多保留3位小数'
        cb(new Error(msg))
      } else if (Number(value) > row.collectionAmountUsd) {
        const msg =
          this.lang === 'en'
            ? 'Cannot be greater than the deductible amount'
            : '不能大于可抵扣金额'
        cb(new Error(msg))
      } else {
        cb()
      }
    },
    confirmDedu() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.totalAmount > this.totalMoney) {
            const msg =
              this.lang === 'en'
                ? 'The deduction amount cannot be greater than the total amount!'
                : '抵扣金额不能大于总金额！'

            this.$message.warning(msg)
            return false
          }
          let discountRemark = ''
          this.form.tableData
            .filter((item) => {
              return item.num > 0
            })
            .forEach((item, index) => {
              const tips1 = this.$t('cusDetail.AssociatedOrder')
              const tips2 = this.$t('AddOrder.AmountDeductedFromThisOrder')

              if (index == 0) {
                discountRemark += `${tips1}：${item.orderNumber} / ${tips2}：${
                  item.num ? '$' + Number(item.num).toFixed(2) : '--'
                }`
              } else {
                discountRemark += `;<br/>${item.orderNumber} / ${tips2}：${
                  item.num ? '$' + Number(item.num).toFixed(2) : '--'
                }`
              }
            })
          let list = this.form.tableData.map((item) => {
            return {
              customerAccountBalanceId: item.customerAccountBalanceId,
              collectionAmountUs: item.num,
            }
          })
          this.$emit('onconfirm', {
            usMoney: this.totalAmount || this.calcAmount || '',
            discountRemark,
            abdDTOS: list,
          })
          this.dialogVisible = false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-table {
  td .cell {
    padding: 10px;
    overflow: visible;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
}
.total {
  text-align: right;
  font-size: 16px;
  margin-top: 10px;
  color: #333;
}
</style>