<template>
  <p>
    <!-- <i v-for="(i, index) in count" :key="index" class="el-icon-star-off"></i> -->
    <span>******</span>
  </p>
</template>

<script>
  export default {
    name: 'BaseStar',
    props: {
      count: {
        type: Number,
        default: 6,
      },
    },
  }
</script>

<style></style>
