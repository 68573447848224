<template>
  <el-dialog title="支付方式" :visible.sync="dialogVisible" width="60%" @close="close" class="dialog-middle">
    <el-form :model="form" label-width="100px" :rules="rules" size="small" ref="form">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="支付方式" prop="financeName">
            <el-input v-model="form.financeName" maxlength="50" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计算手续费" prop="charge">
            <!--fixbugid=7605 财务-财务设置，支付方式，新增，计算手续费根据原型默认显示勾选 by jiangyongqiang -->
            <el-switch v-model="form.charge" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="20">
          <el-form-item>
            <el-checkbox v-model="form.ticketNumberRequired">
              票据单号必填
            </el-checkbox>
            <span style="margin-left: 10px; font-size: 12px">
              勾选后在新增收款单时，票据单号为必填项
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="form.charge === 1">
        <el-col :span="12">
          <el-form-item label="币种" prop="currency">
            <el-select v-model="form.currency" value-key="currency" placeholder="请选择">
              <el-option v-for="item in currencyList" :key="item.financeSetupId" :label="item.financeName"
                :value="item.financeName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="form.charge === 1">
        <el-table :data="form.payMethodDetailList" border>
          <el-table-column align="center" prop="num" label="金额区间" label-class-name="require-th" border width="320px">
            <template slot-scope="scope">
              <div class="input-group">
                <el-form-item :prop="
                    'payMethodDetailList.' + scope.$index + '.amountStartRange'
                  " :rules="[
                    {
                      required: true,
                      validator: (rule, value, cb) => {
                        startNumValid(rule, value, cb, scope.$index)
                      },
                      trigger: 'blur',
                    },
                  ]" label-width="0">
                  <!--  :readonly="scope.$index == 0" -->
                  <el-input size="small" v-model="scope.row.amountStartRange" placeholder="请输入" />
                </el-form-item>
                <span>-</span>
                <el-form-item :prop="
                    'payMethodDetailList.' + scope.$index + '.amountEndRange'
                  " :rules="[
                    {
                      required: true,
                      validator: (rule, value, cb) => {
                        endNumValid(rule, value, cb, scope.$index)
                      },
                      trigger: 'blur',
                    },
                  ]" label-width="0">
                  <el-input size="small" v-model="scope.row.amountEndRange" placeholder="不填写则默认为+∞" />
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="num" label="百分比(%)">
            <template slot-scope="scope">
              <el-form-item :prop="'payMethodDetailList.' + scope.$index + '.percentage'" :rules="[
                  {
                    required: true,
                    validator: (rule, value, cb) => {
                      percentValid(rule, value, cb, scope.$index)
                    },
                    trigger: 'blur',
                  },
                ]" label-width="0">
                <el-input size="small" v-model="scope.row.percentage" placeholder="请输入" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="num" label="固定费">
            <template slot-scope="scope">
              <el-form-item :prop="'payMethodDetailList.' + scope.$index + '.fixedFee'" :rules="rules.fixedFee"
                label-width="0">
                <el-input maxlength="8" size="small" v-model.number="scope.row.fixedFee" placeholder="请输入" />
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column align="center" label="结算百分比(%)">
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="'payMethodDetailList.' + scope.$index + '.settlePercent'" :rules="[
                  {
                    required: true,
                    validator: (rule, value, cb) => {
                      percentValid(rule, value, cb, scope.$index)
                    },
                    trigger: 'blur',
                  },
                ]">
                <el-input size="small" v-model="scope.row.settlePercent" placeholder="请输入" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" label="结算固定费">
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="
                  'payMethodDetailList.' + scope.$index + '.settleFixedFee'
                " :rules="rules.settleFixedFee">
                <el-input maxlength="8" size="small" v-model.number="scope.row.settleFixedFee" placeholder="请输入" />
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column prop="date" label="操作" width="80" align="center">
            <!--fix bugid=7607  财务-财务设置，支付方式，新增，计算手续费选择否时刻全部删除  add form.charge === 0  by jiangyongqiang-->
            <template slot-scope="scope">
              <el-tooltip placement="top" content="删除" :tabindex="scope.$index" trigger="hover" v-if="
                  scope.$index == form.payMethodDetailList.length - 1 &&
                  (form.payMethodDetailList.length != 1 || !form.charge)
                ">
                <el-button :disabled="form.payMethodDetailList.length == 1" icon="el-icon-delete" type="danger"
                  size="mini" circle plain @click="removeItem(scope.row.key)"></el-button>
              </el-tooltip>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="table-foot" @click="addItem" v-if="form.charge === 1">
        新增一行
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="close">取消</el-button>
      <el-button type="primary" size="mini" @click="submit" :loading="loading">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    FinancePaymentAdd,
    FinancePaymentUpdate,
    FinancePaymentDetail,
  } from '@/api/finance/finance-setting'
  export default {
    name: 'PaymentSetting',
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: {
          charge: 1,
          currency: '',
          financeName: '',
          financeSetupId: null,
          payMethodDetailList: [],
          ticketNumberRequired: false, //票据单号
          fixedFee: '',
          settleFixedFee: '',
        },
        currencyList: [],
        rules: {
          financeName: [{ required: true, message: '请输入', trigger: 'blur' }],
          currency: [{ required: true, message: '请选择', trigger: 'change' }],
          fixedFee: [
            { validator: this.$formValidation.isPositiveInt, trigger: 'blur' },
          ],
          settleFixedFee: [
            { validator: this.$formValidation.isPositiveInt, trigger: 'blur' },
          ],
        },
      }
    },
    created() {
      this.getCurrency()
    },
    methods: {
      //打开弹框
      showDialog(row) {
        this.dialogVisible = true
        if (row) {
          FinancePaymentDetail({ financeSetupId: row.financeSetupId }).then(
            (res) => {
              if (res.code == '000000') {
                this.$nextTick(() => {
                  this.form = this.utils.deepCopy(row)
                  this.form.ticketNumberRequired =
                    this.form.ticketNumberRequired == 1 ? true : false
                  this.form.payMethodDetailList = res.data || []

                  // 无穷大
                  if (
                    this.form.payMethodDetailList.length &&
                    this.form.payMethodDetailList[
                      this.form.payMethodDetailList.length - 1
                    ].amountEndRange === 999999999
                  ) {
                    this.form.payMethodDetailList[
                      this.form.payMethodDetailList.length - 1
                    ].amountEndRange = ''
                  }
                })
              }
            }
          )
        } else {
          this.form.financeSetupId = null
          this.form.payMethodDetailList = [
            {
              amountEndRange: '',
              amountStartRange: '',
              fixedFee: '',
              percentage: '',
              settlePercent: '',
              settleFixedFee: '',
              key: Date.now() + Math.random(),
            },
          ]
        }
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
      async getCurrency() {
        let res = await this.pubApi.financeSelect({ financeType: 1 })
        if (res.code == '000000') {
          this.currencyList = res.data
        }
      },
      //清空表单
      close() {
        this.dialogVisible = false
        this.form = {}
        // this.$refs.form.resetFields()
      },
      //添加一行
      addItem() {
        this.form.payMethodDetailList.push({
          amountEndRange: '',
          amountStartRange: '',
          fixedFee: '',
          percentage: '',
          settlePercent: '',
          settleFixedFee: '',
          key: Date.now() + Math.random(),
        })
        if (this.form.payMethodDetailList.length == 1) {
          this.form.payMethodDetailList[0].amountStartRange = 0
        }
      },
      //删除一行
      removeItem(key) {
        this.form.payMethodDetailList.forEach((item, i) => {
          if (item.key == key) {
            this.form.payMethodDetailList.splice(i, 1)
          }
        })
      },
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            let Method
            if (this.form.financeSetupId) {
              Method = FinancePaymentUpdate
            } else {
              Method = FinancePaymentAdd
            }
            let params = JSON.parse(JSON.stringify(this.form))
            if (this.form.payMethodDetailList.length) {
              //如果最后一行不填，表示无穷大，需要传给后端999999999
              if (
                params.payMethodDetailList[
                  params.payMethodDetailList.length - 1
                ].amountEndRange === ''
              ) {
                params.payMethodDetailList[
                  params.payMethodDetailList.length - 1
                ].amountEndRange = 999999999
              }
            } else {
              if (params.charge === 1) {
                this.$message.warning('请最少添加一行')
                return
              }
            }
            this.loading = true
            params.payMethodDetailList = params.payMethodDetailList.map(
              (item) => {
                return {
                  ...item,
                  amountEndRange: Number(item.amountEndRange),
                  amountStartRange: Number(item.amountStartRange),
                }
              }
            )
            params = {
              ...params,
              ticketNumberRequired: params.ticketNumberRequired ? 1 : 0,
              charge: this.form.charge ? 1 : 0,
            }
            Method(params)
              .then((res) => {
                this.loading = false
                if (res.code == '000000') {
                  this.$message.success(
                    this.form.financeSetupId ? '修改成功！' : '新增成功！'
                  )
                  this.close()
                  this.form.ticketNumberRequired = false
                  this.$emit('success')
                }
              })
              .catch((err) => {
                this.loading = false
              })
          }
        })
      },
      // 百分比
      percentValid(rule, value, callback, index) {
        if (value === '') {
          callback()
        } else {
          if (isNaN(value)) {
            callback(new Error('请输入数字'))
          } else if (!/^([1-9]\d*|[0]{1,1})$/g.test(value)) {
            // !/^100|([1-9]?\d)([.]\d{1,2})?$/.test(value)
            callback(new Error('请输入正数'))
          } else if (value > 100) {
            callback(new Error('不能超过100'))
          } else {
            callback()
          }
        }
      },
      // 最小金额校验
      startNumValid(rule, value, callback, index) {
        if (isNaN(value) || value === '') {
          callback(new Error('请输入数字'))
        } else if (index == 0 && value == '0') {
          //第一行可以输入0
          callback()
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
          callback(new Error('最多精确到小数点后两位'))
        } else if (
          index > 0 &&
          Number(value) <
          this.form.payMethodDetailList[index - 1].amountEndRange
        ) {
          callback(new Error('必须大于上一条金额'))
        } else {
          callback()
        }
      },
      // 最大金额校验
      endNumValid(rule, value, callback, index) {
        if (index == this.form.payMethodDetailList.length - 1 && value === '') {
          //最后一条可以不填写
          callback()
        } else {
          if (isNaN(value)) {
            callback(new Error('请输入数字'))
          } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            callback(new Error('最多精确到小数点后两位'))
          } else if (
            Number(value) <
            this.form.payMethodDetailList[index].amountStartRange
          ) {
            callback(new Error('不能小于起始金额'))
          } else {
            callback()
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-select {
    width: 100%;
  }

  .input-group {
    display: flex;
    align-items: center;

    >span {
      margin-bottom: 18px;
    }
  }

  .table-foot {
    text-align: center;
    color: #1890ff;
    font-size: 14px;
    line-height: 2;
    cursor: pointer;
  }
</style>