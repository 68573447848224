<template>
  <el-dialog
    width="1080px"
    top="20%"
    :visible.sync="netPriceVisible"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <table class="custom-table no-space">
        <thead>
          <tr>
            <th>
              <span>
                <i>*</i>
                <!-- 成本价(￥) -->
                {{ lang === 'en' ? 'Cost price (￥)' : '成本价(￥)' }}
              </span>
            </th>
            <th>
              <span>
                <i>*</i>
                <!-- 利润率 -->
                {{ lang === 'en' ? 'rate of profit' : '利润率' }}
              </span>
            </th>
            <th>
              <span>
                <i>*</i>
                <!-- 税率 -->
                {{ lang === 'en' ? 'Tax rate' : '税率' }}
              </span>
            </th>
            <th>
              <span>
                <i>*</i>
                <!-- 装箱体积 -->
                {{ lang === 'en' ? 'Packing volume' : '装箱体积' }}
              </span>
            </th>
            <th>
              <span>
                <i>*</i>
                <!-- 装箱重量 -->
                {{ lang === 'en' ? 'Packing weight' : '装箱重量' }}
              </span>
            </th>
            <th width="240">
              <span>
                <i>*</i>
                <!-- 运输方式 -->
                {{ lang === 'en' ? 'Shipping Method' : '运输方式' }}
              </span>
            </th>
            <th>
              <span>
                <i>*</i>
                <!-- 运费单价 -->
                {{ lang === 'en' ? 'The freight cost' : '运费单价' }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <el-form-item
                prop="costPrice"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'change',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: $t('placeholder.decimals'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="20"
                  v-model="form.costPrice"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </td>
            <td>
              <el-form-item
                prop="profitRate"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'change',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: $t('placeholder.decimals'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="20"
                  v-model="form.profitRate"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </td>
            <td>
              <el-form-item
                prop="taxRate"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'change',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: $t('placeholder.decimals'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="20"
                  v-model="form.taxRate"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </td>
            <td>
              <el-form-item
                prop="totalBoxVolumeM"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'change',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: $t('placeholder.decimals'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="20"
                  v-model="form.totalBoxVolumeM"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </td>
            <td>
              <el-form-item
                prop="totalWeightKgs"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'change',
                  },
                  {
                    pattern: /^\d+(\.\d{1,3})?$/,
                    message: $t('placeholder.decimals'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="20"
                  v-model="form.totalWeightKgs"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </td>
            <td>
              <el-form-item prop="transportWay">
                <el-select
                  @change="transportWayChange"
                  v-model="form.transportWay"
                  :placeholder="$t('placeholder.plsSel')"
                  :disabled="netPrice == 0 ? true : false"
                  clearable
                >
                  <el-option
                    v-for="item in transpWayList"
                    :key="item.value"
                    :label="lang === 'en' ? item.labelEn : item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </td>
            <td>
              <el-form-item prop="transportPrice">
                <el-input
                  maxlength="20"
                  v-model="form.transportPrice"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </td>
          </tr>
        </tbody>
      </table>
    </el-form>

    <div class="flex mt20">
      <el-button type="primary" @click="calculatePrice">
        {{ lang === 'en' ? 'Click on the calculation' : '点击计算' }}
      </el-button>
      <h4 class="ml10">
        Net price($):
        <span>{{ netPrice }}</span>
      </h4>
    </div>

    <div class="text-center mt20">
      <!-- 确认 -->
      <el-button type="primary" @click="save()">
        {{ $t('inquiryList.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="close()">{{ $t('inquiryList.Cancel') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { quickAccessMinFreight } from '@/api/product/productPrintWay.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      amount: null, // 产品数量
      listIndex: null, // 产品索引
      rowIndex: null, // 行缩影
      transpWayList: [], // 运输方式字典
      form: {
        costPrice: '', // 成本价
        profitRate: '', // 利润率
        taxRate: '', // 税率
        totalBoxVolumeM: '', // 装箱体积
        totalWeightKgs: '', // 装箱重量
        transportWay: '', // 运输方式
        transportPrice: '', // 运费单价
      },
      rules: {
        transportWay: [
          {
            required: true,
            message: this.$t('placeholder.plsInput'),
            trigger: 'change',
          },
        ],
        transportPrice: [
          {
            required: true,
            message: this.$t('placeholder.plsInput'),
            trigger: blur,
          },
          {
            pattern: /^\d+(\.\d{1,3})?$/,
            message: this.$t('placeholder.decimals'),
            trigger: 'blur',
          },
        ],
      },
      netPrice: 0,
      netPriceVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  methods: {
    // 初始化加载
    initLoading(params) {
      ;[this.listIndex, this.rowIndex, this.amount] = [...params]
      this.netPriceVisible = true
    },

    // 计算 netprice
    calculatePrice() {
      this.$refs.ruleForm.rules.transportPrice[0].required = false
      this.$refs.ruleForm.rules.transportWay[0].required = false
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            num: this.amount,
            ...this.form,
          }
          quickAccessMinFreight(params).then((res) => {
            const data = res.data
            data.transportWayVOList.find((item) => {
              if (item.freightSettingId === data.freightSettingId) {
                this.form.transportWay = item.freightSettingId
              }
            })
            this.transpWayList = res.data.transportWayVOList.map((item) => {
              return {
                ...item,
                label:
                  item.transportWayCn +
                  ' - ' +
                  item.totalTransportPrice +
                  ' - ' +
                  item.transportPrice,
                labelEn:
                  item.transportWayEn +
                  ' - ' +
                  item.totalTransportPrice +
                  ' - ' +
                  item.transportPrice,
                value: item.freightSettingId,
              }
            })
            this.form.transportPrice = res.data.transportPrice
            this.netPrice = res.data.netPrice.toFixed(3)
          })
        }
      })
    },

    // 确认
    save() {
      this.$refs.ruleForm.rules.transportPrice[0].required = true
      this.$refs.ruleForm.rules.transportWay[0].required = true
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit(
            'calcNetPriche',
            this.listIndex,
            this.rowIndex,
            this.netPrice
          )
          this.close()
        }
      })
    },

    // 运输方式切换
    transportWayChange(val) {
      this.transpWayList.find((item) => {
        if (item.freightSettingId == val) {
          this.form.transportPrice = item.transportPrice
          this.netPrice = item.netPrice.toFixed(3)
        }
      })
    },

    // 取消
    close() {
      this.$refs.ruleForm.resetFields()
      this.netPrice = 0
      this.netPriceVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
h4 {
  line-height: 32px;
  span {
    margin-left: 5px;
    color: #1890ff;
  }
}
</style>
