<!--
  功能：未读邮件
  作者：jiangyongqiang
  时间：2021年11月16日 20:20:50
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard
    title="未读邮件"
    @tab-click="handleTabClick"
    :tabs="tabs"
    :singleNum="singleNum"
    :teamNum="teamNum"
    :loading="loading"
  >
    <div v-if="list.length > 0">
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="unread-mails-item">
          <div
            class="content"
            @click="clientFollowClick(item)"
            :style="itemStyleObj"
          >
            <div class="mails-item--top">
              <span class="sender">{{ item.sender }}</span>
              <span class="topic" :style="boldStyle.bold">
                {{ item.title }}
              </span>
            </div>
            <div class="mails-item--bottom">
              <p>
                <span class="label">接收人:</span>
                <span class="content">{{ item.receiver }}</span>
              </p>
              <p>
                <span class="label">接收时间:</span>
                <span class="content">{{ item.receiveTime | timeFilter }}</span>
              </p>
            </div>
          </div>
        </IndexCardItem>
      </template>
      <div @click="readMore" style="text-align: center; margin-top: 10px">
        <el-link type="primary">查看更多</el-link>
      </div>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'

  import { HomeInteractor } from '@/core'

  export default {
    name: 'UnreadMails',
    mixins: [mixins],
    props: {},
    components: {},
    // 组件状态值
    data() {
      return {
        list: [],
        tabs: true,
        type: '0', //	0.个人 1.团队
        singleNum: 0,
        teamNum: 0,
      }
    },
    computed: {},

    methods: {
      readMore() {
        // 跳转
        this.$router.push({
          path: '/mail/my-mail/receive',
        })
      },
      getList() {
        this.loading = true
        let type = this.active === 'ME' ? '0' : '1'
        HomeInteractor.homeEmailList(type).then((res) => {
          if (res && res.code === '000000') {
            this.list = res.data.homeEmailVO.list || []
            const { singleNum, teamNum } = res.data
            this.singleNum = singleNum
            this.teamNum = teamNum
          } else {
            this.list = []
          }
          this.loading = false
        })
      },

      clientFollowClick({ catagoryId, emailAccount }) {
        let canClick = this.beforeListItemClick()

        if (canClick) {
          this.$store.state.mail.currentAccout = emailAccount
          this.$store.commit('mail/setCurrentAccount', emailAccount)
          let query = {
            emailAccount,
            fromHomePage: true,
          }

          if (catagoryId && catagoryId !== 'null') {
            query = {
              ...query,
              catagoryId,
            }
          }
          this.$router.push({
            path: '/mail/my-mail/receive',
            query,
          })
        }
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
  .unread-mails-item {
    font-size: 14px;
    .mails-item--top {
      margin-bottom: 6px;
      word-break: break-all;
      color: #000;
      .sender {
        margin-right: 10px;
      }
    }
    .mails-item--bottom {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      p {
        color: #5e5e5e;

        .label {
          margin-right: 5px;
        }
      }
    }
  }
</style>
