export const paymentStatusList = [
  // 交易状态
  {
    code: null,
    name: '--',
  },
  {
    code: 1,
    name: '未完成',
  },
  {
    code: 2,
    name: '支付成功',
  },
  {
    code: 3,
    name: '支付失败',
  },
  {
    code: 4,
    name: '银行退票',
  },
  {
    code: 5,
    name: '状态可疑',
  },
  {
    code: 6,
    name: '退票已处理',
  },
]

export const payTypes = [
  //付款类型
  {
    label: '采购付款',
    payType: 1,
    entryType: 0,
    index: 0,
  },
  {
    label: '采购退款',
    payType: 1,
    entryType: 1,
    index: 1,
  },
]
